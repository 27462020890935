import React from "react";
import {
  handleDateFormat,
  handlePanInfo,
  handeDIO2,
  headers,
  handeDIO,
} from "../utils/utils";
import axios from "axios";
import { CVL_KRA_URL } from "../../../Config";
import { createPartner, createPartnerUser } from "../../../api";

function usePrtnerOnboardingFLow({
  setFormData,
  setLoading,
  setApiErrorMessage,
  handleNext,
  formData,
}) {
  const api1 = async (values, rep) => {
    setLoading(true);
    const postData = {
      pan: values.panNumber,
      dob: handleDateFormat(values?.dateOfBirth),
    };
    // let newPayload = await handeDIO(postData, "dob",handleDateFormat);

    try {
      let res = await axios.post(CVL_KRA_URL, postData, { headers });

      if (res.data.success) {
        let pandInfo = handlePanInfo(res?.data, 2);

        let newPandInfo = await handeDIO2({
          ...pandInfo,
          dateOfBirth: values?.dateOfBirth,
          entityType: values?.entityType,
        });

        let obj = rep
          ? {
              representative: { ...newPandInfo, ...values },
            }
          : { ...newPandInfo, ...values };

        setFormData((prev) => {
          handleNext(1, { ...prev, ...obj });
          return { ...prev, ...obj };
        });
        // setNext(next + 1);
      }
      setLoading(false);
    } catch (error) {
      console.log(error, "alfgiasuofas");
      setApiErrorMessage(error?.response?.data?.error?.message);
      setLoading(false);
    }
  };

  const api2 = async (value, customJumb, customPayload) => {
    setLoading(true);

    let payload = {
      entityName: value?.entityName,
      entityType: value?.entityType,
      pan: formData?.panNumber || "",
      //   pan: "WTPRP3055D",
      panVerified: String(formData?.panVerified) || "",
      email: formData?.email || "",
      //   email: "69uubu3438@agmail.com",
      commencementDate: formData?.commencementDate || "",
      address: formData?.address || "",
      kycStatus: formData?.kycStatus || "",
      kycDate: formData?.kycDate || "",
      pancard: value?.pancard || "",
      idProof: value?.idProof || "",
    };

    if (!value?.pancard) {
      delete payload.pancard;
    }

    if (customPayload) {
      payload = customPayload;
    }

    let newPayLoad = handeDIO(payload, "doi", formData);

    let newFormData = new FormData();

    for (const key in newPayLoad) {
      if (key === "pancard") {
        newFormData.append("pan", newPayLoad[key]);
      } else {
        newFormData.append([key], newPayLoad[key]);
      }
    }

    try {
      let res = await createPartner(newFormData);

      setFormData((prev) => {
        handleNext(customJumb ? customJumb : 1, {
          ...prev,
          ...res?.data?.data?.result,
        });
        return { ...prev, ...res?.data?.data?.result };
      });
      // setNext(next + 1);
      // handleNext(1);
      setLoading(false);
    } catch (error) {
      setApiErrorMessage(error?.response?.data?.error?.message);
      setLoading(false);
      throw error?.response?.data?.error?.message;
    }
    // }
  };

  async function api5(formData1, customPayload, customPartnerId) {
    setLoading(true);

    console.log(formData1, formData, "ram");
    //TODO represenativeType harder coded needs to remove
    let payload = {
      representativeName: formData1?.entityName,
      representativeType: formData1?.userRole || "representative",
      // entityType: formData1?.entityType,
      pan: formData1?.panNumber || "",
      // pan: "PPOIQ1109D",
      panVerified: String(formData?.representative?.panVerified),
      email: formData?.representative?.email || "",
      // email: "0pvvhvsr@momoshe.com",
      commencementDate: formData1?.commencementDate,
      address: formData1?.address,
      kycStatus: formData1?.kycStatus,
      kycDate: formData1?.kycDate,
      pancard: formData1?.pancard,
      idProof: formData1?.idProof,
      mobile: formData?.representative?.mobile,
      lobId: formData?.representative?.lobId,
      subLobId: formData?.representative?.subLobId,
      region: formData?.representative?.region,
      //   mobile: "9857514507",
      // dob:formData?.representative?.doi
    };

    if (customPayload) {
      payload = customPayload;
    }

    if (!formData1?.pancard) {
      delete payload.pancard;
    }

    let newPayLoad = handeDIO(payload, "dob", formData1);

    let newFormData = new FormData();

    for (const key in newPayLoad) {
      if (key === "pancard") {
        newFormData.append("pan", newPayLoad[key]);
      } else {
        newFormData.append([key], newPayLoad[key]);
      }
    }

    /////////////////////////////////api call
    // console.log(formData, "afnkasnbjausbfjsa");
    createPartnerUser(
      newFormData,
      customPartnerId ? customPartnerId : formData?.id
    )
      .then((response) => {
        const apiData = response?.result;

        setFormData((prev) => {
          handleNext(1, { ...prev, ...response?.data?.data?.result });
          return { ...prev, ...response?.data?.data?.result };
        }); // setLoading(false);
        // setNext(next + 1);
        // handleNext(1);
        setLoading(false);
      })
      .catch((error) => {
        // setError(error);
        // setLoading(false);
        setApiErrorMessage(error?.response?.data?.error?.message);
        setLoading(false);
      });
  }

  return { api1, api2, api5 };
}

export default usePrtnerOnboardingFLow;
