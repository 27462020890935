import { NEW_ENTITY_TYPES } from "../components/NewSignUp/utils/constands";
import { roundNumber } from "../components/utils/common";

export const myDebounce = (fn) => {
  let timer;
  return function (...args) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      fn.call(this, ...args);
    }, 300);
  };
};

export const checkEmptyList = (dataList, setEmptyList) => {
  dataList && dataList.length > 0 ? setEmptyList(false) : setEmptyList(true);
};

export const getEntityNameFromEntityTypes = (entityValue) => {
  return NEW_ENTITY_TYPES.filter((entity) => entity.value === entityValue)[0]
    ?.name;
};

export const getTodayDateForMinMaxDate = (previousDay = 0) => {
  var dtToday = new Date();
  if (previousDay) {
    dtToday.setDate(dtToday.getDate() - previousDay);
  }
  var month = dtToday.getMonth() + 1;
  var day = dtToday.getDate();
  var year = dtToday.getFullYear();
  if (month < 10) month = "0" + month.toString();
  if (day < 10) day = "0" + day.toString();

  var maxDate = year + "-" + month + "-" + day;

  return maxDate;
};

export const dateFormat_2 = (inputDate) => {
  const date = inputDate.split("-");
  const year = date[0];
  const month = date[1];
  const day = date[2];

  return `${day}/${month}/${year}`;
};
function getval(val, ext) {}
function get_format(format, exit) {
  if (exit) {
    return "";
  }
  switch (format) {
    case "lakhs":
      return " Lacs";

    case "crores":
      return " Cr";
    default:
      return " ";
  }
}

export const currencyFormatPrint = (value, config, currency_format, font) => {
  const val = currencyFormat(value, config, currency_format);
  let pos = 0;
  if (parseFloat(value) < 0) {
    pos = 1;
  }
  let symbol = val.slice(pos, pos + 1);
  let text = val.slice(pos + 1);
  return (
    <>
      {pos == 1 ? "-" : ""}
      <span className={"font500 " + (font && font != "" ? font : "")}>
        {symbol}
      </span>{" "}
      {text}
    </>
  );
};
export const currencyFormatPrintNoFormat = (
  value,
  config,
  currency_format,
  font
) => {
  const val = currencyFormat(value, config, currency_format, true);
  let pos = 0;
  if (parseFloat(value) < 0) {
    pos = 1;
  }
  let symbol = val.slice(pos, pos + 1);
  let text = val.slice(pos + 1);
  return (
    <>
      {pos == 1 ? "-" : ""}
      <span className={"font500 " + (font && font != "" ? font : "")}>
        {symbol}
      </span>{" "}
      {text}
    </>
  );
};
export const currencyFormat = (value, config, currency_format, exit) => {
  let country = config?.country || "en-IN";

  let format = config?.format
    ? config?.format
    : currency_format
    ? currency_format
    : localStorage.getItem("currency_format")
    ? localStorage.getItem("currency_format")
    : "default";
  let ext = "";

  if (config && !config.currency) {
    config.currency = "INR";
  }
  if (config && !config.style) {
    config.style = "currency";
  }

  let style = config
    ? config
    : {
        currency: config?.currency || "INR",
        style: config?.style || "currency",
      };

  if (!config || config.currencyStyle?.style != "decimal") {
    value = roundNumber(value);
    if (format === "default") {
      if (Math.abs(value) >= 10000000) {
        value = roundNumber(value / 10000000);
        format = "crores";
      } else if (Math.abs(value) >= 100000) {
        value = roundNumber(value / 100000);
        format = "lakhs";
      } else {
        value = roundNumber(value);
      }
    } else {
      switch (format) {
        case "lakhs":
          value = roundNumber(value / 100000);
          break;
        case "crores":
          value = roundNumber(value / 10000000);
          break;
        default:
          value = roundNumber(value);
          break;
      }
    }
  } else {
    value = roundNumber(value);
  }

  return (
    // sym +
    new Intl.NumberFormat(country, {
      ...style,
      minimumFractionDigits: config?.showDecimal
        ? 2
        : format == "lakhs" || format == "crores"
        ? 2
        : 0,
      maximumFractionDigits: config?.showDecimal
        ? 2
        : format == "lakhs" || format == "crores"
        ? 2
        : 0,
    }).format(value) +
    (value ? get_format(config?.format ? config?.format : format, exit) : 0)
  );
};

export const currencyFormatNoRoundOff = (value, config, currency_format) => {
  let country = config?.country || "en-IN";

  let format = config?.format
    ? config?.format
    : currency_format
    ? currency_format
    : localStorage.getItem("currency_format")
    ? localStorage.getItem("currency_format")
    : "crores";
  let ext = "";

  if (config && !config.currency) {
    config.currency = "INR";
  }
  if (config && !config.style) {
    config.style = "currency";
  }

  let style = config
    ? config
    : {
        currency: config?.currency || "INR",
        style: config?.style || "currency",
      };

  return (
    // sym +
    new Intl.NumberFormat(country, {
      ...style,
      maximumFractionDigits: 4,
    }).format(value) +
    (value ? get_format(config?.format ? config?.format : format) : 0)
  );
};

export const currencyFormatINR = (value) => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(value);
};

export const dateFormat_3 = (inputDate) => {
  if (inputDate) {
    const date = inputDate.split("-");
    const year = date[0];
    const month = date[1];
    const day = date[2];

    return `${day}-${month}-${year}`;
  }
};
