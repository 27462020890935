import React, { useState } from "react";
import { userLogin } from "../api";
import { OnboardingContext } from "../Context";
import { useLocation, useNavigate } from "react-router-dom";
import ls from "localstorage-slim";
import { useLocalStorage } from "./useLocalStorage";
import { fetchUserRolePermissions } from "../api";
export const useLogin = () => {
  const location = useLocation();
  const { state } = location;

  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const {
    userState: { signInFormData },
    dispatch,
  } = OnboardingContext();

  function generateScopes(arr) {
    return arr.reduce((acc, { role }) => {
      acc[role] = role;
      return acc;
    }, {});
  }
  function formatPermissionsArr(arr) {
    return arr.map((item) => item.role);
  }

  const submitForm = async (values) => {
    const { loginUserType } = values;

    setShowLoader(true);
    try {
      const data = await userLogin(
        values,
        loginUserType
        // loginEntityType ? loginEntityType : "mfo-user"
      );
      const loginData = data.data;

      if (loginData.success) {
        localStorage.setItem("colorMode", loginData.data.result.web_theme);
        window.dispatchEvent(new Event("themeChange"));

        fetchUserRolePermissions()
          .then((response) => {
            console.log(response?.data?.data?.result?.permissions);
            ls.set(
              "userPermissionsArr",
              JSON.stringify(
                formatPermissionsArr(response?.data?.data?.result?.permissions)
              )
            );
            ls.set(
              "scopes",
              JSON.stringify(
                generateScopes(response?.data?.data?.result?.permissions)
              )
            );
            ls.set("roleType", response?.data?.data?.result?.role);

            // NOTE Dont remove this dispatch or else the side bar wont show (Default.js)
            dispatch({
              type: "SET_SCOPES",
              payload: generateScopes(
                response?.data?.data?.result?.permissions
              ),
            });
          })
          .catch((error) => console.log(`An ${error} occurred`));
        // setShowLoader(false);
        const userType = loginData.data.result.user_type;
        let loginUserData = {};

        if (userType === "client") {
          loginUserData = {
            name: loginData.data.result.full_name,
            user_type: loginData.data.result.user_type,
            client_role_id: loginData.data.result.client_role_id,
            token: loginData.data.result.session_token,
            expires_at: loginData.data.result.session.expires_at,
            client_role: loginData.data.result.client_role,
            id: loginData.data.result.client_id,
            mid: loginData.data.result.entity_id,
          };
        } else if (userType === "partner") {
          loginUserData = {
            name: loginData.data.result.full_name,
            user_type: loginData.data.result.user_type,
            partner_role_id: loginData.data.result.partner_role_id,
            token: loginData.data.result.session_token,
            expires_at: loginData.data.result.session.expires_at,
            partner_role: loginData.data.result.partner_role,
            id: loginData.data.result.partner_id,
          };
        } else if (userType === "mfo-user") {
          loginUserData = {
            name: loginData.data.result.name,
            user_type: loginData.data.result.user_type,
            mfo_role_id: loginData.data.result.mfo_role_id,
            token: loginData.data.result.session_token,
            expires_at: loginData.data.result.session.expires_at,
            mfo_role_name: loginData.data.result.mfo_role_name,
            id: loginData.data.result.id,
          };
        }
        localStorage.setItem("userType", loginUserData.user_type);
        ls.set("logged-entity", loginUserData, { secret: 50 });
        localStorage.setItem("currency_format", "default");
        //update state
        dispatch({
          type: "UPDATE_LOGIN_USERDATA",
          payload: loginUserData,
        });

        //navigate to dashboard
        // navigate("/home/all-members");
        setShowLoader(false);
        //sessionStorage.setItem("currency_format", "crores");
        if (state?.return_url) {
          navigate(state?.return_url);
        } else {
          navigate("/home");
        }
      }
    } catch (error) {
      if (error.response.status == 401) {
        setErrorMessage(error.response.data.error.message);
        setShowLoader(false);
        return;
      }
      setErrorMessage(error.response.data.error.message);
      // setErrorMessage("User does not exist ");
      setShowLoader(false);
    }
  };
  return { submitForm, showLoader, errorMessage };
};
