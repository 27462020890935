import React from "react";
import PageLoader from "../../products/common/pageLoader";

function LoadingScreen({ loading }) {
  return (
    <>
      <PageLoader showLoader={loading} />
    </>
  );
}

export default LoadingScreen;
