import { Client_User_Roles } from "../../../../../Constants";
const ENTITY_TYPES =
  // onlyPerosn
  //   ? [
  //       { name: "--Select--", value: "" },
  //       { value: "sole_proprietor", name: "Sole Proprietorship" },
  //     ]
  //   :
  [
    { name: "--Select--", value: "", selected: true },
    { value: "public_limited", name: "Public Limited" },
    { value: "private_limited", name: "Private Limited" },
    { value: "sole_proprietor", name: "Sole Proprietorship" },
    { value: "partnership", name: "Partnership Firm" },
    { value: "llp", name: "Limited Liability Partnership" },
    { value: "individual", name: " Individual" },
    { value: "HUF", name: " Hindu Undivided Family" },
  ];
export const client_add_members = [
  {
    xs: "12",
    lg: "12",
    sm: "12",
    children: [
      {
        type: "select",
        name: "userRole",
        title: "USER ROLE",
        func: null,
        options: Client_User_Roles,
        optionValueKey: "value",
        optionSelected: "selected",
        optionName: "name",
      },
    ],
  },

  {
    lg: "12",
    sm: "12",
    children: [
      {
        type: "select",
        name: "entityType",
        title: "Entity Type",
        func: null,
        options: ENTITY_TYPES,
        optionValueKey: "value",
        optionSelected: "selected",
        optionName: "name",
      },
    ],
  },

  {
    xs: "12",
    lg: "12",
    sm: "12",
    children: [
      {
        type: "text",
        title: "NAME",
        margin: "normal",

        label: "NAME",
        name: "name",
        func: null,
      },
      // {
      //   type: "text",
      //   title: "NAME",
      //   margin: "normal",

      //   label: "NAME",
      //   name: "name",
      //   func: null,
      // },
    ],
  },
  {
    xs: "12",
    lg: "12",
    sm: "12",
    children: [
      {
        type: "text",
        title: "MOBILE",
        margin: "normal",
        label: "MOBILE",
        name: "mobile",
        func: null,
      },
    ],
  },
  {
    xs: "12",
    lg: "12",
    sm: "12",
    children: [
      {
        type: "text",
        title: "EMAIL",
        variant: "outlined",
        margin: "normal",

        label: "EMAIL",
        name: "email",
        func: null,
      },
    ],
  },
  {
    xs: "12",
    lg: "12",
    sm: "12",
    children: [
      {
        type: "text",
        title: "PAN",
        variant: "outlined",
        margin: "normal",

        label: "PAN",
        name: "panNumber",
        func: null,
      },
    ],
  },
];

export const client_set_pin = [
  {
    xs: "12",
    lg: "12",
    sm: "12",
    children: [
      {
        type: "password",
        title: "ENTER PIN",
        variant: "outlined",
        margin: "normal",

        label: "ENTER PIN",
        name: "pin",
        func: null,
      },
    ],
  },
  {
    xs: "12",
    lg: "12",
    sm: "12",
    children: [
      {
        type: "password",
        title: "CONFIRM PIN",
        variant: "outlined",
        margin: "normal",

        label: "CONFIRM PIN",
        name: "confirmPin",
        func: null,
      },
    ],
  },
];
