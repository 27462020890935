import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import "./Table.css";
import { ReactComponent as UpLineSVG } from "../../../../assets/icons/upLineSVG.svg";
import {
  getBgColor,
  getBorderColor,
  objectFormatingPrint,
} from "./utils/common";
import { dateFormatCommon, dateFormatter } from "../../../utils/common";

const stbodyData = [
  {
    assetClass: "Equity",
    currentValue: "12",
    investedValue: "10.3454",
    totalGainLoss: "2.4",
    xirr: "12.4",
    absolueteTwrr: "-12.4",
    annualizedTwrr: "12.4",
  },
  {
    assetClass: "Debt",
    currentValue: "12",
    investedValue: "10.3454",
    totalGainLoss: "2.4",
    xirr: "12.4",
    absolueteTwrr: "12.4",
    annualizedTwrr: "-12.4",
  },
  {
    assetClass: "Alternates",
    currentValue: "12",
    investedValue: "10.3454",
    totalGainLoss: "2.4",
    xirr: "-12.4",
    absolueteTwrr: "12.4",
    annualizedTwrr: "12.4",
  },
  {
    assetClass: "Cash",
    currentValue: "12",
    investedValue: "10.3454",
    totalGainLoss: "2.4",
    xirr: "12.4",
    absolueteTwrr: "12.4",
    annualizedTwrr: "-12.4",
  },
];

const stHeaderData = [
  {
    field: "assetClass",
    title: "Asset Class",
    align: "left",
    color: "",
  },
  {
    field: "currentValue",
    title: "Current Value",
    subTitle: "₹ In Crore",
  },
  {
    field: "investedValue",
    title: "Invested Value",
    subTitle: "₹ In Crore",
  },
  {
    field: "totalGainLoss",
    title: "TOtal Gain/Loss",
    subTitle: "₹ In Crore",
  },
  {
    field: "xirr",
    title: "Xirr",
  },
  {
    field: "absolueteTwrr",
    title: "Absolute Twrr",
  },
  {
    field: "annualizedTwrr",
    title: "Annualized Twrr",
  },
];

let stData = [];
stbodyData.map((val) => {
  stData.push(objectFormatingPrint(val, stHeaderData));
});

// console.log(stData, "akjbakjbfkjas");

function PrintTable({
  data,
  header,
  hideUnderText,
  customPadding,
  firstCellCircleProps,
  titleCellProps,
  normalCellProps,
  headerProps,
  colSpan,
  totalColSpan,
  tableTitle,
  hideFirstCellCircle,
  disclaimerCellProps,
  customRef,
  updatedAsOn,
  hideTitle,
  customTableClass,
  colormapping,
  colors,
  hidecircle,
  eoTable,
}) {
  const tableHeaders = (data) => {
    return data?.map((val) => {
      return (
        <TableCell
          align={val?.align ? val?.align : "right"}
          style={{ ...headerProps?.style }}
          className={
            headerProps?.className ? headerProps?.className : "headerCell"
          }
        >
          <p
            className={`${
              headerProps?.titleClassName ? headerProps?.titleClassName : ""
            }headerTitle gloabalFont`}
            style={{ ...headerProps?.titleStyle, ...val?.headerStyle }}
          >
            {val?.title}
          </p>
          {val?.subtext ? val?.subtext : <div style={{ height: "24px" }}></div>}
          {val?.subTitle && (
            <div className="headerSub">
              <p className={` gloabalFont headerSubTitle`}>{val?.subTitle}</p>
            </div>
          )}
        </TableCell>
      );
    });
  };

  const handleBodyCell = (val, i, colSpan, hideTitle, j, totaltype) => {
    if (val.type_null) {
      return;
    }

    let totalFormat = {
      fontWeight: "900",
    };
    let disclaimerCellStyle = { ...disclaimerCellProps?.font };
    let normalCellStyle = { ...normalCellProps?.fontStyle };

    if (totaltype) {
      disclaimerCellStyle.fontWeight = "900";
      normalCellStyle.fontWeight = "900";
      normalCellStyle.fontFamily = "AvenirBold";
      normalCellStyle.transform = "skew(-5deg)";
      normalCellStyle.webkitTransform = "skew(-5deg)";
      // normalCellStyle.background = "#f0f5fa";
    }
    if (totaltype == "Grand Total") {
      normalCellStyle.marginTop = "10px";
      normalCellStyle.paddingTop = "10px";
      normalCellStyle.paddingBottom = "20px";
      // normalCellStyle.background = "#f0f5fa";
    }
    if (val?.type == "disclaimer") {
      // console.log(titleCellProps, "askfjakjbsfsf");
      return (
        <TableCell
          // ref={customRef}
          colSpan={disclaimerCellProps?.colsSpna}
          style={{
            borderLeft: i == 0 ? `2px solid ${val?.color}` : "",
            ...disclaimerCellProps?.cellStyle,
            // background: val?.posOrNeg
            //   ? getBgColor(val?.posOrNeg)
            //   : titleCellProps?.style?.background,
            // padding:
            //   i == 0
            //     ? colSpan > 1
            //       ? "15px 0px"
            //       : "0px 0px 0px 0px"
            //     : titleCellProps?.style?.padding
            //     ? titleCellProps?.style?.padding
            //     : "15px 0px",
          }}
          className={`
         
          borderNone`}
          // ${getBorderColor(val?.posOrNeg, true)}
          // className={`borderNone`}
          align={val?.align !== undefined ? val?.align : "right"}
        >
          {val?.posOrNeg && (
            <div
              style={{ backgroundColor: titleCellProps?.style?.background }}
              className="whiteSpace"
            ></div>
          )}
          <div
            className={i == 0 ? "firstCell" : "bodyCell"}
            style={{
              justifyContent: val?.align !== undefined ? val?.align : "right",
              paddingLeft: val?.align == "left" ? "0.5rem" : "0",
              paddingRight: val?.align == "right" ? "0.5rem" : "0",
            }}
          >
            {/* {i == 0 && (
              <div
                className="leftCircle"
                style={{ backgroundColor: val?.color, ...firstCellCircleProps }}
              ></div>
            )} */}
            <p
              className="gloabalFont bodyCellFont"
              style={{ ...disclaimerCellStyle }}
            >
              {val?.field}
            </p>
          </div>
        </TableCell>
      );
    }
    if (val?.type == "title") {
      // console.log(titleCellProps, "askfjakjbsfsf");
      if (hideTitle) {
        return null;
      }
      if ((i == 0 || i == 1) && totaltype == "Grand Total") {
        normalCellStyle.fontStyle = "normal";
      }
      return (
        <TableCell
          // ref={customRef}
          colSpan={colSpan}
          style={{
            borderLeft: i == 0 ? `2px solid ${val?.color}` : "",
            ...titleCellProps?.style,
            background: val?.posOrNeg
              ? getBgColor(val?.posOrNeg)
              : titleCellProps?.style?.background,
            padding:
              i == 0
                ? colSpan > 1
                  ? "15px 0px"
                  : "0px 0px 0px 0px"
                : titleCellProps?.style?.padding
                ? titleCellProps?.style?.padding
                : "15px 0px",
          }}
          className={` borderNone`}
          //${getBorderColor(val?.posOrNeg, true)}
          // className={`borderNone`}
          align={val?.align !== undefined ? val?.align : "right"}
        >
          {val?.posOrNeg && (
            <div
              style={{ backgroundColor: titleCellProps?.style?.background }}
              className="whiteSpace"
            ></div>
          )}
          <div
            className={i == 0 ? "firstCell" : "bodyCell"}
            style={{
              justifyContent: val?.align !== undefined ? val?.align : "right",
              minHeight: titleCellProps?.style?.minHeight,
              paddingLeft: val?.align == "left" ? "0.5rem" : "0",
              paddingRight: val?.align == "right" ? "0.5rem" : "0",
              fontFamily: "AvenirBold",
              fontWeight: 600,
            }}
          >
            {i == 0 && (
              <div
                className="leftCircle"
                style={{
                  backgroundColor: hidecircle ? val?.color : "",
                  ...firstCellCircleProps,
                }}
              ></div>
            )}
            <p
              className="gloabalFont bodyCellFont "
              style={{
                ...titleCellProps?.font,
                fontFamily: "AvenirBold",
                fontSize: "14px",
                fontWeight: 600,
                display: "flex",
              }}
            >
              {val?.field}
            </p>
          </div>
        </TableCell>
      );
    }
    if ((i == 0 || i == 1) && totaltype == "Grand Total") {
      normalCellStyle.fontStyle = "normal";
      normalCellStyle.transform = "skew(0deg)";
      normalCellStyle.webkitTransform = "skew(0deg)";
    }
    if (i == 0 && totaltype == "Grand Total") {
      normalCellStyle.fontSize = "18px";
    }
    // if (val?.type == "total" || val.field == "Total") {
    //   // console.log(titleCellProps, "askfjakjbsfsf");
    //   return (
    //     <TableCell
    //       // ref={customRef}
    //       colSpan={totalColSpan}
    //       style={{
    //         // borderLeft: i == 0 ? `2px solid ${val?.color}` : "",
    //         ...titleCellProps?.style,

    //         // background: val?.posOrNeg
    //         //   ? getBgColor(val?.posOrNeg)
    //         //   : titleCellProps?.style?.background,
    //         // background: "#415A73",
    //         padding:
    //           i == 0
    //             ? totalColSpan > 1
    //               ? "15px 0px"
    //               : "0px 0px 0px 0px"
    //             : titleCellProps?.style?.padding
    //             ? titleCellProps?.style?.padding
    //             : "0px",
    //         borderTop: totaltype == "Total" ? "1.2px solid darkgray" : "",
    //       }}
    //       className={`borderNone`}
    //       // className={`borderNone`}
    //       align={val?.align !== undefined ? val?.align : "right"}
    //     >
    //       {val?.posOrNeg && (
    //         <div
    //           style={{ backgroundColor: titleCellProps?.style?.background }}
    //           className="whiteSpace"
    //         ></div>
    //       )}
    //       <div
    //         className={i == 0 ? "firstCell" : "bodyCell"}
    //         style={{
    //           justifyContent: val?.align !== undefined ? val?.align : "right",
    //           paddingLeft: val?.align == "left" ? "0.5rem" : "0",
    //           paddingRight: val?.align == "right" ? "0.5rem" : "0",
    //         }}
    //       >
    //         {i == 0 && !hideFirstCellCircle && (
    //           <div
    //             className="leftCircle"
    //             style={{
    //               backgroundColor: hidecircle ? val?.color : "",
    //               ...firstCellCircleProps,
    //             }}
    //           ></div>
    //         )}
    //         <p
    //           className="gloabalFont bodyCellFont rsSpan"
    //           style={{
    //             ...titleCellProps?.font,
    //             fontWeight: "900",
    //             color: "black",
    //             fontFamily: "AvenirBold",
    //             display: "flex",
    //           }}
    //         >
    //           {val?.field}
    //         </p>
    //       </div>
    //     </TableCell>
    //   );
    // }
    return (
      <TableCell
        // ref={customRef}
        colSpan={colSpan}
        style={{
          // borderLeft: i == 0 ? `2px solid ${val?.color}` : "",
          ...normalCellProps?.tablecell,
          padding:
            i == 0
              ? normalCellProps?.firstCellPadding
                ? normalCellProps?.firstCellPadding
                : "0px"
              : val?.withtwoline
              ? "10px 16px"
              : customPadding,
          borderLeft: colormapping
            ? "4px solid " + colormapping[val.field]
            : colors && i == 0
            ? "4px solid " + colors[j - 1]
            : "none",
          borderTop:
            totaltype == "Total"
              ? "1.2px solid darkgray"
              : "1.2px solid #e0e0e0",
          borderBottom: totaltype == "Total" ? "1.2px solid darkgray" : "none",
          background:
            totaltype == "Grand Total" ? "#f3f8fd" : totaltype ? "#ffffff" : "",
        }}
        className={`
     borderNone`}
        //  ${getBorderColor(
        //   val?.posOrNeg,
        //   true,
        //   totaltype
        // )}
        align={val?.align !== undefined ? val?.align : "right"}
      >
        {val?.posOrNeg && <div className="whiteSpace"></div>}
        <div
          className={i == 0 ? "firstCell" : "bodyCell"}
          style={{
            ...normalCellProps?.parent,
            justifyContent: val?.align !== undefined ? val?.align : "right",
            paddingLeft: val?.align == "left" ? "0.5rem" : "0",
            paddingRight: val?.align == "right" ? "0.5rem" : "0",
          }}
        >
          {i == 0 && !hideFirstCellCircle && (
            <div
              className="leftCircle"
              style={{
                backgroundColor: hidecircle ? val?.color : "",
                ...firstCellCircleProps,
              }}
            ></div>
          )}
          <p
            style={{
              marginLeft: hideFirstCellCircle ? "12px" : "0px",
              ...normalCellStyle,
              ...val?.customStyle,
              display: "flex",
            }}
            className="gloabalFont bodyCellFont"
          >
            {val?.field}
          </p>
        </div>
      </TableCell>
    );
  };

  const tableBody = (data) => {
    let actualData = data?.filter(
      (row) =>
        row[0]?.type !== "disclaimer" &&
        row[0]?.type !== "total" &&
        row[0]?.type !== "title"
    );
    // console.log(actualData, "afljabsf");

    if (actualData?.length > 0) {
      return data?.map((row, j) => {
        // let actualData = row?.filter(
        //   (val) =>
        //     val?.type !== "disclaimer" &&
        //     val?.type !== "total" &&
        //     val?.type !== "title"
        // );
        // console.log(row, "afbsjkfjabsf");
        return (
          <>
            {(row[0].field == "Grand Total" ||
              row[0].title2 == "Grand Total") && (
              <TableRow
                style={{
                  borderTop: "2px solid #E9ECFB",
                  height: "20px",
                  background: "#ffffff",
                }}
              ></TableRow>
            )}
            <TableRow
              style={{
                borderBottom: hideUnderText ? "2px solid #E9ECFB" : "",
                border:
                  row[0].field == "Grand Total" ||
                  row[0].title2 == "Grand Total"
                    ? "1px solid #CFD4F5"
                    : " ",
                borderRadius: "10px",
              }}
            >
              {row?.map((val, i) =>
                handleBodyCell(
                  val,
                  i,
                  val?.colspan || colSpan,
                  hideTitle,
                  j,
                  row[0].field == "Grand Total" ||
                    row[0].title2 == "Grand Total"
                    ? "Grand Total"
                    : row[0].field == "Total" || row[0].subTotal == "Sum Total"
                    ? "Total"
                    : false
                )
              )}
            </TableRow>
            {!hideUnderText && (
              <TableRow>
                <TableCell
                  colSpan={row?.length}
                  style={{
                    borderLeft: `2px solid ${row[0]?.color}`,
                    padding: "0px",
                  }}
                  className="underTextCellContainer"
                >
                  <div className={"underTextCell"}>
                    <UpLineSVG
                      width="34px"
                      height="34px"
                      className="underTextIcon"
                    />
                    <p className="gloabalFont underText">
                      As On {dateFormatCommon(updatedAsOn)}
                    </p>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </>
        );
      });
    } else {
      return (
        <></>
        // <TableRow
        //   style={{
        //     height: "100px",
        //     borderBottom: hideUnderText ? "2px solid #E9ECFB" : "",
        //   }}
        // >
        //   <TableCell colSpan={header?.length} className="noDataContainer">
        //     <div className="noData">
        //       <p className="gloabalFont noDataTextx">Sorry No Data Found</p>
        //     </div>
        //   </TableCell>
        // </TableRow>
      );
    }
  };

  return (
    <div className="tableContainer">
      {tableTitle && <p className="gloabalFont tableTitle">{tableTitle}</p>}
      <Table className={`printTable  ${customTableClass}`}>
        <TableHead className="headerRow">
          <TableRow>{tableHeaders(header ? header : stHeaderData)}</TableRow>
        </TableHead>
        {/* {data?.length > 0 && ( */}
        <TableBody ref={customRef}>
          {/* <TableRow style={{ height: "10px" }} /> */}
          {tableBody(data ? data : [])}
        </TableBody>
        {/* )} */}
      </Table>
      {eoTable && eoTable}
    </div>
  );
}

export default PrintTable;
