import React from "react";
import { useStyles } from "./CardStyles";
import { Box, Typography } from "@material-ui/core";
export const CardInfo = ({ text, value, icon }) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={1}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className={classes.gapBetweenIconAndText}
      >
        {icon}
        <Typography className={classes.secondRowCardText}>{text}</Typography>
      </Box>
      <Box>
        <Typography className={classes.secondRowCardValue}>{value}</Typography>
      </Box>
    </Box>
  );
};
