import { Menu, MenuItem, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Back } from "../../../../assets/holding-summary/back-blue.svg";
import SearchInput from "../common/SearchInput";
import { useNavigate } from "react-router-dom";
import useStyles from "../common/ProductsStyles";
import TabComponent from "../common/TabComponent";
import { getCashFlow, getCashFundLowList } from "../../../../api";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import { useContext } from "react";
import SearchDebouncedInput from "../common/SearchDebouncedInput";
import PageLoader from "../common/pageLoader";
import NewTabComp from "../common/NewTabComp";
import TabBondSub from "./TabBondSub";
import { OnboardingContext } from "../../../../Context";
import BondsCashflowChart from "../../../common/BondsCashflowChart";

function BondsCashFlow({ subHeading, backUrl, entity, assetClasses }) {
  const classes = useStyles();
  const [list, setList] = useState([]);
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const [loading, setLoading] = useState(false);
  const [toggle, settoggle] = useState(true);
  const { userState } = OnboardingContext();

  const callGetCashFlow = async () => {
    setLoading(true);

    let params = { ...getfilter_as_params() };

    if (params?.startDate) {
      delete params?.startDate;
    }
    if (params?.endDate) {
      delete params?.endDate;
    }

    try {
      let payload = {
        ...params,
        clientId: userState?.loginedUserData?.id,
        assetClass: assetClasses,
      };

      let res = await getCashFlow(entity, payload);

      setList(() => res?.data?.data?.result);
      setLoading(false);
    } catch (error) {
      setList(() => []);
      setLoading(false);
    }
  };

  useEffect(() => {
    callGetCashFlow();
  }, [mfoFilter]);

  return (
    <>
      <TabBondSub
        title="Cash Flow"
        toggle={toggle}
        // settoggle={settoggle}
      ></TabBondSub>
      {list?.map((row, i) => {
        return (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "8px 0px 0px 45px",
                padding: "10px 10px 10px 10px",
                width: "fit-content",
                borderTopRightRadius: "10px",
                borderTopLeftRadius: "10px",
                background: "rgb(43, 38, 76)",
              }}
            >
              <Typography
                style={{ cursor: "pointer" }}
                className={classes.titleText}
              >
                {row?.bondName}
              </Typography>
            </div>
            <BondsCashflowChart data={row?.cashflows} />
          </>
        );
      })}

      <PageLoader showLoader={loading} />
    </>
  );
}

export default BondsCashFlow;
