import React from "react";
import NewDialaugeComp from "../../newClientList/NewDialogComp";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import PartnerOnboardingForm1 from "../../NewSignUp/PartnerOnboardingForm1";
import PartnerOnboardingForm2 from "../../NewSignUp/PartnerOnboardingForm2";
import ConfirmationComp from "../../NewSignUp/clientSignUp/ConfirmationComp";
import RepresentativeAddComp from "../../NewSignUp/common/RepresentativeAddComp";
import PartnerOnboarding1 from "../../NewSignUp/PartnerOnboarding1";
import SuccessPage from "../../NewSignUp/SuccessPage";
import { customHeadForPartner, customHeadForRep } from "./constands";
import usePrtnerOnboardingFLow from "../../NewSignUp/hooks/usePrtnerOnboardingFLow";
import RepresentativeConfirm from "../../NewSignUp/common/RepresentativeConfirm";

function PartnerAsistentJourney({ openModal, setOpenModal }) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const gap = "16px";
  const step = 8;
  const [next, setNext] = useState(state?.step ? state?.step : 1); //state?.step ? state?.step :
  const [formData, setFormData] = useState(
    state?.formData ? state?.formData : { representative: {} }
  );
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const total = 7;
  const [next2, setNext2] = useState(0); //starts form 0 only state?.step ? state?.step :

  const handleBack = (step) => {
    if (step >= 1) {
      setNext(step);
      handleNext(-1);
    } else {
      setOpenModal(false);
    }
    setApiErrorMessage("");
  };

  const handleNext = (stepAddition, formData1) => {
    setNext(next + stepAddition);
    navigate("/partner", {
      state: {
        step: next + stepAddition,
        formData: formData1 ? formData1 : formData,
        openModal: true,
      },
    });
    setApiErrorMessage("");
  };

  //   const api1 = (data) => {
  //     console.log("asif b");
  //     handleNext(1, data);
  //   };

  const { api1, api2, api5 } = usePrtnerOnboardingFLow({
    setFormData,
    setLoading,
    setApiErrorMessage,
    handleNext,
    formData,
  });

  const hanldeStep2 = (data) => {
    setFormData((prev) => ({ ...prev, ...data }));
    handleNext(1, data);
  };

  const hanldeStep6 = (data) => {
    setFormData((prev) => ({ ...prev, ...data }));
    handleNext(1, data);
  };

  const hadnleStep1 = (value) => {
    // console.log(value, "aglknanglanglkj");
    if (value?.entityType === "individual") {
      try {
        // api2(value).then((res) => {
        //   console.log(res, "alkangkbj");
        //   setNext2(() => {
        //     setFormData((perv) => ({ ...perv, ...value }));
        //     // api2(value);
        //     return 1;
        //   });
        // });
        let newValue = {
          entityName: value?.entityName,
          entityType: value?.entityType,
        };

        api2(undefined, undefined, newValue).then(() => {
          setNext2(() => {
            setFormData((perv) => ({ ...perv, ...value }));
            // api2(value);
            return 1;
          });
        });
      } catch (err) {
        console.log(err, "alkangkbj");
      }
    } else {
      // if (value?.panNumber !== "") {
      //   api1(value);
      // } else {
      // setFormData(() => ({ ...data }));
      setFormData((prev) => {
        handleNext(1, { ...prev, ...value });
        return { ...prev, ...value };
      });
      // }
    }
  };

  const hadnleStep5 = (value) => {
    // console.log(value, "aglknanglanglkj");
    // if (value?.panNumber !== "") {
    //   api1(value, true);
    // } else {
    setFormData((prev) => {
      handleNext(1, { ...prev, ...value });
      return { ...prev, representative: { ...value } };
    });
    // }
  };

  console.log(state, "kjbfagjkagjkkjg");

  const chagneInStep = (next) => {
    console.log(next, "aglagj");
    switch (next) {
      case 1:
        return (
          <PartnerOnboardingForm1
            currentStep={next}
            apiErrorMessage={apiErrorMessage}
            customHandleSubmit={hadnleStep1}
            formData={formData}
            handleBack={handleBack}
            handleNext={handleNext}
            loading={loading}
            noBottomStep={true}
            formMaxHeight="60vh"
            // gap={gap}
            total={total}
            stepDeviation={formData?.entityType === "individual" ? 3 : 0}
          />
        );
      case 2:
        return (
          <PartnerOnboardingForm2
            handleBack={handleBack}
            currentStep={next}
            customHandleSubmit={hanldeStep2}
            formMaxHeight="65vh"
            formData={formData}
            apiErrorMessage={apiErrorMessage}
            handleNext={handleNext}
            loading={loading}
            noBottomStep={true}
            gap={gap}
          />
        );
      case 3:
        return (
          <ConfirmationComp
            customHandleAccept={() => {
              api2(formData);
            }}
            currentStep={next}
            handleBack={handleBack}
            apiErrorMessage={apiErrorMessage}
            head={customHeadForPartner}
          />
        );
      case 4:
        return <RepresentativeAddComp handleNext={setNext} />;
      case 5:
        return (
          <PartnerOnboardingForm1
            onlyPerosn={true}
            showMobileField={true}
            currentStep={next}
            head={{
              main: "Add Representative Details",
              sub: " ",
              desc: {
                center: [
                  {
                    title: "What’s a Representative?",
                    description:
                      "Holdings are the contents of an investment portfolio held by an individual or an entity, such as a mutual fund or a pension fund. Portfolio holdings may encompass a wide  ",
                  },
                ],
              },
            }}
            handleBack={(step) => {
              handleBack(step);
              if (formData?.entityType === "individual") {
                setNext2(1);
              }
            }}
            handleNext={setNext}
            customHandleSubmit={(values) => {
              hadnleStep5(values, true);
            }}
            apiErrorMessage={apiErrorMessage}
            loading={loading}
            formData={formData?.representative}
            noBottomStep={true}
            formMaxHeight="60vh"
            gap={gap}
            stepDeviation={formData?.entityType === "individual" ? 3 : 0}
            entityName={formData?.entityName}
            entityType={formData?.entityType}
            hide={["entityType"]}
          />
        );
      case 6:
        return (
          <PartnerOnboardingForm2
            currentStep={next}
            handleBack={handleBack}
            handleNext={setNext}
            head={{
              main:
                formData?.entityType == "individual"
                  ? "Partner & Representative Details"
                  : "Representative Details",
              sub: " ",
              desc: {
                center: [
                  {
                    title: "What’s a Partner?",
                    description:
                      "Holdings are the contents of an investment portfolio held by an individual or an entity, such as a mutual fund or a pension fund. Portfolio holdings may encompass a wide ",
                  },
                ],
              },
            }}
            customHandleSubmit={(values) => {
              api5(values);
            }}
            formData={formData?.representative}
            apiErrorMessage={apiErrorMessage}
            loading={loading}
            formMaxHeight="65vh"
            noBottomStep={true}
            gap={gap}
            partnerData={formData}
            stepDeviation={formData?.entityType === "individual" ? 3 : 0}
          />
        );
      //   case 7:
      //     return (
      //       <ConfirmationComp
      //         customHandleAccept={() => {
      //           api1();
      //         }}
      //         currentStep={next}
      //         handleBack={handleBack}
      //         apiErrorMessage={apiErrorMessage}
      //         head={customHeadForRep}
      //       />
      //     );
      case 7:
        return (
          <SuccessPage
            handleBack={handleBack}
            subData="Congratulation your documents have been successfully submitted. An email has been sent to your registered Email Id Please Check"
            buttonTxt="Done"
            currentStep={next}
            noStep={true}
            handleNext={() => {
              setOpenModal(false);
              setFormData({});
              //   setNext(step);
              setNext(1);
              navigate("/partner", {
                state: {
                  step: 1,
                  formData: {},
                  openModal: false,
                },
              });
            }}
            gap={"16rem"}
          />
        );
      default:
        return null;
    }
  };

  // ## New Individual Flow starts from here ##
  const newIndividualFlow = (step) => {
    // console.log("I am here 1");
    switch (step) {
      case 1: {
        return (
          <RepresentativeAddComp
            customBack={() => {
              setFormData((prev) => {
                setNext(() => 1);
                setNext2(0);
                return { ...prev, entityType: "" };
              });
            }}
            handleNext={() => {
              setNext2(2);
              setNext(5);
            }}
          />
        );
      }

      // default: {
      //   return navigate("/");
      // }
    }
  };
  // ## New Individual Flow ends from here ##

  return (
    <NewDialaugeComp
      width={
        (formData?.entityType !== "individual" && next == 2) || next == 6
          ? "md"
          : "sm"
      }
      open={openModal}
      handleClose={() => {
        setOpenModal(false);
        setNext(1);
        setFormData(() => ({
          representative: {},
        }));
        navigate("/partner", {
          state: {
            step: 1,
            formData: { representative: {} },
            openModal: false,
          },
        });
      }}
      handleAccidentalClose={() => {
        setOpenModal(false);
        navigate("/partner", {
          state: {
            step: next,
            formData: formData,
            openModal: false,
          },
        });
      }}
    >
      {next2 == 1 && formData?.entityType === "individual"
        ? newIndividualFlow(next2)
        : chagneInStep(next)}
    </NewDialaugeComp>
  );
}

export default PartnerAsistentJourney;
