import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Login from "../../assets/partner-images/login.png";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import * as yup from "yup";
import { useFormik } from "formik";
import FlashMessage from "react-flash-message";

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    color: "#ec1010",
    marginTop: "10px",
    textAlign: "center",
  },
  onBoardingHeading: {
    fontWeight: "800",
    fontSize: "20px",
    lineHeight: "30px",
    textAlign: "center",
    color: "#FFFFFF",
    marginTop: "10px",
    marginBottom: "15px",
  },
  text: {
    fontStyle: "normal",
    fontFamily: "Avenir",
  },
  success: {
    fontWeight: "800",
    fontSize: "24px",
    lineHeight: "30px",
    textAlign: "center",
    color: "#FFFFFF",
  },
  successText: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    textAlign: "center",
    color: "#FFFFFF",
    marginTop: "15px",
    marginBottom: "15px",
  },

  line: {
    opacity: "0.2",
    backgroundColor: "#D4BBFB",
    border: ".5px solid #D4BBFB",
  },
  buttonContainer: {
    textAlign: "center",
    marginTop: "5px",
    marginBottom: "10px",
  },
  button: {
    paddingTop: "10px",
    width: "70%",
    color: "rgb(255, 255, 255)",
    background:
      "linear-gradient(rgb(170, 111, 254) 0%, rgb(113, 28, 241) 100%)",
    borderRadius: "20px",
    paddingBottom: "10px",
  },
  buttonText: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#FFFFFF",
  },
  image: {
    background: "#D4BBFB",
    padding: "1%",
    width: "40px",
    borderRadius: "50%",
  },
}));

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Enter valid email")
    .required("Email ID is required"),
});

function ForgotPasswordRequest({ setFormData, showErrorMessage, errorMsg }) {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setFormData(values);
    },
  });
  function extractErrorMessage(errorString) {
    const prefix = "Bad request:";
    if (errorString?.includes(prefix)) {
      return errorString.split(prefix)[1].trim();
    }
    return errorString;
  }

  return (
    <>
      <Typography className={`${classes.text} ${classes.onBoardingHeading}`}>
        Forgot Password
      </Typography>
      <div style={{ marginTop: "10px" }}>
        <hr className={classes.line}></hr>
        <div style={{ marginTop: "50px" }}>
          <div style={{ textAlign: "center" }}>
            <img className={classes.image} src={Login} alt="Login" />
          </div>
          <Typography className={`${classes.text} ${classes.success}`}>
            Forgot Password ?
          </Typography>
          <Typography className={`${classes.text} ${classes.successText}`}>
            No Worries,we'll send you reset instructions
          </Typography>

          <form onSubmit={formik.handleSubmit}>
            <div style={{ margin: "4% 4% 15% 10%" }}>
              <Grid container>
                <Grid className={classes.inputGrid} item xs={12}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    name="email"
                    className={classes.inputField}
                    label="Email id"
                    variant="outlined"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
              </Grid>
              {showErrorMessage && (
                <div className={classes.errorMessage}>
                  <FlashMessage duration={3000}>
                    <strong>{extractErrorMessage(errorMsg)}</strong>
                  </FlashMessage>
                </div>
              )}
            </div>

            <div className={classes.buttonContainer}>
              <Button
                type="submit"
                variant="contained"
                className={classes.button}
              >
                <span className={`${classes.text} ${classes.buttonText}`}>
                  Reset Password
                </span>
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ForgotPasswordRequest;
