import React from "react";
import Default from "../components/dashboard/defaultPages/Default";
import StrategyBuilderTable from "../components/dashboard/strategyBuilder/StrategyBuilderTable"; 

function StrategyBuilder() {
  return (
    <Default>
      <StrategyBuilderTable />
    </Default>
  );
}

export default StrategyBuilder;
