import React, { useEffect } from "react";
import useStyles from "../common/ProductsStyles";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Modal,
  Button,
} from "@material-ui/core";
import { useState } from "react";
import _ from "lodash";
import CheckBoxWithColor from "../common/CheckBoxWithColor";
import PmsReportTable from "./PmsReportTable";

function PmsHoldingAlternate({
  mfoFilter,
  openModal,
  setOpenModal,
  getTabelData,
  tableConfig,
  objectFormating,
  setTableHeader,
  tableHeader,
  setTableColumns,
  tableColumns,
  loading,
  pageCount,
  setOffset,
  pageNumber,
  setSecondTableColumns,
  tableColumns2,
}) {
  console.log(openModal);
  //   const [tableColumns, setTableColumns] = useState([]);
  //   const [loading, setLoading] = useState(false);
  const [reset, setReset] = useState();

  //   useEffect(() => {
  //     getTabelData(
  //       setTableColumns,
  //       "summary/holding",
  //       {
  //         clientId: 4,
  //         assetClass: "alternate-assets",
  //       },
  //       setLoading,
  //       objectFormating,
  //       tableHeader
  //     );
  //   }, [mfoFilter, reset]);

  return (
    <PmsReportTable
      productType="pmsHoldingAlternate"
      setTableColumns={setTableColumns}
      tableColumns={tableColumns}
      setSecondTableColumns={setSecondTableColumns}
      tableColumns2={tableColumns2}
      setOpenModal={setOpenModal}
      openModal={openModal}
      tableName={"Alternates"}
      loading={loading}
      pageName="holding"
      headerList={tableConfig}
      tableHeader={tableHeader}
      setTableHeader={setTableHeader}
      stickyProductName={true}
      setReset={setReset}
      pageCount={pageCount}
      setItemOffset={setOffset}
      pageNumber={pageNumber}
    />
  );
}

export default PmsHoldingAlternate;
