import { Box, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "./ProductUploadValidation.css";
import { useNavigate } from "react-router-dom";
import { validationList } from "./productValidationDummyData";
import {
  transactionFileValidationList,
  transactionValidationDetails,
} from "../../api";
import PageLoader from "../dashboard/products/common/pageLoader";
import { ReactComponent as ViewDetailsIcon } from "../../assets/icons/viewDetailsIcon.svg";
// Completed --> No ERROR
// Duplicates --> Show ERROR AND BTN
// Rejected --> Show ERROR

export const ProductUploadValidation = () => {
  const navigate = useNavigate();
  const [validationList, setValidationList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [errorState, setErrorState] = useState(null);
  useEffect(() => {
    setShowLoader(true);
    setErrorState(null); // Reset error state

    transactionFileValidationList("transaction")
      .then((res) => {
        const updatedValidationList = res?.data?.data?.validationList.map(
          (item) => {
            const formattedFileName = item.file_name
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ");

            return { ...item, file_name: formattedFileName };
          }
        );

        setValidationList(updatedValidationList);
        setShowLoader(false);
      })
      .catch((err) => {
        console.error("An Error has occurred", err);
        setErrorState(err);
        setShowLoader(false);
      });
  }, []);

  const handleTransactionValidationDetails = (transactionId, navigate) => {
    setShowLoader(true);
    setErrorState(null);

    transactionValidationDetails("transaction", transactionId)
      .then((res) => {
        let tempData = res?.data?.data;
        if (tempData?.error_list?.transaction_number) {
          let db_duplicates = [];
          let in_sheet_duplicates = [];
          let error_list = tempData?.error_list;
          db_duplicates = error_list?.transaction_number?.db_duplicates;
          in_sheet_duplicates =
            error_list?.transaction_number?.in_sheet_duplicates;

          let finalTransactionNumberErrors = [];
          if (in_sheet_duplicates) {
            finalTransactionNumberErrors =
              db_duplicates?.concat(in_sheet_duplicates);
          } else {
            finalTransactionNumberErrors = db_duplicates;
          }
          error_list = {
            ...error_list,
            transaction_number: finalTransactionNumberErrors,
          };
          tempData = {
            ...tempData,
            error_list: error_list,
          };
        }
        navigate("/product-upload/validation-details", {
          state: { data: tempData },
        });
        setShowLoader(false);
      })
      .catch((err) => {
        console.error(`An Error Has Occurred`, err);
        setErrorState(err);
        setShowLoader(false);
      });
  };

  return (
    <>
      <PageLoader showLoader={showLoader ? true : false} />
      <Grid
        container
        alignItems="center"
        className="centerContainer"
        style={{ maxWidth: "90%" }}
      >
        <Grid item xs={12}>
          <Typography
            // className="mainHeading"
            style={{
              color: "#FFF",
              fontFamily: "Avenir",
              fontSize: "18px !important",
              fontStyle: "normal",
              fontWeight: 800,
              lineHeight: "22px",
              marginTop: "1rem",
            }}
          >
            Validation CheckList
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <p className="subHeading">
            The User Management screen provides administrators with a
            comprehensive view and control over user accounts and their
            associated settings.
          </p>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        style={{ maxWidth: "90%", margin: "0px auto", padding: "0px 1rem" }}
      >
        <Grid item xs={3}>
          <Typography className="headerTxt">File Name</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className="headerTxt">Upload Date</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className="headerTxt">No. Of Errors</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography className="headerTxt">File Type</Typography>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
      <div
        style={{
          width: "100%",
          height: "60vh",
          overflow: "scroll",
        }}
      >
        {validationList?.map((data, index) => (
          <Grid
            key={index}
            container
            alignItems="center"
            style={{ maxWidth: "90%" }}
            // className={data?.isError ? "mainContainer" : "mainContainerInactive"}
            className={
              data?.status != "completed"
                ? "mainContainer"
                : "mainContainerInactive"
            }
          >
            <Grid item xs={3}>
              <Typography
                className={`gridItemTxt ${
                  data?.status != "completed" ? "" : "inactive"
                }`}
              >
                {/* Direct Equity - Holding Summary */}
                {data?.file_name}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className={`gridItemTxt ${
                  data?.status != "completed" ? "" : "inactive"
                }`}
              >
                {data?.upload_date}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className={`gridItemTxt ${
                  data?.status != "completed" ? "" : "inactive"
                }`}
              >
                {data?.no_of_errors}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography
                className={`gridItemTxt ${
                  data?.status != "completed" ? "" : "inactive"
                }`}
              >
                {data?.file_type ? data?.file_type : "-"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {data?.status != "completed" ? (
                <Box
                  display="flex"
                  alignItems="center"
                  style={{ gap: "0.75rem" }}
                >
                  <p className="activeTxt">View Details</p>
                  <ViewDetailsIcon
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleTransactionValidationDetails(data?._id, navigate)
                    }
                  />
                </Box>
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  style={{ gap: "0.75rem" }}
                >
                  <p className="activeTxt inactive">No errors found</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      opacity="0.2"
                      d="M24 12C24 15.1826 22.7358 18.2349 20.4853 20.4853C18.2349 22.7357 15.1827 24 12.0001 24C8.81748 24 5.76526 22.7357 3.51482 20.4853C1.26438 18.2349 1.00239e-06 15.1826 0 12C0 8.8174 1.26438 5.76516 3.51482 3.51472C5.76526 1.26428 8.81748 0 12.0001 0C15.1827 0 18.2349 1.26428 20.4853 3.51472C22.7358 5.76516 24 8.8174 24 12Z"
                      fill="white"
                    />
                    <path
                      d="M16.6399 9.76956L10.6399 14.7699C10.4481 14.9304 10.2031 15.0132 9.95327 15.002C9.70342 14.9908 9.46694 14.8864 9.29028 14.7094L7.29013 12.7093C7.1039 12.5206 6.99986 12.2658 7.00074 12.0007C7.00162 11.7355 7.10738 11.4815 7.29486 11.294C7.48235 11.1065 7.7363 11.0008 8.00145 10.9999C8.26659 10.9991 8.52138 11.1031 8.71011 11.2893L10.0601 12.6492L15.3602 8.22941C15.4609 8.14252 15.5779 8.07658 15.7045 8.03543C15.831 7.99429 15.9644 7.97875 16.097 7.98976C16.2295 8.00076 16.3586 8.03808 16.4766 8.09952C16.5945 8.16096 16.6991 8.24529 16.7842 8.34759C16.8692 8.4499 16.933 8.56813 16.9718 8.69536C17.0107 8.8226 17.0237 8.9563 17.0102 9.08865C16.9968 9.221 16.9572 9.34935 16.8936 9.4662C16.83 9.58304 16.7437 9.68642 16.6399 9.76956Z"
                      fill="white"
                    />
                  </svg>
                </Box>
              )}
            </Grid>
          </Grid>
        ))}
      </div>
    </>
  );
};
