import React from "react";
import "../pag1.css";
import NewLogo from "../../../../assets/icons/newNeoLogo.png";
export default function PrintHeader({ pdfInfo, headerText }) {
  return (
    <div className="print_header p_flex">
      <div
        className="footer_title p_flex a_cen "
        style={{ paddingLeft: "66px" }}
      >
        {" "}
        <div
          className="f10cen"
          style={{ paddingRight: "27px", color: "#2E314A" }}
        >
          {/* <h3 style={{ color: "black", fontSize: "10px" }}>
            {" "}
            {pdfInfo?.client}
          </h3> */}
          <div className="logoContainer">
            <div className="footerIconWrapper">
              <img src={NewLogo} alt="logo" style={{ height: "40px" }}></img>
            </div>
            <h3
              style={{
                marginTop: "5px",
                color: "black",
                fontSize: "10px",
                marginLeft: "-2px ",
              }}
            >
              {" "}
              Do Good
            </h3>
          </div>
        </div>
      </div>
      <div className="pageNumber p_flex center_full">
        {/* <div className="s_text">Page</div> */}
        <div>
          <h3 style={{ color: "black", fontSize: "16px" }}>
            {/* {pdfInfo?.startDate ? pdfInfo?.startDate : "As on date "} -{" "}
            {pdfInfo?.endDate} */}
            {headerText || " *all values are in ₹ "}
          </h3>
        </div>
      </div>
    </div>
  );
}
