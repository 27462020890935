import React, { useEffect, useState } from "react";
import PageContainer from "../PageContainer";
import Header from "../Components/Header";
import PrintTable from "../Components/Table";
import ApexNew_print from "../Components/ApexNew_print";
import chartData from "../../../charts/chartData";
import { ReactComponent as Question } from "../images/question.svg";
import PieChart from "../../../charts/PieChart";
import Print_PieChart from "../Components/Print_PieChart";
import TabCards from "../Components/TabCards";
import { roundNumber } from "../../../utils/common";
import { date } from "yup/lib/locale";
import { currencyFormatPrint as currencyFormat } from "../../../../hooks/constantFunction";

import {
  objectFormatingProductCommonChartWithTitleCOl,
  objectFormatingWithTitleCOlWrapper,
} from "../Components/utils/common";
import { isArray } from "lodash";
import { MarketCapModel } from "../Components/utils/staticData";

export default function MarketCap({
  data,
  denomination,
  title,
  headerComp,
  pdfInfo,
  mappedKey,
}) {
  const getDenomination = localStorage.getItem("denomination");
  // console.log(
  //   data?.[mappedKey.key1]?.[mappedKey.key1 + "Valuation"],
  //   "top 5 amc"
  // );

  const colors = ["#152687", "#4BB3ED", "#4B64ED", "#BDC5F2", "#D094FF"];
  const [colormapped, setColorMapped] = useState({});
  const [total, setTotal] = useState();
  let amc = objectFormatingWithTitleCOlWrapper(
    {
      data: data || [],
    },
    MarketCapModel,
    [],
    "#ADC371",
    undefined,
    // totaRowData ? totaRowData : hideTotalRow ? undefined : total,
    undefined,
    {},
    denomination,
    "type",
    "MarketCap",
    objectFormatingProductCommonChartWithTitleCOl
  );

  const [data1, setData1] = useState([]);
  useEffect(() => {
    let piedata = [];
    data?.map((a, i) => {
      if (a?.["type"] == "total") {
        colormapped[a?.["type"]] = "#ffffff";
        a.portfolioValueAmountPercentage = a?.totalPortfolioPercentage;

        setTotal(a);
        return;
      }
      colormapped[a?.["type"]] = colors[i];

      piedata.push({
        id: i,
        label: a?.type,
        value: roundNumber(a?.portfolioValueAmountPercentage),
        val: [
          roundNumber(data?.portfolioValueAmountt),
          roundNumber(data?.portfolioValueAmount),
          roundNumber(data?.portfolioValueAmountPercentage),
        ],

        color: colors[i],
      });
    });

    setData1(piedata);
    setColorMapped({ ...colormapped });
  }, [data]);
  if (isArray(data) && data?.length == 0) {
    return <></>;
  }

  return (
    <div>
      {data1?.length > 0 && (
        <PageContainer
          layout_t={4}
          pageNo={2}
          bgColor={"#ffffff"}
          pdfInfo={pdfInfo}
        >
          {headerComp && headerComp}
          {!headerComp && (
            <Header
              title={<>{title}</>}
              type={12}
              denomination={denomination}
              hideHr={true}
              greyText={" "}
              subtext={`Engagement Model segregates the Client portfolio into Held & Held Away. Where in Held refers to the Investments Directly managed by us & Held Away refers to the investments managed by other Advisors. `}
              width={"5in"}
            ></Header>
          )}
          <div className="p_flex">
            <div style={{ height: "9in" }}>
              {/* cards */}

              {/* {data1?.map((a, i) => {
            return (
              <TabCards
                data={a}
                text={["Invested Value", "Current Value", "Percentage"]}
                type={["rs", "rs", "percent"]}
                color={colors[i]}
                getkey={i}
                denomination={denomination}
              ></TabCards>
            );
          })} */}
              <div>
                <div className="flex_center" style={{ position: "relative" }}>
                  <div style={{ width: "695px", height: "690px" }}>
                    <div
                      className="p_circle"
                      style={{ transform: "translate(110%, 46%)" }}
                    >
                      <div>
                        <div className="">CURRENT VALUE</div>
                        <div>
                          {currencyFormat(
                            total?.portfolioValueAmount,
                            undefined,
                            denomination
                          )}
                        </div>
                      </div>
                    </div>
                    {data1 && (
                      <Print_PieChart
                        data={data1}
                        config={{ colors: colors }}
                      />
                    )}
                    {/* <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      marginTop: "-18%",
                      marginBottom: "3%",
                      marginRight: "3%",
                    }}
                  ></div> */}
                  </div>
                </div>
              </div>
            </div>
            <div
              // Value
              className=" p_flex a_cen"
              style={{
                height: "879px",
                background: "none",
                marginTop: "-0.5in",
              }}
            >
              {" "}
              <PrintTable
                // customRef={pRef2}
                // colors={colors}
                colormapping={colormapped}
                customTableClass={"printTableEvenAndOddRow"}
                hideTitle={true}
                data={amc || []}
                header={MarketCapModel}
                hideUnderText={true}
                customPadding={"2.2px 16px"}
                firstCellCircleProps={{
                  height: "8px",
                  width: "4.5px",
                }}
                titleCellProps={{
                  firstCellStyle: { padding: "10px 0px 10px 0px" },
                  style: {
                    background: "#F3F8FD",
                    padding: "0px 16px 0px 9px",
                    minHeight: "47px",
                  },
                  font: {
                    fontWeight: "900",
                    fontSize: "16px",
                  },
                }}
                normalCellProps={{
                  firstCellPadding: "10px 0px 10px 0px",
                  fontStyle: { fontWeight: "600", fontSize: "14px" },
                }}
                headerProps={{
                  className: "whiteHeaderCell",
                  titleClassName: "whiteTitle",
                  titleStyle: {
                    fontWeight: "500",
                    fontFamily: "AvenirBold",
                    fontSize: "14px",
                    lineHeight: "18px",
                    letterSpacing: "0.06em",
                    textTransform: "uppercase",
                    color: "#000000",
                  },
                }}
                colSpan={1}
              />
            </div>
          </div>
          {/* <CardsWrapper /> */}
          {/* <Hr title={"Portfolio Performance"} /> */}
          {/* <div className="h_card" style={{ height: "448px" }}> */}
          {/* <PrintTable /> */}
          {/* </div> */}
        </PageContainer>
      )}
    </div>
  );
}

// {data1?.map((m, i) => {
//   return (
//     <div style={{ display: "flex", marginLeft: "30px" }}>
//       <div className="p_flex a_cen">
//         <svg height="23" width="25">
//           <circle
//             cx="12"
//             cy="12"
//             r="8"
//             stroke-width="3"
//             fill={m.color}
//           />
//           Sorry, your browser does not support inline SVG.
//         </svg>
//       </div>
//       <div className={` p_flex a_cen `}>
//         {m.label}
//         {/* <div className="boldText">Percentage</div>
//     <div className="boldText2">{m.val[2] + " %"}</div> */}
//       </div>
//     </div>
//   );
// })}
