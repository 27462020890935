import { Grid } from "@material-ui/core";
import React from "react";
import { useStyles } from "./HoldingSummaryCards/CardStyles";

export default function Sectionhr({ name }) {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12}>
        <section
          id="title-wrapper"
          style={{ marginTop: "54px", marginBottom: "-22px" }}
        >
          <div class="title" className={classes.dividerText}>
            NAME
          </div>
          <div class="dividerLine"></div>
        </section>
      </Grid>
    </>
  );
}
