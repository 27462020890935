import React from "react";
import { useEffect } from "react";
import { fetchRepresetOption } from "../api";
import { useState } from "react";

function useGetRepResentative({ search, parnerId, entity, offset }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  const getData = async ({ search, parnerId, entity }) => {
    console.log(entity, "asglnagjk");
    setLoading(true);
    let param = {
      limit: 5,
      entityType:
        entity == "partner" ? "representative" : "banker" || "representative",
      search: search || "",
      offset:offset
    };
    try {
      let res = await fetchRepresetOption(parnerId, param);
      console.log(res?.data?.data?.meta?.count, "aglnj");
      setTotal(res?.data?.data?.meta?.count);
      setData(() => res?.data?.data?.result);
      setLoading(false);
    } catch (error) {
      console.log(error, "aglnj");
      setLoading(false);
    }
  };

  useEffect(() => {
    getData({ search, parnerId, entity });
  }, [search, entity, offset]);

  return { getData, loading, data, total };
}

export default useGetRepResentative;
