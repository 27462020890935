import React from "react";
import { ReactComponent as Crown } from "../../../assets/icons/crown.svg";

function CrownHead({ memberName, width, height, left }) {
  function initials(memberName) {
    if (!memberName) {
      return;
    }
    let memberNameArr = memberName?.split(" ");
    // if no surname is provided
    if (memberNameArr.length == 1) {
      let firstNameInitial = memberNameArr[0][0];
      return firstNameInitial;
    } else {
      let firstNameInitial = memberNameArr[0][0];
      let lastNameInitial = memberNameArr[1][0];
      return firstNameInitial + lastNameInitial;
    }
  }

  return (
    <div
      className="mrbtm10 bigcrownallmember "
      style={{
        position: "relative",
        width: "4rem",
        marginLeft: "2px",
      }}
    >
      <Crown style={{ left: left ? left : "30%" }} className="yellowCrown" />
      <div
        className="big-avatar-design"
        style={{
          position: "absolute",
          width: width ? width : "57px",
          height: height ? height : "57px",
          top: "20%",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "6px solid",
          background: "#4E189F",
          boxShadow: "inset -1.67229px 1.67229px 8.36143px rgba(0, 0, 0, 0.5)",
        }}
      >
        {" "}
        <span style={{ fontSize: "18px" }}>
          {memberName && initials(memberName)}
        </span>
      </div>
    </div>
  );
}

export default CrownHead;
