import { Avatar } from "@material-ui/core";
import React from "react";
import useStyles from "./Clientdashstyle";

import { ReactComponent as Userpic } from "../../../assets/clientdashboard/user.svg";

import { ReactComponent as Expand } from "../../../assets/icons/expand.svg";
//import { useAllMembersConsolidated } from "../../../hooks/useAllMembersConsolidated";
import moment from "moment";
import PageLoader from "../products/common/pageLoader";
import { useEffect } from "react";
import { FamilyTreeButton } from "./FamilyTreeButton";
import { OnboardingContext } from "../../../Context";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { firstLetterCapitalize } from "../../utils/strHelper";
import { useAllMembersConsolidatedList } from "../../../hooks/useAllMembersConsolidatedList";
function MemberProfile({
  setMemberName,
  crownComponent,
  familyHead: familyHeadAvatar,
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    userState: { loginedUserData },
  } = OnboardingContext();

  let currentDate = new Date()?.toISOString()?.slice(0, 10);
  const date = moment(currentDate).format("DD-MMM-YYYY");
  const formattedDate = date;
  const [endDate, setEndDate] = React.useState(formattedDate);
  const [headOfFamily, loading] = useAllMembersConsolidatedList(
    "consolidated",
    endDate
  );
  // To get the clicked memberName from /client/members
  const clickedMemberName = localStorage && localStorage.getItem("memberName");
  const familyHead = headOfFamily?.find((item) => item.isFamilyHead === true);
  const totalFamilyMembers = headOfFamily?.length;
  const membersArray = headOfFamily?.filter(
    (item) => item.isFamilyHead === false
  );

  // const { memberName, totalFamilyMembers, memberType } = headOfFamily || {};
  // const { members: membersArray } = headOfFamily || [];
  useEffect(() => {
    setMemberName(familyHead?.memberName);
  }, [familyHead?.memberName]);
  function initials(memberName) {
    if (!memberName) {
      return;
    }
    let memberNameArr = memberName?.split(" ");
    // if no surname is provided
    if (memberNameArr.length == 1) {
      let firstNameInitial = memberNameArr[0][0];
      return firstNameInitial;
    } else {
      let firstNameInitial = memberNameArr[0][0];
      let lastNameInitial = memberNameArr[1][0];
      return firstNameInitial + lastNameInitial;
    }
  }
  function strFormatter(str) {
    let upperCaseString = str
      ?.split("_")
      ?.map((name) => name)
      ?.join(" ");

    return str?.includes("_")
      ? firstLetterCapitalize(upperCaseString)
      : firstLetterCapitalize(str);
  }
  return (
    <div className={classes.rightsection}>
      <PageLoader showLoader={loading} />

      <div
        className={classes.avatar}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <Userpic></Userpic> */}
        <Avatar
          className={classes.memavatar}
          style={{
            margin: "0px",
            width: "100px",
            height: "100px",
            borderRadius: "50%",
            fontSize: "2rem",
          }}
        >
          {initials(loginedUserData?.name)}
        </Avatar>

        <h5 className={classes.colorfff}>
          {clickedMemberName ? clickedMemberName : loginedUserData?.name}
        </h5>
        <small className={classes.colorfff}>Account Type</small>
        <p>
          <button className={classes.famhead}>
            {/* {loginedUserData?.client_role} */}
            {strFormatter(loginedUserData?.client_role)}
          </button>
        </p>
        {/* <hr className={classes.hrcolor}></hr> */}
      </div>
      <hr className={classes.hrcolor}></hr>

      <div
        className={classes.membersection}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <FamilyTreeButton style={{ marginTop: "15px" }} />
        <div>
          {totalFamilyMembers != undefined && (
            <p className="totalmems">
              <small className={classes.colorfff}>
                {totalFamilyMembers > 1
                  ? `${totalFamilyMembers} Members`
                  : `${totalFamilyMembers} Member`}
              </small>
              {/* <small className={classes.memnos}>
                {totalFamilyMembers > 1
                  ? `${totalFamilyMembers} Members`
                  : `${totalFamilyMembers} Member`}
              </small> */}
            </p>
          )}
        </div>

        <div
          className={classes.allmems}
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "200px",
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "space-between",
            marginTop: "10px",
            // border: "2px solid yellow",
          }}
        >
          <Avatar className={classes.memavatar}>
            {initials(familyHeadAvatar)}
          </Avatar>
          {membersArray?.map((member, index) => (
            <Avatar className={classes.memavatar}>
              {/* {member?.memberName?.charAt(0)} */}
              {initials(member?.memberName)}
            </Avatar>
          ))}
        </div>
        <div>
          <button
            className="expand textgradientpurple"
            style={{ width: "100%", cursor: "pointer" }}
            onClick={() => navigate("/home/member/detail")}
          >
            <Expand /> Expand
          </button>
        </div>
      </div>
    </div>
  );
}

export default MemberProfile;
