import React, {
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
  useMemo,
} from "react";
import PageContainer from "../PageContainer";
import Header from "./Header";
import PrintTable from "./Table";
import "./Table.css";
import {
  bodyDataFormatWithProd,
  dataFormatWrapper,
  objectFormatingWithTitleCOlWrapper,
} from "./utils/common";

import {
  stBodyEntityData,
  stbodyData,
  stHeaderData,
  sdTotalData,
  headerData,
} from "./utils/staticData";
import Print_PieChart from "./Print_PieChart";
import { currencyFormat } from "../../../../hooks/constantFunction";

function HandlePageBreakChart({
  customData,
  loading,
  title,
  headermodel,

  setChanges,
  piedata,
  pdfInfo,
  colors,
  colormapped,
  denomination,
  total,
  customHeader,
  footerLegendText,
  eoTable,
  asOnDate,
  TableContainerStyle = {},
}) {
  const pRef = useRef(null);

  const containerRef = useRef(null);
  const [newPage1, setNewPage] = useState([]);
  const [newPage3, setNewPage3] = useState([]);

  useEffect(() => {
    setNewPage(() => {
      setNewPage3([]);

      return customData;
    });
  }, [customData]);

  const handleRowSeperation = () => {
    if (pRef.current?.children) {
      let arr = [];
      // let maxHeight = containerRef?.current?.offsetHeight - 380;
      // let currentHeight = 0;
      let count = 0;
      Array.from(pRef.current?.children)?.map((row, i) => {
        // if (currentHeight <= maxHeight) {
        //   console.log(
        //     currentHeight,
        //     row?.offsetHeight,
        //     currentHeight + row?.offsetHeight,
        //     maxHeight,
        //     "page props"
        //   );
        //   currentHeight = currentHeight + row?.offsetHeight;
        // }
        // if (currentHeight > maxHeight) {
        //   arr.push(i);
        //   currentHeight = -row?.offsetHeight;
        // }
        if (count > 7) {
          arr.push(i);
          count = 1;
        }
        count = count + 1;
      });
      return arr;
    }
  };

  useEffect(() => {
    if (newPage1.length > 0) {
      if (
        pRef &&
        pRef?.current?.offsetHeight > containerRef?.current?.offsetHeight - 880
      ) {
        let sepIndexes = handleRowSeperation();

        let newPages2 = [];

        newPage1.map((page) => {
          sepIndexes?.map((seps, i) => {
            if (i == 0) {
              let a = page.slice(0, seps);
              if (a.length > 0) {
                newPages2.push(a);
              }
              if (i == sepIndexes?.length - 1) {
                let a = page.slice(seps, page?.length);
                if (a.length > 0) {
                  newPages2.push(a);
                }
              }
              return;
            } else {
              let a = page.slice(sepIndexes[`${i - 1}`], seps);
              if (a.length > 0) {
                newPages2.push(a);
              }
              if (i == sepIndexes?.length - 1) {
                let a = page.slice(seps, page?.length);
                if (a.length > 0) {
                  newPages2.push(a);
                }
              }
            }
          });
        });
        // console.log(newPages2, "newpages2");

        setNewPage3(newPages2);
        if (setChanges) setChanges(newPages2);
      }
    }
  }, [newPage1]);

  return (
    <>
      {!loading
        ? newPage3 && newPage3.length > 0
          ? newPage3?.map((val1) => (
              <PageContainer
                layout_t={1}
                pageNo={2}
                bgColor={"white"}
                pdfInfo={pdfInfo}
              >
                {customHeader ? (
                  customHeader
                ) : (
                  <Header
                    title={<>{title}</>}
                    type={9}
                    subtext={`Engagement Model segregates the Client portfolio into Held & Held Away. Where in Held refers to the Investments Directly managed by us & Held Away refers to the investments managed by other Advisors. `}
                    width={"5in"}
                  ></Header>
                )}
                <div className="p_flex" style={{ alignItems: "center" }}>
                  <div
                    className="p_flex"
                    style={{
                      height: "9in",
                      width: "100%",
                      marginTop: "-1.1in",
                      ...TableContainerStyle,
                    }}
                  >
                    <div
                      className="flex_center"
                      style={{ marginTop: "0%", position: "relative" }}
                    >
                      <div style={{ width: "695px", height: "690px" }}>
                        <div
                          className="p_circle"
                          style={{ transform: "translate(110%, 1.95in)" }}
                        >
                          <div>
                            <div className="">Current Value</div>
                            <div>
                              {currencyFormat(total, undefined, denomination)}
                            </div>
                          </div>
                        </div>
                        {piedata && (
                          <Print_PieChart
                            data={piedata}
                            config={{ colors: colors }}
                          />
                        )}
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            marginTop: "-18%",
                            marginBottom: "3%",
                            marginRight: "3%",
                          }}
                        >
                          {
                            // piedata?.map((m, i) => {
                            //   return (
                            //     <div style={{ display: "flex", marginLeft: "30px" }}>
                            //       <div className="p_flex a_cen">
                            //         <svg height="23" width="25">
                            //           <circle
                            //             cx="12"
                            //             cy="12"
                            //             r="8"
                            //             stroke-width="3"
                            //             fill={m.color}
                            //           />
                            //           Sorry, your browser does not support inline SVG.
                            //         </svg>
                            //       </div>
                            //       <div className={`uppercase p_flex a_cen`}>
                            //         {m.id}
                            //         {/* <div className="boldText">Percentage</div>
                            //         <div className="boldText2">{m.val[2] + " %"}</div> */}
                            //       </div>
                            //     </div>
                            //   );
                            // })
                          }
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        // position: "absolute",
                        zIndex: "5",
                        width: "100%",
                        // top: "7in",

                        // marginRight: "66px",
                      }}
                    >
                      <PrintTable
                        customRef={pRef}
                        colormapping={colormapped}
                        customTableClass={"printTableEvenAndOddRow"}
                        hideTitle={true}
                        data={val1 || []}
                        header={headermodel}
                        hideUnderText={true}
                        customPadding={"2.2px 16px"}
                        firstCellCircleProps={{
                          height: "8px",
                          width: "4.5px",
                        }}
                        titleCellProps={{
                          firstCellStyle: { padding: "10px 0px 10px 0px" },
                          style: {
                            background: "#F3F8FD",
                            padding: "0px 16px 0px 9px",
                            minHeight: "47px",
                          },
                          font: {
                            fontWeight: "900",
                            fontSize: "16px",
                          },
                        }}
                        normalCellProps={{
                          firstCellPadding: "10px 0px 10px 0px",
                          fontStyle: { fontWeight: "600", fontSize: "14px" },
                        }}
                        headerProps={{
                          className: "whiteHeaderCell",
                          titleClassName: "whiteTitle",
                          titleStyle: {
                            fontWeight: "500",
                            fontFamily: "AvenirBold",
                            fontSize: "14px",
                            lineHeight: "18px",
                            letterSpacing: "0.06em",
                            textTransform: "uppercase",
                            color: "#000000",
                          },
                        }}
                        colSpan={1}
                      />
                    </div>
                  </div>
                </div>
              </PageContainer>
            ))
          : newPage1 && newPage1.length > 0
          ? newPage1?.map((val1) => (
              <PageContainer
                layout_t={1}
                pageNo={2}
                bgColor={"white"}
                pdfInfo={pdfInfo}
                customRef={containerRef}
              >
                {customHeader ? (
                  customHeader
                ) : (
                  <Header
                    title={<>{title}</>}
                    type={9}
                    subtext={`Engagement Model segregates the Client portfolio into Held & Held Away. Where in Held refers to the Investments Directly managed by us & Held Away refers to the investments managed by other Advisors. `}
                    width={"5in"}
                  ></Header>
                )}
                <div className="p_flex" style={{ alignItems: "center" }}>
                  <div
                    className="p_flex"
                    style={{
                      height: "9in",
                      width: "100%",
                      marginTop: "-1.1in",
                      ...TableContainerStyle,
                    }}
                  >
                    <div
                      className="flex_center"
                      style={{ marginTop: "0%", position: "relative" }}
                    >
                      <div style={{ width: "695px", height: "690px" }}>
                        <div
                          className="p_circle"
                          style={{ transform: "translate(110%, 1.95in)" }}
                        >
                          <div>
                            <div className="">Current Value</div>
                            <div>
                              {currencyFormat(total, undefined, denomination)}
                            </div>
                          </div>
                        </div>
                        {piedata && (
                          <Print_PieChart
                            data={piedata}
                            config={{ colors: colors }}
                          />
                        )}
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            marginTop: "-18%",
                            marginBottom: "3%",
                            marginRight: "3%",
                          }}
                        >
                          {
                            // piedata?.map((m, i) => {
                            //   return (
                            //     <div style={{ display: "flex", marginLeft: "30px" }}>
                            //       <div className="p_flex a_cen">
                            //         <svg height="23" width="25">
                            //           <circle
                            //             cx="12"
                            //             cy="12"
                            //             r="8"
                            //             stroke-width="3"
                            //             fill={m.color}
                            //           />
                            //           Sorry, your browser does not support inline SVG.
                            //         </svg>
                            //       </div>
                            //       <div className={`uppercase p_flex a_cen`}>
                            //         {m.id}
                            //         {/* <div className="boldText">Percentage</div>
                            //         <div className="boldText2">{m.val[2] + " %"}</div> */}
                            //       </div>
                            //     </div>
                            //   );
                            // })
                          }
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        // position: "absolute",
                        zIndex: "5",
                        width: "100%",
                        // top: "7in",

                        // marginRight: "66px",
                      }}
                    >
                      <PrintTable
                        // customRef={pRef2}
                        customRef={pRef}
                        colormapping={colormapped}
                        customTableClass={"printTableEvenAndOddRow"}
                        hideTitle={true}
                        data={val1 || []}
                        header={headermodel}
                        hideUnderText={true}
                        customPadding={"2.2px 16px"}
                        firstCellCircleProps={{
                          height: "8px",
                          width: "4.5px",
                        }}
                        titleCellProps={{
                          firstCellStyle: { padding: "10px 0px 10px 0px" },
                          style: {
                            background: "#F3F8FD",
                            padding: "0px 16px 0px 9px",
                            minHeight: "47px",
                          },
                          font: {
                            fontWeight: "900",
                            fontSize: "16px",
                          },
                        }}
                        normalCellProps={{
                          firstCellPadding: "10px 0px 10px 0px",
                          fontStyle: { fontWeight: "600", fontSize: "14px" },
                        }}
                        headerProps={{
                          className: "whiteHeaderCell",
                          titleClassName: "whiteTitle",
                          titleStyle: {
                            fontWeight: "500",
                            fontFamily: "AvenirBold",
                            fontSize: "14px",
                            lineHeight: "18px",
                            letterSpacing: "0.06em",
                            textTransform: "uppercase",
                            color: "#000000",
                          },
                        }}
                        colSpan={1}
                      />
                    </div>
                  </div>
                </div>
              </PageContainer>
            ))
          : " "
        : " "}
    </>
  );
}

export default HandlePageBreakChart;
