import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { useDebounce } from "use-debounce";

export default function CustomInput(props) {
  const {
    title,
    label,
    errorBorder,
    pr,
    style,
    handleChange,
    portFolioName,
    customStyle,
    ...newPorops
  } = props;
  const [inputVal, setInputVal] = useState("");
  const [debouncedValue] = useDebounce(inputVal, 1000);

  const useStyles = makeStyles({
    root: {
      "& label.Mui-focused": {
        color: "transparent",
      },
      "& .MuiInputBase-root": {
        borderRadius: "8px !important",
        border: newPorops?.error
          ? "2px solid red !important"
          : "2px solid #3E5389 !important",
        color: " var(--selectedText)",
        transition: "border-color 0.2s ease-in-out",
        paddingRight: pr ? pr : "0px",
        ...style,
      },
      "& .MuiInputBase-root .MuiSelect-select ": {
        minHeight: "auto",
      },
      "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiInputBase-root .MuiInputBase-input": {
        padding: "14px 14px",
        fontSize: "14px",
      },
      "& .MuiFormHelperText-root": {
        fontSize: "12px",
      },
    },
  });
  const classes = useStyles();

  const handleInputChange = (e) => {
    setInputVal(e.target.value);
  };

  useEffect(() => {
    if (portFolioName) {
      // console.log(portFolioName, "agkbjk");
      setInputVal(() => portFolioName);
    }
  }, [portFolioName]);

  useEffect(() => {
    if (handleChange) handleChange(debouncedValue);
  }, [debouncedValue]);

  return (
    <>
      {title && (
        <InputLabel
          title={title}
          className="loginPageLabel"
          style={{
            letterSpacing: "0.16em",
            textTransform: "uppercase",
            paddingLeft: "10px",
            fontWeight: "500",
            color: "white",
          }}
        >
          {title}
        </InputLabel>
      )}
      <TextField
        {...newPorops}
        classes={{
          root: classes.root,
        }}
        onChange={handleInputChange}
        placeholder={label}
        value={inputVal}
        // InputProps={{ sx: { marginTop: "0px" } }}
        style={{
          "& legend": { display: "none" },
          "& fieldset": { top: 0 },
          mt: "4px",
          mb: "18px",
          // ...style,
        }}
      />
    </>
  );
}
