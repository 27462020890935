import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Login from "../../assets/partner-images/login.png";
import { Button, Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import FlashMessage from "react-flash-message";

const useStyles = makeStyles((theme) => ({
  successMessage: {
    color: "green",
    marginTop: "10px",
    textAlign: "center",
  },
  onBoardingHeading: {
    fontWeight: "800",
    fontSize: "20px",
    lineHeight: "30px",
    textAlign: "center",
    color: "#FFFFFF",
    marginTop: "10px",
    marginBottom: "15px",
  },
  text: {
    fontStyle: "normal",
    fontFamily: "Avenir",
  },
  success: {
    fontWeight: "800",
    fontSize: "20px",
    lineHeight: "30px",
    textAlign: "center",
    color: "#FFFFFF",
  },
  successText: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    textAlign: "center",
    color: "#FFFFFF",
    marginTop: "15px",
    marginBottom: "15px",
  },

  line: {
    opacity: "0.2",
    backgroundColor: "#D4BBFB",
    border: ".5px solid #D4BBFB",
  },
  buttonContainer: {
    textAlign: "center",
    marginTop: "5px",
    marginBottom: "10px",
  },
  button: {
    paddingTop: "10px",
    width: "70%",
    color: "rgb(255, 255, 255)",
    background:
      "linear-gradient(rgb(170, 111, 254) 0%, rgb(113, 28, 241) 100%)",
    borderRadius: "20px",
    paddingBottom: "10px",
  },
  buttonText: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#FFFFFF",
  },
  image: {
    background: "#D4BBFB",
    padding: "1%",
    width: "40px",
    borderRadius: "50%",
  },
}));

function ForgotPasswordResponse({ resendPin, formData, showSuccessMessage }) {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  return (
    <>
      <Typography className={`${classes.text} ${classes.onBoardingHeading}`}>
        Forgot Password
      </Typography>
      <div style={{ marginTop: "10px" }}>
        <hr className={classes.line}></hr>
        <div style={{ marginTop: "50px", marginBottom: "50px" }}>
          {showSuccessMessage && (
            <div className={classes.successMessage}>
              <FlashMessage duration={3000}>
                <strong>Email sent successfully!!</strong>
              </FlashMessage>
            </div>
          )}
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <img className={classes.image} src={Login} alt="Login" />
          </div>
          <Typography className={`${classes.text} ${classes.success}`}>
            Check Your Email{" "}
          </Typography>
          <Typography className={`${classes.text} ${classes.successText}`}>
            We sent a password reset link to <br />
            {formData?.email}.
          </Typography>

          <div className={classes.buttonContainer}>
            <Button
              type="submit"
              variant="contained"
              className={classes.button}
              onClick={handleClick}
            >
              <span className={`${classes.text} ${classes.buttonText}`}>
                Go to login page
              </span>
            </Button>
          </div>

          <Typography
            style={{ textAlign: "center", marginTop: "20px", color: "#9488A7" }}
            className={classes.buttonText}
          >
            Didn’t receive the email?
            <span
              onClick={resendPin}
              style={{
                textDecoration: "none",
                color: "#efab30",
                cursor: "pointer",
              }}
            >
              {" "}
              Click to resend{" "}
            </span>
          </Typography>
        </div>
      </div>
    </>
  );
}

export default ForgotPasswordResponse;
