import React, { useEffect, useState } from "react";
import EquityRevenue from "./EquityRevenue";
import EquityTotalAsset from "./EquityTotalAsset";
import EquityProfit from "./EquityProfit";
import TabIPODetails from "./TabIPODetails";
export default function EquityTabReport({ pageName, setgraphtype }) {
  const [page, setPage] = useState("revenue");

  const changePage = (p) => {
    switch (p) {
      case "revenue":
        if (setgraphtype) {
          setgraphtype("revenue");
        }

        return <EquityRevenue />;
      case "totalassets":
        if (setgraphtype) {
          setgraphtype("totalassets");
        }

        return <EquityTotalAsset />;

      case "profit":
        if (setgraphtype) {
          setgraphtype("profit");
        }

        return <EquityProfit />;
      default:
        return <EquityRevenue></EquityRevenue>;
    }
  };

  return (
    <div>
      <TabIPODetails page={page} setPage={setPage}></TabIPODetails>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {" "}
        {changePage(page)}{" "}
      </div>
    </div>
  );
}
