import React, { useCallback, useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  Chip,
  CircularProgress,
  InputLabel,
  Paper,
  Tooltip,
  styles,
} from "@material-ui/core";
import { myDebounce } from "../../../hooks/constantFunction";

const useStyles = makeStyles(() => ({
  inputField: {
    borderRadius: "4px",

    width: "100%",
    "& .MuiOutlinedInput-root": {
      color: "red",
      borderRadius: "6px !important",
      border: "1.4px solid #541689 !important",
      background: "#000 !important",
    },
  },
  inputField2: {
    borderRadius: "4px",
    color: "#9488a7",
    width: "100%",
    "& .MuiOutlinedInput-root": {
      color: "red",
      borderRadius: "6px !important",
      border: "1.4px solid var(--border) !important",

      background: "#1f1c40 ",
    },
  },
  icon: {
    fill: "#FAF9FF",
  },
  paper: {
    // borderRadius: "4px !important",
    // border: "1.164px solid #461277 !important",
    background: "#1E0D2D !important",
    marginTop: "1rem !important",
  },
  listbox: {
    maxHeight: "172px !important",
    background: "#1E0D2D !important",
    padding: "0px !important",
    borderRadius: "4px !important",
    border: "1.164px solid #461277 !important",
  },
  option: {
    borderBottom: "0.5px solid #6a77ab !important",
    padding: "1rem !important",
  },
  input: {
    padding: "12px 14px !important",
  },
}));

const CustomPaper = (props) => {
  return (
    <Paper
      style={{
        color: "#9488A7",
        background: "#1E0D2D",
        border: "1px solid #4E189F",
        color: "#FFF",
        fontFamily: "Avenir",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 350,
        lineHeight: "22px",
      }}
      elevation={8}
      {...props}
    />
  );
};

function MultiSelect({
  data,
  single,
  getSearchData,
  errors,
  searchLoader,
  label,
  optionTitle,
  fieldName,
  objectFieldName,
  handleChange,
  formData,
  values,
  setValues,
  touch,
  styles = {},
  multiple,
  disable,
  disableClassName,
  placeholder,
  client,
}) {
  const classes = useStyles();
  const [toggle, settoggle] = useState(false);

  const handleInputChange = (value, client) => {
    //if (value) {
    getSearchData(value, client);
    //}
  };
  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8Z"
        stroke="#654E87"
      />
    </svg>
  );
  const checked_icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
        fill="#55A47E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.0234 9.15765L11.3372 4.93849C11.4664 4.83678 11.6228 4.77552 11.7868 4.76242C11.9507 4.74932 12.1149 4.78495 12.2586 4.86484C12.4024 4.94473 12.5193 5.06531 12.5948 5.21145C12.6702 5.35759 12.7008 5.52276 12.6826 5.68622C12.6704 5.79502 12.6368 5.90033 12.5837 5.99607C12.5306 6.09182 12.4591 6.17611 12.3732 6.24409L6.33749 11.0352L6.27889 11.0821C6.25474 11.099 6.2297 11.1146 6.20388 11.1289L6.17107 11.15C5.98052 11.2485 5.75933 11.2695 5.55364 11.2088C5.34794 11.1481 5.17362 11.0103 5.06705 10.8242L4.9733 10.6672L3.42393 7.97394C3.3696 7.87944 3.33441 7.77517 3.32038 7.66707C3.30635 7.55897 3.31375 7.44917 3.34215 7.34393C3.37056 7.2387 3.41941 7.14008 3.48593 7.05373C3.55244 6.96737 3.63532 6.89496 3.72982 6.84063C3.92067 6.73091 4.14729 6.70149 4.35982 6.75886C4.46506 6.78726 4.56367 6.83612 4.65003 6.90263C4.73639 6.96915 4.8088 7.05202 4.86313 7.14652L6.02574 9.15765H6.0234Z"
        fill="white"
      />
    </svg>
  );

  const myDebounceSearch = useCallback(myDebounce(handleInputChange), []);

  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: "#1E0D2D",
    },
    tooltip: {
      backgroundColor: "#1E0D2D",
    },
  }));

  function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
  }

  return (
    // <BootstrapTooltip title={values.map((v) => `${v.full_name}`).toString()}>
    <>
      <Autocomplete
        freeSolo={true}
        id="checkboxes-tags-demo"
        disableCloseOnSelect
        disabled={disable}
        multiple={single ? false : true}
        PaperComponent={CustomPaper}
        classes={{
          paper: classes.paper,
          listbox: classes.listbox,
          option: classes.option,
          input: disableClassName || classes.input,
        }}
        style={{ opacity: values.length < 1 ? 0.5 : 1, ...styles }}
        options={data}
        getOptionLabel={(option) => option?.[optionTitle]}
        // renderTags={(value, getTagProps) => (
        //   <div className={"hide"}>{value[0].full_name}</div>
        // )}
        renderTags={(value, getTagProps) => (
          <div style={{ margin: "10px", display: "flex", flexWrap: "wrap" }}>
            {value?.map((option, index) => (
              // <Chip
              //   variant="outlined"
              //   label={option.full_name}
              //   {...getTagProps({ index })}
              // ></Chip>
              <div style={{ color: "#ffffff" }} {...getTagProps({ index })}>
                {"  " + option?.[optionTitle] + ","}
              </div>
            ))}
          </div>
        )}
        value={values}
        // onChange={(e, newValue) =>
        //   handleChange(
        //     fieldName,
        //     newValue === null ? "" : newValue[objectFieldName]
        //   )
        // }

        onChange={(event, newValue, reason, details) => {
          // console.log(reason, details, newValue, "reason");
          // if (reason !== "remove-option") {
          //   console.log("entering back space");
          let noupdate;
          let indx = " ";
          let exit = newValue.find((obj, i) => {
            if ((obj[objectFieldName] || obj?.id) == "all") {
              if (toggle == false) {
                settoggle(true);
                return true;
              } else {
                settoggle(false);
                indx = i;
              }
            }
          });
          if (indx !== " ") {
            newValue.splice(indx, 1);
            indx = " ";
          }
          if (exit) {
            console.log(exit);
            let newvalues = [
              { [optionTitle]: "All", [objectFieldName]: "all" },
            ];
            setValues([{ [optionTitle]: "All", [objectFieldName]: "all" }]);
            handleChange(fieldName, newValue === null ? "" : newvalues, values);
            return;
          } else {
            noupdate = values.findIndex((v) => {
              if (
                v[objectFieldName] ==
                newValue[newValue.length - 1]?.[objectFieldName]
              ) {
                return true;
              }
            });
            // console.log(noupdate, "noupdate");
            if (noupdate != -1) {
              let filtered = newValue.filter((n) => {
                if (n[objectFieldName] != details.option[objectFieldName]) {
                  return true;
                }
              });
              setValues(filtered);
            } else {
              setValues(newValue);
            }
          }
          handleChange(fieldName, newValue === null ? "" : newValue, values);
        }}
        renderOption={(option, { selected }) => {
          let checked = selected;

          checked = values.find((v) => {
            if (v[objectFieldName] == option[objectFieldName]) {
              // console.log(
              //   v[objectFieldName],
              //   option[objectFieldName],
              //   "selected"
              // );
              return true;
            }
          });

          return (
            <li
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "100%",
                }}
              >
                {option?.[optionTitle]}
              </div>
              <div style={{ marginLeft: "auto", alignItems: "flex-end" }}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checked_icon}
                  style={{ marginRight: 8 }}
                  checked={checked ? true : false}

                  // onChange={(e) => {
                  //   console.log(e.target.checked, option[objectFieldName]);
                  //   if (!selected) {
                  //     console.log(selected, "selected");
                  //     let index = values.findIndex((val, i) => {
                  //       if (val[objectFieldName] == option[objectFieldName]) {
                  //         return i;
                  //       }
                  //     });
                  //     if (index != -1) {
                  //       console.log(index);
                  //       values.splice(index, 1);
                  //     }
                  //   }
                  // }}
                />
              </div>

              {/* {option.full_name} */}
            </li>
          );
        }}
        onInputChange={(e) => myDebounceSearch(e?.target?.value, client)}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              // style={{
              //   "& .MuiOutlinedInput-root.Mui-focused": {
              //     color: "red",
              //   },
              // }}
              className={disable ? classes.inputField2 : classes.inputField}
              // label={label}
              placeholder={placeholder || ""}
              name={fieldName}
              error={
                Boolean(errors?.[fieldName])
                  ? !values?.length > 0 && touch?.[fieldName]
                  : false
              }
              // helperText={errors?.[fieldName]}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {searchLoader ? (
                      <CircularProgress
                        style={{ color: "#FFFFFF" }}
                        size={20}
                      />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          </>
        )}
      />
    </>
    // </BootstrapTooltip>
  );
}

export default MultiSelect;
