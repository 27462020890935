import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from "react";
import useStyles from "../common/ProductsStyles";
import { Grid, Typography } from "@material-ui/core";
import { ReactComponent as Import } from "../../../../assets/icons/import.svg";
import { ReactComponent as Export } from "../../../../assets/icons/export.svg";
import { ReactComponent as Print } from "../../../../assets/icons/print.svg";
import { ReactComponent as EditColumn } from "../../../../assets/icons/edit-column.svg";
import TabPmsSubTransaction from "../common/TabPmsSubTransaction";
import PmsReportTable from "../pms/PmsReportTable";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import moment from "moment";
import { ReactComponent as Back } from "../../../../assets/holding-summary/back-blue.svg";
import { currencyFormat } from "../../../../hooks/constantFunction";
import PageLoader from "../common/pageLoader";
//need confirmatioon to remove these unused components,
//these components are replaced with PmsReportTable component
// import PmsTransactionAll from "./PmsTransactionAll";
// import PmsTransactionPurchase from "./PmsTransactionPurchase";
// import PmsTransactionRedemption from "./PmsTransactionRedemption";
import { dateFormatCommon, roundNumber } from "../../../utils/common";
import TabComponent from "../common/TabComponent";
import SearchInput from "../common/SearchInput";
import { useNavigate } from "react-router-dom";
import { useClientSearch } from "../../../../hooks/useClientSearch";
import NewtableTopSec from "../../common/NewtableTopSec";
import { ITEMS_LIMIT_FILES_PARTNER_LIST } from "../../../../Constants";
import TableSummaryDetails from "../newDesigns/common/newSummaryDetailsTable/TableSummaryDetails";
import { summaryDetailsSecConstData } from "../utils/constands/summaryDetailsSec";
import PieChart from "../../../charts/PieChart";
import { pie_onClick } from "../../../charts/nivo_objects";
import DataNotFound from "../common/DataNotFound";
import { bankbookConfig } from "../REITS/reitsConfig";
import { colors_allocation } from "../../../charts/colors";

const data = [
  { page: "equity", title: "Equity" },
  { page: "debt", title: "Debt" },
  { page: "alternate", title: "Alternate Assets" },
  { page: "cash", title: "Cash" },
];

function AllocationSummary({
  getTabelData,
  customTableConfig,
  subHeading,
  backUrl,
  showisin,
  headerInTwoLine,
  hideSearch,
  pageCount,
}) {
  const { getfilter_as_params } = useContext(mfoFilterContext);
  const [load, setLoad] = useState({ segmentreport: true });
  const classes = useStyles();
  let assetOrder = ["equity", "debt", "alternate", "cash"];
  let selected_asset = getfilter_as_params().assetClass.split(",");
  let defaultAsset = assetOrder.find((a) => {
    if (selected_asset.includes(a)) {
      return a;
    }
  });
  // console.log(selected_asset, "aglagljk");
  const [page, setPage] = useState(defaultAsset);
  const navigate = useNavigate();
  const [tableColumns, setTableColumns] = useState({
    tableColumns: [],
    pieData: [],
    total: "--",
  });
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { mfoFilter } = useContext(mfoFilterContext);
  const [reset, setReset] = useState();
  const tableConfig = customTableConfig
    ? customTableConfig
    : [
        {
          field: "scheme_name",
          title: "Product Name",
          show: true,
          align: "center",
        },
        { field: "advisor", title: "Advisor", show: true, align: "center" },
        { field: "isin", title: "Product Code", show: true, align: "center" },
        { field: "folio_no", title: "Folio No", show: true, align: "center" },
        // { field: "asset_type", title: "Asset Type", show: true },
        {
          field: "product_sub_category",
          title: "Product Sub Category",
          show: true,
          align: "center",
        },
        {
          field: "transaction_date",
          title: "Transaction date",
          show: true,
          align: "center",
        },
        {
          field: "transaction_type",
          title: "Transaction Type",
          show: true,
          align: "center",
        },
        {
          field: "holdingPeriod",
          title: "Holding Period",
          show: true,
        },
        { field: "amount", title: "Amount", show: true },
        { field: "load_charges", title: "Load Charged", show: true },
        { field: "dividend", title: "Dividend", show: true },
        { field: "interest", title: "Interest", show: true },

        { field: "other_income", title: "Other Income", show: true },
        { field: "management_fees", title: "Management Fees", show: true },
        {
          field: "securities_transaction_tax",
          title: "Security Transaction Tax",
          show: true,
        },
        { field: "other_expense", title: "Other Expense", show: true },
      ];
  const [tableHeader, setTableHeader] = useState(tableConfig);
  const [checkedTrans, setCheckedTrans] = useState([]);
  const [search, setSearch] = useState("");
  const [customData, setCustomData] = useState("");
  useMemo(() => {
    setCustomData(
      tableColumns?.tableColumns.filter((f) => {
        console.log(
          f[0].title?.toLowerCase().indexOf(search.toLowerCase()) > -1,
          "search"
        );
        if (
          search == "" ||
          !search ||
          (f[0].field?.toLowerCase().indexOf(search.toLowerCase()) > -1 &&
            f[0].title == "BANK NAME") ||
          (f[1].field?.toLowerCase().indexOf(search.toLowerCase()) > -1 &&
            f[1].title == "ACCOUNT NO")
        ) {
          return f;
        }
      }) || []
    );
  }, [search]);
  // const { searchResults } = useClientSearch(search, tableColumns);
  const [pagiNationData, setPageinationData] = useState({
    pageCount: null,
    // limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
  });
  const [offset, setOffset] = useState(0);
  const [piecolors, setPieColors] = useState(colors_allocation);
  const [pieData, setPieData] = useState([]);

  const [pieState, setPieState] = useState({
    title: "",
    value: "",
    colors: piecolors,
    disabled: [],
    hide: false,
  });

  const handleIconClick = () => {
    setOpenModal(!openModal);
  };

  const objectFormating = (data) => {
    let newData = tableHeader.map((header) => {
      if (header?.field == "transaction_date") {
        return {
          field: dateFormatCommon(new Date(data[`${header.field}`])),
          // moment(new Date(data[`${header.field}`])).format(
          //   "DD - MM - YYYY"
          // ) || "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (header?.field == "isin" || header?.field == "folio_no") {
        return {
          field: data[`${header.field}`],
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }

      if (
        header?.field == "amount" ||
        header?.field == "load_charges" ||
        header?.field == "dividend" ||
        header?.field == "interest" ||
        header?.field == "other_income" ||
        header?.field == "interestIncome" ||
        header?.field == "management_fees" ||
        header?.field == "securities_transaction_tax" ||
        header?.field == "other_expense" ||
        header?.field == "presentValue" ||
        header?.field == "investmentValue" ||
        header?.field == "totalTransactionValue"
      ) {
        return {
          field: currencyFormat(data[`${header.field}`] || 0) || "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (header?.field == "holdingPeriod") {
        return {
          field: data[`${header.field}`]
            ? data[`${header.field}`] + " days"
            : "--",
          // moment(new Date(data[`${header.field}`])).format(
          //   "DD - MM - YYYY"
          // ) || "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      return {
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`]))
          : data[`${header.field}`] || "--",
        title: header.title,
        show: header.show,
        align: header?.align,
      };
    });
    return newData;
  };

  console.log(tableColumns?.tableColumns, "table columns");
  useEffect(() => {
    if (tableHeader) {
      getTabelData(
        setTableColumns,
        "allocation-summary",
        {
          // schemeName: search,
        },
        setLoading,
        objectFormating,
        setCheckedTrans,
        tableHeader,
        ITEMS_LIMIT_FILES_PARTNER_LIST,
        offset,
        setPageinationData
      );
    }
  }, [page, mfoFilter, reset, offset]);

  return (
    <>
      <NewtableTopSec
        handleIconClick={handleIconClick}
        directPageName={"Allocation Summary"}
        // setSearch={!hideSearch && setSearch}
        hideTableEdit={true}
      />
      <Grid xs={12}>
        {tableColumns.length !== 0 ? (
          <div className="flex_center tr20 " style={{ overflow: "hidden" }}>
            <div style={{ width: "380px" }}>
              <PieChart
                config={{
                  innerRadius: "0.80",
                  cornerRadius: 0,
                  padAngle: 0,
                  legends: false,
                  disableArcLink: true,
                  minWith: "380px",
                  margin: { right: 80, top: 20, left: 80 },

                  chart: { width: 420, height: 340 },
                  arcLinkLabel: (d) => `${d.value} %`,
                  // disableArcLink:pieState.hide?true:false
                }}
                colors={pieState.colors}
                onClickEvent={{
                  click: pie_onClick,
                  set: setPieState,
                  cstate: pieState,
                  ncolors: piecolors,
                }}
                data={tableColumns.pieData}
              >
                <div
                  className="cen"
                  style={{
                    color: "#fff",
                    transform: "translate(69%, -47%)",
                    top: "37%",
                  }}
                >
                  <div className="center-info">
                    <div>
                      <div
                        className="glowcircle"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {/* <div className='circle_text2'>{pieState.value?pieState.value+" %":""}</div> */}
                        <div>
                          <div className="circle_text3">
                            {!pieState.title || pieState.title == ""
                              ? ""
                              : showisin
                              ? showisin
                              : "ACC NO"}
                          </div>
                          <div className="circle_text">
                            {!pieState.title || pieState.title == ""
                              ? ""
                              : pieState.title}
                          </div>
                          <div className="circle_text3">
                            {!pieState.title || pieState.title == ""
                              ? "Total Balance"
                              : "Balance"}
                          </div>

                          {/* pieState.title */}
                          {/* <div className="circle_text">
                            {!pieState.title || pieState.title == ""
                              ? "Total Balance"
                              : ""}
                          </div> */}
                          <div className="circle_text2">
                            {pieState.amount
                              ? "" + currencyFormat(pieState.amount)
                              : "" + tableColumns?.total}
                          </div>
                          {/* <div
                            className="circle_text2"
                            style={{ paddingTop: "5px" }}
                          >
                            {pieState?.value
                              ? roundNumber(pieState.value) + "%"
                              : ""}
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </PieChart>
            </div>
          </div>
        ) : load?.segmentreport === false ? (
          <DataNotFound></DataNotFound>
        ) : (
          <DataNotFound text=" "></DataNotFound>
        )}

        {/* <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            marginTop: "-5%",
            marginBottom: "3%",
          }}
        >
          {tableColumns.pieData?.map((m, i) => {
            return (
              <div style={{ display: "flex" }}>
                <div className={classes.graphBullets}>
                  <svg height="25" width="25">
                    <circle
                      cx="12"
                      cy="12"
                      r="5"
                      stroke-width="3"
                      fill={pieState.colors[i]}
                    />
                    Sorry, your browser does not support inline SVG.
                  </svg>
                </div>
                <div className={`${classes.graphLegends}`}>{m.id} </div>
              </div>
            );
          })}
        </div> */}
      </Grid>
      <div style={{ margin: "2%" }}>
        <TableSummaryDetails
          headerData={bankbookConfig}
          customHandleData={() => {
            if (search == "" || !search) {
              return tableColumns?.tableColumns;
            } else {
              return customData;
            }
          }}
          data={
            //   [
            //   {
            //     bank_name: "State Bank of India",
            //     account_no: "235780971",
            //     account_type: "current",
            //     balance: 3000,
            //     allocation_percentage: 60,
            //   },
            //   {
            //     bank_name: "State Bank of India",
            //     account_no: "235780971",
            //     account_type: "current",
            //     balance: 3000,
            //     allocation_percentage: 60,
            //   },
            // ]
            tableColumns?.tableColumns
          }
          // headerData={summaryDetailsSecConstData?.tableHeader}
          hideNavButtons={true}
          // customColorAndProd={customColorAndProd}
          // customProdAndKey={customProdAndKey}
        />
      </div>
      <PageLoader showLoader={loading} />
    </>
  );
}

export default AllocationSummary;
