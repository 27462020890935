import { purple } from "@material-ui/core/colors";

export const gradient = {
  orange: [
    { offset: 0, color: "#F7CB59" },
    { offset: 100, color: "#FF9446" },
  ],
  green: [
    { offset: 0, color: "#00C1CE" },
    { offset: 100, color: "#82EBA1" },
  ],
  blue: [
    { offset: 0, color: "#6BCDF7" },
    { offset: 100, color: "#509FF1" },
  ],
  purple: [
    { offset: 0, color: "#AA6FFE" },
    { offset: 100, color: "#711CF1" },
  ],
  pink: [
    { offset: 0, color: "#FE6587" },
    { offset: 100, color: "#F52D6A" },
  ],
  red: [
    { offset: 0, color: "#E96114" },
    { offset: 100, color: "#B80000" },
  ],
};
export const colors = {
  orange: "#FF9446",
  green: "#82EBA1",
  blue: "#509FF1",
  purple: "#711CF1",
  pink: "#F52D6A",
  red: "#B80000",
};
export const gradient_s8 = [
  {
    id: "c1", //orange
    type: "linearGradient",
    colors: [
      { offset: 0, color: "#F7CB59" },
      { offset: 100, color: "#FF9446" },
    ],
  },
  {
    id: "c2", //green
    type: "linearGradient",
    colors: [
      { offset: 0, color: "#00C1CE" },
      { offset: 100, color: "#82EBA1" },
    ],
  },
  {
    id: "c3", //blue
    type: "linearGradient",
    colors: [
      { offset: 0, color: "#6BCDF7" },
      { offset: 100, color: "#509FF1" },
    ],
  },
  {
    id: "c4", //purple
    type: "linearGradient",
    colors: [
      { offset: 0, color: "#AA6FFE" },
      { offset: 100, color: "#711CF1" },
    ],
  },
  {
    id: "c5", //pink
    type: "linearGradient",
    colors: [
      { offset: 0, color: "#FE6587" },
      { offset: 100, color: "#F52D6A" },
    ],
  },
];
export const colors_allocation = [
  "#0AB68B",
  "#92DE8B",
  "#FFE3B3",
  "#6B3E26",
  "#FFC5D9",
  "#C2F2D0",
  "#FDF5C9",
  "#FF6D00",
  "#FF9201",
  "#FFAB41",
  "#4C7972",
  "#915A3C",
  "#CFB79F",
  "#004581",
  "#018ABD",
  "#97CBDC",
  "#2D8D79",
  "#8FC79A",
  "#5E9387",
  "#CEE9B6",
  "#2E2F5B",
  "#505581",
  "#D47751",
  "#F3D1C3",
  "#D39E45",
  "#A09D52",
  "#326789",
  "#78A6C8",
  "#E65C4F",
  "#0B759D",
  "#1FBABF",
  "#60D3AA",
  "#9CEE8C",
  "#414016",
  "#6E7A31",
  "#A7B987",
  "#DEDAAD",
  "#E984A2",
  "#B9CC95",
  "#F8D49B",
  "#F8E6CB",
  "#8FA5B2",
  "#C4D0C6",
  "#006BB9",
  "#30A0E0",
  "#FFE3B3",
  "#7338AC",
  "#635AD9",
  "#219BE4",
  "#88F4FF",
  "#CD184F",
  "#E84476",
  "#F76CAF",
  "#FFE3B3",
  "#557C83",
  "#3AADA8",
  "#A2D5AC",
  "#E5EEC1",
  "#4D4141",
  "#EE534F",
  "#FD7D96",
  "#FFCDD2",
  "#742092",
  "#C858BA",
  "#FFC973",
  "#FFE3B3",
  "#A77A95",
  "#C3C3D5",
  "#F5D698",
  "#434738",
  "#B5D43B",
  "#D9EF82",
  "#3F7396",
  "#4F8EB5",
  "#77BDDF",
  "#A4E2F9",
  "#243D66",
  "#6E61CA",
  "#C282FA",
  "#C9B6D7",
  "#F6CADD",
  "#C5E0FB",
  "#AED2F9",
  "#5D7723",
  "#B3A943",
  "#FACA72",
  "#1795A8",
  "#24AFC1",
  "#FCCF47",
  "#3D7C98",
  "#73B0CD",
  "#A8DFF1",
  "#743799",
  "#E16BBA",
  "#FEBA7B",
  "#1D2538",
  "#475B74",
  "#7C8DA5",
  "#9AB89A",
  "#799779",
  "#475C46",
  "#C2528B",
  "#F380B9",
  "#5F50A9",
  "#9652C2",
  "#58626C",
  "#DAC8BE",
  "#ECC1A1",
  "#CCA152",
  "#9DB3BE",
  "#58626E",
  "#314657",
  "#898C6F",
  "#EBA91D",
  "#E8D743",
  "#FFA1B3",
  "#B0E1A2",
  "#CBF5AF",
  "#FAFFD6",
  "#383D1F",
  "#2B4625",
  "#929C3B",
  "#ABC337",
  "#74E7DA",
  "#189AB4",
  "#04445F",
  "#5B3D45",
  "#5B868D",
  "#98BFAA",
  "#C8D5CB",
  "#C16C49",
  "#BC8867",
  "#E7C3A8",
  "#D9E3DE",
  "#8F4F07",
  "#F3E4C7",
  "#E6EFF4",
  "#73BFF3",
  "#FF8D70",
  "#FCAA82",
  "#73A2AC",
  "#0B5D69",
  "#728C32",
  "#0567A6",
  "#60E3D5",
  "#F6F0F0",
  "#FFF3E0",
  "#BBEEF1",
  "#5FBEC4",
  "#394F89",
  "#4B5FC2",
  "#9DF6FC",
  "#490B3E",
  "#BC1E51",
  "#FAD1D7",
  "#FFAFB0",
  "#FAFFE1",
  "#FFEACB",
  "#FF9899",
  "#FEEAA5",
  "#4A686A",
  "#EAB875",
  "#E1C7A5",
  "#D3E3E2",
  "#0B698B",
  "#0396A6",
  "#9CD3D8",
  "#9A9CEA",
  "#A2B9EE",
  "#A2DCEE",
  "#ADEEE2",
  "#028174",
];
export const colors_s9 = [
  "#FF9446",
  "#00C1CE",
  "#509FF1",
  "#711CF1",
  "#F52D6A",
];
export const colors_s8 = [
  "#B80000",
  "#82EBA1",
  "#711CF1",
  "#3A56FF",
  "#FF9446",
];
export const colors_s7 = [
  "#3A56FF",
  "#B80000",
  "#82EBA1",
  "#711CF1",
  "#FF9446",
];
export const colors_equi = [
  "#B80000",
  "#35CBBF",
  "#0099FF",
  "#7B61FF",
  "#E1F5FD",
  "#711CF1",
  "#4E189F",
  "#5B0C81",
  "#220848",
  "#380D77",
  "#6BCDF7",
  "#FF9446",
  "#F52D6A",
];
export function pSBC(p, c0, c1, l) {
  let r,
    g,
    b,
    P,
    f,
    t,
    h,
    i = parseInt,
    m = Math.round,
    a = typeof c1 == "string";
  if (
    typeof p != "number" ||
    p < -1 ||
    p > 1 ||
    typeof c0 != "string" ||
    (c0[0] != "r" && c0[0] != "#") ||
    (c1 && !a)
  )
    return null;
  function pSBCr(d) {
    let n = d.length,
      x = {};
    if (n > 9) {
      [r, g, b, a] = d = d.split(",");
      n = d.length;
      if (n < 3 || n > 4) return null;
      x.r = i(r[3] == "a" ? r.slice(5) : r.slice(4));
      x.g = i(g);
      x.b = i(b);
      x.a = a ? parseFloat(a) : -1;
    } else {
      if (n == 8 || n == 6 || n < 4) return null;
      if (n < 6)
        d =
          "#" +
          d[1] +
          d[1] +
          d[2] +
          d[2] +
          d[3] +
          d[3] +
          (n > 4 ? d[4] + d[4] : "");
      d = i(d.slice(1), 16);
      if (n == 9 || n == 5) {
        x.r = (d >> 24) & 255;
        x.g = (d >> 16) & 255;
        x.b = (d >> 8) & 255;
        x.a = m((d & 255) / 0.255) / 1000;
      } else {
        x.r = d >> 16;
        x.g = (d >> 8) & 255;
        x.b = d & 255;
        x.a = -1;
      }
    }
    return x;
  }
  h = c0.length > 9;
  h = a ? (c1.length > 9 ? true : c1 == "c" ? !h : false) : h;
  f = pSBCr(c0);
  P = p < 0;
  t =
    c1 && c1 != "c"
      ? pSBCr(c1)
      : P
      ? { r: 0, g: 0, b: 0, a: -1 }
      : { r: 255, g: 255, b: 255, a: -1 };
  p = P ? p * -1 : p;
  P = 1 - p;
  if (!f || !t) return null;
  if (l) {
    r = m(P * f.r + p * t.r);
    g = m(P * f.g + p * t.g);
    b = m(P * f.b + p * t.b);
  } else {
    r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5);
    g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5);
    b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5);
  }
  a = f.a;
  t = t.a;
  f = a >= 0 || t >= 0;
  a = f ? (a < 0 ? t : t < 0 ? a : a * P + t * p) : 0;
  if (h)
    return (
      "rgb" +
      (f ? "a(" : "(") +
      r +
      "," +
      g +
      "," +
      b +
      (f ? "," + m(a * 1000) / 1000 : "") +
      ")"
    );
  else
    return (
      "#" +
      (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0))
        .toString(16)
        .slice(1, f ? undefined : -2)
    );
}
