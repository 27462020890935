import React, { useContext, useEffect, useState } from "react";
import "./topPerformer.css";
import { OnboardingContext } from "../../../../Context";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import "./PortfolioOverview.css";
import {
  benchMarkXIRR,
  fetchTwrrCurrentHolding,
  fetchXirr,
  getCurrentHoldingGainLoss,
} from "../../../../api";
import moment from "moment";
import { currencyFormat } from "../../../../hooks/constantFunction";
import PageLoader from "../common/pageLoader";
import ReplayIcon from "@material-ui/icons/Replay";
import { ReactComponent as ErrorBarIcon } from "../../../../assets/icons/ErrorBarIcon.svg";
import { CircularProgress } from "@material-ui/core";
import { roundNumber } from "../../../utils/common";

function FirstSection({ onUpdateDate, tableType, type }) {
  const { userState } = OnboardingContext();
  const [loadingXIRR, setLoadingXIRR] = useState(false);
  const [loadingTWRR, setLoadingTWRR] = useState(false);
  const { setMfofilter, mfoFilter, getfilter_as_params } =
    useContext(mfoFilterContext);
  const [data, setData] = useState({
    benchMarkXIRRData: null,
    currentHoldingTWRRData: null,
    currentHoldingGainLossData: null,
  });
  const [loading, setDataLoading] = useState(false);
  const [error, setError] = useState(false);

  const clientId = userState.loginedUserData.id;
  const assetClass = { assetClass: "equity,debt,cash,alternate" };

  let params = { ...getfilter_as_params() };
  useEffect(() => {
    // if (mfoFilter.startDate) {
    //   params["startDate"] = moment(mfoFilter.startDate).format("YYYY-MM-DD");
    //   params["endDate"] = moment(mfoFilter.endDate).format("YYYY-MM-DD");
    // }

    const fetchData = async () => {
      try {
        setLoadingXIRR(true);
        setLoadingTWRR(true);
        const [xirrResponse, xirr, gainLossResponse] = await Promise.allSettled(
          [
            benchMarkXIRR(clientId, assetClass, "current", params),
            fetchXirr(clientId, params, "dashboard"),
            getCurrentHoldingGainLoss(
              clientId,
              assetClass,
              "dashboard",
              "past",
              params
            ),
          ]
        );

        const resolvedXirr =
          xirrResponse.status === "fulfilled"
            ? xirrResponse?.value?.data?.data?.result
            : null;
        const resolvedGainLoss =
          gainLossResponse.status === "fulfilled"
            ? gainLossResponse?.value?.result
            : null;
        const resolvedxirrdash =
          xirr.status === "fulfilled" ? xirr?.value?.result?.xirrValue : null;

        setData({
          xirr: resolvedxirrdash,
          benchMarkXIRRData: resolvedXirr,
          currentHoldingGainLossData: resolvedGainLoss,
        });

        setLoadingXIRR(false);

        onUpdateDate(resolvedGainLoss?.updatedAsOn);

        if (
          xirrResponse.status === "fulfilled" &&
          gainLossResponse.status === "fulfilled"
        ) {
          setLoadingTWRR(true);
          const twrrResponse = await fetchTwrrCurrentHolding(
            clientId,
            assetClass,
            "dashboard",
            params
          );
          setData((prevData) => ({
            ...prevData,
            currentHoldingTWRRData: twrrResponse?.result,
          }));
          setLoadingTWRR(false);
        }
      } catch (error) {
        setLoadingXIRR(false);
        setLoadingTWRR(false);
        console.error("Error fetching data:", error.message);
        setError(true);
      }
    };

    fetchData();
  }, [mfoFilter]);

  const handleRetry = () => {
    setError(false);
    setDataLoading(true);
    fetchTwrrCurrentHolding(clientId, assetClass, "dashboard", params)
      .then((response) => {
        setData({
          ...data,
          currentHoldingTWRRData: response.data.result,
        });
        setDataLoading(false);
      })
      .catch((error) => {
        setDataLoading(false);
        console.error("Error retrying:", error.message);
      });
  };

  return (
    <>
      <div className="">
        {console.log(data, "data2")}
        <h2 className="headerContent">Portfolio Overview</h2>
        <div className="firstBoxContainer">
          <div className="Box">
            <p className="para-text">CURRENT VALUE :</p>
            <p
              className="para-text"
              style={{ fontWeight: "800", fontSize: "1.2rem" }}
            >
              {currencyFormat(
                data.currentHoldingGainLossData?.portfolioValueAmount
              )}
            </p>
          </div>
          <div className="Box">
            <p className="para-text">INVESTED VALUE</p>
            <p
              className="para-text"
              style={{ fontWeight: "800", fontSize: "1.2rem" }}
            >
              {currencyFormat(data.currentHoldingGainLossData?.investedAmount)}
            </p>
          </div>
          <div
            className="Box"
            style={{
              width: "30%",
              background:
                data.currentHoldingGainLossData?.unRealisedGainLoss > 0
                  ? "linear-gradient(to right, #1E2230 30%, #034338 80%)"
                  : "linear-gradient(270deg, #7C162F 0%, #1E2230 100%)",
              border:
                data.currentHoldingGainLossData?.unRealisedGainLoss > 0
                  ? "1px solid #1B7051"
                  : "1px solid #C1475D",
            }}
          >
            <p className="para-text">UNREALISED GAIN/LOSS</p>
            <p
              className="para-text"
              style={{ fontWeight: "800", fontSize: "1.2rem" }}
            >
              {currencyFormat(
                data.currentHoldingGainLossData?.unRealisedGainLoss
              )}
            </p>
          </div>
          <div
            className="Box"
            style={{
              marginRight: "0px",
              background:
                data.benchMarkXIRRData?.portfolioXirr >= 0
                  ? "linear-gradient(to right, #1E2230 30%, #034338 80%)"
                  : "linear-gradient(270deg, #7C162F 0%, #1E2230 100%)",
              border:
                data.benchMarkXIRRData?.portfolioXirr >= 0
                  ? "1px solid #1B7051"
                  : "1px solid #C1475D",
            }}
          >
            <p className="para-text">XIRR( since inception) </p>
            <p
              className="para-text"
              style={{ fontWeight: "800", fontSize: "1.2rem" }}
            >
              {roundNumber(data?.xirr) + " %"}
            </p>
          </div>
        </div>
        <div className="seconContainer">
          <div className="box2">
            {/* <p></p> */}

            {loadingTWRR ? (
              <span style={{ opacity: "0.5" }}>ANNUALIZED TWRR</span>
            ) : (
              "ANNUALIZED TWRR"
            )}

            <p>
              {error ? (
                <ReplayIcon
                  color="white"
                  className="reoloadIcon"
                  onClick={handleRetry}
                />
              ) : (
                <p style={{ fontWeight: "800", fontSize: "1.2rem" }}>
                  {loadingTWRR ? (
                    <CircularProgress color="#FFFFFF" />
                  ) : (
                    roundNumber(data.currentHoldingTWRRData?.annualisedTwrr) +
                    " %"
                  )}
                </p>
              )}
            </p>
          </div>
          <div style={{ borderRight: "1px solid #613D9B" }}></div>
          <div className="box2">
            {loadingTWRR ? (
              <span style={{ opacity: "0.5" }}>ABSOLUTE TWRR</span>
            ) : (
              "ABSOLUTE TWRR"
            )}
            {error ? (
              <ReplayIcon
                color="white"
                className="reoloadIcon"
                onClick={handleRetry}
              />
            ) : (
              <p style={{ fontWeight: "800", fontSize: "1.2rem" }}>
                {loadingTWRR ? (
                  <CircularProgress color="#FFFFFF" />
                ) : (
                  roundNumber(data.currentHoldingTWRRData?.absoluteTwrr) + " %"
                )}
              </p>
            )}
          </div>
          <div style={{ borderRight: "1px solid #613D9B" }}></div>
          <div className="box2">
            <p>BENCHMARK XIRR</p>
            <p style={{ fontWeight: "800", fontSize: "1.2rem" }}>
              {roundNumber(data.benchMarkXIRRData?.benchmarkXirr) + " %"}
            </p>
          </div>
        </div>
        <PageLoader showLoader={loadingXIRR} />
      </div>
    </>
  );
}

export default FirstSection;
