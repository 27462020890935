import { useState, useEffect } from "react";

export const useClientSearch = (search, tableColumns) => {
  const [searchResults, setSearchResults] = useState(null);

  useEffect(() => {
    if (search?.length > 0) {
      const searchData = (searchTerm) => {
        return tableColumns?.filter((item) => {
          return item?.some((field) => {
            return field?.field
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase());
          });
        });
      };
      setSearchResults(searchData(search));
    } else {
      // If the search input is empty, reset the search results
      setSearchResults(null);
    }
  }, [search, tableColumns]);

  return { searchResults };
};
