import React, { useState, useEffect, useContext } from "react";
import useStyles from "../common/ProductsStyles";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import { colors_allocation } from "../../../charts/colors";
import PieChart from "../../../charts/PieChart";
import { pie_onClick } from "../../../charts/nivo_objects";
import { OnboardingContext } from "../../../../Context";
import { getSegmentReport, getSegmentReportTable } from "../../../../api";
import PageLoader from "../common/pageLoader";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import { useNavigate } from "react-router-dom";
import DataNotFound from "../common/DataNotFound";
import TabComponent from "../common/TabComponent";
import { roundNumber, roundNumberWithoutDecimal } from "../../../utils/common";
import { IosSwitch } from "../../../IosSwitch";
import NewTabComp from "../common/NewTabComp";
import TableSummaryDetails from "../newDesigns/common/newSummaryDetailsTable/TableSummaryDetails";
const mfoSegmentHeaderData = [
  {
    field: "type",
    title: "Sub Category",
    dontShowFirstColBorder: true,
  },
  { field: "investedAmount", title: "Invested Value" },
  { field: "portfolioValueAmount", title: "Current Value" },
  { field: "realisedGainLoss", title: "Realized Gain/Loss" },
  {
    field: "unRealisedGainLoss",
    title: "Unrealized Gain/Loss",
  },
  {
    field: "interestIncome",
    title: "Other Income",
  },

  { field: "totalGainLoss", title: "Total Gain/Loss" },
  { field: "xirr", title: "XIRR(%)" },
  { field: "absoluteTwrr", title: "Absolute TWRR(%)" },
  { field: "annualisedTwrr", title: "Annualised TWRR(%)" },
];
const data = [
  { page: "equity", title: "Equity" },
  { page: "debt", title: "Debt" },
  { page: "alternate", title: "Alternate Assets" },
  { page: "cash", title: "Cash" },
];

function MfoSegment({ currencyFormat }) {
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const classes = useStyles();
  let assetOrder = ["equity", "debt", "alternate", "cash"];
  let selected_asset = getfilter_as_params().assetClass.split(",");
  let defaultAsset = assetOrder.find((a) => {
    if (selected_asset.includes(a)) {
      return a;
    }
  });

  const [subPage, setSubPage] = useState(defaultAsset);
  const { userState } = OnboardingContext();
  const [total, setTotal] = useState(null);
  const [load, setLoad] = useState({ segmentreport: true });
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [formattedTableData, setFormattedTableData] = useState([]);
  const [pieData, setPieData] = useState([]);

  useEffect(() => {
    let newList = [];
    let newPieData = [];
    tableData?.map((item, index) => {
      newList.push({
        ...item,
        type: item?.type,
        investedAmount: currencyFormat(item?.investedAmount),
        realisedGainLoss: currencyFormat(item?.realisedGainLoss),
        unRealisedGainLoss: currencyFormat(item?.unRealisedGainLoss),
        totalGainLoss: currencyFormat(item?.totalGainLoss),
        portfolioValueAmount: currencyFormat(item?.portfolioValueAmount),
        totalGainLoss: currencyFormat(item?.totalGainLoss),
        xirr: roundNumberWithoutDecimal(Number(item?.xirr)),
        absoluteTwrr: roundNumberWithoutDecimal(Number(item?.absoluteTwrr)),
        interestIncome: currencyFormat(item?.interestIncome),
        annualisedTwrr: roundNumberWithoutDecimal(Number(item?.annualisedTwrr)),
      });

      if (item.type !== "total") {
        newPieData.push({
          id: item?.type.charAt(0).toUpperCase() + item.type.slice(1),
          label: item.type,
          value: item.portfolioValueAmountPercentage,
          color: colors_allocation[index],
          amount: currencyFormat(item.portfolioValueAmount),
        });
      }
    });
    setPieData(newPieData);
    setFormattedTableData(newList);
  }, [tableData]);

  const [toggle, settoggle] = useState(false);
  const [piecolors, setPieColors] = useState(colors_allocation);
  const [pieState, setPieState] = useState({
    title: "",
    value: "",
    colors: piecolors,
    disabled: [],
    hide: false,
  });

  useEffect(() => {
    const clientId = userState.loginedUserData.id;

    let params = {
      ...getfilter_as_params(),
      assetClass: subPage,
    };

    setLoad({ ...load, segmentreport: true });

    // 🟢 SEGMENT TABLE API CALL
    getSegmentReportTable(clientId, params, toggle)
      .then((res) => {
        setTableData(res?.result);
        setLoad({ ...load, segmentreport: false });
      })
      .catch((err) => {
        setLoad({ ...load, segmentreport: false });
      });
    // getSegmentReport(clientId, params, toggle)
    //   .then((res) => {
    //     let colors = [];
    //     let result = res?.result?.map((r) => {
    //       if (r.color) {
    //         colors.push(r.color);
    //       }

    //       const {
    //         productSubCategory,
    //         presentValuePercentage,
    //         presentValue,
    //         color,
    //         ...rest
    //       } = r;
    //       return {
    //         id:
    //           r.productSubCategory.charAt(0).toUpperCase() +
    //           r.productSubCategory.slice(1),
    //         label: r.productSubCategory,
    //         value: r.presentValuePercentage,
    //         color: r.color,
    //         amount: r.presentValue,
    //         ...rest,
    //       };
    //     });
    //     // console.log(...result.filter((val)=> val?.id == 'Total'),'totalData');
    //     setTotal(...result.filter((val) => val?.id == "Total"));
    //     setPieData(result.filter((val) => val?.id !== "Total"));
    //     setPieColors(colors);
    //     setPieState({
    //       title: "",
    //       value: "",
    //       amount: "",
    //       colors: colors,
    //       disabled: [],
    //       hide: false,
    //     });
    //     setLoad({ ...load, segmentreport: false });
    //   })
    //   .catch((err) => {
    //     setLoad({ ...load, segmentreport: false });
    //   });
  }, [subPage, mfoFilter, toggle]);

  const handleClick = React.useCallback(
    (page) => {
      setSubPage(page);
    },
    [subPage]
  );

  return (
    <>
      <div className={`${classes.topHeader}`}>
        <Typography
          style={{ cursor: "pointer", minWidth: "40%" }}
          className={classes.mfoGraphHeader}
        >
          Sub-Category Wise Allocation
        </Typography>
        <br></br>
        {/* {
          <FormGroup style={{ marginLeft: "auto" }}>
            <FormControlLabel
              control={
                <IosSwitch
                  checked={toggle ? toggle : false}
                  setChecked={settoggle ? settoggle : () => {}}
                />
              }
              labelPlacement="start"
              style={{
                fontFamily: "Avenir",
                fontWeight: 900,
                fontSize: "16px",
                letterSpacing: "1.5px",
                textTransform: "uppercase",
                marginLeft: "auto",
                color: "#FFFFFF",
                opacity: 0.9,
                marginRight: "12px",
              }}
            />
          </FormGroup>
        } */}
      </div>
      {sessionStorage.getItem("currency_format") != "actuals" &&
        sessionStorage.getItem("currency_format") && (
          <span
            className="subtext capitalize"
            style={{ fontSize: "16px", marginLeft: "80px" }}
          >
            {" "}
            (values in {sessionStorage.getItem("currency_format")})
          </span>
        )}
      <div style={{ height: "auto" }} className={classes.mainContent}>
        {/* <Grid xs={6}> */}
        {/* <TabMfoSub subPage={subPage} setSubPage={setSubPage} ></TabMfoSub> */}
        {/* <TabComponent
          data={
            getfilter_as_params().assetClass
              ? data.filter((a) => {
                  if (getfilter_as_params().assetClass.indexOf(a.page) >= 0) {
                    return a;
                  }
                })
              : data
          }
          page={subPage}
          setPage={setSubPage}
          type={"state"}
          width={"fit-content"}
        ></TabComponent> */}
        <div style={{ marginLeft: "1%" }}>
          <NewTabComp
            data={
              getfilter_as_params().assetClass
                ? data.filter((a) => {
                    console.log(
                      a.page.indexOf(getfilter_as_params().assetClass)
                    );
                    if (getfilter_as_params().assetClass.indexOf(a.page) >= 0) {
                      return a;
                    }
                  })
                : data
            }
            customWidth={"fit-content"}
            customHandle={handleClick}
          />
        </div>

        {/* </Grid> */}
        <>
          <Grid xs={12}>
            {pieData.length !== 0 ? (
              <div className="flex_center tr20 " style={{ overflow: "hidden" }}>
                <div style={{ width: "380px" }}>
                  <PieChart
                    config={{
                      innerRadius: "0.80",
                      cornerRadius: 0,
                      padAngle: 0,
                      legends: false,
                      disableArcLink: true,
                      minWith: "380px",
                      margin: { right: 80, top: 20, left: 80 },

                      chart: { width: 420, height: 340 },
                      arcLinkLabel: (d) => `${d.value} %`,
                      // disableArcLink:pieState.hide?true:false
                    }}
                    colors={pieState.colors}
                    onClickEvent={{
                      click: pie_onClick,
                      set: setPieState,
                      cstate: pieState,
                      ncolors: piecolors,
                    }}
                    data={pieData}
                  >
                    <div
                      className="cen"
                      style={{
                        color: "#fff",
                        transform: "translate(100.5%, -47%)",
                      }}
                    >
                      <div>
                        {/* <div className='circle_text2'>{pieState.value?pieState.value+" %":""}</div> */}
                        <div className="circle_text3">{pieState.title}</div>
                        <div className="circle_text2">
                          {pieState.amount ? pieState.amount : ""}
                        </div>
                        <div
                          className="circle_text2"
                          style={{ paddingTop: "5px" }}
                        >
                          {pieState?.value
                            ? roundNumber(pieState.value) + "%"
                            : ""}
                        </div>
                      </div>
                    </div>
                  </PieChart>
                </div>
              </div>
            ) : load?.segmentreport === false ? (
              <DataNotFound></DataNotFound>
            ) : (
              <DataNotFound text=" "></DataNotFound>
            )}

            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                marginTop: "-5%",
                marginBottom: "3%",
              }}
            >
              {pieData?.map((m, i) => {
                return (
                  <div style={{ display: "flex" }}>
                    <div className={classes.graphBullets}>
                      <svg height="25" width="25">
                        <circle
                          cx="12"
                          cy="12"
                          r="5"
                          stroke-width="3"
                          fill={pieState.colors[i]}
                        />
                        Sorry, your browser does not support inline SVG.
                      </svg>
                    </div>
                    <div className={`${classes.graphLegends}`}>{m.id} </div>
                  </div>
                );
              })}
            </div>
          </Grid>
          <Grid xs={12}>
            <div
              className={classes.tableContainerAlign}
              style={{ display: pieData.length === 0 ? "none" : "" }}
            >
              {/* <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      style={{ borderRadius: "20px 0px 0px 20px" }}
                      className={classes.tableHeadText}
                    >
                      Scheme Segment
                    </TableCell>
                    <TableCell align="right" className={classes.tableHeadText}>
                      Invested Value
                    </TableCell>
                    <TableCell align="right" className={classes.tableHeadText}>
                      Withdrawls
                    </TableCell>
                    <TableCell align="right" className={classes.tableHeadText}>
                      Net Invested Amount
                    </TableCell>
                    <TableCell align="right" className={classes.tableHeadText}>
                      Balance Units
                    </TableCell>
                    <TableCell align="right" className={classes.tableHeadText}>
                      Present Value
                    </TableCell>
                    <TableCell align="right" className={classes.tableHeadText}>
                      Unrealized Gain/Loss
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ borderRadius: "0px 20px 20px 0px" }}
                      className={classes.tableHeadText}
                    >
                      Realized Gain/Loss
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableCell
                    style={{
                      height: "5px",
                      padding: "0px",
                    }}
                  ></TableCell>
                  {pieData?.map((p) => {
                    return (
                      <TableRow>
                        <TableCell
                          align="left"
                          className={classes.tableBodyText}
                        >
                          {p.id}
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.tableBodyText}
                        >
                          {currencyFormat(p.investedValue)}
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.tableBodyText}
                        >
                          {currencyFormat(p.withdrawls)}
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.tableBodyText}
                        >
                          {currencyFormat(p.netInvestedAmount)}
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.tableBodyText}
                        >
                          {p.balanceUnits}
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.tableBodyText}
                        >
                          {currencyFormat(p.amount)}
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.tableBodyText}
                        >
                          {currencyFormat(p.unrealisedGainLoss)}
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.tableBodyText}
                        >
                          {currencyFormat(p.realisedGainLoss)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {total && (
                    <TableRow>
                      <TableCell align="left" className={classes.tableBodyText}>
                        {total.id}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableBodyText}
                      >
                        {currencyFormat(total?.investedValue)}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableBodyText}
                      >
                        {currencyFormat(total?.withdrawls)}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableBodyText}
                      >
                        {currencyFormat(total?.netInvestedAmount)}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableBodyText}
                      >
                        {total?.balanceUnits}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableBodyText}
                      >
                        {currencyFormat(total?.amount)}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableBodyText}
                      >
                        {currencyFormat(total?.unrealisedGainLoss)}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableBodyText}
                      >
                        {currencyFormat(total?.realisedGainLoss)}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table> */}
              <TableSummaryDetails
                headerData={mfoSegmentHeaderData}
                data={formattedTableData}
                hideNavButtons={true}
              />
              {load?.segmentreport === false && pieData.length === 0 && (
                // <DataNotFound></DataNotFound>
                <></>
              )}
            </div>
          </Grid>
        </>
      </div>
      <PageLoader showLoader={load.segmentreport}></PageLoader>
    </>
  );
}

export default MfoSegment;
