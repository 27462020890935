import React from "react";
import { Modal, Backdrop, Grid, IconButton } from "@material-ui/core";
import { ReactComponent as CloseIconModal } from "../../../assets/icons/closeIconModal.svg";
import { ReactComponent as CrossIconModal } from "../../../assets/icons/crossIconModal.svg";
import { makeStyles, useTheme } from "@material-ui/core/styles";
const useStyles = makeStyles({
  common: {
    color: "#fff",
    fontFamily: '"Avenir"',
    fontStyle: "normal",
    lineHeight: "32px",
  },
  heading: {
    fontSize: "20px",
    fontWeight: 500,
  },
  description: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
});
export const DataPointModal = ({ open, setOpen }) => {
  const classes = useStyles();
  const theme = useTheme();
  function getModalStyle() {
    return {
      top: "63%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
  }
  return (
    <Modal
      open={open}
      style={{
        ...getModalStyle(),
        width: "96%",
        minHeight: "500px",
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: {
          borderRadius: "16px",
          border: `1px solid ${theme.palette.cards.dataPointModalBorder}`,
          background: theme.palette.cards.dataPointModalContainer,
          // backdropFilter: "blur(20px)",
        },
      }}
    >
      <div
        style={{
          outline: "none",
          margin: "18px",
          position: "relative",
        }}
      >
        <IconButton
          style={{ position: "absolute", right: 0, top: 10 }}
          onClick={() => setOpen(false)}
        >
          <CrossIconModal />
        </IconButton>
        <Grid container spacing={4}>
          <Grid item xs={3} style={{ paddingLeft: "32px" }}>
            <h2 className={`${classes.common} ${classes.heading}`}>
              Portfolio Value
            </h2>
            <p className={`${classes.common} ${classes.description}`}>
              It is the latest market value of your investments. It is derived
              by multiplying NAV (for Mutual funds) or last traded price (for
              Stocks and ETFs) with the number of units/shares. Current Value is
              updated every next business day for Stocks / ETFs and Mutual Funds
            </p>
          </Grid>
          <Grid item xs={3}>
            <h2 className={`${classes.common} ${classes.heading}`}>
              Invested Value
            </h2>
            <p className={`${classes.common} ${classes.description}`}>
              It is the cumulative sum of all Buy / Purchase transaction values
              that you have paid for from your bank account.
            </p>
          </Grid>
          <Grid item xs={5}>
            <h2 className={`${classes.common} ${classes.heading}`}>XIRR</h2>
            <p className={`${classes.common} ${classes.description}`}>
              XIRR is annualized internal rate of return. It is an enhanced form
              of compounded annual growth rate as it accounts for systematic and
              ad hoc investments. It also accounts for withdrawals with
              gains/losses. If you are a long-term investor, XIRR is the best
              way to measure your portfolio’s performance. Until 1 year of
              investment, you will see XIRR as NA
            </p>
          </Grid>
          <Grid item xs={3} style={{ paddingLeft: "32px" }}>
            <h2 className={`${classes.common} ${classes.heading}`}>
              Annualized TWRR
            </h2>
            <p className={`${classes.common} ${classes.description}`}>
              Time-weighted rate of return (TWRR) is a method for calculating
              the compound growth rate of an investment portfolio. Annualized
              TWRR is rate of return compounded annually.
            </p>
          </Grid>
          <Grid item xs={3}>
            <h2 className={`${classes.common} ${classes.heading}`}>
              Absolute TWRR
            </h2>
            <p className={`${classes.common} ${classes.description}`}>
              Time-weighted rate of return (TWRR) is a method for calculating
              the compound growth rate of an investment portfolio. Absolute TWRR
              is rate of return over a period.
            </p>
          </Grid>
          <Grid item xs={2}>
            <h2 className={`${classes.common} ${classes.heading}`}>
              Realised Gain / Loss
            </h2>
            <p className={`${classes.common} ${classes.description}`}>
              This consists of Realised gain, dividend income, interest income,
              capital gain or any other income
            </p>
          </Grid>
          <Grid item xs={4}>
            <h2 className={`${classes.common} ${classes.heading}`}>
              Unrealised Gain / Loss
            </h2>
            <p className={`${classes.common} ${classes.description}`}>
              It is the difference between your average Purchase/Cost Price and
              Current Price/NAV multiplied by number of units/shares. When
              positive, it means you are unbooked profits. If negative, it means
              you have losses that you haven’t booked yet
            </p>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};
