import moment from "moment";
import { useState, useEffect, useContext } from "react";
import { fetchAllMembersConsolidated } from "../api";
import { OnboardingContext } from "../Context";
import { mfoFilterContext } from "../mfo_context/Mfo_Context";
/**
 * Hook for fetching data for All Members Consolidated
 *
 * @param {string} type - the type of data to be fetched (consolidated/individual)
 * @returns {Array} [changePercentage, changeValueAmount, clientName, investedAmount, memberName, portfolioValueAmount, totalFamilyMembers, membersArray] - Array of data properties of all members consolidated/individual data based on type
 * @example
 *
 * const [changePercentage, changeValueAmount, clientName, investedAmount, memberName, portfolioValueAmount, totalFamilyMembers, membersArray] = useAllMembersConsolidated("consolidated");
 */
export const useAllMembersConsolidated = (type, endDate, scripName) => {
  const { userState } = OnboardingContext();
  const [headOfFamily, setHeadOfFamily] = useState();
  const { mfoFilter } = useContext(mfoFilterContext);
  const [loading, setLoading] = useState(true);
  let clientId = userState.loginedUserData.id;

  // let clientId = 199;

  useEffect(() => {
    if (scripName) {
      setLoading(false);
    } else {
      setLoading(true);
    }

    // let params = { type: type, endDate: "2021-12-06" };
    let params = {
      type: type,
      endDate: moment(endDate, "DD-MMM-YYYY").format(
        // "YYYY-MM-DD"
        "YYYY-MM-DD"
      ),
      search: scripName || "",
    };

    /* All Members Consolidated API CALL*/
    fetchAllMembersConsolidated(clientId, params)
      .then((response) => {
        // setLoading(true);

        setHeadOfFamily(response?.data?.data?.result);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [mfoFilter, type, endDate, scripName]);

  // Destructure the response set in headOfFamily state variable
  // const {
  //   changePercentage,
  //   changeValueAmount,
  //   clientName,
  //   investedAmount,
  //   memberName,
  //   portfolioValueAmount,
  //   totalFamilyMembers,
  // } = headOfFamily || {};
  // const { members: membersArray } = headOfFamily || [];

  // return [
  //   changePercentage,
  //   changeValueAmount,
  //   clientName,
  //   investedAmount,
  //   memberName,
  //   portfolioValueAmount,
  //   totalFamilyMembers,
  //   membersArray,
  // ];
  return [headOfFamily, loading];
};
