import { useState, useEffect, useContext } from "react";
import { fetchAllClients } from "../../../../api";
import { OnboardingContext } from "../../../../Context";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";

export const useAllClients = (filterModalOpen, subTabElement) => {
  //   const [initialFamilyMembers, setInitialFamilyMembers] = useState([]);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [clientName, setClientName] = useState(null);
  const { userState } = OnboardingContext();
  const [selectedFamilyMembers, setSelectedFamilyMembers] = useState([]);
  const { mfoFilter } = useContext(mfoFilterContext);
  useEffect(() => {
    let params = {};
    params.status = "approved";
    params.isActive = "1";
    if (clientName) {
      params.name = clientName;
    }
    let clientId = userState.loginedUserData.id;
    if (
      localStorage?.getItem("userType") === "mfo-user" ||
      localStorage?.getItem("userType") === "partner"
    ) {
      clientId = localStorage?.getItem("memberId");
    }
    fetchAllClients(clientId, params).then((res) => {
      setFamilyMembers(res?.data?.data?.result);
      // ** if context does not have any selected members then all members should be selected by default
      if (Object.keys(mfoFilter?.checkData)?.length === 0) {
        // setInitialFamilyMembers(res?.data?.data?.result);
        setSelectedFamilyMembers(res?.data?.data?.result);
      }
    });
  }, [clientName, filterModalOpen]);

  return {
    // initialFamilyMembers,
    familyMembers,
    setFamilyMembers,
    setClientName,
    selectedFamilyMembers,
    setSelectedFamilyMembers,
  };
};
