import React, { useState, useEffect, useContext, useRef } from "react";
import useStyles from "../common/ProductsStyles";
import { Grid, Typography } from "@material-ui/core";
import { ReactComponent as Import } from "../../../../assets/icons/import.svg";
import { ReactComponent as Export } from "../../../../assets/icons/export.svg";
import { ReactComponent as Print } from "../../../../assets/icons/print.svg";
import { ReactComponent as EditColumn } from "../../../../assets/icons/edit-column.svg";
import TabPmsSubTransaction from "../common/TabPmsSubTransaction";
import PmsReportTable from "../pms/PmsReportTable";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import moment from "moment";
import { ReactComponent as Back } from "../../../../assets/holding-summary/back-blue.svg";
import { currencyFormat } from "../../../../hooks/constantFunction";
import PageLoader from "../common/pageLoader";
//need confirmatioon to remove these unused components,
//these components are replaced with PmsReportTable component
// import PmsTransactionAll from "./PmsTransactionAll";
// import PmsTransactionPurchase from "./PmsTransactionPurchase";
// import PmsTransactionRedemption from "./PmsTransactionRedemption";
import {
  dateFormatCommon,
  roundNumber,
  summarizeTableData,
} from "../../../utils/common";
import TabComponent from "../common/TabComponent";
import SearchInput from "../common/SearchInput";
import { useNavigate } from "react-router-dom";
import { useClientSearch } from "../../../../hooks/useClientSearch";
import NewtableTopSec from "../../common/NewtableTopSec";
import { ITEMS_LIMIT_FILES_PARTNER_LIST } from "../../../../Constants";
import { useCardsApi } from "../../../../hooks/useCardsApi";
import { useCurrentHoldingCardsApi } from "../../../../hooks/useCurrentHoldingCardsApi";

const data = [
  { page: "equity", title: "Equity" },
  { page: "debt", title: "Debt" },
  { page: "alternate", title: "Alternate Assets" },
  { page: "cash", title: "Cash" },
];

function MLDHolding({
  getTabelData,
  customTableConfig,
  subHeading,
  backUrl,
  headerInTwoLine,
  hideSearch,
  pageCount,
  typeOfProduct,
  bankTotal,
}) {
  const { getfilter_as_params } = useContext(mfoFilterContext);
  const classes = useStyles();
  let assetOrder = ["equity", "debt", "alternate", "cash"];
  let selected_asset = getfilter_as_params().assetClass.split(",");
  let defaultAsset = assetOrder.find((a) => {
    if (selected_asset.includes(a)) {
      return a;
    }
  });
  // console.log(selected_asset, "aglagljk");
  const [page, setPage] = useState(defaultAsset);
  const { holdingCardData, xirrCurrentHolding, twrrCurrentHolding } =
    useCurrentHoldingCardsApi(true, typeOfProduct);

  const navigate = useNavigate();
  const [tableColumns, setTableColumns] = useState([]);
  const [secondTableColumns, setSecondTableColumns] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { mfoFilter } = useContext(mfoFilterContext);
  const [reset, setReset] = useState();
  const tableConfig = customTableConfig
    ? customTableConfig
    : [
        {
          field: "scheme_name",
          title: "Product Name",
          show: true,
          align: "center",
        },
        { field: "advisor", title: "Advisor", show: true, align: "center" },
        { field: "isin", title: "Product Code", show: true, align: "center" },
        { field: "folio_no", title: "Folio No", show: true, align: "center" },
        // { field: "asset_type", title: "Asset Type", show: true },
        {
          field: "product_sub_category",
          title: "Product Sub Category",
          show: true,
          align: "center",
        },
        {
          field: "transaction_date",
          title: "Transaction date",
          show: true,
          align: "center",
        },
        {
          field: "transaction_type",
          title: "Transaction Type",
          show: true,
          align: "center",
        },
        {
          field: "holdingPeriod",
          title: "Holding Period",
          show: true,
        },
        { field: "amount", title: "Amount", show: true },
        { field: "load_charges", title: "Load Charged", show: true },
        { field: "dividend", title: "Dividend", show: true },
        { field: "interest", title: "Interest", show: true },

        { field: "other_income", title: "Other Income", show: true },
        { field: "management_fees", title: "Management Fees", show: true },
        {
          field: "securities_transaction_tax",
          title: "Security Transaction Tax",
          show: true,
        },
        { field: "other_expense", title: "Other Expense", show: true },
      ];
  const [tableHeader, setTableHeader] = useState(tableConfig);
  const [checkedTrans, setCheckedTrans] = useState([]);
  const [search, setSearch] = useState("");
  const { searchResults } = useClientSearch(search, tableColumns);
  const [pagiNationData, setPageinationData] = useState({
    pageCount: null,
    // limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
  });

  const numberOfInstrumentsCount = useRef(0);
  const [numberOfInstruments, setNumberOfInstruments] = useState(0);
  const [percentageOfTotalPortfolio, setPercentageOfTotalPortfolio] =
    useState(0);

  const [offset, setOffset] = useState(0);

  const handleIconClick = () => {
    setOpenModal(!openModal);
  };

  const objectFormating = (data) => {
    let newData = tableHeader.map((header) => {
      if (header?.field == "transaction_date") {
        return {
          key: header?.field,
          field: dateFormatCommon(new Date(data[`${header.field}`])),
          // moment(new Date(data[`${header.field}`])).format(
          //   "DD - MM - YYYY"
          // ) || "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (header?.field == "isin" || header?.field == "folio_no") {
        return {
          key: header?.field,
          field: data[`${header.field}`],
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (header?.field == "unrealisedGainLoss") {
        return {
          key: header?.field,
          field: currencyFormat(data[`${header.field}`] || 0) || "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (header?.field == "interestAmount") {
        return {
          key: header?.field,
          field: currencyFormat(data[`${header.field}`] || 0) || "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }

      if (
        header?.field == "amount" ||
        header?.field == "load_charges" ||
        header?.field == "dividend" ||
        header?.field == "interest" ||
        header?.field == "other_income" ||
        header?.field == "interestIncome" ||
        header?.field == "management_fees" ||
        header?.field == "securities_transaction_tax" ||
        header?.field == "other_expense" ||
        header?.field == "presentValue" ||
        header?.field == "investmentValue" ||
        header?.field == "totalTransactionValue"
      ) {
        return {
          key: header?.field,
          field: currencyFormat(data[`${header.field}`] || 0) || "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (header?.field == "holdingPeriod") {
        return {
          key: header?.field,
          field: data[`${header.field}`]
            ? data[`${header.field}`] + " days"
            : "--",
          // moment(new Date(data[`${header.field}`])).format(
          //   "DD - MM - YYYY"
          // ) || "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      return {
        key: header?.field,
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`]))
          : data[`${header.field}`] || "--",
        title: header.title,
        show: header.show,
        align: header?.align,
      };
    });
    return newData;
  };

  useEffect(() => {
    if (subHeading === "Bank") {
      setSecondTableColumns(
        summarizeTableData(
          tableColumns,
          holdingCardData,
          xirrCurrentHolding,
          twrrCurrentHolding,
          numberOfInstruments,
          "",
          "bank",
          bankTotal,
          percentageOfTotalPortfolio
        )
      );
    } else {
      setSecondTableColumns(
        summarizeTableData(
          tableColumns,
          holdingCardData,
          xirrCurrentHolding,
          twrrCurrentHolding,
          numberOfInstruments,
          "",
          "",
          "",
          percentageOfTotalPortfolio
        )
      );
    }
  }, [tableColumns, holdingCardData, xirrCurrentHolding, twrrCurrentHolding]);

  useEffect(() => {
    numberOfInstrumentsCount.current = 0;
  }, [page, mfoFilter]);

  useEffect(() => {
    if (tableHeader) {
      getTabelData(
        setTableColumns,
        "summary/holding",
        {
          clientId: 4,
          memberId: 2,
          startDate: "2022-05-01",
          endDate: "2022-05-03",
          // assetClass: page?.toLocaleLowerCase(),
          search: search,
        },
        setLoading,
        objectFormating,
        setCheckedTrans,
        tableHeader,
        ITEMS_LIMIT_FILES_PARTNER_LIST,
        offset,
        setPageinationData,
        pagiNationData,
        numberOfInstrumentsCount,
        setPercentageOfTotalPortfolio,
        setNumberOfInstruments
      );
    }
  }, [
    page,
    mfoFilter,
    // reset,
    search,
    offset,
  ]);
  return (
    <>
      <NewtableTopSec
        handleIconClick={handleIconClick}
        directPageName={"Holding Summary"}
        setSearch={
          !hideSearch &&
          ((e) => {
            setOffset(0);
            setSearch(e);
          })
        }
        product={subHeading ? subHeading : "MLD"}
      />

      <PmsReportTable
        setTableColumns={setTableColumns}
        tableColumns={tableColumns}
        setSecondTableColumns={setSecondTableColumns}
        tableColumns2={secondTableColumns}
        setOpenModal={setOpenModal}
        openModal={openModal}
        //tableName={page}
        loading={loading}
        pageName="trasaction"
        headerList={tableConfig}
        tableHeader={tableHeader}
        setTableHeader={setTableHeader}
        stickyProductName={true}
        checkedTransList={checkedTrans}
        setReset={setReset}
        headerInTwoLine={headerInTwoLine}
        searchParam={search}
        setItemOffset={setOffset}
        pageCount={pagiNationData?.pageCount}
        pageNumber={offset / ITEMS_LIMIT_FILES_PARTNER_LIST + 1 || 1}
      />
      <PageLoader showLoader={loading} />
    </>
  );
}

export default MLDHolding;
