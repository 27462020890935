const tableBorderColor = {
  Equity: "#97B2D5",
  Debt: "#00838F",
  Alternate: "#ADC371",
  Alternates: "#ADC371",
  Cash: "#49618D",
  Reits: "#ADC371",
  Commoditie: "#ADC371",
  Total: "#0AC0CE",
  Banks: "#0AC0CE",
  "HELD ": "hsl(228, 70%, 50%)",
  "HELD AWAY": "hsl(154, 70%, 50%)",
};

const doNothingArrayPrint = [
  "assetClass",
  "assetCategory",
  "benchmark",
  "particularSegmentValue",
  "assset",
  "indicator",
  "schemeName",
  "scripName",
  "equityName",
  "performerName",
  "sectorName",
  "advisor",
  "Instrument Name/Scheme Name ",
  "Member Name",
  "Transaction type Income",
  "Folio No",
  "folioNo",
  "Instrument Name/Scheme Name",
  "accountNo",
];
const TopBottomDoNothingArr = [
  "product",
  "fund",
  "benchmark",
  "schemeName",
  "scripName",
];
const MemberWiseDoNothingArr = ["memberName"];
const TaxDoNothingArr = [
  "instrumentName",
  "daysHeld",
  "holdingPeriod",
  "schemeName",
];

const doNothingProductAllocation = ["product"];
const doNothingCommonChart = ["product", "bondRating", "noOfInstruments"];
const currencyFormatCommonChart = [
  "bondRatingValue",
  "currentValue",
  "presentValue",
  "portfolioValueAmount",
];
const currencyFormatCheckProductAllocationPrint = ["currentValue"];
const currencyFormatCheckTaxPrint = [
  "purchaseAmount",
  "saleAmount",
  "saleRate",
  "purchaseRate",
  "currentFaceValue",
  "stcg",
  "ltcg",
  "amount",
];
const currencyFormatTotalTaxPrint = ["stcg", "ltcg", "realisedGainLoss"];
const currencyFormatCheckArrayPrint = [
  "transactionValue",
  "currentValue",
  "investedValue",
  "totalGainLoss",
  "holdingCost",
  "marketValue",
  "gainLossRealized",
  "portfolioValueAmount",
  "investedAmount",
  "realisedGainLoss",
  "unRealisedGainLoss",
  "netRatePerUnit",
  "investedAmount",
  "portfolioValueAmount",
  "totalGainLoss",
  "commitedAmount",
  "drawdownAmount",
  "pendingDrawdown",
  "totalInvestmentValue",
  "totalPresentValue",
  "totalGainLoss",
  "performerTotalInvestmentValue",
  "performerPresentValue",
  "performerGainLoss",
  "investmentValue",
  "presentValue",
  "openingBalance",
  "purchase",
  "divident_paid",
  "redemption",
  "closingBalance",
  "netValue",
  "Total Transaction Value",
  "Current Market Value",
  "Other Income",
  "Total Cost",
  "totalCost",
  "Price",
  "currentPortfolioValue",
  "otherIncome",
  "investmentAmount",
  "valuation",
  "Amount",
  "price",
  "coverAmount",
  "premium",
  "balance",
  "amount",
  "interestIncome",
];

const onlyPercentage = [
  "contribution",
  "percentageHolding",
  "gainLossUnrelized",
  "actualPercent",
  "allocation",
  "limitPercent",
  // "percentageOfTotalPortfolio",
  "equityPercentage",
  "benchmark",
  "directPercentage",
  "weight",
  "performerPercentage",
];

const percentage = [
  "xirr",
  "absoluteTwrr",
  "annualisedTwrr",
  "annualizedTwrr",

  "benchmarkXirr",
  "irr",
  "bmIrr",
  "benchmarkIrr",
  "excessPerformance",
  "twrr",
  "performerXirr",
  "performerBMXirr",
  "XIRR",
];

const currencyFormaWithGreenRedCell = ["absoluteValue", "unRealisedGainLoss"];

const gorupRows = ["alternates", "reits", "commoditie"];

const dateFormatingArrayPrint = [
  "lastDrawdownDate",
  "Transaction Date",
  "Date",
];
const taxDateArray = ["purchaseDate", "saleDate"];

const positive = "positive";
const negative = "negative";

module.exports = {
  tableBorderColor,
  doNothingArrayPrint,
  currencyFormatCheckArrayPrint,
  dateFormatingArrayPrint,
  percentage,
  positive,
  negative,
  gorupRows,
  onlyPercentage,
  currencyFormaWithGreenRedCell,
  doNothingProductAllocation,
  currencyFormatCheckProductAllocationPrint,
  TopBottomDoNothingArr,
  MemberWiseDoNothingArr,
  doNothingCommonChart,
  currencyFormatCommonChart,
  TaxDoNothingArr,
  currencyFormatCheckTaxPrint,
  currencyFormatTotalTaxPrint,
};
