import React from "react";
import Default from "../components/dashboard/defaultPages/Default";
import { DetailsAccordion } from "../components/dashboard/common/DetailsAccordion";
import { ClientDetailsAccordion } from "../components/dashboard/common/ClientDetailsAccordion";

function ClientPendingDetailsPage() {
  return (
    <Default>
      {/* <DetailsAccordion /> */}
      <ClientDetailsAccordion />
    </Default>
  );
}

export default ClientPendingDetailsPage;
