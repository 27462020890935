import React, { useEffect, useCallback } from "react";
import { useState } from "react";
import _ from "lodash";
import PmsReportTable from "./PmsReportTable";

function PmsHoldingEquity({
  openModal,
  setOpenModal,
  getTabelData,
  tableConfig,
  objectFormating,
  setTableHeader,
  tableHeader,
  mfoFilter,
  tableColumns,
  setTableColumns,
  loading,
  tableName,
  setOffset,
  pageCount,
  pageNumber,
  setSecondTableColumns,
  tableColumns2,
}) {
  return (
    <PmsReportTable
      productType="pmsHoldingEquity"
      setTableColumns={setTableColumns}
      tableColumns={tableColumns}
      setSecondTableColumns={setSecondTableColumns}
      tableColumns2={tableColumns2}
      setOpenModal={setOpenModal}
      openModal={openModal}
      tableName={tableName || "Equity"}
      loading={loading}
      headerList={tableConfig}
      pageName="holding"
      tableHeader={tableHeader}
      setTableHeader={setTableHeader}
      stickyProductName={true}
      setItemOffset={setOffset}
      pageCount={pageCount}
      pageNumber={pageNumber}
    />
  );
}

export default PmsHoldingEquity;
