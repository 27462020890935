import React, { useEffect, useState } from "react";
import EquitySubTab from "./EquitySubTab";
import "../common/ProductsStyles";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Modal,
  Button,
} from "@material-ui/core";
import useStyles from "../common/ProductsStyles";
import PieChart from "../../../charts/PieChart";
import { colors } from "../../../charts/colors";
import { pie_onClick2 } from "../../../charts/nivo_objects";
import { fetch_equity_gain_loss } from "../../../../api";
import { useContext } from "react";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import { OnboardingContext } from "../../../../Context";
import { ReactComponent as Marketuparrow } from "../../../../assets/clientdashboard/marketuparrow.svg";
import { ReactComponent as Marketdownarrow } from "../../../../assets/clientdashboard/marketdownarrow.svg";
import moment from "moment";
import { dateFormatCommon, roundNumber } from "../../../utils/common";
import { currencyFormat } from "../../../../hooks/constantFunction";
import PageLoader from "../common/pageLoader";
// import { handleEqGainLossText } from "./utils/common";
export default function EquityGain() {
  const classes = useStyles();
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const { userState } = OnboardingContext();
  const [loading, setLoading] = useState(true);
  const [gainloss, setgainloss] = useState(null);
  const [realised_graphdat, setgraphdata] = useState({ graph: [], data: [] });
  const [toggle, settoggle] = useState(false);
  const [unrealise_graphdata, setunrealised_graphdata] = useState({
    graph: [],
    data: [],
  });
  const [pie, setPie] = useState({
    title: "",
    value: "",
    colors: ["#B80000", "#82EBA1"],
    disabled: [],
    fill: [
      {
        match: {
          id: "Loss",
        },
        id: "c6",
      },
      {
        match: {
          id: "Gain",
        },
        id: "c2",
      },
    ],
  });
  let tempdata = [
    {
      id: "rust",
      label: "rust",
      value: 200,
      color: "hsl(229, 70%, 50%)",
    },
    {
      id: "css",
      label: "css",
      value: 245,
      color: "hsl(35, 70%, 50%)",
    },
    {
      id: "go",
      label: "go",
      value: 310,
      color: "hsl(91, 70%, 50%)",
    },
    {
      id: "java",
      label: "java",
      value: 385,
      color: "hsl(313, 70%, 50%)",
    },
    {
      id: "python",
      label: "python",
      value: 433,
      color: "hsl(129, 70%, 50%)",
    },
  ];

  const handleEqGainLossText = (text) => {
    let str = "";
    let a = text.split(" ")?.map((val, i) => {
      if (i == 1 || i == 2) {
        return <span style={{ opacity: "0.5" }}> {val} </span>;
      }
      if (i > 2) {
        return (val = str + " " + val);
      }
      return val;
    });

    return a;
  };

  function disp_gain_loss(stock_data, type_) {
    return stock_data.slice(0, 2).map((g, i) => {
      // if (g.changePercentage > 0) {
      return (
        <div
          style={{
            marginBottom: "30px",
            // visibility: g.changePercentage > 0 ? "visible" : "hidden",
          }}
        >
          <Typography
            className={classes.GainDetailsHeadingText}
            style={{ color: "#9488A7" }}
          >
            {g?.scripName}
          </Typography>

          <Typography className={classes.GainDetailsHeadingText}>
            <div
              className="flex-container"
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              {" "}
              {currencyFormat(g.changeAmount) + "    "}
              <div
                className="flex-container"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                {/* {type_ === "loss" ? <Marketdownarrow /> : <Marketuparrow />} */}
              </div>
              <div
                className={type_ == "loss" ? "#B80000" : "#82EBA1"}
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  alignItems: "center",
                  gap: "3px",
                  color: type_ == "loss" ? "#B80000" : "#82EBA1",
                }}
              >
                {"    " + g.changePercentage != null
                  ? roundNumber(g.changePercentage) + "%"
                  : ""}{" "}
                {type_ === "loss" ? <Marketdownarrow /> : <Marketuparrow />}
              </div>
            </div>
          </Typography>
        </div>
      );
      // }
    });
  }
  const ShowLegends = (data, color) => {
    return (
      <div
        style={
          {
            // position: "absolute",
            // top: "10%",
            // left: "340px",
            // minHeight: '150px'
          }
        }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            minHeight: "150px",
            maxWidth: "340px",
          }}
        >
          {data?.map((m, i) => {
            return (
              <div key={i} className={classes.labelContainer}>
                <div
                  className={classes.labelSymbol}
                  style={{ marginTop: "4px" }}
                >
                  <svg height="25" width="25">
                    <circle
                      cx="12"
                      cy="12"
                      r="5"
                      stroke-width="3"
                      fill={color[i]}
                    />
                    Sorry, your browser does not support inline SVG.
                  </svg>
                </div>
                {/* <div className={classes.label}><div className={classes.labelTextLegends}><span style={{marginRight:"20px"}} >{m.label}</span>  <span>{m.value}%</span> </div></div> */}
                <table style={{ width: "300px" }}>
                  <tr className={`${classes.label} no-border `}>
                    <th
                      className={`${classes.labelTextLegends} circle_text3`}
                      style={{ lineHeight: "30px" }}
                    >
                      <p className="eqGainTxt2" style={{ overflow: "unset" }}>
                        {/* {m.title}{" "} */}
                        {handleEqGainLossText(m.title).map((val) => val)}
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th
                      style={{
                        // paddingLeft: "10px",
                        display: "block",
                        fontSize: "20px",
                        borderBottom: "none",
                        fontSize: "1rem",
                        color: i % 2 == 1 ? "#B80000" : "#82EBA1",
                        textAlign: "left",
                      }}
                      className={`${classes.labelTextLegends} ${
                        i % 2 == 1 ? "#B80000" : "#82EBA1"
                      } circle_text3 `}
                    >
                      <div className="flex-container">
                        {" "}
                        <Typography className="eqGainText">
                          {currencyFormat(m.value) + "    "}
                        </Typography>
                        <div
                          className="flex-container"
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}
                        >
                          {i % 2 == 1 ? <Marketdownarrow /> : <Marketuparrow />}
                        </div>
                        <Typography className="eqGainText">
                          {"    " + m.percent != null
                            ? roundNumber(m.percent) + "%"
                            : ""}{" "}
                        </Typography>
                      </div>
                    </th>
                  </tr>
                </table>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  const invAllocationColors = [
    colors.orange,
    colors.green,
    colors.blue,
    colors.purple,
  ];
  const [realisedData, setRealisedData] = useState([]);
  const [invAllocation, setInvAllocation] = useState({
    title: "",
    value: "",
    colors: invAllocationColors,
    disabled: [],
  });
  const [gainstate, setGainState] = useState({
    title: "",
    value: "",
    colors: invAllocationColors,
    disabled: [],
  });
  useEffect(() => {
    const clientId = userState.loginedUserData.id;
    // const clientId = 199;
    //
    let params = { ...getfilter_as_params() };

    setLoading(true);
    fetch_equity_gain_loss(clientId, params, null, true)
      .then((res) => {
        let obj = {};
        let legendsarr = [
          {
            title: res.result.todaysGainLoss.totalFundsGaining,
            value: res.result.todaysGainLoss.totalFundsGainingAmount,
            percent:
              Number(res.result.todaysGainLoss.totalFundsGainingPercentage) *
              100,
          },
          {
            title: res.result.todaysGainLoss.totalFundsLosing,
            value: res.result.todaysGainLoss.totalFundsLosingAmount,
            percent:
              Number(res.result.todaysGainLoss.totalFundsLosingPercentage) *
              100,
          },
        ];
        let unrealised_legendsarr = [
          {
            title: res.result.unRealisedGainLoss.totalFundsGaining,
            value: res.result.unRealisedGainLoss.totalFundsGainingAmount,
            percent:
              Number(
                res.result.unRealisedGainLoss.totalFundsGainingPercentage
              ) * 100,
          },
          {
            title: res.result.unRealisedGainLoss.totalFundsLosing,
            value: res.result.unRealisedGainLoss.totalFundsLosingAmount,
            percent:
              Number(res.result.unRealisedGainLoss.totalFundsLosingPercentage) *
              100,
          },
        ];
        setgainloss({
          todaysgain: {
            date: res.result.todaysGainLoss.asOnDate,
            gaining_stock_data: [
              ...res.result.todaysGainLoss?.gainingStocksData,
            ],
            loosing_stock_data: [
              ...res.result.todaysGainLoss?.losingStocksData,
            ],
          },
          unrealisedgain: {
            date: res.result.unRealisedGainLoss.asOnDate,
            gaining_stock_data: [
              ...res.result.unRealisedGainLoss?.gainingStocksData,
            ],
            loosing_stock_data: [
              ...res.result.unRealisedGainLoss?.losingStocksData,
            ],
          },
        });

        setgraphdata({
          graph: [
            {
              id: "Gain",
              value:
                res.result.todaysGainLoss.totalFundsGainingPercentagePieChart,
            },
            {
              id: "Loss",
              value:
                res.result.todaysGainLoss.totalFundsLosingPercentagePieChart,
            },
          ],
          data: legendsarr,
        });
        setunrealised_graphdata({
          graph: [
            {
              id: "Gain",
              value:
                res.result.unRealisedGainLoss
                  .totalFundsGainingPercentagePieChart,
            },
            {
              id: "Loss",
              value:
                res.result.unRealisedGainLoss
                  .totalFundsLosingPercentagePieChart,
            },
          ],
          data: unrealised_legendsarr,
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [toggle]);

  return (
    <div>
      <EquitySubTab
        title="Gain/Loss Summary"
        toggle={toggle}
        settoggle={settoggle}
      ></EquitySubTab>
      <div style={{ margin: "2%" }} id="equityGainLoss">
        {/* old comp */}

        {/* <Grid container spacing={2} sx={{ height: "100%" }}>

          <Grid md={6} xs={12} item>
            <div className={classes.mainContent}>
              <Typography
                style={{ marginTop: "2%", marginLeft: "2%" }}
                className={`${classes.riskHeaderSub}`}
              >
                Today’s Gain/Loss
              </Typography>
              <Typography
                style={{ marginLeft: "2%", color: "#9488A7" }}
                className={`${classes.label}`}
              >
                as on {gainloss?.todaysgain?.date}
              </Typography>
              <div style={{ display: "flex", margin: "0%", minHeight: "88%" }}>
                <div style={{ height: "100%", width: "100%" }}>
                  <Grid
                    container
                    spacing={2}
                    sx={{ height: "100%" }}
                    style={{ position: "relative" }}
                  >
                    <Grid
                      style={{ margin: "5% 5% 5% 5%" }}
                      zeroMinWidth
                      item
                      md={6}
                      xs={12}
                    >
                      <div
                        style={{
                          width: "300px",
                          height: "250px",
                          overflow: "hidden",
                        }}
                      >
                        <PieChart
                          fill={pie.fill}
                          config={{
                            innerRadius: "0.8",
                            padAngle: 1,
                            legends: false,
                            minWith: "380px",
                            margin: { right: 200, top: 20, left: 30 },
                            disableArcLink: true,
                            chart: { width: 420, height: 340 },
                          }}
                          colors={invAllocation.colors}
                          onClickEvent={{
                            click: pie_onClick2,
                            set: setInvAllocation,
                            cstate: invAllocation,
                            ncolors: invAllocationColors,
                          }}
                          data={realised_graphdat.graph}
                        >
                          <div className="cen" style={{ color: "#fff" }}>
                            <div>
                              <div>{ }</div>
                              <div>{ }</div>
                            </div>
                          </div>
                        </PieChart>
                        {ShowLegends(realised_graphdat.data, ["#B80000", "#82EBA1"])}
                      </div>
                      <Typography
                        style={{
                          textAlign: "center",
                          width: "243px",
                          color: "#9488A7",
                          position: "absolute",
                        }}
                        className={`${classes.label}`}
                      >
                        % Investment
                      </Typography>
                    </Grid>

                    <Grid
                      style={{ margin: "5% 3% 3% 5%" }}
                      zeroMinWidth
                      item
                      md={12}
                      xs={12}
                    >
                      <hr className="filter-divider"></hr>
                    </Grid>

                    <Grid
                      container
                      style={{ margin: "5% 3% 3% 5%" }}
                      zeroMinWidth
                      item
                      md={5}
                      xs={12}
                    >
                      <Typography
                        className={classes.GainDetailsHeadingText}
                        style={{ marginBottom: "20px" }}
                      >
                        Gaining Stocks
                      </Typography>
                      <Grid
                        style={{ margin: "3% 3% 3% 0%" }}
                        zeroMinWidth
                        item
                        md={12}
                        xs={12}
                      >
                        {gainloss &&
                          disp_gain_loss(
                            gainloss?.todaysgain?.gaining_stock_data
                          )}
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      style={{ margin: "5% 3% 3% 5%" }}
                      zeroMinWidth
                      item
                      md={5}
                      xs={12}
                    >
                      <Typography
                        className={classes.GainDetailsHeadingText}
                        style={{ marginBottom: "20px" }}
                      >
                        Loss Stocks
                      </Typography>
                      <Grid
                        style={{ margin: "3% 3% 3% 0%" }}
                        zeroMinWidth
                        item
                        md={12}
                        xs={12}
                      >
                        {gainloss &&
                          disp_gain_loss(
                            gainloss?.todaysgain?.loosing_stock_data,
                            "loss"
                          )}
                      </Grid>
                    </Grid>

                  </Grid>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item md={6} xs={12}>
         
            <div className={classes.mainContent}>
              <Typography
                style={{ marginTop: "2%", marginLeft: "2%" }}
                className={`${classes.riskHeaderSub}`}
              >
                Unrealised Gain/Loss
              </Typography>
              <Typography
                style={{ marginLeft: "2%", color: "#9488A7" }}
                className={`${classes.label}`}
              >
                as on {gainloss?.unrealisedgain?.date}
              </Typography>
              <div style={{ display: "flex", margin: "0%", minHeight: "88%" }}>
                <Grid container spacing={2} style={{ position: "relative" }}>
                  <Grid
                    style={{ margin: "5% 5% 5% 5%" }}
                    zeroMinWidth
                    item
                    md={6}
                    xs={12}
                  >
                    <div
                      style={{
                        width: "300px",
                        height: "250px",
                        overflow: "hidden",
                      }}
                    >
                      <PieChart
                        fill={pie.fill}
                        config={{
                          innerRadius: "0.8",
                          padAngle: 1,
                          legends: false,
                          minWith: "380px",
                          margin: { right: 200, top: 20, left: 30 },
                          disableArcLink: true,
                          chart: { width: 420, height: 340 },
                        }}
                        colors={invAllocation.colors}
                        onClickEvent={{
                          click: pie_onClick2,
                          set: setInvAllocation,
                          cstate: invAllocation,
                          ncolors: invAllocationColors,
                        }}
                        data={unrealise_graphdata.graph}
                      >
                        <div className="cen" style={{ color: "#fff" }}>
                          <div>
                            <div>{ }</div>
                            <div>{ }</div>
                          </div>
                        </div>
                      </PieChart>

                      {ShowLegends(unrealise_graphdata.data, ["#B80000", "#82EBA1"])}
                    </div>
                    <Typography
                      style={{
                        textAlign: "center",
                        width: "243px",
                        color: "#9488A7",
                        position: "absolute",
                      }}
                      className={`${classes.label}`}
                    >
                      % Investment
                    </Typography>
                  </Grid>

                  <Grid
                    style={{ margin: "5% 3% 3% 0%" }}
                    zeroMinWidth
                    item
                    md={12}
                    xs={12}
                  >
                    <hr className="filter-divider"></hr>
                  </Grid>

                  <Grid
                    container
                    style={{ margin: "5% 3% 3% 5%" }}
                    zeroMinWidth
                    item
                    md={5}
                    xs={12}
                  >
                    <Typography className={classes.GainDetailsHeadingText}>
                      Highest Profit
                    </Typography>
                    <Grid
                      style={{ margin: "3% 3% 3% 0%" }}
                      zeroMinWidth
                      item
                    // md={12}
                    // xs={12}
                    >
                      {gainloss &&
                        disp_gain_loss(
                          gainloss?.unrealisedgain?.gaining_stock_data
                        )}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    style={{ margin: "5% 3% 3% 5%" }}
                    zeroMinWidth
                    item
                  md={5}
                  xs={12}
                  >
                    <Typography
                      className={classes.GainDetailsHeadingText}
                      style={{ marginBottom: "20px" }}
                    >
                      Highest Loss
                    </Typography>
                    <Grid
                      style={{ margin: "3% 3% 3% 0%" }}
                      zeroMinWidth
                      item
                    md={12}
                    xs={12}
                    >
                      {gainloss &&
                        disp_gain_loss(
                          gainloss?.unrealisedgain?.loosing_stock_data,
                          "loss"
                        )}
                    </Grid>
                  </Grid>

                </Grid>
              </div>
            </div>
          </Grid>

        </Grid> */}

        {/* new comp */}

        <div style={{ display: "flex" }}>
          {/* left content */}
          <div style={{ width: "50%" }}>
            <div className={classes.mainContent}>
              <div style={{ display: "flex", padding: "20px" }}>
                <div style={{ width: "50%" }}>
                  {/* left top header */}
                  <div>
                    <Typography
                      style={{
                        marginTop: "2%",
                        marginLeft: "2%",
                        fontSize: "1.49rem",
                      }}
                      className={`${classes.riskHeaderSub}`}
                    >
                      Today’s Gain/Loss
                    </Typography>
                    <Typography
                      style={{ marginLeft: "2%", color: "#9488A7" }}
                      className={`${classes.label}`}
                    >
                      as on {gainloss?.todaysgain?.date}
                    </Typography>
                  </div>

                  {/* left top graph */}
                  <div
                    style={{
                      width: "300px",
                      height: "250px",
                      overflow: "hidden",
                    }}
                  >
                    <PieChart
                      fill={pie.fill}
                      config={{
                        innerRadius: "0.80",
                        cornerRadius: 0,
                        padAngle: 0,
                        legends: false,
                        minWith: "380px",
                        margin: { right: 200, top: 20, left: 30 },
                        disableArcLink: true,
                        chart: { width: 420, height: 340 },
                      }}
                      colors={invAllocation.colors}
                      onClickEvent={{
                        click: pie_onClick2,
                        set: setGainState,
                        cstate: gainstate,
                        ncolors: invAllocationColors,
                      }}
                      data={realised_graphdat.graph}
                    >
                      <div className="cen" style={{ color: "#fff" }}>
                        <div>
                          <div>{}</div>
                          <div>
                            {gainstate.value
                              ? roundNumber(gainstate.value) + "%"
                              : ""}
                          </div>
                        </div>
                      </div>
                    </PieChart>
                    {ShowLegends(realised_graphdat.data, [
                      "#82EBA1",
                      "#B80000",
                    ])}
                  </div>
                  <Typography
                    style={{
                      textAlign: "center",
                      width: "243px",
                      color: "#9488A7",
                      position: "absolute",
                    }}
                    className={`${classes.label}`}
                  >
                    % Investment
                  </Typography>
                </div>

                <div style={{ width: "50%", marginTop: "20%" }}>
                  {ShowLegends(realised_graphdat.data, ["#82EBA1", "#B80000"])}
                </div>
              </div>

              <hr style={{ margin: "5% 0" }} className="filter-divider"></hr>

              <div style={{ display: "flex", padding: "20px" }}>
                <div style={{ width: "50%" }}>
                  <div>
                    <Typography
                      className={classes.GainDetailsHeadingText}
                      style={{ marginBottom: "20px" }}
                    >
                      Gaining Stocks
                    </Typography>
                  </div>

                  <div>
                    {gainloss &&
                      disp_gain_loss(gainloss?.todaysgain?.gaining_stock_data)}
                  </div>
                </div>

                <div style={{ width: "50%" }}>
                  <div>
                    <Typography
                      className={classes.GainDetailsHeadingText}
                      style={{ marginBottom: "20px" }}
                    >
                      Loss Stocks
                    </Typography>
                  </div>

                  <div>
                    {gainloss &&
                      disp_gain_loss(
                        gainloss?.todaysgain?.loosing_stock_data,
                        "loss"
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* right content */}
          <div style={{ width: "50%" }}>
            <div className={classes.mainContent}>
              <div style={{ display: "flex", padding: "20px" }}>
                <div style={{ width: "50%" }}>
                  <div>
                    <Typography
                      style={{
                        marginTop: "2%",
                        marginLeft: "2%",
                        fontSize: "1.49rem",
                      }}
                      className={`${classes.riskHeaderSub}`}
                    >
                      Unrealized Gain/Loss
                    </Typography>
                    <Typography
                      style={{ marginLeft: "2%", color: "#9488A7" }}
                      className={`${classes.label}`}
                    >
                      as on {gainloss?.unrealisedgain?.date}
                    </Typography>
                  </div>

                  <div
                    style={{
                      width: "300px",
                      height: "250px",
                      overflow: "hidden",
                    }}
                  >
                    <PieChart
                      fill={pie.fill}
                      config={{
                        innerRadius: "0.80",
                        cornerRadius: 0,
                        padAngle: 0,
                        legends: false,
                        minWith: "380px",
                        margin: { right: 200, top: 20, left: 30 },
                        disableArcLink: true,
                        chart: { width: 420, height: 340 },
                      }}
                      colors={invAllocation.colors}
                      onClickEvent={{
                        click: pie_onClick2,
                        set: setInvAllocation,
                        cstate: invAllocation,
                        ncolors: invAllocationColors,
                      }}
                      data={unrealise_graphdata.graph}
                    >
                      <div className="cen" style={{ color: "#fff" }}>
                        <div>
                          <div>{}</div>
                          <div>
                            {invAllocation.value
                              ? roundNumber(invAllocation.value) + " %"
                              : ""}
                          </div>
                        </div>
                      </div>
                    </PieChart>
                  </div>
                  <Typography
                    style={{
                      textAlign: "center",
                      width: "243px",
                      color: "#9488A7",
                      position: "absolute",
                    }}
                    className={`${classes.label}`}
                  >
                    % Investment
                  </Typography>
                </div>

                <div style={{ width: "50%", marginTop: "20%" }}>
                  <div>
                    {ShowLegends(unrealise_graphdata.data, [
                      "#82EBA1",
                      "#B80000",
                    ])}
                  </div>
                </div>
              </div>

              <hr style={{ margin: "5% 0" }} className="filter-divider"></hr>

              <div style={{ display: "flex", padding: "20px" }}>
                <div style={{ width: "50%" }}>
                  <div>
                    <Typography
                      style={{ marginBottom: "20px" }}
                      className={classes.GainDetailsHeadingText}
                    >
                      Highest Profit
                    </Typography>
                  </div>

                  <div>
                    {gainloss &&
                      disp_gain_loss(
                        gainloss?.unrealisedgain?.gaining_stock_data
                      )}
                  </div>
                </div>

                <div style={{ width: "50%" }}>
                  <div>
                    <Typography
                      className={classes.GainDetailsHeadingText}
                      style={{ marginBottom: "20px" }}
                    >
                      Highest Loss
                    </Typography>
                  </div>

                  <div>
                    {gainloss &&
                      disp_gain_loss(
                        gainloss?.unrealisedgain?.loosing_stock_data,
                        "loss"
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PageLoader showLoader={loading}></PageLoader>
    </div>
  );
}
