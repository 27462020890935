import React, { useEffect, useState } from "react";
import Default from "../../defaultPages/Default";
import PageFilter from "../common/PageFilter";
import BondsHeading from "./BondsHeading";
import { Card, CardContent, Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useStyles from "../common/ProductsStyles";
import { dateFormatCommon, roundNumber } from "../../../utils/common";
import { currencyFormat } from "../../../../hooks/constantFunction";
import { ReactComponent as PieOutline } from "../../../../assets/icons/pieOutline.svg";
import { ReactComponent as InnerCircle } from "../../../../assets/icons/innercircle.svg";
import { ReactComponent as Circlesvg } from "../../../../assets/icons/circlesvg.svg";
import { ReactComponent as Pointer } from "../../../../assets/icons/pointer.svg";
import { routes } from "../../../../routes";
export default function Corporatebond({ data, apiData2, isin }) {
  const classes = useStyles();
  const [meterData, setMeterData] = useState(null);
  const Bondrisk = {
    "AAA+": "105deg",
    AAA: "76deg",
    "AA+": "61deg",
    AA: "45deg",
    "AA-": "22deg",
    "A+": "0deg",
    A: "-42deg",
    "A-": "-65deg",
    "BBB+": "61deg",
    BBB: "78.5deg",
    BB: "-129deg",
    "BBB-": "89deg",
    "BB+": "-100deg",
    B: "-132deg",
    "B-": "137deg",
    "CCC+": "-132deg",
    CCC: "-132deg",
    "CCC-": "-132deg",
    CC: "-132deg",
    C: "-132deg",
  };
  useEffect(() => {
    setMeterData(checkRating());
  }, []);

  let timeline = {
    year: "2021",
    date: "21 Dec",
    invest: "",
    data: [
      { year: "2022", date: "", intrest: "" },
      { year: "2023", date: "", intrest: "" },
      { year: "2024", date: "", intrest: "" },
    ],
  };

  function rateCheck(str) {
    if (str.indexOf("AAA+") >= 0) {
      return "AAA+";
    } else if (str.indexOf("AAA") >= 0) {
      return "AAA";
    } else if (str.indexOf("AA+") >= 0) {
      return "AA+";
    } else if (str.indexOf("AA-") >= 0) {
      return "AA-";
    } else if (str.indexOf("AA") >= 0) {
      return "AA";
    } else if (str.indexOf("A+") >= 0) {
      return "A+";
    } else if (str.indexOf("A-") >= 0) {
      return "A-";
    } else if (str.indexOf("A") >= 0) {
      return "A";
    } else if (str.indexOf("BBB+") >= 0) {
      return "BBB+";
    } else if (str.indexOf("BBB-") >= 0) {
      return "BBB-";
    } else if (str.indexOf("BBB") >= 0) {
      return "BBB";
    } else if (str.indexOf("BB+") >= 0) {
      return "BB+";
    } else if (str.indexOf("BB") >= 0) {
      return "BB";
    } else if (str.indexOf("B") >= 0) {
      return "B";
    } else if (str.indexOf("B-") >= 0) {
      return "B-";
    } else if (str.indexOf("CCC+") >= 0) {
      return "CCC+";
    } else if (str.indexOf("CCC-") >= 0) {
      return "CCC-";
    } else if (str.indexOf("CCC") >= 0) {
      return "CCC";
    } else if (str.indexOf("CC") >= 0) {
      return "CC";
    } else if (str.indexOf("C") >= 0) {
      return "C";
    }
  }

  function checkRating() {
    if (
      data.ratingProvidedByCrisil &&
      data.ratingProvidedByCrisil != "" &&
      Bondrisk[
        rateCheck(
          data.ratingProvidedByCrisil.indexOf("/") != -1
            ? data.ratingProvidedByCrisil.substring(
                0,
                data.ratingProvidedByCrisil.indexOf("/")
              )
            : data.ratingProvidedByCrisil.replace("L", "")
        )
      ]
    ) {
      console.log(
        Bondrisk[
          data.ratingProvidedByCrisil.indexOf("/") != -1
            ? data.ratingProvidedByCrisil.substring(
                0,
                data.ratingProvidedByCrisil.indexOf("/")
              )
            : data.ratingProvidedByCrisil.replace("L", "")
        ],
        data.ratingProvidedByCrisil.indexOf("/") != -1
          ? data.ratingProvidedByCrisil.substring(
              0,
              data.ratingProvidedByCrisil.indexOf("/")
            )
          : data.ratingProvidedByCrisil
      );
      return {
        type: "CRISIL",
        deg: Bondrisk[
          rateCheck(
            data.ratingProvidedByCrisil.indexOf("/") != -1
              ? data.ratingProvidedByCrisil.substring(
                  0,
                  data.ratingProvidedByCrisil.indexOf("/")
                )
              : data.ratingProvidedByCrisil.replace("L", "")
          )
        ],
        extra:
          data.ratingProvidedByCrisil.indexOf("/") != -1
            ? data.ratingProvidedByCrisil.substring(
                data.ratingProvidedByCrisil.indexOf("/") + 1
              )
            : "",
        rating: rateCheck(data.ratingProvidedByCrisil),
      };
    } else if (
      data.ratingProvidedByCare &&
      data.ratingProvidedByCare != "" &&
      Bondrisk[rateCheck(data.ratingProvidedByCare)]
    ) {
      console.log(
        Bondrisk[
          data.ratingProvidedByCare.indexOf("/") != -1
            ? data.ratingProvidedByCare.substring(
                0,
                data.ratingProvidedByCare.indexOf("/")
              )
            : data.ratingProvidedByCare
        ],
        "data.ratingProvidedByCare"
      );
      return {
        type: "CARE",
        deg: Bondrisk[
          rateCheck(
            data.ratingProvidedByCare.indexOf("/") != -1
              ? data.ratingProvidedByCare.substring(
                  0,
                  data.ratingProvidedByCare.indexOf("/")
                )
              : data.ratingProvidedByCare
          )
        ],
        extra:
          data.ratingProvidedByCare.indexOf("/") != -1
            ? data.ratingProvidedByCare.substring(
                data.ratingProvidedByCare.indexOf("/") + 1
              )
            : "",
        rating: rateCheck(data.ratingProvidedByCare),
      };
    } else if (
      data.ratingProvidedByICRA &&
      data.ratingProvidedByICRA != "" &&
      Bondrisk[
        rateCheck(
          data.ratingProvidedByICRA.indexOf("/") != -1
            ? data.ratingProvidedByICRA.substring(
                0,
                data.ratingProvidedByICRA.indexOf("/")
              )
            : data.ratingProvidedByICRA
        )
      ]
    ) {
      return {
        type: "ICRA",
        deg: Bondrisk[
          rateCheck(
            data.ratingProvidedByICRA.indexOf("/") != -1
              ? data.ratingProvidedByICRA.substring(
                  0,
                  data.ratingProvidedByICRA.indexOf("/")
                )
              : data.ratingProvidedByICRA
          )
        ],
        extra:
          data.ratingProvidedByICRA.indexOf("/") != -1
            ? data.ratingProvidedByICRA.substring(
                data.ratingProvidedByICRA.indexOf("/") + 1
              )
            : "",
        rating: rateCheck(data.ratingProvidedByICRA),
      };
    } else {
      return null;
    }
  }
  return (
    <>
      <Default>
        <PageFilter backLink={routes.bondsSummaryPage_transection} />
        <BondsHeading title={data.companyName} isin={isin} />
        <div className={classes.ipoBoxes}>
          <div style={{ display: "flex", padding: "2%" }}>
            <div>
              <div className={classes.tableHeaderTextNew}>Issue Price</div>
              <div className={classes.bondsText}>
                {data?.issPrice ? currencyFormat(data?.issPrice) : "..."}
              </div>
              <div className={classes.tableHeaderTextNew}></div>
            </div>
            <hr className="filter-divider"></hr>
            {/* <div>
              <div className={classes.tableHeaderTextNew}>YTC</div>
              <div className={classes.bondsText}>...</div>
              <div className={classes.tableHeaderTextNew}>
                Coupon{" "}
                <span style={{ color: "#F7CB59" }}>
                  {roundNumber(data.couponRate)}% p.a
                </span>
              </div>
            </div> */}
            <div>
              <div className={classes.tableHeaderTextNew}>Issue Size</div>
              <div className={classes.bondsText}>
                {" "}
                {data?.cashFlow ? data.cashFlow : "..."}
              </div>
              {/* <div className={classes.tableHeaderTextNew}>
                Coupon{" "}
                <span style={{ color: "#F7CB59" }}>
                  {roundNumber(data.couponRate)}% p.a
                </span>
              </div> */}
            </div>
            <hr className="filter-divider"></hr>
            <div>
              <div className={classes.tableHeaderTextNew}>Face Value</div>
              <div className={classes.bondsText}>
                {data.faceValue ? data.faceValue : "..."}
              </div>
              <div className={classes.tableHeaderTextNew}>
                <span style={{ color: "#F7CB59" }}> </span>
                <br />
              </div>
            </div>
            <hr className="filter-divider"></hr>
            <div style={{ alignContent: "center" }}>
              <div className={classes.tableHeaderTextNew}>Coupon Rate</div>
              <div className={classes.bondsText}>
                {" "}
                {roundNumber(data.couponRate)}%{" "}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.ipoBoxes}>
          <div style={{ display: "flex", padding: "2%" }}>
            <div>
              <div className={classes.tableHeaderTextNew}>Allotment Date</div>
              <div className={classes.bondsText}>
                {data?.issueDate
                  ? dateFormatCommon(data?.issueDate, "YYYY-MM-DD")
                  : "..."}
              </div>
              <div className={classes.tableHeaderTextNew}></div>
            </div>
            <hr className="filter-divider"></hr>
            <div>
              <div className={classes.tableHeaderTextNew}>Payment Term</div>
              <div className={classes.bondsText}>
                {data?.intFrequency ? data.intFrequency : "..."}
              </div>
              <div className={classes.tableHeaderTextNew}></div>
            </div>
            <hr className="filter-divider"></hr>
            <div>
              <div className={classes.tableHeaderTextNew}>Tenor</div>
              <div className={classes.bondsText}>
                {data?.tenor ? data?.tenor : "..."}
              </div>
              <div className={classes.tableHeaderTextNew}>
                <span style={{ color: "#F7CB59" }}> </span>
                <br />
              </div>
            </div>
            <hr className="filter-divider"></hr>
            <div style={{ alignContent: "center" }}>
              <div className={classes.tableHeaderTextNew}>Maturity Date</div>
              <div className={classes.bondsText}>
                {data?.maturityDate
                  ? dateFormatCommon(data.maturityDate, "YYYY-MM-DD")
                  : "..."}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.ipoSecondBoxes}>
          <div style={{ marginLeft: "2%" }} className={classes.cardTextSmall}>
            Bonds Rating
          </div>
          <div
            style={{
              display: "flex",
              padding: "1%",
              paddingBottom: "6%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              <div className={classes.tableHeaderTextNew}></div>

              {meterData ? (
                <div
                  style={{
                    position: "relative",
                    height: "209px",
                    marginRight: "78px",
                  }}
                  className={classes.bondsText}
                >
                  <div style={{ position: "absolute" }}>
                    <PieOutline style={{ width: "280px", heigt: "80px" }} />
                  </div>
                  <div>
                    <div
                      style={{
                        position: "absolute",
                        top: "86px",
                        left: "97px",
                        rotate: meterData.deg ? meterData.deg : "0deg",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Pointer
                        style={{
                          width: "41px",
                          heigt: "80px",
                          transform:
                            "translate(-1px, -49px) rotate(4deg) rotate(-4deg) ",
                        }}
                      />
                    </div>
                    <Circlesvg
                      style={{
                        width: "280px",
                        heigt: "200px",
                        transform: "translate(0px, -4px)",
                      }}
                    ></Circlesvg>
                    <div
                      className="circle_text2"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "25.5%",
                        width: "96px",
                        textAlign: "center",
                      }}
                    >
                      {meterData.rating ? meterData.rating : ""}
                      <div
                        className="circle_text"
                        style={{
                          textAlign: "center",
                          marginLeft: "-5px",
                          fontSize: "9px",
                        }}
                      >
                        {meterData.type}{" "}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className={classes.tableHeaderTextNew}></div>
            </div>
            <div className="TopBottomAuto">
              <div className={classes.tableHeaderTextNew}>
                {" "}
                {meterData?.extra}
              </div>
              <div
                style={{ height: "32px" }}
                className={classes.bondsText + " green"}
              >
                {meterData?.rating && meterData?.rating.indexOf("/") != -1
                  ? meterData?.rating.substring(
                      0,
                      meterData?.rating.indexOf("/")
                    )
                  : meterData?.rating}
              </div>
              <div className={classes.tableHeaderTextNew + " subtext"}>
                {meterData?.type ? meterData?.type : " "}
              </div>
            </div>
            {/* <hr className="filter-divider"></hr>
            <div className="TopBottomAuto">
              <div className={classes.tableHeaderTextNew}>Stable</div>
              <div
                style={{ height: "32px" }}
                className={classes.bondsText + " green"}
              >
                {data.ratingProvidedByCrisil
                  ? data.ratingProvidedByCrisil
                  : "..."}
              </div>
              <div className={classes.tableHeaderTextNew + " subtext"}>
                CRISIL
              </div>
            </div>
            <hr className="filter-divider"></hr>
            <div className="TopBottomAuto">
              <div className={classes.tableHeaderTextNew}>Stable</div>
              <div
                style={{ height: "32px" }}
                className={classes.bondsText + " green"}
              >
                {data.ratingProvidedByCare ? data.ratingProvidedByCare : "..."}{" "}
              </div>
              <div className={classes.tableHeaderTextNew + " subtext"}>
                CARE
              </div>
            </div>
            <hr className="filter-divider"></hr>
            <div className="TopBottomAuto">
              <div className={classes.tableHeaderTextNew}>Stable</div>
              <div
                style={{ height: "32px" }}
                className={classes.bondsText + " green"}
              >
                {data.ratingProvidedByICRA ? data.ratingProvidedByICRA : "..."}{" "}
              </div>
              <div className={classes.tableHeaderTextNew + " subtext"}>
                ICRA
              </div>
            </div> */}
          </div>
        </div>
        <div style={{ paddingTop: "25px" }} className={classes.ipoBoxes}>
          <div style={{ marginLeft: "2%" }} className={classes.cardTextSmall}>
            Cashflow
          </div>
          <div
            style={{
              display: "flex",

              paddingBottom: "8%",
              paddingTop: "5%",
              paddingLeft: "2%",
              paddingRight: "2%",
              alignItems: "center",
              justifyContent: "center",
              overflowX: "auto",
            }}
          >
            <div style={{ color: "white", width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      minWidth: "100px",
                      maxWidth: "auto",
                      height: "2px",
                      backgroundColor: "#4E189F",
                    }}
                  ></div>
                  <div
                    className="subtext"
                    style={{ position: "absolute", left: "97%", top: "-20px" }}
                  >
                    {timeline.year}
                  </div>
                  <div
                    style={{
                      width: "8px",
                      height: "8px",
                      borderRadius: "50%",
                      backgroundColor: "blue",
                    }}
                  ></div>
                  <div
                    className="circle_text"
                    style={{
                      position: "absolute",
                      left: "97%",
                      top: "15px",
                      width: "90px",
                    }}
                  >
                    {timeline.date}
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      left: "97%",
                      top: "40px",
                      color: "#fff",
                    }}
                  >
                    <div
                      className="circle_text"
                      style={{ color: "#7B61FF", width: "120px" }}
                    >
                      Your Investment of
                    </div>
                    <div className="circle_text2">₹1,03,03,386.30</div>
                  </div>
                </div>
                <div style={{ width: "100%", display: "flex" }}>
                  {timeline.data.map((t) => {
                    return (
                      <div style={{ flex: "1" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            position: "relative",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              minWidth: "140px",
                              height: "6px",
                              backgroundColor: "#4E189F",
                            }}
                          ></div>
                          <div
                            className="subtext"
                            style={{
                              position: "absolute",
                              left: "97%",
                              top: "-20px",
                            }}
                          >
                            {t.year}
                          </div>
                          <div
                            style={{
                              width: "8px",
                              height: "8px",
                              borderRadius: "50%",
                              background:
                                "linear-gradient(90deg, #F7CB59 0%, #FF9446 100%)",
                            }}
                          ></div>
                          <div
                            className="circle_text"
                            style={{
                              position: "absolute",
                              left: "97%",
                              top: "15px",
                              width: "90px",
                            }}
                          >
                            08 Sep
                          </div>

                          <div
                            style={{
                              position: "absolute",
                              left: "97%",
                              top: "40px",
                              color: "#fff",
                            }}
                          >
                            <div
                              className="circle_text"
                              style={{ color: "#F7CB59", width: "120px" }}
                            >
                              Interest
                            </div>
                            <div className="circle_text2">₹7,84,000.00</div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      minWidth: "140px",
                      maxWidth: "auto",
                      height: "6px",
                      backgroundColor: "#4E189F",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className={classes.ipoBoxes}>
          <div className={classes.bondsHeadText} style={{ padding: "2%" }}>Cashflow</div>

          <div style={{ display: "flex", padding: "2%" }}>
            <div style={{ display: "flex", justifyContent: "center", margin: "1%", width: "18%", padding: "1%" }} className={classes.tableHeaderTextNew}>2022</div>
            <div style={{ display: "flex", justifyContent: "center", margin: "1%", width: "18%", padding: "1%" }} className={classes.tableHeaderTextNew}>2023</div>
            <div style={{ display: "flex", justifyContent: "center", margin: "1%", width: "18%", padding: "1%" }} className={classes.tableHeaderTextNew}>2025</div>
            <div style={{ display: "flex", justifyContent: "center", margin: "1%", width: "18%", padding: "1%" }} className={classes.tableHeaderTextNew}>2035</div>
            <div style={{ display: "flex", justifyContent: "center", margin: "1%", width: "18%", padding: "1%" }} className={classes.tableHeaderTextNew}>2059</div>
          </div>

          <div style={{ marginTop: "-3%", padding: "2%" }}>
            <hr className='equityipofilter-divider' style={{}}></hr>
          </div>

          <div style={{ display: "flex", padding: "1%" }}>
            <div style={{ display: "flex", justifyContent: "center", margin: "1%", width: "18%", padding: "1%" }} className={classes.tableHeaderTextNew}>21 Dec</div>
            <div style={{ display: "flex", justifyContent: "center", margin: "1%", width: "18%", padding: "1%" }} className={classes.tableHeaderTextNew}>21 Dec</div>
            <div style={{ display: "flex", justifyContent: "center", margin: "1%", width: "18%", padding: "1%" }} className={classes.tableHeaderTextNew}>21 Dec</div>
            <div style={{ display: "flex", justifyContent: "center", margin: "1%", width: "18%", padding: "1%" }} className={classes.tableHeaderTextNew}>21 Dec</div>
            <div style={{ display: "flex", justifyContent: "center", margin: "1%", width: "18%", padding: "1%" }} className={classes.tableHeaderTextNew}>21 Dec</div>
          </div>

          <div style={{ display: "flex", padding: "1%" }}>
            <div style={{ margin: "1%", width: "18%", padding: "1%" }}>
              <div className={classes.bondsTextNew} style={{ display: "flex", justifyContent: "center", color: "#7B61FF" }}>Your Investment of</div>
              <div className={classes.bondsText} style={{ display: "flex", justifyContent: "center", whiteSpace: "nowrap" }}>₹1,03,03,386.30</div>
            </div>
            <div style={{ margin: "1%", width: "18%", padding: "1%" }}>
              <div className={classes.bondsTextNew} style={{ display: "flex", justifyContent: "center", color: "#F7CB59" }}>Interest</div>
              <div className={classes.bondsText} style={{ display: "flex", justifyContent: "center" }}>₹7,84,000.00</div>
            </div>
            <div style={{ margin: "1%", width: "18%", padding: "1%" }}>
              <div className={classes.bondsTextNew} style={{ display: "flex", justifyContent: "center", color: "#F7CB59" }}>Interest</div>
              <div className={classes.bondsText} style={{ display: "flex", justifyContent: "center" }}>₹7,84,000.00</div>
            </div>
            <div style={{ margin: "1%", width: "18%", padding: "1%" }}>
              <div className={classes.bondsTextNew} style={{ display: "flex", justifyContent: "center", color: "#F7CB59" }}>Interest</div>
              <div className={classes.bondsText} style={{ display: "flex", justifyContent: "center" }}>₹7,84,000.00</div>
            </div>
            <div style={{ margin: "1%", width: "18%", padding: "1%" }}>
              <div className={classes.bondsTextNew} style={{ display: "flex", justifyContent: "center", color: "#F7CB59" }}>Interest</div>
              <div className={classes.bondsText} style={{ display: "flex", justifyContent: "center" }}>₹7,84,000.00</div>
            </div>
          </div>
        </div> */}
      </Default>
    </>
  );
}
