import React from 'react'
import VerifiyCard from './VerifiyCard';
import PendingCard from './PendingCard';
import RejectCard from './RejectCard';
import { Grid, Typography } from '@material-ui/core';
import { saveAs } from "file-saver";
import _ from 'lodash'
import {makeStyles} from '@material-ui/core/styles'
import { OnboardingContext } from '../../Context';

const useStyles = makeStyles( (theme) =>({
    emptyList:{
        justifyContent: 'center',
        marginTop: '10%'
    }

}))

function UploadContent({content, showLoader, formData}) {
    const classes = useStyles();
    const {userState:{UploadFilesDataForVerify}} = OnboardingContext(); 
    
    return (
          <Grid container className={content?.length === 0 ? classes.emptyList : ''}>
          {content?.length !== 0 ? content?.map( (singleContent) => (
              <Grid key={singleContent.id} item xs={12} sm={6} md={4} lg={3}>
                {singleContent.status === 'pending' &&
                  <PendingCard formDataEntityAndType={formData} singleContent={singleContent}></PendingCard>
                }
                {singleContent.status === 'approved' &&
                  <VerifiyCard singleContent={singleContent}></VerifiyCard>
                }
                {singleContent.status === 'rejected' &&
                  <RejectCard singleContent={singleContent}></RejectCard>
                }
              </Grid>
              
          ))
              :
              <Typography style={{color:'#830808'}}>
                {showLoader ? '' : 'List not found!'}
              </Typography>
          }
             
          </Grid>
    )
}

export default UploadContent