// var CryptoJS = require("crypto-js");
import React, { useRef } from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import Overall_portfolio from "./pages/Overall_portfolio";
import Portfolio_Performance from "./pages/Portfolio_Performance";
import TopPerformer from "./pages/TopPerformer";
import BottomPerformer from "./pages/BottomPerformer";
import Top5issues from "./pages/Top5issues";
import Top5amc from "./pages/Top5amc";
import Alternate_sub_asset from "./pages/Alternate_sub_asset";
import PerformanceSummary1v2 from "./pages/PerformanceSummary1v2";
import PerformanceSummary2v2 from "./pages/PerformanceSummary2v2";
import HoldingSummaryDirectEquity from "./pages/HoldingSummaryDirectEquity";
import Engagement_model from "./pages/Engagement_model";
import Equity_summary from "./pages/Equity_summary";
import Performance_key_indices from "./pages/Performance_key_indices";
import Performance_key_indices1 from "./pages/Performance_key_indices1";
import Market_snapshot from "./pages/Market_snapshot";
import { StackTable } from "./Components/StackTable";
import { AssetSubClass } from "./Components/AssetSubClass";
import { FirstPage } from "./Components/FirstPage";
import { LastPage } from "./Components/LastPage";
import EquitySummary from "./pages/EquitySummary";
import EquityTop5Stock from "./pages/EquityTop5Stock";
import Alternates from "./pages/Alternates";
import IncomeCashFlowCorpusExport from "./pages/IncomeCashFlowCorpusExport";
import RiskProfile from "./pages/RiskProfile";
import ReportingHierarchy from "./pages/ReportingHierarchy";
import Disclaimers from "./pages/Disclaimers";
import Disclaimer from "./pages/Disclaimer";
import { useApi } from "./hook/useApi";
import { useEffect } from "react";
import PerformanceSummary from "./pages/PeroformanceSummary";
import PortfolioAllocation from "./pages/PortfolioAllocation";
import EquityMF from "./pages/EquityMF";
import { useLocation, useSearchParams, useParams } from "react-router-dom";
import { alternateStaticData } from "./pages/utils";
import { useState } from "react";
import PageLoader from "../products/common/pageLoader";
import {
  EquityMFHolding,
  corpusTableHeader,
  corpusTableHeader2,
  top5amc,
  top5issuer,
} from "./Components/utils/staticData";
import EquityTop5Sectors from "./pages/EquityTop5Sectors";
import useWindowSize from "./hook/seWindowSize";
import CashFlow from "./pages/CashFlow";
import { Decrypt } from "./Components/utils/common";
import useGetMongoPrintReport from "../../../hooks/useGetMongoPrintReport";
import { getPrintDataMongo } from "../../../api";
import Asset_allocation from "./pages/Asset_allocation";
import Top5pdf from "./pages/Top5pdf";
import CashFlowNew from "./pages/CashFlowNew";
import Member_wise from "./pages/Member_wise";
import Product_Allocation from "./pages/Product_Allocation";
import Top3performer from "./pages/top3Performer";
import HoldingPdfPage from "./pages/HoldingPdfPage";
import ThankYouPage from "./pages/ThankYouPdf";
import AssetvsBenchmark from "./pages/AssetvsBenchmark";
import LoadingScreen from "./pages/LoadingScreen";
import FundInTransit from "./pages/FundInTransit";
import Advisor from "./pages/Advisor";
import TopBottomPerformer from "./pages/TopBottomPerformer";
import CreditRating from "./pages/CreditRating";
import EquitySectorAllocationPdf from "./pages/EquitySectorAllocationPdf";
import MarketCap from "./pages/MarketCap";
import SubCategoryContainer from "./pages/SubCategoryContainer";
import { CoverPage } from "./pages/subPrintPages/CoverPage";
import Annexures from "./pages/Annexures";
import { OnboardingContext } from "../../../Context";
// import Tax from "./pages/Tax";
// import TaxModule from "./pages/TaxModule";
// import TaxSort from "./pages/TaxSort";

export default function Pdf({ mongo, handleUploadPDF, download }) {
  const { dispatch } = OnboardingContext();
  const [browserSize, setBrowserSize] = useState(
    ((window.outerWidth - 10) / window.innerWidth) * 100
  );
  // const {
  //   state: { mongoId },
  // } = useLocation();
  // const {} = useSearchParams();
  let [searchParams, setSearchParams] = useSearchParams();
  // let denomination = "Lakhs";
  const mongoId = searchParams.get("id");
  const parentRef = useRef(null);
  // const { type, familyMembers, duration, denomination, mongoId } = Decrypt(
  //   searchParams.get("key")
  // );
  const windowSize = useWindowSize();
  const [changes, setChanges] = useState();
  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "#E4E4E4",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
  });
  //
  let payload = {};
  // if (familyMembers?.length > 0) {
  //   payload["memberId"] = familyMembers?.join(",");
  // }
  // if (duration?.startDate !== "") {
  //   payload["startDate"] = duration?.startDate;
  //   payload["endDate"] = duration?.endDate;
  // }
  // localStorage.setItem("denomination", denomination);
  const [data, setData] = useState({});
  const [reset, setReset] = useState(false);
  const {
    showLoader,
    setShowLoader,
    CallApis,
    errorMessage,
    customData,
    erroredAPIS,
    apiArray,
  } = useApi(payload, setReset);

  const { newData, denomination, meta, pdfInfo, loading } =
    useGetMongoPrintReport(
      // "658187af8a5d24635c5e0298"
      // // "65e00dea0cda3c000851f632"
      // "65e15f4b2fffc9727f32cb17"
      // "65e711971cd0cd00098d8f5e"

      // "65e8538e99eb3863be6ae12e"
      // "65f2a702ae19f10008f6aeb2"

      mongoId || mongo
    );
  // mongoId

  const getDenomination = localStorage.getItem("denomination");
  console.log("mongo", mongoId, newData);

  // useEffect(() => {
  //   // handleData();
  //   // if (getDenomination == denomination) {
  //   // setTimeout(() => {
  //   //   console.log(getDenomination, "asfjkbakjsbahf");
  //   CallApis(apiArray(payload).splice(0, 5), {}, 0, 3);
  //   // }, 5000);
  //   // }
  // }, []);

  // useEffect(() => {
  //   setData(() => customData);
  // }, [customData]);

  useEffect(() => {
    //for testing
    // Object?.keys(newData).map((a) => {
    //   newData[a] = "error";
    // });
    // if (changes) {

    if (browserSize < 99) {
      dispatch({
        type: "UPDATE_SNACKBAR_DATA",
        payload: {
          success: true,
          error: false,
          showMsg: true,
          message:
            "Please set Browser Zoom to 100% for better viewing of report",
        },
      });
    }
    let a = parentRef?.current?.querySelectorAll(".printPageNumber");
    if (!a) {
      return;
    }
    Array.from(a)?.map((val, i) => {
      val.innerHTML = `${i + 1}`;
    });
    // setNewPageList(a);
    // }

    const getData = setTimeout(() => {
      if (Object.keys(newData).length > 0 && download == 0) {
        handleUploadPDF();
      }
    }, 5000);

    return () => clearTimeout(getData);
  }, [changes, data, newData]);

  // console.log(Object?.keys(newData), "aglnj");

  return (
    <>
      {loading && (
        <>
          {/* <FirstPage type={"print"} pdfInfo={pdfInfo} /> */}
          <CoverPage
            type={"pdf"}
            title={"Performance Report"}
            meat={meta}
            pdfInfo={pdfInfo}
          />
          <LoadingScreen loading={loading} />
        </>
      )}
      {Object?.keys(newData)?.length > 0 && (
        <Document ref={parentRef}>
          {/* <button
        style={{
          position: "sticky",
          top: "10px",
          left: "10px",
          zIndex: "10",
        }}
        onClick={() => {
          CallApis(
            erroredAPIS.filter((val) => val !== undefined),
            data
          );
          if (erroredAPIS.filter((val) => val !== undefined).length > 0)
            setReset(true);
        }}
      >
        retry
      </button> */}
          {/* <PageLoader
        false={
          false == true
            ? false
            : erroredAPIS.flat(1).filter((val) => val !== undefined)?.length > 0
            ? true
            : false
        }
        hanldeError={() =>
          CallApis(
            erroredAPIS.flat(1).filter((val) => val !== undefined),
            data,
            0,
            5
          )
        }
        error={
          false == false &&
          erroredAPIS.flat(1).filter((val) => val !== undefined)?.length > 0
        }
      /> */}
          {/* <FirstPage type={"print"} pdfInfo={pdfInfo} /> */}
          <CoverPage
            type={"pdf"}
            title={"Performance Report"}
            meat={meta}
            pdfInfo={pdfInfo}
          />
          {/* <TaxSort
            denomination={denomination}
            newdata={newData}
            pdfInfo={pdfInfo}
            setChanges={setChanges}
          /> */}
          {/* <Page size="A4"> */}
          <Overall_portfolio
            newData={newData}
            denomination={denomination}
            meta={meta}
            // data={data}
            pdfInfo={pdfInfo}
          />
          {/* </Page> */}
          <AssetvsBenchmark
            denomination={denomination}
            data={newData || {}}
            title={"Excess Performance Summary"}
            datasrc={[
              "dashboard-asset-details",
              "dashboard-benchmark-absolute-return",
            ]}
            pdfInfo={pdfInfo}
          />
          <Member_wise
            denomination={denomination}
            data={newData?.["dashboard-member-wise-performance"] || {}}
            pdfInfo={pdfInfo}
          />
          <TopBottomPerformer
            denomination={denomination}
            data={newData || {}}
            title={"Top Performers"}
            datasrc="top-performers-by-contribution-"
            pdfInfo={pdfInfo}
            setChanges={setChanges}
          />
          <div className="newgraphPrint">
            <MarketCap
              denomination={denomination}
              data={newData?.["equity-market-cap"] || {}}
              title={"Market Cap"}
              mappedKey={{
                key1: "topFiveAMCs",
                key2: "amc",
                key3: "percentage",
                key4: "topFiveAmcValuation",
              }}
              tableconfig={top5amc}
              pdfInfo={pdfInfo}
            />
            <EquitySectorAllocationPdf
              denomination={denomination}
              setChanges={setChanges}
              newdata={newData?.["equity-sector-allocation"] || {}}
              pdfInfo={pdfInfo}
              // currentData={newData?.["dashboard-gain-loss"]?.portfolioValueAmount}
            />
            <SubCategoryContainer
              denomination={denomination}
              setChanges={setChanges}
              newdata={newData || {}}
              pdfInfo={pdfInfo}
              // currentData={newData?.["dashboard-gain-loss"]?.portfolioValueAmount}
            />
          </div>
          <CreditRating
            denomination={denomination}
            setChanges={setChanges}
            newdata={newData?.["bonds-credit-rating"]?.graphData || {}}
            pdfInfo={pdfInfo}
          />
          <div className="newgraphPrint">
            <Top5pdf
              denomination={denomination}
              data={newData?.["top-five-Amc"] || {}}
              title={"Top 5 AMC"}
              mappedKey={{
                key1: "topFiveAMCs",
                key2: "amc",
                key3: "percentage",
                key4: "topFiveAmcValuation",
              }}
              tableconfig={top5amc}
              pdfInfo={pdfInfo}
            />
            <Top5pdf
              denomination={denomination}
              data={newData?.["top-five-Issuer"] || {}}
              title={"Top 5 Issuers"}
              mappedKey={{
                key1: "topFiveIssuers",
                key2: "issuer",
                key3: "percentage",
                key4: "topFiveIssuerValuation",
              }}
              tableconfig={top5issuer}
              pdfInfo={pdfInfo}
            />
          </div>
          <Page size="A4">
            <PerformanceSummary1v2
              loading={false}
              setChanges={setChanges}
              customData={newData}
              title={"Drawdown Summary"}
              title2={"Drawdown Schedule"}
              // subtext={"REIT"}
              hideTotalRow={true}
              headerType={14}
              denomination={denomination}
              pdfInfo={pdfInfo}
            />
          </Page>
          {/* <CashFlowNew
            denomination={denomination}
            newdata={newData?.["cashflow-data"] || {}}
            pdfInfo={pdfInfo}
          /> */}
          {
            //taxsssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss
          }
          {/* <Page size="A4">
            <Portfolio_Performance
              denomination={denomination}
              data={newData}
              pdfInfo={pdfInfo}
            />
          </Page>
          <Asset_allocation
            denomination={denomination}
            newdata={newData?.["dashboard-asset-summary"] || {}}
            pdfInfo={pdfInfo}
            reportType={"holding"}
          />
          <Product_Allocation
            denomination={denomination}
            data={newData?.["dashboard-product-wise-allocation"] || {}}
            pdfInfo={pdfInfo}
          />
          {Object.keys(newData?.["engagement-model"])?.length > 0 && (
            <Engagement_model
              denomination={denomination}
              data={newData?.["engagement-model"] || {}}
              pdfInfo={pdfInfo}
            />
          )}

          <Top3performer
            denomination={denomination}
            data={newData || {}}
            title={"Top Performers"}
            datasrc="top-performers-"
            pdfInfo={pdfInfo}
            setChanges={setChanges}
          />
          <Top3performer
            denomination={denomination}
            data={newData || {}}
            title={"Bottom Performers"}
            datasrc="bottom-performers-"
            pdfInfo={pdfInfo}
            setChanges={setChanges}
          />
         
          <FundInTransit
            setChanges={setChanges}
            denomination={denomination}
            pdfInfo={pdfInfo}
            data={
              newData?.["transaction-summary"]?.[0]?.products?.[
                "Funds In Transit"
              ]
            }
          />
          <Advisor
            denomination={denomination}
            data={newData?.["advisor-wise-portfolio-distribution"]}
            pdfInfo={pdfInfo}
            setChanges={setChanges}
          />
     
          <Member_wise
            denomination={denomination}
            data={newData?.["dashboard-member-wise-performance"] || {}}
            pdfInfo={pdfInfo}
          />
          <HoldingPdfPage
            newData={newData?.transactionSummary}
            denomination={denomination}
            pdfInfo={pdfInfo}
            breadcrum="Transaction Summary"
            reportType={"transactionSummary"}
            setChanges={setChanges}
          />
          <HoldingPdfPage
            newData={newData?.["holding-summary"]}
            denomination={denomination}
            pdfInfo={pdfInfo}
            reportType={"executiveHolding"}
            setChanges={setChanges}
          /> */}
          {/* the above are all new pdf components that have to be displayed */}
          {/* {newData["top-five-Issuer"]?.topFiveIssuer?.length > 0 && (
            <Page size="A4">
              <Top5issues
                denomination={denomination}
                data={newData}
                windowSize={windowSize}
              />
            </Page>
          )}
          {newData["top-five-Amc"]?.topFiveAmc?.length > 0 && (
            <Page size="A4">
              <Top5amc
                denomination={denomination}
                // data={data}
                data={newData}
                windowSize={windowSize}
              />
            </Page>
          )} */}

          {/* <Page size="A4">
            <PortfolioAllocation
              denomination={denomination}
              loading={false}
              // customData={data}
              newData={newData}
            />
          </Page> */}
          {/* <Page size="A4">
            <EquitySummary newData={newData} denomination={denomination} />
          </Page> */}
          {/* <Page size="A4">
            <EquityTop5Stock
              newData={newData}
              denomination={denomination}
              data={data}
              false={false}
              setChanges={setChanges}
            />
          </Page> */}
          {/* <Page size="A4">
            <EquityTop5Sectors
              newData={newData}
              denomination={denomination}
              data={newData}
              subtext={"Top 5 Sectors By Holding"}
            />
          </Page> */}
          {/* <Page size="A4">
            <Alternates
              newData={newData}
              denomination={denomination}
              data={newData}
            />
          </Page> */}
          {/* <IncomeCashFlowCorpusExport
        customTableHeader={corpusTableHeader}
        setChanges={setChanges}
        false={false}
        denomination={denomination}
      />
      <IncomeCashFlowCorpusExport
        subtext={"Corpus Income & Expense Report"}
        setChanges={setChanges}
        false={false}
        customTableHeader={corpusTableHeader2}
        denomination={denomination}
      />
      <IncomeCashFlowCorpusExport
        subtext={"Movements Since Last Month"}
        setChanges={setChanges}
        false={false}
        denomination={denomination}
      /> */}
          {/* {Object.keys(newData?.["asset-allocation"])[0]?.length > 0 && (
            <Page size="A4">
              <StackTable
                showdate={true}
                //data={data?.reporting?.reporting_Asset_Allocation?.data?.data?.result}
                data={newData?.["asset-allocation"]}
              />
            </Page>
          )}
          {Object.keys(newData?.["engagement-model"])?.length > 0 && (
            <Engagement_model
              denomination={denomination}
              data={newData?.["engagement-model"] || {}}
            />
          )}

          <Asset_allocation
            denomination={denomination}
            data={newData?.["dashboard-asset-summary"] || {}}
          />
          <CashFlowNew
            denomination={denomination}
            data={newData?.["cashflow-data"] || {}}
          />
          <Top5pdf
            denomination={denomination}
            data={newData?.["top-five-Amc"] || {}}
            title={"Top 5 AMC"}
            mappedKey={{
              key1: "topFiveAmc",
              key2: "amcName",
              key3: "amcPercentage",
            }}
            tableconfig={top5amc}
          />
          <Top5pdf
            denomination={denomination}
            data={newData?.["top-five-Issuer"] || {}}
            title={"Top 5 Issuers"}
            mappedKey={{
              key1: "topFiveIssuer",
              key2: "issuerName",
              key3: "issuerPercentage",
            }}
            tableconfig={top5issuer}
          />
          <TopPerformer
            customKey="top-performers-"
            customData={newData}
            loading={false}
            setChanges={setChanges}
            denomination={denomination}
          />
          <TopPerformer
            customKey="bottom-performers-"
            reset={reset}
            customData={newData}
            loading={false}
            setChanges={setChanges}
            title={
              <>
                Bottom <br /> Performer
              </>
            }
            denomination={denomination}
          />
          <PerformanceSummary
            setChanges={setChanges}
            loading={false}
            // customData={data}
            customData={newData}
            denomination={denomination}
          /> */}
          {/* <Page size="A4">
        <PerformanceSummary1v2 />
      </Page>
      <Page size="A4">
        <PerformanceSummary1v2 />
      </Page>
      <Page size="A4">
        <PerformanceSummary2v2 />
      </Page> */}
          {/* {newData?.["equity-holding-summary-equity"]?.length > 0 && (
            <EquityMF
              headerType={4}
              title={"Holding Summary"}
              title2={"Equity"}
              customTableHeader={EquityMFHolding}
              subtext={"Direct Equity"}
              customKey="Direct Equity"
              loading={false}
              setChanges={setChanges}
              error={data?.equity?.direct_equity_holding_summary?.error}
              customData={
                // data?.equity?.direct_equity_holding_summary?.data?.data?.result
                newData?.["equity-holding-summary-equity"]
              }
              customTableClass={"printTableEvenAndOddRow"}
              hideTitle={true}
              hideTotalRow={true}
              denomination={denomination}
            />
          )}
          {newData?.["mf-holding-summary-equity"]?.length > 0 && (
            <EquityMF
              headerType={4}
              title={"Holding Summary"}
              title2={"Equity"}
              subtext={"Equity Mutual Fund"}
              customKey="MF"
              loading={false}
              changes={changes}
              error={data?.equity?.mf_holding_summary?.error}
              // customData={data?.equity?.mf_holding_summary?.data?.data?.result}
              customData={newData?.["mf-holding-summary-equity"]}
              setChanges={setChanges}
              hideTotalRow={true}
              customTableClass={"printTableEvenAndOddRow"}
              hideTitle={true}
              denomination={denomination}
            />
          )}
          {newData?.["bonds-holding-summary-debt"]?.length > 0 && (
            <EquityMF
              headerType={4}
              title={"Holding Summary"}
              title2={"Debt"}
              subtext={"Bonds"}
              customKey="Debt"
              loading={false}
              error={data?.debt?.bonds_holding_summary?.error}
              // customData={data?.debt?.bonds_holding_summary?.data?.data?.result}
              customData={newData?.["bonds-holding-summary-debt"]}
              setChanges={setChanges}
              hideTotalRow={true}
              customTableClass={"printTableEvenAndOddRow"}
              hideTitle={true}
              denomination={denomination}
            />
          )} */}
          {/* No API FOR F/O */}
          {/* <EquityMF
        title={"Holding Summary"}
        title2={"Equity"}
        headerType={4}
        subtext={"Future & Options"}
        hideTitle={true}
        customKey="Maturity Profile"
        hideTotalRow={true}
        // customTableHeader={alternateStaticData?.tableHeader}
        loading={false}
        setChanges={setChanges}
        customTableClass={"printTableEvenAndOddRow"}
        denomination={denomination}
        // hideTitle={true}
        // error={data?.debt?.st_holding_summary?.error}
        // customData={data?.debt?.st_holding_summary?.data?.data?.result}
      /> */}
          {/* {newData?.["structured-holding-summary-debt"]?.length > 0 && (
            <EquityMF
              headerType={4}
              title={"Holding Summary"}
              title2={"Debt"}
              subtext={"Structured Product/MDs"}
              customKey="Debt"
              loading={false}
              error={data?.debt?.st_holding_summary?.error}
              // customData={data?.debt?.st_holding_summary?.data?.data?.result}
              customData={newData?.["structured-holding-summary-debt"]}
              setChanges={setChanges}
              hideTotalRow={true}
              customTableClass={"printTableEvenAndOddRow"}
              hideTitle={true}
              denomination={denomination}
            />
          )}
          {newData?.["mf-holding-summary-cash"]?.length > 0 && (
            <EquityMF
              title={"Holding Summary"}
              subtext={"Liquid & Equivalent Mutual Fund"}
              title2={"Liquid & Equivalent"}
              headerType={4}
              hideTitle={true}
              customKey="Maturity Profile"
              hideTotalRow={true}
              // customTableHeader={alternateStaticData?.tableHeader}
              loading={false}
              error={data?.cash?.mf_holding_summary?.error}
              // customData={data?.cash?.mf_holding_summary?.data?.data?.result}
              customData={newData?.["mf-holding-summary-cash"]}
              setChanges={setChanges}
              customTableClass={"printTableEvenAndOddRow"}
              denomination={denomination}
              // hideTitle={true}
            />
          )}
          {newData?.["mf-holding-summary-debt"]?.length > 0 && (
            <EquityMF
              headerType={4}
              title={"Holding Summary"}
              title2={"Debt"}
              subtext={"Debt Mutual Fund"}
              customKey="Debt"
              loading={false}
              error={data?.debt?.mf_holding_summary?.error}
              // customData={data?.debt?.mf_holding_summary?.data?.data?.result}
              customData={newData?.["mf-holding-summary-debt"]}
              setChanges={setChanges}
              hideTotalRow={true}
              customTableClass={"printTableEvenAndOddRow"}
              hideTitle={true}
              denomination={denomination}
            />
          )} */}
          {/* No API FOR GOLD BONDS */}
          {/* <EquityMF
        title={"Holding Summary"}
        subtext={"Gold Bonds"}
        title2={"Alternate"}
        headerType={4}
        hideTitle={true}
        customKey="Maturity Profile"
        hideTotalRow={true}
        // customTableHeader={alternateStaticData?.tableHeader}
        loading={false}
        setChanges={setChanges}
        customTableClass={"printTableEvenAndOddRow"}
        denomination={denomination}
        // hideTitle={true}
      /> */}
          {/* {newData?.["reits-holding-summary-alternate"]?.length > 0 && (
            <EquityMF
              headerType={4}
              title={"Holding Summary"}
              title2={"REITS/INVITS"}
              subtext={"REITS"}
              customKey="Maturity Profile"
              hideTotalRow={true}
              customTableHeader={alternateStaticData?.tableHeader}
              loading={false}
              error={data?.alternate?.reits_holding_summary?.error}
              // customData={data?.alternate?.reits_holding_summary?.data?.data?.result}
              customData={newData?.["reits-holding-summary-alternate"]}
              setChanges={setChanges}
              denomination={denomination}
            />
          )}
   
          <CashFlow
            denomination={denomination}
            data={newData}
            loading={false}
            setChanges={setChanges}
          />
          {newData["top-five-Issuer"]?.topFiveIssuer?.length > 0 && (
            <Page size="A4">
              <Top5issues
                denomination={denomination}
                data={newData}
                windowSize={windowSize}
              />
            </Page>
          )}
          {newData["top-five-Amc"]?.topFiveAmc?.length > 0 && (
            <Page size="A4">
              <Top5amc
                denomination={denomination}
                // data={data}
                data={newData}
                windowSize={windowSize}
              />
            </Page>
          )}
          {newData["top-five-amc-aif"]?.topFiveAmc?.length > 0 && (
            <Page size="A4">
              <Alternate_sub_asset
                denomination={denomination}
                // data={data}
                data={newData}
                windowSize={windowSize}
              />
            </Page>
          )}
          <Page size="A4">
            <Equity_summary denomination={denomination} data={newData} />
          </Page>
          <Page size="A4">
            <AssetSubClass data={newData} />
          </Page> */}
          {/* <Page size="A4">
        <Performance_key_indices denomination={denomination} data1={data} />
      </Page>
      <Page size="A4">
        <Performance_key_indices1 denomination={denomination} data1={data} />
      </Page> */}
          {/* <Page size="A4">
        <Market_snapshot denomination={denomination} />
      </Page> */}
          {/* <RiskProfile /> */}
          {/* <ReportingHierarchy /> */}
          {/* <Disclaimers /> */}
          <ThankYouPage pdfInfo={pdfInfo} />
          <Annexures pdfInfo={pdfInfo} />
          <Disclaimer pdfInfo={pdfInfo} />
          {/* <Page size="A4">
            <Disclaimer />
          </Page> */}
          {/* <Page size="A4">
            <LastPage type={"pdf"} />
          </Page> */}
        </Document>
      )}
    </>
  );
}
