import React from "react";
import { Grid, Box } from "@material-ui/core";
import { MainCard } from "./MainCard";
import { NestedCard } from "./NestedCard";
import { Typography } from "@material-ui/core/";
import { useStyles, checkValue, formatDate } from "./CardStyles";
import { CardInfo } from "./CardInfo";
import { GainsCardInfo } from "./GainsCardInfo";
import { GainLossLegend } from "./GainLossLegend";
import { TotalGainLossCardInfo } from "./TotalGainLossCardInfo";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ReactComponent as ReloadIcon } from "../../../assets/icons/ReloadIcon.svg";
import { ReactComponent as ErrorBarIcon } from "../../../assets/icons/ErrorBarIcon.svg";
export const NewHoldingSummaryCardsActive = ({
  holdingSummaryCardsActive,
  totalRentalIncome,
  children,
  totalGainLossActive,
  infoIconHovered,
  twrrLoaderCurrentHolding,
  twrrErrorCurrentHolding,
  retryFetchTwrrCurrentHolding,
  renderCustomComponent,
}) => {
  const classes = useStyles();
  function getTwrrData(data) {
    if (twrrLoaderCurrentHolding && !twrrErrorCurrentHolding) {
      return <CircularProgress />;
    } else if (!twrrLoaderCurrentHolding && twrrErrorCurrentHolding) {
      return (
        <div
          onClick={retryFetchTwrrCurrentHolding}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            cursor: "pointer",
            fontSize: "18px",
          }}
        >
          <ReloadIcon />
          <p>Reload</p>
        </div>
      );
    } else {
      return data;
    }
  }
  return (
    <Grid container spacing={1} style={{ position: "relative" }}>
      {infoIconHovered && <TotalGainLossCardInfo />}
      <Grid item xs={2}>
        <Typography className={classes.dateText}>
          Updated As On :{" "}
          <span className={classes.dateValue}>
            {formatDate(holdingSummaryCardsActive?.asOnDate)}
          </span>
        </Typography>
      </Grid>
      <Grid item xs={5}></Grid>
      <Grid item xs={5}>
        <GainLossLegend />
      </Grid>

      {/* 2nd Row Start */}
      {renderCustomComponent || (
        <>
          <Grid item xs={2}>
            <MainCard>
              <Box p={2}>
                <Typography
                  variant="h1"
                  component="h2"
                  className={classes.firstCardHeaderText}
                >
                  Current
                </Typography>
                <Typography
                  variant="h1"
                  component="h2"
                  className={classes.firstCardHeaderText}
                >
                  Portfolio
                </Typography>
                <Typography
                  className={classes.firstCardHeaderText}
                  style={{ marginBottom: "30px" }}
                >
                  Value
                </Typography>
                <Typography className={classes.firstCardHeaderValue}>
                  {holdingSummaryCardsActive.portfolioValueAmount}
                </Typography>
              </Box>
            </MainCard>
          </Grid>
          <Grid item xs={5}>
            <MainCard>
              <NestedCard style={{ marginTop: "5.9px" }}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={"40px"}
                >
                  <Box>
                    <Typography className={classes.investedText}>
                      Invested Value
                    </Typography>
                    <Typography className={classes.investedSubText}>
                      (Cost of Current Holdings)
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className={classes.investedValue}>
                      {holdingSummaryCardsActive?.investedAmount}
                    </Typography>
                  </Box>
                </Box>
              </NestedCard>
            </MainCard>
          </Grid>

          <Grid item xs={5}>
            <MainCard>
              <Grid
                container
                justifyContent="space-between"
                className={classes.spaceBetweenMainHeaderAndInnerCard}
              >
                <Grid item>
                  <Typography className={classes.secondCardHeaderText}>
                    Total Gain/Loss
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.secondCardHeaderValue}>
                    {holdingSummaryCardsActive?.totalGainLoss}
                  </Typography>
                </Grid>
              </Grid>
              <NestedCard>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  className={classes.nestedCardBoxPadding}
                >
                  {totalGainLossActive ? (
                    totalGainLossActive
                  ) : (
                    <>
                      <GainsCardInfo
                        text="Realized Gain/Loss"
                        value={holdingSummaryCardsActive?.realisedGainLoss}
                      />
                      <GainsCardInfo
                        text="Unrealized Gain/Loss"
                        value={holdingSummaryCardsActive?.unRealisedGainLoss}
                        align="right"
                      />
                      {totalRentalIncome}
                    </>
                  )}
                </Box>
              </NestedCard>
            </MainCard>
          </Grid>
        </>
      )}

      {/* DIVIDER LINE START */}
      <Grid item xs={12}>
        <section id="title-wrapper">
          <div class="title" className={classes.dividerText}>
            RETURNS
          </div>
          <div class="dividerLine"></div>
        </section>
      </Grid>
      {/* DIVIDER LINE END */}

      {/* XIRR */}
      <Grid item xs={4}>
        <MainCard
          className={`${classes.secondRowCards} ${
            checkValue(holdingSummaryCardsActive?.xirrValue).type === "gain"
              ? classes.gainBackground
              : classes.lossBackground
          }`}
        >
          <CardInfo
            text="XIRR"
            value={holdingSummaryCardsActive?.xirrValue}
            icon={checkValue(holdingSummaryCardsActive?.xirrValue).icon}
          />
        </MainCard>
      </Grid>

      {/* ABS TWRR */}
      <Grid item xs={4}>
        <MainCard
          className={`${classes.secondRowCards} ${
            checkValue(holdingSummaryCardsActive?.absoluteTwrrData).type ===
            "gain"
              ? classes.gainBackground
              : classes.lossBackground
          }`}
          // if twrr current holding api fails then change bg and border
          style={{
            background: twrrErrorCurrentHolding && "#211534",
            border: twrrErrorCurrentHolding && "1px solid #613D9B",
          }}
        >
          <CardInfo
            // text="Absolute TWRR"
            // if there is an error then reduce text opacity
            text={
              twrrErrorCurrentHolding ? (
                <span style={{ opacity: "0.5" }}>Absolute TWRR</span>
              ) : (
                "Absolute TWRR"
              )
            }
            // value={holdingSummaryCardsActive.absoluteTwrrData}
            value={getTwrrData(holdingSummaryCardsActive?.absoluteTwrrData)}
            icon={
              twrrErrorCurrentHolding ? (
                <ErrorBarIcon />
              ) : (
                checkValue(holdingSummaryCardsActive?.absoluteTwrrData).icon
              )
            }
          />
        </MainCard>
      </Grid>

      {/* ANN TWRR */}
      <Grid item xs={4}>
        <MainCard
          className={`${classes.secondRowCards} ${
            checkValue(holdingSummaryCardsActive?.annualisedTwrrData).type ===
            "gain"
              ? classes.gainBackground
              : classes.lossBackground
          }`}
          // if twrr current holding api fails then change bg and border
          style={{
            background: twrrErrorCurrentHolding && "#211534",
            border: twrrErrorCurrentHolding && "1px solid #613D9B",
          }}
        >
          <CardInfo
            // text="Annualised TWRR"
            // if there is an error then reduce text opacity
            text={
              twrrErrorCurrentHolding ? (
                <span style={{ opacity: "0.5" }}>Annualized TWRR</span>
              ) : (
                "Annualized TWRR"
              )
            }
            // value={holdingSummaryCardsActive.annualisedTwrrData}
            value={getTwrrData(holdingSummaryCardsActive?.annualisedTwrrData)}
            icon={
              twrrErrorCurrentHolding ? (
                <ErrorBarIcon />
              ) : (
                checkValue(holdingSummaryCardsActive?.annualisedTwrrData).icon
              )
            }
          />
        </MainCard>
      </Grid>
    </Grid>
  );
};
