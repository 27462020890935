import React, { useEffect, useRef, useState } from "react";
import useWindowSize from "./hook/seWindowSize";
import { Document, Page, StyleSheet, pdf } from "@react-pdf/renderer";
import { CoverPage } from "./pages/subPrintPages/CoverPage";
import HoldingPrint from "./pages/subPrintPages/HoldingPrint";
import { LastPage } from "./Components/LastPage";
import {
  newpdfTransactions,
  newpdfTransactionsRealEstate,
  newpdfTransactionsFundsInTransit,
  newpdfTransactionsv1,
  newpdfTransactionsInsurance,
} from "./Components/utils/staticData";
import { stDataHolding, stDataTransaction } from "./constands";
import { useLocation, useSearchParams } from "react-router-dom";
import useGetMongoPrintReport from "../../../hooks/useGetMongoPrintReport";
import ThankYouPage from "./pages/ThankYouPdf";
import LoadingScreen from "./pages/LoadingScreen";
import { Link } from "react-router-dom";
import Disclaimer from "./pages/Disclaimer";
import Annexures from "./pages/Annexures";
import CustomizedSnackbars from "../../common/CustomizedSnackbars";
import { OnboardingContext } from "../../../Context";
function TransactionSummaryPdf({ handleUploadPDF, mongo, download, setUrl }) {
  const { dispatch } = OnboardingContext();
  const [browserSize, setBrowserSize] = useState(
    ((window.outerWidth - 10) / window.innerWidth) * 100
  );
  const parentRef = useRef(null);
  const windowSize = useWindowSize();
  const [changes, setChanges] = useState();
  let [searchParams, setSearchParams] = useSearchParams();
  // let denomination = "Lakhs";
  const mongoId = searchParams.get("id");
  const [data, setData] = useState({});
  // const {
  //   state: { mongoId },
  // } = useLocation();
  const { newData, denomination, meta, loading, pdfInfo } =
    useGetMongoPrintReport(
      // "657166f692287431c8849354"
      mongoId || mongo,
      setUrl
    );

  // console.log(newData, denomination, "newdata");
  useEffect(() => {
    if (browserSize < 99) {
      dispatch({
        type: "UPDATE_SNACKBAR_DATA",
        payload: {
          success: true,
          error: false,
          showMsg: true,
          message:
            "Please set Browser Zoom to 100% for better viewing of report",
        },
      });
    }
    if (changes) {
      let a = parentRef.current.querySelectorAll(".printPageNumber");

      Array.from(a)?.map((val, i) => {
        // val.innerHTML = `${i + 1}/${Array.from(a).length}`;
        val.innerHTML = `${i + 1}`;
      });
      // setNewPageList(a);
    }
    const getData = setTimeout(() => {
      if (Object.keys(newData).length > 0 && download == 0) {
        handleUploadPDF();
      }
    }, 5000);

    return () => clearTimeout(getData);
  }, [changes, data]);

  useEffect(() => {
    setBrowserSize(((window.outerWidth - 10) / window.innerWidth) * 100);
  }, []);
  const breadcrumbs = [
    <Link key="1" color="#000000" className="printBreadsel">
      Transaction Summary
    </Link>,
  ];
  const eoTable = (
    <div
      className="p_flex pdfselectedTxt"
      style={{ justifyContent: "flex-end" }}
    >
      <span style={{ color: "#dcaf3d" }}>
        {data?.["isHeld"] == 1 ? "#" : ""}
        Indicates Held-away Transactions
      </span>
    </div>
  );

  function generateTransectionPages(Data) {
    if (
      !newData?.transactionSummary ||
      !Array.isArray(Object.keys(newData?.transactionSummary))
    ) {
      return <></>;
    }
    const sortdata = Object.assign(
      {},
      newData?.["transactionSummary"]?.asset_wise_product_sort_order
    );

    return Object.keys(sortdata).map((asset) => {
      let bread = [...breadcrumbs];
      bread.push(
        <Link
          underline="hover"
          key="2"
          color="
#000000"
        >
          <span className="opc80"> Asset Class : </span>
          <span className="pdfselectedTxt pdfCaps">{asset}</span>
        </Link>
      );
      return sortdata?.[asset]?.map((p) => {
        return newData?.transactionSummary?.[asset]?.[p]?.investments.map(
          (arr) => {
            if (arr?.transactions.length == 0) {
              return <></>;
            }
            let breadcrum = [...bread];
            breadcrum.push(
              <Link
                underline="hover"
                key="2"
                color="
          #000000"
              >
                <span className="opc80"> Product :</span>
                <span className="pdfselectedTxt pdfCaps">{p}</span>
              </Link>
            );

            return (
              <div className="pagebreakTable">
                <HoldingPrint
                  eoTable={eoTable}
                  pdfInfo={pdfInfo}
                  layout={1}
                  headerType={13}
                  title={"Transaction Summary"}
                  footerLegendText={"Indicates Held-away Transactions"}
                  title2={arr?.member}
                  range={meta.range}
                  customTableHeader={
                    p == "Funds In Transit"
                      ? newpdfTransactionsFundsInTransit
                      : p == "Real Estate"
                      ? newpdfTransactionsRealEstate
                      : p == "Insurance"
                      ? newpdfTransactionsInsurance
                      : newpdfTransactionsv1
                  }
                  subtext={p}
                  customKey={arr?.member + " " + p}
                  loading={false}
                  setChanges={setChanges}
                  // error={data?.equity?.direct_equity_holding_summary?.error}
                  customData={
                    // data?.equity?.direct_equity_holding_summary?.data?.data?.result
                    arr.transactions
                  }
                  breadcrumb={breadcrum}
                  customTableClass={"printTableEvenAndOddRow"}
                  hideTitle={true}
                  hideTotalRow={true}
                  denomination={denomination}
                />
              </div>
            );
          }
        );
      });
    });
  }

  return (
    <>
      <CustomizedSnackbars
        customSnackBarPosition={{ vertical: "top", horizontal: "center" }}
        customAlertColor="warning"
      />
      <Document ref={parentRef}>
        {loading && (
          <>
            <CoverPage type={"pdf"} title={"Transaction Summary"} meat={meta} />
            <LoadingScreen loading={loading} />
          </>
        )}
        <CoverPage
          pdfInfo={pdfInfo}
          type={"pdf"}
          title={"Transaction Summary"}
          meat={meta}
        />
        {/* {console.log(newData, "newdata")} */}
        {newData && generateTransectionPages(newData)}
        <ThankYouPage pdfInfo={pdfInfo} />
        <Annexures pdfInfo={pdfInfo} />
        <Disclaimer pdfInfo={pdfInfo} />
        <Page size="A4">{/* <LastPage type={"pdf"} /> */}</Page>
      </Document>
    </>
  );
}

export default TransactionSummaryPdf;
