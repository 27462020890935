import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import "./customInput.css";

export default function CustomInput(props) {
  const {
    title,
    label,
    errorBorder,
    pr,
    style,
    customStyle,
    disable,
    ...newPorops
  } = props;

  const useStyles = makeStyles({
    root: {
      "& label.Mui-focused": {
        color: "transparent !important",
      },
      "& .MuiInputBase-root": {
        borderRadius: "5px !important",
        border: customStyle?.border
          ? customStyle?.border
          : newPorops?.error
          ? "1.4px solid #8939C8 !important"
          : "1.4px solid #8939C8 !important",
        color: "var(--selectedText) !important",
        transition: "border-color 0.2s ease-in-out !important",
        paddingRight: pr ? pr : "",
        backgroundColor: customStyle?.inputBgColor
          ? customStyle?.inputBgColor
          : "black !important",
        "-webkit-box-shadow": "0 0 0 0 #000 inset !important",
        "-webkit-text-fill-color": "#fff !important",
        background: customStyle?.inputBgColor
          ? customStyle?.inputBgColor
          : "black !important ",
        overflow: "hidden !important",
        // '&:hover': {
        //   borderColor: 'transparent',
        // },
        // '&.Mui-focused': {
        //   borderColor: 'transparent',
        // },
      },
      "& .MuiInputBase-root .MuiSelect-select ": {
        minHeight: "auto !important",
      },
      "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": {
        border: "none !important",
      },
      "& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline": {
        border: "none !important",
      },
      "& .MuiInputBase-root .MuiInputBase-input": {
        padding: customStyle?.inputPadding
          ? customStyle?.inputPadding
          : "12px 14px !important",
        fontSize: "14px !important",
      },
      "& .MuiFormHelperText-root": {
        fontSize: "12px !important",
      },
      "& .Mui-disabled": {
        background: "black !important ",
        color: newPorops?.error ? "red !important" : "",
      },
      "& .MuiOutlinedInput-multiline": {
        padding: newPorops?.error ? "24.5px 14px" : "11.5px 14px",
      },
      "& .MuiSelect-iconOutlined": {
        fill: "white",
      },
      "& .MuiFormControl-root .MuiTextField-root .makeStyles-root-6 .MuiFormControl-marginNormal":
        {
          marginTop: "8px !important",
        },
      // "& input:-internal-autofill-selected": {
      //   // backgroundColor: "red !important",
      //   // "-webkit-box-shadow": "0 0 0 100px #000 inset",
      //   // "-webkit-text-fill-color": "#fff",
      //   color: " #fff !important",
      //   "-webkit-text-fill-color": "#fff !important",
      //   "-webkit-background-clip": "text !important",
      //   "background-clip": "text !important",
      // },

      // '&:-webkit-autofill': {
      //   '-webkit-box-shadow': '0 0 0 100px #000 inset',
      //   '-webkit-text-fill-color': '#fff'
      // }
    },
  });
  const classes = useStyles();
  const isEdgeBrowser = window.navigator.userAgent.indexOf("Edg") > -1;

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <InputLabel
        title={title}
        style={{
          paddingLeft: "10px",
          color: "#FFF",
          fontFamily: "Avenir",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "16px",
          /* 133.333% */ letterSpacing: "1.92px",
          textTransform: "uppercase",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          // marginBottom: "10px",
        }}
      >
        {title}
      </InputLabel>
      <TextField
        {...newPorops}
        classes={{
          root: classes.root,
        }}
        // Disable Autocomplete
        inputProps={{
          ...newPorops.inputProps,
          autoComplete: isEdgeBrowser ? "none" : "off",
        }}
        placeholder={label}
        inputProps={{
          ...newPorops.inputProps,
          autoComplete: isEdgeBrowser ? "none" : "off",
        }}
        style={{
          "& legend": { display: "none !important" },
          "& fieldset": { top: 0 },
          marginTop: "8px",
          marginBottom: "18px !important",
          ...style,
          // pr: pr ? pr : "",
        }}
      />
    </div>
  );
}
