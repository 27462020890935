import React from 'react'
import Default from '../components/dashboard/defaultPages/Default'
import InsuranceReport from '../components/dashboard/products/insurance/InsuranceReport'
import { useParams } from "react-router-dom";


function InsuranceDetails() {
    const {pageName}=useParams()
    return (
        <>
            <Default>
              <InsuranceReport pageName={pageName}></InsuranceReport>
            </Default>
        </>
    )
}

export default InsuranceDetails

