import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#29254F",
    borderRadius: "12px",
    minHeight: "auto",
    width: "360px",
    padding: "30px 30px ",
    marginTop: "40px",
  },
  firstbox: {
    backgroundColor: " #1F1C40",
    borderRadius: " 12px",
    height: "219px",
    // width: "260px",
    padding: "20px 20px ",
    marginBottom: "30px",
  },
  box1: {
    display: "flex",
  },
  avatar: {
    width: "30%",
    height: "auto",
    marginTop: "20px",
  },
  avaimg: {
    height: "57px",
    width: "57px",
    margin: "0 auto",
  },

  details: {
    width: "70%",
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    marginBottom: "20px",
  },
  heading: {
    fontfamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "20px",
    color: "#FFFFFF",
  },
  roundbox: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12.375px",
    lineHeight: "17px",
    textAlign: "center",
    color: "#FFFFFF",
    width: "108px",
    height: "30px",
    background: "#302F4D",
    borderRadius: "77.3437px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "6px",
  },

  box2: {
    width: "199px",
    height: "14px",
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12.375px",
    lineHeight: "17px",
    color: "#FFFFFF",
    marginBottom: "10px",
  },
  box3: {
    width: "199px",
    height: "14px",
    textDecorationLine: "underline",
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12.375px",
    lineHeight: "17px",
    color: "#FFFFFF",
    marginBottom: "10px",
  },
  box4: {
    marginTop: "10px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
  },
  box5: {
    display: "flex",
  },
  secondbox: {
    borderRadius: " 12px",
    minHeight: "219px",
    // width: "260px",
    padding: "20px 20px ",
  },
  secondheading: {
    fontfamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "21px",
    color: "#FFFFFF",
  },
  secondroundbox: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "10px",
    lineHeight: "17px",
    textAlign: "center",
    color: "#FFFFFF",
    width: "fit-content",
    height: "23.2px",
    background: "#302F4D",
    borderRadius: "77.3437px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "6px",
    padding: "4px 8px",
    marginLeft: "-5px",
  },
  blockhead: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "10px",
    lineHeight: "13px",
    color: " #FFFFFF",
    opacity: "0.5",
    marginBottom: "20px",
  },
  recboxes: {
    display: "flex",
    marginTop: "20px",
    marginBottom: "20px",
    flexWrap: "wrap",
  },
  recbox1: {
    backgroundColor: "#1F1C40",
    width: "66px",
    height: "66px",
    border: "1px solid #711CF1",
    boxShadow:
      "-4px 4px 6px #000000, 4px -4px 10px rgba(89, 22, 189, 0.5), inset -4px 4px 20px rgba(0, 0, 0, 0.5)",
    borderRadius: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px",
  },
  innerRecbox: {
    width: " 49px",
    height: "49px",
    boxSizing: "borderBox",
    background: "#FAF9FF",
    borderRadius: "50%",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textbox: {
    width: "25px",
    height: "22px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "18px;",
    lineHeight: "22px;",

    background: "linear-gradient(180deg, #AA6FFE 0%, #711CF1 100%);",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: " text;",
    textFillColor: "transparent;",
  },
  modalContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#16082E",
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    color: "white",
    width: "700px",
    height: "auto",
    borderRadius: "30px",
    border: "1px solid #711CF1 !important",
    padding: "3%",
    outline: "none !important",
  },
  select: {
    backgroundColor: "#1C1B39",
    color: "#fff",
    "& .MuiSelect-icon": {
      color: "#fff",
    },
    "& .MuiSelect-icon": {
      color: "#fff",
    },
    "&:focus": {
      backgroundColor: "#1C1B39",
    },
  },
  subTab: {
    width: " 99%",
    background: " #272654",
    border: "1px solid #010825",
    boxShadow: " 0px 3.54122px 3.54122px #000000",
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
    margin: "0 auto",
    height: "40px",
  },
  cardBottomText: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "13px",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
    justifyContent: "center",
    height: "100%",
    paddingBottom: "5px",
  },
  memavatar: {
    margin: "5px",
    background: "#FAF9FF",
    borderRadius: "20px",
    color: "#711CF1",
  },
  icon: {
    fill: "#FAF9FF",
  },
}));

export default useStyles;
