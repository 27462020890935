import { Typography, makeStyles, useTheme } from "@material-ui/core";
import React from "react";
import Breadcrum_route from "../../components/utils/Breadcrum_route";

import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "24px",
    lineHeight: "30px",
    display: "flex",
    alignItems: "center",
    color: "var(--selectedText)",
  },
}));

export default function MasterHeader({ title, state, noback, btn }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const icons = theme.palette.icons;
  const Back = icons.backIcon;

  return (
    <div
      className="filter-box"
      style={{ paddingInline: "5%", paddingTop: "20px" }}
    >
      <Breadcrum_route
        className="bredlinks"
        state={state}
        objname={{
          products: state?.products?.master_name,
          update: "Details",
        }}
        renderClientNameForAdminView={() => {}}
      />
      <div style={{ display: "flex", alignItems: "center" }}>
        <Back
          style={{
            cursor: "pointer",
            marginRight: "12px",
            flexShrink: 0,
            display: noback ? "none" : "",
          }}
          onClick={() => {
            navigate(-1);
          }}
        />
        <Typography className={classes.titleText}>{title}</Typography>

        {btn && btn()}
      </div>
    </div>
  );
}
