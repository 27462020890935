import { dateFormatCommon, roundNumber } from "../utils/common";
import { pSBC } from "./colors";
export const theme = {
  background: "rgba(0,0,0,0.1)",
  textColor: "#333333",
  fontSize: 11,
  axis: {
    domain: {
      line: {
        stroke: "#eae7ed",
        strokeWidth: 0,
      },
    },
    legend: {
      text: {
        fontSize: 12,
        fill: "#F8F9FB",
      },
    },
    ticks: {
      line: {
        stroke: "#1f1c40",
        strokeWidth: 1,
      },
      text: {
        fontSize: 11,
        fill: "#ebe8ee",
      },
    },
  },
  grid: {
    line: {
      stroke: "#1f1c40",
      strokeWidth: 1,
    },
  },
  legends: {
    title: {
      text: {
        fontSize: 11,
        fill: "#ebe8ee",
      },
    },
    text: {
      fontSize: 11,
      fill: "#eae7ed",
    },
    ticks: {
      line: {},
      text: {
        fontSize: 10,
        fill: "#333333",
      },
    },
  },
  annotations: {
    text: {
      fontSize: 13,
      fill: "#333333",
      outlineWidth: 2,
      outlineColor: "#3c9cfa",
      outlineOpacity: 0.7,
    },
    link: {
      stroke: "#000000",
      strokeWidth: 1,
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
    outline: {
      stroke: "#000000",
      strokeWidth: 2,
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
    symbol: {
      fill: "#000000",
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
  },
  tooltip: {
    container: {
      background: "#ffffff",
      color: "#333333",
      fontSize: 12,
    },
    basic: {},
    chip: {},
    table: {},
    tableCell: {},
    tableCellValue: {},
  },
};

const commonProps = {
  margin: { top: 5, right: 20, bottom: 70, left: 80 },
  padding: 0.2,
  labelTextColor: "inherit:darker(1.4)",
  labelSkipWidth: 16,
  labelSkipHeight: 16,
};

export const divergingCommonProps = {
  ...commonProps,

  indexBy: "year",
  minValue: -10,
  maxValue: 10,
  enableGridX: false,
  enableGridY: true,
  valueFormat: (value) => `${Math.abs(value)}`,
  labelTextColor: "inherit:darker(1.2)",
  axisTop: null,
  axisBottom: {
    legend: "",
    legendPosition: "middle",
    legendOffset: 60,
    tickSize: 0,
    tickPadding: 12,
    renderTick: (tick) => {
      return (
        <g transform={`translate(${tick.x},${tick.y + 22})`}>
          {/* <rect x={-14} y={-6} rx={3} ry={3} width={28} height={24} fill="rgba(0, 0, 0, .05)" />
                        <rect x={-12} y={-12} rx={2} ry={2} width={24} height={24} fill="rgb(232, 193, 160)" /> */}
          {/* <line stroke="rgb(232, 193, 160)" strokeWidth={1.5} y1={-22} y2={-12} /> */}
          <rect
            x={-40}
            y={-15}
            rx={2}
            ry={2}
            width={100}
            height={3}
            fill="rgba(31, 28, 64, 1)"
          />
          <text
            textAnchor="middle"
            dominantBaseline="middle"
            style={{
              fill: "#fff",
              fontSize: 10,
            }}
          >
            {tick.value}
          </text>
        </g>
      );
    },
  },

  axisLeft: {
    orient: "left",
    tickSize: 7,
    tickPadding: 6,
    tickRotation: 22,
    legend: "",
    legendOffset: -57,
    textColor: "#fff",
    legendPosition: "middle",
    renderTick: (tick) => {
      return (
        <g transform={`translate(${tick.x - 30},${tick.y})`}>
          {/* <rect x={-14} y={-6} rx={3} ry={3} width={28} height={24} fill="rgba(0, 0, 0, .05)" />
                  <rect x={-12} y={-12} rx={2} ry={2} width={24} height={24} fill="rgb(232, 193, 160)" /> */}
          {/* <line stroke="rgb(232, 193, 160)" strokeWidth={1.5} y1={-22} y2={-12} /> */}
          {/* <rect x={-40} y={-15} rx={2} ry={2} width={100} height={3} fill="rgba(31, 28, 64, 1)" /> */}
          <text
            textAnchor="middle"
            dominantBaseline="middle"
            style={{
              fill: "#b0aab7",
              fontSize: 10,
            }}
          >
            {tick.value} %
          </text>
        </g>
      );
    },
    format: (v) => `${Math.abs(v)}%`,
  },
  axisRight: null,
  markers: [
    {
      axis: "y",
      value: 0,
      lineStyle: { strokeOpacity: 0 },
      textStyle: { fill: "#2ebca6" },
      legend: "", //gain
      legendPosition: "top-left",
      legendOrientation: "vertical",
      legendOffsetY: 120,
    },
    {
      axis: "y",
      value: 0,
      lineStyle: { stroke: "#f47560", strokeWidth: 1 },
      textStyle: { fill: "#e25c3b" },
      legend: "", //loss,
      legendPosition: "bottom-left",
      legendOrientation: "vertical",
      legendOffsetY: 120,
    },
  ],
};

export function pie_onClick3(rdata, setData, cstate, ncolors) {
  let newColors;
  if (cstate.disabled.length == 0) {
    newColors = ncolors?.map((c) => {
      let d = pSBC(-6 / 10, c, false, false);

      cstate.disabled.push(d);

      if (c == rdata.color) {
        return c;
      }
      return d;
    });
  } else {
    // else
    let found = ncolors.find((c, i) => {
      if (c == rdata.color) {
        //   newColors=cstate.disabled
        //   newColors[i]= ncolors[i]
        //   // cstate.colors=newColors

        newColors = ncolors;
        // cstate.disabled=[]
        return true;
      }
    });
    if (!found) {
      newColors = [];
      cstate.disabled.find((r, i) => {
        if (r == rdata.color) {
          newColors.push(ncolors[i]);
        } else {
          newColors.push(r);
        }
      });
    }
  }

  setData({
    title: cstate.disabled.length > 0 ? rdata["label"] : "",
    value: cstate.disabled.length > 0 ? rdata["value"] : "",
    amount: cstate.disabled.length > 0 ? rdata.data["amount"] : "",
    colors: newColors,
    disabled: cstate.disabled,
    hide: true,
  });
}

export function pie_onClick(rdata, setData, cstate, ncolors) {
  let newColors;
  if (cstate.disabled.length == 0) {
    newColors = ncolors?.map((c) => {
      let d = pSBC(-6 / 10, c, false, false);

      cstate.disabled.push(d);

      if (c == rdata.color) {
        return c;
      }
      return d;
    });
  } else {
    // else
    let found = ncolors.find((c, i) => {
      if (c == rdata.color) {
        //   newColors=cstate.disabled
        //   newColors[i]= ncolors[i]
        //   // cstate.colors=newColors

        newColors = ncolors;
        // cstate.disabled=[]
        return true;
      }
    });
    if (!found) {
      newColors = [];
      cstate.disabled.find((r, i) => {
        if (r == rdata.color) {
          newColors.push(ncolors[i]);
        } else {
          newColors.push(r);
        }
      });
    }
  }

  setData({
    title: cstate.disabled.length > 0 ? rdata["id"] : "",
    value: cstate.disabled.length > 0 ? rdata["value"] : "",
    amount: cstate.disabled.length > 0 ? rdata.data["amount"] : "",
    colors: newColors,
    disabled: cstate.disabled,
    hide: true,
  });
}

export function pie_onClick2(rdata, setData, cstate, ncolors) {
  let fills = cstate?.fill?.map((f) => {
    if (f.id.includes("d")) {
      f.id = f.id.replace("d", "");
      return f;
    } else if (f.match.id != rdata.id) {
      f.id = "d" + f.id;
      return f;
    } else {
      return f;
    }
  });

  let newColors;
  if (cstate.disabled.length == 0) {
    newColors = ncolors?.map((c) => {
      let d = pSBC(-6 / 10, c, false, false);

      cstate.disabled.push(d);

      if (c == rdata.color) {
        return c;
      }
      return d;
    });
  } else {
    // else
    let found = ncolors.find((c, i) => {
      if (c == rdata.color) {
        //   newColors=cstate.disabled
        //   newColors[i]= ncolors[i]
        //   // cstate.colors=newColors

        newColors = ncolors;
        // cstate.disabled=[]
        return true;
      }
    });
    if (!found) {
      newColors = [];
      cstate.disabled.find((r, i) => {
        if (r == rdata.color) {
          newColors.push(ncolors[i]);
        } else {
          newColors.push(r);
        }
      });
    }
  }

  setData({
    title: cstate.disabled.length > 0 ? rdata["id"] : "",
    value: cstate.disabled.length > 0 ? rdata["value"] : "",
    amount: cstate.disabled.length > 0 ? rdata.data["amount"] : "",
    colors: newColors,
    disabled: cstate.disabled,
    fill: fills,
  });
}
export const apexTooltip = {
  shared: true,
  custom: function ({ series, seriesIndex, dataPointIndex, w, y1, y2 }) {
    let str =
      ' <div class="tooltip_container">  <div class="subtext tooltip_text">' +
      dateFormatCommon(
        w.globals.initialSeries[seriesIndex]?.data[dataPointIndex][0],
        "YYYY-MM-DD"
      ) +
      "</div>";
    for (let i = 0; i < series.length; i++) {
      str +=
        ` <div class="subtext tooltip_text"> ` +
        ` <div class="text_ellipse">${w.globals.initialSeries[i]?.name} </div>
    ${" " + roundNumber(w.globals.initialSeries[i]?.data[dataPointIndex][2])}` +
        "</div> ";
    }
    str += "</div></div>";
    return str;
  },
};
