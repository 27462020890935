import { Menu, MenuItem, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Back } from "../../../../assets/holding-summary/back-blue.svg";
import SearchInput from "../common/SearchInput";
import { useNavigate } from "react-router-dom";
import useStyles from "../common/ProductsStyles";
import TabComponent from "../common/TabComponent";
import CashflowChart from "../../../common/CashflowChart";
import { getCashFlow, getCashFundLowList } from "../../../../api";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import { useContext } from "react";
import SearchDebouncedInput from "../common/SearchDebouncedInput";
import PageLoader from "../common/pageLoader";
import NewTabComp from "../common/NewTabComp";

function MLDCashFlow({ subHeading, backUrl, entity, clientId, assetClasses }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [page, setPage] = useState("individual");
  const [list, setList] = useState([]);
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const [isin, setIsin] = useState();
  const [search, setSearch] = useState("");
  const searchRef = useRef(null);
  const [searchList, setSearchList] = useState([]);
  const [loading, setLoading] = useState(false);

  const data = [
    { page: "individual", title: "Individual" },
    { page: "consolidated", title: "Consolidated" },
  ];

  const callGetCashFlow = async () => {
    if (page == "individual" && !isin?.isin) {
      setList([]);
      return;
    }

    setLoading(true);
    setList([]);
    // setIsin();

    let params = { ...getfilter_as_params() };

    if (params?.startDate) {
      delete params?.startDate;
    }
    if (params?.endDate) {
      delete params?.endDate;
    }

    try {
      let payload = {
        clientId: clientId,
        // clientId: 267,
        type: page,
        assetClass: assetClasses,
      };

      // payload["isinNo"] = "INE003L07184";
      if (isin?.isin) {
        payload["isinNo"] = isin?.isin;
        // payload["isinNo"] = "INE003L07184";
      }

      let res = await getCashFlow(entity, payload);

      setList(() => res?.data?.data?.result);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setList(() => []);
      setLoading(false);
    }
  };

  const handleSearch = async () => {
    let payload = {
      clientId: 197,
      assetClass: assetClasses,
    };
    if (search) {
      payload["search"] = search;
    }
    try {
      let res = await getCashFundLowList(entity, payload);

      setSearchList(res?.data?.data);
    } catch (error) {
      console.log(error, "asfjbasjkfabsf");
    }
  };

  const handleIsinClick = (data) => {
    setIsin(data);
  };

  useEffect(() => {
    // setIsin();
    callGetCashFlow();
  }, [page, mfoFilter, isin]);

  useEffect(() => {
    if (search) {
      // console.log(search, "asanfkjabsf");
      handleSearch();
    }
  }, [search]);

  const handleClick = React.useCallback(
    (page) => {
      setPage(page);
    },
    [page]
  );

  return (
    <div style={{ marginInline: "2%" }}>
      <div
        style={{ display: "flex", margin: "3% 0%", alignItems: "flex-start" }}
      >
        {/* <Back
          style={{ cursor: "pointer", marginRight: "12px" }}
          onClick={() => {
            if (backUrl) {
              navigate(backUrl);
              return;
            }
            navigate("/home/mld/mld-summary");
          }}
        /> */}
        <Typography style={{ cursor: "pointer" }} className={classes.titleText}>
          Cash Flow
          <br />
          <Typography className={`${classes.subHeading}`}>
            {subHeading}
          </Typography>
          {sessionStorage.getItem("currency_format") != "actuals" &&
            sessionStorage.getItem("currency_format") && (
              <>
                <br></br>
                <span
                  className="subtext capitalize"
                  style={{ fontSize: "16px" }}
                >
                  {" "}
                  (values in {sessionStorage.getItem("currency_format")})
                </span>
              </>
            )}
        </Typography>
      </div>
      <div style={{ display: "flex", margin: "3% 0%", alignItems: "center" }}>
        {/* <TabComponent
          page={page}
          setPage={setPage}
          type={"state"}
          data={data}
          width={"fit-content"}
        ></TabComponent> */}
        <NewTabComp
          data={data}
          customWidth={"fit-content"}
          customHandle={handleClick}
        />
        {page == "individual" && (
          <div style={{ marginLeft: "auto" }}>
            <SearchDebouncedInput
              handleSelection={handleIsinClick}
              customList={searchList}
              search={search}
              setSearchList={setSearchList}
              setSearchName={setSearch}
            />
          </div>
        )}
      </div>
      <div
        style={{ display: "flex", margin: "6% 0% 3% 0%", alignItems: "center" }}
      >
        {page == "individual" && (
          <Typography
            style={{ cursor: "pointer", marginLeft: "12px" }}
            className={classes.titleText}
          >
            {isin?.scheme_name}
          </Typography>
        )}
      </div>
      <CashflowChart data={list} />
      <PageLoader showLoader={loading} />
    </div>
  );
}

export default MLDCashFlow;
