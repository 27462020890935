import { Box, Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import React from "react";
import "./NewSignUp.css";
import PartnerHeading from "./PartnerHeading";
import { useCreatePin } from "./hooks/useCreatePin";
import { useForm } from "./hooks/useForm";
import { pinValidationSchema } from "./ValidationSchema";
import CustomInput from "./common/CustomInput";
import ErrorMessageComp from "./common/ErrorMessageComp";
import LoadingButton from "./common/LoadingButton";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";

function SetPinForm({
  userId,
  handlenext,
  handleBack,
  userType,
  currentStep,
  formWidth,
}) {
  const { setPin, showLoader, apiErrorMessage } = useCreatePin();
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useForm(
    {
      pin: "",
      confirmPin: "",
      agreeToTerms: false,
    },
    (values) => {
      console.log("The values are as follows", values);
      values.pin = Number(values.pin);
      const { pin } = values;
      // delete values?.agreeToTerms;
      //🔴 API CALL
      // TODO Pass userId as 3rd param in setPin
      let user = userType ? userType : "partner";
      setPin(pin, user, userId, handlenext);
    },
    pinValidationSchema
  );

  console.log(errors?.agreeToTerms, "agknagjk");

  return (
    <div
      className="dFlex jtfyContCntr alignCntr h100"
      style={{ width: formWidth }}
    >
      <Box
        className="dFlex jtfyContCntr alignCntr flexDirCol"
        sx={{ width: "330px", gap: "41px" }}
      >
        <PartnerHeading
          noStep={true}
          step={currentStep}
          handleBack={handleBack}
          mainHead="Set Your PIN"
          subtitle="Your Email & Mobile Number has been verified. Please set you pin"
        />

        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid xs={12} item>
              <CustomInput
                title="ENTER PIN"
                type="password"
                variant="outlined"
                margin="normal"
                label="Pin"
                fullWidth
                id="pin"
                name="pin"
                autoComplete="off"
                error={touched.pin && Boolean(errors.pin)}
                helperText={touched.pin && errors.pin}
                value={values.pin}
                onChange={handleChange}
                maxLength={4}
              />
            </Grid>

            <Grid xs={12} item>
              <CustomInput
                maxLength={4}
                title="CONFIRM PIN"
                type="password"
                label="Confirm Pin"
                variant="outlined"
                margin="normal"
                fullWidth
                id="confirmPin"
                name="confirmPin"
                autoComplete="current-password"
                error={touched.confirmPin && Boolean(errors.confirmPin)}
                helperText={touched.confirmPin && errors.confirmPin}
                value={values.confirmPin}
                onChange={handleChange}
              />
            </Grid>
            <Grid xs={12} item>
              <div>
                <div className="checkBoxContainer noPadding">
                  <Checkbox
                    className="noPadding"
                    icon={
                      <div
                        style={{
                          borderRadius: "4px",
                          border: "1.4px solid #541689 ",
                          width: "20px",
                          height: "20px",
                        }}
                      ></div>
                    }
                    checkedIcon={
                      <div
                        style={{
                          borderRadius: "4px",
                          border: "1.4px solid #541689 ",
                          width: "20px",
                          height: "20px",
                        }}
                      >
                        <CheckRoundedIcon
                          style={{
                            width: "20px",
                            marginBottom: "16px",
                            height: "20px",
                          }}
                        />
                      </div>
                    }
                    name="agreeToTerms"
                    value={values?.agreeToTerms}
                    checked={values?.agreeToTerms}
                    onChange={handleChange}
                  />
                  <p className="checkBoxTxt">
                    Agree to all terms and conditions
                  </p>
                </div>
                <p className="inputErrorTxt">{errors?.agreeToTerms}</p>
              </div>
            </Grid>
            <Grid xs={12} style={{ marginTop: "50px" }} item>
              <ErrorMessageComp errorMessage={apiErrorMessage} />
              <LoadingButton
                buttonTitle={"Done"}
                type="submit"
                showLoader={showLoader}
              />
            </Grid>
          </Grid>
        </form>
      </Box>
    </div>
  );
}

export default SetPinForm;
