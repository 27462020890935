import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { ReactComponent as Add } from "../../../../../assets/icons/add.svg";
import { ReactComponent as RightArrow } from "../../../../../assets/icons/rightangle.svg";
import { ReactComponent as Cross } from "../../../../../assets/icons/cross-circle.svg";
import { Link } from "react-router-dom";

import { ReactComponent as LeftBackground } from "../../../../../assets/icons/left_background.svg";
import ls from "localstorage-slim";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import SearchInput from "../SearchInput";

import useStyles from "../../../../user_management/UserManagementStyles";

import { Client_User_Roles, MEMBER_TYPES } from "../../../../../Constants";
import Container_wrapper from "./Container_wrapper";
import C_Modal from "./C_Modal";
import { client_add_members } from "./Clientforms";
import Newform from "./Newform";
import { client_add_member_validation_schema } from "../../../../NewSignUp/ValidationSchema";
import PartnerHeading from "../../../../NewSignUp/PartnerHeading";

function AddMemberModal({
  init,
  arrobj = [],
  header = {
    mainHead: "Add Family Head Details",
    subtitle: "Join us by providing your company details",
    step: 0,
  },
}) {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const arrObj = client_add_members;
  const handleIconClick = () => {
    setOpenModal(!openModal);
  };
  const handleButtonClick = () => {
    setOpenModal(!openModal);
  };
  let initial = {
    name: "",
    entityType: "",
    userRole: "",
    panNumber: "",
    email: "",
    mobile: "",
  };
  //to be removed

  function customHandleSubmit() {}
  function handleBack() {}

  //above content to be removed

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ marginLeft: "5%", width: "35vw" }}></div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "35vw",
          }}
        >
          <Button
            onClick={handleIconClick}
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "10vw",
            }}
            className={classes.button}
          >
            <Add></Add>
            <Typography className={classes.buttonTypo}>Add Member</Typography>
          </Button>
        </div>
      </div>

      {/* new modal */}

      <C_Modal openModal={openModal} closemodal={setOpenModal}>
        <div style={{ width: "100%" }}>
          <div className="newFormTopSec partnerHeadingPadding">
            <PartnerHeading
              mainHead={header.mainHead || ""}
              subtitle={header.subtitle || ""}
              handleBack={handleBack}
              noStep={header.step ? false : true}
              step={header.step}
            />
          </div>
          {openModal && (
            <Newform
              arrObj={arrObj}
              initial={initial}
              schema={client_add_member_validation_schema}
              customHandleSubmit
            ></Newform>
          )}
        </div>
      </C_Modal>
    </>
  );
}

export default AddMemberModal;
