import React from "react";
import { CheckedSvgIcon } from "./common/CheckedSvgIcon";
import { UncheckedSvgIcon } from "./common/UncheckedSvgIcon";

export const NewFilterOffShore = () => {
  return (
    <div className="newFilterOffShoreContainer">
      <button onClick={() => console.log("Test")} className="offShoreBtn">
        <CheckedSvgIcon cssClassName="checkedSvgIcon" />
        Included
      </button>
      <button onClick={() => console.log("Test")} className="offShoreBtn">
        <UncheckedSvgIcon cssClassName="uncheckedSvgIcon" />
        Excluded
      </button>
      <button onClick={() => console.log("Test")} className="offShoreBtn">
        <UncheckedSvgIcon cssClassName="uncheckedSvgIcon" />
        Show Separately
      </button>
    </div>
  );
};
