import React, { createContext, useContext, useReducer } from "react";
import { onBoardingReducer } from "./Reducer";
import ls from "localstorage-slim";
ls.config.encrypt = true;

const OnboardingContextValue = createContext();
function Context({ children }) {
  const [userState, dispatch] = useReducer(onBoardingReducer, {
    appSnackbarData: {
      success: false,
      error: false,
      showMsg: false,
      message: "",
    },
    clientMemberData: {},
    partnerDetail: {},
    partnerRepresentativeDetail: {},
    signInFormData: {},
    loginedUserData: ls.get("logged-entity", { secret: 50 }),
    scopes: {},
  });

  return (
    <OnboardingContextValue.Provider value={{ userState, dispatch }}>
      {children}
    </OnboardingContextValue.Provider>
  );
}

export default Context;

export const OnboardingContext = () => {
  return useContext(OnboardingContextValue);
};
