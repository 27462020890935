import moment from "moment";
export function renderAmountOfFamilyMembersSelected(
  selectedFamilyMembers,
  familyMembers
) {
  if (selectedFamilyMembers?.length === familyMembers?.length) {
    return "All Members";
  } else if (selectedFamilyMembers?.length === 1) {
    return "1 Member";
  } else {
    return `${selectedFamilyMembers?.length} Members`;
  }
}

export function renderAmountOfAdvisorsSelected(selectedAdvisors, advisors) {
  if (selectedAdvisors?.length === advisors?.length) {
    return "All Advisors";
  } else if (selectedAdvisors?.length === 1) {
    return "1 Advisor";
  } else {
    return `${selectedAdvisors?.length} Advisors`;
  }
}

export function renderAmountOfExecutorsSelected(selectedExecutors, executors) {
  if (selectedExecutors?.length === executors?.length) {
    return "All Executors";
  } else if (selectedExecutors?.length === 1) {
    return "1 Executor";
  } else {
    return `${selectedExecutors?.length} Executors`;
  }
}

// export function renderPeriod(selectedPeriod) {
//   if (
//     selectedPeriod === "ITD" ||
//     selectedPeriod === "MTD" ||
//     selectedPeriod === "QTD" ||
//     selectedPeriod === "YTD"
//   ) {
//     return "Since Inception/ITD";
//   } else if (selectedPeriod === "1 Month") {
//     return "1 Month";
//   } else if (selectedPeriod === "3 Months") {
//     return "3 Months";
//   } else if (selectedPeriod === "1 Year") {
//     return "1 Year";
//   } else if (selectedPeriod === "2 Years") {
//     return "2 Years";
//   } else if (selectedPeriod === "3 Years") {
//     return "3 Years";
//   }
// }

export function renderPeriod(selectedPeriod, dateRange) {
  const startDate = moment(dateRange[0]).format("DD-MM-YYYY");
  const endDate = moment(dateRange[1]).format("DD-MM-YYYY");
  if (selectedPeriod === "ITD") {
    return "Since Inception/ITD";
  } else if (selectedPeriod === "Current Month") {
    return `${startDate} - ${endDate}`;
  } else if (selectedPeriod === "Previous Month") {
    return `${startDate} - ${endDate}`;
  } else if (selectedPeriod === "Current Quarter") {
    return `${startDate} - ${endDate}`;
  } else if (selectedPeriod === "Previous Quarter") {
    return `${startDate} - ${endDate}`;
  } else if (selectedPeriod === "MTD") {
    return `${startDate} - ${endDate}`;
  } else if (selectedPeriod === "QTD") {
    return `${startDate} - ${endDate}`;
  } else if (selectedPeriod === "YTD") {
    return `${startDate} - ${endDate}`;
  }
}
