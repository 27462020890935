import { SHOW_ADDITIONAL_DATA_TAB } from "./Config";
import { routes } from "./routes";

export const ENTITY_TYPES = [
  { name: "--Select--", value: "" },
  { value: "public_limited", name: "Public Limited" },
  { value: "private_limited", name: "Private Limited" },
  // { value: "sole_proprietor", name: "Sole Proprietorship" },
  { value: "partnership", name: "Partnership" },
  { value: "llp", name: "LLP (Limited Liability Partnership)" },
];
export const Client_Member_Entity_Types = [
  { value: "individual", name: "Individual" },
  { value: "huf", name: "HUF" },
  { value: "partnership", name: "Partnership" },
  { value: "opc", name: "OPC" },
  { value: "private_ltd", name: "Private Ltd." },
  { value: "public_ltd", name: "Public Ltd." },
  { value: "llp", name: "LLP" },
  { value: "others", name: "Others" },
];

export const MEMBER_TYPES = [
  { name: "--Select--", value: "" },
  { value: "individual", name: "Individual" },
  { value: "non-individual", name: "Non-Individual" },
];

export const Client_User_Roles = [
  { name: "--Select--", value: "" },
  { value: "individual", name: "Individual" },
  { value: "non-individual", name: "Non-Individual" },
  { value: "liaison-officer", name: "Liaison Officer" },
  { value: "head-of-family", name: "Head of Family" },
];

export const partnerUserRoles = [
  { name: "admin ", value: 2 },

  { name: "operation", value: 4 },
  { name: "analyst", value: 5 },
];
export const MFO_USER_ROLE = [
  { name: "SUPER ADMIN", value: "1" },
  // { name: "BANKER", value: "2" },
  { name: "OPERATION", value: "3" },
  { name: "OPERATIONS HEAD", value: "4" },
  { name: "COO", value: "5" },
  { name: "BUSINESS HEAD", value: "6" },
  { name: "ANALYST", value: "7" },
  { name: "CXO", value: "8" },
];

export const ENTITY_LIASON_OFFFICER = "liaison_officer";
export const EMAIL_COM_TYPE = "email";
export const SMS_COM_TYPE = "sms";
export const ENTITY_MEMBER = "member";
export const ENTITY_TYPE_PARTNER = "partner";
export const ENTITY_TYPE_CLIENT = "client";

export const ENTITY_PARTNER = "partner";
export const ENTITY_PARTNER_USER = "representative";

//sample file uploads
export const PRODUCT_TYPES = [
  { name: "--Select--", value: "" },
  { name: "Mutual Fund", value: "mf" },
  { name: "AIF", value: "aif" },
  { name: "PMS", value: "pms" },
  { name: "Real Estate", value: "re" },
  { name: "Insurance", value: "ins" },
  { name: "Insurance-ulip", value: "ins-ulip" },
  { name: "Bonds", value: "bonds" },
  { name: "Equity", value: "equity" },
  { name: "Ipo", value: "equity-ipo" },
  { name: "Unlisted Equity", value: "private" },
  { name: "Structured Products", value: "structured" },
  { name: "MLD", value: "mld" },
  { name: "REITS", value: "reits" },
  { name: "INVITS", value: "invits" },
  { name: "ICD", value: "icd" },
  { name: "Bank Book", value: "bank" },
  { name: "Fixed Deposit", value: "fd" },
  { name: "Recurring Deposit", value: "rd" },
  { name: "Funds In Transit", value: "funds-in-transit" },
];
export const FILE_TYPES = [
  { name: "--Select--", value: "" },
  { name: "Transaction", value: "transaction" },
  { name: "Valuation", value: "valuation" },
];

export const VERIFICATION_UPLOAD_FILE_TYPES = [
  { name: "--Select--", value: "" },
  { name: "Transaction", value: "transaction" },
  { name: "Valuation", value: "valuation" },
  { name: "Unit Based Valuation", value: "unit-based/valuation" },
];
export const ITEMS_LIMIT_FILES = 7;
export const ITEMS_LIMIT_FILES_PARTNER_LIST = 10;
export const ITEMS_LIMIT_UNDERLYING = 10;
export const ITEMS_LIMIT_TAX_LIST = 10;
export const ITEMS_LIMIT_FILES_CLIENT_LIST = 10;
export const ITEMS_LIMIT_MASTERS_LIST = 10;
export const OTP_LENGTH = 6;

export const LOGIN_PAGE_VIDEO =
  "https://assets-staging.neofamilyoffice.in/login_video.mp4";

export const SCHEME_MASTERS = [
  { name: "Mutual Fund", value: "mf" },
  { name: "AIF", value: "aif" },
  { name: "PMS", value: "pms" },
  { name: "Real Estate", value: "re" },
  { name: "Bonds", value: "bonds" },
];
export const ClientMemberRoles = [
  // { name: "LIAISON OFFICER", value: "1" },
  { name: "HEAD OF FAMILY", value: "2", roleId: 2 },
  { name: "INDIVIDUAL", value: "3", roleId: 3 },
  { name: "NON INDIVIDUAL", value: "4" },
  // { name: "ANALYST", value: "5", roleId: 5 },
  // { name: "OPERATION", value: "6" },
];

export const ClientMemberRoles2 = [
  // { name: "LIAISON OFFICER", value: "1" },
  { name: "HEAD OF FAMILY", value: "2", roleId: 2 },
  { name: "INDIVIDUAL", value: "3", roleId: 3 },
  { name: "NON INDIVIDUAL", value: 4 },
  // { name: "ANALYST", value: "5", roleId: 5 },
  // { name: "OPERATION", value: "6" },
];

export const ClientMemberRolesDIY = [
  { name: "HEAD OF FAMILY", value: "2", roleId: 2, selected: true },
];

export const SCHEME_MASTERS_PRODUCT_FIELDS = {
  mf: [
    { label: "ISIN", name: "isin", type: "text" },
    { label: "Fund Name", name: "fundName", type: "text" },
    { label: "Scheme Name", name: "schemeName", type: "text" },
    { label: "RTA Code", name: "rtaCode", type: "text" },
    { label: "Broad Category", name: "broadCategory", type: "text" },
    { label: "Asset Class", name: "assetClass", type: "text" },
    { label: "AMFI Code", name: "amfiCode", type: "text" },
    { label: "Product Subcategory", name: "productSubCategory", type: "text" },
    {
      label: "InvestmentOption",
      name: "investmentOption",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Growth", value: "growth" },
        { title: "Dividend", value: "dividend" },
      ],
    },
    {
      label: "ETF",
      name: "etf",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Yes", value: "yes" },
        { title: "No", value: "no" },
      ],
    },
    { label: "Plan Type", name: "planType", type: "text" },
    { label: "Tax Classification", name: "taxClassification", type: "text" },
    { label: "Benchmark IndexName", name: "benchmarkIndexName", type: "text" },
    {
      label: "Instrument Type",
      name: "instrumentType",
      type: "select",
      values: [{ title: "Unit Based", value: "Unit Based" }],
    },
    {
      label: "MF Status",
      name: "mf_status",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Active", value: "active" },
        { title: "Inactive", value: "inactive" },
      ],
    },
  ],
  aif: [
    { label: "Product Code", name: "isin", type: "text" },
    // { label: "RTA Code", name: "rtaCode", type: "text" },
    { label: "Fund Name", name: "fundName", type: "text" },
    { label: "Scheme Name", name: "schemeName", type: "text" },
    { label: "AMC", name: "amc", type: "text" },
    { label: "Broad Category", name: "broadCategory", type: "text" },
    { label: "Asset Class", name: "assetClass", type: "text" },
    // { label: "AMFI Code", name: "amfiCode", type: "text" },
    { label: "Product Subcategory", name: "productSubCategory", type: "text" },
    {
      label: "InvestmentOption",
      name: "investmentOption",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Growth", value: "growth" },
        { title: "Dividend", value: "dividend" },
      ],
    },
    { label: "Plan Type", name: "planType", type: "text" },
    {
      label: "Primary Prospectus Name",
      name: "primaryProspectusName",
      type: "text",
    },
    { label: "Class", name: "class", type: "text" },
    {
      label: "Aif Fund Type",
      name: "aifFundType",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Value Based", value: "Value Based" },
        { title: "Unit Based", value: "Unit Based" },
      ],
    },
    { label: "Management Fees", name: "managementFees", type: "number" },
    { label: "Hurdle Rate", name: "hurdleRate", type: "number" },
    { label: "Performance Fees", name: "performanceFees", type: "number" },
    {
      label: "Instrument Type",
      name: "instrumentType",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Unit Based", value: "unit_based" },
        { title: "Value Based", value: "value_based" },
      ],
    },
  ],
  pms: [
    { label: "Product Code", name: "isin", type: "text" },
    { label: "Fund Name", name: "fundName", type: "text" },
    { label: "Scheme Name", name: "schemeName", type: "text" },
    // { label: "RTA Code", name: "rtaCode", type: "text" },
    // { label: "Broad Category", name: "broadCategory", type: "text" },
    { label: "Asset Class", name: "assetClass", type: "text" },
    // { label: "AMFI Code", name: "amfiCode", type: "text" },
    { label: "Product Category", name: "productCategory", type: "text" },
    { label: "Product Subcategory", name: "productSubCategory", type: "text" },
    {
      label: "InvestmentOption",
      name: "investmentOption",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Growth", value: "growth" },
        { title: "Dividend", value: "dividend" },
      ],
    },
    { label: "Tax Classification", name: "taxClassification", type: "text" },
    { label: "Benchmark IndexName", name: "benchmarkIndexName", type: "text" },
    {
      label: "Instrument Type",
      name: "instrumentType",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Unit Based", value: "unit_based" },
        { title: "Value Based", value: "value_based" },
      ],
    },
    {
      label: "Plan Type",
      name: "planType",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Regular", value: "regular" },
        { title: "Direct", value: "direct" },
      ],
    },
  ],
  bonds: [
    { label: "Company Name", name: "companyName", type: "text" },
    { label: "ISIN", name: "isin", type: "text" },
    { label: "BSE Group", name: "bseGroup", type: "text" },
    { label: "BSE Code", name: "bseCode", type: "text" },
    { label: "BSE Script Name", name: "bseScripName", type: "text" },
    { label: "BSE List Date", name: "bseListDate", type: "date" },
    { label: "NSE Symbol", name: "nseSymbol", type: "text" },
    { label: "NSE Sec Name", name: "nseSecName", type: "text" },
    { label: "NSE List Date", name: "nseListDate", type: "date" },

    { label: "Maturity Date", name: "maturityDate", type: "date" },
    { label: "Face Value", name: "faceValue", type: "number" },
    { label: "Coupon Rate", name: "couponRate", type: "number" },
    {
      label: "Frequency of the Interest Payment",
      name: "frequencyOfThenInterestPayment",
      type: "text",
    },
    { label: "Tenor", name: "tenure", type: "number" },
    { label: "Credit Rating", name: "creditRating", type: "text" },
    {
      label: "Bond Type",
      name: "bondType",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Corporate bonds", value: "corporate bonds" },
        { title: "Zero coupon bonds", value: "zero coupon bonds" },
        { title: "Government bonds", value: "government bonds" },
        { title: "Money market bonds", value: "money market bonds" },
      ],
    },
    {
      label: "Asset Class",
      name: "assetClass",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Debt", value: "debt" },
      ],
    },
    // { label: "Date of Allotment", name: "dateOfAllotment", type: "date" },
    {
      label: "Product Subcategory",
      name: "productSubCategory",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "T-bill", value: "t-bill" },
        { title: "G-sec", value: "g-sec" },
        { title: "SDL", value: "sdl" },
        { title: "Corporate bonds", value: "corporate bonds" },
        { title: "Certificate of deposit", value: "certificate of deposit" },
        { title: "Commercial paper", value: "commercial paper" },
        { title: "Reits", value: "reits" },
        { title: "Invits", value: "invits" },
        {
          title: "Market linked debentures",
          value: "market linked debentures",
        },
        {
          title: "NON CONVERTIBLE DEBENTURES",
          value: "non convertible debentures",
        },
        {
          title: "Special infrastructure bonds",
          value: "special infrastructure bonds",
        },
        { title: "Zero Coupon Bond", value: "zero coupon bond" },
        { title: "Unlisted bonds", value: "unlisted bonds" },
        { title: "MISC", value: "misc" },
      ],
    },
    // { label: "Put/Call Option", name: "putCallOption", type: "text" },
    // { label: "Redemption", name: "redemption", type: "date" },
    // { label: "Total Issue Size ", name: "totalIssueSize", type: "text" },
    // { label: "Credit Rating Agency", name: "creditRatingAgency", type: "text" },

    { label: "Last Interest Due", name: "lastInterestDue", type: "date" },
    { label: "Start Date ", name: "startDate", type: "date" },
    {
      label: "No. of Blocks of Principle Reductions",
      name: "NoOfBlocksOfPrincipalReductions",
      type: "number",
    },
    {
      label: "No. of Blocks of Interest Reductions",
      name: "NoOfBlocksOfInterestReductions",
      type: "number",
    },

    {
      label: "Cashflow Type",
      name: "cashflowType",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Complex", value: "complex" },
        { title: "Simple", value: "simple" },
      ],
    },
    {
      label: "Instrument Type",
      name: "instrumentType",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Unit Based", value: "unit_based" },
        { title: "Value Based", value: "value_based" },
      ],
    },
    {
      label: "Interest Payment Date Type",
      name: "interestPaymentDateType",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Even", value: "even" },
        { title: "Uneven", value: "uneven" },
      ],
    },

    { label: "Interest Payout Date", name: "interestPayoutDate", type: "text" },

    {
      label: "On Maturity",
      name: "onMaturity",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Yes", value: "yes" },
        { title: "No", value: "no" },
      ],
    },
    {
      label: "Is Listed",
      name: "isListed",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Yes", value: "yes" },
        { title: "No", value: "no" },
      ],
    },
    {
      label: "Manual Price Upload",
      name: "manualPriceUpload",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Yes", value: "yes" },
        { title: "No", value: "no" },
      ],
    },
    {
      label: "Maturity Date Inclusive",
      name: "maturityDateInclusive",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Yes", value: "yes" },
        { title: "No", value: "no" },
      ],
    },
    {
      label: "Perpetual",
      name: "perpetual",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Yes", value: "yes" },
        { title: "No", value: "no" },
      ],
    },
    {
      label: "Include Holidays",
      name: "includeHolidays",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Yes", value: "yes" },
        { title: "No", value: "no" },
      ],
    },
    {
      label: "Issue Date Inclusive",
      name: "issueDateInclusive",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Yes", value: "yes" },
        { title: "No", value: "no" },
      ],
    },
    {
      label: "Interest Days",
      name: "interestDays",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Actual/actual", value: "actual/actual" },
        { title: "Actual/365", value: "actual/365" },
        { title: "Calendar year days", value: "calendar year days" },
      ],
    },
    {
      label: "Is End of Month",
      name: "isEndOfMonth",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Yes", value: "yes" },
        { title: "No", value: "no" },
      ],
    },
  ],
  icd: [
    { label: "Company Name", name: "companyName", type: "text" },
    { label: "ISIN", name: "isin", type: "text" },
    { label: "BSE Group", name: "bseGroup", type: "text" },
    { label: "BSE Code", name: "bseCode", type: "text" },
    { label: "BSE Script Name", name: "bseScripName", type: "text" },
    { label: "BSE List Date", name: "bseListDate", type: "date" },
    { label: "NSE Symbol", name: "nseSymbol", type: "text" },
    { label: "NSE Sec Name", name: "nseSecName", type: "text" },
    { label: "NSE List Date", name: "nseListDate", type: "date" },

    { label: "Maturity Date", name: "maturityDate", type: "date" },
    { label: "Face Value", name: "faceValue", type: "number" },
    { label: "Coupon Rate", name: "couponRate", type: "number" },
    {
      label: "Frequency of the Interest Payment",
      name: "frequencyOfThenInterestPayment",
      type: "text",
    },
    { label: "Tenor", name: "tenure", type: "number" },
    { label: "Credit Rating", name: "creditRating", type: "text" },
    { label: "Bond Type", name: "bondType", type: "text" },
    { label: "Asset Class", name: "assetClass", type: "text" },

    // { label: "Date of Allotment", name: "dateOfAllotment", type: "date" },
    {
      label: "Product Subcategory",
      name: "productSubCategory",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "T-bill", value: "t-bill" },
        { title: "Certificate of deposit", value: "certificate of deposit" },
        { title: "Commercial paper", value: "commercial paper" },
        {
          title: "NON CONVERTIBLE DEBENTURES",
          value: "non convertible debentures",
        },
        {
          title: "Special infrastructure bonds",
          value: "special infrastructure bonds",
        },
        { title: "Zero Coupon Bond", value: "zero coupon bond" },
        { title: "MISC", value: "misc" },
      ],
    },
    // { label: "Put/Call Option", name: "putCallOption", type: "text" },
    // { label: "Redemption", name: "redemption", type: "date" },
    // { label: "Total Issue Size ", name: "totalIssueSize", type: "text" },
    // { label: "Credit Rating Agency", name: "creditRatingAgency", type: "text" },

    { label: "Last Interest Due", name: "lastInterestDue", type: "date" },
    { label: "Start Date ", name: "startDate", type: "date" },
    {
      label: "No. of Blocks of Principle Reductions",
      name: "NoOfBlocksOfPrincipalReductions",
      type: "number",
    },
    {
      label: "No. of Blocks of Interest Reductions",
      name: "NoOfBlocksOfInterestReductions",
      type: "number",
    },
    { label: "Cashflow Type", name: "cashflowType", type: "text" },

    {
      label: "Instrument Type",
      name: "instrumentType",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Unit Based", value: "unit_based" },
        { title: "Value Based", value: "value_based" },
      ],
    },
    {
      label: "Is Listed",
      name: "isListed",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Yes", value: "yes" },
        { title: "No", value: "no" },
      ],
    },
    {
      label: "Compounding",
      name: "compounding",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Yes", value: "yes" },
        { title: "No", value: "no" },
      ],
    },
    {
      label: "ICD Type",
      name: "icdType",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Corporate bonds", value: "corporate bonds" },
        { title: "Zero coupon bonds", value: "zero coupon bonds" },
        { title: "Government bonds", value: "government bonds" },
        { title: "Money market bonds", value: "money market bonds" },
      ],
    },
    {
      label: "Interest Payment Date Type",
      name: "interestPaymentDateType",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Even", value: "even" },
        { title: "Uneven", value: "uneven" },
      ],
    },
    { label: "Interest Payout Date", name: "interestPayoutDate", type: "text" },
  ],
  equity: [
    { label: "Company Name", name: "companyName", type: "text" },
    {
      label: "Demerged Parent Cost",
      name: "demergedParentCost",
      type: "number",
    },
    { label: "Demerged Cost Basis", name: "demergedCostBasis", type: "number" },
  ],
  re: [
    { label: "Project Code", name: "projectCode", type: "text" },
    { label: "Project Name", name: "projectName", type: "text" },
    { label: "Product Subcategory", name: "productSubCategory", type: "text" },
    { label: "Address", name: "address", type: "text" },
    { label: "City", name: "city", type: "text" },
    { label: "State", name: "state", type: "text" },
    { label: "Country", name: "country", type: "text" },
  ],
  invits: [
    { label: "ISIN", name: "isin", type: "text" },
    { label: "Issuer Name", name: "issuerName", type: "text" },
    { label: "Amount Issued", name: "amountIssued", type: "text" },
    { label: "Maturity Date", name: "maturityDate", type: "date" },
    { label: "Yield To Maturity", name: "yieldToMaturity", type: "text" },
    { label: "Bid Price", name: "bidPrice", type: "text" },
    { label: "Ask Price", name: "askPrice", type: "text" },
    { label: "Security Name", name: "securityName", type: "text" },
    { label: "Ticker", name: "ticker", type: "text" },
    { label: "Market Issue", name: "marketIssue", type: "text" },
    { label: "Coupon Rate", name: "couponRate", type: "text" },
    { label: "Asset Type", name: "assetType", type: "text" },
    { label: "Currency", name: "currency", type: "text" },
    { label: "Current Yield", name: "currentYield", type: "text" },
    { label: "Issue Date", name: "issueDate", type: "date" },
    { label: "Minimum Increment", name: "minimumIncrement", type: "text" },
    { label: "Amount Out", name: "amountOut", type: "text" },
    {
      label: "Instrument Type",
      name: "instrumentType",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Unit Based", value: "unit_based" },
        { title: "Value Based", value: "value_based" },
      ],
    },
  ],
  reits: [
    { label: "ISIN", name: "isin", type: "text" },
    { label: "Issuer Name", name: "issuerName", type: "text" },
    { label: "Amount Issued", name: "amountIssued", type: "text" },
    { label: "Maturity Date", name: "maturityDate", type: "date" },
    { label: "Yield To Maturity", name: "yieldToMaturity", type: "text" },
    { label: "Bid Price", name: "bidPrice", type: "text" },
    { label: "Ask Price", name: "askPrice", type: "text" },
    { label: "Security Name", name: "securityName", type: "text" },
    { label: "Ticker", name: "ticker", type: "text" },
    { label: "Market Issue", name: "marketIssue", type: "text" },
    { label: "Coupon Rate", name: "couponRate", type: "text" },
    { label: "Asset Type", name: "assetType", type: "text" },
    { label: "Currency", name: "currency", type: "text" },
    { label: "Current Yield", name: "currentYield", type: "text" },
    { label: "Issue Date", name: "issueDate", type: "date" },
    { label: "Minimum Increment", name: "minimumIncrement", type: "text" },
    { label: "Amount Out", name: "amountOut", type: "text" },
    {
      label: "Instrument Type",
      name: "instrumentType",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Unit Based", value: "unit_based" },
        { title: "Value Based", value: "value_based" },
      ],
    },
  ],

  mld: [
    { label: "ISIN", name: "isin", type: "text" },
    { label: "Issuer Name", name: "issuerName", type: "text" },
    { label: "Des Notes", name: "desNotes", type: "text" },
    { label: "Amount Issued", name: "amountIssued", type: "text" },
    { label: "Face Value", name: "faceValue", type: "number" },
    { label: "Maturity Date", name: "maturityDate", type: "date" },
    { label: "Yield To Maturity", name: "yieldToMaturity", type: "text" },
    { label: "Volume", name: "volume", type: "text" },
    { label: "Bid Price", name: "bidPrice", type: "text" },
    { label: "Ask Price", name: "askPrice", type: "text" },
    { label: "Security Name", name: "securityName", type: "text" },
    { label: "Ticker", name: "ticker", type: "text" },
    { label: "Asset Type", name: "assetType", type: "text" },
    { label: "Market Issue", name: "marketIssue", type: "text" },
    { label: "Coupon Rate", name: "couponRate", type: "text" },
    { label: "Currency", name: "currency", type: "text" },
    { label: "Current Yield", name: "currentYield", type: "text" },
    { label: "Issue Date", name: "issueDate", type: "date" },
    { label: "Minimum Increment", name: "minimumIncrement", type: "text" },
    { label: "Amount Out", name: "amountOut", type: "text" },
    { label: "Rating", name: "rating", type: "text" },
  ],
  structured: [
    { label: "Company Name", name: "companyName", type: "text" },
    { label: "Fund Name", name: "fundName", type: "text" },
    { label: "Scheme Name", name: "schemeName", type: "text" },
    { label: "Issue Price", name: "issuePrice", type: "text" },
    { label: "Face Value", name: "faceValue", type: "text" },
    { label: "Date of Allotment", name: "dateOfAllotment", type: "date" },
    { label: "Maturity Date", name: "maturityDate", type: "date" },
    { label: "Coupon Rate", name: "couponRate", type: "text" },
    {
      label: "Frequency of the Interest Payment",
      name: "frequencyOfInterestPayment",
      type: "text",
    },
    { label: "Put/Call Option", name: "putCallOption", type: "text" },
    { label: "Total Issue Size ", name: "totalIssueSize", type: "text" },
    { label: "Product Subcategory", name: "productSubCategory", type: "text" },
    { label: "Asset Class", name: "assetClass", type: "text" },
    {
      label: "Instrument Type",
      name: "instrumentType",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Unit Based", value: "unit_based" },
        { title: "Value Based", value: "value_based" },
      ],
    },
    {
      label: "Manual Price Upload",
      name: "manualPriceUpload",
      type: "select",
      values: [
        { title: "Yes", value: "yes" },
        { title: "No", value: "no" },
      ],
    },
  ],
  private: [
    { label: "ISIN", name: "isin", type: "text" },
    { label: "Stock Symbol", name: "stockSymbol", type: "text" },
    { label: "Scrip Name", name: "scripName", type: "text" },
    { label: "Sector", name: "sector", type: "text" },
    { label: "Product Subcategory", name: "productSubCategory", type: "text" },
    {
      label: "Asset Class",
      name: "assetClass",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Equity", value: "equity" },
        { title: "Debt", value: "debt" },
        { title: "Alternate", value: "alternate" },
      ],
    },
    {
      label: "Instrument Type",
      name: "instrumentType",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Unit Based", value: "unit_based" },
        { title: "Value Based", value: "value_based" },
      ],
    },
  ],
  fd: [
    { label: "Bank Name", name: "bankName", type: "text" },
    {
      label: "Account Type",
      name: "accountType",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Saving", value: "savings" },
        { title: "Current", value: "current" },
      ],
    },
    { label: "Account No", name: "accountNo", type: "text" },
    { label: "Price Per Unit", name: "pricePerUnit", type: "number" },
    { label: "ISIN", name: "isin", type: "text" },
    { label: "Interest Rate", name: "interestRate", type: "number" },
    {
      label: "Interest Repayment Type",
      name: "interestRepaymentType",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Cumulative", value: "cumulative" },
        { title: "Non-cumulative", value: "non-cumulative" },
      ],
    },
    {
      label: "Interest Repayment Frequency",
      name: "interestRepaymentFrequency",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Monthly", value: "monthly" },
        { title: "Quarterly", value: "quarterly" },
        { title: "Half-yearly", value: "half-yearly" },
        { title: "Annually", value: "annually" },
      ],
    },
    {
      label: "Asset Class",
      name: "assetClass",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Equity", value: "equity" },
        { title: "Debt", value: "debt" },
        { title: "Alternate", value: "alternate" },
      ],
    },
    { label: "Start Date", name: "startDate", type: "date" },
    { label: "maturity Date", name: "maturityDate", type: "date" },
    {
      label: "Penalized Interest Rate",
      name: "penalizedInterestRate",
      type: "number",
    },
    {
      label: "Early Withdrawal Date",
      name: "earlyWithdrawalDate",
      type: "date",
    },
  ],
  rd: [
    { label: "Bank Name", name: "bankName", type: "text" },
    {
      label: "Account Type",
      name: "accountType",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Saving", value: "savings" },
        { title: "Current", value: "current" },
      ],
    },
    { label: "Account No", name: "accountNo", type: "text" },
    { label: "Price Per Unit", name: "pricePerUnit", type: "number" },
    { label: "ISIN", name: "isin", type: "text" },
    { label: "Interest Rate", name: "interestRate", type: "number" },
    {
      label: "Interest Repayment Type",
      name: "interestRepaymentType",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Cumulative", value: "cumulative" },
      ],
    },
    {
      label: "Interest Repayment Frequency",
      name: "interestRepaymentFrequency",
      type: "select",
      values: [
        { title: "--Select--", value: "" },
        { title: "Monthly", value: "monthly" },
        { title: "Quarterly", value: "quarterly" },
        { title: "Half-yearly", value: "half-yearly" },
        { title: "Annually", value: "annually" },
      ],
    },
    { label: "Amount", name: "amount", type: "number" },
    { label: "Start Date", name: "startDate", type: "date" },
    { label: "maturity Date", name: "maturityDate", type: "date" },
    {
      label: "Penalized Interest Rate",
      name: "penalizedInterestRate",
      type: "number",
    },
    {
      label: "Early Withdrawal Date",
      name: "earlyWithdrawalDate",
      type: "date",
    },
  ],
};

export const adduserRoles = [
  { name: "--Select--", value: "", selected: true },
  { value: "superAdmin", name: "Super Admin" },
  { value: "banker", name: "Banker" },
  { value: "operations", name: "Operations" },
  { value: "operationsHead", name: "Operations Head" },
  { value: "coo", name: "COO" },
  { value: "businessHead", name: "Business Head" },
];

export const partnerRepRoles = [
  { name: "--Select--", value: "", selected: true },
  { value: "representative", name: "Partner Representative", roleId: 1 },
  { value: "banker", name: "Banker", roleId: 3 },
];

export const adduserStatus = [
  { name: "--Select--", value: "", selected: true },
  { value: 1, name: "Active" },
  { value: 0, name: "In-Active" },
];

export const new_Client_User_Roles = [
  { name: "--Select--", value: "" },
  { value: "1", name: "Liaison Officer" },
  { value: "6", name: "Client Operations" },
  { value: "5", name: "Client analyst" },
];

export const reportType =
  SHOW_ADDITIONAL_DATA_TAB === "show"
    ? [
        {
          name: "Performance Summary Report",
          value: "Performance Summary Report",
        },
        {
          name: "Transaction Summary Report",
          value: "Transaction Summary Report",
        },
        {
          name: "Holding Summary Report",
          value: "Holding Summary Report",
        },
        {
          name: "Cashflow Summary Report",
          value: "Cashflow Summary Report",
        },
        {
          name: "PMS Underlying Transactions",
          value: "PMS Underlying Transactions",
        },
        {
          name: "Realised Gain/Loss Report",
          value: "Realized GainLoss Report",
        },
        {
          name: "Income Statement",
          value: "Income Statement",
        },

        { name: "Client Portfolio Report", value: "Client Portfolio Report" },
        { name: "Tax Report", value: "Tax Report" },
      ]
    : [
        {
          name: "Performance Summary Report",
          value: "Performance Summary Report",
        },
        {
          name: "Transaction Summary Report",
          value: "Transaction Summary Report",
        },
        {
          name: "Holding Summary Report",
          value: "Holding Summary Report",
        },
        {
          name: "Cashflow Summary Report",
          value: "Cashflow Summary Report",
        },
        {
          name: "PMS Underlying Transactions",
          value: "PMS Underlying Transactions",
        },
        {
          name: "Realised Gain/Loss Report",
          value: "Realized GainLoss Report",
        },
        {
          name: "Income Statement",
          value: "Income Statement",
        },

        { name: "Client Portfolio Report", value: "Client Portfolio Report" },
        { name: "Tax Report", value: "Tax Report" },
      ];

export const exportType = [
  {
    name: "PDF",
    value: "PDF",
  },
  {
    name: "XLS",
    value: "XLS",
  },
];

export const STATUS_CHECK_TIMEOUT = 15; // in secs

export const realEstateTabConfig = [
  { page: "life", title: "Life", path: routes.insuranceSummaryPage },
  {
    page: "health",
    title: "Health",
    path: routes.insuranceSummaryPage_health,
  },
  {
    page: "general",
    title: "General",
    path: routes.insuranceSummaryPage_general,
  },
];

export const MASTERLOBREGION = [
  {
    name: "North",
    value: "north",
  },
  {
    name: "South",
    value: "south",
  },
  {
    name: "East",
    value: "east",
  },
  {
    name: "West",
    value: "west",
  },
];
