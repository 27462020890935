import React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Modal,
  Button,
  Card,
  CardContent,
} from "@material-ui/core";
import { ReactComponent as Back } from "../../../../assets/holding-summary/back-blue.svg";
import { ReactComponent as Print } from "../../../../assets/icons/print.svg";
import { ReactComponent as Import } from "../../../../assets/icons/import.svg";
import { ReactComponent as Export } from "../../../../assets/icons/export.svg";
import { ReactComponent as Eye } from "../../../../assets/icons/white-eye.svg";
import { ReactComponent as Subtract } from "../../../../assets/icons/subtract.svg";
import { Link } from "react-router-dom";
import useStyles from "../common/ProductsStyles";
import { useEquityStockDetail } from "../../../../hooks/useEquityStockDetail";
import Loader from "../../../loader/Loader";
import { dayFormatter, getObjectByColumnName } from "../../../utils/common";
import { PricePercentageChange } from "./PricePercentageChange";
import { routes } from "../../../../routes";

export default function EquityStockDetails() {
  const classes = useStyles();

  const [equityStockData, loading] = useEquityStockDetail();
  const {
    _id,
    co_code,
    bseCode,
    nseSymbol,
    companyName,
    // isin,
    marketCapType,
    XCHNG,
    Upd_Time,
    open_Price,
    High_Price,
    Low_Price,
    Price,
    BBuy_Qty,
    BBuy_Price,
    BSell_Qty,
    BSell_Price,
    Total_Trade,
    Value,
    Volume,
    Oldprice,
    PriceDiff,
    change,
    Net_TrdQty,
    HI_52_WK,
    LO_52_WK,
    H52DATE,
    L52DATE,
    sc_group,
    CompLname,
    Sc_code,
    ListInfo,
    B52HighAdj,
    b52LowAdj,
    symbol,
    financial,
  } = equityStockData;

  let roce = getObjectByColumnName(financial, "ROCE (%)");
  let pbitm = getObjectByColumnName(financial, "PBITM (%)");
  let interestCoverRatio = getObjectByColumnName(
    financial,
    "Interest Cover Ratio"
  );
  let debtors = getObjectByColumnName(financial, "Debtors");
  let pbidtm = getObjectByColumnName(financial, "PBIDTM (%)");
  let longTermDebtEquityRatio = getObjectByColumnName(
    financial,
    "Long Term Debt-Equity Ratio"
  );
  let currentRatio = getObjectByColumnName(financial, "Current Ratio");
  let apatm = getObjectByColumnName(financial, "APATM (%)");
  let debtEquityRatio = getObjectByColumnName(financial, "Debt-Equity Ratio");

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className={`${classes.alignTopHeader}`}>
            <Typography
              className={classes.stockMainHeading}
              style={{ whiteSpace: "nowrap" }}
            >
              {companyName || "-"}
            </Typography>
            <div style={{ paddingLeft: "2%", paddingTop: "2%" }}>
              <div className={classes.stockMainValues}> &#8377;{Price}</div>
              <Typography
                className={classes.mfoSubHeadText}
                style={{
                  color: "linear-gradient(180deg, #00C1CE 0%, #82EBA1 100%)",
                }}
              >
                {" "}
                {/* 31.10 (7.1%) */}
                <PricePercentageChange PriceDiff={PriceDiff} change={change} />
              </Typography>
              <Typography
                className={classes.mfoSubTextCard}
                style={{ whiteSpace: "nowrap" }}
              >
                {" "}
                {dayFormatter(Upd_Time) || "-"}
              </Typography>
            </div>
            <div className={`${classes.sectoriconAlign}`}>
              <Print className={classes.iconStyle} />
              <Export className={classes.iconStyle} />
            </div>
          </div>
          <div
            className={`${classes.sectoralignSubTopHeader}`}
            style={{ display: "flex" }}
          >
            <div
              className={classes.GainDetailsHeadingText}
              style={{ marginRight: "3%" }}
            >
              BSE: {bseCode || "-"}
            </div>
            <div
              className={classes.GainDetailsHeadingText}
              style={{ marginRight: "3%" }}
            >
              NSE: {nseSymbol || "-"}
            </div>
          </div>

          <Grid container spacing={6}>
            <Grid md={7} xs={12} item>
              <div className={classes.mainContent}>
                <div
                  style={{ display: "flex", margin: "0%", minHeight: "88%" }}
                >
                  <div style={{ height: "100%", width: "100%" }}>
                    <Grid container spacing={2} sx={{ height: "100%" }}>
                      <Grid
                        style={{ margin: "5% 3% 3% 5%" }}
                        zeroMinWidth
                        item
                        md={3}
                        xs={12}
                      >
                        <Typography
                          className={classes.GainDetailsHeadingText}
                          style={{ color: "#9488A7" }}
                        >
                          Market Cap
                        </Typography>
                        <Typography className={classes.GainDetailsHeadingText}>
                          {Volume || "-"}
                        </Typography>
                      </Grid>
                      <Grid
                        style={{ margin: "5% 3% 3% 5%" }}
                        zeroMinWidth
                        item
                        md={3}
                        xs={12}
                      >
                        <Typography
                          className={classes.GainDetailsHeadingText}
                          style={{ color: "#9488A7" }}
                        >
                          Current Price
                        </Typography>
                        <Typography className={classes.GainDetailsHeadingText}>
                          {Price || "-"}
                        </Typography>
                      </Grid>
                      <Grid
                        style={{ margin: "5% 3% 3% 5%" }}
                        zeroMinWidth
                        item
                        md={3}
                        xs={12}
                      >
                        <Typography
                          className={classes.GainDetailsHeadingText}
                          style={{ color: "#9488A7" }}
                        >
                          High/Low
                        </Typography>
                        <Typography className={classes.GainDetailsHeadingText}>
                          {High_Price || "-"}/{Low_Price || "-"}
                        </Typography>
                      </Grid>
                      <Grid
                        style={{ margin: "5% 3% 3% 5%" }}
                        zeroMinWidth
                        item
                        md={3}
                        xs={12}
                      >
                        <Typography
                          className={classes.GainDetailsHeadingText}
                          style={{ color: "#9488A7" }}
                        >
                          Debt/Equity
                        </Typography>
                        <Typography className={classes.GainDetailsHeadingText}>
                          {debtEquityRatio?.currentYears || "-"}
                        </Typography>
                      </Grid>
                      <Grid
                        style={{ margin: "5% 3% 3% 5%" }}
                        zeroMinWidth
                        item
                        md={3}
                        xs={12}
                      >
                        <Typography
                          className={classes.GainDetailsHeadingText}
                          style={{ color: "#9488A7" }}
                        >
                          52 week High/Low
                        </Typography>
                        <Typography className={classes.GainDetailsHeadingText}>
                          {HI_52_WK || "-"}
                        </Typography>
                      </Grid>
                      <Grid
                        style={{ margin: "5% 3% 3% 5%" }}
                        zeroMinWidth
                        item
                        md={3}
                        xs={12}
                      >
                        <Typography
                          className={classes.GainDetailsHeadingText}
                          style={{ color: "#9488A7" }}
                        >
                          Interest Cover Ratio
                        </Typography>
                        <Typography className={classes.GainDetailsHeadingText}>
                          {/* 164.05 0.5% */}
                          {interestCoverRatio?.currentYears
                            ? interestCoverRatio?.currentYears + "%"
                            : "-"}
                        </Typography>
                      </Grid>
                      <Grid
                        style={{ margin: "5% 3% 3% 5%" }}
                        zeroMinWidth
                        item
                        md={3}
                        xs={12}
                      >
                        <Typography
                          className={classes.GainDetailsHeadingText}
                          style={{ color: "#9488A7" }}
                        >
                          ROCE
                        </Typography>
                        <Typography className={classes.GainDetailsHeadingText}>
                          {/* 164.05 0.5% */}
                          {roce ? roce?.currentYears + "%" : "-"}
                        </Typography>
                      </Grid>
                      <Grid
                        style={{ margin: "5% 3% 3% 5%" }}
                        zeroMinWidth
                        item
                        md={3}
                        xs={12}
                      >
                        <Typography
                          className={classes.GainDetailsHeadingText}
                          style={{ color: "#9488A7" }}
                        >
                          PBITM
                        </Typography>
                        <Typography className={classes.GainDetailsHeadingText}>
                          {/* 164.05 0.5% */}
                          {pbitm ? pbitm?.currentYears + "%" : "-"}
                        </Typography>
                      </Grid>
                      <Grid
                        style={{ margin: "5% 3% 3% 5%" }}
                        zeroMinWidth
                        item
                        md={3}
                        xs={12}
                      >
                        <Typography
                          className={classes.GainDetailsHeadingText}
                          style={{ color: "#9488A7" }}
                        >
                          Debtors
                        </Typography>
                        <Typography className={classes.GainDetailsHeadingText}>
                          {/* 164.05 0.5% */}
                          {debtors ? debtors?.currentYears : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid md={5} xs={12} style={{ marginTop: "40px" }}>
              <Grid md={12} xs={12}>
                <Typography className={classes.stockContentHeading}>
                  About
                </Typography>
                <Typography
                  className={classes.stockContentText}
                  style={{ width: "90%" }}
                >
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s Lorem Ipsum is simply
                  dummy text of the printing and typesetting industry. Lorem
                  Ipsum has been the industry's standard dummy text ever since
                  the 1500s
                </Typography>
              </Grid>
              <br />
              <br />
              <Grid md={12} xs={12}>
                <Typography className={classes.stockContentHeading}>
                  Key Points
                </Typography>
                <Typography
                  className={classes.stockContentText}
                  style={{ maxWidth: "90%" }}
                >
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s Lorem Ipsum is simply
                  dummy text of the printing and typesetting industry. Lorem
                  Ipsum has been the industry's standard dummy text ever since
                  the 1500s
                </Typography>
              </Grid>
              <Link to={routes?.equityViewChartPriceChart}>
                <Button
                  style={{
                    width: "30%",
                    color: "#FFFFFF",
                    // paddingTop: "15px",
                    borderRadius: "20px",
                  }}
                  className={classes.buttonLightBlue}
                  startIcon={<Eye />}
                >
                  View Chart
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br />
          <br />
          <div className={classes.mainContent}>
            <div style={{ display: "flex" }}>
              <Typography
                style={{ padding: "27px" }}
                className={classes.stockContentHeading}
              >
                Stock Performance
              </Typography>

              <div
                className={`${classes.sectoriconAlign}`}
                style={{ display: "flex-end" }}
              >
                <Print className={classes.iconStyle} />
                <Export className={classes.iconStyle} />
              </div>
            </div>

            {/* <div className={classes.mainContent} style={{ display: "flex" }}>
              <Grid
                container
                spacing={2}
                sx={{ height: "100%" }}
                style={{ padding: "25px" }}
              >
                <Grid md={6} xs={12}>
                  <Typography
                    className={classes.percentageTableValue}
                    style={{
                      color: "#FFFF",
                      paddingBottom: "26px",
                      marginLeft: "2%",
                    }}
                  >
                    Valuation
                  </Typography>
                  <div className={classes.tableContainerAlign}>
                    <Table>
                      <TableHead
                        style={{
                          background: "#1D1B36",
                          borderRadius: "20px",
                          color: "#A468FF",
                        }}
                      >
                        <TableRow className="tableRowStyle">
                          <TableCell
                            align="center"
                            colSpan={2}
                            style={{ borderBottom: "none" }}
                            className={`${classes.tableHeadText} thStyle`}
                          >
                            Stock Name{" "}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ borderBottom: "none" }}
                            className={`${classes.tableHeadText} thStyle`}
                          >
                            Peer Median
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            align="left"
                            className={classes.tableBodyText}
                          >
                            P/E
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.tableBodyText}
                          >
                            5.4
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.tableBodyText}
                          >
                            17.2
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="left"
                            className={classes.tableBodyText}
                          >
                            P/B
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.tableBodyText}
                          >
                            1.44
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.tableBodyText}
                          >
                            6.5
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </Grid>

                <Grid md={6} xs={12}>
                  <Typography
                    className={classes.percentageTableValue}
                    style={{
                      color: "#FFFF",
                      paddingBottom: "26px",
                      marginLeft: "2%",
                    }}
                  >
                    Growth & Efficiency
                  </Typography>
                  <div className={classes.tableContainerAlign}>
                    <Table>
                      <TableHead
                        style={{
                          background: "#1D1B36",
                          borderRadius: "20px",
                          color: "#A468FF",
                        }}
                      >
                        <TableRow className="tableRowStyle">
                          <TableCell
                            align="center"
                            colSpan={2}
                            style={{ borderBottom: "none" }}
                            className={`${classes.tableHeadText} thStyle`}
                          >
                            Stock Name{" "}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ borderBottom: "none" }}
                            className={`${classes.tableHeadText} thStyle`}
                          >
                            Peer Median
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            align="left"
                            className={classes.tableBodyText}
                          >
                            P/E
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.tableBodyText}
                          >
                            5.4
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.tableBodyText}
                          >
                            17.2
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="left"
                            className={classes.tableBodyText}
                          >
                            P/B
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.tableBodyText}
                          >
                            1.44
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.tableBodyText}
                          >
                            6.5
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </Grid>
              </Grid>
            </div> */}

            <div className={classes.mainContent}>
              <Typography
                style={{ padding: "27px" }}
                className={classes.stockContentHeading}
              >
                Financial's
              </Typography>
              {/* <div style={{ height: "175px", display: "flex", marginBottom: "1%",padding: "25px 15px", }} className={classes.mainContent}> */}
              <div
                style={{
                  display: "flex",
                }}
              >
                <Card className={classes.sectorBgCard} style={{ width: "25%" }}>
                  <CardContent className={classes.mfoCardAlign}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div className={classes.subtractCircle}>
                        <Subtract className={classes.mfoCircleAlign} />
                      </div>
                      <Typography
                        className={classes.mfoBackTextMain}
                        style={{ marginLeft: "10px" }}
                      >
                        PBIDTM
                      </Typography>
                    </div>
                    <Typography
                      className={classes.mfoBackTextMain}
                      style={{ margin: "12px 0px" }}
                    >
                      Profit before Interest Depreciation & Tax Margin
                    </Typography>
                    <Grid container spacing={2} style={{ margin: "10px 0px" }}>
                      <Grid md={6}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography
                            style={{ marginTop: "4%" }}
                            className={classes.tableHeaderTextDynamic}
                          >
                            10 years
                          </Typography>
                          <Typography
                            className={classes.fundPerformanceCardText}
                          >
                            {pbitm ? pbidtm?.tenYears + "%" : "-"}
                          </Typography>
                          <Typography
                            style={{ marginTop: "10%" }}
                            className={classes.tableHeaderTextDynamic}
                          >
                            3 years
                          </Typography>
                          <Typography
                            className={classes.fundPerformanceCardText}
                          >
                            {pbidtm ? pbidtm?.threeYears + "%" : "-"}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid md={6}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "35px",
                            marginTop: "5px",
                          }}
                        >
                          <Typography
                            style={{ marginTop: "4%" }}
                            className={classes.tableHeaderTextDynamic}
                          >
                            5 years
                          </Typography>
                          <Typography
                            className={classes.fundPerformanceCardText}
                          >
                            {pbidtm ? pbidtm?.fiveYears + "%" : "-"}
                          </Typography>
                          <Typography
                            style={{ marginTop: "10%" }}
                            className={classes.tableHeaderTextDynamic}
                          >
                            TTM
                          </Typography>
                          <Typography
                            className={classes.fundPerformanceCardText}
                          >
                            {pbidtm ? pbidtm?.currentYears + "%" : "-"}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    {/* <Typography style={{ marginBottom: '0px' }} className={classes.mfoCardValue}>₹ 10000</Typography>
                <Typography className={classes.mfoSubTextCard} style={{ marginBottom: '2px' }}>
                  As on {Date.now()}
                </Typography> */}
                  </CardContent>
                </Card>
                {/* </div> */}

                <Card className={classes.sectorBgCard} style={{ width: "25%" }}>
                  <CardContent className={classes.mfoCardAlign}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexBasis: "auto",
                      }}
                    >
                      <div className={classes.subtractCircle}>
                        <Subtract className={classes.mfoCircleAlign} />
                      </div>
                      <Typography
                        className={classes.mfoBackTextMain}
                        style={{ marginLeft: "10px" }}
                      >
                        Long Term D/E
                      </Typography>
                    </div>
                    <Typography
                      className={classes.mfoBackTextMain}
                      style={{ margin: "12px 0px" }}
                    >
                      Long Term Debit/Equity Ratio
                    </Typography>
                    <Grid container spacing={2} style={{ marginTop: "35px" }}>
                      <Grid md={6}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography
                            style={{ marginTop: "4%" }}
                            className={classes.tableHeaderTextDynamic}
                          >
                            10 years
                          </Typography>
                          <Typography
                            className={classes.fundPerformanceCardText}
                          >
                            {longTermDebtEquityRatio
                              ? longTermDebtEquityRatio?.tenYears + "%"
                              : "-"}
                          </Typography>
                          <Typography
                            style={{ marginTop: "10%" }}
                            className={classes.tableHeaderTextDynamic}
                          >
                            3 years
                          </Typography>
                          <Typography
                            className={classes.fundPerformanceCardText}
                          >
                            {longTermDebtEquityRatio
                              ? longTermDebtEquityRatio?.threeYears + "%"
                              : "-"}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid md={6}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "35px",
                            marginTop: "5px",
                          }}
                        >
                          <Typography
                            style={{ marginTop: "4%" }}
                            className={classes.tableHeaderTextDynamic}
                          >
                            5 years
                          </Typography>
                          <Typography
                            className={classes.fundPerformanceCardText}
                          >
                            {longTermDebtEquityRatio
                              ? longTermDebtEquityRatio?.fiveYears + "%"
                              : "-"}
                          </Typography>
                          <Typography
                            style={{ marginTop: "10%" }}
                            className={classes.tableHeaderTextDynamic}
                          >
                            TTM
                          </Typography>
                          <Typography
                            className={classes.fundPerformanceCardText}
                          >
                            {longTermDebtEquityRatio
                              ? longTermDebtEquityRatio?.currentYears + "%"
                              : "-"}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    {/* <Typography style={{ marginBottom: '0px' }} className={classes.mfoCardValue}>₹ 10000</Typography>
                <Typography className={classes.mfoSubTextCard} style={{ marginBottom: '2px' }}>
                  As on {Date.now()}
                </Typography> */}
                  </CardContent>
                </Card>
                {/* </div> */}

                <Card className={classes.sectorBgCard} style={{ width: "25%" }}>
                  <CardContent className={classes.mfoCardAlign}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexBasis: "auto",
                      }}
                    >
                      <div className={classes.subtractCircle}>
                        <Subtract className={classes.mfoCircleAlign} />
                      </div>
                      <Typography
                        className={classes.mfoBackTextMain}
                        style={{ marginLeft: "10px" }}
                      >
                        CR
                      </Typography>
                    </div>
                    <Typography
                      className={classes.mfoBackTextMain}
                      style={{ margin: "12px 0px" }}
                    >
                      Current Ratio
                    </Typography>
                    <Grid container spacing={2} style={{ marginTop: "35px" }}>
                      <Grid md={6}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography
                            style={{ marginTop: "4%" }}
                            className={classes.tableHeaderTextDynamic}
                          >
                            10 years
                          </Typography>
                          <Typography
                            className={classes.fundPerformanceCardText}
                          >
                            {currentRatio ? currentRatio?.tenYears + "%" : "-"}
                          </Typography>
                          <Typography
                            style={{ marginTop: "10%" }}
                            className={classes.tableHeaderTextDynamic}
                          >
                            3 years
                          </Typography>
                          <Typography
                            className={classes.fundPerformanceCardText}
                          >
                            {currentRatio
                              ? currentRatio?.threeYears + "%"
                              : "-"}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid md={6}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "35px",
                            marginTop: "5px",
                          }}
                        >
                          <Typography
                            style={{ marginTop: "4%" }}
                            className={classes.tableHeaderTextDynamic}
                          >
                            5 years
                          </Typography>
                          <Typography
                            className={classes.fundPerformanceCardText}
                          >
                            {currentRatio ? currentRatio?.fiveYears + "%" : "-"}
                          </Typography>
                          <Typography
                            style={{ marginTop: "10%" }}
                            className={classes.tableHeaderTextDynamic}
                          >
                            TTM
                          </Typography>
                          <Typography
                            className={classes.fundPerformanceCardText}
                          >
                            {currentRatio
                              ? currentRatio?.currentYears + "%"
                              : "-"}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    {/* <Typography style={{ marginBottom: '0px' }} className={classes.mfoCardValue}>₹ 10000</Typography>
                <Typography className={classes.mfoSubTextCard} style={{ marginBottom: '2px' }}>
                  As on {Date.now()}
                </Typography> */}
                  </CardContent>
                </Card>
                {/* </div> */}

                <Card className={classes.sectorBgCard} style={{ width: "25%" }}>
                  <CardContent className={classes.mfoCardAlign}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexBasis: "auto",
                      }}
                    >
                      <div className={classes.subtractCircle}>
                        <Subtract className={classes.mfoCircleAlign} />
                      </div>
                      <Typography
                        className={classes.mfoBackTextMain}
                        style={{ marginLeft: "10px" }}
                      >
                        APATM
                      </Typography>
                    </div>
                    <Typography
                      className={classes.mfoBackTextMain}
                      style={{ margin: "12px 0px" }}
                    >
                      After - Tax Profit Margin
                    </Typography>
                    <Grid container spacing={2} style={{ marginTop: "35px" }}>
                      <Grid md={6}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography
                            style={{ marginTop: "4%" }}
                            className={classes.tableHeaderTextDynamic}
                          >
                            10 years
                          </Typography>
                          <Typography
                            className={classes.fundPerformanceCardText}
                          >
                            {apatm ? apatm?.tenYears + "%" : "-"}
                          </Typography>
                          <Typography
                            style={{ marginTop: "10%" }}
                            className={classes.tableHeaderTextDynamic}
                          >
                            3 years
                          </Typography>
                          <Typography
                            className={classes.fundPerformanceCardText}
                          >
                            {apatm ? apatm?.threeYears + "%" : "-"}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid md={6}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "35px",
                            marginTop: "5px",
                          }}
                        >
                          <Typography
                            style={{ marginTop: "4%" }}
                            className={classes.tableHeaderTextDynamic}
                          >
                            5 years
                          </Typography>
                          <Typography
                            className={classes.fundPerformanceCardText}
                          >
                            {apatm ? apatm?.fiveYears + "%" : "-"}
                          </Typography>
                          <Typography
                            style={{ marginTop: "10%" }}
                            className={classes.tableHeaderTextDynamic}
                          >
                            TTM
                          </Typography>
                          <Typography
                            className={classes.fundPerformanceCardText}
                          >
                            {apatm ? apatm?.currentYears + "%" : "-"}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    {/* <Typography style={{ marginBottom: '0px' }} className={classes.mfoCardValue}>₹ 10000</Typography>
                <Typography className={classes.mfoSubTextCard} style={{ marginBottom: '2px' }}>
                  As on {Date.now()}
                </Typography> */}
                  </CardContent>
                </Card>
                {/* </div> */}
              </div>
            </div>

            <div className={classes.mainContent} style={{ minHeight: "290px" }}>
              <Typography
                style={{ padding: "27px" }}
                className={classes.stockContentHeading}
              >
                Shareholding Pattern’s
              </Typography>
              <div style={{ color: "white", justifyContent: "center" }}>
                Graph Data
              </div>
            </div>
          </div>

          <div style={{ display: "flex", alignItems: "flex-end" }}>
            <Typography
              style={{ padding: "3% 2%" }}
              className={classes.stockContentHeading}
            >
              Shareholding Pattern’s
            </Typography>
            <div className={`${classes.sectoriconAlign}`}>
              <Print className={classes.iconStyle} />
              <Export className={classes.iconStyle} />
            </div>
          </div>

          <div className={classes.mainContent} style={{ minHeight: "290px" }}>
            <div
              className={classes.tableContainerAlign}
              style={{ marginTop: "1%" }}
            >
              <Table>
                <TableHead
                  style={{
                    background: "#1D1B36",
                    borderRadius: "20px",
                    color: "#A468FF",
                  }}
                >
                  <TableRow className="tableRowStyle">
                    <TableCell
                      align="left"
                      style={{ borderBottom: "none" }}
                      className={`${classes.tableHeadText} thStyle`}
                    ></TableCell>
                    <TableCell
                      align="right"
                      style={{ borderBottom: "none" }}
                      className={`${classes.tableHeadText} thStyle`}
                    >
                      Dec 2019
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ borderBottom: "none" }}
                      className={`${classes.tableHeadText} thStyle`}
                    >
                      Mar 2020
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ borderBottom: "none" }}
                      className={`${classes.tableHeadText} thStyle`}
                    >
                      Jun 2020
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ borderBottom: "none" }}
                      className={`${classes.tableHeadText} thStyle`}
                    >
                      Aug 2020
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ borderBottom: "none" }}
                      className={`${classes.tableHeadText} thStyle`}
                    >
                      Oct 2020
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ borderBottom: "none" }}
                      className={`${classes.tableHeadText} thStyle`}
                    >
                      Jun 2020
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ borderBottom: "none" }}
                      className={`${classes.tableHeadText} thStyle`}
                    >
                      Aug 2020
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ borderBottom: "none" }}
                      className={`${classes.tableHeadText} thStyle`}
                    >
                      Oct 2020
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="left" className={classes.tableBodyText}>
                      Promoter
                    </TableCell>
                    <TableCell align="right" className={classes.tableBodyText}>
                      51.69
                    </TableCell>
                    <TableCell align="right" className={classes.tableBodyText}>
                      51.69
                    </TableCell>
                    <TableCell align="right" className={classes.tableBodyText}>
                      51.69
                    </TableCell>
                    <TableCell align="right" className={classes.tableBodyText}>
                      51.69
                    </TableCell>
                    <TableCell align="right" className={classes.tableBodyText}>
                      51.69
                    </TableCell>
                    <TableCell align="right" className={classes.tableBodyText}>
                      51.69
                    </TableCell>
                    <TableCell align="right" className={classes.tableBodyText}>
                      51.69
                    </TableCell>
                    <TableCell align="right" className={classes.tableBodyText}>
                      51.69
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.tableBodyText}>
                      Fils
                    </TableCell>
                    <TableCell align="right" className={classes.tableBodyText}>
                      51.69
                    </TableCell>
                    <TableCell align="right" className={classes.tableBodyText}>
                      51.69
                    </TableCell>
                    <TableCell align="right" className={classes.tableBodyText}>
                      51.69
                    </TableCell>
                    <TableCell align="right" className={classes.tableBodyText}>
                      51.69
                    </TableCell>
                    <TableCell align="right" className={classes.tableBodyText}>
                      51.69
                    </TableCell>
                    <TableCell align="right" className={classes.tableBodyText}>
                      51.69
                    </TableCell>
                    <TableCell align="right" className={classes.tableBodyText}>
                      51.69
                    </TableCell>
                    <TableCell align="right" className={classes.tableBodyText}>
                      51.69
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
