import axios from "axios";
import React from "react";
import { CVL_KRA_URL } from "../../../Config";
import { headers } from "../utils/utils";

export const usePanVerification = () => {
  const verifyPan = async ({ handleSuccess, handleError, payload }) => {
    try {
      let res = await axios.post(CVL_KRA_URL, payload, { headers });
      if (res?.data?.success) {
        handleSuccess(res?.data);
      }
    } catch (error) {
      handleError(error);
    }
  };

  return { verifyPan };
};
