import React from 'react'
import Default from '../components/dashboard/defaultPages/Default'
import AllClientMembers from '../components/dashboard/clientDashboard/AllClientMembers';

function ClientAllMembers() {
  
  return (
    <Default>
        <AllClientMembers></AllClientMembers>
    </Default>
  )
}

export default ClientAllMembers