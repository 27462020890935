import { fetchAdminMfoUsers, getPartnerUserList } from "../api";
import { useState, useEffect } from "react";
import { OnboardingContext } from "../Context";
import { ITEMS_LIMIT_FILES_PARTNER_LIST } from "../Constants";

function keysToCamelCase(obj) {
  if (Array.isArray(obj)) {
    return obj.map((item) => keysToCamelCase(item));
  } else if (typeof obj === "object") {
    const newObj = {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const camelCaseKey = key.replace(/_([a-z])/g, (match, letter) =>
          letter.toUpperCase()
        );
        newObj[camelCaseKey] = keysToCamelCase(obj[key]);
      }
    }
    return newObj;
  } else {
    return obj;
  }
}

export const useAdminListing = (searchTerm, offset) => {
  const { userState } = OnboardingContext();

  let clientId = userState.loginedUserData.id;

  const [loading, setLoading] = useState(true);
  const [listingData, setListingData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);

  const getData = () => {
    let params = {};
    if (searchTerm) {
      params["search"] = searchTerm;
    }

    params["offset"] = offset;
    params["limit"] = ITEMS_LIMIT_FILES_PARTNER_LIST;

    fetchAdminMfoUsers(params)
      .then((response) => {
        setLoading(true);
        const apiResponseData = response?.data?.data?.result;
        setListingData(apiResponseData);
        setLoading(false);
        setTotalPage(() =>
          Math.ceil(
            response?.data?.data?.meta?.count / ITEMS_LIMIT_FILES_PARTNER_LIST
          )
        );
      })
      .catch((err) => {
        setLoading(false);
        console.error(`An Error ${err} occurred`);
      });
  };

  useEffect(() => {
    getData();
  }, [searchTerm, offset]);

  return { listingData, loading, getData, totalPage };
};
