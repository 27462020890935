import React from "react";
import "./commoncss.css";
import useStyles from "../../common/ProductsStyles";
import { Grid, Typography } from "@material-ui/core";
import PmsReportTable from "../../pms/PmsReportTable";
import { fundManagerstableConfig } from "./constands";
import { useEffect } from "react";
import { useState } from "react";
import { fundManagerstableObjectFormating } from "./common";
import { fundHouseConfig } from "./constands";
import { currencyFormat } from "../../../../../hooks/constantFunction";
import HashlinkSupport from "./HashlinkSupport";

function FundDetailsSection({ riskDetails }) {
  const classes = useStyles();
  const [tableColumns, setTableColumns] = useState([]);
  const [tableHeader, setTableHeader] = useState(fundManagerstableConfig);
  const [fundHouseData, setFundHouseData] = useState(fundHouseConfig);

  const configureFundhouseData = (data) => {
    data.value = riskDetails[data?.filed];
    return data;
  };

  useEffect(() => {
    if (riskDetails?.fundManagers?.length > 0) {
      let newList = [];
      riskDetails?.fundManagers.map((val) => {
        newList.push(fundManagerstableObjectFormating(val, tableHeader));
      });
      setTableColumns(newList);
    }
    setFundHouseData((prev) => {
      //   let newList = [];
      prev.map((val) => {
        if (riskDetails[val.filed]) {
          //   newList.push(configureFundhouseData(val));
          if (val.filed == "totalAssetsInCr") {
            val.value =
              currencyFormat(riskDetails[val.filed], {
                currency: "INR",
                style: "currency",
              }) + "Cr";
          } else {
            val.value = riskDetails[val.filed];
          }
        }
      });
      //   console.log(newList, "aifaoief");
      return prev;
    });
  }, []);

  return (
    <div style={{ height: "100%" }}>
      <HashlinkSupport id={"fundDetails"} />

      <div className="dFlex spaceBTWN alignItemsCenter" id={"fundDetails"}>
        <Typography
          style={{ marginBlock: "1% 2%" }}
          className={`${classes.riskHeaderSub}`}
        >
          Fund Details
        </Typography>
      </div>
      <div
        style={{
          margin: "0%",
          width: "100%",
        }}
      >
        <Grid container spacing={3} sx={{ height: "100%" }}>
          <Grid item xs={6} sx={{ height: "100%" }}>
            <div
              style={{
                display: "flex",
                margin: "0%",
                padding: "22px",
                height: "87%",
              }}
              className={`${classes.mainContent} flexDirCol spaceBTWN`}
            >
              <Typography
                // style={{ marginLeft: "2%" }}
                className={`${classes.riskHeaderSub} ${classes.typoInsideAlign}`}
              >
                Fund Management
              </Typography>
              <PmsReportTable
                tableColumns={tableColumns}
                setTableColumns={setTableColumns}
                headerList={fundManagerstableObjectFormating}
                tableHeader={tableHeader}
                setTableHeader={setTableHeader}
                tableBodyBackground={"#110425"}
                tableHeaderBGColor={"#1D1B36"}
                tableMinHeight="auto"
                firstCollFullWidth={true}
              />
            </div>
          </Grid>
          <Grid item xs={6} sx={{ height: "100%" }}>
            <div
              style={{
                display: "flex",
                margin: "0%",
                padding: "22px",
                height: "87%",
              }}
              className={`${classes.mainContent} flexDirCol spaceBTWN`}
            >
              <Typography
                className={`${classes.riskHeaderSub} ${classes.typoInsideAlign}`}
              >
                Fund House Information
              </Typography>
              <Grid container spacing={3} sx={{ height: "100%" }}>
                {fundHouseData?.map((val) => {
                  return (
                    <Grid zeroMinWidth item md={val?.size} xs={12}>
                      <div style={{ margin: "3% 3% 3% 3%" }}>
                        <Typography className={classes.riskDetailsTextMain}>
                          {val.title}
                        </Typography>
                        <Typography
                          className={classes.riskDetailsTextSub}
                          style={{ fontSize: "24px" }}
                        >
                          {val.value}
                        </Typography>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default FundDetailsSection;
