import React, { useEffect } from "react";
import { useState } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import { fetchBondsDetails, getBondsDetailsDataAPI2 } from "../../../../api";
import Corporatebond from "./Corporatebond";
import Govbond from "./Govbond";

function BondsDetailsReport() {
  const [type, setType] = useState(null);
  const [searchParams] = useSearchParams();
  const isin = searchParams.get("isin");
  const { state } = useLocation();
  const [apiData2, setApiData2] = useState({});

  const getBondsDetailsAPI2Call = async () => {
    let params = {
      id: state.id,
      // "order_by": "id",
      // limit: 1,
      // sort_by: "ASC",
    };
    try {
      let res = await getBondsDetailsDataAPI2(params);
      setApiData2(res?.data?.data?.result);
    } catch (error) {}
  };

  useEffect(() => {
    getBondsDetailsAPI2Call();
    fetchBondsDetails(isin).then((res) => {
      if (
        res.result.bondGroup &&
        res.result.bondGroup?.toLowerCase().indexOf("government") != -1
      ) {
        setType({ type: "government", data: res.result });
      } else {
        setType({ type: "corporate", data: res.result });
      }
    });
  }, []);

  const changePage = (p, data) => {
    switch (p) {
      case "corporate":
        return <Corporatebond apiData2={apiData2} data={data} isin={isin} />;

      case "government":
        return <Govbond apiData2={apiData2} data={data} isin={isin} />;

      default:
        return (
          <Corporatebond
            apiData2={apiData2}
            data={data}
            isin={isin}
          ></Corporatebond>
        );
    }
  };

  return <>{type && changePage(type.type, type.data)}</>;
}

export default BondsDetailsReport;
