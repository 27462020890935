let align = "center";
const holdinTableConfig = [
  {
    field: "scripName",
    title: "Security Name",
    show: true,
    align: align,
    tagPresentPromoterStake: "isPromoterStake",
  },
  { field: "memberName", title: "Member Name", show: true, align: align },
  { field: "advisor", title: "Advisor", show: true, align: align },
  {
    field: "isin",
    title: "ISIN No/Product Code",
    show: true,
    align: align,
  },
  { field: "holdingUnits", title: "Quantity", show: true },
  // { field: "weightedAvgCost", title: "Weighted Average Price", show: true },
  {
    field: "investmentValue",
    title: "Investment Value * Includes Brokerage",
    show: true,
  },
  { field: "nav", title: "Current Price (Actuals)", show: true },
  { field: "presentValue", title: "Current Value", show: true },
  {
    field: "percentageOfTotalPortfolio",
    title: "% of Total Portfolio",
    show: true,
  },
  { field: "unRealisedGainLoss", title: "Unrealized Gain/Loss", show: true },
  // { field: "realisedGainLoss", title: "Realized Gain/Loss", show: false },
  {
    field: "interestIncome",
    title: "Dividend Received",
    show: true,
  },
  { field: "totalGainLoss", title: "Total Gain/Loss", show: true },
  { field: "xirr", title: "XIRR (%)", show: true },
  { field: "absoluteTwrr", title: "Absolute TWRR (%)", show: true },
  { field: "annualisedTwrr", title: "Annualized TWRR (%)", show: true },
];

const equityTRTableConfig = [
  { field: "scrip_name", title: "Script Name", show: true, align: align },
  { field: "advisor", title: "Advised by", show: true, align: align },
  { field: "executed_by", title: "Executed By", show: true, align: align },
  { field: "isin", title: "ISIN No/Product Code", show: true, align: align },
  { field: "exchange", title: "Exchange", show: true, align: align },
  { field: "stock_symbol", title: "Stock Symbol", show: true, align: align },
  { field: "account_no", title: "Account no", show: true },
  //   { field: "transaction_id", title: "Transaction ID", show: true },
  {
    field: "transaction_date",
    title: "Transaction Date",
    show: true,
    align: align,
  },
  {
    field: "transaction_type",
    title: "Transaction Type",
    show: true,
    align: align,
  },
  {
    field: "holdingPeriod",
    title: "Holding Period",
    show: true,
    align: align,
  },
  { field: "quantity", title: "Quantity", show: true },
  {
    field: "gross_rate_per_unit",
    title: "Gross Rate Per Unit (Actuals)",
    show: true,
  },
  {
    field: "brokerage_per_unit",
    title: "Brokerage Per unit(Actuals)",
    show: true,
  },
  {
    field: "gst_sst_other_charges",
    title: "GST+STT+Other Charge (Actuals)",
    show: true,
  },
  {
    field: "net_rate_per_unit",
    title: "Net Rate Per Unit (Actuals)",
    show: true,
  },
  {
    field: "total_transaction_value",
    title: "Total Transaction Value (Actuals)",
    show: true,
  },
  {
    field: "dividend_received",
    title: "Dividend Received (Actuals)",
    show: true,
  },
  { field: "last_traded_price", title: "Last Traded price (LTP)", show: true },
  {
    field: "cumulative_quantity",
    title: "Cumulative Quantity",
    show: true,
  },
];
const equityTRCorporateActionsTableConfig = [
  {
    field: "scrip_name",
    title: "Script Name",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "advisor",
    title: "Advised by",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "executed_by",
    title: "Executed By",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "isin",
    title: "ISIN No/Product Code",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "exchange",
    title: "Exchange",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "stock_symbol",
    title: "Stock Symbol",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  { field: "account_no", title: "Account no", show: true, tagPresent: false },
  //   { field: "transaction_id", title: "Transaction ID", show: true },
  {
    field: "transaction_date",
    title: "Transaction Date",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "transaction_type",
    title: "Transaction Type",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "corp_action",
    title: "Corporate Action",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "corp_action_effects",
    title: "Effects",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "corporate_action_value",
    title: "Value (Actuals)",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "ratio",
    title: "Ratio",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "holdingPeriod",
    title: "Holding Period",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  { field: "quantity", title: "Quantity", show: true, tagPresent: false },
  {
    field: "gross_rate_per_unit",
    title: "Gross Rate Per Unit (Actuals)",
    show: true,
    tagPresent: "is_corp_action",
  },
  {
    field: "brokerage_per_unit",
    title: "Brokerage Per unit (Actuals)",
    show: true,
    tagPresent: "is_corp_action",
  },
  {
    field: "gst_sst_other_charges",
    title: "GST+STT+Other Charges (Actuals)",
    show: true,
    tagPresent: "is_corp_action",
  },
  {
    field: "net_rate_per_unit",
    title: "Net Rate Per Unit (Actuals)",
    show: true,
    tagPresent: "is_corp_action",
  },
  {
    field: "total_transaction_value",
    title: "Total Transaction Value (Actuals)",
    show: true,
    tagPresent: "is_corp_action",
  },
  {
    field: "dividend_received",
    title: "Dividend Received (Actuals) ",
    show: true,
    tagPresent: "is_corp_action",
  },
  {
    field: "last_traded_price",
    title: "Last Traded price (LTP)",
    show: true,
    tagPresent: "is_corp_action",
  },
  { field: "remarks", title: "Remarks", show: true, tagPresent: false },
  {
    field: "cumulative_quantity",
    title: "Cumulative Quantity",
    show: true,
    tagPresent: false,
  },
];
const equityStockHead = [
  { title: "Stock Name", field: ["stockName"], show: true, align: "left" },
  {
    title: "Previous Close",
    field: ["previousClosePrice", "previousClosePercent"],
    show: true,
    subHeader: ["Price", "%Change"],
    align: "center",
    showArrow: true,
  },
  // {
  //   title: "Quantity Per Unit",
  //   field: ["quantity", "quantityAvgPrice"],
  //   show: true,
  //   subHeader: ["Quantity", "Avg Price"],
  //   align: "center",
  //   sort: true,
  // },
  {
    title: "Investment",
    field: ["investmentValue", "percentageOfInvestedValue"],
    show: true,
    subHeader: ["Value", "%"],
    align: "center",
    sort: true,
  },
  {
    title: "Latest value",
    field: ["latestValue", "percentageOfLatestValue"],
    show: true,
    subHeader: ["Value", "%"],
    align: "center",
    sort: true,
  },
  {
    title: "Unrealized Gain/Loss",
    field: ["unRealisedGainLoss", "unRealisedGainLossPercent"],
    show: true,
    subHeader: ["Amount", "%"],
    align: "center",
    showArrow: true,
    sort: true,
  },
];

let sortConfig = {
  alphaNumeric: "alphaNumeric",
  numeric: "numeric",
  date: "date",
};

const equityCorporateALlTableConfig = [
  {
    field: "stockName",
    title: "Stoc kName",
    show: true,
    align: align,
    sortType: sortConfig?.alphaNumeric,
  },
  {
    field: "type",
    title: "Type",
    show: true,
    align: align,
    sortType: sortConfig?.alphaNumeric,
  },
  {
    field: "ratio",
    title: "Ratio/Amount",
    show: true,
    align: align,
    sortType: sortConfig?.numeric,
  },
  {
    field: "eventDate",
    title: "Ex Date / Event Date",
    show: true,
    align: align,
    sortType: sortConfig?.date,
  },
  {
    field: "remark",
    title: "Remark",
    show: true,
    align: "left",
    sortType: sortConfig?.alphaNumeric,
  },
];

const staticEquityCorporateData = {
  result: {
    adjustedArray: [
      {
        stockName: "DOC",
        type: "Dividend",
        ratio: "11083.144",
        eventDate: "30/03/2022",
        remark: "1.00:2.00 Bonus - 95 bonus shares added",
      },
      {
        stockName: "KOC",
        type: "Dividend",
        ratio: "100",
        eventDate: "30/03/2022",
        remark: "1.00:2.00 Bonus - 95 bonus shares added",
      },
      {
        stockName: "MOC",
        type: "Dividend",
        ratio: "2031",
        eventDate: "30/03/2022",
        remark: "1.00:2.00 Bonus - 95 bonus shares added",
      },
    ],
    upcomingArray: [
      {
        stockName: "AOC",
        type: "Dividend2",
        ratio: "1.00:2.00",
        eventDate: "30/03/2022",
        remark: "1.00:2.00 Bonus - 95 bonus shares added",
      },
      {
        stockName: "FOC",
        type: "Dividend2",
        ratio: "1.00:2.00",
        eventDate: "30/03/2022",
        remark: "1.00:2.00 Bonus - 95 bonus shares added",
      },
      {
        stockName: "COC",
        type: "Dividend2",
        ratio: "1.00:2.00",
        eventDate: "30/03/2022",
        remark: "1.00:2.00 Bonus - 95 bonus shares added",
      },
    ],
  },
};

const stockSummaryStaticData = {
  success: true,
  event: "EQUITY_STOCK_SUMMARY_FETCH",
  message: "equity stock summary fetched successfully.",
  uuid: "59d95500-d080-11ed-b8dc-853b1b2bee63",
  data: {
    meta: {
      filters: {
        client_id: "17",
        memberId: [],
        type: "all",
      },
    },
    result: {
      stocks: [
        {
          isin: "INE239A01016",
          stockName: "NESTLE INDIA LIMITED (XBOM:500790)",
          quantity: 35,
          investmentValue: 215559.25,
          withdrawalAmount: 0,
          quantityAvgPrice: 6158.835714285714,
          previousClosePrice: 19704.5,
          previousClosePercent: 0.0286870268859305,
          latestValue: 689657.5,
          totalGainLoss: 474098.25,
          realisedGainLoss: 0,
          unRealisedGainLoss: 474098.25,
          unRealisedGainLossPercent: 219.93871754517608,
          percentageOfInvestedValue: 25.35619186220563,
          percentageOfLatestValue: 21.830397084796076,
        },
        {
          isin: "INE131B01039",
          stockName: "RELAXO FOOTWEARS LIMITED (XNSE:RELAXO)",
          quantity: 725,
          investmentValue: 176009.75,
          withdrawalAmount: 0,
          quantityAvgPrice: 242.77206896551724,
          previousClosePrice: 850.95,
          previousClosePercent: 0.011590584878744759,
          latestValue: 616938.75,
          totalGainLoss: 440929,
          realisedGainLoss: 0,
          unRealisedGainLoss: 440929,
          unRealisedGainLossPercent: 250.51396300489034,
          percentageOfInvestedValue: 20.703992014348014,
          percentageOfLatestValue: 19.52856003088161,
        },
        {
          isin: "INE176A01028",
          stockName: "BATA INDIA LTD (XNSE:BATAINDIA)",
          quantity: 240,
          investmentValue: 126539.5,
          withdrawalAmount: 0,
          quantityAvgPrice: 527.2479166666667,
          previousClosePrice: 1418.35,
          previousClosePercent: 0.00706475433115572,
          latestValue: 340404,
          totalGainLoss: 213864.5,
          realisedGainLoss: 0,
          unRealisedGainLoss: 213864.5,
          unRealisedGainLossPercent: 169.01007195381678,
          percentageOfInvestedValue: 14.884816309889597,
          percentageOfLatestValue: 10.775137643327193,
        },
        {
          isin: "INE296A01024",
          stockName: "BAJAJ FINANCE LIMITED (XNSE:BAJFINANCE)",
          quantity: 215,
          investmentValue: 184948.7,
          withdrawalAmount: 51909,
          quantityAvgPrice: 860.226511627907,
          previousClosePrice: 5616.75,
          previousClosePercent: -0.013566912539515252,
          latestValue: 1207601.25,
          totalGainLoss: 1074561.55,
          realisedGainLoss: 51909,
          unRealisedGainLoss: 1022652.55,
          unRealisedGainLossPercent: 552.9384905111525,
          percentageOfInvestedValue: 21.75547893150264,
          percentageOfLatestValue: 38.22537246038229,
        },
        {
          isin: "INE782E01017",
          stockName: "HESTER BIOSCIENCES LIMITED (XNSE:HESTERBIO)",
          quantity: 200,
          investmentValue: 147067.5,
          withdrawalAmount: 0,
          quantityAvgPrice: 735.3375,
          previousClosePrice: 1522.8,
          previousClosePercent: -0.030063694267515984,
          latestValue: 304560,
          totalGainLoss: 157492.5,
          realisedGainLoss: 0,
          unRealisedGainLoss: 157492.5,
          unRealisedGainLossPercent: 107.08858177367534,
          percentageOfInvestedValue: 17.29952088205413,
          percentageOfLatestValue: 9.64053278061283,
        },
      ],
      totalInvestedValue: 850124.7,
      totalLatestValue: 3159161.5,
    },
  },
};

module.exports = {
  holdinTableConfig,
  equityTRTableConfig,
  equityTRCorporateActionsTableConfig,
  equityStockHead,
  equityCorporateALlTableConfig,
  staticEquityCorporateData,
  stockSummaryStaticData,
};
