import React, { useEffect, useContext } from "react";
import moment from "moment";
import {
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { ReactComponent as Back } from "../../../../assets/holding-summary/back-blue.svg";
import useStyles from "../common/ProductsStyles";
import { ReactComponent as Import } from "../../../../assets/icons/import.svg";
import { ReactComponent as Export } from "../../../../assets/icons/export.svg";
import { ReactComponent as Print } from "../../../../assets/icons/print.svg";
import { useState } from "react";
import { getGainLossSummary } from "../../../../api";
import { OnboardingContext } from "../../../../Context";
import Loader from "../../../loader/Loader";
import { useNavigate } from "react-router-dom";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import DataNotFound from "../common/DataNotFound";
import { routes } from "../../../../routes";
function MfoOverall({ currencyFormat }) {
  const navigate = useNavigate();
  const { userState } = OnboardingContext();
  const classes = useStyles();
  const [gainLossData, setGainLossData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  useEffect(() => {
    const clientId = userState.loginedUserData.id;
    let params = { ...getfilter_as_params() };

    setShowLoader(true);

    getGainLossSummary(clientId, params)
      .then((res) => {
        let result = res.result;
        // console.log(result);
        setGainLossData(result);
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }, [mfoFilter]);
  return (
    <>
      <Modal
        open={showLoader}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="loader-wrapper">
          <Loader />
        </div>
      </Modal>
      <div className={`${classes.alignTopHeader}`}>
        <Typography
          style={{ marginLeft: "-4%" }}
          className={`${classes.mfoGraphHeader} ${classes.mainHeadingAlign}`}
        >
          Overall Gain Loss Summary
        </Typography>
        <div className={`${classes.iconAlign}`}>
          <Import className={classes.iconStyle} />
          <Export className={classes.iconStyle} />
          <Print className={classes.iconStyle} />
        </div>
      </div>
      <div
        style={{ height: "auto", position: "relative" }}
        className={classes.mainContent}
      >
        <Grid xs={12}>
          <div
            className={classes.tableContainerAlign}
            style={{ borderRadius: "20px" }}
          >
            <Table
              style={{ borderCollapse: "separate", borderSpacing: "0 4px" }}
            >
              <TableHead
                style={{ background: "#1D1B36", borderRadius: "20px" }}
              >
                <TableRow>
                  <TableCell
                    align="left"
                    style={{
                      borderRadius: "20px 0px 0px 20px",
                      position: "sticky",
                      left: "0px",
                    }}
                    className={classes.tableHeadText}
                  >
                    Scheme Name
                  </TableCell>
                  <TableCell align="right" className={classes.tableHeadText}>
                    Invested Amount
                  </TableCell>
                  <TableCell align="right" className={classes.tableHeadText}>
                    Purchased Unit
                  </TableCell>
                  <TableCell align="right" className={classes.tableHeadText}>
                    Units Sold
                  </TableCell>
                  <TableCell align="right" className={classes.tableHeadText}>
                    Balance Units
                  </TableCell>
                  <TableCell align="right" className={classes.tableHeadText}>
                    Weighted Average Cost
                  </TableCell>
                  <TableCell align="right" className={classes.tableHeadText}>
                    Current NAV
                  </TableCell>
                  <TableCell align="right" className={classes.tableHeadText}>
                    Current Value
                  </TableCell>
                  <TableCell align="right" className={classes.tableHeadText}>
                    Unrealised Gain/Loss
                  </TableCell>
                  <TableCell align="right" className={classes.tableHeadText}>
                    Realised Gain/Loss
                  </TableCell>
                  <TableCell align="right" className={classes.tableHeadText}>
                    Dividend Payout
                  </TableCell>
                  <TableCell align="right" className={classes.tableHeadText}>
                    Anualised TWRR (%)
                  </TableCell>
                  <TableCell align="right" className={classes.tableHeadText}>
                    Absolute TWRR (%)
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ borderRadius: "0px 20px 20px 0px" }}
                    className={classes.tableHeadText}
                  >
                    XIRR (%)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="tableSticky">
                {gainLossData?.map((s) => {
                  return (
                    <TableRow style={{ background: "#231E53" }}>
                      <TableCell
                        style={{
                          borderRadius: "14px 0px 0px 14px",
                          position: "sticky",
                          left: "0px",
                          backgroundColor: "#231E53",
                          zIndex: "10",
                          boxShadow: " 0px 4px 29px rgba(17, 4, 37, 0.5)",
                        }}
                        align="left"
                        className={`stickyCell ${classes.tableBodyTextOverall}`}
                      >
                        <div style={{ width: "360px !important" }}>
                          <a
                            style={{}}
                            className="cursor"
                            onClick={() => {
                              navigate(routes.mfRiskDetailsPage, {
                                state: s.isin,
                              });
                            }}
                          >
                            {s.schemeName}
                          </a>
                        </div>
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableBodyTextOverall}
                        style={{ position: "relative" }}
                      >
                        {currencyFormat(s.investedAmount)}{" "}
                        <span className="smalltdtop">
                          {" "}
                          Initial Purchase Date:{" "}
                          <strong>
                            {" "}
                            {s.transactionDate
                              ? moment(s.transactionDate).format("DD/MM/YYYY")
                              : ""}{" "}
                          </strong>{" "}
                        </span>{" "}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableBodyTextOverall}
                      >
                        {currencyFormat(s.purchasedUnits)}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableBodyTextOverall}
                      >
                        {currencyFormat(s.soldUnits)}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableBodyTextOverall}
                      >
                        {currencyFormat(s.balanceUnits)}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableBodyTextOverall}
                      >
                        {currencyFormat(s.weightedAvgCost)}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableBodyTextOverall}
                      >
                        {currencyFormat(s.currentNav)}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableBodyTextOverall}
                      >
                        {currencyFormat(s.currentValue)}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableBodyTextOverall}
                      >
                        {currencyFormat(s.unrealisedGainLoss)}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableBodyTextOverall}
                      >
                        {currencyFormat(s.realisedGainLoss)}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableBodyTextOverall}
                      >
                        {currencyFormat(s.dividendPayout)}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableBodyTextOverall}
                      >
                        {Number(s.annualisedTwrr * 100).toFixed(2)}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableBodyTextOverall}
                      >
                        {Number(s.absoluteTwrr).toFixed(2)}
                      </TableCell>
                      <TableCell
                        style={{
                          borderTopRightRadius: "20px",
                          borderBottomRightRadius: "20px",
                        }}
                        align="right"
                        className={classes.tableBodyTextOverall}
                      >
                        {Number(s.xirr).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {gainLossData.length === 0 && <DataNotFound></DataNotFound>}
          </div>
        </Grid>
      </div>
    </>
  );
}

export default MfoOverall;
