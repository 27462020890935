export const API_URL = process.env.REACT_APP_API_URL;
export const API_URL_V2 = process.env.REACT_APP_API_URL_V2;
export const CLIENT_CODE = process.env.REACT_APP_CLIENT_CODE;
export const USER_MODULE_CLIENT_CODE =
  process.env.REACT_APP_USER_MODULE_CLIENT_CODE;
export const API_MF_REPORTS = process.env.REACT_APP_API_MF_REPORTS;
export const CVL_KRA_URL = process.env.REACT_APP_CVL_KRA_URL;
export const CVL_KRA_TOKEN = process.env.REACT_APP_CVL_KRA_TOKEN;
export const CVL_KRA_CLIENT_CODE = process.env.REACT_APP_CVL_KRA_CLIENT_CODE;
export const MUTUAL_FUND_URL = process.env.REACT_APP_MUTUAL_FUND_URL;
export const MUTUAL_FUND_TOKEN = process.env.REACT_APP_MUTUAL_FUND_TOKEN;
export const MUTUAL_FUND_CLIENT_CODE =
  process.env.REACT_APP_MUTUAL_FUND_CLIENT_CODE;
export const REACT_EQUITY_STOCK_CLIENT_CODE =
  process.env.REACT_EQUITY_STOCK_CLIENT_CODE;
export const equity_stock_master_api = process.env.REACT_APP_EQUITY_STOCK;
export const user_role_management_api =
  process.env.REACT_APP_USER_ROLE_MANAGEMENT;
export const image_asset_url = process.env.REACT_APP_IMAGE_ASSET_URL;
export const encrypt_key = process.env.REACT_APP_ENCRYPT_KEY;
export const PARTNER_NAME_TEXT_ON_LOGIN_PAGE =
  process.env.REACT_APP_PARTNER_NAME_TEXT_ON_LOGIN_PAGE;
export const SHOW_ADDITIONAL_DATA_TAB =
  process.env.REACT_APP_SHOW_ADDITIONAL_DATA_TAB;
