import { ResponsivePie } from "@nivo/pie";
import "./chart.css";
import React from "react";
import GlowCircle from "./GlowCircle";

export default function PieChart({
  config,
  children,
  onClickEvent,
  colors,
  data,
  fill,
}) {
  let tempdata = [
    {
      id: "rust",
      label: "rust",
      value: 200,
      color: "hsl(229, 70%, 50%)",
    },
    {
      id: "css",
      label: "css",
      value: 245,
      color: "hsl(35, 70%, 50%)",
    },
    {
      id: "go",
      label: "go",
      value: 310,
      color: "hsl(91, 70%, 50%)",
    },
    {
      id: "java",
      label: "java",
      value: 385,
      color: "hsl(313, 70%, 50%)",
    },
    {
      id: "python",
      label: "python",
      value: 433,
      color: "hsl(129, 70%, 50%)",
    },
  ];
  const defaultMargin = { top: 40, right: 95, bottom: 80, left: 95 };

  tempdata = data ? data : tempdata;
  return (
    <div
      className="donut-chart-container"
      style={{
        height: config.height ? config.height : "380px",
        minWidth: config.minWidth ? config.minWidth : "auto",
      }}
    >
      {children}

      <ResponsivePie
        data={tempdata}
        tooltip={() => ""}
        {...config.chart}
        onMouseEnter={(data) => {
          onClickEvent.click(
            data,
            onClickEvent.set,
            onClickEvent.cstate,
            onClickEvent.ncolors
          );
        }}
        onMouseLeave={(data) => {
          onClickEvent.click(
            data,
            onClickEvent.set,
            onClickEvent.cstate,
            onClickEvent.ncolors
          );
          let fills = [];
          if (onClickEvent.cstate.fill) {
            fills = onClickEvent.cstate?.fill?.map((f) => {
              if (f.id.includes("d")) {
                f.id = f.id.replace("d", "");
                return f;
              } else {
                return f;
              }
            });
          }
          onClickEvent.set({
            title: "",
            value: "",
            colors: onClickEvent.ncolors,
            disabled: [],
            fill: fills,
            hide: false,
          });
        }}
        margin={
          config.margin ? { ...defaultMargin, ...config.margin } : defaultMargin
        }
        innerRadius={config.innerRadius ? config.innerRadius : 0.5}
        padAngle={config?.padAngle >= 0 ? config.padAngle : 4}
        cornerRadius={config?.cornerRadius >= 0 ? config.cornerRadius : 10}
        activeOuterRadiusOffset={5}
        colors={colors ? colors : { scheme: "nivo" }} //{{ scheme: 'nivo' }}
        borderWidth={0}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.2]],
        }}
        enableArcLabels={false}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#faf9ff"
        arcLinkLabelsThickness={1}
        enableArcLinkLabels={config.disableArcLink ? false : true}
        // arcLinkLabelsColor={{ from: 'color' }}
        arcLinkLabel={
          config.arcLinkLabel
            ? config.arcLinkLabel
            : (d) =>
                ` ${
                  d.value + "".charAt(0).toUpperCase() + d?.value + "".slice(1)
                }`
        }
        arcLabelsSkipAngle={10}
        arcLinkLabelsColor="#d4bbfb"
        arcLabelsTextColor={{
          from: "color",
          modifiers: [["darker", 2]],
        }}
        defs={[
          {
            id: "dots",
            type: "patternDots",
            background: "inherit",
            color: "rgba(255, 255, 255, 0.3)",
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: "lines",
            type: "patternLines",
            background: "inherit",
            color: "rgba(255, 255, 255, 0.3)",
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
          {
            id: "c1", //orange
            type: "linearGradient",
            colors: [
              { offset: 0, color: "#F7CB59" },
              { offset: 100, color: "#FF9446" },
            ],
          },
          {
            id: "c2", //green
            type: "linearGradient",
            colors: [
              { offset: 0, color: "#00C1CE" },
              { offset: 100, color: "#82EBA1" },
            ],
          },
          {
            id: "c3", //blue
            type: "linearGradient",
            colors: [
              { offset: 0, color: "#6BCDF7" },
              { offset: 100, color: "#509FF1" },
            ],
          },
          {
            id: "c4", //purple
            type: "linearGradient",
            colors: [
              { offset: 0, color: "#AA6FFE" },
              { offset: 100, color: "#711CF1" },
            ],
          },
          {
            id: "c5", //pink
            type: "linearGradient",
            colors: [
              { offset: 0, color: "#FE6587" },
              { offset: 100, color: "#F52D6A" },
            ],
          },
          {
            id: "c6", //red
            type: "linearGradient",
            colors: [
              { offset: 0, color: "#E96114" },
              { offset: 100, color: "#B80000" },
            ],
          },
          {
            id: "c7", //dark blue
            type: "linearGradient",
            colors: [
              { offset: 0, color: "#3650ed" },
              { offset: 100, color: "#223296" },
            ],
          },
          {
            id: "c9", //dark blue
            type: "linearGradient",
            colors: [
              { offset: 0, color: "#BBB580" },
              { offset: 100, color: "#BBB580" },
            ],
          },
          {
            id: "c10", //dark blue
            type: "linearGradient",
            colors: [
              { offset: 0, color: "#1FAAB2" },
              { offset: 100, color: "#1FAAB2" },
            ],
          },
          {
            id: "dc1", //orange
            type: "linearGradient",
            colors: [
              { offset: 0, color: "#a67a06" },
              { offset: 100, color: "#923e01" },
            ],
          },
          {
            id: "dc6", //red
            type: "linearGradient",
            colors: [
              { offset: 0, color: "#af490f" },
              { offset: 100, color: "#8a0000" },
            ],
          },
          {
            id: "dc3", //blue
            type: "linearGradient",
            colors: [
              { offset: 0, color: "#0a7dad" },
              { offset: 100, color: "#1276df" },
            ],
          },
          {
            id: "dc2", //green
            type: "linearGradient",
            colors: [
              { offset: 0, color: "#008b94" },
              { offset: 100, color: "#0f5e27" },
            ],
          },
          {
            id: "dc4", //purple
            type: "linearGradient",
            colors: [
              { offset: 0, color: "#4401a3" },
              { offset: 100, color: "#21054c" },
            ],
          },
          {
            id: "dc7", //dark blue
            type: "linearGradient",
            colors: [
              { offset: 0, color: "#2638a6" },
              { offset: 100, color: "#121b50" },
            ],
          },
          {
            id: "d9", //dark blue
            type: "linearGradient",
            colors: [
              { offset: 0, color: "9D9762" },
              { offset: 100, color: "9D9762" },
            ],
          },
          {
            id: "d10", //dark blue
            type: "linearGradient",
            colors: [
              { offset: 0, color: "	#157278" },
              { offset: 100, color: "	#157278" },
            ],
          },
        ]}
        fill={fill ? fill : []}
        // fill={props.fill?props.fill:[]}

        legends={
          config?.legends == true
            ? [
                {
                  anchor: "right",
                  direction: "column",
                  justify: false,
                  translateX: 140,
                  translateY: 12,
                  itemsSpacing: 0,
                  itemWidth: 98,
                  itemHeight: 36,
                  itemTextColor: " #FAF9FFB2",
                  itemDirection: "left-to-right",
                  itemOpacity: 1,
                  symbolSize: 10,
                  symbolShape: "circle",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemTextColor: "#fff",
                      },
                    },
                  ],
                },
              ]
            : []
        }
      />
      {/* <ResponsivePie
  enableArcLinkLabels={false}
      margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
      data={data}
      innerRadius={0.88}
      //colors={ ['#A6D997','#E5FFE5','#6FFF6F']}
      colors={ ['#fff','#31BA96', ]}
      borderWidth={4}
      borderColor="#31BA96"
      theme={{}}
      enableArcLabels={false}
    /> */}
    </div>
  );
}
