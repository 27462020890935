const tableHeader = [
  {
    field: "scripName",
    title: "Particular Segment Value",
    align: "left",
    color: "",
  },
  {
    field: "quantity",
    title: "Quantity",
  },
  {
    field: "netRatePerUnit",
    title: "Holding cost avg.cost/unit",
  },
  {
    field: "currentValue",
    title: "Current Value incl.intarested accrued current market pr.",
  },
  {
    field: "holding",
    title: "Holding (%)",
  },
  //   {
  //     field: "dividend",
  //     title: "DIvidend",
  //   },
  //   {
  //     field: "unrealisedGainLoss",
  //     title: "Gain/Loss Unrealised %",
  //   },
  {
    field: "benchmarkIrr",
    title: "IRR & Benchmark IRR",
  },
  {
    field: "excessPerformance",
    title: "Excess Performance",
  },
  {
    field: "priceAsOnBeNCHMARK",
    title: "Price AS ON Benchmark",
  },
];

module.exports = {
  tableHeader,
};
