import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
const useAlertDialogStyles = makeStyles(() => ({
  dialogContentRoot: {
    padding: "0px",
  },
}));
export function AlertDialog({
  open,
  setOpen,
  onApprove,
  data,
  dialogTitle,
  dialogContent,
  dialogFooter,
  paperStyle = {},
}) {
  const navigate = useNavigate();
  const classes = useAlertDialogStyles();
  const handleApproveClick = () => {
    if (onApprove) {
      onApprove(); // Call the passed handleApprove function
    }
    setOpen(false); // Close the dialog
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: "transparent", // Set the dialog background to transparent
            boxShadow: "none", // Optional: removes the default shadow
            width: "621px",
            maxWidth: "700px",
            ...paperStyle,
          },
        }}
        BackdropProps={{
          style: {
            backgroundColor: "rgba(17, 7, 22, 0.50)",
            backdropFilter: "blur(35px)",
          },
        }}
      >
        {dialogTitle()}
        <DialogContent classes={{ root: classes.dialogContentRoot }}>
          {dialogContent(setOpen)}
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          {dialogFooter ? (
            dialogFooter(handleClose)
          ) : (
            <>
              <button
                className="rejectBtnDetailsAccordion"
                onClick={handleClose}
              >
                No
              </button>
              <button
                className="approveBtnDetailsAccordion"
                onClick={handleApproveClick}
              >
                Yes
              </button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
