import React from "react";

export const ErrorCard = ({ className, header, body, footer, ...props }) => {
  return (
    <div className={className ? className : "errorCard"} {...props}>
      {header}
      {body}
      {footer}
    </div>
  );
};
