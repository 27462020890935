import React, { useEffect, useState } from "react";
import Default from "../components/dashboard/defaultPages/Default";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "../components/loader/Loader";
import { checkEmptyList } from "../hooks/constantFunction";
import _ from "lodash";
import { ITEMS_LIMIT_MASTERS_LIST, SCHEME_MASTERS } from "../Constants";
import AppPagination from "../components/verificationUpload/AppPagination";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import { download_scheme, fetchAllMasters } from "../api";
import CustomizedSnackbars from "../components/common/CustomizedSnackbars";
import MastersContent from "../components/dashboard/scheme-masters/MastersContent";
import SearchHeaderSchemeMaster from "../components/dashboard/common/SearchHeaderSchemeMaster";
import { ReactComponent as NextArrow } from "../../src/assets/icons/nextArrow.svg";
import Sectionhr from "../components/common/Sectionhr";
import PageFilter from "../components/dashboard/products/common/PageFilter";
import Breadcrum_route from "../components/utils/Breadcrum_route";
import { ReactComponent as Back } from "../assets/holding-summary/back-blue.svg";
import MasterHeader from "../components/common/MasterHeader";
import excelLogo from "../assets/icons/excel.png";
import GetAppIcon from "@material-ui/icons/GetApp";
import { saveAs } from "file-saver";
import { OnboardingContext } from "../Context";
import ls from "localstorage-slim";
const useStyles = makeStyles((theme) => ({
  searchWrapper: {
    marginTop: "1%",
    marginLeft: "5%",
    marginRight: "5%",
  },
  titleWrapper: {
    marginTop: "54px",
    marginLeft: "5%",
    marginBottom: "-28px",
  },
  titleText: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "24px",
    lineHeight: "30px",
    display: "flex",
    alignItems: "center",
    color: "var(--selectedText)",
  },
}));

function MastersList() {
  const masters = SCHEME_MASTERS;
  const classes = useStyles();
  const navigate = useNavigate();
  const itemLimit = ITEMS_LIMIT_MASTERS_LIST;
  const [showLoder, setShowLoader] = useState(false);
  const [mastersData, setMastersData] = useState(masters);
  const [emptyList, setEmptyList] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [offset, setItemOffset] = useState(0);
  const [searchName, setSearchName] = useState("");
  const { dispatch } = OnboardingContext();
  const fetchMasters = async () => {
    try {
      setShowLoader(true);
      setEmptyList(false);
      const data = await fetchAllMasters(searchName, itemLimit, offset);
      const fetchMastersData = data.data;

      if (fetchMastersData.success) {
        const totalItem = fetchMastersData.data.meta.count;
        setPageCount(Math.ceil(totalItem / itemLimit));
        setMastersData(fetchMastersData.data.result);
        checkEmptyList(fetchMastersData.data.result, setEmptyList);
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
      if (error.response.request.status === 401) {
        navigate("/logout");
      }
    }
  };

  useEffect(() => {
    fetchMasters().then((res) => {});
  }, [searchName, offset]);
  const scopes = JSON.parse(ls.get("scopes"));
  function renderDownload() {
    if (!scopes.can_download_schememaster) {
      return <></>;
    }
    return (
      <div
        style={{
          display: " flex",
          alignItems: " center",
          justifyContent: "flex-end",
          width: "87%",
          gap: "10px",
        }}
      >
        <div
          style={{
            display: " flex",
            alignItems: " center",
            gap: "10px",
            cursor: "pointer",
          }}
          onClick={() => {
            dispatch({
              type: "UPDATE_SNACKBAR_DATA",
              payload: {
                success: true,
                error: false,
                showMsg: true,
                message: "Downloading...",
              },
            });
            download_scheme().then((res) => {
              const data = res.data;
              let blob = new Blob([data], { type: "application/zip" });
              saveAs(blob, "schememaster");
              // const href = window.URL.createObjectURL([res.data]);

              // const anchorElement = document.createElement("a");

              // anchorElement.href = href;
              // anchorElement.download = "Scheme master";

              // document.body.appendChild(anchorElement);
              // anchorElement.click();

              // document.body.removeChild(anchorElement);
              // window.URL.revokeObjectURL(href);
            });
          }}
        >
          <img src={excelLogo} style={{ height: "23px", widows: "32px" }} />{" "}
          DOWNLOAD <GetAppIcon style={{ padding: "0px 6px" }} />
        </div>
      </div>
    );
  }

  return (
    <>
      <Default>
        <CustomizedSnackbars />
        <MasterHeader
          title={"Scheme Master"}
          noback={true}
          btn={renderDownload}
        />
        <div className={classes.titleWrapper}></div>

        <div className={classes.searchWrapper}>
          <SearchHeaderSchemeMaster
            setSearchName={setSearchName}
            showAddButton={false}
          ></SearchHeaderSchemeMaster>
          {showLoder && <Loader />}
          {/* <div
            style={{
              minWidth: "fit-content",
              overflow: "auto",
              width: "100%",
              minWidth: "300px",
            }}
          >
            {mastersData.map((m) => {
              return (
                <div
                  style={{
                    display: "flex",
                    color: "white",
                    width: "100%",
                    background: "#211839",
                    borderRadius: "5px",
                    padding: "16px",
                    marginBottom: "8px",
                    alignItems: "center",
                    minWidth: "fit-content",
                  }}
                >
                  <div style={{ width: "100%" }}>{m.product_name}</div>
                  <div style={{ alignSelf: "flex-end", width: "100px" }}>
                    {" "}
                    <NextArrow />
                  </div>
                </div>
              );
            })}
          </div> */}
          <Sectionhr name={"Name"} />
          <div className="tableconfig">
            <MastersContent
              hideHeader={true}
              mastersData={mastersData}
              emptyList={emptyList}
            ></MastersContent>
          </div>

          <div className="paginationstyl">
            {pageCount > 0 && (
              <div>
                <AppPagination
                  pageCount={pageCount}
                  itemLimit={itemLimit}
                  setItemOffset={setItemOffset}
                  otherProps={{ shape: "rounded" }}
                />
              </div>
            )}
          </div>
        </div>
      </Default>
    </>
  );
}

export default MastersList;
