import { Button, CircularProgress } from "@material-ui/core";
import React from "react";

function LoadingButton({ showLoader, buttonTitle, bgColor, ...restProp }) {
  return (
    <Button
      {...restProp}
      fullWidth
      variant="contained"
      style={{
        background: bgColor,
        borderRadius: "6.37652px",
        paddingBottom: "10px",
        paddingTop: "10px",
        marginTop: "25px",
        color: "#FFFFFF",
      }}
      disabled={showLoader}
    >
      {showLoader ? (
        <CircularProgress sx={{ color: "#FFFFFF" }} />
      ) : (
        buttonTitle
      )}
    </Button>
  );
}

export default LoadingButton;
