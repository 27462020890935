// import React from "react";
// import useStyles from "./Clientdashstyle";

// function AifTabDashboard({ setSummaryType, summaryType, setPage, pageName }) {
//   const classes = useStyles();
//   return (
//     <>
//       {/* <div style={{ cursor: "pointer", width: "350px" }} className={classes.tabDash}>
//                 <a onClick={() => { setPage("consolidated") }} style={{ textDecoration: 'none' }} ><div className={`${classes.tabHeaderText} ${page === 'consolidated' ? classes.active : ''}`}>Consolidated</div></a>
//                 <a onClick={() => { setPage("individual") }} style={{ textDecoration: 'none' }}><div className={`${classes.tabHeaderText} ${page === 'individual' ? classes.active : ''}`}>Individual</div></a>
//             </div> */}
//       <div
//         style={{ width: "400px", margin: "2%", cursor: "pointer" }}
//         className={classes.tabDash}
//       >
//         <a
//           onClick={() => {
//             // setPage("holding");
//             setSummaryType("holding");
//           }}
//           style={{ textDecoration: "none" }}
//         >
//           <div
//             style={{ whiteSpace: "nowrap" }}
//             className={`${classes.tabHeaderText} ${
//               summaryType === "holding" ? classes.active : ""
//             }`}
//           >
//             Holding Summary
//           </div>
//         </a>
//         <a
//           onClick={() => {
//             // setPage("transaction");
//             setSummaryType("transaction");
//           }}
//           style={{ textDecoration: "none" }}
//         >
//           <div
//             style={{ whiteSpace: "nowrap" }}
//             className={`${classes.tabHeaderText} ${
//               summaryType === "transaction" ? classes.active : ""
//             }`}
//           >
//             Transaction Summary
//           </div>
//         </a>
//       </div>
//     </>
//   );
// }

// export default AifTabDashboard;

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import useStyles from "../products/common/ProductsStyles";
import { ReactComponent as Back } from "../../../assets/holding-summary/back-blue.svg";
import NewTabComp from "../products/common/NewTabComp";
import { routes } from "../../../routes";
import { SHOW_ADDITIONAL_DATA_TAB } from "../../../Config";

const HOLDING = "holding";
const TRANSACTION = "transaction";
function AifTabDashboard({ pageName, customHanlde }) {
  const classes = useStyles();
  const navigate = useNavigate();

  let data = [
    {
      page: "holding",
      title: "Holding Summary",
      path: routes.aifSummaryPage_holding,
    },
    {
      page: "transaction",
      title: "Transaction Summary",
      path: routes.aifSummaryPage_transection,
    },
    {
      page: "drawdown",
      title: "Drawdown Summary",
      path: routes.aifDrawdown_summary,
    },
  ];

  return (
    <div className="backBtnContainer">
      <NewTabComp
        data={data}
        customWidth={"-webkit-fill-available"}
        customSelect={data.findIndex((val) => val?.page == pageName)}
        customHandle={customHanlde}
      />
    </div>
  );
}

export default AifTabDashboard;
