import React from "react";
import {
  Breadcrumbs as MUIBreadcrumbs,
  Link,
  Typography,
} from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";

export let obj = {
  market: "market capitalization",
  // "equity summary": "equity",
  equity: "Equity ",
  sector: "sector allocation",
  gain: "gain/loss summary",
  stock: "stock summary",
  stockcompare: "stock comparison",
  holding: "holding summary",
  ["security-holding"]: "Security Holding Summary",
  portfolio: "Portfolio Summary",
  transaction: "transaction summary",
  corporate: "corporate actions",
  drawdown: "drawdown summary",
  //mfo
  detailed: "Detailed Summary",
  segment: "Sub-Category Allocation",
  fund: "Fund Performance",
  //aif
  aif: "AIF",
  // "aif-summary": "Aif",
  report: " ",
  //pms
  pms: "PMS",
  // "pms-summary": "Pms",
  //reits
  reits: "REITS",
  // "reits-summary": "Reits ",
  //invits
  invits: "INVITS",
  // "invits-summary": "Invits Summary",
  //private
  "unlisted-equity": "Unlisted Equity ",
  //mld
  mld: "MLD",
  // "mld-summary": "Mld Summary",
  //bond
  bonds: "Bonds",
  // "bonds-summary": "Bonds",
  life: "insurance",
  // health: "insurance",
  health: "Policies",
  // general: "insurance",
  general: "Policies",
  insurance: " ",
  policies: "Policies",
  icd: "ICD",
  bank: "Bank",
  allocation: "Allocation Summary",
};

const Breadcrumbs = (props) => {
  const location = useLocation();
  let pathname = location.pathname;
  const navigate = useNavigate();
  console.log(location, "location");
  const pathnames = pathname.split("/").filter((x) => x);
  return (
    <MUIBreadcrumbs separator="›" aria-label="breadcrumb">
      {props?.renderClientNameForAdminView()}

      {/* {pathnames.length > 0 ? (
        <Link onClick={() => navigate("/")}>Home</Link>
      ) : (
        <Typography> Home </Typography>
      )} */}

      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;

        return isLast && obj[name] != " " ? (
          <p
            key={name}
            className="breadCrumpFont"
            style={{ color: "white", fontSize: "13px" }}
          >
            {obj[name] || name.replaceAll(/-/g, " ")}
          </p>
        ) : obj[name] != " " ? (
          <Link
            key={name}
            onClick={() => {
              if (props?.state) {
                return navigate(routeTo, { state: props?.state[name] });
              }
              return navigate(routeTo);
            }}
          >
            <p
              className="breadCrumpFont highlightText"
              style={{ fontSize: "14px" }}
            >
              {obj[name] || name.replaceAll(/-/g, " ")}
            </p>
          </Link>
        ) : (
          ""
        );
      })}
    </MUIBreadcrumbs>
  );
};

export default Breadcrumbs;
