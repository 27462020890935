import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";
import { updateTransactionValidationStatus } from "../../api";
import CustomizedSnackbars from "../common/CustomizedSnackbars";
import { OnboardingContext } from "../../Context";
const useConfirmationModalStyles = makeStyles({
  scrollPaper: {
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0.36) 0%, #100A01 100%)",
    backdropFilter: "blur(6px)",
  },
});
export function ConfirmationModal({
  setOpenConfirmationModal,
  openConfirmationModal,
}) {
  const handleClose = () => {
    setOpenConfirmationModal(false);
  };
  const classes = useConfirmationModalStyles();
  const { dispatch } = OnboardingContext();
  const location = useLocation();
  const {
    state: {
      data: { _id, head_id, product_type, file_type },
    },
  } = location;
  const navigate = useNavigate();

  const payload = {
    headId: head_id,
    product_type: product_type,
    file_type: file_type,
  };
  const handleOverride = () => {
    updateTransactionValidationStatus("transaction", _id, payload)
      .then((response) => {
        navigate("/product-upload/?key=validation-checklist");
        setOpenConfirmationModal(false);
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_SNACKBAR_DATA",
          payload: {
            success: false,
            error: true,
            showMsg: true,
            message: error.response.data.error.message,
          },
        });
        console.log(`An error ${error} has occurred`);
        //setOpenConfirmationModal(false);
        //navigate("/product-upload/?key=validation-checklist");
      });
  };
  return (
    <div>
      <CustomizedSnackbars />
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "0.75rem",
          },
        }}
        open={openConfirmationModal}
        onClose={handleClose}
        classes={{ scrollPaper: classes.scrollPaper }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="41"
          height="41"
          viewBox="0 0 41 41"
          fill="none"
        >
          <g clip-path="url(#clip0_4989_10815)">
            <path
              d="M20.5001 40.7883C31.7052 40.7883 40.7887 31.7047 40.7887 20.4996C40.7887 9.29448 31.7052 0.210938 20.5001 0.210938C9.29497 0.210938 0.211426 9.29448 0.211426 20.4996C0.211426 31.7047 9.29497 40.7883 20.5001 40.7883Z"
              fill="#CD4141"
            />
            <path
              d="M17.7527 10.3555H23.2898V22.1905H17.7527V10.3555Z"
              fill="white"
            />
            <path
              d="M20.5001 30.6443C22.0175 30.6443 23.2475 29.4142 23.2475 27.8968C23.2475 26.3795 22.0175 25.1494 20.5001 25.1494C18.9827 25.1494 17.7527 26.3795 17.7527 27.8968C17.7527 29.4142 18.9827 30.6443 20.5001 30.6443Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_4989_10815">
              <rect width="41" height="41" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <h1 className="confirmationHeader">Override Confirmation</h1>
        <p className="confirmationDesc">
          You have chosen to override the duplicate transaction warning and
          proceed with the upload.
        </p>

        <DialogActions
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.5rem",
          }}
        >
          <button
            className="confirmationBtn confirmationModalBtnTxt"
            onClick={handleOverride}
          >
            Confirm
          </button>
          <button
            className="cancelBtnValidationDetailsModal confirmationModalBtnTxt"
            onClick={handleClose}
          >
            Cancel
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
