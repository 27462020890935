import React, { useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  Box,
  Typography,
  useTheme,
} from "@material-ui/core";

import useStyles from "../clientDashboard/Clientdashstyle";

import { ReactComponent as Marketuparrow } from "../../../assets/clientdashboard/marketuparrow.svg";
import { ReactComponent as Crownhead } from "../../../assets/clientdashboard/crown.svg";
import { ReactComponent as Marketdownarrow } from "../../../assets/clientdashboard/marketdownarrow.svg";
import { ReactComponent as Circle } from "../../../assets/clientdashboard/white-circle.svg";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Back } from "../../../assets/holding-summary/back-blue.svg";
import { ReactComponent as Rightangle } from "../../../assets/icons/borderednext.svg";
import { currencyFormat } from "../../../hooks/constantFunction";
import { roundNumber, amountWithSigns } from "../../utils/common";
import { OnboardingContext } from "../../../Context";
import { useContext } from "react";
import { ReactComponent as Crown } from "../../../assets/icons/crown.svg";
import { mfoFilterContext } from "../../../mfo_context/Mfo_Context";
import { validationList } from "../../ProductUploadValidation/productValidationDummyData";
import { IosSwitch } from "../../IosSwitch";
import { ReactComponent as NavigateIcon } from "../../../assets/icons/navaigateArrowIcon.svg";
import { ActiveInactiveToggle } from "../common/ActiveInactiveToggle";
import FullWidthInput from "../../common/FullWidthInput";
import { IndividualSwitch } from "./IndividualSwitch";

function ClientConsolidated({
  headOfFamily,
  endDate,
  loading,
  customCheck,
  individualSwitchComponent,
}) {
  const familyHead = headOfFamily?.find((item) => item.isFamilyHead === true);
  const totalFamilyMembers = headOfFamily?.length;
  const membersArray = headOfFamily?.filter(
    (item) => item.isFamilyHead === false
  );
  const { userState } = OnboardingContext();

  const { setMfofilter, mfoFilter, entity } = useContext(mfoFilterContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const classes = useStyles();
  const handleClick = () => {
    setMfofilter({
      ...mfoFilter,
      checkData: {},
      selectAll: true,
    });
    sessionStorage.setItem(
      "filter",
      JSON.stringify({
        ...mfoFilter,
        checkData: {},
        selectAll: true,
      })
    );
    navigate("/home/portfolio");
  };
  // function to get the first letter
  function getFirsLetterFromMemberName(memberName) {
    if (memberName) {
      let firstName = memberName?.split(" ")[0];
      let lastName = memberName?.split(" ")[memberName?.split(" ")?.length - 1];
      return firstName[0] + lastName[0];
    }
  }
  function getlastnameMemberName(memberName) {
    if (memberName) {
      let lastName = memberName?.split(" ")[memberName?.split(" ").length - 1];
      return lastName;
    }
  }

  function checkChangePercentage(data) {
    if (data == 0) {
      return "(+ " + data.toFixed(2) + "%)";
    } else if (data > 0) {
      return "(+ " + roundNumber(data) + "%)";
    } else {
      return "(" + roundNumber(data) + "%)";
    }
  }

  function handleMemberCardClick(memberId, memberName) {
    // setMfofilter({
    //   ...mfoFilter,
    //   checkData: { [memberId]: true },
    //   selectAll: false,
    // });
    setMfofilter((prevState) => {
      return {
        ...prevState,
        checkData: {
          [memberId]: {
            full_name: memberName,
            member_id: memberId,
            // client_code: memberId,
          },
        },
        selectAll: false,
      };
    });
    sessionStorage.setItem(
      "filter",
      JSON.stringify({
        ...mfoFilter,
        checkData: { [memberId]: true },
        selectAll: false,
      })
    );
    navigate("/home/portfolio", {
      state: { checkData: { [memberId]: true } },
    });
  }
  // customCheck
  // false --> consolidated
  // true --> individual
  return (
    <>
      {!loading && headOfFamily?.length === 0 ? (
        <h1
          className="notFoundText"
          style={{
            height: "50vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          No Data Found!
        </h1>
      ) : (
        <>
          <Grid
            container
            alignItems="center"
            style={{
              maxWidth: "90%",
              margin: "0 auto",
              padding: "0px 1rem",
              marginTop: "1.75rem",
            }}
          >
            <Grid
              item
              xs={2}
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Typography className="headerTxt" style={{ marginLeft: "1rem" }}>
                Member Name
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography
                className="headerTxt centerAlignTxt"
                style={{ marginRight: "1rem" }}
              >
                User Role
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography className="headerTxt centerAlignTxt">
                Members
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className="headerTxt centerAlignTxt">
                Change in Value
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className="headerTxt centerAlignTxt">
                Change in Percentage(%)
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className="headerTxt centerAlignTxt">
                Portfolio Value
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {individualSwitchComponent}
            </Grid>
          </Grid>
          <div
            style={{
              maxWidth: "90%",
              height: "60vh",
              overflow: "scroll",
              margin: "0 auto",
            }}
          >
            {headOfFamily?.map((data, index) => (
              <Grid
                key={index}
                container
                alignItems="center"
                style={{
                  maxWidth: "100%",
                  borderLeft:
                    data?.isFamilyHead &&
                    `7px solid ${theme.palette.memberListing.leftBorder}`,
                  padding: data?.isFamilyHead
                    ? "1.7rem 1.7rem 1.7rem 1rem"
                    : "1.7rem",
                  background: data?.isFamilyHead
                    ? theme.palette.memberListing.gridBodyBackGroundActive
                    : theme.palette.memberListing.gridBodyBackGroundInActive,
                  marginLeft: "0px",
                  marginRight: "0px",
                }}
                className={
                  data?.status != "completed"
                    ? "mainContainer"
                    : "mainContainerInactive"
                }
              >
                <Grid item xs={2}>
                  <Typography
                    className={`gridItemTxt ${
                      data?.status != "completed" ? "" : "inactive"
                    }`}
                  >
                    {/* Direct Equity - Holding Summary */}
                    {data?.memberName}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    className={`gridItemTxt ${
                      data?.status != "completed" ? "" : "inactive"
                    }`}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    {/* Head Of Family */}
                    {data?.memberType}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    className={`gridItemTxt ${
                      data?.status != "completed" ? "" : "inactive"
                    }`}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {data?.memberCount <= 1
                      ? data?.memberCount + " Member"
                      : data?.memberCount + " Members"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Typography
                    className={`gridItemTxt ${
                      data?.status != "completed" ? "" : "inactive"
                    }`}
                  >
                    {currencyFormat(data?.changeValueAmount)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Typography
                    className={`gridItemTxt ${
                      data?.status != "completed" ? "" : "inactive"
                    }`}
                  >
                    {roundNumber(data?.changePercentage)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Typography
                    className={`gridItemTxt ${
                      data?.status != "completed" ? "" : "inactive"
                    }`}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {currencyFormat(data?.portfolioValueAmount)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{ gap: "0.75rem" }}
                  >
                    <svg
                      style={{ cursor: "pointer" }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      onClick={() =>
                        data?.isFamilyHead
                          ? customCheck === true
                            ? handleClick()
                            : handleMemberCardClick(
                                data?.memberId,
                                data?.memberName
                              )
                          : handleMemberCardClick(
                              data?.memberId,
                              data?.memberName
                            )
                      }
                    >
                      <circle
                        cx="10"
                        cy="10"
                        r="10"
                        fill={theme.palette.rightArrowIcon.background}
                      />
                      <path
                        d="M7.28898 14.8013C7.1137 14.6709 7.00972 14.4876 7.00065 14.2931C6.99157 14.0985 7.07817 13.9092 7.24079 13.7681L10.8955 10.5094L7.28095 7.2507C6.92984 6.95678 6.92984 6.48489 7.28095 6.19097C7.64554 5.92166 8.20343 5.93924 8.54203 6.23071L12.759 9.97952C12.9146 10.1183 13.0006 10.3005 13 10.4895C13.0002 10.6765 12.914 10.8565 12.759 10.9929L8.54203 14.7616C8.37552 14.9092 8.14384 14.9951 7.89944 15C7.67272 15.0002 7.45443 14.9291 7.28898 14.8013Z"
                        fill="white"
                      />
                    </svg>
                    {/* <NavigateIcon
                      style={{ cursor: "pointer", background: "red" }}
                      onClick={() =>
                        data?.isFamilyHead
                          ? handleClick()
                          : handleMemberCardClick(
                              data?.memberId,
                              data?.memberName
                            )
                      }
                    /> */}
                  </Box>
                </Grid>
              </Grid>
            ))}
          </div>
        </>
      )}

      {/* Previous Cards Implementation */}
      <div class="containergridCon">
        {/* {familyHead?.memberName && (
          <Card
            className="bigmemberscard details cardCon"
            onClick={handleClick}
            style={{ cursor: "pointer", border: "1px solid #711cf1" }}
          >
            <div
              className="bgcirclesbig svgcirclecolor"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "100%",
              }}
            >
              <div className="mainCardLeftChild" style={{ width: "50%" }}>
                <div className="wid100p">
                  <CardContent
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "15px",
                    }}
                    className="contentleft"
                  >
                    <div
                      className="mrbtm10 bigcrownallmember"
                      style={{
                        position: "relative",
                        marginLeft: "1px",
                        width: "auto",
                      }}
                    >
                      <Crown
                        style={{
                          top: "-37px",
                          height: "46px",
                          width: "46px",
                          left: "27%",
                        }}
                        className="yellowCrown"
                      />

                      <div
                        className="big-avatar-design"
                        style={{
                          width: "90px",
                          height: "90px",
                          top: "20%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "50%",
                          left: "25%",
                          border: "6px solid",
                          background: "#4E189F",
                          boxShadow:
                            "inset -1.67229px 1.67229px 8.36143px rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        {getFirsLetterFromMemberName(familyHead?.memberName)}
                      </div>
                    </div>
                    <Typography className={classes.bigfamilymembername}>
                      {getlastnameMemberName(familyHead?.memberName)}
                    </Typography>
                  </CardContent>
                </div>

                <div className="bigblockbtmtext">
                  <CardContent>
                    <div
                      className="leftcontent fleft"
                      style={{
                        width: "auto",
                      }}
                    >
                      <Typography className={classes.bigdetmembername}>
                        {familyHead?.memberName}
                      </Typography>
                      <Typography className={classes.bigfamilyposn}>
                        Family Head
                      </Typography>
                      <Typography
                        style={{ marginTop: "2%" }}
                        variant="subtitle1"
                      >
                        <span className={classes.headmembernos}>
                          {familyHead?.memberCount > 1
                            ? `${familyHead?.memberCount} Members`
                            : `${familyHead?.memberCount} Member`}
                        </span>
                      </Typography>
                    </div>
                  </CardContent>
                </div>
              </div>
              <div className="mainCardRightChild" style={{ width: "50%" }}>
                <div className="rightcontent">
                  <Typography className={classes.bigfamilytext}>
                    PORTFOLIO VALUE
                  </Typography>
                  <div
                    className={`${classes.bigmemberamt} ${
                      familyHead?.portfolioValueAmount >= 0
                        ? "up-green"
                        : "down-red"
                    }`}
                  >
                    {familyHead?.portfolioValueAmount
                      ? currencyFormat(familyHead?.portfolioValueAmount || 0)
                      : currencyFormat(0)}
                  </div>
                  <div>
                    <Typography className={classes.bigfamilytext}>
                      CHANGE IN VALUE
                    </Typography>

                    <div
                      className={`${classes.headmemberrateup} ${
                        familyHead?.changeValueAmount >= 0
                          ? "up-green"
                          : "down-red"
                      }`}
                      style={{}}
                    >
                      {familyHead?.changeValueAmount >= 0 ||
                      !familyHead?.changeValueAmount ? (
                        <Marketuparrow className="mrright5"></Marketuparrow>
                      ) : (
                        <Marketdownarrow className="mrright5"></Marketdownarrow>
                      )}
                      {familyHead?.changeValueAmount
                        ? amountWithSigns(familyHead?.changeValueAmount || 0)
                        : amountWithSigns(0)}
                      <br />
                    </div>
                    <Typography className={classes.bigfamilytext}>
                      CHANGE IN PERCENTAGE
                    </Typography>
                    <span
                      style={{
                        display: "inline-block",
                        fontSize: "14px",
                      }}
                      className={
                        familyHead?.changePercentage >= 0
                          ? "up-green"
                          : "down-red"
                      }
                    >
                      {familyHead?.changePercentage
                        ? checkChangePercentage(
                            familyHead?.changePercentage || 0
                          )
                        : checkChangePercentage(0)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        )} */}

        {/* {membersArray?.map((member, index) => (
          <>
            <Card
              className="root memberscard details bgcircles cardCon"
              onClick={() =>
                handleMemberCardClick(member?.memberId, member?.memberName)
              }
              style={{
                cursor: "pointer",
                height: "257px",
              }}
            >
              <div className="wid50p fleft">
                <CardContent className="contentleft">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10%",
                      marginLeft: "9%",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "50%",
                        width: "30%",
                        height: "4vw",
                      }}
                    >
                      <span
                        className="avatar-design"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        {getFirsLetterFromMemberName(member?.memberName)}
                      </span>
                    </div>
                    <div>
                      <Typography className={classes.headmembername}>
                        {member?.memberName}
                      </Typography>
                      <Typography variant="subtitle1">
                        <span
                          style={{ marginLeft: "-7px" }}
                          className={classes.headmembernos}
                        >
                          {member?.memberCount > 1
                            ? `${member?.memberCount} Members`
                            : `${member?.memberCount} Member`}
                        </span>
                      </Typography>
                    </div>
                  </div>
                </CardContent>
              </div>

              <div className="controls">
                <CardContent className="contentright">
                  <Typography className={classes.childMembertext}>
                    PORTFOLIO VALUE
                  </Typography>
                  <Typography
                    className={`${classes.headmemberamt} ${
                      member?.portfolioValueAmount >= 0
                        ? "up-green"
                        : "down-red"
                    }`}
                  >
                    {member?.portfolioValueAmount
                      ? currencyFormat(member?.portfolioValueAmount || 0)
                      : currencyFormat(0)}
                  </Typography>
                  <div>
                    <Typography className={classes.childMembertext}>
                      CHANGE IN VALUE
                    </Typography>
                    <div
                      className={`${classes.headmemberrateup} ${
                        member?.changeValueAmount >= 0 ? "up-green" : "down-red"
                      }`}
                    >
                      {member?.changeValueAmount >= 0 ||
                      !member?.changeValueAmount ? (
                        <Marketuparrow className="mrright5"></Marketuparrow>
                      ) : (
                        <Marketdownarrow className="mrright5"></Marketdownarrow>
                      )}
                      {member?.changeValueAmount
                        ? amountWithSigns(member?.changeValueAmount || 0)
                        : amountWithSigns(0)}
                      <br />
                    </div>
                    <Typography className={classes.childMembertext}>
                      CHANGE IN PERCENTAGE
                    </Typography>
                    <div
                      style={{
                        display: "block",
                        fontSize: "14px",
                      }}
                      className={
                        member?.changePercentage >= 0 ? "up-green" : "down-red"
                      }
                    >
                      {member?.changePercentage
                        ? checkChangePercentage(member?.changePercentage || 0)
                        : checkChangePercentage(0)}
                    </div>
                  </div>
                </CardContent>
              </div>
            </Card>
          </>
        ))} */}
      </div>
    </>
  );
}

export default ClientConsolidated;
