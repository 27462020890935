import React from "react";
import { useState } from "react";
import PageFilter from "../common/PageFilter";
import TabComponent from "../common/TabComponent";
import HoldingSummaryComp from "../HoldingSummaryComp";
import SummaryDetailsComp from "../SummaryDetailsComp";
import HoldingAdvisor from "./HoldingAdvisor";
import HoldingFamily from "./HoldingFamily";
import HoldingPerformance from "./HoldingPerformance";

import HoldingTopHoldings from "./HoldingTopHoldings";
import HoldingTransaction from "./HoldingTransaction";
import NewTabComp from "../common/NewTabComp";
import { routes } from "../../../../routes";
import EngagmentLayout from "../newDesigns/common/EngagmentLayout";
import EquityEngagment from "./HoldingEngagment";
import TopPerformers from "./TopPerformers";
import { NewFilter } from "../../newFilter/NewFilter";
import Cashflows from "./Cashflows";
import HoldingSummary from "./HoldingSummary";
import {
  tableConfigExcessPerformance,
  tableConfigHoldingSummary,
  tableConfigSecurityHoldingSummary,
  tableConfigTransactionSummary,
  tableConfigUpcomingTransaction,
} from "./utils/constands";
import { IosSwitch } from "../../../IosSwitch";
import { SHOW_ADDITIONAL_DATA_TAB } from "../../../../Config";
import InvestedVSCurrent from "./InvestedVSCurrent";
import { HoldingAssetAllocation } from "./HoldingAssetAllocation";
import { HoldingProductWiseAllocation } from "./HoldingProductWiseAllocation";
import { HoldingReportTop5Amc } from "./HoldingReportTop5Amc";
import { HoldingReportTop5Issuers } from "./HoldingReportTop5Issuers";
import { HoldingPortfolioReportingAgeing } from "./HoldingPortfolioReportingAgeing";
import { HoldingAssetClassBenchMark } from "./HoldingAssetClassBenchMark";
import { HoldingMemberWisePerformance } from "./HoldingMemberWisePerformance";
import ExcessPerformance from "./ExcessPerformance";
import PortFolioOverviewPage from "./PortFolioOverviewPage";
import UpcomingTransaction from "./UpcomingTransaction";
let data = [];
if (SHOW_ADDITIONAL_DATA_TAB === "show") {
  data = [
    { page: "summary", title: "Summary", path: routes?.homeHoldingPage },

    {
      page: "holding",
      title: "Holding Summary",
      path: routes?.homeHoldingTop,
    },
    {
      page: "security-holding",
      title: "Security Holding Summary",
      path: routes?.homeSecurityHodling,
    },
    {
      page: "transaction",
      title: "Transaction Summary",
      path: routes?.homeHoldingTransaction,
    },
    // {
    //   page: "upcoming-transaction",
    //   title: "Upcoming Transaction",
    //   path: routes?.homeHoldingUpcomingTransaction,
    // },
    {
      page: "portfolio-overview",
      title: "Portfolio Overview",
      path: routes?.portFolioOverView,
    },
    {
      page: "excess-performance",
      title: "Excess performance",
      path: routes?.excessPerformance,
    },
    // TODO Uncomment when API is ready for Integration
    {
      page: "asset-class-benchmark",
      title: "Asset Class Vs BenchMark",
      path: routes?.homeHoldingAssetClassBenchMark,
    },

    {
      path: routes?.homeHoldingAdvisorEngagment,
      title: "Engagement Model",
      page: "engagment",
    },
    {
      path: routes?.homeHoldingAdvisor,
      title: "Advisors",
      page: "advisor",
    },

    {
      path: routes?.homeHoldingAssetAllocation,
      title: "Asset Allocation",
      page: "asset-allocation",
    },
    {
      path: routes?.homeHoldingProductWiseAllocation,
      title: "Product Wise Allocation",
      page: "product-wise-allocation",
    },
    {
      path: routes?.reportingPortfolioAgeing,
      title: "Portfolio Ageing",
      page: "portfolio-ageing",
    },
    {
      path: routes?.cashflows,
      title: "Cash Flow",
      page: "cashflows",
    },
    {
      path: routes?.homeHoldingMemberWisePerformance,
      title: "Member Wise Performance",
      page: "member-wise-performance",
    },
    {
      path: routes?.InvestedVSCurrent,
      title: "investment vs current value",
      page: "invested-vs-current",
    },

    {
      page: "top-performer",
      title: "Top Performers",
      path: routes?.homeHoldingTopPerformer,
    },
    {
      page: "bottom-performer",
      title: "Bottom Performers",
      path: routes?.homeHoldingBottomPerformer,
    },
    {
      page: "top-5-amc",
      title: "Top 5 AMC",
      path: routes?.homeHoldingTop5Amc,
    },
    {
      page: "top-5-issuers",
      title: "Top 5 Issuers",
      path: routes?.homeHoldingTop5Issuers,
    },
  ];
} else {
  data = [
    { page: "summary", title: "Summary", path: routes?.homeHoldingPage },

    {
      page: "holding",
      title: "Holding Summary",
      path: routes?.homeHoldingTop,
    },
    {
      page: "security-holding",
      title: "Security Holding Summary",
      path: routes?.homeSecurityHodling,
    },
    {
      page: "transaction",
      title: "Transaction Summary",
      path: routes?.homeHoldingTransaction,
    },
    // {
    //   page: "upcoming-transaction",
    //   title: "Upcoming Transaction",
    //   path: routes?.homeHoldingUpcomingTransaction,
    // },
    {
      page: "portfolio-overview",
      title: "Portfolio Overview",
      path: routes?.portFolioOverView,
    },
    {
      page: "excess-performance",
      title: "Excess performance",
      path: routes?.excessPerformance,
    },
    // TODO Uncomment when API is ready for Integration
    {
      page: "asset-class-benchmark",
      title: "Asset Class Vs BenchMark",
      path: routes?.homeHoldingAssetClassBenchMark,
    },

    {
      path: routes?.homeHoldingAdvisorEngagment,
      title: "Engagement Model",
      page: "engagment",
    },

    {
      path: routes?.homeHoldingProductWiseAllocation,
      title: "Product Wise Allocation",
      page: "product-wise-allocation",
    },
    // {
    //   path: routes?.reportingPortfolioAgeing,
    //   title: "Portfolio Ageing",
    //   page: "portfolio-ageing",
    // },
    {
      path: routes?.cashflows,
      title: "Cash Flow",
      page: "cashflows",
    },
    {
      path: routes?.homeHoldingMemberWisePerformance,
      title: "Member Wise Performance",
      page: "member-wise-performance",
    },

    {
      page: "top-performer",
      title: "Top Performers",
      path: routes?.homeHoldingTopPerformer,
    },
    {
      page: "bottom-performer",
      title: "Bottom Performers",
      path: routes?.homeHoldingBottomPerformer,
    },
    {
      page: "top-5-amc",
      title: "Top 5 AMC",
      path: routes?.homeHoldingTop5Amc,
    },
    {
      page: "top-5-issuers",
      title: "Top 5 Issuers",
      path: routes?.homeHoldingTop5Issuers,
    },
  ];
}

function HoldingReport({ pageName }) {
  const [page, setPage] = useState(pageName);
  const [openModal, setOpenModal] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [checked, setChecked] = useState(false);

  const changePage = (p) => {
    console.log(p, "agaga");
    switch (p) {
      case "summary":
        return (
          <SummaryDetailsComp
            customOpenModal={openModal}
            setCustomOpenModal={setOpenModal}
          ></SummaryDetailsComp>
        );

      // case "performance":
      //   return <HoldingPerformance></HoldingPerformance>;

      case "cashflows":
        return <Cashflows />;

      case "holding":
        return (
          <HoldingSummary
            tableConfig={tableConfigHoldingSummary}
            key={"holding"}
            type={"holdings"}
            showCorporateActions={() => {}}
          />
        );
      case "excess-performance":
        return <ExcessPerformance tableType={"top"} type={"transaction"} />;

      case "portfolio-overview":
        return <PortFolioOverviewPage tableType={"top"} type={"transaction"} />;
      case "security-holding":
        return (
          <HoldingSummary
            tableConfig={tableConfigSecurityHoldingSummary}
            // key={"security-holding"}
            extype={"holdings"}
            type="security-holding"
            showCorporateActions={() => {}}
          />
        );

      case "transaction":
        return (
          <HoldingSummary
            typeOfPage={"transaction"}
            tableName={"transaction-summary"}
            key={"transaction"}
            type={"transaction"}
            showCorporateActions={
              <IosSwitch
                switchLabelName="Show Corporate Action"
                checked={checked}
                setChecked={setChecked}
              />
            }
            tableConfig={tableConfigTransactionSummary}
          />
        );
      case "upcoming-transaction":
        return (
          <UpcomingTransaction
            typeOfPage={"upcoming-transaction"}
            tableName={"Upcoming Transaction"}
            key={"upcoming-transaction"}
            type={"upcoming-transaction"}
            showCorporateActions={<></>}
            tableConfig={tableConfigUpcomingTransaction}
          />
        );
      case "asset-class-benchmark":
        return <HoldingAssetClassBenchMark></HoldingAssetClassBenchMark>;

      case "advisor":
        return <HoldingAdvisor></HoldingAdvisor>;
      case "asset-allocation":
        return <HoldingAssetAllocation />;
      case "product-wise-allocation":
        return <HoldingProductWiseAllocation />;
      case "portfolio-ageing":
        return (
          <HoldingPortfolioReportingAgeing key={"holding"} type={"holdings"} />
        );
      case "engagment":
        return <EquityEngagment />;

      case "transactions":
        return <HoldingTransaction></HoldingTransaction>;

      case "family":
        return <HoldingFamily></HoldingFamily>;
      case "member-wise-performance":
        return <HoldingMemberWisePerformance></HoldingMemberWisePerformance>;
      case "invested-vs-current":
        return <InvestedVSCurrent></InvestedVSCurrent>;
      case "top-performer":
        return <TopPerformers tableType={"top"} type={"transaction"} />;
      case "bottom-performer":
        return <TopPerformers tableType={"bottom"} type={"transaction"} />;
      case "top-5-amc":
        return <HoldingReportTop5Amc />;
      case "top-5-issuers":
        return <HoldingReportTop5Issuers />;

      default:
        return <HoldingSummaryComp></HoldingSummaryComp>;
    }
  };

  const handleTabChange = (page) => {
    setPage(page);
  };

  return (
    <>
      <PageFilter
        customOpenModal={openModal}
        setCustomOpenModal={setOpenModal}
        backLink={routes?.homeHoldingSubHead}
        setFilterModalOpen={setFilterModalOpen}
      ></PageFilter>
      <NewFilter
        filterModalOpen={filterModalOpen}
        setFilterModalOpen={setFilterModalOpen}
        productType="dashboard"
      />
      {/* <div style={{ margin: "2%" }}>
        <TabComponent
          data={data}
          page={page}
          setPage={setPage}
          type={"state"}
          // width={"100%"}
        ></TabComponent>
      </div> */}
      <div style={{ margin: "2%" }}>
        <NewTabComp
          data={data}
          customHandle={handleTabChange}
          customSelect={data.findIndex((val) => val?.page == page)}
        />
      </div>
      {changePage(page)}
    </>
  );
}

export default HoldingReport;
