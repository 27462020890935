import { useState } from "react";
import TabIpoSub from "../../common/TabIpoSub";
import IpoDetailsClosed from "./IpoDetailsClosed";
import IpoDetailsListed from "./IpoDetailsListed";
import IpoDetailsOpen from "./IpoDetailsOpen";
import IpoDetailsUpcoming from "./IpoDetailsUpcoming";
import useStyles from "../../common/ProductsStyles";
import TabComponent from "../../common/TabComponent";

const data = [
  { page: "open", title: "Open" },
  { page: "closed", title: "Closed" },
  { page: "upcoming", title: "Upcoming" },
  { page: "listed", title: "Listed" },
];

function IpoDetails(props) {
  const classes = useStyles();
//   const [page, setPage] = useState(props.page2);

  const changePage = (p) => {
    switch (p) {
      case "open":
        return <IpoDetailsOpen />;

      case "closed":
        return <IpoDetailsClosed />;

      case "upcoming":
        return <IpoDetailsUpcoming />;

      case "listed":
        return <IpoDetailsListed />;

      default:
        return <IpoDetailsOpen />;
    }
  };

  return (
    <>
      {/* {props.page === "ipo_details" ? (
        // <TabIpoSub page={page} setPage={setPage} />
        <div style={{ float: "right", marginTop: "-60px", marginRight: "2%" }}>
          <TabComponent
            data={data}
            page={page}
            setPage={setPage}
            type={"state"}
            width={"fit-content"}
          ></TabComponent>
        </div>
      ) : (
        ""
      )} */}
      {changePage(props.page2)}
    </>
  );
}

export default IpoDetails;
