import {
  Button,
  Dialog,
  Grid,
  Modal,
  Radio,
  Typography,
  makeStyles,
} from "@material-ui/core";
import moment from "moment";
import React, { useRef } from "react";
import { useState } from "react";
// import useStyles from "../products/common/ProductsStyles";
import { ReactComponent as Filter } from "../../../assets/icons/filter.svg";
import { ReactComponent as Sidearrow } from "../../../assets/icons/sidearrow.svg";
import { ReactComponent as Closeicon } from "../../../assets/icons/closeicon.svg";
import SearchInput from "../products/common/SearchInput";
import CheckBoxWithColor from "../products/common/CheckBoxWithColor";
import { ReactComponent as Search } from "../../../assets/icons/search.svg";
// import LineChart from "../../charts/LineChart";

import Loader from "../../loader/Loader";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { dateFormatCommon, roundNumber } from "../../utils/common";
import FilterCalendar from "../../calendar/FilterCalendar";
import {
  getMfFundPerformance,
  get_index_performance,
  get_index_subclass,
} from "../../../api";
import { useContext } from "react";
import { mfoFilterContext } from "../../../mfo_context/Mfo_Context";
import { OnboardingContext } from "../../../Context";
import { ReactComponent as Eye } from "../../../assets/icons/white-eye.svg";
import { lazy } from "react";
import { Suspense } from "react";
// import ApexLine from "../../charts/ApexLine";
import ApexNew from "../../charts/ApexNew";
import DataNotFound from "../products/common/DataNotFound";
import GraphIconHeader from "./GraphIconHeader";
import NewHeadAndDetailsComp from "../products/newDesigns/common/newHeadingAndDetailsComp/NewHeadAndDetailsComp";

import { get_path_type, assetclass } from "../../utils/strHelper";
import { selection } from "d3-selection";
import { CheckBox } from "@material-ui/icons";
import { useDebounce } from "../products/common/util/useDebounce";
import Checkboxicon from "../strategyBuilder/common/checkboxicon";
import ApexArea from "../../charts/ApexArea";
import { capitalize } from "lodash";

const LineChart = lazy(() => import("../../charts/LineChart"));
export default function AreaChart(props) {
  const useStyles = makeStyles((theme) => ({
    backDrop: {
      backdropFilter: "blur(5px)",
      background: "rgba(31, 28, 64, 0.51)",
    },
  }));
  const [checkedChartIndex, setCheckedChartIndex] = useState({
    "Portfolio Value": true,
    TWRR: false,
  });
  let path = get_path_type(window.location.pathname);
  const [checkedindex, setCheckedIndex] = useState({});
  const wrapperRef = useRef(null);
  const classes = useStyles();
  const [display, setDisplay] = useState(false);
  const [clear, setclear] = useState(false);
  const [search, setSearch] = useState("");
  const [subAssetClass, setsubAssetClass] = useState("");
  const [range, setRange] = useState("ITD");
  const [customRange, setCustomRange] = useState([null, null]);
  const [filteredData, setFilteredData] = useState([]); // filtered data for graph based on google contol range
  const [filterRange, setFilterRange] = useState([]);
  const [dataRange, setDataRange] = useState({
    // endDate: moment().format("YYYY-MM-DD"),
    // startDate: moment().subtract(5, "years").format("YYYY-MM-DD"),
  });
  const [loading2, setLoading2] = useState(false);
  const [googleData, setgoogleData] = useState([]);
  const [ticks, setTicks] = useState([]);
  const [linedata, setlinedata] = useState([]);
  const [indexPerformance, setIndexPerformance] = useState([]);
  const [min, setMin] = useState(0);
  const [list, setlist] = useState([]);
  const [schIndexlist, setSchIndexList] = useState([]);
  const navigate = useNavigate();
  const [xval, setxval] = useState([]);
  const colors = ["#F52D6A", "#82EBA1", "#FF9446"];
  const [format, setFormat] = useState("DD-MMM-YYYY");
  const assetlist =
    path == "portfolio"
      ? [
          ...Object.keys(assetclass[path]).filter((k) => {
            if (k != "cash") {
              return true;
            }
          }),
          "hybrid",
        ]
      : [...Object.keys(assetclass[path]), "hybrid"];
  // console.log("asset list", path, props.type, assetlist);
  const [tempchecked, setTempChecked] = useState({});
  const [section, setSection] = useState({
    section: Object.keys(assetclass[path])[0] || "",
    subsection: "",
    selected: [],
  });
  const [subSection, setSubSection] = useState([]);
  const [inputvalue, setInputValue] = useState("");
  const config = {
    enablePoints: true,
    curve: "linear",
    disablePointLabel: true,
    disableAxisBottom: true,
    pointSize: 0.1,
    nopoint: true,
    hide_legends: false,
    leftAxis: {
      legend: "Portfolio Value",
      renderTick: (tick) => {
        return (
          <g transform={`translate(${tick.x - 30},${tick.y})`}>
            <text
              textAnchor="middle"
              dominantBaseline="middle"
              style={{
                fill: "#b0aab7",
                fontSize: 10,
              }}
            >
              {tick.value}
            </text>
          </g>
        );
      },
    },
    extra_props: {
      enableSlices: "x",
      sliceTooltip: (data) => {
        return (
          <div className="tooltip_container">
            {/* <br /> */}
            <div className="subtext tooltip_text">
              {data.slice.points[0].data.x
                ? dateFormatCommon(data.slice.points[0].data.x, "YYYY-MM-DD")
                : ""}
            </div>
            {data.slice.points.map((d, i) => {
              return (
                <div
                  style={{
                    display: "flex",
                    fontFamily: "Avenir",
                    paddingLeft: "25px",
                    paddingRight: "19px",
                    lineHeight: "24px",
                  }}
                >
                  <div className="text_ellipse">{d.id}</div> :{" "}
                  {roundNumber(d?.data?.z)}{" "}
                </div>
              );
            })}
          </div>
        );
      },
    },
  };
  const [selectedRange, setSelectedRange] = useState("1D");
  const rangearr = ["1D", "1M", "3M", "1Y", "3Y", "5Y", "10Y", "ALL", "Custom"];

  function returnTicks(data) {
    let mapdata = [];
    let arr = [];
    data.map((m, i) => {
      if (i == 0) {
        return;
      }

      if (!mapdata.includes(moment(m[0]).format("YYYY"))) {
        mapdata.push(moment(m[0]).format("YYYY"));
        arr.push({ v: m[0], f: moment(m[0]).format("YYYY") });
      }
    });
    return arr;
  }

  function fetchSubclass(a) {
    let params = { assetClass: a?.toLowerCase() };
    return get_index_subclass(params).then((res) => {
      setSubSection(res.result);
      return res.result;
    });
  }

  const { userState } = OnboardingContext();
  const clientId = userState.loginedUserData.id;

  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  let params = { ...getfilter_as_params() };
  const graphTypeMap = {
    "Portfolio Value": "portfolio",
    TWRR: "twrr",
  };
  function mfo_performance(clientId, params) {
    setLoading2(true);
    let CompareType =
      graphTypeMap[
        Object.keys(checkedChartIndex).find((type) => {
          if (checkedChartIndex[type] == true) {
            return type;
          }
        })
      ];
    getMfFundPerformance(
      clientId,
      { ...dataRange, ...params, ...props?.params },
      props?.type,
      props?.exp
    )
      .then((res) => {
        let arr = [];
        let legendss = [];
        let current = [];
        let gdata = {};
        let jn = 0;
        // console.log("sorted", arr);
        // console.log(res.result, "Res res");
        let r = props.exp ? "result" : "results";
        Object.keys(res?.result)?.map((a, j) => {
          jn = j + 1;

          let obj = {
            name: capitalize(a),
          };
          let data = [];
          res?.result?.[a]?.map((d, i) => {
            data.push([d.d, d.a?.toFixed(2), d.a]);
          });
          obj.data = data;

          arr.push(obj);
        });

        let gotdata = arr.find((a) => {
          if (a.data.length > 0) {
            return true;
          }
        });
        if (!gotdata) {
          arr = [];
        }

        // if (props.type === "dashboard") {
        //   let i = -1;
        //   for (let a of arr) {
        //     i = i + 1;
        //     if (
        //       a[0]?.name == "My Portfolio" &&
        //       a[1]?.name == "Target Portfolio" &&
        //       a[2]?.name == "Unique Client Index"
        //     ) {
        //       break;
        //     }
        //     if (a.name == "My Portfolio") {
        //       let temp = arr[0];
        //       arr[0] = a;
        //       arr[i] = temp;

        //       a = temp;
        //     }
        //     if (a.name == "Target Portfolio" && i != 1) {
        //       let temp = arr[1];
        //       arr[1] = a;
        //       arr[i] = temp;

        //       a = temp;
        //     }
        //     if (a.name == "Unique Client Index" && i != 2) {
        //       let temp = arr[2];
        //       arr[2] = a;
        //       arr[i] = temp;

        //       a = temp;
        //     }
        //   }
        // }

        // alert("hello");
        // console.log("sorted", arr);

        setlinedata(arr);

        setLoading2(false);
      })
      .catch((err) => {
        setLoading2(false);
        setlinedata([]);
        setgoogleData([]);
        setFilteredData([]);
      });
  }

  //   useEffect(() => {
  //
  //   }, [filteredData]);

  const clonedViewBtn = React.cloneElement(props?.viewDetailBtn || <></>, {
    style: {
      width: "20%",
      margin: "0% 40% 0",
      color: "#FFFFFF",
      borderRadius: "7px",
      backgroundColor: "#4f1fda !important",
    },
  });

  useEffect(() => {
    const clientId = userState.loginedUserData.id;
    if (props.start && props.start.state == false) {
      return;
    }
    let params = { ...getfilter_as_params() };

    if (Object.keys(checkedindex).length > 0) {
      params["indexId"] = Object.keys(checkedindex).join(",");

      //params["assetClass"] = section.section;
      mfo_performance(clientId, { ...params, ...dataRange });
    } else if (props.exp) {
      mfo_performance(clientId, { ...params, ...dataRange });
    } else {
      mfo_performance(clientId, { ...params, ...dataRange });
    }
  }, [checkedindex, dataRange, mfoFilter, props?.start, checkedChartIndex]);
  useEffect(() => {
    if (props?.type == "bank") {
      return;
    }
    if (search != "" && search) {
      let params = {
        assetClass: section.section,
        limit: 10,
        search: search,
      };
      if (subAssetClass) {
        params["subAssetClass"] = subAssetClass;
      }
      get_index_performance(null, params)
        .then((res) => {
          setIndexPerformance(res.result);
          setSchIndexList(res.result);
        })
        .catch((err) => {});
    } else {
      setSchIndexList([]);
    }
  }, [search]);
  useEffect(() => {
    if (props?.type == "bank") {
      return;
    }
    let params = {
      assetClass: section.section,
      limit: 10,
      search: search,
    };
    fetchSubclass(section.section).then((res) => {
      // console.log(res, "sub classes");
      setSection({
        section: section.section,
        subsection: res[0] || "",
      });
      getindexlist(res[0]);
    });
  }, []);

  const debouncedRequest = useDebounce(() => {
    setSearch(inputvalue);
  });

  const onChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    debouncedRequest();
  };

  function getindexlist(a, b) {
    let params = {
      assetClass: b || section.section,

      limit: 10,
    };
    if (a && a != " ") {
      params["subAssetClass"] = a;
    }
    setsubAssetClass(a);
    get_index_performance(null, params)
      .then((res) => {
        // console.log(res, "response");
        setIndexPerformance(res.result);
        setlist(res.result);
        setSchIndexList([]);
      })
      .catch(() => {
        setIndexPerformance([]);
        setlist([]);
        setSchIndexList([]);
      });
    setlist(indexPerformance);
  }
  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const hideAlert = () => {
    setTimeout(() => {
      const customDiv = document.getElementById("customAlert");
      customDiv.style.display = "none";
    }, 2000);
  };

  const handleClickOutside = (event) => {
    // setSchIndexList([]);
    if (checkedindex.length == 0) {
      setSection({
        section: "",
        subsection: "",
        selected: [],
      });
    }

    const { current: wrap } = wrapperRef;
    if (wrap && !wrap.contains(event.target)) {
      // setDisplay(false);
      // setclear(true);
    }
  };

  const handleAdvisorCheck1 = (event) => {
    if (search && search != "") {
      let index = 0;
      let found = list.find((l, i) => {
        if (l.sectionId == event.target.name) {
          index = i;
          return l;
        }
      });
      if (!found) {
        let sch = schIndexlist.find((l, i) => {
          if (l.sectionId == event.target.name) {
            return l;
          }
        });
        console.log(sch, "sch");
        list.unshift(sch);
      } else {
        list.splice(index, 1);
        console.log(found, "found");
        list.unshift(found);
      }
    }

    if (tempchecked[event.sectionId]) {
      delete tempchecked[event.sectionId];
      setTempChecked({ ...tempchecked });
    } else {
      if (Object.keys(tempchecked).length > 1) {
        return;
      }
      tempchecked[event.sectionId] = true;
      setTempChecked({ ...tempchecked });
    }
  };

  return (
    <div
      style={{
        position: "relative",
        // paddingBottom: props.noPaddingBottom ? "none" : "230px",  //new change done to hide time line and y axis
        paddingBottom: props.noPaddingBottom ? "none" : "0px",
      }}
      // className={classes.mainContent}
    >
      {loading2 && !props?.loading && (
        <Loader style={{ marginLeft: "3%", marginTop: "-4.5%" }}></Loader>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "2%",
        }}
      >
        <Typography
          className={`${classes.mainHeading} ${classes.typoInsideAlign}`}
        >
          {/* {Object.keys(checkedChartIndex)[0]} */}
          <NewHeadAndDetailsComp
            title={
              props?.type == "bank"
                ? "Bank balance over a period of time"
                : "My Portfolio Performance"
            }
            content={``}
          />
        </Typography>

        <div
          style={{
            width: "50%",
            display: "flex",
            justifyContent: " flex-end",
            alignItems: "center",
          }}
        >
          <div>
            <Modal
              disableBackdropClick={true}
              onClose={() => {}}
              open={display}
              className="filerDialog"
              BackdropProps={{
                classes: {
                  root: classes.backDrop,
                },
              }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "transparent",
              }}
            >
              <div
                style={{
                  background: "transparent",
                  minHeight: "570px",
                  outline: 0,
                }}
              >
                <div
                  className="summaryDetailsHeadSec"
                  style={{ marginLeft: "28px" }}
                >
                  <Typography className="summaryDetailsHead">
                    Benchmark Filter
                  </Typography>
                  <Typography className="summaryDetailsTxt opacity">
                    Select Benchmarks across different categories for <br></br>{" "}
                    an accurate and detailed comparison.
                  </Typography>
                </div>

                <div className="filterContainer">
                  {/* //first list */}
                  <div
                    style={{
                      width: "194px",
                      background:
                        "linear-gradient(180deg, #600CA3 0%, #160829 110.75%)",
                      height: "470px",
                      color: "white",
                    }}
                  >
                    {/* //header */}
                    <div
                      style={{
                        paddingLeft: "28px",
                        paddingTop: "26px",
                        paddingBottom: "33px",
                      }}
                    >
                      <div className="fheader">Asset Class</div>
                      <div className="ftext opacity">
                        Select Benchmarks across different categories
                      </div>
                    </div>
                    {/* list */}
                    <div className="flist capitalize">
                      {assetlist.map((a, k) => (
                        <li
                          style={{
                            display: "flex",
                            background: section.section == a ? "#1C0832" : "",
                          }}
                          onClick={() => {
                            setSection({
                              section: a,
                              subsection: "",
                              selected: [],
                            });

                            if (a == "alternate" || a == "hybrid") {
                              getindexlist(" ", a);
                            } else {
                              fetchSubclass(a);
                              setSubSection([]);
                            }
                          }}
                        >
                          <div style={{ width: "100%" }}>{a}</div>
                          <div
                            className="liarrow"
                            style={{
                              display: section.section == a ? "flex" : "",
                              alignItems: "center",
                            }}
                          >
                            <Sidearrow />
                          </div>
                        </li>
                      ))}
                    </div>
                  </div>
                  {/*//second list */}
                  <div
                    style={{
                      width: "194px",
                      background:
                        "linear-gradient(180deg, #600CA3 -100%, #160829 81.75%)",
                      height: "470px",
                      color: "white",
                      opacity: "100%",
                      display:
                        section.section == "alternate" ||
                        section.section == "hybrid"
                          ? "none"
                          : "",
                    }}
                  >
                    {/* //header */}

                    {/* list */}
                    <div
                      className="flist capitalize li2"
                      style={{ height: "470px" }}
                    >
                      {subSection.map((a, k) => (
                        <li
                          style={{
                            background:
                              section.subsection == a ? "rgb(8, 7, 8)" : "",
                          }}
                          onClick={() => {
                            setSection({
                              section: section.section,
                              subsection: a,
                              selected: [],
                            });
                            getindexlist(a);
                          }}
                        >
                          {a}
                        </li>
                      ))}
                    </div>
                  </div>
                  {/* third list */}
                  <div
                    style={{
                      background:
                        "linear-gradient(180deg, #0B0717 0%, #00010C 100%)",
                      minWidth: "294px",
                      height: "470px",
                      color: "white",
                      maxWidth: "446px",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: -10,
                        right: -10,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setDisplay(false);
                      }}
                    >
                      <Closeicon></Closeicon>
                    </div>
                    <div
                      style={{
                        marginTop: "26px",
                        marginBottom: "5px",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "28px",
                          background: "#1C0832",
                          paddingLeft: "16px",
                          borderRadius: "6px",
                          marginRight: "28px",
                        }}
                      >
                        <Search></Search>

                        <input
                          placeholder="Search"
                          className="newMenuSearchBar"
                          type="text"
                          onChange={onChange}
                        />
                      </div>
                    </div>
                    <div className="flist">
                      {((list?.length == 0 && schIndexlist?.length == 0) ||
                        (search && schIndexlist?.length == 0)) && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                          }}
                          className="subtext"
                        >
                          No Benchmark Listed
                        </div>
                      )}
                      {tempchecked &&
                        !search &&
                        list?.map((a, k) => (
                          <li
                            style={{
                              display: "flex",
                            }}
                            onClick={(e) => {
                              handleAdvisorCheck1(a);
                            }}
                          >
                            <checkbox
                              style={{ width: "100%" }}
                              checked={tempchecked[a.sectionId] ? true : false}
                              value={tempchecked[a.sectionId] ? true : false}
                              name={a.sectionId}
                            >
                              {a.indexName}
                            </checkbox>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                paddingLeft: "25px",
                              }}
                            >
                              <Checkboxicon
                                checked={
                                  tempchecked[a.sectionId] ? true : false
                                }
                              />
                            </div>
                          </li>
                        ))}
                      {tempchecked &&
                        search &&
                        schIndexlist?.map((a, k) => (
                          <li
                            style={{
                              display: "flex",
                              background: tempchecked[a.sectionId]
                                ? "#1C0832"
                                : "",
                            }}
                          >
                            <checkbox
                              style={{ width: "100%" }}
                              checked={tempchecked[a.sectionId] ? true : false}
                              value={tempchecked[a.sectionId] ? true : false}
                              name={a.sectionId}
                              onClick={(e) => {
                                handleAdvisorCheck1(a);
                              }}
                            >
                              {a.indexName}
                            </checkbox>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                paddingLeft: "25px",
                              }}
                            >
                              <Checkboxicon
                                checked={
                                  tempchecked[a.sectionId] ? true : false
                                }
                              />
                            </div>
                          </li>
                        ))}
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                      }}
                    >
                      <button
                        className={
                          Object.keys(tempchecked).length == 0
                            ? "applybtn nodisplay"
                            : "applybtn"
                        }
                        disabled={
                          Object.keys(tempchecked).length == 0 ? true : false
                        }
                        onClick={() => {
                          setCheckedIndex(tempchecked);
                          setDisplay(false);
                        }}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>

      <div className="chart_padding" style={{ paddingBottom: "0px" }}>
        <div>
          <div className="line_container">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                background: "#0c061a",
                zIndex: "1",
              }}
            >
              <div className="filter_btn_container ">
                <Button
                  onClick={() => {
                    setRange("1M");
                    setCustomRange([null, null]);

                    setDataRange({
                      endDate: moment().format("YYYY-MM-DD"),
                      startDate: moment()
                        .subtract(1, "months")
                        .format("YYYY-MM-DD"),
                    });
                  }}
                  style={{ textDecoration: "none", minWidth: "10px" }}
                  to="#"
                >
                  <div
                    className={`inner_sub_text  ${
                      range === "1M" ? "active_range" : ""
                    }`}
                    style={{ margin: "0px" }}
                  >
                    1M
                  </div>
                </Button>
                <Button
                  onClick={() => {
                    setRange("3M");
                    setCustomRange([null, null]);
                    setDataRange({
                      endDate: moment().format("YYYY-MM-DD"),
                      startDate: moment()
                        .subtract(3, "months")
                        .format("YYYY-MM-DD"),
                    });
                  }}
                  style={{ textDecoration: "none", minWidth: "10px" }}
                  to="#"
                >
                  <div
                    className={`${"inner_sub_text"} ${
                      range === "3M" ? "active_range" : ""
                    }`}
                    style={{ margin: "0px" }}
                  >
                    3M
                  </div>
                </Button>
                <Button
                  onClick={() => {
                    setRange("1Y");
                    setCustomRange([null, null]);

                    setDataRange({
                      endDate: moment().format("YYYY-MM-DD"),
                      startDate: moment()
                        .subtract(1, "years")
                        .format("YYYY-MM-DD"),
                    });
                  }}
                  style={{ textDecoration: "none", minWidth: "10px" }}
                  to="#"
                >
                  <div
                    className={`${"inner_sub_text"} ${
                      range === "1Y" ? "active_range" : ""
                    }`}
                    style={{ margin: "0px" }}
                  >
                    1Y
                  </div>
                </Button>
                <Button
                  onClick={() => {
                    setRange("3Y");
                    setCustomRange([null, null]);

                    // generate("WEEKLY",2,moment().subtract(3, 'years').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
                    setDataRange({
                      endDate: moment().format("YYYY-MM-DD"),
                      startDate: moment()
                        .subtract(3, "years")
                        .format("YYYY-MM-DD"),
                    });
                  }}
                  style={{ textDecoration: "none", minWidth: "10px" }}
                  to="#"
                >
                  <div
                    className={`${"inner_sub_text"} ${
                      range === "3Y" ? "active_range" : ""
                    }`}
                    style={{ margin: "0px" }}
                  >
                    3Y
                  </div>
                </Button>
                <Button
                  onClick={() => {
                    setRange("5Y");
                    setCustomRange([null, null]);

                    setDataRange({
                      endDate: moment().format("YYYY-MM-DD"),
                      startDate: moment()
                        .subtract(5, "years")
                        .format("YYYY-MM-DD"),
                    });
                  }}
                  style={{ textDecoration: "none", minWidth: "10px" }}
                  to="#"
                >
                  <div
                    className={`${"inner_sub_text"} ${
                      range === "5Y" ? "active_range" : ""
                    }`}
                    style={{ margin: "0px" }}
                  >
                    5Y
                  </div>
                </Button>
                <Button
                  onClick={() => {
                    setRange("ITD");
                    setDataRange({});
                    setCustomRange([null, null]);
                  }}
                  style={{ textDecoration: "none", minWidth: "10px" }}
                  to="#"
                >
                  <div
                    className={`${"inner_sub_text"} ${
                      range === "ITD" ? "active_range" : ""
                    }`}
                    style={{ margin: "0px" }}
                  >
                    ITD
                  </div>
                </Button>
                <Button
                  style={{
                    textDecoration: "none",
                    textTransform: "capitalize",
                    minWidth: "10px",
                  }}
                  to="#"
                >
                  <div
                    className={`${"inner_sub_text"} ${
                      range === "custom" ? "active_range" : ""
                    }`}
                    style={{ margin: "0px" }}
                  >
                    <FilterCalendar
                      values={customRange}
                      minDate={{ min: 5, type: "years" }}
                      maxDate={{
                        current: new Date(moment().format("YYYY-MM-DD")),
                        min: 5,
                        type: "years",
                      }}
                      type="1"
                      hideinput={true}
                      customLabel="Custom"
                      customStyle={{
                        width: "100%",
                        textAlign: "center",
                        borderRadius: "20px",
                        padding: "0px",
                        background: "transparent",
                        border: "none",
                        color: "#FFFFFF",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontFamily: "Avenir",
                        fontWeight: "500",
                        cursor: "pointer",
                      }}
                      customCalenderPostion={{
                        right: "0px",
                        top: "-240px",
                      }}
                      onchange={(newCustomRange) => {
                        setCustomRange(newCustomRange);
                        setRange("custom");
                        setDataRange({
                          startDate: moment(newCustomRange[0]).format(
                            "YYYY-MM-DD"
                          ),
                          endDate: moment(newCustomRange[1]).format(
                            "YYYY-MM-DD"
                          ),
                        });
                      }}
                    />
                  </div>
                </Button>
              </div>
            </div>
            {linedata.length > 0 && (
              <div style={{ position: "relative", top: "-18px" }}>
                {" "}
                <div style={{ height: "300px" }}>
                  {" "}
                  <ApexArea
                    plot={props.plot}
                    type={"area"}
                    checkedChartIndex={checkedChartIndex}
                    controller_disabled={true}
                    min={min}
                    max={100}
                    linedata={linedata}
                    grid={{ x: false, y: false }}
                    config={{
                      colors: ["#F2F7A1", "#6D67E4", "#46C2CB", "#453C67"],
                    }}
                    title={{
                      y:
                        checkedChartIndex?.TWRR == true
                          ? "TWRR"
                          : props.type == "bank"
                          ? "Portfolio Returns"
                          : " Portfolio Value",
                      x: props.type == "bank" ? " " : null,
                    }}
                    hidetooltipLabels={props.exp ? [] : null}
                  />
                </div>
              </div>
            )}
            {linedata.length < 1 && !loading2 && (
              <div
                style={{
                  height: "78%",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  // position:'absolute'
                }}
              >
                <DataNotFound />
              </div>
            )}
          </div>
        </div>
      </div>

      <Grid
        xs={12}
        style={{
          marginLeft: "50%",
          position: props?.type == "bonds" ? "" : "absolute",
          bottom: "5%",
          right: "5%",
        }}
      ></Grid>
      {linedata.length > 0 ? props.viewDetailBtn : clonedViewBtn}
    </div>
  );
}
