import * as Yup from "yup";

let requiredTXT = "Required";
let panError = "Invalid PAN!";
let invalidDateMsg = "Invalid Date";

let entityName = Yup.string()
  .matches(/^[a-z\d\-_\s]+$/i, "Only alphanumeric are allowed")
  .required(requiredTXT);
let basicRequired = Yup.string().required(requiredTXT);
let dateOfBirth = Yup.date().required(requiredTXT);
let email = Yup.string().email("Invalid email address").required(requiredTXT);
let panNumber = Yup.string()
  .matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, panError)
  .required(requiredTXT);
let password = Yup.string()
  .matches(/^\d{4}$/, "Pin must be exactly 4 digits")
  .required(requiredTXT);
let otp = Yup.string()
  .matches(/^\d{8}$/, "OTP must be exactly 8 digits")
  .required(requiredTXT);
let mobile = Yup.string()
  .matches(/^\d{10}$/, "Mobile number must have 10 digits")
  .required(requiredTXT);

let OTP = Yup.string()
  .required("OTP is required")
  .matches(/^[0-9]{6}$/, "OTP must be a 6 digit number");

let pin = Yup.string()
  .required("PIN is required")
  .matches(/^\d{4}$/, "PIN must be a 4-digit number");
let daterange = Yup.date().max(new Date(), "Invalid Date");
let futureDate = Yup.date().min(new Date(), "Invalid Date");
let printDaterange = Yup.date().when("type", {
  is: (val) => val != "Tax Report" || val != "Cashflow Summary Report",
  then: Yup.date().max(new Date(), "Invalid Date"),
});
let printEndDaterange = Yup.date().when("type", {
  is: (val) => val != "Tax Report" || val != "Cashflow Summary Report",
  then: Yup.date().max(new Date(), "Invalid Date"),
});
let printcheck = Yup.boolean().when("type", {
  is: (val) => val != "Holding Summary Report",
  then: Yup.boolean().required(requiredTXT),
});
let printBasicRequired = Yup.string().when("type", {
  is: (val) => {
    // console.log(val, "value");
    if (val != "Tax Report" && val != "Cashflow Summary Report") {
      return true;
    }
  },
  then: Yup.string().required(requiredTXT),
});
let endDaterange = Yup.date()
  .max(new Date(), "Invalid Date")
  // .min(Yup.ref("startDate"), "End Date can't be before start date")
  .required("Invalid Date");
let confirmPin = Yup.string()
  .oneOf([Yup.ref("pin"), null], "PINs do not match")
  .required("Confirm PIN is required");
let multiselect = Yup.lazy((val) =>
  Array.isArray(val)
    ? Yup.array().min(1, "Required").required("Required")
    : Yup.object().required()
);

let agreeToTerms = Yup.boolean().oneOf(
  [true],
  "You must agree to the terms and conditions"
);

export const logInValidationSchema = Yup.object({
  userName: email,
  password: password,
  rememberInfo: Yup.boolean(),
});

export const registerForm1ValidationSchema = Yup.object({
  entityName: entityName,
  entityType: basicRequired,
  panNumber: panNumber,
  dateOfBirth: dateOfBirth,
  email: email,
});
export const registerForm4ValidationSchema = Yup.object({
  entityName: entityName,
  entityType: basicRequired,
  panNumber: panNumber,
  dateOfBirth: dateOfBirth,
  email: email,
  mobile: mobile,
});
export const printForm = Yup.object({
  startDate: printDaterange,
  endDate: printEndDaterange,
  type: basicRequired,
  exportType: basicRequired,
  denomination: printBasicRequired,
  client: multiselect,
  familyHolding: printcheck,
});
export const scheduleForm = Yup.object({
  startDate: futureDate,

  client: multiselect,
});

export const registerForm2ValidationSchema = Yup.object({
  entityName: entityName,
  // entityType: basicRequired,
  dateOfIncorporation: basicRequired,
  panNumber: panNumber,
  // dateOfBirth: dateOfBirth,
  // email: email,
  kycDate: dateOfBirth,
  kycStatus: basicRequired,
  address: basicRequired,
  commencementDate: dateOfBirth,
  // pan: basicRequired,
  // idProof: basicRequired,
});

export const OTPValidationSchema = Yup.object({
  otp: OTP,
});

export const pinValidationSchema = Yup.object({
  pin: pin,
  confirmPin: confirmPin,
  // agreeToTerms: agreeToTerms,
});
