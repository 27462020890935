export function extractFileName(s3Key) {
  if (typeof s3Key !== "string") {
    return "-";
  }
  const fullFileName = s3Key.split("/").pop();
  // Handle edge case where no '.' is present
  if (!fullFileName.includes(".")) {
    return fullFileName;
  }
  const fileName = fullFileName.split(".").slice(0, -1).join(".");
  return fileName;
}

export function getStyleBasedOnVerificationStatus(verificationStatus) {
  if (verificationStatus === "approved") {
    return { color: "#18AD91", marginRight: "6px" };
  } else {
    return { color: "#FF7878", marginRight: "6px" };
  }
}
// To Display In Drop Down Menu
// To convert valuation, unit-based/valuation, transaction ==> Valuation, Unit Based Valuation, Transaction
export function formatString(str) {
  return str
    .split(/[-\/]/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
    .join(" ");
}

// To Send in the Listing API Call
// To convert Valuation, Unit Based Valuation, Transaction ==> valuation, unit-based/valuation, transaction
export function revertFormatString(str) {
  return str
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace("unit-based-valuation", "unit-based/valuation");
}
