import React from "react";
import { ReactComponent as CoinIcon } from "../../../assets/icons/CoinIcon.svg";
import { CheckedSvgIcon } from "./common/CheckedSvgIcon";
import { useNewFilterStyles } from "./newFilterStyles";
export const NewFilterCurrency = () => {
  const classes = useNewFilterStyles();
  return (
    <div className="newFilterCurrencyContainer">
      <div className={classes.currencyCard}>
        <h1 className="currencyCardTxt">Rupees</h1>
        <div className="currencyCardIconContainer">
          <div className="currencyCheckedUncheckedIcon">
            <CheckedSvgIcon />
          </div>
          <div className="currencyCoinIcon">
            <CoinIcon />
          </div>
        </div>
      </div>
    </div>
  );
};
