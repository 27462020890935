import React from "react";
import SelectRole from "./SelectRole";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { handlePanInfo, headers } from "./utils/utils";
import axios from "axios";
import { CVL_KRA_URL } from "../../Config";
import PartnerOnboarding1 from "./PartnerOnboarding1";
import PartnerOnboarding2 from "./PartnerOnboarding2";
import RepresentativeConfirm from "./common/RepresentativeConfirm";
import { BackdropComponent } from "./common/BackDropComponent/BackDropComponent";
import { createPartner, createPartnerUser } from "../../api";
import PartnerOnboarding3 from "./PartnerOnboarding3";
import PartnerSuccess from "./PartnerSuccess";
import SetPin from "./SetPin";
import usePrtnerOnboardingFLow from "./hooks/usePrtnerOnboardingFLow";

function NewSignUp() {
  const { state } = useLocation();
  const [next, setNext] = useState(state?.step ? state?.step : 0); //starts form 0 only state?.step ? state?.step :
  // const [next, setNext] = useState(10);
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const navigate = useNavigate();
  const [formData, setFormData] = useState(
    state?.formData ? state?.formData : { representative: {} }
  );
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [next2, setNext2] = useState(0); //starts form 0 only state?.step ? state?.step :
  const total = 7;

  const handleBack = (step) => {
    if (step >= 1) setNext(step);
    handleNext(-1);
  };

  const handleNext = (stepAddition, formData1, client) => {
    setNext(next + stepAddition);
    let state = {
      formData: formData1 ? formData1 : formData,
      step: next + stepAddition,
    };
    navigate("/register", {
      state: { ...state },
    });

    setApiErrorMessage("");
  };

  const handleConfirmation = async (value) => {
    setFormData((prev) => ({ ...prev, ...value }));
    setOpen(true);
  };

  const { api1, api2, api5 } = usePrtnerOnboardingFLow({
    setFormData,
    setLoading,
    setApiErrorMessage,
    handleNext,
    formData,
  });

  const handleStep1 = (value) => {
    if (value?.entityType === "individual") {
      let newValue = {
        entityName: value?.entityName,
        entityType: value?.entityType,
      };

      api2(undefined, undefined, newValue).then(() => {
        setNext2(() => {
          setFormData((perv) => ({ ...perv, ...value }));
          // api2(value);
          return 1;
        });
      });
    } else {
      // if (value?.panNumber !== "") {
      //   api1(value);
      // } else {
      // setFormData(() => ({ ...data }));
      setFormData((prev) => {
        handleNext(1, { ...prev, ...value });
        return { ...prev, ...value };
      });
      // }
    }
  };

  const hadnleStep4 = (value) => {
    // console.log(value, "aglknanglanglkj");
    // if (value?.panNumber !== "") {
    //   api1(value, true);
    // } else {
    setFormData((prev) => {
      handleNext(1, { ...prev, ...value });
      return { ...prev, representative: { ...value } };
    });
    // }
  };

  const changeInStep = (next) => {
    switch (next) {
      case 0:
        return (
          <SelectRole
            handleBack={handleBack}
            handlePartnerClick={() => {
              handleNext(1);
            }}
            handleClientClick={() => {
              // setClient(true);
              // setNext(next + 1);
              navigate("/register/client", { state: { step: 1 } });
              // setClient(true);
            }}
            hideBackButton={true}
          />
          //   <>
          //     <p>asif</p>
          //   </>
        );
      case 1:
        return (
          <PartnerOnboarding1
            currentStep={next}
            handleBack={handleBack}
            handleNext={handleNext}
            customHandleSubmit={handleStep1}
            apiErrorMessage={apiErrorMessage}
            loading={loading}
            formData={formData}
            total={total}
            head={{
              // main: "Add Representative Details",
              sub: " ",
              desc: {
                center: [
                  {
                    title: "Who is a Partner?",
                    description:
                      "A partner refers to an individual or entity who is who provides financial guidance to their clients.",
                  },
                ],
              },
            }}
          />
        );
      case 2:
        return (
          <PartnerOnboarding2
            currentStep={next}
            handleBack={handleBack}
            handleNext={handleNext}
            customHandleSubmit={(value) => {
              handleConfirmation(value);
              // api2(value, true);
            }}
            formData={formData}
            apiErrorMessage={apiErrorMessage}
            loading={loading}
            total={total}
            head={{
              // main: "Add Representative Details",
              sub: " ",
              desc: {
                center: [
                  {
                    title: "What Does a Partner Do?",
                    description:
                      "A partner helps its clients make informed decisions and helps on navigating through intricate financial landscapes to achieve the client's long term financial goals.",
                  },
                ],
              },
            }}
            customLeftMargin={"19px"}
          />
        );
      case 3:
        return <RepresentativeConfirm handleNext={setNext} />;
      case 4:
        return (
          <PartnerOnboarding1
            onlyPerosn={true}
            currentStep={next}
            head={{
              main: "Add Representative Details",
              sub: " ",
              desc: {
                center: [
                  {
                    title: "What’s a Representative?",
                    description:
                      "A Partner Representative serves as a liaison between the client and the the wealth management firm or entity. They help in fostering a strong client - advisor relationship",
                  },
                ],
              },
            }}
            handleBack={(step) => {
              handleBack(step);
              if (formData?.entityType === "individual") {
                setNext2(1);
              }
            }}
            handleNext={setNext}
            customHandleSubmit={hadnleStep4}
            apiErrorMessage={apiErrorMessage}
            loading={loading}
            formData={formData?.representative}
            showMobileField={true}
            total={formData?.entityType === "individual" ? total - 2 : total}
            stepDeviation={formData?.entityType === "individual" ? 2 : 0}
            entityName={formData?.entityName}
            hide={["entityType"]}
            entityType={formData?.entityType}
            gap={"15px"}
          />
        );
      case 5:
        return (
          <PartnerOnboarding2
            currentStep={next}
            handleBack={handleBack}
            handleNext={setNext}
            head={{
              main:
                formData?.entityType === "individual"
                  ? "Partner & Representative Details"
                  : "Representative Details",
              sub: " ",
              desc: {
                center: [
                  {
                    title: "What’s a Partner?",
                    description:
                      "A Partner Representative serves as a liaison between the client and the the wealth management firm or entity. They help in fostering a strong client - advisor relationship",
                  },
                ],
              },
            }}
            customHandleSubmit={(values) => {
              api5(values);
            }}
            formData={formData?.representative}
            apiErrorMessage={apiErrorMessage}
            loading={loading}
            total={formData?.entityType === "individual" ? total - 2 : total}
            stepDeviation={formData?.entityType === "individual" ? 2 : 0}
            partnerData={formData}
          />
        );
      case 6:
        return (
          <PartnerOnboarding3
            key="email"
            currentStep={next}
            handleBack={handleBack}
            handleNext={handleNext}
            title={
              formData?.entityType === "individual"
                ? "Partner Representative Email Verification"
                : "Representative’s Email Verification"
            }
            subTitle="We have sent an OTP on your email ID"
            comType="email"
            userId={formData?.user?.id}
            entityType="partner-user"
            resendEntityType="representative"
            totalStep={
              formData?.entityType === "individual" ? total - 2 : total
            }
            stepDeviation={formData?.entityType === "individual" ? 2 : 0}
            head={{
              desc: {
                center: [
                  {
                    title: "Why Email Verification?",
                    description:
                      "Email verification is crucial for ensuring the accuracy and security of user information. Its a vital step in maintaining data integrity and building trust within our platform.",
                  },
                ],
              },
            }}
          />
        );
      case 7:
        return (
          <PartnerOnboarding3
            key="mobile"
            currentStep={next}
            handleBack={handleBack}
            handleNext={handleNext}
            title={
              formData?.entityType === "individual"
                ? "Partner Representative Mobile Verification"
                : "Mobile Verification"
            }
            subTitle="We have sent an OTP on your Mobile no"
            comType="sms"
            userId={formData?.user?.id}
            entityType="partner-user"
            resendEntityType="representative"
            totalStep={
              formData?.entityType === "individual" ? total - 2 : total
            }
            stepDeviation={formData?.entityType === "individual" ? 2 : 0}
            head={{
              desc: {
                center: [
                  {
                    title: "Why Mobile Verification?",
                    description:
                      "Mobile verification is essential to establish a secure ad reliable user experience.",
                  },
                ],
              },
            }}
          />
        );
      case 8:
        return (
          <PartnerSuccess
            subData={
              "Your Email & Mobile Number has been verified. Click on continue to set pin. "
            }
            noStep={true}
            handleBack={handleBack}
            handleNext={() => {
              handleNext(1);
            }}
            buttonTxt={"Continue"}
            hideBottomSec={true}
          />
        );
      case 9:
        return (
          <SetPin
            handleBack={handleBack}
            handlenext={handleNext}
            userId={formData?.user?.id}
          />
        );
      case 10:
        return (
          <PartnerSuccess
            handleBack={handleBack}
            subData={
              // formData?.entityType === "individual"
              // ?
              "Your Pin has been set successfully"
              // : "Congratulation Onboard Process Is Completed. Please Continue To Login"
            }
            buttonTxt="Go To Login Page"
            noStep={true}
            handleNext={() => {
              navigate("/");
            }}
          />
        );

      default:
        return navigate("/");
    }
  };

  console.log(formData, "formData");

  // ## New Individual Flow starts from here ##

  const newIndividualFlow = (step) => {
    // console.log("I am here 1");
    switch (step) {
      case 1: {
        return (
          <RepresentativeConfirm
            customBack={() => {
              setFormData((prev) => {
                setNext(() => 1);
                setNext2(0);
                return { ...prev, entityType: "" };
              });
            }}
            handleNext={() => {
              setNext2(2);
              setNext(4);
            }}
          />
        );
      }

      // default: {
      //   return navigate("/");
      // }
    }
  };
  // ## New Individual Flow ends from here ##

  return (
    <>
      <BackdropComponent
        // setConfirmation={setConfirmation}
        customSetOpen={setOpen}
        customOpen={open}
        customHandleAccept={() => {
          api2(formData, true);
        }}
      />
      {next2 == 1 && formData?.entityType === "individual"
        ? newIndividualFlow(next2)
        : changeInStep(next)}
    </>
  );
}

export default NewSignUp;
