import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { OnboardingContext } from "../../Context";
import { useContext } from "react";
import { mfoFilterContext } from "../../mfo_context/Mfo_Context";
import { mfoStaticFilter } from "../utils/common";

function Logout() {
  const { setMfofilter, mfoFilter } = useContext(mfoFilterContext);
  const { dispatch } = OnboardingContext();
  const {
    userState: { loginedUserData },
  } = OnboardingContext();

  // let userType = loginedUserData?.user_type;
  // if (userType === "mfo") {
  //   userType = "mfo-user";
  // }

  useEffect(() => {
    dispatch({
      type: "UPDATE_LOGIN_USERDATA",
      payload: {},
    });
    setMfofilter(mfoStaticFilter);
    localStorage.clear();
    sessionStorage.clear();
  }, []);

  window.location.href = `/`;
}

export default Logout;
