export const objectFormatingHolding = (data, tableConfig) => {
  let newList = [];

  Object.keys(tableConfig).map((val) => {
    let newRow = [
      { show: tableConfig[val]?.show, title: tableConfig[val]?.title },
      ...Object.keys(data).map((colVal) => {
        let val24 = {};
        Object.keys(data[colVal][val]).map((val2) => {
          val24 = {
            ...val24,
            [val2]: data[colVal][val][val2] ? data[colVal][val][val2] : "--",
          };
        });

        return {
          value: val24,
          owner: colVal,
          row: val,
          show: tableConfig[val]?.show,
          title: tableConfig[val]?.title,
        };
      }),
    ];
    // let a = Object.keys(data).map((colVal) => {
    //   return {
    //     value: data[colVal][val],
    //     owner: colVal,
    //     row: val,
    //     show: tableConfig[val]?.show,
    //     title: tableConfig[val]?.title,
    //   };
    // });
    newList.push(newRow);
  });

  return newList;
};

export const tableHeadConfiguration = (data) => {
  const headingData = [
    { subHeading: ["Portfolio Performance Summary"] },
    ...Object.keys(data).map((val) => ({
      subHeading: ["Market Value", "Portfolio %"],
      owner: val,
    })),
  ];

  return headingData;
};
