import React, { useEffect, useState } from "react";
import PageContainer from "../PageContainer";
import Header from "../Components/Header";
import PrintTable from "../Components/Table";
import ApexNew_print from "../Components/ApexNew_print";
import chartData from "../../../charts/chartData";
import { ReactComponent as Question } from "../images/question.svg";
import PieChart from "../../../charts/PieChart";
import Print_PieChart from "../Components/Print_PieChart";
import TabCards from "../Components/TabCards";
import { roundNumber } from "../../../utils/common";
import { date } from "yup/lib/locale";
import { currencyFormat } from "../../../../hooks/constantFunction";
import { MemberModel } from "../Components/utils/staticData";
import { objectFormatingWithTitleCOlWrapper } from "../Components/utils/common";
import { Link, Typography } from "@material-ui/core";
import {
  assetVbenchmark,
  benchmarkVasset,
  excessPerformance,
  newtopPerformancTable,
  topPerformanceTable,
} from "../../products/holding_summary/utils/constands";
import { isArray } from "lodash";

export default function AssetvsBenchmark({
  data,
  denomination,
  title,
  datasrc,
  pdfInfo,
}) {
  const getDenomination = localStorage.getItem("denomination");

  const colors = ["#4B64ED", "#BDC5F2"];
  const [tableData, setTabledata] = useState([]);
  const asset = ["equity", "debt", "alternate", "cash"];

  useEffect(() => {
    let tabledata = [];
    asset.map((a) => {
      data[datasrc[0]]?.data.find((item) => {
        if (a == item.assetClass) {
          let excess = data[
            "dashboard-asset-wise-excess-performance-xirr"
          ].find((as) => {
            if (a == as?.assetClass) {
              return as;
            }
          });
          // console.log(excess, "excess");
          if (!excess) {
            excess = {};
          }
          let excessPerformance = excess?.excessPerformance;
          let benchmarkXirr = excess?.benchmarkXirr;
          let xirr = excess.portfolioXirr;
          if (a.toLocaleLowerCase() == "alternate") {
            excessPerformance = "N/A";
            benchmarkXirr = "N/A";
          }
          tabledata.push({
            ...item,
            excessPerformance: excessPerformance,
            benchmarkXirr: benchmarkXirr,
            xirr,
          });
          return true;
        }
      });
      data[datasrc[1]].find((asset) => {
        if (a == asset.assetClass) {
          let benchmarkName = asset.benchmarkName;
          if (a.toLocaleLowerCase() == "alternate") {
            benchmarkName = "N/A";
          }
          tabledata.push({
            ...asset,
            colspan: 7,
            ignore: true,
            asset: asset.assetClass,
            assetClass: benchmarkName,
            benchmarkName,
          });
          return true;
        }
      });
    });
    tabledata.push({
      assetClass: "Total",

      investedAmount: data?.["dashboard-gain-loss"]?.investedAmount,
      overAllInvestedAmount:
        data?.["dashboard-gain-loss"]?.overAllInvestedAmount,

      portfolioValueAmount: data?.["dashboard-gain-loss"]?.portfolioValueAmount,
      realisedGainLoss: data?.["dashboard-gain-loss"]?.realisedGainLoss,
      totalGainLoss: data?.["dashboard-gain-loss"]?.totalGainLoss,
      xirr: data?.["excess-performance-xirr"]?.portfolioXirr,
      benchmarkXirr: data?.["excess-performance-xirr"]?.benchmarkXirr,
      excessPerformance: data?.["excess-performance-xirr"]?.excessPerformance,
    });
    // console.log(tabledata);
    setTabledata(
      objectFormatingWithTitleCOlWrapper(
        {
          data: tabledata || [],
        },
        header,
        [],
        "#ADC371",
        undefined,
        // totaRowData ? totaRowData : hideTotalRow ? undefined : total,
        undefined,
        {},
        denomination,
        "fund"
      )
    );
  }, [data]);

  const breadcrumbs = [
    <Link key="1" color="#000000" className="printBreadsel">
      {title}
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="
      #A29FA6"
    >
      {pdfInfo?.client}
    </Link>,
  ];
  const [load, setload] = useState(false);
  // const header = assetVbenchmark;
  const header = excessPerformance;
  useEffect(() => {
    setload(true);
  }, [data]);
  const arr = [
    // "MTD - Month to date|QTD - Quarter to date|ITD - Inception to Date",
    // "Return MTD/YTD/ITD include Realized, Unrealized and Other Income",
    // "MTD/QTD/YTD % returns are absolute returns for the period",
    // "ITD % returns are since inception XIRR",
  ];
  return (
    <div className="tableWhiteSpace">
      {load &&
        isArray(data[datasrc[0]]?.data) &&
        isArray(data[datasrc[1]]) &&
        data[datasrc[0]].data.length > 0 &&
        data[datasrc[1]].length > 0 && (
          <div>
            <PageContainer
              layout_t={1}
              pageNo={2}
              bgColor={"white"}
              pdfInfo={pdfInfo}
            >
              <Header
                title={<>{title}</>}
                type={14}
                hideHr={true}
                subtext={""}
                width={"5in"}
                denomination={denomination}
              ></Header>
              <div style={{ alignItems: "center" }}>
                <div
                  style={{
                    height: "fit-content",
                    width: "100%,",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      zIndex: "5",
                      width: "100%",
                      top: "1in",

                      marginRight: "66px",
                    }}
                  >
                    {data?.[datasrc[0]]?.data?.length > 0 && (
                      <PrintTable
                        // customRef={pRef2}
                        customTableClass={"printTableEvenAndOddRow"}
                        hideTitle={true}
                        data={tableData || []}
                        header={header}
                        hideUnderText={true}
                        customPadding={"2.2px 16px"}
                        firstCellCircleProps={{
                          height: "8px",
                          width: "4.5px",
                        }}
                        titleCellProps={{
                          firstCellStyle: { padding: "10px 0px 10px 0px" },
                          style: {
                            background: "#F3F8FD",
                            padding: "0px 16px 0px 9px",
                            minHeight: "47px",
                          },
                          font: {
                            fontWeight: "900",
                            fontSize: "16px",
                          },
                        }}
                        normalCellProps={{
                          firstCellPadding: "10px 0px 10px 0px",
                          fontStyle: {
                            fontWeight: "600",
                            fontSize: "14px",
                          },
                        }}
                        headerProps={{
                          className: "whiteHeaderCell",
                          titleClassName: "whiteTitle",
                          titleStyle: {
                            fontWeight: "500",
                            fontFamily: "AvenirBold",
                            fontSize: "14px",
                            lineHeight: "18px",
                            letterSpacing: "0.06em",
                            textTransform: "uppercase",
                            color: "#000000",
                          },
                        }}
                        colSpan={1}
                      />
                    )}
                  </div>
                </div>
              </div>
              {
                <>
                  <div className="p_flex" style={{ alignItems: "center" }}>
                    <div
                      style={{
                        height: "max-content",
                        width: "100%",
                        position: "relative",
                      }}
                    >
                      <div
                        className="secondTable"
                        style={{
                          // position: "absolute",
                          zIndex: "5",
                          width: "100%",
                          marginTop: "40px",

                          marginRight: "66px",
                        }}
                      >
                        {/* {data?.[datasrc[1]]?.length > 0 && (
                          <PrintTable
                            // customRef={pRef2}
                            customTableClass={"printTableEvenAndOddRow"}
                            hideTitle={true}
                            data={
                              objectFormatingWithTitleCOlWrapper(
                                {
                                  data: data[datasrc[1]] || [],
                                },
                                benchmarkVasset,
                                [],
                                "#ADC371",
                                undefined,
                                // totaRowData ? totaRowData : hideTotalRow ? undefined : total,
                                undefined,
                                {},
                                denomination
                              ) || []
                            }
                            header={benchmarkVasset}
                            hideUnderText={true}
                            customPadding={"2.2px 16px"}
                            firstCellCircleProps={{
                              height: "8px",
                              width: "4.5px",
                            }}
                            titleCellProps={{
                              firstCellStyle: {
                                padding: "10px 0px 10px 0px",
                              },
                              style: {
                                background: "#F3F8FD",
                                padding: "0px 16px 0px 9px",
                                minHeight: "47px",
                              },
                              font: {
                                fontWeight: "900",
                                fontSize: "16px",
                              },
                            }}
                            normalCellProps={{
                              firstCellPadding: "10px 0px 10px 0px",
                              fontStyle: {
                                fontWeight: "600",
                                fontSize: "14px",
                              },
                            }}
                            headerProps={{
                              className: "whiteHeaderCell",
                              titleClassName: "whiteTitle",
                              titleStyle: {
                                fontWeight: "500",
                                fontFamily: "AvenirBold",
                                fontSize: "14px",
                                lineHeight: "18px",
                                letterSpacing: "0.06em",
                                textTransform: "uppercase",
                                color: "#000000",
                              },
                            }}
                            colSpan={1}
                          />
                        )} */}
                        <div className="subList">
                          <ul>
                            {arr.map((a) => {
                              return <li>{a}</li>;
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
            </PageContainer>
          </div>
        )}
    </div>
  );
}
