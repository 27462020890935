import * as React from "react";

import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import DownloadOutlinedIcon from "@material-ui/icons/FontDownloadOutlined";
import RedoOutlinedIcon from "@material-ui/icons/RedoOutlined";
import TuneOutlinedIcon from "@material-ui/icons/TuneOutlined";
import ZoomOutMapOutlinedIcon from "@material-ui/icons/ZoomInOutlined";
import { ReactComponent as Share } from "../../../assets/icons/share.svg";
import { ReactComponent as Redo } from "../../../assets/icons/redo.svg";
import { ReactComponent as Zoom } from "../../../assets/icons/zoom.svg";
import { ReactComponent as Pencil } from "../../../assets/icons/pencil.svg";
import { Box, Radio, Typography } from "@material-ui/core";
import { CheckBox, Height, Print } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import ls from "localstorage-slim";
import { useRef } from "react";
import { useState } from "react";
import CheckBoxWithColor from "../products/common/CheckBoxWithColor";
import { useEffect } from "react";

function GraphIconHeader({
  click,
  setCheckedChartIndex,
  checkedChartIndex,
  handleAdvisorChart,
  type,
  exp,
  sub,
  checkAdvisor,
  setCheckAdvisor,
  list,
}) {
  const wrapperRef = useRef(null);
  const [display, setDisplay] = useState(false);
  const navigate = useNavigate();
  const iconColor = "#7C7E8C;";
  const chartArr = ["Portfolio Value", "TWRR"];
  const scopes = JSON.parse(ls.get("scopes"));
  const [selected, setSelected] = useState([]);

  const hideAlert = () => {
    setTimeout(() => {
      const customDiv = document.getElementById("customAlert");
      customDiv.style.display = "none";
    }, 2000);
  };
  const handleAdvisorCheck = (event) => {
    if (event.target.checked && Object.keys(checkAdvisor).length < 4) {
      setCheckAdvisor({
        [event.target.name]: event.target.checked,
        ...checkAdvisor,
      });

      // handleCloseEvent()
    } else {
      if (event.target.checked) {
        const customDiv = document.getElementById("customAlert");
        customDiv.style.display = "block";
        customDiv.innerHTML = `<div class="alertText">
              <span style="margin-right:20px"> <svg height="32" style="overflow:visible;enable-background:new 0 0 32 32" viewBox="0 0 32 32" width="32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><g id="Error_1_"><g id="Error"><circle cx="16" cy="16" id="BG" r="16" style="fill:#D72828;"/><path d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z" id="Exclamatory_x5F_Sign" style="fill:#E6E6E6;"/></g></g></g></svg></span>
                                        ${"Cannot select more than 4 "}
                                     </div>`;
        hideAlert();
      }
      if (Object.keys(checkAdvisor).length == 1) {
        const customDiv = document.getElementById("customAlert");
        customDiv.style.display = "block";
        customDiv.innerHTML = `<div class="alertText">
        <span style="margin-right:20px"> <svg height="32" style="overflow:visible;enable-background:new 0 0 32 32" viewBox="0 0 32 32" width="32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><g id="Error_1_"><g id="Error"><circle cx="16" cy="16" id="BG" r="16" style="fill:#D72828;"/><path d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z" id="Exclamatory_x5F_Sign" style="fill:#E6E6E6;"/></g></g></g></svg></span>
                                  ${"Cannot deselect minimum selection 1 required "}
                               </div>`;
        hideAlert();
        return;
      }
      delete checkAdvisor[event.target.name];
      setCheckAdvisor({ ...checkAdvisor });
      // handleCloseEvent()
    }
  };
  const handleClickOutside = (event) => {
    const { current: wrap } = wrapperRef;
    if (wrap && !wrap.contains(event.target)) {
      setDisplay(false);
    }
  };
  console.log(checkAdvisor, "checkadvisor");
  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // const handleCheckBoxClick = (e, col) => {
  //   let currentlen = selected.length;
  //   if (e.target.checked && selected.length < 4) {
  //     selected.push(e.target.value);

  //     setSelected([...selected]);

  //    //call api
  //     if (search) {
  //       let found = list.find((m, k) => {
  //         if (m.isin == e.target.value) {
  //           list.splice(k, 1);
  //           list.unshift(col);

  //           list.forEach((m, j) => {
  //             if (selected.includes(m.isin) && j != 0) {
  //               list.splice(j, 1);
  //               list.unshift(m);
  //             }
  //           });
  //           setMutualFunds([...mutualFunds]);
  //           return true;
  //         }
  //       });
  //       if (!found) {
  //         mutualFunds.unshift(col);

  //         mutualFunds.forEach((m, j) => {
  //           if (selected.includes(m.isin) && j != 0) {
  //             mutualFunds.splice(j, 1);
  //             mutualFunds.unshift(m);
  //           }
  //         });
  //         setMutualFunds([...mutualFunds]);
  //       }
  //     }
  //   } else {
  //     let sel = selected.find((isin, i) => {
  //       if (isin === e.target.value && selected.length > 1) {
  //         selected.splice(i, 1);
  //         return true;
  //       }
  //     });
  //     if (!sel) {
  //       const customDiv = document.getElementById("customAlert");
  //       customDiv.style.display = "block";
  //       customDiv.innerHTML = `<div class="alertText">
  //       <span style="margin-right:20px"> <svg height="32" style="overflow:visible;enable-background:new 0 0 32 32" viewBox="0 0 32 32" width="32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><g id="Error_1_"><g id="Error"><circle cx="16" cy="16" id="BG" r="16" style="fill:#D72828;"/><path d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z" id="Exclamatory_x5F_Sign" style="fill:#E6E6E6;"/></g></g></g></svg></span>
  //                                 ${
  //                                   selectedIsin.length < 4
  //                                     ? "Cannot deselect minimum selection 1 required "
  //                                     : "Cannot select more than 4 funds"
  //                                 }
  //                              </div>`;
  //       hideAlert();
  //     } else {

  //     }
  //     setSelectedIsin([...selectedIsin]);

  //   }
  // };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0px 0px 0px 10px",
          float: "right",
        }}
      >
        {/* graph header icons  */}
        {exp === "advisor-wise-portfolio-benchmark" || sub ? (
          <Box
            className="headerIcons"
            sx={{
              display: type == "bank" ? "none" : "flex",
              justifyContent: "right",
            }}
            style={{ fontSize: "60px" }}
          >
            <div style={{ position: "relative" }}>
              {exp === "advisor-wise-portfolio-benchmark" && (
                <div
                  className="graphicons-style ftext"
                  style={{
                    display: "flex",
                    alignItems: "center",

                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setDisplay(!display);
                  }}
                >
                  <span style={{ fontSize: "14px" }}> Advisor</span>
                </div>
              )}
              {display && (
                <div
                  style={{ position: "absolute", zIndex: 2 }}
                  ref={wrapperRef}
                >
                  <div
                    style={{
                      background: " #220848",
                      borderRadius: "20px",
                      border: "1px solid #711CF1 !important",
                      padding: "15px",
                      overflow: "auto",
                      boxShadow: "-4px 4px 10px rgba(0, 0, 0, 0.5)",
                      position: "absolute",
                      top: "0",
                      // right: "0",
                      zIndex: 2,
                    }}
                  >
                    <div style={{ display: "flex", gap: "10px" }}>
                      <Typography>Advisor</Typography>
                      {/* <Typography
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "3px",
                          }}
                          className={classes.mfoTextCard + " pointer"}
                          onClick={() => {
                            setCheckAdvisor({});
                            setclear(true);
                          }}
                        >
                          Clear All
                        </Typography> */}
                    </div>
                    {/* <SearchInput
                        clear={clear}
                        setClear={setclear}
                        setSearchName={setSearch}
                      /> */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "25px",
                      }}
                    >
                      {list.map((a) => {
                        return (
                          <span
                            style={{
                              display: "flex",
                              gap: "80px",
                              // justifyContent: "space-between",
                            }}
                          >
                            <CheckBoxWithColor
                              checked={checkAdvisor[a] ? true : false}
                              value={checkAdvisor[a] ? true : false}
                              onChange={handleAdvisorCheck}
                              name={a}
                            />
                            <Typography
                              className="filter-value-name avtar-text textCapitalize"
                              value={a}
                            >
                              {a}
                            </Typography>
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div
              className="graphicons-style"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Share sx={{ color: iconColor }} onClick={() => {}} />
            </div>
            <div
              className="graphicons-style"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Redo sx={{ color: iconColor }} onClick={() => {}} />
            </div>
            <div
              className="graphicons-style"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Print sx={{ color: iconColor }} onClick={() => {}} />
            </div>

            <div
              className="graphicons-style"
              style={{ display: "flex", alignItems: "center" }}
            >
              <TuneOutlinedIcon sx={{ color: iconColor }} onClick={() => {}} />
            </div>

            <Zoom className="graphiconlast-style" sx={{ color: iconColor }} />
          </Box>
        ) : (
          <></>
        )}
        {exp != "advisor-wise-portfolio-benchmark" && !sub && (
          <Box
            className="headerIcons"
            sx={{
              display: type == "bank" ? "none" : "flex",
              justifyContent: "right",
            }}
            style={{ fontSize: "60px" }}
          >
            {/* <Share className="graphicons-style" sx={{ color: iconColor }} />
          <Redo
            className="graphicons-style desk_show"
            sx={{ color: iconColor }}
          />
          <PrintOutlinedIcon
            className="graphicons-style desk_show"
            sx={{ color: iconColor }}
          /> */}
            {type == "dashboard" &&
              !sub &&
              scopes?.can_view_strategy_builder && (
                <>
                  {" "}
                  <span
                    className="graphicons-style"
                    style={{
                      display: "flex",
                      cursor: "pointer",
                      alignItems: "center",
                      // justifyContent: "space-between",
                    }}
                    onClick={() =>
                      navigate("/home/strategy-builder/target-portfolio")
                    }
                  >
                    <Pencil
                      className="graphiconlast-style"
                      style={{ padding: "0px 12px 1px 5px", marginTop: "1px" }}
                    />
                    <Typography className="  textCapitalize p5">
                      {/* {a.indexName.substring(0, 40) +
                            (a.indexName > 40 ? "..." : "")} */}
                      Edit Target Portfolio
                    </Typography>
                  </span>
                  <span
                    className="graphicons-style"
                    style={{
                      display: "flex",
                      cursor: "pointer",
                      alignItems: "center",
                      // justifyContent: "space-between",
                    }}
                    onClick={() =>
                      navigate("/home/strategy-builder/unique-cLient-index")
                    }
                  >
                    <Pencil
                      className="graphiconlast-style"
                      style={{ padding: "0px 12px 1px 5px", marginTop: "1px" }}
                    />
                    <Typography className="  textCapitalize p5">
                      {/* {a.indexName.substring(0, 40) +
                            (a.indexName > 40 ? "..." : "")} */}
                      Edit Unique Client Index
                    </Typography>
                  </span>
                </>
              )}

            {type != "bank" &&
              !sub &&
              chartArr.map((a, i) => {
                return (
                  <span
                    className="graphicons-style"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      // justifyContent: "space-between",
                    }}
                    key={i}
                  >
                    <Radio
                      size="medium"
                      checked={checkedChartIndex[a] ? true : false}
                      value={checkedChartIndex[a] ? true : false}
                      onChange={(event) => {
                        handleAdvisorChart(event);
                      }}
                      name={a}
                    />
                    <Typography className="  textCapitalize p5">
                      {/* {a.indexName.substring(0, 40) +
                            (a.indexName > 40 ? "..." : "")} */}
                      {a}
                    </Typography>
                  </span>
                );
              })}
            <div
              className="graphicons-style"
              style={{ display: "flex", alignItems: "center" }}
            >
              <TuneOutlinedIcon
                sx={{ color: iconColor }}
                onClick={() => {
                  click?.filter();
                }}
              />
              <Typography
                className=" textCapitalize"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  click?.filter();
                }}
              >
                {/* {a.indexName.substring(0, 40) +
                            (a.indexName > 40 ? "..." : "")} */}
                Benchmark
              </Typography>
            </div>
            <Zoom className="graphiconlast-style" sx={{ color: iconColor }} />
          </Box>
        )}
        {/* graph header icons ends */}
      </Box>
    </>
  );
}

export default GraphIconHeader;
