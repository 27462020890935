import React from "react";
import useStyles from "../common/ProductsStyles";
import { Card, CardContent, Grid, Typography, Button } from "@material-ui/core";
import { ReactComponent as Back } from "../../../../assets/holding-summary/back-blue.svg";
import { Link, useNavigate } from "react-router-dom";
export default function BondsHeading({ title, page, subtitle, type, isin }) {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: page == "govbonds" ? "start" : "center",
          margin: "2%",
        }}
      >
        {/* <Back
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate(-1);
          }}
        /> */}
        {/* <div style={{ width: "120px", height: "120px", borderRadius: "50%", background: "white", marginLeft: "2%" }}></div> */}

        <div style={{ marginLeft: "2%" }}>
          <Typography
            className={`${classes.bondsHeadNew}`}
            style={{ marginLeft: "2%", whiteSpace: "nowrap" }}
          >
            {title}
            <br></br>
            <span
              className="subtext capitalize"
              style={{
                fontSize: "16px",
                paddingLeft: "4px",
                position: "absolute",
              }}
            >
              {" "}
              {isin ? " ISIN:" + isin : ""}
            </span>
          </Typography>
          {page ? (
            <div>
              <div
                className={`${classes.bondsSubTextNew} `}
                style={{ marginLeft: "2%", whiteSpace: "nowrap" }}
              >
                {subtitle}
              </div>
              <br />

              <div
                style={{
                  border: "1px solid #AA6FFE",
                  borderRadius: "34px",
                  width: "150px",
                  height: "40px",
                }}
              >
                <div
                  className={classes.bondsBoxTextNew}
                  style={{ width: "86px", margin: "auto", paddingTop: "7%" }}
                >
                  {type ? type : "..."}
                </div>
              </div>
            </div>
          ) : (
            " "
          )}
        </div>
      </div>
    </div>
  );
}
