import { Grid, Box, Typography, Button } from "@material-ui/core";
import React, { useContext } from "react";
import { HorizontalDivider } from "./HorizontalDivider";
import "./UserManagementDetailsHeader.css";
import { ReactComponent as ArrowButton } from "../../assets/clientdashboard/userManagementArrow.svg";
import { EditableComponent } from "./EditableComponent";
import { useUserManagementForm } from "../../hooks/useUserManagementForm";
import { ReactComponent as EditIcon } from "../../assets/clientdashboard/editIcon.svg";
import { UserHeaderInfo } from "./UserHeaderInfo";
import { UserManagementBackButton } from "./UserManagementHeader";
import { useNavigate } from "react-router-dom";
import { mfoFilterContext } from "../../mfo_context/Mfo_Context";
import * as Yup from "yup";
import { useStyles } from "./UserManagementHeaderStyles";
import { PermissionsGate } from "../auth/PermissionGate";
import ls from "localstorage-slim";
import { mfoStaticFilter } from "../utils/common";

export const UserManagementDetailsHeader = ({
  componentToRender,
  customHandleBack,
  hideLeftSec,
  data,
  partner,
  handleEditPartner,
}) => {
  const scopes = JSON.parse(ls.get("scopes"));

  const renderComponentBasedOnPage = (page) => {
    if (page === "userHeaderInfo") {
      return (
        <>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <UserHeaderInfo data={data} />
          </Grid>
          <Grid item xs={6}>
            <PermissionsGate scopes={[scopes?.can_view_family_tree]}>
              <UserManagementDetailsHeaderBox
                text="View Family Tree"
                backgroundImg="user-header-info-view-family-tree"
                data={data}
              />
            </PermissionsGate>
          </Grid>
        </>
      );
    } else if (page === "userManagementDetailsHeader") {
      return (
        <>
          <Grid item xs={2} style={{ display: "flex" }}>
            {customHandleBack && (
              <div
                style={{
                  height: "fit-content",
                  marginTop: "30px",
                  marginLeft: "30px",
                }}
              >
                <UserManagementBackButton handleClick={customHandleBack} />
              </div>
            )}
            <Box className="profile-pic" style={{ marginLeft: "21px" }}></Box>
          </Grid>
          <Grid item xs={4}>
            <UserDetailsGrid
              handleEditPartner={handleEditPartner}
              data={data}
              partner={partner}
            />
          </Grid>
          {hideLeftSec ? (
            ""
          ) : (
            <>
              <Grid item xs={3}>
                <PermissionsGate
                  scopes={[scopes?.can_view_my_portfolio_holding_summary]}
                >
                  <UserManagementDetailsHeaderBox
                    text="View Consolidated Portfolio"
                    backgroundImg="view-consolidated-bg-img"
                    data={data}
                  />
                </PermissionsGate>
              </Grid>
              <Grid item xs={3}>
                <PermissionsGate scopes={[scopes?.can_view_family_tree]}>
                  <UserManagementDetailsHeaderBox
                    text="View Family Tree"
                    backgroundImg="view-family-tree-bg-img"
                    data={data}
                  />
                </PermissionsGate>
              </Grid>
            </>
          )}
        </>
      );
    }
  };
  return (
    <>
      {/* <Grid container>{renderComponentBasedOnPage("userHeaderInfo")}</Grid> */}
      {/* <Grid container>
        {renderComponentBasedOnPage("userManagementDetailsHeader")}
      </Grid> */}
      <Grid container>{renderComponentBasedOnPage(componentToRender)}</Grid>
      {hideLeftSec ? "" : <HorizontalDivider />}
    </>
  );
};

function UserDetailsGrid({ data, partner, handleEditPartner }) {
  const classes = useStyles();
  const initialValues = {
    partnerName: data?.partner_name || data?.entityName || "--",
  };

  const validationSchema = Yup.object({
    partnerName: Yup.string().required("Partner Name is required"),
  });

  let handleSubmit = (values, submitProps) => {
    handleEditPartner(values);

    // restting form will update the formik.dirty
    // property wich will remove the save button.
    submitProps.resetForm({ values });
  };

  //use formik.submitForm() to get your values on-
  //-passed onsubmit function as arguments.
  //always make sure to pass custom validation schema
  const {
    formik,
    isEditingFullName,
    setIsEditingFullName,
    isEditingMobileNumber,
    setIsEditingMobileNumber,
    isEditingEmailId,
    setIsEditingEmailId,
    fullNameOutsideClickRef,
    mobileNumberOutsideClickRef,
    emailIdOutsideClickRef,
  } = useUserManagementForm({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validations: validationSchema,
  });

  // console.log(formik?.dirty, "aslgabgjk");

  return (
    <>
      <Grid container className="user-management-details">
        {!partner && (
          <Grid xs={4}>
            {/* <EditableComponent
          labelHeader="Client Name"
          isEditing={isEditingFullName}
          formikErrors={formik.errors.fullName}
          outsideClickRef={fullNameOutsideClickRef}
          textFieldId="fullName"
          formikValues={formik?.values?.fullName}
          formikHandleChange={formik.handleChange}
          formikHandleBlur={formik.handleBlur}
          formikTouched={formik.touched.fullName}
        /> */}
            {/* Render the EditIcon only if not in edit mode and no fullName errors */}
            {/* {!isEditingFullName && !formik.errors.fullName && (
          <EditIcon
            style={{ cursor: "pointer" }}
            onClick={() => setIsEditingFullName(true)}
          />
        )} */}
            <Typography className="label">Client Name</Typography>
            <p className="label-value">
              {data?.client_full_name || data?.full_name || "--"}
            </p>
          </Grid>
        )}
        <Grid xs={4} spacing={1}>
          <div style={{ display: "flex", gap: "12px" }}>
            <EditableComponent
              labelHeader="Partner Name"
              isEditing={isEditingFullName}
              formikErrors={formik.errors.partnerName}
              outsideClickRef={fullNameOutsideClickRef}
              textFieldId="partnerName"
              formikValues={formik?.values?.partnerName}
              formikHandleChange={formik.handleChange}
              formikHandleBlur={formik.handleBlur}
              formikTouched={formik.touched.partnerName}
              setIsEditingFullName={setIsEditingFullName}
              isEditable={!partner ? false : true}
            />
            {/* <EditIcon
              style={{ cursor: "pointer" }}
              onClick={() => setIsEditingFullName(true)}
            /> */}
          </div>
          {/* <Typography className="label">Partner Name</Typography> */}
          {/* <p
            style={{ marginBottom: partner ? "0px" : "50px" }}
            className="label-value"
          >
            {data?.partner_name || data?.entity_name || "--"}
          </p> */}
        </Grid>
        {!partner && (
          <Grid xs={4}>
            <Typography className="label">Mapped to (Banker name)</Typography>
            <p className="label-value">{data?.banker_name || "--"}</p>
          </Grid>
        )}
        {!partner && (
          <Grid xs={4}>
            <Typography className="label">Client Code</Typography>
            <p className="label-value">
              {data?.client_code || data?.code || "--"}
            </p>
          </Grid>
        )}
        <Grid xs={4}>
          <Typography className="label">Partner Code</Typography>
          <p
            style={{ marginBottom: partner ? "0px" : "50px" }}
            className="label-value"
          >
            {data?.partner_code ||
              data?.clientPartner?.partner_code ||
              data?.code ||
              "--"}
          </p>
        </Grid>
        {!partner && (
          <Grid xs={4}>
            <Typography className="label">CXO</Typography>
            <Typography className="label-value">
              {data?.cso_name || "--"}
            </Typography>
          </Grid>
        )}
      </Grid>
      {partner && (
        <Grid container className="user-management-details">
          <Grid xs={4}>
            <Typography className="label">No Of Users</Typography>
            <p
              style={{ marginBottom: partner ? "0px" : "50px" }}
              className="label-value"
            >
              {data?.userCount || 0}
            </p>
          </Grid>
          <Grid style={{ marginBottom: partner ? "0px" : "50px" }} xs={4}>
            <Typography className="label">no of clients</Typography>
            <p className="label-value">{data?.clientCount || 0}</p>
          </Grid>
        </Grid>
      )}
      {/* formik?.dirty is used identify is any change in value is occured or not */}
      {formik?.dirty && (
        <Button
          className={classes.saveButton}
          onClick={() => {
            formik?.submitForm();
          }}
        >
          save
        </Button>
      )}
    </>
  );
}
function UserManagementDetailsHeaderBox({ text, backgroundImg, data }) {
  const naivgate = useNavigate();

  const { setMfofilter, mfoFilter } = useContext(mfoFilterContext);

  function naivgateToSpecifiedPage() {
    // For Admin --> id
    // For Partner --> client_id
    let memberId = data?.id || data?.client_id;
    // For Admin --> full_name
    // For Partner --> client_full_name
    // 👇 This would be used and applicable For Admin Login and Partner Login Only
    let clientName = data?.full_name || data?.client_full_name;
    if (memberId) {
      localStorage.setItem("memberId", memberId);
      localStorage.setItem("adminViewClientName", clientName);
    }
    if (text === "View Consolidated Portfolio") {
      // Before navigating reset the filter context with checkdata, advisor & executor as empty objects
      // By default empty means all members would be selected
      sessionStorage.setItem("filter", JSON.stringify(mfoStaticFilter));
      setMfofilter(mfoStaticFilter);
      naivgate("/home/portfolio");
    } else if (text === "View Family Tree") {
      naivgate("/user-management/family-structure");
    }
  }
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{
        gap: "20px",
        width: "100%",
        height: "237px",
      }}
      className={backgroundImg}
    >
      <Typography className="arrowText">{text}</Typography>
      <Box
        style={{ marginTop: "4px", cursor: "pointer" }}
        onClick={naivgateToSpecifiedPage}
      >
        <ArrowButton />
      </Box>
    </Box>
  );
}
