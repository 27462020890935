import React from "react";
import { CheckedSvgIcon } from "./common/CheckedSvgIcon";
import { UncheckedSvgIcon } from "./common/UncheckedSvgIcon";
import { useLocation } from "react-router-dom";
import { get_path_type } from "./utils/getPathType";
import { assetClassConfig } from "./config/newFilterAssetClassConfig";
import { useNewFilterStyles } from "./newFilterStyles";
export const NewFilterAssetClass = ({
  selectedAssetClasses,
  setSelectedAssetClasses,
}) => {
  const location = useLocation();
  const pathtype = get_path_type(location.pathname);
  const classes = useNewFilterStyles();
  // Use assetClassConfig to ensure all asset classes are displayed
  const allAssetClasses = assetClassConfig[pathtype];
  console.log("allAssetClasses", allAssetClasses);

  const handleAssetClick = (assetType) => {
    // Get the current selection status of the asset class
    const currentStatus = selectedAssetClasses[assetType];

    // Toggle the selection status of the clicked asset class and update the state
    setSelectedAssetClasses((prevState) => ({
      ...prevState,
      [assetType]: !currentStatus,
    }));
  };

  return (
    <div className="newFilterAssetClassContainer">
      {Object?.keys(allAssetClasses)?.map((assetType) => (
        <div
          key={assetType}
          className={classes.assetClassCard}
          onClick={() => handleAssetClick(assetType)}
        >
          <h1 className="assetClassCardTxt">
            {assetType.charAt(0).toUpperCase() + assetType.slice(1)}
          </h1>
          {selectedAssetClasses[assetType] ? (
            <CheckedSvgIcon cssClassName="checkedSvgIcon" />
          ) : (
            <UncheckedSvgIcon cssClassName="uncheckedSvgIcon" />
          )}
        </div>
      ))}
    </div>
  );
};
