export const handleAvatarName = (name) => {
  console.log(name, "kjasfkjajf");
  let newName = name?.split(" ");
  let returnName = "";

  if (newName[0]) {
    returnName = returnName + newName[0][0];
  }

  if (newName[1]) {
    returnName = returnName + " " + newName[1][0];
  }
  return returnName;
};
