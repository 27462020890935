import React from "react";
// import { makeStyles } from "@mui/styles";
import {
  Container,
  Paper,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
  Link,
  IconButton,
  Box,
  CircularProgress,
} from "@material-ui/core";
import DefaultSign from "./DefaultSign";
// import BackButton from "../common/BackBtnWithArrow/BackButton";
import { ReactComponent as Client } from "../../assets/partner-images/client.svg";
import Partner from "../../assets/partner-images/partner.svg";
import { useNavigate } from "react-router";
// import { useLogin } from "../../hooks/useLogin";
import PartnerHeading from "./PartnerHeading";
import "./NewSignUp.css";

const desc = {
  bottom: [
    {
      title: "Weclome, ",
      description:
        "This platform is an elegant solution for advisors and their clients. Experience the power of informed decision making basis the wholistic view of your financial standing, performance and aspirations, all within a sophisticated and intuitive framework.",
    },
  ],
};

const BoxWithSvgBackground = ({ children, label, onClick }) => {
  return (
    <Box
      style={{
        width: "130px",
        height: "139px",
        borderRadius: "7px",
        border: "1px solid #3E5389",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        color: "#fff",
        fontWeight: "bold",
        fontSize: "18px",
        lineHeight: "22px",
        // marginRight: "10%",
        cursor: "pointer",
        gap: "10px",
      }}
      className="roles-background"
      onClick={onClick}
    >
      {children}
      <Typography
        className="varientH2 generalFontFam"
        variant="h2"
        style={{ m: "13% 0" }}
      >
        {label}
      </Typography>
    </Box>
  );
};

const SelectRole = ({
  handleClientClick,
  handlePartnerClick,
  handleBack,
  hideBackButton,
}) => {
  return (
    <DefaultSign left={7} right={5} desc={desc}>
      <div className="dFlex jtfyContCntr alignCntr">
        <Box
          className="dFlex jtfyContCntr alignCntr flexDirCol"
          style={{ width: "fit-content", gap: "41px", marginTop: "12%" }}
        >
          {/* <Box style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Box style={{ ml: "-60px" }}>
              <BackButton />
            </Box>
            <Box>
              <Typography component="h6"></Typography>
              <Typography
                style={{
                  fontSize: "28px",
                  fontWeight: "900",
                  mb: "30px",
                  mt: "15px",
                }}
                variant="h1"
              ></Typography>
            </Box>
          </Box> */}

          {/* <PartnerHeading
            mainHead={"Hey there! 👋"}
            subtitle={"Please select the type of onboarding "}
            handleBack={handleBack}
            noStep={true}
            hideBackButton={hideBackButton}
            // step={currentStep}
            // marginLeft="-182px"
          /> */}

          <div className="dFlex alignLeft partnerHeadingContainer">
            <Box>
              <Typography
                style={{
                  color: "#FFFFFF",
                  // opacity: "0.7",
                  // letterSpacing: "0.5px",
                  marginTop: "9px",
                }}
                className="varientH6 generalFontFam"
                variant="h6"
              >
                Hey there! 👋
              </Typography>
              <Typography
                style={{
                  fontSize: "21px",
                  fontWeight: "900",
                  // letterSpacing: "0.5px",
                  color: "white",
                  marginTop: "9px",
                  //textTransform: "capitalize",
                }}
                className="generalFontFam"
                variant="h1"
              >
                Please select the type of onboarding
              </Typography>
            </Box>
          </div>

          <div className="dFlex" style={{ gap: "17px", width: "100%" }}>
            <BoxWithSvgBackground label="Client" onClick={handleClientClick}>
              <div
                style={{
                  width: "70px",
                  height: "70px",
                  background: "#2D3957",
                  borderRadius: "50%",
                  textAlign: "center",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                  <Client />
                </div>
              </div>
            </BoxWithSvgBackground>
            <BoxWithSvgBackground label="Partner" onClick={handlePartnerClick}>
              <div
                style={{
                  width: "70px",
                  height: "70px",
                  background: "#2D3957",
                  borderRadius: "50%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                  <img src={Partner}></img>
                </div>
              </div>
            </BoxWithSvgBackground>
          </div>
        </Box>
      </div>
    </DefaultSign>
  );
};

export default SelectRole;
