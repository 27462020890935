import React, { useEffect, useState } from "react";
import { CheckedSvgIcon } from "./common/CheckedSvgIcon";
import { UncheckedSvgIcon } from "./common/UncheckedSvgIcon";
import { durations } from "./newFilterConstants";
import FilterCalendar from "../../calendar/FilterCalendar";
import moment from "moment";
import {
  calculateDateRange,
  getMemberIdsFromSelectedMembersList,
} from "./utils/newFilterFormatter";
import { useNewFilterStyles } from "./newFilterStyles";
import { fetchITD } from "../../../api";
import { OnboardingContext } from "../../../Context";
import Loader from "../../loader/Loader";
import { TextField } from "@material-ui/core";

export const NewFilterPeriod = ({
  selectedPeriod,
  setSelectedPeriod,
  dateRange,
  setDateRange,
  clear,
  setClear,
  selectedAssetClasses,
  productType,
  selectedFamilyMembers,
  setbuttonDiabled,
}) => {
  const classes = useNewFilterStyles();
  const { userState } = OnboardingContext();
  let clientId = userState.loginedUserData.id;
  const [itdStartDate, setITDStartDate] = useState(null);
  const [itdLoading, setItdLoading] = useState(false);

  useEffect(() => {
    if (selectedPeriod === "ITD" && !itdStartDate) {
      setItdLoading(true);
      setbuttonDiabled(true);
      let params = {
        assetClass: Object?.keys(selectedAssetClasses).join(","),
        memberId: getMemberIdsFromSelectedMembersList(selectedFamilyMembers),
        product: productType === "dashboard" ? "all" : productType,
      };
      fetchITD(clientId, params)
        .then((res) => {
          const fetchedDate = moment(res?.result).toISOString();
          setITDStartDate(fetchedDate);
          // Only set the date range here if ITD is still the selected period
          if (selectedPeriod === "ITD") {
            setDateRange([
              fetchedDate,
              moment().subtract(1, "days").toISOString(),
            ]);
          }
          setbuttonDiabled(false);
        })
        .catch(console.error)
        .finally(() => setItdLoading(false));
    }
  }, [
    selectedPeriod,
    productType,
    selectedAssetClasses,
    selectedFamilyMembers,
  ]);
  const formatDate = (date) => (date ? moment(date).format("YYYY-MM-DD") : "");

  const handleStartDateChange = (event) => {
    if (event.target.length > 10) {
      return;
    }
    setDateRange([
      event.target.value,
      dateRange[1] ? formatDate(dateRange[1]) : "",
    ]);
    setSelectedPeriod(null);
  };

  const handleEndDateChange = (event) => {
    setDateRange([dateRange[0], event.target.value]);
    setSelectedPeriod(null);
  };
  return (
    <>
      {itdLoading && <Loader />}
      <div className="newFilterPeriodContainer">
        {durations.map((duration, index) => (
          <button
            key={index}
            onClick={() => {
              setSelectedPeriod(duration);
              if (duration === "ITD" && itdStartDate) {
                setDateRange([
                  itdStartDate,
                  moment().subtract(1, "days").toISOString(),
                ]);
              } else if (duration !== "ITD") {
                const [start, end] = calculateDateRange(duration);
                setDateRange([start, end]);
              }
            }}
            className={classes.offShoreBtn}
          >
            {selectedPeriod === duration ? (
              <CheckedSvgIcon cssClassName="checkedSvgIcon" />
            ) : (
              <UncheckedSvgIcon cssClassName="uncheckedSvgIcon" />
            )}
            {duration}
          </button>
        ))}
      </div>
      {/* <div style={{ width: "300px", marginTop: "1rem" }}>
        <FilterCalendar
          key={[selectedPeriod, itdStartDate, dateRange]}
          clear={clear}
          setPeriod={setSelectedPeriod}
          values={dateRange}
          style={{ width: "300px" }}
          maxDate={{
            current: new Date(moment().format("YYYY-MM-DD")),
          }}
          onchange={(update) => {
            setClear(false);
            setDateRange(update);
            setSelectedPeriod(null);
          }}
        />
      </div> */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "2rem",
          gap: "2rem",
        }}
      >
        <div style={{ width: "20%" }}>
          <label
            htmlFor="startDate"
            style={{ display: "block", marginBottom: "0.5rem" }}
          >
            Start Date
          </label>
          <TextField
            id="startDate"
            name="startDate"
            style={{
              background: "black",
              padding: "0.5rem",
              borderRadius: "3px",
              width: "100%",
            }}
            label=""
            type="date"
            value={formatDate(dateRange[0])}
            onChange={handleStartDateChange}
            inputProps={{
              max: moment().format("YYYY-MM-DD"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div style={{ width: "20%" }}>
          <label
            htmlFor="endDate"
            style={{ display: "block", marginBottom: "0.5rem" }}
          >
            End Date
          </label>
          <TextField
            id="endDate"
            name="endDate"
            style={{ background: "black", padding: "0.5rem", width: "100%" }}
            label=""
            type="date"
            value={formatDate(dateRange[1])}
            onChange={handleEndDateChange}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={!dateRange[0]} // Disable if start date is not selected
            inputProps={{
              min: formatDate(dateRange[0]), // end date cannot be before start date
              max: "9999-12-31",
            }}
          />
        </div>
      </div>
    </>
  );
};
