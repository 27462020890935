import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  TableContainer,
} from "@material-ui/core";
import React from "react";
import { useState } from "react";
import useStyles from "../../common/ProductsStyles";
import { ReactComponent as Export } from "../../../../../assets/icons/export.svg";
import { useIpoDetails } from "../../../../../hooks/useIpoDetails";
import Loader from "../../../../loader/Loader";
import { roundNumber } from "../../../../utils/common";
import { currencyFormat } from "../../../../../hooks/constantFunction";
import { Link } from "react-router-dom";
import moment from "moment";
const tableColumnsData = [
  { field: "Companies", title: "Companies", show: true },
  { field: "Listed On", title: "Listed On", show: true },
  { field: "Issue Price", title: "Issue Price", show: true },
  { field: "Status", title: "Status", show: true },
];

function IpoDetailsListed() {
  const [ipo, loading] = useIpoDetails("listed");
  const classes = useStyles();

  const [tableColumns, setTableColumns] = useState([tableColumnsData]);
  function dateFormat(dateStr) {
    const date = moment(dateStr, "Do MMMM, YYYY");
    const formattedDate = date.format("D MMM YYYY");
    return formattedDate;
  }
  const tableHeaders = (headers) => {
    return headers?.map((column) =>
      column && column.show ? (
        <TableCell
          style={{ borderBottom: "none", textTransform: "capitalize" }}
          key={column.title}
          align="center"
          className={`${classes.tableHeadBlueText} thStyle`}
        >
          {column.title}
        </TableCell>
      ) : null
    );
  };
  const tableBody = (body) => {
    return body?.map((row) => (
      <TableRow
        key={row.CO_CODE}
        style={{
          background: " linear-gradient(190deg,#220053 42.89%,#3A0C7E 100%)",
          border: " 1px solid #4E189F",
          boxShadow:
            "inset -4px -4px 10px rgba(76, 50, 117, 0.5), inset 4px 4px 10px rgba(0, 0, 0, 0.5)",
          borderRadius: " 13px",
          // transform: "rotate(-180deg)"
        }}
        className="equitytr"
      >
        <TableCell
          key="companyName"
          align="center"
          className={`${classes.rowTextNew} equitytd`}
        >
          {/* Comment route since details page api not ready */}
          <Link
            // to={`/home/equity/equity-summary/ipo/ipodetails`}
            state={{ data: row, ipoType: "listed" }}
            className={`${classes.rowTextNew} equitytd`}
          >
            {row.companyName}
          </Link>
        </TableCell>

        <TableCell
          key="LISTDATE"
          align="center"
          className={`${classes.rowTextNew} equitytd`}
        >
          {dateFormat(row.LISTDATE)}
        </TableCell>
        <TableCell
          key="issuePrice"
          align="center"
          className={`${classes.rowTextNew} equitytd`}
        >
          {roundNumber(row.listprice)}
        </TableCell>
        <TableCell
          key="status"
          align="center"
          className={`${classes.rowTextNew} equitytd`}
        >
          {`Listed at ${currencyFormat(row.listprice)} for `}
          {row.PerChange < 0 ? (
            <>
              <span style={{ color: "#DE4174" }}>
                {roundNumber(row.PerChange)}%
              </span>
              <span> Loss</span>
            </>
          ) : (
            <>
              <span style={{ color: "#22CCC2" }}>
                {roundNumber(row.PerChange)}%
              </span>
              <span> Profit</span>
            </>
          )}
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <>
      {loading && <Loader />}
      <div style={{ minHeight: "500px" }} className={classes.mainContent}>
        <div className={classes.tableContainerAlign}>
          <TableContainer style={{ maxHeight: 500 }}>
            <Table
              style={{ borderCollapse: "separate", borderSpacing: "0 8px" }}
              // stickyHeader
            >
              <TableHead
                style={{
                  background: "#1D1B36",
                  borderRadius: "20px",
                  zIndex: "1",
                  position: "sticky",
                  top: "0",
                }}
              >
                <TableRow className="tableRowStyle equityTable">
                  {tableHeaders(tableColumns[0])}
                </TableRow>
              </TableHead>

              <TableBody className="tableBodyAif">
                {/* <TableRow
                style={{
                  background:
                    " linear-gradient(190deg,#220053 42.89%,#3A0C7E 100%)",
                  border: " 1px solid #4E189F",
                  boxShadow:
                    "inset -4px -4px 10px rgba(76, 50, 117, 0.5), inset 4px 4px 10px rgba(0, 0, 0, 0.5)",
                  borderRadius: " 20px",
                  // transform: "rotate(-180deg)"
                }}
                className="equitytr"
              > */}
                {/* {tableBody(tableColumns[0])} */}
                {ipo && tableBody(ipo)}
                {/* </TableRow> */}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      <div style={{ padding: "2% 4%" }}>
        <div className={classes.titleText}>IPO - Initial Public Offerings</div>
        <div
          className={classes.aifSubText}
          style={{ width: "52%", height: "10%" }}
        >
          is a process by which a private company becomes public. A company
          becomes 'public' when it starts selling its shares in the market for
          the first time. Selling shares is like selling a portion of its stake
          to the investors for the company. There are two types of market
        </div>
      </div>
      <div style={{ padding: "2% 4%", display: "flex", gap: "10px" }}>
        {/* Flex Child 1 */}
        <div className={classes.equityIpoIconText}>
          <div className={classes.EquityIPOiconStyle}>
            <Export style={{ padding: "30%", color: "#AA6FFE" }} />
          </div>
          <div className={classes.equityipotext}>Primary Market</div>
        </div>
        {/* Flex Child 2 */}
        <div className={classes.equityIpoIconText}>
          <div className={classes.EquityIPOiconStyle}>
            <Export style={{ padding: "30%", color: "#AA6FFE" }} />
          </div>
          <div className={classes.equityipotext}>Secondary Market</div>
        </div>
      </div>
      <div style={{ padding: "2% 4%" }}>
        <div style={{ color: "#FFFF" }}>
          IPOs are launched in the{" "}
          <span style={{ color: "#7B61FF" }} className={classes.bondsTextNew}>
            Primary Market
          </span>
        </div>
        <div style={{ height: "50px", paddingLeft: "1%" }}>
          <div class="divider"></div>
        </div>
        {/* <hr style={{display:"flex",flexDirection:"column"}} className='filter-divider'></hr> */}
        <div className={classes.equityIPOtext2}>
          Why do companies launch an IPO ?
        </div>
        <br />
        <div
          style={{ width: "30%", height: "2%" }}
          className={classes.aifSubText}
        >
          Companies use the IPO route to generate fresh equity capital for the
          company to further its business.
        </div>
        {/* <hr className='filter-divider'></hr> */}
        <div style={{ height: "50px", paddingLeft: "1%" }}>
          <div class="divider"></div>
        </div>
        <div className={classes.equityIPOtext2}>Who can invest in IPO?</div>
        <br />
        <div
          style={{ width: "30%", height: "2%" }}
          className={classes.aifSubText}
        >
          Investors are divided into three main category:
        </div>
        <br />
        <br />
        <Grid style={{ display: "flex" }}>
          <Grid md={4}>
            <div className={classes.equityIPOtext2}>
              Qualified Institutinal buyers (QIB)
            </div>
            <ul className="ipodesign">
              <li className={`${classes.aifSubText} ipodesign`}>
                Mutual Funds
              </li>
              <li className={`${classes.aifSubText} ipodesign`}>
                Domestics Financial Institution: Banks, Financial Institutions
                and Insurance Companies
              </li>
              <li className={`${classes.aifSubText} ipodesign`}>
                Foreign Institutional Investors
              </li>
              <li className={`${classes.aifSubText} ipodesign`}>Others</li>
            </ul>
          </Grid>
          <Grid md={4}>
            <div className={classes.equityIPOtext2}>
              Qualified Institutinal buyers (QIB)
            </div>
            <ul>
              <li className={classes.aifSubText}>Mutual Funds</li>
              <li className={classes.aifSubText}>
                Domestics Financial Institution: Banks, Financial Institutions
                and Insurance Companies
              </li>
              <li className={classes.aifSubText}>
                Foreign Institutional Investors
              </li>
              <li className={classes.aifSubText}>Others</li>
            </ul>
          </Grid>
          <Grid md={4}>
            <div className={classes.equityIPOtext2}>
              Qualified Institutinal buyers (QIB)
            </div>
            <ul>
              <li className={classes.aifSubText}>Mutual Funds</li>
              <li className={classes.aifSubText}>
                Domestics Financial Institution: Banks, Financial Institutions
                and Insurance Companies
              </li>
              <li className={classes.aifSubText}>
                Foreign Institutional Investors
              </li>
              <li className={classes.aifSubText}>Others</li>
            </ul>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default IpoDetailsListed;
