import { Box, Grid, MenuItem } from "@material-ui/core";
import React, { useEffect } from "react";
import PartnerHeading from "./PartnerHeading";
import CustomInput from "./common/CustomInput";
import { useForm } from "./hooks/useForm";
import {
  newRegisterForm1ValidationSchema,
  newRegisterForm4ValidationSchema,
} from "./ValidationSchema";
import StepProgressBar from "./common/StepProgressBar/StepProgressBar";
import LoadingButton from "./common/LoadingButton";
import ErrorMessageComp from "./common/ErrorMessageComp";
import "./PartnerOnboarding.css";
import { NEW_ENTITY_TYPES } from "./utils/constands";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import { useState } from "react";
import { useFetchMasterLob } from "../../hooks/useFetchMasterLob";
import { fetchSubLobMaster } from "../../api";
import { MASTERLOBREGION } from "../../Constants";

function PartnerOnboardingForm1({
  handleNext,
  handleBack,
  currentStep,
  customHandleSubmit,
  head,
  onlyPerosn,
  apiErrorMessage,
  loading,
  formData,
  noBottomStep,
  formMaxHeight,
  showMobileField,
  gap,
  total,
  stepDeviation,
  entityName,
  hide,
  entityType,
}) {
  const [checkBox, setCheckBox] = useState(false);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useForm(
    {
      entityName: formData?.entityName && checkBox ? formData?.entityName : "",
      entityType: formData?.entityType ? formData?.entityType : "",
      panNumber: formData?.panNumber ? formData?.panNumber : "",
      // dateOfBirth:
      //   formData?.entityType == "sole_proprietor"
      //     ? formData?.dateOfBirth
      //     : formData?.doi
      //     ? formData?.doi
      //     : "",
      email: formData?.email ? formData?.email : "",
      mobile: formData?.mobile ? formData?.mobile : "",
      lobId: formData?.lobId ? formData?.lobId : "",
      subLobId: formData?.subLobId ? formData?.subLobId : "",
      region: formData?.region ? formData?.region : "",
    },
    // test
    (values) => {
      customHandleSubmit(values);
    },
    currentStep == 4 || showMobileField
      ? newRegisterForm4ValidationSchema
      : newRegisterForm1ValidationSchema
  );

  const { lobList } = useFetchMasterLob();
  const [subLobList, setSubLobList] = useState([]);

  useEffect(() => {
    if (values.lobId) {
      fetchSubLobMaster(values?.lobId)
        .then((response) => {
          if (response?.data?.data?.result) {
            setSubLobList(response?.data?.data?.result);
          }
        })
        .catch((err) => {
          console.error(`An Error ${err} occurred`);
        });
    }
  }, [values.lobId]);

  const hanldeCechBox = () => {
    setCheckBox((prev) => {
      setFieldValue("entityName", prev ? "" : entityName);
      return !prev;
    });
  };

  // console.log("loging");
  return (
    <div className="dFlex jtfyContCntr alignCntr h100">
      <Box
        className="dFlex jtfyContCntr alignCntr flexDirCol"
        sx={{ width: { xs: "auto", sm: "330px" }, gap: gap ? gap : "41px" }}
      >
        <div className="newFormTopSec">
          <PartnerHeading
            mainHead={head?.main ? head?.main : "Partner Onboarding"}
            subtitle={
              head?.sub
                ? head?.sub
                : "Join us by providing your company details"
            }
            handleBack={handleBack}
            step={currentStep}
            stepDeviation={stepDeviation}
          />

          {entityName && entityType == "individual" && (
            <div className="checkBoxContainer">
              <div className="checkBoxWrapper" onClick={hanldeCechBox}>
                {checkBox && (
                  <div className="checkBoxTicked">
                    <CheckRoundedIcon
                      style={{ color: "white", height: "15px", width: "16px" }}
                    />
                  </div>
                )}
              </div>
              <p className="checkBoxTxt">Same as Partner</p>
            </div>
          )}
        </div>
        <div className="newFormContainer" style={{ maxHeight: formMaxHeight }}>
          <Box
            className="dFlex jtfyContCntr alignCntr flexDirCol"
            sx={{ width: { xs: "auto", sm: "330px" }, gap: "41px" }}
          >
            <form
              onSubmit={handleSubmit}
              style={{ width: "-webkit-fill-available" }}
            >
              <div
                // container
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  marginBottom: "14px",
                }}
              >
                {!hide?.includes("entityType") && (
                  <Grid xs={12} lg={12} item>
                    <CustomInput
                      // as={TextField}
                      select
                      fullWidth
                      title="ENTITY TYPE"
                      variant="outlined"
                      margin="normal"
                      // required
                      id="entityType"
                      label="Entity Type"
                      name="entityType"
                      autoComplete="entityType"
                      // autoFocus
                      // type="select"
                      error={touched.entityType && Boolean(errors.entityType)}
                      helperText={touched.entityType && errors.entityType}
                      value={values.entityType}
                      onChange={handleChange}
                    >
                      {NEW_ENTITY_TYPES.map((entity) => {
                        return (
                          <MenuItem
                            key={entity.value}
                            value={entity.value}
                            selected={entity?.selected}
                            style={{
                              border: "0.34px solid #3E5389",
                              backgroundColor: "#131826",
                            }}
                          >
                            {entity.name}
                          </MenuItem>
                        );
                      })}
                    </CustomInput>
                  </Grid>
                )}
                <Grid xs={12} lg={12} item>
                  <CustomInput
                    title={
                      currentStep == 4 || currentStep == 5
                        ? "Representative Name"
                        : values.entityType == "individual"
                        ? "Name"
                        : "ENTITY NAME"
                    }
                    // as={TextField}
                    variant="outlined"
                    margin="normal"
                    // required
                    fullWidth
                    id="entityName"
                    label="Entity Name"
                    name="entityName"
                    autoComplete="entityName"
                    autoFocus
                    error={touched.entityName && Boolean(errors.entityName)}
                    helperText={touched.entityName && errors.entityName}
                    value={values.entityName}
                    onChange={handleChange}
                  />
                </Grid>

                {values.entityType !== "individual" && (
                  <>
                    <Grid
                      xs={12}
                      // lg={6}
                      item
                      // style={{ paddingLeft: { xs: 0, sm: 0, md: 0, lg: 1 } }}
                    >
                      <CustomInput
                        title="PAN NO"
                        // as={TextField}
                        variant="outlined"
                        margin="normal"
                        // required
                        fullWidth
                        id="panNumber"
                        label="Pan Number"
                        name="panNumber"
                        error={touched.panNumber && Boolean(errors.panNumber)}
                        helperText={touched.panNumber && errors.panNumber}
                        value={values.panNumber}
                        onChange={handleChange}
                      />
                    </Grid>
                    {/* <Grid
                      // xs={12}
                      // lg={12}
                      style={{ padding: "0px" }}
                      container
                      spacing={2}
                    > */}

                    {/* <Grid
                        xs={12}
                        lg={6}
                        item
                        // style={{ paddingLeft: { xs: 0, sm: 0, md: 0, lg: 1 } }}
                      >
                        <CustomInput
                          // as={TextField}
                          title={`Date of ${
                            values?.entityType == "sole_proprietor"
                              ? "Birth"
                              : "incorporation"
                          }`}
                          variant="outlined"
                          margin="normal"
                          // required
                          fullWidth
                          id="dateOfBirth"
                          label={`Date of ${
                            values?.entityType == "sole_proprietor"
                              ? "Birth"
                              : "incorporation"
                          }`}
                          name="dateOfBirth"
                          autoComplete="dateOfBirth"
                          // autoFocus
                          type="date"
                          error={
                            touched.dateOfBirth && Boolean(errors.dateOfBirth)
                          }
                          helperText={touched.dateOfBirth && errors.dateOfBirth}
                          value={values.dateOfBirth}
                          onChange={handleChange}
                        />
                      </Grid> */}
                    {/* </Grid> */}

                    <Grid xs={12} lg={12} item>
                      <CustomInput
                        // as={TextField}
                        title="EMAIL ID"
                        variant="outlined"
                        margin="normal"
                        // required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        // autoFocus
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                        value={values.email}
                        onChange={handleChange}
                      />
                    </Grid>
                    {(currentStep == 4 || showMobileField) && (
                      <Grid
                        xs={12}
                        lg={12}
                        item
                        // pr={{ xs: 0, sm: 0, md: 0, lg: 1 }}
                      >
                        <CustomInput
                          title="Mobile no."
                          // as={TextField}
                          variant="outlined"
                          margin="normal"
                          // required
                          fullWidth
                          id="mobile"
                          label="Mobile Number"
                          name="mobile"
                          error={touched.mobile && Boolean(errors.mobile)}
                          helperText={touched.mobile && errors.mobile}
                          value={values.mobile}
                          onChange={handleChange}
                          // maxLength={10}
                        />
                      </Grid>
                    )}
                  </>
                )}
                {(currentStep == 4 || currentStep == 5) && (
                  <>
                    <Grid xs={12} lg={12} item>
                      <CustomInput
                        title="Lob"
                        //   disabled={true}
                        // as={TextField}
                        select
                        variant="outlined"
                        margin="normal"
                        // required
                        fullWidth
                        id="lob"
                        label="LOB"
                        name="lobId"
                        autoComplete="lobId"
                        //   autoFocus
                        error={touched.lobId && Boolean(errors.lobId)}
                        helperText={touched.lobId && errors.lobId}
                        value={values.lobId}
                        onChange={handleChange}
                      >
                        {lobList.map((entity) => {
                          return (
                            <MenuItem
                              key={entity.id}
                              value={entity.id}
                              selected={entity?.id}
                              color="white"
                              style={{
                                border: "0.34px solid #3E5389",
                                backgroundColor: "#131826",
                              }}
                            >
                              {entity.name}
                            </MenuItem>
                          );
                        })}
                      </CustomInput>
                    </Grid>
                    <Grid xs={12} lg={12} item>
                      <CustomInput
                        title="Sub Lob"
                        //   disabled={true}
                        // as={TextField}
                        select
                        variant="outlined"
                        margin="normal"
                        // required
                        fullWidth
                        id="sublob"
                        label="SUB LOB"
                        name="subLobId"
                        autoComplete="subLobId"
                        //   autoFocus
                        error={touched.subLobId && Boolean(errors.subLobId)}
                        helperText={touched.subLobId && errors.subLobId}
                        value={values.subLobId}
                        onChange={handleChange}
                      >
                        {subLobList.map((entity) => {
                          return (
                            <MenuItem
                              key={entity.id}
                              value={entity.id}
                              selected={entity?.id}
                              color="white"
                              style={{
                                border: "0.34px solid #3E5389",
                                backgroundColor: "#131826",
                              }}
                            >
                              {entity.name}
                            </MenuItem>
                          );
                        })}
                      </CustomInput>
                    </Grid>
                    <Grid xs={12} lg={12} item>
                      <CustomInput
                        title="Region"
                        //   disabled={true}
                        // as={TextField}
                        select
                        variant="outlined"
                        margin="normal"
                        // required
                        fullWidth
                        id="region"
                        label="REGION"
                        name="region"
                        autoComplete="region"
                        //   autoFocus
                        error={touched.region && Boolean(errors.region)}
                        helperText={touched.region && errors.region}
                        value={values.region}
                        onChange={handleChange}
                      >
                        {MASTERLOBREGION.map((entity) => {
                          return (
                            <MenuItem
                              key={entity.value}
                              value={entity.value}
                              selected={entity?.value}
                              color="white"
                              style={{
                                border: "0.34px solid #3E5389",
                                backgroundColor: "#131826",
                              }}
                            >
                              {entity.name}
                            </MenuItem>
                          );
                        })}
                      </CustomInput>
                    </Grid>
                  </>
                )}
              </div>
              <div className="newFormBottonSec">
                <Grid xs={12} lg={12} style={{ marginTop: "4px" }} item>
                  <ErrorMessageComp errorMessage={apiErrorMessage} />
                  <LoadingButton
                    buttonTitle={"Continue"}
                    type="submit"
                    showLoader={loading}
                  />
                </Grid>
                {noBottomStep ? (
                  ""
                ) : (
                  <Grid xs={12} item style={{ marginTop: "28px" }}>
                    <StepProgressBar
                      total={
                        values.entityType !== "individual" ? total : total - 2
                      }
                      currentStep={currentStep}
                    />
                  </Grid>
                )}
              </div>
            </form>
          </Box>
        </div>
      </Box>
    </div>
  );
}

export default PartnerOnboardingForm1;
