import React from "react";
import {
  Grid,
  Button,
  Typography,
  Box,
  CircularProgress,
} from "@material-ui/core";
import CustomInput from "./common/CustomInput";
import DefaultSign from "./DefaultSign";
import PartnerHeading from "./PartnerHeading";
import { OTPValidationSchema } from "./ValidationSchema";
import { useForm } from "./hooks/useForm";
import { useOtp } from "./hooks/useOtp";
import StepProgressBar from "./common/StepProgressBar/StepProgressBar";
import ErrorMessageComp from "./common/ErrorMessageComp";
import LoadingButton from "./common/LoadingButton";
import "./NewSignUp.css";

export default function PartnerOnboarding3({
  handleBack,
  currentStep,
  title,
  subTitle,
  comType,
  entityType,
  resendEntityType,
  handleNext,
  userId,
  totalStep,
  stepDeviation,
  head
}) {
  const { showLoader, submitOtp, resendOtp, setShowLoader, errorMessage } =
    useOtp();

  const { values, errors, touched, handleChange, handleSubmit } = useForm(
    {
      otp: "",
    },
    (values) => {
      values.otp = Number(values.otp);
      const { otp } = values;
      //🔴 API CALL
      // TODO Pass userId as 3rd param in submitOtp
      submitOtp(otp, comType, userId, entityType, handleNext);
    },
    OTPValidationSchema
  );

  const desc = head?.desc
    ? head?.desc
    : {
        center: [
          {
            title: "Why Email Verification ?",
            description:
              "Holdings are the contents of an investment portfolio held by an individual or an entity, such as a mutual fund or a pension fund. Portfolio holdings may encompass a wide ",
          },
        ],
      };
  console.log("currentStep", currentStep);

  return (
    <>
      <DefaultSign left={7} right={5} desc={desc}>
        <div className="dFlex jtfyContCntr alignCntr h100">
          <Box
            className="dFlex jtfyContCntr alignCntr flexDirCol"
            sx={{ width: { xs: "auto", sm: "330px" }, gap: "41px" }}
          >
            <PartnerHeading
              mainHead={title}
              subtitle={subTitle}
              handleBack={handleBack}
              step={currentStep}
              marginLeft="-182px"
              stepDeviation={stepDeviation}
            />

            <form onSubmit={handleSubmit}>
              <Grid container>
                <Grid xs={12} item>
                  <CustomInput
                    title="ENTER OTP"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="otp"
                    name="otp"
                    autoFocus
                    error={touched.otp && Boolean(errors.otp)}
                    helperText={touched.otp && errors.otp}
                    value={values.otp}
                    onChange={handleChange}
                    maxLength={6}
                  />
                </Grid>

                <Grid xs={12}>
                  <Typography
                    className="varientH5 generalFontFam"
                    variant="h5"
                    pl="10px"
                  >
                    <span style={{ color: "#9A9A9B" }}>
                      Didn’t receive OTP ?
                    </span>
                    &nbsp;
                    <span
                      style={{
                        color: "#3555FF",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setShowLoader(false);
                        //🔴 API CALL
                        // TODO Pass userId as 3rd param in resendOtp
                        resendOtp(comType, resendEntityType, userId);
                      }}
                    >
                      Resend
                    </span>
                  </Typography>
                </Grid>

                <Grid xs={12} item style={{ marginTop: "56px" }}>
                  {errorMessage && (
                    <ErrorMessageComp errorMessage={errorMessage} />
                  )}
                  <LoadingButton
                    type="submit"
                    buttonTitle={"Verify"}
                    showLoader={showLoader}
                  />
                </Grid>
                <Grid xs={12} style={{ marginTop: "28px" }}>
                  <StepProgressBar
                    total={totalStep}
                    currentStep={currentStep}
                  />
                </Grid>
              </Grid>
            </form>
          </Box>
        </div>
      </DefaultSign>
    </>
  );
}
