import React, { useEffect, useState } from "react";
import PageContainer from "../PageContainer";
import Header from "../Components/Header";
import PrintTable from "../Components/Table";
import ApexNew_print from "../Components/ApexNew_print";
import chartData from "../../../charts/chartData";
import { ReactComponent as Question } from "../images/question.svg";
import PieChart from "../../../charts/PieChart";
import Print_PieChart from "../Components/Print_PieChart";
import TabCards from "../Components/TabCards";
import { roundNumber } from "../../../utils/common";
import { date } from "yup/lib/locale";
import { currencyFormatPrint as currencyFormat } from "../../../../hooks/constantFunction";

import { objectFormatingWithTitleCOlWrapper } from "../Components/utils/common";
import { isArray } from "lodash";

export default function Top5pdf({
  data,
  denomination,
  title,
  tableconfig,
  mappedKey,
  pdfInfo,
}) {
  const getDenomination = localStorage.getItem("denomination");
  // console.log(
  //   data?.[mappedKey.key1]?.[mappedKey.key1 + "Valuation"],
  //   "top 5 amc"
  // );
  const colors = ["#152687", "#4BB3ED", "#4B64ED", "#BDC5F2", "#D094FF"];
  const [colormapped, setColorMapped] = useState({});

  let amc = objectFormatingWithTitleCOlWrapper(
    {
      data: data?.[mappedKey.key1],
    },
    tableconfig,
    [],
    "#ADC371",
    undefined,
    // totaRowData ? totaRowData : hideTotalRow ? undefined : total,
    undefined,
    {},
    denomination
  );

  const [data1, setData1] = useState([]);
  useEffect(() => {
    let piedata = [];
    data?.[mappedKey.key1]?.map((a, i) => {
      colormapped[a?.[mappedKey.key2]] = colors[i];
      // console.log(colormapped[a?.[mappedKey.key2]], "piedata", colormapped);
      piedata.push({
        id: i,
        label: a[mappedKey.key2],
        value: roundNumber(a[mappedKey.key3]),
        val: [
          roundNumber(data?.heldInvestment),
          roundNumber(data?.currentHeldAmount),
          roundNumber(data?.heldPercentage),
        ],

        color: colors[i],
      });
    });

    setData1(piedata);
    setColorMapped({ ...colormapped });
  }, [data]);
  if (isArray(data?.[mappedKey.key1]) && data?.[mappedKey.key1].length == 0) {
    return <></>;
  }

  return (
    <div>
      <PageContainer
        layout_t={4}
        pageNo={2}
        bgColor={"#ffffff"}
        pdfInfo={pdfInfo}
      >
        <Header
          title={<>{title}</>}
          type={12}
          denomination={denomination}
          greyText={" "}
          subtext={`Engagement Model segregates the Client portfolio into Held & Held Away. Where in Held refers to the Investments Directly managed by us & Held Away refers to the investments managed by other Advisors. `}
          width={"5in"}
        ></Header>
        <div className="p_flex">
          <div style={{ height: "9in", width: "50%" }}>
            {/* cards */}

            {/* {data1?.map((a, i) => {
            return (
              <TabCards
                data={a}
                text={["Invested Value", "Current Value", "Percentage"]}
                type={["rs", "rs", "percent"]}
                color={colors[i]}
                getkey={i}
                denomination={denomination}
              ></TabCards>
            );
          })} */}
            <div>
              <div className="flex_center" style={{ position: "relative" }}>
                <div
                  style={{
                    width: "690px",
                    height: "695px",
                    position: "relative",
                  }}
                >
                  <div
                    className="p_circle"
                    style={{ transform: "translate(109%, 1.07in )" }}
                  >
                    <div>
                      <div className="">CURRENT VALUE</div>
                      <div>
                        {currencyFormat(
                          data?.[mappedKey.key4],
                          undefined,
                          denomination
                        )}
                      </div>
                    </div>
                  </div>
                  {data1 && (
                    <Print_PieChart data={data1} config={{ colors: colors }} />
                  )}
                  {/* <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      marginTop: "-18%",
                      marginBottom: "3%",
                      marginRight: "3%",
                    }}
                  ></div> */}
                </div>
              </div>
            </div>
          </div>
          <div
            // Value
            className=" p_flex a_cen"
            style={{
              height: "879px",
              background: "none",
              marginTop: "-0.5in",
            }}
          >
            {" "}
            <PrintTable
              // customRef={pRef2}
              colors={colors}
              // colormapping={colormapped}
              customTableClass={"printTableEvenAndOddRow"}
              hideTitle={true}
              data={amc || []}
              header={tableconfig}
              hideUnderText={true}
              customPadding={"2.2px 16px"}
              firstCellCircleProps={{
                height: "8px",
                width: "4.5px",
              }}
              titleCellProps={{
                firstCellStyle: { padding: "10px 0px 10px 0px" },
                style: {
                  background: "#F3F8FD",
                  padding: "0px 16px 0px 9px",
                  minHeight: "47px",
                },
                font: {
                  fontWeight: "900",
                  fontSize: "16px",
                },
              }}
              normalCellProps={{
                firstCellPadding: "10px 0px 10px 0px",
                fontStyle: { fontWeight: "600", fontSize: "14px" },
              }}
              headerProps={{
                className: "whiteHeaderCell",
                titleClassName: "whiteTitle",
                titleStyle: {
                  fontWeight: "500",
                  fontFamily: "AvenirBold",
                  fontSize: "14px",
                  lineHeight: "18px",
                  letterSpacing: "0.06em",
                  textTransform: "uppercase",
                  color: "#000000",
                },
              }}
              colSpan={1}
            />
          </div>
        </div>
        {/* <CardsWrapper /> */}
        {/* <Hr title={"Portfolio Performance"} /> */}
        {/* <div className="h_card" style={{ height: "448px" }}> */}
        {/* <PrintTable /> */}
        {/* </div> */}
      </PageContainer>
    </div>
  );
}

// {data1?.map((m, i) => {
//   return (
//     <div style={{ display: "flex", marginLeft: "30px" }}>
//       <div className="p_flex a_cen">
//         <svg height="23" width="25">
//           <circle
//             cx="12"
//             cy="12"
//             r="8"
//             stroke-width="3"
//             fill={m.color}
//           />
//           Sorry, your browser does not support inline SVG.
//         </svg>
//       </div>
//       <div className={` p_flex a_cen `}>
//         {m.label}
//         {/* <div className="boldText">Percentage</div>
//     <div className="boldText2">{m.val[2] + " %"}</div> */}
//       </div>
//     </div>
//   );
// })}
