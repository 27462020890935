import React from "react";
import PartnerHeading from "../NewSignUp/PartnerHeading";
import { CustomTabComponent } from "../dashboard/common/CustomTabComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import TableSummaryDetails from "../dashboard/products/newDesigns/common/newSummaryDetailsTable/TableSummaryDetails";
import PartnerAsistentJourney from "../newPartnerList/partnerAsistentJourney/PartnerAsistentJourney";
import {
  bodyData5,
  headerDataPartner,
  clientListHeaderOps,
  clientListHeaderOpsPending,
} from "../user_management/utils/constands";
import ClientAssistentJourneyFlow from "./clientAssistentJourney/ClientAssistentJourneyFlow";
import AppPagination from "../verificationUpload/AppPagination";
import { ITEMS_LIMIT_FILES_PARTNER_LIST } from "../../Constants";
import { useOps } from "../../hooks/useOps";
import { download_cml, getClientList } from "../../api";
import { userStatusConfig } from "./constsnds";
import { handleKey } from "../utils/common";
import PageLoader from "../dashboard/products/common/pageLoader";
import ls from "localstorage-slim";
import { saveAs } from "file-saver";
import GetAppIcon from "@material-ui/icons/GetApp";
import excelLogo from "../../assets/icons/excel.png";
import { OnboardingContext } from "../../Context";
ls.config.encrypt = true;

function NewClientListOpsUser() {
  const mfo_role_name = ls.get("logged-entity", { secret: 50 })?.mfo_role_name;
  const naivgate = useNavigate();
  const [tab, setTab] = useState(
    mfo_role_name === "cxo" || mfo_role_name === "central_analyst" ? 1 : 0
  );
  const [offset, setItemOffset] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState("");
  const { opsData, pageCount, loading } = useOps(
    getClientList,
    userStatusConfig[tab],
    search,
    ITEMS_LIMIT_FILES_PARTNER_LIST,
    offset
  );
  const { dispatch } = OnboardingContext();
  const { state } = useLocation();
  const [header, setHeader] = useState([]);
  const [openFLow, setOpenFlow] = useState(
    state?.openModal ? state?.openModal : false
  );
  const [tableData, setTableData] = useState([]);
  function renderDownload() {
    return (
      <div
        style={{
          display: " flex",

          gap: "10px",
        }}
      >
        <div
          style={{
            display: " flex",
            alignItems: " center",
            gap: "10px",
            paddingBottom: "10px",
            cursor: "pointer",
          }}
          onClick={() => {
            dispatch({
              type: "UPDATE_SNACKBAR_DATA",
              payload: {
                success: true,
                error: false,
                showMsg: true,
                message: "Downloading...",
              },
            });
            download_cml().then((res) => {
              const data = res.data;
              let blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              });
              saveAs(blob, "CML");
            });
          }}
        >
          <img src={excelLogo} style={{ height: "23px", widows: "32px" }} />{" "}
          DOWNLOAD CML <GetAppIcon style={{ padding: "0px 6px" }} />
        </div>
      </div>
    );
  }
  const handleRow = (row, headerData) => {
    let newCOl = headerData?.map((col) => {
      if (col?.field == "userRole" || col?.title == "Head of Family") {
        return {
          ...col,
          field: handleKey(col?.field, row) ? handleKey(col?.field, row) : "--",
          // color2: "#711CF1",
          // onClick: row?.onClick,
          dontShow: row?.dontShow,
          customCellClassName: "childCellCardView",
          customFontClassName: "fontWeight500",
        };
      }

      if (col?.title == "Email") {
        return {
          ...col,
          field: handleKey(col?.field, row) ? handleKey(col?.field, row) : "--",
          onClick: col?.onClick
            ? () => {
                col?.onClick(row);
              }
            : () => {},
          dontShow: row?.dontShow,
          dontShowFirstColBorder: true,
          customCellClassName: "childCellCardView",
          fontWeight: "600",
          customFontClassName: "fontWeight500",
        };
      }

      if (col?.title == "Status") {
        return {
          ...col,
          field: handleKey(col?.field, row) ? handleKey(col?.field, row) : "--",
          onClick: col?.onClick
            ? () => {
                col?.onClick(row);
              }
            : () => {},
          // : () => {
          //     navigate("/user-management/edit", { state: { data: row } });
          //   }
          dontShow: row?.dontShow,
          dontShowFirstColBorder: true,
          customCellClassName: "childCellCardView",
          customFontClassName:
            row[`${col?.field}`] == "Active" ? "activeClass" : "inActiveClass",
          fontWeight: "600",
        };
      }

      if (col?.field == "full_name") {
        return {
          ...col,
          field: handleKey(col?.field, row) ? handleKey(col?.field, row) : "--",
          // color2: "#711CF1",
          onClick: () => {
            col?.onClick(row);
          },
          dontShow: row?.dontShow,
          dontShowFirstColBorder: true,
          customCellClassName: "childCellCardView",
          pic: row[`pic`],
        };
      }
      return {
        ...col,
        field: handleKey(col?.field, row) ? handleKey(col?.field, row) : "--",
        // color2: "#711CF1",
        onClick: row?.onClick,
        dontShow: row?.dontShow,
        customCellClassName: "childCellCardView",
        customFontClassName: "fontWeight500",
      };
    });

    return newCOl;
  };

  const handleHeader = (headerData) => {
    headerData?.map((val) => {
      if (val?.title == "Email" || val?.title == "Status") {
        val.onClick = (data) => {
          if (tab == 1) {
            naivgate("/client/user-management-list", {
              state: {
                data: data,
                headerType: "userManagementDetailsHeader",
              },
            });
          }

          if (tab == 0) {
            naivgate(`/client/pending/detials/${data?.hof_id}`, {
              state: {
                data: data,
                userType: "client",
              },
            });
          }

          if (tab == 2) {
            naivgate(`/client/pending/detials/${data?.hof_id}`, {
              state: {
                data: data,
                userType: "client",
              },
            });
          }
        };
      }
    });
    setHeader(() => headerData);
    setTableData(() => opsData);
  };

  useEffect(() => {
    console.log(opsData, "agjabk");
    if (tab === 0 || tab === 2) {
      handleHeader(clientListHeaderOpsPending);
    } else {
      handleHeader(clientListHeaderOps);
    }

    // setData(bodyData4);
    return () => {
      setTableData(() => []);
      setHeader(() => []);
    };
  }, [tab, opsData]);

  const handleTabChange = () => {
    setItemOffset(0);
    setPageNumber(1);
  };

  const scopes = JSON.parse(ls.get("scopes"));

  return (
    <div className="newPartenrListContainer">
      <div style={{ paddingLeft: "41px", paddingBottom: "40px" }}>
        <PartnerHeading hideBackButton noStep={true} mainHead={"Client"} />
      </div>
      <CustomTabComponent
        customTabHandle={handleTabChange}
        setTab={setTab}
        addBtnTitle={"Add Client"}
        customScopes={[scopes?.can_add_client]}
        handleIconClick={() => {
          setOpenFlow(true);
        }}
        setSearch={(e) => {
          setItemOffset(0);
          setPageNumber(1);
          setSearch(e);
        }}
        addRepComp={<>{scopes.can_download_cml ? renderDownload() : <></>}</>}
      />
      <div style={{ paddingLeft: "41px", paddingRight: "38px" }}>
        {header?.length > 0 && tableData?.length > 0 && (
          <>
            <TableSummaryDetails
              headerData={header}
              data={tableData}
              customHandleRow={handleRow}
              // customIcon={customIcon}
              customStyle={{ headerStyle: { className: "customHeader1" } }}
            />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <AppPagination
                pageCount={pageCount}
                itemLimit={ITEMS_LIMIT_FILES_PARTNER_LIST}
                setItemOffset={setItemOffset}
                pageNumber={offset / ITEMS_LIMIT_FILES_PARTNER_LIST + 1 || 1}
                // setPageNumber={setPageNumber}
              />
            </div>
          </>
        )}
      </div>

      <ClientAssistentJourneyFlow
        openModal={openFLow}
        setOpenModal={setOpenFlow}
      />
      <PageLoader showLoader={loading} />
    </div>
  );
}

export default NewClientListOpsUser;
