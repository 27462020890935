import React, { useState, useEffect } from "react";
import EquityIPOHeading from "./EquityIPOHeading";
import { useLocation, useParams } from "react-router-dom";
import { Card, CardContent, Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useStyles from "../../common/ProductsStyles";
import Image from "../../../../../assets/partner-images/man-laptop.png";

import EquityTabReport from "./EquityTabReport";
import { currencyFormat } from "../../../../../hooks/constantFunction";
import { dateFormatCommon } from "../../../../utils/common";
import moment from "moment";
import BarChart from "../../../../charts/BarChart";
import { divergingCommonProps } from "../../../../charts/nivo_objects";

export default function EquityIPODetails() {
  const classes = useStyles();
  const [graphtype, setgraphtype] = useState("revenue");
  let data1 = [
    {
      period: "2020",
      BB: 142,
    },
    {
      period: "2021",
      AA: 422,
    },
    {
      period: "2022",
      AA: 486,
    },
  ];
  let data2 = [
    {
      period: "2020",
      BB: 3809,
    },
    {
      period: "2021",
      AA: 6004,
    },
    {
      period: "2022",
      AA: 10486,
    },
  ];
  let data3 = [
    {
      period: "2020",
      BB: -175,
    },
    {
      period: "2021",
      AA: -135,
    },
    {
      period: "2022",
      AA: -296,
    },
  ];

  const location = useLocation();
  let diverging = {
    ...divergingCommonProps,
    enableGridY: false,
    labelFormat: (d) => <tspan y={0}>{d}</tspan>,
    axisLeft: null,
    minValue: 0,
    maxValue: 550,

    indexBy: "period",
    valueFormat: (d) => (
      <tspan y={-15} fill="#fff">
        {d}
      </tspan>
    ),
  };
  diverging.markers[1].lineStyle = { stroke: "#1F1C40", strokeWidth: 1 };
  diverging.axisBottom.legend = " ";
  // Destructure
  const {
    state: { data: ipo, ipoType },
  } = location;

  let timeline = {
    year: "2021",
    date: "21 Dec",
    invest: "",
    data: [
      {
        bColor: "1",
        year: "",
        date: "",
        intrest: "",
        value: ipo?.OPENDATE ? moment(ipo?.OPENDATE).format("Do MMMM") : "---",
        title: "Bidding Starts",
      },
      {
        bColor: "0.6",
        year: "",
        date: "",
        intrest: "",
        value: ipo.CLOSDATE ? ipo.CLOSDATE : "---",
        title: "Bidding Ends",
      },
      {
        bColor: "1",
        year: "",
        date: "",
        intrest: "",
        value: "---",
        title: "Allotment Finalisation*",
      },
      {
        bColor: "0.6",
        year: "",
        date: "",
        intrest: "",
        value: "---",
        title: "Refund Initiation",
      },
      {
        bColor: "1",
        year: "",
        date: "",
        intrest: "",
        value: "---",
        title: "Demat Transfer",
      },
      {
        bColor: "0.6",
        year: "",
        date: "",
        intrest: "",
        value: "---",
        title: "Listing",
        value: ipo?.LISTDATE || "--",
      },
    ],
  };

  const { pageName } = useParams();
  const pname = {
    revenue: { data: data1, min: 0, max: 550 },
    totalassets: { data: data2, min: 0, max: 10999 },
    profit: { data: data3, min: -350, max: 20 },
  };

  return (
    <>
      <EquityIPOHeading />
      <div className={classes.mainContent}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "2%",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <div
            style={{
              width: "85px",
              height: "85px",
              borderRadius: "50%",
              background: "white",
            }}
          ></div>

          <div style={{ margin: "auto auto auto 0px" }}>
            <div
              className={classes.ipoTextTitle}
              style={{ whiteSpace: "nowrap" }}
            >
              {ipo?.companyName || ipo?.LNAME || "-"} IPO
            </div>
            <div className={classes.tableHeaderTextNew}>
              {ipo?.companyName || ipo?.LNAME || "-"}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              margin: "32px",
              justifyContent: "center",
            }}
          >
            <div className={classes.ipoTextTitle}>
              ₹13,500{" "}
              {/* {ipo?.parValue || ipo?.issueprice || ipo?.issuepri2 || "-"} */}
              <span className={classes.tableHeaderTextNew}>/40 Share</span>
            </div>
            <div className={classes.tableHeaderTextNew}>Minimum Investment</div>
          </div>
        </div>
        {ipoType == "listed" ? (
          <div className={classes.ipoBoxes}>
            <div style={{ display: "flex", padding: "2%" }}>
              <div>
                <div className={classes.tableHeaderTextNew}>Listed On</div>
                <div className={classes.ipoBoxesText}>
                  {/* {ipo?.LISTDATE || "--"} */}
                  BSE
                </div>
              </div>
              {/* <hr className="filter-divider"></hr> */}

              <hr className="filter-divider"></hr>
              <div>
                <div className={classes.tableHeaderTextNew}>Issued Price</div>
                <div className={classes.ipoBoxesText}>
                  {ipo?.LISTVOL || "-"}
                </div>
              </div>
              <hr className="filter-divider"></hr>
              <div>
                <div className={classes.tableHeaderTextNew}>Listing Price</div>
                <div className={classes.ipoBoxesText}>
                  {currencyFormat(ipo?.listprice) || "-"}
                </div>
              </div>
              <hr className="filter-divider"></hr>
              <div>
                <div className={classes.tableHeaderTextNew}>Price Range </div>
                <div className={classes.ipoBoxesText}>
                  {ipo?.issueprice || "-"} - {ipo?.issuepri2 || "-"}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className={classes.ipoBoxes}>
          <div style={{ display: "flex", padding: "2%" }}>
            <div>
              <div className={classes.tableHeaderTextNew}>
                {ipoType == "listed"
                  ? "Bidding Date"
                  : "Open Date - Close Date"}
              </div>
              <div className={classes.ipoBoxesText}>
                {ipo?.opendate || "-"} - {ipo?.closedate || "-"}
              </div>
            </div>
            <hr className="filter-divider"></hr>
            <div>
              <div className={classes.tableHeaderTextNew}>Min. Investment</div>
              <div className={classes.ipoBoxesText}>
                {currencyFormat(ipo?.issueprice) ||
                  currencyFormat(ipo?.issuepri2) ||
                  currencyFormat(ipo?.parValue) ||
                  "-"}
              </div>
            </div>
            <hr className="filter-divider"></hr>
            <div>
              <div className={classes.tableHeaderTextNew}>Lot Size</div>
              <div className={classes.ipoBoxesText}>{ipo?.LISTVOL || "-"}</div>
            </div>
            <hr className="filter-divider"></hr>
            <div>
              <div className={classes.tableHeaderTextNew}>Price Range</div>
              <div className={classes.ipoBoxesText}>
                {ipo?.priceRange || currencyFormat(ipo?.issueprice) || "-"}
              </div>
            </div>
            <hr className="filter-divider"></hr>
            <div>
              <div className={classes.tableHeaderTextNew}>Issue Size </div>
              <div className={classes.ipoBoxesText}>
                {ipo?.IssueSize || "-"}
              </div>
            </div>
            <hr className="filter-divider"></hr>
            <div>
              <div className={classes.tableHeaderTextNew}>IPO</div>
              <div className={classes.ipoBoxesText}>RHP PDF</div>
            </div>
          </div>
        </div>

        <div className={classes.ipoBoxes}>
          <div style={{ marginTop: "2%" }}>
            <div className={classes.ipoBoxesText}>IPO Timeline</div>
          </div>
          <div style={{ width: "100%", display: "flex" }}>
            {timeline.data.map((t) => {
              return (
                <div style={{ flex: "1" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        minWidth: "140px",
                        height: "6px",
                        backgroundColor: "#4E189F",
                      }}
                    ></div>
                    <div
                      className="subtext"
                      style={{
                        position: "absolute",
                        left: "97%",
                        top: "-20px",
                      }}
                    >
                      {t.year}
                    </div>
                    <div
                      style={{
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        background:
                          "linear-gradient(180deg, #AA6FFE 0%, #711CF1 100%)",
                      }}
                    ></div>
                    <div
                      className="circle_text"
                      style={{
                        position: "absolute",
                        left: "97%",
                        top: "15px",
                        width: "90px",
                      }}
                    ></div>

                    <div
                      style={{
                        position: "absolute",
                        left: "50%",
                        top: "40px",
                        color: "#fff",
                      }}
                    >
                      <div
                        style={{ opacity: `${t.bColor}` }}
                        className={classes.ipoBoxesBlue}
                      >
                        <div
                          className={classes.tableHeaderTextNew}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "10px",
                          }}
                        >
                          {t.title}
                        </div>
                        <div
                          className={classes.ipoBoxesText}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {t.value}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <div
                style={{
                  minWidth: "140px",
                  maxWidth: "auto",
                  height: "6px",
                  backgroundColor: "#4E189F",
                }}
              ></div>
            </div>
          </div>

          <div style={{ display: "flex", padding: "2%", minHeight: "90px" }}>
            {/* <div className={classes.ipoBoxesBlue}>
              <div
                className={classes.tableHeaderTextNew}
                style={{ display: "flex", justifyContent: "center" }}
              >
                Bidding Date
              </div>
              <div
                className={classes.ipoBoxesText}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  whiteSpace: "nowrap",
                }}
              >
                {ipo?.opendate || "-"} - {ipo?.closedate || "-"}
              </div>
            </div>
            <div className={classes.ipoBoxesBlue}>
              <div
                className={classes.tableHeaderTextNew}
                style={{ display: "flex", justifyContent: "center" }}
              >
                Min. Investment
              </div>
              <div
                className={classes.ipoBoxesText}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {currencyFormat(ipo?.issueprice)}
              </div>
            </div>
            <div className={classes.ipoBoxesBlue}>
              <div
                className={classes.tableHeaderTextNew}
                style={{ display: "flex", justifyContent: "center" }}
              >
                Lot Size
              </div>
              <div
                className={classes.ipoBoxesText}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {ipo?.IssueSize || "-"}
              </div>
            </div>
            <div className={classes.ipoBoxesBlue}>
              <div
                className={classes.tableHeaderTextNew}
                style={{ display: "flex", justifyContent: "center" }}
              >
                Price Range
              </div>
              <div
                className={classes.ipoBoxesText}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {currencyFormat(ipo?.issueprice) || "-"} -{" "}
                {currencyFormat(ipo?.issuepri2) || "-"}
              </div>
            </div>
            <div className={classes.ipoBoxesBlue}>
              <div
                className={classes.tableHeaderTextNew}
                style={{ display: "flex", justifyContent: "center" }}
              >
                Issue Size{" "}
              </div>
              <div
                className={classes.ipoBoxesText}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {ipo?.IssueSize || "-"}
              </div>
            </div>
            <div className={classes.ipoBoxesBlue}>
              <div
                className={classes.tableHeaderTextNew}
                style={{ display: "flex", justifyContent: "center" }}
              >
                IPO
              </div>
              <div
                className={classes.ipoBoxesText}
                style={{ display: "flex", justifyContent: "center" }}
              >
                RHP PDF
              </div>
            </div> */}
          </div>
        </div>

        <div style={{ margin: "3% auto auto 2%" }}>
          <div style={{ display: "flex" }}>
            <div className={classes.ipoBoxesText}>Subscription Status</div>
            <div
              className={classes.iposmallText}
              style={{ paddingLeft: "20px" }}
            >
              As on 14 Dec ‘22
            </div>
          </div>
          <Grid container style={{ marginTop: "2%", marginBottom: "2%" }}>
            <Grid md={6}>
              <tr style={{}} className="no-border td ">
                <td className={classes.ipoTrSmallText}>
                  Qualified Institution Buyers
                </td>
                <td
                  className={classes.ipoTrBigText}
                  style={{ marginLeft: " 23vw" }}
                >
                  4.13x
                </td>
              </tr>
              <tr className="no-border td ">
                <td className={classes.ipoTrSmallText}>
                  Retail Individual Investor
                </td>
                <td
                  className={classes.ipoTrBigText}
                  style={{ marginLeft: " 23vw" }}
                >
                  4.13x
                </td>
              </tr>
              <tr className="no-border td ">
                <td className={classes.ipoTrSmallText}>Employee</td>
                <td
                  className={classes.ipoTrBigText}
                  style={{ marginLeft: " 23vw" }}
                >
                  4.13x
                </td>
              </tr>
            </Grid>
            <Grid md={6}>
              <tr className="no-border td ">
                <td className={classes.ipoTrSmallText}>
                  Non-Institutional Investor
                </td>
                <td
                  className={classes.ipoTrBigText}
                  style={{ marginLeft: " 23vw" }}
                >
                  4.13x
                </td>
              </tr>
              <tr className="no-border td ">
                <td className={classes.ipoTrSmallText}>Total</td>
                <td
                  className={classes.ipoTrBigText}
                  style={{ marginLeft: " 23vw" }}
                >
                  4.13x
                </td>
              </tr>
            </Grid>
          </Grid>
        </div>
      </div>

      <div className={classes.mainContent} style={{ padding: "3%" }}>
        <div className={classes.titleText}>About {ipo?.companyName || "-"}</div>
        <div className={classes.ipoPara} style={{ paddingTop: "3%" }}>
          In 2020, the IPO of Computer Age Management Services (CAMS) received
          overwhelming response. The public issue of the financial services and
          infrastructure services provider was subscribed 34 times. And now,
          CAMS will soon be joined by its rival, KFintech, on the bourses.
          <br />
          <br />
          In terms of total assets under management (AUM) of mutual funds
          serviced, the share of CAMS and KFintech stands at 69% and 31%
          respectively, making it a duopolistic market. However, in terms of the
          number of asset management companies (AMCs) serviced, KFintech is the
          largest investor solutions provider. It provides services to 24 out of
          the 41 AMCs, thereby accounting for 59% of the market in terms of
          clientele.
          <br />
          <br />
          KFintech (formerly known as Karvy Fintech) is a leading
          technology-driven platform, providing services and solutions to the
          capital markets ecosystem including asset managers and corporate
          issuers. It also provides investor solutions (including transaction
          origination and processing for mutual funds and private retirement
          schemes) in Malaysia, Philippines and Hong Kong.
        </div>
        <div className={classes.ipoSubPara} style={{ paddingTop: "3%" }}>
          KFintech IPO will be completely an offer for sale. Here are key
          details about the KFintech IPO:
        </div>
      </div>

      <div className={classes.mainContent} style={{ padding: "3%" }}>
        <div className={classes.titleText}>
          Here’s a quick look at KFintech IPO’s strengths,
          <br /> weakness, opportunities and risks (SWOT analysis)
        </div>
        <div className={classes.ipoSubPara} style={{ paddingTop: "3%" }}>
          Strengths and opportunities
        </div>
        <div className={classes.ipoSubParaText} style={{ paddingTop: "2%" }}>
          1. Offers services to mutual funds, alternative investment funds
          (AIFs), wealth managers, pension and corporate issuers
          <br />
          2. Holds 30% market share based on the number of AIFs serviced
          <br />
          3. Is one of the two central record keeping agencies for the National
          Pension System (NPS) in India
          <br />
          4. Services 18 AMC clients in Malaysia and three clients in
          Philippines and Hong Kong
          <br />
          5. Asset-light business model with recurring revenue model, high
          operating leverage, profitability and cash generation
          <br />
        </div>
        <div className={classes.ipoSubPara} style={{ paddingTop: "3%" }}>
          Risks and threats
        </div>
        <div className={classes.ipoSubParaText} style={{ paddingTop: "2%" }}>
          <div style={{ margin: "0" }}>
            1. Mutual fund clients accounted for around 60% of the revenue on
            average annually in FY20, FY21 and FY22
            <br />
            2. Three five customers accounted for around 53% of the revenue in
            the FY22
            <br />
            3. The attrition rate, the percentage of employees who leave the
            company, stood at 26.7% in FY22
            <br />
            4. Operates in a highly regulated environment
            <br />
            5. Consolidation in the financial services industry could adversely
            affect the revenue by eliminating some of the existing and potential
            clients
          </div>
        </div>
      </div>

      <div
        className={classes.mainContent}
        style={{ padding: "3%", minHeight: "400px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            minHeight: "30%",
          }}
        >
          <div className={classes.titleText}>Financials</div>
          <div className={classes.titleText}>
            <EquityTabReport
              pageName={pageName}
              setgraphtype={setgraphtype}
            ></EquityTabReport>
          </div>
        </div>
        {/* <h1 style={{ color: "#FFF" }}> Graph here</h1> */}
        <div className={classes.assetAllocationBar}>
          {/* {console.log(pname[graphtype], "page name data")} */}
          <Grid xs={12}>
            <BarChart
              enableLabel={true}
              fill={[
                { match: { id: "BB" }, id: "c2" },
                { match: { id: "AA" }, id: "c2" },
              ]}
              config={{ height: "400px", padding: 0.88, disabledLegend: true }}
              keys={["BB", "AA"]}
              // tooltip={tooltip}
              colors={[
                "#FF9446",
                "#82EBA1",
                "#509FF1",
                "#711CF1",
                "#F52D6A",
                "#B80000",
              ]}
              commonprops={{
                ...diverging,
                minValue: pname[graphtype].min,
                maxValue: pname[graphtype].max,
              }}
              data={pname[graphtype].data}
            />{" "}
          </Grid>
          {/* <div className={classes.progressBar}>
                  
                  {obj &&
                    Object.keys(obj).map(
                      (val, index) => {
                        if (
                          Number(obj[val]) > 0 &&
                          assetProgressBarColors[val]
                        )
                          return (
                            <div
                              onMouseEnter={() => {
                            
                                let acolors = {
                                  ...disabledassetProgressBarColors,
                                };
                                acolors[val] = assetProgressBarColors[val];
                                setAsset_color(acolors);
                              }}
                              onMouseLeave={() => {
                                setAsset_color({ ...assetProgressBarColors });
                              }}
                              // onMouseMove={(ev) => handleMouseMove(ev)}
                              className={
                                classes[`progressBarItem${index + 1}`] +
                                " outer-tooltip"
                              }
                              style={{
                                width: `${obj[val]}%`,
                                background: asset_color[val]?.color,
                              }}
                            >
                              <div className="inner-tooltip graph-tooltip-box">
                                <span className="graph-tooltip-text">
                                  {val}
                                </span>
                                <span className="graph-tooltip-text">
                                  {" "}
                                  {obj[val]}
                                </span>
                              </div>
                            </div>
                          );
                      }
                    )}
                </div> */}
          {/* <ul className={classes.list}>
                  {obj &&
                    Object.keys(obj).map(
                      (val, index) => {
                        if (
                          Number(obj[val]) > 0 &&
                          assetProgressBarColors[val]
                        )
                          return (
                            <li style={{ color: asset_color[val]?.liColor }}>
                              <span className={classes.listItems}>
                                <span>{assetProgressBarColors[val]?.name}</span>
                                <span>
                                  {Number(
                                    obj[val]
                                  ).toFixed(2)}
                                  %
                                </span>
                              </span>
                            </li>
                          );
                      }
                    )}
                </ul> */}
        </div>
      </div>
    </>
  );
}
