import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { ReactComponent as Dashboard } from "../../../assets/icons/dashboard.svg";
import { ReactComponent as Lead } from "../../../assets/icons/lead.svg";
import { ReactComponent as Document } from "../../../assets/icons/document.svg";
import { ReactComponent as UserSetting } from "../../../assets/icons/user-setting.svg";
import "./default.css";
import Header from "./Header";
import ls from "localstorage-slim";
import { routes } from "../../../routes";

const Default = ({ children }) => {
  const location = useLocation();
  const loc = location.pathname;

  const [isOpen, setIsOpen] = useState(false);
  const opensidebar = () => setIsOpen(!isOpen);
  const closesidebar = () => setIsOpen(false);

  const scopes = JSON.parse(ls.get("scopes"));

  let menuItems = [
    {
      path: "/home",
      name: "Dashboard",
      icon: <Dashboard />,
    },
  ];
  if (scopes) {
    if (scopes.can_view_partner_list) {
      menuItems.push({
        path: "/partner",
        name: "Partner",
        icon: <Lead />,
      });
    }
    if (scopes.can_view_client_list) {
      menuItems.push({
        path: "/client",
        name: "Client",
        icon: <Document />,
      });
    }
    if (scopes.can_view_data_upload_know_more) {
      menuItems.push({
        path: "/product-upload",
        name: "Data Upload",
        icon: <UserSetting />,
      });
    }
    if (scopes.can_view_uploaded_data_file_list) {
      menuItems.push({
        path: "/verification-upload/pending",
        name: "Verification Upload",
        icon: <Document />,
      });
    }
    if (scopes.can_view_user_management) {
      menuItems.push({
        path: routes?.userManagement,
        name: "User Management",
        icon: <UserSetting />,
      });
    }
    if (scopes.can_view_scheme_master_list) {
      menuItems.push({
        path: "/masters",
        name: "Masters",
        icon: <UserSetting />,
      });
    }
  }

  // const menuItem = [
  //   {
  //     path: "/home",
  //     name: "Dashboard",
  //     icon: <Dashboard />,
  //   },
  //   scopes?.can_view_partner_list
  //     ? {
  //         path: "/partner",
  //         name: "Partner",
  //         icon: <Lead />,
  //       }
  //     : {},
  //   scopes?.can_view_client_list
  //     ? {
  //         path: "/client",
  //         name: "Client",
  //         icon: <Document />,
  //       }
  //     : {},
  //   scopes?.can_view_data_upload_know_more
  //     ? {
  //         path: "/product-upload",
  //         name: "Data Upload",
  //         icon: <UserSetting />,
  //       }
  //     : {},
  //   scopes?.can_view_data_verification_upload
  //     ? {
  //         path: "/verification-upload/pending",
  //         name: "Verification Upload",
  //         icon: <Document />,
  //       }
  //     : {},
  //   scopes?.can_view_user_management
  //     ? {
  //         path: "/user-management",
  //         name: "User Management",
  //         icon: <UserSetting />,
  //       }
  //     : {},
  //   scopes?.can_view_scheme_master_list
  //     ? {
  //         path: "/masters",
  //         name: "Masters",
  //         icon: <UserSetting />,
  //       }
  //     : {},
  // ];

  return (
    <div className="header">
      <Header />
      <Grid className="container">
        {/* <div className="main"> */}
        <div className="mainContent">{children}</div>
        {/* </div> */}
      </Grid>
    </div>
  );
};

export default Default;
