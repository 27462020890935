const handleALL = ({ headers, newHeaderList, field, changeInto, changes }) => {
  let changeList = Object.keys(changes);
  headers.map((val) => {
    if (changeList.includes(val?.field)) {
      val.title = changes[val?.field];
    }
    newHeaderList.push(val);
  });
};

const handleHeaderChange = (prev, option, equityCorporateALlTableConfig) => {
  let newList = [];

  if (option == "All") {
    handleALL({
      headers: prev,
      newHeaderList: newList,
      changes: {
        remark: "Remark",
        ratio: "Ratio/Amount ",
        type: "Type",
        stockName: "Stock Name",
        eventDate: "Ex Date / Event Date",
      },
    });
  } else if (option == "Dividend") {
    handleALL({
      headers: prev,
      newHeaderList: newList,
      changes: {
        remark: "Remark",
        ratio: "Divident Amount",
        eventDate: "Dividend Date",
        type: "Type",
        stockName: "Stock Name",
      },
    });
  } else if (option == "Bonus") {
    handleALL({
      headers: prev,
      newHeaderList: newList,
      changes: {
        remark: "Remark",
        ratio: "Ratio",
        eventDate: "Bonus Date / Event Date",
        type: "Type",
        stockName: "Stock Name",
      },
    });
  } else if (option == "Split") {
    handleALL({
      headers: prev,
      newHeaderList: newList,
      changes: {
        remark: "Remark",
        ratio: "Ratio",
        eventDate: "Split Date / Event Date",
        type: "Type",
        stockName: "Stock Name",
      },
    });
  } else if (option == "Right") {
    handleALL({
      headers: prev,
      newHeaderList: newList,
      changes: {
        remark: "Remark",
        ratio: "Ratio",
        eventDate: "Right Date / Event Date",
        type: "Type",
        stockName: "Stock Name",
      },
    });
  } else {
    handleALL({
      headers: equityCorporateALlTableConfig,
      newHeaderList: newList,
      changes: {
        remark: "Remark",
        ratio: "Ratio",
        type: "Type",
        stockName: "Stock Name",
        eventDate: "Ex Date / Event Date",
      },
    });
  }

  return newList;
};

// const handleEqGainLossText = (text) => {
//   let a = text.split(" ")?.map((val, i) => {
//     if (i == 1 || i == 2) {
//       return <span>{val}</span>;
//     }
//     return val;
//   });
//   console.log(text?.split(" "),a, "kjbkjavkads");
// };

module.exports = {
  handleALL,
  handleHeaderChange,
  // handleEqGainLossText,
};
