import React, { useEffect, useState } from "react";
import ForgotPasswordRequest from "../components/forgotPassword/ForgotPasswordRequest";
import ForgotPasswordResponse from "../components/forgotPassword/ForgotPasswordResponse";
import { makeStyles } from "@material-ui/core/styles";
import SignUpImage from "../assets/partner-images/Sign_up.png";
import Logo from "../assets/icons/logo.png";
import { forgotPinSend } from "../api";
import Loader from "../components/loader/Loader";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundImage: `url(${SignUpImage})`,
    height: "100vh",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: "#170630",
  },
  logo: {
    width: "110px",
    left: "5px",
    top: "15px",
    margin: "0px",
    position: "absolute",
  },
  contentBox: {
    background: "rgba(11, 3, 24, 0.6)",
    border: "1px solid #4E189F",
    boxShadow: "0px 0px 10px #4E189F",
    backdropFilter: "blur(50px)",
    borderRadius: "50px",
    position: "relative",
    left: "22%",
    width: "40%",
    top: "14%",
    [theme.breakpoints.down("md")]: {
      left: "10%",
      width: "65%",
      top: "15%",
    },
    [theme.breakpoints.down("xs")]: {
      left: "5%",
      width: "90%",
      top: "15%",
    },
  },
  mainContent: {
    padding: "15px 20px 2px 20px",
  },
}));

function ForgotPassword() {
  const {
    state: { loginEntityType },
  } = useLocation();

  const [requestPage, setRequestPage] = useState(true);
  const classes = useStyles();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showLoder, setShowLoader] = useState(false);
  const [formData, setFormData] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  const submitForm = async (values) => {
    setShowLoader(true);
    setShowSuccessMessage(false);
    setShowErrorMessage(false);
    setErrorMsg(null);
    let forgotPinUserType = "";
    loginEntityType === "admin"
      ? (forgotPinUserType = "mfo-user")
      : (forgotPinUserType = loginEntityType);

    try {
      const data = await forgotPinSend(values, forgotPinUserType);
      setShowLoader(false);
      if (data.data.success === true) {
        setRequestPage(false);
        setShowSuccessMessage(true);
      } else {
        setShowErrorMessage(true);
      }
    } catch (error) {
      setErrorMsg(error.response.data.error.message);
      setShowLoader(false);
      setShowErrorMessage(true);
      setShowSuccessMessage(false);
    }
  };

  const resendPin = () => {
    submitForm(formData);
  };

  useEffect(() => {
    if (formData) {
      submitForm(formData);
    }
  }, [formData]);

  return (
    <div className={classes.container}>
      <img className={classes.logo} src={Logo} alt="logo"></img>
      <div className={classes.contentBox}>
        <div className={classes.mainContent}>
          {showLoder && <Loader />}
          {requestPage ? (
            <ForgotPasswordRequest
              setFormData={setFormData}
              showErrorMessage={showErrorMessage}
              errorMsg={errorMsg}
            />
          ) : (
            <ForgotPasswordResponse
              resendPin={resendPin}
              formData={formData}
              showSuccessMessage={showSuccessMessage}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
