import React from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../routes";
export default function Minnav({ options = [], display, setDisplay }) {
  const wrapperRef = useRef(null);

  const location = useLocation();
  const navigate = useNavigate();

  // let params = useParams();
  const query = new URLSearchParams(location.search);

  const current = query.get("selected");

  const handleClickOutside = (event) => {
    const { current: wrap } = wrapperRef;
    if (wrap && !wrap.contains(event.target)) {
      setDisplay(false);
    }
  };

  const replaceStr = (str) => {
    str = str.replace(/ /g, "XA");
    str = str.replace(/&/g, "YB");
    return str;
  };

  const updateselected = (poke) => {
    // setval(poke);
    setDisplay(false);
  };
  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <div>
      <div className="" ref={wrapperRef}>
        {display && (
          <div
            className={
              options[0].name === "Dividend & Bonus"
                ? `dropContainer dropContainerLeft`
                : `dropContainer dropContainerRight`
            }
          >
            <div className={"navopt "} style={{ height: "50px" }}></div>
            {options.map((value, i) => {
              return (
                <div
                  onClick={() => {
                    updateselected(value.name);
                    navigate(
                      options[0].name === "Dividend & Bonus"
                        ? routes?.equityViewChartPriceChart +
                            "?selected=" +
                            replaceStr(value.name)
                        : routes?.equityViewChartMore +
                            "?selected=" +
                            replaceStr(value.name)
                    );
                  }}
                  className={"opt " + current == value.name ? "selected" : ""}
                  key={i}
                  tabIndex="0"
                >
                  <div
                    className={
                      "navopt " + (current == value.name ? "selected" : "")
                    }
                  >
                    {value.name}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
