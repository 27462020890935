import React from "react";
import { CheckedSvgIcon } from "./common/CheckedSvgIcon";
import { UncheckedSvgIcon } from "./common/UncheckedSvgIcon";
import { Box } from "@material-ui/core";
import { useNewFilterStyles } from "./newFilterStyles";

export const NewFilterPromoterStake = ({
  isPromoterStakeSelected,
  setIsPromoterStakeSelected,
}) => {
  const classes = useNewFilterStyles();
  return (
    <>
      <Box
        className={classes.promoterStakeContainer}
        style={{
          border: isPromoterStakeSelected ? "1px solid #00b78b" : "none",
        }}
        onClick={() => setIsPromoterStakeSelected((prev) => !prev)}
      >
        <div>
          <h4 className={classes.promoterHeader}>Promoter Stake</h4>
          <p className={classes.promoterSubHeader}>
            Promoter stake refers to the ownership percentage held by the
            founders or key individuals in a company. By including the promoter
            stake filter, we are considering these holdings directly tied to
            you.
          </p>
        </div>
        <div className={classes.promoterCheckedContainer}>
          {isPromoterStakeSelected ? (
            <>
              <h4 className={classes.promoterHighlightedTxt}>
                Now Showing Promoter Stake
              </h4>
              <CheckedSvgIcon />
            </>
          ) : (
            <UncheckedSvgIcon />
          )}
        </div>
      </Box>
    </>
  );
};
