import React, { useEffect, useState } from "react";
import "./NewtabComp.css";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Backarrow } from "../../../../assets/icons/backarrow.svg";
import { IconButton, Popover, useTheme } from "@material-ui/core";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import { useLayoutEffect } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const data32 = [
  { title: "Summary" },
  { title: "Performance  Summary" },
  { title: "Top Holdings" },
  { title: "All Transactions" },
  { title: "Top Advisors" },
  { title: "Asset Allocation" },
  { title: "Portfolio Aging" },
  { title: "Top Advisors" },
  { title: "All Transactions" },
  { title: "Summary" },
  { title: "Summary" },
  { title: "Performance  Summary" },
  { title: "Top Holdings" },
  { title: "All Transactions" },
  { title: "Top Advisors" },
  { title: "Asset Allocation" },
  { title: "Portfolio Aging" },
  { title: "Top Advisors" },
  { title: "All Transactions" },
  { title: "Summary" },
];

function NewTabComp({
  data,
  customHandle,
  customWidth,
  customSelect,
  disableArrow,
}) {
  const theme = useTheme();
  useEffect(() => {
    setSelect(
      customSelect !== undefined ? (customSelect == -1 ? 0 : customSelect) : 0
    );
  }, [customSelect]);
  const [select, setSelect] = useState(
    customSelect !== undefined ? (customSelect == -1 ? 0 : customSelect) : 0
  );
  const [width, setWidth] = useState(0);
  const ref = useRef(null);
  const anchorEl = useRef(null);
  const mainContainer = useRef(null);
  const navigate = useNavigate();
  let data2 = data?.map((val, i) => ({ ...val, id: i }));
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (e) => {
    setOpen(true);
  };

  const handleClick = (id, data) => {
    setSelect(() => id);
    if (customWidth) {
      if (width > ref?.current?.offsetWidth) {
        ref.current.scrollLeft = ref.current.children[id].offsetLeft - 90;
      }
    } else {
      ref.current.scrollLeft = ref.current.children[id].offsetLeft - 90;
    }
    if (data[id]?.path) {
      navigate(data[id]?.path, { replace: true });
    }
    customHandle(data[id]?.page, data[id]?.key, data[id]);
  };

  useLayoutEffect(() => {
    let totalWidth = 0;
    Array.from(ref?.current?.children).map((val) => {
      totalWidth += val?.offsetWidth + 39;
    });

    setWidth(() => totalWidth - 61);

    if (customWidth) {
      if (totalWidth - 61 > ref?.current?.offsetWidth) {
        ref.current.scrollLeft =
          ref?.current?.children[customSelect]?.offsetLeft - 90;
      }
    } else {
      ref.current.scrollLeft =
        ref?.current?.children[customSelect]?.offsetLeft - 90;
    }
  }, [data]);
  // ** Testing Setting the state so that the underline of Tabs Work in the NewFilter Component
  // useEffect(() => {
  //   setSelect(() =>
  //     customSelect !== undefined ? (customSelect == -1 ? 0 : customSelect) : 0
  //   );
  // }, [customSelect]);

  console.log(width, ref?.current?.offsetWidth, "agagbj");

  return (
    <div
      style={{ position: "relative", width: customWidth }}
      ref={mainContainer}
    >
      {!disableArrow && width > ref?.current?.offsetWidth && (
        <div
          className="shadowOverLay"
          ref={anchorEl}
          style={{ background: theme.palette.popOverList.containerBackGround }}
        >
          {width > ref?.current?.offsetWidth ? (
            <div
              className="showMoreWrapper"
              onClick={handleOpen}
              style={{
                background: theme.palette.popOverList.containerBackGround,
              }}
            >
              <IconButton className="showMoreBtn">
                <ExpandMoreIcon
                  fontSize="small"
                  style={{ fill: theme.palette.searchIcon.iconColor }}
                />
              </IconButton>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      <div
        className="mewTabContainer"
        // style={{ width: customWidth ? "100%" : "98%" }}
        style={{ width: "100%" }}
        ref={ref}
      >
        {data2?.map((val, index) => (
          <div
            key={val?.id}
            className={`newTabItem ${
              select == val?.id ? "selectedTabItem" : ""
            }`}
            onClick={() => {
              handleClick(val?.id, data2);
            }}
          >
            <p
              className={`newTabItemTxt ${
                select == val?.id ? "selectedTxt" : ""
              }`}
            >
              {val?.title}
            </p>
          </div>
        ))}
        {!disableArrow && (
          <div key={"asfbakjb"} className={`newTabItem ${""}`}>
            <p className={`newTabItemTxt ${""}`}>&nbsp; &nbsp; </p>
          </div>
        )}
      </div>
      <hr className="newTabHrLine" />
      <Popover
        open={open}
        anchorEl={anchorEl?.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            borderRadius: "7px 0px 7px 7px",
          },
        }}
      >
        <div
          className="popoverListContainer"
          style={{
            background: theme.palette.popOverList.containerBackGround,
          }}
        >
          <div
            className="popoverList"
            style={{
              background: theme.palette.popOverList.containerBackGround,
            }}
          >
            {data2?.map((val) => (
              <div
                key={val?.id}
                className={`popoverListItem ${
                  select == val?.id ? "popoverListItemSelected" : ""
                }`}
                onClick={() => {
                  handleClick(val?.id, data2);
                }}
              >
                <p
                  className={`popoverListItemTxt ${
                    select == val?.id ? "" : ""
                  }`}
                >
                  {val?.title}
                </p>
              </div>
            ))}
          </div>
        </div>
      </Popover>
    </div>
  );
}

export default React.memo(NewTabComp);
