import React, { useContext } from "react";
import Default from "../components/dashboard/defaultPages/Default";
import FixedDepositSummary from "../components/dashboard/products/fixedDeposit/FixedDepositSummary";
import { mfoFilterContext } from "../mfo_context/Mfo_Context";

function FixedDepositSummaryPage() {
  const { setEntity } = useContext(mfoFilterContext);
  setEntity("fd");
  return (
    <Default>
      <FixedDepositSummary />
    </Default>
  );
}

export default FixedDepositSummaryPage;
