import { InputAdornment, TextField, makeStyles } from "@material-ui/core";
import { AccountCircle, Search } from "@material-ui/icons";
import React, { useCallback } from "react";
import { useDeferredValue } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useTransition } from "react";
import { myDebounce } from "../../../../hooks/constantFunction";
import { useEffect } from "react";
import SearchInput from "./SearchInput";

const useStyles = makeStyles((theme) => ({
  searchHeaderWrapper: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  pseudoSearch: {
    background: "#1F1C40",
    borderRadius: "20px",
    display: "inline",
    border: "1px solid #711CF1",
    padding: "7px 12px",
    transition: "background-color 0.5 ease-in-out",
  },
  input: {
    border: "none!important",
    backgroundColor: "transparent",
    width: "200px",
    outline: "none",
    color: "#9488A7!important",
    fontFamily: "Avenir",
  },
}));

function SearchDebouncedInput({
  setSearchName,
  search,
  customList,
  setSearchList,
  handleSelection,
}) {
  const [clear, setClear] = useState(false);

  const handleOutSideClick = () => {
    setSearchList([]);
    setSearchName("");
    setClear(true);
  };

  return (
    <>
      {customList?.length > 0 && (
        <div className="outSideClickFinder" onClick={handleOutSideClick}></div>
      )}
      <div className="listSearchWrapper">
        <div className="listSearch">
          <SearchInput
            clear={clear}
            setClear={setClear}
            setSearchName={setSearchName}
            searchVal={search}
          />
        </div>
        {customList?.length > 0 && (
          // search
          <div className="listContainer">
            {customList.map((val, i) => {
              // if (val?.scheme_name) {

              return (
                <div
                  className="searchitems"
                  style={{
                    backgroundColor: i % 2 == 0 ? "#380D77" : "#1F1C40",
                  }}
                  onClick={() => {
                    handleSelection(val);
                    handleOutSideClick();
                  }}
                >
                  {val?.scheme_name}
                </div>
              );
              // }
            })}
          </div>
        )}
      </div>
    </>
  );
}

export default SearchDebouncedInput;
