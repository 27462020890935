import { debounce } from "lodash";
import React, { useCallback, useEffect } from "react";
import { useMemo } from "react";
import { useRef } from "react";
export const useDebounce = (callback) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, 1000);
  }, []);

  return debouncedCallback;
};
