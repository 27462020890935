import { currencyFormat } from "../../../../../hooks/constantFunction";
import { roundNumber } from "../../../../utils/common";

export const generateTableColumnsForEngagementModel = (engagementData) => {
  return [
    [
      {
        field: "Held",
        title: "Asset Class",
        show: true,
      },
      {
        // field: "₹41.27 Cr",
        field: currencyFormat(engagementData?.heldInvestment) || "-",
        title: "Invested Value",
        show: true,
      },
      {
        field: currencyFormat(engagementData?.currentHeldAmount) || "-",
        title: "Current Value",
        show: true,
      },
      {
        field: roundNumber(engagementData?.heldPercentage) || "-",
        title: "% of Total Portfolio",
        show: true,
      },
      {
        field:
          currencyFormat(engagementData?.currentHeldUnrealisedGainLoss) || "-",
        title: "Unrealized Gain/Loss",
        show: true,
      },
      {
        field: currencyFormat(engagementData?.currentHeldTotalGainLoss) || "-",
        title: "Total Gain/Loss",
        show: true,
      },
      {
        field: roundNumber(engagementData?.currentHeldXirr) || "-",
        title: "XIRR (%)",
        show: true,
      },
      {
        field: roundNumber(engagementData?.currentHeldAbsoluteTwrr) || "-",
        title: "Absolute TWRR (%)",
        show: true,
      },
      {
        field: roundNumber(engagementData?.currentHeldAnnualisedTwrr) || "-",
        title: "Annualized TWRR (%)",
        show: true,
      },
    ],
    [
      {
        field: "Held Away",
        title: "Asset Class",
        show: true,
      },
      {
        field: currencyFormat(engagementData?.heldAwayInvestment) || "-",
        title: "Invested Value",
        show: true,
      },

      {
        field: currencyFormat(engagementData?.currentHeldAwayAmount) || "-",
        title: "Current Value",
        show: true,
      },
      {
        field: roundNumber(engagementData?.heldAwayPercentage) || "-",
        title: "% of Total Portfolio",
        show: true,
      },

      {
        field:
          currencyFormat(engagementData?.currentHeldAwayUnrealisedGainLoss) ||
          "-",
        title: "Unrealized Gain/Loss",
        show: true,
      },
      {
        field:
          currencyFormat(engagementData?.currentHeldAwayTotalGainLoss) || "-",
        title: "Total Gain/Loss",
        show: true,
      },
      {
        field: roundNumber(engagementData?.currentHeldAwayXirr) || "-",
        title: "XIRR (%)",
        show: true,
      },
      {
        field: roundNumber(engagementData?.currentHeldAwayAbsoluteTwrr) || "-",
        title: "Absolute TWRR (%)",
        show: true,
      },
      {
        field:
          roundNumber(engagementData?.currentHeldAwayAnnualisedTwrr) || "-",
        title: "Annualized TWRR (%)",
        show: true,
      },
    ],
    [
      {
        field: "Total",
        title: "Total",
        show: true,
      },
      {
        field: currencyFormat(engagementData?.totalCurrentInvestment) || "-",
        title: "Invested Value",
        show: true,
      },

      {
        field: currencyFormat(engagementData?.totalCurrentAmount) || "-",
        title: "Current Value",
        show: true,
      },
      {
        field: roundNumber(engagementData?.totalPercentage) || "-",
        title: "% of Total Portfolio",
        show: true,
      },

      {
        field:
          currencyFormat(engagementData?.totalCurrentUnrealisedGainLoss) || "-",
        title: "Unrealized Gain/Loss",
        show: true,
      },
      {
        field: currencyFormat(engagementData?.totalCurrentGainLoss) || "-",
        title: "Total Gain/Loss",
        show: true,
      },
      {
        field: roundNumber(engagementData?.totalCurrentXirr) || "-",
        title: "XIRR (%)",
        show: true,
      },
      {
        field: roundNumber(engagementData?.totalCurrentAbsoluteTwrr) || "-",
        title: "Absolute TWRR (%)",
        show: true,
      },
      {
        field: roundNumber(engagementData?.totalCurrentAnnualisedTwrr) || "-",
        title: "Annualized TWRR (%)",
        show: true,
      },
    ],
  ];
};
