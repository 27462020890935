import React from "react";
import TopBackComp from "./TopBackComp";
import TopNavWrapper from "./TopNavWrapper";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../routes";

function TableTopComp({
  page,
  data,
  handleClick,
  title,
  addButtonCLick,
  rawtableData,
  back,
}) {
  const navigate = useNavigate();

  return (
    <>
      <TopBackComp
        handleClick={() => {
          navigate(back || routes?.home);
        }}
        title={title ? title : "Strategy Builder"}
      />
      <TopNavWrapper
        page={page}
        handleClick={handleClick}
        data={data}
        buttonTitle={""}
        addButtonCLick={addButtonCLick}
        rawtableData={rawtableData}
      />
    </>
  );
}

export default TableTopComp;
