import React from "react";
import ImageModal from "./common/ImageModal";
import PartnerHeading from "./PartnerHeading";
import {
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  Box,
} from "@material-ui/core";
import CustomInput from "./common/CustomInput";
import { ReactComponent as Upload } from "../../assets/icons/upload.svg";
import { ReactComponent as VerifiedTick } from "../../assets/icons/verified.svg";
import ErrorMessageComp from "./common/ErrorMessageComp";
import LoadingButton from "./common/LoadingButton";
import StepProgressBar from "./common/StepProgressBar/StepProgressBar";
import seperation from "../../assets/partner-images/seperation.svg";
import { useForm } from "./hooks/useForm";
import {
  newRegisterForm2ValidationSchema,
  registerForm2ValidationSchema,
} from "./ValidationSchema";
import { useState } from "react";
import "./PartnerOnboarding.css";

function PartnerOnboardingForm2({
  currentStep,
  handleBack,
  handleNext,
  customHandleSubmit,
  formData = {},
  head,
  apiErrorMessage,
  loading,
  noBottomStep,
  formMaxHeight,
  gap,
  total,
  stepDeviation,
  partnerData,
}) {
  const [files, setFiles] = useState();
  const [filesUrl, setFilesUrl] = useState({ idProof: "", pan: "" });
  const [openModal, setOpenModal] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  const verified = {
    endAdornment: (
      <InputAdornment position="end">
        <VerifiedTick />
      </InputAdornment>
    ),
  };

  const {
    kycStatus,
    kycDate,
    address,
    entityName,
    entityType,
    panNumber,
    commencementDate,
    doi,
    dateOfBirth,
  } = formData;

  const handleFileUpload = (e, key) => {
    // console.log(e?.target?.files[0],'afkjabfahsvf');
    setFiles((prev) => ({ ...prev, [key]: e.target.files[0] }));

    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = function (e) {
      setFilesUrl((prev) => ({ ...prev, [key]: reader.result }));
    }.bind(this);
  };

  const handleViewUploadedFile = (fileType) => {
    const fileUrl = filesUrl[fileType];
    if (fileUrl) {
      setOpenModal(true);
      setImageSrc(fileUrl);
    }
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useForm(
    {
      entityName: entityName ? entityName : "",
      // entityType: entityType ? entityType : "",
      panNumber: panNumber ? panNumber : "",
      // panNumber: "FPOTY1234D",
      commencementDate: commencementDate ? commencementDate : "",
      kycDate: kycDate ? kycDate : "",
      kycStatus: kycStatus ? kycStatus : "",
      address: address ? address : "",
      // idProof: "",
      // pan: "",
      dateOfIncorporation: entityType == "sole_proprietor" ? dateOfBirth : doi,
    },
    (values) => {
      //   handle form submission
      //   values.password = Number(values.password);
      //   submitForm(values);
      //   customHandleSubmit(values);
      //   setOpen(true);
      let value2 = { ...values };
      value2["idProof"] = files?.["idProof"];
      value2["pancard"] = files?.["pan"];
      // console.log(values, value2, files, "aflkbabfbuawubfw61984689162481");
      customHandleSubmit(value2);
    },
    newRegisterForm2ValidationSchema
  );

  return (
    <>
      <Box className="dFlex jtfyContCntr alignCntr h100">
        <Box
          className="dFlex jtfyContCntr flexDirCol secondScreen"
          sx={{
            width: "730px",
            gap: gap ? gap : "41px",
            //   position: "relative",
            //   overflow: "auto",
          }}
        >
          <div className="newFormTopSec" style={{ width: "730px" }}>
            <PartnerHeading
              marginLeft={"-91px"}
              mainHead={head?.main ? head?.main : "Partner Onboarding"}
              step={currentStep}
              handleBack={handleBack}
              stepDeviation={stepDeviation}
            />
          </div>
          <div
            className="newFormContainer"
            style={{ maxHeight: formMaxHeight }}
          >
            <form onSubmit={handleSubmit}>
              <Box
              //   sx={{ borderRight: "2px dashed #3E5389", paddingRight: "5%" }}
              //   item
              >
                <Grid
                  container
                  spacing={2}
                  style={{ justifyContent: "space-between" }}
                >
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // justifyContent: "space-between",
                      maxWidth: "47%",
                      gap: "10px",
                    }}
                    item
                    xs={12}
                    sm={12}
                    md={6}
                  >
                    {partnerData?.entityType == "individual" && (
                      <>
                        <CustomInput
                          title="PARTNER NAME"
                          // as={TextField}
                          variant="outlined"
                          margin="normal"
                          // required
                          fullWidth
                          id="entityName"
                          label="Entity Name"
                          name="entityName"
                          autoComplete="email"
                          autoFocus
                          error={
                            touched.entityName && Boolean(errors.entityName)
                          }
                          helperText={touched.entityName && errors.entityName}
                          value={partnerData.entityName}
                          // disabled={true}
                        />
                        <CustomInput
                          title="PARTNER Type"
                          // as={TextField}
                          variant="outlined"
                          margin="normal"
                          // required
                          fullWidth
                          id="entityName"
                          label="Entity Name"
                          name="entityName"
                          autoComplete="email"
                          autoFocus
                          error={
                            touched.entityName && Boolean(errors.entityName)
                          }
                          helperText={touched.entityName && errors.entityName}
                          value={partnerData.entityType}
                          // disabled={true}
                        />
                      </>
                    )}
                    <CustomInput
                      title="PAN NO"
                      // as={TextField}
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      id="panNumber"
                      label="Pan Number"
                      name="panNumber"
                      error={touched.panNumber && Boolean(errors.panNumber)}
                      helperText={touched.panNumber && errors.panNumber}
                      value={values.panNumber}
                      onChange={handleChange}
                      // disabled={true}
                      InputProps={
                        formData?.kycStatus == "verified" ? verified : {}
                      }
                    />
                    <CustomInput
                      title={
                        currentStep == 5 || currentStep == 6
                          ? "Representative Name"
                          : "ENTITY NAME"
                      }
                      // as={TextField}
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      id="entityName"
                      label="Entity Name"
                      name="entityName"
                      autoComplete="email"
                      autoFocus
                      error={touched.entityName && Boolean(errors.entityName)}
                      helperText={touched.entityName && errors.entityName}
                      value={values.entityName}
                      // disabled={true}
                    />
                    <CustomInput
                      // as={TextField}
                      title={`Date of ${
                        partnerData?.entityType != "individual" &&
                        currentStep == 2
                          ? "incorporation"
                          : "Birth"
                      }`}
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      id="dateOfIncorporation"
                      label={`Date of ${
                        partnerData?.entityType == "individual"
                          ? "Birth"
                          : "incorporation"
                      }`}
                      name="dateOfIncorporation"
                      autoComplete="dateOfIncorporation"
                      autoFocus
                      // type="select"
                      error={
                        touched.dateOfIncorporation &&
                        Boolean(errors.dateOfIncorporation)
                      }
                      helperText={
                        touched.dateOfIncorporation &&
                        errors.dateOfIncorporation
                      }
                      type="date"
                      // value={new Date(values.dateOfIncorporation)}
                      value={values.dateOfIncorporation}
                      onChange={handleChange}
                      // disabled={true}
                    />

                    {entityType != "individual" && currentStep == 2 && (
                      <CustomInput
                        // as={TextField}
                        title="Commencement date"
                        variant="outlined"
                        margin="normal"
                        // required
                        // type="date"
                        fullWidth
                        id="commencementDate"
                        label="Commencement Date"
                        name="commencementDate"
                        autoComplete="commencementDate"
                        autoFocus
                        error={
                          touched.commencementDate &&
                          Boolean(errors.commencementDate)
                        }
                        helperText={
                          touched.commencementDate && errors.commencementDate
                        }
                        type="date"
                        value={values.commencementDate}
                        onChange={handleChange}
                      />
                    )}
                    <CustomInput
                      title="Upload Pan"
                      // as={TextField}
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      className="custom-file-input"
                      id="pan"
                      label="Pan Card"
                      name="pan"
                      type="file"
                      error={touched.pan && Boolean(errors.pan)}
                      helperText={touched.pan && errors.pan}
                      value={values.pan}
                      onChange={(e) => {
                        handleFileUpload(e, "pan");
                        handleChange(e);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            style={{
                              background: " #3E5389",
                              width: "25px",
                              height: "23px",
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <IconButton
                              onClick={() => {
                                document.getElementById("pan").click();
                              }}
                            >
                              <Upload />
                            </IconButton>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography
                              onClick={() => handleViewUploadedFile("pan")}
                              sx={{ color: "#8C92A0", cursor: "pointer" }}
                            >
                              View
                            </Typography>
                          </InputAdornment>
                        ),
                      }}
                      // disabled={true}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={12} md={1}> */}
                  {/* <div
                    style={{
                      // borderRight: "2px dashed #3E5389",
                      height: "100%",
                      width: "1px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  > */}
                  <img src={seperation} style={{ objectFit: "cover" }}></img>
                  <Grid
                    item
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // justifyContent: "space-between",
                      maxWidth: "47%",
                      gap: "10px",
                    }}
                    xs={12}
                    sm={12}
                    md={6}
                  >
                    {/* <Grid container>
                          <Grid lg={12} sx={{ mb: "10px" }} item> */}
                    <CustomInput
                      title="ADDRESS"
                      // as={TextField}
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      id="address"
                      label="Address"
                      name="address"
                      error={touched.address && Boolean(errors.address)}
                      helperText={touched.address && errors.address}
                      rows={4}
                      value={values.address}
                      onChange={handleChange}
                      multiline
                    />
                    {/* </Grid> */}

                    {/* <Grid lg={12} sx={{ mb: "10px" }} item> */}
                    <CustomInput
                      // as={TextField}
                      title="KYC STATUS"
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      id="kycStatus"
                      label="KYC Status"
                      name="kycStatus"
                      autoComplete="email"
                      autoFocus
                      error={touched.kycStatus && Boolean(errors.kycStatus)}
                      helperText={touched.kycStatus && errors.kycStatus}
                      value={values.kycStatus}
                      onChange={handleChange}
                      // disabled={true}
                      InputProps={
                        formData?.kycStatus == "verified" ? verified : {}
                      }
                    />
                    {/* </Grid> */}

                    {/* <Grid lg={12} sx={{ mb: "10px" }} item> */}
                    <CustomInput
                      title="KYC DATE"
                      // as={TextField}
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      id="kycDate"
                      label="KYC Date"
                      name="kycDate"
                      error={touched.kycDate && Boolean(errors.kycDate)}
                      helperText={touched.kycDate && errors.kycDate}
                      // defaultValue={values.kycDate}
                      value={values.kycDate}
                      onChange={handleChange}
                      type="date"
                      // disabled={true}
                    />
                    <CustomInput
                      title="Upload Aadhar"
                      // as={TextField}
                      variant="outlined"
                      margin="normal"
                      // required
                      className="custom-file-input"
                      fullWidth
                      id="idProof"
                      label="Id Proof"
                      name="idProof"
                      type="file"
                      error={touched.idProof && Boolean(errors.idProof)}
                      helperText={touched.idProof && errors.idProof}
                      value={values?.idProof}
                      // onChange={handleChange}
                      onChange={(e) => {
                        handleFileUpload(e, "idProof");
                        handleChange(e);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            style={{
                              background: " #3E5389",
                              width: "25px",
                              height: "23px",
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <IconButton
                              onClick={() => {
                                document.getElementById("idProof").click();
                              }}
                            >
                              <Upload />
                            </IconButton>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography
                              sx={{ color: "#8C92A0", cursor: "pointer" }}
                              onClick={() => handleViewUploadedFile("idProof")}
                            >
                              View
                            </Typography>
                          </InputAdornment>
                        ),
                      }}
                      // disabled={true}
                    />

                    {/* </Grid> */}
                    {/* </Grid> */}
                  </Grid>
                </Grid>

                {/* </Formik> */}
              </Box>

              <Box
                sx={{
                  position: "sticky",
                  bottom: "0px",
                  width: "100%",
                  backgroundColor: "black",
                  paddingTop: "4px",
                }}
              >
                <ErrorMessageComp errorMessage={apiErrorMessage} />

                <LoadingButton
                  buttonTitle={"Continue"}
                  type="submit"
                  showLoader={loading}
                />
                {noBottomStep ? (
                  ""
                ) : (
                  <Box mt={3.5}>
                    <StepProgressBar total={total} currentStep={currentStep} />
                  </Box>
                )}
              </Box>
            </form>
          </div>
        </Box>
      </Box>
      <ImageModal
        openModal={openModal}
        imageSrc={imageSrc}
        setOpenModal={setOpenModal}
      />
    </>
  );
}

export default PartnerOnboardingForm2;
