import React from "react";
import { Box } from "@material-ui/core";
import "./StrategyBuilder.css";

export const SelectedIndividual = ({ selectedMember, svgIcon }) => {
  console.log("SelectedIndividual", selectedMember);
  return (
    <>
      {selectedMember == null && (
        <p className="strategyBuilderSelectText">
          + Select the members for the consolidated portfolio view.
        </p>
      )}
      <Box className="selectedMembersListWrapper">
        {selectedMember && (
          <Box className="selectedMembersList">
            {svgIcon}
            <p className="strategyBuilderMemberName">{selectedMember?.name}</p>
          </Box>
        )}
      </Box>
    </>
  );
};
