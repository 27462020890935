export const stdTableData = [
  {
    reportTYpe: "Direct Equity - Holding Summary",
    memberName: "All-Members",
    duration: "12/02/2008 - 12/02/2018",
    requestTime: "11:23 PM",
    status: "loading",
    estimatedTime: "4 - 5 Mins",
  },
  {
    reportTYpe: "Direct Equity - Holding Summary",
    memberName: "All-Members",
    duration: "12/02/2008 - 12/02/2018",
    requestTime: "11:23 PM",
    status: "loading",
    estimatedTime: "4 - 5 Mins",
  },
  {
    reportTYpe: "Direct Equity - Holding Summary",
    memberName: "All-Members",
    duration: "12/02/2008 - 12/02/2018",
    requestTime: "11:23 PM",
    status: "Completed",
    estimatedTime: "4 - 5 Mins",
  },
];

export const tableReportDownloadConfig = [
  {
    title: "REPORT TYPE",
    field: "report_type",
    color2: "#711CF1",
    dontShowFirstColBorder: true,
    customCellClassName: "reportCell1",
  },
  {
    title: "DETAILS",
    field: "details",
    fontWeight: "500",
    customFontClassName: "fontReport2",
  },
  {
    title: "REQUEST TIME",
    field: "created_at",
  },
  {
    title: "STATUS",
    field: "",
    customCellClassName: "reportCell4",
    customFontClassName: "reportCell4",
  },
];
