import React from "react";
import "../dashboard/products/holding_summary/topPerformer.css";

function NewHoldingButtons({ btnArray, handleChange, selected, tabswitch }) {
  return (
    <div className="buttonSecWrapper" style={{ marginTop: "20px" }}>
      {btnArray?.map((btn, index) => (
        <button
          name={btn.page}
          onClick={() => handleChange(btn.page, btn.page, btn)}
          className={`topPerformerBtn ${
            ((btn.page === selected?.asset && tabswitch != "products") ||
              (btn.page === selected?.product && tabswitch == "products") ||
              btn.page === selected) &&
            "btnActive"
          }`}
        >
          <p className="btnFont">{btn?.title}</p>
        </button>
      ))}
    </div>
  );
}

export default NewHoldingButtons;
