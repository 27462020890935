import React, { useEffect, useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { OnboardingContext } from "../../Context";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function CustomizedSnackbars({
  customSnackBarPosition,
  customAlertColor,
}) {
  const {
    userState: { appSnackbarData },
    dispatch,
  } = OnboardingContext();

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatch({
      type: "UPDATE_SNACKBAR_DATA",
      payload: {
        success: false,
        error: false,
        showMsg: false,
        message: "",
      },
    });
  };

  useEffect(() => {
    if (appSnackbarData.showMsg) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [appSnackbarData]);

  return (
    <div className={classes.root}>
      {open && (
        <Snackbar
          anchorOrigin={customSnackBarPosition}
          open={open}
          autoHideDuration={appSnackbarData?.warning ? 10000 : 5000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={
              appSnackbarData?.success
                ? "success"
                : appSnackbarData?.warning
                ? "warning"
                : "error"
            }
            color={customAlertColor}
          >
            {appSnackbarData?.message}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
