import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import { Add, Remove } from "@material-ui/icons";
import { currencyFormat } from "../../../../hooks/constantFunction";
import CashflowTablePrefix from "./CashflowTablePrefix";

function CashflowTable({ topHeader, header, inflows, outflows, footer }) {
  // Generate top headers
  const generateHeaders = (head, topHeader) => {
    if (topHeader) {
      return head.map((h, i) => (
        <TableCell
          className="top-header"
          style={{ justifyContent: i != 0 ? "flex-end" : "" }}
        >
          <div
            style={{
              justifyContent: i != 0 ? "flex-end" : "",
              display: "flex",
            }}
          >
            {h == "Total" ? "FY" : h}
          </div>

          {/* {h == "total" ? "" : h && "*"} */}
        </TableCell>
      ));
    }
    return head.map((h, i) => (
      <TableCell className="header">
        {" "}
        <div
          className="p_flex a_cen"
          style={{ justifyContent: i != 0 ? "flex-end" : "" }}
        >
          {renderValue(h)}
          {h == "Opening Balance" ? (
            <div className="expTag" style={{ marginLeft: "10px" }}>
              A
            </div>
          ) : (
            ""
          )}
        </div>
      </TableCell>
    ));
  };

  // Generate sub headers (inflows, outflows)
  const generateSubHeader = (head, expression) => {
    return (
      <TableRow>
        <TableCell className="subheader flow" colSpan={topHeader.length + 1}>
          <div className="button23Container">
            {" "}
            {head} {expression}{" "}
          </div>
        </TableCell>
      </TableRow>
    );
  };
  const generateRows = (data, isInflow) => {
    const iconStyles = {
      color: "#7F89A7",
      width: "1rem",
    };
    return data.map((row, rowIdx) => (
      <TableRow className={row[0] === "Closing Balance" ? "dark" : ""}>
        {row.map((cell, index) => (
          <TableCell
            className="rows"
            style={{ position: "relative", background: {} }}
          >
            <div
              style={{
                justifyContent: index != 0 ? "flex-end" : "",
                display: "flex",
              }}
            >
              {index === 0 && cell != "Closing Balance" && (
                <>
                  <CashflowTablePrefix
                    showBottom={data.length !== rowIdx + 1}
                  />
                  <IconButton
                    style={{
                      backgroundColor: "black",
                      width: "1.4rem",
                      height: "1.4rem",
                      marginLeft: "1rem",
                    }}
                  >
                    {isInflow ? (
                      <Add style={iconStyles} />
                    ) : (
                      <Remove style={iconStyles} />
                    )}
                  </IconButton>
                </>
              )}
              <span
                className={cell == "Closing Balance" ? "subheader mw-147" : ""}
              >
                {renderValue(cell) || "-"}
              </span>
              {cell == "Closing Balance" ? (
                <div className="expTag" style={{ marginLeft: "-5px" }}>
                  D
                </div>
              ) : (
                ""
              )}
            </div>
          </TableCell>
        ))}
      </TableRow>
    ));
  };

  // Formatting values to currency denomination if they are number
  const renderValue = (value) => {
    if (typeof value == "number") return currencyFormat(value);
    return value;
  };
  return (
    <TableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead className="tableRowStyle tableSticky">
          <TableRow>{generateHeaders(["", ...topHeader], true)}</TableRow>
          <TableRow>{generateHeaders(["Opening Balance", ...header])}</TableRow>
        </TableHead>
        <TableBody className="tableBodyPms tableSticky cashflowTable">
          {inflows?.length && inflows.length && (
            <>
              {generateSubHeader(
                "Cash Inflows",
                <div className="expTag">B</div>
              )}
              {generateRows(inflows, true)}
            </>
          )}
          {outflows?.length && outflows.length && (
            <>
              {generateSubHeader(
                "Cash Outflows",
                <div className="expTag">C</div>
              )}
              {generateRows(outflows)}
            </>
          )}
          {footer && (
            <TableRow className="footer">
              <TableCell sx={{ borderLeft: "7px solid #61199c !important" }}>
                <div className="button23Container borderless">
                  Net <div className="expTag longexp">( D+B-C-A)</div>
                </div>
              </TableCell>
              {footer.map((amount, i) => (
                <TableCell>
                  <div
                    style={{
                      justifyContent: "flex-end",
                      display: "flex",
                    }}
                  >
                    {renderValue(amount) || "-"}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CashflowTable;
