import React from "react";
import Container_wrapper from "./Container_wrapper";

import { Dialog, Modal } from "@material-ui/core";
import useStyles from "../../user_management/UserManagementStyles";
export default function C_Modal({
  openModal,
  children,
  closemodal,
  containerstyle,
  modalContainer = {},
  onBackdropClick = true,
}) {
  const classes = useStyles();
  return (
    <Dialog
      open={openModal}
      // BackdropProps={{
      //   classes: {
      //     root: classes.backDrop,
      //   },
      // }}
      // aria-labelledby="modal-modal-title"
      // aria-describedby="modal-modal-description"
      // BackdropComponent={{clo}}
      onBackdropClick={onBackdropClick}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
          maxHeight: "calc(100% - 80px)",
        },
      }}
      onClose={() => closemodal(false)}
    >
      <div
        style={{
          width: "548px",
          height: "590px",
          minHeight: "453px",
          backgroundColor: "black",
          borderRadius: "20px",
          overflow: "hidden",
          ...modalContainer,
        }}
      >
        <Container_wrapper containerstyle={containerstyle} close={closemodal}>
          <div style={{ width: "100%" }}>{children}</div>
        </Container_wrapper>
      </div>
      {/* <div
        className={`${classes.modalContainer} form-container`}
        style={{
          width: "548px",
          height: "590px",
          overflow: "initial",
          top: "5.5vh",
          border: "none",
          backgroundColor: "transparent",
        }}
      >
     
      </div> */}
    </Dialog>
  );
}
