import { useState, useEffect, useContext } from "react";
import { OnboardingContext } from "../Context";
import { fetchHoldingPeriod } from "../api";
import { mfoFilterContext } from "../mfo_context/Mfo_Context";
import moment from "moment";

export const useHoldingPeriod = (isinNo, advisor, assetClass, type) => {
  const { userState } = OnboardingContext();
  const { mfoFilter } = useContext(mfoFilterContext);
  const [holdingPeriod, setHoldingPeriod] = useState();
  const [loading, setLoading] = useState(true);
  let clientId = userState.loginedUserData.id;

  useEffect(() => {
    let params = { isinNo, advisor, assetClass };
    if (mfoFilter.startDate) {
      params["startDate"] = moment(mfoFilter.startDate).format("YYYY-MM-DD");
      params["endDate"] = moment(mfoFilter.endDate).format("YYYY-MM-DD");
    }
    if (Object.keys(mfoFilter.checkData).length > 0) {
      params["memberId"] = Object.keys(mfoFilter.checkData).join(",");
    }

    /* Holding Period API CALL*/
    setLoading(true);
    fetchHoldingPeriod(clientId, type, params)
      .then((response) => {
        const apiData = response?.data?.data?.result;
        console.log("ipoData", apiData);
        setHoldingPeriod(apiData);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(`An Error ${err} occurred`);
      });
  }, [mfoFilter, isinNo, advisor, assetClass, type]);
  return [holdingPeriod, loading];
};
