import axios from "axios";
import {
  API_URL,
  CLIENT_CODE,
  USER_MODULE_CLIENT_CODE,
  API_MF_REPORTS,
  equity_stock_master_api,
  user_role_management_api,
  API_URL_V2,
  CVL_KRA_TOKEN,
} from "../Config";
import Swal from "sweetalert2";
import ls from "localstorage-slim";
ls.config.encrypt = true;

const hideAlert = () => {
  setTimeout(() => {
    const customDiv = document.getElementById("customAlert");
    customDiv.style.display = "none";
  }, 2000);
};

function setHeaders(req, client) {
  const loggedUser = ls.get("logged-entity", { secret: 50 });
  const Token = loggedUser?.token;
  // const Token = "ory_st_lLc4U2m3D4QzHrjPdF7480kWD0f9dyVN";
  if (Token) {
    req.headers.Authorization = `Bearer ${Token}`;
  }

  req.headers.client_code = `${client}`;
  // req.headers.responseType = "blob";
  return req;
}

function setMasterStaticHeaders(req, client) {
  req.headers.Authorization = CVL_KRA_TOKEN;
  req.headers.client_code = `${client}`;
  return req;
}
function setDefaultHeaders(req, client) {
  const loggedUser = ls.get("logged-entity", { secret: 50 });
  const Token = loggedUser?.token;
  // const Token = "ory_st_lLc4U2m3D4QzHrjPdF7480kWD0f9dyVN";
  if (Token) {
    req.headers.Authorization = `Bearer 7093ecfdc133d238b1f28d99875369cf`;
  }

  req.headers.client_code = `${client}`;
  return req;
}

function SetStockHeader(req, client) {
  req.headers.client_code = `${client}`;
  return req;
}
const API = axios.create({
  baseURL: API_URL,
});
const APIV2 = axios.create({
  baseURL: API_URL_V2,
});

const LOGIN = axios.create({
  baseURL: API_URL,
});

const MASTERAPISTATICTOKEN = axios.create({
  baseURL: API_URL,
});

const ReportsApi = axios.create({
  baseURL: API_MF_REPORTS,
});
const TaxReportsApi = axios.create({
  baseURL: API_MF_REPORTS,
});
const PrintReportsApi = axios.create({
  baseURL: API_MF_REPORTS,
});
const PrintReportsBackEndApi = axios.create({
  baseURL: API_MF_REPORTS,
});

const Stock_masterApi = axios.create({
  baseURL: equity_stock_master_api,
});
const userRoleManagementApi = axios.create({
  baseURL: user_role_management_api,
});

MASTERAPISTATICTOKEN.interceptors.request.use((req) =>
  setMasterStaticHeaders(req, CLIENT_CODE)
);
API.interceptors.request.use((req) => setHeaders(req, CLIENT_CODE));
APIV2.interceptors.request.use((req) => setHeaders(req, CLIENT_CODE));
LOGIN.interceptors.request.use((req) => setHeaders(req, CLIENT_CODE));
ReportsApi.interceptors.request.use((req) => setHeaders(req, "mfo-reporting"));
ReportsApi.interceptors.request.use(
  function (config) {
    if (
      localStorage?.getItem("userType") === "mfo-user" ||
      localStorage?.getItem("userType") === "partner"
    ) {
      if (!config.params) {
        return config;
      }

      config.params["clientId"] = localStorage?.getItem("memberId");
      return config;
    } else {
      return config;
    }
  },
  function (error) {
    return Promise.reject(error);
  }
);
TaxReportsApi.interceptors.request.use((req) =>
  setHeaders(req, "mfo-reporting")
);
TaxReportsApi.interceptors.request.use(
  function (config) {
    if (
      localStorage?.getItem("userType") === "mfo-user" ||
      localStorage?.getItem("userType") === "partner"
    ) {
      if (!config.params) {
        return config;
      }

      // config.params["clientId"] = localStorage?.getItem("memberId");
      return config;
    } else {
      return config;
    }
  },
  function (error) {
    return Promise.reject(error);
  }
);
PrintReportsApi.interceptors.request.use((req) =>
  setHeaders(req, "mfo-reporting")
);
PrintReportsBackEndApi.interceptors.request.use((req) =>
  setDefaultHeaders(req, "mfo-reporting")
);
PrintReportsApi.interceptors.request.use(
  function (config) {
    if (
      localStorage?.getItem("userType") === "mfo-user" ||
      localStorage?.getItem("userType") === "partner"
    ) {
      if (!config.params) {
        return config;
      }

      return config;
    } else {
      return config;
    }
  },
  function (error) {
    return Promise.reject(error);
  }
);
Stock_masterApi.interceptors.request.use((req) =>
  SetStockHeader(req, "ex_aggregator")
);
userRoleManagementApi.interceptors.request.use((req) =>
  setHeaders(req, USER_MODULE_CLIENT_CODE)
);
LOGIN.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // if (error.response.status === 401) {
    //   sessionStorage.setItem("redirect", window.location.href);
    //   let text = error.response.data.error.message.replace("Bad request:", "");

    //   localStorage.clear();
    //   sessionStorage.clear();
    //   Swal.fire({
    //     title: "Unauthorized",
    //     text: text,
    //     icon: "error",
    //     confirmButtonText: "OK",
    //   }).then(() => {
    //     window.location = "/";
    //   });
    // }
    if (error.response.status === 503) {
      const customDiv = document.getElementById("customAlert");
      customDiv.style.display = "block";
      customDiv.innerHTML = `<div class="alertText">
        <span style="margin-right:20px"> <svg height="32" style="overflow:visible;enable-background:new 0 0 32 32" viewBox="0 0 32 32" width="32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><g id="Error_1_"><g id="Error"><circle cx="16" cy="16" id="BG" r="16" style="fill:#D72828;"/><path d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z" id="Exclamatory_x5F_Sign" style="fill:#E6E6E6;"/></g></g></g></svg></span>
                                  Something Went Wrong...
                               </div>`;
      hideAlert();
    }
    if (error.response) return Promise.reject(error);
  }
);
PrintReportsApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      sessionStorage.setItem("redirect", window.location.href);

      localStorage.clear();
      sessionStorage.clear();
      Swal.fire({
        title: "Unauthorized",
        text: "Session token has Expired",
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        window.location = "/";
      });
    }
    if (error.response.status === 503) {
      const customDiv = document.getElementById("customAlert");
      customDiv.style.display = "block";
      customDiv.innerHTML = `<div class="alertText">
        <span style="margin-right:20px"> <svg height="32" style="overflow:visible;enable-background:new 0 0 32 32" viewBox="0 0 32 32" width="32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><g id="Error_1_"><g id="Error"><circle cx="16" cy="16" id="BG" r="16" style="fill:#D72828;"/><path d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z" id="Exclamatory_x5F_Sign" style="fill:#E6E6E6;"/></g></g></g></svg></span>
                                  Something Went Wrong...
                               </div>`;
      hideAlert();
    }
    if (error.response) return Promise.reject(error);
  }
);
API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      sessionStorage.setItem("redirect", window.location.href);

      localStorage.clear();
      sessionStorage.clear();
      Swal.fire({
        title: "Unauthorized",
        text: "Session token has Expired",
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        window.location = "/";
      });
    }
    if (error.response.status === 503) {
      const customDiv = document.getElementById("customAlert");
      customDiv.style.display = "block";
      customDiv.innerHTML = `<div class="alertText">
        <span style="margin-right:20px"> <svg height="32" style="overflow:visible;enable-background:new 0 0 32 32" viewBox="0 0 32 32" width="32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><g id="Error_1_"><g id="Error"><circle cx="16" cy="16" id="BG" r="16" style="fill:#D72828;"/><path d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z" id="Exclamatory_x5F_Sign" style="fill:#E6E6E6;"/></g></g></g></svg></span>
                                  Something Went Wrong...
                               </div>`;
      hideAlert();
    }
    if (error.response) return Promise.reject(error);
  }
);
ReportsApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      sessionStorage.setItem("redirect", window.location.href);

      localStorage.clear();
      sessionStorage.clear();
      Swal.fire({
        title: "Unauthorized",
        text: "Session token has Expired",
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        window.location = "/";
      });
    }
    if (error.response.status === 503) {
      const customDiv = document.getElementById("customAlert");
      customDiv.style.display = "block";
      customDiv.innerHTML = `<div class="alertText">
        <span style="margin-right:20px"> <svg height="32" style="overflow:visible;enable-background:new 0 0 32 32" viewBox="0 0 32 32" width="32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><g id="Error_1_"><g id="Error"><circle cx="16" cy="16" id="BG" r="16" style="fill:#D72828;"/><path d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z" id="Exclamatory_x5F_Sign" style="fill:#E6E6E6;"/></g></g></g></svg></span>
                                  Something Went Wrong...
                               </div>`;
      hideAlert();
    }
    return Promise.reject(error);
  }
);
TaxReportsApi?.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      sessionStorage.setItem("redirect", window.location.href);

      localStorage.clear();
      sessionStorage.clear();
      Swal.fire({
        title: "Unauthorized",
        text: "Session token has Expired",
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        window.location = "/";
      });
    }
    if (error.response.status === 503) {
      const customDiv = document.getElementById("customAlert");
      customDiv.style.display = "block";
      customDiv.innerHTML = `<div class="alertText">
        <span style="margin-right:20px"> <svg height="32" style="overflow:visible;enable-background:new 0 0 32 32" viewBox="0 0 32 32" width="32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><g id="Error_1_"><g id="Error"><circle cx="16" cy="16" id="BG" r="16" style="fill:#D72828;"/><path d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z" id="Exclamatory_x5F_Sign" style="fill:#E6E6E6;"/></g></g></g></svg></span>
                                  Something Went Wrong...
                               </div>`;
      hideAlert();
    }
    return Promise.reject(error);
  }
);

Stock_masterApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      sessionStorage.setItem("redirect", window.location.href);
      sessionStorage.clear();
      localStorage.clear();
      Swal.fire({
        title: "Unauthorized",
        text: "Session token has Expired",
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        window.location = "/";
      });
    }
    if (error.response.status === 503) {
      const customDiv = document.getElementById("customAlert");
      customDiv.style.display = "block";
      customDiv.innerHTML = `<div class="alertText">
        <span style="margin-right:20px"> <svg height="32" style="overflow:visible;enable-background:new 0 0 32 32" viewBox="0 0 32 32" width="32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><g id="Error_1_"><g id="Error"><circle cx="16" cy="16" id="BG" r="16" style="fill:#D72828;"/><path d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z" id="Exclamatory_x5F_Sign" style="fill:#E6E6E6;"/></g></g></g></svg></span>
                                  Something Went Wrong...
                               </div>`;
      hideAlert();
    }
    return Promise.reject(error);
  }
);
//forgot password and create pin
export const forgotPinSend = (data, userType) =>
  API.post(`/${userType}/forgot/pin`, data);
export const createPin = (data, userType) => API.post(`/${userType}/pin`, data);

//login for user
export const userLogin = (data, userType) =>
  LOGIN.post(`/${userType}/login`, data);

// fetch lob master
export const fetchLobMaster = () => {
  return MASTERAPISTATICTOKEN.get(`/lob`);
};

// fetch sub lob master
export const fetchSubLobMaster = (lobId) => {
  return MASTERAPISTATICTOKEN.get(`/lob/${lobId}/sub-lob`);
};

// Update User Theme
export const updateUserTheme = (body) => {
  return API.patch(`/user/theme`, body);
};
//client onbaording
export const createClient = (data) => API.post(`/client`, data);
export const createClientMember = (data, clientId) =>
  API.post(`/client/${clientId}/member`, data);
export const createLiasonOfficer = (data, clientId) =>
  API.post(`/client/${clientId}/liaison-officer`, data);
export const verifyOTPLiasonOfficer = (data, comType) =>
  API.put(`/client-user/${comType}/verify`, data);
export const sendOTP = (data, comType, userType) =>
  API.post(`/${userType}/${comType}/send-link`, data);
export const reSendOTP = (data, comType, userType) =>
  API.post(`/${userType}/${comType}/otp`, data);

//send Welcome mail
export const sendWelcomeMail = (clientId, filters) => {
  return API.get(`/email/welcome/client/${clientId}`, { params: filters });
};

//download
export const download_scheme = (clientId, filters) => {
  return API.get(`/scheme-master/download`, {
    params: filters,
    responseType: "blob",
  });
};
//download
export const download_cml = (clientId, filters) => {
  return API.get(`/client/download/cml`, {
    params: filters,
    responseType: "blob",
  });
};
//fetch all clients
export const fetchAllClients = (clientId, filters) => {
  return API.get(`client/${clientId}/member`, { params: filters });
};
export const fetchClientByMultiId = (filters) => {
  return API.get(`all-client/members`, { params: filters });
};

// create partner user
export const createPartneruser = (clientId, data) => {
  return API.post(`partner/${clientId}/user`, data);
};
export const fetchPartneruser = (clientId, userid) => {
  return API.get(`partner/${clientId}/user/${userid}`);
};

export const fetchClientByPartner = (partnerId, params) => {
  return API.get(`partner/${partnerId}/client`, {
    params: params || {},
  });
};
export const fetchMembersWiseHierarchy = (clientId, memberId, params) => {
  return API.get(`hierarchy/${clientId}/member/${memberId}`, {
    params: params || {},
  });
};

export const fetchOther_or_Memberuser = (clientId, usertype, params) => {
  return API.get(`client/${clientId}/${usertype || "users"}`, {
    params: params,
  });
};
// export const fetchMembersClient = (clientId, userid) => {
//   return API.get(`client/${clientId}/users`);
// }
// export const fetchPartneruser = (clientId, userid) => {
//   return API.get(`partner/${clientId}/user/${userid}`);
// };
export const updatepartneruser = (type, id, data) => {
  return API.put(`details/${type}/${id}`, data);
};

export const updatePartnerRep = (clientId, userid, body) => {
  return API.patch(`partner/${clientId}/user/${userid}`, body);
};
export const fetchRepresetOption = (clientId, params) => {
  return API.get(`partner/${clientId}/representative`, { params: params });
};

export const fetchRepresetOptionAdmin = (entityType, params) => {
  return API.get(`partner/${entityType}`, { params: params });
};

// fetch all admin user managment
export const fetchAdminMfoUsers = (params) => {
  return API.get(`mfo-user`, { params: params });
};

export const fetchMfoUserMappingById = (clientId, params) => {
  return API.get(`mfo-user/${clientId}`, { params: params });
};

export const createAdminUser = (data) => {
  return API.post(`mfo-user`, data);
};

export const updateAdminUser = (id, data) => {
  return API.put(`mfo-user/${id}`, data);
};

// aif
export const invested_portfolio = (clientId, filters, type) => {
  let params = {
    clientId,
    ...filters,
  };

  return ReportsApi.get(`${type ? type : "aif"}/portfolio-invested`, {
    params: params,
  }).then((res) => {
    return res.data.data;
  });
};

export const fetchTaxRecords = (clientId, filters, type) => {
  let params = {
    clientId: clientId,
    ...filters,
  };

  return TaxReportsApi.get(`${type ? type : "equity"}/taxation/records`, {
    params: params,
  }).then((res) => {
    return res.data.data;
  });
};
export const fetchTaxSummary = (clientId, filters, type) => {
  let params = {
    clientId: clientId,
    ...filters,
  };

  return TaxReportsApi.get(`${type ? type : "equity"}/taxation/summary`, {
    params: params,
  }).then((res) => {
    return res.data.data;
  });
};
export const fetchAssetWiseProductTax = (clientId, filters, type) => {
  let params = {
    clientId,
    ...filters,
  };

  return ReportsApi.get(`taxation/asset-wise-products`, {
    params: params,
  }).then((res) => {
    return res.data.data;
  });
};

//fetch mf summary
export const fetchXirr = (clientId, filters, type) => {
  let params = {
    clientId,
    ...filters,
  };

  return ReportsApi.get(`${type ? type : "mf"}/xirr`, { params: params }).then(
    (res) => {
      return res.data.data;
    }
  );
};
export const fetchXirrCurrentHolding = (clientId, filters, type) => {
  let params = {
    clientId,
    ...filters,
  };

  return ReportsApi.get(`${type ? type : "mf"}/current-holding-xirr`, {
    params: params,
  }).then((res) => {
    return res.data.data;
  });
};

export const fetchAdvisor = (clientId, param, type) => {
  let params = {
    clientId,
    ...param,
  };

  return ReportsApi.get(`${type ? type : "mf"}/advisor`, {
    params: params,
  }).then((res) => {
    return res.data.data;
  });
};
export const fetchITD = (clientId, param, type) => {
  let params = {
    clientId,
    ...param,
  };

  return ReportsApi.get(`/dashboard/itd-filter-start-date`, {
    params: params,
  }).then((res) => {
    return res.data.data;
  });
};
export const fetchBankSummary = (clientId, param) => {
  let params = {
    clientId,
    ...param,
  };

  return ReportsApi.get(`/bank/summary`, {
    params: params,
  }).then((res) => {
    return res.data.data.result;
  });
};
export const fetchExecutor = (clientId, param, type) => {
  let params = {
    clientId,
    ...param,
  };

  return ReportsApi.get(`${type ? type : "mf"}/executed-by`, {
    params: params,
  }).then((res) => {
    return res.data.data;
  });
};

export const fetchTwrr = (clientId, filters, type) => {
  let params = {
    clientId,
    ...filters,
  };

  return ReportsApi.get(`${type ? type : "mf"}/twrr`, { params: params }).then(
    (res) => {
      return res.data.data;
    }
  );
};
// Current Holding Twrr
export const fetchTwrrCurrentHolding = (clientId, filters, type, param) => {
  let params = {
    clientId,
    ...filters,
    ...param,
  };

  return ReportsApi.get(`${type ? type : "mf"}/current-holding-twrr`, {
    params: params,
  }).then((res) => {
    return res.data.data;
  });
};

/* COVERAGE SUMMARY API */
export const fetchCoverageSummary = (clientId, filters, type) => {
  let params = {
    clientId,
    ...filters,
  };
  return ReportsApi.get(`/${type}/coverage-summary`, {
    params: params,
  });
};
/* Equity Stock Detail */
export const fetchEquityStockDetail = (isin) => {
  let params = {
    isin,
  };
  return ReportsApi.get(`/equity/fund/:isin/detail`, {
    params: params,
  });
};
export const fetchSectorAllocation = (clientId, param) => {
  let params = { clientId, ...param };
  return ReportsApi.get(`/equity/sector-allocation`, {
    params: params,
  });
};

export const fetchEquitySectorAllocationGainLoss = (clientId, param) => {
  let params = { clientId, ...param };
  return ReportsApi.get(`/equity/sector-allocation-current-gain-loss`, {
    params: params,
  });
};
export const fetchEquityMarketCap = (clientId, param) => {
  let params = { clientId, ...param };
  return ReportsApi.get(`/equity/market-capitalization-current-gain-loss`, {
    params: params,
  });
};
export const fetchAssetDetailView = (clientId, param) => {
  let params = { clientId, ...param };
  return ReportsApi.get(`dashboard/asset-details`, {
    params: params,
  });
};

export const fetchProductSummary = (clientId, assetClass, param) => {
  let params = { clientId, assetClass, ...param };
  return ReportsApi.get(`/dashboard/product-summary`, { params: params });
};

/* IPO Details */
export const fetchIpoDetails = (type) => {
  let params = { type };
  return ReportsApi.get(`/equity/ipo`, { params: params });
};
/* Asset Details View BENCHMARK XIRR */
export const fetchAssetDetailsViewXIRR = (
  clientId,
  assetClass,
  holdingType
) => {
  let params = { clientId, ...assetClass, holdingType };
  return ReportsApi.get(`/dashboard/benchmark/asset-details-xirr`, {
    params: params,
  });
};

/* Product Details View BENCHMARK XIRR */
export const fetchProductDetailsViewXIRR = (
  clientId,
  assetClass,
  holdingType
) => {
  let params = { clientId, ...assetClass, holdingType };
  return ReportsApi.get(`/dashboard/benchmark/product-summary-xirr`, {
    params: params,
  });
};

/* Asset Detail View TWRR */

export const benchMarkXIRR = (clientId, assetClass, holdingType, param) => {
  let params = { clientId, ...assetClass, holdingType, ...param };
  return ReportsApi.get(`/dashboard/benchmark/xirr`, { params: params });
};

export const fetchAssetDetailsViewTWRR = (
  clientId,
  assetClass,
  comparisionType,
  holdingType
) => {
  let params = { clientId, ...assetClass, comparisionType, holdingType };
  return ReportsApi.get(`/dashboard/benchmark/asset-details-twrr`, {
    params: params,
  });
};

export const fetchAssetVsBenchMarkData = (clientId, dataType) => {
  let params = { clientId, dataType };
  return ReportsApi.get(`dashboard/asset-class-vs-benchmark`, {
    params: params,
  });
};

/* IPO Transaction Summary */
export const fetchIpoTransactionSummary = (clientId, assetClass, param) => {
  let params = { clientId, assetClass, ...param };
  return ReportsApi.get(`/equity/ipo/transaction-summary`, { params: params });
};
/* Corporate details api */

export const commonAPI = (link, params) => {
  return ReportsApi.get(link, { params: params });
};

/* Holding Summary */
export const fetchHoldingSummary = (clientId, filters, type) => {
  let params = {
    clientId,
    ...filters,
  };
  return ReportsApi.get(`/${type}/summary/holding`, {
    params: params,
  });
};

/* Fetch Holding Period  (Mutual Fund & Equity)*/
export const fetchHoldingPeriod = (clientId, type, filters) => {
  let params = {
    clientId,
    ...filters,
  };
  return ReportsApi.get(`/${type}/summary/holding-period`, {
    params: params,
  });
};
/* All Members Consolidated Report */
export const fetchAllMembersConsolidated = (clientId, filters) => {
  let params = {
    clientId,
    ...filters,
  };
  return ReportsApi.get(`dashboard/all-members-consolidated`, {
    params: params,
  });
};

/* All Members Consolidated Report List */
export const fetchAllMembersConsolidatedList = (clientId, filters) => {
  let params = {
    clientId,
    ...filters,
  };
  return ReportsApi.get(`dashboard/all-members-list`, {
    params: params,
  });
};

export const mlyPortfolioTrend = (clientId, filters) => {
  let params = {
    clientId,
    ...filters,
  };

  return ReportsApi.get(`mf/monthly-porfolio-trend`, { params: params }).then(
    (res) => {
      return res.data.data;
    }
  );
};

//chart mf summary
export const getRealisedData = (clientId, filters, type, api) => {
  let params = {
    clientId,
    ...filters,
  };

  return ReportsApi.get(
    `${type ? type : "mf"}/${api ? "current-holding-" : ""}gain-loss`,
    {
      params: params,
    }
  ).then((res) => {
    return res.data.data;
  });
};

// Holding Summary Active Cards
export const getCurrentHoldingGainLoss = (
  clientId,
  filters,
  type,
  holdingType,
  param
) => {
  let params = {
    clientId,
    ...filters,
    holdingType,
    ...param,
  };

  return ReportsApi.get(`${type ? type : "mf"}/current-holding-gain-loss`, {
    params: params,
  }).then((res) => {
    return res.data.data;
  });
};

// product wise allocation
export const productWiseAllocation = (clientId, filters, type) => {
  let params = {
    clientId,
    ...filters,
  };

  return ReportsApi.get(`${type ? type : "mf"}/product-wise-allocation`, {
    params: params,
  }).then((res) => {
    return res.data.data;
  });
};

// portFolio Reporting Aging
export const portFolioREportingAging = (clientId, filters, type) => {
  let params = {
    clientId,
    ...filters,
  };

  return ReportsApi.get(`${type ? type : "mf"}/portfolio-ageing`, {
    params: params,
  }).then((res) => {
    return res.data.data;
  });
};

export const fetchTotalGainLoss = (clientId, filters, type) => {
  let params = {
    clientId,
    ...filters,
  };

  return ReportsApi.get(`${type ? type : "mf"}/total-gain-loss`, {
    params: params,
  }).then((res) => {
    return res.data.data;
  });
};
export const getAssetWiseData = (clientId, filters, type, api) => {
  let params = {
    clientId,
    ...filters,
  };

  return ReportsApi.get(
    `${type ? type : "mf"}/performance/${
      api ? "current-holding-" : ""
    }asset-wise`,
    {
      params: params,
    }
  ).then((res) => {
    return res.data.data.result;
  });
};
export const getDashboardAssetData = (clientId, filters, type) => {
  let params = {
    clientId,
    ...filters,
  };

  return ReportsApi.get(`${type ? type : "mf"}/asset-summary`, {
    params: params,
  }).then((res) => {
    return res.data.data.result;
  });
};
export const getAdvisorTableDataData = (clientId, filters, type) => {
  let params = {
    clientId,
    ...filters,
  };

  return ReportsApi.get(
    `${type ? type : "mf"}/advisor-wise-portfolio-distribution`,
    {
      params: params,
    }
  ).then((res) => {
    return res.data.data.result;
  });
};

export const getDashboardPortfolioXirrGraph = (clientId, filters, type) => {
  let params = {
    clientId,
    ...filters,
  };

  return ReportsApi.get(
    `${type ? type : "dashboard"}/portfolio-performance-xirr-graph`,
    {
      params: params,
    }
  ).then((res) => {
    return res.data.data.result;
  });
};

export const getAdvisorData = (clientId, filters, type) => {
  let params = {
    clientId,
    ...filters,
  };

  return ReportsApi.get(
    `${type ? type : "mf"}/advisor-wise-portfolio-distribution`,
    {
      params: params,
    }
  ).then((res) => {
    return res.data.data;
  });
};
export const getAmcData = (clientId, filters, type, api) => {
  // delete filters.memberId
  // delete filters.advisor
  let params = {
    clientId,
    ...filters,
  };

  return ReportsApi.get(`mf/performance/${api ? "current-holding-" : ""}amc`, {
    params: params,
  }).then((res) => {
    return res.data.data;
  });
};

export const getInvestmentTypeData = (clientId, filters, type, api) => {
  let params = {
    clientId,
    ...filters,
  };

  return ReportsApi.get(
    `mf/performance/${api ? "current-holding-" : ""}investment-type`,
    {
      params: params,
    }
  ).then((res) => {
    return res.data.data;
  });
};

export const getSegmentReport = (clientId, filters, api) => {
  let params = {
    clientId,

    ...filters,
  };

  return ReportsApi.get(
    `mf/report/${api ? "current-holding-" : ""}segment-report`,
    { params: params }
  ).then((res) => {
    return res.data.data;
  });
};
export const getSegmentReportTable = (clientId, filters, api) => {
  let params = {
    clientId,

    ...filters,
  };

  return ReportsApi.get(`mf/sub-category-current-gain-loss`, {
    params: params,
  }).then((res) => {
    return res.data.data;
  });
};
export const getSipSummary = (clientId, filters) => {
  let params = {
    clientId,
    ...filters,
  };

  return ReportsApi.get(`mf/summary/sip`, { params: params }).then((res) => {
    return res.data.data;
  });
};
//holding summary
export const getHoldingsummary = (clientId, filters) => {
  let params = {
    clientId,
    ...filters,
  };

  return ReportsApi.get(`mf/summary/holding`, { params: params }).then(
    (res) => {
      return res.data.data;
    }
  );
};
export const getNonEmptyProductList = (clientId, filters) => {
  let params = {
    clientId,
    ...filters,
  };

  return ReportsApi.get(`/dashboard/non-empty-products`, {
    params: params,
  }).then((res) => {
    return res.data.data;
  });
};
export const getUpcomingTransactions = (filters) => {
  let params = {
    ...filters,
  };

  return ReportsApi.get(`/dashboard/upcoming-transactions`, {
    params: params,
  }).then((res) => {
    return res.data.data;
  });
};
//fund performance
export const fetchFundPerformance = (clientId, filters, other) => {
  let params = {
    clientId,
    ...filters,
  };

  return ReportsApi.get(`${other.type ? other.type : "mf"}/fund-comparision`, {
    params: params,
  });
};

export const getGainLossSummary = (clientId, filters) => {
  let params = {
    clientId,
    ...filters,
  };

  return ReportsApi.get(`mf/summary/gain-loss`, { params: params }).then(
    (res) => {
      return res.data.data;
    }
  );
};
export const getPortfolioSummary = (clientId, filters) => {
  let params = {
    clientId,
    ...filters,
  };

  return ReportsApi.get(`mf/summary/portfolio`, { params: params }).then(
    (res) => {
      return res.data.data;
    }
  );
};
//partner onbaording
export const createPartner = (data) => API.post(`/partner`, data);
export const createPartnerUser = (data, partnerId) =>
  API.post(`/partner/${partnerId}/representative`, data);
export const verifyOTPPartner = (data, comType) =>
  API.put(`/partner/${comType}/verify`, data);
export const verifyOTPPartnerUser = (data, comType) =>
  API.put(`/partner-user/${comType}/verify`, data);
export const verifyOTPPartner2 = (data, comType, entityType) =>
  API.put(`/${entityType}/${comType}/verify`, data);
//fetch partner
export const fetchAllUser = (status, search, limit, offset, head) =>
  API.get(
    `/partner?status=${status}&search=${search}&limit=${limit}&offset=${offset}&orderBy=entity_name&sortBy=ASC`,
    head
  );
export const getPartnerDetail = (partnerId) => API.get(`/partner/${partnerId}`);
export const updateUserStatus = (entityType, id, status) =>
  API.put(`/${entityType}/${id}/status`, { status });

export const getPartnerUserList = (partnerId, params, listingType) =>
  API.get(`${listingType}/${partnerId}/user`, { params: params });
//sample file uplaod
export const getClientList = (status, fullName, limit = 10, offset = 0) => {
  let url = `/client`;
  if (limit) {
    url = url + `?limit=${limit}`;
  }
  if (status) {
    url = url + `&status=${status}`;
  }
  if (fullName) {
    url = url + `&full_name=${fullName}`;
  }
  if (offset) {
    url = url + `&offset=${offset}`;
  }

  url = url + `&orderBy=full_name`;
  url = url + `&sortBy=ASC`;

  return API.get(url);
};

export const getClientMemberDetail = (id, role) => {
  let url = `/client/member/${id}`;
  if (role === "liaison_officer") {
    url = url + `?isLiaisonOfficer=1`;
  }

  return API.get(url);
};

export const getClientMemberList = (
  clientId,
  fullName,
  limit = 10,
  offset = 0
) => {
  let url = `/client/${clientId}/member`;
  if (limit) {
    url = url + `?limit=${limit}`;
  }
  if (fullName) {
    url = url + `&name=${fullName}`;
  }
  if (offset) {
    url = url + `&offset=${offset}`;
  }

  return API.get(url);
};

//bulk upload
const urlEntityAndType = (entity, type) => {
  if (type !== "transaction") {
    return entity + "/" + type;
  } else {
    return entity;
  }
};

const entityHandlerWithType = (product, type) => {
  if (product == "ins-ulip") {
    if (type == "transaction") {
      product = "ins";
    }
  }
  return product;
};
export const uploadPMSFile = (
  formData,

  onUploadProgress
) => {
  // product = entityHandlerWithType(product, type);
  return ReportsApi.post(`/pms/summary/holding-upload`, formData, {
    onUploadProgress: onUploadProgress,
  });
};
export const uploadFile = (
  product,
  clientId,
  formData,
  type,
  onUploadProgress
) => {
  product = entityHandlerWithType(product, type);
  return API.post(`/${product}/client/${clientId}/upload`, formData, {
    onUploadProgress: onUploadProgress,
  });
};

export const uploadBulk = (type, formData, onUploadProgress) => {
  return APIV2.post(`/client/${type}/upload`, formData, {
    onUploadProgress: onUploadProgress,
  });
};
export const uploadBulkV2 = (type, formData, onUploadProgress) => {
  return APIV2.post(`/client/${type}/upload`, formData, {
    onUploadProgress: onUploadProgress,
  });
};
export const uploadBulkUpcoming = (type, formData, onUploadProgress) => {
  return API.post(`/${type}/upload`, formData, {
    onUploadProgress: onUploadProgress,
  });
};
export const uploadBulkUnderlyingTransaction = (
  type,
  formData,
  onUploadProgress
) => {
  return API.post(`/${type}/upload`, formData, {
    onUploadProgress: onUploadProgress,
  });
};
export const transactionFileValidationList = (type) => {
  return API.get(`/client/${type}/validation`);
};
export const transactionValidationDetails = (type, id) => {
  return API.get(`/client/${type}/validation/${id}`);
};
export const updateTransactionValidationStatus = (type, id, paylod) => {
  return API.put(`/client/${type}/validation/${id}`, paylod);
};

export const transactionFileStatus = (payload) => {
  return API.get("/client/pending-validation", { params: payload });
};
// export const uploadValuationFile = (product, clientId, formData) =>
//   API.post(`/${product}/${clientId}/upload`, formData);
export const uploadValuationFile = (
  product,
  clientId,
  formData,
  onUploadProgress
) =>
  API.post(`/${product}/${clientId}/upload`, formData, {
    onUploadProgress: onUploadProgress,
  });

export const uploadValuationFileUnitBased = (
  product,
  formData,
  onUploadProgress
) =>
  API.post(`/${product}/upload`, formData, {
    onUploadProgress: onUploadProgress,
  });

export const uploadPromotorStake = (product, formData, onUploadProgress) =>
  API.post(`/client/${product}/upload`, formData, {
    onUploadProgress: onUploadProgress,
  });

export const uploadValuationFilePayoutReduction = (
  product,
  formData,
  onUploadProgress
) =>
  API.post(`/${product}/bulkUpload`, formData, {
    onUploadProgress: onUploadProgress,
  });

export const downloadSampleFile = (product, fileType) => {
  let newType = "";
  if (fileType === "unit-based/valuation") {
    newType = "unit_based_valuation";
  } else if (fileType === "bonds-payout-reduction-details") {
    newType = "payout_reduction";
  } else {
    newType = fileType;
  }
  // product = entityHandlerWithType(product, fileType);
  return API.get(`/${product}/${newType}/sample-file`);
};
export const getALLFiles = (entity, status, search, offset, limit, type) => {
  entity = entityHandlerWithType(entity, type);
  entity = urlEntityAndType(entity, type);

  // if (type == "unit-based") {
  //   entity = entity + "/valuation";
  // }

  let url = `/${entity}/client`;
  if (limit) {
    url = url + `?limit=${limit}`;
  }
  if (search) {
    url = url + `&search=${search}`;
  }
  if (status) {
    url = url + `&status=${status}`;
  }
  if (offset) {
    url = url + `&offset=${offset}`;
  }
  return API.get(url);
};

export const updateFileStatus = (formData, entity, headId, type) => {
  entity = entityHandlerWithType(entity, type);
  entity = urlEntityAndType(entity, type);

  // if (type == "unit-based") {
  //   entity = entity + "/valuation";
  // }

  return API.put(`/${entity}/${headId}/fileStatus`, formData);
};

//fund details
export const getFundDetails = (entity, isin) =>
  ReportsApi.get(`/${entity}/fund/${isin}/detail`);

//transactionSummary
export const getTransactionsSummary = (entity, tableName, params, api) =>
  ReportsApi.get(`${entity}/${api ? api : ""}${tableName}`, { params: params });

//category-allocation
export const getCategoryAllocation = (entity, params) =>
  ReportsApi.get(`${entity}/category-allocation`, { params: params });

//Cash Flow
export const getCashFlow = (entity, params) =>
  ReportsApi.get(`${entity}/cashflow`, { params: params });

export const getCashFundLowList = (entity, params) =>
  ReportsApi.get(`${entity}/fund-list`, { params: params });

//bonds
//typeofbonds
export const fetchTypeOfBonds = (clientId, filters, type, api) => {
  let params = {
    clientId,
    ...filters,
  };

  return ReportsApi.get(
    `${type ? type : "mf"}/${api ? "current-holding-" : ""}type-summary`,
    {
      params: params,
    }
  ).then((res) => {
    return res.data.data;
  });
};

export const getMfFundPerformance = (clientId, filters, type, exp) => {
  let params = {
    clientId,
    ...filters,
  };

  return ReportsApi.get(
    `${type ? type : "mf"}/${exp || "benchmark-comparision"}`,
    {
      params: params,
    }
  ).then((res) => {
    return res.data.data;
  });
};

export const getRentPerformance = (clientId, filters, type) => {
  let params = {
    clientId,
    ...filters,
  };

  return ReportsApi.get(`${type ? type : "re"}/portfolio-rent-comparision`, {
    params: params,
  }).then((res) => {
    return res.data.data;
  });
};
//bonds creditrating
export const fetchCreditRating = (clientId, filters, type, api) => {
  let params = {
    clientId,
    ...filters,
  };

  return ReportsApi.get(
    `${type ? type : "mf"}/${api ? "current-holding-" : ""}credit-rating`,
    {
      params: params,
    }
  ).then((res) => {
    return res.data.data;
  });
};
//bonds
export const fetchMaturitySummary = (clientId, filters, type, api) => {
  let params = {
    clientId,
    ...filters,
  };

  return ReportsApi.get(
    `${type ? type : "mf"}/${api ? "current-holding-" : ""}maturity`,
    {
      params: params,
    }
  ).then((res) => {
    return res.data.data;
  });
};
export const fetchBondsDetails = (isin) => {
  return ReportsApi.get(`/bonds/${isin}/detail`).then((res) => {
    return res.data.data;
  });
};

export const getBondsDetailsDataAPI2 = (params) =>
  API.get(`/bonds-scheme-master/${params?.id}`);

//equity
//maeket capitalization
export const fetch_marketCapitalisation = (clientId, filters, type, api) => {
  let params = {
    clientId,
    ...filters,
  };

  return ReportsApi.get(
    `${type ? type : "equity"}/${
      api ? "current-holding-" : ""
    }market-capitalization`,
    {
      params: params,
    }
  ).then((res) => {
    return res.data.data;
  });
};
//equity gain loss
export const fetch_equity_gain_loss = (clientId, filters, type, api) => {
  let params = {
    clientId,
    ...filters,
  };

  return ReportsApi.get(
    `${type ? type : "equity"}/${
      api ? "current-holding-" : ""
    }gain-loss-summary`,
    {
      params: params,
    }
  ).then((res) => {
    return res.data.data;
  });
};

//family tree
export const fetch_family_hierarchy = (clientId) => {
  return API.get(`/hierarchy/${clientId}/member`).then((res) => {
    return res.data.data;
  });
};

export const addNewChildToHeirarchy = (body) =>
  API.post("/hierarchy/member", body);

export const fetch_all_client_members = (clientId, isHierarchy, status) => {
  let params = {
    isHierarchy,
    status,
  };
  return API.get(`/client/${clientId}/member`, {
    params: params,
  }).then((res) => {
    return res.data.data;
  });
};

export const getLiaisonOfficer = (clientId) => {
  return API.get(`/client/${clientId}/liaison-officer`).then((res) => {
    return res.data.data;
  });
};
//
export const fetch_all_funds = (clientId, param) => {
  let params = {
    ...param,
  };
  return API.get(`/mf-scheme-master`, {
    params: params,
  }).then((res) => {
    return res.data.data;
  });
};
export const fetch_all_fund_bond = (clientId, param) => {
  let params = {
    ...param,
  };
  return API.get(`/bonds-scheme-master`, {
    params: params,
  }).then((res) => {
    return res.data.data;
  });
};
export const get_from_stockmaster = (clientId, param, mastercode) => {
  let params = {
    ...param,
  };
  return Stock_masterApi.get(`/master/` + mastercode, {
    params: params,
  }).then((res) => {
    return res.data.data;
  });
};
export const get_index_performance = (clientId, param, mastercode) => {
  let params = {
    ...param,
  };
  return Stock_masterApi.get(`/indexPerformance/index-list`, {
    params: params,
  }).then((res) => {
    return res.data.data;
  });
};
export const get_index_subclass = (param, mastercode) => {
  let params = {
    ...param,
  };
  return Stock_masterApi.get(`/indexPerformance/sub-assetClass-list`, {
    params: params,
  }).then((res) => {
    return res.data.data;
  });
};

export const fetchUserRolePermissions = () => {
  return userRoleManagementApi.get("/user/detail/permission");
};
export const fetchAllMasters = (search, limit, offset) =>
  API.get(
    `/scheme-master-product?search=${search}&limit=${limit}&offset=${offset}`
  );

export const fetchAllMastersProducts = (
  master,
  search,
  limit,
  offset,
  status
) =>
  API.get(
    `/${master}-scheme-master?search=${search}&limit=${limit}&offset=${offset} ${
      status ? "&status=" + status : ""
    }`
  );

export const downloadSampleFileSchemeMaster = (master) => {
  return API.get(`/${master}/scheme/sample-file`);
};
export const downloadSampleFileNav = (master, nav) => {
  return API.get(`/${master}/${nav}/sample-file`);
};

export const uploadFileSchemeMaster = (master, formData) => {
  return API.post(`/${master}-scheme-master/bulkUpload`, formData);
};

export const uploadCorporateAction = (master, formData) => {
  return ReportsApi.post(`/upload/private-corp-act`, formData);
};
export const uploadBondsUploadPrice = (master, formData) => {
  return ReportsApi.post(`/bonds/nav`, formData);
};
export const uploadPayoutReduction = (master, formData) => {
  return API.post(`/${master}-payout-details/bulkUpload`, formData);
};
export const downloadPayoutSampleFileNav = (master) => {
  return API.get(`/${master}/payout_reduction/sample-file`);
};
export const downloadBondsUploadPrice = (master) => {
  return API.get(`/${master}/nav/sample-file`);
};
export const uploadNav = (master, formData) => {
  return ReportsApi.post(`/${master}/inactive-fund/nav`, formData);
};
export const uploadPrice = (master, formData) => {
  return ReportsApi.post(`/${master}/nav`, formData);
};
export const fetchMasterProduct = (id, master) => {
  return API.get(`/${master}-scheme-master/${id}`);
};

export const updateScemeMaster = (id, isin, master, formData) => {
  if (id === 0) {
    let url = `/${master}-scheme-master`;
    return API.post(url, formData);
  } else {
    let url;
    if (master === "re") {
      url = `/${master}-scheme-master/${id}`;
    } else {
      url = `/${master}-scheme-master/${id}/${isin}`;
    }
    if (master === "equity") {
      return API.patch(url, formData);
    } else {
      return API.put(url, formData);
    }
  }
};

//pdf
export const fetchEngagement = (clientId, filters, type) => {
  let params = {
    clientId,
    ...filters,
  };
  return ReportsApi.get(`/engagement-model`, {
    params: params,
  });
};

export const fetchTop5Amc = (clientId, filters, type) => {
  let params = {
    clientId,
    ...filters,
  };
  return ReportsApi.get(`/top-five-amc`, {
    params: params,
  });
};

export const fetchTopFiveIssuers = (clientId, filters, type) => {
  let params = {
    clientId,
    ...filters,
  };
  return ReportsApi.get(`/top-five-issuers`, {
    params: params,
  });
};
export const fetchTop5AIF = (clientId, filters, type) => {
  let params = {
    clientId,
    ...filters,
  };
  return ReportsApi.get(`/top-five-amc-aif`, {
    params: params,
  });
};

export const fetchDrawdown = (clientId, filters, type) => {
  let params = {
    clientId,
    ...filters,
  };
  return ReportsApi.get(`/drawdown-summary`, {
    params: params,
  });
};

export const fetchMarketcap = (clientId, filters, type) => {
  let params = {
    clientId,
    ...filters,
  };
  return ReportsApi.get(`/equity-asset-market-capitalization`, {
    params: params,
  });
};

export const fetchTop5Stocks = (clientId, filters, type) => {
  let params = {
    clientId,
    ...filters,
  };
  return ReportsApi.get(`top-five-stocks`, {
    params: params,
  });
};

export const topPerfromers = (clientId, filters, type) => {
  let params = {
    clientId,
    ...filters,
  };
  return ReportsApi.get(`top-performers`, {
    params: params,
  });
};

export const bottomPerfromers = (clientId, filters, type) => {
  let params = {
    clientId,
    ...filters,
  };
  return ReportsApi.get(`bottom-performers`, {
    params: params,
  });
};

export const topBottomPerformers = (clientId, filters, type) => {
  let params = {
    clientId,
    ...filters,
  };
  return ReportsApi.get(`/dashboard/top-bottom-performers`, {
    params: params,
  });
};

export const fetchMemberWisePerformance = (clientId, filters, type) => {
  let params = {
    clientId,
    ...filters,
  };
  return ReportsApi.get(`/${type}/member-wise-performance`, {
    params: params,
  });
};
export const fetchAsset_Allocation = (clientId, filters, type) => {
  let params = {
    clientId,
    ...filters,
  };
  return ReportsApi.get(`/asset-allocation`, {
    params: params,
  });
};
export const fetchAlternateSub_Allocation = (clientId, filters, type) => {
  let params = {
    clientId,
    ...filters,
  };
  return ReportsApi.get(`/alternate-subclasses-investments`, {
    params: params,
  });
};

export const fetchTop5Sectors = (clientId, filters, type) => {
  let params = {
    clientId,
    ...filters,
  };
  return ReportsApi.get(`equity-summary`, {
    params: params,
  });
};

export const getCashFlow2 = (clientId, filters, type) => {
  let params = {
    clientId,
    ...filters,
  };
  return ReportsApi.get(`/dashboard/advisor-wise-cashflow`, {
    params: params,
  });
};
export const getQuarterWiseCashFlow = (clientId, filters, type) => {
  let params = {
    clientId,
    ...filters,
  };
  return ReportsApi.get(`/dashboard/quarter-wise-cashflow`, {
    params: params,
  });
};

export const deleteTransaction = (clientId, productType, transactionId) => {
  let params = {
    clientId,
    productType,
    transactionId,
  };
  return ReportsApi.delete(`/dashboard/delete-transaction`, {
    params: params,
  });
};

//strategy builder apis

export const getStrategies = ({ params }) => {
  return ReportsApi.get("strategy-builders", { params: params });
};

export const getIndexExpectedReturnAPI = ({ params }) => {
  // console.log(params, "agagnil");
  return ReportsApi.get("/strategy-builders/index/average-br", {
    params: params,
  });
};

export const createStrategyBuildersAPI = (params, isBenchmark) => {
  if (isBenchmark) {
    return ReportsApi.post("/excess-performance/asset-benchmark", params);
  } else {
    return ReportsApi.post("/strategy-builders", params);
  }
};

export const fetchAssetBenchMark = ({ params }) => {
  return ReportsApi.get("/excess-performance/asset-benchmark", {
    params: params,
  });
};

export const updateStrategyBuildersAPI = (params) => {
  return ReportsApi.put(`/strategy-builders/${params?.id}`, params);
};

export const mapClientRep = (body) => {
  return API.post("/representatives-client-mapping", body);
};

//new print-report apis

export const getTableStatus = (params) => {
  return ReportsApi.get("/print-report/status", { params: params });
};
export const ScheduleRequestReport = (data) => {
  return ReportsApi.post("/print-report/scheduled-request", data);
};
export const ApproveScheduleRequest = (data) => {
  return ReportsApi.patch("/print-report/scheduled-request", data);
};

export const getScheduleList = (created_at, params) => {
  return ReportsApi.get("/print-report/scheduled-request/" + created_at, {
    params: params,
  });
};
export const getPrintDataMongo = (params) => {
  return ReportsApi.get("/print-report/data", { params: params });
};

export const RequestReport = (params) => {
  console.log("params", params);
  return PrintReportsApi.get("/print-report/request", { params: params });
};
export const printPdfUpload = (data) => {
  return PrintReportsApi.post("/print-report/upload", data);
};

//twrr dashboard

export const assetDetailsTwrr = (params) => {
  // console.log("params", params);
  return ReportsApi.get("/dashboard/asset-details-twrr", { params: params });
};

export const productWiseTwrr = (params) => {
  // console.log("params", params);
  return ReportsApi.get("/dashboard/product-summary-twrr", { params: params });
};
