import * as React from "react";
import { styled, useTheme } from "@material-ui/core";
import { FormGroup } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import { Switch } from "@material-ui/core";
import { useEffect, useState } from "react";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 62,
  height: 30,
  padding: "4px",
  "& .MuiSwitch-root": {
    width: "70px",
    height: "35px",
  },
  "& .MuiSwitch-switchBase": {
    padding: "2px", // Adjusted padding to create space
    margin: "2px", // Adjusted margin to create space
    position: "absolute",
    top: "3px",
    left: "5px",
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(28px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.cards.switchBgActive,
        opacity: 1,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 24,
    height: 26,
    // background: "linear-gradient(127deg, #4C1A97 14.75%, #7636D7 88.7%)",
    background: "red",
    marginTop: "2px",
    marginLeft: "1.5px",
  },
  "& .MuiSwitch-track": {
    borderRadius: "31px",
    // backgroundColor: theme.palette.mode === "light" ? "#16082E" : "#39393D",
    background: theme.palette.cards.switchBgInactive,
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    border: `1px solid ${theme.palette.cards.switchBorder}`,
    boxShadow: "inset 0px 6px 8px 3px rgba(0, 0, 0, 0.1)",
  },
}));

export function IosSwitch({ checked, setChecked, switchLabelName }) {
  const theme = useTheme();
  useEffect(() => {
    let holdingSwitchLabel = document.querySelector(".holdingSwitch");
    if (document.querySelector("#ios-switch")) {
      let parent = document.querySelector("#ios-switch");
      let muiSwitchThumb = parent.nextSibling;
      if (checked) {
        muiSwitchThumb.classList.remove("custom-switch-inactive");
        muiSwitchThumb.classList.add("custom-switch-active");
        muiSwitchThumb.style.background = theme.palette.cards.switchThumbActive;
        holdingSwitchLabel.lastChild.classList.remove("holdingSwitchInactive");
        holdingSwitchLabel.lastChild.classList.add("holdingSwitchActive");
      } else {
        muiSwitchThumb.classList.remove("custom-switch-active");
        muiSwitchThumb.classList.add("custom-switch-inactive");
        muiSwitchThumb.style.background = "#ffff";
        holdingSwitchLabel.lastChild.classList.remove("holdingSwitchActive");
        holdingSwitchLabel.lastChild.classList.add("holdingSwitchInactive");
      }
    }
  }, [checked]);

  return (
    <FormGroup>
      <FormControlLabel
        className="holdingSwitch"
        control={
          <IOSSwitch
            style={{ m: 1 }}
            className="custom-switch" // add custom CSS class
            checked={checked}
            onChange={() => setChecked((prev) => !prev)}
            id="ios-switch"
          />
        }
        label={switchLabelName ? switchLabelName : "Current Holdings"}
        labelPlacement="start"
        style={{
          fontFamily: "Avenir",
          fontWeight: 900,
          fontSize: "16px",
          letterSpacing: "1.5px",
          textTransform: "uppercase",
          color: "#FFFFFF",
          opacity: 0.9,
        }}
      />
    </FormGroup>
  );
}
