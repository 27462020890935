import React, {
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
  useMemo,
} from "react";
import PageContainer from "../PageContainer";
import Header from "./Header";
import PrintTable from "./Table";
import "./Table.css";
import {
  bodyDataFormatWithProd,
  dataFormatWrapper,
  objectFormatingWithTitleCOlWrapper,
} from "./utils/common";

import {
  stBodyEntityData,
  stbodyData,
  stHeaderData,
  sdTotalData,
  headerData,
} from "./utils/staticData";

function HandlePageBreak({
  customData,
  loading,
  headerData,
  customHeader,
  setChanges,
  changes,
  hideTitle,
  layout,
  reset,
  customTableClass,
  hidecircle,
  pdfInfo,
  footerLegendText,
  footerLegendNotes,
  eoTable,
  asOnDate,
}) {
  const pRef = useRef(null);

  const containerRef = useRef(null);
  const [newPage1, setNewPage] = useState([]);
  const [newPage3, setNewPage3] = useState([]);

  useEffect(() => {
    setNewPage(() => {
      setNewPage3([]);
      return customData;
    });
  }, [customData]);

  const handleRowSeperation = () => {
    if (pRef.current?.children) {
      let arr = [];
      // let maxHeight = containerRef?.current?.offsetHeight - 380;
      // let currentHeight = 0;
      let count = 0;
      Array.from(pRef.current?.children)?.map((row, i) => {
        // if (currentHeight <= maxHeight) {
        //   console.log(
        //     currentHeight,
        //     row?.offsetHeight,
        //     currentHeight + row?.offsetHeight,
        //     maxHeight,
        //     "page props"
        //   );
        //   currentHeight = currentHeight + row?.offsetHeight;
        // }
        // if (currentHeight > maxHeight) {
        //   arr.push(i);
        //   currentHeight = -row?.offsetHeight;
        // }
        if (count > 6) {
          arr.push(i);
          count = 1;
        }
        count = count + 1;
      });
      return arr;
    }
  };

  useEffect(() => {
    if (newPage1.length > 0) {
      if (
        pRef &&
        pRef?.current?.offsetHeight > containerRef?.current?.offsetHeight - 880
      ) {
        let sepIndexes = handleRowSeperation();

        let newPages2 = [];

        newPage1.map((page) => {
          sepIndexes?.map((seps, i) => {
            if (i == 0) {
              let a = page.slice(0, seps);
              if (a.length > 0) {
                newPages2.push(a);
              }
              if (i == sepIndexes?.length - 1) {
                let a = page.slice(seps, page?.length);
                if (a.length > 0) {
                  newPages2.push(a);
                }
              }
              return;
            } else {
              let a = page.slice(sepIndexes[`${i - 1}`], seps);
              if (a.length > 0) {
                newPages2.push(a);
              }

              if (i == sepIndexes?.length - 1) {
                let a = page.slice(seps, page?.length);
                if (a.length > 0) {
                  newPages2.push(a);
                }
              }
            }
          });
        });
        // console.log(newPages2, "ramdat");
        setNewPage3(newPages2);
        if (setChanges) setChanges(newPages2);
      }
    }
  }, [newPage1]);

  return (
    <>
      {!loading
        ? newPage3 && newPage3.length > 0
          ? newPage3?.map((val1) => (
              <PageContainer
                layout_t={layout || 1}
                bgColor={"#fff"}
                pdfInfo={pdfInfo}
                footerLegendText={footerLegendText}
                footerLegendNotes={footerLegendNotes}
                asOnDate={asOnDate}
              >
                {customHeader ? (
                  customHeader
                ) : (
                  <Header
                    title={
                      <>
                        Performance <br /> Summary
                      </>
                    }
                    type={3}
                    subtext={""}
                  ></Header>
                )}
                {val1 && (
                  <PrintTable
                    hidecircle={hidecircle}
                    colors={[]}
                    eoTable={eoTable}
                    // customRef={pRef2}
                    customTableClass={customTableClass}
                    hideTitle={hideTitle}
                    data={val1}
                    header={headerData}
                    hideUnderText={true}
                    customPadding={"2.2px 16px"}
                    firstCellCircleProps={{
                      height: "8px",
                      width: "4.5px",
                    }}
                    titleCellProps={{
                      firstCellStyle: { padding: "10px 0px 10px 0px" },
                      style: {
                        background: "#F3F8FD",
                        padding: "0px 16px 0px 9px",
                        minHeight: "47px",
                      },
                      font: {
                        fontWeight: "900",
                        fontSize: "16px",
                      },
                    }}
                    normalCellProps={{
                      firstCellPadding: "10px 0px 10px 0px",
                      fontStyle: { fontWeight: "600", fontSize: "14px" },
                      tablecell: { verticalAlign: "top" },
                    }}
                    headerProps={{
                      className: "whiteHeaderCell",
                      titleClassName: "whiteTitle",
                      titleStyle: {
                        fontWeight: "500",
                        fontFamily: "AvenirBold",
                        fontSize: "14px",
                        lineHeight: "18px",
                        letterSpacing: "0.06em",
                        textTransform: "uppercase",
                        color: "#000000",
                      },
                    }}
                    colSpan={1}
                  />
                )}
                {eoTable && eoTable}
              </PageContainer>
            ))
          : newPage1 && newPage1.length > 0
          ? newPage1?.map((val1) => (
              <PageContainer
                customRef={containerRef}
                layout_t={layout || 1}
                bgColor={"#fff"}
                pdfInfo={pdfInfo}
                footerLegendText={footerLegendText}
                footerLegendNotes={footerLegendNotes}
                asOnDate={asOnDate}
              >
                {customHeader ? (
                  customHeader
                ) : (
                  <Header
                    title={
                      <>
                        Performance <br /> Summary
                      </>
                    }
                    type={3}
                    subtext={""}
                  ></Header>
                )}

                {val1 && (
                  <PrintTable
                    colors={[]}
                    eoTable={eoTable}
                    hidecircle={hidecircle}
                    customTableClass={customTableClass}
                    hideTitle={hideTitle}
                    customRef={pRef}
                    data={val1}
                    header={headerData}
                    hideUnderText={true}
                    customPadding={"2.2px 16px"}
                    firstCellCircleProps={{
                      height: "8px",
                      width: "4.5px",
                    }}
                    titleCellProps={{
                      firstCellStyle: { padding: "0px 0px 0px 0px" },
                      style: {
                        background: "#F3F8FD", //color change
                        padding: "0px 16px 0px 9px",
                        minHeight: "47px",
                      },
                      font: {
                        fontWeight: "900",
                        fontSize: "16px",
                      },
                    }}
                    normalCellProps={{
                      firstCellPadding: "10px 0px 10px 0px",
                      fontStyle: { fontWeight: "600", fontSize: "14px" },
                      tablecell: { verticalAlign: "top" },
                    }}
                    headerProps={{
                      className: "whiteHeaderCell",
                      titleClassName: "whiteTitle",
                      titleStyle: {
                        fontWeight: "500",
                        fontFamily: "AvenirBold",
                        fontSize: "14px",
                        lineHeight: "18px",
                        letterSpacing: "0.06em",
                        textTransform: "uppercase",
                        color: "#000000",
                      },
                    }}
                    colSpan={1}
                  />
                )}
              </PageContainer>
            ))
          : "no Data"
        : "loading"}
    </>
  );
}

export default HandlePageBreak;
