import React from "react";
import { useStyles } from "./CardStyles";
import { Box } from "@material-ui/core";
export const GainLossLegend = () => {
  const classes = useStyles();
  return (
    <Box className={classes.legendContainer}>
      <Box className={classes.gainLossContainer}>
        <div className={classes.gainCirlce}></div>
        <span className={classes.legendText}>Gain</span>
      </Box>
      <Box className={classes.gainLossContainer}>
        <div className={classes.lossCircle}></div>
        <span className={classes.legendText}>Loss</span>
      </Box>
    </Box>
  );
};
