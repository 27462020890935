import { Grid, Typography } from "@material-ui/core";
import React, { useRef, useLayoutEffect } from "react";
import { pie_onClick3 } from "../../../../charts/nivo_objects";
import { roundNumber } from "../../../../utils/common";
import useStyles from "../../common/ProductsStyles";
import PmsReportTable from "../../pms/PmsReportTable";
import PieChart from "../../../../charts/PieChart";
import { useState } from "react";
import { useEffect } from "react";
import HashlinkSupport from "./HashlinkSupport";

function CardWithPieChartAndTable({
  data,
  tableConfig,
  title,
  subTitle,
  id,
  sectorWiseColor,
  setSectorWiseState,
  sectorWiseState,
}) {
  const [tableColumns, setTableColumns] = useState([...data?.tableData]);
  const [tableHeader, setTableHeader] = useState(tableConfig);
  const [pieChartData, setPieChartData] = useState([...data?.pieChartData]);
  const classes = useStyles();
  const pieChartDiv = useRef(null);
  const [height, setHeight] = useState();
  const [centerData, setCenterData] = useState({
    title: "",
    value: "",
    colors: data?.pieChartData?.map((val) => val?.color),
    disabled: [],
  });

  const newSectorWiseColor = data?.pieChartData?.map((val) => val?.color);

  //   console.log(centerData,'afbawaowgfoawg');

  //   useLayoutEffect(() => {
  //     // setWidth(ref.current.offsetWidth);

  //   }, []);

  useEffect(() => {
    setTableColumns(data?.tableData);
    setPieChartData(data?.pieChartData);
  }, [data]);

  useEffect(() => {
    setHeight(pieChartDiv?.current?.offsetHeight);
  }, [pieChartData]);

  return (
    <>
      <HashlinkSupport id={id} />

      {title && (
        <Typography
          // style={{ marginLeft: "3%" }}
          className={`${classes.riskHeaderSub} ${classes.typoInsideAlign}`}
          id={id}
        >
          {title}
        </Typography>
      )}
      <div
        className={classes.mainContent}
        style={{
          display: "flex",
          margin: "2%",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          flexDirection: "column",
        }}
      >
        {/* {subTitle && (
          <Typography
            style={{ marginLeft: "2%" }}
            className={`${classes.riskHeaderSub} ${classes.typoInsideAlign}`}
            id="fundPortfolio"
          >
            {subTitle}
          </Typography>
        )} */}
        <Grid container spacing={3}>
          {pieChartData?.length > 0 && (
            <Grid item md={5} sm={12}>
              <div
                // className={classes.mainContent}
                style={{
                  display: "flex",
                  margin: "0%",
                  alignItems: "center",
                  //   height: "web",
                  justifyContent: "center",
                  // alignItems: "flex-start",
                  alignContent: "flex-start",
                  flexDirection: "column",
                }}
              >
                {subTitle && (
                  <Typography
                    style={{ marginLeft: "2%", width: "100%" }}
                    className={`${classes.riskHeaderSub} ${classes.typoInsideAlign}`}
                    id="fundPortfolio"
                  >
                    {subTitle}
                  </Typography>
                )}
                <div
                  ref={pieChartDiv}
                  style={{
                    // display: "block",
                    // margin: "0%",
                    // marginTop: "auto",
                    // marginBottom: "auto",
                    // // height: "422px",
                    // width: "527px",
                    display: "flex",
                    margin: "auto 0%",
                    width: "527px",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <div style={{ width: "460px", height: "300px" }}>
                    <PieChart
                      config={{
                        innerRadius: "0.70",
                        padAngle: 2,
                        legends: false,
                        disableArcLink: true,
                        minWith: "100%",
                        // arcLinkLabel: (d) => `${d.value}%`,
                        // margin: { right: 80, top: 20, left: 80 },
                        height: "300px",

                        chart: { height: 385 },
                      }}
                      colors={pieChartData.map((val) => val?.color)}
                      onClickEvent={{
                        click: pie_onClick3,
                        set: setCenterData,
                        cstate: centerData,
                        ncolors: newSectorWiseColor,
                      }}
                      data={pieChartData}
                    >
                      <div
                        className="cen1"
                        style={{
                          color: "#fff",
                          transform: "translate(-56px, 0%)",
                        }}
                      >
                        <div className="cir">
                          <div className="glowcircle glowcircle2">
                            <div className="circleInnerText">
                              <p className="innerCircleTitle">
                                {centerData?.title
                                  ? centerData?.title
                                  : data?.total
                                  ? "Total"
                                  : ""}
                              </p>
                              <p className="innerCircleValue">
                                {centerData?.value
                                  ? roundNumber(centerData?.value) + "%"
                                  : data?.total
                                  ? roundNumber(data?.total) + "%"
                                  : ""}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <GlowCircle
                      centerInfoCircleStyle={{
                        top: "50.9%",
                        left: "55%",
                      }}
                      circleBorderStyle={{
                        maxWidth: "144px",
                        height: "144px",
                      }}
                      glowCircleStyle={{ width: "122px", height: "122px" }}
                    >
                      <div className="circle_content">
                        <div> */}
                      {/* <b>{pie.text}</b> */}
                      {/* <br></br> */}
                      {/* <b>{pie.value}</b> */}
                      {/* </div>
                      </div> */}
                      {/* </GlowCircle> */}
                    </PieChart>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      marginBlock: "23px",
                      width: "460px",
                    }}
                  >
                    {pieChartData.map((m, i) => {
                      return (
                        <div className="llabeContainer">
                          <div className="lsymbol">
                            <svg height="25" width="25">
                              <circle
                                cx="12"
                                cy="12"
                                r="5"
                                stroke-width="3"
                                fill={m?.color}
                              />
                              Sorry, your browser does not support inline SVG.
                            </svg>
                          </div>
                          <div className="llabel">
                            <span className="labelText sector-wise-holding">
                              {m?.label}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Grid>
          )}
          <Grid item md={pieChartData?.length == 0 ? 12 : 7} sm={12}>
            <div
              style={{
                // maxHeight: "475px",
                display: "flex",
                //   width: "97%",
                margin: "0%",
                // height: "100%",
              }}
              //   className={classes.mainContent}
            >
              <div style={{ width: "-webkit-fill-available", height: height }}>
                <PmsReportTable
                  tableMaxHeight={height + 5}
                  tableColumns={tableColumns}
                  setTableColumns={setTableColumns}
                  headerList={tableConfig}
                  tableHeader={tableHeader}
                  setTableHeader={setTableHeader}
                  tableBodyBackground={"transparent"}
                  tableHeaderBGColor={"#1D1B36"}
                  margin="1%"
                  firstCollFullWidth={true}

                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default CardWithPieChartAndTable;
