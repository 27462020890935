import React, { useState, useEffect } from "react";

import HandlePageBreak from "../../Components/HandlePageBreak";
import Header from "../../Components/Header";
import { objectFormatingWithTitleCOlWrapper } from "../../Components/utils/common";
import { EquityMFHeader } from "../../Components/utils/staticData";
import { Link } from "react-router-dom";

function HoldingPrint({
  customData,
  loading,
  subtext,
  title,
  title2,
  title1,
  headerType,
  error,
  type,
  customKey,
  customTableHeader,
  setChanges,
  changes,
  hideTotalRow,
  hideTitle,
  totaRowData,
  footerLegendText,
  footerLegendNotes,
  customTableClass,
  denomination,
  customHeader,
  headerStyle = {},
  asOnDate,
  range,
  layout,
  pdfInfo,
  breadcrumb = [],
  colmapping,
  render,
}) {
  const [newPage, setNewPage] = useState([]);
  //   console.log(
  //     customData?.equity?.mf_holding_summary?.data?.data?.result,
  //     "afjsjfkbshbfjaf"
  //   );
  // console.log(customKey, customTableHeader, loading, "afjsjfkbshbfjaf");
  const asset = ["equity", "debt", "alternate", "cash"];
  const handleData = (key1, headerData) => {
    // console.log(key1, headerData, "afjsjfkbshbfjaf");
    if (error == true) {
      return;
    }
    let total = {};

    let firstCells = ["scripName", "schemeName"];

    headerData?.map((val) => {
      if (firstCells.includes(val?.field)) return (total[val?.field] = "total");
      return (total[val?.field] = "");
    });

    let a = objectFormatingWithTitleCOlWrapper(
      {
        [key1]: customData,
      },
      headerData,
      [],
      "#ADC371",
      undefined,
      totaRowData ? totaRowData : hideTotalRow ? undefined : total,
      {},
      denomination,
      "schemeName",
      type,
      colmapping
    );
    // console.log(a, "object formatting");

    setNewPage(() => [a]);
  };

  useEffect(() => {
    if (!loading) {
      handleData(customKey, customTableHeader);
    }
  }, [loading, customData]);
  const breadcrumbs = [
    <Link key="1" color="#000000" className="printBreadsel">
      {title ? title : <>Holding Summary</>}
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="
      #A29FA6"
    >
      {pdfInfo?.client}
    </Link>,
  ];
  return (
    <div className="newPrint">
      <HandlePageBreak
        asOnDate={asOnDate}
        customTableClass={customTableClass}
        setChanges={setChanges}
        footerLegendText={footerLegendText}
        footerLegendNotes={footerLegendNotes}
        changes={changes}
        layout={layout || 4}
        pdfInfo={pdfInfo}
        customHeader={
          headerType == "11" ? (
            <>
              <Header
                tab={title1}
                breadcrumbs={breadcrumbs}
                type={11}
                tabs={asset}
                subtext={""}
                width={"5in"}
                reportType={type}
                denomination={denomination}
              ></Header>
              <Header
                title={<>{title2}</>}
                type={10}
                subtext={""}
                width={"5in"}
              ></Header>
            </>
          ) : (
            <Header
              title={title ? title : <>Holding Summary</>}
              title1={title1}
              title2={title2 ? title2 : undefined}
              date={range}
              type={headerType ? headerType : 3}
              subtext={subtext ? subtext : "Direct Equity"}
              Style={headerStyle}
              breadcrumbs={breadcrumb}
              denomination={denomination}
              reportType={type}
            ></Header>
          )
        }
        customData={newPage}
        loading={loading}
        headerData={customTableHeader}
        hideTitle={hideTitle}
      />
      {render && render}
    </div>
  );
}

export default HoldingPrint;
