import React, { useContext, useState } from "react";
import PageFilter from "../common/PageFilter";
import { ReactComponent as Back } from "../../../../assets/holding-summary/back-blue.svg";
import { useNavigate } from "react-router-dom";
import TabComponent from "../common/TabComponent";
import { OnboardingContext } from "../../../../Context";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import PmsHolding from "../pms/PmsHolding";
import PmsTransaction from "../pms/PmsTransaction";
import PmsPortfolio from "../pms/PmsPortfolio";
import { getTransactionsSummary } from "../../../../api";
import {
  transactionTableConfig,
  holdingTableConfig,
} from "../structured_products/utils/constands";
import {
  mldHoldingTableConfig,
  mldTransactionConfig,
  customTableConfig,
} from "./mldConfig";
import { objectFormating1 } from "../../../utils/objectFormating";
import MLDHolding from "./MLDHolding";
import MLDTransaction from "./MLDTransaction";
import useStyles from "../common/ProductsStyles";
import MLDCashFlow from "./MLDCashFlow";
import NewTabComp from "../common/NewTabComp";
import { routes } from "../../../../routes";
import { NewFilter } from "../../newFilter/NewFilter";

const data = [
  {
    page: "holding",
    title: "Holding Summary",
    path: routes?.mldHolding,
  },
  {
    page: "transaction",
    title: "Transaction Summary",
    path: routes?.mldTransaction,
  },
  // {
  //   page: "cash-flow",
  //   title: "Cash Flow",
  //   path: routes?.mldCashFlow,
  // },
];

const stData = {
  success: true,
  event: "PRIVATE_HOLDING_SUMMARY",
  message: "Private Holding Summary fetched successfully.",
  uuid: "5173c680-d444-11ed-930f-411b3acf3bb0",
  data: {
    meta: {
      filters: {
        clientId: 199,
        assetClass: "equity,debt",
      },
    },
    result: [
      {
        advisor: "neo",
        isin: "INE012A01025",
        assetType: "debt",
        transactionType: "fresh purchase",
        productSubCategory: "g-sec",
        scripName: "ACC Ltd",
        stockSymbol: "ACC",
        transactionDate: "2023-03-22",
        units: 400,
        nav: 55,
        investmentValue: 110000,
        withdrawalAmount: 0,
        presentValue: 0,
        totalGainLoss: -110000,
        realisedGainLoss: 0,
        unRealisedGainLoss: -110000,
        xirr: 0,
        absoluteTwrr: -100,
        annualisedTwrr: -1,
        avgHoldingPeriod: 0,
        percentageOfTotalPortfolio: 0,
      },
    ],
  },
};

function MLDReport({ pageName }) {
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const [page, setPage] = useState(pageName);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const { userState } = OnboardingContext();
  const classes = useStyles();
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  // const [pagiNationData, setPageinationData] = useState({
  //   pageCount: null,
  //   // limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
  // });

  const getTabelData = async (
    setTableColumns,
    tableName,
    params,
    setLoading,
    objectFormating,
    setCheckedTrans,
    tableHeader,
    limit,
    offset,
    setPageinationData,
    pagiNationData,
    numberOfInstrumentsCount,
    setPercentageOfTotalPortfolio,
    setNumberOfInstruments
  ) => {
    setLoading(true);
    // static params for testing purpose
    // params =
    //   page === "holding"
    //     ? {
    //         clientId: 199,
    //         assetClass: params.assetClass,
    //       }
    //     : {
    //         clientId: 199,
    //         memberId: 44,
    //         startDate: "2021-04-10",
    //         endDate: "2022-10-09",
    //         assetClass: params.assetClass,
    //       };
    // if (params["schemeName"]) {
    //   params["schemeName"] = params["schemeName"];
    // }

    params = {
      clientId: userState.loginedUserData.id,
      // clientId: 197,
      offset: offset,
      limit: limit,
      search: params["search"] ? params["search"] : "",
      ...getfilter_as_params(),
      // assetClass: params.assetClass,
    };

    try {
      let response = await getTransactionsSummary("mld", tableName, params);
      setPageinationData((prev) => {
        return { ...prev, pageCount: response?.data?.data?.meta?.count };
      });

      if (page == "holding") {
        if (numberOfInstrumentsCount.current === 0) {
          setNumberOfInstruments(response?.data?.data?.meta?.count);
        }
        numberOfInstrumentsCount.current = numberOfInstrumentsCount.current + 1;
        setPercentageOfTotalPortfolio(
          response?.data?.data?.total?.percentageOfTotalPortfolio
        );
      }

      let newlist = [];
      let transactionList = [];
      // stData?.data?.result?.map((val) => {
      //   if (page === "holding") {
      //     let obj = objectFormating1(val, tableHeader);
      //     console.log(obj, "afpnafpabwifawf");
      //     newlist.push(obj);
      //   } else {
      //     val.advisor = val?.advisor + "%" + val?.updated_at;
      //     let obj = objectFormating1(val, tableHeader);
      //     newlist.push(obj);
      //     if (
      //       val["transaction_type"] != null &&
      //       !transactionList
      //         .map((val) => val?.title)
      //         .includes(val["transaction_type"])
      //     ) {
      //       transactionList.push({
      //         title: val["transaction_type"],
      //         show: false,
      //       });
      //     }
      //     console.log(newlist, "aflibaibwfowf");
      //   }
      // });
      response?.data?.data?.result?.map((val) => {
        if (page === "holding") {
          let obj = objectFormating1(val, tableHeader);

          newlist.push(obj);
        } else {
          val.advisor = val?.advisor + "%" + val?.updated_at;
          let obj = objectFormating1(val, tableHeader);
          newlist.push(obj);
          if (
            val["transaction_type"] != null &&
            !transactionList
              .map((val) => val?.title)
              .includes(val["transaction_type"])
          ) {
            transactionList.push({
              title: val["transaction_type"],
              show: false,
            });
          }
        }
      });

      if (page !== "holding") {
        setCheckedTrans(transactionList);
      }
      setTableColumns(newlist);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const changePage = (p) => {
    switch (p) {
      case "holding":
        return (
          <MLDHolding
            typeOfProduct={"mld"}
            hideSubTab={true}
            customTableConfig={customTableConfig(mldHoldingTableConfig)}
            getTabelData={getTabelData}
            subHeading={"Market Linked Debentures"}
            headerInTwoLine={true}
          ></MLDHolding>
        );

      case "transaction":
        return (
          <MLDTransaction
            hideSubTab={true}
            getTabelData={getTabelData}
            customTableConfig={customTableConfig(mldTransactionConfig)}
            subHeading={"Market Linked Debentures"}
            headerInTwoLine={true}
            // pageCount={pagiNationData?.pageCount}
          />
        );

      case "cash-flow":
        return (
          <MLDCashFlow
            clientId={userState.loginedUserData.id}
            assetClasses="debt"
            getParams={getfilter_as_params}
            entity={"mld"}
            subHeading={"Market Linked Debentures"}
          />
        );

      default:
        return (
          <MLDHolding
            typeOfProduct={"mld"}
            customTableConfig={customTableConfig(mldHoldingTableConfig)}
            getTabelData={getTabelData}
            subHeading={"Market Linked Debentures"}
            headerInTwoLine={true}
          ></MLDHolding>
        );
    }
  };

  const handleClick = React.useCallback(
    (page) => {
      setPage(page);
    },
    [page]
  );

  return (
    <>
      <PageFilter
        backLink={routes?.mldSummaryPage}
        setFilterModalOpen={setFilterModalOpen}
      ></PageFilter>
      <NewFilter
        filterModalOpen={filterModalOpen}
        setFilterModalOpen={setFilterModalOpen}
        productType="mld"
      />
      <div className="backBtnContainer" style={{ margin: "2%" }}>
        <NewTabComp
          data={data}
          customWidth={"100%"}
          customHandle={handleClick}
          customSelect={data.findIndex((val) => val?.page == page)}
        />
      </div>
      {changePage(page)}
    </>
  );
}

export default MLDReport;
