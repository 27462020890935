import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Done from "../assets/partner-images/done.png";
import Loader from "../components/loader/Loader";
import { API_URL, CLIENT_CODE } from "../Config";
import Lock from "../assets/partner-images/lock.png";
import EnterPINInput from "../components/common/PinInput";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography, Grid } from "@material-ui/core";
import SignUpImage from "../assets/partner-images/Sign_up.png";
import Logo from "../assets/icons/logo.png";
import { ReactComponent as Pending } from "../assets/icons/pending-verification.svg";
import { ReactComponent as Verified } from "../assets/icons/verified-verification.svg";
import { ENTITY_TYPE_CLIENT, ENTITY_TYPE_PARTNER } from "../Constants";
import CustomizedSnackbars from "../components/common/CustomizedSnackbars";
import { createPin } from "../api";
import { OnboardingContext } from "../Context";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundImage: `url(${SignUpImage})`,
    height: "100vh",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: "#170630",
  },
  logo: {
    width: "55px",
    left: "5px",
    margin: "0px",
    top: "15px",
    position: "absolute",
  },
  contentBox: {
    background: "rgba(11, 3, 24, 0.6)",
    border: "1px solid #4E189F",
    boxShadow: "0px 0px 10px #4E189F",
    backdropFilter: "blur(50px)",
    borderRadius: "50px",
    position: "relative",
    left: "22%",
    width: "40%",
    top: "25%",
    [theme.breakpoints.down("md")]: {
      left: "10%",
      width: "65%",
      top: "25%",
    },
    [theme.breakpoints.down("xs")]: {
      left: "5%",
      width: "90%",
      top: "25%",
    },
  },
  mainContent: {
    height: "300px",
    padding: "15px 20px 2px 20px",
  },
  text: {
    fontStyle: "normal",
    fontFamily: "Avenir",
  },
  success: {
    fontWeight: "800",
    fontSize: "24px",
    lineHeight: "30px",
    textAlign: "center",
    color: "#FFFFFF",
    marginBottom: "10px",
  },
  successText: {
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "24px",
    textAlign: "center",
    color: "#FFFFFF",
    marginBottom: "15px",
  },

  line: {
    opacity: "0.2",
    backgroundColor: "#D4BBFB",
    border: ".5px solid #D4BBFB",
  },
  buttonContainer: {
    textAlign: "center",
    marginTop: "5px",
    marginBottom: "10px",
  },
  button: {
    paddingTop: "10px",
    width: "70%",
    color: "rgb(255, 255, 255)",
    background:
      "linear-gradient(rgb(170, 111, 254) 0%, rgb(113, 28, 241) 100%)",
    borderRadius: "20px",
    paddingBottom: "10px",
  },
  buttonText: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#FFFFFF",
  },
  image: {
    background: "#D4BBFB",
    borderRadius: "50%",
    height: "30px",
    width: "30px",
    padding: "10px",
  },
  onBoardingHeading: {
    fontWeight: "800",
    fontSize: "24px",
    lineHeight: "30px",
    textAlign: "center",
    color: "#FFFFFF",
    marginTop: "10px",
    marginBottom: "15px",
  },
  label: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#9488A7",
    marginTop: "10px",
    marginBottom: "15px",
  },
}));

function VerifyEmailMobile() {
  const classes = useStyles();
  const [showLoder, setShowLoader] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");
  const { comType, entityType } = useParams();
  const [verificationData, setVerificationData] = useState(null);
  const [activeStep, setActiveStep] = useState(1);
  const [enterPIN, setEnterPIN] = useState("");
  const [confirmPIN, setConfirmPIN] = useState("");
  const { dispatch } = OnboardingContext();
  const navigate = useNavigate();

  const verifyEmail = async () => {
    const apiURl = API_URL;
    const url = `${apiURl}/${entityType}/${comType}/verify-link?token=${token}`;
    setShowLoader(true);

    const data = await axios.put(
      url,
      { token },
      {
        headers: {
          client_code: CLIENT_CODE,
        },
      }
    );
    const statusData = data.data;

    if (statusData.success) {
      setShowLoader(false);
      setVerificationData(statusData.data.result);
    }
  };

  useEffect(() => {
    verifyEmail();
  }, []);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const submitForm = async (values) => {
    setShowLoader(true);
    let userType = "";
    if (entityType === "client-user") {
      userType = ENTITY_TYPE_CLIENT;
    } else if (entityType === "partner-user") {
      userType = ENTITY_TYPE_PARTNER;
    }

    try {
      const data = await createPin(values, userType);
      const pinData = data.data;

      if (pinData.success) {
        setShowLoader(false);
        handleNext();
      }
    } catch (error) {
      setShowLoader(false);

      if (!error.response.data.error.success) {
        dispatch({
          type: "UPDATE_SNACKBAR_DATA",
          payload: {
            success: false,
            error: true,
            showMsg: true,
            message: error.response.data.error.message,
          },
        });
      }
    }
  };

  const handleSubmit = () => {
    if (enterPIN !== "" && confirmPIN !== "" && enterPIN === confirmPIN) {
      submitForm({
        userId: verificationData.userId,
        pin: parseInt(enterPIN),
      });
    } else {
      dispatch({
        type: "UPDATE_SNACKBAR_DATA",
        payload: {
          success: false,
          error: true,
          showMsg: true,
          message: "Invalid PIN or not matched!",
        },
      });
    }
  };

  return (
    <>
      {showLoder && <Loader />}
      <CustomizedSnackbars />
      <div className={classes.container}>
        <img className={classes.logo} src={Logo} alt="logo"></img>
        <div className={classes.contentBox}>
          <div className={classes.mainContent}>
            {activeStep === 1 && (
              <div style={{ marginTop: "10px" }}>
                <div style={{ marginTop: "50px" }}>
                  <Typography className={`${classes.text} ${classes.success}`}>
                    Verification
                  </Typography>
                  <Typography
                    className={`${classes.text} ${classes.successText}`}
                  >
                    {verificationData?.email === 1 ? (
                      <>
                        <Verified height={16} /> Email ID verified. <br />
                      </>
                    ) : (
                      <>
                        <Pending height={25} />
                        Email ID verification pending... <br />
                      </>
                    )}
                    {entityType !== ENTITY_TYPE_PARTNER ? (
                      verificationData?.sms === 1 ? (
                        <>
                          <Verified height={16} /> Mobile verified.
                          <br />
                        </>
                      ) : (
                        <>
                          <Pending height={25} />
                          Mobile verification pending... <br />
                        </>
                      )
                    ) : (
                      <></>
                    )}
                  </Typography>
                  {verificationData?.userType !== ENTITY_TYPE_PARTNER &&
                    verificationData?.sms === 1 &&
                    verificationData?.email === 1 && (
                      <>
                        <hr
                          className={classes.line}
                          style={{ marginTop: "70px" }}
                        ></hr>
                        <div className={classes.buttonContainer}>
                          <Button
                            variant="contained"
                            className={classes.button}
                            onClick={handleNext}
                          >
                            <span
                              className={`${classes.text} ${classes.buttonText}`}
                            >
                              Set PIN
                            </span>
                          </Button>
                        </div>
                      </>
                    )}
                </div>
              </div>
            )}
            {activeStep === 2 && (
              <>
                <Typography
                  className={`${classes.text} ${classes.onBoardingHeading}`}
                >
                  Set new pin
                </Typography>
                <div style={{ marginTop: "10px" }}>
                  <hr className={classes.line}></hr>
                  <div style={{ marginTop: "15px" }}>
                    <div style={{ textAlign: "center" }}>
                      <img src={Lock} alt="Lock" className={classes.image} />
                    </div>
                    <div style={{ marginLeft: "30%", marginBottom: "10%" }}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography className={classes.label}>
                            Enter Pin
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <EnterPINInput
                            handleChange={setEnterPIN}
                            autoFocus={true}
                          />
                        </Grid>
                      </Grid>
                      <Grid container style={{ marginTop: "20px" }}>
                        <Grid item xs={12}>
                          <Typography className={classes.label}>
                            Confirm Pin
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <EnterPINInput
                            handleChange={setConfirmPIN}
                            autoFocus={false}
                          />
                        </Grid>
                      </Grid>
                    </div>
                    <div className={classes.buttonContainer}>
                      <Button
                        variant="contained"
                        className={classes.button}
                        onClick={handleSubmit}
                      >
                        <span
                          className={`${classes.text} ${classes.buttonText}`}
                        >
                          Submit
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}
            {activeStep === 3 && (
              <>
                <Typography
                  className={`${classes.text} ${classes.onBoardingHeading}`}
                >
                  Awesome!!!
                </Typography>
                <div style={{ marginTop: "10px" }}>
                  <hr className={classes.line}></hr>
                  <div style={{ marginTop: "50px" }}>
                    <div style={{ textAlign: "center" }}>
                      <img
                        src={Done}
                        alt="OtpPassword"
                        style={{ width: "140px" }}
                      />
                    </div>
                    <Typography
                      className={`${classes.text} ${classes.success}`}
                    >
                      Thank You
                    </Typography>
                    <Typography
                      className={`${classes.text} ${classes.successText}`}
                    >
                      Your 4 digit pin is set successfully!
                    </Typography>

                    <hr
                      className={classes.line}
                      style={{ marginTop: "70px" }}
                    ></hr>
                    <div className={classes.buttonContainer}>
                      <Button
                        variant="contained"
                        className={classes.button}
                        onClick={() => navigate("/")}
                      >
                        <span
                          className={`${classes.text} ${classes.buttonText}`}
                        >
                          Go To Login Page
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default VerifyEmailMobile;
