import { useEffect, useState } from "react";
import "./ActiveInactiveToggle.css";
export const ActiveInactiveToggle = ({
  isActive,
  toggleIndex,
  handleChange,
  disabled,
}) => {
  const [active, setActive] = useState(false);
  console.log(isActive, "agljagj");

  useEffect(() => {
    if (isActive === 0) {
      setActive(true);
    }
    if (isActive === 1) {
      setActive(false);
    }

    if (isActive === "INACTIVE") {
      setActive(true);
    }
    if (isActive === "ACTIVE") {
      setActive(false);
    }
  }, []);

  const hadnleChange1 = (e) => {
    console.log(e.target.value, "lakgajkgj");
    handleChange(e.target.value);
    setActive((prev) => !prev);
  };
  return (
    <>
      <div
        className="btn-container"
        style={{
          cursor: "not-allowed",
          pointerEvents: disabled && disabled() == true ? "none" : "all",
          position: "relative",
        }}
      >
        {disabled && disabled() == true && (
          <div className="disabledLayer1"></div>
        )}
        <label className="switch btn-color-mode-switch">
          <input
            type="checkbox"
            name="color_mode"
            id={`color_mode_${toggleIndex}`}
            value={active}
            checked={active}
            onClick={hadnleChange1}
          />
          <label
            htmlFor={`color_mode_${toggleIndex}`}
            data-on="Inactive"
            data-off="Active"
            className="btn-color-mode-switch-inner"
          ></label>
        </label>
      </div>
      {/* <pre>{JSON.stringify(active)}</pre> */}
    </>
  );
};
