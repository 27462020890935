import { Typography } from "@material-ui/core";
import React from "react";
import useStyles from "../products/common/ProductsStyles";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";

function NewTableTitle({
  pageName,
  product,
  customMainHederClass,
  customWrapperStyle,
}) {
  const classes = useStyles();

  return (
    <div
      style={{
        display: "flex",
        gap: "5px",
        marginTop: "22px",
        alignItems: "center",
        marginBottom: "5px",
        flexWrap: "wrap",
        ...customWrapperStyle,
      }}
    >
      <Typography
        className={`${
          customMainHederClass ? customMainHederClass : classes.mainHeading
        }`}
      >
        {pageName}
      </Typography>
      {product && (
        <>
          <ArrowForwardIosRoundedIcon
            style={{ color: "rebeccapurple", height: "15px", marginTop: "4px" }}
          />
          <Typography className="subProdName">{product}</Typography>
        </>
      )}
    </div>
  );
}

export default NewTableTitle;
