import React from "react";
import TopBackComp from "../common/TopBackComp";
import CustomInput from "../../../common/CustomInput";
import "./uniqueClientIndex.css";
import { Typography } from "@material-ui/core";
import Step3Table from "../common/Step3Table";
import { useState } from "react";
import { useEffect } from "react";
import PopoverComp from "../common/PopoverComp";

function StrategyBuilderStep3({
  handleBack,
  customheader,
  tableData,
  selectedMemberList,
  handlePortFolioName,
  portFolioName,
  error,
  type,
}) {
  const [header, setHeader] = useState([]);
  const [table, setTable] = useState([]);

  useEffect(() => {
    setHeader(() => customheader);
    setTable(() => tableData);
    return () => {
      setTable(() => []);
      setHeader(() => []);
    };
  }, [tableData, customheader]);

  return (
    <div>
      <TopBackComp handleClick={handleBack} title={"Strategy Builder"} />
      <div className="step3TopContainer">
        <div className="step3TopContainerInputSec">
          <CustomInput
            handleChange={handlePortFolioName}
            portFolioName={portFolioName}
            fullWidth
            style={{
              background: "var(--searchBackground) !important",
              border: "1.164px solid var(--border)",
              // marginTop: "10px",
            }}
            label={`${type ? "Unique CLient Index" : "Target Portfolio"} name`}
            title={`${type ? "Unique CLient Index" : "Target Portfolio"} name`}
          />
          <Typography className="warningTxt error" style={{ left: "10px" }}>
            {error?.name}
          </Typography>
        </div>
        <div className="step3TopContainerInputSelectedSec">
          <Typography className="loginPageLabel">member name</Typography>
          {selectedMemberList}
          <Typography className="warningTxt error">{error?.members}</Typography>
        </div>
      </div>
      <div style={{ marginTop: "50px" }}>
        <Step3Table error={error} header={header} tableData={table} />
      </div>
      {(error?.benchMarkMissing || error?.totalAllocation) && (
        <div className="warningSecWrapper">
          <div className="warningSec">
            {error.benchMarkMissing ? (
              <p className="warningTxt">{error?.benchMarkMissing}</p>
            ) : error.totalAllocation ? (
              <p className="warningTxt">{error?.totalAllocation}</p>
            ) : (
              <p className="warningTxt">
                Total Allocation should not exceed 100%*
              </p>
            )}
          </div>
        </div>
      )}

      <PopoverComp />
    </div>
  );
}

export default StrategyBuilderStep3;
