import React from "react";
import "./insuranceTab.css";
import { useNavigate } from "react-router-dom";

const FileTabItem = ({ data, seleted, customHandleClick, index }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (data?.path) {
      navigate(data?.path, { replace: true });
    }
    // this customHandleClick function can be used for handling with state
    // in this case selection handled with pageName(params)
    customHandleClick({ slecetedIndex: index });
  };

  return (
    <div
      className={`fileTabItem ${
        index == seleted ? "fileTabSeleted" : "fileTabNotSelected"
      }`}
      onClick={handleClick}
    >
      <p className={`fileTxt ${index == seleted ? "fileTxtSelected" : ""}`}>
        {data?.title}
      </p>
    </div>
  );
};

function FileTabComp({ seleted, handleClick, data }) {
  return (
    <div className="fileTabWrapper">
      <div className="fileTabContainer" style={{ marginTop: "10px" }}>
        {data?.map((tab, i) => (
          <FileTabItem
            seleted={seleted}
            customHandleClick={handleClick}
            data={tab}
            key={i}
            index={i}
          />
        ))}
      </div>
      <div className="fileTabChildArea"></div>
    </div>
  );
}

export default FileTabComp;
