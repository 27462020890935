import React from 'react'
import useStyles from "../common/ProductsStyles";
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'

function PmsTransactionRedemption() {
    const classes = useStyles();

    return (
        <> 
                <Grid xs={12}>
                    <Table style={{marginTop:"2%"}} >
                        <TableHead style={{ background: "#1D1B36", borderRadius: "20px" }}>
                            <TableRow>
                                <TableCell align='left' style={{ borderBottom: "none" }} className={classes.tableHeadText}>Scheme Name</TableCell>
                                <TableCell align='right' style={{ borderBottom: "none" }} className={classes.tableHeadText}>Type</TableCell>
                                <TableCell align='right' style={{ borderBottom: "none" }} className={classes.tableHeadText}>Date</TableCell>
                                <TableCell align='right' style={{ borderBottom: "none" }} className={classes.tableHeadText}>Transaction Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell align='left' className={classes.tableBodyText}>1</TableCell>
                                <TableCell align='right' className={classes.tableBodyText}>1</TableCell>
                                <TableCell align='right' className={classes.tableBodyText}>1</TableCell>
                                <TableCell align='right' className={classes.tableBodyText}>1</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </>
    )
}

export default PmsTransactionRedemption