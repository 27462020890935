import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import EquitySubTab from "./EquitySubTab";
import "../common/ProductsStyles";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Modal,
  Button,
} from "@material-ui/core";
import Searchval from "../../../common/Searchval";
import { ReactComponent as Search } from "../../../../assets/icons/search.svg";
import useStyles from "../common/ProductsStyles";
import { ReactComponent as Updown } from "../../../../assets/icons/up-down-arrow.svg";
import LineChart from "../../../charts/LineChart";
import { myDebounce } from "../../../../hooks/constantFunction";
import moment from "moment";
import FilterCalendar from "../../../calendar/FilterCalendar";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import { OnboardingContext } from "../../../../Context";
import {
  fetchFundPerformance,
  fetch_all_funds,
  get_from_stockmaster,
} from "../../../../api";
import { dateFormatCommon, roundNumber } from "../../../utils/common";
import PageLoader from "../common/pageLoader";
import ApexNew from "../../../charts/ApexNew";

export default function EquityStockCompare() {
  const classes = useStyles();
  const { userState } = OnboardingContext();
  const [options, setOptions] = useState([]);
  const [Filteroptions, setFilterOptions] = useState([]);
  const [search, setSearch] = useState({
    search1: { value: "", id: "" },
    search: { value: "", id: "" },
    search2: { value: "", id: "" },
    search3: { value: "", id: "" },
  });
  const [selected, setSelected] = useState({
    search1: { value: "", id: "" },
    search: { value: "", id: "" },
    search2: { value: "", id: "" },
    search3: { value: "", id: "" },
  });

  const [lineData, setLineData] = useState([]);
  const [load, setLoad] = useState(true);
  const [chartload, setchartload] = useState(false);
  const [searchname, setSearchName] = useState("");
  const clientId = userState.loginedUserData.id;
  const { mfoFilter } = useContext(mfoFilterContext);
  const [dataRange, setDataRange] = useState({
    startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const [xval, setxval] = useState([]);
  const [range, setRange] = useState("1M");
  const lineColors = ["#F52D6A", "#82EBA1", "#FF9446", "#711CF1"];
  const [legends, setlegends] = useState([]);
  const [selectedsin, setSelectedsin] = useState([]);
  const [oldisin, setOldisis] = useState([]);
  const config = {
    enablePoints: true,
    curve: "linear",
    disablePointLabel: true,
    disableAxisBottom: true,
    pointSize: 0.1,
    nopoint: true,
    hide_legends: true,
    leftAxis: {
      legend: "Portfolio Return",
      renderTick: (tick) => {
        return (
          <g transform={`translate(${tick.x - 30},${tick.y})`}>
            {/* <rect x={-14} y={-6} rx={3} ry={3} width={28} height={24} fill="rgba(0, 0, 0, .05)" />
                        <rect x={-12} y={-12} rx={2} ry={2} width={24} height={24} fill="rgb(232, 193, 160)" /> */}
            {/* <line stroke="rgb(232, 193, 160)" strokeWidth={1.5} y1={-22} y2={-12} /> */}
            {/* <rect x={-40} y={-15} rx={2} ry={2} width={100} height={3} fill="rgba(31, 28, 64, 1)" /> */}
            <text
              textAnchor="middle"
              dominantBaseline="middle"
              style={{
                fill: "#b0aab7",
                fontSize: 10,
              }}
            >
              {tick.value}
            </text>
          </g>
        );
      },
    },
    extra_props: {
      enableSlices: "x",
      sliceTooltip: (data) => {
        // console.log(data);
        return (
          <div
            style={{
              padding: 7,
              color: "#FFFFFF",
              background: "#2A274F",
              borderRadius: "10px",
            }}
          >
            {/* <br /> */}
            <div
              className="subtext"
              style={{
                display: "flex",
                fontFamily: "Avenir",
                paddingLeft: "25px",
                paddingRight: "19px",
                lineHeight: "24px",
              }}
            >
              {/* <div style={{background:data.point.serieColor,width:"20px",height:"20px",borderRadius:"10px",marginRight:"5px"}}></div> */}
              {data.slice.points[0].data.x
                ? dateFormatCommon(data.slice.points[0].data.x, "YYYY-MM-DD")
                : ""}
            </div>
            {data.slice.points.map((d, i) => {
              return (
                <div
                  style={{
                    display: "flex",
                    fontFamily: "Avenir",
                    paddingLeft: "25px",
                    paddingRight: "19px",
                    lineHeight: "24px",
                  }}
                >
                  {/* <div style={{background:data.point.serieColor,width:"20px",height:"20px",borderRadius:"10px",marginRight:"5px"}}></div> */}
                  <div
                    style={{
                      width: "300px",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {d.data.id}
                  </div>{" "}
                  : {roundNumber(d?.data?.z)}{" "}
                </div>
              );
            })}
          </div>
        );
      },
    },
  };

  function areEqual(array1, array2) {
    if (array1.length === array2.length) {
      return array1.every((element, index) => {
        if (element === array2[index]) {
          return true;
        }

        return false;
      });
    }
  }
  useEffect(() => {
    // if (

    //   search.search.value === "" &&
    //   search.search1.value === "" &&
    //   search.search2.value === "" &&
    //   search.search3.value === ""
    // ) {
    let params = {
      limit: 300,
      offset: 0,
    };
    if (searchname) {
      // setLoad(true);

      params["companyName"] = searchname;
    }

    // console.log("api called");
    get_from_stockmaster(clientId, params, "COMPANY")
      .then((res) => {
        if (search) {
          setFilterOptions(res);

          if (options.length == 0) {
            setOptions(res);
          }
        } else {
          setOptions(res);
        }

        // let selectedisin = [
        //   res[0]?.isin,
        //   res[1]?.isin,
        //   res[2]?.isin,
        //   res[3]?.isin,
        // ];
        // fetch_stock_compare(selectedisin);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
      });
    // } else {
    //   let selectedisin = Object.keys(search).map((s) => {
    //     return search[s].id && search[s].id != "" ? search[s].id : undefined;
    //   });
    //   fetch_stock_compare(selectedisin);
    // searchFund();
  }, [searchname]);
  useEffect(() => {
    let complete = false;
    let isin = [];
    let count = 0;
    if (search) {
      Object.keys(search).forEach((s) => {
        if (search[s].id || search[s] != "") {
          isin.push(search[s].id);
          if (oldisin.length < isin.length) {
            complete = true;
          } else if (!complete && oldisin[count] != isin[count]) {
            complete = true;
          }
          count = count + 1;
        }
      });
    }
    let linedata = [];
    let legendss = [];
    if (complete) {
      setOldisis(isin);
      isin.forEach((isin, k) => {
        lineData.find((l, j) => {
          if (isin == l.isin) {
            linedata.push(l);

            legendss.push(legends[j]);
            return true;
          }
        });
      });
      //  lineData.filter((l) => {
      //   if (isin.includes(l.isin)) {
      //     return l;
      //   }
      // });
      // console.log(lineData, "line");

      setLineData(linedata);
      setlegends(legendss);
    }
  }, [search]);
  useEffect(() => {
    if (
      search.search.value === "" &&
      search.search1.value === "" &&
      search.search2.value === "" &&
      search.search3.value === ""
    ) {
      setLineData([]);
      setlegends([]);
      return;
    }

    let selectedisin = [];
    Object.keys(search).filter((s) => {
      if (search[s].id && search[s].id != "") {
        selectedisin.push(search[s].id);
      }
    });

    // if (areEqual(selectedsin, selectedisin)) {
    //   return;
    // }
    setSelectedsin(selectedisin);

    fetch_stock_compare(selectedisin);
  }, [selected, mfoFilter, dataRange]);

  async function fetch_stock_compare(selectedisin, isinmap) {
    if (selectedisin.length == 0) {
      return;
    }
    let params = {};
    if (Object.keys(mfoFilter.checkData).length > 0) {
      params["memberId"] = Object.keys(mfoFilter.checkData).join(",");
    }
    if (Object.keys(mfoFilter.advisor).length > 0) {
      params["advisor"] = Object.keys(mfoFilter.advisor).join(",");
    }
    setLoad(true);

    const isin = selectedisin.filter((isin) => isin);
    try {
      const responses = await fetchFundPerformance(
        clientId,
        { ...dataRange, ...params, isin: isin.join(",") },
        { type: "equity" }
      );

      let current = [];
      let legendss = [];
      let result = responses.data.data.result.map((res) => {
        legendss.push(res.fundName);
        let data = res.graphData.map((r, i) => {
          return [
            // id:
            //   res.fundName.length > 30
            //     ? res.fundName.substring(0, 30) + "..."
            //     : res.fundName,
            r.date,
            parseInt(r.rebaseNav),
            r.fundNav,
          ];
        });
        return {
          name:
            res.fundName.length > 30
              ? res.fundName.substring(0, 30) + "..."
              : res.fundName,
          // color: "hsl(240, 70%, 50%)",
          isin: res.isin,
          data: data ? data : [],
        };
      });
      setlegends(legendss);
      result = result.filter((item) => item);
      setLineData(result);
      setchartload(result?.length === 0 ? true : false);
    } catch (error) {
      setLoad(false);
    }
    // setCurrentList(currentList);
    setLoad(false);
  }

  const [customRange, setCustomRange] = useState([null, null]);
  function generateDates(dateStart, type, count) {
    var dateEnd = moment();

    var timeValues = [];

    while (
      dateEnd > dateStart ||
      dateStart.format("DD-MM-YYYY") === dateEnd.format("DD-MM-YYYY")
    ) {
      timeValues.push(dateStart.format("YYYY-MM-DD"));
      dateStart.add(count, type);
    }

    setxval(timeValues);
  }

  return (
    <div>
      <EquitySubTab title="Stock Comparison"></EquitySubTab>
      <div
        style={{
          background: "#220848",
          boxShadow:
            "-6.22302px -6.22302px 18.6691px #302A64, 6.22302px 6.22302px 18.6691px #000000",
          borderRadius: "124.46px",
        }}
      ></div>
      {/* <div style={{ marginTop: "3%" }}
        className={classes.tableContainerAlign}>
      
      </div> */}
      <div
        style={{
          marginTop: "2.5%",
          boxShadow:
            "-4px 4px 6px #000000, 4px -4px 10px rgba(89, 22, 189, 0.5), inset -4px 4px 20px #000000, inset 3px -4px 10px #5916BD",
          borderRadius: "20px",
          padding: "15px 25px",
          marginLeft: "2%",
          marginRight: "2%",
        }}
      >
        {/* <input
        id="auto"
        onClick={() => setDisplay(!display)}
        placeholder="Type to search"
        value={search}
        onChange={event => {setSearch(event.target.value)
          myDebounceSearch(event.target.value)
          }}
      /> */}

        <div>
          <div
            className="w451"
            style={{
              marginRight: "auto",
              background: "#220848",
              marginLeft: "43px",
              marginTop: "54px",
              boxShadow: "-4px -4px 10px #431D84, 4px 4px 10px #000000",
              paddingRight: "5px",
              borderRadius: "124.46px",
              padding: "5px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              onClick={() => {
                setRange("1M");
                setCustomRange([null, null]);
                generateDates(moment().subtract(1, "months"), "days", 1);

                setDataRange({
                  endDate: moment().format("YYYY-MM-DD"),
                  startDate: moment()
                    .subtract(1, "months")
                    .format("YYYY-MM-DD"),
                });
              }}
              style={{ textDecoration: "none", minWidth: "13%" }}
              to="#"
            >
              <div
                className={`${classes.bottomTabHeaderText} ${
                  range === "1M" ? classes.bottomTabActive : ""
                }`}
                style={{ margin: "0px" }}
              >
                1M
              </div>
            </Button>
            <Button
              onClick={() => {
                setRange("3M");
                setCustomRange([null, null]);
                generateDates(moment().subtract(3, "months"), "days", 3);

                setDataRange({
                  endDate: moment().format("YYYY-MM-DD"),
                  startDate: moment()
                    .subtract(3, "months")
                    .format("YYYY-MM-DD"),
                });
              }}
              style={{ textDecoration: "none", minWidth: "13%" }}
              to="#"
            >
              <div
                className={`${classes.bottomTabHeaderText} ${
                  range === "3M" ? classes.bottomTabActive : ""
                }`}
                style={{ margin: "0px" }}
              >
                3M
              </div>
            </Button>
            <Button
              onClick={() => {
                setRange("1Y");
                setCustomRange([null, null]);
                generateDates(moment().subtract(1, "years"), "days", 7);

                setDataRange({
                  endDate: moment().format("YYYY-MM-DD"),
                  startDate: moment().subtract(1, "years").format("YYYY-MM-DD"),
                });
              }}
              style={{ textDecoration: "none", minWidth: "13%" }}
              to="#"
            >
              <div
                className={`${classes.bottomTabHeaderText} ${
                  range === "1Y" ? classes.bottomTabActive : ""
                }`}
                style={{ margin: "0px" }}
              >
                1Y
              </div>
            </Button>
            <Button
              onClick={() => {
                setRange("3Y");
                setCustomRange([null, null]);
                generateDates(moment().subtract(3, "years"), "days", 14);

                setDataRange({
                  endDate: moment().format("YYYY-MM-DD"),
                  startDate: moment().subtract(3, "years").format("YYYY-MM-DD"),
                });
              }}
              style={{ textDecoration: "none", minWidth: "13%" }}
              to="#"
            >
              <div
                className={`${classes.bottomTabHeaderText} ${
                  range === "3Y" ? classes.bottomTabActive : ""
                }`}
                style={{ margin: "0px" }}
              >
                3Y
              </div>
            </Button>
            <Button
              onClick={() => {
                setRange("5Y");
                setCustomRange([null, null]);
                generateDates(moment().subtract(5, "years"), "months", 1);

                setDataRange({
                  endDate: moment().format("YYYY-MM-DD"),
                  startDate: moment().subtract(5, "years").format("YYYY-MM-DD"),
                });
              }}
              style={{ textDecoration: "none", minWidth: "13%" }}
              to="#"
            >
              <div
                className={`${classes.bottomTabHeaderText} ${
                  range === "5Y" ? classes.bottomTabActive : ""
                }`}
                style={{ margin: "0px" }}
              >
                5Y
              </div>
            </Button>
            <Button
              onClick={() => {
                setRange("Inception Till Date");
                setDataRange();
                setCustomRange([null, null]);
              }}
              style={{ textDecoration: "none", minWidth: "13%" }}
              to="#"
            >
              <div
                className={`${classes.bottomTabHeaderText} ${
                  range === "Inception Till Date" ? classes.bottomTabActive : ""
                }`}
                style={{ margin: "0px", width: "124px" }}
              >
                Inception Till Date
              </div>
            </Button>
            {/* <Button style={{ textDecoration: 'none', textTransform: "capitalize", minWidth: '13%' }} to='#'>
                <div
                  className={`${classes.bottomTabHeaderText} ${range === "custom" ? classes.bottomTabActive : ""}`}
                  style={{ margin: '0px' }}>
                  <FilterCalendar
                    values={customRange}
                    customLabel='Custom'
                    customStyle={{
                      width: "100%",
                      textAlign: "center",
                      borderRadius: "20px",
                      padding: "0px",
                      background: "transparent",
                      border: 'none',
                      color: "#FFFFFF",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontFamily: "Avenir",
                      fontWeight: "500",
                      cursor: 'pointer',
                    }}
                    customCalenderPostion={{
                      right: "0px",
                      top: "55px"
                    }}
                    onchange={(newCustomRange) => {
                      setCustomRange(newCustomRange)
                      setRange("custom")
                      setDataRange({ startDate: moment(newCustomRange[0]).format('YYYY-MM-DD'), endDate: moment(newCustomRange[1]).format('YYYY-MM-DD') })
                    }} />
                </div>
              </Button> */}
          </div>
          <div
            style={{
              width: "95%",
              margin: "2%",
              cursor: "pointer",
              alignItems: "center",
            }}
            className={classes.tabMfo}
          >
            {/* <div className='sch'>
        <input  onClick={(e) =>{
         
          setDisplay(!display)}} value={search}  onChange={event =>{
           myDebounceSearch(event.target.value)
          setSearch(event.target.value)}}></input>
        <Search style={{color: '#949BA5', verticalAlign:'middle',paddingRight:"20px"}} />
        {display && (
        <div className="autContainer">
          {options
            .filter(({ name }) => name.indexOf(search.toLowerCase()) > -1)
            .map((value, i) => {
              return (
                <div
                  onClick={() => updateselected(value.name,setSearch)}
                  className="opt"  
                  key={i}
                  tabIndex="0"
                >
                  <span>{value.name}</span>
                 
                </div>
              );
            })}
        </div>
      )}
      </div> */}
            <Searchval
              search={search.search.value}
              setSearchName={setSearchName}
              allval={search}
              setSearch={{ setSearch: setSearch, txt: "search" }}
              options={options}
              option={{ id: "isin", name: "CompanyName" }}
              setOptions={setFilterOptions}
              options2={Filteroptions}
              setSelect={setSelected}
              selected={selected}
            ></Searchval>

            <Searchval
              search={search.search1.value}
              allval={search}
              setSearch={{ setSearch: setSearch, txt: "search1" }}
              setSearchName={setSearchName}
              options={options}
              option={{ id: "isin", name: "CompanyName" }}
              setOptions={setFilterOptions}
              options2={Filteroptions}
              setSelect={setSelected}
              selected={selected}
            ></Searchval>
            <Searchval
              search={search.search2.value}
              allval={search}
              setSearch={{ setSearch: setSearch, txt: "search2" }}
              setSearchName={setSearchName}
              options={options}
              option={{ id: "isin", name: "CompanyName" }}
              setOptions={setFilterOptions}
              options2={Filteroptions}
              setSelect={setSelected}
              selected={selected}
            ></Searchval>
            <Searchval
              search={search.search3.value}
              allval={search}
              setSearch={{ setSearch: setSearch, txt: "search3" }}
              setSearchName={setSearchName}
              options={options}
              option={{ id: "isin", name: "CompanyName" }}
              setOptions={setFilterOptions}
              options2={Filteroptions}
              setSelect={setSelected}
              selected={selected}
            ></Searchval>
          </div>
        </div>
        <div style={{ marginTop: "3%" }}>
          {lineData && lineData.length !== 0 ? (
            // <LineChart
            //   config={config}
            //   colors={[" #82EBA1", "#711CF1", " #FC5555", "#FF9446"]}
            //   data={lineData}
            //   xval={xval}
            // ></LineChart>
            <ApexNew
              linedata={lineData}
              title={{ y: "Portfolio Return", X: " " }}
              controller_disabled={true}
              legends_hide={true}
              config={{
                colors: lineColors,
                // [" #ff0000", "#711CF1", " #FC5555", "#FF9446"],
              }}
            />
          ) : (
            ""
          )}
          {lineData && (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                marginTop: "20px",
                marginBottom: "3%",
                marginRight: "3%",
              }}
            >
              {legends?.map((l, i) => {
                return (
                  <div style={{ display: "flex" }} className="outer-tooltip">
                    <div className={classes.graphBulletBonds}>
                      <svg height="25" width="25">
                        <circle
                          cx="12"
                          cy="12"
                          r="5"
                          stroke-width="3"
                          fill={lineColors[i]}
                        />
                        Sorry, your browser does not support inline SVG.
                      </svg>
                    </div>
                    <div className={`${classes.graphLegends}`}>
                      {l.length > 30 ? l.substring(0, 30) + "..." : l}
                    </div>
                    <div className=" inner-tooltip tooltip-box">
                      <p className="tooltip-text">{l}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <PageLoader showLoader={load} />
      </div>
    </div>
  );
}
