import { OTP_LENGTH } from "../Constants";

export const createPartnerValidate = (values) => {
  const errors = {};

  if (!values?.name) {
    errors.name = "Name is required";
  } else if (!/^[aA-zZ\s]+$/.test(values?.name)) {
    errors.name = "Only alphabets are allowed";
  } else if (!/^\s*\S[\s\S]*$/.test(values?.name)) {
    errors.name = "Name is required";
  }
  if (!values?.partner_code) {
    errors.partner_code = "Partner code is required";
  } else if (!/^[a-zA-Z0-9]+$/.test(values?.partner_code)) {
    errors.partner_code = "Only alphanumric are allowed";
  } else if (!/^\s*\S[\s\S]*$/.test(values?.partner_code)) {
    errors.partner_code = "Partner code is required";
  }

  return errors;
};

export const createPartnerRepresenativeValidate = (values) => {
  const errors = {};

  if (!values?.representativeName) {
    errors.representativeName = "Representative name is required";
  } else if (!/^[aA-zZ\s]+$/.test(values?.representativeName)) {
    errors.representativeName = "Only alphabets are allowed";
  } else if (!/^\s*\S[\s\S]*$/.test(values?.representativeName)) {
    errors.representativeName = "Representative name is required";
  }

  if (!values?.email) {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values?.email)) {
    errors.email = "Enter valid email";
  }
  if (!values?.mobile) {
    errors.mobile = "Mobile is required!";
  } else if (!/^\d{10}$/.test(values?.mobile)) {
    errors.mobile = "Invalid mobile!";
  }

  // if (!values?.pan) {
  //   errors.pan = "PAN is required!";
  // } else if (!/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(values?.pan)) {
  //   errors.pan = "Invalid PAN!";
  // }

  // if (!values?.dob) {
  //   errors.dob = "DOB is required!";
  // }

  return errors;
};
export const createPartnervalidate = (values) => {
  const errors = {};

  if (!values?.entityName) {
    errors.entityName = "Entity name is required";
  } else if (!/^[aA-zZ\s]+$/.test(values?.entityName)) {
    errors.entityName = "Only alphabets are allowed";
  } else if (!/^\s*\S[\s\S]*$/.test(values?.entityName)) {
    errors.entityName = "Entity name is required";
  }
  if (!values?.entityType) {
    errors.entityType = "Entity Type is required";
  }
  // if (!values?.email) {
  //   errors.email = "Email is required";
  // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values?.email)) {
  //   errors.email = "Enter valid email";
  // }
  // if(!values?.mobile){
  //     errors.mobile = 'Mobile is required!';
  // }else if(!/^\d{10}$/.test(values?.mobile)){
  //     errors.mobile = 'Invalid mobile!'
  // }

  // if (!values?.pan) {
  //   errors.pan = "PAN is required!";
  // } else if (!/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(values?.pan)) {
  //   errors.pan = "Invalid PAN!";
  // }

  // if (!values?.dob) {
  //   if (values.entityType === "sole_proprietor") {
  //     errors.dob = "DOB is required!";
  //   } else {
  //     errors.dob = "Date of Incorporation is required!";
  //   }
  // }

  return errors;
};
export const createMembervalidate = (values) => {
  const errors = {};

  if (!values?.full_name) {
    errors.full_name = "Full name is required";
  } else if (!/^[aA-zZ\s]+$/.test(values?.full_name)) {
    errors.full_name = "Only alphabets are allowed";
  } else if (!/^\s*\S[\s\S]*$/.test(values?.full_name)) {
    errors.full_name = "Full name is required";
  }
  if (!values?.member_type) {
    errors.member_type = "Member Type is required";
  }
  if (!values?.email) {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values?.email)) {
    errors.email = "Enter valid email";
  }
  if (!values?.mobile) {
    errors.mobile = "Mobile is required!";
  } else if (!/^\d{10}$/.test(values?.mobile)) {
    errors.mobile = "Invalid mobile!";
  }

  if (!values?.pan) {
    errors.pan = "PAN is required!";
  } else if (!/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(values?.pan)) {
    errors.pan = "Invalid PAN!";
  }

  if (!values?.dob) {
    errors.dob = "DOB is required!";
  }

  return errors;
};

export const liasonOfficerValidate = (values) => {
  const errors = {};

  if (!values?.name) {
    errors.name = "Name is required";
  } else if (!/^[aA-zZ\s]+$/.test(values?.name)) {
    errors.name = "Only alphabets are allowed";
  } else if (!/^\s*\S[\s\S]*$/.test(values?.name)) {
    errors.name = "Name is required";
  }

  if (!values?.email) {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values?.email)) {
    errors.email = "Enter valid email";
  }
  if (!values?.mobile) {
    errors.mobile = "Mobile is required!";
  } else if (!/^\d{10}$/.test(values?.mobile)) {
    errors.mobile = "Invalid mobile!";
  }

  return errors;
};

export const otpValidate = (otp) => {
  let error = false;
  if (!otp || otp.length < OTP_LENGTH) {
    error = true;
  }

  return error;
};

export const validatePartner = (values) => {
  const errors = {};

  // if (!values.doi) {
  //   errors.doi = "Date of incorporation is required!";
  // }
  // if (!values.commencementDate || values.commencementDate === "NA") {
  //   errors.commencementDate = "Commencement date is required!";
  // }
  // if (!values.kycDate) {
  //   errors.kycDate = "kyc date is required!";
  // }
  // if (!values.pancard) {
  //   errors.pancard = "Pan file is required!";
  // }

  // if (!values.idProof) {
  //   errors.idProof = "Id proof is required!";
  // }

  // if (!values.address) {
  //   errors.address = "Address is required!";
  // }

  return errors;
};

export const validateOnboardUser = (values) => {
  const errors = {};

  // if (!values.kycDate) {
  //   errors.kycDate = "kyc date is required!";
  // }
  // if (!values.pancard) {
  //   errors.pancard = "Pan file is required!";
  // }

  // if (!values.idProof) {
  //   errors.idProof = "Id proof is required!";
  // }

  return errors;
};

export const uploadSampleFileValidate = (values, fieldname = "", page) => {
  const errors = {};

  if (fieldname) {
    if (!values[fieldname]) {
      errors[fieldname] = "client is required!";
    }
  } else {
    // DONT CHECK FOR CLIENT ID IF BULK UPLOAD IS SELECTED
    if (page !== "Bulk Upload" && !values.clientId) {
      errors.clientId = "Client is required!";
    }
    if (!values.entity) {
      errors.entity = "Product is required!";
    }

    if (!values.fileType) {
      errors.fileType = "File type is required!";
    }

    if (!values.file?.name) {
      errors.file = "File is required!";
    } else {
      const type = values.file?.name.split(".")[1];
      if (type !== "xlsx" && type !== "csv") {
        errors.file = "Only csv and xlsx allowed!";
      }
    }
  }

  return errors;
};
export const uploadPDFFileValidate = (values, fieldname = "") => {
  const errors = {};

  // DONT CHECK FOR CLIENT ID IF BULK UPLOAD IS SELECTED

  if (!values.file?.name) {
    errors.file = "File is required!";
  } else {
    const type = values.file?.name.split(".")[1];
    if (type !== "pdf") {
      errors.file = "Only pdf file is allowed!";
    }
  }

  return errors;
};
export const downloadSampleFileValidate = (values, fieldname = "") => {
  const errors = {};

  if (!values.entity) {
    errors.entity = "Product is required!";
  }

  if (!values.fileType) {
    errors.fileType = "File type is required!";
  }

  return errors;
};

export const uploadSampleFileValidateSchemeMaster = (values) => {
  const errors = {};

  if (!values.file?.name) {
    errors.file = "File is required!";
  } else {
    const type = values.file?.name.split(".")[1];
    if (type !== "xlsx" && type !== "csv") {
      errors.file = "Only csv and xlsx allowed!";
    }
  }
};
export const addSchemeMasterProduct = (values) => {
  const errors = {};

  // if (!values?.neoProductName) {
  //   errors.neoProductName = "Neo Product Name is required";
  // }

  if (!values?.status) {
    errors.status = "Status is required";
  }

  if (
    values.master === "mf" ||
    values.master === "aif" ||
    values.master === "pms"
  ) {
    if (!values?.instrumentType) {
      errors.instrumentType = "Company Name is required";
    }
    if (!values?.isin) {
      errors.isin = "ISIN is required";
    }
    if (!values?.schemeName) {
      errors.schemeName = "Scheme Name is required";
    }
    if (!values?.fundName) {
      errors.fundName = "Fund Name is required";
    }

    if (!values?.assetClass) {
      errors.assetClass = "Asset Class is required!";
    }

    if (!values?.productSubCategory) {
      errors.productSubCategory = "Product Sub Category is required!";
    }
    if (!values?.investmentOption) {
      errors.investmentOption = "Investment Option is required!";
    }
    if (!values?.planType) {
      errors.planType = "Plan Type is required!";
    }
    if (values.master === "mf") {
      if (!values?.broadCategory) {
        errors.broadCategory = "Broad Category is required!";
      }
      // if (!values?.taxClassification) {
      //   errors.taxClassification = "TAX Classification is required!";
      // }
      if (!values?.benchmarkIndexName) {
        errors.benchmarkIndexName = "Primary Prospectus Benchmark is required!";
      }
      // if (!values?.rtaCode) {
      //   errors.rtaCode = "RTA Code is required";
      // }
      if (!values?.etf) {
        errors.etf = "ETF Code is required";
      }
      if (!values?.mf_status) {
        errors.mf_status = "MF Status Code is required";
      }
      if (!values?.amfiCode) {
        errors.amfiCode = "AMFI Code is required!";
      }
    }

    if (values.master === "aif") {
      if (!values?.broadCategory) {
        errors.broadCategory = "Broad Category is required!";
      }
      if (!values?.amc) {
        errors.amc = "AMC is required!";
      }
      // if (!values?.primaryProspectusName) {
      //   errors.primaryProspectusName = "AMC is required!";
      // }
      if (!values?.class) {
        errors.class = "AMC is required!";
      }
      if (!values?.aifFundType) {
        errors.aifFundType = "AMC is required!";
      }
      // if (!values?.managementFees) {
      //   errors.managementFees = "AMC is required!";
      // }
      // if (!values?.hurdleRate) {
      //   errors.hurdleRate = "AMC is required!";
      // }
      // if (!values?.performanceFees) {
      //   errors.performanceFees = "AMC is required!";
      // }
    }
    if (values.master === "pms") {
      if (!values?.productCategory) {
        errors.productCategory = "AMC is required!";
      }
    }
  }

  if (
    values.master === "bonds" ||
    values.master === "structured" ||
    values.master === "icd"
  ) {
    if (values.master === "structured") {
      if (!values?.frequencyOfInterestPayment) {
        errors.frequencyOfInterestPayment =
          "Frequency of the Interest Payment is required!";
      }
      if (!values?.fundName) {
        errors.fundName = "companyName is required";
      }
      if (!values?.schemeName) {
        errors.schemeName = "companyName is required";
      }
      if (!values?.totalIssueSize) {
        errors.totalIssueSize = "AMFI Code is required!";
      }

      if (!values?.putCallOption) {
        errors.putCallOption = "Investment Option is required!";
      }
      if (!values?.issuePrice) {
        errors.issuePrice = "Investment Option is required!";
      }
      if (!values?.dateOfAllotment) {
        errors.dateOfAllotment = "Investment Option is required!";
      }
    }
    if (!values?.isin) {
      errors.isin = "ISIN is required";
    }
    if (!values?.companyName) {
      errors.companyName = "companyName is required";
    }
    if (!values?.maturityDate) {
      errors.maturityDate = "Frequency of the Interest Payment is required!";
    }
    if (!values?.assetClass) {
      errors.assetClass = "Asset Class is required!";
    }
    if (!values?.faceValue) {
      errors.faceValue = "Investment Option is required!";
    }
    if (!values?.couponRate) {
      if (values?.couponRate !== 0) {
        errors.couponRate = "couponRate is required";
      }
    }
    if (!values?.instrumentType) {
      errors.instrumentType = "Company Name is required";
    }
    if (!values?.productSubCategory) {
      errors.productSubCategory = "Product Sub Category is required!";
    }
    if (values.master === "bonds" || values.master === "icd") {
      if (values.master === "bonds") {
        if (!values?.interestPaymentDateType) {
          errors.interestPaymentDateType =
            "interestPaymentDateType is required";
        } else {
          if (values.interestPaymentDateType === "uneven") {
            if (!values?.interestPayoutDate) {
              errors.interestPayoutDate = "interestPayoutDate is required";
            }
          }
        }
        if (!values?.onMaturity) {
          errors.onMaturity = "onMaturity is required";
        }
      }
      if (values.master === "icd") {
        if (!values?.interestPaymentDateType) {
          errors.interestPaymentDateType =
            "interestPaymentDateType is required";
        } else {
          if (values.interestPaymentDateType === "uneven") {
            if (!values?.interestPayoutDate) {
              errors.interestPayoutDate = "interestPayoutDate is required";
            }
          }
        }
      }
      // if (!values?.redemption) {
      //   errors.redemption = "Investment Option is required!";
      // }
      // if (!values?.creditRatingAgency) {
      //   errors.creditRatingAgency = "Broad Category is required!";
      // }
      if (!values?.creditRating) {
        errors.creditRating = "Fund Name is required";
      }
      if (!values?.isListed) {
        errors.isListed = "Frequency of the Interest Payment is required!";
      }
      if (!values?.frequencyOfThenInterestPayment) {
        errors.frequencyOfThenInterestPayment =
          "Frequency of the Interest Payment is required!";
      }
      if (!values?.bseGroup) {
        errors.bseGroup = "Fund Name is required";
      }
      if (!values?.bseCode) {
        errors.bseCode = "Fund Name is required";
      }
      if (!values?.bseScripName) {
        errors.bseScripName = "Fund Name is required";
      }
      if (!values?.bseListDate) {
        errors.bseListDate = "Fund Name is required";
      }
      if (!values?.nseSymbol) {
        errors.nseSymbol = "Fund Name is required";
      }
      if (!values?.nseSecName) {
        errors.nseSecName = "Fund Name is required";
      }
      if (!values?.nseListDate) {
        errors.nseListDate = "Fund Name is required";
      }
      if (!values?.tenure) {
        errors.tenure = "Fund Name is required";
      }
      if (!values?.bondType) {
        errors.bondType = "Fund Name is required";
      }
      if (!values?.lastInterestDue) {
        errors.lastInterestDue = "Fund Name is required";
      }
      if (!values?.startDate) {
        errors.startDate = "Fund Name is required";
      }
      // if (!values?.NoOfBlocksOfPrincipalReductions) {
      //   errors.NoOfBlocksOfPrincipalReductions = "Fund Name is required";
      // }
      // if (!values?.NoOfBlocksOfInterestReductions) {
      //   errors.NoOfBlocksOfInterestReductions = "Fund Name is required";
      // }
      if (!values?.cashflowType) {
        errors.cashflowType = "Fund Name is required";
      }
    }
  }

  if (values.master === "re") {
    if (!values?.projectCode) {
      errors.projectCode = "projectCode is required";
    }
    if (!values?.projectName) {
      errors.projectName = "projectName is required";
    }
    if (!values?.productSubCategory) {
      errors.productSubCategory = "productSubCategory is required";
    }
    if (!values?.address) {
      errors.address = "address is required!";
    }
    if (!values?.city) {
      errors.city = "City is required!";
    }
    if (!values?.state) {
      errors.state = "State is required!";
    }
    if (!values?.country) {
      errors.country = "Country is required!";
    }
  }

  // if (values.master === "equity") {
  //   if (!values?.companyName) {
  //     errors.companyName = "Company Name is required";
  //   }
  //   if (!values?.instrumentType) {
  //     errors.instrumentType = "Company Name is required";
  //   }
  // }

  if (values.master === "private") {
    if (!values?.stockSymbol) {
      errors.stockSymbol = "Company Name is required";
    }
    if (!values?.scripName) {
      errors.scripName = "Company Name is required";
    }
    if (!values?.sector) {
      errors.sector = "Company Name is required";
    }
    if (!values?.assetClass) {
      errors.assetClass = "Company Name is required";
    }
    if (!values?.productSubCategory) {
      errors.productSubCategory = "Company Name is required";
    }
    if (!values?.instrumentType) {
      errors.instrumentType = "Company Name is required";
    }
  }

  if (values.master === "mld") {
    if (!values?.isin) {
      errors.isin = "ISIN is required";
    }
    if (!values?.desNotes) {
      errors.desNotes = "Company Name is required";
    }
    if (!values?.amountIssued) {
      errors.amountIssued = "Company Name is required";
    }
    if (!values?.maturityDate) {
      errors.maturityDate = "Company Name is required";
    }
    if (!values?.volume) {
      errors.volume = "Company Name is required";
    }
    if (!values?.yieldToMaturity) {
      errors.yieldToMaturity = "Company Name is required";
    }
    if (!values?.bidPrice) {
      errors.bidPrice = "Company Name is required";
    }
    if (!values?.askPrice) {
      errors.askPrice = "Company Name is required";
    }
    if (!values?.securityName) {
      errors.securityName = "Company Name is required";
    }
    if (!values?.ticker) {
      errors.ticker = "Company Name is required";
    }
    if (!values?.assetType) {
      errors.assetType = "ISIN is required";
    }
    if (!values?.marketIssue) {
      errors.marketIssue = "Company Name is required";
    }
    if (!values?.couponRate) {
      if (values?.couponRate !== 0) {
        errors.couponRate = "couponRate is required";
      }
    }
    if (!values?.currency) {
      errors.currency = "Company Name is required";
    }
    if (!values?.currentYield) {
      errors.currentYield = "Company Name is required";
    }

    if (!values?.issuerName) {
      errors.issuerName = "Company Name is required";
    }
    if (!values?.amountOut) {
      errors.amountOut = "Company Name is required";
    }
    if (!values?.rating) {
      errors.rating = "Company Name is required";
    }
    if (!values?.minimumIncrement) {
      errors.minimumIncrement = "Company Name is required";
    }
    if (!values?.issueDate) {
      errors.issueDate = "Company Name is required";
    }
    if (!values?.faceValue) {
      errors.faceValue = "faceValue is required!";
    }
  }

  if (values.master === "reits") {
    if (!values?.isin) {
      errors.isin = "ISIN is required";
    }
    if (!values?.instrumentType) {
      errors.instrumentType = "Company Name is required";
    }
    if (!values?.assetType) {
      errors.assetType = "ISIN is required";
    }
    if (!values?.amountIssued) {
      errors.amountIssued = "Company Name is required";
    }
    if (!values?.maturityDate) {
      errors.maturityDate = "Company Name is required";
    }

    if (!values?.yieldToMaturity) {
      errors.yieldToMaturity = "Company Name is required";
    }
    if (!values?.bidPrice) {
      errors.bidPrice = "Company Name is required";
    }
    if (!values?.askPrice) {
      errors.askPrice = "Company Name is required";
    }
    if (!values?.securityName) {
      errors.securityName = "Company Name is required";
    }
    if (!values?.ticker) {
      errors.ticker = "Company Name is required";
    }
    if (!values?.marketIssue) {
      errors.marketIssue = "Company Name is required";
    }
    if (!values?.couponRate) {
      if (values?.couponRate !== 0) {
        errors.couponRate = "couponRate is required";
      }
    }
    if (!values?.currency) {
      errors.currency = "Company Name is required";
    }
    if (!values?.currentYield) {
      errors.currentYield = "Company Name is required";
    }
    if (!values?.issuerName) {
      errors.issuerName = "Company Name is required";
    }
    if (!values?.amountOut) {
      errors.amountOut = "Company Name is required";
    }
    if (!values?.minimumIncrement) {
      errors.minimumIncrement = "Company Name is required";
    }
    if (!values?.issueDate) {
      errors.issueDate = "Company Name is required";
    }
  }
  if (values.master === "invits") {
    if (!values?.isin) {
      errors.isin = "ISIN is required";
    }
    if (!values?.instrumentType) {
      errors.instrumentType = "Company Name is required";
    }
    if (!values?.assetType) {
      errors.assetType = "ISIN is required";
    }
    if (!values?.amountIssued) {
      errors.amountIssued = "Company Name is required";
    }
    if (!values?.maturityDate) {
      errors.maturityDate = "Company Name is required";
    }

    if (!values?.yieldToMaturity) {
      errors.yieldToMaturity = "Company Name is required";
    }
    if (!values?.bidPrice) {
      errors.bidPrice = "Company Name is required";
    }
    if (!values?.askPrice) {
      errors.askPrice = "Company Name is required";
    }
    if (!values?.securityName) {
      errors.securityName = "Company Name is required";
    }
    if (!values?.ticker) {
      errors.ticker = "Company Name is required";
    }
    if (!values?.marketIssue) {
      errors.marketIssue = "Company Name is required";
    }
    if (!values?.couponRate) {
      if (values?.couponRate !== 0) {
        errors.couponRate = "couponRate is required";
      }
    }
    if (!values?.currency) {
      errors.currency = "Company Name is required";
    }
    if (!values?.currentYield) {
      errors.currentYield = "Company Name is required";
    }
    if (!values?.issuerName) {
      errors.issuerName = "Company Name is required";
    }
    if (!values?.amountOut) {
      errors.amountOut = "Company Name is required";
    }
    if (!values?.minimumIncrement) {
      errors.minimumIncrement = "Company Name is required";
    }
    if (!values?.issueDate) {
      errors.issueDate = "Company Name is required";
    }
  }
  if (values.master === "fd" || values.master === "rd") {
    if (!values?.isin) {
      errors.isin = "ISIN is required";
    }
    if (!values?.interestRate) {
      errors.interestRate = "interestRate is required";
    }
    if (!values?.bankName) {
      errors.bankName = "bankName is required";
    }
    if (!values?.accountType) {
      errors.accountType = "accountType is required";
    }
    if (!values?.interestRepaymentType) {
      errors.interestRepaymentType = "interestRepaymentType is required";
    }
    if (!values?.maturityDate) {
      errors.maturityDate = "maturityDate is required";
    }

    if (!values?.interestRepaymentFrequency) {
      errors.interestRepaymentFrequency =
        "interestRepaymentFrequency is required";
    }
    if (!values?.startDate) {
      errors.startDate = "startDate is required";
    }
    if (values.master === "fd") {
      if (!values?.assetClass) {
        errors.assetClass = "assetClass is required";
      }
    }
    // if (values.master === "rd") {
    //   if (!values?.amount) {
    //     errors.amount = "amount is required";
    //   }
    // }
  }

  return errors;
};
