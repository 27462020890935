import React from "react";
import { Link } from "react-router-dom";
import useStyles from "./ProductsStyles";

function TabHeader() {
  const classes = useStyles();
  return (
    <div
      className={classes.tabHeader}
      style={{ justifyContent: "space-between" }}
    >
      <Link style={{ textDecoration: "none" }} to="#">
        <div className={`${classes.tabHeaderText} ${classes.active}`}>
          Summary
        </div>
      </Link>
      <Link style={{ fontSize: "0px", textDecoration: "none" }} to="#">
        <div className={`${classes.tabHeaderText} `}></div>
      </Link>
      <Link style={{ textDecoration: "none" }} to="#">
        <div className={`${classes.tabHeaderText} `}></div>
      </Link>
      <Link style={{ textDecoration: "none" }} to="#">
        <div className={`${classes.tabHeaderText} `}></div>
      </Link>
      <Link style={{ textDecoration: "none" }} to="#">
        <div className={`${classes.tabHeaderText} `}></div>
      </Link>
      <Link style={{ textDecoration: "none" }} to="#">
        <div className={`${classes.tabHeaderText} `}></div>
      </Link>
      <Link style={{ textDecoration: "none" }} to="#">
        <div className={`${classes.tabHeaderText} `}></div>
      </Link>
      <Link style={{ textDecoration: "none" }} to="#">
        <div className={`${classes.tabHeaderText} `}></div>
      </Link>
    </div>
  );
}

export default TabHeader;
