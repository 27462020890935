import React, { useState } from "react";
import Bar from "../print/Components/barGraph";
import { useEffect } from "react";
import { getDashboardPortfolioXirrGraph } from "../../../api";
import { OnboardingContext } from "../../../Context";
import { useContext } from "react";
import { mfoFilterContext } from "../../../mfo_context/Mfo_Context";
import NewHeadAndDetailsComp from "../products/newDesigns/common/newHeadingAndDetailsComp/NewHeadAndDetailsComp";
import { Typography } from "@material-ui/core";
import { roundNumber } from "../../utils/common";

export default function PortfolioBarGraph({ date }) {
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const { userState } = OnboardingContext();
  const client_id = userState?.loginedUserData?.id;
  const label = (d) => (
    <tspan fill="#ffffff" y={-10}>
      {roundNumber(d.value) + "%"}
    </tspan>
  );
  let bartheme = {
    background: "transparent",
    textColor: "#333333",
    fontSize: 11,
    axis: {
      domain: {
        line: {
          stroke: "#fff",
          strokeWidth: 2,
        },
      },
      legend: {
        text: {
          fontSize: 12,
          fill: "#F8F9FB",
        },
      },
      ticks: {
        line: {
          stroke: "#1f1c40",
          strokeWidth: 1,
        },
        text: {
          fontSize: 12,
          fill: "#ffffff",
        },
      },
    },
    grid: {
      line: {
        stroke: "#1f1c40",
        strokeWidth: 1,
      },
    },
    legends: {
      title: {
        text: {
          fontSize: 11,
          fill: "#ebe8ee",
        },
      },
      text: {
        fontSize: 11,
        fill: "#ffffff",
      },
      ticks: {
        line: {},
        text: {
          fontSize: 10,
          fill: "#333333",
        },
      },
    },
    annotations: {
      text: {
        fontSize: 13,
        fill: "#333333",
        outlineWidth: 2,
        outlineColor: "#3c9cfa",
        outlineOpacity: 0.7,
      },
      link: {
        stroke: "#000000",
        strokeWidth: 1,
        outlineWidth: 2,
        outlineColor: "#ffffff",
        outlineOpacity: 1,
      },
      outline: {
        stroke: "#000000",
        strokeWidth: 2,
        outlineWidth: 2,
        outlineColor: "#ffffff",
        outlineOpacity: 1,
      },
      symbol: {
        fill: "#000000",
        outlineWidth: 2,
        outlineColor: "#ffffff",
        outlineOpacity: 1,
      },
    },
    tooltip: {
      container: {
        background: "#ffffff",
        color: "#333333",
        fontSize: 12,
      },
      basic: {},
      chip: {},
      table: {},
      tableCell: {},
      tableCellValue: {},
    },
  };
  bartheme.axis.domain.line.stroke = "transparent";
  bartheme.axis.domain.line.strokeWidth = "0";

  const [bardata, setBarData] = useState([]);
  useEffect(() => {
    let params = { ...getfilter_as_params() };
    getgraph(client_id, params);
  }, [mfoFilter]);

  function getgraph(client, params) {
    getDashboardPortfolioXirrGraph(client, params).then((res) => {
      setBarData(res);
    });
  }

  return (
    <>
      <NewHeadAndDetailsComp
        title={"Portfolio Overview"}
        content={
          <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
            <Typography className="fw30010">{"Updated As on:"}</Typography>
            <div className="date" style={{ marginTop: "2px" }}>
              {date}
            </div>
            {/* {date && moment(date) && (
              <div className="date">{moment(date).format("DD-MM-YYYY")}</div>
            ) } */}
          </div>
        }
      />

      <div style={{ height: "325px", width: "100%" }}>
        <Bar
          data={bardata || []}
          config={{ theme: bartheme, legendtranslatey: 75, label: label }}
          commonprops={{
            enableGridY: false,
            axisLeft: null,
            colors: ["#97B2D5", "#00838F", "#B2C77A"],
            tooltip: ({ id, value, color }) => {
              return (
                <div
                  style={{
                    padding: "9px 12px",
                    color: "#fff",
                    background: " #1d0640",
                    border: "1px solid #000000",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      height: "16px",
                      width: "16px",
                      background: color,
                      marginRight: "5px",
                    }}
                  ></div>
                  <div>
                    {id}
                    <b>{value ? roundNumber(value) + "%" : ""}</b>
                  </div>
                </div>
              );
            },
          }}
        />
      </div>
    </>
  );
}
