import React, { useState, useMemo } from "react";
import "../common/ProductsStyles";
import "../common/products-style.css";
import useStyles from "../common/ProductsStyles";
import EquitySubTab from "./EquitySubTab";
import { ReactComponent as Updown } from "../../../../assets/icons/up-down-arrow.svg";
import { commonAPI } from "../../../../api";
import { useEffect } from "react";
import {
  staticEquityCorporateData,
  equityCorporateALlTableConfig,
} from "./utils/constands";
import { objectFormatingEquityCorporate } from "./utils/objectFormating";
import PmsReportTable from "../pms/PmsReportTable";
import { handleALL, handleHeaderChange } from "./utils/common";
import NewTabComp from "../common/NewTabComp";

export default function EquityCorporate() {
  // const [tableConfig, setTableConfig] = useState(equityCorporateALlTableConfig);
  const classes = useStyles();
  const [page, setPage] = useState("adjusted");
  const [option, setOption] = useState("All");
  const [tableColumns, setTableColumns] = useState([]);
  const [result, setResult] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [tableHeader, setTableHeader] = useState(equityCorporateALlTableConfig);
  const [loading, setLoading] = useState(false);

  const handleChangeInPage = (page, data) => {
    // console.log(tableHeader, "afiilaflaf");
    let newList = [];
    if (page == "adjusted") {
      data?.adjustedData?.map((val) => {
        newList.push(objectFormatingEquityCorporate(val, tableHeader));
      });
    } else {
      data?.upcomingData?.map((val) => {
        newList.push(objectFormatingEquityCorporate(val, tableHeader));
      });
    }
    // console.log(newList, data, page, "afinbgoagwa");
    setTableColumns(newList);
  };

  const getEquityCorporateAPI = async (newList) => {
    // console.log(newList, "alifafwa");
    setLoading(true);
    try {
      let res = await commonAPI("/equity/corporate-action", {
        type: option.toLocaleLowerCase(),
      });
      // console.log(res?.data?.data?.result, "aohoa9e");
      setResult(res?.data?.data?.result);
      // let header = handleHeader(option);
      handleChangeInPage(page, res?.data?.data?.result);
      // console.log(res, "EquityCorporateRes");
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    setTableHeader((prev) => {
      let newList = handleHeaderChange(
        prev,
        option,
        equityCorporateALlTableConfig
      );
      console.log(newList, "newlist");
      return newList;
    });

    getEquityCorporateAPI();
  }, [option]);

  useEffect(() => {
    if (result) {
      handleChangeInPage(page, result);
    }
  }, [page]);

  const handleClick = (page) => {
    setPage(page);
  };

  const data = [
    {
      page: "adjusted",
      title: "Adjusted",
    },
    {
      page: "upcoming",
      title: "Upcoming",
    },
  ];

  return (
    <div>
      <EquitySubTab
        title="Corporate Actions"
        importIconVisible={false}
      ></EquitySubTab>
      <br />
      <div style={{ minHeight: "190px" }} className={classes.mainContent}>
        <div
          style={{
            backgroundColor: "#1D1B36",
            margin: "-15px",
            height: "75px",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
          }}
        >
          <div
            style={{ padding: "2% 0 0 3%" }}
            className={classes.stockContentHeading}
          >
            {" "}
            Filter
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "5%",
            justifyContent: "space-around",
          }}
          className={classes.stockContentHeading}
        >
          {/* <div style={{}}><CheckBoxWithColor />All</div>
          <div style={{}}><CheckBoxWithColor />Dividend</div>
          <div style={{}}><CheckBoxWithColor />Bonus</div>
          <div style={{}}><CheckBoxWithColor />Split</div>
          <div style={{}}><CheckBoxWithColor />Right</div> */}
          <div className="checkwrapper">
            <input
              type="checkbox"
              checked={option === "All"}
              onChange={() => setOption("All")}
            />
            <span>All</span>
          </div>
          <div className="checkwrapper">
            <input
              type="checkbox"
              checked={option === "Dividend"}
              onChange={() => setOption("Dividend")}
            />
            <span>Dividend</span>
          </div>
          <div className="checkwrapper">
            <input
              type="checkbox"
              checked={option === "Bonus"}
              onChange={() => setOption("Bonus")}
            />
            <span>Bonus</span>
          </div>
          <div className="checkwrapper">
            <input
              type="checkbox"
              checked={option === "Split"}
              onChange={() => setOption("Split")}
            />
            <span>Split</span>
          </div>
          <div className="checkwrapper">
            <input
              type="checkbox"
              checked={option === "Right"}
              onChange={() => setOption("Right")}
            />
            <span>Right</span>
          </div>
        </div>
      </div>
      {/* <br />
      <br /> */}

      <div style={{ margin: "2%" }}>
        <NewTabComp
          data={data}
          customHandle={handleClick}
          customWidth={"fit-content"}
        />
      </div>

      {/* <div
        style={{ width: "fit-content", margin: "2%", cursor: "pointer" }}
        className={classes.tabMfo}
      >
        <a
          onClick={() => {
            setPage("adjusted");
          }}
          style={{ textDecoration: "none" }}
        >
          <div
            className={`${classes.tabHeaderText} ${
              page === "adjusted" ? classes.active : ""
            }`}
          >
            Adjusted
          </div>
        </a>
        <a
          onClick={() => {
            setPage("upcoming");
          }}
          style={{ textDecoration: "none" }}
        >
          <div
            className={`${classes.tabHeaderText} ${
              page === "upcoming" ? classes.active : ""
            }`}
          >
            Upcoming
          </div>
        </a>
      </div> */}

      {/* <button onClick={() => setOpenModal(!openModal)}>openModal</button> */}
      <div
        // style={{ minHeight: "190px" }}
        className={classes.mainContent}
      >
        <PmsReportTable
          tableColumns={tableColumns}
          setTableColumns={setTableColumns}
          tableHeader={tableHeader}
          setTableHeader={setTableHeader}
          headerList={equityCorporateALlTableConfig}
          tableHeaderBGColor="#1D1B36"
          tableBodyBackground="#110425"
          openModal={openModal}
          setOpenModal={setOpenModal}
          loading={loading}
        />
      </div>

      {/* <div style={{ minHeight: "190px" }} className={classes.mainContent}>
        <div
          style={{ marginTop: "3%" }}
          className={classes.tableContainerAlign}
        >
          <Table>
            <TableHead style={{ background: "#1D1B36", borderRadius: "20px" }}>
              <TableRow className="tableRowStyle">
                <TableCell
                  align="left"
                  style={{ borderBottom: "none" }}
                  className={`${classes.EquityTabHead} thStyle`}
                >
                  Stock Name <Updown />
                </TableCell>
                <TableCell
                  align="right"
                  style={{ borderBottom: "none" }}
                  className={`${classes.EquityTabHead} thStyle`}
                >
                  Type <Updown />
                </TableCell>
                <TableCell
                  align="right"
                  style={{ borderBottom: "none" }}
                  className={`${classes.EquityTabHead} thStyle`}
                >
                  Ratio <Updown />
                </TableCell>
                <TableCell
                  align="right"
                  style={{ borderBottom: "none" }}
                  className={`${classes.EquityTabHead} thStyle`}
                >
                  Ex Date / Event Date <Updown />
                </TableCell>
                <TableCell
                  align="right"
                  style={{ borderBottom: "none" }}
                  className={`${classes.EquityTabHead} thStyle`}
                >
                  Remarks <Updown />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left" className={classes.tableBodyText}>
                  Adani Green Ene
                </TableCell>
                <TableCell align="right" className={classes.tableBodyText}>
                  1
                </TableCell>
                <TableCell align="right" className={classes.tableBodyText}>
                  1
                </TableCell>
                <TableCell align="right" className={classes.tableBodyText}>
                  1
                </TableCell>
                <TableCell align="right" className={classes.tableBodyText}>
                  1
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left" className={classes.tableBodyText}>
                  Indian Oil Corporates
                </TableCell>
                <TableCell align="right" className={classes.tableBodyText}>
                  1
                </TableCell>
                <TableCell align="right" className={classes.tableBodyText}>
                  1
                </TableCell>
                <TableCell align="right" className={classes.tableBodyText}>
                  1
                </TableCell>
                <TableCell align="right" className={classes.tableBodyText}>
                  1
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div> */}
    </div>
  );
}
