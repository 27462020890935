import React from "react";
import Default from "../components/dashboard/defaultPages/Default";
import UserManagementComp from "../components/user_management/UserManagementComp";
import { NewUserManagementComp } from "../components/user_management/NewUserManagementComp";

function UserManagementEdit() {
  return (
    <Default>
      <NewUserManagementComp></NewUserManagementComp>
    </Default>
  );
}

export default UserManagementEdit;
