import { useState, useEffect, useContext } from "react";
import {
  getDashboardAssetData,
  fetchAssetDetailsViewXIRR,
  fetchAssetDetailsViewTWRR,
} from "../api";
import { OnboardingContext } from "../Context";

import { mfoFilterContext } from "../mfo_context/Mfo_Context";

/**
 * Custom hook to fetch the asset detail view data.
 *
 * @returns {Array} - An array containing the rows of the asset detail view table.
 */
export const useExcessPerformanceView = (setLoading, setLoaderAssetClass) => {
  const { userState } = OnboardingContext();
  const [tablerRows, setTablerRows] = useState([]);
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  let clientId = userState.loginedUserData.id;
  // let clientId = 199;

  const getBenchmarkTwrr = async () => {
    let params = { ...getfilter_as_params() };
    params["assetClass"] = "equity,debt,cash,alternate";
    setLoaderAssetClass(true);
    try {
      let res = await fetchAssetDetailsViewTWRR(
        clientId,
        params,
        "excessPerformance",
        "current"
      );
      setTablerRows((prev) => {
        let newList = [];

        prev?.map((val) => {
          val.absoluteTwrr = res?.data?.data?.result?.filter(
            (val2) => val2?.assetClass == val?.assetClass
          )[0]?.absoluteTwrr;
          val.annualisedTwrr = res?.data?.data?.result?.filter(
            (val2) => val2?.assetClass == val?.assetClass
          )[0]?.annualisedTwrr;
          val.absoluteTwrrExcessPerformance = res?.data?.data?.result?.filter(
            (val2) => val2?.assetClass == val?.assetClass
          )[0]?.absoluteTwrrExcessPerformance;
          val.annualisedTwrrExcessPerformance = res?.data?.data?.result?.filter(
            (val2) => val2?.assetClass == val?.assetClass
          )[0]?.annualisedTwrrExcessPerformance;
          val.benchmarkAbsoluteTwrr = res?.data?.data?.result?.filter(
            (val2) => val2?.assetClass == val?.assetClass
          )[0]?.benchmarkAbsoluteTwrr;
          val.benchmarkAnnualisedTwrr = res?.data?.data?.result?.filter(
            (val2) => val2?.assetClass == val?.assetClass
          )[0]?.benchmarkAnnualisedTwrr;
          newList.push(val);
        });

        return newList;
      });
      setLoaderAssetClass(false);
    } catch (error) {
      if (error) {
        setTablerRows((prev) => {
          let newList = [];
          prev?.map((val) => {
            val.absoluteTwrr = "Error";
            val.annualisedTwrr = "Error";
            val.absoluteTwrrExcessPerformance = "Error";
            val.annualisedTwrrExcessPerformance = "Error";
            val.benchmarkAbsoluteTwrr = "Error";
            val.benchmarkAnnualisedTwrr = "Error";
            newList.push(val);
          });
          return newList;
        });
      }
      setLoaderAssetClass(false);
    }
  };

  useEffect(() => {
    let params = { ...getfilter_as_params() };
    params["assetClass"] = "equity,debt,cash,alternate";

    setLoading(true);
    Promise.allSettled([
      getDashboardAssetData(clientId, params, "dashboard"),
      fetchAssetDetailsViewXIRR(clientId, params, "current"),
    ]).then(([asset_ress, xirr_res]) => {
      let newList = [];
      if (asset_ress.status == "fulfilled") {
        asset_ress.value?.data?.map((val) => {
          val.absoluteTwrr = "loading";
          val.annualisedTwrr = "loading";
          val.absoluteTwrrExcessPerformance = "loading";
          val.annualisedTwrrExcessPerformance = "loading";
          val.benchmarkAbsoluteTwrr = "loading";
          val.benchmarkAnnualisedTwrr = "loading";
          newList.push(val);
        });
      }

      let NewList2 = [];
      if (xirr_res.status == "fulfilled") {
        newList?.map((val) => {
          val.portfolioXirr = xirr_res?.value?.data?.data?.result?.filter(
            (val2) => val2?.assetClass == val?.assetClass
          )[0]?.portfolioXirr;
          val.benchmarkXirr = xirr_res?.value?.data?.data?.result?.filter(
            (val2) => val2?.assetClass == val?.assetClass
          )[0]?.benchmarkXirr;
          val.excessPerformance = xirr_res?.value?.data?.data?.result?.filter(
            (val2) => val2?.assetClass == val?.assetClass
          )[0]?.excessPerformance;
          val.benchmarkName = xirr_res?.value?.data?.data?.result?.filter(
            (val2) => val2?.assetClass == val?.assetClass
          )[0]?.benchmarkName;

          NewList2.push(val);
        });
      }

      setTablerRows(() => NewList2);

      setLoading(false);
      getBenchmarkTwrr();
    });
  }, [mfoFilter]);

  return [tablerRows, getBenchmarkTwrr];
};
