const chartData = [
  {
    name: "Series 1",
    type: "column",
    data: [
      [1587537000000, -4.061],
      [1587537900000, 28.009],
      [1587538800000, 51.958],
      [1587539700000, 76.503],
      [1587540600000, 101.047],
    ],
  },
  {
    name: "Series 2",
    type: "line",
    data: [
      [1587537900000, 46],
      [1587538800000, 76],
      [1587539700000, 116],
      [1587540600000, 155],
      [1587541500000, 197],
    ],
  },
];

export default chartData;
