import React from "react";
import Default from "../components/dashboard/defaultPages/Default";
import { useParams } from "react-router-dom";
import INVITSReport from "../components/dashboard/products/invits/INVITSReport";

function INVITSReportDetails() {
  const { pageName } = useParams();

  return (
    <Default>
      <INVITSReport pageName={pageName} />
    </Default>
  );
}

export default INVITSReportDetails;
