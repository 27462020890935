import { ResponsiveBar } from "@nivo/bar";

import React from "react";
import { roundNumber } from "../../../utils/common";

export default function Bar({ data, config, commonprops }) {
  const data1 = [
    {
      country: "1 Month",
      "Target Portfolio Returns": 53,
      "Target Portfolio ReturnsColor": "hsl(120, 70%, 50%)",
      burger: 179,
      burgerColor: "hsl(307, 70%, 50%)",
      sandwich: 135,
      sandwichColor: "hsl(4, 70%, 50%)",
      "Unique Client Index": 118,
      "Unique Client Index Color": "hsl(219, 70%, 50%)",
      fries: 114,
      friesColor: "hsl(48, 70%, 50%)",
      "Current Portfolio Returns": 168,
      donutColor: "hsl(150, 70%, 50%)",
    },
    {
      country: "5 Months",
      "Target Portfolio Returns": 100,
      "Target Portfolio ReturnsColor": "hsl(304, 70%, 50%)",
      burger: 61,
      burgerColor: "hsl(262, 70%, 50%)",
      sandwich: 42,
      sandwichColor: "hsl(62, 70%, 50%)",
      "Unique Client Index": 79,
      "Unique Client Index Color": "hsl(288, 70%, 50%)",
      fries: 162,
      friesColor: "hsl(253, 70%, 50%)",
      "Current Portfolio Returns": 10,
      donutColor: "hsl(319, 70%, 50%)",
    },
    {
      country: "6 Months",
      "Target Portfolio Returns": 153,
      "Target Portfolio ReturnsColor": "hsl(119, 70%, 50%)",
      burger: 131,
      burgerColor: "hsl(194, 70%, 50%)",
      sandwich: 35,
      sandwichColor: "hsl(337, 70%, 50%)",
      "Unique Client Index": 149,
      "Unique Client Index Color": "hsl(41, 70%, 50%)",
      fries: 172,
      friesColor: "hsl(251, 70%, 50%)",
      "Current Portfolio Returns": 25,
      donutColor: "hsl(89, 70%, 50%)",
    },
    {
      country: "12 Months",
      "Target Portfolio Returns": 50,
      "Target Portfolio ReturnsColor": "hsl(26, 70%, 50%)",
      burger: 112,
      burgerColor: "hsl(266, 70%, 50%)",
      sandwich: 116,
      sandwichColor: "hsl(312, 70%, 50%)",
      "Unique Client Index": 97,
      "Unique Client Index Color": "hsl(162, 70%, 50%)",
      fries: 168,
      friesColor: "hsl(286, 70%, 50%)",
      "Current Portfolio Returns": 81,
      donutColor: "hsl(88, 70%, 50%)",
    },
    {
      country: "Since Inception",
      "hot dog": 46,
      "hot dogColor": "hsl(243, 70%, 50%)",
      burger: 133,
      burgerColor: "hsl(193, 70%, 50%)",
      sandwich: 64,
      sandwichColor: "hsl(239, 70%, 50%)",
      "Unique Client Index": 179,
      "Unique Client Index Color": "hsl(8, 70%, 50%)",
      fries: 98,
      friesColor: "hsl(170, 70%, 50%)",
      "Current Portfolio Returns": 158,
      donutColor: "hsl(265, 70%, 50%)",
    },
  ];

  return (
    <ResponsiveBar
      data={data ? data : data1}
      keys={["Target Portfolio", "Unique Client Index", , "Current Portfolio"]}
      theme={config.theme}
      indexBy="timeSlab"
      margin={{ top: 50, right: 130, bottom: 60, left: 60 }}
      padding={0.3}
      groupMode="grouped"
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={{ scheme: "nivo" }}
      // minValue={-1000}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: "fries",
          },
          id: "dots",
        },
        {
          match: {
            id: "sandwich",
          },
          id: "lines",
        },
      ]}
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: " ",
        legendPosition: "middle",
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "food",
        legendPosition: "middle",
        legendOffset: -40,
      }}
      enableGridY={false}
      label={
        config.label
          ? config.label
          : (d) => <tspan y={-10}>{roundNumber(d.value) + "%"}</tspan>
      }
      // labelSkipWidth={12}
      // labelSkipHeight={0}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      legends={[
        {
          anchor: "bottom",
          direction: "row",
          justify: false,
          translateX: 18,
          translateY: config?.legendtranslatey ? config?.legendtranslatey : 70,
          itemsSpacing: 50,
          itemDirection: "left-to-right",
          itemWidth: 110,
          itemHeight: 63,
          itemOpacity: 0.75,
          symbolSize: 9,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      {...commonprops}
      role="application"
      ariaLabel="Nivo bar chart demo"
      barAriaLabel={(e) =>
        e.id + ": " + e.formattedValue + " in country: " + e.indexValue
      }
    />
  );
}
