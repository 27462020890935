import { useState, useEffect } from "react";
import { fetchAllMasters } from "../api";
import { useNavigate } from "react-router";

// Define a cache object to store API responses
const cache = new Map();

export function useMastersList(searchTerm) {
  const [mastersData, setMastersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let timeoutId;

    // Clear the previous timeout if it exists
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setLoading(true);

    // Set a new timeout to delay the API call by 300ms
    timeoutId = setTimeout(async () => {
      // Check if the response is cached
      if (cache.has(searchTerm)) {
        const cachedData = cache.get(searchTerm);
        setMastersData(cachedData);
        setLoading(false);
        return;
      }

      try {
        const data = await fetchAllMasters(searchTerm, 20, 0); // Replace with your actual API function
        const fetchMastersData = data.data;

        if (fetchMastersData.success) {
          setMastersData(fetchMastersData.data.result);
          setLoading(false);
          // Cache the API response with the searchTerm as the key
          cache.set(searchTerm, fetchMastersData.data.result);
        }
      } catch (error) {
        setLoading(false);
        if (error.response?.status === 401) {
          navigate("/logout");
        }
      }
    }, 300);

    // Clean up the timeout on component unmount or when searchTerm changes
    return () => {
      clearTimeout(timeoutId);
      setLoading(false);
    };
  }, [searchTerm]);

  return {
    mastersData,
    loading,
  };
}
