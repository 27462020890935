import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Union } from "../../../assets/icons/union.svg";
import { ReactComponent as NextArrow } from "../../../assets/icons/nextArrow.svg";
import { Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paddingLeft: {
    paddingLeft: "10px!important",
  },
  rowText: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "14px",
    color: "#FFFFFF",
  },
  table: {
    minWidth: 650,
  },
  tableHeaderText: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#A468FF",
    borderBottom: "none",
    whiteSpace: "nowrap",
  },
  tableContainer: {
    marginTop: "3%",
    height: "60vh",
    scrollbarWidth: "none",
    // overflowY: "scroll",
    [theme.breakpoints.down("md")]: {
      height: "48vh",
    },
    [theme.breakpoints.down("sm")]: {
      height: "44vh",
    },
    [theme.breakpoints.down("xs")]: {
      height: "42vh",
    },
  },
}));

function MastersContent({ mastersData, emptyList, hideHeader }) {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleSelectedMaster = (product_name, product_code) => {
    navigate("/masters/products", {
      state: { master: product_code, master_name: product_name },
    });
  };

  return (
    <>
      <TableContainer className={classes.tableContainer}>
        {emptyList ? (
          <Typography
            style={{ color: "#FFFFFF", marginTop: "10%", textAlign: "center" }}
          >
            List not found!
          </Typography>
        ) : (
          <>
            <Table
              className={classes.table + " seperate "}
              aria-label="simple table"
            >
              <TableHead
                style={{
                  background: "#1D1B36",
                  borderRadius: "20px",
                  display: hideHeader ? "none" : " ",
                }}
              >
                <TableRow>
                  <TableCell align="left" className={classes.tableHeaderText}>
                    NAME
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.tableHeaderText}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* <TableRow style={{ height: "0px" }}></TableRow> */}
                {mastersData?.map((dat) => (
                  <TableRow key={dat?.value}>
                    <TableCell
                      className={`${classes.rowText} ${classes.paddingLeft} tLeftBorderRadius5`}
                      style={{ borderBottom: "1px solid #28235C" }}
                      align="left"
                    >
                      {dat?.product_name}
                    </TableCell>

                    <TableCell
                      className={classes.rowText + "tRightBorderRadius5 "}
                      style={{ borderBottom: "1px solid #28235C" }}
                      align="right"
                    >
                      <NextArrow
                        onClick={() =>
                          handleSelectedMaster(
                            dat.product_name,
                            dat.product_code
                          )
                        }
                        style={{ cursor: "pointer" }}
                      />
                      {/* <Union
                        
                      /> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}
      </TableContainer>
    </>
  );
}

export default MastersContent;
