import React from "react";
import { useForm } from "../hooks/useForm";
import PartnerHeading from "../PartnerHeading";
import "../../NewSignUp/PartnerOnboarding.css";
import "../../NewSignUp/NewSignUp.css";
import { Box, Grid, MenuItem } from "@material-ui/core";

import LoadingButton from "../common/LoadingButton";
import ErrorMessageComp from "../common/ErrorMessageComp";

import {
  addUserSchema,
  client_add_member_validation_schema,
  pinSchema,
  updatePartnerUser,
} from "../ValidationSchema";
import {
  Client_User_Roles,
  ENTITY_TYPES,
  adduserRoles,
  adduserStatus,
  new_Client_User_Roles,
} from "../../../Constants";
import CustomInput from "../common/CustomInput";
import CheckBoxWithColor from "../../dashboard/products/common/CheckBoxWithColor";

function VerifyPinForm({
  handleNext,
  handleBack,
  currentStep,
  customHandleSubmit,
  head,
  onlyPerosn,
  apiErrorMessage,
  loading,
  formData,
  totalStep,
  noStepBottom,
  formMaxHeight,
  noStep,
  formWidth,
}) {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useForm(
    {
      pin: "",
      confirmPin: "",
      agree: false,
    },
    // test,
    (values) => {
      console.log(values, "aflkbabfbuawubfw");
      customHandleSubmit(values);
    },
    pinSchema
  );

  return (
    <div
      className="dFlex  alignCntr h100 "
      style={{ gap: "25px", width: formWidth }}
    >
      <div className="newFormContainer" style={{ maxHeight: formMaxHeight }}>
        <Box
          className="dFlex jtfyContCntr alignCntr flexDirCol nopaddingbuttop"
          sx={{ width: { xs: "auto", sm: "330px" }, gap: "47px" }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid xs={12} item>
                <CustomInput
                  title="ENTER PIN"
                  type="password"
                  variant="outlined"
                  margin="normal"
                  label="Pin"
                  fullWidth
                  id="pin"
                  name="pin"
                  autoComplete="off"
                  error={touched.pin && Boolean(errors.pin)}
                  helperText={touched.pin && errors.pin}
                  value={values.pin}
                  onChange={handleChange}
                  maxLength={4}
                />
              </Grid>

              <Grid xs={12} item>
                <CustomInput
                  maxLength={4}
                  title="CONFIRM PIN"
                  type="password"
                  label="Confirm Pin"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="confirmPin"
                  name="confirmPin"
                  autoComplete="current-password"
                  error={touched.confirmPin && Boolean(errors.confirmPin)}
                  helperText={touched.confirmPin && errors.confirmPin}
                  value={values.confirmPin}
                  onChange={handleChange}
                />
              </Grid>
              <Grid xs={12} item>
                <CheckBoxWithColor
                  icon={
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="black"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.581797"
                        y="0.706797"
                        width="18.8364"
                        height="18.8364"
                        rx="3.4182"
                        stroke="#3E5389"
                        stroke-width="1.16359"
                      />
                    </svg>
                  }
                  checked={values.agree}
                  value={values.agree}
                  name="agree"
                  onChange={handleChange}
                />
                <span style={{ color: "#ffffff", opacity: 0.7 }}>
                  {" "}
                  Agree to all terms and conditions
                </span>
              </Grid>

              <div className="newFormBottonSec">
                <Grid xs={12} lg={12} style={{ marginTop: "30px" }} item>
                  {touched.agree && (
                    <ErrorMessageComp
                      errorMessage={errors.agree}
                      style={{ marginTop: "-10px" }}
                    />
                  )}
                  <LoadingButton
                    buttonTitle={"Done"}
                    type="submit"
                    showLoader={loading}
                  />
                </Grid>
              </div>
            </Grid>
          </form>
        </Box>
      </div>
    </div>
  );
}

export default VerifyPinForm;
