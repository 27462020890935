import { useState, useEffect, useContext } from "react";
import { OnboardingContext } from "../Context";
import { mfoFilterContext } from "../mfo_context/Mfo_Context";
import moment from "moment";
import {
  getCurrentHoldingGainLoss,
  fetchTwrrCurrentHolding,
  fetchXirrCurrentHolding,
} from "../api";

export const useCurrentHoldingCardsApi = (
  checked,
  type,
  productSubCategory,
  insuranceType,
  pagetype
) => {
  console.log("ramtype", pagetype);
  const { userState } = OnboardingContext();
  const [holdingCardData, setHoldingCardData] = useState();
  const [xirrCurrentHolding, setXirrCurrentHolding] = useState();
  const [twrrCurrentHolding, setTwrrCurrentHolding] = useState();
  const [loading, setLoading] = useState(true);
  // ** Loader for twrr
  const [twrrLoaderCurrentHolding, setTwrrLoaderCurrentHolding] =
    useState(true);
  // ** error for twrr
  const [twrrErrorCurrentHolding, setTwrrErrorCurrentHolding] = useState(false);
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  let clientId = userState.loginedUserData.id;
  // let clientId = 199;
  // In case of Insurance need to pass productSubCategory and insuranceType
  function getParams(type) {
    if (type === "ins") {
      return { insuranceType, productSubCategory };
    } else {
      return { ...getfilter_as_params() };
    }
  }
  //   Retry Call Back Function
  const retryFetchTwrrCurrentHolding = () => {
    let params = getParams(type);
    setTwrrLoaderCurrentHolding(true);
    setTwrrErrorCurrentHolding(false);

    fetchTwrrCurrentHolding(clientId, params, type)
      .then((res) => {
        setTwrrCurrentHolding(res?.result);
        setTwrrLoaderCurrentHolding(false);
      })
      .catch((err) => {
        setTwrrErrorCurrentHolding(true);
        setTwrrLoaderCurrentHolding(false);
      });
  };
  useEffect(() => {
    if (checked) {
      if (type === "bank" || pagetype === "transaction") {
        return;
      }
      // let params = { ...getfilter_as_params() };
      let params = getParams(type);
      if (mfoFilter.startDate) {
        params["startDate"] = moment(mfoFilter.startDate).format("YYYY-MM-DD");
        params["endDate"] = moment(mfoFilter.endDate).format("YYYY-MM-DD");
      }
      params["promoterStake"] = mfoFilter.promoterStake;
      if (Object.keys(mfoFilter.checkData).length > 0) {
        params["memberId"] = Object.keys(mfoFilter.checkData).join(",");
      }
      if (Object.keys(mfoFilter.advisor).length > 0) {
        params["advisor"] = Object.keys(mfoFilter.advisor).join(",");
      }
      if (type === "dashboard") {
        params["assetClass"] = "equity,debt,cash,alternate";
      }

      setLoading(true);
      Promise.allSettled([
        getCurrentHoldingGainLoss(clientId, params, type),
        fetchXirrCurrentHolding(clientId, params, type),
      ]).then(([current_holidng_res, xirr_current_res]) => {
        if (current_holidng_res.status == "fulfilled") {
          setHoldingCardData(current_holidng_res?.value?.result);
        }

        if (xirr_current_res.status == "fulfilled") {
          setXirrCurrentHolding(xirr_current_res?.value?.result);
        }
        setLoading(false);
        setTwrrLoaderCurrentHolding(true);
        fetchTwrrCurrentHolding(clientId, params, type)
          .then((res) => {
            setTwrrLoaderCurrentHolding(true);
            setTwrrCurrentHolding(res?.result);
            setTwrrLoaderCurrentHolding(false);
            setTwrrErrorCurrentHolding(false);
          })
          .catch((err) => {
            setLoading(false);
            setTwrrLoaderCurrentHolding(false);
            setTwrrErrorCurrentHolding(true);
          });
      });
    }
  }, [mfoFilter, checked, type]);

  return {
    holdingCardData,
    xirrCurrentHolding,
    twrrCurrentHolding,
    loading,
    twrrLoaderCurrentHolding,
    twrrErrorCurrentHolding,
    retryFetchTwrrCurrentHolding,
  };
};
