import React from "react";
import Loader from "../../../loader/Loader";
import useStyles from "../common/ProductsStyles";
import { Modal } from "@material-ui/core";
export default function PageLoader({ showLoader, que, error, hanldeError }) {
  const classes = useStyles();
  return (
    <div>
      <Modal
        open={showLoader}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="loader-wrapper newLoader">
          {error ? (
            <div className="errorContainer">
              <h3 className="errorText">
                Sorry We Ran Into Error. Please Click The Below Button to Try
                Again
              </h3>
              <button className="errorButton" onClick={hanldeError}>
                Retry
              </button>
            </div>
          ) : (
            <Loader />
          )}
        </div>
      </Modal>
    </div>
  );
}
