import React, { useEffect, useRef } from "react";
import useStyles from "../common/ProductsStyles";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  Menu,
  Modal,
  Radio,
  FormGroup,
  FormControlLabel,
  useTheme,
} from "@material-ui/core";
import { ReactComponent as Back } from "../../../../assets/holding-summary/back-blue.svg";
import { ReactComponent as Print } from "../../../../assets/icons/print.svg";

import { ReactComponent as Import } from "../../../../assets/icons/import.svg";
import { ReactComponent as Export } from "../../../../assets/icons/export.svg";
import { ReactComponent as Filter } from "../../../../assets/icons/filter.svg";
import { ReactComponent as EditColumn } from "../../../../assets/icons/edit-column.svg";
import { ReactComponent as Eye } from "../../../../assets/icons/white-eye.svg";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import CheckBoxWithColor from "../common/CheckBoxWithColor";
import "../common/products-style.css";
import SearchInput from "../common/SearchInput";
import { set } from "lodash";
import { IosSwitch } from "../../../IosSwitch";

export default function TabBondSub(props) {
  const {
    checkAdvisor,
    setCheckAdvisor,
    handleIconClick,
    editColumnIconVisible,
    type,
    setopen,
    modal,
  } = props;
  const [search, setSearch] = useState("");
  const [clear, setclear] = useState(false);
  const classes = useStyles();
  const navigate = useNavigate();
  // const [anchorEl, setAnchorEl] = useState(null);
  // const [checkAdvisor, setCheckAdvisor] = useState({});
  // const openMenu = Boolean(anchorEl);
  const arr = ["CRISIL", "ICRA", "CARE", "FITCH", "BRICK"];
  const [list, setlist] = useState(arr);
  const [openModal, setOpenModal] = useState(false);
  const wrapperRef = useRef(null);
  const [display, setDisplay] = useState(false);

  const theme = useTheme();
  const icons = theme.palette.icons;
  const Bulb = icons.bulbIcon;

  const handleClickOutside = (event) => {
    const { current: wrap } = wrapperRef;
    if (wrap && !wrap.contains(event.target)) {
      setDisplay(false);
    }
  };

  useEffect(() => {
    if (search == "" || !search) {
      setlist(arr);
    } else {
      let filtered = [];
      arr.map((a) => {
        if (a.toUpperCase().indexOf(search.toUpperCase()) > -1) {
          filtered.push(a);
        }
      });

      setlist(filtered);
    }
  }, [search]);
  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // const handleCloseEvent = () => {
  //   setAnchorEl(null);
  // };
  const handleOpen = (event) => {
    // console.log("event", event.currentTarget);
    // setAnchorEl(event.currentTarget);
    // setOpenModal(!openModal);
    setDisplay(!display);
  };

  const handleAdvisorCheck = (event) => {
    if (event.target.checked) {
      setCheckAdvisor({
        [event.target.name]: event.target.checked,
      });
      // handleCloseEvent()
    } else {
      delete checkAdvisor[event.target.name];
      setCheckAdvisor({ ...checkAdvisor });
      // handleCloseEvent()
    }
  };

  return (
    <>
      <div className="credit">
        <div className={`newTopHeader ${classes.alignTopHeader}`}>
          {/* <Back
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/home/bonds/bonds-summary");
            }}
          /> */}
          <Typography
            className={`${classes.mainHeading} ${classes.mainHeadingAlign}`}
          >
            {props.title}
            {props?.subText && (
              <Typography
                style={{
                  cursor: "pointer",
                  // marginLeft: "90px",
                  marginTop: "1%",
                }}
                className={classes.aifSubText}
              >
                {props?.subText}&nbsp;
                {sessionStorage.getItem("currency_format") != "actuals" &&
                  sessionStorage.getItem("currency_format") && (
                    <span
                      className="subtext capitalize"
                      style={{
                        fontSize: "16px",
                        // marginLeft: "80px",
                        // marginTop: "-28px",
                        position: "absolute",
                      }}
                    >
                      {" "}
                      (values in {sessionStorage.getItem("currency_format")})
                    </span>
                  )}
              </Typography>
            )}
            {!props?.subText &&
              sessionStorage.getItem("currency_format") != "actuals" &&
              sessionStorage.getItem("currency_format") && (
                <>
                  <br />
                  <span
                    className="subtext capitalize"
                    style={{
                      fontSize: "16px",
                      // marginLeft: "80px",
                      // marginTop: "-28px",
                      position: "absolute",
                    }}
                  >
                    {" "}
                    (values in {sessionStorage.getItem("currency_format")})
                  </span>
                </>
              )}
          </Typography>

          <div className={`newTopHeaderIcon ${classes.iconAlign}`}>
            {props.settoggle && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <IosSwitch
                      checked={props?.toggle ? props.toggle : false}
                      setChecked={props?.settoggle ? props.settoggle : () => {}}
                    />
                  }
                  labelPlacement="start"
                  style={{
                    fontFamily: "Avenir",
                    fontWeight: 900,
                    fontSize: "16px",
                    letterSpacing: "1.5px",
                    textTransform: "uppercase",
                    color: "#FFFFFF",
                    opacity: 0.9,
                    marginRight: "12px",
                  }}
                />
              </FormGroup>
            )}
            {props.title === "Credit Rating" || props.title === "Comparison" ? (
              <div>
                <Filter
                  onClick={
                    setopen
                      ? () => {
                          setopen(!modal);
                        }
                      : handleOpen
                  }
                  className={classes.iconStyle}
                />
                {display && (
                  <div
                    className="autContainer"
                    style={{ position: "inherit" }}
                    ref={wrapperRef}
                  >
                    <div
                      style={{
                        background: " #220848",
                        borderRadius: "20px",
                        border: "1px solid #711CF1 !important",
                        padding: "15px",
                        overflow: "auto",
                        boxShadow: "-4px 4px 10px rgba(0, 0, 0, 0.5)",
                        position: "absolute",
                        top: "352px",
                        right: "175px",
                        zIndex: 2,
                      }}
                    >
                      <div style={{ display: "flex", gap: "10px" }}>
                        <Typography className={classes.textCardSmallValue}>
                          Credit Rating Agency
                        </Typography>
                        <Typography
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "3px",
                          }}
                          className={classes.mfoTextCard + " pointer"}
                          onClick={() => {
                            setCheckAdvisor({});
                            setclear(true);
                          }}
                        >
                          Clear All
                        </Typography>
                      </div>
                      <SearchInput
                        clear={clear}
                        setClear={setclear}
                        setSearchName={setSearch}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "25px",
                        }}
                      >
                        {list.map((a) => {
                          return (
                            <span
                              style={{
                                display: "flex",
                                gap: "80px",
                                // justifyContent: "space-between",
                              }}
                            >
                              <Radio
                                checked={checkAdvisor[a] ? true : false}
                                value={checkAdvisor[a] ? true : false}
                                onChange={handleAdvisorCheck}
                                name={a}
                              />
                              <Typography className="filter-value-name avtar-text textCapitalize">
                                {a}
                              </Typography>
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
            <Import className={classes.iconStyle} />
            <Export className={classes.iconStyle} />
            <Print className={classes.iconStyle} />
            {editColumnIconVisible && (
              <EditColumn
                onClick={handleIconClick}
                className={classes.iconStyle}
              />
            )}
          </div>
        </div>
        {/* <Typography
          style={{ cursor: "pointer", marginLeft: "90px", marginTop: "0.6%" }}
          className={classes.aifSubText}
        >
          {props?.subText}
        </Typography> */}
      </div>

      {/* {props.title === "Credit" && <Menu
        // id="demo-positioned-menu"
        // aria-labelledby="demo-positioned-button"
        className="modal-wrapper"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseEvent}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{
          backgroundColor: "#711CF1 !important",
          borderRadius: "20px",
          border: "1px solid #4E189F",
          // position: "absolute",
          outline: 0,
          // top: "15%",
          // right: "40%",
          width: "220px",
          padding: "15px",
          height: "200px",
          overflow: "scroll",
          boxShadow: "-4px 4px 10px rgba(0, 0, 0, 0.5)",
        }}
        style={{ minWidth: "400px" }}
      >
        <div style={{ padding: "10px" }} className="member">
          <div className="member-content">
            <div style={{ display: "flex", gap: "10px" }}>
              <Typography className={classes.textCardSmallValue}>
                Credit Rating Agency
              </Typography>
              <Typography style={{ display: "flex", alignItems: "center", marginTop: "3px" }} className={classes.mfoTextCard + " pointer"} onClick={() => {
                setCheckAdvisor({})
                setclear(true)
              }}>
                Clear All
              </Typography>
            </div>
            <SearchInput clear={clear} setClear={setclear} setSearchName={setSearch} />
            <div style={{ display: "flex", flexDirection: "column", gap: "25px" }}>
              {list.map((a) => {
                return (
                  <span
                    style={{
                      display: "flex",
                      gap: "80px"
                      // justifyContent: "space-between",
                    }}
                  >
                    <CheckBoxWithColor
                      checked={checkAdvisor[a] ? true : false}
                      value={checkAdvisor[a] ? true : false}
                      onChange={handleAdvisorCheck}
                      name={a}
                    />
                    <Typography className="filter-value-name avtar-text textCapitalize">
                      {a}
                    </Typography>
                  </span>
                )
              })}

            </div>


          </div>
        </div>

      </Menu>} */}

      {props.title === "Credit Rating" && (
        <Modal
          open={openModal}
          onClose={() => setOpenModal(!openModal)}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div style={{ position: "relative", outline: 0 }}>
            <div
              style={{
                background: " #220848",
                borderRadius: "20px",
                border: "1px solid #711CF1 !important",
                padding: "15px",
                overflow: "auto",
                boxShadow: "-4px 4px 10px rgba(0, 0, 0, 0.5)",
                position: "absolute",
                top: "250px",
                right: "250px",
              }}
            >
              <div style={{ display: "flex", gap: "10px" }}>
                <Typography className={classes.textCardSmallValue}>
                  Credit Rating Agency
                </Typography>
                <Typography
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "3px",
                  }}
                  className={classes.mfoTextCard + " pointer"}
                  onClick={() => {
                    setCheckAdvisor({});
                    setclear(true);
                  }}
                >
                  Clear All
                </Typography>
              </div>
              <SearchInput
                clear={clear}
                setClear={setclear}
                setSearchName={setSearch}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "25px",
                }}
              >
                {list.map((a) => {
                  return (
                    <span
                      style={{
                        display: "flex",
                        gap: "80px",
                        // justifyContent: "space-between",
                      }}
                    >
                      <Radio
                        checked={checkAdvisor[a] ? true : false}
                        value={checkAdvisor[a] ? true : false}
                        onChange={handleAdvisorCheck}
                        name={a}
                      />
                      <Typography className="filter-value-name avtar-text textCapitalize">
                        {a}
                      </Typography>
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
