import { useState } from "react";
/**
 * Toggles the show property of a column in the tableColumns array.
 *
 * @param {Event} e - The event object.
 * @returns {void}
 */
export const useCheckBoxClick = (tableColumnsData) => {
  const [tableColumns, setTableColumns] = useState(tableColumnsData);
  const handleCheckBoxClick = (e) => {
    const list = [...tableColumns];
    list.map((col, index) => {
      if (col.field === e.target.name) {
        const data = { ...col };
        data["show"] = !col.show;
        list[index] = { ...col, show: !col.show };
      }
    });

    setTableColumns(list);
  };
  return [handleCheckBoxClick, tableColumns];
};
