import React from "react";
import "./loaderStyle.css";

function Loader(props) {
  return (
    <div className="loader" style={props?.style}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}

export default Loader;
