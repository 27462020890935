import React, { useState, useEffect } from "react";
import TableSummaryDetails from "./TableSummaryDetails"; // Adjust the import path as necessary

function withSorting(WrappedComponent) {
  return function WithSortingComponent(props) {
    const { data, ...otherProps } = props;
    const [sortedData, setSortedData] = useState(data);
    const [sortOrderDetails, setSortOrderDetails] = useState({
      field: "",
      order: true,
    });

    // Sorting function
    // Assuming `onSort` is a prop function coming from the parent or HOC designed to handle sorting
    const onSort = (field, ascending) => {
      // Create a new sorted array from `data`
      const sortedData = [...data].sort((a, b) => {
        if (a[field] === b[field]) return 0;
        if (ascending) {
          return a[field] < b[field] ? -1 : 1;
        } else {
          return a[field] > b[field] ? -1 : 1;
        }
      });

      // Use `setTableData` to update the state with this new sorted array
      setSortedData(sortedData);

      // Update the sortOrderDetails to reflect the current sorting state
      setSortOrderDetails({
        field: field,
        order: ascending,
      });
    };

    // Effect to sort data initially or on data change
    useEffect(() => {
      onSort(sortOrderDetails.field, sortOrderDetails.order);
    }, [data, sortOrderDetails.field, sortOrderDetails.order]);

    return (
      <WrappedComponent
        data={sortedData}
        onSort={onSort}
        sortOrderDetails={sortOrderDetails}
        {...otherProps}
      />
    );
  };
}

export default withSorting(TableSummaryDetails);
