import React, { useState, useMemo, useContext } from "react";
import "../common/ProductsStyles";
import "../common/products-style.css";
import useStyles from "../common/ProductsStyles";
import EquitySubTab from "../equity/EquitySubTab";
import { ReactComponent as Updown } from "../../../../assets/icons/up-down-arrow.svg";
import { commonAPI, fetchEngagement } from "../../../../api";
import { useEffect } from "react";
import {
  staticEquityCorporateData,
  equityCorporateALlTableConfig,
} from "../equity/utils/constands";
import { objectFormatingEquityCorporate } from "../equity/utils/objectFormating";
import PmsReportTable from "../pms/PmsReportTable";
import { handleALL, handleHeaderChange } from "../equity/utils/common";
import { roundNumber } from "../../../utils/common";
import GlowCircle from "../../../charts/GlowCircle";
import PieChart from "../../../charts/PieChart";
import Engagement_model from "../../print/pages/Engagement_model";
import { pie_onClick, pie_onClick2 } from "../../../charts/nivo_objects";
import { colorAndProd } from "../utils/constands/prodAndColor";
import { useNavigate } from "react-router-dom";
import EngagmentLayout from "../newDesigns/common/EngagmentLayout";
import { OnboardingContext } from "../../../../Context";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import { currencyFormat } from "../../../../hooks/constantFunction";
import SummaryDetailsTable from "../common/SummaryDetailsTable";
import { generateTableColumnsForEngagementModel } from "./utils/utilHoldingEngagement";
const tableColumnsData = [
  { field: "assetClass", title: "Asset Class", show: true },
  { field: "investedAmount", title: "Invested Value", show: true },
  {
    field: "portfolioValueAmount",
    title: "Current Portfolio Value",
    show: true,
  },
  {
    field: "percentageOfTotalPortfolio",
    title: "% of Total Portfolio",
    show: true,
  },
  { field: "unRealisedGainLoss", title: "Unrealized Gain/Loss", show: true },
  { field: "totalGainLoss", title: "Total Gain/Loss", show: true },
  { field: "xirr", title: "XIRR (%)", show: true },
  { field: "absoluteTwrr", title: "Absolute TWRR (%)", show: true },
  { field: "annualisedTwrr", title: "Annualized TWRR (%)", show: true },
];
const bottomGrandTotalTableColumnsData = [
  { field: "product", title: "", show: true },
  { field: "investedAmount", title: "Invested Value", show: true },
  { field: "withDrawalAmount", title: "Withdrawals", show: true },
  { field: "portfolioValueAmount", title: "Current Value", show: true },
  {
    field: "totalPortfolioPercentage",
    title: "% of Total Portfolio",
    show: true,
  },
  { field: "realisedGainLoss", title: "Realized Gain/Loss", show: true },
  { field: "unRealisedGainLoss", title: "Unrealized Gain/Loss", show: true },
  { field: "xirrValue", title: "XIRR (%)", show: true },
  { field: "absoluteTwrr", title: "Absolute TWRR (%)", show: true },
  { field: "annualisedTwrr", title: "Annualized TWRR (%)", show: true },
];
export default function EquityEngagment() {
  // const [tableConfig, setTableConfig] = useState(equityCorporateALlTableConfig);
  const classes = useStyles();
  const [page, setPage] = useState("adjusted");
  const [option, setOption] = useState("All");
  const [tableColumns, setTableColumns] = useState([]);
  const [result, setResult] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [engagementData, setEngageMentData] = useState({});
  //const [tableHeader, setTableHeader] = useState(equityCorporateALlTableConfig);
  const [loading, setLoading] = useState(false);
  const [asset_color, setAsset_Color] = useState(Object.values(colorAndProd));
  const navigate = useNavigate();
  const colors = ["#F0DB90", "#6D67E4"];
  const [pie, setPie] = useState({
    title: "",
    value: "",
    colors: colors,
    disabled: [],
  });

  const { userState } = OnboardingContext();
  const [data1, setData] = useState([]);
  const [total, setTotal] = useState(null);
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  useEffect(() => {
    setLoading(true);
    const clientId = userState.loginedUserData.id;
    // const clientId = 199;

    let params = { ...getfilter_as_params() };

    fetchEngagement(clientId, params)
      .then((res) => {
        setEngageMentData(res.data.data.result);
        setTotal(res.data.data.result?.totalCurrentAmount);
        setData([
          {
            id: "Held",
            label: "Held",
            value: roundNumber(res.data.data.result?.heldPercentage),
            val: [
              roundNumber(res.data.data.result?.heldInvestment),
              roundNumber(res.data.data.result?.currentHeldAmount),
              roundNumber(res.data.data.result?.heldPercentage),
            ],

            color: "hsl(228, 70%, 50%)",
          },
          {
            id: "Held Away",
            label: "Held Away",
            value: roundNumber(res.data.data.result?.heldAwayPercentage),
            val: [
              roundNumber(res.data.data.result?.heldAwayInvestment),
              roundNumber(res.data.data.result?.currentHeldAwayAmount),
              roundNumber(res.data.data.result?.heldAwayPercentage),
            ],
            color: "hsl(154, 70%, 50%)",
          },
        ]);
      })
      .catch({})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleChangeInPage = (page, data) => {
    let newList = [];
    if (page == "adjusted") {
      data?.adjustedData?.map((val) => {
        newList.push(objectFormatingEquityCorporate(val, tableHeader));
      });
    } else {
      data?.upcomingData?.map((val) => {
        newList.push(objectFormatingEquityCorporate(val, tableHeader));
      });
    }

    setTableColumns(newList);
  };

  useEffect(() => {
    if (result) {
      handleChangeInPage(page, result);
    }
  }, [page]);

  const engagment_Content = {
    title: "Engagement Model",
    // content: (
    //   <>
    //     Engagement Model segregates the Client portfolio into Held & Held Away.
    //     Where in Held refers to <br></br> the Investments Directly managed by us
    //     & Held Away refers to the investments managed by other Advisors.
    //     {/* Holdings are the contents of an investment portfolio held by an individual
    //     or an entity, <br />
    //     such as a mutual fund or a pension fund. Portfolio holdings may encompass
    //     a wide */}
    //   </>
    // ),
  };
  const [newTableColumns, setNewTableColumns] = useState([]);
  const [tableHeader, setTableHeader] = useState(tableColumnsData);
  const [bottomTableHeader, setBottomTableHeader] = useState(
    bottomGrandTotalTableColumnsData
  );
  const [bottomTabel, setBottomTabel] = useState({
    // TODO TO SHOW THE GRAND TOTAL ROW UNCOMMENT THE FOLLOWING
    // grand_Total: [
    //   {
    //     field: "--",
    //     title: "",
    //     show: true,
    //   },
    //   {
    //     field: "₹316.59 Cr",
    //     title: "Invested Value",
    //     show: true,
    //   },
    //   {
    //     field: "₹305.73 Cr",
    //     title: "Current Value",
    //     show: true,
    //   },
    //   {
    //     field: "100.00",
    //     title: "% of Total Portfolio",
    //     show: true,
    //   },
    //   {
    //     field: "-₹67.09 Cr",
    //     title: "Unrealized Gain/Loss",
    //     show: true,
    //   },
    //   {
    //     field: "12.08",
    //     title: "XIRR (%)",
    //     show: true,
    //   },
    //   {
    //     field: "4.4904374872569e+24",
    //     title: "Absolute TWRR (%)",
    //     show: true,
    //   },
    //   {
    //     field: "948.33",
    //     title: "Annualized TWRR (%)",
    //     show: true,
    //   },
    // ],
  });

  return (
    <div style={{ marginTop: "0px" }}>
      {/* <EquitySubTab title="" importIconVisible={false}></EquitySubTab> */}

      <EngagmentLayout
        summaryDetailsSecConstData={engagment_Content}
        cardData1={data1}
        hideNavButtons={true}
        colors={colors}
        handleClick={() => navigate("/")}
        peiChart={
          <div className="flex_center tr20" style={{ height: "100%" }}>
            <div className={classes.wid380px}>
              <PieChart
                colors={pie.colors}
                // fill={pie.fill}
                config={{
                  innerRadius: "0.80",
                  padAngle: 0,
                  legends: false,
                  disableArcLink: true,
                  colors: ["#F0DB90", "#6D67E4"],
                  minWith: "380px",
                  cornerRadius: 0.3,
                  chart: {
                    width: 440,
                    height: 420,
                    arcLinkLabelsStraightLength: 15,
                    arcLinkLabelsDiagonalLength: 20,
                  },

                  arcLinkLabel: (d) => (
                    <tspan
                      x="0"
                      y="0"
                      style={{
                        fontFamily: "Avenir",
                        fontWeight: "400",
                        fontSize: "12",
                        textTransform: "capitalize",
                      }}
                    >
                      {" "}
                      {d.id}
                      <tspan
                        x="0"
                        y="18"
                        style={{
                          fontFamily: "Open Sans",
                          fontWeight: "700",
                          fontSize: "14",
                        }}
                      >
                        {" "}
                        {d.value}%
                      </tspan>
                    </tspan>
                  ),
                }}
                onClickEvent={{
                  click: pie_onClick,
                  set: setPie,
                  cstate: pie,
                  ncolors: colors,
                }}
                data={data1}
              >
                <GlowCircle>
                  <div className="circle_content">
                    <div>
                      <b className="circle_text">
                        {pie.title ? pie.title : "Portfolio Value"}
                      </b>
                      <br></br>
                      <b></b>
                      <div
                        className="circle_text2"
                        style={{ paddingTop: "5px" }}
                      >
                        {pie?.value
                          ? roundNumber(pie?.value) + "%"
                          : currencyFormat(total, undefined)}
                      </div>
                    </div>
                  </div>
                </GlowCircle>
              </PieChart>
            </div>
          </div>
        }
      />
      <SummaryDetailsTable
        setTableColumns={setNewTableColumns}
        tableColumns={generateTableColumnsForEngagementModel(engagementData)}
        headerList={tableColumnsData}
        tableHeader={tableHeader}
        setTableHeader={setTableHeader}
        tableHeaderBGColor="#16082e"
        bottomTabel={bottomTabel}
        setBottomTabel={setBottomTabel}
        setBottomTableHeader={setBottomTableHeader}
        loading={loading}
        stickyProductName={true}
        renderArrowButton={false}
      />
    </div>
  );
}
