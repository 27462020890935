import React from 'react'
import Default from '../components/dashboard/defaultPages/Default'
import RealEstateComp from '../components/dashboard/products/realEstate/RealEstateComp'

function RealEstate() {

    return (
        <>
            <Default>
                <RealEstateComp></RealEstateComp>
            </Default>
        </>
    )
}

export default RealEstate