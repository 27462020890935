import React from "react";

export default function Checkboxicon({ checked, color }) {
  return (
    <div style={{ display: "flex" }}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8Z"
          stroke={color || "#654E87"}
        />
        <path
          d="M8 129C10.7614 129 13 126.761 13 124C13 121.239 10.7614 119 8 119C5.23858 119 3 121.239 3 124C3 126.761 5.23858 129 8 129Z"
          fill={color || "#654E87"}
        ></path>
        <path
          d="M 7.962 13.057 C 10.723 13.057 12.962 10.819 12.962 8.057 C 12.962 5.296 10.723 3.057 7.962 3.057 C 5.201 3.057 2.962 5.296 2.962 8.057 C 2.962 10.819 5.201 13.057 7.962 13.057 Z"
          fill={!checked ? "transparent" : color || "#A647F5"}
        ></path>
      </svg>
    </div>
  );
}
