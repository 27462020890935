import React from "react";
import { currencyFormat } from "../../../../hooks/constantFunction";
import { dateFormatCommon, roundNumber } from "../../../utils/common";
import Default from "../../defaultPages/Default";
import PageFilter from "../common/PageFilter";
import useStyles from "../common/ProductsStyles";
import BondsHeading from "./BondsHeading";
import { routes } from "../../../../routes";

export default function Govbond({ data, apiData2, isin }) {
  const classes = useStyles();

  return (
    <>
      <Default>
        <PageFilter backLink={routes.bondsSummaryPage_transection} />
        <BondsHeading
          title={data.companyName}
          subtitle={
            " "
            // `*(${data.secDesc})`
          }
          type={data.typeOfBond}
          page={"govbonds"}
          isin={isin}
        />
        <div style={{ display: "flex", marginLeft: "6%" }}>
          <div className={classes.bondsSubTitleText}>Issuer :</div>
          <div
            className={classes.bondsSubTitleText2}
            style={{ marginLeft: "1%" }}
          >
            {" "}
          </div>
          <div className={classes.ipoBoxesText} style={{ marginLeft: "0.5%" }}>
            {" "}
          </div>
        </div>

        <div className={classes.ipoBoxes}>
          <div style={{ display: "flex", padding: "2%" }}>
            <div>
              <div className={classes.tableHeaderTextNew}>Face Value</div>
              <div className={classes.bondsText}>
                {currencyFormat(data.faceValue)}{" "}
              </div>
            </div>
            <hr className="filter-divider"></hr>
            <div>
              <div className={classes.tableHeaderTextNew}>Coupon Rate</div>
              <div className={classes.bondsText}>
                {roundNumber(data.couponRate)} %
              </div>
            </div>
            <hr className="filter-divider"></hr>
            <div>
              <div className={classes.tableHeaderTextNew}>Issue Date</div>
              <div className={classes.bondsText}>
                {data.issueDate
                  ? dateFormatCommon(data.issueDate, "YYYY-MM-DD")
                  : "..."}
              </div>
            </div>
            <hr className="filter-divider"></hr>
            <div style={{ alignContent: "center" }}>
              <div className={classes.tableHeaderTextNew}>Maturity Date</div>
              <div className={classes.bondsText}>
                {data.maturityDate
                  ? dateFormatCommon(data.maturityDate, "YYYY-MM-DD")
                  : "..."}
              </div>
            </div>
            <hr className="filter-divider"></hr>
            <div style={{ paddingRight: "3%" }}>
              <div className={classes.tableHeaderTextNew}>Remaining Period</div>
              <div className={classes.bondsText}>...</div>
            </div>
          </div>
        </div>

        <div className={classes.ipoBoxes}>
          <div style={{ display: "flex", padding: "2%" }}>
            <div>
              <div className={classes.tableHeaderTextNew}>Tax Free</div>
              <div className={classes.bondsText}>...</div>
            </div>
            <hr className="filter-divider"></hr>
            <div>
              <div className={classes.tableHeaderTextNew}>
                Investment Payment Type
              </div>
              <div className={classes.bondsText}>...</div>
            </div>
            <hr className="filter-divider"></hr>
            <div>
              <div className={classes.tableHeaderTextNew}>
                Interest Frequency
              </div>
              <div className={classes.bondsText}>{data.intFrequency}</div>
            </div>
            <hr className="filter-divider"></hr>
            <div>
              <div className={classes.tableHeaderTextNew}>Holding</div>
              <div className={classes.bondsText}>...</div>
            </div>
            <hr className="filter-divider"></hr>
            <div style={{ paddingRight: "3%" }}>
              <div className={classes.tableHeaderTextNew}>
                Max. Available Quantity
              </div>
              <div className={classes.bondsText}>...</div>
            </div>
          </div>
        </div>

        {/* <div className={classes.ipoBoxes}>
          <div className={classes.bondsHeadText} style={{ padding: "2%" }}>Cashflow</div>

          <div style={{ display: "flex", padding: "2%" }}>
            <div style={{ display: "flex", justifyContent: "center", margin: "1%", width: "18%", padding: "1%" }} className={classes.tableHeaderTextNew}>2022</div>
            <div style={{ display: "flex", justifyContent: "center", margin: "1%", width: "18%", padding: "1%" }} className={classes.tableHeaderTextNew}>2023</div>
            <div style={{ display: "flex", justifyContent: "center", margin: "1%", width: "18%", padding: "1%" }} className={classes.tableHeaderTextNew}>2025</div>
            <div style={{ display: "flex", justifyContent: "center", margin: "1%", width: "18%", padding: "1%" }} className={classes.tableHeaderTextNew}>2035</div>
            <div style={{ display: "flex", justifyContent: "center", margin: "1%", width: "18%", padding: "1%" }} className={classes.tableHeaderTextNew}>2059</div>
          </div>

          <div style={{ marginTop: "-3%", padding: "2%" }}>
            <hr className='equityipofilter-divider' style={{}}></hr>
          </div>

          <div style={{ display: "flex", padding: "1%" }}>
            <div style={{ display: "flex", justifyContent: "center", margin: "1%", width: "18%", padding: "1%" }} className={classes.tableHeaderTextNew}>21 Dec</div>
            <div style={{ display: "flex", justifyContent: "center", margin: "1%", width: "18%", padding: "1%" }} className={classes.tableHeaderTextNew}>21 Dec</div>
            <div style={{ display: "flex", justifyContent: "center", margin: "1%", width: "18%", padding: "1%" }} className={classes.tableHeaderTextNew}>21 Dec</div>
            <div style={{ display: "flex", justifyContent: "center", margin: "1%", width: "18%", padding: "1%" }} className={classes.tableHeaderTextNew}>21 Dec</div>
            <div style={{ display: "flex", justifyContent: "center", margin: "1%", width: "18%", padding: "1%" }} className={classes.tableHeaderTextNew}>21 Dec</div>
          </div>

          <div style={{ display: "flex", padding: "1%" }}>
            <div style={{ margin: "1%", width: "18%", padding: "1%" }}>
              <div className={classes.bondsTextNew} style={{ display: "flex", justifyContent: "center", color: "#7B61FF" }}>Your Investment of</div>
              <div className={classes.bondsText} style={{ display: "flex", justifyContent: "center", whiteSpace: "nowrap" }}>₹1,03,03,386.30</div>
            </div>
            <div style={{ margin: "1%", width: "18%", padding: "1%" }}>
              <div className={classes.bondsTextNew} style={{ display: "flex", justifyContent: "center", color: "#F7CB59" }}>Interest</div>
              <div className={classes.bondsText} style={{ display: "flex", justifyContent: "center" }}>₹7,84,000.00</div>
            </div>
            <div style={{ margin: "1%", width: "18%", padding: "1%" }}>
              <div className={classes.bondsTextNew} style={{ display: "flex", justifyContent: "center", color: "#F7CB59" }}>Interest</div>
              <div className={classes.bondsText} style={{ display: "flex", justifyContent: "center" }}>₹7,84,000.00</div>
            </div>
            <div style={{ margin: "1%", width: "18%", padding: "1%" }}>
              <div className={classes.bondsTextNew} style={{ display: "flex", justifyContent: "center", color: "#F7CB59" }}>Interest</div>
              <div className={classes.bondsText} style={{ display: "flex", justifyContent: "center" }}>₹7,84,000.00</div>
            </div>
            <div style={{ margin: "1%", width: "18%", padding: "1%" }}>
              <div className={classes.bondsTextNew} style={{ display: "flex", justifyContent: "center", color: "#F7CB59" }}>Interest</div>
              <div className={classes.bondsText} style={{ display: "flex", justifyContent: "center" }}>₹7,84,000.00</div>
            </div>
          </div>
        </div> */}

        <div className={classes.ipoBoxes}>
          <div style={{ display: "flex", padding: "2%" }}>
            <div>
              <div className={classes.tableHeaderTextNew}>
                Redemption Amount (A)
              </div>
              <div className={classes.bondsText}>
                {currencyFormat(data.redemptionAmount, true)}
              </div>
            </div>
            <hr className="filter-divider"></hr>
            <div>
              <div className={classes.tableHeaderTextNew}>
                Total Interest Payable (B)
              </div>
              <div className={classes.bondsText}>...</div>
            </div>
            <hr className="filter-divider"></hr>
            <div style={{ paddingRight: "5%" }}>
              <div className={classes.tableHeaderTextNew}>
                Net Cash Back (A+B)
              </div>
              <div className={classes.bondsText}>...</div>
            </div>
          </div>
        </div>
      </Default>
    </>
  );
}
