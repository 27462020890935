// import { useTheme } from "@mui/material/styles";
// import { useMediaQuery } from "@mui/material";
import {
  Container,
  Paper,
  Grid,
  TextField,
  FormControlLabel,
  InputLabel,
  Checkbox,
  Button,
  Typography,
  Link,
  IconButton,
  Box,
  useTheme,
} from "@material-ui/core";
import React from "react";
import ArrowButton from "../../components/dashboard/products/newDesigns/common/arrowButton/ArrowButton";
import "./PartnerOnboarding.css";
import BackButton from "./common/BackBtnWithArrow/BackButton";
export default function PartnerHeading({
  mainHead,
  subtitle,
  step,
  handleBack,
  marginLeft,
  noStep,
  hideBackButton,
  stepDeviation,
}) {
  const navBack = () => {
    handleBack(step - 1);
  };
  const theme = useTheme();
  const icons = theme.palette.icons;
  const BackIcon = icons.backIcon;

  return (
    <div className="dFlex alignLeft partnerHeadingContainer">
      {/* <Box style={{ ml: isMobile ? "-11px" : marginLeft }}> */}
      {!hideBackButton && (
        <div className="backBtnContainer1">
          <BackIcon onClick={navBack} />
        </div>
      )}
      <Box>
        {!noStep && (
          <Typography
            style={{
              color: "#FFFFFF",
              opacity: "0.84",
              mb: "6px",
              // letterSpacing: "0.5px",
            }}
            className="step"
          >
            STEP {stepDeviation ? step - stepDeviation : step}
          </Typography>
        )}
        <Typography
          style={{
            fontSize: "26px",
            fontWeight: "900",
            // letterSpacing: "0.5px",
            color: "white",
            marginTop: noStep ? "5px" : "9px",
            textTransform: "capitalize",
          }}
          className="generalFontFam varientH1"
          variant="h1"
        >
          {mainHead}
        </Typography>
        <Typography
          style={{
            color: "#FFFFFF",
            // opacity: "0.7",
            // letterSpacing: "0.5px",
            marginTop: "9px",
          }}
          className="varientH6 generalFontFam"
          variant="h6"
        >
          {subtitle ? subtitle : ""}
        </Typography>
      </Box>
    </div>
  );
}
