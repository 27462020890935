import { Modal } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useState } from "react";

const data = [
  { val: "a" },
  { val: "b" },
  { val: "c" },
  { val: "d" },
  { val: "e" },
];

function FamilyFunction() {
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState("");

  const handleClick = () => {
    setOpenModal(!openModal);
  };
  const formik = useFormik({
    initialValues: {
      optionSelect: "",
    },
    onSubmit: (values) => {
      setSelected(values.optionSelect);
    },
  });

  return (
    <>
      <div
        style={{
          margin: 30,
          padding: 10,
          borderRadius: 5,
          width: "20%",
          height: "200px",
          background: "darkgrey",
          color: "white",
          textAlign: "center",
        }}
      >
        <button onClick={handleClick}>+</button>
      </div>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(!openModal)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={{ position: "relative", outline: 0 }}>
          <div
            style={{
              background: " #220848",
              borderRadius: "20px",
              border: "1px solid #711CF1 !important",
              padding: "15px",
              overflow: "auto",
              boxShadow: "-4px 4px 10px rgba(0, 0, 0, 0.5)",
              position: "absolute",
              top: "250px",
              left: "350px",
            }}
          >
            <form onSubmit={formik.handleSubmit}>
              <div>
                <select
                  name="optionSelect"
                  value={formik.values.optionSelect}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                >
                  <option>Select anyone</option>
                  {data.map((c) => {
                    return (
                      <option name={c.val} value={c.val}>
                        {c.val}
                      </option>
                    );
                  })}
                </select>
              </div>
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default FamilyFunction;
