import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { OnboardingContext } from "../../../../Context";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import PmsReportTable from "../pms/PmsReportTable";
import { trasactionTableConfig } from "./constands";
import { ITEMS_LIMIT_FILES_PARTNER_LIST } from "../../../../Constants";
function RETransaction({
  getTableData,
  openModal,
  setOpenModal,
  loading,
  pageName,
  search,
  setOffset,
  offset,
}) {
  const { mfoFilter } = useContext(mfoFilterContext);
  const [tableHeader, setTableHeader] = useState(trasactionTableConfig);
  const [checkedTrans, setCheckedTrans] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [pagiNationData, setPageinationData] = useState({
    pageCount: null,
  });
  // const [offset, setOffset] = useState(0);

  useEffect(() => {
    getTableData({
      setTableColumns: setTableColumns,
      config: trasactionTableConfig,
      setCheckedTrans: setCheckedTrans,
      limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
      offset: offset,
      setPageinationData: setPageinationData,
      // schemeName: search,
    });
  }, [mfoFilter, offset]);

  return (
    <PmsReportTable
      setOpenModal={setOpenModal}
      setTableColumns={setTableColumns}
      tableColumns={tableColumns}
      openModal={openModal}
      headerList={trasactionTableConfig}
      loading={loading}
      //   pageName="transaction"
      // tableHeaderBGColor="#4E189F"
      tableHeader={tableHeader}
      setTableHeader={setTableHeader}
      checkedTransList={checkedTrans}
      stickyProductName={true}
      setItemOffset={setOffset}
      pageCount={pagiNationData?.pageCount}
      pageNumber={offset / ITEMS_LIMIT_FILES_PARTNER_LIST + 1 || 1}
    />
  );
}

export default RETransaction;
