import React from "react";
import { CheckedSvgIcon } from "./common/CheckedSvgIcon";
import { UncheckedSvgIcon } from "./common/UncheckedSvgIcon";
import { useState } from "react";
import { denominations } from "./newFilterConstants";
import { useNewFilterStyles } from "./newFilterStyles";

export const NewFilterDenomination = ({
  selectedDenomination,
  setSelectedDenomination,
}) => {
  const classes = useNewFilterStyles();
  return (
    <div className="newFilterOffShoreContainer">
      {denominations.map((denomination, index) => (
        <button
          key={index}
          onClick={() => setSelectedDenomination(denomination)}
          className={classes.offShoreBtn}
        >
          {selectedDenomination === denomination ? (
            <CheckedSvgIcon cssClassName="checkedSvgIcon" />
          ) : (
            <UncheckedSvgIcon cssClassName="uncheckedSvgIcon" />
          )}
          {denomination}
        </button>
      ))}
    </div>
  );
};
