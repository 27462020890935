import { useEffect } from "react";
import { useState } from "react";
import { fetchAdvisor } from "../../../../api";
import { OnboardingContext } from "../../../../Context";
import { useContext } from "react";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import { getMemberIdsFromSelectedMembersList } from "../utils/newFilterFormatter";

export const useAdvisors = (
  filterModalOpen,
  productType,
  selectedFamilyMembers,
  triggerFetchAdvisors
) => {
  const [advisors, setAdvisors] = useState([]);
  //   👇 TODO State for Search (Not in the design hence pending)
  const [advisorName, setAdvisorName] = useState("");
  const { userState } = OnboardingContext();
  const [selectedAdvisors, setSelectedAdvisors] = useState([]);
  const [advisorsLoading, setAdvisorsLoading] = useState(true);
  const { mfoFilter } = useContext(mfoFilterContext);
  let clientId = userState.loginedUserData.id;

  useEffect(() => {
    if (!triggerFetchAdvisors) return;
    let params = {
      memberId: getMemberIdsFromSelectedMembersList(selectedFamilyMembers),
    };
    setAdvisorsLoading(true);
    fetchAdvisor(clientId, params, productType)
      .then((res) => {
        setAdvisors(res?.result);
        if (Object.keys(mfoFilter?.advisor)?.length === 0) {
          setSelectedAdvisors(res?.result);
        }
      })
      .catch((err) => {
        console.log(`An ${err} has occurred`);
      })
      .finally(() => {
        setAdvisorsLoading(false);
      });
  }, [advisorName, filterModalOpen, productType, triggerFetchAdvisors]);
  return { advisors, selectedAdvisors, setSelectedAdvisors, advisorsLoading };
};
