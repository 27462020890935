import { Typography } from "@material-ui/core";
import React from "react";

function NewHeadAndDetailsComp({ title, content, customStyle }) {
  return (
    <div className="summaryDetailsHeadSec" style={customStyle?.container}>
      <Typography className={`summaryDetailsHead ${customStyle?.title}`}>
        {title}
      </Typography>
      {/* Removing content */}
      <Typography className="summaryDetailsTxt">{content}</Typography>
    </div>
  );
}

export default NewHeadAndDetailsComp;
