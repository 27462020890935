import React, { useEffect, useState } from "react";
import PageFilter from "../common/PageFilter";
import TabMfoMain from "../common/TabMfoMain";
import MfoFund from "./MfoFund";
import MfoHolding from "./MfoHolding";
import MfoOverall from "./MfoOverall";
import MfoPortfolio from "./MfoPortfolio";
import MfoSegment from "./MfoSegment";
import MfoSip from "./MfoSip";
import MutualFundSummary from "./MutualFundSummary";
import MfoTransaction from "./MfoTransaction";
import { currencyFormat } from "../../../../hooks/constantFunction";
import TabComponent from "../common/TabComponent";
import NewTabComp from "../common/NewTabComp";
import { routes } from "../../../../routes";
import { NewFilter } from "../../newFilter/NewFilter";
import { SHOW_ADDITIONAL_DATA_TAB } from "../../../../Config";

let data = [];
if (SHOW_ADDITIONAL_DATA_TAB === "show") {
  data = [
    {
      page: "detailed",
      title: "Detailed Summary",
      path: routes.mfDetailed_Detailed,
    },
    {
      page: "segment",
      title: "Sub-Category Allocation",
      path: routes.mfDetailed_SubCategory,
    },
    {
      page: "fund",
      title: "Fund Performance",
      path: routes.mfDetailed_fund,
    },
    {
      page: "holding",
      title: "Holding Summary",
      path: routes.mfDetailed_holding,
    },
    {
      page: "transaction",
      title: "Transaction Summary",
      path: routes.mfDetailed_transaction,
    },
    //   { page: "sip", title: "SIP Summary", path: "/home/mfo/mfo-summary/report/sip" },
  ];
} else {
  data = [
    {
      page: "detailed",
      title: "Detailed Summary",
      path: routes.mfDetailed_Detailed,
    },
    {
      page: "segment",
      title: "Sub-Category Allocation",
      path: routes.mfDetailed_SubCategory,
    },

    {
      page: "holding",
      title: "Holding Summary",
      path: routes.mfDetailed_holding,
    },
    {
      page: "transaction",
      title: "Transaction Summary",
      path: routes.mfDetailed_transaction,
    },
    //   { page: "sip", title: "SIP Summary", path: "/home/mfo/mfo-summary/report/sip" },
  ];
}

function MfoReport({ pageName }) {
  const [page, setPage] = useState(pageName);
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const changePage = (p) => {
    switch (p) {
      case "detailed":
        return (
          <MutualFundSummary
            currencyFormat={currencyFormat}
          ></MutualFundSummary>
        );

      case "segment":
        return <MfoSegment currencyFormat={currencyFormat}></MfoSegment>;

      //   case "sip":
      // return <MfoSip currencyFormat={currencyFormat} />;
      // return <></>;

      case "portfolio":
        return <MfoPortfolio currencyFormat={currencyFormat} />;

      case "overall":
        return <MfoOverall currencyFormat={currencyFormat} />;

      case "fund":
        return <MfoFund currencyFormat={currencyFormat} />;

      case "holding":
        return <MfoHolding currencyFormat={currencyFormat} />;

      case "transaction":
        return <MfoTransaction />;

      default:
        return <MfoSegment currencyFormat={currencyFormat}></MfoSegment>;
    }
  };

  // useEffect(() => {
  //     setPage(pageName)
  // }, [pageName])

  const handleClick = React.useCallback(
    (page) => {
      setPage(page);
    },
    [page]
  );

  return (
    <>
      <PageFilter
        backLink={routes.mfSummaryPage}
        setFilterModalOpen={setFilterModalOpen}
      ></PageFilter>
      <NewFilter
        filterModalOpen={filterModalOpen}
        setFilterModalOpen={setFilterModalOpen}
        productType="mf"
      />
      <div style={{ margin: "2%" }}>
        <NewTabComp
          data={data}
          // customWidth={"fit-content"}
          customHandle={handleClick}
          customSelect={data.findIndex((val) => val?.page == page)}
        />
      </div>
      {changePage(page)}
    </>
  );
}

export default MfoReport;
