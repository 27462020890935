import React, { useEffect, useState } from "react";
import EquitySubTab from "./EquitySubTab";
import "../common/ProductsStyles";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Modal,
  Button,
} from "@material-ui/core";
import useStyles from "../common/ProductsStyles";
import { ReactComponent as Updown } from "../../../../assets/icons/up-down-arrow.svg";
import CustomTable1 from "../../../utils/CustomTable1";
import { equityStockHead, stockSummaryStaticData } from "./utils/constands";
import { dateFormatCommon, roundNumber } from "../../../utils/common";
import {
  currencyFormatCheckArray,
  dateFormatingArray,
  doNothingArray,
  stockNavData,
} from "../../../utils/constands";
import { currencyFormat } from "../../../../hooks/constantFunction";
import { getTransactionsSummary } from "../../../../api";
import TabComponent from "../common/TabComponent";
import { useNavigate } from "react-router-dom";
import NewTabComp from "../common/NewTabComp";
import { routes } from "../../../../routes";

export default function EquityStock() {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [page, setPage] = useState("all");
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();

  const objectFormating = (data, headerData) => {
    let newData = headerData.map((header) => {
      if (header?.field[0] == "stockName") {
        return {
          field: { value: data[`${header.field[0]}`] },
          title: header.title,
          show: header.show,
          subHeader: header?.subHeader,
          align: header?.align,
          showArrow: header?.showArrow,
          onClick: () => {
            navigate(routes?.equityStockdetails + `${data?.isin}`, {
              state: { isin: data?.isin },
            });
          },
        };
      }
      if (doNothingArray.includes(header?.field[0])) {
        return {
          field: { value: data[`${header.field[0]}`] },
          title: header.title,
          show: header.show,
          subHeader: header?.subHeader,
          align: header?.align,
          showArrow: header?.showArrow,
        };
      }
      // if (header?.field[0] == "quantity") {
      //   return {
      //     field: {
      //       value: data[`${header.field[0]}`],
      //       precentage: currencyFormat(data[`${header.field[1]}`]),
      //     },
      //     title: header.title,
      //     show: header.show,
      //     subHeader: header?.subHeader,
      //     align: header?.align,
      //     showArrow: header?.showArrow,
      //   };
      // }
      return {
        field: {
          precentage: !isNaN(data[`${header.field[1]}`])
            ? roundNumber(Number(data[`${header.field[1]}`]))
            : data[`${header.field[1]}`] || "--",
          value: !isNaN(data[`${header.field[0]}`])
            ? currencyFormat(Number(data[`${header.field[0]}`]))
            : data[`${header.field[0]}`] || "--",
        },
        title: header.title,
        show: header.show,
        subHeader: header?.subHeader,
        align: header?.align,
        showArrow: header?.showArrow,
      };
    });
    return newData;
  };

  const customApiCall = async (
    params,
    setTableColumns,
    setLoading,
    tableHeader
  ) => {
    setLoading(true);
    // params.clientId = 199;
    params.type = page;
    try {
      let res = await getTransactionsSummary(
        "equity",
        "stock-summary",
        params,
        toggle ? "" : "current-holding-"
      );
      let newlist = [];
      res?.data?.data?.result?.stocks?.map((val) => {
        newlist.push(objectFormating(val, tableHeader));
      });
      // stockSummaryStaticData?.data?.result?.stocks?.map((val) => {
      //   newlist.push(objectFormating(val, tableHeader));
      // });

      let totalData = {
        // isin: "INF109KC1KT9",
        stockName: "Total",
        quantity: 0,
        investmentValue: res?.data?.data?.result?.totalInvestedValue,
        withdrawalAmount: 0,
        quantityAvgPrice: 0,
        previousClosePrice: 0,
        previousClosePercent: 0,
        latestValue: res?.data?.data?.result?.totalLatestValue,
        totalGainLoss: 0,
        realisedGainLoss: 0,
        unRealisedGainLoss: 0,
        unRealisedGainLossPercent: 0,
        percentageOfInvestedValue: 0,
        percentageOfLatestValue: 0,
      };

      let totalRow = tableHeader.map((header) => {
        if (header?.field[0] == "stockName") {
          return {
            field: {
              value: totalData[`${header.field[0]}`],
              // precentage: currencyFormat(totalData[`${header.field[1]}`]),
            },
            title: header.title,
            show: true,
            subHeader: header?.subHeader,
            align: "center",
            showArrow: header?.showArrow,
            hideData: false,
          };
        }
        if (
          header?.field[0] == "investmentValue" ||
          header?.field[0] == "latestValue"
        ) {
          return {
            field: {
              value: currencyFormat(totalData[`${header.field[0]}`]),
              // precentage: currencyFormat(totalData[`${header.field[1]}`]),
            },
            title: header.title,
            show: true,
            subHeader: header?.subHeader,
            align: "center",
            showArrow: header?.showArrow,
            hideData: false,
          };
        }
        return {
          field: {
            value: totalData[`${header.field[0]}`],
            // precentage: currencyFormat(totalData[`${header.field[1]}`]),
          },
          title: header.title,
          show: true,
          subHeader: header?.subHeader,
          align: header?.align,
          showArrow: header?.showArrow,
          hideData: true,
        };
      });
      if (res?.data?.data?.result?.stocks?.length > 0) {
        newlist.push(totalRow);
      }

      setTableColumns(newlist);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div>
      <EquitySubTab
        title="Stock Summary"
        importIconVisible={false}
        editColumnIconVisible={true}
        setOpenModal={setOpenModal}
        showCurrencyFormat={true}
        toggle={toggle}
        settoggle={setToggle}
      ></EquitySubTab>
      <div
        style={{
          background: "#220848",
          boxShadow:
            "-6.22302px -6.22302px 18.6691px #302A64, 6.22302px 6.22302px 18.6691px #000000",
          borderRadius: "124.46px",
        }}
      ></div>

      <div
        style={{
          marginTop: "3%",
          boxShadow:
            "-4px 4px 6px #000000, 4px -4px 10px rgba(89, 22, 189, 0.5), inset -4px 4px 20px #000000, inset 3px -4px 10px #5916BD",
          borderRadius: "20px",
          padding: "15px 5px",
        }}
        className={classes.tableContainerAlign}
      >
        {/* <TabComponent
          type={"state"}
          page={page}
          setPage={setPage}
          data={stockNavData}
          width="fit-content"
          tabType="stock-summary"
        /> */}
        <div style={{ marginInline: "2%" }}>
          <NewTabComp
            data={stockNavData}
            customHandle={(page) => {
              setPage(page);
            }}
            customWidth="fit-content"
          />
        </div>
        {/* <button
          onClick={() => {
            setOpenModal(!openModal);
          }}
        >
          open modal
        </button> */}
        <CustomTable1
          headerConfig={equityStockHead}
          soratableTable={true}
          product={"equity"}
          tableName={"stock-summary"}
          objectFormating={objectFormating}
          customApiCall={customApiCall}
          tableHeaderBGColor={"#1D1B36"}
          tableBodyBackground={"#110425"}
          openModal={openModal}
          setOpenModal={setOpenModal}
          useEffectDepArray={[page, toggle]}
        />
      </div>
    </div>
  );
}
