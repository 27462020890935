import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import DataNotFound from "../products/common/DataNotFound";

function CustomTableColSpan2({
  tableHeaderBGColor,
  tableHeader,
  tableColumns,
  loading,
  tableBodyBackground,
}) {
  const getTableHeader = (data) => {
    return (
      <>
        <TableRow>
          {data?.map((rows, index) => (
            <TableCell
              className={`thStyle2 ${index !== 0 ? `borderLeft` : ""}`}
              //   key={rows?.title}
              align="center"
              style={{
                borderBottom: "none",
                textTransform: "capitalize",
                backgroundColor: tableHeaderBGColor || "#4E189F",
                color: "white",
              }}
              colSpan={rows?.owner ? 2 : 1}
            >
              <div className="customTabllCell">{rows?.owner}</div>
            </TableCell>
          ))}
        </TableRow>
        <TableRow className="stickSecondRow">
          {data?.map((rows, i) =>
            rows?.subHeading.map((subHeading, index) => {
              return (
                <TableCell
                  className={`${index == 0 && i !== 0 ? `borderLeft` : ""}`}
                  key={subHeading}
                  align="center"
                  style={{
                    color: "white",
                    borderBottom: "none",
                    textTransform: "capitalize",
                    backgroundColor: tableHeaderBGColor || "#4E189F",
                  }}
                  //   colSpan={rows?.owner ? 2 : 1}
                >
                  <div
                    className="customTabllCell"
                    style={{ width: "max-content" }}
                  >
                    {subHeading}
                  </div>
                </TableCell>
              );
            })
          )}
        </TableRow>
      </>
    );
  };

  const getValueCol = (value) => {
    return Object.keys(value).map((col) => {
      return (
        <TableCell
          //   key={column.title}
          align={"center"}
          //   className={"tdPms"}
          style={{
            // position: "relative",
            // cursor: column?.onClick ? "pointer" : "",
            // paddingTop: "38px",
            backgroundColor: tableBodyBackground || "#231E53",
            color: "white",
          }}
        >
          <div className="customTabllCell">{value[col]}</div>
        </TableCell>
      );
    });
  };

  const getTablebody = (data) => {
    return data.map((row) => {
      return (
        <TableRow className="tableRowPms" style={{ background: "#1D1B36" }}>
          {row.map((column) =>
            column?.value ? (
              getValueCol(column?.value)
            ) : (
              <TableCell
                //   key={column.title}
                align={"left"}
                // className={"tdPms"}
                style={{
                  // position: "relative",
                  // cursor: column?.onClick ? "pointer" : "",
                  //   paddingTop: "38px",
                  color: "white",
                  backgroundColor: tableBodyBackground || "#231E53",
                }}
              >
                <div className="customTabllCell">{column?.title}</div>
              </TableCell>
            )
          )}
        </TableRow>
      );
    });
  };

  return (
    <>
      <div
        style={{
          minHeight: "300px",
          width: "-webkit-fill-available",
          margin: "2%",
          maxHeight: "500px",
          display: "flex",
          position: "relative",
        }}
      >
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              className="tableRowStyle tableSticky"
              style={{ position: "relative" }}
            >
              {getTableHeader(tableHeader)}
            </TableHead>
            <TableBody className="tableBodyPms tableSticky">
              <TableRow style={{ height: "10px" }} />
              {getTablebody(tableColumns)}
            </TableBody>
          </Table>
        </TableContainer>
        {tableColumns?.length == 0 && (
          <div
            style={{
              position: "absolute",
              left: "44%",
              top: "14%",
            }}
          >
            {!loading && <DataNotFound />}
          </div>
        )}
      </div>
    </>
  );
}

export default CustomTableColSpan2;
