import { useContext, useEffect, useState } from "react";
import { OnboardingContext } from "../../../../Context";
import { fetchExecutor } from "../../../../api";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import { getMemberIdsFromSelectedMembersList } from "../utils/newFilterFormatter";

export const useExecutedBy = (
  filterModalOpen,
  productType,
  selectedFamilyMembers,
  triggerFetchExecutedBy
) => {
  const [executors, setExecutors] = useState([]);
  const [selectedExecutors, setSelectedExecutors] = useState([]);
  const [executedByLoading, setExecutedByLoading] = useState(true);
  const { userState } = OnboardingContext();
  let clientId = userState.loginedUserData.id;
  const { mfoFilter } = useContext(mfoFilterContext);

  useEffect(() => {
    if (!triggerFetchExecutedBy) return;

    let params = {
      memberId: getMemberIdsFromSelectedMembersList(selectedFamilyMembers),
    };
    setExecutedByLoading(true);
    fetchExecutor(clientId, params, productType)
      .then((res) => {
        setExecutors(res?.result?.filter((val) => val !== null));
        if (Object.keys(mfoFilter?.executor)?.length === 0) {
          setSelectedExecutors(res?.result?.filter((val) => val !== null));
        }
      })
      .catch((err) => {
        console.log(`An ${err} has occurred`);
      })
      .finally(() => {
        setExecutedByLoading(false);
      });
  }, [filterModalOpen, productType, triggerFetchExecutedBy]);
  return {
    executors,
    selectedExecutors,
    setSelectedExecutors,
    executedByLoading,
  };
};
