import { Box, Grid, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "./StrategyBuilder.css";
import { ReactComponent as MyPortfolioIcon } from "../../../assets/icons/newMenuMyPortfolioIcon.svg";
import { StrategyBuilderCard } from "./StrategyBuilderCard";
import { StrategyBuilderMemberCheckBoxList } from "./StrategyBuilderMemberCheckBoxList";
import { StrategyBuilderMemberRadioList } from "./StrategyBuilderMemberRadioList";
import { SelectedMemberList } from "./SelectedMemberList";
import { SelectedIndividual } from "./SelectedIndividual";
import TopBackComp from "./common/TopBackComp";
import FullWidthInput from "../../common/FullWidthInput";
import { fetchAllClients, fetch_family_hierarchy } from "../../../api";
import { OnboardingContext } from "../../../Context";
import PageLoader from "../products/common/pageLoader";
let svgIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill="#55A47E"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.0234 9.15765L11.3372 4.93849C11.4664 4.83678 11.6228 4.77552 11.7868 4.76242C11.9507 4.74932 12.1149 4.78495 12.2586 4.86484C12.4024 4.94473 12.5193 5.06531 12.5948 5.21145C12.6702 5.35759 12.7008 5.52276 12.6826 5.68622C12.6704 5.79502 12.6368 5.90033 12.5837 5.99607C12.5306 6.09182 12.4591 6.17611 12.3732 6.24409L6.33749 11.0352L6.27889 11.0821C6.25474 11.099 6.2297 11.1146 6.20388 11.1289L6.17107 11.15C5.98052 11.2485 5.75933 11.2695 5.55364 11.2088C5.34794 11.1481 5.17362 11.0103 5.06705 10.8242L4.9733 10.6672L3.42393 7.97394C3.3696 7.87944 3.33441 7.77517 3.32038 7.66707C3.30635 7.55897 3.31375 7.44917 3.34215 7.34393C3.37056 7.2387 3.41941 7.14008 3.48593 7.05373C3.55244 6.96737 3.63532 6.89496 3.72982 6.84063C3.92067 6.73091 4.14729 6.70149 4.35982 6.75886C4.46506 6.78726 4.56367 6.83612 4.65003 6.90263C4.73639 6.96915 4.8088 7.05202 4.86313 7.14652L6.02574 9.15765H6.0234Z"
      fill="white"
    />
  </svg>
);
export const StrategyBuilderSelection = ({
  handleNext,
  handleBack,
  rowData,
}) => {
  const { userState } = OnboardingContext();
  const CONSOLIDATED_CARD = "consolidated";
  const INDIVIDUAL_CARD = "individual";
  function updateCheckedStatusForMembers(data, memberNames) {
    return data.map((item) => ({
      ...item,
      checked: memberNames?.includes(item.full_name) ? true : item.checked,
    }));
  }

  const [selectedCard, setSelectedCard] = useState(
    rowData?.strategyType || CONSOLIDATED_CARD
  );
  const [selectAll, setSelectAll] = useState(false);
  const [laoding, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [searchlist, setSearchList] = useState([]);
  // State For Listing Members With ChecBox
  const [checkboxes, setCheckboxes] = useState([]);
  console.log(
    "StrategyBuilderSelection",
    rowData,
    checkboxes,
    updateCheckedStatusForMembers(checkboxes, rowData?.members)
  );
  // State For Listing Members With Radio Button
  const [members, setMembers] = useState([]);
  let selectedMembers = checkboxes.filter((checkbox) => checkbox.checked);
  const handleCheckboxChange = (index) => {
    let chk;
    if (search.length > 0) {
      chk = searchlist;
    } else {
      chk = checkboxes;
    }
    const updatedCheckboxes = [...chk];
    updatedCheckboxes[index].checked = !updatedCheckboxes[index].checked;
    if (search.length > 0) {
      setSearchList(updatedCheckboxes);

      updateMainChkList(updatedCheckboxes[index]);
    } else {
      setCheckboxes(updatedCheckboxes);
    }

    // Check if all checkboxes are checked or not
    let selectall = checkboxes.every((checkbox) => checkbox.checked);
    setSelectAll(selectall);
  };
  // Individual Selected Member Radio Btn
  const [selectedMember, setSelectedMember] = useState(
    rowData
      ? {
          type: "radio",
          member_id: rowData?.memberIds[0],
          name: rowData?.members[0],
          strategyType: INDIVIDUAL_CARD,
        }
      : null
  );

  const handleRadioChange = (event, index) => {
    let obj = {
      type: "radio",
      member_id: members[index].member_id,
      name: event.target.value,
      strategyType: INDIVIDUAL_CARD,
    };
    setSelectedMember(obj);
  };

  const handleSelectAllChange = () => {
    const updatedCheckboxes = checkboxes.map((checkbox) => ({
      ...checkbox,
      checked: !selectAll,
    }));
    setCheckboxes(updatedCheckboxes);
    setSelectAll(!selectAll);
  };

  // useEffect(() => {
  //   if (search.length > 0) {
  //     const clientId = userState.loginedUserData.id;
  //     fetchAllClients(clientId, { isHierarchy: 0, name: search }).then(
  //       (res) => {

  //       }
  //     );
  //   }
  // }, [search]);

  function updateMainChkList(s) {
    let index = checkboxes.findIndex((c, i) => {
      if (c.full_name == s.full_name) {
        return true;
      }
    });
    console.log(index, "index");
    if (index == -1 && s.checked) {
      checkboxes.push(s);
    } else {
      checkboxes[index].checked = s.checked;
    }
  }

  // API CALL 👇
  useEffect(() => {
    const clientId =
      localStorage.getItem("memberId") || userState.loginedUserData.id;
    setLoading(true);
    fetchAllClients(clientId, { name: search })
      .then((res) => {
        // Map and format the retrieved data to create formatted checkbox data

        let formattedCheckBoxData = res?.data?.data?.result.map((item) => ({
          ...item,
          name: item?.full_name,
          checked: false,
          strategyType: CONSOLIDATED_CARD,
        }));

        // Extract the full names to create radio data options
        let radioData = res?.data?.data?.result.map((item) => ({
          ...item,
          name: item?.full_name,
        }));
        // Handle Edit State
        if (search && search.length > 0) {
          checkboxes.filter((c) => {
            if (c.checked) {
              formattedCheckBoxData.find((s) => {
                if (s.full_name == c.full_name) {
                  s.checked = true;
                }
              });
            }
          });

          setSearchList((prev) => {
            return updateCheckedStatusForMembers(
              formattedCheckBoxData,
              rowData?.members
            );
          });
          // let editedRadioObj = {
          //   type: "radio",
          //   member_id: rowData?.memberIds[0],
          //   name: rowData?.members[0],
          //   strategyType: INDIVIDUAL_CARD,
          // };
          // setSelectedMember(editedRadioObj);
        } else {
          if ((search == "" || search == null) && checkboxes.length == 0) {
            console.log(search.length, checkboxes, "searchlist cleared");
            setCheckboxes((prev) => {
              return updateCheckedStatusForMembers(
                formattedCheckBoxData,
                rowData?.members
              );
            });
          } else {
            // console.log("searchlist cleared");
            // console.log(checkboxes);
            // searchlist.map((s) => {
            //   let index = checkboxes.findIndex((c, i) => {
            //     if (c.full_name == s.full_name) {
            //       return true;
            //     }
            //   });
            //   console.log(index, "index");
            //   if (index == -1 && s.checked) {
            //     checkboxes.push(s);
            //   } else {
            //     checkboxes[index].checked = s.checked;
            //   }
            // });
            setSearchList([]);
          }
          // let editedRadioObj = {
          //   type: "radio",
          //   member_id: rowData?.memberIds[0],
          //   name: rowData?.members[0],
          //   strategyType: INDIVIDUAL_CARD,
          // };
          // setSelectedMember(editedRadioObj);
        }
        // if (rowData) {
        //   // setSelectedCard(rowData?.strategyType);
        // } else {
        //   setCheckboxes(formattedCheckBoxData);
        // }
        setMembers(radioData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(`An Error ${err} has occurred`);
        setLoading(false);
      });
  }, [rowData, search]);
  // const isAnyCheckboxSelected = checkboxes.some((checkbox) => checkbox.checked);
  const atLeastTwoMembersSelected =
    checkboxes.filter((checkbox) => checkbox.checked).length >= 2;

  console.log("dfdsddssds", members, selectedMember, rowData);
  return (
    <>
      <PageLoader showLoader={laoding} />
      <TopBackComp handleClick={handleBack} title={"Strategy Builder"} />

      <Grid container className="strategyBuilderSelectionContainer">
        {/* Left Side */}
        <Grid item xs={5} className="leftSideStrategyBuilderSelection">
          <StrategyBuilderCard
            onClick={() => setSelectedCard(CONSOLIDATED_CARD)}
            className={
              selectedCard === CONSOLIDATED_CARD &&
              "strategyBuilderCardSelected"
            }
            icon={
              <Box className="strategyBuilderCardIconWrapper">
                <MyPortfolioIcon />
                <div style={{ marginBottom: "auto" }}>
                  {selectedCard === CONSOLIDATED_CARD && svgIcon}
                </div>
              </Box>
            }
            heading={
              <h1 className="strategyBuilderCardHeading">Consolidated</h1>
            }
            subHeading={
              <p className="strategyBuilderCardSubHeading">
                A consolidated target portfolio for multiple members combines
                the investment preferences and objectives of several individuals
                into a single diversified portfolio.
              </p>
            }
          />
          <StrategyBuilderCard
            onClick={() => setSelectedCard(INDIVIDUAL_CARD)}
            className={
              selectedCard === INDIVIDUAL_CARD && "strategyBuilderCardSelected"
            }
            icon={
              <Box className="strategyBuilderCardIconWrapper">
                <MyPortfolioIcon />
                <div style={{ marginBottom: "auto" }}>
                  {selectedCard === INDIVIDUAL_CARD && svgIcon}
                </div>
              </Box>
            }
            heading={<h1 className="strategyBuilderCardHeading">Individual</h1>}
            subHeading={
              <p className="strategyBuilderCardSubHeading">
                An individual target portfolio is a customized investment blend
                designed to match a person's financial goals and risk tolerance,
                encompassing various asset classes and specific allocations.
              </p>
            }
          />
        </Grid>
        {/* Right Side */}
        <Grid item xs={7} className="rightSideStrategyBuilderSelection">
          {/* Search Bar Start */}
          <FullWidthInput
            setSearch={setSearch}
            customInputStyle={{ height: "54px" }}
            customWrapperStyle={{ marginBottom: "0px" }}
          />
          {/* Search Bar End */}
          {/* <div style={{ marginTop: "28px" }}> */}
          {/* Selected Member List Display Start */}
          {selectedCard === CONSOLIDATED_CARD && (
            <SelectedMemberList
              selectedMembers={selectedMembers}
              svgIcon={svgIcon}
            />
          )}
          {/* Selected Member List Display End */}

          {/* Selected Individual Display Start */}
          {selectedCard === INDIVIDUAL_CARD && (
            <SelectedIndividual
              selectedMember={selectedMember}
              svgIcon={svgIcon}
            />
          )}
          {/* </div> */}
          {/* Selected Individual Display End */}

          <Box className="strategyBuilderMemberHeadingWrapper">
            <h1 className="strategyBuilderMemberHeading">Member</h1>
            {selectedCard === CONSOLIDATED_CARD && (
              <div className="formGroupSelectAll">
                <input
                  id="selectAll"
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                />
                <label
                  className="strategyBuilderMemberHeading"
                  htmlFor="selectAll"
                >
                  Select All
                </label>
              </div>
            )}
          </Box>

          {selectedCard === CONSOLIDATED_CARD && searchlist.length == 0 && (
            <>
              <StrategyBuilderMemberCheckBoxList
                key={"checkboxes"}
                checkboxes={checkboxes}
                handleCheckboxChange={handleCheckboxChange}
              />
            </>
          )}
          {selectedCard === CONSOLIDATED_CARD && searchlist.length > 0 && (
            <>
              <StrategyBuilderMemberCheckBoxList
                key={search}
                checkboxes={searchlist}
                handleCheckboxChange={handleCheckboxChange}
              />
            </>
          )}
          {selectedCard === INDIVIDUAL_CARD && (
            <StrategyBuilderMemberRadioList
              options={members}
              selectedOption={selectedMember}
              onChange={handleRadioChange}
            />
          )}
          {selectedCard === CONSOLIDATED_CARD && (
            <Button
              contained
              className="confirmBtn portfolioBtn"
              disabled={!atLeastTwoMembersSelected}
              onClick={() => handleNext(selectedMembers)}
            >
              Continue
            </Button>
          )}
          {selectedCard === INDIVIDUAL_CARD && (
            <Button
              contained
              className="confirmBtn portfolioBtn"
              disabled={!selectedMember}
              onClick={() => handleNext([selectedMember])}
            >
              Continue
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};
