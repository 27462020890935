import React, { useState } from "react";
import { ReactComponent as Back } from "../../../../../assets/holding-summary/back-blue.svg";

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Modal,
  Button,
} from "@material-ui/core";
import useStyles from "../../common/ProductsStyles";
import { useNavigate } from "react-router-dom";
import SearchInput from "../../common/SearchInput";
import { useIpoTransactionSummary } from "../../../../../hooks/useIpoTransactionSummary";
import DataNotFound from "../../common/DataNotFound";
import Loader from "../../../../loader/Loader";
import { currencyFormat } from "../../../../../hooks/constantFunction";
import PmsReportTable from "../../pms/PmsReportTable";
import { useEffect } from "react";
import { objectFormating1 } from "../../../../utils/objectFormating";
import { stData } from "./constands";
import { firstLetterCapitalize } from "../../../../utils/strHelper";
import moment from "moment";

let align = "center";
const tableColumnsData = [
  { field: "company_name", title: "Company Name", show: true, align: align },
  { field: "pan", title: "PAN NO.", show: true },
  { field: "advisor", title: "Advisor", show: true, align: align },
  { field: "ISIN", title: "ISIN No", show: true, align: align },
  { field: "stock_symbol", title: "Stock Symbol", show: true, align: align },
  { field: "type", title: "Type", show: true, align: align },
  {
    field: "application_no",
    title: "Application No",
    show: true,
    align: align,
  },
  {
    field: "application_date",
    title: "Application Date",
    show: true,
    align: align,
  },
  { field: "application_amount", title: "Application Amount", show: true },
  { field: "lot_size", title: "Lots Size", show: true },
  { field: "lot_applied", title: "Lots Applied", show: true, align: align },
  { field: "shares_applied", title: "Shares Applied", show: true },
  { field: "bid_amount_per_share", title: "Bid Amount Per Share", show: true },
  {
    field: "allotment_date",
    title: "Allotment Date",
    show: true,
    align: align,
  },
  { field: "allotted_shares", title: "Allotted Shares", show: true },
  { field: "allotted_price", title: "Allotted Price", show: true },
  { field: "allotted_amount", title: "Allotted Amount", show: true },
  { field: "refund_date", title: "Refund Date", show: true, align: align },
  { field: "refund_amount", title: "Refund Amount", show: true },

  // { field: "scheme_name", title: "Scheme/Product Name", show: true },
  // { field: "asset_type", title: "Asset Type", show: true },
  // { field: "product_sud_category", title: "Product Sud Category", show: true },
  // { field: "class", title: "Class () a,b,c,d,e", show: true },
  // { field: "AIF_type", title: "AIF Type", show: true },
  // { field: "quantity_units", title: "Quantity/Units", show: true },
  // { field: "purchase_rate", title: "Purchase Rate/NAV", show: true },
  // { field: "current_unit_balance", title: "Current Unit Balance", show: true },
  // { field: "current_rate", title: "Current Rate/Nav", show: true },
  // { field: "commited_amount", title: "Commited Amount", show: true },
  // { field: "invested_value", title: "Invested Value", show: true },
  // { field: "drawdowns", title: "Drawdowns", show: true },
  // {
  //   field: "compensation_contribution",
  //   title: "Compensation Contribution",
  //   show: true,
  // },
  // { field: "current_value", title: "Current Value", show: true },
  // { field: "load", title: "Load", show: true },
  // { field: "dividend_recieved", title: "Dividend Recieved", show: true },
  // { field: "coupon_date", title: "Coupon Date", show: true },
  // { field: "par_value", title: "Par Value", show: true },
  // { field: "principal_paid_back", title: "Principal Paid Back", show: true },
  // { field: "total_portfolio", title: "% of Total Portfolio", show: true },
  // { field: "unrealised_gain_loss", title: "Unrealised Gain/Loss", show: true },
  // { field: "realised_gain_loss", title: "Realised Gain/Loss", show: true },
  // { field: "XIRR", title: "XIRR (%)", show: false },
  // { field: "TWRR_absolute", title: "TWRR Absolute", show: false },
  // { field: "TWRR_annualised", title: "TWRR Annualised", show: false },
];
function IpoHolding({ open, setOpen }) {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState(null);
  const [transactionSummary, loading] = useIpoTransactionSummary(searchText);
  const classes = useStyles();

  const [tableColumns, setTableColumns] = useState([]);
  const [tableHeader, setTableheader] = useState(tableColumnsData);
  // const [tableColumnsData, setTableColumnsData] = useState([tableColumnsDataDetails])

  const tableHeaders = (headers) => {
    return headers?.map((column) =>
      column && column.show ? (
        <TableCell
          style={{ borderBottom: "none", textTransform: "capitalize" }}
          key={column.title}
          align="left"
          className={`${classes.tableHeadBlueText} thTop`}
        >
          {column.title}
        </TableCell>
      ) : null
    );
  };
  const tableBody = (body) => {
    return body?.map((column) => (
      <TableRow className={`${classes.tableBodyBlue} tableRowAif`}>
        <TableCell
          key={column?.pan}
          align="left"
          className={`${classes.tableBodyText} tdAif`}
          style={{
            position: "relative",
            paddingRight: column.title == "advisor" ? "92px" : "0px",
            paddingTop: "38px",
          }}
        >
          {column?.pan}
        </TableCell>
        <TableCell
          key={column?.advisor}
          align="left"
          className={`${classes.tableBodyText} tdAif`}
          style={{
            position: "relative",
            paddingRight: column.title == "advisor" ? "92px" : "0px",
            paddingTop: "38px",
          }}
        >
          {firstLetterCapitalize(column?.advisor)}
        </TableCell>
        <TableCell
          key={column?.isin}
          align="left"
          className={`${classes.tableBodyText} tdAif`}
          style={{
            position: "relative",
            paddingRight: column.title == "advisor" ? "92px" : "0px",
            paddingTop: "38px",
          }}
        >
          {column?.isin}
        </TableCell>
        <TableCell
          key={column?.stock_symbol}
          align="left"
          className={`${classes.tableBodyText} tdAif`}
          style={{
            position: "relative",
            paddingRight: column.title == "advisor" ? "92px" : "0px",
            paddingTop: "38px",
          }}
        >
          {column?.stock_symbol}
        </TableCell>
        <TableCell
          key={column?.type}
          align="left"
          className={`${classes.tableBodyText} tdAif`}
          style={{
            position: "relative",
            paddingRight: column.title == "advisor" ? "92px" : "0px",
            paddingTop: "38px",
          }}
        >
          {column?.type}
        </TableCell>
        <TableCell
          key={column?.company_name}
          align="left"
          className={`${classes.tableBodyText} tdAif`}
          style={{
            position: "relative",
            paddingRight: column.title == "advisor" ? "92px" : "0px",
            paddingTop: "38px",
          }}
        >
          {column?.company_name}
        </TableCell>
        <TableCell
          key={column?.application_no}
          align="center"
          className={`${classes.tableBodyText} tdAif`}
          style={{
            position: "relative",
            paddingRight: column.title == "advisor" ? "92px" : "0px",
            paddingTop: "38px",
          }}
        >
          {column?.application_no}
        </TableCell>
        <TableCell
          key={column?.application_date}
          align="center"
          className={`${classes.tableBodyText} tdAif`}
          style={{
            position: "relative",
            paddingRight: column.title == "advisor" ? "92px" : "0px",
            paddingTop: "38px",
          }}
        >
          {moment(column?.application_date).format("DD/MM/YYYY")}
        </TableCell>
        <TableCell
          key={column?.application_amount}
          align="center"
          className={`${classes.tableBodyText} tdAif`}
          style={{
            position: "relative",
            paddingRight: column.title == "advisor" ? "92px" : "0px",
            paddingTop: "38px",
          }}
        >
          {currencyFormat(column?.application_amount)}
        </TableCell>
        <TableCell
          key={column?.lot_size}
          align="center"
          className={`${classes.tableBodyText} tdAif`}
          style={{
            position: "relative",
            paddingRight: column.title == "advisor" ? "92px" : "0px",
            paddingTop: "38px",
          }}
        >
          {column?.lot_size}
        </TableCell>
        <TableCell
          key={column?.lots_applied}
          align="center"
          className={`${classes.tableBodyText} tdAif`}
          style={{
            position: "relative",
            paddingRight: column.title == "advisor" ? "92px" : "0px",
            paddingTop: "38px",
          }}
        >
          {column?.lots_applied}
        </TableCell>
        <TableCell
          key={column?.shares_applied}
          align="center"
          className={`${classes.tableBodyText} tdAif`}
          style={{
            position: "relative",
            paddingRight: column.title == "advisor" ? "92px" : "0px",
            paddingTop: "38px",
          }}
        >
          {column?.shares_applied}
        </TableCell>
        <TableCell
          key={column?.bid_amount_per_share}
          align="center"
          className={`${classes.tableBodyText} tdAif`}
          style={{
            position: "relative",
            paddingRight: column.title == "advisor" ? "92px" : "0px",
            paddingTop: "38px",
          }}
        >
          {currencyFormat(column?.bid_amount_per_share)}
        </TableCell>
        <TableCell
          key={column?.allotment_date}
          align="center"
          className={`${classes.tableBodyText} tdAif`}
          style={{
            position: "relative",
            paddingRight: column.title == "advisor" ? "92px" : "0px",
            paddingTop: "38px",
          }}
        >
          {moment(column?.allotment_date).format("DD/MM/YYYY")}
        </TableCell>
        <TableCell
          key={column?.allotted_shares}
          align="center"
          className={`${classes.tableBodyText} tdAif`}
          style={{
            position: "relative",
            paddingRight: column.title == "advisor" ? "92px" : "0px",
            paddingTop: "38px",
          }}
        >
          {column?.allotted_shares}
        </TableCell>
        <TableCell
          key={column?.allotted_price}
          align="center"
          className={`${classes.tableBodyText} tdAif`}
          style={{
            position: "relative",
            paddingRight: column.title == "advisor" ? "92px" : "0px",
            paddingTop: "38px",
          }}
        >
          {currencyFormat(column?.allotted_price)}
        </TableCell>
        <TableCell
          key={column?.allotted_amount}
          align="center"
          className={`${classes.tableBodyText} tdAif`}
          style={{
            position: "relative",
            paddingRight: column.title == "advisor" ? "92px" : "0px",
            paddingTop: "38px",
          }}
        >
          {currencyFormat(column?.allotted_amount)}
        </TableCell>
        <TableCell
          key={column?.refund_date}
          align="center"
          className={`${classes.tableBodyText} tdAif`}
          style={{
            position: "relative",
            paddingRight: column.title == "advisor" ? "92px" : "0px",
            paddingTop: "38px",
          }}
        >
          {moment(column?.refund_date).format("DD/MM/YYYY")}
        </TableCell>
        <TableCell
          key={column?.refund_amount}
          align="center"
          className={`${classes.tableBodyText} tdAif`}
          style={{
            position: "relative",
            paddingRight: column.title == "advisor" ? "92px" : "0px",
            paddingTop: "38px",
          }}
        >
          {currencyFormat(column?.refund_amount)}
        </TableCell>
      </TableRow>
    ));
  };

  useEffect(() => {
    if (transactionSummary) {
      let newlist = [];
      transactionSummary.map((val) => {
        newlist.push(objectFormating1(val, tableHeader));
      });
      setTableColumns(newlist);
    }
  }, [transactionSummary]);

  return (
    <>
      <div className={`${classes.newAlignTopHeader}`}>
        {/* <Back
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate(-1);
          }}
        /> */}
        <Typography
          // style={{ marginLeft: "-23%" }}
          className={`${classes.mainHeading} ${classes.mainHeadingAlign}`}
        >
          Transaction Summary
          <Typography
            style={{
              cursor: "pointer",
              // marginLeft: "90px",
              marginTop: "1%",
            }}
            className={classes.aifSubText}
          >
            IPO
          </Typography>
        </Typography>
        <div style={{ marginLeft: "auto" }}>
          <SearchInput
            setSearchName={setSearchText}
            placeHolderText="Search by Stock Name"
          />
        </div>
      </div>

      <div style={{ marginTop: "2%" }}>
        <Grid xs={12}>
          <div className={classes.tableContainerAlign}>
            <PmsReportTable
              tableColumns={tableColumns}
              tableHeader={tableHeader}
              headerList={tableColumnsData}
              setTableHeader={setTableheader}
              setTableColumns={setTableColumns}
              stickyProductName={true}
              loading={loading}
              setOpenModal={setOpen}
              openModal={open}
            />
            {/* <Table>
              <TableHead>
                <TableRow className={`${classes.tableHeadBlue} tableRowStyle`}>
                  {tableHeaders(tableColumns[0])}
                </TableRow>
              </TableHead>
              {}
              <TableBody className="tableBodyAif"> */}
            {/* <TableRow className={`${classes.tableBodyBlue} tableRowAif`}> */}
            {/* {tableBody(tableColumns[0])} */}

            {/* </TableRow> */}
            {/* {transactionSummary?.length == 0 ? (
                  <tr>
                    <td className="dataNotFound" colSpan={12}>
                      <DataNotFound />
                    </td>
                  </tr>
                ) : (
                  tableBody(transactionSummary)
                )} */}
            {/* </TableBody>
            </Table> */}
          </div>
        </Grid>
      </div>
    </>
  );
}

export default IpoHolding;
