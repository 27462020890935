import React from "react";
// import { Formik, Form, Field } from "formik";
// import * as Yup from "yup";
import { makeStyles } from "@material-ui/core";
import {
  Grid,
  Button,
  Box,
  MenuItem,
  Select,
  InputAdornment,
} from "@material-ui/core";
import CustomInput from "../common/CustomInput";
import DefaultSign from "../DefaultSign";
// import BackButton from "../common/BackBtnWithArrow/BackButton";
import PartnerHeading from "../PartnerHeading";
// import VerifiedTick from "../../asset/icons/verified.svg";
import {
  registerClientForm1ValidationSchema,
  registerForm1ValidationSchema,
  registerForm4ValidationSchema,
} from "../ValidationSchema";
import StepProgressBar from "../common/StepProgressBar/StepProgressBar";
import { useForm } from "../hooks/useForm";
import "../PartnerOnboarding.css";
import ErrorMessageComp from "../common/ErrorMessageComp";
import LoadingButton from "../common/LoadingButton";
import { ReactComponent as ClientStep1bg } from "../../../assets/client-onboarding/clientStep1bg.svg";
import { ReactComponent as ClientStep1Bulb } from "../../../assets/client-onboarding/clientStep1Bulb.svg";

import "./clientCss.css";
import ClientForm1 from "./ClientForm1";

export default function ClientStep1(prop) {
  // const { entityName, entityType, panNumber, doi, dateOfBirth, email, mobile } =
  //   formData;

  const { head } = prop;

  const desc = head?.desc
    ? head?.desc
    : {
        center: [
          {
            title: "Who’s a Client?",
            description:
              "Holdings are the contents of an investment portfolio held by an individual or an entity, such as a mutual fund or a pension fund. Portfolio holdings may encompass a wide ",
          },
        ],
      };
  return (
    <>
      <DefaultSign left={7} right={5} desc={desc}>
        <ClientForm1 {...prop} />
      </DefaultSign>
    </>
  );
}
