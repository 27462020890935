import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import PmsReportTable from "../pms/PmsReportTable";

import {
  tableConfigHoldingSummary,
  holdingSummaryTableNavConfig,
  tableConfigTransactionSummaryCorporateActions,
} from "./utils/constands";

import { objectFormatingupcomingTransection } from "../../../utils/objectFormating";

import DeleteList from "./DeleteList";

import { ITEMS_LIMIT_FILES_PARTNER_LIST } from "../../../../Constants";
import { OnboardingContext } from "../../../../Context";
import { deleteTransaction } from "../../../../api";
import ls from "localstorage-slim";

import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";

import useGetTableUpcoming from "../../../../hooks/useGetTableUpcoming";
function UpcomingTransaction({
  tableConfig,
  type,
  extype,
  showCorporateActions,
  tableName,
  typeOfPage,
}) {
  const { userState } = OnboardingContext();
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);

  const [tabData, setTabData] = useState([]);

  console.log(
    "nonEmptyProductList",
    // transformedData,
    holdingSummaryTableNavConfig
  );

  let clientId = userState.loginedUserData.id;
  const scopes = JSON.parse(ls.get("scopes"));
  const checked = React.cloneElement(showCorporateActions, {}, null).props
    .checked;

  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState("");
  const [tableHeader, setTableHeader] = useState(
    // tableConfig || tableConfigHoldingSummary
    []
  );
  const [selectedProd, setSelectedProd] = useState();

  let productsHavingCorporateActionsToggle = [
    "equity",
    "mfo",
    "reits",
    "invits",
    "private",
  ];
  const [pie, setPie] = useState({
    title: "",
    value: "",
    colors: Object.values({}),
    disabled: [],
    fill: [
      {
        match: {
          id: "Commerical",
        },
        id: "c9",
      },
      {
        match: {
          id: "Residential",
        },
        id: "c10",
      },
    ],
  });

  const newTableConfig = useMemo(() => {
    if (type == "transaction") {
      if (selectedProd?.transactionConfig) {
        // setTableHeader(() => selectedProd?.transactionConfig);
        return selectedProd?.transactionConfig;
      } else {
        return checked
          ? tableConfigTransactionSummaryCorporateActions
          : tableConfig;
      }
    } else {
      if (selectedProd?.holdingConfig) {
        // console.log(selectedProd?.holdingConfig, "aglagnk");
        // setTableHeader(() => selectedProd?.holdingConfig);
        return selectedProd?.holdingConfig;
      } else {
        return tableConfig;
      }
    }
  }, [selectedProd, checked]);

  // const [loading, setLoading] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);
  const [secondTableColumns, setSecondTableColumns] = useState([]);
  const [page, setPage] = useState(null);
  const [assetTotalData, setAssetTotalData] = useState({});

  const [key, setKey] = useState("equity");
  function checkPage(page) {
    if (page === "re") {
      return "real-estate";
    } else if (page === "fd") {
      return "fixed-deposit";
    } else if (page === "rd") {
      return "recurring-deposit";
    } else if (page === "ins") {
      return "insurance";
    } else {
      return page;
    }
  }
  let productSubCategory = "";
  let insType = "";
  if (page === "ins") {
    productSubCategory = "ulip";
    insType = "life";
  }

  const [offset, setOffset] = useState(0);
  // const { searchResults } = useClientSearch(search, tableColumns);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteList, setDeleteList] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const {
    getTableData,
    loading,
    tableData,
    paginationData,
    numberOfInstruments,
  } = useGetTableUpcoming({
    tableName: type,
    product: page,
    offset: offset,
    sortBy: "DESC",

    setDeleteList,
    setShowDelete,
    setTableColumns,
  });
  // const [pageNumber, setPageNumber] = useState(1);

  const handleIconClick = () => {
    setOpenModal(!openModal);
  };

  //step 1 fix header config
  const handleHeader = (tableConfig) => {
    let newconfog = tableConfig.map((val) => {
      if (val?.field == "select") {
        return {
          ...val,
          show: showDelete,
        };
      }

      return val;
    });

    setTableHeader(() => newconfog);
    handleData(newconfog);
  };

  //step 2 format data with fixed header config
  const handleData = (newconfog) => {
    let list = [];

    tableData.map((val, i) => {
      //Adding ceckbox component as a value

      // This handling is required due to backend is sending
      //different keys

      // if (key !== "bank" && key !== "funds-in-transit") {
      //   if (val["scripName"]) {
      //     newconfog[0].field = "scripName";
      //   } else if (val["schemeName"]) {
      //     newconfog[0].field = "schemeName";
      //   } else if (val["bankName"]) {
      //     newconfog[0].field = "bankName";
      //   } else {
      //     newconfog[0].field = "projectName";
      //   }
      //   if (val["nav"]) {
      //     type === "security-holding"
      //       ? (newconfog[7].field = "nav")
      //       : (newconfog[10].field = "nav");
      //   } else {
      //     type === "security-holding"
      //       ? (newconfog[7].field = "currentPrice")
      //       : (newconfog[10].field = "currentPrice");
      //   }

      //   if (val["isin"]) {
      //     type === "security-holding"
      //       ? (newconfog[1].field = "isin")
      //       : (newconfog[3].field = "isin");
      //   } else {
      //     type === "security-holding"
      //       ? (newconfog[1].field = "projectCode")
      //       : (newconfog[3].field = "projectCode");
      //   }
      // }

      // console.log(newconfog, "jnjn");

      list.push(objectFormatingupcomingTransection(val, newconfog));
    });
    // console.log(list, "jnjn");
    // On Tab Switch while loading empty the tableColumns so that previous tab data is not shown in table
    if (loading) {
      setTableColumns([]);
    } else {
      setTableColumns(() => list);
    }
  };
  // useEffect(() => {
  //   setSecondTableColumns(
  //     summarizeTableData(
  //       tableColumns,
  //       holdingCardData,
  //       xirrCurrentHolding,
  //       twrrCurrentHolding,
  //       numberOfInstruments,
  //       "portfolio-holding", // 🟢 typeOfPage To check and render '--' for product name column
  //       page, // typeOfProduct,
  //       assetTotalData.total
  //     )
  //   );
  // }, [tableColumns, holdingCardData, xirrCurrentHolding, twrrCurrentHolding]);

  const handleDeleteSelect = (row) => {
    setDeleteList((prev) => {
      if (Object.keys(prev).includes(Object.keys(row)[0])) {
        delete prev[`${Object.keys(row)[0]}`];
        return { ...prev };
      }
      return { ...prev, ...row };
    });
  };

  const handleDeleteClick = () => {
    if (Object.values(deleteList).length !== 0) {
      setOpenDeleteModal(true);
    }
  };

  useEffect(() => {
    // console.log(newTableConfig, "agnlagj");
    // if (tableData?.length > 0) {
    handleHeader(
      newTableConfig
      // ? newTableConfig : tableConfigHoldingSummary
    );
    // }

    setKey(page);
  }, [showDelete, tableData, newTableConfig, checked, page, loading]);

  // console.log("fsdfsdsadsfd", checked, type, tableHeader, newTableConfig);

  const deleteClick = () => {
    setShowDelete((prev) => !prev);
  };

  const handleDeletion = useCallback(async () => {
    const transactionsIdsToDelete = Object.values(deleteList)
      .map(({ id }) => id)
      .join(",");
    await deleteTransaction(clientId, key, transactionsIdsToDelete);
    setOpenDeleteModal(false);
    setShowDelete();
    getTableData();
    setDeleteList(() => []);
  }, [deleteList]);

  return (
    <div>
      {/* <NewtableTopSec
        key={page}
        handleIconClick={handleIconClick}
        directPageName={"Holding Summary"}
        setSearch={(e) => {
          setOffset(0);
          // setPageNumber(1);
          setSearch(e);
        }}
        product={null}
        hideTableEdit={showDelete}
        icons={
          type == "transaction" && scopes.can_delete_transaction_summary
            ? [
                <DeleteTransactionsIcon
                  deleteBtnProps={{
                    disabled: Object.values(deleteList).length == 0,
                    handleDeleteClick: handleDeleteClick,
                    deleteCancel: () => {
                      deleteClick();
                      setDeleteList(() => []);
                    },
                    showDelete,
                    setShowDelete,
                  }}
                />,
              ]
            : []
        }
        // Show Corporate Action Toggle
        iosSwitch={
          type === "transaction" &&
          productsHavingCorporateActionsToggle.includes(page) &&
          showCorporateActions
        }
      /> */}
      {/* <div style={{ margin: "2%" }}>
        <NewHoldingButtons
          selected={page}
          handleChange={handleClick}
          btnArray={tabData}
        />
      </div> */}
      {openDeleteModal && (
        <DeleteList
          handleClose={() => {
            setOpenDeleteModal(false);
            setDeleteList(() => []);
            setShowDelete((prev) => !prev);
          }}
          open={openDeleteModal}
          seletedList={deleteList}
          submitDelete={() => handleDeletion()}
          tableConfig={
            page === "bank"
              ? newTableConfig?.filter((val) => val?.field !== "select")
              : undefined
          }
        />
      )}
      {/* {tableColumns?.length > 0 && ( */}
      <PmsReportTable
        tableName={tableName}
        key={tableColumns}
        productType="pmsHoldingDebt"
        setTableColumns={setTableColumns}
        tableColumns={tableColumns}
        setSecondTableColumns={setSecondTableColumns}
        tableColumns2={secondTableColumns}
        setOpenModal={setOpenModal}
        openModal={openModal}
        // tableName={"Debt"}
        loading={loading}
        pageName="holding"
        headerList={
          newTableConfig
          // || tableConfigHoldingSummary
        }
        tableHeader={tableHeader}
        setTableHeader={setTableHeader}
        stickyProductName={true}
        pageCount={paginationData?.pageCount}
        setItemOffset={setOffset}
        productName={null}
        customTableClass={showDelete && "selectDelete"}
        // setPageNumber={setPageNumber}
        pageNumber={offset / ITEMS_LIMIT_FILES_PARTNER_LIST + 1 || 1}
        // setPageNumber
      />
      {/* )} */}
    </div>
  );
}

export default UpcomingTransaction;
