import {
  Button,
  Checkbox,
  FormControlLabel,
  Modal,
  Typography,
} from "@material-ui/core";
import React from "react";
import useStyles from "./ProductsStyles";
import NewTableTitle from "../../common/NewTableTitle";
import { getProductAndEntityFromRoute } from "../../../utils/strHelper";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ReactComponent as DragIndicator } from "../../../../assets/icons/dragIndicator.svg";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import { useState } from "react";
import { useEffect } from "react";

function TableEditModal({
  openModal,
  setTickedColumns,
  setOpenModal,
  onDragEndAction,
  getItemStyle,
  handleReset,
  handleButtonClickApply,
  tickedColumns,
  handleCheckBoxClick,
  headerList,
  tableHeader,
  productName,
}) {
  const classes = useStyles();
  const [disabledList, setDisabledList] = useState([]);

  const handleOrder = (openModal) => {
    // let newDisabledList = [...dataList].filter((val) => !val.show);
    // console.log(headerList, "gagjagbj");
    setDisabledList(() => [headerList[0]]);
    //else setDisabledList(() => []);
    // setTickedColumns(() => {
    //   headerList?.pop();
    // });
  };

  useEffect(() => {
    handleOrder(openModal);
  }, [tickedColumns, openModal]);

  return (
    <Modal
      open={openModal}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
        style: {
          backgroundColor: "",
          background:
            "linear-gradient(to right, #ffffff00 18%, #000000bd 100%)",
        },
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={() => {
        setDisabledList(() => []);
        setTickedColumns((prev) => tableHeader);
        setOpenModal(false);
      }}
    >
      <div
        className={`${classes.modalContainer} form-container`}
        style={{
          padding: "11px 0px 0px 0px",
          width: "356px",
          display: "flex",
          flexDirection: "column",
          borderRadius: "0px",
          boxSizing: "border-box",
          border: "none",
        }}
      >
        <NewTableTitle
          // customMainHederClass="newFilterHeader"
          pageName={
            getProductAndEntityFromRoute(window.location.href).entity
              ? getProductAndEntityFromRoute(window.location.href).entity
              : "Table Type"
          }
          product={
            productName !== null
              ? getProductAndEntityFromRoute(window.location.href).product
                ? getProductAndEntityFromRoute(window.location.href).product
                : "Table Name"
              : ""
          }
          customWrapperStyle={{
            paddingInline: "19px 19px",
          }}
        />
        <Typography
          className="subProdName"
          style={{
            marginTop: "13px",
            marginBottom: "12px",
            paddingInline: "19px 19px",
          }}
        >
          Rearrange your columns for your viewing.
        </Typography>
        <div className="member">
          <div className="member-content">
            {disabledList?.map((column, i) => {
              return (
                <div className="new-filter-header">
                  <div
                    className="colEditItems"
                    style={{
                      opacity: !column?.show ? "0.5" : "1",
                      paddingInline: "19px 19px",
                    }}
                  >
                    {/* {column.show && i > 0 ? (
                      <DragIndicator
                        className={`colEditIcon ${
                          column.show == true ? "" : "tableColumnSelected"
                        }`}
                        width={"11px"}
                      />
                    ) : ( */}
                    <span style={{ width: "11px" }}></span>
                    {/* )} */}
                    <FormControlLabel
                      className="customLabel"
                      control={
                        <Checkbox
                          icon={<div style={{ width: "27px" }}></div>}
                          checkedIcon={
                            <div className="circularCheckIconBorder">
                              <CheckCircleRoundedIcon className="circularCheckIcon" />
                            </div>
                          }
                          name={column?.field}
                          value={column?.title}
                          checked={i === 0 ? false : column?.show}
                          onChange={i === 0 ? () => {} : handleCheckBoxClick}
                        />
                      }
                    />
                    <Typography className="newFilterItemFont">
                      {column?.title}
                    </Typography>
                  </div>
                </div>
              );
            })}
            <DragDropContext onDragEnd={onDragEndAction}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {tickedColumns?.map((column, index) =>
                      column && column.title !== "" ? (
                        <Draggable
                          key={column.title}
                          draggableId={column.title}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                ),
                                display:
                                  column?.field !== headerList[0]?.field
                                    ? "flex"
                                    : "none",
                              }}
                              className="new-filter-header"
                            >
                              <div
                                className="colEditItems"
                                style={{
                                  opacity:
                                    snapshot.isDragging || !column.show
                                      ? "0.5"
                                      : "1",
                                  paddingInline: "19px 19px",
                                }}
                              >
                                {column.show ? (
                                  <DragIndicator
                                    className={`colEditIcon ${
                                      column.show == true
                                        ? ""
                                        : "tableColumnSelected"
                                    }`}
                                    width={"11px"}
                                  />
                                ) : (
                                  <span style={{ width: "11px" }}></span>
                                )}
                                <FormControlLabel
                                  className="customLabel"
                                  control={
                                    <Checkbox
                                      icon={
                                        <div className="circularCheckIconBorder"></div>
                                      }
                                      checkedIcon={
                                        <div className="circularCheckIconBorder">
                                          <CheckCircleRoundedIcon className="circularCheckIcon" />
                                        </div>
                                      }
                                      name={column.field}
                                      value={column.title}
                                      checked={column.show}
                                      onChange={handleCheckBoxClick}
                                    />
                                  }
                                />
                                <Typography className="newFilterItemFont">
                                  {column.title}
                                </Typography>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ) : null
                    )}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
        <div className={"newButtonSec"}>
          <Button className={"newCancelButton"} onClick={handleReset}>
            <span className={`${classes.buttonText}`}>Reset</span>
          </Button>
          <Button className={"newApplyButton"} onClick={handleButtonClickApply}>
            <span className={`${classes.buttonText}`}>Save</span>
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default TableEditModal;
