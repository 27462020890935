import React, { useEffect, useState } from "react";
import Default from "../../../components/dashboard/defaultPages/Default";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "../../../components/loader/Loader";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@material-ui/core";
import CustomizedSnackbars from "../../../components/common/CustomizedSnackbars";
import UpdateSingleEdit from "./UpdateSingleEdit";
import { fetchMasterProduct } from "../../../api";
import MasterHeader from "../../common/MasterHeader";

const useStyles = makeStyles((theme) => ({
  searchWrapper: {
    marginTop: "1%",
    marginLeft: "5%",
    marginRight: "5%",
  },
  titleWrapper: {
    marginTop: "2%",
    marginLeft: "5%",
  },
  titleText: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "24px",
    lineHeight: "30px",
    display: "flex",
    alignItems: "center",
    color: "var(--selectedText)",
  },
  subtitleText: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "14px",
    lineHeight: "30px",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
  },
}));

function UpdateSingle() {
  const classes = useStyles();
  const [showLoder, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const {
    state: { id, master, isin, master_name },
  } = useLocation();

  const formObject = {
    //neoProductName: productCode,
    isin: "",
    schemeName: "",
    amc: "",
    rtaCode: "",
    fundName: "",
    broadCategory: "",
    assetClass: "",
    assetType: "",
    amfiCode: "",
    productCategory: "",
    productSubCategory: "",
    investmentOption: "growth",
    planType: "",
    taxClassification: "",
    benchmarkIndexName: "",
    primaryProspectusName: "",
    class: "",
    aifFundType: "",
    managementFees: 0,
    hurdleRate: 0,
    performanceFees: 0,
    status: "active",
    companyName: "",
    projectCode: "",
    projectName: "",
    address: "",
    state: "",
    city: "",
    country: "",
    //instrumentName: "",
    //descNSDL: "",
    isListed: "",
    issuePrice: "",
    faceValue: "",
    dateOfAllotment: "",
    redemption: "",
    couponRate: "",
    frequencyOfThenInterestPayment: "",
    frequencyOfInterestPayment: "",
    putCallOption: "",
    totalIssueSize: "",
    creditRatingAgency: "",
    creditRating: "",
    maturityDate: "",

    issuerName: "",
    desNotes: "",
    amountIssued: "",
    yieldToMaturity: "",
    volume: "",
    bidPrice: "",
    askPrice: "",
    securityName: "",
    ticker: "",
    marketIssue: "",
    currency: "",
    currentYield: "",
    issueDate: "",
    minimumIncrement: "",
    amountOut: "",
    rating: "",
    stockSymbol: "",
    scripName: "",
    sector: "",

    bseGroup: "",
    bseCode: "",
    bseScripName: "",
    bseListDate: "",
    nseSymbol: "",
    nseSecName: "",
    nseListDate: "",
    tenure: "",
    bondType: "",
    lastInterestDue: "",
    startDate: "",
    NoOfBlocksOfPrincipalReductions: 0,
    NoOfBlocksOfInterestReductions: 0,
    cashflowType: "",
    instrumentType: master === "mf" || master === "equity" ? "unit_based" : "",
    etf: "",
    master: master,
    mf_status: "",
    demergedParentCost: 0,
    demergedCostBasis: 0,
    interestPaymentDateType: "",
    onMaturity: "",
    interestPayoutDate: "",
    manualPriceUpload: "",
    maturityDateInclusive: "",
    issueDateInclusive: "",
    includeHolidays: "",
    perpetual: "",
    compounding: "",
    isEndOfMonth: "",
    interestDays: "",
    icdType: "",
    interestRepaymentFrequency: "",
    interestRepaymentType: "",
    interestRate: 0,
    amount: 0,
    bankName: "",
    accountType: "",
    accountNo: "",
    pricePerUnit: 0,
    penalizedInterestRate: 0,
    earlyWithdrawalDate: "",
  };

  const [singleMasterProductData, setSingleMasterProductData] =
    useState(formObject);

  const fetchMasterProductData = async (id) => {
    try {
      setShowLoader(true);
      const data = await fetchMasterProduct(id, master);
      const fetchMastersData = data.data;

      if (fetchMastersData.success) {
        const fetchMastersProductData = fetchMastersData?.data?.result;
        const formObject = {
          ...singleMasterProductData,
          // neoProductName: !fetchMastersProductData.neo_product_name
          //   ? productCode
          //   : fetchMastersProductData.neo_product_name,
          isin: !fetchMastersProductData.isin
            ? ""
            : fetchMastersProductData.isin,
          schemeName: !fetchMastersProductData.scheme_name
            ? ""
            : fetchMastersProductData.scheme_name,
          rtaCode: !fetchMastersProductData.rta_code
            ? ""
            : fetchMastersProductData.rta_code,
          fundName: !fetchMastersProductData.fund_name
            ? ""
            : fetchMastersProductData.fund_name,
          broadCategory: !fetchMastersProductData.broad_category
            ? ""
            : fetchMastersProductData.broad_category,
          assetClass: !fetchMastersProductData.asset_class
            ? ""
            : fetchMastersProductData.asset_class,
          amfiCode: !fetchMastersProductData.amfi_code
            ? ""
            : fetchMastersProductData.amfi_code,
          productSubCategory: !fetchMastersProductData.product_sub_category
            ? ""
            : fetchMastersProductData.product_sub_category,
          productCategory: !fetchMastersProductData.product_category
            ? ""
            : fetchMastersProductData.product_category,

          investmentOption: !fetchMastersProductData.investment_option
            ? "growth"
            : fetchMastersProductData.investment_option,
          planType: !fetchMastersProductData.plan_type
            ? ""
            : fetchMastersProductData.plan_type,
          taxClassification: !fetchMastersProductData.tax_classification
            ? ""
            : fetchMastersProductData.tax_classification,
          benchmarkIndexName: !fetchMastersProductData?.benchmark_index_name
            ? ""
            : fetchMastersProductData?.benchmark_index_name,
          primaryProspectusName:
            !fetchMastersProductData?.primary_prospectus_name
              ? ""
              : fetchMastersProductData?.primary_prospectus_name,

          class: !fetchMastersProductData?.class
            ? ""
            : fetchMastersProductData?.class,
          aifFundType: !fetchMastersProductData?.aif_fund_type
            ? ""
            : fetchMastersProductData?.aif_fund_type,
          hurdleRate: !fetchMastersProductData?.hurdle_rate
            ? 0
            : fetchMastersProductData?.hurdle_rate,
          performanceFees: !fetchMastersProductData?.performance_fees
            ? 0
            : fetchMastersProductData?.performance_fees,
          managementFees: !fetchMastersProductData?.management_fees
            ? 0
            : fetchMastersProductData?.management_fees,
          amc: !fetchMastersProductData?.amc
            ? ""
            : fetchMastersProductData?.amc,
          companyName: !fetchMastersProductData?.company_name
            ? ""
            : fetchMastersProductData?.company_name,
          projectCode: !fetchMastersProductData?.project_code
            ? ""
            : fetchMastersProductData?.project_code,
          projectName: !fetchMastersProductData?.project_name
            ? ""
            : fetchMastersProductData?.project_name,
          address: !fetchMastersProductData?.address
            ? ""
            : fetchMastersProductData?.address,
          city: !fetchMastersProductData?.city
            ? ""
            : fetchMastersProductData?.city,
          state: !fetchMastersProductData?.state
            ? ""
            : fetchMastersProductData?.state,
          country: !fetchMastersProductData?.country
            ? ""
            : fetchMastersProductData?.country,
          isListed: !fetchMastersProductData?.is_listed
            ? ""
            : fetchMastersProductData?.is_listed,

          // instrumentName: !fetchMastersProductData?.instrument_name
          //   ? ""
          //   : fetchMastersProductData?.instrument_name,
          // descNSDL: !fetchMastersProductData?.desc_nsdl
          //   ? ""
          //   : fetchMastersProductData?.desc_nsdl,
          issuePrice: !fetchMastersProductData?.issue_price
            ? ""
            : fetchMastersProductData?.issue_price,
          faceValue: !fetchMastersProductData?.face_value
            ? ""
            : fetchMastersProductData?.face_value,
          dateOfAllotment: !fetchMastersProductData?.date_of_allotment
            ? ""
            : fetchMastersProductData?.date_of_allotment,
          redemption: !fetchMastersProductData?.redemption
            ? ""
            : fetchMastersProductData?.redemption,
          couponRate: !fetchMastersProductData?.coupon_rate
            ? ""
            : fetchMastersProductData?.coupon_rate,

          frequencyOfThenInterestPayment:
            !fetchMastersProductData?.frequency_of_then_interest_payment
              ? ""
              : fetchMastersProductData?.frequency_of_then_interest_payment,
          frequencyOfInterestPayment:
            !fetchMastersProductData?.frequency_of_interest_payment
              ? ""
              : fetchMastersProductData?.frequency_of_interest_payment,
          putCallOption: !fetchMastersProductData?.put_call_option
            ? ""
            : fetchMastersProductData?.put_call_option,
          totalIssueSize: !fetchMastersProductData?.total_issue_size
            ? ""
            : fetchMastersProductData?.total_issue_size,
          creditRatingAgency: !fetchMastersProductData?.credit_rating_agency
            ? ""
            : fetchMastersProductData?.credit_rating_agency,
          creditRating: !fetchMastersProductData?.credit_rating
            ? ""
            : fetchMastersProductData?.credit_rating,

          maturityDate: !fetchMastersProductData?.maturity_date
            ? ""
            : fetchMastersProductData?.maturity_date,
          issuerName: !fetchMastersProductData.issuer_name
            ? ""
            : fetchMastersProductData.issuer_name,
          desNotes: !fetchMastersProductData.des_notes
            ? ""
            : fetchMastersProductData.des_notes,
          amountIssued: !fetchMastersProductData.amount_issued
            ? ""
            : fetchMastersProductData.amount_issued,
          yieldToMaturity: !fetchMastersProductData.yield_to_maturity
            ? ""
            : fetchMastersProductData.yield_to_maturity,
          volume: !fetchMastersProductData.volume
            ? ""
            : fetchMastersProductData.volume,
          bidPrice: !fetchMastersProductData.bid_price
            ? ""
            : fetchMastersProductData.bid_price,
          askPrice: !fetchMastersProductData.ask_price
            ? ""
            : fetchMastersProductData.ask_price,
          securityName: !fetchMastersProductData.security_name
            ? ""
            : fetchMastersProductData.security_name,
          ticker: !fetchMastersProductData.ticker
            ? ""
            : fetchMastersProductData.ticker,
          marketIssue: !fetchMastersProductData.market_issue
            ? ""
            : fetchMastersProductData.market_issue,
          currency: !fetchMastersProductData.currency
            ? ""
            : fetchMastersProductData.currency,
          currentYield: !fetchMastersProductData.current_yield
            ? ""
            : fetchMastersProductData.current_yield,
          issueDate: !fetchMastersProductData.issue_date
            ? ""
            : fetchMastersProductData.issue_date,
          minimumIncrement: !fetchMastersProductData.minimum_increment
            ? ""
            : fetchMastersProductData.minimum_increment,
          amountOut: !fetchMastersProductData.amount_out
            ? ""
            : fetchMastersProductData.amount_out,
          rating: !fetchMastersProductData.rating
            ? ""
            : fetchMastersProductData.rating,
          stockSymbol: !fetchMastersProductData.stock_symbol
            ? ""
            : fetchMastersProductData.stock_symbol,
          scripName: !fetchMastersProductData.scrip_name
            ? ""
            : fetchMastersProductData.scrip_name,
          sector: !fetchMastersProductData.sector
            ? ""
            : fetchMastersProductData.sector,
          assetType: !fetchMastersProductData.asset_type
            ? ""
            : fetchMastersProductData.asset_type,
          bseGroup: !fetchMastersProductData.bse_group
            ? ""
            : fetchMastersProductData.bse_group,

          bseCode: !fetchMastersProductData.bse_code
            ? ""
            : fetchMastersProductData.bse_code,
          bseScripName: !fetchMastersProductData.bse_scrip_name
            ? ""
            : fetchMastersProductData.bse_scrip_name,
          bseListDate: !fetchMastersProductData.bse_list_date
            ? ""
            : fetchMastersProductData.bse_list_date,
          nseSymbol: !fetchMastersProductData.nse_symbol
            ? ""
            : fetchMastersProductData.nse_symbol,
          nseSecName: !fetchMastersProductData.nse_sec_name
            ? ""
            : fetchMastersProductData.nse_sec_name,
          nseListDate: !fetchMastersProductData.nse_list_date
            ? ""
            : fetchMastersProductData.nse_list_date,
          tenure: !fetchMastersProductData.tenure
            ? ""
            : fetchMastersProductData.tenure,
          lastInterestDue: !fetchMastersProductData.last_interest_due
            ? ""
            : fetchMastersProductData.last_interest_due,
          startDate: !fetchMastersProductData.start_date
            ? ""
            : fetchMastersProductData.start_date,
          NoOfBlocksOfPrincipalReductions:
            !fetchMastersProductData.number_of_blocks_of_principal_reductions
              ? 0
              : fetchMastersProductData.number_of_blocks_of_principal_reductions,
          NoOfBlocksOfInterestReductions:
            !fetchMastersProductData.number_of_blocks_of_interest_reductions
              ? 0
              : fetchMastersProductData.number_of_blocks_of_interest_reductions,
          cashflowType: !fetchMastersProductData.cashflow_type
            ? ""
            : fetchMastersProductData.cashflow_type,
          bondType: !fetchMastersProductData.bond_type
            ? ""
            : fetchMastersProductData.bond_type,

          instrumentType: !fetchMastersProductData.instrument_type
            ? ""
            : fetchMastersProductData.instrument_type,

          etf: !fetchMastersProductData.etf ? "" : fetchMastersProductData.etf,
          mf_status: !fetchMastersProductData.mf_status
            ? ""
            : fetchMastersProductData.mf_status,

          status: !fetchMastersProductData.status
            ? "active"
            : fetchMastersProductData.status,

          demergedParentCost: !fetchMastersProductData.demerged_parent_cost
            ? 0
            : fetchMastersProductData.demerged_parent_cost,

          demergedCostBasis: !fetchMastersProductData.demerged_cost_basis
            ? 0
            : fetchMastersProductData.demerged_cost_basis,
          interestPaymentDateType:
            !fetchMastersProductData.interest_payment_date_type
              ? ""
              : fetchMastersProductData.interest_payment_date_type,
          onMaturity: !fetchMastersProductData.on_maturity
            ? ""
            : fetchMastersProductData.on_maturity,
          interestPayoutDate: !fetchMastersProductData.interest_payout_dates
            ? ""
            : fetchMastersProductData.interest_payout_dates,
          manualPriceUpload: !fetchMastersProductData.manual_price_upload
            ? ""
            : fetchMastersProductData.manual_price_upload,
          maturityDateInclusive:
            !fetchMastersProductData.maturity_date_inclusive
              ? ""
              : fetchMastersProductData.maturity_date_inclusive,
          perpetual: !fetchMastersProductData.perpetual
            ? ""
            : fetchMastersProductData.perpetual,
          includeHolidays: !fetchMastersProductData.include_holidays
            ? ""
            : fetchMastersProductData.include_holidays,
          issueDateInclusive: !fetchMastersProductData.issue_date_inclusive
            ? ""
            : fetchMastersProductData.issue_date_inclusive,
          interestDays: !fetchMastersProductData.is_actual_days
            ? ""
            : fetchMastersProductData.is_actual_days,
          isEndOfMonth: !fetchMastersProductData.is_end_of_month
            ? ""
            : fetchMastersProductData.is_end_of_month,
          icdType: !fetchMastersProductData.icd_type
            ? ""
            : fetchMastersProductData.icd_type,
          interestRate: !fetchMastersProductData.interest_rate
            ? 0
            : fetchMastersProductData.interest_rate,
          interestRepaymentType:
            !fetchMastersProductData.interest_repayment_type
              ? ""
              : fetchMastersProductData.interest_repayment_type,
          interestRepaymentFrequency:
            !fetchMastersProductData.interest_repayment_frequency
              ? ""
              : fetchMastersProductData.interest_repayment_frequency,
          bankName: !fetchMastersProductData.bank_name
            ? ""
            : fetchMastersProductData.bank_name,
          accountType: !fetchMastersProductData.account_type
            ? ""
            : fetchMastersProductData.account_type,
          accountNo: !fetchMastersProductData.account_no
            ? ""
            : fetchMastersProductData.account_no,
          pricePerUnit: !fetchMastersProductData.price_per_unit
            ? 0
            : fetchMastersProductData.price_per_unit,
          penalizedInterestRate:
            !fetchMastersProductData.penalized_interest_rate
              ? 0
              : fetchMastersProductData.penalized_interest_rate,
          earlyWithdrawalDate: !fetchMastersProductData.early_withdrawal_date
            ? ""
            : fetchMastersProductData.early_withdrawal_date,
        };
        setSingleMasterProductData(formObject);
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
      if (error.response.request.status === 401) {
        navigate("/logout");
      }
    }
  };

  useEffect(() => {
    if (id) {
      fetchMasterProductData(id);
    }
  }, [id]);

  return (
    <>
      <Default>
        <CustomizedSnackbars />
        <MasterHeader
          title={`${master_name} Details`}
          state={{ products: { id, master, isin, master_name } }}
        />
        {/* <div className={classes.titleWrapper}>
          <Typography className={classes.subtitleText}>Details</Typography>
        </div> */}
        <div className={classes.searchWrapper}>
          {showLoder && <Loader />}
          <UpdateSingleEdit
            id={id}
            master={master}
            master_name={master_name}
            isin={isin}
            singleMasterProductData={singleMasterProductData}
          ></UpdateSingleEdit>
        </div>
      </Default>
    </>
  );
}

export default UpdateSingle;
