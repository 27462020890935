import React from "react";
import { CustomTabComponent } from "../dashboard/common/CustomTabComponent";
import PartnerHeading from "../NewSignUp/PartnerHeading";
import "./NewPartnerList.css";
import TableSummaryDetails from "../dashboard/products/newDesigns/common/newSummaryDetailsTable/TableSummaryDetails";
import {
  bodyData5,
  headerDataPartner,
  partnerListHeaderOps,
  partnerListHeaderOpsRejected,
} from "../user_management/utils/constands";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PartnerAsistentJourney from "./partnerAsistentJourney/PartnerAsistentJourney";
import AppPagination from "../verificationUpload/AppPagination";
import { ITEMS_LIMIT_FILES_PARTNER_LIST } from "../../Constants";
import { useOps } from "../../hooks/useOps";
import { fetchAllUser } from "../../api";
import { userStatusConfig } from "../newClientList/constsnds";
import { handleKey } from "../utils/common";
import PageLoader from "../dashboard/products/common/pageLoader";
import ls from "localstorage-slim";

function NewPartnerList({ pageTitle }) {
  const [offset, setItemOffset] = useState(0);
  const [tab, setTab] = useState(0);
  const [search, setSearch] = useState("");
  const { opsData, pageCount, loading } = useOps(
    fetchAllUser,
    userStatusConfig[tab],
    search,
    ITEMS_LIMIT_FILES_PARTNER_LIST,
    offset
  );
  const { state } = useLocation();
  const [header, setHeader] = useState([]);
  const [openFLow, setOpenFlow] = useState(
    state?.openModal ? state?.openModal : false
  );
  const [tableData, setTableData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const handleRow = (row, headerData) => {
    let newCOl = headerData?.map((col) => {
      if (col?.field == "userRole" || col?.title == "Representative No") {
        return {
          ...col,
          field: handleKey(col?.field, row) ? handleKey(col?.field, row) : "--",
          // color2: "#711CF1",
          // onClick: row?.onClick,
          dontShow: row?.dontShow,
          customCellClassName: "childCellCardView",
          customFontClassName: "fontWeight500",
          onClick: col?.onClick
            ? () => {
                col?.onClick(row);
              }
            : () => {},
        };
      }

      if (col?.title == "Status") {
        return {
          ...col,
          field: handleKey(col?.field, row) ? handleKey(col?.field, row) : "--",
          onClick: col?.onClick
            ? () => {
                col?.onClick(row);
              }
            : () => {},
          // : () => {
          //     navigate("/user-management/edit", { state: { data: row } });
          //   }
          dontShow: row?.dontShow,
          dontShowFirstColBorder: true,
          customCellClassName: "childCellCardView",
          customFontClassName:
            row[`${col?.field}`] == "Active" ? "activeClass" : "inActiveClass",
          fontWeight: "600",
        };
      }

      if (col?.field == "entity_name") {
        return {
          ...col,
          field: handleKey(col?.field, row) ? handleKey(col?.field, row) : "--",
          // color2: "#711CF1",
          onClick: () => {
            col?.onClick(row);
          },
          dontShow: row?.dontShow,
          dontShowFirstColBorder: true,
          customCellClassName: "childCellCardView",
          pic: row[`pic`],
          customFontClassName: "fontWeight500",
        };
      }
      return {
        ...col,
        field: handleKey(col?.field, row) ? handleKey(col?.field, row) : "--",
        // color2: "#711CF1",
        onClick: row?.onClick,
        dontShow: row?.dontShow,
        customCellClassName: "childCellCardView",
        customFontClassName: "fontWeight500",
      };
    });

    return newCOl;
  };

  const naivgate = useNavigate();

  const handleHeader = (headerData) => {
    headerData?.map((val) => {
      if (val?.title == "Status" || val?.title == "Representative No") {
        val.onClick = (data) => {
          if (tab == 0) {
            naivgate(`/partner/Pending/detials/${data?.id}`, {
              state: {
                data: data,
                userType: "partner",
              },
            });
          }

          if (tab == 1) {
            naivgate("/partner/approved-list", {
              state: {
                data: data,
                userType: "partner",
              },
            });
          }

          if (tab == 2) {
            naivgate(`/partner/Pending/detials/${data?.id}`, {
              state: {
                data: data,
                userType: "partner",
              },
            });
          }
        };
      }
    });
    setHeader(headerData);
    setTableData(opsData);
  };

  useEffect(() => {
    if (tab === 0 || tab === 2) {
      // setTableData(() => []);
      handleHeader(partnerListHeaderOpsRejected);
      // setTimeout(() => {
      // }, 500);
    } else {
      setTableData(() => []);
      handleHeader(partnerListHeaderOps);
      // setTimeout(() => {
      // }, 500);
    }

    // handleHeader(partnerListHeaderOps);
    // setData(bodyData4);
    return () => {
      //   setData([]);
      setHeader([]);
    };
  }, [tab, opsData]);

  const handleTabChange = () => {
    setItemOffset(0);
  };

  const scopes = JSON.parse(ls.get("scopes"));

  return (
    <div className="newPartenrListContainer">
      <div style={{ paddingLeft: "41px", paddingBottom: "40px" }}>
        <PartnerHeading
          noStep={true}
          mainHead={pageTitle ? pageTitle : "Partner"}
          hideBackButton={true}
        />
      </div>
      <CustomTabComponent
        customTabHandle={handleTabChange}
        addBtnTitle={"Add Partner"}
        handleIconClick={() => {
          setOpenFlow(true);
        }}
        customScopes={[scopes?.can_add_partner]}
        setTab={setTab}
        setSearch={(e) => {
          setItemOffset(0);
          setPageNumber(1);
          setSearch(e);
        }}
      />
      <div style={{ paddingLeft: "41px", paddingRight: "38px" }}>
        {header?.length > 0 && tableData?.length > 0 && (
          <>
            <TableSummaryDetails
              headerData={header}
              data={tableData}
              customHandleRow={handleRow}
              customStyle={{ headerStyle: { className: "customHeader1" } }}
              // customIcon={customIcon}
            />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <AppPagination
                pageCount={pageCount}
                itemLimit={ITEMS_LIMIT_FILES_PARTNER_LIST}
                // setPageNumber={setPageNumber}
                setItemOffset={setItemOffset}
                pageNumber={offset / ITEMS_LIMIT_FILES_PARTNER_LIST + 1 || 1}
              />
            </div>
          </>
        )}
      </div>

      <PageLoader showLoader={loading} />
      <PartnerAsistentJourney openModal={openFLow} setOpenModal={setOpenFlow} />
    </div>
  );
}

export default NewPartnerList;
