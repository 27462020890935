import React, { useState, useRef, useEffect } from "react";
import Calendar from "react-calendar";
import moment from "moment/moment";
import "react-calendar/dist/Calendar.css";
import "./custom-calendar.css";
import MaskInput from "react-maskinput";
import { useTheme } from "@material-ui/core";

export default function FilterCalendar({
  onchange,
  values,
  openCustom,
  customLabel,
  customStyle,
  customCalenderPostion,
  minDate,
  maxDate,
  hideinput,
  clear,
  setPeriod,
  type,
  style = {},
}) {
  let calRef = useRef();
  const theme = useTheme();
  const [date, setDate] = useState([new Date(), new Date()]);

  const [open, setOpen] = useState(openCustom ? openCustom : false);
  const [tempdate, setTempdate] = useState(null);
  const [tempmaxdate, setTempmaxdate] = useState(null);
  const [inputDate, setInputDate] = useState({
    start: "",
    end: "",
  });

  const [mask, setMask] = React.useState("00-00-0000 - 00-00-0000");
  const [maskString, setMaskString] = React.useState("DD-MM-YYYY - DD-MM-YYYY");
  const [localdate, setlocaldate] = useState(
    values[0]
      ? moment(values[0]).format("DD-MM-YYYY") +
          "-" +
          moment(values[1]).format("DD-MM-YYYY")
      : ""
  );
  const onChange1231 = (e) => {
    setlocaldate(e.target.value);
    if (setPeriod && e.target.value == "DD-MM-YYYY - DD-MM-YYYY") {
      setPeriod("Inception Till Date");
    }

    if (e.target.value.length == 23) {
      if (
        moment(e.target.value?.split(" - ")[0], "DD-MM-YYYY").isValid() &&
        moment(e.target.value?.split(" - ")[1], "DD-MM-YYYY").isValid()
      ) {
        const regex = /[DMY]/i;
        if (regex.test(e.target.value)) {
          return;
        }
        let start = new Date(
          moment(e.target.value?.split(" - ")[0], "DD-MM-YYYY").format(
            "YYYY-MM-DD"
          )
        );
        let end = new Date(
          moment(e.target.value?.split(" - ")[1], "DD-MM-YYYY").format(
            "YYYY-MM-DD"
          )
        );
        if (moment(start).isAfter(moment())) {
          start = new Date(moment().format("YYYY-MM-DD"));
        }
        if (moment(end).isAfter(moment())) {
          end = new Date(moment().format("YYYY-MM-DD"));
        }
        onchange([start, end]);
      }
    }
    // if (parseInt(e.target.value[6], 10) > 2) {
    //   setMaskString("DD-MM-YY - DD-MM-YY");
    //   setMask("00.00.00 - 00.00.00");
    // } else {
    setMaskString("DD-MM-YYYY - DD-MM-YYYY");

    setMask("00-00-0000 - 00-00-0000");
    // }
  };

  const dateValidate = (date) => {
    return moment(date, "DD/MM/YYYY", true)?._isValid;
  };

  const handleChange = (e) => {};

  useEffect(() => {
    if (clear) {
      setlocaldate("");
    }
  }, [clear]);
  useEffect(() => {
    const handle = (e) => {
      if (!calRef?.current?.contains(e.target)) setOpen(false);
    };
    document.addEventListener("mousedown", handle);
  }, []);
  return (
    <div className={customStyle ? "" : "filterCal"}>
      {!hideinput ? (
        <MaskInput
          alwaysShowMask
          onChange={onChange1231}
          maskString={maskString}
          mask={mask}
          size={20}
          showMask={true}
          value={localdate}
          // maskFormat={'DD-MM-YYYY'}
          onClick={() => {
            setOpen(true);
          }}
          style={
            customStyle || {
              width: "100%",
              background: theme.palette.filterCalendar.background,
              border: `1.9px solid ${theme.palette.filterCalendar.border}`,
              height: "35px",
              textAlign: "center",
              borderRadius: "20px",
              color: "white",
            }
          }
        />
      ) : (
        <div
          onClick={() => {
            setOpen(true);
          }}
        >
          Custom
        </div>
      )}

      {/* <input
        style={
          customStyle || {
            width: "100%",
            background: "#130C28",
            border: " 1.9px solid #711CF1",
            height: "35px",
            textAlign: "center",
            borderRadius: "20px",
          }
        }
        onChange={handleChange}
        placeholder={customLabel ? "" : "dd/mm/yyyy - dd/mm/yyyy"}
        type="text"
        value={
          values[0]
            ? `${moment(values[0]).format(`DD-MM-YYYY`)} - ${moment(
                values[1]
              ).format(`DD-MM-YYYY`)}`
            : currentDate
            ? currentDate?.start + "-" + currentDate?.end
            : customLabel
            ? customLabel
            : ""
        }
        onClick={() => {
          setOpen(true);
        }}
      /> */}

      <div
        className="calendar-container"
        ref={calRef}
        style={{
          display: open ? "block" : "none",
          width: "254px",
          ...style,
          ...customCalenderPostion,
        }}
      >
        <Calendar
          allowPartialRange={true}
          onChange={(d) => {
            if (d.length < 2) {
              setTempdate(d[0]);
              setTempmaxdate(null);
            } else {
              if (
                moment(d[0]).diff(moment(d[1]), "months") == 0 &&
                type &&
                type == "1"
              ) {
                d[0] = new Date(moment(d[0]).format("YYYY-MM-") + "01");

                if (
                  moment(d[0])
                    .add(1, "months")
                    .isAfter(moment().format("DD-MM-YYYY"))
                ) {
                  d[1] = new Date(
                    moment(moment(d[1]).format("YYYY-MM-") + "01")
                      .add(1, "month")
                      .subtract(1, "day")
                  );
                } else {
                  d[1] = new Date(
                    moment(d[1]).add(1, "month").format("YYYY-MM-") + "01"
                  );
                }
              }
              setTempdate(null);
              setTempmaxdate(d[0]);
              onchange(d);
              setlocaldate(
                d[0]
                  ? moment(d[0]).format("DD-MM-YYYY") +
                      "-" +
                      moment(d[1]).format("DD-MM-YYYY")
                  : ""
              );

              setOpen(false);
            }
          }}
          value={values}
          selectRange={true}
          defaultView="year"
          //   minDetail='month'
          //   maxDetail='year'
          minDate={
            tempdate && minDate
              ? new Date(
                  moment(tempdate)
                    .subtract(minDate.min, minDate.type)
                    .format("YYYY-MM-DD")
                )
              : null
          }
          maxDate={
            maxDate
              ? maxDate.current
                ? maxDate.current
                : moment(tempmaxdate, "YYYY-MM-DD")
                    .add(maxDate.min, maxDate.type)
                    .format("YYYY-MM-DD")
              : null
          }
        />
      </div>
    </div>
  );
}
