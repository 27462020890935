import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tabs, Tab, Menu, MenuItem, Popover } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useState } from "react";
import { useNavigate } from "react-router";
import { routes } from "../../../../routes";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    background: "white",
  },

  /* To Align Arrow Drop Down */
  arrowDropDownIcon: {
    verticalAlign: "middle",
    marginLeft: "5px",
    marginBottom: "3px",
  },
});

export default function TabEquityPriceChart({ setTitle }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tab, setTab] = React.useState(0);
  const [priceDropDownValue, setPriceDropDownValue] = useState("");
  const [moreDropDownValue, setMoreDropDownValue] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = (event, tab) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setTab(tab);
  };

  const handleClose = (e) => {
    const { innerText } = e.target;
    // Price Tab is Selected
    if (tab === 0) {
      setPriceDropDownValue(innerText);
      setMoreDropDownValue("");
      /*
       * Naviate to /dividend-&-bonus or /volume
       */
      navigate(
        `${routes?.equityCorporate}/price-chart/${innerText
          .toLowerCase()
          .replaceAll(" ", "-")}`
      );
    }
    // More Tab is Selected
    else {
      setMoreDropDownValue(innerText);
      setPriceDropDownValue("");
      navigate(
        `${routes?.equityCorporate}/price-chart/${innerText
          .toLowerCase()
          .replaceAll("/", "-")}`
      );
    }
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <Tabs value={value} onChange={handleChange}>
        <Tab
          label={
            <div>
              Price
              <ArrowDropDownIcon className={classes.arrowDropDownIcon} />
            </div>
          }
          onClick={(e) => {
            e.preventDefault();
            handleClick(e, 0);
          }}
        />

        <Tab
          label="PE Ratio"
          onClick={(e) => {
            e.preventDefault();
            handleClick(e, 1);
          }}
        />
        <Tab
          label="Sales & Margin"
          onClick={(e) => {
            e.preventDefault();
            handleClick(e, 2);
          }}
        />
        <Tab
          label={
            <div>
              More
              <ArrowDropDownIcon className={classes.arrowDropDownIcon} />
            </div>
          }
          onClick={(e) => {
            e.preventDefault();
            handleClick(e, 3);
          }}
        />
      </Tabs>
      <Menu
        id="simple-menu"
        variant="menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {tab === 0 && (
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem onClick={handleClose}>Dividend & Bonus</MenuItem>
            <MenuItem onClick={handleClose}>Volume</MenuItem>
          </Popover>
        )}

        {tab === 3 && (
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem onClick={handleClose}>EV/EVBITDA</MenuItem>
            <MenuItem onClick={handleClose}>Market/Cap Sales</MenuItem>
          </Popover>
        )}
      </Menu>
    </div>
  );
}
