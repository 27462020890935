import React from "react";
import useStyles from "../../common/ProductsStyles";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  useTheme,
} from "@material-ui/core";
import { ReactComponent as Back } from "../../../../../assets/holding-summary/back-blue.svg";
import { ReactComponent as Print } from "../../../../../assets/icons/print.svg";

import { ReactComponent as Import } from "../../../../../assets/icons/import.svg";
import { ReactComponent as Export } from "../../../../../assets/icons/export.svg";
import { ReactComponent as Filter } from "../../../../../assets/icons/filter.svg";
import { ReactComponent as Eye } from "../../../../../assets/icons/white-eye.svg";
import { Link, useNavigate } from "react-router-dom";
export default function EquityIPOHeading() {
  const classes = useStyles();
  const theme = useTheme();
  const icons = theme.palette.icons;
  const Bulb = icons.bulbIcon;
  const navigate = useNavigate();
  return (
    <div>
      <div className={`${classes.alignTopHeader}`}>
        <Back
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate(-1);
          }}
        />
        <Typography
          style={{ marginLeft: "-4%" }}
          className={`${classes.mainHeading} ${classes.mainHeadingAlign}`}
        >
          IPO Details
        </Typography>
        <div className={`${classes.iconAlign}`}>
          {/* {props.title === "Holding Summary" || props.title === "Transaction Summary" ? <div style={{ marginTop: "-8px" }}><SearchInput /></div> : ""} */}
          <Bulb className={classes.iconStyle} />
          <Import className={classes.iconStyle} />
          <Export className={classes.iconStyle} />
          <Print className={classes.iconStyle} />
          <Filter className={classes.iconStyle} />
        </div>
      </div>
      {/* <Typography style={{ cursor: 'pointer', marginLeft: "90px", marginTop: "-1.5%" }} className={classes.aifSubText}>{props.title === "Holding Summary" || props.title === "Transaction Summary" ? "Direct Equity" : ""}</Typography> */}
    </div>
  );
}
