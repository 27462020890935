import React from "react";
import Default from "../components/dashboard/defaultPages/Default";
import { useParams } from "react-router-dom";
import StructeredProductReport from "../components/dashboard/products/structured_products/StructeredProductReport";

function StructuredProductDetails() {
  const { pageName } = useParams();
  return (
    <>
      <Default>
        <StructeredProductReport pageName={pageName} />
      </Default>
    </>
  );
}

export default StructuredProductDetails;
