import PageContainer from "../PageContainer";
import { divergingCommonProps } from "../../../charts/nivo_objects";
import React from "react";
import "./Annexures.css";

export default function Annexures({ pdfInfo }) {
  let bartheme = {
    background: "transparent",
    textColor: "#333333",
    fontSize: 11,
    axis: {
      domain: {
        line: {
          stroke: "#fff",
          strokeWidth: 2,
        },
      },
      legend: {
        text: {
          fontSize: 12,
          fill: "#F8F9FB",
        },
      },
      ticks: {
        line: {
          stroke: "#1f1c40",
          strokeWidth: 1,
        },
        text: {
          fontSize: 11,
          fill: "#000000",
        },
      },
    },
    grid: {
      line: {
        stroke: "#1f1c40",
        strokeWidth: 1,
      },
    },
    legends: {
      title: {
        text: {
          fontSize: 11,
          fill: "#ebe8ee",
        },
      },
      text: {
        fontSize: 11,
        fill: "#000000",
      },
      ticks: {
        line: {},
        text: {
          fontSize: 10,
          fill: "#333333",
        },
      },
    },
    annotations: {
      text: {
        fontSize: 13,
        fill: "#333333",
        outlineWidth: 2,
        outlineColor: "#3c9cfa",
        outlineOpacity: 0.7,
      },
      link: {
        stroke: "#000000",
        strokeWidth: 1,
        outlineWidth: 2,
        outlineColor: "#ffffff",
        outlineOpacity: 1,
      },
      outline: {
        stroke: "#000000",
        strokeWidth: 2,
        outlineWidth: 2,
        outlineColor: "#ffffff",
        outlineOpacity: 1,
      },
      symbol: {
        fill: "#000000",
        outlineWidth: 2,
        outlineColor: "#ffffff",
        outlineOpacity: 1,
      },
    },
    tooltip: {
      container: {
        background: "#ffffff",
        color: "#333333",
        fontSize: 12,
      },
      basic: {},
      chip: {},
      table: {},
      tableCell: {},
      tableCellValue: {},
    },
  };
  bartheme.axis.domain.line.stroke = "#ECECEC";
  bartheme.axis.domain.line.strokeWidth = "1";
  let diverging = {
    ...divergingCommonProps,
    enableGridY: false,
  };
  return (
    <div>
      <PageContainer
        layout_t={1}
        pageNo={1}
        bgColor={"#ffffff"}
        pdfInfo={pdfInfo}
        headerText={" "}
      >
        <div
          style={{
            height: "3.8in",
            paddingTop: "50px",
            paddingBottom: "100px",
          }}
        >
          <h3 className="headerText">Annexures</h3>
          <div className="boxContainer">
            <div className="annBox">
              <h3 className="h3Style">Equity</h3>
              <span className="spanCls"></span>
              <p className="paraStyle">Direct Equity</p>
              <p className="paraStyle">Mutual Funds</p>
              <p className="paraStyle">AIF</p>
              <p className="paraStyle">PMS</p>
              <p className="paraStyle">Structured Product</p>
              <p className="paraStyle">Unlisted Equity</p>
            </div>
            <div className="annBox">
              <h3 className="h3Style">Debt</h3>
              <span className="spanCls"></span>
              <p className="paraStyle">Bonds</p>
              <p className="paraStyle">Mutual Funds</p>
              <p className="paraStyle">AIF</p>
              <p className="paraStyle">PMS</p>

              <p className="paraStyle">Inter-Corporate Deposits</p>
              <p className="paraStyle">Fixed Deposits</p>
              <p className="paraStyle">Recurring Deposits</p>
            </div>
            <div className="annBox">
              <h3 className="h3Style">Alternates</h3>
              <span className="spanCls"></span>

              <p className="paraStyle">Mutual Funds</p>
              <p className="paraStyle">AIF</p>
              <p className="paraStyle">PMS</p>
              <p className="paraStyle">Structured Product</p>

              <p className="paraStyle">Market-linked Debentures</p>
              <p className="paraStyle">Real Estate</p>
              <p className="paraStyle">Insurance</p>
              <p className="paraStyle">REITS</p>
              <p className="paraStyle">InVITS</p>
            </div>
            <div className="annBox">
              <h3 className="h3Style">Cash</h3>
              <span className="spanCls"></span>
              <p className="paraStyle">Bank Book</p>
              {/* <p className="paraStyle">PMS</p> */}
              <p className="paraStyle">Mutual Funds</p>
              <p className="paraStyle">Funds-in-Transit</p>
            </div>
          </div>
        </div>
      </PageContainer>
    </div>
  );
}
