// var CryptoJS = require("crypto-js");
import React, { useRef } from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

import Disclaimer from "./pages/Disclaimer";
import { useApi } from "./hook/useApi";
import { useEffect } from "react";

import { useLocation, useSearchParams, useParams } from "react-router-dom";

import { useState } from "react";
import PageLoader from "../products/common/pageLoader";

import useWindowSize from "./hook/seWindowSize";

import useGetMongoPrintReport from "../../../hooks/useGetMongoPrintReport";

import ThankYouPage from "./pages/ThankYouPdf";

import LoadingScreen from "./pages/LoadingScreen";

import { CoverPage } from "./pages/subPrintPages/CoverPage";
import Annexures from "./pages/Annexures";
import { OnboardingContext } from "../../../Context";
// import Tax from "./pages/Tax";
// import TaxModule from "./pages/TaxModule";
import TaxSort from "./pages/TaxSort";
import {
  objectFormatingFamilyHolding,
  objectFormatingIncomeHolding,
} from "./Components/utils/common";
import {
  IncomeMemberholding,
  IncomeModel,
  RealisedMemberholding,
  UnrealisedGainlossModel,
  familyholding,
} from "./Components/utils/staticData";
import HoldingPrint from "./pages/subPrintPages/HoldingPrint";
import GainLossSort from "./pages/GainLossSort";
import IncomeSort from "./pages/IncomeSort";

export default function IncomeReport({ mongo, handleUploadPDF, download }) {
  const { dispatch } = OnboardingContext();
  const [browserSize, setBrowserSize] = useState(
    ((window.outerWidth - 10) / window.innerWidth) * 100
  );
  // const {
  //   state: { mongoId },
  // } = useLocation();
  // const {} = useSearchParams();
  let [searchParams, setSearchParams] = useSearchParams();
  // let denomination = "Lakhs";
  const mongoId = searchParams.get("id");
  const parentRef = useRef(null);
  // const { type, familyMembers, duration, denomination, mongoId } = Decrypt(
  //   searchParams.get("key")
  // );
  const windowSize = useWindowSize();
  const [changes, setChanges] = useState();
  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "#E4E4E4",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
  });
  //
  let payload = {};
  // if (familyMembers?.length > 0) {
  //   payload["memberId"] = familyMembers?.join(",");
  // }
  // if (duration?.startDate !== "") {
  //   payload["startDate"] = duration?.startDate;
  //   payload["endDate"] = duration?.endDate;
  // }
  // localStorage.setItem("denomination", denomination);
  const [data, setData] = useState({});
  const [reset, setReset] = useState(false);
  const {
    showLoader,
    setShowLoader,
    CallApis,
    errorMessage,
    customData,
    erroredAPIS,
    apiArray,
  } = useApi(payload, setReset);

  const { newData, denomination, meta, pdfInfo, loading } =
    useGetMongoPrintReport(
      // "658187af8a5d24635c5e0298"
      // // "65e00dea0cda3c000851f632"
      // "65e15f4b2fffc9727f32cb17"
      // "65e711971cd0cd00098d8f5e"

      // "65e8538e99eb3863be6ae12e"
      // "65f2a702ae19f10008f6aeb2"

      mongoId || mongo
    );
  // mongoId

  const getDenomination = localStorage.getItem("denomination");

  // useEffect(() => {
  //   // handleData();
  //   // if (getDenomination == denomination) {
  //   // setTimeout(() => {
  //   //   console.log(getDenomination, "asfjkbakjsbahf");
  //   CallApis(apiArray(payload).splice(0, 5), {}, 0, 3);
  //   // }, 5000);
  //   // }
  // }, []);

  // useEffect(() => {
  //   setData(() => customData);
  // }, [customData]);
  const common = [
    {
      title: (
        <p>
          Scheme Name
          {/* <span className="s_text">ISIN</span> */}
        </p>
      ),
      align: "left",
      color: "",
      subtext: <p className="italic1 ">ISIN</p>,
    },
    {
      title: "Product ",

      align: "right",
      color: "",
    },
    {
      title: "Date",
      align: "right",
      color: "",
    },
    {
      title: "Type",
      align: "right",
      color: "",
    },
    {
      title: "Quantity",
      align: "right",
      color: "",
    },
    {
      title: "Amount ",
      align: "right",
      color: "",
    },
  ];
  let headers = [
    ...common,
    {
      title: "Total Amount ",
      align: "right",
      color: "",
    },

    // {
    //   title: " ",
    //   align: "center",
    //   color: "",
    //   col: 1,
    // },
    // {
    //   title: "Sale",
    //   align: "center",
    //   color: "",
    //   col: 4,
    // },
    // {
    //   title: "Purchase",
    //   align: "center",
    //   color: "",
    //   col: 4,
    // },
  ];
  let headers2 = [
    ...common,
    {
      title: "Current FV ",
      field: "currentFaceValue",
      align: "right",
    },
    {
      title: "Total Amount ",
      align: "right",
      color: "",
    },
  ];
  // const header2 = [
  //   {
  //     title: "Instrument Name",
  //     align: "left",
  //     subtext: "ISIN",
  //     borderRight: " 0.6px solid #DDE1F8",
  //     col: 1,
  //   },
  //   {
  //     title: "Sale Date",
  //     align: "left",
  //     color: "",
  //     col: 1,
  //   },
  //   {
  //     title: "Quantity",
  //     align: "left",
  //     color: "",
  //     col: 1,
  //   },
  //   {
  //     title: "Sale Rate",
  //     align: "left",
  //     color: "",
  //     col: 1,
  //   },
  //   {
  //     title: "Sale Amount",
  //     align: "left",
  //     color: "",
  //     borderRight: " 0.6px solid #DDE1F8",
  //     col: 1,
  //   },
  //   {
  //     title: "Purchase Date",
  //     align: "left",
  //     color: "",
  //     col: 1,
  //   },
  //   {
  //     title: "Purchase Rate",
  //     align: "left",
  //     color: "",
  //     col: 1,
  //   },
  //   {
  //     title: "Purchase Amount",
  //     align: "left",
  //     color: "",
  //     col: 1,
  //     borderRight: " 0.6px solid #DDE1F8",
  //   },

  //   {
  //     title: "Total Amount",
  //     align: "left",
  //     color: "",
  //     col: 1,
  //   },
  // ];
  useEffect(() => {
    //for testing
    // Object?.keys(newData).map((a) => {
    //   newData[a] = "error";
    // });
    // if (changes) {

    if (browserSize < 99) {
      dispatch({
        type: "UPDATE_SNACKBAR_DATA",
        payload: {
          success: true,
          error: false,
          showMsg: true,
          message:
            "Please set Browser Zoom to 100% for better viewing of report",
        },
      });
    }
    let a = parentRef?.current?.querySelectorAll(".printPageNumber");
    if (!a) {
      return;
    }
    Array.from(a)?.map((val, i) => {
      val.innerHTML = `${i + 1}`;
    });
    // setNewPageList(a);
    // }

    const getData = setTimeout(() => {
      if (Object.keys(newData).length > 0 && download == 0) {
        handleUploadPDF();
      }
    }, 5000);

    return () => clearTimeout(getData);
  }, [changes, data, newData]);

  const infoText = "";

  // console.log(Object?.keys(newData), "aglnj");
  function generateFamilyHoldings() {
    console.log("Family Holding");
    let data =
      newData?.["member-wise-modules-data"]?.overview?.["memberAssetWiseTotal"];

    data?.push({
      ...(newData?.["member-wise-modules-data"]?.overview.assetWiseTotal ?? {}),

      memberName: "Total",
    });
    // console.log("data", data);
    const breadcrumbs = [
      <a key="1" color="#000000" className="printBreadsel">
        Overview
      </a>,
    ];
    return (
      <div className="newPrint pagebreakTable verticleAlignCen">
        {data && data.length > 0 && (
          <HoldingPrint
            headerType={13}
            title={breadcrumbs}
            footerLegendText={""}
            pdfInfo={pdfInfo}
            // asOnDate={refForTotal?.asOnDate}
            layout={1}
            title2={""}
            title1={""}
            customTableHeader={IncomeMemberholding}
            subtext={""}
            customKey={""}
            type={"holdingSummary"}
            loading={false}
            setChanges={() => {}}
            breadcrumb={breadcrumbs}
            // error={data?.equity?.direct_equity_holding_summary?.error}
            customData={data}
            customTableClass={"printTableEvenAndOddRow"}
            hideTitle={true}
            hideTotalRow={true}
            denomination={denomination}
            colmapping={objectFormatingIncomeHolding}
          />
        )}
      </div>
    );
  }

  return (
    <>
      {loading && (
        <>
          {/* <FirstPage type={"print"} pdfInfo={pdfInfo} /> */}
          <CoverPage
            type={"pdf"}
            title={"Income Statement"}
            meat={meta}
            pdfInfo={pdfInfo}
          />
          <LoadingScreen loading={loading} />
        </>
      )}
      {Object?.keys(newData)?.length > 0 && (
        <Document ref={parentRef}>
          <CoverPage
            type={"pdf"}
            title={"Income Statement"}
            meat={meta}
            pdfInfo={pdfInfo}
          />
          {newData &&
            pdfInfo.processing_format != "productWise" &&
            generateFamilyHoldings()}
          <div className="incomeSort verticlealignTop">
            <IncomeSort
              infoText={infoText}
              title={"Income Statement"}
              type="unrealised"
              model={IncomeModel}
              headers={headers}
              headers2={headers2}
              // header2={header2}
              denomination={denomination}
              newdata={newData}
              pdfInfo={pdfInfo}
              setChanges={setChanges}
            />
          </div>

          <ThankYouPage pdfInfo={pdfInfo} />
          <Annexures pdfInfo={pdfInfo} />
          <Disclaimer pdfInfo={pdfInfo} />
          {/* <Page size="A4">
            <Disclaimer />
          </Page> */}
          {/* <Page size="A4">
            <LastPage type={"pdf"} />
          </Page> */}
        </Document>
      )}
    </>
  );
}
