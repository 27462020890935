import React from "react";
import { useParams } from "react-router-dom";
import Default from "../components/dashboard/defaultPages/Default";
import RealEstateReport from "../components/dashboard/products/realEstate/RealEstateReport";

function RealEstateDetails() {
  const { pageName } = useParams();

  return (
    <>
      <Default>
        <RealEstateReport pageName={pageName}></RealEstateReport>
      </Default>
    </>
  );
}

export default RealEstateDetails;
