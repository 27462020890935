import { ResponsivePie } from "@nivo/pie";

import React from "react";
import { currencyFormat } from "../../../../hooks/constantFunction";
// let data1 = [
//   {
//     id: "Held",
//     label: "Held",
//     value: "5.54",
//     val: ["564092483.94", "671527005.81", "9.54"],
//     color: "hsl(228, 70%, 50%)",
//   },
//   {
//     id: "Held Away",
//     label: "Held Away",
//     value: "90.46",
//     val: ["5347050732.85", "3040403303.03", "90.46"],
//     color: "hsl(154, 70%, 50%)",
//   },
//   {
//     id: "Held Away1",
//     label: "Held Away",
//     value: "30.46",
//     val: ["5347050732.85", "3040403303.03", "90.46"],
//     color: "hsl(154, 70%, 50%)",
//   },
//   {
//     id: "Held Away2",
//     label: "Held Away",
//     value: "40.46",
//     val: ["5347050732.85", "3040403303.03", "90.46"],
//     color: "hsl(154, 70%, 50%)",
//   },
// ];

function Print_PieChart({ config, data = [] }) {
  let colors = [];
  // let count = 0;
  data = data?.filter((d, i) => {
    if (d?.value > 0) {
      colors.push(config?.colors[i]);
      // count = count + 1;
      // console.log("colors", config.colors, i, config.colors[i]);
      return true;
    }
  });

  // console.log("ramData", data);
  return (
    <ResponsivePie
      data={data}
      tooltip={() => ""}
      {...config.chart}
      margin={{ top: 160, right: 170, bottom: 190, left: 170 }}
      innerRadius={0.7}
      startAngle={19}
      endAngle={-360}
      padAngle={0.7}
      cornerRadius={3}
      colors={config.colors ? colors : { scheme: "nivo" }}
      //activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["darker", "0.2"]],
      }}
      arcLinkLabel={(e) => (
        <tspan className="tspans">
          {" "}
          <tspan x="10" dy="-25px">
            {}
            {e.id}{" "}
          </tspan>
          {/* <tspan x="20" dy="1.3em">
            {}
            Invested Value
          </tspan>
          <tspan x="20" dy="15px">
            {currencyFormat(e.data.val[0] || null) || ""}
          </tspan>
          <tspan x="20" dy="20px">
            Current Value
          </tspan>
          <tspan x="20" dy="15px">
            {currencyFormat(e.data.val[1] || null) || ""}
          </tspan>
          <tspan x="20" dy="20px">
            Percentage
          </tspan> */}
          <tspan x="10" dy="25px">
            {e.data.val[2] + "%" || ""}
          </tspan>
        </tspan>
      )}
      enableArcLinkLabels={false}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextOffset={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsOffset={-2}
      arcLinkLabelsDiagonalLength={118}
      arcLinkLabelsStraightLength={15}
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color", modifiers: [] }}
      enableArcLabels={false}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      defs={
        [
          // {
          //   id: "dots",
          //   type: "patternDots",
          //   background: "inherit",
          //   color: "rgba(255, 255, 255, 0.3)",
          //   size: 4,
          //   padding: 1,
          //   stagger: true,
          // },
          // {
          //   id: "lines",
          //   type: "patternLines",
          //   background: "inherit",
          //   color: "rgba(255, 255, 255, 0.3)",
          //   rotation: -45,
          //   lineWidth: 6,
          //   spacing: 10,
          // },
        ]
      }
      fill={[
        {
          match: {
            id: "ruby",
          },
          id: "dots",
        },
        {
          match: {
            id: "c",
          },
          id: "dots",
        },
        {
          match: {
            id: "go",
          },
          id: "dots",
        },
        {
          match: {
            id: "python",
          },
          id: "dots",
        },
        {
          match: {
            id: "scala",
          },
          id: "lines",
        },
        {
          match: {
            id: "lisp",
          },
          id: "lines",
        },
        {
          match: {
            id: "elixir",
          },
          id: "lines",
        },
        {
          match: {
            id: "javascript",
          },
          id: "lines",
        },
      ]}
      legends={
        [
          // {
          //   anchor: "bottom",
          //   direction: "row",
          //   justify: false,
          //   translateX: 0,
          //   translateY: 156,
          //   itemsSpacing: 0,
          //   itemWidth: 100,
          //   itemHeight: 18,
          //   itemTextColor: "#999",
          //   itemDirection: "left-to-right",
          //   itemOpacity: 1,
          //   symbolSize: 18,
          //   symbolShape: "circle",
          //   legendFormat: (d) => d.id + "leg",
          //   effects: [
          //     {
          //       on: "hover",
          //       style: {
          //         itemTextColor: "#000",
          //       },
          //     },
          //   ],
          // },
        ]
      }
    />
  );
}

export default Print_PieChart;
