// var CryptoJS = require("crypto-js");
import React, { useRef } from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { LastPage } from "./Components/LastPage";
import { useApi } from "./hook/useApi";
import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useState } from "react";
import {
  newpdfHolding,
  newPdfSecurityHolding,
} from "./Components/utils/staticData";
import useWindowSize from "./hook/seWindowSize";
import HoldingPrint from "./pages/subPrintPages/HoldingPrint";
import { CoverPage } from "./pages/subPrintPages/CoverPage";
import { stDataHolding } from "./constands";
import useGetMongoPrintReport from "../../../hooks/useGetMongoPrintReport";
import HoldingPdfPage from "./pages/HoldingPdfPage";
import ThankYouPage from "./pages/ThankYouPdf";
import LoadingScreen from "./pages/LoadingScreen";
import HoldingPdfPagev1 from "./pages/HoldingPdfPagev1";
import Annexures from "./pages/Annexures";
import Disclaimer from "./pages/Disclaimer";
import CustomizedSnackbars from "../../common/CustomizedSnackbars";
import { OnboardingContext } from "../../../Context";
import HoldingSecurityPdfPagev1 from "./pages/HoldingSecurityPdfPagev1";
import HoldingPdfPagev2 from "./pages/HoldingPdfPagev2";
const SECURITY_HOLDING_SUMMARY =
  "Consolidated Securities Holding Summary Report";
export default function HoldingPdf({
  type,
  mongo,
  handleUploadPDF,
  download,
  setUrl,
}) {
  const { userState, dispatch } = OnboardingContext();
  const [browserSize, setBrowserSize] = useState(
    ((window.outerWidth - 10) / window.innerWidth) * 100
  );

  const parentRef = useRef(null);
  const windowSize = useWindowSize();
  const [changes, setChanges] = useState();
  //
  let payload = {};

  const [data, setData] = useState({});
  let [searchParams, setSearchParams] = useSearchParams();
  // let denomination = "Lakhs";
  const mongoId = searchParams.get("id");
  // const {
  //   state: { mongoId },
  // } = useLocation();

  const { newData, denomination, meta, reportType, loading, pdfInfo } =
    useGetMongoPrintReport(
      // "657166f692287431c8849354"
      // "657317110313eb5a951157bb"
      // "6579e6737ca3421e40620d21" //for holding summary
      // "65c472423b4b2585bbc7a02e"
      // "65e8538e99eb3863be6ae12e"
      // "65f1438c685fe824f3140626"
      mongoId || mongo,
      setUrl
    );

  const getDenomination = localStorage.getItem("denomination");

  useEffect(() => {
    if (browserSize < 99) {
      dispatch({
        type: "UPDATE_SNACKBAR_DATA",
        payload: {
          success: true,
          error: false,
          showMsg: true,
          message:
            "Please set Browser Zoom to 100% for better viewing of report",
        },
      });
    }
    if (changes) {
      let a = parentRef.current.querySelectorAll(".printPageNumber");

      Array.from(a)?.map((val, i) => {
        // val.innerHTML = `${i + 1}/${Array.from(a).length}`;
        val.innerHTML = `${i + 1}`;
      });
      // setNewPageList(a);
    }
    const getData = setTimeout(() => {
      if (Object.keys(newData).length > 0 && download == 0) {
        handleUploadPDF();
      }
    }, 5000);

    return () => clearTimeout(getData);
  }, [changes, data, browserSize]);

  useEffect(() => {
    setBrowserSize(((window.outerWidth - 10) / window.innerWidth) * 100);
  }, []);

  function renderCustomTableHeaderBasedOnReportType() {
    if (reportType === SECURITY_HOLDING_SUMMARY) {
      return newPdfSecurityHolding;
    } else {
      return newpdfHolding;
    }
  }

  return (
    <>
      <CustomizedSnackbars
        customSnackBarPosition={{ vertical: "top", horizontal: "center" }}
        customAlertColor="warning"
      />
      <Document ref={parentRef}>
        {loading && (
          <>
            <CoverPage
              type={"pdf"}
              title={
                reportType === SECURITY_HOLDING_SUMMARY &&
                "Security Holding Summary"
              }
            />

            <LoadingScreen loading={loading} />
          </>
        )}
        {/* <button
        style={{
          position: "sticky",
          top: "10px",
          left: "10px",
          zIndex: "10",
        }}
        onClick={() => {
          CallApis(
            erroredAPIS.filter((val) => val !== undefined),
            data
          );
          if (erroredAPIS.filter((val) => val !== undefined).length > 0)
            setReset(true);
        }}
      >
        retry
      </button> */}
        {/* <PageLoader
        false={
          false == true
            ? false
            : erroredAPIS.flat(1).filter((val) => val !== undefined)?.length > 0
            ? true
            : false
        }
        hanldeError={() =>
          CallApis(
            erroredAPIS.flat(1).filter((val) => val !== undefined),
            data,
            0,
            5
          )
        }
        error={
          false == false &&
          erroredAPIS.flat(1).filter((val) => val !== undefined)?.length > 0
        }
      /> */}

        <CoverPage
          type={"pdf"}
          pdfInfo={pdfInfo}
          title={
            reportType === SECURITY_HOLDING_SUMMARY &&
            "Security Holding Summary"
          }
        />
        {/* {Array.isArray(newData) &&
        newData?.map((arr) => {
          return Object.keys(arr?.assetClass).map((p) => {
            return Object.keys(arr?.assetClass[p]).map((prod) => {
              if (
                arr.assetClass[p][prod]?.investments?.length == 0 ||
                arr.assetClass[p][prod]?.investments == undefined
              ) {
                return <></>;
              }
              let refForTotal = arr.assetClass[p][prod]?.totalValues;

              arr.assetClass[p][prod]?.investments.push({
                " % of Total Portfolio": refForTotal?.["% of Portfolio"],
                "Absolute TWRR": refForTotal?.["Absolute TWRR"],
                "Advised By": "‎ ",
                "Annualized TWRR": refForTotal?.["Annualised TWRR"],
                "Asset Class": " ",
                "Current Market Value": refForTotal?.["Current Value"],
                "Folio No": " ",
                "Gain/Loss": refForTotal?.["Unrealised Gain/Loss"],
                "ISIN No/Product Code": " ",
                "Instrument Name/Scheme Name": "Total",
                "Last Price Date": "‎ ",
                "Market Price per Unit": " ",
                "Member Name": " ",
                "Other Income": refForTotal?.["Other Income"],
                "Product Name": " ",
                "Quantity/Units": "‎ ",
                "Total Cost": refForTotal?.["Investment Value"],
                XIRR: refForTotal?.["XIRR"],
              });
              return (
                <HoldingPrint
                  headerType={8}
                  title={"Holding Summary"}
                  title2={prod}
                  title1={p}
                  customTableHeader={renderCustomTableHeaderBasedOnReportType()}
                  subtext={prod}
                  customKey={prod}
                  loading={false}
                  setChanges={setChanges}
                  // error={data?.equity?.direct_equity_holding_summary?.error}
                  customData={
                    // data?.equity?.direct_equity_holding_summary?.data?.data?.result
                    // stDataHolding
                    arr.assetClass[p][prod]?.investments
                  }
                  customTableClass={"printTableEvenAndOddRow"}
                  hideTitle={true}
                  hideTotalRow={true}
                  denomination={denomination}
                />
              );
            });
          });
        })} */}
        {type == "holding" && (
          <HoldingPdfPagev2
            newData={newData}
            reportType={reportType}
            key={"holdingSummary"}
            denomination={denomination}
            setChanges={setChanges}
            pdfInfo={pdfInfo}
          />
        )}
        {type == "security-holding" && (
          <HoldingSecurityPdfPagev1
            newData={newData}
            reportType={reportType}
            key1={"securities-holding-summary"}
            denomination={denomination}
            setChanges={setChanges}
            pdfInfo={pdfInfo}
          />
        )}
        {type != "holding" && type != "security-holding" && (
          <HoldingPdfPage
            newData={newData}
            reportType={reportType}
            denomination={denomination}
            setChanges={setChanges}
            pdfInfo={pdfInfo}
          />
        )}
        <ThankYouPage pdfInfo={pdfInfo} />
        <Annexures pdfInfo={pdfInfo} />
        <Disclaimer pdfInfo={pdfInfo} />
        {/* <Page size="A4">
        <LastPage type={"pdf"} />
      </Page> */}
      </Document>
    </>
  );
}
