import React from "react";
import { ReactComponent as Filter } from "../../../assets/icons/filter.svg";
import useStyles from "../../../../src/components/dashboard/products/common/ProductsStyles";
import { useTheme } from "@material-ui/core";

export default function Filtericon({ showfilters, setShowfilter, Icon }) {
  const classes = useStyles();

  return (
    <div>
      <Icon
        className={"iconStyle"}
        onClick={() => {
          setShowfilter(true);
        }}
      ></Icon>
      <div className="dispnone"></div>
    </div>
  );
}
