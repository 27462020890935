import { Box, Chip, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
export const UserHeaderInfo = ({ data }) => {
  const useStyles = makeStyles({
    headerInfoWrapper: {
      display: "flex",
      alignItems: "center",
      gap: "20px",
    },
    profilePicture: {
      width: "70px",
      height: "70px",
      borderRadius: "50%",
      background: "purple",
    },
    userDetails: {
      color: "#fff",
      fontFamily: '"Avenir"',
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "32px",
      marginLeft: "5px",
    },
    userName: {
      color: "white",
      marginLeft: "5px",
    },
    userRole: {
      color: "#fff",
      fontFamily: '"Avenir"',
      fontSize: "12px",
      fontWeight: 350,
      lineHeight: "22px",
      background: "#21163e",
    },
    familyMembers: {
      color: "#9488a7",
      fontFamily: '"Avenir"',
      fontSize: "13.505px",
      fontWeight: 500,
      lineHeight: "20.258px",
      textTransform: "uppercase",
    },
    membersLabel: {
      color: "#9488a7",
      marginBottom: "18px",
    },
    membersCount: {
      color: "#fff",
      fontFamily: '"Avenir"',
      fontSize: "47.751px",
      fontWeight: 500,
      lineHeight: "20.258px",
      textTransform: "capitalize",
    },
    mainWrapper: {
      //   border: "1px solid red",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      marginLeft: "35px",
    },
  });
  const classes = useStyles();
  function formattedString(text) {
    let re = /[A-Z-_\&](?=[a-z0-9]+)|[A-Z-_\&]+(?![a-z0-9])/g;
    return text?.replace(re, " $&")?.trim() || "--";
  }
  return (
    <Box className={classes.mainWrapper}>
      <Typography className={classes.userName}>User Management</Typography>
      <Box
        display="flex"
        alignItems="center"
        className={classes.headerInfoWrapper}
      >
        <Box className={classes.profilePicture}></Box>
        <Box className={classes.userDetails}>
          <Typography className={classes.userName}>{data?.name}</Typography>
          <Chip
            className={classes.userRole + " capitalize"}
            label={formattedString(data.role)}
          />
        </Box>
        <Box className={classes.familyMembers}>
          <Typography className={classes.membersLabel}>
            No of Members
          </Typography>
          <Typography className={classes.membersCount}>
            {data?.mapped}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
