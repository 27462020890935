import { Box } from "@material-ui/core";
import React from "react";

export const StrategyBuilderCard = ({
  icon,
  heading,
  subHeading,
  onClick,
  className,
}) => {
  // Ensure that className is a string or an array of strings
  let classNames = "";
  if (Array.isArray(className)) {
    classNames = className.join(" ");
  } else if (typeof className === "string") {
    classNames = className;
  }
  return (
    <Box className={`strategyBuilderCard ${classNames}`} onClick={onClick}>
      {icon}
      {heading}
      {subHeading}
    </Box>
  );
};
