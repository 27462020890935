import React from "react";
import Default from "../components/dashboard/defaultPages/Default";
import INVITSSummaryComp from "../components/dashboard/products/invits/INVITSSummaryComp";

function INVITSSummary() {
  return (
    <>
      <Default>
        <INVITSSummaryComp />
      </Default>
    </>
  );
}

export default INVITSSummary;
