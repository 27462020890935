import React from "react";
import { dateFormatCommon } from "../../../utils/common";

export const FooterLegend = ({ pdfInfo, valuationAsOnDate, text, notes }) => {
  return (
    <>
      {notes &&
        notes?.map((t) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0px 70px 0px 70px",
              }}
            >
              <p
                style={{
                  fontWeight: 800,
                  fontSize: "14px",
                  textTransform: "capitalize",
                  color: "#090909",
                  fontFamily: "'Avenir'",
                  fontStyle: "normal",
                }}
              >
                {t?.note}
              </p>
            </div>
          );
        })}
      {!notes && (
        <div
          className={"text-piped"}
          style={{
            display: "flex",
            alignItems: "center",
            margin: "15px 70px 0px 70px",
          }}
        >
          {pdfInfo?.report == "Client Portfolio Report" && text && (
            <p
              style={{
                fontFamily: "'Avenir'",
                fontStyle: "normal",
                fontWeight: 800,
                fontSize: "14px",
                lineHeight: "12px",
                textTransform: "capitalize",
                color: "#090909",
                paddingRight: "2px",
              }}
            >
              <span style={{ color: "#C6A03E" }}> * </span>
              Indicates Multiple Purchase dates
            </p>
          )}
          {typeof text == "string" ? (
            <p
              style={{
                fontWeight: 800,
                fontSize: "14px",
                textTransform: "capitalize",
                color: "#090909",
                fontFamily: "'Avenir'",
                fontStyle: "normal",
              }}
            >
              {text && (
                <span style={{ color: "#C6A03E", fontWeight: 900 }}>#</span>
              )}{" "}
              {text}
            </p>
          ) : (
            text?.map((t) => {
              return (
                <p
                  style={{
                    fontWeight: 800,
                    fontSize: "14px",
                    textTransform: "capitalize",
                    color: "#090909",
                    fontFamily: "'Avenir'",
                    fontStyle: "normal",
                  }}
                >
                  {text && (
                    <span
                      className={
                        t.text === "Indicative Price" ? "fAvenirBold" : ""
                      }
                      style={{ color: "#C6A03E", fontWeight: 900 }}
                    >
                      {t.symbol}
                    </span>
                  )}{" "}
                  {t.text}
                </p>
              );
            })
          )}
        </div>
      )}
    </>
  );
};
