import { useState, useEffect, useContext } from "react";
import { OnboardingContext } from "../Context";
import { mfoFilterContext } from "../mfo_context/Mfo_Context";
import moment from "moment";
import { portFolioREportingAging } from "../api";

export const usePortFolioReportingAging = (type, productVal, searchVal,offset,limit) => {
  const { userState } = OnboardingContext();
  const [portFolioReportingAging, setPortFolioReportingAging] = useState([]);
  const [loading, setLoading] = useState(true);
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  let clientId = userState.loginedUserData.id;

  // Inside your usePortFolioReportingAging hook
  useEffect(() => {
    let params = { ...getfilter_as_params() };
    if (mfoFilter.startDate) {
      params["startDate"] = moment(mfoFilter.startDate).format("YYYY-MM-DD");
      params["endDate"] = moment(mfoFilter.endDate).format("YYYY-MM-DD");
    }
    if (Object.keys(mfoFilter.checkData).length > 0) {
      params["memberId"] = Object.keys(mfoFilter.checkData).join(",");
    }
    if (Object.keys(mfoFilter.advisor).length > 0) {
      params["advisor"] = Object.keys(mfoFilter.advisor).join(",");
    }
    if (type === "dashboard") {
      params["assetClass"] = "equity,debt,cash,alternate";
    }
    params["productName"] = productVal;
    params["offset"] = offset;
    params["limit"] = limit;
    if (searchVal.length !== 0) {
      params["search"] = searchVal;
    }

    setLoading(true);
    portFolioREportingAging(clientId, params, type)
      .then((res) => setPortFolioReportingAging(res))
      .catch((err) => console.log(err, "error"))
      .finally(() => setLoading(false));
  }, [mfoFilter, productVal, searchVal,offset]);
  return {
    portFolioReportingAging,
    loading,
  };
};
