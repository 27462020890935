import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import { NewFilterSelectionChip } from "./NewFilterSelectionChip";
import { ReactComponent as CheckCircleIcon } from "../../../assets/icons/newMenuCheckCircleIcon.svg";
import "./NewFilter.css";
import {
  renderAmountOfAdvisorsSelected,
  renderAmountOfExecutorsSelected,
  renderAmountOfFamilyMembersSelected,
  renderPeriod,
} from "./utils/newFilterHeader";
import moment from "moment";
import { useTheme } from "@material-ui/core";
export const NewFilterHeader = ({
  selectedFamilyMembers,
  familyMembers,
  selectedAdvisors,
  advisors,
  selectedExecutors,
  executors,
  selectedDenomination,
  selectedPeriod,
  closeIcon,
  dateRange,
}) => {
  const theme = useTheme();
  return (
    <DialogTitle
      style={{
        background: theme.palette.newFilter.newFilterHeader,
        padding: "1.5rem",
      }}
    >
      <h1 className="newFilterHeaderTxt">Selection Preview</h1>
      {/* Header Selection Start */}
      <div className="newFilterHeaderSelectionWrapper">
        <NewFilterSelectionChip>
          <p className="newFilterHeaderSubTxt">Family Member : </p>
          <div className="newFilterHeaderChipLabel">
            <p className="newFilterHeaderSubTxt">
              {renderAmountOfFamilyMembersSelected(
                selectedFamilyMembers,
                familyMembers
              )}
            </p>
          </div>
          <CheckCircleIcon />
        </NewFilterSelectionChip>
        <NewFilterSelectionChip>
          <p className="newFilterHeaderSubTxt">Advisor : </p>
          <div className="newFilterHeaderChipLabel">
            <p className="newFilterHeaderSubTxt">
              {renderAmountOfAdvisorsSelected(selectedAdvisors, advisors)}
            </p>
          </div>
          <CheckCircleIcon />
        </NewFilterSelectionChip>
        <NewFilterSelectionChip>
          <p className="newFilterHeaderSubTxt">Executed By : </p>
          <div className="newFilterHeaderChipLabel">
            <p className="newFilterHeaderSubTxt">
              {renderAmountOfExecutorsSelected(selectedExecutors, executors)}
            </p>
          </div>
          <CheckCircleIcon />
        </NewFilterSelectionChip>
        <NewFilterSelectionChip>
          <p className="newFilterHeaderSubTxt">Currency : </p>
          <div className="newFilterHeaderChipLabel">
            <p className="newFilterHeaderSubTxt">Rupees</p>
          </div>
          <CheckCircleIcon />
        </NewFilterSelectionChip>
        <NewFilterSelectionChip>
          <p className="newFilterHeaderSubTxt">Period : </p>
          <div className="newFilterHeaderChipLabel">
            <p className="newFilterHeaderSubTxt">
              {selectedPeriod === null
                ? dateRange
                    .map((date) => moment(date).format("DD-MM-YYYY"))
                    ?.join(" ")
                : renderPeriod(selectedPeriod, dateRange)}
            </p>
          </div>
          <CheckCircleIcon />
        </NewFilterSelectionChip>
        <NewFilterSelectionChip>
          <p className="newFilterHeaderSubTxt">Denomination : </p>
          <div className="newFilterHeaderChipLabel">
            <p className="newFilterHeaderSubTxt">{selectedDenomination}</p>
          </div>
          <CheckCircleIcon />
        </NewFilterSelectionChip>
      </div>
      {/* Header Selection End */}
      {/* <IconButton
        aria-label="close"
        // onClick={onClose}
        style={{ position: "absolute", right: 8, top: 8 }}
      >
        <CloseIcon />
      </IconButton> */}
      {closeIcon}
    </DialogTitle>
  );
};
