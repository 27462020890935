import React, { useEffect, useState } from "react";
import PageContainer from "../PageContainer";
import Header from "../Components/Header";
import PrintTable from "../Components/Table";
import ApexNew_print from "../Components/ApexNew_print";
import chartData from "../../../charts/chartData";
import { ReactComponent as Question } from "../images/question.svg";
import PieChart from "../../../charts/PieChart";
import Print_PieChart from "../Components/Print_PieChart";
import TabCards from "../Components/TabCards";
import { roundNumber } from "../../../utils/common";
import { date } from "yup/lib/locale";
import { currencyFormatPrint as currencyFormat } from "../../../../hooks/constantFunction";
import {
  EquitySectorModel,
  ProductAllocationModel,
  assetAllocationModel,
  engagmentModel,
} from "../Components/utils/staticData";
import {
  objectFormatingWithTitleCOlWrapper,
  objectFormatingProductAllocationWithTitleCOl,
  objectFormatingProductWithTitleCOlWrapper,
  objectFormatingProductCommonChartWithTitleCOl,
} from "../Components/utils/common";
import HandlePageBreakChart from "../Components/HandlePageBreakChart";
import { colors_allocation } from "../../../charts/colors";

export default function EquitySectorAllocationPdf({
  newdata,
  denomination,
  pdfInfo,
  setChanges,
  currentData,
  fundsInTransit,
  headerComp,
}) {
  const getDenomination = localStorage.getItem("denomination");
  const [newPage, setNewPage] = useState([]);

  const colors = [...colors_allocation];
  const [colormapped, setColorMapped] = useState({});
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState();
  // let newdata = [
  //   {
  //     name: "HELD ",
  //     investedValue: data?.heldInvestment,
  //     currentPortfolioValue: data?.currentHeldAmount,
  //     percentageOfTotalPortfolio: data?.heldPercentage,
  //     unrealisedGainLoss: data?.currentHeldUnrealisedGainLoss,
  //     totalGainLoss: data?.currentHeldTotalGainLoss,
  //     xirr: data?.currentHeldXirr,
  //     absoluteTwrr: data?.currentHeldAbsoluteTwrr,
  //     annualizedTwrr: data?.currentHeldAnnualisedTwrr,
  //   },

  //   {
  //     name: "HELD AWAY",
  //     investedValue: data?.heldAwayInvestment,
  //     currentPortfolioValue: data?.currentHeldAwayAmount,
  //     percentageOfTotalPortfolio: data?.heldAwayPercentage,
  //     unrealisedGainLoss: data?.currentHeldAwayUnrealisedGainLoss,
  //     totalGainLoss: data?.currentHeldAwayTotalGainLoss,
  //     xirr: data?.currentHeldAwayXirr,
  //     absoluteTwrr: data?.currentHeldAwayAbsoluteTwrr,
  //     annualizedTwrr: data?.currentHeldAwayAnnualisedTwrr,
  //   },
  //   {
  //     name: "Total",
  //     investedValue: data?.totalCurrentInvestment,
  //     currentPortfolioValue: data?.totalCurrentAmount,
  //     percentageOfTotalPortfolio: data?.totalPercentage,
  //     unrealisedGainLoss: data?.totalCurrentUnrealisedGainLoss,
  //     totalGainLoss: data?.totalCurrentGainLoss,
  //     xirr: data?.totalCurrentXirr,
  //     absoluteTwrr: data?.totalCurrentAbsoluteTwrr,
  //     annualizedTwrr: data?.totalCurrentAnnualisedTwrr,
  //   },
  // ];

  const [data1, setData1] = useState([]);
  useEffect(() => {
    let assetPieData = [];
    newdata?.map(
      (data, i) => {
        if (data.type.toLowerCase() == "total") {
          colormapped[data?.type] = "#ffffff";
          data.portfolioValueAmountPercentage = data?.totalPortfolioPercentage;
          setTotal(data?.portfolioValueAmount);
          return;
        }
        colormapped[data?.type] = colors[i];
        data.color = colors[i];
        assetPieData.push({
          id: i,
          label: data?.type,
          value: roundNumber(data?.portfolioValueAmount),
          val: [],

          color: colors[i],
        });
      },
      [newdata]
    );
    setData1(assetPieData);
    setColorMapped({ ...colormapped });
    let a = objectFormatingProductWithTitleCOlWrapper(
      {
        data: [
          ...(newdata || []),
          // {
          //   portfolioValueAmount: newdata?.totalPortfolioAmount,
          //   percentageOfTotalPortfolio: newdata?.percentageOfTotalPortfolio,
          //   assetClass: "Total",
          // },
        ],
      },
      EquitySectorModel,
      [],
      "#ADC371",
      undefined,
      // totaRowData ? totaRowData : hideTotalRow ? undefined : total,
      undefined,
      {},
      denomination,
      "type",
      null,
      objectFormatingProductCommonChartWithTitleCOl
    );
    setNewPage(() => [a]);
    // setData1([
    //   {
    //     id: "Held",
    //     label: "Held",
    //     value: roundNumber(data?.heldPercentage),
    //     val: [
    //       roundNumber(data?.heldInvestment),
    //       roundNumber(data?.currentHeldAmount),
    //       roundNumber(data?.heldPercentage),
    //     ],

    //     color: "hsl(228, 70%, 50%)",
    //   },
    //   {
    //     id: "Held Away",
    //     label: "Held Away",
    //     value: roundNumber(data?.heldAwayPercentage),
    //     val: [
    //       roundNumber(data?.heldAwayInvestment),
    //       roundNumber(data?.currentHeldAwayAmount),
    //       roundNumber(data?.heldAwayPercentage),
    //     ],
    //     color: "#bdc5f2",
    //   },
    // ]);
    setLoading(false);
  }, [newdata]);

  return (
    <div>
      {data1?.length > 0 && (
        <HandlePageBreakChart
          customData={newPage}
          loading={loading}
          title={"Sector Allocation"}
          headermodel={EquitySectorModel}
          denomination={denomination}
          setChanges={setChanges}
          piedata={data1}
          customHeader={headerComp || null}
          total={total}
          pdfInfo={pdfInfo}
          colors={colors}
          colormapped={colormapped}
        />
      )}
    </div>
  );
}
