import { useState } from "react";
import { fetchClientByPartner } from "../../../api";

function useGetClientListByPartnerId() {
  const [loading, setLoading] = useState(false);

  const getClientListByPartnerId = async (partnerId, params) => {
    setLoading(true);
    try {
      let res = await fetchClientByPartner(partnerId, params);
      // console.log(res, "sghsjkg");
      setLoading(false);

      return res;
    } catch (error) {
      // console.log(error);
      setLoading(false);

      return error;
    }
  };

  return { getClientListByPartnerId, loading };
}

export default useGetClientListByPartnerId;
