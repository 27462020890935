const align = "center";

// dont want in two line then set nonTwoline --> true

export function customTableConfig(TableConfig) {
  const fieldsToSetTrue = [
    "advisor",
    "transactionDate",
    "issueOpenDate",
    "maturityDate",
    "assetType",
    "quantity",
    "rate",
    "transaction_date",
    "issue_open_date",
    "maturity_date",
    "asset_type",
    "scripName",
    "scrip_name",
    "transactionDate",
    "transaction_type",
    "pan",
    "xirr",
    "absoluteTwrr",
    "annualisedTwrr",
  ];

  let newMldHoldingTableConfig = TableConfig.map((config) => {
    const nonTwoLine = fieldsToSetTrue.includes(config.field);
    return { ...config, nonTwoLine };
  });

  return newMldHoldingTableConfig;
}

export const mldHoldingTableConfig = [
  {
    field: "schemeName",
    title: "Product Name",
    show: true,
    align: align,
  },
  { field: "memberName", title: "Member Name", show: true, align: align },
  {
    field: "isin",
    title: "ISIN No/Product Code",
    show: true,
    align: align,
  },
  { field: "presentValue", title: "Present Value", show: true, align: align },
  // {
  //   field: "investmentValue",
  //   title: "Investment Value",
  //   show: true,
  //   align: align,
  // },
  { field: "advisor", title: "Advisor", show: true, align: align },
  {
    field: "transactionDate",
    title: "Transaction date",
    show: true,
    align: align,
  },
  {
    field: "issueOpenDate",
    title: "Issue Open Date",
    show: true,
    align: align,
  },
  {
    field: "maturityDate",
    title: "Maturity Date",
    show: true,
    align: align,
  },
  {
    field: "transaction_type",
    title: "Transaction Type",
    show: true,
    align: align,
  },
  { field: "assetType", title: "Asset Type", show: true },
  {
    field: "productSubCategory",
    title: "Product Sub Category",
    show: true,
    align: align,
  },
  { field: "quantity", title: "Quantity", show: true },
  { field: "rate", title: "Rate (Actuals)", show: true, align: align },
  {
    field: "investmentValue",
    title: "Invested Amount",
    show: true,
    align: align,
  },
  {
    field: "couponRate",
    title: "Coupon rate",
    show: true,
    align: align,
  },
  {
    field: "yieldToMaturity",
    title: "Yield to Maturity",
    show: true,
    align: align,
  },
  {
    field: "callPutDate",
    title: "Call/Put Date",
    show: true,
    align: align,
  },
  {
    field: "yieldToCallPut",
    title: "Yield to call/put",
    show: true,
    align: align,
  },
  {
    field: "interestPaymentFrequency",
    title: "Interest Payment Frequency",
    show: true,
    align: align,
  },
  {
    field: "cumulativePayment",
    title: "Cumulative Payment",
    show: true,
    align: align,
  },
  {
    field: "accrualFrequency",
    title: "Accural Frequency",
    show: true,
    align: align,
  },
  {
    field: "accrualInterestPaid",
    title: "Accural Interest Paid",
    show: true,
    align: align,
  },
  {
    field: "maturityPayment",
    title: "Maturity Payment",
    show: true,
    align: align,
  },
  {
    field: "percentageOfTotalPortfolio",
    title: "% of Total Portfolio",
    show: true,
    align: align,
  },
  // {
  //   field: "realisedGainLoss",
  //   title: "Realized Gain/Loss",
  //   show: false,
  // },
  {
    field: "absoluteReturn",
    title: "Absolute Return",
    show: true,
  },

  {
    field: "unRealisedGainLoss",
    title: "Unrealized Gain/Loss",
    show: true,
  },
  {
    field: "interestIncome",
    title: "Total Interest Income",
    show: true,
  },
  { field: "totalGainLoss", title: "Total Gain/Loss", show: true },
  { field: "xirr", title: "XIRR (%)", show: true },
  {
    field: "absoluteTwrr",
    title: "Absolute TWRR (%)",
    show: true,
  },
  {
    field: "annualisedTwrr",
    title: "Annulised TWRR (%)",
    show: true,
  },
];

export const mldTransactionConfig = [
  { field: "scheme_name", title: "Product Name", show: true, align: align },
  { field: "isin", title: "ISIN/NO Product Code", show: true, align: align },
  { field: "advisor", title: "Advised by", show: true, align: align },
  { field: "executed_by", title: "Executed By", show: true, align: align },
  {
    field: "transaction_date",
    title: "Transaction date",
    show: true,
    align: align,
  },
  {
    field: "issue_open_date",
    title: "Issue Open Date",
    show: true,
    align: align,
  },
  {
    field: "maturity_date",
    title: "Maturity Date",
    show: true,
    align: align,
  },
  {
    field: "transaction_type",
    title: "Transaction Type",
    show: true,
    align: align,
    nonTwoLine: false,
  },
  { field: "asset_type", title: "Asset Type", show: true },
  {
    field: "product_sub_category",
    title: "Product Sub Category",
    show: true,
    align: align,
  },
  { field: "units", title: "Quantity", show: true },
  { field: "rate", title: "Rate (Actuals)", show: true, align: align },
  {
    field: "invested_amount",
    title: "Invested Amount",
    show: true,
    align: align,
  },
  {
    field: "coupon_rate",
    title: "Coupon rate",
    show: true,
    align: align,
  },
  {
    field: "yield_to_maturity",
    title: "Yield to Maturity",
    show: true,
    align: align,
  },
  {
    field: "call_put_date",
    title: "Call/Put Date",
    show: true,
    align: align,
  },
  {
    field: "yield_to_call_put",
    title: "Yield to call/put",
    show: true,
    align: align,
  },
  {
    field: "interest_payment_frequency",
    title: "Interest Payment Frequency",
    show: true,
    align: align,
  },
  {
    field: "cumulative_payment",
    title: "Cumulative Payment",
    show: true,
    align: align,
  },
  {
    field: "accrual_frequency",
    title: "Accural Frequency",
    show: true,
    align: align,
  },
  {
    field: "accrual_interest_paid",
    title: "Accural Interest Paid",
    show: true,
    align: align,
  },
  {
    field: "maturity_payment",
    title: "Maturity Payment",
    show: true,
    align: align,
  },
  {
    field: "cumulative_quantity",
    title: "Cumulative Quantity",
    show: true,
    align: align,
  },
];
