const align = "center";
const transactionTableConfig = [
  { field: "scheme_name", title: "Product Name", show: true, align: align },
  { field: "advisor", title: "Advised by", show: true, align: align },
  { field: "executed_by", title: "Executed By", show: true, align: align },
  { field: "isin", title: "Product Code", show: true, align: align },
  // { field: "folio", title: "Folio Number", show: true, align: align },
  {
    field: "transaction_date",
    title: "Transaction date",
    show: true,
    align: align,
  },
  {
    field: "issue_open_date",
    title: "Issue Open Date",
    show: true,
    align: align,
  },
  {
    field: "maturity_date",
    title: "Maturity Date",
    show: true,
    align: align,
  },
  {
    field: "transaction_type",
    title: "Transaction Type",
    show: true,
    align: align,
  },
  { field: "asset_type", title: "Asset Type", show: true },
  {
    field: "product_sub_category",
    title: "Product Sub Category",
    show: true,
    align: align,
  },
  { field: "units", title: "Quantity", show: true },
  { field: "rate", title: "Rate (Actuals)", show: true, align: align },
  {
    field: "amount",
    title: "Invested Amount",
    show: true,
    align: align,
  },
  {
    field: "coupon_rate",
    title: "Coupon rate",
    show: true,
    align: align,
  },
  {
    field: "interest_payment_frequency",
    title: "Interest Payment Frequency",
    show: true,
    align: align,
  },
  {
    field: "transaction_type_id",
    title: "Cumulative payment",
    show: true,
    align: align,
  },
  {
    field: "cumulative_quantity",
    title: "Cumulative Quantity",
    show: true,
    align: align,
  },
  // {
  //   field: "accural_frequency",
  //   title: "Accural Frequency",
  //   show: true,
  //   align: align,
  // },
  // {
  //   field: "accural_interest_paid",
  //   title: "Accural Interest Paid",
  //   show: true,
  // },
  // { field: "dividend_received", title: "Dividend Received", show: true },
  // { field: "maturity_payment", title: "Maturity Payment", show: true },
  // { field: "current_valuation", title: "Current Valuation", show: true },
];

const holdingTableConfig = [
  //   ...transactionTableConfig,
  { field: "schemeName", title: "Product Name", show: true, align: align },
  { field: "memberName", title: "Member Name", show: true, align: align },
  { field: "advisor", title: "Advisor", show: true, align: align },
  { field: "isin", title: "Product Code", show: true, align: align },
  { field: "folio", title: "Folio Number", show: true, align: align },
  {
    field: "transactionDate",
    title: "Transaction date",
    show: false,
    align: align,
  },
  {
    field: "issueOpenDate",
    title: "Issue Open Date",
    show: false,
    align: align,
  },
  {
    field: "maturityDate",
    title: "Maturity Date",
    show: false,
    align: align,
  },
  {
    field: "transactionType",
    title: "Transaction Type",
    show: false,
    align: align,
  },
  { field: "assetType", title: "Asset Type", show: false },
  {
    field: "productSubCategory",
    title: "Product Sub Category",
    show: false,
    align: align,
  },
  { field: "units", title: "Quantity", show: false },
  { field: "rate", title: "Rate (Actuals)", show: false, align: align },
  {
    field: "investmentValue",
    title: "Invested Amount",
    show: true,
    align: align,
  },

  {
    field: "couponRate",
    title: "Coupon rate",
    show: true,
    align: align,
  },
  {
    field: "interestPaymentFrequency",
    title: "Interest Payment Frequency",
    show: true,
    align: align,
  },

  {
    field: "transactionTypeId",
    title: "Cumulative payment",
    show: false,
    align: align,
  },
  {
    field: "accuralFrequency",
    title: "Accural Frequency",
    show: false,
    align: align,
  },
  {
    field: "accuralInterestPaid",
    title: "Accural Interest Paid",
    show: false,
  },
  { field: "dividendReceived", title: "Dividend Received", show: false },
  { field: "maturityPayment", title: "Maturity Payment", show: false },
  { field: "presentValue", title: "Current Valuation", show: true },
  {
    field: "percentageOfTotalPortfolio",
    title: "% of Total Portfolio",
    show: true,
    align: align,
  },
  { field: "unRealisedGainLoss", title: "Unrealized Gain/Loss", show: true },
  // { field: "realisedGainLoss", title: "Realized Gain/Loss", show: false },
  {
    field: "interestIncome",
    title: "Other Income",
    show: true,
  },
  { field: "totalGainLoss", title: "Total Gain/Loss", show: true },
  //   { field: "presentValue", title: "Present Value", show: true },
  //   { field: "totalGainLoss", title: "Total Gain", show: true },
  { field: "xirr", title: "XIRR (%)", show: true },
  { field: "absoluteTwrr", title: "Absolute TWRR (%)", show: true },
  { field: "annualisedTwrr", title: "Annualized TWRR (%)", show: true },
  //   {
  //     field: "avgHoldingPeriod",
  //     title: "Holding Period (In Years)",
  //     show: true,
  //   },
];

const trSTData = {
  success: true,
  event: "STRUCTURED_TRANSACTION_SUMMARY_FETCH",
  message: "structured transaction summary fetched successfully.",
  uuid: "b30a40f0-d3ad-11ed-9d2a-f13334b38043",
  data: {
    meta: {
      filters: {
        clientId: "199",
        assetClass: "equity,debt",
      },
    },
    result: [
      {
        id: 4,
        structured_head_id: 5,
        client_id: 199,
        member_id: 45,
        pan: "AAACY2068D",
        advisor: "edelweiss",
        isin: "INE516Y07444",
        scheme_master_id: 9,
        asset_type: "debt",
        product_sub_category: "corporate bonds",
        scheme_name: "Piramal Capital & Housing Finance, 6.75% 26sep2031, INR",
        transaction_type_id: 12,
        transaction_date: "2021-03-27",
        units: 80,
        nav: 120,
        amount: 9600,
        cashflow: 9600,
        coupon_rate: 8.25,
        interest_payment_frequency: "NA",
        issue_open_date: "2021-03-21",
        maturity_date: "2024-03-26",
        is_active: 1,
        is_deleted: 0,
        created_at: "2023-04-05T06:10:33.000Z",
        updated_at: "2023-04-05T06:10:33.000Z",
        transaction_type: "buy",
        holdingPeriod: 739,
      },
      {
        id: 2,
        structured_head_id: 1,
        client_id: 199,
        member_id: 45,
        pan: "AAACY2068D",
        advisor: "edelweiss",
        isin: "INE516Y07394",
        scheme_master_id: 3,
        asset_type: "debt",
        product_sub_category: "corporate bonds",
        scheme_name:
          "PIRAMAL CAPITAL & HOUSING FINANCE LIMITED II SR II 8.50 LOA 31JL23 FVRS10LAC",
        transaction_type_id: 12,
        transaction_date: "2021-03-27",
        units: 80,
        nav: 120,
        amount: 9600,
        cashflow: 9600,
        coupon_rate: 8.5,
        interest_payment_frequency: "NA",
        issue_open_date: "2021-03-21",
        maturity_date: "2024-03-26",
        is_active: 1,
        is_deleted: 0,
        created_at: "2023-04-04T11:03:55.000Z",
        updated_at: "2023-04-04T11:03:55.000Z",
        transaction_type: "buy",
        holdingPeriod: 739,
      },
      {
        id: 3,
        structured_head_id: 5,
        client_id: 199,
        member_id: 45,
        pan: "AAACY2068D",
        advisor: "neo",
        isin: "INE514F07083",
        scheme_master_id: 8,
        asset_type: "debt",
        product_sub_category: "corporate bonds",
        scheme_name: "Neelachal Ispat Nigam, 11.9% 27mar2024, INR",
        transaction_type_id: 12,
        transaction_date: "2020-09-21",
        units: 100,
        nav: 100,
        amount: 10000,
        cashflow: 10000,
        coupon_rate: 8.8,
        interest_payment_frequency: "Annually",
        issue_open_date: "2020-08-20",
        maturity_date: "2023-09-21",
        is_active: 1,
        is_deleted: 0,
        created_at: "2023-04-05T06:10:33.000Z",
        updated_at: "2023-04-05T06:10:33.000Z",
        transaction_type: "buy",
        holdingPeriod: 926,
      },
      {
        id: 1,
        structured_head_id: 1,
        client_id: 199,
        member_id: 45,
        pan: "AAACY2068D",
        advisor: "neo",
        isin: "INF200K01VH2",
        scheme_master_id: 1,
        asset_type: "debt",
        product_sub_category: "corporate bonds",
        scheme_name: "Infinity Mandate Approach",
        transaction_type_id: 12,
        transaction_date: "2020-09-21",
        units: 100,
        nav: 100,
        amount: 10000,
        cashflow: 10000,
        coupon_rate: 8.8,
        interest_payment_frequency: "Annually",
        issue_open_date: "2020-08-20",
        maturity_date: "2023-09-21",
        is_active: 1,
        is_deleted: 0,
        created_at: "2023-04-04T11:03:55.000Z",
        updated_at: "2023-04-04T11:03:55.000Z",
        transaction_type: "buy",
        holdingPeriod: 926,
      },
    ],
  },
};

module.exports = {
  transactionTableConfig,
  holdingTableConfig,
  trSTData,
};
