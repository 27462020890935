import React,{useEffect,useContext} from 'react'
import{Outlet,Navigate} from 'react-router-dom'
import { mfoFilterContext } from '../mfo_context/Mfo_Context'
export default function Aifpages() {
  const {setEntity}=useContext(mfoFilterContext)
        setEntity("aif")


   
  return <Outlet/>

}