import React, { useEffect, useState, useContext } from "react";
import PageFilter from "../common/PageFilter";
import TabPmsMain from "../common/TabPmsMain";
import PmsHolding from "./PmsHolding";
import PmsPortfolio from "./PmsPortfolio";
import PmsTransaction from "./PmsTransaction";
import { getTransactionsSummary } from "../../../../api";
import { OnboardingContext } from "../../../../Context";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import NewTabComp from "../common/NewTabComp";
import { routes } from "../../../../routes";
import { NewFilter } from "../../newFilter/NewFilter";
import { SHOW_ADDITIONAL_DATA_TAB } from "../../../../Config";
import PmsUnderlyingTransaction from "./PmsUnderlyingTransaction";

const pmsTransactionTableConfig = [
  {
    field: "scheme_name",
    title: "Product Name",
    show: true,
    align: "center",
  },
  { field: "advisor", title: "Advised by", show: true, align: "center" },
  {
    field: "executed_by",
    title: "Executed By",
    show: true,
    align: "center",
  },
  { field: "isin", title: "Product Code", show: true, align: "center" },
  { field: "folio_no", title: "Folio No", show: true, align: "center" },
  // { field: "asset_type", title: "Asset Type", show: true },
  {
    field: "product_sub_category",
    title: "Product Sub Category",
    show: true,
    align: "center",
  },
  {
    field: "transaction_date",
    title: "Transaction date",
    show: true,
    align: "center",
  },
  {
    field: "transaction_type",
    title: "Transaction Type",
    show: true,
    align: "center",
  },
  {
    field: "holdingPeriod",
    title: "Holding Period",
    show: true,
  },
  { field: "amount", title: "Amount", show: true },
  { field: "load_charges", title: "Load Charged", show: true },
  { field: "dividend", title: "Dividend", show: true },
  { field: "interest", title: "Interest", show: true },

  { field: "other_income", title: "Other Income", show: true },
  { field: "management_fees", title: "Management Fees", show: true },
  {
    field: "securities_transaction_tax",
    title: "Security Transaction Tax",
    show: true,
  },
  { field: "other_expense", title: "Other Expense", show: true },
];
let data = [];
if (SHOW_ADDITIONAL_DATA_TAB === "show") {
  data = [
    {
      page: "holding",
      title: "Holding Summary",
      path: routes.pmsSummaryPage_holding,
    },
    {
      page: "performance-summary",
      title: "Performance Summary",
      path: routes.pmsSummaryPage_performanceSummary,
    },
    {
      page: "transaction",
      title: "Transaction Summary",
      path: routes.pmsSummaryPage_transection,
    },
    {
      page: "underlying",
      title: "Underlying Transactions",
      path: routes.pmsSummaryPage_underlying_transaction,
    },
  ];
} else {
  data = [
    {
      page: "holding",
      title: "Holding Summary",
      path: routes.pmsSummaryPage_holding,
    },

    {
      page: "transaction",
      title: "Transaction Summary",
      path: routes.pmsSummaryPage_transection,
    },
    {
      page: "underlying",
      title: "Underlying Transactions",
      path: routes.pmsSummaryPage_underlying_transaction,
    },
  ];
}

function PmsReport({ pageName }) {
  const [page, setPage] = useState(pageName);
  const { userState } = OnboardingContext();
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const getTabelData = async (
    setTableColumns,
    tableName,
    params,
    setLoading,
    objectFormating1,
    setCheckedTrans,
    customTableConfig,
    limit,
    offset,
    setPageinationData,
    pagiNationData,
    numberOfInstrumentsCount,
    setPercentageOfTotalPortfolio,
    setNumberOfInstruments
  ) => {
    setLoading(true);
    // static params for testing purpose
    // params =
    //   page === "holding"e
    //     ? {
    //         clientId: 199,
    //         assetClass: params.assetClass,
    //       }
    //     : {
    //         clientId: 199,
    //         memberId: 44,
    //         startDate: "2021-04-10",
    //         endDate: "2022-10-09",
    //         assetClass: params.assetClass,
    //       };
    let filterParams = getfilter_as_params();
    params = {
      clientId: userState.loginedUserData.id,
      // userState.loginedUserData.id,
      // clientId: 199,
      limit: limit,
      offset: offset,
      advisor: filterParams.advisor,
      memberId: filterParams.memberId,
      // ...getfilter_as_params(),
      assetClass: params.assetClass,
      search: params?.search,
    };

    try {
      let response = await getTransactionsSummary("pms", tableName, params);
      // if (page !== "holding") {
      setPageinationData((prev) => {
        return { ...prev, pageCount: response?.data?.data?.meta?.count };
      });

      if (page == "holding") {
        if (numberOfInstrumentsCount?.current === 0) {
          setNumberOfInstruments(response?.data?.data?.meta?.count);
        }
        numberOfInstrumentsCount.current = numberOfInstrumentsCount.current + 1;
        setPercentageOfTotalPortfolio(
          response?.data?.data?.total?.percentageOfTotalPortfolio
        );
      }
      if (page == "underlying") {
        return response?.data?.data?.result;
      }
      let newlist = [];
      let transactionList = [];
      response?.data?.data?.result?.map((val) => {
        if (page === "holding") {
          let obj = objectFormating1(val);
          newlist.push(obj);
        } else {
          val.advisor = val?.advisor + "%" + val?.updated_at;
          let obj = objectFormating1(val);
          newlist.push(obj);
          if (
            val["transaction_type"] != null &&
            !transactionList
              .map((val) => val?.title)
              .includes(val["transaction_type"])
          ) {
            transactionList.push({
              title: val["transaction_type"],
              show: false,
            });
          }
        }
      });

      if (page !== "holding") {
        setCheckedTrans(transactionList);
      }
      setTableColumns(newlist);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const changePage = (p) => {
    switch (p) {
      case "holding":
        return (
          <PmsHolding
            getTabelData={getTabelData}
            typeOfProduct={"pms"}
          ></PmsHolding>
        );

      case "performance-summary":
        return (
          <PmsPortfolio
            mfoFilter={mfoFilter}
            getfilter_as_params={getfilter_as_params}
          />
        );

      case "transaction":
        return (
          <PmsTransaction
            getTabelData={getTabelData}
            customTableConfig={pmsTransactionTableConfig}
          />
        );
      case "underlying":
        return (
          <PmsUnderlyingTransaction
            getTabelData={getTabelData}
            client={userState.loginedUserData.id}
            customTableConfig={pmsTransactionTableConfig}
          />
        );

      default:
        return (
          <PmsHolding
            getTabelData={getTabelData}
            typeOfProduct={"pms"}
          ></PmsHolding>
        );
    }
  };

  // useEffect(() => {
  //   setPage(pageName);
  // }, [pageName]);
  const handleClick = React.useCallback(
    (page) => {
      setPage(page);
    },
    [page]
  );

  return (
    <>
      <PageFilter
        backLink={routes.pmsSummaryPage}
        setFilterModalOpen={setFilterModalOpen}
      ></PageFilter>
      <NewFilter
        filterModalOpen={filterModalOpen}
        setFilterModalOpen={setFilterModalOpen}
        productType="pms"
      />
      {/* <TabPmsMain pageName={pageName}></TabPmsMain> */}
      <div className="backBtnContainer" style={{ margin: "2%" }}>
        {/* <Back
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/home/pms/pms-summary");
          }}
        /> */}
        <NewTabComp
          data={data}
          customWidth={"100%"}
          customHandle={handleClick}
          customSelect={data.findIndex((val) => val?.page == page)}
        />
      </div>
      {changePage(page)}
    </>
  );
}

export default PmsReport;
