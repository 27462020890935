export const tokens = (mode) => {
  if (mode === "gold") {
    return {
      // PRIMARY COLORS
      secondary: "#222325", // contrast // cement
      primarySurface: "#121314", // main // carbon
      lightAccent: "#1A1B1D", // light // ash
      highLight: "#0C0C0D", // dark // coal

      // SECONDARY COLORS
      lightAccent: "#EDC150", // light

      gain: "#008000",
      successProfit: "#11A671",
      loss: "#FF0000",
      failedLoss: "#952829",
      // Theme Switcher
      themeSwitcher: { bodyBackGround: "#121314", headerBackGround: "#222325" },
      // NEW MENU
      newMenu: {
        leftSideBackground: "#222325",
        rightSideBackground: "#121314",
        rightSideProductHeading: "#ffffff",
        newMenuItemSelectedText: "#000000",
        newMenutItemTxtColor: "",
        leftSideMenuItemSelected: "#EDCC7E",
        leftSideMenuItemHover: "#EDCC7E",
        newMenuText: "",
        searchWrapperInput: "#222325",
        newMenuHeading: "",
        newMenuSubHeading: "",
        newMenuDashBoardItemHover: "#222325",
        unexpandedAccordionHover: "#222325",
      },
      memberListing: {
        headerBackGround: "#1a1b1d",
        leftBorder: "#EDC150",
        gridBodyBackGroundActive: "#121314",
        gridBodyBackGroundInActive: "#1a1b1d",
      },
      // HOLDING SUMMARY CARDS COLORS
      cards: {
        mainCard: "#222325",
        innerCard: "#111213",
        switchBorder: "#EDC150",
        switchBgInactive: "#000000",
        switchBgActive: "rgba(242, 247, 161, 0.3)",
        switchThumbActive: "#EDC150",
        dataPointModalBorder: "#EDC150",
        dataPointModalContainer: "#111213",
        infoIconContainer: "#EDC150",
        infoIconInnerCircle: "#FFE39C",
        infoIconText: "#000000",
        gain: "linear-gradient(272deg, #034338 0%, #1E2230 100%)",
        loss: "linear-gradient(270deg, #7C162F 0%, #1E2230 100%)",
        textColor: "#FFFFFF",
      },
      searchIcon: { background: "#EDC150", iconColor: "#000000" },
      rightArrowIcon: { background: "#000000" },
      popOverList: { containerBackGround: "#222325" },
      // NEW GLOBAL FILTER COLORS
      newFilter: {
        newFilterHeader: "#222325",
        newFilterBody: "#121314",
        newFilterFooter: "#121314",
        newFilterFooterBtn: "#EDC150",
        newFilterSearch: "#222325",
        newFilterFooterBtnTxt: "#000000",
        newFilterHeaderTxt: "",
        newFilterHeaderSubTxt: "",
        newFilterHeaderSelection: "",
        newFilterHeaderChipLabel: "",
        selectedMembersListViewHeading: "",
        selectedMemberName: "",
        memberChipContainer: "#222325",
        memberChipSelectedBorder: "#EDC150",
        memberChipSelected: "#222325",
        memberChipIcon: "",
        memberName: "",
        memberNameLevelLabel: "",
        advisorBtn: "",
        advisorBtnSelected: "",
        advisorCardContainerHeading: "",
        advisorCard: "#222325",
        advisorCardSubHeading: "",
        advisorCardHeading: "",
        offShoreBtn: "#222325",
        newFilterSubHeader: "#121314",
        newFilterSubHeaderSelectedMembers: "",
        assetClassCard: "#222325",
        assetClassCardTxt: "",
        currencyCard: "#222325",
        currencyCardTxt: "",
      },
      filterCalendar: { background: "#222325", border: "#EDC150" },
      selectDropDown: {
        background: "#000000",
        border: "#222325",
        optionBackground: "#222325",
      },
      bankBookCard: {
        mainCard: "#222325",
        innerCard: "#111213",
      },
      top5AmcIssuerTable: {
        background: "#222325",
      },
    };
    /* GOLD THEME COLOR TOKEN END */
  } else if (mode === "default") {
    return {
      // PRIMARY COLORS
      secondary: "#222325", // contrast // cement
      primarySurface: "#230E42", // main // carbon
      lightAccent: "#160F27", // light // ash
      highLight: "#2F1D5B", // dark // coal

      // SECONDARY COLORS
      lightAccent: "#EDC150", // light

      gain: "#008000",
      successProfit: "#11A671",
      loss: "#FF0000",
      failedLoss: "#952829",
      themeSwitcher: { bodyBackGround: "#2D1E54", headerBackGround: "#211534" },
      newMenu: {
        leftSideBackground: "#2a0944",
        rightSideBackground: "#0b0717",
        rightSideProductHeading: "#ffffff",
        newMenuItemSelectedText: "#FFFFFF",
        newMenutItemTxtColor: "#FFFFFF",
        leftSideMenuItemSelected: "#1d0630",
        leftSideMenuItemHover: "#1d0630",
        newMenuText: "#FFFFFF",
        searchWrapperInput: "#211839",
        newMenuHeading: "#FFFFFF",
        newMenuSubHeading: "#FFFFFF",
        newMenuDashBoardItemHover:
          "linear-gradient(45deg, #530e8c -30.67%, rgba(0, 0, 0, 0) 58.95%)",
        unexpandedAccordionHover: "#211839",
      },
      memberListing: {
        headerBackGround: "#211839",
        leftBorder: "#61199C",
        gridBodyBackGroundActive: "#2F1D5B",
        gridBodyBackGroundInActive: "#211839",
      },
      // HOLDING SUMMARY CARDS COLORS
      cards: {
        mainCard: "linear-gradient(146deg, #2B0D58 0%, #692FC0 100%)",
        innerCard: "linear-gradient(269deg, #211839 0%, #090418 83.33%)",
        switchBorder: "#7420F2",
        switchBgInactive: "#16082E",
        switchBgActive: "#5923AB",
        switchThumbActive: "#FFFFFF",
        dataPointModalBorder: "#711CF1",
        dataPointModalContainer:
          "linear-gradient(269deg, #211839 0%, #090418 83.33%)",
        infoIconContainer: "#6A2EFF",
        infoIconInnerCircle: "#381C78",
        infoIconText: "#ffffff",
        gain: "linear-gradient(272deg, #034338 0%, #1E2230 100%)",
        loss: "linear-gradient(270deg, #7C162F 0%, #1E2230 100%)",
        textColor: "#FFFFFF",
      },
      searchIcon: {
        background: "rgba(86, 38, 197, 1)",
        iconColor: "#ffffff",
      },
      rightArrowIcon: { background: "#9339DB" },
      popOverList: {
        containerBackGround:
          "linear-gradient(180deg, #220c44 0%, #120e1e 100%)",
      },
      // NEW GLOBAL FILTER COLORS
      newFilter: {
        newFilterHeader: "linear-gradient(180deg, #350958 0%, #160829 125.75%)",
        newFilterBody: "rgb(25 20 38)",
        newFilterFooter: "rgb(25 20 38)",
        newFilterFooterBtn: "#3555ff",
        newFilterSearch: "#211839",
        newFilterFooterBtnTxt: "#ffffff",
        newFilterHeaderTxt: "#ffffff",
        newFilterHeaderSubTxt: "#ffffff",
        newFilterHeaderSelection: "#0b0813",
        newFilterHeaderChipLabel: "#151320",
        selectedMembersListViewHeading: "#ffffff",
        selectedMemberName: "#ffffff",
        memberChipContainer: "#211839",
        memberChipSelectedBorder: "#00b78b",
        memberChipSelected: "#1e2230",
        memberChipIcon: "#c4c4c4",
        memberName: "#ffffff",
        memberNameLevelLabel: "#b1bef0",
        advisorBtn: "#ffffff",
        advisorBtnSelected: "#211839",
        advisorCardContainerHeading: "#ffffff",
        advisorCard: "#211839",
        advisorCardSubHeading: "#ffffff",
        advisorCardHeading: "#ffffff",
        offShoreBtn: "#211839",
        newFilterSubHeader: "rgb(25, 20, 38)",
        newFilterSubHeaderSelectedMembers: "#b1bef0",
        assetClassCard: "#211839",
        assetClassCardTxt: "#ffffff",
        currencyCard: "#211839",
        currencyCardTxt: "#ffffff",
      },
      filterCalendar: { background: "#130C28", border: "#711CF1" },
      selectDropDown: {
        background: "#1e0d2d",
        border: "#461277",
        optionBackground: "",
      },
      bankBookCard: {
        mainCard: "linear-gradient(127deg, #2B0D58 14.75%, #692FC0 88.7%)",
        innerCard:
          "linear-gradient(270deg, rgba(33, 24, 57, 0.5) -44.29%, rgba(9, 4, 24, 0.5) 75.8%)",
      },
      top5AmcIssuerTable: {
        background: "#20153E",
      },
    };
  } else {
    return {
      // PRIMARY COLORS
      secondary: "#222325", // contrast // cement
      primarySurface: "#230E42", // main // carbon
      lightAccent: "#160F27", // light // ash
      highLight: "#2F1D5B", // dark // coal

      // SECONDARY COLORS
      lightAccent: "#EDC150", // light

      gain: "#008000",
      successProfit: "#11A671",
      loss: "#FF0000",
      failedLoss: "#952829",
      themeSwitcher: { bodyBackGround: "#2D1E54", headerBackGround: "" },
      newMenu: {
        leftSideBackground: "#242B3E",
        rightSideBackground: "#FFFFFF",
        rightSideProductHeading: "#000000",
        newMenuItemSelectedText: "#FFFFFF",
        newMenutItemTxtColor: "#FFFFFF",
        leftSideMenuItemSelected: "#191E2C",
        leftSideMenuItemHover: "#191E2C",
        newMenuText: "#FFFFFF",
        searchWrapperInput: "#242B3E",
        newMenuHeading: "#FFFFFF",
        newMenuSubHeading: "#FFFFFF",
        newMenuDashBoardItemHover: "#242B3E",
        unexpandedAccordionHover: "#242B3E",
      },
      memberListing: {
        headerBackGround: "#E9EAF5",
        leftBorder: "",
        gridBodyBackGroundActive: "",
        gridBodyBackGroundInActive: "",
      },
      // HOLDING SUMMARY CARDS COLORS
      cards: {
        mainCard: "#353E56",
        innerCard: "#242B3E",
        switchBorder: "#7420F2",
        switchBgInactive: "#16082E",
        switchBgActive: "#5923AB",
        switchThumbActive: "#FFFFFF",
        dataPointModalBorder: "#711CF1",
        dataPointModalContainer:
          "linear-gradient(269deg, #211839 0%, #090418 83.33%)",
        infoIconContainer: "#6A2EFF",
        infoIconInnerCircle: "#381C78",
        infoIconText: "#ffffff",
        gain: "#e2f2e8",
        loss: "#f1e0e0",
        textColor: "#000000",
      },
      searchIcon: {
        background: "#000000",
        iconColor: "#FFFFFF",
      },
      rightArrowIcon: { background: "#000000" },
      popOverList: { containerBackGround: "#222325" },
      // NEW GLOBAL FILTER COLORS
      newFilter: {
        newFilterHeader: "#C8C9D5",
        newFilterBody: "#FFFFFF",
        newFilterFooter: "#FFFFFF",
        newFilterFooterBtn: "#3555ff",
        newFilterSearch: "#211839",
        newFilterFooterBtnTxt: "#ffffff",
        newFilterHeaderTxt: "#000000",
        newFilterHeaderSubTxt: "#ffffff",
        newFilterHeaderSelection: "#0b0813",
        newFilterHeaderChipLabel: "#151320",
        selectedMembersListViewHeading: "#000000",
        selectedMemberName: "#000000",
        memberChipContainer: "#211839",
        memberChipSelectedBorder: "#00b78b",
        memberChipSelected: "#1e2230",
        memberChipIcon: "#c4c4c4",
        memberName: "#ffffff",
        memberNameLevelLabel: "#b1bef0",
        advisorBtn: "#ffffff",
        advisorBtnSelected: "#211839",
        advisorCardContainerHeading: "#ffffff",
        advisorCard: "#211839",
        advisorCardSubHeading: "#ffffff",
        advisorCardHeading: "#ffffff",
        offShoreBtn: "#211839",
        newFilterSubHeader: "#FFFFFF",
        newFilterSubHeaderSelectedMembers: "#b1bef0",
        assetClassCard: "#211839",
        assetClassCardTxt: "#ffffff",
        currencyCard: "#211839",
        currencyCardTxt: "#ffffff",
      },
      filterCalendar: { background: "#130C28", border: "#711CF1" },
      selectDropDown: {
        background: "#1e0d2d",
        border: "#461277",
        optionBackground: "",
      },
      bankBookCard: { mainCard: "#353E56", innerCard: "#242B3E" },
      top5AmcIssuerTable: {
        background: "#222325",
      },
    };
  }
};
