import React from "react";
import {
  Card,
  CardContent,
  Typography, 
  styled,
  Grid,
  Paper
} from "@material-ui/core";
import useStyles from "../clientDashboard/Clientdashstyle";
import { ReactComponent as Portfoliocompuser } from "../../../assets/images/portfoliocompareuser.svg";
import { ReactComponent as Marketuparrow } from "../../../assets/clientdashboard/marketuparrow.svg";
import { ReactComponent as Marketdownarrow } from "../../../assets/clientdashboard/marketdownarrow.svg";
import { ReactComponent as Marketmeter } from "../../../assets/images/marketmeter.svg";

const Item = styled(Paper)(({ theme }) => ({
 
  textAlign: 'center',
}));

function CardnewDesign() {
  const classes = useStyles();

  return (
    <>
      <div>
        <Card
          className="bgcirclesportcomp " style={{ width: "30%", backgroundColor: "#4C1A97",margin: "40px 50px",
          borderRadius: "16px", padding: "20px 15px", minHeight: "450px", maxHeight: "500px",}}>
          <div className="fleft">
            <CardContent>
              <div >
                <Portfoliocompuser>
                </Portfoliocompuser>
                
              </div>
              
            </CardContent>
          </div>

          <div className={classes.rightportcomp}>
            <CardContent >
              <Typography
                className={classes.portcompname}   >
                Isha Ambani
                <br />
                <span className={classes.famheadportcomp}  >familyHead</span> 
              </Typography>
              <Typography>
                <span className={classes.totgainloss}  > Total Gain/Loss </span> 
                </Typography>
              <Typography>
                <span>
                  <Marketuparrow className="mrright5"></Marketuparrow>
                  <span className={classes.totgainlossamt}  > +1,50,000 (+56.66%) </span>
                  
                </span>
              </Typography>
              
            </CardContent>
          </div>
          <div>
            <div className={classes.leftedcasection}>
              <CardContent  className={classes.portcompblock}>
                <Typography className={classes.mrtop20}>
                  <span className={classes.equitylabel}  > Equity </span> 
                  </Typography>
                <Typography
                  className={classes.edcaamt}   >
                  72.45
                  
                </Typography>
                
                <Typography>
                  <span>
                    <Marketuparrow className="mrright5"></Marketuparrow>
                    <span className={classes.edcaglamtprofit}  > 3.10 (1.00%) </span>
                    
                  </span>
                </Typography>
                
              </CardContent>
              <CardContent >
                <Typography className={classes.mrtop20}>
                  <span className={classes.cashlabel}  > Cash </span> 
                  </Typography>
                <Typography
                  className={classes.edcaamt}   >
                  72.45
                  
                </Typography>
                
                <Typography>
                  <span>
                    <Marketuparrow className="mrright5"></Marketuparrow>
                    <span className={classes.edcaglamtprofit}  > 3.10 (1.00%) </span>
                    
                  </span>
                </Typography>
                
              </CardContent>
            </div>
            <div className={classes.rightedcasection}>
              <CardContent >
                <Typography className={classes.mrtop20}>
                  <span className={classes.debtlabel}  > Debt </span> 
                  </Typography>
                <Typography
                  className={classes.edcaamt}   >
                  72.45
                  
                </Typography>
                
                <Typography>
                  <span>
                    <Marketuparrow className="mrright5"></Marketuparrow>
                    <span className={classes.edcaglamtprofit}  > 3.10 (1.00%) </span>
                    
                  </span>
                </Typography>
                
              </CardContent>
              </div>
              <div>
              <CardContent >
                <Typography className={classes.mrtop20}>
                  <span className={classes.alternatelabel}  > Alternate  </span> 
                  </Typography>
                <Typography
                  className={classes.edcaamt}   >
                  72.45
                  
                </Typography>
                
                <Typography>
                  <span>
                    <Marketdownarrow className="mrright5"></Marketdownarrow>
                    <span className={classes.edcaglamtloss}  > 3.10 (1.00%) </span>
                    
                  </span>
                </Typography>
                
              </CardContent> 
            </div>
            
          </div>
          
        </Card>
      </div>
        
      <div>
        <Grid container spacing={0} className={classes.idcusection}>
          <Grid item xs={2}>
            <Item className={classes.idtitlebr}> Ideal </Item>
          </Grid>
          <Grid item xs={2}>
            <Item className={classes.metertitle}>
              <Marketmeter>
              </Marketmeter> 
                <br/>
                Conservative 
              </Item>
          </Grid>
          <Grid item xs={2}>
            <Item className={classes.metertitle}>
              <Marketmeter>
              </Marketmeter> 
                <br/>
                Moderate Conservative 
              </Item>
          </Grid>
          <Grid item xs={2}>
            <Item className={classes.metertitle}>
              <Marketmeter>
              </Marketmeter> 
                <br/>
                Moderate 
              </Item>
          </Grid>
          <Grid item xs={2}>
            <Item className={classes.metertitle}>
              <Marketmeter>
              </Marketmeter> 
                <br/>
                Moderate Aggressive 
              </Item>
          </Grid>
          <Grid item xs={2}>
            <Item className={classes.metertitlelastborder}>
              <Marketmeter>
              </Marketmeter> 
                <br/>
                Aggressive
            </Item>
          </Grid>
        </Grid>

        <Grid container spacing={0} className={classes.idcusection}>
          <Grid item xs={2}>
            <Item className={classes.cutitlebr}> Current </Item>
          </Grid>
          <Grid item xs={2}>
            <Item className={classes.metertitle}>
              <Marketmeter>
              </Marketmeter> 
                <br/>
                Conservative 
              </Item>
          </Grid>
          <Grid item xs={2}>
            <Item className={classes.metertitle}>
              <Marketmeter>
              </Marketmeter> 
                <br/>
                Moderate Conservative 
              </Item>
          </Grid>
          <Grid item xs={2}>
            <Item className={classes.metertitle}>
              <Marketmeter>
              </Marketmeter> 
                <br/>
                Moderate 
              </Item>
          </Grid>
          <Grid item xs={2}>
            <Item className={classes.metertitle}>
              <Marketmeter>
              </Marketmeter> 
                <br/>
                Moderate Aggressive 
              </Item>
          </Grid>
          <Grid item xs={2}>
            <Item className={classes.metertitlelastborder}>
              <Marketmeter>
              </Marketmeter> 
                <br/>
                Aggressive
            </Item>
          </Grid>
        </Grid>
      </div>
        
    </>
  );
  }

export default CardnewDesign;
