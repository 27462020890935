import React, { useContext, useEffect, useState } from "react";
import "./topPerformer.css";
import { OnboardingContext } from "../../../../Context";
import FirstSection from "./firstSection";
import PortfolioBarGraph from "../../common/PortfolioBarGraph";
import "./PortfolioOverview.css";

function PortFolioOverviewPage({ tableType, type }) {
  const [date, setDate] = useState(null);

  const handleData = (data) => {
    setDate(data);
  };
  return (
    <>
      <div className="sectionContainer">
        <div>
          <FirstSection onUpdateDate={handleData} />
        </div>
        <PortfolioBarGraph date={ date}  />
      </div>
    </>
  );
}

export default PortFolioOverviewPage;
