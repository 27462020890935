import moment from "moment";
import React, { useState, useContext, useEffect, useRef } from "react";
import { getTransactionsSummary } from "../../../../api";
import { OnboardingContext } from "../../../../Context";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import PmsReportTable from "../pms/PmsReportTable";
import { tableTypeHolding } from "./AifTable";
import { objectFormating } from "./objectFormating";
import { useClientSearch } from "../../../../hooks/useClientSearch";
import { ITEMS_LIMIT_FILES_PARTNER_LIST } from "../../../../Constants";
import { summarizeTableData } from "../../../utils/common";
import { useCurrentHoldingCardsApi } from "../../../../hooks/useCurrentHoldingCardsApi";

function AifHolding({ p, setOpenModal, search, openModal, offset, setOffset }) {
  const [tableColumns, setTableColumns] = useState([]);
  const [secondTableColumns, setSecondTableColumns] = useState([]);
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const { userState } = OnboardingContext();
  const [loading, setLoading] = useState(false);
  const [reset, setReset] = useState();
  const [tableHeader, setTableHeader] = useState(tableTypeHolding);
  const [checkedTrans, setCheckedTrans] = useState([]);
  // const { searchResults } = useClientSearch(search, tableColumns);
  const [pagiNationData, setPageinationData] = useState({
    pageCount: null,
    // limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
  });

  const numberOfInstrumentsCount = useRef(0);
  const [numberOfInstruments, setNumberOfInstruments] = useState(0);
  const [percentageOfTotalPortfolio, setPercentageOfTotalPortfolio] =
    useState(0);

  // const [offset, setOffset] = useState(0);
  const { holdingCardData, xirrCurrentHolding, twrrCurrentHolding } =
    useCurrentHoldingCardsApi(true, "aif");
  const getTableData = async () => {
    setLoading(true);
    let tableName = "summary/holding";

    let params = {
      clientId: userState?.loginedUserData?.id,
      // clientId: 199,
      search: search,
      ...getfilter_as_params(),
      assetClass: p || "equity",
      offset: offset,
      limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
    };

    try {
      let response = await getTransactionsSummary("aif", tableName, params);
      let newlist = [];
      let transactionList = [];
      setPageinationData((prev) => {
        return { ...prev, pageCount: response?.data?.data?.meta?.count };
      });

      //code for number of instruments and total portfolio

      if (numberOfInstrumentsCount.current === 0) {
        setNumberOfInstruments(response?.data?.data?.meta?.count);
      }
      numberOfInstrumentsCount.current = numberOfInstrumentsCount.current + 1;
      setPercentageOfTotalPortfolio(
        response?.data?.data?.total?.percentageOfTotalPortfolio
      );

      // console.log(response?.data?.data?.meta?.count, "alnsvnj");
      response?.data?.data?.result?.map((val) => {
        // if (pageName === "holding") {
        let obj = objectFormating(val, tableHeader, " Days");
        newlist.push(obj);
        // }
      });
      setCheckedTrans(transactionList);
      setTableColumns(newlist);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getTableData();
  }, [p, mfoFilter, offset, search]);

  useEffect(() => {
    numberOfInstrumentsCount.current = 0;
  }, [p, mfoFilter]);

  useEffect(() => {
    setSecondTableColumns(
      summarizeTableData(
        tableColumns,
        holdingCardData,
        xirrCurrentHolding,
        twrrCurrentHolding,
        numberOfInstruments,
        "",
        "",
        "",
        percentageOfTotalPortfolio
      )
    );
  }, [tableColumns, holdingCardData, xirrCurrentHolding, twrrCurrentHolding]);
  // console.log(pagiNationData,'jnkbjh');

  return (
    <div style={{ position: "relative" }}>
      <PmsReportTable
        setTableColumns={setTableColumns}
        tableColumns={tableColumns}
        setSecondTableColumns={setSecondTableColumns}
        tableColumns2={secondTableColumns}
        loading={loading}
        setOpenModal={setOpenModal}
        openModal={openModal}
        headerList={tableTypeHolding}
        tableHeader={tableHeader}
        setTableHeader={setTableHeader}
        stickyProductName={true}
        checkedTransList={checkedTrans}
        setReset={setReset}
        pageCount={pagiNationData?.pageCount}
        setItemOffset={setOffset}
        pageNumber={offset / ITEMS_LIMIT_FILES_PARTNER_LIST + 1 || 1}
      />
    </div>
  );
}

export default AifHolding;
