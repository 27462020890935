import React from "react";
import { LinearProgress, makeStyles, useTheme } from "@material-ui/core";
import {
  countWordsWithSpace,
  firstLetterCapitalize,
} from "../../../utils/strHelper";
import { currencyFormat } from "../../../../hooks/constantFunction";
import { roundNumber, roundNumberWithoutDecimal } from "../../../utils/common";

export const ProgressBarFile = ({ item }) => {
  const gradientPosition = item.weightage > 100 ? 100 : item.weightage;
  const theme = useTheme();
  const goldMode = theme.palette.mode === "gold";

  const classes = makeStyles({
    progressBarContainerStyle: {
      width: "100%",
      borderLeft: `5px solid ${goldMode ? "#EDC150" : "#6247AA"}`,
      position: "relative",
      marginTop: "28px",
      border: "0px",
      height: "65px !important",
      borderRadius: "8px",
      backgroundImage: `linear-gradient(to right, #3F2663 ${gradientPosition}%, #230E42 ${gradientPosition}%)`,
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: "#3F2663 !important",
      },
    },
    dataContainer: {
      display: "flex",
      position: "relative",
      marginTop: "-40px",
      paddingLeft: "25px",
      justifyContent: "space-between",
      paddingRight: "150px",
    },
    currentVal: {
      left: "40%",
      textWrap: "nowrap",
      position: "absolute",
      fontWeight: "800",
    },
    totalContainer: {
      background: "#230E42",
      width: "100%",
      border: "0px",
      borderLeft: `5px solid ${goldMode ? "#EDC150" : "#6247AA"}`,
      height: "65px !important",
      position: "relative",
      display: "flex",
      marginTop: "28px",
      borderRadius: "8px",
      alignItems: "center",
      justifyContent: "space-between",
    },
  })();
  const renderProduct = (text) => {
    return countWordsWithSpace(text) === false
      ? text.toLowerCase() != "bonds"
        ? text.toUpperCase()
        : firstLetterCapitalize(text)
      : firstLetterCapitalize(text);
  };

  return (
    <>
      {item.product !== "Total" ? (
        <div key={item.product}>
          <LinearProgress
            variant="determinate"
            value={item.weightage}
            className={classes.progressBarContainerStyle}
          />
          <div className={classes.dataContainer}>
            <p style={{ position: "relative", fontWeight: "800" }}>
              {renderProduct(item.product)}
            </p>
            <p className={classes.currentVal}>
              <span
                style={{
                  color: "#816f9c",
                  fontWeight: "800",
                  paddingRight: "35px",
                }}
              >
                Current Value:
              </span>
              {currencyFormat(item.currentValue)}
            </p>
            <p
              style={{
                // position: "absolute",
                // left: "1260px",
                fontWeight: "800",
              }}
            >
              {roundNumberWithoutDecimal(item.weightage)}%
            </p>
          </div>
        </div>
      ) : (
        <>
          <div className={classes.totalContainer}>
            <p style={{ paddingLeft: "20px", fontWeight: "800" }}>
              TOTAL CURRENT VALUE:
            </p>
            <p style={{ paddingRight: "150x", fontWeight: "800" }}>
              {" "}
              {currencyFormat(item.currentValue)}
            </p>
            <p style={{ paddingRight: "150px", fontWeight: "800" }}>
              {" "}
              {roundNumberWithoutDecimal(item.weightage)}%
            </p>
          </div>
        </>
      )}
    </>
  );
};
