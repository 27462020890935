import React from "react";
import PartnerHeading from "./PartnerHeading";
import DefaultSign from "./DefaultSign";
import {
  Container,
  Paper,
  Grid,
  TextField,
  FormControlLabel,
  InputLabel,
  Checkbox,
  Button,
  Typography,
  Link,
  IconButton,
  Box,
} from "@material-ui/core";
import "./NewSignUp.css";
import LoadingButton from "./common/LoadingButton";

export default function PartnerSuccess({
  handleNext,
  subData,
  noStep,
  handleBack,
  buttonTxt,
  hideBottomSec
}) {
  // const desc = {
  //   center: [
  //     {
  //       title: "Why Email Verification?",
  //       description:
  //         "Holdings are the contents of an investment portfolio held by an individual or an entity, such as a mutual fund or a pension fund. Portfolio holdings may encompass a wide  ",
  //     },
  //   ],
  // };
  const desc = {
    center: [
      {
        title: "",
        description: "",
      },
    ],
  };
  return (
    <>
      <DefaultSign left={7} right={5} desc={desc}>
        <div className="dFlex jtfyContCntr alignCntr h100">
          <Box
            className="dFlex jtfyContCntr alignCntr flexDirCol"
            sx={{ width: { xs: "auto", sm: "330px" }, gap: "41px" }}
          >
            <PartnerHeading
              handleBack={handleBack}
              noStep={noStep}
              mainHead="Successfull"
              subtitle={
                subData
                  ? subData
                  : "Your Email & Mobile Number has been verified. You will recieve a link to set your pin shorlty"
              }
            />
            <Box sx={{ width: "100%" }}>
              {/* <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                style={{ mt: "5px" }}
                onClick={handleNext}
              >
                {buttonTxt ? buttonTxt : "Done"}
              </Button> */}
              <LoadingButton
                buttonTitle={buttonTxt ? buttonTxt : "Done"}
                // type="submit"
                // showLoader={loading}
                customOnclick={handleNext}
              />
            </Box>
            {!hideBottomSec && (
              <div className="successPageWarningSec">
                <div className="warningSign">i</div>
                <Typography
                  className="generalFontFam varient12"
                  style={{ marginTop: "auto" }}
                >
                  You will be able to login only once your details have been
                  verified by us, you will receive an email for the same.
                </Typography>
              </div>
            )}
          </Box>
        </div>
      </DefaultSign>
    </>
  );
}
