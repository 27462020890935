import React from "react";
import SubCategoryAllocationPdf from "./subCategory";
import { Link } from "react-router-dom";

export default function SubCategoryContainer({
  newdata,
  pdfInfo,
  setChanges,
  denomination,
}) {
  const assetClass = ["equity", "debt", "alternate", "cash"];
  const breadcrumbs = [
    <Link
      key="1"
      color="#000000"
      className="printBreadsel fontFamilyAvBold f16"
    >
      Mutual Fund Sub-Category Allocation
    </Link>,
  ];
  return (
    <>
      {assetClass.map((a) => {
        let bread = [...breadcrumbs];
        bread.push(
          <Link
            underline="hover"
            key="2"
            className="pdfselectedTxt pdfCaps fontFamilyAvBold f16"
          >
            <span className="opc80"> Asset Class : </span>{" "}
            <span className="pdfselectedTxt pdfCaps">{a}</span>
          </Link>
        );
        return (
          <SubCategoryAllocationPdf
            newdata={newdata["mf-sub-category-allocation-" + a]}
            breadcrumbs={bread}
            pdfInfo={pdfInfo}
            setChanges={setChanges}
            denomination={denomination}
          />
        );
      })}
    </>
  );
}
