import React from "react";
import NewTabComp from "../../products/common/NewTabComp";
import { getIndex } from "../../products/common/util/newTabCommon";
import AddButton from "../../../common/AddButton";
import "./topNavWrapper.css";

function TopNavWrapper({
  handleClick,
  data,
  page,
  buttonTitle,
  addButtonCLick,
  rawtableData,
}) {
  return (
    <div style={{ marginBottom: "40px", position: "relative" }}>
      <NewTabComp
        customHandle={handleClick}
        data={data}
        customSelect={getIndex(data, page)}
      />
      {rawtableData?.length > 0 && (
        <div className="buttonContainer">
          <AddButton
            addBtnTitle={
              buttonTitle
                ? buttonTitle
                : `Create ${data[getIndex(data, page)]?.title}`
            }
            customBtnTxtClass={"capitalize btTxt"}
            handleIconClick={addButtonCLick}
          />
        </div>
      )}
    </div>
  );
}

export default TopNavWrapper;
