import React from "react";
import Default from "../components/dashboard/defaultPages/Default";
import InsuranceComp from "../components/dashboard/products/insurance/InsuranceComp";
function Insurance() {
  return (
    <>
      <Default>
        <InsuranceComp></InsuranceComp>
      </Default>
    </>
  );
}

export default Insurance;
