import React from "react";

function HashlinkSupport({ id }) {
  return (
    <>
      <div
        style={{
          height: "50px",
          width: "100%",
        }}
        id={id}
      ></div>
    </>
  );
}

export default HashlinkSupport;
