import React, { useCallback, useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Paper } from "@material-ui/core";
import { myDebounce } from "../../../hooks/constantFunction";

const useStyles = makeStyles((theme) => ({
  inputField: {
    borderColor: " #711CF1",
    borderRadius: "20px",
    width: "100%",
    "& .MuiOutlinedInput-root": {
      color: "red",
      borderRadius: "6px !important",
      border: "none !important",
      background: "#000 !important",
    },
  },
  icon: {
    fill: "#FAF9FF",
  },
  paper: {
    // borderRadius: "4px !important",
    // border: "1.164px solid #461277 !important",
    background: "var(--dropdown) !important",
    marginTop: "1rem !important",
  },
  listbox: {
    maxHeight: "172px !important",
    // 👉 NOTE :- 🟢 Responsible for Select Client DropDown Options background
    background: `${theme.palette.selectDropDown.optionBackground} !important`, //
    padding: "0px !important",
    borderRadius: "4px !important",
    border: `1.164px solid ${theme.palette.selectDropDown.border} !important`,
  },
  option: {
    borderBottom: "0.5px solid #6a77ab !important",
    padding: "1rem !important",
  },
}));

const CustomPaper = (props) => {
  return (
    <Paper
      style={{
        color: "#9488A7",
        background: "var(--dropdown)",
        border: "1px solid var(--border)",
        color: "#FFF",
        fontFamily: "Avenir",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 350,
        lineHeight: "22px",
      }}
      elevation={8}
      {...props}
    />
  );
};

function AsynchronousInput({
  data,
  getSearchData,
  errors,
  searchLoader,
  label,
  optionTitle,
  fieldName,
  objectFieldName,
  handleChange,
  formData,
  placeholder,
  defaultValue,
}) {
  const classes = useStyles();

  const handleInputChange = (value) => {
    //if (value) {
    getSearchData(value);
    //}
  };

  const myDebounceSearch = useCallback(myDebounce(handleInputChange), []);

  return (
    <Autocomplete
      // open={true}
      PaperComponent={CustomPaper}
      classes={{
        paper: classes.paper,
        listbox: classes.listbox,
        option: classes.option,
      }}
      options={data}
      getOptionLabel={(option) => {
        console.log(option?.[optionTitle]);
        return option?.[optionTitle];
      }}
      onChange={(e, newValue) =>
        handleChange(
          fieldName,
          newValue === null ? "" : newValue[objectFieldName],
          e
        )
      }
      onInputChange={(e) => myDebounceSearch(e?.target?.value)}
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            // style={{
            //   "& .MuiOutlinedInput-root.Mui-focused": {
            //     color: "red",
            //   },
            // }}
            // defaultValue={defaultValue}
            className={classes.inputField}
            // label={label}
            placeholder={placeholder || "Select Client"}
            name={fieldName}
            error={Boolean(errors?.[fieldName])}
            // helperText={errors?.[fieldName]}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {searchLoader ? (
                    <CircularProgress style={{ color: "#FFFFFF" }} size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        </>
      )}
    />
  );
}

export default AsynchronousInput;
