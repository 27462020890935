import {
  checkChangePercentage,
  roundNumber,
  amountWithSigns,
} from "../../../utils/common";
import { ReactComponent as Marketuparrow } from "../../../../assets/clientdashboard/marketuparrow.svg";
import { ReactComponent as Marketdownarrow } from "../../../../assets/clientdashboard/marketdownarrow.svg";
export function PricePercentageChange({ PriceDiff, change }) {
  return (
    <p className="portfolioBoxValueSmall">
      <span className={roundNumber(PriceDiff) >= 0 ? "up-green" : "down-red"}>
        {roundNumber(PriceDiff) >= 0 || !roundNumber(PriceDiff) ? (
          <Marketuparrow className="mrright5"></Marketuparrow>
        ) : (
          <Marketdownarrow className="mrright5"></Marketdownarrow>
        )}
        {roundNumber(PriceDiff)
          ? amountWithSigns(roundNumber(PriceDiff) || 0)
          : amountWithSigns(0)}
        <small
          style={{
            marginLeft: "6px",
            fontSize: "16px",
          }}
          className={roundNumber(change) >= 0 ? "up-green" : "down-red"}
        >
          {change
            ? checkChangePercentage(change || 0)
            : checkChangePercentage(0)}
        </small>
      </span>
    </p>
  );
}
