import React from "react";
import NewHeadAndDetailsComp from "./newHeadingAndDetailsComp/NewHeadAndDetailsComp";
import TableSummaryDetails from "./newSummaryDetailsTable/TableSummaryDetails";
import BarChartIcon from "@material-ui/icons/BarChart";
import { ReactComponent as BgCicularPieChart } from "../../../../../assets/holding-summary/bgCicularPieChart.svg";
import { Children } from "react";
import { Button, Grid } from "@material-ui/core";

function SummaryDetailsSectionWrapper({
  summaryDetailsSecConstData,
  cardData1,
  hideNavButtons,
  handleClick,
  peiChart,
  customColorAndProd,
  customProdAndKey,
  truncTitle,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        margin: "2%",
      }}
    >
      <div style={{ height: "100%", width: "55%" }}>
        <NewHeadAndDetailsComp
          title={summaryDetailsSecConstData?.title}
          content={summaryDetailsSecConstData?.content}
        />
        <TableSummaryDetails
          data={cardData1}
          headerData={summaryDetailsSecConstData?.tableHeader}
          hideNavButtons={hideNavButtons}
          customColorAndProd={customColorAndProd}
          customProdAndKey={customProdAndKey}
          truncTitle={truncTitle}
        />
        {handleClick && (
          <Grid container xs={12} sm={12} lg={2}>
            <Button
              variant="contained"
              className={"summaryDetailsBtn" + " portfolioBtn "}
              startIcon={<BarChartIcon />}
              style={{
                width: { xs: "100%", sm: "auto" },
              }}
              onClick={handleClick}
            >
              View Detailed Report
            </Button>
          </Grid>
        )}
      </div>
      <div style={{ width: "45%", position: "relative" }}>
        <BgCicularPieChart
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "10px",
            left: "7px",
          }}
        />
        {peiChart}
      </div>
    </div>
  );
}

export default SummaryDetailsSectionWrapper;
