import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
} from "@material-ui/core";
import _, { trim } from "lodash";
import { useNavigate } from "react-router-dom";
import { addSchemeMasterProduct } from "../../../hooks/validationFunctions";
import { updateScemeMaster } from "../../../api";
import { OnboardingContext } from "../../../Context";
import { useFormik } from "formik";
import { SCHEME_MASTERS_PRODUCT_FIELDS } from "../../../Constants";

const useStyles = makeStyles((theme) => ({
  root: {
    "& label.Mui-focused": {
      color: "transparent",
    },

    // "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": {
    //   border: "none",
    // },
    // "& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline": {
    //   border: "none",
    // },
  },
  formContainer: {
    marginTop: "15px",
    marginLeft: "45px",
    height: "78%",
    overflow: "scroll",
    overflowX: "hidden",
  },
  deleteIcon: {
    top: "-27px",
    position: "relative",
    left: "99%",
    cursor: "pointer",
    marginBottom: "-25px",
  },
  icon: {
    fill: "#FAF9FF",
  },
  headerText: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "18px",
    lineHeight: "30px",
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
  headerTextSmall: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "18px",
    alignItems: "center",
    textAlign: "center",
    color: "#9488A7",
  },

  formHeading: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "16px",
    lineHeight: "20px",
    marginTop: "15px",
    marginLeft: "10px",
    background: "linear-gradient(180deg, #AA6FFE 0%, #711CF1 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  inputField: {
    borderColor: " var( --border)",
    borderRadius: "6px",
    width: "100%",
    border: "1px solid var( --border)",
    borderRadius: "6px",
  },
  inputGrid: {
    marginTop: "15px",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
  },
  container: {
    textAlign: "center",
    marginTop: "10px",
    marginBottom: "10px",
  },
  formFieldsContainer: {
    padding: "15px",
    background: "rgb(11, 3, 24)",
    mixBlendMode: "normal",
    boxShadow: "rgb(61 19 125) 0px 0px 10px",
    borderRadius: "6px",
    marginLeft: "10px",
    width: "90%",
    marginTop: "15px",
    [theme.breakpoints.down("xs")]: {
      width: "75%",
    },
  },
  gridContainer: {
    marginLeft: "10px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
}));

function UpdateSingleEdit({
  id,
  master,
  isin,
  master_name,
  singleMasterProductData,
}) {
  const products_fields = SCHEME_MASTERS_PRODUCT_FIELDS[master];
  const navigate = useNavigate();
  const classes = useStyles();
  const [showLoder, setShowLoader] = useState(false);
  const { dispatch } = OnboardingContext();

  const updateProductData = async (values) => {
    if (values.interestPayoutDate) {
      values.interestPayoutDate = values.interestPayoutDate.replace(
        /,\s*$/,
        ""
      );
    }
    setShowLoader(true);
    try {
      const data = await updateScemeMaster(id, isin, master, values);
      const updateDtata = data.data;
      setShowLoader(false);
      if (updateDtata.success) {
        dispatch({
          type: "UPDATE_SNACKBAR_DATA",
          payload: {
            success: true,
            error: false,
            showMsg: true,
            message: updateDtata.message,
          },
        });

        navigate("/masters/products", { state: { master, master_name } });
      }
    } catch (error) {
      if (error.response.request.status === 401) {
        navigate("/logout");
      } else if (!error.response.data.error.success) {
        dispatch({
          type: "UPDATE_SNACKBAR_DATA",
          payload: {
            success: false,
            error: true,
            showMsg: true,
            message: error.response.data.error.message,
          },
        });
      }
    }
  };

  const formik = useFormik({
    initialValues: singleMasterProductData,
    enableReinitialize: true,
    validate: addSchemeMasterProduct,
    onSubmit: (values) => {
      updateProductData(values);
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="forms">
        <Grid
          container
          className={classes.gridContainer + " mastergrid"}
          spacing={0}
        >
          {products_fields?.map((pf) => (
            <>
              {pf["type"] === "select" && (
                <Grid key={pf.name} className={classes.inputGrid} item xs={6}>
                  <div style={{ width: "100%" }}>
                    <div className="rightFields">
                      <span className="label subtext">{pf.label}</span>
                      <FormControl
                        variant="outlined"
                        className={`${classes.formControl} ${classes.inputField}`}
                      >
                        {/* <InputLabel id="demo-simple-select-outlined-label">
                      {pf.label}
                    </InputLabel> */}
                        <Select
                          native
                          style={{ color: "#FAF9FF", border: "none!important" }}
                          labelId="demo-simple-select-outlined-label"
                          value={formik.values[pf.name]}
                          onChange={formik.handleChange}
                          error={
                            formik.touched[pf.name] &&
                            Boolean(formik.errors[pf.name])
                          }
                          // label={pf.label}
                          inputProps={{
                            name: pf.name,
                            id: "outlined-Investment-Option-native-simple",
                            classes: {
                              icon: classes.icon,
                            },
                          }}
                        >
                          {pf.values.map((entity) => {
                            return (
                              <option
                                className="optiondesign"
                                key={entity.value}
                                value={entity.value}
                              >
                                {entity.title}
                              </option>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </Grid>
              )}

              {pf["type"] === "date" && (
                <Grid className={classes.inputGrid} item xs={6}>
                  <div style={{ width: "100%" }}>
                    <div className="rightFields">
                      <span className="label subtext">{pf.label}</span>
                      <TextField
                        variant="outlined"
                        // label={pf.label}
                        classes={{
                          root: classes.root,
                        }}
                        name={pf.name}
                        value={formik.values[pf.name]}
                        onChange={formik.handleChange}
                        error={
                          formik.touched[pf.name] &&
                          Boolean(formik.errors[pf.name])
                        }
                        type="date"
                        style={{ svg: "#FFFFFF" }}
                        className={`${classes.textField} ${classes.inputField}`}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>
                </Grid>
              )}
              {pf["type"] === "text" && (
                <Grid key={pf.name} className={classes.inputGrid} item xs={6}>
                  <div style={{ width: "100%" }}>
                    <div className="rightFields">
                      <span className="label subtext">{pf.label}</span>
                      <TextField
                        className={classes.inputField}
                        autoComplete="off"
                        // label={pf.label}
                        classes={{
                          root: classes.root,
                        }}
                        variant="outlined"
                        name={pf.name}
                        value={formik.values[pf.name]}
                        onChange={formik.handleChange}
                        error={
                          formik.touched[pf.name] &&
                          Boolean(formik.errors[pf.name])
                        }
                      />
                    </div>
                  </div>
                </Grid>
              )}
              {pf["type"] === "number" && (
                <Grid key={pf.name} className={classes.inputGrid} item xs={6}>
                  <div style={{ width: "100%" }}>
                    <div className="rightFields">
                      <span className="label subtext">{pf.label}</span>
                      <TextField
                        className={classes.inputField}
                        classes={{
                          root: classes.root,
                        }}
                        autoComplete="off"
                        // label={pf.label}
                        type="number"
                        variant="outlined"
                        name={pf.name}
                        value={formik.values[pf.name]}
                        onChange={formik.handleChange}
                        error={
                          formik.touched[pf.name] &&
                          Boolean(formik.errors[pf.name])
                        }
                      />
                    </div>
                  </div>
                </Grid>
              )}
            </>
          ))}
          {id !== 0 && master !== "equity" && (
            <Grid className={classes.inputGrid} item xs={6}>
              <div style={{ width: "100%" }}>
                <div className="rightFields">
                  <span className="label subtext">Status</span>
                  <FormControl
                    variant="outlined"
                    className={`${classes.formControl} ${classes.inputField}`}
                  >
                    {/* <InputLabel id="demo-simple-select-outlined-label">
                  Status
                </InputLabel> */}
                    <Select
                      native
                      classes={{
                        root: classes.root,
                      }}
                      style={{ color: "#FAF9FF", border: "none!important" }}
                      labelId="demo-simple-select-outlined-label"
                      value={formik.values.status}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.status && Boolean(formik.errors.status)
                      }
                      // label="Status"
                      inputProps={{
                        name: "status",
                        id: "outlined-status-native-simple",
                        classes: {
                          icon: classes.icon,
                        },
                      }}
                    >
                      <option className="optiondesign" value="active">
                        Active
                      </option>
                      <option className="optiondesign" value="inactive">
                        Inactive
                      </option>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </Grid>
          )}
        </Grid>

        <div className={classes.container}>
          <Button
            type="submit"
            style={{
              height: "35px",
              width: "40%",

              // background: "linear-gradient(180deg, #AA6FFE 0%, #711CF1 100%)",

              marginTop: "30px",

              borderRadius: "6px",
            }}
            variant="contained"
            className={classes.button + " portfolioBtn"}
            endIcon={
              showLoder && <CircularProgress style={{ color: "#0b0318" }} />
            }
          >
            <span
              style={{
                textTransform: "none",
                fontFamily: "Avenir",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "17px",
                lineHeight: "22px",
                padding: "11.5px",
                letterSpacing: "0px",
              }}
            >
              Save
            </span>
          </Button>
        </div>
      </form>
    </>
  );
}

export default UpdateSingleEdit;
