import React from "react";
import TaxModule from "./TaxModule";
import {
  objectFormatingWithGainlossTitleCOl,
  objectFormatingWithIncomeTitleCOl,
} from "../Components/utils/common";
import { PrinciplePaybackIncomeModel } from "../Components/utils/staticData";

export default function IncomeSort({
  newdata,
  denomination,
  pdfInfo,
  header,
  headers,
  header2,
  headers2,
  model,
  type,
  title,
  infoText,
  setChanges,
}) {
  let headers1 = headers;
  function addOnTotal(product) {
    if (type == "unrealised") {
      return "Total - " + product.replace(/Statement Of/, "");
    } else {
      return undefined;
    }
  }
  function addOnGrandTotal(member, t) {
    return `Grand - Total (${member})`;
  }

  function pdfSort() {
    const breadcrumbs = [
      <a key="1" color="#000000" className="printBreadsel fAvenirBold">
        {title || "Tax Summary"}
      </a>,
    ];

    let asset =
      newdata && Object.keys(newdata?.["asset-wise-product-sort-order"] || {});
    let arr = [];

    if (newdata?.["income-sort-order"]) {
      console.log("prod ssd");
      newdata?.["member-wise-modules-data"].memberWiseData?.map((member) => {
        // let list = member?.data && Object.keys(member?.data);
        let count = 0;
        let filtered = newdata?.["income-sort-order"].filter((prod) => {
          if (
            member?.data?.[prod] &&
            member?.data?.[prod]?.transactions?.length > 0
          ) {
            return true;
          }
        });
        let filterlen =
          filtered[filtered.length - 1] == "Principal Payback Summary"
            ? filtered.length - 1
            : filtered.length;
        // console.log(filtered, "filtered");
        // if (member.memberName == "Divita Juneja") {
        //   console.log(list, "pdfinfo");
        // }

        filtered?.map((product) => {
          let prodarr = member?.data?.[product]?.transactions;
          let total = member?.data?.[product]?.total;
          let bread = [...breadcrumbs];
          let dataModel = model;
          if (product == "Principal Payback Summary") {
            dataModel = PrinciplePaybackIncomeModel;
            headers1 = headers2;
            // headers1[0] = headers[0];
            // headers1.splice(6, 0, {
            //   title: "Current FV ",
            //   field: "currentFaceValue",
            //   align: "right",
            // });
            // console.log(dataModel, "data model");
          } else {
            headers1 = headers;
            dataModel = model;
          }
          bread.push(
            <a
              underline="hover"
              key="2"
              color="
                  #000000"
            >
              {/* <span className="opc80">Product :</span> */}
              <span className="pdfselectedTxt">{product}</span>
            </a>
          );
          prodarr &&
            (prodarr[prodarr.length - 1]?.instrumentName != "Total" ||
              prodarr[prodarr.length - 2]?.instrumentName != "Total") &&
            prodarr.push({
              schemeName: "Total",
              totalAmount: total,

              colspan: 6,
              // realisedGainLoss: total.productTotalRealisedGainLoss,
              addOn: addOnTotal(product),
              // ltcg: total.productTotalLtcg,
              // stcg: total.productTotalStcg,
            });
          if (prodarr) {
            count = count + 1;

            if (count == filterlen) {
              prodarr.push({
                saleDate: "--",
                quantity: "--",
                saleRate: "--",
                saleAmount: "--",
                purchaseDate: "--",
                purchaseRate: "--",
                purchaseAmount: "--",
                holdingPeriod: "--",

                // NoOfTransection:
                //   member?.data?.overview.overAllMemberNoOfTransactions,
                // instrumentName: "Grand Total",
                // class: "styledTotal",
                // type: "gainloss",
                schemeName: "Grand Total",
                colspan: 6,
                // isin: "",
                totalAmount: member?.memberTotalOtherIncome,
                addOn: addOnGrandTotal(
                  filtered
                    .slice(0, filterlen)
                    .join(" + ")
                    .replace(/Statement Of/g, "")
                ),

                // ltcg: member?.data?.overview.overAllMemberTotalLtcg,
                // stcg: member?.data?.overview.overAllMemberTotalStcg,
              });
            }
          }

          arr.push(
            <TaxModule
              objFormatModel={objectFormatingWithIncomeTitleCOl}
              infoText={infoText}
              model={dataModel}
              headers={headers1}
              header2={header2}
              perPage={6}
              setChanges={setChanges}
              breadcrumbs={bread}
              memberName={member.memberName}
              denomination={denomination}
              newdata={prodarr}
              subtext={""}
              product={product}
              pdfInfo={pdfInfo}
            />
          );
        });
      });
    }
    return arr;
  }

  return <div className="gainlossReport">{newdata && pdfSort(newdata)}</div>;
}
