// export const newFilterMainTab = [
//   {
//     id: "familyMember",
//     page: "Family Member",
//     title: "Family Member",
//   },
//   {
//     id: "advisor",
//     page: "Advisor",
//     title: "Advisor",
//   },
//   {
//     id: "executedBy",
//     page: "Executed By",
//     title: "Executed By",
//   },
//   {
//     id: "assetClass",
//     page: "Asset Class",
//     title: "Asset Class",
//   },

//   // {
//   //   page: "OffShore",
//   //   title: "OffShore",
//   // },
//   {
//     id: "currency",
//     page: "Currency",
//     title: "Currency",
//   },
//   {
//     id: "denomination",
//     page: "Denomination",
//     title: "Denomination",
//   },
//   {
//     id: "period",
//     page: "Period",
//     title: "Period",
//   },
// ];
export function newFilterMainTab(productType) {
  const newFilterMainTab = [
    {
      id: "familyMember",
      page: "Family Member",
      title: "Family Member",
    },
    {
      id: "advisor",
      page: "Advisor",
      title: "Advisor",
    },
    {
      id: "executedBy",
      page: "Executed By",
      title: "Executed By",
    },
    {
      id: "assetClass",
      page: "Asset Class",
      title: "Asset Class",
    },
    {
      id: "currency",
      page: "Currency",
      title: "Currency",
    },
    {
      id: "promoterStake",
      page: "Promoter Stake",
      title: "Promoter Stake",
    },
    {
      id: "denomination",
      page: "Denomination",
      title: "Denomination",
    },
    {
      id: "period",
      page: "Period",
      title: "Period",
    },
  ];

  // Filter out the 'Asset Class' tab if productType is 'dashboard'
  if (productType === "dashboard") {
    return newFilterMainTab.filter((tab) => tab.id !== "assetClass");
  }

  // Return all tabs if productType is not 'dashboard'
  return newFilterMainTab;
}

export const newFilterSubTab = [
  {
    id: "head_of_family",
    page: "head_of_family",
    title: "Head Of Family",
  },
  {
    id: "individual",
    page: "individual",
    title: "Individuals",
  },
  {
    id: "non_individual",
    page: "non_individual",
    title: "Non Individuals",
  },
];

export const durations = [
  "Previous Month",
  "Previous Quarter",
  "MTD",
  "ITD",
  "QTD",
  "YTD",
];
// "1 Month",
// "3 Months",
// "1 Year",
// "2 Years",
// "3 Years",
export const denominations = ["In Lakhs", "In Crores", "Actuals", "Default"];
export const denominationOptions = [
  { name: "In Lakhs", value: "Lakhs" },
  { name: "In Crores", value: "Crores" },
  { name: "Actuals", value: "Actuals" },
];
export const TaxdenominationOptions = [
  { name: "In Lakhs", value: "lakhs" },
  { name: "In Crores", value: "crores" },
  { name: "Actuals", value: "Actuals" },
];
export const taxFormat = [
  { name: "Asset Class Wise", value: "assetWise" },
  { name: "Product Wise", value: "productWise" },
];
