import React from "react";
import { divergingCommonProps } from "../../../charts/nivo_objects";
import Header from "../Components/Header";
import CardsWrapper from "../Components/cardsWrapper";
import Hr from "../Components/Hr";
import Bar from "../Components/barGraph";
import PageContainer from "../PageContainer";
import PrintTable from "../Components/Table";
import { StackBarChart } from "../Components/StackBarChart";
import { StackTable } from "../Components/StackTable";

export default function Disclaimer({ pdfInfo }) {
  let bartheme = {
    background: "transparent",
    textColor: "#333333",
    fontSize: 11,
    axis: {
      domain: {
        line: {
          stroke: "#fff",
          strokeWidth: 2,
        },
      },
      legend: {
        text: {
          fontSize: 12,
          fill: "#F8F9FB",
        },
      },
      ticks: {
        line: {
          stroke: "#1f1c40",
          strokeWidth: 1,
        },
        text: {
          fontSize: 11,
          fill: "#000000",
        },
      },
    },
    grid: {
      line: {
        stroke: "#1f1c40",
        strokeWidth: 1,
      },
    },
    legends: {
      title: {
        text: {
          fontSize: 11,
          fill: "#ebe8ee",
        },
      },
      text: {
        fontSize: 11,
        fill: "#000000",
      },
      ticks: {
        line: {},
        text: {
          fontSize: 10,
          fill: "#333333",
        },
      },
    },
    annotations: {
      text: {
        fontSize: 13,
        fill: "#333333",
        outlineWidth: 2,
        outlineColor: "#3c9cfa",
        outlineOpacity: 0.7,
      },
      link: {
        stroke: "#000000",
        strokeWidth: 1,
        outlineWidth: 2,
        outlineColor: "#ffffff",
        outlineOpacity: 1,
      },
      outline: {
        stroke: "#000000",
        strokeWidth: 2,
        outlineWidth: 2,
        outlineColor: "#ffffff",
        outlineOpacity: 1,
      },
      symbol: {
        fill: "#000000",
        outlineWidth: 2,
        outlineColor: "#ffffff",
        outlineOpacity: 1,
      },
    },
    tooltip: {
      container: {
        background: "#ffffff",
        color: "#333333",
        fontSize: 12,
      },
      basic: {},
      chip: {},
      table: {},
      tableCell: {},
      tableCellValue: {},
    },
  };
  bartheme.axis.domain.line.stroke = "#ECECEC";
  bartheme.axis.domain.line.strokeWidth = "1";
  let diverging = {
    ...divergingCommonProps,
    enableGridY: false,
  };
  return (
    <div>
      <PageContainer
        layout_t={1}
        pageNo={1}
        bgColor={"#ffffff"}
        pdfInfo={pdfInfo}
        headerText={" "}
      >
        {/* <Header title={"Overall Portfolio View"} type={1} subtext={""}></Header> */}

        {/* <Hr title={"Portfolio Performance"} /> */}
        {/* <div className="h_card" style={{ height: "448px" }}> */}
        {/* <Bar
            config={{ theme: bartheme }}
            commonprops={{
              enableGridY: false,
              axisLeft: null,
              colors: ["#97B2D5", "#00838F", "#B2C77A"],
            }}
          /> */}
        {/* </div> */}

        <div
          style={{
            height: "3.8in",
            paddingTop: "65px",
            paddingBottom: "100px",
          }}
        >
          <p
            className="gloabalFont title2 fz-24"
            style={{ paddingBottom: "25px", fontWeight: "800" }}
          >
            Disclaimers
          </p>
          <p style={{ paddingBottom: "25px" }}>
            Disclaimer for Neo Wealth Partners Private Limited.
          </p>
          {/* <hr className="hrLine" style={{ background: "#4E5DE3" }} /> */}
          <p className="" style={{ fontWeight: "800", paddingBottom: "25px" }}>
            Data and Privacy:
          </p>
          <p style={{ paddingBottom: "25px" }}>
            Neo Wealth Partners Private Limited are committed to protecting the
            privacy and confidentiality of our clients' information. Any
            personal or financial information collected is used strictly for the
            purpose of providing our services and in accordance with applicable
            data protection laws.
          </p>
          <p style={{ fontWeight: "800", paddingBottom: "25px" }}>
            Disclaimer:
          </p>
          <p style={{ paddingBottom: "25px" }}>
            The advice provided by Neo Wealth Partners Private Limited is based
            on our analysis and interpretation of market conditions and
            individual client profiles. We do not guarantee the accuracy or
            completeness of information provided and are not responsible for any
            losses arising from investment decisions made based on such
            information. Clients are encouraged to consider their specific
            financial situations and consult with independent advisors.
          </p>
          <p style={{ fontWeight: "800", paddingBottom: "25px" }}>
            Conflict of Interest:
          </p>
          <p style={{ paddingBottom: "25px" }}>
            Neo Wealth Partners Private Limited maintain a stringent policy to
            manage and disclose any potential conflicts of interest that may
            arise in the provision of our services.
          </p>

          <p style={{ fontWeight: "800", paddingBottom: "25px" }}>
            Limitation of Liability:
          </p>
          <p style={{ paddingBottom: "25px" }}>
            Neither Neo Wealth Partners Private Limited, nor their directors,
            employees, or affiliates will be liable for any direct, indirect,
            incidental, or consequential damages arising from the use of or
            reliance on information provided.{" "}
          </p>
          <p style={{ fontWeight: "800", paddingBottom: "25px" }}>
            Intellectual Property:
          </p>
          <p style={{ paddingBottom: "25px" }}>
            All content, including text, graphics, logos, and images, are the
            intellectual property of Neo Wealth Management Private Limited.
            Unauthorized use or distribution of this material is strictly
            prohibited.
          </p>
          <p style={{ fontWeight: "800", paddingBottom: "25px" }}>
            Jurisdiction and Governing Law:
          </p>
          <p style={{ paddingBottom: "25px" }}>
            This disclaimer and the services provided by Neo Wealth Partners
            Private Limited are governed by the laws of India, and anydisputes
            arising in relation to these services, or the disclaimer shall be
            subject to the exclusive jurisdiction of the courts in Mumbai,
            India.
          </p>
          {/* <div className="disclaimerPageBottomDiv">
            
          <p className="para">Currency</p>
          <p className="para">Hybrid/Others</p>
          <p className="para">Commodities</p>
          <p className="para">Insurance</p>
      
       
          <p className="para">Future</p>
          <p className="para">Future</p>
          <p className="para">Future</p>
          <p className="para">Future</p>
        
        
       
          <p className="para">OTC Contracts</p>
          <p className="para">OTC Contracts</p>
          <p className="para">OTC Contracts</p>
          <p className="para">OTC Contracts</p>
        
       
          <p className="para"></p>
          <p className="para">Future</p>
          <p className="para">Future</p>
          <p className="para">Future</p>
      
        </div> */}
        </div>
      </PageContainer>
    </div>
  );
}
