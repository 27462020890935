import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import React from "react";
import { ReactComponent as AsistentJourney } from "../../assets/icons/asistentJourney.svg";
import { ReactComponent as ClosePlane } from "../../assets/icons/ClosePlane.svg";

function NewDialaugeComp({
  open,
  handleClose,
  children,
  width,
  fullWidth,
  maxH,
  customPaperStyle,
  handleAccidentalClose,
}) {
  return (
    <Dialog
      fullWidth={fullWidth !== undefined ? fullWidth : true}
      maxWidth={width}
      open={open}
      onClose={handleAccidentalClose}
      //   aria-labelledby="max-width-dialog-title"
      //   PaperComponent={{}}
      PaperProps={{
        style: {
          overflowY: "hidden",
          overflowX: "hidden",
          border: "none",
          background: "black",
          paddingBottom: "45px",
          paddingTop: "30px",
          borderRadius: "17px",
          minHeight: "560px",
          maxHeight: "82%",
          paddingLeft: "10px",
          ...customPaperStyle,
        },
      }}
      BackdropProps={{
        style: {
          backdropFilter: "blur(13px)",
          backgroundColor: "rgba(0,0,30,0.4)",
        },
      }}
    >
      {/* <DialogTitle></DialogTitle> */}
      {/* <DialogContent> */}
      <div
        style={{
          width: "40px",
          height: "100%",
          background: "linear-gradient(180deg, #6D1CAC 0%, #240848 100%)",
          position: "absolute",
          right: "0",
          top: "0",
          zIndex: "10",
        }}
      >
        <ClosePlane
          onClick={handleClose}
          style={{
            position: "absolute",
            top: "15px",
            right: "15px",
            cursor: "pointer",
          }}
        />
        <AsistentJourney style={{ height: "-webkit-fill-available" }} />
      </div>
      {/* <div style={{ background: "black" }}> */}
      {children}
      {/* </div> */}
      {/* </DialogContent> */}
    </Dialog>
  );
}

export default NewDialaugeComp;
