import React, { useEffect, useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { mfoFilterContext } from "../mfo_context/Mfo_Context";

function ICDRoutes() {
  const { setEntity } = useContext(mfoFilterContext);
  setEntity("icd");

  return <Outlet />;
}

export default ICDRoutes;
