import { Button } from "@material-ui/core";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import useStyles from "../common/ProductsStyles";

import { divergingCommonProps } from "../../../charts/nivo_objects";

import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import { OnboardingContext } from "../../../../Context";
import { useContext } from "react";
import { useEffect } from "react";
import moment from "moment";
import {
  fetchTwrr,
  fetchTwrrCurrentHolding,
  fetchXirr,
  fetchXirrCurrentHolding,
  getRealisedData,
  invested_portfolio,
} from "../../../../api";
import { roundNumber, roundNumberWithoutDecimal } from "../../../utils/common";
import { currencyFormat } from "../../../../hooks/constantFunction";
import PageLoader from "../common/pageLoader";
import SummaryPageTop from "../common/SummaryPageTop";

import { loadingText } from "../../../utils/constands";
import { useHoldingCardActive } from "../../../../hooks/useHoldingCardActive";
import { NewHoldingSummaryCards } from "../../../common/HoldingSummaryCards/NewHoldingSummaryCards";
import { NewHoldingSummaryCardsActive } from "../../../common/HoldingSummaryCards/NewHoldingSummaryCardsActive";
import { useReturns } from "../../../../hooks/useReturns";
import { useNormalReturns } from "../../../../hooks/useNormalReturns";
import BarChartIcon from "@material-ui/icons/BarChart";
import PortfolioChart from "../../common/portfolioChart";
import { routes } from "../../../../routes";
import { useTotalGainLoss } from "../../../../hooks/useTotalGainLoss";
import { NewFilter } from "../../newFilter/NewFilter";
import { GainsCardInfo } from "../../../common/HoldingSummaryCards/GainsCardInfo";
import { ReactComponent as InfoIcon } from "../../../../assets/icons/TotalGainLossInfoIcon.svg";
import { useCardsApi } from "../../../../hooks/useCardsApi";
import { useCurrentHoldingCardsApi } from "../../../../hooks/useCurrentHoldingCardsApi";
export default function EquityComp() {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [infoIconHovered, setInfoIconHovered] = useState(false);
  // const [holdingCardData] = useHoldingCardActive("equity", checked);
  // const [totalGainLossData] = useTotalGainLoss("equity");
  // const { returns: twrr } = useNormalReturns(
  //   fetchTwrr,
  //   "normal-twrr",
  //   checked,
  //   "equity"
  // );
  // const { returns: xirr } = useNormalReturns(
  //   fetchXirr,
  //   "normal-xirr",
  //   checked,
  //   "equity"
  // );
  const {
    holdingCardData,
    totalGainLossData,
    xirr,
    twrr,
    loading: cardLoader,
    twrrLoader,
    twrrError,
    retryFetchTwrr,
  } = useCardsApi("equity");
  const {
    // holdingCardData,
    xirrCurrentHolding,
    twrrCurrentHolding,
    loading: currentHoldingCardLoader,
    twrrLoaderCurrentHolding,
    twrrErrorCurrentHolding,
    retryFetchTwrrCurrentHolding,
  } = useCurrentHoldingCardsApi(checked, "equity");
  // const { returns: xirrCurrentHolding } = useReturns(
  //   fetchXirrCurrentHolding,
  //   "xirr",
  //   checked,
  //   "equity"
  // );
  // const { returns: twrrCurrentHolding, loading: twrrCurrentHoldingLoading } =
  //   useReturns(fetchTwrrCurrentHolding, "twrr", checked, "equity");

  const navigate = useNavigate();
  const [flip, setFlip] = useState(false);
  // const [twrr, setTwrr] = useState({});
  // const [twrrCurrentHolding, setTwrrCurrentHolding] = useState({});
  // const [xirr, setXirr] = useState({});
  // const [xirrCurrentHolding, setXirrCurrentHolding] = useState({});
  const [realisedData, setRealisedData] = useState({
    arr: [],
  });
  const [investmentPortfolio, setInvestmentPortfolio] = useState([]);
  const [loading, setLoading] = useState(true);

  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const { userState } = OnboardingContext();

  const diverging = {
    ...divergingCommonProps,
  };
  diverging.axisBottom.legendOffset = 65;
  diverging.margin = { top: 5, right: 20, bottom: 70, left: 80 };
  useEffect(() => {
    const clientId = userState.loginedUserData.id;

    let params = { ...getfilter_as_params() };
    params["promoterStake"] = mfoFilter.promoterStake;
    setLoading(true);
    Promise.allSettled([
      // fetchXirr(clientId, params, "equity"),
      // fetchXirrCurrentHolding(clientId, params, "equity"),
      // fetchTwrr(clientId, params, "equity"),
      // fetchTwrrCurrentHolding(clientId, params, "equity"),
      getRealisedData(clientId, params, "equity", checked),
    ]).then(
      ([
        // xirr_res,
        // xirrCurrentHolding_res,
        // twrr_res,

        // twrrCurrentHolding_res,
        realiseddata_res,
      ]) => {
        // async function allres(){
        //   await xirr_res
        //   await twrr_res
        //   await realiseddata_res
        //   await asset_ress
        //   setLoading(false)
        // }

        // if (xirr_res.status == "fulfilled") {
        //   setXirr(xirr_res.value.result);
        //   console.log(xirr_res.value.result);
        // }
        // if (xirrCurrentHolding_res.status == "fulfilled") {
        //   setXirrCurrentHolding(xirrCurrentHolding_res.value.result);
        // }
        // if (twrr_res.status == "fulfilled") {
        //   if (twrr_res.value?.result?.annualisedTwrr) {
        //     twrr_res.value.result.annualisedTwrr =
        //       twrr_res.value?.result?.annualisedTwrr * 100;
        //   }
        //   console.log(twrr_res.value.result);

        //   setTwrr(twrr_res.value.result);
        // }

        // if (twrrCurrentHolding_res.status == "fulfilled") {
        //   if (twrrCurrentHolding_res.value?.result?.annualisedTwrr) {
        //     twrrCurrentHolding_res.value.result.annualisedTwrr =
        //       twrrCurrentHolding_res.value?.result?.annualisedTwrr * 100;
        //   }
        //   setTwrrCurrentHolding(twrrCurrentHolding_res.value.result);
        // }

        if (realiseddata_res.status == "fulfilled") {
          setRealisedData({ ...realiseddata_res.value.result });
        }
        setLoading(false);
      }
    );

    // setloadque(4);
    // fetchXirr(clientId, params, "pms")
    //   .then((res) => {
    //     setXirr(res.result);
    //     setloadque(loadque > 1 ? loadque - 1 : null);
    //   })
    //   .catch((err) => {
    //     setloadque(loadque > 1 ? loadque - 1 : null);
    //   });
    // fetchTwrr(clientId, params, "pms")
    //   .then((res) => {
    //     if (res?.result?.annualisedTwrr) {
    //       res.result.annualisedTwrr = res?.result?.annualisedTwrr * 100;
    //     }
    //     setTwrr(res.result);
    //     setloadque(loadque > 1 ? loadque - 1 : null);
    //   })
    //   .catch((err) => {
    //     setloadque(loadque > 1 ? loadque - 1 : null);
    //   });
    // invested_portfolio(clientId, params, "pms")
    //   .then((res) => {
    //     setInvestmentPortfolio(res.result);
    //     // setPie({
    //     //     text: "Portfolio value",
    //     //     value: res.result.portfolioValueAmount
    //     // })
    //     setloadque(loadque > 1 ? loadque - 1 : null);
    //   })
    //   .catch((err) => {
    //     setloadque(loadque > 1 ? loadque - 1 : null);
    //   });
    // getAssetWiseData(clientId, params, "pms")
    //   .then((res) => {
    //     let arr = [];
    //     let asset = {};
    //     let colors = [];
    //     res.result.forEach((a) => {
    //       arr.push({
    //         id: `${a.asset_class}`,
    //         label: `${
    //           a.asset_class
    //             ? a.asset_class.charAt(0).toUpperCase() +
    //               a?.asset_class?.slice(1)
    //             : " "
    //         }  ${a.percentage}%`,
    //         value: Number(a.percentage).toFixed(2),
    //         amount: a.amount,
    //       });
    //       colors.push(color[a.asset_class]);

    //       asset = {
    //         ...asset,
    //         [a.asset_class]: {
    //           percent: Number(a.percentage).toFixed(2),
    //           amount: a.amount,
    //           date: a.as_on_date,
    //         },
    //       };
    //     });
    //     setAsset_Color(colors);
    //     setPie({
    //       ...pie,
    //       colors: colors,
    //     });
    //     setAssets(asset);
    //     setAssetData(arr);
    //     setloadque(loadque > 1 ? loadque - 1 : null);
    //   })
    //   .catch((err) => {
    //     console.log(err.response.data);
    //     setloadque(loadque > 1 ? loadque - 1 : null);
    //   });
  }, [mfoFilter, checked]);
  function holdingSummaryCardsInactiveData() {
    return {
      asOnDate:
        realisedData?.asOnDate != null
          ? holdingCardData?.asOnDate
          : loadingText,

      //    * current-holding api values
      portfolioValueAmount:
        holdingCardData?.portfolioValueAmount != null
          ? currencyFormat(holdingCardData?.portfolioValueAmount)
          : loadingText,
      //   * gain-loss api values
      overallInvestedAmount:
        realisedData?.overAllInvestedAmount != null
          ? currencyFormat(realisedData?.overAllInvestedAmount)
          : loadingText,
      //    * current-holding api values
      investedAmount:
        holdingCardData?.investedAmount != null
          ? currencyFormat(holdingCardData?.investedAmount)
          : loadingText,
      //    * current-holding api values
      redeemedInvestedAmount:
        holdingCardData?.redeemedInvestedAmount != null
          ? currencyFormat(holdingCardData?.redeemedInvestedAmount)
          : loadingText,
      //   * total-gain-loss api value
      totalGainLoss:
        totalGainLossData?.totalGainLoss != null
          ? currencyFormat(totalGainLossData?.totalGainLoss)
          : loadingText,
      //   * gain-loss api values
      realisedGainLoss:
        realisedData?.realisedGainLoss != null
          ? currencyFormat(realisedData?.realisedGainLoss)
          : loadingText,
      //    * current-holding api values
      unRealisedGainLoss:
        holdingCardData?.unRealisedGainLoss != null
          ? currencyFormat(holdingCardData?.unRealisedGainLoss)
          : loadingText,
      //   * gain-loss api values
      xirrValue:
        xirr?.xirr != null
          ? roundNumberWithoutDecimal(Number(xirr?.xirr)) + " %"
          : loadingText,
      //   * gain-loss api values
      absoluteTwrrData:
        twrr?.absoluteTwrr != null
          ? roundNumberWithoutDecimal(Number(twrr?.absoluteTwrr)) + " %"
          : loadingText,
      //   * gain-loss api values
      annualisedTwrrData:
        twrr?.annualisedTwrr != null
          ? roundNumberWithoutDecimal(Number(twrr?.annualisedTwrr)) + " %"
          : loadingText,
      //   * gain-loss api values
      interestIncome:
        realisedData?.interestIncome != null
          ? currencyFormat(realisedData?.interestIncome)
          : loadingText,
    };
  }
  function holdingSummaryCardsActiveData() {
    return {
      asOnDate:
        holdingCardData?.asOnDate !== null
          ? holdingCardData?.asOnDate
          : loadingText,
      portfolioValueAmount:
        holdingCardData?.portfolioValueAmount !== null
          ? currencyFormat(holdingCardData?.portfolioValueAmount)
          : loadingText,
      investedAmount:
        holdingCardData?.investedAmount !== null
          ? currencyFormat(holdingCardData?.investedAmount)
          : loadingText,
      totalGainLoss:
        holdingCardData?.totalGainLoss !== null
          ? currencyFormat(holdingCardData?.totalGainLoss)
          : loadingText,
      realisedGainLoss:
        holdingCardData?.realisedGainLoss !== null
          ? currencyFormat(0)
          : loadingText,
      unRealisedGainLoss:
        holdingCardData?.unRealisedGainLoss !== null
          ? currencyFormat(holdingCardData?.unRealisedGainLoss)
          : loadingText,
      xirrValue:
        xirrCurrentHolding?.xirr !== null
          ? roundNumberWithoutDecimal(Number(xirrCurrentHolding?.xirr)) + " %"
          : loadingText,
      absoluteTwrrData:
        twrrCurrentHolding?.absoluteTwrr !== null
          ? roundNumberWithoutDecimal(
              Number(twrrCurrentHolding?.absoluteTwrr)
            ) + " %"
          : loadingText,
      annualisedTwrrData:
        twrrCurrentHolding?.annualisedTwrr !== null
          ? roundNumberWithoutDecimal(
              Number(twrrCurrentHolding?.annualisedTwrr)
            ) + " %"
          : loadingText,
      interestIncome:
        holdingCardData?.interestIncome !== null
          ? currencyFormat(holdingCardData?.interestIncome)
          : loadingText,
    };
  }
  return (
    <>
      <SummaryPageTop
        backLink={routes?.homeHoldingPage}
        pageTitle={"Direct Equity Performance Summary"}
        flip={flip}
        setFlip={setFlip}
        checked={checked}
        setChecked={setChecked}
        setFilterModalOpen={setFilterModalOpen}
      />
      <NewFilter
        filterModalOpen={filterModalOpen}
        setFilterModalOpen={setFilterModalOpen}
        productType="equity"
      />
      {/*<PageFilter />
       <div className={`${classes.alignTopHeader}`}>
        <Back
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/home/holding-summary/details");
          }}
        />
        <Typography
          style={{ marginLeft: "-4%" }}
          className={`${classes.mainHeading} ${classes.mainHeadingAlign}`}
        >
          Equity Performance Summary
        </Typography>
        <div className={`${classes.iconAlign}`}>
          {!flip && (
            <Bulb
              onClick={() => setFlip(!flip)}
              className={classes.iconStyle}
            />
          )}
          {flip && (
            <LitBulb
              onClick={() => setFlip(!flip)}
              className={classes.iconStyle}
            />
          )}
          <Import className={classes.iconStyle} />
          <Export className={classes.iconStyle} />
          <Print className={classes.iconStyle} />
          <Filter className={classes.iconStyle} />
        </div>
      </div> */}
      {/* <div style={{ position: "relative" }} className={classes.mainContent}> */}
      {/* <EquityCardSummary/> */}
      {/* {twrr?.absoluteTwrr} */}
      {/* <FlippyArray
          page={"equity"}
          investmentData={
            realisedData?.investedAmount != null
              ? currencyFormat(realisedData?.investedAmount)
              : "..."
          }
          portfolioData={
            realisedData?.portfolioValueAmount != null
              ? currencyFormat(realisedData?.portfolioValueAmount)
              : "..."
          }
          realisedGainData={
            realisedData?.realisedGainLossPercent != null
              ? currencyFormat(realisedData?.realisedGainLoss)
              : "..."
          }
          unrealisedGainData={
            realisedData?.unRealisedGainLossPercent != null
              ? currencyFormat(realisedData?.unRealisedGainLoss)
              : "..."
          }
          xirrData={xirr.xirr != null ? roundNumber(xirr.xirr) + "%" : "..."}
          absoluteTwrrData={
            twrr?.absoluteTwrr != null
              ? roundNumber(twrr?.absoluteTwrr) + "%"
              : "..."
          }
          annualisedTwrrData={
            twrr?.annualisedTwrr != null && Number(twrr?.annualisedTwrr)
              ? roundNumber(Number(twrr?.annualisedTwrr) * 100) + "%"
              : "..."
          }
          flip={flip}
        /> */}
      <div style={{ margin: "2%" }}>
        {!checked ? (
          <NewHoldingSummaryCards
            retryFetchTwrr={retryFetchTwrr}
            twrrLoader={twrrLoader}
            twrrError={twrrError}
            infoIconHovered={infoIconHovered}
            holdingSummaryCardsInactive={holdingSummaryCardsInactiveData()}
            totalGainLossInactive={
              <>
                <GainsCardInfo
                  text="Realized Gain/Loss"
                  value={holdingSummaryCardsInactiveData()?.realisedGainLoss}
                  align="left"
                />
                <GainsCardInfo
                  text="Unrealized Gain/Loss"
                  value={holdingSummaryCardsInactiveData()?.unRealisedGainLoss}
                  align="center"
                />
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <GainsCardInfo
                    text="Dividend Received"
                    value={holdingSummaryCardsInactiveData()?.interestIncome}
                    align="right"
                  />
                </div>
              </>
            }
          ></NewHoldingSummaryCards>
        ) : (
          <NewHoldingSummaryCardsActive
            retryFetchTwrrCurrentHolding={retryFetchTwrrCurrentHolding}
            twrrLoaderCurrentHolding={twrrLoaderCurrentHolding}
            twrrErrorCurrentHolding={twrrErrorCurrentHolding}
            infoIconHovered={infoIconHovered}
            holdingSummaryCardsActive={holdingSummaryCardsActiveData()}
            totalGainLossActive={
              <>
                <GainsCardInfo
                  text="Unrealized Gain/Loss"
                  value={holdingSummaryCardsActiveData()?.unRealisedGainLoss}
                  align="left"
                />
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <GainsCardInfo
                    text="Dividend Received"
                    value={holdingSummaryCardsActiveData()?.interestIncome}
                    align="right"
                  />
                </div>
              </>
            }
          ></NewHoldingSummaryCardsActive>
        )}
      </div>

      {/* <div
          style={{ position: "relative", paddingBottom: "5%" }}
          className={classes.mainContent}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              className={`${classes.titleText} ${classes.typoInsideAlign}`}
            >
              Portfolio Movement (Absolute Value)
            </Typography>

            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: " flex-end",
                marginRight: "20px",
              }}
            >
              <Filter
                style={{ border: "1px solid #FFFFFF" }}
                className={classes.iconStyle}
              />
            </div>
          </div>
          <div className="chart_padding" style={{ paddingBottom: "100px" }}>
            <div>
              <LineChart config={{}}></LineChart>
            </div>
            <div>
              <BarChart
                tooltip={tooltip}
                config={{ height: "200px" }}
                commonprops={diverging}
              />
            </div>
            <div style={{ height: "80px", overflow: "hidden" }}>
              <GoogleChart></GoogleChart>
            </div>
          </div>
          <Grid
            xs={12}
            style={{
              marginLeft: "50%",
              marginTop: "20%",
              position: "absolute",
              bottom: "12%",
              right: "3%",
            }}
          >
            <div className={classes.bottomTabHeader}>
              <Link style={{ textDecoration: "none", paddingTop: "2px" }} to="#">
                <div
                  className={`${classes.bottomTabHeaderText} ${classes.bottomTabActive}`}
                >
                  3M
                </div>
              </Link>
              <Link style={{ textDecoration: "none", paddingTop: "2px" }} to="#">
                <div className={`${classes.bottomTabHeaderText} `}>6M</div>
              </Link>
              <Link style={{ textDecoration: "none", paddingTop: "2px" }} to="#">
                <div className={`${classes.bottomTabHeaderText} `}>1Y</div>
              </Link>
              <Link style={{ textDecoration: "none", paddingTop: "2px" }} to="#">
                <div className={`${classes.bottomTabHeaderText} `}>3Y</div>
              </Link>
              <Link style={{ textDecoration: "none", paddingTop: "2px" }} to="#">
                <div className={`${classes.bottomTabHeaderText} `}>5Y</div>
              </Link>
              <Link style={{ textDecoration: "none", paddingTop: "2px" }} to="#">
                <div className={`${classes.bottomTabHeaderText} `}>
                  Inception Till Date
                </div>
              </Link>
              <Link style={{ textDecoration: "none", paddingTop: "2px" }} to="#">
                <div className={`${classes.bottomTabHeaderText} `}>Custom</div>
              </Link>
            </div>
          </Grid>
        </div> */}
      <PortfolioChart
        type={"equity"}
        loading={loading}
        noPaddingBottom={true}
        viewDetailBtn={
          <Button
            onClick={() => navigate(routes?.equityMarket)}
            className={classes.viewDetailBtn + " portfolioBtn "}
            style={{
              width: "20%",
              margin: "0% 40% 0",

              borderRadius: "7px",
              backgroundColor: "#4f1fda !important",
            }}
            startIcon={<BarChartIcon />}
          >
            View Detail Report
          </Button>
        }
      ></PortfolioChart>
      {/* <Button
        onClick={() => navigate("/home/equity/equity-summary/market")}
        style={{ width: "20%", margin: "2% 40%", color: "#FFFFFF" }}
        className={classes.viewDetailBtn}
        startIcon={<BarChartIcon />}
      >
        View Detail Report
      </Button> */}
      <PageLoader showLoader={loading} />
      <PageLoader
        showLoader={currentHoldingCardLoader && checked ? true : false}
      />
    </>
  );
}
