import React from "react";

function CashflowTablePrefix({ showBottom }) {
  return (
    <div
      style={{
        position: "absolute",
        top: "0",
        bottom: "0",
        // height: "100%",
        opacity: "0.2",
        overflow: "hidden"
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="64"
        viewBox="0 0 16 64"
        fill="none"
      >
        <g opacity="0.67">
          <path d="M1 0V19C1 21.7614 3.23858 24 6 24H16" stroke="white" />
          <path d="M1 0V19C1 21.7614 3.23858 24 6 24H16" stroke="white" />
        </g>
        <g opacity={showBottom ? "0.67" : "0"}>
          <path d="M1 19V68C1 70.7614 3.23858 73 6 73H16" stroke="white" />
          <path d="M1 19V68C1 70.7614 3.23858 73 6 73H16" stroke="white" />
        </g>
      </svg>
    </div>
  );
}

export default CashflowTablePrefix;
