import {
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@material-ui/core";
import React from "react";
import { useState } from "react";
import useStyles from "./FamilyStyles";
import { makeStyles } from "@material-ui/core/styles";
import "./familtyTreeModal.css";

function FamilyModal({
  open,
  setOpen,
  selectedOption,
  setSelectedOption,
  handleClick,
  child,
  setNickName,
  nickName,
}) {
  const classes = useStyles();
  const options = ["Child1", "Child2", "Child3", "Child4", "Child5"];
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [parentId, setParentId] = useState();

  const handleNickname = (e) => {
    setNickName(e?.target?.value);
  };

  const handleClose = () => {
    setOpen(false);
    // if (selectedOption) {

    //     handleClick()
    // }
  };

  const handleSelectOption = (event) => {
    const option = event.target.value;

    setSelectedOption(option);
  };

  const handleAddOption = () => {
    if (selectedOption) {
      setSelectedOptions((c) => [
        ...c,
        { childTag: selectedOption, parentTag: parentId },
      ]);
      setSelectedOption("");
      handleClose();
      handleClick();
    } else {
      handleClose();
    }
  };
  const styles = {
    root: {
      borderRadius: "50px",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "var(--border)",
          //   backgroundColor: '#1C1B39',
          width: "50%",
        },
      },
    },
  };
  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <div
          className={classes.modalContent}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div style={{ display: "flex" }}>
            <div>
              <FormControl
                style={{ minWidth: "326px" }}
                variant="outlined"
                className="noneFieldset"
                // className={`${classes.formControl} ${classes.inputField}`}
              >
                <p
                  style={{ color: "#EBFAF9" }}
                  className="treeModalLabel1"
                  id="child-select-label"
                >
                  Select the child
                </p>
                {/* <br /> */}
                <Select
                  native
                  // className={classes.select}
                  labelId="child-select-label"
                  id="child-select"
                  style={{ height: "50px" }}
                  // label="Select the child"
                  value={selectedOption}
                  inputProps={{
                    name: "member_type",
                    id: "outlined-entity_type-native-simple",
                    classes: {
                      icon: classes.icon,
                    },
                  }}
                  onChange={handleSelectOption}
                >
                  {child.map((option) => (
                    // <MenuItem key={option} value={option} disabled={selectedOptions.some(obj => obj.childTag == option)}>
                    //     {option}
                    // </MenuItem>
                    <option
                      className="optiondesign1"
                      key={option.member_id}
                      value={option.member_id}
                      disabled={selectedOptions.some(
                        (obj) => obj.childTag == option
                      )}
                    >
                      {option.full_name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div
              style={{
                width: "1px",
                border: "0px solid rgba(231, 234, 243, 0.2)",
                backgroundColor: "rgba(231, 234, 243, 0.2)",
                margin: "20px",
                height: "29vw",
              }}
            ></div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              {/* <div className={classes.ChildText}>{selectedOption}</div> */}
              {/* <br /> */}
              <form>
                <p
                  style={{ color: "#EBFAF9" }}
                  className="treeModalLabel1"
                  // id="child-select-label"
                >
                  Relation/Nickname
                </p>
                <TextField
                  style={{ width: "326px" }}
                  // label="Relation/Nickname"
                  variant="outlined"
                  focused
                  value={nickName}
                  onChange={handleNickname}
                  sx={styles.root}
                />
              </form>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddOption}
            >
              {selectedOption ? `Add` : `Close`}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default FamilyModal;
