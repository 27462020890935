import React from "react";
import Default from "../components/dashboard/defaultPages/Default";
import MLDReport from "../components/dashboard/products/mld/MLDReport";
import { useParams } from "react-router-dom";

function MLDReportDetails() {
  const { pageName } = useParams();

  return (
    <Default>
      <MLDReport pageName={pageName} />
    </Default>
  );
}

export default MLDReportDetails;
