import React, { useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { ReactComponent as EditIcon } from "../../assets/icons/EditIcon.svg";
import {
  bodyData3,
  headerDataPartner2,
} from "../user_management/utils/constands";
import { UserManagementDetailsHeader } from "../user_management/UserManagementDetailsHeader";
import ClientAddMember from "../user_management/client_flow/ClientAddMember";
import { useLocation, useNavigate } from "react-router-dom";
import AddUserFlow from "../user_management/AddUserFlow/AddUserFlow";
import NewPartnerUserManagement from "../user_management/PartnerUserManagement/NewPartnerUserManagement";
import useGetNoOfUserAndClient from "../../hooks/useGetNoOfUserAndClient";
import { updatepartneruser } from "../../api";

function PartnerApproved() {
  const { state } = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const [header, setHeader] = useState([]);
  const [resData, setData] = useState();
  const navigate = useNavigate();
  const [selectedUserData, setSelecteduserData] = useState();
  // console.log(state, "agkajn");
  const { getUserAndClientCount } = useGetNoOfUserAndClient({
    setData,
  });

  const handleHeader = (headerData) => {
    headerData?.map((val) => {
      if (val?.field == "status") {
        val.onClick = (data) => {
          //here we will get data for edit modal
          setSelecteduserData(data);
        };
      }
    });
    setHeader(headerData);
  };

  // useEffect(() => {
  //   if (selectedUserData) {
  //     setOpenModal(true);
  //   }
  // }, [selectedUserData]);

  useEffect(() => {
    getUserAndClientCount(state?.data?.id);
    // handleHeader(headerDataPartner2);
    // setData(bodyData3);
    // return () => {
    //   setData([]);
    //   setHeader([]);
    // };
  }, []);

  const handleEditPartner = async (value) => {
    console.log(value, "kbbh");
    try {
      let payload = { fullName: value?.partnerName };
      let res = await updatepartneruser("partner", state?.data?.id, payload);
      // throw "hai";
      setData((prev) => {
        return {
          ...prev,
          entityName: value?.partnerName,
          entity_name: value?.partnerName,
        };
      });
      // console.log(res, "aflnajklf");
    } catch (error) {}
  };

  // console.log(resData, "afkjbahf");

  return (
    <>
      <UserManagementDetailsHeader
        key={resData}
        hideLeftSec={true}
        componentToRender={"userManagementDetailsHeader"}
        customHandleBack={() => {
          navigate("/partner");
        }}
        data={{
          userCount: resData?.user_count,
          clientCount: resData?.client_count,
          entityName: resData?.entity_name,
          ...state?.data,
        }}
        partner={true}
        handleEditPartner={handleEditPartner}
      />
      <NewPartnerUserManagement
        customTitle={"User List"}
        partnerId={state?.data?.id}
        addBtnTitle={"Add Partner User"}
      />

      {/* <ClientAddMember openModal={openModal} setOpenModal={setOpenModal} /> */}
      {/* <AddUserFlow
        handleClose={() => {
          setSelecteduserData();
        }}
        selectedData={selectedUserData}
        openModal={openModal}
        setOpenModal={setOpenModal}
      /> */}
    </>
  );
}

export default PartnerApproved;
