import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    background: "#120D20",
  },
  tableCell: {
    borderBottom: "none",
    color: "#FFF",
    opacity: 0.51,
  },
  customPaper: {
    borderRadius: 0, // Remove border-radius
  },
});
function getTableColumnsBasedOnErrorName(errName) {
  if (errName === "isin") {
    return ["ISIN", "TRANSACTION DATE", "ROW NO"];
  } else if (errName === "mandatory") {
    return ["COLUMN NAME", "ROW NO"];
  } else if (errName === "valuation_date") {
    return ["TRANSACTION DATE", "ROW NO"];
  } else if (errName === "duplicate_records") {
    return ["PAN", "ISIN", "TRANSACTION DATE", "QUANTITY/UNITS"];
  } else if (errName === "miscellaneous") {
    return ["COLUMN NAME", "ROW NO", "INVALID DATA"];
  } else if (errName === "transaction_date") {
    return ["TRANSACTION DATE", "ROW NO"];
  } else if (errName === "transaction_number") {
    return ["ROW NO", "Transaction Date", "Transaction Number"];
  }
}
function getRelevantRowData(errName, rowData) {
  if (errName === "duplicate_records") {
    // Return only the fields that match the headers for duplicate records
    return {
      pan: rowData?.pan || "-",
      isin: rowData?.product_code || rowData?.isin || "-",
      valuation_date:
        rowData?.transaction_date || rowData?.valuation_date || "-",
      quantity_units: rowData?.quantity || rowData?.units || "-",
    };
  } else {
    // For other error types, return the row data as is
    return rowData;
  }
}
export function ValidationDisplayTable({ tableData }) {
  const classes = useStyles();
  // const headers = ["PAN NO.", "ISIN", "TRANSACTION DATE", "QUANTITY / UNITS"];
  const headers = getTableColumnsBasedOnErrorName(tableData?.errorName);

  console.log("tableData", tableData, headers);

  return (
    <TableContainer component={Paper} classes={{ root: classes.customPaper }}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {headers?.map((header, index) => (
              <TableCell key={index} className={classes.tableCell} align="left">
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData?.data?.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {Object?.entries(
                getRelevantRowData(tableData?.errorName, row)
              ).map(([key, value], cellIndex) => (
                <TableCell
                  key={cellIndex}
                  className={classes.tableCell}
                  align="left"
                >
                  {value}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
