import React from "react";
import NewTabComp from "../products/common/NewTabComp";
import { newFilterMainTab } from "./newFilterConstants";
import { useTheme } from "@material-ui/core";
export const NewFilterSubHeader = ({
  handleMainTabClick,
  currentTabIndex,
  selectedFamilyMembers,
  productType,
}) => {
  console.log("NewFilterSubHeader", currentTabIndex);
  const theme = useTheme();
  return (
    // Main Tab
    <div
      className="newFilterSubHeader"
      style={{ background: theme.palette.newFilter.newFilterSubHeader }}
    >
      <NewTabComp
        key={currentTabIndex}
        data={newFilterMainTab(productType)}
        customWidth={"100%"}
        customHandle={handleMainTabClick}
        customSelect={currentTabIndex}
        disableArrow={true}
      />
      <div className="newFilterSubHeaderSelectedMembers">
        {selectedFamilyMembers?.length > 1
          ? `${selectedFamilyMembers?.length} Members Selected`
          : `${selectedFamilyMembers?.length} Member Selected`}
      </div>
    </div>
  );
};
