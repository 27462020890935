import React, { useEffect, useState } from "react";
import Default from "../components/dashboard/defaultPages/Default";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "../components/loader/Loader";
import { checkEmptyList } from "../hooks/constantFunction";
import _ from "lodash";
import { ITEMS_LIMIT_MASTERS_LIST } from "../Constants";
import AppPagination from "../components/verificationUpload/AppPagination";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { fetchAllMastersProducts } from "../api";
import CustomizedSnackbars from "../components/common/CustomizedSnackbars";
import MasterProductsContent from "../components/dashboard/scheme-masters/MasterProductsContent";
import SearchHeaderSchemeMaster from "../components/dashboard/common/SearchHeaderSchemeMaster";
import MasterHeader from "../components/common/MasterHeader";
import C_Modal from "../components/common/modal_add_member/C_Modal";
import UploadBulk from "../components/dashboard/scheme-masters/UploadBulk";

const useStyles = makeStyles((theme) => ({
  searchWrapper: {
    marginTop: "1%",
    marginLeft: "5%",
    marginRight: "5%",
  },
  titleWrapper: {
    marginTop: "2%",
    marginLeft: "5%",
  },
  titleText: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "24px",
    lineHeight: "30px",
    display: "flex",
    alignItems: "center",
    color: "var(--selectedText)",
  },
}));

function MasterProducts() {
  const config = {
    equity: { type: "price", header: "Upload NAV" },
    bonds: { type: "price", header: "Upload NAV" },
    mf: { type: "nav", header: "Upload NAV" },
    structured: { type: "price", header: "Upload NAV" },
  };
  const classes = useStyles();
  const itemLimit = ITEMS_LIMIT_MASTERS_LIST;
  const [showLoder, setShowLoader] = useState(false);
  const [masterProductsData, setMasterProductsData] = useState(null);
  const [emptyList, setEmptyList] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [offset, setItemOffset] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openModal3, setOpenModal3] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [openNav, setOpenNav] = useState(false);
  const [openCorporateAction, setOpenCorporate] = useState(false);
  const [active, setActive] = useState(false);

  const {
    state: { master, master_name },
  } = useLocation();
  const navigate = useNavigate();

  const [uploadConfig, setUploadConfig] = useState(
    config[master] ? config[master] : { type: "nav", header: "Upload NAV" }
  );

  const fetchMastersProducts = async () => {
    try {
      setShowLoader(true);
      setEmptyList(false);
      const data = await fetchAllMastersProducts(
        master,
        searchName,
        itemLimit,
        offset,
        active
      );
      const fetchMasterProdcutsData = data.data;

      if (fetchMasterProdcutsData.success) {
        const totalItem = fetchMasterProdcutsData.data.meta.count;
        setPageCount(Math.ceil(totalItem / itemLimit));
        setMasterProductsData(fetchMasterProdcutsData.data.result);
        checkEmptyList(fetchMasterProdcutsData.data.result, setEmptyList);
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
      if (error.response.request.status === 401) {
        navigate("/logout");
      }
    }
  };

  useEffect(() => {
    fetchMastersProducts();
  }, [searchName, offset, active]);

  const openMOdal = (value) => {
    if (master === "private") {
      setOpenCorporate(value);
    } else {
      setOpenModal2(value);
    }
  };

  const openMOdalBond = (value) => {
    setOpenModal3(value);
  };

  return (
    <>
      <Default>
        <CustomizedSnackbars />
        <MasterHeader
          title={`${master_name} Master`}
          state={{ products: { master, master_name } }}
        />
        <div className={classes.titleWrapper}></div>
        <div className={classes.searchWrapper}>
          <SearchHeaderSchemeMaster
            setSearchName={setSearchName}
            showAddButton={true}
            showAddButtonSingleUpload={master === "equity" ? false : true}
            btn2={setOpenModal}
            btn3={
              master === "bonds" || master === "private" ? openMOdal : false
            }
            btn4={master === "bonds" ? openMOdalBond : false}
            btnTitle={
              master === "private"
                ? "Upload Corporate Actions"
                : "Upload Payout Reduction Details"
            }
            btnTitle4={"Upload Price"}
          ></SearchHeaderSchemeMaster>
          {showLoder && <Loader />}
          <div className="tableconfig">
            <MasterProductsContent
              masterProductsData={masterProductsData}
              emptyList={emptyList}
              setOpenNav={setOpenNav}
              setActive={setActive}
            ></MasterProductsContent>
          </div>
          <div>
            <div className="paginationstyl">
              {pageCount > 0 && (
                <AppPagination
                  pageCount={pageCount}
                  itemLimit={itemLimit}
                  setItemOffset={setItemOffset}
                  otherProps={{ shape: "rounded" }}
                />
              )}
            </div>
          </div>
        </div>
        <C_Modal
          openModal={openModal}
          containerstyle={{ paddingLeft: "53px", height: "510px" }}
          modalContainer={{ height: "500px" }}
          closemodal={setOpenModal}
          onBackdropClick="false"
        >
          <UploadBulk
            type={{ type: "bulk", header: "Upload Scheme Master" }}
          ></UploadBulk>
        </C_Modal>

        {/* //bonds  */}
        <C_Modal
          openModal={openModal2}
          containerstyle={{ paddingLeft: "53px", height: "510px" }}
          modalContainer={{ height: "500px" }}
          closemodal={setOpenModal2}
          onBackdropClick="false"
        >
          <UploadBulk
            type={{ type: "payout", header: "Upload Payout Reduction Details" }}
          ></UploadBulk>
        </C_Modal>

        <C_Modal
          openModal={openModal3}
          containerstyle={{ paddingLeft: "53px", height: "510px" }}
          modalContainer={{ height: "500px" }}
          closemodal={setOpenModal3}
          onBackdropClick="false"
        >
          <UploadBulk
            type={{ type: "bonds-upload-price", header: "Upload Price" }}
          ></UploadBulk>
        </C_Modal>

        <C_Modal
          openModal={openNav}
          containerstyle={{ paddingLeft: "53px", height: "510px" }}
          modalContainer={{ height: "500px" }}
          closemodal={setOpenNav}
          onBackdropClick="false"
        >
          <UploadBulk type={uploadConfig}></UploadBulk>
        </C_Modal>
        <C_Modal
          openModal={openCorporateAction}
          containerstyle={{ paddingLeft: "53px", height: "510px" }}
          modalContainer={{ height: "500px" }}
          closemodal={setOpenCorporate}
          onBackdropClick="false"
        >
          <UploadBulk type={{ type: "private" }}></UploadBulk>
        </C_Modal>
      </Default>
    </>
  );
}

export default MasterProducts;
