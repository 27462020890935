import React from "react";
import {
  styled,
  Grid,
  Paper
} from "@material-ui/core";
import useStyles from "../common/ProductsStyles";
import { ReactComponent as Marketmeter } from "../../../../assets/images/marketmeter.svg";

const Item = styled(Paper)(({ theme }) => ({
 
  textAlign: 'center',
}));

function CardnewDesign() {
  const classes = useStyles();

  return (
    <>
      
      <div className={classes.idcumainsection}>
        <Grid container spacing={0} className={classes.idcusection}>
          <Grid item xs={2}>
            <Item className={classes.idtitlebr}> Ideal </Item>
          </Grid>
          <Grid item xs={2}>
            <Item className={classes.metertitle}>
              <Marketmeter>
              </Marketmeter> 
                <br/>
                Conservative 
              </Item>
          </Grid>
          <Grid item xs={2}>
            <Item className={classes.metertitle}>
              <Marketmeter>
              </Marketmeter> 
                <br/>
                Moderate Conservative 
              </Item>
          </Grid>
          <Grid item xs={2}>
            <Item className={classes.metertitle}>
              <Marketmeter>
              </Marketmeter> 
                <br/>
                Moderate 
              </Item>
          </Grid>
          <Grid item xs={2}>
            <Item className={classes.metertitle}>
              <Marketmeter>
              </Marketmeter> 
                <br/>
                Moderate Aggressive 
              </Item>
          </Grid>
          <Grid item xs={2}>
            <Item className={classes.metertitlelastborder}>
              <Marketmeter>
              </Marketmeter> 
                <br/>
                Aggressive
            </Item>
          </Grid>
        </Grid>

        <Grid container spacing={0} className={classes.idcusection}>
          <Grid item xs={2}>
            <Item className={classes.cutitlebr}> Current </Item>
          </Grid>
          <Grid item xs={2}>
            <Item className={classes.metertitle}>
              <Marketmeter>
              </Marketmeter> 
                <br/>
                Conservative 
              </Item>
          </Grid>
          <Grid item xs={2}>
            <Item className={classes.metertitle}>
              <Marketmeter>
              </Marketmeter> 
                <br/>
                Moderate Conservative 
              </Item>
          </Grid>
          <Grid item xs={2}>
            <Item className={classes.metertitle}>
              <Marketmeter>
              </Marketmeter> 
                <br/>
                Moderate 
              </Item>
          </Grid>
          <Grid item xs={2}>
            <Item className={classes.metertitle}>
              <Marketmeter>
              </Marketmeter> 
                <br/>
                Moderate Aggressive 
              </Item>
          </Grid>
          <Grid item xs={2}>
            <Item className={classes.metertitlelastborder}>
              <Marketmeter>
              </Marketmeter> 
                <br/>
                Aggressive
            </Item>
          </Grid>
        </Grid>
      </div>
        
    </>
  );
  }

export default CardnewDesign;
