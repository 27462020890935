import React from "react";
import Default from "../components/dashboard/defaultPages/Default";
import BankBookReport from "../components/dashboard/products/bankBook/BankBookReport";
import { useParams } from "react-router-dom";

function BankBookDetails() {
  const { pageName } = useParams();

  return (
    <Default>
      <BankBookReport pageName={pageName} />
    </Default>
  );
}

export default BankBookDetails;
