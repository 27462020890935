import React, { useState } from "react";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import { Checkbox, FormControlLabel, Box } from "@material-ui/core";
import "./PrintModal.css";
export const PrintCheckBox = ({ setSelectedValue, selectedValue }) => {
  const handleChange = (value) => {
    setSelectedValue(value);
  };

  return (
    <Box className="radio-btn-container">
      <FormControlLabel
        control={
          <Checkbox
            checked={selectedValue === "pdf"}
            onClick={() => handleChange("pdf")}
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<RadioButtonCheckedIcon color="#8D45F7" />}
          />
        }
        label="PDF"
        labelPlacement="end"
        id="button-label"
        className="pdf"
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={selectedValue === "print"}
            onClick={() => handleChange("print")}
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<RadioButtonCheckedIcon color="#8D45F7" />}
          />
        }
        label="Print"
        id="button-label"
      />
    </Box>
  );
};
