import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
// import series from "./chartData";
// import { data } from "../../../src/components/dashboard/common/data";
import { dateFormatCommon, roundNumber } from "../utils/common";
export default function ApexNew({
  linedata,
  title,
  controller_disabled,
  legends_hide,
  showOps,
  config,
  grid,
  hidetooltipLabels,
  checkedChartIndex,
}) {
  // console.log(linedata, "linedata");
  // const linedata = series;
  let op = {
    grid: {
      borderColor: "#1F1C40",
    },
    tooltip: config?.tooltip
      ? config?.tooltip
      : {
          shared: false,

          custom: function ({
            series,
            seriesIndex,
            dataPointIndex,
            w,
            y1,
            y2,
          }) {
            //console.log(w.globals.initialSeries[seriesIndex], "series");
            // console.log(
            //   w.globals.initialSeries[seriesIndex]?.data.l,
            //   "globaldata"
            // );

            let str =
              ' <div class="tooltip_container">  <div class="subtext tooltip_text">' +
              dateFormatCommon(
                w.globals.initialSeries[seriesIndex]?.data[dataPointIndex][0],
                "YYYY-MM-DD"
              ) +
              "</div>";
            for (let i = 0; i < series.length; i++) {
              if (
                (hidetooltipLabels &&
                  hidetooltipLabels.includes(
                    w.globals.initialSeries[i]?.name
                  )) ||
                // w.globals.initialSeries[seriesIndex]?.data.length == 0 ||
                w.globals.initialSeries[i].data.length == 0
              ) {
                continue;
              }
              // if( w.globals.initialSeries[i].data.length==0){
              //   continue;
              // }
              str +=
                ` <div class="subtext tooltip_text"> ` +
                ` <div class="text_ellipse">${
                  w.globals.initialSeries[i]?.name
                } </div>
          ${
            w.globals.initialSeries[i]?.name == " " &&
            (typeof w.globals.initialSeries[i]?.data[dataPointIndex]?.[2] !=
              "number" ||
              typeof w.globals.initialSeries[i]?.data[dataPointIndex]?.[2] !=
                "string")
              ? ""
              : " " +
                  typeof w.globals.initialSeries[i]?.data[
                    dataPointIndex
                  ]?.[2] ==
                "string"
              ? w.globals.initialSeries[i]?.data[dataPointIndex]?.[2]
              : roundNumber(
                  w.globals.initialSeries[i]?.data[dataPointIndex]?.[2]
                ) + (title?.y == "TWRR" ? "%" : "")
          }` +
                "</div> ";
            }
            str += "</div></div>";
            return str;
          },
        },
    chart: {
      id: "chart1",
      type: "area",
      height: 230,
      width: "100%",
      toolbar: {
        show: false,
        tools: {
          reset: false,
        },
      },
      zoom: {
        type: "x",
        enabled: false,
      },
    },
    legend: {
      // show: legends_hide ? false : true,
      showForSingleSeries: true,
      showForNullSeries: true,
      height: legends_hide ? 10 : 105,
      offsetY: 15,
      itemMargin: {
        horizontal: 20,
      },
      labels: {
        colors: "#EAE7ED",
        background:
          "linear-gradient(0deg, #EAE7ED, #EAE7ED),linear-gradient(0deg, rgba(250, 249, 255, 0.7), rgba(250, 249, 255, 0.7))",
      },
    },
    stroke: {
      width: 3,
      dashArray: config?.dash ? config.dash : [0],
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    colors: config?.colors || [
      "#F52D6A",
      "#82EBA1",
      "#FF9446",
      "#711CF1",
      "#f6c7b6",
      "#0096FF",
      "#Ffff00",
    ],

    grid: {
      borderColor: "rgba(227, 227, 227, 0.5)",
      // row: {
      //   colors: ["transparent", "transparent"],
      // },
      // column: {
      //   colors: ["transparent", "transparent"],
      // },
      xaxis: {
        lines: {
          show: grid?.x ? true : false,
        },
      },
      yaxis: {
        lines: {
          show: grid?.y ? true : false,
        },
      },
    },
    xaxis: {
      type: "datetime",
      tooltip: {
        enabled: false,
      },
      title: {
        text: title?.x ? title.x : "Time Period",
        offsetY: 15,
        style: {
          fontFamily: "Avenir",
          fontSize: "14px",
          fontWeight: 300,
          lineHeight: "33px",
          color: "#fff",
        },
      },

      axisTicks: {
        show: false,
      },
      //   labels: {
      //
      //   },
      axisBorder: {
        show: true,
        color: "#F3F3F3",
      },

      labels: {
        datetimeFormatter: {
          year: "MMM-yyyy",
          month: "MMM-yyyy ",
          day: "dd-MMM",
          hour: "HH:mm",
        },

        // format: "MMM yyyy",
        style: {
          // fill: "#b0aab7",
          fontFamily: "Avenir",
          fontSize: "18px",
          fontWeight: "500",

          fontSize: 10,
          colors: "#ffffff",
        },
        datetimeUTC: false,
      },
    },

    yaxis: [
      {
        opposite: false,
        min: (min2, options) => {
          // let min3 = Math.min(...options["series"][0]["data"]);

          return min2;
        },

        // forceNiceScale: true,
        axisBorder: {
          show: false,
          color: "red",
        },
        title: {
          text: title?.y ? title.y : "Portfolio Value",
          style: {
            fontFamily: "Avenir",
            fontSize: "18px",
            fontWeight: 400,
            lineHeight: "30px",
            color: "#fff",
          },
        },
        labels: {
          style: {
            // fill: "#b0aab7",
            fontFamily: "Avenir",
            fontSize: "18px",
            fontWeight: "500",

            fontSize: 10,
            // colors: "#b0aab7",
            colors: "#FFFFFF61",
          },
        },
      },
      showOps
        ? {
            opposite: true,
            axisBorder: {
              show: true,
              color: "#EAE7ED",
            },
            labels: {
              style: {
                fontFamily: "Avenir",
                fontSize: "18px",
                fontWeight: "500",

                fontSize: 10,
                // colors: "#b0aab7",
                colors: "#FFFFFF61",
              },
            },
            title: {
              text: showOps.title || "",
              offsetY: 15,
              style: {
                fontFamily: "Avenir",
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "30px",
                color: "#fff",
              },
            },
          }
        : {},
      // : {
      //     show: false,
      //     axisBorder: {
      //       show: false,
      //       color: "#EAE7ED",
      //     },
      //     labels: {
      //       show: false,
      //       style: {
      //         colors: "#EAE7ED",
      //       },
      //     },
      //   },
    ],
  };
  const [options, setOptions] = useState(
    !showOps
      ? () => {
          op.yaxis.splice(0, 1);
          op.yaxis = {
            labels: {
              show: false,
              style: {
                // fill: "#b0aab7",
                fontFamily: "Avenir",
                fontSize: "18px",
                fontWeight: "500",

                fontSize: 10,
                colors: "#FFFFFF61",
                opacity: 0.1,
              },
            },
            title: {
              text: title?.y ? title.y : " ",
              offsetY: "5",
              style: {
                fontFamily: "Avenir",
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "30px",
                color: "#fff",
              },
            },
          };

          return op;
        }
      : op
  );

  const brushOptions = {
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    chart: {
      id: "chart2",
      height: 120,
      width: "100%",
      type: "area",
      // events: {
      //   selection: function (chartContext, { xaxis, yaxis }) {
      //
      //   },
      // },

      brush: {
        target: "chart1",
        enabled: true,
      },
      selection: {
        enabled: true,
      },
    },
    colors: config?.colors || [
      "#F52D6A",
      "#82EBA1",
      "#FF9446",
      "#f3b49f",
      "#f6c7b6",
    ],
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.91,
        opacityTo: 0.1,
      },
    },
    xaxis: {
      type: "datetime",
      tooltip: {
        enabled: false,
      },

      labels: {
        // datetimeFormatter: {
        //   year: "yyyy",
        //   month: "MMM. yyyy ",
        //   day: "dd. MMM",
        // },
        minHeight: 120,
        style: {
          // fill: "#b0aab7",

          colors: "#ffffff",
        },
      },
      axisTicks: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    yaxis: {
      tickAmount: 4,
      labels: {
        style: {
          // fill: "#b0aab7",
          fontFamily: "Avenir",
          fontSize: "18px",
          fontWeight: "500",

          fontSize: 10,
          colors: "#FFFFFF61",
          opacity: 0.1,
        },
      },
    },
  };

  const [Apexdata, setApexdata] = useState([]);
  useEffect(() => {
    if (!linedata) {
      return;
    }

    // let dt = [];
    // data.map((d) => {
    //   let obj = {
    //     name: d.id,
    //     data: [],
    //   };
    //   d.data.forEach((idata) => {
    //     obj.data.push([idata.x, roundNumber(idata.y), roundNumber(idata.z)]);
    //   });
    //   dt.push(obj);
    // });

    setApexdata(() => linedata);
    // options.yaxis.title.text = title?.y || " ";

    setOptions({ ...options });
    let arr = [];
  }, [linedata]);
  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          {options && linedata && (
            <div>
              <Chart
                options={options}
                series={linedata}
                type="line"
                height={400}
              />

              {!controller_disabled && (
                <Chart
                  // events={{
                  //   selection: function (chartContext, { xaxis, yaxis }) {
                  //
                  //   },
                  // }}

                  options={brushOptions}
                  series={linedata}
                  type="area"
                  height={250}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
