import React, { useState } from "react";
import { verifyOTPPartner2, sendOTP, reSendOTP } from "../../../api/index";
import { headers } from "../utils/utils";

export const useOtp = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const submitOtp = async (otp, comType, userId, entityType, handleNext) => {
    const data = {
      //   TODO Remove hardcoded values
      userId: userId,
      otp: otp,
    };

    setShowLoader(true);

    try {
      const res = await verifyOTPPartner2(data, comType, entityType);
      const verifyData = res.data;

      if (verifyData.success) {
        // go to next step
        handleNext(1);
        // clear any error message from previous step
        setErrorMessage("");
        setShowLoader(false);
      }
    } catch (error) {
      if (error.response.status) {
        setShowLoader(false);
        setErrorMessage(error?.response?.data?.error?.message);
        return;
      }
    }
  };

  const resendOtp = async (com_type, user_type, userId) => {
    const data = {
      userId: userId,
    };

    setShowLoader(true);

    try {
      const res = await reSendOTP(data, com_type, user_type, headers);
      const otpData = res.data;

      if (otpData.success) {
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
      console.log("An error occured", error);
    }
  };
  return { showLoader, submitOtp, resendOtp, setShowLoader, errorMessage };
};
