import React from "react";
// import { Formik, Form, Field } from "formik";
// import * as Yup from "yup";
import { makeStyles } from "@material-ui/core";
import {
  Grid,
  Button,
  Box,
  MenuItem,
  Select,
  InputAdornment,
} from "@material-ui/core";
import CustomInput from "./common/CustomInput";
import DefaultSign from "./DefaultSign";
// import BackButton from "../common/BackBtnWithArrow/BackButton";
import PartnerHeading from "./PartnerHeading";
// import VerifiedTick from "../../asset/icons/verified.svg";
import {
  registerForm1ValidationSchema,
  registerForm4ValidationSchema,
} from "./ValidationSchema";
import StepProgressBar from "./common/StepProgressBar/StepProgressBar";
import { useForm } from "./hooks/useForm";
import "./PartnerOnboarding.css";
import ErrorMessageComp from "./common/ErrorMessageComp";
import LoadingButton from "./common/LoadingButton";
import PartnerOnboardingForm1 from "./PartnerOnboardingForm1";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
  },
}));

export default function PartnerOnboarding1(props) {
  const { head } = props;

  const desc = head?.desc
    ? head?.desc
    : {
        center: [
          {
            title: "What’s a Partner?",
            description:
              "Holdings are the contents of an investment portfolio held by an individual or an entity, such as a mutual fund or a pension fund. Portfolio holdings may encompass a wide ",
          },
        ],
      };
  return (
    <>
      <DefaultSign left={7} right={5} desc={desc}>
        <PartnerOnboardingForm1 {...props} />
      </DefaultSign>
    </>
  );
}
