import { Popover } from "@material-ui/core";
import React from "react";
import "./popoverComp.css";

function PopoverComp({
  anchorEl,
  handleClose,
  data,
  open,
  customId,
  handleClickItem,
  customItemKey,
}) {
  let data2 = data?.map((val, i) => ({ ...val, id: i }));

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          borderRadius: "7px 0px 7px 7px",
        },
      }}
    >
      <div className="popoverListContainer">
        <div className="popoverList">
          {data2?.map((val) => (
            <div
              onClick={handleClickItem?.bind(null, val)}
              key={val?.id}
              className={`popoverListItem`}
            >
              <p className={`popoverListItemTxt`}>
                {val?.title ? val?.title : val[customItemKey]}
              </p>
            </div>
          ))}
        </div>
      </div>
    </Popover>
  );
}

export default PopoverComp;
