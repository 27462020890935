import { Button, CircularProgress } from "@material-ui/core";
import React from "react";
import "../NewSignUp.css";

function LoadingButton({
  showLoader,
  buttonTitle,
  customOnclick,
  ...restProp
}) {
  return (
    <Button
      {...restProp}
      onClick={customOnclick}
      fullWidth
      variant="contained"
      // color=""
      disabled={showLoader}
      className="continuieBtn generalFontFam varientH5"
    >
      {showLoader ? (
        <CircularProgress style={{ color: "#FFFFFF" }} />
      ) : (
        buttonTitle
      )}
    </Button>
  );
}

export default LoadingButton;
