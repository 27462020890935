import { Typography } from "@material-ui/core";
import React from "react";
import "./stepProgressBar.css";
import "../../NewSignUp.css";

function StepProgressBar({ currentStep, total }) {
  return (
    <div className="dFlex flexDirCol stepBarContainer alignCntr">
      <div className="stepBar">
        <div
          className="barPercentage"
          style={{ width: (100 / total) * currentStep + "%" }}
        ></div>
      </div>
      <Typography
        style={{ color: "#2d9878" }}
        className="varientH6 generalFontFam"
      >
        {currentStep}/{total} Steps
      </Typography>
    </div>
  );
}

export default StepProgressBar;
