import React, { useEffect, useState } from "react";
import PageContainer from "../PageContainer";
import Header from "../Components/Header";
import PrintTable from "../Components/Table";
import ApexNew_print from "../Components/ApexNew_print";
import chartData from "../../../charts/chartData";
import { ReactComponent as Question } from "../images/question.svg";
import PieChart from "../../../charts/PieChart";
import Print_PieChart from "../Components/Print_PieChart";
import TabCards from "../Components/TabCards";
import { roundNumber } from "../../../utils/common";
import { date } from "yup/lib/locale";
import { currencyFormatPrint as currencyFormat } from "../../../../hooks/constantFunction";
import {
  CreditRatingModel,
  PmsUnderlyimgModel,
  ProductAllocationModel,
  TaxModel,
  assetAllocationModel,
  engagmentModel,
} from "../Components/utils/staticData";
import {
  objectFormatingWithTitleCOlWrapper,
  objectFormatingProductAllocationWithTitleCOl,
  objectFormatingProductWithTitleCOlWrapper,
  objectFormatingProductCommonChartWithTitleCOl,
  objectFormatingWithTaxTitleCOl,
  objectFormatingWithPmsTitleCOl,
} from "../Components/utils/common";
import HandlePageBreakChart from "../Components/HandlePageBreakChart";
import { colors_allocation } from "../../../charts/colors";
import TaxPageBreak from "../Components/TaxPageBreak";
import PmsPageBreak from "../Components/PmsPageBreak";

export default function PmsModule({
  newdata,
  denomination,
  pdfInfo,
  setChanges,
  breadcrumbs,
  headerStyle,
  product,
  memberName,
  range,
  subtext,
  instrumentName,
  folioNo,
  isin,
  currentData,
  fundsInTransit,
}) {
  const [newPage, setNewPage] = useState([]);
  const colors = [...colors_allocation];
  const [colormapped, setColorMapped] = useState({});
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState();

  const [data1, setData1] = useState([]);

  useEffect(() => {
    let assetPieData = [];
    if (newdata?.length > 0) {
      let a = objectFormatingWithTitleCOlWrapper(
        {
          data: newdata || [],
        },
        PmsUnderlyimgModel,
        [],
        "#ADC371",
        undefined,

        undefined,
        {},
        denomination,
        "amount",
        "underlying",
        objectFormatingWithPmsTitleCOl
      );

      setNewPage(() => [a]);
    }
    setLoading(false);
  }, [newdata]);

  return (
    <div>
      <PmsPageBreak
        customData={newPage}
        loading={loading}
        total={total}
        title={"Tax"}
        instrumentName={instrumentName}
        folioNo={folioNo}
        isin={isin}
        headermodel={TaxModel}
        customHeader={
          <>
            <Header
              title={breadcrumbs ? breadcrumbs : <>Underlying Summary</>}
              title1={product}
              title2={memberName ? memberName : undefined}
              date={range}
              type={13}
              subtext={subtext ? subtext : "Direct Equity"}
              Style={headerStyle}
              breadcrumbs={breadcrumbs}
              denomination={denomination}
              reportType={"TaxSummary"}
            ></Header>
          </>
        }
        setChanges={setChanges}
        // piedata={data1}
        pdfInfo={pdfInfo}
        // colors={colors}
        // colormapped={colormapped}
      />
    </div>
  );
}
