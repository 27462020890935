import { useState } from "react";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  toggleSwitch: {
    width: 180,
    height: 25,
    borderRadius: 25,
    backgroundColor: "black",
    position: "relative",
    cursor: "pointer",
    userSelect: "none",
    border: `1.5px solid ${theme.palette.cards.switchBorder}`,
  },
  toggleSlider: {
    width: 20,
    height: 20,
    backgroundColor: "#fff",
    borderRadius: "50%",
    position: "absolute",
    transition: "left 0.2s",
    top: "3px",
  },
  label: {
    position: "absolute",
    color: "#fff",
    lineHeight: "30px",
    textAlign: "center",
    width: "100%",
    transition: "opacity 0.2s, visibility 0.2s",
    opacity: 0,
    visibility: "hidden",
  },
  labelLeft: {
    right: 10,
    transitionDelay: "0.2s",
    top: -2,
  },
  labelRight: {
    left: 10,
    transitionDelay: "0.2s",
    top: -2,
  },
  active: {
    opacity: 1,
    visibility: "visible",
    transitionDelay: "0s",
  },
}));
export const IndividualSwitch = ({ isOn, toggleSwitch }) => {
  const classes = useStyles();

  return (
    <div className={classes.toggleSwitch} onClick={toggleSwitch}>
      <div
        className={classes.toggleSlider}
        style={{ left: isOn ? "155px" : "5px" }}
      ></div>
      <span
        className={`${classes.label} ${classes.labelRight} ${
          !isOn ? classes.active : ""
        }`}
      >
        INDIVIDUAL
      </span>
      <span
        className={`${classes.label} ${classes.labelLeft} ${
          isOn ? classes.active : ""
        }`}
      >
        CONSOLIDATED
      </span>
    </div>
  );
};
