import React, { useState } from "react";
import Default from "../../../defaultPages/Default";
import PageFilter from "../../../products/common/PageFilter";
import SummaryPageTop from "../../../products/common/SummaryPageTop";
import { NewFilter } from "../../../newFilter/NewFilter";
import { useNavigate, useParams } from "react-router-dom";
import { PermissionsGate } from "../../../../auth/PermissionGate";
import ls from "localstorage-slim";
import { Typography, useTheme } from "@material-ui/core";
import useStyles from "../../../products/common/ProductsStyles";
import NewTabComp from "../../../products/common/NewTabComp";
import ReportCenterTable from "./ReportCenterTable";
import { useLocation } from "react-router-dom";
import FilterForm from "../../../../../pages/filterForm";
import ScheduleReport from "../../../../../pages/ScheduleReport";
import Reports from "./Reports";

function PirintSummary({}) {
  const classes = useStyles();
  const params = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const icons = theme.palette.icons;
  const Back = icons.backIcon;
  const myParam = new URLSearchParams(params.search).get("key");
  // console.log(myParam, "agjkagbj");
  const [page, setPage] = useState(myParam || "center");
  // const [filterModalOpen, setFilterModalOpen] = useState(false);
  const scopes = JSON.parse(ls.get("scopes"));

  let data = [
    {
      title: "Reports",
      page: "reports",
      path: "/home/reports?key=reports",
    },

    // {
    //   title: "Report",
    //   page: "report",
    //   path: "/home/reports?key=report",
    // },
  ];
  if (scopes?.can_schedule_report) {
    data.push({
      title: "Schedule Report",
      page: "schedule-report",
      path: "/home/reports?key=schedule-report",
    });
  }
  data.push({
    title: "Report Center",
    page: "report-center",
    path: "/home/reports?key=report-center",
  });
  const handleClick = (page) => {
    setPage(page);
  };

  const handleChange = (page) => {
    switch (page) {
      case "reports":
        return <FilterForm />;
      case "schedule-report":
        return <ScheduleReport />;
      case "report-center":
        return <ReportCenterTable />;
      case "report":
        return <Reports />;
      default:
        return <FilterForm />;
    }
  };

  return (
    <Default>
      <div>
        {/* <PermissionsGate scopes={[scopes?.can_view_my_portfolio_filter]}>
          <PageFilter
            backLink={`/home/portfolio`}
            setFilterModalOpen={setFilterModalOpen}
          />
        </PermissionsGate> */}

        {/* <NewFilter
          filterModalOpen={filterModalOpen}
          setFilterModalOpen={setFilterModalOpen}
          productType={pageName}
        /> */}

        <div style={{ margin: "2%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingBottom: page == "report" ? "68px" : "",
            }}
          >
            <Back
              style={{
                cursor: "pointer",
                marginRight: "12px",
                flexShrink: 0,
                display: page != "report" ? "none" : "",
              }}
              onClick={() => {
                if (page == "report") {
                  return navigate("/home/reports?key=report-center");
                }
                window.location.href = "/home/reports";
              }}
            />
            <Typography
              className={`${classes.mainHeading} ${classes.mainHeadingAlign}`}
            >
              Reports
            </Typography>
          </div>

          <div
            style={{
              marginBlock: "2%",
              display: page == "report" ? "none" : "",
            }}
          >
            <NewTabComp
              data={data}
              customSelect={data.findIndex((val) => val?.page == page)}
              customHandle={handleClick}
            />
          </div>
          <div>{handleChange(page)}</div>
        </div>
      </div>
    </Default>
  );
}

export default PirintSummary;
