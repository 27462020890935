import React, { useState, useContext, useEffect, useRef } from "react";
import useStyles from "../common/ProductsStyles";
import { Grid, Typography } from "@material-ui/core";
import { ReactComponent as Import } from "../../../../assets/icons/import.svg";
import { ReactComponent as Export } from "../../../../assets/icons/export.svg";
import { ReactComponent as Print } from "../../../../assets/icons/print.svg";
import { ReactComponent as EditColumn } from "../../../../assets/icons/edit-column.svg";
import PmsHoldingEquity from "./PmsHoldingEquity";
import PmsHoldingDebt from "./PmsHoldingDebt";
import PmsHoldingAlternate from "./PmsHoldingAlternate";
import PmsHoldingCash from "./PmsHoldingCash";
import TabPmsSubHolding from "../common/TabPmsSubHolding";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import { currencyFormat } from "../../../../hooks/constantFunction";
import { roundNumber, summarizeTableData } from "../../../utils/common";
import TabComponent from "../common/TabComponent";
import NewTabComp from "../common/NewTabComp";
import NewtableTopSec from "../../common/NewtableTopSec";
import { useClientSearch } from "../../../../hooks/useClientSearch";
import { assectClassConfigSelection } from "../common/util/common";
import { ITEMS_LIMIT_FILES_PARTNER_LIST } from "../../../../Constants";
import { useCardsApi } from "../../../../hooks/useCardsApi";
import { useCurrentHoldingCardsApi } from "../../../../hooks/useCurrentHoldingCardsApi";

const data = [
  { page: "equity", title: "Equity" },
  { page: "debt", title: "Debt" },
  { page: "alternate", title: "Alternate Assets" },
  { page: "cash", title: "Cash" },
];

function PmsHolding({
  getTabelData,
  customTableConfig,
  subHeading,
  typeOfProduct,
}) {
  const { getfilter_as_params } = useContext(mfoFilterContext);
  // let assetOrder = ["equity", "debt", "alternate", "cash"];
  // let selected_asset = getfilter_as_params().assetClass.split(",");
  // let defaultAsset = assetOrder.find((a) => {
  //   if (selected_asset.includes(a)) {
  //     return a;
  //   }
  // });
  const [page, setPage] = useState(getfilter_as_params().assetClass);
  const { holdingCardData, xirrCurrentHolding, twrrCurrentHolding } =
    useCurrentHoldingCardsApi(true, typeOfProduct);
  const [openModal, setOpenModal] = useState(false);
  const { mfoFilter } = useContext(mfoFilterContext);
  const tableConfig = customTableConfig
    ? customTableConfig
    : [
        {
          field: "schemeName",
          title: "Product Name",
          show: true,
          align: "center",
        },
        {
          field: "memberName",
          title: "Member Name",
          show: true,
          align: "center",
        },
        { field: "advisor", title: "Advisor", show: true, align: "center" },
        { field: "isin", title: "Product Code", show: true, align: "center" },
        { field: "folioNo", title: "Folio No", show: true, align: "center" },
        // { field: "assetType", title: "Asset Type", show: true },
        {
          field: "productSubCategory",
          title: "Product Sub Category",
          show: true,
          align: "center",
        },
        { field: "investmentValue", title: "Invested Value", show: true },
        { field: "withdrawalAmount", title: "Withdrawal", show: true },
        { field: "pmsCash", title: "PMS Cash", show: true },
        { field: "portfolioValue", title: "Portfolio Value", show: true },
        { field: "presentValue", title: "Present Value", show: true },
        {
          field: "avgHoldingPeriod",
          title: "Holding Period",
          show: true,
        },
        { field: "totalCharges", title: "Total Charges", show: true },
        // { field: "realisedGainLoss", title: "Realized Gain/Loss", show: false },

        {
          field: "unRealisedGainLoss",
          title: "Unrealized Gain/Loss",
          show: true,
        },
        // {
        //   field: "interestIncome",
        //   title: "Other Income",
        //   show: true,
        // },
        { field: "totalGainLoss", title: "Total Gain/Loss", show: true },
        { field: "xirr", title: "XIRR (%)", show: true },
        { field: "absoluteTwrr", title: "Absolute TWRR (%)", show: true },
        { field: "annualisedTwrr", title: "Annualized TWRR (%)", show: true },
      ];
  const [tableHeader, setTableHeader] = useState(tableConfig);
  const [loading, setLoading] = useState(false);
  const [checkedTrans, setCheckedTrans] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [secondTableColumns, setSecondTableColumns] = useState([]);
  const [search, setSearch] = useState("");
  const { searchResults } = useClientSearch(search, tableColumns);
  const [pagiNationData, setPageinationData] = useState({
    pageCount: null,
    // limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
  });

  const numberOfInstrumentsCount = useRef(0);
  const [numberOfInstruments, setNumberOfInstruments] = useState(0);
  const [percentageOfTotalPortfolio, setPercentageOfTotalPortfolio] =
    useState(0);

  const [offset, setOffset] = useState(0);

  const objectFormating = (data) => {
    let newData = tableHeader.map((header) => {
      if (header?.field == "annualisedTwrr") {
        return {
          key: header?.field,
          field: roundNumber(data[`${header.field}`]) || "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }

      if (
        header?.field == "investmentValue" ||
        header?.field == "presentValue" ||
        header?.field == "totalGainLoss" ||
        header?.field == "withdrawalAmount" ||
        header?.field == "realisedGainLoss" ||
        header?.field == "interestIncome" ||
        header?.field == "unRealisedGainLoss" ||
        header?.field == "pmsCash" ||
        header?.field == "portfolioValue" ||
        header?.field == "totalCharges"
      ) {
        return {
          key: header?.field,
          field: currencyFormat(data[`${header.field}`] || 0) || 0,
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (header?.field == "isin" || header?.field == "folioNo") {
        return {
          key: header?.field,
          field: data[`${header.field}`],
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (header?.field == "avgHoldingPeriod") {
        return {
          key: header?.field,
          field: !isNaN(data[`${header.field}`])
            ? Number(data[`${header.field}`]) + " Days"
            : data[`${header.field}`] || "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      return {
        key: header?.field,
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`]))
          : data[`${header.field}`] || "--",
        title: header.title,
        show: header.show,
        align: header?.align,
      };
    });
    return newData;
  };

  const handleIconClick = () => {
    setOpenModal(!openModal);
  };

  useEffect(() => {
    numberOfInstrumentsCount.current = 0;
  }, [page, mfoFilter]);

  useEffect(() => {
    // changePage(page);
    getTabelData(
      setTableColumns,
      "summary/holding",
      {
        clientId: 4,
        assetClass: page,
        search: search,
      },
      setLoading,
      objectFormating,
      setCheckedTrans,
      tableHeader,
      ITEMS_LIMIT_FILES_PARTNER_LIST,
      offset,
      setPageinationData,
      pagiNationData,
      numberOfInstrumentsCount,
      setPercentageOfTotalPortfolio,
      setNumberOfInstruments
    );
  }, [page, mfoFilter, offset, search]);

  useEffect(() => {
    setSecondTableColumns(
      summarizeTableData(
        tableColumns,
        holdingCardData,
        xirrCurrentHolding,
        twrrCurrentHolding,
        numberOfInstruments,
        "",
        "",
        "",
        percentageOfTotalPortfolio
      )
    );
  }, [tableColumns, holdingCardData, xirrCurrentHolding, twrrCurrentHolding]);

  // console.log(offset,'aglnagbj');

  const changePage = (p) => {
    switch (p) {
      case "equity":
        return (
          <PmsHoldingEquity
            tableConfig={tableConfig}
            getTabelData={getTabelData}
            openModal={openModal}
            tableName={page}
            setOpenModal={setOpenModal}
            objectFormating={objectFormating}
            setTableHeader={setTableHeader}
            tableHeader={tableHeader}
            mfoFilter={mfoFilter}
            tableColumns={tableColumns}
            setTableColumns={setTableColumns}
            setSecondTableColumns={setSecondTableColumns}
            tableColumns2={secondTableColumns}
            loading={loading}
            pageCount={pagiNationData?.pageCount}
            setOffset={setOffset}
            pageNumber={offset / ITEMS_LIMIT_FILES_PARTNER_LIST + 1 || 1}
          />
        );
      case "debt":
        return (
          <PmsHoldingDebt
            tableName={page}
            tableConfig={tableConfig}
            getTabelData={getTabelData}
            openModal={openModal}
            setOpenModal={setOpenModal}
            objectFormating={objectFormating}
            setTableHeader={setTableHeader}
            tableHeader={tableHeader}
            mfoFilter={mfoFilter}
            tableColumns={tableColumns}
            setTableColumns={setTableColumns}
            setSecondTableColumns={setSecondTableColumns}
            tableColumns2={secondTableColumns}
            loading={loading}
            pageCount={pagiNationData?.pageCount}
            setOffset={setOffset}
            pageNumber={offset / ITEMS_LIMIT_FILES_PARTNER_LIST + 1 || 1}
          />
        );

      case "alternate":
        return (
          <PmsHoldingAlternate
            tableName={page}
            tableConfig={tableConfig}
            getTabelData={getTabelData}
            openModal={openModal}
            setOpenModal={setOpenModal}
            objectFormating={objectFormating}
            setTableHeader={setTableHeader}
            tableHeader={tableHeader}
            mfoFilter={mfoFilter}
            tableColumns={tableColumns}
            setTableColumns={setTableColumns}
            setSecondTableColumns={setSecondTableColumns}
            tableColumns2={secondTableColumns}
            loading={loading}
            pageCount={pagiNationData?.pageCount}
            setOffset={setOffset}
            pageNumber={offset / ITEMS_LIMIT_FILES_PARTNER_LIST + 1 || 1}
          />
        );

      case "cash":
        return (
          <PmsHoldingCash
            tableName={page}
            tableConfig={tableConfig}
            getTabelData={getTabelData}
            openModal={openModal}
            setOpenModal={setOpenModal}
            objectFormating={objectFormating}
            setTableHeader={setTableHeader}
            tableHeader={tableHeader}
            mfoFilter={mfoFilter}
            tableColumns={tableColumns}
            setTableColumns={setTableColumns}
            setSecondTableColumns={setSecondTableColumns}
            tableColumns2={secondTableColumns}
            loading={loading}
            pageCount={pagiNationData?.pageCount}
            setOffset={setOffset}
            pageNumber={offset / ITEMS_LIMIT_FILES_PARTNER_LIST + 1 || 1}
          />
        );

      default:
        return (
          <PmsHoldingEquity
            tableName={page}
            tableConfig={tableConfig}
            getTabelData={getTabelData}
            openModal={openModal}
            setOpenModal={setOpenModal}
            objectFormating={objectFormating}
            setTableHeader={setTableHeader}
            tableHeader={tableHeader}
            mfoFilter={mfoFilter}
            tableColumns={tableColumns}
            setTableColumns={setTableColumns}
            setSecondTableColumns={setSecondTableColumns}
            tableColumns2={secondTableColumns}
            loading={loading}
            pageCount={pagiNationData?.pageCount}
            setOffset={setOffset}
            pageNumber={offset / ITEMS_LIMIT_FILES_PARTNER_LIST + 1 || 1}
          />
        );
    }
  };

  const handleClick = React.useCallback(
    (page) => {
      //console.log(page, "page");
      setPage(page);
      setPageinationData((prev) => ({ ...prev, pageCount: 0 }));
      setOffset(0);
    },
    [page]
  );

  // console.log(pagiNationData, "asgnagk");

  return (
    <div>
      <NewtableTopSec
        handleIconClick={handleIconClick}
        directPageName={"Holding Summary"}
        setSearch={(e) => {
          setOffset(0);
          setSearch(e);
        }}
        product={subHeading || "PMS"}
      />
      <div style={{ margin: "2%" }}>
        <NewTabComp
          data={
            assectClassConfigSelection({
              assetClassList: getfilter_as_params().assetClass,
              data: data,
            })
            // getfilter_as_params().assetClass
            //   ? data.filter((a) => {
            //       console.log(a.page, "aibvpaibsvaso");
            //       if (getfilter_as_params().assetClass.indexOf(a.page) >= 0) {
            //         return a;
            //       }
            //     })
            //   : data
          }
          // customSelect={page.split(",")?.length > 1 ? 0 : undefined}
          customWidth={"100%"}
          customHandle={handleClick}
        />
      </div>

      {changePage(page)}
    </div>
  );
}

export default PmsHolding;
