const align = "center";

export const reitsHoldingConfig = [
  { field: "scripName", title: "Security Name", show: true, align: align },
  { field: "memberName", title: "Member Name", show: true, align: align },
  { field: "advisor", title: "Advisor", show: true, align: align },
  {
    field: "isin",
    title: "ISIN No/Product Code",
    show: true,
    align: align,
  },
  { field: "holdingUnits", title: "Quantity", show: true, align: align },
  // { field: "pan", title: "Pan No", show: false, align: align },
  {
    field: "investmentValue",
    title: "Investment Value",
    show: true,
    align: align,
  },
  {
    field: "currentPrice",
    title: "Current Price (Actuals)",
    show: true,
    align: align,
  },
  { field: "presentValue", title: "Current Value", show: true, align: align },

  // { field: "exchange", title: "Exchange", show: false, align: align },
  // { field: "stockSymbol", title: "Stock Symbol", show: false, align: align },

  // {
  //   field: "transactionDate",
  //   title: "Transaction Date",
  //   show: true,
  //   align: align,
  // },
  // {
  //   field: "transaction_type",
  //   title: "Transaction Type",
  //   show: true,
  //   align: align,
  // },

  // {
  //   field: "grossRatePerUnit",
  //   title: "Gross Rate Per Unit (Actuals)",
  //   show: false,
  //   align: align,
  // },
  // {
  //   field: "brokeragePerUnit",
  //   title: "Brokerage Per Unit",
  //   show: false,
  //   align: align,
  // },
  // {
  //   field: "gstSstOtherCharges",
  //   title: "GST+SST+Other Charges (Actuals)",
  //   show: false,
  //   align: align,
  // },
  // {
  //   field: "netRatePerUnit",
  //   title: "Net Rate Per Unit (Actuals)",
  //   show: false,
  //   align: align,
  // },
  // {
  //   field: "totalTransactionValue",
  //   title: "Total Transaction Value (Actuals)",
  //   show: false,
  //   align: align,
  // },
  {
    field: "percentageOfTotalPortfolio",
    title: "% of Total Portfolio",
    show: true,
    align: align,
  },
  {
    field: "unRealisedGainLoss",
    title: "Unrealized Gain/Loss",
    show: true,
  },
  // {
  //   field: "realisedGainLoss",
  //   title: "Realized Gain/Loss",
  //   show: false,
  // },

  {
    field: "interestIncome",
    title: "Other Income",
    show: true,
  },
  { field: "totalGainLoss", title: "Total Gain/Loss", show: true },
  // {
  //   field: "absoluteReturn",
  //   title: "Absolute Return",
  //   show: false,
  // },
  // {
  //   field: "dividendReceived",
  //   title: "Income Distribution Received",
  //   show: true,
  // },
  { field: "xirr", title: "XIRR (%)", show: true },
  {
    field: "absoluteTwrr",
    title: "Absolute TWRR (%)",
    show: true,
  },
  {
    field: "annualisedTwrr",
    title: "Annualized TWRR (%)",
    show: true,
  },
];

// Config for INVITS
export const invitsHoldingConfig = [
  { field: "scripName", title: "Security Name", show: true, align: align },
  { field: "memberName", title: "Member Name", show: true, align: align },
  { field: "advisor", title: "Advisor", show: true, align: align },
  {
    field: "isin",
    title: "ISIN/NO Product Code",
    show: true,
    align: align,
  },
  { field: "holdingUnits", title: "Quantity", show: true, align: align },
  // { field: "pan", title: "Pan No", show: false, align: align },

  {
    field: "investmentValue",
    title: "Investment Value",
    show: true,
    align: align,
  },
  {
    field: "currentPrice",
    title: "Current Price (Actuals)",
    show: true,
    align: align,
  },
  { field: "presentValue", title: "Current Value", show: true, align: align },

  // { field: "exchange", title: "Exchange", show: false, align: align },
  // { field: "stockSymbol", title: "Stock Symbol", show: false, align: align },

  // {
  //   field: "transactionDate",
  //   title: "Transaction Date",
  //   show: true,
  //   align: align,
  // },
  // {
  //   field: "transaction_type",
  //   title: "Transaction Type",
  //   show: true,
  //   align: align,
  // },

  // {
  //   field: "grossRatePerUnit",
  //   title: "Gross Rate Per Unit (Actuals)",
  //   show: false,
  //   align: align,
  // },
  // {
  //   field: "brokeragePerUnit",
  //   title: "Brokerage Per Unit",
  //   show: false,
  //   align: align,
  // },
  // {
  //   field: "gstSstOtherCharges",
  //   title: "GST+SST+Other Charges (Actuals)",
  //   show: false,
  //   align: align,
  // },
  // {
  //   field: "netRatePerUnit",
  //   title: "Net Rate Per Unit (Actuals)",
  //   show: false,
  //   align: align,
  // },
  // {
  //   field: "totalTransactionValue",
  //   title: "Total Transaction Value (Actuals)",
  //   show: false,
  //   align: align,
  // },
  {
    field: "percentageOfTotalPortfolio",
    title: "% of Total Portfolio",
    show: true,
    align: align,
  },
  {
    field: "unRealisedGainLoss",
    title: "Unrealized Gain/Loss",
    show: true,
  },
  // {
  //   field: "realisedGainLoss",
  //   title: "Realized Gain/Loss",
  //   show: false,
  // },
  {
    field: "interestIncome",
    title: "Other Income",
    show: true,
  },
  { field: "totalGainLoss", title: "Total Gain/Loss", show: true },
  // {
  //   field: "absoluteReturn",
  //   title: "Absolute Return",
  //   show: false,
  // },
  // {
  //   field: "dividendReceived",
  //   title: "Income Distribution Received",
  //   show: true,
  // },
  { field: "xirr", title: "XIRR (%)", show: true },
  {
    field: "absoluteTwrr",
    title: "Absolute TWRR (%)",
    show: true,
  },
  {
    field: "annualisedTwrr",
    title: "Annualized TWRR (%)",
    show: true,
  },
];

export const bankbookConfig = [
  { field: "bank_name", title: "BANK NAME", show: true, align: align },
  { field: "account_no", title: "ACCOUNT NO", show: true, align: align },
  { field: "account_type", title: "ACCOUNT TYPE", show: true, align: align },
  {
    field: "balance",
    title: "BALANCE",
    show: true,
    align: align,
  },
  {
    field: "allocation_percentage",
    title: "% ALLOCATION",
    show: true,
    align: align,
  },
];

export const reitsTransactionConfig = [
  { field: "scrip_name", title: "Script Name", show: true, align: align },
  { field: "advisor", title: "Advised by", show: true, align: align },
  { field: "executed_by", title: "Executed By", show: true, align: align },
  { field: "pan", title: "Pan No", show: true, align: align },
  {
    field: "isin",
    title: "ISIN/NO Product Code",
    show: true,
    align: align,
  },
  { field: "exchange", title: "Exchange", show: true, align: align },
  { field: "stock_symbol", title: "Stock Symbol", show: true, align: align },
  {
    field: "transaction_date",
    title: "Transaction Date",
    show: true,
    align: align,
  },
  {
    field: "transaction_type",
    title: "Transaction Type",
    show: true,
    align: align,
  },
  { field: "quantity", title: "Quantity", show: true, align: align },
  {
    field: "gross_rate_per_unit",
    title: "Gross Rate Per Unit (Actuals)",
    show: true,
    align: align,
  },
  {
    field: "brokerage_per_unit",
    title: "Brokerage Per Unit (Actuals)",
    show: true,
    align: align,
  },
  {
    field: "gst_sst_other_charges",
    title: "GST+STT+Other Charges (Actuals)",
    show: true,
    align: align,
  },
  {
    field: "net_rate_per_unit",
    title: "Net Rate Per Unit (Actuals)",
    show: true,
    align: align,
  },
  {
    field: "total_transaction_value",
    title: "Total Transaction Value (Actuals)",
    show: true,
    align: align,
  },
  {
    field: "cumulative_quantity",
    title: "Cumulative Quantity",
    show: true,
    align: align,
  },
];

export const reitsTRCorporateActionsTableConfig = [
  {
    field: "scrip_name",
    title: "Script Name",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "advisor",
    title: "Advised by",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "executed_by",
    title: "Executed By",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "pan",
    title: "Pan No",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "isin",
    title: "ISIN/NO Product Code",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "exchange",
    title: "Exchange",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "stock_symbol",
    title: "Stock Symbol",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "transaction_date",
    title: "Transaction Date",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "transaction_type",
    title: "Transaction Type",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "corp_action",
    title: "Corporate Action",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "corp_action_effects",
    title: "Effects",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "corporate_action_value",
    title: "Value (Actuals)",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "ratio",
    title: "Ratio",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "quantity",
    title: "Quantity",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "gross_rate_per_unit",
    title: "Gross Rate Per Unit (Actuals)",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "brokerage_per_unit",
    title: "Brokerage Per Unit (Actuals)",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "gst_sst_other_charges",
    title: "GST+STT+Other Charges (Actuals)",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "net_rate_per_unit",
    title: "Net Rate Per Unit (Actuals)",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "total_transaction_value",
    title: "Total Transaction Value (Actuals)",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "remarks",
    title: "Remarks",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "cumulative_quantity",
    title: "Cumulative Quantity",
    show: true,
    align: align,
  },
];
