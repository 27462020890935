import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PmsReportTable from "../pms/PmsReportTable";
import FinxedIcomeSection from "./utils/FinxedIcomeSection";
import FundPortfolioCard from "./utils/FundPortfolioCard";
import MarketCapPieChart from "./utils/MarketCapPieChart";
import SectorWiseHolding from "./utils/SectorWiseHolding";
import useStyles from "../common/ProductsStyles";
import {
  creditRatingConfig,
  creditRatingPieChartConfig,
  creditRatingStaticData,
  EquityTableConfig,
  holdingDetailsConfigChart,
  holdingDetailsConfigTable,
} from "./utils/constands";
import PieChart from "../../../charts/PieChart";
import { pie_onClick3 } from "../../../charts/nivo_objects";
import { dayFormatter2, roundNumber } from "../../../utils/common";
import CardInvestment from "./utils/CardInvestment";
import FundDetailsSection from "./utils/FundDetailsSection";
import { getTableAndPieChartData } from "./utils/common";
import CardWithPieChartAndTable from "./utils/CardWithPieChartAndTable";
import HashlinkSupport from "./utils/HashlinkSupport";

function RiskDetailsDebt({
  riskDetails,
  marketCapColors,
  marketCapState,
  setMarketCapState,
  assetProgressBarColors,
  asset_color,
  disabledassetProgressBarColors,
  setAsset_color,
  handleMouseMove,
  tableColumns,
  setTableColumns,
  tableHeader,
  setTableHeader,
  setSectorWiseState,
  sectorWiseState,
  sectorWiseColor,
  sectorAllocation,
}) {
  const classes = useStyles();

  const [portfolioPieChart, setPortfolioPieChart] = useState([
    {
      id: "bond",
      label: "Bond",
      value: 0,
      color: "#509FF1",
    },
    {
      id: "cash",
      label: "Cash",
      value: 0,
      color: "#00C1CE",
    },
    {
      id: "equity",
      label: "Equity",
      value: 0,
      color: "#E96114",
    },
  ]);

  const [topHoldingData, setTopHoldingData] = useState({
    tableData: [],
    pieChartData: [],
  });
  const [creditRating, setCreditRating] = useState({
    tableData: [],
    pieChartData: [],
  });

  const objectFormating = (data, headerConfig) => {
    let newData = headerConfig?.map((header) => {
      // if (header?.field == "Name") {
      //   return {
      //     field: data[`${header.field}`],
      //     title: header.title,
      //     show: header.show,
      //     align: header?.align,
      //   };
      // }
      return {
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`]))
          : data[`${header.field}`] || "--",
        title: header.title,
        show: header.show,
        align: header?.align,
      };
    });

    return newData;
  };

  const handleTopHoldingData = (data) => {
    let res = getTableAndPieChartData({
      data: data,
      field: "holdingDetails",
      tableConfig: holdingDetailsConfigTable,
      tableDataFormatter: objectFormating,
      pieChartConfig: holdingDetailsConfigChart,
    });

    setTopHoldingData((prev) => {
      return {
        tableData: res?.tableData,
        pieChartData: [],
      };
    });
  };

  const handleCreditRatingData = (data) => {
    if (data) {
      let newData = Object.keys(data)?.map((val) => ({
        id: val,
        label: val,
        value: data?.[val],
      }));

      let res = getTableAndPieChartData({
        data: { data: newData },
        field: "data",
        tableConfig: creditRatingConfig,
        tableDataFormatter: objectFormating,
        pieChartConfig: creditRatingPieChartConfig,
      });

      setCreditRating(res);
      setTableColumns(res.tableData);
      setTableHeader(creditRatingConfig);
    } else {
      setTableColumns([]);
      setTableHeader(creditRatingConfig);
    }
  };

  useEffect(() => {
    setPortfolioPieChart((prev) => {
      let newList = [];
      prev.map((val) => {
        val.value = riskDetails.assetAllocation[val?.id];
        newList.push(val);
      });
      return newList;
    });
    handleTopHoldingData(riskDetails);
    // if (riskDetails?.creditRating) {
    handleCreditRatingData(riskDetails?.creditRating);
    // }
  }, []);

  return (
    <>
      <FinxedIcomeSection riskDetails={riskDetails} />

      <HashlinkSupport id={"portfolioAllocation"} />

      <Typography
        style={{ marginLeft: "3%" }}
        className={`${classes.riskHeaderSub} ${classes.typoInsideAlign}`}
        id="portfolioAllocation"
      >
        Portfolio Allocation
      </Typography>
      <div style={{ display: "flex", margin: "2%" }}>
        <Grid container spacing={3}>
          <Grid item md={5} sm={12}>
            <div
              className={classes.mainContent}
              style={{
                display: "flex",
                margin: "0% 0%",
                alignItems: "flex-start",
                height: "475px",
                // justifyContent: "center",
                justifyContent: "flex-start",
                flexDirection: "column",
              }}
            >
              <Typography
                style={{ marginLeft: "2%" }}
                className={`${classes.riskHeaderSub} ${classes.typoInsideAlign}`}
                id="fundPortfolio"
              >
                Asset Allocation
              </Typography>
              <div
                style={{
                  display: "block",
                  margin: "0%",
                  marginTop: "auto",
                  marginBottom: "auto",
                  // height: "422px",
                  width: "527px",
                }}
              >
                <PieChart
                  config={{
                    innerRadius: "0.70",
                    padAngle: 2,
                    legends: false,
                    disableArcLink: true,
                    minWith: "100%",
                    // arcLinkLabel: (d) => `${d.value}%`,
                    // margin: { right: 80, top: 20, left: 80 },
                    height: "300px",
                    chart: { height: 385 },
                  }}
                  colors={portfolioPieChart.map((val) => val?.color)}
                  onClickEvent={{
                    click: pie_onClick3,
                    set: setSectorWiseState,
                    cstate: sectorWiseState,
                    ncolors: sectorWiseColor,
                  }}
                  data={portfolioPieChart}
                >
                  <div className="cen1" style={{ color: "#fff" }}>
                    <div className="cir">
                      <div className="glowcircle glowcircle2">
                        <div className="circleInnerText">
                          <p className="innerCircleTitle">
                            {sectorWiseState.title}
                          </p>
                          <p className="innerCircleValue">
                            {sectorWiseState?.value &&
                              roundNumber(sectorWiseState?.value) + "%"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </PieChart>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    marginBlock: "23px",
                  }}
                >
                  {portfolioPieChart.map((m, i) => {
                    return (
                      <div className="llabeContainer">
                        <div className="lsymbol">
                          <svg height="25" width="25">
                            <circle
                              cx="12"
                              cy="12"
                              r="5"
                              stroke-width="3"
                              fill={m?.color}
                            />
                            Sorry, your browser does not support inline SVG.
                          </svg>
                        </div>
                        <div className="llabel">
                          <span className="labelText sector-wise-holding">
                            {m?.label}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={7} sm={12}>
            <div
              style={{
                maxHeight: "475px",
                display: "flex",
                //   width: "97%",
                margin: "0% 0%",
                height: "100%",
                alignItems: "flex-start",
                // height: "475px",
                // justifyContent: "center",
                justifyContent: "flex-start",
                flexDirection: "column",
              }}
              className={classes.mainContent}
            >
              <Typography
                style={{ marginLeft: "2%" }}
                className={`${classes.riskHeaderSub} ${classes.typoInsideAlign}`}
                id="fundPortfolio"
              >
                Credit Rating &nbsp;
                <Typography
                  style={{
                    fontSize: "16px",
                    color: "#9488A7",
                    fontWeight: "500",
                  }}
                  className={`${classes.riskHeaderSub}`}
                  // id="fundPortfolio"
                >
                  {riskDetails?.creditRatingDate
                    ? " (As on Date " +
                      dayFormatter2(riskDetails?.creditRatingDate) +
                      ")"
                    : ""}
                </Typography>
              </Typography>

              <div style={{ width: "-webkit-fill-available" }}>
                <PmsReportTable
                  tableMaxHeight={"393px"}
                  tableColumns={tableColumns}
                  setTableColumns={setTableColumns}
                  headerList={creditRatingConfig}
                  tableHeader={tableHeader}
                  setTableHeader={setTableHeader}
                  tableBodyBackground={"transparent"}
                  tableHeaderBGColor={"#1D1B36"}
                  firstCollFullWidth={true}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <CardWithPieChartAndTable
        sectorWiseColor={sectorWiseColor}
        setSectorWiseState={setSectorWiseState}
        sectorWiseState={sectorWiseState}
        id={"topHoldings"}
        title="Top Holdings"
        data={topHoldingData}
        tableConfig={holdingDetailsConfigTable}
      />
      <div style={{ margin: "2%" }}>
        <CardInvestment riskDetails={riskDetails} />
      </div>
      <div style={{ margin: "2%" }}>
        <FundDetailsSection riskDetails={riskDetails} />
      </div>
    </>
  );
}

export default RiskDetailsDebt;
