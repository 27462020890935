import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/*
 * Helper component that starts the page from top
 * instead of starting the page from previous scroll position,
 * whenever user navigates to different page
 */
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

export default ScrollToTop;
