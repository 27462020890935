import React, { useState, useEffect, useContext } from "react";
import useStyles from "../common/ProductsStyles";
import { Grid, Typography } from "@material-ui/core";
import { ReactComponent as Import } from "../../../../assets/icons/import.svg";
import { ReactComponent as Export } from "../../../../assets/icons/export.svg";
import { ReactComponent as Print } from "../../../../assets/icons/print.svg";
import { ReactComponent as EditColumn } from "../../../../assets/icons/edit-column.svg";
// import TabPmsSubTransaction from "../common/TabPmsSubTransaction";
import PmsReportTable from "../pms/PmsReportTable";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import moment from "moment";
import { currencyFormat } from "../../../../hooks/constantFunction";
import PageLoader from "../common/pageLoader";
import { ReactComponent as Back } from "../../../../assets/holding-summary/back-blue.svg";
import { useNavigate } from "react-router-dom";

//need confirmatioon to remove these unused components,
//these components are replaced with PmsReportTable component
// import PmsTransactionAll from "./PmsTransactionAll";
// import PmsTransactionPurchase from "./PmsTransactionPurchase";
// import PmsTransactionRedemption from "./PmsTransactionRedemption";
import { dateFormatCommon, roundNumber } from "../../../utils/common";
import NewtableTopSec from "../../common/NewtableTopSec";
import { ITEMS_LIMIT_FILES_PARTNER_LIST } from "../../../../Constants";

const data = [
  { page: "equity", title: "Equity" },
  { page: "debt", title: "Debt" },
  { page: "alternate", title: "Alternate Assets" },
  { page: "cash", title: "Cash" },
];

function MLDTransaction({
  getTabelData,
  customTableConfig,
  subHeading,
  backUrl,
  headerInTwoLine,
  iosSwitch,
  showCorpActions,
  hideSearch,
  pageCount,
}) {
  const { getfilter_as_params } = useContext(mfoFilterContext);
  const classes = useStyles();
  let assetOrder = ["equity", "debt", "alternate", "cash"];
  let selected_asset = getfilter_as_params().assetClass.split(",");
  let defaultAsset = assetOrder.find((a) => {
    if (selected_asset.includes(a)) {
      return a;
    }
  });
  const [page, setPage] = useState(defaultAsset);
  const [tableColumns, setTableColumns] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { mfoFilter } = useContext(mfoFilterContext);
  const [reset, setReset] = useState();
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);
  const [pagiNationData, setPageinationData] = useState({
    pageCount: null,
    // limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
  });
  console.log("MLDTransaction", pagiNationData);
  const tableConfig = customTableConfig
    ? customTableConfig
    : [
        {
          field: "scheme_name",
          title: "Product Name",
          show: true,
          align: "center",
        },
        { field: "advisor", title: "Advisor", show: true, align: "center" },
        { field: "isin", title: "Product Code", show: true, align: "center" },
        { field: "folio_no", title: "Folio No", show: true, align: "center" },
        // { field: "asset_type", title: "Asset Type", show: true },
        {
          field: "product_sub_category",
          title: "Product Sub Category",
          show: true,
          align: "center",
        },
        {
          field: "transaction_date",
          title: "Transaction date",
          show: true,
          align: "center",
        },
        {
          field: "transaction_type",
          title: "Transaction Type",
          show: true,
          align: "center",
        },
        {
          field: "holdingPeriod",
          title: "Holding Period",
          show: true,
        },
        { field: "amount", title: "Amount", show: true },
        { field: "load_charges", title: "Load Charged", show: true },
        { field: "dividend", title: "Dividend", show: true },
        { field: "interest", title: "Interest", show: true },

        { field: "other_income", title: "Other Income", show: true },
        { field: "management_fees", title: "Management Fees", show: true },
        {
          field: "securities_transaction_tax",
          title: "Security Transaction Tax",
          show: true,
        },
        { field: "other_expense", title: "Other Expense", show: true },
      ];
  const [tableHeader, setTableHeader] = useState(tableConfig);
  const [checkedTrans, setCheckedTrans] = useState([]);

  const handleIconClick = () => {
    setOpenModal(!openModal);
  };

  const objectFormating = (data) => {
    let newData = tableHeader.map((header) => {
      if (header?.field == "transaction_date") {
        return {
          field: dateFormatCommon(new Date(data[`${header.field}`])),
          // moment(new Date(data[`${header.field}`])).format(
          //   "DD - MM - YYYY"
          // ) || "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (header?.field == "isin" || header?.field == "folio_no") {
        return {
          field: data[`${header.field}`],
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (
        header?.field == "amount" ||
        header?.field == "load_charges" ||
        header?.field == "dividend" ||
        header?.field == "interest" ||
        header?.field == "other_income" ||
        header?.field == "management_fees" ||
        header?.field == "securities_transaction_tax" ||
        header?.field == "other_expense" ||
        header?.field == "total_transaction_value" ||
        header?.field == "accrual_interest_paid" ||
        header?.field == "invested_amount"
      ) {
        return {
          field: currencyFormat(data[`${header.field}`] || 0) || "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (header?.field == "holdingPeriod") {
        return {
          field: data[`${header.field}`]
            ? data[`${header.field}`] + " days"
            : "--",
          // moment(new Date(data[`${header.field}`])).format(
          //   "DD - MM - YYYY"
          // ) || "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }

      return {
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`]))
          : data[`${header.field}`] || "--",
        title: header.title,
        show: header.show,
        align: header?.align,
      };
    });
    return newData;
  };

  useEffect(() => {
    setTableHeader(() => tableConfig);
  }, [tableConfig]);

  useEffect(() => {
    if (tableHeader) {
      getTabelData(
        setTableColumns,
        "transaction-summary",
        {
          clientId: 4,
          memberId: 2,
          startDate: "2022-05-01",
          endDate: "2022-05-03",
          assetClass: page?.toLocaleLowerCase(),
          schemeName: search,
        },
        setLoading,
        objectFormating,
        setCheckedTrans,
        tableConfig,
        ITEMS_LIMIT_FILES_PARTNER_LIST,
        offset,
        setPageinationData
      );
    }
  }, [page, mfoFilter, search, showCorpActions, , offset]);
  return (
    <>
      <NewtableTopSec
        handleIconClick={handleIconClick}
        directPageName={"Transaction Summary"}
        setSearch={
          !hideSearch &&
          ((e) => {
            setOffset(0);
            setSearch(e);
          })
        }
        product={subHeading ? subHeading : "MLD"}
        iosSwitch={iosSwitch}
      />

      <PmsReportTable
        searchParam={search}
        setTableColumns={setTableColumns}
        tableColumns={tableColumns}
        setOpenModal={setOpenModal}
        openModal={openModal}
        //   tableName={page}
        loading={loading}
        pageName="trasaction"
        headerList={tableConfig}
        tableHeader={tableHeader}
        setTableHeader={setTableHeader}
        stickyProductName={true}
        checkedTransList={checkedTrans}
        setReset={setReset}
        headerInTwoLine={headerInTwoLine}
        corporateActionsLabel={
          showCorpActions ? (
            <div
              style={{
                position: "absolute",
                top: "-83px",
                left: "3px",
                // zIndex: "99",
              }}
            >
              <div id="corporateActions">Corporate Actions</div>
            </div>
          ) : (
            <></>
          )
        }
        setItemOffset={setOffset}
        pageCount={pagiNationData?.pageCount}
        pageNumber={offset / ITEMS_LIMIT_FILES_PARTNER_LIST + 1 || 1}
      />
      {/* </div> */}
      <PageLoader showLoader={loading} />
    </>
  );
}

export default MLDTransaction;
