import {
  Button,
  Dialog,
  Grid,
  Modal,
  Radio,
  Typography,
  makeStyles,
} from "@material-ui/core";
import moment from "moment";
import React, { useRef } from "react";
import { useState } from "react";
// import useStyles from "../products/common/ProductsStyles";
import { ReactComponent as Filter } from "../../../assets/icons/filter.svg";
import { ReactComponent as Sidearrow } from "../../../assets/icons/sidearrow.svg";
import { ReactComponent as Closeicon } from "../../../assets/icons/closeicon.svg";
import SearchInput from "../products/common/SearchInput";
import CheckBoxWithColor from "../products/common/CheckBoxWithColor";
import { ReactComponent as Search } from "../../../assets/icons/search.svg";
// import LineChart from "../../charts/LineChart";

import Loader from "../../loader/Loader";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { dateFormatCommon, roundNumber } from "../../utils/common";
import FilterCalendar from "../../calendar/FilterCalendar";
import {
  fetchAdvisor,
  getMfFundPerformance,
  get_index_performance,
  get_index_subclass,
} from "../../../api";
import { useContext } from "react";
import { mfoFilterContext } from "../../../mfo_context/Mfo_Context";
import { OnboardingContext } from "../../../../src/Context";
import { ReactComponent as Eye } from "../../../assets/icons/white-eye.svg";
import { lazy } from "react";
import { Suspense } from "react";
// import ApexLine from "../../charts/ApexLine";
import ApexNew from "../../charts/ApexNew";
import DataNotFound from "../products/common/DataNotFound";
import GraphIconHeader from "./GraphIconHeader";
import NewHeadAndDetailsComp from "../products/newDesigns/common/newHeadingAndDetailsComp/NewHeadAndDetailsComp";

import { get_path_type, assetclass } from "../../utils/strHelper";
import { selection } from "d3-selection";
import { CheckBox } from "@material-ui/icons";
import { useDebounce } from "../../../../src/components/dashboard/products/common/util/useDebounce";
import Checkboxicon from "../strategyBuilder/common/checkboxicon";

const LineChart = lazy(() => import("../../charts/LineChart"));
export default function PortfolioChart(props) {
  const useStyles = makeStyles((theme) => ({
    backDrop: {
      backdropFilter: "blur(5px)",
      background: "rgba(31, 28, 64, 0.51)",
    },
  }));
  const [checkedChartIndex, setCheckedChartIndex] = useState({
    "Portfolio Value": true,
    TWRR: false,
  });
  let path = get_path_type(window.location.pathname);
  const [checkedindex, setCheckedIndex] = useState({});
  const wrapperRef = useRef(null);
  const classes = useStyles();
  const [display, setDisplay] = useState(false);
  const [clear, setclear] = useState(false);
  const [search, setSearch] = useState("");
  const [subAssetClass, setsubAssetClass] = useState("");
  const [range, setRange] = useState("ITD");
  const [customRange, setCustomRange] = useState([null, null]);
  const [filteredData, setFilteredData] = useState([]); // filtered data for graph based on google contol range
  const [filterRange, setFilterRange] = useState([]);
  const [checkadvisorListed, setCheckAdvisorListed] = useState({});
  const [dataRange, setDataRange] = useState({
    // endDate: moment().format("YYYY-MM-DD"),
    // startDate: moment().subtract(5, "years").format("YYYY-MM-DD"),
  });
  const [loading2, setLoading2] = useState(false);
  const [googleData, setgoogleData] = useState([]);
  const [ticks, setTicks] = useState([]);
  const [linedata, setlinedata] = useState([]);
  const [indexPerformance, setIndexPerformance] = useState([]);
  const [min, setMin] = useState(0);
  const [list, setlist] = useState([]);
  const [schIndexlist, setSchIndexList] = useState([]);
  const navigate = useNavigate();
  const [xval, setxval] = useState([]);
  const colors = ["#F52D6A", "#82EBA1", "#FF9446"];
  const [format, setFormat] = useState("DD-MMM-YYYY");
  const assetlist =
    path == "portfolio"
      ? [
          ...Object.keys(assetclass[path]).filter((k) => {
            if (k != "cash") {
              return true;
            }
          }),
          "hybrid",
        ]
      : [...Object.keys(assetclass[path]), "hybrid"];
  // console.log("asset list", path, props.type, assetlist);
  const [tempchecked, setTempChecked] = useState({});
  const [section, setSection] = useState({
    section: Object.keys(assetclass[path])[0] || "",
    subsection: "",
    selected: [],
  });
  const [subSection, setSubSection] = useState([]);
  const [inputvalue, setInputValue] = useState("");
  const config = {
    enablePoints: true,
    curve: "linear",
    disablePointLabel: true,
    disableAxisBottom: true,
    pointSize: 0.1,
    nopoint: true,
    hide_legends: false,
    leftAxis: {
      legend: "Portfolio Value",
      renderTick: (tick) => {
        return (
          <g transform={`translate(${tick.x - 30},${tick.y})`}>
            {/* <rect x={-14} y={-6} rx={3} ry={3} width={28} height={24} fill="rgba(0, 0, 0, .05)" />
                        <rect x={-12} y={-12} rx={2} ry={2} width={24} height={24} fill="rgb(232, 193, 160)" /> */}
            {/* <line stroke="rgb(232, 193, 160)" strokeWidth={1.5} y1={-22} y2={-12} /> */}
            {/* <rect x={-40} y={-15} rx={2} ry={2} width={100} height={3} fill="rgba(31, 28, 64, 1)" /> */}
            <text
              textAnchor="middle"
              dominantBaseline="middle"
              style={{
                fill: "#b0aab7",
                fontSize: 10,
              }}
            >
              {tick.value}
            </text>
          </g>
        );
      },
    },
    extra_props: {
      enableSlices: "x",
      sliceTooltip: (data) => {
        return (
          <div className="tooltip_container">
            {/* <br /> */}
            <div
              className="subtext tooltip_text"
              // style={{
              //   display: "flex",
              //   fontFamily: "Avenir",
              //   paddingLeft: "25px",
              //   paddingRight: "19px",
              //   lineHeight: "24px",
              // }}
            >
              {/* <div style={{background:data.point.serieColor,width:"20px",height:"20px",borderRadius:"10px",marginRight:"5px"}}></div> */}
              {data.slice.points[0].data.x
                ? dateFormatCommon(data.slice.points[0].data.x, "YYYY-MM-DD")
                : ""}
            </div>
            {data.slice.points.map((d, i) => {
              return (
                <div
                  style={{
                    display: "flex",
                    fontFamily: "Avenir",
                    paddingLeft: "25px",
                    paddingRight: "19px",
                    lineHeight: "24px",
                  }}
                >
                  {/* <div style={{background:data.point.serieColor,width:"20px",height:"20px",borderRadius:"10px",marginRight:"5px"}}></div> */}
                  <div className="text_ellipse">{d.id}</div> :{" "}
                  {roundNumber(d?.data?.z)}{" "}
                </div>
              );
            })}
          </div>
        );
      },
    },
  };
  const [selectedRange, setSelectedRange] = useState("1D");
  const rangearr = ["1D", "1M", "3M", "1Y", "3Y", "5Y", "10Y", "ALL", "Custom"];

  function returnTicks(data) {
    let mapdata = [];
    let arr = [];
    data.map((m, i) => {
      if (i == 0) {
        return;
      }

      if (!mapdata.includes(moment(m[0]).format("YYYY"))) {
        mapdata.push(moment(m[0]).format("YYYY"));
        arr.push({ v: m[0], f: moment(m[0]).format("YYYY") });
      }
    });
    return arr;
  }

  function fetchSubclass(a) {
    let params = { assetClass: a?.toLowerCase() };
    return get_index_subclass(params).then((res) => {
      setSubSection(res.result);
      return res.result;
    });
  }

  function checkcustom(startdate) {
    let diff = moment().diff(moment(startdate), "years");

    if (diff == 0) {
      diff = moment().diff(moment(startdate), "months");

      if (diff < 1.5) {
        setFormat("DD-MMM-YYYY");
        return { type: "days", count: 3 };
      } else if (diff < 3.5) {
        setFormat("DD-MMM-YYYY");
        return { type: "days", count: 9 };
      } else {
        setFormat("MMM-YYYY");
        return { type: "months", count: 1 };
      }
    } else if (diff > 5) {
      setFormat("MMM-YYYY");
      return { type: "years", count: 1 };
    } else if (diff == 1) {
      setFormat("MMM-YYYY");
      return { type: "months", count: 1 };
    } else if (diff > 2) {
      setFormat("MMM-YYYY");
      return { type: "months", count: 4 };
    } else if (diff < 2) {
      setFormat("MMM-YYYY");
      return { type: "months", count: 2 };
    } else {
      setFormat("MMM-YYYY");
      return { type: "months", count: 6 };
    }
  }

  function switchRange(startdate) {
    let type = "days";
    let count = 1;
    let custom;
    // switch (range) {
    //   case "1M":
    //     return setxval(generateDates(moment(startdate), "days", 3));

    //   case "3M":
    //     return setxval(generateDates(moment(startdate), "days", 9));

    //   case "1Y":
    //     return setxval(generateDates(moment(startdate), "months", 1));

    //   case "3Y":
    //     return setxval(generateDates(moment(startdate), "months", 4));

    //   case "5Y":
    //     return setxval(generateDates(moment(startdate), "months", 6));
    //   case "ITD":
    //     custom = checkcustom(startdate);

    //     return setxval(
    //       generateDates(moment(startdate), custom.type, custom.count)
    //     );
    //   case "custom":
    //     custom = checkcustom(startdate);

    //     return setxval(
    //       generateDates(moment(startdate), custom.type, custom.count)
    //     );
    //   default:
    //     break;
    // }
    custom = checkcustom(startdate);

    return setxval(generateDates(moment(startdate), custom.type, custom.count));
  }

  const chartArr = ["Portfolio Value", "TWRR"];
  const { userState } = OnboardingContext();
  const clientId = userState.loginedUserData.id;

  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const [advisorList, setAdvisors] = useState([]);
  let params = { ...getfilter_as_params() };
  const graphTypeMap = {
    "Portfolio Value": "portfolio",
    TWRR: "twrr",
  };
  function mfo_performance(clientId, params) {
    setLoading2(true);
    let CompareType =
      graphTypeMap[
        Object.keys(checkedChartIndex).find((type) => {
          if (checkedChartIndex[type] == true) {
            return type;
          }
        })
      ];
    let param = {
      ...dataRange,
      ...params,
      ...props?.params,
    };
    if (props.sub) {
      return handleSubPageChartApi(param);
    }
    if (props.exp != "advisor-wise-portfolio-benchmark") {
      param.compareType = CompareType;
    }

    getMfFundPerformance(clientId, param, props?.type, props?.exp)
      .then((res) => {
        let arr = [];
        let legendss = [];
        let current = [];
        let gdata = {};
        let jn = 0;
        // res.results = [
        //   { fundName: "mfo test", graphData: [] },
        //   { fundName: "mfo test2", graphData: [] },
        // ];

        if (props.type == "bank") {
          let graphdata = [];
          res.result.map((r) => {
            graphdata.push([r?.transaction_date, r.balance, r.balance]);
          });
          arr.push({
            name: "Bank Balance",
            data: graphdata,
          });

          setlinedata(res.result.length == 0 ? [] : arr);
          setLoading2(false);
          return;
        }

        let r = props.exp ? "result" : "results";
        props.exp != "advisor-wise-portfolio-benchmark"
          ? res[r]?.map((a, j) => {
              jn = j + 1;

              let obj = {
                name: a?.fundName || a?.advisor,
                // color: "hsl(135, 70%, 50%)",
              };

              let data = [];
              a.graphData.map((d, i) => {
                // if (j == 0 && i == 0) {
                //
                //   switchRange(d.date);
                //   // setxval(generateDates(moment(d.date), "months", 4));
                // }
                // if (min1 > d.rebaseNav) {
                //   min1 = parseFloat(d.rebaseNav);
                //
                // }
                //google data formating
                // gdata[d.date]
                //   ? (gdata[d.date]["value" + j] = d.rebaseNav)
                //   : (gdata[d.date] = { ["value" + j]: d.rebaseNav });
                data.push([
                  d.date,
                  d.rebaseNav?.toFixed(2),
                  d.value,
                  // Number(Number(d.value).toFixed(2)),
                ]);
              });
              obj.data = data;

              arr.push(obj);
            })
          : res?.map((a, j) => {
              jn = j + 1;

              let obj = {
                name: a.advisor,
              };
              let data = [];
              a.graphData.map((d, i) => {
                data.push([d.date, d.rebaseNav?.toFixed(2), d.value]);
              });
              obj.data = data;

              arr.push(obj);
            });

        //google data formatting
        // let gdataarr = [
        //   ["Date", "Value", "value1", "value2", "value3"].splice(0, jn + 1),
        // ];
        // Object.keys(gdata).map((key) => {
        //   let g = Object.values(gdata[key]);
        //   gdataarr.push([new Date(key), ...g]);
        // });

        // setTicks(returnTicks(gdataarr));
        // setgoogleData(gdataarr);
        // setFilteredData(arr);
        let gotdata = arr.find((a) => {
          if (a.data.length > 0) {
            return true;
          }
        });
        if (!gotdata) {
          arr = [];
        }

        if (props.type === "dashboard") {
          let i = -1;
          for (let a of arr) {
            i = i + 1;
            if (
              a[0]?.name == "My Portfolio" &&
              a[1]?.name == "Target Portfolio" &&
              a[2]?.name == "Unique Client Index"
            ) {
              break;
            }
            if (a.name == "My Portfolio") {
              let temp = arr[0];
              arr[0] = a;
              arr[i] = temp;

              a = temp;
            }
            if (a.name == "Target Portfolio" && i != 1) {
              let temp = arr[1];
              arr[1] = a;
              arr[i] = temp;

              a = temp;
            }
            if (a.name == "Unique Client Index" && i != 2) {
              let temp = arr[2];
              arr[2] = a;
              arr[i] = temp;

              a = temp;
            }
          }
        }

        console.log("sorted");
        setlinedata(arr);
        // let len = (parseInt(min1) + "").length - 1;
        // let str = "1";
        // for (let i = 0; i < len - 1; i++) {
        //   str = str + "0";
        // }
        //
        //
        // setMin(parseInt(min1) - parseInt(str) * 2);

        setLoading2(false);
      })
      .catch((err) => {
        setLoading2(false);
        setlinedata([]);
        setgoogleData([]);
        setFilteredData([]);
      });
  }

  function handleSubPageChartApi(param) {
    switch (props?.sub) {
      case "investedvsCurrent":
        investedVsCurrentApi(param, "result");
    }
  }

  async function investedVsCurrentApi(param, mapkey) {
    try {
      let res = await getMfFundPerformance(
        clientId,
        param,
        props?.type,
        props?.exp
      );
      let res2 = await getMfFundPerformance(
        clientId,
        param,
        props?.type,
        "invested-value"
      );

      let arr = [];

      let r = mapkey;
      //calling at same time
      // Promise.allSettled([
      //   getMfFundPerformance(clientId, param, props?.type, props?.exp),
      //   getMfFundPerformance(clientId, param, props?.type, "invested-value"),
      // ]).then(([currentVal_res, investedVal_ress]) => {
      //   if (currentVal_res.status == "fulfilled") {
      //     formatData(currentVal_res?.value, r, arr, "Current Value");
      //   }

      //   if (inputvalue.status == "fulfilled") {
      //     formatData(currentVal_res?.value, r, arr, "Current Value");
      //   }
      //   setlinedata(arr);
      //   setLoading2(false);
      // });

      let legendss = [];
      let current = [];
      let gdata = {};

      formatData(res, r, arr, "Current Value");
      formatData(res2, r, arr, "Invested Value");
      console.log(arr, "linedata");
      setlinedata(arr);

      setLoading2(false);
    } catch (err) {
      console.log(err, "error");
      setLoading2(false);
      setlinedata([]);
      setgoogleData([]);
      setFilteredData([]);
    }
  }

  function formatData(response, r, arr, name) {
    let obj = {
      name: name,
    };

    let data = [];
    response[r]?.map((a, j) => {
      data.push([
        a.date,
        a.value?.toFixed(2),
        a.value,
        // Number(Number(d.value).toFixed(2)),
      ]);
    });
    obj.data = data;

    arr.push(obj);

    let gotdata = arr.find((a) => {
      if (a.data.length > 0) {
        return true;
      }
    });
    if (!gotdata) {
      arr = [];
    }
  }
  //   useEffect(() => {
  //
  //   }, [filteredData]);

  const clonedViewBtn = React.cloneElement(props?.viewDetailBtn || <></>, {
    style: {
      width: "20%",
      margin: "0% 40% 0",
      color: "#FFFFFF",
      borderRadius: "7px",
      backgroundColor: "#4f1fda !important",
    },
  });

  useEffect(() => {
    if (props?.exp != "advisor-wise-portfolio-benchmark") {
      return;
    }
    getAdvisorList(clientId, {}, "dashboard");
  }, []);

  useEffect(() => {
    const clientId = userState.loginedUserData.id;
    if (props.start && props.start.state == false) {
      return;
    }
    let params = { ...getfilter_as_params() };
    params["promoterStake"] = mfoFilter.promoterStake;
    // if (props.type === "dashboard") {
    //   params["assetClass"] = "alternate,cash,debt,equity";
    // }
    // console.log(params, "aglkangjkbn");

    if (Object.keys(checkedindex).length > 0) {
      params["indexId"] = Object.keys(checkedindex).join(",");

      //params["assetClass"] = section.section;
      mfo_performance(clientId, { ...params, ...dataRange });
    } else if (props.exp) {
      let check = Object.keys(checkadvisorListed);
      if (props?.exp == "advisor-wise-portfolio-benchmark") {
        if (check.length > 0) {
          params["advisor"] = check.join(",");
          console.log(check, "advisors");
        } else {
          return;
        }
      }
      mfo_performance(clientId, { ...params, ...dataRange });
    } else {
      mfo_performance(clientId, { ...params, ...dataRange });
    }
  }, [
    checkedindex,
    dataRange,
    mfoFilter,
    props?.start,
    checkedChartIndex,
    checkadvisorListed,
  ]);
  useEffect(() => {
    if (props?.type == "bank") {
      return;
    }
    if (search != "" && search) {
      // let filtered = [];
      // indexPerformance.map((a) => {
      //   if (a.indexName.toUpperCase().indexOf(search.toUpperCase()) > -1) {
      //     filtered.push(a);
      //   }
      // });
      let params = {
        assetClass: section.section,
        limit: 10,
        search: search,
      };
      if (subAssetClass) {
        params["subAssetClass"] = subAssetClass;
      }
      get_index_performance(null, params)
        .then((res) => {
          setIndexPerformance(res.result);
          setSchIndexList(res.result);
        })
        .catch((err) => {});
    } else {
      setSchIndexList([]);
    }
  }, [search]);
  useEffect(() => {
    if (
      props?.type == "bank" ||
      props?.exp == "advisor-wise-portfolio-benchmark" ||
      props.sub
    ) {
      return;
    }
    let params = {
      assetClass: section.section,
      limit: 10,
      search: search,
    };
    fetchSubclass(section.section).then((res) => {
      // console.log(res, "sub classes");
      setSection({
        section: section.section,
        subsection: res[0] || "",
      });
      getindexlist(res[0]);
    });
  }, []);

  function getAdvisorList(id, params, entity) {
    let members;

    members = Object?.keys(mfoFilter?.checkData);
    if (members.length > 0) {
      params["memberId"] = members.join(",");
    }
    fetchAdvisor(id, params, "dashboard").then((res) => {
      setAdvisors(res?.result);
      let l = {};
      for (let i = 0; i < res?.result?.length; i++) {
        if (i >= 4) {
          break;
        }

        l[res?.result[i]] = true;
      }
      setCheckAdvisorListed(l);
    });
  }
  // useEffect(() => {
  //   if (!search) {
  //     let params = {
  //       limit: 10,
  //     };
  //     get_index_performance(null, params).then((res) => {
  //       // console.log(res, "response");
  //       setIndexPerformance(res.result);
  //       setlist(res.result);
  //       setSchIndexList([]);
  //       // let obj = {
  //       //   ...dataRange,
  //       //   ...params,
  //       // };
  //       if (res.result.length > 0) {
  //         // console.log(res.result[0]);
  //         // obj["indexId"] = res.result[0].sectionId;
  //         setCheckedIndex({
  //           [res.result[0].sectionId]: "true",
  //         });
  //       }
  //       // console.log(clientId, "not getting clientid ");
  //       // console.log("calling from search");
  //       // mfo_performance(clientId, obj);
  //     });
  //     setlist(indexPerformance);
  //   }
  // }, []);

  const debouncedRequest = useDebounce(() => {
    // send request to the backend
    // access to latest state here
    setSearch(inputvalue);
  });

  const onChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    debouncedRequest();
  };

  function getindexlist(a, b) {
    let params = {
      assetClass: b || section.section,

      limit: 10,
    };
    if (a && a != " ") {
      params["subAssetClass"] = a;
    }
    setsubAssetClass(a);
    get_index_performance(null, params)
      .then((res) => {
        // console.log(res, "response");
        setIndexPerformance(res.result);
        setlist(res.result);
        console.log(res.result, "list");
        setSchIndexList([]);
      })
      .catch(() => {
        setIndexPerformance([]);
        setlist([]);
        setSchIndexList([]);
      });
    // setlist(indexPerformance);
  }
  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const hideAlert = () => {
    setTimeout(() => {
      const customDiv = document.getElementById("customAlert");
      customDiv.style.display = "none";
    }, 2000);
  };
  function generateDates(dateStart, type, count) {
    var dateEnd = moment();

    var timeValues = [];

    while (
      dateEnd > dateStart ||
      dateStart.format("DD-MM-YYYY") === dateEnd.format("DD-MM-YYYY")
    ) {
      timeValues.push(dateStart.format("YYYY-MM-DD"));
      dateStart.add(count, type);
    }

    return timeValues;
  }
  const handleClickOutside = (event) => {
    // setSchIndexList([]);
    if (checkedindex.length == 0) {
      setSection({
        section: "",
        subsection: "",
        selected: [],
      });
    }

    const { current: wrap } = wrapperRef;
    if (wrap && !wrap.contains(event.target)) {
      // setDisplay(false);
      // setclear(true);
    }
  };

  const handleAdvisorCheck = (event) => {
    if (event.target.checked && Object.keys(checkedindex).length < 4) {
      if (search && search != "") {
        let index = 0;
        let found = list.find((l, i) => {
          if (l.sectionId == event.target.name) {
            index = i;
            return l;
          }
        });
        if (!found) {
          let sch = schIndexlist.find((l, i) => {
            if (l.sectionId == event.target.name) {
              return l;
            }
          });

          list.unshift(sch);
        } else {
          list.splice(index, 1);

          list.unshift(found);
        }
      }

      setCheckedIndex({
        ...checkedindex,
        [event.target.name]: event.target.checked,
      });
    } else if (Object.keys(checkedindex).length == 4 && event.target.checked) {
      const customDiv = document.getElementById("customAlert");
      customDiv.style.display = "block";
      customDiv.innerHTML = `<div class="alertText">
      <span style="margin-right:20px"> <svg height="32" style="overflow:visible;enable-background:new 0 0 32 32" viewBox="0 0 32 32" width="32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><g id="Error_1_"><g id="Error"><circle cx="16" cy="16" id="BG" r="16" style="fill:#D72828;"/><path d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z" id="Exclamatory_x5F_Sign" style="fill:#E6E6E6;"/></g></g></g></svg></span>
                                
                                  
                                     Cannot select more than 4
                                
                             </div>`;
      hideAlert();
    } else {
      if (!props.exp && Object.keys(checkedindex).length < 2) {
        const customDiv = document.getElementById("customAlert");
        customDiv.style.display = "block";
        customDiv.innerHTML = `<div class="alertText">
      <span style="margin-right:20px"> <svg height="32" style="overflow:visible;enable-background:new 0 0 32 32" viewBox="0 0 32 32" width="32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><g id="Error_1_"><g id="Error"><circle cx="16" cy="16" id="BG" r="16" style="fill:#D72828;"/><path d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z" id="Exclamatory_x5F_Sign" style="fill:#E6E6E6;"/></g></g></g></svg></span>
                                
                                     Cannot deselect minimum selection 1 required 
                                  
                               
                             </div>`;
        hideAlert();
        return;
      }
      delete checkedindex[event.target.name];
      setCheckedIndex({ ...checkedindex });
    }
  };
  const handleAdvisorCheck1 = (event) => {
    // console.log(event, "clicked");
    if (search && search != "") {
      let index = 0;
      let found = list.find((l, i) => {
        if (l.sectionId == event.sectionId) {
          index = i;
          return l;
        }
      });
      if (!found) {
        let sch = schIndexlist.find((l, i) => {
          if (l.sectionId == event.sectionId) {
            return l;
          }
        });
        console.log(sch, "sch");
        list.unshift(sch);
      } else {
        list.splice(index, 1);
        console.log(found, "found");
        list.unshift(found);
      }
    }

    if (tempchecked[event.sectionId]) {
      delete tempchecked[event.sectionId];
      setTempChecked({ ...tempchecked });
    } else {
      if (Object.keys(tempchecked).length > 1) {
        // const customDiv = document.getElementById("customAlert");
        // customDiv.style.display = "block";
        // customDiv.innerHTML = `<div class="alertText">
        //     <span style="margin-right:20px"> <svg height="32" style="overflow:visible;enable-background:new 0 0 32 32" viewBox="0 0 32 32" width="32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><g id="Error_1_"><g id="Error"><circle cx="16" cy="16" id="BG" r="16" style="fill:#D72828;"/><path d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z" id="Exclamatory_x5F_Sign" style="fill:#E6E6E6;"/></g></g></g></svg></span>

        //                                    Cannot select more than 2

        //                            </div>`;
        // hideAlert();

        return;
      }
      tempchecked[event.sectionId] = true;
      setTempChecked({ ...tempchecked });
    }
  };

  const handleAdvisorChart = (event) => {
    if (event.target.checked) {
      setCheckedChartIndex(() => ({
        [event.target.name]: event.target.checked,
      }));

      // handleCloseEvent()
    } else {
      delete checkedChartIndex[event.target.name];
      setCheckedChartIndex(() => ({ ...checkedChartIndex }));
      // handleCloseEvent()
    }
    setlinedata([]);
  };
  return (
    <div
      style={{
        position: "relative",
        // paddingBottom: props.noPaddingBottom ? "none" : "230px",  //new change done to hide time line and y axis
        paddingBottom: props.noPaddingBottom ? "none" : "0px",
      }}
      // className={classes.mainContent}
    >
      {loading2 && !props?.loading && (
        <Loader style={{ marginLeft: "3%", marginTop: "-4.5%" }}></Loader>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "2%",
        }}
      >
        <Typography
          className={`${classes.mainHeading} ${classes.typoInsideAlign}`}
        >
          {/* {Object.keys(checkedChartIndex)[0]} */}
          <NewHeadAndDetailsComp
            title={
              props?.type == "bank"
                ? "Bank balance over a period of time"
                : props.headerTitle || "My Portfolio Performance"
            }
            titleBtn={props.titlebtn}
            content={
              props.headerContent ||
              `Holdings are the contents of an investment portfolio held by an individual or an entity, such as a mutual fund or a pension fund. Portfolio holdings may encompass a wide `
            }
          />
        </Typography>

        <div
          style={{
            width: "50%",
            display: "flex",
            justifyContent: " flex-end",
            alignItems: "center",
            // marginRight: "20px",
          }}
        >
          {props?.titlebtn && props?.titlebtn}
          <div>
            {/* <Filter
              onClick={() => {
                setSearch("");
                setDisplay(!display);
              }}
              className={classes.iconStyle}
            /> */}

            <Modal
              disableBackdropClick={true}
              onClose={() => {
                // if (Object.keys(checkedindex).length == 0) {
                //   setSection({
                //     section: "",
                //     subsection: "",
                //     selected: [],
                //   });
                // }
                // setDisplay(false);
              }}
              open={display}
              className="filerDialog"
              BackdropProps={{
                classes: {
                  root: classes.backDrop,
                },
              }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "transparent",
              }}
            >
              <div
                style={{
                  background: "transparent",
                  minHeight: "570px",
                  outline: 0,
                }}
              >
                <div
                  className="summaryDetailsHeadSec"
                  style={{ marginLeft: "28px" }}
                >
                  <Typography className="summaryDetailsHead">
                    Benchmark Filter
                  </Typography>
                  <Typography className="summaryDetailsTxt opacity">
                    Select Benchmarks across different categories for <br></br>{" "}
                    an accurate and detailed comparison.
                  </Typography>
                </div>

                <div className="filterContainer">
                  {/* //first list */}
                  <div
                    style={{
                      width: "194px",
                      background:
                        "linear-gradient(180deg, #600CA3 0%, #160829 110.75%)",
                      height: "470px",
                      color: "white",
                    }}
                  >
                    {/* //header */}
                    <div
                      style={{
                        paddingLeft: "28px",
                        paddingTop: "26px",
                        paddingBottom: "33px",
                      }}
                    >
                      <div className="fheader">Asset Class</div>
                      <div className="ftext opacity">
                        Select Benchmarks across different categories
                      </div>
                    </div>
                    {/* list */}
                    <div className="flist capitalize">
                      {assetlist.map((a, k) => (
                        <li
                          style={{
                            display: "flex",
                            background: section.section == a ? "#1C0832" : "",
                          }}
                          onClick={() => {
                            setSection({
                              section: a,
                              subsection: "",
                              selected: [],
                            });

                            if (a == "alternate" || a == "hybrid") {
                              getindexlist(" ", a);
                            } else {
                              fetchSubclass(a);
                              setSubSection([]);
                            }
                          }}
                        >
                          <div style={{ width: "100%" }}>{a}</div>
                          <div
                            className="liarrow"
                            style={{
                              display: section.section == a ? "flex" : "",
                              alignItems: "center",
                            }}
                          >
                            <Sidearrow />
                          </div>
                        </li>
                      ))}
                    </div>
                  </div>
                  {/*//second list */}
                  <div
                    style={{
                      width: "194px",
                      background:
                        "linear-gradient(180deg, #600CA3 -100%, #160829 81.75%)",
                      height: "470px",
                      color: "white",
                      opacity: "100%",
                      display:
                        section.section == "alternate" ||
                        section.section == "hybrid"
                          ? "none"
                          : "",
                    }}
                  >
                    {/* //header */}

                    {/* list */}
                    <div
                      className="flist capitalize li2"
                      style={{ height: "470px" }}
                    >
                      {subSection.map((a, k) => (
                        <li
                          style={{
                            background:
                              section.subsection == a ? "rgb(8, 7, 8)" : "",
                          }}
                          onClick={() => {
                            setSection({
                              section: section.section,
                              subsection: a,
                              selected: [],
                            });
                            getindexlist(a);
                          }}
                        >
                          {a}
                        </li>
                      ))}
                    </div>
                  </div>
                  {/* third list */}
                  <div
                    style={{
                      background:
                        "linear-gradient(180deg, #0B0717 0%, #00010C 100%)",
                      minWidth: "294px",
                      height: "470px",
                      color: "white",
                      maxWidth: "446px",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: -10,
                        right: -10,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        // if (Object.keys(checkedindex).length == 0) {
                        //   setSection({
                        //     section: "",
                        //     subsection: "",
                        //     selected: [],
                        //   });
                        // }
                        setDisplay(false);
                      }}
                    >
                      <Closeicon></Closeicon>
                    </div>
                    <div
                      style={{
                        marginTop: "26px",
                        marginBottom: "5px",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "28px",
                          background: "#1C0832",
                          paddingLeft: "16px",
                          borderRadius: "6px",
                          marginRight: "28px",
                        }}
                      >
                        <Search></Search>
                        {/* <SearchInput
                          clear={clear}
                          setClear={setclear}
                          setSearchName={setSearch}
                        
                        /> */}
                        <input
                          placeholder="Search"
                          className="newMenuSearchBar"
                          type="text"
                          onChange={onChange}
                          // onChange={(e) => {
                          //   // ** Reset the expanded state else the assetClass of the expanded state gets alloted to the searched one
                          //   setExpandedAccordions([]);
                          //   setSearchTerm(e.target.value);
                          // }}
                        />
                      </div>
                    </div>
                    <div className="flist">
                      {((list?.length == 0 && schIndexlist?.length == 0) ||
                        (search && schIndexlist?.length == 0)) && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                          }}
                          className="subtext"
                        >
                          No Benchmark Listed
                        </div>
                      )}
                      {tempchecked &&
                        !search &&
                        list?.map((a, k) => (
                          <li
                            style={{
                              display: "flex",
                              // justifyContent: "center",

                              // background: tempchecked[a.sectionId]
                              //   ? "#1C0832"
                              //   : "",
                            }}
                            onClick={(e) => {
                              handleAdvisorCheck1(a);
                              // e.target.checked = !e.target.checked;
                              // console.log(e.target.checked, "checked");
                            }}
                          >
                            <checkbox
                              style={{ width: "100%" }}
                              checked={tempchecked[a.sectionId] ? true : false}
                              value={tempchecked[a.sectionId] ? true : false}
                              name={a.sectionId}
                            >
                              {a.indexName}
                            </checkbox>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                paddingLeft: "25px",
                              }}
                            >
                              <Checkboxicon
                                checked={
                                  tempchecked[a.sectionId] ? true : false
                                }
                              />
                            </div>
                          </li>
                        ))}
                      {tempchecked &&
                        search &&
                        schIndexlist?.map((a, k) => (
                          <li
                            style={{
                              display: "flex",
                              background: tempchecked[a.sectionId]
                                ? "#1C0832"
                                : "",
                            }}
                            onClick={(e) => {
                              handleAdvisorCheck1(a);
                            }}
                          >
                            <checkbox
                              style={{ width: "100%" }}
                              checked={tempchecked[a.sectionId] ? true : false}
                              value={tempchecked[a.sectionId] ? true : false}
                              name={a.sectionId}
                              onClick={(e) => {
                                // console.log("clicked");
                                // handleAdvisorCheck1(a);
                                // e.target.checked = !e.target.checked;
                                // console.log(e.target.checked, "checked");
                              }}
                            >
                              {a.indexName}
                            </checkbox>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                paddingLeft: "25px",
                              }}
                            >
                              <Checkboxicon
                                checked={
                                  tempchecked[a.sectionId] ? true : false
                                }
                              />
                            </div>
                          </li>
                        ))}
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                      }}
                    >
                      <button
                        className={
                          Object.keys(tempchecked).length == 0
                            ? "applybtn nodisplay"
                            : "applybtn"
                        }
                        disabled={
                          Object.keys(tempchecked).length == 0 ? true : false
                        }
                        onClick={() => {
                          setCheckedIndex(tempchecked);
                          setDisplay(false);
                        }}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>

      <div className="chart_padding" style={{ paddingBottom: "0px" }}>
        <div>
          {/* <Suspense fallback={<Loader />}>
            <LineChart
              config={{
                extra_props: config.extra_props,
                hide_legends: true,
                leftAxis: config.leftAxis,
                bottomLegend: "Time Period",
                max: max,
                format: format,
              }}
              xval={xval}
              data={filteredData}
              colors={["#F52D6A", "#82EBA1", "#FF9446"]}
            ></LineChart>
          </Suspense> */}

          <div className="line_container">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="flex1">
                <div className="filter_btn_container ">
                  <Button
                    onClick={() => {
                      setRange("1M");
                      setCustomRange([null, null]);
                      // setxval(generateDates(moment().subtract(1, "months"), "days", 3));
                      // generate("DAILY",1,moment().subtract(1, 'months').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
                      setDataRange({
                        endDate: moment().format("YYYY-MM-DD"),
                        startDate: moment()
                          .subtract(1, "months")
                          .format("YYYY-MM-DD"),
                      });
                    }}
                    style={{ textDecoration: "none", minWidth: "10px" }}
                    to="#"
                  >
                    <div
                      className={`inner_sub_text  ${
                        range === "1M" ? "active_range" : ""
                      }`}
                      style={{ margin: "0px" }}
                    >
                      1M
                    </div>
                  </Button>
                  <Button
                    onClick={() => {
                      // setxval(generateDates(moment().subtract(3, "months"), "days", 9));
                      // generate("DAILY",3,moment().subtract(3, 'months').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
                      setRange("3M");
                      setCustomRange([null, null]);
                      setDataRange({
                        endDate: moment().format("YYYY-MM-DD"),
                        startDate: moment()
                          .subtract(3, "months")
                          .format("YYYY-MM-DD"),
                      });
                    }}
                    style={{ textDecoration: "none", minWidth: "10px" }}
                    to="#"
                  >
                    <div
                      className={`${"inner_sub_text"} ${
                        range === "3M" ? "active_range" : ""
                      }`}
                      style={{ margin: "0px" }}
                    >
                      3M
                    </div>
                  </Button>
                  <Button
                    onClick={() => {
                      setRange("1Y");
                      setCustomRange([null, null]);
                      // setxval(
                      //   generateDates(moment().subtract(1, "years"), "months", 1)
                      // );
                      // generate("WEEKLY",1,moment().subtract(1, 'years').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
                      setDataRange({
                        endDate: moment().format("YYYY-MM-DD"),
                        startDate: moment()
                          .subtract(1, "years")
                          .format("YYYY-MM-DD"),
                      });
                    }}
                    style={{ textDecoration: "none", minWidth: "10px" }}
                    to="#"
                  >
                    <div
                      className={`${"inner_sub_text"} ${
                        range === "1Y" ? "active_range" : ""
                      }`}
                      style={{ margin: "0px" }}
                    >
                      1Y
                    </div>
                  </Button>
                  <Button
                    onClick={() => {
                      setRange("3Y");
                      setCustomRange([null, null]);

                      // generate("WEEKLY",2,moment().subtract(3, 'years').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
                      setDataRange({
                        endDate: moment().format("YYYY-MM-DD"),
                        startDate: moment()
                          .subtract(3, "years")
                          .format("YYYY-MM-DD"),
                      });
                    }}
                    style={{ textDecoration: "none", minWidth: "10px" }}
                    to="#"
                  >
                    <div
                      className={`${"inner_sub_text"} ${
                        range === "3Y" ? "active_range" : ""
                      }`}
                      style={{ margin: "0px" }}
                    >
                      3Y
                    </div>
                  </Button>
                  <Button
                    onClick={() => {
                      setRange("5Y");
                      setCustomRange([null, null]);
                      // generateDates(moment().subtract(5, "years"), "months", 3);
                      // setxval(
                      //   generate(
                      //     "MONTHLY",
                      //     1,
                      //     moment().subtract(5, "years").format("YYYY-MM-DD"),
                      //     moment().format("YYYY-MM-DD")
                      //   )
                      // );
                      // setxval(
                      //   generateDates(moment().subtract(5, "years"), "months", 6)
                      // );
                      setDataRange({
                        endDate: moment().format("YYYY-MM-DD"),
                        startDate: moment()
                          .subtract(5, "years")
                          .format("YYYY-MM-DD"),
                      });
                    }}
                    style={{ textDecoration: "none", minWidth: "10px" }}
                    to="#"
                  >
                    <div
                      className={`${"inner_sub_text"} ${
                        range === "5Y" ? "active_range" : ""
                      }`}
                      style={{ margin: "0px" }}
                    >
                      5Y
                    </div>
                  </Button>
                  <Button
                    onClick={() => {
                      setRange("ITD");
                      setDataRange({});
                      setCustomRange([null, null]);
                    }}
                    style={{ textDecoration: "none", minWidth: "10px" }}
                    to="#"
                  >
                    <div
                      className={`${"inner_sub_text"} ${
                        range === "ITD" ? "active_range" : ""
                      }`}
                      style={{ margin: "0px" }}
                    >
                      ITD
                    </div>
                  </Button>
                  <Button
                    style={{
                      textDecoration: "none",
                      textTransform: "capitalize",
                      minWidth: "10px",
                    }}
                    to="#"
                  >
                    <div
                      className={`${"inner_sub_text"} ${
                        range === "custom" ? "active_range" : ""
                      }`}
                      style={{ margin: "0px" }}
                    >
                      <FilterCalendar
                        values={customRange}
                        minDate={{ min: 5, type: "years" }}
                        maxDate={{
                          current: new Date(moment().format("YYYY-MM-DD")),
                          min: 5,
                          type: "years",
                        }}
                        type="1"
                        hideinput={true}
                        customLabel="Custom"
                        customStyle={{
                          width: "100%",
                          textAlign: "center",
                          borderRadius: "20px",
                          padding: "0px",
                          background: "transparent",
                          border: "none",
                          color: "#FFFFFF",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontFamily: "Avenir",
                          fontWeight: "500",
                          cursor: "pointer",
                        }}
                        customCalenderPostion={{
                          right: "0px",
                          top: "-240px",
                        }}
                        onchange={(newCustomRange) => {
                          setCustomRange(newCustomRange);
                          setRange("custom");
                          setDataRange({
                            startDate: moment(newCustomRange[0]).format(
                              "YYYY-MM-DD"
                            ),
                            endDate: moment(newCustomRange[1]).format(
                              "YYYY-MM-DD"
                            ),
                          });
                        }}
                      />
                    </div>
                  </Button>
                  {/* {rangearr.map((d) => {
                      return (
                        <button
                          onClick={() => {
                            setSelectedRange(d);
                          }}
                          className={
                            selectedRange == d
                              ? "inner_sub_text " + "active_range"
                              : "inner_sub_text"
                          }
                        >
                          {d}{" "}
                        </button>
                      );
                    })} */}
                </div>
              </div>
              <div style={{}}>
                {/* graph icons */}
                <GraphIconHeader
                  setCheckedChartIndex={setCheckedChartIndex}
                  checkedChartIndex={checkedChartIndex}
                  handleAdvisorChart={handleAdvisorChart}
                  type={props?.type}
                  exp={props?.exp}
                  sub={props?.sub}
                  setCheckAdvisor={setCheckAdvisorListed}
                  checkAdvisor={checkadvisorListed}
                  list={advisorList}
                  click={{
                    filter: () => {
                      setSearch("");
                      setDisplay(!display);
                    },
                  }}
                />
                {/* graph icons end*/}

                {/* <div className="filter_btn_container">
                                       <button
                                           onClick={() => {
                                               setSelectedRange("print");
                                           }}
                                           className={
                                               selectedRange == "print"
                                                   ? "inner_sub_text " + "active_range"
                                                   : "inner_sub_text"
                                           }
                                       >
                                           <LocalPrintshopOutlinedIcon className="icon_" />
                                       </button>
                                       <button
                                           onClick={() => {
                                               setSelectedRange("download");
                                           }}
                                           className={
                                               selectedRange == "download"
                                                   ? "inner_sub_text " + "active_range"
                                                   : "inner_sub_text"
                                           }
                                       >
                                           <FileDownloadOutlinedIcon className="icon_" />
                                       </button>
                                       <button
                                           onClick={() => {
                                               setSelectedRange("share");
                                           }}
                                           className={
                                               selectedRange == "share"
                                                   ? "inner_sub_text " + "active_range"
                                                   : "inner_sub_text"
                                           }
                                       >
                                           <FileDownloadOutlinedIcon className="icon_" />
                                       </button>
                                   </div> */}
              </div>
            </div>
            {linedata.length > 0 && (
              <div style={{ position: "relative", top: "64px" }}>
                {" "}
                <div style={{ height: "300px" }}>
                  {" "}
                  <ApexNew
                    checkedChartIndex={checkedChartIndex}
                    controller_disabled={true}
                    min={min}
                    linedata={linedata}
                    grid={{ x: true, y: true }}
                    config={props?.config}
                    title={{
                      y:
                        checkedChartIndex?.TWRR == true
                          ? "TWRR"
                          : props.type == "bank" ||
                            props.exp == "advisor-wise-portfolio-benchmark"
                          ? "Portfolio Returns"
                          : props.chartxTitle || " Portfolio Value",
                      x:
                        props.type == "bank" ||
                        props.exp == "advisor-wise-portfolio-benchmark"
                          ? " "
                          : null,
                    }}
                    hidetooltipLabels={props.exp ? [] : null}
                  />
                </div>
              </div>
            )}
            {linedata.length < 1 && !loading2 && (
              <div
                style={{
                  height: "78%",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  // position:'absolute'
                }}
              >
                <DataNotFound />
              </div>
            )}
          </div>
        </div>
        {/* <div>
        <BarChart
          tooltip={tooltip}
          config={{ height: "200px" }}
          commonprops={diverging}
        />
      </div> */}
        {/* <div style={{ height: "80px", overflow: "hidden" }}>
          {googleData.length > 0 && (
            <GoogleChart
              data={googleData}
              lineData={linedata}
              setFilter={setFilteredData}
              setFilterRange={setFilterRange}
              filterRange={filterRange}
              ticks={ticks}
            ></GoogleChart>
          )}
        </div> */}
        {/* {linedata && (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {linedata?.map((l, i) => {
              return (
                <div style={{ display: "flex" }} className="outer-tooltip">
                  <div className={classes.graphBulletBonds}>
                    <svg height="25" width="25">
                      <circle
                        cx="12"
                        cy="12"
                        r="5"
                        strokeWidth="3"
                        fill={colors[i]}
                      />
                      Sorry, your browser does not support inline SVG.
                    </svg>
                  </div>
                  <div className={`${classes.graphLegends}`}>
                    {l.id.length > 30 ? l.id.substring(0, 30) + "..." : l.id}
                  </div>
                  <div className=" inner-tooltip tooltip-box">
                    <p className="tooltip-text">{l.id}</p>
                  </div>
                </div>
              );
            })}
          </div>
        )} */}
      </div>

      <Grid
        xs={12}
        style={{
          marginLeft: "50%",
          position: props?.type == "bonds" ? "" : "absolute",
          bottom: "5%",
          right: "5%",
        }}
      >
        {/* <div
          className="w451"
          style={{
            // width: "660px",
            marginLeft: "auto",
            background: "#220848",
            marginRight: "43px",
            marginTop: props.type == "bonds" ? "0px" : "54px",
            boxShadow: "-4px -4px 10px #431D84, 4px 4px 10px #000000",
            paddingRight: "5px",
            borderRadius: "124.46px",
            padding: "5px",
            display: "flex",
            justifyContent: "space-between",
          }}
        ></div> */}
      </Grid>
      {linedata.length > 0 ? props.viewDetailBtn : clonedViewBtn}
    </div>
  );
}
