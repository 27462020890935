import React from "react";
import { useState } from "react";
import { pie_onClick, pie_onClick2 } from "../../../charts/nivo_objects";
import PieChart from "../../../charts/PieChart";
import EquitySubTab from "./EquitySubTab";
import useStyles from "../common/ProductsStyles";
import { Grid } from "@material-ui/core";
import DataNotFound from "../common/DataNotFound";
import { currencyFormat } from "../../../../hooks/constantFunction";
import { useEffect } from "react";
import {
  fetchEquityMarketCap,
  fetch_marketCapitalisation,
} from "../../../../api";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import { useContext } from "react";
import { OnboardingContext } from "../../../../Context";
import moment from "moment";
import { firstLetterCapitalize } from "../../../utils/strHelper";
import PageLoader from "../common/pageLoader";
import { roundNumber } from "../../../utils/common";
import TableSummaryDetails from "../newDesigns/common/newSummaryDetailsTable/TableSummaryDetails";
import { ITEMS_LIMIT_FILES_PARTNER_LIST } from "../../../../Constants";
import PmsReportTable from "../pms/PmsReportTable";

export default function EquityMarket() {
  const tableColumnsData = [
    {
      field: "type",
      title: "Market Cap",
      show: true,
      align: "center",
      dontShowFirstColBorder: true, // ** NOTE TO REMOVE THE LEFT COLOR BORDER
    },
    {
      field: "investedAmount",
      title: "Invested Value",
      show: true,
      align: "center",
    },
    {
      field: "portfolioValueAmount",
      title: "Present Value",
      show: true,
      align: "right",
    },
    {
      field: "unRealisedGainLoss",
      title: "Unrealised Gain/Loss",
      show: true,
      align: "right",
    },
    {
      field: "realisedGainLoss",
      title: "Realised Gain/Loss",
      show: true,
      align: "right",
    },
    {
      field: "interestIncome",
      title: "Dividend Received",
      show: true,
      align: "right",
    },
    {
      field: "totalGainLoss",
      title: "Total Gain/Loss",
      show: true,
      align: "right",
    },
    {
      field: "xirr",
      title: "XIRR",
      show: true,
      align: "right",
    },
    {
      field: "absoluteTwrr",
      title: "Absolute TWRR",
      show: true,
      align: "right",
    },
    {
      field: "annualisedTwrr",
      title: "Annualised TWRR",
      show: true,
      align: "right",
    },
  ];
  const [assets, setAssets] = useState({});
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const [tableHeader, setTableHeader] = useState(tableColumnsData);
  const { userState } = OnboardingContext();
  const [newTableColumns, setNewTableColumns] = useState([]);
  const [asset_color, setAsset_Color] = useState([
    "#3A56FF",
    "#B80000",
    "#82EBA1",
    "#711CF1",
  ]);
  const [load, setLoad] = useState(true);
  const [pieData, setPieData] = useState([]);
  const [piecolors, setPieColors] = useState([
    "#FF9446",
    "#82EBA1",
    "#509FF1",
    "#711CF1",
    "#F52D6A",
    " #B80000",
  ]);
  const [pieState, setPieState] = useState({
    title: "",
    value: "",
    amount: null,
    colors: piecolors,
    disabled: [],
    hide: false,
    fill: [
      {
        match: {
          id: "Micro Cap",
        },
        id: "c6",
      },
      {
        match: {
          id: "Small Cap",
        },
        id: "c2",
      },
      {
        match: {
          id: "Large Cap",
        },
        id: "c1",
      },
      {
        match: {
          id: "Alternate",
        },
        id: "c3",
      },
    ],
  });
  let tempdata = [
    {
      id: "rust",
      label: "rust",
      value: 200,
      color: "hsl(229, 70%, 50%)",
    },
    {
      id: "css",
      label: "css",
      value: 245,
      color: "hsl(35, 70%, 50%)",
    },
    {
      id: "go",
      label: "go",
      value: 310,
      color: "hsl(91, 70%, 50%)",
    },
    {
      id: "java",
      label: "java",
      value: 385,
      color: "hsl(313, 70%, 50%)",
    },
    {
      id: "python",
      label: "python",
      value: 433,
      color: "hsl(129, 70%, 50%)",
    },
  ];
  const [pie, setPie] = useState({
    title: "",
    value: "",
    colors: asset_color,
    disabled: [],
    fill: [
      {
        match: {
          id: "Debt",
        },
        id: "c6",
      },
      {
        match: {
          id: "Equity",
        },
        id: "c3",
      },
      {
        match: {
          id: "Alternate",
        },
        id: "c2",
      },
      {
        match: {
          id: "Cash",
        },
        id: "c4",
      },
    ],
  });
  const [assetData, setAssetData] = useState([]);
  const [toggle, settoggle] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [pagiNationData, setPageinationData] = useState({
    pageCount: null,
    // limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
  });
  const [offset, setOffset] = useState(0);
  const objectFormating = (data) => {
    let newData = tableHeader.map((header) => {
      if (header?.field == "type") {
        return {
          key: header?.field,
          field: data[`${header.field}`],
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (header?.field == "annualisedTwrr") {
        return {
          key: header?.field,
          field: roundNumber(data[`${header.field}`]) || "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }

      if (
        header?.field == "investedAmount" ||
        header?.field == "portfolioValueAmount" ||
        header?.field == "presentValue" ||
        header?.field == "totalGainLoss" ||
        header?.field == "realisedGainLoss" ||
        header?.field == "interestIncome" ||
        header?.field == "withdrawalAmount" ||
        header?.field == "unRealisedGainLoss" ||
        header?.field == "dividendReinvestment"
      ) {
        return {
          key: header?.field,
          field:
            currencyFormat(data[`${header.field}`] || 0, {
              showDecimal: true,
            }) || 0,
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      return {
        key: header?.field,
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`]))
          : data[`${header.field}`] || "--",
        title: header.title,
        show: header.show,
        align: header?.align,
      };
    });
    return newData;
  };
  let getSummary = () => {
    setLoading(true);
    let params = {
      ...getfilter_as_params(),
      // assetClass: page,
      // clientId: 199,
      offset: offset,
      limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
    };

    if (search) {
      params["search"] = search;
    }
    fetchEquityMarketCap(userState.loginedUserData.id, params)
      .then((res) => {
        setPageinationData((prev) => {
          return { ...prev, pageCount: res?.meta?.count };
        });
        let newList = [];
        let arr = [];
        let fill = [];
        res?.data?.data?.result.map((val, i) => {
          let obj = objectFormating(val);
          newList.push(obj);

          if (val?.type !== "total") {
            arr.push({
              id: `${val.type}`,
              label: `${val.type ? firstLetterCapitalize(val.type) : " "}`,
              value: roundNumber(val.portfolioValueAmountPercentage),
              amount: currencyFormat(val.portfolioValueAmount),
            });
            fill.push({
              match: {
                id: firstLetterCapitalize(val.type),
              },
              id: "c" + (i + 1),
            });
          }
        });

        setPieState({ ...pieState, fill: fill });
        setPieData(arr);

        setNewTableColumns(newList);
        setLoading(true);
        setLoad(false);
      })
      .catch((err) => {
        setLoading(false);
        setLoad(false);
      });
  };
  useEffect(() => {
    getSummary();
  }, [mfoFilter, toggle]);

  const classes = useStyles();
  return (
    <>
      <EquitySubTab
        title="Market Capitalization"
        toggle={toggle}
        settoggle={settoggle}
      ></EquitySubTab>

      <h1 style={{ color: "#FFF" }}>
        {/* <PieChart
                  colors={pie.colors}
                  fill={pie.fill}
                  config={{
                    innerRadius: "0.90",
                    padAngle: 0,
                    legends: false,
                    minWith: "380px",
                    chart: {
                      width: 440,
                      height: 420,
                      arcLinkLabelsStraightLength: 15,
                      arcLinkLabelsDiagonalLength: 20,
                    },

                    arcLinkLabel: (d) => (
                      <tspan
                        x="0"
                        y="0"
                        style={{
                          fontFamily: "Avenir",
                          fontWeight: "400",
                          fontSize: "12",
                          textTransform: "capitalize",
                        }}
                      >
                        {" "}
                        {d.id}
                        <tspan
                          x="0"
                          y="18"
                          style={{
                            fontFamily: "Open Sans",
                            fontWeight: "700",
                            fontSize: "14",
                          }}
                        >
                          {" "}
                          {d.value}%
                        </tspan>
                      </tspan>
                    ),
                  }}
                  onClickEvent={{
                    click: pie_onClick2,
                    set: setPie,
                    cstate: pie,
                    ncolors: asset_color,
                  }}
                  data={tempdata}
                > */}
        {/* <GlowCircle>
                    <div className="circle_content capitalize">
                      <div>
                        <b>{pie.title ? pie.title : "Portfolio value"}</b>
                        <br></br>
                        <b>
                          {pie.amount
                            ? currencyFormat(pie.amount, true)
                            : realisedData.portfolioValueAmount
                            ? currencyFormat(
                                realisedData.portfolioValueAmount,
                                true
                              )
                            : "__"}
                        </b>
                      </div>
                    </div>
                  </GlowCircle> */}
        {/* </PieChart> */}
      </h1>

      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          {pieData.length !== 0 ? (
            <div className="flex_center tr20 " style={{ overflow: "hidden" }}>
              <div style={{ width: "380px", marginTop: "5%" }}>
                <PieChart
                  fill={pieState.fill}
                  config={{
                    innerRadius: "0.80",
                    cornerRadius: 0,
                    padAngle: 0,
                    legends: false,
                    minWith: "380px",
                    margin: { right: 80, top: 20, left: 80 },
                    disableArcLink: true,
                    chart: { width: 420, height: 340 },
                    arcLinkLabel: (d) => `${d.value} %`,
                    // disableArcLink:pieState.hide?true:false
                  }}
                  colors={pieState.colors}
                  onClickEvent={{
                    click: pie_onClick2,
                    set: setPieState,
                    cstate: pieState,
                    ncolors: piecolors,
                  }}
                  data={pieData}
                >
                  <div
                    className="cen"
                    style={{
                      color: "#fff",
                      transform: "translate(100.5%, -47%)",
                    }}
                  >
                    <div>
                      {/* <div className='circle_text2'>{pieState.value?pieState.value+" %":""}</div> */}
                      <div className="circle_text3">{pieState.title}</div>

                      <div className="circle_text2">
                        {pieState?.amount ? "" + pieState.amount : ""}
                      </div>
                      <div
                        className="circle_text2"
                        style={{ paddingTop: "5px" }}
                      >
                        {pieState?.value
                          ? roundNumber(pieState.value) + "%"
                          : ""}
                      </div>
                    </div>
                  </div>
                </PieChart>
              </div>
            </div>
          ) : load === false ? (
            <div style={{ position: "absolute", marginLeft: "40%" }}>
              {" "}
              <DataNotFound></DataNotFound>
            </div>
          ) : (
            <DataNotFound text=" "></DataNotFound>
          )}
        </Grid>
        {pieData.length !== 0 && (
          <Grid item xs={12} md={6}>
            <div
              style={{
                display: "flex",
                alignItems: "left",
                justifyContent: "left",
                marginTop: "12%",
              }}
            >
              <div
                style={{
                  background: "#16082E",
                  border: "1px solid #4E189F",
                  borderRadius: "20px",
                  height: "auto",
                  width: "300px",
                  padding: "20px 15px",
                }}
              >
                {pieData.map((p, i) => {
                  return (
                    <td
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "10px",
                      }}
                      className={classes.EquityMarketText}
                    >
                      <div className={classes.labelSymbol}>
                        <svg height="25" width="25">
                          <circle
                            cx="12"
                            cy="12"
                            r="5"
                            stroke-width="3"
                            fill={pieState.colors[i]}
                            // fill={color[i]}
                          />
                        </svg>
                      </div>
                      <div style={{ marginLeft: "-80px" }}>{p.id}</div>
                      <div style={{ width: "80px", textAlign: "left" }}>
                        {p.value + " %"}
                      </div>
                    </td>
                  );
                })}
              </div>
              <PageLoader showLoader={load}></PageLoader>
            </div>
          </Grid>
        )}
      </Grid>
      <div style={{ margin: "2%" }}>
        <PmsReportTable
          productType="mfoHolding"
          searchParam={search}
          setTableColumns={setNewTableColumns}
          // TODO Pass only newTableCoumns when backend search API is ready
          tableColumns={newTableColumns}
          loading={load}
          headerList={tableColumnsData}
          tableHeader={tableHeader}
          setTableHeader={setTableHeader}
          stickyProductName={true}
          // reset={getSummary}
          pageCount={pagiNationData?.pageCount}
          setItemOffset={setOffset}
          pageNumber={offset / ITEMS_LIMIT_FILES_PARTNER_LIST + 1 || 1}
          hidePagination={true}
        />
      </div>
    </>
  );
}
