import React, { useState } from "react";
import { useNewFilterStyles } from "./newFilterStyles";

export const NewFilterMemberChip = ({
  familyMembers,
  setFamilyMembers,
  selectedFamilyMembers,
  setSelectedFamilyMembers,
  handleCheck,
}) => {
  const classes = useNewFilterStyles();
  return (
    <>
      {familyMembers?.map((familyMember, index) => (
        <div
          key={familyMember?.member_id}
          className={`${classes.memberChipContainer} ${
            selectedFamilyMembers.some(
              (member) => member.member_id === familyMember.member_id
            ) && classes.memberChipSelected
          }`}
          onClick={() => handleCheck(familyMember?.member_id)}
        >
          {/* Icon */}
          <div className="memberChipIcon"></div>

          {/* Name With Label */}
          <div className="textWithLabelChip">
            <p className="memberName">{familyMember?.full_name}</p>
            {/* 💡 Can reuse the following div to show total selected members */}
            <div className="memberNameLevelLabel">Level 1</div>
          </div>
        </div>
      ))}
    </>
  );
};
