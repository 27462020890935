import { useEffect } from "react";

export const useScrollbarColor = (color, themeMode) => {
  useEffect(() => {
    const styleElement = document.createElement("style");
    styleElement.appendChild(
      document.createTextNode(
        `::-webkit-scrollbar-thumb { background: ${color}; }`
      )
    );
    document.head.appendChild(styleElement);
    document.body.className = themeMode;

    return () => {
      document.head.removeChild(styleElement);
    };
  }, [color, themeMode]);
};
