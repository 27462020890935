import React from 'react'
import useStyles from './ProductsStyles'

function TabIpoSub({ page, setPage }) {

    const classes = useStyles()
    return (
        <>
            <div style={{ width: "45%", cursor: "pointer", float: "right", marginTop: "-64px", marginRight: "2%" }} className={classes.tabMfo}>
                <a onClick={() => { setPage("open") }} style={{ textDecoration: 'none' }} ><div className={`${classes.tabHeaderText} ${page === 'open' ? classes.active : ''}`}>Open</div></a>
                <a onClick={() => { setPage("closed") }} style={{ textDecoration: 'none' }}><div className={`${classes.tabHeaderText} ${page === 'closed' ? classes.active : ''}`}>Closed</div></a>
                <a onClick={() => { setPage("upcoming") }} style={{ textDecoration: 'none' }} ><div className={`${classes.tabHeaderText}  ${page === 'upcoming' ? classes.active : ''} `}>Upcoming</div></a>
                <a onClick={() => { setPage("listed") }} style={{ textDecoration: 'none' }} ><div className={`${classes.tabHeaderText}  ${page === 'listed' ? classes.active : ''} `}>Listed</div></a>
            </div>
        </>
    )
}

export default TabIpoSub

// not used