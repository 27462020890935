import React, { useState } from "react";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/deleteIcon.svg";

function useDeleteTransactionsIcon({ deleteBtnProps }) {
  if (deleteBtnProps) {
    var {
      handleDeleteClick,
      disabled,
      deleteCancel,
      setShowDelete,
      showDelete,
    } = deleteBtnProps;
  }
  const deleteClick = () => {
    setShowDelete((prev) => !prev);
  };

  return (
    <>
      {showDelete === true ? (
        <>
          {/* <span onClick={icons?.delete} className="icon-border2"> */}
          <button
            className="newCancelbtn"
            onClick={() => {
              deleteCancel();
            }}
          >
            <p className="cancelButtonTxt" style={{ cursor: "pointer" }}>
              Cancel
            </p>
          </button>
          {/* </span> */}
          {/* <span onClick={icons?.delete} className="icon-border2"> */}
          <button
            className={`newApplyBtn`}
            onClick={handleDeleteClick}
            style={{ position: "relative" }}
          >
            {disabled && <div className="disabledBtnLayer"></div>}
            <p className="cancelButtonTxt" style={{ cursor: "pointer" }}>
              Delete
            </p>
          </button>
          {/* </span> */}
        </>
      ) : (
        <span onClick={deleteClick} className="icon-border2">
          <DeleteIcon
            style={{ color: "rgb(85 78 218)" }}
            // color="red"
            height={"24px"}
            width={"21px"}
          />
        </span>
      )}
    </>
  );
}

export default useDeleteTransactionsIcon;
