import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Modal,
  Button,
  Card,
  CardContent,
  Box,
} from "@material-ui/core";
import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import CardSummary from "../common/CardSummary";
import PageFilter from "../common/PageFilter";
import useStyles from "../common/ProductsStyles";

import { ReactComponent as EditColumn } from "../../../../assets/icons/edit-column.svg";

import { divergingCommonProps } from "../../../charts/nivo_objects";
import TabHeader from "../common/TabHeader";
import TabInsuranceMain from "../common/TabInsuranceMain";
import CheckBoxWithColor from "../common/CheckBoxWithColor";
import TabInsuranceSub from "../common/TabInsuranceSub";
import TabInsuranceUlip from "../common/TabInsuranceUlip";

import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import { useContext } from "react";
import { useEffect } from "react";
import moment from "moment";

import {
  fetchCoverageSummary,
  fetchTwrr,
  fetchTwrrCurrentHolding,
  fetchXirr,
  fetchXirrCurrentHolding,
  getRealisedData,
} from "../../../../api";
import { OnboardingContext } from "../../../../Context";
import { currencyFormat } from "../../../../hooks/constantFunction";

import FlippyArray from "../common/FlippyArray";
import PmsReportTable from "../pms/PmsReportTable";
import {
  dateFormatCommon,
  roundNumber,
  roundNumberWithoutDecimal,
} from "../../../utils/common";
import { loadingText } from "../../../utils/constands";
import SummaryPageTop from "../common/SummaryPageTop";
import PortfolioChart from "../../common/portfolioChart";

import { useHoldingCardActive } from "../../../../hooks/useHoldingCardActive";
import { NewHoldingSummaryCards } from "../../../common/HoldingSummaryCards/NewHoldingSummaryCards";
import { NewHoldingSummaryCardsActive } from "../../../common/HoldingSummaryCards/NewHoldingSummaryCardsActive";
import { useNormalReturns } from "../../../../hooks/useNormalReturns";
import { useReturns } from "../../../../hooks/useReturns";
import NewTabComp from "../common/NewTabComp";
import { routes } from "../../../../routes";
import { useTotalGainLoss } from "../../../../hooks/useTotalGainLoss";
import { NewFilter } from "../../newFilter/NewFilter";
import { useCardsApi } from "../../../../hooks/useCardsApi";
import { useCurrentHoldingCardsApi } from "../../../../hooks/useCurrentHoldingCardsApi";
import {
  ITEMS_LIMIT_FILES_PARTNER_LIST,
  realEstateTabConfig,
} from "../../../../Constants";
import FileTabComp from "../common/FileTabComp";
import { useStyles as useStylesRdCards } from "../../../common/HoldingSummaryCards/CardStyles";
import { MainCard } from "../../../common/HoldingSummaryCards/MainCard";
import { NestedCard } from "../../../common/HoldingSummaryCards/NestedCard";
import BarChartIcon from "@material-ui/icons/BarChart";
import InsuranceLifeReport from "./InsuranceLifeReport";
import NewtableTopSec from "../../common/NewtableTopSec";
let align = "center";

export const tableCoverData = [
  {
    field: "productName",
    title: "Product Name",
    show: true,
    secondLine: false,
    align: align,
  },
  { field: "advisor", title: "Advised By", show: true, align: align },
  { field: "executed_by", title: "Executed By", show: true, align: align },
  { field: "isin", title: "ISIN", show: true, align: align },
  {
    field: "assetAllocation",
    title: "Asset Allocation",
    show: true,
    align: align,
  },
  {
    field: "productSubCategory",
    title: "Product Sub Category",
    show: true,
    align: align,
  },
  {
    field: "transactionType",
    title: "Transaction Type",
    show: true,
    align: align,
  },
  {
    field: "policyCommencementDate",
    title: "Policy Commencement Date",
    show: true,
    align: align,
  },
  {
    field: "policyEndDate",
    title: "Policy End Date",
    show: true,
    align: align,
  },
  { field: "noOfPremiums", title: "No of Premium Paid", show: true },
  {
    field: "premiumFrequency",
    title: "Premium Frequency",
    show: true,
    align: align,
  },
  { field: "premiumAmount", title: "Premium Amount", show: true },
  { field: "lifeCoverAmount", title: "Life Cover Amount", show: true },
];

export const tableReturnsData = [
  {
    field: "productName",
    title: "Product Name",
    show: true,
    secondLine: false,
    align: align,
  },
  { field: "advisor", title: "Advised By", show: true, align: align },
  { field: "executed_by", title: "Executed By", show: true, align: align },
  { field: "isin", title: "ISIN", show: true, align: align },
  {
    field: "assetAllocation",
    title: "Asset Allocation",
    show: true,
    align: align,
  },
  {
    field: "productSubCategory",
    title: "Product Sub Category",
    show: true,
    align: align,
  },
  {
    field: "transactionType",
    title: "Transaction Type",
    show: true,
    align: align,
  },
  {
    field: "policyCommencementDate",
    title: "Policy Commencement Date",
    show: true,
    align: align,
  },
  {
    field: "policyEndDate",
    title: "Policy End Date",
    show: true,
    align: align,
  },
  { field: "noOfPremiums", title: "No of Premium Paid", show: true },
  {
    field: "premiumFrequency",
    title: "Premium Frequency",
    show: true,
    align: align,
  },
  { field: "premiumAmount", title: "Premium Amount", show: true },
  { field: "lifeCoverAmount", title: "Life Cover Amount", show: true },
  { field: "currentNav", title: "Current NAV (Actuals)", show: true },
  { field: "investmentValue", title: "Invested Value", show: true },
  { field: "currentUnits", title: "Unit", show: true },
  { field: "currentValue", title: "Current Value", show: true },
  {
    field: "percentageOfTotalPortfolio",
    title: "% of Total Portfolio",
    show: true,
    align: "center",
  },
  { field: "unRealisedGainLoss", title: "Unrealised Gain/Loss", show: true },
  { field: "realisedGainLoss", title: "Realised Gain/Loss", show: true },
  { field: "xirr", title: "XIRR (%)", show: true },
  { field: "absoluteTwrr", title: "Absolute TWRR (%)", show: true },
  { field: "annualisedTwrr", title: "Annualized TWRR (%)", show: true },
];

function InsuranceComp() {
  const { mfoFilter } = useContext(mfoFilterContext);
  const { userState } = OnboardingContext();
  const [checked, setChecked] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  // const [xirr, setXirr] = useState({});
  const [ulip, setUlip] = useState("ulip");
  // const [holdingCardData] = useHoldingCardActive("ins", "", ulip, "life");
  // const [totalGainLossData] = useTotalGainLoss("ins", "", ulip, "life");
  // const { returns: xirr } = useNormalReturns(
  //   fetchXirr,
  //   "normal-xirr",
  //   checked,
  //   "ins",
  //   ulip
  // );
  // const { returns: twrr } = useNormalReturns(
  //   fetchTwrr,
  //   "normal-twrr",
  //   checked,
  //   "ins",
  //   ulip
  // );
  const {
    holdingCardData,
    totalGainLossData,
    xirr,
    twrr,
    loading: cardLoader,
    twrrLoader,
    twrrError,
    retryFetchTwrr,
  } = useCardsApi("ins", ulip, "life");

  // const { returns: twrrCurrentHolding, loading: twrrCurrentHoldingLoading } =
  //   useReturns(fetchTwrrCurrentHolding, "twrr", checked, "ins", ulip);

  // const { returns: xirrCurrentHolding } = useReturns(
  //   fetchXirrCurrentHolding,
  //   "xirr",
  //   checked,
  //   "ins",
  //   ulip
  // );

  const {
    // holdingCardData,
    xirrCurrentHolding,
    twrrCurrentHolding,
    loading: currentHoldingCardLoader,
    twrrLoaderCurrentHolding,
    twrrErrorCurrentHolding,
    retryFetchTwrrCurrentHolding,
  } = useCurrentHoldingCardsApi(checked, "ins", ulip, "life");
  const [coverageSummary, setCoverageSummary] = useState([]);
  const [flip, setFlip] = useState(false);

  const classes = useStyles();
  const rdCardClass = useStylesRdCards();
  const navigate = useNavigate();
  const [tableColumns, setTableColumns] = useState(tableCoverData);
  const [openModal, setOpenModal] = useState(false);

  const [cover, setCover] = useState("cover");
  // const [twrr, setTwrr] = useState({});
  // const [twrrCurrentHolding, setTwrrCurrentHolding] = useState({});
  const [realisedData, setRealisedData] = useState({});
  const [loading, setLoading] = useState(true);
  const [load, setLoad] = useState(false);

  const [pagiNationData, setPageinationData] = useState({
    pageCount: null,
    // limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
  });
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState("");

  const [pie, setPie] = useState({
    text: "Portfolio value",
    value: "3000",
  });

  const [tableHeader, setTableHeader] = useState(
    cover == "cover" ? tableCoverData : tableReturnsData
  );
  const [newTableColumns, setNewTableColumns] = useState([]);

  const { pageName } = useParams();

  const objectFormating = (data) => {
    let newData = tableHeader.map((header) => {
      if (header?.field == "annualisedTwrr") {
        return {
          field: !isNaN(data[`${header.field}`])
            ? roundNumber(Number(data[`${header.field}`]))
            : "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }

      if (
        header?.field == "policyCommencementDate" ||
        header?.field == "policyEndDate"
      ) {
        return {
          field:
            // moment(data[`${header.field}`]).format("DD - MM - YYYY") || "--",
            dateFormatCommon(new Date(data[`${header.field}`])),
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (
        header?.field == "premiumAmount" ||
        header?.field == "lifeCoverAmount" ||
        header?.field == "investmentValue" ||
        header?.field == "currentValue" ||
        header?.field == "unRealisedGainLoss" ||
        header?.field == "realisedGainLoss" ||
        header?.field == "currentNav"
      ) {
        let currencyformat = currencyFormat(data[`${header.field}`] || 0);
        if (header?.field == "currentNav") {
          currencyformat = currencyFormat(data[`${header.field}`] || 0, {
            format: "actuals",
          });
        }
        return {
          field: currencyformat || 0,
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (
        header?.field == "isin" ||
        header?.field == "folioNo" ||
        header?.field == "executed_by" ||
        header?.field == "noOfPremiums"
      ) {
        return {
          field: data[`${header.field}`]
            ? data[`${header.field}`].toString()
            : "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      return {
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`]))
          : data[`${header.field}`] || "--",
        title: header.title,
        show: header.show,
        align: header?.align,
        secondLine: header?.secondLine,
      };
    });
    return newData;
  };

  const diverging = {
    ...divergingCommonProps,
  };
  diverging.axisBottom.legendOffset = 65;
  diverging.margin = { top: 5, right: 20, bottom: 70, left: 80 };
  useEffect(() => {
    const clientId = userState.loginedUserData.id;
    // const clientId = 199;
    const type = "ins";

    let params = { search: search };
    //staticParams
    // let params = {
    //   clientId: 1,
    //   startDate: "2020-01-02",
    //   endDate: "2023-01-02",
    //   memberId: "2",
    //   advisor: "Neo",
    // };

    if (mfoFilter.startDate) {
      params["startDate"] = moment(mfoFilter.startDate).format("YYYY-MM-DD");
      params["endDate"] = moment(mfoFilter.endDate).format("YYYY-MM-DD");
    }
    if (Object.keys(mfoFilter.checkData).length > 0) {
      params["memberId"] = Object.keys(mfoFilter.checkData).join(",");
    }
    if (Object.keys(mfoFilter.advisor).length > 0) {
      params["advisor"] = Object.keys(mfoFilter.advisor).join(",");
    }
    params["insuranceType"] = "life";

    setLoading(true);
    Promise.allSettled([
      // fetchTwrr(clientId, params),
      // fetchTwrr(
      //   clientId,
      //   {
      //     ...params,
      //     productSubCategory: ulip,
      //   },
      //   type
      // ),
      // fetchTwrrCurrentHolding(
      //   clientId,
      //   {
      //     ...params,
      //     productSubCategory: ulip,
      //   },
      //   type
      // ),
      getRealisedData(
        clientId,
        { ...params, productSubCategory: ulip },
        type,
        checked
      ),
      //#TODO prevent this api call for ulip since it is removed from here.
      fetchCoverageSummary(
        clientId,
        { ...params, productSubCategory: ulip, coverReturnType: cover },
        type
      ),
    ])
      .then(
        ([
          // twrr_res,
          // twrrCurrentHolding_res,
          realiseddata_res,
          cover_ress,
        ]) => {
          // async function allres(){
          //   await xirr_res
          //   await twrr_res
          //   await realiseddata_res
          //   await asset_ress
          //   setLoading(false)
          // }

          // if (twrr_res.status == "fulfilled") {
          //   if (twrr_res.value?.result?.annualisedTwrr) {
          //     twrr_res.value.result.annualisedTwrr =
          //       twrr_res.value?.result?.annualisedTwrr * 100;
          //   }
          //   setTwrr(twrr_res.value.result);
          // }

          // if (twrrCurrentHolding_res.status == "fulfilled") {
          //   if (twrrCurrentHolding_res.value?.result?.annualisedTwrr) {
          //     twrrCurrentHolding_res.value.result.annualisedTwrr =
          //       twrrCurrentHolding_res.value?.result?.annualisedTwrr * 100;
          //   }
          //   setTwrrCurrentHolding(twrrCurrentHolding_res.value.result);
          // }
          if (realiseddata_res.status == "fulfilled") {
            console.log(realiseddata_res.result);
            setRealisedData(realiseddata_res.value.result);
          }

          if (cover_ress.status == "fulfilled") {
            setCoverageSummary(cover_ress?.value.data?.data?.result);
            setPageinationData((prev) => {
              return {
                ...prev,
                pageCount: cover_ress?.value.data?.data.meta?.count,
              };
            });
            //new table implimentation
            let newList = [];
            cover_ress.value?.data?.data?.result?.map((val) => {
              let obj = objectFormating(val);
              newList.push(obj);
            });

            setNewTableColumns(newList);
          }
          setLoading(false);
        }
      )
      .catch((error) => {
        console.log(error, "error");
        setLoading(false);
      });

    if (ulip == "ulip") {
      // setLoad(true);
      // fetchXirr(clientId, { ...params, productSubCategory: ulip }, type)
      //   .then((res) => {
      //     setXirr(res.result);
      //     setLoad(false);
      //   })
      //   .catch((err) => {
      //     setLoad(false);
      //   });
      // fetchXirrCurrentHolding(
      //   clientId,
      //   { ...params, productSubCategory: ulip },
      //   type
      // )
      //   .then((res) => {
      //     setXirrCurrentHolding(res.result);
      //     setLoad(false);
      //   })
      //   .catch((err) => {
      //     setLoad(false);
      //   });
    }

    /* Coverage Summary API Call */
    // setLoading(true);
    // fetchCoverageSummary(
    //   clientId,
    //   {
    //     ...params,
    //     productSubCategory: ulip,
    //     coverReturnType: cover,
    //   },
    //   type
    // )
    //   .then((res) => {
    //     setCoverageSummary(res?.data?.data?.result);
    //     //new table implimentation
    //     let newList = [];
    //     res?.data?.data?.result?.map((val) => {
    //       let obj = objectFormating(val);
    //       newList.push(obj);
    //     });
    //     console.log(newList, "afaewfe");
    //     setNewTableColumns(newList);
    //     setLoading(false);
    //   })
    //   .catch((error) => {
    //     console.log(error, "error123");
    //     setLoading(false);
    //   });
  }, [mfoFilter, ulip, cover, checked, search]);

  const tableHeaders = (headers) => {
    return headers.map((column) =>
      column.show ? (
        <TableCell
          style={{ borderBottom: "none" }}
          key={column.field}
          align="left"
          className={`${classes.tableHeaderTextNew} thStyle`}
        >
          {column.title}
        </TableCell>
      ) : null
    );
  };

  function pie_onclick(data, setData) {
    setData({
      text: data["id"],
      value: data["value"],
    });
  }

  const handleCheckBoxClick = (e) => {
    const list = [...tableColumns];
    list.map((col, index) => {
      if (col.field === e.target.name) {
        const data = { ...col };
        data["show"] = !col.show;
        // console.log(data);
        list[index] = { ...col, show: !col.show };
      }
    });
    setTableColumns(list);
  };

  const handleButtonClick = () => {
    setOpenModal(!openModal);
  };

  const handleIconClick = () => {
    setOpenModal(!openModal);
  };

  // let currencyFormat = (value) => {
  //   return currencyFormat(value, {
  //     currencyStyle: { style: "currency", currency: "INR" },
  //   });
  // };
  const TableCellStyle = (color = "white") => ({
    color: color,
  });
  function holdingSummaryCardsInactiveData() {
    return {
      asOnDate:
        realisedData?.asOnDate != null
          ? holdingCardData?.asOnDate
          : loadingText,

      //    * current-holding api values
      portfolioValueAmount:
        holdingCardData?.portfolioValueAmount != null
          ? currencyFormat(holdingCardData?.portfolioValueAmount)
          : loadingText,
      //   * gain-loss api values
      overallInvestedAmount:
        realisedData?.overAllInvestedAmount != null
          ? currencyFormat(realisedData?.overAllInvestedAmount)
          : loadingText,
      //    * current-holding api values
      investedAmount:
        holdingCardData?.investedAmount != null
          ? currencyFormat(holdingCardData?.investedAmount)
          : loadingText,
      //    * current-holding api values
      redeemedInvestedAmount:
        holdingCardData?.redeemedInvestedAmount != null
          ? currencyFormat(holdingCardData?.redeemedInvestedAmount)
          : loadingText,
      //   * total-gain-loss api value
      totalGainLoss:
        totalGainLossData?.totalGainLoss != null
          ? currencyFormat(totalGainLossData?.totalGainLoss)
          : loadingText,
      //   * gain-loss api values
      realisedGainLoss:
        realisedData?.realisedGainLoss != null
          ? currencyFormat(realisedData?.realisedGainLoss)
          : loadingText,
      //    * current-holding api values
      unRealisedGainLoss:
        holdingCardData?.unRealisedGainLoss != null
          ? currencyFormat(holdingCardData?.unRealisedGainLoss)
          : loadingText,
      //   * gain-loss api values
      xirrValue:
        xirr?.xirr != null
          ? roundNumberWithoutDecimal(Number(xirr?.xirr)) + " %"
          : loadingText,
      //   * gain-loss api values
      absoluteTwrrData:
        twrr?.absoluteTwrr != null
          ? roundNumberWithoutDecimal(Number(twrr?.absoluteTwrr)) + " %"
          : loadingText,
      //   * gain-loss api values
      annualisedTwrrData:
        twrr?.annualisedTwrr != null
          ? roundNumberWithoutDecimal(Number(twrr?.annualisedTwrr)) + " %"
          : loadingText,
    };
  }
  function holdingSummaryCardsActiveData() {
    return {
      asOnDate:
        holdingCardData?.asOnDate !== null
          ? holdingCardData?.asOnDate
          : loadingText,
      portfolioValueAmount:
        holdingCardData?.portfolioValueAmount !== null
          ? currencyFormat(holdingCardData?.portfolioValueAmount)
          : loadingText,
      investedAmount:
        holdingCardData?.investedAmount !== null
          ? currencyFormat(holdingCardData?.investedAmount)
          : loadingText,
      totalGainLoss:
        holdingCardData?.totalGainLoss !== null
          ? currencyFormat(holdingCardData?.totalGainLoss)
          : loadingText,
      realisedGainLoss:
        holdingCardData?.realisedGainLoss !== null
          ? currencyFormat(0)
          : loadingText,
      unRealisedGainLoss:
        holdingCardData?.unRealisedGainLoss !== null
          ? currencyFormat(holdingCardData?.unRealisedGainLoss)
          : loadingText,
      xirrValue:
        xirrCurrentHolding?.xirr !== null
          ? roundNumberWithoutDecimal(Number(xirrCurrentHolding?.xirr)) + " %"
          : loadingText,
      absoluteTwrrData:
        twrrCurrentHolding?.absoluteTwrr !== null
          ? roundNumberWithoutDecimal(
              Number(twrrCurrentHolding?.absoluteTwrr)
            ) + " %"
          : loadingText,
      annualisedTwrrData:
        twrrCurrentHolding?.annualisedTwrr !== null
          ? roundNumberWithoutDecimal(
              Number(twrrCurrentHolding?.annualisedTwrr)
            ) + " %"
          : loadingText,
    };
  }

  const data = [
    { page: "ulip", title: "ULIPs" },
    { page: "non-ulip", title: " Non ULIPs" },
  ];

  const data2 = [
    { page: "life", title: "Life", path: routes.insuranceSummaryPage },
    {
      page: "health",
      title: "Health",
      path: routes.insuranceSummaryPage_health,
    },
    {
      page: "general",
      title: "General",
      path: routes.insuranceSummaryPage_general,
    },
  ];

  const handleClick = React.useCallback(
    (page) => {
      setUlip(page);
    },
    [ulip]
  );

  const data23 = [
    { page: "cover", title: "Cover" },
    { page: "return", title: "Return" },
  ];

  const handleClick23 = React.useCallback(
    (col) => {
      // (col) => {
      setCover(col);
      if (col === "cover") {
        setTableColumns(tableCoverData);
        setTableHeader(tableCoverData);
      } else {
        setTableColumns(tableReturnsData);
        setTableHeader(tableReturnsData);
      }
      // }
    },
    [cover]
  );
  const handleSummaryClick = () => {
    navigate(routes?.insuranceReportPage);
  };
  return (
    <>
      <SummaryPageTop
        backLink={routes?.homeHoldingPage}
        pageTitle={"Insurance Portfolio Summary"}
        flip={flip}
        setFlip={setFlip}
        checked={checked}
        setChecked={setChecked}
        ulip={ulip}
        setFilterModalOpen={setFilterModalOpen}
      />
      <NewFilter
        filterModalOpen={filterModalOpen}
        setFilterModalOpen={setFilterModalOpen}
        productType="ins"
      />
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "2%",
        }}
      > */}
      {/* <TabInsuranceMain></TabInsuranceMain> */}

      {/* <div>
          <NewTabComp
            data={data}
            customWidth={"fit-content"}
            customHandle={handleClick}
          />
        </div> */}
      {/* //TODO delete component */}
      {/* <TabInsuranceUlip ulip={ulip} setUlip={setUlip}></TabInsuranceUlip> */}
      {/* </div> */}
      <FileTabComp
        data={realEstateTabConfig}
        seleted={
          realEstateTabConfig.findIndex((val) => val.page == pageName) == -1
            ? 0
            : realEstateTabConfig.findIndex((val) => val.page == pageName)
        }
      />
      <div style={{ marginInline: "2.5%" }}>
        <NewTabComp
          data={data}
          customWidth={"100%"}
          customHandle={handleClick}
        />
      </div>

      <div style={{ margin: "2%", paddingLeft: "10px", paddingRight: "10px" }}>
        {ulip === "ulip" &&
          // <div className={classes.mainContent}>
          //   <InsuranceCardSummary
          //     currencyFormat={currencyFormat}
          //     xirr={xirr}
          //     realisedData={realisedData}
          //     twrr={twrr}
          //   ></InsuranceCardSummary>
          // </div>
          // <FlippyArray
          //   page={"ins"}
          //   portfolioData={
          //     realisedData?.portfolioAmount != null
          //       ? currencyFormat(realisedData?.portfolioAmount)
          //       : loadingText
          //   }
          //   investmentData={
          //     realisedData?.investedAmount != null
          //       ? currencyFormat(realisedData?.investedAmount)
          //       : loadingText
          //   }
          //   gainData={
          //     realisedData?.totalGainLoss != null
          //       ? currencyFormat(realisedData?.totalGainLoss)
          //       : loadingText
          //   }
          //   realisedData={realisedData}
          //   xirrData={
          //     xirr?.xirr != null
          //       ? roundNumber(Number(xirr?.xirr)) + " %"
          //       : loadingText
          //   }
          //   absoluteTwrrData={
          //     twrr?.absoluteTwrr != null
          //       ? roundNumber(Number(twrr?.absoluteTwrr)) + " %"
          //       : loadingText
          //   }
          //   annualisedTwrrData={
          //     twrr?.annualisedTwrr != null
          //       ? roundNumber(Number(twrr?.annualisedTwrr)) + " %"
          //       : loadingText
          //   }
          //   flip={flip}
          // />
          (!checked ? (
            <NewHoldingSummaryCards
              retryFetchTwrr={retryFetchTwrr}
              twrrLoader={twrrLoader}
              twrrError={twrrError}
              holdingSummaryCardsInactive={holdingSummaryCardsInactiveData()}
            ></NewHoldingSummaryCards>
          ) : (
            <NewHoldingSummaryCardsActive
              retryFetchTwrrCurrentHolding={retryFetchTwrrCurrentHolding}
              twrrLoaderCurrentHolding={twrrLoaderCurrentHolding}
              twrrErrorCurrentHolding={twrrErrorCurrentHolding}
              holdingSummaryCardsActive={holdingSummaryCardsActiveData()}
            ></NewHoldingSummaryCardsActive>
          ))}
        <Grid
          container
          className={classes.insurancecardswidth}
          id="grid-insurance-cards"
          style={{ margin: "0 auto" }}
        >
          {/* <Grid item xs={6}>
            <Card className={classes.lifeCoverAmount}>
              <Box className={classes.lifeCoverAmountImg}>
                <InsuranceCardCircle />
              </Box>
              <CardContent className={classes.lifeCoverAmountCardContent}>
                <Typography
                  variant="h4"
                  className={classes.lifeCoverAmountText1}
                >
                  {realisedData.lifCoverAmount != null
                    ? currencyFormat(realisedData.lifCoverAmount)
                    : loadingText}
                </Typography>
                <Typography
                  variant="h6"
                  className={classes.lifeCoverAmountText2}
                >
                  Life Cover Amount
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card className={classes.lifeCoverAmount}>
              <Box className={classes.lifeCoverAmountImg}>
                <InsuranceCardCircle />
              </Box>
              <CardContent className={classes.lifeCoverAmountCardContent}>
                <Typography
                  variant="h4"
                  className={classes.lifeCoverAmountText1}
                >
                  {realisedData.premiumAmount != null
                    ? currencyFormat(realisedData.premiumAmount)
                    : loadingText}
                </Typography>
                <Typography
                  variant="h6"
                  className={classes.lifeCoverAmountText2}
                >
                  Premium Amount
                </Typography>
              </CardContent>
            </Card>
          </Grid> */}
          <Grid container spacing={1}>
            {/* Show the Divider Line Only For ULIP and Not For Non-Ulip */}
            {ulip === "ulip" && (
              <Grid item xs={12}>
                <section id="title-wrapper">
                  <div class="title" className={rdCardClass.dividerText}>
                    ULIPS DETAILS
                  </div>
                  <div class="dividerLine"></div>
                </section>
              </Grid>
            )}

            <Grid item xs={6}>
              <MainCard className="rdMainCard">
                <NestedCard
                  className="rdNestedCard"
                  style={{
                    background:
                      "linear-gradient(269deg, rgba(33, 24, 57, 0.5) 0%, rgba(9, 4, 24, 0.5) 83.33%)",
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    className={rdCardClass.nestedCardBoxPadding}
                  >
                    <h1 className="rdcardText rdCardHeadingTxt">
                      LIFE COVER AMOUNT
                    </h1>
                    <p className="rdcardText rdCardValueTxt">
                      {realisedData.lifCoverAmount != null
                        ? currencyFormat(realisedData.lifCoverAmount)
                        : loadingText}
                    </p>
                  </Box>
                </NestedCard>
              </MainCard>
            </Grid>
            <Grid item xs={6}>
              <MainCard className="rdMainCard">
                <NestedCard
                  className="rdNestedCard"
                  style={{
                    background:
                      "linear-gradient(269deg, rgba(33, 24, 57, 0.5) 0%, rgba(9, 4, 24, 0.5) 83.33%)",
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    className={rdCardClass.nestedCardBoxPadding}
                  >
                    <h1 className="rdcardText rdCardHeadingTxt">
                      PREMIUM AMOUNT
                    </h1>
                    <p className="rdcardText rdCardValueTxt">
                      {realisedData.premiumAmount != null
                        ? currencyFormat(realisedData.premiumAmount)
                        : loadingText}
                    </p>
                  </Box>
                </NestedCard>
              </MainCard>
            </Grid>
          </Grid>
          {/* <FlippyArray
            page={"ins-life"}
            lifeData={
              realisedData.lifCoverAmount != null
                ? currencyFormat(realisedData.lifCoverAmount)
                : loadingText
            }
            premiumData={
              realisedData.premiumAmount != null
                ? currencyFormat(realisedData.premiumAmount)
                : loadingText
            }
            flip={flip}
            outerContent={true}
          /> */}
        </Grid>
        {ulip === "ulip" ? (
          <>
            <PortfolioChart
              type={"ins"}
              params={{ productSubCategory: "ulip" }}
              loading={loading || load ? true : false}
              noPaddingBottom={true}
              viewDetailBtn={
                <Button
                  onClick={handleSummaryClick}
                  className={classes.viewDetailBtn + " portfolioBtn "}
                  style={{
                    width: "20%",
                    margin: "0% 40% 0",

                    borderRadius: "7px",
                    backgroundColor: "#4f1fda !important",
                  }}
                  startIcon={<BarChartIcon />}
                >
                  View Details
                </Button>
              }
            ></PortfolioChart>
          </>
        ) : (
          ""
        )}
      </div>

      {ulip !== "ulip" && (
        <div style={{ height: "auto", margin: "1.5%" }}>
          <div
            style={{
              display: "flex",
              // justifyContent: "space-between",
              justifyContent: ulip === "ulip" ? "space-between" : "flex-end",
              margin: "2%",
            }}
          >
            {/* 🧾 Toggling of Cover and Returns Table */}
            {ulip === "ulip" ? (
              // <TabInsuranceSub
              //   click={(col) => {
              //     if (col === "cover") {
              //       setTableColumns(tableCoverData);
              //       setTableHeader(tableCoverData);
              //     } else {
              //       setTableColumns(tableReturnsData);
              //       setTableHeader(tableReturnsData);
              //     }
              //   }}
              //   cover={cover}
              //   setCover={setCover}
              // ></TabInsuranceSub>
              <NewTabComp
                data={data23}
                customWidth={"fit-content"}
                customHandle={handleClick23}
              />
            ) : (
              <></>
            )}

            {/* <EditColumn
              onClick={handleIconClick}
              className={classes.iconStyle}
            /> */}
          </div>
          <NewtableTopSec
            handleIconClick={handleIconClick}
            directPageName={"Policies"}
            setSearch={(e) => {
              setOffset(0);
              setSearch(e);
            }}
          />
          {/* <Typography
            style={{ cursor: "pointer", margin: "2% 2% 0.7% 2%" }}
            className={classes.titleText}
          >
            {cover === "cover" ? "Coverage Summary" : "Holding Summary"}
          </Typography> */}
          <Typography
            style={{ cursor: "pointer", margin: "0% 0% 2% 2%" }}
            className={classes.rowTextTable}
          >
            {/* Life Insurance */}
            {sessionStorage.getItem("currency_format") != "actuals" &&
              sessionStorage.getItem("currency_format") && (
                <span
                  className="subtext capitalize"
                  style={{ fontSize: "16px" }}
                >
                  {" "}
                  (values in {sessionStorage.getItem("currency_format")})
                </span>
              )}
          </Typography>

          {cover == "cover" ? (
            <PmsReportTable
              setTableColumns={setNewTableColumns}
              tableColumns={newTableColumns}
              setOpenModal={setOpenModal}
              openModal={openModal}
              loading={loading || load ? true : false}
              headerList={tableCoverData}
              tableHeader={tableHeader}
              setTableHeader={setTableHeader}
              stickyProductName={true}
              headerInTwoLine={true}
              pageCount={pagiNationData?.pageCount}
              setItemOffset={setOffset}
              pageNumber={offset / ITEMS_LIMIT_FILES_PARTNER_LIST + 1 || 1}
            />
          ) : (
            <PmsReportTable
              setTableColumns={setNewTableColumns}
              tableColumns={newTableColumns}
              setOpenModal={setOpenModal}
              openModal={openModal}
              loading={loading || load ? true : false}
              headerList={tableReturnsData}
              tableHeader={tableHeader}
              setTableHeader={setTableHeader}
              stickyProductName={true}
              headerInTwoLine={true}
              pageCount={pagiNationData?.pageCount}
              setItemOffset={setOffset}
              pageNumber={offset / ITEMS_LIMIT_FILES_PARTNER_LIST + 1 || 1}
            />
          )}

          {/* 
        <Grid xs={12}>
          <div
            style={{
              minHeight: "300px",
              overflowY: "hidden",
              width: "auto",
              margin: "2%",
            }}
          >
            <Table>
              <TableHead>
                <TableRow
                  style={{ background: "#4E189F" }}
                  className="tableRowStyle"
                >
                  {tableHeaders(tableColumns)}
                </TableRow>
              </TableHead>
              <TableBody className="tableBodyPms">
                <TableRow style={{ height: "10px" }} />
                {/* <TableRow className="tableRowPms" style={{ background: "red" }}>
                  {tableColumns?.map((column) =>
                    column.show ? (
                      <TableCell
                        key={column.field}
                        align="left"
                        className={`${classes.tableBodyText} tdPms`}
                      >
                        {column.field}
                      </TableCell>
                    ) : null
                  )}
                </TableRow> 
                {coverageSummary?.map((item, index) => (
                  <TableRow
                    key={index}
                    className="tableRowPms"
                    style={{ background: "1D1B36" }}
                  >
                    {/* ADVISOR 
                    <TableCell style={TableCellStyle()}>
                      {item.advisor}
                    </TableCell>
                    {/* ISIN 
                    <TableCell style={TableCellStyle()}>{item.isin}</TableCell>
                    {/* PRODUCT NAME 
                    <TableCell style={TableCellStyle()}>
                      {firstLetterCapitalize(item.productName)}
                    </TableCell>
                    {/* ASSET ALLOCATION 
                    <TableCell style={TableCellStyle()}>
                      {firstCharCapitalize(item?.assetAllocation)}
                    </TableCell>
                    {/* PRODUCT SUB-CATEGORY 
                    <TableCell style={TableCellStyle()}>
                      {item.productSubCategory.toUpperCase()}
                    </TableCell>
                    {/* TRANSACTION TYPE
                    <TableCell style={TableCellStyle()}>
                      {firstLetterCapitalize(item.transactionType)}
                    </TableCell>
                    {/* COMMENCEMENT DATE
                    <TableCell style={TableCellStyle()}>
                      {dateFormat_2(item.policyCommencementDate)}
                    </TableCell>
                    {/* END DATE 
                    <TableCell style={TableCellStyle()}>
                      {dateFormat_2(item.policyEndDate)}
                    </TableCell>
                    {/* NO OF PREMIUMS 
                    <TableCell style={TableCellStyle()}>
                      {item.noOfPremiums.toFixed(2)}
                    </TableCell>
                    {/* PREMIUM FREQUENCY 
                    <TableCell style={TableCellStyle()}>
                      {item.premiumFrequency}
                    </TableCell>
                    {/* PREMIUM AMOUNT 
                    <TableCell style={TableCellStyle()}>
                      {currencyFormatINR(item.premiumAmount)}
                    </TableCell>
                    {/* LIFE COVER AMOUNT 
                    <TableCell style={TableCellStyle()}>
                      {currencyFormatINR(item.lifeCoverAmount)}
                    </TableCell>

                    {/* RETURNS TABLE 
                    {cover === "return"}
                    <TableCell style={TableCellStyle()}>
                      {item.currentNav}
                    </TableCell>
                    <TableCell style={TableCellStyle()}>
                      {item.investmentValue}
                    </TableCell>
                    <TableCell style={TableCellStyle()}>
                      {item.currentUnits}
                    </TableCell>
                    <TableCell style={TableCellStyle()}>
                      {item.currentValue}
                    </TableCell>
                    <TableCell style={TableCellStyle()}>
                      {item.percentageOfTotalPortfolio}
                    </TableCell>
                    <TableCell style={TableCellStyle()}>
                      {item.unRealisedGainLoss}
                    </TableCell>
                    <TableCell style={TableCellStyle()}>
                      {item.realisedGainLoss}
                    </TableCell>
                    <TableCell style={TableCellStyle()}>{item.xirr}</TableCell>
                    <TableCell style={TableCellStyle()}>
                      {item.absoluteTwrr}
                    </TableCell>
                    <TableCell style={TableCellStyle()}>
                      {item.annualisedTwrr * 100}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Grid>*/}

          {/* <Modal
          open={openModal}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className={`${classes.modalContainer} form-container`}>
            <div className="filter-header">
              <Typography className="filter-header-text">
                Modify Columns
              </Typography>
            </div>
            <hr className="filter-divider"></hr>
            <div className="member">
              <div className="filter-header">
                <Typography className="filter-header-text">
                  Select Columns
                </Typography>
              </div>
              <div className="member-content">
                {tableColumns?.map((column) =>
                  column.title !== "" ? (
                    <div key={column.field} className="filter-header">
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <CheckBoxWithColor
                          checked={column.show}
                          value={column.field}
                          name={column.field}
                          onChange={(e) => handleCheckBoxClick(e)}
                        />
                        <Typography className="filter-value-name avtar-text">
                          {column.title}
                        </Typography>
                      </span>
                    </div>
                  ) : null
                )}
              </div>
            </div>

            <hr className="filter-divider"></hr>
            <div className={classes.buttonContainer}>
              <Button
                // variant="contained"
                className={classes.buttonNo}
                style={{ width: "60%", marginTop: "5%" }}
                onClick={() => {
                  setOpenModal(!openModal);
                }}
              >
                <span className={`${classes.buttonText}`}>close</span>
              </Button>
              <Button
                // variant="contained"
                className={classes.buttonApplyModal}
                style={{ width: "60%", marginTop: "5%" }}
                onClick={handleButtonClick}
              >
                <span className={`${classes.buttonText}`}>apply</span>
              </Button>
            </div>
          </div>
        </Modal> */}
        </div>
      )}
    </>
  );
}

export default InsuranceComp;
