import moment from "moment";
import { currencyFormatPrintNoFormat as currencyFormat } from "../../../../../hooks/constantFunction";
import { dateFormatCommon, roundNumber } from "../../../../utils/common";
import { firstCharCapitalize } from "../../../../utils/strHelper";
import {
  doNothingArrayPrint,
  currencyFormatCheckArrayPrint,
  tableBorderColor,
  dateFormatingArrayPrint,
  percentage,
  positive,
  negative,
  gorupRows,
  onlyPercentage,
  currencyFormaWithGreenRedCell,
  currencyFormatCheckProductAllocationPrint,
  doNothingProductAllocation,
  TopBottomDoNothingArr,
  MemberWiseDoNothingArr,
  doNothingCommonChart,
  currencyFormatCommonChart,
  TaxDoNothingArr,
  currencyFormatCheckTaxPrint,
  currencyFormatTotalTaxPrint,
} from "./constands";
import CryptoJS from "crypto-js";
import { encrypt_key } from "../../../../../Config";
import { assetVbenchmark } from "../../../products/holding_summary/utils/constands";
import { isArray } from "lodash";

const checkNumberPositiveOrNeg = (val) => {
  if (!isNaN(val)) {
    if (val > 0) {
      return positive;
    } else if (val < 0) {
      return negative;
    }
  }
  return undefined;
};

const getColor = (assetClass) => {
  if (tableBorderColor[assetClass]) return tableBorderColor[assetClass];
  else return "#0AC0CE";
};

const getDenomination = localStorage.getItem("denomination");

export const objectFormatingPrint = (data, headerData, denomination, into) => {
  let newData = headerData.map((header) => {
    if (header?.field == "indicator") {
      return { ...header, field: data[`${header.field}`] };
    }
    if (header?.field == "unRealisedGainLoss") {
      if (denomination)
        return {
          ...header,
          field:
            currencyFormat(
              data[`${header.field}`] || 0,
              undefined,
              denomination
            ) || "--",
          posOrNeg: checkNumberPositiveOrNeg(data[`${header.field}`]),
        };

      return {
        ...header,
        field:
          currencyFormat(
            data[`${header.field}`] || 0,
            undefined,
            getDenomination
          ) || "--",
        posOrNeg: checkNumberPositiveOrNeg(data[`${header.field}`]),
      };
    }
    if (currencyFormaWithGreenRedCell.includes(header?.field)) {
      if (denomination) {
        return {
          ...header,
          field: !isNaN(data[`${header.field}`])
            ? currencyFormat(
                Number(data[`${header.field}`]),
                undefined,
                denomination
              ) + "%"
            : data[`${header.field}`] || "--",
          posOrNeg: checkNumberPositiveOrNeg(data[`${header.field}`]),
        };
      }

      return {
        ...header,
        field: !isNaN(data[`${header.field}`])
          ? currencyFormat(
              Number(data[`${header.field}`]),
              undefined,
              getDenomination
            ) + "%"
          : data[`${header.field}`] || "--",
        posOrNeg: checkNumberPositiveOrNeg(data[`${header.field}`]),
      };
    }

    if (percentage.includes(header?.field)) {
      if (
        header?.field == "TWRR_annualised" ||
        header?.field == "annualisedTwrr"
      ) {
        return {
          ...header,
          field: !isNaN(data[`${header.field}`])
            ? roundNumber(Number(data[`${header.field}`])) + " %"
            : "--",
        };
      }
      if (header?.field == "absoluteTwrr") {
        return {
          ...header,
          field: !isNaN(data[`${header.field}`])
            ? roundNumber(Number(data[`${header.field}`]) * (into || 100)) +
              " %"
            : "--",
        };
      }

      return {
        ...header,
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`])) + "%"
          : data[`${header.field}`] || "--",
        posOrNeg: checkNumberPositiveOrNeg(data[`${header.field}`]),
      };
    }
    if (doNothingArrayPrint.includes(header?.field)) {
      return {
        ...header,
        field: data[`${header.field}`] ? String(data[`${header.field}`]) : "--",
        color: getColor(firstCharCapitalize(data["assetClass"])),
      };
    }
    if (header?.field == "holdingPeriod") {
      return {
        ...header,
        field: data[`${header.field}`]
          ? data[`${header.field}`] + " days"
          : "--",
      };
    }
    if (
      header?.field == "TWRR_annualised" ||
      header?.field == "annualisedTwrr"
    ) {
      return {
        ...header,
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`])) + " %"
          : "--",
      };
    }
    if (currencyFormatCheckArrayPrint.includes(header?.field)) {
      if (denomination)
        return {
          ...header,
          field:
            currencyFormat(
              data[`${header.field}`] || 0,
              undefined,
              denomination
            ) || "--",
        };

      return {
        ...header,
        field:
          currencyFormat(
            data[`${header.field}`] || 0,
            undefined,
            getDenomination
          ) || "--",
      };
    }

    if (dateFormatingArrayPrint.includes(header?.field)) {
      return {
        ...header,
        field: data[`${header.field}`]
          ? dateFormatCommon(new Date(data[`${header.field}`]))
          : "--",
        // moment(new Date(data[`${header.field}`])).format(
        //   "DD - MM - YYYY"
        // ) || "--",
      };
    }
    return {
      ...header,
      field: !isNaN(data[`${header.field}`])
        ? roundNumber(Number(data[`${header.field}`]))
        : data[`${header.field}`] || "--",
    };
  });

  return newData;
};

const handleNullData = (data) => {
  let a = !isNaN(data) ? roundNumber(Number(data)) : data || "--";
  // console.log(data, a, "kajbsfabjfasf");

  return a;
};

const handleUnitsData = (data) => {
  // console.log(data, "kajbsfabjfasf");
  let unitsKeys = ["unts", "holdingUnits", "quantity"];

  // let a = unitsKeys.map((val) => {
  //   if (data[val]) {
  //     return data[val];
  //   }
  // });
  // console.log(a,'aljkvsjfsaf');

  if (data?.units) {
    return handleNullData(data?.units);
  }
  if (data?.holdingUnits) {
    return handleNullData(data?.holdingUnits);
  }
  if (data?.quantity) {
    return handleNullData(data?.quantity);
  }
  return "--";
};
const formatNumberWithCommas = (number) => {
  if (!number || isNaN(number)) {
    return "--";
  }
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
};

export const objectFormatingWithTitleCOl = (
  data,
  headerData,
  key,
  denomination,
  total,
  title
) => {
  let assetvbench = [
    "itdTotalGainLoss",

    "mtdTotalGainLoss",

    "qtdTotalGainLoss",

    "ytdTotalGainLoss",
  ];
  let benchmarkVasset = [
    "itdAbsoluteReturn",
    "mtdAbsoluteReturn",
    "qtdAbsoluteReturn",
    "ytdAbsoluteReturn",
  ];

  let fontclass = " ";
  if (total) {
    fontclass = "font900";
  }
  let folio = headerData.find((h) => {
    if (h.field == "folioNo") {
      return true;
    }
  });
  let newData = headerData.map((header) => {
    if (header?.field != "assetClass" && data.benchmarkName && data.ignore) {
      return {
        type_null: true,
      };
    }
    // console.log("56437534gh", data, header?.title);
    if (
      (data.schemeName == "Grand Total" || data.schemeName == "Sum Total") &&
      header?.field == "units" &&
      !folio
    ) {
      if (!data?.["instruments"] && !data?.["uniqueInstruments"]) {
        return {
          ...header,
          field: "--",
          color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
        };
      }
      if (data.noUniqueInstruments) {
        return {
          ...header,
          field: (
            <div className="nowrap">
              <span>Total Instruments - </span>
              {data?.["instruments"]}
            </div>
          ),
          color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
        };
      }

      if (title == "holdingSummary") {
        return {
          ...header,
          field: (
            <div className="nowrap">
              <span>Total Instruments - </span>
              {data?.["instruments"]}
            </div>
          ),
          color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
        };
      }
      return {
        ...header,
        field: (
          <div className="tableSubDivide">
            <div>
              <span>Total Instruments - </span>
              {data?.["instruments"]}
            </div>
            <div>
              <span>Unique Instruments - </span>
              {data?.["uniqueInstruments"]}
            </div>
          </div>
        ),
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }
    if (header?.title == "Units") {
      return {
        ...header,
        field: handleUnitsData(data),
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }

    if (header?.title == "Quantity") {
      const formattedQuantity = formatNumberWithCommas(data[header.field]);

      return {
        ...header,
        withtwoline: true,
        // field: handleUnitsData(data),
        field: (
          <div>
            {formattedQuantity || "--"}
            <div style={{ transform: " skew(-5deg)", fontSize: "12px" }}>
              {data[`${header.subdata}`]
                ? currencyFormat(
                    data[`${header.subdata}`] || 0,
                    { showDecimal: true },
                    "Actuals",
                    fontclass
                  ) || "--"
                : " "}
            </div>
          </div>
        ),

        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }
    if (header?.field == "Initial Purchase Date") {
      return {
        ...header,
        field: data[`${header.field}`] ? (
          <>
            {" "}
            {dateFormatCommon(new Date(data[`${header.field}`]))}{" "}
            <span style={{ color: "#dcaf3d", fontSize: "15px" }}>
              {data?.["Has Multiple Buy Transactions"] ||
              data?.["HasMultipleBuyTransactions"]
                ? "*"
                : ""}
            </span>
          </>
        ) : (
          "--"
        ),
        // moment(new Date(data[`${header.field}`])).format(
        //   "DD - MM - YYYY"
        // ) || "--",
      };
    }
    if (header?.field == "transactionDate") {
      return {
        ...header,
        field: data[`${header.field}`] ? (
          <>
            {" "}
            {dateFormatCommon(new Date(data[`${header.field}`]))}{" "}
            <span style={{ color: "#dcaf3d", paddingLeft: "5px" }}>
              {data?.["isHeld"] == 0 ? "#" : ""}
            </span>
          </>
        ) : (
          "--"
        ),
      };
    }
    if (
      (data.schemeName == "Grand Total" || data.schemeName == "Sum Total") &&
      (header?.field == "folioNo" ||
        header?.field == "type" ||
        header?.field == "insType")
    ) {
      if (!data?.["instruments"] && !data?.["uniqueInstruments"]) {
        return {
          ...header,
          field: "--",
          color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
        };
      }
      if (!data?.["uniqueInstruments"] && data?.["instruments"]) {
        return {
          ...header,
          field: (
            <div className="nowrap">
              <span>Total Instruments - </span>
              {data?.["instruments"]}
            </div>
          ),
          color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
        };
      }
      return {
        ...header,
        field: (
          <div className="tableSubDivide">
            <div>
              <span>Total Instruments - </span>
              {data?.["instruments"]}
            </div>
            <div>
              <span>Unique Instruments - </span>
              {data?.["uniqueInstruments"]}
            </div>
          </div>
        ),
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }

    if (header?.field == "assetClass") {
      if (data.benchmarkName) {
        return {
          ...header,
          colspan: data?.colspan,
          field: data[`${header.field}`] ? (
            <>
              {" "}
              <span
                style={{
                  opacity: "0.5",
                  paddingRight: "4px",
                  whiteSpace: "nowrap",
                }}
              >
                {/* {data?.asset + " "} */}
                {"Benchmark : "}{" "}
              </span>
              <div>
                {` `} {String(data[`${header.field}`])}
              </div>
            </>
          ) : data[`product`] ? (
            String(data[`product`])
          ) : (
            "--"
          ),
          color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
        };
      }
      return {
        ...header,
        field: data[`${header.field}`]
          ? String(data[`${header.field}`])
          : data[`product`]
          ? String(data[`product`])
          : "--",
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }
    if (header.field == "asOnDate") {
      return {
        ...header,
        field: data[`${header.field}`]
          ? moment(data[`${header.field}`], "DD MMM YYYY").format("DD-MM-YYYY")
          : "--",
      };
    }
    if (dateFormatingArrayPrint.includes(header?.field)) {
      return {
        ...header,
        field: data[`${header.field}`]
          ? moment(data[`${header.field}`]).format("DD-MMM-YYYY")
          : "--",
      };
    }

    if (onlyPercentage.includes(header?.field)) {
      return {
        ...header,
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`])) + "%"
          : data[`${header.field}`] || "--",
      };
    }
    if (percentage.includes(header?.field)) {
      if (
        header?.field == "TWRR_annualised" ||
        header?.field == "annualisedTwrr" ||
        header?.field == "XIRR"
      ) {
        return {
          ...header,
          field: !isNaN(data[`${header.field}`])
            ? roundNumber(Number(data[`${header.field}`])) + "%"
            : data[`${header.field}`] || "--",
          posOrNeg: checkNumberPositiveOrNeg(data[`${header.field}`]),
        };
      }
      return {
        ...header,
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`])) + "%"
          : data[`${header.field}`] || "--",
        posOrNeg: checkNumberPositiveOrNeg(data[`${header.field}`]),
      };
    }
    if (
      header.title == "Policy" &&
      data.schemeName != "Grand Total" &&
      data.schemeName != "Total" &&
      title == "holdingSummary"
    ) {
      if (data.schemeName == "Sum Total") {
        return {
          ...header,
          subTotal: "Sum Total",
          field: data[`${header?.field}`] ? (
            <>
              {" "}
              <div>
                {String(data[`${header.field}`])}
                <div
                  style={{
                    transform: " skew(-5deg)",
                    fontSize: "12px",
                    fontFamily: "Avenir",
                  }}
                >
                  {/* As on: {data.asOnDate} */}
                  {data?.asset}
                </div>
              </div>
            </>
          ) : (
            "--"
          ),
          color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
        };
      }

      return {
        ...header,
        field: data[`${header?.field}`] ? (
          <>
            {" "}
            <div>
              {String(data[`${header.field}`])}
              <div style={{ transform: " skew(-5deg)", fontSize: "12px" }}>
                {/* As on: {data.asOnDate} */}
                Weightage: {roundNumber(data?.weightage)} %
              </div>
            </div>
          </>
        ) : (
          "--"
        ),
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }
    if (
      ((header.field == "schemeName" &&
        data.schemeName != "Grand Total" &&
        data.schemeName != "Total") ||
        (header.field == "member" &&
          data.member != "Grand Total" &&
          data.member != "Total")) &&
      title == "holdingSummary"
    ) {
      if (data.schemeName == "Sum Total") {
        return {
          ...header,
          subTotal: "Sum Total",
          field: data[`${header?.field}`] ? (
            <>
              {" "}
              <div style={{ display: "flex" }}>
                <div>
                  {String(data[`${header.field}`])}
                  <div
                    style={{
                      transform: " skew(-5deg)",
                      fontSize: "12px",
                      fontFamily: "Avenir",
                    }}
                  >
                    {/* As on: {data.asOnDate} */}
                    {data?.asset}
                  </div>
                </div>

                {header?.showInstruments && (
                  <div style={{ marginLeft: "15px" }}>
                    <span>| Total Instruments - </span>
                    {data?.["instruments"]}
                  </div>
                )}
              </div>
            </>
          ) : (
            "--"
          ),
          color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
        };
      }
      if (data.schemeName == "Grand Total" && header?.showInstruments) {
        return {
          ...header,
          title2: "Grand Total",
          field: data[`${header?.field}`] ? (
            <>
              {" "}
              <div style={{ display: "flex" }}>
                <div>{String(data[`${header.field}`])}</div>

                {header?.showInstruments && (
                  <div style={{ marginLeft: "15px" }}>
                    <span>| Total Instruments - </span>
                    {data?.["instruments"]}
                  </div>
                )}
              </div>
            </>
          ) : (
            "--"
          ),
          color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
        };
      }

      return {
        ...header,
        field: data[`${header?.field}`] ? (
          <>
            {" "}
            <div>
              <span style={{ color: "#dcaf3d", paddingRight: "2px" }}>
                {data?.["isHeld"] == 0 ? " # " : ""}
              </span>{" "}
              {String(data[`${header.field}`])}
              <span style={{ color: "#dcaf3d", fontSize: "15px" }}>
                {data?.["isPromoterStake"] == 1 ? "**" : ""}
              </span>
              <div style={{ transform: " skew(-5deg)", fontSize: "12px" }}>
                {/* As on: {data.asOnDate} */}
                Weightage: {roundNumber(data?.weightage)} %
              </div>
            </div>
          </>
        ) : (
          "--"
        ),
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }

    if (doNothingArrayPrint.includes(header?.field)) {
      return {
        ...header,
        field: data[`${header?.field}`]
          ? String(data[`${header.field}`])
          : "--",
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }
    if (
      header?.field == "unRealisedGainLoss12" ||
      header?.field == "unRealisedGainLoss" ||
      header?.field == "Gain/Loss"
    ) {
      // console.log(data[`${header.field}`], "aglnalk");
      return {
        ...header,
        field:
          currencyFormat(
            data[`${header.field}`] || 0,
            undefined,
            denomination,
            fontclass
          ) || "--",
        posOrNeg: checkNumberPositiveOrNeg(data[`${header.field}`]),
      };
    }

    if (currencyFormatCheckArrayPrint.includes(header?.field)) {
      if (header?.field === "price") {
        return {
          ...header,
          field: data[`${header.field}`]
            ? currencyFormat(
                data[`${header.field}`] || 0,
                { showDecimal: true },
                "Actuals",
                fontclass
              ) || "--"
            : "--",
        };
      }
      if (header?.field === "otherIncome") {
        return {
          ...header,
          field: data[`${header.field}`]
            ? currencyFormat(
                data[`${header.field}`] || 0,
                undefined,
                denomination,
                fontclass
              ) || "--"
            : "--",
        };
      }

      if (
        (header?.field === "investmentValue" ||
          header?.field === "totalCost") &&
        header.subdata &&
        data.schemeName != "Grand Total" &&
        data.schemeName != "Total" &&
        data.schemeName != "Sum Total"
      ) {
        return {
          ...header,
          withtwoline: true,
          field: (
            <div>
              {currencyFormat(
                data[`${header.field}`] || 0,
                undefined,
                denomination,
                fontclass
              ) || "--"}
              <div style={{ transform: " skew(-5deg)", fontSize: "12px" }}>
                {data[`${header.subdata}`]
                  ? currencyFormat(
                      data[`${header.subdata}`] || 0,
                      undefined,
                      denomination,
                      fontclass
                    ) || "--"
                  : "--"}
              </div>
            </div>
          ),
        };
      }

      if (
        (header?.field == "balance" &&
          data.bankName != "Grand Total" &&
          data.bankName != "Total" &&
          data.schemeName != "Sum Total") ||
        (header.field == "currentValue" &&
          data.schemeName != "Grand Total" &&
          data.schemeName != "Total" &&
          data.schemeName != "Sum Total")
      ) {
        return {
          ...header,
          withtwoline: true,
          field: (
            <div>
              <div className="p_flex">
                <div>
                  {currencyFormat(
                    data[`${header.field}`] || 0,
                    undefined,
                    denomination,
                    fontclass
                  ) || "--"}{" "}
                  <div
                    style={{
                      transform: " skew(-5deg)",
                      fontSize: "12px",
                    }}
                  >
                    {data?.asOnDate ? data?.asOnDate : "--"}
                  </div>
                </div>

                <span
                  className="fAvenirBold"
                  style={{
                    color: "#dcaf3d",
                    marginLeft: "3px",
                    fontWeight: "900",
                    display: data?.["CurrentValIndicator"] ? "" : "none",
                  }}
                >
                  {data?.["CurrentValIndicator"] ? "^" : ""}
                </span>
              </div>
            </div>
          ),
        };
      }
      if (denomination) {
        return {
          ...header,
          field:
            currencyFormat(
              data[`${header.field}`] || 0,
              undefined,
              denomination,
              fontclass
            ) || "--",
        };
      }

      return {
        ...header,
        field:
          currencyFormat(
            data[`${header.field}`] || 0,
            undefined,
            getDenomination,
            fontclass
          ) || "--",
      };
    }
    if (assetvbench.includes(header?.field)) {
      // console.log(header?.field, "asfkhakjsbbf1");
      let classname = "";
      if (!data.benchmarkName && parseFloat(data[`${header.field}`]) > 0) {
        classname = "pos";
      } else if (
        !data.benchmarkName &&
        parseFloat(data[`${header.field}`]) < 0
      ) {
        classname = "neg";
      } else {
        if (parseFloat(data[`${header?.percent}`]) > 0) {
          classname = "pos";
        } else if (parseFloat(data[`${header?.percent}`]) < 0) {
          classname = "neg";
        }
      }
      if (denomination) {
        return {
          ...header,
          field: (
            <div className={classname + " p_flex"}>
              {!data.benchmarkName &&
                (currencyFormat(
                  data[`${header.field}`] || 0,
                  undefined,
                  denomination,
                  fontclass
                ) ||
                  "--")}{" "}
              {data.benchmarkName && (
                <span className={"font500 fAvenirBold "}>
                  {roundNumber(data[`${header?.percent}`]) + "%"}
                </span>
              )}
              {!data.benchmarkName && (
                <span className={"font500 fAvenirBold "}>
                  ({roundNumber(data[`${header?.percent}`]) + "%"})
                </span>
              )}
            </div>
          ),
        };
      }

      return {
        ...header,
        field: (
          <div className={classname + " p_flex"}>
            {currencyFormat(
              data[`${header.field}`] || 0,
              undefined,
              getDenomination,
              fontclass
            ) || "--"}{" "}
            <span className={"font500 fAvenirBold " + classname}>
              ({roundNumber(data[`${header?.percent}`]) + "%"})
            </span>
          </div>
        ),
      };
    }
    if (benchmarkVasset.includes(header?.field)) {
      let classname = "";
      if (parseFloat(data[`${header.field}`]) > 0) {
        classname = "pos";
      } else if (parseFloat(data[`${header.field}`]) < 0) {
        classname = "neg";
      }
      return {
        ...header,
        field: (
          <>
            <span className={"font500 fAvenirBold " + classname}>
              ({roundNumber(data[`${header?.field}`]) + "%"})
            </span>
          </>
        ),
      };
    }
    return {
      ...header,
      field: !isNaN(data[`${header.field}`])
        ? roundNumber(Number(data[`${header.field}`]))
        : data[`${header.field}`] || "--",
    };
  });
  return newData;
};

export const objectFormatingFamilyHolding = (
  data,
  headerData,
  key,
  denomination,
  total,
  title
) => {
  let fontclass = " ";
  if (total) {
    fontclass = "font900";
  }
  let folio = headerData.find((h) => {
    if (h.field == "folioNo") {
      return true;
    }
  });
  let newData = headerData.map((header) => {
    // console.log("56437534gh", data, header?.title);
    if (
      data.schemeName == "Grand Total" &&
      header?.field == "units" &&
      !folio
    ) {
      if (data.noUniqueInstruments) {
        return {
          ...header,
          field: (
            <div className="nowrap">
              <span>Total Instruments - </span>
              {data?.["instruments"]}
            </div>
          ),
          color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
        };
      }
      return {
        ...header,
        field: (
          <div className="tableSubDivide">
            <div>
              <span>Total Instruments - </span>
              {data?.["instruments"]}
            </div>
            <div>
              <span>Unique Instruments - </span>
              {data?.["uniqueInstruments"]}
            </div>
          </div>
        ),
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }
    if (header?.title == "Units") {
      return {
        ...header,
        field: handleUnitsData(data),
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }
    if (header?.title == "Quantity") {
      const formattedQuantity = formatNumberWithCommas(data[header.field]);
      return {
        ...header,
        // field: handleUnitsData(data),
        field: formattedQuantity,
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }

    if (
      data.schemeName == "Grand Total" &&
      (header?.field == "folioNo" ||
        header?.field == "type" ||
        header?.field == "insType")
    ) {
      if (data.noUniqueInstruments) {
        return {
          ...header,
          field: (
            <div className="nowrap">
              <span>Total Instruments - </span>
              {data?.["instruments"]}
            </div>
          ),
          color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
        };
      }
      return {
        ...header,
        field: (
          <div className="tableSubDivide">
            <div>
              <span>Total Instruments - </span>
              {data?.["instruments"]}
            </div>
            <div>
              <span>Unique Instruments - </span>
              {data?.["uniqueInstruments"]}
            </div>
          </div>
        ),
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }

    if (header?.field == "assetClass") {
      if (data.benchmarkName) {
        return {
          ...header,
          field: data[`${header.field}`] ? (
            <>
              {" "}
              <span
                style={{
                  opacity: "0.5",
                  paddingRight: "4px",
                  whiteSpace: "nowrap",
                }}
              >
                {/* {data?.asset + " "} */}
                {"Benchmark : "}{" "}
              </span>
              <div>
                {` `} {String(data[`${header.field}`])}
              </div>
            </>
          ) : data[`product`] ? (
            String(data[`product`])
          ) : (
            "--"
          ),
          color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
        };
      }
      return {
        ...header,
        field: data[`${header.field}`]
          ? String(data[`${header.field}`])
          : data[`product`]
          ? String(data[`product`])
          : "--",
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }

    if (
      ((header.field == "schemeName" &&
        data.schemeName != "Grand Total" &&
        data.schemeName != "Total") ||
        (header.field == "member" &&
          data.member != "Grand Total" &&
          data.member != "Total")) &&
      title == "holdingSummary"
    ) {
      return {
        ...header,
        field: data[`${header?.field}`] ? (
          <>
            {" "}
            <div>
              <span style={{ color: "#dcaf3d", paddingRight: "2px" }}>
                {data?.["isHeld"] == 0 ? " # " : ""}
              </span>{" "}
              {String(data[`${header.field}`])}
              <span style={{ color: "#dcaf3d", fontSize: "15px" }}>
                {data?.["isPromoterStake"] == 1 ? "**" : ""}
              </span>
              <div style={{ transform: " skew(-5deg)", fontSize: "12px" }}>
                {/* As on: {data.asOnDate} */}
                Weightage: {roundNumber(data?.weightage)} %
              </div>
            </div>
          </>
        ) : (
          "--"
        ),
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }

    if (doNothingArrayPrint.includes(header?.field)) {
      return {
        ...header,
        field: data[`${header?.field}`]
          ? String(data[`${header.field}`])
          : "--",
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }

    if (
      [
        "equity",
        "debt",
        "alternate",
        "cash",
        "totalPortfolioValue",
        "total",
      ].includes(header?.field)
    ) {
      if (header?.field === "price") {
        return {
          ...header,
          field: data[`${header.field}`]
            ? currencyFormat(
                data[`${header.field}`] || 0,
                { showDecimal: true },
                "Actuals",
                fontclass
              ) || "--"
            : "--",
        };
      }
      if (header?.field === "otherIncome") {
        return {
          ...header,
          field: data[`${header.field}`]
            ? currencyFormat(
                data[`${header.field}`] || 0,
                undefined,
                denomination,
                fontclass
              ) || "--"
            : "--",
        };
      }

      if (
        (header?.field === "investmentValue" ||
          header?.field === "totalCost") &&
        header.subdata &&
        data.schemeName != "Grand Total" &&
        data.schemeName != "Total"
      ) {
        return {
          ...header,
          withtwoline: true,
          field: (
            <div>
              {currencyFormat(
                data[`${header.field}`] || 0,
                undefined,
                denomination,
                fontclass
              ) || "--"}
              <div style={{ transform: " skew(-5deg)", fontSize: "12px" }}>
                {data[`${header.subdata}`]
                  ? currencyFormat(
                      data[`${header.subdata}`] || 0,
                      undefined,
                      denomination,
                      fontclass
                    ) || "--"
                  : "--"}
              </div>
            </div>
          ),
        };
      }

      if (
        (header?.field == "balance" &&
          data.bankName != "Grand Total" &&
          data.bankName != "Total") ||
        (header.field == "currentValue" &&
          data.schemeName != "Grand Total" &&
          data.schemeName != "Total")
      ) {
        return {
          ...header,
          withtwoline: true,
          field: (
            <div>
              <div className="p_flex">
                <div>
                  {currencyFormat(
                    data[`${header.field}`] || 0,
                    undefined,
                    denomination,
                    fontclass
                  ) || "--"}{" "}
                  <div
                    style={{
                      transform: " skew(-5deg)",
                      fontSize: "12px",
                    }}
                  >
                    {data?.asOnDate ? data?.asOnDate : "--"}
                  </div>
                </div>

                <span
                  className="fAvenirBold"
                  style={{
                    color: "#dcaf3d",
                    marginLeft: "3px",
                    fontWeight: "900",
                    display: data?.["CurrentValIndicator"] ? "" : "none",
                  }}
                >
                  {data?.["CurrentValIndicator"] ? "^" : ""}
                </span>
              </div>
            </div>
          ),
        };
      }
      if (denomination) {
        return {
          ...header,
          field: (
            <div
              className={
                header.field == "totalPortfolioValue"
                  ? "font900 fAvenirBold"
                  : ""
              }
            >
              {currencyFormat(
                data[`${header.field}`] || 0,
                undefined,
                denomination,
                fontclass
              ) || "--"}
            </div>
          ),
        };
      }

      return {
        ...header,
        field:
          currencyFormat(
            data[`${header.field}`] || 0,
            undefined,
            getDenomination,
            fontclass
          ) || "--",
      };
    }

    return {
      ...header,
      field: !isNaN(data[`${header.field}`])
        ? roundNumber(Number(data[`${header.field}`]))
        : data[`${header.field}`] || "--",
    };
  });
  return newData;
};
//
export const objectFormatingIncomeHolding = (
  data,
  headerData,
  key,
  denomination,
  total,
  title
) => {
  let fontclass = " ";
  if (total) {
    fontclass = "font900";
  }
  let folio = headerData.find((h) => {
    if (h.field == "folioNo") {
      return true;
    }
  });
  let newData = headerData.map((header) => {
    // console.log("56437534gh", data, header?.title);
    // if (
    //   data.schemeName == "Grand Total" &&
    //   header?.field == "units" &&
    //   !folio
    // ) {
    //   if (data.noUniqueInstruments) {
    //     return {
    //       ...header,
    //       field: (
    //         <div className="nowrap">
    //           <span>Total Instruments - </span>
    //           {data?.["instruments"]}
    //         </div>
    //       ),
    //       color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
    //     };
    //   }
    //   return {
    //     ...header,
    //     field: (
    //       <div className="tableSubDivide">
    //         <div>
    //           <span>Total Instruments - </span>
    //           {data?.["instruments"]}
    //         </div>
    //         <div>
    //           <span>Unique Instruments - </span>
    //           {data?.["uniqueInstruments"]}
    //         </div>
    //       </div>
    //     ),
    //     color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
    //   };
    // }
    if (header?.title == "Units") {
      return {
        ...header,
        field: handleUnitsData(data),
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }
    if (header?.title == "Quantity") {
      const formattedQuantity = formatNumberWithCommas(data[header.field]);
      return {
        ...header,
        // field: handleUnitsData(data),
        field: formattedQuantity,
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }

    // if (
    //   data.schemeName == "Grand Total" &&
    //   (header?.field == "folioNo" ||
    //     header?.field == "type" ||
    //     header?.field == "insType")
    // ) {
    //   if (data.noUniqueInstruments) {
    //     return {
    //       ...header,
    //       field: (
    //         <div className="nowrap">
    //           <span>Total Instruments - </span>
    //           {data?.["instruments"]}
    //         </div>
    //       ),
    //       color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
    //     };
    //   }
    //   return {
    //     ...header,
    //     field: (
    //       <div className="tableSubDivide">
    //         <div>
    //           <span>Total Instruments - </span>
    //           {data?.["instruments"]}
    //         </div>
    //         <div>
    //           <span>Unique Instruments - </span>
    //           {data?.["uniqueInstruments"]}
    //         </div>
    //       </div>
    //     ),
    //     color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
    //   };
    // }

    // if (header?.field == "assetClass") {
    //   if (data.benchmarkName) {
    //     return {
    //       ...header,
    //       field: data[`${header.field}`] ? (
    //         <>
    //           {" "}
    //           <span
    //             style={{
    //               opacity: "0.5",
    //               paddingRight: "4px",
    //               whiteSpace: "nowrap",
    //             }}
    //           >
    //             {/* {data?.asset + " "} */}
    //             {"Benchmark : "}{" "}
    //           </span>
    //           <div>
    //             {` `} {String(data[`${header.field}`])}
    //           </div>
    //         </>
    //       ) : data[`product`] ? (
    //         String(data[`product`])
    //       ) : (
    //         "--"
    //       ),
    //       color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
    //     };
    //   }
    //   return {
    //     ...header,
    //     field: data[`${header.field}`]
    //       ? String(data[`${header.field}`])
    //       : data[`product`]
    //       ? String(data[`product`])
    //       : "--",
    //     color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
    //   };
    // }

    // if (
    //   ((header.field == "schemeName" &&
    //     data.schemeName != "Grand Total" &&
    //     data.schemeName != "Total") ||
    //     (header.field == "member" &&
    //       data.member != "Grand Total" &&
    //       data.member != "Total")) &&
    //   title == "holdingSummary"
    // ) {
    //   return {
    //     ...header,
    //     field: data[`${header?.field}`] ? (
    //       <>
    //         {" "}
    //         <div>
    //           <span style={{ color: "#dcaf3d", paddingRight: "2px" }}>
    //             {data?.["isHeld"] == 0 ? " # " : ""}
    //           </span>{" "}
    //           {String(data[`${header.field}`])}
    //           <span style={{ color: "#dcaf3d", fontSize: "15px" }}>
    //             {data?.["isPromoterStake"] == 1 ? "**" : ""}
    //           </span>
    //           <div style={{ transform: " skew(-5deg)", fontSize: "12px" }}>
    //             {/* As on: {data.asOnDate} */}
    //             Weightage: {roundNumber(data?.weightage)} %
    //           </div>
    //         </div>
    //       </>
    //     ) : (
    //       "--"
    //     ),
    //     color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
    //   };
    // }

    if (doNothingArrayPrint.includes(header?.field)) {
      return {
        ...header,
        field: data[`${header?.field}`]
          ? String(data[`${header.field}`])
          : "--",
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }

    if (
      [
        "Statement Of Dividend",
        "Statement Of Interest",
        "Statement Of Other Income",
        "Principal Payback Summary",
        "total",
      ].includes(header?.field)
    ) {
      if (header?.field === "price") {
        return {
          ...header,
          field: data[`${header.field}`]
            ? currencyFormat(
                data[`${header.field}`] || 0,
                { showDecimal: true },
                "Actuals",
                fontclass
              ) || "--"
            : "--",
        };
      }
      // if (header?.field === "otherIncome") {
      //   return {
      //     ...header,
      //     field: data[`${header.field}`]
      //       ? currencyFormat(
      //           data[`${header.field}`] || 0,
      //           undefined,
      //           denomination,
      //           fontclass
      //         ) || "--"
      //       : "--",
      //   };
      // }

      // if (
      //   (header?.field === "investmentValue" ||
      //     header?.field === "totalCost") &&
      //   header.subdata &&
      //   data.schemeName != "Grand Total" &&
      //   data.schemeName != "Total"
      // ) {
      //   return {
      //     ...header,
      //     withtwoline: true,
      //     field: (
      //       <div>
      //         {currencyFormat(
      //           data[`${header.field}`] || 0,
      //           undefined,
      //           denomination,
      //           fontclass
      //         ) || "--"}
      //         <div style={{ transform: " skew(-5deg)", fontSize: "12px" }}>
      //           {data[`${header.subdata}`]
      //             ? currencyFormat(
      //                 data[`${header.subdata}`] || 0,
      //                 undefined,
      //                 denomination,
      //                 fontclass
      //               ) || "--"
      //             : "--"}
      //         </div>
      //       </div>
      //     ),
      //   };
      // }

      // if (
      //   (header?.field == "balance" &&
      //     data.bankName != "Grand Total" &&
      //     data.bankName != "Total") ||
      //   (header.field == "currentValue" &&
      //     data.schemeName != "Grand Total" &&
      //     data.schemeName != "Total")
      // ) {
      //   return {
      //     ...header,
      //     withtwoline: true,
      //     field: (
      //       <div>
      //         <div className="p_flex">
      //           <div>
      //             {currencyFormat(
      //               data[`${header.field}`] || 0,
      //               undefined,
      //               denomination,
      //               fontclass
      //             ) || "--"}{" "}
      //             <div
      //               style={{
      //                 transform: " skew(-5deg)",
      //                 fontSize: "12px",
      //               }}
      //             >
      //               {data?.asOnDate ? data?.asOnDate : "--"}
      //             </div>
      //           </div>

      //           <span
      //             className="fAvenirBold"
      //             style={{
      //               color: "#dcaf3d",
      //               marginLeft: "3px",
      //               fontWeight: "900",
      //               display: data?.["CurrentValIndicator"] ? "" : "none",
      //             }}
      //           >
      //             {data?.["CurrentValIndicator"] ? "^" : ""}
      //           </span>
      //         </div>
      //       </div>
      //     ),
      //   };
      // }
      if (denomination) {
        return {
          ...header,
          field: (
            <div
              className={
                header.field == "totalPortfolioValue"
                  ? "font900 fAvenirBold"
                  : ""
              }
            >
              {currencyFormat(
                data[`${header.field}`] || 0,
                undefined,
                denomination,
                fontclass
              ) || "--"}
            </div>
          ),
        };
      }

      return {
        ...header,
        field:
          currencyFormat(
            data[`${header.field}`] || 0,
            undefined,
            getDenomination,
            fontclass
          ) || "--",
      };
    }

    return {
      ...header,
      field: !isNaN(data[`${header.field}`])
        ? roundNumber(Number(data[`${header.field}`]))
        : data[`${header.field}`] || "--",
    };
  });
  return newData;
};

export const objectFormatingWithClientHoldingTitleCOl = (
  data,
  headerData,
  key,
  denomination,
  total,
  title
) => {
  let fontclass = " ";
  if (total) {
    fontclass = "font900";
  }
  let folio = headerData.find((h) => {
    if (h.field == "folioNo") {
      return true;
    }
  });
  let newData = headerData.map((header) => {
    // console.log("56437534gh", data, header?.title);

    if (
      data.schemeName == "Grand Total" &&
      header?.field == "units" &&
      !folio
    ) {
      return {
        ...header,
        field: (
          <div className="">
            <div className="nowrap">
              <span>Total Instruments - {data?.["instruments"]}</span>
            </div>
            {/* <div>
              <span>Unique Instruments - </span>
              {data?.["uniqueInstruments"]}
            </div> */}
          </div>
        ),
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }
    if (header?.title == "Units") {
      return {
        ...header,
        field: handleUnitsData(data),
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }

    if (
      data.schemeName == "Grand Total" &&
      (header?.field == "folioNo" ||
        header?.field == "type" ||
        header?.field == "insType")
    ) {
      return {
        ...header,
        field: (
          <div className="">
            <div className="nowrap">
              <span>Total Instruments - {data?.["instruments"]}</span>
            </div>
            {/* <div>
              <span>Unique Instruments - </span>
              {data?.["uniqueInstruments"]}
            </div> */}
          </div>
        ),
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }
    if (header?.title == "Quantity") {
      const formattedQuantity = formatNumberWithCommas(data[header.field]);
      return {
        ...header,
        // field: handleUnitsData(data),
        field: formattedQuantity,
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }
    if (
      (header?.field === "investmentValue" || header?.field === "totalCost") &&
      header.subdata &&
      data.schemeName != "Grand Total" &&
      data.schemeName != "Total"
    ) {
      return {
        ...header,
        withtwoline: true,
        field: (
          <div>
            {currencyFormat(
              data[`${header.field}`] || 0,
              undefined,
              denomination,
              fontclass
            ) || "--"}
            <div style={{ transform: " skew(-5deg)", fontSize: "12px" }}>
              {data[`${header.subdata}`]
                ? currencyFormat(
                    data[`${header.subdata}`] || 0,
                    undefined,
                    denomination,
                    fontclass
                  ) || "--"
                : "--"}
            </div>
          </div>
        ),
      };
    }

    if (header?.field == "assetClass") {
      return {
        ...header,
        field: data[`${header.field}`]
          ? String(data[`${header.field}`])
          : data[`product`]
          ? String(data[`product`])
          : "--",
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }
    if (header.field == "asOnDate") {
      return {
        ...header,
        field: data[`${header.field}`]
          ? moment(data[`${header.field}`], "DD MMM YYYY").format("DD-MM-YYYY")
          : "--",
      };
    }
    if (dateFormatingArrayPrint.includes(header?.field)) {
      return {
        ...header,
        field: data[`${header.field}`]
          ? moment(data[`${header.field}`]).format("DD-MMM-YYYY")
          : "--",
      };
    }

    if (onlyPercentage.includes(header?.field)) {
      return {
        ...header,
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`])) + "%"
          : data[`${header.field}`] || "--",
      };
    }
    if (percentage.includes(header?.field)) {
      if (
        header?.field == "TWRR_annualised" ||
        header?.field == "annualisedTwrr" ||
        header?.field == "XIRR"
      ) {
        return {
          ...header,
          field: !isNaN(data[`${header.field}`])
            ? roundNumber(Number(data[`${header.field}`])) + "%"
            : data[`${header.field}`] || "--",
          posOrNeg: checkNumberPositiveOrNeg(data[`${header.field}`]),
        };
      }
      return {
        ...header,
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`])) + "%"
          : data[`${header.field}`] || "--",
        posOrNeg: checkNumberPositiveOrNeg(data[`${header.field}`]),
      };
    }
    if (
      (header.title == "Policy" || header.field == "bankName") &&
      data.schemeName != "Grand Total" &&
      data.schemeName != "Total" &&
      title == "ClientholdingSummary"
    ) {
      return {
        ...header,
        field: data[`${header?.field}`] ? (
          <>
            {" "}
            <div>
              {String(data[`${header.field}`])}
              <div style={{ transform: " skew(-5deg)", fontSize: "12px" }}>
                {"   weightage: "}
                {roundNumber(data?.weightage)}% |{" "}
                {moment(data.initialPurchaseDate, "YYYY-MM-DD").format(
                  "Do MMM YYYY"
                )}
                <span style={{ color: "#dcaf3d", fontSize: "15px" }}>
                  {data?.["hasMultipleBuyTransactions"] ? "*" : ""}
                </span>
              </div>
            </div>
          </>
        ) : (
          "--"
        ),
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }
    if (
      ((header.field == "schemeName" &&
        data.schemeName != "Grand Total" &&
        data.schemeName != "Total") ||
        (header.field == "member" &&
          data.member != "Grand Total" &&
          data.member != "Total")) &&
      title == "ClientholdingSummary"
    ) {
      return {
        ...header,
        field: data[`${header?.field}`] ? (
          <>
            {" "}
            <div>
              <span style={{ color: "#dcaf3d", paddingRight: "2px" }}>
                {data?.["isHeld"] == 0 ? " # " : ""}
              </span>{" "}
              {String(data[`${header.field}`])}{" "}
              <span style={{ color: "#dcaf3d", fontSize: "15px" }}>
                {data?.["isPromoterStake"] == 1 ? "**" : ""}
              </span>
              <div style={{ transform: " skew(-5deg)", fontSize: "12px" }}>
                {"   weightage: "}
                {roundNumber(data?.weightage)}% |{" "}
                {moment(data.initialPurchaseDate, "YYYY-MM-DD").format(
                  "Do MMM YYYY"
                )}
                <span style={{ color: "#dcaf3d", fontSize: "15px" }}>
                  {data?.["hasMultipleBuyTransactions"] ? "*" : ""}
                </span>
              </div>
            </div>
          </>
        ) : (
          "--"
        ),
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }

    if (doNothingArrayPrint.includes(header?.field)) {
      return {
        ...header,
        field: data[`${header?.field}`]
          ? String(data[`${header.field}`])
          : "--",
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }
    if (
      header?.field == "unRealisedGainLoss12" ||
      header?.field == "unRealisedGainLoss" ||
      header?.field == "Gain/Loss"
    ) {
      // console.log(data[`${header.field}`], "aglnalk");
      return {
        ...header,
        field:
          currencyFormat(
            data[`${header.field}`] || 0,
            undefined,
            denomination,
            fontclass
          ) || "--",
        posOrNeg: checkNumberPositiveOrNeg(data[`${header.field}`]),
      };
    }
    if (currencyFormatCheckArrayPrint.includes(header?.field)) {
      if (header?.field === "price") {
        return {
          ...header,
          field: data[`${header.field}`]
            ? currencyFormat(
                data[`${header.field}`] || 0,
                { showDecimal: true },
                "Actuals",
                fontclass
              ) || "--"
            : "--",
        };
      }
      if (header?.field === "otherIncome") {
        return {
          ...header,
          field: data[`${header.field}`]
            ? currencyFormat(
                data[`${header.field}`] || 0,
                undefined,
                denomination,
                fontclass
              ) || "--"
            : "--",
        };
      }

      if (
        (header?.field == "balance" &&
          data.bankName != "Grand Total" &&
          data.bankName != "Total") ||
        (header.field == "currentValue" &&
          data.schemeName != "Grand Total" &&
          data.schemeName != "Total")
      ) {
        return {
          ...header,
          withtwoline: true,
          field: (
            <div className="p_flex">
              <div>
                {currencyFormat(
                  data[`${header.field}`] || 0,
                  undefined,
                  denomination,
                  fontclass
                ) || "--"}{" "}
                <div
                  style={{
                    transform: " skew(-5deg)",
                    fontSize: "12px",
                  }}
                >
                  {data?.asOnDate
                    ? moment(data?.asOnDate, "YYYY-MM-DD").isValid()
                      ? moment(data?.asOnDate, "YYYY-MM-DD").format(
                          "Do MMM YYYY"
                        )
                      : data?.asOnDate
                    : "--"}{" "}
                </div>
              </div>

              <span
                className="fAvenirBold"
                style={{
                  color: "#dcaf3d",
                  marginLeft: "2px",
                  fontWeight: "900",
                }}
              >
                {data?.["CurrentValIndicator"] ? "^" : ""}
              </span>
            </div>
          ),
        };
      }
      if (denomination) {
        return {
          ...header,
          field:
            currencyFormat(
              data[`${header.field}`] || 0,
              undefined,
              denomination,
              fontclass
            ) || "--",
        };
      }

      return {
        ...header,
        field:
          currencyFormat(
            data[`${header.field}`] || 0,
            undefined,
            getDenomination,
            fontclass
          ) || "--",
      };
    }

    return {
      ...header,
      field: !isNaN(data[`${header.field}`])
        ? roundNumber(Number(data[`${header.field}`]))
        : data[`${header.field}`] || "--",
    };
  });
  return newData;
};
export const objectFormatingWithProductAllocationTitleCOl = (
  data,
  headerData,
  key,
  denomination,
  total,
  title
) => {
  let fontclass = " ";
  if (total) {
    fontclass = "font900";
  }
  let folio = headerData.find((h) => {
    if (h.field == "folioNo") {
      return true;
    }
  });
  let newData = headerData.map((header) => {
    // console.log("56437534gh", data, header?.title);

    if (header?.title == "Units") {
      return {
        ...header,
        field: handleUnitsData(data),
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }

    if (header?.title == "Quantity") {
      const formattedQuantity = formatNumberWithCommas(data[header.field]);
      return {
        ...header,
        // field: handleUnitsData(data),
        field: formattedQuantity,
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }
    if (header?.field === "currentValue" && data.product == "Total") {
      return {
        ...header,
        withtwoline: true,
        field: (
          <div>
            {currencyFormat(
              data[`${header.field}`] || 0,
              undefined,
              denomination,
              fontclass
            ) || "--"}
            <div style={{ height: "14px" }}></div>
          </div>
        ),
      };
    }
    if (
      (header?.field === "portfolioValueAmount" ||
        header?.field === "currentValue" ||
        header?.field === "investedAmount") &&
      header.subdata &&
      data.schemeName != "Grand Total" &&
      data.product != "Total"
    ) {
      return {
        ...header,
        withtwoline: true,
        field: (
          <div>
            {currencyFormat(
              data[`${header.field}`] || 0,
              undefined,
              denomination,
              fontclass
            ) || "--"}
            <div style={{ transform: " skew(-5deg)", fontSize: "12px" }}>
              {data[`${header.subdata}`]
                ? moment(data[`${header.subdata}`], "DD/MM/YYYY").format(
                    "Do MMM YYYY"
                  ) || "--"
                : "--"}
            </div>
          </div>
        ),
      };
    }

    if (header?.field == "assetClass") {
      return {
        ...header,
        field: data[`${header.field}`]
          ? String(data[`${header.field}`])
          : data[`product`]
          ? String(data[`product`])
          : "--",
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }
    if (header.field == "asOnDate") {
      return {
        ...header,
        field: data[`${header.field}`]
          ? moment(data[`${header.field}`], "DD MMM YYYY").format("DD-MM-YYYY")
          : "--",
      };
    }
    if (dateFormatingArrayPrint.includes(header?.field)) {
      return {
        ...header,
        field: data[`${header.field}`]
          ? moment(data[`${header.field}`]).format("DD-MMM-YYYY")
          : "--",
      };
    }

    if (onlyPercentage.includes(header?.field)) {
      return {
        ...header,
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`])) + "%"
          : data[`${header.field}`] || "--",
      };
    }
    if (percentage.includes(header?.field)) {
      if (
        header?.field == "TWRR_annualised" ||
        header?.field == "annualisedTwrr" ||
        header?.field == "XIRR"
      ) {
        return {
          ...header,
          field: !isNaN(data[`${header.field}`])
            ? roundNumber(Number(data[`${header.field}`])) + "%"
            : data[`${header.field}`] || "--",
          posOrNeg: checkNumberPositiveOrNeg(data[`${header.field}`]),
        };
      }
      return {
        ...header,
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`])) + "%"
          : data[`${header.field}`] || "--",
        posOrNeg: checkNumberPositiveOrNeg(data[`${header.field}`]),
      };
    }
    if (
      (header.title == "Policy" || header.field == "bankName") &&
      data.schemeName != "Grand Total" &&
      data.schemeName != "Total" &&
      title == "ClientholdingSummary"
    ) {
      return {
        ...header,
        field: data[`${header?.field}`] ? (
          <>
            {" "}
            <div>
              {String(data[`${header.field}`])}
              <div style={{ transform: " skew(-5deg)", fontSize: "12px" }}>
                {"   weightage: "}
                {roundNumber(data?.weightage)}% |{" "}
                {moment(data.initialPurchaseDate, "YYYY-MM-DD").format(
                  "Do MMM YYYY"
                )}
                <span style={{ color: "#dcaf3d", fontSize: "15px" }}>
                  {data?.["hasMultipleBuyTransactions"] ? "*" : ""}
                </span>
              </div>
            </div>
          </>
        ) : (
          "--"
        ),
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }
    if (
      ((header.field == "schemeName" &&
        data.schemeName != "Grand Total" &&
        data.schemeName != "Total") ||
        (header.field == "member" &&
          data.member != "Grand Total" &&
          data.member != "Total")) &&
      title == "ClientholdingSummary"
    ) {
      return {
        ...header,
        field: data[`${header?.field}`] ? (
          <>
            {" "}
            <div>
              <span style={{ color: "#dcaf3d", paddingRight: "2px" }}>
                {data?.["isHeld"] == 0 ? " # " : ""}
              </span>{" "}
              {String(data[`${header.field}`])}
              <div style={{ transform: " skew(-5deg)", fontSize: "12px" }}>
                {"   weightage: "}
                {roundNumber(data?.weightage)}% |{" "}
                {moment(data.initialPurchaseDate, "YYYY-MM-DD").format(
                  "Do MMM YYYY"
                )}
                <span style={{ color: "#dcaf3d", fontSize: "15px" }}>
                  {data?.["hasMultipleBuyTransactions"] ? "*" : ""}
                </span>
              </div>
            </div>
          </>
        ) : (
          "--"
        ),
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }

    if (header?.field === "otherIncome" || header?.field === "interestIncome") {
      return {
        ...header,
        field: data[`${header.field}`]
          ? currencyFormat(
              data[`${header.field}`] || 0,
              undefined,
              denomination,
              fontclass
            ) || "--"
          : "--",
      };
    }
    if (doNothingArrayPrint.includes(header?.field)) {
      return {
        ...header,
        field: data[`${header?.field}`]
          ? String(data[`${header.field}`])
          : "--",
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }
    if (
      header?.field == "unRealisedGainLoss12" ||
      header?.field == "unRealisedGainLoss" ||
      header?.field == "Gain/Loss"
    ) {
      // console.log(data[`${header.field}`], "aglnalk");
      return {
        ...header,
        field:
          currencyFormat(
            data[`${header.field}`] || 0,
            undefined,
            denomination,
            fontclass
          ) || "--",
        posOrNeg: checkNumberPositiveOrNeg(data[`${header.field}`]),
      };
    }
    if (currencyFormatCheckArrayPrint.includes(header?.field)) {
      if (header?.field === "price") {
        return {
          ...header,
          field: data[`${header.field}`]
            ? currencyFormat(
                data[`${header.field}`] || 0,
                { showDecimal: true },
                "Actuals",
                fontclass
              ) || "--"
            : "--",
        };
      }

      if (
        (header?.field == "balance" &&
          data.bankName != "Grand Total" &&
          data.bankName != "Total") ||
        (header.field == "currentValue" &&
          data.schemeName != "Grand Total" &&
          data.schemeName != "Total")
      ) {
        return {
          ...header,
          withtwoline: true,
          field: (
            <div>
              {currencyFormat(
                data[`${header.field}`] || 0,
                undefined,
                denomination,
                fontclass
              ) || "--"}
              <div style={{ transform: " skew(-5deg)", fontSize: "12px" }}>
                {data?.asOnDate ? data?.asOnDate : "--"}
              </div>
            </div>
          ),
        };
      }
      if (denomination) {
        return {
          ...header,
          field:
            currencyFormat(
              data[`${header.field}`] || 0,
              undefined,
              denomination,
              fontclass
            ) || "--",
        };
      }

      return {
        ...header,
        field:
          currencyFormat(
            data[`${header.field}`] || 0,
            undefined,
            getDenomination,
            fontclass
          ) || "--",
      };
    }

    return {
      ...header,
      field: !isNaN(data[`${header.field}`])
        ? roundNumber(Number(data[`${header.field}`]))
        : data[`${header.field}`] || "--",
    };
  });
  return newData;
};
export const objectFormatingWithClientTopBottomTitleCOl = (
  data,
  headerData,
  key,
  denomination,
  total,
  title
) => {
  let fontclass = " ";
  if (total) {
    fontclass = "font900";
  }

  let newData = headerData.map((header) => {
    // console.log("56437534gh", data, header?.title);

    if (header?.title == "Units") {
      return {
        ...header,
        field: handleUnitsData(data),
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }
    if (header?.title == "Quantity") {
      const formattedQuantity = formatNumberWithCommas(data[header.field]);
      return {
        ...header,
        // field: handleUnitsData(data),
        field: formattedQuantity,
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }

    if (
      data.schemeName == "Grand Total" &&
      (header?.field == "folioNo" ||
        header?.field == "type" ||
        header?.field == "insType")
    ) {
      return {
        ...header,
        field: (
          <div className="tableSubDivide">
            <div>
              <span>Total Instruments - </span>
              {data?.["instruments"]}
            </div>
            <div>
              <span>Unique Instruments - </span>
              {data?.["uniqueInstruments"]}
            </div>
          </div>
        ),
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }

    if (header?.field == "assetClass") {
      return {
        ...header,
        field: data[`${header.field}`]
          ? String(data[`${header.field}`])
          : data[`product`]
          ? String(data[`product`])
          : "--",
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }
    if (header.field == "asOnDate") {
      return {
        ...header,
        field: data[`${header.field}`]
          ? moment(data[`${header.field}`], "DD MMM YYYY").format("DD-MM-YYYY")
          : "--",
      };
    }
    if (dateFormatingArrayPrint.includes(header?.field)) {
      return {
        ...header,
        field: data[`${header.field}`]
          ? moment(data[`${header.field}`]).format("DD-MMM-YYYY")
          : "--",
      };
    }

    if (onlyPercentage.includes(header?.field)) {
      return {
        ...header,
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`])) + "%"
          : data[`${header.field}`] || "--",
      };
    }
    if (percentage.includes(header?.field)) {
      if (
        header?.field == "TWRR_annualised" ||
        header?.field == "annualisedTwrr" ||
        header?.field == "XIRR"
      ) {
        return {
          ...header,
          field: !isNaN(data[`${header.field}`])
            ? roundNumber(Number(data[`${header.field}`])) + "%"
            : data[`${header.field}`] || "--",
          posOrNeg: checkNumberPositiveOrNeg(data[`${header.field}`]),
        };
      }
      return {
        ...header,
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`])) + "%"
          : data[`${header.field}`] || "--",
        posOrNeg: checkNumberPositiveOrNeg(data[`${header.field}`]),
      };
    }
    if (
      header.title == "Policy" &&
      data.schemeName != "Grand Total" &&
      data.schemeName != "Total" &&
      title == "holdingSummary"
    ) {
      return {
        ...header,
        withtwoline: true,
        field: data[`${header?.field}`] ? (
          <>
            {" "}
            <div>
              {String(data[`${header.field}`])}
              <div style={{ transform: " skew(-5deg)", fontSize: "12px" }}>
                {data?.asOnDate ? data?.asOnDate : "--"}
              </div>
            </div>
          </>
        ) : (
          "--"
        ),
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }
    if (
      ((header.field == "schemeName" &&
        data.schemeName != "Grand Total" &&
        data.schemeName != "Total") ||
        (header.field == "member" &&
          data.member != "Grand Total" &&
          data.member != "Total")) &&
      title == "ClientholdingSummary"
    ) {
      return {
        ...header,
        field: data[`${header?.field}`] ? (
          <>
            {" "}
            <div>
              <span style={{ color: "#dcaf3d", paddingRight: "2px" }}>
                {data?.["isHeld"] == 0 ? " # " : ""}
              </span>{" "}
              {String(data[`${header.field}`])}
              <div style={{ transform: " skew(-5deg)", fontSize: "12px" }}>
                {data.initialPurchaseDate} / {data.asOnDate}
              </div>
            </div>
          </>
        ) : (
          "--"
        ),
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }

    if (TopBottomDoNothingArr.includes(header?.field)) {
      return {
        ...header,
        field: data[`${header?.field}`]
          ? String(data[`${header.field}`])
          : "--",
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }
    if (
      header?.field == "unRealisedGainLoss12" ||
      header?.field == "unRealisedGainLoss" ||
      header?.field == "Gain/Loss"
    ) {
      // console.log(data[`${header.field}`], "aglnalk");
      return {
        ...header,
        field:
          currencyFormat(
            data[`${header.field}`] || 0,
            undefined,
            denomination,
            fontclass
          ) || "--",
        posOrNeg: checkNumberPositiveOrNeg(data[`${header.field}`]),
      };
    }
    if (currencyFormatCheckArrayPrint.includes(header?.field)) {
      if (header?.field === "price") {
        return {
          ...header,
          field: data[`${header.field}`]
            ? currencyFormat(
                data[`${header.field}`] || 0,
                { showDecimal: true },
                "Actuals",
                fontclass
              ) || "--"
            : "--",
        };
      }
      if (header?.field === "otherIncome") {
        return {
          ...header,
          field: data[`${header.field}`]
            ? currencyFormat(
                data[`${header.field}`] || 0,
                undefined,
                denomination,
                fontclass
              ) || "--"
            : "--",
        };
      }

      // if (
      //   (header?.field == "balance" &&
      //     data.bankName != "Grand Total" &&
      //     data.bankName != "Total") ||
      //   (header.field == "currentValue" &&
      //     data.schemeName != "Grand Total" &&
      //     data.schemeName != "Total")
      // ) {
      //   return {
      //     ...header,
      //     field: (
      //       <div>
      //         {currencyFormat(
      //           data[`${header.field}`] || 0,
      //           undefined,
      //           denomination,
      //           fontclass
      //         ) || "--"}
      //         <div style={{ transform: " skew(-5deg)", fontSize: "12px" }}>
      //           As on: {data.asOnDate}
      //         </div>
      //       </div>
      //     ),
      //   };
      // }
      if (denomination) {
        return {
          ...header,
          field:
            currencyFormat(
              data[`${header.field}`] || 0,
              undefined,
              denomination,
              fontclass
            ) || "--",
        };
      }

      return {
        ...header,
        field:
          currencyFormat(
            data[`${header.field}`] || 0,
            undefined,
            getDenomination,
            fontclass
          ) || "--",
      };
    }

    return {
      ...header,
      field: !isNaN(data[`${header.field}`])
        ? roundNumber(Number(data[`${header.field}`]))
        : data[`${header.field}`] || "--",
    };
  });
  return newData;
};
export const objectFormatingWithClientMemberWiseTitleCOl = (
  data,
  headerData,
  key,
  denomination,
  total,
  title
) => {
  let fontclass = " ";
  if (total) {
    fontclass = "font900";
  }
  let newData = headerData.map((header) => {
    // console.log("56437534gh", data, header?.title);
    // if (header?.title == "Units") {
    //   return {
    //     ...header,
    //     field: handleUnitsData(data),
    //     color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
    //   };
    // }
    // if (header?.title == "Quantity") {
    //   const formattedQuantity = formatNumberWithCommas(data[header.field]);
    //   return {
    //     ...header,
    //     // field: handleUnitsData(data),
    //     field: formattedQuantity,
    //     color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
    //   };
    // }

    // if (
    //   data.schemeName == "Grand Total" &&
    //   (header?.field == "folioNo" ||
    //     header?.field == "type" ||
    //     header?.field == "insType")
    // ) {
    //   return {
    //     ...header,
    //     field: (
    //       <div className="tableSubDivide">
    //         <div>
    //           <span>Total Instruments - </span>
    //           {data?.["instruments"]}
    //         </div>
    //         <div>
    //           <span>Unique Instruments - </span>
    //           {data?.["uniqueInstruments"]}
    //         </div>
    //       </div>
    //     ),
    //     color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
    //   };
    // }

    // if (header?.field == "assetClass") {
    //   return {
    //     ...header,
    //     field: data[`${header.field}`]
    //       ? String(data[`${header.field}`])
    //       : data[`product`]
    //       ? String(data[`product`])
    //       : "--",
    //     color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
    //   };
    // }

    if (dateFormatingArrayPrint.includes(header?.field)) {
      return {
        ...header,
        field: data[`${header.field}`]
          ? moment(data[`${header.field}`]).format("DD-MMM-YYYY")
          : "--",
      };
    }

    if (onlyPercentage.includes(header?.field)) {
      return {
        ...header,
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`])) + "%"
          : data[`${header.field}`] || "--",
      };
    }
    if (percentage.includes(header?.field)) {
      if (header?.field == "XIRR") {
        return {
          ...header,
          field: !isNaN(data[`${header.field}`])
            ? roundNumber(Number(data[`${header.field}`])) + "%"
            : data[`${header.field}`] || "--",
          posOrNeg: checkNumberPositiveOrNeg(data[`${header.field}`]),
        };
      }
      return {
        ...header,
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`])) + "%"
          : data[`${header.field}`] || "--",
        posOrNeg: checkNumberPositiveOrNeg(data[`${header.field}`]),
      };
    }

    if (MemberWiseDoNothingArr.includes(header?.field)) {
      if (data.isFamilyHead) {
        return {
          ...header,
          field: data[`${header?.field}`] ? (
            <div>
              {" "}
              {String(data[`${header.field}`])}
              <span>
                <div
                  className="s_text italic1"
                  style={{ paddingLeft: "2px", display: "inline-block" }}
                >
                  (Head Of Family)
                </div>
              </span>
            </div>
          ) : (
            "--"
          ),
          color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
        };
      } else {
        return {
          ...header,
          field: data[`${header?.field}`]
            ? String(data[`${header.field}`])
            : "--",
          color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
        };
      }
    }
    if (
      header?.field == "unRealisedGainLoss12" ||
      header?.field == "unRealisedGainLoss" ||
      header?.field == "Gain/Loss"
    ) {
      return {
        ...header,
        field:
          currencyFormat(
            data[`${header.field}`] || 0,
            undefined,
            denomination,
            fontclass
          ) || "--",
        posOrNeg: checkNumberPositiveOrNeg(data[`${header.field}`]),
      };
    }
    if (currencyFormatCheckArrayPrint.includes(header?.field)) {
      if (header?.field === "price") {
        return {
          ...header,
          field: data[`${header.field}`]
            ? currencyFormat(
                data[`${header.field}`] || 0,
                { showDecimal: true },
                "Actuals",
                fontclass
              ) || "--"
            : "--",
        };
      }
      if (header?.field === "otherIncome") {
        return {
          ...header,
          field: data[`${header.field}`]
            ? currencyFormat(
                data[`${header.field}`] || 0,
                undefined,
                denomination,
                fontclass
              ) || "--"
            : "--",
        };
      }

      if (denomination) {
        return {
          ...header,
          field:
            currencyFormat(
              data[`${header.field}`] || 0,
              undefined,
              denomination,
              fontclass
            ) || "--",
        };
      }

      return {
        ...header,
        field:
          currencyFormat(
            data[`${header.field}`] || 0,
            undefined,
            getDenomination,
            fontclass
          ) || "--",
      };
    }

    return {
      ...header,
      field: !isNaN(data[`${header.field}`])
        ? roundNumber(Number(data[`${header.field}`]))
        : data[`${header.field}`] || "--",
    };
  });
  return newData;
};
export const objectFormatingWithTaxTitleCOl = (
  data,
  headerData,
  key,
  denomination,
  total,
  title
) => {
  let fontclass = " ";
  if (total) {
    fontclass = "font900";
  }
  let newData = headerData.map((header) => {
    if (
      ["purchaseDate", "saleDate"].includes(header?.field) &&
      data.instrumentName != "Total" &&
      data.instrumentName != "Grand Total"
    ) {
      return {
        ...header,
        field: data[`${header.field}`] ? (
          <div style={{ whiteSpace: "nowrap" }}>
            {moment(data[`${header.field}`]).format("DD-MMM-YYYY")}
          </div>
        ) : (
          "--"
        ),
      };
    }
    if (
      header?.field == "quantity" &&
      data.instrumentName != "Total" &&
      data.instrumentName != "Grand Total"
    ) {
      return {
        ...header,
        field: data[`${header.field}`]
          ? roundNumber(data[`${header.field}`])
          : "--",
      };
    }

    if (TaxDoNothingArr.includes(header?.field)) {
      if (
        header.title == "Instrument Name ISIN" &&
        data[`${header?.field}`] != "Total" &&
        data[`${header?.field}`] != "Grand Total"
      ) {
        return {
          ...header,
          field: data[`${header?.field}`] ? (
            <div>
              {" "}
              {String(data[`${header.field}`])}
              <div
                className="s_text italic1"
                style={{ paddingLeft: "2px", display: "block" }}
              >
                {data.isin}
              </div>
            </div>
          ) : (
            "--"
          ),
          color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
        };
      } else {
        return {
          ...header,
          addOn: data?.addOn,
          field: data[`${header?.field}`]
            ? String(data[`${header.field}`])
            : "--",
          color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
        };
      }
    }

    if (currencyFormatTotalTaxPrint.includes(header?.field)) {
      if (denomination) {
        return {
          ...header,
          field:
            currencyFormat(
              data[`${header.field}`] || 0,
              undefined,
              denomination,
              fontclass
            ) || "--",
        };
      }

      return {
        ...header,
        field:
          currencyFormat(
            data[`${header.field}`] || 0,
            undefined,
            getDenomination,
            fontclass
          ) || "--",
      };
    }

    if (currencyFormatCheckTaxPrint.includes(header?.field)) {
      if (
        data.instrumentName == "Total" ||
        data.instrumentName == "Grand Total"
      ) {
        return {
          ...header,
          field: "--",
        };
      }
      if (
        header?.field === "price" ||
        header?.field == "purchaseRate" ||
        header?.field == "saleRate"
      ) {
        return {
          ...header,
          field: data[`${header.field}`]
            ? currencyFormat(
                data[`${header.field}`] || 0,
                { showDecimal: true },
                "Actuals",
                fontclass
              ) || "--"
            : "--",
        };
      }
      if (header?.field === "otherIncome") {
        return {
          ...header,

          field: data[`${header.field}`]
            ? currencyFormat(
                data[`${header.field}`] || 0,
                undefined,
                denomination,
                fontclass
              ) || "--"
            : "--",
        };
      }

      if (denomination) {
        return {
          ...header,
          addOn: data?.addOn,
          field:
            currencyFormat(
              data[`${header.field}`] || 0,
              { showDecimal: true },
              denomination,
              fontclass
            ) || "--",
        };
      }

      return {
        ...header,
        addOn: data?.addOn,
        field:
          currencyFormat(
            data[`${header.field}`] || 0,
            { showDecimal: true },
            getDenomination,
            fontclass
          ) || "--",
      };
    }

    return {
      ...header,
      field: !isNaN(data[`${header.field}`])
        ? roundNumber(Number(data[`${header.field}`]))
        : data[`${header.field}`] || "--",
    };
  });
  return newData;
};
export const objectFormatingWithGainlossTitleCOl = (
  data,
  headerData,
  key,
  denomination,
  total,
  title
) => {
  let fontclass = " ";
  if (total) {
    fontclass = "font900";
  }
  let newData = headerData.map((header) => {
    if (
      data["instrumentName"] == "Grand Total" ||
      data["instrumentName"] == "Total"
    ) {
      if (
        [
          "saleDate",
          // "realisedGainLoss",
          "purchaseAmount",
          "purchaseRate",
          "purchaseDate",
          "saleAmount",
          "saleRate",
          "quantity",
        ].includes(header.field)
      ) {
        return {
          type_null: true,
        };
      }
    }

    if (
      ["purchaseDate", "saleDate"].includes(header?.field) &&
      data.instrumentName != "Total" &&
      data.instrumentName != "Grand Total"
    ) {
      return {
        ...header,
        field: data[`${header.field}`] ? (
          <div style={{ whiteSpace: "nowrap" }}>
            {moment(data[`${header.field}`]).format("DD-MMM-YYYY")}
          </div>
        ) : (
          "--"
        ),
      };
    }
    if (
      header?.field == "quantity" &&
      data.instrumentName != "Total" &&
      data.instrumentName != "Grand Total"
    ) {
      return {
        ...header,
        field: data[`${header.field}`]
          ? roundNumber(data[`${header.field}`])
          : "--",
      };
    }

    if (TaxDoNothingArr.includes(header?.field)) {
      if (
        header.title == "Instrument Name ISIN" &&
        data[`${header?.field}`] != "Total" &&
        data[`${header?.field}`] != "Grand Total"
      ) {
        return {
          ...header,
          field: data[`${header?.field}`] ? (
            <div>
              {" "}
              {String(data[`${header.field}`])}
              <div
                className="s_text italic1"
                style={{ paddingLeft: "2px", display: "block" }}
              >
                {data.isin}
              </div>
            </div>
          ) : (
            "--"
          ),
          color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
        };
      } else if (header.title == "Instrument Name ISIN") {
        return {
          ...header,
          addOn: data?.addOn,
          colspan: data?.colspan,
          field: data[`${header?.field}`]
            ? String(data[`${header.field}`])
            : "--",
          color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
        };
      } else {
        return {
          ...header,
          addOn: data?.addOn,

          field: data[`${header?.field}`]
            ? String(data[`${header.field}`])
            : "--",
          color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
        };
      }
    }

    if (currencyFormatTotalTaxPrint.includes(header?.field)) {
      if (denomination) {
        return {
          ...header,
          field:
            currencyFormat(
              data[`${header.field}`] || 0,
              undefined,
              denomination,
              fontclass
            ) || "--",
        };
      }

      return {
        ...header,
        field:
          currencyFormat(
            data[`${header.field}`] || 0,
            undefined,
            getDenomination,
            fontclass
          ) || "--",
      };
    }

    if (currencyFormatCheckTaxPrint.includes(header?.field)) {
      if (
        data.instrumentName == "Total" ||
        data.instrumentName == "Grand Total"
      ) {
        return {
          ...header,
          field: "--",
        };
      }
      if (
        header?.field === "price" ||
        header?.field == "purchaseRate" ||
        header?.field == "saleRate"
      ) {
        return {
          ...header,
          field: data[`${header.field}`]
            ? currencyFormat(
                data[`${header.field}`] || 0,
                { showDecimal: true },
                "Actuals",
                fontclass
              ) || "--"
            : "--",
        };
      }
      if (header?.field === "otherIncome") {
        return {
          ...header,

          field: data[`${header.field}`]
            ? currencyFormat(
                data[`${header.field}`] || 0,
                undefined,
                denomination,
                fontclass
              ) || "--"
            : "--",
        };
      }

      if (denomination) {
        return {
          ...header,
          addOn: data?.addOn,
          field:
            currencyFormat(
              data[`${header.field}`] || 0,
              { showDecimal: true },
              denomination,
              fontclass
            ) || "--",
        };
      }

      return {
        ...header,
        addOn: data?.addOn,
        field:
          currencyFormat(
            data[`${header.field}`] || 0,
            { showDecimal: true },
            getDenomination,
            fontclass
          ) || "--",
      };
    }

    return {
      ...header,
      field: !isNaN(data[`${header.field}`])
        ? roundNumber(Number(data[`${header.field}`]))
        : data[`${header.field}`] || "--",
    };
  });
  return newData;
};
export const objectFormatingWithIncomeTitleCOl = (
  data,
  headerData,
  key,
  denomination,
  total,
  title
) => {
  let fontclass = " ";
  if (total) {
    fontclass = "font900";
  }
  let newData = headerData.map((header) => {
    if (data["schemeName"] == "Grand Total" || data["schemeName"] == "Total") {
      if (
        [
          "amount",

          "productName",

          "quantity",

          "transactionDate",

          "transactionType",
          // "currentFaceValue",
        ].includes(header.field)
      ) {
        return {
          type_null: true,
        };
      }
      if (header.field == "currentFaceValue") {
        return {
          ...header,
          field: "",
          color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
        };
      }
    }
    if (data["schemeName"] == "Total") {
      console.log("sdsd total");
    }

    if (
      ["transactionDate"].includes(header?.field) &&
      data.schemeName != "Total" &&
      data.schemeName != "Grand Total"
    ) {
      return {
        ...header,
        field: data[`${header.field}`] ? (
          <div style={{ whiteSpace: "nowrap" }}>
            {moment(data[`${header.field}`]).format("DD-MMM-YYYY")}
          </div>
        ) : (
          "--"
        ),
      };
    }
    if (
      header?.field == "quantity" &&
      data.schemeName != "Total" &&
      data.schemeName != "Grand Total"
    ) {
      return {
        ...header,
        field: data[`${header.field}`]
          ? roundNumber(data[`${header.field}`])
          : "--",
      };
    }

    if (TaxDoNothingArr.includes(header?.field)) {
      if (
        header.title == "Scheme Name ISIN" &&
        data[`${header?.field}`] != "Total" &&
        data[`${header?.field}`] != "Grand Total"
      ) {
        return {
          ...header,
          field: data[`${header?.field}`] ? (
            <div>
              {" "}
              {String(data[`${header.field}`])}
              <div
                className="s_text italic1"
                style={{ paddingLeft: "2px", display: "block" }}
              >
                {data.isin}
              </div>
            </div>
          ) : (
            "--"
          ),
          color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
        };
      } else if (header.title == "Scheme Name ISIN") {
        // console.log(data?.colspan, "col span");
        return {
          ...header,
          addOn: data?.addOn,
          colspan: data?.colspan,
          field: data[`${header?.field}`]
            ? String(data[`${header.field}`])
            : "--",
          color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
        };
      } else {
        return {
          ...header,
          addOn: data?.addOn,

          field: data[`${header?.field}`]
            ? String(data[`${header.field}`])
            : "--",
          color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
        };
      }
    }

    if (["totalAmount"].includes(header?.field)) {
      if (denomination) {
        return {
          ...header,
          field:
            currencyFormat(
              data[`${header.field}`] || 0,
              undefined,
              denomination,
              fontclass
            ) || "--",
        };
      }

      return {
        ...header,
        field:
          currencyFormat(
            data[`${header.field}`] || 0,
            undefined,
            getDenomination,
            fontclass
          ) || "--",
      };
    }

    if (currencyFormatCheckTaxPrint.includes(header?.field)) {
      if (data.schemeName == "Total" || data.schemeName == "Grand Total") {
        return {
          ...header,
          field: "--",
        };
      }
      if (
        header?.field === "price" ||
        header?.field == "purchaseRate" ||
        header?.field == "saleRate" ||
        header?.field == "amount"
      ) {
        return {
          ...header,
          field: data[`${header.field}`]
            ? currencyFormat(
                data[`${header.field}`] || 0,
                { showDecimal: true },
                "Actuals",
                fontclass
              ) || "--"
            : "--",
        };
      }
      if (header?.field === "currentFaceValue") {
        return {
          ...header,
          field:
            data[`${header.field}`] &&
            typeof data[`${header.field}`] == "number"
              ? currencyFormat(
                  data[`${header.field}`] || 0,
                  { showDecimal: true },
                  "Actuals",
                  fontclass
                ) || data[`${header.field}`]
              : "--",
        };
      }
      if (header?.field === "otherIncome") {
        return {
          ...header,

          field: data[`${header.field}`]
            ? currencyFormat(
                data[`${header.field}`] || 0,
                undefined,
                denomination,
                fontclass
              ) || "--"
            : "--",
        };
      }

      if (denomination) {
        return {
          ...header,
          addOn: data?.addOn,
          field:
            currencyFormat(
              data[`${header.field}`] || 0,
              { showDecimal: true },
              denomination,
              fontclass
            ) || "--",
        };
      }

      return {
        ...header,
        addOn: data?.addOn,
        field:
          currencyFormat(
            data[`${header.field}`] || 0,
            { showDecimal: true },
            getDenomination,
            fontclass
          ) || "--",
      };
    }

    return {
      ...header,
      field: !isNaN(data[`${header.field}`])
        ? roundNumber(Number(data[`${header.field}`]))
        : data[`${header.field}`] || "--",
    };
  });
  return newData;
};
export const objectFormatingWithPmsTitleCOl = (
  data,
  headerData,
  key,
  denomination,
  total,
  title
) => {
  let fontclass = " ";
  if (total) {
    fontclass = "font900";
  }
  let newData = headerData.map((header) => {
    if (
      ["transaction_date"].includes(header?.field) &&
      data.instrumentName != "Total" &&
      data.instrumentName != "Grand Total"
    ) {
      return {
        ...header,
        field: data[`${header.field}`] ? (
          <div style={{ whiteSpace: "nowrap" }}>
            {moment(data[`${header.field}`]).format("DD-MMM-YYYY")}
          </div>
        ) : (
          "--"
        ),
      };
    }
    if (
      header?.field == "units" &&
      data.instrumentName != "Total" &&
      data.instrumentName != "Grand Total"
    ) {
      return {
        ...header,
        field: data[`${header.field}`]
          ? roundNumber(data[`${header.field}`])
          : "--",
      };
    }
    //do nothing check
    if (["transaction_type", "folio_no"].includes(header?.field)) {
      if (
        header.title == "Instrument Name ISIN" &&
        data[`${header?.field}`] != "Total" &&
        data[`${header?.field}`] != "Grand Total"
      ) {
        return {
          ...header,
          field: data[`${header?.field}`] ? (
            <div>
              {" "}
              {String(data[`${header.field}`])}
              <div
                className="s_text italic1"
                style={{ paddingLeft: "2px", display: "block" }}
              >
                {data.isin}
              </div>
            </div>
          ) : (
            "--"
          ),
          color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
        };
      } else {
        return {
          ...header,
          field: data[`${header?.field}`]
            ? String(data[`${header.field}`])
            : "--",
          color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
        };
      }
    }
    //currency format
    if (["amount", "price"].includes(header?.field)) {
      if (denomination) {
        return {
          ...header,
          field:
            currencyFormat(
              data[`${header.field}`] || 0,
              undefined,
              denomination,
              fontclass
            ) || "--",
        };
      }

      return {
        ...header,
        field:
          currencyFormat(
            data[`${header.field}`] || 0,
            undefined,
            getDenomination,
            fontclass
          ) || "--",
      };
    }

    if (currencyFormatCheckTaxPrint.includes(header?.field)) {
      if (
        data.instrumentName == "Total" ||
        data.instrumentName == "Grand Total"
      ) {
        return {
          ...header,
          field: "--",
        };
      }
      if (header?.field === "price") {
        return {
          ...header,
          field: data[`${header.field}`]
            ? currencyFormat(
                data[`${header.field}`] || 0,
                { showDecimal: true },
                "Actuals",
                fontclass
              ) || "--"
            : "--",
        };
      }
      if (header?.field === "otherIncome") {
        return {
          ...header,
          field: data[`${header.field}`]
            ? currencyFormat(
                data[`${header.field}`] || 0,
                undefined,
                denomination,
                fontclass
              ) || "--"
            : "--",
        };
      }

      if (denomination) {
        return {
          ...header,
          field:
            currencyFormat(
              data[`${header.field}`] || 0,
              { showDecimal: true },
              denomination,
              fontclass
            ) || "--",
        };
      }

      return {
        ...header,
        field:
          currencyFormat(
            data[`${header.field}`] || 0,
            { showDecimal: true },
            getDenomination,
            fontclass
          ) || "--",
      };
    }

    return {
      ...header,
      field: !isNaN(data[`${header.field}`])
        ? roundNumber(Number(data[`${header.field}`]))
        : data[`${header.field}`] || "--",
    };
  });
  return newData;
};
export const objectFormatingWithExtended = (
  data,
  headerData,
  key,
  denomination,
  total,
  title
) => {
  let fontclass = " ";
  if (total) {
    fontclass = "font900";
  }
  let newData = headerData.map((header) => {
    if (
      ["transaction_date"].includes(header?.field) &&
      data.instrumentName != "Total" &&
      data.instrumentName != "Grand Total"
    ) {
      return {
        ...header,
        field: data[`${header.field}`] ? (
          <div style={{ whiteSpace: "nowrap" }}>
            {moment(data[`${header.field}`]).format("DD-MMM-YYYY")}
          </div>
        ) : (
          "--"
        ),
      };
    }
    if (
      header?.field == "units" &&
      data.instrumentName != "Total" &&
      data.instrumentName != "Grand Total"
    ) {
      return {
        ...header,
        field: data[`${header.field}`]
          ? roundNumber(data[`${header.field}`])
          : "--",
      };
    }
    //do nothing check
    if (["transaction_type", "folio_no"].includes(header?.field)) {
      if (
        header.title == "Instrument Name ISIN" &&
        data[`${header?.field}`] != "Total" &&
        data[`${header?.field}`] != "Grand Total"
      ) {
        return {
          ...header,
          field: data[`${header?.field}`] ? (
            <div>
              {" "}
              {String(data[`${header.field}`])}
              <div
                className="s_text italic1"
                style={{ paddingLeft: "2px", display: "block" }}
              >
                {data.isin}
              </div>
            </div>
          ) : (
            "--"
          ),
          color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
        };
      } else {
        return {
          ...header,
          field: data[`${header?.field}`]
            ? String(data[`${header.field}`])
            : "--",
          color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
        };
      }
    }
    //currency format
    if (["amount", "price"].includes(header?.field)) {
      if (denomination) {
        return {
          ...header,
          field:
            currencyFormat(
              data[`${header.field}`] || 0,
              undefined,
              denomination,
              fontclass
            ) || "--",
        };
      }

      return {
        ...header,
        field:
          currencyFormat(
            data[`${header.field}`] || 0,
            undefined,
            getDenomination,
            fontclass
          ) || "--",
      };
    }

    if (currencyFormatCheckTaxPrint.includes(header?.field)) {
      if (
        data.instrumentName == "Total" ||
        data.instrumentName == "Grand Total"
      ) {
        return {
          ...header,
          field: "--",
        };
      }
      if (header?.field === "price") {
        return {
          ...header,
          field: data[`${header.field}`]
            ? currencyFormat(
                data[`${header.field}`] || 0,
                { showDecimal: true },
                "Actuals",
                fontclass
              ) || "--"
            : "--",
        };
      }
      if (header?.field === "otherIncome") {
        return {
          ...header,
          field: data[`${header.field}`]
            ? currencyFormat(
                data[`${header.field}`] || 0,
                undefined,
                denomination,
                fontclass
              ) || "--"
            : "--",
        };
      }

      if (denomination) {
        return {
          ...header,
          field:
            currencyFormat(
              data[`${header.field}`] || 0,
              { showDecimal: true },
              denomination,
              fontclass
            ) || "--",
        };
      }

      return {
        ...header,
        field:
          currencyFormat(
            data[`${header.field}`] || 0,
            { showDecimal: true },
            getDenomination,
            fontclass
          ) || "--",
      };
    }

    return {
      ...header,
      field: !isNaN(data[`${header.field}`])
        ? roundNumber(Number(data[`${header.field}`]))
        : data[`${header.field}`] || "--",
    };
  });
  return newData;
};
export const objectFormatingProductAllocationWithTitleCOl = (
  data,
  headerData,
  key,
  denomination,
  total,
  title
) => {
  let fontclass = " ";
  if (total) {
    fontclass = "font900";
  }
  let newData = headerData.map((header) => {
    if (header?.field == "assetClass") {
      return {
        ...header,
        field: data[`${header.field}`]
          ? String(data[`${header.field}`])
          : data[`product`]
          ? String(data[`product`])
          : "--",
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }

    if (doNothingProductAllocation.includes(header?.field)) {
      return {
        ...header,
        field: data[`${header?.field}`]
          ? String(data[`${header.field}`])
          : "--",
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }

    if (currencyFormatCheckArrayPrint.includes(header?.field)) {
      if (denomination) {
        return {
          ...header,
          field:
            currencyFormat(
              data[`${header.field}`] || 0,
              undefined,
              denomination,
              fontclass
            ) || "--",
        };
      }

      return {
        ...header,
        field:
          currencyFormat(
            data[`${header.field}`] || 0,
            undefined,
            getDenomination,
            fontclass
          ) || "--",
      };
    }

    return {
      ...header,
      field: !isNaN(data[`${header.field}`])
        ? roundNumber(Number(data[`${header.field}`]))
        : data[`${header.field}`] || "--",
    };
  });
  return newData;
};
export const objectFormatingProductCommonChartWithTitleCOl = (
  data,
  headerData,
  key,
  denomination,
  total,
  title
) => {
  console.log("new table ");
  let fontclass = " ";
  if (total) {
    fontclass = "font900";
  }
  let newData = headerData.map((header) => {
    // if (header?.field == "assetClass") {
    //   return {
    //     ...header,
    //     field: data[`${header.field}`]
    //       ? String(data[`${header.field}`])
    //       : data[`product`]
    //       ? String(data[`product`])
    //       : "--",
    //     color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
    //   };
    // }

    // if (dateFormatingArrayPrint.includes(header?.field)) {
    //   return {
    //     ...header,
    //     field: data[`${header.field}`]
    //       ? moment(data[`${header.field}`]).format("DD-MMM-YYYY")
    //       : "--",
    //   };
    // }

    if (["weight"].includes(header?.field)) {
      return {
        ...header,
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`])) + "%"
          : data[`${header.field}`] || "--",
      };
    }
    // if (percentage.includes(header?.field)) {
    //   return {
    //     ...header,
    //     field: !isNaN(data[`${header.field}`])
    //       ? roundNumber(Number(data[`${header.field}`])) + "%"
    //       : data[`${header.field}`] || "--",
    //     posOrNeg: checkNumberPositiveOrNeg(data[`${header.field}`]),
    //   };
    // }

    if (doNothingCommonChart.includes(header?.field)) {
      return {
        ...header,
        field: data[`${header?.field}`]
          ? String(data[`${header.field}`])
          : "--",
        color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
      };
    }

    if (currencyFormatCommonChart.includes(header?.field)) {
      if (denomination) {
        return {
          ...header,
          field:
            currencyFormat(
              data[`${header.field}`] || 0,
              undefined,
              denomination,
              fontclass
            ) || "--",
        };
      }

      return {
        ...header,
        field:
          currencyFormat(
            data[`${header.field}`] || 0,
            undefined,
            getDenomination,
            fontclass
          ) || "--",
      };
    }

    return {
      ...header,
      field: !isNaN(data[`${header.field}`])
        ? roundNumber(Number(data[`${header.field}`]))
        : data[`${header.field}`] || "--",
    };
  });
  return newData;
};
// export const objectFormatingWithnewProductAllocationTitleCOl = (
//   data,
//   headerData,
//   key,
//   denomination,
//   total,
//   title
// ) => {
//   let fontclass = " ";
//   if (total) {
//     fontclass = "font900";
//   }

//   let newData = headerData.map((header) => {
//     // console.log("56437534gh", data, header?.title);

//     if (header?.title == "Units") {
//       return {
//         ...header,
//         field: handleUnitsData(data),
//         color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
//       };
//     }

//     if (header?.title == "Quantity") {
//       const formattedQuantity = formatNumberWithCommas(data[header.field]);
//       return {
//         ...header,
//         // field: handleUnitsData(data),
//         field: formattedQuantity,
//         color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
//       };
//     }
//     if (
//       header?.field === "investedAmount" &&
//       header.subdata &&
//       data.schemeName != "Grand Total" &&
//       data.schemeName != "Total"
//     ) {
//       return {
//         ...header,
//         withtwoline: true,
//         field: (
//           <div>
//             {currencyFormat(
//               data[`${header.field}`] || 0,
//               undefined,
//               denomination,
//               fontclass
//             ) || "--"}
//             <div style={{ transform: " skew(-5deg)", fontSize: "12px" }}>
//               {data[`${header.subdata}`]
//                 ? dateFormatCommon(data[`${header.subdata}`]) || "--"
//                 : "--"}
//             </div>
//           </div>
//         ),
//       };
//     }

//     if (header?.field == "assetClass") {
//       return {
//         ...header,
//         field: data[`${header.field}`]
//           ? String(data[`${header.field}`])
//           : data[`product`]
//           ? String(data[`product`])
//           : "--",
//         color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
//       };
//     }
//     if (header.field == "asOnDate") {
//       return {
//         ...header,
//         field: data[`${header.field}`]
//           ? moment(data[`${header.field}`], "DD MMM YYYY").format("DD-MM-YYYY")
//           : "--",
//       };
//     }
//     if (dateFormatingArrayPrint.includes(header?.field)) {
//       return {
//         ...header,
//         field: data[`${header.field}`]
//           ? moment(data[`${header.field}`]).format("DD-MMM-YYYY")
//           : "--",
//       };
//     }

//     if (onlyPercentage.includes(header?.field)) {
//       return {
//         ...header,
//         field: !isNaN(data[`${header.field}`])
//           ? roundNumber(Number(data[`${header.field}`])) + "%"
//           : data[`${header.field}`] || "--",
//       };
//     }
//     if (percentage.includes(header?.field)) {
//       if (
//         header?.field == "TWRR_annualised" ||
//         header?.field == "annualisedTwrr" ||
//         header?.field == "XIRR"
//       ) {
//         return {
//           ...header,
//           field: !isNaN(data[`${header.field}`])
//             ? roundNumber(Number(data[`${header.field}`])) + "%"
//             : data[`${header.field}`] || "--",
//           posOrNeg: checkNumberPositiveOrNeg(data[`${header.field}`]),
//         };
//       }
//       return {
//         ...header,
//         field: !isNaN(data[`${header.field}`])
//           ? roundNumber(Number(data[`${header.field}`])) + "%"
//           : data[`${header.field}`] || "--",
//         posOrNeg: checkNumberPositiveOrNeg(data[`${header.field}`]),
//       };
//     }
//     if (
//       (header.title == "Policy" || header.field == "bankName") &&
//       data.schemeName != "Grand Total" &&
//       data.schemeName != "Total" &&
//       title == "ClientholdingSummary"
//     ) {
//       return {
//         ...header,
//         field: data[`${header?.field}`] ? (
//           <>
//             {" "}
//             <div>
//               {String(data[`${header.field}`])}
//               <div style={{ transform: " skew(-5deg)", fontSize: "12px" }}>
//                 {"   weightage: "}
//                 {roundNumber(data?.weightage)}% /{" "}
//                 {moment(data.initialPurchaseDate, "YYYY-MM-DD").format(
//                   "Do MMM YYYY"
//                 )}
//                 <span style={{ color: "#dcaf3d" }}>
//                   {data?.["hasMultipleBuyTransactions"] ? "*" : ""}
//                 </span>
//               </div>
//             </div>
//           </>
//         ) : (
//           "--"
//         ),
//         color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
//       };
//     }
//     if (
//       ((header.field == "schemeName" &&
//         data.schemeName != "Grand Total" &&
//         data.schemeName != "Total") ||
//         (header.field == "member" &&
//           data.member != "Grand Total" &&
//           data.member != "Total")) &&
//       title == "ClientholdingSummary"
//     ) {
//       return {
//         ...header,
//         field: data[`${header?.field}`] ? (
//           <>
//             {" "}
//             <div>
//               <span style={{ color: "#dcaf3d", paddingRight: "2px" }}>
//                 {data?.["isHeld"] == 0 ? " # " : ""}
//               </span>{" "}
//               {String(data[`${header.field}`])}
//               <div style={{ transform: " skew(-5deg)", fontSize: "12px" }}>
//                 {"   weightage: "}
//                 {roundNumber(data?.weightage)}% /{" "}
//                 {moment(data.initialPurchaseDate, "YYYY-MM-DD").format(
//                   "Do MMM YYYY"
//                 )}
//                 <span style={{ color: "#dcaf3d" }}>
//                   {data?.["hasMultipleBuyTransactions"] ? "*" : ""}
//                 </span>
//               </div>
//             </div>
//           </>
//         ) : (
//           "--"
//         ),
//         color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
//       };
//     }

//     if (doNothingArrayPrint.includes(header?.field)) {
//       return {
//         ...header,
//         field: data[`${header?.field}`]
//           ? String(data[`${header.field}`])
//           : "--",
//         color: key ? getColor(firstCharCapitalize(key)) : "#ADC371",
//       };
//     }
//     if (
//       header?.field == "unRealisedGainLoss12" ||
//       header?.field == "unRealisedGainLoss" ||
//       header?.field == "Gain/Loss"
//     ) {
//       // console.log(data[`${header.field}`], "aglnalk");
//       return {
//         ...header,
//         field:
//           currencyFormat(
//             data[`${header.field}`] || 0,
//             undefined,
//             denomination,
//             fontclass
//           ) || "--",
//         posOrNeg: checkNumberPositiveOrNeg(data[`${header.field}`]),
//       };
//     }
//     if (currencyFormatCheckArrayPrint.includes(header?.field)) {
//       if (header?.field === "price") {
//         return {
//           ...header,
//           field: data[`${header.field}`]
//             ? currencyFormat(
//                 data[`${header.field}`] || 0,
//                 { showDecimal: true },
//                 "Actuals",
//                 fontclass
//               ) || "--"
//             : "--",
//         };
//       }
//       if (header?.field === "otherIncome") {
//         return {
//           ...header,
//           field: data[`${header.field}`]
//             ? currencyFormat(
//                 data[`${header.field}`] || 0,
//                 undefined,
//                 denomination,
//                 fontclass
//               ) || "--"
//             : "--",
//         };
//       }

//       if (
//         (header?.field == "balance" &&
//           data.bankName != "Grand Total" &&
//           data.bankName != "Total") ||
//         (header.field == "currentValue" &&
//           data.schemeName != "Grand Total" &&
//           data.schemeName != "Total")
//       ) {
//         return {
//           ...header,
//           withtwoline: true,
//           field: (
//             <div>
//               {currencyFormat(
//                 data[`${header.field}`] || 0,
//                 undefined,
//                 denomination,
//                 fontclass
//               ) || "--"}
//               <div style={{ transform: " skew(-5deg)", fontSize: "12px" }}>
//                 {data?.asOnDate ? data?.asOnDate : "--"}
//               </div>
//             </div>
//           ),
//         };
//       }
//       if (denomination) {
//         return {
//           ...header,
//           field:
//             currencyFormat(
//               data[`${header.field}`] || 0,
//               undefined,
//               denomination,
//               fontclass
//             ) || "--",
//         };
//       }

//       return {
//         ...header,
//         field:
//           currencyFormat(
//             data[`${header.field}`] || 0,
//             undefined,
//             getDenomination,
//             fontclass
//           ) || "--",
//       };
//     }

//     return {
//       ...header,
//       field: !isNaN(data[`${header.field}`])
//         ? roundNumber(Number(data[`${header.field}`]))
//         : data[`${header.field}`] || "--",
//     };
//   });
//   return newData;
// };
const handleGroupRowTitle = (titles) => {
  titles = titles.map((val) => firstCharCapitalize(val));
  const lastElement = titles.pop();
  const title = titles.join(",");

  return title + " and " + lastElement;
};

export const objectFormatingWithTitleCOlWrapper = (
  data,
  headerData,
  gorupRows,
  groupRowColor,
  stBodyEntityData,
  totalData,
  customRef,
  denomination,
  firstKey,
  type,
  colmapping
) => {
  // if (headerData[0].field == "memberName") {
  //   console.log("membertype");
  // }
  let newArray = [];

  let groupArry =
    gorupRows.length > 0
      ? [
          [
            {
              title: handleGroupRowTitle(gorupRows),
              field: handleGroupRowTitle(gorupRows),
              type: "title",
              color: groupRowColor,
              align: "left",
              customRef: customRef,
            },
          ],
        ]
      : [];
  let totalRow = [];

  // console.log(data, "asfjkbaskjf");

  if (totalData) {
    if (colmapping) {
      totalRow = [
        ...colmapping(totalData, headerData, "Total", denomination).map(
          (val) => ({ ...val, type: "total" })
        ),
      ];
    } else {
      totalRow = [
        ...objectFormatingWithTitleCOl(
          totalData,
          headerData,
          "Total",
          denomination
        ).map((val) => ({ ...val, type: "total" })),
      ];
    }

    // console.log(totalRow, "alfknalsnf");
  }

  // console.log(data, "dta");
  Object.keys(data).map((key) => {
    if (gorupRows?.includes(key)) {
      data[key].map((val) => {
        if (colmapping) {
          groupArry.push(
            colmapping(val, headerData, key, denomination).map((val) => ({
              ...val,
              customRef: customRef,
            }))
          );
        } else {
          groupArry.push(
            objectFormatingWithTitleCOl(val, headerData, key, denomination).map(
              (val) => ({
                ...val,
                customRef: customRef,
              })
            )
          );
        }
      });
    }
    // if (stBodyEntityData) {
    if (stBodyEntityData && stBodyEntityData[key]) {
      // console.log(
      //   objectFormatingWithTitleCOl(
      //     stBodyEntityData[key],
      //     headerData,
      //     key
      //   ).map((val) => ({ ...val, type: "title" })),
      //   "afjkbaskjfioio"
      // );
      if (colmapping) {
        newArray.push(
          colmapping(
            stBodyEntityData[key],
            headerData,
            key,
            denomination,
            null,
            type
          ).map((val) => ({ ...val, type: "title", customRef: customRef }))
        );
      } else {
        newArray.push(
          objectFormatingWithTitleCOl(
            stBodyEntityData[key],
            headerData,
            key,
            denomination,
            null,
            type
          ).map((val) => ({ ...val, type: "title", customRef: customRef }))
        );
      }
    }
    //  else {
    //   newArray.push([
    //     {
    //       title: key,
    //       field: key,
    //       type: "title",
    //       color: getColor(firstCharCapitalize(key)),
    //       align: "left",
    //       customRef: customRef,
    //     },
    //   ]);
    // }
    // }
    if (!isArray(data[key])) {
      console.log(key, data[key], "error in print");
    }
    isArray(data[key]) &&
      data[key]?.map((val) => {
        if (colmapping) {
          newArray.push(
            colmapping(
              val,
              headerData,
              key,
              denomination,
              val[firstKey] == "total" ||
                val[firstKey] == "Total" ||
                val[firstKey] == "grandTotal"
                ? "grandTotal"
                : false,
              type
            )?.map((val) => ({
              ...val,
              customRef: customRef,
            }))
          );
        } else {
          newArray.push(
            objectFormatingWithTitleCOl(
              val,
              headerData,
              key,
              denomination,
              val[firstKey] == "total" ||
                val[firstKey] == "Total" ||
                val[firstKey] == "grandTotal"
                ? "grandTotal"
                : false,
              type
            )?.map((val) => ({
              ...val,
              customRef: customRef,
            }))
          );
        }
      });
  });

  let returnData = [...newArray, ...groupArry];
  if (totalData) {
    returnData = [...returnData, totalRow];
  }
  // console.log(returnData, "asfkhakjsbbf");

  return returnData;
};

export const objectFormatingProductWithTitleCOlWrapper = (
  data,
  headerData,
  gorupRows,
  groupRowColor,
  stBodyEntityData,
  totalData,
  customRef,
  denomination,
  firstKey,
  type,
  colformatting
) => {
  // if (headerData[0].field == "memberName") {
  //   console.log("membertype");
  // }
  let newArray = [];

  let groupArry =
    gorupRows.length > 0
      ? [
          [
            {
              title: handleGroupRowTitle(gorupRows),
              field: handleGroupRowTitle(gorupRows),
              type: "title",
              color: groupRowColor,
              align: "left",
              customRef: customRef,
            },
          ],
        ]
      : [];
  let totalRow = [];

  // console.log(data, "asfjkbaskjf");

  if (totalData) {
    totalRow = [
      ...colformatting(totalData, headerData, "Total", denomination).map(
        (val) => ({ ...val, type: "total" })
      ),
    ];
    // console.log(totalRow, "alfknalsnf");
  }

  // console.log(data, "dta");
  Object.keys(data).map((key) => {
    if (gorupRows?.includes(key)) {
      data[key].map((val) => {
        groupArry.push(
          colformatting(val, headerData, key, denomination).map((val) => ({
            ...val,
            customRef: customRef,
          }))
        );
      });
    }
    // if (stBodyEntityData) {
    if (stBodyEntityData && stBodyEntityData[key]) {
      // console.log(
      //   objectFormatingWithTitleCOl(
      //     stBodyEntityData[key],
      //     headerData,
      //     key
      //   ).map((val) => ({ ...val, type: "title" })),
      //   "afjkbaskjfioio"
      // );
      newArray.push(
        colformatting(
          stBodyEntityData[key],
          headerData,
          key,
          denomination,
          null,
          type
        ).map((val) => ({ ...val, type: "title", customRef: customRef }))
      );
    } else {
      newArray.push([
        {
          title: key,
          field: key,
          type: "title",
          color: getColor(firstCharCapitalize(key)),
          align: "left",
          customRef: customRef,
        },
      ]);
    }
    // }
    data[key]?.map((val) => {
      newArray.push(
        colformatting(
          val,
          headerData,
          key,
          denomination,
          val[firstKey] == "total" ||
            val[firstKey] == "Total" ||
            val[firstKey] == "grandTotal"
            ? "grandTotal"
            : false,
          type
        )?.map((val) => ({
          ...val,
          customRef: customRef,
        }))
      );
    });
  });

  let returnData = [...newArray, ...groupArry];
  if (totalData) {
    returnData = [...returnData, totalRow];
  }
  // console.log(returnData, "asfkhakjsbbf");

  return returnData;
};

export const getBorderColor = (data, relative, totaltype) => {
  if (data == positive) {
    return `${totaltype ? "totalPos" : "totalPos"} ${
      relative ? "posRel" : ""
    } `;
  } else if (data == negative) {
    return `${totaltype ? "totalNeg" : "totalNeg"} ${relative ? "posRel" : ""}`;
  }
  return "";
};

export const getBgColor = (data, relative) => {
  if (data == positive) {
    return `#000000`;
  } else if (data == negative) {
    return `#000000`;
  }
  return "";
};

export const dataFormatWrapper = ({ data, key }) => {
  let newData = {};
  data?.map((val1) => {
    newData = { ...newData, [val1[key]]: val1 };
  });
  // console.log(newData, "newData");
  return newData;
};

export const bodyDataFormatWithProd = ({ data, keys }) => {
  let newData = {};
  keys.map((product) => {
    data?.[`dashboard-product-summary-${product}`].map((a) => {
      data?.[`dashboard-product-summary-twrr-${product}`]?.data?.find((f) => {
        if (a["product"] == f["productFullName"]) {
          a["absoluteTwrr"] = f["absoluteTwrr"];
          a["annualisedTwrr"] = f["annualisedTwrr"];
        }
      });
    });
    let a = { [product]: data?.[`dashboard-product-summary-${product}`] };
    // console.log(a, "newData");
    newData = {
      ...newData,
      [product]: data?.[`dashboard-product-summary-${product}`],
    };
  });
  // console.log(newData, "newData");
  return newData;
};

export function Decrypt(word) {
  let decData = CryptoJS.enc.Base64.parse(word).toString(CryptoJS.enc.Utf8);
  let bytes = CryptoJS.AES.decrypt(decData, encrypt_key).toString(
    CryptoJS.enc.Utf8
  );
  // console.log("searchParem", JSON.parse(bytes));
  return JSON.parse(bytes);
}

export function Encrypt(word) {
  let encJson = CryptoJS.AES.encrypt(
    JSON.stringify(word),
    encrypt_key
  ).toString();
  let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson));
  // console.log(encData, "sfgjkbgj");
  return encData;
}
