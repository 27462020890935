import React from "react";

export const UncheckedSvgIcon = ({ cssClassName }) => {
  return (
    <svg
      className={cssClassName}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <circle cx="10" cy="10" r="9.5" stroke="#6A77AB" />
    </svg>
  );
};
