import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";

export default function CustomInputLogin(props) {
  const { title, label, errorBorder, pr, style, ...newPorops } = props;
  const useStyles = makeStyles({
    root: {
      "& label.Mui-focused": {
        color: "transparent",
      },
      "& .MuiInputBase-root": {
        borderRadius: "8px",
        border: newPorops?.error ? "2px solid red" : "2px solid #3E5389",
        color: "var(--selectedText)",
        transition: "border-color 0.2s ease-in-out",
        paddingRight: pr ? pr : "",
      },
      "& .MuiInputBase-root .MuiSelect-select ": {
        minHeight: "auto",
      },
      "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiInputBase-root .MuiInputBase-input": {
        padding: "12px 14px",
        fontSize: "14px",
      },
      "& .MuiFormHelperText-root": {
        fontSize: "12px",
      },
    },
  });
  const classes = useStyles();
  const isEdgeBrowser = window.navigator.userAgent.indexOf("Edg") > -1;
  return (
    <>
      <InputLabel
        title={title}
        className="LoginPageLabel"
        sx={{
          letterSpacing: "0.16em",
          textTransform: "uppercase",
          pl: "10px",
          fontWeight: "500",
        }}
      >
        {title}
      </InputLabel>
      <TextField
        {...newPorops}
        classes={{
          root: classes.root,
        }}
        // Disable Autocomplete
        inputProps={{
          ...newPorops.inputProps,
          autoComplete: isEdgeBrowser ? "none" : "off",
        }}
        placeholder={label}
        inputProps={{
          ...newPorops.inputProps,
          autoComplete: isEdgeBrowser ? "none" : "off",
        }}
        sx={{
          "& legend": { display: "none" },
          "& fieldset": { top: 0 },
          mt: "4px",
          mb: "18px",
          ...style,
          // pr: pr ? pr : "",
        }}
      />
    </>
  );
}
