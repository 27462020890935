import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PageFilter from "../common/PageFilter";
import useStyles from "../common/ProductsStyles";

import PieChart from "../../../charts/PieChart";
import GlowCircle from "../../../charts/GlowCircle";

import { colors_s8 } from "../../../charts/colors";

import {
  divergingCommonProps,
  pie_onClick2,
} from "../../../charts/nivo_objects";

import {
  getAmcData,
  getAssetWiseData,
  getRealisedData,
  getInvestmentTypeData,
  fetchTwrr,
  fetchTwrrCurrentHolding,
  fetchXirr,
  fetchXirrCurrentHolding,
  get_index_performance,
  mlyPortfolioTrend,
  getMfFundPerformance,
} from "../../../../api";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import { useContext } from "react";
import { OnboardingContext } from "../../../../Context";
import moment from "moment";
import { currencyFormat } from "../../../../hooks/constantFunction";
import {
  dateFormatCommon,
  roundNumber,
  roundNumberWithoutDecimal,
} from "../../../utils/common";
import PageLoader from "../common/pageLoader";
import { loadingText } from "../../../utils/constands";
import SummaryPageTop from "../common/SummaryPageTop";

import PortfolioChart from "../../common/portfolioChart";

import { useHoldingCardActive } from "../../../../hooks/useHoldingCardActive";
import { NewHoldingSummaryCardsActive } from "../../../common/HoldingSummaryCards/NewHoldingSummaryCardsActive";
import { NewHoldingSummaryCards } from "../../../common/HoldingSummaryCards/NewHoldingSummaryCards";
import { summaryDetailsSecConstData } from "../utils/constands/summaryDetailsSec";
import SummaryDetailsSectionWrapper from "../newDesigns/common/SummaryDetailsSectionWrapper";
import { colorAndProd } from "../utils/constands/prodAndColor";
import { useReturns } from "../../../../hooks/useReturns";
import { useNormalReturns } from "../../../../hooks/useNormalReturns";
import { routes } from "../../../../routes";
import { useTotalGainLoss } from "../../../../hooks/useTotalGainLoss";
import { NewFilter } from "../../newFilter/NewFilter";
import { GainsCardInfo } from "../../../common/HoldingSummaryCards/GainsCardInfo";
import { ReactComponent as InfoIcon } from "../../../../assets/icons/TotalGainLossInfoIcon.svg";
import { useCardsApi } from "../../../../hooks/useCardsApi";
import { useCurrentHoldingCardsApi } from "../../../../hooks/useCurrentHoldingCardsApi";
import { TotalGainLossCardInfo } from "../../../common/HoldingSummaryCards/TotalGainLossCardInfo";
function MfoSummaryComp() {
  const [checked, setChecked] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [infoIconHovered, setInfoIconHovered] = useState(false);
  // const [holdingCardData] = useHoldingCardActive("mf", checked);
  // const [totalGainLossData] = useTotalGainLoss("mf");
  // const { returns: twrr } = useNormalReturns(
  //   fetchTwrr,
  //   "normal-twrr",
  //   checked,
  //   "mf"
  // );
  // const { returns: xirr } = useNormalReturns(
  //   fetchXirr,
  //   "normal-xirr",
  //   checked,
  //   "mf"
  // );
  const {
    holdingCardData,
    totalGainLossData,
    xirr,
    twrr,
    loading: cardLoader,
    twrrLoader,
    twrrError,
    retryFetchTwrr,
  } = useCardsApi("mf");
  // const { returns: xirrCurrentHolding } = useReturns(
  //   fetchXirrCurrentHolding,
  //   "xirr",
  //   checked,
  //   "mf"
  // );
  // const { returns: twrrCurrentHolding } = useReturns(
  //   fetchTwrrCurrentHolding,
  //   "twrr",
  //   checked,
  //   "mf"
  // );
  const {
    // holdingCardData,
    xirrCurrentHolding,
    twrrCurrentHolding,
    loading: currentHoldingCardLoader,
    twrrLoaderCurrentHolding,
    twrrErrorCurrentHolding,
    retryFetchTwrrCurrentHolding,
  } = useCurrentHoldingCardsApi(checked, "mf");

  const classes = useStyles();
  const [flip, setFlip] = useState(false);

  // const [twrr, setTwrr] = useState({});
  // const [twrrCurrentHolding, setTwrrCurrentHolding] = useState({});
  // const [xirr, setXirr] = useState({});
  // const [xirrCurrentHolding, setXirrCurrentHolding] = useState({});
  const [assetData, setAssetData] = useState([]);
  const [start, setStart] = useState({ state: false });
  const [realisedData, setRealisedData] = useState({
    arr: [],
  });
  const [xval, setxval] = useState(
    generateDates(moment().subtract(1, "months"), "days", 1)
  );
  const [loadque, setloadque] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);

  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  let selectedAssetClass = getfilter_as_params().assetClass;
  // console.log("mfoSummary", getfilter_as_params().assetClass);
  const { userState } = OnboardingContext();
  const [assets, setAssets] = useState({});
  const [assetTotalData, setAssetTotalData] = useState({});
  const [newCardData, setNewCardData] = useState([]);

  // const [chartList, setChartList] = useState({ "Portfolio Value": true, "Twrr": false })

  const [checkedindex, setCheckedIndex] = useState({});
  const [newCardData1, setNewCardData1] = useState([]);

  const [asset_color, setAsset_Color] = useState(Object.values(colorAndProd));
  const [pie, setPie] = useState({
    title: "",
    value: "",
    colors: asset_color,
    disabled: [],
    fill: [
      {
        match: {
          id: "debt",
        },
        id: "c6",
      },
      {
        match: {
          id: "equity",
        },
        id: "c3",
      },
      {
        match: {
          id: "alternate",
        },
        id: "c2",
      },
      {
        match: {
          id: "cash",
        },
        id: "c4",
      },
    ],
  });

  const config = {
    enablePoints: true,
    curve: "linear",
    disablePointLabel: true,
    disableAxisBottom: true,
    pointSize: 0.1,
    nopoint: true,
    hide_legends: false,
    leftAxis: {
      legend: "Portfolio Value",
      renderTick: (tick) => {
        return (
          <g transform={`translate(${tick.x - 30},${tick.y})`}>
            {/* <rect x={-14} y={-6} rx={3} ry={3} width={28} height={24} fill="rgba(0, 0, 0, .05)" />
                        <rect x={-12} y={-12} rx={2} ry={2} width={24} height={24} fill="rgb(232, 193, 160)" /> */}
            {/* <line stroke="rgb(232, 193, 160)" strokeWidth={1.5} y1={-22} y2={-12} /> */}
            {/* <rect x={-40} y={-15} rx={2} ry={2} width={100} height={3} fill="rgba(31, 28, 64, 1)" /> */}
            <text
              textAnchor="middle"
              dominantBaseline="middle"
              style={{
                fill: "#b0aab7",
                fontSize: 10,
              }}
            >
              {tick.value}
            </text>
          </g>
        );
      },
    },
    extra_props: {
      enableSlices: "x",
      sliceTooltip: (data) => {
        // console.log(data);
        return (
          <div
            style={{
              padding: 7,
              color: "#FFFFFF",
              background: "#2A274F",
              borderRadius: "10px",
            }}
          >
            {/* <br /> */}
            <div
              className="subtext"
              style={{
                display: "flex",
                fontFamily: "Avenir",
                paddingLeft: "25px",
                paddingRight: "19px",
                lineHeight: "24px",
              }}
            >
              {/* <div style={{background:data.point.serieColor,width:"20px",height:"20px",borderRadius:"10px",marginRight:"5px"}}></div> */}
              {data.slice.points[0].data.x
                ? dateFormatCommon(data.slice.points[0].data.x, "YYYY-MM-DD")
                : ""}
            </div>

            {data.slice.points.map((d, i) => {
              return (
                <div
                  style={{
                    display: "flex",
                    fontFamily: "Avenir",
                    paddingLeft: "25px",
                    paddingRight: "19px",
                    lineHeight: "24px",
                  }}
                >
                  {/* <div style={{background:data.point.serieColor,width:"20px",height:"20px",borderRadius:"10px",marginRight:"5px"}}></div> */}
                  <div
                    style={{
                      width: "300px",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {d.id}
                  </div>{" "}
                  : {roundNumber(d?.data?.y)}{" "}
                </div>
              );
            })}
          </div>
        );
      },
    },
  };
  const color = colorAndProd;

  const navigate = useNavigate();

  function generateDates(dateStart, type, count) {
    var dateEnd = moment();

    var timeValues = [];

    while (
      dateEnd > dateStart ||
      dateStart.format("DD-MM-YYYY") === dateEnd.format("DD-MM-YYYY")
    ) {
      timeValues.push(dateStart.format("YYYY-MM-DD"));
      dateStart.add(count, type);
    }
    return timeValues;
  }

  const handleSummaryClick = () => {
    navigate("/home/holding-summary/details");
  };
  divergingCommonProps.axisLeft.legend = "Time Period";
  divergingCommonProps.axisBottom.legend = "Percentage Change";
  divergingCommonProps.axisBottom.legendOffset = 42;
  const diverging = {
    ...divergingCommonProps,
  };
  diverging.axisBottom.legendOffset = 65;
  diverging.margin = { top: 5, right: 20, bottom: 70, left: 80 };

  useEffect(() => {
    //userState.loginedUserData.id;
    const clientId = userState.loginedUserData.id;
    // const clientId = 199;

    let params = { ...getfilter_as_params() };
    // console.log(params, "afjkabshjvf");

    let current = undefined;
    let realizedCurrent1 = undefined;

    if (checked) {
      current = "asset-wise";
      realizedCurrent1 = "gain-loss";
    } else {
      current = "current-holding-asset-wise";
      realizedCurrent1 = "current-holding-gain-loss";
    }

    setLoading(true);
    Promise.allSettled([
      // fetchXirr(clientId, params),
      // fetchXirrCurrentHolding(clientId, params),
      // fetchTwrr(clientId, params),
      // fetchTwrrCurrentHolding(clientId, params),
      getRealisedData(clientId, params, undefined, checked),
      getAssetWiseData(clientId, params, undefined, checked),
    ]).then(
      ([
        // xirr_res,
        // xirrCurrentHolding_res,
        // twrr_res,
        // twrrCurrentHolding_res,
        realiseddata_res,
        asset_ress,
        index_performance,
      ]) => {
        // async function allres(){
        //   await xirr_res
        //   await twrr_res
        //   await realiseddata_res
        //   await asset_ress
        //   setLoading(false)
        // }

        // if (xirr_res.status == "fulfilled") {
        //   setXirr(xirr_res.value.result);
        // }
        // if (xirrCurrentHolding_res.status == "fulfilled") {
        //   setXirrCurrentHolding(xirrCurrentHolding_res.value.result);
        // }
        // if (twrr_res.status == "fulfilled") {
        //   if (twrr_res.value?.result?.annualisedTwrr) {
        //     twrr_res.value.result.annualisedTwrr =
        //       twrr_res.value?.result?.annualisedTwrr * 100;
        //   }
        //   setTwrr(twrr_res.value.result);
        // }
        // if (twrrCurrentHolding_res.status == "fulfilled") {
        //   if (twrrCurrentHolding_res.value?.result?.annualisedTwrr) {
        //     twrrCurrentHolding_res.value.result.annualisedTwrr =
        //       twrrCurrentHolding_res.value?.result?.annualisedTwrr * 100;
        //   }
        //   setTwrrCurrentHolding(twrrCurrentHolding_res.value.result);
        // }
        if (realiseddata_res.status == "fulfilled") {
          let arr = [];
          if (realiseddata_res.value.result.unRealisedGainLossPercent != null) {
            if (realiseddata_res.value.result.realisedGainLossPercent != 0) {
              arr.push({
                id: `Realised Gain Loss`,
                label: `Realised Gain`,
                value: parseFloat(
                  realiseddata_res.value.result.realisedGainLossPercent
                ).toFixed(2),
                amount: realiseddata_res.value.result.realisedGainLoss,
              });
            }
            if (realiseddata_res.value.result.unRealisedGainLossPercent != 0) {
              arr.push({
                id: `Unrealised Gain Loss`,
                label: `Unrealised Gain`,
                value: parseFloat(
                  realiseddata_res.value.result.unRealisedGainLossPercent
                ).toFixed(2),
                amount: realiseddata_res.value.result.unRealisedGainLoss,
              });
            }
          }

          setRealisedData({ ...realiseddata_res.value.result, arr });
          setStart({ state: true });
        } else {
          setStart({ state: true });
        }

        if (asset_ress.status == "fulfilled") {
          let arr = [];
          let asset = {};
          let colors = [];

          asset_ress.value.data.forEach((a) => {
            if (selectedAssetClass.includes(a?.asset_class)) {
              arr.push({
                id: `${a.asset_class}`,
                label: `${
                  a.asset_class
                    ? a.asset_class.charAt(0).toUpperCase() +
                      a?.asset_class?.slice(1)
                    : " "
                }  ${a.percentage}%`,
                value: Number(a.percentage).toFixed(2),
                amount: a.amount,
                color: colorAndProd[a.asset_class],
              });
              colors.push(colorAndProd[a.asset_class]);
            }

            asset = {
              ...asset,
              [a.asset_class]: {
                percent: Number(a.percentage).toFixed(2),
                amount: a.amount,
                date: a.updatedAsOn,
              },
            };
            // if (color[a.asset_class.toLowerCase()]) {
            //   colors.push(color[a.asset_class.toLowerCase()]);
            // }
            // arr.push({
            //   id: `${
            //     a?.asset_class.charAt(0).toUpperCase() + a?.asset_class.slice(1)
            //   }`,
            //   label: `${
            //     a.asset_class
            //       ? a?.asset_class.charAt(0).toUpperCase() +
            //         a?.asset_class.slice(1)
            //       : " "
            //   }`,
            //   value: parseFloat(a.percentage).toFixed(2),
            //   amount: currencyFormat(a.amount),
            // });
            // asset = {
            //   ...asset,
            //   [a.asset_class]: {
            //     percent: Number(a.percentage).toFixed(2),
            //     amount: a.amount,
            //     date: dateFormatCommon(a.updatedAsOn, "DD/MM/YYYY"),
            //   },
            // };
          });

          // let c = {
          //   success: true,
          //   event: "PRIVATE_PERFORMANCE_ASSET_WISE",
          //   message: "Asset-wise performance fetch successfully.",
          //   uuid: "28a8b130-db64-11ed-844c-f34a4aa7ccb1",
          //   data: {
          //     meta: {
          //       filters: {
          //         client_id: 199,
          //         asset_type: {},
          //         valuation_date: {},
          //       },
          //     },
          //     result: [
          //       // {
          //       //   asset_class: "equity",
          //       //   amount: 3601993.5,
          //       //   percentage: 40,
          //       //   as_on_date: "15/04/2023",
          //       // },
          //       {
          //         asset_class: "alternate",
          //         amount: 3601993.5,
          //         percentage: 30,
          //         as_on_date: "15/04/2023",
          //       },
          //       {
          //         asset_class: "debt",
          //         amount: 3601993.5,
          //         percentage: 30,
          //         as_on_date: "15/04/2023",
          //       },
          //       {
          //         asset_class: "equity",
          //         amount: 3601993.5,
          //         percentage: 20,
          //         as_on_date: "15/04/2023",
          //       },
          //       {
          //         asset_class: "cash",
          //         amount: 3601993.5,
          //         percentage: 20,
          //         as_on_date: "15/04/2023",
          //       },
          //     ],
          //   },
          // };

          // c.data.result?.map((a) => {
          //   if (selectedAssetClass.includes(a?.asset_class)) {
          //     arr.push({
          //       id: `${a.asset_class}`,
          //       label: `${
          //         a.asset_class
          //           ? a.asset_class.charAt(0).toUpperCase() +
          //             a?.asset_class?.slice(1)
          //           : " "
          //       }  ${a.percentage}%`,
          //       value: Number(a.percentage).toFixed(2),
          //       amount: a.amount,
          //       color: color[a.asset_class],
          //     });
          //     colors.push(color[a.asset_class]);
          //   }

          //   asset = {
          //     ...asset,
          //     [a.asset_class]: {
          //       percent: Number(a.percentage).toFixed(2),
          //       amount: a.amount,
          //       date: a.as_on_date,
          //     },
          //   };
          // });
          const data = [
            {
              key: "equity",
              title: "Equity",
              amount: asset["equity"]
                ? currencyFormat(asset["equity"]?.amount)
                : currencyFormat(0),
              percent: asset["equity"]
                ? roundNumber(asset["equity"]?.percent) + "%"
                : "0.00%",
              dateStr: asset["equity"]?.date ? "Updated as on" : "",
              date: dateFormatCommon(asset["equity"]?.date),
              color: "bluebox",
              width: "240px",
            },
            {
              key: "debt",
              title: "Debt",
              amount: asset["debt"]
                ? currencyFormat(asset["debt"]?.amount)
                : currencyFormat(0),
              percent: asset["debt"]
                ? roundNumber(asset["debt"]?.percent) + "%"
                : "0.00%",
              dateStr: asset["debt"]?.date ? "Updated as on" : "",
              date: dateFormatCommon(asset["debt"]?.date),
              // color: "greenbox",
              color: "orangebox",
              width: "240px",
            },
            {
              key: "alternate",
              title: "Alternates",
              amount: asset["alternate"]
                ? currencyFormat(asset["alternate"]?.amount)
                : currencyFormat(0),
              percent: asset["alternate"]
                ? roundNumber(asset["alternate"]?.percent) + "%"
                : "0.00%",
              dateStr: asset["alternate"]?.date ? "Updated as on" : "",
              date: dateFormatCommon(asset["alternate"]?.date),
              // color: "orangebox",
              color: "greenbox",
              width: "240px",
            },
            {
              key: "cash",
              title: "Cash",
              amount: asset["cash"]
                ? currencyFormat(asset["cash"]?.amount)
                : currencyFormat(0),
              percent: asset["cash"]
                ? roundNumber(asset["cash"]?.percent) + "%"
                : "0.00%",
              dateStr: asset["cash"]?.date ? "Updated as on" : "",
              date: dateFormatCommon(asset["cash"]?.date),
              color: "purplebox",
              width: "240px",
            },
            {
              key: "total",
              title: "Total",
              amount: asset_ress.value?.totalAmount
                ? currencyFormat(asset_ress.value?.totalAmount)
                : currencyFormat(0),
              percent: asset_ress.value?.totalPercentage
                ? roundNumber(asset_ress.value?.totalPercentage) + "%"
                : "0.00%",
              dateStr: asset_ress.value?.lastUpdatedDate ? "Updated as on" : "",
              date: dateFormatCommon(asset_ress.value?.lastUpdatedDate),
              color: "purplebox",
              width: "240px",
            },
          ];

          let cardData1 = data.map((data) => {
            if (!selectedAssetClass.includes(data.key)) {
              data.dontShow = true;
            }
            if (data.key === "total") {
              data.dontShow = false;
            }
            return data;
          });

          setNewCardData1(cardData1);

          setNewCardData(
            data.filter((data) => selectedAssetClass.includes(data.key))
          );

          setAssetData(arr);
          colors = [];
          arr.forEach((a) => {
            colors.push(colorAndProd[a.id]);
          });
          setPie({ ...pie, colors: colors });
          setAsset_Color(colors);
          setAssets(asset);

          setAssetTotalData({
            percent: Number(asset_ress.value?.totalPercentage).toFixed(2),
            amount: asset_ress.value?.totalAmount,
            date: asset_ress.value?.lastUpdatedDate,
          });
        }
        setLoading(false);
      }
    );
  }, [mfoFilter, checked]);
  function holdingSummaryCardsInactiveData() {
    return {
      asOnDate:
        realisedData?.asOnDate != null
          ? holdingCardData?.asOnDate
          : loadingText,

      //    * current-holding api values
      portfolioValueAmount:
        holdingCardData?.portfolioValueAmount != null
          ? currencyFormat(holdingCardData?.portfolioValueAmount)
          : loadingText,
      //   * gain-loss api values
      overallInvestedAmount:
        realisedData?.overAllInvestedAmount != null
          ? currencyFormat(realisedData?.overAllInvestedAmount)
          : loadingText,
      //    * current-holding api values
      investedAmount:
        holdingCardData?.investedAmount != null
          ? currencyFormat(holdingCardData?.investedAmount)
          : loadingText,
      //    * current-holding api values
      redeemedInvestedAmount:
        holdingCardData?.redeemedInvestedAmount != null
          ? currencyFormat(holdingCardData?.redeemedInvestedAmount)
          : loadingText,
      //   * total-gain-loss api value
      totalGainLoss:
        totalGainLossData?.totalGainLoss != null
          ? currencyFormat(totalGainLossData?.totalGainLoss)
          : loadingText,
      //   * gain-loss api values
      realisedGainLoss:
        realisedData?.realisedGainLoss != null
          ? currencyFormat(realisedData?.realisedGainLoss)
          : loadingText,
      //    * current-holding api values
      unRealisedGainLoss:
        holdingCardData?.unRealisedGainLoss != null
          ? currencyFormat(holdingCardData?.unRealisedGainLoss)
          : loadingText,
      //   * gain-loss api values
      xirrValue:
        xirr?.xirr != null
          ? roundNumberWithoutDecimal(Number(xirr?.xirr)) + " %"
          : loadingText,
      //   * gain-loss api values
      absoluteTwrrData:
        twrr?.absoluteTwrr != null
          ? roundNumberWithoutDecimal(Number(twrr?.absoluteTwrr)) + " %"
          : loadingText,
      //   * gain-loss api values
      annualisedTwrrData:
        twrr?.annualisedTwrr != null
          ? roundNumberWithoutDecimal(Number(twrr?.annualisedTwrr)) + " %"
          : loadingText,
      //   * gain-loss api values
      interestIncome:
        realisedData?.interestIncome != null
          ? currencyFormat(realisedData?.interestIncome)
          : loadingText,
    };
  }
  function holdingSummaryCardsActiveData() {
    return {
      asOnDate:
        holdingCardData?.asOnDate !== null
          ? holdingCardData?.asOnDate
          : loadingText,
      portfolioValueAmount:
        holdingCardData?.portfolioValueAmount !== null
          ? currencyFormat(holdingCardData?.portfolioValueAmount)
          : loadingText,
      investedAmount:
        holdingCardData?.investedAmount !== null
          ? currencyFormat(holdingCardData?.investedAmount)
          : loadingText,
      totalGainLoss:
        holdingCardData?.totalGainLoss !== null
          ? currencyFormat(holdingCardData?.totalGainLoss)
          : loadingText,
      realisedGainLoss:
        holdingCardData?.realisedGainLoss !== null
          ? currencyFormat(0)
          : loadingText,
      unRealisedGainLoss:
        holdingCardData?.unRealisedGainLoss !== null
          ? currencyFormat(holdingCardData?.unRealisedGainLoss)
          : loadingText,
      xirrValue:
        xirrCurrentHolding?.xirr !== null
          ? roundNumberWithoutDecimal(Number(xirrCurrentHolding?.xirr)) + " %"
          : loadingText,
      absoluteTwrrData:
        twrrCurrentHolding?.absoluteTwrr !== null
          ? roundNumberWithoutDecimal(
              Number(twrrCurrentHolding?.absoluteTwrr)
            ) + " %"
          : loadingText,
      annualisedTwrrData:
        twrrCurrentHolding?.annualisedTwrr !== null
          ? roundNumberWithoutDecimal(
              Number(twrrCurrentHolding?.annualisedTwrr)
            ) + " %"
          : loadingText,
      interestIncome:
        holdingCardData?.interestIncome != null
          ? currencyFormat(holdingCardData?.interestIncome)
          : loadingText,
    };
  }
  return (
    <>
      <SummaryPageTop
        backLink={routes?.homeHoldingPage}
        pageTitle={"Mutual Fund Portfolio Summary"}
        flip={flip}
        setFlip={setFlip}
        checked={checked}
        setChecked={setChecked}
        setFilterModalOpen={setFilterModalOpen}
        filterModalOpen={filterModalOpen}
      />
      <NewFilter
        filterModalOpen={filterModalOpen}
        setFilterModalOpen={setFilterModalOpen}
        productType="mf"
      />
      {/* <PageFilter />

      <div className={`${classes.alignTopHeader}`}>
        <Back
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/home/holding-summary/details");
          }}
        />
        <Typography
          style={{ marginLeft: "-4%" }}
          className={`${classes.mainHeading} ${classes.mainHeadingAlign}`}
        >
          Mutual Fund Portfolio Summary
          <br></br>
          {sessionStorage.getItem("currency_format") != "actuals" &&
            sessionStorage.getItem("currency_format") && (
              <span
                className="subtext capitalize"
                style={{ fontSize: "16px", position: "absolute" }}
              >
                {" "}
                (values in {sessionStorage.getItem("currency_format")})
              </span>
            )}
        </Typography>
        <div className={`${classes.iconAlign}`}>
          {!flip && (
            <Bulb
              onClick={() => setFlip(!flip)}
              className={classes.iconStyle}
            />
          )}
          {flip && (
            <LitBulb
              onClick={() => setFlip(!flip)}
              className={classes.iconStyle}
            />
          )}
          <Import className={classes.iconStyle} />
          <Export className={classes.iconStyle} />
          <Print className={classes.iconStyle} />
          <Filter className={classes.iconStyle} />
        </div>
      </div> */}

      {/* <HoldingCardSummary></HoldingCardSummary> */}
      {/* {FlippyArray(mfoFLipArr, flip)} */}
      {/* <FlippyArray
        page={"mfo"}
        portfolioData={
          realisedData.portfolioValueAmount != null
            ? currencyFormat(realisedData.portfolioValueAmount)
            : loadingText
        }
        investmentData={
          realisedData.investedAmount != null
            ? currencyFormat(realisedData.investedAmount)
            : loadingText
        }
        gainData={
          realisedData.totalGainLoss != null
            ? currencyFormat(realisedData.totalGainLoss)
            : loadingText
        }
        xirrData={
          xirr.xirr != null
            ? roundNumber(Number(xirr.xirr)) + " %"
            : loadingText
        }
        absoluteTwrrData={
          twrr.absoluteTwrr != null
            ? roundNumber(Number(twrr.absoluteTwrr)) + " %"
            : loadingText
        }
        annualisedTwrrData={
          twrr.annualisedTwrr != null
            ? roundNumber(Number(twrr.annualisedTwrr)) + " %"
            : loadingText
        }
        realisedData={realisedData}
        flip={flip}
      /> */}
      <div style={{ margin: "2%" }}>
        {!checked ? (
          <NewHoldingSummaryCards
            retryFetchTwrr={retryFetchTwrr}
            twrrLoader={twrrLoader}
            twrrError={twrrError}
            // infoIconHovered={infoIconHovered}
            infoIcon={
              infoIconHovered && (
                <TotalGainLossCardInfo
                  textToRender={
                    <p className="totalIncomeDescription">
                      In case of Mutual Funds you will find dividends from
                      equity ownership you hold through the Equity Mutual Funds
                      you may hold and enjoy regular interest payments from Debt
                      Mutual Funds you may hold.
                    </p>
                  }
                />
              )
            }
            holdingSummaryCardsInactive={holdingSummaryCardsInactiveData()}
            totalGainLossInactive={
              <>
                <GainsCardInfo
                  text="Realized Gain/Loss"
                  value={holdingSummaryCardsInactiveData()?.realisedGainLoss}
                  align="left"
                />
                <GainsCardInfo
                  text="Unrealized Gain/Loss"
                  value={holdingSummaryCardsInactiveData()?.unRealisedGainLoss}
                  align="center"
                />
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <GainsCardInfo
                    text="Other Income"
                    value={holdingSummaryCardsInactiveData()?.interestIncome}
                    align="right"
                    customStyle={{ marginRight: "2rem" }}
                  />
                  <InfoIcon
                    onMouseOver={() => setInfoIconHovered(true)}
                    onMouseOut={() => setInfoIconHovered(false)}
                    style={{
                      position: "absolute",
                      top: "4px",
                      right: "4px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </>
            }
          ></NewHoldingSummaryCards>
        ) : (
          <NewHoldingSummaryCardsActive
            retryFetchTwrrCurrentHolding={retryFetchTwrrCurrentHolding}
            twrrLoaderCurrentHolding={twrrLoaderCurrentHolding}
            twrrErrorCurrentHolding={twrrErrorCurrentHolding}
            infoIconHovered={infoIconHovered}
            holdingSummaryCardsActive={holdingSummaryCardsActiveData()}
            totalGainLossActive={
              <>
                <GainsCardInfo
                  text="Unrealized Gain/Loss"
                  value={holdingSummaryCardsActiveData()?.unRealisedGainLoss}
                  align="left"
                />
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <GainsCardInfo
                    text="Other Income"
                    value={holdingSummaryCardsActiveData()?.interestIncome}
                    align="right"
                    customStyle={{ marginRight: "2rem" }}
                  />
                  <InfoIcon
                    onMouseEnter={() => setInfoIconHovered((prev) => !prev)}
                    onMouseLeave={() => setInfoIconHovered((prev) => !prev)}
                    style={{
                      position: "absolute",
                      top: "4px",
                      right: "4px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </>
            }
          ></NewHoldingSummaryCardsActive>
        )}
      </div>

      {/* <div style={{ height: "175px", display: "flex", marginBottom: "1%", padding: "25px 15px", }} className={classes.mainContent}>
                <Grid xs={2}>
                    <Card className="bgholdingsummary bigCard">
                        <CardContent className={classes.mfoCardAlign}>
                            <div className={classes.subtractCircle}>
                                <Subtract className={classes.mfoCircleAlign} />
                            </div>
                            <Typography className={classes.mfoTextCard} style={{ marginBottom: '2px' }}>
                                Portfolio Value
                            </Typography>
                            <Typography style={{ marginBottom: '0px' }} className={classes.mfoCardValue}>{realisedData.portfolioValueAmount ? currencyFormat(realisedData.portfolioValueAmount) : "--"}</Typography>
                            <Typography className={classes.mfoSubTextCard} style={{ marginBottom: '2px' }}>
                                As on {realisedData?.asOnDate}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={2}>
                    <Card className="bgholdingsummary bigCard">
                        <CardContent className={classes.mfoCardAlign}>
                            <div className={classes.subtractCircle}>
                                <Subtract className={classes.mfoCircleAlign} />
                            </div>
                            <Typography className={classes.mfoTextCard}>
                                Investment Value
                            </Typography>
                            <Typography className={classes.mfoCardValue}>{realisedData.investedAmount ? currencyFormat(realisedData.investedAmount) : "--"}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={2}>
                    <Card className="bgholdingsummary bigCard">
                        <CardContent className={classes.mfoCardAlign}>
                            <div className={classes.subtractCircle}>
                                <Subtract className={classes.mfoCircleAlign} />
                            </div>
                            <Typography className={classes.mfoTextCard}>
                                Gain/Loss
                            </Typography>
                            <Typography className={classes.mfoCardValue}>{realisedData.totalGainLoss ? currencyFormat(realisedData.totalGainLoss) : "--"}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={2}>
                    <Card className="bgholdingsummary bigCard">
                        <CardContent className={classes.mfoCardAlign}>
                            <div className={classes.subtractCircle}>
                                <Subtract className={classes.mfoCircleAlign} />
                            </div>
                            <Typography className={classes.mfoTextCard}>
                                XIRR (%)
                            </Typography>
                            <Typography className={classes.mfoCardValue}>{xirr.xirr ? Number(xirr.xirr).toFixed(2) + " %" : "--"}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={2}>
                    <Card className="bgholdingsummary bigCard">
                        <CardContent className={classes.mfoCardAlign}>
                            <div className={classes.subtractCircle}>
                                <Subtract className={classes.mfoCircleAlign} />
                            </div>
                            <Typography className={classes.mfoTextCard}>
                                Annualised TWRR
                            </Typography>

                            <Typography className={classes.mfoCardValue}>{twrr.annualisedTwrr ? Number(twrr.annualisedTwrr).toFixed(2) + " %" : "--"}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={2}>
                    <Card className="bgholdingsummary bigCard">
                        <CardContent className={classes.mfoCardAlign}>
                            <div className={classes.subtractCircle}>
                                <Subtract className={classes.mfoCircleAlign} />
                            </div>
                            <Typography className={classes.mfoTextCard}>
                                Absolute TWRR
                            </Typography>

                            <Typography className={classes.mfoCardValue}>{twrr.absoluteTwrr ? Number(twrr.absoluteTwrr).toFixed(2) + " %" : "--"}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </div> */}

      <SummaryDetailsSectionWrapper
        summaryDetailsSecConstData={summaryDetailsSecConstData}
        cardData1={newCardData1}
        hideNavButtons={true}
        handleClick={() => navigate(routes.mfDetailed_Detailed)}
        peiChart={
          <div className="flex_center tr20" style={{ height: "100%" }}>
            <div className={classes.wid380px}>
              <PieChart
                colors={pie.colors}
                // fill={pie.fill}
                config={{
                  innerRadius: "0.80",
                  cornerRadius: 0,
                  padAngle: 0,
                  legends: false,
                  disableArcLink: true,
                  minWith: "380px",
                  chart: {
                    width: 440,
                    height: 420,
                    arcLinkLabelsStraightLength: 15,
                    arcLinkLabelsDiagonalLength: 20,
                  },

                  arcLinkLabel: (d) => (
                    <tspan
                      x="0"
                      y="0"
                      style={{
                        fontFamily: "Avenir",
                        fontWeight: "400",
                        fontSize: "12",
                        textTransform: "capitalize",
                      }}
                    >
                      {" "}
                      {d.id}
                      <tspan
                        x="0"
                        y="18"
                        style={{
                          fontFamily: "Open Sans",
                          fontWeight: "700",
                          fontSize: "14",
                        }}
                      >
                        {" "}
                        {d.value}%
                      </tspan>
                    </tspan>
                  ),
                }}
                onClickEvent={{
                  click: pie_onClick2,
                  set: setPie,
                  cstate: pie,
                  ncolors: asset_color,
                }}
                data={assetData}
              >
                <GlowCircle>
                  <div className="circle_content">
                    <div>
                      <b className="circle_text">
                        {pie.title ? pie.title : "Portfolio value"}
                      </b>
                      <br></br>
                      <b>
                        {pie.amount
                          ? currencyFormat(pie.amount)
                          : assetTotalData.amount
                          ? currencyFormat(assetTotalData.amount)
                          : "__"}
                      </b>
                      <div
                        className="circle_text2"
                        style={{ paddingTop: "5px" }}
                      >
                        {pie?.value ? roundNumber(pie?.value) + "%" : ""}
                      </div>
                    </div>
                  </div>
                </GlowCircle>
              </PieChart>
            </div>
          </div>
        }
      />
      <PageLoader showLoader={loading ? true : false} />
      <PageLoader
        showLoader={currentHoldingCardLoader && checked ? true : false}
      />
      <PortfolioChart type={"mf"} start={start} loading={loading} />
      {/* 👇Commenting the button as per change in requirement 👇 */}
      {/* <div className={classes.buttonLightBlueAlign}>
        <Button
          onClick={() => navigate("/home/mfo/mfo-summary/report/detailed")}
          className={`${classes.buttonLightBlue} ${classes.tableBodyTextNew}`}
        >
          <span
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Eye />
            View Detail Report
          </span>
        </Button>
      </div> */}
    </>
  );
}

export default MfoSummaryComp;
