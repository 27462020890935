import React from "react";
import Overall_portfolioHolding from "./Overall_portfolioHolding";
import NewProduct from "./newProduct";
import AssetClassSummaryPdf from "./AssetClassSummaryPdf";
import ProductandHoldingpdf from "./ProductandHoldingpdf";
import { isArray } from "lodash";

export default function MemberReport({
  sortData,
  newData,
  pdfInfo,
  denomination,
  setChanges,
}) {
  function generatePages() {
    if (newData == "error") {
      return <></>;
    }
    return (
      isArray(newData) &&
      newData?.map((data) => {
        let assettotal = {
          assetClass: "Total",

          interestIncome: data?.data?.overview?.["gainLoss"]?.interestIncome,

          investedAmount: data?.data?.overview?.["gainLoss"]?.investedAmount,

          percentageOfTotalPortfolio:
            data?.data?.overview?.["gainLoss"]?.totalPortfolioPercentage,

          portfolioValueAmount:
            data?.data?.overview?.["gainLoss"]?.portfolioValueAmount,

          unRealisedGainLoss:
            data?.data?.overview?.["gainLoss"]?.unRealisedGainLoss,

          xirr: data?.data?.overview?.["currentHoldingXirr"]?.xirrValue,
        };

        return (
          <>
            <div className="newPrint">
              <Overall_portfolioHolding
                newData={data?.data?.overview}
                denomination={denomination}
                xirrStr={"xirr"}
                gainLossDataStr={"gainLoss"}
                topAllocationsStr={"topAllocations"}
                assetSummaryStr={"assetSummary"}
                member={data?.memberName}
                // data={data}
                pdfInfo={pdfInfo}
              />
            </div>
            <AssetClassSummaryPdf
              denomination={denomination}
              title={"Summary"}
              data={
                [...data?.data?.overview?.["assetDetails"]?.data, assettotal] ||
                {}
              }
              pdfInfo={pdfInfo}
              member={data?.memberName}
            />
            <ProductandHoldingpdf
              sortData={sortData}
              denomination={denomination}
              newdata={data?.data?.assets || {}}
              overview={data?.data?.overview}
              assetDetails={data?.data?.overview?.["assetDetails"]?.data || {}}
              pdfInfo={pdfInfo}
              member={data?.memberName}
              setChanges={setChanges}
            />
            {/* <NewProduct
            newData={data}
            reportType={"Product allocation"}
            denomination={denomination}
            setChanges={setChanges}
            pdfInfo={pdfInfo}
          /> */}
          </>
        );
      })
    );
  }

  return <div>{newData && generatePages()}</div>;
}
