import React, { useState } from "react";
import EquitySubTab from "./EquitySubTab";
import CustomTable1 from "../../../utils/CustomTable1";
import { holdinTableConfig } from "./utils/constands";
import { objectFormating1 } from "../../../utils/objectFormating";
import NewtableTopSec from "../../common/NewtableTopSec";

function EquityHolding() {
  const [openModal, setOpenModal] = useState(false);
  const [scripName, setScripName] = useState(null);
  const [offset, setOffset] = useState(0);

  const handleIconClick = () => {
    setOpenModal(!openModal);
  };

  return (
    <div>
      <NewtableTopSec
        handleIconClick={handleIconClick}
        directPageName={"Holding Summary"}
        setSearch={(e) => {
          setOffset(0);
          setScripName(e);
        }}
        // product={"MLD"}
      />
      <CustomTable1
        product="equity"
        tableName="summary/holding"
        objectFormating={objectFormating1}
        headerConfig={holdinTableConfig}
        tableBodyBackground="#1D1B36"
        openModal={openModal}
        setOpenModal={setOpenModal}
        scripName={scripName}
        // TODO Remove this prop when search API ready
        tableType="equityHolding"
        offset={offset}
        setOffset={setOffset}
      />
    </div>
  );
}

export default EquityHolding;
