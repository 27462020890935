import {
  Typography,
  Box,
  Button,
  Grid,
  TextField,
  useTheme,
} from "@material-ui/core";
import { ReactComponent as Back } from "../../assets/holding-summary/back-blue.svg";
import { ReactComponent as ArrowButton } from "../../assets/clientdashboard/userManagementArrow.svg";
import { ReactComponent as EditIcon } from "../../assets/clientdashboard/editIcon.svg";
import { useStyles } from "./UserManagementHeaderStyles";
import { useUserManagementForm } from "../../hooks/useUserManagementForm";
import { ActiveInactiveToggle } from "../dashboard/common/ActiveInactiveToggle";
import { EditableComponent } from "./EditableComponent";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PermissionsGate } from "../auth/PermissionGate";
import ls from "localstorage-slim";

export function UserManagementHeader({
  handleback,
  initialValue,
  details,
  handleSave,
  data,
}) {
  const classes = useStyles();
  const [status, setStatus] = useState(details.status);
  const navigate = useNavigate();
  const scopes = JSON.parse(ls.get("scopes"));

  // //   ** 👇These values would be coming from the route state
  // const fullNameProps = "Rahul Vakil";
  // const mobileNumberProps = 9819812498;
  // const emailIdProps = "johndoe@gmail.com";

  // const fullNameOutsideClickRef = useRef(null);
  // const mobileNumberOutsideClickRef = useRef(null);
  // const emailIdOutsideClickRef = useRef(null);
  // // ** EDIT STATES
  // const [isEditingFullName, setIsEditingFullName] = useState(false);
  // const [isEditingMobileNumber, setIsEditingMobileNumber] = useState(false);
  // const [isEditingEmailId, setIsEditingEmailId] = useState(false);
  // // ** FORMIK INITIAL STATES
  // const formik = useFormik({
  //   initialValues: {
  //     fullName: fullNameProps,
  //     mobileNumber: mobileNumberProps,
  //     emailId: emailIdProps,
  //   },
  //   validationSchema: Yup.object({
  //     fullName: Yup.string().required("First Name is required"),
  //     mobileNumber: Yup.string()
  //       .required("Mobile Number is Required")
  //       .matches(/^[0-9]{10}$/, "Mobile Number must be exactly 10 digits"),
  //     emailId: Yup.string()
  //       .email("Invalid email format")
  //       .required("Email is Required"),
  //   }),
  // });
  // console.log("Formik Values", formik.values);
  // console.log("formik error", formik.errors.mobileNumber);
  // useEffect(() => {
  //   const handleOutsideClick = (event) => {
  //     if (
  //       (fullNameOutsideClickRef.current &&
  //         !fullNameOutsideClickRef.current.contains(event.target)) ||
  //       (mobileNumberOutsideClickRef.current &&
  //         !mobileNumberOutsideClickRef.current.contains(event.target)) ||
  //       (emailIdOutsideClickRef.current &&
  //         !emailIdOutsideClickRef.current.contains(event.target))
  //     ) {
  //       // The click occurred outside the component.
  //       console.log("Outside click detected!", formik.errors.mobileNumber);
  //       setIsEditingFullName(false);
  //       setIsEditingMobileNumber(false);
  //       setIsEditingEmailId(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleOutsideClick);

  //   return () => {
  //     document.removeEventListener("mousedown", handleOutsideClick);
  //   };
  // }, []);
  // ** 👇These values would be coming from the route state
  // const initialValues = {
  //   ...initialValue,
  // };

  // console.log(scopes?.can_view_family_tree, "ajblagbj");

  const {
    formik,
    isEditingFullName,
    setIsEditingFullName,
    isEditingMobileNumber,
    setIsEditingMobileNumber,
    isEditingEmailId,
    setIsEditingEmailId,
    fullNameOutsideClickRef,
    mobileNumberOutsideClickRef,
    emailIdOutsideClickRef,
  } = useUserManagementForm(initialValue);

  useEffect(() => {
    formik.setValues(initialValue);
  }, [initialValue]);

  function naivgateToSpecifiedPage() {
    // For Mapped user --> member_id
    // For Other User --> client_member_id
    let memberId = data?.client_id || data?.member_id || data?.client_member_id;
    localStorage.setItem("memberId", memberId);
    // if (text === "View Consolidated Portfolio") {
    //   return naivgate("/home/portfolio");
    // } else if (text === "View Family Tree") {
    navigate("/user-management/family-structure");
    // }
  }

  return (
    <>
      {formik && (
        <Box className={classes.headerWrapper}>
          <div className={classes.gridSpacing}>
            <Grid
              container
              alignItems="center"
              style={{ marginBottom: "20px" }}
            >
              <Grid item xs={1}>
                <UserManagementBackButton handleClick={handleback} />
              </Grid>
              <Grid item xs={7}>
                <UserManagementMainHeading name={formik?.values?.fullName} />
              </Grid>
              <Grid item xs={2}>
                <Button
                  className={classes.saveButton}
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    // if (Object.keys(formik.errors).length > 0) {
                    //   console.log(formik.errors,"errors");
                    //   return;
                    // }
                    if (formik?.errors?.fullName) {
                      return;
                    }

                    if (handleSave) {
                      handleSave({ ...formik.values, status });
                    }
                    // console.log("Clicked$$", formik.errors);
                  }}
                >
                  Save
                </Button>
              </Grid>
              <Grid item xs={2}>
                <PermissionsGate scopes={[scopes?.can_view_family_tree]}>
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{ gap: "30px" }}
                  >
                    <Typography className={classes.arrowText}>
                      View Family Tree
                    </Typography>
                    <Box
                      style={{ marginTop: "4px", cursor: "pointer" }}
                      onClick={naivgateToSpecifiedPage}
                    >
                      <ArrowButton />
                    </Box>
                  </Box>
                </PermissionsGate>
              </Grid>
            </Grid>

            <Grid container alignItems="flex-end">
              <Grid item xs={1}>
                {/* UPLOAD ICON START*/}
                <Box
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                    background: "purple",
                  }}
                ></Box>
                {/* UPLOAD ICON END */}
              </Grid>
              {/* FULL NAME START */}
              <Grid item xs={1}>
                <Box display="flex" style={{ gap: "10px" }}>
                  <Box>
                    <EditableComponent
                      labelHeader="Full Name"
                      isEditing={isEditingFullName}
                      formikErrors={formik?.errors?.fullName}
                      outsideClickRef={fullNameOutsideClickRef}
                      textFieldId="fullName"
                      formikValues={formik?.values?.fullName}
                      formikHandleChange={formik.handleChange}
                      formikHandleBlur={formik.handleBlur}
                      formikTouched={formik?.touched?.fullName}
                      setIsEditingFullName={setIsEditingFullName}
                    />
                  </Box>
                  {/* Render the EditIcon only if not in edit mode and no fullName errors */}
                  {/* {!isEditingFullName && !formik.errors.fullName && (
                    <EditIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => setIsEditingFullName(true)}
                    />
                  )} */}
                </Box>
              </Grid>
              {/* FULL NAME END */}
              <Grid item xs={1}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography className={classes.labelHeader}>
                    User Role
                  </Typography>
                  <Typography className={classes.editText}>
                    {details?.role}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={1}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography className={classes.labelHeader}>
                    PAN NO
                  </Typography>
                  <Typography className={classes.editText}>
                    {details?.pan || "--"}
                  </Typography>
                </div>
              </Grid>

              {/* MOBILE NUMBER START */}
              <Grid item xs={2}>
                {/* <Box display="flex" style={{ gap: "10px" }}> */}
                {/* <Box style={{ marginLeft: "70px" }}>
                    <EditableComponent
                      labelHeader="Mobile Number"
                      isEditing={isEditingMobileNumber}
                      formikErrors={formik.errors.mobileNumber}
                      outsideClickRef={mobileNumberOutsideClickRef}
                      textFieldId="mobileNumber"
                      formikValues={formik.values.mobileNumber || "--"}
                      formikHandleChange={formik.handleChange}
                      formikHandleBlur={formik.handleBlur}
                      formikTouched={formik.touched.mobileNumber}
                    />
                  </Box> */}

                {/* Render the EditIcon only if not in edit mode and no mobileNumber errors */}
                {/* {!isEditingMobileNumber && !formik.errors.mobileNumber && (
                    <EditIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => setIsEditingMobileNumber(true)}
                    />
                  )} */}
                {/* </Box> */}
                <div>
                  <Typography className={classes.labelHeader}>
                    Mobile Number
                  </Typography>
                  <Typography className={classes.editText}>
                    {formik?.values?.mobileNumber || "--"}
                  </Typography>
                </div>
              </Grid>
              {/* MOBILE NUMBER END */}

              {/* EMAIL ID START */}
              <Grid item xs={2}>
                {/* <Box display="flex"> */}
                {/* <Box
                    style={{
                      marginLeft: "25px",
                    }}
                  >
                    <EditableComponent
                      labelHeader="Email Id"
                      isEditing={isEditingEmailId}
                      formikErrors={formik.errors.emailId}
                      outsideClickRef={emailIdOutsideClickRef}
                      textFieldId="emailId"
                      formikValues={formik.values.emailId || "--"}
                      formikHandleChange={formik.handleChange}
                      formikHandleBlur={formik.handleBlur}
                      formikTouched={formik.touched.emailId}
                    />
                  </Box> */}

                {/* Render the EditIcon only if not in edit mode and no emailId errors */}
                {/* {!isEditingEmailId && !formik.errors.emailId && (
                    <EditIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => setIsEditingEmailId(true)}
                    />
                  )} */}
                {/* </Box> */}
                <div>
                  <Typography className={classes.labelHeader}>
                    Email Id
                  </Typography>
                  <Typography className={classes.editText}>
                    {formik?.values?.emailId || "--"}
                  </Typography>
                </div>
              </Grid>
              {/* EMAIL ID END */}

              <Grid item xs={1}>
                <Typography className={classes.labelHeader}>
                  Banker Name
                </Typography>
                <Typography className={classes.editText}>
                  {details?.banker || "--"}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <div>
                  <Typography className={classes.labelHeader}>CXO</Typography>
                  <Typography className={classes.editText}>
                    {details?.cso || "--"}
                  </Typography>
                </div>
              </Grid>

              <Grid item xs={2}>
                <Typography className={classes.labelHeader}>Status</Typography>
                <ActiveInactiveToggle
                  isActive={details?.status}
                  handleChange={(val) => {
                    console.log("checking status");
                    setStatus(val == "false" || !val ? 0 : 1);
                  }}
                />
              </Grid>
            </Grid>
            <UserManagementDivider />
          </div>
        </Box>
      )}
    </>
  );
}

function UserManagementMainHeading({ name }) {
  const classes = useStyles();
  return (
    <Typography className={classes.arrowText}>{name + "'s Details"}</Typography>
  );
}

export function UserManagementBackButton({ handleClick }) {
  const theme = useTheme();
  const icons = theme.palette.icons;
  const BackIcon = icons.backIcon;
  return (
    <BackIcon
      style={{ cursor: "pointer" }}
      onClick={() => {
        handleClick();
      }}
    />
  );
}

function UserManagementDivider() {
  return (
    <section id="title-wrapper">
      <div className="dividerLine"></div>
    </section>
  );
}
