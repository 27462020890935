export var routes = {
  home: "/home",
  printReport: "/print-report",
  printSchedule: "/home/reports/report",
  printReportdownload: "/print-report-download",
  printReportAutoDownload: "/print-report-download",
  get printReportHolding() {
    return `${this.printReport}/holding`;
  },
  newPrintReport: "/home/reports",
  newPrintReportEquity: "/home/equity/reports",
  login: "/",
  register: "/register",
  familyTree: "/user-management/family-structure",
  userManagement: "/user-management", // sidebar condition
  //home holding page
  homeHoldingPage: "/home/portfolio/summary",
  //home holding subs
  homeHoldingSubHead: "/home/portfolio",
  get homeHoldingTop() {
    return `${this.homeHoldingSubHead}/holding`;
  },
  get homeSecurityHodling() {
    return `${this.homeHoldingSubHead}/security-holding`;
  },
  get excessPerformance() {
    return `${this.homeHoldingSubHead}/excess-performance`;
  },

  get portFolioOverView() {
    return `${this.homeHoldingSubHead}/portfolio-overview`;
  },
  get homeHoldingTransaction() {
    return `${this.homeHoldingSubHead}/transaction`;
  },
  get homeHoldingUpcomingTransaction() {
    return `${this.homeHoldingSubHead}/upcoming-transaction`;
  },
  get homeHoldingAssetClassBenchMark() {
    return `${this.homeHoldingSubHead}/asset-class-benchmark`;
  },
  get homeHoldingPerformance() {
    return `${this.homeHoldingSubHead}/performance`;
  },
  get homeHoldingAdvisor() {
    return `${this.homeHoldingSubHead}/advisor`;
  },
  get homeHoldingAssetAllocation() {
    return `${this.homeHoldingSubHead}/asset-allocation`;
  },
  get homeHoldingProductWiseAllocation() {
    return `${this.homeHoldingSubHead}/product-wise-allocation`;
  },
  get reportingPortfolioAgeing() {
    return `${this.homeHoldingSubHead}/portfolio-ageing`;
  },
  get homeHoldingTransactions() {
    return `${this.homeHoldingSubHead}/transactions`;
  },
  get cashflows() {
    return `${this.homeHoldingSubHead}/cashflows`;
  },
  get homeHoldingFamily() {
    return `${this.homeHoldingSubHead}/family`;
  },
  get homeHoldingTopPerformer() {
    return `${this.homeHoldingSubHead}/top-performer`;
  },
  get homeHoldingMemberWisePerformance() {
    return `${this.homeHoldingSubHead}/member-wise-performance`;
  },
  get InvestedVSCurrent() {
    return `${this.homeHoldingSubHead}/invested-vs-current`;
  },
  get homeHoldingBottomPerformer() {
    return `${this.homeHoldingSubHead}/bottom-performer`;
  },
  get homeHoldingTop5Amc() {
    return `${this.homeHoldingSubHead}/top-5-amc`;
  },
  get homeHoldingTop5Issuers() {
    return `${this.homeHoldingSubHead}/top-5-issuers`;
  },
  //mf
  mfSummaryPage: "/home/mutual-fund",
  mfRiskDetailsPage: "/home/mutual-fund/transaction/risk",
  mfDetailed: "/home/mutual-fund/:pageName",
  //mf sub pages
  get mfDetailed_Detailed() {
    return `${this.mfSummaryPage}/detailed`;
  },
  get mfDetailed_SubCategory() {
    return `${this.mfSummaryPage}/segment`;
  }, //css condition in tabmfo main
  get mfDetailed_fund() {
    return `${this.mfSummaryPage}/fund`;
  },
  get mfDetailed_holding() {
    return `${this.mfSummaryPage}/holding`;
  },
  get mfDetailed_transaction() {
    return `${this.mfSummaryPage}/transaction`;
  },
  get mfDetailed_holdingPeriod() {
    return `${this.mfSummaryPage}/holding/holding-period`;
  },
  //pms
  pmsSummaryPage: "/home/pms",
  pmsDetailed: "/home/pms/report/:pageName",
  //pms sub pages
  get pmsSummaryPage_holding() {
    return `${this.pmsSummaryPage}/report/holding`;
  },
  get pmsSummaryPage_underlying_transaction() {
    return `${this.pmsSummaryPage}/report/underlying`;
  }, //tabPmsMain condition
  get pmsSummaryPage_transection() {
    return `${this.pmsSummaryPage}/report/transaction`;
  },
  get pmsSummaryPage_portfolio() {
    return `${this.pmsSummaryPage}/report/portfolio`;
  },
  get pmsSummaryPage_performanceSummary() {
    return `${this.pmsSummaryPage}/report/performance-summary`;
  },

  //aif
  aifSummaryPage: "/home/aif",
  aifDetailed: "/home/aif/:pageName",
  //aif sub pages3

  //realEstate
  realEstateSummaryPage: "/home/real-estate",
  realEstateDetailed: "/home/real-estate/:pageName",
  //realestate sub pages
  get realEstateSummaryPage_holding() {
    return `${this.realEstateSummaryPage}/holding`;
  },
  get realEstateSummaryPage_transection() {
    return `${this.realEstateSummaryPage}/transaction`;
  },
  //insurance
  insurance: "/home/insurance",
  insuranceSummaryPage: "/home/insurance/life",
  //insurance life subs
  insuranceLifeSubPages: "/home/insurance/life/:pageName",
  insuranceReportPage: "/home/insurance/life/policies",
  get insuranceLifeSubPageCover() {
    return `${this?.insuranceLifeSubPages}/cover`;
  },
  get insuranceLifeSubPageReturn() {
    return `${this?.insuranceLifeSubPages}/return`;
  },
  insuranceSubPage: "/home/insurance/:pageName",
  get insuranceSummaryPage_health() {
    return `${this.insurance}/health`;
  },
  get insuranceSummaryPage_general() {
    return `${this.insurance}/general`;
  },
  //bonds
  bondsSummaryPage: "/home/bonds",
  bondsDetailed: "/home/bonds/:pageName",
  bondsDetails: "/details",
  get bondsSummaryPage_holding() {
    return `${this.bondsSummaryPage}/holding`;
  },
  get bondsSummaryPage_transection() {
    return `${this.bondsSummaryPage}/transaction`;
  },
  get bondsSummaryPage_cashflow() {
    return `${this.bondsSummaryPage}/cash-flow`;
  },
  get bondsSummaryPage_credit() {
    return `${this.bondsSummaryPage}/credit`;
  },
  get bondsSummaryPage_maturity() {
    return `${this.bondsSummaryPage}/maturity`;
  },
  get bondsSummaryPage_comparsion() {
    return `${this.bondsSummaryPage}/comparsion`;
  },
  get bondsSummaryPage_types() {
    return `${this.bondsSummaryPage}/types`;
  },

  //equity
  equitySummaryPage: "/home/equity",
  equityDetailed: "/home/equity/:pageName",
  equityHoldingPeriod: "/home/equity/holding/holding-period",
  equityStockdetails: "/home/equity/stock-details/:isin",
  equityIpoDetails: "/home/equity/ipo/ipodetails/",
  //equity subs
  get equityViewChart() {
    return this?.equitySummaryPage + "/view-charts/:id";
  },
  //equity viewChart subs
  get equityViewChartPeRatio() {
    return this?.equitySummaryPage + "/view-charts/PE-Ratio";
  },
  get equityViewChartSaleMargin() {
    return this?.equitySummaryPage + "/view-charts/Sales-And-Margin";
  },
  get equityViewChartPriceChart() {
    return this?.equitySummaryPage + "/view-charts/price-chart";
  },
  get equityViewChartMore() {
    return this?.equitySummaryPage + "/view-charts/more";
  },
  get equityMarket() {
    return this.equitySummaryPage + "/market";
  },
  get equitySector() {
    return this.equitySummaryPage + "/sector";
  },
  get equityStock() {
    return this.equitySummaryPage + "/stock";
  },
  get equityEngagment() {
    return this.equitySummaryPage + "/engagement";
  },
  get equityGain() {
    return this.equitySummaryPage + "/gain";
  },
  get equityStockCompare() {
    return this.equitySummaryPage + "/stockcompare";
  },
  get equityHolding() {
    return this.equitySummaryPage + "/holding";
  },
  get equityTransaction() {
    return this.equitySummaryPage + "/transaction";
  },
  get equityCorporate() {
    return this.equitySummaryPage + "/corporate";
  },
  get equityIpo() {
    return this.equitySummaryPage + "/ipo";
  },
  //private
  privateMarketSummaryPage: "/home/unlisted-equity",
  privateMarketDetailed: "/home/unlisted-equity/:pageName",
  //private subs
  get privateMarketHolding() {
    return this.privateMarketSummaryPage + "/holding";
  },
  get privateMarketTransaction() {
    return this.privateMarketSummaryPage + "/transaction";
  },
  //structured
  structuredProdSummaryPage: "/home/structured-product",
  structuredProdDetailed: "/home/structured-product/:pageName",
  //structured-prod subs
  get structuredProdHolding() {
    return this.structuredProdSummaryPage + "/holding";
  },
  get structuredProdTransaction() {
    return this.structuredProdSummaryPage + "/transaction";
  },
  //mld
  mldSummaryPage: "/home/mld",
  mldDetailed: "/home/mld/:pageName",
  //mld subs
  get mldHolding() {
    return this.mldSummaryPage + "/holding";
  },
  get mldTransaction() {
    return this.mldSummaryPage + "/transaction";
  },
  get mldCashFlow() {
    return this.mldSummaryPage + "/cash-flow";
  },
  //reits
  reitsSummaryPage: "/home/reits",
  reitsDetailed: "/home/reits/:pageName",
  //retis sub
  get reitsHolding() {
    return this.reitsSummaryPage + "/holding";
  },
  get reitsTransaction() {
    return this.reitsSummaryPage + "/transaction";
  },
  get reitsCashFlow() {
    return this.reitsSummaryPage + "/cash-flow";
  },
  //invits
  invitsSummaryPage: "/home/invits",
  invitsDetailed: "/home/invits/:pageName",
  //invits subs
  get invitsHolding() {
    return this.invitsSummaryPage + "/holding";
  },
  get invitsTransaction() {
    return this.invitsSummaryPage + "/transaction";
  },
  get invitsCashFlow() {
    return this.invitsSummaryPage + "/cash-flow";
  },
  // icd
  icdSummaryPage: "/home/icd",
  icdDetailed: "/home/icd/:pageName",
  //icd subs
  get icdHolding() {
    return this.icdSummaryPage + "/holding";
  },
  get icdTransaction() {
    return this.icdSummaryPage + "/transaction";
  },
  // bank-book
  bankBookSummaryPage: "/home/bank",
  bankBookDetailed: "/home/bank/:pageName",
  //bank book subs
  get bankBookHolding() {
    return this.bankBookSummaryPage + "/holding";
  },
  get bankBookTransaction() {
    return this.bankBookSummaryPage + "/transaction";
  },
  get bankBookAllocation() {
    return this.bankBookSummaryPage + "/allocation";
  },
  // Fixed-Deposit
  fixedDepositSummaryPage: "/home/fixed-deposit",
  fixedDepositDetailed: "/home/fixed-deposit/:pageName",
  // Fixed-Deposit subs
  get fixedDepositHolding() {
    return this.fixedDepositSummaryPage + "/holding";
  },
  get fixedDepositTransaction() {
    return this.fixedDepositSummaryPage + "/transaction";
  },
  get fixedDepositAllocation() {
    return this.fixedDepositSummaryPage + "/allocation";
  },
  // Recurring-Deposit
  recurringDepositSummaryPage: "/home/recurring-deposit",
  recurringDepositDetailed: "/home/recurring-deposit/:pageName",
  // Recurring-Deposit subs
  get recurringDepositHolding() {
    return this.recurringDepositSummaryPage + "/holding";
  },
  get recurringDepositTransaction() {
    return this.recurringDepositSummaryPage + "/transaction";
  },
  get recurringDepositAllocation() {
    return this.recurringDepositSummaryPage + "/allocation";
  },
  //strategy builder
  strategyBuilder: "/home/strategy-builder/:pageName",
  strategyBuilderSubHead: "/home/strategy-builder",
  //strategy builder-subs
  get strategyBuilderTargetPortfolio() {
    return this.strategyBuilderSubHead + "/target-portfolio";
  },
  get strategyBuilderUniqueCLientIndex() {
    return this.strategyBuilderSubHead + "/unique-cLient-index";
  },
  get strategyBuilderBenchmark() {
    return this.strategyBuilderSubHead + "/benchmark";
  },
};

routes.aifSummaryPage_holding = `${routes.aifSummaryPage}/holding`;
//aif tab dashboard condition
routes.aifSummaryPage_transection = `${routes.aifSummaryPage}/transaction`;
routes.aifDrawdown_summary = `${routes.aifSummaryPage}/drawdown`;
