import { Card, CardContent, Grid, Typography, Button } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import useStyles from "../common/ProductsStyles";

import PieChart from "../../../charts/PieChart";
import GlowCircle from "../../../charts/GlowCircle";

import {
  divergingCommonProps,
  pie_onClick2,
} from "../../../charts/nivo_objects";
import TabHeader from "../common/TabHeader";
import PageLoader from "../common/pageLoader";
import {
  fetchXirr,
  fetchXirrCurrentHolding,
  fetchTwrrCurrentHolding,
  fetchTwrr,
  invested_portfolio,
  getAssetWiseData,
} from "../../../../api";
import { OnboardingContext } from "../../../../Context";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import moment from "moment";
import { currencyFormat } from "../../../../hooks/constantFunction";

import {
  dateFormatCommon,
  roundNumber,
  roundNumberWithoutDecimal,
} from "../../../utils/common";
import { loadingText } from "../../../utils/constands";
import SummaryPageTop from "../common/SummaryPageTop";
import TileComp from "../common/TileComp";
import Default from "../../defaultPages/Default";
import PortfolioChart from "../../common/portfolioChart";

import { useHoldingCardActive } from "../../../../hooks/useHoldingCardActive";
import { NewHoldingSummaryCards } from "../../../common/HoldingSummaryCards/NewHoldingSummaryCards";
import {
  holdingSummaryCardsActiveData,
  holdingSummaryCardsInactiveData,
} from "../common/util/common";
import { NewHoldingSummaryCardsActive } from "../../../common/HoldingSummaryCards/NewHoldingSummaryCardsActive";
import SummaryDetailsSectionWrapper from "../newDesigns/common/SummaryDetailsSectionWrapper";
import { summaryDetailsSecConstData } from "../utils/constands/summaryDetailsSec";
import { colorAndProd } from "../utils/constands/prodAndColor";
import { useReturns } from "../../../../hooks/useReturns";
import { useNormalReturns } from "../../../../hooks/useNormalReturns";
import { routes } from "../../../../routes";
import { useTotalGainLoss } from "../../../../hooks/useTotalGainLoss";
import { NewFilter } from "../../newFilter/NewFilter";
import { GainsCardInfo } from "../../../common/HoldingSummaryCards/GainsCardInfo";
import { ReactComponent as InfoIcon } from "../../../../assets/icons/TotalGainLossInfoIcon.svg";
import { useCardsApi } from "../../../../hooks/useCardsApi";
import { useCurrentHoldingCardsApi } from "../../../../hooks/useCurrentHoldingCardsApi";
import { TotalGainLossCardInfo } from "../../../common/HoldingSummaryCards/TotalGainLossCardInfo";
function StructuredProductSummaryComp() {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  // const [holdingCardData] = useHoldingCardActive("structured", checked);
  // const [totalGainLossData] = useTotalGainLoss("structured");
  // const { returns: twrr } = useNormalReturns(
  //   fetchTwrr,
  //   "normal-twrr",
  //   checked,
  //   "structured"
  // );
  // const { returns: xirr } = useNormalReturns(
  //   fetchXirr,
  //   "normal-xirr",
  //   checked,
  //   "structured"
  // );
  const {
    holdingCardData,
    totalGainLossData,
    xirr,
    twrr,
    loading: cardLoader,
    twrrLoader,
    twrrError,
    retryFetchTwrr,
  } = useCardsApi("structured");
  // const { returns: xirrCurrentHolding } = useReturns(
  //   fetchXirrCurrentHolding,
  //   "xirr",
  //   checked,
  //   "structured"
  // );
  // const { returns: twrrCurrentHolding, loading: twrrCurrentHoldingLoading } =
  //   useReturns(fetchTwrrCurrentHolding, "twrr", checked, "structured");
  const {
    // holdingCardData,
    xirrCurrentHolding,
    twrrCurrentHolding,
    loading: currentHoldingCardLoader,
    twrrLoaderCurrentHolding,
    twrrErrorCurrentHolding,
    retryFetchTwrrCurrentHolding,
  } = useCurrentHoldingCardsApi(checked, "structured");

  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  let selectedAssetClass = getfilter_as_params().assetClass;

  const { userState } = OnboardingContext();
  // const [twrr, setTwrr] = useState({});
  // const [twrrCurrentHolding, setTwrrCurrentHolding] = useState({});
  // const [xirr, setXirr] = useState({});
  // const [xirrCurrentHolding, setXirrCurrentHolding] = useState({});
  const [investmentPortfolio, setInvestmentPortfolio] = useState([]);
  const [loadque, setloadque] = useState(null);
  const [loading, setLoading] = useState(true);
  const [assetData, setAssetData] = useState([]);
  const [assets, setAssets] = useState({});
  const [assetTotalData, setAssetTotalData] = useState({});
  const [flip, setFlip] = useState(false);
  const [newCardData, setNewCardDaat] = useState([]);

  const [asset_color, setAsset_Color] = useState(Object.values(colorAndProd));
  const [pie, setPie] = useState({
    title: "",
    value: "",
    colors: asset_color,
    disabled: [],
    fill: [
      {
        match: {
          id: "debt",
        },
        id: "c6",
      },
      {
        match: {
          id: "equity",
        },
        id: "c3",
      },
      {
        match: {
          id: "alternate",
        },
        id: "c2",
      },
      {
        match: {
          id: "cash",
        },
        id: "c4",
      },
    ],
  });

  const color = colorAndProd;

  // let currencyFormat = (value) => {
  //   return currencyFormatLocal(value, {
  //     currencyStyle: { style: "currency", currency: "INR" },
  //   });
  // };

  const diverging = {
    ...divergingCommonProps,
  };
  diverging.axisBottom.legendOffset = 65;
  diverging.margin = { top: 5, right: 20, bottom: 70, left: 80 };
  useEffect(() => {
    const clientId = userState.loginedUserData.id;
    // const clientId = 199;

    let params = { ...getfilter_as_params() };

    setLoading(true);
    Promise.allSettled([
      // fetchXirr(clientId, params, "structured"),
      // fetchXirrCurrentHolding(clientId, params, "structured"),
      // fetchTwrr(clientId, params, "structured"),
      // fetchTwrrCurrentHolding(clientId, params, "structured"),
      invested_portfolio(clientId, params, "structured"),
      getAssetWiseData(clientId, params, "structured", checked),
    ]).then(
      ([
        // xirr_res,
        // xirrCurrentHolding_res,
        // twrr_res,
        // twrrCurrentHolding_res,
        invest_res,
        asset_ress,
      ]) => {
        // async function allres(){
        //   await xirr_res
        //   await twrr_res
        //   await realiseddata_res
        //   await asset_ress
        //   setLoading(false)
        // }

        // if (xirr_res.status == "fulfilled") {
        //   setXirr(xirr_res.value.result);
        // }
        // if (xirrCurrentHolding_res.status == "fulfilled") {
        //   setXirrCurrentHolding(xirrCurrentHolding_res.value.result);
        // }
        // if (twrr_res.status == "fulfilled") {
        //   if (twrr_res.value?.result?.annualisedTwrr) {
        //     twrr_res.value.result.annualisedTwrr =
        //       twrr_res.value?.result?.annualisedTwrr * 100;
        //   }
        //   setTwrr(twrr_res.value.result);
        // }
        // if (twrrCurrentHolding_res.status == "fulfilled") {
        //   if (twrrCurrentHolding_res.value?.result?.annualisedTwrr) {
        //     twrrCurrentHolding_res.value.result.annualisedTwrr =
        //       twrrCurrentHolding_res.value?.result?.annualisedTwrr * 100;
        //   }
        //   setTwrrCurrentHolding(twrrCurrentHolding_res.value.result);
        // }
        if (invest_res.status == "fulfilled") {
          let arr = [];
          setInvestmentPortfolio(invest_res.value.result);
        }

        if (asset_ress.status == "fulfilled") {
          let arr = [];
          let asset = {};
          let colors = [];
          asset_ress.value.data.forEach((a) => {
            if (selectedAssetClass.includes(a?.asset_class)) {
              arr.push({
                id: `${a.asset_class}`,
                label: `${
                  a.asset_class
                    ? a.asset_class.charAt(0).toUpperCase() +
                      a?.asset_class?.slice(1)
                    : " "
                }  ${a.percentage}%`,
                value: Number(a.percentage).toFixed(2),
                amount: a.amount,
                color: color[a.asset_class],
              });
              colors.push(color[a.asset_class]);
            }

            asset = {
              ...asset,
              [a.asset_class]: {
                percent: Number(a.percentage).toFixed(2),
                amount: a.amount,
                date: a.as_on_date,
              },
            };
          });

          // let c = {
          //   success: true,
          //   event: "STRUCTURED_PERFORMANCE_ASSET_WISE",
          //   message: "Asset-wise performance fetch successfully.",
          //   uuid: "f7c03440-db62-11ed-844c-f34a4aa7ccb1",
          //   data: {
          //     meta: {
          //       filters: {
          //         client_id: 199,
          //         asset_type: {},
          //         valuation_date: {},
          //       },
          //     },
          //     result: [
          //       {
          //         asset_class: "equity",
          //         amount: 11900917.0051,
          //         percentage: 30,
          //         as_on_date: "15/04/2023",
          //       },
          //       {
          //         asset_class: "debt",
          //         amount: 11900917.0051,
          //         percentage: 70,
          //         as_on_date: "15/04/2023",
          //       },
          //     ],
          //   },
          // };

          // c.data.result?.map((a) => {
          //   if (selectedAssetClass.includes(a?.asset_class)) {
          //     arr.push({
          //       id: `${a.asset_class}`,
          //       label: `${
          //         a.asset_class
          //           ? a.asset_class.charAt(0).toUpperCase() +
          //             a?.asset_class?.slice(1)
          //           : " "
          //       }  ${a.percentage}%`,
          //       value: Number(a.percentage).toFixed(2),
          //       amount: a.amount,
          //       color: color[a.asset_class],
          //     });
          //     colors.push(color[a.asset_class]);
          //   }

          //   asset = {
          //     ...asset,
          //     [a.asset_class]: {
          //       percent: Number(a.percentage).toFixed(2),
          //       amount: a.amount,
          //       date: a.as_on_date,
          //     },
          //   };
          // });

          setAsset_Color(colors);
          setPie({
            ...pie,
            colors: colors,
          });

          setAssets(asset);
          setAssetData(arr);
          setAssetTotalData({
            percent: Number(asset_ress.value?.totalPercentage).toFixed(2),
            amount: asset_ress.value?.totalAmount,
            date: asset_ress.value?.lastUpdatedDate,
          });
        }
        setLoading(false);
      }
    );
  }, [mfoFilter, checked]);

  const navigate = useNavigate();
  const handleSummaryClick = () => {
    navigate(routes?.structuredProdHolding);
  };

  const data = [
    {
      key: "equity",
      title: "Equity",
      amount: assets["equity"]
        ? currencyFormat(assets["equity"]?.amount)
        : currencyFormat(0),
      percent: assets["equity"]
        ? roundNumber(assets["equity"]?.percent) + "%"
        : "0.00%",
      dateStr: assets["equity"]?.date ? "Updated as on" : "",
      date: dateFormatCommon(assets["equity"]?.date),
      color: "bluebox",
      width: "240px",
    },
    {
      key: "debt",
      title: "Debt",
      amount: assets["debt"]
        ? currencyFormat(assets["debt"]?.amount)
        : currencyFormat(0),
      percent: assets["debt"]
        ? roundNumber(assets["debt"]?.percent) + "%"
        : "0.00%",
      dateStr: assets["debt"]?.date ? "Updated as on" : "",
      date: dateFormatCommon(assets["debt"]?.date),
      // color: "greenbox",
      color: "orangebox",
      width: "240px",
    },
    {
      key: "alternate",
      title: "Alternates",
      amount: assets["alternate"]
        ? currencyFormat(assets["alternate"]?.amount)
        : currencyFormat(0),
      percent: assets["alternate"]
        ? roundNumber(assets["alternate"]?.percent) + "%"
        : "0.00%",
      dateStr: assets["alternate"]?.date ? "Updated as on" : "",
      date: dateFormatCommon(assets["alternate"]?.date),
      // color: "orangebox",
      color: "greenbox",
      width: "240px",
    },
    {
      key: "cash",
      title: "Cash",
      amount: assets["cash"]
        ? currencyFormat(assets["cash"]?.amount)
        : currencyFormat(0),
      percent: assets["cash"]
        ? roundNumber(assets["cash"]?.percent) + "%"
        : "0.00%",
      dateStr: assets["cash"]?.date ? "Updated as on" : "",
      date: dateFormatCommon(assets["cash"]?.date),
      color: "purplebox",
      width: "240px",
    },
    {
      key: "total",
      title: "Total",
      amount: assetTotalData?.amount
        ? currencyFormat(assetTotalData?.amount)
        : currencyFormat(0),
      percent: assetTotalData?.percent
        ? roundNumber(assetTotalData?.percent) + "%"
        : "0.00%",
      dateStr: assetTotalData?.date ? "Updated as on" : "",
      date: dateFormatCommon(assetTotalData?.date),
      color: "purplebox",
      width: "240px",
    },
  ];
  /* Filter Cards Based on Selected Asset Class from filter */
  // useEffect(() => {
  // }, [pie]);
  let cardData = data.filter((data) => selectedAssetClass.includes(data.key));
  let cardData1 = data.map((data) => {
    if (!selectedAssetClass.includes(data.key)) {
      data.dontShow = true;
    }
    if (data.key === "total") {
      data.dontShow = false;
    }
    return data;
  });
  const [infoIconHovered, setInfoIconHovered] = useState(false);
  return (
    <>
      <SummaryPageTop
        backLink={routes?.homeHoldingPage}
        pageTitle={"Structured Products Portfolio Summary"}
        flip={flip}
        setFlip={setFlip}
        checked={checked}
        setChecked={setChecked}
        setFilterModalOpen={setFilterModalOpen}
      />
      <NewFilter
        filterModalOpen={filterModalOpen}
        setFilterModalOpen={setFilterModalOpen}
        productType="structured"
      />
      {/* <PageFilter />
      <div className={`${classes.alignTopHeader}`}>
        <Back
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/home/holding-summary/details");
          }}
        />
        <Typography
          style={{ marginLeft: "-4%" }}
          className={`${classes.mainHeading} ${classes.mainHeadingAlign}`}
        >
          PMS Portfolio Summary
          <br></br>
          {sessionStorage.getItem("currency_format") != "actuals" &&
            sessionStorage.getItem("currency_format") && (
              <span
                className="subtext capitalize"
                style={{ fontSize: "16px", position: "absolute" }}
              >
                {" "}
                (values in {sessionStorage.getItem("currency_format")})
              </span>
            )}
        </Typography>
        <div className={`${classes.iconAlign}`}>
          {!flip && (
            <Bulb
              onClick={() => setFlip(!flip)}
              className={classes.iconStyle}
            />
          )}
          {flip && (
            <LitBulb
              onClick={() => setFlip(!flip)}
              className={classes.iconStyle}
            />
          )}
          <Import className={classes.iconStyle} />
          <Export className={classes.iconStyle} />
          <Print className={classes.iconStyle} />
          <Filter className={classes.iconStyle} />
        </div>
      </div> */}

      {/* <CardSummary
        xirr={xirr}
        twrr={twrr}
        investment={investmentPortfolio.investedAmount}
        portfolio={investmentPortfolio}
      ></CardSummary> */}

      {/* <FlippyArray
        page={"pms"}
        investmentData={
          investmentPortfolio.investedAmount != null
            ? currencyFormat(investmentPortfolio.investedAmount)
            : loadingText
        }
        portfolioData={
          investmentPortfolio.portfolioValueAmount != null
            ? currencyFormat(investmentPortfolio.portfolioValueAmount)
            : loadingText
        }
        portfolioDate={{
          asOnDate:
            investmentPortfolio?.asOnDate != null
              ? investmentPortfolio?.asOnDate
              : loadingText,
        }}
        realisedGainData={
          investmentPortfolio.realisedGainLoss != null
            ? currencyFormat(investmentPortfolio.realisedGainLoss)
            : loadingText
        }
        unrealisedGainData={
          investmentPortfolio.unRealisedGainLoss != null
            ? currencyFormat(investmentPortfolio.unRealisedGainLoss)
            : loadingText
        }
        xirrData={
          xirr.xirr != null
            ? roundNumber(Number(xirr.xirr)) + " %"
            : loadingText
        }
        absoluteTwrrData={
          twrr.absoluteTwrr != null
            ? roundNumber(Number(twrr.absoluteTwrr)) + " %"
            : loadingText
        }
        annualisedTwrrData={
          twrr?.annualisedTwrr != null
            ? roundNumber(Number(twrr.annualisedTwrr)) + "%"
            : loadingText
        }
        flip={flip}
      /> */}
      <div style={{ margin: "2%" }}>
        {!checked ? (
          <NewHoldingSummaryCards
            retryFetchTwrr={retryFetchTwrr}
            twrrLoader={twrrLoader}
            twrrError={twrrError}
            // infoIconHovered={infoIconHovered}
            infoIcon={
              infoIconHovered && (
                <TotalGainLossCardInfo
                  textToRender={
                    <p className="totalIncomeDescription">
                      In case of Structured Products you will find dividends
                      from equity ownership and enjoy regular interest payments
                      from debt instruments.
                    </p>
                  }
                />
              )
            }
            holdingSummaryCardsInactive={holdingSummaryCardsInactiveData(
              investmentPortfolio,
              holdingCardData,
              xirr,
              twrr,
              totalGainLossData
            )}
            totalGainLossInactive={
              <>
                <GainsCardInfo
                  text="Realized Gain/Loss"
                  value={
                    holdingSummaryCardsInactiveData(
                      investmentPortfolio,
                      holdingCardData,
                      xirr,
                      twrr,
                      totalGainLossData
                    )?.realisedGainLoss
                  }
                  align="left"
                />
                <GainsCardInfo
                  text="Unrealized Gain/Loss"
                  value={
                    holdingSummaryCardsInactiveData(
                      investmentPortfolio,
                      holdingCardData,
                      xirr,
                      twrr,
                      totalGainLossData
                    )?.unRealisedGainLoss
                  }
                  align="center"
                />
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <GainsCardInfo
                    text="Other Income"
                    value={
                      holdingSummaryCardsInactiveData(
                        investmentPortfolio,
                        holdingCardData,
                        xirr,
                        twrr,
                        totalGainLossData
                      )?.interestIncome
                    }
                    align="right"
                    customStyle={{ marginRight: "2rem" }}
                  />
                  <InfoIcon
                    onMouseOver={() => setInfoIconHovered(true)}
                    onMouseOut={() => setInfoIconHovered(false)}
                    style={{
                      position: "absolute",
                      top: "4px",
                      right: "4px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </>
            }
          ></NewHoldingSummaryCards>
        ) : (
          <NewHoldingSummaryCardsActive
            retryFetchTwrrCurrentHolding={retryFetchTwrrCurrentHolding}
            twrrLoaderCurrentHolding={twrrLoaderCurrentHolding}
            twrrErrorCurrentHolding={twrrErrorCurrentHolding}
            infoIconHovered={infoIconHovered}
            holdingSummaryCardsActive={holdingSummaryCardsActiveData(
              holdingCardData,
              xirrCurrentHolding,
              twrrCurrentHolding
            )}
            totalGainLossActive={
              <>
                <GainsCardInfo
                  text="Unrealized Gain/Loss"
                  value={
                    holdingSummaryCardsActiveData(
                      holdingCardData,
                      xirrCurrentHolding,
                      twrrCurrentHolding
                    )?.unRealisedGainLoss
                  }
                  align="left"
                />
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <GainsCardInfo
                    text="Other Income"
                    value={
                      holdingSummaryCardsActiveData(
                        holdingCardData,
                        xirrCurrentHolding,
                        twrrCurrentHolding
                      )?.interestIncome
                    }
                    align="right"
                    customStyle={{ marginRight: "2rem" }}
                  />
                  <InfoIcon
                    onMouseEnter={() => setInfoIconHovered((prev) => !prev)}
                    onMouseLeave={() => setInfoIconHovered((prev) => !prev)}
                    style={{
                      position: "absolute",
                      top: "4px",
                      right: "4px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </>
            }
          ></NewHoldingSummaryCardsActive>
        )}
      </div>
      <SummaryDetailsSectionWrapper
        summaryDetailsSecConstData={summaryDetailsSecConstData}
        cardData1={cardData1}
        hideNavButtons={true}
        handleClick={handleSummaryClick}
        peiChart={
          <div className="flex_center tr20" style={{ height: "100%" }}>
            <div className={classes.wid380px}>
              <PieChart
                colors={pie.colors}
                // fill={pie.fill}
                config={{
                  innerRadius: "0.80",
                  cornerRadius: 0,
                  padAngle: 0,
                  legends: false,
                  minWith: "380px",
                  disableArcLink: true,
                  chart: {
                    width: 440,
                    height: 420,
                    arcLinkLabelsStraightLength: 20,
                    arcLinkLabelsDiagonalLength: 20,
                  },

                  arcLinkLabel: (d) => (
                    <tspan
                      x="0"
                      y="0"
                      style={{
                        fontFamily: "Avenir",
                        fontWeight: "400",
                        fontSize: "12",
                        textTransform: "capitalize",
                      }}
                    >
                      {" "}
                      {d.id}
                      <tspan
                        x="0"
                        y="18"
                        style={{
                          fontFamily: "Open Sans",
                          fontWeight: "700",
                          fontSize: "14",
                        }}
                      >
                        {" "}
                        {d.value}%
                      </tspan>
                    </tspan>
                  ),
                }}
                onClickEvent={{
                  click: pie_onClick2,
                  set: setPie,
                  cstate: pie,
                  ncolors: asset_color,
                }}
                data={assetData}
              >
                <GlowCircle>
                  <div className="circle_content">
                    <div>
                      <b className="circle_text">
                        {pie.title && pie.value != null
                          ? pie.title
                          : "Portfolio value"}
                      </b>
                      <br></br>
                      <b className="circle_text2">
                        {pie.amount != null
                          ? currencyFormat(pie.amount)
                          : assetTotalData.amount != null
                          ? currencyFormat(assetTotalData.amount)
                          : ""}
                      </b>
                      <div
                        className="circle_text2"
                        style={{ paddingTop: "5px" }}
                      >
                        {pie?.value ? roundNumber(pie?.value) + "%" : ""}
                      </div>
                    </div>
                  </div>
                </GlowCircle>
              </PieChart>
            </div>
          </div>
        }
      />
      {/* <Typography
        style={{ margin: "3%" }}
        className={`${classes.titleText} ${classes.typoInsideAlign}`}
      >
        Asset Allocation
      </Typography>
      <div className={classes.mainContent}>
        <Grid container className={classes.holdingContentAlign}>
          <Grid style={{ marginTop: "-5%" }} xs={6}>
            <div className="flex_center tr20">
              <div style={{ width: "380px" }}>
                <PieChart
                  colors={pie.colors}
                  fill={pie.fill}
                  config={{
                    innerRadius: "0.90",
                    padAngle: 0,
                    legends: false,
                    minWith: "380px",
                    disableArcLink: true,
                    chart: {
                      width: 440,
                      height: 420,
                      arcLinkLabelsStraightLength: 20,
                      arcLinkLabelsDiagonalLength: 20,
                    },

                    arcLinkLabel: (d) => (
                      <tspan
                        x="0"
                        y="0"
                        style={{
                          fontFamily: "Avenir",
                          fontWeight: "400",
                          fontSize: "12",
                          textTransform: "capitalize",
                        }}
                      >
                        {" "}
                        {d.id}
                        <tspan
                          x="0"
                          y="18"
                          style={{
                            fontFamily: "Open Sans",
                            fontWeight: "700",
                            fontSize: "14",
                          }}
                        >
                          {" "}
                          {d.value}%
                        </tspan>
                      </tspan>
                    ),
                  }}
                  onClickEvent={{
                    click: pie_onClick2,
                    set: setPie,
                    cstate: pie,
                    ncolors: asset_color,
                  }}
                  data={assetData}
                >
                  <GlowCircle>
                    <div className="circle_content">
                      <div>
                        <b className="circle_text">
                          {pie.title && pie.value != null
                            ? pie.title
                            : "Portfolio value"}
                        </b>
                        <br></br>
                        <b className="circle_text2">
                          {pie.amount != null
                            ? currencyFormat(pie.amount)
                            : investmentPortfolio.portfolioValueAmount != null
                            ? currencyFormat(
                                investmentPortfolio.portfolioValueAmount
                              )
                            : ""}
                        </b>
                        <div
                          className="circle_text2"
                          style={{ paddingTop: "5px" }}
                        >
                          {pie?.value ? roundNumber(pie?.value) + "%" : ""}
                        </div>
                      </div>
                    </div>
                  </GlowCircle>
                </PieChart>
              </div>
            </div>
            <Typography
              className={classes.graphHeader}
              style={{ paddingBottom: "5%", justifyContent: "center" }}
            >
              Current Allocation
            </Typography>
            <Grid style={{ display: "flex", justifyContent: "center" }}>
              <Button
                style={{
                  width: "30%",
                  margin: "0 2.5%",
                  background: " #380D77",
                  borderRadius: "50px",
                }}
                className={`${classes.holdingButton}`}
                // variant="contained"
                onClick={handleSummaryClick}
              >
                <Typography
                  className={classes.holdingButtonText}
                  style={{ padding: "2%" }}
                >
                  View Details
                </Typography>
              </Button>
            </Grid>
          </Grid>
          <TileComp data={cardData} />
        </Grid>
      </div> */}

      <PortfolioChart type={"structured"} loading={loading}></PortfolioChart>
      <PageLoader showLoader={loading ? true : false} />
      <PageLoader
        showLoader={currentHoldingCardLoader && checked ? true : false}
      />
    </>
  );
}

export default StructuredProductSummaryComp;
