import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { dateFormatCommon, roundNumber } from "../../../../utils/common";
import useStyles from "../../common/ProductsStyles";
import HashlinkSupport from "./HashlinkSupport";

function FundPortfolioCard({
  riskDetails,
  assetProgressBarColors,
  disabledassetProgressBarColors,
  setAsset_color,
  handleMouseMove,
  asset_color,
}) {
  const classes = useStyles();

  return (
    <>
      <HashlinkSupport id={"portfolioAllocation"} />

      <Typography
        style={{ marginLeft: "3%" }}
        className={`${classes.riskHeaderSub} ${classes.typoInsideAlign}`}
        id="portfolioAllocation"
      >
        Portfolio Allocation
      </Typography>
      <div
        style={{ padding: "35px 25px 60px 25px" }}
        className={classes.mainContent}
      >
        <Typography
          style={{ margin: "1%" }}
          className={`${classes.riskHeaderSub}`}
        >
          Asset Allocation
        </Typography>
        <div className={classes.assetAllocationBar}>
          <div className={classes.progressBar}>
            {/* {console.log(riskDetails?.assetAllocation)} */}
            {riskDetails?.assetAllocation &&
              Object.keys(riskDetails?.assetAllocation).map((val, index) => {
                if (
                  Number(riskDetails?.assetAllocation[val]) > 0 &&
                  assetProgressBarColors[val]
                )
                  return (
                    <div
                      onMouseEnter={() => {
                        let acolors = {
                          ...disabledassetProgressBarColors,
                        };
                        acolors[val] = assetProgressBarColors[val];
                        setAsset_color(acolors);
                      }}
                      onMouseLeave={() => {
                        setAsset_color({ ...assetProgressBarColors });
                      }}
                      onMouseMove={(ev) => handleMouseMove(ev)}
                      className={
                        classes[`progressBarItem${index + 1}`] +
                        " outer-tooltip"
                      }
                      style={{
                        width: `${riskDetails?.assetAllocation[val]}%`,
                        background: asset_color[val]?.color,
                      }}
                    >
                      <div
                        className="inner-tooltip graph-tooltip-box"
                        style={{ width: "max-content" }}
                      >
                        <span className="graph-tooltip-text capitalize">
                          {assetProgressBarColors[val]?.name + " "}
                        </span>
                        <span className="graph-tooltip-text">
                          {roundNumber(riskDetails?.assetAllocation[val]) + "%"}
                        </span>
                      </div>
                    </div>
                  );
              })}
          </div>
          <ul className={classes.list}>
            {riskDetails?.assetAllocation &&
              Object.keys(riskDetails?.assetAllocation).map((val, index) => {
                if (
                  Number(riskDetails?.assetAllocation[val]) > 0 &&
                  assetProgressBarColors[val]
                )
                  return (
                    <li style={{ color: asset_color[val]?.liColor }}>
                      <span className={classes.listItems}>
                        <span>{assetProgressBarColors[val]?.name}</span>
                        <span>
                          {roundNumber(riskDetails?.assetAllocation[val])}%
                        </span>
                      </span>
                    </li>
                  );
              })}
          </ul>
        </div>
      </div>
    </>
  );
}

export default FundPortfolioCard;
