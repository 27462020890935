import React, { useEffect, useState } from "react";
import AddMemberForm1 from "../client_flow/AddMemberForm1";
import SetPinForm from "../../NewSignUp/SetPinForm";
import NewDialaugeComp from "../../newClientList/NewDialogComp";
import AddClientUserForm1 from "./AddClientUserForm1";
import useUserAddClientUserFlowApi from "./useUserAddClientUserFlowApi";

function AddClientUserFlow({
  openModal,
  setOpenModal,
  selectedData,
  handleClose,
  data,
}) {
  const [next, setNext] = useState(1); //state?.step ? state?.step :
  const [formData, setFormData] = useState(selectedData);
  const [apiErrorMessage, setApiErrorMessage] = useState("");

  useEffect(() => {
    if (selectedData) setFormData(selectedData);
  }, [selectedData]);

  const handleBack = (step) => {
    if (step >= 1) setNext(step);
    handleNext(-1);
  };

  const handleNext = (stepAddition, formData1, client) => {
    setNext(next + stepAddition);
    let state = {
      formData: formData1 ? formData1 : formData,
      step: next + stepAddition,
    };
    // navigate("/register1", {
    //   state: { ...state },
    // });
    // console.log(stepAddition, "sgjsbgkjsg");
    setApiErrorMessage("");
  };

  const { createClientUser } = useUserAddClientUserFlowApi();

  const handleStep1 = (payload) => {
    payload["name"] = payload["fullName"];
    delete payload["fullName"];
    payload["roleId"] = payload["userRole"];
    delete payload["userRole"];
    // console.log(data, "alsfnjaf");

    createClientUser({
      clientId: data?.client_id || data?.id,
      payload: payload,
    })
      .then((res) => {
        console.log(res?.data?.data?.result, "aglagjk");
        setFormData(res?.data?.data?.result);
        if (res?.data?.data?.result) handleNext(1);
      })
      .catch((error) => {
        console.log(error, "aglagjk");
      });
  };

  const chagneInStep = (step) => {
    switch (step) {
      case 1:
        return (
          <AddClientUserForm1
            formMaxHeight="65vh"
            noStepBottom={true}
            currentStep={next}
            noStep={true}
            formData={formData}
            customHandleSubmit={handleStep1}
            formWidth={"523px"}
            apiErrorMessage={apiErrorMessage}
          />
        );
      case 2:
        return (
          <SetPinForm
            formWidth={"523px"}
            handleBack={handleBack}
            handlenext={handleNext}
            userId={formData?.user?.id}
            userType={"client"}
          />
        );

      default:
        return setOpenModal(false);
    }
  };

  return (
    <NewDialaugeComp
      width={"640px"}
      fullWidth={false}
      open={openModal}
      handleClose={() => {
        setOpenModal(false);
        setFormData({});
        handleClose();
      }}
    >
      {chagneInStep(next)}
    </NewDialaugeComp>
  );
}

export default AddClientUserFlow;
