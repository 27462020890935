import React, { useState } from "react";
import { updatepartneruser } from "../api";

function useUpdateUsers({ setLoading }) {
  const [load, setLoad] = useState(false);

  const updateUsers = async ({ type, id, data }) => {
    try {
      if (setLoading) setLoading(true);
      setLoad(true);
      let res = await updatepartneruser(type, id, data);
      if (setLoading) setLoading(false);
      setLoad(false);
      return res;
    } catch (error) {
      console.log(error);
      if (setLoading) setLoading(false);
      setLoad(false);
    }
  };

  return { updateUsers, load };
}

export default useUpdateUsers;
