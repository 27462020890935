import { useTheme } from "@material-ui/core";
import { currencyFormat } from "../../../hooks/constantFunction";
import { roundNumber } from "../../utils/common";
export const ClientMemberHeaderDetails = ({ headOfFamily }) => {
  const theme = useTheme();
  console.log("theme palette", theme.palette.memberListing.headerBackGround);
  return (
    <div
      style={{
        boxSizing: "border-box",
        // background: "#211839",
        background: theme.palette.memberListing.headerBackGround,
        maxWidth: "90%",
        margin: "0 auto",
        marginTop: "1.7rem",
        padding: "0.5rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          marginLeft: "1rem",
        }}
      >
        <h4 className="headerTxt">Total Members</h4>
        <p className="totalConsolidatedValue">
          {headOfFamily?.overAllTotalMembers}
        </p>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="totalConsolidatedWrapper">
          <h4 className="headerTxt">Total consolidated Portfolio Value</h4>
          <p className="totalConsolidatedValue">
            {currencyFormat(headOfFamily?.overAllPortfolioAmount)}
          </p>
        </div>

        <span className="memberListingHeaderDivider"></span>
        <div className="changeInValueWrapper">
          <h4 className="headerTxt">Change in Value</h4>
          <p className="memberListingHeaderChangeInValue">
            {currencyFormat(headOfFamily?.overAllChangeValueAmount)}
          </p>
        </div>

        <span className="memberListingHeaderDivider"></span>
        <div className="changeInPercentWrapper">
          <h4 className="headerTxt">Change in Percentage</h4>
          <p className="memberListingHeaderChangeInValue">
            {`( ${roundNumber(headOfFamily?.overAllChangePercentage)} %)`}
          </p>
        </div>
      </div>
    </div>
  );
};
