import React, { useState } from "react";
import EquitySubTab from "./EquitySubTab";
import {
  equityTRTableConfig,
  equityTRCorporateActionsTableConfig,
} from "./utils/constands";
import CustomTable1 from "../../../utils/CustomTable1";
import { objectFormating1 } from "../../../utils/objectFormating";
import { IosSwitch } from "../../../IosSwitch";
import NewtableTopSec from "../../common/NewtableTopSec";

function EquityTransaction() {
  const [openModal, setOpenModal] = useState(false);
  const [scripName, setScripName] = useState(null);
  const [checked, setChecked] = useState(false);
  const [offset, setOffset] = useState(0);

  const handleIconClick = () => {
    setOpenModal(!openModal);
  };

  return (
    <div>
      <NewtableTopSec
        handleIconClick={handleIconClick}
        setSearch={(e) => {
          setOffset(0);
          setScripName(e);
        }}
        product={"Equity"}
        iosSwitch={
          <IosSwitch
            switchLabelName="Show Corporate Action"
            checked={checked}
            setChecked={setChecked}
          />
        }
      />
      <CustomTable1
        product="equity"
        tableName="transaction-summary"
        objectFormating={objectFormating1}
        headerConfig={
          checked ? equityTRCorporateActionsTableConfig : equityTRTableConfig
        }
        tableBodyBackground="#1D1B36"
        openModal={openModal}
        setOpenModal={setOpenModal}
        scripName={scripName}
        isToggleChecked={checked}
        useEffectDepArray={[checked]}
        offset={offset}
        setOffset={setOffset}
      />
    </div>
  );
}

export default EquityTransaction;
