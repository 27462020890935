import React, { useContext, useEffect } from "react";
import { Box, Grid, makeStyles } from "@material-ui/core";
import DataNotFound from "../common/DataNotFound";
import { currencyFormat } from "../../../../hooks/constantFunction";
import PieChart from "../../../charts/PieChart";
import { colors_allocation } from "../../../charts/colors";
import { ReactComponent as BgCicularPieChart } from "../../../../assets/holding-summary/bgCicularPieChart.svg";
import { useState } from "react";
import { pie_onClick } from "../../../charts/nivo_objects";
import PortfolioChart from "../../common/portfolioChart";
import AreaChart from "../../common/AreaChart";
import { OnboardingContext } from "../../../../Context";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import { getDashboardAssetData } from "../../../../api";
import { colorAndProd } from "../utils/constands/prodAndColor";
import PageLoader from "../common/pageLoader";
export const HoldingAssetAllocation = () => {
  const { userState } = OnboardingContext();
  const useAssetAllocationStyles = makeStyles({
    allocationMainContainer: {
      padding: "1rem",
      //   border: "1px solid orange",
      display: "flex",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      margin: "2%",
    },
    allocationContainer: {
      display: "flex",
      //   border: "1px solid red",
      flexDirection: "column",
      flex: 1,
      marginRight: "16px",
      //   cursor: "pointer",
      gap: "1.8rem",
      padding: "0.8rem",
      borderRadius: "12px",
      "&:hover": {
        background:
          "linear-gradient(45deg, #530e8c -30.67%, rgba(0, 0, 0, 0) 58.95%)",
        cursor: "pointer",
      },
    },
    pieChartContainer: {
      flex: 1,
    },
    header: {
      fontSize: "20px",
      fontWeight: "500",
      color: "#FFFFFF",
      marginTop: "40px",
    },
    headingTxt: {
      fontFamily: "Avenir",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "1.92px",
      textTransform: "uppercase",
      opacity: 0.51,
    },
    assetSquare: {
      width: "20px",
      height: "20px",
      background: "red",
      borderRadius: "0.2rem",
    },
    assetNameContainer: {
      display: "flex",
      alignItems: "center",
      gap: "1.5rem",
      marginTop: "10px",
    },
    assetName: {
      margin: 0,
      marginTop: "10px",
    },
  });
  const { setMfofilter, mfoFilter, getfilter_as_params } =
    useContext(mfoFilterContext);
  const [asset_color, setAsset_Color] = useState(Object.values(colorAndProd));
  // const [assets, setAssets] = useState({});
  const [loading, setLoading] = useState(true);
  const [assetTotalData, setAssetTotalData] = useState({});
  const [assetData, setAssetData] = useState([]);
  const [pie, setPie] = useState({
    title: "",
    value: "",
    colors: asset_color,
    disabled: [],
    fill: [
      {
        match: {
          id: "debt",
        },
        id: "c6",
      },
      {
        match: {
          id: "equity",
        },
        id: "c3",
      },
      {
        match: {
          id: "alternate",
        },
        id: "c2",
      },
      {
        match: {
          id: "cash",
        },
        id: "c4",
      },
    ],
  });
  useEffect(() => {
    const clientId = userState.loginedUserData.id;
    let params = { ...getfilter_as_params() };
    setLoading(true);
    getDashboardAssetData(clientId, params, "dashboard")
      .then((res) => {
        setLoading(false);
        let arr = [];
        let asset = {};
        pie.colors = [];

        res?.data?.forEach((a) => {
          pie.colors.push(assetColor[a.assetClass]);
          arr.push({
            id: `${a.assetClass}`,
            label: `${
              a.assetClass
                ? a?.assetClass.charAt(0).toUpperCase() + a?.assetClass.slice(1)
                : " "
            }`,
            value: parseFloat(a.percentageOfTotalPortfolio).toFixed(2),
            amount: parseFloat(a.portfolioValueAmount).toFixed(2),
          });
          asset = {
            ...asset,
            [a.assetClass]: {
              percent: Number(a.percentageOfTotalPortfolio).toFixed(2),
              amount: a.portfolioValueAmount,
              date: a.updatedAsOn,
            },
          };
        });

        setAssetData(arr);
        setAssetTotalData({
          percent: Number(res?.percentageOfTotalPortfolio).toFixed(2),
          amount: res?.totalPortfolioAmount,
          date: res?.lastUpdatedDate,
        });
        // setAssets(asset);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);
  const [piecolors, setPieColors] = useState([
    "#f1f69d",
    "#6d67e4",
    "#46c2cb",
    "#7868B1",
  ]);
  const [pieState, setPieState] = useState({
    title: "",
    value: "",
    colors: piecolors,
    disabled: [],
    hide: false,
  });

  const classes = useAssetAllocationStyles();
  // TODO REMOVE STATIC DATA ONCE API INTEGRATION IS DONE
  const assetColor = {
    equity: "#f1f69d",

    debt: "#6d67e4",

    alternate: "#46c2cb",

    cash: "#7868B1",
  };

  return (
    <>
      <div style={{ margin: "2%" }}>
        <h1 className={classes.header}>Asset Allocation</h1>
      </div>
      <Box className={classes.allocationMainContainer}>
        <div style={{ width: "50%", display: "flex" }}>
          {assetData.map((asset, index) => {
            return (
              <Box className={classes.allocationContainer}>
                <Box className={classes.assetClass}>
                  <p className={classes.headingTxt}>ASSET CLASS</p>
                  <Box className={classes.assetNameContainer}>
                    <div
                      className={classes.assetSquare}
                      style={{ background: assetColor[asset.id] }}
                    ></div>
                    <h2 className={classes.assetName} style={{ margin: 0 }}>
                      {asset?.label}
                    </h2>
                  </Box>
                </Box>
                <Box className={classes.percentageCalculation}>
                  <p className={classes.headingTxt}>% ALLOCATION</p>
                  <h3 className={classes.assetName}>
                    {asset.value ? asset.value + "%" : "--"}
                  </h3>
                </Box>
                <Box className={classes.currentValue}>
                  <p className={classes.headingTxt}>CURRENT VALUE</p>
                  <h2 className={classes.assetName}>
                    {currencyFormat(asset.amount)}
                  </h2>
                </Box>
              </Box>
            );
          })}
        </div>
        <Box className={classes.pieChartContainer}>
          {/* {tableColumns.length !== 0 ? ( */}
          <div
            style={{ width: "100%", height: "500px", position: " relative" }}
          >
            <BgCicularPieChart
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                // top: "215px",
                // left: "56%",
              }}
            />
            <div
              className="flex_center tr20 "
              style={{
                overflow: "hidden",
                transform: "translate(-19px, 111px)",
              }}
            >
              <div style={{ width: "380px" }}>
                <PieChart
                  config={{
                    innerRadius: "0.80",
                    cornerRadius: 0,
                    padAngle: 0,
                    legends: false,
                    disableArcLink: true,
                    minWith: "380px",
                    margin: { right: 80, top: 20, left: 80 },

                    chart: { width: 420, height: 340 },
                    arcLinkLabel: (d) => `${d.value} %`,
                    // disableArcLink:pieState.hide?true:false
                  }}
                  colors={pieState.colors}
                  onClickEvent={{
                    click: pie_onClick,
                    set: setPieState,
                    cstate: pieState,
                    ncolors: piecolors,
                  }}
                  data={assetData}
                >
                  <div
                    className="cen"
                    style={{
                      color: "#fff",
                      transform: "translate(69%, -47%)",
                      top: "37%",
                    }}
                  >
                    <div className="center-info">
                      <div>
                        <div
                          className="glowcircle"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {/* <div className='circle_text2'>{pieState.value?pieState.value+" %":""}</div> */}
                          <div>
                            <div className="circle_text3">
                              {!pieState.title || pieState.title == ""
                                ? ""
                                : " "}
                            </div>
                            <div className="circle_text">
                              {!pieState.title || pieState.title == ""
                                ? ""
                                : pieState.title}
                            </div>
                            <div className="circle_text3">
                              {!pieState.title || pieState.title == ""
                                ? "Portfolio Value"
                                : " "}
                            </div>

                            {/* pieState.title */}
                            {/* <div className="circle_text">
                            {!pieState.title || pieState.title == ""
                              ? "Total Balance"
                              : ""}
                          </div> */}
                            <div className="circle_text2">
                              {
                                pieState.amount
                                  ? "" + currencyFormat(pieState.amount)
                                  : assetTotalData?.amount
                                  ? currencyFormat(assetTotalData?.amount)
                                  : ""
                                // tableColumns?.total
                              }
                            </div>
                            {/* <div
                            className="circle_text2"
                            style={{ paddingTop: "5px" }}
                          >
                            {pieState?.value
                              ? roundNumber(pieState.value) + "%"
                              : ""}
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </PieChart>
              </div>
            </div>
          </div>

          {/* ) : load?.segmentreport === false ? (
          <DataNotFound></DataNotFound>
        ) : (
          <DataNotFound text=" "></DataNotFound>
        )} */}
        </Box>
      </Box>
      <AreaChart
        type={"dashboard"}
        exp="asset-allocation-over-time"
        plot={"percent"}
      />
      <PageLoader showLoader={loading} />
    </>
  );
};
