import React, { useContext, useEffect, useState } from "react";
import useStyles from "../common/ProductsStyles";
import { useHoldingCardActive } from "../../../../hooks/useHoldingCardActive";
import { useReturns } from "../../../../hooks/useReturns";
import { useNormalReturns } from "../../../../hooks/useNormalReturns";
import { OnboardingContext } from "../../../../Context";
import BarChartIcon from "@material-ui/icons/BarChart";
import {
  fetchTwrr,
  fetchTwrrCurrentHolding,
  fetchXirr,
  fetchXirrCurrentHolding,
  getAssetWiseData,
  getInvestmentTypeData,
  getRealisedData,
  invested_portfolio,
} from "../../../../api";
import { useNavigate } from "react-router-dom";
import SummaryPageTop from "../common/SummaryPageTop";
import { routes } from "../../../../routes";
import { NewHoldingSummaryCards } from "../../../common/HoldingSummaryCards/NewHoldingSummaryCards";
import { NewHoldingSummaryCardsActive } from "../../../common/HoldingSummaryCards/NewHoldingSummaryCardsActive";

import SummaryDetailsSectionWrapper from "../newDesigns/common/SummaryDetailsSectionWrapper";
import PieChart from "../../../charts/PieChart";
import GlowCircle from "../../../charts/GlowCircle";
import { currencyFormat } from "../../../../hooks/constantFunction";
import {
  dateFormatCommon,
  roundNumber,
  roundNumberWithoutDecimal,
} from "../../../utils/common";
import PortfolioChart from "../../common/portfolioChart";
import PageLoader from "../common/pageLoader";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import { colorAndProd } from "../utils/constands/prodAndColor";
import {
  divergingCommonProps,
  pie_onClick2,
} from "../../../charts/nivo_objects";
import { summaryDetailsSecConstData } from "../utils/constands/summaryDetailsSec";
import { GainsCardInfo } from "../../../common/HoldingSummaryCards/GainsCardInfo";
import { loadingText } from "../../../utils/constands";
import { NewFilter } from "../../newFilter/NewFilter";
import { ReactComponent as InfoIcon } from "../../../../assets/icons/TotalGainLossInfoIcon.svg";
import { useCardsApi } from "../../../../hooks/useCardsApi";
import { useCurrentHoldingCardsApi } from "../../../../hooks/useCurrentHoldingCardsApi";
import { Button } from "@material-ui/core";
function ICDSummaryComp() {
  const classes = useStyles();
  const { mfoFilter, getfilter_as_params, entity } =
    useContext(mfoFilterContext);
  const [checked, setChecked] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [infoIconHovered, setInfoIconHovered] = useState(false);
  // const [holdingCardData] = useHoldingCardActive("icd");
  // const { returns: twrr } = useNormalReturns(
  //   fetchTwrr,
  //   "normal-twrr",
  //   checked,
  //   "icd"
  // );
  // const { returns: xirr } = useNormalReturns(
  //   fetchXirr,
  //   "normal-xirr",
  //   checked,
  //   "icd"
  // );
  const {
    holdingCardData,
    totalGainLossData,
    xirr,
    twrr,
    loading: cardLoader,
    twrrLoader,
    twrrError,
    retryFetchTwrr,
  } = useCardsApi("icd");
  // const { returns: xirrCurrentHolding } = useReturns(
  //   fetchXirrCurrentHolding,
  //   "xirr",
  //   checked,
  //   "icd"
  // );
  // const { returns: twrrCurrentHolding, loading: twrrCurrentHoldingLoading } =
  //   useReturns(fetchTwrrCurrentHolding, "twrr", checked, "icd");
  const {
    // holdingCardData,
    xirrCurrentHolding,
    twrrCurrentHolding,
    loading: currentHoldingCardLoader,
    twrrLoaderCurrentHolding,
    twrrErrorCurrentHolding,
    retryFetchTwrrCurrentHolding,
  } = useCurrentHoldingCardsApi(checked, "icd");

  let selectedAssetClass = getfilter_as_params().assetClass;
  const { userState } = OnboardingContext();
  const [investmentPortfolio, setInvestmentPortfolio] = useState([]);
  const [loadque, setloadque] = useState(null);
  const [loading, setLoading] = useState(true);
  const [assetData, setAssetData] = useState([]);
  const [assets, setAssets] = useState({});
  const [flip, setFlip] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [realisedData, setRealisedData] = useState({
    arr: [],
  });
  const [investmentTypeData, setInvestmentTypeData] = useState([]);
  const [asset_color, setAsset_Color] = useState(Object.values(colorAndProd));

  const color = colorAndProd;
  const [pie, setPie] = useState({
    title: "",
    value: "",
    colors: asset_color,
    disabled: [],
    fill: [
      {
        match: {
          id: "debt",
        },
        id: "c6",
      },
      {
        match: {
          id: "equity",
        },
        id: "c3",
      },
      {
        match: {
          id: "alternate",
        },
        id: "c2",
      },
    ],
  });

  const diverging = {
    ...divergingCommonProps,
  };
  diverging.axisBottom.legendOffset = 65;
  diverging.margin = { top: 5, right: 20, bottom: 70, left: 80 };
  useEffect(() => {
    const clientId = userState.loginedUserData.id;

    let params = { ...getfilter_as_params() };

    if (!checked) {
      setLoading(true);
      Promise.allSettled([
        // fetchXirr(clientId, params, "bonds"),
        // fetchXirrCurrentHolding(clientId, params, "bonds"),
        // fetchTwrr(clientId, params, "bonds"),
        // fetchTwrrCurrentHolding(clientId, params, "bonds"),
        getRealisedData(clientId, params, "icd", checked),
        // getInvestmentTypeData(clientId, params, "icd"),
      ]).then(
        ([
          // xirr_res,
          // xirrCurrentHolding_res,
          // twrr_res,
          // twrrCurrentHolding_res,
          realised_ress,
          // invest_res,
        ]) => {
          // if (invest_res.status == "fulfilled") {
          //   let arr = [];
          //   invest_res.value.result.forEach((a) => {
          //     if (a.investment_type.toLowerCase() == "sip") {
          //       a.investment_type = "SIP";
          //     }
          //     arr.push({
          //       id: `${a.investment_type}`,
          //       label: `${
          //         a.investment_type
          //           ? a.investment_type.charAt(0).toUpperCase() +
          //             a.investment_type.slice(1)
          //           : " "
          //       }`,
          //       value: parseFloat(a.percentage).toFixed(2),
          //       amount: a.amount,
          //     });
          //   });
          //   setInvestmentTypeData(arr);
          // }

          if (realised_ress.status == "fulfilled") {
            let arr = [];
            if (realised_ress.value.result.unRealisedGainLossPercent != null) {
              if (realised_ress.value.result.realisedGainLossPercent != 0) {
                arr.push({
                  id: `Realised Gain Loss`,
                  label: `Realised Gain`,
                  value: parseFloat(
                    realised_ress.value.result.realisedGainLossPercent
                  ).toFixed(2),
                  amount: realised_ress.value.result.realisedGainLoss,
                });
              }
              if (realised_ress.value.result.unRealisedGainLossPercent != 0) {
                arr.push({
                  id: `Unrealised Gain Loss`,
                  label: `Unrealised Gain`,
                  value: parseFloat(
                    realised_ress.value.result.unRealisedGainLossPercent
                  ).toFixed(2),
                  amount: realised_ress.value.result.unRealisedGainLoss,
                });
              }
            }

            setRealisedData({ ...realised_ress.value.result, arr });
          }
          setLoading(false);
        }
      );
    }
  }, [mfoFilter, checked]);

  // console.log(realisedData, holdingCardData, "agiagouiagbu");

  //   useEffect(() => {
  //     const clientId = userState.loginedUserData.id;
  //     // const clientId = 199;

  //     let params = { ...getfilter_as_params() };

  //     setLoading(true);
  //     Promise.allSettled([
  //       invested_portfolio(clientId, params, "bonds"),
  //       getAssetWiseData(clientId, params, "bonds", checked),
  //     ]).then(([invest_res, asset_ress]) => {
  //       if (invest_res.status == "fulfilled") {
  //         setInvestmentPortfolio(invest_res.value.result);
  //       }

  //       if (asset_ress.status == "fulfilled") {
  //         let arr = [];
  //         let colors = [];
  //         let asset = {};
  //         asset_ress.value.result.forEach((a) => {
  //           if (selectedAssetClass.includes(a?.asset_class)) {
  //             arr.push({
  //               id: `${a.asset_class}`,
  //               label: `${
  //                 a.asset_class
  //                   ? a.asset_class.charAt(0).toUpperCase() +
  //                     a?.asset_class?.slice(1)
  //                   : " "
  //               }  ${a.percentage}%`,
  //               value: Number(a.percentage).toFixed(2),
  //               amount: a.amount,
  //               color: color[a.asset_class],
  //             });
  //             colors.push(color[a.asset_class]);
  //           }

  //           asset = {
  //             ...asset,
  //             [a.asset_class]: {
  //               percent: Number(a.percentage).toFixed(2),
  //               amount: a.amount,
  //               date: a.as_on_date,
  //             },
  //           };
  //         });

  //         setAsset_Color(colors);
  //         setPie({
  //           ...pie,
  //           colors: colors,
  //         });
  //         setAssets(asset);
  //         setAssetData(arr);
  //       }
  //       setLoading(false);
  //     });
  //   }, [mfoFilter, checked]);

  const navigate = useNavigate();

  const data = [
    {
      key: "equity",
      title: "Equity",
      amount: assets["equity"]
        ? currencyFormat(assets["equity"]?.amount)
        : currencyFormat(0),
      percent: assets["equity"]
        ? roundNumber(assets["equity"]?.percent) + "%"
        : "0.00%",
      dateStr: assets["equity"]?.date ? "Updated as on" : "",
      date: dateFormatCommon(assets["equity"]?.date),
      color: "bluebox",
      width: "240px",
    },
    {
      key: "debt",
      title: "Debt",
      amount: assets["debt"]
        ? currencyFormat(assets["debt"]?.amount)
        : currencyFormat(0),
      percent: assets["debt"]
        ? roundNumber(assets["debt"]?.percent) + "%"
        : "0.00%",
      dateStr: assets["debt"]?.date ? "Updated as on" : "",
      date: dateFormatCommon(assets["debt"]?.date),
      // color: "greenbox",
      color: "orangebox",
      width: "500px",
    },
    {
      key: "alternate",
      title: "Alternates",
      amount: assets["alternate"]
        ? currencyFormat(assets["alternate"]?.amount)
        : currencyFormat(0),
      percent: assets["alternate"]
        ? roundNumber(assets["alternate"]?.percent) + "%"
        : "0.00%",
      dateStr: assets["alternate"]?.date ? "Updated as on" : "",
      date: dateFormatCommon(assets["alternate"]?.date),
      // color: "orangebox",
      color: "greenbox",
      width: "240px",
    },

    {
      key: "cash",
      title: "Cash",
      amount: assets["cash"]
        ? currencyFormat(assets["cash"]?.amount)
        : currencyFormat(0),
      percent: assets["cash"]
        ? roundNumber(assets["cash"]?.percent) + "%"
        : "0.00%",
      dateStr: assets["cash"]?.date ? "Updated as on" : "",
      date: dateFormatCommon(assets["cash"]?.date),
      color: "purplebox",
      width: "240px",
    },
  ];
  /* Filter Cards Based on Selected Asset Class from filter */
  let cardData1 = data.map((data) => {
    if (!selectedAssetClass.includes(data.key)) {
      data.dontShow = true;
    }
    return data;
  });

  function holdingSummaryCardsInactiveData() {
    return {
      asOnDate:
        realisedData?.asOnDate != null
          ? holdingCardData?.asOnDate
          : loadingText,

      //    * current-holding api values
      portfolioValueAmount:
        holdingCardData?.portfolioValueAmount != null
          ? currencyFormat(holdingCardData?.portfolioValueAmount)
          : loadingText,
      //   * gain-loss api values
      overallInvestedAmount:
        realisedData?.overAllInvestedAmount != null
          ? currencyFormat(realisedData?.overAllInvestedAmount)
          : loadingText,
      //    * current-holding api values
      investedAmount:
        holdingCardData?.investedAmount != null
          ? currencyFormat(holdingCardData?.investedAmount)
          : loadingText,
      //    * current-holding api values
      redeemedInvestedAmount:
        holdingCardData?.redeemedInvestedAmount != null
          ? currencyFormat(holdingCardData?.redeemedInvestedAmount)
          : loadingText,
      //   * total-gain-loss api value
      totalGainLoss:
        totalGainLossData?.totalGainLoss != null
          ? currencyFormat(totalGainLossData?.totalGainLoss)
          : loadingText,
      //   * gain-loss api values
      realisedGainLoss:
        realisedData?.realisedGainLoss != null
          ? currencyFormat(realisedData?.realisedGainLoss)
          : loadingText,
      //    * current-holding api values
      unRealisedGainLoss:
        holdingCardData?.unRealisedGainLoss != null
          ? currencyFormat(holdingCardData?.unRealisedGainLoss)
          : loadingText,
      //   * gain-loss api values
      xirrValue:
        xirr?.xirr != null
          ? roundNumberWithoutDecimal(Number(xirr?.xirr)) + " %"
          : loadingText,
      //   * gain-loss api values
      absoluteTwrrData:
        twrr?.absoluteTwrr != null
          ? roundNumberWithoutDecimal(Number(twrr?.absoluteTwrr)) + " %"
          : loadingText,
      //   * gain-loss api values
      annualisedTwrrData:
        twrr?.annualisedTwrr != null
          ? roundNumberWithoutDecimal(Number(twrr?.annualisedTwrr)) + " %"
          : loadingText,
      //   * gain-loss api values
      interestIncome:
        realisedData?.interestIncome != null
          ? currencyFormat(realisedData?.interestIncome)
          : loadingText,
    };
  }
  function holdingSummaryCardsActiveData() {
    return {
      asOnDate:
        holdingCardData?.asOnDate !== null
          ? holdingCardData?.asOnDate
          : loadingText,
      portfolioValueAmount:
        holdingCardData?.portfolioValueAmount !== null
          ? currencyFormat(holdingCardData?.portfolioValueAmount)
          : loadingText,
      investedAmount:
        holdingCardData?.investedAmount !== null
          ? currencyFormat(holdingCardData?.investedAmount)
          : loadingText,
      totalGainLoss:
        holdingCardData?.totalGainLoss !== null
          ? currencyFormat(holdingCardData?.totalGainLoss)
          : loadingText,
      realisedGainLoss:
        holdingCardData?.realisedGainLoss !== null
          ? currencyFormat(0)
          : loadingText,
      unRealisedGainLoss:
        holdingCardData?.unRealisedGainLoss !== null
          ? currencyFormat(holdingCardData?.unRealisedGainLoss)
          : loadingText,
      xirrValue:
        xirrCurrentHolding?.xirr !== null
          ? roundNumberWithoutDecimal(Number(xirrCurrentHolding?.xirr)) + " %"
          : loadingText,
      absoluteTwrrData:
        twrrCurrentHolding?.absoluteTwrr !== null
          ? roundNumberWithoutDecimal(
              Number(twrrCurrentHolding?.absoluteTwrr)
            ) + " %"
          : loadingText,
      annualisedTwrrData:
        twrrCurrentHolding?.annualisedTwrr !== null
          ? roundNumberWithoutDecimal(
              Number(twrrCurrentHolding?.annualisedTwrr)
            ) + " %"
          : loadingText,
      interestIncome:
        holdingCardData?.interestIncome != null
          ? currencyFormat(holdingCardData?.interestIncome)
          : loadingText,
    };
  }

  const handleSummaryClick = () => {
    navigate(routes.icdHolding);
  };

  return (
    <>
      <SummaryPageTop
        backLink={routes?.homeHoldingPage}
        pageTitle={"ICD Portfolio Summary"}
        asOnDate={false}
        flip={flip}
        setFlip={setFlip}
        checked={checked}
        setChecked={setChecked}
        setFilterModalOpen={setFilterModalOpen}
      />
      <NewFilter
        filterModalOpen={filterModalOpen}
        setFilterModalOpen={setFilterModalOpen}
        productType="icd"
      />
      <div style={{ margin: "2%" }}>
        {!checked ? (
          <NewHoldingSummaryCards
            retryFetchTwrr={retryFetchTwrr}
            twrrLoader={twrrLoader}
            twrrError={twrrError}
            infoIconHovered={infoIconHovered}
            holdingSummaryCardsInactive={holdingSummaryCardsInactiveData(
              realisedData,
              holdingCardData,
              xirr,
              twrr
            )}
            // totalRentalIncome={
            //   <GainsCardInfo
            //     text="Total Interest Income"
            //     align="right"
            //     value={
            //       realisedData?.interestIncome != null
            //         ? currencyFormat(holdingCardData?.interestIncome)
            //         : loadingText
            //     }
            //   />
            // }
            totalGainLossInactive={
              <>
                <GainsCardInfo
                  text="Realized Gain/Loss"
                  value={
                    holdingSummaryCardsInactiveData(
                      investmentPortfolio,
                      holdingCardData,
                      xirr,
                      twrr,
                      totalGainLossData
                    )?.realisedGainLoss
                  }
                  align="left"
                />
                <GainsCardInfo
                  text="Unrealized Gain/Loss"
                  value={
                    holdingSummaryCardsInactiveData(
                      investmentPortfolio,
                      holdingCardData,
                      xirr,
                      twrr,
                      totalGainLossData
                    )?.unRealisedGainLoss
                  }
                  align="center"
                />
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <GainsCardInfo
                    text="Total Interest Income"
                    value={
                      holdingSummaryCardsInactiveData(
                        investmentPortfolio,
                        holdingCardData,
                        xirr,
                        twrr,
                        totalGainLossData
                      )?.interestIncome
                    }
                    align="right"
                  />
                </div>
              </>
            }
          ></NewHoldingSummaryCards>
        ) : (
          <NewHoldingSummaryCardsActive
            retryFetchTwrrCurrentHolding={retryFetchTwrrCurrentHolding}
            twrrLoaderCurrentHolding={twrrLoaderCurrentHolding}
            twrrErrorCurrentHolding={twrrErrorCurrentHolding}
            infoIconHovered={infoIconHovered}
            holdingSummaryCardsActive={holdingSummaryCardsActiveData(
              holdingCardData,
              xirrCurrentHolding,
              twrrCurrentHolding
            )}
            // totalRentalIncome={
            //   <GainsCardInfo
            //     text="Total Interest Income"
            //     align="right"
            //     value={
            //       holdingCardData?.interestIncome != null
            //         ? currencyFormat(holdingCardData?.interestIncome)
            //         : loadingText
            //     }
            //   />
            // }
            totalGainLossActive={
              <>
                <GainsCardInfo
                  text="Unrealized Gain/Loss"
                  value={
                    holdingSummaryCardsActiveData(
                      holdingCardData,
                      xirrCurrentHolding,
                      twrrCurrentHolding
                    )?.unRealisedGainLoss
                  }
                  align="left"
                />
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <GainsCardInfo
                    text="Total Interest Income"
                    value={
                      holdingSummaryCardsActiveData(
                        holdingCardData,
                        xirrCurrentHolding,
                        twrrCurrentHolding
                      )?.interestIncome
                    }
                    align="right"
                  />
                </div>
              </>
            }
          ></NewHoldingSummaryCardsActive>
        )}
      </div>
      {/* <SummaryDetailsSectionWrapper
        summaryDetailsSecConstData={summaryDetailsSecConstData}
        cardData1={cardData1}
        hideNavButtons={true}
        handleClick={() => navigate(routes.icdHolding)}
        peiChart={
          <div className="flex_center tr20" style={{ height: "100%" }}>
            <div className={classes.wid380px}>
              <PieChart
                colors={pie.colors}
                // fill={pie.fill}
                config={{
                  innerRadius: "0.90",
                  padAngle: 0,
                  legends: false,
                  minWith: "380px",
                  disableArcLink: true,
                  chart: {
                    width: 440,
                    height: 420,
                    arcLinkLabelsStraightLength: 15,
                  },
                  arcLinkLabel: (d) => (
                    <tspan
                      x="0"
                      y="0"
                      style={{
                        fontFamily: "Avenir",
                        fontWeight: "400",
                        fontSize: "12",
                        textTransform: "capitalize",
                      }}
                    >
                      {" "}
                      {d.id}
                      <tspan
                        x="0"
                        y="18"
                        style={{
                          fontFamily: "Open Sans",
                          fontWeight: "700",
                          fontSize: "14",
                        }}
                      >
                        {" "}
                        {d.value}%
                      </tspan>
                    </tspan>
                  ),
                }}
                //click: realisedGain_onClick, set: setRealisedGain, cstate: realisedGain, ncolors: realisedGainColors
                onClickEvent={{
                  click: pie_onClick2,
                  set: setPie,
                  cstate: pie,
                  ncolors: asset_color,
                }}
                // onClickEvent={{ click: pie_onClick, set: setPie,cstate:pie,ncolors:asset_color }}
                data={assetData}
              >
                <GlowCircle>
                  <div className="circle_content">
                    <div>
                      <b className="circle_text">
                        {pie.title && pie.value != null
                          ? pie.title
                          : "Portfolio value"}
                      </b>
                      <br></br>
                      <b className="circle_text2">
                        {pie.amount != null
                          ? currencyFormat(pie.amount)
                          : investmentPortfolio.portfolioValueAmount != null
                          ? currencyFormat(
                              investmentPortfolio.portfolioValueAmount
                            )
                          : ""}
                      </b>
                      <div className="circle_text2 pt5">
                        {pie?.value ? roundNumber(pie?.value) + "%" : ""}
                      </div>
                    </div>
                  </div>
                </GlowCircle>
              </PieChart>
            </div>
          </div>
        }
      /> */}
      {
        <PortfolioChart
          type={"icd"}
          loading={loading}
          noPaddingBottom={true}
          viewDetailBtn={
            <Button
              onClick={handleSummaryClick}
              className={classes.viewDetailBtn + " portfolioBtn "}
              style={{
                width: "20%",
                margin: "0% 40% 0",

                borderRadius: "7px",
                backgroundColor: "#4f1fda !important",
              }}
              startIcon={<BarChartIcon />}
            >
              View Detail Report
            </Button>
          }
        ></PortfolioChart>
      }
      <PageLoader showLoader={loading ? true : false} />
      <PageLoader
        showLoader={currentHoldingCardLoader && checked ? true : false}
      />
    </>
  );
}

export default ICDSummaryComp;
