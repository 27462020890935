let align = "center";
export const bondsTableColumnsDataHolding = [
  {
    field: "schemeName",
    title: "Scheme/Product Name",
    show: true,
    align: align,
  },
  { field: "advisor", title: "Advisor", show: true, align: align },
  { field: "isin", title: "ISIN No", show: true, align: align },
  // { field: "folioNo", title: "Folio Number", show: true, align: align },
  { field: "investmentValue", title: "Amount", show: true },
  {
    field: "withdrawalAmount",
    title: "Withdrawal * Includes Capital Reduction",
    show: true,
  },
  { field: "presentValue", title: "Present Value", show: true },

  {
    field: "percentageOfTotalPortfolio",
    title: "% of Total Portfolio",
    show: true,
  },
  // { field: "realisedGainLoss", title: "Realized Gain/Loss", show: false },

  { field: "unRealisedGainLoss", title: "Unrealized Gain/Loss", show: true },
  {
    field: "interestIncome",
    title: "Total Interest Income",
    show: true,
  },
  {
    field: "totalGainLoss",
    title: "Total Gain/Loss",
    show: true,
  },
  { field: "xirr", title: "XIRR (%)", show: true, nonTwoLine: true },
  {
    field: "absoluteTwrr",
    title: "Absolute TWRR (%)",
    show: true,
    nonTwoLine: true,
  },
  {
    field: "annualisedTwrr",
    title: "Annualized TWRR (%)",
    show: true,
    nonTwoLine: true,
  },
];

export const bondsTableColumnsDataTransaction = [
  {
    field: "scheme_name",
    title: "Scheme/Product Name",
    show: true,
    align: align,
  },
  { field: "advisor", title: "Advised by", show: true, align: align },
  { field: "executed_by", title: "Executed By", show: true, align: align },
  { field: "isin", title: "ISIN No", show: true, align: align },
  { field: "folio_no", title: "Folio Number", show: true, align: align },
  {
    field: "transaction_date",
    title: "Transaction Date",
    show: true,
    secondLine: false,
    align: align,
  },
  {
    field: "issue_open_date",
    title: "Issue Open Date",
    show: true,
    secondLine: false,
    align: align,
  },
  {
    field: "transaction_type",
    title: "Transaction Type",
    show: true,
    align: align,
  },
  {
    field: "holdingPeriod",
    title: "Holding Period",
    show: true,
    align: align,
  },
  { field: "quantity", title: "Quantity", show: true },
  { field: "price", title: "price (Actuals)", show: true },
  { field: "stamp_duty", title: "Stamp Duty (Actuals)", show: true },
  { field: "other_charges", title: "Other Charges (Actuals)", show: true },
  { field: "amount", title: "Amount", show: true },
  {
    field: "yield_to_call_put",
    title: "Yield to Call / Put",
    show: true,
    align: align,
  },
  {
    field: "call_put_date",
    title: "Call/put Date",
    show: true,
    secondLine: false,
    align: align,
  },
  { field: "cumulative_payment", title: "Cumulative payment", show: true },
  { field: "accrual_frequency", title: "Accural Frequency", show: true },
  {
    field: "accural_interest_paid",
    title: "Accural Interest Paid",
    show: true,
  },
  {
    field: "maturity_payment",
    title: "Maturity Payment",
    show: true,
  },
];

const icdTableColumnsDataTransactionList =
  bondsTableColumnsDataTransaction.filter(
    (val) => val.field !== "issue_open_date"
  );
export const icdTableColumnsDataTransaction = [
  ...icdTableColumnsDataTransactionList,
  {
    field: "remark",
    title: "Remark",
    show: true,
  },
];
