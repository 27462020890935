import React, { useState } from "react";
import HoldingPrint from "./subPrintPages/HoldingPrint";
import {
  ClientHolding,
  newpdfHoldingsRealEstate,
  newPdfSecurityHolding,
  newpdfHolding,
  newpdfHoldings,
  newpdfTransactions,
  newpdfHoldingsInsuranceNonUlip,
  newpdfHoldingsInsuranceUlip,
  newAifPdfHoldings,
  newDirectEquityPdfHoldings,
  newpdfHoldingsBankBook,
  newpdfTransactionsFundsInTransit,
  newpdfHoldingsFundInTransit,
  newpdClientfHoldings,
  newProductAllocationModel,
  newMemberProductAllocationModel,
  top5amc,
} from "../Components/utils/staticData";
import { Link } from "react-router-dom";
import Overall_portfolioHolding from "./Overall_portfolioHolding";
import { objectFormatingWithProductAllocationTitleCOl } from "../Components/utils/common";
import MarketCap from "./MarketCap";
import EquitySectorAllocationPdf from "./EquitySectorAllocationPdf";

export default function NewProduct({
  newData,
  denomination,
  reportType,
  setChanges,
  pdfInfo,
  member,
  asset,
  productSummaryStr,
  breadcrum,
}) {
  const colsWithSubtext = [
    "Bonds",
    "Real Estate Investment Trusts",
    "Infrastructure Investment Trusts",
    "AIF",
  ];
  console.log("newProduct", newData?.["equity-market-cap"]);
  const marketCap = newData?.["equity-market-cap"];
  const SECURITY_HOLDING_SUMMARY =
    "Consolidated Securities Holding Summary Report";
  function modifyFirstcol(p) {
    let colarr = renderCustomTableHeaderBasedOnReportType(p);
    colarr = JSON.parse(JSON.stringify(colarr));

    let index = colarr?.findIndex((a) => {
      if (a.title == "Current Value (₹)") {
        return a;
      }
    });

    if (index >= 0) {
      colarr[index]["subdata"] = "updatedAsOn";

      colarr[index]["subtext"] = (
        <div className="s_text italic1">Valuation As On</div>
      );
    }

    return colarr;
  }
  function renderCustomTableHeaderBasedOnReportType(product) {
    // if (reportType === SECURITY_HOLDING_SUMMARY) {
    //   return newPdfSecurityHolding;
    // } else if (reportType === "clientHolding") {
    //   return ClientHolding;
    // } else if (reportType === "transactionSummary") {
    //   return newpdfTransactions;
    // } else {
    if (member) {
      return newMemberProductAllocationModel;
    }
    return newProductAllocationModel;
    // }
  }
  const breadcrumbs = [
    <Link
      key="1"
      color="#000000"
      className="printBreadsel fontFamilyAvBold f16"
    >
      Product allocation
    </Link>,
  ];
  // console.log(
  //   newData?.["holdingSummary"] && Object?.keys(newData?.["holdingSummary"]),
  //   "newData"
  // );
  function generateTablePages() {
    const assetClasses = ["equity", "debt", "alternate", "cash"];
    if (!member) {
      return assetClasses.map((arr) => {
        let bread = [...breadcrumbs];
        bread.push(
          <Link
            underline="hover"
            key="2"
            color="
  #000000"
          >
            <span className="opc80"> Asset Class : </span>
            <span className="pdfselectedTxt pdfCaps fontFamilyAvBold f16">
              {arr}
            </span>
          </Link>
        );
        let total = newData?.["dashboard-asset-details"]?.data?.find((a) => {
          if (a.assetClass == arr) {
            return a;
          }
        });
        let totaldata = {
          portfolioValueAmount: total?.portfolioValueAmount,

          investedAmount: total?.investedAmount,

          interestIncome: total?.interestIncome,

          unRealisedGainLoss: total.unRealisedGainLoss,

          percentageOfTotalPortfolio: total?.percentageOfTotalPortfolio,

          xirr: total?.xirr,
          product: "Total",
        };
        // console.log(
        //   newData?.[(productSummaryStr || "dashboard-product-summary-") + arr],
        //   arr,
        //   "new data"
        // );
        // console.log("generating tables");
        if (
          newData?.[
            (productSummaryStr || "dashboard-product-summary-") + arr
          ]?.[
            newData?.[(productSummaryStr || "dashboard-product-summary-") + arr]
              ?.length - 1
          ]?.product != "Total"
        ) {
          newData?.[
            (productSummaryStr || "dashboard-product-summary-") + arr
          ].push(totaldata);
        }
        return (
          <div className="newPrint pagebreakTable">
            {newData?.[
              (productSummaryStr || "dashboard-product-summary-") + arr
            ].length > 1 && (
              <HoldingPrint
                headerType={13}
                title={bread || "Holding Summary"}
                // footerLegendText={"Indicates Held-away Holdings"}
                pdfInfo={pdfInfo}
                // asOnDate={refForTotal?.asOnDate}
                layout={1}
                title2={member || null}
                // title1={"Product allocation"}
                customTableHeader={modifyFirstcol(null)}
                subtext={""}
                customKey={"ProductAllocation"}
                type={"ProductAllocationy"}
                loading={false}
                setChanges={setChanges}
                breadcrumb={bread}
                // error={data?.equity?.direct_equity_holding_summary?.error}
                customData={
                  // data?.equity?.direct_equity_holding_summary?.data?.data?.result
                  // stDataHolding

                  newData?.[
                    (productSummaryStr || "dashboard-product-summary-") + arr
                  ]
                }
                customTableClass={"printTableEvenAndOddRow"}
                hideTitle={true}
                hideTotalRow={true}
                denomination={denomination}
                colmapping={objectFormatingWithProductAllocationTitleCOl}
                render={
                  <>
                    {/* {arr == "equity" && marketCap && (
                      <>
                        <MarketCap
                          denomination={denomination}
                          data={marketCap || {}}
                          title={"Market Cap"}
                          mappedKey={{
                            key1: "topFiveAMCs",
                            key2: "amc",
                            key3: "percentage",
                            key4: "topFiveAmcValuation",
                          }}
                          tableconfig={top5amc}
                        />
                      </>
                    )}
                    {arr == "equity" && (
                      <EquitySectorAllocationPdf
                        denomination={denomination}
                        setChanges={setChanges}
                        newdata={newData?.["equity-sector-allocation"] || {}}
                        pdfInfo={pdfInfo}
                        // currentData={newData?.["dashboard-gain-loss"]?.portfolioValueAmount}
                      />
                    )} */}
                  </>
                }
              />
            )}
            {/* )} */}
          </div>
        );
      });
    } else {
      let bread = [...breadcrumbs];
      bread.push(
        <Link
          underline="hover"
          key="2"
          color="
#000000"
        >
          <span className="opc80"> Asset Class : </span>
          <span className="pdfselectedTxt pdfCaps fontFamilyAvBold f16">
            {asset}
          </span>
        </Link>
      );

      return (
        <div className="newPrint pagebreakTable">
          {/* {asset == "equity" && (
            <>
              <h1>Market Cap</h1>

              {marketCap && (
                <>
                  <h1>Market Csssss</h1>
                  <MarketCap
                    denomination={denomination}
                    data={marketCap || {}}
                    title={"Market Cap"}
                    mappedKey={{
                      key1: "topFiveAMCs",
                      key2: "amc",
                      key3: "percentage",
                      key4: "topFiveAmcValuation",
                    }}
                    tableconfig={top5amc}
                  />
                </>
              )}
            </>
          )} */}
          <HoldingPrint
            headerType={13}
            title={bread || "Holding Summary"}
            // footerLegendText={"Indicates Held-away Holdings"}
            pdfInfo={pdfInfo}
            // asOnDate={refForTotal?.asOnDate}
            layout={1}
            title2={member || null}
            // title1={"Product allocation"}
            customTableHeader={modifyFirstcol(null)}
            subtext={""}
            customKey={"ProductAllocation"}
            type={"ProductAllocationy"}
            loading={false}
            setChanges={setChanges}
            breadcrumb={bread}
            // error={data?.equity?.direct_equity_holding_summary?.error}
            customData={
              // data?.equity?.direct_equity_holding_summary?.data?.data?.result
              // stDataHolding

              newData || []
            }
            customTableClass={"printTableEvenAndOddRow"}
            hideTitle={true}
            hideTotalRow={true}
            denomination={denomination}
            colmapping={objectFormatingWithProductAllocationTitleCOl}
          />
        </div>
      );
    }
  }
  return <>{newData && generateTablePages()}</>;
}
