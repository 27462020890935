import React from 'react'
import { useLocation } from 'react-router-dom';
import Default from '../components/dashboard/defaultPages/Default'
import VerificationTabs from '../components/verificationUpload/VerificationUploadContent'

function VerificationUpload() {
    const location  = useLocation()
    const pageName = location?.pathname.split('/')[2]
    
    return (
      <Default>
          <VerificationTabs 
            pageName={pageName}
          />
      </Default>
    )
}

export default VerificationUpload