import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Done from "../assets/partner-images/done.png";
import Loader from "../components/loader/Loader";
import { API_URL, CLIENT_CODE } from "../Config";
import Lock from "../assets/partner-images/lock.png";
import EnterPINInput from "../components/common/PinInput";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography, Grid } from "@material-ui/core";
import SignUpImage from "../assets/partner-images/Sign_up.png";
import { ReactComponent as Approve } from "../assets/icons/approve.svg";
import { ReactComponent as PendingSvg } from "../assets/icons/pending.svg";
import Logo from "../assets/icons/logo.png";
import { ReactComponent as Pending } from "../assets/icons/pending-verification.svg";
import { ReactComponent as Verified } from "../assets/icons/verified-verification.svg";
import { ENTITY_TYPE_CLIENT, ENTITY_TYPE_PARTNER } from "../Constants";
import CustomizedSnackbars from "../components/common/CustomizedSnackbars";
import { createPin } from "../api";
import { OnboardingContext } from "../Context";
import VerificationScreen from "../components/NewSignUp/verificationScreen/VerificatonScreen";
import C_Modal from "../components/common/modal_add_member/C_Modal";
import PartnerHeading from "../components/NewSignUp/PartnerHeading";
import Container_wrapper from "../components/common/modal_add_member/Container_wrapper";
import BackToLogin from "../components/NewSignUp/verificationScreen/BackToLogin";
import SetPinForm from "../components/NewSignUp/SetPinForm";

import VerifyPinForm from "../components/NewSignUp/verificationScreen/VerifyPinForm";

const useStyles = makeStyles((theme) => ({
  container: {
    // backgroundImage: `url(${SignUpImage})`,
    minHeight: "100vh",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: "#170630",
    with: "100%",
  },
  logo: {
    width: "55px",
    left: "5px",
    margin: "0px",
    top: "15px",
    position: "absolute",
  },
  contentBox: {
    // // background: "rgba(11, 3, 24, 0.6)",
    // // border: "1px solid #4E189F",
    // // boxShadow: "0px 0px 10px #4E189F",
    // // backdropFilter: "blur(50px)",
    // borderRadius: "50px",
    position: "relative",
    // left: "22%",
    width: "100%",
    minwidth: "558px",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: "25%",
    [theme.breakpoints.down("md")]: {
      // left: "10%",
      width: "100%",
      // top: "25%",
    },
    // [theme.breakpoints.down("xs")]: {
    //   left: "5%",
    //   width: "90%",
    //   top: "25%",
    // },
  },
  mainContent: {
    height: "300px",
    padding: "15px 20px 2px 20px",
  },
  text: {
    fontStyle: "normal",
    fontFamily: "Avenir",
  },
  success: {
    fontWeight: "800",
    fontSize: "24px",
    lineHeight: "30px",
    textAlign: "center",
    color: "#FFFFFF",
    marginBottom: "10px",
  },
  successText: {
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "24px",
    textAlign: "center",
    color: "#FFFFFF",
    marginBottom: "15px",
  },

  line: {
    opacity: "0.2",
    backgroundColor: "#D4BBFB",
    border: ".5px solid #D4BBFB",
  },
  buttonContainer: {
    textAlign: "center",
    marginTop: "5px",
    marginBottom: "10px",
  },
  button: {
    paddingTop: "10px",
    width: "70%",
    color: "rgb(255, 255, 255)",
    background:
      "linear-gradient(rgb(170, 111, 254) 0%, rgb(113, 28, 241) 100%)",
    borderRadius: "20px",
    paddingBottom: "10px",
  },
  buttonText: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#FFFFFF",
  },
  image: {
    background: "#D4BBFB",
    borderRadius: "50%",
    height: "30px",
    width: "30px",
    padding: "10px",
  },
  onBoardingHeading: {
    fontWeight: "800",
    fontSize: "24px",
    lineHeight: "30px",
    textAlign: "center",
    color: "#FFFFFF",
    marginTop: "10px",
    marginBottom: "15px",
  },
  label: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#9488A7",
    marginTop: "10px",
    marginBottom: "15px",
  },
}));

function VerificationEmailMobile() {
  const classes = useStyles();
  const [showLoder, setShowLoader] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");
  const { comType, entityType } = useParams();
  const [verificationData, setVerificationData] = useState({});
  const [activeStep, setActiveStep] = useState(1);
  const [header, setHeader] = useState({
    mainHead: "Verification",
    sub: "An email has been sent to your registered Email Id",
  });
  const [enterPIN, setEnterPIN] = useState("");
  const [confirmPIN, setConfirmPIN] = useState("");
  const { dispatch } = OnboardingContext();
  const navigate = useNavigate();

  const verifyEmail = async () => {
    const apiURl = API_URL;
    const url = `${apiURl}/${entityType}/${comType}/verify-link?token=${token}`;
    setShowLoader(true);

    const data = await axios.put(
      url,
      { token },
      {
        headers: {
          client_code: CLIENT_CODE,
        },
      }
    );
    const statusData = data.data;

    if (statusData.success) {
      setShowLoader(false);
      setVerificationData(statusData.data.result);
    }
  };

  useEffect(() => {
    verifyEmail();
  }, []);
  useEffect(() => {
    switch (activeStep) {
      case 1:
        setHeader({
          mainHead: "Verification",
          sub: "An email has been sent to your registered Email Id",
        });
        break;
      case 2:
        setHeader({
          mainHead: "Set Your PIN",
          sub: "Your Email & Mobile Number has been verified. You will recieve a link to set your pin shorlty",
        });
        break;
      case 3:
        setHeader({
          mainHead: "Successfull",
          sub: "Your Pin has been set successfully",
        });
        break;
    }
  }, [activeStep]);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const submitForm = async (values) => {
    setShowLoader(true);
    let userType = "";
    if (entityType === "client-user") {
      userType = ENTITY_TYPE_CLIENT;
    } else if (entityType === "partner-user") {
      userType = ENTITY_TYPE_PARTNER;
    }

    try {
      const data = await createPin(values, userType);
      const pinData = data.data;

      if (pinData.success) {
        setShowLoader(false);
        setActiveStep(3);
        setHeader({
          mainHead: "Successfull",
          sub: "Your Pin has been set successfully",
        });
        // handleNext();
      }
    } catch (error) {
      setShowLoader(false);

      if (!error.response.data.error.success) {
        dispatch({
          type: "UPDATE_SNACKBAR_DATA",
          payload: {
            success: false,
            error: true,
            showMsg: true,
            message: error.response.data.error.message,
          },
        });
      }
    }
  };

  const handleSubmit = (data) => {
    // if (enterPIN !== "" && confirmPIN !== "" && enterPIN === confirmPIN) {

    submitForm({
      userId: verificationData?.userId,
      pin: parseInt(data.pin),
    });
    // } else {
    //   dispatch({
    //     type: "UPDATE_SNACKBAR_DATA",
    //     payload: {
    //       success: false,
    //       error: true,
    //       showMsg: true,
    //       message: "Invalid PIN or not matched!",
    //     },
    //   });
    // }
  };

  return (
    <>
      {showLoder && <Loader />}
      <CustomizedSnackbars />
      <div className={classes.container}>
        <img className={classes.logo} src={Logo} alt="logo"></img>

        {/* <VerificationScreen></VerificationScreen> */}
        <div className={classes.contentBox}>
          <div
            style={{
              width: "548px",
              height: "590px",
              minHeight: "453px",
              backgroundColor: "black",
              borderRadius: "20px",
              overflow: "hidden",
            }}
          >
            <Container_wrapper
              containerstyle={{}}
              close={() => {}}
              hideclose={true}
            >
              <div style={{ width: "100%" }}>
                <div style={{ width: "100%" }}>
                  <div className="newFormTopSec partnerHeadingPadding h6sub">
                    <PartnerHeading
                      hideBackButton={true}
                      mainHead={header.mainHead}
                      subtitle={header.sub}
                      handleBack={() => {}}
                      noStep={true}
                      step={0}
                    />
                  </div>
                  {activeStep == 1 && (
                    <VerificationScreen
                      formData={verificationData}
                      customHandleSubmit={() => {
                        console.log("step 2");
                        setActiveStep(2);
                      }}
                    />
                  )}

                  {activeStep == 2 && (
                    <VerifyPinForm
                      customHandleSubmit={(data) => {
                        console.log(data, "data");
                        handleSubmit(data);
                      }}
                    />
                  )}
                  {activeStep == 3 && (
                    <BackToLogin
                      customHandleSubmit={(data) => {
                        navigate("/");
                      }}
                    />
                  )}
                  {/* {openModal && (
    <Newform
      arrObj={arrObj}
      initial={initial}
      schema={client_add_member_validation_schema}
      customHandleSubmit
    ></Newform>
  )} */}
                </div>
              </div>
            </Container_wrapper>
          </div>
          {/* <div className={classes.mainContent}>
            {activeStep === 1 && (
              <div style={{ marginTop: "10px" }}>
                <div style={{ marginTop: "50px" }}>
                  <Typography className={`${classes.text} ${classes.success}`}>
                    Verification
                  </Typography>
                  <Typography
                    className={`${classes.text} ${classes.successText}`}
                  >
                    {verificationData?.email === 1 ? (
                      <>
                        <Verified height={16} /> Email ID verified. <br />
                      </>
                    ) : (
                      <>
                        <Pending height={25} />
                        Email ID verification pending... <br />
                      </>
                    )}
                    {entityType !== ENTITY_TYPE_PARTNER ? (
                      verificationData?.sms === 1 ? (
                        <>
                          <Verified height={16} /> Mobile verified.
                          <br />
                        </>
                      ) : (
                        <>
                          <Pending height={25} />
                          Mobile verification pending... <br />
                        </>
                      )
                    ) : (
                      <></>
                    )}
                  </Typography>
                  {verificationData?.userType !== ENTITY_TYPE_PARTNER &&
                    verificationData?.sms === 1 &&
                    verificationData?.email === 1 && (
                      <>
                        <hr
                          className={classes.line}
                          style={{ marginTop: "70px" }}
                        ></hr>
                        <div className={classes.buttonContainer}>
                          <Button
                            variant="contained"
                            className={classes.button}
                            onClick={handleNext}
                          >
                            <span
                              className={`${classes.text} ${classes.buttonText}`}
                            >
                              Set PIN
                            </span>
                          </Button>
                        </div>
                      </>
                    )}
                </div>
              </div>
            )}
            {activeStep === 2 && (
              <>
                <Typography
                  className={`${classes.text} ${classes.onBoardingHeading}`}
                >
                  Set new pin
                </Typography>
                <div style={{ marginTop: "10px" }}>
                  <hr className={classes.line}></hr>
                  <div style={{ marginTop: "15px" }}>
                    <div style={{ textAlign: "center" }}>
                      <img src={Lock} alt="Lock" className={classes.image} />
                    </div>
                    <div style={{ marginLeft: "30%", marginBottom: "10%" }}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography className={classes.label}>
                            Enter Pin
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <EnterPINInput
                            handleChange={setEnterPIN}
                            autoFocus={true}
                          />
                        </Grid>
                      </Grid>
                      <Grid container style={{ marginTop: "20px" }}>
                        <Grid item xs={12}>
                          <Typography className={classes.label}>
                            Confirm Pin
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <EnterPINInput
                            handleChange={setConfirmPIN}
                            autoFocus={false}
                          />
                        </Grid>
                      </Grid>
                    </div>
                    <div className={classes.buttonContainer}>
                      <Button
                        variant="contained"
                        className={classes.button}
                        onClick={handleSubmit}
                      >
                        <span
                          className={`${classes.text} ${classes.buttonText}`}
                        >
                          Submit
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}
            {activeStep === 3 && (
              <>
                <Typography
                  className={`${classes.text} ${classes.onBoardingHeading}`}
                >
                  Awesome!!!
                </Typography>
                <div style={{ marginTop: "10px" }}>
                  <hr className={classes.line}></hr>
                  <div style={{ marginTop: "50px" }}>
                    <div style={{ textAlign: "center" }}>
                      <img
                        src={Done}
                        alt="OtpPassword"
                        style={{ width: "140px" }}
                      />
                    </div>
                    <Typography
                      className={`${classes.text} ${classes.success}`}
                    >
                      Thank You
                    </Typography>
                    <Typography
                      className={`${classes.text} ${classes.successText}`}
                    >
                      Your 4 digit pin is set successfully!
                    </Typography>

                    <hr
                      className={classes.line}
                      style={{ marginTop: "70px" }}
                    ></hr>
                    <div className={classes.buttonContainer}>
                      <Button
                        variant="contained"
                        className={classes.button}
                        onClick={() => navigate("/")}
                      >
                        <span
                          className={`${classes.text} ${classes.buttonText}`}
                        >
                          Go To Login Page
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div> */}
        </div>
      </div>
    </>
  );
}
export default VerificationEmailMobile;
