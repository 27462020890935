import React, { useEffect } from "react";
import { useState } from "react";
import _ from "lodash";
import PmsReportTable from "./PmsReportTable";

function PmsHoldingDebt({
  mfoFilter,
  openModal,
  setOpenModal,
  getTabelData,
  tableConfig,
  objectFormating,
  tableHeader,
  setTableHeader,
  setTableColumns,
  tableColumns,
  loading,
  setOffset,
  pageCount,
  pageNumber,
  setSecondTableColumns,
  tableColumns2,
}) {
  //   const [tableColumns, setTableColumns] = useState([]);
  //   const [loading, setLoading] = useState(false);
  const [reset, setReset] = useState();

  //   useEffect(() => {
  //     // if (tableHeader)
  //     console.log(tableHeader, "abfioafavwfkuavwashbd");
  //     getTabelData(
  //       setTableColumns,
  //       "summary/holding",
  //       {
  //         clientId: 4,
  //         assetClass: "debt",
  //       },
  //       setLoading,
  //       objectFormating,
  //       tableHeader
  //     );
  //   }, [mfoFilter, reset]);

  return (
    <PmsReportTable
      productType="pmsHoldingDebt"
      setTableColumns={setTableColumns}
      tableColumns={tableColumns}
      setSecondTableColumns={setSecondTableColumns}
      tableColumns2={tableColumns2}
      setOpenModal={setOpenModal}
      openModal={openModal}
      tableName={"Debt"}
      loading={loading}
      pageName="holding"
      headerList={tableConfig}
      tableHeader={tableHeader}
      setTableHeader={setTableHeader}
      stickyProductName={true}
      // setReset={setReset}
      setItemOffset={setOffset}
      pageCount={pageCount}
      pageNumber={pageNumber}
    />
  );
}

export default PmsHoldingDebt;
