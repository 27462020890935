import * as React from "react";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Grid,
} from "@material-ui/core";
import PageContainer from "../PageContainer";
import Header from "../Components/Header";
import { ReactComponent as Question } from "../images/question.svg";
import { dateFormatCommon, roundNumber } from "../../../utils/common";
import { firstLetterCapitalize } from "../../../utils/strHelper";
import { isArray } from "lodash";
export function StackTable({
  tableCell,
  title,
  assetSubClassLegends,
  data,
  reachedData,
  dynamic,
  subTitle,
  bubbleText,
  showdate,
  ason,
}) {
  const [assetData, setAssetData] = React.useState({});
  const [assetdate, setdate] = React.useState({});
  const [legends, setLegends] = React.useState(
    dynamic
      ? {}
      : {
          equity: { color: "#97B2D5" },
          debt: { color: "#00838F" },
          alternate: { color: "#ADC371" },
          cash: { color: "#49618D" },
        }
  );
  function get_color() {
    if (colors.length > 0) {
      return colors.pop();
    }
    let color = "#" + Math.random().toString(16).substring(4);
    return color.substring(0, 7);
  }
  React.useEffect(() => {
    if (reachedData) {
    }

    if (!data) {
      return;
    }

    let assetdata = data;
    if (!assetdata) {
      return;
    }
    Object.keys(assetdata)?.map((i) => {
      let total = 0;

      Array.isArray(assetdata[i]) &&
        assetdata[i].forEach((element, k) => {
          if (!legends[element.assetType || element.product]) {
            legends[element.assetType || element.product] = {
              color: get_color(),
            };
          }

          if (
            element.percentageInvested &&
            parseFloat(roundNumber(element.percentageInvested))
          ) {
            total =
              total + parseFloat(roundNumber(element.percentageInvested)) + 10;
            element.percentageInvested = parseFloat(
              roundNumber(element.percentageInvested)
            );
          } else {
            delete assetdata[i][k];
          }
        });
      if (total == 0 && Array.isArray(assetdata[i])) {
        let a = "" + assetdata[i];
        setdate({ ...assetdate, i: a });

        delete assetdata[i];

        return;
      } else if (Array.isArray(assetdata[i])) {
        assetdata[i] = assetdata[i].filter((item) => {
          if (item !== undefined) {
            item.total = total;
            return item;
          }
        });
      }
    });

    setLegends({ ...legends });
    setAssetData(assetdata);
  }, [data]);

  // const array = [1, 2, 3, 4];

  // const table1 = () => {
  //   return (
  //     <Table aria-label="simple table">
  //       <TableHead>
  //         <TableRow>
  //           <TableCell></TableCell>
  //           <TableCell align="left">
  //             <Typography variant="subtitle1">
  //               <span
  //                 style={{
  //                   textDecoration: "underline",
  //                   display: "inline-block",
  //                   textDecorationColor: "#4B64ED",
  //                   textUnderlineOffset: "3px",
  //                   textDecorationThickness: "2px",
  //                 }}
  //               >
  //                 AMO
  //               </span>
  //               UNT INVESTED
  //             </Typography>
  //             <Typography
  //               variant="body1"
  //               style={{ fontWeight: 900, fontSize: "16px" }}
  //             >
  //               2.04 Cr
  //             </Typography>
  //           </TableCell>
  //           <TableCell align="left">
  //             <Typography variant="subtitle1">
  //               <span
  //                 style={{
  //                   textDecoration: "underline",
  //                   display: "inline-block",
  //                   textDecorationColor: "#FAC26D",
  //                   textUnderlineOffset: "3px",
  //                   textDecorationThickness: "2px",
  //                 }}
  //               >
  //                 AMO
  //               </span>
  //               UNT INVESTED
  //             </Typography>
  //             <Typography
  //               variant="body1"
  //               style={{ fontWeight: 900, fontSize: "16px" }}
  //             >
  //               2.04 Cr
  //             </Typography>
  //           </TableCell>
  //           <TableCell align="left">
  //             <Typography variant="subtitle1">
  //               <span
  //                 style={{
  //                   textDecoration: "underline",
  //                   display: "inline-block",
  //                   textDecorationColor: "#0EB9D0",
  //                   textUnderlineOffset: "3px",
  //                   textDecorationThickness: "2px",
  //                 }}
  //               >
  //                 AMO
  //               </span>
  //               UNT INVESTED
  //             </Typography>
  //             <Typography
  //               variant="body1"
  //               style={{ fontWeight: 900, fontSize: "16px" }}
  //             >
  //               2.04 Cr
  //             </Typography>
  //           </TableCell>
  //           {tableCell || (
  //             <TableCell align="left">
  //               <Typography variant="subtitle1">
  //                 <span
  //                   style={{
  //                     textDecoration: "underline",
  //                     display: "inline-block",
  //                     textDecorationColor: "#453C68",
  //                     textUnderlineOffset: "3px",
  //                     textDecorationThickness: "2px",
  //                   }}
  //                 >
  //                   AMO
  //                 </span>
  //                 UNT INVESTED
  //               </Typography>
  //               <Typography
  //                 variant="body1"
  //                 style={{ fontWeight: 900, fontSize: "16px" }}
  //               >
  //                 2.04 Cr
  //               </Typography>
  //             </TableCell>
  //           )}
  //         </TableRow>
  //       </TableHead>
  //       <TableBody>
  //         <TableRow>
  //           <TableCell
  //             component="th"
  //             scope="row"
  //             style={{ fontWeight: 900, fontSize: "16px" }}
  //           >
  //             Jan-Mar
  //           </TableCell>
  //           <TableCell
  //             style={{
  //               background: "#97B2D5",
  //               padding: "24px",
  //               fontWeight: 900,
  //               color: "white",
  //               fontSize: "20px",
  //             }}
  //             component="th"
  //             scope="row"
  //           >
  //             20%
  //           </TableCell>
  //           <TableCell
  //             style={{
  //               background: "#00838F",
  //               fontWeight: 900,
  //               color: "white",
  //               fontSize: "20px",
  //             }}
  //             component="th"
  //             scope="row"
  //           >
  //             20%
  //           </TableCell>
  //           <TableCell
  //             style={{
  //               background: "#ADC371",
  //               fontWeight: 900,
  //               color: "white",
  //               fontSize: "20px",
  //             }}
  //             component="th"
  //             scope="row"
  //           >
  //             20%
  //           </TableCell>
  //           {tableCell || (
  //             <TableCell
  //               style={{
  //                 background: "#49618D",
  //                 fontWeight: 900,
  //                 color: "white",
  //                 fontSize: "20px",
  //               }}
  //               component="th"
  //               scope="row"
  //             >
  //               20%
  //             </TableCell>
  //           )}
  //         </TableRow>
  //       </TableBody>
  //     </Table>
  //   );
  // };
  // const table2 = () => {
  //   return (
  //     <Table aria-label="simple table">
  //       <TableHead>
  //         <TableRow>
  //           <TableCell></TableCell>
  //           <TableCell align="left">
  //             <Typography variant="subtitle1">
  //               {" "}
  //               <span
  //                 style={{
  //                   textDecoration: "underline",
  //                   display: "inline-block",
  //                   textDecorationColor: "#4B64ED",
  //                   textUnderlineOffset: "3px",
  //                   textDecorationThickness: "2px",
  //                 }}
  //               >
  //                 AMO
  //               </span>
  //               UNT INVESTED
  //             </Typography>
  //             <Typography
  //               variant="body1"
  //               style={{ fontWeight: 900, fontSize: "16px" }}
  //             >
  //               2.04 Cr
  //             </Typography>
  //           </TableCell>
  //           <TableCell align="left">
  //             <Typography variant="subtitle1">
  //               <span
  //                 style={{
  //                   textDecoration: "underline",
  //                   display: "inline-block",
  //                   textDecorationColor: "#FAC26D",
  //                   textUnderlineOffset: "3px",
  //                   textDecorationThickness: "2px",
  //                 }}
  //               >
  //                 AMO
  //               </span>
  //               UNT INVESTED
  //             </Typography>
  //             <Typography
  //               variant="body1"
  //               style={{ fontWeight: 900, fontSize: "16px" }}
  //             >
  //               2.04 Cr
  //             </Typography>
  //           </TableCell>
  //           <TableCell align="left">
  //             <Typography variant="subtitle1">
  //               <span
  //                 style={{
  //                   textDecoration: "underline",
  //                   display: "inline-block",
  //                   textDecorationColor: "#0EB9D0",
  //                   textUnderlineOffset: "3px",
  //                   textDecorationThickness: "2px",
  //                 }}
  //               >
  //                 AMO
  //               </span>
  //               UNT INVESTED
  //             </Typography>
  //             <Typography
  //               variant="body1"
  //               style={{ fontWeight: 900, fontSize: "16px" }}
  //             >
  //               2.04 Cr
  //             </Typography>
  //           </TableCell>
  //           {tableCell || (
  //             <TableCell align="left">
  //               <Typography variant="subtitle1">
  //                 <span
  //                   style={{
  //                     textDecoration: "underline",
  //                     display: "inline-block",
  //                     textDecorationColor: "#453C68",
  //                     textUnderlineOffset: "3px",
  //                     textDecorationThickness: "2px",
  //                   }}
  //                 >
  //                   AMO
  //                 </span>
  //                 UNT INVESTED
  //               </Typography>
  //               <Typography
  //                 variant="body1"
  //                 style={{ fontWeight: 900, fontSize: "16px" }}
  //               >
  //                 2.04 Cr
  //               </Typography>
  //             </TableCell>
  //           )}
  //         </TableRow>
  //       </TableHead>
  //       <TableBody>
  //         <TableRow>
  //           <TableCell
  //             component="th"
  //             scope="row"
  //             style={{ fontWeight: 900, fontSize: "16px" }}
  //           >
  //             Apr-Jun
  //           </TableCell>
  //           <TableCell
  //             style={{
  //               background: "#97B2D5",
  //               padding: "24px",
  //               fontWeight: 900,
  //               color: "white",
  //               fontSize: "20px",
  //             }}
  //             component="th"
  //             scope="row"
  //           >
  //             20%
  //           </TableCell>
  //           <TableCell
  //             style={{
  //               background: "#00838F",
  //               fontWeight: 900,
  //               color: "white",
  //               fontSize: "20px",
  //             }}
  //             component="th"
  //             scope="row"
  //           >
  //             20%
  //           </TableCell>
  //           <TableCell
  //             style={{
  //               background: "#ADC371",
  //               fontWeight: 900,
  //               color: "white",
  //               fontSize: "20px",
  //             }}
  //             component="th"
  //             scope="row"
  //           >
  //             20%
  //           </TableCell>
  //           {tableCell || (
  //             <TableCell
  //               style={{
  //                 background: "#49618D",
  //                 fontWeight: 900,
  //                 color: "white",
  //                 fontSize: "20px",
  //               }}
  //               component="th"
  //               scope="row"
  //             >
  //               20%
  //             </TableCell>
  //           )}
  //         </TableRow>
  //       </TableBody>
  //     </Table>
  //   );
  // };

  // const table3 = () => {
  //   return (
  //     <Table aria-label="simple table">
  //       <TableHead>
  //         <TableRow>
  //           <TableCell></TableCell>
  //           <TableCell align="left">
  //             <Typography variant="subtitle1">
  //               {" "}
  //               <span
  //                 style={{
  //                   textDecoration: "underline",
  //                   display: "inline-block",
  //                   textDecorationColor: "#4B64ED",
  //                   textUnderlineOffset: "3px",
  //                   textDecorationThickness: "2px",
  //                 }}
  //               >
  //                 AMO
  //               </span>
  //               UNT INVESTED
  //             </Typography>
  //             <Typography
  //               variant="body1"
  //               style={{ fontWeight: 900, fontSize: "16px" }}
  //             >
  //               2.04 Cr
  //             </Typography>
  //           </TableCell>
  //           <TableCell align="left">
  //             <Typography variant="subtitle1">
  //               <span
  //                 style={{
  //                   textDecoration: "underline",
  //                   display: "inline-block",
  //                   textDecorationColor: "#FAC26D",
  //                   textUnderlineOffset: "3px",
  //                   textDecorationThickness: "2px",
  //                 }}
  //               >
  //                 AMO
  //               </span>
  //               UNT INVESTED
  //             </Typography>
  //             <Typography
  //               variant="body1"
  //               style={{ fontWeight: 900, fontSize: "16px" }}
  //             >
  //               2.04 Cr
  //             </Typography>
  //           </TableCell>
  //           <TableCell align="left">
  //             <Typography variant="subtitle1">
  //               <span
  //                 style={{
  //                   textDecoration: "underline",
  //                   display: "inline-block",
  //                   textDecorationColor: "#0EB9D0",
  //                   textUnderlineOffset: "3px",
  //                   textDecorationThickness: "2px",
  //                 }}
  //               >
  //                 AMO
  //               </span>
  //               UNT INVESTED
  //             </Typography>
  //             <Typography
  //               variant="body1"
  //               style={{ fontWeight: 900, fontSize: "16px" }}
  //             >
  //               2.04 Cr
  //             </Typography>
  //           </TableCell>
  //           {tableCell || (
  //             <TableCell align="left">
  //               <Typography variant="subtitle1">
  //                 <span
  //                   style={{
  //                     textDecoration: "underline",
  //                     display: "inline-block",
  //                     textDecorationColor: "#453C68",
  //                     textUnderlineOffset: "3px",
  //                     textDecorationThickness: "2px",
  //                   }}
  //                 >
  //                   AMO
  //                 </span>
  //                 UNT INVESTED
  //               </Typography>
  //               <Typography
  //                 variant="body1"
  //                 style={{ fontWeight: 900, fontSize: "16px" }}
  //               >
  //                 2.04 Cr
  //               </Typography>
  //             </TableCell>
  //           )}
  //         </TableRow>
  //       </TableHead>
  //       <TableBody>
  //         <TableRow>
  //           <TableCell
  //             component="th"
  //             scope="row"
  //             style={{ fontWeight: 900, fontSize: "16px" }}
  //           >
  //             Jul-Sep
  //           </TableCell>
  //           <TableCell
  //             style={{
  //               background: "#97B2D5",
  //               padding: "24px",
  //               fontWeight: 900,
  //               color: "white",
  //               fontSize: "20px",
  //             }}
  //             component="th"
  //             scope="row"
  //           >
  //             20%
  //           </TableCell>
  //           <TableCell
  //             style={{
  //               background: "#00838F",
  //               fontWeight: 900,
  //               color: "white",
  //               fontSize: "20px",
  //             }}
  //             component="th"
  //             scope="row"
  //           >
  //             20%
  //           </TableCell>
  //           <TableCell
  //             style={{
  //               background: "#ADC371",
  //               fontWeight: 900,
  //               color: "white",
  //               fontSize: "20px",
  //             }}
  //             component="th"
  //             scope="row"
  //           >
  //             20%
  //           </TableCell>
  //           {tableCell || (
  //             <TableCell
  //               style={{
  //                 background: "#49618D",
  //                 fontWeight: 900,
  //                 color: "white",
  //                 fontSize: "20px",
  //               }}
  //               component="th"
  //               scope="row"
  //             >
  //               20%
  //             </TableCell>
  //           )}
  //         </TableRow>
  //       </TableBody>
  //     </Table>
  //   );
  // };
  let obj = [0.1, 95, 0, 0];
  let total = 0;
  let final = obj.map((o) => {
    total = total + o + 5;
    return o + 5;
  });

  const colors = ["#97B2D5", "#00838f", "#ADC371", "#49618D"];
  let percentage = final.map((f) => {});
  const table4 = () => {
    return (
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="left">
              <Typography variant="subtitle1">
                {" "}
                <span
                  style={{
                    textDecoration: "underline",
                    display: "inline-block",
                    textDecorationColor: "#4B64ED",
                    textUnderlineOffset: "3px",
                    textDecorationThickness: "2px",
                  }}
                >
                  AMO
                </span>
                UNT INVESTED
              </Typography>
              <Typography
                variant="body1"
                style={{ fontWeight: 900, fontSize: "16px" }}
              >
                2.04 Cr
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="subtitle1">
                <span
                  style={{
                    textDecoration: "underline",
                    display: "inline-block",
                    textDecorationColor: "#FAC26D",
                    textUnderlineOffset: "3px",
                    textDecorationThickness: "2px",
                  }}
                >
                  AMO
                </span>
                UNT INVESTED
              </Typography>
              <Typography
                variant="body1"
                style={{ fontWeight: 900, fontSize: "16px" }}
              >
                2.04 Cr
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="subtitle1">
                <span
                  style={{
                    textDecoration: "underline",
                    display: "inline-block",
                    textDecorationColor: "#0EB9D0",
                    textUnderlineOffset: "3px",
                    textDecorationThickness: "2px",
                  }}
                >
                  AMO
                </span>
                UNT INVESTED
              </Typography>
              <Typography
                variant="body1"
                style={{ fontWeight: 900, fontSize: "16px" }}
              >
                2.04 Cr
              </Typography>
            </TableCell>
            {tableCell || (
              <TableCell align="left">
                <Typography variant="subtitle1">
                  <span
                    style={{
                      textDecoration: "underline",
                      display: "inline-block",
                      textDecorationColor: "#453C68",
                      textUnderlineOffset: "3px",
                      textDecorationThickness: "2px",
                    }}
                  >
                    AMO
                  </span>
                  UNT INVESTED
                </Typography>
                <Typography
                  variant="body1"
                  style={{ fontWeight: 900, fontSize: "16px" }}
                >
                  2.04 Cr
                </Typography>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              component="th"
              scope="row"
              style={{ fontWeight: 900, fontSize: "16px" }}
            >
              Oct-Dec
            </TableCell>
            <TableCell
              style={{
                background: "#97B2D5",
                padding: "24px",
                fontWeight: 900,
                color: "white",
                fontSize: "20px",
              }}
              component="th"
              scope="row"
            >
              20%
            </TableCell>
            <TableCell
              style={{
                background: "#00838F",
                fontWeight: 900,
                color: "white",
                fontSize: "20px",
              }}
              component="th"
              scope="row"
            >
              20%
            </TableCell>
            <TableCell
              style={{
                background: "#ADC371",
                fontWeight: 900,
                color: "white",
                fontSize: "20px",
              }}
              component="th"
              scope="row"
            >
              20%
            </TableCell>
            {tableCell || (
              <TableCell
                style={{
                  background: "#49618D",
                  fontWeight: 900,
                  color: "white",
                  fontSize: "20px",
                }}
                component="th"
                scope="row"
              >
                20%
              </TableCell>
            )}
          </TableRow>
        </TableBody>
      </Table>
    );
  };
  return (
    <div style={{ position: "relative" }}>
      <PageContainer layout_t={2} pageNo={2}>
        <Header
          title={
            title || (
              <>
                Asset <br /> Allocation
                <br></br>
                <div className="sub">Quarter Wise</div>
              </>
            )
          }
          type={2}
          subtext={
            subTitle ||
            `Asset Allocation refers to the Strategy of distributing the Clients Portfolio across asset classed to diversify the client's portfolio.`
          }
          width={"5in"}
        ></Header>
        <div
          className="p_flex"
          style={{
            height: showdate ? "655px" : "745px",
            width: "50%",
            alignItems: "flex-end",
          }}
        >
          <div
            className="h_card "
            style={{
              width: "338px",
              borderRadius: "18px",
              padding: "0px 13px 13px 13px",
              marginBottom: "12%",
              display: "flex",
              alignItems: "flex-start",
              border: "1px solid #5C86B0",
            }}
          >
            <Question height={"120"} width={"49"} />
            <div>
              {bubbleText
                ? bubbleText
                : ` Need to show Disclaimer for Time period on logic of showing Asset
              Allocation on a case to Case Basis`}
            </div>
          </div>
          <div></div>
        </div>
        {showdate && (
          <div
            style={{
              position: "absolute",
              top: "70px",
              left: "90%",
              display: "block",
            }}
          >
            <b className="subtext5">
              {assetData["asOnDate"] &&
                dateFormatCommon(assetData["asOnDate"], "YYYY-MM-DD")}
            </b>
            <p className="duration">Duration</p>
            <div
              className="subtext5"
              style={{ fontSize: "16px", textAlign: "right", marginTop: "0px" }}
            >
              {assetData["duration"] + " Months"}
            </div>
          </div>
        )}
        <div
          className="m_card"
          style={{
            height: showdate ? "766px" : "879px",
            marginBottom: "65px",
            position: " absolute",
            width: "9.25in",
            top: showdate ? "172px" : "77px",
            left: "6in",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ width: "90%" }}>
            <div style={{ padding: "20px" }}>
              {Object.keys(assetData).length > 0 && (
                <Grid container spacing={6}>
                  {Object.keys(assetData || [])
                    ?.splice(0, Object.keys(assetData).length - 2)
                    .map((i, k) => {
                      let total = 0;

                      // assetData[i].forEach((element,k) => {
                      //   if(element.percentageInvested){
                      //     total= total+ parseFloat( roundNumber(element.percentageInvested))
                      //   }else{
                      //     delete assetData[i][k]
                      //   }

                      // });
                      // if(total==0){
                      //    delete assetData[i]
                      //    return (<div style={{display:"none"}}></div>)
                      // }else{
                      //   assetData[i]=assetData[i].filter(item => item !== undefined)
                      // }
                      return (
                        <Grid
                          item
                          key={k}
                          xs={Object.keys(assetData).length - 2 == 2 ? 6 : 12}
                        >
                          <div style={{ display: "block" }}>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection:
                                  Object.keys(assetData).length - 2 < 3
                                    ? "column-reverse"
                                    : "row",
                              }}
                            >
                              <div
                                style={{ width: "100px", position: "relative" }}
                              >
                                <div
                                  style={{
                                    position: "absolute",
                                    marginTop: "40%",
                                    left: "20px",
                                  }}
                                >
                                  <div className="ason"> {ason || ""}</div>
                                  <span className="bold2">{i}</span>
                                </div>
                              </div>
                              <div style={{ width: "90%", display: "flex" }}>
                                {/* {console.log(assetData, "asset data")} */}
                                {isArray(assetData) &&
                                  assetData[i]?.map((f, i) => {
                                    // console.log(legends[f.assetType], "color");
                                    return (
                                      <div
                                        key={i}
                                        style={{
                                          width:
                                            ((f.percentageInvested + 10) /
                                              f.total) *
                                              100 +
                                            "%",
                                          height: "80px",
                                          backgroundColor: legends[
                                            f.assetType || f.product
                                          ]?.color
                                            ? legends[f.assetType || f.product]
                                                .color
                                            : "#1076ba",
                                          position: "relative",
                                        }}
                                      >
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: " 74%",
                                            left: "2%",
                                            fontWeight: "bold",
                                            color: "#ffffff",
                                          }}
                                        >
                                          {f.percentageInvested + "%"}
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        </Grid>
                      );
                    })}
                </Grid>
              )}
            </div>
            {/* <TableContainer component={Paper}> */}
            {/* Table 1 */}
            {/* {table1()} */}
            {/* Table 2 */}
            {/* {table2()} */}
            {/* Table 3 */}
            {/* {table3()} */}
            {/* Table 4 */}
            {/* {table4()} */}
            {/* </TableContainer> */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop:
                  Object.keys(assetData).length - 2 == 2 ? "10%" : "3%",
              }}
            >
              {Object.keys(legends).map((circle) => (
                <Box
                  key={circle}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "10px",
                  }}
                >
                  <Box
                    sx={{
                      width: "20px",
                      height: "20px",
                      borderRadius: "50%",
                      backgroundColor: legends[circle].color,
                      marginRight: "5px",
                    }}
                  />
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "Avenir",
                      fontStyle: "normal",
                      fontWeight: "900",
                      fontSize: "16px",
                      color: "#FFFFFF",
                    }}
                  >
                    {firstLetterCapitalize(circle)}
                  </Typography>
                </Box>
              ))}
            </Box>
          </div>
        </div>
      </PageContainer>
    </div>
  );
}
