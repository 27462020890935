import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import Default from "../components/dashboard/defaultPages/Default";
import HoldingSummaryComp from "../components/dashboard/products/HoldingSummaryComp";
import HoldingReport from "../components/dashboard/products/holding_summary/HoldingReport";
import { mfoFilterContext } from "../mfo_context/Mfo_Context";

function HoldingSummary() {
  // const location = useLocation()
  // const pageName = location?.pathname.split('/')[3]
  const { setEntity } = useContext(mfoFilterContext);
  setEntity("dashboard");

  return (
    <>
      <Default>
        <HoldingSummaryComp></HoldingSummaryComp>
        {/* <HoldingReport pageName={pageName}></HoldingReport> */}
      </Default>
    </>
  );
}

export default HoldingSummary;
