import React, { useContext, useState } from "react";
import PageFilter from "../common/PageFilter";
import AifEquity from "./AifEquity";
import AifTabDashboard from "../../clientDashboard/AifTabDashboard";
import { tableTypeTransaction } from "./AifTable";
import AifHolding from "./AifHolding";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import NewTabComp from "../common/NewTabComp";
import { routes } from "../../../../routes";
import NewtableTopSec from "../../common/NewtableTopSec";
import { assectClassConfigSelection } from "../common/util/common";
import AifDrawdownSummary from "./AifDrawdownSummary";
import { NewFilter } from "../../newFilter/NewFilter";

const data = [
  { page: "equity", title: "Equity" },
  { page: "debt", title: "Debt" },
  { page: "alternate", title: "Alternates" },
];
let assetOrder = ["equity", "debt", "alternate", "cash"];
function AifReport({ pageName }) {
  console.log(pageName, "agjbkj");
  const { getfilter_as_params } = useContext(mfoFilterContext);
  const [page, setPage] = useState(getfilter_as_params().assetClass);
  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState("");
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [offset, setOffset] = useState(0);

  const handleIconClick = () => {
    setOpenModal(!openModal);
  };

  const handleClick = React.useCallback(
    (page) => {
      setPage(() => {
        setOffset(0);
        return page;
      });
    },
    [page]
  );

  const handleChange = (pageName) => {
    switch (pageName) {
      case "holding":
        return (
          <AifHolding
            p={page}
            openModal={openModal}
            setOpenModal={setOpenModal}
            search={search}
            setOffset={setOffset}
            offset={offset}
          />
        );
      case "transaction":
        return (
          <AifEquity
            p={page}
            pageName={pageName}
            openModal={openModal}
            setOpenModal={setOpenModal}
            tableConfig={tableTypeTransaction}
            search={search}
            setOffset={setOffset}
            offset={offset}
          />
        );
      case "drawdown":
        return (
          <AifDrawdownSummary
            p={page}
            openModal={openModal}
            setOpenModal={setOpenModal}
            search={search}
          />
        );
      default:
        return (
          <AifHolding
            p={page}
            openModal={openModal}
            setOpenModal={setOpenModal}
            search={search}
            setOffset={setOffset}
            offset={offset}
          />
        );
    }
  };

  return (
    <>
      <PageFilter
        backLink={routes.aifSummaryPage}
        setFilterModalOpen={setFilterModalOpen}
      ></PageFilter>
      <NewFilter
        filterModalOpen={filterModalOpen}
        setFilterModalOpen={setFilterModalOpen}
        productType="aif"
      />

      <AifTabDashboard
        customHanlde={() => {
          setOffset(0);
        }}
        pageName={pageName}
      />

      <div style={{ height: "auto" }}>
        <NewtableTopSec
          handleIconClick={handleIconClick}
          directPageName={
            pageName === "drawdown"
              ? "Drawdown Summary"
              : pageName === "holding"
              ? "Holding Summary"
              : "Transaction Summary"
          }
          product={"AIF"}
          setSearch={(e) => {
            setOffset(0);
            setSearch(e);
          }}
        />
        <div style={{ margin: "2%" }}>
          <NewTabComp
            data={assectClassConfigSelection({
              assetClassList: getfilter_as_params().assetClass,
              data: data,
            })}
            // customWidth={"fit-content"}
            customHandle={handleClick}
          />
        </div>
        {handleChange(pageName)}
      </div>
    </>
  );
}

export default AifReport;
