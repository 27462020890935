import React from "react";
import { Link } from "react-router-dom";
import useStyles from "./ProductsStyles";
import NewTabComp from "./NewTabComp";
import { routes } from "../../../../routes";

function TabInsuranceMain({ pageName, setFlip }) {
  const classes = useStyles();

  const data = [
    { page: "life", title: "Life", path: routes.insuranceSummaryPage },
    {
      page: "health",
      title: "Health",
      path: routes.insuranceSummaryPage_health,
    },
    {
      page: "general",
      title: "General",
      path: routes.insuranceSummaryPage_general,
    },
  ];

  //   console.log(data.findIndex((val) => val.page == pageName), "aflbja");

  return (
    <>
      <div style={{ margin: "0" }}>
        <NewTabComp
          data={data}
          customSelect={
            data.findIndex((val) => val.page == pageName) == -1
              ? 0
              : data.findIndex((val) => val.page == pageName)
          }
          customWidth={"fit-content"}
        />
        {/* <FileTabComp /> */}
      </div>
    </>
  );
}

export default TabInsuranceMain;
