import React from 'react'
import Default from '../components/dashboard/defaultPages/Default'
import PmsSummaryComp from '../components/dashboard/products/pms/PmsSummaryComp'

function PmsSummary() {

    return (
        <>
            <Default>
              <PmsSummaryComp></PmsSummaryComp>
            </Default>
        </>
    )
}

export default PmsSummary