export const tooltip= (point) => {
    
    return (
        <div
            style={{
               
                padding: '9px 12px',
               color:"#fff",
                background:" #1d0640",
                border: "1px solid #000000",
                borderRadius: "5px",
            }}
        >
            <div>Investment: <b>{point.data["gained > 100$"]}</b></div>
            <div> Redmption: <b>{"test"}</b></div>
            <div> Additional Investment: <b>{point.data["gained <= 100$"]}</b></div>
        </div>
    )
}