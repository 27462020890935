import React from "react";
import NewDialaugeComp from "../../newClientList/NewDialogComp";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AddMemberForm1 from "./AddMemberForm1";
import SetPinForm from "../../NewSignUp/SetPinForm";
import {
  createPartneruser,
  fetchPartneruser,
  updatePartnerRep,
  updatepartneruser,
} from "../../../api";
import { OnboardingContext } from "../../../Context";
function AddPartnerUserFlow({
  openModal,
  setOpenModal,
  selectedData,
  handleClose,
  hideAlert,
  onUpdate,
  partnerId,
}) {
  const { state } = useLocation();
  const [next, setNext] = useState(1); //state?.step ? state?.step :
  const [formData, setFormData] = useState();
  const [apiErrorMessage, setApiErrorMessage] = useState("");

  const { userState } = OnboardingContext();
  const [open, setOpen] = useState(false);

  const clientId = partnerId ? partnerId : userState.loginedUserData.id;

  // useEffect(() => {
  //   if (selectedData) setFormData(selectedData);
  // }, [selectedData]);

  // hideAlert();
  useEffect(() => {
    setApiErrorMessage("");
    if (openModal == false) {
      setOpen(false);

      handleClose();
      return;
    }
    if (selectedData?.userId) {
      fetchPartneruser(clientId, selectedData?.userId)
        .then((res) => {
          console.log(res.data.data.result, "update");
          let rep = [];
          let banker = [];
          let all = [];
          res.data.data.result.partnerUserEntities.map((r) => {
            if (r.representative_type == "BANKER") {
              banker.push({
                full_name: r.partnerRepresentative.full_name,
                id: r.partnerRepresentative.id,
              });
              all.push(r.partnerRepresentative.id);
            } else {
              rep.push({
                full_name: r.partnerRepresentative.full_name,
                id: r.partnerRepresentative.id,
              });
              all.push(r.partnerRepresentative.id);
            }
          });
          res.data.data.result.rep = rep;
          res.data.data.result.banker = banker;
          res.data.data.result.all = all;
          setFormData(res.data.data.result);

          setOpen(true);
        })
        .catch((err) => {
          setOpenModal(false);
          handleClose();
          const customDiv = document.getElementById("customAlert");
          customDiv.style.display = "block";
          customDiv.innerHTML = `<div class="alertText capitalize">
          <span style="margin-right:20px"> <svg height="32" style="overflow:visible;enable-background:new 0 0 32 32" viewBox="0 0 32 32" width="32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><g id="Error_1_"><g id="Error"><circle cx="16" cy="16" id="BG" r="16" style="fill:#D72828;"/><path d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z" id="Exclamatory_x5F_Sign" style="fill:#E6E6E6;"/></g></g></g></svg></span>
          <div style="margin-right:20px;font-size:14px">                         
          ${err.response.data.error.message.replace("Bad request:", "")}
                                    </div>
                                 </div>`;
          hideAlert();
        });
    } else {
      setOpen(true);
    }
  }, [openModal]);

  const handleBack = (step) => {
    if (step >= 1) setNext(step);
    handleNext(-1);
  };

  function updateUser(data, entityMappedTo) {
    console.log("update user");
    updatepartneruser("partnerUser", selectedData?.userId, data)
      .then((res) => {
        let r = false;
        let b = false;
        let removed = [];
        formData.all.map((entity) => {
          r = entityMappedTo.find((r) => {
            if (r == entity) {
              return true;
            }
          });

          if (!r) {
            removed.push(entity);
          }
        });

        if (
          removed.length == 0 &&
          entityMappedTo?.length == formData?.all?.length
        ) {
          setOpenModal(false);
          setFormData({});
          setOpen(false);
          onUpdate();
          handleClose();
          return;
        }

        updatePartnerRep(clientId, selectedData?.userId, {
          entityMappedTo: entityMappedTo,
          entityRemoved: removed,
        }).then((res) => {
          setOpenModal(false);
          setFormData({});
          setOpen(false);
          onUpdate();
          handleClose();
        });
      })
      .catch((err) => {});
  }

  function save(clientId, data) {
    let data1 = {
      name: data.fullName,
      mobile: data.mobile,
      email: data.email,
      roleId: "" + data.userRole,
    };

    if (selectedData?.userId) {
      delete data1.name;
      data1.status = data.status;
      data1.fullName = data.fullName;
      if (selectedData.roleId != data.userRole) {
        data1.newRoleId = "" + data.userRole;
      }

      return updateUser(data1, [
        ...data.rep.map((r) => r.id),
        ...data.bank.map((r) => r.id),
      ]);
    }
    data1.entityMappedTo = [
      ...data.rep.map((r) => r.id),
      ...data.bank.map((r) => r.id),
    ];
    data1.password = parseInt(data.pin);
    createPartneruser(clientId, data1)
      .then((res) => {
        console.log(res);
        setOpenModal(false);
        setOpen(false);
        setFormData({});
        handleClose(false);
        onUpdate();
      })
      .catch((err) => {
        setApiErrorMessage(err?.response?.data?.error?.message);
      });
  }

  const handleNext = (stepAddition, formData1, client) => {
    setNext(next + stepAddition);
    let state = {
      formData: formData1 ? formData1 : formData,
      step: next + stepAddition,
    };
    // navigate("/register1", {
    //   state: { ...state },
    // });
    // console.log(stepAddition, "sgjsbgkjsg");
    setApiErrorMessage("");
  };

  // #TODO API Integration pending

  const chagneInStep = (step) => {
    switch (step) {
      case 1:
        return (
          <AddMemberForm1
            update={selectedData ? true : false}
            formMaxHeight="65vh"
            noStepBottom={true}
            currentStep={next}
            noStep={true}
            formData={formData}
            customHandleSubmit={(data) => {
              save(clientId, data);
              console.log(data, "afjkababjsf");
            }}
            head={{
              main: selectedData ? "Edit Partner User" : "Add Partner User",
            }}
            formWidth={"523px"}
            apiErrorMessage={apiErrorMessage}
            clientId={clientId}
          />
        );
      case 2:
        return (
          <SetPinForm
            formWidth={"523px"}
            handleBack={handleBack}
            handlenext={handleNext}
            userId={formData?.id}
          />
        );

      default:
        return setOpenModal(false);
    }
  };

  return (
    <>
      {/* {open && ( */}
      <NewDialaugeComp
        width={"640px"}
        fullWidth={false}
        open={open}
        handleClose={() => {
          setOpenModal(false);
          setOpen(false);
          setFormData({});
          handleClose();
        }}
      >
        {chagneInStep(next)}
      </NewDialaugeComp>
      {/* )} */}
    </>
  );
}

export default AddPartnerUserFlow;
