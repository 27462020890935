import React from "react";
import { currencyFormat } from "../../../../hooks/constantFunction";
import { roundNumber } from "../../../utils/common";
import { firstLetterCapitalize } from "../../../utils/strHelper";
const getDenomination = localStorage.getItem("denomination");

export default function TabCards({
  data,
  color,
  text,
  type,
  denomination,
  showcolor,
}) {
  const { label, val } = data;

  function getvalue(v, t) {
    if (t == "rs") {
      return currencyFormat(v, undefined, denomination);
    } else {
      return roundNumber(v) + "%";
    }
  }

  return (
    <div
      className="tab_card"
      style={{ borderLeft: `7px solid ${showcolor ? "transparent" : color}` }}
    >
      <div className="bold ptlb-20 filterContainer ">
        {/* {label}{" "} */}
        {firstLetterCapitalize(label)}
        <div
          style={{
            width: "12px",
            height: "12px",
            background: color,
            marginLeft: "12px",
            display: showcolor ? "" : "none",
          }}
        ></div>{" "}
      </div>
      <div className="p_flex">
        {text?.map((t, i) => {
          return (
            <div style={{ marginRight: "18px" }}>
              <div className=" w-50 cardTitle font600">{t ? t : " "}</div>
              <div className="bold">
                {getvalue(val[i] ? val[i] : null, type[i])}
              </div>
            </div>
          );
        })}

        {/* <div>
          <div className=" w-50">{text[1]?text[1]:" "}</div>
          <div className="bold">{getvalue(value[1], type[1])}</div>
        </div>
        <div>
          <div className=" w-50">{text[2]?text[2]:" "}</div>
          <div className="bold">{getvalue(value[1], type[1])}</div>
        </div> */}
      </div>
    </div>
  );
}
