import React, { useRef, useState } from "react";
import { Grid, Box } from "@material-ui/core";
export const ErrorBlock = ({ setShowErrorBlock, text }) => {
  return (
    <Grid container alignItems="center" className="mainContainer">
      <Grid item xs={11}>
        <Box display="flex" alignItems="center" style={{ gap: "1rem" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <rect opacity="0.9" width="32" height="32" rx="4" fill="black" />
            <path
              opacity="0.7"
              d="M15.6667 25.9993C21.0054 25.9993 25.3333 21.6714 25.3333 16.3327C25.3333 10.9939 21.0054 6.66602 15.6667 6.66602C10.3279 6.66602 6 10.9939 6 16.3327C6 21.6714 10.3279 25.9993 15.6667 25.9993Z"
              fill="#CD4141"
            />
            <path
              d="M15.6667 13.555C16.3059 13.555 16.8241 13.0369 16.8241 12.3976C16.8241 11.7584 16.3059 11.2402 15.6667 11.2402C15.0275 11.2402 14.5093 11.7584 14.5093 12.3976C14.5093 13.0369 15.0275 13.555 15.6667 13.555Z"
              fill="white"
            />
            <path
              d="M16.5926 14.9443H14.7407V21.4258H16.5926V14.9443Z"
              fill="white"
            />
          </svg>
          <p>
            Your File Has Been Rejected:{" "}
            <span>
              {text || "Please rectify the below given issues and reupload"}
            </span>
          </p>
        </Box>
      </Grid>
      <Grid item xs={1} style={{ display: "flex", justifyContent: "flex-end" }}>
        <svg
          onClick={() => {
            setShowErrorBlock((prev) => !prev);
          }}
          style={{ cursor: "pointer" }}
          xmlns="http://www.w3.org/2000/svg"
          width="11"
          height="11"
          viewBox="0 0 11 11"
          fill="none"
        >
          <path
            d="M1 9.75195L9.75156 1.00039"
            stroke="white"
            stroke-width="1.75031"
            stroke-linecap="round"
          />
          <path
            d="M9.75146 9.75195L0.999906 1.00039"
            stroke="white"
            stroke-width="1.75031"
            stroke-linecap="round"
          />
        </svg>
      </Grid>
    </Grid>
  );
};
