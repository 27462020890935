import React from "react";
import { useParams } from "react-router-dom";
import Default from "../components/dashboard/defaultPages/Default";
import ICDReport from "../components/dashboard/products/icd/ICDReport";

function ICDDetailes() {
  const { pageName } = useParams();
  return (
    <Default>
      <ICDReport pageName={pageName} />
    </Default>
  );
}

export default ICDDetailes;
