import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { OnboardingContext } from "../Context";
import ls from "localstorage-slim";
import { mfoStaticFilter } from "../components/utils/common";
import { mfoFilterContext } from "../mfo_context/Mfo_Context";
function ProtecatedUrl({ children, returnurl }) {
  const { setMfofilter, mfoFilter } = useContext(mfoFilterContext);
  const { dispatch } = OnboardingContext();
  const location = useLocation();
  const {
    userState: { loginedUserData },
  } = OnboardingContext();
  const token = loginedUserData?.token;
  // TODO uncomment once functioanlity is received
  // const roleType = ls.get("roleType");

  if (!token) {
    return (
      <Navigate
        to="/"
        state={{
          return_url: returnurl ? location.pathname + location.search : null,
        }}
      />
    );
  }
  // TODO uncomment once functioanlity is received
  // else if (allowedUserType && !allowedUserType.includes(roleType)) {
  //   return <Navigate to="/" />;
  // }
  else {
    const expires_at = loginedUserData.expires_at;
    const currentDate = new Date();
    const expires_at_new = new Date(expires_at);

    if (currentDate > expires_at_new) {
      dispatch({
        type: "UPDATE_LOGIN_USERDATA",
        payload: {},
      });
      setMfofilter(mfoStaticFilter);
      localStorage.clear();
      sessionStorage.clear();
      return (
        <Navigate
          to="/"
          state={{
            return_url: returnurl ? location.pathname + location.search : null,
          }}
        />
      );
    } else {
      return children;
    }
  }
}

export default ProtecatedUrl;
