import React from 'react'
import EquityDetails from '../../../../../pages/EquityDetails'
import Default from '../../../defaultPages/Default'
import PageFilter from '../../common/PageFilter'

import EquityIPODetails from './EquityIPODetails'

export default function EquityIPO() {
  return (
    <>
    <Default>
      <PageFilter/>
      <EquityIPODetails/>
    </Default>
    </>
  )
}
