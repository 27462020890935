import React from "react";
import { TableContainer, Typography } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import "../newSummaryDetailsTable/tableSummaryDetails.css";
import ArrowButton from "../arrowButton/ArrowButton";
import { useEffect } from "react";
import { useState } from "react";
import { getColAndProd, prodAndKey } from "../../../utils/common/prodAndColor";
import { colorAndProd } from "../../../utils/constands/prodAndColor";
import { dateFormatCommon2 } from "../../../../../utils/common";
import { ActiveInactiveToggle } from "../../../../common/ActiveInactiveToggle";
// import { ActiveInactiveToggle } from "../.  ./../../common/ActiveInactiveToggle";
// import { useTheme } from "@mui/material";
import { ReactComponent as UpArrow } from "../../../../../../assets/icons/upArrow.svg";
import { ToogleDropDown } from "../../../../common/ToggleDropDown";

function TaxTable({
  data,
  expandTable = {},
  hideNavButtons,
  customColorAndProd,
  customProdAndKey,
  customHandleRow,
  headerData2,
  customHandleData,
  customIcon,
  customStyle,
  onSort, // Function to handle sorting
  sortOrderDetails, // Object containing the current sort field and order
  summaryDetailsBodyMidCellsStyle, // to center algin table body text refer BondsCredit
  summaryDetailsLastCellStyle, // align last cell in tableBody Refer BondsCredit
  alignHeaderColumn = () => {}, // alignHeaderColumn is a function so that we can pass the current header column index to align it as flex-start Refer BondsCredit
  customFontWeight,
  fontSize,
  truncTitle,
}) {
  const [tableData, setTableData] = useState([]);

  const handleRow = (row, headerData) => {
    let newCOl = headerData?.map((col) => {
      if (col?.field == "date") {
        return {
          ...col,
          field: row?.dontShow
            ? col?.field == "title"
              ? row[`${col?.field}`]
              : "--"
            : row[`${col?.field}`] == "Invalid date" ||
              row[`${col?.field}`] == "" ||
              !row[`${col?.field}`]
            ? "--"
            : dateFormatCommon2(row[`${col?.field}`]),
          color2: getColAndProd(
            customColorAndProd ? customColorAndProd : colorAndProd,
            row?.title,
            customProdAndKey ? customProdAndKey : prodAndKey
          ),
          onClick: row?.onClick,
          dontShow: row?.dontShow,
          dontShowIcon: row?.hideNavButtons,
          align: row?.align,
        };
      } else {
        return {
          ...col,
          field: row?.dontShow
            ? col?.field == "title"
              ? row[`${col?.field}`]
              : "--"
            : row[`${col?.field}`] == "Invalid date" ||
              row[`${col?.field}`] == "" ||
              !row[`${col?.field}`]
            ? "--"
            : row[`${col?.field}`],
          color2: getColAndProd(
            customColorAndProd ? customColorAndProd : colorAndProd,
            row?.title,
            customProdAndKey ? customProdAndKey : prodAndKey
          ),
          onClick: row?.onClick,
          dontShow: row?.dontShow,
          align: row?.align,
        };
      }
    });

    return newCOl;
  };

  const handleData = (data, headerData) => {
    let newList = [];
    data?.map((row, i) => {
      if (customHandleRow) {
        newList.push(customHandleRow(row, headerData));
      } else {
        newList.push(handleRow(row, headerData, i));
      }
    });
    setTableData(newList);
  };

  useEffect(() => {
    if (customHandleData) {
      setTableData(customHandleData);
    } else {
      handleData(data, headerData);
    }
  }, [data]);

  const headerData = [
    {
      field: "name",
      title: "INSTRUMENT NAME",
      show: true,
      align: "center",
      col: 2,
      dontShowFirstColBorder: true, // ** NOTE TO REMOVE THE LEFT COLOR BORDER
    },
    {
      field: "isin",
      title: "ISIN",
      show: true,
      align: "center",
      col: 2,
    },
    {
      field: "noOfTransactions",
      title: "NO. OF TRANSACTIONS",
      show: true,
      align: "center",
      col: 2,
    },
    {
      field: "totalLtcg",
      title: "LTCG",
      show: true,
      align: "center",
      col: 1,
    },
    {
      field: "totalStcg",
      title: "STCG",
      show: true,
      align: "center",
      col: 2,
    },
  ];

  const getTablebody = (data) => {
    return data?.map((row, index) => (
      <>
        <TableRow className="top10">
          <TableCell className="top10"></TableCell>
        </TableRow>
        <TableRow key={row?.[0]?.data?.field} className="tableRow">
          {row?.data?.map((column, i) =>
            i == 0 ? (
              <TableCell
                // key={i}
                style={{
                  backgroundColor: "#19122C",

                  // theme.palette.mode == "light"
                  //   ? theme.palette.primary.dark
                  //   : theme.palette.primary.light,
                }}
                className={`firstCellWrapper summaryDetailsBody ${column?.customCellClassName}`}
              >
                {column?.dontShow && <div className="disabledLayer"></div>}
                {!column?.dontShowFirstColBorder && (
                  <div
                    style={{
                      backgroundColor: column?.color2 ? column?.color2 : "",
                    }}
                    className="stColor"
                  ></div>
                )}
                <div
                  className={`firstCell1 ${column?.customCellClassName}`}
                  style={{
                    backgroundColor: "#19122C",
                    width: "90%",
                    //   theme.palette.mode == "light"
                    //     ? theme.palette.primary.dark
                    //     : theme.palette.primary.light,
                  }}
                >
                  {column?.pic && <div className="tableImage"></div>}
                  <Tooltip title={column?.field} placement="bottom">
                    <span
                      className={`${
                        column?.tagPresent === 1 && index === 0
                          ? "colValue"
                          : ""
                      } ${column?.field?.length > 20 ? "truncate" : ""} ${
                        column?.customClass
                      }`}
                      title={column?.field}
                    >
                      <Typography
                        style={{
                          fontWeight:
                            customFontWeight || column?.fontWeight
                              ? customFontWeight
                              : "400",
                          cursor: column?.onClick ? "pointer" : "default",
                          fontSize: fontSize && "12px !important",
                        }}
                        // handler Function
                        onClick={column?.onClick}
                        className={` truncate ${
                          column?.spclFont ? column?.spclFont : ""
                        } capitalize ${
                          fontSize ? "cellFont2" : "cellFont"
                        } ${""} ${column?.customFontClassName}`}
                      >
                        {column?.field?.length > 20 && truncTitle
                          ? column?.field?.substr(0, 20) + "..."
                          : column?.field}
                      </Typography>
                    </span>
                  </Tooltip>
                </div>
              </TableCell>
            ) : row?.data?.length - 1 == i ? (
              <TableCell
                // key={i}
                style={{
                  backgroundColor: "#19122C",
                  // theme.palette.mode == "light"
                  //   ? theme.palette.primary.dark
                  //   : theme.palette.primary.light,
                  width: column?.width ? column?.width : "auto",
                  ...summaryDetailsLastCellStyle,
                }}
                className="lastCellWrapper"
              >
                {console.log(column, "col")}
                {column?.dontShow && (
                  <div style={{}} className="disabledLayer"></div>
                )}
                <div
                  className={`lastCell dFlex jtfyContSpcBtwn alignCntr ${column?.customCellClassName}`}
                >
                  {column?.title === "Status" ? (
                    <div style={{ marginTop: "6px" }}>
                      {console.log("zustom toggle", column)}
                      <ToogleDropDown
                        disabled={column?.disabled}
                        key={column?.field}
                        toggleIndex={index}
                        isActive={column?.field}
                        handleChange={column?.handleTogle}
                      />
                    </div>
                  ) : (
                    <Typography
                      variant={column?.fontVarient ? column?.fontVarient : "h6"}
                      style={{
                        fontWeight: column?.fontWeight
                          ? column?.fontWeight
                          : "400",
                      }}
                      className={`${
                        column?.spclFont ? column?.spclFont : ""
                      } capitalize cellFont ${column?.customFontClassName}`}
                    >
                      {column?.field}
                    </Typography>
                  )}

                  {!hideNavButtons ? (
                    !column?.dontShowIcon ? (
                      customIcon ? (
                        <div onClick={column?.onClick}>{customIcon}</div>
                      ) : (
                        <ArrowButton
                          onClick={column?.onClick}
                          bgColor={column?.color2}
                          right={true}
                        />
                      )
                    ) : null
                  ) : null}
                </div>
              </TableCell>
            ) : (
              <TableCell
                style={{
                  backgroundColor: "#19122C",
                  ...summaryDetailsBodyMidCellsStyle,
                }}
                className={`summaryDetailsBody ${column?.customCellClassName}`}
                {...(column?.customIcon ? "" : { onClick: column.onClick })}
                // key={i}
              >
                {column?.dontShow && <div className="disabledLayer"></div>}
                <Typography
                  //   variant={column?.fontVarient ? column?.fontVarient : "h6"}
                  style={{
                    fontWeight: column?.fontWeight ? column?.fontWeight : "400",
                    cursor: column?.onClick ? "pointer" : "default",
                    fontSize: fontSize && "12px !important",
                  }}
                  className={`${
                    column?.spclFont ? column?.spclFont : ""
                  } capitalize cellFont ${""} ${column?.customFontClassName}`}
                >
                  {column?.field}
                </Typography>
                {column?.customIcon && (
                  <div className="visibilityContainer" onClick={column.onClick}>
                    {column?.customIcon}
                  </div>
                )}
              </TableCell>
            )
          )}
        </TableRow>
        <TableRow>
          <TableCell className="top10" colSpan={9}>
            <div
              className="innerth"
              style={{
                // height: expandTable[row.isin] ? "200px" : "0px",
                width: "100%",
                overflowY: "auto",
              }}
            >
              {/* {console.log(expandTable, row.isin, "expand")} */}
              {expandTable[row.isin] && (
                <TableContainer
                  className="summaryTable "
                  style={{
                    maxHeight: expandTable[row.isin] ? "200px" : "0px",
                    overflowX: "hidden",
                  }}
                >
                  <Table aria-label="sticky table">
                    <TableHead
                      style={{ position: "sticky", top: "0", zIndex: "1" }}
                    >
                      <TableRow>
                        {getTableheader(headerData2, {
                          justifyContent: "center",
                          display: "flex",
                        })}
                      </TableRow>
                    </TableHead>
                    {expandTable[row.isin]?.length > 0 && (
                      <TableBody
                        className={customStyle?.bodyStyle?.className || ""}
                      >
                        {getTablebody2(expandTable[row.isin])}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              )}
            </div>
          </TableCell>
        </TableRow>
      </>
    ));
  };
  const getTablebody2 = (data1) => {
    console.log(data1, "log data");
    return data1?.map((row, index) => (
      <>
        <TableRow className="top10">
          <TableCell className="top10"></TableCell>
        </TableRow>
        <TableRow key={row?.[0]?.data?.field} className="tableRow">
          {row?.data?.map((column, i) =>
            i == 0 ? (
              <TableCell
                // key={i}
                style={{
                  backgroundColor: "#130d23",
                  // theme.palette.mode == "light"
                  //   ? theme.palette.primary.dark
                  //   : theme.palette.primary.light,
                }}
                className={`firstCellWrapper  ${column?.customCellClassName}`}
              >
                {column?.dontShow && <div className="disabledLayer"></div>}
                {!column?.dontShowFirstColBorder && (
                  <div
                    style={{
                      backgroundColor: column?.color2 ? column?.color2 : "",
                    }}
                    className="stColor"
                  ></div>
                )}
                <div
                  className={`firstCell2 ${column?.customCellClassName}`}
                  style={{
                    backgroundColor: "#130d23",
                    width: "100%",
                    display: "flex",
                    justifyContent: column.align,
                    paddingLeft: column?.align == "left" ? "" : "0px",

                    //   theme.palette.mode == "light"
                    //     ? theme.palette.primary.dark
                    //     : theme.palette.primary.light,
                  }}
                >
                  {column?.pic && <div className="tableImage"></div>}
                  <Tooltip title={column?.field} placement="bottom">
                    <span
                      className={`${
                        column?.tagPresent === 1 && index === 0
                          ? "colValue"
                          : ""
                      } ${column?.field?.length > 20 ? "truncate" : ""} ${
                        column?.customClass
                      }`}
                      title={column?.field}
                    >
                      <Typography
                        style={{
                          fontWeight:
                            customFontWeight || column?.fontWeight
                              ? customFontWeight
                              : "400",
                          cursor: column?.onClick ? "pointer" : "default",
                          fontSize: fontSize && "12px !important",
                          display: "flex",
                          justifyContent: "center",
                        }}
                        // handler Function
                        onClick={column?.onClick}
                        className={`${
                          column?.spclFont ? column?.spclFont : ""
                        } capitalize ${fontSize ? "cellFont2" : "cellFont"}
                        textAlign:"center",
                        
                        ${""} ${column?.customFontClassName}`}
                      >
                        {column?.field?.length > 20 && truncTitle
                          ? column?.field?.substr(0, 20) + "..."
                          : column?.field}
                      </Typography>
                    </span>
                  </Tooltip>
                </div>
              </TableCell>
            ) : row?.data?.length - 1 == i ? (
              <TableCell
                // key={i}
                style={{
                  backgroundColor: "#130d23",
                  // theme.palette.mode == "light"
                  //   ? theme.palette.primary.dark
                  //   : theme.palette.primary.light,
                  width: column?.width ? column?.width : "auto",
                  ...summaryDetailsLastCellStyle,
                }}
                className="lastInnerCellWrapper"
              >
                {console.log(column, "col")}
                {column?.dontShow && (
                  <div style={{}} className="disabledLayer"></div>
                )}
                <div
                  className={`lastCell dFlex jtfyContSpcBtwn alignCntr ${column?.customCellClassName}`}
                >
                  {column?.title === "Status" ? (
                    <div
                      style={{
                        marginTop: "6px",
                      }}
                    >
                      {console.log("zustom toggle", column)}
                      <ToogleDropDown
                        disabled={column?.disabled}
                        key={column?.field}
                        toggleIndex={index}
                        isActive={column?.field}
                        handleChange={column?.handleTogle}
                      />
                    </div>
                  ) : (
                    <Typography
                      variant={column?.fontVarient ? column?.fontVarient : "h6"}
                      style={{
                        fontWeight: column?.fontWeight
                          ? column?.fontWeight
                          : "400",

                        width: "100%",
                        display: "flex",
                        justifyContent: column.align,
                      }}
                      className={`${
                        column?.spclFont ? column?.spclFont : ""
                      } capitalize cellFont ${column?.customFontClassName}`}
                    >
                      {column?.field}
                    </Typography>
                  )}

                  {!hideNavButtons ? (
                    !column?.dontShowIcon ? (
                      customIcon ? (
                        <div onClick={column?.onClick}>{customIcon}</div>
                      ) : (
                        <ArrowButton
                          onClick={column?.onClick}
                          bgColor={column?.color2}
                          right={true}
                        />
                      )
                    ) : null
                  ) : null}
                </div>
              </TableCell>
            ) : (
              <TableCell
                style={{
                  backgroundColor: "#130d23",
                  ...summaryDetailsBodyMidCellsStyle,
                }}
                className={`innersummaryDetailsBody ${column?.customCellClassName}`}
                {...(column?.customIcon ? "" : { onClick: column.onClick })}
                // key={i}
              >
                {column?.dontShow && <div className="disabledLayer"></div>}
                <Typography
                  //   variant={column?.fontVarient ? column?.fontVarient : "h6"}
                  style={{
                    fontWeight: column?.fontWeight ? column?.fontWeight : "400",
                    cursor: column?.onClick ? "pointer" : "default",
                    fontSize: fontSize && "12px !important",
                    display: "flex",
                    justifyContent: column.align,
                  }}
                  className={`${
                    column?.spclFont ? column?.spclFont : ""
                  } capitalize cellFont ${""} ${column?.customFontClassName}`}
                >
                  {column?.field}
                </Typography>
                {column?.customIcon && (
                  <div className="visibilityContainer" onClick={column.onClick}>
                    {column?.customIcon}
                  </div>
                )}
              </TableCell>
            )
          )}
        </TableRow>
      </>
    ));
  };

  // const getTableheader = (data) => {
  //   return data.map((header, index) => {
  //     return (
  //       <TableCell
  //         // align={header?.align ? header?.align : "left"}
  //         className={`summaryDetailsHeader ${
  //           index == 0 ? "summaryDetailsHeader" + index : ""
  //         } ${customStyle?.headerStyle?.className}`}
  //       >
  //         <Typography
  //           // variant="body1"
  //           className={"headerTxt"}
  //         >
  //           {header?.title}
  //         </Typography>
  //       </TableCell>
  //     );
  //   });
  // };

  const getTableheader = (data, style = {}) => {
    return data.map((header, index) => (
      <TableCell
        key={index}
        className={`summaryDetailsHeader ${
          index === 0 ? "summaryDetailsHeader" + index : ""
        } ${customStyle?.headerStyle?.className}`}
        style={{ background: header?.bgColor }}
      >
        <div
          style={{
            ...alignHeaderColumn(index),
            ...style,
          }}
        >
          <Typography className={"headerTxt"}>{header.title}</Typography>
          {header.sortable && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "5px",
                gap: "0.4rem",
              }}
            >
              <UpArrow
                onClick={() => onSort(header.field, true)}
                style={{
                  padding: "0",
                  cursor: "pointer",
                  ...(sortOrderDetails.field === header.field &&
                  sortOrderDetails.order === true
                    ? { fill: "green" }
                    : {}),
                }}
              />
              <UpArrow
                onClick={() => onSort(header.field, false)}
                style={{
                  cursor: "pointer",
                  transform: "rotate(180deg)",
                  ...(sortOrderDetails.field === header.field &&
                  sortOrderDetails.order === false
                    ? { fill: "green" }
                    : {}),
                }}
              />
            </div>
          )}
        </div>
      </TableCell>
    ));
  };
  return (
    <>
      {/* {isMobile ? <MobileCards /> : */}

      <div
        style={{
          minHeight: "372px",
          width: "-webkit-fill-available",
          // width: "50%",
          // margin: "2%",
          maxHeight: "450px",
          display: "flex",
          position: "relative",
        }}
      >
        {tableData?.length > 0 && (
          <TableContainer className="summaryTable odd">
            <Table aria-label="sticky table">
              <TableHead>
                <TableRow>{getTableheader(headerData)}</TableRow>
              </TableHead>

              <TableBody className={customStyle?.bodyStyle?.className || ""}>
                {getTablebody(data)}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>

      {/* } */}
    </>
  );
}

export default TaxTable;
