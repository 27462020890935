import React, { useState } from "react";
import UserManagementTableWrapper from "../UserManagementTableWrapper";
import {
  bodyData3,
  headerData,
  headerDataPartner,
  headerDataPartner2,
} from "../utils/constands";
import { useEffect } from "react";
import { ReactComponent as EditIcon } from "../../../assets/icons/EditIcon.svg";
import AddUserFlow from "../AddUserFlow/AddUserFlow";
import { useLocation, useNavigate } from "react-router-dom";
import { Add } from "@material-ui/icons";
import AddPartnerUserFlow from "../PartnerUserFlow/AddPartnerUserFlow";
import AddPartnerRepresentativeFlow from "./AddPartnerRepresentativeFlow";
import AddButton from "../../common/AddButton";
import { useListing } from "../../../hooks/useListing";
import useUpdateUsers from "../../../hooks/useUpdateUsers";
import PageLoader from "../../dashboard/products/common/pageLoader";
import { handleRoleId } from "../utils/cmmon";
import { partnerRepRoles } from "../../../Constants";
import ls from "localstorage-slim";
import { PermissionsGate } from "../../auth/PermissionGate";

function NewPartnerUserManagement({ partnerId, customTitle, addBtnTitle }) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [openModalRep, setOpenModalRep] = useState(false);
  const [header, setHeader] = useState([]);
  const [data, setData] = useState([]);
  const [selectedUserData, setSelecteduserData] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [offset, setItemOffset] = useState(0);
  const { listingData, getData, totalPage, loading } = useListing(
    searchTerm,
    "partner",
    offset,
    state?.data?.id
  );
  const scopes = JSON.parse(ls.get("scopes"));

  // console.log(selectedUserData, "aglakgbj");

  const { updateUsers, load } = useUpdateUsers({});

  const handleHeader = (headerData) => {
    headerData?.map((val) => {
      if (val?.field == "status") {
        val.onClick = (data) => {
          //here we will get data for edit modal
          console.log(data, "agklangj");
          setSelecteduserData(() => data);
        };
        val.handleTogle = ({ rowData, toglge }) => {
          let payload = {
            status: toglge == "false" ? 0 : 1,
            roleId: rowData.partnerRoleId
              ? `${rowData.partnerRoleId}`
              : `${handleRoleId(rowData?.partnerRole, partnerRepRoles)}`,
          };
          updateUsers({
            data: payload,
            id: rowData?.userId,
            type: "partnerUser",
          });
          // console.log(rowData, toglge, "agjkbahj");
        };
      }
    });
    setHeader(() => headerData);
  };

  const hideAlert = () => {
    setTimeout(() => {
      const customDiv = document.getElementById("customAlert");
      customDiv.style.display = "none";
    }, 2000);
  };

  useEffect(() => {
    if ([1, 3].includes(selectedUserData?.partnerRoleId)) {
      // console.log("agklangj 1");
      //
      setOpenModalRep(true);
    } else if ([2, 4, 5].includes(selectedUserData?.partnerRoleId)) {
      setEditModal(true);
    }
  }, [selectedUserData]);

  useEffect(() => {
    setData(() => listingData);
  }, [listingData]);

  useEffect(() => {
    handleHeader(headerDataPartner2);
    return () => {
      setData([]);
      setHeader([]);
    };
  }, []);

  const handleAddUserClose = () => {
    setSelecteduserData(() => {});
  };

  const handleOpenAddRepModal = () => {
    setOpenModalRep(true);
  };

  return (
    <>
      {/* {header?.length > 0 && data?.length > 0 && ( */}
      <UserManagementTableWrapper
        customScopes={[scopes?.can_add_partner_representative]}
        customTitle={customTitle || "User Management"}
        addBtnTitle="Add Partner User"
        headerData={header}
        data={listingData}
        totalPage={totalPage}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        listingData={listingData}
        openModal={openModal}
        setOpenModal={setOpenModal}
        setItemOffset={setItemOffset}
        offset={offset}
        customIcon={
          <EditIcon
            style={{ marginRight: "38px", cursor: "pointer" }}
            // onClick={(data) => {
            //   console.log(data, "agklangj");
            // }}
          />
        }
        addRepComp={
          <PermissionsGate scopes={[scopes?.can_add_partner_representative]}>
            <AddButton
              handleIconClick={handleOpenAddRepModal}
              addBtnTitle={"Add Partner Representative / Banker"}
            />
          </PermissionsGate>
        }
      />
      {/* )} */}
      {/* {openModal && ( */}
      <AddPartnerUserFlow
        handleClose={handleAddUserClose}
        openModal={openModal}
        setOpenModal={setOpenModal}
        hideAlert={hideAlert}
        onUpdate={() => {
          getData();
        }}
        partnerId={state?.data?.id}
      />
      <AddPartnerUserFlow
        handleClose={handleAddUserClose}
        selectedData={selectedUserData}
        openModal={editModal}
        setOpenModal={setEditModal}
        hideAlert={hideAlert}
        onUpdate={() => {
          getData();
        }}
        partnerId={state?.data?.id}
      />
      {/* // )} */}
      {/* <AddUserFlow
        handleClose={handleAddUserClose}
        selectedData={selectedUserData}
        openModal={openModal}
        setOpenModal={setOpenModal}
      /> */}
      {openModalRep && (
        <AddPartnerRepresentativeFlow
          handleClose={() => {
            handleAddUserClose();
          }}
          onUpdate={() => {
            getData();
          }}
          selectedData={selectedUserData}
          openModal={openModalRep}
          setOpenModal={setOpenModalRep}
          partnerId={state?.data?.id}
        />
      )}
      <PageLoader showLoader={loading || load} />
    </>
  );
}

export default NewPartnerUserManagement;
