import React, { useState, useRef, useEffect } from "react";
import Calendar from "react-calendar";
import moment from "moment/moment";
import "react-calendar/dist/Calendar.css";
import "./custom-calendar.css";
// import Calendericon from '../../assets/partner-images/calendar.svg'
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { CalendarToday } from "@material-ui/icons";
import { InputAdornment, TextField } from "@material-ui/core";
export default function RangeCalendar({ onchange, values }) {
  let calRef = useRef();
  const [date, setDate] = useState([new Date(), new Date()]);
  console.log(date);
  const [open, setOpen] = useState(false);
  const [tempdate, setTempdate] = useState(null);
  useEffect(() => {
    const handle = (e) => {
      if (!calRef.current.contains(e.target)) setOpen(false);
    };
    document.addEventListener("mousedown", handle);
  }, []);
  return (
    <div>
      <IconTextField
        fullWidth
        name="duration"
        label="Duration"
        variant="outlined"
        onClick={() => {
          setOpen(true);
        }}
        value={`${moment(values[0]).format(`MM-YYYY`)} - ${moment(
          values[1]
        ).format(`MM-YYYY`)}`}
        iconEnd={<CalendarToday style={{ color: "#fff" }} />}
      />

      <span> </span>
      <div
        className="calendar-container"
        ref={calRef}
        style={{ display: open ? "block" : "none", width: "309px" }}
      >
        <Calendar
          onChange={(d) => {
            if (d.length < 2) {
              setTempdate(d[0]);
            } else {
              setTempdate(null);
              setDate(d);
            }
          }}
          value={date}
          selectRange={true}
          defaultView="decade"
          allowPartialRange={true}
          minDetail="month"
          maxDetail="year"
          minDate={
            tempdate
              ? new Date(
                  moment(tempdate).subtract(11, "months").format("YYYY-MM-DD")
                )
              : null
          }
          maxDate={
            tempdate
              ? new Date(
                  moment(tempdate).add(11, "months").isAfter()
                    ? moment().format("YYYY-MM-DD")
                    : moment(tempdate).add(11, "months").format("YYYY-MM-DD")
                )
              : new Date(moment().format("YYYY-MM-DD"))
          }
        />
        {date.length > 0 ? (
          <div class="datedisplay">
            <p className="text-center">
              {" "}
              {moment(date[0]).format(`MMM-YYYY`)}
              &nbsp; - &nbsp;
              {moment(date[1]).format(`MMM-YYYY`)}
              <button
                type="button"
                className="donebtn"
                onClick={() => {
                  onchange(date);
                  setOpen(false);
                }}
              >
                {" "}
                Done
              </button>
            </p>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

const IconTextField = ({ iconStart, iconEnd, InputProps, ...props }) => {
  return (
    <TextField
      {...props}
      InputProps={{
        ...InputProps,
        startAdornment: iconStart ? (
          <InputAdornment position="start">{iconStart}</InputAdornment>
        ) : null,
        endAdornment: iconEnd ? (
          <InputAdornment position="end">{iconEnd}</InputAdornment>
        ) : null,
      }}
    />
  );
};
