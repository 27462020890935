import React from "react";
import { StackTable } from "./StackTable";
import { useEffect } from "react";
import { useState } from "react";

export const AssetSubClass = ({ data }) => {
  const [assetData, setAssetData] = useState({});
  useEffect(() => {
    if (!data) {
      return;
    }
    setAssetData(data?.["alternate-subclasses-investment"]);
  }, [data]);

  const title = (
    <>
      Alternates <br />
      Sub Class
    </>
  );
  const assetSubClassLegends = [
    { name: "Gold", backgroundColor: "#97B2D5" },
    { name: "Private", backgroundColor: "#00838F" },
    { name: "Real Estate", backgroundColor: "#ADC371" },
  ];
  return (
    <>
      {Object.keys(data["alternate-subclasses-investment"])[0].length > 0 && (
        <StackTable
          title={title}
          ason={"As on"}
          subTitle={
            "This refers to the further sub classification of Alternates into sub categories such as Real Estate, REITS& INVITS, etc."
          }
          bubbleText={
            "Need to show Disclaimer for Time period on logic of showing Asset Allocation on a case to Case Basis"
          }
          assetSubClassLegends={assetSubClassLegends}
          data={assetData}
          dynamic={true}
          reachedData={"reached"}
        />
      )}
    </>
  );
};
