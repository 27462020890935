import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import { myDebounce } from "../../hooks/constantFunction";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: "0.8",
  },
  pseudoSearch: {
    background: "#1F1C40",
    borderRadius: "20px",
    display: "inline",
    border: "1px solid #711CF1",
    padding: "5px 12px",
    marginTop: "10%",
    transition: "background-color 0.5 ease-in-out",
  },
  input: {
    border: "none!important",
    backgroundColor: "transparent",
    width: "200px",
    outline: "none",
    color: "#9488A7!important",
    fontFamily: "Avenir",
  },
  buttonContainer: {
    float: "right",
    display: "flex",
    width: "21%",
    [theme.breakpoints.down("md")]: {
      width: "235px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "235px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "310px",
      marginBottom: "20px",
      marginTop: "20px",
    },
  },
  verticalMiddle: {
    justifyContent: "center",
  },
}));

function SearchHeader({ setSearchName, setItemOffset }) {
  const classes = useStyles();

  const handleSearchChange = (value) => {
    setSearchName(value);
    setItemOffset(0);
  };

  const myDebounceSearch = useCallback(myDebounce(handleSearchChange), []);

  return (
    <div style={{ marginTop: "3%" }}>
      <div className={classes.pseudoSearch}>
        <input
          onChange={(e) => myDebounceSearch(e.target.value)}
          className={classes.input}
          style={{ border: "none" }}
          type="text"
          placeholder="Search  by client"
        />
        <Search style={{ color: "#949BA5", verticalAlign: "middle" }} />
      </div>
    </div>
  );
}

export default SearchHeader;
