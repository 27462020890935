import React from "react";
import { useParams } from "react-router-dom";
import { routes } from "../../../routes";
import TargetPortfolioTable from "./targetPortfolio/TargetPortfolioTable";
import { useState } from "react";
import TopNavWrapper from "./common/TopNavWrapper";
import TopBackComp from "./common/TopBackComp";
import { StrategyBuilderSelection } from "./StrategyBuilderSelection";
import UniqueCLientIndexFlow from "./uniqueCLientIndex/UniqueCLientIndexFlow";
import TargetPortfolioTableFlow from "./targetPortfolio/TargetPortfolioTableFlow";
import Benchmark from "./benchmark/Benchmark";

function StrategyBuilderTable() {
  const { pageName } = useParams();
  const [page, setPage] = useState(pageName);

  const handleTableChange = (table) => {
    switch (table) {
      case "target-portfolio":
        return <TargetPortfolioTableFlow />;
      case "unique-cLient-index":
        return <UniqueCLientIndexFlow />;
        case "benchmark":
          return <Benchmark />;
      default:
        return <TargetPortfolioTableFlow />;
    }
  };

  return <div style={{ margin: "2%" }}>{handleTableChange(pageName)}</div>;
}

export default StrategyBuilderTable;
