import React, { useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Search } from "../../../../assets/icons/search.svg";
import { myDebounce } from "../../../../hooks/constantFunction";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  searchHeaderWrapper: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  pseudoSearch: {
    background: "var(--searchBackground)",
    borderRadius: "20px",
    display: "inline",
    border: "1px solid var(--border)",
    padding: "7px 12px",
    transition: "background-color 0.5 ease-in-out",
  },
  input: {
    border: "none!important",
    backgroundColor: "transparent",
    width: "200px",
    outline: "none",
    color: "#9488A7!important",
    fontFamily: "Avenir",
  },
}));

function SearchInput({
  setSearchName,
  clear,
  setClear,
  placeHolderText,
  customStyle,
  searchVal,
}) {
  const classes = useStyles();
  const [search, setSearch] = useState(null);

  useEffect(() => {
    if (clear) {
      setSearchName("");
      setSearch("");
      setClear(false);
    }
  }, [clear]);
  const handleSearchChange = (value) => {
    setSearchName(value);
  };

  const myDebounceSearch = useCallback(myDebounce(handleSearchChange), []);

  return (
    <div className={classes.searchHeaderWrapper} style={{ ...customStyle }}>
      <div
        className={classes.pseudoSearch}
        style={{ borderRadius: searchVal ? "20px 20px 0px 0px" : "20px" }}
      >
        <input
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            myDebounceSearch(e.target.value);
          }}
          className={classes.input}
          style={{ border: "none" }}
          type="text"
          placeholder={placeHolderText || "Search by name "}
        />
        <Search
          style={{ color: "var(--searchBackground)", verticalAlign: "middle" }}
        />
      </div>
    </div>
  );
}

export default SearchInput;
