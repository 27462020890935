import {
  currencyFormat,
  dateFormat_2,
} from "../../../../../hooks/constantFunction";
import {
  dateFormatCommon,
  dayFormatter,
  dayFormatter2,
  dayFormatterMMMYYYY,
  roundNumber,
} from "../../../../utils/common";
import { handleExitLoad, handleSip } from "./constands";

export const handleData = (data) => {
  return data ? data : "--";
};

export const objectFormating = (data, tableHeader) => {
  let newData = tableHeader.map((header) => {
    if (header?.field == "schemeName") {
      return {
        field: data[`${header.field}`],
        title: header.title,
        show: header.show,
        align: header?.align,
      };
    }
    if (header?.field == "annualisedTwrr") {
      return {
        field: roundNumber(data[`${header.field}`] * 100) || "--",
        title: header.title,
        show: header.show,
        align: header?.align,
      };
    }
    if (header?.field == "avgHoldingPeriod") {
      return {
        field: data[`${header.field}`] + " Days" || "--",
        title: header.title,
        show: header.show,
        align: header?.align,
      };
    }
    if (header?.field == "isin" || header?.field == "folioNo") {
      return {
        field: data[`${header.field}`],
        title: header.title,
        show: header.show,
        align: header?.align,
      };
    }
    if (
      header?.field == "investmentValue" ||
      header?.field == "presentValue" ||
      header?.field == "nav"
    ) {
      let currencyformat = currencyFormat(data[`${header.field}`] || 0);
      if (header?.field == "nav") {
        currencyformat = currencyFormat(data[`${header.field}`] || 0, {
          format: "actuals",
        });
      }

      return {
        field: currencyformat || 0,
        title: header.title,
        show: header.show,
        align: header?.align,
      };
    }
    return {
      field: !isNaN(data[`${header.field}`])
        ? roundNumber(Number(data[`${header.field}`]))
        : data[`${header.field}`] || "--",
      title: header.title,
      show: header.show,
      align: header?.align,
    };
  });
  return newData;
};

export const configuring = (val, newList, riskDetails, type) => {
  if (val.title == "Inception Date") {
    val.value = dayFormatter2(riskDetails[val.filed]);
    newList.push(val);
    return;
  }
  if (val.title == "Bench mark") {
    // let a = riskDetails.primaryProspectusBenchmarks[0]?.IndexName;
    // console.log("Lbvolaewg", a);
    val.value = riskDetails.primaryProspectusBenchmarks[0]?.IndexName;
    newList.push(val);
    return;
  }
  if (val.filed == "totalAssetsInCr" || val.filed == "cashNet") {
    val.value =
      currencyFormat(riskDetails[val.filed], {
        currency: "INR",
        style: "currency",
      }) + "Cr" || "--";
    newList.push(val);
    return;
  }
  if (val.filed == "exitLoad") {
    val.value = handleExitLoad(riskDetails) || "--";
    newList.push(val);
    return;
  }
  if (val.filed == "sip.minimumInvestmentAmount") {
    if (type.filter((val) => val.selected)[0]?.filed == "sip") {
      val.title = "Minimum SIP Amount";
      val.value = handleSip(riskDetails) || "--";
    } else if (type.filter((val) => val.selected)[0]?.filed == "lumpsum") {
      val.title = "Min Investment Amount";
      val.value = riskDetails?.lumpsum?.minimumInvestmentAmount;
    }
    newList.push(val);
    return;
  }

  val.value = riskDetails[val.filed] || "--";
  newList.push(val);
};

export const fundManagerstableObjectFormating = (data, tableHeader) => {
  let newData = tableHeader?.map((header) => {
    if (header?.field == "Tenure") {
      return {
        field: data[`${header.field}`] + " Years",
        title: header.title,
        show: header.show,
        align: header?.align,
      };
    }

    if (header?.field == "Name") {
      return {
        field: data[`${header.field}`],
        title: header.title,
        show: header.show,
        align: header?.align,
        subText: `${
          data?.StartDate ? dayFormatterMMMYYYY(data?.StartDate) : ""
        } - ${data?.EndDate ? dayFormatterMMMYYYY(data?.EndDate) : "Present"}`,
      };
    }
    return {
      field: !isNaN(data[`${header.field}`])
        ? roundNumber(Number(data[`${header.field}`]))
        : data[`${header.field}`] || "--",
      title: header.title,
      show: header.show,
      align: header?.align,
    };
  });

  return newData;
};

const randomColor = () =>
  "#" + Math.floor(Math.random() * 16777215).toString(16);

const getUniqueColor = (colorArray) => {
  let newColor = randomColor();

  if (colorArray.includes(newColor)) {
    getUniqueColor(colorArray);
  } else {
    return newColor;
  }
};

const pieChartDataGenerator = (data, config, colorArray) => {
  let color = getUniqueColor(colorArray);
  let newData = {
    id: data[config?.id],
    label: data[config?.id],
    value: data[config?.value],
    color: color,
  };
  return newData;
};

export const getTableAndPieChartData = ({
  data,
  field,
  tableConfig,
  tableDataFormatter,
  pieChartConfig,
}) => {
  let tableData = [];
  let pieChartData = [];
  let total = 0;

  if (data[field]?.length > 0) {
    data[field].map((val) => {
      tableData.push(tableDataFormatter(val, tableConfig));
      // console.log(val, "afbiaoefiabeg");
      total += Number(val?.value) ? Number(val?.value) : 0;
      if (pieChartConfig) {
        pieChartData.push(
          pieChartDataGenerator(
            val,
            pieChartConfig,
            pieChartData.map((val) => val?.color)
          )
        );
      }
    });
  }

  // console.log(data[`${field}`], "o1y95017932405");
  return { tableData: tableData, pieChartData: pieChartData, total: total };
};
