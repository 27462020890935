import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import headerLogo from "../../../assets/images/neoLogo.png";
import { ReactComponent as BellIcon } from "../../../assets/icons/bell-icon.svg";
import { useNavigate } from "react-router-dom";
import { OnboardingContext } from "../../../Context";
import { useState } from "react";
import { Menu, MenuItem } from "@material-ui/core";
import { NewMenu } from "../../newMenu/NewMenu";
import { useTheme } from "@material-ui/core";
import { ColorModeContext, useMode } from "../../../theme";
import headerLogo from "../../../assets/icons/headerLogo.png";
import { ThemeSwitchDropDown } from "./ThemeSwitchDropDown";
const useStyles = makeStyles((theme) => ({
  logo: {
    width: "55px",
    left: "5px",
    margin: "0px",
    // position: "absolute",
    // height: '125px',
    // top: '-28px'
  },
  profileName: {
    marginLeft: "5px",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    fontFamily: "Avenir",
    lineHeight: "27px",
    color: "#FFFFFF",
  },
  grow: {
    flexGrow: 1,
  },
  avtar: {
    background:
      "radial-gradient(50.03% 50.03% at 49.93% 50.01%, #FFFFFF 10.49%, #A1DEF3 21.46%, rgba(59, 3, 144, 0.7) 46.59%, rgba(52, 0, 130, 0.5) 100%)",
  },
  defaultPage: {
    width: "100%",
    height: "100vh",
    background: "#220848",
  },

  mainContent: {
    border: "1px solid #711CF1",
    height: "91vh",
    minWidth: "96%",
    background: "#16082E",
    boxShadow: "inset 0px 4px 10px #000000",
    borderRadius: "50px",
    borderRight: "none",
    htRadius: "0px",
    borderBottom: "none",
    borderBottomLeftRadius: "0px",
    borderTopRightRadius: "0px",
  },
  headerContent: {
    display: "flex",
    justifyContent: "space-between",
  },
  list: {
    height: "487px !important",
    width: "402px !important",
    border: "none !important",
    background: `${theme.palette.themeSwitcher.bodyBackGround} !important`,
  },
  paper: {
    borderRadius: "1rem !important",
    background: "transparent !important",
  },
}));
function Header() {
  const navigate = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  // const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const {
    userState: { loginedUserData },
  } = OnboardingContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    // So that if gold is selected and logged out then context should reset to default
    // else after login again the mode is still set to previous selected value that is gold
    colorMode.colorMode.toggleColorMode("default");
    localStorage.removeItem("colorMode");
    handleClose();
    navigate("/logout");
  };
  console.log("loginedUserData", loginedUserData);
  return (
    <>
      <Grid item xs={12} className="bg-surface">
        <div className={classes.headerContent}>
          <img
            className={classes.logo}
            src={headerLogo}
            alt="logo"
            style={{
              cursor: "pointer",
              width: "160px",
              height: "72px",
              top: "0px",
              left: "0px",
            }}
            onMouseOver={() => setIsMenuOpen(true)}
            onMouseOut={() => setIsMenuOpen(false)}
          ></img>
          {/* <img
            className={classes.logo}
            src={headerLogo}
            alt="logo"
            style={{
              cursor: "pointer",
              width: "90px",
              top: "0px",
              left: "0px",
            }}
            onMouseOver={() => setIsMenuOpen(true)}
            onMouseOut={() => setIsMenuOpen(false)}
          ></img> */}
          <div className={classes.grow} />
          <div
            style={{
              marginRight: "2%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "#F7F7FF",
                borderRadius: "50%",
                width: "25px",
                height: "25px",
              }}
            >
              <BellIcon style={{ color: "#9B9FAB" }} />
            </div>
            <div style={{ display: "flex", marginLeft: "12px" }}>
              <Avatar style={{ width: "25px", height: "25px" }}>
                {loginedUserData?.name?.charAt(0)}
              </Avatar>
              <Typography className={classes.profileName}>
                {loginedUserData?.name}
              </Typography>
            </div>
            <ExpandMoreIcon
              style={{ cursor: "pointer", color: "#94979E" }}
              onClick={handleMenu}
            />
            <Menu
              classes={{ list: classes.list, paper: classes.paper }}
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              style={{
                // Add here you negative margin
                marginLeft: "-8px",
                marginTop: "50px",
              }}
            >
              {/* <MenuItem
                onClick={handleLogout}
                style={{
                  border: "1px solid red",
                  width: "402px",
                  height: "487px",
                }}
              > */}
              <ThemeSwitchDropDown
                theme={theme}
                navigate={navigate}
                colorMode={colorMode}
                handleLogout={handleLogout}
                loginedUserData={loginedUserData}
              />

              {/* </MenuItem> */}
            </Menu>
          </div>
        </div>
      </Grid>
      {isMenuOpen && <NewMenu setIsMenuOpen={setIsMenuOpen} />}
    </>
  );
}

export default Header;
