import React, { useState } from "react";
import TableSummaryDetails from "../dashboard/products/newDesigns/common/newSummaryDetailsTable/TableSummaryDetails";
import { ActiveInactiveToggle } from "../dashboard/common/ActiveInactiveToggle";
import CustomInput from "../NewSignUp/common/CustomInput";
import {
  Typography,
  Button,
  InputAdornment,
  Tabs,
  Tab,
} from "@material-ui/core";
import { PermissionsGate } from "../auth/PermissionGate";
import SearchIcon from "@material-ui/icons/Search";
import useStyles from "./UserManagementStyles";
import ls from "localstorage-slim";
import { ReactComponent as Add } from "../../assets/icons/add.svg";
import { useNavigate } from "react-router-dom";
import C_Modal from "../dashboard/products/common/modal_add_member/C_Modal";
import PartnerHeading from "../NewSignUp/PartnerHeading";
import Newform from "../dashboard/products/common/modal_add_member/Newform";
import { client_add_members } from "../dashboard/products/common/modal_add_member/Clientforms";
import { useEffect } from "react";
import AppPagination from "../verificationUpload/AppPagination";
import { ITEMS_LIMIT_FILES_PARTNER_LIST } from "../../Constants";
import AddButton from "../common/AddButton";
import AddMemberFlow from "./client_flow/AddMemberFlow";
import { debounce } from "lodash";
import NewTabComp from "../dashboard/products/common/NewTabComp";
import { TableDataNotFound } from "../dashboard/strategyBuilder/common/TableDataNotFound";
import Loader from "../loader/Loader";
import PageLoader from "../dashboard/products/common/pageLoader";
function UserManagementTableWrapper({
  data,
  headerData,
  openModal,
  setOpenModal,
  customIcon,
  customTitle,
  addBtnTitle,
  addRepComp,
  addRepStyle = {},
  listingData,
  setSearchTerm,
  setItemOffset,
  totalPage,
  offset,
  customheader,
  returnno,
  loading,
  customScopes,
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const scopes = JSON.parse(ls.get("scopes"));
  const [customHeader, setCustomHeader] = useState([]);
  const [customData, setCusotmData] = useState([]);
  // const [pageNumber, setPageNumber] = useState(1);
  // const [searchTerm, setSearchTerm] = useState("");
  // const [listingData] = useListing(searchTerm, type, trigerAPI);
  // console.log(data, "member data");

  function handleSearch(e) {
    if (offset > 0) {
      setItemOffset(0);
    }
    // setPageNumber(1);
    // console.log("handleSearch", e.target.value);
    setSearchTerm(e?.target?.value);
  }

  const debouncedSearch = debounce(handleSearch, 500);
  function handleBack() {}

  let header = {
    mainHead: "Add Family Head Details",
    subtitle: "Join us by providing your company details",
    step: 0,
  };
  const arrObj = client_add_members;

  const handleIconClick = () => {
    setOpenModal(!openModal);
  };

  const handleRow = (row, headerData) => {
    console.log(row, "agklagnjk");
    let newCOl = headerData?.map((col) => {
      if (col?.title == "User Role" || col?.title == "Email ID") {
        return {
          ...col,
          field: row[`${col?.field}`] ? row[`${col?.field}`] : "--",
          // color2: "#711CF1",
          // onClick: row?.onClick,
          dontShow: row?.dontShow,
          // customCellClassName: "childCell2",
          customCellClassName: row?.parrent ? "" : "childCellCardView",
          customFontClassName: "fontWeight500",
        };
      }

      if (col?.field == "status" || col?.field == "is_active") {
        return {
          ...col,
          field:
            row[`${col?.field}`] || row[`${col?.field}`] === 0
              ? row[`${col?.field}`]
              : "--",
          onClick: col?.onClick
            ? () => {
                col?.onClick(row);
              }
            : () => {},
          handleTogle: col?.handleTogle
            ? (toggleData) => {
                col?.handleTogle({ rowData: row, toglge: toggleData });
              }
            : () => {},
          disabled: col?.disabled
            ? () => {
                console.log(col?.disabled(row), "aflnk");
                return col?.disabled(row);
              }
            : () => {},
          // : () => {
          //     navigate("/user-management/edit", { state: { data: row } });
          //   }
          dontShow: row?.dontShow,
          dontShowFirstColBorder: row?.parrent ? false : true,
          customCellClassName: row?.parrent ? "" : "childCellCardView",
          customFontClassName:
            row[`${col?.field}`] == "Active" ? "activeClass" : "inActiveClass",
          fontWeight: "600",
        };
      }

      if (
        col?.field == "name" ||
        col?.field == "fullName" ||
        col?.field == "client_full_name"
      ) {
        return {
          ...col,
          field: row[`${col?.field}`] ? row[`${col?.field}`] : "--",
          // color2: "#711CF1",
          onClick: () => {
            col?.onClick(row);
          },
          dontShow: row?.dontShow,
          dontShowFirstColBorder: row?.parrent ? false : true,
          customCellClassName: row?.parrent ? "" : "childCellCardView",
        };
      }
      return {
        ...col,
        field: row[`${col?.field}`] ? row[`${col?.field}`] : "--",
        // color2: "#711CF1",
        onClick: row?.onClick,
        dontShow: row?.dontShow,
        customCellClassName: row?.parrent ? "" : "childCellCardView",
      };
    });
    console.log(newCOl, "algbajgkb565656");
    return newCOl;
  };

  // console.log(customheader, "kjbkjegweg");

  useEffect(() => {
    setCustomHeader(() => headerData);
    setCusotmData(() => data);
    // return () => {
    //   setCustomHeader(() => []);
    //   setCusotmData(() => []);
    // };
  }, [headerData, data]);

  return (
    <div
      className="summaryDetailsHeadSec"
      style={{ marginInline: "2%", marginTop: "0px" }}
    >
      <div className="tableTopSecUser">
        {!customheader && (
          <Typography className={classes.titleText}>
            {customTitle ? customTitle : "Mapped Users"}
          </Typography>
        )}
        {customheader && customheader()}
        <div style={addRepStyle}>
          {addRepComp && addRepComp}
          <PermissionsGate scopes={customScopes}>
            <AddButton
              addBtnTitle={addBtnTitle}
              handleIconClick={handleIconClick}
            />
          </PermissionsGate>
        </div>
      </div>
      <div style={{ marginBottom: "27px", width: "auto" }}>
        <CustomInput
          // value={searchTerm}
          onChange={debouncedSearch}
          customStyle={{
            inputBgColor: "var(--searchBackground)",
            border: "none",
            inputPadding: "16px 2px !important",
          }}
          fullWidth
          label={"Search"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className="inputAdornmentStyle">
                <SearchIcon style={{ opacity: "0.5" }} />
              </InputAdornment>
            ),
          }}
        />
      </div>
      {customHeader?.length > 0 && customData?.length > 0 && (
        <>
          <TableSummaryDetails
            headerData={customHeader}
            data={customData}
            customHandleRow={handleRow}
            customIcon={customIcon}
            // customStyle={{ headerStyle: { className: "customHeader1" } }}
          />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <AppPagination
              pageCount={totalPage}
              pageNumber={offset / ITEMS_LIMIT_FILES_PARTNER_LIST + 1 || 1}
              // setPageNumber={setPageNumber}
              itemLimit={ITEMS_LIMIT_FILES_PARTNER_LIST}
              setItemOffset={setItemOffset}

              // returnno
            />
          </div>
        </>
      )}
      {customData?.length == 0 && (
        <>
          <TableDataNotFound
            tabType={addBtnTitle || "Add Member"}
            addButtonCLick={handleIconClick}
            customScopes={customScopes}
          />
        </>
      )}
      <PageLoader showLoader={loading} />

      {/* {loading && (
        <Loader style={{ marginLeft: "3%", marginTop: "-4.5%" }}></Loader>
      )} */}
    </div>
  );
}

export default UserManagementTableWrapper;
