import React from "react";

import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import AddClientMemberForm1 from "./AddClientMemberForm1";
import NewDialaugeComp from "../../../newClientList/NewDialogComp";
import SetPinForm from "../../../NewSignUp/SetPinForm";
import { createClientMember } from "../../../../api";
import { OnboardingContext } from "../../../../Context";

function AddClientMemberFlow({
  openModal,
  setOpenModal,
  selectedData,
  handleClose,
  id,
  handleRefresh,
}) {
  const { userState } = OnboardingContext();
  const { state } = useLocation();
  const [next, setNext] = useState(1); //state?.step ? state?.step :
  const [formData, setFormData] = useState(selectedData);
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    if (selectedData) setFormData(selectedData);
  }, [selectedData]);

  const handleBack = (step) => {
    if (step >= 1) setNext(step);
    handleNext(-1);
  };

  const handleNext = (stepAddition, formData1, client) => {
    setNext(next + stepAddition);
    let state = {
      formData: formData1 ? formData1 : formData,
      step: next + stepAddition,
    };
    // navigate("/register1", {
    //   state: { ...state },
    // });
    // console.log(stepAddition, "sgjsbgkjsg");
    setApiErrorMessage("");
  };

  async function saveMemberClient(data1) {
    try {
      const data = await createClientMember(
        data1,
        id || state?.clientId || userState.loginedUserData.id
      );
      const memberData = data.data;

      if (memberData.success) {
        setLoader(false);
        setFormData({ id: memberData.data.result.user.id });
        handleNext(1);
      }
    } catch (error) {
      setLoader(false);
      setApiErrorMessage(error?.response?.data?.error?.message);
      // if (!error.response.data.error.success) {
      //   dispatch({
      //     type: "UPDATE_SNACKBAR_DATA",
      //     payload: {
      //       success: false,
      //       error: true,
      //       showMsg: true,
      //       message: error.response.data.error.message,
      //     },
      //   });
      // }
    }
    console.log(data1, "saved data");
  }
  // #TODO API Integration pending

  const chagneInStep = (step) => {
    switch (step) {
      case 1:
        return (
          <AddClientMemberForm1
            formMaxHeight="65vh"
            noStepBottom={true}
            currentStep={next}
            noStep={true}
            head={{ main: "add client member" }}
            formData={formData}
            customHandleSubmit={(data) => {
              saveMemberClient(data);
              console.log(data, "afjkababjsf");
            }}
            formWidth={"523px"}
            apiErrorMessage={apiErrorMessage}
          />
        );
      case 2:
        return (
          <SetPinForm
            formWidth={"523px"}
            handleBack={handleBack}
            handlenext={() => {
              console.log("handle next is called");
              handleNext(-1);
              setOpenModal(false);
              if (handleRefresh) {
                handleRefresh();
              }
            }}
            userId={formData?.id}
            userType={"client"}
          />
        );

      default:
        return setOpenModal(false);
    }
  };

  return (
    <NewDialaugeComp
      width={"640px"}
      fullWidth={false}
      open={openModal}
      handleClose={() => {
        setOpenModal(false);
        setFormData({});
        handleClose();
      }}
    >
      {chagneInStep(next)}
    </NewDialaugeComp>
  );
}

export default AddClientMemberFlow;
