import {
  Button,
  Grid,
  Typography,
  Modal,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import React, { useState, useEffect, useMemo, useContext } from "react";
import { Link, json, useNavigate } from "react-router-dom";
import { ReactComponent as Back } from "../../../../assets/holding-summary/back-blue.svg";
import useStyles from "../common/ProductsStyles";
import LineChart from "../../../charts/LineChart";
import CheckBoxWithColor from "../common/CheckBoxWithColor";
import SearchInput from "../common/SearchInput";
import { getMutualFundList } from "../../../../api/mutualFundAPI";
import { fetchFundPerformance, fetch_all_funds } from "../../../../api";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import { OnboardingContext } from "../../../../Context";
import moment from "moment";
import PageLoader from "../common/pageLoader";
import DataNotFound from "../common/DataNotFound";
import FilterCalendar from "../../../calendar/FilterCalendar";
import { RRule, RRuleSet, rrulestr } from "rrule";
import { dateFormatCommon, roundNumber } from "../../../utils/common";
import ApexNew from "../../../charts/ApexNew";
// import { ReactComponent as Filter } from "../../../../assets/icons/filter.svg";

function MfoFund() {
  const classes = useStyles();
  const theme = useTheme();
  const icons = theme.palette.icons;
  const Filter = icons.globalFilterIcon;

  const { userState } = OnboardingContext();
  const [openModal, setOpenModal] = useState(false);
  const [mutualFunds, setMutualFunds] = useState([]);
  const [search, setSearch] = useState(null);
  const [searchList, setSearchList] = useState([]);
  const [currentList, setCurrentList] = useState([]);
  const [selectedIsin, setSelectedIsin] = useState([]);
  const [load, setLoad] = useState(true);
  const [chartload, setchartload] = useState(false);
  const [isinMap, setIsinMap] = useState({});
  const [lineData, setLineData] = useState([]);
  const [xval, setxval] = useState(
    generateDates(moment().subtract(1, "months"), "days", 1)
  );
  const clientId = userState.loginedUserData.id;
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const navigate = useNavigate();
  const [dataRange, setDataRange] = useState({
    startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  const [min, setmin] = useState(100);
  const [xrange, setXrange] = useState([]);
  const [range, setRange] = useState("1M");
  const [format, setFormat] = useState("DD-MMM-YYYY");
  const [legends, setlegends] = useState([]);
  const [clear, setclear] = useState(false);

  const lineColors = ["#F52D6A", "#82EBA1", "#FF9446", "#6319d3", "#f6c7b6"];
  // [" #82EBA1", "#711CF1", " #FC5555", "#FF9446"];
  const hideAlert = () => {
    setTimeout(() => {
      const customDiv = document.getElementById("customAlert");
      customDiv.style.display = "none";
    }, 2000);
  };
  const [customRange, setCustomRange] = useState([null, null]);
  useMemo(() => fundPerformance(selectedIsin, isinMap), [range, dataRange]);
  // useMemo(() => {
  //   setDataRange({ startDate: moment(mfoFilter.startDate).format("YYYY-MM-DD"), endDate: moment(mfoFilter.endDate).format("YYYY-MM-DD") })
  //   setRange(false)
  // }, [mfoFilter.startDate, mfoFilter.endDate])
  useEffect(() => {
    if (!search) {
      setLoad(true);
      fetch_all_funds(clientId, {})
        .then((res) => {
          let result = res.result;

          setMutualFunds(result);
          let isinmap = {};
          let selectedisin = [
            result[0]?.isin,
            result[1]?.isin,
            result[2]?.isin,
            result[3]?.isin,
          ];

          if (selectedIsin.length === 0 && result.length > 0) {
            if (result.length > 4) {
              setSelectedIsin(selectedisin);
              isinmap[result[0]["isin"]] = result[0].scheme_name;
              isinmap[result[1]["isin"]] = result[1].scheme_name;
              isinmap[result[2]["isin"]] = result[2].scheme_name;
              isinmap[result[3]["isin"]] = result[3].scheme_name;
              setIsinMap(isinmap);

              fundPerformance(selectedisin, isinmap);
            } else {
              setSelectedIsin([result[0].isin]);
              isinmap[result[0]["isin"]] = result[0].scheme_name;
              setIsinMap(isinmap);

              fundPerformance(selectedisin, isinmap);
            }
          } else {
            setLoad(false);
          }
        })
        .catch((err) => {
          setLoad(false);
        });
    }
  }, [mfoFilter]);
  useEffect(() => {
    setLineData([...lineData]);
  }, [selectedIsin]);
  useEffect(() => {
    if (search) {
      searchFund();
    }
  }, [search]);
  function switchRange(startdate) {
    let type = "days";
    let count = 1;
    let custom;
    // switch (range) {
    //   case "1M":
    //     return setxval(generateDates(moment(startdate), "days", 3));

    //   case "3M":
    //     return setxval(generateDates(moment(startdate), "days", 9));

    //   case "1Y":
    //     return setxval(generateDates(moment(startdate), "months", 1));

    //   case "3Y":
    //     return setxval(generateDates(moment(startdate), "months", 4));

    //   case "5Y":
    //     return setxval(generateDates(moment(startdate), "months", 6));
    //   case "ITD":
    //     custom = checkcustom(startdate);

    //     return setxval(
    //       generateDates(moment(startdate), custom.type, custom.count)
    //     );
    //   case "custom":
    //     custom = checkcustom(startdate);

    //     return setxval(
    //       generateDates(moment(startdate), custom.type, custom.count)
    //     );
    //   default:
    //     break;
    // }
    custom = checkcustom(startdate);

    return setxval(generateDates(moment(startdate), custom.type, custom.count));
  }
  function checkcustom(startdate) {
    let diff = moment().diff(moment(startdate), "years");

    if (diff == 0) {
      diff = moment().diff(moment(startdate), "months");

      if (diff < 1.5) {
        setFormat("DD-MMM-YYYY");
        return { type: "days", count: 3 };
      } else if (diff < 3.5) {
        setFormat("DD-MMM-YYYY");
        return { type: "days", count: 9 };
      } else {
        setFormat("MMM-YYYY");
        return { type: "months", count: 1 };
      }
    } else if (diff > 5) {
      setFormat("MMM-YYYY");
      return { type: "years", count: 1 };
    } else if (diff == 1) {
      setFormat("MMM-YYYY");
      return { type: "months", count: 1 };
    } else if (diff > 2) {
      setFormat("MMM-YYYY");
      return { type: "months", count: 4 };
    } else if (diff < 2) {
      setFormat("MMM-YYYY");
      return { type: "months", count: 2 };
    } else {
      setFormat("MMM-YYYY");
      return { type: "months", count: 6 };
    }
  }
  function generate(val, interval, start, end) {
    const rule = new RRule({
      freq: RRule[val],
      interval: interval,
      byweekday: [RRule.MO, RRule.FR],
      dtstart: new Date(start),
      until: new Date(end),
    });
  }
  function generateDates(dateStart, type, count) {
    var dateEnd = moment();

    var timeValues = [];

    while (
      dateEnd > dateStart ||
      dateStart.format("DD-MM-YYYY") === dateEnd.format("DD-MM-YYYY")
    ) {
      timeValues.push(dateStart.format("YYYY-MM-DD"));
      dateStart.add(count, type);
    }
    // console.log(timeValues);
    return timeValues;
  }

  async function fundPerformance(selectedisin, isinmap) {
    if (selectedisin.length == 0) {
      setLoad(false);
      return;
    }
    let params = { assetClass: getfilter_as_params().assetClass };
    if (Object.keys(mfoFilter.checkData).length > 0) {
      params["memberId"] = Object.keys(mfoFilter.checkData).join(",");
    }
    if (Object.keys(mfoFilter.advisor).length > 0) {
      params["advisor"] = Object.keys(mfoFilter.advisor).join(",");
    }
    setLoad(true);

    const isin = selectedisin.map((isin) => isin);
    try {
      const responses = await fetchFundPerformance(
        clientId,
        { ...dataRange, ...params, isin: isin.join(",") },
        { type: "mf" }
      );
      let legendss = [];
      let current = [];
      let min1 = 100;
      let result = responses.data.data.result.map((res, j) => {
        legendss.push(res.fundName);

        let data = res.graphData.map((r, i) => {
          if (j == 0 && i == 0) {
            switchRange(r.date);
            // setxval(generateDates(moment(d.date), "months", 4));
          }
          if (parseFloat(r.rebaseNav) < min1) {
            min1 = parseFloat(r.rebaseNav);
          }

          return [r.date, roundNumber(r.rebaseNav), roundNumber(r.fundNav)];
        });

        return {
          name: res.fundName,
          // color: "hsl(240, 70%, 50%)",
          isin: res.isin,
          data: data ? data : [],
        };
      });
      setmin(min1 - 1);
      // console.log(min1 - 1, "min1");
      setlegends(legendss);
      // let results = responses.map((response, i) => {

      //   let data = response.value?.data?.data?.result?.map((r) => {
      //     return (
      //       {
      //         id: isinmap[selectedisin[i]],
      //         x: r.d,
      //         y: r.v
      //       }
      //     )
      //   })

      //   if (response.status === 'fulfilled') {
      //     let result = {
      //       "id": isinmap[selectedisin[i]].length > 30 ? isinmap[selectedisin[i]].substring(0, 30) + "..." : isinmap[selectedisin[i]],
      //       "color": "hsl(240, 70%, 50%)",
      //       isin: selectedisin[i],
      //       data: data ? data : []
      //     };
      //     current.push(selectedisin[i])

      //     return result;
      //   }
      // })
      // result = result.filter((item) => item);
      if (result.length == 1) {
        result.push({
          name: " ",
          // color: "hsl(240, 70%, 50%)",
          isin: " ",
          data: [],
        });
      }
      setLineData(result);
      setchartload(result?.length === 0 ? true : false);
    } catch (err) {
      console.log(err.response.data.error.message);
      const customDiv = document.getElementById("customAlert");
      customDiv.style.display = "block";
      customDiv.innerHTML = `<div class="alertText capitalize">
      <span style="margin-right:20px"> <svg height="32" style="overflow:visible;enable-background:new 0 0 32 32" viewBox="0 0 32 32" width="32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><g id="Error_1_"><g id="Error"><circle cx="16" cy="16" id="BG" r="16" style="fill:#D72828;"/><path d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z" id="Exclamatory_x5F_Sign" style="fill:#E6E6E6;"/></g></g></g></svg></span>
      <div style="margin-right:20px;font-size:14px">                         
      ${err.response.data.error.message.replace(
        "Bad request: Invalid request",
        ""
      )}
                                </div>
                             </div>`;
      hideAlert();
      setLineData([]);
      setlegends([]);
      setchartload(true);
    } // setCurrentList(currentList);
    setLoad(false);
  }
  function searchFund() {
    setLoad(false);

    fetch_all_funds(clientId, { fund_name: search })
      .then((res) => {
        setSearchList(res.result);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
      });
  }

  const handleCheckBoxClick = (e, col) => {
    let currentlen = selectedIsin.length;
    if (e.target.checked && selectedIsin.length < 4) {
      selectedIsin.push(e.target.value);
      isinMap[e.target.value] = e.target.name;
      setSelectedIsin([...selectedIsin]);

      setIsinMap({ ...isinMap });
      fundPerformance(selectedIsin, isinMap);
      if (search) {
        let found = mutualFunds.find((m, k) => {
          if (m.isin == e.target.value) {
            mutualFunds.splice(k, 1);
            mutualFunds.unshift(col);

            mutualFunds.forEach((m, j) => {
              if (selectedIsin.includes(m.isin) && j != 0) {
                mutualFunds.splice(j, 1);
                mutualFunds.unshift(m);
              }
            });
            setMutualFunds([...mutualFunds]);
            return true;
          }
        });
        if (!found) {
          mutualFunds.unshift(col);

          mutualFunds.forEach((m, j) => {
            if (selectedIsin.includes(m.isin) && j != 0) {
              mutualFunds.splice(j, 1);
              mutualFunds.unshift(m);
            }
          });
          setMutualFunds([...mutualFunds]);
        }
      }
    } else {
      let sel = selectedIsin.find((isin, i) => {
        if (isin === e.target.value && selectedIsin.length > 1) {
          selectedIsin.splice(i, 1);
          return true;
        }
      });
      if (!sel) {
        const customDiv = document.getElementById("customAlert");
        customDiv.style.display = "block";
        customDiv.innerHTML = `<div class="alertText">
        <span style="margin-right:20px"> <svg height="32" style="overflow:visible;enable-background:new 0 0 32 32" viewBox="0 0 32 32" width="32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><g id="Error_1_"><g id="Error"><circle cx="16" cy="16" id="BG" r="16" style="fill:#D72828;"/><path d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z" id="Exclamatory_x5F_Sign" style="fill:#E6E6E6;"/></g></g></g></svg></span>
                                  ${
                                    selectedIsin.length < 4
                                      ? "Cannot deselect minimum selection 1 required "
                                      : "Cannot select more than 4 funds"
                                  }
                               </div>`;
        hideAlert();
      } else {
        fundPerformance(selectedIsin, isinMap);
      }
      setSelectedIsin([...selectedIsin]);

      let data = lineData.filter((l, i) => {
        if (l.isin == e.target.value && currentlen > 1) {
          // lineData.splice(i, 1);
          legends.find((legend, j) => {
            if (l.name == legend) {
              legends.splice(j, 1);
              setlegends([...legends]);
              return true;
            }
          });
        } else {
          return l;
        }
      });

      if (data.length == 1) {
        data.push({
          name: " ",
          // color: "hsl(240, 70%, 50%)",
          isin: " ",
          data: [],
        });
      }
      setLineData(data);
    }
  };

  const config = {
    enablePoints: true,
    curve: "linear",
    disablePointLabel: true,
    disableAxisBottom: true,
    pointSize: 0.1,
    nopoint: true,
    hide_legends: true,
    leftAxis: {
      legend: "Portfolio Return",
      renderTick: (tick) => {
        return (
          <g transform={`translate(${tick.x - 30},${tick.y})`}>
            {/* <rect x={-14} y={-6} rx={3} ry={3} width={28} height={24} fill="rgba(0, 0, 0, .05)" />
                        <rect x={-12} y={-12} rx={2} ry={2} width={24} height={24} fill="rgb(232, 193, 160)" /> */}
            {/* <line stroke="rgb(232, 193, 160)" strokeWidth={1.5} y1={-22} y2={-12} /> */}
            {/* <rect x={-40} y={-15} rx={2} ry={2} width={100} height={3} fill="rgba(31, 28, 64, 1)" /> */}
            <text
              textAnchor="middle"
              dominantBaseline="middle"
              style={{
                fill: "#b0aab7",
                fontSize: 10,
              }}
            >
              {tick.value}
            </text>
          </g>
        );
      },
    },
    extra_props: {
      enableSlices: "x",
      sliceTooltip: (data) => {
        // console.log(data);
        return (
          <div
            style={{
              padding: 7,
              color: "#FFFFFF",
              background: "#2A274F",
              borderRadius: "10px",
            }}
          >
            {/* <br /> */}
            <div
              className="subtext"
              style={{
                display: "flex",
                fontFamily: "Avenir",
                paddingLeft: "25px",
                paddingRight: "19px",
                lineHeight: "24px",
              }}
            >
              {/* <div style={{background:data.point.serieColor,width:"20px",height:"20px",borderRadius:"10px",marginRight:"5px"}}></div> */}
              {data.slice.points[0].data.x
                ? dateFormatCommon(data.slice.points[0].data.x, "YYYY-MM-DD")
                : ""}
            </div>

            {data.slice.points.map((d, i) => {
              return (
                <div
                  style={{
                    display: "flex",
                    fontFamily: "Avenir",
                    paddingLeft: "25px",
                    paddingRight: "19px",
                    lineHeight: "24px",
                  }}
                >
                  {/* <div style={{background:data.point.serieColor,width:"20px",height:"20px",borderRadius:"10px",marginRight:"5px"}}></div> */}
                  <div
                    style={{
                      width: "300px",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {d.data.id}
                  </div>{" "}
                  : {roundNumber(d?.data?.z)}{" "}
                </div>
              );
            })}
          </div>
        );
      },
    },
  };

  return (
    <>
      <div className={`${classes.alignTopHeader}`}>
        <Typography
          // style={{ marginLeft: "-4%" }}
          className={`${classes.mfoGraphHeader} ${classes.mainHeadingAlign}`}
        >
          Fund Performance
        </Typography>
        <div className={`${classes.iconAlign}`}>
          {/* <Filtericon setShowfilter={setOpenModal} /> */}
          {/* <Button
            style={{
              width: "157px",
              background:
                "linear-gradient(0deg, #220848, #220848),linear-gradient(0deg, #4E189F, #4E189F)",
              border: " 1px solid #4E189F ",
              borderRadius: "49px",
              padding: "14px",
            }}
            className={`${classes.fundButton}`}
            variant="contained"
            onClick={() => {
              setOpenModal(true);
            }}
          >
            <Typography className={classes.holdingButtonText}>
              Select Funds
            </Typography>
          </Button> */}
        </div>
      </div>

      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(!openModal);
          setclear(true);
        }}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={`${classes.modalContainerModified} form-container`}>
          <div className="filter-header">
            <Typography className="filter-header-text">Add Funds</Typography>
          </div>
          <hr className="filter-divider"></hr>
          <div className="member">
            <SearchInput
              setSearchName={setSearch}
              clear={clear}
              setClear={setclear}
            />
            <div className="member-content">
              {!search &&
                mutualFunds?.map((column, key) =>
                  column && column?.title !== "" ? (
                    <div key={key} className="filter-header">
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <CheckBoxWithColor
                          checked={selectedIsin.includes(column.isin)}
                          value={column.isin}
                          name={column.scheme_name}
                          onChange={(e) => {
                            handleCheckBoxClick(e, column);
                          }}
                        />
                        <Tooltip title={column.scheme_name}>
                          <Typography className="filter-value-name avtar-text overflow-ellipse">
                            {column.scheme_name}
                          </Typography>
                        </Tooltip>
                      </span>
                    </div>
                  ) : null
                )}
              {search &&
                searchList?.map((column, key) =>
                  column.title !== "" ? (
                    <div key={key} className="filter-header">
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <CheckBoxWithColor
                          checked={selectedIsin.includes(column.isin)}
                          value={column.isin}
                          name={column.scheme_name}
                          onChange={(e) => {
                            handleCheckBoxClick(e, column);
                          }}
                        />
                        <Tooltip title={column.scheme_name}>
                          <Typography className="filter-value-name avtar-text overflow-ellipse">
                            {column.scheme_name}
                          </Typography>
                        </Tooltip>
                      </span>
                    </div>
                  ) : null
                )}
            </div>
          </div>
        </div>
      </Modal>

      <div style={{ display: "flex" }}>
        <Grid xs={12}>
          <div className={classes.mainContent}>
            <Typography
              className={`${classes.fundPerformanceCardText} ${classes.typoInsideAlign}`}
            ></Typography>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Filter
                className={classes.iconStyle}
                onClick={() => {
                  setOpenModal(true);
                }}
              ></Filter>
              <div className="dispnone"></div>
            </div>
            {lineData && lineData.length !== 0 ? (
              // <LineChart
              //   config={{
              //     extra_props: config.extra_props,
              //     hide_legends: true,
              //     leftAxis: config.leftAxis,
              //     format: format,
              //     bottomLegend: "Time Period",
              //   }}
              //   colors={[" #82EBA1", "#711CF1", " #FC5555", "#FF9446"]}
              //   data={lineData}
              //   xval={xval}
              //   min={min}
              // ></LineChart>
              <ApexNew
                linedata={lineData}
                title={{ y: "Portfolio Return" }}
                controller_disabled={true}
                legends_hide={true}
              />
            ) : (
              ""
            )}
            {lineData && (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  marginTop: "20px",
                  marginBottom: "3%",
                  marginRight: "3%",
                }}
              >
                {legends?.map((l, i) => {
                  if (!l) {
                    return;
                  }
                  return (
                    <div style={{ display: "flex" }} className="outer-tooltip">
                      <div className={classes.graphBulletBonds}>
                        <svg height="25" width="25">
                          <circle
                            cx="12"
                            cy="12"
                            r="5"
                            stroke-width="3"
                            fill={lineColors[i]}
                          />
                          Sorry, your browser does not support inline SVG.
                        </svg>
                      </div>
                      <div className={`${classes.graphLegends}`}>
                        {l.length > 30 ? l.substring(0, 30) + "..." : l}
                      </div>
                      <div className=" inner-tooltip tooltip-box">
                        <p className="tooltip-text">{l}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {chartload ? <DataNotFound></DataNotFound> : ""}
            {/* <Button onClick={()=>{
                getdaterange()
              }}>
                dates
              </Button> */}
            <div className="w451 yearSelector">
              <Button
                onClick={() => {
                  setRange("1M");
                  setCustomRange([null, null]);
                  // setxval(
                  //   generateDates(moment().subtract(1, "months"), "days", 1)
                  // );
                  // generate("DAILY",1,moment().subtract(1, 'months').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
                  setDataRange({
                    endDate: moment().format("YYYY-MM-DD"),
                    startDate: moment()
                      .subtract(1, "months")
                      .format("YYYY-MM-DD"),
                  });
                }}
                style={{ textDecoration: "none", minWidth: "13%" }}
                to="#"
              >
                <div
                  className={`${classes.bottomTabHeaderText} ${
                    range === "1M" ? classes.bottomTabActive : ""
                  }`}
                  style={{ margin: "0px" }}
                >
                  1M
                </div>
              </Button>
              <Button
                onClick={() => {
                  setRange("3M");
                  setCustomRange([null, null]);
                  // setxval(
                  //   generateDates(moment().subtract(3, "months"), "days", 14)
                  // );
                  // generate("DAILY",3,moment().subtract(3, 'months').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
                  setDataRange({
                    endDate: moment().format("YYYY-MM-DD"),
                    startDate: moment()
                      .subtract(3, "months")
                      .format("YYYY-MM-DD"),
                  });
                }}
                style={{ textDecoration: "none", minWidth: "13%" }}
                to="#"
              >
                <div
                  className={`${classes.bottomTabHeaderText} ${
                    range === "3M" ? classes.bottomTabActive : ""
                  }`}
                  style={{ margin: "0px" }}
                >
                  3M
                </div>
              </Button>
              <Button
                onClick={() => {
                  setRange("1Y");
                  setCustomRange([null, null]);
                  // setxval(
                  //   generateDates(moment().subtract(1, "years"), "months", 1)
                  // );
                  // generate("WEEKLY",1,moment().subtract(1, 'years').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
                  setDataRange({
                    endDate: moment().format("YYYY-MM-DD"),
                    startDate: moment()
                      .subtract(1, "years")
                      .format("YYYY-MM-DD"),
                  });
                }}
                style={{ textDecoration: "none", minWidth: "13%" }}
                to="#"
              >
                <div
                  className={`${classes.bottomTabHeaderText} ${
                    range === "1Y" ? classes.bottomTabActive : ""
                  }`}
                  style={{ margin: "0px" }}
                >
                  1Y
                </div>
              </Button>
              <Button
                onClick={() => {
                  setRange("3Y");
                  setCustomRange([null, null]);
                  // setxval(
                  //   generateDates(moment().subtract(3, "years"), "months", 2)
                  // );
                  // generate("WEEKLY",2,moment().subtract(3, 'years').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
                  setDataRange({
                    endDate: moment().format("YYYY-MM-DD"),
                    startDate: moment()
                      .subtract(3, "years")
                      .format("YYYY-MM-DD"),
                  });
                }}
                style={{ textDecoration: "none", minWidth: "13%" }}
                to="#"
              >
                <div
                  className={`${classes.bottomTabHeaderText} ${
                    range === "3Y" ? classes.bottomTabActive : ""
                  }`}
                  style={{ margin: "0px" }}
                >
                  3Y
                </div>
              </Button>
              <Button
                onClick={() => {
                  setRange("5Y");
                  setCustomRange([null, null]);
                  // generateDates(moment().subtract(5, "years"), "months", 3);
                  // setxval(
                  //   generate(
                  //     "MONTHLY",
                  //     1,
                  //     moment().subtract(5, "years").format("YYYY-MM-DD"),
                  //     moment().format("YYYY-MM-DD")
                  //   )
                  // );
                  // setxval(
                  //   generateDates(moment().subtract(5, "years"), "months", 3)
                  // );
                  setDataRange({
                    endDate: moment().format("YYYY-MM-DD"),
                    startDate: moment()
                      .subtract(5, "years")
                      .format("YYYY-MM-DD"),
                  });
                }}
                style={{ textDecoration: "none", minWidth: "13%" }}
                to="#"
              >
                <div
                  className={`${classes.bottomTabHeaderText} ${
                    range === "5Y" ? classes.bottomTabActive : ""
                  }`}
                  style={{ margin: "0px" }}
                >
                  5Y
                </div>
              </Button>
              {/* <Button
                onClick={() => {
                  setRange("ITD");
                  setDataRange();
                  setCustomRange([null, null]);
                }}
                style={{ textDecoration: "none", minWidth: "13%" }}
                to="#"
              >
                <div
                  className={`${classes.bottomTabHeaderText} ${
                    range === "ITD" ? classes.bottomTabActive : ""
                  }`}
                  style={{ margin: "0px" }}
                >
                  ITD
                </div>
              </Button> */}
              <Button
                style={{
                  textDecoration: "none",
                  textTransform: "capitalize",
                  minWidth: "13%",
                }}
                to="#"
              >
                <div
                  className={`${classes.bottomTabHeaderText} ${
                    range === "custom" ? classes.bottomTabActive : ""
                  }`}
                  style={{ margin: "0px" }}
                >
                  <FilterCalendar
                    values={customRange}
                    minDate={{ min: 5, type: "years" }}
                    maxDate={{
                      current: new Date(moment().format("YYYY-MM-DD")),
                      min: 5,
                      type: "years",
                    }}
                    hideinput={true}
                    customLabel="Custom"
                    customStyle={{
                      width: "100%",
                      textAlign: "center",
                      borderRadius: "20px",
                      padding: "0px",
                      background: "transparent",
                      border: "none",
                      color: "#FFFFFF",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontFamily: "Avenir",
                      fontWeight: "500",
                      cursor: "pointer",
                    }}
                    customCalenderPostion={{
                      right: "0px",
                      top: "55px",
                    }}
                    onchange={(newCustomRange) => {
                      setCustomRange(newCustomRange);
                      setRange("custom");
                      setDataRange({
                        startDate: moment(newCustomRange[0]).format(
                          "YYYY-MM-DD"
                        ),
                        endDate: moment(newCustomRange[1]).format("YYYY-MM-DD"),
                      });
                    }}
                  />
                </div>
              </Button>
            </div>
          </div>
          {/* <div className={classes.mainContent}>
                <Typography className={`${classes.fundPerformanceCardText} ${classes.typoInsideAlign}`}>
                            DSP Mutual Fund
                        </Typography>
                <LineChart config={config} colors={["#6BCDF7"]} data={data}></LineChart>
                </div> */}
        </Grid>
        {/* <Grid xs={6}>
                <div className={classes.mainContent}>
                <Typography className={`${classes.fundPerformanceCardText} ${classes.typoInsideAlign}`}>
                            Axis Mutual Fund
                        </Typography>
                <LineChart config={config} colors={[" #DB2FB5"]} data={data}></LineChart>
                </div>
                <div className={classes.mainContent}>
                <Typography className={`${classes.fundPerformanceCardText} ${classes.typoInsideAlign}`}>
                            SBI Mutual Fund
                        </Typography>
                <LineChart config={config}colors={["#FF9446"]} data={data}></LineChart>
                </div>
            </Grid> */}
        <PageLoader showLoader={load} />
      </div>
    </>
  );
}

export default MfoFund;
