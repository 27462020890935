import React, { useState } from "react";
import HoldingPrint from "./subPrintPages/HoldingPrint";
import {
  ClientHolding,
  newpdfHoldingsRealEstate,
  newPdfSecurityHolding,
  newpdfHolding,
  newpdfHoldings,
  newpdfTransactions,
  newpdfHoldingsInsuranceNonUlip,
  newpdfHoldingsInsuranceUlip,
  newAifPdfHoldings,
  newDirectEquityPdfHoldings,
  newpdfHoldingsBankBook,
  newpdfTransactionsFundsInTransit,
  newpdfHoldingsFundInTransit,
  newpdClientfHoldings,
  allData,
  top5amc,
  newFDRDPMSPdfHoldings,
  newPmsPdfHoldings,
} from "../Components/utils/staticData";
import { Link } from "react-router-dom";
import Overall_portfolioHolding from "./Overall_portfolioHolding";
import { objectFormatingWithClientHoldingTitleCOl } from "../Components/utils/common";
import SubCategoryAllocationPdf from "./subCategory";
import EquitySectorAllocationPdf from "./EquitySectorAllocationPdf";
import MarketCap from "./MarketCap";
import Header from "../Components/Header";

export default function MemberHoldingSummary({
  newData1,
  denomination,
  reportType,
  setChanges,
  pdfInfo,
  breadcrum,
  member,
  asset,
  allData,
  overview,
}) {
  const colsWithSubtext = [
    "Bonds",
    "Real Estate Investment Trusts",
    "Infrastructure Investment Trusts",
    "AIF",
  ];

  const SECURITY_HOLDING_SUMMARY =
    "Consolidated Securities Holding Summary Report";
  function modifyFirstcol(p) {
    let colarr = renderCustomTableHeaderBasedOnReportType(p);
    colarr = JSON.parse(JSON.stringify(colarr));
    colarr[0]["subtext"] = <div className="s_text italic1">Weightage </div>;
    let index = colarr.findIndex((a, i) => {
      if (a?.title == "Current Value") {
        return true;
      }
    });
    let index2 = colarr.findIndex((a, i) => {
      if (a?.title == "Invested Value") {
        return true;
      }
    });
    let index3 = colarr.findIndex((a, i) => {
      if (a?.title == "Quantity") {
        return true;
      }
    });
    if (index3 != -1 && index3) {
      colarr[index3]["subtext"] = (
        <div
          className="s_text italic1"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            whiteSpace: "nowrap",
          }}
        >
          Avg Buy Price (₹)
        </div>
      );
      colarr[index3]["subdata"] = "weightedAvgCost";
    }

    if (colarr[index]?.title == "Current Value") {
      colarr[index]["subtext"] = (
        <div
          className="s_text italic1"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          Valuation As On
        </div>
      );
    }
    if (colsWithSubtext.includes(p)) {
      colarr[index2]["subdata"] = "principalPayback";
      colarr[index2]["subtext"] = (
        <div className="s_text italic1">Principal Payback</div>
      );
    }

    return colarr;
  }

  function renderCustomTableHeaderBasedOnReportType(product) {
    if (reportType === SECURITY_HOLDING_SUMMARY) {
      return newPdfSecurityHolding;
    } else if (reportType === "clientHolding") {
      return ClientHolding;
    } else if (reportType === "transactionSummary") {
      return newpdfTransactions;
    } else {
      if (product == "Real Estate") {
        return newpdfHoldingsRealEstate;
      } else if (product == "Insurance (Non ULIP)") {
        return newpdfHoldingsInsuranceNonUlip;
      } else if (product == "Insurance (ULIP)") {
        return newpdfHoldingsInsuranceUlip;
      } else if (product == "Bank Book") {
        return newpdfHoldingsBankBook;
      } else if (product == "Funds In Transit") {
        return newpdfHoldingsFundInTransit;
      }
      if (product == "AIF" || product == "Mutual Funds") {
        return newpdfHoldings;
      }
      if (product == "Direct Equity") {
        return newDirectEquityPdfHoldings;
      } else if (product == "PMS") {
        return newPmsPdfHoldings;
      } else if (
        product == "PMS" ||
        product == "Fixed Deposit" ||
        product == "Recurring Deposit"
      ) {
        return newFDRDPMSPdfHoldings;
      } else {
        return newAifPdfHoldings;
      }
    }
  }
  const breadcrumbs = [
    <Link
      key="1"
      color="#000000"
      className="printBreadsel fontFamilyAvBold f16"
    >
      Holding Summary
    </Link>,
  ];

  // console.log(
  //   newData?.["holdingSummary"] && Object?.keys(newData?.["holdingSummary"]),
  //   "newData"
  // );
  function generateTablePages() {
    // const sortdata = Object.assign(
    //   {},
    //   newData?.["holdingSummary"]?.asset_wise_product_sort_order
    // );
    console.log("zxcv");

    let bread = [...breadcrumbs];
    bread.push(
      <Link
        underline="hover"
        key="2"
        color="
  #000000"
      >
        <span className="opc80"> Asset Class : </span>
        <span className="pdfselectedTxt pdfCaps fontFamilyAvBold f16">
          {asset}
        </span>
      </Link>
    );
    //   console.log("arr", arr);

    return newData1?.map((newData, k) => {
      let footerLegend = [
        { symbol: "#", text: "Indicates Held-away Holdings" },
      ];
      let footerLegendNotes = [];
      let breadcrum = [...bread];
      breadcrum.push(
        <Link
          underline="hover"
          key="2"
          color="
        #000000"
        >
          <span className="opc80"> Product : </span>
          <span className="pdfselectedTxt pdfCaps fontFamilyAvBold 16">
            {newData?.product}
          </span>
        </Link>
      );

      try {
        if (!newData?.holdings || newData?.holdings.length == 0) {
          return <></>;
        }
      } catch (err) {
        console.log(err, "err");
        console.log(newData?.product, "product");
      }
      if (newData?.product == "Funds In Transit") {
        newData?.holdings.map((arr) => {
          arr.member = member;
        });
      }
      if (newData?.product.toLowerCase() == "unlisted equity") {
        newData?.holdings.map((arr) => {
          arr.CurrentValIndicator = true;
        });
        footerLegend.push({ symbol: "**", text: "Promoter stake" });
        footerLegend.push({ symbol: "^", text: "Indicative Price" });
      }
      if (newData?.product.toLowerCase() == "direct equity") {
        footerLegend.push({ symbol: "**", text: "Promoter stake" });
      }
      if (newData?.product.toLowerCase() == "aif") {
        footerLegendNotes.push({
          note: "Note - Negative Values - in other income denote equalisation paid.",
        });
      }
      let refForTotal = newData?.total;
      if (newData?.product == "Bank Book") {
        newData?.holdings.push({
          bankName: "Total",
          balance: refForTotal.totalBalance,
        });
      } else if (newData?.product == "Funds In Transit") {
        newData?.holdings.push({
          member: "Total",
          amount: refForTotal.totalBalance,
        });
      } else {
        newData?.holdings?.push({
          " % of Total Portfolio": refForTotal?.["% of Portfolio"],
          "Absolute TWRR": refForTotal?.["Absolute TWRR"],
          "Advised By": "--",
          "Annualized TWRR": refForTotal?.["Annualised TWRR"],
          "Asset Class": "--",
          currentValue: refForTotal?.["currentValue"],
          "Folio No": "--",
          unRealisedGainLoss: refForTotal?.["unRealisedGainLoss"],
          "ISIN No/Product Code": "--",
          schemeName: "Total",
          "Last Price Date": "--",
          "Market Price per Unit": "--",
          "Member Name": "--",
          otherIncome: refForTotal?.["otherIncome"],
          "Product Name": "--",
          "Quantity/Units": "--",
          totalCost: refForTotal?.["totalCost"],
          xirr: refForTotal?.["xirr"],
          coverAmount: refForTotal?.coverAmount,
          premium: refForTotal?.premium,
        });
      }
      if (newData.grandTotal) {
        newData?.holdings?.push(newData.grandTotal);
        // grandTotal = newData["holdingSummary"][arr][p]?.totalValues;
        // if (p == "Funds In Transit") {

        // } else if (p == "Bank Book") {
        //   grandTotal.bankName = "Grand Total";
        //   grandTotal.balance = grandTotal.totalBalance;
        //   prod?.holdings?.push(grandTotal);
        // } else {
        //   grandTotal.schemeName = "Grand Total";
        //   prod?.holdings?.push(grandTotal);
        // }
      }
      if (newData.grandTotal1) {
        newData?.holdings?.push(newData.grandTotal1);
      }
      return (
        <div className="newgraphPrint pagebreakTable">
          <HoldingPrint
            headerType={reportType == "executiveHolding" ? "11" : 13}
            title={breadcrum || "Holding Summary"}
            footerLegendText={footerLegend}
            footerLegendNotes={footerLegendNotes}
            pdfInfo={pdfInfo}
            asOnDate={refForTotal?.asOnDate}
            layout={1}
            title2={newData?.product == "Funds In Transit" ? "" : member}
            title1={newData?.product}
            customTableHeader={modifyFirstcol(newData?.product)}
            subtext={""}
            customKey={newData?.product}
            type={"holdingSummary"}
            loading={false}
            setChanges={setChanges}
            breadcrumb={breadcrum}
            // error={data?.equity?.direct_equity_holding_summary?.error}
            customData={
              // data?.equity?.direct_equity_holding_summary?.data?.data?.result
              // stDataHolding

              newData?.holdings
            }
            customTableClass={"printTableEvenAndOddRow"}
            hideTitle={true}
            hideTotalRow={true}
            denomination={denomination}
            // colmapping={objectFormatingWithClientHoldingTitleCOl}
          />
        </div>
      );
    });
  }
  return <>{newData1 && generateTablePages()}</>;
}
