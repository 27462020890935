import _ from "lodash";
import { obj } from "./Breadcrum_route";

/*
 * Input str = "max life insurance"
 * Output str = "Max Life Insurance"
 */
export function firstLetterCapitalize(str) {
  const words = str?.split(" ");
  return words
    ?.map((word) => {
      return word[0]?.toUpperCase() + word?.substring(1);
    })
    .join(" ");
}

export const countWordsWithSpace = (str) => {
  const trimmedText = str.trim();
  if (trimmedText.includes(" ")) {
    // Multiple
    return true;
  } else {
    // Single
    return false;
  }
};

/*
 * Input str = "equity 30%"
 * Output str = "Equity 30%"
 */
export function firstCharCapitalize(str) {
  if (str) {
    return str[0]?.toUpperCase() + str?.substring(1);
  } else {
    return "-";
  }
}

export function get_path_type(path) {
  if (path.indexOf("/portfolio") >= 0) {
    return "portfolio";
  }

  if (path.indexOf("/mutual-fund") >= 0) {
    return "mfo";
  } else if (path.indexOf("/aif") >= 0) {
    return "aif";
  } else if (path.indexOf("/pms") >= 0) {
    return "pms";
  } else if (path.indexOf("/equity") >= 0) {
    return "equity";
  } else if (path.indexOf("/real-estate") >= 0) {
    return "real-estate";
  } else if (path.indexOf("/bonds") >= 0) {
    return "bonds";
  } else if (path.indexOf("/unlisted-equity") >= 0) {
    return "private";
  } else if (path.indexOf("/structured-product") >= 0) {
    return "structured";
  } else if (path.indexOf("/mld") >= 0) {
    return "mld";
  } else if (path.indexOf("/invits") >= 0) {
    return "invits";
  } else if (path.indexOf("/reits") >= 0) {
    return "reits";
  } else if (path.indexOf("/icd") >= 0) {
    return "icd";
  } else if (path.indexOf("/fixed-deposit") >= 0) {
    return "fixed-deposit";
  } else if (path.indexOf("/bank") >= 0) {
    return "bank";
  } else if (path.indexOf("/recurring-deposit") >= 0) {
    return "recurring-deposit";
  }
  if (path.indexOf("/holding") >= 0) {
    return "holding";
  } else {
    return "general";
  }
}

export const assetclass = {
  mfo: { equity: true, debt: true, alternate: true },
  aif: { equity: true, debt: true, alternate: true },
  bonds: { debt: true },
  pms: { equity: true, debt: true, alternate: true },
  equity: { equity: true },
  "real-estate": { alternate: true },
  general: { equity: true, debt: true, alternate: true },
  holding: { equity: true, debt: true, alternate: true },
  //insurance: {},
  insurance: { alternate: true },
  private: { equity: true },
  structured: { alternate: true },
  mld: { alternate: true },
  invits: { alternate: true },
  reits: { alternate: true },
  icd: { debt: true },
  "fixed-deposit": { alternate: true, debt: true, equity: true },
  bank: { cash: true },
  portfolio: { equity: true, debt: true, alternate: true, cash: true },
  "recurring-deposit": { alternate: true, debt: true, equity: true },
};
export const getProductAndEntityFromRoute = (routeLink) => {
  let path = routeLink?.split("/");

  return {
    product: path[path?.length - 2],
    entity: obj[_.last(path)],
  };
};
