const { routes } = require("../../../../routes");

const tableAlignCenter = "center";

const holdingTableCOnfig = [
  {
    field: "projectName",
    title: "Project Name",
    show: true,
    align: tableAlignCenter,
  },
  {
    field: "memberName",
    title: "Member Name",
    show: true,
    align: tableAlignCenter,
  },
  { field: "advisor", title: "Advisor", show: true, align: tableAlignCenter },
  {
    field: "projectCode",
    title: "Project Code",
    show: true,
    align: tableAlignCenter,
  },
  {
    field: "transactionType",
    title: "Transaction Type",
    show: true,
    align: tableAlignCenter,
  },
  { field: "date", title: "Date", show: true, align: tableAlignCenter },
  { field: "investmentValue", title: "Investment value", show: true },
  { field: "presentValue", title: "Present Value", show: true },
  { field: "loanAmount", title: "Loan Amount", show: true },
  {
    field: "remainingLoanAmount",
    title: "Remaining Loan Amount",
    show: true,
  },
  { field: "selfFundingAmount", title: "Self Funding", show: true },
  {
    field: "annualInterestPaidOnLoan",
    title: "Annual Interest Paid on Loan",
    show: true,
  },
  { field: "address", title: "Address", show: false, align: tableAlignCenter },
  { field: "state", title: "State", show: false, align: tableAlignCenter },
  { field: "city", title: "City", show: false, align: tableAlignCenter },
  { field: "country", title: "Country", show: false, align: tableAlignCenter },
  {
    field: "percentageOfTotalPortfolio",
    title: "% of Total Portfolio",
    show: true,
  },
  {
    field: "unRealisedGainLoss",
    title: "Unrealized Gain/Loss",
    show: true,
  },
  // {
  //   field: "realisedGainLoss",
  //   title: "Realized Gain/Loss",
  //   show: false,
  // },
  {
    field: "interestIncome",
    title: "Total Rental Income",
    show: true,
  },
  { field: "totalGainLoss", title: "Total Gain/Loss", show: true },
  { field: "xirr", title: "XIRR (%)", show: true },
  { field: "absoluteTwrr", title: "Absolute TWRR(%)", show: true },
  {
    field: "annualisedTwrr",
    title: "Annualized TWRR(%)",
    show: true,
  },
];

const trasactionTableConfig = [
  {
    field: "project_name",
    title: "Project Name",
    show: true,
    align: tableAlignCenter,
  },
  {
    field: "advisor",
    title: "Advised by",
    show: true,
    align: tableAlignCenter,
  },
  {
    field: "executed_by",
    title: "Executed By",
    show: true,
    align: tableAlignCenter,
  },

  // { field: "asset_type", title: "Asset Type", show: true },
  {
    field: "product_sub_category",
    title: "Product Sub Category",
    show: true,
    align: tableAlignCenter,
  },
  {
    field: "transaction_date",
    title: "Date of Purchase",
    show: true,
    align: tableAlignCenter,
  },
  // { field: "date_of_sale", title: "Date of Sale", show: true },
  {
    field: "project_code",
    title: "Project Code",
    show: true,
    align: tableAlignCenter,
  },
  {
    field: "transaction_date",
    title: "Transaction Date",
    show: true,
    align: tableAlignCenter,
  },
  {
    field: "transaction_type",
    title: "Transaction Type",
    show: true,
    align: tableAlignCenter,
  },
  { field: "value", title: "Value", show: true, align: tableAlignCenter },
  // { field: "address", title: "Address", show: true, align: tableAlignCenter },
  // { field: "state", title: "State", show: true, align: tableAlignCenter },
  // { field: "city", title: "City", show: true, align: tableAlignCenter },
  // { field: "country", title: "Country", show: true, align: tableAlignCenter },

  { field: "value", title: "Investment Value", show: true },
  { field: "rental_incom_PA", title: "Rental Income P A", show: true },
  { field: "loan_amount", title: "Loan Amount", show: true },
  {
    field: "remaining_loan_amount",
    title: "Remaining Loan Amount",
    show: true,
  },
  { field: "market_value", title: "Market Value", show: true },
  { field: "self_funding_amount", title: "Self Funding", show: true },
  { field: "maintainence_PA", title: "Maintainence P A", show: true },
  { field: "property_tax_PA", title: "Property Tax P A", show: true },
  {
    field: "annual_interest_paid_on_loan",
    title: "Annual Interest Paid on Loan",
    show: true,
  },
  {
    field: "holdingPeriod",
    title: "Holding Period",
    show: true,
    align: tableAlignCenter,
  },
  { field: "address", title: "Address", show: false, align: tableAlignCenter },
  { field: "state", title: "State", show: false, align: tableAlignCenter },
  { field: "city", title: "City", show: false, align: tableAlignCenter },
  { field: "country", title: "Country", show: false, align: tableAlignCenter },
];

const tabData = [
  {
    page: "holding",
    title: "Holding Summary",
    path: routes.realEstateSummaryPage_holding,
  },
  {
    page: "transaction",
    title: "Transaction Summary",
    path: routes.realEstateSummaryPage_transection,
  },
];

module.exports = {
  holdingTableCOnfig,
  trasactionTableConfig,
  tabData,
};
