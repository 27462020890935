import { useState, useEffect, useContext } from "react";
import { OnboardingContext } from "../Context";
import { fetchHoldingPeriod, fetchOther_or_Memberuser } from "../api";

export const useFetchOthersOrMembers = (
  usertype,
  params,
  selectedClientId,
  disable,
  setTotal
) => {
  console.log(selectedClientId, "afkjsdfbj");
  const { userState } = OnboardingContext();
  // const { mfoFilter } = useContext(mfoFilterContext);
  const [Others_Members, setOthers_Members] = useState();
  const [param, setParams] = useState(params);
  const [userType, setUsertype] = useState(usertype);
  const [loading, setLoading] = useState(false);
  let clientId = selectedClientId || userState.loginedUserData.id;

  const handleCall = async () => {
    if (disable && userType == "member") {
      return;
    }

    setLoading(true);
    fetchOther_or_Memberuser(clientId, userType, param)
      .then((response) => {
        const apiData = response?.data?.data?.result;
        console.log("ipoData", apiData);
        setOthers_Members(apiData);
        setTotal(response?.data?.data?.meta?.count);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(`An Error ${err} occurred`);
      });
  };

  useEffect(() => {
    // if (Object.keys(mfoFilter.checkData).length > 0) {
    //   params["memberId"] = Object.keys(mfoFilter.checkData).join(",");
    // }
    // if (cancelCall) return;
    //#TODO for testing
    // clientId = 353;
    handleCall();
  }, [userType, param]);
  console.log(loading, "aglnagj");
  return [Others_Members, setUsertype, setParams, userType, loading, param];
};
