import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { useIpoDetails } from "../../../../../hooks/useIpoDetails";
import useStyles from "../../common/ProductsStyles";
import moment from "moment";
import DataNotFound from "../../common/DataNotFound";
import { Link } from "react-router-dom";
import Loader from "../../../../loader/Loader";
import { dayFormatter2 } from "../../../../utils/common";
const tableColumnsData = [
  { field: "Companies", title: "Companies", show: true },
  { field: "Bid Starts", title: "Bid Starts", show: true },
  { field: "Price Range", title: "Price Range", show: true },
];

function IpoDetailsUpcoming() {
  const [ipo, loading] = useIpoDetails("upcoming");
  const classes = useStyles();

  const [tableColumns, setTableColumns] = useState([tableColumnsData]);
  const tableHeaders = (headers) => {
    return headers?.map((column) =>
      column && column.show ? (
        <TableCell
          style={{ borderBottom: "none", textTransform: "capitalize" }}
          key={column.title}
          align="center"
          className={`${classes.tableHeadBlueText} thStyle`}
        >
          {column.title}
        </TableCell>
      ) : null
    );
  };
  //   const tableBody = (body) => {
  //     return body?.map((column) =>
  //       column && column.show ? (
  //         <TableCell
  //           key={column.title}
  //           align="center"
  //           className={`${classes.rowTextNew} equitytd`}
  //         >
  //           {column.title}
  //         </TableCell>
  //       ) : null
  //     );
  //   };
  const tableBody = (body) => {
    return body?.map((row) => (
      <TableRow
        key={row.CO_CODE}
        style={{
          background: " linear-gradient(190deg,#220053 42.89%,#3A0C7E 100%)",
          border: " 1px solid #4E189F",
          boxShadow:
            "inset -4px -4px 10px rgba(76, 50, 117, 0.5), inset 4px 4px 10px rgba(0, 0, 0, 0.5)",
          borderRadius: " 13px",
          // transform: "rotate(-180deg)"
        }}
        className="equitytr"
      >
        <TableCell key="companyName" align="center">
          {/* Comment route since details page api not ready */}
          <Link
            // to={`/home/equity/equity-summary/ipo/ipodetails`}
            state={{ data: row }}
            className={`${classes.rowTextNew} equitytd`}
          >
            {row?.companyName || row?.LNAME || "--"}
          </Link>
        </TableCell>
        <TableCell
          key="OPENDATE"
          align="center"
          className={`${classes.rowTextNew} equitytd`}
        >
          {/* {moment(row.OPENDATE).format("Do MMMM hh:mm A")} */}
          {dayFormatter2(row?.OPENDATE)}
        </TableCell>
        <TableCell
          key="priceRange"
          align="center"
          className={`${classes.rowTextNew} equitytd`}
        >
          {row.priceRange}
        </TableCell>
      </TableRow>
    ));
  };
  return (
    <>
      {loading && <Loader />}
      <div style={{ minHeight: "500px" }} className={classes.mainContent}>
        <div className={classes.tableContainerAlign}>
          <Table style={{ borderCollapse: "separate", borderSpacing: "0 8px" }}>
            <TableHead style={{ background: "#1D1B36", borderRadius: "20px" }}>
              <TableRow className="tableRowStyle equityTable">
                {tableHeaders(tableColumns[0])}
              </TableRow>
            </TableHead>

            <TableBody className="tableBodyAif">
              {/* <TableRow
                style={{
                  background:
                    " linear-gradient(190deg,#220053 42.89%,#3A0C7E 100%)",
                  border: " 1px solid #4E189F",
                  boxShadow:
                    "inset -4px -4px 10px rgba(76, 50, 117, 0.5), inset 4px 4px 10px rgba(0, 0, 0, 0.5)",
                  borderRadius: " 13px",
                  // transform: "rotate(-180deg)"
                }}
                className="equitytr"
              > */}
              {/* {tableBody(tableColumns[0])} */}
              {ipo?.length == 0 ? (
                <tr>
                  <td colSpan={3} className="dataNotFound">
                    <DataNotFound />
                  </td>
                </tr>
              ) : (
                tableBody(ipo)
              )}

              {/* </TableRow> */}
            </TableBody>
            {/* <DataNotFound /> */}
          </Table>
        </div>
      </div>
    </>
  );
}

export default IpoDetailsUpcoming;
