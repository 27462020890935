import React, { useEffect, useState } from "react";
import TableSummaryDetails from "../../../products/newDesigns/common/newSummaryDetailsTable/TableSummaryDetails";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { tableReportDownloadConfig, stdTableData } from "./constands";
import {
  dateFormatCommon2,
  dateFormatCommon3,
  dateFormatCommon4,
  handleKey,
} from "../../../../utils/common";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ReactComponent as Failed } from "../../../../../assets/icons/failed.svg";
import GetAppIcon from "@material-ui/icons/GetApp";
import useGetReportStatusTable from "../../../../../hooks/useGetReportStatusTable";
import PageLoader from "../../../products/common/pageLoader";
import { getPrintDataMongo } from "../../../../../api";
import { Link } from "react-router-dom";
import ls from "localstorage-slim";
// import fileDownload from "js-file-download";
// import axios from "axios";

export default function ReportCenterTable() {
  const [header, setHeader] = useState([]);
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  const { data, loading } = useGetReportStatusTable();
  const scopes = JSON.parse(ls.get("scopes"));

  // function download(url, filename) {
  //   axios
  //     .get(url, {
  //       responseType: "blob",
  //     })
  //     .then((res) => {
  //       fileDownload(res.data, filename);
  //     });
  // }

  const download = (url) => {
    saveAs(url);
  };
  function getexcel(id) {
    getPrintDataMongo({
      // mongoDataId: "65604c3693a720206ae3411a",
      // mongoDataId: "65670ed0fda1a6d051a3304e",
      mongoDataId: id,
    }).then((res) => {
      download(res.data.data.result.download_link);
      console.log(res.data.data.result.s3_url, "res res");
    });
  }

  function routeToPdf(type) {
    switch (type) {
      case "Transaction Summary Report":
        return "/transaction";
      case "Holding Summary Report":
        return "/holding";
      case "Consolidated Securities Holding Summary Report":
        return "/security-holding";
      case "Client Portfolio Report":
        return "/client-portfolio-report";
      default:
        return "";
    }
  }
  const handleRow = (row, headerData) => {
    let newCOl = headerData?.map((col) => {
      if (col?.field == "details") {
        return {
          ...col,
          field: (
            <>
              {/* <span className="dullTxt">Member Name:</span>{" "}
              {row["member_id"] || "--"} */}
              <span className="dullTxt">Duration:</span>{" "}
              {row["start_date"]
                ? dateFormatCommon3(row["start_date"], "YYYY-MM-DD") + "-"
                : "As on Date - "}{" "}
              {dateFormatCommon3(row["end_date"], "YYYY-MM-DD")}
            </>
          ),
          // color2: "#711CF1",
          // onClick: row?.onClick,
          dontShow: row?.dontShow,
          //   customCellClassName: "childCell2",
          customFontClassName: "fontReport2",
        };
      }

      if (col?.field === "created_at") {
        return {
          ...col,
          field: handleKey(col?.field, row)
            ? dateFormatCommon4(new Date(handleKey(col?.field, row)))
            : "--",
          // color2: "#711CF1",
          onClick: row?.onClick,
          dontShow: row?.dontShow,
          // customCellClassName: "childCell2",
          customFontClassName: col?.customFontClassName
            ? col?.customFontClassName
            : "fontWeight500",
        };
      }

      if (col?.title === "STATUS") {
        return {
          ...col,
          field: (
            <>
              {((row["status"] === "Completed" && row.export_type == "XLS") ||
                (row["scheduled_status"] === "pdf_generated" &&
                  row?.["is_scheduled"] == 1) ||
                row["status"] === "Pdfgenerated") && (
                <div
                  style={{
                    display: "flex",
                    // justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  {/* <ExcelIcon /> */}
                  {row?.["is_scheduled"] == "1" ? (
                    <Link
                      style={{
                        textDecoration: "none",
                        color: "#fff",
                        cursor: "pointer",
                      }}
                      to={
                        "/home/reports/report?key=report&created_at=" +
                        row?.created_at
                      }
                    >
                      View Reports
                    </Link>
                  ) : (
                    <>
                      Download Report
                      {/* <Link
                    to={"/print-report" + routeToPdf(row.report_type)}
                    params={{
                      mongoId: row?.mongo_data_id,
                      denomination: row?.denomination,
                    }}
                    target="_blank"
                  >
                    test
                  </Link> */}
                      <span
                        className="reportIconWrapper"
                        onClick={(data) => {
                          console.log(row, "table data");
                          if (
                            row.export_type == "XLS" ||
                            row.export_type == "PDF"
                          ) {
                            return getexcel(row.mongo_data_id);
                          }

                          // window.open(
                          //   "/print-report" +
                          //     routeToPdf(row.report_type) +
                          //     `?id=${row?.mongo_data_id}`,
                          //   "_blank"
                          // );
                          // navigate("/print-report" + routeToPdf(row.report_type), {
                          //   state: {
                          //     mongoId: row?.mongo_data_id,
                          //     denomination: row?.denomination,
                          //   },
                          // });

                          col?.onClick(row);
                        }}
                      >
                        <GetAppIcon style={{ marginRight: "0px" }} />
                      </span>
                    </>
                  )}
                </div>
              )}

              {row["status"] === "Failed" && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Failed />
                  Request Failed
                </div>
              )}
              {(row["status"] === "Inprogress" ||
                row["status"] === "Pending" ||
                (row["scheduled_status"] != "pdf_generated" &&
                  row["status"] != "Failed" &&
                  row["status"] == "Completed" &&
                  row?.["is_scheduled"] == 1) ||
                (row.export_type == "PDF" &&
                  row["status"] != "Pdfgenerated" &&
                  row["status"] != "Failed" &&
                  row["scheduled_status"] != "pdf_generated")) && (
                <>
                  <CircularProgress
                    style={{
                      color: "white",
                      marginBottom: "-11px",
                      padding: "5px",
                    }}
                  />
                  Your report is being created
                </>
              )}
            </>
          ),
          dontShow: row?.dontShow,
        };
      }
      return {
        ...col,
        field: handleKey(col?.field, row) ? handleKey(col?.field, row) : "--",
        // color2: "#711CF1",
        onClick: row?.onClick,
        dontShow: row?.dontShow,
        // customCellClassName: "childCell2",
        customFontClassName: col?.customFontClassName
          ? col?.customFontClassName
          : "fontWeight500",
      };
    });

    return newCOl;
  };

  const handleHeader = (headerData) => {
    headerData?.map((val) => {
      if (val?.title == "STATUS") {
        val.onClick = (data) => {};
      }
    });
    setHeader(() => headerData);
  };

  useEffect(() => {
    handleHeader(tableReportDownloadConfig);
    if (scopes?.can_generate_download_excel_report) {
      if (!scopes?.can_generate_download_excel_report_tax) {
        let newlist = data?.filter((d) => {
          if (d.report_type != "Tax Report") {
            return true;
          }
        });
        setTableData(() => newlist);
      } else {
        setTableData(() => data);
      }
    } else {
      let newlist = data?.filter((d) => {
        if (
          scopes?.can_generate_download_excel_report_tax &&
          d.report_type == "Tax Report"
        ) {
          return true;
        } else return d.export_type !== "XLS";
      });
      setTableData(() => newlist);
    }
  }, [data]);

  return (
    <>
      <TableSummaryDetails
        headerData={header}
        data={tableData}
        customHandleRow={handleRow}
        customScopes={[
          scopes?.can_generate_download_excel_report,
          scopes?.can_generate_download_excel_report_tax,
        ]}
        customStyle={{
          headerStyle: { className: "customHeader1" },
          bodyStyle: { className: "custombody" },
        }}
        customIcon={<></>}
        // customIcon={customIcon}
      />
      <PageLoader showLoader={loading} />
    </>
  );
}
