import { Typography, Box } from "@material-ui/core";
import { useStyles } from "./CardStyles";
import { checkValue } from "./CardStyles";
export const GainsCardInfo = ({
  text,
  value,
  align,
  valueTextColor,
  customStyle,
  renderCustomTextComponent,
  renderCustomValueComponent,
}) => {
  const classes = useStyles();
  return (
    <Box style={customStyle}>
      {renderCustomTextComponent || (
        <Typography className={classes.innerCardText}>{text}</Typography>
      )}
      {renderCustomValueComponent || (
        <Typography
          className={classes.innerCardValue}
          style={{
            color: valueTextColor ? valueTextColor : checkValue(value).color,
          }}
          align={align}
        >
          {value}
        </Typography>
      )}
    </Box>
  );
};
