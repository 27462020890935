import React, { useEffect, useState } from "react";
import {
  FormControl,
  Grid,
  Button,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  FormHelperText,
  useTheme,
} from "@material-ui/core";
import Default from "../components/dashboard/defaultPages/Default";
import {
  ENTITY_TYPE_CLIENT,
  FILE_TYPES,
  PRODUCT_TYPES,
  STATUS_CHECK_TIMEOUT,
} from "../Constants";
import { OnboardingContext } from "../Context";
import Loader from "../components/loader/Loader";
import CustomizedSnackbars from "../components/common/CustomizedSnackbars";
import {
  downloadSampleFile,
  getClientList,
  uploadFile,
  uploadValuationFile,
  uploadBulk,
  uploadValuationFileUnitBased,
  uploadValuationFilePayoutReduction,
  uploadBulkV2,
  transactionFileStatus,
  uploadPromotorStake,
  uploadBulkUpcoming,
  uploadBulkUnderlyingTransaction,
} from "../api";
import { useLocation, useNavigate } from "react-router-dom";
import AsynchronousInput from "../components/dashboard/common/AsynchronousInput";
import {
  downloadSampleFileValidate,
  uploadSampleFileValidate,
} from "../hooks/validationFunctions";
import _ from "lodash";
import { saveAs } from "file-saver";
import { useRef } from "react";
import { getFileTypeByProduct } from "../components/utils/common";
import NewTabComp from "../components/dashboard/products/common/NewTabComp";
import ls from "localstorage-slim";
import excelLogo from "../assets/icons/excel.png";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ProductUploadValidation } from "../components/ProductUploadValidation/ProductUploadValidation";
import CircularProgress from "@material-ui/core/CircularProgress";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
const browseIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="69"
    height="61"
    viewBox="0 0 69 61"
    fill="none"
  >
    <path
      d="M36.389 15.3906L36.4803 15.4179L36.4843 15.4134C36.9191 15.4923 37.3505 15.232 37.4782 14.8008C38.6419 10.8908 42.308 8.15929 46.3924 8.15929C46.876 8.15929 47.2682 7.76708 47.2682 7.28353C47.2682 6.79996 46.876 6.40778 46.3924 6.40778C41.3726 6.40778 37.1543 9.75002 35.7998 14.3016C35.6616 14.7652 35.9257 15.2524 36.389 15.3906Z"
      fill="white"
      stroke="#F9FFF9"
      stroke-width="0.297963"
    />
    <path
      d="M56.5667 42.895H52.2061C51.8049 42.895 51.4794 42.5695 51.4794 42.1682C51.4794 41.767 51.8048 41.4415 52.2061 41.4415H56.5667C62.5774 41.4415 67.4681 36.5508 67.4681 30.5401C67.4681 24.5293 62.5774 19.6386 56.5667 19.6386H56.4618C56.251 19.6386 56.0506 19.5473 55.9126 19.3879C55.7745 19.2285 55.7122 19.0172 55.7423 18.8086C55.8072 18.3559 55.8399 17.9011 55.8399 17.4584C55.8399 12.249 51.6012 8.01039 46.3919 8.01039C44.3653 8.01039 42.4326 8.64371 40.8025 9.84234C40.4444 10.1055 39.9356 9.98873 39.7285 9.59472C35.112 0.804045 23.0543 -0.376453 16.803 7.27066C14.1695 10.4923 13.1348 14.6831 13.9639 18.7676C14.0553 19.2187 13.7101 19.6392 13.2517 19.6392H12.9605C6.9497 19.6392 2.05906 24.5298 2.05906 30.5406C2.05906 36.5514 6.9497 41.442 12.9605 41.442H17.321C17.7223 41.442 18.0478 41.7675 18.0478 42.1688C18.0478 42.5701 17.7223 42.8956 17.321 42.8956H12.9605C6.14808 42.8956 0.605469 37.353 0.605469 30.5406C0.605469 23.9193 5.84123 18.4976 12.39 18.1986C11.7748 13.961 12.9511 9.68657 15.6775 6.3508C22.3704 -1.8372 35.197 -0.919431 40.619 8.21078C42.3488 7.12634 44.3281 6.55745 46.3917 6.55745C52.7031 6.55745 57.7043 11.9293 57.2667 18.2053C63.7551 18.5692 68.9213 23.9623 68.9213 30.54C68.9213 37.353 63.3787 42.895 56.5663 42.895L56.5667 42.895Z"
      fill="white"
    />
    <path
      d="M16.3478 41.7579C16.3478 51.8588 24.5653 60.0761 34.666 60.0761C44.7669 60.0761 52.9843 51.8586 52.9843 41.7579C52.9843 31.657 44.7669 23.4396 34.666 23.4396C24.5651 23.4396 16.3478 31.6571 16.3478 41.7579ZM18.0996 41.7579C18.0996 32.6234 25.5314 25.1915 34.666 25.1915C43.8005 25.1915 51.2325 32.6233 51.2325 41.7579C51.2325 50.8923 43.8005 58.3243 34.666 58.3243C25.5316 58.3243 18.0996 50.8925 18.0996 41.7579Z"
      fill="white"
      stroke="#F9FFF9"
      stroke-width="0.297963"
    />
    <path
      d="M34.3172 49.0724C34.3172 49.4484 34.6222 49.7534 34.9983 49.7534C35.3743 49.7534 35.6793 49.4489 35.6793 49.0724V35.2384C35.6793 34.8623 35.3743 34.5573 34.9983 34.5573C34.6222 34.5573 34.3172 34.8623 34.3172 35.2384V49.0724Z"
      fill="white"
      stroke="#483EA8"
      stroke-width="0.297963"
    />
    <path
      d="M34.9981 36.2026L31.2233 39.9775C31.2232 39.9775 31.2232 39.9775 31.2232 39.9776C30.9574 40.2437 30.526 40.2436 30.26 39.9776C29.9939 39.7115 29.9939 39.2805 30.26 39.0144L34.5164 34.7579C34.7822 34.4917 35.2137 34.4918 35.4797 34.7578L39.7363 39.0144C40.0023 39.2805 40.0023 39.7115 39.7363 39.9776C39.6032 40.111 39.4283 40.177 39.2547 40.177L34.9981 36.2026ZM34.9981 36.2026L38.7731 39.9776C38.9059 40.1104 39.0807 40.177 39.2547 40.177L34.9981 36.2026Z"
      fill="white"
      stroke="#483EA8"
      stroke-width="0.297963"
    />
  </svg>
);

const downloadIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
  >
    <rect
      opacity="0.7"
      x="0.172913"
      y="0.731934"
      width="24.611"
      height="24.611"
      rx="12.3055"
      fill="#090513"
    />
    <path
      d="M16.8732 11.4863H14.3619V7.76367H10.5949V11.4863H8.08359L12.4784 15.8294L16.8732 11.4863ZM8.08359 17.0703V18.3112H16.8732V17.0703H8.08359Z"
      fill="white"
    />
  </svg>
);
const useStyles = makeStyles((theme) => ({
  contentBox: {
    background: "rgba(11, 3, 24, 0.6)",
    border: "1px solid #4E189F",
    boxShadow: "0px 0px 10px #4E189F",
    backdropFilter: "blur(50px)",
    borderRadius: "50px",
    position: "relative",
    // left: "10%",
    width: "80%",
    top: "20px",
    margin: "15px 20px 20px 20px",
    [theme.breakpoints.down("md")]: {
      left: "10%",
      width: "65%",
      top: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      left: "5%",
      width: "90%",
      top: "20px",
    },
  },
  mainContent: {
    padding: "15px 20px 20px 20px",
  },
  gridContainer: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  icon: {
    fill: "#FAF9FF",
  },
  text: {
    fontStyle: "normal",
    fontFamily: "Avenir",
  },
  inputField: {
    borderColor: " #711CF1",
    borderRadius: "20px",
    width: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "6px !important",
      border: "none !important",
      background: "#000 !important",
    },
  },
  inputGrid: {
    // marginTop: "20px",
  },
  formContent: {
    marginLeft: "15%",
    marginTop: "10px",
    height: "340px",
    overflowY: "scroll",
  },

  iconborder: {
    border: "1px solid #4E189F",
    backdropFilter: "blur(50px)",
    borderRadius: "30px",
    margin: "20px 5px 5px 5px",
    padding: "22px 55px 10px 44px",
  },
  uploadicon: {
    position: "absolute",
    top: "-30px",
    left: "-50px",
  },
  formControl: {
    // border: "1px solid #4E189F",
  },
  boxfileuploadContainer: {
    background: "#220848",
    border: "1px dashed #FFFFFF",
    boxShadow:
      "-6.22302px -6.22302px 18.6691px #302A64, 6.22302px 6.22302px 18.6691px #000000",
    borderRadius: "20px",
    // width: "98%",
  },
  line: {
    opacity: "0.2",
    backgroundColor: "#D4BBFB",
    border: ".5px solid #D4BBFB",
    marginBlock: "2em",
    width: "100%",
  },
  buttonContainer: {
    textAlign: "center",
    // marginTop: "5px",
    // marginBottom: "10px",
  },
  button: {
    paddingTop: "10px",
    // width: "80%",
    color: "rgb(255, 255, 255)",
    background:
      "linear-gradient(rgb(170, 111, 254) 0%, rgb(113, 28, 241) 100%)",
    borderRadius: "20px",
    paddingBottom: "10px",
    paddingInline: "6em",
    textTransform: "capitalize",
  },
  buttonText: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#FFFFFF",
  },
  nobuttonui: {
    color: "#fff",
    // background: "transparent",
    textAlign: "center",
    padding: "1% 36%",
    width: "100%",
    background: "#220848",
    height: "228px",
    borderRadius: "20px",
  },
  uploadsvg: {
    width: "13%",
    paddingTop: "10%",
  },
  downloadformat: {
    color: "#fff",
    fontSize: "16px",
    textAlign: "center",
  },
  downloadicon: {
    fontSize: "15px",
    padding: "0px 4px",
    verticalAlign: "bottom",
  },
  downloadtext: {
    color: "#3A56FF",
    cursor: "pointer",
  },
  customLinearProgress: {
    height: "2.98px !important",
    borderRadius: "10px !important",
    // backgroundColor: "red !important",
    "& .MuiLinearProgress-bar": {
      backgroundColor: "#B65BFF !important",
    },
  },
  selectDropDownMenu: {
    borderRadius: "4px !important",
    border: `1.164px solid  ${theme.palette.selectDropDown.border}!important`,
  },
}));
const VALIDATION_CHECKLIST_PAGE = "validation-checklist";

function ProductUpload() {
  const classes = useStyles();
  const [clientList, setClientList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const scopes = JSON.parse(ls.get("scopes"));
  const [page, setPage] = useState("Data Upload");
  const [progressbar, setProgressBar] = useState(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const keyValue = searchParams.get("key");
  const theme = useTheme();
  const handleTabClick = (page) => {
    setPage(page);
    setFormdata((prev) => {
      return { ...prev, entity: "", fileType: "", file: "" };
    });
    setTransactionStatus(true);
  };

  const {
    userState: { loginedUserData },
    dispatch,
  } = OnboardingContext();
  const [errors, setErrors] = useState(null);
  const [formData, setFormdata] = useState({
    clientId:
      loginedUserData?.user_type === ENTITY_TYPE_CLIENT
        ? loginedUserData?.id
        : "",
    entity: "",
    fileType: "",
    file: "",
  });

  const hiddenFileInput = useRef(null);
  const [uploadErrors, setUploadErrors] = useState("");
  const [transactionStatus, setTransactionStatus] = useState(true);

  // 👇 STATUS API
  const fetchTransactionStatus = () => {
    if (page === "Data Upload" || page === "Bulk Upload") {
      if (
        formData.entity !== "" &&
        formData.fileType !== "" &&
        formData.fileType != "promoter_stake"
      ) {
        transactionFileStatus({
          product_type: formData.entity,
          file_type: formData.fileType,
        })
          .then((res) => {
            if (res?.data?.data?.result?.length === 0) {
              setTransactionStatus(true);
            } else {
              setTransactionStatus(false);
            }
          })
          .catch((error) => console.log(`An ${error} has occurred`));
      }
    }
  };
  useEffect(() => {
    if (
      formData.entity !== "" &&
      formData.fileType !== "" &&
      formData.fileType != "promoter_stake"
    ) {
      fetchTransactionStatus();
      const interval = setInterval(
        fetchTransactionStatus,
        STATUS_CHECK_TIMEOUT * 1000
      ); // 6 min
      return () => clearInterval(interval);
    }
  }, [formData]);
  console.log("Trannsaction statysdas", transactionStatus);
  const [tabData, setTabData] = useState([
    {
      title: "Data Upload",
      page: "Data Upload",
      path: "/product-upload",
    },
  ]);

  const saveFile = (url) => {
    saveAs(url);
  };
  // greater than equal to 1 then don't allow to upload

  const fetchClientList = async (status = "approved", searchValue = "") => {
    setShowLoader(true);

    try {
      const data = await getClientList(status, searchValue);
      const clientList = data.data;

      if (clientList.success) {
        setShowLoader(false);
        setClientList(clientList.data.result);
      }
    } catch (error) {
      setShowLoader(false);
      if (error.response.request.status === 401) {
        navigate("/logout");
      } else if (!error.response.data.error.success) {
        dispatch({
          type: "UPDATE_SNACKBAR_DATA",
          payload: {
            success: false,
            error: true,
            showMsg: true,
            message: error.response.data.error.message,
          },
        });
      }
    }
  };

  const submitForm = async (values) => {
    setShowLoader(true);

    try {
      const formData = new FormData();
      formData.append("file", values.file);
      const onUploadProgress = (progressEvent) => {
        // Calculate progress percentage
        const progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        console.log("progressEvent", progressEvent, progress);
        setProgressBar(progress);
      };

      let data;
      if (values?.fileType == "valuation") {
        data = await uploadValuationFile(
          values.entity + "/" + values?.fileType,
          values.clientId,
          formData,
          onUploadProgress
        );
      } else if (values?.fileType == "underlying_transaction") {
        data = await uploadFile(
          values?.entity + "/underlying-transaction",
          values.clientId,
          formData,
          values?.fileType,
          onUploadProgress
        );
      } else {
        data = await uploadFile(
          values?.entity,
          values.clientId,
          formData,
          values?.fileType,
          onUploadProgress
        );
      }
      const uploadData = data.data;

      if (uploadData.success) {
        setShowLoader(false);
        if (uploadData?.data?.result?.validation_status) {
          setTransactionStatus(false);
        } else {
          setTransactionStatus(true);
        }
        hiddenFileInput.current.value = null;
        setFormdata((prev) => ({
          ...formData,
          clientId:
            loginedUserData?.user_type === ENTITY_TYPE_CLIENT
              ? loginedUserData?.id
              : prev?.clientId
              ? prev?.clientId
              : "",
          entity: "",
          fileType: "",
          file: undefined,
        }));
        dispatch({
          type: "UPDATE_SNACKBAR_DATA",
          payload: {
            success: true,
            error: false,
            showMsg: true,
            message: uploadData.message,
          },
        });
      }
    } catch (error) {
      setShowLoader(false);
      setTransactionStatus(true);

      if (error.response.request.status === 401) {
        navigate("/logout");
      } else if (!error.response.data.error.success) {
        dispatch({
          type: "UPDATE_SNACKBAR_DATA",
          payload: {
            success: false,
            error: true,
            showMsg: true,
            message: error.response.data.error.message,
          },
        });
      }
      if (error?.response?.data?.error?.code == "SCHEMA_ERROR") {
        hiddenFileInput.current.value = null;
        setUploadErrors(error?.response?.data?.error?.fields[0]?.description);
        setFormdata((prev) => ({
          ...prev,
          file: undefined,
        }));
      }
    }
  };

  const submitBulkUploadForm = async (values) => {
    setShowLoader(true);

    try {
      const formData = new FormData();
      formData.append("file", values.file);
      formData.append("productType", values.entity);
      const onUploadProgress = (progressEvent) => {
        // Calculate progress percentage
        const progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        console.log("progressEvent", progressEvent, progress);
        setProgressBar(progress);
      };

      let data;
      if (values?.fileType == "valuation") {
        data = await uploadBulk("valuation", formData);
      } else if (values?.fileType === "upcoming_transaction") {
        data = await uploadBulkUpcoming(
          "client/upcoming-transaction",
          formData,
          onUploadProgress
        );
      } else if (values?.fileType === "underlying_transaction") {
        data = await uploadBulkUnderlyingTransaction(
          "client/underlying-transaction",
          formData,
          onUploadProgress
        );
      } else if (values?.fileType === "promoter_stake") {
        data = await uploadPromotorStake(
          "promoter-stake",
          formData,
          onUploadProgress
        );
      } else if (values?.fileType === "bonds-payout-details") {
        data = await uploadValuationFilePayoutReduction(
          "bonds-payout-details",
          formData,
          onUploadProgress
        );
      } else if (values?.fileType === "unit-based/valuation") {
        data = await uploadValuationFileUnitBased(
          values.entity + "/unit-based/valuation",
          formData,
          onUploadProgress
        );
      } else {
        data = await uploadBulkV2("transaction", formData, onUploadProgress);
      }
      const uploadData = data.data;

      if (uploadData.success) {
        setShowLoader(false);
        if (uploadData?.data?.result?.validation_status) {
          setTransactionStatus(false);
        } else {
          setTransactionStatus(true);
        }
        hiddenFileInput.current.value = null;
        setFormdata((prev) => ({
          ...formData,
          entity: "",
          fileType: "",
          file: undefined,
        }));
        dispatch({
          type: "UPDATE_SNACKBAR_DATA",
          payload: {
            success: true,
            error: false,
            showMsg: true,
            message: uploadData.message,
          },
        });
      }
    } catch (error) {
      setShowLoader(false);
      setTransactionStatus(true);

      if (error.response.request.status === 401) {
        navigate("/logout");
      } else if (!error.response.data.error.success) {
        dispatch({
          type: "UPDATE_SNACKBAR_DATA",
          payload: {
            success: false,
            error: true,
            showMsg: true,
            message: error.response.data.error.message,
          },
        });
      }
      if (error?.response?.data?.error?.code == "SCHEMA_ERROR") {
        hiddenFileInput.current.value = null;
        setUploadErrors(error?.response?.data?.error?.fields[0]?.description);
        setFormdata((prev) => ({
          ...prev,
          file: undefined,
        }));
      }
    }
  };

  const handleSubmit = () => {
    const errorData = uploadSampleFileValidate(formData, "", page);
    setErrors(errorData);
    // console.log(errorData, formData, "abfoiuabwfoaw");

    if (_.isEmpty(errorData)) {
      if (page === "Bulk Upload") {
        submitBulkUploadForm(formData);
        return;
      }
      submitForm(formData);
    }
  };

  useEffect(() => {
    fetchClientList();
  }, []);
  useEffect(() => {
    const keyValue = searchParams.get("key");
    if (keyValue) {
      setPage(keyValue);
    }
  }, [location.search]);

  const downloadFile = async (values) => {
    setShowLoader(true);
    try {
      // let newData = await entityHandler(values)
      const data = await downloadSampleFile(values.entity, values.fileType);
      const downloadData = data.data;

      if (downloadData.success) {
        setShowLoader(false);
        const url = downloadData.data.result.s3_url;
        saveFile(url);

        dispatch({
          type: "UPDATE_SNACKBAR_DATA",
          payload: {
            success: true,
            error: false,
            showMsg: true,
            message: downloadData.message,
          },
        });
      }
    } catch (error) {
      setShowLoader(false);
      if (error.response.request.status === 401) {
        navigate("/logout");
      } else if (!error.response.data.error.success) {
        dispatch({
          type: "UPDATE_SNACKBAR_DATA",
          payload: {
            success: false,
            error: true,
            showMsg: true,
            message: error.response.data.error.message,
          },
        });
      }
    }
  };

  const handleDownload = () => {
    const errorData = downloadSampleFileValidate(formData);
    setErrors(errorData);

    if (_.isEmpty(errorData)) {
      downloadFile(formData);
    }
  };

  const handleChange = (e) => {
    let newFormData = { ...formData, [e.target.name]: e.target.value };
    if (e.target.name === "entity") {
      let defaultFileType = "";
      newFormData.fileType = defaultFileType;
    }
    setFormdata(newFormData);

    //setFormdata({ ...formData, [e.target.name]: e.target.value });
    const errorData = uploadSampleFileValidate(
      { ...formData, [e.target.name]: e.target.value },
      e.target.name
    );
    setErrors(errorData);
  };

  const handleFileChange = (e) => {
    setUploadErrors("");
    setFormdata({ ...formData, [e.target.name]: e.target.files[0] });

    const errorData = uploadSampleFileValidate(
      { ...formData, [e.target.name]: e.target.files[0] },
      e.target.name
    );
    setErrors(errorData);
  };
  const handleDrop = (e) => {
    e.preventDefault();
    setUploadErrors("");
    setFormdata({ ...formData, ["file"]: e.dataTransfer.files[0] });
    const errorData = uploadSampleFileValidate(
      { ...formData, ["file"]: e.dataTransfer.files[0] },
      "file"
    );
    setErrors(errorData);
  };
  console.log("handleDrop", formData);

  const handleAsncChange = (name, value) => {
    setFormdata({ ...formData, [name]: value });

    const errorData = uploadSampleFileValidate(
      { ...formData, [name]: value },
      name
    );
    setErrors(errorData);
  };
  const isValidationCheckListPage =
    page === VALIDATION_CHECKLIST_PAGE ||
    keyValue === VALIDATION_CHECKLIST_PAGE;
  const renderFooterContentBasedOnStatus = (transactionStatus) => {
    if (transactionStatus) {
      return (
        <button className="productUploadBtn " onClick={handleSubmit}>
          Upload
        </button>
      );
    } else {
      return (
        <div style={{ width: "351px" }}>
          <p style={{ textAlign: "center" }}>
            Once the verification process is complete, you will receive an
            instant notification.
          </p>
        </div>
      );
    }
  };

  useEffect(() => {
    let updatedTabData = [
      {
        title: "Data Upload",
        page: "Data Upload",
        path: "/product-upload",
      },
    ];

    if (scopes.can_upload_data_file_bulk) {
      updatedTabData.push({
        page: "Bulk Upload",
        title: "Bulk Upload",
        path: "/product-upload/?key=Bulk Upload",
      });
    }

    if (scopes.can_view_verification_checklist) {
      updatedTabData.push({
        title: "Validation Check List",
        page: VALIDATION_CHECKLIST_PAGE,
        path: `/product-upload/?key=${VALIDATION_CHECKLIST_PAGE}`,
      });
    }

    setTabData(updatedTabData);
  }, []);

  // Function to create tab mapping based on user permissions
  const createTabMapping = () => {
    if (scopes.can_upload_data_file_bulk) {
      return {
        "data-upload": 0,
        "Bulk Upload": 1,
        "validation-checklist": 2,
      };
    } else {
      return {
        "data-upload": 0,
        "validation-checklist": 1,
      };
    }
  };

  const tabMapping = createTabMapping();

  const activeTabIndex = tabMapping[keyValue] || 0;

  // For Data Upload don't show funds-int-transit
  const productTypesBasedOnPage =
    page === "Data Upload"
      ? PRODUCT_TYPES.filter((type) => type.value !== "funds-in-transit")
      : PRODUCT_TYPES;
  return (
    <Default>
      {showLoader && <Loader />}
      <CustomizedSnackbars />
      <div className="productContainerWrapper">
        {/* {page === "Data Upload" ? "" : "Bulk Upload"} */}
        {/* Display "Bulk Upload" only if the user does not have permission to upload data files in bulk */}
        <div
          className="productUploadContainer"
          style={{ background: "none", boxShadow: "none", marginBottom: 0 }}
        >
          <NewTabComp
            data={tabData}
            customWidth={"100%"}
            customHandle={handleTabClick}
            customSelect={activeTabIndex}
          />
        </div>

        {isValidationCheckListPage ? (
          <ProductUploadValidation />
        ) : (
          <div
            className="dropDown&UploadContainer"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
              width: "100%",
            }}
          >
            <div className="productUploadContainer">
              <div className="productUploadHeadingSubHeadingWrapper">
                <h1 className="productUploadContainerHeading">{page}</h1>
                <p className="productUploadContainerSubHeading">
                  The User Management screen provides administrators with a
                  comprehensive view and control over user accounts and their
                  associated settings.
                </p>
              </div>
              <div className="productUploadSelectDropDown">
                {loginedUserData?.user_type !== ENTITY_TYPE_CLIENT && (
                  <>
                    {/* Show Client DropDown Only if Data Upload is Selected */}
                    {page === "Data Upload" && (
                      <div className="selectClient">
                        <p className="selectDropDownLabel">Client</p>
                        <AsynchronousInput
                          data={clientList?.length > 0 ? clientList : []}
                          getSearchData={(value) =>
                            fetchClientList("approved", value)
                          }
                          errors={errors}
                          searchLoader={showLoader}
                          label="Client"
                          optionTitle="full_name"
                          fieldName="clientId"
                          objectFieldName="id"
                          formData={formData}
                          handleChange={(name, value) =>
                            handleAsncChange(name, value)
                          }
                          // helperText={errors?.clientId}
                        />
                      </div>
                    )}
                  </>
                )}
                <div
                  className={
                    page === "Data Upload"
                      ? "selectProduct"
                      : "selectProductBulkUpload"
                  }
                >
                  <FormControl
                    variant="outlined"
                    className={`${classes.formControl} ${classes.inputField}`}
                  >
                    {/* <InputLabel
                  id="demo-simple-select-outlined-label"
                  style={{ background: "black !important" }}
                >
                  Select Product
                </InputLabel> */}
                    {/* <div className="selectDropDown"> */}
                    <p className="selectDropDownLabel">Select Product</p>
                    <Select
                      // For PlaceHolder
                      displayEmpty
                      renderValue={
                        formData?.entity !== ""
                          ? undefined
                          : () => (
                              <span style={{ color: "#59595B" }}>
                                Select Product
                              </span>
                            )
                      }
                      // native
                      style={{ color: "#FAF9FF" }}
                      labelId="demo-simple-select-outlined-label"
                      // label="Select Product"
                      value={formData?.entity}
                      onChange={(e) => handleChange(e)}
                      error={Boolean(errors?.entity)}
                      inputProps={{
                        name: "entity",
                        id: "outlined-entity_type-native-simple",
                        classes: {
                          icon: classes.icon,
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                            backgroundColor:
                              theme.palette.selectDropDown.background,
                            border: `1.164px solid ${theme.palette.selectDropDown.border}`,
                            marginTop: "1rem",
                          },
                        },
                        MenuListProps: {
                          className: classes.selectDropDownMenu,
                        },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {productTypesBasedOnPage.map((product) => {
                        return (
                          <option
                            className="optiondesignNew"
                            key={product.value}
                            value={product.value}
                          >
                            {product.name}
                          </option>
                        );
                      })}
                    </Select>
                    {/* </div> */}
                  </FormControl>
                </div>
                <div
                  className={
                    page === "Data Upload"
                      ? "selectType"
                      : "selectTypeBulkUpload"
                  }
                >
                  <FormControl
                    variant="outlined"
                    className={`${classes.formControl} ${classes.inputField}`}
                  >
                    {/* <InputLabel id="demo-simple-select-outlined-label">
                  Select Type
                </InputLabel> */}
                    <p className="selectDropDownLabel">Select Type</p>
                    <Select
                      // For PlaceHolder
                      displayEmpty
                      renderValue={
                        formData?.fileType !== ""
                          ? undefined
                          : () => (
                              <span style={{ color: "#59595B" }}>
                                Select Type
                              </span>
                            )
                      }
                      // native
                      style={{ color: "#FAF9FF" }}
                      labelId="demo-simple-select-outlined-label"
                      // label="Select Type"
                      value={formData?.fileType}
                      onChange={(e) => handleChange(e)}
                      error={Boolean(errors?.fileType)}
                      inputProps={{
                        name: "fileType",
                        id: "outlined-entity_type-native-simple",
                        classes: {
                          icon: classes.icon,
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                            backgroundColor:
                              theme.palette.selectDropDown.optionBackground,
                            border: `1px solid ${theme.palette.selectDropDown.border}`,
                            marginTop: "1rem",
                          },
                        },
                        MenuListProps: {
                          className: classes.selectDropDownMenu,
                        },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {getFileTypeByProduct(
                        formData?.entity,
                        page,
                        false,
                        "optiondesignNew"
                      )}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>

            {/* Upload Start */}
            <div className="productUploadContainer dragDrop">
              <div className="downloadFormatContainer">
                <img src={excelLogo} />

                <h1 className="downloadFormatTxt">Download Format</h1>
                <div className="downloadIcon" onClick={() => handleDownload()}>
                  {downloadIcon}
                </div>
              </div>

              <div
                className="browseContainer"
                onDragOver={(e) => {
                  e.preventDefault();
                }}
                onDrop={(e) => handleDrop(e)}
              >
                <div className="browseHeadingSubHeadingWrapper">
                  <div className="browseIcon">{browseIcon}</div>
                  <h1 className="browseHeading">
                    Drag & drop files or
                    <input
                      type="file"
                      name="file"
                      hidden
                      onChange={(e) => handleFileChange(e)}
                      ref={hiddenFileInput}
                    />
                    <span
                      className="browseTxt"
                      onClick={() => hiddenFileInput.current.click()}
                    >
                      Browse
                    </span>
                  </h1>
                  <p className="browseSubHeading">Supported Formats :- xlsx</p>
                  {!transactionStatus && (
                    <>
                      <div className="verificationInProgress">
                        <CircularProgress /> <p>Undergoing Verification</p>
                      </div>
                      <div className="notificationBell">
                        <NotificationsActiveIcon />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* Progress Bar Start */}
            {formData?.file && (
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <div className="progressBar">
                  <p className="uploadedFileName">{formData?.file?.name}</p>
                  <div
                    className="cancelBtn"
                    onClick={() => {
                      setFormdata((prev) => ({
                        ...prev,
                        file: "",
                      }));
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                    >
                      <path
                        d="M8.78571 16.6334C6.67845 16.6334 4.65724 15.7962 3.16727 14.3062C1.6772 12.816 0.840027 10.795 0.840027 8.68774C0.840027 6.58048 1.6772 4.55928 3.16727 3.06931C4.65746 1.57924 6.67845 0.742062 8.78571 0.742062C10.893 0.742062 12.9142 1.57924 14.4041 3.06931C15.8942 4.5595 16.7314 6.58048 16.7314 8.68774C16.7288 10.7943 15.891 12.814 14.4014 14.3034C12.9118 15.793 10.892 16.6309 8.78571 16.6334ZM11.8499 6.64413V6.64401C11.9892 6.50942 12.0686 6.32453 12.0703 6.1308C12.072 5.93707 11.9958 5.75074 11.8588 5.61372C11.7218 5.4767 11.5355 5.40053 11.3417 5.40222C11.148 5.40391 10.9631 5.48336 10.8285 5.62267L8.78571 7.66622L6.7429 5.62267C6.55945 5.44551 6.29619 5.37816 6.05011 5.44563C5.80414 5.51311 5.612 5.70526 5.54451 5.95123C5.47704 6.19731 5.54439 6.46056 5.72155 6.64402L7.76436 8.68757L5.72155 10.7311C5.58224 10.8657 5.5028 11.0506 5.5011 11.2443C5.49941 11.4381 5.57559 11.6244 5.7126 11.7614C5.84961 11.8984 6.03595 11.9746 6.22968 11.9729C6.42341 11.9712 6.60829 11.8918 6.74289 11.7525L8.7857 9.70891L10.8285 11.7525C10.9631 11.8918 11.148 11.9712 11.3417 11.9729C11.5354 11.9746 11.7218 11.8984 11.8588 11.7614C11.9958 11.6244 12.072 11.4381 12.0703 11.2443C12.0686 11.0506 11.9892 10.8657 11.8498 10.7311L9.80704 8.68757L11.8499 6.64413Z"
                        fill="#E6E6E6"
                      />
                    </svg>
                  </div>
                </div>
                <div style={{ width: "550px" }}>
                  <LinearProgress
                    variant="determinate"
                    value={progressbar}
                    className={classes.customLinearProgress}
                  />
                </div>
              </div>
            )}
            {/* Progress Bar End */}
            <footer
              style={{
                display: "grid",
                placeItems: "center",
              }}
            >
              {renderFooterContentBasedOnStatus(transactionStatus)}
              {/* <button className="productUploadBtn" onClick={handleSubmit}>
                Save
              </button> */}
            </footer>
          </div>
        )}

        {/* Upload End */}
      </div>
    </Default>
  );
}

export default ProductUpload;
