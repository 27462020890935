import React, { useEffect, useState } from "react";
import {
  FormControl,
  Grid,
  Button,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  FormHelperText,
  TextField,
  InputAdornment,
  DialogTitle,
  DialogContentText,
  useTheme,
} from "@material-ui/core";
import Default from "../components/dashboard/defaultPages/Default";
import YellowArrow from "../assets/icons/yellowNextArrow1.png";
import { ReactComponent as UploadBulbicon } from "../assets/icons/upload_bulb.svg";
import { ReactComponent as RightAlertIcon } from "../assets/icons/rightAlertIcon.svg";
import { ReactComponent as IIcon } from "../assets/icons/iIcon.svg";
import { ReactComponent as Close } from "../assets/icons/close_esx.svg";
import Icon from "@material-ui/core/Icon";
import {
  ENTITY_TYPE_CLIENT,
  FILE_TYPES,
  PRODUCT_TYPES,
  exportType,
  reportType,
} from "../Constants";
import { OnboardingContext } from "../Context";
import Loader from "../components/loader/Loader";
import CustomizedSnackbars from "../components/common/CustomizedSnackbars";
import {
  downloadSampleFile,
  getClientList,
  uploadFile,
  uploadValuationFile,
  uploadBulk,
  uploadValuationFileUnitBased,
  uploadValuationFilePayoutReduction,
  fetchClientByMultiId,
  RequestReport,
  fetchClientByPartner,
} from "../api";
import { useNavigate } from "react-router-dom";
import AsynchronousInput from "../components/dashboard/common/AsynchronousInput";
import {
  downloadSampleFileValidate,
  uploadSampleFileValidate,
} from "../hooks/validationFunctions";
import _ from "lodash";
import { saveAs } from "file-saver";
import { useRef } from "react";
import { getFileTypeByProduct } from "../components/utils/common";
import NewTabComp from "../components/dashboard/products/common/NewTabComp";
import ls from "localstorage-slim";
import excelLogo from "../assets/icons/excel.png";
import LinearProgress from "@material-ui/core/LinearProgress";
// import SelectDate from "../components/calendar/SelectDate";
import moment from "moment";
import { useForm } from "../components/NewSignUp/hooks/useForm";
import AsyncCheckbox from "../components/dashboard/common/AsyncCheckbox";
import { useAllClients } from "../components/dashboard/newFilter/hooks/useAllClients";
import {
  denominationOptions,
  denominations,
  taxFormat,
} from "../components/dashboard/newFilter/newFilterConstants";
import MultiSelect from "../components/dashboard/common/MultiSelect";
import SingleSelect from "../components/dashboard/common/SingleSelect";
import { printForm } from "../hooks/ValidationSchema";

import ErrorMessageComp from "../components/NewSignUp/common/ErrorMessageComp";
import { AlertDialog } from "../components/dashboard/common/AlertDialog";
const browseIcon = (
  <svg
    width="8"
    height="6"
    viewBox="0 0 8 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.27295 6L7.73705 0H0.808848L4.27295 6Z" fill="#C3AAFF" />
  </svg>
);

const useStyles = makeStyles((theme) => ({
  contentBox: {
    background: "rgba(11, 3, 24, 0.6)",
    border: "1px solid #4E189F",
    boxShadow: "0px 0px 10px #4E189F",
    backdropFilter: "blur(50px)",
    borderRadius: "50px",
    position: "relative",
    // left: "10%",
    width: "80%",
    top: "20px",
    margin: "15px 20px 20px 20px",
    [theme.breakpoints.down("md")]: {
      left: "10%",
      width: "65%",
      top: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      left: "5%",
      width: "90%",
      top: "20px",
    },
  },
  mainContent: {
    padding: "15px 20px 20px 20px",
  },
  gridContainer: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  icon: {
    fill: "#FAF9FF",
  },
  text: {
    fontStyle: "normal",
    fontFamily: "Avenir",
  },
  inputField: {
    borderColor: " #711CF1",
    borderRadius: "2px",
    width: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "6px !important",
      border: "none !important",
      background: "#000 !important",
    },
  },
  inputGrid: {
    // marginTop: "20px",
  },
  formContent: {
    marginLeft: "15%",
    marginTop: "10px",
    height: "340px",
    overflowY: "scroll",
  },

  iconborder: {
    border: "1px solid #4E189F",
    backdropFilter: "blur(50px)",
    borderRadius: "30px",
    margin: "20px 5px 5px 5px",
    padding: "22px 55px 10px 44px",
  },
  uploadicon: {
    position: "absolute",
    top: "-30px",
    left: "-50px",
  },
  formControl: {
    // border: "1px solid #4E189F",
  },
  boxfileuploadContainer: {
    background: "#220848",
    border: "1px dashed #FFFFFF",
    boxShadow:
      "-6.22302px -6.22302px 18.6691px #302A64, 6.22302px 6.22302px 18.6691px #000000",
    borderRadius: "20px",
    // width: "98%",
  },
  line: {
    opacity: "0.2",
    backgroundColor: "#D4BBFB",
    border: ".5px solid #D4BBFB",
    marginBlock: "2em",
    width: "100%",
  },
  buttonContainer: {
    textAlign: "center",
    // marginTop: "5px",
    // marginBottom: "10px",
  },
  button: {
    paddingTop: "10px",
    // width: "80%",
    color: "rgb(255, 255, 255)",
    background:
      "linear-gradient(rgb(170, 111, 254) 0%, rgb(113, 28, 241) 100%)",
    borderRadius: "20px",
    paddingBottom: "10px",
    paddingInline: "6em",
    textTransform: "capitalize",
  },
  buttonText: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#FFFFFF",
  },
  nobuttonui: {
    color: "#fff",
    // background: "transparent",
    textAlign: "center",
    padding: "1% 36%",
    width: "100%",
    background: "#220848",
    height: "228px",
    borderRadius: "20px",
  },
  uploadsvg: {
    width: "13%",
    paddingTop: "10%",
  },
  downloadformat: {
    color: "#fff",
    fontSize: "16px",
    textAlign: "center",
  },
  downloadicon: {
    fontSize: "15px",
    padding: "0px 4px",
    verticalAlign: "bottom",
  },
  downloadtext: {
    color: "#3A56FF",
    cursor: "pointer",
  },
  customLinearProgress: {
    height: "2.98px !important",
    borderRadius: "10px !important",
    // backgroundColor: "red !important",
    "& .MuiLinearProgress-bar": {
      backgroundColor: "#B65BFF !important",
    },
  },
  selectDropDownMenu: {
    borderRadius: "4px !important",
    border: `1.164px solid ${theme.palette.selectDropDown.border} !important`,
  },
}));

function FilterForm() {
  const classes = useStyles();
  const theme = useTheme();
  console.log("theme mui", theme.palette.selectDropDown.border);
  const [clientList, setClientList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const scopes = JSON.parse(ls.get("scopes"));
  const [page, setPage] = useState("Data Upload");
  const [progressbar, setProgressBar] = useState(null);

  const [clear, setClear] = useState(false);
  const [members, setMEmbers] = useState([]);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [reportBased, setReportBased] = useState("single");
  const [showNote, setShowNote] = useState(0);
  const [open, setOpen] = useState(false);
  const [showloading, setShowLoading] = useState({
    client: false,
    memberId: false,
  });

  const [key, setKey] = useState({ key: "full_name", id: "id" });
  const errorStyle = {
    marginTop: "5px",
    marginBottom: "5px",
    textAlign: "left",
  };

  const handleTabClick = (page) => {
    setPage(page);
  };
  // const {
  //   familyMembers,
  //   setFamilyMembers,
  //   setClientName,
  //   selectedFamilyMembers,
  //   setSelectedFamilyMembers,
  // } = useAllClients(false);
  const {
    userState: { loginedUserData },
    dispatch,
  } = OnboardingContext();
  // const [errors, setErrors] = useState(null);

  const [client, setClient] = useState(
    loginedUserData?.user_type === ENTITY_TYPE_CLIENT
      ? [{ full_name: loginedUserData.name, id: loginedUserData.id }]
      : []
  );
  function calQ(year, q, type) {
    switch (q) {
      case "Q1":
        if (type == "from") {
          return year - 1 + "-04-" + "01";
        } else {
          return moment(year - 1 + "-06-" + "01")
            .endOf("month")
            .format("YYYY-MM-DD");
        }

      case "Q2":
        if (type == "from") {
          return year - 1 + "-07-" + "01";
        } else {
          return moment(year - 1 + "-09-" + "01")
            .endOf("month")
            .format("YYYY-MM-DD");
        }

      case "Q3":
        if (type == "from") {
          return year - 1 + "-10-" + "01";
        } else {
          return moment(year - 1 + "-12-" + "01")
            .endOf("month")
            .format("YYYY-MM-DD");
        }

      case "Q4":
        if (type == "from") {
          return year + "-01-" + "01";
        } else {
          return moment(year + "-03-" + "01")
            .endOf("month")
            .format("YYYY-MM-DD");
        }

      default:
        break;
    }
  }

  function getexport(values) {
    if (
      values.type == "Performance Summary Report" ||
      values.type == "Client Portfolio Report" ||
      values.type == "Consolidated Securities Holding Summary Report" ||
      (values.type == "Holding Summary Report" &&
        values.familyHolding == true) ||
      // values.type == "Realized GainLoss Report" ||
      // values.type == "Other Income Report" ||
      values.type == "PMS Transaction Report" ||
      values.type == "PMS Underlying Transactions"
    ) {
      return (
        <option className="optiondesignNew" key={"PDF"} value={"PDF"}>
          {"PDF"}
        </option>
      );
    } else if (
      values.type == "Tax Report" &&
      scopes.can_generate_download_excel_report_tax &&
      client?.length < 6 &&
      client[0]?.id != "all"
    ) {
      return exportType.map((product) => {
        return (
          <option
            className="optiondesignNew"
            key={product.value}
            value={product.value}
          >
            {product.name}
          </option>
        );
      });
    } else if (
      values.type == "Cashflow Summary Report"
      // &&
      // client?.length < 6 &&
      // client[0]?.id != "all"
    ) {
      // return exportType.map((product) => {
      //   return (
      //     <option
      //       className="optiondesignNew"
      //       key={product.value}
      //       value={product.value}
      //     >
      //       {product.name}
      //     </option>
      //   );
      // });
      return (
        <option className="optiondesignNew" key={"PDF"} value={"PDF"}>
          {"PDF"}
        </option>
      );
    } else if (
      (values.type != "" && client?.length > 5) ||
      (client && client[0]?.id == "all")
    ) {
      if (values.type == "Tax Report") {
        if (scopes.can_generate_download_excel_report_tax) {
          return (
            <option className="optiondesignNew" key={"excel"} value={"XLS"}>
              {"XLS"}
            </option>
          );
        }
      }

      if (scopes?.can_generate_download_excel_report) {
        return (
          <option className="optiondesignNew" key={"excel"} value={"XLS"}>
            {"XLS"}
          </option>
        );
      }
    } else if (values.denomination != "Actuals" && values.denomination != "") {
      return (
        <option className="optiondesignNew" key={"PDF"} value={"PDF"}>
          {"PDF"}
        </option>
      );
    } else {
      if (scopes?.can_generate_download_excel_report) {
        return exportType.map((product) => {
          return (
            <option
              className="optiondesignNew"
              key={product.value}
              value={product.value}
            >
              {product.name}
            </option>
          );
        });
      } else {
        return (
          <option className="optiondesignNew" key={"PDF"} value={"PDF"}>
            {"PDF"}
          </option>
        );
      }
    }
  }

  const [formData, setFormdata] = useState({
    clientId:
      loginedUserData?.user_type === ENTITY_TYPE_CLIENT
        ? loginedUserData?.id
        : "",
    entity: "",
    fileType: "",
    file: "",
  });
  const hiddenFileInput = useRef(null);
  const [uploadErrors, setUploadErrors] = useState("");
  function showError(touched, error, absolute) {
    if (touched && error) {
      return (
        <ErrorMessageComp
          style={{
            ...errorStyle,
            position: absolute ? "absolute" : "relative",
          }}
          errorMessage={error}
        />
      );
    }
  }

  function getClient(search) {
    if (loginedUserData?.user_type === "partner") {
      fetchClientByPartner(loginedUserData?.id, {
        search,
        onboarding_status: "approved",
      }).then((res) => {
        // setKey({ key: "client_full_name", id: "client_id" });
        let arr = res?.data?.data?.result?.map((k) => {
          return { full_name: k?.client_full_name, id: k?.client_id };
        });
        setFamilyMembers(arr);
      });
    } else {
      getClientList("approved", search).then((res) => {
        console.log(res.data.data.result, "result data");
        setFamilyMembers(res.data.data.result);
      });
    }
  }
  useEffect(() => {
    console.log("get client");
    getClient();
  }, []);

  function fetchMembers(id, search, client1) {
    let tempData;
    if (client.length > 0 || client?.id) {
      tempData = client;
    } else {
      tempData = client1;
    }

    if (search) {
      if (tempData?.length > 0 || tempData?.id || id) {
      } else {
        return;
      }
    }

    if (tempData?.length > 0 || tempData?.id || id) {
      let params = {
        status: "approved",
        search: search || "",
        clientId: id
          ? id
          : Array.isArray(tempData)
          ? tempData
              .map((c) => {
                return c.id;
              })
              .toString()
          : typeof tempData == "object"
          ? tempData.id
          : tempData,
        partnerId:
          loginedUserData?.user_type === "partner" && tempData[0]?.id == "all"
            ? loginedUserData?.id
            : "",
      };
      setShowLoading({ ...showloading, memberId: true });
      fetchClientByMultiId(params)
        .then((res) => {
          // console.log(res, "res");
          setShowLoading({ ...showloading, memberId: false });
          setMEmbers(res.data.data.result);
        })
        .catch((errors) => {
          setShowLoading({ ...showloading, memberId: false });
        });
    }
  }
  const fetchClientList = async (status = "approved", searchValue = "") => {
    setShowLoader(true);

    try {
      const data = await getClientList(status, searchValue);
      const clientList = data.data;

      if (clientList.success) {
        setShowLoader(false);
        setClientList(clientList.data.result);
      }
    } catch (error) {
      setShowLoader(false);
      if (error.response.request.status === 401) {
        navigate("/logout");
      } else if (!error.response.data.error.success) {
        dispatch({
          type: "UPDATE_SNACKBAR_DATA",
          payload: {
            success: false,
            error: true,
            showMsg: true,
            message: error.response.data.error.message,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (client) {
      if (loginedUserData?.user_type === ENTITY_TYPE_CLIENT) {
        setClientList([
          {
            full_name: loginedUserData.name,
            id: loginedUserData.id,
          },
        ]);
        fetchMembers(loginedUserData.id);
      } else {
        fetchMembers();
      }
    }
  }, [client]);

  // const handleChange = (e) => {
  //   setFormdata({ ...formData, [e.target.name]: e.target.value });
  //   // console.log(e.target.name, e.target.value, "e data");
  //   const errorData = uploadSampleFileValidate(
  //     { ...formData, [e.target.name]: e.target.value },
  //     e.target.name
  //   );
  //   setErrors(errorData);
  // };

  const handleAsncChange = (name, value, valuez, option) => {
    // console.log(name, value, valuez, "name and value");

    if (typeof value == "string" || typeof value == "number") {
      console.log("ramvalue1", value);
      let val = option.find((v) => {
        if (v?.[key.id] == value) {
          return true;
        }
      });

      setClient(val ? val : []);
      // console.log(val, "value ww");
      setFieldValue(name, val ? val : []);
      setFormdata({ ...formData, [name]: val });
    } else {
      console.log("ramvalue2", value);
      setFieldValue(name, value);
      setFormdata({ ...formData, [name]: value });
    }

    const errorData = uploadSampleFileValidate(
      { ...formData, [name]: value },
      name
    );
  };
  function customHandleSubmit() {}

  // console.log(familyMembers, "family members");
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,

    setFieldValue,
  } = useForm(
    {
      memberId: [],
      client:
        loginedUserData?.user_type === ENTITY_TYPE_CLIENT
          ? {
              full_name: loginedUserData.name,
              id: loginedUserData.id,
            }
          : [],

      startDate: "",
      endDate: moment().format("YYYY-MM-DD"),
      exportType: "",
      type: "",
      denomination: "",
      from: "Q1",
      to: "Q4",
      familyHolding: false,
      processingFormat: "assetWise",
      promoterStake: 1,
      includeHeldAway: true,
    },
    // test
    (values, { resetForm }) => {
      // console.log("handle submit", values);
      const { client, ...data } = values;

      data["memberId"] = values?.memberId
        ?.map((m) => {
          return m.member_id;
        })
        .toString();

      if (data["memberId"] == "") {
        delete data["memberId"];
      }

      if (
        typeof values?.["client"] == "number" ||
        typeof values?.["client"] == "string"
      ) {
        data["clientId"] = values?.["client"];
      } else if (Array.isArray(values?.["client"])) {
        data["clientId"] = values?.["client"]
          ?.map((m) => {
            return m.id;
          })
          .toString();
      } else if (typeof values?.["client"] == "object") {
        data["clientId"] = values?.["client"]?.id;
      }

      if (data.startDate === "") {
        delete data["startDate"];
      }
      if (loginedUserData?.user_type === "partner") {
        data["partnerId"] = loginedUserData?.id;
      }
      if (values.type == "Tax Report") {
        data.endDate = calQ(values.year, values.to, "to");
        data.denomination = "Actuals";
        data.startDate = calQ(values.year, values.from, "from");
      }
      if (values.type == "Cashflow Summary Report") {
        data.denomination = "Actuals";
        data.startYear = data.year - 1;
        data.endDate = moment().endOf(data.year).format("YYYY-MM-DD");
      }
      if (values.type == "Holding Summary Report") {
        data.includeSecuritiesHoldingSummary = values.familyHolding;
      }

      // data["clientId"] =
      //   loginedUserData?.user_type === ENTITY_TYPE_CLIENT
      //     ? typeof values?.["client"] == "number" ||
      //       typeof values?.["client"] == "string"
      //       ? values?.["client"]
      //       : Array.isArray(values?.["client"])
      //       ? values?.["client"]
      //           ?.map((m) => {
      //             return m.id;
      //           })
      //           .toString()
      //       : values?.["client"]?.id
      //     : values?.["client"]
      //         ?.map((m) => {
      //           return m.id;
      //         })
      //         .toString();

      setShowLoader(true);

      // console.log("request data", data);
      RequestReport(data)
        .then((res) => {
          setShowLoader(false);
          // console.log(res, "request");
          dispatch({
            type: "UPDATE_SNACKBAR_DATA",
            payload: {
              success: true,
              error: false,
              showMsg: true,
              message: res.data.message,
            },
          });
          setClient([]);
          resetForm();
          setOpen(true);
        })
        .catch((error) => {
          setShowLoader(false);
          console.log(error, "error");
          dispatch({
            type: "UPDATE_SNACKBAR_DATA",
            payload: {
              success: false,
              error: true,
              showMsg: true,
              message: error.response.data.error.message,
            },
          });
        });
      customHandleSubmit(values);
    },
    printForm
  );
  useEffect(() => {
    if (client.length > 1 || client[0]?.id == "all") {
      values.familyHolding = "false";
      setFieldValue("familyHolding", false);
    }
    if (
      values?.memberId?.length == 1 &&
      values?.memberId[0]?.member_id != "all"
    ) {
      values.familyHolding = "false";
      setFieldValue("familyHolding", false);
    }
  }, [client, values.memberId]);
  function selectComponent(values) {
    // console.log(values, "values");
    switch (values.type) {
      case "Performance Summary Report":
        return (
          <>
            <SingleSelect
              // getSearchData={() => {}}
              getSearchData={(value) => getClient(value)}
              data={familyMembers}
              styles={{ opacity: 1, border: "0px solid black" }}
              searchLoader={false}
              disable={
                loginedUserData?.user_type === ENTITY_TYPE_CLIENT ? true : false
              }
              errors={errors}
              touch={touched}
              label={key.key}
              optionTitle={key.key}
              fieldName="client"
              objectFieldName={key.id}
              formData={formData}
              value={values.client}
              handleChange={(name, value1, valuez) => {
                handleAsncChange(name, value1, valuez, familyMembers);
                // if (typeof value1 == "string" || typeof value1 == "number") {
                //   let val = familyMembers.find((v) => {
                //     if (v.id == value1) {
                //       return true;
                //     }
                //   });

                //   setClient(val ? val : []);
                //   setFieldValue(name, val);
                // }
                setSelectedMembers([]);
                setFieldValue("memberId", []);
              }}
            />

            {showError(
              (!values.client || values.client == "") && touched.client,
              errors.client,
              true
            )}
          </>
        );

      case "Holding Summary Report":
        if (scopes?.can_generate_download_excel_report) {
          return (
            <>
              <MultiSelect
                multiple={true}
                styles={{ opacity: 1, border: "0px solid black" }}
                disableClassName="true"
                data={[{ [key.key]: "All", id: "all" }, ...familyMembers]}
                getSearchData={(value) => getClient(value)}
                values={client}
                setValues={setClient}
                errors={errors}
                touch={touched}
                disable={
                  loginedUserData?.user_type === ENTITY_TYPE_CLIENT
                    ? true
                    : false
                }
                searchLoader={showLoader}
                label={key.key}
                optionTitle={key.key}
                fieldName="client"
                objectFieldName={key.id}
                formData={formData}
                handleChange={(name, value1, valuez) => {
                  handleAsncChange(name, value1, valuez);
                  setFieldValue("memberId", []);
                  setSelectedMembers([]);
                  if (values.exportType == "PDF" && value1.length > 5) {
                    setFieldValue("exportType", "");
                  }
                  if (value1.length > 5) {
                    setFieldValue("denomination", "Actuals");
                  }
                  if (
                    (value1[0]?.id == "all" || value1.length > 5) &&
                    showNote == 0
                  ) {
                    setShowNote(1);
                  }
                }}
                // helperText={errors?.clientId}
              />

              {showError(
                (!client || client?.length < 1) && touched.client,
                errors.client,
                true
              )}
            </>
          );
        } else {
          return (
            <>
              <SingleSelect
                // getSearchData={() => {}}
                getSearchData={(value) => getClient(value)}
                data={familyMembers}
                styles={{ opacity: 1, border: "0px solid black" }}
                searchLoader={false}
                disable={
                  loginedUserData?.user_type === ENTITY_TYPE_CLIENT
                    ? true
                    : false
                }
                errors={errors}
                touch={touched}
                label={key.key}
                optionTitle={key.key}
                fieldName="client"
                objectFieldName={key.id}
                formData={formData}
                value={values.client}
                handleChange={(name, value1, valuez) => {
                  handleAsncChange(name, value1, valuez, familyMembers);
                  // if (typeof value1 == "string" || typeof value1 == "number") {
                  //   let val = familyMembers.find((v) => {
                  //     if (v.id == value1) {
                  //       return true;
                  //     }
                  //   });

                  //   setClient(val ? val : []);
                  //   setFieldValue(name, val);
                  // }
                  setSelectedMembers([]);
                  setFieldValue("memberId", []);
                }}
              />

              {showError(
                (!values.client || values.client == "") && touched.client,
                errors.client,
                true
              )}
            </>
          );
        }
      case "Tax Report":
        if (scopes?.can_generate_download_excel_report_tax) {
          return (
            <>
              <MultiSelect
                multiple={true}
                styles={{ opacity: 1, border: "0px solid black" }}
                disableClassName="true"
                data={[{ [key.key]: "All", id: "all" }, ...familyMembers]}
                getSearchData={(value) => getClient(value)}
                values={client}
                setValues={setClient}
                errors={errors}
                touch={touched}
                disable={
                  loginedUserData?.user_type === ENTITY_TYPE_CLIENT
                    ? true
                    : false
                }
                searchLoader={showLoader}
                label={key.key}
                optionTitle={key.key}
                fieldName="client"
                objectFieldName={key.id}
                formData={formData}
                handleChange={(name, value1, valuez) => {
                  handleAsncChange(name, value1, valuez);
                  setFieldValue("memberId", []);
                  setSelectedMembers([]);
                  if (values.exportType == "PDF" && value1.length > 5) {
                    setFieldValue("exportType", "");
                  }
                  if (value1.length > 5) {
                    setFieldValue("denomination", "Actuals");
                  }
                  if (
                    (value1[0]?.id == "all" || value1.length > 5) &&
                    showNote == 0
                  ) {
                    setShowNote(1);
                  }
                }}
                // helperText={errors?.clientId}
              />

              {showError(
                (!client || client?.length < 1) && touched.client,
                errors.client,
                true
              )}
            </>
          );
        } else {
          return (
            <>
              <SingleSelect
                // getSearchData={() => {}}
                getSearchData={(value) => getClient(value)}
                data={familyMembers}
                styles={{ opacity: 1, border: "0px solid black" }}
                searchLoader={false}
                disable={
                  loginedUserData?.user_type === ENTITY_TYPE_CLIENT
                    ? true
                    : false
                }
                errors={errors}
                touch={touched}
                label={key.key}
                optionTitle={key.key}
                fieldName="client"
                objectFieldName={key.id}
                formData={formData}
                value={values.client}
                handleChange={(name, value1, valuez) => {
                  handleAsncChange(name, value1, valuez, familyMembers);
                  // if (typeof value1 == "string" || typeof value1 == "number") {
                  //   let val = familyMembers.find((v) => {
                  //     if (v.id == value1) {
                  //       return true;
                  //     }
                  //   });

                  //   setClient(val ? val : []);
                  //   setFieldValue(name, val);
                  // }
                  setSelectedMembers([]);
                  setFieldValue("memberId", []);
                }}
              />

              {showError(
                (!values.client || values.client == "") && touched.client,
                errors.client,
                true
              )}
            </>
          );
        }

      case "Transaction Summary Report":
        if (scopes?.can_generate_download_excel_report) {
          return (
            <>
              <MultiSelect
                multiple={true}
                styles={{ opacity: 1, border: "0px solid black" }}
                disableClassName="true"
                data={[{ [key.key]: "All", id: "all" }, ...familyMembers]}
                getSearchData={(value) => getClient(value)}
                values={Array.isArray(client) ? client : [client]}
                disable={
                  loginedUserData?.user_type === ENTITY_TYPE_CLIENT
                    ? true
                    : false
                }
                setValues={setClient}
                errors={errors}
                touch={touched}
                searchLoader={showLoader}
                label={key.key}
                optionTitle={key.key}
                fieldName="client"
                objectFieldName={key.id}
                formData={formData}
                handleChange={(name, value1, valuez) => {
                  handleAsncChange(name, value1, valuez);
                  setFieldValue("memberId", []);
                  setSelectedMembers([]);
                  if (values.exportType == "PDF" && value1.length > 5) {
                    setFieldValue("exportType", "");
                  }
                  if (value1.length > 5) {
                    setFieldValue("denomination", "Actuals");
                  }
                  if (
                    (value1[0]?.id == "all" || value1.length > 5) &&
                    showNote == 0
                  ) {
                    setShowNote(1);
                  }
                }}
                // helperText={errors?.clientId}
              />
              {showError(
                (!client || client?.length < 1) && touched.client,
                errors.client,
                true
              )}
            </>
          );
        } else {
          return (
            <>
              <SingleSelect
                // getSearchData={() => {}}
                getSearchData={(value) => getClient(value)}
                data={familyMembers}
                styles={{ opacity: 1, border: "0px solid black" }}
                searchLoader={false}
                disable={
                  loginedUserData?.user_type === ENTITY_TYPE_CLIENT
                    ? true
                    : false
                }
                errors={errors}
                touch={touched}
                label={key.key}
                optionTitle={key.key}
                fieldName="client"
                objectFieldName={key.id}
                formData={formData}
                value={values.client}
                handleChange={(name, value1, valuez) => {
                  handleAsncChange(name, value1, valuez, familyMembers);
                  // if (typeof value1 == "string" || typeof value1 == "number") {
                  //   let val = familyMembers.find((v) => {
                  //     if (v.id == value1) {
                  //       return true;
                  //     }
                  //   });

                  //   setClient(val ? val : []);
                  //   setFieldValue(name, val);
                  // }
                  setSelectedMembers([]);
                  setFieldValue("memberId", []);
                }}
              />

              {showError(
                (!values.client || values.client == "") && touched.client,
                errors.client,
                true
              )}
            </>
          );
        }

      default:
        return (
          <>
            <SingleSelect
              // getSearchData={() => {}}
              getSearchData={(value) => getClient(value)}
              data={familyMembers}
              styles={{ opacity: 1, border: "0px solid black" }}
              searchLoader={false}
              disable={
                loginedUserData?.user_type === ENTITY_TYPE_CLIENT ? true : false
              }
              errors={errors}
              touch={touched}
              label={key.key}
              optionTitle={key.key}
              fieldName="client"
              objectFieldName={key.id}
              formData={formData}
              value={values.client}
              handleChange={(name, value1, valuez) => {
                handleAsncChange(name, value1, valuez, familyMembers);
                // if (typeof value1 == "string" || typeof value1 == "number") {
                //   let val = familyMembers.find((v) => {
                //     if (v.id == value1) {
                //       return true;
                //     }
                //   });

                //   setClient(val ? val : []);
                //   setFieldValue(name, val);
                // }
                setSelectedMembers([]);
                setFieldValue("memberId", []);
              }}
            />

            {showError(
              (!values.client || values.client == "") && touched.client,
              errors.client,
              true
            )}
          </>
        );
    }
  }

  const handleViewCenter = () => {
    window.location.href = "/home/reports?key=report-center";
  };
  // Start Date will be hidden for Holding & Security Holding
  const showStartDateFor = [
    "Transaction Summary Report",
    "PMS Transaction Report",
    "Realized GainLoss Report",
    "Income Statement",
    "PMS Underlying Transactions",
  ];

  return (
    <div>
      <AlertDialog
        open={open}
        setOpen={setOpen}
        dialogTitle={() => (
          <DialogTitle id="alert-dialog-title">
            <div
              style={{
                alignTtems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <RightAlertIcon />
            </div>
            <Typography
              style={{
                color: "#fff",
                fontFamily: "Avenir",
                fontSize: "18x",
                fontStyle: "normal",
                fontWeight: 800,
                lineHeight: "35.2px",
                textAlign: "center",
              }}
            >
              Your Report is being created
            </Typography>
          </DialogTitle>
        )}
        dialogContent={() => (
          <DialogContentText
            id="alert-dialog-description"
            style={{
              color: "#fff",
              textAlign: "center",
              fontFamily: "Avenir",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 350,
              lineHeight: "22px",
            }}
          >
            Upon Completion you will receive an intimation email.
          </DialogContentText>
        )}
        dialogFooter={() => (
          <button
            className="approveBtnDetailsAccordion"
            style={{ background: "#7043DE", width: "250px" }}
            onClick={handleViewCenter}
          >
            View Report Center
          </button>
        )}
      />
      {showLoader && <Loader />}

      <CustomizedSnackbars />
      <div className="containerWrapper">
        <p
          className="topText"
          style={{
            marginTop: "1.5rem",
            marginBottom: "1rem",
            marginRight: "8.5rem",
          }}
        >
          {/* {page === "Data Upload" ? "" : "Bulk Upload"} */}
          {/* Display "Bulk Upload" only if the user does not have permission to upload data files in bulk */}
        </p>

        <div className="productUploadContinerForUserManagement">
          <div className="productUploadSelectDropDown">
            {/* {console.log(errors, touched, values, "values")} */}

            <>
              {/* Show Client DropDown Only if Data Upload is Selected */}
              <div className={"selectClient1"}>
                <FormControl
                  variant="outlined"
                  className={`${classes.formControl} ${classes.inputField}`}
                  style={{ position: "relative" }}
                >
                  {/* <InputLabel
                  id="demo-simple-select-outlined-label"
                  style={{ background: "black !important" }}
                >
                  Select Product
                </InputLabel> */}
                  {/* <div className="selectDropDown"> */}
                  <p className="selectDropDownLabel">Report Type</p>
                  <Select
                    // For PlaceHolder
                    displayEmpty
                    renderValue={
                      values.type !== ""
                        ? undefined
                        : () => (
                            <span style={{ color: "#59595B" }}>
                              Select Report
                            </span>
                          )
                    }
                    // native
                    style={{ color: "#FAF9FF" }}
                    labelId="demo-simple-select-outlined-label"
                    // label="Select Product"
                    value={values.type}
                    onChange={(e) => {
                      handleChange(e);
                      if (loginedUserData?.user_type != ENTITY_TYPE_CLIENT) {
                        setFieldValue("client", "");
                        setClient([]);
                      }
                    }}
                    error={
                      Boolean(errors?.type) && values.type == ""
                        ? touched.type
                        : false
                    }
                    inputProps={{
                      name: "type",
                      id: "outlined-entity_type-native-simple",
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          backgroundColor: "#1e0d2d",
                          border: `1.164px solid ${theme.palette.selectDropDown.border}`,
                          marginTop: "1rem",
                        },
                      },
                      MenuListProps: {
                        className: classes.selectDropDownMenu,
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {reportType.map((product) => {
                      return (
                        <option
                          className="optiondesignNew"
                          key={product.value}
                          value={product.value}
                        >
                          {product.name}
                        </option>
                      );
                    })}
                  </Select>

                  {/* </div> */}
                </FormControl>
                {showError(
                  values.type == "" && touched?.type,
                  errors.type,
                  true
                )}
              </div>
              {page === "Data Upload" && (
                <div
                  className={`selectClient1 asyncbox ${
                    loginedUserData?.user_type === ENTITY_TYPE_CLIENT
                      ? "disable_multiselect"
                      : ""
                  }`}
                >
                  <p className="selectDropDownLabel">Select Client</p>

                  {selectComponent(values)}
                  <div
                    style={{
                      height: "145px",
                      width: "355px",
                      background:
                        "linear-gradient(38.98deg, #220F32 -7.48%, #281850 129.72%)",
                      borderRadius: "8px",
                      boxShadow: "-21px 21px 20px 0px #00000087",
                      position: "absolute",
                      top: "120px",
                      right: "157px",
                      zIndex: "2",
                      display: showNote == 1 ? "block" : "none",
                    }}
                  >
                    <div className="note">
                      <div>
                        {" "}
                        <button
                          onClick={() => {
                            setShowNote(-1);
                          }}
                        >
                          <Close />
                        </button>
                        <IIcon /> <b>PDF Not Available</b>
                      </div>

                      <div className="subtext">
                        PDF export for the multiple clients is not available.
                        Please select an individual client .
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        </div>

        <p
          style={{
            alignItems: "left",

            color: "#fff",
            marginBottom: "2px",
            marginTop: "33px  ",
          }}
        >
          Report Details
        </p>

        {values.type != "Tax Report" &&
          values.type != "Holding Summary Report" &&
          values.type != "Cashflow Summary Report" &&
          values.type != "Realized GainLoss Report" && (
            <div
              className="productUploadContainer fade_purple_bg"
              style={{ margin: "1rem 0 0 0", width: "auto" }}
            >
              <div style={{ display: "flex", gap: 25 }}>
                {showStartDateFor.includes(values.type) && (
                  <div className="selectClient1">
                    <FormControl
                      variant="outlined"
                      className={`${classes.formControl} ${classes.inputField}`}
                    >
                      <p className="selectDropDownLabel">START DATE</p>

                      <TextField
                        // For PlaceHolder
                        variant="outlined"
                        displayEmpty
                        renderValue={
                          formData?.entity !== ""
                            ? undefined
                            : () => (
                                <span style={{ color: "#59595B" }}>
                                  Select Date
                                </span>
                              )
                        }
                        // native
                        style={{ color: "#FAF9FF" }}
                        labelId="demo-simple-select-outlined-label"
                        // label="Select Product"
                        type="date"
                        id="date"
                        value={values.startDate}
                        onChange={(e) => handleChange(e)}
                        error={touched.startDate && Boolean(errors?.startDate)}
                        endAdornment={
                          <InputAdornment position="end">
                            {browseIcon}
                          </InputAdornment>
                        }
                        inputProps={{
                          max: moment().format("YYYY-MM-DD"),
                          name: "startDate",
                          id: "outlined-entity_type-native-simple",
                          classes: {
                            icon: classes.icon,
                          },
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                              backgroundColor: "#1e0d2d",
                              border: "1.164px solid #461277",
                              marginTop: "1rem",
                            },
                          },
                          MenuListProps: {
                            className: classes.selectDropDownMenu,
                          },
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                      ></TextField>

                      {showError(touched.startDate, errors.startDate)}
                      {/* </div> */}
                    </FormControl>
                  </div>
                )}
                <div className="selectClient1">
                  <FormControl
                    variant="outlined"
                    className={`${classes.formControl} ${classes.inputField}`}
                  >
                    {/* <InputLabel
                  id="demo-simple-select-outlined-label"
                  style={{ background: "black !important" }}
                >
                  Select Product
                </InputLabel> */}
                    {/* <div className="selectDropDown"> */}
                    <p className="selectDropDownLabel">END DATE</p>
                    <TextField
                      // For PlaceHolder
                      variant="outlined"
                      // displayEmpty
                      // renderValue={
                      //   formData?.entity !== ""
                      //     ? undefined
                      //     : () => (
                      //         <span style={{ color: "#59595B" }}>
                      //           Select Product
                      //         </span>
                      //       )
                      // }
                      // native
                      style={{ color: "#FAF9FF" }}
                      labelId="demo-simple-select-outlined-label"
                      // label="Select Product"
                      type="date"
                      id="date"
                      // value={values.endDate}
                      value={values.endDate}
                      onChange={(e) => handleChange(e)}
                      error={touched.endDate && Boolean(errors?.endDate)}
                      endAdornment={
                        <InputAdornment position="end">
                          {browseIcon}
                        </InputAdornment>
                      }
                      inputProps={{
                        max: moment().format("YYYY-MM-DD"),
                        name: "endDate",
                        id: "outlined-entity_type-native-simple",
                        classes: {
                          icon: classes.icon,
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                            backgroundColor: "#1e0d2d",
                            border: "1.164px solid #461277",
                            marginTop: "1rem",
                          },
                        },
                        MenuListProps: {
                          className: classes.selectDropDownMenu,
                        },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    ></TextField>
                    {showError(touched.endDate, errors.endDate)}

                    {/* </div> */}
                  </FormControl>
                </div>
                {values?.type == "Holding Summary Report" && (
                  <Grid xs={6} lg={6} item>
                    <FormControl
                      variant="outlined"
                      className={`${classes.formControl} ${classes.inputField}`}
                    >
                      <p className="selectDropDownLabel">
                        Include Family Holding
                      </p>
                      {console.log(client.length, "length")}
                      <Select
                        // For PlaceHolder
                        placeholder="Family Holding"
                        displayEmpty
                        // native
                        style={{ color: "#FAF9FF" }}
                        labelId="demo-simple-select-outlined-label"
                        renderValue={
                          values.familyHolding !== ""
                            ? undefined
                            : () => (
                                <span style={{ color: "#59595B" }}>
                                  Select Family Holding
                                </span>
                              )
                        }
                        value={
                          values?.memberId?.length == 1 &&
                          values?.memberId[0]?.member_id != "all" &&
                          (client.length > 1 || client[0]?.id == "all")
                            ? false
                            : values.familyHolding
                        }
                        onChange={(e) => handleChange(e)}
                        error={
                          touched?.familyHolding &&
                          Boolean(errors?.familyHolding)
                        }
                        inputProps={{
                          name: "familyHolding",
                          id: "outlined-entity_type-native-simple",
                          classes: {
                            icon: classes.icon,
                          },
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                              backgroundColor: "#1e0d2d",
                              border: `1.164px solid ${theme.palette.selectDropDown.border}`,
                              marginTop: "1rem",
                            },
                          },
                          MenuListProps: {
                            className: classes.selectDropDownMenu,
                          },
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        {(values?.memberId?.length > 1 ||
                          values?.memberId[0]?.member_id == "all") &&
                          client.length == 1 &&
                          client[0]?.id != "all" && (
                            <option
                              className="optiondesignNew"
                              key={"1"}
                              value={true}
                            >
                              {"Yes"}
                            </option>
                          )}
                        <option
                          className="optiondesignNew"
                          key={"0"}
                          value={false}
                        >
                          {"No"}
                        </option>
                      </Select>
                      {showError(touched.familyHolding, errors.familyHolding)}

                      {/* </div> */}
                    </FormControl>
                  </Grid>
                )}
              </div>

              {values?.type == "Holding Summary Report" && (
                <Grid xs={12} lg={12} item>
                  <FormControl
                    variant="outlined"
                    className={`${classes.formControl} ${classes.inputField}`}
                  >
                    <p className="selectDropDownLabel">
                      Include Promotor Stake
                    </p>
                    <Select
                      // For PlaceHolder
                      placeholder="Promotor Stake"
                      displayEmpty
                      // native
                      style={{ color: "#FAF9FF" }}
                      labelId="demo-simple-select-outlined-label"
                      renderValue={
                        values.promoterStake !== ""
                          ? undefined
                          : () => (
                              <span style={{ color: "#59595B" }}>
                                Select Promotor Stake
                              </span>
                            )
                      }
                      value={values.promoterStake}
                      onChange={(e) => handleChange(e)}
                      error={
                        touched?.promoterStake && Boolean(errors?.promoterStake)
                      }
                      inputProps={{
                        name: "promoterStake",
                        id: "outlined-entity_type-native-simple",
                        classes: {
                          icon: classes.icon,
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                            backgroundColor: "#1e0d2d",
                            border: `1.164px solid ${theme.palette.selectDropDown.border}`,
                            marginTop: "1rem",
                          },
                        },
                        MenuListProps: {
                          className: classes.selectDropDownMenu,
                        },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <option className="optiondesignNew" key={"1"} value={1}>
                        {"Yes"}
                      </option>
                      <option className="optiondesignNew" key={"0"} value={0}>
                        {"No"}
                      </option>
                    </Select>
                    {showError(touched.promoterStake, errors.promoterStake)}
                    {/* </div> */}
                  </FormControl>
                </Grid>
              )}
              <Grid xs={12} lg={12} item>
                <FormControl
                  variant="outlined"
                  className={`${classes.formControl} ${classes.inputField}`}
                >
                  <p className="selectDropDownLabel">Denomination</p>
                  <Select
                    // For PlaceHolder
                    placeholder="Denomination"
                    name="denomination"
                    displayEmpty
                    // native
                    renderValue={
                      values.denomination !== ""
                        ? undefined
                        : () => (
                            <span style={{ color: "#59595B" }}>
                              Select Denomination
                            </span>
                          )
                    }
                    style={{ color: "#FAF9FF" }}
                    labelId="demo-simple-select-outlined-label"
                    // label="Select Product"
                    value={values.denomination}
                    onChange={(e) => {
                      // console.log(e, "deno");
                      setFieldValue(e.target.name, e.target.value);
                      if (values.exportType == "XLS") {
                        setFieldValue("exportType", "");
                      }
                      handleChange(e);
                    }}
                    error={
                      touched?.denomination && Boolean(errors?.denomination)
                    }
                    inputProps={{
                      name: "denomination",
                      id: "outlined-entity_type-native-simple",
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          backgroundColor: "#1e0d2d",
                          border: `1.164px solid ${theme.palette.selectDropDown.border}`,
                          marginTop: "1rem",
                        },
                      },
                      MenuListProps: {
                        className: classes.selectDropDownMenu,
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {(client?.length > 5 || (client && client[0]?.id == "all")
                      ? [{ name: "Actuals", value: "Actuals" }]
                      : denominationOptions
                    ).map((product) => {
                      return (
                        <option
                          className="optiondesignNew"
                          key={product.value}
                          value={product.value}
                        >
                          {product.name}
                        </option>
                      );
                    })}
                  </Select>
                  {showError(
                    values.denomination !== "Actuals" &&
                      values.denomination != "",
                    "*You can not export XLS"
                  )}
                  {showError(touched.denomination, errors.denomination)}
                  {/* </div> */}
                </FormControl>
              </Grid>
              <Grid xs={12} lg={12} item>
                <FormControl
                  variant="outlined"
                  className={`${classes.formControl} ${classes.inputField}`}
                >
                  <p className="selectDropDownLabel">Members</p>

                  <MultiSelect
                    multiple={true}
                    styles={{ opacity: 1, border: "0px solid black" }}
                    disableClassName="true"
                    data={[{ full_name: "All", member_id: "all" }, ...members]}
                    getSearchData={(value, client1) =>
                      fetchMembers(null, value, client1)
                    }
                    client={client}
                    values={selectedMembers}
                    setValues={setSelectedMembers}
                    errors={errors.memberId}
                    searchLoader={showloading.memberId}
                    placeholder={"Select Members"}
                    label="memberId"
                    optionTitle="full_name"
                    fieldName="memberId"
                    objectFieldName="member_id"
                    formData={formData}
                    handleChange={(name, value1, valuez) => {
                      handleAsncChange(name, value1, valuez);
                    }}
                  />

                  {/* </div> */}
                </FormControl>
              </Grid>
              <Grid container>
                <Grid xs={6} lg={6} item>
                  <FormControl
                    variant="outlined"
                    className={`${classes.formControl} ${classes.inputField}`}
                  >
                    <p className="selectDropDownLabel">Include Held Away?</p>
                    <Select
                      placeholder="Held Away"
                      displayEmpty
                      style={{ color: "#FAF9FF", marginRight: "8px" }}
                      labelId="demo-simple-select-outlined-label"
                      value={values.includeHeldAway}
                      onChange={(e) => handleChange(e)}
                      error={
                        touched?.includeHeldAway &&
                        Boolean(errors?.includeHeldAway)
                      }
                      inputProps={{
                        name: "includeHeldAway",
                        id: "outlined-entity_type-native-simple",
                        classes: {
                          icon: classes.icon,
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                            backgroundColor: "#1e0d2d",
                            border: `1.164px solid ${theme.palette.selectDropDown.border}`,
                            marginTop: "1rem",
                          },
                        },
                        MenuListProps: {
                          className: classes.selectDropDownMenu,
                        },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <option
                        className="optiondesignNew"
                        key={"1"}
                        value={true}
                      >
                        {"Yes"}
                      </option>
                      <option
                        className="optiondesignNew"
                        key={"0"}
                        value={false}
                      >
                        {"No"}
                      </option>
                    </Select>
                    {showError(touched.includeHeldAway, errors.includeHeldAway)}
                    {/* </div> */}
                  </FormControl>
                </Grid>
                <Grid xs={6} lg={6} item>
                  <FormControl
                    variant="outlined"
                    className={`${classes.formControl} ${classes.inputField}`}
                  >
                    <p className="selectDropDownLabel">Export Type</p>
                    <Select
                      // For PlaceHolder
                      placeholder="Export Type"
                      displayEmpty
                      // native
                      style={{ color: "#FAF9FF" }}
                      labelId="demo-simple-select-outlined-label"
                      renderValue={
                        values.exportType !== ""
                          ? undefined
                          : () => (
                              <span style={{ color: "#59595B" }}>
                                Select Export Type
                              </span>
                            )
                      }
                      value={values.exportType}
                      onChange={(e) => handleChange(e)}
                      error={touched?.exportType && Boolean(errors?.exportType)}
                      inputProps={{
                        name: "exportType",
                        id: "outlined-entity_type-native-simple",
                        classes: {
                          icon: classes.icon,
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                            backgroundColor: "#1e0d2d",
                            border: `1.164px solid ${theme.palette.selectDropDown.border}`,
                            marginTop: "1rem",
                          },
                        },
                        MenuListProps: {
                          className: classes.selectDropDownMenu,
                        },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {getexport(values)}
                    </Select>
                    {showError(touched.exportType, errors.exportType)}
                    {/* </div> */}
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          )}
        {values.type == "Holding Summary Report" && (
          <div
            className="productUploadContainer fade_purple_bg"
            style={{ margin: "1rem 0 0 0", width: "auto" }}
          >
            <Grid xs={12} lg={12} item>
              <FormControl
                variant="outlined"
                className={`${classes.formControl} ${classes.inputField}`}
              >
                <p className="selectDropDownLabel">Members</p>

                <MultiSelect
                  multiple={true}
                  styles={{ opacity: 1, border: "0px solid black" }}
                  disableClassName="true"
                  data={[{ full_name: "All", member_id: "all" }, ...members]}
                  getSearchData={(value, client1) =>
                    fetchMembers(null, value, client1)
                  }
                  client={client}
                  values={selectedMembers}
                  setValues={setSelectedMembers}
                  errors={errors.memberId}
                  searchLoader={showloading.memberId}
                  placeholder={"Select Members"}
                  label="memberId"
                  optionTitle="full_name"
                  fieldName="memberId"
                  objectFieldName="member_id"
                  formData={formData}
                  handleChange={(name, value1, valuez) => {
                    handleAsncChange(name, value1, valuez);
                  }}
                />

                {/* </div> */}
              </FormControl>
            </Grid>
            <div style={{ display: "flex", gap: 25 }}>
              <div className="selectClient1">
                <FormControl
                  variant="outlined"
                  className={`${classes.formControl} ${classes.inputField}`}
                >
                  {/* <InputLabel
                  id="demo-simple-select-outlined-label"
                  style={{ background: "black !important" }}
                >
                  Select Product
                </InputLabel> */}
                  {/* <div className="selectDropDown"> */}
                  <p className="selectDropDownLabel">AS ON DATE</p>
                  <TextField
                    // For PlaceHolder
                    variant="outlined"
                    // displayEmpty
                    // renderValue={
                    //   formData?.entity !== ""
                    //     ? undefined
                    //     : () => (
                    //         <span style={{ color: "#59595B" }}>
                    //           Select Product
                    //         </span>
                    //       )
                    // }
                    // native
                    style={{ color: "#FAF9FF" }}
                    labelId="demo-simple-select-outlined-label"
                    // label="Select Product"
                    type="date"
                    id="date"
                    // value={values.endDate}
                    value={values.endDate}
                    onChange={(e) => handleChange(e)}
                    error={touched.endDate && Boolean(errors?.endDate)}
                    endAdornment={
                      <InputAdornment position="end">
                        {browseIcon}
                      </InputAdornment>
                    }
                    inputProps={{
                      max: moment().format("YYYY-MM-DD"),
                      name: "endDate",
                      id: "outlined-entity_type-native-simple",
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          backgroundColor: "#1e0d2d",
                          border: "1.164px solid #461277",
                          marginTop: "1rem",
                        },
                      },
                      MenuListProps: {
                        className: classes.selectDropDownMenu,
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  ></TextField>
                  {showError(touched.endDate, errors.endDate)}

                  {/* </div> */}
                </FormControl>
              </div>
              {values?.type == "Holding Summary Report" && (
                <Grid xs={6} lg={6} item>
                  <FormControl
                    variant="outlined"
                    className={`${classes.formControl} ${classes.inputField}`}
                  >
                    <p className="selectDropDownLabel">
                      Include Family Holding
                    </p>
                    {console.log(client.length, "length")}
                    <Select
                      // For PlaceHolder
                      placeholder="Family Holding"
                      displayEmpty
                      // native
                      style={{ color: "#FAF9FF" }}
                      labelId="demo-simple-select-outlined-label"
                      renderValue={
                        values.familyHolding !== ""
                          ? undefined
                          : () => (
                              <span style={{ color: "#59595B" }}>
                                Select Family Holding
                              </span>
                            )
                      }
                      value={
                        values?.memberId?.length == 1 &&
                        values?.memberId[0]?.member_id != "all" &&
                        (client.length > 1 || client[0]?.id == "all")
                          ? false
                          : values.familyHolding
                      }
                      onChange={(e) => handleChange(e)}
                      error={
                        touched?.familyHolding && Boolean(errors?.familyHolding)
                      }
                      inputProps={{
                        name: "familyHolding",
                        id: "outlined-entity_type-native-simple",
                        classes: {
                          icon: classes.icon,
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                            backgroundColor: "#1e0d2d",
                            border: `1.164px solid ${theme.palette.selectDropDown.border}`,
                            marginTop: "1rem",
                          },
                        },
                        MenuListProps: {
                          className: classes.selectDropDownMenu,
                        },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {(values?.memberId?.length > 1 ||
                        values?.memberId[0]?.member_id == "all") &&
                        ((client?.length == 1 && client[0]?.id != "all") ||
                          (typeof client == "object" &&
                            Object.keys(client).length > 1)) && (
                          <option
                            className="optiondesignNew"
                            key={"1"}
                            value={true}
                          >
                            {"Yes"}
                          </option>
                        )}
                      <option
                        className="optiondesignNew"
                        key={"0"}
                        value={false}
                      >
                        {"No"}
                      </option>
                    </Select>
                    {showError(touched.familyHolding, errors.familyHolding)}

                    {/* </div> */}
                  </FormControl>
                </Grid>
              )}
            </div>
            <div style={{ display: "flex", gap: 25 }}>
              {values?.type == "Holding Summary Report" && (
                <Grid xs={6} lg={6} item>
                  <FormControl
                    variant="outlined"
                    className={`${classes.formControl} ${classes.inputField}`}
                  >
                    <p className="selectDropDownLabel">
                      Include Promotor Stake
                    </p>
                    <Select
                      // For PlaceHolder
                      placeholder="Promotor Stake"
                      displayEmpty
                      // native
                      style={{ color: "#FAF9FF", marginRight: "8px" }}
                      labelId="demo-simple-select-outlined-label"
                      renderValue={
                        values.promoterStake !== ""
                          ? undefined
                          : () => (
                              <span style={{ color: "#59595B" }}>
                                Select Promotor Stake
                              </span>
                            )
                      }
                      value={values.promoterStake}
                      onChange={(e) => handleChange(e)}
                      error={
                        touched?.promoterStake && Boolean(errors?.promoterStake)
                      }
                      inputProps={{
                        name: "promoterStake",
                        id: "outlined-entity_type-native-simple",
                        classes: {
                          icon: classes.icon,
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                            backgroundColor: "#1e0d2d",
                            border: `1.164px solid ${theme.palette.selectDropDown.border}`,
                            marginTop: "1rem",
                          },
                        },
                        MenuListProps: {
                          className: classes.selectDropDownMenu,
                        },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <option className="optiondesignNew" key={"1"} value={1}>
                        {"Yes"}
                      </option>
                      <option className="optiondesignNew" key={"0"} value={0}>
                        {"No"}
                      </option>
                    </Select>
                    {showError(touched.promoterStake, errors.promoterStake)}
                    {/* </div> */}
                  </FormControl>
                </Grid>
              )}
              <Grid xs={6} lg={6} item>
                <FormControl
                  variant="outlined"
                  className={`${classes.formControl} ${classes.inputField}`}
                >
                  <p className="selectDropDownLabel">Denomination</p>
                  <Select
                    // For PlaceHolder
                    placeholder="Denomination"
                    name="denomination"
                    displayEmpty
                    // native
                    renderValue={
                      values.denomination !== ""
                        ? undefined
                        : () => (
                            <span style={{ color: "#59595B" }}>
                              Select Denomination
                            </span>
                          )
                    }
                    style={{ color: "#FAF9FF", marginLeft: "-10px" }}
                    labelId="demo-simple-select-outlined-label"
                    // label="Select Product"
                    value={values.denomination}
                    onChange={(e) => {
                      // console.log(e, "deno");
                      setFieldValue(e.target.name, e.target.value);
                      if (values.exportType == "XLS") {
                        setFieldValue("exportType", "");
                      }
                      handleChange(e);
                    }}
                    error={
                      touched?.denomination && Boolean(errors?.denomination)
                    }
                    inputProps={{
                      name: "denomination",
                      id: "outlined-entity_type-native-simple",
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          backgroundColor: "#1e0d2d",
                          border: `1.164px solid ${theme.palette.selectDropDown.border}`,
                          marginTop: "1rem",
                        },
                      },
                      MenuListProps: {
                        className: classes.selectDropDownMenu,
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {(client?.length > 1 || (client && client[0]?.id == "all")
                      ? [{ name: "Actuals", value: "Actuals" }]
                      : denominationOptions
                    ).map((product) => {
                      return (
                        <option
                          className="optiondesignNew"
                          key={product.value}
                          value={product.value}
                        >
                          {product.name}
                        </option>
                      );
                    })}
                  </Select>
                  {showError(
                    values.denomination !== "Actuals" &&
                      values.denomination != "",
                    "*You can not export XLS"
                  )}
                  {showError(touched.denomination, errors.denomination)}
                  {/* </div> */}
                </FormControl>
              </Grid>
            </div>
            <Grid container>
              <Grid xs={6} lg={6} item>
                <FormControl
                  variant="outlined"
                  className={`${classes.formControl} ${classes.inputField}`}
                >
                  <p className="selectDropDownLabel">Include Held Away?</p>
                  <Select
                    placeholder="Held Away"
                    displayEmpty
                    style={{ color: "#FAF9FF", marginRight: "8px" }}
                    labelId="demo-simple-select-outlined-label"
                    value={values.includeHeldAway}
                    onChange={(e) => handleChange(e)}
                    error={
                      touched?.includeHeldAway &&
                      Boolean(errors?.includeHeldAway)
                    }
                    inputProps={{
                      name: "includeHeldAway",
                      id: "outlined-entity_type-native-simple",
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          backgroundColor: "#1e0d2d",
                          border: `1.164px solid ${theme.palette.selectDropDown.border}`,
                          marginTop: "1rem",
                        },
                      },
                      MenuListProps: {
                        className: classes.selectDropDownMenu,
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    <option className="optiondesignNew" key={"1"} value={true}>
                      {"Yes"}
                    </option>
                    <option className="optiondesignNew" key={"0"} value={false}>
                      {"No"}
                    </option>
                  </Select>
                  {showError(touched.includeHeldAway, errors.includeHeldAway)}
                  {/* </div> */}
                </FormControl>
              </Grid>
              <Grid xs={6} lg={6} item>
                <FormControl
                  variant="outlined"
                  className={`${classes.formControl} ${classes.inputField}`}
                >
                  <p className="selectDropDownLabel">Export Type</p>
                  <Select
                    // For PlaceHolder
                    placeholder="Export Type"
                    displayEmpty
                    // native
                    style={{ color: "#FAF9FF" }}
                    labelId="demo-simple-select-outlined-label"
                    renderValue={
                      values.exportType !== ""
                        ? undefined
                        : () => (
                            <span style={{ color: "#59595B" }}>
                              Select Export Type
                            </span>
                          )
                    }
                    value={values.exportType}
                    onChange={(e) => handleChange(e)}
                    error={touched?.exportType && Boolean(errors?.exportType)}
                    inputProps={{
                      name: "exportType",
                      id: "outlined-entity_type-native-simple",
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          backgroundColor: "#1e0d2d",
                          border: `1.164px solid ${theme.palette.selectDropDown.border}`,
                          marginTop: "1rem",
                        },
                      },
                      MenuListProps: {
                        className: classes.selectDropDownMenu,
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {getexport(values)}
                  </Select>
                  {showError(touched.exportType, errors.exportType)}
                  {/* </div> */}
                </FormControl>
              </Grid>
            </Grid>
          </div>
        )}

        {values.type == "Tax Report" && (
          <div
            className="productUploadContainer fade_purple_bg"
            style={{ margin: "1rem 0 0 0", width: "auto" }}
          >
            <div style={{ display: "flex", gap: 25 }}>
              <Grid xs={6} lg={6} item>
                <FormControl
                  variant="outlined"
                  className={`${classes.formControl} ${classes.inputField}`}
                >
                  <p className="selectDropDownLabel">Members</p>

                  <MultiSelect
                    multiple={true}
                    styles={{ opacity: 1, border: "0px solid black" }}
                    disableClassName="true"
                    data={[{ full_name: "All", member_id: "all" }, ...members]}
                    getSearchData={(value, client1) =>
                      fetchMembers(null, value, client1)
                    }
                    client={client}
                    values={selectedMembers}
                    setValues={setSelectedMembers}
                    errors={errors.memberId}
                    searchLoader={showloading.memberId}
                    placeholder={"Select Members"}
                    label="memberId"
                    optionTitle="full_name"
                    fieldName="memberId"
                    objectFieldName="member_id"
                    formData={formData}
                    handleChange={(name, value1, valuez) => {
                      handleAsncChange(name, value1, valuez);
                    }}
                  />

                  {/* </div> */}
                </FormControl>
              </Grid>
              <Grid xs={6} lg={6} item>
                <FormControl
                  variant="outlined"
                  className={`${classes.formControl} ${classes.inputField}`}
                >
                  <p className="selectDropDownLabel">FORMAT</p>
                  <Select
                    // For PlaceHolder
                    placeholder="Format"
                    name="processingFormat"
                    displayEmpty
                    // native
                    renderValue={
                      values.processingFormat !== ""
                        ? undefined
                        : () => (
                            <span style={{ color: "#59595B" }}>
                              Select Format
                            </span>
                          )
                    }
                    style={{ color: "#FAF9FF" }}
                    labelId="demo-simple-select-outlined-label"
                    // label="Select Product"
                    value={values.processingFormat}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    error={
                      touched?.processingFormat &&
                      Boolean(errors?.processingFormat)
                    }
                    inputProps={{
                      name: "processingFormat",
                      id: "outlined-entity_type-native-simple",
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          backgroundColor: "#1e0d2d",
                          border: `1.164px solid ${theme.palette.selectDropDown.border}`,
                          marginTop: "1rem",
                        },
                      },
                      MenuListProps: {
                        className: classes.selectDropDownMenu,
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {taxFormat.map((product) => {
                      return (
                        <option
                          className="optiondesignNew"
                          key={product.value}
                          value={product.value}
                        >
                          {product.name}
                        </option>
                      );
                    })}
                  </Select>

                  {showError(touched.processingFormat, errors.processingFormat)}
                  {/* </div> */}
                </FormControl>
              </Grid>
            </div>

            <div style={{ display: "flex", gap: 25 }}>
              <Grid xs={9} lg={9}>
                <FormControl
                  variant="outlined"
                  className={`${classes.formControl} ${classes.inputField}`}
                  style={{ position: "relative" }}
                >
                  <p className="selectDropDownLabel">Year</p>
                  <Select
                    // For PlaceHolder
                    placeholder="Year"
                    name="year"
                    displayEmpty
                    // native
                    renderValue={
                      values.format !== ""
                        ? undefined
                        : () => (
                            <span style={{ color: "#59595B" }}>
                              Select Format
                            </span>
                          )
                    }
                    style={{ color: "#FAF9FF" }}
                    labelId="demo-simple-select-outlined-label"
                    // label="Select Product"
                    value={values.year}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    error={touched?.year && Boolean(errors?.year)}
                    inputProps={{
                      name: "year",
                      id: "outlined-entity_type-native-simple",
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          backgroundColor: "#1e0d2d",
                          border: `1.164px solid ${theme.palette.selectDropDown.border}`,
                          marginTop: "1rem",
                        },
                      },
                      MenuListProps: {
                        className: classes.selectDropDownMenu,
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {Array.from(Array(10).keys()).map((num) => {
                      let year = moment().add(1, "year").year();
                      return (
                        <option
                          className="optiondesignNew"
                          key={year - 1}
                          value={year - num}
                        >
                          {year - num - 1} - {year - num}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={3} lg={3}>
                <FormControl
                  variant="outlined"
                  className={`${classes.formControl} ${classes.inputField}`}
                  style={{ position: "relative" }}
                >
                  <p className="selectDropDownLabel">From</p>
                  <Select
                    // For PlaceHolder
                    placeholder="From"
                    name="from"
                    displayEmpty
                    // native
                    renderValue={
                      values.format !== ""
                        ? undefined
                        : () => (
                            <span style={{ color: "#59595B" }}>
                              Select From
                            </span>
                          )
                    }
                    style={{ color: "#FAF9FF" }}
                    labelId="demo-simple-select-outlined-label"
                    // label="Select Product"
                    value={values.from}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    error={touched?.from && Boolean(errors?.from)}
                    inputProps={{
                      name: "from",
                      id: "outlined-entity_type-native-simple",
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          backgroundColor: "#1e0d2d",
                          border: `1.164px solid ${theme.palette.selectDropDown.border}`,
                          marginTop: "1rem",
                        },
                      },
                      MenuListProps: {
                        className: classes.selectDropDownMenu,
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {Array.from(Array(4).keys()).map((num) => {
                      return (
                        <option
                          className="optiondesignNew"
                          key={"Q" + (num + 1)}
                          value={"Q" + (num + 1)}
                        >
                          {"Q" + (num + 1)}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={3} lg={3}>
                <FormControl
                  variant="outlined"
                  className={`${classes.formControl} ${classes.inputField}`}
                  style={{ position: "relative" }}
                >
                  <p className="selectDropDownLabel">To</p>
                  <Select
                    // For PlaceHolder
                    placeholder="To"
                    name="to"
                    displayEmpty
                    // native
                    renderValue={
                      values.format !== ""
                        ? undefined
                        : () => (
                            <span style={{ color: "#59595B" }}>Select To</span>
                          )
                    }
                    style={{ color: "#FAF9FF" }}
                    labelId="demo-simple-select-outlined-label"
                    // label="Select Product"
                    value={values.to}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    error={touched?.to && Boolean(errors?.to)}
                    inputProps={{
                      name: "to",
                      id: "outlined-entity_type-native-simple",
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          backgroundColor: "#1e0d2d",
                          border: `1.164px solid ${theme.palette.selectDropDown.border}`,
                          marginTop: "1rem",
                        },
                      },
                      MenuListProps: {
                        className: classes.selectDropDownMenu,
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {Array.from(Array(4).keys()).map((num) => {
                      return (
                        <option
                          className="optiondesignNew"
                          key={"Q" + (num + 1)}
                          value={"Q" + (num + 1)}
                        >
                          {"Q" + (num + 1)}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </div>
            <Grid container>
              <Grid xs={6} lg={6} item>
                <FormControl
                  variant="outlined"
                  className={`${classes.formControl} ${classes.inputField}`}
                >
                  <p className="selectDropDownLabel">Include Held Away?</p>
                  <Select
                    placeholder="Held Away"
                    displayEmpty
                    style={{ color: "#FAF9FF", marginRight: "8px" }}
                    labelId="demo-simple-select-outlined-label"
                    value={values.includeHeldAway}
                    onChange={(e) => handleChange(e)}
                    error={
                      touched?.includeHeldAway &&
                      Boolean(errors?.includeHeldAway)
                    }
                    inputProps={{
                      name: "includeHeldAway",
                      id: "outlined-entity_type-native-simple",
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          backgroundColor: "#1e0d2d",
                          border: `1.164px solid ${theme.palette.selectDropDown.border}`,
                          marginTop: "1rem",
                        },
                      },
                      MenuListProps: {
                        className: classes.selectDropDownMenu,
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    <option className="optiondesignNew" key={"1"} value={true}>
                      {"Yes"}
                    </option>
                    <option className="optiondesignNew" key={"0"} value={false}>
                      {"No"}
                    </option>
                  </Select>
                  {showError(touched.includeHeldAway, errors.includeHeldAway)}
                  {/* </div> */}
                </FormControl>
              </Grid>

              <Grid xs={6} lg={6} item>
                <FormControl
                  variant="outlined"
                  className={`${classes.formControl} ${classes.inputField}`}
                >
                  <p className="selectDropDownLabel">Export Type</p>
                  <Select
                    // For PlaceHolder
                    placeholder="Export Type"
                    displayEmpty
                    // native
                    style={{ color: "#FAF9FF" }}
                    labelId="demo-simple-select-outlined-label"
                    renderValue={
                      values.exportType !== ""
                        ? undefined
                        : () => (
                            <span style={{ color: "#59595B" }}>
                              Select Export Type
                            </span>
                          )
                    }
                    value={values.exportType}
                    onChange={(e) => handleChange(e)}
                    error={touched?.exportType && Boolean(errors?.exportType)}
                    inputProps={{
                      name: "exportType",
                      id: "outlined-entity_type-native-simple",
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          backgroundColor: "#1e0d2d",
                          border: `1.164px solid ${theme.palette.selectDropDown.border}`,
                          marginTop: "1rem",
                        },
                      },
                      MenuListProps: {
                        className: classes.selectDropDownMenu,
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {getexport(values)}
                  </Select>
                  {showError(touched.exportType, errors.exportType)}
                  {/* </div> */}
                </FormControl>
              </Grid>
            </Grid>
          </div>
        )}
        {values.type == "Realized GainLoss Report" && (
          <div
            className="productUploadContainer fade_purple_bg"
            style={{ margin: "1rem 0 0 0", width: "auto" }}
          >
            <div style={{ display: "flex", gap: 25 }}>
              <Grid xs={12} lg={12} item>
                <FormControl
                  variant="outlined"
                  className={`${classes.formControl} ${classes.inputField}`}
                >
                  <p className="selectDropDownLabel">Members</p>

                  <MultiSelect
                    multiple={true}
                    styles={{ opacity: 1, border: "0px solid black" }}
                    disableClassName="true"
                    data={[{ full_name: "All", member_id: "all" }, ...members]}
                    getSearchData={(value, client1) =>
                      fetchMembers(null, value, client1)
                    }
                    client={client}
                    values={selectedMembers}
                    setValues={setSelectedMembers}
                    errors={errors.memberId}
                    searchLoader={showloading.memberId}
                    placeholder={"Select Members"}
                    label="memberId"
                    optionTitle="full_name"
                    fieldName="memberId"
                    objectFieldName="member_id"
                    formData={formData}
                    handleChange={(name, value1, valuez) => {
                      handleAsncChange(name, value1, valuez);
                    }}
                  />

                  {/* </div> */}
                </FormControl>
              </Grid>
            </div>

            <div style={{ display: "flex", gap: 25 }}>
              {showStartDateFor.includes(values.type) && (
                <div className="selectClient1">
                  <FormControl
                    variant="outlined"
                    className={`${classes.formControl} ${classes.inputField}`}
                  >
                    <p className="selectDropDownLabel">START DATE</p>

                    <TextField
                      // For PlaceHolder
                      variant="outlined"
                      displayEmpty
                      renderValue={
                        formData?.entity !== ""
                          ? undefined
                          : () => (
                              <span style={{ color: "#59595B" }}>
                                Select Date
                              </span>
                            )
                      }
                      // native
                      style={{ color: "#FAF9FF" }}
                      labelId="demo-simple-select-outlined-label"
                      // label="Select Product"
                      type="date"
                      id="date"
                      value={values.startDate}
                      onChange={(e) => handleChange(e)}
                      error={touched.startDate && Boolean(errors?.startDate)}
                      endAdornment={
                        <InputAdornment position="end">
                          {browseIcon}
                        </InputAdornment>
                      }
                      inputProps={{
                        max: moment().format("YYYY-MM-DD"),
                        name: "startDate",
                        id: "outlined-entity_type-native-simple",
                        classes: {
                          icon: classes.icon,
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                            backgroundColor: "#1e0d2d",
                            border: "1.164px solid #461277",
                            marginTop: "1rem",
                          },
                        },
                        MenuListProps: {
                          className: classes.selectDropDownMenu,
                        },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    ></TextField>

                    {showError(touched.startDate, errors.startDate)}
                    {/* </div> */}
                  </FormControl>
                </div>
              )}
              <div className="selectClient1">
                <FormControl
                  variant="outlined"
                  className={`${classes.formControl} ${classes.inputField}`}
                >
                  {/* <InputLabel
                  id="demo-simple-select-outlined-label"
                  style={{ background: "black !important" }}
                >
                  Select Product
                </InputLabel> */}
                  {/* <div className="selectDropDown"> */}
                  <p className="selectDropDownLabel">END DATE</p>
                  <TextField
                    // For PlaceHolder
                    variant="outlined"
                    displayEmpty
                    renderValue={
                      formData?.entity !== ""
                        ? undefined
                        : () => (
                            <span style={{ color: "#59595B" }}>
                              Select Product
                            </span>
                          )
                    }
                    // native
                    style={{ color: "#FAF9FF" }}
                    labelId="demo-simple-select-outlined-label"
                    // label="Select Product"
                    type="date"
                    id="date"
                    // value={values.endDate}
                    value={values.endDate}
                    onChange={(e) => handleChange(e)}
                    error={touched.endDate && Boolean(errors?.endDate)}
                    endAdornment={
                      <InputAdornment position="end">
                        {browseIcon}
                      </InputAdornment>
                    }
                    inputProps={{
                      max: moment().format("YYYY-MM-DD"),
                      name: "endDate",
                      id: "outlined-entity_type-native-simple",
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          backgroundColor: "#1e0d2d",
                          border: "1.164px solid #461277",
                          marginTop: "1rem",
                        },
                      },
                      MenuListProps: {
                        className: classes.selectDropDownMenu,
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  ></TextField>
                  {showError(touched.endDate, errors.endDate)}

                  {/* </div> */}
                </FormControl>
              </div>
            </div>
            <Grid xs={12} lg={12} item>
              <FormControl
                variant="outlined"
                className={`${classes.formControl} ${classes.inputField}`}
              >
                <p className="selectDropDownLabel">Denomination</p>
                <Select
                  // For PlaceHolder
                  placeholder="Denomination"
                  name="denomination"
                  displayEmpty
                  // native
                  renderValue={
                    values.denomination !== ""
                      ? undefined
                      : () => (
                          <span style={{ color: "#59595B" }}>
                            Select Denomination
                          </span>
                        )
                  }
                  style={{ color: "#FAF9FF" }}
                  labelId="demo-simple-select-outlined-label"
                  // label="Select Product"
                  value={values.denomination}
                  onChange={(e) => {
                    // console.log(e, "deno");
                    setFieldValue(e.target.name, e.target.value);
                    if (values.exportType == "XLS") {
                      setFieldValue("exportType", "");
                    }
                    handleChange(e);
                  }}
                  error={touched?.denomination && Boolean(errors?.denomination)}
                  inputProps={{
                    name: "denomination",
                    id: "outlined-entity_type-native-simple",
                    classes: {
                      icon: classes.icon,
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                        backgroundColor: "#1e0d2d",
                        border: `1.164px solid ${theme.palette.selectDropDown.border}`,
                        marginTop: "1rem",
                      },
                    },
                    MenuListProps: {
                      className: classes.selectDropDownMenu,
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {(client?.length > 5 || (client && client[0]?.id == "all")
                    ? [{ name: "Actuals", value: "Actuals" }]
                    : denominationOptions
                  ).map((product) => {
                    return (
                      <option
                        className="optiondesignNew"
                        key={product.value}
                        value={product.value}
                      >
                        {product.name}
                      </option>
                    );
                  })}
                </Select>
                {showError(
                  values.denomination !== "Actuals" &&
                    values.denomination != "",
                  "*You can not export XLS"
                )}
                {showError(touched.denomination, errors.denomination)}
                {/* </div> */}
              </FormControl>
            </Grid>
            <Grid container>
              <Grid xs={6} lg={6} item>
                <FormControl
                  variant="outlined"
                  className={`${classes.formControl} ${classes.inputField}`}
                >
                  <p className="selectDropDownLabel">Include Held Away?</p>
                  <Select
                    placeholder="Held Away"
                    displayEmpty
                    style={{ color: "#FAF9FF", marginRight: "8px" }}
                    labelId="demo-simple-select-outlined-label"
                    value={values.includeHeldAway}
                    onChange={(e) => handleChange(e)}
                    error={
                      touched?.includeHeldAway &&
                      Boolean(errors?.includeHeldAway)
                    }
                    inputProps={{
                      name: "includeHeldAway",
                      id: "outlined-entity_type-native-simple",
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          backgroundColor: "#1e0d2d",
                          border: `1.164px solid ${theme.palette.selectDropDown.border}`,
                          marginTop: "1rem",
                        },
                      },
                      MenuListProps: {
                        className: classes.selectDropDownMenu,
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    <option className="optiondesignNew" key={"1"} value={true}>
                      {"Yes"}
                    </option>
                    <option className="optiondesignNew" key={"0"} value={false}>
                      {"No"}
                    </option>
                  </Select>
                  {showError(touched.includeHeldAway, errors.includeHeldAway)}
                  {/* </div> */}
                </FormControl>
              </Grid>
              <Grid xs={6} lg={6} item>
                <FormControl
                  variant="outlined"
                  className={`${classes.formControl} ${classes.inputField}`}
                >
                  <p className="selectDropDownLabel">Export Type</p>
                  <Select
                    // For PlaceHolder
                    placeholder="Export Type"
                    displayEmpty
                    // native
                    style={{ color: "#FAF9FF" }}
                    labelId="demo-simple-select-outlined-label"
                    renderValue={
                      values.exportType !== ""
                        ? undefined
                        : () => (
                            <span style={{ color: "#59595B" }}>
                              Select Export Type
                            </span>
                          )
                    }
                    value={values.exportType}
                    onChange={(e) => handleChange(e)}
                    error={touched?.exportType && Boolean(errors?.exportType)}
                    inputProps={{
                      name: "exportType",
                      id: "outlined-entity_type-native-simple",
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          backgroundColor: "#1e0d2d",
                          border: `1.164px solid ${theme.palette.selectDropDown.border}`,
                          marginTop: "1rem",
                        },
                      },
                      MenuListProps: {
                        className: classes.selectDropDownMenu,
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {getexport(values)}
                  </Select>
                  {showError(touched.exportType, errors.exportType)}
                  {/* </div> */}
                </FormControl>
              </Grid>
            </Grid>
          </div>
        )}
        {values.type == "Cashflow Summary Report" && (
          <div
            className="productUploadContainer fade_purple_bg"
            style={{ margin: "1rem 0 0 0", width: "auto" }}
          >
            <div style={{ display: "flex", gap: 25 }}>
              <Grid xs={12} lg={12} item>
                <FormControl
                  variant="outlined"
                  className={`${classes.formControl} ${classes.inputField}`}
                >
                  <p className="selectDropDownLabel">Members</p>

                  <MultiSelect
                    multiple={true}
                    styles={{ opacity: 1, border: "0px solid black" }}
                    disableClassName="true"
                    data={[{ full_name: "All", member_id: "all" }, ...members]}
                    getSearchData={(value, client1) =>
                      fetchMembers(null, value, client1)
                    }
                    client={client}
                    values={selectedMembers}
                    setValues={setSelectedMembers}
                    errors={errors.memberId}
                    searchLoader={showloading.memberId}
                    placeholder={"Select Members"}
                    label="memberId"
                    optionTitle="full_name"
                    fieldName="memberId"
                    objectFieldName="member_id"
                    formData={formData}
                    handleChange={(name, value1, valuez) => {
                      handleAsncChange(name, value1, valuez);
                    }}
                  />

                  {/* </div> */}
                </FormControl>
              </Grid>
            </div>

            <div style={{ display: "flex", gap: 25 }}>
              <Grid xs={12} lg={12}>
                <FormControl
                  variant="outlined"
                  className={`${classes.formControl} ${classes.inputField}`}
                  style={{ position: "relative" }}
                >
                  <p className="selectDropDownLabel">Select Financial Year</p>
                  <Select
                    // For PlaceHolder
                    placeholder="Year"
                    name="year"
                    displayEmpty
                    // native
                    renderValue={
                      values.format !== ""
                        ? undefined
                        : () => (
                            <span style={{ color: "#59595B" }}>
                              Select Format
                            </span>
                          )
                    }
                    style={{ color: "#FAF9FF" }}
                    labelId="demo-simple-select-outlined-label"
                    // label="Select Product"
                    value={values.year}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    error={touched?.year && Boolean(errors?.year)}
                    inputProps={{
                      name: "year",
                      id: "outlined-entity_type-native-simple",
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          backgroundColor: "#1e0d2d",
                          border: `1.164px solid ${theme.palette.selectDropDown.border}`,
                          marginTop: "1rem",
                        },
                      },
                      MenuListProps: {
                        className: classes.selectDropDownMenu,
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {Array.from(Array(10).keys()).map((num) => {
                      let year = moment().add(1, "year").year();
                      return (
                        <option
                          className="optiondesignNew"
                          key={year - 1}
                          value={year - num}
                        >
                          {year - num - 1} - {year - num}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </div>
            <Grid container>
              <Grid xs={6} lg={6} item>
                <FormControl
                  variant="outlined"
                  className={`${classes.formControl} ${classes.inputField}`}
                >
                  <p className="selectDropDownLabel">Include Held Away?</p>
                  <Select
                    placeholder="Held Away"
                    displayEmpty
                    style={{ color: "#FAF9FF", marginRight: "8px" }}
                    labelId="demo-simple-select-outlined-label"
                    value={values.includeHeldAway}
                    onChange={(e) => handleChange(e)}
                    error={
                      touched?.includeHeldAway &&
                      Boolean(errors?.includeHeldAway)
                    }
                    inputProps={{
                      name: "includeHeldAway",
                      id: "outlined-entity_type-native-simple",
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          backgroundColor: "#1e0d2d",
                          border: `1.164px solid ${theme.palette.selectDropDown.border}`,
                          marginTop: "1rem",
                        },
                      },
                      MenuListProps: {
                        className: classes.selectDropDownMenu,
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    <option className="optiondesignNew" key={"1"} value={true}>
                      {"Yes"}
                    </option>
                    <option className="optiondesignNew" key={"0"} value={false}>
                      {"No"}
                    </option>
                  </Select>
                  {showError(touched.includeHeldAway, errors.includeHeldAway)}
                  {/* </div> */}
                </FormControl>
              </Grid>

              <Grid xs={6} lg={6} item>
                <FormControl
                  variant="outlined"
                  className={`${classes.formControl} ${classes.inputField}`}
                >
                  <p className="selectDropDownLabel">Export Type</p>
                  <Select
                    // For PlaceHolder
                    placeholder="Export Type"
                    displayEmpty
                    // native
                    style={{ color: "#FAF9FF" }}
                    labelId="demo-simple-select-outlined-label"
                    renderValue={
                      values.exportType !== ""
                        ? undefined
                        : () => (
                            <span style={{ color: "#59595B" }}>
                              Select Export Type
                            </span>
                          )
                    }
                    value={values.exportType}
                    onChange={(e) => handleChange(e)}
                    error={touched?.exportType && Boolean(errors?.exportType)}
                    inputProps={{
                      name: "exportType",
                      id: "outlined-entity_type-native-simple",
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          backgroundColor: "#1e0d2d",
                          border: `1.164px solid ${theme.palette.selectDropDown.border}`,
                          marginTop: "1rem",
                        },
                      },
                      MenuListProps: {
                        className: classes.selectDropDownMenu,
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {getexport(values)}
                  </Select>
                  {showError(touched.exportType, errors.exportType)}
                  {/* </div> */}
                </FormControl>
              </Grid>
            </Grid>
          </div>
        )}

        <footer
          style={{
            display: "grid",
            placeItems: "center",
          }}
        >
          <button
            className="productUploadBtn "
            style={{ width: "194px" }}
            onClick={handleSubmit}
          >
            Export
          </button>
        </footer>
      </div>
    </div>
  );
}

export default FilterForm;
