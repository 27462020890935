import React from "react";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";

import useStyles from "../clientDashboard/Clientdashstyle";

import { ReactComponent as Marketuparrow } from "../../../assets/clientdashboard/marketuparrow.svg";
import { ReactComponent as Crownhead } from "../../../assets/clientdashboard/crown.svg";
import { ReactComponent as Moveicon } from "../../../assets/clientdashboard/moveicon.svg";
import { ReactComponent as Marketdownarrow } from "../../../assets/clientdashboard/marketdownarrow.svg";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Back } from "../../../assets/holding-summary/back-blue.svg";
import { ReactComponent as Rightangle } from "../../../assets/icons/borderednext.svg";
import { ReactComponent as Circle } from "../../../assets/clientdashboard/white-circle.svg";
import { currencyFormat } from "../../../hooks/constantFunction";
import { roundNumber } from "../../utils/common";
import { routes } from "../../../routes";

function ClientIndividual({ headOfFamily }) {
  const {
    changePercentage,
    changeValueAmount,
    clientName,
    investedAmount,
    memberName,
    portfolioValueAmount,
    totalFamilyMembers,
  } = headOfFamily || {};
  const { members: membersArray } = headOfFamily || [];
  const navigate = useNavigate();
  const classes = useStyles();
  const handleClick = () => {
    navigate(routes?.homeHoldingPage);
  };

  // let currencyFormat = (value) => {
  //   return currencyFormatLocal(value, {
  //     currencyStyle: { style: "currency", currency: "INR" },
  //   });
  // };

  // function to get the first letter
  function getFirsLetterFromMemberName(memberName) {
    let firstName = memberName.split(" ")[0];
    let lastName = memberName.split(" ")[memberName.split(" ").length - 1];
    return firstName[0] + lastName[0];
  }
  function getlastnameMemberName(memberName) {
    let lastName = memberName.split(" ")[memberName.split(" ").length - 1];
    return lastName;
  }
  return (
    <>
      <div class="containergridCon">
        <Card className="bigmemberscard details cardCon" onClick={handleClick}>
          <div className="bgcirclesbig svgcirclecolor">
            <div className="wid100p">
              <CardContent
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                }}
                className="contentleft"
              >
                {/* <Crownhead className="mrbtm10 bigcrown"> </Crownhead> */}
                <div
                  className="mrbtm10 bigcrown"
                  style={{ position: "relative" }}
                >
                  <Crownhead style={{ width: "100%", height: "100%" }}>
                    {" "}
                  </Crownhead>
                  <div
                    className="big-avatar-design"
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "80%",
                      top: "20%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    {getFirsLetterFromMemberName(memberName)}
                  </div>
                </div>
                <Typography className={classes.bigfamilymembername}>
                  {getlastnameMemberName(memberName)}
                </Typography>
              </CardContent>
            </div>

            <div className="bigblockbtmtext">
              <CardContent>
                <div className="leftcontent fleft">
                  <Typography className={classes.bigdetmembername}>
                    {memberName}
                  </Typography>
                  <Typography className={classes.bigfamilyposn}>
                    Family Head
                  </Typography>
                  <Typography style={{ marginTop: "2%" }} variant="subtitle1">
                    <span className={classes.headmembernos}>
                      {totalFamilyMembers > 1
                        ? `${totalFamilyMembers} Members`
                        : `${totalFamilyMembers} Member`}
                    </span>
                  </Typography>
                </div>
                <div className="rightcontent">
                  <Typography className={classes.bigmemberamt}>
                    {/* $ 500,000,000 */}
                    {currencyFormat(portfolioValueAmount)}
                  </Typography>
                  <Typography>
                    <span className={classes.headmemberrateup}>
                      <Marketuparrow className="mrright5"></Marketuparrow>
                      {/* +1,50,000 <br />
                      (+56.66%) */}
                      {currencyFormat(changeValueAmount)}
                      {changePercentage == 0
                        ? changePercentage.toFixed(2)
                        : roundNumber(changePercentage)}
                    </span>
                  </Typography>
                </div>
              </CardContent>
            </div>
          </div>
        </Card>
        {/* MAP MEMEBERS ARRAY START*/}
        {membersArray?.map((member, index) =>
          member.investedAmount != 0 ? (
            <>
              <Card className="root memberscard details bgcircles cardCon">
                <div className="wid50p fleft">
                  <CardContent className="contentleft">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10%",
                        marginLeft: "9%",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#FFFFFF",
                          borderRadius: "50%",
                          width: "30%",
                          height: "4vw",
                        }}
                      >
                        <span
                          className="avatar-design"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          {" "}
                          {getFirsLetterFromMemberName(member?.memberName)}
                        </span>
                      </div>
                      <div>
                        <Typography
                          // style={{ marginLeft: "40px", marginTop: "22px" }}
                          className={classes.headmembername}
                        >
                          {member?.memberName}
                        </Typography>
                        <Typography variant="subtitle1">
                          <span
                            style={{ marginLeft: "-7px" }}
                            className={classes.headmembernos}
                          >
                            {member?.memberCount > 1
                              ? `${member?.memberCount} Members`
                              : `${member?.memberCount} Member`}
                          </span>
                        </Typography>
                      </div>
                    </div>
                  </CardContent>
                </div>

                <div className="controls">
                  <CardContent className="contentright">
                    <Typography className={classes.headmemberamt}>
                      {/* $ 500,000,000 */}
                      {currencyFormat(member?.portfolioValueAmount)}
                    </Typography>
                    <Typography className={classes.headmemberrateup}>
                      <span
                        className={
                          member?.changeValueAmount < 0
                            ? classes.headmemberratedown
                            : classes.headmemberrateup
                        }
                      >
                        {member?.changeValueAmount < 0 ? (
                          <Marketdownarrow className="mrright5"></Marketdownarrow>
                        ) : (
                          <Marketuparrow className="mrright5"></Marketuparrow>
                        )}
                        {/* {"+" + currencyFormat(member?.changeValueAmount)} <br /> */}

                        {member?.changeValueAmount < 0
                          ? "-" + currencyFormat(member?.changeValueAmount)
                          : "+" + currencyFormat(member?.changeValueAmount)}
                        <br />
                        <div style={{ marginTop: "2%" }}>
                          {member?.changeValueAmount < 0
                            ? "(-" +
                              roundNumber(member?.changePercentage) +
                              "%)"
                            : "(+" +
                              roundNumber(member?.changePercentage) +
                              "%)"}
                        </div>
                        {/* (+56.66%) */}
                      </span>
                    </Typography>
                  </CardContent>
                </div>
              </Card>
            </>
          ) : (
            ""
          )
        )}
      </div>
    </>
  );
}

export default ClientIndividual;
