import React from "react";
import Default from "../components/dashboard/defaultPages/Default";
import AifSummaryComp from "../components/dashboard/products/aif/AifSummaryComp";

function AifSummary() {
  return (
    <>
      <Default>
        <AifSummaryComp></AifSummaryComp>
      </Default>
    </>
  );
}

export default AifSummary;
