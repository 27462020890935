import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";
import "./Step3Table.css";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import AddRoundedIcon from "@material-ui/icons/AddRounded";

function Step3Table({ header, tableData, error }) {
  const getTableHed = (data) => {
    return (
      <TableRow>
        {data?.map((val, i) => {
          return (
            <TableCell
              colSpan={i == 0 ? 2 : 1}
              align={val?.align}
              className="step3TableCellhead"
            >
              <Typography className="loginPageLabel colorBlack">
                {val.title}
              </Typography>
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  // console.log(error, "aglkbagbj");

  const getTablebody = (data) => {
    console.log(data, "aflkagbj");
    return data?.map((row) => (
      <>
        <TableRow className="top10">
          <TableCell className="top10"></TableCell>
        </TableRow>
        <TableRow className="tableRow">
          {row?.map((column, i) => {
            if (i == 0) {
              return (
                <>
                  <TableCell className="expandSec">
                    <AddRoundedIcon className="newButtonAddIcon step3tableAddIcon" />
                  </TableCell>
                  <TableCell
                    key={i}
                    style={{
                      backgroundColor: "#211839 !important",
                    }}
                    className={`summaryDetailsBody step3TableCellBody ${column?.customCellClassName}`}
                    align={column?.align}
                  >
                    <Typography
                      className={`step3TableFont ${column?.customFontClass}`}
                    >
                      {column?.field}
                    </Typography>
                  </TableCell>
                </>
              );
            }

            if (column?.cellType == "dropDown") {
              // console.log(column, "alglanoieni");
              return (
                <TableCell
                  key={i}
                  style={{
                    backgroundColor: "#211839 !important",
                  }}
                  className={`summaryDetailsBody step3TableCellBody ${column?.customCellClassName}`}
                  align={column?.align}
                >
                  <div
                    className="step3tableCellInput step3Benchmark"
                    onClick={column?.onClick}
                  >
                    <p
                      className="step3BenchmarkFont truncate"
                      title={column?.field}
                    >
                      {column?.field}
                    </p>
                    <ArrowBackIosRoundedIcon className="popOverActiveIconArrow" />
                  </div>
                </TableCell>
              );
            }

            if (column?.cellType == "input") {
              return (
                <TableCell
                  key={i}
                  style={{
                    backgroundColor: "#211839 !important",
                  }}
                  className={`summaryDetailsBody step3TableCellBody ${column?.customCellClassName}`}
                  align={column?.align}
                >
                  <input
                    onChange={column?.onChange}
                    defaultValue={column?.field}
                    className={`step3tableCellInput ${
                      error?.totalAllocation
                        ? "step3AllocationCellError"
                        : "step3AllocationCell"
                    }`}
                  ></input>
                </TableCell>
              );
            }

            return (
              <TableCell
                key={i}
                style={{
                  backgroundColor: "#211839 !important",
                }}
                className={`summaryDetailsBody step3TableCellBody ${column?.customCellClassName}`}
                align={column?.align}
              >
                <Typography
                  className={`step3TableFont ${column?.customFontClass}`}
                >
                  {column?.field}
                </Typography>
              </TableCell>
            );
          })}
        </TableRow>
      </>
    ));
  };

  return (
    <div
      style={{
        minHeight: "372px",
        width: "-webkit-fill-available",
        maxHeight: "400px",
        display: "flex",
        position: "relative",
      }}
    >
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>{getTableHed(header)}</TableHead>
          <TableBody>{getTablebody(tableData)}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Step3Table;
