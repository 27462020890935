import React from "react";
import { ReactComponent as DownloadFileicon } from "../../assets/icons/download.svg";
import { ReactComponent as FileBlue } from "../../assets/icons/file_blue.svg";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  statusapproved: {
    color: "green",
    marginLeft: "5px",
    fontWeight: "800",
    fontFamily: "Avenir",
  },
  avatar: {
    backgroundColor: red[500],
  },
  filebluebg: {
    background: "linear-gradient(270deg, #00C1CE 0%, #82EBA1 100%)",
  },
  opacity1: {
    opacity: "1",
  },
  carddefault: {
    color: "#fff",
    background: "#262346",
    borderRadius: "16px",
    margin: "5px",
  },
  detailbox: {
    background: "#1D1B36",
    borderRadius: "16px",
    color: "#635F94",
  },
  titletext: {
    color: "var(--selectedText)",
    fontWeight: "bold",
  },
  text: {
    fontFamily: "Avenir",
  },
  nomargin: {
    margin: "0px",
  },
  subheader: {
    color: "#635F94",
  },
  subnametext: {
    color: "#FFFFFF",
    opacity: "0.7",
  },
}));

function VerifiyCard({ singleContent }) {
  const classes = useStyles();

  const convertDate = (dateOld) => {
    let date = new Date(dateOld);
    date = date.toString("YYYY-MM-dd").split(" ");

    return `${date[2]} ${date[1]} ${date[3]} ${date[4]}`;
  };

  return (
    <Card className={classes.carddefault}>
      <div className={classes.detailbox}>
        <CardHeader
          classes={{ title: classes.titletext }}
          avatar={
            <Avatar aria-label="recipe" className={classes.filebluebg}>
              <FileBlue className={classes.opacity1}></FileBlue>
            </Avatar>
          }
          action={
            <IconButton aria-label="download">
              <a href={singleContent.signed_url} download>
                <DownloadFileicon />
              </a>
            </IconButton>
          }
          title={singleContent.s3_key.split("/")[1].substring(0, 20)}
          subheader={
            <p className={classes.nomargin}>
              <span className={classes.subheader}>
                Upload Details:{convertDate(singleContent.updated_at)}
              </span>
              <br></br>
              <span className={classes.subheader}>By </span>
              <span className={classes.subnametext}>
                {singleContent.created_by_name}
              </span>
            </p>
          }
        />
      </div>
      <CardContent>
        <div style={{ marginBottom: "5px", textAlign: "center" }}>
          <span>
            <span className={classes.text}>Verification Status: </span>
            <span className={classes.statusapproved}>Approved</span>
          </span>
        </div>
      </CardContent>
    </Card>
  );
}

export default VerifiyCard;
