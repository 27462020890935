import React from "react";
import { ReactComponent as Back } from "../../../../assets/holding-summary/back-blue.svg";
import "./topBackComp.css";
import { Typography, useTheme } from "@material-ui/core";

function TopBackComp({ handleClick, title }) {
  const theme = useTheme();
  const icons = theme.palette.icons;
  const BackIcon = icons.backIcon;

  return (
    <div className="topBackCompContainer">
      <BackIcon
        style={{ cursor: "pointer", marginRight: "12px", flexShrink: 0 }}
        onClick={handleClick}
      />
      <Typography className="topBackCompTitle">{title}</Typography>
    </div>
  );
}

export default TopBackComp;
