import React, { useContext, useState } from "react";
import { bottomPerfromers, topBottomPerformers, topPerfromers } from "../api";
import { OnboardingContext } from "../Context";
import { mfoFilterContext } from "../mfo_context/Mfo_Context";
import { objectFormating1 } from "../components/utils/objectFormating";

function useGetPerformaceTable({
  tableType,
  assetClass,
  performerType,
  assetType,
  productType,
}) {
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const { userState } = OnboardingContext();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  let params = {
    // clientId: userState?.loginedUserData?.id,
    // clientId: 199,
    // schemeName: search,
    ...getfilter_as_params(),

    performerType: performerType,
    assetType: assetType,
    productType: productType,
  };

  const getData = async ({ tableHeader }) => {
    setLoading(true);
    try {
      let res;

      res = await topBottomPerformers(userState?.loginedUserData?.id, params);

      let newList = [];
      console.log(res, "asd");
      res?.data?.data?.result?.map((val) => {
        // if (pageName === "holding") {
        let obj = objectFormating1(val, tableHeader, " Days");
        newList.push(obj);
        // }
      });
      console.log(newList, "agakgbj");
      setTableData(() => newList);
      setLoading(false);
      return newList;
    } catch (error) {
      console.log(error, "agakgbj");
      setLoading(false);
    }
  };

  return { getData, loading, tableData };
}

export default useGetPerformaceTable;
