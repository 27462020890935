import axios from 'axios'
import { MUTUAL_FUND_URL, MUTUAL_FUND_TOKEN, MUTUAL_FUND_CLIENT_CODE } from '../Config'

const API = axios.create({
    baseURL: MUTUAL_FUND_URL
})

API.interceptors.request.use( (req) => {
   
    req.headers.Authorization = `${MUTUAL_FUND_TOKEN}`;
    req.headers.client_code = `${MUTUAL_FUND_CLIENT_CODE}`;
    return req;
})

//master api
export const getMutualFundList = (search) => {
    let url = `?limit=10`;
    if(search){ url = url+`&legalName=${search}`}
    
    return API.get(url)
}
