import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { getALLFiles } from "../api";
import { OnboardingContext } from "../Context";
import { revertFormatString } from "../components/verificationUpload/utilsVerificationUpload";

export const useFetchFiles = ({
  pageName,
  formData,
  searchName,
  offset,
  itemLimit,
}) => {
  const [content, setContent] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const { dispatch } = OnboardingContext();

  const fetchFiles = useCallback(async () => {
    const fileStatus = pageName === "pending" ? "pending" : "verified";

    try {
      setShowLoader(true);
      const data = await getALLFiles(
        formData?.entity,
        fileStatus,
        searchName,
        offset,
        itemLimit,
        revertFormatString(
          formData?.fileType === "upcoming_transaction"
            ? "upcoming-transaction"
            : formData?.fileType === "underlying_transaction"
            ? "underlying-transaction"
            : formData?.fileType
        )
      );
      const filesData = data.data;

      if (filesData.success) {
        const totalItem = filesData.data.meta.count;
        setPageCount(Math.ceil(totalItem / itemLimit));
        setContent(filesData.data.result);
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
      setContent([]);
      if (error.response && error.response.request.status === 401) {
        navigate("/logout");
      } else if (error.response && !error.response.data.error.success) {
        dispatch({
          type: "UPDATE_SNACKBAR_DATA",
          payload: {
            success: false,
            error: true,
            showMsg: true,
            message: error.response.data.error.message,
          },
        });
      }
    }
  }, [pageName, formData, searchName, offset, itemLimit, navigate, dispatch]);

  return { fetchFiles, content, pageCount, showLoader };
};
