import React, { useState } from "react";
import { MainCard } from "../../common/HoldingSummaryCards/MainCard";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Chip, Typography } from "@material-ui/core";
import userImg from "../../../assets/clientdashboard/user.png";
const useStyles = makeStyles({
  defaultCard: {
    width: "266px",
    minHeight: "116px",
    borderRadius: "6px",
  },
  placeholderImg: {
    width: "63px",
    height: "63px",
    borderRadius: "50%",
  },
  defaultCardText: {
    color: "#FFF",
    fontFamily: "Avenir",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "32px",
    marginLeft: "6px", // to align the text vertically with the chip
  },
  cardChip: {
    color: "#fff",
    fontFamily: '"Avenir"',
    fontSize: "12px",
    fontWeight: 350,
    lineHeight: "22px",
    background: "#21163e",
  },
  addCard: {
    width: "388px",
    minHeight: "116px",
    background:
      "linear-gradient(147deg, #2B0D58 0%, #692FC0 88.87%, #692FC0 100%)",
    borderRadius: "6px",
  },
  addCardSubText: {
    color: "#FFF",
    fontFamily: "Avenir",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 350,
    lineHeight: "18px",
  },

  cardContentWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "116px",
    margin: 0,
    gap: "20px",
  },
  mapWithCard: {
    width: "285px",
    minHeight: "135px",
    borderRadius: "6px",
    background: "#250E4A",
    margin: "0px",
    cursor: "pointer",
  },

  mapWithCardSelected: {
    width: "285px",
    minHeight: "135px",
    borderRadius: "6px",
    background: "#250E4A",
    border: "4px solid #56358C",
    margin: "0px",
    cursor: "pointer",
  },
  mapWithCardText: {
    color: "#FFF",
    fontFamily: "Avenir",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "22px",
    marginBottom: "8px",
  },
  mapWithCardSubText: {
    color: "#FFF",
    fontFamily: "Avenir",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 350,
    lineHeight: "18px",
    opacity: 0.7,
  },
  mapWithCardContentWrapper: {
    padding: "16px",
    marginTop: "12px",
  },
});

// TODO Remove ClientMapping Component this is only a test component
export const ClientMapping = () => {
  const classes = useStyles();
  const [selectedCard, setSelectedCard] = useState("Banker");

  return (
    <>
      <Box display="flex" alignItems="center">
        <MainCard className={classes.defaultCard}>
          <ClientCardContent
            clientName={
              <Typography className={classes.defaultCardText}>
                Ambani
              </Typography>
            }
            userImg={userImg}
            chip={<Chip className={classes.cardChip} label="Client Name" />}
          />
        </MainCard>
        <MainCard className={classes.defaultCard}>
          <ClientCardContent
            clientName={
              <Typography className={classes.defaultCardText}>
                Ambani
              </Typography>
            }
            chip={<Chip className={classes.cardChip} label="Head Of Family" />}
            userImg={userImg}
          />
        </MainCard>
        <MainCard className={classes.addCard}>
          <Box className={classes.cardContentWrapper}>
            <Box className={classes.placeholderImgWrapper}>
              <Box className={classes.placeholderImg}></Box>
            </Box>
            <Box className={classes.textChipWrapper}>
              <Typography className={classes.defaultCardText}>
                Map With
              </Typography>
              <Typography className={classes.addCardSubText}>
                The User Management screen provides administrators with a
                comprehensive view
              </Typography>
            </Box>
          </Box>
        </MainCard>

        {/* MAP WITH CARD */}
        <MainCard
          className={
            selectedCard === "Banker"
              ? classes.mapWithCardSelected
              : classes.mapWithCard
          }
          onClick={() => setSelectedCard("Banker")}
        >
          <MapWithCardContent
            text={
              <Typography className={classes.mapWithCardText}>
                Banker
              </Typography>
            }
            subText={
              <Typography className={classes.mapWithCardSubText}>
                The User Management screen provides administrators
              </Typography>
            }
          />
        </MainCard>
        <MainCard
          className={
            selectedCard === "Partner"
              ? classes.mapWithCardSelected
              : classes.mapWithCard
          }
          onClick={() => setSelectedCard("Partner")}
        >
          <MapWithCardContent
            text={
              <Typography className={classes.mapWithCardText}>
                Partner
              </Typography>
            }
            subText={
              <Typography className={classes.mapWithCardSubText}>
                The User Management screen provides administrators
              </Typography>
            }
          />
        </MainCard>
      </Box>
    </>
  );
};

export const ClientCardContent = ({ userImg, clientName, chip }) => {
  const classes = useStyles();
  return (
    <Box className={classes.cardContentWrapper}>
      <Box className={classes.placeholderImgWrapper}>
        {userImg && <img src={userImg} className={classes.placeholderImg} />}
      </Box>

      <Box className={classes.textChipWrapper}>
        {clientName}
        {chip}
      </Box>
    </Box>
  );
};

export const MapWithCardContent = ({ text, subText }) => {
  const classes = useStyles();
  return (
    <Box className={classes.mapWithCardContentWrapper}>
      <Box className={classes.textChipWrapper}>
        {text}
        {subText}
      </Box>
    </Box>
  );
};
