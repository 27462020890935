import React, { useEffect, useState } from "react";
import "./topPerformer.css";

import PmsReportTable from "../pms/PmsReportTable";
import PageLoader from "../common/pageLoader";
import { excessPerformanceTable as header } from "./utils/constands";
import { useExcessPerformanceView } from "../../../../hooks/useExcessPerformanceView";
import { roundNumber } from "../../../utils/common";
import { currencyFormat } from "../../../../hooks/constantFunction";

function ExcessPerformance() {
  const [tableDataLoading, setTableDataLoading] = useState(false);
  const [tableHeader, setTableHeader] = useState(header);

  const [loading, setLoading] = useState(false);
  const [loaderAssetClass, setLoaderAssetClass] = useState(false);
  const [newTableColumns, setNewTableColumns] = useState([]);

  const [tablerRows, getBenchmarkTwrr] = useExcessPerformanceView(
    setLoading,
    setLoaderAssetClass
  );

  const objectFormating = (data, headerData) => {
    let newData = headerData.map((header) => {
      if (header?.field == "benchmarkName") {
        return {
          field: data[`${header.field}`] || "--",
          title: header.title,
          show: header.show,
          align: "left",
        };
      }
      if (
        header?.field == "investedAmount" ||
        header?.field == "portfolioValueAmount" ||
        header?.field == "totalGainLoss"
      ) {
        return {
          field: currencyFormat(data[`${header.field}`] || 0) || "--",
          title: header.title,
          show: header.show,
        };
      }
      if (
        header?.field == "portfolioXirr" ||
        header?.field == "excessPerformance" ||
        header?.field == "benchmarkXirr"
      ) {
        return {
          field: !isNaN(data[`${header.field}`])
            ? roundNumber(Number(data[`${header.field}`] * 1)) + " %" || "--"
            : data[`${header.field}`] || "--",
          title: header.title,
          show: header.show,
        };
      }
      if (
        header?.field == "annualisedTwrr" ||
        header?.field == "annualisedTwrr" ||
        header?.field == "absoluteTwrrExcessPerformance" ||
        header?.field == "annualisedTwrrExcessPerformance" ||
        header?.field == "benchmarkAbsoluteTwrr" ||
        header?.field == "benchmarkAnnualisedTwrr"
      ) {
        if (data[`${header.field}`] == "Error") {
          return {
            field: !isNaN(data[`${header.field}`])
              ? roundNumber(Number(data[`${header.field}`] * 1)) || "--"
              : data[`${header.field}`] || "--",
            title: header.title,
            show: header.show,
            hanleErrorRetry: () => getBenchmarkTwrr(),
          };
        }
        return {
          field: !isNaN(data[`${header.field}`])
            ? roundNumber(Number(data[`${header.field}`])) + " %" || "--"
            : data[`${header.field}`] || "--",
          title: header.title,
          show: header.show,
        };
      }
      return {
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`]))
          : data[`${header.field}`] || "--",
        title: header.title,
        show: header.show,
      };
    });
    return newData;
  };

  useEffect(() => {
    if (tablerRows) {
      let newList = [];
      tablerRows?.map((val) => {
        let newVal = objectFormating(val, tableHeader);
        newList.push(newVal);
      });

      setNewTableColumns(newList);
      newList = [];
    }
  }, [tablerRows]);

  console.log("ramtable", newTableColumns);

  return (
    <>
      <div className="topPerformerWrapper" style={{ margin: "3% 2%" }}>
        <div style={{ position: "relative" }}>
          <PmsReportTable
            key={"excess-performance"}
            setTableColumns={setNewTableColumns}
            tableColumns={newTableColumns}
            loading={tableDataLoading}
            headerList={tableHeader}
            tableHeader={tableHeader}
            setTableHeader={setTableHeader}
            stickyProductName={true}
            hidePagination={true}
            tableHeaderBGColor={"#61199C"}
            rowSeperation={"12px"}
            loaderAssetClass={loaderAssetClass}
          />
          <PageLoader showLoader={loading} />
        </div>
      </div>
    </>
  );
}

export default ExcessPerformance;
