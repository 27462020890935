import React, { useState, useEffect } from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import useStyles from "../common/ProductsStyles";
import { useContext } from "react";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import { OnboardingContext } from "../../../../Context";
import moment from "moment";
import { fetchCoverageSummary, getRealisedData } from "../../../../api";
import FlippyArray from "../common/FlippyArray";
import PmsReportTable from "../pms/PmsReportTable";
import { ReactComponent as Filter } from "../../../../assets/icons/filter.svg";
import { ReactComponent as BookIcon } from "../../../../assets/icons/edit-column.svg";
import { currencyFormat } from "../../../../hooks/constantFunction";
import { ITEMS_LIMIT_FILES_PARTNER_LIST } from "../../../../Constants";
import { MainCard } from "../../../common/HoldingSummaryCards/MainCard";
import { NestedCard } from "../../../common/HoldingSummaryCards/NestedCard";
import { useStyles as useStylesRdCards } from "../../../common/HoldingSummaryCards/CardStyles";
import { loadingText } from "../../../utils/constands";
import NewtableTopSec from "../../common/NewtableTopSec";

function InsuranceHealth({
  flip,
  openModal,
  setOpenModal,
  tableColumnsData,
  objectFormating,
}) {
  const [tableColumns, setTableColumns] = useState(tableColumnsData);
  const { mfoFilter } = useContext(mfoFilterContext);
  const { userState } = OnboardingContext();
  const [realisedData, setRealisedData] = useState({});
  const [coverageSummary, setCoverageSummary] = useState([]);
  const classes = useStyles();
  const rdCardClass = useStylesRdCards();
  const [tableHeader, setTableHeader] = useState(tableColumnsData);
  const [loading, setLoading] = useState(false);
  const [newTableColumns, setNewTableColumns] = useState([]);
  const [pagiNationData, setPageinationData] = useState({
    pageCount: null,
    // limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
  });
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const clientId = userState.loginedUserData.id;
    // const clientId = 1;

    const type = "ins";
    //static params for testing
    // let params = {
    //   clientId: 1,
    //   insuranceType: "health",
    //   memberId: "2",
    //   advisor: "Neo",
    //   startDate: "2020-01-02",
    //   endDate: "2023-01-02",
    //   coverReturnType: "cover",
    // };

    let params = { search: search };
    if (mfoFilter.startDate) {
      params["startDate"] = moment(mfoFilter.startDate).format("YYYY-MM-DD");
      params["endDate"] = moment(mfoFilter.endDate).format("YYYY-MM-DD");
    }
    if (Object.keys(mfoFilter.checkData).length > 0) {
      params["memberId"] = Object.keys(mfoFilter.checkData).join(",");
    }
    if (Object.keys(mfoFilter.advisor).length > 0) {
      params["advisor"] = Object.keys(mfoFilter.advisor).join(",");
    }
    params["insuranceType"] = "health";
    setLoading(true);
    getRealisedData(clientId, { ...params }, type).then((res) => {
      setRealisedData(res.result);
    });
    /* Coverage Summary API Call */
    fetchCoverageSummary(
      clientId,
      {
        ...params,
        // productSubCategory: ulip,
        // coverReturnType: "cover",
      },
      type
    )
      .then((res) => {
        setCoverageSummary(res?.data?.data?.result);
        setPageinationData((prev) => {
          return { ...prev, pageCount: res?.data?.data?.meta?.count };
        });
        let newList = [];
        res?.data?.data?.result?.map((val) => {
          let obj = objectFormating(val, tableHeader);
          newList.push(obj);
        });
        setNewTableColumns(newList);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [mfoFilter, search]);
  const TableCellStyle = (color = "white") => ({
    color: color,
  });

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  return (
    <>
      <Grid
        spacing={1}
        container
        className={classes.insurancecardswidth}
        style={{ width: "95%", margin: "2%" }}
      >
        {/* <Grid style={{ height: "10%" }} xs={6}>
          <Card className="bgholdingsummary bigCard">
            <CardContent style={{ marginTop: "10%", marginLeft: "10%" }}>
              <Typography className={classes.cardText}>
                {realisedData.lifCoverAmount != null
                  ? "₹" + realisedData.lifCoverAmount.toLocaleString("en-IN")
                  : "__"}
              </Typography>
              <Typography
                style={{ marginBottom: "5%" }}
                className={classes.cardSubText}
              >
                Life Cover Amount
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid xs={6}>
          <Card className="bgholdingsummary bigCard">
            <CardContent style={{ marginTop: "10%", marginLeft: "10%" }}>
              <Typography className={classes.cardText}>
                {realisedData.premiumAmount != null
                  ? "₹" + realisedData.premiumAmount.toLocaleString("en-IN")
                  : "__"}
              </Typography>
              <Typography
                style={{ marginBottom: "5%" }}
                className={classes.cardSubText}
              >
                Premium Amount
              </Typography>
            </CardContent>
          </Card>
        </Grid> */}

        {/* //** Previous Implementation Of Cards */}
        {/* <FlippyArray
          page={"ins-heal"}
          lifeData={
            realisedData.lifCoverAmount != null
              ? currencyFormat(realisedData.lifCoverAmount)
              : "__"
          }
          premiumData={
            realisedData.premiumAmount != null
              ? currencyFormat(realisedData.premiumAmount)
              : "__"
          }
          flip={flip}
          outerContent={true}
        /> */}
        <Grid item xs={6}>
          <MainCard className="rdMainCard">
            <NestedCard
              className="rdNestedCard"
              style={{
                background:
                  "linear-gradient(269deg, rgba(33, 24, 57, 0.5) 0%, rgba(9, 4, 24, 0.5) 83.33%)",
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className={rdCardClass.nestedCardBoxPadding}
              >
                <h1 className="rdcardText rdCardHeadingTxt">
                  LIFE COVER AMOUNT
                </h1>
                <p className="rdcardText rdCardValueTxt">
                  {realisedData.lifCoverAmount != null
                    ? currencyFormat(realisedData.lifCoverAmount)
                    : loadingText}
                </p>
              </Box>
            </NestedCard>
          </MainCard>
        </Grid>
        <Grid item xs={6}>
          <MainCard className="rdMainCard">
            <NestedCard
              className="rdNestedCard"
              style={{
                background:
                  "linear-gradient(269deg, rgba(33, 24, 57, 0.5) 0%, rgba(9, 4, 24, 0.5) 83.33%)",
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className={rdCardClass.nestedCardBoxPadding}
              >
                <h1 className="rdcardText rdCardHeadingTxt">PREMIUM AMOUNT</h1>
                <p className="rdcardText rdCardValueTxt">
                  {realisedData.premiumAmount != null
                    ? currencyFormat(realisedData.premiumAmount)
                    : loadingText}
                </p>
              </Box>
            </NestedCard>
          </MainCard>
        </Grid>
      </Grid>

      <div style={{ height: "auto", margin: "1.5%" }}>
        <NewtableTopSec
          handleIconClick={handleModal}
          directPageName={"Policies"}
          setSearch={(e) => {
            setOffset(0);
            setSearch(e);
          }}
        />

        {/* <div
          style={{
            display: "flex",
            margin: "0% 2%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            style={{ cursor: "pointer", marginTop: "1%", width: " 60%" }}
            className={classes.titleText}
          >
            Coverage Summary
            Policies
            <Typography
              style={{
                cursor: "pointer",
                // marginLeft: "3%",
                // width: " 70vw",
                marginTop: "1%",
              }}
              className={classes.rowTextTable}
            >
              Health Insurance
            </Typography>
          </Typography>
          <BookIcon onClick={handleModal} className={classes.iconStyle} />
        </div> */}
        {/* <Typography
          style={{
            cursor: "pointer",
            marginLeft: "3%",
            width: " 70vw",
            marginBottom: "2%",
          }}
          className={classes.rowTextTable}
        >
          Health Insurance
        </Typography> */}
        <PmsReportTable
          setTableColumns={setNewTableColumns}
          tableColumns={newTableColumns}
          openModal={openModal}
          loading={loading}
          setOpenModal={setOpenModal}
          headerList={tableColumnsData}
          tableHeader={tableHeader}
          setTableHeader={setTableHeader}
          stickyProductName={true}
          headerInTwoLine={true}
          pageCount={pagiNationData?.pageCount}
          setItemOffset={setOffset}
          pageNumber={offset / ITEMS_LIMIT_FILES_PARTNER_LIST + 1 || 1}
        />
        {/* <Grid xs={12}> 
          <div
            style={{
              minHeight: "300px",
              overflowY: "hidden",
              width: "95%",
              margin: "0 2.5%",
            }}
          >
            <Table>
              <TableHead>
                <TableRow
                  style={{ background: "#4E189F" }}
                  className="tableRowStyle"
                >
                  {tableColumns?.map((column) =>
                    column.show ? (
                      <TableCell
                        style={{ borderBottom: "none" }}
                        key={column.field}
                        align="left"
                        className={`${classes.tableHeaderTextNew} thStyle`}
                      >
                        {column.title}
                      </TableCell>
                    ) : null
                  )}
                </TableRow>
              </TableHead>
              <TableBody className="tableBodyPms">
                <TableRow style={{ height: "10px" }} />
                {/* <TableRow
                  className="tableRowPms"
                  style={{ background: "#1D1B36" }}
                >
                  {tableColumns?.map((column) =>
                    column.show ? (
                      <TableCell
                        key={column.field}
                        align="left"
                        className={`${classes.tableBodyText} tdPms`}
                      >
                        {column.field}
                      </TableCell>
                    ) : null
                  )}
                </TableRow> */}
        {/* {coverageSummary?.map((item, index) => (
                  <TableRow
                    key={index}
                    className="tableRowPms"
                    style={{ background: "1D1B36" }}
                  > */}
        {/* ADVISOR 
                    <TableCell style={TableCellStyle()}>
                      {item.advisor}
                    </TableCell>
                    {/* PRODUCT NAME 
                    <TableCell style={TableCellStyle()}>
                      {firstLetterCapitalize(item.productName)}
                    </TableCell>
                    {/* ASSET ALLOCATION 
                    <TableCell style={TableCellStyle()}>
                      {firstCharCapitalize(item?.assetAllocation)}
                    </TableCell>
                    {/* PRODUCT SUB-CATEGORY 
                    <TableCell style={TableCellStyle()}>
                      {item.productSubCategory.toUpperCase()}
                    </TableCell>
                    {/* COMMENCEMENT DATE 
                    <TableCell style={TableCellStyle()}>
                      {dateFormat_2(item.policyCommencementDate)}
                    </TableCell>
                    {/* END DATE 
                    <TableCell style={TableCellStyle()}>
                      {dateFormat_2(item.policyEndDate)}
                    </TableCell>
                    {/* NO OF PREMIUMS 
                    <TableCell style={TableCellStyle()}>
                      {item.noOfPremiums.toFixed(2)}
                    </TableCell>
                    {/* PREMIUM FREQUENCY 
                    <TableCell style={TableCellStyle()}>
                      {item.premiumFrequency}
                    </TableCell>
                    {/* PREMIUM AMOUNT 
                    <TableCell style={TableCellStyle()}>
                      {currencyFormatINR(item.premiumAmount)}
                    </TableCell>
                    {/* LIFE COVER AMOUNT 
                    <TableCell style={TableCellStyle()}>
                      {currencyFormatINR(item.lifeCoverAmount)}
                    </TableCell>

                    {/* RETURNS TABLE 
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Grid> */}
      </div>
    </>
  );
}

export default InsuranceHealth;
