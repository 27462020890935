import React, { useEffect } from "react";
import DialogContent from "@material-ui/core/DialogContent";
import { NewFilterFamilyMember } from "./NewFilterFamilyMember";
import { NewFilterAdvisor } from "./NewFilterAdvisor";
import { NewFilterOffShore } from "./NewFilterOffShore";
import { NewFilterPeriod } from "./NewFilterPeriod";
import { NewFilterDenomination } from "./NewFilterDenomination";
import { NewFilterAssetClass } from "./NewFilterAssetClass";
import { NewFilterCurrency } from "./NewFilterCurrency";
import { NewFilterExecutedBy } from "./NewFilterExecutedBy";
import { useTheme } from "@material-ui/core";

export const NewFilterBody = ({
  mainTabElement,
  newFilterFamilyMember,
  newFilterAdvisor,
  newFilterExecutedBy,
  newFilterAssetClass,
  newFilterDenomination,
  newFilterPeriod,
  newFilterPromoterStake,
}) => {
  const changePage = (clickedTab) => {
    switch (clickedTab) {
      case "Family Member":
        return newFilterFamilyMember;
      case "Advisor":
        return newFilterAdvisor;
      case "Executed By":
        return newFilterExecutedBy;
      case "Asset Class":
        return newFilterAssetClass;
      case "OffShore":
        return <NewFilterOffShore />;
      case "Currency":
        return <NewFilterCurrency />;
      case "Promoter Stake":
        return newFilterPromoterStake;
      case "Denomination":
        return newFilterDenomination;
      case "Period":
        return newFilterPeriod;
      default:
        // return <NewFilterFamilyMember />;
        return newFilterFamilyMember;
    }
  };
  const theme = useTheme();

  return (
    <DialogContent
      dividers
      style={{ background: theme.palette.newFilter.newFilterBody }}
    >
      <div
        style={{
          minHeight: "394px",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {changePage(mainTabElement)}
      </div>
    </DialogContent>
  );
};
