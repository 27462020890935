import { makeStyles } from "@material-ui/core/styles";
import { Tabs, Tab, Typography, InputAdornment } from "@material-ui/core";
import { useState } from "react";
import { PermissionsGate } from "../../auth/PermissionGate";
import AddButton from "../../common/AddButton";
import ls from "localstorage-slim";
import { useEffect } from "react";
import FullWidthInput from "../../common/FullWidthInput";
ls.config.encrypt = true;

export const CustomTabComponent = ({
  addBtnTitle,
  handleIconClick,
  setTab,
  hideSearch,
  customtabData,
  customStyle,
  customTabHandle,
  setSearch,
  customScopes,
  addRepComp,
}) => {
  const useStyles = makeStyles(() => ({
    tabText: {
      // ** Styles for the non-selected tabs
      ...customStyle?.tabStyle,
      color: "#9B9FB3",
      textAlign: "center",
      fontFamily: "Avenir",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "24px",
      "&.Mui-selected": {
        // ** Styles for the selected tab
        color: "#FFF",
        position: "relative",
        "&::before": {
          content: '""',
          position: "absolute",
          width: "50%", // Adjust the width of the indicator as needed
          height: "2px", // Adjust the height of the indicator as needed
          backgroundColor: "#7B2BF4", // Color of the indicator
          bottom: 0,
          left: "38px", // Adjust the left position of the indicator
          zIndex: 2,
          ...customStyle?.indicatorStyle,
        },
      },
    },

    line: {
      // ** Styles for the horizontal line below the tabs
      background: "#A95DE5",
      height: "0.5px",
      width: "95%", // Adjust the width of the line as needed
      left: "38px", // Adjust the left position of the line
      position: "absolute",
      opacity: 0.4,
    },
    tabContainer: {
      position: "relative",
    },
    tabPanelWrapper: {
      width: "100%", // Set width to 100% to take full width of the container
      margin: "0 auto", // Center horizontally
    },
    fullWidthSearchWrapper: {
      width: "95%",
      margin: "16px auto",
    },
  }));
  const mfo_role_name = ls.get("logged-entity", { secret: 50 })?.mfo_role_name;
  let tabData = [];
  if (mfo_role_name === "cxo" || mfo_role_name === "central_analyst") {
    tabData = [{ label: "Approved", content: "Approved Tab Content" }];
  } else {
    tabData = [
      { label: "Pending", content: "Pending Tab Content" },
      { label: "Approved", content: "Approved Tab Content" },
      { label: "Rejected", content: "Rejected Tab Content" },
    ];
  }
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [newTabData, setNewTabData] = useState([...tabData]);
  const scopes = JSON.parse(ls.get("scopes"));
  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log("fjkahkjahf", newValue);
    setTab(() => {
      if (customTabHandle) customTabHandle();
      return newValue;
    });
  };
  const styles = {
    fullWidthSearchWrapper: {
      width: "95%",
      margin: "27px auto",
    },
  };

  const TabPanel = ({ value, index, children }) => {
    return (
      <div hidden={value !== index} className={classes.tabPanelWrapper}>
        {value === index && (
          <Typography component="div" style={{ padding: 8 }}>
            {children}
          </Typography>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (customtabData) setNewTabData(customtabData);
  }, []);

  return (
    <div className={classes.tabContainer}>
      <Tabs
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
      >
        {newTabData?.map((tab, index) => (
          <Tab key={index} label={tab.label} className={classes.tabText} />
        ))}
        {handleIconClick && addBtnTitle && (
          <div
            style={{ marginLeft: "auto", marginRight: "21px", display: "flex" }}
          >
            {addRepComp && addRepComp}
            <PermissionsGate scopes={customScopes}>
              <AddButton
                addBtnTitle={addBtnTitle}
                handleIconClick={handleIconClick}
              />
            </PermissionsGate>
          </div>
        )}
      </Tabs>

      <div className={classes.line} style={customStyle?.customLineStyle}></div>
      {hideSearch ? (
        ""
      ) : (
        <div style={{ margin: "2%" }}>
          <FullWidthInput setSearch={setSearch} customWrapperStyle={styles} />
        </div>
      )}
      {/* {tabData.map((tab, index) => (
        <TabPanel key={index} value={value} index={index}>
          {/* {tab.content} 
        </TabPanel>
      ))} */}
    </div>
  );
};
