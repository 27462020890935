import React, { useEffect, useState } from "react";
import useStyles from "../common/ProductsStyles";
import Default from "../../defaultPages/Default";
import PageFilter from "../common/PageFilter";
import { ReactComponent as Back } from "../../../../assets/holding-summary/back-blue.svg";
import RiskSlider from "../../../charts/RiskSlider";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import TabHeader from "../common/TabHeader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PieChart from "../../../charts/PieChart";
import { getFundDetails } from "../../../../api";
import Rating from "@material-ui/lab/Rating";
import TableContainer from "@material-ui/core/TableContainer";
import PageLoader from "../common/pageLoader";
import "./RiskDetails.css";
import { HashLink } from "react-router-hash-link";
import { pie_onClick, pie_onClick3 } from "../../../charts/nivo_objects";
import "../common/products-style.css";
import GlowCircle from "../../../charts/GlowCircle";
import { dateFormatCommon, roundNumber } from "../../../utils/common";
import {
  riskLevels,
  EquityTableConfig,
  equitySectorWiseALocationConfig,
  debtSectorWiseALocationConfig,
  hashLinkListEquity,
  hashLinkListDebtAndHybrid,
  riskColor,
} from "./utils/constands";
import { objectFormating } from "./utils/common";
import PmsReportTable from "../pms/PmsReportTable";
import { currencyFormat } from "../../../../hooks/constantFunction";
import { dayFormatter2 } from "../../../utils/common";
import {
  firstCharCapitalize,
  firstLetterCapitalize,
} from "../../../utils/strHelper";
import RiskDetailsDebt from "./RiskDetailsDebt";
import { image_asset_url } from "../../../../Config";
import RiskDetailsPageEquity from "./RiskDetailsPageEquity";
import RiskDetailsPageHybrid from "./RiskDetailsPageHybrid";
import HashlinkSupport from "./utils/HashlinkSupport";
import NewTabComp from "../common/NewTabComp";
import { routes } from "../../../../routes";

function RiskDetails() {
  const classes = useStyles();
  const [tableColumns, setTableColumns] = useState([]);
  const [tableHeader, setTableHeader] = useState(EquityTableConfig);
  const [sectorAllocation, setSectorAlocation] = useState([]);
  const [riskDetails, setRiskDetails] = useState({
    exitLoad: [],
    marketCapAllocation: [
      {
        id: "gaintCap",
        label: "Gaint Cap",
        value: 0,
        color: "#E96114",
      },
      {
        id: "largeCap",
        label: "Large Cap",
        value: 0,
        color: "#3A56FF",
      },
      {
        id: "midCap",
        label: "Mid Cap",
        value: 0,
        color: "#DB2FB5",
      },
      {
        id: "smallCap",
        label: "Small Cap",
        value: 0,
        color: "#711CF1",
      },
      {
        id: "microCap",
        label: "Micro Cap",
        value: 0,
        color: "#82EBA1",
      },
    ],
  });
  const [loading, setLoading] = useState(false);
  const { state, search } = useLocation();
  const navigate = useNavigate();
  const marketCapColors = [
    "#E96114",
    "#3A56FF",
    "#DB2FB5",
    "#711CF1",
    "#82EBA1",
  ];
  const [year, setYear] = useState(1);
  const [headers, setHeaders] = useState([]);
  const [hashLinkList, setHashLinkList] = useState([]);

  const assetProgressBarColors = {
    equity: {
      name: "Equity",
      color: "linear-gradient(143.94deg, #E96114 18.28%, #B80000 86.6%)",
      liColor: "#E96114",
    },
    cash: {
      name: "Cash",
      color: "linear-gradient(270deg, #00C1CE 0%, #82EBA1 100%)",
      liColor: "#00C1CE",
    },
    bond: {
      name: "Bond",
      color: "linear-gradient(180deg, #509FF1 0%, #3A56FF 100%)",
      liColor: "#509FF1",
    },
  };
  const disabledassetProgressBarColors = {
    equity: {
      name: "Equity",
      color: "linear-gradient(143.94deg, #b14a0f 18.28%, #470000 86.6%)",
      liColor: "#b14a0f",
    },
    cash: {
      name: "Cash",
      color: "linear-gradient(270deg, #004b50 0%, #116c2c 100%)",
      liColor: "#00939d",
    },
    bond: {
      name: "Bond",
      color: "linear-gradient(180deg, #0c529b 0%, #000c53 100%)",
      liColor: "#1278e2",
    },
  };
  const [asset_color, setAsset_color] = useState(assetProgressBarColors);
  const sectorWiseColor = [
    "#711CF1",
    "#3A56FF",
    "#82EBA1",
    "#B80000",
    "#DB2FB5",
    "#FF9446",
  ];
  const [marketCapState, setMarketCapState] = useState({
    title: "",
    value: "",
    colors: marketCapColors,
    disabled: [],
  });
  const [sectorWiseState, setSectorWiseState] = useState({
    title: "",
    value: "",
    colors: sectorWiseColor,
    disabled: [],
  });

  const getFundDetailsAPI = async (entity, isin) => {
    try {
      setLoading(true);
      let response = await getFundDetails(entity, isin);

      setRiskDetails((prev) => {
        if (response.data.data.result?.marketCapAllocation) {
          prev.marketCapAllocation.map((val) => {
            val.value = response.data.data.result?.marketCapAllocation[val.id];
          });
        }

        delete response.data.data.result?.marketCapAllocation;

        if (response.data.data.result?.sectorAllocation) {
          let newList = [];
          equitySectorWiseALocationConfig.map((val) => {
            if (response.data.data.result?.sectorAllocation[val.id] > 0) {
              val.value = response.data.data.result?.sectorAllocation[val.id];
              newList.push(val);
            }
          });
          debtSectorWiseALocationConfig.map((val) => {
            if (response.data.data.result?.sectorAllocation[val.id] > 0) {
              val.value = response.data.data.result?.sectorAllocation[val.id];
              newList.push(val);
            }
          });
          setSectorAlocation(newList);
          // console.log(newList, "lifoihoeigaeg");
        }

        return {
          ...prev,
          ...response.data.data.result,
          assetAlocationArray: {
            equity: response.data.data.result?.equityNet,
            cash: response.data.data.result?.cashNet,
            bond: response.data.data.result?.bondNet,
            other: response.data.data.result?.otherNet,
          },
        };
      });

      if (response.data.data.result?.holdingDetails) {
        // setHeaders([
        //   "Name",
        //   ...Object.keys(response.data.data.result?.holdingDetails[0]).filter(
        //     (val) => val !== "Name"
        //   ),
        // ]);
        let newList = [];
        response.data.data.result?.holdingDetails.map((val) => {
          newList.push(objectFormating(val, tableHeader));
        });

        setTableColumns(newList);
        // delete response.data.data.result?.holdingDetails
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const [MousePosition, setMousePosition] = useState({
    left: 30,
    top: -20,
  });

  function handleMouseMove(ev) {
    setMousePosition({ left: ev.pageX, top: ev.pageY });
  }

  useEffect(() => {
    let isin = search?.slice(6);
    getFundDetailsAPI("mf", isin);
    window.location.hash = "#riskSection";
  }, []);

  useEffect(() => {
    if (riskDetails) {
      if (riskDetails?.assetType == "Equity") {
        setHashLinkList(hashLinkListEquity);
      } else {
        setHashLinkList(hashLinkListDebtAndHybrid);
      }
    }
  }, [riskDetails]);

  const navigateBack = () => {
    navigate(routes.mfDetailed_transaction);
  };

  const handleChange = (e) => {
    setYear(e.target.value);
  };

  const sectorWiseData = [
    {
      id: "Financial",
      label: "Financial",
      value: 200,
      color: "hsl(229, 70%, 50%)",
    },
    {
      id: "Communication",
      label: "Communication",
      value: 245,
      color: "hsl(35, 70%, 50%)",
    },
    {
      id: "Technology",
      label: "Technology",
      value: 245,
      color: "hsl(35, 70%, 50%)",
    },
    {
      id: "Construction",
      label: "Construction",
      value: 245,
      color: "hsl(35, 70%, 50%)",
    },
    {
      id: "Material",
      label: "Material",
      value: 245,
      color: "hsl(35, 70%, 50%)",
    },
    {
      id: "Energy",
      label: "Energy",
      value: 245,
      color: "hsl(35, 70%, 50%)",
    },
  ];

  const getRiskLevelValue = (value) => {
    // console.log(riskLevels.indexOf(value),value, "blafwabioasf");
    return riskLevels.indexOf(value) + 1;
  };

  const changeInFundType = (fundType) => {
    switch (fundType) {
      case "Debt":
        return (
          <RiskDetailsDebt
            riskDetails={riskDetails}
            marketCapColors={marketCapColors}
            marketCapState={marketCapState}
            setMarketCapState={setMarketCapState}
            setAsset_color={setAsset_color}
            assetProgressBarColors={assetProgressBarColors}
            asset_color={asset_color}
            disabledassetProgressBarColors={disabledassetProgressBarColors}
            handleMouseMove={handleMouseMove}
            sectorAllocation={sectorAllocation}
            tableColumns={tableColumns}
            setTableColumns={setTableColumns}
            tableHeader={tableHeader}
            setTableHeader={setTableHeader}
            setSectorWiseState={setSectorWiseState}
            sectorWiseState={sectorWiseState}
            sectorWiseColor={sectorWiseColor}
          />
        );
      case "Equity":
        return (
          <RiskDetailsPageEquity
            riskDetails={riskDetails}
            marketCapColors={marketCapColors}
            marketCapState={marketCapState}
            setMarketCapState={setMarketCapState}
            setAsset_color={setAsset_color}
            assetProgressBarColors={assetProgressBarColors}
            asset_color={asset_color}
            disabledassetProgressBarColors={disabledassetProgressBarColors}
            handleMouseMove={handleMouseMove}
            sectorAllocation={sectorAllocation}
            tableColumns={tableColumns}
            setTableColumns={setTableColumns}
            tableHeader={tableHeader}
            setTableHeader={setTableHeader}
            setSectorWiseState={setSectorWiseState}
            sectorWiseState={sectorWiseState}
            sectorWiseColor={sectorWiseColor}
          />
        );
      case "Hybrid":
        return (
          <RiskDetailsPageHybrid
            riskDetails={riskDetails}
            marketCapColors={marketCapColors}
            marketCapState={marketCapState}
            setMarketCapState={setMarketCapState}
            setAsset_color={setAsset_color}
            assetProgressBarColors={assetProgressBarColors}
            asset_color={asset_color}
            disabledassetProgressBarColors={disabledassetProgressBarColors}
            handleMouseMove={handleMouseMove}
            sectorAllocation={sectorAllocation}
            tableColumns={tableColumns}
            setTableColumns={setTableColumns}
            tableHeader={tableHeader}
            setTableHeader={setTableHeader}
            setSectorWiseState={setSectorWiseState}
            sectorWiseState={sectorWiseState}
            sectorWiseColor={sectorWiseColor}
          />
        );
      default:
        return (
          <>
            {/* Investment Details  and Basic Details */}
            <div style={{ margin: "2%" }} id="investmentDetails">
              <Grid container spacing={2} sx={{ height: "100%" }}>
                <Grid md={6} xs={12} item>
                  <Typography
                    style={{ margin: "2%" }}
                    className={`${classes.riskHeaderSub}`}
                  >
                    Investment Details
                  </Typography>
                  <div
                    style={{ display: "flex", margin: "0%", minHeight: "88%" }}
                    className={classes.mainContent}
                  >
                    <div style={{ height: "100%", width: "100%" }}>
                      <Grid container spacing={2} sx={{ height: "100%" }}>
                        <Grid
                          style={{ margin: "3% 3% 3% 5%" }}
                          zeroMinWidth
                          item
                          md={5}
                          xs={12}
                        >
                          <Typography className={classes.riskDetailsTextMain}>
                            Min. Investment ($)
                          </Typography>
                          <Typography className={classes.riskDetailsTextSub}>
                            {riskDetails?.lumpsum?.minimumInvestmentAmount}
                          </Typography>
                        </Grid>
                        <Grid
                          style={{ margin: "3% 3% 3% 5%" }}
                          zeroMinWidth
                          item
                          md={5}
                          xs={12}
                        >
                          <Typography className={classes.riskDetailsTextMain}>
                            Min. SIP Investment ($)
                          </Typography>
                          <Typography className={classes.riskDetailsTextSub}>
                            {riskDetails?.minimumAip || "--"}
                          </Typography>
                        </Grid>
                        <Grid
                          style={{ margin: "5% 3% 3% 5%" }}
                          zeroMinWidth
                          item
                          md={5}
                          xs={12}
                        >
                          <Typography className={classes.riskDetailsTextMain}>
                            Min. No of Cheques
                          </Typography>
                          <Typography className={classes.riskDetailsTextSub}>
                            --
                          </Typography>
                        </Grid>
                        <Grid
                          style={{ margin: "5% 3% 3% 5%" }}
                          zeroMinWidth
                          item
                          md={5}
                          xs={12}
                        >
                          <Typography className={classes.riskDetailsTextMain}>
                            Exit Load
                          </Typography>
                          <Typography className={classes.riskDetailsTextSub}>
                            {riskDetails?.exitLoad?.length > 0 &&
                            riskDetails?.exitLoad[0]?.value &&
                            riskDetails?.exitLoad[0]?.text
                              ? `${Number(
                                  riskDetails?.exitLoad[0]?.value
                                ).toFixed(2)}% for redemption width ${
                                  riskDetails?.exitLoad[0]?.text
                                }`
                              : "--"}
                          </Typography>
                        </Grid>
                        <Grid
                          style={{ margin: "5% 3% 3% 5%" }}
                          zeroMinWidth
                          item
                          md={5}
                          xs={12}
                        >
                          <Typography className={classes.riskDetailsTextMain}>
                            Min. Additional Investment ($)
                          </Typography>
                          <Typography className={classes.riskDetailsTextSub}>
                            {riskDetails?.minimumSubsequent || "--"}
                          </Typography>
                        </Grid>
                        <Grid
                          style={{ margin: "5% 3% 3% 5%" }}
                          zeroMinWidth
                          item
                          md={5}
                          xs={12}
                        >
                          <Typography className={classes.riskDetailsTextMain}>
                            Min. Withdrawal ($)
                          </Typography>
                          <Typography className={classes.riskDetailsTextSub}>
                            {riskDetails?.lumpsum?.minimumRedemptionQty}
                          </Typography>
                        </Grid>
                        <Grid
                          style={{ margin: "5% 3% 3% 5%" }}
                          zeroMinWidth
                          item
                          md={5}
                          xs={12}
                        >
                          <Typography className={classes.riskDetailsTextMain}>
                            Min. Balance ($)
                          </Typography>
                          <Typography className={classes.riskDetailsTextSub}>
                            --
                          </Typography>
                        </Grid>
                        <Grid
                          style={{ margin: "5% 3% 3% 5%" }}
                          zeroMinWidth
                          item
                          md={5}
                          xs={12}
                        >
                          <Typography className={classes.riskDetailsTextMain}>
                            Lock-in Period
                          </Typography>
                          <Typography className={classes.riskDetailsTextSub}>
                            {riskDetails?.initialLockupPeriod || "--"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Grid>
                <Grid item md={6} xs={12}>
                  <div style={{ height: "100%" }}>
                    <Typography
                      style={{ margin: "2%" }}
                      className={`${classes.riskHeaderSub}`}
                    >
                      Basic Details
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        margin: "0%",
                        minHeight: "88%",
                      }}
                      className={classes.mainContent}
                    >
                      <Grid container spacing={2}>
                        <Grid
                          style={{ margin: "3% 3% 1% 5%" }}
                          zeroMinWidth
                          item
                          md={5}
                          xs={12}
                        >
                          <Typography className={classes.riskDetailsTextMain}>
                            Fund House
                          </Typography>
                          <Typography className={classes.riskDetailsTextSub}>
                            {riskDetails?.amcName}
                          </Typography>
                        </Grid>
                        <Grid
                          style={{ margin: "3% 3% 1% 5%" }}
                          zeroMinWidth
                          item
                          md={5}
                          xs={12}
                        >
                          <Typography className={classes.riskDetailsTextMain}>
                            Return Since Inception
                          </Typography>
                          <Typography className={classes.riskDetailsTextSub}>
                            --
                          </Typography>
                        </Grid>
                        <Grid
                          style={{ margin: "1% 3% 3% 5%" }}
                          zeroMinWidth
                          item
                          md={5}
                          xs={12}
                        >
                          <Typography className={classes.riskDetailsTextMain}>
                            Riskometer
                          </Typography>
                          <Typography className={classes.riskDetailsTextSub}>
                            {riskDetails?.riskLevel}
                          </Typography>
                        </Grid>
                        <Grid
                          style={{ margin: "1% 3% 3% 5%" }}
                          item
                          md={5}
                          xs={12}
                        >
                          <Typography className={classes.riskDetailsTextMain}>
                            Assets
                          </Typography>
                          <Typography className={classes.riskDetailsTextSub}>
                            {new Intl.NumberFormat("en-In", {
                              style: "currency",
                              currency: "Ind",
                            })
                              .format(riskDetails?.totalAssets)
                              .replace("IND", "₹")}
                          </Typography>
                        </Grid>
                        <Grid
                          style={{ margin: "1% 3% 3% 5%" }}
                          item
                          md={5}
                          xs={12}
                        >
                          <Typography className={classes.riskDetailsTextMain}>
                            Risk Grade
                          </Typography>
                          <Typography className={classes.riskDetailsTextSub}>
                            --
                          </Typography>
                        </Grid>
                        <Grid
                          style={{ margin: "1% 3% 3% 5%" }}
                          item
                          md={5}
                          xs={12}
                        >
                          <Typography className={classes.riskDetailsTextMain}>
                            Turnover
                          </Typography>
                          <Typography className={classes.riskDetailsTextSub}>
                            {riskDetails?.AnnualReportTurnoverRatio || "--"}
                          </Typography>
                        </Grid>

                        <Grid
                          style={{ margin: "1% 3% 3% 5%" }}
                          item
                          md={5}
                          xs={12}
                        >
                          <Typography className={classes.riskDetailsTextMain}>
                            Inception Date
                          </Typography>
                          <Typography className={classes.riskDetailsTextSub}>
                            {/* {dayFormatter2(riskDetails?.inceptionDate) || "--"} */}
                            {dateFormatCommon(
                              riskDetails?.inceptionDate,
                              "YYYY-MM-DD"
                            ) || "--"}
                          </Typography>
                        </Grid>
                        <Grid
                          style={{ margin: "1% 3% 3% 5%" }}
                          item
                          md={5}
                          xs={12}
                        >
                          <Typography className={classes.riskDetailsTextMain}>
                            Benchmark
                          </Typography>
                          <Typography className={classes.riskDetailsTextSub}>
                            {riskDetails?.primaryProspectusBenchmarks
                              ? riskDetails?.primaryProspectusBenchmarks[0]
                                  .IndexName
                              : "--"}
                          </Typography>
                        </Grid>
                        <Grid
                          style={{ margin: "1% 3% 3% 5%" }}
                          item
                          md={5}
                          xs={12}
                        >
                          <Typography className={classes.riskDetailsTextMain}>
                            Type
                          </Typography>
                          <Typography className={classes.riskDetailsTextSub}>
                            {riskDetails?.assetType}
                          </Typography>
                        </Grid>
                        <Grid
                          style={{ margin: "1% 3% 3% 5%" }}
                          item
                          md={5}
                          xs={12}
                        >
                          <Typography className={classes.riskDetailsTextMain}>
                            Expense Ratio
                          </Typography>
                          <Typography className={classes.riskDetailsTextSub}>
                            {roundNumber(riskDetails?.interimNetExpenseRatio) +
                              "%" || "--"}
                          </Typography>
                        </Grid>
                        <Grid
                          style={{ margin: "1% 3% 3% 5%" }}
                          item
                          md={5}
                          xs={12}
                        >
                          <Typography className={classes.riskDetailsTextMain}>
                            Return Goods
                          </Typography>
                          <Typography className={classes.riskDetailsTextSub}>
                            --
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            {/* assetAllocation */}
            {riskDetails?.assetAllocation && (
              <>
                <Typography
                  style={{ marginLeft: "3%" }}
                  className={`${classes.riskHeaderSub} ${classes.typoInsideAlign}`}
                  id="fundPortfolio"
                >
                  Fund Portfolio
                </Typography>
                <div
                  style={{ padding: "35px 25px 60px 25px" }}
                  className={classes.mainContent}
                >
                  <Typography
                    style={{ margin: "1%" }}
                    className={`${classes.riskHeaderSub}`}
                  >
                    Asset Allocation
                  </Typography>
                  <div className={classes.assetAllocationBar}>
                    <div className={classes.progressBar}>
                      {/* {console.log(riskDetails?.assetAllocation)} */}
                      {riskDetails?.assetAllocation &&
                        Object.keys(riskDetails?.assetAllocation).map(
                          (val, index) => {
                            if (
                              Number(riskDetails?.assetAllocation[val]) > 0 &&
                              assetProgressBarColors[val]
                            )
                              return (
                                <div
                                  onMouseEnter={() => {
                                    let acolors = {
                                      ...disabledassetProgressBarColors,
                                    };
                                    acolors[val] = assetProgressBarColors[val];
                                    setAsset_color(acolors);
                                  }}
                                  onMouseLeave={() => {
                                    setAsset_color({
                                      ...assetProgressBarColors,
                                    });
                                  }}
                                  onMouseMove={(ev) => handleMouseMove(ev)}
                                  className={
                                    classes[`progressBarItem${index + 1}`] +
                                    " outer-tooltip"
                                  }
                                  style={{
                                    width: `${riskDetails?.assetAllocation[val]}%`,
                                    background: asset_color[val]?.color,
                                  }}
                                >
                                  <div className="inner-tooltip graph-tooltip-box">
                                    <span className="graph-tooltip-text capitalize">
                                      {/* {val} */}
                                      {/* {assetProgressBarColors[val]?.name} */}
                                    </span>
                                    <span className="graph-tooltip-text">
                                      {" "}
                                      {roundNumber(
                                        riskDetails?.assetAllocation[val]
                                      )}
                                      25525
                                    </span>
                                  </div>
                                </div>
                              );
                          }
                        )}
                    </div>
                    <ul className={classes.list}>
                      {riskDetails?.assetAllocation &&
                        Object.keys(riskDetails?.assetAllocation).map(
                          (val, index) => {
                            if (
                              Number(riskDetails?.assetAllocation[val]) > 0 &&
                              assetProgressBarColors[val]
                            )
                              return (
                                <li
                                  style={{ color: asset_color[val]?.liColor }}
                                >
                                  <span className={classes.listItems}>
                                    <span>
                                      {assetProgressBarColors[val]?.name}
                                    </span>
                                    <span>
                                      {roundNumber(
                                        riskDetails?.assetAllocation[val]
                                      )}
                                      %
                                    </span>
                                  </span>
                                </li>
                              );
                          }
                        )}
                    </ul>
                  </div>
                </div>
              </>
            )}
            {/* Market Cap Weightage */}
            {riskDetails?.marketCapAllocation.length > 0 && (
              <div className={classes.mainContent}>
                <Typography
                  style={{ margin: "1.5% 1%" }}
                  className={`${classes.riskHeaderSub}`}
                >
                  Market Cap Weightage
                </Typography>
                <div className="flex_center ">
                  <div style={{ width: "50%" }}>
                    <PieChart
                      config={{
                        innerRadius: "0.50",
                        padAngle: 5,
                        legends: false,
                        minWith: "100%",
                        margin: { top: 40, right: 80, bottom: 80, left: 80 },
                        arcLinkLabel: (d) => `${d.value}%`,
                        // margin: { right: 50, top: 50 },

                        chart: { height: 420 },
                      }}
                      colors={riskDetails?.marketCapAllocation.map(
                        (val) => val.color
                      )}
                      onClickEvent={{
                        click: pie_onClick,
                        set: setMarketCapState,
                        cstate: marketCapState,
                        ncolors: marketCapColors,
                      }}
                      data={riskDetails?.marketCapAllocation}
                    ></PieChart>
                  </div>
                  <div style={{ width: "50%" }}>
                    <div style={{ display: "flex" }}>
                      {riskDetails?.marketCapAllocation?.map((m, i) => {
                        return (
                          <div className="llabeContainer">
                            <div className="lsymbol">
                              <svg height="25" width="25">
                                <circle
                                  cx="12"
                                  cy="12"
                                  r="5"
                                  stroke-width="3"
                                  fill={marketCapColors[i]}
                                />
                                Sorry, your browser does not support inline SVG.
                              </svg>
                            </div>
                            <div className="llabel">
                              <span className="labelText">{m?.label}</span>{" "}
                              <div className="labelValue">
                                {roundNumber(m.value)}%
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* End of Market Cap Weightage */}
            {/* <Typography className={`${classes.titleText} ${classes.typoInsideAlign}`}>
                    Fund Details
                </Typography>
                <div style={{ height: "50vh" }} className={classes.mainContent}>
                    <div style={{ height: "35vh", display: "flex", justifyContent: "space-around" }}>
                        <Grid xs={5}>
                            <Typography className={`${classes.titleText} ${classes.typoInsideAlign}`}>
                                Portfolio Aggregates
                            </Typography>
                            <div style={{ height: "35vh", display: "flex" }} className={classes.mainContent}>
                            </div>
                        </Grid>
                        <Grid xs={3}>
                            <Typography className={`${classes.titleText} ${classes.typoInsideAlign}`}>
                                Fund Style
                            </Typography>
                            <div style={{ height: "35vh", display: "flex" }} className={classes.mainContent}>
                            <Box size={3} data={["0-0"]}/>
                            </div>
                        </Grid>
                        <Grid xs={3}>
                            <Typography className={`${classes.titleText} ${classes.typoInsideAlign}`}>
                                Concentration
                            </Typography>
                            <div style={{ height: "35vh", display: "flex" }} className={classes.mainContent}>
                            </div>
                        </Grid>
                    </div>
                </div> */}
            {/* Sector Wise Holding */}
            <Typography
              style={{ marginLeft: "3%" }}
              className={`${classes.riskHeaderSub} ${classes.typoInsideAlign}`}
              id="sectorWiseHolding"
            >
              Sector Wise Holding
            </Typography>
            <div style={{ display: "flex", margin: "2%" }}>
              <Grid container>
                {sectorAllocation.length > 0 && (
                  <Grid item md={5} sm={12}>
                    <div
                      className={classes.mainContent}
                      style={{
                        display: "flex",
                        margin: "0% 2%",
                        alignItems: "center",
                        height: "475px",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "block",
                          margin: "0%",
                          marginTop: "auto",
                          marginBottom: "auto",
                          // height: "422px",
                          width: "527px",
                        }}
                      >
                        <PieChart
                          config={{
                            innerRadius: "0.70",
                            padAngle: 2,
                            legends: false,
                            disableArcLink: true,
                            minWith: "100%",
                            // arcLinkLabel: (d) => `${d.value}%`,
                            // margin: { right: 80, top: 20, left: 80 },
                            height: "300px",
                            chart: { height: 385 },
                          }}
                          colors={sectorAllocation.map((val) => val?.color)}
                          onClickEvent={{
                            click: pie_onClick3,
                            set: setSectorWiseState,
                            cstate: sectorWiseState,
                            ncolors: sectorWiseColor,
                          }}
                          data={sectorAllocation}
                        >
                          <div className="cen1" style={{ color: "#fff" }}>
                            <div className="cir">
                              <div className="glowcircle glowcircle2">
                                <div className="circleInnerText">
                                  <p className="innerCircleTitle">
                                    {sectorWiseState.title}
                                  </p>
                                  <p className="innerCircleValue">
                                    {sectorWiseState?.value &&
                                      roundNumber(sectorWiseState?.value) + "%"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <GlowCircle
                      centerInfoCircleStyle={{
                        top: "50.9%",
                        left: "55%",
                      }}
                      circleBorderStyle={{
                        maxWidth: "144px",
                        height: "144px",
                      }}
                      glowCircleStyle={{ width: "122px", height: "122px" }}
                    >
                      <div className="circle_content">
                        <div> */}
                          {/* <b>{pie.text}</b> */}
                          {/* <br></br> */}
                          {/* <b>{pie.value}</b> */}
                          {/* </div>
                      </div> */}
                          {/* </GlowCircle> */}
                        </PieChart>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            marginBlock: "23px",
                          }}
                        >
                          {sectorAllocation.map((m, i) => {
                            return (
                              <div className="llabeContainer">
                                <div className="lsymbol">
                                  <svg height="25" width="25">
                                    <circle
                                      cx="12"
                                      cy="12"
                                      r="5"
                                      stroke-width="3"
                                      fill={m?.color}
                                    />
                                    Sorry, your browser does not support inline
                                    SVG.
                                  </svg>
                                </div>
                                <div className="llabel">
                                  <span className="labelText sector-wise-holding">
                                    {m?.label}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </Grid>
                )}
                <Grid item md={7} sm={12}>
                  <div
                    style={{
                      maxHeight: "475px",
                      display: "flex",
                      //   width: "97%",
                      margin: "0% 1.5%",
                      height: "100%",
                    }}
                    className={classes.mainContent}
                  >
                    <div style={{ width: "-webkit-fill-available" }}>
                      <PmsReportTable
                        tableMaxHeight={"446px"}
                        tableColumns={tableColumns}
                        setTableColumns={setTableColumns}
                        headerList={EquityTableConfig}
                        tableHeader={tableHeader}
                        setTableHeader={setTableHeader}
                        tableBodyBackground={"transparent"}
                        tableHeaderBGColor={"#1D1B36"}
                      />
                    </div>
                    {/* <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow className="tableRowStyle">
                        {headers.map((val) => (
                          <TableCell
                            style={{ backgroundColor: "#1D1B36" }}
                            className={`${classes.tableHeaderTextNew} thStyle`}
                          >
                            {val == "HoldingType"
                              ? "Holding Type"
                              : val == "Weighting"
                              ? "% Assets"
                              : val}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {riskDetails?.holdingDetails &&
                        riskDetails?.holdingDetails?.map((row) => {
                          return (
                            <TableRow>
                              {headers.map((column) => {
                                return (
                                  <TableCell
                                    className={`${classes.rowTextNew}`}
                                    style={{
                                      borderBottom: "1px solid #28235C",
                                    }}
                                    align="left"
                                  >
                                    {Number(row[column])
                                      ? Number(row[column]).toFixed(2)
                                      : row[column]}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                  {riskDetails?.holdingDetails == undefined ||
                    (headers.length == 0 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "448px",
                        }}
                      >
                        <p
                          style={{
                            color: "#F8F9FB",
                            fontSize: "22px",
                            fontStyle: "normal",
                            fontFamily: "Avenir",
                            fontWeight: "800",
                            lineHeight: "30px",
                          }}
                        >
                          No data found
                        </p>
                      </div>
                    ))}
                </TableContainer> */}
                  </div>
                </Grid>
              </Grid>
            </div>
            {/*End Of Sector Wise Holding */}
          </>
        );
    }
  };

  return (
    <>
      <Default>
        <PageFilter backLink={routes.mfDetailed_transaction}></PageFilter>
        <div style={{ margin: "2%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "15px",
            }}
          >
            {/* <Back onClick={navigateBack} style={{ cursor: "pointer" }} /> */}
            <img
              className={classes.amcName}
              loading={"lazy"}
              src={image_asset_url + riskDetails?.amcImageUrl}
              height={"70px"}
              width={"70px"}
            />
            <div
              style={{
                minWidth: "98px",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "-40px",
              }}
            >
              <Link className={classes.riskLinkTitle}>
                {riskDetails?.assetType}
              </Link>
              <Link className={`textCapitalize ${classes.riskLinkTitle}`}>
                {riskDetails?.schemePlan?.toLowerCase()}
              </Link>
            </div>
          </div>
          <div>
            <Typography
              style={{
                cursor: "pointer",
                marginLeft: "86px",
                marginTop: "-34px",
              }}
              className={classes.riskMainTitle}
            >
              {riskDetails?.legalName} -{" "}
              {/* <span className="textCapitalize"> */}
              &nbsp;{firstCharCapitalize(riskDetails?.schemePlan)} &nbsp;
              {/* </span>{" "} */}
              plan
            </Typography>
            <br></br>
            {/* <span
              className="subtext capitalize"
              style={{ fontSize: "16px", paddingLeft: "4%" }}
            >
              {" "}
              {state ? "ISIN:" + state : ""}
            </span> */}
          </div>
        </div>

        <div style={{ padding: "40px 0px" }} className={classes.mainContent}>
          <div className={classes.riskoMeter}>
            <div>
              <Typography className={classes.riskHeaderMain}>
                Riskometer
              </Typography>
              <Typography
                style={{
                  color: riskColor(riskDetails?.riskLevel),
                  // "linear-gradient(143.94deg, #E96114 18.28%, #B80000 86.6%), #D9D9D9",
                  // WebkitBackgroundClip: "text",
                  // WebkitTextFillColor: "transparent",
                  // backgroundClip: "text",
                  // textFillColor: "transparent",
                }}
                className={classes.riskHeaderSub}
              >
                {riskDetails?.riskLevel}
              </Typography>
            </div>
            {riskDetails?.assetType == "Equity" && (
              <>
                <div style={{ width: "1px", background: "#1F1C40" }}></div>
                <div>
                  <Typography className={classes.riskHeaderMain}>
                    Expense Ratio
                  </Typography>
                  <Typography
                    className={classes.riskHeaderSub}
                    style={{
                      background:
                        "linear-gradient(143.94deg, #E96114 18.28%, #B80000 86.6%), #D9D9D9",
                      WebkitBackgroundClip: "text",
                      backgroundClip: "text",
                      color: "white",
                      marginLeft: "35px",
                    }}
                  >
                    {riskDetails?.interimNetExpenseRatio}%
                  </Typography>
                </div>
              </>
            )}
            <div style={{ width: "1px", background: "#1F1C40" }}></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "7px",
              }}
            >
              <div className="customSelect subtext" style={{ display: "flex" }}>
                Returns
                <select
                  native
                  className="customSelect"
                  style={{
                    color: "#FAF9FF",
                    fontSize: "14px",
                    marginTop: "7px",
                  }}
                  labelId="demo-simple-select-outlined-label"
                  label="Select Product"
                  value={year}
                  onChange={handleChange}
                  // error={Boolean(errors?.entity)}
                  inputProps={{
                    name: "entity",
                    id: "outlined-entity_type-native-simple",
                    classes: {
                      icon: classes.icon,
                    },
                  }}
                >
                  <option className="optiondesign" value={1}>
                    1 years
                  </option>
                  <option className="optiondesign" value={3}>
                    3 years
                  </option>
                  <option className="optiondesign" value={5}>
                    5 years
                  </option>
                </select>
              </div>
              <Typography
                style={{
                  background:
                    "linear-gradient(143.94deg, #E96114 18.28%, #B80000 86.6%), #D9D9D9",
                  WebkitBackgroundClip: "text",
                  // WebkitTextFillColor: "transparent",
                  backgroundClip: "text",
                  // textFillColor: "transparent",
                  color: "#82EBA1",
                }}
                className={classes.riskHeaderSub}
              >
                {roundNumber(riskDetails?.[`return${year}Yr`])} %
              </Typography>
            </div>
            <div style={{ width: "1px", background: "#1F1C40" }}></div>
            <div>
              <Typography className={classes.riskHeaderMain}>
                NAV-Growth
              </Typography>
              <Typography
                style={{
                  flexDirection: "column",
                  // color: "#82EBA1",
                }}
                className={classes.riskHeaderSub}
              >
                <div className="navtextContainer">
                  <p
                    className={`${
                      riskDetails?.dayNavChangePercentage > 0
                        ? "up-green"
                        : "down-red"
                    } navText`}
                  >
                    {currencyFormat(riskDetails?.dayEndNav, {
                      showDecimal: true,
                      currency: "INR",
                      style: "currency",
                    }) || "--"}
                  </p>
                  <p
                    className={`${
                      riskDetails?.dayNavChangePercentage > 0
                        ? "up-green"
                        : "down-red"
                    } navText navPercentage`}
                  >
                    {roundNumber(riskDetails?.dayNavChangePercentage) + "%" ||
                      "--"}
                  </p>
                </div>
                <span
                  style={{
                    color: "#9488A7",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                >
                  As on {dayFormatter2(riskDetails?.dayEndNavDate)}
                </span>
              </Typography>
            </div>
            <div style={{ width: "1px", background: "#1F1C40" }}></div>
            <div className={classes.riskHeaderAlign} style={{ border: "none" }}>
              <Typography className={classes.riskHeaderMain}>
                MorningStar Rank
              </Typography>
              {riskDetails?.rating && (
                <Rating
                  className={classes.startRating}
                  size="large"
                  name="customized-10"
                  readOnly
                  defaultValue={riskDetails?.rating}
                  max={riskDetails?.rating}
                />
              )}
              <Typography
                style={{
                  background:
                    "linear-gradient(143.94deg, #E96114 18.28%, #B80000 86.6%), #D9D9D9",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  backgroundClip: "text",
                  textFillColor: "transparent",
                }}
                className={classes.riskHeaderSub}
              ></Typography>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            margin: "2%",
            alignItems: "center",
            position: "sticky",
            top: "-1px",
            zIndex: "100",
            background: "#16082E",
          }}
          // className={classes.tabMfo}
        >
          <NewTabComp
            customWidth={"100%"}
            data={hashLinkList.map((val) => {
              return {
                ...val,
                title: (
                  <>
                    <HashLink
                      style={{ textDecoration: "none", color: "white" }}
                      scroll={(el) =>
                        el.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        })
                      }
                      // smooth
                      to={val?.id}
                    >
                      {val?.title}
                    </HashLink>
                  </>
                ),
              };
            })}
          />
          {/* {hashLinkList.map((link) => {
            return (
              <HashLink
                style={{ textDecoration: "none" }}
                scroll={(el) =>
                  el.scrollIntoView({ behavior: "smooth", block: "start" })
                }
                // smooth
                to={link?.id}
              >
                <div
                  style={{ paddingTop: "0px" }}
                  className={`${classes.tabHeaderText} ${
                    window.location.hash == link?.id ? classes.active : ""
                  }`}
                >
                  {link?.title}
                </div>
              </HashLink>
            );
          })} */}
        </div>
        <HashlinkSupport id={"riskSection"} />
        <Typography
          style={{ marginLeft: "3%" }}
          className={`${classes.riskTitle} ${classes.typoInsideAlign}`}
          id="riskSection"
        >
          Risk
        </Typography>
        <div
          style={{ display: "flex", padding: "3% 2%" }}
          className={classes.mainContent}
        >
          <div style={{ width: "100%", height: "150px" }}>
            <Typography
              className={`${classes.titleText} ${classes.riskText}`}
              style={{ color: riskColor(riskDetails?.riskLevel) }}
            >
              This fund has{" "}
              <span style={{ textTransform: "capitalize" }}>
                {riskDetails?.riskLevel}
              </span>
            </Typography>
            <div style={{ minHeight: "80px" }}>
              <RiskSlider
                riskValue={
                  riskDetails?.riskLevelId
                    ? 16.67 * getRiskLevelValue(riskDetails?.riskLevel) - 8.3
                    : null
                }
              />
            </div>
            <Typography
              style={{ marginLeft: "1%", marginTop: "1%" }}
              className={`${classes.mfoTextCard}`}
            >
              As per SEBI’s Riskometer
            </Typography>
          </div>
        </div>
        {/* Risk Measures */}
        <div style={{ maxHeight: "60vh" }} className={classes.mainContent}>
          <Typography
            style={{ margin: "1.5% 1%" }}
            className={`${classes.riskHeaderSub}`}
          >
            Risk Measures
          </Typography>
          <Table
            style={{
              borderCollapse: "separate",
              width: "98%",
              margin: "1% auto",
            }}
            aria-label="simple table"
          >
            <TableHead style={{ background: "#1D1B36" }}>
              <TableRow>
                <TableCell
                  style={{
                    borderTopLeftRadius: "20px",
                    borderBottomLeftRadius: "20px",
                  }}
                  className={classes.tableHeaderTextNew}
                ></TableCell>
                <TableCell className={classes.tableHeaderTextNew}>
                  Alpha(%)
                </TableCell>
                <TableCell className={classes.tableHeaderTextNew}>
                  Std Dev(%)
                </TableCell>
                <TableCell className={classes.tableHeaderTextNew}>
                  Sharpe(%)
                </TableCell>
                <TableCell className={classes.tableHeaderTextNew}>
                  Sortino(%)
                </TableCell>
                <TableCell className={classes.tableHeaderTextNew}>
                  Beta(%)
                </TableCell>
                {/* <TableCell
                  style={{
                    borderTopRightRadius: "20px",
                    borderBottomRightRadius: "20px",
                  }}
                  className={classes.tableHeaderTextNew}
                >
                  Alpha(%)
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  className={`${classes.rowTextNew} ${classes.paddingLeft}`}
                  style={{ borderBottom: "1px solid #28235C" }}
                  align="left"
                >
                  {riskDetails?.amcName ? riskDetails?.amcName : "--"}
                </TableCell>
                <TableCell
                  className={classes.rowTextNew}
                  style={{ borderBottom: "1px solid #28235C" }}
                  align="left"
                >
                  {riskDetails?.[`alpha${year}Yr`]
                    ? Number(riskDetails?.[`alpha${year}Yr`]).toFixed(2)
                    : "--"}
                </TableCell>
                <TableCell
                  className={classes.rowTextNew}
                  style={{ borderBottom: "1px solid #28235C" }}
                  align="left"
                >
                  {riskDetails?.[`stdDev${year}Yr`]
                    ? Number(riskDetails?.[`stdDev${year}Yr`]).toFixed(2)
                    : "--"}
                </TableCell>
                <TableCell
                  className={classes.rowTextNew}
                  style={{ borderBottom: "1px solid #28235C" }}
                  align="left"
                >
                  {riskDetails?.[`sharpeRatio${year}Yr`]
                    ? Number(riskDetails?.[`sharpeRatio${year}Yr`]).toFixed(2)
                    : "--"}
                </TableCell>
                <TableCell
                  className={classes.rowTextNew}
                  style={{ borderBottom: "1px solid #28235C" }}
                  align="left"
                >
                  {riskDetails?.[`sortinoRatio${year}Y`]
                    ? Number(riskDetails?.[`sortinoRatio${year}Y`]).toFixed(2)
                    : "--"}
                </TableCell>
                <TableCell
                  className={classes.rowTextNew}
                  style={{ borderBottom: "1px solid #28235C" }}
                  align="left"
                >
                  {riskDetails?.[`beta${year}Yr`]
                    ? Number(riskDetails?.[`beta${year}Yr`]).toFixed(2)
                    : "--"}
                </TableCell>
                {/* <TableCell
                  className={classes.rowTextNew}
                  style={{ borderBottom: "1px solid #28235C" }}
                  align="left"
                >
                  {riskDetails?.[`alpha${year}Yr`]
                    ? Number(riskDetails?.[`alpha${year}Yr`]).toFixed(2)
                    : "--"}
                </TableCell> */}
              </TableRow>
            </TableBody>
          </Table>
        </div>
        {changeInFundType(riskDetails?.assetType)}
      </Default>
      <PageLoader showLoader={loading} />
    </>
  );
}

export default RiskDetails;
