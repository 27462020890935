import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Overall_portfolio from "./Overall_portfolio";
import Pdf from "../pdf";
import { Snackbar, SnackbarContent } from "@material-ui/core";
import { StackTable } from "../Components/StackTable";
import "./canvas.css";
import PDFHoldingSummary from "../PDFHoldingSummary";
import { useParams, useSearchParams } from "react-router-dom";
import HoldingPdf from "../holdingPdf";
import TransactionSummaryPdf from "../TransactionSummaryPdf";
import ClientPortfolioPdf from "../ClientPortfolioPdf";
import { printPdfUpload } from "../../../../api";
import ls from "localstorage-slim";
import TaxReport from "../TaxReport";
import { saveAs } from "file-saver";
import CashflowReport from "../CashflowReport";
import PmsUnderlyingReport from "../PmsUnderlyingReport";
import UnrealisedGainLossReport from "../UnrealisedGainLossReport";
import IncomeReport from "../IncomeReport";
export default function ComponentToPDF({ src }) {
  const divRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [showdownload, setshowDownload] = useState(false);
  const [progress, setProgress] = useState(0);
  const { sub } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  // let denomination = "Lakhs";
  const mongoId = searchParams.get("mongoId");
  const token = searchParams.get("token");
  if (src) {
    let loginUserData = {
      token: token || "7093ecfdc133d238b1f28d99875369cf",
    };
    ls.set("logged-entity", loginUserData, { secret: 50 });
  }

  const download = searchParams.get("isAutoDownload");
  const report_type = searchParams.get("reportType");
  useLayoutEffect(() => {}, []);
  // console.log(report_type, "report_type");
  function getType(type) {
    switch (type) {
      case "holding": {
        return "Holding Summary";
      }
      case "security-holding": {
        return "Security Holding Summary";
      }
      case "transaction": {
        return "Transaction Summary";
      }
      case "client-portfolio-report": {
        return "Client Portfolio Summary";
      }
      default: {
        return <Pdf />;
      }
    }
  }

  // const handleConvertToPDF = async () => {
  //   setOpen(true);
  //   const pdfWidth = 900;
  //   const pdfHeight = 977;
  //   const pdf = new jsPDF({
  //     format: [pdfWidth, pdfHeight],
  //   });

  //   // Get all the pages
  //   const pages = divRef.current.querySelectorAll(".pdf-page");

  //   // Iterate through each page and convert to canvas
  //   for (let i = 0; i < pages.length; i++) {
  //     const page = pages[i];
  //     const canvas = await html2canvas(page, {
  //       useCORS: true,
  //     });
  //     const imgData = canvas.toDataURL("image/png");

  //     if (i !== 0) {
  //       pdf.addPage();
  //     }

  //     pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
  //   }

  //   // Save the PDF
  //   setOpen(false);
  //   pdf.save("component.pdf");
  // };

  // 2
  const handleConvertToPDF = async () => {
    if (url) {
      return saveAs(url);
    }
    setOpen(true);
    setProgress(0);
    const pdfWidth = 25.6; // Convert inches to points (1 inch = 72 points)
    const pdfHeight = 18.08;

    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "in",
      format: [pdfWidth, pdfHeight],
      compressPdf: true,
    });

    // Get all the pages
    const pages = divRef.current.querySelectorAll(".pdf-page");

    // Iterate through each page and convert to canvas
    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];
      const canvas = await html2canvas(page, {
        useCORS: true,
      });
      const imgData = canvas.toDataURL("image/jpeg", 1); // Change image format if needed

      if (i !== 0) {
        pdf.addPage();
      }

      const imgProps = pdf.getImageProperties(imgData);
      // const margin = 0.001;

      // const pdfWidthAdjusted = pdf.internal.pageSize.width * (1 - margin);
      // const pdfHeightAdjusted = pdf.internal.pageSize.height * (1 - margin);

      // const x = pdf.internal.pageSize.width * (margin / 2);
      // const y = pdf.internal.pageSize.height * (margin / 2);

      // const widthRatio = pdfWidthAdjusted / imgProps.width;
      // const heightRatio = pdfHeightAdjusted / imgProps.height;
      // const ratio = Math.min(widthRatio, heightRatio);

      // const w = imgProps.width * ratio;
      // const h = imgProps.height * ratio;
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
      // Update progress
      const progressPercentage = ((i + 1) / pages.length) * 100;
      setProgress(progressPercentage);
    }

    // Save the PDFs
    setOpen(false);
    pdf.save(getType(sub) + ".pdf");
  };
  async function dataUrlToFile(dataUrl, fileName) {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    const file = new File([blob], fileName, { type: "application/pdf" });

    const fd = new FormData();
    fd.append("file", file);
    fd.append("clientId", 267);
    fd.append("reportType", "Holding Summary Report");
    fd.append("isScheduledReport", 1);
    fd.append("mongoId", mongoId);
    return printPdfUpload(fd)
      .then((res) => {
        console.log(res);
        setshowDownload(true);
      })
      .catch((err) => {
        setshowDownload(true);
      });
  }
  const handleUploadPDF = async () => {
    setOpen(true);
    setProgress(0);
    const pdfWidth = 25.6; // Convert inches to points (1 inch = 72 points)
    const pdfHeight = 18.08;

    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "in",
      format: [pdfWidth, pdfHeight],
      compressPdf: true,
    });

    // Get all the pages
    const pages = divRef.current.querySelectorAll(".pdf-page");

    // Iterate through each page and convert to canvas
    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];
      const canvas = await html2canvas(page, {
        useCORS: true,
      });
      const imgData = canvas.toDataURL("image/jpeg", 1); // Change image format if needed

      if (i !== 0) {
        pdf.addPage();
      }

      const imgProps = pdf.getImageProperties(imgData);
      // const margin = 0.001;

      // const pdfWidthAdjusted = pdf.internal.pageSize.width * (1 - margin);
      // const pdfHeightAdjusted = pdf.internal.pageSize.height * (1 - margin);

      // const x = pdf.internal.pageSize.width * (margin / 2);
      // const y = pdf.internal.pageSize.height * (margin / 2);

      // const widthRatio = pdfWidthAdjusted / imgProps.width;
      // const heightRatio = pdfHeightAdjusted / imgProps.height;
      // const ratio = Math.min(widthRatio, heightRatio);

      // const w = imgProps.width * ratio;
      // const h = imgProps.height * ratio;
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
      // Update progress
      const progressPercentage = ((i + 1) / pages.length) * 100;
      setProgress(progressPercentage);
    }

    // Save the PDFs
    setOpen(false);
    const out = pdf.output("datauristring");
    dataUrlToFile(out, getType(sub) + ".pdf");

    // pdf.save(getType(sub) + ".pdf");
  };
  const vertical = "bottom"; // Updated value
  const horizontal = "right";

  const handlePDF = (type) => {
    switch (type) {
      case "holding": {
        return <HoldingPdf type={"holding"} setUrl={setUrl} />;
      }
      case "pms": {
        return <PmsUnderlyingReport type={"pms"} setUrl={setUrl} />;
      }
      case "Holding Summary Report": {
        return (
          <HoldingPdf
            type={"holding"}
            mongo={mongoId}
            handleUploadPDF={handleUploadPDF}
            download={download}
          />
        );
      }

      case "Transaction Summary Report": {
        return (
          <TransactionSummaryPdf
            mongo={mongoId}
            handleUploadPDF={handleUploadPDF}
            download={download}
          />
        );
      }
      case "Consolidated Securities Holding Summary Report": {
        return (
          <HoldingPdf
            type={"security-holding"}
            mongo={mongoId}
            handleUploadPDF={handleUploadPDF}
            download={download}
          />
        );
      }
      case "Client Portfolio Report": {
        return (
          <ClientPortfolioPdf
            mongo={mongoId}
            handleUploadPDF={handleUploadPDF}
            download={download}
          />
        );
      }
      case "Cashflow Summary Report": {
        return (
          <CashflowReport
            mongo={mongoId}
            handleUploadPDF={handleUploadPDF}
            download={download}
          />
        );
      }
      case "Performance Summary Report": {
        return (
          <Pdf
            mongo={mongoId}
            handleUploadPDF={handleUploadPDF}
            download={download}
          />
        );
      }
      case "Tax Report": {
        return (
          <TaxReport
            mongo={mongoId}
            handleUploadPDF={handleUploadPDF}
            download={download}
          />
        );
      }
      case "Realized GainLoss Report": {
        return (
          <UnrealisedGainLossReport
            mongo={mongoId}
            handleUploadPDF={handleUploadPDF}
            download={download}
          />
        );
      }
      case "PMS Underlying Transactions": {
        return (
          <PmsUnderlyingReport
            mongo={mongoId}
            handleUploadPDF={handleUploadPDF}
            download={download}
          />
        );
      }
      case "Income Statement": {
        return (
          <IncomeReport
            mongo={mongoId}
            handleUploadPDF={handleUploadPDF}
            download={download}
          />
        );
      }
      case "security-holding": {
        return <HoldingPdf type={"security-holding"} />;
      }
      case "transaction": {
        return <TransactionSummaryPdf setUrl={setUrl} />;
      }
      case "client-portfolio-report": {
        return <ClientPortfolioPdf />;
      }
      default: {
        return <Pdf />;
      }
    }
  };

  // const pages = divRef?.current?.children;
  // console.log(pages, "asfkbjafsajf");

  return (
    <div>
      <button
        style={{
          position: "fixed",
          top: "20px",
          right: "10px",
          background: "#380D77",
          width: "100px",
          borderRadius: "4px",
          color: "white",
          cursor: "pointer",
          border: "none",
          padding: "8px",
          zIndex: "1",
          display: src && !showdownload ? "none" : "",
        }}
        id={"download-button"}
        onClick={handleConvertToPDF}
      >
        Download PDF
      </button>
      <Snackbar anchorOrigin={{ vertical, horizontal }} open={open}>
        <SnackbarContent
          style={{ backgroundColor: "#380D77", color: "white" }}
          message={`Downloading PDF (${progress.toFixed(0)}%)`}
        />
      </Snackbar>
      <div ref={divRef}>
        {/* <Pdf></Pdf> */}
        {handlePDF(sub || report_type)}
      </div>
    </div>
  );
}
