import React from "react";
import { Box, Typography } from "@material-ui/core";
import "../../../print/pag1.css";
import pdfLogo from "../../../../../assets/images/pdfLogo.png";
import newNeoLogo from "../../../../../assets/icons/newNeoLogo.png";
import printReportCover from "../../../../../assets/images/PrintReportCover.png";
import pdfReportCover from "../../../../../assets/images/PdfReportCover.png";
import { useLocation } from "react-router";
import moment from "moment";

export const CoverPage = ({ type, title, meta, pdfInfo }) => {
  function getTextColor() {
    let black = "#000000";
    let white = "#FFFFFF";
    return black;
  }
  let backgroundStyle = {
    background: "#ffffff",
    display: "flex",
    heigh: "40%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
  function coverformat(title) {
    if (
      (title == "Transaction Summary" && pdfInfo?.startDate) ||
      (title == "Realised Gain/Loss Report" && pdfInfo?.startDate) ||
      (title == "PMS Underlying Transaction" && pdfInfo?.startDate) ||
      (title == "Income Statement" && pdfInfo?.startDate)
    ) {
      return (
        <>
          <div
            className="infoContainer"
            style={{ paddingBottom: "0px", paddingTop: "30px" }}
          >
            <p className="onDate">Start Date</p>
            <p className="onDate" style={{ paddingLeft: "195px" }}>
              End Date
            </p>
          </div>
          {console.log(pdfInfo, "pdfInfo")}
          <div className="infoContainer">
            <p className="onDate">{pdfInfo?.startDate}</p>
            <p className="onDate" style={{ paddingLeft: "154px" }}>
              {pdfInfo?.endDate}
            </p>
          </div>
        </>
      );
    } else if (title == "Tax Report") {
      return (
        <>
          <p className="onDate" style={{ display: "flex", paddingTop: "30px" }}>
            Financial Year:{" "}
            <p style={{ paddingLeft: "12px" }}>{pdfInfo?.startDate}</p>
            {" - "}
            <p style={{ paddingLeft: "12px" }}>{pdfInfo?.endDate}</p>
          </p>
        </>
      );
    } else if (title == "Cash Flow Summary") {
      return (
        <>
          <p className="onDate" style={{ display: "flex", paddingTop: "30px" }}>
            Financial Year{" "}
            <p style={{ paddingLeft: "92px" }}>
              {moment(pdfInfo?.endDate, "DD-MMM-YYYY")
                .subtract(1, "year")

                .format("YYYY") + " - "}
            </p>
            <p style={{ paddingLeft: "5px" }}>
              {moment(pdfInfo?.endDate, "DD-MMM-YYYY").format("YYYY")}
            </p>
          </p>
        </>
      );
    } else {
      return (
        <>
          <p className="onDate" style={{ display: "flex", paddingTop: "30px" }}>
            As on Date:{" "}
            <p style={{ paddingLeft: "12px" }}>{pdfInfo?.endDate}</p>
          </p>
        </>
      );
    }
  }
  return (
    <div className="pdf-page">
      <div style={backgroundStyle}>
        <Box
          className="left-box"
          style={{ justifyContent: "unset", paddingTop: "1%" }}
        >
          {/* Logo */}
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              width: "279px",
            }}
          >
            <img src={newNeoLogo} alt="logo"></img>
            <h1
              style={{
                fontSize: "20px",
                fontStyle: "normal",
                marginLeft: "5px",
                fontFamily: "Avenir",
                fontWeight: "800",
                letterSpacing: "3px",
                textTransform: "uppercase",
              }}
            >
              Wealth Partners
            </h1>
          </Box>

          {/* Main Text */}
          <Box style={{ paddingTop: "31%" }}>
            <div
              className="infoContainer"
              style={{ flexDirection: "column", gap: "8px" }}
            >
              <p className="clientName">{pdfInfo?.client}</p>
              <p className="numberOfMember">
                No. of Members:
                <span style={{ paddingLeft: "5px" }}>
                  {pdfInfo?.totalMembers}
                </span>
              </p>
              <div className="divider"></div>
              <Typography
                id="main-header"
                variant="h1"
                style={{
                  color: getTextColor(),
                  fontSize: "40px",
                  fontFamily: "AvenirBold",
                  letterSpacing: "1px",
                  padding: "40px 0px",
                }}
                className="holdingTitle"
              >
                {title || "Holding Summary"}
              </Typography>
              <div className="divider"></div>

              {coverformat(title)}

              {/* <p className="startDate">End Date</p> */}
            </div>

            {/* <div className="infoContainer">
            <p className={"date1 " + " w4in"}>{pdfInfo?.client}</p>
            <p className="date1">{pdfInfo?.totalMembers}</p>
          </div> */}
          </Box>

          {/* Client Name */}
          {/* // un coment this box only */}
          {/* <Box style={{ visibility: "hidden" }}>
          <p
            className="main-header"
            style={{
              fontSize: "19px",
              fontWeight: "350",
              color: getTextColor(),
            }}
          >
            Client Name
          </p>
          <Typography
            variant="h4"
            className="client-text"
            style={{ color: getTextColor() }}
          >
            Rahul Vakil
          </Typography>
          <p
            className="main-header"
            style={{
              fontSize: "19px",
              fontWeight: "350",
              marginTop: "40px",
              color: getTextColor(),
            }}
          >
            No of Members:
          </p>
          <Typography
            variant="h4"
            className="client-text"
            style={{ color: getTextColor() }}
          >
            24
          </Typography>
        </Box> */}

          {/* Relationship Manager */}
          {/* <Box>
          <p className="rm-main-text" style={{ color: getTextColor() }}>
            YOUR RELATIONSHIP MANAGER
          </p>
          <Box
            className="d_flex_space_around_center"
            style={{ color: getTextColor() }}
          >
            <p>
              Name -{" "}
              <span style={{ color: getTextColor() }} className="rm-subText">
                Ralph Futardo
              </span>
            </p>
            <p>
              Mobile -{" "}
              <span style={{ color: getTextColor() }} className="rm-subText">
                9819812498
              </span>
            </p>
            <p>
              Email -{" "}
              <span style={{ color: getTextColor() }} className="rm-subText">
                johndoe@email.com
              </span>
            </p>
          </Box>
        </Box> */}
        </Box>

        <Box
          className="right"
          style={{
            width: "45%",
            // paddingRight: "2%",
          }}
        >
          <img
            src={printReportCover}
            style={{
              marginTop: "215px",
              marginLeft: "55px",
              maxHeight: "80%",
            }}
          />
        </Box>
      </div>
      <div
        style={{
          backgroundColor: "#f5f9ff",
          display: "flex",
          justifyContent: "space-between",
          padding: "10px 36px 10px 36px",
          height: "100%",
        }}
      >
        {pdfInfo?.banker_name && (
          <div>
            <Typography>
              <span
                style={{
                  color: "#000000",
                  fontWeight: "700",
                  fontSize: "14px",
                }}
              >
                {pdfInfo?.banker_name}
              </span>
              <span style={{ fontSize: "14px", opacity: ".8" }}>
                {" "}
                ( RELATIONSHIP MANAGER )
              </span>
            </Typography>
            <Typography style={{ fontSize: "14px" }}>
              {pdfInfo?.banker_email} | {pdfInfo?.banker_mobile}
            </Typography>
          </div>
        )}
        {pdfInfo?.cso_name && (
          <div>
            <Typography>
              <span
                style={{
                  color: "#000000",
                  fontWeight: "700",
                  fontSize: "14px",
                }}
              >
                {pdfInfo?.cso_name}
              </span>
              <span style={{ fontSize: "14px", opacity: ".8" }}>
                {" "}
                ( SERVICE MANAGER )
              </span>
            </Typography>
            <Typography style={{ fontSize: "14px", textAlign: "right" }}>
              {pdfInfo?.cso_email} | {pdfInfo?.cso_mobile}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};
