import React from "react";
import useStyles from "../common/ProductsStyles";

function DataNotFound({ text }) {
  const classes = useStyles();

  return (
    <div className={classes.dataAlign}>
      <h1 className={`${classes.titleText} capitalize`}>{text ? text : "Data not found"}</h1>
    </div>
  );
}

export default DataNotFound;
