import { Box, Typography } from "@material-ui/core";
import React from "react";
import PartnerHeading from "../PartnerHeading";
import "../NewSignUp.css";
import LoadingButton from "./LoadingButton";
import Great from "../../common/Great";

function RepresentativeAddComp({ handleNext, customBack }) {
  return (
    <div className="dFlex jtfyContCntr alignCntr h100">
      <Box
        className="dFlex jtfyContCntr alignCntr flexDirCol"
        sx={{ width: { xs: "auto", sm: "386px" }, gap: "41px" }}
      >
        {" "}
        <div>
          <PartnerHeading
            handleBack={
              customBack
                ? customBack
                : () => {
                    handleNext((prev) => prev - 1);
                  }
            }
            mainHead={<Great />}
            subtitle="You’ve added you'r details Successfully"
            noStep={true}
          />
          <div style={{ marginBottom: "10px", marginTop: "8px" }}>
            <div
              style={{
                borderLeft: "3px solid #D4BBFB",
                height: "188px",
                opacity: "0.16",
                marginLeft: "4px",
              }}
            ></div>
            <div
              style={{
                borderRadius: "50%",
                backgroundColor: " #2D9878",
                height: "10px",
                width: "10px",
              }}
            ></div>
          </div>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              // mb: "30px",
            }}
          >
            <Box>
              <Typography
                // variant="body1"
                className="generalFontFam"
                style={{
                  opacity: "0.84",
                  color: "white",
                  marginTop: "12px",
                }}
              >
                NEXT
              </Typography>
              <Typography
                className="generalFontFam"
                style={{
                  fontSize: "23px",
                  fontWeight: "900",
                  marginTop: "12px",
                  color: "white",
                }}
                // variant="h1"
              >
                Let’s now add a representative
              </Typography>
              <Typography
                // variant="h6"
                className="generalFontFam varientH6 nonLetterSpacing"
                style={{
                  opacity: "0.7",
                  color: "white",
                  marginTop: "8px",
                }}
              >
                A Partner Representative serves as a liaison between the client
                and the the wealth management firm or entity. They help in
                fostering a strong client - advisor relationship
              </Typography>
            </Box>
          </Box>
          <Box mt={"20px"} height={"48px"}>
            <LoadingButton
              buttonTitle={"Continue"}
              customOnclick={() => {
                handleNext((prev) => prev + 1);
              }}
            />
          </Box>
        </div>
      </Box>
    </div>
  );
}

export default RepresentativeAddComp;
