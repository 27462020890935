import React from "react";
import NewHeadAndDetailsComp from "./newHeadingAndDetailsComp/NewHeadAndDetailsComp";
import TableSummaryDetails from "./newSummaryDetailsTable/TableSummaryDetails";
import BarChartIcon from "@material-ui/icons/BarChart";
import { ReactComponent as BgCicularPieChart } from "../../../../../assets/holding-summary/bgCicularPieChart.svg";
import { Children } from "react";
import { Button, Grid } from "@material-ui/core";
import TabCards from "../../../print/Components/TabCards";

function EngagmentLayout({
  summaryDetailsSecConstData,
  cardData1,

  peiChart,

  colors = [],
}) {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        margin: "2%",
      }}
    >
      <div style={{ height: "100%", width: "55%" }}>
        <NewHeadAndDetailsComp
          title={summaryDetailsSecConstData?.title}
          content={summaryDetailsSecConstData?.content}
        />
        <div className="holdingCard" >
          {/* cards */}
          {cardData1?.map((a, i) => {
            return (
              <div className="hborder">
                <TabCards
                  data={a}
                  text={["Invested Value", "Current Value", "Percentage"]}
                  type={["rs", "rs", "percent"]}
                  color={colors[i]}
                  showcolor={true}
                  getkey={i}
                ></TabCards>
              </div>
            );
          })}
        </div>
      </div>
      <div style={{ width: "45%", position: "relative", marginTop: "-12px", height:'590px' }}>
        <BgCicularPieChart
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "10px",
            left: "7px",
          }}
        />
        {peiChart}
      </div>
    </div>
  );
}

export default EngagmentLayout;
