import { useState, useEffect, useContext } from "react";
import { OnboardingContext } from "../Context";
import { mfoFilterContext } from "../mfo_context/Mfo_Context";
import moment from "moment";
import { productWiseAllocation } from "../api";

export const useProductWiseAllocation = (
  type,
  productSubCategory,
  insuranceType
) => {
  const { userState } = OnboardingContext();
  const [productWiseAllocationData, setProductWiseAllocationData] = useState(
    []
  );
  const [loading, setLoading] = useState(true);
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  let clientId = userState.loginedUserData.id;

  function sortDesc(data) {
    const totalEntry = data.find((item) => item.product === "Total");
    const nonTotalEntries = data.filter((item) => item.product !== "Total");
    nonTotalEntries.sort((a, b) => b.currentValue - a.currentValue);
    const sortedData = [...nonTotalEntries, totalEntry];
    return sortedData;
  }

  // Inside your useProductWiseAllocation hook
  useEffect(() => {
    let params = { ...getfilter_as_params() };
    if (mfoFilter.startDate) {
      params["startDate"] = moment(mfoFilter.startDate).format("YYYY-MM-DD");
      params["endDate"] = moment(mfoFilter.endDate).format("YYYY-MM-DD");
    }
    if (Object.keys(mfoFilter.checkData).length > 0) {
      params["memberId"] = Object.keys(mfoFilter.checkData).join(",");
    }
    if (Object.keys(mfoFilter.advisor).length > 0) {
      params["advisor"] = Object.keys(mfoFilter.advisor).join(",");
    }
    if (type === "dashboard") {
      params["assetClass"] = "equity,debt,cash,alternate";
    }

    setLoading(true);
    productWiseAllocation(clientId, params, type)
      .then((res) => setProductWiseAllocationData(sortDesc(res?.result)))
      .catch((err) => console.log(err, "error"))
      .finally(() => setLoading(false));
  }, [mfoFilter]);
  return {
    productWiseAllocationData,
    loading,
  };
};
