import { Grid, IconButton, useTheme } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { NewMenuAccordion } from "./NewMenuAccordion";
import { NewMenuDashboardContent } from "./NewMenuDashboardContent";
import { OnboardingContext } from "../../Context";
import SearchIcon from "@material-ui/icons/Search";
import ls from "localstorage-slim";
import { routes } from "../../routes";
import { useNewMenuStyles } from "./newMenuStyles";
export const NewMenu = ({ setIsMenuOpen }) => {
  const [menuType, setMenuType] = useState("");
  const [expandedAccordions, setExpandedAccordions] = useState([]);
  const [isMenuItemClicked, setIsMenuItemClicked] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const { userState } = OnboardingContext();
  const userType = userState?.loginedUserData?.user_type;
  const scopes = JSON.parse(ls.get("scopes"));
  const [height, setHeight] = useState(null);

  useEffect(() => {
    const element = document.querySelector("#newMenuLeftSide");
    const leftSideNavHeight = element.offsetHeight;
    if (leftSideNavHeight) {
      setHeight(leftSideNavHeight);
    }
    console.log("leftSideNavHeight", leftSideNavHeight);
  }, []);
  const classes = useNewMenuStyles();
  const theme = useTheme();
  const icons = theme.palette.icons;
  const DashBoardMenuIcon = icons.dashboardMenuIcon;
  const UploadsMenuIcon = icons.uploadsMenuIcon;
  const UserManagementIcon = icons.userManagementMenuIcon;
  // TODO Following icons are pending to add
  const ProductsIcon = icons.userManagementMenuIcon;
  const ClientsIcon = icons.userManagementMenuIcon;
  const ReportIcon = icons.userManagementMenuIcon;
  const PartnersIcon = icons.userManagementMenuIcon;
  const SchemeMasterIcon = icons.userManagementMenuIcon;
  const UploadVerificationIcon = icons.userManagementMenuIcon;
  return (
    <div
      // className="newMenuContainer"
      className={classes.newMenuContainer}
    >
      {/* LEFT SIDE MENU START */}

      <div
        id="newMenuLeftSide"
        className={classes.newMenuLeftSide}
        onMouseOver={() => setIsMenuOpen(true)}
        onMouseOut={() => setIsMenuOpen(false)}
      >
        {/* HEADING AND SUB-HEADING START */}
        <div className={classes.newMenuHeadingWrapper}>
          <h1 className={classes.newMenuHeading}>Menu</h1>
          {/* <p className="newMenuSubHeading">Holdings are the contents</p>
          <p className="newMenuSubHeading">of an investment</p> */}
        </div>
        {/* HEADING AND SUB-HEADING END */}
        <ul
          // className="newMenuList"
          className={classes.newMenuList}
        >
          {/* PRODUCTS */}
          {userType === "client" && (
            <li
              onClick={() => {
                //  Whenever products is clicked
                // search & expanded state should be reset
                setSearchTerm("");
                setExpandedAccordions([]);

                setMenuType("products");
                setIsMenuItemClicked(true);
              }}
              className={
                menuType === "products"
                  ? classes.leftSideMenuItemSelected
                  : classes.newMenuItem
              }
            >
              <div
                // className="newMenuIconWithText"
                className={classes.newMenuIconWithText}
              >
                <span
                  // className="newMenuIcon"
                  className={classes.newMenuIcon}
                >
                  <ProductsIcon />
                </span>
                <span
                  // className="newMenuText"
                  className={classes.newMenuText}
                >
                  Products
                </span>
              </div>
              <div
                // className="newMenuArrowIcon"
                className={classes.newMenuArrowIcon}
              >
                <span
                  // className="newArrow"
                  className={classes.newArrow}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <circle
                      cx="10"
                      cy="10"
                      r="10"
                      fill={theme.palette.rightArrowIcon.background}
                    />
                    <path
                      d="M7.28898 14.8013C7.1137 14.6709 7.00972 14.4876 7.00065 14.2931C6.99157 14.0985 7.07817 13.9092 7.24079 13.7681L10.8955 10.5094L7.28095 7.2507C6.92984 6.95678 6.92984 6.48489 7.28095 6.19097C7.64554 5.92166 8.20343 5.93924 8.54203 6.23071L12.759 9.97952C12.9146 10.1183 13.0006 10.3005 13 10.4895C13.0002 10.6765 12.914 10.8565 12.759 10.9929L8.54203 14.7616C8.37552 14.9092 8.14384 14.9951 7.89944 15C7.67272 15.0002 7.45443 14.9291 7.28898 14.8013Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </div>
            </li>
          )}

          {/* DASHBOARDS */}
          {userType === "client" && (
            <li
              onClick={(e) => {
                setMenuType("dashboards");
                setIsMenuItemClicked(true);
              }}
              className={
                menuType === "dashboards"
                  ? classes.leftSideMenuItemSelected
                  : classes.newMenuItem
              }
            >
              <div className={classes.newMenuIconWithText}>
                <span className={classes.newMenuIcon}>
                  <DashBoardMenuIcon />
                </span>
                <span className={classes.newMenuText}>Dashboards</span>
              </div>
              <div className={classes.newMenuArrowIcon}>
                <span className={classes.newArrow}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <circle
                      cx="10"
                      cy="10"
                      r="10"
                      fill={theme.palette.rightArrowIcon.background}
                    />
                    <path
                      d="M7.28898 14.8013C7.1137 14.6709 7.00972 14.4876 7.00065 14.2931C6.99157 14.0985 7.07817 13.9092 7.24079 13.7681L10.8955 10.5094L7.28095 7.2507C6.92984 6.95678 6.92984 6.48489 7.28095 6.19097C7.64554 5.92166 8.20343 5.93924 8.54203 6.23071L12.759 9.97952C12.9146 10.1183 13.0006 10.3005 13 10.4895C13.0002 10.6765 12.914 10.8565 12.759 10.9929L8.54203 14.7616C8.37552 14.9092 8.14384 14.9951 7.89944 15C7.67272 15.0002 7.45443 14.9291 7.28898 14.8013Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </div>
            </li>
          )}

          {/* USER MANAGEMENT */}
          {scopes?.can_view_user_management && (
            <li
              onClick={() => {
                navigate(routes?.userManagement);
              }}
              className={classes.newMenuItem}
            >
              <div className={classes.newMenuIconWithText}>
                <span className={classes.newMenuIcon}>
                  <UserManagementIcon />
                </span>
                <span className={classes.newMenuText}>User Management</span>
              </div>
              <div className={classes.newMenuArrowIcon}>
                <span className={classes.newArrow}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <circle
                      cx="10"
                      cy="10"
                      r="10"
                      fill={theme.palette.rightArrowIcon.background}
                    />
                    <path
                      d="M7.28898 14.8013C7.1137 14.6709 7.00972 14.4876 7.00065 14.2931C6.99157 14.0985 7.07817 13.9092 7.24079 13.7681L10.8955 10.5094L7.28095 7.2507C6.92984 6.95678 6.92984 6.48489 7.28095 6.19097C7.64554 5.92166 8.20343 5.93924 8.54203 6.23071L12.759 9.97952C12.9146 10.1183 13.0006 10.3005 13 10.4895C13.0002 10.6765 12.914 10.8565 12.759 10.9929L8.54203 14.7616C8.37552 14.9092 8.14384 14.9951 7.89944 15C7.67272 15.0002 7.45443 14.9291 7.28898 14.8013Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </div>
            </li>
          )}

          {/* UPLOADS */}
          {scopes?.can_view_data_upload_know_more && (
            <li
              onClick={() => navigate("/product-upload")}
              className={classes.newMenuItem}
            >
              <div className={classes.newMenuIconWithText}>
                <span className={classes.newMenuIcon}>
                  <UploadsMenuIcon />
                </span>
                <span className={classes.newMenuText}>Uploads</span>
              </div>
              <div className={classes.newMenuArrowIcon}>
                <span className={classes.newArrow}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <circle
                      cx="10"
                      cy="10"
                      r="10"
                      fill={theme.palette.rightArrowIcon.background}
                    />
                    <path
                      d="M7.28898 14.8013C7.1137 14.6709 7.00972 14.4876 7.00065 14.2931C6.99157 14.0985 7.07817 13.9092 7.24079 13.7681L10.8955 10.5094L7.28095 7.2507C6.92984 6.95678 6.92984 6.48489 7.28095 6.19097C7.64554 5.92166 8.20343 5.93924 8.54203 6.23071L12.759 9.97952C12.9146 10.1183 13.0006 10.3005 13 10.4895C13.0002 10.6765 12.914 10.8565 12.759 10.9929L8.54203 14.7616C8.37552 14.9092 8.14384 14.9951 7.89944 15C7.67272 15.0002 7.45443 14.9291 7.28898 14.8013Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </div>
            </li>
          )}

          {scopes?.can_view_uploaded_data_file_list && (
            <li
              onClick={() => navigate("/verification-upload/pending")}
              className={classes.newMenuItem}
            >
              <div className={classes.newMenuIconWithText}>
                <span className={classes.newMenuIcon}>
                  <UploadVerificationIcon />
                </span>
                <span className={classes.newMenuText}>Upload Verification</span>
              </div>
              <div className={classes.newMenuArrowIcon}>
                <span className={classes.newArrow}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <circle
                      cx="10"
                      cy="10"
                      r="10"
                      fill={theme.palette.rightArrowIcon.background}
                    />
                    <path
                      d="M7.28898 14.8013C7.1137 14.6709 7.00972 14.4876 7.00065 14.2931C6.99157 14.0985 7.07817 13.9092 7.24079 13.7681L10.8955 10.5094L7.28095 7.2507C6.92984 6.95678 6.92984 6.48489 7.28095 6.19097C7.64554 5.92166 8.20343 5.93924 8.54203 6.23071L12.759 9.97952C12.9146 10.1183 13.0006 10.3005 13 10.4895C13.0002 10.6765 12.914 10.8565 12.759 10.9929L8.54203 14.7616C8.37552 14.9092 8.14384 14.9951 7.89944 15C7.67272 15.0002 7.45443 14.9291 7.28898 14.8013Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </div>
            </li>
          )}

          {/* CLIENT */}
          {scopes?.can_view_client_list && (
            <li
              onClick={() => navigate("/client")}
              className={classes.newMenuItem}
            >
              <div className={classes.newMenuIconWithText}>
                <span className={classes.newMenuIcon}>
                  <ClientsIcon />
                </span>
                <span className={classes.newMenuText}>Clients</span>
              </div>
              <div className={classes.newMenuArrowIcon}>
                <span className={classes.newArrow}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <circle
                      cx="10"
                      cy="10"
                      r="10"
                      fill={theme.palette.rightArrowIcon.background}
                    />
                    <path
                      d="M7.28898 14.8013C7.1137 14.6709 7.00972 14.4876 7.00065 14.2931C6.99157 14.0985 7.07817 13.9092 7.24079 13.7681L10.8955 10.5094L7.28095 7.2507C6.92984 6.95678 6.92984 6.48489 7.28095 6.19097C7.64554 5.92166 8.20343 5.93924 8.54203 6.23071L12.759 9.97952C12.9146 10.1183 13.0006 10.3005 13 10.4895C13.0002 10.6765 12.914 10.8565 12.759 10.9929L8.54203 14.7616C8.37552 14.9092 8.14384 14.9951 7.89944 15C7.67272 15.0002 7.45443 14.9291 7.28898 14.8013Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </div>
            </li>
          )}
          {/* PARTNER */}
          {scopes?.can_view_partner_list && (
            <li
              onClick={() => navigate("/partner")}
              className={classes.newMenuItem}
            >
              <div className={classes.newMenuIconWithText}>
                <span className={classes.newMenuIcon}>
                  <PartnersIcon />
                </span>
                <span className={classes.newMenuText}>Partner</span>
              </div>
              <div className={classes.newMenuArrowIcon}>
                <span className={classes.newArrow}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <circle
                      cx="10"
                      cy="10"
                      r="10"
                      fill={theme.palette.rightArrowIcon.background}
                    />
                    <path
                      d="M7.28898 14.8013C7.1137 14.6709 7.00972 14.4876 7.00065 14.2931C6.99157 14.0985 7.07817 13.9092 7.24079 13.7681L10.8955 10.5094L7.28095 7.2507C6.92984 6.95678 6.92984 6.48489 7.28095 6.19097C7.64554 5.92166 8.20343 5.93924 8.54203 6.23071L12.759 9.97952C12.9146 10.1183 13.0006 10.3005 13 10.4895C13.0002 10.6765 12.914 10.8565 12.759 10.9929L8.54203 14.7616C8.37552 14.9092 8.14384 14.9951 7.89944 15C7.67272 15.0002 7.45443 14.9291 7.28898 14.8013Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </div>
            </li>
          )}

          {/* Scheme master*/}
          {scopes?.can_view_scheme_master_list && (
            <li
              onClick={() => navigate("/masters")}
              className={classes.newMenuItem}
            >
              <div className={classes.newMenuIconWithText}>
                <span className={classes.newMenuIcon}>
                  <SchemeMasterIcon />
                </span>
                <span className={classes.newMenuText}>Scheme Masters</span>
              </div>
              <div className={classes.newMenuArrowIcon}>
                <span className={classes.newArrow}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <circle
                      cx="10"
                      cy="10"
                      r="10"
                      fill={theme.palette.rightArrowIcon.background}
                    />
                    <path
                      d="M7.28898 14.8013C7.1137 14.6709 7.00972 14.4876 7.00065 14.2931C6.99157 14.0985 7.07817 13.9092 7.24079 13.7681L10.8955 10.5094L7.28095 7.2507C6.92984 6.95678 6.92984 6.48489 7.28095 6.19097C7.64554 5.92166 8.20343 5.93924 8.54203 6.23071L12.759 9.97952C12.9146 10.1183 13.0006 10.3005 13 10.4895C13.0002 10.6765 12.914 10.8565 12.759 10.9929L8.54203 14.7616C8.37552 14.9092 8.14384 14.9951 7.89944 15C7.67272 15.0002 7.45443 14.9291 7.28898 14.8013Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </div>
            </li>
          )}
          {/* Report Center */}
          {/* // TODO Add Permission  */}
          <li
            onClick={() => navigate(`/home/reports?key=reports`)}
            className={classes.newMenuItem}
          >
            <div className={classes.newMenuIconWithText}>
              <span className={classes.newMenuIcon}>
                <ReportIcon />
              </span>
              <span className={classes.newMenuText}>Reports</span>
            </div>
            <div className={classes.newMenuArrowIcon}>
              <span className={classes.newArrow}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <circle
                    cx="10"
                    cy="10"
                    r="10"
                    fill={theme.palette.rightArrowIcon.background}
                  />
                  <path
                    d="M7.28898 14.8013C7.1137 14.6709 7.00972 14.4876 7.00065 14.2931C6.99157 14.0985 7.07817 13.9092 7.24079 13.7681L10.8955 10.5094L7.28095 7.2507C6.92984 6.95678 6.92984 6.48489 7.28095 6.19097C7.64554 5.92166 8.20343 5.93924 8.54203 6.23071L12.759 9.97952C12.9146 10.1183 13.0006 10.3005 13 10.4895C13.0002 10.6765 12.914 10.8565 12.759 10.9929L8.54203 14.7616C8.37552 14.9092 8.14384 14.9951 7.89944 15C7.67272 15.0002 7.45443 14.9291 7.28898 14.8013Z"
                    fill="white"
                  />
                </svg>
              </span>
            </div>
          </li>
          {scopes?.can_view_tax_report && (
            <li
              onClick={() => navigate("/tax")}
              className={classes.newMenuItem}
            >
              <div className={classes.newMenuIconWithText}>
                <span className={classes.newMenuIcon}>
                  <SchemeMasterIcon />
                </span>
                <span className={classes.newMenuText}>Tax Report</span>
              </div>
              <div className={classes.newMenuArrowIcon}>
                <span className={classes.newArrow}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <circle
                      cx="10"
                      cy="10"
                      r="10"
                      fill={theme.palette.rightArrowIcon.background}
                    />
                    <path
                      d="M7.28898 14.8013C7.1137 14.6709 7.00972 14.4876 7.00065 14.2931C6.99157 14.0985 7.07817 13.9092 7.24079 13.7681L10.8955 10.5094L7.28095 7.2507C6.92984 6.95678 6.92984 6.48489 7.28095 6.19097C7.64554 5.92166 8.20343 5.93924 8.54203 6.23071L12.759 9.97952C12.9146 10.1183 13.0006 10.3005 13 10.4895C13.0002 10.6765 12.914 10.8565 12.759 10.9929L8.54203 14.7616C8.37552 14.9092 8.14384 14.9951 7.89944 15C7.67272 15.0002 7.45443 14.9291 7.28898 14.8013Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </div>
            </li>
          )}
        </ul>
      </div>

      {/* LEFT SIDE MENU END */}

      {/* RIGHT SIDE MENU START */}
      {isMenuItemClicked && menuType === "products" ? (
        <div
          // className="newMenuRightSide"
          className={classes.newMenuRightSide}
          style={{ height: height }}
          onMouseOver={() => setIsMenuOpen(true)}
          onMouseOut={() => setIsMenuOpen(false)}
        >
          <div className={classes.searchWrapper}>
            <input
              type="text"
              placeholder="Search Product"
              className={classes.searchWrapperInput}
              onChange={(e) => {
                // ** Reset the expanded state else the assetClass of the expanded state gets alloted to the searched one
                setExpandedAccordions([]);
                setSearchTerm(e.target.value);
              }}
              value={searchTerm}
            />
            <div>
              <IconButton
                style={{
                  // backgroundColor: "#5626C5",
                  background: theme.palette.searchIcon.background,
                  height: "100%",
                  borderRadius: "6px",
                  width: "3rem",
                }}
              >
                <SearchIcon
                  style={{
                    color: "white !important",
                  }}
                />
              </IconButton>
            </div>
          </div>
          <p
            style={{
              color: "#FFF",
              fontFamily: "Avenir",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "12.658px",
              letterSpacing: "2.4px",
              textTransform: "uppercase",
              display: "inline-block",
              marginLeft: "3.5rem",
              marginBottom: "0",
              marginTop: "1.75rem",
            }}
          >
            Product Name
          </p>
          <NewMenuAccordion
            searchTerm={searchTerm}
            expandedAccordions={expandedAccordions}
            setExpandedAccordions={setExpandedAccordions}
          />
        </div>
      ) : (
        <></>
      )}

      {isMenuItemClicked && menuType === "dashboards" ? (
        <div
          // className="newMenuRightSide"
          className={classes.newMenuRightSide}
          onMouseOver={() => setIsMenuOpen(true)}
          onMouseOut={() => setIsMenuOpen(false)}
        >
          <NewMenuDashboardContent />
        </div>
      ) : (
        <></>
      )}
      {/* RIGHT SIDE MENU END */}
    </div>
  );
};
