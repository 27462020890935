import React from "react";
import DefaultSign from "./DefaultSign";
import PartnerOnboardingForm2 from "./PartnerOnboardingForm2";

export default function PartnerOnboarding2(props) {
  const { head,customLeftMargin } = props;

  const desc = head?.desc
    ? head?.desc
    : {
        center: [
          {
            title: "Why MFO?",
            description:
              "Holdings are the contents of an investment portfolio held by an individual or an entity, such as a mutual fund or a pension fund. Portfolio holdings may encompass a wide ",
          },
        ],
      };

  return (
    <>
      <DefaultSign
        left={9}
        right={3}
        customLeftMargin={customLeftMargin ? customLeftMargin : "38px"}
        desc={desc}
      >
        <PartnerOnboardingForm2 {...props} />
      </DefaultSign>
    </>
  );
}
