import { cloneElement } from "react";
import { PERMISSIONS } from "./permission-maps";
import { useGetRole } from "./useGetRole";
import ls from "localstorage-slim";
const hasPermission = ({ userPermissionsArr, scopes }) => {
  const scopesMap = {};
  scopes.forEach((scope) => {
    scopesMap[scope] = true;
  });

  return userPermissionsArr?.some((permission) => scopesMap[permission]);
};

export function PermissionsGate({ children, scopes = [] }) {
  // const { roleType, userPermissionsArr } = useGetRole();
  const userPermissionsArr = JSON.parse(ls.get("userPermissionsArr"));
  // const permissions = PERMISSIONS[roleType];

  const permissionGranted = hasPermission({ userPermissionsArr, scopes });

  if (!permissionGranted) return null;

  return <>{children}</>;
}
