import {
  Box,
  Typography,
  makeStyles,
  Grid,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";
import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { HorizontalDivider } from "../../user_management/HorizontalDivider";
import panCard from "../../../assets/images/panCardPic.png";
import aadharCard from "../../../assets/images/aadharCardPic.png";
import PartnerHeading from "../../NewSignUp/PartnerHeading";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getClientMemberDetail, getPartnerDetail } from "../../../api";
import { useState } from "react";
import { usePartnerStatus } from "../../../hooks/usePartnerStatus";
import { updateUserStatus } from "../../../api";
import ConfirmationComp from "../../NewSignUp/clientSignUp/ConfirmationComp";
import { BackdropComponent } from "../../NewSignUp/common/BackDropComponent/BackDropComponent";
import { AlertDialog } from "./AlertDialog";
import { RepresentativeDetails } from "./RepresentativeDetails";
import { ExtraDetails } from "./ExtraDetails";
import { HeadOfFamilyDetails } from "./HeadOfFamilyDetails";
export const ClientDetailsAccordion = () => {
  const useStyles = makeStyles({
    mainContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "16px",
    },
    iconTextWrapper: {
      display: "flex",
      alignItems: "center",
      gap: "20px",
    },
    mainTextHeading: {
      color: "#FFF",
      fontFamily: "Avenir",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "12.658px",
      letterSpacing: "2.4px",
      textTransform: "uppercase",
      opacity: 0.7,
      marginBottom: "8px",
    },

    mainText: {
      color: "#FFF",
      fontFamily: "Avenir",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
    },
    mainTextClientName: { marginBottom: "8px" },
    accordionSummary: {
      flexDirection: "row-reverse",
      gap: "20px",
    },
    accordionSummaryHeading: {
      color: "#FFF",
      fontFamily: "Avenir",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
    },
    accordion: {
      background: "#21163E",
      borderRadius: "8px",
    },
    expandMoreIcon: {
      borderRadius: "50%",
      background: "#55596D",
    },
    accordionDetails: {
      //   height: "381px",
      background: "#21163E",
      paddingLeft: "32px",
      borderRadius: "8px",
      // marginTop: "32px",
    },
    accordionDetailsMainText: {
      fontSize: "16px",
      color: "#FFF",
    },
    secondGridRow: {
      marginTop: "48px",
    },
    row1Accordion: {
      width: "20%",
      alignSelf: "flex-start",
      marginTop: "2rem",
    },
    row2Accordion: {
      width: "20%",
      alignSelf: "flex-start",
      marginTop: "2.5rem",
    },
  });
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [errorMsg, setErrorMsg] = useState("");

  const location = useLocation();
  const state = location?.state;
  const navigate = useNavigate();
  let pathNameArr = location?.pathname?.split("/");
  let hofId = pathNameArr[pathNameArr?.length - 1];
  const { partnerData } = usePartnerStatus(hofId, getClientMemberDetail);

  const IconWrapper = () => {
    return (
      <Box className="iconWrapper">
        <Box
          style={{
            width: "50px",
            height: "50px",
            background: "white",
            borderRadius: "50%",
          }}
        ></Box>
      </Box>
    );
  };
  const TextBlock = ({ text1, text2, classNameText1, classNameText2 }) => {
    const combinedClassNamesText1 = classNameText1?.join(" ");
    const combinedClassNamesText2 = classNameText2?.join(" ");

    return (
      <Box className="textWrapper">
        <Typography className={combinedClassNamesText1}>{text1}</Typography>
        <Typography className={combinedClassNamesText2}>{text2}</Typography>
      </Box>
    );
  };

  const handleBack = (user) => {
    navigate(`/${user}`);
  };
  console.log("sattdsa", state, partnerData);
  const handleApprove = async () => {
    let entityType = "member";
    let id = hofId;
    try {
      const response = await updateUserStatus(entityType, id, "approved");
      console.log("Approval successful", response);
      if (window.location.href.includes("/client")) {
        navigate("/client/mapping", {
          state: { data: state, userType: "client" },
        });
      }
    } catch (error) {
      setErrorMsg(error?.response?.data?.error?.message);
      console.error("Error approving", error?.response?.data?.error?.message);
    }
  };
  const handleReject = async () => {
    let entityType = "member";
    let id = hofId;
    try {
      const response = await updateUserStatus(entityType, id, "rejected");
      console.log("Rejection successful", response);
      navigate("/client");
    } catch (error) {
      console.error("Error rejecting", error);
    }
  };
  const [open, setOpen] = useState(false);

  return (
    <div style={{ position: "relative" }}>
      <AlertDialog
        open={open}
        setOpen={setOpen}
        onApprove={handleApprove}
        data={state}
        dialogTitle={() => (
          <DialogTitle id="alert-dialog-title">
            <Typography
              style={{
                color: "#fff",
                fontFamily: "Avenir",
                fontSize: "39.6px",
                fontStyle: "normal",
                fontWeight: 800,
                lineHeight: "35.2px",
                textAlign: "center",
              }}
            >
              Confirmation
            </Typography>
          </DialogTitle>
        )}
        dialogContent={() => (
          <DialogContentText
            id="alert-dialog-description"
            style={{
              color: "#fff",
              textAlign: "center",
              fontFamily: "Avenir",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "22px",
            }}
          >
            Are you sure you want to approve this Client. Approving will send an
            email to the Client
          </DialogContentText>
        )}
      />
      <Box style={{ margin: "2%", minHeight: "100vh" }}>
        <div style={{ marginLeft: "65px", marginBottom: "42px" }}>
          <PartnerHeading
            handleBack={() => {
              handleBack(state?.userType);
            }}
            mainHead={`${state?.userType} Details`}
            noStep={true}
          />
        </div>
        <Grid
          container
          style={{
            background: "#21163E",
            borderRadius: "8px",
            marginBottom: "10px",
          }}
        >
          <Grid item xs={6}>
            <Box className={classes.mainContainer}>
              <Box className={classes.iconTextWrapper}>
                <IconWrapper />
                <TextBlock
                  text1="Client Name"
                  text2={partnerData?.client_name}
                  classNameText1={[classes.mainTextHeading]}
                  classNameText2={[classes.mainText]}
                />
              </Box>
              <TextBlock
                text1="Email ID"
                text2={!partnerData?.email ? "-" : partnerData?.email}
                classNameText1={[classes.mainTextHeading]}
                classNameText2={[classes.mainText]}
              />
              <TextBlock
                text1="Mobile No"
                text2={!partnerData?.mobile ? "-" : partnerData?.mobile}
                classNameText1={[classes.mainTextHeading]}
                classNameText2={[classes.mainText]}
              />
            </Box>
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
        {/* Accordion 1*/}
        <Accordion
          className={classes.accordion}
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            className={classes.accordionSummary}
            expandIcon={<ExpandMoreIcon className={classes.expandMoreIcon} />}
            id="panel1a-header"
          >
            <Typography className={classes.accordionSummaryHeading}>
              Head of family Details
            </Typography>
          </AccordionSummary>
          <HorizontalDivider style={{ padding: "0 20px" }} />
          <AccordionDetails className={classes.accordionDetails}>
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <HeadOfFamilyDetails partnerData={partnerData} />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>

      {/* For Reject Tab Do not show the buttons */}
      {partnerData?.status === "pending" && (
        <div className="accordionBottomContainer">
          <p className="newErrorTxt">{errorMsg}</p>
          <div className="detailsAccordionBtnContainer">
            <button
              className="rejectBtnDetailsAccordion"
              onClick={handleReject}
            >
              Reject
            </button>
            <button
              className="approveBtnDetailsAccordion"
              onClick={() => setOpen(true)}
            >
              Approve
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
