let stData = {
  success: true,
  event: "EQUITY_IPO_TRANSACTION_SUMMARY",
  message: "Equity IPO transaction summary fetched successfully.",
  uuid: "971382e0-c948-11ed-aed4-15a7739265dc",
  data: {
    meta: {
      filters: {
        clientId: "1",
        assetClass: "equity",
      },
    },
    result: [
      {
        client_id: 1,
        member_id: 2,
        pan: "PKHKI1234N",
        advisor: "Neo",
        isin: "INF194KA12S5",
        type: "IPO",
        company_name: "GHCL Limited",
        stock_symbol: "GICHSGFIN",
        application_no: "4571",
        application_date: "2022-03-12",
        application_amount: 115000,
        lot_size: 10,
        lots_applied: 10,
        shares_applied: 105,
        bid_amount_per_share: 1095.2381,
        allotment_date: "2022-04-02",
        allotted_shares: 35,
        allotted_price: 100,
        allotted_amount: 3500,
        refund_date: "2022-04-10",
        refund_amount: 111500,
      },
      {
        client_id: 1,
        member_id: 2,
        pan: "PKHKI1234N",
        advisor: "Edelweiss",
        isin: "INF194KA12S4",
        type: "IPO",
        company_name: "Geojit Financial Services Limited",
        stock_symbol: "GEOJITFSL",
        application_no: "4570",
        application_date: "2022-03-12",
        application_amount: 110000,
        lot_size: 10,
        lots_applied: 10,
        shares_applied: 110,
        bid_amount_per_share: 1000,
        allotment_date: "2022-04-02",
        allotted_shares: 40,
        allotted_price: 100,
        allotted_amount: 4000,
        refund_date: "2022-04-10",
        refund_amount: 106000,
      },
      {
        client_id: 1,
        member_id: 2,
        pan: "PKHKI1234N",
        advisor: "Edelweiss",
        isin: "INF194KA12S0",
        type: "IPO",
        company_name: "Aditya Birla Capital Limited",
        stock_symbol: "ABCAPITAL",
        application_no: "1234",
        application_date: "2022-03-12",
        application_amount: 100000,
        lot_size: 10,
        lots_applied: 10,
        shares_applied: 100,
        bid_amount_per_share: 1000,
        allotment_date: "2022-04-02",
        allotted_shares: 30,
        allotted_price: 100,
        allotted_amount: 3000,
        refund_date: "2022-04-10",
        refund_amount: 97000,
      },
      {
        client_id: 1,
        member_id: 2,
        pan: "PKHKI1234N",
        advisor: "Neo",
        isin: "INF194KA12S1",
        type: "FPO",
        company_name: "Action Construction Equipment Limited",
        stock_symbol: "ACE",
        application_no: "4312",
        application_date: "2022-03-12",
        application_amount: 100000,
        lot_size: 10,
        lots_applied: 10,
        shares_applied: 100,
        bid_amount_per_share: 1000,
        allotment_date: "2022-04-02",
        allotted_shares: 30,
        allotted_price: 100,
        allotted_amount: 3000,
        refund_date: "2022-04-10",
        refund_amount: 97000,
      },
      {
        client_id: 1,
        member_id: 2,
        pan: "PKHKI1234N",
        advisor: "Neo",
        isin: "INF194KA12S3",
        type: "IPO",
        company_name: "Aban Offshore Limited",
        stock_symbol: "ABAN",
        application_no: "4569",
        application_date: "2022-03-12",
        application_amount: 120000,
        lot_size: 10,
        lots_applied: 10,
        shares_applied: 100,
        bid_amount_per_share: 1200,
        allotment_date: "2022-04-02",
        allotted_shares: 30,
        allotted_price: 100,
        allotted_amount: 3000,
        refund_date: "2022-04-10",
        refund_amount: 117000,
      },
      {
        client_id: 1,
        member_id: 2,
        pan: "PKHKI1234N",
        advisor: "Neo",
        isin: "INF194KA12S3",
        type: "IPO",
        company_name: "Aban Offshore Limited",
        stock_symbol: "ABAN",
        application_no: "4569",
        application_date: "2022-03-12",
        application_amount: 120000,
        lot_size: 10,
        lots_applied: 10,
        shares_applied: 100,
        bid_amount_per_share: 1200,
        allotment_date: "2022-04-02",
        allotted_shares: 30,
        allotted_price: 100,
        allotted_amount: 3000,
        refund_date: "2022-04-10",
        refund_amount: 117000,
      },
      {
        client_id: 1,
        member_id: 2,
        pan: "PKHKI1234N",
        advisor: "Edelweiss",
        isin: "INF194KA12S2",
        type: "IPO",
        company_name: "Aavas Financiers Limited",
        stock_symbol: "AAVAS",
        application_no: "4568",
        application_date: "2022-03-12",
        application_amount: 100000,
        lot_size: 10,
        lots_applied: 10,
        shares_applied: 100,
        bid_amount_per_share: 1000,
        allotment_date: "2022-04-02",
        allotted_shares: 30,
        allotted_price: 100,
        allotted_amount: 3000,
        refund_date: "2022-04-10",
        refund_amount: 97000,
      },
      {
        client_id: 1,
        member_id: 2,
        pan: "PKHKI1234N",
        advisor: "Edelweiss",
        isin: "INF194KA12S2",
        type: "IPO",
        company_name: "Aavas Financiers Limited",
        stock_symbol: "AAVAS",
        application_no: "4568",
        application_date: "2022-03-12",
        application_amount: 100000,
        lot_size: 10,
        lots_applied: 10,
        shares_applied: 100,
        bid_amount_per_share: 1000,
        allotment_date: "2022-04-02",
        allotted_shares: 30,
        allotted_price: 100,
        allotted_amount: 3000,
        refund_date: "2022-04-10",
        refund_amount: 97000,
      },
    ],
  },
};

module.exports = {
  stData,
};
