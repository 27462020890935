import { Card, CardContent, Grid, Typography, Button } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import CardSummary from "../common/CardSummary";
import PageFilter from "../common/PageFilter";
import useStyles from "../common/ProductsStyles";

import {
  divergingCommonProps,
  pie_onClick2,
} from "../../../charts/nivo_objects";
import TabHeader from "../common/TabHeader";
import PageLoader from "../common/pageLoader";
import {
  fetchXirr,
  fetchXirrCurrentHolding,
  fetchTwrrCurrentHolding,
  fetchTwrr,
  invested_portfolio,
  getAssetWiseData,
} from "../../../../api";
import { OnboardingContext } from "../../../../Context";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import moment from "moment";

import {
  dateFormatCommon,
  roundNumber,
  roundNumberWithoutDecimal,
} from "../../../utils/common";
import { loadingText } from "../../../utils/constands";
import SummaryPageTop from "../common/SummaryPageTop";
import TileComp from "../common/TileComp";
import Default from "../../defaultPages/Default";
import PortfolioChart from "../../common/portfolioChart";

import { useHoldingCardActive } from "../../../../hooks/useHoldingCardActive";
import { NewHoldingSummaryCards } from "../../../common/HoldingSummaryCards/NewHoldingSummaryCards";
import {
  holdingSummaryCardsActiveData,
  holdingSummaryCardsInactiveData,
} from "../common/util/common";
import { NewHoldingSummaryCardsActive } from "../../../common/HoldingSummaryCards/NewHoldingSummaryCardsActive";
import { useReturns } from "../../../../hooks/useReturns";
import { useNormalReturns } from "../../../../hooks/useNormalReturns";
import BarChartIcon from "@material-ui/icons/BarChart";
import { routes } from "../../../../routes";
import { useTotalGainLoss } from "../../../../hooks/useTotalGainLoss";
import { NewFilter } from "../../newFilter/NewFilter";
import { GainsCardInfo } from "../../../common/HoldingSummaryCards/GainsCardInfo";
import { ReactComponent as InfoIcon } from "../../../../assets/icons/TotalGainLossInfoIcon.svg";
import { useCardsApi } from "../../../../hooks/useCardsApi";
import { useCurrentHoldingCardsApi } from "../../../../hooks/useCurrentHoldingCardsApi";
import { TotalGainLossCardInfo } from "../../../common/HoldingSummaryCards/TotalGainLossCardInfo";
function REITSSummaryComp() {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [infoIconHovered, setInfoIconHovered] = useState(false);
  // const [holdingCardData] = useHoldingCardActive("reits", checked);
  // const [totalGainLossData] = useTotalGainLoss("reits");
  // const { returns: twrr } = useNormalReturns(
  //   fetchTwrr,
  //   "normal-twrr",
  //   checked,
  //   "reits"
  // );
  // const { returns: xirr } = useNormalReturns(
  //   fetchXirr,
  //   "normal-xirr",
  //   checked,
  //   "reits"
  // );
  const {
    holdingCardData,
    totalGainLossData,
    xirr,
    twrr,
    loading: cardLoader,
    twrrLoader,
    twrrError,
    retryFetchTwrr,
  } = useCardsApi("reits");
  // const { returns: xirrCurrentHolding } = useReturns(
  //   fetchXirrCurrentHolding,
  //   "xirr",
  //   checked,
  //   "reits"
  // );
  // const { returns: twrrCurrentHolding, loading: twrrCurrentHoldingLoading } =
  //   useReturns(fetchTwrrCurrentHolding, "twrr", checked, "reits");
  const {
    // holdingCardData,
    xirrCurrentHolding,
    twrrCurrentHolding,
    loading: currentHoldingCardLoader,
    twrrLoaderCurrentHolding,
    twrrErrorCurrentHolding,
    retryFetchTwrrCurrentHolding,
  } = useCurrentHoldingCardsApi(checked, "reits");

  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  let selectedAssetClass = getfilter_as_params().assetClass;

  const { userState } = OnboardingContext();
  // const [twrr, setTwrr] = useState({});
  // const [twrrCurrentHolding, setTwrrCurrentHolding] = useState({});
  // const [xirr, setXirr] = useState({});
  // const [xirrCurrentHolding, setXirrCurrentHolding] = useState({});
  const [investmentPortfolio, setInvestmentPortfolio] = useState([]);
  const [loadque, setloadque] = useState(null);
  const [loading, setLoading] = useState(true);
  const [assetData, setAssetData] = useState([]);
  const [assets, setAssets] = useState({});
  const [flip, setFlip] = useState(false);
  const [newCardData, setNewCardDaat] = useState([]);

  const [asset_color, setAsset_Color] = useState([
    "#3A56FF",
    "#B80000",
    "#82EBA1",
    "#711CF1",
  ]);
  const [pie, setPie] = useState({
    title: "",
    value: "",
    colors: asset_color,
    disabled: [],
    fill: [
      {
        match: {
          id: "debt",
        },
        id: "c6",
      },
      {
        match: {
          id: "equity",
        },
        id: "c3",
      },
      {
        match: {
          id: "alternate",
        },
        id: "c2",
      },
      {
        match: {
          id: "cash",
        },
        id: "c4",
      },
    ],
  });

  const color = {
    equity: "#3A56FF",
    debt: "#82EBA1",
    alternate: "#B80000",
    cash: "#711CF1",
  };

  // let currencyFormat = (value) => {
  //   return currencyFormatLocal(value, {
  //     currencyStyle: { style: "currency", currency: "INR" },
  //   });
  // };

  const diverging = {
    ...divergingCommonProps,
  };
  diverging.axisBottom.legendOffset = 65;
  diverging.margin = { top: 5, right: 20, bottom: 70, left: 80 };
  useEffect(() => {
    const clientId = userState.loginedUserData.id;
    // const clientId = 199;

    let params = { ...getfilter_as_params() };

    setLoading(true);
    Promise.allSettled([invested_portfolio(clientId, params, "reits")]).then(
      ([
        // xirr_res,
        // xirrCurrentHolding_res,
        // twrr_res,
        // twrrCurrentHolding_res,
        invest_res,
        // asset_ress,
      ]) => {
        if (invest_res.status == "fulfilled") {
          let arr = [];
          setInvestmentPortfolio(() => invest_res.value.result);
        }
        setLoading(false);
      }
    );
  }, [mfoFilter]);

  const navigate = useNavigate();
  const handleSummaryClick = () => {
    navigate(routes?.reitsHolding);
  };

  return (
    <>
      <SummaryPageTop
        backLink={routes?.homeHoldingPage}
        pageTitle={"Real Estate Investment Trust Portfolio Summary"}
        flip={flip}
        setFlip={setFlip}
        checked={checked}
        setChecked={setChecked}
        setFilterModalOpen={setFilterModalOpen}
      />
      <NewFilter
        filterModalOpen={filterModalOpen}
        setFilterModalOpen={setFilterModalOpen}
        productType="reits"
      />
      <div style={{ margin: "2%" }}>
        {!checked ? (
          <NewHoldingSummaryCards
            retryFetchTwrr={retryFetchTwrr}
            twrrLoader={twrrLoader}
            twrrError={twrrError}
            // infoIconHovered={infoIconHovered}
            infoIcon={
              infoIconHovered && (
                <TotalGainLossCardInfo
                  textToRender={
                    <p className="totalIncomeDescription">
                      This includes dividends, fixed deposit interest, income
                      distribution, interest earnings, interim dividends, other
                      relevant income distributed by the trusts. This
                      comprehensive aggregation offers a comprehensive picture
                      of the income that is distributed by these trusts.
                    </p>
                  }
                />
              )
            }
            holdingSummaryCardsInactive={holdingSummaryCardsInactiveData(
              investmentPortfolio,
              holdingCardData,
              xirr,
              twrr,
              totalGainLossData
            )}
            totalGainLossInactive={
              <>
                <GainsCardInfo
                  text="Realized Gain/Loss"
                  value={
                    holdingSummaryCardsInactiveData(
                      investmentPortfolio,
                      holdingCardData,
                      xirr,
                      twrr,
                      totalGainLossData
                    )?.realisedGainLoss
                  }
                  align="left"
                />
                <GainsCardInfo
                  text="Unrealized Gain/Loss"
                  value={
                    holdingSummaryCardsInactiveData(
                      investmentPortfolio,
                      holdingCardData,
                      xirr,
                      twrr,
                      totalGainLossData
                    )?.unRealisedGainLoss
                  }
                  align="center"
                />
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <GainsCardInfo
                    text="Other Income"
                    value={
                      holdingSummaryCardsInactiveData(
                        investmentPortfolio,
                        holdingCardData,
                        xirr,
                        twrr,
                        totalGainLossData
                      )?.interestIncome
                    }
                    align="right"
                    customStyle={{ marginRight: "2rem" }}
                  />
                  <InfoIcon
                    onMouseOver={() => setInfoIconHovered(true)}
                    onMouseOut={() => setInfoIconHovered(false)}
                    style={{
                      position: "absolute",
                      top: "4px",
                      right: "4px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </>
            }
          ></NewHoldingSummaryCards>
        ) : (
          <NewHoldingSummaryCardsActive
            retryFetchTwrrCurrentHolding={retryFetchTwrrCurrentHolding}
            twrrLoaderCurrentHolding={twrrLoaderCurrentHolding}
            twrrErrorCurrentHolding={twrrErrorCurrentHolding}
            infoIconHovered={infoIconHovered}
            holdingSummaryCardsActive={holdingSummaryCardsActiveData(
              holdingCardData,
              xirrCurrentHolding,
              twrrCurrentHolding
            )}
            totalGainLossActive={
              <>
                <GainsCardInfo
                  text="Unrealized Gain/Loss"
                  value={
                    holdingSummaryCardsActiveData(
                      holdingCardData,
                      xirrCurrentHolding,
                      twrrCurrentHolding
                    )?.unRealisedGainLoss
                  }
                  align="left"
                />
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <GainsCardInfo
                    text="Other Income"
                    value={
                      holdingSummaryCardsActiveData(
                        holdingCardData,
                        xirrCurrentHolding,
                        twrrCurrentHolding
                      )?.interestIncome
                    }
                    align="right"
                    customStyle={{ marginRight: "2rem" }}
                  />
                  <InfoIcon
                    onMouseEnter={() => setInfoIconHovered((prev) => !prev)}
                    onMouseLeave={() => setInfoIconHovered((prev) => !prev)}
                    style={{
                      position: "absolute",
                      top: "4px",
                      right: "4px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </>
            }
          ></NewHoldingSummaryCardsActive>
        )}
      </div>
      <PortfolioChart
        type={"reits"}
        loading={loading}
        noPaddingBottom={true}
        viewDetailBtn={
          <Button
            onClick={handleSummaryClick}
            className={classes.viewDetailBtn + " portfolioBtn "}
            style={{
              width: "20%",
              margin: "0% 40% 0",
              color: "#FFFFFF",
              borderRadius: "7px",
              backgroundColor: "#4f1fda !important",
            }}
            startIcon={<BarChartIcon />}
          >
            View Details
          </Button>
        }
      ></PortfolioChart>
      <PageLoader showLoader={loading ? true : false} />
      <PageLoader
        showLoader={currentHoldingCardLoader && checked ? true : false}
      />
    </>
  );
}

export default REITSSummaryComp;
