import React from "react";
import DefaultSign from "./DefaultSign";
import "./NewSignUp.css";
import SetPinForm from "./SetPinForm";

const SetPin = (props) => {
  const desc = {
    center: [
      {
        title: "Set Your Pin",
        description:
          "By Setting your PIN you ensure that only you can access your account, safe guarding your personal information and transactions from unauthorized access.",
      },
    ],
  };
  return (
    <DefaultSign left={7} right={5} desc={desc}>
      <SetPinForm {...props} />
    </DefaultSign>
  );
};
export default SetPin;
