import { currencyFormat } from "../../../../../hooks/constantFunction";
import { loadingText } from "../../../../utils/constands";
import { roundNumberWithoutDecimal } from "../../../../utils/common";
export const handleState = (setState, state) => {
  setState(state);
};

const formatValue = (value) =>
  value !== null ? currencyFormat(value) : loadingText;

const formatPercentage = (value) =>
  value !== null
    ? roundNumberWithoutDecimal(Number(value)) + " %"
    : loadingText;

/**
 * Generates data for holding summary cards in inactive state.
 *
 * @param {object} portfolioValue - The portfolio value data (realisedData for holding, mfo, bonds, equity, RE, Ins; investmentPortfolio for mld, reits, invits, PM, structured, pms, aif).
 * @param {object} holdingCardData - The holding card data.
 * @param {object} xirr - The XIRR data.
 * @param {object} twrr - The TWRR data.
 * @returns {object} - The generated data for holding summary cards in inactive state.
 */
export function holdingSummaryCardsInactiveData(
  portfolioValue,
  holdingCardData,
  xirr,
  twrr,
  totalGainLossData
) {
  return {
    asOnDate: portfolioValue?.asOnDate || loadingText,
    portfolioValueAmount: formatValue(holdingCardData?.portfolioValueAmount),
    overallInvestedAmount: formatValue(portfolioValue?.overAllInvestedAmount),
    investedAmount: formatValue(holdingCardData?.investedAmount),
    redeemedInvestedAmount: formatValue(
      holdingCardData?.redeemedInvestedAmount
    ),
    // ** added key for 2505 ticket
    interestIncome: formatValue(portfolioValue?.interestIncome),
    // totalGainLoss: formatValue(portfolioValue?.totalGainLoss),
    totalGainLoss: formatValue(totalGainLossData?.totalGainLoss),
    realisedGainLoss: formatValue(portfolioValue?.realisedGainLoss),
    unRealisedGainLoss: formatValue(holdingCardData?.unRealisedGainLoss),
    xirrValue: formatPercentage(xirr?.xirr),
    absoluteTwrrData: formatPercentage(twrr?.absoluteTwrr),
    annualisedTwrrData: formatPercentage(twrr?.annualisedTwrr),
  };
}
export function holdingSummaryCardsActiveData(
  holdingCardData,
  xirrCurrentHolding,
  twrrCurrentHolding
) {
  return {
    asOnDate: holdingCardData?.asOnDate || loadingText,
    portfolioValueAmount: formatValue(holdingCardData?.portfolioValueAmount),
    investedAmount: formatValue(holdingCardData?.investedAmount),
    totalGainLoss: formatValue(holdingCardData?.totalGainLoss),
    // realisedGainLoss: formatValue(holdingCardData?.realisedGainLoss),
    realisedGainLoss: formatValue(0),
    // ** added key for 2505 ticket
    interestIncome: formatValue(holdingCardData?.interestIncome),
    unRealisedGainLoss: formatValue(holdingCardData?.unRealisedGainLoss),
    xirrValue: formatPercentage(xirrCurrentHolding?.xirr),
    absoluteTwrrData: formatPercentage(twrrCurrentHolding?.absoluteTwrr),
    annualisedTwrrData: formatPercentage(twrrCurrentHolding?.annualisedTwrr),
  };
}

export const assectClassConfigSelection = ({ assetClassList, data }) => {
  let newList = [];
  if (assetClassList) {
    newList = data.filter((a) => {
      if (assetClassList.indexOf(a.page) >= 0) {
        return a;
      }
    });
  } else {
    newList = data;
  }
  return [{ page: assetClassList, title: "All" }, ...newList];
};
