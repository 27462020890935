import { useState, useEffect, useContext } from "react";
import { OnboardingContext } from "../Context";
import { mfoFilterContext } from "../mfo_context/Mfo_Context";
import moment from "moment";
import {
  getCurrentHoldingGainLoss,
  fetchTotalGainLoss,
  fetchXirr,
  fetchTwrr,
} from "../api";

export const useCardsApi = (
  type,
  productSubCategory,
  insuranceType,
  totalRowParams
) => {
  const { userState } = OnboardingContext();
  const [totalGainLossData, setTotalGainLossData] = useState();
  const [holdingCardData, setHoldingCardData] = useState();
  const [xirr, setXirr] = useState();
  const [twrr, setTwrr] = useState();
  const [loading, setLoading] = useState(true);
  // ** Loader for twrr
  const [twrrLoader, setTwrrLoader] = useState(true);
  // ** error for twrr
  const [twrrError, setTwrrError] = useState(false);
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  let clientId = userState.loginedUserData.id;
  // let clientId = 199;
  // In case of Insurance need to pass productSubCategory and insuranceType
  function getParams(type) {
    if (type === "ins") {
      return { insuranceType, productSubCategory };
    } else {
      return { ...getfilter_as_params() };
    }
  }
  //   Retry Call Back Function
  // Inside your useCardsApi hook
  const retryFetchTwrr = () => {
    let params = getParams(type);
    setTwrrLoader(true);
    setTwrrError(false);

    fetchTwrr(clientId, params, type)
      .then((res) => {
        setTwrr(res?.result);
        setTwrrLoader(false);
      })
      .catch((err) => {
        setTwrrError(true);
        setTwrrLoader(false);
      });
  };
  useEffect(() => {
    // Since Bank Book does not have these API's
    if (type === "bank") {
      return;
    }
    // let params = { ...getfilter_as_params() };
    let params = totalRowParams
      ? { assetClass: totalRowParams }
      : getParams(type);
    if (mfoFilter.startDate) {
      params["startDate"] = moment(mfoFilter.startDate).format("YYYY-MM-DD");
      params["endDate"] = moment(mfoFilter.endDate).format("YYYY-MM-DD");
    }
    params["promoterStake"] = mfoFilter.promoterStake;
    if (Object.keys(mfoFilter.checkData).length > 0) {
      params["memberId"] = Object.keys(mfoFilter.checkData).join(",");
    }
    if (Object.keys(mfoFilter.advisor).length > 0) {
      params["advisor"] = Object.keys(mfoFilter.advisor).join(",");
    }
    if (type === "dashboard") {
      params["assetClass"] = "equity,debt,cash,alternate";
    }

    setLoading(true);
    Promise.allSettled([
      getCurrentHoldingGainLoss(clientId, params, type),
      fetchTotalGainLoss(clientId, params, type),
      fetchXirr(clientId, params, type),
      //   fetchTwrr(clientId, params, "dashboard"),
    ]).then(([current_holidng_res, total_gain_loss_res, xirr_res]) => {
      if (current_holidng_res.status == "fulfilled") {
        setHoldingCardData(current_holidng_res?.value?.result);
      }
      if (total_gain_loss_res.status == "fulfilled") {
        setTotalGainLossData(total_gain_loss_res?.value?.result);
      }
      if (xirr_res.status == "fulfilled") {
        setXirr(xirr_res?.value?.result);
      }
      setTwrrLoader(true);
      setLoading(false);
      fetchTwrr(clientId, params, type)
        .then((res) => {
          setTwrrLoader(true);
          console.log("fsdfsdsfdsdfdssd", res);
          setTwrr(res?.result);
          //   setLoading(false);
          setTwrrLoader(false);
          setTwrrError(false);
        })
        .catch((err) => {
          setLoading(false);
          setTwrrLoader(false);
          setTwrrError(true);
        });
    });
  }, [mfoFilter, totalRowParams]);
  console.log("twrrLoader twrrError", twrrLoader, twrrError);
  return {
    holdingCardData,
    totalGainLossData,
    xirr,
    twrr,
    loading,
    twrrLoader,
    twrrError,
    retryFetchTwrr,
  };
};
