import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
// import { useTheme } from "@emotion/react";
import { ReactComponent as RightSection } from "../../assets/partner-images/right-section.svg";
import "./NewSignUp.css";

function DefaultSign({ children, left, right, desc, customLeftMargin }) {
  // const theme = useTheme();
  const align = {
    top: { marginTop: "50px" },
    center: {},
    bottom: { marginBottom: "50px" },
  };
  function addRows() {
    let arr = [];
    return Object.keys(align).map((a) => {
      return (
        <div
          className={" " + "flex1"}
          style={{
            ...align[a],
            marginLeft: customLeftMargin ? customLeftMargin : "64px",
          }}
        >
          {desc[a]?.map((c) => {
            return (
              <Box>
                <Typography
                  style={{
                    fontWeight: "900",
                    fontSize: "36px",
                    mb: "10px",
                    color: "white",
                  }}
                  variant="h1"
                  className="generalFontFam"
                >
                  {c?.title}
                </Typography>

                <Typography
                  variant="h6"
                  style={{
                    width: "80%",
                    fontWeight: "400 !important",
                    fontSize: "14px !important",
                    opacity: "0.9",
                    color: "white",
                  }}
                  className="varientH6 generalFontFam"
                >
                  {c?.description}
                </Typography>
              </Box>
            );
          })}
        </div>
      );
    });
  }
  return (
    <>
      <Grid
        container
        style={{
          height: "100vh",
          background: "#000000",
          overflow: { xs: "auto", sm: "auto", md: "auto", lg: "auto" },
        }}
      >
        <Grid style={{ background: "black" }} item sm={12} xs={12} lg={left}>
          <div className="defaultLogin h100">{children}</div>
        </Grid>
        <Grid
          style={{
            overflow: "hidden",
            background: "linear-gradient(180deg, #6D1CAC 0%, #240848 100%)",
            position: "relative",
            display: {
              xs: "none !important",
              sm: "none !important",
              md: "none !important",
              lg: "flex",
            },
          }}
          item
          xs={12}
          sm={12}
          lg={right}
        >
          <div
            style={{
              maxWidth: "fit-content",
              marginLeft: "auto",
              // position: "fixed",
            }}
          >
            <RightSection
              viewBox="0 0 644.85 858.142"
              style={{ height: "99vh" }}
            />
          </div>
          {/* <img src={<RightSection/>}></img> */}
          <div
            className="full"
            style={{
              position: "absolute",
              top: "0px",
            }}
          >
            <div
              className="dFlex flexCol full"
              // style={{
              //   marginLeft: customLeftMargin ? customLeftMargin : "64px",
              // }}
            >
              {desc ? addRows() : ""}
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default DefaultSign;
