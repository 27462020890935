import React from "react";
import Default from "../components/dashboard/defaultPages/Default";
import StructuredProductSummaryComp from "../components/dashboard/products/structured_products/StructuredProductSummaryComp";

function StructuredProductSummary() {
  return (
    <>
      <Default>
        <StructuredProductSummaryComp />
      </Default>
    </>
  );
}

export default StructuredProductSummary;
