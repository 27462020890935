import React from "react";
import NewProduct from "./newProduct";
import { ClientHolding } from "../Components/utils/staticData";
import ClientholdingSummary from "./clientHoldingSummary";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { Link } from "react-router-dom";
import SubCategoryAllocationPdf from "./subCategory";

export default function ProductandHoldingpdf({
  sortData,
  newdata,
  pdfInfo,
  member,
  overview,
  denomination,
  assetDetails,
}) {
  function generareHoldingPages() {
    return Object.keys(sortData)?.map((asset) => {
      let data = [];
      sortData[asset]?.forEach((prod) => {
        if (!newdata[asset]?.allocationSummary[prod]) {
          return;
        }
        data.push({
          ...(newdata[asset]?.allocationSummary[prod] ?? []),
          product: prod,
        });
      });
      let total = newdata[asset].assetDetails?.data[0];
      if (data?.length != 0) {
        data.push({
          currentValue: total?.portfolioValueAmount,

          investmentValue: total?.investedAmount,

          otherIncome: total?.interestIncome,

          unRealisedGainLoss: total.unRealisedGainLoss,

          weightage: total?.percentageOfTotalPortfolio,

          xirr: total?.xirr,
          product: "Total",
        });
      }

      let product = Object.keys(newdata[asset]?.holdingSummary);
      let holding = sortData[asset].map((prod, i) => {
        if (i == product.length - 1 && assetDetails) {
          let grandTotal = assetDetails.find((a) => {
            if (a.assetClass == asset) {
              return a;
            }
          });

          return {
            ...(newdata[asset].holdingSummary[prod] ?? []),
            product: prod,
            grandTotal: {
              " % of Total Portfolio": grandTotal?.percentageOfTotalPortfolio,

              "Asset Class": "--",
              currentValue: grandTotal?.portfolioValueAmount,
              "Folio No": "--",
              unRealisedGainLoss: grandTotal?.unRealisedGainLoss,
              "ISIN No/Product Code": "--",

              "Last Price Date": "--",
              "Market Price per Unit": "--",
              "Member Name": "--",
              otherIncome: grandTotal.interestIncome,
              "Product Name": "--",
              "Quantity/Units": "--",
              totalCost: grandTotal?.investedAmount,
              xirr: grandTotal?.xirr,
              // coverAmount: refForTotal?.coverAmount,
              // premium: refForTotal?.premium,

              currentValue: grandTotal?.portfolioValueAmount,
              otherIncome: grandTotal.interestIncome,
              schemeName: "Grand Total",
              instruments: newdata[asset]?.totalInstrumentsCount,
              balance: grandTotal?.totalBalance,
            },
          };
        }
        return {
          ...(newdata[asset].holdingSummary[prod] ?? []),
          product: prod,
        };
      });

      return (
        <>
          {data?.length > 1 && (
            <NewProduct
              newData={data}
              reportType={"Product allocation"}
              denomination={denomination}
              setChanges={() => {
                console.log("changed");
              }}
              pdfInfo={pdfInfo}
              member={member}
              asset={asset}
            />
          )}

          <ClientholdingSummary
            sortData={sortData}
            newData1={holding}
            allData={newdata}
            overview={overview}
            reportType={"Product allocation"}
            denomination={denomination}
            setChanges={() => {
              console.log("changed");
            }}
            pdfInfo={pdfInfo}
            member={member}
            asset={asset}
          />
        </>
      );
    });
  }

  return <div>{newdata && generareHoldingPages(newdata)}</div>;
}
