import React from "react";
import { useForm } from "../../NewSignUp/hooks/useForm";
import PartnerHeading from "../../NewSignUp/PartnerHeading";
import "../../NewSignUp/PartnerOnboarding.css";
import "../../NewSignUp/NewSignUp.css";
import { Box, Grid, MenuItem } from "@material-ui/core";
import CustomInput from "../../NewSignUp/common/CustomInput";
import StepProgressBar from "../../NewSignUp/common/StepProgressBar/StepProgressBar";
import LoadingButton from "../../NewSignUp/common/LoadingButton";
import ErrorMessageComp from "../../NewSignUp/common/ErrorMessageComp";
import {
  addUserSchema,
  client_add_member_validation_schema,
  updatePartnerUser,
} from "../../NewSignUp/ValidationSchema";
import {
  Client_User_Roles,
  ENTITY_TYPES,
  adduserRoles,
  adduserStatus,
  new_Client_User_Roles,
} from "../../../Constants";
import { handleInitValue } from "../utils/cmmon";

function AddClientUserForm1({
  handleNext,
  handleBack,
  currentStep,
  customHandleSubmit,
  head,
  onlyPerosn,
  apiErrorMessage,
  loading,
  formData,
  totalStep,
  noStepBottom,
  formMaxHeight,
  noStep,
  formWidth,
}) {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useForm(
    {
      fullName: formData?.fullName ? formData?.fullName : "",
      //   entityType:
      //     formData?.entityType !== undefined ? formData?.entityType : "",
      userRole: formData?.userRole
        ? handleInitValue(formData?.userRole, new_Client_User_Roles)
        : "",
      //   panNumber: formData?.panNumber ? formData?.panNumber : "",
      //   dateOfBirth: formData?.dateOfBirth ? formData?.dateOfBirth : "",
      //   formData?.entityType == "sole_proprietor"
      //   ? formData?.dateOfBirth
      //   : formData?.doi
      //   ? formData?.doi
      //   : "",
      email: formData?.email ? formData?.email : "",
      mobile: formData?.mobileNo ? formData?.mobileNo : "",
    },
    // test,
    (values) => {
      console.log(values, "aflkbabfbuawubfw");
      customHandleSubmit(values);
    },
    updatePartnerUser
  );

  return (
    <div
      className="dFlex jtfyContCntr alignCntr h100 flexDirCol"
      style={{ gap: "25px", width: formWidth }}
    >
      <div className="newFormTopSec">
        <PartnerHeading
          mainHead={head?.main ? head?.main : "Add Client User"}
          subtitle={
            head?.sub ? head?.sub : "Join us by providing your company details"
          }
          handleBack={handleBack}
          step={currentStep}
          noStep={noStep}
        />
      </div>
      <div className="newFormContainer" style={{ maxHeight: formMaxHeight }}>
        <Box
          className="dFlex jtfyContCntr alignCntr flexDirCol"
          sx={{ width: { xs: "auto", sm: "330px" }, gap: "41px" }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid xs={12} lg={12} item>
                <CustomInput
                  title="user role"
                  //   disabled={true}
                  // as={TextField}
                  select
                  variant="outlined"
                  margin="normal"
                  // required
                  fullWidth
                  id="userRole"
                  label="User Role"
                  name="userRole"
                  autoComplete="userRole"
                  autoFocus
                  error={touched.userRole && Boolean(errors.userRole)}
                  helperText={touched.userRole && errors.userRole}
                  value={values.userRole}
                  onChange={handleChange}
                >
                  {new_Client_User_Roles.map((entity) => {
                    return (
                      <MenuItem
                        key={entity.value}
                        value={entity.value}
                        selected={entity?.selected}
                        color="white"
                        style={{
                          border: "0.34px solid #3E5389",
                          backgroundColor: "#131826",
                        }}
                      >
                        {entity.name}
                      </MenuItem>
                    );
                  })}
                </CustomInput>
              </Grid>
              {/* <Grid xs={12} lg={12} item>
                <CustomInput
                  title="Entity Type"
                  //   disabled={true}
                  // as={TextField}
                  select
                  variant="outlined"
                  margin="normal"
                  // required
                  fullWidth
                  id="entityType"
                  label="Entity Type"
                  name="entityType"
                  autoComplete="entityType"
                  //   autoFocus
                  error={touched.entityType && Boolean(errors.entityType)}
                  helperText={touched.entityType && errors.entityType}
                  value={values.entityType}
                  onChange={handleChange}
                >
                  {ENTITY_TYPES.map((entity) => {
                    return (
                      <MenuItem
                        key={entity.value}
                        value={entity.value}
                        selected={entity?.selected}
                        color="white"
                        style={{
                          border: "0.34px solid #3E5389",
                          backgroundColor: "#131826",
                        }}
                      >
                        {entity.name}
                      </MenuItem>
                    );
                  })}
                </CustomInput>
              </Grid> */}

              <Grid xs={12} lg={12} item>
                <CustomInput
                  title="Name"
                  // as={TextField}
                  variant="outlined"
                  margin="normal"
                  // required
                  fullWidth
                  id="fullName"
                  label="Full Name"
                  name="fullName"
                  autoComplete="fullName"
                  error={touched.fullName && Boolean(errors.fullName)}
                  helperText={touched.fullName && errors.fullName}
                  value={values.fullName}
                  onChange={handleChange}
                />
              </Grid>

              <Grid
                xs={12}
                lg={12}
                item
                // pr={{ xs: 0, sm: 0, md: 0, lg: 1 }}
              >
                <CustomInput
                  title="Mobile no."
                  // as={TextField}
                  variant="outlined"
                  margin="normal"
                  // required
                  fullWidth
                  id="mobile"
                  label="Mobile Number"
                  name="mobile"
                  error={touched.mobile && Boolean(errors.mobile)}
                  helperText={touched.mobile && errors.mobile}
                  value={values.mobile}
                  onChange={handleChange}
                  // maxLength={10}
                />
              </Grid>
              <Grid xs={12} lg={12} item>
                <CustomInput
                  // as={TextField}
                  title="EMAIL ID"
                  variant="outlined"
                  margin="normal"
                  // required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  //   autoFocus
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  value={values.email}
                  onChange={handleChange}
                />
              </Grid>

              <div className="newFormBottonSec">
                <Grid xs={12} lg={12} style={{ marginTop: "30px" }} item>
                  <ErrorMessageComp errorMessage={apiErrorMessage} />
                  <LoadingButton
                    buttonTitle={"Save"}
                    type="submit"
                    showLoader={loading}
                  />
                </Grid>
                {noStepBottom ? (
                  ""
                ) : (
                  <Grid xs={12} item style={{ marginTop: "28px" }}>
                    <StepProgressBar
                      total={totalStep}
                      currentStep={currentStep}
                    />
                  </Grid>
                )}
              </div>
            </Grid>
          </form>
        </Box>
      </div>
    </div>
  );
}

export default AddClientUserForm1;
