import React from "react";
import "./NewSignUp.css";
import LoadingButton from "./common/LoadingButton";
import { Box, Typography } from "@material-ui/core";
import PartnerHeading from "./PartnerHeading";

function SuccessPage({
  handleBack,
  noStep,
  subData,
  buttonTxt,
  handleNext,
  gap,
  currentStep
}) {
  return (
    <div className="dFlex jtfyContCntr alignCntr h100">
      <Box
        className="dFlex jtfyContCntr alignCntr flexDirCol"
        sx={{ width: { xs: "auto", sm: "330px" }, gap: gap ? gap : "119px" }}
      >
        <PartnerHeading
          handleBack={handleBack}
          step={currentStep}
          noStep={noStep}
          mainHead="Successfull"
          subtitle={
            subData
              ? subData
              : "Your Email & Mobile Number has been verified. You will recieve a link to set your pin shorlty"
          }
        />

        <Box sx={{ width: "100%" }}>
          <LoadingButton
            buttonTitle={buttonTxt ? buttonTxt : "Done"}
            customOnclick={handleNext}
          />
        </Box>
        {/* <div className="successPageWarningSec">
          <div className="warningSign">i</div>
          <Typography
            className="generalFontFam varient12"
            style={{ marginTop: "auto" }}
          >
            You will be able to login only once your details have been verified
            by us, you will receive an email for the same.
          </Typography>
        </div> */}
      </Box>
    </div>
  );
}

export default SuccessPage;
