import React from "react";
import Default from "../components/dashboard/defaultPages/Default";
import { NewVerificationUpload } from "../components/verificationUpload/NewVerificationUpload";

function VerificationUploadV2() {
  return (
    <Default>
      <NewVerificationUpload />
    </Default>
  );
}

export default VerificationUploadV2;
