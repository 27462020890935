import React from "react";
import NewProduct from "./newProduct";
import { ClientHolding } from "../Components/utils/staticData";
import ClientholdingSummary from "./clientHoldingSummary";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { Link } from "react-router-dom";
import SubCategoryAllocationPdf from "./subCategory";
import MemberHolding from "./MemberHolding";
import MemberHoldingSummary from "./MemberHoldingSummary";

export default function HoldingMemberWrapper({
  sortData,
  newdata,
  pdfInfo,
  member,
  grandTotal1,
  overview,
  denomination,
  assetDetails,
  last,
}) {
  function generareHoldingPages() {
    let sortedAsset = Object.keys(sortData).filter((s) => {
      if (Object.keys(newdata[s].holdingSummary).length > 0) {
        return s;
      }
    });
    return sortedAsset?.map((asset, assetLen) => {
      let data = [];
      sortData[asset]?.forEach((prod) => {
        if (!newdata[asset]?.allocationSummary[prod]) {
          return;
        }
        data.push({
          ...(newdata[asset]?.allocationSummary[prod] ?? []),
          product: prod,
        });
      });
      let total = newdata[asset].assetDetails?.data[0];
      if (data?.length != 0) {
        data.push({
          currentValue: total?.portfolioValueAmount,

          investmentValue: total?.investedAmount,

          otherIncome: total?.interestIncome,

          unRealisedGainLoss: total?.unRealisedGainLoss,

          weightage: total?.percentageOfTotalPortfolio,

          xirr: total?.xirr,
          product: "Total",
        });
      }

      let product = Object.keys(newdata[asset]?.holdingSummary);
      const productlist = sortData[asset].filter((p) => {
        if (newdata[asset].holdingSummary[p]) {
          return p;
        }
      });
      let holding = productlist.map((prod, i) => {
        if (i == product.length - 1 && assetDetails) {
          let grandTotal = assetDetails.find((a) => {
            if (a.assetClass == asset) {
              return a;
            }
          });

          return {
            ...(newdata[asset].holdingSummary[prod] ?? []),
            product: prod,
            grandTotal: {
              " % of Total Portfolio": grandTotal?.percentageOfTotalPortfolio,

              "Asset Class": "--",
              currentValue: grandTotal?.portfolioValueAmount,
              "Folio No": "--",
              unRealisedGainLoss: grandTotal?.unRealisedGainLoss,
              "ISIN No/Product Code": "--",

              "Last Price Date": "--",
              "Market Price per Unit": "--",
              "Member Name": "--",
              otherIncome: grandTotal?.interestIncome,
              "Product Name": "--",
              "Quantity/Units": "--",
              totalCost: grandTotal?.investedAmount,
              xirr: grandTotal?.xirr,
              // coverAmount: refForTotal?.coverAmount,
              // premium: refForTotal?.premium,
              asset: asset,
              subTotal: "Sum Total",
              currentValue: grandTotal?.portfolioValueAmount,
              otherIncome: grandTotal?.interestIncome,
              schemeName: "Sum Total",
              instruments: newdata[asset]?.totalInstrumentsCount,
              balance: grandTotal?.totalBalance,
            },
            grandTotal1:
              assetLen == sortedAsset.length - 1 ? grandTotal1 : null,
            //  {
            //   " % of Total Portfolio":
            //     grandTotal1?.grand?.totalPortfolioPercentage,

            //   "Asset Class": "--",
            //   currentValue: grandTotal1?.grand?.portfolioValueAmount,
            //   "Folio No": "--",
            //   unRealisedGainLoss: grandTotal1?.grand?.unRealisedGainLoss,
            //   "ISIN No/Product Code": "--",

            //   "Last Price Date": "--",
            //   "Market Price per Unit": "--",
            //   "Member Name": "--",
            //   otherIncome: grandTotal1.grand?.interestIncome,
            //   "Product Name": "--",
            //   "Quantity/Units": "--",
            //   totalCost: grandTotal1?.grand?.investedAmount,
            //   xirr: grandTotal1?.xirr?.xirrValue,
            //   // coverAmount: refForTotal?.coverAmount,
            //   // premium: refForTotal?.premium,

            //   currentValue: grandTotal1?.grand?.portfolioValueAmount,
            //   otherIncome: grandTotal1?.grand?.interestIncome,
            //   schemeName: "Grand Total",
            //   // instruments: newdata[asset]?.totalInstrumentsCount,
            //   balance: grandTotal1.grand?.totalBalance,
            // },
          };
        }
        return {
          ...(newdata[asset].holdingSummary[prod] ?? []),
          product: prod,
        };
      });

      return (
        <>
          {/* {data?.length > 1 && (
            <NewProduct
              newData={data}
              reportType={"Product allocation"}
              denomination={denomination}
              setChanges={() => {
                console.log("changed");
              }}
              pdfInfo={pdfInfo}
              member={member}
              asset={asset}
            />
          )} */}

          <MemberHoldingSummary
            sortData={sortData}
            newData1={holding}
            allData={newdata}
            overview={overview}
            reportType={"Product allocation"}
            denomination={denomination}
            setChanges={() => {
              console.log("changed");
            }}
            pdfInfo={pdfInfo}
            member={member}
            asset={asset}
          />
        </>
      );
    });
  }

  return <div>{newdata && generareHoldingPages(newdata)}</div>;
}
