import "./riskSlider.css";
import React, { useState } from "react";

export default function RiskSlider({ riskValue }) {
  const [price, setPrice] = useState(riskValue);
  const [textpos, setTextpos] = useState(0);

  const str = `calc(${riskValue}% - 20px)`;
  return (
    <div>
      <div className="slideBlock">
        <div className="slideProgress">
          <div className="greenclass curvedBorderLeft">
            <p>Low</p>
          </div>
          <div className="lightgreenclass ">
            <p>Low to Moderate</p>
          </div>
          <div className="orangeclass">
            <p>Moderate</p>
          </div>
          <div className="darkorangeclass">
            <p>Moderately High</p>
          </div>
          <div className="redclass">
            <p>High</p>
          </div>
          <div className="darkRedclass curvedBorderRight">
            <p>Very High</p>
          </div>
        </div>
        {riskValue && (
          <input
            style={{ height: "52px", border: "none" }}
            // onChange={(e) => setPrice(
            //     e.target.value
            // )}
            value={riskValue}
            type="range"
            name="range"
            id="range"
            min="0"
            max="100"
          />
        )}
        {riskValue && (
          <b
            className="circle_text3"
            style={{
              color: "#fff",
              left: `${str}`,
              position: "relative",
              top: "15px",
            }}
          >
            This Fund{" "}
          </b>
        )}
      </div>
    </div>
  );
}
