import React, { useEffect, useState, useContext, useRef } from "react";
import moment from "moment";
import useStyles from "../common/ProductsStyles";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
// import { ReactComponent as Subtract } from "../../../../assets/icons/subtract.svg";
// import { ReactComponent as Eye } from "../../../../assets/icons/white-eye.svg";
// import { ReactComponent as Filter } from "../../../../assets/icons/filter.svg";
// import { ReactComponent as Bulb } from "../../../../assets/icons/bulb-glow.svg";
// import { ReactComponent as BigBulb } from "../../../../assets/icons/big-bulb.svg";
// import { ReactComponent as Back } from '../../../../assets/partner-images/back.svg'
import { ReactComponent as Back } from "../../../../assets/holding-summary/back-blue.svg";
import PieChart from "../../../charts/PieChart";
import { colors, pSBC } from "../../../charts/colors";
import BarChart from "../../../charts/BarChart";
import { divergingCommonProps } from "../../../charts/nivo_objects";
// import { tooltip } from "../../../charts/barTooltip";
import { useNavigate } from "react-router-dom";
import { OnboardingContext } from "../../../../Context";
import "react-datepicker/dist/react-datepicker.css";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import PageLoader from "../common/pageLoader";
import {
  getAmcData,
  getAssetWiseData,
  getRealisedData,
  getInvestmentTypeData,
  mlyPortfolioTrend,
} from "../../../../api";
import RangeCalendar from "../../../calendar/RangeCalendar";
import DataNotFound from "../common/DataNotFound";
import "../common/products-style.css";
import { currencyFormat } from "../../../../hooks/constantFunction";
import { roundNumber } from "../../../utils/common";

function MutualFundSummary() {
  const theme = {
    // background: "#110425",
    textColor: "#333333",
    fontSize: 11,
    axis: {
      domain: {
        line: {
          stroke: "#EFF1F3",
          strokeWidth: 1,
        },
      },
      legend: {
        text: {
          fontSize: 12,
          fill: "#F8F9FB",
        },
      },
      ticks: {
        line: {
          stroke: "#1f1c40",
          strokeWidth: 1,
        },
        text: {
          fontSize: 11,
          fill: "#ebe8ee",
        },
      },
    },
    grid: {
      line: {
        stroke: "#1f1c40",
        strokeWidth: 1,
      },
    },
    legends: {
      title: {
        text: {
          fontSize: 11,
          fill: "#ebe8ee",
        },
      },
      text: {
        fontSize: 11,
        fill: "#eae7ed",
      },
      ticks: {
        line: {},
        text: {
          fontSize: 10,
          fill: "#333333",
        },
      },
    },
    annotations: {
      text: {
        fontSize: 13,
        fill: "#333333",
        outlineWidth: 2,
        outlineColor: "#3c9cfa",
        outlineOpacity: 0.7,
      },
      link: {
        stroke: "#000000",
        strokeWidth: 1,
        outlineWidth: 2,
        outlineColor: "#ffffff",
        outlineOpacity: 1,
      },
      outline: {
        stroke: "#000000",
        strokeWidth: 2,
        outlineWidth: 2,
        outlineColor: "#ffffff",
        outlineOpacity: 1,
      },
      symbol: {
        fill: "#000000",
        outlineWidth: 2,
        outlineColor: "#ffffff",
        outlineOpacity: 1,
      },
    },
    tooltip: {
      container: {
        background: "#ffffff",
        color: "#333333",
        fontSize: 12,
      },
      basic: {},
      chip: {},
      table: {},
      tableCell: {},
      tableCellValue: {},
    },
  };
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const { userState } = OnboardingContext();
  const classes = useStyles();
  const [loadque, setloadque] = useState(null);
  const [dateRange, setDateRange] = useState([
    new Date(moment().subtract(1, "years")),
    new Date(moment()),
  ]);
  const [startDate, endDate] = dateRange;
  const navigate = useNavigate();
  const [flip, setFlip] = useState(false);
  const realisedGainColors = [colors.purple, colors.green];
  const assetAllocationColors = [
    colors.blue,
    colors.orange,
    colors.green,
    colors.pink,
    colors.purple,
  ];
  const amcColors = [
    colors.pink,
    colors.orange,
    colors.green,
    colors.blue,
    colors.purple,
  ];
  const invAllocationColors = [
    colors.orange,
    colors.green,
    colors.blue,
    colors.purple,
  ];
  const [realisedGain, setRealisedGain] = useState({
    title: "",
    value: "",
    colors: realisedGainColors,
    disabled: [],
  });
  const [assetAllocation, setAssetAllocation] = useState({
    title: "",
    value: "",
    colors: assetAllocationColors,
    disabled: [],
  });

  const [amc, setAmc] = useState({
    title: "",
    value: "",
    colors: amcColors,
    disabled: [],
  });
  const [invAllocation, setInvAllocation] = useState({
    title: "",
    value: "",
    colors: invAllocationColors,
    disabled: [],
  });
  const [twrr, setTwrr] = useState({});
  const [xirr, setXirr] = useState({});
  const [portfolioTrend, setPortfolioTrend] = useState([]);
  const [amcData, setAmcData] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const [minMax, setMinMax] = useState([0, 5]);
  const [investmentTypeData, setInvestmentTypeData] = useState([]);
  const [toggle, setToggle] = useState(true);
  const [realisedData, setRealisedData] = useState({
    arr: [],
  });
  const [loading, setLoading] = useState({
    realisedDataLoading: true,
    investmentTypeDataLoading: true,
    assetWiseDataLoading: true,
    amcDataLoading: true,

    monthOnMonthLoading: true,
  });

  useEffect(() => {
    //userState.loginedUserData.id;
    const clientId = userState.loginedUserData.id;
    divergingCommonProps.axisLeft.legend = "Time Period";
    divergingCommonProps.axisBottom.legend = "Percentage Change";
    divergingCommonProps.axisBottom.legendOffset = 42;
    let params = { ...getfilter_as_params() };

    setloadque(6);
    // fetchXirr(clientId, params)
    //   .then((res) => {
    //     setXirr(res.result);
    //     setloadque(loadque > 1 ? loadque - 1 : null);
    //     setLoading((prev) => ({ ...prev, xirr: false }));
    //   })
    //   .catch((err) => {
    //     setloadque(loadque > 1 ? loadque - 1 : null);
    //     setLoading((prev) => ({ ...prev, xirr: false }));
    //   });
    // fetchTwrr(clientId, params)
    //   .then((res) => {
    //     if (res?.result?.annualisedTwrr) {
    //       res.result.annualisedTwrr = res?.result?.annualisedTwrr * 100;
    //     }
    //     setTwrr(res.result);
    //     setloadque(loadque > 1 ? loadque - 1 : null);
    //     setLoading((prev) => ({ ...prev, twrr: false }));
    //   })
    //   .catch((err) => {
    //     setloadque(loadque > 1 ? loadque - 1 : null);
    //     setLoading((prev) => ({ ...prev, twrr: false }));
    //   });

    // charts api
    getAmcData(clientId, params, null, toggle)
      .then((res) => {
        let AMC = [];
        res.result.forEach((a, i) => {
          AMC.push({
            id: `${i}`,
            label: `${a?.amc.charAt(0).toUpperCase() + a.amc.slice(1)}`,
            value: parseFloat(a.percent).toFixed(2),
            amount: a.currentValue,
          });
        });
        setAmcData(AMC);
        setloadque(loadque > 1 ? loadque - 1 : null);
        setLoading((prev) => ({ ...prev, amcDataLoading: false }));
      })
      .catch((err) => {
        setloadque(loadque > 1 ? loadque - 1 : null);
        setLoading((prev) => ({ ...prev, amcDataLoading: false }));
      });

    getAssetWiseData(clientId, params, null, toggle)
      .then((res) => {
        let arr = [];
        res?.data.forEach((a) => {
          arr.push({
            id: `${a.asset_class}`,
            label: `${
              a.asset_class
                ? a?.asset_class.charAt(0).toUpperCase() +
                  a?.asset_class.slice(1)
                : " "
            }`,
            value: parseFloat(a.percentage).toFixed(2),
            amount: a.amount,
          });
        });
        setAssetData(arr);
        setloadque(loadque > 1 ? loadque - 1 : null);
        setLoading((prev) => ({ ...prev, assetWiseDataLoading: false }));
      })
      .catch((err) => {
        setloadque(loadque > 1 ? loadque - 1 : null);
        setLoading((prev) => ({ ...prev, assetWiseDataLoading: false }));
      });
    getRealisedData(clientId, params, null, toggle)
      .then((res) => {
        let arr = [];
        if (res.result.unRealisedGainLossPercent != null) {
          if (res.result.realisedGainLossPercent != 0) {
            arr.push({
              id: `Realised Gain Loss`,
              label: `Realized Gain/Loss`,
              value: Math.abs(
                parseFloat(res.result.realisedGainLossPercent).toFixed(2)
              ),
              nag:
                parseFloat(res.result.realisedGainLossPercent).toFixed(2) < 0
                  ? true
                  : false,
              amount: res.result.realisedGainLoss,
            });
          }
          if (res.result.unRealisedGainLossPercent != 0) {
            arr.push({
              id: `Unrealised Gain Loss`,
              label: `Unrealized Gain/Loss`,
              value: Math.abs(
                parseFloat(res.result.unRealisedGainLossPercent).toFixed(2)
              ),
              amount: res.result.unRealisedGainLoss,
            });
          }
        }

        setRealisedData({ ...res.result, arr });
        setloadque(loadque > 1 ? loadque - 1 : null);
        setLoading((prev) => ({ ...prev, realisedDataLoading: false }));
      })
      .catch((err) => {
        setloadque(loadque > 1 ? loadque - 1 : null);
        setLoading((prev) => ({ ...prev, realisedDataLoading: false }));
      });

    getInvestmentTypeData(clientId, params, null, toggle)
      .then((res) => {
        let arr = [];
        res.result.forEach((a) => {
          if (a.investment_type?.toLowerCase() == "sip") {
            a.investment_type = "SIP";
          }
          arr.push({
            id: `${a.investment_type}`,
            label: `${
              a.investment_type
                ? a.investment_type?.charAt(0)?.toUpperCase() +
                  a.investment_type?.slice(1)
                : " "
            }`,
            value: parseFloat(a.percentage).toFixed(2),
            amount: a.amount,
          });
        });

        setInvestmentTypeData(arr);
        setloadque(loadque > 1 ? loadque - 1 : null);
        setLoading((prev) => ({ ...prev, investmentTypeDataLoading: false }));
      })
      .catch((err) => {
        setloadque(loadque > 1 ? loadque - 1 : null);
        setLoading((prev) => ({ ...prev, investmentTypeDataLoading: false }));
      });
  }, [mfoFilter, toggle]);

  useEffect(() => {
    portfolio([dateRange[0], dateRange[1]]);
  }, [mfoFilter]);

  const ShowLegends = (data, color, realised) => {
    return (
      <div
        style={{
          position: "absolute",
          top: "100px",
          // left: "285px",
          // minHeight: '150px'
          // top: "12%",
          right: "18px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            minHeight: "150px",
            maxWidth: "340px",
          }}
        >
          {data?.map((m, i) => {
            return (
              <div key={i} className={classes.labelContainer}>
                <div className={classes.labelSymbol}>
                  <svg height="25" width="25">
                    <circle
                      cx="12"
                      cy="12"
                      r="5"
                      stroke-width="3"
                      fill={color[i]}
                    />
                    Sorry, your browser does not support inline SVG.
                  </svg>
                </div>
                {/* <div className={classes.label}><div className={classes.labelTextLegends}><span style={{marginRight:"20px"}} >{m.label}</span>  <span>{m.value}%</span> </div></div> */}
                <table style={{ width: "270px" }}>
                  <tr className={`${classes.label} no-border `}>
                    <td
                      className={`${classes.labelTextLegends}`}
                      style={{ backgroundColor: "transparent" }}
                    >
                      <div
                        style={{
                          width: "140px",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          marginRight: "30px",
                          paddingBottom: "10px",
                        }}
                      >
                        {m.label}
                      </div>
                    </td>
                    <td
                      style={{
                        width: "60px",
                        paddingLeft: "10px",
                        display: "block",
                        backgroundColor: "transparent",
                        color: realised
                          ? m?.nag == true
                            ? "#F52D6B"
                            : "#35CBBF"
                          : " ",
                      }}
                      className={classes.labelTextLegends}
                    >
                      {realised ? (m.nag == true ? "-" : " ") : " "} {m.value}%
                    </td>
                  </tr>
                </table>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  const ShowLegendsAmc = (data, color) => {
    return (
      <div
        style={{
          position: "absolute",
          // top: "100px",
          // left: "285px",
          // minHeight: '150px'
          top: "12%",
          right: "0px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            minHeight: "150px",
            maxWidth: "340px",
          }}
        >
          {data?.map((m, i) => {
            return (
              <div key={i} className={classes.labelContainer}>
                <div className={classes.labelSymbol}>
                  <svg height="25" width="25">
                    <circle
                      cx="12"
                      cy="12"
                      r="5"
                      stroke-width="3"
                      fill={color[i]}
                    />
                    Sorry, your browser does not support inline SVG.
                  </svg>
                </div>
                {/* <div className={classes.label}><div className={classes.labelTextLegends}><span style={{marginRight:"20px"}} >{m.label}</span>  <span>{m.value}%</span> </div></div> */}
                <table style={{ width: "270px" }}>
                  <tr className={`${classes.label} no-border `}>
                    <td
                      className={`${classes.labelTextLegends} outer-tooltip`}
                      style={{ backgroundColor: "transparent" }}
                    >
                      <div
                        style={{
                          width: "140px",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          marginRight: "30px",
                          paddingBottom: "10px",
                        }}
                      >
                        {m.label}
                      </div>
                      <div
                        className=" inner-tooltip tooltip-box"
                        style={{
                          left: "-144px",
                        }}
                      >
                        <p className="tooltip-small-text">
                          Name of Mutual Fund
                        </p>
                        <p className="tooltip-text">{m.label}</p>
                      </div>
                    </td>
                    <td
                      style={{
                        width: "60px",
                        paddingLeft: "10px",
                        display: "block",
                        backgroundColor: "transparent",
                      }}
                      className={classes.labelTextLegends}
                    >
                      {m.value}%
                    </td>
                  </tr>
                </table>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  function portfolio(range) {
    if (!range[0] || !range[1]) {
      return;
    }
    setLoading((prev) => ({ ...prev, monthOnMonthLoading: true }));
    setloadque(loadque ? loadque + 1 : 1);
    const clientId = userState.loginedUserData.id;

    let params = {
      assetClass: getfilter_as_params().assetClass,
      startMonthYear: moment(range[0]).format("YYYY-MM"),
      endMonthYear: moment(range[1]).format("YYYY-MM"),
    };
    if (Object.keys(mfoFilter.checkData).length > 0) {
      params["memberId"] = Object.keys(mfoFilter.checkData).join(",");
    }
    if (Object.keys(mfoFilter.advisor).length > 0) {
      params["advisor"] = Object.keys(mfoFilter.advisor).join(",");
    }
    let min = 0;
    let max = 5;
    mlyPortfolioTrend(clientId, params)
      .then((res) => {
        let result = res.result.map((r) => {
          if (r.changePercentage < 0 && r.changePercentage < min) {
            min = r.changePercentage;
          } else if (r.changePercentage > max) {
            max = r.changePercentage;
          }
          return {
            ...r,
            loss_percent: r.changePercentage < 0 ? r.changePercentage : 0,
            gain_percent: r.changePercentage > 0 ? r.changePercentage : 0,
          };
        });
        if (min > -7) {
          min = -7;
        }
        if (max < 5) {
          max = 5;
        }
        if (max * -1 < min) {
          min = max * -1;
        } else if (min * -1 > max) {
          max = min * -1;
        }
        setPortfolioTrend(result);
        // if(moment(moment("01-"+result[result.length-1].month).format("YYYY-MM-DD")).isBefore(moment("01-"+result[0].month).format("YYYY-MM-DD"))){
        //   setPortfolioTrend(result);
        // }else{

        //   setPortfolioTrend(result.reverse());
        // }

        setMinMax([min - 4, max + 4]);
        setloadque(loadque > 1 ? loadque - 1 : null);
        setLoading((prev) => ({ ...prev, monthOnMonthLoading: false }));
      })
      .catch((err) => {
        setloadque(loadque > 1 ? loadque - 1 : null);
        setLoading((prev) => ({ ...prev, monthOnMonthLoading: false }));
      });
  }

  const tooltip = (point) => {
    return (
      <div
        style={{
          padding: "9px 12px",
          color: "#fff",
          background: " #1d0640",
          border: "1px solid #000000",
          borderRadius: "5px",
        }}
      >
        <div>
          change percent:{" "}
          <b>
            {point.data["gain_percent"]
              ? roundNumber(point.data["gain_percent"]) + "%"
              : roundNumber(point.data["loss_percent"]) + "%"}
          </b>
        </div>
      </div>
    );
  };

  //realised gain on click
  function realisedGain_onClick(rdata, setData, cstate, ncolors) {
    let newColors;
    if (cstate.disabled.length === 0) {
      newColors = ncolors?.map((c) => {
        let d = pSBC(-7 / 10, c, false, false);

        cstate.disabled.push(d);

        if (c === rdata.color) {
          return c;
        }
        return d;
      });
    } else {
      // else
      let found = ncolors.find((c, i) => {
        if (c === rdata.color) {
          //   newColors=cstate.disabled
          //   newColors[i]= ncolors[i]
          //   // cstate.colors=newColors

          newColors = ncolors;
          cstate.disabled = [];
          return true;
        }
      });
      if (!found) {
        newColors = [];
        cstate.disabled.filter((r, i) => {
          if (r === rdata.color) {
            newColors.push(ncolors[i]);
          } else {
            newColors.push(r);
          }
        });
      }
    }

    setData({
      title: cstate.disabled.length > 0 ? rdata["label"] : "",
      value:
        cstate.disabled.length > 0
          ? currencyFormat(rdata.data["amount"]?.toFixed(2))
          : "",
      colors: newColors,
      disabled: cstate.disabled,
    });
  }

  divergingCommonProps.axisLeft.renderTick = (tick) => {
    return (
      <g transform={`translate(${tick.x - 30},${tick.y})`}>
        <text
          textAnchor="middle"
          dominantBaseline="middle"
          style={{
            fill: "#b0aab7",
            fontSize: 10,
          }}
        >
          {tick.value}
        </text>
      </g>
    );
  };
  divergingCommonProps.indexBy = "month";

  // let currencyFormat = (value) => {
  //   return currencyFormat(value, {
  //     currencyStyle: { style: "currency", currency: "INR" },
  //   });
  // };

  // const frontSideComponent = (
  //   <div style={{ width: "300px" }}>
  //     <Card className="bgholdingsummary bigCardModify">
  //       <CardContent className={classes.mfoCardAlign}>
  //         <div className={classes.subtractCircle}>
  //           <Subtract className={classes.mfoCircleAlign} />
  //         </div>
  //         <Typography
  //           className={classes.mfoTextCard}
  //           style={{ marginBottom: "2px" }}
  //         >
  //           PortFolio Value
  //         </Typography>
  //         <Typography
  //           style={{ marginBottom: "0px" }}
  //           className={classes.mfoCardValue}
  //         >
  //           ₹{" "}
  //           {realisedData.portfolioValueAmount
  //             ? parseFloat(
  //               parseFloat(realisedData.portfolioValueAmount)?.toFixed(2)
  //             ).toLocaleString("en-IN")
  //             : "--"}
  //         </Typography>
  //         <Typography
  //           className={classes.mfoSubTextCard}
  //           style={{ marginBottom: "2px" }}
  //         >
  //           As on {realisedData?.asOnDate}
  //         </Typography>
  //       </CardContent>
  //     </Card>
  //   </div>
  // );

  // const backSideComponent = (
  //   <div style={{ width: "300px" }}>
  //     <Card
  //       className={classes.mfoBackCard}
  //       style={{ position: "relative", minHeight: "150px" }}
  //     >
  //       <CardContent className={classes.mfoCardAlign}>
  //         <Typography
  //           className={classes.mfoBackTextMain}
  //           style={{ marginTop: "10px" }}
  //         >
  //           Portfolio Value
  //         </Typography>
  //         <Typography
  //           className={classes.mfoBackTextSub}
  //           style={{ marginTop: "10px", width: "180px" }}
  //         >
  //           Portfolio Value means the market value from time to time of the
  //           Portfolio
  //         </Typography>
  //         <div style={{ position: "absolute", top: "0", right: "0" }}>
  //           {" "}
  //           <BigBulb />
  //         </div>
  //       </CardContent>
  //     </Card>
  //   </div>
  // );

  return (
    <>
      {/* <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1  }}
        open={loadque?true:false}
       
      >
        <CircularProgress color="blue" />
      </Backdrop> */}
      {/* <PageFilter></PageFilter> */}
      <div
        className={`${classes.alignTopHeader}`}
        style={{ justifyContent: "flex-start" }}
      >
        {/* <Back
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/home/mutual-fund/mutual-fund-summary");
          }}
        /> */}
        <Typography
          // style={{ marginLeft: "-4%" }}
          className={`${classes.mfoGraphHeader} ${classes.mainHeadingAlign}`}
        >
          Detailed Summary
        </Typography>
        {/* <div className={`${classes.iconAlign}`}>
          {/* <Filter className={classes.iconStyle} /> */}
        {/* <Bulb onClick={() => setFlip(!flip)} className={classes.iconStyle} /> 
          {
            <FormGroup>
              <FormControlLabel
                control={
                  <IosSwitch
                    checked={toggle ? toggle : false}
                    setChecked={setToggle ? setToggle : () => {}}
                  />
                }
                labelPlacement="start"
                style={{
                  fontFamily: "Avenir",
                  fontWeight: 900,
                  fontSize: "16px",
                  letterSpacing: "1.5px",
                  textTransform: "uppercase",
                  color: "#FFFFFF",
                  opacity: 0.9,
                  marginRight: "12px",
                }}
              />
            </FormGroup>
          }
        </div> */}
      </div>

      {/* working functionality for react-flippy */}
      {/* {MfoFlippyArray(mfoFLipArr, flip)} */}

      <div className={classes.mfoMainContainer}>
        <Grid xs={6}>
          <div className={`${classes.mainContent} ${classes.mfoTopMainAlign}`}>
            <Typography
              className={`${classes.mfoGraphHeader} ${classes.typoInsideAlign}`}
            >
              Realized and Unrealized Gain/Loss
            </Typography>
            <div className="flex_center" style={{ overflow: "hidden" }}>
              <div
                style={{
                  width: "100%",
                  // marginLeft: "30px",
                  marginTop: "-30px",
                }}
              >
                {/* realised Gain pie */}
                {loading?.realisedDataLoading == false ? (
                  realisedData?.arr?.length > 0 ? (
                    <PieChart
                      config={{
                        innerRadius: "0.80",
                        cornerRadius: 0,
                        padAngle: 0,
                        legends: false,
                        minWith: "380px",
                        margin: { right: 200, top: 20, left: 30 },
                        disableArcLink: true,
                        chart: { width: 420, height: 340 },
                      }}
                      colors={realisedGain.colors}
                      onClickEvent={{
                        click: realisedGain_onClick,
                        set: setRealisedGain,
                        cstate: realisedGain,
                        ncolors: realisedGainColors,
                      }}
                      data={realisedData.arr}
                    >
                      <div className="cen" style={{ color: "#fff" }}>
                        <div>
                          <div>{realisedGain.title}</div>
                          <div
                            style={{
                              color:
                                realisedGain?.value.indexOf("-") < 0
                                  ? "#35CBBF"
                                  : "#F52D6B",
                            }}
                          >
                            {realisedGain.value}
                          </div>
                        </div>
                      </div>
                    </PieChart>
                  ) : (
                    <DataNotFound />
                  )
                ) : (
                  ""
                )}

                {/* <div style={{
                                    position:"absolute",
                                    top:"100px",
                                    left:'240px'
                                }}>
                                    {realisedData.arr.map((m, i) => {
                                        return (
                                            <div className={classes.labelContainer}>
                                                <div className={classes.labelSymbol}  >
                                                    <svg height="25" width="25">
                                                        <circle cx="12" cy="12" r="5" stroke-width="3" fill={realisedGainColors[i]} />
                                                        Sorry, your browser does not support inline SVG.
                                                    </svg>
                                                </div>
                                                <div className={classes.label}><span className={classes.labelTextLegends}>{m.label}</span></div>
                                            </div>
                                        )    //
                                    })}
                                </div> */}
                {ShowLegends(realisedData.arr, realisedGain.colors, "realised")}
              </div>
            </div>
          </div>
          <div
            className={`${classes.mainContent} ${classes.mfoBottomMainAlign}`}
          >
            <Typography
              className={`${classes.mfoGraphHeader} ${classes.typoInsideAlign}`}
            >
              Top 5 Mutual fund holdings
            </Typography>
            <div
              className="flex_center "
              style={{ overflow: "hidden", position: "relative" }}
            >
              <div
                style={{
                  width: "100%",
                  // marginLeft: "30px",
                  marginTop: "-30px",
                }}
              >
                {loading?.amcDataLoading == false ? (
                  amcData.length > 0 ? (
                    <PieChart
                      config={{
                        innerRadius: "0.80",
                        cornerRadius: 0,
                        padAngle: 0,
                        legends: false,
                        minWith: "380px",
                        margin: { right: 200, top: 20, left: 30 },
                        disableArcLink: true,
                        chart: { width: 420, height: 340 },
                      }}
                      colors={amc.colors}
                      onClickEvent={{
                        click: realisedGain_onClick,
                        set: setAmc,
                        cstate: amc,
                        ncolors: amcColors,
                      }}
                      data={amcData}
                    >
                      <div className="cen" style={{ color: "#fff" }}>
                        <div>
                          <div>{amc.title}</div>
                          <div>{amc.value}</div>
                        </div>
                      </div>
                    </PieChart>
                  ) : (
                    <DataNotFound />
                  )
                ) : (
                  ""
                )}
                {ShowLegendsAmc(amcData, amc.colors)}
              </div>
            </div>
          </div>
        </Grid>

        <Grid xs={6}>
          <div className={`${classes.mainContent} ${classes.mfoTopMainAlign}`}>
            <Typography
              className={`${classes.mfoGraphHeader} ${classes.typoInsideAlign}`}
            >
              Asset Wise Allocation
            </Typography>
            <div className="flex_center " style={{ overflow: "hidden" }}>
              <div
                style={{
                  width: "100%",
                  // marginLeft: "30px",
                  marginTop: "-30px",
                }}
              >
                {loading?.assetWiseDataLoading == false ? (
                  assetData.length > 0 ? (
                    <PieChart
                      config={{
                        innerRadius: "0.80",
                        cornerRadius: 0,
                        padAngle: 0,
                        legends: false,
                        minWith: "380px",
                        margin: { right: 200, top: 20, left: 30 },
                        disableArcLink: true,
                        chart: { width: 420, height: 340 },
                      }}
                      colors={assetAllocation.colors}
                      onClickEvent={{
                        click: realisedGain_onClick,
                        set: setAssetAllocation,
                        cstate: assetAllocation,
                        ncolors: assetAllocationColors,
                      }}
                      data={assetData}
                    >
                      <div className="cen" style={{ color: "#fff" }}>
                        <div>
                          <div>{assetAllocation.title}</div>
                          <div>{assetAllocation.value}</div>
                        </div>
                      </div>
                    </PieChart>
                  ) : (
                    <DataNotFound />
                  )
                ) : (
                  ""
                )}
                {ShowLegends(assetData, assetAllocation.colors)}
              </div>
            </div>
          </div>
          <div
            className={`${classes.mainContent} ${classes.mfoBottomMainAlign}`}
          >
            <Typography
              className={`${classes.mfoGraphHeader} ${classes.typoInsideAlign}`}
            >
              Investment Type Allocation
            </Typography>
            <div className="flex_center " style={{ overflow: "hidden" }}>
              <div
                style={{
                  width: "100%",
                  // marginLeft: "30px",
                  marginTop: "-30px",
                }}
              >
                {loading?.investmentTypeDataLoading == false ? (
                  investmentTypeData.length > 0 ? (
                    <PieChart
                      config={{
                        innerRadius: "0.80",
                        cornerRadius: 0,
                        padAngle: 0,
                        legends: false,
                        minWith: "380px",
                        margin: { right: 200, top: 20, left: 30 },
                        disableArcLink: true,
                        chart: { width: 420, height: 340 },
                      }}
                      colors={invAllocation.colors}
                      onClickEvent={{
                        click: realisedGain_onClick,
                        set: setInvAllocation,
                        cstate: invAllocation,
                        ncolors: invAllocationColors,
                      }}
                      data={investmentTypeData}
                    >
                      <div className="cen" style={{ color: "#fff" }}>
                        <div>
                          <div>{invAllocation.title}</div>
                          <div>{invAllocation.value}</div>
                        </div>
                      </div>
                    </PieChart>
                  ) : (
                    <DataNotFound />
                  )
                ) : (
                  ""
                )}
                {ShowLegends(investmentTypeData, invAllocation.colors)}
              </div>
            </div>
          </div>
        </Grid>
      </div>

      <div
        style={{ minHeight: "500px", marginTop: "1%" }}
        className={classes.mainContent}
      >
        <div style={{ display: "flex" }}>
          <Typography
            className={`${classes.mfoGraphHeader} ${classes.typoInsideAlign}`}
            style={{ width: "50%" }}
          >
            Month on Month Investment Valuation <br></br> and Portfolio Trend
            <br></br>
            {sessionStorage.getItem("currency_format") != "actuals" &&
              sessionStorage.getItem("currency_format") && (
                <span
                  className="subtext capitalize"
                  style={{ fontSize: "16px" }}
                >
                  {" "}
                  (values in {sessionStorage.getItem("currency_format")})
                </span>
              )}
          </Typography>

          <div className="rangeAlignment">
            <RangeCalendar
              values={dateRange}
              onchange={(update) => {
                portfolio(update);
                setDateRange(update);
              }}
            />
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <Grid xs={6}>
            <TableContainer
              style={{
                maxHeight: "500px",
                overflowY: "scroll",
                width: "94%",
                margin: "0% 3%",
              }}
            >
              <Table
                style={{ borderCollapse: "separate" }}
                aria-label="simple table"
              >
                <TableHead
                  style={{
                    background: "#090416",
                    position: "sticky",
                    top: 0,
                  }}
                >
                  <TableRow>
                    <TableCell className={classes.tableHeaderTextNew}>
                      Month
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.tableHeaderTextNew}
                    >
                      Portfolio Value
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.tableHeaderTextNew}
                    >
                      Change Value
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.tableHeaderTextNew}
                    >
                      Change (%)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableCell
                    style={{
                      height: "5px",
                      padding: "0px",
                    }}
                  ></TableCell>
                  {loading?.monthOnMonthLoading == false
                    ? portfolioTrend.map((trend, k) => {
                        return (
                          <TableRow key={k}>
                            <TableCell
                              className={`${classes.tableBodyTextNew} ${classes.paddingLeft}`}
                              align="left"
                            >
                              {trend.month}
                            </TableCell>
                            <TableCell
                              className={classes.tableBodyTextNew}
                              align="right"
                            >
                              {currencyFormat(trend.portfolioValue)}
                            </TableCell>
                            <TableCell
                              className={classes.tableBodyTextNew}
                              align="right"
                            >
                              <div
                                style={{
                                  color:
                                    trend.changeAmount > 0
                                      ? "#35CBBF"
                                      : "#F52D6B",
                                }}
                              >
                                {currencyFormat(trend.changeAmount)}
                              </div>
                            </TableCell>
                            <TableCell
                              className={classes.tableBodyTextNew}
                              align="right"
                            >
                              <div
                                style={{
                                  color:
                                    trend.changePercentage > 0
                                      ? "#35CBBF"
                                      : "#F52D6B",
                                }}
                              >
                                {roundNumber(trend.changePercentage)}
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : ""}
                </TableBody>
              </Table>
            </TableContainer>
            {loading?.monthOnMonthLoading == false &&
              portfolioTrend?.length == 0 && <DataNotFound />}
          </Grid>

          <Grid xs={6}>
            <div>
              <BarChart
                tooltip={tooltip}
                colors={["#97e3d5", "#e25c3b"]}
                config={{
                  theme: theme,
                  height: "500px",
                  groupMode: "grouped",
                  layout: "horizontal",
                  padding: portfolioTrend.length < 4 ? 0.7 : 0.1,
                  disabledLegend: true,
                  labelY: "Time Period",
                  labelX: "Percentage Change",
                }}
                data={[...portfolioTrend].reverse()}
                keys={["gain_percent", "loss_percent"]}
                commonprops={{
                  ...divergingCommonProps,
                  minValue: minMax[0],
                  maxValue: minMax[1],
                  borderRadius: "3px",
                  markers: [
                    {
                      axis: "x",
                      value: 0,
                      lineStyle: { stroke: "#EFF1F3", strokeWidth: 1 },
                      legend: "",
                      legendOrientation: "vertical",
                    },
                  ],

                  renderTick: (tick) => {
                    return (
                      <g transform={`translate(${tick.x - 30},${tick.y})`}>
                        {/* <rect x={-14} y={-6} rx={3} ry={3} width={28} height={24} fill="rgba(0, 0, 0, .05)" />
                                <rect x={-12} y={-12} rx={2} ry={2} width={24} height={24} fill="rgb(232, 193, 160)" /> */}
                        {/* <line stroke="rgb(232, 193, 160)" strokeWidth={1.5} y1={-22} y2={-12} /> */}
                        {/* <rect x={-40} y={-15} rx={2} ry={2} width={100} height={3} fill="rgba(31, 28, 64, 1)" /> */}
                        <text
                          textAnchor="middle"
                          dominantBaseline="middle"
                          style={{
                            fill: "#b0aab7",
                            fontSize: 10,
                          }}
                        >
                          {tick.value} %
                        </text>
                      </g>
                    );
                  },
                }}
              />
            </div>
          </Grid>
        </div>
      </div>
      {/* <div className={classes.buttonLightBlueAlign}>
          <Button
            onClick={() => navigate("/home/mfo/mfo-summary/report/segment")}
            className={`${classes.buttonLightBlue} ${classes.tableBodyTextNew}`}
          >
            <span
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Eye />
              View Detail Report
            </span>
          </Button>
        </div> */}

      <PageLoader showLoader={Object.values(loading).includes(true)} />
    </>
  );
}

export default MutualFundSummary;
