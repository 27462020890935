import React from "react";
import DefaultSign from "../DefaultSign";
import "../PartnerOnboarding.css";
import ClientForm2 from "./ClientForm2";

export default function ClientStep2(props) {
  const { head } = props;

  const desc = head?.desc
    ? head?.desc
    : {
        center: [
          {
            title: "What’s a Partner?",
            description:
              "Holdings are the contents of an investment portfolio held by an individual or an entity, such as a mutual fund or a pension fund. Portfolio holdings may encompass a wide ",
          },
        ],
      };
  return (
    <>
      <DefaultSign left={7} right={5} desc={desc}>
        <ClientForm2 {...props} />
        {/* <div className="dFlex jtfyContCntr alignCntr h100 flexDirCol">
          <div className="newFormTopSec">
            <PartnerHeading
              mainHead={head?.main ? head?.main : "Add Family Head Details"}
              subtitle={
                head?.sub
                  ? head?.sub
                  : "Join us by providing your company details"
              }
              handleBack={handleBack}
              step={currentStep}
            />
          </div>
          <div className="newFormContainer">
            <Box
              className="dFlex jtfyContCntr alignCntr flexDirCol"
              sx={{ width: { xs: "auto", sm: "330px" }, gap: "41px" }}
            >
              
              <form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid xs={12} lg={12} item>
                    <CustomInput
                      // as={TextField}
                      select
                      fullWidth
                      title="Member type"
                      variant="outlined"
                      margin="normal"
                      // required
                      id="memberType"
                      label="Member Type"
                      name="memberType"
                      autoComplete="memberType"
                      // autoFocus
                      // type="select"
                      error={touched.memberType && Boolean(errors.memberType)}
                      helperText={touched.memberType && errors.memberType}
                      value={values.memberType}
                      onChange={handleChange}
                    >
                      {ENTITY_TYPES.map((entity) => {
                        return (
                          <MenuItem
                            key={entity.value}
                            value={entity.value}
                            selected={entity?.selected}
                            style={{
                              border: "0.34px solid #3E5389",
                              backgroundColor: "#131826",
                            }}
                          >
                            {entity.name}
                          </MenuItem>
                        );
                      })}
                    </CustomInput>
                  </Grid>
                  <Grid xs={12} lg={12} item>
                    <CustomInput
                      title="user role"
                      disabled={true}
                      // as={TextField}
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      id="userRole"
                      label="User Role"
                      name="userRole"
                      autoComplete="userRole"
                      autoFocus
                      error={touched.userRole && Boolean(errors.userRole)}
                      helperText={touched.userRole && errors.userRole}
                      value={values.userRole}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid xs={12} lg={12} item>
                    <CustomInput
                      title="Full Name"
                      // as={TextField}
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      id="fullName"
                      label="Full Name"
                      name="fullName"
                      autoComplete="fullName"
                      autoFocus
                      error={touched.fullName && Boolean(errors.fullName)}
                      helperText={touched.fullName && errors.fullName}
                      value={values.fullName}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid
                    xs={12}
                    lg={12}
                    item
                    // pr={{ xs: 0, sm: 0, md: 0, lg: 1 }}
                  >
                    <CustomInput
                      title="Mobile no."
                      // as={TextField}
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      id="mobile"
                      label="Mobile Number"
                      name="mobile"
                      error={touched.mobile && Boolean(errors.mobile)}
                      helperText={touched.mobile && errors.mobile}
                      value={values.mobile}
                      onChange={handleChange}
                      // maxLength={10}
                    />
                  </Grid>
                  <Grid
                    // xs={12}
                    // lg={12}
                    style={{ padding: "0px" }}
                    container
                    spacing={2}
                  >
                    <Grid
                      xs={12}
                      lg={6}
                      item
                      // style={{ paddingLeft: { xs: 0, sm: 0, md: 0, lg: 1 } }}
                    >
                      <CustomInput
                        title="PAN NO"
                        // as={TextField}
                        variant="outlined"
                        margin="normal"
                        // required
                        fullWidth
                        id="panNumber"
                        label="Pan Number"
                        name="panNumber"
                        error={touched.panNumber && Boolean(errors.panNumber)}
                        helperText={touched.panNumber && errors.panNumber}
                        value={values.panNumber}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid
                      xs={12}
                      lg={6}
                      item
                      // style={{ paddingLeft: { xs: 0, sm: 0, md: 0, lg: 1 } }}
                    >
                      <CustomInput
                        // as={TextField}
                        title={
                          "Date Of Birth"
                          //   `Date of ${
                          //   values?.entityType == "sole_proprietor"
                          //     ? "Birth"
                          //     : "incorporation"
                          // }`
                        }
                        variant="outlined"
                        margin="normal"
                        // required
                        fullWidth
                        id="dateOfBirth"
                        label={
                          "Date Of Birth"
                          //   `Date of
                          // ${
                          //   values?.entityType == "sole_proprietor"
                          //     ? "Birth"
                          //     : "incorporation"
                          // }`
                        }
                        name="dateOfBirth"
                        autoComplete="dateOfBirth"
                        autoFocus
                        type="date"
                        error={
                          touched.dateOfBirth && Boolean(errors.dateOfBirth)
                        }
                        helperText={touched.dateOfBirth && errors.dateOfBirth}
                        value={values.dateOfBirth}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>

                  <Grid xs={12} lg={12} item>
                    <CustomInput
                      // as={TextField}
                      title="EMAIL ID"
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      value={values.email}
                      onChange={handleChange}
                    />
                  </Grid>
                  <div className="newFormBottonSec">
                    <Grid xs={12} lg={12} style={{ marginTop: "30px" }} item>
                      <ErrorMessageComp errorMessage={apiErrorMessage} />
                      <LoadingButton
                        buttonTitle={"Continue"}
                        type="submit"
                        showLoader={loading}
                      />
                    </Grid>
                    <Grid xs={12} item style={{ marginTop: "28px" }}>
                      <StepProgressBar
                        total={totalStep}
                        currentStep={currentStep}
                      />
                    </Grid>
                  </div>
                </Grid>
              </form>
              
            </Box>
          </div>
        </div> */}
      </DefaultSign>
    </>
  );
}
