import React, { useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import ls from "localstorage-slim";
import {
  Box,
  FormControl,
  Grid,
  Select,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";

import { useLocation } from "react-router-dom";
// import FilterForm from "../../../../../pages/filterForm";
// import ScheduleReport from "../../../../../pages/ScheduleReport";
// import Reports from "./Reports";
import Default from "../../defaultPages/Default";
// import useStyles from "../common/ProductsStyles";
import NewTabComp from "../common/NewTabComp";
import {
  fetchAssetWiseProductTax,
  fetchClientByMultiId,
  fetchTaxRecords,
  fetchTaxSummary,
} from "../../../../api";
import { OnboardingContext } from "../../../../Context";
import {
  ENTITY_TYPE_CLIENT,
  ITEMS_LIMIT_TAX_LIST,
} from "../../../../Constants";
import MultiSelect from "../../common/MultiSelect";
import SingleSelect from "../../common/SingleSelect";
import moment from "moment";
import FileTabComp from "../common/FileTabComp";
import TaxTable from "../newDesigns/common/tax/TaxTable";
import { useEffect } from "react";
import { currencyFormat } from "../../../../hooks/constantFunction";
import { NestedCard } from "../../../common/HoldingSummaryCards/NestedCard";
import { MainCard } from "../../../common/HoldingSummaryCards/MainCard";
import AppPagination from "../../../verificationUpload/AppPagination";
import { roundNumber } from "../../../utils/common";
import {
  TaxdenominationOptions,
  denominationOptions,
} from "../../newFilter/newFilterConstants";
import Loader from "../../../loader/Loader";
import CustomizedSnackbars from "../../../common/CustomizedSnackbars";
const useStyles = makeStyles((theme) => ({
  mainCard: {
    //background: "linear-gradient(127deg, #4C1A97 14.75%, #7636D7 88.7%)",
    // background: "linear-gradient(146deg, #2B0D58 0%, #692FC0 100%)",
    background: theme.palette.cards.mainCard,
    borderRadius: "8px",
    minHeight: "156px",
  },
  // * This is the nested card inside the purple card
  nestedCard: {
    background: theme.palette.cards.innerCard,
    minHeight: "70px",
    borderRadius: "7px",
  },
  nestedCardBoxPadding: { padding: "5px", marginBottom: "-4.5px" },

  dividerText: {
    color: "#FFF",
    fontFamily: "Avenir",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "2.24px",
    textTransform: "uppercase",
    opacity: "0.5",
    marginRight: "10px",
  },
  contentBox: {
    background: "rgba(11, 3, 24, 0.6)",
    border: "1px solid #4E189F",
    boxShadow: "0px 0px 10px #4E189F",
    backdropFilter: "blur(50px)",
    borderRadius: "50px",
    position: "relative",
    // left: "10%",
    width: "80%",
    top: "20px",
    margin: "15px 20px 20px 20px",
    [theme.breakpoints.down("md")]: {
      left: "10%",
      width: "65%",
      top: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      left: "5%",
      width: "90%",
      top: "20px",
    },
  },
  mainContent: {
    padding: "15px 20px 20px 20px",
  },
  gridContainer: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  icon: {
    fill: "#FAF9FF",
  },
  text: {
    fontStyle: "normal",
    fontFamily: "Avenir",
  },
  inputField: {
    borderColor: " #711CF1",
    borderRadius: "2px",
    color: "#fff",
    width: "100%",
    "& .MuiInputBase-root": {
      color: "#ffffff",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "6px !important",
      border: "none !important",
      background: "#000 !important",
    },
  },
  inputGrid: {
    // marginTop: "20px",
  },
  formContent: {
    marginLeft: "15%",
    marginTop: "10px",
    height: "340px",
    overflowY: "scroll",
  },

  iconborder: {
    border: "1px solid #4E189F",
    backdropFilter: "blur(50px)",
    borderRadius: "30px",
    margin: "20px 5px 5px 5px",
    padding: "22px 55px 10px 44px",
  },
  uploadicon: {
    position: "absolute",
    top: "-30px",
    left: "-50px",
  },
  formControl: {
    // border: "1px solid #4E189F",
  },
  boxfileuploadContainer: {
    background: "#220848",
    border: "1px dashed #FFFFFF",
    boxShadow:
      "-6.22302px -6.22302px 18.6691px #302A64, 6.22302px 6.22302px 18.6691px #000000",
    borderRadius: "20px",
    // width: "98%",
  },
  line: {
    opacity: "0.2",
    backgroundColor: "#D4BBFB",
    border: ".5px solid #D4BBFB",
    marginBlock: "2em",
    width: "100%",
  },
  buttonContainer: {
    textAlign: "center",
    // marginTop: "5px",
    // marginBottom: "10px",
  },
  button: {
    paddingTop: "10px",
    // width: "80%",
    color: "rgb(255, 255, 255)",
    background:
      "linear-gradient(rgb(170, 111, 254) 0%, rgb(113, 28, 241) 100%)",
    borderRadius: "20px",
    paddingBottom: "10px",
    paddingInline: "6em",
    textTransform: "capitalize",
  },
  buttonText: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#FFFFFF",
  },
  nobuttonui: {
    color: "#fff",
    // background: "transparent",
    textAlign: "center",
    padding: "1% 36%",
    width: "100%",
    background: "#220848",
    height: "228px",
    borderRadius: "20px",
  },
  uploadsvg: {
    width: "13%",
    paddingTop: "10%",
  },
  downloadformat: {
    color: "#fff",
    fontSize: "16px",
    textAlign: "center",
  },
  downloadicon: {
    fontSize: "15px",
    padding: "0px 4px",
    verticalAlign: "bottom",
  },
  downloadtext: {
    color: "#3A56FF",
    cursor: "pointer",
  },
  customLinearProgress: {
    height: "2.98px !important",
    borderRadius: "10px !important",
    // backgroundColor: "red !important",
    "& .MuiLinearProgress-bar": {
      backgroundColor: "#B65BFF !important",
    },
  },
  selectDropDownMenu: {
    borderRadius: "4px !important",
    border: `1.164px solid ${theme.palette.selectDropDown.border} !important`,
  },
}));
const headerData = [
  {
    field: "name",
    title: "INSTRUMENT NAME",
    show: true,
    align: "center",
    col: 2,
    dontShowFirstColBorder: true, // ** NOTE TO REMOVE THE LEFT COLOR BORDER
  },
  {
    field: "isin",
    title: "ISIN",
    show: true,
    align: "center",
    col: 2,
  },
  {
    field: "noOfTransactions",
    title: "NO. OF TRANSACTIONS",
    show: true,
    align: "center",
    col: 2,
  },
  {
    field: "totalLtcg",
    title: "LTCG",
    show: true,
    align: "center",
    col: 1,
  },
  {
    field: "totalStcg",
    title: "STCG",
    show: true,
    align: "center",
    col: 2,
  },
  {
    field: "isin",
    title: "Status",
    show: true,
    align: "center",
    col: 1,
  },
];

function TaxWeb({}) {
  const assetTabs = [
    { page: "EQUITY", title: "EQUITY" },
    {
      page: "DEBT",
      title: "DEBT",
    },
    {
      page: "ALTERNATE",
      title: "ALTERNATE",
    },
    {
      page: "CASH",
      title: "CASH",
    },
  ];
  const [metadat, setMetaData] = useState();

  const [selectedAsset, setSelectedAsset] = useState();
  const {
    userState: { loginedUserData },
    dispatch,
  } = OnboardingContext();

  const params = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const classes = useStyles();
  const icons = theme.palette.icons;
  const Back = icons.backIcon;
  const myParam = new URLSearchParams(params.search).get("key");
  const { userState } = OnboardingContext();
  // console.log(myParam, "agjkagbj");
  const [page, setPage] = useState(myParam || "center");
  const [members, setMEmbers] = useState([]);
  const [year, setselectYear] = useState(moment().year());
  const [from, setselectFrom] = useState("Q1");
  const [to, setselectTo] = useState("Q4");
  const [summary, setSummary] = useState();
  const [expandTable, setExpandTable] = useState({});
  const [itemOffset, setItemOffset] = useState(0);
  const [totalTax, setTotalTax] = useState({
    ltcg: null,
    stcg: null,
  });
  const [showloading, setShowLoading] = useState({
    client: false,
    memberId: false,
  });
  const clientId = userState.loginedUserData.id;
  const [products, setProducts] = useState({});
  const [selectedMembers, setSelectedMembers] = useState();
  const [isinBased, setIsinBased] = useState({});
  const [tabs, setTabs] = useState([]);
  const [denominations, setDenomination] = useState("Actuals");
  const [resData, setResData] = useState({});
  const [client, setClient] = useState(
    loginedUserData?.user_type === ENTITY_TYPE_CLIENT
      ? [{ full_name: loginedUserData.name, id: loginedUserData.id }]
      : []
  );
  const boldrd = {
    fontSize: "24px",
    fontWeight: "900",
  };
  // const [filterModalOpen, setFilterModalOpen] = useState(false);
  const scopes = JSON.parse(ls.get("scopes"));
  const [key, setKey] = useState({ key: "full_name", id: "member_id" });
  const [toggle, setToggle] = useState(false);
  const [loader, setLoader] = useState(false);
  function resetData(d) {
    setSummary(generatedata(resData, headerData, d));
    Object.keys(expandTable).map((e) => {
      expandTable[e] = isinBased[e];
    });
  }
  function generatedata(data, header, denomination) {
    // console.log(generatedata, "generatedata");
    return data?.map((a) => {
      let data = header.map((h) => {
        let val = a[h.field];

        if (["totalLtcg", "totalStcg"].includes(h.field)) {
          val = currencyFormat(val, null, denomination || denominations);
        }
        if (h.title == "Status") {
          isinBased[a.isin] = generatedata2(
            a.records,
            headerData2,
            denomination
          );
          return {
            ...h,
            field: val,
            handleTogle: (e, val1) => {
              console.log(e, "event", val1);
              if (e) {
                setExpandTable({
                  ...expandTable,
                  [val1]: isinBased[val1],
                });
              } else {
                delete expandTable[val1];
                setExpandTable({ ...expandTable });
              }
            },
          };
        }
        return {
          ...h,

          field: val,
        };
      });

      return { data: data, isin: a["isin"] };
    });
  }
  const headerData2 = [
    {
      field: "saleDate",
      title: "Sale Date",
      show: true,
      align: "center",
      col: 1,
      bgColor: "#2E234E",
      dontShowFirstColBorder: true, // ** NOTE TO REMOVE THE LEFT COLOR BORDER
    },
    {
      field: "quantity",
      title: "Quantity",
      show: true,
      align: "center",
      col: 1,
      bgColor: "#2E234E",
    },
    {
      field: "saleRate",
      title: "Sale Rate",
      show: true,
      align: "center",
      col: 1,
      bgColor: "#2E234E",
    },
    {
      field: "saleAmount",
      title: "Sale AMT",
      show: true,
      align: "center",
      col: 1,
      bgColor: "#2E234E",
    },
    {
      field: "purchaseDate",
      title: "purchase date",
      show: true,
      align: "center",
      col: 1,
      bgColor: "#504275",
    },
    {
      field: "purchaseRate",
      title: "purchase rate",
      show: true,
      align: "center",
      col: 1,
      bgColor: "#504275",
    },
    {
      field: "purchaseAmount",
      title: "purchase AMT",
      show: true,
      align: "center",
      col: 1,
      bgColor: "#504275",
    },
    {
      field: "holdingPeriod",
      title: "days held",
      show: true,
      align: "center",
      col: 1,
      bgColor: "#504275",
    },
    {
      field: "ltcg",
      title: "LTCG",
      show: true,
      align: "center",
      col: 1,
      bgColor: "#251d3a",
    },
    {
      field: "stcg",
      title: "STCG",
      show: true,
      align: "center",
      col: 1,
      bgColor: "#251d3a",
    },
  ];
  function generatedata2(data, header, denomination) {
    return data?.map((a) => {
      let data = header.map((h) => {
        let val = a[h.field];

        if (
          [
            "saleRate",
            "saleAmount",
            "purchaseRate",
            "purchaseAmount",
            "ltcg",
            "stcg",
            "totalLtcg",
            "totalStcg",
          ].includes(h.field)
        ) {
          val = currencyFormat(
            val,
            { showDecimal: true },
            denomination || denominations
          );
        }
        if (h.field == "quantity") {
          val = roundNumber(val);
        }
        if (["saleDate", "purchaseDate"].includes(h.field)) {
          val = moment(val, "YYYY-MM-DD").format("DD-MM-YYYY");
        }

        return {
          ...h,

          field: val,
        };
      });

      return { data: data };
    });
  }
  let data = [
    {
      title: "Reports",
      page: "reports",
      path: "/home/reports?key=reports",
    },

    {
      title: "Report Center",
      page: "report-center",
      path: "/home/reports?key=report-center",
    },
    {
      title: "Report",
      page: "report",
      path: "/home/reports?key=report",
    },
  ];
  if (!scopes?.can_schedule_report) {
    data.push({
      title: "Schedule Report",
      page: "schedule-report",
      path: "/home/reports?key=schedule-report",
    });
  }
  const handleClick = (page) => {
    setPage(page);
  };

  function fetchMembers(id, search, client1) {
    if (search) {
    }

    let params = {
      status: "approved",
      search: search || "",
      clientId:
        loginedUserData?.user_type === "partner" ||
        loginedUserData?.user_type === "mfo-user"
          ? "all"
          : clientId,
      partnerId:
        loginedUserData?.user_type === "partner" ? loginedUserData?.id : "",
    };
    setShowLoading({ ...showloading, memberId: true });
    fetchClientByMultiId(params)
      .then((res) => {
        // console.log(res, "res");
        setShowLoading({ ...showloading, memberId: false });
        setMEmbers(res.data.data.result);
      })
      .catch((errors) => {
        dispatch({
          type: "UPDATE_SNACKBAR_DATA",
          payload: {
            success: false,
            error: true,
            showMsg: true,
            message: errors.response.data.error.message,
          },
        });
        setShowLoading({ ...showloading, memberId: false });
      });
  }
  useEffect(() => {
    fetchMembers();
  }, []);

  useEffect(() => {
    fetchAssetWiseProductTax().then((res) => {
      setProducts(res);
      setSelectedAsset(0);
    });
  }, []);

  function calQ(q, type) {
    switch (q) {
      case "Q1":
        if (type == "from") {
          return year - 1 + "-04-" + "01";
        } else {
          return moment(year - 1 + "-06-" + "01")
            .endOf("month")
            .format("YYYY-MM-DD");
        }

      case "Q2":
        if (type == "from") {
          return year - 1 + "-07-" + "01";
        } else {
          return moment(year - 1 + "-09-" + "01")
            .endOf("month")
            .format("YYYY-MM-DD");
        }

      case "Q3":
        if (type == "from") {
          return year - 1 + "-10-" + "01";
        } else {
          return moment(year - 1 + "-12-" + "01")
            .endOf("month")
            .format("YYYY-MM-DD");
        }

      case "Q4":
        if (type == "from") {
          return year + "-01-" + "01";
        } else {
          return moment(year + "-03-" + "01")
            .endOf("month")
            .format("YYYY-MM-DD");
        }

      default:
        break;
    }
  }

  useEffect(() => {
    if (page && selectedMembers) {
      let startDate = calQ(from, "from");
      let endDate = calQ(to, "end");

      let params = {
        startDate,
        endDate,
        memberId: selectedMembers.member_id,

        limit: ITEMS_LIMIT_TAX_LIST,
        offset: itemOffset,
        assetClass: assetTabs[selectedAsset || 0].title.toLocaleLowerCase(),
      };

      // to

      setLoader(true);
      fetchTaxRecords(selectedMembers.client_id + "" || clientId, params, page)
        .then((res) => {
          console.log(res, "res");
          setLoader(false);
          setMetaData(res.meta);
          setResData(res.result);
          setExpandTable({});
          setIsinBased({});
          setSummary(generatedata(res.result, headerData));
        })
        .catch((err) => {
          setSummary([]);
          dispatch({
            type: "UPDATE_SNACKBAR_DATA",
            payload: {
              success: false,
              error: true,
              showMsg: true,
              message: err.response.data.error.message,
            },
          });
          setLoader(false);
        });
    }
  }, [page, itemOffset, toggle]);

  useEffect(() => {
    if (!selectedMembers) {
      return;
    }
    setItemOffset(0);
    setToggle(!toggle);
    let startDate = calQ(from, "from");
    let endDate = calQ(to, "end");

    let params = {
      startDate,
      endDate,
      memberId: selectedMembers.member_id,
    };

    // to

    fetchTaxSummary(
      selectedMembers.client_id + "" || clientId,
      params,
      "dashboard"
    ).then((res) => {
      console.log(res, "taxxxx");
      let total = {};
      res.result.map((a) => {
        total[a.capitalGainType] = a.taxLiability;
      });
      setTotalTax(total);
    });
  }, [selectedMembers, year, from, to]);

  useEffect(() => {
    let asset = assetTabs[selectedAsset || 0]?.page;
    if (Object.keys(products).length > 0) {
      setTabs(
        Object.keys(products?.[asset.toLowerCase()])?.map((p, i) => {
          if (i == 0) {
            setPage(() => products[asset.toLowerCase()][p]);
            setToggle(!toggle);
          }
          return {
            page: products[asset.toLowerCase()][p],
            title: p,
          };
        })
      );
    }
  }, [selectedAsset]);

  const handleAsncChange = (name, value, valuez, option) => {
    console.log(name, value, valuez, "name and value");

    if (typeof value == "string" || typeof value == "number") {
      console.log("ramvalue1", value);
      let val = option.find((v) => {
        if (v?.[key.id] == value) {
          return true;
        }
      });

      setSelectedMembers(val ? val : []);
      // console.log(val, "value ww");
      //   setFieldValue(name, val ? val : []);
      //   setFormdata({ ...formData, [name]: val });
      // } else {
      //   console.log("ramvalue2", value);
      //   setFieldValue(name, value);
      //   setFormdata({ ...formData, [name]: value });
      // }
    }
  };

  return (
    <Default>
      <div>
        {/* <PermissionsGate scopes={[scopes?.can_view_my_portfolio_filter]}>
          <PageFilter
            backLink={`/home/portfolio`}
            setFilterModalOpen={setFilterModalOpen}
          />
        </PermissionsGate> */}

        {/* <NewFilter
          filterModalOpen={filterModalOpen}
          setFilterModalOpen={setFilterModalOpen}
          productType={pageName}
        /> */}

        <div style={{ margin: "2%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingBottom: page == "report" ? "68px" : "31px",
            }}
          >
            <Back
              style={{
                cursor: "pointer",
                marginRight: "12px",
                flexShrink: 0,
                display: page != "report" ? "none" : "",
              }}
              onClick={() => {
                window.location.href = "/home/reports";
              }}
            />
            <Typography
              className={`${classes.mainHeading} ${classes.mainHeadingAlign}`}
              style={{ fontSize: "20px" }}
            >
              TAX
            </Typography>
          </div>

          <div style={{ width: "100%", display: "flex", gap: "20px" }}>
            {/* <MultiSelect
                  single={true}
                  styles={{ opacity: 1, border: "0px solid black" }}
                  disableClassName="true"
                  data={[...members]}
                  getSearchData={(value, client1) =>
                    fetchMembers(null, value, client1)
                  }
                  client={client}
                  values={selectedMembers}
                  setValues={setSelectedMembers}
                  // errors={errors.memberId}
                  searchLoader={showloading.memberId}
                  placeholder={"Select Members"}
                  label="memberId"
                  optionTitle="full_name"
                  fieldName="memberId"
                  objectFieldName="member_id"
                  handleChange={(name, value1, valuez) => {
                    handleAsncChange(name, value1, valuez);
                  }}
                /> */}
            <>
              <Grid xs={4} lg={4} item>
                <p className="selectDropDownLabel">Select Member</p>
                <SingleSelect
                  // getSearchData={() => {}}
                  getSearchData={(value, client1) =>
                    fetchMembers(null, value, client1)
                  }
                  data={[...members]}
                  styles={{ opacity: 1, border: "0px solid black" }}
                  searchLoader={false}
                  // disable={
                  //   loginedUserData?.user_type === ENTITY_TYPE_CLIENT
                  //     ? true
                  //     : false
                  // }
                  // errors={errors}
                  // touch={touched}
                  label={key.key}
                  optionTitle={key.key}
                  fieldName="member"
                  objectFieldName={key.id}
                  value={selectedMembers}
                  handleChange={(name, value1, valuez) => {
                    handleAsncChange(name, value1, valuez, members);
                    // if (typeof value1 == "string" || typeof value1 == "number") {
                    //   let val = familyMembers.find((v) => {
                    //     if (v.id == value1) {
                    //       return true;
                    //     }
                    //   });

                    //   setClient(val ? val : []);
                    //   setFieldValue(name, val);
                    // }
                    // setSelectedMembers([]);
                    // setFieldValue("memberId", []);
                  }}
                />
              </Grid>
              <Grid xs={3} lg={3}>
                <FormControl
                  variant="outlined"
                  className={`${classes.formControl} ${classes.inputField}`}
                  style={{ position: "relative" }}
                >
                  <p className="selectDropDownLabel">Financial Year</p>
                  <Select
                    styles={{
                      opacity: 1,
                      border: "0px solid black",
                      color: "#FAF9FF",
                    }}
                    sx={{ color: "#fff" }}
                    displayEmpty
                    renderValue={
                      year !== ""
                        ? undefined
                        : () => (
                            <span style={{ color: "#59595B" }}>
                              Select Year
                            </span>
                          )
                    }
                    // native
                    // style={{ color: "#FAF9FF" }}
                    labelId="demo-simple-select-outlined-label"
                    // label="Select Product"
                    value={year}
                    onChange={(e) => {
                      setselectYear(e.target.value);
                    }}
                    inputProps={{
                      name: "type",
                      id: "outlined-entity_type-native-simple",

                      classes: {
                        icon: classes.icon,
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          backgroundColor: "#1e0d2d",
                          border: `1.164px solid ${theme.palette.selectDropDown.border}`,
                          marginTop: "1rem",
                        },
                      },
                      MenuListProps: {
                        className: classes.selectDropDownMenu,
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {Array.from(Array(10).keys()).map((num) => {
                      let year = moment().add(1, "year").year();
                      return (
                        <option
                          className="optiondesignNew"
                          key={year - 1}
                          value={year - num}
                        >
                          {year - num - 1} - {year - num}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={2} lg={2}>
                <FormControl
                  variant="outlined"
                  className={`${classes.formControl} ${classes.inputField}`}
                  style={{ position: "relative" }}
                >
                  <p className="selectDropDownLabel">From</p>
                  <Select
                    styles={{
                      opacity: 1,
                      border: "0px solid black",
                      color: "#FAF9FF",
                    }}
                    sx={{ color: "#fff" }}
                    displayEmpty
                    renderValue={
                      year !== ""
                        ? undefined
                        : () => <span style={{ color: "#59595B" }}>Select</span>
                    }
                    // native
                    // style={{ color: "#FAF9FF" }}
                    labelId="demo-simple-select-outlined-label"
                    // label="Select Product"
                    value={from}
                    onChange={(e) => {
                      setselectFrom(e.target.value);
                    }}
                    inputProps={{
                      name: "type",
                      id: "outlined-entity_type-native-simple",

                      classes: {
                        icon: classes.icon,
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          backgroundColor: "#1e0d2d",
                          border: `1.164px solid ${theme.palette.selectDropDown.border}`,
                          marginTop: "1rem",
                        },
                      },
                      MenuListProps: {
                        className: classes.selectDropDownMenu,
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {Array.from(Array(4).keys()).map((num) => {
                      return (
                        <option
                          className="optiondesignNew"
                          key={"Q" + (num + 1)}
                          value={"Q" + (num + 1)}
                        >
                          {"Q" + (num + 1)}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={2} lg={2}>
                <FormControl
                  variant="outlined"
                  className={`${classes.formControl} ${classes.inputField}`}
                  style={{ position: "relative" }}
                >
                  <p className="selectDropDownLabel">To</p>
                  <Select
                    styles={{
                      opacity: 1,
                      border: "0px solid black",
                      color: "#FAF9FF",
                    }}
                    sx={{ color: "#fff" }}
                    displayEmpty
                    renderValue={
                      year !== ""
                        ? undefined
                        : () => <span style={{ color: "#59595B" }}>Select</span>
                    }
                    // native
                    // style={{ color: "#FAF9FF" }}
                    labelId="demo-simple-select-outlined-label"
                    // label="Select Product"
                    value={to}
                    onChange={(e) => {
                      setselectTo(e.target.value);
                    }}
                    inputProps={{
                      name: "type",
                      id: "outlined-entity_type-native-simple",

                      classes: {
                        icon: classes.icon,
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          backgroundColor: "#1e0d2d",
                          border: `1.164px solid ${theme.palette.selectDropDown.border}`,
                          marginTop: "1rem",
                        },
                      },
                      MenuListProps: {
                        className: classes.selectDropDownMenu,
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {Array.from(Array(4).keys()).map((num) => {
                      return (
                        <option
                          className="optiondesignNew"
                          key={"Q" + (num + 1)}
                          value={"Q" + (num + 1)}
                        >
                          {"Q" + (num + 1)}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={2} lg={2}>
                <FormControl
                  variant="outlined"
                  className={`${classes.formControl} ${classes.inputField}`}
                  style={{ position: "relative" }}
                >
                  <p className="selectDropDownLabel">Denomination</p>
                  <Select
                    styles={{
                      opacity: 1,
                      border: "0px solid black",
                      color: "#FAF9FF",
                    }}
                    sx={{ color: "#fff" }}
                    displayEmpty
                    renderValue={
                      year !== ""
                        ? undefined
                        : () => <span style={{ color: "#59595B" }}>Select</span>
                    }
                    // native
                    // style={{ color: "#FAF9FF" }}
                    labelId="demo-simple-select-outlined-label"
                    // label="Select Product"
                    value={denominations}
                    onChange={(e) => {
                      setDenomination(e.target.value);
                      resetData(e.target.value);
                    }}
                    inputProps={{
                      name: "type",
                      id: "outlined-entity_type-native-simple",

                      classes: {
                        icon: classes.icon,
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          backgroundColor: "#1e0d2d",
                          border: `1.164px solid ${theme.palette.selectDropDown.border}`,
                          marginTop: "1rem",
                        },
                      },
                      MenuListProps: {
                        className: classes.selectDropDownMenu,
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {TaxdenominationOptions.map((product) => {
                      return (
                        <option
                          className="optiondesignNew"
                          key={product.value}
                          value={product.value}
                        >
                          {product.name}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              {/* {showError(
                    (!values.client || values.client == "") && touched.client,
                    errors.client,
                    true
                  )} */}
            </>

            {/* </div> */}
          </div>
          <Grid
            item
            xs={12}
            style={{ marginTop: "41px", marginBottom: "19px" }}
          >
            <section id="title-wrapper">
              <div class="title" className={classes.dividerText}>
                TAX LIABILITY
              </div>
              <div class="dividerLine"></div>
            </section>
          </Grid>
          <div style={{ display: "flex", gap: "13px", marginBottom: "53px" }}>
            <Grid item xs={6}>
              <MainCard className="rdMainCard">
                <NestedCard
                  className="rdNestedCard"
                  style={{
                    background:
                      "linear-gradient(269deg, rgba(33, 24, 57, 0.5) 0%, rgba(9, 4, 24, 0.5) 83.33%)",
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.nestedCardBoxPadding}
                  >
                    <h1 className="rdcardText rdCardHeadingTxt">LTCG</h1>
                    <p className="rdcardText rdCardValueTxt" style={boldrd}>
                      {totalTax.ltcg != null
                        ? currencyFormat(totalTax.ltcg, null, denominations)
                        : "..."}
                    </p>
                  </Box>
                </NestedCard>
              </MainCard>
            </Grid>
            <Grid item xs={6}>
              <MainCard className="rdMainCard">
                <NestedCard
                  className="rdNestedCard"
                  style={{
                    background:
                      "linear-gradient(269deg, rgba(33, 24, 57, 0.5) 0%, rgba(9, 4, 24, 0.5) 83.33%)",
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.nestedCardBoxPadding}
                  >
                    <h1 className="rdcardText rdCardHeadingTxt">STCG</h1>
                    <p className="rdcardText rdCardValueTxt" style={boldrd}>
                      {totalTax.stcg != null && denominations
                        ? currencyFormat(totalTax.stcg, null, denominations)
                        : "..."}
                    </p>
                  </Box>
                </NestedCard>
              </MainCard>
            </Grid>
          </div>
          <FileTabComp
            data={assetTabs}
            handleClick={(val) => {
              setSelectedAsset(val.slecetedIndex);
            }}
            seleted={selectedAsset}
          />
          {tabs.length > 0 && (
            <div
              style={{
                marginBlock: "2%",
                marginLeft: "35px",
                display: page == "report" ? "none" : "",
              }}
            >
              <NewTabComp
                data={tabs}
                customSelect={tabs?.findIndex((val) => val?.page == page)}
                customHandle={handleClick}
              />
            </div>
          )}
          {/* <div>{handleChange(page)}</div> */}
          <div
            style={{
              marginLeft: "20px",
              position: "relative",
            }}
          >
            {loader && (
              <div style={{ height: "200px", position: "relative" }}>
                {" "}
                <Loader style={{ marginLeft: "", marginTop: "-2%" }}></Loader>
              </div>
            )}
            {!loader && (
              <TaxTable
                data={summary}
                hideNavButtons={true}
                headerData2={headerData2}
                expandTable={expandTable}
              ></TaxTable>
            )}
          </div>
          <div className="paginationstyl">
            <div>
              <AppPagination
                pageCount={
                  Math.ceil(metadat?.count / ITEMS_LIMIT_TAX_LIST) || 0
                }
                itemLimit={ITEMS_LIMIT_TAX_LIST}
                setItemOffset={setItemOffset}
                otherProps={{ shape: "rounded" }}
              />
            </div>
          </div>
        </div>
      </div>
      <CustomizedSnackbars />
    </Default>
  );
}

export default TaxWeb;
