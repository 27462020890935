import React, { useState, useEffect } from "react";
import "./NewFilter.css";
import NewTabComp from "../products/common/NewTabComp";
import FullWidthInput2 from "../../common/FullWidthInput2";
import { NewFilterMemberChip } from "./NewFilterMemberChip";
import { newFilterSubTab } from "./newFilterConstants";
import { useAllClients } from "./hooks/useAllClients";
import { CheckedSvgIcon } from "./common/CheckedSvgIcon";
import { useTheme } from "@material-ui/core";
import { useNewFilterStyles } from "./newFilterStyles";

export const NewFilterFamilyMember = ({
  selectedFamilyMembers,
  setSelectedFamilyMembers,
  familyMembers,
  setClientName,
  setFamilyMembers,
  setSubTabElement,
  subTabElement,
}) => {
  // const [subTabElement, setSubTabElement] = useState("individual");
  const theme = useTheme();
  const classes = useNewFilterStyles();
  const handleSubTabClick = (page) => {
    setSubTabElement(page);
  };
  // console.log("subTabElement", subTabElement);

  const handleCheck = (member_id) => {
    if (
      selectedFamilyMembers.length === 1 &&
      selectedFamilyMembers.some((member) => member.member_id === member_id)
    ) {
      // If there's only one selected member, do not allow deselection
      return;
    }

    const memberIndex = selectedFamilyMembers.findIndex(
      (member) => member.member_id === member_id
    );
    if (memberIndex !== -1) {
      // Deselect
      setSelectedFamilyMembers(
        selectedFamilyMembers.filter((member, index) => index !== memberIndex)
      );
    } else {
      // Select
      const memberToToggle = familyMembers.find(
        (member) => member.member_id === member_id
      );
      setSelectedFamilyMembers([...selectedFamilyMembers, memberToToggle]);
    }
  };

  const changePage = (clickedTab) => {
    switch (clickedTab) {
      case "individual":
        return (
          <NewFilterMemberChip
            familyMembers={familyMembers?.filter(
              (member) => member?.role === "individual"
            )}
            setFamilyMembers={setFamilyMembers}
            selectedFamilyMembers={selectedFamilyMembers}
            setSelectedFamilyMembers={setSelectedFamilyMembers}
            handleCheck={handleCheck}
          />
        );
      case "non_individual":
        return (
          <NewFilterMemberChip
            familyMembers={familyMembers?.filter(
              (member) => member?.role === "non_individual"
            )}
            setFamilyMembers={setFamilyMembers}
            selectedFamilyMembers={selectedFamilyMembers}
            setSelectedFamilyMembers={setSelectedFamilyMembers}
            handleCheck={handleCheck}
          />
        );
      case "head_of_family":
        return (
          <NewFilterMemberChip
            familyMembers={familyMembers?.filter(
              (member) => member?.is_head_of_family == "1"
            )}
            setFamilyMembers={setFamilyMembers}
            selectedFamilyMembers={selectedFamilyMembers}
            setSelectedFamilyMembers={setSelectedFamilyMembers}
            handleCheck={handleCheck}
          />
        );
      default:
        return (
          <NewFilterMemberChip
            familyMembers={familyMembers?.filter(
              (member) => member?.role === "individual"
            )}
            setFamilyMembers={setFamilyMembers}
            selectedFamilyMembers={selectedFamilyMembers}
            setSelectedFamilyMembers={setSelectedFamilyMembers}
            handleCheck={handleCheck}
          />
        );
    }
  };

  return (
    <>
      {/* Selected Member List */}
      <div className="selectedMembersListView">
        {selectedFamilyMembers?.length === 0 && (
          <p className="selectedMembersListViewHeading">
            + Select the members for the consolidated portfolio view.
          </p>
        )}
      </div>
      <div className="selectedMemberLabelContainer">
        {selectedFamilyMembers?.map((selectedFamilyMember, index) => (
          <div
            key={selectedFamilyMember?.member_id}
            className="selectedMemberLabelWithCheckIcon"
            onClick={() => handleCheck(selectedFamilyMember?.member_id)}
          >
            <CheckedSvgIcon />
            <h1 className={classes.selectedMemberName}>
              {selectedFamilyMember?.full_name}
            </h1>
          </div>
        ))}
      </div>

      {/* Sub Tab And Search Bar */}
      <div className="subTabWithSearchBar">
        <NewTabComp
          data={newFilterSubTab}
          customWidth={"43%"}
          customHandle={handleSubTabClick}
          disableArrow={true}
        />
        <FullWidthInput2
          customInputStyle={{
            height: "48px",
            background: theme.palette.newFilter.newFilterSearch,
            width: "352px",
          }}
          customWrapperStyle={{ marginBottom: "0px !important" }}
          setSearch={setClientName}
        />
      </div>

      <div className="memberChipListingContainer">
        {changePage(subTabElement)}
        {/* <NewFilterMemberChip
          familyMembers={familyMembers}
          setFamilyMembers={setFamilyMembers}
          selectedFamilyMembers={selectedFamilyMembers}
          setSelectedFamilyMembers={setSelectedFamilyMembers}
          handleCheck={handleCheck}
        /> */}
      </div>
    </>
  );
};
