import React from "react";
import useStyles from "../common/ProductsStyles";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import { ReactComponent as Expand } from "../../../../assets/icons/expand.svg";
import { ReactComponent as EditColumn } from "../../../../assets/icons/edit-column.svg";

import DataNotFound from "../common/DataNotFound";

function HoldingTopHoldings() {
  const classes = useStyles();

  return (
    <>
      <div
        style={{ position: "relative", paddingBottom: "5%" }}
        className={classes.mainContent}
      >
        <div className={`${classes.alignTopHeader}`}>
          <Typography className={`${classes.mainHeading} `}>
            Top 10 Holdings
          </Typography>

          <div style={{ gap: "10px" }} className={`${classes.iconAlign}`}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <button
                style={{ width: "auto", margin: "auto" }}
                className={` expand textgradientpurple`}
              >
                <Expand /> Expand
              </button>
            </div>
            <EditColumn className={classes.iconStyle} />
          </div>
        </div>
        <Grid xs={12}>
          <div className={classes.tableContainerAlign}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    style={{ borderRadius: "20px 0px 0px 20px" }}
                    className={classes.tableHeadText}
                  >
                    Scheme Segment
                  </TableCell>
                  <TableCell align="right" className={classes.tableHeadText}>
                    Invested Value
                  </TableCell>
                  <TableCell align="right" className={classes.tableHeadText}>
                    Withdrawls
                  </TableCell>
                  <TableCell align="right" className={classes.tableHeadText}>
                    Net Invested Amount
                  </TableCell>
                  <TableCell align="right" className={classes.tableHeadText}>
                    Balance Units
                  </TableCell>
                  <TableCell align="right" className={classes.tableHeadText}>
                    Present Value
                  </TableCell>
                  <TableCell align="right" className={classes.tableHeadText}>
                    Unrealized Gain/Loss
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ borderRadius: "0px 20px 20px 0px" }}
                    className={classes.tableHeadText}
                  >
                    Realized Gain/Loss
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left" className={classes.tableBodyText}>
                    1
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </Grid>
      </div>
    </>
  );
}

export default HoldingTopHoldings;
