import React, { useState, useEffect, useMemo, useContext } from "react";
import { Button, Grid, Typography, Modal, Tooltip } from "@material-ui/core";
import TabBondSub from "./TabBondSub";
import useStyles from "../common/ProductsStyles";
import moment from "moment";
import RangeCalendar from "../../../calendar/RangeCalendar";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarMonthIcon, CalendarToday } from "@material-ui/icons";
import LineChart from "../../../charts/LineChart";

import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import { OnboardingContext } from "../../../../Context";
import { fetchFundPerformance, fetch_all_fund_bond } from "../../../../api";
import CheckBoxWithColor from "../common/CheckBoxWithColor";
import SearchInput from "../common/SearchInput";
import DataNotFound from "../common/DataNotFound";
import { dateFormatCommon, roundNumber } from "../../../utils/common";
import FilterCalendar from "../../../calendar/FilterCalendar";
import PageLoader from "../common/pageLoader";
import Loader from "../../../loader/Loader";

export default function BondsComparison() {
  const classes = useStyles();
  const [xval, setxval] = useState(
    generateDates(moment().subtract(1, "months"), "days", 1)
  );
  const [dataRange, setDataRange] = useState([
    moment().subtract(3, "months").format("YYYY-MM-DD"),
    moment().format("YYYY-MM-DD"),
  ]);
  const [chartload, setchartload] = useState(false);

  const [range, setRange] = useState("3M");
  const [legends, setlegends] = useState([]);
  const lineColors = [" #82EBA1", "#711CF1", " #FC5555", "#FF9446"];
  const { userState } = OnboardingContext();
  const clientId = userState.loginedUserData.id;
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const [mutualFunds, setMutualFunds] = useState([]);
  const [search, setSearch] = useState(null);
  const [lineData, setLineData] = useState(null);
  const [googleData, setgoogleData] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [load, setLoad] = useState(true);
  const [selectedIsin, setSelectedIsin] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [filteredData, setFilteredData] = useState([]); // filtered data for graph based on google contol range
  const [filterRange, setFilterRange] = useState([]); // filter range google contol on change
  const [clear, setClear] = useState(false);
  const [clearSh, setClearSh] = useState(false);
  // {
  //   startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
  //   endDate: moment().format("YYYY-MM-DD"),
  // }
  useMemo(() => fundPerformance(selectedIsin), [range, dataRange]);
  function generateDates(dateStart, type, count) {
    var dateEnd = moment();

    var timeValues = [];

    while (
      dateEnd > dateStart ||
      dateStart.format("DD-MM-YYYY") === dateEnd.format("DD-MM-YYYY")
    ) {
      timeValues.push(dateStart.format("YYYY-MM-DD"));
      dateStart.add(count, type);
    }
    return timeValues;
  }

  // useEffect(() => {
  //   if (filterRange?.range?.start) {
  //     filteringData();
  //   }
  // }, [filterRange?.range?.start, filterRange?.range?.end]);
  // useMemo(() => {
  //   setDataRange({ startDate: moment(mfoFilter.startDate).format("YYYY-MM-DD"), endDate: moment(mfoFilter.endDate).format("YYYY-MM-DD") })
  //   setRange(false)
  // }, [mfoFilter.startDate, mfoFilter.endDate])
  const config = {
    enablePoints: true,
    curve: "linear",
    disablePointLabel: true,
    disableAxisBottom: true,
    pointSize: 0.1,
    nopoint: true,
    hide_legends: true,
    leftAxis: {
      legend: "",
      renderTick: (tick) => {
        return (
          <g transform={`translate(${tick.x - 30},${tick.y})`}>
            {/* <rect x={-14} y={-6} rx={3} ry={3} width={28} height={24} fill="rgba(0, 0, 0, .05)" />
                        <rect x={-12} y={-12} rx={2} ry={2} width={24} height={24} fill="rgb(232, 193, 160)" /> */}
            {/* <line stroke="rgb(232, 193, 160)" strokeWidth={1.5} y1={-22} y2={-12} /> */}
            {/* <rect x={-40} y={-15} rx={2} ry={2} width={100} height={3} fill="rgba(31, 28, 64, 1)" /> */}
            <text
              textAnchor="middle"
              dominantBaseline="middle"
              style={{
                fill: "#b0aab7",
                fontSize: 10,
              }}
            >
              {tick.value}
            </text>
          </g>
        );
      },
    },
    extra_props: {
      enableSlices: "x",
      sliceTooltip: (data) => {
        // console.log(data);
        return (
          <div
            style={{
              padding: 7,
              color: "#FFFFFF",
              background: "#2A274F",
              borderRadius: "10px",
            }}
          >
            {/* <br /> */}
            <div
              className="subtext"
              style={{
                display: "flex",
                fontFamily: "Avenir",
                paddingLeft: "25px",
                paddingRight: "19px",
                lineHeight: "24px",
              }}
            >
              {/* <div style={{background:data.point.serieColor,width:"20px",height:"20px",borderRadius:"10px",marginRight:"5px"}}></div> */}
              {data.slice.points[0].data.x
                ? dateFormatCommon(data.slice.points[0].data.x, "YYYY-MM-DD")
                : ""}
            </div>
            {data.slice.points.map((d, i) => {
              return (
                <div
                  style={{
                    display: "flex",
                    fontFamily: "Avenir",
                    paddingLeft: "25px",
                    paddingRight: "19px",
                    lineHeight: "24px",
                  }}
                >
                  {/* <div style={{background:data.point.serieColor,width:"20px",height:"20px",borderRadius:"10px",marginRight:"5px"}}></div> */}
                  <div
                    style={{
                      width: "300px",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {d.data.id}
                  </div>{" "}
                  : {roundNumber(d?.data?.z)}{" "}
                </div>
              );
            })}
          </div>
        );
      },
    },
  };

  useEffect(() => {
    if (!search) {
      setLoad(true);
      fetch_all_fund_bond(clientId, {})
        .then((res) => {
          let result = res.result;

          setMutualFunds(result);
          let isinmap = {};
          let selectedisin = [
            result[0]?.isin,
            result[1]?.isin,
            result[2]?.isin,
            result[3]?.isin,
          ];

          if (selectedIsin.length === 0 && result.length > 0) {
            if (result.length > 4) {
              setSelectedIsin(selectedisin);
              isinmap[result[0]["isin"]] = result[0].company_name;
              isinmap[result[1]["isin"]] = result[1].company_name;
              isinmap[result[2]["isin"]] = result[2].company_name;
              isinmap[result[3]["isin"]] = result[3].company_name;

              fundPerformance(selectedisin, isinmap);
            } else {
              setSelectedIsin([result[0].isin]);
              isinmap[result[0]["isin"]] = result[0].company_name;

              fundPerformance(selectedisin, isinmap);
            }
          } else {
            setLoad(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoad(false);
        });
    }
  }, [mfoFilter]);
  useEffect(() => {
    if (search) {
      searchFund();
    }
  }, [search]);
  function searchFund() {
    setLoad(false);

    fetch_all_fund_bond(clientId, { search: search, limit: 300, offset: 0 })
      .then((res) => {
        setSearchList(res.result);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
      });
  }
  async function fundPerformance(selectedisin, isinmap) {
    if (selectedisin.length == 0) {
      setLoad(false);
      return;
    }
    let params = { assetClass: getfilter_as_params().assetClass };
    if (Object.keys(mfoFilter.checkData).length > 0) {
      params["memberId"] = Object.keys(mfoFilter.checkData).join(",");
    }
    if (Object.keys(mfoFilter.advisor).length > 0) {
      params["advisor"] = Object.keys(mfoFilter.advisor).join(",");
    }
    setLoad(true);
    if (dataRange[0] != null) {
      params["startDate"] = moment(dataRange[0]).format("YYYY-MM-DD");
      params["endDate"] = moment(dataRange[1]).format("YYYY-MM-DD");
    }
    const isin = selectedisin.map((isin) => isin);
    try {
      const responses = await fetchFundPerformance(
        clientId,
        {
          ...params,
          isin: isin.join(","),
        },
        { type: "bonds" }
      );

      let legendss = [];
      let current = [];
      let gdata = {};
      let jn = 0;
      let result = responses.data.data.result.map((res, j) => {
        jn = j + 1;
        legendss.push(res.fundName);
        let data = res.graphData.map((r, i) => {
          gdata[r.date]
            ? (gdata[r.date]["value" + j] = r.fundNav)
            : (gdata[r.date] = { ["value" + j]: r.fundNav });
          return {
            id: res.fundName,
            x: r.date,
            y: r.rebaseNav,
            z: r.fundNav,
          };
        });
        return {
          id:
            res.fundName.length > 30
              ? res.fundName.substring(0, 30) + "..."
              : res.fundName,
          color: "hsl(240, 70%, 50%)",
          isin: res.isin,
          data: data ? data : [],
        };
      });
      setlegends(legendss);

      // jn = 4 - jn;

      let gdataarr = [
        ["Date", "Value", "value1", "value2", "value3"].splice(0, jn + 1),
      ];
      Object.keys(gdata).map((key) => {
        let g = Object.values(gdata[key]);
        gdataarr.push([new Date(key), ...g]);
      });
      setgoogleData(gdataarr);
      result = result.filter((item) => item);
      setLineData(result);
      setFilteredData(result);
      setchartload(result?.length === 0 ? true : false);

      // setCurrentList(currentList);
    } catch (error) {
      setLoad(false);
    }
    setLoad(false);
  }
  const hideAlert = () => {
    setTimeout(() => {
      const customDiv = document.getElementById("customAlert");
      customDiv.style.display = "none";
    }, 2000);
  };
  const handleCheckBoxClick = (e, col) => {
    if (e.target.checked && selectedIsin.length < 4) {
      selectedIsin.push(e.target.value);
      // isinMap[e.target.value] = e.target.name;
      setSelectedIsin([...selectedIsin]);
      // setIsinMap({ ...isinMap });
      fundPerformance(selectedIsin);
      if (search) {
        let found = mutualFunds.find((m, k) => {
          if (m.isin == e.target.value) {
            mutualFunds.splice(k, 1);
            mutualFunds.unshift(col);
            mutualFunds.forEach((m, j) => {
              if (selectedIsin.includes(m.isin) && j != 0) {
                mutualFunds.splice(j, 1);
                mutualFunds.unshift(m);
              }
            });
            setMutualFunds([...mutualFunds]);
            return true;
          }
        });
        if (!found) {
          mutualFunds.unshift(col);
          mutualFunds.forEach((m, j) => {
            if (selectedIsin.includes(m.isin) && j != 0) {
              mutualFunds.splice(j, 1);
              mutualFunds.unshift(m);
            }
          });
          setMutualFunds([...mutualFunds]);
        }
      }
    } else {
      let sel = selectedIsin.find((isin, i) => {
        if (isin === e.target.value) {
          selectedIsin.splice(i, 1);
          return true;
        }
      });
      if (!sel) {
        const customDiv = document.getElementById("customAlert");
        customDiv.style.display = "block";
        customDiv.innerHTML = `<div class="alertText">
        <span style="margin-right:20px"> <svg height="32" style="overflow:visible;enable-background:new 0 0 32 32" viewBox="0 0 32 32" width="32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><g id="Error_1_"><g id="Error"><circle cx="16" cy="16" id="BG" r="16" style="fill:#D72828;"/><path d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z" id="Exclamatory_x5F_Sign" style="fill:#E6E6E6;"/></g></g></g></svg></span>
                                  Cannot select more than 4 funds
                               </div>`;
        hideAlert();
      }
      setSelectedIsin([...selectedIsin]);
      lineData.find((l, i) => {
        if (l.isin == e.target.value) {
          lineData.splice(i, 1);
          legends.find((legend, j) => {
            if (l.data[0]?.id == legend) {
              legends.splice(j, 1);
              setlegends([...legends]);
              return true;
            }
          });
          setLineData([...lineData]);
        }
      });
    }
  };
  return (
    <div>
      {load && <Loader />}{" "}
      <TabBondSub
        title="Comparison"
        type="external_click"
        setopen={setOpenModal}
        modal={openModal}
      ></TabBondSub>
      {/* <div className={`${classes.iconAlign}`}>
        <Button
          style={{ width: "157px" }}
          className={`${classes.fundButton}`}
          variant="contained"
          onClick={() => {
            setOpenModal(true);
          }}
        >
          <Typography className={classes.holdingButtonText}>
            Select Funds
          </Typography>
        </Button>
      </div> */}
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(!openModal);
          setClearSh(true);
        }}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={`${classes.modalContainerModified} form-container`}>
          <div className="filter-header">
            <Typography className="filter-header-text">Bonds</Typography>
          </div>
          <hr className="filter-divider"></hr>
          <div className="member">
            <SearchInput
              setSearchName={setSearch}
              clear={clearSh}
              setClear={setClearSh}
            />

            <div className="member-content">
              {!search &&
                mutualFunds?.map((column, key) =>
                  column && column?.title !== "" ? (
                    <div key={key} className="filter-header">
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <CheckBoxWithColor
                          checked={selectedIsin.includes(column.isin)}
                          value={column.isin}
                          name={column.company_name}
                          onChange={(e) => {
                            handleCheckBoxClick(e, column);
                          }}
                        />
                        <Tooltip title={column.company_name}>
                          <Typography className="filter-value-name avtar-text overflow-ellipse">
                            {column.company_name}
                          </Typography>
                        </Tooltip>
                      </span>
                    </div>
                  ) : null
                )}
              {search &&
                searchList?.map((column, key) =>
                  column.title !== "" ? (
                    <div key={key} className="filter-header">
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <CheckBoxWithColor
                          checked={selectedIsin.includes(column.isin)}
                          value={column.isin}
                          name={column.company_name}
                          onChange={(e) => {
                            handleCheckBoxClick(e, column);
                          }}
                        />
                        <Tooltip title={column.company_name}>
                          <Typography className="filter-value-name avtar-text overflow-ellipse">
                            {column.company_name}
                          </Typography>
                        </Tooltip>
                      </span>
                    </div>
                  ) : null
                )}
            </div>
          </div>
        </div>
      </Modal>
      {/* <br/>   */}
      <div
        style={{
          display: "flex",
          margin: "2% 2%",
          alignItems: "center",
        }}
      >
        <div className={classes.bottomTabHeader}>
          <Button
            onClick={() => {
              setClear(true);
              setRange("3M");
              setDataRange([
                moment().subtract(3, "months").format("YYYY-MM-DD"),
                moment().format("YYYY-MM-DD"),
              ]);
            }}
            style={{ textDecoration: "none" }}
            to="#"
          >
            <div
              className={`${classes.bottomTabHeaderText} ${
                range === "3M" ? classes.bottomTabActive : ""
              }`}
            >
              3M
            </div>
          </Button>
          <Button
            onClick={() => {
              setClear(true);
              setRange("6M");
              setDataRange([
                moment().subtract(6, "months").format("YYYY-MM-DD"),
                moment().format("YYYY-MM-DD"),
              ]);
            }}
            style={{ textDecoration: "none" }}
            to="#"
          >
            <div
              className={`${classes.bottomTabHeaderText} ${
                range === "6M" ? classes.bottomTabActive : ""
              }`}
            >
              6M
            </div>
          </Button>
          <Button
            onClick={() => {
              setClear(true);
              setRange("1Y");
              setDataRange([
                moment().subtract(1, "years").format("YYYY-MM-DD"),
                moment().format("YYYY-MM-DD"),
              ]);
            }}
            style={{ textDecoration: "none" }}
            to="#"
          >
            <div
              className={`${classes.bottomTabHeaderText} ${
                range === "1Y" ? classes.bottomTabActive : ""
              }`}
            >
              1Y
            </div>
          </Button>
          <Button
            onClick={() => {
              setClear(true);
              setRange("3Y");
              setDataRange([
                moment().subtract(3, "years").format("YYYY-MM-DD"),
                moment().format("YYYY-MM-DD"),
              ]);
            }}
            style={{ textDecoration: "none" }}
            to="#"
          >
            <div
              className={`${classes.bottomTabHeaderText} ${
                range === "3Y" ? classes.bottomTabActive : ""
              }`}
            >
              3Y
            </div>
          </Button>
          <Button
            onClick={() => {
              setClear(true);
              setRange("Inception Till Date");
              setDataRange([null, null]);
            }}
            style={{ textDecoration: "none" }}
            to="#"
          >
            <div
              className={`${classes.bottomTabHeaderText} ${
                range === "Inception Till Date" ? classes.bottomTabActive : ""
              }`}
            >
              Inception Till Date
            </div>
          </Button>
          <Button style={{ textDecoration: "none", display: "none" }} to="#">
            <div
              className={`${classes.bottomTabHeaderText} ${classes.bottomTabActive}`}
            ></div>
          </Button>
        </div>

        <div style={{ marginLeft: "auto" }}>
          <fieldset className="fildset">
            <legend className="legen">
              <div>Select date</div>
            </legend>
            {/* <div style={{ width: "200px" }}> */}
            <FilterCalendar
              clear={clear}
              values={dataRange}
              minDate={{ min: 5, type: "years" }}
              maxDate={{
                current: new Date(moment().format("YYYY-MM-DD")),
              }}
              hideinput={false}
              customLabel="Custom"
              customStyle={{
                // width: "100%",
                width: "254px",
                textAlign: "center",
                borderRadius: "20px",
                padding: "0px",
                background: "transparent",
                border: "none",
                color: "#FFFFFF",
                fontSize: "16px",
                fontStyle: "normal",
                fontFamily: "Avenir",
                fontWeight: "500",
                cursor: "pointer",
              }}
              customCalenderPostion={{
                right: "0px",
                top: "55px",
              }}
              onchange={(update) => {
                // portfolio(update)
                setRange(null);
                setClear(false);
                setDataRange(update);
              }}
            />
            {/* </div> */}
            <div style={{ marginLeft: "auto", marginRight: "10px" }}>
              <CalendarToday style={{ color: "#fff" }} />
            </div>
          </fieldset>
          {/* <RangeCalendar
            values={dataRange}
            onchange={(update) => {
              // portfolio(update)
              setDataRange(update);
            }}
          /> */}
          {/* <CalendarMonthIcon/> */}
        </div>
      </div>
      <div>
        {lineData && lineData.length !== 0 ? (
          <LineChart
            config={config}
            colors={[" #82EBA1", "#711CF1", " #FC5555", "#FF9446"]}
            data={filteredData}
            xval={xval}
          ></LineChart>
        ) : (
          ""
        )}
        {lineData && (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              marginTop: "20px",
              marginBottom: "3%",
              marginRight: "3%",
            }}
          >
            {legends?.map((l, i) => {
              return (
                <div style={{ display: "flex" }} className="outer-tooltip">
                  <div className={classes.graphBulletBonds}>
                    <svg height="25" width="25">
                      <circle
                        cx="12"
                        cy="12"
                        r="5"
                        stroke-width="3"
                        fill={lineColors[i]}
                      />
                      Sorry, your browser does not support inline SVG.
                    </svg>
                  </div>
                  <div className={`${classes.graphLegends}`}>
                    {l.length > 30 ? l.substring(0, 30) + "..." : l}
                  </div>
                  <div className=" inner-tooltip tooltip-box">
                    <p className="tooltip-text">{l}</p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {chartload ? <DataNotFound></DataNotFound> : ""}

        {/* <div
          style={{
            marginTop: "40px",
            marginLeft: "15px",
            height: "80px",
            overflow: "hidden",
          }}
        >
          {googleData.length > 0 && (
            <GoogleChart
              data={googleData}
              lineData={lineData}
              setFilter={setFilteredData}
              setFilterRange={setFilterRange}
              filterRange={filterRange}
            ></GoogleChart>
          )}
        </div> */}
        {/* <PageLoader showLoader={load} /> */}
      </div>
    </div>
  );
}
