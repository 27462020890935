import { Typography } from "@material-ui/core";
import React from "react";

function ErrorMessageComp({ errorMessage }) {
  return (
    <Typography style={{ color: "red", textAlign: "left", mb: "10px" }}>
      {errorMessage}
    </Typography>
  );
}

export default ErrorMessageComp;
