import React from "react";
import { Grid, Box } from "@material-ui/core";
import { MainCard } from "./MainCard";
import { NestedCard } from "./NestedCard";
import { Typography } from "@material-ui/core/";
import { useStyles, checkValue, formatDate } from "./CardStyles";
import { CardInfo } from "./CardInfo";
import { GainsCardInfo } from "./GainsCardInfo";
import { GainLossLegend } from "./GainLossLegend";
import { TotalGainLossCardInfo } from "./TotalGainLossCardInfo";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ReactComponent as ReloadIcon } from "../../../assets/icons/ReloadIcon.svg";
import { ReactComponent as ErrorBarIcon } from "../../../assets/icons/ErrorBarIcon.svg";
export const NewHoldingSummaryCards = ({
  holdingSummaryCardsInactive,
  totalRentalIncome,
  children,
  totalGainLossInactive,
  twrrLoader,
  twrrError,
  retryFetchTwrr,
  infoIcon,
  renderCustomComponent,
}) => {
  const classes = useStyles();
  function getTwrrData(data) {
    if (twrrLoader && !twrrError) {
      return <CircularProgress />;
    } else if (!twrrLoader && twrrError) {
      return (
        <div
          onClick={retryFetchTwrr}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            cursor: "pointer",
            fontSize: "18px",
          }}
        >
          <ReloadIcon />
          <p>Reload</p>
        </div>
      );
    } else {
      return data;
    }
  }

  return (
    <Grid container spacing={1} style={{ position: "relative" }}>
      {/* 1st Row Start */}
      {infoIcon}
      <Grid item xs={2}>
        <Typography className={classes.dateText}>
          Updated As On :{" "}
          <span className={classes.dateValue}>
            {formatDate(holdingSummaryCardsInactive.asOnDate)}
          </span>
        </Typography>
      </Grid>

      <Grid item xs={5}></Grid>

      <Grid item xs={5}>
        <GainLossLegend />
      </Grid>
      {/* 1st Row End */}

      {/* 2nd Row Start */}
      {renderCustomComponent || (
        <>
          <Grid item xs={2}>
            <MainCard>
              <Box p={2}>
                <Typography
                  variant="h1"
                  component="h2"
                  className={classes.firstCardHeaderText}
                >
                  Current
                </Typography>
                <Typography
                  variant="h1"
                  component="h2"
                  className={classes.firstCardHeaderText}
                >
                  Portfolio
                </Typography>
                <Typography
                  className={classes.firstCardHeaderText}
                  style={{ marginBottom: "30px" }}
                >
                  Value
                </Typography>
                <Typography className={classes.firstCardHeaderValue}>
                  {holdingSummaryCardsInactive.portfolioValueAmount}
                </Typography>
              </Box>
            </MainCard>
          </Grid>

          <Grid item xs={5}>
            <MainCard>
              <Grid
                container
                justifyContent="space-between"
                className={classes.spaceBetweenMainHeaderAndInnerCard}
              >
                <Grid item>
                  <Typography className={classes.secondCardHeaderText}>
                    Total cost of investments
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.secondCardHeaderValue}>
                    {holdingSummaryCardsInactive.overallInvestedAmount}
                  </Typography>
                </Grid>
              </Grid>
              <NestedCard>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  className={classes.nestedCardBoxPadding}
                >
                  <GainsCardInfo
                    text="Cost of Current Holding"
                    value={holdingSummaryCardsInactive.investedAmount}
                    valueTextColor="white"
                  />
                  <GainsCardInfo
                    text="Cost of Redeemed Investements"
                    value={holdingSummaryCardsInactive.redeemedInvestedAmount}
                    align="right"
                    valueTextColor="white"
                  />
                </Box>
              </NestedCard>
            </MainCard>
          </Grid>

          <Grid item xs={5}>
            <MainCard>
              <Grid
                container
                justifyContent="space-between"
                className={classes.spaceBetweenMainHeaderAndInnerCard}
              >
                <Grid item>
                  <Typography className={classes.secondCardHeaderText}>
                    Total Gain/Loss
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.secondCardHeaderValue}>
                    {holdingSummaryCardsInactive.totalGainLoss}
                  </Typography>
                </Grid>
              </Grid>
              <NestedCard>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  className={classes.nestedCardBoxPadding}
                >
                  {/* // TODO Pass Only totalGainLossInactive remove the else case when totalGainLoss is implemented throughout */}
                  {totalGainLossInactive ? (
                    totalGainLossInactive
                  ) : (
                    <>
                      <GainsCardInfo
                        text="Realized Gain/Loss"
                        value={holdingSummaryCardsInactive.realisedGainLoss}
                      />
                      <GainsCardInfo
                        text="Unrealized Gain/Loss"
                        value={holdingSummaryCardsInactive.unRealisedGainLoss}
                        align="right"
                      />
                      {totalRentalIncome}
                    </>
                  )}
                  {/* <GainsCardInfo
                text="Realized Gain/Loss"
                value={holdingSummaryCardsInactive.realisedGainLoss}
              />
              <GainsCardInfo
                text="Unrealized Gain/Loss"
                value={holdingSummaryCardsInactive.unRealisedGainLoss}
                align="right"
              />
              {totalRentalIncome} */}
                </Box>
              </NestedCard>
            </MainCard>
          </Grid>
        </>
      )}

      {/* 2nd Row End */}

      {/* 3rd Row Start */}
      {/* DIVIDER LINE START */}
      <Grid item xs={12}>
        <section id="title-wrapper">
          <div class="title" className={classes.dividerText}>
            RETURNS
          </div>
          <div class="dividerLine"></div>
        </section>
      </Grid>
      {/* DIVIDER LINE END */}
      {/* 3rd Row End */}

      {/* 4th Row Start */}
      {/* XIRR */}
      <Grid item xs={4}>
        <MainCard
          className={`${classes.secondRowCards} ${
            checkValue(holdingSummaryCardsInactive.xirrValue).type === "gain"
              ? classes.gainBackground
              : classes.lossBackground
          }`}
        >
          <CardInfo
            text="XIRR"
            value={holdingSummaryCardsInactive.xirrValue}
            icon={checkValue(holdingSummaryCardsInactive.xirrValue).icon}
          />
        </MainCard>
      </Grid>

      {/* ABS TWRR */}
      <Grid item xs={4}>
        <MainCard
          className={`${classes.secondRowCards} ${
            checkValue(holdingSummaryCardsInactive.absoluteTwrrData).type ===
            "gain"
              ? classes.gainBackground
              : classes.lossBackground
          }`}
          // if twrr api fails then change bg and border
          style={{
            background: twrrError && "#211534",
            border: twrrError && "1px solid #613D9B",
          }}
        >
          <CardInfo
            // text="Absolute TWRR"
            // if there is an error then reduce text opacity
            text={
              twrrError ? (
                <span style={{ opacity: "0.5" }}>Absolute TWRR</span>
              ) : (
                "Absolute TWRR"
              )
            }
            // value={holdingSummaryCardsInactive.absoluteTwrrData}
            value={getTwrrData(holdingSummaryCardsInactive.absoluteTwrrData)}
            icon={
              twrrError ? (
                <ErrorBarIcon />
              ) : (
                checkValue(holdingSummaryCardsInactive.absoluteTwrrData).icon
              )
            }
          />
        </MainCard>
      </Grid>

      {/* ANN TWRR */}
      <Grid item xs={4}>
        <MainCard
          className={`${classes.secondRowCards} ${
            checkValue(holdingSummaryCardsInactive.annualisedTwrrData).type ===
            "gain"
              ? classes.gainBackground
              : classes.lossBackground
          }`}
          // if twrr api fails then change bg and border
          style={{
            background: twrrError && "#211534",
            border: twrrError && "1px solid #613D9B",
          }}
        >
          <CardInfo
            // text="Annualised TWRR"
            // if there is an error then reduce text opacity
            text={
              twrrError ? (
                <span style={{ opacity: "0.5" }}>Annualized TWRR</span>
              ) : (
                "Annualized TWRR"
              )
            }
            // value={holdingSummaryCardsInactive.annualisedTwrrData}
            value={getTwrrData(holdingSummaryCardsInactive.annualisedTwrrData)}
            icon={
              twrrError ? (
                <ErrorBarIcon />
              ) : (
                checkValue(holdingSummaryCardsInactive.annualisedTwrrData).icon
              )
            }
          />
        </MainCard>
      </Grid>
      {/* 4th Row End */}
    </Grid>
  );
};
