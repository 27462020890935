import React from "react";
import "./editBtn.css";

function EditBtn({ title, customClass, hanldleClick }) {
  return (
    <div className="editBtnContainer">
      <button className={`editBtn ${customClass}`} onClick={hanldleClick}>
        <p className="btnTxt">{title ? title : "Edit"}</p>
      </button>
    </div>
  );
}

export default EditBtn;
