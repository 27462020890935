import {
  Avatar,
  Button,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { useState, useContext, useEffect, useRef } from "react";
import moment from "moment/moment";
import "./products-style.css";
import useStyles from "./ProductsStyles";
import { ReactComponent as ExpandLess } from "../../../../assets/icons/expandLess.svg";
import { ReactComponent as ExpandMore } from "../../../../assets/icons/expandMore.svg";
import SearchInput from "./SearchInput";
import CheckBoxWithColor from "./CheckBoxWithColor";
import Context from "../../../../Context";
import { ReactComponent as Back } from "../../../../assets/holding-summary/back-blue.svg";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import {
  fetchAllClients,
  fetchXirr,
  fetchAdvisor,
  fetchExecutor,
} from "../../../../api";
import { OnboardingContext } from "../../../../Context";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useMemo } from "react";
import RangeCalendar from "../../../calendar/RangeCalendar";
import FilterCalendar from "../../../calendar/FilterCalendar";
import { firstLetterCapitalize } from "../../../utils/strHelper";
import { json } from "d3";
import { useLocation, useNavigate } from "react-router-dom";
import Filtericon from "../../common/Filtericon";
import Breadcrum_route from "../../../utils/Breadcrum_route";

function PageFilter({
  setCustomOpenModal,
  customOpenModal,
  setPrintModalMembers,
  backLink,
  setFilterModalOpen,
}) {
  const theme = useTheme();
  const icons = theme.palette.icons;
  const globalFilterIcon = icons.globalFilterIcon;
  const BackIcon = icons.backIcon;
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [expand, setExpand] = useState({
    member: false,
    advisor: false,
    currency: false,
    offshore: false,
    denomination: false,
    period: false,
    asset: false,
  });
  const [showfilters, setShowfilter] = useState(false);

  const [familyMembers, setFamilyMembers] = useState([]);
  const [advisors, setAdvisors] = useState([]);
  const [executors, setExecutors] = useState([]);
  const [executorName, setExecutorName] = useState(null);
  const [advisorName, setAdvisorName] = useState(null);
  const [neolvai, setNeolvai] = useState(false);
  const [clientName, setClientname] = useState(null);
  const [period, setPeriod] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [more, setMore] = useState(false);
  const [clear, setClear] = useState(false);
  const wrapperRef = useRef(null);
  const [assetClass, setAssetClass] = useState({
    mfo: { equity: true, debt: true, alternate: true, cash: true },
    mf: { equity: true, debt: true, alternate: true, cash: true },
    aif: { equity: true, debt: true, alternate: true },
    bonds: { debt: true },
    pms: { equity: true, debt: true, alternate: true, cash: true },
    equity: { equity: true },
    "real-estate": { alternate: true },
    general: { equity: true, debt: true, alternate: true, cash: true },
    holding: { equity: true, debt: true, alternate: true, cash: true },
    insurance: {},
    private: { equity: true, debt: true, alternate: true },
    structured: { equity: true, debt: true },
    mld: { debt: true },
    invits: { alternate: true },
    reits: { alternate: true },
    icd: { debt: true },
    "fixed-deposit": { alternate: true, debt: true, equity: true },
    bank: { cash: true },
    "recurring-deposit": { alternate: true, debt: true, equity: true },
  });
  const [assets, setAssets] = useState(["equity", "debt", "alternate", "Cash"]);
  const [currency_format, setCurrencyFormat] = useState(
    localStorage.getItem("currency_format")
      ? localStorage.getItem("currency_format")
      : "actuals"
  );
  const navigate = useNavigate();
  let assetlist = {
    mfo: ["equity", "debt", "alternate", "cash"],
    mf: ["equity", "debt", "alternate", "cash"],
    aif: ["equity", "debt", "alternate"],
    bonds: ["debt"],
    pms: ["equity", "debt", "alternate", "cash"],
    equity: ["equity"],
    "real-estate": ["alternate"],
    general: ["equity", "debt", "alternate", "cash"],
    holding: ["equity", "debt", "alternate", "cash"],
    private: ["equity", "debt", "alternate"],
    structured: ["equity", "debt"],
    insurance: [],
    mld: ["debt"],
    invits: ["alternate"],
    reits: ["alternate"],
    icd: ["debt"],
    "fixed-deposit": ["equity", "debt", "alternate"],
    bank: ["cash"],
    "recurring-deposit": ["equity", "debt", "alternate"],
  };
  const location = useLocation();
  const [pathtype, setPathtype] = useState("general");

  // const [startDate,setStartDate]=useState(moment("2021-04-01"))
  // const [endDate,setEndDate]=useState(moment("2021-05-30"))
  const [currency, setCurrency] = useState("Rupees");

  const [dateRange, setDateRange] = useState([
    new Date("2021-04-01"),
    new Date("2021-05-30"),
  ]);

  const { userState } = OnboardingContext();
  // console.log(userState)
  // console.log(checkData);
  const { setMfofilter, mfoFilter, entity } = useContext(mfoFilterContext);
  const [selectAll, setSelectAll] = useState(
    !mfoFilter ? true : mfoFilter?.selectAll
  );
  const [checkData, setCheckData] = useState({});
  const [checkAdvisor, setCheckAdvisor] = useState({});
  const [selectAllAdvisor, setSelectAllAdvisor] = useState(
    !mfoFilter ? true : mfoFilter?.selectAllAdvisor
  );
  const [startDate, endDate] = dateRange;
  const [selectAllExecutor, setSelectAllExecutor] = useState(
    !mfoFilter ? true : mfoFilter?.selectAllExecutor
  );
  const [checkExecutor, setCheckExecutor] = useState({});
  const height = useRef(null);
  // sessionStorage.setItem("checkedData", JSON.stringify(checkData))

  // sessionStorage.setItem("checkedData", JSON.stringify(checkData))
  // console.log(mfoFilter, "mfo filter");
  function handleAllCheck(e, setState) {
    if (!e.target.checked) {
      setCheckData({});
      setSelectAll(false);
      return;
    } else {
      setSelectAll(true);
      // value={selectAll}
    }
    // let obj = {};

    // familyMembers.forEach((f) => {
    //   obj[f.member_id] = e.target.checked;
    // });

    setCheckData({});
  }
  function checkAllAdvisor(e, setState) {
    if (!e.target.checked) {
      setSelectAllAdvisor(false);
      setCheckAdvisor({});
      return;
    }
    let obj = {};
    setSelectAllAdvisor(true);
    // advisors.forEach((a) => {
    //   obj[a] = e.target.checked;
    // });

    setCheckAdvisor({});
  }

  function checkAllExecutor(e, setState) {
    if (!e.target.checked) {
      setSelectAllExecutor(false);
      setCheckExecutor({});
      return;
    }
    let obj = {};
    setSelectAllExecutor(true);
    // advisors.forEach((a) => {
    //   obj[a] = e.target.checked;
    // });

    setCheckExecutor({});
  }

  function getclientlist(id, name) {
    fetchAllClients(id, name).then((res) => {
      setFamilyMembers(res?.data?.data?.result);
      if (setPrintModalMembers) {
        setPrintModalMembers(res?.data?.data?.result);
      }
    });

    // fetchXirr(id);
  }
  // console.log("family members", familyMembers);
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        handleButtonClick();
        setTimeout(() => {
          setFromContext();
        }, 10);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    let checkfilter = sessionStorage.getItem("filter");

    Object.keys(assetClass).map((a) => {
      let sortedarr = Object.keys(assetClass[a]).sort(function (a, b) {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });
      let obj = {};
      sortedarr.map((s) => {
        obj[s] = true;
      });
      assetClass[a] = obj;
    });
    let stringfilter = JSON.stringify({
      checkData: checkData,
      startDate: startDate,
      endDate: endDate,
      advisor: checkAdvisor,
      currency: currency,
      period: period,
      assetclass: { ...assetClass },
      selectAll: selectAll,
      selectAllAdvisor: selectAllAdvisor,
      selectAllExecutor: selectAllExecutor,
      executor: checkExecutor,
    });

    if (
      (Object.keys(checkData).length == 0 && !selectAll) ||
      (Object.keys(checkAdvisor).length == 0 && !selectAllAdvisor) ||
      (Object.keys(checkExecutor).length == 0 && !selectAllExecutor) ||
      (stringfilter == checkfilter &&
        currency_format == localStorage.getItem("currency_format"))
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [
    checkData,
    startDate,
    endDate,
    checkAdvisor,
    currency,
    period,
    showfilters,
    currency_format,
    assetClass,
    checkExecutor,
  ]);
  useEffect(() => {
    let params = {};
    if (clientName) {
      params.name = clientName;
    }
    // Check user Type and set clientId Accordingly
    let clientId = userState.loginedUserData.id;
    if (localStorage?.getItem("userType") === "mfo-user") {
      clientId = localStorage?.getItem("memberId");
    }
    getclientlist(clientId, params);
  }, [clientName]);

  useMemo(() => {
    if (entity) {
      let params = {};
      // console.log(entity);
      // console.log(advisorName);
      if (advisorName) {
        params.name = advisorName;
      }
      getAdvisorList(userState.loginedUserData.id, params, entity);
    }
  }, [advisorName, entity]);

  useMemo(() => {
    if (entity) {
      let params = {};
      // console.log(entity);
      // console.log(advisorName);
      if (executorName) {
        params.name = executorName;
      }
      getExecutors(userState.loginedUserData.id, params, entity);
    }
  }, [executorName, entity]);

  function getExecutors(id, params, entity) {
    fetchExecutor(id, params, entity).then((res) => {
      //TODO Remove after test

      //
      setExecutors(res?.result?.filter((val) => val !== null));
      // let lvai = res?.result
      //   ?.filter((val) => val !== null)
      //   ?.find((adv) => {
      //     if (adv.toUpperCase == "NEO LVAI") {
      //       setNeolvai(adv);
      //       return true;
      //     }
      //   });
      // if (!lvai) {
      //   setNeolvai(false);
      // }
    });
  }

  useEffect(() => {
    setFromContext();
  }, [mfoFilter]);

  useEffect(() => {
    let path_type = get_path_type(location.pathname);
    // console.log(assetlist[path_type], path_type, "aflibaiwgfiawfbpowa");
    setAssets(assetlist[path_type]);
    setPathtype(path_type);
  }, []);

  function get_path_type(path) {
    if (path.indexOf("/mutual-fund") >= 0) {
      return "mfo";
    } else if (path.indexOf("/aif") >= 0) {
      return "aif";
    } else if (path.indexOf("/pms") >= 0) {
      return "pms";
    } else if (path.indexOf("/equity") >= 0) {
      return "equity";
    } else if (path.indexOf("/real-estate") >= 0) {
      return "real-estate";
    } else if (path.indexOf("/bonds") >= 0) {
      return "bonds";
    } else if (path.indexOf("/unlisted-equity") >= 0) {
      return "private";
    } else if (path.indexOf("/structured-product") >= 0) {
      return "structured";
    } else if (path.indexOf("/mld") >= 0) {
      return "mld";
    } else if (path.indexOf("/invits") >= 0) {
      return "invits";
    } else if (path.indexOf("/reits") >= 0) {
      return "reits";
    } else if (path.indexOf("/icd") >= 0) {
      return "icd";
    } else if (path.indexOf("/fixed-deposit") >= 0) {
      return "fixed-deposit";
    } else if (path.indexOf("/bank") >= 0) {
      return "bank";
    } else if (path.indexOf("/recurring-deposit") >= 0) {
      return "recurring-deposit";
    }

    if (path.indexOf("/holding") >= 0) {
      return "holding";
    } else {
      return "general";
    }
  }

  function getAdvisorList(id, params, entity) {
    fetchAdvisor(id, params, entity).then((res) => {
      setAdvisors(res?.result);
      let lvai = res?.result.find((adv) => {
        if (adv.toUpperCase == "NEO LVAI") {
          setNeolvai(adv);
          return true;
        }
      });
      if (!lvai) {
        setNeolvai(false);
      }
    });
  }

  function setFromContext() {
    let sv = JSON.parse(sessionStorage.getItem("filter"));
    sv = sv ? JSON.parse(sessionStorage.getItem("filter")) : mfoFilter;
    setDateRange([mfoFilter.startDate, mfoFilter.endDate]);

    setCheckData((prevCheckData) => {
      // Modify the prevCheckData object or create a new object based on your requirements

      const updatedCheckData = { ...sv?.checkData };

      return updatedCheckData;
    });

    setCheckAdvisor((prev) => ({ ...sv?.advisor }));
    setPeriod(sv?.period);
    setAssetClass({ ...mfoFilter.assetclass });
    setSelectAll(sv?.selectAll);
    setSelectAllAdvisor(sv?.selectAllAdvisor);
    setCurrencyFormat(
      localStorage.getItem("currency_format")
        ? localStorage.getItem("currency_format")
        : "actuals"
    );
    setCheckExecutor((prev) => ({ ...sv?.executor }));
    setSelectAllExecutor(sv?.selectAllExecutor);
  }

  function setFromSessionstorage() {
    setDateRange([mfoFilter.startDate, mfoFilter.endDate]);

    setCheckData((prev) => ({ ...mfoFilter.checkData }));
    setCheckAdvisor((prev) => ({ ...mfoFilter.advisor }));
    setPeriod(mfoFilter.period);
    setAssetClass({ ...mfoFilter.assetclass });
    setSelectAll(mfoFilter.selectAll);
    setSelectAllAdvisor(mfoFilter.selectAllAdvisor);
  }
  const handleExpandClick = (type) => {
    setExpand({ ...expand, [type]: !expand[type] });
  };

  const handleButtonClick = () => {
    setShowfilter(false);
    if (setCustomOpenModal) {
      setCustomOpenModal(false);
      return;
    }
    setOpenModal(false);
  };

  const handleCheck = (event) => {
    setSelectAll(false);
    if (event.target.checked) {
      let obj = { ...checkData, [event.target.name]: event.target.checked };
      if (Object.keys(obj).length == familyMembers.length) {
        setCheckData({});
        setSelectAll(true);
      } else {
        setCheckData({
          ...checkData,
          [event.target.name]: event.target.checked,
        });
      }
    } else {
      if (selectAll) {
        let obj = {};

        familyMembers.forEach((f) => {
          obj[f.member_id] = true;
        });
        delete obj[event.target.name];

        setCheckData({ ...obj });
      } else {
        delete checkData[event.target.name];
        let checkedobj = JSON.stringify(checkData);
        setCheckData({ ...JSON.parse(checkedobj) });
      }
    }
  };

  const handleAdvisorCheck = (event) => {
    setSelectAllAdvisor(false);
    if (event.target.checked) {
      let tempval = {};
      if (event.target.name.toUpperCase == "NEO" && neolvai) {
        tempval[neolvai] = true;
      }
      let obj = {
        ...checkAdvisor,
        [event.target.name]: event.target.checked,
        ...tempval,
      };
      if (Object.keys(obj).length == advisors.length) {
        setCheckAdvisor({});
        setSelectAllAdvisor(true);
      } else {
        setCheckAdvisor({
          ...checkAdvisor,
          [event.target.name]: event.target.checked,
          ...tempval,
        });
      }
    } else {
      if (selectAllAdvisor) {
        let obj = {};

        advisors.forEach((f) => {
          obj[f] = true;
        });
        delete obj[event.target.name];

        setCheckAdvisor({ ...obj });
      } else {
        // console.log(mfoFilter, "check advisor");
        delete checkAdvisor[event.target.name];
        let checkedobj = JSON.stringify(checkAdvisor);
        // console.log(mfoFilter, "check advisor");
        setCheckAdvisor({ ...JSON.parse(checkedobj) });
      }
    }
  };

  const handleExcutorCheck = (event) => {
    setSelectAllExecutor(false);
    if (event.target.checked) {
      let tempval = {};

      let obj = {
        ...checkExecutor,
        [event.target.name]: event.target.checked,
        ...tempval,
      };

      if (Object.keys(obj).length == executors.length) {
        setCheckExecutor({});
        setSelectAllExecutor(true);
      } else {
        setCheckExecutor({
          ...checkExecutor,
          [event.target.name]: event.target.checked,
          ...tempval,
        });
      }
    } else {
      if (selectAllExecutor) {
        let obj = {};

        executors.forEach((f) => {
          obj[f] = true;
        });
        delete obj[event.target.name];

        setCheckExecutor({ ...obj });
      } else {
        // console.log(mfoFilter, "check advisor");
        delete checkExecutor[event.target.name];
        let checkedobj = JSON.stringify(checkExecutor);
        // console.log(mfoFilter, "check advisor");
        setCheckExecutor({ ...JSON.parse(checkedobj) });
      }
    }
  };

  const handleAssets = (event) => {
    // console.log(assetClass);
    if (event.target.checked) {
      setAssetClass({
        ...assetClass,
        [pathtype]: {
          ...assetClass[pathtype],
          [event.target.name]: event.target.checked,
        },
      });
    } else {
      if (Object.keys(assetClass[pathtype]).length == 1) {
        return;
      }
      // console.log(mfoFilter);
      let obj = JSON.parse(JSON.stringify(assetClass));
      delete obj[pathtype][event.target.name];
      // console.log(mfoFilter);
      setAssetClass({
        ...assetClass,
        [pathtype]: {
          ...obj[pathtype],
        },
      });
    }
  };

  console.log(assetClass[pathtype], "aglakgnkj");

  // console.log(
  //   // advisors
  //   //   .filter((member) =>
  //   //     Object.keys(checkAdvisor).includes(String(member?.member_id))
  //   //   )
  //   //   .map((member) => member?.full_name),
  //   checkData,
  //   familyMembers,
  //   "ozifiehi"
  // );

  console.log(height?.current?.clientHeight, "agljg");

  return (
    <>
      {/* <h1 style={{ color: "white" }}>{String(showfilters)}</h1> */}
      {/* {console.log("jjj", showfilters)} */}
      <div
        className="filter-box"
        style={{ paddingInline: "2%", paddingTop: "20px" }}
      >
        <Breadcrum_route
          className="bredlinks"
          renderClientNameForAdminView={() => {
            if (
              localStorage.getItem("adminViewClientName") &&
              localStorage.getItem("adminViewClientName") !== "undefined"
            ) {
              return (
                <span className="adminViewClientNameBreadcrumb">
                  {localStorage.getItem("adminViewClientName") + " " + ":"}
                </span>
              );
            }
          }}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <BackIcon
            style={{ cursor: "pointer", marginRight: "12px", flexShrink: 0 }}
            onClick={() => {
              navigate(backLink);
            }}
          />
          <div
            className="filterBoxContainer"
            style={{ width: "-webkit-fill-available" }}
          >
            <div className="wrapper">
              <div
                class="content1231"
                style={{
                  // height: more ? "auto" : "62px",
                  height: more
                    ? "auto"
                    : `${height?.current?.clientHeight + 20}px`,
                }}
              >
                <div className="item">
                  <div
                    ref={height}
                    className="new-md-chip-container filter-input2"
                  >
                    <span className="filter-label">Family Member:</span>
                    <div className="custom-md-chip2 md-chip">
                      {Object.keys(checkData).length > 0 &&
                      Object.keys(checkData).length != familyMembers.length
                        ? familyMembers
                            ?.filter((member) =>
                              Object.keys(checkData).includes(
                                String(member?.member_id)
                              )
                            )
                            .map((member) => (
                              <div className="selecetedItems">
                                {member?.full_name}
                              </div>
                            ))
                        : // `${Object.keys(checkData).length} selected`
                          "All Members"}
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="new-md-chip-container filter-input">
                    <span className="filter-label">Advisor:</span>
                    <div className="custom-md-chip md-chip">
                      {Object.keys(checkAdvisor).length > 0 &&
                      advisors.length != Object.keys(checkAdvisor).length
                        ? advisors.map((adviser) => {
                            if (checkAdvisor[adviser]) {
                              return (
                                <div className="selecetedItems capitalize">
                                  {adviser.toLowerCase()}
                                </div>
                              );
                            }
                          })
                        : // `${Object.keys(checkAdvisor).length} selected`
                          "All Advisor"}
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="new-md-chip-container filter-input">
                    <span className="filter-label">Executed By:</span>
                    <div className="custom-md-chip md-chip">
                      {Object.keys(checkExecutor).length > 0 &&
                      executors.length != Object.keys(checkExecutor).length
                        ? executors.map((adviser) => {
                            if (checkExecutor[adviser]) {
                              return (
                                <div className="selecetedItems capitalize">
                                  {adviser?.toLowerCase()}
                                </div>
                              );
                            }
                          })
                        : // `${Object.keys(checkAdvisor).length} selected`
                          "All Executors"}
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="filter-input">
                    <span className="filter-label">Currency:</span>
                    <span className="md-chip">
                      <span>Rupees</span>
                      {/* <button type="button" className="md-chip-remove"></button> */}
                    </span>
                  </div>
                </div>
                {/* <div className="filter-input">
            <span className="filter-label">Denomination:</span>
            <span className="md-chip">
              <span>in Lakhs</span>
            </span>
          </div> */}
                <div className="item">
                  <div className="filter-input">
                    <span className="filter-label">Period:</span>
                    <span className="md-chip">
                      <span>
                        {period === "Inception Till Date"
                          ? "Since Inception/Inception Till Date"
                          : moment(startDate).format("DD MMM YYYY") +
                            "-" +
                            moment(endDate).format("DD MMM YYYY")}
                      </span>
                      {/* <button type="button" className="md-chip-remove"></button> */}
                    </span>
                  </div>
                </div>
                <div className="item">
                  <div className="filter-input">
                    <span className="filter-label">Denomination:</span>
                    <span className="md-chip">
                      {firstLetterCapitalize(
                        localStorage.getItem("currency_format")
                          ? localStorage.getItem("currency_format")
                          : "default"
                      )}
                    </span>
                  </div>
                </div>

                {pathtype != "holding" && (
                  <div className="item">
                    <div className="new-md-chip-container filter-input">
                      <span className="filter-label">Asset Class:</span>
                      <div className="custom-md-chip md-chip">
                        {Object.keys(assetClass[pathtype])?.length > 0
                          ? Object.keys(assetClass[pathtype]).map((key) => {
                              if (key) {
                                return (
                                  <div className="selecetedItems capitalize">
                                    {key}
                                  </div>
                                );
                              }
                            })
                          : // `${Object.keys(checkData).length} selected`
                            "All Members"}
                      </div>
                    </div>
                  </div>
                )}
                {/* <div className='filter-input'>
                        <span className='filter-label'>OffShore:</span>
                        <span className="md-chip">
                            <span>Chip chip chip</span>
                            <button type="button" className="md-chip-remove"></button>
                        </span>
                    </div> */}
              </div>
            </div>
            <div
              style={{
                marginLeft: "auto",
                marginBottom: "auto",
                marginTop: "10px",
              }}
              className="filter-button"
            >
              <Button
                className="filter-button-text"
                style={{
                  minWidth: "94px",
                  lineHeight: "29px",
                }}
                onClick={() => {
                  setMore(!more);
                }}
              >
                Show More
              </Button>
            </div>
            <div
              style={{
                // marginLeft: "auto",
                marginBottom: "auto",
                marginTop: "10px",
              }}
              // Triggers Opening of New Filter
              onClick={() => setFilterModalOpen(true)}
            >
              <Filtericon
                Icon={globalFilterIcon}
                // 👇 Uncomment the following if want to render old filter
                // showfilters={showfilters}
                // setShowfilter={setShowfilter}
                setShowfilter={() => {}}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Side bar modal */}
      <Modal
        open={(customOpenModal ? customOpenModal : openModal) || showfilters}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          className={`${classes.modalContainer} form-container`}
          ref={wrapperRef}
        >
          <div className="filter-header">
            <Typography className="filter-header-text">Filter</Typography>
            <Button
              onClick={() => {
                setCheckData({});
                setCheckAdvisor({});
                setSelectAllAdvisor(false);
                setSelectAll(false);
                setSelectAllExecutor(false);
                setCheckExecutor({});
                // setDateRange([
                //   new Date(moment().subtract(1, "years").format("YYYY-MM-DD")),
                //   new Date(moment().format("YYYY-MM-DD")),
                // ]);
                setClear(true);
                setDateRange([null, null]);
                setAssetClass({
                  ...assetClass,
                  [pathtype]: {},
                });
                setPeriod("ITD");
                setClientname(null);
                setAdvisorName(null);
              }}
              style={{ cursor: "pointer", color: "#fff" }}
              variant="text"
              className="filter-value-name"
            >
              Clear All
            </Button>
          </div>
          <hr className="filter-divider"></hr>
          <div className="member">
            <div className="filter-header">
              <Typography className="filter-header-text">
                All Members
              </Typography>
              <span>
                <span className="filter-badge">
                  {selectAll == true
                    ? familyMembers.length
                    : Object.keys(checkData).length}
                  {}
                </span>
                <span onClick={() => handleExpandClick("member")}>
                  {expand.member ? <ExpandLess /> : <ExpandMore width={12} />}
                </span>
              </span>
            </div>
            {expand.member && (
              <div className="member-content">
                <SearchInput
                  setSearchName={setClientname}
                  clear={clear}
                  setClear={setClear}
                />
                <div className="filter-header">
                  <Typography className="filter-header-text"></Typography>
                  <span>
                    <Typography className="filter-header-text">
                      Select All
                      <span>
                        <CheckBoxWithColor
                          checked={selectAll}
                          // value={selectAll}
                          onChange={(e) => handleAllCheck(e)}
                        />
                      </span>
                    </Typography>
                  </span>
                </div>

                {familyMembers?.map((f, k) => {
                  return (
                    <div className="filter-header" key={k}>
                      <Avatar className="capitalize" width={20} height={20}>
                        {f?.full_name[0]}
                      </Avatar>
                      <div className="filtet_text">
                        <Typography className="filter-value-name avtar-text capitalize">
                          {f?.full_name}
                        </Typography>
                        <span className="filter-value-subheading avtar-text capitalize">
                          {f?.role}
                        </span>
                      </div>
                      <span>
                        <CheckBoxWithColor
                          checked={
                            checkData[f?.member_id] || selectAll ? true : false
                          }
                          value={checkData[f?.member_id] ? true : false}
                          onChange={handleCheck}
                          name={f.member_id}
                        />
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <hr className="filter-divider"></hr>
          <div className="adviser">
            <div className="filter-header">
              <Typography className="filter-header-text">
                All Advisors
              </Typography>
              <span>
                <span className="filter-badge">
                  {selectAllAdvisor
                    ? advisors.length
                    : Object.keys(checkAdvisor).length}
                </span>
                <span onClick={() => handleExpandClick("advisor")}>
                  {expand.advisor ? <ExpandLess /> : <ExpandMore width={12} />}
                </span>
              </span>
            </div>
            {expand.advisor && (
              <div className="member-content">
                <SearchInput
                  setSearchName={setAdvisorName}
                  clear={clear}
                  setClear={setClear}
                />
                <div className="filter-header">
                  <Typography className="filter-header-text"></Typography>
                  <span>
                    <Typography className="filter-header-text">
                      Select All
                      <span>
                        <CheckBoxWithColor
                          checked={selectAllAdvisor}
                          value={selectAllAdvisor}
                          onChange={(e) => checkAllAdvisor(e)}
                        />
                      </span>
                    </Typography>
                  </span>
                </div>
                {advisors?.map((a, k) => {
                  return (
                    <div key={k} className="filter-header">
                      <Avatar width={20} height={20}>
                        {a.charAt(0).toUpperCase()}
                      </Avatar>
                      <div className="filtet_text">
                        <Typography className="signle-text filter-value-name avtar-text capitalize">
                          {a.toLowerCase()}
                        </Typography>
                      </div>
                      <span>
                        <CheckBoxWithColor
                          checked={
                            checkAdvisor[a] || selectAllAdvisor ? true : false
                          }
                          value={
                            checkAdvisor[a] || selectAllAdvisor ? true : false
                          }
                          onChange={(e) => {
                            handleAdvisorCheck(e);
                          }}
                          name={a}
                        />
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <hr className="filter-divider"></hr>
          <div className="adviser">
            <div className="filter-header">
              <Typography className="filter-header-text">
                Executed By
              </Typography>
              <span>
                <span className="filter-badge">
                  {selectAllExecutor
                    ? executors.length
                    : Object.keys(checkExecutor).length}
                </span>
                <span onClick={() => handleExpandClick("executor")}>
                  {expand.executor ? <ExpandLess /> : <ExpandMore width={12} />}
                </span>
              </span>
            </div>
            {expand.executor && (
              <div className="member-content">
                <SearchInput
                  setSearchName={setExecutorName}
                  clear={clear}
                  setClear={setClear}
                />
                <div className="filter-header">
                  <Typography className="filter-header-text"></Typography>
                  <span>
                    <Typography className="filter-header-text">
                      Select All
                      <span>
                        <CheckBoxWithColor
                          checked={selectAllExecutor}
                          value={selectAllExecutor}
                          onChange={(e) => checkAllExecutor(e)}
                        />
                      </span>
                    </Typography>
                  </span>
                </div>
                {executors?.map((a, k) => {
                  return (
                    <div key={k} className="filter-header">
                      <Avatar width={20} height={20}>
                        {a?.charAt(0)?.toUpperCase()}
                      </Avatar>
                      <div className="filtet_text">
                        <Typography className="signle-text filter-value-name avtar-text capitalize">
                          {a?.toLowerCase()}
                        </Typography>
                      </div>
                      <span>
                        <CheckBoxWithColor
                          checked={
                            checkExecutor[a] || selectAllExecutor ? true : false
                          }
                          value={
                            checkExecutor[a] || selectAllExecutor ? true : false
                          }
                          onChange={(e) => {
                            handleExcutorCheck(e);
                          }}
                          name={a}
                        />
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <hr className="filter-divider"></hr>
          <div className="currency">
            <div className="filter-header">
              <Typography className="filter-header-text">Currency</Typography>
              <span onClick={() => handleExpandClick("currency")}>
                {expand.currency ? <ExpandLess /> : <ExpandMore width={12} />}
              </span>
            </div>
            {expand.currency && (
              <div className="member-content" style={{ marginTop: "10px" }}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="Rupees"
                  name="currency"
                >
                  <FormControlLabel
                    className="radio-label"
                    value="Rupees"
                    style={{ marginLeft: "0px" }}
                    control={<Radio />}
                    label="Rupees(₹)"
                  />
                  {/* <FormControlLabel className='radio-label' value="dollar" control={<Radio />} label="Dollars($)" /> */}
                </RadioGroup>
              </div>
            )}
          </div>
          {pathtype != "holding" && (
            <>
              {" "}
              <hr className="filter-divider"></hr>
              <div className="Asset">
                <div className="filter-header">
                  <Typography className="filter-header-text">
                    Asset Class
                  </Typography>
                  <span onClick={() => handleExpandClick("asset")}>
                    {expand.asset ? <ExpandLess /> : <ExpandMore width={12} />}
                  </span>
                </div>
                {expand.asset && (
                  <div className="member-content" style={{ marginTop: "10px" }}>
                    {assets?.map((a, k) => {
                      return (
                        <div key={k} className="filter-header">
                          <div className="filtet_text">
                            <Typography
                              className="signle-text filter-value-name avtar-text capitalize "
                              style={{ paddingLeft: "30px" }}
                            >
                              {a.toLowerCase()}
                            </Typography>
                          </div>
                          <span>
                            <CheckBoxWithColor
                              checked={assetClass[pathtype][a] ? true : false}
                              value={assetClass[pathtype][a] ? true : false}
                              onChange={handleAssets}
                              name={a}
                            />
                          </span>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </>
          )}
          {/* <hr className='filter-divider'></hr>
                    <div className='offshore'>
                        <div className='filter-header'>
                            <Typography className='filter-header-text'>Offshore</Typography>
                            <span onClick={() => handleExpandClick('offshore')}>{expand.offshore ? <ExpandLess /> : <ExpandMore width={12} />}</span>
                        </div>
                        {expand.offshore &&
                            <div className='member-content' style={{ marginTop: '10px' }}>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="excluded"
                                    name="offshore"
                                >
                                    <FormControlLabel className='radio-label' value="excluded" control={<Radio />} label="Excluded" />
                                    <FormControlLabel className='radio-label' value="dollar" control={<Radio />} label="Included(Summary)" />
                                    <FormControlLabel className='radio-label' value="dollar" control={<Radio />} label="Show Seprately" />

                                </RadioGroup>
                            </div>
                        }
                    </div> */}

          <hr className="filter-divider"></hr>
          <div className="denomination">
            <div className="filter-header">
              <Typography className="filter-header-text">
                Denomination
              </Typography>
              <span onClick={() => handleExpandClick("denomination")}>
                {expand.denomination ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore width={12} />
                )}
              </span>
            </div>
            {expand.denomination && (
              <div className="member-content" style={{ marginTop: "10px" }}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={
                    localStorage.getItem("currency_format")
                      ? localStorage.getItem("currency_format")
                      : "actuals"
                  }
                  name="denomination"
                  onChange={(e) => {
                    setCurrencyFormat(e.target.value);
                  }}
                >
                  <FormControlLabel
                    className="radio-label"
                    value="lakhs"
                    control={<Radio />}
                    style={{ marginLeft: "0px" }}
                    label="in Lakhs"
                  />
                  <FormControlLabel
                    className="radio-label"
                    value="crores"
                    control={<Radio />}
                    style={{ marginLeft: "0px" }}
                    label="in Crores"
                  />
                  <FormControlLabel
                    className="radio-label"
                    value="actuals"
                    control={<Radio />}
                    style={{ marginLeft: "0px" }}
                    label="Actuals"
                  />
                </RadioGroup>
              </div>
            )}
          </div>

          <hr className="filter-divider"></hr>
          <div className="period">
            <div className="filter-header">
              <Typography className="filter-header-text">Period</Typography>
              <span onClick={() => handleExpandClick("period")}>
                {expand.period ? <ExpandLess /> : <ExpandMore width={12} />}
              </span>
            </div>
            {expand.period && (
              <div className="member-content" style={{ marginTop: "10px" }}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="Inception Till Date"
                  name="period"
                  value={period}
                  onChange={(e) => {
                    if (e.target.value == "Inception Till Date") {
                      setDateRange([null, null]);
                      setClear(true);
                      setPeriod(e.target.value);
                    }
                  }}
                >
                  {/* <FormControlLabel className='radio-label' value="3m" control={<Radio />} label="3M" />
                                    <FormControlLabel className='radio-label' value="dollar" control={<Radio />} label="1 year" />
                                    <FormControlLabel className='radio-label' value="rupee" control={<Radio />} label="2 year" />
                                    <FormControlLabel className='radio-label' value="dollar" control={<Radio />} label="3 year" />
                                    <FormControlLabel className='radio-label' value="rupee" control={<Radio />} label="MTD" /> */}
                  <FormControlLabel
                    className="radio-label"
                    value="Inception Till Date"
                    style={{ marginLeft: "0px" }}
                    control={<Radio />}
                    label="Inception Till Date"
                  />
                  {/* <FormControlLabel className='radio-label' value="dollar" control={<Radio />} label="QTD" /> */}
                </RadioGroup>

                <br></br>
                {/* <DatePicker
                                    className={classes.dateRange}
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        setDateRange(update);
                                        setPeriod(null)
                                    }}
                                // isClearable={true}
                                >

                                </DatePicker> */}
                <div className={classes.filterRange}>
                  <FilterCalendar
                    clear={clear}
                    setPeriod={setPeriod}
                    values={dateRange}
                    style={{ width: "300px" }}
                    maxDate={{
                      current: new Date(moment().format("YYYY-MM-DD")),
                    }}
                    onchange={(update) => {
                      // console.log("dateData", update);
                      // portfolio(update)
                      // setDateRange(update);
                      setClear(false);
                      setDateRange(update);
                      setPeriod(null);
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          <hr className="filter-divider"></hr>
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              className={classes.buttonNo}
              style={{ width: "60%", marginTop: "5%" }}
              onClick={() => {
                handleButtonClick();
                setFromContext();
              }}
            >
              <span className={`${classes.buttonText}`}>Cancel</span>
            </Button>
            {
              <Button
                variant="contained"
                className={classes.buttonYes}
                style={{ width: "60%", marginTop: "5%" }}
                onClick={() => {
                  localStorage.setItem("currency_format", currency_format);
                  handleButtonClick();
                  // console.log(checkAdvisor);
                  let save = {
                    checkData: checkData,
                    startDate: startDate,
                    endDate: endDate,
                    advisor: checkAdvisor,
                    currency: currency,
                    period: period,
                    assetclass: Object.assign({}, assetClass),
                    selectAll: selectAll,
                    selectAllAdvisor: selectAllAdvisor,
                    selectAllExecutor: selectAllExecutor,
                    executor: checkExecutor,
                  };

                  sessionStorage.setItem("filter", JSON.stringify(save));

                  let sv = JSON.stringify(save);
                  setMfofilter(JSON.parse(sv));
                }}
                disabled={disabled}
              >
                <span className={`${classes.buttonText}`}>Apply</span>
              </Button>
            }
          </div>
        </div>
      </Modal>
    </>
  );
}

export default PageFilter;
