import { Box, Grid } from "@material-ui/core";
import React from "react";
import { ReactComponent as NeoNext } from "../../assets/icons/neoNext.svg";
import { ReactComponent as MyPortfolioIcon } from "../../assets/icons/newMenuMyPortfolioIcon.svg";
import { ReactComponent as MarketTrackerIcon } from "../../assets/icons/newMenuMarketTrackerIcon.svg";
import { ReactComponent as StrategyBuilderIcon } from "../../assets/icons/newMenuStrategyBuildericon.svg";
import { ReactComponent as TransactionIcon } from "../../assets/icons/newMenuTransactionIcon.svg";
import { ReactComponent as AdvisorsIcon } from "../../assets/icons/newMenuAdvisorsIcon.svg";
import "./NewMenu.css";
import { useNavigate } from "react-router";
import { routes } from "../../routes";
import { useNewMenuStyles } from "./newMenuStyles";
export const NewMenuDashboardContent = () => {
  const navigate = useNavigate();
  const classes = useNewMenuStyles();
  return (
    <>
      <Grid container className="newMenuDashboardGridContainer">
        <Grid xs={3}>
          <Box className={classes.newMenuDashBoardItem}>
            <MyPortfolioIcon className="newMenuDashBoardIcon" />
            <h1
              className="newMenuDashboardHeading"
              onClick={() => navigate("/home/member/detail")}
            >
              My Portfolio
            </h1>
            <p className="newMenuDashboardSubHeading">
              Track, manage, and optimize your investments in one place.
            </p>
          </Box>
        </Grid>
        <Grid xs={3}>
          <Box
            className={classes.newMenuDashBoardItem}
            style={{ background: "#000000" }}
          >
            <NeoNext className="newMenuDashBoardIcon" />
            <h1 className="newMenuDashboardHeading">Neo Next</h1>
            <p className="newMenuDashboardSubHeading">
              Get insights in your inbox. Subscribe for updates and reports.
            </p>
          </Box>
        </Grid>
        <Grid xs={3}>
          <Box
            className={classes.newMenuDashBoardItem}
            style={{ background: "#000000" }}
          >
            <MarketTrackerIcon className="newMenuDashBoardIcon" />
            <h1 className="newMenuDashboardHeading">Market Tracker</h1>
            <p className="newMenuDashboardSubHeading">
              Keep a pulse on the markets. Real-time data and trends.
            </p>
          </Box>
        </Grid>
        <Grid xs={3}>
          <Box
            className={classes.newMenuDashBoardItem}
            onClick={() => navigate(routes?.strategyBuilderTargetPortfolio)}
          >
            <StrategyBuilderIcon className="newMenuDashBoardIcon" />
            <h1 className="newMenuDashboardHeading">Strategy Builder</h1>
            <p className="newMenuDashboardSubHeading">
              Design and refine your strategies with ease
            </p>
          </Box>
        </Grid>
        <Grid xs={3}>
          <Box
            className={classes.newMenuDashBoardItem}
            style={{ background: "#000000" }}
          >
            <TransactionIcon className="newMenuDashBoardIcon" />
            <h1 className="newMenuDashboardHeading">Transaction</h1>
            <p className="newMenuDashboardSubHeading">
              Record, review, and analyze your transactions seamlessly
            </p>
          </Box>
        </Grid>
        <Grid xs={3}>
          <Box
            className={classes.newMenuDashBoardItem}
            style={{ background: "#000000" }}
          >
            <AdvisorsIcon className="newMenuDashBoardIcon" />
            <h1 className="newMenuDashboardHeading">Advisors</h1>
            <p className="newMenuDashboardSubHeading">
              Expert guidance at your fingertips.
            </p>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
