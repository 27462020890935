import React, { useEffect, useState, useContext } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import { ReactComponent as Import } from "../../../../assets/icons/import.svg";
import { ReactComponent as Export } from "../../../../assets/icons/export.svg";
import { ReactComponent as Print } from "../../../../assets/icons/print.svg";
import useStyles from "../common/ProductsStyles";
import { OnboardingContext } from "../../../../Context";
import { getPortfolioSummary } from "../../../../api";
import { useNavigate } from "react-router-dom";
import Loader from "../../../loader/Loader";
import "../common/products-style.css";
import DataNotFound from "../common/DataNotFound";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import moment from "moment";
import { routes } from "../../../../routes";

function MfoPortfolio({ currencyFormat }) {
  const { userState } = OnboardingContext();
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const classes = useStyles();
  const [portfolioSummary, setPortfolioSummary] = useState([]);

  useEffect(() => {
    const clientId = userState.loginedUserData.id;
    let params = { ...getfilter_as_params() };

    setShowLoader(true);
    getPortfolioSummary(clientId, params)
      .then((res) => {
        setShowLoader(false);

        let result = res.result;

        setPortfolioSummary(result);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }, [mfoFilter]);

  return (
    <>
      {showLoader && (
        <div className="loader-wrapper">
          <Loader />
        </div>
      )}

      <div className={`${classes.alignTopHeader}`}>
        <Typography
          style={{ marginLeft: "-4%" }}
          className={`${classes.mfoGraphHeader} ${classes.mainHeadingAlign}`}
        >
          Portfolio Summary
        </Typography>
        <div className={`${classes.iconAlign}`}>
          <Import className={classes.iconStyle} />
          <Export className={classes.iconStyle} />
          <Print className={classes.iconStyle} />
        </div>
      </div>

      <div style={{ height: "auto" }} className={classes.mainContent}>
        <Grid xs={12}>
          <div className={classes.tableContainerAlign}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    style={{ borderRadius: "20px 0px 0px 20px" }}
                    className={classes.tableHeadText}
                  >
                    Scheme Name
                  </TableCell>
                  <TableCell align="right" className={classes.tableHeadText}>
                    Investment Value{" "}
                  </TableCell>
                  <TableCell align="right" className={classes.tableHeadText}>
                    Present Value
                  </TableCell>
                  <TableCell align="right" className={classes.tableHeadText}>
                    Divided Payout
                  </TableCell>
                  <TableCell align="right" className={classes.tableHeadText}>
                    Unrealised Gain/Loss
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ borderRadius: "0px 20px 20px 0px" }}
                    className={classes.tableHeadText}
                  >
                    XIRR(%)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {portfolioSummary?.map((s) => {
                  return (
                    <TableRow>
                      <TableCell
                        style={{
                          borderTopLeftRadius: "20px",
                          borderBottomLeftRadius: "20px",
                        }}
                        align="left"
                        className={classes.tableBodyText}
                      >
                        <a
                          className="cursor"
                          onClick={() => {
                            navigate(routes.mfRiskDetailsPage, {
                              state: s.isin,
                            });
                          }}
                        >
                          {s.schemeName}
                        </a>
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableBodyText}
                      >
                        {currencyFormat(s.investmentValue)}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableBodyText}
                      >
                        {currencyFormat(s.presentValue)}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableBodyText}
                      >
                        {currencyFormat(s.dividendPayout)}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableBodyText}
                      >
                        {currencyFormat(s.unrealisedGainLoss)}
                      </TableCell>
                      <TableCell
                        style={{
                          borderTopRightRadius: "20px",
                          borderBottomRightRadius: "20px",
                        }}
                        align="right"
                        className={classes.tableBodyText}
                      >
                        {Number(s.xirr).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {portfolioSummary.length === 0 && <DataNotFound></DataNotFound>}
          </div>
        </Grid>
      </div>
    </>
  );
}

export default MfoPortfolio;
