import React, { useEffect, useState, useContext } from "react";
import "../common/ProductsStyles";
import useStyles from "../common/ProductsStyles";
import EquitySubTab from "./EquitySubTab";
// import { useSectorAllocation } from "../../../../hooks/useSectorAllocation";
import Loader from "../../../loader/Loader";
import { roundNumber } from "../../../utils/common";
import { currencyFormat } from "../../../../hooks/constantFunction";
import DataNotFound from "../common/DataNotFound";
import BoxChart from "../common/BoxChart";
import { fetchEquitySectorAllocationGainLoss } from "../../../../api";
import { OnboardingContext } from "../../../../Context";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import PmsReportTable from "../pms/PmsReportTable";
import { ITEMS_LIMIT_FILES_PARTNER_LIST } from "../../../../Constants";
import moment from "moment";
import { pie_onClick } from "../../../charts/nivo_objects";
import { colors_allocation } from "../../../charts/colors";
import { Grid } from "@material-ui/core";
import PieChart from "../../../charts/PieChart";
export default function EquitySector() {
  const classes = useStyles();
  // const [sectorAllocationData] = useSectorAllocation();
  const [loading, setLoading] = useState(false);
  const [toggle, settoggle] = useState(false);
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const { userState } = OnboardingContext();
  let clientId = userState.loginedUserData.id;
  const tableColumnsData = [
    { field: "type", title: "Sector", show: true, align: "center" },
    {
      field: "investedAmount",
      title: "Invested Value",
      show: true,
      align: "center",
    },
    { field: "withDrawalAmount", title: "Withdrawals", show: true },
    { field: "portfolioValueAmount", title: "Current Value", show: true },
    { field: "realisedGainLoss", title: "Realised Gain/Loss", show: true },
    { field: "unRealisedGainLoss", title: "Unrealized Gain/Loss", show: true },
    ,
    {
      field: "interestIncome",
      title: "Dividend Received",
      show: true,
    },
    {
      field: "totalGainLoss",
      title: "Total Gain/Loss",
      show: true,
    },

    { field: "xirr", title: "XIRR (%)", show: true },
    { field: "absoluteTwrr", title: "Absolute TWRR (%)", show: true },
    { field: "annualisedTwrr", title: "Annualized TWRR (%)", show: true },
  ];
  const [search, setSearch] = useState("");
  const [tableHeader, setTableHeader] = useState(tableColumnsData);
  const [newTableColumns, setNewTableColumns] = useState([]);
  const [totalValue, setTotalValue] = useState(0);
  const [pagiNationData, setPageinationData] = useState({
    pageCount: null,
    // limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
  });
  const [piecolors, setPieColors] = useState(colors_allocation);
  const [pieData, setPieData] = useState([]);
  const [pieState, setPieState] = useState({
    title: "",
    value: "",
    colors: piecolors,
    disabled: [],
    hide: false,
  });
  const [offset, setOffset] = useState(0);
  const objectFormating = (data) => {
    let newData = tableHeader.map((header) => {
      if (header?.field == "memberName") {
        return {
          key: header?.field,
          field: data[`${header.field}`],
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (header?.field == "annualisedTwrr") {
        return {
          key: header?.field,
          field: roundNumber(data[`${header.field}`]) || "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }

      if (
        header?.field == "investedAmount" ||
        header?.field == "portfolioValueAmount" ||
        header?.field == "realisedGainLoss" ||
        header?.field == "interestIncome" ||
        header?.field == "unRealisedGainLoss" ||
        header?.field == "currentValue" ||
        header?.field == "otherIncome" ||
        header?.field == "withDrawalAmount" ||
        header?.field == "totalGainLoss"
      ) {
        return {
          key: header?.field,
          field:
            currencyFormat(data[`${header.field}`] || 0, {
              showDecimal: true,
            }) || 0,
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      return {
        key: header?.field,
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`]))
          : data[`${header.field}`] || "--",
        title: header.title,
        show: header.show,
        align: header?.align,
      };
    });
    return newData;
  };
  useEffect(() => {
    if (!search) {
      setLoading(true);
    }
    getSummary();
  }, [mfoFilter, offset, search]);
  let getSummary = () => {
    setLoading(true);
    let params = {
      ...getfilter_as_params(),
      //   assetClass: page,
      offset: offset,
      limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
      reportType: "dashboard",
    };

    if (search) {
      params["search"] = search;
    }
    if (mfoFilter.startDate) {
      params["startDate"] = moment(mfoFilter.startDate).format("YYYY-MM-DD");
      params["endDate"] = moment(mfoFilter.endDate).format("YYYY-MM-DD");
    }
    if (Object.keys(mfoFilter.checkData).length > 0) {
      params["memberId"] = Object.keys(mfoFilter.checkData).join(",");
    }
    if (Object.keys(mfoFilter.advisor).length > 0) {
      params["advisor"] = Object.keys(mfoFilter.advisor).join(",");
    }

    fetchEquitySectorAllocationGainLoss(userState.loginedUserData.id, params)
      .then((res) => {
        setPageinationData((prev) => {
          return { ...prev, pageCount: res?.data?.data?.meta?.count };
        });
        // setTableData(res?.data?.data?.result);
        //new table implimentation
        let newList = [];
        let newPieData = [];
        res?.data?.data?.result?.map((val, i) => {
          let obj = objectFormating(val);
          newList.push(obj);

          if (val.type !== "total") {
            newPieData.push({
              id: val?.type.charAt(0).toUpperCase() + val.type.slice(1),
              label: val.type,
              value: val.portfolioValueAmountPercentage,
              color: colors_allocation[i],
              amount: val.portfolioValueAmount,
            });
          } else {
            setTotalValue(val.portfolioValueAmount);
          }
        });
        setPieData(newPieData);
        setNewTableColumns(newList);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  console.log("piedataram", pieData);
  return (
    <>
      <EquitySubTab
        title="Sector Allocation"
        toggle={toggle}
        // settoggle={settoggle}
      ></EquitySubTab>
      <Grid xs={12}>
        {newTableColumns.length !== 0 && (
          <div className="flex_center tr20 " style={{ overflow: "hidden" }}>
            <div style={{ width: "380px" }}>
              <PieChart
                config={{
                  innerRadius: "0.80",
                  cornerRadius: 0,
                  padAngle: 0,
                  legends: false,
                  disableArcLink: true,
                  minWith: "380px",
                  margin: { right: 80, top: 20, left: 80 },

                  chart: { width: 420, height: 340 },
                  arcLinkLabel: (d) => `${d.value} %`,
                  // disableArcLink:pieState.hide?true:false
                }}
                colors={pieState.colors}
                onClickEvent={{
                  click: pie_onClick,
                  set: setPieState,
                  cstate: pieState,
                  ncolors: piecolors,
                }}
                data={pieData}
              >
                <div
                  className="cen"
                  style={{
                    color: "#fff",
                    transform: "translate(69%, -47%)",
                    top: "37%",
                  }}
                >
                  <div className="center-info">
                    <div>
                      <div
                        className="glowcircle"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div>
                          <div className="circle_text3">
                            {!pieState.title || pieState.title == ""
                              ? "Equity"
                              : pieState.title}
                          </div>

                          <div className="circle_text2">
                            {pieState.amount != null
                              ? currencyFormat(pieState.amount, {
                                  showDecimal: true,
                                })
                              : totalValue != null
                              ? currencyFormat(totalValue, {
                                  showDecimal: true,
                                })
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </PieChart>
            </div>
          </div>
        )}
      </Grid>
      <PmsReportTable
        productType="memberWisePerformance"
        searchParam={search}
        setTableColumns={setNewTableColumns}
        tableColumns={newTableColumns}
        // setOpenModal={setOpenModal}
        // openModal={openModal}
        loading={loading}
        headerList={tableColumnsData}
        tableHeader={tableHeader}
        setTableHeader={setTableHeader}
        stickyProductName={true}
        // reset={getSummary}
        pageCount={pagiNationData?.pageCount}
        setItemOffset={setOffset}
        pageNumber={offset / ITEMS_LIMIT_FILES_PARTNER_LIST + 1 || 1}
        hidePagination={true}
      />
    </>
  );
}
