import React, { useContext } from "react";
import useStyles from "../common/ProductsStyles";
import { useState } from "react";
import _ from "lodash";
import PageFilter from "../common/PageFilter";
import { useNavigate } from "react-router-dom";
import { fetchHoldingSummary, getTransactionsSummary } from "../../../../api";
import { OnboardingContext } from "../../../../Context";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import PageLoader from "../common/pageLoader";
import { trasactionTableConfig, tabData } from "./constands";
import REHolding from "./REHolding";
import RETransaction from "./RETransaction";
import { dateFormatCommon, roundNumber } from "../../../utils/common";
import NewTabComp from "../common/NewTabComp";
import { routes } from "../../../../routes";
import NewtableTopSec from "../../common/NewtableTopSec";
import { currencyFormat } from "../../../../hooks/constantFunction";
import { NewFilter } from "../../newFilter/NewFilter";
function RealEstateReport({ pageName }) {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userState } = OnboardingContext();
  const { getfilter_as_params } = useContext(mfoFilterContext);
  const [search, setSearch] = useState("");
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [offset, setOffset] = useState(0);

  const objectFormating = (data, headerConfig) => {
    let newData = headerConfig.map((header) => {
      if (header?.field == "projectCode" || header?.field == "executed_by") {
        return {
          key: header?.field,
          field: data[`${header.field}`] ? data[`${header.field}`] : "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (
        header?.field == "TWRR_annualised" ||
        header?.field == "annualisedTwrr"
      ) {
        return {
          key: header?.field,
          field: roundNumber(data[`${header.field}`]) || "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (
        header?.field == "value" ||
        header?.field == "loan_amount" ||
        header?.field == "loanAmount" ||
        header?.field == "remaining_loan_amount" ||
        header?.field == "remainingLoanAmount" ||
        header?.field == "market_value" ||
        header?.field == "self_funding_amount" ||
        header?.field == "selfFundingAmount" ||
        header?.field == "annual_interest_paid_on_loan" ||
        header?.field == "annualInterestPaidOnLoan" ||
        header?.field == "unrealised_gain_loss" ||
        header?.field == "unRealisedGainLoss" ||
        header?.field == "realised_gain_loss" ||
        header?.field == "realisedGainLoss" ||
        header?.field == "rental_incom_PA" ||
        header?.field == "maintainence_PA" ||
        header?.field == "property_tax_PA" ||
        header?.field == "investmentValue" ||
        header?.field == "presentValue" ||
        header?.field == "totalGainLoss"
      ) {
        return {
          key: header?.field,
          field: currencyFormat(data[`${header.field}`] || 0) || "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (header?.field == "holdingPeriod") {
        return {
          key: header?.field,
          field: data[`${header.field}`]
            ? data[`${header.field}`] + " days"
            : "--",

          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (header?.field == "transaction_date" || header?.field == "date") {
        return {
          key: header?.field,
          field: dateFormatCommon(new Date(data[`${header.field}`])),
          // moment(new Date(data[`${header.field}`])).format(
          //   "DD - MM - YYYY"
          // ) || "--",
          title: header.title,
          show: header.show,
          align: header?.align || "right",
        };
      }
      if (
        header?.field == "city" ||
        header?.field == "state" ||
        header?.field == "country" ||
        header?.field == "address"
      ) {
        return {
          key: header?.field,
          field: data[`${header.field}`] || "--",
          title: header.title,
          show: header.show,
          align: header?.align || "right",
        };
      }
      return {
        key: header?.field,
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`]))
          : data[`${header.field}`] || "--",
        title: header.title,
        show: header.show,
        align: header?.align || "right",
      };
    });

    return newData;
  };

  // console.log(loading, "asgknag");

  const getTableData = async ({
    setTableColumns,
    config,
    setCheckedTrans,
    schemeName,
    limit,
    offset,
    setPageinationData,
    pagiNationData,
    numberOfInstrumentsCount,
    setPercentageOfTotalPortfolio,
    setNumberOfInstruments,
  }) => {
    setLoading(true);
    let params = {
      clientId: userState.loginedUserData.id,
      limit: limit,
      offset: offset,
      // clientId: 1,
      ...getfilter_as_params(),
      search: schemeName,
    };
    // if (get_path_type(window.location.pathname) == "real-estate") {
    params["assetClass"] = null;

    try {
      // setLoading(true);
      let newlist = [];
      let transactionList = [];
      if (pageName !== "holding") {
        // setLoading(true);
        // let params = {
        //   clientId: 1,
        // };

        let response = await getTransactionsSummary(
          "re",
          "transaction-summary",
          params
        );
        setPageinationData((prev) => {
          return { ...prev, pageCount: response?.data?.data?.meta?.count };
        });

        if (pageName == "holding") {
          if (numberOfInstrumentsCount.current === 0) {
            setNumberOfInstruments(response?.data?.data?.meta?.count);
          }
          numberOfInstrumentsCount.current =
            numberOfInstrumentsCount.current + 1;
          setPercentageOfTotalPortfolio(
            response?.data?.data?.total?.percentageOfTotalPortfolio
          );
        }

        response?.data?.data?.result?.summary?.map((val) => {
          val.advisor = val?.advisor + "%" + val?.updated_at;
          let obj = objectFormating(val, config);
          newlist.push(obj);
          if (
            val["transaction_type"] != null &&
            !transactionList
              .map((val) => val?.title)
              .includes(val["transaction_type"])
          ) {
            transactionList.push({
              title: val["transaction_type"],
              show: false,
            });
          }
        });
        setCheckedTrans(transactionList);
        setTableColumns(newlist);
        setLoading(false);
      } else {
        // setLoading(true);
        /* Holding Summary API Call */
        fetchHoldingSummary(
          userState.loginedUserData.id,
          {
            ...params,
          },
          "re"
        )
          .then((res) => {
            let transactionList = [];
            console.log(res, "aksjbgj");
            setPageinationData((prev) => {
              return { ...prev, pageCount: res?.data?.data?.meta?.count };
            });
            res?.data?.data?.result?.map((val) => {
              val.advisor = val?.advisor + "%" + val?.updated_at;
              let obj = objectFormating(val, config);
              newlist.push(obj);
              if (
                val["transactionType"] != null &&
                !transactionList
                  .map((val) => val?.title)
                  .includes(val["transactionType"])
              ) {
                transactionList.push({
                  title: val["transactionType"],
                  show: false,
                });
              }
            });
            setTableColumns(newlist);

            setCheckedTrans(transactionList);
            setLoading(false);
            // setHoldingSummary(res?.data?.data?.result);
          })
          .catch((error) => {
            setLoading(false);
          });
        // setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleIconClick = () => {
    setOpenModal(!openModal);
  };

  return (
    <>
      <PageFilter
        backLink={routes.realEstateSummaryPage}
        setFilterModalOpen={setFilterModalOpen}
      ></PageFilter>
      <NewFilter
        filterModalOpen={filterModalOpen}
        setFilterModalOpen={setFilterModalOpen}
        productType="re"
      />
      <div style={{ margin: "2%" }}>
        <NewTabComp
          data={tabData}
          customWidth={"100%"}
          customSelect={tabData.findIndex((val) => val?.page == pageName)}
          customHandle={() => {
            setOffset(0);
          }}
        />
      </div>
      <div>
        <NewtableTopSec
          handleIconClick={handleIconClick}
          directPageName={"Holding Summary"}
          setSearch={
            pageName === "holding" &&
            ((e) => {
              setSearch(e);
              setOffset(0);
            })
          }
          // product={"MLD"}
        />
        <PageLoader showLoader={loading} />
        {pageName == "holding" ? (
          <REHolding
            getTableData={getTableData}
            openModal={openModal}
            setOpenModal={setOpenModal}
            loading={loading}
            search={search}
            setOffset={setOffset}
            offset={offset}
          />
        ) : (
          <RETransaction
            getTableData={getTableData}
            openModal={openModal}
            setOpenModal={setOpenModal}
            loading={loading}
            setOffset={setOffset}
            offset={offset}

            // search={search}
          />
        )}
      </div>
    </>
  );
}

export default RealEstateReport;
