import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import NewtableTopSec from "../../common/NewtableTopSec";
import PmsReportTable from "../pms/PmsReportTable";
import { useClientSearch } from "../../../../hooks/useClientSearch";
import NewTabComp from "../common/NewTabComp";
import {
  tableConfigHoldingSummary,
  holdingSummaryTableNavConfig,
  tableConfigTransactionSummaryCorporateActions,
  tableConfigUpcomingTransaction,
  tableConfigHoldingSummaryPms,
} from "./utils/constands";
import { holdingSummary } from "./utils/staticData";
import {
  objectFormating1,
  objectFormatingupcomingTransection,
} from "../../../utils/objectFormating";
import { Checkbox, FormControlLabel, Select } from "@material-ui/core";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import DeleteList from "./DeleteList";
import DeleteTransactionsIcon from "../common/DeleteTransactionsIcon";
import useGetTableHolding from "../../../../hooks/useGetTableHolding";
import { ITEMS_LIMIT_FILES_PARTNER_LIST } from "../../../../Constants";
import { OnboardingContext } from "../../../../Context";
import { deleteTransaction } from "../../../../api";
import ls from "localstorage-slim";
import { useNonEmptyProductList } from "../../../../hooks/useNonEmptyProductList";
import {
  bankHoldingConfig,
  bankTransactionConfig,
  fundsInTransitTransactionConfig,
} from "../bankBook/bankBoonConfig";
import { isArray } from "lodash";
import { summarizeTableData } from "../../../utils/common";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import { useBankSummary } from "../../../../hooks/useBankSummary";
import { isEmptyArray } from "formik";
import { useCurrentHoldingCardsApi } from "../../../../hooks/useCurrentHoldingCardsApi";

import NewHoldingButtons from "../../../common/NewHoldingButtons";
import UpcomingTransaction from "./UpcomingTransaction";
function HoldingSummary({
  tableConfig,
  type,
  extype,
  showCorporateActions,
  tableName,
  typeOfPage,
}) {
  const { userState } = OnboardingContext();
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);

  const listobj = useNonEmptyProductList({
    dataType: extype || type,
    typeOfPage,
  });
  const { nonEmptyProductList } = listobj;
  const [tabData, setTabData] = useState([]);

  console.log(
    "nonEmptyProductList",
    // transformedData,
    holdingSummaryTableNavConfig
  );

  let clientId = userState.loginedUserData.id;
  const scopes = JSON.parse(ls.get("scopes"));
  const checked = React.cloneElement(showCorporateActions, {}, null).props
    .checked;

  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState("");
  const [tableHeader, setTableHeader] = useState(
    // tableConfig || tableConfigHoldingSummary
    []
  );
  const [selectedProd, setSelectedProd] = useState();

  let productsHavingCorporateActionsToggle = [
    "equity",
    "mfo",
    "reits",
    "invits",
    "private",
  ];
  const [pie, setPie] = useState({
    title: "",
    value: "",
    colors: Object.values({}),
    disabled: [],
    fill: [
      {
        match: {
          id: "Commerical",
        },
        id: "c9",
      },
      {
        match: {
          id: "Residential",
        },
        id: "c10",
      },
    ],
  });

  const newTableConfig = useMemo(() => {
    if (type == "transaction") {
      if (selectedProd?.transactionConfig) {
        // setTableHeader(() => selectedProd?.transactionConfig);
        return selectedProd?.transactionConfig;
      } else {
        return checked
          ? tableConfigTransactionSummaryCorporateActions
          : tableConfig;
      }
    } else {
      if (selectedProd?.holdingConfig) {
        // console.log(selectedProd?.holdingConfig, "aglagnk");
        // setTableHeader(() => selectedProd?.holdingConfig);
        return selectedProd?.holdingConfig;
      } else {
        return tableConfig;
      }
    }
  }, [selectedProd, checked]);

  // const [loading, setLoading] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);
  const [secondTableColumns, setSecondTableColumns] = useState([]);
  const [page, setPage] = useState(null);
  const [assetTotalData, setAssetTotalData] = useState({});

  const { bankSummary } = useBankSummary({ setPie, setAssetTotalData });
  const [key, setKey] = useState("equity");
  function checkPage(page) {
    if (page === "re") {
      return "real-estate";
    } else if (page === "fd") {
      return "fixed-deposit";
    } else if (page === "rd") {
      return "recurring-deposit";
    } else if (page === "ins") {
      return "insurance";
    } else {
      return page;
    }
  }
  let productSubCategory = "";
  let insType = "";
  if (page === "ins") {
    productSubCategory = "ulip";
    insType = "life";
  }

  const { holdingCardData, xirrCurrentHolding, twrrCurrentHolding } =
    useCurrentHoldingCardsApi(true, page, productSubCategory, insType, type);

  const [offset, setOffset] = useState(0);
  // const { searchResults } = useClientSearch(search, tableColumns);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteList, setDeleteList] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const {
    getTableData,
    loading,
    tableData,
    paginationData,
    percentageOfTotalPortfolio,
    numberOfInstruments,
    checkedTrans,
  } = useGetTableHolding({
    tableName: type,
    product: page,
    offset: offset,
    sortBy: "DESC",
    search,
    showCorpAction: checked,
    setDeleteList,
    setShowDelete,
    setTableColumns,
    promoterStake: mfoFilter.promoterStake,
  });
  // const [pageNumber, setPageNumber] = useState(1);

  const handleIconClick = () => {
    setOpenModal(!openModal);
  };

  const handleClick = React.useCallback(
    (page1, key, data) => {
      if (page1 != page) {
        setTableColumns(() => []);
      }

      setSelectedProd(() => data);
      setDeleteList(() => []);
      setPage(page1);
      setOffset(0);
      setSearch("");
      setKey(key);
      // setPageNumber(1);
    },
    [page]
  );

  //step 1 fix header config
  const handleHeader = (tableConfig, page1) => {
    let newconfog = tableConfig.map((val) => {
      if (val?.field == "select") {
        return {
          ...val,
          show: showDelete,
        };
      }

      return val;
    });

    setTableHeader(() => newconfog);
    handleData(newconfog, page1);
  };

  //step 2 format data with fixed header config
  const handleData = (newconfog, page1) => {
    let list = [];
    // console.log(page1, "page1");
    tableData?.map((val, i) => {
      //Adding ceckbox component as a value
      if (type == "transaction") {
        val.select = (
          <>
            <Checkbox
              onChange={() => {
                handleDeleteSelect({ [i]: val });
              }}
              // onClick={() => {}}
              icon={<div className="checkBoxCell"></div>}
              checkedIcon={
                <div className="checkBoxCell">
                  <CheckCircleRoundedIcon
                    style={{
                      width: "18px",
                      height: "18px",
                      color: "#6d30cf",
                      flexShrink: "0",
                    }}
                    color="#6d30cf"
                  />
                </div>
              }
            />
          </>
        );
      }
      // This handling is required due to backend is sending
      //different keys jhb
      if (type == "transaction") {
        if (
          key !== "bank" &&
          key !== "funds-in-transit" &&
          key != "upcoming transaction" &&
          page1 != "upcoming transaction"
        ) {
          if (val["scheme_name"]) {
            newconfog[0].field = "scheme_name";
          } else if (val["schemeName"]) {
            newconfog[0].field = "schemeName";
          } else if (val["bankName"]) {
            newconfog[0].field = "bank_name";
          } else if (val["project_name"]) {
            newconfog[0].field = "project_name";
          } else {
            newconfog[0].field = "scrip_name";
          }
          if (val["total_transaction_value"]) {
            newconfog[16].field = "total_transaction_value";
          } else if (val["amount"]) {
            if (newconfog.length > 15) {
              newconfog[16].field = "amount";
            }
          } else {
            newconfog[16].field = "value";
          }
          if (val["gross_rate_per_unit"]) {
            newconfog[11].field = "gross_rate_per_unit";
          } else if (val["price"]) {
            newconfog[11].field = "price";
          } else {
            newconfog[11].field = "nav";
          }
          if (val["quantity"]) {
            newconfog[10].field = "quantity";
          } else {
            newconfog[10].field = "units";
          }

          if (val["amount"]) {
            newconfog[16].field = "amount";
          } else if (val["total_transaction_value"]) {
            newconfog[16].field = "total_transaction_value";
          } else if (val["value"]) {
            newconfog[16].field = "value";
          } else {
            newconfog[16].field = "invested_amount";
          }
        }
      } else {
        if (key !== "bank" && key !== "funds-in-transit") {
          if (val["scripName"]) {
            newconfog[0].field = "scripName";
          } else if (val["schemeName"]) {
            newconfog[0].field = "schemeName";
          } else if (val["bankName"]) {
            newconfog[0].field = "bankName";
          } else {
            newconfog[0].field = "projectName";
          }
          if (val["nav"]) {
            type === "security-holding"
              ? (newconfog[7].field = "nav")
              : (newconfog[10].field = "nav");
          } else {
            type === "security-holding"
              ? (newconfog[7].field = "currentPrice")
              : (newconfog[10].field = "currentPrice");
          }

          if (val["isin"]) {
            type === "security-holding"
              ? (newconfog[1].field = "isin")
              : (newconfog[3].field = "isin");
          } else {
            type === "security-holding"
              ? (newconfog[1].field = "projectCode")
              : (newconfog[3].field = "projectCode");
          }
        }
      }
      // console.log(newconfog, "jnjn");
      if (page == "upcoming transaction") {
        // let newconfig = { ...newconfog[0], field: "schemeName" };
        // newconfog[0] = newconfig;
        list.push(objectFormatingupcomingTransection(val, newconfog));
      } else {
        list.push(objectFormating1(val, newconfog));
      }
    });
    // console.log(list, "jnjn");
    // On Tab Switch while loading empty the tableColumns so that previous tab data is not shown in table
    if (loading) {
      setTableColumns([]);
    } else {
      setTableColumns(() => list);
    }
  };
  useEffect(() => {
    setSecondTableColumns(
      summarizeTableData(
        tableColumns,
        holdingCardData,
        xirrCurrentHolding,
        twrrCurrentHolding,
        numberOfInstruments,
        "portfolio-holding", // 🟢 typeOfPage To check and render '--' for product name column
        page, // typeOfProduct,
        assetTotalData.total,
        percentageOfTotalPortfolio
      )
    );
  }, [tableColumns, holdingCardData, xirrCurrentHolding, twrrCurrentHolding]);

  const handleDeleteSelect = (row) => {
    setDeleteList((prev) => {
      if (Object.keys(prev).includes(Object.keys(row)[0])) {
        delete prev[`${Object.keys(row)[0]}`];
        return { ...prev };
      }
      return { ...prev, ...row };
    });
  };

  const handleDeleteClick = () => {
    if (Object.values(deleteList).length !== 0) {
      setOpenDeleteModal(true);
    }
  };

  useEffect(() => {
    // console.log(newTableConfig, "agnlagj");
    // if (tableData?.length > 0) {

    // }
    handleHeader(
      newTableConfig,
      page
      // ? newTableConfig : tableConfigHoldingSummary
    );
    nonEmptyProductList?.map((item) => {
      const key = Object.keys(item)[0];
      const title = item[key];

      let specialConfig = {};

      if (key === "bank") {
        specialConfig = {
          transactionConfig: bankTransactionConfig,
          holdingConfig: bankHoldingConfig,
        };
      } else if (key === "funds-in-transit") {
        specialConfig = {
          transactionConfig: fundsInTransitTransactionConfig,
        };
      }

      return { page: key, title, key, ...specialConfig };
    });
    let tabdata = nonEmptyProductList?.map((item) => {
      const key = Object.keys(item)[0];
      const title = item[key];

      let specialConfig = {};

      if (key === "bank") {
        specialConfig = {
          transactionConfig: bankTransactionConfig,
          holdingConfig: bankHoldingConfig,
        };
      } else if (key === "funds-in-transit") {
        specialConfig = {
          transactionConfig: fundsInTransitTransactionConfig,
        };
      }
      console.log("key", key);
      if (key.toLowerCase() == "pms" && type == "holdings") {
        specialConfig = {
          holdingConfig: tableConfigHoldingSummaryPms,
        };
      }
      return { page: key, title, key, ...specialConfig };
    });

    if (typeOfPage == "transaction") {
      tabdata?.push({
        id: tabdata.length,
        key: "upcoming transaction",
        page: "upcoming transaction",
        title: "Upcoming Transaction",
        transactionConfig: tableConfigUpcomingTransaction,
      });
    }
    setTabData(tabdata);
    if (page == "bank") {
      setSelectedProd({
        id: 0,
        key: "bank",
        page: "bank",
        title: "Bank Book",
        ...{
          transactionConfig: bankTransactionConfig,
          holdingConfig: bankHoldingConfig,
        },
      });
    }
    if (page == "funds-in-transit") {
      setSelectedProd({
        id: 0,
        key: "funds-in-transit",
        page: "funds-in-transit",
        title: "Funds In Transit",
        ...{
          transactionConfig: fundsInTransitTransactionConfig,
        },
      });
    }
    setKey(page);
  }, [showDelete, tableData, newTableConfig, checked, page, loading]);

  useEffect(() => {
    if (!isArray(nonEmptyProductList)) {
      return;
    }
    if (!isEmptyArray(nonEmptyProductList)) {
      let pg = Object.keys(nonEmptyProductList[0])[0];
      setPage(pg);
    }
  }, [nonEmptyProductList]);
  // console.log("fsdfsdsadsfd", checked, type, tableHeader, newTableConfig);

  const deleteClick = () => {
    setShowDelete((prev) => !prev);
  };

  const handleDeletion = useCallback(async () => {
    const transactionsIdsToDelete = Object.values(deleteList)
      .map(({ id }) => id)
      .join(",");
    await deleteTransaction(clientId, key, transactionsIdsToDelete);
    setOpenDeleteModal(false);
    setShowDelete();
    getTableData();
    setDeleteList(() => []);
  }, [deleteList]);

  return (
    <div>
      <NewtableTopSec
        key={page}
        handleIconClick={handleIconClick}
        directPageName={"Holding Summary"}
        setSearch={(e) => {
          setOffset(0);
          // setPageNumber(1);
          setSearch(e);
        }}
        product={null}
        hideTableEdit={showDelete}
        icons={
          type == "transaction" &&
          scopes.can_delete_transaction_summary &&
          page != "upcoming transaction"
            ? [
                <DeleteTransactionsIcon
                  deleteBtnProps={{
                    disabled: Object.values(deleteList).length == 0,
                    handleDeleteClick: handleDeleteClick,
                    deleteCancel: () => {
                      deleteClick();
                      setDeleteList(() => []);
                    },
                    showDelete,
                    setShowDelete,
                  }}
                />,
              ]
            : []
        }
        // Show Corporate Action Toggle
        iosSwitch={
          type === "transaction" &&
          productsHavingCorporateActionsToggle.includes(page) &&
          showCorporateActions
        }
      />
      <div style={{ margin: "2%" }}>
        <NewHoldingButtons
          selected={page}
          handleChange={handleClick}
          btnArray={tabData}
        />
      </div>
      {openDeleteModal && (
        <DeleteList
          handleClose={() => {
            setOpenDeleteModal(false);
            setDeleteList(() => []);
            setShowDelete((prev) => !prev);
          }}
          open={openDeleteModal}
          seletedList={deleteList}
          submitDelete={() => handleDeletion()}
          tableConfig={
            page === "bank"
              ? newTableConfig?.filter((val) => val?.field !== "select")
              : undefined
          }
        />
      )}
      {/* {tableColumns?.length > 0 && ( */}
      {/* {typeOfPage == "transaction" && page == "upcoming transaction" && (
        <UpcomingTransaction
          typeOfPage={"upcoming-transaction"}
          tableName={"Upcoming Transactions"}
          key={"upcoming-transaction"}
          type={"upcoming-transaction"}
          showCorporateActions={<></>}
          tableConfig={newTableConfig}
        />
      )} */}
      {
        <PmsReportTable
          tableName={tableName}
          key={tableColumns}
          productType="pmsHoldingDebt"
          setTableColumns={setTableColumns}
          tableColumns={tableColumns}
          setSecondTableColumns={setSecondTableColumns}
          tableColumns2={secondTableColumns}
          setOpenModal={setOpenModal}
          openModal={openModal}
          // tableName={"Debt"}
          loading={loading || listobj.loading}
          pageName="holding"
          checkedTransList={page === "upcoming transaction" ? checkedTrans : []}
          headerList={
            newTableConfig
            // || tableConfigHoldingSummary
          }
          tableHeader={tableHeader}
          setTableHeader={setTableHeader}
          stickyProductName={true}
          pageCount={paginationData?.pageCount}
          setItemOffset={setOffset}
          productName={null}
          customTableClass={showDelete && "selectDelete"}
          // setPageNumber={setPageNumber}
          pageNumber={offset / ITEMS_LIMIT_FILES_PARTNER_LIST + 1 || 1}
          // setPageNumber
        />
      }
      {/* )} */}
    </div>
  );
}

export default HoldingSummary;
