import React, { useEffect, useState } from "react";
import {
  FormControl,
  Grid,
  Button,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  FormHelperText,
  TextField,
  InputAdornment,
  DialogTitle,
  DialogContentText,
  useTheme,
  Checkbox,
} from "@material-ui/core";
import Default from "../components/dashboard/defaultPages/Default";
import YellowArrow from "../assets/icons/yellowNextArrow1.png";
import { ReactComponent as UploadBulbicon } from "../assets/icons/upload_bulb.svg";
import { ReactComponent as RightAlertIcon } from "../assets/icons/rightAlertIcon.svg";
import { ReactComponent as IIcon } from "../assets/icons/iIcon.svg";
import { ReactComponent as Close } from "../assets/icons/close_esx.svg";
import Icon from "@material-ui/core/Icon";
import {
  ENTITY_TYPE_CLIENT,
  FILE_TYPES,
  PRODUCT_TYPES,
  exportType,
  reportType,
} from "../Constants";
import { OnboardingContext } from "../Context";
import Loader from "../components/loader/Loader";
import CustomizedSnackbars from "../components/common/CustomizedSnackbars";
import {
  downloadSampleFile,
  getClientList,
  uploadFile,
  uploadValuationFile,
  uploadBulk,
  uploadValuationFileUnitBased,
  uploadValuationFilePayoutReduction,
  fetchClientByMultiId,
  RequestReport,
  fetchClientByPartner,
  ScheduleRequestReport,
} from "../api";
import { useNavigate } from "react-router-dom";
import AsynchronousInput from "../components/dashboard/common/AsynchronousInput";
import {
  downloadSampleFileValidate,
  uploadSampleFileValidate,
} from "../hooks/validationFunctions";
import _ from "lodash";
import { saveAs } from "file-saver";
import { useRef } from "react";
import { getFileTypeByProduct } from "../components/utils/common";
import NewTabComp from "../components/dashboard/products/common/NewTabComp";
import ls from "localstorage-slim";
import excelLogo from "../assets/icons/excel.png";
import LinearProgress from "@material-ui/core/LinearProgress";
// import SelectDate from "../components/calendar/SelectDate";
import moment from "moment";
import { useForm } from "../components/NewSignUp/hooks/useForm";
import AsyncCheckbox from "../components/dashboard/common/AsyncCheckbox";
import { useAllClients } from "../components/dashboard/newFilter/hooks/useAllClients";
import {
  denominationOptions,
  denominations,
} from "../components/dashboard/newFilter/newFilterConstants";
import MultiSelect from "../components/dashboard/common/MultiSelect";
import SingleSelect from "../components/dashboard/common/SingleSelect";
import { printForm, scheduleForm } from "../hooks/ValidationSchema";

import ErrorMessageComp from "../components/NewSignUp/common/ErrorMessageComp";
import { AlertDialog } from "../components/dashboard/common/AlertDialog";
const browseIcon = (
  <svg
    width="8"
    height="6"
    viewBox="0 0 8 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.27295 6L7.73705 0H0.808848L4.27295 6Z" fill="#C3AAFF" />
  </svg>
);

const useStyles = makeStyles((theme) => ({
  contentBox: {
    background: "rgba(11, 3, 24, 0.6)",
    border: "1px solid #4E189F",
    boxShadow: "0px 0px 10px #4E189F",
    backdropFilter: "blur(50px)",
    borderRadius: "50px",
    position: "relative",
    // left: "10%",
    width: "80%",
    top: "20px",
    margin: "15px 20px 20px 20px",
    [theme.breakpoints.down("md")]: {
      left: "10%",
      width: "65%",
      top: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      left: "5%",
      width: "90%",
      top: "20px",
    },
  },
  mainContent: {
    padding: "15px 20px 20px 20px",
  },
  gridContainer: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  icon: {
    fill: "#FAF9FF",
  },
  text: {
    fontStyle: "normal",
    fontFamily: "Avenir",
  },
  inputField: {
    borderColor: " #711CF1",
    borderRadius: "2px",
    width: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "6px !important",
      border: "none !important",
      background: "#000 !important",
    },
  },
  inputGrid: {
    // marginTop: "20px",
  },
  formContent: {
    marginLeft: "15%",
    marginTop: "10px",
    height: "340px",
    overflowY: "scroll",
  },

  iconborder: {
    border: "1px solid #4E189F",
    backdropFilter: "blur(50px)",
    borderRadius: "30px",
    margin: "20px 5px 5px 5px",
    padding: "22px 55px 10px 44px",
  },
  uploadicon: {
    position: "absolute",
    top: "-30px",
    left: "-50px",
  },
  formControl: {
    // border: "1px solid #4E189F",
  },
  boxfileuploadContainer: {
    background: "#220848",
    border: "1px dashed #FFFFFF",
    boxShadow:
      "-6.22302px -6.22302px 18.6691px #302A64, 6.22302px 6.22302px 18.6691px #000000",
    borderRadius: "20px",
    // width: "98%",
  },
  line: {
    opacity: "0.2",
    backgroundColor: "#D4BBFB",
    border: ".5px solid #D4BBFB",
    marginBlock: "2em",
    width: "100%",
  },
  buttonContainer: {
    textAlign: "center",
    // marginTop: "5px",
    // marginBottom: "10px",
  },
  button: {
    paddingTop: "10px",
    // width: "80%",
    color: "rgb(255, 255, 255)",
    background:
      "linear-gradient(rgb(170, 111, 254) 0%, rgb(113, 28, 241) 100%)",
    borderRadius: "20px",
    paddingBottom: "10px",
    paddingInline: "6em",
    textTransform: "capitalize",
  },
  buttonText: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#FFFFFF",
  },
  nobuttonui: {
    color: "#fff",
    // background: "transparent",
    textAlign: "center",
    padding: "1% 36%",
    width: "100%",
    background: "#220848",
    height: "228px",
    borderRadius: "20px",
  },
  uploadsvg: {
    width: "13%",
    paddingTop: "10%",
  },
  downloadformat: {
    color: "#fff",
    fontSize: "16px",
    textAlign: "center",
  },
  downloadicon: {
    fontSize: "15px",
    padding: "0px 4px",
    verticalAlign: "bottom",
  },
  downloadtext: {
    color: "#3A56FF",
    cursor: "pointer",
  },
  customLinearProgress: {
    height: "2.98px !important",
    borderRadius: "10px !important",
    // backgroundColor: "red !important",
    "& .MuiLinearProgress-bar": {
      backgroundColor: "#B65BFF !important",
    },
  },
  selectDropDownMenu: {
    borderRadius: "4px !important",
    border: `1.164px solid ${theme.palette.selectDropDown.border} !important`,
  },
}));

function ScheduleReport() {
  const classes = useStyles();
  const theme = useTheme();
  console.log("theme mui", theme.palette.selectDropDown.border);
  const [clientList, setClientList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const scopes = JSON.parse(ls.get("scopes"));
  const [page, setPage] = useState("Data Upload");
  const [progressbar, setProgressBar] = useState(null);

  const [clear, setClear] = useState(false);
  const [members, setMEmbers] = useState([]);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [reportBased, setReportBased] = useState("single");
  const [showNote, setShowNote] = useState(0);
  const [open, setOpen] = useState(false);
  const [showloading, setShowLoading] = useState({
    client: false,
    memberId: false,
  });

  const [key, setKey] = useState({ key: "full_name", id: "id" });
  const errorStyle = {
    marginTop: "5px",
    marginBottom: "5px",
    textAlign: "left",
  };
  const checkboxbtnStyle = {
    display: "flex",
    background: "#211839",
    padding: "11px 23px",
    width: "fit-content",
  };
  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8Z"
        stroke="#654E87"
      />
    </svg>
  );
  const checked_icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
        fill="#55A47E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.0234 9.15765L11.3372 4.93849C11.4664 4.83678 11.6228 4.77552 11.7868 4.76242C11.9507 4.74932 12.1149 4.78495 12.2586 4.86484C12.4024 4.94473 12.5193 5.06531 12.5948 5.21145C12.6702 5.35759 12.7008 5.52276 12.6826 5.68622C12.6704 5.79502 12.6368 5.90033 12.5837 5.99607C12.5306 6.09182 12.4591 6.17611 12.3732 6.24409L6.33749 11.0352L6.27889 11.0821C6.25474 11.099 6.2297 11.1146 6.20388 11.1289L6.17107 11.15C5.98052 11.2485 5.75933 11.2695 5.55364 11.2088C5.34794 11.1481 5.17362 11.0103 5.06705 10.8242L4.9733 10.6672L3.42393 7.97394C3.3696 7.87944 3.33441 7.77517 3.32038 7.66707C3.30635 7.55897 3.31375 7.44917 3.34215 7.34393C3.37056 7.2387 3.41941 7.14008 3.48593 7.05373C3.55244 6.96737 3.63532 6.89496 3.72982 6.84063C3.92067 6.73091 4.14729 6.70149 4.35982 6.75886C4.46506 6.78726 4.56367 6.83612 4.65003 6.90263C4.73639 6.96915 4.8088 7.05202 4.86313 7.14652L6.02574 9.15765H6.0234Z"
        fill="white"
      />
    </svg>
  );

  const handleTabClick = (page) => {
    setPage(page);
  };
  // const {
  //   familyMembers,
  //   setFamilyMembers,
  //   setClientName,
  //   selectedFamilyMembers,
  //   setSelectedFamilyMembers,
  // } = useAllClients(false);
  const {
    userState: { loginedUserData },
    dispatch,
  } = OnboardingContext();
  // const [errors, setErrors] = useState(null);

  const [client, setClient] = useState(
    // loginedUserData?.user_type === ENTITY_TYPE_CLIENT
    //   ? [{ full_name: loginedUserData.name, id: loginedUserData.id }]
    //   :
    []
  );

  function getexport(values) {
    if (
      values.type == "Performance Summary Report" ||
      values.type == "Client Portfolio Report"
    ) {
      return (
        <option className="optiondesignNew" key={"PDF"} value={"PDF"}>
          {"PDF"}
        </option>
      );
    } else if (
      (values.type != "" && client?.length > 1) ||
      (client && client[0]?.id == "all")
    ) {
      if (scopes?.can_generate_download_excel_report) {
        return (
          <option className="optiondesignNew" key={"excel"} value={"XLS"}>
            {"XLS"}
          </option>
        );
      }
    } else if (values.denomination != "Actuals" && values.denomination != "") {
      return (
        <option className="optiondesignNew" key={"PDF"} value={"PDF"}>
          {"PDF"}
        </option>
      );
    } else {
      if (scopes?.can_generate_download_excel_report) {
        return exportType.map((product) => {
          return (
            <option
              className="optiondesignNew"
              key={product.value}
              value={product.value}
            >
              {product.name}
            </option>
          );
        });
      } else {
        return (
          <option className="optiondesignNew" key={"PDF"} value={"PDF"}>
            {"PDF"}
          </option>
        );
      }
    }
  }

  const [formData, setFormdata] = useState({
    clientId:
      loginedUserData?.user_type === ENTITY_TYPE_CLIENT
        ? loginedUserData?.id
        : "",
    entity: "",
    fileType: "",
    file: "",
  });
  const hiddenFileInput = useRef(null);
  const [uploadErrors, setUploadErrors] = useState("");
  function showError(touched, error, absolute) {
    if (touched && error) {
      return (
        <ErrorMessageComp
          style={{
            ...errorStyle,
            position: absolute ? "absolute" : "relative",
          }}
          errorMessage={error}
        />
      );
    }
  }

  function getClient(search) {
    if (loginedUserData?.user_type === "partner") {
      fetchClientByPartner(loginedUserData?.id, {
        search,
        onboarding_status: "approved",
      }).then((res) => {
        // setKey({ key: "client_full_name", id: "client_id" });
        let arr = res?.data?.data?.result?.map((k) => {
          return { full_name: k?.client_full_name, id: k?.client_id };
        });
        setFamilyMembers(arr);
      });
    } else {
      getClientList("approved", search).then((res) => {
        console.log(res.data.data.result, "result data");
        setFamilyMembers(res.data.data.result);
      });
    }
  }
  useEffect(() => {
    console.log("get client");
    getClient();
  }, []);

  function fetchMembers(id, search, client1) {
    let tempData;
    if (client.length > 0 || client?.id) {
      tempData = client;
    } else {
      tempData = client1;
    }

    if (search) {
      if (tempData?.length > 0 || tempData?.id || id) {
      } else {
        return;
      }
    }

    if (tempData?.length > 0 || tempData?.id || id) {
      let params = {
        status: "approved",
        search: search || "",
        clientId: id
          ? id
          : Array.isArray(tempData)
          ? tempData
              .map((c) => {
                return c.id;
              })
              .toString()
          : typeof tempData == "object"
          ? tempData.id
          : tempData,
        partnerId:
          loginedUserData?.user_type === "partner" && tempData[0]?.id == "all"
            ? loginedUserData?.id
            : "",
      };
      setShowLoading({ ...showloading, memberId: true });
      fetchClientByMultiId(params)
        .then((res) => {
          // console.log(res, "res");
          setShowLoading({ ...showloading, memberId: false });
          setMEmbers(res.data.data.result);
        })
        .catch((errors) => {
          setShowLoading({ ...showloading, memberId: false });
        });
    }
  }
  const fetchClientList = async (status = "approved", searchValue = "") => {
    setShowLoader(true);

    try {
      const data = await getClientList(status, searchValue);
      const clientList = data.data;

      if (clientList.success) {
        setShowLoader(false);
        setClientList(clientList.data.result);
      }
    } catch (error) {
      setShowLoader(false);
      if (error.response.request.status === 401) {
        navigate("/logout");
      } else if (!error.response.data.error.success) {
        dispatch({
          type: "UPDATE_SNACKBAR_DATA",
          payload: {
            success: false,
            error: true,
            showMsg: true,
            message: error.response.data.error.message,
          },
        });
      }
    }
  };
  function checkboxError() {
    dispatch({
      type: "UPDATE_SNACKBAR_DATA",
      payload: {
        success: false,
        error: true,
        showMsg: true,
        message: "At least one report type should be selected",
      },
    });
  }

  // useEffect(() => {
  //   if (client) {
  //     if (loginedUserData?.user_type === ENTITY_TYPE_CLIENT) {
  //       setClientList([
  //         {
  //           full_name: loginedUserData.name,
  //           id: loginedUserData.id,
  //         },
  //       ]);
  //       fetchMembers(loginedUserData.id);
  //     } else {
  //       fetchMembers();
  //     }
  //   }
  // }, [client]);

  // const handleChange = (e) => {
  //   setFormdata({ ...formData, [e.target.name]: e.target.value });
  //   // console.log(e.target.name, e.target.value, "e data");
  //   const errorData = uploadSampleFileValidate(
  //     { ...formData, [e.target.name]: e.target.value },
  //     e.target.name
  //   );
  //   setErrors(errorData);
  // };

  const handleAsncChange = (name, value, valuez, option) => {
    // console.log(name, value, valuez, "name and value");

    if (typeof value == "string" || typeof value == "number") {
      console.log("ramvalue1", value);
      let val = option.find((v) => {
        if (v?.[key.id] == value) {
          return true;
        }
      });

      setClient(val ? val : []);
      // console.log(val, "value ww");
      setFieldValue(name, val ? val : []);
      setFormdata({ ...formData, [name]: val });
    } else {
      console.log("ramvalue2", value);
      setFieldValue(name, value);
      setFormdata({ ...formData, [name]: value });
    }

    const errorData = uploadSampleFileValidate(
      { ...formData, [name]: value },
      name
    );
  };
  function customHandleSubmit() {}

  // console.log(familyMembers, "family members");
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,

    setFieldValue,
  } = useForm(
    {
      // memberId: [],
      client:
        // loginedUserData?.user_type === ENTITY_TYPE_CLIENT
        //   ? {
        //       full_name: loginedUserData.name,
        //       id: loginedUserData.id,
        //     }
        //   :
        [],
      // reportType: [],

      startDate: "",

      Holding: true,
      Transactuion: false,
      type: [],
    },
    // test
    (values, { resetForm }) => {
      // console.log("handle submit", values);
      const { client, ...data } = values;
      console.log(values, "values");
      // data["memberId"] = values?.memberId
      //   ?.map((m) => {
      //     return m.member_id;
      //   })
      //   .toString();

      // if (data["memberId"] == "") {
      //   delete data["memberId"];
      // }

      if (data.Holding) {
        data?.type?.push("Holding Summary Report");
      }
      if (data.Transaction) {
        data?.type?.push("Transaction Summary Report");
      }
      delete data.Holding;
      delete data.Transaction;
      data.type = data?.type?.join(",");
      if (
        typeof values?.["client"] == "number" ||
        typeof values?.["client"] == "string"
      ) {
        data["clientId"] = values?.["client"];
      } else if (Array.isArray(values?.["client"])) {
        data["clientId"] = values?.["client"]
          ?.map((m) => {
            return m.id;
          })
          .toString();
      } else if (typeof values?.["client"] == "object") {
        data["clientId"] = values?.["client"]?.id.toString();
      }

      if (data.startDate === "") {
        delete data["startDate"];
      }
      // if (loginedUserData?.user_type === "partner") {
      //   data["partnerId"] = loginedUserData?.id;
      // }
      // data["clientId"] =
      //   loginedUserData?.user_type === ENTITY_TYPE_CLIENT
      //     ? typeof values?.["client"] == "number" ||
      //       typeof values?.["client"] == "string"
      //       ? values?.["client"]
      //       : Array.isArray(values?.["client"])
      //       ? values?.["client"]
      //           ?.map((m) => {
      //             return m.id;
      //           })
      //           .toString()
      //       : values?.["client"]?.id
      //     : values?.["client"]
      //         ?.map((m) => {
      //           return m.id;
      //         })
      //         .toString();

      setShowLoader(true);

      // console.log("request data", data);
      ScheduleRequestReport(data)
        .then((res) => {
          setShowLoader(false);
          // console.log(res, "request");
          dispatch({
            type: "UPDATE_SNACKBAR_DATA",
            payload: {
              success: true,
              error: false,
              showMsg: true,
              message: res.data.message,
            },
          });
          setClient([]);
          resetForm();
          setOpen(true);
        })
        .catch((error) => {
          setShowLoader(false);
          console.log(error, "error");
          dispatch({
            type: "UPDATE_SNACKBAR_DATA",
            payload: {
              success: false,
              error: true,
              showMsg: true,
              message: error.response.data.error.message,
            },
          });
        });
      customHandleSubmit(values);
    },
    scheduleForm
  );
  function selectComponent(values) {
    // console.log(values, "values");
    switch (values.type) {
      case "Performance Summary Report":
        return (
          <>
            <SingleSelect
              // getSearchData={() => {}}
              getSearchData={(value) => getClient(value)}
              data={familyMembers}
              styles={{ opacity: 1, border: "0px solid black" }}
              searchLoader={false}
              disable={
                loginedUserData?.user_type === ENTITY_TYPE_CLIENT ? true : false
              }
              errors={errors}
              touch={touched}
              label={key.key}
              optionTitle={key.key}
              fieldName="client"
              objectFieldName={key.id}
              formData={formData}
              value={values.client}
              handleChange={(name, value1, valuez) => {
                handleAsncChange(name, value1, valuez, familyMembers);
                // if (typeof value1 == "string" || typeof value1 == "number") {
                //   let val = familyMembers.find((v) => {
                //     if (v.id == value1) {
                //       return true;
                //     }
                //   });

                //   setClient(val ? val : []);
                //   setFieldValue(name, val);
                // }
                setSelectedMembers([]);
                setFieldValue("memberId", []);
              }}
            />

            {showError(
              (!values.client || values.client == "") && touched.client,
              errors.client,
              true
            )}
          </>
        );

      case "Holding Summary Report":
        if (scopes?.can_generate_download_excel_report) {
          return (
            <>
              <MultiSelect
                multiple={true}
                styles={{ opacity: 1, border: "0px solid black" }}
                disableClassName="true"
                data={[{ [key.key]: "All", id: "all" }, ...familyMembers]}
                getSearchData={(value) => getClient(value)}
                values={client}
                setValues={setClient}
                errors={errors}
                touch={touched}
                disable={
                  loginedUserData?.user_type === ENTITY_TYPE_CLIENT
                    ? true
                    : false
                }
                searchLoader={showLoader}
                label={key.key}
                optionTitle={key.key}
                fieldName="client"
                objectFieldName={key.id}
                formData={formData}
                handleChange={(name, value1, valuez) => {
                  handleAsncChange(name, value1, valuez);
                  setFieldValue("memberId", []);
                  setSelectedMembers([]);
                  if (values.exportType == "PDF" && value1.length > 1) {
                    setFieldValue("exportType", "");
                  }
                  if (value1.length > 1) {
                    setFieldValue("denomination", "Actuals");
                  }
                  if (
                    (value1[0]?.id == "all" || value1.length > 1) &&
                    showNote == 0
                  ) {
                    setShowNote(1);
                  }
                }}
                // helperText={errors?.clientId}
              />

              {showError(
                (!client || client?.length < 1) && touched.client,
                errors.client,
                true
              )}
            </>
          );
        } else {
          return (
            <>
              <SingleSelect
                // getSearchData={() => {}}
                getSearchData={(value) => getClient(value)}
                data={familyMembers}
                styles={{ opacity: 1, border: "0px solid black" }}
                searchLoader={false}
                disable={
                  loginedUserData?.user_type === ENTITY_TYPE_CLIENT
                    ? true
                    : false
                }
                errors={errors}
                touch={touched}
                label={key.key}
                optionTitle={key.key}
                fieldName="client"
                objectFieldName={key.id}
                formData={formData}
                value={values.client}
                handleChange={(name, value1, valuez) => {
                  handleAsncChange(name, value1, valuez, familyMembers);
                  // if (typeof value1 == "string" || typeof value1 == "number") {
                  //   let val = familyMembers.find((v) => {
                  //     if (v.id == value1) {
                  //       return true;
                  //     }
                  //   });

                  //   setClient(val ? val : []);
                  //   setFieldValue(name, val);
                  // }
                  setSelectedMembers([]);
                  setFieldValue("memberId", []);
                }}
              />

              {showError(
                (!values.client || values.client == "") && touched.client,
                errors.client,
                true
              )}
            </>
          );
        }

      case "Transaction Summary Report":
        if (scopes?.can_generate_download_excel_report) {
          return (
            <>
              <MultiSelect
                multiple={true}
                styles={{ opacity: 1, border: "0px solid black" }}
                disableClassName="true"
                data={[{ [key.key]: "All", id: "all" }, ...familyMembers]}
                getSearchData={(value) => getClient(value)}
                values={Array.isArray(client) ? client : [client]}
                disable={
                  loginedUserData?.user_type === ENTITY_TYPE_CLIENT
                    ? true
                    : false
                }
                setValues={setClient}
                errors={errors}
                touch={touched}
                searchLoader={showLoader}
                label={key.key}
                optionTitle={key.key}
                fieldName="client"
                objectFieldName={key.id}
                formData={formData}
                handleChange={(name, value1, valuez) => {
                  handleAsncChange(name, value1, valuez);
                  setFieldValue("memberId", []);
                  setSelectedMembers([]);
                  if (values.exportType == "PDF" && value1.length > 1) {
                    setFieldValue("exportType", "");
                  }
                  if (value1.length > 1) {
                    setFieldValue("denomination", "Actuals");
                  }
                  if (
                    (value1[0]?.id == "all" || value1.length > 1) &&
                    showNote == 0
                  ) {
                    setShowNote(1);
                  }
                }}
                // helperText={errors?.clientId}
              />
              {showError(
                (!client || client?.length < 1) && touched.client,
                errors.client,
                true
              )}
            </>
          );
        } else {
          return (
            <>
              <SingleSelect
                // getSearchData={() => {}}
                getSearchData={(value) => getClient(value)}
                data={familyMembers}
                styles={{ opacity: 1, border: "0px solid black" }}
                searchLoader={false}
                disable={
                  loginedUserData?.user_type === ENTITY_TYPE_CLIENT
                    ? true
                    : false
                }
                errors={errors}
                touch={touched}
                label={key.key}
                optionTitle={key.key}
                fieldName="client"
                objectFieldName={key.id}
                formData={formData}
                value={values.client}
                handleChange={(name, value1, valuez) => {
                  handleAsncChange(name, value1, valuez, familyMembers);
                  // if (typeof value1 == "string" || typeof value1 == "number") {
                  //   let val = familyMembers.find((v) => {
                  //     if (v.id == value1) {
                  //       return true;
                  //     }
                  //   });

                  //   setClient(val ? val : []);
                  //   setFieldValue(name, val);
                  // }
                  setSelectedMembers([]);
                  setFieldValue("memberId", []);
                }}
              />

              {showError(
                (!values.client || values.client == "") && touched.client,
                errors.client,
                true
              )}
            </>
          );
        }

      default:
        return (
          <>
            <SingleSelect
              // getSearchData={() => {}}
              getSearchData={(value) => getClient(value)}
              data={familyMembers}
              styles={{ opacity: 1, border: "0px solid black" }}
              searchLoader={false}
              disable={
                loginedUserData?.user_type === ENTITY_TYPE_CLIENT ? true : false
              }
              errors={errors}
              touch={touched}
              label={key.key}
              optionTitle={key.key}
              fieldName="client"
              objectFieldName={key.id}
              formData={formData}
              value={values.client}
              handleChange={(name, value1, valuez) => {
                handleAsncChange(name, value1, valuez, familyMembers);
                // if (typeof value1 == "string" || typeof value1 == "number") {
                //   let val = familyMembers.find((v) => {
                //     if (v.id == value1) {
                //       return true;
                //     }
                //   });

                //   setClient(val ? val : []);
                //   setFieldValue(name, val);
                // }
                setSelectedMembers([]);
                setFieldValue("memberId", []);
              }}
            />

            {showError(
              (!values.client || values.client == "") && touched.client,
              errors.client,
              true
            )}
          </>
        );
    }
  }

  const handleViewCenter = () => {
    window.location.href = "/home/reports?key=report-center";
  };
  // Start Date will be hidden for Holding & Security Holding
  const showStartDateFor = [
    "Transaction Summary Report",
    "Performance Summary Report",
  ];
  return (
    <div>
      <AlertDialog
        open={open}
        setOpen={setOpen}
        dialogTitle={() => (
          <DialogTitle id="alert-dialog-title">
            <div
              style={{
                alignTtems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <RightAlertIcon />
            </div>
            <Typography
              style={{
                color: "#fff",
                fontFamily: "Avenir",
                fontSize: "18x",
                fontStyle: "normal",
                fontWeight: 800,
                lineHeight: "35.2px",
                textAlign: "center",
              }}
            >
              Your Report is being created
            </Typography>
          </DialogTitle>
        )}
        dialogContent={() => (
          <DialogContentText
            id="alert-dialog-description"
            style={{
              color: "#fff",
              textAlign: "center",
              fontFamily: "Avenir",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 350,
              lineHeight: "22px",
            }}
          >
            Upon Completion you will receive an intimation email.
          </DialogContentText>
        )}
        dialogFooter={() => (
          <button
            className="approveBtnDetailsAccordion"
            style={{ background: "#7043DE", width: "250px" }}
            onClick={handleViewCenter}
          >
            View Report Center
          </button>
        )}
      />
      {showLoader && <Loader />}

      <CustomizedSnackbars />
      <div className="containerWrapper">
        <p
          className="topText"
          style={{
            marginTop: "1.5rem",
            marginBottom: "1rem",
            marginRight: "8.5rem",
          }}
        >
          {/* {page === "Data Upload" ? "" : "Bulk Upload"} */}
          {/* Display "Bulk Upload" only if the user does not have permission to upload data files in bulk */}
        </p>

        <div className="productUploadContinerForUserManagement fade_purple_bg">
          <div className="productUploadSelectDropDown">
            {/* {console.log(errors, touched, values, "values")} */}

            {/* <FormControl
                  variant="outlined"
                  className={`${classes.formControl} ${classes.inputField}`}
                >
                  <p className="selectDropDownLabel">Schedule Report Date</p>

                  <TextField
                    // For PlaceHolder
                    variant="outlined"
                    displayEmpty
                    renderValue={
                      formData?.entity !== ""
                        ? undefined
                        : () => (
                            <span style={{ color: "#59595B" }}>
                              Select Date
                            </span>
                          )
                    }
                    // native
                    style={{ color: "#FAF9FF" }}
                    labelId="demo-simple-select-outlined-label"
                    // label="Select Product"
                    type="date"
                    id="date"
                    value={values.startDate}
                    onChange={(e) => handleChange(e)}
                    error={touched.startDate && Boolean(errors?.startDate)}
                    endAdornment={
                      <InputAdornment position="end">
                        {browseIcon}
                      </InputAdornment>
                    }
                    inputProps={{
                      min: moment().add(1, "d").format("YYYY-MM-DD"),
                      name: "startDate",
                      id: "outlined-entity_type-native-simple",
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          backgroundColor: "#1e0d2d",
                          border: "1.164px solid #461277",
                          marginTop: "1rem",
                        },
                      },
                      MenuListProps: {
                        className: classes.selectDropDownMenu,
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  ></TextField>

                  {showError(touched.startDate, errors.startDate)}
               
                </FormControl> */}
            <>
              <div className="selectClient1">
                <Grid xs={12} lg={12} item>
                  {page === "Data Upload" && (
                    <div
                      className={`selectClient1 asyncbox
                  
                  `}
                    >
                      <p className="selectDropDownLabel">Select Client</p>

                      <MultiSelect
                        multiple={true}
                        styles={{ opacity: 1, border: "0px solid black" }}
                        disableClassName="true"
                        data={
                          //[
                          // { [key.key]: "All", id: "all" },
                          familyMembers
                          //   ]
                        }
                        getSearchData={(value) => getClient(value)}
                        values={client}
                        setValues={setClient}
                        errors={errors}
                        touch={touched}
                        // disable={
                        //   loginedUserData?.user_type === ENTITY_TYPE_CLIENT
                        //     ? true
                        //     : false
                        // }
                        searchLoader={showLoader}
                        label={key.key}
                        optionTitle={key.key}
                        fieldName="client"
                        objectFieldName={key.id}
                        formData={formData}
                        handleChange={(name, value1, valuez) => {
                          handleAsncChange(name, value1, valuez);
                          setFieldValue("memberId", []);
                          setSelectedMembers([]);
                          if (values.exportType == "PDF" && value1.length > 1) {
                            setFieldValue("exportType", "");
                          }
                          if (value1.length > 1) {
                            setFieldValue("denomination", "Actuals");
                          }
                          if (
                            (value1[0]?.id == "all" || value1.length > 1) &&
                            showNote == 0
                          ) {
                            setShowNote(1);
                          }
                        }}
                        // helperText={errors?.clientId}
                      />
                      {showError(
                        (!client || client?.length < 1) && touched.client,
                        errors.client,
                        true
                      )}
                      {/* <div
                        style={{
                          height: "145px",
                          width: "355px",
                          background:
                            "linear-gradient(38.98deg, #220F32 -7.48%, #281850 129.72%)",
                          borderRadius: "8px",
                          boxShadow: "-21px 21px 20px 0px #00000087",
                          position: "absolute",
                          top: "120px",
                          right: "157px",
                          zIndex: "2",
                          display: showNote == 1 ? "block" : "none",
                        }}
                      >
                        <div className="note">
                          <div>
                            {" "}
                            <button
                              onClick={() => {
                                setShowNote(-1);
                              }}
                            >
                              <Close />
                            </button>
                            <IIcon /> <b>PDF Not Available</b>
                          </div>

                          <div className="subtext">
                            PDF export for the multiple clients is not
                            available. Please select an individual client .
                          </div>
                        </div>
                      </div> */}
                    </div>
                  )}
                </Grid>
              </div>
              {/* Show Client DropDown Only if Data Upload is Selected */}
              <div className={"selectClient1"}>
                <FormControl
                  variant="outlined"
                  className={`${classes.formControl} ${classes.inputField}`}
                  style={{ position: "relative" }}
                >
                  <p className="selectDropDownLabel">Select Report TYPES</p>
                  <div style={{ display: "flex" }}>
                    <Grid xs={6} lg={6} item>
                      <div style={checkboxbtnStyle}>
                        <div
                          style={{
                            marginRight: "auto",
                            alignItems: "flex-start",
                          }}
                        >
                          <Checkbox
                            icon={icon}
                            checkedIcon={checked_icon}
                            style={{ marginRight: 8 }}
                            name={"Holding"}
                            id="Holding"
                            checked={values?.["Holding"] ? true : false}
                            onChange={
                              (e) => {
                                if (!values?.["Transaction"]) {
                                  checkboxError();
                                  return;
                                }
                                handleChange(e);
                              }

                              //   (e) => {
                              //   console.log(e.target.checked);
                              //   if (e.target.checked) {
                              //   }
                              // }
                            }
                          />
                        </div>
                        <div
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "100%",
                          }}
                        >
                          Holding Summary Report
                        </div>
                      </div>
                    </Grid>
                    <Grid xs={6} lg={6} item>
                      <div style={checkboxbtnStyle}>
                        <div
                          style={{
                            marginRight: "auto",
                            alignItems: "flex-start",
                          }}
                        >
                          <Checkbox
                            icon={icon}
                            checkedIcon={checked_icon}
                            style={{ marginRight: 8 }}
                            name={"Transaction"}
                            id="Transaction"
                            checked={values?.["Transaction"] ? true : false}
                            onChange={(e) => {
                              if (!values?.["Holding"]) {
                                checkboxError();
                                return;
                              }
                              handleChange(e);
                            }}
                          />
                        </div>
                        <div
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "100%",
                          }}
                        >
                          Transactional Summary Report
                        </div>
                      </div>
                    </Grid>
                  </div>
                  {/* <Select
                    // For PlaceHolder
                    displayEmpty
                    renderValue={
                      values.type !== ""
                        ? undefined
                        : () => (
                            <span style={{ color: "#59595B" }}>
                              Select Report
                            </span>
                          )
                    }
                    // native
                    style={{ color: "#FAF9FF" }}
                    labelId="demo-simple-select-outlined-label"
                    // label="Select Product"
                    value={values.type}
                    onChange={(e) => {
                      handleChange(e);
                      if (loginedUserData?.user_type != ENTITY_TYPE_CLIENT) {
                        setFieldValue("client", "");
                        setClient([]);
                      }
                    }}
                    error={
                      Boolean(errors?.type) && values.type == ""
                        ? touched.type
                        : false
                    }
                    inputProps={{
                      name: "type",
                      id: "outlined-entity_type-native-simple",
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          backgroundColor: "#1e0d2d",
                          border: `1.164px solid ${theme.palette.selectDropDown.border}`,
                          marginTop: "1rem",
                        },
                      },
                      MenuListProps: {
                        className: classes.selectDropDownMenu,
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {reportType.map((product) => {
                      return (
                        <option
                          className="optiondesignNew"
                          key={product.value}
                          value={product.value}
                        >
                          {product.name}
                        </option>
                      );
                    })}
                  </Select> */}

                  {/* </div> */}
                </FormControl>
                {showError(
                  values.type == "" && touched?.type,
                  errors.type,
                  true
                )}
              </div>
            </>
          </div>
          {/* <Grid xs={6} lg={6} item>
            {page === "Data Upload" && (
              <div
                className={`selectClient1 asyncbox
                  
                  `}
              >
                <p className="selectDropDownLabel">Select Client</p>

                <MultiSelect
                  multiple={true}
                  styles={{ opacity: 1, border: "0px solid black" }}
                  disableClassName="true"
                  data={[{ [key.key]: "All", id: "all" }, ...familyMembers]}
                  getSearchData={(value) => getClient(value)}
                  values={client}
                  setValues={setClient}
                  errors={errors}
                  touch={touched}
                  // disable={
                  //   loginedUserData?.user_type === ENTITY_TYPE_CLIENT
                  //     ? true
                  //     : false
                  // }
                  searchLoader={showLoader}
                  label={key.key}
                  optionTitle={key.key}
                  fieldName="client"
                  objectFieldName={key.id}
                  formData={formData}
                  handleChange={(name, value1, valuez) => {
                    handleAsncChange(name, value1, valuez);
                    setFieldValue("memberId", []);
                    setSelectedMembers([]);
                    if (values.exportType == "PDF" && value1.length > 1) {
                      setFieldValue("exportType", "");
                    }
                    if (value1.length > 1) {
                      setFieldValue("denomination", "Actuals");
                    }
                    if (
                      (value1[0]?.id == "all" || value1.length > 1) &&
                      showNote == 0
                    ) {
                      setShowNote(1);
                    }
                  }}
                  // helperText={errors?.clientId}
                />
                <div
                  style={{
                    height: "145px",
                    width: "355px",
                    background:
                      "linear-gradient(38.98deg, #220F32 -7.48%, #281850 129.72%)",
                    borderRadius: "8px",
                    boxShadow: "-21px 21px 20px 0px #00000087",
                    position: "absolute",
                    top: "120px",
                    right: "157px",
                    zIndex: "2",
                    display: showNote == 1 ? "block" : "none",
                  }}
                >
                  <div className="note">
                    <div>
                      {" "}
                      <button
                        onClick={() => {
                          setShowNote(-1);
                        }}
                      >
                        <Close />
                      </button>
                      <IIcon /> <b>PDF Not Available</b>
                    </div>

                    <div className="subtext">
                      PDF export for the multiple clients is not available.
                      Please select an individual client .
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Grid> */}
        </div>

        <footer
          style={{
            display: "grid",
            placeItems: "center",
          }}
        >
          <button
            className="productUploadBtn "
            style={{ width: "194px", marginTop: "200px" }}
            onClick={handleSubmit}
          >
            Request
          </button>
        </footer>
      </div>
    </div>
  );
}

export default ScheduleReport;
