import React from "react";
import Default from "../components/dashboard/defaultPages/Default";
import BankBookSummary from "../components/dashboard/products/bankBook/BankBookSummary";

function BankBookSummaryPage() {
  return (
    <Default>
      <BankBookSummary />
    </Default>
  );
}

export default BankBookSummaryPage;
