import React, { useEffect, useState } from "react";
import { UserManagementHeader } from "./UserManagementHeader";
import UserManagementTableWrapper from "./UserManagementTableWrapper";
import { bodyData3, headerData, hierarchyHeader } from "./utils/constands";
import { useLocation, useNavigate } from "react-router-dom";
import { BackDrop } from "../dashboard/common/BackDrop";
import AddMemberFlow from "./client_flow/AddMemberFlow";
import { fetchMembersWiseHierarchy, updatepartneruser } from "../../api";
import { OnboardingContext } from "../../Context";
import { useParams } from "react-router-dom";
import { Decrypt, Encrypt } from "../dashboard/print/Components/utils/common";
import { useMemo } from "react";
import AddButton from "../common/AddButton";
import AddClientMemberFlow from "./client_flow/UsermanagmentClient/AddClientMemberFlow";
import { ITEMS_LIMIT_FILES_PARTNER_LIST } from "../../Constants";
import ls from "localstorage-slim";

export const NewUserManagementComp = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { userState } = OnboardingContext();
  const [openModal, setOpenModal] = useState(false);
  const [tabledata, setTabledata] = useState([]);
  const [search, setSearchTerm] = useState("");
  const [offset, setItemOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [header, setHeader] = useState(hierarchyHeader);
  let clientId = userState.loginedUserData.id;
  const { details } = useParams();
  let currentUser = Decrypt(details);
  // const [user, setuser] = useState(currentUser);
  // console.log(state, "afkjbafjavhf");
  //return <UserManagementHeader />;
  const scopes = JSON.parse(ls.get("scopes"));

  const totalPage = useMemo(
    () => Math.ceil(total / ITEMS_LIMIT_FILES_PARTNER_LIST),
    [total]
  );

  useEffect(() => {
    // setuser(() => currentUser);
    console.log(currentUser, "current user");

    // currentUser = { client_id: 197, member_id: 42 };

    let params = {
      limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
      offset: offset,
    };
    if (search !== "" && search) {
      params["search"] = search;
    }
    fetchMembersWiseHierarchy(
      currentUser.client_id,
      currentUser.member_id || currentUser.client_member_id,
      params
    ).then((res) => {
      setTabledata(res.data.data.result.hierarchy);
      setTotal(res.data.data.meta.count);
      console.log(res.data.data, "tabledata");
    });
  }, [search, details, refresh]);

  useEffect(() => {
    handleHeader(hierarchyHeader);
  }, []);

  function handleSave(values, id) {
    if (id) {
      updatepartneruser("clientUser", id, values)
        .then((res) => {})
        .catch((err) => {});
      return;
    }
    let data = {};
    if (values.fullName != currentUser.full_name) {
      data["fullName"] = values.fullName;
    }

    data["status"] = values.status;

    if (currentUser.member_role_id) {
      data["roleId"] = "" + currentUser.member_role_id;
    }
    if (currentUser.role_id) {
      data["roleId"] = "" + currentUser.role_id;
    }
    updatepartneruser(
      "clientUser",
      id ||
        currentUser?.client_user_id ||
        currentUser?.member_user_id ||
        currentUser?.user_id,
      data
    )
      .then((res) => {})
      .catch((err) => {});
  }

  const handleHeader = (headerData) => {
    headerData?.map((val) => {
      if (val?.field == "is_active") {
        val.onClick = (data) => {
          if (window?.location.pathname.includes("client")) {
            console.log(data, "edit data");
            navigate("/client/user-management/edit" + `${Encrypt(data)}`);
          } else {
            navigate("/user-management/edit/" + `${Encrypt(data)}`, {
              state: { data: data },
            });
          }
        };
        val.handleTogle = (data) => {
          console.log("toggled", data);
          let data1 = {
            status: "" + data.toglge == "true" ? 1 : 0,
          };
          if (data?.rowData?.member_role_id) {
            data1["roleId"] = "" + data.rowData.member_role_id;
          }
          if (currentUser.role_id) {
            data1["roleId"] = "" + data.rowData.role_id;
          }
          handleSave(
            data1,
            data?.rowData?.member_user_id ||
              currentUser?.rowData?.client_user_id
          );
        };
      }
    });
    setHeader(() => headerData);
  };

  // console.log(scopes?.can_add_client_member, "agnlkagn1");

  return (
    <>
      <UserManagementHeader
        initialValue={{
          fullName:
            currentUser?.full_name ||
            currentUser?.name ||
            currentUser?.member_name,
          mobileNumber:
            currentUser?.client_mobile ||
            currentUser?.mobile ||
            currentUser?.member_mobile,
          emailId:
            currentUser?.client_email ||
            currentUser?.email ||
            currentUser?.member_email,
        }}
        details={{
          pan: currentUser?.member_pan || currentUser?.pan,
          role: currentUser?.member_role || currentUser?.role,
          banker: currentUser?.banker_name,
          cso: currentUser?.cso_name || currentUser?.cxo_name,
          status:
            currentUser?.is_active == 1 ||
            currentUser?.status == "approved" ||
            currentUser?.status == 1
              ? 1
              : 0,
        }}
        handleSave={handleSave}
        handleback={() => {
          if (window?.location.pathname.includes("client")) {
            // navigate("/client/user-management-list", {
            //   state: {
            //     headerType: "userManagementDetailsHeader",
            //     data: currentUser,
            //   },
            // });
            navigate(-1);
            // return;
          } else {
            navigate("/user-management");
          }
        }}
        data={currentUser}
      />

      {/* <BackDrop /> */}
      <UserManagementTableWrapper
        setSearchTerm={setSearchTerm}
        setItemOffset={setItemOffset}
        customScopes={[scopes?.can_add_client_member]}
        totalPage={totalPage}
        returnno={true}
        headerData={header}
        data={tabledata}
        openModal={openModal}
        setOpenModal={setOpenModal}
        // addRepComp={
        //   <AddButton
        //     addBtnTitle={"Add Client Member"}
        //     handleIconClick={() => setOpenModal(true)}
        //   />
        // }
      />
      <AddClientMemberFlow
        data={state?.data}
        openModal={openModal}
        setOpenModal={setOpenModal}
        id={
          currentUser?.client_id ||
          currentUser?.client_user_id ||
          currentUser?.member_user_id
        }
        handleRefresh={() => {
          setRefresh(!refresh);
        }}
      />
      {/* <AddMemberFlow openModal={openModal} setOpenModal={setOpenModal} /> */}
    </>
  );
};
