import React, { useState } from "react";
import { mapClientRep } from "../api";
import { useNavigate } from "react-router";

function useMapClientRep() {
  const [loading1, setLoading] = useState(false);
  const navigate = useNavigate();
  const mapClient = async ({ payload }) => {
    setLoading(true);
    try {
      //   let payload = {
      //     partnerRepresentativeId: 156,
      //     clientId: 1,
      //   };

      await mapClientRep(payload);

      console.log(payload, "aglkgnlk");
      setLoading(false);
      navigate("/client");
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return { mapClient, loading1 };
}

export default useMapClientRep;
