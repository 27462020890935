import { Card, CardContent, Grid, Typography, Button } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import CardSummary from "../common/CardSummary";
import PageFilter from "../common/PageFilter";
import useStyles from "../common/ProductsStyles";

import {
  divergingCommonProps,
  pie_onClick2,
} from "../../../charts/nivo_objects";
import TabHeader from "../common/TabHeader";
import PageLoader from "../common/pageLoader";
import {
  fetchXirr,
  fetchTwrrCurrentHolding,
  fetchXirrCurrentHolding,
  fetchTwrr,
  invested_portfolio,
  getAssetWiseData,
} from "../../../../api";
import { OnboardingContext } from "../../../../Context";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";

import { loadingText } from "../../../utils/constands";
import SummaryPageTop from "../common/SummaryPageTop";
import TileComp from "../common/TileComp";
import Default from "../../defaultPages/Default";
import PortfolioChart from "../../common/portfolioChart";

import { useHoldingCardActive } from "../../../../hooks/useHoldingCardActive";
import { NewHoldingSummaryCards } from "../../../common/HoldingSummaryCards/NewHoldingSummaryCards";
import { NewHoldingSummaryCardsActive } from "../../../common/HoldingSummaryCards/NewHoldingSummaryCardsActive";
import {
  holdingSummaryCardsInactiveData,
  holdingSummaryCardsActiveData,
} from "../common/util/common";
import { useReturns } from "../../../../hooks/useReturns";
import { useNormalReturns } from "../../../../hooks/useNormalReturns";
import BarChartIcon from "@material-ui/icons/BarChart";
import { routes } from "../../../../routes";
import { useTotalGainLoss } from "../../../../hooks/useTotalGainLoss";
import { NewFilter } from "../../newFilter/NewFilter";
import { GainsCardInfo } from "../../../common/HoldingSummaryCards/GainsCardInfo";
import { ReactComponent as InfoIcon } from "../../../../assets/icons/TotalGainLossInfoIcon.svg";
import { useCardsApi } from "../../../../hooks/useCardsApi";
import { useCurrentHoldingCardsApi } from "../../../../hooks/useCurrentHoldingCardsApi";
function MLDSummaryComp() {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [infoIconHovered, setInfoIconHovered] = useState(false);
  // const [holdingCardData] = useHoldingCardActive("mld", checked);
  // const [totalGainLossData] = useTotalGainLoss("mld");
  // const { returns: twrr } = useNormalReturns(
  //   fetchTwrr,
  //   "normal-twrr",
  //   checked,
  //   "mld"
  // );
  // const { returns: xirr } = useNormalReturns(
  //   fetchXirr,
  //   "normal-xirr",
  //   checked,
  //   "mld"
  // );
  const {
    holdingCardData,
    totalGainLossData,
    xirr,
    twrr,
    loading: cardLoader,
    twrrLoader,
    twrrError,
    retryFetchTwrr,
  } = useCardsApi("mld");
  // const { returns: xirrCurrentHolding } = useReturns(
  //   fetchXirrCurrentHolding,
  //   "xirr",
  //   checked,
  //   "mld"
  // );
  // const { returns: twrrCurrentHolding, loading: twrrCurrentHoldingLoading } =
  //   useReturns(fetchTwrrCurrentHolding, "twrr", checked, "mld");
  const {
    // holdingCardData,
    xirrCurrentHolding,
    twrrCurrentHolding,
    loading: currentHoldingCardLoader,
    twrrLoaderCurrentHolding,
    twrrErrorCurrentHolding,
    retryFetchTwrrCurrentHolding,
  } = useCurrentHoldingCardsApi(checked, "mld");
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  let selectedAssetClass = getfilter_as_params().assetClass;
  const { userState } = OnboardingContext();
  // const [twrr, setTwrr] = useState({});

  // const [twrrCurrentHolding, setTwrrCurrentHolding] = useState({});
  // const [xirr, setXirr] = useState({});
  // const [xirrCurrentHolding, setXirrCurrentHolding] = useState({});
  const [investmentPortfolio, setInvestmentPortfolio] = useState([]);
  const [loadque, setloadque] = useState(null);
  const [loading, setLoading] = useState(true);
  const [assetData, setAssetData] = useState([]);
  const [assets, setAssets] = useState({});
  const [flip, setFlip] = useState(false);
  const [newCardData, setNewCardDaat] = useState([]);

  const [asset_color, setAsset_Color] = useState([
    "#3A56FF",
    "#B80000",
    "#82EBA1",
    "#711CF1",
  ]);
  const [pie, setPie] = useState({
    title: "",
    value: "",
    colors: asset_color,
    disabled: [],
    fill: [
      {
        match: {
          id: "debt",
        },
        id: "c6",
      },
      {
        match: {
          id: "equity",
        },
        id: "c3",
      },
      {
        match: {
          id: "alternate",
        },
        id: "c2",
      },
      {
        match: {
          id: "cash",
        },
        id: "c4",
      },
    ],
  });

  const color = {
    equity: "#3A56FF",
    debt: "#82EBA1",
    alternate: "#B80000",
    cash: "#711CF1",
  };

  // let currencyFormat = (value) => {
  //   return currencyFormatLocal(value, {
  //     currencyStyle: { style: "currency", currency: "INR" },
  //   });
  // };

  const diverging = {
    ...divergingCommonProps,
  };
  diverging.axisBottom.legendOffset = 65;
  diverging.margin = { top: 5, right: 20, bottom: 70, left: 80 };
  useEffect(() => {
    const clientId = userState.loginedUserData.id;
    // const clientId = 199;

    let params = { ...getfilter_as_params() };

    setLoading(true);
    Promise.allSettled([
      // fetchXirr(clientId, params, "mld"),
      // fetchXirrCurrentHolding(clientId, params, "mld"),
      // fetchTwrr(clientId, params, "mld"),
      // fetchTwrrCurrentHolding(clientId, params, "mld"),
      invested_portfolio(clientId, params, "mld"),
      // getAssetWiseData(clientId, params, "mld"),
    ]).then(
      ([
        // xirr_res,
        // xirrCurrentHolding_res,
        // twrr_res,
        // twrrCurrentHolding_res,
        invest_res,
        // asset_ress,
      ]) => {
        // async function allres(){
        //   await xirr_res
        //   await twrr_res
        //   await realiseddata_res
        //   await asset_ress
        //   setLoading(false)
        // }

        // if (xirr_res.status == "fulfilled") {
        //   setXirr(xirr_res.value.result);
        // }
        // if (xirrCurrentHolding_res.status == "fulfilled") {
        //   setXirrCurrentHolding(xirrCurrentHolding_res.value.result);
        // }
        // if (twrr_res.status == "fulfilled") {
        //   if (twrr_res.value?.result?.annualisedTwrr) {
        //     twrr_res.value.result.annualisedTwrr =
        //       twrr_res.value?.result?.annualisedTwrr * 100;
        //   }
        //   setTwrr(twrr_res.value.result);
        // }

        // if (twrrCurrentHolding_res.status == "fulfilled") {
        //   if (twrrCurrentHolding_res.value?.result?.annualisedTwrr) {
        //     twrrCurrentHolding_res.value.result.annualisedTwrr =
        //       twrrCurrentHolding_res.value?.result?.annualisedTwrr * 100;
        //   }
        //   setTwrrCurrentHolding(twrrCurrentHolding_res.value.result);
        // }
        if (invest_res.status == "fulfilled") {
          let arr = [];
          setInvestmentPortfolio(invest_res.value.result);
        }

        // if (asset_ress.status == "fulfilled") {
        //   let arr = [];
        //   let asset = {};
        //   let colors = [];
        //   asset_ress.value.result.forEach((a) => {
        //     if (selectedAssetClass.includes(a?.asset_class)) {
        //       arr.push({
        //         id: `${a.asset_class}`,
        //         label: `${
        //           a.asset_class
        //             ? a.asset_class.charAt(0).toUpperCase() +
        //               a?.asset_class?.slice(1)
        //             : " "
        //         }  ${a.percentage}%`,
        //         value: Number(a.percentage).toFixed(2),
        //         amount: a.amount,
        //         color: color[a.asset_class],
        //       });
        //       colors.push(color[a.asset_class]);
        //     }

        //     asset = {
        //       ...asset,
        //       [a.asset_class]: {
        //         percent: Number(a.percentage).toFixed(2),
        //         amount: a.amount,
        //         date: a.as_on_date,
        //       },
        //     };
        //   });

        //   setAsset_Color(colors);
        //   setPie({
        //     ...pie,
        //     colors: colors,
        //   });

        //   setAssets(asset);
        //   setAssetData(arr);
        // }
        setLoading(false);
      }
    );
  }, [mfoFilter]);

  const navigate = useNavigate();
  const handleSummaryClick = () => {
    navigate(routes?.mldHolding);
  };

  return (
    <>
      <SummaryPageTop
        backLink={routes?.homeHoldingPage}
        pageTitle={"Market Linked Debentures Portfolio Summary "}
        flip={flip}
        setFlip={setFlip}
        checked={checked}
        setChecked={setChecked}
        setFilterModalOpen={setFilterModalOpen}
      />
      <NewFilter
        filterModalOpen={filterModalOpen}
        setFilterModalOpen={setFilterModalOpen}
        productType="mld"
      />

      {/* <FlippyArray
        page={"pms"}
        investmentData={
          investmentPortfolio.investedAmount != null
            ? currencyFormat(investmentPortfolio.investedAmount)
            : loadingText
        }
        portfolioData={
          investmentPortfolio.portfolioValueAmount != null
            ? currencyFormat(investmentPortfolio.portfolioValueAmount)
            : loadingText
        }
        portfolioDate={{
          asOnDate:
            investmentPortfolio?.asOnDate != null
              ? investmentPortfolio?.asOnDate
              : loadingText,
        }}
        realisedGainData={
          investmentPortfolio.realisedGainLoss != null
            ? currencyFormat(investmentPortfolio.realisedGainLoss)
            : loadingText
        }
        unrealisedGainData={
          investmentPortfolio.unRealisedGainLoss != null
            ? currencyFormat(investmentPortfolio.unRealisedGainLoss)
            : loadingText
        }
        xirrData={
          xirr.xirr != null
            ? roundNumber(Number(xirr.xirr)) + " %"
            : loadingText
        }
        absoluteTwrrData={
          twrr.absoluteTwrr != null
            ? roundNumber(Number(twrr.absoluteTwrr)) + " %"
            : loadingText
        }
        annualisedTwrrData={
          twrr?.annualisedTwrr != null
            ? roundNumber(Number(twrr.annualisedTwrr)) + "%"
            : loadingText
        }
        flip={flip}
      /> */}
      <div style={{ margin: "2%" }}>
        {!checked ? (
          <NewHoldingSummaryCards
            retryFetchTwrr={retryFetchTwrr}
            twrrLoader={twrrLoader}
            twrrError={twrrError}
            infoIconHovered={infoIconHovered}
            holdingSummaryCardsInactive={holdingSummaryCardsInactiveData(
              investmentPortfolio,
              holdingCardData,
              xirr,
              twrr,
              totalGainLossData
            )}
            totalGainLossInactive={
              <>
                <GainsCardInfo
                  text="Realized Gain/Loss"
                  value={
                    holdingSummaryCardsInactiveData(
                      investmentPortfolio,
                      holdingCardData,
                      xirr,
                      twrr,
                      totalGainLossData
                    )?.realisedGainLoss
                  }
                  align="left"
                />
                <GainsCardInfo
                  text="Unrealized Gain/Loss"
                  value={
                    holdingSummaryCardsInactiveData(
                      investmentPortfolio,
                      holdingCardData,
                      xirr,
                      twrr,
                      totalGainLossData
                    )?.unRealisedGainLoss
                  }
                  align="center"
                />
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <GainsCardInfo
                    text="Total Interest Income"
                    value={
                      holdingSummaryCardsInactiveData(
                        investmentPortfolio,
                        holdingCardData,
                        xirr,
                        twrr,
                        totalGainLossData
                      )?.interestIncome
                    }
                    align="right"
                  />
                </div>
              </>
            }
          ></NewHoldingSummaryCards>
        ) : (
          <NewHoldingSummaryCardsActive
            retryFetchTwrrCurrentHolding={retryFetchTwrrCurrentHolding}
            twrrLoaderCurrentHolding={twrrLoaderCurrentHolding}
            twrrErrorCurrentHolding={twrrErrorCurrentHolding}
            infoIconHovered={infoIconHovered}
            holdingSummaryCardsActive={holdingSummaryCardsActiveData(
              holdingCardData,
              xirrCurrentHolding,
              twrrCurrentHolding
            )}
            totalGainLossActive={
              <>
                <GainsCardInfo
                  text="Unrealized Gain/Loss"
                  value={
                    holdingSummaryCardsActiveData(
                      holdingCardData,
                      xirrCurrentHolding,
                      twrrCurrentHolding
                    )?.unRealisedGainLoss
                  }
                  align="left"
                />
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <GainsCardInfo
                    text="Total Interest Income"
                    value={
                      holdingSummaryCardsActiveData(
                        holdingCardData,
                        xirrCurrentHolding,
                        twrrCurrentHolding
                      )?.interestIncome
                    }
                    align="right"
                  />
                </div>
              </>
            }
          ></NewHoldingSummaryCardsActive>
        )}
      </div>
      <PortfolioChart
        type={"mld"}
        loading={loading}
        noPaddingBottom={true}
        viewDetailBtn={
          <Button
            onClick={handleSummaryClick}
            className={classes.viewDetailBtn + " portfolioBtn "}
            style={{
              width: "20%",
              margin: "0% 40% 0",
              // color: "#FFFFFF",
              borderRadius: "7px",
              backgroundColor: "#4f1fda !important",
            }}
            startIcon={<BarChartIcon />}
          >
            View Details
          </Button>
        }
      ></PortfolioChart>
      <PageLoader showLoader={loading ? true : false} />
      <PageLoader
        showLoader={currentHoldingCardLoader && checked ? true : false}
      />
      {/* <Grid style={{ display: "flex", justifyContent: "center" }}>
        <Button
          style={{
            width: "30%",
            margin: "0 2.5%",
            background: " #380D77",
            borderRadius: "50px",
          }}
          className={`${classes.holdingButton}`}
          // variant="contained"
          onClick={handleSummaryClick}
        >
          <Typography
            className={classes.holdingButtonText}
            style={{ padding: "2%" }}
          >
            View Details
          </Typography>
        </Button>
      </Grid> */}
    </>
  );
}

export default MLDSummaryComp;
