import React, { useEffect, useState } from "react";
import SearchHeader from "./SearchHeader";
import TabHeader from "./TabHeader";
import UploadContent from "./UploadContent";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import AppPagination from "./AppPagination";
import { ITEMS_LIMIT_FILES } from "../../Constants";
import { getALLFiles } from "../../api";
import Loader from "../../components/loader/Loader";
import CustomizedSnackbars from "../../components/common/CustomizedSnackbars";
import { useNavigate } from "react-router-dom";
import { OnboardingContext } from "../../Context";
import { FormControl, InputLabel, Select } from "@material-ui/core";
import { FILE_TYPES, PRODUCT_TYPES } from "../../Constants";
import { getFileTypeByProduct } from "../utils/common";

const useStyles = makeStyles((theme) => ({
  pendingVerification: {
    overflow: "scroll",
    height: "60vh",
    overflowX: "hidden",
  },
  pagination: {
    marginTop: "15px",
    float: "right",
    paddingTop: "2px",
    width: "380px",
    background: "#220848",
    border: "1px solid #4E189F",
    boxShadow:
      "inset -4px -4px 10px rgb(76 50 117 / 50%), inset 4px 4px 10px rgb(0 0 0 / 50%)",
    borderRadius: "124.46px",
    paddingBottom: "2px",
  },
  mainContent: {
    background: "#110425",
    boxShadow:
      "-4px 4px 6px #000000, 4px -4px 6px rgba(89, 22, 189, 0.3), inset -4px 4px 20px #000000, inset 3px -4px 10px #5916BD",
    borderRadius: "20px",
    marginTop: "10px",
    position: "absolute",
    width: "95%",
    height: "auto",
    padding: "15px",

    [theme.breakpoints.down("md")]: {
      width: "93%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "93%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "82%",
    },
    inputSearch: {
      marginTop: "1%",
    },
  },
  inputField: {
    borderColor: " #711CF1",
    borderRadius: "20px",
    width: "97%",
  },
  formControl: {
    border: "1px solid #4E189F",
  },
  icon: {
    fill: "#FAF9FF",
  },
}));

function VerificationUploadContent({ pageName }) {
  const itemLimit = ITEMS_LIMIT_FILES;
  const classes = useStyles();
  const [offset, setItemOffset] = useState(0);
  const [searchName, setSearchName] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const { dispatch } = OnboardingContext();
  const [pageCount, setPageCount] = useState(0);
  const [content, setContent] = useState([]);
  const [formData, setFormData] = useState({
    entity: "mf",
    fileType: "transaction",
  });

  const fetchFiles = async () => {
    const fileStatus = pageName === "pending" ? "pending" : "verified";

    try {
      setShowLoader(true);
      const data = await getALLFiles(
        formData?.entity,
        fileStatus,
        searchName,
        offset,
        itemLimit,
        formData?.fileType
      );
      const filesData = data.data;

      if (filesData.success) {
        const totalItem = filesData.data.meta.count;
        setPageCount(Math.ceil(totalItem / itemLimit));
        setContent(filesData.data.result);
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
      setContent([]);
      if (error.response.request.status === 401) {
        navigate("/logout");
      } else if (!error.response.data.error.success) {
        dispatch({
          type: "UPDATE_SNACKBAR_DATA",
          payload: {
            success: false,
            error: true,
            showMsg: true,
            message: error.response.data.error.message,
          },
        });
      }
    }
  };

  const handleChange = (e) => {
    if (e.target.value == "") {
      return;
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    fetchFiles();
  }, [searchName, offset, pageName, formData]);

  return (
    <>
      {showLoader && <Loader />}
      <CustomizedSnackbars />

      <div className="container" style={{ margin: "20px" }}>
        <div className={classes.mainContent}>
          <Grid container style={{ paddingBottom: "1%" }}>
            <Grid className={classes.inputGrid} item xs={6}>
              <FormControl
                variant="outlined"
                className={`${classes.formControl} ${classes.inputField}`}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Product
                </InputLabel>
                <Select
                  native
                  style={{ color: "#FAF9FF" }}
                  labelId="demo-simple-select-outlined-label"
                  label="Select Product"
                  value={formData?.entity}
                  onChange={(e) => handleChange(e)}
                  // error={Boolean(errors?.entity)}
                  inputProps={{
                    name: "entity",
                    id: "outlined-entity_type-native-simple",
                    classes: {
                      icon: classes.icon,
                    },
                  }}
                >
                  {PRODUCT_TYPES.map((product) => {
                    return (
                      <option
                        className="optiondesign"
                        key={product.value}
                        value={product.value}
                      >
                        {product.name}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid className={classes.inputGrid} item xs={6}>
              <FormControl
                variant="outlined"
                className={`${classes.formControl} ${classes.inputField}`}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Type
                </InputLabel>
                <Select
                  native
                  style={{ color: "#FAF9FF" }}
                  labelId="demo-simple-select-outlined-label"
                  label="Select Type"
                  value={formData?.fileType}
                  onChange={(e) => handleChange(e)}
                  // error={Boolean(errors?.fileType)}
                  inputProps={{
                    name: "fileType",
                    id: "outlined-entity_type-native-simple",
                    classes: {
                      icon: classes.icon,
                    },
                  }}
                >
                  {getFileTypeByProduct(formData?.entity, undefined, {
                    payOutReduction: true,
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <div>
            <Grid container className={classes.gridContainer}>
              <Grid className={classes.inputGrid} item xs={4}>
                <TabHeader pageName={pageName} />
              </Grid>
              <Grid item xs={5}></Grid>
              <Grid
                className={`${classes.inputGrid} ${classes.inputSearch}`}
                item
                xs={3}
              >
                <SearchHeader
                  setSearchName={setSearchName}
                  setItemOffset={setItemOffset}
                />
              </Grid>
            </Grid>
          </div>
          <div className={`${classes.pendingVerification} form-container`}>
            <UploadContent
              formData={formData}
              content={content}
              showLoader={showLoader}
            ></UploadContent>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "50px",
              alignItems: "end",
            }}
          >
            {pageCount > 1 && (
              <AppPagination
                customStyle={{ height: "32px", marginTop: "0px" }}
                pageCount={pageCount}
                itemLimit={itemLimit}
                setItemOffset={setItemOffset}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default VerificationUploadContent;
