import React, { useContext, useEffect, useState } from "react";
import { fetchMemberWisePerformance } from "../../../../api";
import { ITEMS_LIMIT_FILES_PARTNER_LIST } from "../../../../Constants";
import PmsReportTable from "../pms/PmsReportTable";
import { OnboardingContext } from "../../../../Context";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import moment from "moment";
import { currencyFormat } from "../../../../hooks/constantFunction";
import { roundNumber, summarizeTableData } from "../../../utils/common";
import { useCurrentHoldingCardsApi } from "../../../../hooks/useCurrentHoldingCardsApi";
export const HoldingMemberWisePerformance = () => {
  const { userState } = OnboardingContext();
  const { holdingCardData, xirrCurrentHolding, twrrCurrentHolding } =
    useCurrentHoldingCardsApi(true, "dashboard");
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(null);
  let clientId = userState.loginedUserData.id;
  const [percentageOfTotalPortfolio, setPercentageOfTotalPortfolio] =
    useState(0);

  const tableColumnsData = [
    { field: "memberName", title: "Member Name", show: true, align: "center" },
    {
      field: "allocation",
      title: "Allocation(%)",
      show: true,
      align: "center",
    },
    {
      field: "investmentValue",
      title: "Investment Value",
      show: true,
      align: "center",
    },
    { field: "currentValue", title: "Current Value", show: true },
    {
      field: "otherIncome",
      title: "Other Income",
      show: true,
    },
    { field: "unRealisedGainLoss", title: "Unrealized Gain/Loss", show: true },
    { field: "xirr", title: "XIRR (%)", show: true },
    { field: "absoluteTwrr", title: "Absolute TWRR (%)", show: true },
    { field: "annualisedTwrr", title: "Annualized TWRR (%)", show: true },
  ];
  const [tableHeader, setTableHeader] = useState(tableColumnsData);
  const [newTableColumns, setNewTableColumns] = useState([]);
  const [secondTableColumns, setSecondTableColumns] = useState([]);
  const [pagiNationData, setPageinationData] = useState({
    pageCount: null,
    // limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
  });
  const [offset, setOffset] = useState(0);

  const objectFormating = (data) => {
    let newData = tableHeader.map((header) => {
      if (header?.field == "memberName") {
        return {
          key: header?.field,
          field: data[`${header.field}`],
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (header?.field == "annualisedTwrr") {
        return {
          key: header?.field,
          field: roundNumber(data[`${header.field}`]) || "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }

      if (
        header?.field == "investmentValue" ||
        header?.field == "realisedGainLoss" ||
        header?.field == "interestIncome" ||
        header?.field == "unRealisedGainLoss" ||
        header?.field == "currentValue" ||
        header?.field == "otherIncome"
      ) {
        return {
          key: header?.field,
          field: currencyFormat(data[`${header.field}`] || 0) || 0,
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      return {
        key: header?.field,
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`]))
          : data[`${header.field}`] || "--",
        title: header.title,
        show: header.show,
        align: header?.align,
      };
    });
    return newData;
  };

  useEffect(() => {
    setSecondTableColumns(
      summarizeTableData(
        newTableColumns,
        holdingCardData,
        xirrCurrentHolding,
        twrrCurrentHolding,
        pagiNationData?.pageCount,
        "holding",
        "holding-member-wise-performance",
        "",
        percentageOfTotalPortfolio
      )
    );
  }, [
    newTableColumns,
    holdingCardData,
    xirrCurrentHolding,
    twrrCurrentHolding,
  ]);

  useEffect(() => {
    if (!search) {
      setLoading(true);
    }
    getSummary();
  }, [mfoFilter, offset, search]);
  let getSummary = () => {
    setLoading(true);
    let params = {
      ...getfilter_as_params(),
      //   assetClass: page,
      offset: offset,
      limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
      reportType: "dashboard",
    };

    if (search) {
      params["search"] = search;
    }
    if (mfoFilter.startDate) {
      params["startDate"] = moment(mfoFilter.startDate).format("YYYY-MM-DD");
      params["endDate"] = moment(mfoFilter.endDate).format("YYYY-MM-DD");
    }
    if (Object.keys(mfoFilter.checkData).length > 0) {
      params["memberId"] = Object.keys(mfoFilter.checkData).join(",");
    }
    if (Object.keys(mfoFilter.advisor).length > 0) {
      params["advisor"] = Object.keys(mfoFilter.advisor).join(",");
    }

    fetchMemberWisePerformance(
      userState.loginedUserData.id,
      params,
      "dashboard"
    )
      .then((res) => {
        console.log("fsdfsdfsdfd", res);
        setPageinationData((prev) => {
          return { ...prev, pageCount: res?.data?.data?.meta?.count };
        });
        setPercentageOfTotalPortfolio(
          res?.data?.data?.total?.percentageOfTotalPortfolio
        );
        // setTableData(res?.data?.data?.result);
        //new table implimentation
        let newList = [];
        res?.data?.data?.result?.map((val) => {
          let obj = objectFormating(val);
          newList.push(obj);
        });
        setNewTableColumns(newList);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      <PmsReportTable
        productType="memberWisePerformance"
        searchParam={search}
        setTableColumns={setNewTableColumns}
        tableColumns={newTableColumns}
        setSecondTableColumns={setSecondTableColumns}
        tableColumns2={secondTableColumns}
        // setOpenModal={setOpenModal}
        // openModal={openModal}
        loading={loading}
        headerList={tableColumnsData}
        tableHeader={tableHeader}
        setTableHeader={setTableHeader}
        stickyProductName={true}
        // reset={getSummary}
        pageCount={pagiNationData?.pageCount}
        setItemOffset={setOffset}
        pageNumber={offset / ITEMS_LIMIT_FILES_PARTNER_LIST + 1 || 1}
      />
    </>
  );
};
