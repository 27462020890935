import React from "react";
import { Grid, Box, Typography, makeStyles } from "@material-ui/core";
import panCard from "../../../assets/images/panCardPic.png";
import aadharCard from "../../../assets/images/aadharCardPic.png";
import NoIdCard from "../../common/NoIdCard";
const useStyles = makeStyles({
  mainContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px",
  },
  iconTextWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },
  mainTextHeading: {
    color: "#FFF",
    fontFamily: "Avenir",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "12.658px",
    letterSpacing: "2.4px",
    textTransform: "uppercase",
    opacity: 0.7,
    marginBottom: "8px",
  },

  mainText: {
    color: "#FFF",
    fontFamily: "Avenir",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
  },
  mainTextClientName: { marginBottom: "8px" },
  accordionSummary: {
    flexDirection: "row-reverse",
    gap: "20px",
  },
  accordionSummaryHeading: {
    color: "#FFF",
    fontFamily: "Avenir",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
  },
  accordion: {
    background: "#21163E",
    borderRadius: "8px",
  },
  expandMoreIcon: {
    borderRadius: "50%",
    background: "#55596D",
  },
  accordionDetails: {
    //   height: "381px",
    background: "#21163E",
    paddingLeft: "32px",
    borderRadius: "8px",
    // marginTop: "32px",
  },
  accordionDetailsMainText: {
    fontSize: "16px",
    color: "#FFF",
  },
  secondGridRow: {
    marginTop: "48px",
  },
  row1Accordion: {
    width: "20%",
    alignSelf: "flex-start",
    marginTop: "2rem",
  },
  row2Accordion: {
    width: "20%",
    alignSelf: "flex-start",
    marginTop: "2.5rem",
  },
});
export const RepresentativeDetails = ({ partnerData }) => {
  const classes = useStyles();
  const TextBlock = ({ text1, text2, classNameText1, classNameText2 }) => {
    const combinedClassNamesText1 = classNameText1?.join(" ");
    const combinedClassNamesText2 = classNameText2?.join(" ");

    return (
      <Box className="textWrapper">
        <Typography className={combinedClassNamesText1}>{text1}</Typography>
        <Typography className={combinedClassNamesText2}>{text2}</Typography>
      </Box>
    );
  };
  return (
    <Grid
      container
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
      }}
    >
      {/* ROW 1 */}
      <Box className={classes.row1Accordion}>
        <TextBlock
          text1="Partner Representative
                  Name"
          text2={
            !partnerData?.partnerRepresentative?.full_name
              ? "-"
              : partnerData?.partnerRepresentative?.full_name
          }
          classNameText1={[classes.mainTextHeading]}
          classNameText2={[classes.mainText, classes.accordionDetailsMainText]}
        />
      </Box>
      <Box className={classes.row1Accordion}>
        <TextBlock
          text1="Date of Birth"
          text2={
            !partnerData?.partnerRepresentative?.dob
              ? "-"
              : partnerData?.partnerRepresentative?.dob
          }
          classNameText1={[classes.mainTextHeading]}
          classNameText2={[classes.mainText, classes.accordionDetailsMainText]}
        />
      </Box>
      <Box className={classes.row1Accordion}>
        <TextBlock
          text1="Address"
          text2={
            !partnerData?.partnerRepresentative?.address
              ? "-"
              : partnerData?.partnerRepresentative?.address
          }
          classNameText1={[classes.mainTextHeading]}
          classNameText2={[classes.mainText, classes.accordionDetailsMainText]}
        />
      </Box>
      <Box className={classes.row1Accordion}>
        <TextBlock
          text1="PAN NO"
          text2={
            !partnerData?.partnerRepresentative?.pan
              ? "-"
              : partnerData?.partnerRepresentative?.pan
          }
          classNameText1={[classes.mainTextHeading]}
          classNameText2={[classes.mainText, classes.accordionDetailsMainText]}
        />
      </Box>
      <Box className={classes.row1Accordion}>
        <TextBlock
          text1="EMAIL ID"
          text2={
            !partnerData?.partnerRepresentative?.email
              ? "-"
              : partnerData?.partnerRepresentative?.email
          }
          classNameText1={[classes.mainTextHeading]}
          classNameText2={[classes.mainText, classes.accordionDetailsMainText]}
        />
      </Box>

      {/* ROW 2 */}
      <Box className={classes.row2Accordion}>
        <TextBlock
          text1="MOBILE NO"
          text2={
            !partnerData?.partnerRepresentative?.mobile
              ? "-"
              : partnerData?.partnerRepresentative?.mobile
          }
          classNameText1={[classes.mainTextHeading]}
          classNameText2={[classes.mainText, classes.accordionDetailsMainText]}
        />
      </Box>
      <Box className={classes.row2Accordion}>
        <TextBlock
          text1="PAN CARD"
          text2=""
          classNameText1={[classes.mainTextHeading]}
          classNameText2={[classes.mainText]}
        />
        <div style={{ width: "145px", height: "102px" }}>
          {partnerData?.pan_card_url ? (
            <img
              style={{ width: "100%", height: "100%" }}
              src={partnerData?.pan_card_url}
              loading="lazy"
            />
          ) : (
            <NoIdCard />
          )}
        </div>
      </Box>
      <Box className={classes.row2Accordion}>
        <TextBlock
          text1="ID PROOF"
          text2=""
          classNameText1={[classes.mainTextHeading]}
          classNameText2={[classes.mainText]}
        />
        <div style={{ width: "145px", height: "102px" }}>
          {partnerData?.id_proof_url ? (
            <img
              style={{ width: "100%", height: "100%" }}
              src={partnerData?.id_proof_url}
              loading="lazy"
            />
          ) : (
            <NoIdCard />
          )}
        </div>
      </Box>
      <Box className={classes.row2Accordion}>
        <TextBlock
          text1="KYC Status"
          text2={
            !partnerData?.partnerRepresentative?.kyc_status
              ? "-"
              : partnerData?.partnerRepresentative?.kyc_status
          }
          classNameText1={[classes.mainTextHeading]}
          classNameText2={[classes.mainText, classes.accordionDetailsMainText]}
        />
      </Box>
      <Box className={classes.row2Accordion}>
        <TextBlock
          text1="KYC Date"
          text2={
            !partnerData?.partnerRepresentative?.kyc_date
              ? "-"
              : partnerData?.partnerRepresentative?.kyc_date
          }
          classNameText1={[classes.mainTextHeading]}
          classNameText2={[classes.mainText, classes.accordionDetailsMainText]}
        />
      </Box>
    </Grid>
  );
};
