import React, { useEffect, useState } from "react";
import {
  Container,
  Paper,
  Grid,
  TextField,
  FormControlLabel,
  InputLabel,
  Checkbox,
  Button,
  Typography,
  Link,
  IconButton,
  Box,
  InputAdornment,
} from "@material-ui/core";
import CustomInput from "../common/CustomInput";
import DefaultSign from "../DefaultSign";
// import BackButton from "../common/BackBtnWithArrow/BackButton";
import PartnerHeading from "../PartnerHeading";
import {
  registerClientForm1ValidationSchema3,
  registerForm2ValidationSchema,
} from "../ValidationSchema";
import StepProgressBar from "../common/StepProgressBar/StepProgressBar";
// import { useTheme } from "@emotion/react";
import { useForm } from "../hooks/useForm";
// import moment from "moment";
import { ReactComponent as Upload } from "../../../assets/icons/upload.svg";
import { ReactComponent as VerifiedTick } from "../../../assets/icons/verified.svg";
// import { dateConvert } from "./utils/utils";
import ImageModal from "../common/ImageModal";
import ErrorMessageComp from "../common/ErrorMessageComp";
import LoadingButton from "../common/LoadingButton";
import seperation from "../../../assets/partner-images/seperation.svg";
import ClientForm3 from "./ClientForm3";

export default function ClientStep3(props) {
  const [files, setFiles] = useState();
  const [filesUrl, setFilesUrl] = useState({ idProof: "", pan: "" });
  const [openModal, setOpenModal] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const { head, customLeftMargin } = props;
  const desc = head?.desc
    ? head?.desc
    : {
        center: [
          {
            title: "Why MFO?",
            description:
              "Holdings are the contents of an investment portfolio held by an individual or an entity, such as a mutual fund or a pension fund. Portfolio holdings may encompass a wide ",
          },
        ],
      };

  // const {
  //   kycStatus,
  //   kycDate,
  //   address,
  //   entityName,
  //   entityType,
  //   panNumber,
  //   commencementDate,
  //   doi,
  //   dateOfBirth,
  //   userRole,
  //   memberType,
  //   memberName,
  //   fullName
  // } = formData;

  // console.log(formData, "aflaibuaiwbasf");

  // console.log(formData, "aflknafoias");

  const verified = {
    endAdornment: (
      <InputAdornment position="end">
        <VerifiedTick />
      </InputAdornment>
    ),
  };

  return (
    <>
      <DefaultSign
        left={9}
        right={3}
        desc={desc}
        customLeftMargin={customLeftMargin}
      >
        <ClientForm3 {...props} />
      </DefaultSign>
    </>
  );
}
