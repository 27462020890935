import React, { useContext, useEffect, useState } from "react";
import useStyles from "../common/ProductsStyles";
import NewHeadAndDetailsComp from "../newDesigns/common/newHeadingAndDetailsComp/NewHeadAndDetailsComp";
import { getDashboardAssetData } from "../../../../api";
import { OnboardingContext } from "../../../../Context";
import PageLoader from "../common/pageLoader";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import { currencyFormat } from "../../../../hooks/constantFunction";
import PortfolioChart from "../../common/portfolioChart";
function InvestedVSCurrent() {
  const { userState } = OnboardingContext();
  const classes = useStyles();
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const [data, setData] = useState([]);
  let clientId = userState.loginedUserData.id;
  const [loading, setLoading] = useState(false);
  const boxStyle = {
    width: "13px",
    height: "13px",
  };
  useEffect(() => {
    let params = { ...getfilter_as_params() };
    params["assetClass"] = "equity,debt,cash,alternate";
    params["holdingType"] = "current";
    setLoading(true);
    getDashboardAssetData(clientId, params, "dashboard")
      .then((response) => {
        console.log(response, "res");
        setData(response);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching asset data:", error);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          margin: "2%",
        }}
      >
        <div style={{ height: "100%", width: "100%" }}>
          <NewHeadAndDetailsComp
            title={"Invested Value VS Current Value"}
            content={""}
          />

          <div style={{ display: "flex" }}>
            {data?.data?.map((val) => (
              <div className="AssetCards">
                <div>
                  <div>
                    <div className="subtext"> ASSET CLASS</div>
                    <div className="fw50012">{val.assetClass}</div>
                  </div>
                </div>
                <div style={{ paddingTop: "30px", maxWidth: "70%" }}>
                  <div
                    className="assetValue"
                    style={{
                      background: "rgb(54 51 33)",
                      borderLeft: "7px solid #EDCA19",
                      width: `${
                        (val.investedAmount /
                          (val.investedAmount + val.portfolioValueAmount)) *
                        100
                      }%`,
                    }}
                  >
                    <b>{currencyFormat(val.investedAmount)}</b>
                  </div>
                  <div
                    className="assetValue"
                    style={{
                      backgroundColor: "rgb(9 26 78)",
                      borderLeft: "7px solid #6A8EF5",
                      width: `${
                        (val.portfolioValueAmount /
                          (val.investedAmount + val.portfolioValueAmount)) *
                        100
                      }%`,
                    }}
                  >
                    <b>{currencyFormat(val.portfolioValueAmount)}</b>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div
            style={{ marginLeft: "-2%", marginRight: "-2%", marginTop: "50px" }}
          >
            <PortfolioChart
              titlebtn={
                data && (
                  <div
                    style={{
                      display: "flex ",
                      justifyContent: "flex-end",
                      gap: "10px",
                    }}
                  >
                    <div>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          gap: 10,
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        <div
                          style={{ ...boxStyle, backgroundColor: "#EDCA19" }}
                        ></div>
                        Investment Value
                      </div>
                      <div
                        className="fw50012"
                        style={{ fontSize: "17px", paddingTop: "12px" }}
                      >
                        {currencyFormat(data.totalInvestedAmount)}
                      </div>
                    </div>
                    <div>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          gap: 10,
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        <div
                          style={{ ...boxStyle, backgroundColor: "#6A8EF5" }}
                        ></div>
                        Current Value
                      </div>
                      <div
                        className="fw50012"
                        style={{ fontSize: "17px", paddingTop: "12px" }}
                      >
                        {currencyFormat(data.totalPortfolioAmount)}
                      </div>
                    </div>
                  </div>
                )
              }
              chartxTitle="Portfolio Returns"
              headerTitle="Investment VS Current Value"
              headerContent=" "
              sub={"investedvsCurrent"}
              exp={"current-value"}
              type={"dashboard"}
              loading={loading}
              noPaddingBottom={true}
              viewDetailBtn={<></>}
            ></PortfolioChart>
          </div>
        </div>
        <PageLoader showLoader={loading} />
      </div>
    </>
  );
}

export default InvestedVSCurrent;

function AdvisorCard({ percentage }) {
  const colors = ["#F5D580", "#4F5FCF", "#C5306B", "#175B85"];

  const progressLineWidth = 180 * (percentage / 100);
  return <></>;
}
