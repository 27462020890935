import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import { Add, Remove } from "@material-ui/icons";

import CashflowTablePrefix from "../../../products/holding_summary/CashflowTablePrefix";
import { currencyFormatPrintNoFormat as currencyFormat } from "../../../../../hooks/constantFunction";

function CashflowTable({
  topHeader,
  header,
  inflows,
  outflows,
  footer,
  header2,
  denomination,
}) {
  // Generate top headers
  const generateHeaders = (head, topHeader, className) => {
    if (topHeader) {
      return head.map((h) => (
        <TableCell className="print_top-header ">
          {h}
          {/* {h == "total" ? "" : h && "*"} */}
        </TableCell>
      ));
    }
    let k = null;

    return head.map((h) => (
      <TableCell className={className}>
        <div className="p_flex a_cen fAvenirBold font600">
          {renderValue(h)}
          {h == "Opening Balance" ? (
            <div className="print_cash_expTag">A</div>
          ) : (
            ""
          )}
          {h == "Closing Balance" ? (
            <div className="print_cash_expTag">D</div>
          ) : (
            ""
          )}
        </div>
      </TableCell>
    ));
  };

  // Generate sub headers (inflows, outflows)
  const generateSubHeader = (head, expression) => {
    return (
      <TableRow>
        <TableCell
          className="printCashflowSubheader cash_flow cashflow_header_border "
          style={{
            borderTop: head == "Cash Inflows" ? "none" : "",
          }}
          colSpan={topHeader.length + 1}
        >
          <div className="p_flex a_cen ">
            {" "}
            {head} {expression}{" "}
          </div>
        </TableCell>
      </TableRow>
    );
  };
  const generateRows = (data, isInflow) => {
    const iconStyles = {
      color: "black",
      width: "1rem",
    };
    return data.map((row, rowIdx) => (
      <TableRow>
        {row.map((cell, index) => (
          <TableCell
            className="print_cash_rows"
            style={{ position: "relative" }}
          >
            <div>
              {index === 0 && (
                <>
                  <CashflowTablePrefix
                    showBottom={data.length !== rowIdx + 1}
                  />
                  {/* <IconButton
                    style={{
                      backgroundColor: "#BEC7D4",
                      width: "1.4rem",
                      height: "1.4rem",
                      marginLeft: "1rem",
                    }}
                  >
                    {isInflow ? (
                      <Add style={iconStyles} />
                    ) : (
                      <Remove style={iconStyles} />
                    )}
                  </IconButton> */}
                </>
              )}

              <span>{renderValue(cell) || "-"}</span>
              {cell == "Closing Balance" ? (
                <div
                  className="print_cash_expTag"
                  style={{ marginLeft: "-10px" }}
                >
                  D
                </div>
              ) : (
                ""
              )}
            </div>
          </TableCell>
        ))}
      </TableRow>
    ));
  };

  // Formatting values to currency denomination if they are number
  const renderValue = (value) => {
    if (typeof value == "number")
      return currencyFormat(value || 0, undefined, denomination, "") || "--";
    return value;
  };
  return (
    <TableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead className="tableRowStyle printtableSticky">
          <TableRow>{generateHeaders(["", ...topHeader], true)}</TableRow>
          <TableRow>
            {generateHeaders(
              ["Opening Balance", ...header],
              0,
              "cashflow_header_border "
            )}
          </TableRow>
        </TableHead>
        <TableBody className="tableBodyPms printtableSticky printcashflowTable">
          {inflows?.length && inflows.length && (
            <>
              {generateSubHeader(
                "Cash Inflows",
                <div className="print_cash_expTag">B</div>
              )}
              {generateRows(inflows, true)}
            </>
          )}
          {outflows?.length && outflows.length && (
            <>
              {generateSubHeader(
                "Cash Outflows",
                <div className="print_cash_expTag">C</div>
              )}
              {generateRows(outflows)}
            </>
          )}
          <TableRow>
            {generateHeaders(["Closing Balance", ...header2], 0)}
          </TableRow>

          {footer && (
            <TableRow className="print_cash_footer">
              <TableCell className="cashflow_header_border ">
                <div className="button23Container print_cash_borderless fAvenirBold ">
                  Net{" "}
                  <div className="print_cash_expTag print_cash_longexp">
                    D+B-C-A
                  </div>
                </div>
              </TableCell>
              {footer.map((amount) => (
                <TableCell className="cashflow_header_border fAvenirBold font600">
                  <span className="fAvenirBold font600">
                    {renderValue(amount) || "-"}
                  </span>
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CashflowTable;
