import { useState, useEffect, useContext } from "react";
import { OnboardingContext } from "../Context";
import {
  fetchProductDetailsViewXIRR,
  fetchProductSummary,
  productWiseTwrr,
} from "../api";
import { mfoFilterContext } from "../mfo_context/Mfo_Context";
import moment from "moment";
import { last } from "lodash";

/**
 * Custom hook to fetch the product summary data for a specific asset class.
 *
 * @param {string} assetClass - The asset class for which the product summary data is to be fetched.
 * @returns {Array} - An array containing the product summary data.
 */
export const useProductSummary = (
  assetClass,
  setLoading,
  resetTabel,
  expandRow,
  expandAll,
  handleLoading
) => {
  const { userState } = OnboardingContext();
  const { mfoFilter } = useContext(mfoFilterContext);
  const [productSummary, setProductSummary] = useState();
  const [copyExpandRow, setCopyExpandRow] = useState([]);

  const getProductTwrr = async (assetClass) => {
    let params = {};
    if (mfoFilter.startDate) {
      params["startDate"] = moment(mfoFilter.startDate).format("YYYY-MM-DD");
      params["endDate"] = moment(mfoFilter.endDate).format("YYYY-MM-DD");
    }
    if (Object.keys(mfoFilter.checkData).length > 0) {
      params["memberId"] = Object.keys(mfoFilter.checkData).join(",");
    }
    if (Object.keys(mfoFilter.advisor).length > 0) {
      params["advisor"] = Object.keys(mfoFilter.advisor).join(",");
    }

    try {
      handleLoading(assetClass || last(expandRow), true);
      let res = await productWiseTwrr({
        clientId: userState.loginedUserData.id,
        assetClass: assetClass || last(expandRow),
        ...params,
      });
      setProductSummary((prev) => {
        let newList = { ...prev };

        let newArray = [];

        prev[last(expandRow)]?.map((val) => {
          let filteredVal = res?.data?.data?.result?.data?.filter(
            (val2) => val2?.productFullName == val.product
          )[0];
          // ) {
          // console.log(filteredVal?.absoluteTwrr, "akajkbkag");

          val.absoluteTwrr = filteredVal?.absoluteTwrr || "--";
          val.annualisedTwrr = filteredVal?.annualisedTwrr || "--";
          newArray.push(val);
        });

        newList[last(expandRow)] = newArray;

        console.log(newList, "agkjj");

        return newList;
      });
      handleLoading(assetClass || last(expandRow), false);

      // throw "error";
      // });
    } catch (error) {
      if (error) {
        setProductSummary((prev) => {
          let newList = { ...prev };
          let newArray = [];
          prev[last(expandRow)]?.map((val) => {
            val.absoluteTwrr = "Error";
            val.annualisedTwrr = "Error";
            newArray.push(val);
          });
          newList[last(expandRow)] = newArray;
          // console.log(prev, "agkjj");
          return newList;
        });
      }
      handleLoading(assetClass || last(expandRow), false);
    }
  };

  const handleApiCall = async () => {
    let params = {};
    if (mfoFilter.startDate) {
      params["startDate"] = moment(mfoFilter.startDate).format("YYYY-MM-DD");
      params["endDate"] = moment(mfoFilter.endDate).format("YYYY-MM-DD");
    }
    if (Object.keys(mfoFilter.checkData).length > 0) {
      params["memberId"] = Object.keys(mfoFilter.checkData).join(",");
    }
    if (Object.keys(mfoFilter.advisor).length > 0) {
      params["advisor"] = Object.keys(mfoFilter.advisor).join(",");
    }
    /* Product Summary API CALL*/
    // this case is only for expand all and colapse all logic
    if (expandAll && expandRow?.length == 4) {
      expandRow?.map((val) => {
        setLoading(true);

        Promise.allSettled([
          fetchProductSummary(userState.loginedUserData.id, val, params),
          fetchProductDetailsViewXIRR(
            userState.loginedUserData.id,
            { ...params, assetClass: val },
            "past"
          ),
        ])
          .then(([product_Summary, benchmark_xirr]) => {
            if (product_Summary?.status === "fulfilled") {
              product_Summary?.value?.data?.data?.result?.forEach((val) => {
                val.absoluteTwrr = "loading";
                val.annualisedTwrr = "loading";
                val.benchmarkXirr =
                  benchmark_xirr?.value?.data?.data?.result?.filter(
                    (val2) => val2?.product == val?.product
                  )[0]?.benchmarkXirr;
                val.benchmarkName =
                  benchmark_xirr?.value?.data?.data?.result?.filter(
                    (val2) => val2?.product == val?.product
                  )[0]?.benchmarkName;
              });
              const apiData = product_Summary?.value?.data?.data?.result;
              setProductSummary((prev) => {
                return {
                  ...prev,
                  [val]: apiData,
                };
              });
            }
            setLoading(false);
            getProductTwrr();
          })
          .catch((error) => {
            setLoading(false);
          });
      });
    } else if (expandRow?.length > 0) {
      // console.log(expandRow, copyExpandRow, "glagnjkabgj");

      if (expandRow?.length > copyExpandRow?.length) {
        setLoading(true);
        Promise.allSettled([
          fetchProductSummary(
            userState.loginedUserData.id,
            last(expandRow),
            params
          ),
          fetchProductDetailsViewXIRR(
            userState.loginedUserData.id,
            { ...params, assetClass: last(expandRow) },
            "past"
          ),
        ])
          .then(([product_Summary, benchmark_xirr]) => {
            if (product_Summary?.status === "fulfilled") {
              product_Summary?.value?.data?.data?.result?.forEach((val) => {
                val.absoluteTwrr = "loading";
                val.annualisedTwrr = "loading";
                val.benchmarkXirr =
                  benchmark_xirr?.value?.data?.data?.result?.filter(
                    (val2) => val2?.product == val?.product
                  )[0]?.benchmarkXirr;
                val.benchmarkName =
                  benchmark_xirr?.value?.data?.data?.result?.filter(
                    (val2) => val2?.product == val?.product
                  )[0]?.benchmarkName;
              });
              const apiData = product_Summary?.value?.data?.data?.result;
              setProductSummary((prev) => {
                return {
                  ...prev,
                  [last(expandRow)]: apiData,
                };
              });
            }

            setLoading(false);
            getProductTwrr();
          })
          .catch((error) => {
            setLoading(false);
          });
      }
    }

    setCopyExpandRow(() => expandRow);
  };

  useEffect(() => {
    handleApiCall();
  }, [mfoFilter, assetClass, resetTabel, expandRow]);
  return { productSummary, getProductTwrr };
};
