import React from 'react'
import { useLocation } from 'react-router-dom'
import Default from '../components/dashboard/defaultPages/Default'
import HoldingReport from '../components/dashboard/products/holding_summary/HoldingReport'
import SummaryDetailsComp from '../components/dashboard/products/SummaryDetailsComp'

function SummaryDetails() {
    const location = useLocation()
    const pageName = location?.pathname.split('/')[3]

    return (
        <Default>
            {/* <SummaryDetailsComp pageName={pageName} /> */}
            <HoldingReport pageName={pageName}></HoldingReport>

        </Default>

    )
}

export default SummaryDetails