import React, { useEffect, useState } from "react";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import { useMemo } from "react";

const useStyles = makeStyles(() => ({
  pagination: {
    marginTop: "2%",
    float: "right",
    paddingTop: "2px",
    // background: "#220848",
    // border: "1px solid #4E189F",
    // boxShadow:
    //   "inset -4px -4px 10px rgb(76 50 117 / 50%), inset 4px 4px 10px rgb(0 0 0 / 50%)",
    borderRadius: "124.46px",
    paddingBottom: "2px",
    //   height: '32px'
  },
  ul: {
    "& .MuiPaginationItem-root": {
      "&.Mui-selected": {
        background: "var( --primaryHighlight-color) !important",
        color: "var(btnText-color) !important",
        // borderRadius: '50%',
      },
      background: "var(--table)",
    },
    "& .MuiPaginationItem-ellipsis": {
      height: "32px",
      borderRadius: "4px",
      display: "grid",
      placeItems: "center",
    },
  },
}));

export default function AppPagination({
  pageCount,
  setItemOffset,
  itemLimit,
  pageNumber,
  customStyle,
  otherProps = {},
  returnno,
  setPageNumber,
}) {
  const classes = useStyles();
  console.log(pageNumber, "pagenumber");
  const pageNO = pageNumber ? pageNumber : 1;
  const [page, setPage] = useState(pageNumber ? pageNumber : 1);

  const handleChange = (value) => {
    console.log("handle page clicked", value);
    setPage(parseInt(value));
  };

  useEffect(() => {
    if (setItemOffset) {
      if (returnno) {
        return setItemOffset(page - 1);
      } else {
        setItemOffset(itemLimit * page - itemLimit);
      }
    }
  }, [page]);
  // console.log(pageNumber, "alskngajklsgn");
  useMemo(() => {
    // console.log(page, "alskngajklsgn");

    if (pageNumber) {
      // console.log(page, "alskngajklsgn");

      setPage(pageNumber);
    }
  }, [pageNO]);

  return (
    <Pagination
      style={{ ...customStyle }}
      page={pageNumber ? pageNumber : page}
      onChange={(e, value) => handleChange(value)}
      className={classes.pagination}
      classes={{ root: classes.ul }}
      count={pageCount}
      variant="outlined"
      shape="rounded"
      {...otherProps}
    />
  );
}
