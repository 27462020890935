export const onBoardingReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_USERTYPE":
      return { ...state, userType: action?.payload };

    case "CHANGE_ACTIVE_STEP":
      return { ...state, activeStep: action?.payload };

    case "ENTER_USERNAME":
      return { ...state, signInFormData: action?.payload };

    case "ENTER_PASSWORD":
      const signInFormData = state.signInFormData;
      const signInFormDataNew = {
        ...signInFormData,
        password: action?.payload,
      };
      return { ...state, signInFormData: signInFormDataNew };

    case "UPDATE_LOGIN_USERDATA":
      return { ...state, loginedUserData: action?.payload };

    case "UPDATE_SNACKBAR_DATA":
      return { ...state, appSnackbarData: action?.payload };

    case "UPDATE_CLIENT_MEMBER_DATA":
      const clientMemberData = state.clientMemberData;
      const clientMemberDataNew = { ...clientMemberData, ...action?.payload };
      return { ...state, clientMemberData: clientMemberDataNew };

    case "UPDATE_PARTNER_DATA":
      const partnerDetail = state.partnerDetail;
      const partnerDetailNew = { ...partnerDetail, ...action?.payload };
      return { ...state, partnerDetail: partnerDetailNew };

    case "UPDATE_PARTNER_REPRESENTATIVE_DATA":
      const partnerRepresentativeDetail = state.partnerRepresentativeDetail;
      const partnerRepresentativeDetailNew = {
        ...partnerRepresentativeDetail,
        ...action?.payload,
      };
      return {
        ...state,
        partnerRepresentativeDetail: partnerRepresentativeDetailNew,
      };

    case "EMPTY_CLIENT_MEMBER_DATA":
      return { ...state, clientMemberData: action?.payload };

    case "EMPTY_PARTNER_DATA":
      return { ...state, partnerDetail: action?.payload };

    case "EMPTY_PARTNER_REPRESENTATIVE_DATA":
      return { ...state, partnerRepresentativeDetail: action?.payload };

    case "SET_SCOPES":
      return { ...state, scopes: action?.payload };

    default:
      break;
  }
};
