import React from "react";
import { useStyles } from "./CardStyles";
import { Card, CardContent } from "@material-ui/core";
import { NestedCard } from "./NestedCard";
export const MainCard = ({
  className,
  onClick,
  children,
  style,
  carContentProps,
}) => {
  const classes = useStyles();

  return (
    <Card
      className={`${classes.mainCard} ${className}`}
      onClick={onClick}
      style={style}
    >
      <CardContent className={classes.mainCardContent} {...carContentProps}>
        {children}
      </CardContent>
    </Card>
  );
};
