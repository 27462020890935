import { Breadcrumbs, Typography } from "@material-ui/core";
import React from "react";
// import { dateFormatter, dateFormatter2 } from "../../../utils/common";
import { dateFormatCommon, dateFormatter } from "../../../utils/common";
import "./Table.css";
import moment from "moment";

export default function Header({
  title,
  type,
  subtext,
  width,
  title2,
  title1,
  lineColor,
  tabs,
  tab,
  titleStyle = {},
  updatedAsOn,
  noDateFormat,
  hideHr,
  style = {},
  date = "",
  greyText,
  breadcrumbs,
  reportType,
  denomination,
  financialYear,
}) {
  function layout(type) {
    switch (type) {
      case 1:
        return (
          <div style={{ paddingTop: "67px", paddingBottom: "47px" }}>
            <div className="p_flex alignBottom gap20">
              <p className="gloabalFont title3">{title}</p>
              {updatedAsOn && (
                <p className="gloabalFont asOnDate">
                  As On{" "}
                  {noDateFormat ? updatedAsOn : dateFormatCommon(updatedAsOn)}
                </p>
              )}
            </div>
          </div>
        );
      case 2:
        return (
          <div style={{ paddingTop: "67px", paddingBottom: "47px" }}>
            <div>
              <p
                className="gloabalFont title3"
                // style={{ width: "min-content" }}
              >
                {title}
              </p>

              {subtext && (
                <div
                  className=" p_flex"
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    alignItems: "flex-end",
                    paddingBottom: "4px",
                    paddingTop: "28px",
                    width: width ? width : "5in",
                    ...style,
                  }}
                >
                  {subtext}
                </div>
              )}
            </div>
          </div>
        );
      case 3:
        return (
          <div style={{ paddingTop: "67px", paddingBottom: "47px" }}>
            <div>
              <p
                // variant="h4"
                // component="h1"
                // style={{ width: "min-content" }}
                className="gloabalFont title3"
              >
                {title}
              </p>
              <hr className="hrLine" />

              {subtext && (
                <div
                  className=" p_flex"
                  style={{
                    fontSize: "18px",
                    alignItems: "flex-end",
                    paddingBottom: "4px",
                    paddingTop: "28px",
                    width: "40%",
                  }}
                >
                  {subtext}
                </div>
              )}
            </div>
          </div>
        );
      case 4:
        return (
          <div style={{ paddingTop: "67px", paddingBottom: "47px" }}>
            <div>
              <p className="gloabalFont title3">{title}</p>
              <p className="gloabalFont title2">{title2}</p>
              <hr className="hrLine" />
              {subtext && <p className="gloabalFont subtext2">{subtext}</p>}
            </div>
          </div>
        );
      case 5:
        return (
          <div style={{ paddingTop: "67px", paddingBottom: "47px" }}>
            <div>
              <p className="gloabalFont title3">{title}</p>
              <p className="gloabalFont title2">{title2}</p>
              <hr className="hrLine" />
              {subtext && <p className="gloabalFont subtext5">{subtext}</p>}
            </div>
          </div>
        );
      case 6:
        return (
          <div style={{ paddingTop: "67px" }}>
            <div>
              <p className="gloabalFont title3">{title}</p>
              <p className="gloabalFont title2">{title2}</p>
              <hr
                className="hrLine"
                style={{ backgroundColor: lineColor ? lineColor : "blue" }}
              />
              {subtext && <p className="gloabalFont subtext5">{subtext}</p>}
            </div>
          </div>
        );
      case 7:
        return (
          <div style={{ paddingTop: "67px", paddingBottom: "47px" }}>
            <div>
              <p
                className="gloabalFont title3"
                // style={{ width: "min-content" }}
              >
                {title}
              </p>

              {subtext && <p className="gloabalFont subtext2">{subtext}</p>}
            </div>
          </div>
        );
      case 8:
        return (
          <div style={{ paddingBottom: "47px" }}>
            {/* <div
              style={{
                // background: "red",
                // display: "flex",
                // justifyContent: "flex-end",
                // alignContent: "center",
                // background: "#F5F9FF",
                // height: "27px",
                // marginBottom: "16px",
                width: "-webkit-fill-available",
                left: 0,
                position: "absolute",
                paddingRight: "65px",
              }}
              className="holdingTopPdfSec1"
            >
              <p
                className="holdingTopPdfSec1Txt"
                // style={{ marginRight: "66px" }}
              >
                {date}
              </p>
            </div> */}
            <div style={{ paddingTop: "67px" }}>
              <p className="gloabalFont title4 f16">{title}</p>
              <br />
              <hr
                className="default_hrLine"
                style={{
                  width: "-webkit-fill-available",
                  height: "0.5px",
                  margin: "0px",
                  border: "none",
                  opacity: "0.6",
                }}
              />

              {title1 && (
                <div
                  className="pdfnewTabContainer"
                  // style={{ width: customWidth ? "100%" : "98%" }}
                  style={{ width: "100%", marginTop: "17px" }}
                >
                  <div className={`pdfnewTabItem ${"pdfselectedTabItem"}`}>
                    <p className={`pdfnewTabItemTxt ${"pdfselectedTxt"}`}>
                      {title1}
                    </p>
                  </div>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: title1 ? "26px" : "46px",
                  alignItems: "flex-end",
                }}
              >
                {/* <p className="gloabalFont titl6">{title2}</p> */}
                <p className="gloabalFont titl7" style={{ color: "#919191" }}>
                  *all values are in ₹ Absolute
                </p>
              </div>
              <br />
              {/* <hr
                className="default_hrLine"
                style={{
                  width: "-webkit-fill-available",
                  height: "0.5px",
                  margin: "0px",
                  border: "none",
                  opacity: " 0.6",
                  marginTop: title1 ? "26px" : "0px",
                }}
              /> */}
              {/* {subtext && <p className="gloabalFont subtext2">{subtext}</p>} */}
            </div>
          </div>
        );
      case 9:
        return (
          <div style={{ paddingBottom: "47px" }}>
            {/* <div
              style={{
                width: "-webkit-fill-available",
                left: 0,
                position: "absolute",
                paddingRight: "65px",
              }}
              className="holdingTopPdfSec1"
            >
              <p className="holdingTopPdfSec1Txt">{date}</p>
            </div> */}
            <div style={{ paddingTop: "67px" }}>
              {/* <p className="gloabalFont title4">{title}</p>
                <br />
                <hr
                  className="default_hrLine"
                  style={{
                    width: "-webkit-fill-available",
                    height: "0.5px",
                    margin: "0px",
                    border: "none",
                    opacity: "0.6",
                  }}
                /> */}

              {title1 && (
                <div
                  className="pdfnewTabContainer"
                  // style={{ width: customWidth ? "100%" : "98%" }}
                  style={{ width: "100%", marginTop: "17px" }}
                >
                  <div className={`pdfnewTabItem ${"pdfselectedTabItem"}`}>
                    <p className={`pdfnewTabItemTxt ${"pdfselectedTxt"}`}>
                      {title1}
                    </p>
                  </div>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: title1 ? "26px" : "0px",
                  alignItems: "flex-end",
                }}
              >
                <p className="gloabalFont titl6 f16">{title}</p>

                {/* <p className="gloabalFont titl7" style={{ color: "#919191" }}>
                  *all values are in ₹ Absolute
                </p> */}
              </div>
              <br />

              {/* {subtext && <p className="gloabalFont subtext2">{subtext}</p>} */}
            </div>
          </div>
        );
      case 10:
        return (
          <div style={{ paddingBottom: "47px" }}>
            <div style={{ paddingTop: "0px" }}>
              {title1 && (
                <div
                  className="pdfnewTabContainer"
                  // style={{ width: customWidth ? "100%" : "98%" }}
                  style={{ width: "100%", marginTop: "17px" }}
                >
                  <div className={`pdfnewTabItem ${"pdfselectedTabItem"}`}>
                    <p className={`pdfnewTabItemTxt ${"pdfselectedTxt"}`}>
                      {title1}
                    </p>
                  </div>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: title1 ? "26px" : "46px",
                  alignItems: "flex-end",
                }}
              >
                <p className="gloabalFont title4 f16">{title}</p>

                <p
                  className="gloabalFont titl7"
                  style={{ color: "black", opacity: "43%" }}
                >
                  {greyText ? greyText : "*all values are in ₹ Absolute"}
                </p>
              </div>
              <br />

              {/* {subtext && <p className="gloabalFont subtext2">{subtext}</p>} */}
            </div>
          </div>
        );
      case 11:
        return (
          <div style={{ paddingTop: "67px" }}>
            <div>
              <Breadcrumbs
                separator="›"
                aria-label="breadcrumb"
                className="printBread"
              >
                {breadcrumbs}
              </Breadcrumbs>
            </div>
            <div
              style={{
                paddingTop: "0px",
                display: "flex",
                borderBottom: "1px solid #A29FA6",
              }}
            >
              {}
              {tabs &&
                tabs?.map((t) => {
                  return (
                    <div
                      className="pdfnewTabContainer"
                      // style={{ width: customWidth ? "100%" : "98%" }}
                      style={{
                        width: "fit-content",
                        marginTop: "17px",
                        marginRight: "26px",
                      }}
                    >
                      <div
                        className={`pdfnewTabItem ${
                          t == tab ? "pdfselectedTabItem" : "pdfdeselectedTxt"
                        }`}
                      >
                        <p
                          className={`pdfnewTabItemTxt ${
                            t == tab ? "pdfselectedTxt" : ""
                          }`}
                        >
                          {t}
                        </p>
                      </div>
                    </div>
                  );
                })}

              <br />
            </div>
          </div>
        );
      case 12:
        return (
          <div style={{ paddingBottom: "47px" }}>
            <div style={{ paddingTop: "67px" }}>
              {title1 && (
                <div
                  className="pdfnewTabContainer"
                  // style={{ width: customWidth ? "100%" : "98%" }}
                  style={{ width: "100%", marginTop: "17px" }}
                >
                  <div className={`pdfnewTabItem ${"pdfselectedTabItem"}`}>
                    <p className={`pdfnewTabItemTxt ${"pdfselectedTxt"}`}>
                      {title1}
                    </p>
                  </div>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: title1 ? "26px" : "46px",
                  alignItems: "flex-end",
                }}
              >
                <p className="gloabalFont title4 f16">{title}</p>

                <div
                  className="boldText2 pdfCaps"
                  style={{ lineHeight: "0px" }}
                >
                  {denomination && denomination.toLowerCase() != "actuals"
                    ? "( ₹ in " + denomination + ")"
                    : ""}
                </div>
                {/* <p className="gloabalFont titl7" style={{ color: "#919191" }}>
                    *all values are in ₹ Absolute
                  </p> */}

                {/* <p
                  className="gloabalFont titl7"
                  style={{ color: "black", opacity: "43%" }}
                >
                  {greyText ? greyText : "*all values are in ₹ Absolute"}
                </p> */}
              </div>
              <br />

              {/* {subtext && <p className="gloabalFont subtext2">{subtext}</p>} */}
            </div>
          </div>
        );

      case 13:
        return (
          <div style={{ paddingTop: "67px", ...titleStyle }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Breadcrumbs
                separator="›"
                aria-label="breadcrumb"
                className="printBread"
              >
                {breadcrumbs}
              </Breadcrumbs>
              <div className="boldText2 pdfCaps" style={{ lineHeight: "0px" }}>
                {financialYear ? (
                  <>
                    {" "}
                    <span className="f14" style={{ letterSpacing: "2px" }}>
                      {" "}
                      FINANCIAL YEAR :
                    </span>
                    <span className="fAvenirBold" style={{ fontWeight: "600" }}>
                      {moment(financialYear, "DD-MMM-YYYY")
                        .subtract(1, "year")
                        .format("YYYY") +
                        " - " +
                        moment(financialYear, "DD-MMM-YYYY").format("YYYY")}
                    </span>
                  </>
                ) : (
                  ""
                )}
                {denomination && denomination.toLowerCase() != "actuals"
                  ? "( ₹ in " + denomination + ")"
                  : ""}
              </div>
            </div>
            <div
              style={{
                paddingTop: "0px",
                display: "flex",
                width: "100%",
                borderBottom: hideHr ? "" : "1px solid #A29FA6",
              }}
            >
              <br />
            </div>
            <br />
            {title2 && (
              <Typography
                variant="h6"
                component="h3"
                style={{ fontSize: "1.20 rem" }}
              >
                <span className="pdfdeselectedTxt">Member : </span> {title2}
              </Typography>
            )}
            <br />
          </div>
        );

      case 14:
        return (
          <div style={{ paddingBottom: "47px" }}>
            <div style={{ paddingTop: "67px" }}>
              {title1 && (
                <div
                  className="pdfnewTabContainer"
                  // style={{ width: customWidth ? "100%" : "98%" }}
                  style={{ width: "100%", marginTop: "17px" }}
                >
                  <div className={`pdfnewTabItem ${"pdfselectedTabItem"}`}>
                    <p className={`pdfnewTabItemTxt ${"pdfselectedTxt"} f16`}>
                      {title1}
                    </p>
                  </div>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: title1 ? "26px" : "0px",
                  alignItems: "flex-end",
                }}
              >
                <p className="gloabalFont titl6">{title}</p>
                <div
                  className="boldText2 pdfCaps"
                  style={{ lineHeight: "0px" }}
                >
                  {denomination && denomination.toLowerCase() != "actuals"
                    ? "( ₹ in " + denomination + ")"
                    : ""}
                </div>
                {/* <p className="gloabalFont titl7" style={{ color: "#919191" }}>
                    *all values are in ₹ Absolute
                  </p> */}
              </div>
              <br />
              <hr
                className="default_hrLine"
                style={{
                  width: "-webkit-fill-available",
                  height: "0.5px",
                  margin: "0px",
                  border: "none",
                  opacity: "0.6",
                  display: hideHr ? "none" : "",
                }}
              />
              {/* {subtext && <p className="gloabalFont subtext2">{subtext}</p>} */}
            </div>
          </div>
        );
      case 15:
        return (
          <div style={{ paddingTop: "67px", ...titleStyle }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Breadcrumbs
                separator="›"
                aria-label="breadcrumb"
                className="printBread"
              >
                {breadcrumbs}
              </Breadcrumbs>
              <div className="boldText2 pdfCaps" style={{ lineHeight: "0px" }}>
                {financialYear ? (
                  <>
                    {" "}
                    <span style={{ letterSpacing: "2px", fontSize: "14px" }}>
                      {" "}
                      FINANCIAL YEAR :
                    </span>
                    <span
                      className="fAvenirBold"
                      style={{ fontWeight: "600", fontSize: "12px" }}
                    >
                      {moment(financialYear, "DD-MMM-YYYY")
                        .subtract(1, "year")
                        .format("YYYY") +
                        " - " +
                        moment(financialYear, "DD-MMM-YYYY").format("YYYY")}
                    </span>
                  </>
                ) : (
                  ""
                )}
                {denomination && denomination.toLowerCase() != "actuals"
                  ? "( ₹ in " + denomination + ")"
                  : ""}
              </div>
            </div>
            <div
              style={{
                paddingTop: "0px",
                display: "flex",
                width: "100%",
                borderBottom: hideHr ? "" : "1px solid #A29FA6",
              }}
            >
              <br />
            </div>
            <br />
            {title2 && (
              <Typography
                variant="h6"
                component="h3"
                style={{ fontSize: "1.20 rem" }}
              >
                <span className="pdfdeselectedTxt">Member : </span> {title2}
              </Typography>
            )}
            <br />
          </div>
        );

      default:
        return (
          <div style={{ paddingTop: "67px", paddingBottom: "47px" }}>
            <div className="p_flex">
              <Typography variant="h4" component="h1">
                {title}
              </Typography>

              <div
                className="s_text p_flex"
                style={{
                  fontSize: "18px",
                  alignItems: "flex-end",
                  paddingBottom: "4px",
                  paddingLeft: "12px",
                }}
              >
                As On 31st August 2022
              </div>
            </div>
          </div>
        );
    }
  }

  return <div>{layout(type)}</div>;
}
