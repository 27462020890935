import moment from "moment";
import { routes } from "../../../../../routes";
import { roundNumber } from "../../../../utils/common";
import VisibiltyIcon from "../VisibiltyIcon";

export const getStrLength = (str) => {
  if (String(str)) return String(str).length;
  else return 0;
};

export const getListFromStr = (str, separator) => {
  if (String(str)) return String(str).split(separator ? separator : ",");
  return "wrong type";
};

const handleFirstSpace = (str) => {
  if (String(str).indexOf(" ") == 0) {
    let a = str?.slice(0, 1);
    console.log(str, "ldbjgfksjbgj");
    // str?.slice;
  }
  return str;
};

export const convertToListObj = (list, key) => {
  if (list?.length > 0)
    return list?.map((val) => ({ [key]: handleFirstSpace(val) }));
  return [];
};

export const strategyBuilderTopNavData = [
  {
    page: "target-portfolio",
    title: "Target Portfolio",
    path: routes?.strategyBuilderTargetPortfolio,
  },
  {
    page: "unique-cLient-index",
    title: "Unique CLient Index",
    path: routes?.strategyBuilderUniqueCLientIndex,
  },
  {
    page: "benchmark",
    title: "Benchmark",
    path: routes?.strategyBuilderBenchmark,
  },
];

export const objectFomatingStep3table = (
  data,
  tableHeader,
  index,
  columnClickConfig
) => {
  let newData = tableHeader.map((header) => {
    if (header?.field == "expectedReturns") {
      return {
        ...header,
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`])) + "%"
          : data[`${header.field}`] || "--",
      };
    }

    if (header?.field == "allocationPercentage") {
      // console.log(data, "asfljafbkj");
      return {
        ...header,
        onChange: columnClickConfig?.[header?.field]
          ? (e) => {
              columnClickConfig?.[header?.field](e, index);
            }
          : () => {},
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`])) + "%"
          : data[`${header.field}`] || "--",
        title: header.title,
        show: header.show,
      };
    }

    if (header?.field == "benchmarkIndex") {
      // console.log(data, "asfljafbkj");
      return {
        ...header,
        onClick: columnClickConfig?.[header?.field]
          ? (e) => {
              columnClickConfig?.[header?.field](e, data, index);
            }
          : () => {},
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`]))
          : data[`${header.field}`]?.name || "--",
        title: header.title,
        show: header.show,
      };
    }

    return {
      ...header,
      field: !isNaN(data[`${header.field}`])
        ? roundNumber(Number(data[`${header.field}`]))
        : data[`${header.field}`] || "--",
      title: header.title,
      show: header.show,
    };
  });
  return newData;
};

export const objectFormater = (row, headerData, columnClickConfig) => {
  let newCOl = headerData?.map((col) => {
    if (col?.field == "members") {
      return {
        ...col,
        field: row[`${col?.field}`] ? row[`${col?.field}`].join(", ") : "--",
        onClick:
          getStrLength(row[`${col?.field}`]) > 31
            ? columnClickConfig?.[col?.field]
              ? (e) => {
                  columnClickConfig?.[col?.field](e, row);
                }
              : () => {}
            : () => {},
        dontShow: row?.dontShow,
        customCellClassName: "customCell2 customCell5",
        customFontClassName:
          getStrLength(row[`${col?.field}`]) > 31 ? "customCell3" : "",
        customIcon:
          getStrLength(row[`${col?.field}`]) > 31 ? <VisibiltyIcon /> : "",
      };
    }

    if (col?.field == "created_at") {
      return {
        ...col,
        onClick: columnClickConfig?.[col?.field]
          ? (e) => {
              columnClickConfig?.[col?.field](e, row);
            }
          : () => {},
        field: row[`${col?.field}`]
          ? moment(row[`${col?.field}`]).format("DD/MM/YYYY")
          : "--",
        customCellClassName: "customCell2",
      };
    }

    return {
      ...col,
      field: row[`${col?.field}`] ? row[`${col?.field}`] : "--",
      dontShow: row?.dontShow,
      customCellClassName: "customCell2",
      dontShowFirstColBorder: true,
    };
  });
  return newCOl;
};

export const handlePayloadCreateStrategy = ({
  allocationData,
  portFolioName,
  memberNames,
}) => {
  let newList = [];
  let error = {};
  let total = 0;

  allocationData?.map((val) => {
    total = total + parseFloat(val?.allocationPercentage);
    if (val?.benchmarkIndex?.name) {
      newList.push({
        allocationPercentage: parseFloat(val?.allocationPercentage),
        assetClass: val?.assetClass?.toLowerCase(),
        benchmarkIndex: val?.benchmarkIndex,
      });
    } else {
      error.benchMarkMissing = "Please Select Bench Mark";
    }
  });

  total = roundNumber(total);

  if (total > 100 || total < 100) {
    error.totalAllocation = "Please Make Sure Total Allocation Is 100%";
    error.total = total;
  }

  if (!portFolioName) {
    error.name = "Name Required";
  }

  if (memberNames?.length == 0 || !memberNames) {
    error.members = "Please Select Members";
  }

  let payload = {
    memberIds: memberNames?.map((val) => val?.member_id),
    allocationsBreakUp: newList,
    name: portFolioName,
  };

  return { payload, error };
};

export const handleOnLoadData = ({ allocationData, staticData, type }) => {
  let newList = [];

  allocationData?.map((val) => {
    if (
      staticData?.findIndex((val2) => val2?.assetClass == val?.assetClass) !== -1
    ) {
      // console.log("afkjalfahfjk");
      if (type == "UniqueClientIndex") {
        newList.push({
          ...staticData[
            staticData?.findIndex((val2) => val2?.assetClass == val?.assetClass)
          ],
          assetClass: val?.assetClass,
          allocationPercentage: val?.percentageOfTotalPortfolio,
        });
      } else {
        newList.push({
          ...staticData[
            staticData?.findIndex((val2) => val2?.assetClass == val?.assetClass)
          ],
          assetClass: val?.assetClass,
        });
      }
    } else {
      if (type == "UniqueClientIndex") {
        newList.push({
          assetClass: val?.assetClass,
          allocationPercentage: val?.percentageOfTotalPortfolio,
          benchmarkIndex: "--",
        });
      } else {
        newList.push({
          assetClass: val?.assetClass,
          benchmarkIndex: "--",
        });
      }
    }
  });

  console.log(newList, "aglknagj");

  return newList;
};
