import React, { useContext, useState } from "react";
import PageFilter from "../common/PageFilter";
import { ReactComponent as Back } from "../../../../assets/holding-summary/back-blue.svg";
import { useNavigate } from "react-router-dom";
import TabComponent from "../common/TabComponent";
import { OnboardingContext } from "../../../../Context";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import PmsHolding from "../pms/PmsHolding";
import PmsTransaction from "../pms/PmsTransaction";
import PmsPortfolio from "../pms/PmsPortfolio";
import { getTransactionsSummary } from "../../../../api";
import {
  holdingTableConfig,
  transactionTableConfig,
  trSTData,
} from "./utils/constands";
import { objectFormating1 } from "../../../utils/objectFormating";
import NewTabComp from "../common/NewTabComp";
import { routes } from "../../../../routes";
import { NewFilter } from "../../newFilter/NewFilter";
import MLDHolding from "../mld/MLDHolding";
import MLDTransaction from "../mld/MLDTransaction";

const data = [
  {
    page: "holding",
    title: "Holding Summary",
    path: routes?.structuredProdHolding,
  },
  // { page: "portfolio", title: "Performance Summary" },
  {
    page: "transaction",
    title: "Transaction Summary",
    path: routes?.structuredProdTransaction,
  },
];

function StructeredProductReport({ pageName }) {
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const [page, setPage] = useState(pageName);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const { userState } = OnboardingContext();
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const getTabelData = async (
    setTableColumns,
    tableName,
    params,
    setLoading,
    objectFormating,
    setCheckedTrans,
    tableHeader,
    limit,
    offset,
    setPageinationData,
    pagiNationData
  ) => {
    setLoading(true);
    // static params for testing purpose
    // params =
    //   page === "holding"
    //     ? {
    //         clientId: 199,
    //         assetClass: params.assetClass,
    //       }
    //     : {
    //         clientId: 199,
    //         memberId: 44,
    //         startDate: "2021-04-10",
    //         endDate: "2022-10-09",
    //         assetClass: params.assetClass,
    //       };
    params = {
      clientId: userState.loginedUserData.id,
      search: params["search"] || "",
      // clientId: 1,
      ...getfilter_as_params(),
      //assetClass: params.assetClass,
      limit: limit,
      offset: offset,
    };

    try {
      let response = await getTransactionsSummary(
        "structured",
        tableName,
        params
      );
      console.log("Structured Product Afd", response?.data?.data?.meta?.limit);
      setPageinationData((prev) => {
        return { ...prev, pageCount: response?.data?.data?.meta?.count };
      });
      let newlist = [];
      let transactionList = [];
      response?.data?.data?.result?.map((val) => {
        if (page === "holding") {
          let obj = objectFormating1(val, tableHeader);
          newlist.push(obj);
        } else {
          val.advisor = val?.advisor + "%" + val?.updated_at;
          let obj = objectFormating1(val, tableHeader);
          newlist.push(obj);
          if (
            val["transaction_type"] != null &&
            !transactionList
              .map((val) => val?.title)
              .includes(val["transaction_type"])
          ) {
            transactionList.push({
              title: val["transaction_type"],
              show: false,
            });
          }
        }
      });

      if (page !== "holding") {
        setCheckedTrans(transactionList);
      }
      setTableColumns(newlist);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const changePage = (p) => {
    switch (p) {
      case "holding":
        return (
          <MLDHolding
            typeOfProduct={"structured"}
            hideSubTab={true}
            customTableConfig={holdingTableConfig}
            getTabelData={getTabelData}
            subHeading={"Structured Products"}
            headerInTwoLine={true}
          ></MLDHolding>
        );

      case "transaction":
        return (
          <MLDTransaction
            hideSubTab={true}
            getTabelData={getTabelData}
            customTableConfig={transactionTableConfig}
            subHeading={"Structured Products"}
            headerInTwoLine={true}
          />
        );

      default:
        return (
          <MLDHolding
            typeOfProduct={"structured"}
            hideSubTab={true}
            customTableConfig={holdingTableConfig}
            getTabelData={getTabelData}
            subHeading={"Structured Products"}
            headerInTwoLine={true}
          ></MLDHolding>
        );
    }
  };

  const handleClick = React.useCallback(
    (page) => {
      setPage(page);
    },
    [page]
  );

  return (
    <>
      <PageFilter
        backLink={routes?.structuredProdSummaryPage}
        setFilterModalOpen={setFilterModalOpen}
      ></PageFilter>
      <NewFilter
        filterModalOpen={filterModalOpen}
        setFilterModalOpen={setFilterModalOpen}
        productType="structured"
      />
      <div className="backBtnContainer" style={{ margin: "2%" }}>
        <NewTabComp
          data={data}
          customWidth={"100%"}
          customHandle={handleClick}
          customSelect={data.findIndex((val) => val?.page == page)}
        />
      </div>
      {changePage(page)}
    </>
  );
}

export default StructeredProductReport;
