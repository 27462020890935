import React from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import "./visibilityIcon.css";

function VisibiltyIcon({ customId, customOnClick }) {
  return (
    // <div
    //   onClick={customOnClick}
    //   aria-describedby={customId}
    //   className="visibilityContainer"
    // >
      <VisibilityIcon className="visibilityIcon" />
    // </div>
  );
}

export default VisibiltyIcon;
