import React, { useLayoutEffect, useRef, useEffect, useMemo } from "react";
import { OrgChart, Layout } from "d3-org-chart";
import Default from "../dashboard/defaultPages/Default";
import FamilySidebar from "./FamilySidebar";
import * as d3 from "d3";
import { useState } from "react";
import FamilyModal from "./FamilyModal";
import ReactDOMServer from "react-dom/server";
import {
  fetch_all_client_members,
  fetch_family_hierarchy,
  addNewChildToHeirarchy,
  getLiaisonOfficer,
} from "../../api";
import { OnboardingContext } from "../../Context";
import DataNotFound from "../dashboard/products/common/DataNotFound";
import { PermissionsGate } from "../auth/PermissionGate";
// import { SCOPES } from "../auth/permission-maps";
import ls from "localstorage-slim";
import { staticFamilyTreeData } from "./utils/constands";

export const ORGD3Chart = (props, ref) => {
  // const classes = useStyles();
  const { userState } = OnboardingContext();
  const [open, setOpen] = useState(false);
  const [id, setId] = useState();
  const [nickName, setNickName] = useState("");
  const d3Container = useRef(null);
  const buttonRef = useRef(null);
  let chart = null;
  const [colors, setColors] = useState([
    { color: "red", nodeId: "" },
    { color: "green", nodeId: "" },
    { color: "#509FF1", nodeId: "" },
  ]);
  const [selectedOption, setSelectedOption] = useState(0);
  const [parent, setParent] = useState([]);
  const [child, setChild] = useState([
    { full_name: "--Select--", member_id: 0 },
  ]);
  const [relation, setRelation] = useState("");
  const [node, setNode] = useState([]);
  const [childData, setChildData] = useState();
  const [selectedNode, setSelectedNode] = useState();
  const [liaison, setLiaison] = useState([]);
  const [error, setError] = useState({ status: false, message: "" });
  const scopes = JSON.parse(ls.get("scopes"));

  const [data, setData] = useState([]);

  useEffect(() => {
    let clientId = userState.loginedUserData.id;
    if (localStorage.getItem("memberId") !== null) {
      // setting clientId to memberId if clicked memberName from /client/members
      clientId = localStorage.getItem("memberId");
    }
    // userState.loginedUserData.id
    const isHierarchy = 1;
    const status = "approved";

    fetch_family_hierarchy(clientId).then((res) => {
      // console.log(res.result, "akjfu");

      let newList = res.result?.map((val) => {
        if (val?.parent_member_id == null) {
          hanldeHighlight(val?.member_id);
        }
        return {
          nickName: val?.nickname,
          nodeId: val?.member_id,
          parentNodeId: val?.parent_member_id,
          _upToTheRootHighlighted: false,
          _expanded: true,
          full_name: val?.clientMember?.full_name,
        };
      });

      // # for testing static data # //

      // let newList = staticFamilyTreeData?.data.result?.map((val) => {
      //   if (val?.parent_member_id == null) {
      //     hanldeHighlight(val?.member_id);
      //   }
      //   return {
      //     nickName: val?.nickname,
      //     nodeId: val?.member_id,
      //     parentNodeId: val?.parent_member_id,
      //     _upToTheRootHighlighted: false,
      //     _expanded: true,
      //     full_name: val?.clientMember?.full_name,
      //   };
      // });

      setData(newList);
      return setParent([...res.result]);
    });

    fetch_all_client_members(clientId, isHierarchy, status).then((res) => {
      return setChild((prev) => [...prev, ...res.result]);
    });

    getLiaisonOfficer(clientId).then((res) => {
      return setLiaison([res.result]);
    });
  }, []);

  const handleClick = () => {
    let { client_id, member_id, full_name } = child?.find(
      (val) => val?.member_id == selectedOption
    );

    // console.log(nickName, "njnkj");

    // setData((prev) => {
    //   return [
    //     ...prev,
    //     {
    //       nickName: nickName,
    //       membnodeIder_id: member_id,
    //       parentNodeId: Number(id),
    //       full_name: full_name,
    //       _upToTheRootHighlighted: false,
    //       _expanded: true,
    //     },
    //   ];
    // });
    addNewChild({
      nickname: nickName,
      client_id: client_id,
      member_id: member_id,
      parent_member_id: Number(id),
      full_name: full_name,
      depth: 1,
      branch: "L",
    });
  };

  const addNewChild = async (data) => {
    try {
      let res = await addNewChildToHeirarchy(data);
      // console.log(res, "resfjkkk");
      setData((prev) => {
        return [
          ...prev,
          {
            nickName: data?.nickname,
            nodeId: data?.member_id,
            parentNodeId: data?.parent_member_id,
            full_name: data?.full_name,
            _upToTheRootHighlighted: false,
            _expanded: true,
          },
        ];
      });
      setNickName("");
      setOpen(!open);
    } catch (error) {
      // console.log(error);
      setOpen(false);
    }
  };

  const handleModal = (d) => {
    setOpen(true);
    setId(d);
  };
  const boxStyle = {
    borderRadius: 5,
    background: " #1C1B39",
    color: "white",
    textAlign: "center",
    borderRadius: "14.276px",
  };

  const hanldeHighlight = (nodeId, state) => {
    // console.log("abc", nodeId);
    let currentNode;
    currentNode = data.find((node) => node.nodeId == nodeId);
    // console.log("CurrentNode:", currentNode)
    setId(nodeId);

    setData((prev) => {
      let newList = prev.map((node) => {
        if (node.nodeId == nodeId) {
          node._upToTheRootHighlighted = !node._upToTheRootHighlighted;
        }
        return node;
      });
      return [...newList];
    });

    if (currentNode?.parentNodeId) {
      hanldeHighlight(currentNode.parentNodeId);
    }

    setNode((c) => {
      let marr = [nodeId, ...c];
      marr[0] = parseInt(marr[0]);
      return marr;
    });
  };

  //////////////////////////////////////////////

  function findSubChildren(nodeId, data) {
    const subChildren = [];
    data.forEach((item) => {
      if (item.parentNodeId === nodeId) {
        subChildren.push(item.nodeId);
        subChildren.push(...findSubChildren(item.nodeId, data));
      }
    });
    return subChildren;
  }
  const subChildren = findSubChildren(node[0], data);

  const SelectedNodes = [node[0], ...subChildren];

  const associatedData = useMemo(() => {
    return data
      .filter((item) => SelectedNodes.includes(item.nodeId))
      .map((item) => ({ item }));
  }, [data]);

  const parentData = useMemo(() => {
    return data
      .filter((item) => node.includes(item.nodeId))
      .map((item) => ({ item }));
  }, [data]);

  /////////////////////////////////////////////////
  const coloredLinkChild = (data, col) => {
    return data.map((val) => {
      val.color = col;
      return val;
    });
  };

  const manageColoredLine = (arr, index) => {
    let list = arr.map((data1, index) => {
      // console.log(data1.__data__, "lbabuofw");
      let data = data1.__data__;
      if (data.depth == 1 && !data.data.color) {
        data.data.color = colors[index].color;

        setColors((prev) => {
          prev[index].nodeId = data.data.nodeId;
          return prev;
        });

        if (data?.children) {
          data.children = coloredLinkChild(data?.children, data.data.color);
        }
        return data;
      } else {
        return data;
      }
    });

    // console.log(list, "akfhouiw");

    return list;

    // if (data.depth == 1 && !data.data.color) {
    //   data.data.color = colors[index].color;
    //   console.log(data.data.color, "oabfiuawe");

    //   setColors((prev) => {
    //     prev[index].nodeId = data.data.nodeId;
    //     return prev;
    //   });

    //   if (data?.children) {
    //     data.children = coloredLinkChild(data?.children, data.data.color);
    //   }
    //   console.log(data, "lbabuofw");
    //   return data;
    // }else{

    // }
  };

  // console.log(colors, "afagw");

  const ClickOnNode = (value) => {
    // console.log(value);
    // console.log("Clicked On Node")
  };

  // We need to manipulate DOM
  useLayoutEffect(() => {
    if (data && d3Container.current) {
      if (!chart) {
        chart = new OrgChart();
      }
      try {
        chart
          .container(d3Container.current)
          .data(data)
          .nodeWidth((d) => (!d.data.parentNodeId ? 269 : 200))
          .nodeHeight((d) => 120)
          .childrenMargin((d) => 120)
          .compactMarginBetween((d) => 85)
          .compactMarginPair((d) => 80)
          .compact(false) // used for make all children align in same row with respect to its depth.
          .linkUpdate(function (d, i, arr) {
            // console.log(d.data, "abofabwfw");
            // customization of dashed lines.
            d3.select(this)
              .attr("stroke", (d) =>
                d.data._upToTheRootHighlighted
                  ? "yellow"
                  : d?.data?.color
                  ? d?.data?.color
                  : "#AA6FFE"
              )
              .attr("stroke-width", (d) =>
                d.data._upToTheRootHighlighted ? 3 : 2
              )
              .attr("stroke-dasharray", "8")
              .attr("stroke-dashoffset", "100");

            if (d.data._upToTheRootHighlighted) {
              d3.select(this).raise();
            }
          })
          .onNodeClick((d, i, arr) => {
            return null;
          })
          .buttonContent(({ node13123, state }) => {
            // function to remove stroke getting added on highlight
            d3.selectAll(".node-rect").style("stroke", "none");

            // function to remove onclick expand hidden button
            d3.selectAll(".node-button-g")
              .on("click", function (e, d) {
                e.preventDefault();
              })
              .style("display", "none");

            // function to triger highlight onclick on card

            d3.selectAll(".name6879").on("click", function (e, d) {
              setNode([]);
              setData((prev) => {
                let newList = prev.map((node) => {
                  // if (node.nodeId == this?.id) {
                  node._upToTheRootHighlighted = false;
                  // }
                  return node;
                });
                return [...newList];
              });
              hanldeHighlight(this?.id, state);
            });

            // function to open modal on click on plus button
            d3.selectAll(".btn234213")
              .on("click", function (e, d) {
                e.preventDefault();
                handleModal(this?.name);
              })
              .style("cursor", "pointer");

            return null;
          })
          .nodeContent(function (d, i, arr, state) {
            const color = "#FFFFFF";

            if (!d?.data?.parentNodeId) {
              return ReactDOMServer.renderToStaticMarkup(
                <div
                  id="1"
                  style={{
                    width: `${d.width}px`,
                    height: `${d.height}px`,
                    position: "absolute",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    ...boxStyle,
                    background: "#1C1B39",
                  }}
                  onClick={ClickOnNode(d)}
                >
                  <div
                    name={`${d?.data?.nodeId}`}
                    id={`${d?.data?.nodeId}`}
                    className="name6879"
                    style={{
                      padding: "18px",
                      height: "70%",
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        background: "#302F4D",
                        borderRadius: "58.8507px",
                        padding: "5px 11px",
                        margin: "10px 0px",
                        position: "absolute",
                        top: "-21px",
                        left: "33%",
                      }}
                    >
                      Family Head
                    </div>
                    <div
                      className="familyInnerCard"
                      style={{ justifyContent: "center" }}
                    >
                      <div
                        className="familyCardPic"
                        style={{
                          border: "none",
                          height: "51px",
                          width: "51px",
                        }}
                      >
                        <h2
                          className="familyCardName1"
                          style={{ marginTop: "1px", fontSize: "23px" }}
                        >
                          {d?.data?.full_name?.split(" ")[0]
                            ? d?.data?.full_name
                                ?.split(" ")[0][0]
                                ?.toUpperCase()
                            : ""}
                          {d?.data?.full_name?.split(" ")[1]
                            ? d?.data?.full_name
                                ?.split(" ")[1][0]
                                ?.toUpperCase()
                            : ""}
                        </h2>
                      </div>

                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <p className="familyCardName" style={{ width: "auto" }}>
                          {d?.data?.full_name}
                        </p>
                      </div>
                    </div>
                  </div>
                  <PermissionsGate scopes={[scopes.can_create_family_tree]}>
                    <div id={`${d?.data?.nodeId}`} className="familyTreeBtn">
                      <button
                        ref={buttonRef}
                        name={`${d?.data?.nodeId}`}
                        id={`${d?.data?.nodeId}`}
                        className="btn234213"
                      >
                        +
                      </button>
                    </div>
                  </PermissionsGate>
                </div>
              );
            }

            return ReactDOMServer.renderToStaticMarkup(
              <div
                id="1"
                style={{
                  width: `${d.width}px`,
                  height: `${d.height}px`,
                  position: "absolute",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  ...boxStyle,
                  background: "#1C1B39",
                }}
                onClick={ClickOnNode(d)}
              >
                <div
                  name={`${d?.data?.nodeId}`}
                  id={`${d?.data?.nodeId}`}
                  className="name6879"
                  style={{ padding: "18px", height: "70%" }}
                >
                  <div className="familyInnerCard">
                    <div className="familyCardPic">
                      <h2 className="familyCardName1">
                        {/* {d?.data?.full_name.split(" ")[0][0]?.toUpperCase()}
                        {d?.data?.full_name.split(" ")[1][0]?.toUpperCase()} */}
                        {d?.data?.full_name?.split(" ")[0]
                          ? d?.data?.full_name?.split(" ")[0][0]?.toUpperCase()
                          : ""}
                        {d?.data?.full_name?.split(" ")[1]
                          ? d?.data?.full_name?.split(" ")[1][0]?.toUpperCase()
                          : ""}
                      </h2>
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p className="familyCardName">{d?.data?.full_name}</p>
                      <div
                        style={{
                          background: "#302F4D",
                          borderRadius: "58.8507px",
                          padding: "5px",
                          margin: "10px 0px",
                        }}
                      >
                        {d?.data?.nickName}
                      </div>
                    </div>
                  </div>
                </div>
                <PermissionsGate scopes={[scopes.can_create_family_tree]}>
                  <div id={`${d?.data?.nodeId}`} className="familyTreeBtn">
                    <button
                      ref={buttonRef}
                      name={`${d?.data?.nodeId}`}
                      id={`${d?.data?.nodeId}`}
                      className="btn234213"
                    >
                      +
                    </button>
                  </div>
                </PermissionsGate>
                {/* <div className="familyTreeBtn">
                  <button onClick={() => handleModal(id)}>+</button>
                </div> */}
              </div>
            );
          })
          .initialZoom(0.9)
          .render();
      } catch (error) {
        if (error?.message == "multiple roots") {
          error.message =
            error?.message + " (One child Can't Have Multiple Parents)";
        }
        setError(() => {
          return {
            message: "Error : " + error?.message,
            status: true,
          };
        });
      }
    }
  }, [data, d3Container.current]);

  return (
    <>
      <Default>
        <div style={{ display: "flex" }}>
          <div style={{ width: "70%", height: "auto" }}>
            {error?.status && <DataNotFound text={error?.message} />}
            <div style={{ height: "100%" }} ref={d3Container} />
          </div>
          <div style={{ width: "20%" }}>
            <FamilySidebar
              key={data}
              associatedData={associatedData}
              parentData={parentData}
              SelectedNodes={SelectedNodes}
              hierarchyChild={child}
              liaison={liaison}
            ></FamilySidebar>
          </div>
        </div>
      </Default>

      <FamilyModal
        open={open}
        setOpen={setOpen}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        handleClick={handleClick}
        child={child}
        nickName={nickName}
        setNickName={setNickName}
      />
    </>
  );
};
