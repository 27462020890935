import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { ErrorCard } from "./ErrorCard";
import { productValidationDetailsDummyData } from "./productValidationDummyData";
import { useLocation } from "react-router-dom";
import { mapKeyToName, reorderErrorList } from "./uploadValidationUtils";
import { useEffect } from "react";
import { ReactComponent as ErrorAlert } from "../../assets/icons/errorAlert.svg";
function transformData(data) {
  const errorDescriptions = {
    isin: "Identify and report any invalid ISIN/Product Codes not present in the scheme master.",
    mandatory:
      "Verify that all mandatory fields are filled. If not, indicate which ISINs have missing data and specify the missing columns.",
    transaction_date: "Ensure transaction dates are not in the future.",
    duplicate_records:
      "Identify and report any invalid ISIN/Product Codes not present in the scheme master.",
    miscellaneous:
      "Identify and report any invalid ISIN/Product Codes not present in the scheme master.",
    transaction_number:
      "Identify and report any invalid Transaction Number not present in the scheme master.",
  };

  // Filter out the '_id' key and only process keys with array values
  return Object.keys(data)
    .filter((key) => key !== "_id" && Array.isArray(data[key]))
    .map((key) => ({
      errorName: key,
      errorDescription: errorDescriptions[key] || "No description available",
      data: data[key],
    }));
}

export const DisplayErrorCards = ({ setTableData }) => {
  const location = useLocation();
  const [selectedError, setSelectedError] = useState("");
  console.log("Display Error Card", location?.state?.data);
  const test = transformData(
    reorderErrorList(location?.state?.data?.error_list)
  );
  // Set the initial table data with the first non-empty error list item
  useEffect(() => {
    const firstNonEmptyError = test.find(
      (item) => item.data && item.data.length > 0
    );
    console.log("firstNonEmptyError", firstNonEmptyError);
    if (firstNonEmptyError) {
      setTableData(firstNonEmptyError);
      setSelectedError(firstNonEmptyError?.errorName);
    }
  }, []);
  console.log("test data is", test);

  return (
    <Grid
      container
      alignItems="center"
      className="centerContainer"
      style={{ paddingLeft: 0, paddingRight: 0, gap: "2.8rem" }}
    >
      {test?.map(
        (data, index) =>
          data?.data?.length > 0 && (
            <Grid
              item
              xs={2}
              key={index}
              style={{ cursor: "pointer" }}
              onClick={() => {
                console.log("adsfdfds", data);
                setTableData(data);
                setSelectedError(data?.errorName);
              }}
            >
              <ErrorCard
                style={{
                  background:
                    selectedError === data?.errorName &&
                    "linear-gradient(128deg, #8637FC -13.68%, #833CEE 61.27%, #6803FF 124.7%)",
                }}
                header={<ErrorAlert />}
                body={
                  <Typography className="mainHeading">
                    {mapKeyToName(data?.errorName)}
                  </Typography>
                }
                footer={
                  <p
                    className={
                      selectedError === data?.errorName
                        ? "errDescActiveTxt"
                        : "inactive"
                    }
                  >
                    {data?.errorDescription}
                  </p>
                }
              />
            </Grid>
          )
      )}
    </Grid>
  );
};
