import React, { useContext, useEffect, useState } from "react";
import NewHeadAndDetailsComp from "../newDesigns/common/newHeadingAndDetailsComp/NewHeadAndDetailsComp";
import {
  topPerformerPage,
  bottomPerformerPage,
  topPerformanceProductTable,
} from "./utils/constands";
import "./topPerformer.css";
import NewTopAndBottomButtons from "../../../common/NewTopAndBottomButtons";
import useGetPerformaceTable from "../../../../hooks/useGetPerformaceTable";
import PmsReportTable from "../pms/PmsReportTable";
import PageLoader from "../common/pageLoader";
import { topPerformanceTable as header, btnArray } from "./utils/constands";

import NewTabComp from "../common/NewTabComp";
import { useNonEmptyProductList } from "../../../../hooks/useNonEmptyProductList";
import { isArray } from "lodash";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
const tabData = [
  { page: "assetClass", title: "Asset Class" },

  {
    page: "products",
    title: "Products",
  },
];

function TopPerformers({ tableType, type }) {
  const [product, setProduct] = useState({
    asset: "all",
    product: "all",
    selected: "all",
  });
  const [btnarr, setBtnarr] = useState([]);
  const { getData, loading } = useGetPerformaceTable({
    tableType: tableType,

    performerType: tableType || "top",
    assetType: product.asset,
    productType: product.product,
  });
  const [tableData1, setTableData] = useState([]);
  const [tableHeader, setTableHeader] = useState(header);
  const { nonEmptyProductList } = useNonEmptyProductList({ dataType: type });
  const { mfoFilter } = useContext(mfoFilterContext);
  const [page, setPage] = useState();
  const handleTabChange = (page) => {
    setPage(page);
    if (page == "products") {
      setTableHeader(topPerformanceProductTable);
    } else {
      setTableHeader(header);
    }
    setProduct((prev) => {
      return {
        ...prev,
        asset: "all",
        product: "all",
        selected: "all",
      };
    });
  };
  useEffect(() => {
    if (page == "products") {
      let all = nonEmptyProductList?.find((val) => {
        if (val.all) {
          return true;
        }
      });
      isArray(nonEmptyProductList) &&
        !all &&
        nonEmptyProductList.unshift({
          all: "All",
        });
      setBtnarr(
        isArray(nonEmptyProductList)
          ? nonEmptyProductList?.map((item) => {
              const key = Object.keys(item)[0];
              const title = item[key];
              const specialConfig =
                key === "bank"
                  ? {
                      // transactionConfig: bankTransactionConfig,
                      // holdingConfig: bankHoldingConfig,
                    }
                  : {};

              return { page: key, title, key, ...specialConfig };
            })
          : []
      );
    } else {
      setBtnarr(btnArray);
    }
  }, [page]);
  const handleGetData = () => {
    getData({ tableHeader: tableHeader }).then((res) => {
      console.log("res", res);
      if (res?.length > 0) {
        setTableData(() => res);
      } else {
        setTableData(() => []);
      }
    });
  };

  const handleProductSelect = (e) => {
    console.log("product is selected");
    let prod = btnarr.find((val) => val?.title === e?.target?.innerText)?.page;

    if (page == "products") {
      setProduct((prev) => {
        return {
          ...prev,
          asset: null,
          product: prod,
          selected: prod,
        };
      });
    } else {
      setProduct((prev) => {
        return {
          ...prev,
          product: null,
          asset: btnarr.find((val) => val?.title === e?.target?.innerText).page,
          selected: prod,
        };
      });
    }
  };

  useEffect(() => {
    handleGetData();
  }, [tableType, product, page, mfoFilter]);

  return (
    <>
      <div className="topPerformerWrapper">
        <NewHeadAndDetailsComp
          title={
            tableType == "bottom"
              ? bottomPerformerPage?.title
              : topPerformerPage?.title
          }
          content={topPerformerPage?.content()}
        />
        <NewTabComp
          customWidth={"fit-content"}
          data={tabData}
          customHandle={handleTabChange}
        />
        <div style={{ height: "45px" }}></div>
        <NewTopAndBottomButtons
          selected={product}
          tabswitch={page}
          handleChange={handleProductSelect}
          btnArray={btnarr}
        />
      </div>
      <div style={{ position: "relative" }}>
        <PmsReportTable
          key={product}
          setTableColumns={setTableData}
          tableColumns={tableData1}
          loading={loading}
          headerList={tableHeader}
          tableHeader={tableHeader}
          setTableHeader={setTableHeader}
          stickyProductName={true}
          hidePagination={true}
          // customTableClass="topPerformance"
          tableHeaderBGColor={"rgb(14 8 32)"}
          rowSeperation={"12px"}
        />
        <PageLoader showLoader={loading} />
      </div>
    </>
  );
}

export default TopPerformers;
