import { ResponsiveBar } from "@nivo/bar";
import { theme } from "./nivo_objects";
import React, { useEffect } from "react";
import { linearGradientDef } from "@nivo/core";

export default function BarChart(props) {
  // config{
  //     height:"div height default 300px",
  //     groupMode:"default stacked",
  //     padding:"default 0.6"
  // }
  let wordlength = 0;
  props?.keys?.map((k) => {
    if (wordlength < k?.length) {
      wordlength = k?.length;
    }
  });

  const data = [
    {
      "gained > 100$": 17,
      "gained <= 100$": 32,
      "lost <= 100$": -41,
      "lost > 100$": -10,
      year: "2010",
    },
    {
      "gained > 100$": 17,
      "gained <= 100$": 32,
      "lost <= 100$": -41,
      "lost > 100$": -10,
      year: "2011",
    },
    {
      "gained > 100$": 17,
      "gained <= 100$": 32,
      "lost <= 100$": -41,
      "lost > 100$": -10,
      year: "2012",
    },
    {
      "gained > 100$": 17,
      "gained <= 100$": 32,
      "lost <= 100$": -41,
      "lost > 100$": -10,
      year: "2013",
    },
    {
      "gained > 100$": 17,
      "gained <= 100$": 32,
      "lost <= 100$": -41,
      "lost > 100$": -10,
      year: "2014",
    },
    {
      "gained > 100$": 2,
      "gained <= 100$": 90,
      "lost <= 100$": -8,
      "lost > 100$": 0,
      year: "2015",
    },
    {
      "gained > 100$": 18,
      "gained <= 100$": 45,
      "lost <= 100$": -28,
      "lost > 100$": -9,
      year: "2016",
    },
    {
      "gained > 100$": 1,
      "gained <= 100$": 10,
      "lost <= 100$": -77,
      "lost > 100$": -12,
      year: "2017",
    },
    {
      "gained > 100$": 27,
      "gained <= 100$": 67,
      "lost <= 100$": -4,
      "lost > 100$": -2,
      year: "2018",
    },
    {
      "gained > 100$": 12,
      "gained <= 100$": 75,
      "lost <= 100$": -9,
      "lost > 100$": -4,
      year: "2019",
    },
    {
      "gained > 100$": 1,
      "gained <= 100$": 15,
      "lost <= 100$": -72,
      "lost > 100$": -12,
      year: "2020",
    },
    {
      "gained > 100$": 2,
      "gained <= 100$": 40,
      "lost <= 100$": -35,
      "lost > 100$": -23,
      year: "2021",
    },
    {
      "gained > 100$": 13,
      "gained <= 100$": 19,
      "lost <= 100$": -55,
      "lost > 100$": -13,
      year: "2022",
    },
  ];

  let data1 = [
    {
      period: "3months",
      BB: 10,
    },
    {
      period: "1month",
      AA: 10,
    },
    {
      period: "2month",
      AA: 20,
    },

    {
      period: "3month",
      AA: 20,
    },
    {
      period: "4month",
      AA: 20,
    },
    {
      period: "5month",
      AA: 20,
    },
    {
      period: "6month",
      AA: 20,
    },
  ];
  return (
    <div
      style={{ height: props.config.height ? props.config.height : "600px" }}
    >
      <ResponsiveBar
        defs={[
          // using helpers
          // will inherit colors from current element
          linearGradientDef(
            "gradientA",
            [
              { offset: 0, color: "#009A50" },
              { offset: 100, color: "#15E5A7", opacity: 1 },

              {
                gradientTransform: "rotate(180 0.5 0.5)",
              },
            ],

            {
              x1: "0%",
              y1: "0%",
              x2: "100%",
              y2: "0%",
            }
          ),
          {
            id: "c1", //orange
            type: "linearGradient",
            colors: [
              { offset: 0, color: "#F7CB59" },
              { offset: 100, color: "#FF9446" },
            ],
          },
          {
            id: "c2", //green
            type: "linearGradient",
            colors: [
              { offset: 0, color: "#00C1CE" },
              { offset: 100, color: "#82EBA1" },
            ],
          },
          {
            id: "c3", //blue
            type: "linearGradient",
            colors: [
              { offset: 0, color: "#6BCDF7" },
              { offset: 100, color: "#509FF1" },
            ],
          },
          {
            id: "c4", //purple
            type: "linearGradient",
            colors: [
              { offset: 0, color: "#AA6FFE" },
              { offset: 100, color: "#711CF1" },
            ],
          },
          {
            id: "c5", //pink
            type: "linearGradient",
            colors: [
              { offset: 0, color: "#FE6587" },
              { offset: 100, color: "#F52D6A" },
            ],
          },
          {
            id: "c6", //red
            type: "linearGradient",
            colors: [
              { offset: 0, color: "#E96114" },
              { offset: 100, color: "#B80000" },
            ],
          },

          linearGradientDef(
            "gradientB",
            [
              { offset: 0, color: "#F57474" },
              { offset: 100, color: "#C10046", opacity: 1 },

              {
                gradientTransform: "rotate(180 0.5 0.5)",
              },
            ],

            {
              x1: "0%",
              y1: "0%",
              x2: "100%",
              y2: "0%",
            }
          ),
        ]}
        fill={
          props.fill
            ? props.fill
            : [
                { match: { id: "gain_percent" }, id: "gradientA" },
                { match: { id: "loss_percent" }, id: "gradientB" },
              ]
        }
        data={props.data ? props.data : data1}
        {...props.commonprops}
        layout={props.config?.layout ? props.config.layout : "vertical"}
        theme={props.config.theme ? props.config.theme : theme}
        legends={
          props.config.disabledLegend
            ? []
            : [
                {
                  anchor: "bottom",
                  direction: "row",
                  justify: false,
                  translateX: 18,
                  translateY: 70,
                  itemsSpacing: wordlength * 4.5 || 45,
                  itemDirection: "left-to-right",
                  itemWidth: 60,
                  itemHeight: 63,
                  itemOpacity: 0.75,
                  symbolSize: 9,
                  symbolShape: "circle",
                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemBackground: "rgba(0, 0, 0, .03)",
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]
        }
        tooltip={props.tooltip ? props.tooltip : () => {}}
        enableLabel={props.enableLabel ? true : false}
        keys={
          props.keys
            ? props.keys
            : ["gained > 100$", "gained <= 100$", "lost <= 100$", "lost > 100$"]
        }
        groupMode={props.config?.groupMode ? props.config.groupMode : "stacked"}
        padding={props.config?.padding ? props.config.padding : 0.6}
        colors={
          props.colors
            ? props.colors
            : ["#61cdbb", "#97e3d5", "#f47560", "#e25c3b"]
        }
        innerPadding={0}
      />
    </div>
  );
}
