export const prodAndKey = [
  {
    key: "equity",
    prod: "Equity",
  },
  {
    key: "alternate",
    prod: "Alternates",
  },
  {
    key: "debt",
    prod: "Debt",
  },
  {
    key: "cash",
    prod: "Cash",
  },
  {
    key: "total",
    prod: "Total",
  },
];

const getProd = (prod, configProd) => {
  return configProd?.filter((val) => val?.prod == prod)[0]?.key;
};

export const getColAndProd = (config, prod, prodAndKeyConfig) => {
  // console.log(
  //   prod,
  //   "afjhafjkabf",
  //   prodAndKeyConfig,
  //   getProd(prod, prodAndKeyConfig)
  // );
  return config[`${getProd(prod, prodAndKeyConfig)}`];
};
