import React, { useEffect, useState } from "react";
import PageContainer from "../PageContainer";
import Header from "../Components/Header";
import PrintTable from "../Components/Table";
import ApexNew_print from "../Components/ApexNew_print";
import chartData from "../../../charts/chartData";
import { ReactComponent as Question } from "../images/question.svg";
import PieChart from "../../../charts/PieChart";
import Print_PieChart from "../Components/Print_PieChart";
import TabCards from "../Components/TabCards";
import { dateFormatCommon, roundNumber } from "../../../utils/common";
import { date } from "yup/lib/locale";
import { currencyFormat } from "../../../../hooks/constantFunction";
import { MemberModel, TaxModel } from "../Components/utils/staticData";
import { ReactComponent as UpLineSVG } from "../../../../assets/icons/upLineSVG.svg";
import {
  getBgColor,
  getBorderColor,
  objectFormatingWithClientMemberWiseTitleCOl,
  objectFormatingWithTaxTitleCOl,
  objectFormatingWithTitleCOlWrapper,
} from "../Components/utils/common";
import { isArray } from "lodash";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

export default function Tax({
  data,
  infoText = "",
  denomination,
  pdfInfo,
  updatedAsOn,
  customRef,
  headers = [],
  header2 = [],
}) {
  const getDenomination = localStorage.getItem("denomination");

  const colors = ["#4B64ED", "#BDC5F2"];
  const [tableData, setTabledata] = useState([]);

  const [data1, setData1] = useState([]);

  //constants
  let hideUnderText = true;
  const hidecircle = true;
  const hideFirstCellCircle = true;
  const firstCellCircleProps = {
    height: "8px",
    width: "4.5px",
  };
  const customPadding = "2.2px 16px";
  const colormapping = [];
  const colSpan = 1;
  const hideTitle = true;
  const headerProps = {
    className: "whiteHeaderCell",
    titleClassName: "whiteTitle",
    titleStyle: {
      fontWeight: "500",
      fontFamily: "AvenirBold",
      fontSize: "14px",
      lineHeight: "18px",
      letterSpacing: "0.06em",
      textTransform: "uppercase",
      color: "#000000",
    },
  };
  let titleCellProps = {
    firstCellStyle: { padding: "10px 0px 10px 0px" },
    style: {
      background: "#F3F8FD",
      padding: "0px 16px 0px 9px",
      minHeight: "47px",
    },
    font: {
      fontWeight: "900",
      fontSize: "16px",
    },
  };
  const normalCellProps = {
    firstCellPadding: "10px 0px 10px 0px",
    fontStyle: { fontWeight: "600", fontSize: "14px" },
  };

  function handleOtherTotal(val) {
    if (val.addOn) {
      return val.addOn;
    } else {
      return val.field;
    }
  }

  // useEffect(() => {
  //   // if (!isArray(data) || data == "error") {
  //   //   return;
  //   // }
  //   // console.log(data, "error");
  //   setTabledata(data);
  // }, [data]);
  // if (!isArray(data) || data == "error") {
  //   return;
  // }

  // table bodyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy
  const tableBody = (data) => {
    let actualData = data?.filter(
      (row) =>
        row[0]?.type !== "disclaimer" &&
        row[0]?.type !== "total" &&
        row[0]?.type !== "title"
    );
    // console.log(actualData, "afljabsf");

    if (actualData?.length > 0) {
      return data?.map((row, j) => {
        // let actualData = row?.filter(
        //   (val) =>
        //     val?.type !== "disclaimer" &&
        //     val?.type !== "total" &&
        //     val?.type !== "title"
        // );
        // console.log(row, "afbsjkfjabsf");
        return (
          <>
            {row[0]?.field == "Grand Total" && (
              <TableRow
                style={{
                  borderTop: "2px solid #E9ECFB",
                  height: "20px",
                  background: "#ffffff",
                }}
              ></TableRow>
            )}
            <TableRow
              className={
                row[0]?.field == "Grand Total" ? "styledGrandTotal" : ""
              }
              style={{
                borderBottom: hideUnderText ? "2px solid #E9ECFB" : "",
                border:
                  row[0]?.field == "Grand Total" ? "1px solid #CFD4F5" : " ",
                borderRadius: "10px",
              }}
            >
              {row?.map((val, i) =>
                handleBodyCell(
                  val,
                  i,
                  val?.colspan || colSpan,
                  hideTitle,
                  j,

                  row[0].field == "Grand Total"
                    ? "Grand Total"
                    : row[0].field == "Total"
                    ? "Total"
                    : false
                )
              )}
            </TableRow>
            {!hideUnderText && (
              <TableRow
                className={
                  row[0]?.field == "Grand Total" ? "styledGrandTotal" : ""
                }
              >
                <TableCell
                  colSpan={row?.length}
                  style={{
                    borderLeft: `2px solid ${row[0]?.color}`,
                    padding: "0px",
                  }}
                  className="underTextCellContainer"
                >
                  <div className={"underTextCell"}>
                    <UpLineSVG
                      width="34px"
                      height="34px"
                      className="underTextIcon"
                    />
                    <p className="gloabalFont underText">
                      As On {dateFormatCommon(updatedAsOn)}
                    </p>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </>
        );
      });
    } else {
      return <></>;
    }
  };

  const handleBodyCell = (val, i, colSpan, hideTitle, j, totaltype) => {
    //console.log(val, colSpan, "gainloss");
    if (val?.type_null) {
      return;
    }
    let totalFormat = {
      fontWeight: "900",
    };
    let disclaimerCellStyle = {};
    let normalCellStyle = { ...normalCellProps?.fontStyle };

    if (totaltype) {
      disclaimerCellStyle.fontWeight = "900";
      normalCellStyle.fontWeight = "900";
      normalCellStyle.fontFamily = "AvenirBold";
      normalCellStyle.transform = "skew(-5deg)";
      normalCellStyle.webkitTransform = "skew(-5deg)";
      // normalCellStyle.background = "#f0f5fa";
    }
    if (totaltype == "Grand Total") {
      normalCellStyle.marginTop = "10px";
      normalCellStyle.paddingTop = "10px";
      normalCellStyle.paddingBottom = "20px";
      // normalCellStyle.background = "#f0f5fa";
    }

    if (val?.type == "title") {
      // console.log(titleCellProps, "askfjakjbsfsf");
      if (hideTitle) {
        return null;
      }
      if ((i == 0 || i == 1) && totaltype == "Grand Total") {
        normalCellStyle.fontStyle = "normal";
      }
      return (
        <TableCell
          // ref={customRef}
          colSpan={colSpan}
          style={{
            borderLeft: i == 0 ? `2px solid ${val?.color}` : "",
            ...titleCellProps?.style,
            background: val?.posOrNeg
              ? getBgColor(val?.posOrNeg) //posibly value ini in object formatting
              : titleCellProps?.style?.background,
            padding:
              i == 0
                ? colSpan > 1
                  ? "15px 0px"
                  : "0px 0px 0px 0px"
                : titleCellProps?.style?.padding
                ? titleCellProps?.style?.padding
                : "15px 0px",
          }}
          className={`${getBorderColor(val?.posOrNeg, true)} borderNone`}
          // className={`borderNone`}
          align={val?.align !== undefined ? val?.align : "right"}
        >
          {val?.posOrNeg && (
            <div
              style={{ backgroundColor: titleCellProps?.style?.background }}
              className="whiteSpace"
            ></div>
          )}
          <div
            className={i == 0 ? "firstCell" : "bodyCell"}
            style={{
              justifyContent: val?.align !== undefined ? val?.align : "right",
              minHeight: titleCellProps?.style?.minHeight,
              paddingLeft: val?.align == "left" && i == 0 ? "0.5rem" : "0",
              paddingRight: val?.align == "right" ? "0.5rem" : "0",
              fontFamily: "AvenirBold",
              fontWeight: 600,
            }}
          >
            {i == 0 && (
              <div
                className="leftCircle"
                style={{
                  backgroundColor: hidecircle ? val?.color : "",
                  ...firstCellCircleProps,
                }}
              ></div>
            )}
            <p
              className="gloabalFont bodyCellFont "
              style={{
                ...titleCellProps?.font,
                fontFamily: "AvenirBold",
                fontSize: "14px",
                fontWeight: 600,
                display: "flex",
              }}
            >
              {val?.field}
            </p>
          </div>
        </TableCell>
      );
    }
    if ((i == 0 || i == 1) && totaltype == "Grand Total") {
      normalCellStyle.fontStyle = "normal";
      normalCellStyle.transform = "skew(0deg)";
      normalCellStyle.webkitTransform = "skew(0deg)";
    }
    if (i == 0 && totaltype == "Grand Total") {
      normalCellStyle.fontSize = "18px";
    }

    return (
      <TableCell
        // ref={customRef}
        colSpan={colSpan}
        style={{
          ...normalCellProps?.tablecell,
          padding:
            i == 0
              ? normalCellProps?.firstCellPadding
                ? normalCellProps?.firstCellPadding
                : "0px"
              : val?.withtwoline
              ? "10px 16px"
              : customPadding,
          borderLeft: colormapping
            ? "4px solid " + colormapping[val.field]
            : colors && i == 0
            ? "4px solid " + colors[j - 1]
            : "none",
          borderTop:
            totaltype == "Total"
              ? "1.2px solid darkgray"
              : "1.2px solid #e0e0e0",
          borderBottom: totaltype == "Total" ? "1.2px solid darkgray" : "none",
          background:
            totaltype == "Grand Total" ? "#f3f8fd" : totaltype ? "#ffffff" : "",
        }}
        className={`${getBorderColor(
          val?.posOrNeg,
          true,
          totaltype
        )} borderNone`}
        align={val?.align !== undefined ? val?.align : "right"}
      >
        {val?.posOrNeg && <div className="whiteSpace"></div>}
        <div
          className={i == 0 ? "firstCell" : "bodyCell"}
          style={{
            ...normalCellProps?.parent,
            justifyContent: val?.align !== undefined ? val?.align : "right",
            paddingLeft: val?.align == "left" && i == 0 ? "0.5rem" : "0",
            paddingRight: val?.align == "right" ? "0.5rem" : "0",
          }}
        >
          {i == 0 && !hideFirstCellCircle && (
            <div
              className="leftCircle"
              style={{
                backgroundColor: hidecircle ? val?.color : "",
                ...firstCellCircleProps,
              }}
            ></div>
          )}
          <p
            style={{
              marginLeft: hideFirstCellCircle
                ? i == 0
                  ? "16px"
                  : "9px"
                : "0px",
              ...normalCellStyle,
              ...val?.customStyle,
              display: "flex",
            }}
            className="gloabalFont bodyCellFont"
          >
            {totaltype == "Grand Total" || totaltype == "Total"
              ? handleOtherTotal(val)
              : val?.field}
          </p>
        </div>
      </TableCell>
    );
  };

  //end of table bodyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy
  const tableHeaders = (data) => {
    return data?.map((val) => {
      return (
        <TableCell
          colSpan={val?.col}
          align={val?.align ? val?.align : "right"}
          style={{ ...headerProps?.style, borderRight: val.borderRight || "" }}
          className={
            headerProps?.className ? headerProps?.className : "headerCell"
          }
        >
          <p
            className={`${
              headerProps?.titleClassName ? headerProps?.titleClassName : ""
            }headerTitle gloabalFont`}
            style={{ ...headerProps?.titleStyle, ...val?.headerStyle }}
          >
            {val?.title}
          </p>
          {val?.subtext ? (
            <div style={{ color: "#000000" }}>{val?.subtext} </div>
          ) : (
            <div style={{ height: "24px" }}></div>
          )}
          {val?.subTitle && (
            <div className="headerSub">
              <p className={` gloabalFont headerSubTitle`}>{val?.subTitle}</p>
            </div>
          )}
        </TableCell>
      );
    });
  };

  return (
    <div>
      <div className="p_flex" style={{ alignItems: "center" }}>
        <div
          style={{
            position: "absolute",
            zIndex: "5",
            width: "90%",
            top: "2in",

            marginRight: "66px",
          }}
        >
          <div className="newPrint pagebreakTable">
            {data.length > 0 && (
              <div className="tableContainer">
                {/* {tableTitle && <p className="gloabalFont tableTitle">{tableTitle}</p>} */}

                <Table className={`printTable  printTableEvenAndOddRow`}>
                  <TableHead className="headerRow">
                    <TableRow>{tableHeaders(headers)}</TableRow>
                  </TableHead>
                  {header2 && (
                    <TableHead className="bgblu">
                      <TableRow>{tableHeaders(header2)}</TableRow>
                    </TableHead>
                  )}
                  {/* {data?.length > 0 && ( */}
                  <TableBody ref={customRef}>
                    {/* <TableRow style={{ height: "10px" }} /> */}
                    {tableBody(data ? data : [])}
                  </TableBody>
                  {/* )} */}
                </Table>

                <div className="s_text pt10"> {infoText}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
