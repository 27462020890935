import React from "react";
import Default from "../components/dashboard/defaultPages/Default";
import EquityComp from "../components/dashboard/products/equity/EquityComp";
function Equity() {
  return (
    <>
      <Default>
        <EquityComp></EquityComp>
      </Default>
    </>
  );
}

export default Equity;
