import { Card, CardContent, Grid, Typography, Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import PageFilter from "../common/PageFilter";
import useStyles from "../common/ProductsStyles";
import { ReactComponent as Print } from "../../../../assets/icons/print.svg";

import PieChart from "../../../charts/PieChart";
import GlowCircle from "../../../charts/GlowCircle";
import LineChart from "../../../charts/LineChart";
import BarChart from "../../../charts/BarChart";
import { tooltip } from "../../../charts/barTooltip";

import { colors_s8 } from "../../../charts/colors";
import { apexTooltip } from "../../../charts/nivo_objects";
import {
  divergingCommonProps,
  pie_onClick2,
} from "../../../charts/nivo_objects";
import TabHeader from "../common/TabHeader";
import RealEstateCardSummary from "./RealEstateCardSummary";
import { OnboardingContext } from "../../../../Context";
import { useContext } from "react";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import {
  fetchTwrr,
  fetchTwrrCurrentHolding,
  getRealisedData,
  fetchXirr,
  fetchXirrCurrentHolding,
  getCategoryAllocation,
  getRentPerformance,
} from "../../../../api";
import moment from "moment";
import PageLoader from "../common/pageLoader";
import DataNotFound from "../common/DataNotFound";
import { currencyFormat } from "../../../../hooks/constantFunction";

import {
  dateFormatCommon,
  roundNumber,
  roundNumberWithoutDecimal,
} from "../../../utils/common";
import { loadingText } from "../../../utils/constands";
import SummaryPageTop from "../common/SummaryPageTop";
import ApexNew from "../../../charts/ApexNew";
import FilterCalendar from "../../../calendar/FilterCalendar";

import { useHoldingCardActive } from "../../../../hooks/useHoldingCardActive";

import { NewHoldingSummaryCards } from "../../../common/HoldingSummaryCards/NewHoldingSummaryCards";
import { GainsCardInfo } from "../../../common/HoldingSummaryCards/GainsCardInfo";
import { NewHoldingSummaryCardsActive } from "../../../common/HoldingSummaryCards/NewHoldingSummaryCardsActive";
import SummaryDetailsSectionWrapper from "../newDesigns/common/SummaryDetailsSectionWrapper";
import { summaryDetailsSecConstData } from "../utils/constands/summaryDetailsSec";
import {
  colorAndProdRealEstate,
  prodAndKeyRealEstate,
} from "../utils/constands/prodAndColor";
import { useReturns } from "../../../../hooks/useReturns";
import { useNormalReturns } from "../../../../hooks/useNormalReturns";
import { routes } from "../../../../routes";
import { useTotalGainLoss } from "../../../../hooks/useTotalGainLoss";
import { NewFilter } from "../../newFilter/NewFilter";
import { ReactComponent as InfoIcon } from "../../../../assets/icons/TotalGainLossInfoIcon.svg";
import { useCardsApi } from "../../../../hooks/useCardsApi";
import { useCurrentHoldingCardsApi } from "../../../../hooks/useCurrentHoldingCardsApi";
function RealEstateComp() {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [infoIconHovered, setInfoIconHovered] = useState(false);
  // const [holdingCardData] = useHoldingCardActive("re", checked);
  // const [totalGainLossData] = useTotalGainLoss("re");
  // const { returns: twrr } = useNormalReturns(
  //   fetchTwrr,
  //   "normal-twrr",
  //   checked,
  //   "re"
  // );
  // const { returns: xirr } = useNormalReturns(
  //   fetchXirr,
  //   "normal-xirr",
  //   checked,
  //   "re"
  // );
  const {
    holdingCardData,
    totalGainLossData,
    xirr,
    twrr,
    loading: cardLoader,
    twrrLoader,
    twrrError,
    retryFetchTwrr,
  } = useCardsApi("re");
  // const { returns: xirrCurrentHolding } = useReturns(
  //   fetchXirrCurrentHolding,
  //   "xirr",
  //   checked,
  //   "re"
  // );
  // const { returns: twrrCurrentHolding, loading: twrrCurrentHoldingLoading } =
  //   useReturns(fetchTwrrCurrentHolding, "twrr", checked, "re");
  const {
    // holdingCardData,
    xirrCurrentHolding,
    twrrCurrentHolding,
    loading: currentHoldingCardLoader,
    twrrLoaderCurrentHolding,
    twrrErrorCurrentHolding,
    retryFetchTwrrCurrentHolding,
  } = useCurrentHoldingCardsApi(checked, "re");

  const navigate = useNavigate();
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const { userState } = OnboardingContext();
  // const [xirr, setXirr] = useState({});
  // const [xirrCurrentHolding, setXirrCurrentHolding] = useState({});
  // const [twrr, setTwrr] = useState({});
  // const [twrrCurrentHolding, setTwrrCurrentHolding] = useState({});
  const [realisedData, setRealisedData] = useState({});
  const [dateRange, setDataRange] = useState({
    startDate: moment().subtract(5, "year").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const [range, setRange] = useState("5Y");
  const [customRange, setCustomRange] = useState([null, null]);
  const [apexLinedata, setApexLineData] = useState([]);
  const [categoryAlocation, setCategoryAlocation] = useState({
    colors: Object.values(colorAndProdRealEstate),
  });
  const [loading, setLoading] = useState(true);
  const [load, setLoad] = useState(true);
  const [flip, setFlip] = useState(false);
  const [pie, setPie] = useState({
    title: "",
    value: "",
    colors: Object.values(colorAndProdRealEstate),
    disabled: [],
    fill: [
      {
        match: {
          id: "Commerical",
        },
        id: "c9",
      },
      {
        match: {
          id: "Residential",
        },
        id: "c10",
      },
    ],
  });
  const [newCardData, setNewCardData] = useState([]);
  const Data = [
    {
      fundName: "Value",
      graphData: [
        {
          d: "Oct-2021",
          v: 0,
        },
        {
          d: "Nov-2021",
          v: 0,
        },
        {
          d: "Dec-2021",
          v: 0,
        },
        {
          d: "Jan-2022",
          v: 0,
        },
        {
          d: "Feb-2022",
          v: 0,
        },
        {
          d: "Mar-2022",
          v: 0,
        },
        {
          d: "Apr-2022",
          v: 0,
        },
        {
          d: "May-2022",
          v: 0,
        },
        {
          d: "Jun-2022",
          v: 2460.69,
        },
        {
          d: "Jul-2022",
          v: 2460.69,
        },
        {
          d: "Aug-2022",
          v: 2460.69,
        },
        {
          d: "Sep-2022",
          v: 2460.69,
        },
        {
          d: "Oct-2022",
          v: 2460.69,
        },
        {
          d: "Nov-2022",
          v: 2460.69,
        },
        {
          d: "Dec-2022",
          v: 2460.69,
        },
        {
          d: "Jan-2023",
          v: 2460.69,
        },
        {
          d: "Feb-2023",
          v: 2460.69,
        },
        {
          d: "Mar-2023",
          v: 2460.69,
        },
        {
          d: "Apr-2023",
          v: 2460.69,
        },
        {
          d: "May-2023",
          v: 2460.69,
        },
      ],
    },
    {
      fundName: "Rent",
      graphData: [
        {
          d: "Oct-2021",
          v: 2500000,
        },
        {
          d: "Nov-2021",
          v: 4500000,
        },
        {
          d: "Dec-2021",
          v: 3000000,
        },
        {
          d: "Jan-2022",
          v: 0,
        },
        {
          d: "Feb-2022",
          v: 0,
        },
        {
          d: "Mar-2022",
          v: 0,
        },
        {
          d: "Apr-2022",
          v: 3000000,
        },
        {
          d: "May-2022",
          v: 3000000,
        },
        {
          d: "Jun-2022",
          v: 3000000,
        },
        {
          d: "Jul-2022",
          v: 0,
        },
        {
          d: "Aug-2022",
          v: 2500000,
        },
        {
          d: "Sep-2022",
          v: 0,
        },
        {
          d: "Oct-2022",
          v: 4500000,
        },
        {
          d: "Nov-2022",
          v: 0,
        },
        {
          d: "Dec-2022",
          v: 15400000,
        },
        {
          d: "Jan-2023",
          v: 0,
        },
        {
          d: "Feb-2023",
          v: 0,
        },
        {
          d: "Mar-2023",
          v: 0,
        },
        {
          d: "Apr-2023",
          v: 0,
        },
        {
          d: "May-2023",
          v: 0,
        },
      ],
    },
  ];

  const handleCategoryAllocationData = (data) => {
    let newData = data?.map((val) => {
      if (val?.product_sub_category !== "")
        return {
          title: val?.product_sub_category,
          percent: val?.percentage
            ? roundNumber(val?.percentage) + "%"
            : "0.00%",
          date: val?.asOnDate ? dateFormatCommon(val?.asOnDate) : "--",
          amount: val?.amount ? currencyFormat(val?.amount) : currencyFormat(0),
        };
    });

    setNewCardData(() => [...newData?.filter((val) => val !== undefined)]);
  };

  const getCategoryAllocationData = async (params) => {
    setLoad(true);
    let colors = Object.values(colorAndProdRealEstate);
    try {
      params = {
        ...params,
        clientId: userState.loginedUserData.id,
      };
      let res = await getCategoryAllocation("re", params);

      handleCategoryAllocationData(res?.data?.data?.result?.categoryAllocation);

      setCategoryAlocation((prev) => ({ ...prev, ...res?.data?.data?.result }));
      res?.data?.data?.result?.categoryAllocation?.map((val) => {
        if (val?.product_sub_category == "commercial") {
          setCategoryAlocation((prev) => ({
            ...res?.data?.data?.result,
            colors: [...prev.colors, colorAndProdRealEstate["commercial"]],
          }));
          colors.push("#E96114");
        } else if (val?.product_sub_category.toLowerCase == "residential") {
          setCategoryAlocation((prev) => ({
            ...res?.data?.data?.result,
            colors: [...prev.colors, colorAndProdRealEstate["residential"]],
          }));
          colors.push(colorAndProdRealEstate["residential"]);
        }
        //  else {
        //   setCategoryAlocation((prev) => ({
        //     ...res?.data?.data?.result,
        //     colors: [
        //       ...prev.colors,
        //       colorAndProdRealEstate[val?.product_sub_category.toLowerCase],
        //     ],
        //   }));
        //   colors.push(
        //     colorAndProdRealEstate[val?.product_sub_category.toLowerCase]
        //   );
        // }
      });
    } catch (error) {
      console.log(error);
    }
    setPie({ ...pie, colors: colors });
    setLoad(false);
  };

  const diverging = {
    ...divergingCommonProps,
  };
  diverging.axisBottom.legendOffset = 65;
  diverging.margin = { top: 5, right: 20, bottom: 70, left: 80 };
  useEffect(() => {
    const clientId = userState.loginedUserData.id;
    const type = "re";

    let params = { ...getfilter_as_params() };

    setLoading(true);
    Promise.allSettled([
      // fetchXirr(clientId, params, type),
      // fetchXirrCurrentHolding(clientId, params, type),
      // fetchTwrr(clientId, params, type),
      // fetchTwrrCurrentHolding(clientId, params, type),
      getRealisedData(clientId, params, type, checked),
      getRentPerformance(clientId, { ...params, ...dateRange }),
    ]).then(
      ([
        // xirr_res,
        // xirrCurrentHolding_res,
        // twrr_res,
        // twrrCurrentHolding_res,
        realiseddata_res,
        rent_res,
      ]) => {
        // async function allres(){
        //   await xirr_res
        //   await twrr_res
        //   await realiseddata_res
        //   await asset_ress
        //   setLoading(false)
        // }

        // if (xirr_res.status == "fulfilled") {
        //   setXirr(xirr_res.value.result);
        // }
        // if (xirrCurrentHolding_res.status == "fulfilled") {
        //   setXirrCurrentHolding(xirrCurrentHolding_res.value.result);
        // }
        // if (twrr_res.status == "fulfilled") {
        //   if (twrr_res.value?.result?.annualisedTwrr) {
        //     twrr_res.value.result.annualisedTwrr =
        //       twrr_res.value?.result?.annualisedTwrr * 100;
        //   }
        //   setTwrr(twrr_res.value.result);
        // }
        // if (twrrCurrentHolding_res.status == "fulfilled") {
        //   if (twrrCurrentHolding_res.value?.result?.annualisedTwrr) {
        //     twrrCurrentHolding_res.value.result.annualisedTwrr =
        //       twrrCurrentHolding_res.value?.result?.annualisedTwrr * 100;
        //   }
        //   setTwrrCurrentHolding(twrrCurrentHolding_res.value.result);
        // }
        if (realiseddata_res.status == "fulfilled") {
          setRealisedData(realiseddata_res.value.result);
        }
        if (rent_res.status == "fulfilled") {
          let linedata = [];
          rent_res.value.results.forEach((l) => {
            let obj = { name: l.fundName, data: [] };
            l.graphData.forEach((d) => {
              obj.data.push([d.d, parseInt(d.v), d.v]);
            });
            linedata.push(obj);
          });

          setApexLineData(linedata);
        }

        setLoading(false);
      }
    );

    // setLoading(true);
    // fetchXirr(clientId, params, type).then((res) => {
    //   setXirr(res.result);
    //   console.log(res.result);
    // });
    // fetchTwrr(clientId, params, type).then((res) => {
    //   if (res?.result?.annualisedTwrr) {
    //     res.result.annualisedTwrr = res?.result?.annualisedTwrr * 100;
    //   }
    //   setTwrr(res.result);
    //   console.log(res.result);
    // });
    // getRealisedData(clientId, params, type).then((res) => {
    //   setRealisedData(res.result);
    //   console.log(res.result);
    // });
    // setLoading(false);
    getCategoryAllocationData(params);
  }, [mfoFilter, checked]);
  useEffect(() => {
    let params = { ...getfilter_as_params() };
    let clientId = userState.loginedUserData.id;
    setLoading(true);
    getRentPerformance(clientId, { ...params, ...dateRange })
      .then((res) => {
        let linedata = [];
        res.results.forEach((l) => {
          let obj = { name: l.fundName, data: [] };
          l.graphData.forEach((d) => {
            obj.data.push([d.d, parseInt(d.v), d.v]);
          });
          linedata.push(obj);
        });

        setApexLineData(linedata);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [dateRange]);

  // const handleSummaryClick = () => {
  //   navigate("/home/aif/real-estate/report")
  // }

  // let currencyFormat = (value) => {
  //   return currencyFormatLocal(value, {
  //     currencyStyle: { style: "currency", currency: "INR" },
  //   });
  // };
  function holdingSummaryCardsInactiveData() {
    return {
      asOnDate:
        realisedData?.asOnDate != null
          ? holdingCardData?.asOnDate
          : loadingText,

      //    * current-holding api values
      portfolioValueAmount:
        holdingCardData?.portfolioValueAmount != null
          ? currencyFormat(holdingCardData?.portfolioValueAmount)
          : loadingText,
      //   * gain-loss api values
      overallInvestedAmount:
        realisedData?.overAllInvestedAmount != null
          ? currencyFormat(realisedData?.overAllInvestedAmount)
          : loadingText,
      //    * current-holding api values
      investedAmount:
        holdingCardData?.investedAmount != null
          ? currencyFormat(holdingCardData?.investedAmount)
          : loadingText,
      //    * current-holding api values
      redeemedInvestedAmount:
        holdingCardData?.redeemedInvestedAmount != null
          ? currencyFormat(holdingCardData?.redeemedInvestedAmount)
          : loadingText,
      //   * total-gain-loss api value
      totalGainLoss:
        totalGainLossData?.totalGainLoss != null
          ? currencyFormat(totalGainLossData?.totalGainLoss)
          : loadingText,
      //   * gain-loss api values
      realisedGainLoss:
        realisedData?.realisedGainLoss != null
          ? currencyFormat(realisedData?.realisedGainLoss)
          : loadingText,
      //    * current-holding api values
      unRealisedGainLoss:
        holdingCardData?.unRealisedGainLoss != null
          ? currencyFormat(holdingCardData?.unRealisedGainLoss)
          : loadingText,
      //   * gain-loss api values
      xirrValue:
        xirr?.xirr != null
          ? roundNumberWithoutDecimal(Number(xirr?.xirr)) + " %"
          : loadingText,
      //   * gain-loss api values
      absoluteTwrrData:
        twrr?.absoluteTwrr != null
          ? roundNumberWithoutDecimal(Number(twrr?.absoluteTwrr)) + " %"
          : loadingText,
      //   * gain-loss api values
      annualisedTwrrData:
        twrr?.annualisedTwrr != null
          ? roundNumberWithoutDecimal(Number(twrr?.annualisedTwrr)) + " %"
          : loadingText,
    };
  }
  function holdingSummaryCardsActiveData() {
    return {
      asOnDate:
        holdingCardData?.asOnDate !== null
          ? holdingCardData?.asOnDate
          : loadingText,
      portfolioValueAmount:
        holdingCardData?.portfolioValueAmount !== null
          ? currencyFormat(holdingCardData?.portfolioValueAmount)
          : loadingText,
      investedAmount:
        holdingCardData?.investedAmount !== null
          ? currencyFormat(holdingCardData?.investedAmount)
          : loadingText,
      totalGainLoss:
        holdingCardData?.totalGainLoss !== null
          ? currencyFormat(holdingCardData?.totalGainLoss)
          : loadingText,
      realisedGainLoss:
        holdingCardData?.realisedGainLoss !== null
          ? currencyFormat(0)
          : loadingText,
      unRealisedGainLoss:
        holdingCardData?.unRealisedGainLoss !== null
          ? currencyFormat(holdingCardData?.unRealisedGainLoss)
          : loadingText,
      xirrValue:
        xirrCurrentHolding?.xirr !== null
          ? roundNumberWithoutDecimal(Number(xirrCurrentHolding?.xirr)) + " %"
          : loadingText,
      absoluteTwrrData:
        twrrCurrentHolding?.absoluteTwrr !== null
          ? roundNumberWithoutDecimal(
              Number(twrrCurrentHolding?.absoluteTwrr)
            ) + " %"
          : loadingText,
      annualisedTwrrData:
        twrrCurrentHolding?.annualisedTwrr !== null
          ? roundNumberWithoutDecimal(
              Number(twrrCurrentHolding?.annualisedTwrr)
            ) + " %"
          : loadingText,
    };
  }
  return (
    <>
      <PageLoader showLoader={loading || load ? true : false} />
      <PageLoader
        showLoader={currentHoldingCardLoader && checked ? true : false}
      />
      <SummaryPageTop
        pageTitle={"Real Estate Portfolio Summary"}
        backLink={routes?.homeHoldingPage}
        // asOnDate={realisedData?.asOnDate}
        flip={flip}
        setFlip={setFlip}
        checked={checked}
        setChecked={setChecked}
        setFilterModalOpen={setFilterModalOpen}
      />
      <NewFilter
        filterModalOpen={filterModalOpen}
        setFilterModalOpen={setFilterModalOpen}
        productType="re"
      />
      {/* <PageFilter />
      <div className={`${classes.alignTopHeader}`}>
        <Back
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/home/holding-summary/details")}
        />
        <Typography
          style={{ marginLeft: "-4%" }}
          className={`${classes.mainHeading} ${classes.mainHeadingAlign}`}
        >
          Real Estate Portfolio Summary
          <br />
          {realisedData?.asOnDate && (
            <span className={`portfolioAsOnTxt ${classes.mfoSubTextCard}`}>
              As on {realisedData?.asOnDate}
              {sessionStorage.getItem("currency_format") != "actuals" &&
                sessionStorage.getItem("currency_format") && (
                  <span
                    className="subtext capitalize"
                    style={{ fontSize: "16px" }}
                  >
                    {" "}
                    (values in {sessionStorage.getItem("currency_format")})
                  </span>
                )}
            </span>
          )}
        </Typography>

        <div className={`${classes.iconAlign}`}>
          {!flip && (
            <Bulb
              onClick={() => setFlip(!flip)}
              className={classes.iconStyle}
            />
          )}
          {flip && (
            <LitBulb
              onClick={() => setFlip(!flip)}
              className={classes.iconStyle}
            />
          )}
          <Import className={classes.iconStyle} />
          <Export className={classes.iconStyle} />
          <Print className={classes.iconStyle} />
          <Filter className={classes.iconStyle} />
        </div>
      </div> */}
      {/* <div style={{ margin: "2%" }} className={classes.topHeader}>
        <Back style={{ cursor: 'pointer', width: "40px" }} onClick={() => navigate(-1)} />
        <Typography style={{ cursor: 'pointer' }} className={classes.titleText}>
          Portfolio Holding Summary
          <br/>
          <small className="fnt14">Updated as on 12/10/2022</small>
        </Typography>
        <div style={{ display: "flex", justifyContent: "flex-end", marginLeft: 'auto' }}>
          <Bulb className={classes.iconStyle} />
          <Import className={classes.iconStyle} />
          <Export className={classes.iconStyle} />
          <Print className={classes.iconStyle} />
          <Filter className={classes.iconStyle} />
        </div>
      </div> */}

      {/* <RealEstateCardSummary
        xirr={xirr}
        twrr={twrr}
        realisedData={realisedData}
      ></RealEstateCardSummary> */}
      {!checked ? (
        <div style={{ margin: "2%" }}>
          <NewHoldingSummaryCards
            retryFetchTwrr={retryFetchTwrr}
            twrrLoader={twrrLoader}
            twrrError={twrrError}
            infoIconHovered={infoIconHovered}
            holdingSummaryCardsInactive={holdingSummaryCardsInactiveData()}
            // totalRentalIncome={
            //   <GainsCardInfo
            //     text="Total Rental Income"
            //     align="right"
            //     value={
            //       realisedData?.rentalIncome != null
            //         ? currencyFormat(realisedData?.rentalIncome)
            //         : loadingText
            //     }
            //   />
            // }
            totalGainLossInactive={
              <>
                <GainsCardInfo
                  text="Realized Gain/Loss"
                  value={holdingSummaryCardsInactiveData()?.realisedGainLoss}
                  align="left"
                />
                <GainsCardInfo
                  text="Unrealized Gain/Loss"
                  value={holdingSummaryCardsInactiveData()?.unRealisedGainLoss}
                  align="center"
                />
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <GainsCardInfo
                    text="Total Rental Income"
                    value={
                      realisedData?.rentalIncome != null
                        ? currencyFormat(realisedData?.rentalIncome)
                        : loadingText
                    }
                    align="right"
                  />
                </div>
              </>
            }
          ></NewHoldingSummaryCards>
        </div>
      ) : (
        <div style={{ margin: "2%" }}>
          <NewHoldingSummaryCardsActive
            retryFetchTwrrCurrentHolding={retryFetchTwrrCurrentHolding}
            twrrLoaderCurrentHolding={twrrLoaderCurrentHolding}
            twrrErrorCurrentHolding={twrrErrorCurrentHolding}
            infoIconHovered={infoIconHovered}
            holdingSummaryCardsActive={holdingSummaryCardsActiveData()}
            // totalRentalIncome={
            //   <GainsCardInfo
            //     text="Total Rental Income"
            //     align="right"
            //     value={
            //       holdingCardData?.rentalIncome != null
            //         ? currencyFormat(holdingCardData?.rentalIncome)
            //         : loadingText
            //     }
            //   />
            // }
            totalGainLossActive={
              <>
                <GainsCardInfo
                  text="Unrealized Gain/Loss"
                  value={holdingSummaryCardsActiveData()?.unRealisedGainLoss}
                  align="left"
                />
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <GainsCardInfo
                    text="Total Rental Income"
                    value={
                      holdingCardData?.rentalIncome != null
                        ? currencyFormat(holdingCardData?.rentalIncome)
                        : loadingText
                    }
                    align="right"
                  />
                </div>
              </>
            }
          ></NewHoldingSummaryCardsActive>
        </div>
      )}

      <SummaryDetailsSectionWrapper
        summaryDetailsSecConstData={summaryDetailsSecConstData}
        cardData1={newCardData}
        hideNavButtons={true}
        customColorAndProd={colorAndProdRealEstate}
        customProdAndKey={prodAndKeyRealEstate}
        handleClick={() => navigate(routes.realEstateSummaryPage_holding)}
        peiChart={
          <div className="flex_center tr20" style={{ height: "100%" }}>
            <div className={classes.wid380px}>
              <PieChart
                colors={pie.colors}
                fill={pie.fill}
                config={{
                  innerRadius: "0.80",
                  cornerRadius: 0,
                  padAngle: 0,
                  legends: false,
                  minWith: "380px",
                  disableArcLink: true,
                  chart: {
                    width: 440,
                    height: 420,
                    arcLinkLabelsStraightLength: 20,
                    arcLinkLabelsDiagonalLength: 20,
                  },

                  arcLinkLabel: (d) => (
                    <tspan
                      x="0"
                      y="0"
                      style={{
                        fontFamily: "Avenir",
                        fontWeight: "400",
                        fontSize: "12",
                        textTransform: "capitalize",
                      }}
                    >
                      {" "}
                      {d.id}
                      <tspan
                        x="0"
                        y="18"
                        style={{
                          fontFamily: "Open Sans",
                          fontWeight: "700",
                          fontSize: "14",
                        }}
                      >
                        {" "}
                        {d.value}%
                      </tspan>
                    </tspan>
                  ),
                }}
                onClickEvent={{
                  click: pie_onClick2,
                  set: setPie,
                  cstate: pie,
                  ncolors: categoryAlocation.colors,
                }}
                data={categoryAlocation?.categoryAllocation?.map((val) => ({
                  id:
                    val?.product_sub_category?.charAt(0).toUpperCase() +
                    val?.product_sub_category?.slice(1),
                  label: val?.product_sub_category,
                  value: Number(val?.percentage).toFixed(2),
                  amount: val?.amount,
                  color:
                    val?.product_sub_category == "commercial"
                      ? "#E96114"
                      : "#509FF1",
                }))}
              >
                <GlowCircle>
                  <div className="circle_content">
                    <div>
                      <b className="circle_text">
                        {pie.title && pie.value != null
                          ? pie.title
                          : "Portfolio value"}
                      </b>
                      <br></br>
                      <b className="circle_text2">
                        {pie.amount != null
                          ? currencyFormat(pie.amount)
                          : categoryAlocation?.currentValueAmount != null
                          ? currencyFormat(
                              categoryAlocation?.currentValueAmount
                            )
                          : ""}
                      </b>
                      <div
                        className="circle_text2"
                        style={{ paddingTop: "5px" }}
                      >
                        {pie?.value ? roundNumber(pie?.value) + "%" : ""}
                      </div>
                    </div>
                  </div>
                </GlowCircle>
              </PieChart>
            </div>
          </div>
        }
      />
      {/* {categoryAlocation?.categoryAllocation?.length > 0 && */}
      {/* <div className={classes.mainContent}>
        
        <Typography
          className={`${classes.mfoGraphHeader} ${classes.typoInsideAlign}`}
        >
          Category Allocation
        </Typography>

        <Grid container className={classes.holdingContentAlign}>
          <Grid
            item
            xs={categoryAlocation?.categoryAllocation?.length > 0 ? 6 : 12}
          >
            <div className="flex_center">
              <div style={{ width: "498px" }}>
                {categoryAlocation?.categoryAllocation?.length > 0 ? (
                  <PieChart
                    fill={pie.fill}
                    config={{
                      innerRadius: "0.90",
                      padAngle: 0,
                      legends: false,
                      disableArcLink: true,
                      minWith: "380px",
                      margin: { top: 40, right: 130, bottom: 80, left: 120 },
                      chart: { width: 500, height: 420 },
                      arcLinkLabel: (d) => (
                        <tspan>
                          <tspan
                            x="0"
                            y="0"
                            style={{
                              fontStyle: "Avenir",
                              fontWeight: "400",
                              fontSize: "12px",
                              textTransform: "capitalize",
                            }}
                          >
                            {d.label}
                          </tspan>
                          <tspan
                            x="0"
                            y="18"
                            style={{
                              fontStyle: "Avenir",
                              fontWeight: "700",
                              fontSize: "14px",
                              lineHeight: "27px",
                            }}
                          >
                            {d.value}%
                          </tspan>
                        </tspan>
                      ),
                    }}
                    onClickEvent={{
                      click: pie_onClick2,
                      set: setPie,
                      cstate: pie,
                      ncolors: categoryAlocation.colors,
                    }}
                    colors={pie.colors}
                    data={categoryAlocation?.categoryAllocation?.map((val) => ({
                      id:
                        val?.product_sub_category?.charAt(0).toUpperCase() +
                        val?.product_sub_category?.slice(1),
                      label: val?.product_sub_category,
                      value: Number(val?.percentage).toFixed(2),
                      amount: val?.amount,
                      color:
                        val?.product_sub_category == "commercial"
                          ? "#E96114"
                          : "#509FF1",
                    }))}
                  >
                    <div className="circle_translate">
                      <GlowCircle>
                        <div className="circle_content">
                          <div>
                            <b>{pie.title ? pie.title : "Current Value"}</b>
                            <br></br>
                            <b>
                              {pie.value
                                ? currencyFormat(pie.amount)
                                : currencyFormat(
                                    categoryAlocation?.currentValueAmount
                                  )}
                            </b>{" "}
                            <div
                              className="circle_text2"
                              style={{ paddingTop: "5px" }}
                            >
                              {pie?.value ? roundNumber(pie?.value) + "%" : ""}
                            </div>
                          </div>
                        </div>
                      </GlowCircle>
                    </div>
                  </PieChart>
                ) : (
                  <DataNotFound />
                )}
              </div>
            </div>
            {categoryAlocation?.categoryAllocation?.length > 0 && (
              <Typography
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  marginBlock: "4%",
                }}
                className={`${classes.titleText} ${classes.typoInsideAlign}`}
              >
                Current Allocation
              </Typography>
            )}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                style={{
                  // width: "40px",
                  background: " #380D77",
                  borderRadius: "50px",
                }}
                className={`${classes.holdingButton}`}
                variant="contained"
                onClick={() => navigate("/home/real-estate/report/holding")}
              >
                <Typography
                  className={classes.holdingButtonText}
                  style={{ padding: "2%" }}
                >
                  View Details
                </Typography>
              </Button>
              {/* <Button
                style={{
                  width: "50%",
                  background: " #380D77",
                  borderRadius: "50px",
                }}
                className={`${classes.holdingButton}`}
                variant="contained"
                onClick={() => navigate("/home/real-estate/report/transaction")}
              >
                <Typography
                  className={classes.holdingButtonText}
                  style={{ padding: "2%" }}
                >
                  View Transaction Summary
                </Typography>
              </Button> 
            </div>
          </Grid>

          <Grid
            item
            xs={categoryAlocation?.categoryAllocation?.length > 0 ? 6 : 0}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "24px",
                alignContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "center",
                height: "100%",
              }}
            >
              {categoryAlocation?.categoryAllocation?.map((val) => {
                if (
                  val?.product_sub_category == "commercial" ||
                  val?.product_sub_category == "residential"
                )
                  return (
                    <div
                      classname="customCardContainer"
                      style={{
                        width: "100%",
                        position: "relative",
                      }}
                    >
                      <div
                        className="customCard"
                        style={{
                          background:
                            val?.product_sub_category == "commercial"
                              ? " linear-gradient(247.79deg, #FE6587 3.21%, #F58D2D 100%)"
                              : " linear-gradient(180deg, #509FF1 0%, #3A56FF 100%)",
                          height: "200px",
                        }}
                      >
                        <CardContent className={classes.newCardContent}>
                          <Typography
                            style={{
                              textTransform: "capitalize",
                              fontSize: "22px",
                            }}
                            className={classes.cardTextSmall}
                          >
                            {val?.product_sub_category}
                          </Typography>
                          <Typography
                            style={{
                              textTransform: "capitalize",
                              fontSize: "1rem",
                            }}
                            className={classes.cardTextSmall}
                          >
                            Current Value
                          </Typography>
                          <Typography
                            className={classes.customCardText900}
                            style={{
                              fontSize: "25px",
                              marginBottom: "none",
                            }}
                          >
                            {currencyFormat(val?.amount)}
                          </Typography>
                          <Typography
                            style={{
                              textTransform: "capitalize",
                              fontSize: "0.875rem",
                            }}
                            className={classes.cardTextSmall}
                          >
                            Percentage Of Current Value
                          </Typography>
                          <Typography
                            className={classes.cardSubTextSmall}
                            style={{ fontSize: "18px" }}
                          >
                            {val?.percentage.toFixed(2)}%
                          </Typography>
                        </CardContent>
                      </div>
                      <div
                        className={classes.subTab}
                        style={{ width: "max-content", margin: "0px" }}
                      >
                        <Typography
                          className={classes.cardBottomText}
                          style={{ paddingInline: "50px" }}
                        >
                          Updated as on {dateFormatCommon(val?.asOnDate)}
                        </Typography>
                      </div>
                    </div>
                  );
              })}
            </div>
            {/* <div>
              <Card style={{ marginTop: "20px", background: " linear-gradient(247.79deg, #FE6587 3.21%, #F58D2D 100%)" }} className={classes.smallCard}>
                <CardContent className={classes.cardContent} >
                  <Typography style={{ marginBottom: "10px" }} className={classes.cardTextSmall}>
                    Commercial
                  </Typography>
                  <Typography className={classes.cardTextSmall}>
                    306.96 Lacs
                  </Typography>
                  <Typography className={classes.cardSubTextSmall}>
                    67.9%
                  </Typography>
                </CardContent>
              </Card>
              <div className={classes.subTab}><Typography className={classes.cardBottomText}>Updated as on 12/10/2022</Typography></div>

            </div> 
          </Grid>
        </Grid>
      </div> */}

      <div
        style={{ position: "relative", padding: "20px 39px 43px 39px" }}
        className={classes.mainContent}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            className={`${classes.mainHeading} ${classes.typoInsideAlign}`}
          >
            My Portfolio Performance
          </Typography>

          <div
            style={{
              width: "50%",
              display: "flex",
              justifyContent: " flex-end",
            }}
          >
            <Print
              style={{ border: "1px solid #FFFFFF" }}
              className={classes.iconStyle}
            />
          </div>
        </div>

        <div className="chart_padding" style={{ paddingBottom: "50px" }}>
          <div>
            {/* <LineChart config={{}}></LineChart> */}
            {apexLinedata.length > 0 && (
              <ApexNew
                linedata={apexLinedata}
                title={{ y: "Value" }}
                controller_disabled={true}
                legends_hide={false}
                showOps={{ t: true, title: "Rent" }}
                config={{
                  tooltip: apexTooltip,
                  colors: ["#509FF1", "#F7CB59"],
                }}
              />
            )}
            {apexLinedata.length == 0 && !loading && <DataNotFound />}
          </div>
          <div>
            <BarChart
              tooltip={tooltip}
              config={{ height: "200px" }}
              commonprops={diverging}
            />
          </div>
          {/* <div style={{ height: "80px", overflow: "hidden" }}>
            <GoogleChart></GoogleChart>
          </div> */}
        </div>

        <Grid xs={12} style={{ display: "flex" }}>
          <div
            className="w451"
            style={{
              // width: "660px",
              marginLeft: "auto",
              background: "#220848",
              marginRight: "43px",
              marginTop: "54px",
              boxShadow: "-4px -4px 10px #431D84, 4px 4px 10px #000000",
              paddingRight: "5px",
              borderRadius: "124.46px",
              padding: "5px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              onClick={() => {
                setRange("1M");
                setCustomRange([null, null]);
                // setxval(generateDates(moment().subtract(1, "months"), "days", 3));
                // generate("DAILY",1,moment().subtract(1, 'months').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
                setDataRange({
                  endDate: moment().format("YYYY-MM-DD"),
                  startDate: moment()
                    .subtract(1, "months")
                    .format("YYYY-MM-DD"),
                });
              }}
              style={{ textDecoration: "none", minWidth: "13%" }}
              to="#"
            >
              <div
                className={`${classes.bottomTabHeaderText} ${
                  range === "1M" ? classes.bottomTabActive : ""
                }`}
                style={{ margin: "0px" }}
              >
                1M
              </div>
            </Button>
            <Button
              onClick={() => {
                setRange("3M");
                setCustomRange([null, null]);
                // setxval(generateDates(moment().subtract(3, "months"), "days", 9));
                // generate("DAILY",3,moment().subtract(3, 'months').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
                setDataRange({
                  endDate: moment().format("YYYY-MM-DD"),
                  startDate: moment()
                    .subtract(3, "months")
                    .format("YYYY-MM-DD"),
                });
              }}
              style={{ textDecoration: "none", minWidth: "13%" }}
              to="#"
            >
              <div
                className={`${classes.bottomTabHeaderText} ${
                  range === "3M" ? classes.bottomTabActive : ""
                }`}
                style={{ margin: "0px" }}
              >
                3M
              </div>
            </Button>
            <Button
              onClick={() => {
                setRange("1Y");
                setCustomRange([null, null]);
                // setxval(
                //   generateDates(moment().subtract(1, "years"), "months", 1)
                // );
                // generate("WEEKLY",1,moment().subtract(1, 'years').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
                setDataRange({
                  endDate: moment().format("YYYY-MM-DD"),
                  startDate: moment().subtract(1, "years").format("YYYY-MM-DD"),
                });
              }}
              style={{ textDecoration: "none", minWidth: "13%" }}
              to="#"
            >
              <div
                className={`${classes.bottomTabHeaderText} ${
                  range === "1Y" ? classes.bottomTabActive : ""
                }`}
                style={{ margin: "0px" }}
              >
                1Y
              </div>
            </Button>
            <Button
              onClick={() => {
                setRange("3Y");
                setCustomRange([null, null]);

                // generate("WEEKLY",2,moment().subtract(3, 'years').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
                setDataRange({
                  endDate: moment().format("YYYY-MM-DD"),
                  startDate: moment().subtract(3, "years").format("YYYY-MM-DD"),
                });
              }}
              style={{ textDecoration: "none", minWidth: "13%" }}
              to="#"
            >
              <div
                className={`${classes.bottomTabHeaderText} ${
                  range === "3Y" ? classes.bottomTabActive : ""
                }`}
                style={{ margin: "0px" }}
              >
                3Y
              </div>
            </Button>
            <Button
              onClick={() => {
                setRange("5Y");
                setCustomRange([null, null]);
                // generateDates(moment().subtract(5, "years"), "months", 3);
                // setxval(
                //   generate(
                //     "MONTHLY",
                //     1,
                //     moment().subtract(5, "years").format("YYYY-MM-DD"),
                //     moment().format("YYYY-MM-DD")
                //   )
                // );
                // setxval(
                //   generateDates(moment().subtract(5, "years"), "months", 6)
                // );
                setDataRange({
                  endDate: moment().format("YYYY-MM-DD"),
                  startDate: moment().subtract(5, "years").format("YYYY-MM-DD"),
                });
              }}
              style={{ textDecoration: "none", minWidth: "13%" }}
              to="#"
            >
              <div
                className={`${classes.bottomTabHeaderText} ${
                  range === "5Y" ? classes.bottomTabActive : ""
                }`}
                style={{ margin: "0px" }}
              >
                5Y
              </div>
            </Button>
            <Button
              onClick={() => {
                setRange("ITD");
                setDataRange({});
                setCustomRange([null, null]);
              }}
              style={{ textDecoration: "none", minWidth: "13%" }}
              to="#"
            >
              <div
                className={`${classes.bottomTabHeaderText} ${
                  range === "ITD" ? classes.bottomTabActive : ""
                }`}
                style={{ margin: "0px" }}
              >
                ITD
              </div>
            </Button>
            <Button
              style={{
                textDecoration: "none",
                textTransform: "capitalize",
                minWidth: "13%",
              }}
              to="#"
            >
              <div
                className={`${classes.bottomTabHeaderText} ${
                  range === "custom" ? classes.bottomTabActive : ""
                }`}
                style={{ margin: "0px" }}
              >
                <FilterCalendar
                  values={customRange}
                  minDate={{ min: 5, type: "years" }}
                  maxDate={{
                    current: new Date(moment().format("YYYY-MM-DD")),
                    min: 5,
                    type: "years",
                  }}
                  type="1"
                  hideinput={true}
                  customLabel="Custom"
                  customStyle={{
                    width: "100%",
                    textAlign: "center",
                    borderRadius: "20px",
                    padding: "0px",
                    background: "transparent",
                    border: "none",
                    color: "#FFFFFF",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontFamily: "Avenir",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                  customCalenderPostion={{
                    right: "0px",
                    top: "-240px",
                  }}
                  onchange={(newCustomRange) => {
                    setCustomRange(newCustomRange);
                    setRange("custom");
                    setDataRange({
                      startDate: moment(newCustomRange[0]).format("YYYY-MM-DD"),
                      endDate: moment(newCustomRange[1]).format("YYYY-MM-DD"),
                    });
                  }}
                />
              </div>
            </Button>
          </div>
        </Grid>
      </div>
    </>
  );
}

export default RealEstateComp;
