import React from "react";

function Great({ bottom, right, height, width }) {
  return (
    <span style={{ position: "relative" }}>
      Great !
      <span
        style={{
          position: "absolute",
          height: height || "3.8px",
          width: width || "3.8px",
          background: "yellow",
          borderRadius: "50%",
          bottom: bottom || "5px",
          right: right || "3.5px",
        }}
      ></span>
    </span>
  );
}

export default Great;
