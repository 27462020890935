import { ReactComponent as DashBoardMenuIconGold } from "./assets/goldTheme/dashboardMenuIcon.svg";
import { ReactComponent as DashBoardMenuIconDefault } from "./assets/defaultTheme/dashboardMenuIcon.svg";

import { ReactComponent as UploadsMenuIconGold } from "./assets/goldTheme/uploadsMenuIcon.svg";
import { ReactComponent as UploadsMenuIconDefault } from "./assets/defaultTheme/uploadsMenuIcon.svg";

import { ReactComponent as UserManagementIconGold } from "./assets/goldTheme/usermanagementMenuIcon.svg";
import { ReactComponent as UserManagementIconDefault } from "./assets/defaultTheme/userManagementMenuIcon.svg";
import { ReactComponent as GlobalFilterIconGold } from "./assets/goldTheme/filter.svg";
import { ReactComponent as GlobalFilterIconDefault } from "./assets/defaultTheme/filter.svg";
import { ReactComponent as NotFoundiconDefault } from "./assets/defaultTheme/notFoundicon.svg";
import { ReactComponent as NotFoundiconGold } from "./assets/goldTheme/notFoundicon.svg";
import { ReactComponent as UploadFileDefault } from "./assets/defaultTheme/uploadFile.svg";
import { ReactComponent as UploadFileGold } from "./assets/goldTheme/uploadFile.svg";
import { ReactComponent as BackIconGold } from "./assets/goldTheme/backIcon.svg";
import { ReactComponent as BackIconDefault } from "./assets/defaultTheme/backIcon.svg";
import { ReactComponent as BulbIconDefault } from "./assets/defaultTheme/bulbIcon.svg";
import { ReactComponent as BulbIconGold } from "./assets/goldTheme/bulbIcon.svg";
import { ReactComponent as TableEditIconGold } from "./assets/goldTheme/tableEditIcon.svg";
import { ReactComponent as TableEditIconDefault } from "./assets/defaultTheme/tableEditIcon.svg";
export const defaultThemeIcons = {
  dashboardMenuIcon: (props) => <DashBoardMenuIconDefault {...props} />,
  uploadsMenuIcon: (props) => <UploadsMenuIconDefault {...props} />,
  userManagementMenuIcon: (props) => <UserManagementIconDefault {...props} />,
  globalFilterIcon: (props) => <GlobalFilterIconDefault {...props} />,
  backIcon: (props) => <BackIconDefault {...props} />,
  notFoundicon: (props) => <NotFoundiconDefault {...props} />,
  uploadFile: (props) => <UploadFileDefault {...props} />,
  bulbIcon: (props) => <BulbIconDefault {...props} />,
  tableEditIcon: (props) => <TableEditIconDefault {...props} />,
};

export const goldThemeIcons = {
  dashboardMenuIcon: (props) => <DashBoardMenuIconGold {...props} />,
  uploadsMenuIcon: (props) => <UploadsMenuIconGold {...props} />,
  userManagementMenuIcon: (props) => <UserManagementIconGold {...props} />,
  globalFilterIcon: (props) => <GlobalFilterIconGold {...props} />,
  backIcon: (props) => <BackIconGold {...props} />,
  notFoundicon: (props) => <NotFoundiconGold {...props} />,
  uploadFile: (props) => <UploadFileGold {...props} />,
  bulbIcon: (props) => <BulbIconGold {...props} />,
  tableEditIcon: (props) => <TableEditIconGold {...props} />,
};
