import { getClientMemberDetail } from "../api";
import { useState, useEffect } from "react";
export const usePartnerStatus = (id, fn) => {
  const [partnerData, setPartnerData] = useState({});
  useEffect(() => {
    fn(id)
      .then((res) => {
        console.log("getPartnerDetails", res?.data?.data?.result);
        setPartnerData(res?.data?.data?.result);
      })
      .catch((err) => {
        console.log(`An error ${err} has occurrred`);
      });
  }, []);
  return { partnerData };
};
