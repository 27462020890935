import React from "react";
import Default from "../components/dashboard/defaultPages/Default";
import REITSSummaryComp from "../components/dashboard/products/REITS/REITSSummaryComp";

function REITSSummary() {
  return (
    <>
      <Default>
        <REITSSummaryComp />
      </Default>
    </>
  );
}

export default REITSSummary;
