import { useEffect, useState } from "react";
import Step3Table from "../common/Step3Table";
import {
  allocationTableStaticData,
  benchmarkTableHead,
  step3tableStaticData,
  step3tableStaticDataUniqueIndex,
} from "../constand";
import useGetStrategyTable from "../hooks/useGetStrategyTable";
import { OnboardingContext } from "../../../../Context";
import {
  handleOnLoadData,
  handlePayloadCreateStrategy,
  objectFomatingStep3table,
  strategyBuilderTopNavData,
} from "../common/utils/utils";
import { useDebounce } from "use-debounce";
import moment from "moment";
import PopoverComp from "../common/PopoverComp";
import EditBtn from "../common/EditBtn";
import PageLoader from "../../products/common/pageLoader";
import {
  createStrategyBuildersAPI,
  fetchAssetBenchMark,
} from "../../../../api";
import TableTopComp from "../common/TableTopComp";
import CustomizedSnackbars from "../../../common/CustomizedSnackbars";

function Benchmark({ handleBack, memberNames, handleNext, rowData, type }) {
  const [tableData, setTableData] = useState([]);
  const [header, setHeader] = useState([]);
  const [rawtableData, setRawTableData] = useState([]);
  const [debouncedValue] = useDebounce(rawtableData, 500);
  const [benchmarkList, step3BenchmarkList] = useState({});
  const [anchorEl, setAnchorEl1] = useState(null);
  const [position, setPosition] = useState(0);
  const [currentBenchMarkList, setCurrentBenchMarkList] = useState([]);
  const [portFolioName, setProtFolioName] = useState("");
  const [table, setTable] = useState([]);
  const [error, setError] = useState(false);
  const [assetBenchMarkList, setAssetBenchMarkList] = useState([]);
  const [postApiLoading, setPostApiLoading] = useState(false);
  // const [memberNames, setMemberNames] = useState(null);
  const {
    getIndexExpectedReturn,
    creatStrategy,
    loading,
    getIndexList,
    updateStrategy,
    getAllocationData,
  } = useGetStrategyTable();

  const { userState, dispatch } = OnboardingContext();

  const handleClick = (e, data, position) => {
    setAnchorEl1(e.currentTarget);
    position = position;
    setPosition(() => {
      setCurrentBenchMarkList(() => {
        return benchmarkList[data?.assetClass];
      });
      return position;
    });
  };

  // Hadnler of table input for allocation
  const handleInputChange = (e, index) => {
    setRawTableData((prev) => {
      let newList = [...prev];
      newList[index].allocationPercentage = String(e?.target?.value)?.replace(
        "%",
        ""
      );
      return newList;
    });
  };

  const columnClickConfig = {
    benchmarkIndex: handleClick,
    allocationPercentage: handleInputChange,
  };

  const handleData = (table, header) => {
    let newList = [];
    table.map((val, i) => {
      newList.push(objectFomatingStep3table(val, header, i, columnClickConfig));
    });
    setTableData(() => newList);
  };

  //to get index list of all asset class
  const getAllIndexListOfAssetClass = async () => {
    ["equity", "debt", "cash", "alternate"].map(async (assetClass) => {
      let params = {};
      if (assetClass === "equity") {
        params.assetClass = "equity";
      }
      if (assetClass === "debt") {
        params.assetClass = "debt";
      }
      if (assetClass === "cash") {
        params.assetClass = "debt";
        params.subAssetClass = "liquid";
      }
      await getIndexList({
        setter: step3BenchmarkList,
        params: params,
        assetClass: assetClass,
      });
    });
  };

  let tMinusOneEndDate = moment().subtract(1, "days").format("YYYY-MM-DD");

  // API to Populate return index data on table data
  const returnIndex = async (data) => {
    await data?.allocationsBreakUp?.map((val, i) => {
      getIndexExpectedReturn({
        params: {
          indexId: val?.benchmarkIndex?.indexId,
          endDate: tMinusOneEndDate,
        },
      })
        .then(async (res) => {
          setRawTableData((prev) => {
            let index = prev.findIndex(
              (val2) => val2?.assetClass == val.assetClass
            );

            if (index !== -1) {
              prev[index].expectedReturns = res[0]?.averageBr;
            }

            return [...prev];
          });
        })
        .catch((error) => {});
    });
  };

  const handleOnLoadState = async () => {
    getAllIndexListOfAssetClass();
    setHeader(() => benchmarkTableHead);
    // await getAllocationData({
    //   clientId: userState?.loginedUserData?.id,
    //   type: "dashboard",
    //   filters: {
    //     memberId: memberNames?.map((val) => val?.member_id).join(","),
    //     // Include endDate in filters only when the type is "UniqueClientIndex"
    //     // ...(type === "UniqueClientIndex" && { endDate: tMinusOneEndDate }),
    //   },
    // })
    let params = { clientId: userState?.loginedUserData?.id };
    fetchAssetBenchMark({ params })
      .then((benchmarkResponse) => {
        const fetchedBenchmarks =
          benchmarkResponse?.data?.data?.result?.assetBenchmarkMapping;

        let newList = handleOnLoadData({
          allocationData: allocationTableStaticData,
          staticData: rowData?.allocationsBreakUp
            ? rowData?.allocationsBreakUp
            : // Setting the initial state for benchmark excess performance
              step3tableStaticData.map((staticItem) => {
                const apiItem = fetchedBenchmarks?.find(
                  (item) => item.assetClass === staticItem.assetClass
                );
                return {
                  ...staticItem,
                  benchmarkIndex: apiItem
                    ? apiItem?.benchmarkIndex
                    : staticItem?.benchmarkIndex,
                };
              }),
          type: type,
        });

        // console.log(newList, "aljbagkabkh");

        setRawTableData((prev) => {
          return newList;
        });
      })
      .catch((benchmarkError) => {
        console.log("benchmarkError", benchmarkError);
      });

    if (rowData) {
      setProtFolioName(() => rowData?.name);
      if (type !== "UniqueClientIndex") {
        // 🟡 Uncomment for returnIndex
        // returnIndex(rowData);
      }
    }
  };

  useEffect(() => {
    handleOnLoadState();
  }, []);

  useEffect(() => {
    if (header?.length > 0 && debouncedValue?.length > 0)
      handleData(debouncedValue, header);
  }, [debouncedValue]);

  // To close drop down in table
  const handleClose = () => {
    setAnchorEl1(null);
    setPosition(0);
  };

  // Handler of onCLick event of drop down in table
  async function handleClickItem(data) {
    let res;

    if (type !== "UniqueClientIndex") {
      // 🟡 Uncomment for returnIndex
      // res = await getIndexExpectedReturn({
      //   params: {
      //     indexId: data?.sectionId,
      //     endDate: tMinusOneEndDate,
      //   },
      // });
    }

    setRawTableData((prev) => {
      let newList = [...prev];
      newList[position].benchmarkIndex = {
        name: data?.indexName,
        indexId: data?.sectionId,
      };
      if (type !== "UniqueClientIndex") {
        // 🟡 Uncomment for returnIndex
        // newList[position].expectedReturns = res[0]?.averageBr;
      }
      return newList;
    });
    handleClose();
  }

  // Update and Crate API
  const handleCreate = async (isBenchmark) => {
    try {
      let { error, payload } = handlePayloadCreateStrategy({
        allocationData: [...rawtableData],
        portFolioName: portFolioName,
        memberNames: memberNames,
      });

      if (Object.keys(error).length > 0) {
        setError(() => error);
        throw error;
      }

      let payLoad = {
        strategyType: memberNames[0]?.strategyType,
        // clientId: 267,
        clientId: userState?.loginedUserData?.id,
        type: type ? type : "TargetPortfolio",
        ...payload,
      };
      await creatStrategy({ params: { ...payLoad }, isBenchmark });

      setRawTableData(() => []);
      handleNext();

      // console.log(res, "clicked123");
    } catch (error) {
      console.log(error, "clicked123");
    }
  };
  return (
    <>
      <CustomizedSnackbars />
      <div style={{ marginTop: "50px" }}>
        <TableTopComp
          page={"benchmark"}
          data={strategyBuilderTopNavData}
          back={-1}
        />
        <Step3Table header={header} tableData={tableData} />
        <PopoverComp
          anchorEl={anchorEl}
          handleClose={handleClose}
          data={currentBenchMarkList}
          handleClickItem={handleClickItem}
          open={anchorEl != null ? true : false}
          customItemKey={"indexName"}
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            border: "none",
            outline: "none",
          }}
        >
          <EditBtn
            title="Add Benchmark"
            customClass={"step3ContinueBtn"}
            // hanldleClick={() => handleCreate(true)}
            hanldleClick={() => {
              let params = {
                clientId: userState?.loginedUserData?.id,
                assetBenchmarkMapping: rawtableData?.map(
                  ({ assetClass, benchmarkIndex }) => ({
                    assetClass,
                    benchmarkIndex,
                  })
                ),
              };
              setPostApiLoading(true);
              createStrategyBuildersAPI(params, true)
                .then((res) => {
                  if (res.status === 200) {
                    dispatch({
                      type: "UPDATE_SNACKBAR_DATA",
                      payload: {
                        success: true,
                        error: false,
                        showMsg: true,
                        message: res?.data?.message,
                      },
                    });
                  }
                })
                .catch((err) => {
                  console.error("Error creating strategy", err);
                  dispatch({
                    type: "UPDATE_SNACKBAR_DATA",
                    payload: {
                      success: false,
                      error: true,
                      showMsg: true,
                      message: err?.message,
                    },
                  });
                })
                .finally(() => {
                  setPostApiLoading(false);
                });
            }}
          />
        </div>
      </div>
      <PageLoader
        showLoader={loading ? loading : tableData?.length > 0 ? false : true}
      />
      <PageLoader showLoader={postApiLoading ? true : false} />
    </>
  );
}

export default Benchmark;
