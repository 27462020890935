import React, { useState } from "react";
import { BackdropComponent } from "../common/BackDropComponent/BackDropComponent";
import ClientStep1 from "./ClientStep1";
import { useNavigate, useLocation } from "react-router-dom";
import ClientStep2 from "./ClientStep2";
import ClientStep3 from "./ClientStep3";
import PartnerOnboarding3 from "../PartnerOnboarding3";
import PartnerSuccess from "../PartnerSuccess";
import SetPin from "../SetPin";
import { usePanVerification } from "../hooks/usePanVerification";
import useClientOnboardFlow from "../hooks/useClientOnboardFlow";
import { ClientMemberRolesDIY } from "../../../Constants";

function ClientNewSignUp() {
  const { state } = useLocation();
  const [open, setOpen] = useState(false);
  const [next, setNext] = useState(state?.step ? state?.step : 0); //starts form 0 only state?.step ? state?.step :
  //const [next, setNext] = useState(3);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(
    state?.formData ? state?.formData : {}
  );
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const totalStep = 5;
  const handleBack = (step) => {
    if (step >= 1) setNext(step);
    if (step == 0) {
      //   setNext(step);
      navigate("/register");
      return;
    }
    handleNext(-1);
  };
  const navigate = useNavigate();

  const handleNext = (stepAddition, formData1) => {
    setNext(next + stepAddition);
    navigate("/register/client", {
      state: {
        step: next + stepAddition,
        formData: formData1 ? formData1 : formData,
      },
    });
    setApiErrorMessage("");
  };

  const handleErrorMessage = (error) => {
    if (error?.message) setApiErrorMessage(error?.message);
    else setApiErrorMessage("Something Went Wrong");
  };

  const { api1, api2, api3 } = useClientOnboardFlow({
    handleErrorMessage,
    handleNext,
    setFormData,
    setLoading,
  });

  // console.log(formData, "agljj");

  const changeInStep = (next) => {
    switch (next) {
      case 1:
        return (
          <>
            <ClientStep1
              currentStep={next}
              handleBack={handleBack}
              handleNext={handleNext}
              customHandleSubmit={api1}
              apiErrorMessage={apiErrorMessage}
              loading={loading}
              formData={formData}
              totalStep={totalStep}
              head={{
                desc: {
                  center: [
                    {
                      title: "Who is a Client?",
                      description: (
                        <>
                          A client refers to any distinguished individual or
                          family.
                          <br></br>
                          <br></br>
                          This platform offers tailored financial solutions,
                          data consolidation, and sophisticated insights to help
                          clients efficiently oversee their investments and
                          goals with utmost clarity and precision.
                        </>
                      ),
                    },
                  ],
                },
              }}
            />
          </>
        );
      case 2:
        return (
          <>
            <ClientStep2
              userRoleConfig={ClientMemberRolesDIY}
              currentStep={next}
              handleBack={handleBack}
              handleNext={handleNext}
              customHandleSubmit={api2}
              apiErrorMessage={apiErrorMessage}
              loading={loading}
              formData={formData}
              totalStep={totalStep}
              head={{
                desc: {
                  center: [
                    {
                      title: "Who is the Head of the Family?",
                      description:
                        "The Head of the Family assumes a central role and is considered to be the principle decision maker and custodian of the family's wealth and legacy.",
                    },
                  ],
                },
              }}
            />
          </>
        );
      case 3:
        return (
          <>
            <ClientStep3
              currentStep={next}
              handleBack={handleBack}
              handleNext={handleNext}
              apiErrorMessage={apiErrorMessage}
              customHandleSubmit={(data) => {
                setFormData((prev) => {
                  return {
                    ...prev,
                    ...data,
                  };
                });
                setOpen(true);
              }}
              loading={loading}
              formData={formData}
              totalStep={totalStep}
              customLeftMargin={"18px"}
              head={{
                desc: {
                  center: [
                    {
                      title: "What is the role of the Head of the Family?",
                      description:
                        "The head of the Family guides the overarching vision and ensures the seamless coordination of wealth management, estate planning and intergenerational aspirations.",
                    },
                  ],
                },
              }}
            />
          </>
        );
      case 4:
        return (
          <PartnerOnboarding3
            key={next}
            currentStep={next}
            handleBack={handleBack}
            handleNext={handleNext}
            title="Head of Family  Email Verification"
            subTitle="We have sent an OTP on your email ID"
            comType="email"
            userId={formData?.user?.id}
            entityType="client-user"
            resendEntityType="member"
            totalStep={totalStep}
            head={{
              desc: {
                center: [
                  {
                    title: "Why Email Verification ?",
                    description:
                      "Email verification is crucial for ensuring the accuracy and security of user information. Its a vital step in maintaining data integrity and building trust within our platform.",
                  },
                ],
              },
            }}
          />
        );
      case 5:
        return (
          <PartnerOnboarding3
            key={next}
            currentStep={next}
            handleBack={handleBack}
            handleNext={handleNext}
            title="Mobile Verification"
            subTitle="We have sent an OTP on your Mobile no"
            comType="sms"
            userId={formData?.user?.id}
            entityType="client-user"
            resendEntityType="member"
            totalStep={totalStep}
            head={{
              desc: {
                top: [
                  {
                    title: "Why Mobile Verification ?",
                    description:
                      "Mobile verification is essential to establish a secure ad reliable user experience.",
                  },
                ],
              },
            }}
          />
        );
      case 6:
        return (
          <PartnerSuccess
            subData={
              "Your Email & Mobile Number has been verified. Click on continue to set pin. "
            }
            noStep={true}
            handleBack={handleBack}
            handleNext={() => {
              handleNext(1);
            }}
            buttonTxt={"Continue"}
          />
        );
      case 7:
        return (
          <SetPin
            handleBack={handleBack}
            handlenext={handleNext}
            userId={formData?.user?.id}
            userType={"client"}
          />
        );
      case 8:
        return (
          <PartnerSuccess
            handleBack={handleBack}
            subData="Congratulation Onboard Process Is Completed. Please Continue To Login"
            buttonTxt="Go To Login Page"
            noStep={true}
            handleNext={() => {
              navigate("/");
            }}
          />
        );
      default:
        return navigate("/");
    }
  };

  return (
    <>
      <BackdropComponent
        customSetOpen={setOpen}
        customOpen={open}
        customHandleAccept={() => {
          api3(formData);
        }}
      />
      {changeInStep(next)}
    </>
  );
}

export default ClientNewSignUp;
