import React from "react";

export const CheckedSvgIcon = ({ cssClassName }) => {
  return (
    <svg
      className={cssClassName}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <circle cx="10" cy="10" r="9.5" stroke="#6A77AB" />
      <path
        d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z"
        fill="#55A47E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.02437 11.1577L13.3382 6.93849C13.4674 6.83678 13.6238 6.77552 13.7878 6.76242C13.9517 6.74932 14.1159 6.78495 14.2596 6.86484C14.4034 6.94473 14.5203 7.06531 14.5957 7.21145C14.6712 7.35759 14.7017 7.52276 14.6836 7.68622C14.6714 7.79502 14.6378 7.90033 14.5847 7.99607C14.5316 8.09182 14.46 8.17611 14.3742 8.24409L8.33847 13.0352L8.27987 13.0821C8.25571 13.099 8.23067 13.1146 8.20486 13.1289L8.17204 13.15C7.9815 13.2485 7.76031 13.2695 7.55461 13.2088C7.34892 13.1481 7.1746 13.0103 7.06803 12.8242L6.97427 12.6672L5.4249 9.97394C5.37057 9.87944 5.33539 9.77517 5.32136 9.66707C5.30733 9.55897 5.31473 9.44917 5.34313 9.34393C5.37153 9.2387 5.42039 9.14008 5.4869 9.05373C5.55342 8.96737 5.63629 8.89496 5.73079 8.84063C5.92164 8.73091 6.14826 8.70149 6.3608 8.75886C6.46604 8.78726 6.56465 8.83612 6.65101 8.90263C6.73737 8.96915 6.80978 9.05202 6.86411 9.14652L8.02672 11.1577H8.02437Z"
        fill="white"
      />
    </svg>
  );
};
