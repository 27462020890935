// var CryptoJS = require("crypto-js");
import React, { useRef } from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

import Disclaimer from "./pages/Disclaimer";
import { useApi } from "./hook/useApi";
import { useEffect } from "react";

import { useLocation, useSearchParams, useParams } from "react-router-dom";

import { useState } from "react";
import PageLoader from "../products/common/pageLoader";

import useWindowSize from "./hook/seWindowSize";

import useGetMongoPrintReport from "../../../hooks/useGetMongoPrintReport";

import ThankYouPage from "./pages/ThankYouPdf";

import LoadingScreen from "./pages/LoadingScreen";

import { CoverPage } from "./pages/subPrintPages/CoverPage";
import Annexures from "./pages/Annexures";
import { OnboardingContext } from "../../../Context";
// import Tax from "./pages/Tax";
// import TaxModule from "./pages/TaxModule";
import TaxSort from "./pages/TaxSort";
import PmsUnderlyingModule from "./pages/PmsUnderlyingModule";

export default function PmsUnderlyingReport({
  mongo,
  handleUploadPDF,
  download,
}) {
  const { dispatch } = OnboardingContext();
  const [browserSize, setBrowserSize] = useState(
    ((window.outerWidth - 10) / window.innerWidth) * 100
  );
  // const {
  //   state: { mongoId },
  // } = useLocation();
  // const {} = useSearchParams();
  let [searchParams, setSearchParams] = useSearchParams();
  // let denomination = "Lakhs";
  const mongoId = searchParams.get("id");
  const parentRef = useRef(null);
  // const { type, familyMembers, duration, denomination, mongoId } = Decrypt(
  //   searchParams.get("key")
  // );
  const windowSize = useWindowSize();
  const [changes, setChanges] = useState();
  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "#E4E4E4",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
  });
  //
  let payload = {};
  // if (familyMembers?.length > 0) {
  //   payload["memberId"] = familyMembers?.join(",");
  // }
  // if (duration?.startDate !== "") {
  //   payload["startDate"] = duration?.startDate;
  //   payload["endDate"] = duration?.endDate;
  // }
  // localStorage.setItem("denomination", denomination);
  const [data, setData] = useState({});
  const [reset, setReset] = useState(false);
  const {
    showLoader,
    setShowLoader,
    CallApis,
    errorMessage,
    customData,
    erroredAPIS,
    apiArray,
  } = useApi(payload, setReset);

  const { newData, denomination, meta, pdfInfo, loading } =
    useGetMongoPrintReport(
      // "658187af8a5d24635c5e0298"
      // // "65e00dea0cda3c000851f632"
      // "65e15f4b2fffc9727f32cb17"
      // "65e711971cd0cd00098d8f5e"

      // "65e8538e99eb3863be6ae12e"
      // "65f2a702ae19f10008f6aeb2"

      mongoId || mongo
    );
  // mongoId

  const getDenomination = localStorage.getItem("denomination");

  // useEffect(() => {
  //   // handleData();
  //   // if (getDenomination == denomination) {
  //   // setTimeout(() => {
  //   //   console.log(getDenomination, "asfjkbakjsbahf");
  //   CallApis(apiArray(payload).splice(0, 5), {}, 0, 3);
  //   // }, 5000);
  //   // }
  // }, []);

  // useEffect(() => {
  //   setData(() => customData);
  // }, [customData]);

  useEffect(() => {
    //for testing
    // Object?.keys(newData).map((a) => {
    //   newData[a] = "error";
    // });
    // if (changes) {

    if (browserSize < 99) {
      dispatch({
        type: "UPDATE_SNACKBAR_DATA",
        payload: {
          success: true,
          error: false,
          showMsg: true,
          message:
            "Please set Browser Zoom to 100% for better viewing of report",
        },
      });
    }
    let a = parentRef?.current?.querySelectorAll(".printPageNumber");
    if (!a) {
      return;
    }
    Array.from(a)?.map((val, i) => {
      val.innerHTML = `${i + 1}`;
    });
    // setNewPageList(a);
    // }

    const getData = setTimeout(() => {
      if (Object.keys(newData).length > 0 && download == 0) {
        handleUploadPDF();
      }
    }, 5000);

    return () => {
      let a = parentRef?.current?.querySelectorAll(".printPageNumber");
      if (!a) {
        return;
      }
      Array.from(a)?.map((val, i) => {
        val.innerHTML = `${i + 1}`;
      });
      clearTimeout(getData);
    };
  }, [changes, data, newData]);

  // console.log(Object?.keys(newData), "aglnj");

  return (
    <>
      {loading && (
        <>
          {/* <FirstPage type={"print"} pdfInfo={pdfInfo} /> */}
          <CoverPage
            type={"pdf"}
            title={"PMS Underlying Transaction"}
            meat={meta}
            pdfInfo={pdfInfo}
          />
          <LoadingScreen loading={loading} />
        </>
      )}
      {Object?.keys(newData)?.length > 0 && (
        <Document ref={parentRef}>
          <CoverPage
            type={"pdf"}
            title={"PMS Underlying Transaction"}
            meat={meta}
            pdfInfo={pdfInfo}
          />
          <PmsUnderlyingModule
            denomination={denomination}
            newdata={newData}
            pdfInfo={pdfInfo}
            setChanges={setChanges}
          />

          <ThankYouPage pdfInfo={pdfInfo} />
          <Annexures pdfInfo={pdfInfo} />
          <Disclaimer pdfInfo={pdfInfo} />
          {/* <Page size="A4">
            <Disclaimer />
          </Page> */}
          {/* <Page size="A4">
            <LastPage type={"pdf"} />
          </Page> */}
        </Document>
      )}
    </>
  );
}
