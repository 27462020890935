import React, { useEffect, useState } from "react";
import PartnerHeading from "../NewSignUp/PartnerHeading";
import "./client.css";
import {
  bankerMappingData,
  bankerMappingHeader,
  bodyData4,
  headerDataPartner3,
  headerDataPartner4,
  bodyData6,
} from "../user_management/utils/constands";
import ClientMappingTableWrapper from "./ClientMappingTableWrapper";
import AddButton from "../common/AddButton";
import { MainCard } from "../common/HoldingSummaryCards/MainCard";
import {
  ClientCardContent,
  MapWithCardContent,
} from "./clientMapping/ClientMapping";
import { useClientMappingStyles } from "./clientMapping/ClientMappingStyles";
import {
  Typography,
  Chip,
  Box,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";
import userImg from "../../assets/clientdashboard/user.png";
import NewHeadAndDetailsComp from "../dashboard/products/newDesigns/common/newHeadingAndDetailsComp/NewHeadAndDetailsComp";
import { HorizontalDivider } from "../user_management/HorizontalDivider";
import useGetRepResentative from "../../hooks/useGetRepResentative";
import PageLoader from "../dashboard/products/common/pageLoader";
import useMapClientRep from "../../hooks/useMapClientRep";
import { useLocation, useNavigate } from "react-router-dom";
import { AlertDialog } from "../dashboard/common/AlertDialog";
import { capitalize } from "lodash";

function NewClientMaping() {
  const classes = useClientMappingStyles();
  const [selectedCard, setSelectedCard] = useState("Banker");
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [tempSate, setTempState] = useState(null);
  // const [selectedBanker, setSelectedBanker] = useState({});
  const { state } = useLocation();
  const naavigate = useNavigate();
  // console.log(state, "agljgj");

  const { data, loading, total } = useGetRepResentative({
    parnerId: state?.data?.data?.partner_id,
    // parnerId: 173,
    entity: selectedCard.toLowerCase(),
    offset,
    search,
  });
  const { loading1, mapClient } = useMapClientRep();
  const handleApprove = () => {
    mapClient({
      payload: {
        partnerRepresentativeId: tempSate?.partner_entity_id,
        clientId: state?.data?.data?.id,
      },
    });
  };
  const handleMapClick = (data) => {
    // console.log(
    //   data,
    //   { partnerRepresentativeId: data?.partner_entity_id, clientId: state.data?.data?.id },
    //   "akagbj"
    // );
    setTempState(data);

    setOpen(true);
  };

  return (
    <div className="mapingContainer">
      <AlertDialog
        open={open}
        setOpen={setOpen}
        onApprove={handleApprove}
        data={state}
        dialogTitle={() => (
          <DialogTitle id="alert-dialog-title">
            <Typography
              style={{
                color: "#fff",
                fontFamily: "Avenir",
                fontSize: "39.6px",
                fontStyle: "normal",
                fontWeight: 800,
                lineHeight: "35.2px",
                textAlign: "center",
              }}
            >
              Confirmation
            </Typography>
          </DialogTitle>
        )}
        dialogContent={() => (
          <DialogContentText
            id="alert-dialog-description"
            style={{
              color: "#fff",
              textAlign: "center",
              fontFamily: "Avenir",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "22px",
            }}
          >
            Are you sure you want to map - {capitalize(tempSate?.partner_role)}{" "}
            Name: <b>{tempSate?.name}</b> to the respective client
          </DialogContentText>
        )}
      />

      <div className="mapingTopSec">
        <PartnerHeading
          mainHead={"Client Mapping"}
          handleBack={() => naavigate(-1)}
          noStep={true}
        />
      </div>
      {/* CARDS */}

      <Box className={classes.cardsAlignment}>
        <MainCard className={classes.defaultCard}>
          <ClientCardContent
            clientName={
              <Typography
                className={`${classes.defaultCardText} truncate`}
                style={{
                  lineHeight: "20px",
                  paddingBottom: "5px",
                  width: "145px",
                }}
                title={state?.data?.data?.full_name}
              >
                {state?.data?.data?.full_name}
              </Typography>
            }
            userImg={userImg}
            chip={<Chip className={classes.cardChip} label="Client Name" />}
          />
        </MainCard>
        {/* <MainCard className={classes.defaultCard}>
          <ClientCardContent
            clientName={
              <Typography className={classes.defaultCardText}>
                Ambani
              </Typography>
            }
            userImg={userImg}
            chip={<Chip className={classes.cardChip} label="Head Of Family" />}
          />
        </MainCard> */}
        <MainCard className={classes.addCard}>
          <ClientCardContent
            clientName={
              <Typography className={classes.addCardText}>
                Map With : {state?.data?.data?.partner_name}
              </Typography>
            }
            // userImg={userImg}
            chip={
              <Typography className={classes.addCardSubText}>
                The User Management screen provides administrators with a
                comprehensive view
              </Typography>
            }
          />
        </MainCard>
      </Box>
      {/* Black Box sec */}
      <div className="mapingContainerTable">
        <NewHeadAndDetailsComp
          customStyle={{
            container: { marginTop: "0px", width: "494px" },
            title: "mapingTitle",
          }}
          title={"Select User Role"}
          content={
            "The User Management screen provides administrators with a comprehensive view and control over user accounts and their associated settings."
          }
        />
        {/* MAP WITH CARD */}
        <Box className={classes.cardsAlignment}>
          <MainCard
            className={
              selectedCard === "Banker"
                ? classes.mapWithCardSelected
                : classes.mapWithCard
            }
            onClick={() => setSelectedCard("Banker")}
          >
            <MapWithCardContent
              text={
                <Typography className={classes.mapWithCardText}>
                  Banker
                </Typography>
              }
              subText={
                <Typography className={classes.mapWithCardSubText}>
                  The User Management screen provides administrators
                </Typography>
              }
            />
          </MainCard>
          <MainCard
            className={
              selectedCard === "Partner"
                ? classes.mapWithCardSelected
                : classes.mapWithCard
            }
            onClick={() => setSelectedCard("Partner")}
          >
            <MapWithCardContent
              text={
                <Typography className={classes.mapWithCardText}>
                  Partner Representative
                </Typography>
              }
              subText={
                <Typography className={classes.mapWithCardSubText}>
                  The User Management screen provides administrators
                </Typography>
              }
            />
          </MainCard>
        </Box>
        {/* Divider Line */}
        <HorizontalDivider
          style={{ padding: "0px", marginTop: "40px" }}
          dividerText={
            <div class="title" className={classes.dividerText}>
              Select Internal Partner ({selectedCard})
            </div>
          }
        />
        {/* Table */}

        {/* {tableHeader?.length > 0 && ( */}
        <ClientMappingTableWrapper
          customHeader={headerDataPartner3}
          data={data}
          // selectedBanker={selectedBanker}
          handleMapClick={handleMapClick}
          setItemOffset={setOffset}
          pageCount={Math.ceil(total / 5)}
          setSearch={setSearch}
        />
        <PageLoader showLoader={loading || loading1} />
        {/* )} */}
      </div>
    </div>
  );
}

export default NewClientMaping;
