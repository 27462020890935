import React from "react";
import Default from "../components/dashboard/defaultPages/Default";
import MfoReport from "../components/dashboard/products/mfo/MfoReport";
import { useParams } from "react-router-dom";

function MfoSummaryDetails() {
  const { pageName } = useParams();

  return (
    <>
      <Default>
        <MfoReport pageName={pageName}></MfoReport>
      </Default>
    </>
  );
}

export default MfoSummaryDetails;
