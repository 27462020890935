import React from "react";
import Default from "../components/dashboard/defaultPages/Default";
import BondsComp from "../components/dashboard/products/bonds/BondsComp";
function Bonds() {
  return (
    <>
      <Default>
        <BondsComp></BondsComp>
      </Default>
    </>
  );
}

export default Bonds;
