import React from "react";
import CustomInput from "./CustomInput";
import { InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

function FullWidthInput({ customWrapperStyle, customInputStyle, setSearch }) {
  return (
    <div style={{ marginBottom: "27px", width: "auto", ...customWrapperStyle }}>
      <CustomInput
        handleChange={setSearch}
        customStyle={{
          inputBgColor: "rgba(33, 22, 62, 1)",
          border: "none",
          inputPadding: "16px 2px !important",
        }}
        fullWidth
        style={{
          background: "var(--searchBackground) !important",
          border: "none",
          ...customInputStyle,
        }}
        label={"Search"}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className="inputAdornmentStyle">
              <SearchIcon style={{ opacity: "0.5" }} />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}

export default FullWidthInput;
