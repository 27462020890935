import React, { useCallback, useEffect, useRef } from "react";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";

import useStyles from "../clientDashboard/Clientdashstyle";
import SearchHeader from "../../verificationUpload/SearchHeader";
import MemberProfile from "./MemberProfile";
import { ReactComponent as Crown } from "../../../assets/icons/crown.svg";
import { ReactComponent as PageBack } from "../../../assets/holding-summary/back-blue.svg";
import { ReactComponent as CalenderDate } from "../../../assets/icons/calenderdate.svg";
import { ReactComponent as Marketuparrow } from "../../../assets/clientdashboard/marketuparrow.svg";
import { ReactComponent as Crownhead } from "../../../assets/clientdashboard/crown.svg";
import { ReactComponent as Moveicon } from "../../../assets/clientdashboard/moveicon.svg";
import { ReactComponent as Marketdownarrow } from "../../../assets/clientdashboard/marketdownarrow.svg";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Back } from "../../../assets/holding-summary/back-blue.svg";
import { useAllMembersConsolidated } from "../../../hooks/useAllMembersConsolidated";
import { firstLetterCapitalize } from "../../utils/strHelper";
import {
  roundNumber,
  amountWithSigns,
  getFirsLetterFromMemberName,
  getlastnameMemberName,
} from "../../utils/common";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { currencyFormat } from "../../../hooks/constantFunction";
import { DriveEtaOutlined } from "@material-ui/icons";
import Loader from "../../loader/Loader";
import { Calendar } from "react-calendar";
import moment from "moment";
import { useState } from "react";
import { dateFormatCommon } from "../../utils/common";
import PageLoader from "../products/common/pageLoader";
import SearchInput from "../products/common/SearchInput";
import DataNotFound from "../products/common/DataNotFound";
import CrownHead from "../common/CrownHead";
function AllClientMembers() {
  const navigate = useNavigate();
  const [scripName, setScripName] = useState(null);
  const [calendarVisible, setCalendarVisible] = useState(false);
  let currentDate = new Date()?.toISOString()?.slice(0, 10);
  const date = moment().format("DD-MMM-YYYY");
  const formattedDate = date;
  const [endDate, setEndDate] = React.useState(formattedDate);
  const classes = useStyles();
  const [membersArray, setMembesArray] = useState([]);
  const [memberNameTest, setMemberName] = useState("");
  const handleClick = () => {
    navigate("/home/member/detail");
  };
  const [headOfFamily, loading] = useAllMembersConsolidated(
    "consolidated",
    // "09 Feb 2022"
    endDate,
    scripName
  );

  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setCalendarVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  console.log("head of family", headOfFamily);
  const familyHead = headOfFamily?.find((item) => item.isFamilyHead === true);

  const totalFamilyMembers = headOfFamily?.length;
  useEffect(() => {
    let members = headOfFamily?.filter((item) => item.isFamilyHead === false);
    setMembesArray(members);
  }, [headOfFamily]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  // const {
  //   changePercentage,
  //   changeValueAmount,
  //   clientName,
  //   investedAmount,
  //   isFamilyHead,
  //   memberCount,
  //   memberId,
  //   memberName,
  //   memberType,
  //   portfolioValueAmount,
  // } = familyHead;

  // const {
  //   changePercentage,
  //   changeValueAmount,
  //   clientName,
  //   investedAmount,
  //   memberName,
  //   portfolioValueAmount,
  //   totalFamilyMembers,
  // } = headOfFamily || {};
  // const { members: membersArray } = headOfFamily || [];
  const getItemStyle = (isDragging = false, draggableStyle) => {
    return {
      ...draggableStyle,
    };
  };
  const onDragEndAction = (result) => {
    let newList = reorder(
      membersArray,
      result.source.index,
      result.destination.index
    );
    setMembesArray([...newList]);
  };
  function checkChangePercentage(data) {
    if (data == 0) {
      return "(+" + data.toFixed(2) + "%)";
    } else if (data > 0) {
      return "(+" + roundNumber(data) + "%)";
    } else {
      return "(" + roundNumber(data) + "%)";
    }
  }
  const handleCalendarChange = (date) => {
    let dateFormat = moment(date, "ddd MMM DD YYYY HH:mm:ss ZZ").format(
      // "YYYY-MM-DD"
      "DD MMM YYYY"
    );
    setEndDate(dateFormat);
    // Close the calendar as soon as date is selected
    setCalendarVisible(false);
  };

  function initials(memberName) {
    if (!memberName) {
      return;
    }
    let memberNameArr = memberName?.split(" ");
    // if no surname is provided
    if (memberNameArr.length == 1) {
      let firstNameInitial = memberNameArr[0][0];
      return firstNameInitial;
    } else {
      let firstNameInitial = memberNameArr[0][0];
      let lastNameInitial = memberNameArr[1][0];
      return firstNameInitial + lastNameInitial;
    }
  }

  let currencyFormatValue = localStorage.getItem("currency_format");
  currencyFormatValue =
    currencyFormatValue === null ? "crores" : currencyFormatValue;

  return (
    //new comp
    <div style={{ display: "flex" }}>
      <div style={{ width: "75%" }}>
        <div className={`${classes.alignTopHeader}`}>
          <Back
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/home");
            }}
          />
          <Typography
            style={{ marginLeft: "-18%" }}
            className={`${classes.mainHeading} ${classes.mainHeadingAlign}`}
          >
            All Members
          </Typography>
          <p className={classes.portfolioline} style={{ marginRight: "-18%" }}>
            Portfolio Value as on: &nbsp;
            <strong className={classes.colorfff}>
              {/* {dateFormatCommon(endDate) || dateFormatCommon(currentDate)} */}
              {endDate || formattedDate}
            </strong>{" "}
            &nbsp;
            <span></span>
          </p>

          <div>
            <CalenderDate
              onClick={() => setCalendarVisible((prev) => !prev)}
              style={{ position: "relative", cursor: "pointer" }}
              className={classes.pagebackarrow}
            ></CalenderDate>
            {calendarVisible && (
              <div
                style={{
                  position: "absolute",
                  right: "50px",
                  zIndex: 1,
                }}
                ref={ref}
              >
                <Calendar
                  onChange={handleCalendarChange}
                  selectRange={false}
                  // value={endDate}
                  maxDate={new Date(moment().format("YYYY-MM-DD"))}
                  value={endDate ? new Date(endDate) : null}
                />
              </div>
            )}
          </div>
        </div>

        <div style={{ marginLeft: "3%", display: "flex" }}>
          {/* <SearchHeader></SearchHeader> */}
          <SearchInput
            setSearchName={setScripName}
            placeHolderText="Search By Client"
          />

          <span
            className={classes.portfolioline}
            style={{ paddingTop: "40px", width: "72%", textAlign: "end" }}
          >
            Values in :
            <strong className={classes.colorfff}>
              {" "}
              {firstLetterCapitalize(currencyFormatValue)}
            </strong>
          </span>
        </div>

        <div
          style={{
            margin: "2% 3%",
            display: "flex",
            justifyContent: "space-between",
          }}
          className={classes.topGrayBox}
        >
          <div style={{ margin: "2%" }}>
            <p className="portfolioBoxTitle">Total Portfolio Values</p>
            <p className={classes.colorlightgrey}>
              {totalFamilyMembers > 1
                ? `${totalFamilyMembers || 0} Members`
                : `${totalFamilyMembers || 0} Member`}
            </p>
          </div>

          <div style={{ marginRight: "2%" }}>
            <p className="portfolioBoxValue" style={{ textAlign: "right" }}>
              {familyHead?.portfolioValueAmount
                ? currencyFormat(familyHead?.portfolioValueAmount || 0)
                : currencyFormat(0)}
            </p>
            <p className="portfolioBoxValueSmall">
              <span
                className={
                  familyHead?.changeValueAmount >= 0 ? "up-green" : "down-red"
                }
              >
                {familyHead?.changeValueAmount >= 0 ||
                !familyHead?.changeValueAmount ? (
                  <Marketuparrow className="mrright5"></Marketuparrow>
                ) : (
                  <Marketdownarrow className="mrright5"></Marketdownarrow>
                )}
                {familyHead?.changeValueAmount
                  ? amountWithSigns(familyHead?.changeValueAmount || 0)
                  : amountWithSigns(0)}
                <small
                  style={{
                    display: "block",
                    textAlign: "right",
                    marginTop: "5px",
                  }}
                >
                  {familyHead?.changePercentage
                    ? checkChangePercentage(familyHead?.changePercentage || 0)
                    : checkChangePercentage(0)}
                </small>
              </span>
            </p>
          </div>
        </div>

        {/* static         */}

        {/* cards to map */}
        <PageLoader showLoader={loading} />

        {headOfFamily?.length === 0 ? (
          <DataNotFound />
        ) : (
          <div>
            {/* first card */}

            {/* other cards */}
            <DragDropContext onDragEnd={onDragEndAction}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    class="containergridFirst"
                  >
                    {familyHead?.memberName && (
                      <Card
                        className="root memberscard details bgcircles cardFirst"
                        onClick={handleClick}
                        style={{
                          cursor: "pointer",
                          // border: "0.1px solid yellow",
                          border: "1px solid #711cf1",
                          minHeight: "auto",
                          gridRow: 1,
                        }}
                      >
                        <div
                          // className="mainCard"
                          className="bgcirclesbig svgcirclecolor"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            height: "100%",
                            minHeight: "auto",
                          }}
                        >
                          {/* HEAD CROWN */}
                          <div className="headCrown" style={{ width: "20%" }}>
                            <CrownHead memberName={familyHead?.memberName} />
                            {/* <div
                      className="mrbtm10 bigcrownallmember "
                      style={{
                        position: "relative",
                        width: "4rem",
                        marginLeft: "2px",
                      }}
                    >
                      <Crown className="yellowCrown" />
                      <div
                        className="big-avatar-design"
                        style={{
                          position: "absolute",
                          width: "57px",
                          height: "57px",
                          top: "20%",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "6px solid",
                          background: "#4E189F",
                          boxShadow:
                            "inset -1.67229px 1.67229px 8.36143px rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        {" "}
                        <span style={{ fontSize: "18px" }}>
                          {familyHead?.memberName &&
                            initials(familyHead?.memberName)}
                        </span>
                      </div>
                    </div> */}
                          </div>
                          <div
                            className="wid50p fleft"
                            style={{ width: "30%" }}
                          >
                            <CardContent
                              // style={{ marginTop: "15%" }}
                              className="contentleftNew"
                            >
                              <Typography className={classes.headmembername}>
                                {familyHead?.memberName}
                              </Typography>
                              <Typography variant="subtitle1">
                                <span
                                  className={classes.headmembernos}
                                  style={{
                                    display: "inline-block",
                                    width: "82px",
                                    padding: "0px",
                                  }}
                                >
                                  {familyHead?.memberCount > 1
                                    ? `${familyHead?.memberCount} Members`
                                    : `${familyHead?.memberCount} Member`}
                                </span>
                              </Typography>
                            </CardContent>
                          </div>

                          <div className="controls" style={{ width: "50%" }}>
                            <CardContent
                              // style={{ marginTop: "15%" }}
                              className="contentright"
                            >
                              {/* <Moveicon className="mrbtm10"> </Moveicon> */}
                              <Typography
                                style={{ fontSize: "1rem", fontWeight: "600" }}
                                className={classes.bigfamilyposn}
                              >
                                PORTFOLIO VALUE
                              </Typography>
                              <Typography
                                className={`${classes.bigmemberamt} ${
                                  familyHead?.portfolioValueAmount >= 0
                                    ? "up-green"
                                    : "down-red"
                                }`}
                                style={{
                                  fontSize: "1.2rem",
                                }}
                              >
                                {familyHead?.portfolioValueAmount
                                  ? currencyFormat(
                                      familyHead?.portfolioValueAmount || 0
                                    )
                                  : currencyFormat(0)}
                              </Typography>
                              <Typography>
                                <Typography
                                  style={{
                                    fontSize: "1rem",
                                    fontWeight: "600",
                                  }}
                                  className={classes.bigfamilyposn}
                                >
                                  CHANGE IN VALUE
                                </Typography>
                                <div
                                  className={
                                    familyHead?.changeValueAmount >= 0
                                      ? "up-green"
                                      : "down-red"
                                  }
                                >
                                  {familyHead?.changeValueAmount >= 0 ||
                                  !familyHead?.changeValueAmount ? (
                                    <Marketuparrow className="mrright5"></Marketuparrow>
                                  ) : (
                                    <Marketdownarrow className="mrright5"></Marketdownarrow>
                                  )}
                                  {familyHead?.changeValueAmount
                                    ? amountWithSigns(
                                        familyHead?.changeValueAmount || 0
                                      )
                                    : amountWithSigns(0)}
                                  <br />
                                  {/* <Typography className={classes.bigfamilyposn}>
                          CHANGE IN PERCENTAGE
                        </Typography> */}
                                  {/* <span style={{ fontSize: "14px" }}>
                          {familyHead?.changePercentage
                            ? checkChangePercentage(
                                familyHead?.changePercentage || 0
                              )
                            : checkChangePercentage(0)}
                        </span> */}
                                </div>
                                <Typography
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "600",
                                  }}
                                  className={classes.bigfamilyposn}
                                >
                                  CHANGE IN PERCENTAGE
                                </Typography>
                                <span
                                  className={
                                    familyHead?.changeValueAmount >= 0
                                      ? "up-green"
                                      : "down-red"
                                  }
                                  style={{ fontSize: "14px" }}
                                >
                                  {familyHead?.changePercentage
                                    ? checkChangePercentage(
                                        familyHead?.changePercentage || 0
                                      )
                                    : checkChangePercentage(0)}
                                </span>
                              </Typography>
                            </CardContent>
                          </div>
                        </div>
                      </Card>
                    )}
                    {membersArray?.map((member, index) => (
                      <Draggable
                        key={member?.memberName}
                        draggableId={member?.memberName}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <Card
                            className="root cardFirst memberscard details bgcircles"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                height: "100%",
                              }}
                            >
                              <div style={{ width: "20%" }}>
                                <div
                                  style={{
                                    backgroundColor: "#FFFFFF",
                                    borderRadius: "50%",
                                    width: "60px",
                                    height: "60px",
                                  }}
                                >
                                  <span
                                    className="avatar-design"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "100%",
                                      // border: "2px solid red",
                                    }}
                                  >
                                    {initials(member?.memberName)}
                                  </span>
                                </div>
                              </div>
                              <div
                                style={{ width: "30%" }}
                                className="wid50p fleft"
                              >
                                <CardContent
                                  style={{ marginTop: "5%" }}
                                  className="contentleftNew"
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      // flexDirection: "column",
                                      alignItems: "center",
                                      gap: "20px",
                                    }}
                                  >
                                    {/* <Typography className={classes.headmembername}>
                               {member?.memberName}
                             </Typography> */}

                                    <div>
                                      <Typography
                                        className={classes.headmembername}
                                      >
                                        {member?.memberName}
                                      </Typography>
                                      <Typography variant="subtitle1">
                                        <span
                                          className={classes.headmembernos}
                                          style={{
                                            display: "inline-block",
                                            width: "82px",
                                            padding: "0px",
                                          }}
                                        >
                                          {member?.memberCount > 1
                                            ? `${member?.memberCount} Members`
                                            : `${member?.memberCount} Member`}
                                        </span>
                                      </Typography>
                                    </div>
                                  </div>
                                </CardContent>
                              </div>

                              <div
                                style={{ width: "50%" }}
                                className="controls"
                              >
                                <CardContent className="contentright">
                                  <Moveicon className="mrbtm10"> </Moveicon>
                                  <Typography
                                    style={{
                                      fontSize: "1rem",
                                      fontWeight: "600",
                                    }}
                                    className={classes.childMembertext}
                                  >
                                    PORTFOLIO VALUE
                                  </Typography>
                                  <Typography
                                    className={
                                      member?.portfolioValueAmount >= 0
                                        ? "up-green"
                                        : "down-red"
                                    }
                                  >
                                    {member?.portfolioValueAmount
                                      ? currencyFormat(
                                          member?.portfolioValueAmount || 0
                                        )
                                      : currencyFormat(0)}
                                  </Typography>
                                  <Typography
                                    style={{
                                      fontSize: "1rem",
                                      fontWeight: "600",
                                    }}
                                    className={classes.childMembertext}
                                  >
                                    CHANGE IN VALUE
                                  </Typography>
                                  <Typography>
                                    <div
                                      className={
                                        member?.changeValueAmount >= 0
                                          ? "up-green"
                                          : "down-red"
                                      }
                                    >
                                      {member?.changeValueAmount > 0 ||
                                      !member?.changeValueAmount ? (
                                        <Marketuparrow className="mrright5"></Marketuparrow>
                                      ) : (
                                        <Marketdownarrow className="mrright5"></Marketdownarrow>
                                      )}
                                      {member?.changeValueAmount
                                        ? amountWithSigns(
                                            member?.changeValueAmount || 0
                                          )
                                        : amountWithSigns(0)}
                                      <br />
                                    </div>
                                  </Typography>
                                  <Typography
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                    }}
                                    className={classes.childMembertext}
                                  >
                                    CHANGE IN PERCENTAGE
                                  </Typography>
                                  <span
                                    className={
                                      member?.changeValueAmount >= 0
                                        ? "up-green"
                                        : "down-red"
                                    }
                                    style={{
                                      marginTop: "2%",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {member?.changePercentage
                                      ? checkChangePercentage(
                                          member?.changePercentage || 0
                                        )
                                      : checkChangePercentage(0)}
                                  </span>
                                </CardContent>
                              </div>
                            </div>
                          </Card>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {/* <Card className="root cardFirst memberscard details bgcircles">
            <div className="wid50p fleft">
              <CardContent className="contentleftNew">
                <Typography className={classes.headmembername}>
                  Stark
                </Typography>
                <Typography className={classes.headmembername}>
                  Arya Stark
                </Typography>
                <Typography variant="subtitle1" style={{ marginTop: "47%" }}>
                  <span className={classes.headmembernos}>4 Members</span>
                </Typography>
              </CardContent>
            </div>

            <div className="controls">
              <CardContent className="contentright">
                <Moveicon className="mrbtm10"> </Moveicon>
                <Typography className={classes.headmemberamt}>
                  $ 500,000,000
                </Typography>
                <Typography>
                  <span className={classes.headmemberrateup}>
                    <Marketuparrow className="mrright5"></Marketuparrow>
                    +1,50,000 <br />
                    (+56.66%)
                  </span>
                </Typography>
              </CardContent>
            </div>
          </Card>
          <Card className="root cardFirst memberscard details bgcircles">
            <div className="wid50p fleft">
              <CardContent className="contentleftNew">
                <Typography className={classes.headmembername}>
                  Stark
                </Typography>
                <Typography className={classes.headmembername}>
                  Arya Stark
                </Typography>
                <Typography variant="subtitle1" style={{ marginTop: "47%" }}>
                  <span className={classes.headmembernos}>4 Members</span>
                </Typography>
              </CardContent>
            </div>

            <div className="controls">
              <CardContent className="contentright">
                <Moveicon className="mrbtm10"> </Moveicon>
                <Typography className={classes.headmemberamt}>
                  $ 500,000,000
                </Typography>
                <Typography>
                  <span className={classes.headmemberrateup}>
                    <Marketuparrow className="mrright5"></Marketuparrow>
                    +1,50,000 <br />
                    (+56.66%)
                  </span>
                </Typography>
              </CardContent>
            </div>
          </Card>
          <Card className="root cardFirst memberscard details bgcircles">
            <div className="wid50p fleft">
              <CardContent className="contentleftNew">
                <Typography className={classes.headmembername}>
                  Stark
                </Typography>
                <Typography className={classes.headmembername}>
                  Arya Stark
                </Typography>
                <Typography variant="subtitle1" style={{ marginTop: "47%" }}>
                  <span className={classes.headmembernos}>4 Members</span>
                </Typography>
              </CardContent>
            </div>

            <div className="controls">
              <CardContent className="contentright">
                <Moveicon className="mrbtm10"> </Moveicon>
                <Typography className={classes.headmemberamt}>
                  $ 500,000,000
                </Typography>
                <Typography>
                  <span className={classes.headmemberrateup}>
                    <Marketuparrow className="mrright5"></Marketuparrow>
                    +1,50,000 <br />
                    (+56.66%)
                  </span>
                </Typography>
              </CardContent>
            </div>
          </Card> */}
          </div>
        )}
      </div>

      <div style={{ width: "25%" }}>
        <MemberProfile
          crownComponent={
            <div
              className="crownComp"
              style={{ position: "relative", left: "8%" }}
            >
              <Crownhead style={{ width: "80%", height: "80%" }}> </Crownhead>
              <div
                className="big-avatar-design"
                style={{
                  position: "absolute",
                  width: "95%",
                  // height: "80%",
                  top: "30%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <span
                  style={{
                    fontSize: "18px",
                    marginBottom: "3px",
                    marginRight: "15px",
                  }}
                >
                  {familyHead?.memberName && initials(familyHead?.memberName)}
                </span>
              </div>
            </div>
          }
          familyHead={familyHead?.memberName}
          setMemberName={setMemberName}
        ></MemberProfile>
      </div>
    </div>
  );
}

export default AllClientMembers;
