import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PageFilter from "./PageFilter";
import useStyles from "../common/ProductsStyles";
import { ReactComponent as Back } from "../../../../assets/holding-summary/back-blue.svg";

import { ReactComponent as LitBulb } from "../../../../assets/icons/bulb-glow.svg";
import { ReactComponent as Import } from "../../../../assets/icons/import.svg";
import { ReactComponent as Export } from "../../../../assets/icons/export.svg";
import { ReactComponent as Filter } from "../../../../assets/icons/filter.svg";
import { ReactComponent as Print } from "../../../../assets/icons/print.svg";
import { handleState } from "./util/common";
import { Typography, useTheme } from "@material-ui/core";
import { PrintModal } from "../../print/Components/PrintModal";
import { PermissionsGate } from "../../../auth/PermissionGate";
import { IosSwitch } from "../../../IosSwitch";
import ls from "localstorage-slim";
import { DataPointModal } from "../../common/DataPointModal";
import { routes } from "../../../../routes";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";

function SummaryPageTop({
  //   openModal,
  //   setOpenModal,
  backLink,
  pageTitle,
  asOnDate,
  flip,
  setFlip,
  doNotShowPageFilter,
  customOpenModal,
  setCustomOpenModal,
  setChecked,
  checked,
  ulip,
  setFilterModalOpen,
  hideRightSec,
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const pathArr = [
    routes.insuranceSummaryPage,
    routes.insuranceSummaryPage_health,
    routes.insuranceSummaryPage_general,
    ,
  ];
  const insurancePathArr = [
    routes.insuranceSummaryPage,
    routes.insuranceSummaryPage_health,
    routes.insuranceSummaryPage_general,
  ];

  const classes = useStyles();
  // const { roleType, userPermissionsArr, scopes } = useGetRole();
  const { entity } = useContext(mfoFilterContext);
  // console.log(entity, "afgjF");
  const scopes = JSON.parse(ls.get("scopes"));
  //   const [flip, setFlip] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [printModalMembers, setPrintModalMembers] = useState([]);
  const theme = useTheme();
  const icons = theme.palette.icons;
  const Bulb = icons.bulbIcon;

  console.log(entity, "aglnk");
  return (
    <>
      <PrintModal
        reportModal={reportModal}
        setReportModal={setReportModal}
        printModalMembers={printModalMembers}
      />
      {/* For Insurance And Real Estate Dont show the modal for now */}
      {!pathArr.includes(location.pathname) && (
        <DataPointModal open={flip} setOpen={setFlip} />
      )}
      {!doNotShowPageFilter && (
        <PermissionsGate scopes={[scopes?.can_view_my_portfolio_filter]}>
          <PageFilter
            backLink={backLink}
            customOpenModal={customOpenModal ? customOpenModal : openModal}
            setCustomOpenModal={
              setCustomOpenModal ? setCustomOpenModal : setOpenModal
            }
            setPrintModalMembers={setPrintModalMembers}
            setFilterModalOpen={setFilterModalOpen}
          />
        </PermissionsGate>
      )}
      <div className={`${classes.alignTopHeader}`}>
        {/* <Back
          style={{ cursor: "pointer", marginRight: "12px" }}
          onClick={() => {
            navigate(backLink);
          }}
        /> */}
        <Typography
          style={{ marginLeft: "-2px" }}
          className={`${classes.mainHeading} ${classes.mainHeadingAlign}`}
        >
          {pageTitle}
          <br />
          <span className={`portfolioAsOnTxt ${classes.mfoSubTextCard}`}>
            {asOnDate ? `As on ${asOnDate}` : ""}
            {sessionStorage.getItem("currency_format") != "actuals" &&
              sessionStorage.getItem("currency_format") && (
                <span
                  className="subtext capitalize"
                  style={{ fontSize: "16px" }}
                >
                  {" "}
                  (values in {sessionStorage.getItem("currency_format")})
                </span>
              )}
          </span>
        </Typography>
        {/* <IosSwitch checked={checked} setChecked={setChecked} /> */}
        {!hideRightSec && (
          <div className={`${classes.iconAlign}`}>
            <div style={{ marginRight: "13px" }}>
              {/* Conditional rendering of the IosSwitch component based on insurance path and ULIP status 
             // If the current path is in the insurance path array and ULIP is not "ulip" or undefined
            // Render an empty fragment (no component)
            */}
              {insurancePathArr.includes(location.pathname) &&
              (ulip !== "ulip" || ulip == undefined) ? (
                <></>
              ) : (
                <IosSwitch checked={checked} setChecked={setChecked} />
              )}
            </div>
            {!flip && (
              <PermissionsGate
                scopes={[scopes?.can_view_my_portfolio_know_more_bulb_icon]}
              >
                <Bulb
                  onClick={() => setFlip(!flip)}
                  // className={classes.iconStyle}
                  className={`${classes.iconStyle} scalDownIcon`}
                />
              </PermissionsGate>
            )}
            {flip && (
              <LitBulb
                onClick={() => setFlip(!flip)}
                // className={classes.iconStyle}
                className={`${classes.iconStyle} scalDownIcon`}
              />
            )}

            {/* <Import className={`${classes.iconStyle} scalDownIcon`} />
            <Export className={`${classes.iconStyle} scalDownIcon`} />
            <PermissionsGate
              scopes={[scopes?.can_view_my_portfolio_export_print]}
            >
              <Print
                className={`${classes.iconStyle} scalDownIcon`}
                onClick={() => {
                  // setReportModal(true);
                  navigate("/home/reports");
                  // if (location.pathname == "/home/holding") {
                  //   navigate("/print-report");
                  // }
                  // if (pageTitle === "Portfolio Summary") {
                  //   navigate(`/home/reports?key=report-center`);
                  // }
                }}
              />
            </PermissionsGate> */}
            {/* <Filter
            className={classes.iconStyle}
            onClick={() => {
              if (setCustomOpenModal) {
                handleState(setCustomOpenModal, true);
                return;
              }
              handleState(setOpenModal, true);
            }}
          /> */}
          </div>
        )}
      </div>
    </>
  );
}

export default SummaryPageTop;
