import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
// import "../BackBtnWithArrow/backButton.less";
import React from "react";
import "./arrowButton.css";

function ArrowButton({ right, bgColor, onClick, customStyles }) {
  return (
    <span
      style={{
        backgroundColor: bgColor ? bgColor : "yellow",
        cursor: onClick ? "pointer" : "auto",
        width: "20px !important",
        ...customStyles,
      }}
      className={`backButtonWrapper1 smallerIconWrapper dFlex jtfyContCntr alignCntr ${
        right ? `rightBtn` : ""
      }`}
      onClick={onClick}
    >
      <ArrowBackIosRoundedIcon
        className="backIocn smallerIcon"
        fontSize="small"
      />
    </span>
  );
}

export default ArrowButton;
