import React, { useState } from "react";
import { ReactComponent as Back } from "../../../../../assets/holding-summary/back-blue.svg";

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Modal,
  Button,
} from "@material-ui/core";
import useStyles from "../../common/ProductsStyles";
import { useNavigate } from "react-router-dom";
import SearchInput from "../../common/SearchInput";
const tableColumnsData = [
  { field: "advisor", title: "Advisor", show: true },
  { field: "scheme_name", title: "Scheme/Product Name", show: true },
  { field: "ISIN", title: "ISIN No/Product Code", show: true },
  { field: "asset_type", title: "Asset Type", show: true },
  { field: "product_sud_category", title: "Product Sud Category", show: true },
  { field: "class", title: "Class () a,b,c,d,e", show: true },
  { field: "AIF_type", title: "AIF Type", show: true },
  { field: "quantity_units", title: "Quantity/Units", show: true },
  { field: "purchase_rate", title: "Purchase Rate/NAV", show: true },
  { field: "current_unit_balance", title: "Current Unit Balance", show: true },
  { field: "current_rate", title: "Current Rate/Nav", show: true },
  { field: "commited_amount", title: "Commited Amount", show: true },
  { field: "invested_value", title: "Invested Value", show: true },
  { field: "drawdowns", title: "Drawdowns", show: true },
  {
    field: "compensation_contribution",
    title: "Compensation Contribution",
    show: true,
  },
  { field: "current_value", title: "Current Value", show: true },
  { field: "load", title: "Load", show: true },
  { field: "dividend_recieved", title: "Dividend Recieved", show: true },
  { field: "coupon_date", title: "Coupon Date", show: true },
  { field: "par_value", title: "Par Value", show: true },
  { field: "principal_paid_back", title: "Principal Paid Back", show: true },
  { field: "total_portfolio", title: "% of Total Portfolio", show: true },
  { field: "unrealised_gain_loss", title: "Unrealised Gain/Loss", show: true },
  { field: "realised_gain_loss", title: "Realised Gain/Loss", show: true },
  { field: "XIRR", title: "XIRR (%)", show: false },
  { field: "TWRR_absolute", title: "TWRR Absolute", show: false },
  { field: "TWRR_annualised", title: "TWRR Annualized", show: false },
];
function IpoTransaction() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [tableColumns, setTableColumns] = useState([tableColumnsData]);
  // const [tableColumnsData, setTableColumnsData] = useState([tableColumnsDataDetails])

  const tableHeaders = (headers) => {
    return headers?.map((column) =>
      column && column.show ? (
        <TableCell
          style={{ borderBottom: "none", textTransform: "capitalize" }}
          key={column.title}
          align="left"
          className={`${classes.tableHeadBlueText} thTop`}
        >
          {column.title}
        </TableCell>
      ) : null
    );
  };
  const tableBody = (body) => {
    return body?.map((column) =>
      column && column.show ? (
        <TableCell
          key={column.title}
          align="left"
          className={`${classes.tableBodyText} tdAif`}
          style={{
            position: "relative",
            paddingRight: column.title == "advisor" ? "92px" : "0px",
            paddingTop: "38px",
          }}
        >
          {column.title}
        </TableCell>
      ) : null
    );
  };
  return (
    <>
      <div className={`${classes.alignTopHeader}`}>
        {/* <Back
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate(-1);
          }}
        /> */}
        <Typography
          style={{ marginLeft: "-23%" }}
          className={`${classes.mainHeading} ${classes.mainHeadingAlign}`}
        >
          Transaction Summary
        </Typography>
        <SearchInput />
      </div>
      <Typography
        style={{ cursor: "pointer", marginLeft: "90px", marginTop: "-1.5%" }}
        className={classes.aifSubText}
      >
        IPO
      </Typography>
      <div style={{ marginTop: "2%" }}>
        <Grid xs={12}>
          <div className={classes.tableContainerAlign}>
            <Table>
              <TableHead>
                <TableRow className={`${classes.tableHeadBlue} tableRowStyle`}>
                  {tableHeaders(tableColumns[0])}
                </TableRow>
              </TableHead>
              {}
              <TableBody className="tableBodyAif">
                <TableRow className={`${classes.tableBodyBlue} tableRowAif`}>
                  {tableBody(tableColumns[0])}
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </Grid>
      </div>
    </>
  );
}

export default IpoTransaction;
