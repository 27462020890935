import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { OnboardingContext } from "../../../../Context";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import PmsReportTable from "../pms/PmsReportTable";
import { holdingTableCOnfig } from "./constands";
import { useClientSearch } from "../../../../hooks/useClientSearch";
import { ITEMS_LIMIT_FILES_PARTNER_LIST } from "../../../../Constants";
import { useCardsApi } from "../../../../hooks/useCardsApi";
import { summarizeTableData } from "../../../utils/common";
import { useCurrentHoldingCardsApi } from "../../../../hooks/useCurrentHoldingCardsApi";

function REHolding({
  getTableData,
  openModal,
  setOpenModal,
  loading,
  pageName,
  search,
  setOffset,
  offset,
}) {
  const { userState } = OnboardingContext();
  const { mfoFilter } = useContext(mfoFilterContext);
  const [tableHeader, setTableHeader] = useState(holdingTableCOnfig);
  const [checkedTrans, setCheckedTrans] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [secondTableColumns, setSecondTableColumns] = useState([]);
  const { searchResults } = useClientSearch(search, tableColumns);
  const [pagiNationData, setPageinationData] = useState({
    pageCount: null,
  });

  const numberOfInstrumentsCount = useRef(0);
  const [numberOfInstruments, setNumberOfInstruments] = useState(0);
  const [percentageOfTotalPortfolio, setPercentageOfTotalPortfolio] =
    useState(0);
  // const [offset, setOffset] = useState(0);

  const { holdingCardData, xirrCurrentHolding, twrrCurrentHolding } =
    useCurrentHoldingCardsApi(true, "re");
  useEffect(() => {
    getTableData({
      setTableColumns: setTableColumns,
      config: holdingTableCOnfig,
      setCheckedTrans: setCheckedTrans,
      schemeName: search,
      limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
      offset: offset,
      setPageinationData: setPageinationData,
      pagiNationData: pagiNationData,
      numberOfInstrumentsCount,
      setPercentageOfTotalPortfolio,
      setNumberOfInstruments,
    });
  }, [mfoFilter, offset, search]);

  useEffect(() => {
    numberOfInstrumentsCount.current = 0;
  }, [mfoFilter]);

  useEffect(() => {
    setSecondTableColumns(
      summarizeTableData(
        tableColumns,
        holdingCardData,
        xirrCurrentHolding,
        twrrCurrentHolding,
        pagiNationData?.pageCount
      )
    );
  }, [tableColumns, holdingCardData, xirrCurrentHolding, twrrCurrentHolding]);

  return (
    <PmsReportTable
      setOpenModal={setOpenModal}
      setTableColumns={setTableColumns}
      tableColumns={tableColumns}
      setSecondTableColumns={setSecondTableColumns}
      tableColumns2={secondTableColumns}
      openModal={openModal}
      headerList={holdingTableCOnfig}
      loading={loading}
      //   pageName="transaction"
      // tableHeaderBGColor="#4E189F"
      tableHeader={tableHeader}
      setTableHeader={setTableHeader}
      checkedTransList={checkedTrans}
      stickyProductName={true}
      setItemOffset={setOffset}
      pageCount={pagiNationData?.pageCount}
      pageNumber={offset / ITEMS_LIMIT_FILES_PARTNER_LIST + 1 || 1}
    />
  );
}

export default REHolding;
