import React, { useMemo, useRef } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { getTransactionsSummary } from "../../api";
import { mfoFilterContext } from "../../mfo_context/Mfo_Context";
import { OnboardingContext } from "../../Context";
import moment from "moment";
import { useState } from "react";
import PmsReportTable from "../dashboard/products/pms/PmsReportTable";
import SortableTable from "../dashboard/products/common/SortableTable";
import { useNavigate } from "react-router";
import { useClientSearch } from "../../hooks/useClientSearch";
import { ITEMS_LIMIT_FILES_PARTNER_LIST } from "../../Constants";
import { summarizeTableData } from "./common";
import { useCardsApi } from "../../hooks/useCardsApi";
import { useCurrentHoldingCardsApi } from "../../hooks/useCurrentHoldingCardsApi";

function CustomTable1({
  useEffectDepArray = [],
  product,
  tableName,
  objectFormating,
  headerConfig,
  customApiCall,
  tableBodyBackground,
  openModal,
  setOpenModal,
  soratableTable,
  tableHeaderBGColor,
  scripName,
  isToggleChecked,
  tableType,
  offset,
  setOffset,
}) {
  const { holdingCardData, xirrCurrentHolding, twrrCurrentHolding } =
    useCurrentHoldingCardsApi(true, "equity");

  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const { userState } = OnboardingContext();
  const [tableColumns, setTableColumns] = useState([]);
  const [secondTableColumns, setSecondTableColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableHeader, setTableHeader] = useState(headerConfig);
  const [checkedTrans, setCheckedTrans] = useState([]);
  const navigate = useNavigate();
  const { searchResults } = useClientSearch(scripName, tableColumns);
  const [totalCount, setTotalCount] = useState(null);
  const [pagiNationData, setPageinationData] = useState({
    pageCount: null,
    // limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
  });

  const numberOfInstrumentsCount = useRef(0);
  const [numberOfInstruments, setNumberOfInstruments] = useState(0);
  const [percentageOfTotalPortfolio, setPercentageOfTotalPortfolio] =
    useState(0);
  // const [offset, setOffset] = useState(customOffset || 0);

  const getTableData = async (params) => {
    if (scripName) {
      setLoading(false);
    } else {
      setLoading(true);
    }
    try {
      let newParams = {
        ...params,
        promoterStake: mfoFilter.promoterStake,
        offset: offset,
        limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
      };

      let res = await getTransactionsSummary(product, tableName, newParams);
      let newlist = [];
      let transactionList = [];

      res?.data?.data?.result.map((val) => {
        newlist.push(
          objectFormating(val, headerConfig, navigate, isToggleChecked)
        );
        if (
          val["transaction_type"] != null &&
          !transactionList
            .map((val) => val?.title)
            .includes(val["transaction_type"])
        ) {
          transactionList.push({
            title: val["transaction_type"],
            show: false,
          });
        }
      });
      setCheckedTrans(transactionList);
      setPageinationData((prev) => {
        return { ...prev, pageCount: res?.data?.data?.meta?.count };
      });

      if (numberOfInstrumentsCount.current === 0) {
        setNumberOfInstruments(res?.data?.data?.meta?.count);
      }
      numberOfInstrumentsCount.current = numberOfInstrumentsCount.current + 1;
      setPercentageOfTotalPortfolio(
        res?.data?.data?.total?.percentageOfTotalPortfolio
      );

      setTotalCount(res?.data?.data?.meta?.count);
      setTableColumns(newlist);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    setSecondTableColumns(
      summarizeTableData(
        tableColumns,
        holdingCardData,
        xirrCurrentHolding,
        twrrCurrentHolding,
        numberOfInstruments,
        "",
        "",
        "",
        percentageOfTotalPortfolio
      )
    );
  }, [tableColumns, holdingCardData, xirrCurrentHolding, twrrCurrentHolding]);
  useEffect(() => {
    setTableHeader(() => headerConfig);
    let params = {
      clientId: userState.loginedUserData.id,
      // clientId: 4,
      ...getfilter_as_params(),
      showCorpAction: isToggleChecked,
    };
    // TODO Remove the 1st if check when the search API is ready
    // if (!tableType) {
    if (!scripName || scripName !== "") {
      params["search"] = scripName;
    }
    // }

    if (customApiCall) {
      customApiCall(params, setTableColumns, setLoading, tableHeader);
    } else {
      getTableData(params);
    }
  }, [
    ...useEffectDepArray,
    headerConfig,
    mfoFilter,
    product,
    tableName,
    // TODO Remove the check and set only scripName
    // !tableType && scripName,
    scripName,
    offset,
  ]);

  useEffect(() => {
    numberOfInstrumentsCount.current = 0;
  }, [product, mfoFilter]);

  return (
    <>
      {soratableTable ? (
        <SortableTable
          tableColumns={tableColumns}
          setTableColumns={setTableColumns}
          loading={loading}
          headerList={headerConfig}
          tableHeader={tableHeader}
          setTableHeader={setTableHeader}
          tableHeaderBGColor={tableHeaderBGColor}
          tableBodyBackground={tableBodyBackground}
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
      ) : (
        <>
          <PmsReportTable
            tableName={tableName}
            tableColumns={tableColumns}
            setTableColumns={setTableColumns}
            setSecondTableColumns={setSecondTableColumns}
            tableColumns2={secondTableColumns}
            loading={loading}
            headerList={headerConfig}
            tableHeader={tableHeader}
            setTableHeader={setTableHeader}
            tableBodyBackground={tableBodyBackground}
            openModal={openModal}
            setOpenModal={setOpenModal}
            stickyProductName={true}
            checkedTransList={checkedTrans}
            corporateActionsLabel={
              isToggleChecked ? (
                <div
                  style={{
                    position: "absolute",
                    top: "-83px",
                    left: "3px",
                    // zIndex: "99",
                  }}
                >
                  <div id="corporateActions">Corporate Actions</div>
                </div>
              ) : (
                <></>
              )
            }
            pageCount={pagiNationData?.pageCount}
            setItemOffset={setOffset}
          />
        </>
      )}
    </>
  );
}

export default CustomTable1;
