import React from 'react'
import useStyles from '../../common/ProductsStyles';

function TabIPODetails({ page, setPage }) {
    const classes = useStyles()
    return (
        <div style={{ width: "100%", margin: "2%", cursor: "pointer" }} className={classes.tabMfo}>
            <a onClick={() => { setPage("revenue") }} style={{ textDecoration: 'none' }} ><div className={`${classes.tabHeaderText} ${page === 'revenue' ? classes.active : ''}`}>Revenue</div></a>
            <a onClick={() => { setPage("totalassets") }} style={{ textDecoration: 'none' }}><div className={`${classes.tabHeaderText} ${page === 'totalassets' ? classes.active : ''}`}>Total Assets</div></a>
            <a onClick={() => { setPage("profit") }} style={{ textDecoration: 'none' }} ><div className={`${classes.tabHeaderText}  ${page === 'profit' ? classes.active : ''} `}>Profit</div></a>
        </div>
    )
}

export default TabIPODetails