import { fetchBankSummary } from "../api";
import { useState, useEffect, useContext } from "react";
import { OnboardingContext } from "../Context";
import { mfoFilterContext } from "../mfo_context/Mfo_Context";
import moment from "moment";
import { dateFormatCommon, roundNumber } from "../components/utils/common";
import { currencyFormat } from "./constantFunction";
import { colorAndProdBank } from "../components/dashboard/products/utils/constands/prodAndColor";

export const useBankSummary = ({ setPie, setAssetTotalData }) => {
  const { userState } = OnboardingContext();
  let clientId = userState.loginedUserData.id;
  // let clientId = 199;
  const { mfoFilter } = useContext(mfoFilterContext);
  const [loading, setLoading] = useState(true);
  const [bankSummary, setBankSummary] = useState([]);
  const [summaryDetailData, setSummaryDetialData] = useState([]);
  const [color2, setColor] = useState([]);

  const handleCategoryAllocationData = (data) => {
    // console.log(data, "ajgbj");
    let color = [];
    let newData = data.bankSummary?.map((val) => {
      if (val?.account_type == "savings") {
        color.push(colorAndProdBank[val?.account_type]);
      }

      if (val?.account_type == "current") {
        color.push(colorAndProdBank[val?.account_type]);
      }

      return {
        title: val?.account_type + " Account",
        percent: val?.allocation_percentage
          ? roundNumber(val?.allocation_percentage) + "%"
          : "0.00%",
        date: val?.updated_as_on
          ? dateFormatCommon(val?.updated_as_on, "YYYY-MM-DD")
          : "--",
        amount: val?.balance ? currencyFormat(val?.balance) : currencyFormat(0),
      };
    });
    setPie((prev) => {
      return {
        ...prev,
        colors: color,
      };
    });
    setColor(() => [...color]);

    setSummaryDetialData(() => [
      ...newData?.filter((val) => val !== undefined),
    ]);

    setSummaryDetialData(() => [
      ...newData,
      {
        title: "Total",
        percent: roundNumber(100) + "%",
        date: data?.latest_updated_as_on
          ? dateFormatCommon(data?.latest_updated_as_on, "YYYY-MM-DD")
          : "--",
        amount: data?.total_balance
          ? currencyFormat(data?.total_balance)
          : currencyFormat(0),
      },
    ]);
  };

  useEffect(() => {
    let params = {};

    if (mfoFilter.startDate) {
      params["startDate"] = moment(mfoFilter.startDate).format("YYYY-MM-DD");
      params["endDate"] = moment(mfoFilter.endDate).format("YYYY-MM-DD");
    }
    if (Object.keys(mfoFilter.checkData).length > 0) {
      params["memberId"] = Object.keys(mfoFilter.checkData).join(",");
    }
    if (Object.keys(mfoFilter.advisor).length > 0) {
      params["advisor"] = Object.keys(mfoFilter.advisor).join(",");
    }
    params["assetClass"] = "cash";
    fetchBankSummary(clientId, params)
      .then((response) => {
        const apiData = response?.bankSummary;
        handleCategoryAllocationData(response);
        setBankSummary(apiData);
        setAssetTotalData({
          total: response?.total_balance,
          updated_as_on: response?.latest_updated_as_on,
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(`An Error ${err} occurred`);
      });
  }, [mfoFilter]);
  return { bankSummary, loading, summaryDetailData, color: color2 };
};
