import { makeStyles } from "@material-ui/core";
import React from "react";

function NoIdCard() {
  const useStyles = makeStyles({
    mainContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "16px",
    },
    iconTextWrapper: {
      display: "flex",
      alignItems: "center",
      gap: "20px",
    },
    mainTextHeading: {
      color: "#FFF",
      fontFamily: "Avenir",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "12.658px",
      letterSpacing: "2.4px",
      textTransform: "uppercase",
      opacity: 0.7,
      marginBottom: "8px",
    },
  });
  const classes = useStyles();

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        background: "white",
        display: "flex",
        alignItems: "center",
        margin: "0px",
      }}
    >
      <p
        style={{ color: "black", textAlign: "center" }}
        className={classes.mainTextHeading}
      >
        Sorry Not Uploaded
      </p>
    </div>
  );
}

export default NoIdCard;
