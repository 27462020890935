import React, { useEffect, useState } from "react";
import PageContainer from "../PageContainer";
import Header from "../Components/Header";
import PrintTable from "../Components/Table";
import ApexNew_print from "../Components/ApexNew_print";
import chartData from "../../../charts/chartData";
import { ReactComponent as Question } from "../images/question.svg";
import PieChart from "../../../charts/PieChart";
import Print_PieChart from "../Components/Print_PieChart";
import TabCards from "../Components/TabCards";
import { roundNumber } from "../../../utils/common";
import { date } from "yup/lib/locale";
import { currencyFormat } from "../../../../hooks/constantFunction";
import { MemberModel } from "../Components/utils/staticData";
import {
  objectFormatingWithClientTopBottomTitleCOl,
  objectFormatingWithTitleCOlWrapper,
} from "../Components/utils/common";
import { Link, Typography } from "@material-ui/core";
import {
  newtopPerformancTable,
  topBottomPerformanceTable,
} from "../../products/holding_summary/utils/constands";

export default function TopBottomPerformer({
  data,
  denomination,
  title,
  datasrc,
  pdfInfo,
  setChanges,
}) {
  const getDenomination = localStorage.getItem("denomination");

  const colors = ["#4B64ED", "#BDC5F2"];
  const [tableData, setTabledata] = useState([]);
  const asset = ["equity", "debt", "alternate", "cash"];
  const breadcrumbs = [];
  const [load, setload] = useState(false);
  const header = topBottomPerformanceTable;
  useEffect(() => {
    console.log("top3performance");
    setload(true);
    setChanges((prev) => !prev);
  }, [data]);

  function pageContent(key, bread, tabledata) {
    if (!tabledata || tabledata.length == 0) {
      return <></>;
    }
    return (
      <div key={key}>
        <Header
          breadcrumbs={bread}
          type={13}
          tabs={asset}
          hideHr={true}
          denomination={denomination}
          subtext={""}
          width={"5in"}
        ></Header>
        {/* <Header
        title={<>{pos[index + 1]?.product}</>}
        type={10}
        subtext={""}
        width={"5in"}
      ></Header> */}
        <div style={{ alignItems: "center" }}>
          <div
            style={{
              height: "fit-content",
              width: "100%,",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "relative",
                zIndex: "5",
                width: "100%",
                top: "-0.5in",

                marginRight: "66px",
              }}
            >
              <PrintTable
                // customRef={pRef2}
                customTableClass={"printTableEvenAndOddRow"}
                hideTitle={true}
                data={
                  objectFormatingWithTitleCOlWrapper(
                    {
                      data: tabledata || [],
                    },
                    header,
                    [],
                    "#ADC371",
                    undefined,
                    // totaRowData ? totaRowData : hideTotalRow ? undefined : total,
                    undefined,
                    {},
                    denomination,
                    "fund",
                    undefined,
                    objectFormatingWithClientTopBottomTitleCOl
                  ) || []
                }
                header={header}
                hideUnderText={true}
                customPadding={"2.2px 16px"}
                firstCellCircleProps={{
                  height: "8px",
                  width: "4.5px",
                }}
                titleCellProps={{
                  firstCellStyle: { padding: "10px 0px 10px 0px" },
                  style: {
                    background: "#F3F8FD",
                    padding: "0px 16px 0px 9px",
                    minHeight: "47px",
                  },
                  font: {
                    fontWeight: "900",
                    fontSize: "16px",
                  },
                }}
                normalCellProps={{
                  firstCellPadding: "10px 0px 10px 0px",
                  fontStyle: {
                    fontWeight: "600",
                    fontSize: "14px",
                  },
                }}
                headerProps={{
                  className: "whiteHeaderCell",
                  titleClassName: "whiteTitle",
                  titleStyle: {
                    fontWeight: "500",
                    fontFamily: "AvenirBold",
                    fontSize: "14px",
                    lineHeight: "18px",
                    letterSpacing: "0.06em",
                    textTransform: "uppercase",
                    color: "#000000",
                  },
                }}
                colSpan={1}
              />
            </div>
          </div>
        </div>
        {/* {index + 2 <= pos?.length - 1 &&
        pos[index + 2]?.performerData?.length +
          pos[index + 1]?.performerData?.length <=
          6 && (
          <>
            {pos[index + 2]?.performerData?.length != 0 && (
              <Header
                title={<>{pos[index + 2]?.product}</>}
                type={10}
                subtext={""}
                width={"5in"}
              ></Header>
            )}
            <div
              className="p_flex"
              style={{ alignItems: "center" }}
            >
              <div
                style={{
                  height: "295px",
                  width: "100%",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    // position: "absolute",
                    zIndex: "5",
                    width: "100%",
                    top: "-0.5in",

                    marginRight: "66px",
                  }}
                >
                  {pos[index + 2]?.performerData?.length != 0 && (
                    <PrintTable
                      // customRef={pRef2}
                      customTableClass={"printTableEvenAndOddRow"}
                      hideTitle={true}
                      data={
                        objectFormatingWithTitleCOlWrapper(
                          {
                            data:
                              pos[index + 2]?.performerData || [],
                          },
                          header,
                          [],
                          "#ADC371",
                          undefined,
                          // totaRowData ? totaRowData : hideTotalRow ? undefined : total,
                          undefined,
                          {},
                          denomination
                        ) || []
                      }
                      header={header}
                      hideUnderText={true}
                      customPadding={"2.2px 16px"}
                      firstCellCircleProps={{
                        height: "8px",
                        width: "4.5px",
                      }}
                      titleCellProps={{
                        firstCellStyle: {
                          padding: "10px 0px 10px 0px",
                        },
                        style: {
                          background: "#F3F8FD",
                          padding: "0px 16px 0px 9px",
                          minHeight: "47px",
                        },
                        font: {
                          fontWeight: "900",
                          fontSize: "16px",
                        },
                      }}
                      normalCellProps={{
                        firstCellPadding: "10px 0px 10px 0px",
                        fontStyle: {
                          fontWeight: "600",
                          fontSize: "14px",
                        },
                      }}
                      headerProps={{
                        className: "whiteHeaderCell",
                        titleClassName: "whiteTitle",
                        titleStyle: {
                          fontWeight: "500",
                          fontFamily: "AvenirBold",
                          fontSize: "14px",
                          lineHeight: "18px",
                          letterSpacing: "0.06em",
                          textTransform: "uppercase",
                          color: "#000000",
                        },
                      }}
                      colSpan={1}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        )} */}
      </div>
    );
  }

  return (
    <div>
      {load &&
        asset.map((a, i) => {
          let top = data[datasrc + a];
          let bottom = data["bottom-performers-by-contribution-" + a];
          // let k = Math.ceil(pos?.length / 2);

          console.log(top, a, "top performer");
          let cols = [];
          const breadasset = (
            <Link
              underline="hover"
              key="2"
              color="#000000"
              className="printBreadsel fontFamilyAvBold f16"
            >
              <span className="opc80"> Asset Class : </span>
              <span className="pdfselectedTxt pdfCaps fontFamilyAvBold f16">
                {a}
              </span>
            </Link>
          );
          let bread = [];
          let bread1 = [];
          bread.push(
            <Link
              key="1"
              color="#000000"
              className="printBreadsel fontFamilyAvBold f16"
            >
              {title}
            </Link>
          );
          bread1.push(
            <Link
              key="1"
              color="#000000"
              className="printBreadsel fontFamilyAvBold f16"
            >
              Bottom performers
            </Link>
          );
          bread.push(breadasset);
          bread1.push(breadasset);

          return (
            <>
              {(top?.length > 0 || bottom?.length > 0) && (
                <div>
                  <PageContainer
                    layout_t={1}
                    pageNo={2}
                    bgColor={"white"}
                    pdfInfo={pdfInfo}
                  >
                    {pageContent(i + "" + i, bread, top)}
                    {pageContent(i, bread1, bottom)}
                  </PageContainer>
                </div>
              )}
            </>
          );
        })}
    </div>
  );
}
