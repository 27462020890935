export const riskLevels = [
  "Low Risk",
  "Low to Moderate Risk",
  "Moderate Risk",
  "Moderately High risk",
  "High Risk",
  "Very High Risk",
];

export const EquityTableConfig = [
  {
    field: "Name",
    title: "Company Name",
    show: true,
    align: "center",
  },
  {
    field: "Sector",
    title: "Sector",
    show: true,
    align: "center",
  },
  {
    field: "Weighting",
    title: "% Assets",
    show: true,
    align: "center",
  },
  // {
  //   field: "PERatio",
  //   title: "PE Ratio ",
  //   show: true,
  //   align: "center",
  // },
];

export const equitySectorWiseALocationConfig = [
  {
    id: "BasicMaterials",
    label: "Basic Materials",
    value: "",
    color: "#B80000",
  },
  {
    id: "CommunicationServices",
    label: "Communication Services",
    value: "",
    color: "#7B61FF",
  },
  {
    id: "ConsumerCyclical",
    label: "Consumer Cyclical",
    value: "",
    color: "#82EBA1",
  },
  {
    id: "ConsumerDefensive",
    label: "Consumer Defensive",
    value: "",
    color: "#FC5555",
  },
  {
    id: "Energy",
    label: "Energy",
    value: "",
    color: "#5B0C81",
  },
  {
    id: "FinancialServices",
    label: "Financial Services",
    value: "",
    color: "#E1F5FD",
  },
  {
    id: "Healthcare",
    label: "Healthcare",
    value: "",
    color: "#29CC6A",
  },
  {
    id: "Industrials",
    label: "Industrials",
    value: "",
    color: "#FF9446",
  },
  {
    id: "RealEstate",
    label: "RealEstate",
    value: "",
    color: "#2D2727",
  },
  {
    id: "Technology",
    label: "Technology",
    value: "",
    color: "#F0EB8D",
  },
  {
    id: "Utilities",
    label: "Utilities",
    value: "",
    color: "#539165",
  },
];

export const debtSectorWiseALocationConfig = [
  {
    id: "AgencyMortgage-Backed",
    label: "Agency Mortgage-Backed",
    value: "",
    color: "#FCE700",
  },
  {
    id: "AssetBacked",
    label: "Asset Backed",
    value: "",
    color: "#0002A1",
  },
  {
    id: "BankLoan",
    label: "Bank Loan",
    value: "",
    color: "#400D51",
  },
  {
    id: "CashEquivalents",
    label: "Cash Equivalents",
    value: "",
    color: "#7868E6",
  },
  {
    id: "CommercialMortgageBacked",
    label: "Commercial Mortgage Backed",
    value: "",
    color: "#C7FFD8",
  },
  {
    id: "Convertible",
    label: "Convertible",
    value: "",
    color: "#00263B",
  },
  {
    id: "CorporateBond",
    label: "Corporate Bond",
    value: "",
    color: "#8E3200",
  },
  {
    id: "CoveredBond",
    label: "Covered Bond",
    value: "",
    color: "#470D21",
  },
  {
    id: "ForwardFuture",
    label: "Forward Future",
    value: "",
    color: "#FFDCAE",
  },
  {
    id: "Government",
    label: "Government",
    value: "",
    color: "#5F7161",
  },
  {
    id: "GovernmentRelated",
    label: "Government Related",
    value: "",
    color: "#90007F",
  },
  {
    id: "MunicipalTaxExempt",
    label: "Municipal Tax Exempt",
    value: "",
    color: "#3B503D",
  },
  {
    id: "MunicipalTaxable",
    label: "Municipal Taxable",
    value: "",
    color: "#FA7F72",
  },
  {
    id: "NonAgencyResidentialMortgage",
    label: "NonAgency Residential Mortgage",
    value: "",
    color: "#9951FF",
  },
  {
    id: "OptionWarrant",
    label: "Option Warrant",
    value: "",
    color: "#AA7070",
  },
  {
    id: "PreferredStock",
    label: "Preferred Stock",
    value: "",
    color: "#A2C11C",
  },
  {
    id: "SectorSwap",
    label: "Sector Swap",
    value: "",
    color: "#0A97B0",
  },
];

export const investmentDetailsTypeConfig = [
  { title: "SIP", filed: "sip", show: false, selected: false },
  { title: "Lumpsum", filed: "lumpsum", show: false, selected: false },
];

export const handleExitLoad = (data) => {
  if (data?.exitLoad[0]) {
    return `${Number(data?.exitLoad[0]?.value).toFixed(
      2
    )}% for redemption width ${data?.exitLoad[0]?.text}`;
  } else {
    return "--";
  }
};

export const handleSip = (data) => {
  let minVal = "--";
  if (data?.sip) {
    if (data?.sip?.length > 0) {
      data?.sip.map((val) => {
        if (val.frequencyType == "MONTHLY") {
          minVal = val?.minimumInstallmentAmount;
        }
      });
    }
  }
  return minVal;
};

export const sipConfig = [
  {
    title: "Inception Date",
    filed: "inceptionDate",
    value: "--",
  },
  {
    title: "Expense Ratio",
    filed: "interimNetExpenseRatio",
    value: "--",
  },
  {
    title: "Cash Holding",
    filed: "cashNet",
    value: "--",
  },
  {
    title: "Bench mark",
    filed: "primaryProspectusBenchmarks[0]?.IndexName",
    value: "--",
  },
  {
    title: "Min Investment Amount",
    filed: "sip.minimumInvestmentAmount",
    value: "--",
  },
  {
    title: "Fund Size",
    filed: "totalAssetsInCr",
    value: "--",
  },
  {
    title: "Exit Load",
    filed: "exitLoad",
    value: "--",
  },
];

export const fundManagerstableConfig = [
  {
    field: "Name",
    title: "Fund Manager",
    show: true,
    align: "left",
  },
  {
    field: "Tenure",
    title: "Tenure",
    show: true,
    align: "right",
  },
];

export const fundHouseConfig = [
  {
    title: "Fund House Name",
    filed: "amcName",
    value: "--",
    size: 6,
  },
  {
    title: "Total Assets Under Management",
    filed: "totalAssetsInCr",
    value: "--",
    size: 6,
  },
  {
    title: "Address of Head Quarter",
    filed: "amcAddress",
    value: "--",
    size: 12,
  },
];

export const holdingDetailsConfigChart = {
  id: "Name",
  label: "",
  value: "Weighting",
  color: "",
};

export const holdingDetailsConfigTable = [
  {
    field: "Name",
    title: "Company Name",
    show: true,
    align: "left",
  },
  {
    field: "Weighting",
    title: "Weight(%)",
    show: true,
    align: "right",
  },
];

export const sectorWiseConfigTable = [
  {
    field: "label",
    title: "Company Name",
    show: true,
    align: "left",
  },
  {
    field: "value",
    title: "Weight(%)",
    show: true,
    align: "right",
  },
];

export const creditRatingConfig = [
  {
    field: "label",
    title: "Credit Quality",
    show: true,
    align: "left",
  },
  {
    field: "value",
    title: "Weight(%)",
    show: true,
    align: "right",
  },
];

export const creditRatingPieChartConfig = {
  id: "id",
  label: "",
  value: "value",
  color: "",
};

export const creditRatingStaticData = {
  "AA+": "60.32538",
  AA: "10.8921",
  Sovereign: "10.80636",
  AAA: "20.70132",
};

let risk = {
  title: "Risk",
  id: "#riskSection",
};

let portfolio = {
  title: "Portfolio Allocation",
  id: "#portfolioAllocation",
};

let topHolding = {
  title: "Top Holdings",
  id: "#topHoldings",
};

let investment = {
  title: "Investment Details",
  id: "#investmentDetails",
};

let fundDetails = {
  title: "Fund Details",
  id: "#fundDetails",
};

let fixedIncome = {
  title: "Fixed Income Attributes",
  id: "#fixedIncomeAttributes",
};

export const hashLinkListEquity = [
  risk,
  portfolio,
  topHolding,
  investment,
  fundDetails,
];

export const hashLinkListDebtAndHybrid = [
  risk,
  fixedIncome,
  portfolio,
  topHolding,
  investment,
  fundDetails,
];

export const riskColor = (risk) => {
  switch (risk) {
    case "Low Risk":
      return "#1ca051";
    case "Low to Moderate Risk":
      return "#80ba47";
    case "Moderate Risk":
      return "#c88e34";
    case "Moderately High risk":
      return "#b96110";
    case "High Risk":
      return "#b91014";
    case "Very High Risk":
      return "#7c0a0d";
    default:
      return "White";
  }
};
