import { fetchLobMaster } from "../api";
import { useState, useEffect } from "react";
export const useFetchMasterLob = () => {
  const [lobList, setLobList] = useState([]);

  useEffect(() => {
    fetchLobMaster()
      .then((response) => {
        if (response?.data?.data?.result) {
          setLobList(response?.data?.data?.result);
        }
      })
      .catch((err) => {
        console.error(`An Error ${err} occurred`);
      });
  }, []);
  return { lobList };
};
