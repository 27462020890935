import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// MUI Component & CSS
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import "./NewFilter.css";

// Components
import { NewFilterHeader } from "./NewFilterHeader";
import { NewFilterFooter } from "./NewFilterFooter";
import { NewFilterBody } from "./NewFilterBody";
import { NewFilterSubHeader } from "./NewFilterSubHeader";
import { NewFilterFamilyMember } from "./NewFilterFamilyMember";
import { NewFilterAdvisor } from "./NewFilterAdvisor";
import { NewFilterExecutedBy } from "./NewFilterExecutedBy";
import { NewFilterAssetClass } from "./NewFilterAssetClass";

// Utils & Constants
import { newFilterMainTab } from "./newFilterConstants";
import { assetClassConfig } from "./config/newFilterAssetClassConfig";
import { get_path_type } from "../../utils/strHelper";

// Custom HOOKS
import { useAllClients } from "./hooks/useAllClients";
import { useAdvisors } from "./hooks/useAdvisors";
import { useExecutedBy } from "./hooks/useExecutedBy";
import { NewFilterPeriod } from "./NewFilterPeriod";
import { NewFilterDenomination } from "./NewFilterDenomination";
import { mfoFilterContext } from "../../../mfo_context/Mfo_Context";
import { useContext } from "react";
import { formatCurrencyFromLocalStorage } from "./utils/newFilterFormatter";
import { NewFilterPromoterStake } from "./NewFilterPromoterStake";

export const NewFilter = ({
  filterModalOpen,
  setFilterModalOpen,
  productType,
}) => {
  const [triggerFetchExecutedBy, setTriggerFetchExecutedBy] = useState(false);
  const [triggerFetchAdvisors, setTriggerFetchAdvisors] = useState(false);
  // const { open, onClose, title, content, onConfirm } = props;
  const location = useLocation();
  const { mfoFilter } = useContext(mfoFilterContext);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [mainTabElement, setMainTabElement] = useState("Family Member");

  // Asset Class States
  const [assetClass, setAssetClass] = useState(assetClassConfig);
  const [selectedAssetClasses, setSelectedAssetClasses] = useState({});
  const [pathtype, setPathtype] = useState("general");

  const [selectedDenomination, setSelectedDenomination] = useState("Default");

  // Period State
  const [selectedPeriod, setSelectedPeriod] = useState("ITD");
  const [dateRange, setDateRange] = useState([null, null]);
  const [clear, setClear] = useState();
  const [buttonDisabled, setbuttonDiabled] = useState(false);

  // Promoter Stake
  const [isPromoterStakeSelected, setIsPromoterStakeSelected] = useState(
    mfoFilter?.promoterStake
  );

  // INDIVIDUAL NON_INDIVIDUAL TAB STATE
  const [subTabElement, setSubTabElement] = useState("head_of_family");

  // 🟢 API For Family Members Tab
  const {
    familyMembers,
    setFamilyMembers,
    setClientName,
    selectedFamilyMembers,
    setSelectedFamilyMembers,
  } = useAllClients(filterModalOpen, subTabElement);

  // 🟢 API For Advisors Tab
  const { advisors, selectedAdvisors, setSelectedAdvisors, advisorsLoading } =
    useAdvisors(
      filterModalOpen,
      productType,
      selectedFamilyMembers, // 👈 advisors are filtered based on member_id
      triggerFetchAdvisors // 👈 Trigger Advisor API only on btn click or tab switch
    );

  // 🟢 API For Executed By Tab
  const {
    executors,
    selectedExecutors,
    setSelectedExecutors,
    executedByLoading,
  } = useExecutedBy(
    filterModalOpen,
    productType,
    selectedFamilyMembers, // 👈 executors are filtered based on member_id
    triggerFetchExecutedBy // 👈 Trigger ExecutedBy API only on btn click or tab switch
  );

  const handleMainTabClick = (page) => {
    const index = newFilterMainTab(productType).findIndex(
      (tab) => tab.page === page
    );
    const tabId = newFilterMainTab(productType)[index]?.id;
    if (tabId === "familyMember") {
      /*
      Reset The Flag Because if the user selects members,
      then again API call needs to be made.
      */
      setTriggerFetchExecutedBy(false);
      setTriggerFetchAdvisors(false);
    }
    if (tabId === "advisor") {
      setTriggerFetchAdvisors(true);
    }
    if (tabId === "executedBy") {
      setTriggerFetchExecutedBy(true);
    }

    if (index !== -1) {
      setCurrentTabIndex(index);
    }
    setMainTabElement(page);
  };
  console.log("mfoFilter ds", selectedExecutors, selectedFamilyMembers);
  // Reset mainTabElement to default value when the modal is closed and reopened
  useEffect(() => {
    setSelectedDenomination(
      formatCurrencyFromLocalStorage(localStorage?.getItem("currency_format"))
    );
    // On Filter Modal Open set the selected family member from context
    setSelectedFamilyMembers(
      Object.keys(mfoFilter?.checkData)?.map(
        (data, index) => mfoFilter?.checkData[data]
      )
    );
    // On Filter Modal Open set the selected advisor from context
    setSelectedAdvisors(
      Object.keys(mfoFilter?.advisor).filter((key) => mfoFilter?.advisor[key])
    );
    // On Filter Modal Open set the selected executor from context
    setSelectedExecutors(
      Object.keys(mfoFilter?.executor).filter((key) => mfoFilter?.executor[key])
    );
    console.log("mfoFilter?.checkData", mfoFilter?.checkData);
    if (!filterModalOpen) {
      setMainTabElement("Family Member");
      setCurrentTabIndex(0);
    }
  }, [filterModalOpen]);

  // 👇 Asset Class Effect
  // useEffect(() => {
  //   const path_type = get_path_type(location.pathname);
  //   const defaultSelection = assetClass[path_type];
  //   setSelectedAssetClasses(defaultSelection);
  //   setPathtype(path_type);
  // }, [location.pathname]);
  // 👇 Asset Class Effect

  useEffect(() => {
    const path_type = get_path_type(location.pathname);

    // Check if the mfoFilter for the current path_type has any selected asset classes
    const assetClassesFromContext = mfoFilter.assetclass?.[path_type];

    // If there are selections in the context, use those, otherwise fall back to default
    const assetClassesToSet =
      assetClassesFromContext ?? assetClassConfig[path_type];

    setSelectedAssetClasses(assetClassesToSet);
    setPathtype(path_type);
  }, [location.pathname, mfoFilter.assetClass, assetClassConfig]);

  useEffect(() => {
    if (mfoFilter.startDate && mfoFilter.endDate) {
      const startDate = new Date(mfoFilter.startDate);
      const endDate = new Date(mfoFilter.endDate);
      setDateRange([startDate, endDate]);
    }
    // Set Period From Context
    setSelectedPeriod(mfoFilter?.selectedPeriod);
  }, [filterModalOpen]);

  // Whenever the Product Type Changes Reset Promoter Stake to True
  // useEffect(() => {
  //   setIsPromoterStakeSelected(1);
  // }, [productType]);
  return (
    <Dialog
      open={filterModalOpen}
      // Click on backdrop
      onClose={() => setFilterModalOpen((prev) => !prev)}
      fullWidth
      maxWidth="lg"
    >
      <NewFilterHeader
        selectedFamilyMembers={selectedFamilyMembers}
        familyMembers={familyMembers}
        selectedAdvisors={selectedAdvisors}
        advisors={advisors}
        selectedExecutors={selectedExecutors}
        executors={executors}
        selectedDenomination={selectedDenomination}
        selectedPeriod={selectedPeriod}
        dateRange={dateRange}
        closeIcon={
          <IconButton
            aria-label="close"
            onClick={() => setFilterModalOpen((prev) => !prev)}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        }
      />
      <NewFilterSubHeader
        handleMainTabClick={handleMainTabClick}
        currentTabIndex={currentTabIndex}
        selectedFamilyMembers={selectedFamilyMembers}
        productType={productType}
      />
      <NewFilterBody
        newFilterFamilyMember={
          <NewFilterFamilyMember
            selectedFamilyMembers={selectedFamilyMembers}
            setSelectedFamilyMembers={setSelectedFamilyMembers}
            familyMembers={familyMembers}
            setClientName={setClientName}
            setFamilyMembers={setFamilyMembers}
            setSubTabElement={setSubTabElement}
            subTabElement={subTabElement}
          />
        }
        newFilterAdvisor={
          <NewFilterAdvisor
            selectedAdvisors={selectedAdvisors}
            setSelectedAdvisors={setSelectedAdvisors}
            advisors={advisors}
            advisorsLoading={advisorsLoading}
          />
        }
        newFilterExecutedBy={
          <NewFilterExecutedBy
            selectedExecutors={selectedExecutors}
            setSelectedExecutors={setSelectedExecutors}
            executors={executors}
            executedByLoading={executedByLoading}
          />
        }
        newFilterAssetClass={
          <NewFilterAssetClass
            selectedAssetClasses={selectedAssetClasses}
            setSelectedAssetClasses={setSelectedAssetClasses}
          />
        }
        newFilterPromoterStake={
          <NewFilterPromoterStake
            isPromoterStakeSelected={isPromoterStakeSelected}
            setIsPromoterStakeSelected={setIsPromoterStakeSelected}
          />
        }
        newFilterDenomination={
          <NewFilterDenomination
            selectedDenomination={selectedDenomination}
            setSelectedDenomination={setSelectedDenomination}
          />
        }
        newFilterPeriod={
          <NewFilterPeriod
            selectedAssetClasses={selectedAssetClasses}
            selectedFamilyMembers={selectedFamilyMembers}
            productType={productType}
            selectedPeriod={selectedPeriod}
            setSelectedPeriod={setSelectedPeriod}
            dateRange={dateRange}
            setDateRange={setDateRange}
            clear={clear}
            setbuttonDiabled={setbuttonDiabled}
            setClear={setClear}
          />
        }
        mainTabElement={mainTabElement}
      />
      <NewFilterFooter
        disabled={buttonDisabled}
        handleMainTabClick={handleMainTabClick}
        nextTabElement={""}
        currentTabIndex={currentTabIndex}
        selectedFamilyMembers={selectedFamilyMembers}
        selectedAdvisors={selectedAdvisors}
        selectedExecutors={selectedExecutors}
        selectedAssetClasses={selectedAssetClasses}
        isPromoterStakeSelected={isPromoterStakeSelected}
        selectedDenomination={selectedDenomination}
        selectedPeriod={selectedPeriod}
        setFilterModalOpen={setFilterModalOpen}
        dateRange={dateRange}
        setTriggerFetchExecutedBy={setTriggerFetchExecutedBy}
        setTriggerFetchAdvisors={setTriggerFetchAdvisors}
        productType={productType}
      />
    </Dialog>
  );
};
