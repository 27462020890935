import React, { useContext } from "react";
import useStyles from "../common/ProductsStyles";
import { Box } from "@material-ui/core";
import { OnboardingContext } from "../../../../Context";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import { useEffect } from "react";
import { useState } from "react";
import { getAdvisorData, getAdvisorTableDataData } from "../../../../api";
import { roundNumber } from "../../../utils/common";
import PortfolioChart from "../../common/portfolioChart";
import PmsReportTable from "../pms/PmsReportTable";
import { advisorModalTable as header } from "./utils/constands";
import PageLoader from "../common/pageLoader";
import { currencyFormat } from "../../../../hooks/constantFunction";
const card1Color = "#1D0A3C";
const card2Color = "#2D1E54";
function HoldingAdvisor() {
  const classes = useStyles();
  const nums = [1, 2, 3, 4, 5, 6, 7, 8];
  const percentageValues = [10, 20, 30, 40, 50, 60];
  const [tableDataLoading, setTableDataLoading] = useState(false);
  const [tableHeader, setTableHeader] = useState(header);
  const [tableColumns, setTableColumns] = useState([]);

  const { setMfofilter, mfoFilter, getfilter_as_params } =
    useContext(mfoFilterContext);
  const { userState } = OnboardingContext();
  const [loading, setLoading] = useState(true);
  const [advisorData, setAdvisorData] = useState([]);

  let params = { ...getfilter_as_params() };
  params["assetClass"] = "equity,debt,cash,alternate";

  const objectFormating = (data) => {
    let newData = tableHeader.map((header) => {
      if (
        header?.field == "totalPortfolioPercentage" ||
        header?.field == "xirr" ||
        header?.field == "absoluteTwrr" ||
        header?.field == "annualisedTwrr"
      ) {
        return {
          key: header?.field,
          field: `${roundNumber(data[header.field])}`,
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (
        header?.field == "investedAmount" ||
        header?.field == "portfolioValueAmount" ||
        header?.field == "interestIncome" ||
        header?.field == "unRealisedGainLoss"
      ) {
        return {
          key: header?.field,
          field: currencyFormat(data[`${header.field}`] || 0) || "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      } else {
        return {
          key: header?.field,
          field: `${data[header.field]}`,
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
    });
    return newData;
  };

  const getTableData = async () => {
    setTableDataLoading(true);

    try {
      const clientId = userState?.loginedUserData?.id;
      let res = await getAdvisorTableDataData(clientId, params, "dashboard");
      let newlist = [];
      res?.map((val) => {
        let obj = objectFormating(val);
        newlist.push(obj);
      });
      setTableColumns(newlist);
      setTableDataLoading(false);
    } catch (error) {
      console.log(error);
      setTableDataLoading(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, [mfoFilter]);
  const clientId = userState.loginedUserData.id;

  return (
    <>
      {
        <PortfolioChart
          type={"dashboard"}
          exp="advisor-wise-portfolio-benchmark"
          headerTitle="Advisor Comparison"
          headerContent=" "
        ></PortfolioChart>
      }
      <div className="topPerformerWrapper" style={{ margin: "3% 1%" }}>
        <div style={{ position: "relative" }}>
          <PmsReportTable
            key={"excess-performance"}
            setTableColumns={setTableColumns}
            tableColumns={tableColumns}
            loading={tableDataLoading}
            headerList={tableHeader}
            tableHeader={tableHeader}
            setTableHeader={setTableHeader}
            stickyProductName={true}
            hidePagination={true}
            tableHeaderBGColor={"#2F1D5B"}
            rowSeperation={"12px"}
          />
          <PageLoader showLoader={tableDataLoading} />
        </div>
      </div>
      {/* <Box
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          overflow: "auto",
        }}
        className="advisorsFlexContainer"
      >
        {advisorData?.map((data, index) => (
          <AdvisorCard data={data} />
        ))}
      </Box> */}
    </>
  );
}

export default HoldingAdvisor;

function AdvisorCard({ data }) {
  const colors = ["#F5D580", "#4F5FCF", "#C5306B", "#175B85"];

  const progressLineWidth = 180 * (data?.percentageShareOfPortfolio / 100);
  return (
    <>
      <Box className="advisorCard1">
        <Box
          className="card1"
          style={{
            boxSizing: "border-box",
            width: "180px",
            background: card1Color,
            padding: "1.5rem",
          }}
        >
          <p>ADVISOR NAME</p>
          <p>{data?.advisor}</p>
          <p>AMOUNT</p>
          <p>{data?.presentAmount}</p>
        </Box>
        <Box
          className="card2"
          style={{
            boxSizing: "border-box",
            width: "180px",
            background: card2Color,
          }}
        >
          <Box
            style={{
              boxSizing: "border-box",
              width: "180px",
              padding: "1.5rem",
            }}
          >
            <p>Allocated %</p>
            <p>{roundNumber(data?.percentageShareOfPortfolio) + "%"}</p>
          </Box>
          <Box
            className="progressLine"
            style={{
              boxSizing: "border-box",
              width: `${progressLineWidth}px`,
              padding: "0.3rem",
              background: colors[Math.floor(Math.random() * colors.length)],
            }}
          ></Box>
        </Box>
      </Box>
    </>
  );
}
