export const assetClassConfig = {
  mfo: { equity: true, debt: true, alternate: true, cash: true },
  aif: { equity: true, debt: true, alternate: true },
  bonds: { debt: true },
  pms: { equity: true, debt: true, alternate: true },
  equity: { equity: true },
  "real-estate": { alternate: true },
  general: { equity: true, debt: true, alternate: true, cash: true },
  portfolio: { equity: true, debt: true, alternate: true, cash: true },
  //insurance: { equity: true, debt: true, alternate: true, cash: true },
  insurance: { alternate: true },
  private: { equity: true },
  structured: { alternate: true },
  mld: { alternate: true },
  invits: { alternate: true },
  reits: { alternate: true },
  icd: { debt: true },
  "fixed-deposit": { debt: true },
  bank: { cash: true },
  "recurring-deposit": { debt: true },
};
