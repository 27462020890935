// useUserManagementForm.js
import { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

export const useUserManagementForm = ({
  initialValues,
  validations,
  onSubmit,
}) => {
  const fullNameOutsideClickRef = useRef(null);
  const mobileNumberOutsideClickRef = useRef(null);
  const emailIdOutsideClickRef = useRef(null);
  const [intVal, setIntVal] = useState(initialValues);

  const [isEditingFullName, setIsEditingFullName] = useState(false);
  const [isEditingMobileNumber, setIsEditingMobileNumber] = useState(false);
  const [isEditingEmailId, setIsEditingEmailId] = useState(false);

  // useEffect(() => {
  //   setIntVal(() => initialValues);
  // }, [initialValues]);

  const validationSchema =
    validations ||
    Yup.object({
      fullName: Yup.string().required("Full Name is required"),
      mobileNumber: Yup.string()
        .required("Mobile Number is Required")
        .matches(/^\d{10}$/, "Mobile Number must be exactly 10 digits"),
      emailId: Yup.string()
        .email("Invalid email format")
        .required("Email is Required"),
    });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        (fullNameOutsideClickRef.current &&
          !fullNameOutsideClickRef.current.contains(event.target)) ||
        (mobileNumberOutsideClickRef.current &&
          !mobileNumberOutsideClickRef.current.contains(event.target)) ||
        (emailIdOutsideClickRef.current &&
          !emailIdOutsideClickRef.current.contains(event.target))
      ) {
        // The click occurred outside the component.
        setIsEditingFullName(false);
        setIsEditingMobileNumber(false);
        setIsEditingEmailId(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return {
    formik,
    isEditingFullName,
    setIsEditingFullName,
    isEditingMobileNumber,
    setIsEditingMobileNumber,
    isEditingEmailId,
    setIsEditingEmailId,
    fullNameOutsideClickRef,
    mobileNumberOutsideClickRef,
    emailIdOutsideClickRef,
  };
};
