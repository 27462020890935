import React, { useState } from "react";
import HoldingPrint from "./subPrintPages/HoldingPrint";
import {
  ClientHolding,
  newpdfHoldingsRealEstate,
  newPdfSecurityHolding,
  newpdfHolding,
  newpdfHoldings,
  newpdfTransactions,
  newpdfHoldingsInsuranceNonUlip,
  newpdfHoldingsInsuranceUlip,
  newAifPdfHoldings,
  newDirectEquityPdfHoldings,
  newpdfHoldingsBankBook,
  newpdfTransactionsFundsInTransit,
  newpdfHoldingsFundInTransit,
  familyholding,
  newpdfHoldingSecurityBankBook,
  newpdfSecurityHoldings,
  newPmsPdfHoldings,
  newFDRDPMSPdfHoldings,
} from "../Components/utils/staticData";
import { Link } from "react-router-dom";
import Overall_portfolioHolding from "./Overall_portfolioHolding";
import { objectFormatingFamilyHolding } from "../Components/utils/common";
import MemberReport from "./MemberReport";
import MemberHolding from "./MemberHolding";

export default function HoldingPdfPagev2({
  newData,
  denomination,
  reportType,
  setChanges,
  pdfInfo,
  key1,
  breadcrum,
}) {
  const colsWithSubtext = [
    "Bonds",
    "Real Estate Investment Trusts",
    "Infrastructure Investment Trusts",
    "AIF",
  ];
  // console.log("security");
  const SECURITY_HOLDING_SUMMARY =
    "Consolidated Securities Holding Summary Report";

  // for security holding
  function modifySecurityFirstcol(p) {
    let colarr = renderSecurityCustomTableHeaderBasedOnReportType(p);
    colarr = JSON.parse(JSON.stringify(colarr));
    colarr[0]["subtext"] = <div className="s_text italic1">Weightage </div>;
    let index = colarr.findIndex((a, i) => {
      if (a?.title == "Current Value") {
        return true;
      }
    });
    let index2 = colarr.findIndex((a, i) => {
      if (a?.title == "Invested Value") {
        return true;
      }
    });
    let index3 = colarr.findIndex((a, i) => {
      if (a?.title == "Quantity") {
        return true;
      }
    });
    if (index3 != -1 && index3) {
      colarr[index3]["subtext"] = (
        <div
          className="s_text italic1"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            whiteSpace: "nowrap",
          }}
        >
          Avg Buy Price (₹)
        </div>
      );
      colarr[index3]["subdata"] = "weightedAvgCost";
    }
    if (colarr[index]?.title == "Current Value") {
      colarr[index]["subtext"] = (
        <div
          className="s_text italic1"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          Valuation As On
        </div>
      );
    }
    if (colsWithSubtext.includes(p)) {
      colarr[index2]["subdata"] = "principalPayback";
      colarr[index2]["subtext"] = (
        <div className="s_text italic1">Principal Payback</div>
      );
    }

    return colarr;
  }
  function renderSecurityCustomTableHeaderBasedOnReportType(product) {
    if (reportType === "clientHolding") {
      return ClientHolding;
    } else if (reportType === "transactionSummary") {
      return newpdfTransactions;
    } else {
      if (product == "Real Estate") {
        return newpdfHoldingsRealEstate;
      } else if (product == "Insurance (Non ULIP)") {
        return newpdfHoldingsInsuranceNonUlip;
      } else if (product == "Insurance (ULIP)") {
        return newpdfHoldingsInsuranceUlip;
      } else if (product == "Bank Book") {
        return newpdfHoldingSecurityBankBook;
      } else if (product == "Funds In Transit") {
        return newpdfHoldingsFundInTransit;
      }
      if (product == "AIF" || product == "Mutual Funds") {
        return newpdfSecurityHoldings;
      }
      if (product == "Direct Equity") {
        return newDirectEquityPdfHoldings;
      }
      if (product == "PMS") {
        return newPmsPdfHoldings;
      } else if (product == "Fixed Deposit" || product == "Recurring Deposit") {
        return newFDRDPMSPdfHoldings;
      } else {
        return newAifPdfHoldings;
      }
    }
  }

  const breadcrumbs = [
    <a key="1" color="#000000" className="printBreadsel">
      Holding Summary
    </a>,
  ];
  // console.log(
  //   newData?.[key||"holdingSummary"] && Object?.keys(newData?.[key||"holdingSummary"]),
  //   "newData"
  // );

  function generateSecurityPages(mainkey) {
    // console.log("in security holding");
    const sortdata = newData?.["asset-wise-product-sort-order"];
    let securitybread = [
      <a key="1" color="#000000" className="printBreadsel">
        Security Holding Summary
      </a>,
    ];

    let filteredSortData = Object.keys(sortdata).filter((f) => {
      let found = Object.keys(
        newData[mainkey || "holdingSummary"]?.assetClass?.[f]
      ).find((prod) => {
        if (
          newData[mainkey || "holdingSummary"]?.assetClass?.[f]?.[prod] &&
          newData[mainkey || "holdingSummary"]?.assetClass?.[f]?.[prod]
            ?.investments?.length != 0
        ) {
          return true;
        }
      });
      if (found) {
        return f;
      }
    });
    return filteredSortData?.map((arr, j) => {
      let bread = [...securitybread];
      bread.push(
        <Link
          underline="hover"
          key="2"
          color="
  #000000"
        >
          <span className="opc80"> Asset Class :</span>
          <span className="pdfselectedTxt pdfCaps">{arr}</span>
        </Link>
      );
      //   console.log("arr", arr);
      let count = 0;
      let filteredSort = sortdata?.[arr].filter((assetProduct) => {
        if (
          newData[mainkey || "holdingSummary"]?.assetClass?.[arr]?.[
            assetProduct
          ] &&
          newData[mainkey || "holdingSummary"]?.assetClass?.[arr]?.[
            assetProduct
          ]?.investments?.length != 0
        ) {
          return assetProduct;
        }
      });
      let len = filteredSort.length;
      return filteredSort?.map((p, pno) => {
        let breadcrum = [...bread];
        breadcrum.push(
          <Link
            underline="hover"
            key="2"
            color="
        #000000"
          >
            <span className="opc80">Product :</span>
            <span className="pdfselectedTxt">{p}</span>
          </Link>
        );
        let footerLegend = [];
        let footerLegendNotes = [];
        let dt = newData[mainkey || "holdingSummary"]?.assetClass?.[arr]?.[p];
        let productLen = Object.keys(
          newData[mainkey || "holdingSummary"]?.assetClass?.[arr]
        ).length;
        try {
          if (dt?.investments?.length == 0 || !dt?.investments) {
            return <></>;
          }
        } catch (err) {
          console.log(err, "err");
        }
        if (p.toLowerCase() == "aif") {
          footerLegendNotes.push({
            note: "Note - Negative Values - in other income denote equalisation paid.",
          });
        }
        if (p == "Funds In Transit") {
          dt?.investments?.map((arr) => {
            arr.member = dt?.member;
          });
        }

        if (p.toLowerCase() == "unlisted equity") {
          dt?.investments?.map((arr) => {
            arr.CurrentValIndicator = true;
          });
          footerLegend.push({ symbol: "^", text: "Indicative Price" });
          footerLegend.push({ symbol: "**", text: "Promoter stake" });
        }
        if (p.toLowerCase() == "direct equity") {
          footerLegend.push({ symbol: "**", text: "Promoter stake" });
        }

        let grandTotal;
        let refForTotal = dt?.totalValues;
        if (p == "Bank Book") {
          dt?.investments.push({
            schemeName: "Total",
            currentValue: refForTotal.currentValue,
          });
        } else if (p == "Funds In Transit") {
          dt?.investments.push({
            member: "Total",
            amount: refForTotal.totalBalance,
          });
        } else {
          dt?.investments?.push({
            " % of Total Portfolio": refForTotal?.["% of Portfolio"],
            "Absolute TWRR": refForTotal?.["Absolute TWRR"],
            "Advised By": "--",
            "Annualized TWRR": refForTotal?.["Annualised TWRR"],
            "Asset Class": "--",
            currentValue: refForTotal?.["currentValue"],
            "Folio No": "--",
            unRealisedGainLoss: refForTotal?.["unrealisedGainLoss"],
            "ISIN No/Product Code": "--",
            schemeName: "Total",
            "Last Price Date": "--",
            "Market Price per Unit": "--",
            "Member Name": "--",
            otherIncome: refForTotal?.["otherIncome"],
            "Product Name": "--",
            "Quantity/Units": "--",
            totalCost:
              refForTotal?.["totalCost"] || refForTotal?.["investmentValue"],
            xirr: refForTotal?.["xirr"],
            coverAmount: refForTotal?.coverAmount,
            premium: refForTotal?.premium,
          });
          if (pno == len - 1) {
            newData?.["dashboard-asset-details"]?.data.find((a) => {
              if (arr == a.assetClass) {
                a.schemeName = "Sum Total";
                a.instruments =
                  newData[mainkey || "holdingSummary"]?.totalInstruments?.[arr];
                a.noUniqueInstruments = true;
                a.totalCost = a.investedAmount;
                a.otherIncome = a.interestIncome;
                a.currentValue = a.portfolioValueAmount;
                a.asset = arr;
                a.bankName = "Sum Total";
                grandTotal = a;
              }
            });
            dt?.investments?.push(grandTotal);

            if (j == Object.keys(filteredSortData).length - 1) {
              let grandTotal1 = {
                " % of Total Portfolio":
                  newData?.["dashboard-gain-loss"]?.totalPortfolioPercentage,

                "Asset Class": "--",
                currentValue:
                  newData?.["dashboard-gain-loss"]?.portfolioValueAmount,
                "Folio No": "--",
                unRealisedGainLoss:
                  newData?.["dashboard-gain-loss"]?.unRealisedGainLoss,
                "ISIN No/Product Code": "--",

                "Last Price Date": "--",
                "Market Price per Unit": "--",
                "Member Name": "--",
                otherIncome: newData?.["dashboard-gain-loss"]?.interestIncome,
                "Product Name": "--",
                "Quantity/Units": "--",
                totalCost: newData?.["dashboard-gain-loss"]?.investedAmount,
                xirr: newData?.["dashboard-xirr"]?.xirrValue,
                // coverAmount: refForTotal?.coverAmount,
                // premium: refForTotal?.premium,

                currentValue:
                  newData?.["dashboard-gain-loss"]?.portfolioValueAmount,
                otherIncome: newData?.["dashboard-gain-loss"]?.interestIncome,
                schemeName: "Grand Total",
                instruments:
                  newData?.["securities-holding-summary"]?.totalInstruments
                    ?.total,
                balance: newData?.["dashboard-gain-loss"]?.totalBalance,
              };
              dt?.investments?.push(grandTotal1);
            }
          }
        }
        // if (
        //   i ==
        //   newData[key1 || "holdingSummary"].assetClass?.[arr][p]?.investments
        //     ?.length -
        //     1
        // ) {
        //   grandTotal =
        //     newData[key1 || "holdingSummary"].assetClass?.[arr][p]
        //       ?.totalValues;
        //   if (p == "Funds In Transit") {
        //   } else if (p == "Bank Book") {
        //     grandTotal.bankName = "Grand Total";
        //     grandTotal.balance = grandTotal.totalBalance;
        //     prod?.holdings?.push(grandTotal);
        //   } else {
        //     grandTotal.schemeName = "Grand Total";
        //     prod?.holdings?.push(grandTotal);
        //   }
        // }
        return (
          <div className="newPrint pagebreakTable">
            <HoldingPrint
              headerType={reportType == "executiveHolding" ? "11" : 13}
              title={breadcrum || "Holding Summary"}
              footerLegendText={footerLegend}
              footerLegendNotes={footerLegendNotes}
              pdfInfo={pdfInfo}
              asOnDate={refForTotal?.asOnDate}
              layout={1}
              title2={p == "Funds In Transit" ? "" : ""}
              title1={p}
              customTableHeader={modifySecurityFirstcol(p)}
              subtext={""}
              customKey={p}
              type={"holdingSummary"}
              loading={false}
              setChanges={setChanges}
              breadcrumb={breadcrum}
              // error={data?.equity?.direct_equity_holding_summary?.error}
              customData={
                // data?.equity?.direct_equity_holding_summary?.data?.data?.result
                // stDataHolding

                dt?.investments
              }
              customTableClass={"printTableEvenAndOddRow"}
              hideTitle={true}
              hideTotalRow={true}
              denomination={denomination}
              // colmapping={objectFormatingWithClientHoldingTitleCOl}
            />
          </div>
        );
      });
    });
  }

  function generateMemberPages() {
    return (
      <MemberHolding
        sortData={newData?.["asset-wise-product-sort-order"]}
        newData={newData?.["holdingSummary"]}
        denomination={denomination}
        setChanges={setChanges}
        grandTotal={{
          grand: newData?.["dashboard-gain-loss"],
          xirr: newData?.["dashboard-xirr"],
          totalInstruments:
            newData?.["securities-holding-summary"]?.totalInstruments,
        }}
        pdfInfo={pdfInfo}
      />
    );
  }

  function generateFamilyHoldings() {
    console.log("Family Holding");
    let data = newData?.[
      "dashboard-member-asset-wise-holdings"
    ]?.memberAssetWiseHoldings?.map((m) => {
      let dt = {
        ...m?.holdings,
        memberName: m?.memberName,

        totalPortfolioValue: m?.totalPortfolioValue,
      };
      return dt;
    });
    data?.push({
      ...(newData?.["dashboard-member-asset-wise-holdings"]?.grandTotal ?? {}),
      totalPortfolioValue:
        newData?.["dashboard-asset-summary"].totalPortfolioAmount,
      memberName: "Total",
    });
    // console.log("data", data);
    const breadcrumbs = [
      <a key="1" color="#000000" className="printBreadsel">
        Consolidated Family Holdings
      </a>,
    ];
    return (
      <div className="newPrint pagebreakTable">
        {data && data.length > 0 && (
          <HoldingPrint
            headerType={13}
            title={breadcrumbs}
            footerLegendText={""}
            pdfInfo={pdfInfo}
            // asOnDate={refForTotal?.asOnDate}
            layout={1}
            title2={""}
            title1={""}
            customTableHeader={familyholding}
            subtext={""}
            customKey={""}
            type={"holdingSummary"}
            loading={false}
            setChanges={() => {}}
            breadcrumb={breadcrumbs}
            // error={data?.equity?.direct_equity_holding_summary?.error}
            customData={data}
            customTableClass={"printTableEvenAndOddRow"}
            hideTitle={true}
            hideTotalRow={true}
            denomination={denomination}
            colmapping={objectFormatingFamilyHolding}
          />
        )}
      </div>
    );
  }

  return (
    <>
      <div className="newPrint">
        <Overall_portfolioHolding
          newData={newData}
          denomination={denomination}
          // data={data}
          pdfInfo={pdfInfo}
        />
      </div>
      {
        // newData?.memberAssetWiseHoldings &&
        //   newData?.memberAssetWiseHoldings != "error" &&

        newData && generateFamilyHoldings()
      }
      {newData?.["securities-holding-summary"] &&
        generateSecurityPages("securities-holding-summary")}
      {newData?.[key1 || "holdingSummary"] && generateMemberPages()}
      {/* {newData?.[key1 || "holdingSummary"] && generateTablePages()} */}
    </>
  );
}
