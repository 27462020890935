import React, { useContext, useState } from "react";
// import { verifyOTPPartner, sendOTP, reSendOTP } from "../api";
// import { headers } from "../components/partner/utils/utils";

import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import { OnboardingContext } from "../../../../Context";
import {
  fetchAlternateSub_Allocation,
  bottomPerfromers,
  fetchAssetDetailView,
  fetchAsset_Allocation,
  fetchDrawdown,
  fetchEngagement,
  fetchHoldingSummary,
  fetchMarketcap,
  fetchProductSummary,
  fetchTop5AIF,
  fetchTop5Amc,
  fetchTop5Stocks,
  fetchTopFiveIssuers,
  fetchTwrr,
  fetchXirr,
  fetch_marketCapitalisation,
  getRealisedData,
  topPerfromers,
  fetchTop5Sectors,
  getCashFlow2,
  getMfFundPerformance,
} from "../../../../api";

export const useApi = (payload, setReset) => {
  const { userState } = OnboardingContext();

  const [showLoader, setShowLoader] = useState(true);
  const [customData, setSData] = useState({});
  const [errorMessage, setErrorMessage] = useState([]);
  const { setMfofilter, mfoFilter, getfilter_as_params } =
    useContext(mfoFilterContext);

  //all apis

  const [pagewise, setPagewise] = useState("alternate1");
  const [erroredAPIS, setErroredAPIS] = useState([]);
  // let apiLegnth = 0;
  let clientId = userState?.loginedUserData?.id;
  // let clientId = 199;
  let apiArray = (common_params = payload ? payload : {}) => [
    {
      name: "dashboard",
      type: "currentHoldingRealizedData",
      fn: getRealisedData.bind(
        null,
        clientId,
        { ...common_params, assetClass: "equity,debt,alternate,cash" },
        "dashboard",
        true
      ),
    },
    {
      name: "dashboard",
      type: "portfolioPermormance",
      fn: getMfFundPerformance.bind(
        null,
        clientId,
        { ...common_params, assetClass: "equity,debt,alternate,cash" },
        "dashboard",
        "portfolio-performance-graph",
        true
      ),
    },
    {
      name: "dashboard",
      type: "xirr",
      fn: fetchXirr.bind(
        null,
        clientId,
        { ...common_params, assetClass: "equity,debt,alternate,cash" },
        "dashboard"
      ),
    },
    {
      name: "dashboard",
      type: "twrr",
      fn: fetchTwrr.bind(
        null,
        clientId,
        { ...common_params, assetClass: "equity,debt,alternate,cash" },
        "dashboard"
      ),
    },
    {
      name: "dashboard",
      type: "realiseddata",
      fn: getRealisedData.bind(
        null,
        clientId,
        { ...common_params, assetClass: "equity,debt,alternate,cash" },
        "dashboard"
      ),
    },
    {
      name: "dashboard",
      type: "asset",
      fn: fetchAssetDetailView.bind(
        null,
        clientId,
        { ...common_params, assetClass: "equity,debt,alternate,cash" },
        "dashboard"
      ),
    },
    {
      name: "dashboard",
      type: "asset-equity",
      fn: fetchProductSummary.bind(
        null,
        clientId,
        clientId,
        { ...common_params, assetClass: "equity" },
        "dashboard"
      ),
    },
    {
      name: "dashboard",
      type: "asset-debt",
      fn: fetchProductSummary.bind(
        null,
        clientId,
        clientId,
        { ...common_params, assetClass: "debt" },
        "dashboard"
      ),
    },

    {
      name: "dashboard",
      type: "asset-alternate",
      fn: fetchProductSummary.bind(
        null,
        clientId,
        clientId,
        { ...common_params, assetClass: "alternate" },
        "dashboard"
      ),
    },

    {
      name: "dashboard",
      type: "asset-cash",
      fn: fetchProductSummary.bind(
        null,
        clientId,
        clientId,
        { ...common_params, assetClass: "cash" },
        "dashboard"
      ),
    },
    {
      name: "equity",
      type: "market_capitalization",
      fn: fetchMarketcap.bind(
        null,
        clientId,
        { ...common_params, assetClass: "equity" },
        "equity"
      ),
    },
    {
      name: "equity",
      type: "direct_equity_holding_summary",
      fn: fetchHoldingSummary.bind(
        null,
        clientId,
        { ...common_params, assetClass: "equity" },
        "equity"
      ),
    },
    {
      name: "debt",
      type: "bonds_holding_summary",
      fn: fetchHoldingSummary.bind(
        null,
        clientId,
        { ...common_params, assetClass: "debt" },
        "bonds"
      ),
    },
    {
      name: "debt",
      type: "st_holding_summary",
      fn: fetchHoldingSummary.bind(
        null,
        clientId,
        { ...common_params, assetClass: "debt" },
        "structured"
      ),
    },
    {
      name: "debt",
      type: "mf_holding_summary",
      fn: fetchHoldingSummary.bind(
        null,
        clientId,
        { ...common_params, assetClass: "debt" },
        "mf"
      ),
    },
    {
      name: "cash",
      type: "mf_holding_summary",
      fn: fetchHoldingSummary.bind(
        null,
        clientId,
        { ...common_params, assetClass: "cash" },
        "mf"
      ),
    },
    {
      name: "equity",
      type: "mf_holding_summary",
      fn: fetchHoldingSummary.bind(
        null,
        clientId,
        { ...common_params, assetClass: "equity" },
        "mf"
      ),
    },
    {
      name: "alternate",
      type: "reits_holding_summary",
      fn: fetchHoldingSummary.bind(
        null,
        clientId,
        { ...common_params, assetClass: "alternate" },
        "reits"
      ),
    },
    {
      name: "reporting",
      type: "reporting_top5_Aif",
      fn: fetchTop5AIF.bind(
        null,
        clientId,
        { ...common_params, assetClass: "equity,debt,alternate,cash" },
        "reporting"
      ),
    },
    {
      name: "reporting",
      type: "reporting_top5_Issuer",
      fn: fetchTopFiveIssuers.bind(
        null,
        clientId,
        { ...common_params, assetClass: "equity,debt,alternate,cash" },
        "reporting"
      ),
    },
    {
      name: "reporting",
      type: "reporting_top5_Amc",
      fn: fetchTop5Amc.bind(
        null,
        clientId,
        { ...common_params, assetClass: "equity,debt,alternate,cash" },
        "reporting"
      ),
    },
    {
      name: "reporting",
      type: "reporting_engagementmodel",
      fn: fetchEngagement.bind(
        null,
        clientId,
        { ...common_params, assetClass: "equity,debt,alternate,cash" },
        "reporting"
      ),
    },
    {
      name: "equity",
      type: "reporting_Drawdown_Summary",
      fn: fetchDrawdown.bind(
        null,
        clientId,
        { ...common_params, assetClass: "equity" },
        "reporting"
      ),
    },
    {
      name: "debt",
      type: "reporting_Drawdown_Summary",
      fn: fetchDrawdown.bind(
        null,
        clientId,
        { ...common_params, assetClass: "debt" },
        "reporting"
      ),
    },
    {
      name: "alternate",
      type: "reporting_Drawdown_Summary",
      fn: fetchDrawdown.bind(
        null,
        clientId,
        { ...common_params, assetClass: "alternate" },
        "reporting"
      ),
    },
    {
      name: "cash",
      type: "reporting_Drawdown_Summary",
      fn: fetchDrawdown.bind(
        null,
        clientId,
        { ...common_params, assetClass: "cash" },
        "reporting"
      ),
    },
    {
      name: "equity",
      type: "reporting_top5_Stocks",
      fn: fetchTop5Stocks.bind(
        null,
        clientId,
        { ...common_params, assetClass: "equity" },
        "reporting"
      ),
    },
    {
      name: "reporting",
      type: "equity_topPerfromers",
      fn: topPerfromers.bind(
        null,
        clientId,
        { ...common_params, assetClass: "equity" },
        "reporting"
      ),
    },
    {
      name: "reporting",
      type: "cash_topPerfromers",
      fn: topPerfromers.bind(
        null,
        clientId,
        { ...common_params, assetClass: "cash" },
        "reporting"
      ),
    },
    {
      name: "reporting",
      type: "debt_topPerfromers",
      fn: topPerfromers.bind(
        null,
        clientId,
        { ...common_params, assetClass: "debt" },
        "reporting"
      ),
    },
    {
      name: "reporting",
      type: "alternate_topPerfromers",
      fn: topPerfromers.bind(
        null,
        clientId,
        { ...common_params, assetClass: "alternate" },
        "reporting"
      ),
    },
    {
      name: "reporting",
      type: "equity_bottomPerfromers",
      fn: bottomPerfromers.bind(
        null,
        clientId,
        { ...common_params, assetClass: "equity" },
        "reporting"
      ),
    },
    {
      name: "reporting",
      type: "cash_bottomPerfromers",
      fn: bottomPerfromers.bind(
        null,
        clientId,
        { ...common_params, assetClass: "cash" },
        "reporting"
      ),
    },
    {
      name: "reporting",
      type: "debt_bottomPerfromers",
      fn: bottomPerfromers.bind(
        null,
        clientId,
        { ...common_params, assetClass: "debt" },
        "reporting"
      ),
    },
    {
      name: "reporting",
      type: "alternate_bottomPerfromers",
      fn: bottomPerfromers.bind(
        null,
        clientId,
        { ...common_params, assetClass: "alternate" },
        "reporting"
      ),
    },
    {
      name: "reporting",
      type: "reporting_Asset_Allocation",
      fn: fetchAsset_Allocation.bind(
        null,
        clientId,
        { ...common_params },
        "reporting"
      ),
    },
    {
      name: "alternate",
      type: "reporting_AlternateSub_Allocation",
      fn: fetchAlternateSub_Allocation.bind(
        null,
        clientId,
        { ...common_params },
        "reporting"
      ),
    },
    {
      name: "equity",
      type: "top5_sector",
      fn: fetchTop5Sectors.bind(
        null,
        clientId,
        { ...common_params, assetClass: "equity,debt,alternate,cash" },
        "reporting"
      ),
    },
    {
      name: "reporting",
      type: "cash_flow",
      fn: getCashFlow2.bind(
        null,
        clientId,
        { ...common_params, assetClass: "equity,debt,alternate,cash" },
        "dashboard"
      ),
    },
  ];

  const CallApis = (
    apiList,
    dt = {},
    start,
    end,
    errorList
    // apiCustomClass
    // common_params = payload ? payload : {}
  ) => {
    const data = { ...dt };
    let errors = [];
    // prettier-ignore

    let pagewise = apiList?.slice(start, end);

    setShowLoader(true);

    Promise.allSettled(
      pagewise.map((val) => {
        return val.fn();
      })
    )
      .then((results) => {
        let obj = {};
        results.forEach((d, i) => {
          if (d.status == "fulfilled") {
            let dt = data[pagewise[i]?.name];

            data[pagewise[i]?.name] = dt
              ? { ...dt, [pagewise[i]?.type]: d?.value }
              : { [pagewise[i]?.type]: d?.value };

            pagewise[i] = undefined;
            // console.log(pagewise.slice(0,i),'aflafjlabakf')
          } else {
            let dt = data[pagewise[i].name];
            data[pagewise[i]?.name] = dt
              ? {
                  ...dt,
                  [pagewise[i].type]: { error: true, data: { result: [] } },
                }
              : { [pagewise[i].type]: { error: true, data: { result: [] } } };
            errors.push({
              [pagewise[i]?.name]: d.reason?.response?.data?.error?.message,
            });
          }
        });
        // setPagewise(pagewise);
        // console.log(pagewise, "afjabbfjkabf");
        // setErroredAPIS((prev) => {
        //   let newList = [];
        //   let oldList = prev.flat().filter((val) => val !== undefined);
        //   let currentList = pagewise.filter((val) => val !== undefined);

        //   console.log(oldList, currentList, "oldListoldListoldListoldList");
        //   oldList.map((val) => {
        //     currentList?.map((val2) => {
        //       console.log(val2, "afajkfhafagbf");
        //     });
        //   });
        //   let oldErrorList = errorList ? errorList : [];
        //   // if (
        //   //   prev?.flat(1)?.length > 0
        //   // ) {
        //   // newList?.flat(1)?.map((val, j) => {
        //   //   if (val) {
        //   //     pagewise?.map((val2, i) => {
        //   //       // if (JSON.stringify(val) === JSON.stringify(val2)) {
        //   //       //   console.log("val89797797979");
        //   //       //   pagewise[i] = undefined;
        //   //       //   newList.flat(1)[j] = undefined;
        //   //       // }
        //   //     });
        //   //   }
        //   // });
        //   // }
        //   // console.log(newList.flat(1), "alfakbfakjbsf");
        //   return [...prev, pagewise];
        // });
        // pagewise = obj;
        setErrorMessage(errors);
        // setShowLoader(false);
        setSData(() => data);
        setReset(false);

        // console.log(apiList?.length, "afnafkjafbkas");

        if (end >= apiList?.length) {
          setShowLoader(false);
          // setErroredAPIS([]);
          let newErrorList = errorList
            ? [...errorList, ...pagewise.filter((val) => val !== undefined)]
            : pagewise.filter((val) => val !== undefined);

          // if (apiList?.length < end) {
          //   console.log(
          //     pagewise.filter((val) => val !== undefined),
          //     "kjbkjbjbkjkj"
          //   );
          //   setErroredAPIS(pagewise);
          // } else {
          // console.log(newErrorList, "kjbkjbjbkjkj");
          setErroredAPIS(newErrorList);
          // }
        } else {
          if (errorList) {
            CallApis(apiList, data, end, end + 3, [
              ...pagewise.filter((val) => val !== undefined),
              ...errorList,
            ]);
          } else {
            CallApis(
              apiList,
              data,
              end,
              end + 3,
              pagewise.filter((val) => val !== undefined)
            );
          }
        }
      })
      .catch((errors) => {
        setShowLoader(false);
        console.log(errors, "afaljshfajsf");
      });
  };

  return {
    showLoader,
    setShowLoader,
    CallApis,
    // pagewise,
    errorMessage,
    customData,
    erroredAPIS,
    apiArray,
    setPagewise,
  };
};
