import React, { useState, useEffect, useContext, useCallback } from "react";
import TabBondSub from "./TabBondSub";
import useStyles from "../common/ProductsStyles";
import { getTransactionsSummary } from "../../../../api";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import { OnboardingContext } from "../../../../Context";
import moment from "moment";
import { currencyFormat } from "../../../../hooks/constantFunction";
import PmsReportTable from "../pms/PmsReportTable";
import {
  bondsTransactionSummaryDateFormat,
  dateFormatCommon,
  roundNumber,
} from "../../../utils/common";
import { useNavigate } from "react-router-dom";
import NewtableTopSec from "../../common/NewtableTopSec";
import { ITEMS_LIMIT_FILES_PARTNER_LIST } from "../../../../Constants";

let align = "center";

const tableColumnsData = [
  {
    field: "scheme_name",
    title: "Scheme/Product Name",
    show: true,
    align: align,
  },
  { field: "advisor", title: "Advised by", show: true, align: align },
  { field: "executed_by", title: "Executed By", show: true, align: align },
  { field: "isin", title: "ISIN No", show: true, align: align },
  { field: "folio_no", title: "Folio Number", show: true, align: align },
  {
    field: "transaction_date",
    title: "Transaction Date",
    show: true,
    secondLine: false,
    align: align,
  },
  {
    field: "issue_open_date",
    title: "Issue Open Date",
    show: true,
    secondLine: false,
    align: align,
  },
  {
    field: "transaction_type",
    title: "Transaction Type",
    show: true,
    align: align,
  },
  {
    field: "holdingPeriod",
    title: "Holding Period",
    show: true,
    align: align,
  },
  { field: "quantity", title: "Quantity", show: true },
  { field: "price", title: "price (Actuals)", show: true },
  { field: "stamp_duty", title: "Stamp Duty (Actuals)", show: true },
  { field: "other_charges", title: "Other Charges (Actuals)", show: true },
  { field: "amount", title: "Amount", show: true },
  {
    field: "yield_to_call_put",
    title: "Yield to Call / Put",
    show: true,
    align: align,
  },
  {
    field: "call_put_date",
    title: "Call/put Date",
    show: true,
    secondLine: false,
    align: align,
  },
  { field: "cumulative_payment", title: "Cumulative payment", show: true },
  { field: "accrual_frequency", title: "Accural Frequency", show: true },
  {
    field: "accural_interest_paid",
    title: "Accural Interest Paid",
    show: true,
  },
  {
    field: "maturity_payment",
    title: "Maturity Payment",
    show: true,
  },
  { field: "cumulative_quantity", title: "Cumulative Quantity", show: true },
];

function BondsTransaction() {
  const classes = useStyles();
  const [tableColumns, setTableColumns] = useState([]);
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const { userState } = OnboardingContext();
  const [tableHeader, setTableHeader] = useState(tableColumnsData);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [checkedTrans, setCheckedTrans] = useState([]);
  const [search, setSearch] = useState("");
  const [pagiNationData, setPageinationData] = useState({
    pageCount: null,
    // limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
  });
  const [offset, setOffset] = useState(0);

  let actualsList = ["price", "stamp_duty", "other_charges"];
  const objectFormating = (data) => {
    let newData = tableHeader.map((header) => {
      if (header?.field == "scheme_name") {
        return {
          field: data[`${header.field}`],
          title: header.title,
          show: header.show,
          onClick: () => {
            navigate("/details/?isin=" + data[`isin`], {
              state: { id: data[`scheme_master_id`] },
            });
          },
          align: header?.align,
        };
      }
      if (
        header?.field == "isin" ||
        header?.field == "folio_no" ||
        header?.field == "executed_by"
      ) {
        return {
          field: data[`${header.field}`] ? data[`${header.field}`] : "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (
        header?.field == "price" ||
        header?.field == "amount" ||
        header?.field == "cumulative_payment" ||
        header?.field == "accural_interest_paid" ||
        header?.field == "maturity_payment" ||
        header?.field == "stamp_duty" ||
        header?.field == "other_charges"
      ) {
        let currencyformat = currencyFormat(data[`${header.field}`] || 0, {
          showDecimal: true,
        });
        if (actualsList.includes(header?.field)) {
          currencyformat = currencyFormat(data[`${header.field}`] || 0, {
            format: "actuals",
            showDecimal: true,
          });
        }
        return {
          field: currencyformat || "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (
        header?.field == "transaction_date" ||
        header?.field == "issue_open_date" ||
        header?.field == "call_put_date" ||
        header?.field == "yield_to_call_put"
      ) {
        return {
          field: bondsTransactionSummaryDateFormat(
            new Date(data[`${header.field}`])
          ),
          // moment(data[`${header.field}`]).format("DD - MM - YYYY")
          //   ? moment(data[`${header.field}`]).format("DD - MM - YYYY")
          //   : "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (header?.field == "holdingPeriod") {
        return {
          field: data[`${header.field}`]
            ? data[`${header.field}`] + " days"
            : "--",
          // moment(new Date(data[`${header.field}`])).format(
          //   "DD - MM - YYYY"
          // ) || "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      return {
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`]))
          : data[`${header.field}`] || "--",
        title: header.title,
        show: header.show,
        align: header?.align,
      };
    });
    return newData;
  };

  const getTableData = async () => {
    setLoading(true);
    try {
      // let params = {
      //   clientId: 199,
      //   // memberId: 1,
      //   // startDate: "2021-04-10",
      //   // endDate: "2022-10-09",
      //   // assetClass: p,
      // };

      let params = {
        clientId: userState?.loginedUserData?.id,
        //clientId: 199,
        search: search,
        ...getfilter_as_params(),
        offset: offset,
        limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
      };

      const res = await getTransactionsSummary(
        "bonds",
        "transaction-summary",
        params
      );
      let newlist = [];
      let transactionList = [];
      res?.data?.data?.result?.map((val) => {
        let obj = objectFormating(val);
        newlist.push(obj);
        // Handle Transaction Type Filter
        if (
          val["transaction_type"] != null &&
          !transactionList
            .map((val) => val?.title)
            .includes(val["transaction_type"])
        ) {
          transactionList.push({
            title: val["transaction_type"],
            show: false,
          });
        }
      });
      setPageinationData((prev) => {
        return { ...prev, pageCount: res?.data?.data?.meta?.count };
      });
      setCheckedTrans(transactionList);
      setTableColumns(newlist);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, [mfoFilter, search, offset]);
  const handleIconClick = () => {
    setOpenModal(!openModal);
  };
  return (
    <div>
      <NewtableTopSec
        handleIconClick={handleIconClick}
        directPageName={"Transaction Summary"}
        product={"Bonds"}
        setSearch={(e) => {
          setOffset(0);
          setSearch(e);
        }}
      />
      <PmsReportTable
        setTableColumns={setTableColumns}
        tableColumns={tableColumns}
        loading={loading}
        setOpenModal={setOpenModal}
        openModal={openModal}
        headerList={tableColumnsData}
        tableHeader={tableHeader}
        setTableHeader={setTableHeader}
        stickyProductName={true}
        headerInTwoLine={true}
        checkedTransList={checkedTrans}
        pageCount={pagiNationData?.pageCount}
        setItemOffset={setOffset}
        pageNumber={offset / ITEMS_LIMIT_FILES_PARTNER_LIST + 1 || 1}
      />
    </div>
  );
}

export default BondsTransaction;
