import React from "react";
import { Box, Radio, withStyles } from "@material-ui/core";
import "./StrategyBuilder.css";
import { green } from "@material-ui/core/colors";

const GreenRadio = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export const StrategyBuilderMemberRadioList = ({
  options,
  selectedOption,
  onChange,
}) => {
  console.log("StrategyBuilderMemberRadioList", options);
  return (
    <Box className="strategyBuilderMemberNameBox">
      {options?.map((option, index) => (
        <Box className="strategyBuilderMemberFormGroup" key={index}>
          <label htmlFor={option?.name}>{option?.name}</label>
          {/* <input
            id={option}
            type="radio"
            name="consolidatedMember"
            value={option}
            onChange={onChange}
            checked={selectedOption === option}
          /> */}
          <GreenRadio
            id={option?.name}
            type="radio"
            // name="consolidatedMember"
            value={option?.name}
            onChange={(event) => onChange(event, index)}
            checked={selectedOption?.name === option?.name}
            name="radio-button-demo"
            inputProps={{ "aria-label": option?.name }}
          />
        </Box>
      ))}
    </Box>
  );
};
