// var CryptoJS = require("crypto-js");
import React, { useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import {
  Button,
  Modal,
  IconButton,
  Typography,
  Tab,
  Tabs,
  Box,
  TextField,
} from "@material-ui/core";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/closeModal.svg";
import "./PrintModal.css";
import { PrintCheckBox } from "./PrintCheckBox";
import { PrintModalCards } from "./PrintModalCards";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Encrypt } from "./utils/common";

const tabsData = [
  { name: "FAMILY MEMBER" },
  { name: "DURATION" },
  { name: "DENOMINATION" },
];

const modalStyle = {
  width: 631,
  height: 724,
  margin: "15px auto",
  background: "#170630",
  borderRadius: "50px",
  outline: "none",
  border: "1px solid #4E189F",
  position: "relative",
};

export const PrintModal = ({
  reportModal,
  setReportModal,
  printModalMembers,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [cards, setCards] = useState(printModalMembers);
  const [selectedCards, setSelectedCards] = useState([]);
  const [selectedValue, setSelectedValue] = useState("pdf");
  const [denomination, setDenomination] = useState("in actuals");
  const [selectedDenomination, setSelectedDenomination] = useState("actuals");
  const [disabledNext, setDisabledNext] = useState(false);
  const navigate = useNavigate();
  const denominations = ["in crores", "in lakhs", "in actuals"];
  const denominationsMap = {
    ["in crores"]: "crores",
    ["in lakhs"]: "lakhs",
    ["in actuals"]: "actuals",
  };

  useEffect(() => {
    setCards(printModalMembers);
  }, [printModalMembers]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  // moment().format("YYYY-MM-DD") Current date
  // moment().add(1, "months").format("YYYY-MM-DD") One month after the current date
  const handleSelectedCards = (member_id) => {
    const clickedCard = cards.find((card) => card.member_id === member_id);
    const isSelected = selectedCards.includes(member_id);

    if (isSelected) {
      setSelectedCards(selectedCards.filter((id) => id !== member_id));
    } else {
      setSelectedCards([...selectedCards, member_id]);
    }
    // const isSelected = selectedCards.some(
    //   (card) => card.member_id === member_id
    // );

    // if (isSelected) {
    //   setSelectedCards(
    //     selectedCards.filter((card) => card.member_id !== member_id)
    //   );
    // } else {
    //   setSelectedCards([...selectedCards, clickedCard]);
    // }
  };

  // const handleChange = (event) => {
  //   const { name, value } = event.target;

  //   if (name === "startDate") {
  //     setStartDate(value);
  //   } else if (name === "endDate") {
  //     setEndDate(value);
  //   }
  // };
  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "startDate") {
      setStartDate(value);
      if (!value && !endDate) {
        setDisabledNext(false);
      } else {
        setDisabledNext(true);
      }
    } else if (name === "endDate") {
      setEndDate(value);
      if (!startDate && !value) {
        setDisabledNext(false);
      } else {
        const start = moment(startDate);
        const end = moment(value);
        const diffMonths = end.diff(start, "months");
        if (!startDate || !value || diffMonths < 1) {
          setDisabledNext(true);
        } else {
          setDisabledNext(false);
        }
      }
    }
  };

  const isInvalidDateRange = () => {
    if (!startDate || !endDate) {
      return false;
    }

    const start = new Date(startDate);
    const end = new Date(endDate);

    // Calculate the difference
    const diffTime = Math.abs(start - end);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (start > end) {
      return "Start date cannot be after end date";
    }
    if (diffDays < 30) {
      return "The minimum duration should be one month";
    }

    return false;
  };

  const handleDenominationClick = (selectedDenomination) => {
    setDenomination(selectedDenomination);
    setSelectedDenomination(denominationsMap[selectedDenomination]);
  };

  const tabs = () => {
    return (
      <Tabs
        style={{
          // 👇 this width is dynamic based on tabs
          width: "90%",
          margin: "25px auto",
          boxShadow: "inset 0 -1px 0 0 #E6ECF0",
          "& .Mui-selected": {
            color: "#7B2BF4",
          },
          "& .MuiTabs-indicator": {
            // backgroundColor: theme.palette.text.selected,
          },
        }}
        value={tabIndex}
        onChange={(e, index) => {
          setTabIndex(index);
        }}
        TabIndicatorProps={{
          style: {
            backgroundColor: "#7B2BF4",
          },
        }}
      >
        {tabsData?.map((tab, index) => (
          <Tab
            id="tab"
            label={tab.name}
            key={tab.name}
            disabled={tab.name === "DENOMINATION" && disabledNext}
            style={{
              minHeight: 53,
              minWidth: 80,
              // remaining tabs font color
              color: "#73788F",
              "&:hover": {
                backgroundColor: "rgba(29, 161, 242, 0.1)",
                "& .MuiTab-wrapper": {
                  color: "#1da1f2",
                },
              },
              "&.Mui-selected": {
                // color: theme.palette.text.selected,
                color: "#7B2BF4",
              },
              "& .MuiTab-wrapper": {
                textTransform: "none",
                fontSize: 15,
                fontWeight: 700,
                color: "#657786",
              },
            }}
          />
        ))}
      </Tabs>
    );
  };

  const handleOpenNewTab = () => {
    const data = {
      type: selectedValue,
      familyMembers: selectedCards,
      duration: {
        startDate: startDate,
        endDate: endDate,
      },
      denomination: selectedDenomination,
    };
    // localStorage.setItem("denomination", selectedDenomination);

    // navigate("/print-report", {
    //   state: data,
    // });
  };

  return (
    <div>
      <Modal
        open={reportModal}
        style={{
          background: "rgba(31, 28, 64, 0.51)",
          backdropFilter: "blur(5px)",
        }}
      >
        <div style={modalStyle}>
          <IconButton id="closeButton" onClick={() => setReportModal(false)}>
            <CloseIcon />
          </IconButton>
          {/* HEADER */}
          <Typography variant="h5" align="center" id="modalHeading">
            Report Export
          </Typography>
          <hr style={{ width: "90%", background: "white" }} />
          <PrintCheckBox
            setSelectedValue={setSelectedValue}
            selectedValue={selectedValue}
          />
          {/* Tab */}
          {tabs()}
          {/* Text */}
          {tabIndex == 0 && (
            <Box className="selected-tab-text">
              <Typography id="text">Member Selection</Typography>
              <p id="subtext">Showing mebers linked directly to you</p>
            </Box>
          )}

          {tabIndex == 1 && (
            <Box className="selected-tab-text">
              <Typography id="text">Duration</Typography>
              <p id="subtext">Please Select your Start and End Date</p>
            </Box>
          )}
          {tabIndex == 2 && (
            <Box className="selected-tab-text">
              <Typography id="text">Denomination</Typography>
              <p id="subtext">Please Select your print report denomination</p>
            </Box>
          )}

          {/* FAMILY MEMBER CARDS */}
          {tabIndex == 0 && (
            <PrintModalCards
              printModalMembers={printModalMembers}
              handleSelectedCards={handleSelectedCards}
              cards={cards}
              selectedCards={selectedCards}
            />
          )}
          {/* DURATION */}
          {tabIndex == 1 && (
            <>
              <Box className="duration-container">
                <TextField
                  className="date-picker"
                  name="startDate"
                  variant="outlined"
                  type="date"
                  value={startDate}
                  onChange={handleChange}
                  error={Boolean(isInvalidDateRange())}
                  helperText={isInvalidDateRange()}
                  InputProps={{
                    inputProps: {
                      max: "9999-12-31", // https://github.com/mui/material-ui/issues/10675
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: { color: "#9488A7" },
                  }}
                  label="Start Date"
                />
                <TextField
                  className="date-picker"
                  name="endDate"
                  variant="outlined"
                  type="date"
                  value={endDate}
                  onChange={handleChange}
                  error={Boolean(isInvalidDateRange())}
                  helperText={isInvalidDateRange()}
                  InputProps={{
                    inputProps: {
                      max: "9999-12-31", // https://github.com/mui/material-ui/issues/10675
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: { color: "#9488A7" },
                  }}
                  label="End Date"
                  disabled={!startDate}
                />
              </Box>
            </>
          )}
          {/* DENOMINATION */}
          {tabIndex == 2 && (
            <Box id="denomination-container">
              {denominations.map((denominationItem, index) => (
                <Box
                  key={index}
                  className={`modal-denomination ${
                    denomination === denominationItem ? "selected" : ""
                  }`}
                  onClick={() => handleDenominationClick(denominationItem)}
                >
                  <Typography className="denomination-text">
                    {denominationItem}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}

          {/* BUTTONS */}
          <Box align="center" style={{ marginTop: "20px" }}>
            <Button id="btn-cancel" onClick={() => setReportModal(false)}>
              Cancel
            </Button>
            {tabIndex == 2 ? (
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to={{
                  pathname: `/print-report`,
                  search: `key=${Encrypt({
                    type: selectedValue,
                    familyMembers: selectedCards,
                    duration: {
                      startDate: startDate,
                      endDate: endDate,
                    },
                    denomination: selectedDenomination,
                  })}`,
                }}
                target="_blank"
              >
                <Button id="btn-next">Confirm</Button>
              </Link>
            ) : (
              <Button
                id="btn-next"
                disabled={disabledNext}
                onClick={
                  tabIndex == 2
                    ? () => handleOpenNewTab()
                    : () => setTabIndex((prev) => prev + 1)
                }
              >
                {tabIndex == 2 ? "Confirm" : "Next"}
              </Button>
            )}
          </Box>
        </div>
      </Modal>
    </div>
  );
};
