import React, { useContext, useEffect, useRef, useState } from "react";
import { getTransactionsSummary, getUpcomingTransactions } from "../api";
import { mfoFilterContext } from "../mfo_context/Mfo_Context";
import { OnboardingContext } from "../Context";
import { ITEMS_LIMIT_FILES_PARTNER_LIST } from "../Constants";

function useGetTableHolding({
  tableName,
  offset,
  sortBy,
  product,
  search,
  showCorpAction,
  setDeleteList,
  setShowDelete,
  holdingCardData,
  xirr,
  twrr,
  setTableColumns,
  promoterStake,
}) {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const { userState } = OnboardingContext();
  const [paginationData, setPaginationData] = useState({ pageCount: 0 });
  const [checkedTrans, setcheckedTrans] = useState([]);
  const numberOfInstrumentsCount = useRef(0);
  const [numberOfInstruments, setNumberOfInstruments] = useState(0);
  const [percentageOfTotalPortfolio, setPercentageOfTotalPortfolio] =
    useState(0);

  useEffect(() => {
    numberOfInstrumentsCount.current = 0;
  }, [product, mfoFilter]);

  const getTableData = async () => {
    setTableColumns([]);
    setTableData([]);
    setPaginationData((prev) => {
      return {
        ...prev,
        pageCount: 0,
      };
    });
    setLoading(true);

    try {
      const filterParams = { ...getfilter_as_params() };
      let assetClass = "";

      if (filterParams["assetClass"]) {
        let prod = product;
        if (product == "re") {
          prod = "real-estate";
        }
        if (product == "ins") {
          prod = "insurancee";
        }
        if (product == "fd") {
          prod = "fixed-deposit";
        }
        if (product == "rd") {
          prod = "recurring-deposit";
        }
        if (product == "mf") {
          prod = "mfo";
        }
        try {
          assetClass = Object?.keys(mfoFilter?.assetclass?.[prod]).join(",");
        } catch (error) {
          console.log("error");
          assetClass = null;
        }
        delete filterParams["assetClass"];
      }

      let params = {
        clientId: userState.loginedUserData.id,
        showCorpAction: showCorpAction,
        ...filterParams,
        // clientId: 197,
        assetClass: assetClass,
        offset: offset,
        sortBy: sortBy,
        limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
        promoterStake: promoterStake,
      };
      if (product == "upcoming transaction") {
        delete params.promoterStake;
        delete params.showCorpAction;
        delete params.advisor;
        delete params.executor;
        delete params.endDate;
        delete params.startDate;
      }

      if (search) {
        params["search"] = search;
      }
      if (product === "ins") {
        params["insuranceType"] = "life";
        params["coverReturnType"] = "return";
        params["productSubCategory"] = "ulip";
      }

      let tableName1 = "";

      if (tableName == "transaction") {
        tableName1 = "transaction-summary";
        // params["showCorpAction"] = false;
      } else if (tableName == "security-holding") {
        tableName1 = "securities-holding-summary";
        // params["showCorpAction"] = false;
      } else {
        if (product == "bank") tableName1 = "holding-summary";
        else tableName1 = "summary/holding";
      }

      if (product == "ins") {
        if (tableName == "transaction") {
          tableName1 = "coverage-summary";
        } else if (tableName == "security-holding") {
          tableName1 = "securities-holding-summary";
        } else if (tableName == "holdings") {
          tableName1 = "coverage-summary";
        }
      }

      let res =
        product == "upcoming transaction"
          ? await getUpcomingTransactions(params)
          : await getTransactionsSummary(
              product == "real-estate"
                ? "re"
                : product == "mfo"
                ? "mf"
                : product,
              tableName1,
              params
            );

      // console.log(res, "slbjkgbs");
      if (
        (product == "real-estate" || product == "re") &&
        tableName == "transaction"
      ) {
        setTableData(() => res?.data?.data?.result?.summary);

        setPaginationData((prev) => ({
          ...prev,
          pageCount: res?.data?.data?.meta?.count,
        }));
      }
      let transactionList = [];
      if (product == "upcoming transaction") {
        // console.log(res, "upcoming res");
        res?.result?.map((val) => {
          if (
            val["type"] != null &&
            !transactionList.map((val) => val?.title).includes(val["type"])
          ) {
            transactionList.push({
              title: val["type"],
              show: false,
            });
          }
        });
        setcheckedTrans(transactionList);
        setTableData(() => res?.result);
        setPaginationData((prev) => ({
          ...prev,
          pageCount: res?.meta?.count,
        }));
      } else {
        setPaginationData((prev) => ({
          ...prev,
          pageCount: res?.data?.data?.meta?.count,
        }));

        if (
          (product == "real-estate" || product == "re") &&
          tableName == "transaction"
        ) {
          setTableData(() => res?.data?.data?.result?.summary);
        } else {
          setTableData(() => res?.data?.data?.result);
        }

        //code for number of instruments and total portfolio

        if (numberOfInstrumentsCount.current === 0) {
          setNumberOfInstruments(res?.data?.data?.meta?.count);
        }
        numberOfInstrumentsCount.current = numberOfInstrumentsCount.current + 1;
        setPercentageOfTotalPortfolio(
          res?.data?.data?.total?.percentageOfTotalPortfolio
        );
      }

      setDeleteList({});
      setShowDelete(false);
      setLoading(false);
    } catch (error) {
      console.log(error, "slbjkgb");
      setTableData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!product) {
      return;
    }
    getTableData();
  }, [
    mfoFilter,
    offset,
    product,
    search,
    showCorpAction,
    holdingCardData,
    xirr,
    twrr,
  ]);

  return {
    getTableData,
    tableData,
    loading,
    paginationData,
    numberOfInstruments,
    percentageOfTotalPortfolio,
    checkedTrans,
  };
}

export default useGetTableHolding;
