import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

/**
 * Custom hook to fetch operational data with error handling and pagination support.
 * @param {function} fn - The function to fetch operational data.
 * @param {string} status - The status of the operational data to fetch.
 * @param {string} searchName - The search term for filtering data.
 * @param {number} itemLimit - The maximum number of items to fetch per page.
 * @param {number} offset - The offset for paginated data.
 * @returns {object} - An object containing operational data, pagination information, loading state, and error state.
 */
export const useOps = (fn, status, searchName, itemLimit, offset) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [opsData, setOpsData] = useState([]);
  const [error, setError] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    setLoading(true);
    setError(null);
    fn(status, searchName, itemLimit, offset)
      .then((response) => {
        const apiData = response?.data?.data?.result;
        const totalItem = response?.data?.data?.meta?.count;
        setPageCount(Math.ceil(totalItem / itemLimit));
        setOpsData(apiData);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
        if (err.response.request.status === 401) {
          navigate("/logout");
        }
      });
  }, [searchName, offset, status]);

  return { opsData, pageCount, loading, error };
};
