import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  saveButton: {
    color: "white",
    width: "97px",
    borderRadius: "20px",
    background: "#8F48F8",
  },
  gridSpacing: {
    display: "flex",
    flexDirection: "column", // Stack the items vertically
    gap: "30px",
    // border: "1px solid red",
  },
  headerWrapper: {
    margin: "30px",
    // border: "1px solid red",
    // minHeight: "30vh",
    marginBottom: "0px",
  },
  arrowText: {
    color: "#FFF",
    fontFamily: "Avenir",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "22px",
  },
  labelHeader: {
    color: "#9488A7",
    fontFamily: "Avenir",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "12.658px",
    textTransform: "uppercase",
    textAlign: "center",
    marginBottom: "5px",
  },
  editText: {
    color: "#FFF",
    fontFamily: "Avenir",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "27.645px",
    textAlign: "center",
  },
  editTextField: {},
  editTextFieldText: {
    "& .MuiInputBase-input": {
      borderRadius: "35px",
      background: "#000",
      textAlign: "center",
      color: "#FFF",
      fontFamily: "Avenir",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "27.645px",
      textAlign: "center",
    },
  },
});
