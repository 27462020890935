import React from "react";
import Default from "../components/dashboard/defaultPages/Default";
import NewPartnerList from "../components/newPartnerList/NewPartnerList";

function NewPartnerListPage() {
  return (
    <>
      <Default>
        <NewPartnerList />
      </Default>
    </>
  );
}

export default NewPartnerListPage;
