import React, { useState, useContext, useRef } from "react";
import moment from "moment";
import useStyles from "../common/ProductsStyles";
import { Grid, Typography } from "@material-ui/core";
import { ReactComponent as Back } from "../../../../assets/holding-summary/back-blue.svg";
import { ReactComponent as Import } from "../../../../assets/icons/import.svg";
import { ReactComponent as Export } from "../../../../assets/icons/export.svg";
import { ReactComponent as Print } from "../../../../assets/icons/print.svg";
import { ReactComponent as EditColumn } from "../../../../assets/icons/edit-column.svg";
import { OnboardingContext } from "../../../../Context";
import { getHoldingsummary } from "../../../../api";
import { useEffect } from "react";
import SearchInput from "../common/SearchInput";
import { useNavigate } from "react-router-dom";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import { currencyFormat } from "../../../../hooks/constantFunction";
import PmsReportTable from "../pms/PmsReportTable";
import {
  roundNumber,
  roundNumberWithoutDecimal,
  summarizeTableData,
} from "../../../utils/common";
import TabComponent from "../common/TabComponent";
import PageFilter from "../common/PageFilter";
import NewTabComp from "../common/NewTabComp";
import { routes } from "../../../../routes";
import { useClientSearch } from "../../../../hooks/useClientSearch";
import NewtableTopSec from "../../common/NewtableTopSec";
import { assectClassConfigSelection } from "../common/util/common";
import { ITEMS_LIMIT_FILES_PARTNER_LIST } from "../../../../Constants";
import { useCardsApi } from "../../../../hooks/useCardsApi";
import { useCurrentHoldingCardsApi } from "../../../../hooks/useCurrentHoldingCardsApi";
const data = [
  { page: "equity", title: "Equity" },
  { page: "debt", title: "Debt" },
  { page: "alternate", title: "Alternate Assets" },
  { page: "cash", title: "Cash" },
];

function MfoHolding() {
  const navigate = useNavigate();
  const tableColumnsData = [
    {
      field: "schemeName",
      title: "Scheme/Product Name",
      show: true,
      align: "center",
    },
    { field: "memberName", title: "Member Name", show: true, align: "center" },
    { field: "advisor", title: "Advisor", show: true, align: "center" },
    { field: "isin", title: "ISIN", show: true, align: "center" },
    { field: "folioNo", title: "Folio No", show: true },
    // { field: "assetType", title: "Asset Type", show: true },
    {
      field: "investmentValue",
      title: "Invested Value * Includes Stamp Duty",
      show: true,
    },
    { field: "withdrawalAmount", title: "Withdrawals", show: true },
    {
      field: "weightedAvgCost",
      title: "Weighted Avg Cost (Actuals)",
      show: true,
    },

    // { field: "realisedGainLoss", title: "Realized Gain/Loss", show: false },

    { field: "holdingUnits", title: "Balance Units", show: true },
    { field: "nav", title: "Nav (Actuals)", show: true },
    { field: "presentValue", title: "Present Value ", show: true },
    { field: "avgHoldingPeriod", title: "Average Holding Period", show: true },
    { field: "unRealisedGainLoss", title: "Unrealized Gain/Loss", show: true },
    // { field: "valueDate", title: "Value Date", show: true, align: "center" },
    {
      field: "interestIncome",
      title: "Other Income",
      show: true,
    },
    { field: "totalGainLoss", title: "Total Gain/Loss", show: true },
    { field: "xirr", title: "XIRR (%)", show: true },
    { field: "absoluteTwrr", title: "Absolute TWRR (%)", show: true },
    { field: "annualisedTwrr", title: "Annualized TWRR (%)", show: true },
  ];
  const classes = useStyles();
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  // #TODO Need to remove old login for default assetClass selection
  // let assetOrder = ["equity", "debt", "alternate", "cash"];
  // let selected_asset = getfilter_as_params().assetClass.split(",");
  // let defaultAsset = assetOrder.find((a) => {
  //   if (selected_asset.includes(a)) {
  //     return a;
  //   }
  // });
  const [page, setPage] = useState(getfilter_as_params().assetClass);
  const { holdingCardData, xirrCurrentHolding, twrrCurrentHolding } =
    useCurrentHoldingCardsApi(true, "mf");

  const [openModal, setOpenModal] = useState(false);
  const { userState } = OnboardingContext();
  const [tableColumns, setTableColumns] = useState(tableColumnsData);
  const [checkboxList, setCheckboxList] = useState(tableColumnsData);
  const [assetClass, setAssetClass] = useState("equity");
  const [tableData, setTableData] = useState([]);
  const [load, setLoading] = useState(true);
  const [search, setSearch] = useState(null);

  const [tableHeader, setTableHeader] = useState(tableColumnsData);
  const [newTableColumns, setNewTableColumns] = useState([]);
  const [secondTableColumns, setSecondTableColumns] = useState([]);
  const [reset, setReset] = useState();
  const { searchResults } = useClientSearch(search, newTableColumns);
  const [pagiNationData, setPageinationData] = useState({
    pageCount: null,
    // limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
  });

  const numberOfInstrumentsCount = useRef(0);
  const [numberOfInstruments, setNumberOfInstruments] = useState(0);
  const [percentageOfTotalPortfolio, setPercentageOfTotalPortfolio] =
    useState(0);

  const [offset, setOffset] = useState(0);

  const objectFormating = (data) => {
    let newData = tableHeader.map((header) => {
      if (header?.field == "schemeName") {
        return {
          key: header?.field,
          field: data[`${header.field}`],
          title: header.title,
          show: header.show,
          // onClick: () => {
          //   navigate(`/home/mfo/risk/?isin=${data["isin"]}`, {
          //     state: data["isin"],
          //   });
          // },
          onClick: () => {
            navigate(routes.mfDetailed_holdingPeriod, {
              state: {
                isin: data["isin"],
                advisor: data["advisor"],
                assetClass: page,
                type: "mf",
              },
            });
          },
          align: header?.align,
        };
      }
      if (header?.field == "annualisedTwrr") {
        return {
          key: header?.field,
          field: roundNumber(data[`${header.field}`]) || "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (header?.field == "avgHoldingPeriod") {
        return {
          key: header?.field,
          field: data[`${header.field}`] + " Days" || "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (header?.field == "isin" || header?.field == "folioNo") {
        return {
          key: header?.field,
          field: data[`${header.field}`],
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (header?.field == "nav") {
        let currencyformat = currencyFormat(data[`${header.field}`] || 0, {
          showDecimal: true,
          currency: "INR",
          style: "currency",
        });

        currencyformat = currencyFormat(data[`${header.field}`] || 0, {
          showDecimal: true,
          currency: "INR",
          style: "currency",
          format: "actuals",
        });

        return {
          key: header?.field,
          field: currencyformat || 0,
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }

      if (header?.field == "weightedAvgCost") {
        let currencyformat = currencyFormat(data[`${header.field}`] || 0, {
          showDecimal: true,
          currency: "INR",
          style: "currency",
        });

        currencyformat = currencyFormat(data[`${header.field}`] || 0, {
          showDecimal: true,
          currency: "INR",
          style: "currency",
          format: "actuals",
        });

        return {
          key: header?.field,
          field: currencyformat || 0,
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }

      if (
        header?.field == "investmentValue" ||
        header?.field == "presentValue" ||
        header?.field == "totalGainLoss" ||
        header?.field == "realisedGainLoss" ||
        header?.field == "interestIncome" ||
        header?.field == "withdrawalAmount" ||
        header?.field == "unRealisedGainLoss"
      ) {
        return {
          key: header?.field,
          field: currencyFormat(data[`${header.field}`] || 0) || 0,
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      return {
        key: header?.field,
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`]))
          : data[`${header.field}`] || "--",
        title: header.title,
        show: header.show,
        align: header?.align,
      };
    });
    return newData;
  };

  useEffect(() => {
    numberOfInstrumentsCount.current = 0;
  }, [page, mfoFilter]);

  useEffect(() => {
    if (!search) {
      setLoading(true);
    }
    getSummary();
  }, [page, mfoFilter, reset, offset, search]);

  let getSummary = () => {
    // console.log("akbhfuiawui", getfilter_as_params().assetClass);
    setLoading(true);
    let params = {
      ...getfilter_as_params(),
      assetClass: page,

      // clientId: 199,
      offset: offset,
      limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
    };

    if (search) {
      params["search"] = search;
    }
    //setNewTableColumns([]);
    // userState.loginedUserData.id,
    getHoldingsummary(userState.loginedUserData.id, params)
      .then((res) => {
        setPageinationData((prev) => {
          return { ...prev, pageCount: res?.meta?.count };
        });

        if (numberOfInstrumentsCount.current === 0) {
          setNumberOfInstruments(res?.data?.data?.meta?.count);
        }
        numberOfInstrumentsCount.current = numberOfInstrumentsCount.current + 1;
        setPercentageOfTotalPortfolio(
          res?.data?.data?.total?.percentageOfTotalPortfolio
        );
        // console.log(res, "jjknkjj");
        setTableData(res.result);
        //new table implimentation
        let newList = [];

        res.result.map((val) => {
          let obj = objectFormating(val);
          newList.push(obj);
        });
        console.log("newList test", newList);
        setNewTableColumns(newList);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  console.log("fdsfsdfds", secondTableColumns, newTableColumns);
  useEffect(() => {
    setSecondTableColumns(
      summarizeTableData(
        newTableColumns,
        holdingCardData,
        xirrCurrentHolding,
        twrrCurrentHolding,
        numberOfInstruments,
        "",
        "",
        "",
        percentageOfTotalPortfolio
      )
    );
  }, [
    newTableColumns,
    holdingCardData,
    xirrCurrentHolding,
    twrrCurrentHolding,
  ]);
  // console.log(pagiNationData,'jnkbjh');
  // const summarizeTableData = (data) => {
  //   if (data.length > 0) {
  //     const firstRow = data[0];
  //     const summaryRow = firstRow.map((item) => {
  //       let newField = item.field;
  //       console.log("inside function", item);
  //       if (
  //         [
  //           "Advisor",
  //           "Folio No",
  //           "ISIN",
  //           "Balance Units",
  //           "Nav (Actuals)",
  //           "Average Holding Period",
  //           "Member Name",
  //           "Weighted Avg Cost (Actuals)",
  //           "Withdrawals",
  //         ].includes(item.title)
  //       ) {
  //         newField = "--";
  //       }
  //       if (item.title === "Total Gain/Loss") {
  //         newField = currencyFormat(holdingCardData?.totalGainLoss);
  //       }
  //       if (item.title === "Present Value") {
  //         newField = currencyFormat(holdingCardData?.portfolioValueAmount);
  //       }
  //       if (item.title === "Invested Value * Includes Stamp Duty") {
  //         newField = currencyFormat(holdingCardData?.investedAmount);
  //       }
  //       if (item.title === "Unrealized Gain/Loss") {
  //         newField = currencyFormat(holdingCardData?.unRealisedGainLoss);
  //       }
  //       if (item.title === "XIRR (%)") {
  //         newField = roundNumberWithoutDecimal(Number(xirr?.xirr));
  //       }
  //       if (item.title === "Absolute TWRR (%)") {
  //         newField = roundNumberWithoutDecimal(Number(twrr?.absoluteTwrr));
  //       }
  //       if (item.title === "Annualized TWRR (%)") {
  //         newField = roundNumberWithoutDecimal(Number(twrr?.annualisedTwrr));
  //       }
  //       if (item.title === "Scheme/Product Name") {
  //         newField = "Total";
  //       }

  //       return { ...item, field: newField };
  //     });
  //     setSecondTableColumns([summaryRow]);
  //   }
  // };

  const handleIconClick = () => {
    setOpenModal(!openModal);
    setCheckboxList(tableColumns);
  };

  const handleClick = React.useCallback(
    (page) => {
      setOffset(0);
      setPage(page);
    },
    [page]
  );

  return (
    <>
      <NewtableTopSec
        handleIconClick={handleIconClick}
        directPageName={"Holding Summary"}
        setSearch={(e) => {
          setOffset(0);
          setSearch(e);
        }}
        product={"Mutual Fund"}
      />
      <div style={{ height: "auto" }}>
        <div style={{ margin: "2%" }}>
          <NewTabComp
            data={assectClassConfigSelection({
              assetClassList: getfilter_as_params().assetClass,
              data: data,
            })}
            // customWidth={"fit-content"}
            customHandle={handleClick}
          />
        </div>
        <PmsReportTable
          productType="mfoHolding"
          searchParam={search}
          setTableColumns={setNewTableColumns}
          setSecondTableColumns={setSecondTableColumns}
          tableColumns2={secondTableColumns}
          // TODO Pass only newTableCoumns when backend search API is ready
          tableColumns={newTableColumns}
          setOpenModal={setOpenModal}
          openModal={openModal}
          loading={load}
          headerList={tableColumnsData}
          tableHeader={tableHeader}
          setTableHeader={setTableHeader}
          stickyProductName={true}
          // reset={getSummary}
          pageCount={pagiNationData?.pageCount}
          setItemOffset={setOffset}
          pageNumber={offset / ITEMS_LIMIT_FILES_PARTNER_LIST + 1 || 1}
        />
        {/* </div> */}
      </div>
    </>
  );
}

export default MfoHolding;
