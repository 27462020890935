import moment from "moment";
import { currencyFormat } from "../../../../hooks/constantFunction";
import { dateFormatCommon, roundNumber } from "../../../utils/common";

export const objectFormating = (data, tableHeader, entity) => {
  let newData = tableHeader.map((header) => {
    if (
      header?.field == "isin" ||
      header?.field == "folio_no" ||
      header?.field == "folioNo" ||
      header?.field == "executed_by"
    ) {
      return {
        key: header?.field,
        field: data[`${header.field}`] ? data[`${header.field}`] : "--",
        title: header.title,
        show: header.show,
        align: header?.align,
      };
    }
    if (header?.field == "withdrawalAmount") {
      return {
        key: header?.field,
        field: currencyFormat(data[`${header.field}`] || 0) || 0,
        title: header.title,
        show: header.show,
        align: header?.align,
      };
    }
    if (
      header?.field == "investmentValue" ||
      header?.field == "withdrawalAmount" ||
      header?.field == "presentValue" ||
      header?.field == "totalGainLoss" ||
      header?.field == "unRealisedGainLoss" ||
      header?.field == "realisedGainLoss" ||
      header?.field == "amount" ||
      header?.field == "committed_amount" ||
      header?.field == "undrawn_committed_amount" ||
      header?.field == "compensation_contribution" ||
      header?.field == "exit_load" ||
      header?.field == "management_fees" ||
      header?.field == "performance_fees" ||
      header?.field == "stamp_duty" ||
      header?.field == "securities_transaction_tax" ||
      header?.field == "gst" ||
      header?.field == "tds" ||
      header?.field == "cess" ||
      header?.field == "commitedAmount" ||
      header?.field == "drawdownAmount" ||
      header?.field == "pendingDrawdown" ||
      header?.field == "totalInvestmentValue" ||
      header?.field == "totalPresentValue" ||
      header?.field == "interestIncome" ||
      header?.field == "totalCharges"
    ) {
      return {
        key: header?.field,
        field: currencyFormat(data[`${header.field}`] || 0) || "--",
        title: header.title,
        show: header.show,
        align: header?.align,
      };
    }
    if (
      header?.field == "transaction_date" ||
      header?.field == "lastDrawdownDate"
    ) {
      return {
        key: header?.field,
        field:
          // moment(new Date(data[`${header.field}`])).format("DD - MM - YYYY") ||
          // "--",
          data[`${header.field}`]
            ? dateFormatCommon(new Date(data[`${header.field}`]))
            : "--",
        title: header.title,
        show: header.show,
        align: header?.align,
      };
    }
    if (
      header?.field == "holdingPeriod" ||
      header?.field == "avgHoldingPeriod"
    ) {
      return {
        key: header?.field,
        field: data[`${header.field}`]
          ? data[`${header.field}`] + (entity ? entity : " Days")
          : "--",
        // moment(new Date(data[`${header.field}`])).format(
        //   "DD - MM - YYYY"
        // ) || "--",
        title: header.title,
        show: header.show,
        align: header?.align,
      };
    }
    if (header?.field == "annualisedTwrr") {
      return {
        key: header?.field,
        field: roundNumber(Number(data[`${header.field}`])) || "--",
        title: header.title,
        show: header.show,
        align: header?.align,
      };
    }
    if (header?.field == "units" || header?.field == "holdingUnits") {
      return {
        key: header?.field,
        field: data[`${header.field}`]
          ? roundNumber(data[`${header.field}`])
          : "--",
        title: header.title,
        show: header.show,
        align: header?.align,
      };
    }
    return {
      key: header?.field,
      field: !isNaN(data[`${header.field}`])
        ? roundNumber(Number(data[`${header.field}`]))
        : data[`${header.field}`] || "--",
      title: header.title,
      show: header.show,
      align: header?.align,
    };
  });
  return newData;
};
