import React, { useEffect, useState } from "react";
import PageFilter from "../common/PageFilter";
import TabInsuranceMain from "../common/TabInsuranceMain";
import InsuranceComp from "./InsuranceComp";
import InsuranceGeneral from "./InsuranceGeneral";
import InsuranceHealth from "./InsuranceHealth";
import useStyles from "../common/ProductsStyles";
import { ReactComponent as Print } from "../../../../assets/icons/print.svg";

import { ReactComponent as Import } from "../../../../assets/icons/import.svg";
import { ReactComponent as Export } from "../../../../assets/icons/export.svg";
import { ReactComponent as Filter } from "../../../../assets/icons/filter.svg";
import { ReactComponent as Back } from "../../../../assets/holding-summary/back-blue.svg";
import { ReactComponent as LitBulb } from "../../../../assets/icons/bulb-glow.svg";
import { ReactComponent as EditColumn } from "../../../../assets/icons/edit-column.svg";

import { Typography, useTheme } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { currencyFormat } from "../../../../hooks/constantFunction";
import moment from "moment";
import { dateFormatCommon, roundNumber } from "../../../utils/common";
import SummaryPageTop from "../common/SummaryPageTop";
import { routes } from "../../../../routes";
import { NewFilter } from "../../newFilter/NewFilter";
import FileTabComp from "../common/FileTabComp";
import { realEstateTabConfig } from "../../../../Constants";

function InsuranceReport({ pageName }) {
  const classes = useStyles();
  const [page, setPage] = useState("life");
  const [openModal, setOpenModal] = useState(false);
  const [flip, setFlip] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  let align = "center";
  const theme = useTheme();
  const icons = theme.palette.icons;
  const Bulb = icons.bulbIcon;
  const tableColumnsData = [
    {
      field: "productName",
      title: "Product Name",
      show: true,
      secondLine: false,
      align: align,
    },
    { field: "advisor", title: "Advised By", show: true, align: align },
    { field: "executed_by", title: "Executed By", show: true, align: align },
    {
      field: "assetAllocation",
      title: "Asset Allocation",
      show: true,
      align: align,
    },
    {
      field: "productSubCategory",
      title: "Product Sub Category",
      show: true,
      align: align,
    },
    {
      field: "policyCommencementDate",
      title: "Policy Commencement Date",
      show: true,
      align: align,
    },
    {
      field: "policyEndDate",
      title: "Policy End Date",
      show: true,
      align: align,
    },
    { field: "noOfPremiums", title: "No of Premium Paid", show: true },
    {
      field: "premiumFrequency",
      title: "Premium Frequency",
      show: true,
      align: align,
    },
    { field: "premiumAmount", title: "Premium Amount", show: true },
    { field: "lifeCoverAmount", title: "Cover Amount", show: true },
  ];

  const objectFormating = (data, tableHeader) => {
    let newData = tableHeader.map((header) => {
      if (header?.field == "annualisedTwrr") {
        return {
          field: !isNaN(data[`${header.field}`])
            ? roundNumber(Number(data[`${header.field}`]))
            : "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }

      if (
        header?.field == "policyCommencementDate" ||
        header?.field == "policyEndDate"
      ) {
        return {
          field:
            // moment(data[`${header.field}`]).format("DD - MM - YYYY") || "--",
            dateFormatCommon(new Date(data[`${header.field}`])),
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (
        header?.field == "premiumAmount" ||
        header?.field == "lifeCoverAmount" ||
        header?.field == "investmentValue" ||
        header?.field == "currentValue" ||
        header?.field == "unRealisedGainLoss" ||
        header?.field == "realisedGainLoss" ||
        header?.field == "currentNav"
      ) {
        return {
          field: currencyFormat(data[`${header.field}`] || 0) || 0,
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (
        header?.field == "isin" ||
        header?.field == "folioNo" ||
        header?.field == "executed_by"
      ) {
        return {
          field: data[`${header.field}`] ? data[`${header.field}`] : "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      return {
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`]))
          : data[`${header.field}`] || "--",
        title: header.title,
        show: header.show,
        secondLine: header?.secondLine,
        align: header?.align,
      };
    });
    return newData;
  };

  const navigate = useNavigate();
  const changePage = (p) => {
    switch (p) {
      case "life":
        return <InsuranceComp></InsuranceComp>;

      case "health":
        return (
          <InsuranceHealth
            flip={flip}
            openModal={openModal}
            setOpenModal={setOpenModal}
            tableColumnsData={tableColumnsData}
            objectFormating={objectFormating}
          />
        );

      case "general":
        return (
          <InsuranceGeneral
            flip={flip}
            openModal={openModal}
            setOpenModal={setOpenModal}
            tableColumnsData={tableColumnsData}
            objectFormating={objectFormating}
          />
        );

      default:
        return <InsuranceComp></InsuranceComp>;
    }
  };

  useEffect(() => {
    setPage(pageName);
  }, [pageName]);

  const handleIconClick = () => {
    setOpenModal(!openModal);
  };
  // const handleCheckBoxClick = (e) => {
  //     const list = [...tableColumns];
  //     list.map((col, index) => {
  //         if (col.field === e.target.name) {
  //             const data = { ...col };
  //             data["show"] = !col.show;
  //             console.log(data);
  //             list[index] = { ...col, show: !col.show };
  //         }
  //     });
  //     setTableColumns(list);
  // };

  // const handleButtonClick = () => {
  //     setOpenModal(!openModal);
  //     console.log("clicked");
  // };

  return (
    <>
      <SummaryPageTop
        pageTitle={"Insurance Portfolio Summary"}
        backLink={routes?.homeHoldingPage}
        flip={flip}
        setFlip={setFlip}
        setFilterModalOpen={setFilterModalOpen}
      />
      <NewFilter
        filterModalOpen={filterModalOpen}
        setFilterModalOpen={setFilterModalOpen}
        productType="ins"
      />
      {/* <PageFilter></PageFilter>
      <div className={`${classes.alignTopHeader}`}>
        <Back
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/home/holding-summary/details");
          }}
        />
        <Typography
          style={{ marginLeft: "-4%" }}
          className={`${classes.mainHeading} ${classes.mainHeadingAlign}`}
        >
          Insurance Portfolio Summary
        </Typography>
        <div className={`${classes.iconAlign}`}>
          {!flip && (
            <Bulb
              onClick={() => setFlip(!flip)}
              className={classes.iconStyle}
            />
          )}
          {flip && (
            <LitBulb
              onClick={() => setFlip(!flip)}
              className={classes.iconStyle}
            />
          )}
          <Import className={classes.iconStyle} />
          <Export className={classes.iconStyle} />
          <Print className={classes.iconStyle} />
          <EditColumn onClick={handleIconClick} className={classes.iconStyle} />
        </div>
      </div> */}
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "2%",
        }}
      >
        <TabInsuranceMain
          setFlip={setFlip}
          pageName={pageName}
        ></TabInsuranceMain>
      </div> */}
      <FileTabComp
        data={realEstateTabConfig}
        seleted={
          realEstateTabConfig.findIndex((val) => val.page == pageName) == -1
            ? 0
            : realEstateTabConfig.findIndex((val) => val.page == pageName)
        }
      />
      {changePage(page)}
      {/* <Modal
                open={openModal}
                BackdropProps={{
                    classes: {
                        root: classes.backDrop,
                    },
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className={`${classes.modalContainer} form-container`}>
                    <div className="filter-header">
                        <Typography className="filter-header-text">
                            Modify Columns
                        </Typography>
                    </div>
                    <hr className="filter-divider"></hr>
                    <div className="member">
                        <div className="filter-header">
                            <Typography className="filter-header-text">
                                Select Columns
                            </Typography>
                        </div>
                        <div className="member-content">
                            {tableColumns?.map((column) =>
                                column.title !== "" ? (
                                    <div key={column.field} className="filter-header">
                                        <span
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <CheckBoxWithColor
                                                checked={column.show}
                                                value={column.field}
                                                name={column.field}
                                                onChange={(e) => handleCheckBoxClick(e)}
                                            />
                                            <Typography className="filter-value-name avtar-text">
                                                {column.title}
                                            </Typography>
                                        </span>
                                    </div>
                                ) : null
                            )}
                        </div>
                    </div>

                    <hr className="filter-divider"></hr>
                    <div className={classes.buttonContainer}>
                        <Button
                            // variant="contained"
                            className={classes.buttonNo}
                            style={{ width: "60%", marginTop: "5%" }}
                            onClick={() => {
                                setOpenModal(!openModal);
                            }}
                        >
                            <span className={`${classes.buttonText}`}>close</span>
                        </Button>
                        <Button
                            // variant="contained"
                            className={classes.buttonApplyModal}
                            style={{ width: "60%", marginTop: "5%" }}
                            onClick={handleButtonClick}
                        >
                            <span className={`${classes.buttonText}`}>apply</span>
                        </Button>
                    </div>
                </div>
            </Modal> */}
    </>
  );
}

export default InsuranceReport;
