import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { fetchSectorAllocation } from "../api";
import { OnboardingContext } from "../Context";
import { mfoFilterContext } from "../mfo_context/Mfo_Context";
import moment from "moment";
export const useSectorAllocation = () => {
  const { userState } = OnboardingContext();
  const [loading, setLoading] = useState(true);
  const { mfoFilter } = useContext(mfoFilterContext);
  const [sectorAllocationData, setSectorAllocationData] = useState([]);
  let clientId = userState.loginedUserData.id;
  // let clientId = 197


  useEffect(() => {
    // fetchSectorAllocation(clientId);
    let params = {};
    if (mfoFilter.startDate) {
      params["startDate"] = moment(mfoFilter.startDate).format("YYYY-MM-DD");
      params["endDate"] = moment(mfoFilter.endDate).format("YYYY-MM-DD");
    }
    if (Object.keys(mfoFilter.checkData).length > 0) {
      params["memberId"] = Object.keys(mfoFilter.checkData).join(",");
    }

    setLoading(true);
    fetchSectorAllocation(clientId, params)
      .then((response) => {
        const apiData = response?.data?.data?.result;
        setSectorAllocationData(apiData);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(`An Error ${err} occurred`);
      });
  }, [mfoFilter]);
  return [sectorAllocationData, loading];
};
