import React from "react";
import { useParams } from "react-router-dom";
import Default from "../components/dashboard/defaultPages/Default";
import AifReport from "../components/dashboard/products/aif/AifReport";

function AifSummaryDetails() {
  const { pageName } = useParams();

  return (
    <>
      <Default>
        <AifReport pageName={pageName}></AifReport>
      </Default>
    </>
  );
}

export default AifSummaryDetails;
