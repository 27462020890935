export const summaryDetailsSecConstData = {
  title: "Summary Details",
  content: (
    <>
      Holdings are the contents of an investment portfolio held by an individual
      or an entity, <br />
      such as a mutual fund or a pension fund. Portfolio holdings may encompass
      a wide
    </>
  ),
  tableHeader: [
    {
      title: "ASSET CLASS",
      field: "title",
      align: "center",
      fontWeight: "900",
    },
    {
      title: "CURRENT VALUE",
      field: "amount",
      fontWeight: "800",
    },
    {
      title: "% ALLOCATION",
      field: "percent",
      fontWeight: "400",
    },
    {
      title: "UPDATED AS ON",
      field: "date",
      spclFont: "italic",
    },
  ],
};

export const summaryDetailsTableConfigBank = {
  title: "Summary Details",
  // content: (
  //   <>
  //     Holdings are the contents of an investment portfolio held by an individual
  //     or an entity, <br />
  //     such as a mutual fund or a pension fund. Portfolio holdings may encompass
  //     a wide
  //   </>
  // ),
  tableHeader: [
    {
      title: "ACCOUNT TYPE",
      field: "title",
      align: "center",
      fontWeight: "900",
    },
    {
      title: "BALANCE",
      field: "amount",
      fontWeight: "800",
    },
    {
      title: "% ALLOCATION",
      field: "percent",
      fontWeight: "400",
    },
    {
      title: "UPDATED AS ON",
      field: "date",
      spclFont: "italic",
    },
  ],
};

export const summaryDetailsTableConfigTOP5AMC = {
  title: "Top 5 AMC",
  tableHeader: [
    {
      title: "AMC Name",
      field: "amc",
      align: "center",
      fontWeight: "400",
      dontShowFirstColBorder: true,
    },
    {
      title: "Current VALUE",
      field: "valuation",
      fontWeight: "400",
    },
    {
      title: "WEIGHTAGE",
      field: "percentage",
      fontWeight: "400",
    },
  ],
};

export const summaryDetailsTableConfigTOP5Issuers = {
  title: "Top 5 Issuers",
  tableHeader: [
    {
      title: "Issuer Name",
      field: "issuer",
      align: "left",
      fontWeight: "400",
      dontShowFirstColBorder: true,
    },
    {
      title: "Current VALUE",
      field: "valuation",
      align: "center",
      fontWeight: "400",
    },
    {
      title: "WEIGHTAGE",
      field: "percentage",
      align: "center",
      fontWeight: "400",
    },
  ],
};
