import React, { useState } from "react";
import "./cashFlowChart.css";
import { Typography } from "@material-ui/core";
import moment from "moment";
import { currencyFormat } from "../../hooks/constantFunction";
import { dateFormatCommon, dateFormatCommon2 } from "../utils/common";
import DataNotFound from "../dashboard/products/common/DataNotFound";
import { useEffect } from "react";
import { ReactComponent as TodayLine } from "../../assets/icons/todayLine.svg";

function BondsCashflowChart({ data, loading }) {
  const [newData, setNewData] = useState([]);
  // let today = new Date("2024/02/10");
  let today = new Date();

  const handleToday = (data) => {
    setNewData(() =>
      data.map((val, i) => {
        if (
          moment(moment(today).subtract("hours", 4)).isBetween(
            moment(val?.transaction_date, "YYYY-MM-DD").format("YYYY-MM-DD"),
            moment(data[i + 1]?.transaction_date, "YYYY-MM-DD")
          )
        ) {
          // console.log(val, "fiidfif");
          val.today = true;
          val.todayPos = moment(moment(today).subtract("hours", 4)).diff(
            moment(val.transaction_date, "YYYY-MM-DD"),
            "days"
          );
        }

        if (moment(val.transaction_date, "YYYY-MM-DD").isBefore(today)) {
          val.before = true;
        }

        return val;
      })
    );
  };

  useEffect(() => {
    handleToday(data);
  }, [data]);

  return (
    <div
      className="outerWrapperCashFLow"
      style={{ margin: "-8px 20px 20px 20px" }}
    >
      {data?.length > 0 && <p className="chartPastTxt">Past Cash Flow</p>}
      {data?.length > 0 && <p className="chartFutureTxt">Future Cash Flow</p>}
      <div className="scrollContainer">
        {data?.length > 0 && (
          <div className="scrollWrapper">
            {newData?.length > 0 &&
              newData.map((item, i) => {
                return (
                  <div className="items">
                    <p className={"timelineYear"}>
                      {
                        dateFormatCommon2(
                          item?.transaction_date,
                          "YYYY-MM-DD"
                        )?.split(" ")[2]
                      }
                    </p>
                    <div className="lineWrapper">
                      <div className="lineContainer">
                        <div className="circle"></div>

                        {item?.today ? (
                          <>
                            <div
                              className="timeLine"
                              style={{
                                height: "2px",
                                width: "50%",
                              }}
                            ></div>

                            <div
                              className="timeLine"
                              style={{
                                height: "5px",
                                width: "65%",
                              }}
                            ></div>
                          </>
                        ) : (
                          <div
                            className="timeLine"
                            style={{
                              height:
                                item?.before && !item?.today ? " 2px" : "5px",
                            }}
                          ></div>
                        )}

                        {/* <div className="todatLine"> */}

                        {item?.today && (
                          <div className="todatLine">
                            <p className="todayTxt">Today</p>
                            <TodayLine />
                          </div>
                        )}
                        {/* </div> */}
                      </div>
                    </div>
                    <p className="timelineDate">
                      {dateFormatCommon2(
                        item?.transaction_date,
                        "YYYY-MM-DD"
                      )?.split(" ")[0] +
                        " " +
                        dateFormatCommon2(
                          item?.transaction_date,
                          "YYYY-MM-DD"
                        )?.split(" ")[1]}
                    </p>
                    <Typography
                      className="investmentIndicator"
                      style={{
                        // color: i == 0 ? "#7b61ff" : "#F7CB59",
                        color: "#F7CB59",
                      }}
                    >
                      {item?.type}
                    </Typography>
                    <p className="investedValue">
                      {currencyFormat(item?.amount)}
                    </p>
                  </div>
                );
              })}
          </div>
        )}
        {data?.length == 0 && (
          <div className="noDataFontContainer">
            <DataNotFound text={loading ? "Loading..." : undefined} />
          </div>
        )}
      </div>
    </div>
  );
}

export default BondsCashflowChart;
