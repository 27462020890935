import React from "react";
import { Link } from "react-router-dom";
import useStyles from "./ProductsStyles";
import { routes } from "../../../../routes";

function TabMfoMain({ pageName }) {
  const classes = useStyles();

  return (
    <>
      <div style={{ margin: "2%" }} className={classes.tabMfo}>
        <Link
          style={{ textDecoration: "none" }}
          to={routes.mfDetailed_Detailed}
        >
          <div
            className={`${classes.tabHeaderText} ${
              pageName == "detailed" ? classes.active : ""
            }`}
          >
            Detailed Summary{" "}
          </div>
        </Link>
        <Link
          style={{ textDecoration: "none" }}
          to={routes.mfDetailed_SubCategory}
        >
          <div
            className={`${classes.tabHeaderText} ${
              pageName === undefined || pageName === "segment"
                ? classes.active
                : ""
            }`}
          >
            Sub-Category Allocation
          </div>
        </Link>
        <Link style={{ textDecoration: "none" }} to={routes.mfDetailed_fund}>
          <div
            className={`${classes.tabHeaderText} ${
              pageName == "fund" ? classes.active : ""
            }`}
          >
            Fund Performance
          </div>
        </Link>
        <Link
          style={{ textDecoration: "none" }}
          to={routes.mfDetailed_holding}
        >
          <div
            className={`${classes.tabHeaderText} ${
              pageName == "holding" ? classes.active : ""
            }`}
          >
            Holding Summary
          </div>
        </Link>
        <Link
          style={{ textDecoration: "none" }}
          to={routes.mfDetailed_transaction}
        >
          <div
            className={`${classes.tabHeaderText} ${
              pageName == "transaction" ? classes.active : ""
            }`}
          >
            Transaction Summary
          </div>
        </Link>
        {/* 👇Commenting for Now due to change in requirement👇 */}
        {/* <Link style={{ textDecoration: 'none' }} to='/home/mfo/mfo-summary/sip'><div className={`${classes.tabHeaderText} ${pageName == 'sip' ? classes.active : ''}`}>SIP Summary</div></Link> */}
        {/* <Link style={{ textDecoration: 'none' }} to='/home/mfo/mfo-summary/portfolio'><div className={`${classes.tabHeaderText} ${pageName == 'portfolio' ? classes.active : ''}`}>Portfolio Summary</div></Link>
                <Link style={{ textDecoration: 'none' }} to='/home/mfo/mfo-summary/overall'><div className={`${classes.tabHeaderText} ${pageName == 'overall' ? classes.active : ''}`}>Overall Gain/Loss Summary</div></Link> */}
      </div>
    </>
  );
}

export default TabMfoMain;

// not used
