import React, { useEffect, useState } from "react";
import PageContainer from "../PageContainer";
import Header from "../Components/Header";
import PrintTable from "../Components/Table";
import ApexNew_print from "../Components/ApexNew_print";
import chartData from "../../../charts/chartData";
import { ReactComponent as Question } from "../images/question.svg";
import PieChart from "../../../charts/PieChart";
import Print_PieChart from "../Components/Print_PieChart";
import TabCards from "../Components/TabCards";
import { roundNumber } from "../../../utils/common";
import { date } from "yup/lib/locale";
import { currencyFormatPrint as currencyFormat } from "../../../../hooks/constantFunction";
import {
  CreditRatingModel,
  ProductAllocationModel,
  TaxModel,
  assetAllocationModel,
  engagmentModel,
} from "../Components/utils/staticData";
import {
  objectFormatingWithTitleCOlWrapper,
  objectFormatingProductAllocationWithTitleCOl,
  objectFormatingProductWithTitleCOlWrapper,
  objectFormatingProductCommonChartWithTitleCOl,
  objectFormatingWithTaxTitleCOl,
} from "../Components/utils/common";
import HandlePageBreakChart from "../Components/HandlePageBreakChart";
import { colors_allocation } from "../../../charts/colors";
import TaxPageBreak from "../Components/TaxPageBreak";

export default function TaxModule({
  newdata,
  denomination,
  pdfInfo,
  infoText,
  perPage,
  setChanges,
  breadcrumbs,
  headerStyle,
  product,
  headers,
  header2,
  memberName,
  range,
  subtext,
  model,
  objFormatModel,
  currentData,
  fundsInTransit,
}) {
  const [newPage, setNewPage] = useState([]);
  const colors = [...colors_allocation];
  const [colormapped, setColorMapped] = useState({});
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState();

  const [data1, setData1] = useState([]);

  useEffect(() => {
    let assetPieData = [];
    if (newdata?.length > 0) {
      let a = objectFormatingWithTitleCOlWrapper(
        {
          data: newdata || [],
        },
        model || TaxModel,
        [],
        "#ADC371",
        undefined,
        // totaRowData ? totaRowData : hideTotalRow ? undefined : total,
        undefined,
        {},
        denomination,
        "isin",
        "tax",
        objFormatModel || objectFormatingWithTaxTitleCOl
      );

      setNewPage(() => [a]);
    }
    setLoading(false);
  }, [newdata]);

  return (
    <div>
      <TaxPageBreak
        infoText={infoText}
        headers={headers}
        header2={header2}
        perPage={perPage}
        customData={newPage}
        loading={loading}
        total={total}
        title={"Tax"}
        headermodel={TaxModel}
        customHeader={
          <>
            <Header
              title={breadcrumbs ? breadcrumbs : <>Tax Summary</>}
              title1={product}
              title2={memberName ? memberName : undefined}
              date={range}
              type={13}
              subtext={subtext ? subtext : "Direct Equity"}
              Style={headerStyle}
              breadcrumbs={breadcrumbs}
              denomination={denomination}
              reportType={"TaxSummary"}
            ></Header>
          </>
        }
        setChanges={setChanges}
        // piedata={data1}
        pdfInfo={pdfInfo}
        // colors={colors}
        // colormapped={colormapped}
      />
    </div>
  );
}
