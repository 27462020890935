import React from "react";
import "./deleteList.css";
import { Dialog } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import PmsReportTable from "../pms/PmsReportTable";
import {
  deleteTableConfigSummary,
  tableConfigHoldingSummary,
} from "./utils/constands";
import { useState } from "react";
import { useEffect } from "react";
import { holdingSummary } from "./utils/staticData";
import { objectFormating1 } from "../../../utils/objectFormating";

function DeleteList({
  open,
  handleClose,
  seletedList,
  submitDelete,
  tableConfig,
}) {
  const [tableColumns, setTableColumns] = useState([]);
  const [tableHeader, setTableHeader] = useState(
    tableConfig ? [...tableConfig] : [...deleteTableConfigSummary]
  );

  const handleData = (newconfog, seletedList) => {
    let list = [];
    let newConfig = [...newconfog];

    Object.values(seletedList).map((val, i) => {
      if (val["scheme_name"]) {
        newconfog[0].field = "scheme_name";
      } else if (val["scrip_name"]) {
        newconfog[0].field = "scrip_name";
      } 
      list.push(objectFormating1(val, newConfig));
    });
    setTableColumns(() => list);
  };

  useEffect(() => {
    handleData(
      tableConfig ? tableConfig : deleteTableConfigSummary,
      seletedList
    );
  }, []);

  return (
    <Dialog
      onClose={() => {
        setTableColumns(() => []);
        handleClose();
      }}
      aria-labelledby="customized-dialog-title"
      open={open}
      BackdropProps={{ className: "backdropDelete" }}
      PaperProps={{
        className: "deleteModalPaper",
      }}
    >
      <div className="deleteTitleSec">
        <ErrorIcon className="alertIcon" />
        <p className="deleteTitleSecTxt1">Delete Confirmation</p>
        <p className="deleteTitleSecTxt2">
          You have chosen to delete the selected transaction.
        </p>
      </div>
      <p className="deleteCountTitle">No of transactions </p>
      <div className="deleteTableSec">
        <div className="deleteCountSec">
          <p className="deleteCountTxt">{tableColumns?.length}</p>
        </div>
        {tableColumns?.length > 0 && (
          <PmsReportTable
            productType="pmsHoldingDebt"
            setTableColumns={setTableColumns}
            tableColumns={tableColumns}
            //   setOpenModal={setOpenModal}
            //   openModal={openModal}
            // tableName={"Debt"}
            //   loading={loading}
            //   pageName="holding"
            //   headerList={tableConfig || tableConfigHoldingSummary}
            tableHeader={tableHeader}
            setTableHeader={setTableHeader}
            stickyProductName={true}
            hidePagination={true}
            tableMinHeight={"100px"}
            tableMaxHeight={"330px"}
            customTableClass={"deleteTransaction"}
            //   setItemOffset={setOffset}
            //   productName={null}
            //   customTableClass={showDelete && "selectDelete"}
          />
        )}
      </div>
      <div className="deleteFooterSec">
        <button className="newCancelbtn" onClick={handleClose}>
          <p className="cancelButtonTxt" style={{ cursor: "pointer" }}>
            Cancel
          </p>
        </button>
        <button className="newApplyBtn" onClick={submitDelete}>
          <p className="cancelButtonTxt" style={{ cursor: "pointer" }}>
            Delete
          </p>
        </button>
      </div>
    </Dialog>
  );
}

export default DeleteList;
