import React from "react";
import useStyles from "../common/ProductsStyles";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  FormGroup,
  FormControlLabel,
  useTheme,
} from "@material-ui/core";
import { ReactComponent as Back } from "../../../../assets/holding-summary/back-blue.svg";
import { ReactComponent as Print } from "../../../../assets/icons/print.svg";

import { ReactComponent as Import } from "../../../../assets/icons/import.svg";
import { ReactComponent as Export } from "../../../../assets/icons/export.svg";
import { ReactComponent as Filter } from "../../../../assets/icons/filter.svg";
import { ReactComponent as Eye } from "../../../../assets/icons/white-eye.svg";
import { ReactComponent as EditColumn } from "../../../../assets/icons/edit-column.svg";
import { Link, useNavigate } from "react-router-dom";
import SearchInput from "../common/SearchInput";
import { useState } from "react";
import { IosSwitch } from "../../../IosSwitch";
export default function EquitySubTab(props) {
  const {
    setOpenModal,
    editColumnIconVisible,
    importIconVisible,
    setScripName,
    showCurrencyFormat,
    iosSwitch,
  } = props;
  const theme = useTheme();
  const icons = theme.palette.icons;
  const Bulb = icons.bulbIcon;
  const classes = useStyles();
  const navigate = useNavigate();
  // const [scripName, setScripName] = useState(null);
  return (
    <div>
      <div className={`${classes.newAlignTopHeader}`}>
        {/* <Back
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/home/equity/equity-summary");
          }}
        /> */}
        <Typography
          // style={{ marginLeft: "-2%" }}
          style={{ position: "relative" }}
          className={`${classes.mainHeading} ${classes.mainHeadingAlign}`}
        >
          {props.title}
          <Typography
            style={{
              cursor: "pointer",
              // marginLeft: "73px",
              // marginTop: "1.5%",
            }}
            className={classes.aifSubText}
          >
            {props.title === "Holding Summary" ||
            props.title === "Transaction Summary"
              ? "Direct Equity"
              : ""}
            {showCurrencyFormat &&
              sessionStorage.getItem("currency_format") &&
              sessionStorage.getItem("currency_format") != "actuals" && (
                <span className="subtext capitalize">
                  {" "}
                  (values in {sessionStorage.getItem("currency_format")})
                </span>
              )}
          </Typography>
          {/* {showCurrencyFormat &&
            sessionStorage.getItem("currency_format") != "actuals" && (
              <span className="subtext capitalize">
                {" "}
                (values in {sessionStorage.getItem("currency_format")})
              </span>
            )} */}
          <div style={{ position: "absolute", top: 0, right: "-210px" }}>
            {iosSwitch}
          </div>
        </Typography>
        {/* {iosSwitch} */}
        <div className={`${classes.newIconAlign}`}>
          {props.title === "Holding Summary" ||
          props.title === "Transaction Summary" ? (
            <div style={{ marginTop: "-8px" }}>
              <SearchInput setSearchName={setScripName} />
            </div>
          ) : (
            ""
          )}
          {/* {props.settoggle && (
            <FormGroup>
              <FormControlLabel
                control={
                  <IosSwitch
                    checked={props?.toggle ? props.toggle : false}
                    setChecked={props?.settoggle ? props.settoggle : () => {}}
                  />
                }
                labelPlacement="start"
                style={{
                  fontFamily: "Avenir",
                  fontWeight: 900,
                  fontSize: "16px",
                  letterSpacing: "1.5px",
                  textTransform: "uppercase",
                  color: "#FFFFFF",
                  opacity: 0.9,
                  marginRight: "12px",
                }}
              />
            </FormGroup>
          )} */}
          {/* For Stock Summary Import Icon Hidden */}
          {importIconVisible == false ? (
            <></>
          ) : (
            <Import className={classes.iconStyle} />
          )}

          <Export className={classes.iconStyle} />
          <Print className={classes.iconStyle} />
          {editColumnIconVisible && (
            <EditColumn
              onClick={() => setOpenModal((prev) => !prev)}
              className={classes.iconStyle}
            />
          )}
        </div>
      </div>
    </div>
  );
}
