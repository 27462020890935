import React, { useRef, useEffect } from "react";
import { divergingCommonProps } from "../../../charts/nivo_objects";
import Header from "../Components/Header";
import CardsWrapper from "../Components/cardsWrapper";
import Hr from "../Components/Hr";
import Bar from "../Components/barGraph";
import PageContainer from "../PageContainer";
import PrintTable from "../Components/Table";
import { StackBarChart } from "../Components/StackBarChart";
import { StackTable } from "../Components/StackTable";
import { currencyFormatPrint as currencyFormat } from "../../../../hooks/constantFunction";
import { roundNumber } from "../../../utils/common";

export default function Overall_portfolio({
  data,
  denomination,
  newData,
  meta,
  pdfInfo,
}) {
  // console.log(meta, "newdata");

  function getposColor(val) {
    if (val < 0) {
      return "#A60000";
    } else if (val) {
      return "#00A651";
    }
  }
  let bartheme = {
    background: "transparent",
    textColor: "#333333",
    fontSize: 11,
    axis: {
      domain: {
        line: {
          stroke: "#fff",
          strokeWidth: 2,
        },
      },
      legend: {
        text: {
          fontSize: 12,
          fill: "#F8F9FB",
        },
      },
      ticks: {
        line: {
          stroke: "#1f1c40",
          strokeWidth: 1,
        },
        text: {
          fontSize: 11,
          fill: "#000000",
        },
      },
    },
    grid: {
      line: {
        stroke: "#1f1c40",
        strokeWidth: 1,
      },
    },
    legends: {
      title: {
        text: {
          fontSize: 11,
          fill: "#ebe8ee",
        },
      },
      text: {
        fontSize: 11,
        fill: "#000000",
      },
      ticks: {
        line: {},
        text: {
          fontSize: 10,
          fill: "#333333",
        },
      },
    },
    annotations: {
      text: {
        fontSize: 13,
        fill: "#333333",
        outlineWidth: 2,
        outlineColor: "#3c9cfa",
        outlineOpacity: 0.7,
      },
      link: {
        stroke: "#000000",
        strokeWidth: 1,
        outlineWidth: 2,
        outlineColor: "#ffffff",
        outlineOpacity: 1,
      },
      outline: {
        stroke: "#000000",
        strokeWidth: 2,
        outlineWidth: 2,
        outlineColor: "#ffffff",
        outlineOpacity: 1,
      },
      symbol: {
        fill: "#000000",
        outlineWidth: 2,
        outlineColor: "#ffffff",
        outlineOpacity: 1,
      },
    },
    tooltip: {
      container: {
        background: "#ffffff",
        color: "#333333",
        fontSize: 12,
      },
      basic: {},
      chip: {},
      table: {},
      tableCell: {},
      tableCellValue: {},
    },
  };
  bartheme.axis.domain.line.stroke = "#ECECEC";
  bartheme.axis.domain.line.strokeWidth = "1";

  let diverging = {
    ...divergingCommonProps,
    enableGridY: false,
  };

  let carddata = [
    {
      title: "Current Value",
      value: currencyFormat(
        newData?.["dashboard-gain-loss"]?.portfolioValueAmount,
        undefined,
        denomination,
        "prl2"
      ),
      style: { color: "black", fontSize: "20px" },
      type: "rs",
    },
    {
      title: "Invested Value",
      value: currencyFormat(
        newData?.["dashboard-gain-loss"]?.investedAmount,
        undefined,
        denomination,
        "prl2"
      ),
      style: { color: "black", fontSize: "20px" },
      type: "rs",
    },
    {
      title: "Unrealised Gain/Loss",
      value: currencyFormat(
        newData?.["dashboard-gain-loss"]?.unRealisedGainLoss,
        undefined,
        denomination,
        "prl2"
      ),
      style: {
        color: getposColor(
          newData?.["dashboard-gain-loss"]?.unRealisedGainLoss
        ),
        fontSize: "20px",
      },
      type: "rs",
    },
    {
      title: "XIRR  ( Since Inception )",
      value:
        newData?.["excess-performance-xirr"]?.portfolioXirr != null
          ? roundNumber(newData?.["excess-performance-xirr"]?.portfolioXirr) +
            " %"
          : "--",
      style: {
        color: getposColor(newData?.["excess-performance-xirr"]?.portfolioXirr),
        fontSize: "20px",
      },
      type: "rs",
    },
  ];
  let carddata2 = [
    // {
    //   title: "Annualised TWRR:",
    //   value:
    //     newData?.["dashboard-twrr"]?.annualisedTwrr != null
    //       ? roundNumber(
    //           parseFloat(newData?.["dashboard-twrr"]?.annualisedTwrr)
    //         ) + " %"
    //       : "--",
    //   display: "p_flex",
    //   titleStyle: { maxWidth: "222px", minWidth: "fit-content" },
    //   style: {
    //     color: getposColor(newData?.["dashboard-twrr"]?.annualisedTwrr),
    //     paddingTop: "0px",
    //     fontSize: "20px",
    //     marginTop: "-7px",
    //   },
    //   type: "rs",
    // },
    // {
    //   title: "Absolute TWRR:",
    //   value:
    //     newData?.["dashboard-twrr"]?.absoluteTwrr != null
    //       ? roundNumber(newData?.["dashboard-twrr"]?.absoluteTwrr) + " %"
    //       : "--",
    //   style: {
    //     color: getposColor(newData?.["dashboard-twrr"]?.absoluteTwrr),
    //     paddingTop: "0px",
    //     fontSize: "20px",
    //     marginTop: "-7px",
    //   },
    //   titleStyle: { maxWidth: "222px", minWidth: "fit-content" },
    //   display: "p_flex",
    //   type: "rs",
    // },

    {
      title: "Benchmark XIRR",
      value:
        newData?.["excess-performance-xirr"]?.benchmarkXirr != null
          ? roundNumber(newData?.["excess-performance-xirr"]?.benchmarkXirr) +
            " %"
          : "--",
      style: {
        paddingTop: "0px",
        fontSize: "20px",
        marginTop: "-7px",
        color: "#000000",
      },
      display: "p_flex",
      titleStyle: { maxWidth: "222px", minWidth: "fit-content" },
      type: "rs",
    },
    {
      title: "Excess Performance",
      value:
        newData?.["excess-performance-xirr"]?.excessPerformance != null
          ? roundNumber(
              newData?.["excess-performance-xirr"]?.excessPerformance
            ) + " %"
          : "--",
      style: {
        paddingTop: "0px",
        fontSize: "20px",
        marginTop: "-7px",
        color: "#000000",
      },
      display: "p_flex",
      titleStyle: { maxWidth: "222px", minWidth: "fit-content" },
      type: "rs",
    },
  ];

  return (
    <div>
      <PageContainer layout_t={1} pageNo={1} bgColor={"#fff"} pdfInfo={pdfInfo}>
        <Header
          title={"Portfolio Overview"}
          type={9}
          subtext={""}
          noDateFormat={true}
          updatedAsOn={newData?.["dashboard-gain-loss"]?.updatedAsOn}
        ></Header>
        <CardsWrapper carddata={carddata} />
        <CardsWrapper
          carddata={carddata2}
          style={{ gridTemplateColumns: "1fr 1fr ", marginTop: "10px" }}
          classnm="tab3"
        />
        {/* <Hr title={"Portfolio Performance"} /> */}
        <Header
          title={"Portfolio Performance"}
          type={10}
          subtext={""}
          noDateFormat={true}
          greyText={` `}
        ></Header>

        <div style={{ height: "499px", width: "100%" }}>
          <div
            className="h_card"
            style={{
              height: "100%",
              width: "84%",
              paddingBottom: "50px",
              boxShadow: "none",
              alignItems: "flex-start",
            }}
          >
            <Bar
              data={
                newData?.["dashboard-portfolio-performance-xirr-graph"] ==
                "error"
                  ? []
                  : newData?.["dashboard-portfolio-performance-xirr-graph"]
              }
              config={{ theme: bartheme, legendtranslatey: 80 }}
              commonprops={{
                enableGridY: false,
                axisLeft: null,
                colors: ["#97B2D5", "#00838F", "#B2C77A"],
              }}
            />
          </div>
        </div>
      </PageContainer>
    </div>
  );
}
