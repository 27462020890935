import * as React from "react";
import { Box, Button, Backdrop, Typography } from "@material-ui/core";
import "../../NewSignUp.css";
import Great from "../../../common/Great";

const mainHeading = {
  fontFamily: "Avenir important",
  fontWeight: 900,
  fontSize: "40px !important",
  color: "#FFFFFF important",
};
const primaryBtn = {
  background: "#4D5CE3",
  borderRadius: "6px",
  width: "147px",
  color: "#FFFFFF",
  fontFamily: "Avenir",
  fontWeight: 500,
  fontSize: "16px",
  padding: "11px 18px",
  height: "50px",
};
const secondaryBtn = {
  ...primaryBtn,
  // background: "rgba(53, 85, 255, 0.1)",
  // border: "1px solid #3555FF",
  // marginRight: "10px",
};
export function BackdropComponent({
  customOpen,
  customSetOpen,
  customHandleAccept,
}) {
  const [open, setOpen] = React.useState(customOpen ? customOpen : false);

  const handleClose = () => {
    customSetOpen(false);
    // customHandleClose()
  };

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // React.useEffect(() => {
  //   setOpen(customOpen);
  // }, [customOpen]);
  console.log(customOpen, "aflauvfwfaf");

  return (
    <>
      {/* <Button onClick={handleOpen}>Show backdrop</Button> */}

      <Backdrop
        style={{
          color: "#fff",
          zIndex: "10000",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "30px",
          backdropFilter: "blur(6px)",
        }}
        open={customOpen}
        onClick={handleClose}
      >
        {/* Main Text */}
        <Typography
          style={{
            fontFamily: "Avenir ",
            fontWeight: "900 ",
            fontSize: "40px ",
            color: "#FFFFFF ",
          }}
          // className="varientH1"
          // variant="h1"
          // component="h1"
          align="center"
        >
          <Great
            bottom={"9px"}
            right={"4.5px"}
            height={"5.8px"}
            width={"5.8px"}
          />
        </Typography>
        {/* Sub Text */}
        <Typography
          className="varientH5 generalFontFam"
          variant="h5"
          align="center"
          style={{ opacity: "0.699999988079071" }}
        >
          Are you sure you want to submit <br></br>these details?
        </Typography>

        {/* Buttons */}
        <Box style={{ display: "flex", gap: "10px" }}>
          <Button
            onClick={() => {
              handleClose();
            }}
            variant="contained"
            style={secondaryBtn}
            className="cancelBtn"
          >
            No
          </Button>
          {/* FORM SUBMIT API CALL👇 */}
          <Button
            variant="contained"
            style={primaryBtn}
            className="continuieBtn"
            onClick={() => {
              customHandleAccept();
              handleClose();
            }}
          >
            Yes
          </Button>
        </Box>
      </Backdrop>
    </>
  );
}
