import React from "react";
import useStyles from "../products/common/ProductsStyles";

import NewTableTitle from "./NewTableTitle";
import FullWidthInput2 from "../../common/FullWidthInput2";
import { getProductAndEntityFromRoute } from "../../utils/strHelper";
import DeleteIcon from "@material-ui/icons/Delete";
import { useTheme } from "@material-ui/core";

function NewtableTopSec({
  handleIconClick,
  pageName,
  setSearch,
  directPageName,
  product,
  iosSwitch,
  icons,
  hideTableEdit,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const themeicons = theme.palette.icons;
  const TableEditIcon = themeicons.tableEditIcon;
  return (
    <div className={`${classes.alignTopHeader}`}>
      <div className={`${classes.mainHeadingAlign}`}>
        <NewTableTitle
          pageName={
            directPageName
              ? getProductAndEntityFromRoute(window?.location?.href)
                ? getProductAndEntityFromRoute(window?.location?.href).entity
                : directPageName
              : pageName === "holding"
              ? "Holding Summary"
              : "Transaction Summary"
          }
          product={
            product == null
              ? ""
              : product
              ? product
              : getProductAndEntityFromRoute(window?.location?.href).product
              ? getProductAndEntityFromRoute(window?.location?.href).product
              : product
          }
        />
        <br />
        {sessionStorage.getItem("currency_format") != "actuals" &&
          sessionStorage.getItem("currency_format") && (
            <span className="subtext capitalize" style={{ fontSize: "16px" }}>
              {" "}
              (values in {sessionStorage.getItem("currency_format")})
            </span>
          )}
      </div>

      <div className={`${classes.iconAlign}`} style={{ gap: "11px" }}>
        {iosSwitch && iosSwitch}
        {setSearch && (
          <FullWidthInput2
            customInputStyle={{
              height: "48px",

              width: "352px",
            }}
            customWrapperStyle={{ marginBottom: "0px !important" }}
            setSearch={setSearch}
          />
        )}

        {hideTableEdit !== true && (
          <span onClick={handleIconClick} className="icon-border2">
            <TableEditIcon height={"24px"} width={"21px"} />
          </span>
        )}
        {icons}
      </div>
    </div>
  );
}

export default NewtableTopSec;
