import React, { useEffect, useMemo, useState } from "react";
import {
  OtherUserHeaderData,
  bodyData3,
  clientHeaderData,
  headerData,
} from "./utils/constands";
import "./userManagemantStyles.css";
import UserManagementTableWrapper from "./UserManagementTableWrapper";
import { UserManagementDetailsHeader } from "./UserManagementDetailsHeader";
import ClientAddMember from "./client_flow/ClientAddMember";
import { useNavigate, useLocation } from "react-router-dom";
import AddUserFlow from "./AddUserFlow/AddUserFlow";
import AddMemberFlow from "./client_flow/AddMemberFlow";
import { useFetchOthersOrMembers } from "../../hooks/useFetchOthersOrMembers";
import NewTabComp from "../dashboard/products/common/NewTabComp";
import { Encrypt } from "../dashboard/print/Components/utils/common";
import AddButton from "../common/AddButton";
import AddClientUserFlow from "./addClientUserFLow/AddClientUserFlow";
import AddClientMemberFlow from "./client_flow/UsermanagmentClient/AddClientMemberFlow";
import { sendWelcomeMail, updatepartneruser } from "../../api";
import { ITEMS_LIMIT_FILES_PARTNER_LIST } from "../../Constants";
import ls from "localstorage-slim";
import { PermissionsGate } from "../auth/PermissionGate";
import { ReactComponent as Email_mail } from "../../../src/assets/icons/email_mail.svg";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { AlertDialog } from "../dashboard/common/AlertDialog";
import { OnboardingContext } from "../../Context";
import CustomizedSnackbars from "../common/CustomizedSnackbars";
function UserManagementClientComp() {
  const [openModal, setOpenModal] = useState(false);
  const [openModalUser, setOpenModalUser] = useState(false);
  const [header, setHeader] = useState([]);
  const scopes = JSON.parse(ls.get("scopes"));
  const [search, setSearchTerm] = useState("");
  const [offset, setItemOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [refresh, setRefresh] = useState(false);

  const naivgate = useNavigate();
  const { state } = useLocation();
  const [openDialog, setOpenDialog] = useState(false);
  const { dispatch } = OnboardingContext();
  // console.log(state, "agalgjj");
  const [memberorothers, setUsertype, setParams, userType, isloading] =
    useFetchOthersOrMembers(
      "member",
      { limit: ITEMS_LIMIT_FILES_PARTNER_LIST },
      state?.data?.client_id || state?.data?.id,
      false,
      setTotal
    );

  const totalPage = useMemo(
    () => Math.ceil(total / ITEMS_LIMIT_FILES_PARTNER_LIST),
    [total]
  );
  function handleSave(values, id) {
    if (id) {
      updatepartneruser("clientUser", id, values)
        .then((res) => {})
        .catch((err) => {});
      return;
    }
  }
  const handleHeader = (headerData) => {
    headerData?.map((val) => {
      if (val?.field == "status") {
        val.onClick = (data) => {
          // data["client_id"] = 197;
          // data["member_id"] = 42;
          // console.log(data, "aflajfn");
          if (window?.location.pathname.includes("client")) {
            naivgate("/client/user-management/edit" + `${Encrypt(data)}`, {
              state: { data: data },
            });
          } else {
            naivgate("/user-management/edit/" + `${Encrypt(data)}`, {
              state: { data: data },
            });
          }
        };
      }
      val.handleTogle = (data) => {
        console.log("toggled", data);
        let data1 = {
          status: "" + data.toglge == "true" ? 1 : 0,
        };

        if (data?.rowData?.role_id) {
          data1["roleId"] = "" + data.rowData.role_id;
        }

        handleSave(data1, data?.rowData?.user_id);
      };
    });
    setHeader(() => headerData);
  };

  const data1 = [
    {
      page: "mapped_users",
      title: "MAPPED USERS",
    },
    {
      page: "other_user",
      title: "Other User",
    },
  ];

  useEffect(() => {
    handleHeader(userType == "member" ? clientHeaderData : OtherUserHeaderData);
  }, [userType]);

  useEffect(() => {
    // setuser(() => currentUser);

    // currentUser = { client_id: 197, member_id: 42 };

    let params = {
      limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
      offset: offset,
    };
    if (search !== "" && search) {
      params["search"] = search;
    }
    setParams(params);
  }, [search, refresh, offset]);

  const hadleOpenAddUser = () => {
    setOpenModalUser(true);
  };
  // console.log(scopes?.can_add_client_member, "agnlkagn");

  return (
    <>
      <UserManagementDetailsHeader
        data={state?.data}
        componentToRender={
          state?.headerType ? state?.headerType : "userHeaderInfo"
        }
        customHandleBack={() => {
          if (window?.location.pathname.includes("client")) {
            naivgate("/client");
          }
        }}
      />
      <CustomizedSnackbars />
      {header?.length > 0 && (
        <UserManagementTableWrapper
          customScopes={[scopes?.can_add_client_member]}
          customTitle={"Family Members"}
          headerData={header}
          setSearchTerm={setSearchTerm}
          setItemOffset={setItemOffset}
          totalPage={totalPage}
          addBtnTitle={"Add Client Member"}
          addRepStyle={{ display: "flex" }}
          addRepComp={
            <>
              <PermissionsGate scopes={[scopes?.can_send_welcome_email]}>
                <button
                  className="reportBtn"
                  style={{
                    background: "var(--border)",
                    padding: "0px 30px",
                    height: "46px",
                  }}
                  onClick={() => {
                    setOpenDialog(true);
                  }}
                >
                  {" "}
                  <Email_mail style={{ paddingRight: "10px" }} /> Send Welcome
                  Email
                </button>
              </PermissionsGate>
              <PermissionsGate scopes={[scopes?.can_add_client_member]}>
                <AddButton
                  addBtnTitle={"Add Client User"}
                  handleIconClick={hadleOpenAddUser}
                />
              </PermissionsGate>
            </>
          }
          returnno={true}
          data={memberorothers}
          openModal={openModal}
          setOpenModal={setOpenModal}
          customheader={
            state?.headerType == "userManagementDetailsHeader"
              ? undefined
              : () => (
                  <NewTabComp
                    data={data1}
                    customWidth={"fit-content"}
                    disableArrow={true}
                    // customSelect={data.findIndex((val) => val?.page == pageName)}
                    customHandle={(e) => {
                      console.log("clicked", e);
                      if (e == "mapped_users") {
                        setUsertype("member");
                      } else {
                        setUsertype("users");
                      }
                    }}
                  />
                )
          }
        />
      )}
      {/* <AddMemberFlow
        data={state?.data}
        openModal={openModal}
        setOpenModal={setOpenModal}
      /> */}
      <AddClientMemberFlow
        data={state?.data}
        id={state?.data?.id}
        openModal={openModal}
        setOpenModal={setOpenModal}
        handleRefresh={() => {
          setRefresh(!refresh);
        }}
      />
      <AddClientUserFlow
        data={state?.data}
        openModal={openModalUser}
        setOpenModal={setOpenModalUser}
      />
      <AlertDialog
        open={openDialog}
        setOpen={setOpenDialog}
        paperStyle={{
          width: "400px",
          padding: "30px 20px",
          background: "var( --secondaryBackground-color)",
        }}
        dialogTitle={() => (
          <DialogTitle id="alert-dialog-title">
            <div
              style={{
                alignTtems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            ></div>
            {/* <Typography
              style={{
                color: "#fff",
                fontFamily: "Avenir",
                fontSize: "18x",
                fontStyle: "normal",
                fontWeight: 800,
                lineHeight: "35.2px",
                textAlign: "center",
              }}
            >
              Your Report is being created
            </Typography> */}
          </DialogTitle>
        )}
        dialogContent={() => (
          <DialogContentText
            id="alert-dialog-description"
            style={{
              color: "#fff",
              textAlign: "center",
              fontFamily: "Avenir",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 350,
              lineHeight: "22px",
            }}
          >
            Are you sure you want to send the Welcome Email?
          </DialogContentText>
        )}
        dialogFooter={() => (
          <>
            <button
              className="approveBtnDetailsAccordion"
              style={{ background: "#7043DE", width: "250px" }}
              onClick={() => {
                sendWelcomeMail(state?.data?.client_id || state?.data?.id)
                  .then((res) => {
                    dispatch({
                      type: "UPDATE_SNACKBAR_DATA",
                      payload: {
                        success: true,
                        error: false,
                        showMsg: true,
                        message: res.data.message,
                      },
                    });
                  })
                  .catch((error) => {
                    dispatch({
                      type: "UPDATE_SNACKBAR_DATA",
                      payload: {
                        success: false,
                        error: true,
                        showMsg: true,
                        message: error.response.data.error.message,
                      },
                    });
                  });
                setOpenDialog(false);
              }}
            >
              Yes
            </button>
            <button
              className="approveBtnDetailsAccordion"
              style={{ background: "#41297b", width: "250px", border: "none" }}
              onClick={() => {
                setOpenDialog(false);
              }}
            >
              No
            </button>
          </>
        )}
      />
      {/* <AddUserFlow
        // handleClose={handleAddUserClose}
        // selectedData={selectedUserData}
        openModal={openModal}
        setOpenModal={setOpenModal}
      /> */}
    </>
    // <>
    //   <Typography
    //     style={{
    //       cursor: "pointer",
    //       marginTop: "3%",
    //       marginLeft: "5%",
    //       width: " 70vw",
    //     }}
    //     className={classes.titleText}
    //   >
    //     Holding Summary
    //   </Typography>

    //   <div style={{ display: "flex", justifyContent: "space-between" }}>
    //     <div style={{ marginLeft: "5%", width: "35vw" }}>
    //       <SearchInput></SearchInput>
    //     </div>
    //     <div
    //       style={{
    //         display: "flex",
    //         justifyContent: "space-evenly",
    //         width: "35vw",
    //       }}
    //     >
    //       <FormControl
    //         variant="outlined"
    //         className={`${classes.formControl} ${classes.inputField}`}
    //       >
    //         <InputLabel id="demo-simple-select-outlined-label">
    //           Select Roles
    //         </InputLabel>
    //         <Select
    //           native
    //           style={{ color: "#FAF9FF" }}
    //           labelId="demo-simple-select-outlined-label"
    //           label="Select Roles"
    //           inputProps={{
    //             classes: {
    //               icon: classes.icon,
    //             },
    //           }}
    //         >
    //           <option className={classes.optiondesign}>1</option>
    //           <option className={classes.optiondesign}>2</option>
    //           <option className={classes.optiondesign}>3</option>
    //         </Select>
    //       </FormControl>
    //       <PermissionsGate scopes={[scopes.can_add_user_management_member]}>
    //         <Button
    //           onClick={handleIconClick}
    //           style={{
    //             display: "flex",
    //             justifyContent: "space-evenly",
    //             width: "10vw",
    //           }}
    //           className={classes.button}
    //         >
    //           <Add></Add>
    //           <Typography className={classes.buttonTypo}>Add Member</Typography>
    //         </Button>
    //       </PermissionsGate>
    //     </div>
    //   </div>

    //   <div
    //     style={{
    //       minHeight: "300px",
    //       overflowY: "hidden",
    //       width: "90%",
    //       margin: "2% 5%",
    //     }}
    //   >
    //     <TableContainer>
    //       <Table
    //         style={{ borderCollapse: "separate" }}
    //         aria-label="simple table"
    //       >
    //         <TableHead
    //           style={{ background: "#1D1B36", position: "sticky", top: 0 }}
    //         >
    //           <TableRow>
    //             <TableCell
    //               style={{
    //                 borderTopLeftRadius: "20px",
    //                 borderBottomLeftRadius: "20px",
    //                 borderBottom: "none",
    //               }}
    //               className={classes.tableHeaderTextNew}
    //             >
    //               Name
    //             </TableCell>
    //             <TableCell
    //               style={{ borderBottom: "none" }}
    //               className={classes.tableHeaderTextNew}
    //             >
    //               Role
    //             </TableCell>
    //             <TableCell
    //               style={{ borderBottom: "none" }}
    //               className={classes.tableHeaderTextNew}
    //             >
    //               Permission
    //             </TableCell>
    //             <TableCell
    //               style={{
    //                 borderTopRightRadius: "20px",
    //                 borderBottomRightRadius: "20px",
    //                 borderBottom: "none",
    //               }}
    //               className={classes.tableHeaderTextNew}
    //             >
    //               Last Active
    //             </TableCell>
    //           </TableRow>
    //         </TableHead>
    //         <TableBody>
    //           <TableRow>
    //             <TableCell
    //               className={`${classes.rowTextNew} ${classes.paddingLeft}`}
    //               style={{ borderBottom: "1px solid #28235C" }}
    //               align="left"
    //             >
    //               1
    //             </TableCell>
    //             <TableCell
    //               className={classes.rowTextNew}
    //               style={{ borderBottom: "1px solid #28235C" }}
    //               align="left"
    //             >
    //               1
    //             </TableCell>
    //             <TableCell
    //               className={classes.rowTextNew}
    //               style={{ borderBottom: "1px solid #28235C" }}
    //               align="left"
    //             >
    //               1
    //             </TableCell>
    //             <TableCell
    //               className={classes.rowTextNew}
    //               style={{ borderBottom: "1px solid #28235C" }}
    //               align="left"
    //             >
    //               1
    //             </TableCell>
    //           </TableRow>
    //           <TableRow>
    //             <TableCell
    //               className={`${classes.rowTextNew} ${classes.paddingLeft}`}
    //               style={{ borderBottom: "1px solid #28235C" }}
    //               align="left"
    //             >
    //               1
    //             </TableCell>
    //             <TableCell
    //               className={classes.rowTextNew}
    //               style={{ borderBottom: "1px solid #28235C" }}
    //               align="left"
    //             >
    //               1
    //             </TableCell>
    //             <TableCell
    //               className={classes.rowTextNew}
    //               style={{ borderBottom: "1px solid #28235C" }}
    //               align="left"
    //             >
    //               1
    //             </TableCell>
    //             <TableCell
    //               className={classes.rowTextNew}
    //               style={{ borderBottom: "1px solid #28235C" }}
    //               align="left"
    //             >
    //               1
    //             </TableCell>
    //           </TableRow>
    //           <TableRow>
    //             <TableCell
    //               className={`${classes.rowTextNew} ${classes.paddingLeft}`}
    //               style={{ borderBottom: "1px solid #28235C" }}
    //               align="left"
    //             >
    //               1
    //             </TableCell>
    //             <TableCell
    //               className={classes.rowTextNew}
    //               style={{ borderBottom: "1px solid #28235C" }}
    //               align="left"
    //             >
    //               1
    //             </TableCell>
    //             <TableCell
    //               className={classes.rowTextNew}
    //               style={{ borderBottom: "1px solid #28235C" }}
    //               align="left"
    //             >
    //               1
    //             </TableCell>
    //             <TableCell
    //               className={classes.rowTextNew}
    //               style={{ borderBottom: "1px solid #28235C" }}
    //               align="left"
    //             >
    //               1
    //             </TableCell>
    //           </TableRow>
    //           <TableRow>
    //             <TableCell
    //               className={`${classes.rowTextNew} ${classes.paddingLeft}`}
    //               style={{ borderBottom: "1px solid #28235C" }}
    //               align="left"
    //             >
    //               1
    //             </TableCell>
    //             <TableCell
    //               className={classes.rowTextNew}
    //               style={{ borderBottom: "1px solid #28235C" }}
    //               align="left"
    //             >
    //               1
    //             </TableCell>
    //             <TableCell
    //               className={classes.rowTextNew}
    //               style={{ borderBottom: "1px solid #28235C" }}
    //               align="left"
    //             >
    //               1
    //             </TableCell>
    //             <TableCell
    //               className={classes.rowTextNew}
    //               style={{ borderBottom: "1px solid #28235C" }}
    //               align="left"
    //             >
    //               1
    //             </TableCell>
    //           </TableRow>
    //         </TableBody>
    //       </Table>
    //     </TableContainer>
    //   </div>

    //   <div
    //     style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}
    //   >
    //     <Link
    //       to="/user-management/family-structure"
    //       style={{ textDecoration: "none" }}
    //       className={classes.familyLinkTypo}
    //     >
    //       View Family Structure{" "}
    //       <div style={{ marginTop: "4px", marginLeft: "10px" }}>
    //         <RightArrow />{" "}
    //       </div>{" "}
    //     </Link>
    //   </div>

    //   <Modal
    //     open={openModal}
    //     BackdropProps={{
    //       classes: {
    //         root: classes.backDrop,
    //       },
    //     }}
    //     aria-labelledby="modal-modal-title"
    //     aria-describedby="modal-modal-description"
    //   >
    //     <div className={`${classes.modalContainer} form-container`}>
    //       <div style={{ marginBottom: "5%" }}>
    //         <Typography
    //           style={{ textAlign: "center" }}
    //           className={classes.titleText}
    //         >
    //           User Role & Access
    //         </Typography>

    //         <Cross
    //           onClick={handleButtonClick}
    //           style={{ float: "right", marginTop: "-6%", cursor: "pointer" }}
    //         />
    //       </div>
    //       <hr className={classes.modalHr}></hr>
    //       {/* <FormControl> */}
    //       <RadioGroup
    //         row
    //         aria-labelledby="demo-row-radio-buttons-group-label"
    //         name="row-radio-buttons-group"
    //         style={{ display: "flex", justifyContent: "space-evenly" }}
    //       >
    //         <FormControlLabel
    //           className={classes.inputRadio}
    //           value="all_access"
    //           control={<Radio />}
    //           label="All Access"
    //         />
    //         <FormControlLabel
    //           className={classes.inputRadio}
    //           value="restriced_user"
    //           control={<Radio />}
    //           label="Restricted User"
    //         />
    //       </RadioGroup>
    //       {/* </FormControl> */}

    //       <div
    //         style={{
    //           minHeight: "300px",
    //           overflowY: "hidden",
    //           width: "90%",
    //           margin: "2% 5%",
    //         }}
    //       >
    //         <TableContainer>
    //           <Table
    //             style={{ borderCollapse: "separate" }}
    //             aria-label="simple table"
    //           >
    //             <TableHead
    //               style={{ background: "#1D1B36", position: "sticky", top: 0 }}
    //             >
    //               <TableRow>
    //                 <TableCell
    //                   style={{
    //                     borderTopLeftRadius: "20px",
    //                     borderBottomLeftRadius: "20px",
    //                     borderBottom: "none",
    //                     paddingLeft: "45px",
    //                   }}
    //                   className={classes.tableHeaderTextNew}
    //                 >
    //                   Name
    //                 </TableCell>
    //                 <TableCell
    //                   style={{
    //                     borderTopRightRadius: "20px",
    //                     borderBottomRightRadius: "20px",
    //                     borderBottom: "none",
    //                     textAlign: "right",
    //                     paddingRight: "45px",
    //                   }}
    //                   className={classes.tableHeaderTextNew}
    //                   textAlign="right"
    //                 >
    //                   Grant Access
    //                 </TableCell>
    //               </TableRow>
    //             </TableHead>
    //             <TableBody>
    //               <TableRow>
    //                 <TableCell
    //                   className={`${classes.rowTextNew} ${classes.paddingLeft}`}
    //                   style={{
    //                     borderBottom: "1px solid #28235C",
    //                     paddingLeft: "45px",
    //                   }}
    //                 >
    //                   1
    //                 </TableCell>
    //                 <TableCell
    //                   className={classes.rowTextNew}
    //                   style={{
    //                     borderBottom: "1px solid #28235C",
    //                     textAlign: "right",
    //                     paddingRight: "75px",
    //                   }}
    //                 >
    //                   1
    //                 </TableCell>
    //               </TableRow>
    //             </TableBody>
    //           </Table>
    //         </TableContainer>
    //       </div>

    //       <div className={classes.buttonContainer}>
    //         <Button
    //           variant="contained"
    //           className={classes.buttonlined}
    //           style={{ width: "40%", marginTop: "5%" }}
    //           onClick={handleButtonClick}
    //         >
    //           <span className={`${classes.buttonTypo}`}>Cancel</span>
    //         </Button>
    //         <Button
    //           variant="contained"
    //           className={classes.button}
    //           style={{ width: "40%", marginTop: "5%" }}
    //         >
    //           <span className={`${classes.buttonTypo}`}>Save</span>
    //         </Button>
    //       </div>
    //     </div>
    //   </Modal>
    // </>
  );
}

export default UserManagementClientComp;
