import React, { useEffect, useState } from "react";
import NewDialaugeComp from "../../newClientList/NewDialogComp";
import { useLocation, useNavigate } from "react-router-dom";
import AddRepForm1 from "./AddRepForm1";
import usePrtnerOnboardingFLow from "../../NewSignUp/hooks/usePrtnerOnboardingFLow";
import SetPinForm from "../../NewSignUp/SetPinForm";
import { OnboardingContext } from "../../../Context";
import useUpdateUsers from "../../../hooks/useUpdateUsers";
import { handleRoleId } from "../utils/cmmon";
import { partnerRepRoles } from "../../../Constants";
import PageLoader from "../../dashboard/products/common/pageLoader";

function AddPartnerRepresentativeFlow({
  openModal,
  setOpenModal,
  selectedData,
  handleClose,
  onUpdate,
  partnerId,
}) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { userState } = OnboardingContext();
  const [next, setNext] = useState(1); //state?.step ? state?.step :
  const [formData, setFormData] = useState({
    id: userState?.loginedUserData?.id,
    ...selectedData,
  });
  const [loading, setLoading] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState("");

  // console.log(formData, "agknlgjj");

  const handleNext = (stepAddition, formData1) => {
    setNext((prev) => prev + stepAddition);
    let state = {
      formData: formData1 ? formData1 : formData,
      step: next + stepAddition,
    };
    if (partnerId) {
      // navigate("/user-management", { state: state });
    } else {
      navigate("/user-management", { state: state });
    }
  };

  const { api5 } = usePrtnerOnboardingFLow({
    setFormData,
    setLoading,
    setApiErrorMessage,
    handleNext,
    formData,
  });
  const { updateUsers } = useUpdateUsers({ setLoading });

  const handleBack = (step) => {
    if (step >= 1) setNext(step);
    handleNext(-1);
  };

  useEffect(() => {
    setFormData((prev) => ({ id: userState?.loginedUserData?.id }));
    if (selectedData) setFormData((prev) => ({ ...prev, ...selectedData }));
  }, [selectedData]);

  const chagneInStep = (step) => {
    switch (step) {
      case 1:
        return (
          <AddRepForm1
            formMaxHeight="63vh"
            noStepBottom={true}
            currentStep={next}
            noStep={true}
            formData={formData}
            edit={selectedData}
            formWidth={"523px"}
            customHandleSubmit={(data) => {
              if (selectedData) {
                let updatePayload = {
                  fullName: data?.fullName,
                  roleId: `${handleRoleId(data?.userRole, partnerRepRoles)}`,
                  // lobId: data?.lobId,
                  // subLobId: data?.lobId,
                  // region: data?.region,
                };

                if (selectedData?.partnerRole !== data?.userRole) {
                  updatePayload["newRoleId"] = `${handleRoleId(
                    data?.userRole,
                    partnerRepRoles
                  )}`;
                }
                // console.log(data?.userRole, selectedData, "aglkagjn");
                updateUsers({
                  data: updatePayload,
                  id: selectedData?.userId,
                  type: "partnerUser",
                }).then((re) => {
                  setNext(1);
                  setOpenModal(false);
                  setFormData(() => {});
                  onUpdate();
                  if (handleClose) handleClose();
                });
              } else {
                let newPayload = {
                  representativeName: data?.fullName,
                  mobile: data?.mobile,
                  email: data?.email,
                  representativeType: data?.userRole,
                  lobId: data?.lobId,
                  subLobId: data?.lobId,
                  region: data?.region,
                };

                api5(undefined, newPayload, partnerId);
              }
            }}
            apiErrorMessage={apiErrorMessage}
            loading={loading}
          />
        );
      case 2:
        return (
          <SetPinForm
            formWidth={"523px"}
            handleBack={handleBack}
            handlenext={(step) => {
              onUpdate();
              handleNext(step);
            }}
            userId={formData?.user?.id}
          />
        );
      default: {
        setNext(1);
        setFormData(() => ({ id: userState?.loginedUserData?.id }));
        return setOpenModal(false);
      }
      // navigate("/user-management", {
      //   state: {
      //     step: next,
      //     formData: formData,
      //     openModal: false,
      //   },
      // });
    }
  };

  return (
    <>
      <NewDialaugeComp
        // width={next == 3 ? "md" : "sm"}
        fullWidth={false}
        open={openModal}
        customPaperStyle={{
          paddingBottom: "55px",
          minHeight: "auto",
        }}
        handleClose={() => {
          setOpenModal(() => false);
          setFormData(() => ({ id: userState?.loginedUserData?.id }));
          if (handleClose) handleClose();
        }}
      >
        {chagneInStep(next)}
      </NewDialaugeComp>
      <PageLoader showLoader={loading} />
    </>
  );
}

export default AddPartnerRepresentativeFlow;
