import React, { useEffect, useContext } from "react";
import useStyles from "../common/ProductsStyles";
import { useState } from "react";
import _, { map } from "lodash";
import { getTransactionsSummary } from "../../../../api";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import { OnboardingContext } from "../../../../Context";
import moment from "moment";
import "../common/products-style.css";
import PmsReportTable from "../pms/PmsReportTable";
import { objectFormating } from "./objectFormating";
import { ITEMS_LIMIT_FILES_PARTNER_LIST } from "../../../../Constants";

function AifEquity({
  openModal,
  setOpenModal,
  pageName,
  p,
  summaryType,
  tableConfig,
  search,
  offset,
  setOffset,
}) {
  const [tableColumns, setTableColumns] = useState([]);
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const { userState } = OnboardingContext();
  const [loading, setLoading] = useState(false);
  const [reset, setReset] = useState();
  const [tableHeader, setTableHeader] = useState(() => [...tableConfig]);
  const [checkedTrans, setCheckedTrans] = useState([]);
  const [pagiNationData, setPageinationData] = useState({
    pageCount: null,
    // limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
  });

  const getTableData = async () => {
    setLoading(true);
    let tableName = "transaction-summary";
    // static params for testing purpose
    // let params =
    //   pageName === "holding"
    //     ? {
    //         clientId: 199,
    //         assetClass: p,
    //       }
    //     : {
    //         clientId: 199,
    //         memberId: 1,
    //         startDate: "2021-04-10",
    //         endDate: "2022-10-09",
    //         assetClass: p,
    //       };

    let params = {
      clientId: userState?.loginedUserData?.id,
      // clientId: 199,
      search: search,
      ...getfilter_as_params(),
      assetClass: p || "equity",
      offset: offset,
      limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
    };

    try {
      let response = await getTransactionsSummary("aif", tableName, params);
      let newlist = [];
      let transactionList = [];
      response?.data?.data?.result?.map((val) => {
        // if (pageName === "holding") {
        let obj = objectFormating(val, tableHeader);
        newlist.push(obj);
        // } else {
        // if (val.asset_type == p) {
        //   val.advisor = val?.advisor + "%" + val?.updated_at;
        //   let obj = objectFormating(val, tableHeader);
        //   newlist.push(obj);
        // }
        // if (
        //   val["transaction_type"] != null &&
        //   !transactionList
        //     .map((val) => val?.title)
        //     .includes(val["transaction_type"])
        // ) {
        //   transactionList.push({
        //     title: val["transaction_type"],
        //     show: false,
        //   });
        // }
        // }
      });
      // console.log(newlist, "ajagbkj");
      setPageinationData((prev) => {
        return { ...prev, pageCount: response?.data?.data?.meta?.count };
      });
      setCheckedTrans(transactionList);
      setTableColumns(() => newlist);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getTableData();
  }, [pageName, p, mfoFilter, reset, tableConfig, search, offset]);

  return (
    <div style={{ position: "relative" }}>
      <PmsReportTable
        setTableColumns={setTableColumns}
        tableColumns={tableColumns}
        loading={loading}
        setOpenModal={setOpenModal}
        openModal={openModal}
        headerList={tableConfig}
        tableHeader={tableHeader}
        setTableHeader={setTableHeader}
        stickyProductName={true}
        checkedTransList={checkedTrans}
        setReset={setReset}
        pageCount={pagiNationData?.pageCount}
        setItemOffset={setOffset}
        pageNumber={offset / ITEMS_LIMIT_FILES_PARTNER_LIST + 1 || 1}
      />
    </div>
  );
}

export default AifEquity;
