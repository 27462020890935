import React from "react";
import useStyles from "./FamilyStyles";
import { Avatar } from "@material-ui/core";
import Xarrow from "react-xarrows";
import { useState } from "react";
import { handleAvatarName } from "./utils/common";

function FamilySidebar({
  associatedData,
  SelectedNodes,
  parentData,
  hierarchyChild,
  liaison,
}) {
  const classes = useStyles();
  const [remain, setRemain] = useState();
  const [child, setChild] = useState();
  console.log("ramdata", hierarchyChild);
  const getId = (id, associated) => {
    let arr = [];
    let newArr = [];
    associated.map((c) => {
      if (id == c?.item?.parentNodeId) {
        arr.push(c);
        newArr?.push(c?.item?.nodeId);
      }
    });
    associated.map((a) => {
      if (newArr?.includes(a?.item?.parentNodeId)) {
        arr.push(a);
        newArr.push(a?.item?.nodeId);
      }
    });

    return arr;
  };

  const ArrowDown = ({ from, to }) => (
    <Xarrow
      //   dashness={true}
      headShape="circle"
      showTail={false}
      startAnchor={{ position: "left" }}
      endAnchor={{ position: "left" }}
      tailShape="circle"
      curveness={0.8}
      path={"grid"}
      gridBreak={"5%"}
      start={from}
      end={to}
      strokeWidth={2}
      color="#AA6FFE"
      dashness={{ strokeLen: 5, nonStrokeLen: 5 }}
    />
  );

  return (
    <>
      <div className={classes.container}>
        {/* FIRST SECTION */}
        {/* <ArrowDown from={"parent"} to={"child"} /> */}
        {associatedData?.map((data) => {
          // SelectedNodes[0] == data.item.nodeId ? setRemain(data.item.nodeId) : setRemain(null)
          return SelectedNodes[0] == data.item.nodeId ? (
            <div id={`parent`} className={classes.firstbox}>
              <div className={classes.box1}>
                <div className={classes.avatar}>
                  <Avatar className={classes.avaimg}></Avatar>
                </div>
                <div className={classes.details}>
                  <div>
                    <span className={classes.heading}>
                      {data?.item?.full_name}
                    </span>
                  </div>

                  <div className={classes.roundbox}>
                    <span className={classes.des}>{data?.item?.nickName}</span>
                  </div>
                </div>
              </div>
              <p className={classes.box2} style={{ width: "auto" }}>
                Client Representative:
              </p>
              <div className={classes.box4}>
                <span className={classes.box3}>Primary Contact</span>
                <span className={classes.box2}>
                  From Client: {liaison[0]?.full_name}
                </span>
                <span className={classes.box2}>
                  From Neo: {hierarchyChild[1]?.banker_name}
                </span>
              </div>
            </div>
          ) : null;
        })}

        {associatedData?.map((data) => {
          if (SelectedNodes[0] == data.item.parentNodeId) {
            console.log(getId(data.item.nodeId, associatedData));
          }
          // SelectedNodes[0] == data.item.parentNodeId ? setChild(data.item.nodeId) : setChild(null)
          return SelectedNodes[0] == data.item.parentNodeId ? (
            <>
              <div className={classes.secondbox}>
                <div className={classes.box1}>
                  <div
                    // id={`child` + data.item.nodeId}
                    className={classes.avatar}
                  >
                    <Avatar
                      id={`child` + data.item.nodeId}
                      className={classes.avaimg}
                    >
                      {handleAvatarName(data?.item?.full_name)}
                    </Avatar>
                  </div>
                  <div className={classes.details}>
                    <div>
                      <span className={classes.secondheading}>
                        {data?.item?.full_name}
                      </span>
                    </div>

                    <div className={classes.secondroundbox}>
                      <span>{data?.item?.nickName}</span>
                    </div>
                  </div>
                </div>

                <div>
                  <span className={classes.blockhead}>
                    Members that can view your portfolio
                  </span>
                  <div className={classes.recboxes}>
                    {/* {getIdParent(data.item.nodeId, parentData).map((d) => {
                                            
                                            return <div className={classes.recbox1}>
                                                <div className={classes.innerRecbox}>
                                                    <div className={classes.textbox}>
                                                        <span>{d?.item.nickName[0]?.toUpperCase()}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        })} */}
                    {parentData.map((d) => {
                      return (
                        <div className={classes.recbox1}>
                          <Avatar className={classes.memavatar}>
                            {/* {d?.item?.full_name[0]?.toUpperCase()} */}
                            {/* {d?.item?.full_name
                              ? d?.item?.full_name[0]?.toUpperCase()
                              : ""} */}
                            {handleAvatarName(d?.item?.full_name)}
                          </Avatar>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div>
                  <span className={classes.blockhead}>
                    Portfolio that can be viewed by you{" "}
                  </span>
                  <div className={classes.recboxes}>
                    {getId(data?.item?.nodeId, associatedData)?.map((d) => {
                      return (
                        <div className={classes.recbox1}>
                          <Avatar className={classes.memavatar}>
                            {/* {d?.item.nickName[0]?.toUpperCase()} */}
                            {handleAvatarName(d?.item?.full_name)}
                          </Avatar>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <ArrowDown from={"parent"} to={"child" + data.item.nodeId} />
            </>
          ) : null;
        })}
      </div>
    </>
  );
}

export default FamilySidebar;
