import { ReactComponent as FamilyIcon } from "../../../assets/icons/familyTreeIcon.svg";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routes";
import { useTheme } from "@material-ui/core";
export function FamilyTreeButton({ style }) {
  const navigate = useNavigate();
  const handleFamilyTreeNav = () => {
    navigate(routes?.familyTree);
  };
  const theme = useTheme();
  return (
    <>
      <button
        className={"button23"}
        onClick={handleFamilyTreeNav}
        // style={style}
        style={{
          background: theme.palette.filterCalendar.background,
          border: `2px solid ${theme.palette.filterCalendar.border}`,
        }}
      >
        <span
          className="button23Container"
          style={{ background: theme.palette.filterCalendar.background }}
        >
          <FamilyIcon /> Family Tree Mapping
        </span>
      </button>
    </>
  );
}
