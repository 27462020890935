
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

const CheckBoxWithColor = withStyles((theme) => ({
  root: {
    color: "#8b44f6",
    "& .MuiIconButton-label": {
      position: "relative",
      zIndex: 0,
      "& .MuiSvgIcon-root":{
        width:'18px',
        height:'18px'
      }
    },
    "&:not($checked) .MuiIconButton-label:after": {
      content: '""',
      left: 4,
      top: 4,
      height: 10,
      width: 10,
      position: "absolute",
      backgroundColor: "white",
      zIndex: -1
    }
  },
}))(Checkbox);

export default CheckBoxWithColor