import React from "react";
import Default from "../components/dashboard/defaultPages/Default";
import { MfoHoldingPeriod } from "../components/dashboard/products/mfo/HoldingPeriod";

function HoldingPeriodDetails() {
  return (
    <Default>
      <MfoHoldingPeriod></MfoHoldingPeriod>
    </Default>
  );
}

export default HoldingPeriodDetails;
