import React from "react";
import NewDialaugeComp from "../../newClientList/NewDialogComp";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AddMemberForm1 from "./AddMemberForm1";
import SetPinForm from "../../NewSignUp/SetPinForm";

function AddMemberFlow({ openModal, setOpenModal, selectedData, handleClose }) {
  const { state } = useLocation();
  const [next, setNext] = useState(1); //state?.step ? state?.step :
  const [formData, setFormData] = useState(selectedData);
  const [apiErrorMessage, setApiErrorMessage] = useState("");

  useEffect(() => {
    if (selectedData) setFormData(selectedData);
  }, [selectedData]);

  const handleBack = (step) => {
    if (step >= 1) setNext(step);
    handleNext(-1);
  };

  const handleNext = (stepAddition, formData1, client) => {
    setNext(next + stepAddition);
    let state = {
      formData: formData1 ? formData1 : formData,
      step: next + stepAddition,
    };
    // navigate("/register1", {
    //   state: { ...state },
    // });
    // console.log(stepAddition, "sgjsbgkjsg");
    setApiErrorMessage("");
  };

  // #TODO API Integration pending

  const chagneInStep = (step) => {
    switch (step) {
      case 1:
        return (
          <AddMemberForm1
            formMaxHeight="65vh"
            noStepBottom={true}
            currentStep={next}
            noStep={true}
            formData={formData}
            customHandleSubmit={(data) => {
              console.log(data, "afjkababjsf");
            }}
            formWidth={"523px"}
            apiErrorMessage={apiErrorMessage}
          />
        );
      case 2:
        return (
          <SetPinForm
            formWidth={"523px"}
            handleBack={handleBack}
            handlenext={handleNext}
            userId={formData?.id}
          />
        );

      default:
        return setOpenModal(false);
    }
  };

  return (
    <NewDialaugeComp
      width={"640px"}
      fullWidth={false}
      open={openModal}
      handleClose={() => {
        setOpenModal(false);
        setFormData({});
        handleClose();
      }}
    >
      {chagneInStep(next)}
    </NewDialaugeComp>
  );
}

export default AddMemberFlow;
