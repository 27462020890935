import React, { useState, useEffect, useCallback, useRef } from "react";
import useStyles from "../common/ProductsStyles";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Modal,
  Button,
  Menu,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import ErrorMessageComp from "../../../../../src/components/NewSignUp/common/ErrorMessageComp";
import PageLoader from "../common/pageLoader";
import CheckBoxWithColor from "../common/CheckBoxWithColor";
import "../common/products-style.css";
import TableContainer from "@material-ui/core/TableContainer";
import DataNotFound from "../common/DataNotFound";
import { ReactComponent as Transaction } from "../../../../assets/icons/transaction-type.svg";
import HighLightText from "../../../common/HighLightText";
import { ReactComponent as UpArrow } from "../../../../assets/icons/upArrow.svg";
import { ReactComponent as DragIndicator } from "../../../../assets/icons/dragIndicator.svg";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import RadioButtonUncheckedRoundedIcon from "@material-ui/icons/RadioButtonUncheckedRounded";
import { firstCharCapitalize } from "../../../utils/strHelper";
import NewTableTitle from "../../common/NewTableTitle";
import TableEditModal from "../common/TableEditModal";
import AppPagination from "../../../verificationUpload/AppPagination";
import { ITEMS_LIMIT_FILES_PARTNER_LIST } from "../../../../Constants";
import { useTheme } from "@material-ui/core";
import Loader from "../../../loader/Loader";
import ReplayIcon from "@material-ui/icons/Replay";
import { dividerPMSReportTableHeader } from "../../../utils/constands";
import { ReactComponent as DownloadIcon } from "../../../../assets/icons/uploadIcon.svg";
import { ReactComponent as UploadIcon } from "../../../../assets/icons/downloadIcon.svg";
import excelLogo from "../../../../assets/icons/excel.png";
import LinearProgress from "@material-ui/core/LinearProgress";

import CircularProgress from "@material-ui/core/CircularProgress";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import { OnboardingContext } from "../../../../Context";
import { uploadPDFFileValidate } from "../../../../hooks/validationFunctions";
import { uploadPMSFile } from "../../../../api";
import { saveAs } from "file-saver";
import Close from "@material-ui/icons/Close";
import CustomizedSnackbars from "../../../common/CustomizedSnackbars";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

const browseIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="69"
    height="61"
    viewBox="0 0 69 61"
    fill="none"
  >
    <path
      d="M36.389 15.3906L36.4803 15.4179L36.4843 15.4134C36.9191 15.4923 37.3505 15.232 37.4782 14.8008C38.6419 10.8908 42.308 8.15929 46.3924 8.15929C46.876 8.15929 47.2682 7.76708 47.2682 7.28353C47.2682 6.79996 46.876 6.40778 46.3924 6.40778C41.3726 6.40778 37.1543 9.75002 35.7998 14.3016C35.6616 14.7652 35.9257 15.2524 36.389 15.3906Z"
      fill="white"
      stroke="#F9FFF9"
      stroke-width="0.297963"
    />
    <path
      d="M56.5667 42.895H52.2061C51.8049 42.895 51.4794 42.5695 51.4794 42.1682C51.4794 41.767 51.8048 41.4415 52.2061 41.4415H56.5667C62.5774 41.4415 67.4681 36.5508 67.4681 30.5401C67.4681 24.5293 62.5774 19.6386 56.5667 19.6386H56.4618C56.251 19.6386 56.0506 19.5473 55.9126 19.3879C55.7745 19.2285 55.7122 19.0172 55.7423 18.8086C55.8072 18.3559 55.8399 17.9011 55.8399 17.4584C55.8399 12.249 51.6012 8.01039 46.3919 8.01039C44.3653 8.01039 42.4326 8.64371 40.8025 9.84234C40.4444 10.1055 39.9356 9.98873 39.7285 9.59472C35.112 0.804045 23.0543 -0.376453 16.803 7.27066C14.1695 10.4923 13.1348 14.6831 13.9639 18.7676C14.0553 19.2187 13.7101 19.6392 13.2517 19.6392H12.9605C6.9497 19.6392 2.05906 24.5298 2.05906 30.5406C2.05906 36.5514 6.9497 41.442 12.9605 41.442H17.321C17.7223 41.442 18.0478 41.7675 18.0478 42.1688C18.0478 42.5701 17.7223 42.8956 17.321 42.8956H12.9605C6.14808 42.8956 0.605469 37.353 0.605469 30.5406C0.605469 23.9193 5.84123 18.4976 12.39 18.1986C11.7748 13.961 12.9511 9.68657 15.6775 6.3508C22.3704 -1.8372 35.197 -0.919431 40.619 8.21078C42.3488 7.12634 44.3281 6.55745 46.3917 6.55745C52.7031 6.55745 57.7043 11.9293 57.2667 18.2053C63.7551 18.5692 68.9213 23.9623 68.9213 30.54C68.9213 37.353 63.3787 42.895 56.5663 42.895L56.5667 42.895Z"
      fill="white"
    />
    <path
      d="M16.3478 41.7579C16.3478 51.8588 24.5653 60.0761 34.666 60.0761C44.7669 60.0761 52.9843 51.8586 52.9843 41.7579C52.9843 31.657 44.7669 23.4396 34.666 23.4396C24.5651 23.4396 16.3478 31.6571 16.3478 41.7579ZM18.0996 41.7579C18.0996 32.6234 25.5314 25.1915 34.666 25.1915C43.8005 25.1915 51.2325 32.6233 51.2325 41.7579C51.2325 50.8923 43.8005 58.3243 34.666 58.3243C25.5316 58.3243 18.0996 50.8925 18.0996 41.7579Z"
      fill="white"
      stroke="#F9FFF9"
      stroke-width="0.297963"
    />
    <path
      d="M34.3172 49.0724C34.3172 49.4484 34.6222 49.7534 34.9983 49.7534C35.3743 49.7534 35.6793 49.4489 35.6793 49.0724V35.2384C35.6793 34.8623 35.3743 34.5573 34.9983 34.5573C34.6222 34.5573 34.3172 34.8623 34.3172 35.2384V49.0724Z"
      fill="white"
      stroke="#483EA8"
      stroke-width="0.297963"
    />
    <path
      d="M34.9981 36.2026L31.2233 39.9775C31.2232 39.9775 31.2232 39.9775 31.2232 39.9776C30.9574 40.2437 30.526 40.2436 30.26 39.9776C29.9939 39.7115 29.9939 39.2805 30.26 39.0144L34.5164 34.7579C34.7822 34.4917 35.2137 34.4918 35.4797 34.7578L39.7363 39.0144C40.0023 39.2805 40.0023 39.7115 39.7363 39.9776C39.6032 40.111 39.4283 40.177 39.2547 40.177L34.9981 36.2026ZM34.9981 36.2026L38.7731 39.9776C38.9059 40.1104 39.0807 40.177 39.2547 40.177L34.9981 36.2026Z"
      fill="white"
      stroke="#483EA8"
      stroke-width="0.297963"
    />
  </svg>
);

const downloadIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
  >
    <rect
      opacity="0.7"
      x="0.172913"
      y="0.731934"
      width="24.611"
      height="24.611"
      rx="12.3055"
      fill="#090513"
    />
    <path
      d="M16.8732 11.4863H14.3619V7.76367H10.5949V11.4863H8.08359L12.4784 15.8294L16.8732 11.4863ZM8.08359 17.0703V18.3112H16.8732V17.0703H8.08359Z"
      fill="white"
    />
  </svg>
);
function PmsReportTable({
  key,
  searchParam,
  setTableColumns,
  tableColumns,
  setOpenModal,
  openModal,
  tableName,
  loading,
  pageName,
  tableHeaderBGColor,
  headerList,
  tableHeader,
  setTableHeader,
  stickyProductName,
  checkedTransList,
  tableBodyBackground,
  headerInTwoLine,
  tableMaxHeight,
  tableMinHeight,
  margin,
  corporateActionsLabel,
  productType,
  pageCount,
  itemLimit,
  setItemOffset,
  firstCollFullWidth,
  hidePagination,
  customTableClass,
  rowSeperation,
  productName,
  pageNumber,
  setPageNumber,
  setSecondTableColumns,
  tableColumns2,
  retryError,
  secondApiLoading,
  setFirstApiCall,
  loaderAssetClass,
}) {
  const theme = useTheme();
  const goldMode = theme.palette.mode === "gold";
  const [tickedColumns, setTickedColumns] = useState(headerList);
  const [tableReOrderConfig, setTableReOrderConfig] = useState([]);
  const [resetTable, setResetTable] = useState();
  const [checkedTrans, setCheckedTrans] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    field: "",
    order: false,
  });
  const navigate = useNavigate();
  const hiddenFileInput = useRef(null);
  const [progressbar, setProgressBar] = useState(null);
  const [uploadErrors, setUploadErrors] = useState("");
  console.log("productType", productType);
  const [errors, setErrors] = useState(null);
  const { userState, dispatch } = OnboardingContext();

  const clientId = userState.loginedUserData.id;
  const [display, setDisplay] = useState(false);
  const [currentColumn, setCurrentColumn] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [formData, setFormdata] = useState({
    clientId: clientId?.id,
    entity: "",
    fileType: "",
    file: "",
  });

  const handleSubmit = () => {
    const errorData = uploadPDFFileValidate(formData);
    setErrors(errorData);
    if (_.isEmpty(errorData)) {
      submitForm(formData);
    }
  };
  const handleFileChange = (e) => {
    setUploadErrors("");
    setFormdata({ ...formData, [e.target.name]: e.target.files[0] });

    const errorData = uploadPDFFileValidate(
      { ...formData, [e.target.name]: e.target.files[0] },
      e.target.name
    );
    setErrors(errorData);
  };
  const handleDrop = (e) => {
    e.preventDefault();
    setUploadErrors("");
    setFormdata({ ...formData, ["file"]: e.dataTransfer.files[0] });
    const errorData = uploadPDFFileValidate(
      { ...formData, ["file"]: e.dataTransfer.files[0] },
      "file"
    );
    setErrors(errorData);
  };
  const submitForm = async (values) => {
    setShowLoader(true);

    try {
      const formData = new FormData();
      formData.append("file", values.file);
      formData.append("advisor", currentColumn.advisor);
      formData.append("isin", currentColumn.isin);
      formData.append("memberId", currentColumn.memberId);
      formData.append(
        "clientId",
        localStorage.getItem("userType") == "mfo-user"
          ? localStorage.getItem("memberId")
          : userState.loginedUserData.id
      );
      const onUploadProgress = (progressEvent) => {
        // Calculate progress percentage
        const progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        console.log("progressEvent", progressEvent, progress);
        setProgressBar(progress);
      };

      let data;

      data = await uploadPMSFile(
        formData,

        onUploadProgress
      );

      const uploadData = data.data;

      if (uploadData.success) {
        setShowLoader(false);

        hiddenFileInput.current.value = null;
        setFormdata((prev) => ({
          ...formData,
          clientId: userState.loginedUserData.id,

          entity: "",
          fileType: "",
          file: undefined,
        }));
        setDisplay(false);
        dispatch({
          type: "UPDATE_SNACKBAR_DATA",
          payload: {
            success: true,
            error: false,
            showMsg: true,
            message: uploadData.message,
          },
        });
      }
    } catch (error) {
      setShowLoader(false);

      if (error.response.request.status === 401) {
        navigate("/logout");
      } else if (!error.response.data.error.success) {
        dispatch({
          type: "UPDATE_SNACKBAR_DATA",
          payload: {
            success: false,
            error: true,
            showMsg: true,
            message: error.response.data.error.message,
          },
        });
      }
      if (error?.response?.data?.error?.code == "SCHEMA_ERROR") {
        hiddenFileInput.current.value = null;
        setUploadErrors(error?.response?.data?.error?.fields[0]?.description);
        setFormdata((prev) => ({
          ...prev,
          file: undefined,
        }));
      }
    }
  };

  useEffect(() => {
    // NOTE :- (BUG MFOP-2376) Check if the product type is mfoHolding or pmsHolding
    // If it is, do nothing and return early
    const validProductTypes = [
      "mfoHolding",
      "pmsHoldingEquity",
      "pmsHoldingDebt",
      "pmsHoldingAlternate",
      "pmsHoldingCash",
    ];

    if (validProductTypes.includes(productType)) {
      // If it is a valid product type, do nothing and return early
      return;
    } else {
      // Set the ticked columns based on the provided header list
      setTickedColumns(headerList);
    }
    // handleReset(false);
    // setTableHeader(tableHeader);
  }, [headerList]);

  useEffect(() => {
    if (checkedTransList?.length > 0) {
      setCheckedTrans([...checkedTransList]);
    }
  }, [checkedTransList]);

  const trFilter = () => {
    let checkList = checkedTrans
      .filter((val) => val.show == true)
      .map((val) => val?.title?.toLowerCase());

    setTableColumns((prev) => {
      prev.map((row) => {
        let add = false;
        row.map((column) => {
          if (checkList.length == 0) {
            add = true;
          }
          if (checkList?.includes(column?.field?.toLowerCase())) {
            add = true;
          }
        });
        if (add == true) {
          row.map((column) => {
            tickedColumns?.map((col) => {
              if (col?.title == column?.title) {
                column.show = col?.show;
              }
            });
            return column;
          });
        } else {
          row.map((column) => {
            column.show = false;
            return column;
          });
        }
      });
      return [...prev];
    });
    handleCloseEvent();
  };

  const sort = (title, order, type) => {
    let sortFunction;
    if (type == "alphaNumeric") {
      const getFirstAndSecond = (columnVal) => {
        return columnVal.filter((val) => val.title == title)[0]?.field;
      };
      if (order == true) {
        sortFunction = (first, second) =>
          String(getFirstAndSecond(first)).localeCompare(
            String(getFirstAndSecond(second))
          );
      } else {
        sortFunction = (first, second) =>
          String(getFirstAndSecond(second)).localeCompare(
            String(getFirstAndSecond(first))
          );
      }
    } else if (type == "numeric") {
      const getFirstAndSecond = (columnVal) => {
        let first;

        first = columnVal.filter((val) => val.title == title)[0]?.field;

        if (first?.includes(",")) {
          first = first.replace(",", "");
        }

        if (first?.includes("₹")) {
          first = first.replace("₹", "");
        }

        return Number(first);
      };

      if (order == true) {
        sortFunction = (first, second) =>
          getFirstAndSecond(first) - getFirstAndSecond(second);
      } else {
        sortFunction = (first, second) =>
          getFirstAndSecond(second) - getFirstAndSecond(first);
      }
    }

    setTableColumns((prev) => {
      let sortedList = prev?.sort(sortFunction);
      return [...sortedList];
    });
    setSecondTableColumns((prev) => {
      let sortedList = prev?.sort(sortFunction);
      return [...sortedList];
    });
  };

  const handleCheckBoxClickTrans = (e) => {
    const list = [...checkedTrans];
    list.map((col, index) => {
      if (col.title === e.target.name) {
        const data = { ...col };
        data["show"] = !col.show;
        list[index] = { ...col, show: !col.show };
      }
    });

    setCheckedTrans(list);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClickEvent = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseEvent = () => {
    setAnchorEl(null);
  };
  const handleReset = () => {
    setTickedColumns(headerList);
    setTableHeader(headerList);
    setTableReOrderConfig([]);
    setTableColumns((prev) => {
      let newList = [];
      prev?.map((row) => {
        let res = getResetedRow(row, headerList);
        newList.push(res);
      });
      return newList;
    });
    setSecondTableColumns((prev) => {
      let newList = [];
      prev?.map((row) => {
        let res = getResetedRow(row, headerList);
        newList.push(res);
      });
      return newList;
    });
    setOpenModal(!openModal);
  };

  const getResetedRow = (row, configArray) => {
    let newRow = [];
    configArray.map((column) => {
      row.map((oldCol) => {
        if (column.title == oldCol.title) {
          oldCol.show = column.show;
          newRow.push(oldCol);
        }
      });
    });

    return newRow;
  };

  const handleButtonClickApply = () => {
    setTableHeader(() => tickedColumns);
    let filterList = tickedColumns
      .filter((val) => val.show == false)
      .map((val) => val.title);

    setTableColumns((prev) => {
      let newRows = [];
      prev.map(async (row) => {
        let newList = reOrderBody(row);
        newList.map((column) => {
          // console.log(filterList.includes(column.title), 'asfefw');
          if (filterList.includes(column.title)) {
            column.show = false;
          } else {
            column.show = true;
          }
          return column;
        });
        newRows.push(newList);
      });
      return [...newRows];
    });
    setSecondTableColumns((prev) => {
      let newRows = [];
      prev.map(async (row) => {
        let newList = reOrderBody(row);
        newList.map((column) => {
          // console.log(filterList.includes(column.title), 'asfefw');
          if (filterList.includes(column.title)) {
            column.show = false;
          } else {
            column.show = true;
          }
          return column;
        });
        newRows.push(newList);
      });
      return [...newRows];
    });
    setOpenModal(!openModal);
    setTableReOrderConfig([]);
  };
  const handleCheckBoxClick = (e) => {
    const list = [...tickedColumns];
    list.map((col, index) => {
      if (col.field === e.target.name) {
        const data = { ...col };
        data["show"] = !col.show;

        list[index] = { ...col, show: !col.show };
      }
    });
    setTickedColumns(() => list);
    // setTickedColumns((prev) => {
    //     prev.map((rows) => {
    //         rows.map((col) => {
    //             if (col !== undefined && col?.title === e.target.name) {
    //                 col.show = !col.show
    //             } else {
    //                 return col
    //             }
    //         })
    //     })
    //     return [...prev]
    // })
  };

  const tableHeaders = (headers) => {
    return headers?.map((column, index) =>
      column && column.show ? (
        <TableCell
          style={{
            borderLeft: key
              ? "excess-performance"
              : dividerPMSReportTableHeader.includes(column.title)
              ? "1px #AA97C5 solid"
              : "none",
            borderBottom: "none",
            textTransform: "initial",
            backgroundColor: tableHeaderBGColor || "var( --stickyHeader)",
            top: "0px",
            width: firstCollFullWidth
              ? "auto"
              : index === 0
              ? "320px"
              : "fit-content",
            boxSizing: "border-box",
          }}
          key={column.title}
          align={column?.align || "right"}
          className={`${classes.tableHeaderTextNew} newThStyle ${
            stickyProductName ? "stickyCellHed" : ""
          } textCapitalize`}
        >
          <div
            style={{
              display: "flex",
              gap: "4px",
              flexDirection: column.sortType ? "row" : "column",
              justifyContent:
                index == 0 ? "left" : column?.align ? column?.align : "center",
            }}
            className={column?.cellCustomClass}
          >
            {column.title === "Transaction Type" && checkedTrans?.length > 0 ? (
              <div style={{ display: "flex", marginTop: "5px" }}>
                Transaction Type
                <span
                  onClick={handleClickEvent}
                  style={{ cursor: "pointer", marginLeft: "20px" }}
                >
                  <Transaction />
                </span>
              </div>
            ) : column.title.includes("*") ? (
              column.title.split("*")[0]
            ) : !column.title.includes(" ") || column?.secondLine == false ? (
              <p
                style={{
                  minWidth: "70px",
                  margin: "0px",
                }}
              >
                {column.title}
              </p>
            ) : (
              <p
                style={{
                  minWidth: "70px",
                  margin: "0px",
                }}
              >
                {headerInTwoLine && !column?.nonTwoLine ? (
                  <>
                    {column.title.slice(0, column.title.lastIndexOf(" "))}
                    <br />
                    {column.title.slice(
                      column.title.lastIndexOf(" ") + 1,
                      column.title.length
                    )}
                  </>
                ) : (
                  column.title
                )}
              </p>
            )}
            {column.title.includes("*") && (
              <>
                <br />
                <div
                  className="headSubTextContainer"
                  style={{ justifyContent: "right" }}
                >
                  <p className="headSubText">
                    {column.title.includes("*")
                      ? "*" + column.title.split("*")[1]
                      : ""}
                  </p>
                </div>
              </>
            )}
            {column.sortType && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "3px",
                  marginTop: "2px",
                }}
              >
                <UpArrow
                  width={"10px"}
                  height={"10px"}
                  onClick={() => {
                    setSortConfig({
                      title: column?.title,
                      order: true,
                    });
                    sort(column?.title, true, column?.sortType);
                  }}
                  className={
                    sortConfig.title == column?.title &&
                    sortConfig.order == true
                      ? "svgSelected"
                      : ""
                  }
                />
                <UpArrow
                  height={"10px"}
                  width={"10px"}
                  onClick={() => {
                    setSortConfig({
                      title: column?.title,
                      order: false,
                    });
                    sort(column?.title, false, column?.sortType);
                  }}
                  className={
                    sortConfig.title == column?.title &&
                    sortConfig.order == false
                      ? "svgSelected"
                      : ""
                  }
                  style={{
                    transform: "rotate(180deg)",
                  }}
                />
              </div>
            )}
          </div>
        </TableCell>
      ) : null
    );
  };

  const tableBodyRef = useRef(null);
  const [heightArray, setHeightArray] = useState([]);
  // console.log(Array.from(a), "sdgbj");
  useEffect(() => {
    let a = Array.from(tableBodyRef?.current?.querySelectorAll(".tableRowPms"));
    setHeightArray(() => a);
    return () => {
      setHeightArray(() => []);
    };
  }, [headerList, tableColumns]);

  function renderRow(rows, isTotalRow, rowIndex) {
    let renderRows = rows?.map((column, index) =>
      column && column.show ? (
        column?.comp ? (
          <TableCell
            key={column.title}
            align={column?.align || "right"}
            className={`${classes.tableBodyText} tdPms ${
              stickyProductName ? "stickyCell" : ""
            } textCapitalize`}
            style={{
              cursor: column?.onClick ? "pointer" : "",
              padding:
                (column?.tagPresent === 1 ||
                  column?.tagPresentPromoterStake === 1) &&
                index === 0
                  ? "0px"
                  : "16px",
              width: firstCollFullWidth
                ? "auto"
                : index === 0
                ? "320px"
                : "fit-content",
              boxSizing: "border-box",

              // paddingTop: "38px",
              // backgroundColor: tableBodyBackground || "#231E53",
            }}
          >
            <div
              style={{
                width: index === 0 ? "auto" : "auto",
                // whiteSpace: "nowrap",
                // overFlowWrap: "break-word",
              }}
              onClick={() => {
                if (column?.onClick) column?.onClick();
              }}
              className={index === 0 && "newTabelCell"}
            >
              {column?.comp}
            </div>
          </TableCell>
        ) : (
          <TableCell
            key={column.title}
            align={column?.align || "right"}
            className={`${classes.tableBodyText} tdPms ${
              stickyProductName ? "stickyCell" : ""
            } textCapitalize`}
            style={{
              cursor: column?.onClick ? "pointer" : "",
              padding:
                (column?.tagPresent === 1 ||
                  column?.tagPresentPromoterStake === 1) &&
                index === 0
                  ? "0px"
                  : "16px",
              width: firstCollFullWidth
                ? "auto"
                : index === 0
                ? "320px"
                : column.sameAsFirst
                ? "320px"
                : "fit-content",
              boxSizing: "border-box",
              // HANDLE TOTAL ROW STYLE
              borderLeft:
                index === 0 && isTotalRow
                  ? `5px solid ${goldMode ? "#EDC150" : "#A26CF2"}`
                  : undefined,
              background:
                index === 0 &&
                isTotalRow &&
                `${goldMode ? "rgb(139 119 66)" : "#6027B4"}`,
              height:
                index === 0 ? heightArray[rowIndex]?.clientHeight : "100%",
              // paddingTop: "38px",
              // backgroundColor: tableBodyBackground || "#231E53",
            }}
          >
            <div
              style={
                {
                  // width: "auto",
                  // whiteSpace: "nowrap",
                  // overFlowWrap: "break-word",
                }
              }
              onClick={() => {
                if (column?.onClick) column?.onClick();
              }}
              className={`${index === 0 && "newTabelCell"} ${
                column?.customWrapperClass
              }`}
            >
              <span
                className={`${
                  (column?.tagPresent === 1 ||
                    column?.tagPresentPromoterStake === 1) &&
                  index === 0
                    ? "colValue"
                    : ""
                } 
              ${column?.field?.length > 30 ? "truncate" : ""} ${
                  column?.customClass
                }`}
                title={column?.field?.length > 30 ? column?.field : ""}
              >
                {column?.field === "twrr" && retryError && <p>retry</p>}
                {column.title == "Advisor" || column.title == "Advised by" ? (
                  column.field.split("%")[0]
                ) : (
                  <>
                    {column?.field == "loading" ? (
                      <div
                        className="tableLoader"
                        // style={{ position: "relative" }}
                      >
                        <Loader />
                      </div>
                    ) : column?.field == "Error" ? (
                      <div
                        className="tableLoader"
                        // style={{ position: "relative" }}
                      >
                        {loaderAssetClass ? (
                          <Loader />
                        ) : (
                          <button onClick={column?.hanleErrorRetry}>
                            <ReplayIcon color="white" className="reoloadIcon" />
                            <p className="tableLoaderbtnTxt">Reload</p>
                          </button>
                        )}
                      </div>
                    ) : isTotalRow ? (
                      column?.field
                    ) : column.showUploadDownload ? (
                      <span style={{ display: "flex", alignItems: "center" }}>
                        {firstCharCapitalize(
                          column?.field?.length >
                            (column.showUploadDownload ? 22 : 30)
                            ? column?.field?.substring(
                                0,
                                column.showUploadDownload ? 22 : 30
                              ) + "..."
                            : column?.field
                        )}
                        {column.showUploadDownload && (
                          <span
                            style={{
                              display: "inline-flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "5px",
                              paddingLeft: "10px",
                            }}
                          >
                            <UploadIcon
                              onClick={() => {
                                setCurrentColumn(column);
                                console.log(column, "column");
                                setDisplay(true);
                              }}
                            />
                            {column.file && (
                              <DownloadIcon
                                onClick={() => {
                                  saveAs(column.file);
                                }}
                              />
                            )}
                          </span>
                        )}
                      </span>
                    ) : (
                      <span>
                        {firstCharCapitalize(
                          column?.field?.length > 30
                            ? column?.field?.substring(0, 29) + "..."
                            : column?.field
                        )}
                      </span>
                    )}
                    {/* </HighLightText> */}
                    {column?.subText && (
                      <Typography className={`subText12`}>
                        {column?.subText}
                      </Typography>
                    )}
                  </>
                )}
              </span>
              {column?.tagPresent === 1 && index == 0 && (
                <span className="newcorporateActionsLabel">
                  <p className="newcorporateActionsLabelFont">
                    Corporate Action
                  </p>
                </span>
              )}
              {column?.tagPresentPromoterStake === 1 &&
                index == 0 &&
                !isTotalRow && (
                  <span className="newcorporateActionsLabel">
                    <p className="newcorporateActionsLabelFont">
                      Promoter Stake
                    </p>
                  </span>
                )}
            </div>
          </TableCell>
        )
      ) : (
        ""
      )
    );
    let displayRow = renderRows.filter((d) => (d != "" ? d : false));
    if (displayRow.length > 0) {
      return (
        <TableRow
          className="tableRowPms bondsTableRowPms"
          // style={{ background: "#1D1B36" }}
          style={{ position: "relative" }}
        >
          {displayRow}
        </TableRow>
      );
    } else return <></>;
  }

  const tableBody = (body, isTotalRow) => {
    return body?.map((rows, rowIndex) => (
      <>
        {/* <TableRow style={{ height: rowSeperation }} /> */}

        {renderRow(rows, isTotalRow, rowIndex)}
      </>
    ));
  };

  const handleButtonClick = () => {
    setOpenModal(!openModal);
    setTableColumns(() => [...tickedColumns]);
  };

  // drag fuctionality

  const onDragEndAction = (result) => {
    // console.log(result, "result131341");
    let newList = reorder(
      tickedColumns,
      result.source.index,
      result.destination.index
    );
    setTickedColumns(newList);
    setTableReOrderConfig((prev) => [
      ...prev,
      {
        sourceIndex: result.source.index,
        destinationIndex: result.destination.index,
      },
    ]);
  };

  const reOrderBody = (row) => {
    let newRow = row;

    /* maping through this tableReOrderConfig for to reflect 
    changes on multiple drag */
    tableReOrderConfig.map((val) => {
      newRow = reorder(newRow, val?.sourceIndex, val?.destinationIndex);
    });
    return newRow;
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const getItemStyle = (isDragging = false, draggableStyle) => {
    return {
      ...draggableStyle,
    };
  };

  const classes = useStyles();

  return (
    <>
      {tableName && (
        <Typography
          style={{ cursor: "pointer", margin: "2%", fontSize: "20px" }}
          className={`${classes.titleText} capitalize`}
        >
          {tableName || ""}
        </Typography>
      )}
      <div
        style={{
          minHeight: tableMinHeight ? tableMinHeight : "300px",
          width: "-webkit-fill-available",
          margin: margin ? margin : "2%",
          maxHeight: tableMaxHeight ? tableMaxHeight : "400px",
          display: "flex",
          position: "relative",
          // flexDirection: "column",
        }}
        className={`${customTableClass}`}
      >
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead className="tableRowStyle tableSticky">
              <TableRow>{tableHeaders(tableHeader)}</TableRow>
            </TableHead>
            <TableBody ref={tableBodyRef} className="tableBodyPms tableSticky">
              {/* <TableRow style={{ height: "10px" }} /> */}
              {tableBody(tableColumns)}
              {/* Dont show total row for transaction summary table */}
              {tableName === "transaction-summary" ? (
                <></>
              ) : (
                tableBody(tableColumns2, true)
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {console.log("tableColumns1", tableColumns)}
        {tableColumns?.length == 0 && (
          <div
            style={{
              position: "absolute",
              left: "45%",
              top: "14%",
            }}
          >
            {!loading && <DataNotFound />}
          </div>
        )}
      </div>
      {hidePagination ||
        (tableColumns?.length > 0 && !firstCollFullWidth && (
          <div className="paginationstyl">
            <AppPagination
              pageCount={
                Math.ceil(pageCount / ITEMS_LIMIT_FILES_PARTNER_LIST) || 0
              }
              itemLimit={ITEMS_LIMIT_FILES_PARTNER_LIST}
              setItemOffset={setItemOffset ? setItemOffset : () => {}}
              otherProps={{ shape: "rounded" }}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
          </div>
        ))}

      {setOpenModal && (
        <TableEditModal
          // key={headerList}
          openModal={openModal}
          setTickedColumns={setTickedColumns}
          setOpenModal={setOpenModal}
          onDragEndAction={onDragEndAction}
          getItemStyle={getItemStyle}
          handleReset={handleReset}
          handleButtonClickApply={handleButtonClickApply}
          tickedColumns={tickedColumns}
          handleCheckBoxClick={handleCheckBoxClick}
          headerList={headerList}
          tableHeader={tableHeader}
          productName={productName}
        />
      )}

      <Menu
        className="modal-wrapper"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseEvent}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        // transformOrigin={{
        //     vertical: 'top',
        //     horizontal: 'center',
        // }}
        sx={{
          backgroundColor: "#711CF1 !important",
          borderRadius: "20px",
          border: "1px solid #4E189F",
          // position: "absolute",
          outline: 0,
          // top: "15%",
          // right: "40%",
          width: "220px",
          padding: "15px",
          height: "200px",
          overflow: "scroll",
          boxShadow: "-4px 4px 10px rgba(0, 0, 0, 0.5)",
        }}
        style={{ minWidth: "400px" }}
      >
        <div className="member" style={{ minWidth: "157px" }}>
          <div className="member-content">
            {checkedTrans?.length > 0 &&
              checkedTrans?.map((column) =>
                column && column.title !== "" ? (
                  <div key={column.title} className="filter-header">
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <CheckBoxWithColor
                        checked={column.show}
                        value={column.title}
                        name={column.title}
                        onChange={(e) => handleCheckBoxClickTrans(e)}
                      />
                      <Typography className="filter-value-name avtar-text textCapitalize">
                        {column.title}
                      </Typography>
                    </span>
                  </div>
                ) : null
              )}
          </div>
        </div>
        <hr className="filter-divider"></hr>
        <div className={classes.buttonContainerTransaction}>
          <Button
            // variant="contained"
            className={classes.buttonNo}
            style={{ width: "60%", marginTop: "5%" }}
            onClick={handleCloseEvent}
          >
            <span className={`${classes.buttonText}`}>Cancel</span>
          </Button>
          <Button
            // variant="contained"
            className={classes.buttonApplyModal}
            style={{ width: "60%", marginTop: "5%" }}
            onClick={trFilter}
          >
            <span className={`${classes.buttonText}`}>Apply</span>
          </Button>
        </div>
      </Menu>
      <div
        className="tableLoader"
        // style={{ position: "relative" }}
      >
        {/* {secondApiLoading ? (
                      <Loader />
                    ) : (
                      <button onClick={()=>setFirstApiCall(true)} >
                        <ReplayIcon color="white" className="reoloadIcon" />
                        <p className="tableLoaderbtnTxt">Reload</p>
                      </button>
                    )} */}
      </div>
      <Modal
        disableBackdropClick={true}
        onClose={() => {}}
        open={display}
        className="pmsUpload"
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "transparent",
          marginTop: "20px",
        }}
      >
        <div
          style={{
            background: "rgba(33, 24, 57, 1)",
            padding: "80px",
            outline: 0,
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              color: "#ffffff",
              flexDirection: "row-reverse",
              position: "absolute",
              right: 10,
              top: 10,
            }}
          >
            <Close
              onClick={() => {
                setDisplay(false);
                setFormdata((prev) => ({
                  ...formData,
                  clientId: userState.loginedUserData.id,

                  entity: "",
                  fileType: "",
                  file: undefined,
                }));
                setErrors({});
              }}
            />
          </div>
          {/* Upload Start */}
          <div className="productUploadContainer dragDrop">
            <div
              className="browseContainer"
              onDragOver={(e) => {
                e.preventDefault();
              }}
              onDrop={(e) => handleDrop(e)}
            >
              <div className="browseHeadingSubHeadingWrapper">
                <div className="browseIcon">{browseIcon}</div>
                <h1 className="browseHeading">
                  Drag & drop files or
                  <input
                    type="file"
                    name="file"
                    hidden
                    onChange={(e) => handleFileChange(e)}
                    ref={hiddenFileInput}
                  />
                  <span
                    className="browseTxt"
                    onClick={() => hiddenFileInput.current.click()}
                  >
                    Browse
                  </span>
                </h1>
                <p className="browseSubHeading">Supported Formats :- pdf</p>
                {/* {!transactionStatus && (
                    <>
                      <div className="verificationInProgress">
                        <CircularProgress /> <p>Undergoing Verification</p>
                      </div>
                      <div className="notificationBell">
                        <NotificationsActiveIcon />
                      </div>
                    </>
                  )} */}
              </div>
            </div>
          </div>

          {/* Progress Bar Start */}
          {formData?.file && (
            <div
              style={{
                display: "grid",
                placeItems: "center",
              }}
            >
              <div className="progressBar">
                <p className="uploadedFileName">{formData?.file?.name}</p>
                <div
                  className="cursor"
                  onClick={() => {
                    setFormdata((prev) => ({
                      ...prev,
                      file: "",
                    }));
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                  >
                    <path
                      d="M8.78571 16.6334C6.67845 16.6334 4.65724 15.7962 3.16727 14.3062C1.6772 12.816 0.840027 10.795 0.840027 8.68774C0.840027 6.58048 1.6772 4.55928 3.16727 3.06931C4.65746 1.57924 6.67845 0.742062 8.78571 0.742062C10.893 0.742062 12.9142 1.57924 14.4041 3.06931C15.8942 4.5595 16.7314 6.58048 16.7314 8.68774C16.7288 10.7943 15.891 12.814 14.4014 14.3034C12.9118 15.793 10.892 16.6309 8.78571 16.6334ZM11.8499 6.64413V6.64401C11.9892 6.50942 12.0686 6.32453 12.0703 6.1308C12.072 5.93707 11.9958 5.75074 11.8588 5.61372C11.7218 5.4767 11.5355 5.40053 11.3417 5.40222C11.148 5.40391 10.9631 5.48336 10.8285 5.62267L8.78571 7.66622L6.7429 5.62267C6.55945 5.44551 6.29619 5.37816 6.05011 5.44563C5.80414 5.51311 5.612 5.70526 5.54451 5.95123C5.47704 6.19731 5.54439 6.46056 5.72155 6.64402L7.76436 8.68757L5.72155 10.7311C5.58224 10.8657 5.5028 11.0506 5.5011 11.2443C5.49941 11.4381 5.57559 11.6244 5.7126 11.7614C5.84961 11.8984 6.03595 11.9746 6.22968 11.9729C6.42341 11.9712 6.60829 11.8918 6.74289 11.7525L8.7857 9.70891L10.8285 11.7525C10.9631 11.8918 11.148 11.9712 11.3417 11.9729C11.5354 11.9746 11.7218 11.8984 11.8588 11.7614C11.9958 11.6244 12.072 11.4381 12.0703 11.2443C12.0686 11.0506 11.9892 10.8657 11.8498 10.7311L9.80704 8.68757L11.8499 6.64413Z"
                      fill="#E6E6E6"
                    />
                  </svg>
                </div>
              </div>
              <div style={{ width: "550px" }}>
                <LinearProgress
                  variant="determinate"
                  value={progressbar}
                  className={classes.customLinearProgress}
                />
              </div>
              <ErrorMessageComp
                errorMessage={errors.file}
                style={{ marginTop: "10px" }}
              />
            </div>
          )}
          {/* Progress Bar End */}
          <footer
            style={{
              display: "grid",
              placeItems: "center",
            }}
          >
            <button className="productUploadBtn " onClick={handleSubmit}>
              Upload
            </button>
            {/* <button className="productUploadBtn" onClick={handleSubmit}>
                Save
              </button> */}
          </footer>
        </div>
      </Modal>
      <PageLoader showLoader={loading} />
      <CustomizedSnackbars />
    </>
  );
}

export default PmsReportTable;
