import React from "react";
import { useForm } from "../hooks/useForm";
import PartnerHeading from "../PartnerHeading";
import "../../NewSignUp/PartnerOnboarding.css";
import "../../NewSignUp/NewSignUp.css";
import { Box, Grid, MenuItem } from "@material-ui/core";
import CustomInput from "../common/CustomInput";
import StepProgressBar from "../common/StepProgressBar/StepProgressBar";
import LoadingButton from "../common/LoadingButton";
import ErrorMessageComp from "../common/ErrorMessageComp";
import { ReactComponent as Approved } from "../../../assets/icons/approve.svg";
import { ReactComponent as PendingSvg } from "../../../assets/icons/pending.svg";
import {
  addUserSchema,
  client_add_member_validation_schema,
  updatePartnerUser,
} from "../ValidationSchema";
import {
  Client_User_Roles,
  ENTITY_TYPES,
  adduserRoles,
  adduserStatus,
  new_Client_User_Roles,
} from "../../../Constants";

function BackToLogin({
  handleNext,
  handleBack,
  currentStep,
  customHandleSubmit,
  head,
  onlyPerosn,
  apiErrorMessage,
  loading,
  formData,
  totalStep,
  noStepBottom,
  formMaxHeight,
  noStep,
  formWidth,
}) {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useForm(
    {},
    // test,
    (values) => {
      console.log(values, "aflkbabfbuawubfw");
      customHandleSubmit(values);
    }
  );

  return (
    <div
      className="dFlex  alignCntr h100 "
      style={{ gap: "25px", width: formWidth }}
    >
      <div className="newFormContainer" style={{ maxHeight: formMaxHeight }}>
        <Box
          className="dFlex jtfyContCntr alignCntr flexDirCol nopaddingbuttop"
          sx={{ width: { xs: "auto", sm: "330px" }, gap: "47px" }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container>
              <div className="newFormBottonSec">
                <Grid xs={12} lg={12} style={{ marginTop: "30px" }} item>
                  <ErrorMessageComp errorMessage={apiErrorMessage} />
                  <LoadingButton
                    buttonTitle={"Go to login up"}
                    type="submit"
                    showLoader={loading}
                  />
                </Grid>
              </div>

              <div className="infoCard">
                <div className="i">i</div>
                <p>
                  You will be able to login only once your details have been
                  verified by us, you will receive an email for the same.
                </p>
              </div>
            </Grid>
          </form>
        </Box>
      </div>
    </div>
  );
}

export default BackToLogin;
