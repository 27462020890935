let align = "center";
export const tableConfig = [
  { field: "scheme_name", title: "Scheme Name", show: true, align: align },
  { field: "advisor", title: "Advised by", show: true, align: align },
  { field: "executed_by", title: "Executed By", show: true, align: align },
  { field: "isin", title: "ISIN No", show: true, align: align },
  { field: "folio_no", title: "Folio No", show: true, align: align },
  { field: "asset_type", title: "Asset Type", show: true, align: align },
  {
    field: "product_sub_category",
    title: "Product Sub Category",
    show: true,
    align: align,
  },
  {
    field: "transaction_type",
    title: "Transaction Type",
    show: true,
    align: align,
  },
  {
    field: "transaction_date",
    title: "Transaction Date",
    show: true,
    align: align,
  },
  { field: "value", title: "Total Transaction Value (Actuals)", show: true },
  { field: "units", title: "Units", show: true },
  { field: "nav", title: "Nav (Actuals)", show: true },

  { field: "stamp_duty", title: "Stamp Duty (Actuals)", show: true },
  { field: "other_charges", title: "Other Charges (Actuals)", show: true },

  // { field: "value_date", title: "Value Date", show: true, align: align },
  {
    field: "transaction_mode",
    title: "Transaction Mode",
    show: true,
    align: align,
  },
  {
    field: "holdingPeriod",
    title: "Holding Period",
    show: true,
    align: align,
  },
];
export const CorporateTableConfig = [
  ...tableConfig,
  {
    field: "corp_action_effects",
    title: "Effects",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },

  {
    field: "corporate_action_value",
    title: "Value (Actuals)",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "ratio",
    title: "Ratio",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "remarks",
    title: "Remarks",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
];
