import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { dateFormatCommon, roundNumber } from "../../../utils/common";
import { currencyFormat } from "../../../../hooks/constantFunction";
// import series from "./chartData";
// import { data } from "../../../src/components/dashboard/common/data";
const getDenomination = localStorage.getItem("denomination");

export default function ApexNew_print({
  linedata,
  title,
  controller_disabled,
  legends_hide,
  showOps,
  config,
  denomination,
}) {
  // console.log("linedata", linedata);
  // const linedata = series;

  // let linedata = [
  //   {
  //     name: "Total 45118079.05",
  //     type: "column",
  //     data: [
  //       {
  //         x: "Edelweiss Crossover Opportunities Fund- Series I",
  //         y: "39918693.66",
  //         z: "3.00",
  //       },
  //       // {
  //       //   x: "Edelweiss Crossover Opportunities Fund- Series 2",
  //       //   y: "39918693.66",
  //       //   z: "3.00",
  //       // },
  //       // {
  //       //   x: "Edelweiss Crossover Opportunities Fund- Series 3",
  //       //   y: "39918693.66",
  //       //   z: "3.00",
  //       // },
  //       // {
  //       //   x: "Edelweiss Crossover Opportunities Fund- Series 4",
  //       //   y: "39918693.66",
  //       //   z: "3.00",
  //       // },
  //       // {
  //       //   x: "Edelweiss Crossover Opportunities Fund- Series 5",
  //       //   y: "39918693.66",
  //       //   z: "3.00",
  //       // },
  //       // {
  //       //   x: "Edelweiss Crossover Opportunities Fund- Series 6",
  //       //   y: "39918693.66",
  //       //   z: "3.00",
  //       // },
  //     ],
  //   },
  //   {
  //     name: "Weight 3.39 %",
  //     type: "line",
  //     data: [
  //       {
  //         x: 0,
  //         y: "3.00",
  //       },
  //       {
  //         x: 1,
  //         y: "0.27",
  //       },
  //       {
  //         x: 2,
  //         y: "0.11",
  //       },
  //       {
  //         x: 3,
  //         y: "0.01",
  //       },
  //     ],
  //   },
  // ];
  const [colwidth, setColwidth] = useState(
    20 + 60 / (1 + 30 * Math.exp(-2 / 3))
  );
  useEffect(() => {
    if (linedata[0]?.data?.length) {
      setColwidth(
        linedata[0]?.data?.length < 3
          ? 20 + 60 / (1 + 30 * Math.exp(-2 / 3))
          : 30 + 60 / (1 + 30 * Math.exp(-linedata[0]?.data?.length / 3))
      );
    }
  }, [linedata]);
  var optimalColumnWidthPercent =
    linedata[0]?.data?.length < 3
      ? 20 + 60 / (1 + 30 * Math.exp(-2 / 3))
      : 30 + 60 / (1 + 30 * Math.exp(-2 / 3));
  // debugger;
  let op = {
    grid: {
      borderColor: "#f2f2f2",
    },
    tooltip: config?.tooltip
      ? config?.tooltip
      : {
          enabled: false,
          shared: true,
          custom: function ({
            series,
            seriesIndex,
            dataPointIndex,
            w,
            y1,
            y2,
          }) {
            let str =
              ' <div class="tooltip_container">  <div class="subtext tooltip_text">' +
              dateFormatCommon(
                w.globals.initialSeries[seriesIndex]?.data[dataPointIndex][0],
                "YYYY-MM-DD"
              ) +
              "</div>";
            for (let i = 0; i < series.length; i++) {
              str +=
                ` <div class="subtext tooltip_text"> ` +
                ` <div class="text_ellipse">${
                  w.globals.initialSeries[i]?.name
                } </div>
          ${
            " " +
            roundNumber(w.globals.initialSeries[i]?.data[dataPointIndex][2])
          }` +
                "</div> ";
            }
            str += "</div></div>";
            return str;
          },
        },

    chart: {
      id: "chart1",
      type: "area",
      height: 230,
      width: "100%",
      toolbar: {
        show: false,
        tools: {
          reset: false,
        },
      },
      offsetY: 10,

      zoom: {
        type: "x",
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: colwidth + "%",
      },
    },
    legend: {
      // show: legends_hide ? false : true,
      height: legends_hide ? 10 : 105,
      offsetY: 15,
      itemMargin: {
        horizontal: 20,
      },
      labels: {
        colors: "#000000",
        background:
          "linear-gradient(0deg, #EAE7ED, #EAE7ED),linear-gradient(0deg, rgba(250, 249, 255, 0.7), rgba(250, 249, 255, 0.7))",
      },
    },
    stroke: {
      width: 3,
      dashArray: config?.dashed ? config.dashed : [0],
    },
    dataLabels: {
      enabled: config?.dataLabels?.enable ? true : false,
      position: "middle",
      enabledOnSeries: [0],
      formatter: function (val, x, y, z) {
        console.log(val, x, y, z, "val");
        let xval = "" + x?.globals?.seriesZ[0][x.seriesIndex].toString();
        xval =
          "" + xval
            ? currencyFormat(val, undefined, denomination) +
              " | " +
              (xval ? roundNumber(xval) + "%" : "")
            : currencyFormat(val, undefined, denomination);
        console.log("xval");
        return x.seriesIndex == 0
          ? xval
          : " " +
              val +
              "% ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎";
      },
      offsetY: -10,
      style: {
        fontSize: "12px",
        width: "40px",

        colors: ["#dddff0", "red"],
      },

      background: {
        enabled: true,
        foreColor: "black",
        padding: 8,
      },
    },

    colors: config?.colors || [
      "#F52D6A",
      "#82EBA1",
      "#FF9446",
      "#711CF1",
      "#f6c7b6",
    ],
    markers: !config.marker
      ? {
          size: 0,
        }
      : {
          size: 3,
          colors: undefined,
          strokeColors: "#000",
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [],
          shape: "circle",
          radius: 3,
          offsetX: 0,
          offsetY: 0,
          onClick: undefined,
          onDblClick: undefined,
          showNullDataPoints: true,
          hover: {
            size: undefined,
            sizeOffset: 3,
          },
          ...config.marker,
        },
    xaxis: {
      type: config.xtype ? config.xtype : "datetime",
      // type: "category",
      // categories: [
      //   "Apples",
      //   "Strawberries",
      //   "Pineapples",
      //   ["Sugar", "Baby", "Watermelon"],
      //   ["Prickly", "Pear"],
      //   "Pears",
      //   ["Quararibea", "cordata"],
      //   "Pomegranates",
      //   "Tangerines",
      // ],
      tooltip: {
        enabled: false,
      },
      // title: {
      //   text: title?.x ? title.x : "Time Period",
      //   offsetY: 15,
      //   style: {
      //     fontFamily: "Avenir",
      //     fontSize: "14px",
      //     fontWeight: 300,
      //     lineHeight: "33px",
      //     color: "#fff",
      //   },
      // },

      axisTicks: {
        show: false,
      },
      //   labels: {
      //
      //   },
      axisBorder: {
        show: true,
        color: "#8C5ED2",
      },

      labels: {
        datetimeFormatter: {
          year: "MMM 'yy",
          month: "MMM 'yy",
          day: "dd-MMM",
          hour: "HH:mm",
        },
        // format: config.xformat ? config.xformat : "MMM yyyy",
        // formatter: function (value, timestamp, opts) {
        //   return (
        //     <tspan>
        //       <tspan>123</tspan> <tspan>${value}</tspan>{" "}
        //     </tspan>
        //   );
        // },
        rotate: 0,
        hideOverlappingLabels: false,
        trim: true,
        maxHeight: 220,
        align: "left",
        style: {
          // fill: "#b0aab7",
          fontFamily: "Avenir",
          // fontSize: "18px",
          fontWeight: "500",
          textAlign: "left",
          fontSize: 12,
          colors: "black",
          cssClass: "apexcharts-xlabel",
        },
        datetimeUTC: false,
      },
    },
    yaxis: [
      {
        opposite: false,
        min: (min2, options) => {
          // let min3 = Math.min(...options["series"][0]["data"]);

          return min2;
        },
        // forceNiceScale: true,
        axisBorder: {
          show: false,
          color: "red",
        },
        title: {
          text: title?.y ? title.y : "Portfolio Value",

          style: {
            fontFamily: "Avenir",
            fontSize: "18px",
            fontWeight: 400,
            lineHeight: "30px",
            color: title?.color || "#fff",
          },
        },
        labels: {
          show: config?.y?.show ? false : true,
          style: {
            // fill: "#b0aab7",
            fontFamily: "Avenir",
            fontSize: "18px",
            fontWeight: "500",

            fontSize: 10,
            colors: "#b0aab7",
          },
        },
      },
      showOps
        ? {
            opposite: true,
            axisBorder: {
              show: showOps?.show ? false : true,
              color: "#EAE7ED",
            },
            labels: {
              show: showOps?.show ? false : true,
              style: {
                colors: "#EAE7ED",
              },
            },
            title: {
              text: showOps.title || "",
              offsetY: 15,
              style: {
                fontFamily: "Avenir",
                fontSize: "14px",
                fontWeight: 300,
                lineHeight: "33px",
                color: "#fff",
              },
            },
          }
        : {},
      // : {
      //     show: false,
      //     axisBorder: {
      //       show: false,
      //       color: "#EAE7ED",
      //     },
      //     labels: {
      //       show: false,
      //       style: {
      //         colors: "#EAE7ED",
      //       },
      //     },
      //   },
    ],
  };
  const [options, setOptions] = useState(
    !showOps
      ? () => {
          let index = op.yaxis.findIndex((obj, i) => {
            if (obj?.opposite) {
              return i;
            }
          });
          op.yaxis.splice(index, 1);
          // console.log(op);
          return op;
        }
      : op
  );

  const brushOptions = {
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    chart: {
      id: "chart2",
      height: 120,
      width: "100%",
      type: "area",
      events: {
        selection: function (chartContext, { xaxis, yaxis }) {},
      },

      brush: {
        target: "chart1",
        enabled: true,
      },
      selection: {
        enabled: true,
      },
    },
    colors: ["#F52D6A", "#82EBA1", "#FF9446", "#f3b49f", "#f6c7b6"],
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.91,
        opacityTo: 0.1,
      },
    },
    xaxis: {
      type: "datetime",
      tooltip: {
        enabled: false,
      },

      labels: {
        // datetimeFormatter: {
        //   year: "yyyy",
        //   month: "MMM. yyyy ",
        //   day: "dd. MMM",
        // },
        style: {
          // fill: "#b0aab7",

          colors: "#ffffff",
        },
      },
      axisTicks: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    yaxis: {
      tickAmount: 4,
      labels: {
        style: {
          // fill: "#b0aab7",
          fontFamily: "Avenir",
          fontSize: "18px",
          fontWeight: "500",

          fontSize: 10,
          colors: "rgba(255, 99, 71, 0)",
          opacity: 0.1,
        },
      },
    },
  };
  const [Apexdata, setApexdata] = useState([]);
  useEffect(() => {
    if (!linedata) {
      return;
    }

    // let dt = [];
    // data.map((d) => {
    //   let obj = {
    //     name: d.id,
    //     data: [],
    //   };
    //   d.data.forEach((idata) => {
    //     obj.data.push([idata.x, roundNumber(idata.y), roundNumber(idata.z)]);
    //   });
    //   dt.push(obj);
    // });

    setApexdata(linedata);

    setOptions({ ...options });
    let arr = [];
  }, [linedata]);
  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          {options && linedata && (
            <div>
              <Chart
                options={options}
                series={linedata}
                type="line"
                height={config?.height || 400}
              />

              {!controller_disabled && (
                <Chart
                  // events={{
                  //   selection: function (chartContext, { xaxis, yaxis }) {
                  //
                  //   },
                  // }}

                  options={brushOptions}
                  series={linedata}
                  type="area"
                  height={180}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
