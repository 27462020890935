import React, { useEffect, useState } from "react";
import TableSummaryDetails from "../dashboard/products/newDesigns/common/newSummaryDetailsTable/TableSummaryDetails";
import { FullWidthSearchComponent } from "../dashboard/common/FullWidthSearchComponent";
import AddButton from "../common/AddButton";
import "./client.css";
import NewHeadAndDetailsComp from "../dashboard/products/newDesigns/common/newHeadingAndDetailsComp/NewHeadAndDetailsComp";
import { CustomTabComponent } from "../dashboard/common/CustomTabComponent";
import AppPagination from "../verificationUpload/AppPagination";
import FullWidthInput from "../common/FullWidthInput";

function ClientMappingTableWrapper({
  data,
  customHeader,
  selectedBanker,
  handleMapClick,
  pageCount,
  itemLimit,
  setItemOffset,
  setSearch,
}) {
  const [header, setHeader] = useState([]);
  const [tableData, setTableTableData] = useState([]);
  const [tab, setTab] = useState("");
  const handleRow = (row, headerData) => {
    let newCOl = headerData?.map((col) => {
      if (col?.field == "action") {
        return {
          ...col,
          field: (
            <>
              <AddButton
                addBtnTitle={"Map with Client"}
                handleIconClick={() => handleMapClick(row)}
              />
            </>
          ),
          dontShow: row?.dontShow,
          customCellClassName: selectedBanker?.partnerName
            ? "childCell3"
            : "childCell2",
          pic: row[`pic`],
        };
      }

      if (col?.field == "partnerName") {
        return {
          ...col,
          field: row[`${col?.field}`] ? row[`${col?.field}`] : "--",
          onClick: () => {
            col?.onClick(row);
          },
          dontShow: row?.dontShow,
          customCellClassName: selectedBanker?.partnerName
            ? "childCell3"
            : "childCell2",
        };
      }

      return {
        ...col,
        field: row[`${col?.field}`] ? row[`${col?.field}`] : "--",
        // color2: "#711CF1",
        onClick: row?.onClick,
        dontShow: row?.dontShow,
        customCellClassName: selectedBanker?.partnerName
          ? "childCell3"
          : "childCell2",
        customFontClassName: "fontWeight500",
      };
    });

    return newCOl;
  };

  const tabData = [{ label: "Representative" }];

  useEffect(() => {
    setHeader(() => customHeader);
    setTableTableData(() => data);
    return () => {
      setTableTableData([]);
      setHeader([]);
    };
  }, [customHeader, data]);

  return (
    <div
      className={selectedBanker?.partnerCode ? "mappingBankerContainer" : ""}
      style={{ marginTop: "30px" }}
    >
      {selectedBanker?.partnerName && (
        <NewHeadAndDetailsComp
          customStyle={{
            container: { marginTop: "0px", width: "206px" },
            title: "mapingTitle",
          }}
          title={selectedBanker?.partnerName}
          content={"The User Management screen provides administrators "}
        />
      )}
      {selectedBanker?.partnerName && (
        <div style={{ marginBottom: "22px" }}>
          <CustomTabComponent
            setTab={setTab}
            //   addBtnTitle={"Add Client"}
            hideSearch={true}
            customtabData={tabData}
            customStyle={{
              customLineStyle: { width: "100%", left: "0px" },
              indicatorStyle: { width: "100%", left: "0px" },
              tabStyle: {
                padding: "0px",
              },
            }}
          />
        </div>
      )}
      <FullWidthInput setSearch={setSearch} />
      {/* <FullWidthSearchComponent
        styles={{ inputBgColor: selectedBanker?.partnerName ? "#110624" : "" }}
      /> */}
      {tableData?.length > 0 && (
        <>
          <TableSummaryDetails
            headerData={header}
            data={tableData}
            customHandleRow={handleRow}
            customIcon={<></>}
          />
          <div className="paginationstyl">
            <AppPagination
              pageCount={pageCount || 34}
              itemLimit={itemLimit || 5}
              setItemOffset={setItemOffset ? setItemOffset : () => {}}
              otherProps={{ shape: "rounded" }}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default ClientMappingTableWrapper;
