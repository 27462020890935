import React from "react";
import { Grid, Box, useTheme } from "@material-ui/core";
import { MainCard } from "./MainCard";
import { NestedCard } from "./NestedCard";
import { Typography } from "@material-ui/core/";
import { useStyles, checkValue, formatDate } from "./CardStyles";
import { GainsCardInfo } from "./GainsCardInfo";
import { currencyFormat } from "../../../hooks/constantFunction";
import moment from "moment";

export const BankBookCard = ({ bankSummary, assetTotalData }) => {
  const classes = useStyles();
  const theme = useTheme();

  function sumUpBalance(arr) {
    return arr?.reduce((accum, item) => accum + item.balance, 0);
  }

  return (
    <Grid container spacing={1} style={{ position: "relative" }}>
      {/* 1st Row Start */}
      <Grid item xs={2}>
        <Typography className={classes.dateText}>
          Updated As On :{" "}
          <span className={classes.dateValue}>
            {moment(assetTotalData?.updated_as_on, "YYYY-MM-DD").format(
              "DD-MM-YYYY"
            ) || ""}
            {/* {formatDate(holdingSummaryCardsInactive.asOnDate)} */}
          </span>
        </Typography>
      </Grid>
      <Grid item xs={5}></Grid>
      <Grid item xs={5}></Grid>
      {/* 1st Row End */}

      {/* 2nd Row Start */}
      <Grid item xs={12}>
        <MainCard
          style={{
            minHeight: "0",
            background: theme.palette.bankBookCard.mainCard,
            borderRadius: "1rem",
          }}
          carContentProps={{ style: { margin: "0px" } }}
        >
          <NestedCard
            style={{
              background: theme.palette.bankBookCard.innerCard,
              borderRadius: "0.75rem",
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              className={classes.nestedCardBoxPadding}
            >
              <GainsCardInfo
                valueTextColor="white"
                renderCustomTextComponent={
                  <h1 className="bankBookBaseTxt bankBookCardTxt">
                    Bank Balance
                  </h1>
                }
                renderCustomValueComponent={
                  <p className="bankBookBaseTxt bankBookCardValue">
                    {/* ₹ 40,00,00,00,000 */}
                    {currencyFormat(sumUpBalance(bankSummary))}
                  </p>
                }
                customStyle={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  margin: "10px auto",
                }}
              />
            </Box>
          </NestedCard>
        </MainCard>
      </Grid>
      {/* 2nd Row End */}
    </Grid>
  );
};
