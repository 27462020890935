import React, { useEffect, useState } from "react";
import { StrategyBuilderSelection } from "../StrategyBuilderSelection";
import TargetPortfolioTable from "../targetPortfolio/TargetPortfolioTable";
import TargetPortfolioStep3Wrapper from "../targetPortfolio/TargetPortfolioStep3Wrapper";
import Benchmark from "../benchmark/Benchmark";

function UniqueCLientIndexFlow() {
  const [step, setStep] = useState(1);
  const [rowData, setRowData] = useState(() => {});
  const [memberNames, setMemberNames] = useState(null);

  const handleBack = () => {
    if (step > 0) setStep((prev) => prev - 1);
  };

  const handleNext = (members) => {
    setStep((prev) => prev + 1);
    setMemberNames(members);
  };

  const changeInStep = (step) => {
    switch (step) {
      case 1:
        return (
          <TargetPortfolioTable
            handleNext={handleNext}
            setRowData={setRowData}
            type={"UniqueClientIndex"}
          />
        );
      case 2:
        return (
          <StrategyBuilderSelection
            handleBack={handleBack}
            handleNext={handleNext}
            rowData={rowData}
          />
        );
      case 3:
        return (
          <TargetPortfolioStep3Wrapper
            type={"UniqueClientIndex"}
            handleBack={handleBack}
            handleNext={handleNext}
            memberNames={memberNames}
            rowData={rowData}
          />
        );
        case 4:
          return (
            <Benchmark
              type={"benchmark"}
              handleBack={handleBack}
              handleNext={handleNext}
              memberNames={memberNames}
              rowData={rowData}
            />
          );
      default: {
        setStep(1);
        return (
          <TargetPortfolioTable
            handleNext={handleNext}
            setRowData={setRowData}
            type={"UniqueClientIndex"}
          />
        );
      }
    }
  };

  return <>{changeInStep(step)}</>;
}

export default UniqueCLientIndexFlow;
