import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import "../PartnerOnboarding.css";
import PartnerHeading from "../PartnerHeading";
import "../NewSignUp.css";
import ErrorMessageComp from "../common/ErrorMessageComp";

const primaryBtn = {
  background: "#4D5CE3",
  borderRadius: "6px",
  width: "147px",
  color: "#FFFFFF",
  fontFamily: "Avenir",
  fontWeight: 500,
  fontSize: "16px",
  padding: "11px 18px",
  height: "50px",
};

const secondaryBtn = {
  ...primaryBtn,
};

function ConfirmationComp({
  customOpen,
  customSetOpen,
  customHandleAccept,
  head,
  handleBack,
  currentStep,
  apiErrorMessage,
  hideStep
}) {
  //   const handleClose = () => {
  //     customSetOpen(false);
  //     // customHandleClose()
  //   };
  return (
    <div className="dFlex jtfyContCntr alignCntr h100 flexDirCol">
      <div className="newFormTopSec">
        <PartnerHeading
          mainHead={head?.main ? head?.main : "Add Family Head Details"}
          subtitle={
            head?.sub ? head?.sub : "Join us by providing your company details"
          }
          handleBack={handleBack}
          step={currentStep}
          noStep={hideStep}
        />
      </div>
      <Typography
        style={{
          fontFamily: "Avenir ",
          fontWeight: "900 ",
          fontSize: "40px ",
          color: "#FFFFFF ",
          marginTop: "110px",
        }}
        // className="varientH1"
        // variant="h1"
        // component="h1"
        align="center"
      >
        Great !
      </Typography>
      {/* Sub Text */}
      <Typography
        className="varientH5 generalFontFam"
        variant="h5"
        align="center"
        style={{
          opacity: "0.699999988079071",
          color: "white",
          marginTop: "8px",
        }}
      >
        Are you sure you want to submit <br></br>these details?
      </Typography>
      <div style={{ width: "330px" }}>
        <ErrorMessageComp errorMessage={apiErrorMessage} />
      </div>

      {/* Buttons */}
      <Box style={{ display: "flex", gap: "10px", marginTop: "44px" }}>
        <Button
          onClick={() => {
            // handleClose();
          }}
          variant="contained"
          style={secondaryBtn}
          className="cancelBtn"
        >
          No
        </Button>
        {/* FORM SUBMIT API CALL👇 */}
        <Button
          variant="contained"
          style={primaryBtn}
          className="continuieBtn"
          onClick={() => {
            customHandleAccept();
            // handleClose();
          }}
        >
          Yes
        </Button>
      </Box>
    </div>
  );
}

export default ConfirmationComp;
