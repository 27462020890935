// var CryptoJS = require("crypto-js");
import React, { useRef } from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import Overall_portfolio from "./pages/Overall_portfolio";
import Portfolio_Performance from "./pages/Portfolio_Performance";
import TopPerformer from "./pages/TopPerformer";
import BottomPerformer from "./pages/BottomPerformer";
import Top5issues from "./pages/Top5issues";
import Top5amc from "./pages/Top5amc";
import Alternate_sub_asset from "./pages/Alternate_sub_asset";
import PerformanceSummary1v2 from "./pages/PerformanceSummary1v2";
import PerformanceSummary2v2 from "./pages/PerformanceSummary2v2";
import HoldingSummaryDirectEquity from "./pages/HoldingSummaryDirectEquity";
import Engagement_model from "./pages/Engagement_model";
import Equity_summary from "./pages/Equity_summary";
import Performance_key_indices from "./pages/Performance_key_indices";
import Performance_key_indices1 from "./pages/Performance_key_indices1";
import Market_snapshot from "./pages/Market_snapshot";
import { StackTable } from "./Components/StackTable";
import { AssetSubClass } from "./Components/AssetSubClass";
import { FirstPage } from "./Components/FirstPage";
import { LastPage } from "./Components/LastPage";
import EquitySummary from "./pages/EquitySummary";
import EquityTop5Stock from "./pages/EquityTop5Stock";
import Alternates from "./pages/Alternates";
import IncomeCashFlowCorpusExport from "./pages/IncomeCashFlowCorpusExport";
import RiskProfile from "./pages/RiskProfile";
import ReportingHierarchy from "./pages/ReportingHierarchy";
import Disclaimers from "./pages/Disclaimers";
import Disclaimer from "./pages/Disclaimer";
import { useApi } from "./hook/useApi";
import { useEffect } from "react";
import PerformanceSummary from "./pages/PeroformanceSummary";
import PortfolioAllocation from "./pages/PortfolioAllocation";
import EquityMF from "./pages/EquityMF";
import { useLocation, useSearchParams, useParams } from "react-router-dom";
import { alternateStaticData } from "./pages/utils";
import { useState } from "react";
import PageLoader from "../products/common/pageLoader";
import {
  EquityMFHolding,
  corpusTableHeader,
  corpusTableHeader2,
  top5amc,
  top5issuer,
} from "./Components/utils/staticData";
import EquityTop5Sectors from "./pages/EquityTop5Sectors";
import useWindowSize from "./hook/seWindowSize";
import CashFlow from "./pages/CashFlow";
import { Decrypt } from "./Components/utils/common";
import useGetMongoPrintReport from "../../../hooks/useGetMongoPrintReport";
import { getPrintDataMongo } from "../../../api";
import Asset_allocation from "./pages/Asset_allocation";
import Top5pdf from "./pages/Top5pdf";
import CashFlowNew from "./pages/CashFlowNew";
import Member_wise from "./pages/Member_wise";
import Product_Allocation from "./pages/Product_Allocation";
import Top3performer from "./pages/top3Performer";
import HoldingPdfPage from "./pages/HoldingPdfPage";
import ThankYouPage from "./pages/ThankYouPdf";
import { ClientFirstPage } from "./Components/ClientFirstPage";
import TopHoldingByAllocation from "./pages/TopHoldingByAllocation";
import LoadingScreen from "./pages/LoadingScreen";
import NewProductAllocation from "./pages/NewProductAllocation";
import HoldingPdfPagev1 from "./pages/HoldingPdfPagev1";
import Overall_portfolioHolding from "./pages/Overall_portfolioHolding";
import ClientHoldings from "./pages/ClientHoldings";
import TopBottomPerformer from "./pages/TopBottomPerformer";
import { CoverPage } from "./pages/subPrintPages/CoverPage";
import Annexures from "./pages/Annexures";
import AssetClassSummaryPdf from "./pages/AssetClassSummaryPdf";
import NewProduct from "./pages/newProduct";
import MemberReport from "./pages/MemberReport";
import { OnboardingContext } from "../../../Context";
import EquitySectorAllocationPdf from "./pages/EquitySectorAllocationPdf";
import SubCategoryAllocationPdf from "./pages/subCategory";
import SubCategoryContainer from "./pages/SubCategoryContainer";
import MarketCap from "./pages/MarketCap";

export default function ClientPortfolioPdf({
  mongo,
  handleUploadPDF,
  download,
}) {
  // const {
  //   state: { mongoId },
  // } = useLocation();
  // const {} = useSearchParams();
  let [searchParams, setSearchParams] = useSearchParams();
  // let denomination = "Lakhs";
  const mongoId = searchParams.get("id");
  const parentRef = useRef(null);
  // const { type, familyMembers, duration, denomination, mongoId } = Decrypt(
  //   searchParams.get("key")
  // );

  const { dispatch } = OnboardingContext();
  const [browserSize, setBrowserSize] = useState(
    ((window.outerWidth - 10) / window.innerWidth) * 100
  );
  const windowSize = useWindowSize();
  const [changes, setChanges] = useState();
  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "#E4E4E4",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
  });
  //
  let payload = {};
  // if (familyMembers?.length > 0) {
  //   payload["memberId"] = familyMembers?.join(",");
  // }
  // if (duration?.startDate !== "") {
  //   payload["startDate"] = duration?.startDate;
  //   payload["endDate"] = duration?.endDate;
  // }
  // localStorage.setItem("denomination", denomination);
  const [data, setData] = useState({});
  const [reset, setReset] = useState(false);
  const {
    showLoader,
    setShowLoader,
    CallApis,
    errorMessage,
    customData,
    erroredAPIS,
    apiArray,
  } = useApi(payload, setReset);

  const { newData, denomination, meta, pdfInfo, loading } =
    useGetMongoPrintReport(
      // "65f1438c685fe824f3140626"
      // "65f17b8f1c6deb43e82defeb"
      // "65f2f586ae19f10008f9054b"
      mongoId || mongo
    );
  // mongoId

  const getDenomination = localStorage.getItem("denomination");
  console.log("mongo", mongoId, newData);
  let assettotal = {
    assetClass: "Total",

    interestIncome: newData?.["dashboard-gain-loss"]?.interestIncome,

    investedAmount: newData?.["dashboard-gain-loss"]?.investedAmount,

    percentageOfTotalPortfolio:
      newData?.["dashboard-gain-loss"]?.totalPortfolioPercentage,

    portfolioValueAmount:
      newData?.["dashboard-gain-loss"]?.portfolioValueAmount,

    unRealisedGainLoss: newData?.["dashboard-gain-loss"]?.unRealisedGainLoss,

    xirr: newData?.["dashboard-current-holding-xirr"]?.xirrValue,
  };
  useEffect(() => {
    if (browserSize < 99) {
      dispatch({
        type: "UPDATE_SNACKBAR_DATA",
        payload: {
          success: true,
          error: false,
          showMsg: true,
          message:
            "Please set Browser Zoom to 100% for better viewing of report",
        },
      });
    }
  }, [browserSize]);

  useEffect(() => {
    if (newData) {
      let a = parentRef?.current?.querySelectorAll(".printPageNumber");
      if (!a) {
        return;
      }
      console.log(a, "checkinga");
      Array.from(a)?.map((val, i) => {
        val.innerHTML = `${i + 1}`;
      });
    }
    const getData = setTimeout(() => {
      if (Object.keys(newData).length > 0 && download == 0) {
        handleUploadPDF();
      }
    }, 5000);

    return () => clearTimeout(getData);
    // setNewPageList(a);
  }, [changes, data, newData]);

  // console.log(Object?.keys(newData), "aglnj");

  return (
    <>
      {loading && (
        <>
          {/* <ClientFirstPage type={"print"} pdfInfo={pdfInfo} /> */}
          <CoverPage
            type={"pdf"}
            pdfInfo={pdfInfo}
            title={"Client Portfolio Report"}
          />
          <LoadingScreen loading={loading} />
        </>
      )}
      {Object?.keys(newData)?.length > 0 && (
        <Document ref={parentRef}>
          <CoverPage
            type={"pdf"}
            pdfInfo={pdfInfo}
            title={"Client Portfolio Report"}
          />
          <Page size="A4">
            <div className="newPrint">
              <Overall_portfolioHolding
                newData={newData}
                denomination={denomination}
                // data={data}
                pdfInfo={pdfInfo}
              />
            </div>
          </Page>
          <AssetClassSummaryPdf
            denomination={denomination}
            title={"Asset Class Summary"}
            data={
              [...newData?.["dashboard-asset-details"]?.data, assettotal] || {}
            }
            pdfInfo={pdfInfo}
          />

          <NewProduct
            newData={newData}
            reportType={"Product allocation"}
            denomination={denomination}
            setChanges={setChanges}
            pdfInfo={pdfInfo}
          />
          <div className="newgraphPrint">
            <MarketCap
              denomination={denomination}
              data={newData?.["equity-market-cap"] || {}}
              title={"Market Cap"}
              mappedKey={{
                key1: "topFiveAMCs",
                key2: "amc",
                key3: "percentage",
                key4: "topFiveAmcValuation",
              }}
              tableconfig={top5amc}
              pdfInfo={pdfInfo}
            />
            <EquitySectorAllocationPdf
              denomination={denomination}
              setChanges={setChanges}
              newdata={newData?.["equity-sector-allocation"] || {}}
              pdfInfo={pdfInfo}
              // currentData={newData?.["dashboard-gain-loss"]?.portfolioValueAmount}
            />

            <SubCategoryContainer
              denomination={denomination}
              setChanges={setChanges}
              newdata={newData || {}}
              pdfInfo={pdfInfo}
              // currentData={newData?.["dashboard-gain-loss"]?.portfolioValueAmount}
            />
          </div>
          {/* <CashFlowNew
            denomination={denomination}
            newdata={newData?.["cashflow-data"] || {}}
            pdfInfo={pdfInfo}
          /> */}
          <Member_wise
            denomination={denomination}
            data={newData?.["dashboard-member-wise-performance"] || {}}
            pdfInfo={pdfInfo}
          />

          <MemberReport
            sortData={newData?.["asset-wise-product-sort-order"]}
            newData={newData?.["member-wise-modules-data"]}
            denomination={denomination}
            setChanges={setChanges}
            pdfInfo={pdfInfo}
          />

          {/* <Asset_allocation
            denomination={denomination}
            newdata={newData?.["dashboard-asset-summary"] || {}}
            pdfInfo={pdfInfo}
          /> */}
          {/* <NewProductAllocation
            denomination={denomination}
            newdata={newData?.["dashboard-product-wise-allocation"] || {}}
            pdfInfo={pdfInfo}
            currentData={newData?.["dashboard-gain-loss"]?.portfolioValueAmount}
            fundsInTransit={
              newData?.["funds-in-transit-current-value"]?.presentAmount
            }
          /> */}
          {/* <Product_Allocation
            denomination={denomination}
            data={newData?.["dashboard-product-wise-allocation"] || {}}
            pdfInfo={pdfInfo}
          /> */}
          {/* <ClientHoldings
            newData={newData}
            reportType={"holding"}
            denomination={denomination}
            setChanges={setChanges}
            pdfInfo={pdfInfo}
          /> */}
          {/* <HoldingPdfPage
            newData={newData?.holdingSummary}
            denomination={denomination}
            pdfInfo={pdfInfo}
            reportType={"clientHolding"}
            setChanges={setChanges}
          /> */}
          {/* <TopBottomPerformer
            denomination={denomination}
            data={newData || {}}
            title={"Top Performers"}
            datasrc="top-performers-by-xirr-"
            pdfInfo={pdfInfo}
            setChanges={setChanges}
          /> */}

          {/* <Top3performer
            denomination={denomination}
            data={newData || {}}
            title={"Top Performers"}
            datasrc="top-performers-"
            pdfInfo={pdfInfo}
            setChanges={setChanges}
          />
          <Top3performer
            denomination={denomination}
            data={newData || {}}
            title={"Bottom Performers"}
            datasrc="bottom-performers-"
            pdfInfo={pdfInfo}
            setChanges={setChanges}
          />
          <TopHoldingByAllocation
            denomination={denomination}
            data={newData || {}}
            title={"Top Holdings By Allocation"}
            datasrc="top-holdings-by-allocation-"
            pdfInfo={pdfInfo}
            setChanges={setChanges}
          /> */}

          <ThankYouPage pdfInfo={pdfInfo} />
          <Annexures pdfInfo={pdfInfo} />
          <Disclaimer pdfInfo={pdfInfo} />
          {/* <Page size="A4">
            <Disclaimer />
          </Page> */}
        </Document>
      )}
    </>
  );
}
