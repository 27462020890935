import React, { useEffect } from "react";
import { useState } from "react";
import { roundNumber } from "../../../utils/common";
import CardInvestment from "./utils/CardInvestment";
import CardWithPieChartAndTable from "./utils/CardWithPieChartAndTable";
import { getTableAndPieChartData } from "./utils/common";
import {
  holdingDetailsConfigChart,
  holdingDetailsConfigTable,
  sectorWiseConfigTable,
} from "./utils/constands";
import FundDetailsSection from "./utils/FundDetailsSection";
import FundPortfolioCard from "./utils/FundPortfolioCard";
import MarketCapPieChart from "./utils/MarketCapPieChart";

function RiskDetailsPageEquity({
  riskDetails,
  tableColumns,
  setTableColumns,
  tableHeader,
  setTableHeader,
  setSectorWiseState,
  sectorWiseState,
  sectorWiseColor,
  assetProgressBarColors,
  asset_color,
  disabledassetProgressBarColors,
  setAsset_color,
  handleMouseMove,
  marketCapState,
  setMarketCapState,
  marketCapColors,
  sectorAllocation,
}) {
  const [topHoldingData, setTopHoldingData] = useState({
    tableData: [],
    pieChartData: [],
  });
  const [newSectorAllocation, setNewSectorAlocation] = useState({
    tableData: [],
    pieChartData: [],
  });

  const objectFormating = (data, headerConfig) => {
    let newData = headerConfig?.map((header) => {
      // if (header?.field == "Name") {
      //   return {
      //     field: data[`${header.field}`],
      //     title: header.title,
      //     show: header.show,
      //     align: header?.align,
      //   };
      // }
      return {
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`]))
          : data[`${header.field}`] || "--",
        title: header.title,
        show: header.show,
        align: header?.align,
      };
    });

    return newData;
  };

  const handleTopHoldingData = (data) => {
    let res = getTableAndPieChartData({
      data: data,
      field: "holdingDetails",
      tableConfig: holdingDetailsConfigTable,
      tableDataFormatter: objectFormating,
      pieChartConfig: holdingDetailsConfigChart,
    });

    setTopHoldingData((prev) => {
      return {
        tableData: res?.tableData,
        pieChartData: [],
      };
    });
  };

  const handleSectorAlocationData = (data) => {
    let res = getTableAndPieChartData({
      data: { data: data },
      field: "data",
      tableConfig: sectorWiseConfigTable,
      tableDataFormatter: objectFormating,
    });

    setNewSectorAlocation(() => ({
      tableData: res?.tableData,
      pieChartData: data,
      total: res?.total,
    }));
  };

  useEffect(() => {
    handleTopHoldingData(riskDetails);
    handleSectorAlocationData(sectorAllocation);
  }, []);

  return (
    <>
      <FundPortfolioCard
        riskDetails={riskDetails}
        assetProgressBarColors={assetProgressBarColors}
        disabledassetProgressBarColors={disabledassetProgressBarColors}
        setAsset_color={setAsset_color}
        handleMouseMove={handleMouseMove}
        asset_color={asset_color}
      />
      <MarketCapPieChart
        riskDetails={riskDetails}
        marketCapState={marketCapState}
        setMarketCapState={setMarketCapState}
        marketCapColors={marketCapColors}
      />
      <CardWithPieChartAndTable
        sectorWiseColor={sectorWiseColor}
        setSectorWiseState={setSectorWiseState}
        sectorWiseState={sectorWiseState}
        subTitle="Sector Allocation"
        data={newSectorAllocation}
        tableConfig={sectorWiseConfigTable}
      />
      <CardWithPieChartAndTable
        sectorWiseColor={sectorWiseColor}
        setSectorWiseState={setSectorWiseState}
        sectorWiseState={sectorWiseState}
        id={"topHoldings"}
        title="Top Holdings"
        data={topHoldingData}
        tableConfig={holdingDetailsConfigTable}
      />
      <div style={{ margin: "2%" }}>
        <CardInvestment riskDetails={riskDetails} />
      </div>
      <div style={{ margin: "2%" }}>
        <FundDetailsSection riskDetails={riskDetails} />
      </div>
    </>
  );
}

export default RiskDetailsPageEquity;
