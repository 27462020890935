let align = "center";
export const aifDrawdownTable = [
  {
    field: "schemeName",
    title: "Particular Segment Value",
    align: "center",
    show: true,
    color: "",
  },
  {
    field: "folioNo",
    title: "Folio No",
    align: "left",
    color: "",
    show: true,
  },
  {
    field: "assetClass",
    title: "Asset Class",
    align: "left",
    color: "",
    show: true,
  },

  {
    field: "lastDrawdownDate",
    title: "Last Drawdown Date",
    align: "center",
    show: true,
  },
  {
    field: "commitedAmount",
    title: "Committed Amount",
    align: "center",
    show: true,
  },
  {
    field: "drawdownAmount",
    title: "Drawdown Amount",
    align: "center",
    show: true,
  },
  {
    field: "pendingDrawdown",
    title: "Pending Drawdown",
    align: "center",
    show: true,
  },
  {
    field: "totalInvestmentValue",
    title: "Invested Value",
    align: "center",
    show: true,
  },
  {
    field: "totalPresentValue",
    title: "Current Value",
    align: "center",
    show: true,
  },
  {
    title: "Total Gain loss",
    field: "totalGainLoss",
    align: "center",
    show: true,
  },
];
export const tableTypeHolding = [
  { field: "schemeName", title: "Product Name", show: true, align: align },
  { field: "advisor", title: "Advisor", show: true, align: align },
  { field: "memberName", title: "Member Name", show: true, align: align },
  { field: "isin", title: "Product Code", show: true, align: align },
  { field: "folioNo", title: "Folio No", show: true, align: align },
  // { field: "assetType", title: "Asset Type", show: true },
  {
    field: "productSubCategory",
    title: "Product Sub Category",
    show: true,
    align: align,
  },
  { field: "class", title: "Class", show: true, align: align },
  { field: "aifType", title: "AIF Type", show: true, align: align },
  { field: "investmentValue", title: "Invested Value", show: true },
  { field: "withdrawalAmount", title: "Withdrawal", show: true },

  { field: "presentValue", title: "Present Value", show: true },

  { field: "totalCharges", title: "Total Charges", show: true },
  {
    field: "avgHoldingPeriod",
    title: "Holding Period",
    show: true,
  },
  {
    field: "holdingUnits",
    title: "Units",
    show: true,
  },
  {
    field: "unRealisedGainLoss",
    title: "Unrealized Gain/Loss",
    show: true,
  },
  // {
  //   field: "realisedGainLoss",
  //   title: "Realized Gain/Loss",
  //   show: false,
  // },
  {
    field: "interestIncome",
    title: "Other Income",
    show: true,
  },
  { field: "totalGainLoss", title: "Total Gain/Loss", show: true },
  { field: "xirr", title: "XIRR (%)", show: true },
  { field: "absoluteTwrr", title: "Absolute TWRR (%)", show: true },
  { field: "annualisedTwrr", title: "Annualized TWRR (%)", show: true },
];

export const tableTypeTransaction = [
  { field: "scheme_name", title: "Product Name", show: true, align: align },
  { field: "advisor", title: "Advised by", show: true, align: align },
  { field: "executed_by", title: "Executed By", show: true, align: align },
  { field: "isin", title: "Product Code", show: true, align: align },
  { field: "folio_no", title: "Folio No", show: true, align: align },
  // { field: "asset_type", title: "Asset Type", show: true },
  {
    field: "product_sub_category",
    title: "Product Sub Category",
    show: true,
    align: align,
  },
  { field: "class", title: "Class", show: true, align: align },
  { field: "aif_fund_type", title: "AIF Type", show: true, align: align },
  {
    field: "transaction_date",
    title: "Transaction Date",
    show: true,
    align: align,
  },
  {
    field: "transaction_type",
    title: "Transaction Type",
    show: true,
    align: align,
  },
  {
    field: "holdingPeriod",
    title: "Holding Period",
    show: true,
    align: align,
  },
  { field: "amount", title: "Amount", show: true },
  { field: "committed_amount", title: "Commited Amount", show: true },
  {
    field: "undrawn_committed_amount",
    title: "Undrawn Committed Amount",
    show: true,
  },
  {
    field: "compensation_contribution",
    title: "Compensation Contribution",
    show: true,
  },
  { field: "exit_load", title: "Exit Load", show: true },
  { field: "management_fees", title: "Management Fees", show: true },
  { field: "hurdle_rate", title: "Hurdle rate (%)", show: true },
  { field: "performance_fees", title: "Performance Fees", show: true },
  { field: "stamp_duty", title: "Stamp Duty", show: true },
  {
    field: "securities_transaction_tax",
    title: "Security Transaction Tax",
    show: true,
  },

  { field: "gst", title: "GST", show: true },
  { field: "tds", title: "TDS", show: true },
  { field: "cess", title: "CESS", show: true },
  { field: "units", title: "Units", show: true },
];
