import React from "react";
import H_Cards from "./H_Cards";

export default function CardsWrapper({ carddata, style, classnm }) {
  // let data = [
  //   {
  //     title: "Current value",
  //     value: "₹ 40.00 Cr",
  //     style: {},
  //     type: "rs",
  //   },
  //   {
  //     title: "Invested Value",
  //     value: "₹ 20.00 Cr",
  //     style: {},
  //     type: "rs",
  //   },
  //   {
  //     title: "Total Gian/Loss",
  //     value: "₹20.00 Cr",
  //     style: {},
  //     type: "rs",
  //   },
  //   {
  //     title: "XIRR",
  //     value: "10.5%",
  //     style: { background: "#D9FEEB" },
  //     type: "rs",
  //   },
  //   {
  //     title: "Annualised Twrr",
  //     value: "10.5%",
  //     style: { background: "#D9FEEB" },
  //     type: "rs",
  //   },
  //   {
  //     title: "Absolute TWRR",
  //     value: "10.5%",
  //     style: { background: "#D9FEEB" },
  //     type: "rs",
  //   },
  //   {
  //     title: "Benchmark Xirr",
  //     value: "10.5%",
  //     style: {},
  //     type: "rs",
  //   },
  //   {
  //     title: "Blended Benchmark",
  //     value: "10.5%",
  //     style: {},
  //     type: "rs",
  //   },
  // ];

  return (
    <div className="new_p_flex" style={{ gap: "9px 0px", ...style }}>
      {carddata.map((d, k) => {
        return <H_Cards data={d} key={k} classnm={classnm}></H_Cards>;
      })}
    </div>
  );
}
