import React from 'react'
import useStyles from './Clientdashstyle'

function TabDashboard({ page, setPage }) {
    const classes = useStyles()
    return (
        <>
            {/* <div style={{ cursor: "pointer", width: "350px" }} className={classes.tabDash}>
                <a onClick={() => { setPage("consolidated") }} style={{ textDecoration: 'none' }} ><div className={`${classes.tabHeaderText} ${page === 'consolidated' ? classes.active : ''}`}>Consolidated</div></a>
                <a onClick={() => { setPage("individual") }} style={{ textDecoration: 'none' }}><div className={`${classes.tabHeaderText} ${page === 'individual' ? classes.active : ''}`}>Individual</div></a>
            </div> */}
            <div style={{ width: "350px", margin: "2%", cursor: "pointer" }} className={classes.tabDash}>
                <a onClick={() => { setPage("consolidated") }} style={{ textDecoration: 'none' }} ><div className={`${classes.tabHeaderText} ${page === 'consolidated' ? classes.active : ''}`}>Consolidated</div></a>
                <a onClick={() => { setPage("individual") }} style={{ textDecoration: 'none' }}><div className={`${classes.tabHeaderText} ${page === 'individual' ? classes.active : ''}`}>Individual</div></a>
            </div>
        </>
    )
}

export default TabDashboard