import { useState, useEffect, useContext } from "react";
import { OnboardingContext } from "../Context";
import { mfoFilterContext } from "../mfo_context/Mfo_Context";
import moment from "moment";
import { getNonEmptyProductList } from "../api";

export const useNonEmptyProductList = ({ dataType, typeOfPage }) => {
  const { userState } = OnboardingContext();
  const [nonEmptyProductList, setNonEmptyProductList] = useState();
  const [loading, setLoading] = useState(true);
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  let clientId = userState.loginedUserData.id;

  useEffect(() => {
    let params = {
      ...getfilter_as_params(),
      dataType: dataType,
      includeFundsInTransit: typeOfPage === "transaction" ? 1 : 0,
    };

    if (mfoFilter.startDate) {
      params["startDate"] = moment(mfoFilter.startDate).format("YYYY-MM-DD");
      params["endDate"] = moment(mfoFilter.endDate).format("YYYY-MM-DD");
    }
    if (Object.keys(mfoFilter.checkData).length > 0) {
      params["memberId"] = Object.keys(mfoFilter.checkData).join(",");
    }
    if (Object.keys(mfoFilter.advisor).length > 0) {
      params["advisor"] = Object.keys(mfoFilter.advisor).join(",");
    }

    setLoading(true);
    getNonEmptyProductList(clientId, params)
      .then((res) => {
        setNonEmptyProductList(res?.result);
      })
      .catch((err) => {
        console.log(`An ${err} has occurred`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [mfoFilter, dataType]);

  return { nonEmptyProductList, loading };
};
