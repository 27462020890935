import { Typography, useTheme } from "@material-ui/core";
import React from "react";
import useStyles from "./common/ProductsStyles";
import { ReactComponent as EditColumn } from "../../../assets/icons/edit-column.svg";
import { ReactComponent as Expand } from "../../../assets/icons/expand.svg";
import { ReactComponent as Collapse } from "../../../assets/icons/collapse.svg";
import { useState } from "react";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAssetDetailView } from "../../../hooks/useAssetDetailView";
import { useProductSummary } from "../../../hooks/useProductSummary";
import { useCheckBoxClick } from "../../../hooks/useCheckBoxClick";
import SummaryDetailsTable from "./common/SummaryDetailsTable";
import { currencyFormat } from "../../../hooks/constantFunction";
import { roundNumber } from "../../utils/common";
import PageLoader from "./common/pageLoader";
import { tableConfig } from "./mfo/mfoTableColumns";
import { mfoFilterContext } from "../../../mfo_context/Mfo_Context";
import { useContext } from "react";
import { get_path_type } from "../../utils/strHelper";
import { routes } from "../../../routes";

import FullWidthInput from "../../common/FullWidthInput";
import FullWidthInput2 from "../../common/FullWidthInput2";
// import { ReactComponent as Back } from "../../../assets/holding-summary/back-blue.svg";

const tableColumnsData = [
  { field: "assetClass", title: "Asset Class", show: true },
  { field: "investedAmount", title: "Invested Value", show: true },
  { field: "withDrawalAmount", title: "Withdrawals", show: true },
  { field: "portfolioValueAmount", title: "Current Value", show: true },
  {
    field: "percentageOfTotalPortfolio",
    title: "% of Total Portfolio",
    show: true,
  },
  { field: "realisedGainLoss", title: "Realized Gain/Loss", show: true },
  { field: "unRealisedGainLoss", title: "Unrealized Gain/Loss", show: true },
  { field: "interestIncome", title: "Other Income", show: true },
  { field: "xirr", title: "XIRR (%)", show: true },
  { field: "benchmarkXirr", title: "Benchmark XIRR (%)", show: true },
  { field: "absoluteTwrr", title: "Absolute TWRR (%)", show: true },
  { field: "annualisedTwrr", title: "Annualized TWRR (%)", show: true },
  {
    field: "benchmarkName",
    title: "Benchmark Name",
    show: true,
    align: "left",
  },
  // { field: "XIRR_FY", title: "XIRR (FY)", show: false },
];

const bottomGrandTotalTableColumnsData = [
  { field: "product", title: "", show: true },
  { field: "investedAmount", title: "Invested Value", show: true },
  { field: "withDrawalAmount", title: "Withdrawals", show: true },
  { field: "portfolioValueAmount", title: "Current Value", show: true },
  {
    field: "totalPortfolioPercentage",
    title: "% of Total Portfolio",
    show: true,
  },
  { field: "realisedGainLoss", title: "Realized Gain/Loss", show: true },
  { field: "unRealisedGainLoss", title: "Unrealized Gain/Loss", show: true },
  { field: "interestIncome", title: "Other Income", show: true },
  { field: "xirrValue", title: "XIRR (%)", show: true },
  { field: "benchmarkXirr", title: "Benchmark XIRR (%)", show: true },
  { field: "absoluteTwrr", title: "Absolute TWRR (%)", show: true },
  { field: "annualisedTwrr", title: "Annualized TWRR (%)", show: true },
  {
    field: "--",
    title: "Benchmark Name",
    show: true,
  },
  // { field: "XIRR_FY", title: "XIRR (FY)", show: false },
];

const productRoutes = {
  ["Mutual Funds"]: routes.mfSummaryPage,
  ["PMS"]: routes.pmsSummaryPage,
  ["AIF"]: routes.aifSummaryPage,
  ["Real Estate"]: routes.realEstateSummaryPage,
  ["Insurance"]: routes.insuranceSummaryPage,
  ["Bonds"]: routes.bondsSummaryPage,
  ["Direct Equity"]: routes?.equitySummaryPage,
  ["Structured Product"]: routes.structuredProdSummaryPage,
  ["Unlisted Equity"]: routes.privateMarketSummaryPage,
  ["Market-Linked Debentures"]: routes.mldSummaryPage,
  ["Real Estate Investment Trusts"]: routes.reitsSummaryPage,
  ["Infrastructure Investment Trusts"]: routes.invitsSummaryPage,
  ["Inter Corporate Deposits"]: routes.icdSummaryPage,
  ["Bank Book"]: routes.bankBookSummaryPage,
  ["Fixed Deposit"]: routes.fixedDepositSummaryPage,
  ["Recurring Deposit"]: routes.recurringDepositSummaryPage,
};

const laoderConfig = {
  equity: false,
  cash: false,
  alternate: false,
  debt: false,
};

function SummaryDetailsComp() {
  const classes = useStyles();
  const theme = useTheme();
  const themeicons = theme.palette.icons;
  const TableEditIcon = themeicons.tableEditIcon;

  const { mfoFilter, getfilter_as_params, setMfofilter } =
    useContext(mfoFilterContext);
  const [loading, setLoading] = useState(false);
  const [assetClass, setAssetClass] = useState();
  const [resetTabel, setResetTable] = useState();
  const [expandRow, setExpandRow] = useState([]);

  const [loaderConfigAssetClass, setLoaderConfigAssetClass] = useState(false);
  const [totalRowLoading, setTotalRowLoading] = useState(false);

  const [tablerRows, grandTotal, getAssetWiseTwrr, getTotalRow] =
    useAssetDetailView(
      setLoading,
      resetTabel,
      setLoaderConfigAssetClass,
      setTotalRowLoading
    );

  const [expandAll, setExpandAll] = useState(false);
  const [loaderConfig, setLoaderConfig] = useState({
    ...laoderConfig,
  });

  const handleLoading = (assetClass, state) => {
    setLoaderConfig((prev) => {
      prev[assetClass] = state;
      return { ...prev };
    });
  };

  const { productSummary, getProductTwrr } = useProductSummary(
    assetClass,
    setLoading,
    resetTabel,
    expandRow,
    expandAll,
    handleLoading
  );
  const [handleCheckBoxClick, tableColumns] =
    useCheckBoxClick(tableColumnsData);
  const [openModal, setOpenModal] = useState(false);
  const [productExpand, setProductExpand] = useState({
    equity: false,
    debt: false,
  });
  const [tableHeader, setTableHeader] = useState(tableColumnsData);
  const [newTableColumns, setNewTableColumns] = useState([]);
  const [innerTable, setInnerTable] = useState({});
  const [bottomTabel, setBottomTabel] = useState({});
  const [bottomTableHeader, setBottomTableHeader] = useState(
    bottomGrandTotalTableColumnsData
  );

  let handleProductClick = (product, assetClass) => {
    let pathtype = get_path_type(productRoutes[product]);

    mfoFilter.assetclass[pathtype] = { [assetClass]: true };

    setMfofilter({ ...mfoFilter });
    sessionStorage.setItem("filter", JSON.stringify(mfoFilter));

    navigate(productRoutes[product]);
  };

  const innerTableColumnsData = [
    {
      field: "product",
      title: "",
      show: true,
      customOnClick: (product, assetClass) =>
        handleProductClick(product, assetClass),
    },
    { field: "investedAmount", title: "Invested Value", show: true },
    { field: "withDrawalAmount", title: "Withdrawals", show: true },
    { field: "portfolioValueAmount", title: "Current Value", show: true },
    {
      field: "percentageOfTotalPortfolio",
      title: "% of Total Portfolio",
      show: true,
    },
    { field: "realisedGainLoss", title: "Realized Gain/Loss", show: true },
    { field: "unRealisedGainLoss", title: "Unrealized Gain/Loss", show: true },
    { field: "interestIncome", title: "Other Income", show: true },
    { field: "xirr", title: "XIRR (%)", show: true },
    { field: "benchmarkXirr", title: "Benchmark XIRR (%)", show: true },
    { field: "absoluteTwrr", title: "Absolute TWRR (%)", show: true },
    { field: "annualisedTwrr", title: "Annualized TWRR (%)", show: true },
    {
      field: "benchmarkName",
      title: "Benchmark Name",
      show: true,
    },
    // { field: "XIRR_FY", title: "XIRR (FY)", show: false },
  ];

  const [innetTableHeader, setInnerTableHeader] = useState(
    innerTableColumnsData
  );

  const objectFormating = (data, headerData, assetClass) => {
    let newData = headerData.map((header) => {
      if (header?.field == "withDrawalAmount") {
        return {
          field: currencyFormat(data[`${header.field}`] || 0) || 0,
          title: header.title,
          show: header.show,
        };
      }
      if (header?.field == "benchmarkName") {
        return {
          field: data[`${header.field}`] || "--",
          title: header.title,
          show: header.show,
          align: "left",
        };
      }
      if (
        header?.field == "investedAmount" ||
        header?.field == "interestIncome" ||
        header?.field == "portfolioValueAmount" ||
        header?.field == "totalGainLoss" ||
        header?.field == "unRealisedGainLoss" ||
        header?.field == "realisedGainLoss"
      ) {
        return {
          field: currencyFormat(data[`${header.field}`] || 0) || "--",
          title: header.title,
          show: header.show,
        };
      }
      if (
        header?.field == "xirr" ||
        header?.field == "benchmarkXirr" ||
        header?.field == "absoluteTwrr"
      ) {
        if (
          header?.field == "absoluteTwrr" &&
          data[`${header.field}`] == "Error"
        ) {
          console.log("dataError", data, assetClass);
          return {
            field: !isNaN(data[`${header.field}`])
              ? roundNumber(Number(data[`${header.field}`] * 1)) || "--"
              : data[`${header.field}`] || "--",
            title: header.title,
            show: header.show,
            hanleErrorRetry: !data?.assetClass
              ? () => getTotalRow()
              : assetClass
              ? () => getProductTwrr(assetClass)
              : () => getAssetWiseTwrr(),
          };
        }

        return {
          field: !isNaN(data[`${header.field}`])
            ? roundNumber(Number(data[`${header.field}`] * 1)) || "--"
            : data[`${header.field}`] || "--",
          title: header.title,
          show: header.show,
        };
      }
      if (header?.field == "annualisedTwrr") {
        if (data[`${header.field}`] == "Error") {
          console.log("dataError", data, assetClass);
          return {
            field: !isNaN(data[`${header.field}`])
              ? roundNumber(Number(data[`${header.field}`] * 1)) || "--"
              : data[`${header.field}`] || "--",
            title: header.title,
            show: header.show,
            hanleErrorRetry: !data?.assetClass
              ? () => getTotalRow()
              : assetClass
              ? () => getProductTwrr(assetClass)
              : () => getAssetWiseTwrr(),
          };
        }
        return {
          field: !isNaN(data[`${header.field}`])
            ? roundNumber(Number(data[`${header.field}`])) || "--"
            : data[`${header.field}`] || "--",
          title: header.title,
          show: header.show,
        };
      }
      return {
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`]))
          : data[`${header.field}`] || "--",
        title: header.title,
        show: header.show,
      };
    });
    return newData;
  };

  const { state } = useLocation();

  useEffect(() => {
    newHandleProductExpand(state?.prodExpand);
  }, []);

  useEffect(() => {
    if (tablerRows) {
      console.log(tablerRows, "agknj");
      let newList = [];
      tablerRows?.map((val) => {
        let newVal = objectFormating(val, tableHeader);
        newList.push(newVal);
      });

      setNewTableColumns(newList);
      newList = [];
    }

    if (productSummary) {
      console.log(productSummary, "afkbhj");
      let newList = [];
      let newObj = {};
      Object.keys(productSummary).map((val1) => {
        newObj[val1] = [];
        productSummary[val1]?.map((val) => {
          let newVal = objectFormating(val, innetTableHeader, val1);
          newList.push(newVal);
          newObj[val1] = [...newObj[val1], newVal];
        });
      });
      // productSummary?.map((val) => {
      //   let newVal = objectFormating(val, innetTableHeader);
      //   newList.push(newVal);
      // });

      setInnerTable((prev) => newObj);
      // setInnerTable((prev) => ({ ...prev, [assetClass]: [...newList] }));
    }

    if (grandTotal) {
      let newList = [];
      let newVal = objectFormating(grandTotal, bottomTableHeader);
      newList.push(newVal);
      setBottomTabel((prev) => ({ ...prev, grand_Total: newVal }));
    }
  }, [tablerRows, grandTotal, productSummary]);

  const navigate = useNavigate();

  const handleButtonClick = () => {
    setOpenModal(!openModal);
  };

  const newHandleProductExpand = (product, remove) => {
    if (product !== undefined) {
      if (remove) {
        setAssetClass("");
        setExpandRow((prev) => prev.filter((val) => val !== product));
        setInnerTable((prev) => {
          prev[product] = [];
          return { ...prev };
        });
        return;
      }

      setExpandRow((prev) => [...prev, product]);
      setAssetClass(product);
    }
  };

  const handleProductExpand = (type) => {
    setAssetClass(type);
    setProductExpand({
      ...productExpand,
      [type.toLowerCase()]: !productExpand[type.toLowerCase()],
    });
  };

  const getResetedRow = (row) => {
    let newRow = [];
    tableConfig.map((column) => {
      row.map((oldCol) => {
        if (column.title == oldCol.title) {
          newRow.push(oldCol);
        }
      });
    });
  };

  const handleExpandAll = () => {
    setExpandRow((prev) => {
      if (prev.length == 0) {
        setExpandAll(true);
        return ["equity", "debt", "alternate", "cash"];
      }
      if (prev.length !== 0) {
        setExpandAll(false);
        return [];
      }
    });
  };

  return (
    <>
      {/* <PageFilter /> */}
      <>
        <div className="pageTitleContainer">
          <Typography className={classes.titleText}>
            Summary
            {sessionStorage.getItem("currency_format") != "actuals" &&
              sessionStorage.getItem("currency_format") && (
                <span
                  className="subtext capitalize"
                  style={{
                    fontSize: "16px",
                    // marginLeft: "80px",
                    // marginTop: "-28px",
                    position: "absolute",
                  }}
                >
                  {" "}
                  (values in {sessionStorage.getItem("currency_format")})
                </span>
              )}
          </Typography>

          <div
            style={{ display: "flex", gap: "10px" }}
            className="marginLeftAuto"
          >
            {/* <FullWidthInput2
              customInputStyle={{
                height: "48px",
                background: "#211839",
                width: "352px",
              }}

            /> */}
            {/* <span
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0px 15px",
              }}
              className="icon-border2"
              onClick={handleExpandAll}
            >
              {expandRow.length > 0 ? (
                <Collapse width={15} height={15} />
              ) : (
                <Expand width={15} height={15} />
              )}

              <span className="expand-text rotate-svg">
                {expandRow.length > 0 ? "Collapse" : "Expand"}
              </span>
            </span> */}
            <span onClick={handleButtonClick} className="icon-border2">
              <TableEditIcon height={"24px"} width={"21px"} />
            </span>
          </div>
        </div>
        <SummaryDetailsTable
          setTableColumns={setNewTableColumns}
          tableColumns={newTableColumns}
          headerList={tableColumnsData}
          tableHeader={tableHeader}
          setTableHeader={setTableHeader}
          setOpenModal={setOpenModal}
          openModal={openModal}
          tableHeaderBGColor="rgb(15 7 36"
          tableHeaderclass={"summaryTableHeader"}
          expandedRows={expandRow}
          newHandleProductExpand={newHandleProductExpand}
          innerTable={innerTable}
          setInnerTable={setInnerTable}
          setInnerTableHeader={setInnerTableHeader}
          innetTableHeader={innetTableHeader}
          bottomTabel={bottomTabel}
          setBottomTabel={setBottomTabel}
          setBottomTableHeader={setBottomTableHeader}
          loading={loading}
          handleProductClick={handleProductClick}
          stickyProductName={true}
          loaderConfig={loaderConfig}
          loaderConfigAssetClass={loaderConfigAssetClass}
          totalRowLoading={totalRowLoading}
          renderArrowButton={true}
        />
        <PageLoader showLoader={loading} />
      </>
    </>
  );
}

export default SummaryDetailsComp;
