const loadingText = "...";
const numberFormatingWithComaOnlyCOnfig = {
  style: "decimal",
};
const currency_format_exception = [
  "nav",
  "gross_rate_per_unit",
  "net_rate_per_unit",
  "rate",
  "grossRatePerUnit",
  "netRatePerUnit",
  // "gst_sst_other_charges",
  "gst_sst_other_charges",
  "brokerage_per_unit",
  "dividend_received",
  "total_transaction_value",
  "totalTransactionValue",
  "corporate_action_value",
  "currentPrice",
  "value",
  "amount",
];
const currencyFormatCheckArray = [
  "investmentValue",
  "investmentAmount",
  "currentValue",
  "amount",
  "nav",
  "netRatePerUnit",
  "grossRatePerUnit",
  "presentValue",
  "weightedAvgCost",
  "presentValue",
  "unRealisedGainLoss",
  "realisedGainLoss",
  "dividendReceived",
  "gross_rate_per_unit",
  "brokerage_per_unit",
  "net_rate_per_unit",
  "total_transaction_value",
  "gst_sst_other_charges",
  "last_traded_price",
  "application_amount",
  "bid_amount_per_share",
  "allotted_price",
  "allotted_amount",
  "refund_amount",
  "brokerage_per_unit",
  "gst_stt_other_charges",
  "dividend_received",
  "accrual_interest_paid",
  "invested_amount",
  "totalTransactionValue",
  "corporate_action_value",
  "currentPrice",
  "performerTotalInvestmentValue",
  "performerPresentValue",
  "performerGainLoss",
  "interestIncome",
  "value",
  "totalCharges",
  "price",
  "withdrawalAmount",
  "totalGainLoss",
  "interestAmount",
  "presentAmount",
];
const roundNumberArray = ["holdingUnits"];
const dateFormatingArray = [
  "transaction_date",
  "application_date",
  "allotment_date",
  "refund_date",
  "issue_open_date",
  "maturity_date",
  "maturityDate",
  "issueOpenDate",
  "transactionDate",
  "startDate",
];
const doNothingArray = [
  "scrip_name",
  "exchange",
  "stock_symbol",
  "isin",
  "advisor",
  "pan",
  "application_no",
  "units",
  "reference_no",
  "account_no",
  "accountNo",
  "performerName",
  "productSubCategory",
  "stockSymbol",
  "folioNo",
  "folio_no",
];

const dividerPMSReportTableHeader = [
  "XIRR",
  "Absolute TWRR",
  "Annualized TWRR",
  "Benchmark",
];

const bankBookDoNothing = ["bank_name", "account_no", "account_type"];
const bankBookCurrencyFormat = ["balance"];
const bankBookPercentage = ["allocation_percentage"];
const percentageArray = [
  "performerPercentage",
  "performerXirr",
  "performerXirr",
];

const stockNavData = [
  { page: "all", title: "All" },
  { page: "gainers", title: "Gainers" },
  { page: "losers", title: "Losers" },
];

module.exports = {
  loadingText,
  numberFormatingWithComaOnlyCOnfig,
  currencyFormatCheckArray,
  roundNumberArray,
  dateFormatingArray,
  doNothingArray,
  stockNavData,
  currency_format_exception,
  percentageArray,
  bankBookDoNothing,
  bankBookPercentage,
  bankBookCurrencyFormat,
  dividerPMSReportTableHeader,
};
