import React, { useEffect } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { ReactComponent as Portfolio } from "../../../assets/clientdashboard/portfolio.svg";
import { ReactComponent as MarketTracker } from "../../../assets/clientdashboard/market-tracker.svg";
import { ReactComponent as NeoNext } from "../../../assets/clientdashboard/neo-next.svg";
import { ReactComponent as Global } from "../../../assets/clientdashboard/global.svg";
import { ReactComponent as Transaction } from "../../../assets/clientdashboard/transaction.svg";
import { ReactComponent as Advisor } from "../../../assets/clientdashboard/advisor.svg";
import { ReactComponent as Crownhead } from "../../../assets/clientdashboard/crown.svg";
import { ReactComponent as Lock } from "../../../assets/clientdashboard/lock.svg";

import "../clientDashboard/ClientDash.css";
import MemberProfile from "./MemberProfile";
import useStyles from "./Clientdashstyle";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { OnboardingContext } from "../../../Context";
import { routes } from "../../../routes";

function HomePage() {
  const location = useLocation();

  const classes = useStyles();
  const navigate = useNavigate();
  const [memberName, setMemberName] = useState("");

  const handleClick = () => {
    navigate("/home/member/detail");
  };

  const {
    userState: { loginedUserData },
  } = OnboardingContext();

  function initials(memberName) {
    if (!memberName) {
      return;
    }
    let memberNameArr = memberName?.split(" ");
    // if no surname is provided
    if (memberNameArr.length == 1) {
      let firstNameInitial = memberNameArr[0][0];
      return firstNameInitial;
    } else {
      let firstNameInitial = memberNameArr[0][0];
      let lastNameInitial = memberNameArr[1][0];
      return firstNameInitial + lastNameInitial;
    }
  }

  const handleClickStrategy = () => {
    // navigate(routes?.strategyBuilder + "/target-portfolio");
    navigate(routes?.strategyBuilderTargetPortfolio);
  };

  return (
    <div style={{ display: "flex", overflow: "hidden" }}>
      <div style={{ width: "75%" }}>
        <div>
          <h2
            className={classes.colorfff}
            style={{ marginLeft: "3%", marginTop: "5%" }}
          >
            Hi {loginedUserData?.name} 👋{" "}
          </h2>
          <p style={{ marginLeft: "3%", color: "#FFFFFF" }}>
            Welcome to your one stop solution for all your wealth management and
            investment reporting needs.
          </p>
        </div>
        {/* TODO Remove hardcoded Check once API integration is Done */}

        <div>
          <h2
            className={classes.colorfff}
            style={{ marginLeft: "3%", marginTop: "5%" }}
          >
            Dashboard
          </h2>
        </div>

        {/* TODO Remove hardcoded Check once API integration is Done */}

        <div className="gridBoxContainer">
          <div onClick={handleClick} className="dashCard">
            <Portfolio></Portfolio>
            <Typography className={classes.cardTitleNew}>
              My Portfolio
            </Typography>
          </div>

          <div className="dashCard">
            <div className="disabled"></div>
            <Lock className="lockIcon" width={"150px"} height={"170px"} />
            <MarketTracker></MarketTracker>
            <Typography className={classes.cardTitleNew}>
              Market Tracker
            </Typography>
          </div>
          <div style={{ overflow: "hidden" }} className="dashCard">
            <div className="disabled"></div>
            <Lock className="lockIcon" width={"150px"} height={"170px"} />
            <NeoNext></NeoNext>
            <Typography className={classes.cardTitleNew}>Neo Next</Typography>
          </div>
          <div className="dashCard" onClick={handleClickStrategy}>
            <Global></Global>
            <Typography className={classes.cardTitleNew}>
              Strategy Builder
            </Typography>
          </div>
          <div className="dashCard">
            <div className="disabled"></div>
            <Lock className="lockIcon" width={"150px"} height={"170px"} />
            <Transaction
              style={{ display: "block", width: "100%", height: "140px" }}
            ></Transaction>
            <Typography className={classes.cardTitleNew}>
              Transaction
            </Typography>
          </div>
          <div className="dashCard">
            <div className="disabled"></div>
            <Lock className="lockIcon" width={"150px"} height={"170px"} />
            <Advisor></Advisor>
            <Typography className={classes.cardTitleNew}>Advisor</Typography>
          </div>
        </div>
      </div>

      {/* TODO Remove hardcoded Check once API integration is Done */}

      <div style={{ width: "25%" }}>
        <MemberProfile
          familyHead={memberName}
          crownComponent={
            <div
              className="crownComp"
              style={{ position: "relative", left: "8%" }}
            >
              <Crownhead style={{ width: "80%", height: "80%" }}> </Crownhead>
              <div
                className="big-avatar-design"
                style={{
                  position: "absolute",
                  width: "95%",
                  // height: "80%",
                  top: "30%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <span
                  style={{
                    fontSize: "18px",
                    marginBottom: "3px",
                    marginRight: "15px",
                  }}
                >
                  {memberName && initials(memberName)}
                </span>
              </div>
            </div>
          }
          setMemberName={setMemberName}
        ></MemberProfile>
      </div>
    </div>
  );
}

export default HomePage;
