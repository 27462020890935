import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { dateFormatCommon, roundNumber } from "../../../../utils/common";
import useStyles from "../../common/ProductsStyles";
import { pie_onClick, pie_onClick3 } from "../../../../charts/nivo_objects";
import PieChart from "../../../../charts/PieChart";
import "../../../../dashboard/products/mfo/utils/commoncss.css";

function MarketCapPieChart({
  riskDetails,
  marketCapState,
  setMarketCapState,
  marketCapColors,
}) {
  const classes = useStyles();

  return (
    <div className={classes.mainContent}>
      <Typography
        style={{ margin: "1.5% 1%" }}
        className={`${classes.riskHeaderSub}`}
      >
        Market Cap Weightage
      </Typography>
      <div className="dFlex gap10 alignItemsCenter">
        <div
          style={{
            width: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: "49px",
          }}
        >
          <div
            // style={{ width: "50%" }}
            style={{ width: "460px", height: "300px" }}
          >
            {/* <PieChart
            config={{
              innerRadius: "0.50",
              padAngle: 5,
              legends: false,
              minWith: "100%",
              margin: { top: 40, right: 80, bottom: 80, left: 80 },
              arcLinkLabel: (d) => `${d.value}%`,
              // margin: { right: 50, top: 50 },

              chart: { height: 420 },
            }}
            // colors={marketCapState.colors}
            colors={riskDetails?.marketCapAllocation
              .filter((val) => val.value !== 0)
              .map((val) => val.color)}
            onClickEvent={{
              click: pie_onClick,
              set: setMarketCapState,
              cstate: marketCapState,
              ncolors: marketCapColors,
            }}
            data={riskDetails?.marketCapAllocation.filter(
              (val) => val.value !== 0
            )}
          ></PieChart> */}
            <PieChart
              config={{
                innerRadius: "0.80",
                cornerRadius: 0,
                padAngle: 0,
                legends: false,
                disableArcLink: true,
                minWith: "100%",
                // arcLinkLabel: (d) => `${d.value}%`,
                // margin: { right: 80, top: 20, left: 80 },
                height: "300px",

                chart: { height: 385 },
              }}
              colors={riskDetails?.marketCapAllocation
                .filter((val) => val.value !== 0)
                .map((val) => val.color)}
              onClickEvent={{
                click: pie_onClick3,
                set: setMarketCapState,
                cstate: marketCapState,
                ncolors: marketCapColors,
              }}
              data={riskDetails?.marketCapAllocation.filter(
                (val) => val.value !== 0
              )}
            >
              <div
                className="cen1"
                style={{
                  color: "#fff",
                  transform: "translate(-56px, 0%)",
                }}
              >
                <div className="cir">
                  <div className="glowcircle glowcircle2">
                    <div className="circleInnerText">
                      <p className="innerCircleTitle">{marketCapState.title}</p>
                      <p className="innerCircleValue">
                        {marketCapState?.value &&
                          roundNumber(marketCapState?.value) + "%"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </PieChart>
          </div>
        </div>
        <div style={{ width: "50%" }}>
          <div style={{ display: "flex" }}>
            <Grid container spacing={3}>
              {riskDetails?.marketCapAllocation?.map((m, i) => {
                if (m.value !== 0)
                  return (
                    <Grid item xs={6}>
                      <div className="llabeContainer">
                        <div className="lsymbol">
                          <svg height="25" width="25">
                            <circle
                              cx="12"
                              cy="12"
                              r="5"
                              stroke-width="5"
                              fill={marketCapColors[i]}
                            />
                            Sorry, your browser does not support inline SVG.
                          </svg>
                        </div>

                        <div className="llabel">
                          <span className="newLabelText">{m?.label}</span>{" "}
                          <div className="newLabelValue">
                            {roundNumber(m.value)}%
                          </div>
                        </div>
                      </div>
                    </Grid>
                  );
              })}
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MarketCapPieChart;
