import React, { useEffect } from "react";
import useStyles from "../common/ProductsStyles";
import {
  FormControlLabel,
  FormGroup,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { ReactComponent as Import } from "../../../../assets/icons/import.svg";
import { ReactComponent as Export } from "../../../../assets/icons/export.svg";
import { ReactComponent as Print } from "../../../../assets/icons/print.svg";
import { ReactComponent as UpArrow } from "../../../../assets/icons/upArrow.svg";
import { getTransactionsSummary } from "../../../../api";
import { useState } from "react";
import PageLoader from "../common/pageLoader";
import { OnboardingContext } from "../../../../Context";
import TableContainer from "@material-ui/core/TableContainer";
import DataNotFound from "../common/DataNotFound";
import moment from "moment";
import { currencyFormat } from "../../../../hooks/constantFunction";
import { dateFormatCommon } from "../../../utils/common";
import Filtericon from "../../common/Filtericon";
import { IosSwitch } from "../../../IosSwitch";

function PmsPortfolio({ mfoFilter, getfilter_as_params }) {
  const classes = useStyles();
  const [result, setResult] = useState({ asOnDate: "", summary: [] });
  const [loading, setLoading] = useState(true);
  const { userState } = OnboardingContext();
  const [toggle, settoggle] = useState(false);
  const [sortOrderDetails, setSortOrderDetails] = useState({
    field: "",
    order: false,
  });
  const [showfilters, setShowfilter] = useState(false);

  const getTaableData = async () => {
    setLoading(true);

    // let a = getfilter_as_params()
    try {
      // userState.loginedUserData.id
      let params = {
        clientId: userState.loginedUserData.id,
        ...getfilter_as_params(),
      };
      let respose;

      // if (toggle) {
      respose = await getTransactionsSummary(
        "pms",
        "current-holding-performance-summary",
        {
          ...params,
        }
      );
      // } else {
      //   respose = await getTransactionsSummary(
      //     "pms",
      //     "performance-summary",
      //     {
      //       ...params,
      //     }
      //   );
      // }

      // let respose = await getTransactionsSummary("pms", "performance-summary", {
      //   ...params,
      // });
      setResult(respose?.data?.data?.result);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getTaableData();
  }, [mfoFilter, toggle]);

  const tableHead = [
    { value: "Product Name", field: "productName" },
    { value: "Latest Valuation", field: "presentValue" },
    { value: "1 Month", field: "oneMonth" },
    { value: "3 Month", field: "threeMonths" },
    { value: "6 Month", field: "sixMonths" },
    { value: "1 year", field: "twelveMonths" },
    { value: "Amount Invested", field: "investmentValue" },
  ];
  const commonTableBody = [
    "oneMonth",
    "threeMonths",
    "sixMonths",
    "twelveMonths",
  ];

  const sort = (columnName, order) => {
    let sortFunction;
    if (columnName !== "presentValue" && columnName !== "investmentValue") {
      sortFunction =
        order == false
          ? (a, b) =>
              Number(b[columnName].percentage) -
              Number(a[columnName].percentage)
          : (a, b) =>
              Number(a[columnName].percentage) -
              Number(b[columnName].percentage);
    } else {
      sortFunction =
        order == false
          ? (a, b) => Number(b[columnName]) - Number(a[columnName])
          : (a, b) => Number(a[columnName]) - Number(b[columnName]);
    }

    setResult((prev) => {
      return { ...prev, summary: result.summary.sort(sortFunction) };
    });
  };

  return (
    <>
      <div className={classes.mainContent}>
        <div style={{ display: "flex", margin: "2%", alignItems: "center" }}>
          <Typography
            style={{ cursor: "pointer" }}
            className={classes.titleText}
          >
            Performance Summary <br />
            <span style={{ fontSize: "16px" }}>
              As on {dateFormatCommon(result?.asOnDate)}
            </span>
            {sessionStorage.getItem("currency_format") != "actuals" &&
              sessionStorage.getItem("currency_format") && (
                <span
                  className="subtext capitalize"
                  style={{ fontSize: "16px" }}
                >
                  {" "}
                  (values in {sessionStorage.getItem("currency_format")})
                </span>
              )}
          </Typography>
          {/* {
            <FormGroup style={{ marginLeft: "auto" }}>
              <FormControlLabel
                control={
                  <IosSwitch
                    checked={toggle ? toggle : false}
                    setChecked={settoggle ? settoggle : () => {}}
                  />
                }
                labelPlacement="start"
                style={{
                  fontFamily: "Avenir",
                  fontWeight: 900,
                  fontSize: "16px",
                  letterSpacing: "1.5px",
                  textTransform: "uppercase",
                  color: "#FFFFFF",
                  opacity: 0.9,
                  marginRight: "12px",
                  marginLeft: "auto",
                }}
              />
            </FormGroup>
          } */}
          <Import
            className={classes.iconStyle}
            style={{ cursor: "pointer", width: "50px", marginLeft: "auto" }}
          />

          <Export
            className={classes.iconStyle}
            style={{ cursor: "pointer", width: "50px" }}
          />
          <Print
            className={classes.iconStyle}
            style={{ cursor: "pointer", width: "50px" }}
          />
        </div>
        <div
          style={{
            minHeight: "300px",
            overflowY: "hidden",
            width: "-webkit-fill-available",
            margin: "2%",
            display: "flex",
            maxHeight: "400px",
            position: "relative",
          }}
        >
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead
                style={{
                  background: "#1D1B36",
                  borderRadius: "20px",
                  color: "#A468FF",
                }}
                className=" tableSticky"
              >
                <TableRow className="tableRowStyle">
                  {tableHead.map((val) => (
                    <TableCell
                      style={{
                        borderBottom: "none",
                        background: "#1D1B36",
                        zIndex: val.value == "Product Name" ? "1000" : "",
                      }}
                      className={`${classes.tableHeadText} thStyle ${
                        val.value == "Product Name" ? "stickyCellHed" : ""
                      }`}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent:
                            val?.value == "Product Name"
                              ? "flex-start"
                              : "flex-end",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        <span style={{ color: "#A468FF" }}>{val?.value}</span>
                        {val.value !== "Product Name" && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <UpArrow
                              className={
                                sortOrderDetails.field == val?.field &&
                                sortOrderDetails.order == true
                                  ? "svgSelected"
                                  : ""
                              }
                              width={"10px"}
                              height={"10px"}
                              onClick={() => {
                                sort(`${val?.field}`, true);
                                setSortOrderDetails((prev) => {
                                  return {
                                    ...prev,
                                    field: val?.field,
                                    order: true,
                                  };
                                });
                              }}
                            />
                            <UpArrow
                              className={
                                sortOrderDetails.field == val?.field &&
                                sortOrderDetails.order == false
                                  ? "svgSelected"
                                  : ""
                              }
                              height={"10px"}
                              width={"10px"}
                              style={{
                                transform: "rotate(180deg)",
                              }}
                              onClick={() => {
                                sort(`${val?.field}`, false);
                                setSortOrderDetails((prev) => {
                                  return {
                                    ...prev,
                                    field: val?.field,
                                    order: false,
                                  };
                                });
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className="tableBodyPms tableSticky">
                <TableRow
                  style={{
                    height: "10px",
                  }}
                ></TableRow>
                {result.summary.map((val) => (
                  <TableRow>
                    <TableCell
                      style={
                        {
                          // background: "#110425",
                        }
                      }
                      align="left"
                      className={`${
                        classes.tableBodyText
                      } tdPms ${"stickyCellHed"}`}
                    >
                      {val?.productName}
                    </TableCell>
                    <TableCell align="right" className={classes.tableBodyText}>
                      {currencyFormat(val?.presentValue)}
                    </TableCell>
                    {commonTableBody.map((val2) => (
                      <TableCell
                        align="right"
                        style={
                          val?.[`${val2}`]?.percentage == "" ||
                          val?.[`${val2}`]?.percentage == "0.00"
                            ? {
                                color: `#ffffff`,
                                fontFamily: "Avenir",
                                fontStyle: "normal",
                                fontWeight: "800",
                                fontSize: "16px",
                              }
                            : val?.[`${val2}`]?.percentage.includes("-")
                            ? {
                                color: `#E96114`,
                                fontFamily: "Avenir",
                                fontStyle: "normal",
                                fontWeight: "800",
                                fontSize: "16px",
                              }
                            : {
                                color: `rgb(0, 183, 139)`,
                                fontWeight: "800",
                                fontFamily: "Avenir",
                                fontStyle: "normal",
                                fontSize: "16px",
                              }
                        }
                      >
                        {currencyFormat(val?.[`${val2}`]?.amount)}
                        <br />
                        <span className={classes.percentageTableValue}>
                          {Number(val?.[`${val2}`]?.percentage).toFixed(2) +
                            "%" || "--"}
                        </span>
                      </TableCell>
                    ))}
                    <TableCell align="right" className={classes.tableBodyText}>
                      {currencyFormat(val?.investmentValue)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {result.summary?.length == 0 && (
            <div
              style={{
                position: "absolute",
                left: "44%",
                top: "14%",
              }}
            >
              {!loading && <DataNotFound />}
            </div>
          )}
        </div>
      </div>
      <PageLoader showLoader={loading} />
    </>
  );
}

export default PmsPortfolio;
