import React from "react";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import "./NewFilter.css";
import { newFilterMainTab } from "./newFilterConstants";
import { useContext } from "react";
import { mfoFilterContext } from "../../../mfo_context/Mfo_Context";
import { assetClassConfig } from "./config/newFilterAssetClassConfig";
import {
  formatAdvisor,
  formatDenominationDisplay,
  formatExecutor,
  formatSelectedFamilyMembers,
  formatSelectedPeriod,
} from "./utils/newFilterFormatter";
import { get_path_type } from "../../utils/strHelper";
import { useLocation } from "react-router-dom";
import { useTheme } from "@material-ui/core";

export const NewFilterFooter = ({
  handleMainTabClick,
  currentTabIndex,
  selectedFamilyMembers,
  selectedAdvisors,
  selectedExecutors,
  selectedAssetClasses,
  isPromoterStakeSelected,
  selectedDenomination,
  selectedPeriod,
  setFilterModalOpen,
  dateRange,
  setTriggerFetchExecutedBy,
  setTriggerFetchAdvisors,
  productType,
  disabled,
}) => {
  const { setMfofilter, mfoFilter } = useContext(mfoFilterContext);
  const location = useLocation();
  const theme = useTheme();

  const handleNextTabBtnClick = () => {
    const nextTabElement = getNextTab();
    if (nextTabElement === "Advisors") {
      setTriggerFetchAdvisors(true);
    }
    if (nextTabElement === "Executed By") {
      setTriggerFetchExecutedBy(true);
    }
    if (nextTabElement !== null) {
      handleMainTabClick(nextTabElement);
    } else {
      // Set Currency Format in LocalStorage
      localStorage.setItem(
        "currency_format",
        formatDenominationDisplay(selectedDenomination)
      );
      // Filter out the false values from selectedAssetClasses
      const filteredAssetClasses = Object.entries(selectedAssetClasses).reduce(
        (acc, [key, value]) => {
          if (value) {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );
      let save = {
        checkData: formatSelectedFamilyMembers(selectedFamilyMembers),
        advisor: formatAdvisor(selectedAdvisors),
        executor: formatExecutor(selectedExecutors),
        currency: "Rupees",
        promoterStake: isPromoterStakeSelected === false ? 0 : 1,
        period: formatSelectedPeriod(selectedPeriod),
        startDate: dateRange[0],
        endDate: dateRange[1],
        selectedPeriod: selectedPeriod,
        assetclass: {
          ...assetClassConfig,
          // [get_path_type(location.pathname)]: { ...selectedAssetClasses },
          [get_path_type(location.pathname)]: filteredAssetClasses,
        },
      };
      console.log("saveed data", save);
      sessionStorage.setItem("filter", JSON.stringify(save));
      let sv = JSON.stringify(save);
      setMfofilter({ ...mfoFilter, ...JSON.parse(sv) });

      // Handle the last tab close the modal or api call
      setFilterModalOpen(false);
    }
  };

  const getNextTab = () => {
    const nextIndex = currentTabIndex + 1;
    if (nextIndex < newFilterMainTab(productType).length) {
      return newFilterMainTab(productType)[nextIndex].page;
    }
    // Return null or an empty string when the last tab is reached
    return null;
  };
  const isLastTab =
    currentTabIndex === newFilterMainTab(productType).length - 1;
  return (
    <DialogActions
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: theme.palette.newFilter.newFilterFooter,
        padding: "0.75rem",
      }}
    >
      <Button
        disabled={disabled}
        disableRipple
        disableElevation
        variant="contained"
        onClick={handleNextTabBtnClick}
        color="primary"
        style={{
          borderRadius: "7px",
          background: theme.palette.newFilter.newFilterFooterBtn,
          width: "101px",
          color: theme.palette.newFilter.newFilterFooterBtnTxt,
        }}
      >
        {isLastTab ? "Save" : "Next"}
      </Button>
    </DialogActions>
  );
};
