import { useState, useEffect, useContext } from "react";
import { OnboardingContext } from "../Context";
import { fetchIpoDetails } from "../api";
import { mfoFilterContext } from "../mfo_context/Mfo_Context";
import moment from "moment";

export const useIpoDetails = (type) => {
  const { userState } = OnboardingContext();
  const { mfoFilter } = useContext(mfoFilterContext);
  const [ipo, setIpo] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let params = type;

    setLoading(true);
    fetchIpoDetails(params)
      .then((response) => {
        const apiData = response?.data?.data?.result;

        setIpo(apiData);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(`An Error ${err} occurred`);
      });
  }, [type]);
  return [ipo, loading];
};
