import React from "react";
import { ReactComponent as Subtract } from "../../../../assets/icons/subtract.svg";

import useStyles from "../common/ProductsStyles";
import {
  Card,
  Grid,
  div,
  Typography,
  Button,
  useTheme,
} from "@material-ui/core";
import { ReactComponent as Back } from "../../../../assets/holding-summary/back-blue.svg";
import { ReactComponent as Arrow } from "../../../../assets/icons/list_arrow.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Default from "../../defaultPages/Default";
import TabInsuranceUlip from "../common/TabInsuranceUlip";
import TabInsuranceMain from "../common/TabInsuranceMain";
import TabEquityPriceChart from "../common/TabEquityPriceChart";
import { useState } from "react";
import TabEquityPrice from "../common/TabEquityPrice";
import Minnav from "../../../common/minnav";
import { useMemo } from "react";
import EquityPriceChartVolume from "./EquityPriceChartVolume";
import EquityPriceChartDividend from "./EquityPriceChartDividend";
// import Layerschart from "../../../charts/Layerschart";
import BarChart from "../../../charts/BarChart";
import { tooltip } from "../../../charts/barTooltip";
import { divergingCommonProps } from "../../../charts/nivo_objects";
import { routes } from "../../../../routes";
// import { Button } from "bootstrap";
// import Default from '../components/dashboard/defaultPages/Default'

export default function EquityPriceChart() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  console.log(params.id);
  const [title, setTitle] = useState("Dividend & Bonus");
  const [display, setDisplay] = useState(false);
  const [display1, setDisplay1] = useState(false);
  const [moreTab, setMoreTab] = useState("Sales and Margin");
  const theme = useTheme();
  const icons = theme.palette.icons;
  const Bulb = icons.bulbIcon;
  const query = new URLSearchParams(location.search);

  const current = query.get("selected");
  console.log(current, typeof current);

  const replaceStr = (str) => {
    str = str.replace(/XA/g, " ");
    str = str.replace(/YB/g, "&");
    return str;
  };

  let diverging = {
    ...divergingCommonProps,
    minValue: 0,
    maxValue: 100,
    indexBy: "period",
    borderRadius: "3px",
  };

  useMemo(() => {
    if (params.id == "price-chart") {
      setTitle(
        current ? "Price Chart : " + replaceStr(current) : "Price Chart"
      );
    } else if (params.id == "more") {
      setMoreTab(current);
      setTitle(current);
    } else {
      setTitle(params.id);
    }
  }, [current, params.id]);

  const switchPage = (page) => {
    switch (page) {
      case "price-chart":
        if (current == "Dividend") {
          return <EquityPriceChartDividend />;
        } else {
          return <EquityPriceChartVolume />;
        }

      default:
        break;
    }
  };

  return (
    <Default>
      <div className={`${classes.alignTopHeader}`}>
        <Back
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate(-1);
          }}
        />
        <Typography
          className={`${classes.mainHeading} ${classes.mainHeadingAlign}`}
        >
          {/* {switchPage(params.id)} */}
          {title}
        </Typography>
        {/* <TabEquityPrice /> */}
        {/* <TabEquityPriceChart setTitle={setTitle} /> */}
        <div
          style={{
            margin: "2%",
            cursor: "pointer",
            alignItems: "center",
            position: "relative",
            whiteSpace: "nowrap",
          }}
          className={classes.tabMfo + " minvav"}
        >
          <Minnav
            options={[{ name: "Dividend & Bonus" }, { name: "Volume" }]}
            display={display}
            setDisplay={setDisplay}
          ></Minnav>
          <Minnav
            options={[{ name: "EV/EBITDA" }, { name: "Market/Cap sales" }]}
            display={display1}
            setDisplay={setDisplay1}
          ></Minnav>
          <Button
            className={classes.tabHeaderText + " zi2 circle_text3"}
            onClick={() => {
              if (params.id === "price-chart") {
                setDisplay(true);
              } else {
                navigate(routes?.equityViewChartPieChart);
              }
            }}
          >
            {" "}
            Price <Arrow style={{ paddingLeft: "10px" }} />
          </Button>
          <Button
            className={classes.tabHeaderText + " zi2 circle_text3"}
            onClick={() => navigate(routes?.equityViewChartPeRatio)}
          >
            {" "}
            PE Ratio
          </Button>
          <Button
            className={classes.tabHeaderText + " zi2 circle_text3"}
            onClick={() => navigate(routes?.equityViewChartSaleMargin)}
          >
            {moreTab}
          </Button>
          <Button
            className={classes.tabHeaderText + " zi2 circle_text3"}
            onClick={() => setDisplay1(true)}
          >
            {" "}
            More <Arrow style={{ paddingLeft: "10px" }} />
          </Button>
          <div
            style={{
              width: "100%",
              height: "100%",
              margin: "-10px",
              cursor: "pointer",
              alignItems: "center",
              position: "absolute",
            }}
            className={classes.tabMfo}
          ></div>
          {/* <div style={{ width: "300px", height: "300px", margin: "-10px", cursor: "pointer", alignItems: "center", position: "absolute" }}>
          <BarChart tooltip={tooltip} keys={["AA","AAA","BB","BBB"]} config={{ height: "400px",padding:0.6 }} commonprops={diverging} />
          </div> */}
          {/* <Layerschart/> */}
        </div>
      </div>
      <h1 style={{ color: "#FFF" }}> Graph here</h1>
    </Default>
  );
}
