import { useState } from "react";
import { createPin } from "../../../api";

export const useCreatePin = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const setPin = async (pin, entityType, userId, handleNext) => {
    // PAYLOAD
    const createPinData = {
      //   TODO Remove hardcoded values
      userId: userId,
      pin: pin,
    };
    setShowLoader(true);

    try {
      const data = await createPin(createPinData, entityType);
      const pinData = data.data;

      await handleNext(1);

      // if (pinData.success) {
      setShowLoader(false);
      // }
    } catch (error) {
      setShowLoader(false);
      console.log("An error occurred", error);
      setApiErrorMessage(error?.response?.data?.error?.message);

      // if (!error.response.data.error.success) {
      //   dispatch({
      //     type: "UPDATE_SNACKBAR_DATA",
      //     payload: {
      //       success: false,
      //       error: true,
      //       showMsg: true,
      //       message: error.response.data.error.message,
      //     },
      //   });
      // }
    }
  };
  return { setPin, showLoader, apiErrorMessage };
};
