import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  useTheme,
  Tooltip,
  Zoom,
  Box,
} from "@material-ui/core";
import "../../../ProductUploadValidation/ProductUploadValidation.css";
import { fetchTop5Amc } from "../../../../api";
import { OnboardingContext } from "../../../../Context";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import { currencyFormat } from "../../../../hooks/constantFunction";
import { roundNumberWithoutDecimal } from "../../../utils/common";
import PageLoader from "../common/pageLoader";
import SummaryDetailsSectionWrapper from "../newDesigns/common/SummaryDetailsSectionWrapper";
import { summaryDetailsTableConfigTOP5Issuers } from "../utils/constands/summaryDetailsSec";
import { useTop5amcIssuer } from "../../../../hooks/useTop5amc";
import {
  colorAndProdBank,
  prodAndKeyBank,
} from "../utils/constands/prodAndColor";
import PieChart from "../../../charts/PieChart";
import GlowCircle from "../../../charts/GlowCircle";
import useStyles from "../common/ProductsStyles";
import { pie_onClick2 } from "../../../charts/nivo_objects";
import { colors_allocation } from "../../../charts/colors";
import { useTop5Issuer } from "../../../../hooks/useTop5Issuer";
export const HoldingReportTop5Issuers = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { userState } = OnboardingContext();
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  let clientId = userState?.loginedUserData?.id;
  const [loading, setLoading] = useState(true);
  const [piecolors, setPieColors] = useState(colors_allocation);
  const [pieData, setPieData] = useState([]);
  const [pie, setPie] = useState({
    title: "",
    value: "",
    colors: piecolors,
    disabled: [],
    hide: false,
  });

  const { totalData, topData, color } = useTop5Issuer({
    setPieData,
    setLoading,
  });

  return (
    <>
      <PageLoader showLoader={loading} />
      <SummaryDetailsSectionWrapper
        summaryDetailsSecConstData={summaryDetailsTableConfigTOP5Issuers}
        cardData1={topData}
        hideNavButtons={true}
        customColorAndProd={colorAndProdBank}
        customProdAndKey={prodAndKeyBank}
        truncTitle={true}
        peiChart={
          <div className="flex_center tr20" style={{ height: "100%" }}>
            <div className={classes.wid380px}>
              <PieChart
                colors={pie.colors}
                fill={pie.fill}
                config={{
                  innerRadius: "0.80",
                  padAngle: 0,
                  legends: false,
                  minWith: "380px",
                  disableArcLink: true,
                  cornerRadius: 0,
                  chart: {
                    width: 440,
                    height: 420,
                    arcLinkLabelsStraightLength: 20,
                    arcLinkLabelsDiagonalLength: 20,
                  },

                  arcLinkLabel: (d) => (
                    <tspan
                      x="0"
                      y="0"
                      style={{
                        fontFamily: "Avenir",
                        fontWeight: "400",
                        fontSize: "12",
                        textTransform: "capitalize",
                      }}
                    >
                      {" "}
                      {d.id}
                      <tspan
                        x="0"
                        y="18"
                        style={{
                          fontFamily: "Open Sans",
                          fontWeight: "700",
                          fontSize: "14",
                        }}
                      >
                        {" "}
                        {d.value}%
                      </tspan>
                    </tspan>
                  ),
                }}
                onClickEvent={{
                  click: pie_onClick2,
                  set: setPie,
                  cstate: pie,
                  ncolors: piecolors,
                }}
                data={pieData}
              >
                <GlowCircle>
                  <div className="circle_content">
                    <div>
                      <b className="circle_text">
                        {pie.title && pie.value != null
                          ? pie.title
                          : "Total balance"}
                      </b>
                      <br></br>
                      <b className="circle_text2">
                        {pie.amount
                          ? "" + currencyFormat(pie.amount)
                          : "" + totalData}
                      </b>
                    </div>
                  </div>
                </GlowCircle>
              </PieChart>
            </div>
          </div>
        }
      />
    </>
  );
};
