import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Logout from "./components/auth/Logout";
//import SingleClientDetail from "./components/dashboard/partner-client-list/SingleClientDetail";
//import SinglePartnerDetail from "./components/dashboard/partner-client-list/SinglePartnerDetail";
import MutualFundSummary from "./components/dashboard/products/mfo/MutualFundSummary";
import RiskDetails from "./components/dashboard/products/mfo/RiskDetails";
import { mfoFilterContext } from "./mfo_context/Mfo_Context";
// import MFOSummary from './components/dashboard/products/mfo/SummaryDetails';
import Context from "./Context";
//import ClientList from "./pages/ClientList";
import Home from "./pages/Home";
import ForgotPassword from "./pages/ForgotPassword";
import HoldingSummary from "./pages/HoldingSummary";
//import PartnerList from "./pages/PartnerList";
import ProductUpload from "./pages/ProductUpload";
import ProtecatedUrl from "./pages/ProtecatedUrl";
import SignIn from "./pages/SignIn";
//import SignUp from "./pages/SignUp";
import SummaryDetails from "./pages/SummaryDetails";
import VerificationUpload from "./pages/VerificationUpload";
import ClientMemberDetails from "./pages/ClientMemberDetails";
import Box from "./components/charts/Box";
import ClientAllMembers from "./pages/ClientAllMembers";
import MfoSummaryDetails from "./pages/MfoSummaryDetails";
//import ClientMemberList from "./pages/ClientMemberList";
import CardnewDesign from "./pages/CardnewDesign";
import PmsSummary from "./pages/PmsSummary";
import PmsSummaryDetails from "./pages/PmsSummaryDetails";
import AifSummary from "./pages/AifSummary";
import AifReport from "./components/dashboard/products/aif/AifReport";
import AifSummaryDetails from "./pages/AifSummaryDetails";
import RealEstate from "./pages/RealEstate";
import RealEstateDetails from "./pages/RealEstateDetails";
import InsuranceDetails from "./pages/InsuranceDetails";
import Insurance from "./pages/Insurance";
import FamilyStructure from "./pages/FamilyStructure";
import UserManagement from "./pages/UserManagement";
import Mfopages from "./pages/Mforoutes";
import Pmspages from "./pages/Pmsroutes";
import Aifpages from "./pages/Aifroutes";
import VerifyEmailMobile from "./pages/VerifyEmailMobile";
import InsuranceRoute from "./pages/InsuranceRoute";
import RealEstateRoute from "./pages/RealEstateRoute";
import ScrollToTop from "./components/helper/ScrollToTop";
import BondsDetails from "./pages/BondsDetails";
import Bonds from "./pages/Bonds";
import MfoSummary from "./pages/MfoSummary";
import Equity from "./pages/Equity";
import EquityDetails from "./pages/EquityDetails";
import EquityStockDetailsPage from "./pages/EquityStock";
import IpoHolding from "./components/dashboard/products/equity/ipo/IpoHolding";
import EquityPriceChart from "./components/dashboard/products/equity/EquityPriceChart";
import Bondpages from "./pages/Bondpages";
import EquityIPO from "./components/dashboard/products/equity/ipo/EquityIPO";
import BondsDetailsPage from "./components/dashboard/products/bonds/BondsDetailsPage";
import Govbond from "./components/dashboard/products/bonds/Govbond";
import Corporatebond from "./components/dashboard/products/bonds/Corporatebond";
import BondsPage from "./components/dashboard/products/bonds/BondsPage";
import BondsDetailsReport from "./components/dashboard/products/bonds/BondsDetailsReport";
import Equitypages from "./pages/Equitypages";
import FamilyStructureComp from "./components/family_tree/FamilyStructureComp";
import { ORGD3Chart } from "./components/family_tree/ORGD3Chart";
import { json, selectAll } from "d3";
import HoldingPeriodDetails from "./pages/HoldingPeriodDetails";
import PrivateMarketSummaryComp from "./components/dashboard/products/private_markets/PrivateMarketSummaryComp";
import PrivateMarketSummary from "./pages/PrivateMarketSummary";
import PrivateMarketPages from "./pages/PrivateMarketPages";
import PrivateMarketDetails from "./pages/PrivateMarketDetails";
import StructuredProductPages from "./pages/StructuredProductPages";
import StructuredProductSummary from "./pages/StructuredProductSummary";
import StructuredProductDetails from "./pages/StructuredProductDetails";
import MLDRoutes from "./pages/MLDRoutes";
import MLDSummary from "./pages/MLDSummary";
import MLDReportDetails from "./pages/MLDReportDetails";
import MastersList from "./pages/MastersList";
import MasterProducts from "./pages/MasterProducts";
import AddMasterProducts from "./pages/AddMasterProducts";
import REITSRoutes from "./pages/REITSRoutes";
import REITSSummary from "./pages/REITSSummary";
import REITSReportDetails from "./pages/REITSReportDetails";
import INVITSRoutes from "./pages/INVITSRoutes";
import INVITSSummary from "./pages/INVITSSummary";
import INVITSReportDetails from "./pages/INVITSReportDetails";

import { lazy } from "react";
import Loader from "./components/loader/Loader";
import { Suspense } from "react";

import Overall_portfolio from "./components/dashboard/print/pages/Overall_portfolio";
import Portfolio_Performance from "./components/dashboard/print/pages/Portfolio_Performance";
import Pdf from "./components/dashboard/print/pdf";
import ViewPdf from "./components/dashboard/print/viewPdf";
import Top5issues from "./components/dashboard/print/pages/Top5issues";
import ComponentToCanvas from "./components/dashboard/print/pages/ComponentToCanvas";
import { StackBarChart } from "./components/dashboard/print/Components/StackBarChart";
import { StackTable } from "./components/dashboard/print/Components/StackTable";
import { FirstPage } from "./components/dashboard/print/Components/FirstPage";
import { LastPage } from "./components/dashboard/print/Components/LastPage";
import {
  HEAD_OF_FAMILY,
  SYSTEM_ADMINISTRATOR,
  BANKER,
  ADMIN_OPERATIONS_HEAD,
  COO,
  INDIVIDUAL,
  NON_INDIVIDUAL,
  LIAISON_OFFICER,
  ADMIN_OPERATIONS,
  PARTNER_REPRESENTATIVE,
} from "./components/auth/permission-maps";
import { routes } from "./routes";
import ICDRoutes from "./pages/ICDRoutes";
import ICDSummary from "./pages/ICDSummary";
import ICDDetailes from "./pages/ICDDetailes";
import StrategyBuilder from "./pages/StrategyBuilder";
import StrategyBuilderStep3 from "./components/dashboard/strategyBuilder/uniqueCLientIndex/StrategyBuilderStep3";

import AddMemberModal from "./components/dashboard/products/common/modal_add_member/AddMemberModal";
import NewSignUp from "./components/NewSignUp/NewSignUp";
import ClientNewSignUp from "./components/NewSignUp/clientSignUp/ClientNewSignUp";
import UserManagementEdit from "./pages/UserManagementEdit";
import NewClientListPage from "./pages/NewClientListPage";
import NewClintToUserManagement from "./pages/NewClintToUserManagement";
import NewPartnerList from "./components/newPartnerList/NewPartnerList";
import NewPartnerListPage from "./pages/NewPartnerListPage";
import ClientPendingDetailsPage from "./pages/ClientPendingDetailsPage";
import NewPartnerDetailPage from "./pages/NewPartnerDetailPage";
import PartnerApprovedPage from "./pages/PartnerApprovedPage";
import ClientMapingPage from "./pages/ClientMapingPage";
import VerificationEmailMobile from "./pages/VerificationEmailMobile";
import BankBookRoutes from "./pages/BankBookRoutes";
import BankBookSummaryPage from "./pages/BankBookSummary";
import BankBookDetails from "./pages/BankBookDetails";
import FilterForm from "./pages/filterForm";
import PirintSummary from "./components/dashboard/print/Components/NewPrintDesigns/PirintSummary";
import { ProductUploadValidation } from "./components/ProductUploadValidation/ProductUploadValidation";
import { ProductUploadValidationDetails } from "./components/ProductUploadValidation/ProductUploadValidationDetails";
import { NewVerificationUpload } from "./components/verificationUpload/NewVerificationUpload";
import VerificationUploadV2 from "./pages/VerificationUploadV2";
import InsuranceLifeReport from "./components/dashboard/products/insurance/InsuranceLifeReport";
import InsuranceSummary from "./pages/InsuranceSummary";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { useScrollbarColor } from "./hooks/useScrollBarColor";
import RecurringDepositRoutes from "./pages/RecurringDepositRoutes";
import RecurringDepositDetails from "./pages/RecurringDepositDetails";
import RecurringDepositSummaryPage from "./pages/RecurringDepositSummary";
import FixedDepositRoutes from "./pages/FixedDepositRoutes";
import FixedDepositDetails from "./pages/FixedDepositDetails";
import FixedDepositSummaryPage from "./pages/FixedDepositSummary";
import { mfoStaticFilter } from "./components/utils/common";
import ComponentToCanvasTemp from "./components/dashboard/print/pages/ComponentToCanvasTemp";
import TaxWeb from "./components/dashboard/products/tax/TaxWeb";

//import NewSignUp from "./pages/NewSignUp/NewSignUp";
// import ClientNewSignUp from "./pages/NewSignUp/clientSignUp/ClientNewSignUp";

const Mfosummary = lazy(() => import("./pages/MfoSummary"));
function App() {
  const filter = JSON.parse(sessionStorage.getItem("filter"));
  const [entity, setEntity] = useState(null);
  const [mfoFilter, setMfofilter] = useState(filter ? filter : mfoStaticFilter);
  function get_path_type(path) {
    if (path.indexOf("/portfolio") >= 0) {
      return "portfolio";
    }

    if (path.indexOf("/mutual-fund") >= 0) {
      return "mfo";
    } else if (path.indexOf("/aif") >= 0) {
      return "aif";
    } else if (path.indexOf("/pms") >= 0) {
      return "pms";
    } else if (path.indexOf("/equity") >= 0) {
      return "equity";
    } else if (path.indexOf("/real-estate") >= 0) {
      return "real-estate";
    } else if (path.indexOf("/bonds") >= 0) {
      return "bonds";
    } else if (path.indexOf("/unlisted-equity") >= 0) {
      return "private";
    } else if (path.indexOf("/structured-product") >= 0) {
      return "structured";
    } else if (path.indexOf("/mld") >= 0) {
      return "mld";
    } else if (path.indexOf("/invits") >= 0) {
      return "invits";
    } else if (path.indexOf("/reits") >= 0) {
      return "reits";
    } else if (path.indexOf("/icd") >= 0) {
      return "icd";
    } else if (path.indexOf("/fixed-deposit") >= 0) {
      return "fixed-deposit";
    } else if (path.indexOf("/bank") >= 0) {
      return "bank";
    } else if (path.indexOf("/recurring-deposit") >= 0) {
      return "recurring-deposit";
    }

    if (path.indexOf("/holding") >= 0) {
      return "holding";
    } else {
      return "general";
    }
  }
  let path = get_path_type(window.location.pathname);
  console.log(path);

  useEffect(() => {
    const filter = sessionStorage.getItem("filter");
    // console.log(JSON.parse(filter),'askjfbavf');
    if (filter) {
      // setMfofilter(JSON.parse(filter));
    } else {
      sessionStorage.setItem("filter", JSON.stringify(mfoFilter));
      localStorage.setItem("currency_format", "default");
    }
  }, []);
  const [toggleFilter, setToggleFilter] = useState(false);

  function getfilter_as_params(pth) {
    let params = {};
    if (mfoFilter.startDate) {
      params["startDate"] = moment(mfoFilter.startDate).format("YYYY-MM-DD");
      params["endDate"] = moment(mfoFilter.endDate).format("YYYY-MM-DD");
    }
    if (Object.keys(mfoFilter.checkData).length > 0) {
      params["memberId"] = Object.keys(mfoFilter.checkData).join(",");
    }
    if (Object.keys(mfoFilter.advisor).length > 0) {
      params["advisor"] = Object.keys(mfoFilter.advisor).join(",");
    }
    if (Object.keys(mfoFilter?.executor)?.length > 0) {
      params["executor"] = Object.keys(mfoFilter?.executor).join(",");
    }
    if (mfoFilter.assetclass) {
      params["assetClass"] = Object?.keys(
        mfoFilter.assetclass[pth ? pth : path ? path : "general"]
      ).join(",");
    }
    return params;
  }
  const [theme, colorMode, mode] = useMode();
  useScrollbarColor(theme.palette.gradient.primary, theme.palette.mode);

  return (
    <ColorModeContext.Provider value={{ colorMode: colorMode, mode: mode }}>
      <ThemeProvider theme={theme}>
        {/* <CssBaseline /> */}
        <Context>
          <div className={"App " + mode}>
            <BrowserRouter>
              <ScrollToTop />

              <mfoFilterContext.Provider
                value={{
                  mfoFilter,
                  setMfofilter,
                  toggleFilter,
                  setToggleFilter,
                  entity,
                  getfilter_as_params,
                  setEntity,
                }}
              >
                <Routes>
                  <Route
                    // path="/print-report"
                    path={routes?.printReport}
                    exact
                    // element={<Overall_portfolio></Overall_portfolio>}
                    element={<ComponentToCanvas></ComponentToCanvas>}
                  ></Route>
                  <Route
                    // path="/print-report"
                    path={routes?.printReportdownload}
                    exact
                    // element={<Overall_portfolio></Overall_portfolio>}
                    element={
                      <ComponentToCanvas src="backend"></ComponentToCanvas>
                    }

                    // path={`${routes?.printReportAutoDownload}/`}
                    // exact
                    // // element={<Overall_portfolio></Overall_portfolio>}
                    // element={<ComponentToCanvasTemp></ComponentToCanvasTemp>}
                  ></Route>
                  <Route
                    // path="/print-report"
                    path={`${routes?.printReport}/:sub`}
                    exact
                    // element={<Overall_portfolio></Overall_portfolio>}
                    element={<ComponentToCanvas></ComponentToCanvas>}
                  ></Route>
                  <Route
                    path="/first-page"
                    exact
                    // element={<Overall_portfolio></Overall_portfolio>}
                    element={<FirstPage></FirstPage>}
                  ></Route>
                  <Route
                    path="/last-page"
                    exact
                    // element={<Overall_portfolio></Overall_portfolio>}
                    element={<LastPage></LastPage>}
                  ></Route>
                  {/* <Route
                path="/page2"
                exact
                element={<StackBarChart></StackBarChart>}
              ></Route> */}
                  <Route
                    path="/page3"
                    exact
                    element={<StackTable></StackTable>}
                  ></Route>
                  <Route path="/page2" exact element={<Pdf></Pdf>}></Route>
                  <Route
                    path="/pdf"
                    exact
                    element={<ViewPdf></ViewPdf>}
                  ></Route>
                  <Route
                    path="/page4"
                    exact
                    element={<NewVerificationUpload />}
                  ></Route>
                  <Route
                    path="/page5"
                    exact
                    element={<ProductUploadValidation />}
                  ></Route>
                  <Route
                    path="/product-upload/validation-details"
                    exact
                    element={<ProductUploadValidationDetails />}
                  ></Route>

                  <Route
                    path={routes?.newPrintReport}
                    exact
                    key={routes?.printSchedule}
                    element={
                      <ProtecatedUrl returnurl={true}>
                        <PirintSummary></PirintSummary>
                      </ProtecatedUrl>
                    }
                  ></Route>
                  <Route
                    key={routes?.printSchedule}
                    path={routes?.printSchedule}
                    exact
                    element={
                      <ProtecatedUrl
                        key={routes?.printSchedule}
                        returnurl={true}
                      >
                        <PirintSummary></PirintSummary>
                      </ProtecatedUrl>
                    }
                  ></Route>

                  <Route
                    path={routes?.login}
                    // path="/"
                    // exact
                    element={<SignIn></SignIn>}
                  ></Route>
                  <Route
                    // path="/:loginUserType"
                    path={routes?.login}
                    // exact
                    element={<SignIn></SignIn>}
                  ></Route>
                  <Route
                    // path="/register"
                    path={routes?.register}
                    exact
                    element={<NewSignUp></NewSignUp>}
                  ></Route>
                  <Route
                    path="/register/client"
                    exact
                    element={<ClientNewSignUp />}
                  ></Route>
                  <Route
                    path="/reset-password"
                    exact
                    element={<ForgotPassword></ForgotPassword>}
                  ></Route>
                  <Route
                    path="/logout"
                    exact
                    element={<Logout></Logout>}
                  ></Route>
                  <Route
                    path="/client"
                    exact
                    element={
                      <ProtecatedUrl
                      // allowedUserType={[
                      //   HEAD_OF_FAMILY,
                      //   INDIVIDUAL,
                      //   NON_INDIVIDUAL,
                      //   LIAISON_OFFICER,
                      //   SYSTEM_ADMINISTRATOR,
                      //   ADMIN_OPERATIONS,
                      //   ADMIN_OPERATIONS_HEAD,
                      //   COO,
                      //   BANKER,
                      //   PARTNER_REPRESENTATIVE,
                      // ]}
                      >
                        {/* <ClientList></ClientList> */}
                        <NewClientListPage />
                      </ProtecatedUrl>
                    }
                  ></Route>
                  <Route
                    path="/client/pending/detials/:id"
                    exact
                    element={
                      <ProtecatedUrl>
                        <ClientPendingDetailsPage />
                      </ProtecatedUrl>
                    }
                  ></Route>
                  {/* <Route
                path="/client/members"
                exact
                element={
                  <ProtecatedUrl
                  // allowedUserType={[
                  //   HEAD_OF_FAMILY,
                  //   NON_INDIVIDUAL,
                  //   INDIVIDUAL,
                  //   LIAISON_OFFICER,
                  //   SYSTEM_ADMINISTRATOR,
                  //   ADMIN_OPERATIONS,
                  //   ADMIN_OPERATIONS_HEAD,
                  //   COO,
                  //   BANKER,
                  // ]}
                  >
                    <ClientMemberList></ClientMemberList>
                  </ProtecatedUrl>
                }
              ></Route> */}
                  <Route
                    path="/client/mapping"
                    exact
                    element={
                      <ProtecatedUrl>
                        <ClientMapingPage />
                      </ProtecatedUrl>
                    }
                  ></Route>
                  <Route
                    path="/cardnewdesign"
                    exact
                    element={<CardnewDesign></CardnewDesign>}
                  ></Route>
                  <Route
                    path="/partner"
                    exact
                    element={
                      <ProtecatedUrl
                      // allowedUserType={[
                      //   SYSTEM_ADMINISTRATOR,
                      //   ADMIN_OPERATIONS,
                      //   ADMIN_OPERATIONS_HEAD,
                      //   COO,
                      // ]}
                      >
                        {/* <PartnerList></PartnerList> */}
                        <NewPartnerListPage />
                      </ProtecatedUrl>
                    }
                  ></Route>
                  <Route
                    path="/partner/pending/detials/:id"
                    exact
                    element={
                      <ProtecatedUrl
                      // allowedUserType={[
                      //   SYSTEM_ADMINISTRATOR,
                      //   ADMIN_OPERATIONS,
                      //   ADMIN_OPERATIONS_HEAD,
                      //   COO,
                      // ]}
                      >
                        <NewPartnerDetailPage />
                      </ProtecatedUrl>
                    }
                  ></Route>
                  <Route
                    path="/partner/approved-list"
                    exact
                    element={
                      <ProtecatedUrl
                      // allowedUserType={[
                      //   SYSTEM_ADMINISTRATOR,
                      //   ADMIN_OPERATIONS,
                      //   ADMIN_OPERATIONS_HEAD,
                      //   COO,
                      // ]}
                      >
                        <PartnerApprovedPage />
                      </ProtecatedUrl>
                    }
                  ></Route>
                  {/* <Route
                path="/client/member/detail"
                exact
                element={
                  <ProtecatedUrl
                  // allowedUserType={[SYSTEM_ADMINISTRATOR, BANKER]}
                  >
                    <SingleClientDetail></SingleClientDetail>
                  </ProtecatedUrl>
                }
              ></Route>
              <Route
                path="/partner/detail"
                exact
                element={
                  <ProtecatedUrl
                  // allowedUserType={[SYSTEM_ADMINISTRATOR, ADMIN_OPERATIONS]}
                  >
                    <SinglePartnerDetail></SinglePartnerDetail>
                  </ProtecatedUrl>
                }
              ></Route> */}
                  <Route
                    path="/product-upload"
                    exact
                    element={
                      <ProtecatedUrl>
                        <ProductUpload></ProductUpload>
                      </ProtecatedUrl>
                    }
                  ></Route>
                  <Route
                    path="/home/member/detail"
                    exact
                    element={
                      <ProtecatedUrl
                      // allowedUserType={[
                      //   HEAD_OF_FAMILY,
                      //   INDIVIDUAL,
                      //   NON_INDIVIDUAL,
                      //   LIAISON_OFFICER,
                      //   SYSTEM_ADMINISTRATOR,
                      //   ADMIN_OPERATIONS,
                      //   ADMIN_OPERATIONS_HEAD,
                      //   COO,
                      // ]}
                      >
                        <ClientMemberDetails></ClientMemberDetails>
                      </ProtecatedUrl>
                    }
                  ></Route>
                  <Route
                    path="/home/all-members"
                    exact
                    element={
                      <ProtecatedUrl
                      // allowedUserType={[
                      //   HEAD_OF_FAMILY,
                      //   INDIVIDUAL,
                      //   NON_INDIVIDUAL,
                      //   LIAISON_OFFICER,
                      //   SYSTEM_ADMINISTRATOR,
                      //   ADMIN_OPERATIONS,
                      //   ADMIN_OPERATIONS_HEAD,
                      //   COO,
                      //   BANKER,
                      // ]}
                      >
                        <ClientAllMembers></ClientAllMembers>
                      </ProtecatedUrl>
                    }
                  ></Route>
                  <Route
                    path="/verification-upload-old/:pageName"
                    exact
                    element={
                      <ProtecatedUrl
                      // allowedUserType={[
                      //   SYSTEM_ADMINISTRATOR,
                      //   ADMIN_OPERATIONS,
                      //   ADMIN_OPERATIONS_HEAD,
                      //   COO,
                      // ]}
                      >
                        <VerificationUpload></VerificationUpload>
                      </ProtecatedUrl>
                    }
                  ></Route>
                  <Route
                    path="/verification-upload/:pageName"
                    exact
                    element={
                      <ProtecatedUrl
                      // allowedUserType={[
                      //   SYSTEM_ADMINISTRATOR,
                      //   ADMIN_OPERATIONS,
                      //   ADMIN_OPERATIONS_HEAD,
                      //   COO,
                      // ]}
                      >
                        <VerificationUploadV2></VerificationUploadV2>
                      </ProtecatedUrl>
                    }
                  ></Route>
                  <Route
                    path="/home"
                    exact
                    element={
                      <ProtecatedUrl>
                        <Home></Home>
                      </ProtecatedUrl>
                    }
                  ></Route>
                  {/* // TODO  DELETE THIS ROUTE */}
                  <Route
                    path="/home/accordian"
                    exact
                    element={
                      <ProtecatedUrl>
                        <UserManagement></UserManagement>
                      </ProtecatedUrl>
                    }
                  ></Route>
                  <Route
                    path={routes?.homeHoldingSubHead}
                    exact
                    element={
                      <ProtecatedUrl
                      // allowedUserType={[
                      //   HEAD_OF_FAMILY,
                      //   INDIVIDUAL,
                      //   NON_INDIVIDUAL,
                      //   LIAISON_OFFICER,
                      // ]}
                      >
                        <HoldingSummary></HoldingSummary>
                      </ProtecatedUrl>
                    }
                  ></Route>
                  <Route
                    path={`${routes?.homeHoldingSubHead}/:pageName`}
                    exact
                    element={
                      <ProtecatedUrl>
                        <SummaryDetails></SummaryDetails>
                      </ProtecatedUrl>
                    }
                  ></Route>
                  <Route
                    path={`/tax`}
                    exact
                    element={
                      <ProtecatedUrl>
                        <TaxWeb></TaxWeb>
                      </ProtecatedUrl>
                    }
                  ></Route>
                  <Route element={<BankBookRoutes />}>
                    <Route
                      exact
                      path={routes?.bankBookSummaryPage}
                      element={
                        <ProtecatedUrl>
                          <Suspense fallback={<Loader />}>
                            <BankBookSummaryPage />
                          </Suspense>
                        </ProtecatedUrl>
                      }
                    />
                    <Route
                      path={routes?.bankBookDetailed}
                      exact
                      element={
                        <ProtecatedUrl>
                          <BankBookDetails />
                        </ProtecatedUrl>
                      }
                    />
                  </Route>
                  <Route element={<RecurringDepositRoutes />}>
                    <Route
                      exact
                      path={routes?.recurringDepositSummaryPage}
                      element={
                        <ProtecatedUrl>
                          <Suspense fallback={<Loader />}>
                            <RecurringDepositSummaryPage />
                          </Suspense>
                        </ProtecatedUrl>
                      }
                    />
                    <Route
                      path={routes?.recurringDepositDetailed}
                      exact
                      element={
                        <ProtecatedUrl>
                          <RecurringDepositDetails />
                        </ProtecatedUrl>
                      }
                    />
                  </Route>
                  <Route element={<FixedDepositRoutes />}>
                    <Route
                      exact
                      path={routes?.fixedDepositSummaryPage}
                      element={
                        <ProtecatedUrl>
                          <Suspense fallback={<Loader />}>
                            <FixedDepositSummaryPage />
                          </Suspense>
                        </ProtecatedUrl>
                      }
                    />
                    <Route
                      path={routes?.fixedDepositDetailed}
                      exact
                      element={
                        <ProtecatedUrl>
                          <FixedDepositDetails />
                        </ProtecatedUrl>
                      }
                    />
                  </Route>

                  <Route element={<ICDRoutes />}>
                    <Route
                      exact
                      path={routes?.icdSummaryPage}
                      element={
                        <ProtecatedUrl>
                          <Suspense fallback={<Loader />}>
                            <ICDSummary />
                          </Suspense>
                        </ProtecatedUrl>
                      }
                    />
                    <Route
                      path={routes?.icdDetailed}
                      exact
                      element={
                        <ProtecatedUrl>
                          <ICDDetailes />
                        </ProtecatedUrl>
                      }
                    />
                  </Route>
                  <Route element={<Mfopages />}>
                    <Route
                      // path="/home/mutual-fund"
                      path={routes?.mfSummaryPage}
                      exact
                      element={
                        <ProtecatedUrl>
                          <Suspense fallback={<Loader />}>
                            <Mfosummary />
                          </Suspense>
                          {/* <MfoSummary></MfoSummary> */}
                        </ProtecatedUrl>
                      }
                    ></Route>
                    {/* <Route
                  path="/home/mutual-fund/detailed"
                  exact
                  element={
                    <ProtecatedUrl>
                      <MutualFundSummary></MutualFundSummary>
                    </ProtecatedUrl>
                  }
                ></Route> */}
                    <Route
                      // path="/home/mutual-fund/risk"
                      path={routes?.mfRiskDetailsPage}
                      exact
                      element={
                        <ProtecatedUrl>
                          <RiskDetails></RiskDetails>
                        </ProtecatedUrl>
                      }
                    ></Route>
                    {/* <Route path='/box' exact element={<Box size={3} data={["0-0"]}/>}></Route> */}

                    {/* <Route
                  path="/home/mutual-fund/report"
                  exact
                  element={
                    <ProtecatedUrl>
                      <MfoSummaryDetails></MfoSummaryDetails>
                    </ProtecatedUrl>
                  }
                ></Route> */}
                    <Route
                      // path="/home/mutual-fund/:pageName"
                      path={routes?.mfDetailed}
                      exact
                      element={
                        <ProtecatedUrl>
                          <MfoSummaryDetails></MfoSummaryDetails>
                        </ProtecatedUrl>
                      }
                    ></Route>
                    <Route
                      path={routes?.mfDetailed_holdingPeriod}
                      exact
                      element={
                        <ProtecatedUrl>
                          <HoldingPeriodDetails></HoldingPeriodDetails>
                        </ProtecatedUrl>
                      }
                    ></Route>
                  </Route>
                  <Route element={<Pmspages />}>
                    <Route
                      // path="/home/pms/pms-summary"
                      path={routes?.pmsSummaryPage}
                      exact
                      element={
                        <ProtecatedUrl>
                          <PmsSummary></PmsSummary>
                        </ProtecatedUrl>
                      }
                    ></Route>
                    <Route
                      // path="/home/pms/pms-summary/report/:pageName"
                      path={routes?.pmsDetailed}
                      exact
                      element={
                        <ProtecatedUrl>
                          <PmsSummaryDetails></PmsSummaryDetails>
                        </ProtecatedUrl>
                      }
                    ></Route>
                  </Route>
                  <Route element={<Aifpages />}>
                    <Route
                      // path="/home/aif/aif-summary"
                      path={routes?.aifSummaryPage}
                      exact
                      element={
                        <ProtecatedUrl>
                          <AifSummary></AifSummary>
                        </ProtecatedUrl>
                      }
                    ></Route>
                    <Route
                      // path="/home/aif/aif-summary/report/:pageName"
                      path={routes?.aifDetailed}
                      exact
                      element={
                        <ProtecatedUrl>
                          <AifSummaryDetails></AifSummaryDetails>
                        </ProtecatedUrl>
                      }
                    ></Route>
                  </Route>
                  <Route element={<RealEstateRoute />}>
                    <Route
                      // path="/home/real-estate"
                      path={routes?.realEstateSummaryPage}
                      exact
                      element={<RealEstate></RealEstate>}
                    ></Route>
                    <Route
                      // path="/home/real-estate/report/:pageName"
                      path={routes?.realEstateDetailed}
                      exact
                      element={<RealEstateDetails></RealEstateDetails>}
                    ></Route>
                  </Route>
                  <Route element={<InsuranceRoute />}>
                    <Route
                      // path="/home/insurance/report/life"
                      path={routes?.insuranceSummaryPage}
                      exact
                      element={<Insurance></Insurance>}
                    ></Route>
                    <Route
                      path={routes?.insuranceReportPage}
                      exact
                      element={<InsuranceSummary></InsuranceSummary>}
                    ></Route>
                    <Route
                      // path="/home/insurance/report/:pageName"
                      path={routes?.insuranceSubPage}
                      exact
                      element={<InsuranceDetails></InsuranceDetails>}
                    ></Route>
                  </Route>

                  <Route
                    // path="/user-management/family-structure"
                    path={routes?.familyTree}
                    exact
                    element={<ORGD3Chart />}
                  ></Route>
                  <Route
                    path="/client/user-management-list"
                    exact
                    element={
                      <ProtecatedUrl
                      // allowedUserType={[
                      //   HEAD_OF_FAMILY,
                      //   INDIVIDUAL,
                      //   NON_INDIVIDUAL,
                      //   LIAISON_OFFICER,
                      //   SYSTEM_ADMINISTRATOR,
                      //   ADMIN_OPERATIONS,
                      //   ADMIN_OPERATIONS_HEAD,
                      //   PARTNER_REPRESENTATIVE,
                      //   COO,
                      // ]}
                      >
                        <NewClintToUserManagement />
                      </ProtecatedUrl>
                    }
                  ></Route>
                  <Route
                    path="/client/user-management/edit:details"
                    exact
                    element={
                      <ProtecatedUrl
                      // allowedUserType={[
                      //   HEAD_OF_FAMILY,
                      //   INDIVIDUAL,
                      //   NON_INDIVIDUAL,
                      //   LIAISON_OFFICER,
                      //   SYSTEM_ADMINISTRATOR,
                      //   ADMIN_OPERATIONS,
                      //   ADMIN_OPERATIONS_HEAD,
                      //   PARTNER_REPRESENTATIVE,
                      //   COO,
                      // ]}
                      >
                        <UserManagementEdit></UserManagementEdit>
                      </ProtecatedUrl>
                    }
                  ></Route>
                  <Route
                    path={routes?.userManagement}
                    exact
                    element={
                      <ProtecatedUrl
                      // allowedUserType={[
                      //   HEAD_OF_FAMILY,
                      //   INDIVIDUAL,
                      //   NON_INDIVIDUAL,
                      //   LIAISON_OFFICER,
                      //   SYSTEM_ADMINISTRATOR,
                      //   ADMIN_OPERATIONS,
                      //   ADMIN_OPERATIONS_HEAD,
                      //   PARTNER_REPRESENTATIVE,
                      //   COO,
                      // ]}
                      >
                        <UserManagement></UserManagement>
                      </ProtecatedUrl>
                    }
                  ></Route>
                  {/* EDIT USER MANAGEMENT */}
                  <Route
                    path="/user-management/edit/:details"
                    exact
                    element={
                      <ProtecatedUrl
                      // allowedUserType={[
                      //   HEAD_OF_FAMILY,
                      //   INDIVIDUAL,
                      //   NON_INDIVIDUAL,
                      //   LIAISON_OFFICER,
                      //   SYSTEM_ADMINISTRATOR,
                      //   ADMIN_OPERATIONS,
                      //   ADMIN_OPERATIONS_HEAD,
                      //   PARTNER_REPRESENTATIVE,
                      //   COO,
                      // ]}
                      >
                        <UserManagementEdit></UserManagementEdit>
                      </ProtecatedUrl>
                    }
                  ></Route>
                  <Route
                    path="/verify/:entityType/:comType"
                    exact
                    element={
                      <VerificationEmailMobile></VerificationEmailMobile>
                    }
                  ></Route>
                  <Route element={<Bondpages />}>
                    <Route
                      // path="/home/bonds/bonds-summary"
                      path={routes?.bondsSummaryPage}
                      exact
                      element={<Bonds></Bonds>}
                    ></Route>
                    <Route
                      // path="/home/bonds/bonds-summary/:pageName"
                      path={routes?.bondsDetailed}
                      exact
                      element={<BondsDetails></BondsDetails>}
                    ></Route>
                    {/* Testing Routes */}
                    {/* <Route
                  path="/home/bonds/bonds-summary/details/bondsDetails"
                  // path={routes?.bondsDetailed}
                  exact
                  element={<BondsDetailsPage></BondsDetailsPage>}
                ></Route>
                <Route
                  path="/home/bonds/bonds-summary/details/govbonds"
                  exact
                  element={<Govbond></Govbond>}
                ></Route>
                <Route
                  path="/home/bonds/bonds-summary/details/corporatebonds"
                  exact
                  element={<Corporatebond></Corporatebond>}
                ></Route>
                <Route
                  path="/home/bonds/bonds-summary/details/bonds"
                  exact
                  element={<BondsPage></BondsPage>}
                ></Route> */}
                  </Route>
                  <Route element={<Equitypages />}>
                    <Route
                      // path="/home/equity/equity-summary"
                      path={routes?.equitySummaryPage}
                      exact
                      element={<Equity></Equity>}
                    ></Route>
                    <Route
                      // path="/home/equity/equity-summary/:pageName"
                      path={routes?.equityDetailed}
                      exact
                      element={<EquityDetails></EquityDetails>}
                    ></Route>
                  </Route>

                  <Route
                    path={routes?.equityHoldingPeriod}
                    exact
                    element={<HoldingPeriodDetails></HoldingPeriodDetails>}
                  ></Route>
                  <Route
                    path={routes?.equityStockdetails}
                    exact
                    element={<EquityStockDetailsPage></EquityStockDetailsPage>}
                  ></Route>
                  <Route
                    path={routes?.equityViewChart}
                    exact
                    element={<EquityPriceChart></EquityPriceChart>}
                  ></Route>
                  <Route
                    path={routes?.equityIpoDetails}
                    exact
                    element={<EquityIPO></EquityIPO>}
                  ></Route>
                  <Route element={<PrivateMarketPages />}>
                    <Route
                      // path="/home/private-market/private-market-summary"
                      path={routes?.privateMarketSummaryPage}
                      exact
                      element={<PrivateMarketSummary />}
                    ></Route>
                    <Route
                      // path="/home/private-market/private-market-summary/report/:pageName"
                      path={routes?.privateMarketDetailed}
                      exact
                      element={<PrivateMarketDetails />}
                    ></Route>
                  </Route>
                  <Route element={<StructuredProductPages />}>
                    <Route
                      // path="/home/structured-product/structured-product-summary"
                      path={routes?.structuredProdSummaryPage}
                      exact
                      element={<StructuredProductSummary />}
                    ></Route>
                    <Route
                      // path="/home/structured-product/structured-product-summary/report/:pageName"
                      path={routes?.structuredProdDetailed}
                      exact
                      element={<StructuredProductDetails />}
                    ></Route>
                  </Route>
                  <Route element={<MLDRoutes />}>
                    <Route
                      // path="/home/mld/mld-summary"
                      path={routes?.mldSummaryPage}
                      element={<MLDSummary />}
                    />
                    <Route
                      // path="/home/mld/mld-summary/report/:pageName"
                      path={routes?.mldDetailed}
                      element={<MLDReportDetails />}
                    />
                  </Route>
                  <Route element={<REITSRoutes />}>
                    <Route
                      // path="/home/reits/reits-summary"
                      path={routes?.reitsSummaryPage}
                      element={<REITSSummary />}
                    />
                    <Route
                      // path="/home/reits/reits-summary/report/:pageName"
                      path={routes?.reitsDetailed}
                      element={<REITSReportDetails />}
                    />
                  </Route>
                  <Route element={<INVITSRoutes />}>
                    <Route
                      // path="/home/invits/invits-summary"
                      path={routes?.invitsSummaryPage}
                      element={<INVITSSummary />}
                    />
                    <Route
                      // path="/home/invits/invits-summary/report/:pageName"
                      path={routes?.invitsDetailed}
                      element={<INVITSReportDetails />}
                    />
                  </Route>
                  <Route
                    path="/masters"
                    exact
                    element={
                      <ProtecatedUrl
                      // allowedUserType={[
                      //   SYSTEM_ADMINISTRATOR,
                      //   ADMIN_OPERATIONS_HEAD,
                      //   COO,
                      // ]}
                      >
                        <MastersList></MastersList>
                      </ProtecatedUrl>
                    }
                  ></Route>
                  <Route
                    path="/masters/products"
                    exact
                    element={
                      <ProtecatedUrl
                      // allowedUserType={[
                      //   SYSTEM_ADMINISTRATOR,
                      //   ADMIN_OPERATIONS_HEAD,
                      //   COO,
                      // ]}
                      >
                        <MasterProducts></MasterProducts>
                      </ProtecatedUrl>
                    }
                  ></Route>
                  <Route
                    path="/masters/products/:addType"
                    exact
                    element={
                      <ProtecatedUrl
                      // allowedUserType={[
                      //   SYSTEM_ADMINISTRATOR,
                      //   ADMIN_OPERATIONS_HEAD,
                      //   COO,
                      // ]}
                      >
                        <AddMasterProducts></AddMasterProducts>
                      </ProtecatedUrl>
                    }
                  ></Route>

                  <Route
                    path={routes?.bondsDetails}
                    exact
                    element={<BondsDetailsReport></BondsDetailsReport>}
                  ></Route>
                  <Route
                    path={routes?.strategyBuilder}
                    exact
                    element={
                      <ProtecatedUrl>
                        <StrategyBuilder />
                      </ProtecatedUrl>
                    }
                  />
                </Routes>
              </mfoFilterContext.Provider>
            </BrowserRouter>
          </div>
          <div id="customAlert" className="containerAlert"></div>
        </Context>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
