import { useTheme } from "@material-ui/core";

export const InfoIcon = () => {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <rect
        opacity="0.7"
        width="48"
        height="48"
        rx="6"
        fill={theme.palette.cards.infoIconContainer}
      />
      <path
        opacity="0.7"
        d="M23.5 39C31.5081 39 38 32.5081 38 24.5C38 16.4919 31.5081 10 23.5 10C15.4919 10 9 16.4919 9 24.5C9 32.5081 15.4919 39 23.5 39Z"
        fill={theme.palette.cards.infoIconInnerCircle}
      />
      <path
        d="M23.4998 20.3333C24.4586 20.3333 25.2359 19.556 25.2359 18.5972C25.2359 17.6384 24.4586 16.8611 23.4998 16.8611C22.541 16.8611 21.7637 17.6384 21.7637 18.5972C21.7637 19.556 22.541 20.3333 23.4998 20.3333Z"
        fill={theme.palette.cards.infoIconText}
      />
      <path
        d="M24.8891 22.4169H22.1113V32.1392H24.8891V22.4169Z"
        fill={theme.palette.cards.infoIconText}
      />
    </svg>
  );
};
