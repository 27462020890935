import React from "react";
import { useState } from "react";
import TabIpo from "../../common/TabIpo";
import IpoDetails from "./IpoDetails";
import IpoHolding from "./IpoHolding";
import IpoTransaction from "./IpoTransaction";
import useStyles from "../../common/ProductsStyles";
import { ReactComponent as Print } from "../../../../../assets/icons/print.svg";

import { ReactComponent as Import } from "../../../../../assets/icons/import.svg";
import { ReactComponent as Export } from "../../../../../assets/icons/export.svg";
import { ReactComponent as Filter } from "../../../../../assets/icons/filter.svg";
import { ReactComponent as Back } from "../../../../../assets/holding-summary/back-blue.svg";
import { ReactComponent as EditColumn } from "../../../../../assets/icons/edit-column.svg";
import { useNavigate } from "react-router-dom";
import { Typography, useTheme } from "@material-ui/core";
import TabIpoSub from "../../common/TabIpoSub";
import TabComponent from "../../common/TabComponent";
import NewTabComp from "../../common/NewTabComp";

const data = [
  { page: "holding", title: "Transaction Summary" },
  // { page: "transaction", title: "Transaction Summary" },
  { page: "ipo_details", title: "IPO Details" },
];

const data2 = [
  { page: "open", title: "Open" },
  { page: "closed", title: "Closed" },
  { page: "upcoming", title: "Upcoming" },
  { page: "listed", title: "Listed" },
];

function IpoReport() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [page, setPage] = useState("holding");
  const [page2, setPage2] = useState("open");
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const icons = theme.palette.icons;
  const Bulb = icons.bulbIcon;

  const changePage = (p) => {
    switch (p) {
      case "holding":
        return <IpoHolding open={open} setOpen={setOpen} />;

      // case "transaction":
      //     return <IpoTransaction />;

      case "ipo_details":
        return <IpoDetails page={page} page2={page2} />;

      default:
        return <IpoHolding open={open} setOpen={setOpen} />;
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClick = React.useCallback(
    (page) => {
      setPage(page);
    },
    [page]
  );

  const handleClick2 = React.useCallback(
    (page) => {
      setPage2(page);
    },
    [page2]
  );

  return (
    <>
      {page === "ipo_details" ? (
        <>
          <div className={`${classes.newAlignTopHeader}`}>
            {/* <Back
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(-1);
              }}
            /> */}
            <Typography
              className={`${classes.mainHeading} ${classes.mainHeadingAlign}`}
            >
              IPO - Initial Public Offerings
            </Typography>
            <div className={`${classes.newIconAlign}`}>
              {/* <Bulb className={classes.iconStyle} /> */}
              <Import className={classes.iconStyle} />
              <Export className={classes.iconStyle} />
              <Print className={classes.iconStyle} />

              {/* <Filter className={classes.iconStyle} /> */}
            </div>
          </div>
          <div
            style={{
              //   width: "%",
              margin: "1% 2%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {/* <TabIpo page={page} setPage={setPage} /> */}
            {/* <TabIpoSub /> */}
            {/* <TabComponent
              data={data}
              page={page}
              setPage={setPage}
              type={"state"}
            ></TabComponent> */}
            {/* <div style={{ margin: "2%" }}> */}
            <NewTabComp
              data={data}
              customHandle={handleClick}
              customWidth={"fit-content"}
              customSelect={1}
            />
            {/* </div> */}

            {page === "ipo_details" ? (
              // <TabIpoSub page={page} setPage={setPage} />
              //   <div
              //     style={{
              //       float: "right",
              //       marginTop: "-60px",
              //       marginRight: "2%",
              //     }}
              //   >
              // <TabComponent
              //   data={data2}
              //   page={page2}
              //   setPage={setPage2}
              //   type={"state"}
              //   width={"fit-content"}
              // ></TabComponent>
              // <div style={{ margin: "2%" }}>
              <NewTabComp
                data={data2}
                customHandle={handleClick2}
                customWidth={"fit-content"}
              />
            ) : (
              // </div>
              //   </div>
              ""
            )}
          </div>
          {changePage(page)}
        </>
      ) : (
        <>
          <div style={{ minHeight: "500px" }} className={classes.mainContent}>
            <div className={`${classes.newAlignTopHeader}`}>
              {/* <TabIpo page={page} setPage={setPage} /> */}
              {/* <div style={{ margin: "2%" }}> */}
              <NewTabComp
                data={data}
                customHandle={handleClick}
                customWidth={"fit-content"}
              />
              {/* </div> */}
              <div className={`${classes.newIconAlign}`}>
                {/* <Bulb className={classes.iconStyle} /> */}
                <Import className={classes.iconStyle} />
                <Export className={classes.iconStyle} />
                <Print className={classes.iconStyle} />
                <EditColumn
                  className={classes.iconStyle}
                  onClick={handleOpen}
                />
              </div>
            </div>
            {changePage(page)}
          </div>
        </>
      )}
    </>
  );
}

export default IpoReport;
