import { Typography } from "@material-ui/core";
import React from "react";
import "../NewSignUp.css";

function ErrorMessageComp({ errorMessage, style = {} }) {
  return (
    <Typography
      className="generalFontFam varientError"
      style={{
        color: "red !important",
        textAlign: "center",
        marginBottom: "10px",
        ...style,
      }}
    >
      {errorMessage}
    </Typography>
  );
}

export default ErrorMessageComp;
