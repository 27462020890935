import React, { useEffect, useState } from "react";
import { getTableStatus } from "../api";
import { STATUS_CHECK_TIMEOUT } from "../Constants";

function useGetReportStatusTable() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getTableData = async () => {
    // setData([]);
    try {
      let res = await getTableStatus({
        // clientId: 199,
        //type: "Executive Client Summary Report",
      });
      console.log(res?.data?.data?.result, "agkj");
      setData(() => res?.data?.data?.result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getTableData();
    const interval = setInterval(() => {
      getTableData();
    }, STATUS_CHECK_TIMEOUT * 1000);

    return () => clearInterval(interval);
  }, []);

  return { getTableData, data, loading };
}

export default useGetReportStatusTable;
