import React, { useEffect, useMemo, useState } from "react";
import NewHeadAndDetailsComp from "../newDesigns/common/newHeadingAndDetailsComp/NewHeadAndDetailsComp";
import { cashflowPage } from "./utils/constands";
import CashflowTable from "./CashflowTable";
import { OnboardingContext } from "../../../../Context";
import { getCashFlow2, getQuarterWiseCashFlow } from "../../../../api";
import PageLoader from "../common/pageLoader";

import "./Cashflows.css";
import { useContext } from "react";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import moment from "moment";
import {
  FormControl,
  Grid,
  Select,
  makeStyles,
  useTheme,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  mainCard: {
    //background: "linear-gradient(127deg, #4C1A97 14.75%, #7636D7 88.7%)",
    // background: "linear-gradient(146deg, #2B0D58 0%, #692FC0 100%)",
    background: theme.palette.cards.mainCard,
    borderRadius: "8px",
    minHeight: "156px",
  },
  // * This is the nested card inside the purple card
  nestedCard: {
    background: theme.palette.cards.innerCard,
    minHeight: "70px",
    borderRadius: "7px",
  },
  nestedCardBoxPadding: { padding: "5px", marginBottom: "-4.5px" },

  dividerText: {
    color: "#FFF",
    fontFamily: "Avenir",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "2.24px",
    textTransform: "uppercase",
    opacity: "0.5",
    marginRight: "10px",
  },
  contentBox: {
    background: "rgba(11, 3, 24, 0.6)",
    border: "1px solid #4E189F",
    boxShadow: "0px 0px 10px #4E189F",
    backdropFilter: "blur(50px)",
    borderRadius: "50px",
    position: "relative",
    // left: "10%",
    width: "80%",
    top: "20px",
    margin: "15px 20px 20px 20px",
    [theme.breakpoints.down("md")]: {
      left: "10%",
      width: "65%",
      top: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      left: "5%",
      width: "90%",
      top: "20px",
    },
  },
  mainContent: {
    padding: "15px 20px 20px 20px",
  },
  gridContainer: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  icon: {
    fill: "#FAF9FF",
  },
  text: {
    fontStyle: "normal",
    fontFamily: "Avenir",
  },
  inputField: {
    borderColor: " #711CF1",
    borderRadius: "2px",
    color: "#fff",
    width: "100%",
    "& .MuiInputBase-root": {
      color: "#ffffff",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "6px !important",
      border: "none !important",
      background: "#000 !important",
    },
  },
  inputGrid: {
    // marginTop: "20px",
  },
  formContent: {
    marginLeft: "15%",
    marginTop: "10px",
    height: "340px",
    overflowY: "scroll",
  },

  iconborder: {
    border: "1px solid #4E189F",
    backdropFilter: "blur(50px)",
    borderRadius: "30px",
    margin: "20px 5px 5px 5px",
    padding: "22px 55px 10px 44px",
  },
  uploadicon: {
    position: "absolute",
    top: "-30px",
    left: "-50px",
  },
  formControl: {
    // border: "1px solid #4E189F",
  },
  boxfileuploadContainer: {
    background: "#220848",
    border: "1px dashed #FFFFFF",
    boxShadow:
      "-6.22302px -6.22302px 18.6691px #302A64, 6.22302px 6.22302px 18.6691px #000000",
    borderRadius: "20px",
    // width: "98%",
  },
  line: {
    opacity: "0.2",
    backgroundColor: "#D4BBFB",
    border: ".5px solid #D4BBFB",
    marginBlock: "2em",
    width: "100%",
  },
  buttonContainer: {
    textAlign: "center",
    // marginTop: "5px",
    // marginBottom: "10px",
  },
  button: {
    paddingTop: "10px",
    // width: "80%",
    color: "rgb(255, 255, 255)",
    background:
      "linear-gradient(rgb(170, 111, 254) 0%, rgb(113, 28, 241) 100%)",
    borderRadius: "20px",
    paddingBottom: "10px",
    paddingInline: "6em",
    textTransform: "capitalize",
  },
  buttonText: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#FFFFFF",
  },
  nobuttonui: {
    color: "#fff",
    // background: "transparent",
    textAlign: "center",
    padding: "1% 36%",
    width: "100%",
    background: "#220848",
    height: "228px",
    borderRadius: "20px",
  },
  uploadsvg: {
    width: "13%",
    paddingTop: "10%",
  },
  downloadformat: {
    color: "#fff",
    fontSize: "16px",
    textAlign: "center",
  },
  downloadicon: {
    fontSize: "15px",
    padding: "0px 4px",
    verticalAlign: "bottom",
  },
  downloadtext: {
    color: "#3A56FF",
    cursor: "pointer",
  },
  customLinearProgress: {
    height: "2.98px !important",
    borderRadius: "10px !important",
    // backgroundColor: "red !important",
    "& .MuiLinearProgress-bar": {
      backgroundColor: "#B65BFF !important",
    },
  },
  selectDropDownMenu: {
    borderRadius: "4px !important",
    border: `1.164px solid ${theme.palette.selectDropDown.border} !important`,
  },
}));
function Cashflows() {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [cashflowsData, setCashflowsData] = useState([]);
  const { userState } = OnboardingContext();
  const [year, setselectYear] = useState(moment().year());
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  console.log(mfoFilter, "mfoFilter");
  async function getCashFlow() {
    setLoading(true);
    // Using default assetClass
    let filterparams = getfilter_as_params();

    const params = {
      assetClass: "equity,debt,alternate,cash",
      reportType: "dashboard",
      startYear: year - 1,
      reportType: "dashboard",
    };
    if (filterparams.memberId) {
      params.memberId = filterparams.memberId;
    }
    if (filterparams.advisor) {
      params.advisor = filterparams.advisor;
    }
    // if (mfoFilter.endDate) {
    //   params["endDate"] = moment(mfoFilter.endDate, "YYYY-MM-DD").format(
    //     "YYYY-MM-DD"
    //   );
    // }
    // if (mfoFilter.startDate) {
    //   params["startDate"] = moment(mfoFilter.startDate, "YYYY-MM-DD").format(
    //     "YYYY-MM-DD"
    //   );
    // }
    const clientId = userState.loginedUserData.id;
    // const { data } = await getCashFlow2(clientId, params);
    const { data } = await getQuarterWiseCashFlow(clientId, params);
    // console.log(data, "data");
    // Ignoring Total
    setCashflowsData([
      // ...data?.data?.result?.advisor,
      ...data?.data?.result?.quarter,
      data?.data?.result?.total,
    ]);
    setLoading(false);
  }
  useEffect(() => {
    try {
      getCashFlow();
    } catch (err) {
      setLoading(false);
    }
  }, [mfoFilter, year]);

  // Get details of a particular row
  const getRow = (rowName) => {
    if (cashflowsData.length > 0) {
      return cashflowsData.map((item) => item[rowName]);
    }
    return [];
  };

  // Creating [][] for infows
  const inflows = useMemo(() => {
    let INFLOWS = [];
    if (cashflowsData.length > 0) {
      // INFLOWS.push(["Dividend Received", ...getRow("divident_paid")]);
      // INFLOWS.push(["Interest Received", ...getRow("interest_paid")]);
      // INFLOWS.push(["Other Income", ...getRow("other_income")]);
      // INFLOWS.push(["opening Balance", ...getRow("openingBalance")]);
      // INFLOWS.push(["Advisor", ...getRow("advisor")]);
      INFLOWS.push(["Redemption", ...getRow("redemption")]);
      INFLOWS.push(["Principal Payback", ...getRow("principalPayback")]);
      INFLOWS.push(["Realised Gain/Loss", ...getRow("realisedGainLoss")]);
      INFLOWS.push(["Other Income", ...getRow("otherIncome")]);
      return INFLOWS;
    }
    return [];
  }, [cashflowsData]);

  // Creating [][] for outflows
  const outflows = useMemo(() => {
    let OUTFLOWS = [];
    if (cashflowsData.length) {
      OUTFLOWS.push(["Purchase", ...getRow("purchase")]);
      OUTFLOWS.push([
        "Dividend Reinvestment",
        ...getRow("dividendReinvestment"),
      ]);
      OUTFLOWS.push(["Equalisation Paid", ...getRow("equalisationPaid")]);
      OUTFLOWS.push(["Other Charges", ...getRow("otherCharges")]);
      OUTFLOWS.push(["Closing Balance", ...getRow("closingBalance")]);
      return OUTFLOWS;
    }
    return [];
  }, [cashflowsData]);

  return (
    <>
      <PageLoader showLoader={loading} />
      <div
        className="main-wrapper"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <NewHeadAndDetailsComp
          title={cashflowPage?.title}
          // content={cashflowPage?.content()}
        />
        <Grid xs={3} lg={3}>
          <FormControl
            variant="outlined"
            className={`${classes.formControl} ${classes.inputField}`}
            style={{ position: "relative" }}
          >
            <p className="selectDropDownLabel">Select Financial Year</p>
            <Select
              styles={{
                opacity: 1,
                border: "0px solid black",
                color: "#FAF9FF",
              }}
              sx={{ color: "#fff" }}
              displayEmpty
              renderValue={
                year !== ""
                  ? undefined
                  : () => <span style={{ color: "#59595B" }}>Select Year</span>
              }
              // native
              // style={{ color: "#FAF9FF" }}
              labelId="demo-simple-select-outlined-label"
              // label="Select Product"
              value={year}
              onChange={(e) => {
                setselectYear(e.target.value);
              }}
              inputProps={{
                name: "type",
                id: "outlined-entity_type-native-simple",

                classes: {
                  icon: classes.icon,
                },
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    backgroundColor: "#1e0d2d",
                    border: `1.164px solid ${theme.palette.selectDropDown.border}`,
                    marginTop: "1rem",
                  },
                },
                MenuListProps: {
                  className: classes.selectDropDownMenu,
                },
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {Array.from(Array(10).keys()).map((num) => {
                let year = moment().add(1, "year").year();
                return (
                  <option
                    className="optiondesignNew"
                    key={year - 1}
                    value={year - num}
                  >
                    {year - num - 1} - {year - num}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </div>
      {cashflowsData.length > 0 && (
        <section className="main-wrapper">
          <CashflowTable
            topHeader={getRow("quarter")}
            header={getRow("openingBalance")}
            inflows={inflows}
            outflows={outflows}
            footer={getRow("net")}
          />
        </section>
      )}
    </>
  );
}

export default Cashflows;
