import React from "react";

import Pdf from "./pdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
export default function ViewPdf() {
  return (
    <PDFDownloadLink document={Pdf} fileName="report">
      {({ loading }) =>
        loading ? <button>Loading Document</button> : <button>Download</button>
      }
    </PDFDownloadLink>
    // <PDFViewer>
    //   <Pdf></Pdf>
    // </PDFViewer>
  );
}
