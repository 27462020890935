import React from "react";
import TaxModule from "./TaxModule";
import PmsModule from "./PmsModule";
import { Link } from "react-router-dom";

export default function PmsUnderlyingModule({
  newdata,
  denomination,
  pdfInfo,
  setChanges,
}) {
  function pdfSort() {
    const breadcrumbs = [
      <a key="1" color="#000000" className="printBreadsel">
        PMS Underlying Transaction
      </a>,
    ];

    // let asset =
    //   newdata && Object.keys(newdata?.["asset-wise-product-sort-order"]);
    let arr = [];
    // console.log(newdata, "newData");
    return newdata?.["member-pms-scheme-wise-transactions"].map((m) => {
      return m.details.map((d) => {
        let bread = [...breadcrumbs];
        bread.push(
          <Link
            underline="hover"
            key="2"
            color="
    #A29FA6"
          >
            <span style={{ opacity: 0.8 }}> Asset Class : </span>
            <span className="pdfselectedTxt pdfCaps">{d?.asset_type}</span>
          </Link>
        );
        return (
          <>
            <PmsModule
              setChanges={setChanges}
              breadcrumbs={bread}
              memberName={m.member_name}
              denomination={denomination}
              instrumentName={d.fund_name}
              folioNo={d.folio_no}
              isin={d.isin}
              newdata={d.transactions}
              subtext={""}
              product={""}
              pdfInfo={pdfInfo}
            />
          </>
        );
      });
    });
    // if (pdfInfo.processing_format == "productWise" && asset) {
    //   console.log("prod ssd");
    //   newdata?.["member-wise-modules-data"].productWiseMemberData?.map(
    //     (member) => {
    //       let list =
    //         member?.data?.products && Object.keys(member?.data?.products);
    //       let count = 0;
    //       if (member.memberName == "Divita Juneja") {
    //         console.log(list, "pdfinfo");
    //       }

    //       list?.map((product) => {
    //         let prodarr = member?.data?.products?.[product]?.holdings;
    //         let total = member?.data?.products?.[product]?.total;
    //         let bread = [...breadcrumbs];

    //         bread.push(
    //           <a
    //             underline="hover"
    //             key="2"
    //             color="
    //               #000000"
    //           >
    //             <span className="opc80">Product :</span>
    //             <span className="pdfselectedTxt">{product}</span>
    //           </a>
    //         );
    //         prodarr &&
    //           (prodarr[prodarr.length - 1]?.instrumentName != "Total" ||
    //             prodarr[prodarr.length - 2]?.instrumentName != "Total") &&
    //           prodarr.push({
    //             isin: "",

    //             saleDate: "--",
    //             quantity: "--",
    //             saleRate: "--",
    //             saleAmount: "--",
    //             purchaseDate: "--",
    //             purchaseRate: "--",
    //             purchaseAmount: "--",
    //             holdingPeriod: "--",

    //             instrumentName: "Total",
    //             isin: "Total",
    //             ltcg: total.productTotalLtcg,
    //             stcg: total.productTotalStcg,
    //           });
    //         if (prodarr) {
    //           count = count + 1;
    //           if (count == list.length) {
    //             prodarr.push({
    //               saleDate: "--",
    //               quantity: "--",
    //               saleRate: "--",
    //               saleAmount: "--",
    //               purchaseDate: "--",
    //               purchaseRate: "--",
    //               purchaseAmount: "--",
    //               holdingPeriod: "--",

    //               NoOfTransection:
    //                 member?.data?.overview.overAllMemberNoOfTransactions,
    //               instrumentName: "Grand Total",
    //               isin: "",
    //               ltcg: member?.data?.overview.overAllMemberTotalLtcg,
    //               stcg: member?.data?.overview.overAllMemberTotalStcg,
    //             });
    //           }
    //         }

    //         arr.push(
    //           <TaxModule
    //             setChanges={setChanges}
    //             breadcrumbs={bread}
    //             memberName={member.memberName}
    //             denomination={denomination}
    //             newdata={prodarr}
    //             subtext={""}
    //             product={product}
    //             pdfInfo={pdfInfo}
    //           />
    //         );
    //       });
    //     }
    //   );
    // } else {
    //   if (asset) {
    //     newdata?.["member-wise-modules-data"]?.assetWiseMemberData?.map(
    //       (member) => {
    //         asset.map((a, i) => {
    //           let list =
    //             member?.data?.assets?.[a]?.holdingSummary &&
    //             Object.keys(member?.data?.assets?.[a]?.holdingSummary);
    //           let count = 0;
    //           newdata?.["asset-wise-product-sort-order"][a]?.map((product) => {
    //             let prodarr =
    //               member?.data?.assets?.[a]?.holdingSummary?.[product]?.data
    //                 ?.holdings;
    //             let total =
    //               member?.data?.assets?.[a]?.holdingSummary?.[product]?.data
    //                 ?.total;
    //             let bread = [...breadcrumbs];
    //             bread.push(
    //               <a
    //                 underline="hover"
    //                 key="2"
    //                 color="
    //           #000000"
    //               >
    //                 <span className="opc80"> Asset Class :</span>
    //                 <span className="pdfselectedTxt pdfCaps">{a}</span>
    //               </a>
    //             );

    //             bread.push(
    //               <a
    //                 underline="hover"
    //                 key="2"
    //                 color="
    //               #000000"
    //               >
    //                 <span className="opc80">Product :</span>
    //                 <span className="pdfselectedTxt">{product}</span>
    //               </a>
    //             );
    //             prodarr &&
    //               prodarr.push({
    //                 isin: "",

    //                 saleDate: "--",
    //                 quantity: "--",
    //                 saleRate: "--",
    //                 saleAmount: "--",
    //                 purchaseDate: "--",
    //                 purchaseRate: "--",
    //                 purchaseAmount: "--",
    //                 holdingPeriod: "--",

    //                 instrumentName: "Total",
    //                 isin: "Total",
    //                 ltcg: total.productTotalLtcg,
    //                 stcg: total.productTotalStcg,
    //               });
    //             if (prodarr) {
    //               count = count + 1;
    //               if (count == list.length) {
    //                 prodarr.push({
    //                   saleDate: "--",
    //                   quantity: "--",
    //                   saleRate: "--",
    //                   saleAmount: "--",
    //                   purchaseDate: "--",
    //                   purchaseRate: "--",
    //                   purchaseAmount: "--",
    //                   holdingPeriod: "--",
    //                   NoOfTransection:
    //                     member?.data?.assets?.[a]?.overAllAssetNoOfTransactions,
    //                   instrumentName: "Grand Total",
    //                   isin: "",
    //                   ltcg: member?.data?.assets?.[a]?.overAllAssetTotalLtcg,
    //                   stcg: member?.data?.assets?.[a]?.overAllAssetTotalStcg,
    //                 });
    //               }
    //             }

    //             arr.push(
    //               <TaxModule
    //                 setChanges={setChanges}
    //                 breadcrumbs={bread}
    //                 memberName={member.memberName}
    //                 denomination={denomination}
    //                 newdata={prodarr}
    //                 subtext={""}
    //                 product={product}
    //                 pdfInfo={pdfInfo}
    //               />
    //             );
    //           });
    //         });
    //       }
    //     );
    //     // console.log(arr, "arr");
    //   }
    // }
    // return arr;
  }

  return <div>{newdata && pdfSort(newdata)}</div>;
}
