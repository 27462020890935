import React, { useState } from "react";
import UserManagementTableWrapper from "../UserManagementTableWrapper";
import {
  AdminHeaderUserManagment,
  bodyData3,
  headerData,
  headerDataPartner,
  headerDataPartner2,
} from "../utils/constands";
import { useEffect } from "react";
import { ReactComponent as EditIcon } from "../../../assets/icons/EditIcon.svg";

import { useLocation, useNavigate } from "react-router-dom";
import { Add } from "@material-ui/icons";
import AddPartnerUserFlow from "../PartnerUserFlow/AddPartnerUserFlow";

import AddButton from "../../common/AddButton";

import useUpdateUsers from "../../../hooks/useUpdateUsers";
import PageLoader from "../../dashboard/products/common/pageLoader";
import { handleRoleId } from "../utils/cmmon";
import { partnerRepRoles } from "../../../Constants";
import AddAdminRepresentativeFlow from "./AddAdminRepresentativeFlow";
import { useAdminListing } from "../../../hooks/useAdminListing";
import AddAdminUserFlow from "./AddAdminUserFlow";
import { updateAdminUser } from "../../../api";
import ls from "localstorage-slim";

function NewAdminUserManagement() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [openModalRep, setOpenModalRep] = useState(false);
  const [header, setHeader] = useState([]);
  const [data, setData] = useState([]);
  const [selectedUserData, setSelecteduserData] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [offset, setItemOffset] = useState(0);
  const { listingData, getData, totalPage, loading } = useAdminListing(
    searchTerm,

    offset
  );
  const scopes = JSON.parse(ls.get("scopes"));

  const { updateUsers, load } = useUpdateUsers({});
  function handleSave(data, id) {
    updateAdminUser(id, data)
      .then((res) => {
        // getData();
        return;
      })
      .catch((err) => {});
  }
  const handleHeader = (headerData) => {
    headerData?.map((val) => {
      if (val?.field == "status") {
        val.onClick = (data) => {
          //here we will get data for edit modal
          // console.log(data, "agklangj");
          setSelecteduserData(() => data);
        };
        val.handleTogle = ({ rowData, toglge }) => {
          let payload = {
            status: toglge == "false" ? 0 : 1,
            roleId: rowData.partnerRoleId
              ? `${rowData.partnerRoleId}`
              : `${handleRoleId(rowData?.partnerRole, partnerRepRoles)}`,
          };
          updateUsers({
            data: payload,
            id: rowData?.userId,
            type: "partnerUser",
          });
          // console.log(rowData, toglge, "agjkbahj");
        };
      }
      val.handleTogle = (data) => {
        console.log("toggled", data);
        let data1 = {
          status: "" + data.toglge == "true" ? 1 : 0,
        };
        // if (data?.rowData?.member_role_id) {
        //   data1["roleId"] = "" + data.rowData.member_role_id;
        // }
        // if (data?.rowData?.) {
        //   data1["roleId"] = "" + data.rowData.member_role_id;
        // }

        handleSave(data1, data?.rowData?.id);
      };
    });
    setHeader(() => headerData);
  };

  const hideAlert = () => {
    setTimeout(() => {
      const customDiv = document.getElementById("customAlert");
      customDiv.style.display = "none";
    }, 2000);
  };

  useEffect(() => {
    if (selectedUserData && Object.keys(selectedUserData).length > 0) {
      setEditModal(true);
    }
  }, [selectedUserData]);

  useEffect(() => {
    setData(() => listingData);
    handleHeader(AdminHeaderUserManagment);
  }, [listingData]);

  useEffect(() => {
    handleHeader(AdminHeaderUserManagment);
    return () => {
      setData([]);
      setHeader([]);
    };
  }, []);

  const handleAddUserClose = () => {
    setSelecteduserData(() => {});
  };

  const handleOpenAddRepModal = () => {
    // setOpenModalRep(true);
  };

  // console.log(scopes?.can_add_user_management_member, "aglak");
  return (
    <>
      {header?.length > 0 && (
        <UserManagementTableWrapper
          customScopes={[scopes?.can_add_user_management_member]}
          customTitle="User Management"
          addBtnTitle="Add User"
          headerData={header}
          loading={loading}
          data={listingData}
          totalPage={totalPage}
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
          listingData={listingData}
          openModal={openModal}
          setOpenModal={setOpenModal}
          setItemOffset={setItemOffset}
          offset={offset}
          returnno={true}
          customIcon={
            <EditIcon
              style={{ marginRight: "38px", cursor: "pointer" }}
              onClick={() => {
                console.log("edit clicked");
              }}
            />
          }
          // addRepComp={
          //   <AddButton
          //     handleIconClick={handleOpenAddRepModal}
          //     addBtnTitle={"Add Partner Representative / Banker"}
          //   />
          // }
        />
      )}
      {/* {openModal && ( */}
      <AddAdminUserFlow
        handleClose={handleAddUserClose}
        openModal={openModal}
        setOpenModal={setOpenModal}
        hideAlert={hideAlert}
        onUpdate={() => {
          getData();
        }}
      />
      <AddAdminUserFlow
        handleClose={handleAddUserClose}
        selectedData={selectedUserData}
        openModal={editModal}
        setOpenModal={setEditModal}
        hideAlert={hideAlert}
        onUpdate={() => {
          getData();
        }}
      />
      {/* // )} */}
      {/* <AddUserFlow
        handleClose={handleAddUserClose}
        selectedData={selectedUserData}
        openModal={openModal}
        setOpenModal={setOpenModal}
      /> */}
      {/* {openModalRep && (
        <AddAdminRepresentativeFlow
          handleClose={() => {
            handleAddUserClose();
          }}
          onUpdate={() => {
            getData();
          }}
          selectedData={selectedUserData}
          openModal={openModalRep}
          setOpenModal={setOpenModalRep}
        />
      )} */}
      <PageLoader showLoader={loading || load} />
    </>
  );
}

export default NewAdminUserManagement;
