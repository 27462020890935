import { useEffect, useState } from "react";
import "./ActiveInactiveToggle.css";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
export const ToogleDropDown = ({
  isActive,
  toggleIndex,
  handleChange,
  disabled,
}) => {
  const [active, setActive] = useState(false);
  console.log(isActive, "agljagj");

  // useEffect(() => {
  //   if (isActive === 0) {
  //     setActive(true);
  //   }
  //   if (isActive === 1) {
  //     setActive(false);
  //   }

  //   if (isActive === "INACTIVE") {
  //     setActive(true);
  //   }
  //   if (isActive === "ACTIVE") {
  //     setActive(false);
  //   }
  // }, []);

  const hadnleChange1 = (e) => {
    console.log(e.target.value, "lakgajkgj");
    handleChange(e.target.value);
    setActive((prev) => !prev);
  };
  return (
    <>
      <div
        className="btn-container"
        style={{
          cursor: "pointer",
          pointerEvents: disabled && disabled() == true ? "none" : "all",
          position: "relative",
        }}
      >
        {disabled && disabled() == true && (
          <div className="disabledLayer1"></div>
        )}
        <ExpandMoreOutlinedIcon
          style={{ color: "#fff" }}
          onClick={() => {
            setActive(!active);
            handleChange(!active, isActive);
          }}
        />
      </div>
      {/* <pre>{JSON.stringify(active)}</pre> */}
    </>
  );
};
