import React, { useState } from "react";
import { createLiasonOfficer } from "../../../api";

function useUserAddClientUserFlowApi() {
  const [loading, setLoading] = useState(false);

  const createClientUser = async ({ clientId, payload }) => {
    setLoading(true);
    try {
      let res = await createLiasonOfficer(payload, clientId);
      // console.log(res, "agalgnj");
      setLoading(false);
      return res;
    } catch (error) {
      setLoading(false);
      console.log(error, "aglagjk");

      return error;
    }
  };

  return { createClientUser, loading };
}

export default useUserAddClientUserFlowApi;
