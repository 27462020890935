import React, { useContext, useEffect, useRef, useState } from "react";
import { getTransactionsSummary, getUpcomingTransactions } from "../api";
import { mfoFilterContext } from "../mfo_context/Mfo_Context";
import { OnboardingContext } from "../Context";
import { ITEMS_LIMIT_FILES_PARTNER_LIST } from "../Constants";

function useGetTableUpcoming({
  tableName,
  offset,
  sortBy,

  setDeleteList,
  setShowDelete,
  holdingCardData,

  setTableColumns,
}) {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const { userState } = OnboardingContext();
  const [paginationData, setPaginationData] = useState({ pageCount: 0 });
  const numberOfInstrumentsCount = useRef(0);
  const [numberOfInstruments, setNumberOfInstruments] = useState(0);

  const getTableData = async () => {
    setTableColumns([]);
    setTableData([]);
    setPaginationData((prev) => {
      return {
        ...prev,
        pageCount: 0,
      };
    });
    setLoading(true);

    try {
      // const filterParams = { ...getfilter_as_params() };
      // let assetClass = "";

      let params = {
        clientId: userState.loginedUserData.id,

        offset: offset,
        sortBy: sortBy,
        limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
      };

      let tableName1 = "";

      let res = await getUpcomingTransactions(params);
      // console.log(res, "slbjkgbs");

      setPaginationData((prev) => ({
        ...prev,
        pageCount: res?.meta?.count,
      }));
      setTableData(() => res?.result);

      //code for number of instruments and total portfolio

      if (numberOfInstrumentsCount.current === 0) {
        setNumberOfInstruments(res?.data?.data?.meta?.count);
      }
      numberOfInstrumentsCount.current = numberOfInstrumentsCount.current + 1;
      setDeleteList({});
      setShowDelete(false);
      setLoading(false);
    } catch (error) {
      console.log(error, "slbjkgb");
      setTableData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, [mfoFilter, offset]);

  return {
    getTableData,
    tableData,
    loading,
    paginationData,
    numberOfInstruments,
  };
}

export default useGetTableUpcoming;
