import { useState, useEffect, useContext } from "react";
import { OnboardingContext } from "../Context";
import { mfoFilterContext } from "../mfo_context/Mfo_Context";
import moment from "moment";
import { dateFormatCommon, roundNumber } from "../components/utils/common";
import { currencyFormat } from "./constantFunction";
import { colorAndProdBank } from "../components/dashboard/products/utils/constands/prodAndColor";
import { colors_allocation } from "../components/charts/colors";
import { fetchTopFiveIssuers } from "../api";

export const useTop5Issuer = ({ setPieData, setLoading }) => {
  const { userState } = OnboardingContext();
  let clientId = userState.loginedUserData.id;
  const { mfoFilter } = useContext(mfoFilterContext);
  const [topData, setTopData] = useState([]);
  const [totalData, setTotalData] = useState(0);

  const handleData = (data) => {
    let pieData = [];
    let newData = data?.topFiveIssuers?.map((val, i) => {
      pieData.push({
        id: val.issuer,
        label: val.issuer,
        value: val.percentage,
        color: colors_allocation[i],
        amount: val.valuation,
      });
      return {
        issuer: val?.issuer,
        percentage: val?.percentage
          ? roundNumber(val?.percentage) + "%"
          : "0.00%",
        valuation: val?.valuation
          ? currencyFormat(val?.valuation)
          : currencyFormat(0),
      };
    });
    setPieData(pieData);
    setTotalData(
      data?.topFiveIssuerValuation
        ? currencyFormat(data?.topFiveIssuerValuation)
        : currencyFormat(0)
    );

    setTopData(() => [...newData?.filter((val) => val !== undefined)]);
  };

  useEffect(() => {
    let params = {};

    if (mfoFilter.startDate) {
      params["startDate"] = moment(mfoFilter.startDate).format("YYYY-MM-DD");
      params["endDate"] = moment(mfoFilter.endDate).format("YYYY-MM-DD");
    }
    if (Object.keys(mfoFilter.checkData).length > 0) {
      params["memberId"] = Object.keys(mfoFilter.checkData).join(",");
    }
    if (Object.keys(mfoFilter.advisor).length > 0) {
      params["advisor"] = Object.keys(mfoFilter.advisor).join(",");
    }
    params["assetClass"] = "equity,debt,cash,alternate";

    fetchTopFiveIssuers(clientId, params)
      .then((response) => {
        const apiData = response?.data?.data?.result;
        handleData(apiData);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(`An Error ${err} occurred`);
      });
  }, [mfoFilter]);
  return { totalData, topData };
};
