import React, { useEffect, useState } from "react";
import { handleInitValue } from "../utils/cmmon";
import { useForm } from "../../NewSignUp/hooks/useForm";
import {
  addPartnerRepSchema,
  addPartnerRepSchemaEdit,
  addUserSchema,
} from "../../NewSignUp/ValidationSchema";
import PartnerHeading from "../../NewSignUp/PartnerHeading";
import { Box, Grid, MenuItem } from "@material-ui/core";
import CustomInput from "../../NewSignUp/common/CustomInput";
import {
  adduserRoles,
  adduserStatus,
  MASTERLOBREGION,
  partnerRepRoles,
} from "../../../Constants";
import ErrorMessageComp from "../../NewSignUp/common/ErrorMessageComp";
import LoadingButton from "../../NewSignUp/common/LoadingButton";
import StepProgressBar from "../../NewSignUp/common/StepProgressBar/StepProgressBar";
import { useFetchMasterLob } from "../../../hooks/useFetchMasterLob";
import { fetchSubLobMaster } from "../../../api";

function AddRepForm1({
  handleNext,
  handleBack,
  currentStep,
  customHandleSubmit,
  head,
  onlyPerosn,
  apiErrorMessage,
  loading,
  formData,
  totalStep,
  noStepBottom,
  formMaxHeight,
  noStep,
  formWidth,
  edit,
}) {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useForm(
    {
      fullName: formData?.name ? formData?.name : "",
      //   status: formData?.status !== undefined ? formData?.status : "",
      userRole: formData?.partnerRole
        ? handleInitValue(formData?.partnerRole, partnerRepRoles)
        : "",
      //   panNumber: formData?.panNumber ? formData?.panNumber : "",
      //   dateOfBirth: formData?.dateOfBirth ? formData?.dateOfBirth : "",
      //   formData?.entityType == "sole_proprietor"
      //   ? formData?.dateOfBirth
      //   : formData?.doi
      //   ? formData?.doi
      //   : "",
      email: formData?.email ? formData?.email : "",
      mobile: formData?.mobile ? formData?.mobile : "",
      lobId: formData?.lobId ? formData?.lobId : "",
      subLobId: formData?.subLobId ? formData?.subLobId : "",
      region: formData?.region ? formData?.region : "",
    },
    // test,
    (values) => {
      console.log(values, "aflkbabfbuawubfw");
      customHandleSubmit(values);
    },
    edit ? addPartnerRepSchemaEdit : addPartnerRepSchema
  );

  const { lobList } = useFetchMasterLob();
  const [subLobList, setSubLobList] = useState([]);

  useEffect(() => {
    if (values.lobId) {
      fetchSubLobMaster(values?.lobId)
        .then((response) => {
          if (response?.data?.data?.result) {
            setSubLobList(response?.data?.data?.result);
          }
        })
        .catch((err) => {
          console.error(`An Error ${err} occurred`);
        });
    }
  }, [values.lobId]);

  return (
    <div
      className="dFlex jtfyContCntr alignCntr h100 flexDirCol"
      style={{ gap: "25px", width: formWidth }}
    >
      <div className="newFormTopSec">
        <PartnerHeading
          mainHead={head?.main ? head?.main : "Add Details"}
          subtitle={
            head?.sub ? head?.sub : "Join us by providing your company details"
          }
          handleBack={handleBack}
          step={currentStep}
          noStep={noStep}
        />
      </div>
      <div className="newFormContainer" style={{ maxHeight: formMaxHeight }}>
        <Box
          className="dFlex jtfyContCntr alignCntr flexDirCol"
          sx={{ width: { xs: "auto", sm: "330px" }, gap: "41px" }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid xs={12} lg={12} item>
                <CustomInput
                  title="Full Name"
                  // as={TextField}
                  variant="outlined"
                  margin="normal"
                  // required
                  fullWidth
                  id="fullName"
                  label="Full Name"
                  name="fullName"
                  autoComplete="fullName"
                  autoFocus
                  error={touched.fullName && Boolean(errors.fullName)}
                  helperText={touched.fullName && errors.fullName}
                  value={values.fullName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid xs={12} lg={12} item>
                <CustomInput
                  title="user role"
                  //   disabled={true}
                  // as={TextField}
                  select
                  variant="outlined"
                  margin="normal"
                  // required
                  fullWidth
                  id="userRole"
                  label="User Role"
                  name="userRole"
                  autoComplete="userRole"
                  //   autoFocus
                  error={touched.userRole && Boolean(errors.userRole)}
                  helperText={touched.userRole && errors.userRole}
                  value={values.userRole}
                  onChange={handleChange}
                >
                  {partnerRepRoles.map((entity) => {
                    return (
                      <MenuItem
                        key={entity.value}
                        value={entity.value}
                        selected={entity?.selected}
                        color="white"
                        style={{
                          border: "0.34px solid #3E5389",
                          backgroundColor: "#131826",
                        }}
                      >
                        {entity.name}
                      </MenuItem>
                    );
                  })}
                </CustomInput>
              </Grid>
              {!edit && (
                <>
                  <Grid
                    xs={12}
                    lg={12}
                    item
                    // pr={{ xs: 0, sm: 0, md: 0, lg: 1 }}
                  >
                    <CustomInput
                      title="Mobile no."
                      // as={TextField}
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      id="mobile"
                      label="Mobile Number"
                      name="mobile"
                      error={touched.mobile && Boolean(errors.mobile)}
                      helperText={touched.mobile && errors.mobile}
                      value={values.mobile}
                      onChange={handleChange}
                      // maxLength={10}
                    />
                  </Grid>
                  <Grid xs={12} lg={12} item>
                    <CustomInput
                      // as={TextField}
                      title="EMAIL ID"
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      //   autoFocus
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      value={values.email}
                      onChange={handleChange}
                    />
                  </Grid>
                </>
              )}
              {/* <Grid xs={12} lg={12} item>
                <CustomInput
                  // as={TextField}
                  select
                  fullWidth
                  title="Status"
                  variant="outlined"
                  margin="normal"
                  // required
                  id="status"
                  label="Status"
                  name="status"
                  autoComplete="status"
                  // autoFocus
                  // type="select"
                  error={touched.status && Boolean(errors.status)}
                  helperText={touched.status && errors.status}
                  value={values.status}
                  onChange={handleChange}
                >
                  {adduserStatus.map((entity) => {
                    return (
                      <MenuItem
                        key={entity.value}
                        value={entity.value}
                        selected={entity?.selected}
                        style={{
                          border: "0.34px solid #3E5389",
                          backgroundColor: "#131826",
                        }}
                      >
                        {entity.name}
                      </MenuItem>
                    );
                  })}
                </CustomInput>
              </Grid> */}
              {!edit && (
                <>
                  <Grid xs={12} lg={12} item>
                    <CustomInput
                      title="Lob"
                      //   disabled={true}
                      // as={TextField}
                      select
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      id="lob"
                      label="LOB"
                      name="lobId"
                      autoComplete="lobId"
                      //   autoFocus
                      error={touched.lobId && Boolean(errors.lobId)}
                      helperText={touched.lobId && errors.lobId}
                      value={values.lobId}
                      onChange={handleChange}
                    >
                      {lobList.map((entity) => {
                        return (
                          <MenuItem
                            key={entity.id}
                            value={entity.id}
                            selected={entity?.id}
                            color="white"
                            style={{
                              border: "0.34px solid #3E5389",
                              backgroundColor: "#131826",
                            }}
                          >
                            {entity.name}
                          </MenuItem>
                        );
                      })}
                    </CustomInput>
                  </Grid>
                  <Grid xs={12} lg={12} item>
                    <CustomInput
                      title="Sub Lob"
                      //   disabled={true}
                      // as={TextField}
                      select
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      id="sublob"
                      label="SUB LOB"
                      name="subLobId"
                      autoComplete="subLobId"
                      //   autoFocus
                      error={touched.subLobId && Boolean(errors.subLobId)}
                      helperText={touched.subLobId && errors.subLobId}
                      value={values.subLobId}
                      onChange={handleChange}
                    >
                      {subLobList.map((entity) => {
                        return (
                          <MenuItem
                            key={entity.id}
                            value={entity.id}
                            selected={entity?.id}
                            color="white"
                            style={{
                              border: "0.34px solid #3E5389",
                              backgroundColor: "#131826",
                            }}
                          >
                            {entity.name}
                          </MenuItem>
                        );
                      })}
                    </CustomInput>
                  </Grid>
                  <Grid xs={12} lg={12} item>
                    <CustomInput
                      title="Region"
                      //   disabled={true}
                      // as={TextField}
                      select
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      id="region"
                      label="REGION"
                      name="region"
                      autoComplete="region"
                      //   autoFocus
                      error={touched.region && Boolean(errors.region)}
                      helperText={touched.region && errors.region}
                      value={values.region}
                      onChange={handleChange}
                    >
                      {MASTERLOBREGION.map((entity) => {
                        return (
                          <MenuItem
                            key={entity.value}
                            value={entity.value}
                            selected={entity?.value}
                            color="white"
                            style={{
                              border: "0.34px solid #3E5389",
                              backgroundColor: "#131826",
                            }}
                          >
                            {entity.name}
                          </MenuItem>
                        );
                      })}
                    </CustomInput>
                  </Grid>
                </>
              )}
              <div className="newFormBottonSec">
                <Grid xs={12} lg={12} style={{ marginTop: "30px" }} item>
                  <ErrorMessageComp errorMessage={apiErrorMessage} />
                  <LoadingButton
                    buttonTitle={"Save"}
                    type="submit"
                    showLoader={loading}
                  />
                </Grid>
                {noStepBottom ? (
                  ""
                ) : (
                  <Grid xs={12} item style={{ marginTop: "28px" }}>
                    <StepProgressBar
                      total={totalStep}
                      currentStep={currentStep}
                    />
                  </Grid>
                )}
              </div>
            </Grid>
          </form>
        </Box>
      </div>
    </div>
  );
}

export default AddRepForm1;
