import React, { useState, useContext, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./NewMenu.css";
import { Button, Box } from "@material-ui/core";
import { ReactComponent as CheckCircleIcon } from "../../assets/icons/newMenuCheckCircleIcon.svg";
import { mfoFilterContext } from "../../mfo_context/Mfo_Context";
import { get_path_type } from "../utils/strHelper";
import { useNavigate } from "react-router";
import { useMastersList } from "../../hooks/useMastersList";
import { routes } from "../../routes";
import Loader from "../loader/Loader";
import { useNewMenuStyles } from "./newMenuStyles";
const productRoutes = {
  ["Mutual Funds"]: routes.mfSummaryPage,
  ["PMS"]: routes.pmsSummaryPage,
  ["AIF"]: routes.aifSummaryPage,
  ["Real Estate"]: routes.realEstateSummaryPage,
  ["Insurance"]: routes.insuranceSummaryPage,
  ["Bonds"]: routes.bondsSummaryPage,
  ["Direct Equity"]: routes.equitySummaryPage,
  ["Structured Product"]: routes.structuredProdSummaryPage,
  ["Unlisted Equity"]: routes.privateMarketSummaryPage,
  ["MLD"]: routes.mldSummaryPage,
  ["REITS"]: routes.reitsSummaryPage,
  ["INVITS"]: routes.invitsSummaryPage,
  ["ICD"]: routes.icdSummaryPage,
  ["Bank Book"]: routes.bankBookSummaryPage,
  ["Fixed Deposit"]: routes.fixedDepositSummaryPage,
  ["Recurring Deposit"]: routes.recurringDepositSummaryPage,
};
const useStyles = makeStyles((theme) => ({
  root: {
    width: "95%",
    margin: "22px auto",
  },
  heading: {
    color: "#FFF",
    fontFamily: "Avenir",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "24px",
    "&. hover": {
      color: "white",
    },
  },
  assetClassBoxWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "0.3rem",
    flexWrap: "wrap",
  },
  assetClassBox: {
    height: "56px",
    width: "102px",
    background: "black",
    display: "flex",
    borderRadius: "6px",
    backgroundColor: "#0E081D",
    padding: "10px",
    cursor: "pointer",
  },
}));

export function NewMenuAccordion({
  searchTerm,
  expandedAccordions,
  setExpandedAccordions,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const menuStyleClasses = useNewMenuStyles();

  const { mfoFilter, getfilter_as_params, setMfofilter } =
    useContext(mfoFilterContext);
  const { mastersData, loading } = useMastersList(searchTerm);
  const [productName, setProductName] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const navigate = useNavigate();
  //   const [expandedAccordions, setExpandedAccordions] = useState([]);

  const [assetClasses, setAssetClasses] = useState({
    ["Mutual Funds"]: [
      { name: "Equity", selected: false },
      { name: "Debt", selected: false },
      { name: "Alternate", selected: false },
      { name: "Cash", selected: false },
    ],
    ["PMS"]: [
      { name: "Equity", selected: false },
      { name: "Debt", selected: false },
      { name: "Alternate", selected: false },
    ],
    ["AIF"]: [
      { name: "Equity", selected: false },
      { name: "Debt", selected: false },
      { name: "Alternate", selected: false },
    ],
    ["Real Estate"]: [{ name: "Alternate", selected: false }],
    // ["Insurance"]: [
    //   { name: "Equity", selected: false },
    //   { name: "Debt", selected: false },
    //   { name: "Alternate", selected: false },
    //   { name: "Cash", selected: false },
    // ],
    ["Insurance"]: [{ name: "Alternate", selected: false }],
    ["Bonds"]: [{ name: "Debt", selected: false }],
    ["Direct Equity"]: [{ name: "Equity", selected: false }],
    ["Structured Product"]: [{ name: "Alternate", selected: false }],
    ["Unlisted Equity"]: [{ name: "Equity", selected: false }],
    ["MLD"]: [{ name: "Alternate", selected: false }],
    ["REITS"]: [{ name: "Alternate", selected: false }],
    ["INVITS"]: [{ name: "Alternate", selected: false }],
    ["ICD"]: [{ name: "Debt", selected: false }],
    ["Bank Book"]: [{ name: "Cash", selected: false }],
    ["Fixed Deposit"]: [{ name: "Debt", selected: false }],
    ["Recurring Deposit"]: [{ name: "Debt", selected: false }],
  });
  const productWithSingleAssetClass = [
    "ICD",
    "INVITS",
    "REITS",
    "MLD",
    "Real Estate",
    "Bonds",
    "Direct Equity",
    "Bank Book",
    "Fixed Deposit",
    "Recurring Deposit",
    "Structured Product",
    "Insurance",
    "Unlisted Equity",
  ];
  //   const handleChange = (panel) => (event, isExpanded) => {
  //     if (isExpanded) {
  //       setExpandedAccordions([...expandedAccordions, panel]);
  //     } else {
  //       setExpandedAccordions(
  //         expandedAccordions.filter((item) => item !== panel)
  //       );
  //     }
  //   };

  const handleChange = (panel, product, index) => (event, isExpanded) => {
    setSelectedProduct(product);
    // Detect Last Element
    // Check if the product has a single asset class selected
    if (productWithSingleAssetClass.includes(product)) {
      // Create an object with the asset class as the key and set it to true
      let assetClassObj = {
        [assetClasses[product][0].name.toLowerCase()]: true,
      };
      // Determine the path type based on the product route
      const pathtype = get_path_type(productRoutes[product]);
      // Update the asset class filter in the MFO filter object
      mfoFilter.assetclass[pathtype] = assetClassObj;
      // Update the MFO filter state
      setMfofilter({ ...mfoFilter });
      // Store the updated filter in session storage
      sessionStorage.setItem("filter", JSON.stringify(mfoFilter));
      // Navigate to the selected product's route
      navigate(productRoutes[product]);
      return;
    }

    if (isExpanded) {
      // Open the clicked accordion and close the others
      setExpandedAccordions([panel]);
    } else {
      // Close the clicked accordion
      setExpandedAccordions([]);
    }
  };

  //   const handleConfirmButton = (product) => {
  //     // Filter Out Only The Selected Asset Classes
  //     let filterSelectedAssetClasses = assetClasses.filter(
  //       (assetClass) => assetClass.selected
  //     );

  //     // Extract only the Asset Class Name and create an object
  //     let assetClassObj = {};
  //     filterSelectedAssetClasses.forEach((item) => {
  //       assetClassObj[item.name.toLowerCase()] = true;
  //     });

  //     let pathtype = get_path_type(productRoutes[product]);
  //     mfoFilter.assetclass[pathtype] = assetClassObj;
  //     setMfofilter({ ...mfoFilter });
  //     sessionStorage.setItem("filter", JSON.stringify(mfoFilter));
  //     navigate(productRoutes[product]);
  //     console.log("selectedAssetClasses", assetClassObj, pathtype, mfoFilter);
  //   };

  // Updated function to handle the confirm button
  // const handleConfirmButton = (product) => {
  //   // Initialize assetClassObj with all asset classes set to their selected status
  //   const assetClassObj = {};
  //   assetClasses[product].forEach((assetClass) => {
  //     assetClassObj[assetClass.name.toLowerCase()] = assetClass.selected;
  //   });

  //   const pathtype = get_path_type(productRoutes[product]);
  //   mfoFilter.assetclass[pathtype] = assetClassObj;
  //   setMfofilter({ ...mfoFilter });
  //   sessionStorage.setItem("filter", JSON.stringify(mfoFilter));
  //   navigate(productRoutes[product]);
  // };
  const handleConfirmButton = (product) => {
    // Create an object with asset classes set to their selected status
    const assetClassEntries = assetClasses[product].map((assetClass) => [
      assetClass.name.toLowerCase(),
      assetClass.selected,
    ]);

    // Filter out the asset classes where selected is false
    const filteredAssetClassEntries = assetClassEntries.filter(
      ([, selected]) => selected
    );

    // Create an object from the filtered entries
    const assetClassObj = Object.fromEntries(filteredAssetClassEntries);

    const pathtype = get_path_type(productRoutes[product]);

    // Use the setter function for updating state in an immutable way
    setMfofilter((prevMfoFilter) => {
      return {
        ...prevMfoFilter,
        assetclass: {
          ...prevMfoFilter.assetclass,
          [pathtype]: assetClassObj,
        },
      };
    });

    // Make sure to stringify the latest state after it has been updated
    const newFilterState = {
      ...mfoFilter,
      assetclass: {
        ...mfoFilter.assetclass,
        [pathtype]: assetClassObj,
      },
    };
    sessionStorage.setItem("filter", JSON.stringify(newFilterState));

    navigate(productRoutes[product]);
  };

  // 👇 handle ScrollIntoView
  useEffect(() => {
    let scrollTimeout;
    let ele = document.getElementById(`Scheme-Master-${selectedProduct}`);
    let container = document.getElementById("Scheme-Master-Container");
    let lastElement = mastersData[mastersData?.length - 1]?.product_name;
    // console.log("lastElement", lastElement);
    if (ele) {
      if (lastElement === selectedProduct) {
        scrollTimeout = setTimeout(() => {
          ele.scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
        }, 300);
      } else {
        ele.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
    return () => {
      clearTimeout(scrollTimeout);
    };
  }, [selectedProduct]);

  return (
    <div className={classes.root} id="Scheme-Master-Container">
      {mastersData?.map((master, index) => (
        <Accordion
          id={`Scheme-Master-${master?.product_name}`}
          key={index}
          expanded={expandedAccordions.includes(`panel${index}`)}
          onChange={handleChange(`panel${index}`, master?.product_name, index)}
          className={
            expandedAccordions.includes(`panel${index}`)
              ? menuStyleClasses.expandedAccordion
              : menuStyleClasses.unexpandedAccordion
          }
          style={{
            padding: "10px 16px 10px 16px",
            background: "inherit",
            color: "white",
            borderRadius: "9px",
          }}
        >
          <AccordionSummary
            expandIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                className={
                  expandedAccordions.includes(`panel${index}`)
                    ? "expandedSvgAccordion"
                    : "collapsedSvgAccordion"
                }
              >
                <circle
                  cx="10"
                  cy="10"
                  r="10"
                  fill={theme.palette.rightArrowIcon.background}
                />
                <path
                  d="M7.28898 14.8013C7.1137 14.6709 7.00972 14.4876 7.00065 14.2931C6.99157 14.0985 7.07817 13.9092 7.24079 13.7681L10.8955 10.5094L7.28095 7.2507C6.92984 6.95678 6.92984 6.48489 7.28095 6.19097C7.64554 5.92166 8.20343 5.93924 8.54203 6.23071L12.759 9.97952C12.9146 10.1183 13.0006 10.3005 13 10.4895C13.0002 10.6765 12.914 10.8565 12.759 10.9929L8.54203 14.7616C8.37552 14.9092 8.14384 14.9951 7.89944 15C7.67272 15.0002 7.45443 14.9291 7.28898 14.8013Z"
                  fill="white"
                />
              </svg>
            }
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
            onClick={() => setProductName(master.product_name)}
          >
            <Typography
              className={classes.heading}
              style={{ color: theme.palette.newMenu.rightSideProductHeading }}
            >
              {master.product_name}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            // id={`Scheme-Master-${master?.product_name}`}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Typography className="newMenuAccordionContentHeading">
              Please select the asset classes for the above selected Product
              Summary.
            </Typography>
            {/* Rest of your content */}
            {/* <Box className={classes.assetClassBoxWrapper}>
              {assetClasses[productName]?.map((asset, index) => (
                <Box
                  className={classes.assetClassBox}
                  onClick={() => {
                    setAssetClasses((prevAssetClasses) =>
                      prevAssetClasses.map((prevAsset) =>
                        prevAsset.name === asset.name
                          ? { ...prevAsset, selected: !prevAsset.selected }
                          : prevAsset
                      )
                    );
                  }}
                >
                  <p style={{ alignSelf: "flex-end", margin: "0", flex: 1 }}>
                    {asset.name}
                  </p>
                  {asset.selected && (
                    <CheckCircleIcon style={{ color: "green" }} />
                  )}
                </Box>
              ))}

              <input
                type="checkbox"
                onClick={(e) => {
                  const isChecked = e.target.checked;
                  setAssetClasses((prev) =>
                    prev.map((assetClass) => ({
                      ...assetClass,
                      selected: isChecked,
                    }))
                  );
                }}
              />
              <label>Select All</label>
            </Box> */}
            <Box className={classes.assetClassBoxWrapper}>
              {assetClasses[productName]?.map((asset, index) => (
                <Box
                  className={classes.assetClassBox}
                  onClick={() => {
                    setAssetClasses((prevAssetClasses) => {
                      const updatedAssetClasses = {
                        ...prevAssetClasses,
                        [productName]: prevAssetClasses[productName].map(
                          (prevAsset) =>
                            prevAsset.name === asset.name
                              ? { ...prevAsset, selected: !prevAsset.selected }
                              : prevAsset
                        ),
                      };
                      return updatedAssetClasses;
                    });
                  }}
                >
                  <p style={{ alignSelf: "flex-end", margin: "0", flex: 1 }}>
                    {asset.name}
                  </p>
                  {asset.selected && (
                    <CheckCircleIcon id="newMenuCheckedCirlceIcon" />
                  )}
                </Box>
              ))}
              <div className="formGroup">
                <input
                  id={master?.product_name}
                  type="checkbox"
                  checked={
                    /* 
  Determine the state of the "Select All" checkbox based on whether 
  all individual checkboxes within the current product category 
  are selected or not. If all are selected, set the "Select All"
  checkbox to checked otherwise, set it to unchecked.
*/
                    assetClasses[productName]?.every(
                      (asset) => asset.selected
                    ) || false
                  }
                  onClick={(e) => {
                    const isChecked = e.target.checked;
                    setAssetClasses((prev) => ({
                      ...prev,
                      [productName]: prev[productName].map((assetClass) => ({
                        ...assetClass,
                        selected: isChecked,
                      })),
                    }));
                  }}
                />
                <label htmlFor={master?.product_name}>Select All</label>
              </div>
            </Box>

            {/* BUTTON */}
            <Button
              contained
              style={{
                borderRadius: "7px",
                background: "#4F1FDA",
                color: "white",
                padding: "7px 26px 7px 26px",
                textTransform: "capitalize",
                fontSize: "14px",
                fontWeight: 500,
                fontFamily: "Avenir",
                letterSpacing: "0.5px",
                width: "104px",
              }}
              onClick={() => handleConfirmButton(master.product_name)}
              disabled={
                // Determine the disabled state of the button based on whether
                // at least one asset class within the current product category
                // is selected or not. If none are selected, disable the button.
                !assetClasses[productName]?.some((asset) => asset.selected)
              }
            >
              Confirm
            </Button>
          </AccordionDetails>
        </Accordion>
      ))}
      {loading && <Loader />}

      {!loading && mastersData?.length === 0 && (
        <h1 className="notFoundText">No Products Found!</h1>
      )}
    </div>
  );
}
