import React, { useState, useContext } from "react";
import moment from "moment";
import useStyles from "../common/ProductsStyles";
import { Grid, Typography } from "@material-ui/core";
import { ReactComponent as Back } from "../../../../assets/holding-summary/back-blue.svg";
import { ReactComponent as Import } from "../../../../assets/icons/import.svg";
import { ReactComponent as Export } from "../../../../assets/icons/export.svg";
import { ReactComponent as Print } from "../../../../assets/icons/print.svg";
import { ReactComponent as EditColumn } from "../../../../assets/icons/edit-column.svg";
import { OnboardingContext } from "../../../../Context";
import { getHoldingsummary } from "../../../../api";
import { useEffect } from "react";
import SearchInput from "../common/SearchInput";
import { useNavigate } from "react-router-dom";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import { currencyFormat } from "../../../../hooks/constantFunction";
import PmsReportTable from "../pms/PmsReportTable";
import { dayFormatter2, roundNumber } from "../../../utils/common";
import TabComponent from "../common/TabComponent";
import PageFilter from "../common/PageFilter";
import { useLocation } from "react-router-dom";
import { useHoldingPeriod } from "../../../../hooks/useHoldingPeriod";
import { routes } from "../../../../routes";
const data = [
  { page: "equity", title: "Equity" },
  { page: "debt", title: "Debt" },
  { page: "alternate", title: "Alternate Assets" },
  { page: "cash", title: "Cash" },
];

export function MfoHoldingPeriod() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { isin, advisor, assetClass, type } = state;
  const [holdingPeriod, loading] = useHoldingPeriod(
    isin,
    advisor,
    assetClass,
    type
  );
  const tableColumnsData = [
    {
      field: "schemeName",
      title: "Scheme Name",
      show: true,
      align: "center",
    },
    { field: "isin", title: "ISIN No ", show: true, align: "center" },
    // 👇since folioNo was not present in api for type equity
    {
      field: type == "mf" ? "folioNo" : "accountNo",
      title: "Folio No ",
      show: true,
      align: "center",
    },
    { field: "nav", title: "NAV (Actuals) ", show: true },
    { field: "holdingUnits", title: "Units ", show: true },
    // { field: "advisor", title: "Advisor", show: true, align: "center" },
    {
      field: "presentValue",
      title: "Present Value",
      show: true,
      align: "center",
    },
    {
      field: "purchaseDate",
      title: "Purchase Date",
      show: true,
      align: "center",
    },
    {
      field: "todaysDate",
      title: "Today's Date",
      show: true,
      align: "center",
    },
    {
      field: "holdingPeriod",
      title: "Holding Days (in years)",
      show: true,
      align: "center",
    },
    {
      field: "investedValue",
      title: "Invested Value",
      show: true,
      align: "center",
    },
    { field: "irr", title: "IRR", show: true, align: "center" },
    // { field: "assetType", title: "Asset Type", show: true },
    // { field: "holdingUnits", title: "Balance Units", show: true },
    // { field: "valueDate", title: "Value Date", show: true, align: "center" },
    // { field: "xirr", title: "XIRR (%)", show: true },
    // { field: "absoluteTwrr", title: "Absolute TWRR (%)", show: true },
    // { field: "annualisedTwrr", title: "Annualised TWRR (%)", show: true },
    // { field: "avgHoldingPeriod", title: "Average Holding Period", show: true },
  ];

  // folio, units, purchase date, today's date, holding days, IRR
  const classes = useStyles();

  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  let assetOrder = ["equity", "debt", "alternate", "cash"];
  let selected_asset = getfilter_as_params().assetClass.split(",");
  let defaultAsset = assetOrder.find((a) => {
    if (selected_asset.includes(a)) {
      return a;
    }
  });
  const [page, setPage] = useState(defaultAsset);

  const [showfilters, setShowfilter] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { userState } = OnboardingContext();
  const clientId = userState.loginedUserData.id;
  const [tableColumns, setTableColumns] = useState(tableColumnsData);
  const [checkboxList, setCheckboxList] = useState(tableColumnsData);
  // const [assetClass, setAssetClass] = useState("equity");
  const [tableData, setTableData] = useState([]);
  const [load, setLoading] = useState(true);
  const [search, setSearch] = useState(null);

  const [tableHeader, setTableHeader] = useState(tableColumnsData);
  const [newTableColumns, setNewTableColumns] = useState([]);
  const [reset, setReset] = useState();
  const dateFormat = (dateStr) => {
    const date = moment(dateStr, "DD-MM-YYYY");
    const formattedDate = date.format("D MMM YYYY");
    return formattedDate;
  };

  const objectFormating = (data) => {
    let newData = tableHeader.map((header) => {
      if (header?.field == "schemeName") {
        return {
          field: data[`${header.field}`],
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (header?.field == "irr") {
        return {
          // field: roundNumber(data[`${header.field}`] * 100) + " %" || "--",
          field: roundNumber(data[`${header.field}`]) + " %" || "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (header?.field == "holdingPeriod") {
        return {
          field:
            //roundNumber(((data[`${header.field}`] / 365) * 100) / 100) || "--",
            roundNumber(data[`${header.field}`]) || "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (header?.field == "purchaseDate" || header?.field == "todaysDate") {
        return {
          field: dateFormat(data[`${header.field}`]) || "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      // if (header?.field == "avgHoldingPeriod") {
      //     field: data[`${header.field}`] + " Days" || "--",
      //     title: header.title,
      //     show: header.show,
      //     align: header?.align,
      //   };
      // }
      if (
        header?.field == "isin" ||
        header?.field == "folioNo" ||
        header?.field == "accountNo"
      ) {
        return {
          field: data[`${header.field}`],
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (
        header?.field == "investmentValue" ||
        header?.field == "presentValue" ||
        header?.field == "nav" ||
        header?.field == "investedValue"
      ) {
        let currencyformat = currencyFormat(data[`${header.field}`] || 0);
        if (header?.field == "nav") {
          currencyformat = currencyFormat(data[`${header.field}`] || 0, {
            format: "actuals",
          });
        }
        return {
          field: currencyformat || 0,
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      return {
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`]))
          : data[`${header.field}`] || "--",
        title: header.title,
        show: header.show,
        align: header?.align,
      };
    });
    return newData;
  };

  useEffect(() => {
    if (!search) {
      setLoading(true);
    }
    // getSummary();

    if (holdingPeriod?.length > 0) {
      setLoading(true);
      let newList = [];
      holdingPeriod.map((val) => {
        let obj = objectFormating(val);
        newList.push(obj);
      });
      setNewTableColumns(newList);
      setLoading(false);
    }
  }, [page, mfoFilter, reset, search, holdingPeriod]);

  let getSummary = () => {
    // console.log('akbhfuiawui');
    let params = {
      ...getfilter_as_params(),
      assetClass: page,
    };

    if (search) {
      params["schemeName"] = search;
    }
    setNewTableColumns([]);
    // userState.loginedUserData.id,
    getHoldingsummary(userState.loginedUserData.id, params)
      .then((res) => {
        // console.log(res.result);
        setTableData(res.result);
        //new table implimentation
        let newList = [];
        holdingPeriod.map((val) => {
          let obj = objectFormating(val);
          newList.push(obj);
        });
        setNewTableColumns(newList);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleIconClick = () => {
    setOpenModal(!openModal);
    setCheckboxList(tableColumns);
  };

  const handleCheckBoxClick = (e) => {
    const list = [...checkboxList];
    list.map((col, index) => {
      if (col.field === e.target.name) {
        const data = { ...col };
        data["show"] = !col.show;

        list[index] = { ...col, show: !col.show };
      }
    });

    setCheckboxList(list);
  };

  const handleButtonClick = () => {
    setOpenModal(!openModal);
    setTableColumns(checkboxList);
  };

  return (
    <>
      <PageFilter
        backLink={
          type === "mf" ? routes.mfDetailed_holding : routes?.equityHolding
        }
      ></PageFilter>

      <div style={{ height: "auto" }} className={classes.mainContent}>
        <div className={`${classes.alignTopHeader}`}>
          {/* <Back
            style={{ cursor: "pointer" }}
            onClick={() =>
              type === "mf"
                ? navigate(routes.mfDetailed_holding)
                : navigate(routes?.equityHolding)
            }
          /> */}
          <Typography
            // style={{ marginLeft: "-4%" }}
            className={`${classes.mfoGraphHeader} ${classes.mainHeadingAlign}`}
          >
            Holding Period
            <Typography
              style={{
                cursor: "pointer",
                marginTop: "1%",
              }}
              className={classes.mfoSubHeadText}
            >
              {sessionStorage.getItem("currency_format") != "actuals" &&
                sessionStorage.getItem("currency_format") && (
                  <span
                    className="subtext capitalize"
                    style={{ fontSize: "16px", paddingLeft: "4px" }}
                  >
                    {" "}
                    (values in {sessionStorage.getItem("currency_format")})
                  </span>
                )}
            </Typography>
          </Typography>
          <div className={`${classes.iconAlign}`}>
            <Import className={classes.iconStyle} />
            <Export className={classes.iconStyle} />
            <Print
              className={classes.iconStyle}
              onClick={() => {
                setShowfilter(true);
              }}
            />
            <EditColumn
              onClick={handleIconClick}
              className={classes.iconStyle}
            />
          </div>
        </div>
        {/* <TabComponent
          data={
            getfilter_as_params().assetClass
              ? data.filter((a) => {
                  
                  if (getfilter_as_params().assetClass.indexOf(a.page) >= 0) {
                    return a;
                  }
                })
              : data
          }
          page={page}
          setPage={setPage}
          type={"state"}
          width={"35%"}
        ></TabComponent> */}

        <>
          {/* <div style={{ display: "flex" }}>
            <Typography
              style={{
                cursor: "pointer",
                margin: "25px",
                marginBottom: "0",
                textTransform: "capitalize",
              }}
              className={classes.mfoGraphHeader}
            >
              {page}
            </Typography>
          </div> */}
          {/* <div style={{ margin: "25px", marginTop: "0" }}>
            <SearchInput setSearchName={setSearch} />
          </div> */}
          <Grid xs={12}></Grid>
          <PmsReportTable
            searchParam={search}
            setTableColumns={setNewTableColumns}
            tableColumns={newTableColumns}
            setOpenModal={setOpenModal}
            openModal={openModal}
            loading={load}
            headerList={tableColumnsData}
            tableHeader={tableHeader}
            setTableHeader={setTableHeader}
            stickyProductName={true}
            reset={getSummary}
            setReset={setReset}
            headerInTwoLine={true}
          />
        </>
      </div>
    </>
  );
}
