export const errorType = {
  ISIN: "isin",
  MANDATORY: "mandatory",
  MISCELLANEOUS: "miscellaneous",
  TRANSACTION_DATE: "transaction_date",
  DUPLICATE: "duplicate_records",
  TRANSACTION_NUMBER: "transaction_number",
};

export const mapKeyToName = (key) => {
  if (key === errorType.ISIN) {
    return "Invalid ISIN/Product Code";
  } else if (key === errorType.MANDATORY) {
    return "Mandatory Fields Check";
  } else if (key === errorType.TRANSACTION_DATE) {
    return "Transaction Date Validation";
  } else if (key === errorType.MISCELLANEOUS) {
    return "Data Format Check";
  } else if (key === errorType.DUPLICATE) {
    return "Duplicate Detected";
  } else if (key === errorType.TRANSACTION_NUMBER) {
    return "Duplicate Transaction Number Detected";
  } else {
    throw new Error(`Invalid key format ${key}`);
  }
};

export function formatString(str) {
  if (typeof str !== "string" || str === null || str === undefined) {
    return "";
  }

  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(" ");
}

export function reorderErrorList(errorList) {
  const orderedKeys = [
    "isin",
    "mandatory",
    "transaction_date",
    "miscellaneous",
    "duplicate_records",
    "transaction_number",
  ];

  let reorderedErrorList = {};
  orderedKeys.forEach((key) => {
    if (errorList.hasOwnProperty(key)) {
      reorderedErrorList[key] = errorList[key];
    }
  });

  return reorderedErrorList;
}

export function isOnlyDuplicateRecordsNonEmpty(apiData) {
  const errorCategories = Object.keys(apiData);

  for (let i = 0; i < errorCategories.length; i++) {
    const key = errorCategories[i];

    // Don't check for _id
    if (key === "_id") continue;

    if (key !== "duplicate_records" && apiData[key].length > 0) {
      return false;
    }
  }

  return apiData.duplicate_records && apiData.duplicate_records.length > 0;
}
