import React from "react";
import { TableContainer, Typography } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import "./tableSummaryDetails.css";
import ArrowButton from "../arrowButton/ArrowButton";
import { useEffect } from "react";
import { useState } from "react";
import { getColAndProd, prodAndKey } from "../../../utils/common/prodAndColor";
import { colorAndProd } from "../../../utils/constands/prodAndColor";
import { dateFormatCommon2 } from "../../../../../utils/common";
import { ActiveInactiveToggle } from "../../../../common/ActiveInactiveToggle";
// import { ActiveInactiveToggle } from "../.  ./../../common/ActiveInactiveToggle";
// import { useTheme } from "@mui/material";
import { ReactComponent as UpArrow } from "../../../../../../assets/icons/upArrow.svg";

function TableSummaryDetails({
  data,
  headerData,
  hideNavButtons,
  customColorAndProd,
  customProdAndKey,
  customHandleRow,
  customHandleData,
  customIcon,
  customStyle,
  onSort, // Function to handle sorting
  sortOrderDetails, // Object containing the current sort field and order
  summaryDetailsBodyMidCellsStyle, // to center algin table body text refer BondsCredit
  summaryDetailsLastCellStyle, // align last cell in tableBody Refer BondsCredit
  alignHeaderColumn = () => {}, // alignHeaderColumn is a function so that we can pass the current header column index to align it as flex-start Refer BondsCredit
  customFontWeight,
  fontSize,
  truncTitle,
}) {
  const [tableData, setTableData] = useState([]);

  const handleRow = (row, headerData) => {
    let newCOl = headerData?.map((col) => {
      if (col?.field == "date") {
        return {
          ...col,
          field: row?.dontShow
            ? col?.field == "title"
              ? row[`${col?.field}`]
              : "--"
            : row[`${col?.field}`] == "Invalid date" ||
              row[`${col?.field}`] == "" ||
              !row[`${col?.field}`]
            ? "--"
            : dateFormatCommon2(row[`${col?.field}`]),
          color2: getColAndProd(
            customColorAndProd ? customColorAndProd : colorAndProd,
            row?.title,
            customProdAndKey ? customProdAndKey : prodAndKey
          ),
          onClick: row?.onClick,
          dontShow: row?.dontShow,
          dontShowIcon: row?.hideNavButtons,
          align: row?.align,
        };
      } else {
        return {
          ...col,
          field: row?.dontShow
            ? col?.field == "title"
              ? row[`${col?.field}`]
              : "--"
            : row[`${col?.field}`] == "Invalid date" ||
              row[`${col?.field}`] == "" ||
              !row[`${col?.field}`]
            ? "--"
            : row[`${col?.field}`],
          color2: getColAndProd(
            customColorAndProd ? customColorAndProd : colorAndProd,
            row?.title,
            customProdAndKey ? customProdAndKey : prodAndKey
          ),
          onClick: row?.onClick,
          dontShow: row?.dontShow,
          align: row?.align,
        };
      }
    });

    return newCOl;
  };

  const handleData = (data, headerData) => {
    let newList = [];
    data?.map((row, i) => {
      if (customHandleRow) {
        newList.push(customHandleRow(row, headerData));
      } else {
        newList.push(handleRow(row, headerData, i));
      }
    });
    setTableData(newList);
  };

  useEffect(() => {
    if (customHandleData) {
      setTableData(customHandleData);
    } else {
      handleData(data, headerData);
    }
  }, [data]);

  const getTablebody = (data) => {
    return data.map((row, index) => (
      <>
        <TableRow className="top10">
          <TableCell className="top10"></TableCell>
        </TableRow>
        <TableRow key={row[0]?.field} className="tableRow">
          {row.map((column, i) =>
            i == 0 ? (
              <TableCell
                // key={i}
                style={{
                  backgroundColor: "#19122C",
                  // theme.palette.mode == "light"
                  //   ? theme.palette.primary.dark
                  //   : theme.palette.primary.light,
                }}
                className={`firstCellWrapper summaryDetailsBody ${column?.customCellClassName}`}
              >
                {column?.dontShow && <div className="disabledLayer"></div>}
                {!column?.dontShowFirstColBorder && (
                  <div
                    style={{
                      backgroundColor: column?.color2 ? column?.color2 : "",
                    }}
                    className="stColor"
                  ></div>
                )}
                <div
                  className={`firstCell1 ${column?.customCellClassName}`}
                  style={{
                    backgroundColor: "#19122C",
                    //   theme.palette.mode == "light"
                    //     ? theme.palette.primary.dark
                    //     : theme.palette.primary.light,
                  }}
                >
                  {column?.pic && <div className="tableImage"></div>}
                  <Tooltip title={column?.field} placement="bottom">
                    <span
                      className={`${
                        column?.tagPresent === 1 && index === 0
                          ? "colValue"
                          : ""
                      } ${column?.field?.length > 20 ? "truncate" : ""} ${
                        column?.customClass
                      }`}
                      title={column?.field}
                    >
                      <Typography
                        style={{
                          fontWeight:
                            customFontWeight || column?.fontWeight
                              ? customFontWeight
                              : "400",
                          cursor: column?.onClick ? "pointer" : "default",
                          fontSize: fontSize && "12px !important",
                        }}
                        // handler Function
                        onClick={column?.onClick}
                        className={`${
                          column?.spclFont ? column?.spclFont : ""
                        } capitalize ${fontSize ? "cellFont2" : "cellFont"} ${
                          i === 1 && !customFontWeight ? "assetWisetxt" : ""
                        } ${column?.customFontClassName}`}
                      >
                        {column?.field?.length > 20 && truncTitle
                          ? column?.field?.substr(0, 20) + "..."
                          : column?.field}
                      </Typography>
                    </span>
                  </Tooltip>
                </div>
              </TableCell>
            ) : row.length - 1 == i ? (
              <TableCell
                // key={i}
                style={{
                  backgroundColor: "#19122C",
                  // theme.palette.mode == "light"
                  //   ? theme.palette.primary.dark
                  //   : theme.palette.primary.light,
                  width: column?.width ? column?.width : "auto",
                  ...summaryDetailsLastCellStyle,
                }}
                className="lastCellWrapper"
              >
                {column?.dontShow && (
                  <div style={{}} className="disabledLayer"></div>
                )}
                <div
                  className={`lastCell dFlex jtfyContSpcBtwn alignCntr ${column?.customCellClassName}`}
                >
                  {column?.title === "Status" ? (
                    <div style={{ marginTop: "6px" }}>
                      {console.log("zustom toggle", column)}
                      <ActiveInactiveToggle
                        disabled={column?.disabled}
                        key={column?.field}
                        toggleIndex={index}
                        isActive={column?.field}
                        handleChange={column?.handleTogle}
                      />
                    </div>
                  ) : (
                    <Typography
                      variant={column?.fontVarient ? column?.fontVarient : "h6"}
                      style={{
                        fontWeight: column?.fontWeight
                          ? column?.fontWeight
                          : "400",
                      }}
                      className={`${
                        column?.spclFont ? column?.spclFont : ""
                      } capitalize cellFont ${column?.customFontClassName}`}
                    >
                      {column?.field}
                    </Typography>
                  )}

                  {!hideNavButtons ? (
                    !column?.dontShowIcon ? (
                      customIcon ? (
                        <div onClick={column?.onClick}>{customIcon}</div>
                      ) : (
                        <ArrowButton
                          onClick={column?.onClick}
                          bgColor={column?.color2}
                          right={true}
                        />
                      )
                    ) : null
                  ) : null}
                </div>
              </TableCell>
            ) : (
              <TableCell
                style={{
                  backgroundColor: "#19122C",
                  ...summaryDetailsBodyMidCellsStyle,
                }}
                className={`summaryDetailsBody ${column?.customCellClassName}`}
                {...(column?.customIcon ? "" : { onClick: column.onClick })}
                // key={i}
              >
                {column?.dontShow && <div className="disabledLayer"></div>}
                <Typography
                  //   variant={column?.fontVarient ? column?.fontVarient : "h6"}
                  style={{
                    fontWeight: column?.fontWeight ? column?.fontWeight : "400",
                    cursor: column?.onClick ? "pointer" : "default",
                    fontSize: fontSize && "12px !important",
                  }}
                  className={`${
                    column?.spclFont ? column?.spclFont : ""
                  } capitalize cellFont ${
                    i == 1 && !customFontWeight ? "assetWisetxt" : ""
                  } ${column?.customFontClassName}`}
                >
                  {column?.field}
                </Typography>
                {column?.customIcon && (
                  <div className="visibilityContainer" onClick={column.onClick}>
                    {column?.customIcon}
                  </div>
                )}
              </TableCell>
            )
          )}
        </TableRow>
      </>
    ));
  };

  // const getTableheader = (data) => {
  //   return data.map((header, index) => {
  //     return (
  //       <TableCell
  //         // align={header?.align ? header?.align : "left"}
  //         className={`summaryDetailsHeader ${
  //           index == 0 ? "summaryDetailsHeader" + index : ""
  //         } ${customStyle?.headerStyle?.className}`}
  //       >
  //         <Typography
  //           // variant="body1"
  //           className={"headerTxt"}
  //         >
  //           {header?.title}
  //         </Typography>
  //       </TableCell>
  //     );
  //   });
  // };
  const getTableheader = (data) => {
    return data.map((header, index) => (
      <TableCell
        key={index}
        className={`summaryDetailsHeader ${
          index === 0 ? "summaryDetailsHeader" + index : ""
        } ${customStyle?.headerStyle?.className}`}
      >
        <div style={{ ...alignHeaderColumn(index) }}>
          <Typography className={"headerTxt"}>{header.title}</Typography>
          {header.sortable && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "5px",
                gap: "0.4rem",
              }}
            >
              <UpArrow
                onClick={() => onSort(header.field, true)}
                style={{
                  padding: "0",
                  cursor: "pointer",
                  ...(sortOrderDetails.field === header.field &&
                  sortOrderDetails.order === true
                    ? { fill: "green" }
                    : {}),
                }}
              />
              <UpArrow
                onClick={() => onSort(header.field, false)}
                style={{
                  cursor: "pointer",
                  transform: "rotate(180deg)",
                  ...(sortOrderDetails.field === header.field &&
                  sortOrderDetails.order === false
                    ? { fill: "green" }
                    : {}),
                }}
              />
            </div>
          )}
        </div>
      </TableCell>
    ));
  };
  return (
    <>
      {/* {isMobile ? <MobileCards /> : */}
      {data?.length > 0 && (
        <div
          style={{
            minHeight: "372px",
            width: "-webkit-fill-available",
            // width: "50%",
            // margin: "2%",
            maxHeight: "450px",
            display: "flex",
            position: "relative",
          }}
        >
          <TableContainer className="summaryTable">
            <Table aria-label="sticky table">
              <TableHead>
                <TableRow>{getTableheader(headerData)}</TableRow>
              </TableHead>
              {tableData?.length > 0 && (
                <TableBody className={customStyle?.bodyStyle?.className || ""}>
                  {getTablebody(tableData)}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </div>
      )}
      {/* } */}
    </>
  );
}

export default TableSummaryDetails;
