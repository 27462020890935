import {
  Grid,
  InputLabel,
  NativeSelect,
  Select,
  Typography,
  FormControl,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStyles from "../../common/ProductsStyles";
import { configuring, handleData } from "./common";
import "./commoncss.css";
import { investmentDetailsTypeConfig, sipConfig } from "./constands";
import HashlinkSupport from "./HashlinkSupport";

function CardInvestment({ riskDetails }) {
  const classes = useStyles();
  const [type, setType] = useState(investmentDetailsTypeConfig);
  const [investmentDetailsData, setInvestmentDetailsData] = useState([]);
  //   const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  let data = [
    {
      title: "Min. Investment",
      data: handleData(riskDetails?.modifiedDuration),
    },
    {
      title: "Min. SIP Investment",
      data: handleData(riskDetails?.avgCreditQualityName),
    },
    {
      title: "YTM",
      data: handleData(riskDetails?.yieldMaturity),
    },
  ];

  useEffect(() => {
    // let selected;
    setType((prev) => {
      prev.map((type) => {
        if (riskDetails[type?.filed]) {
          type.show = true;
        }
      });
      // if()
      prev[0].selected = true;
      // selected = prev[1];
      return prev;
    });
  }, []);

  useEffect(() => {
    setInvestmentDetailsData((prev) => {
      let newList = [];
      sipConfig.map((val) => {
        configuring(val, newList, riskDetails, type);
      });

      return newList;
    });
  }, [type]);

  const handleTypeChange = (e) => {
    setType((prev) => {
      let newList = [];
      prev.map((type) => {
        if (type?.title == e.target.value) {
          type.selected = true;
        } else {
          type.selected = false;
        }
        newList.push(type);
      });

      return newList;
    });
  };

  return (
    <div style={{ height: "100%" }}>
      <HashlinkSupport id={"investmentDetails"} />
      <div
        className="dFlex spaceBTWN alignItemsCenter"
        style={{ marginBlock: "1% 2%" }}
        id="investmentDetails"
      >
        <Typography className={`${classes.riskHeaderSub}`}>
          Investment Details
        </Typography>
        <div
          className=" dFlex alignItemsCenter"
          //   onClick={handleClick}
          //   aria-controls="long-menu"
        >
          {/* <p className="typeTittle">
            {type.filter((val) => val.selected)[0]?.title}
          </p> */}
          {/* <FormControl className={classes.formControl}> */}
          {/* <NativeSelect
            // value={type.filter((val) => val.selected)[0]?.title}
            onChange={handleTypeChange}
            name="age"
            style={{
              border: "none",
            }}
            //   className={classes.selectEmpty}
            inputProps={{ "aria-label": "age" }}
          >
            {type.map((types) => {
              return (
                <option selected={types?.selected} value={types.title}>
                  {types.title}
                </option>
              );
            })} */}
          {/* <option
              selected={type.filter((val) => val.selected)[0]?.selected}
              value={type.filter((val) => val.selected)[0]?.title}
            >
              {type.filter((val) => val.selected)[0]?.title}
            </option>
            <option
              selected={type.filter((val) => !val.selected)[0]?.selected}
              value={type.filter((val) => !val.selected)[0]?.title}
            >
              {type.filter((val) => !val.selected)[0]?.title}
            </option> */}
          {/* </NativeSelect> */}

          <FormControl
            variant="outlined"
            className={`${classes.formControl} ${classes.inputField}`}
          >
            {/* <InputLabel id="demo-simple-select-outlined-label">Select Roles</InputLabel> */}
            <Select
              native
              style={{
                color: "#FAF9FF",
                backgroundColor: "#1F1C40 !important",
              }}
              labelId="demo-simple-select-outlined-label"
              label="Select Roles"
              onChange={handleTypeChange}
              // value={type.filter((val) => val.selected)[0]?.title}
              inputProps={{
                classes: {
                  icon: classes.icon,
                },
              }}
            >
              {/* <option className={classes.optiondesign} >1</option>
              <option className={classes.optiondesign} >2</option>
              <option className={classes.optiondesign} >3</option> */}
              {type.map((types) => {
                return (
                  <option
                    className={classes.optiondesign}
                    selected={types?.selected}
                    value={types.title}
                  >
                    {types.title}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          {/* <FormHelperTextt>With visually hidden label</FormHelperText> */}
          {/* </FormControl> */}
          {/* <UpArrow
            width={"10px"}
            height={"10px"}

            // className={
            //   sortConfig.title == column?.title && sortConfig.order == true
            //     ? "svgSelected"
            //     : ""
            // }
          /> */}
        </div>
      </div>
      <div
        style={{
          marginBlock: "1% 2%",
          display: "flex",
          margin: "0%",
          minHeight: "88%",
          padding: "55px",
        }}
        className={classes.mainContent}
      >
        {/* <div style={{ height: "100%", width: "100%" }}> */}
        <Grid container spacing={3} sx={{ height: "100%" }}>
          {investmentDetailsData?.map((val) => {
            return (
              <Grid zeroMinWidth item md={3} xs={12}>
                <div style={{ margin: "3% 3% 3% 5%" }}>
                  <Typography className={classes.riskDetailsTextMain}>
                    {val.title}
                  </Typography>
                  <Typography
                    className={classes.riskDetailsTextSub}
                    style={{ fontSize: "24px" }}
                  >
                    {val.value}
                  </Typography>
                </div>
              </Grid>
            );
          })}
        </Grid>
        {/* </div> */}
      </div>
      {/* <Menu
        id="long-menu"
        // aria-labelledby="demo015917305135"
        className="modal-wrapper"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        // anchorOrigin={{
        //     vertical: 'bottom',
        //     horizontal: 'right',
        // }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        sx={{
          backgroundColor: "#711CF1 !important",
          borderRadius: "20px",
          border: "1px solid #4E189F",
          // position: "absolute",
          outline: 0,
          // top: "15%",
          // right: "40%",
          width: "150px",
          padding: "15px",
          height: "200px",
          overflow: "scroll",
          boxShadow: "-4px 4px 10px rgba(0, 0, 0, 0.5)",
        }}
        keepMounted
        style={{ minWidth: "400px" }}
      >
        <div className="member" style={{ minWidth: "100px" }}>
          <div className="member-content">
            {type?.length > 0 &&
              type?.map((column) =>
                column && column.title !== "" ? (
                  //   <Typography className="filter-value-name avtar-text textCapitalize">
                  <p className="typeTittle">{column.title}</p>
                ) : (
                  //   </Typography>
                  ""
                )
              )}
          </div>
        </div>
      </Menu> */}
    </div>
  );
}

export default CardInvestment;
