const align = "center";
export const FDHoldingConfig = [
  // { field: "productName", title: "Product Name", show: true, align: align },
  { field: "bankName", title: "Scheme Name", show: true, align: align },
  { field: "memberName", title: "Member Name", show: true, align: align },
  { field: "advisor", title: "Advisor", show: true, align: align },
  { field: "isin", title: "Product Code", show: true, align: align },
  {
    field: "accountNo",
    title: "Account Number",
    show: true,
    align: align,
  },
  {
    field: "startDate",
    title: "Start Date",
    show: true,
    align: align,
  },
  // { field: "frequency", title: "Frequency", show: true, align: align },
  {
    field: "investedAmount",
    title: "Investment Amount",
    show: true,
    align: align,
  },
  {
    field: "interestAmount",
    title: "Interest Earned",
    show: true,
    align: align,
  },
  {
    field: "presentAmount",
    title: "Current Value",
    show: true,
    align: align,
  },
  // {
  //   field: "tenure",
  //   title: "Tenure",
  //   show: true,
  //   align: align,
  // },
  {
    field: "interestRepaymentFrequency",
    title: "Interest Payment Frequency",
    show: true,
    align: align,
  },
  {
    field: "maturityDate",
    title: "Maturity date",
    show: true,
    align: align,
  },
  {
    field: "unRealisedGainLoss",
    title: "Unrealised Gain/Loss",
    show: true,
    align: align,
  },
  {
    field: "totalGainLoss",
    title: "Total Gain/Loss",
    show: true,
    align: align,
  },
  {
    field: "xirr",
    title: "XIRR (%)",
    show: true,
    align: align,
  },
  {
    field: "absoluteTwrr",
    title: "Absolute TWRR (%)",
    show: true,
    align: align,
  },
  {
    field: "annualisedTwrr",
    title: "Annualised TWRR (%)",
    show: true,
    align: align,
  },
];

export const FDTransactionConfig = [
  // { field: "product_name", title: "Product Name", show: true, align: align },
  { field: "scheme_name", title: "Bank Name", show: true, align: align },
  { field: "advisor", title: "Advisor Name", show: true, align: align },
  { field: "executed_by", title: "Executed By", show: true, align: align },
  { field: "pan", title: "Pan No", show: true, align: align },
  { field: "isin", title: "Product Code", show: true, align: align },

  {
    field: "account_no",
    title: "Account No",
    show: true,
    align: align,
  },
  // { field: "frequency", title: "Frequency", show: true, align: align },
  {
    field: "transaction_date",
    title: "Transaction Date",
    show: true,
    align: align,
  },
  {
    field: "transaction_type",
    title: "Transaction Type",
    show: true,
    align: align,
  },
  {
    field: "amount",
    title: "Transaction Amount (₹)",
    show: true,
    align: align,
  },
  // {
  //   field: "transaction_type_id",
  //   title: "Transaction ID",
  //   show: true,
  //   align: align,
  // },
  //   { field: "quantity", title: "Quantity", show: true, align: align },
  //   {
  //     field: "gross_rate_per_unit",
  //     title: "Gross Rate Per Unit (Actuals)",
  //     show: true,
  //     align: align,
  //   },
  //   {
  //     field: "brokerage_per_unit",
  //     title: "Brokerage Per Unit (Actuals)",
  //     show: true,
  //     align: align,
  //   },
  //   {
  //     field: "gst_sst_other_charges",
  //     title: "GST+SST+Other Charges (Actuals)",
  //     show: true,
  //     align: align,
  //   },
  //   {
  //     field: "net_rate_per_unit",
  //     title: "Net Rate Per Unit (Actuals)",
  //     show: true,
  //     align: align,
  //   },
  //   {
  //     field: "total_transaction_value",
  //     title: "Total Transaction Value (Actuals)",
  //     show: true,
  //     align: align,
  //   },
  //   {
  //     field: "cumulative_quantity",
  //     title: "Cumulative Quantity",
  //     show: true,
  //     align: align,
  //   },
];

export const productName = "Fixed Deposit";
