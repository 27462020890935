import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import TabBondSub from "./TabBondSub";
import useStyles from "../common/ProductsStyles";
import { getTransactionsSummary } from "../../../../api";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import { OnboardingContext } from "../../../../Context";
import moment from "moment";
import { currencyFormat } from "../../../../hooks/constantFunction";
import PmsReportTable from "../pms/PmsReportTable";
import { roundNumber } from "../../../utils/common";
import { useNavigate } from "react-router-dom";
import { ReactComponent as EditColumn } from "../../../../assets/icons/edit-column.svg";
import NewtableTopSec from "../../common/NewtableTopSec";
import { useClientSearch } from "../../../../hooks/useClientSearch";
import { ITEMS_LIMIT_FILES_PARTNER_LIST } from "../../../../Constants";
import { summarizeTableData } from "../../../utils/common";
import { useCurrentHoldingCardsApi } from "../../../../hooks/useCurrentHoldingCardsApi";
let align = "center";

const tableColumnsData = [
  {
    field: "schemeName",
    title: "Scheme/Product Name",
    show: true,
    align: align,
  },
  { field: "advisor", title: "Advisor", show: true, align: align },
  { field: "memberName", title: "Member Name", show: true, align: align },
  { field: "isin", title: "ISIN No", show: true, align: align },
  // { field: "folioNo", title: "Folio Number", show: true, align: align },
  { field: "investmentValue", title: "Amount", show: true },
  {
    field: "withdrawalAmount",
    title: "Withdrawal * Includes Capital Reduction",
    show: true,
  },
  { field: "presentValue", title: "Present Value", show: true },
  { field: "holdingUnits", title: "Quantity", show: true },

  {
    field: "percentageOfTotalPortfolio",
    title: "% of Total Portfolio",
    show: true,
  },
  // { field: "realisedGainLoss", title: "Realized Gain/Loss", show: false },

  { field: "unRealisedGainLoss", title: "Unrealized Gain/Loss", show: true },
  {
    field: "interestIncome",
    title: "Total Interest Income",
    show: true,
  },
  {
    field: "totalGainLoss",
    title: "Total Gain/Loss",
    show: true,
  },
  { field: "xirr", title: "XIRR (%)", show: true, nonTwoLine: true },
  {
    field: "absoluteTwrr",
    title: "Absolute TWRR (%)",
    show: true,
    nonTwoLine: true,
  },
  {
    field: "annualisedTwrr",
    title: "Annualized TWRR (%)",
    show: true,
    nonTwoLine: true,
  },
];

function BondsHolding() {
  const classes = useStyles();
  const { holdingCardData, xirrCurrentHolding, twrrCurrentHolding } =
    useCurrentHoldingCardsApi(true, "bonds");

  const [tableColumns, setTableColumns] = useState([]);
  const [secondTableColumns, setSecondTableColumns] = useState([]);
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const { userState } = OnboardingContext();
  const [tableHeader, setTableHeader] = useState(tableColumnsData);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState("");
  const { searchResults } = useClientSearch(search, tableColumns);
  const [pagiNationData, setPageinationData] = useState({
    pageCount: null,
    // limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
  });

  const numberOfInstrumentsCount = useRef(0);
  const [numberOfInstruments, setNumberOfInstruments] = useState(0);
  const [percentageOfTotalPortfolio, setPercentageOfTotalPortfolio] =
    useState(0);

  const [offset, setOffset] = useState(0);

  const objectFormating = (data) => {
    let newData = tableHeader.map((header) => {
      if (header?.field == "schemeName") {
        return {
          key: header?.field,
          field: data[`${header.field}`],
          title: header.title,
          show: header.show,
          // onClick: () => {
          //   navigate("/details/?isin=" + data[`isin`]);
          // },
        };
      }
      if (header?.field == "annualisedTwrr") {
        return {
          key: header?.field,
          field: roundNumber(Number(data[`${header.field}`])) || "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (
        header?.field == "isin" ||
        header?.field == "folioNo" ||
        header?.field == "holdingUnits"
      ) {
        return {
          key: header?.field,
          field: `${data[`${header.field}`]}`,
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (
        header?.field == "investmentValue" ||
        header?.field == "withdrawalAmount" ||
        header?.field == "presentValue" ||
        header?.field == "totalGainLoss" ||
        header?.field == "realisedGainLoss" ||
        header?.field == "interestIncome" ||
        header?.field == "unRealisedGainLoss"
      ) {
        return {
          key: header?.field,
          field: currencyFormat(data[`${header.field}`] || 0) || "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      return {
        key: header?.field,
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`]))
          : data[`${header.field}`] || "--",
        title: header.title,
        show: header.show,
        align: header?.align,
      };
    });
    return newData;
  };

  const getTaableData = async () => {
    setLoading(true);

    try {
      // let params = {
      //   clientId: 4,
      //   // memberId: 1,
      //   // startDate: "2021-04-10",
      //   // endDate: "2022-10-09",
      //   // assetClass: p,
      // };
      let params = {
        clientId: userState?.loginedUserData?.id,
        //clientId: 199,
        search: search,
        ...getfilter_as_params(),
        offset: offset,
        limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
      };

      let res = await getTransactionsSummary(
        "bonds",
        "summary/holding",
        params
      );
      let newlist = [];
      setPageinationData((prev) => {
        return { ...prev, pageCount: res?.data?.data?.meta?.count };
      });

      //code for number of instruments and total portfolio

      if (numberOfInstrumentsCount.current === 0) {
        setNumberOfInstruments(res?.data?.data?.meta?.count);
      }
      numberOfInstrumentsCount.current = numberOfInstrumentsCount.current + 1;
      setPercentageOfTotalPortfolio(
        res?.data?.data?.total?.percentageOfTotalPortfolio
      );

      res?.data?.data?.result?.map((val) => {
        // console.log(val);
        let obj = objectFormating(val);
        newlist.push(obj);
      });
      setTableColumns(newlist);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTaableData();
  }, [mfoFilter, offset, search]);

  useEffect(() => {
    setSecondTableColumns(
      summarizeTableData(
        tableColumns,
        holdingCardData,
        xirrCurrentHolding,
        twrrCurrentHolding,
        numberOfInstruments,
        "",
        "",
        "",
        percentageOfTotalPortfolio
      )
    );
  }, [tableColumns, holdingCardData, xirrCurrentHolding, twrrCurrentHolding]);
  const handleIconClick = () => {
    setOpenModal(!openModal);
  };

  return (
    <div>
      <NewtableTopSec
        handleIconClick={handleIconClick}
        directPageName={"Holding Summary"}
        setSearch={(e) => {
          setOffset(0);
          setSearch(e);
        }}
        product={"Bonds"}
      />
      <PmsReportTable
        setTableColumns={setTableColumns}
        tableColumns={tableColumns}
        setSecondTableColumns={setSecondTableColumns}
        tableColumns2={secondTableColumns}
        loading={loading}
        setOpenModal={setOpenModal}
        openModal={openModal}
        headerList={tableColumnsData}
        tableHeader={tableHeader}
        setTableHeader={setTableHeader}
        stickyProductName={true}
        headerInTwoLine={true}
        pageCount={pagiNationData?.pageCount}
        setItemOffset={setOffset}
        pageNumber={offset / ITEMS_LIMIT_FILES_PARTNER_LIST + 1 || 1}
      />
    </div>
  );
}

export default BondsHolding;
