import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  mainContentbox: {
    background: "#110425",
    // boxShadow: '-4px 4px 6px #000000, 4px -4px 6px rgba(89, 22, 189, 0.3), inset -4px 4px 20px #000000, inset 3px -4px 10px #5916BD',
    borderRadius: "20px",
    margin: "20px 10px 10px 20px",
    // position: 'absolute',
    width: "94%",
    height: "auto",
    padding: "15px",

    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "82%",
    },
  },
  topNavContainer: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  active: {
    background: "#19003e",
    backdropFilter: "blur(15px)",
    boxShadow: "inset 0px 4px 10px #220848",
    borderRadius: "50px",
    color: "#F1E8FE",
    lineHeight: "20px",
    padding: "8px 20px!important",
    border: "1px solid #711CF1",
    marginRight: "0",
    marginLeft: "0",
    width: "auto",
  },
  media: {
    height: 140,
  },

  imagealign: {
    textAlign: "center",
  },
  width100p: {
    width: "100%",
  },
  blocktitle: {
    color: "#fff",
    padding: "0px!important",
    position: "absolute",
    bottom: "15%",
    left: "15%",
  },
  colorfff: {
    color: "#fff",
  },
  colorlightgrey: {
    color: "#9F9DAC",
  },

  rightsection: {
    width: "18%",
    margin: "20px 10px 10px 20px",
    position: "absolute",
    height: "auto",
    padding: "15px",
  },
  avatar: {
    textAlign: "center",
  },
  famhead: {
    color: "#fff",
    background: "linear-gradient(180deg, #AA6FFE 0%, #711CF1 100%)",
    border: "1px solid #010825",
    boxShadow: "0px 4px 4px #000000",
    borderRadius: "20px",
    padding: "10px 20px",
  },
  hrcolor: {
    color: "#4E189F",
  },
  memnos: {
    background: "#380D77",
    color: "#fff",
    border: "1px solid #711CF1",
    boxShadow: "inset 0px 4px 10px #220848",
    borderRadius: "50px",
    padding: "10px 20px",
    margin: "0px 20px 0px 0px",
  },

  allmems: {
    display: "flex",
    width: "30%",
  },
  memavatar: {
    margin: "5px",
    background: "#FAF9FF",
    borderRadius: "20px",
    color: "#711CF1",
  },

  pagebackarrow: {
    background: "#220848",
    // borderRadius: "50%",
    // padding: "10px",
  },
  titlepage: {
    verticalAlign: "super",
    margin: "0px 20px",
  },
  marginbr: {
    margin: "0px 10px 10px 10px",
  },
  portfolioline: {
    color: "#9488A7",
    margin: "0px",
  },
  textright: {
    textAlign: "right",
  },
  portfdetails: {
    background: "#1F1C40",
    borderRadius: "20px",
    padding: "10px",
    // margin: '10px',
  },
  portftitle: {
    color: "#fff",
    fontSize: "22px",
  },
  portfvalue: {
    color: "#fff",
    fontSize: "22px",
    textAlign: "right",
  },

  headmembername: {
    color: "#fff",
    marginBottom: "10px",
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "18px",
    lineGeight: "22px",
  },

  headmembernos: {
    color: "#fff",
    background: "#380D77",
    border: "1px solid #711CF1",
    boxShadow: "inset 0px 4px 10px #220848",
    borderRadius: "50px",
    padding: "5px 10px",
    fontSize: "12px",
    // margin:'10px 20px',
  },

  headmemberamt: {
    color: "#00C1CE",
    marginBottom: "10px",
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800px",
    fontSize: "28px",
    lineHeight: "30px",
  },

  headmemberratedown: {
    color: "#B80000",
    fontWeight: "700",
    marginBottom: "10px",
  },
  headmemberrateup: {
    color: "#00C1CE",
    fontFamily: "Avenir",
    fontWeight: "700px",
    fontSize: "20px",
    lineHeight: "20px",
    // marginBottom: '10px',
    marginTop: "1.9%",
  },

  bigfamilymembername: {
    color: "#fff",
    marginTop: "6%",
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "36px",
    lineHeight: "44px",
  },
  bigdetmembername: {
    color: "#fff",
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "28px",
    lineHeight: "34px",
    marginTop: "2%",
  },
  bigfamilyposn: {
    color: "#9F9DAC",
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "22px",
    marginTop: "5%",
  },
  bigfamilytext: {
    color: "#9F9DAC",
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "1.2rem",
    marginTop: "5%",
  },
  childMembertext: {
    color: "#9F9DAC",
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    marginTop: "5%",
  },
  bigmembernos: {
    color: "#fff",
    fontSize: "14px",
    border: "1px solid #711CF1",
    padding: "10px 20px",
    background: "#380D77",
    boxShadow: "inset 0px 4px 10px #220848",
    borderRadius: "50px",
  },
  bigmemberratedown: {
    color: "#B80000",
    fontSize: "22px",
    fontWeight: "700",
  },
  bigmemberrateup: {
    color: "#00C1CE",
    fontSize: "22px",
    fontWeight: "700",
  },

  bigmemberamt: {
    color: "#00C1CE",
    fontFamily: "Avenir",
    fontWeight: "800",
    fontSize: "28px",
    lineHeight: "30px",
    marginTop: "4%",
  },
  margin5ptb: {
    margin: "5% 0% 5% 2%",
  },
  memberrateup: {
    color: "#00C1CE",
  },
  memberratedown: {
    color: "#F52D6A",
  },
  viewtext: {
    color: "#fff",
    fontSize: "12px",
  },

  wid50p: {
    width: "50%",
  },

  cardTitleNew: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "24px",
    lineHeight: "111.6%",
    color: "#FFFFFF",
    position: "absolute",
    bottom: "20px",
    left: "20px",
  },
  alignTopHeader: {
    margin: "2%  3%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mainHeading: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "22px",
    lineHeight: "30px",
    color: " #F8F9FB",
  },
  mainHeadingAlign: {
    cursor: "pointer",
    width: "45%",
    marginLeft: "10px",
  },
  topGrayBox: {
    background: "#1F1C40",
    borderRadius: "20px",
    // padding: '10px',
  },
  tabDash: {
    background: " #220848",
    // border: "1px solid #4E189F",
    boxShadow:
      "-6.22302px -6.22302px 18.6691px #302A64, 6.22302px 6.22302px 18.6691px #000000",
    borderRadius: "124.46px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px",
    margin: "0.5%",
  },
  tabHeaderText: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "35px",
    textAlign: "center",
    color: "#FFFFFF",
    // paddingTop: "5px",
    margin: "10px 20px",
  },
  tabClient: {
    background: " #220848",
    // border: "1px solid #4E189F",
    boxShadow:
      "-6.22302px -6.22302px 18.6691px #302A64, 6.22302px 6.22302px 18.6691px #000000",
    borderRadius: "124.46px",
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 25px",
    margin: "0.5%",
  },
  active: {
    background: "#380D77",
    boxShadow: "inset 0px 4px 10px #220848",
    borderRadius: "50px",
    color: "#F1E8FE",
    lineHeight: "20px",
    padding: "8px 25px!important",
    border: "1px solid #711CF1",
    marginRight: "0",
    marginLeft: "0",
    width: "auto",
  },
  allmemberhead: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "17px",
    lineHeight: "21px",
    textAlign: "center",
    color: "#FFFFFF",
  },
  // portfolio comparison card 
  portfoliocard:
  {
  backgroundColor: "#4C1A97!important",
  borderRadius: "16px!important",
  },

  portfoliouser:
  {
  width: "30%",
  float:"left",
  borderRadius: "14px",
  },
  bgcircles: 
  {
    width: "100%",
    maxHeight: "200px",
    backgroundImage: "url('../../../assets/clientdashboard/bgcircles.svg') !important",
    backgroundRepeat: "no-repeat !important",
    backgroundPosition: "right center !important",
  },
  bigmemberamtportcomp:
  {
    color: "#EFEEF5",
    fontWeight: "700",
    fontSize: "22px",
  },
  famheadportcomp:
  {
    color: "#EFEEF5",
    fontWeight: "400",
    fontSize: "16px",
  },
  totgainloss:
  {
    fontWeight: "400",
    color: "#C9C3D3",
    fontSize: "12px",
    paddingTop: "20px",
  },
  totgainlossamt:
  {
    color: "#35CBBF",
    fontWeight: "700",
    fontSize: "18px",
  },
  rightportcomp: 
  {
    display: "flex",
    paddingLeft: "10px",
  },
  portcompname:
  {
    color: "#EFEEF5",
    fontSize: "20px",
    fontWeight: "700",
  },
  equitylabel:
  {
    color: "#FFFFFF",
    fontSize: "14px",
    background: "linear-gradient(180deg, #FE6587 0%, #F52D6A 100%)",
    borderRadius: "20px",
    padding: "8px 20px",
  },
  debtlabel:
  {
    color: "#FFFFFF",
    fontSize: "14px",
    background: "linear-gradient(180deg, #AA6FFE 0%, #711CF1 100%);",
    borderRadius: "20px",
    padding: "8px 20px",
  },
  cashlabel:
  {
    color: "#FFFFFF",
    fontSize: "14px",
    background: "linear-gradient(180deg, #509FF1 0%, #3A56FF 100%);",
    borderRadius: "20px",
    padding: "8px 20px",
  },
  alternatelabel:
  {
    color: "#FFFFFF",
    fontSize: "14px",
    background: "linear-gradient(270deg, #00C1CE 0%, #82EBA1 100%);",
    borderRadius: "20px",
    padding: "8px 20px",
  },
  edcaamt:
  {
    color: "#FFFFFF",
    fontSize: "30px",
    fontWeight: "600",
    margin: "30px 0px 0px 5px",
  },
  edcaglamtprofit:
  {
    color: "#00C1CE", 
    fontSize:"14px",
  },
  edcaglamtloss:
  {
    color: "#B80000", 
    fontSize:"14px",
  },
  mrtop20:
  {
    marginTop:"20px",
  },
  leftedcasection:
  {
    float:"left",
    width:"50%",
  },
  rightedcasection:
  {
    display: "flex",
  },
  bgcirclesportcomp:
   {
    backgroundPosition: "top right !important",
  },
  // marketmeter style
  idcusection:
  {
    padding: "0px 50px",
  },

  idtitlebr:
  {
    borderRadius: "10px 0px 0px 0px",
    background: "#270c51",
    color: "#fff",
    borderBottom: "2px solid #888",
    padding: "55px 20px",
  },
  cutitlebr:
  {
    borderRadius: "0px 0px 0px 10px",
    background: "#270c51",
    color: "#fff",
    padding: "55px 20px",
  },
  metertitle:
  {
    background: "#1D1B36",
    color: "#fff",
    padding: "34px 20px",
    borderRadius: "0px",
    fontWeight: "100",
    fontSize: "13px",
    borderRight: "2px solid #181727",
  },
  metertitlelastborder:
  {
    borderRadius: "0px 10px 10px 0px!important",
    background: "#1D1B36",
    color: "#fff",
    fontWeight: "100",
    fontSize: "13px",
    padding: "34px 20px",
  },
}));

export default useStyles;
