import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

if (process.env.REACT_APP_ENV !== "DEVELOPMENT") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}
root.render(<App />);
