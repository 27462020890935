import { CVL_KRA_CLIENT_CODE, CVL_KRA_TOKEN } from "../../../Config";

export const headers = {
  client_code: CVL_KRA_CLIENT_CODE,
  Authorization: CVL_KRA_TOKEN,
};

export const handlePanInfo = (kycData, step) => {
  const resultData = kycData.data.result.KYC_DATA;

  let kycDate = "";
  if (resultData.APP_DATE) {
    kycDate = resultData.APP_DATE.split("-");
    kycDate = kycDate[2] + "-" + kycDate[1] + "-" + kycDate[0];
  }

  let commencementDate = "";
  if (resultData.APP_COMMENCE_DT) {
    commencementDate = resultData.APP_COMMENCE_DT.split("-");
    commencementDate =
      commencementDate[2] +
      "-" +
      commencementDate[1] +
      "-" +
      commencementDate[0];
  }

  return {
    step: step,
    commencementDate: commencementDate,
    address:
      resultData.APP_PER_ADD1 +
      " " +
      resultData.APP_PER_CITY +
      " " +
      resultData.APP_PER_PINCD,
    kycDate: kycDate,
    kycStatus: "verified",
    panVerified: 1,
    doi: "",
  };
};

export function dateConvert(date) {
  let formattedDate = date.split("-").reverse().join("-");
  console.log("date formatted", formattedDate);
  return formattedDate;
}

export const handleDateFormat = (str) => {
  return String(str).split("-").reverse().join("/");
};

export const handeDIO2 = (payload) => {
  if (payload?.entityType == "sole_proprietor") {
    payload["dob"] = payload?.dateOfBirth;
  } else {
    payload["doi"] = payload?.dateOfBirth;
  }

  return payload;
};

export const handeDIO = (payload, key, formData) => {
  if (formData?.entityType == "individual") {
    payload[key] = formData?.dateOfIncorporation;
  } else {
    payload[key] = formData?.dateOfIncorporation;
  }

  return payload;
};
