import * as React from "react";
import { Box } from "@material-ui/core";
import { Modal } from "@material-ui/core";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 370,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

export default function ImageModal({ openModal, imageSrc, setOpenModal }) {
  const handleClose = () => setOpenModal(false);
  console.log(openModal);
  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img style={{ height: "210px", width: "350px" }} src={imageSrc}></img>
        </Box>
      </Modal>
    </div>
  );
}
