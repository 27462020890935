import moment from "moment";
import { currencyFormat } from "../../../../../hooks/constantFunction";
import { roundNumber } from "../../../../utils/common";
import { dayFormatter2 } from "../../../../utils/common";

export const objectFormatingEquityCorporate = (data, headerData) => {
  let newData = headerData.map((header) => {
    if (header?.field == "ratio") {
      // console.log(data, headerData, header, "header");
      // if (!data[`${header.field}`]) {
      //   console.log(data);
      // }
      return {
        field: data[`${header.field}`] + " ",
        title: header.title,
        show: header.show,
        align: "center",
      };
    }
    if (header?.field == "remark") {
      return {
        field: data[`${header.field}`] ? data[`${header.field}`] : "",
        title: header.title,
        show: header.show,
        align: "left",
      };
    }
    if (header?.field == "eventDate") {
      return {
        field: data[`${header.field}`]
          ? moment(data[`${header.field}`]).format("DD-MMM-YYYY")
          : "",
        title: header.title,
        show: header.show,
        align: "left",
      };
    }
    // if (header?.field == "eventDate") {
    //   return {
    //     field: dayFormatter2(data[`${header.field}`]) || 0,
    //     title: header.title,
    //     show: header.show,
    //   };
    // }
    return {
      field: !isNaN(data[`${header.field}`])
        ? roundNumber(Number(data[`${header.field}`]))
        : data[`${header.field}`] || "--",
      title: header.title,
      show: header.show,
      align: "center",
    };
  });
  return newData;
};
