// import React from "react";
// import { Box } from "@material-ui/core";
// import "./StrategyBuilder.css";
// export const StrategyBuilderMemberCheckBoxList = ({
//   checkboxes,
//   handleCheckboxChange,
// }) => {
//   return (
//     <Box className="strategyBuilderMemberNameBox">
//       {checkboxes.map((checkbox, index) => (
//         <Box className="strategyBuilderMemberFormGroup" key={index}>
//           <label htmlFor={checkbox.name} className="strategyBuilderMemberName">
//             {checkbox.name}
//           </label>
//           <input
//             id={checkbox.name}
//             type="checkbox"
//             checked={checkbox.checked}
//             onChange={() => handleCheckboxChange(index)}
//           />
//           {/* To show when it is unchecked */}
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             width="16"
//             height="16"
//             viewBox="0 0 16 16"
//             fill="none"
//           >
//             <path
//               d="M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8Z"
//               stroke="#654E87"
//             />
//           </svg>
//           {/* To Show When it is checked */}
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             width="16"
//             height="16"
//             viewBox="0 0 16 16"
//             fill="none"
//           >
//             <path
//               d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
//               fill="#55A47E"
//             />
//             <path
//               fill-rule="evenodd"
//               clip-rule="evenodd"
//               d="M6.0234 9.15765L11.3372 4.93849C11.4664 4.83678 11.6228 4.77552 11.7868 4.76242C11.9507 4.74932 12.1149 4.78495 12.2586 4.86484C12.4024 4.94473 12.5193 5.06531 12.5948 5.21145C12.6702 5.35759 12.7008 5.52276 12.6826 5.68622C12.6704 5.79502 12.6368 5.90033 12.5837 5.99607C12.5306 6.09182 12.4591 6.17611 12.3732 6.24409L6.33749 11.0352L6.27889 11.0821C6.25474 11.099 6.2297 11.1146 6.20388 11.1289L6.17107 11.15C5.98052 11.2485 5.75933 11.2695 5.55364 11.2088C5.34794 11.1481 5.17362 11.0103 5.06705 10.8242L4.9733 10.6672L3.42393 7.97394C3.3696 7.87944 3.33441 7.77517 3.32038 7.66707C3.30635 7.55897 3.31375 7.44917 3.34215 7.34393C3.37056 7.2387 3.41941 7.14008 3.48593 7.05373C3.55244 6.96737 3.63532 6.89496 3.72982 6.84063C3.92067 6.73091 4.14729 6.70149 4.35982 6.75886C4.46506 6.78726 4.56367 6.83612 4.65003 6.90263C4.73639 6.96915 4.8088 7.05202 4.86313 7.14652L6.02574 9.15765H6.0234Z"
//               fill="white"
//             />
//           </svg>
//         </Box>
//       ))}
//     </Box>
//   );
// };
import React from "react";
import { Box } from "@material-ui/core";
import "./StrategyBuilder.css";

export const StrategyBuilderMemberCheckBoxList = ({
  checkboxes,
  handleCheckboxChange,
}) => {
  return (
    <Box className="strategyBuilderMemberNameBox">
      {checkboxes?.map((checkbox, index) => (
        <Box className="strategyBuilderMemberFormGroup" key={index}>
          <label htmlFor={checkbox.name} className="strategyBuilderMemberName">
            {checkbox.name}
          </label>
          {/* To Show When it is unchecked */}
          {!checkbox.checked && (
            <div className="themeCheck">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                onClick={() => handleCheckboxChange(index)}
              >
                <path
                  d="M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8Z"
                  stroke="#654E87"
                />
              </svg>
            </div>
          )}
          {/* To Show When it is checked */}
          {checkbox.checked && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              onClick={() => handleCheckboxChange(index)}
            >
              <path
                d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                fill="#55A47E"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.0234 9.15765L11.3372 4.93849C11.4664 4.83678 11.6228 4.77552 11.7868 4.76242C11.9507 4.74932 12.1149 4.78495 12.2586 4.86484C12.4024 4.94473 12.5193 5.06531 12.5948 5.21145C12.6702 5.35759 12.7008 5.52276 12.6826 5.68622C12.6704 5.79502 12.6368 5.90033 12.5837 5.99607C12.5306 6.09182 12.4591 6.17611 12.3732 6.24409L6.33749 11.0352L6.27889 11.0821C6.25474 11.099 6.2297 11.1146 6.20388 11.1289L6.17107 11.15C5.98052 11.2485 5.75933 11.2695 5.55364 11.2088C5.34794 11.1481 5.17362 11.0103 5.06705 10.8242L4.9733 10.6672L3.42393 7.97394C3.3696 7.87944 3.33441 7.77517 3.32038 7.66707C3.30635 7.55897 3.31375 7.44917 3.34215 7.34393C3.37056 7.2387 3.41941 7.14008 3.48593 7.05373C3.55244 6.96737 3.63532 6.89496 3.72982 6.84063C3.92067 6.73091 4.14729 6.70149 4.35982 6.75886C4.46506 6.78726 4.56367 6.83612 4.65003 6.90263C4.73639 6.96915 4.8088 7.05202 4.86313 7.14652L6.02574 9.15765H6.0234Z"
                fill="white"
              />
            </svg>
          )}
        </Box>
      ))}
    </Box>
  );
};
