import React from "react";
import Default from "../components/dashboard/defaultPages/Default";
import { useParams } from "react-router-dom";
import REITSReport from "../components/dashboard/products/REITS/REITSReport";

function REITSReportDetails() {
  const { pageName } = useParams();

  return (
    <Default>
      <REITSReport pageName={pageName} />
    </Default>
  );
}

export default REITSReportDetails;
