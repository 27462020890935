import React from "react";
import "../pag1.css";
import NewLogo from "../../../../assets/icons/newNeoLogo.png";
import { Link } from "react-router-dom";
import { Breadcrumbs } from "@material-ui/core";
import { dateFormatCommon } from "../../../utils/common";
export default function Footer({ title, page, data }) {
  const breadcrumbs = [
    <Link key="1" color="#000000" className="printBreadsel pdfCaps font600">
      {data?.client}
    </Link>,
    <Link
      underline="hover"
      key="2"
      style={{ color: "#000000" }}
      className="uppercase font500"
    >
      {data?.report}
    </Link>,
  ];

  return (
    <div className="print_footer p_flex">
      <div
        className="footer_title p_flex a_cen "
        style={{ paddingLeft: "66px" }}
      >
        <div style={{ paddingRight: "27px", color: "#2E314A" }}>
          <Breadcrumbs
            separator="›"
            aria-label="breadcrumb"
            className="printBread"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </div>
        <div
          className="printPageNumber fAvenirBold"
          style={{ width: "93.5%", color: "#2E314A", position: "absolute" }}
        >
          {page}
        </div>{" "}
      </div>
      <div
        className="pageNumber p_flex center_full"
        style={{ paddingRight: "35px" }}
      >
        {/* <div className="logoContainer">
          <div className="footerIconWrapper">
            <img src={NewLogo} alt="logo" style={{ height: "28px" }}></img>
         
          </div>
          <h3 style={{ marginTop: "0px", color: "black", fontSize: "10px" }}>
         
          </h3>
        </div> */}
        <h3 style={{ color: "black", fontSize: "14px" }}>
          {data?.report == "Holding Summary Report"
            ? "As On Date - " + data?.endDate
            : ""}
          {data?.report == "Transaction Summary Report" ||
          data?.report == "Performance Summary Report" ||
          data?.report == "Realized GainLoss Report" ||
          data?.report == "PMS Transaction Report" ||
          data?.report == "Tax Report" ||
          data?.report == "Income Statement"
            ? (data?.startDate ? data?.startDate : "As On Date") +
              " - " +
              data?.endDate
            : ""}
        </h3>

        {/* <div className="s_text">Page</div> */}
      </div>
    </div>
  );
}
