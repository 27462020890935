import React from "react";
import { Box, Typography } from "@material-ui/core";
import "../../print/pag1.css";
import pdfLogo from "../../../../assets/images/pdfLogo.png";
import printReportCover from "../../../../assets/images/PrintReportCover.png";
import pdfReportCover from "../../../../assets/images/PdfReportCover.png";
import "./FirstPage.css";
import { useLocation } from "react-router";
export const FirstPage = ({ type, pdfInfo }) => {
  function getTextColor() {
    let black = "#000000";
    let white = "#FFFFFF";
    return type == "print" ? black : white;
  }
  return (
    <div
      className="pdf-page"
      style={{
        backgroundColor: "#ffffff",
        display: "flex",
        backgroundImage: type === "pdf" ? `url(${pdfReportCover})` : "none",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Box className="left-box">
        {/* Logo */}
        <Box style={{ position: "absolute", top: "30px" }}>
          <img src={pdfLogo} className="logo" />
          {/* <p
            style={{
              marginTop: "0px",
              fontWeight: "700",
              color: getTextColor(),
            }}
          >
            Do Good
          </p> */}
        </Box>

        {/* Main Text */}
        <Box>
          <Typography
            id="main-header"
            variant="h1"
            style={{ color: getTextColor() }}
          >
            Executive
          </Typography>
          <Typography
            id="main-header"
            variant="h1"
            style={{ color: getTextColor() }}
          >
            Summary Report
          </Typography>
          <Typography
            id="main-header"
            variant="h1"
            style={{ color: getTextColor() }}
          >
            & Holding Report
          </Typography>
          <div className="infoContainer" style={{ paddingTop: "100px" }}>
            <p className="startDate">Start Date</p>
            <p className="startDate">End Date</p>
          </div>
          <div className="infoContainer">
            <p className="pdfDate">{pdfInfo?.startDate}</p>
            <p className="pdfDate">{pdfInfo?.endDate}</p>
          </div>
          <div className="infoContainer" style={{ paddingTop: "100px" }}>
            <p className="startDate">Client Name</p>
            <p className="startDate">No of Member</p>
          </div>
          <div className="infoContainer">
            <p className={"date1 " + " w4in"}>{pdfInfo?.client}</p>
            <p className="date1">{pdfInfo?.totalMembers}</p>
          </div>
          {/* <p
            className="main-header"
            style={{
              fontSize: "23px",
              fontWeight: "500",
              color: getTextColor(),
            }}
          >
            <span style={{ fontSize: "20px", color: getTextColor() }}>
              AS ON
            </span>{" "}
            31st August 2022
          </p> */}
        </Box>

        {/* Client Name */}
        {/* <Box>
          <Typography
            variant="h4"
            className="client-text"
            style={{ color: getTextColor() }}
          >
            Rahul Vakil
          </Typography>
          <p className="client-subText" style={{ color: getTextColor() }}>
            CRN: 999999| Family Vakil
          </p>
        </Box> */}

        {/* Relationship Manager */}
        {/* <Box>
          <p className="rm-main-text" style={{ color: getTextColor() }}>
            YOUR RELATIONSHIP MANAGER
          </p>
          <Box
            className="d_flex_space_around_center"
            style={{ color: getTextColor() }}
          >
            <p>
              Name -{" "}
              <span style={{ color: getTextColor() }} className="rm-subText">
                Ralph Futardo
              </span>
            </p>
            <p>
              Mobile -{" "}
              <span style={{ color: getTextColor() }} className="rm-subText">
                9819812498
              </span>
            </p>
            <p>
              Email -{" "}
              <span style={{ color: getTextColor() }} className="rm-subText">
                johndoe@email.com
              </span>
            </p>
          </Box>
        </Box> */}
      </Box>

      <Box className="right" style={{ width: "50%" }}>
        {type === "print" && (
          <img src={printReportCover} style={{ marginTop: "240px" }} />
        )}
      </Box>
    </div>
  );
};
