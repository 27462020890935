import React from "react";
import PieChart from "../../../../charts/PieChart";
import PmsReportTable from "../../pms/PmsReportTable";
import { EquityTableConfig } from "./constands";
import useStyles from "../../common/ProductsStyles";
import { Grid, Typography } from "@material-ui/core";
import { roundNumber } from "../../../../utils/common";
import { pie_onClick3 } from "../../../../charts/nivo_objects";

function SectorWiseHolding({
  sectorAllocation,
  riskDetails,
  tableColumns,
  setTableColumns,
  tableHeader,
  setTableHeader,
  setSectorWiseState,
  sectorWiseState,
  sectorWiseColor,
}) {
  const classes = useStyles();

  return (
    <>
      <Typography
        style={{ marginLeft: "3%" }}
        className={`${classes.riskHeaderSub} ${classes.typoInsideAlign}`}
        id="sectorWiseHolding"
      >
        Sector Wise Holding
      </Typography>
      <div style={{ display: "flex", margin: "2%" }}>
        <Grid container>
          {sectorAllocation.length > 0 && (
            <Grid item md={5} sm={12}>
              <div
                className={classes.mainContent}
                style={{
                  display: "flex",
                  margin: "0% 2%",
                  alignItems: "center",
                  height: "475px",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "block",
                    margin: "0%",
                    marginTop: "auto",
                    marginBottom: "auto",
                    // height: "422px",
                    width: "527px",
                  }}
                >
                  <PieChart
                    config={{
                      innerRadius: "0.80",
                      cornerRadius: 0,
                      padAngle: 0,
                      legends: false,
                      disableArcLink: true,
                      minWith: "100%",
                      // arcLinkLabel: (d) => `${d.value}%`,
                      // margin: { right: 80, top: 20, left: 80 },
                      height: "300px",
                      chart: { height: 385 },
                    }}
                    colors={sectorAllocation.map((val) => val?.color)}
                    onClickEvent={{
                      click: pie_onClick3,
                      set: setSectorWiseState,
                      cstate: sectorWiseState,
                      ncolors: sectorWiseColor,
                    }}
                    data={sectorAllocation}
                  >
                    <div className="cen1" style={{ color: "#fff" }}>
                      <div className="cir">
                        <div className="glowcircle glowcircle2">
                          <div className="circleInnerText">
                            <p className="innerCircleTitle">
                              {sectorWiseState.title}
                            </p>
                            <p className="innerCircleValue">
                              {sectorWiseState?.value &&
                                roundNumber(sectorWiseState?.value) + "%"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <GlowCircle
                      centerInfoCircleStyle={{
                        top: "50.9%",
                        left: "55%",
                      }}
                      circleBorderStyle={{
                        maxWidth: "144px",
                        height: "144px",
                      }}
                      glowCircleStyle={{ width: "122px", height: "122px" }}
                    >
                      <div className="circle_content">
                        <div> */}
                    {/* <b>{pie.text}</b> */}
                    {/* <br></br> */}
                    {/* <b>{pie.value}</b> */}
                    {/* </div>
                      </div> */}
                    {/* </GlowCircle> */}
                  </PieChart>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      marginBlock: "23px",
                    }}
                  >
                    {sectorAllocation.map((m, i) => {
                      return (
                        <div className="llabeContainer">
                          <div className="lsymbol">
                            <svg height="25" width="25">
                              <circle
                                cx="12"
                                cy="12"
                                r="5"
                                stroke-width="3"
                                fill={m?.color}
                              />
                              Sorry, your browser does not support inline SVG.
                            </svg>
                          </div>
                          <div className="llabel">
                            <span className="labelText sector-wise-holding">
                              {m?.label}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Grid>
          )}
          <Grid item md={7} sm={12}>
            <div
              style={{
                maxHeight: "475px",
                display: "flex",
                //   width: "97%",
                margin: "0% 1.5%",
                height: "100%",
              }}
              className={classes.mainContent}
            >
              <div style={{ width: "-webkit-fill-available" }}>
                <PmsReportTable
                  tableMaxHeight={"446px"}
                  tableColumns={tableColumns}
                  setTableColumns={setTableColumns}
                  headerList={EquityTableConfig}
                  tableHeader={tableHeader}
                  setTableHeader={setTableHeader}
                  tableBodyBackground={"transparent"}
                  tableHeaderBGColor={"#1D1B36"}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default SectorWiseHolding;
