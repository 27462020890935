import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { ProgressBarFile } from "./ProgressBarFile";
import { useProductWiseAllocation } from "../../../../hooks/useProductWiseAllocation";
import PageLoader from "../../products/common/pageLoader";

export const HoldingProductWiseAllocation = () => {
  const { productWiseAllocationData, loading } =
    useProductWiseAllocation("dashboard");

  const useHoldingProductWiseAllocation = makeStyles({
    header: {
      fontSize: "20px",
      fontWeight: "500",
      color: "#FFFFFF",
      marginTop: "40px",
    },
    marginCls: {
      margin: "2%",
    },
    para: {
      color: "#816f9c",
      fontSize: "12px",
      fontWeight: "800",
    },

    progressHead: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "40px",
    },
  });
  const classes = useHoldingProductWiseAllocation();

  return (
    <>
      <div style={{ margin: "2%" }}>
        <h1 className={classes.header}>Product Wise Allocation</h1>
        <div className={classes.progressHead}>
          <p className={classes.para}>PRODUCT NAME</p>
          <p className={classes.para} style={{ paddingRight: "140px" }}>
            WEIGHTAGE
          </p>
        </div>
        {productWiseAllocationData?.map((item) => (
          <ProgressBarFile key={item.product} item={item} />
        ))}
      </div>
      <PageLoader showLoader={loading} />
    </>
  );
};
