import React from "react";
import Default from "../components/dashboard/defaultPages/Default";
import PartnerApproved from "../components/newPartnerList/PartnerApproved";

function PartnerApprovedPage() {
  return (
    <Default>
      <PartnerApproved />
    </Default>
  );
}

export default PartnerApprovedPage;
