import React from 'react'
import Default from '../components/dashboard/defaultPages/Default'
import EquityStockDetails from "../components/dashboard/products/equity/EquityStockDetails";

export default function EquityStockDetailsPage() {
  return (
    <div>
      <Default>
        <EquityStockDetails></EquityStockDetails>
      </Default>


    </div>
  )
}
