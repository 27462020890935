import React from "react";
import PageFilter from "../common/PageFilter";
import TabBondsMain from "../common/TabBondsMain";
import useStyles from "../common/ProductsStyles";
import BondsTransaction from "./BondsTransaction";
import BondsHolding from "./BondsHolding";
import BondsCredit from "./BondsCredit";
import BondsMaturity from "./BondsMaturity";
import BondsDuration from "./BondsDuration";
import BondsComparison from "./BondsComparison";
import BondsTypes from "./BondsTypes";
import { useEffect, useState } from "react";
import TabComponent from "../common/TabComponent";
import NewTabComp from "../common/NewTabComp";
import { routes } from "../../../../routes";
import { NewFilter } from "../../newFilter/NewFilter";
import { SHOW_ADDITIONAL_DATA_TAB } from "../../../../Config";
import BondsCashFlow from "./BondsCashFlow";

let data = [];

if (SHOW_ADDITIONAL_DATA_TAB === "show") {
  data = [
    {
      page: "credit",
      title: "Credit Rating",
      path: routes?.bondsSummaryPage_credit,
    },
    {
      page: "maturity",
      title: "Maturity",
      path: routes?.bondsSummaryPage_maturity,
    },
    // { page: "duration", title: "Duration" },
    {
      page: "comparsion",
      title: "Comparison",
      path: routes?.bondsSummaryPage_comparsion,
    },
    {
      page: "types",
      title: "Types of Bonds",
      path: routes?.bondsSummaryPage_types,
    },
    {
      page: "holding",
      title: "Holding Summary",
      path: routes?.bondsSummaryPage_holding,
    },
    {
      page: "transaction",
      title: "Transaction Summary",
      path: routes?.bondsSummaryPage_transection,
    },
    {
      page: "cash-flow",
      title: "Cash Flow",
      path: routes?.bondsSummaryPage_cashflow,
    },
  ];
} else {
  data = [
    {
      page: "credit",
      title: "Credit Rating",
      path: routes?.bondsSummaryPage_credit,
    },
    {
      page: "maturity",
      title: "Maturity",
      path: routes?.bondsSummaryPage_maturity,
    },
    {
      page: "types",
      title: "Types of Bonds",
      path: routes?.bondsSummaryPage_types,
    },
    {
      page: "holding",
      title: "Holding Summary",
      path: routes?.bondsSummaryPage_holding,
    },
    {
      page: "transaction",
      title: "Transaction Summary",
      path: routes?.bondsSummaryPage_transection,
    },
    {
      page: "cash-flow",
      title: "Cash Flow",
      path: routes?.bondsSummaryPage_cashflow,
    },
  ];
}

function BondsReport({ pageName }) {
  // console.log(pageName,);
  const [page, setPage] = useState(pageName);
  const classes = useStyles();
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const changePage = (p) => {
    switch (p) {
      case "transaction":
        return <BondsTransaction />;
      case "holding":
        return <BondsHolding />;
      case "credit":
        return <BondsCredit />;
      case "maturity":
        return <BondsMaturity />;
      case "duration":
        return <BondsDuration />;
      case "comparsion":
        return <BondsComparison />;
      case "types":
        return <BondsTypes />;
      case "cash-flow":
        return <BondsCashFlow entity={"bonds"} assetClasses={"debt"} />;
      default:
        return <BondsTransaction></BondsTransaction>;
    }
  };

  // useEffect(() => {
  //     setPage(pageName)
  // }, [pageName])

  const handleClick = React.useCallback(
    (page) => {
      setPage(page);
    },
    [page]
  );

  return (
    <>
      <PageFilter
        backLink={routes.bondsSummaryPage}
        setFilterModalOpen={setFilterModalOpen}
      ></PageFilter>
      <NewFilter
        filterModalOpen={filterModalOpen}
        setFilterModalOpen={setFilterModalOpen}
        productType="bonds"
      />
      <div
        style={{ minHeight: "500px" }}
        // className={
        //   pageName == "transaction" || pageName == "holding"
        //     ? ""
        //     : classes?.mainContent
        // }
      >
        <div style={{ margin: "2%" }}>
          <NewTabComp
            data={data}
            customWidth={"100%"}
            customHandle={handleClick}
            customSelect={data.findIndex((val) => val?.page == page)}
          />
        </div>
        {changePage(page)}
      </div>
    </>
  );
}

export default BondsReport;
