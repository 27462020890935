import React from "react";
import { notFoundSvgIcon } from "../constand";
import { Box, useTheme } from "@material-ui/core";
import "./TableDataNotFound.css";
import AddButton from "../../../common/AddButton";
import { PermissionsGate } from "../../../auth/PermissionGate";
export const TableDataNotFound = ({
  tabType,
  addButtonCLick,
  customScopes,
}) => {
  const theme = useTheme();
  const icons = theme.palette.icons;
  const NotFoundicon = icons.notFoundicon;
  return (
    <Box className="notFoundWrapper">
      <NotFoundicon />
      <div className="notFoundTextWrapper">
        <h1 className="notFoundMainText">Looks Empty !</h1>
        <p className="notFoundSubText">
          View All {tabType} Return presets here.
        </p>
      </div>
      <div>
        {/* TODO: check for customScopes shuld be handled on  PermissionsGate */}
        {customScopes ? (
          <PermissionsGate scopes={customScopes}>
            <AddButton
              addBtnTitle={`${tabType}`}
              customBtnTxtClass={"capitalize btTxt"}
              handleIconClick={addButtonCLick}
            />
          </PermissionsGate>
        ) : (
          <AddButton
            addBtnTitle={`${tabType}`}
            customBtnTxtClass={"capitalize btTxt"}
            handleIconClick={addButtonCLick}
          />
        )}
      </div>
    </Box>
  );
};
