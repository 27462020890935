import { createContext, useState, useMemo, useEffect } from "react";
import { createTheme } from "@material-ui/core";
import { tokens } from "./colorTokens";
import { defaultThemeIcons, goldThemeIcons } from "./icons";

export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "gold"
        ? {
            primary: {
              main: colors.primarySurface,
              light: colors.lightAccent,
              dark: colors.highLight,
              contrast: colors.secondary,
              contrastText: "",
            },
            secondary: {
              main: colors.lightAccent,
              light: colors.secondaryLightAccent,
              dark: colors.secondaryHighLight,
              contrastText: "",
            },
            text: {
              disabled: "",
              hint: "",
              primary: "#ffffff",
              secondary: "#000000",
            },
            success: {
              main: colors.gain,
              light: colors.successProfit,
              dark: "",
              contrastText: "",
            },
            error: {
              main: colors.loss,
              light: colors.failedLoss,
              dark: "",
              contrastText: "",
            },
            icons: {
              ...goldThemeIcons,
            },
            gradient: {
              primary: colors.lightAccent,
            },
            action: {
              hover: "rgba(242, 247, 161, 0.3)",
            },
            newMenu: { ...colors.newMenu },
            memberListing: { ...colors.memberListing },
            cards: {
              ...colors.cards,
            },
            searchIcon: { ...colors.searchIcon },
            rightArrowIcon: { ...colors.rightArrowIcon },
            newFilter: { ...colors.newFilter },
            filterCalendar: { ...colors.filterCalendar },
            selectDropDown: { ...colors.selectDropDown },
            popOverList: { ...colors.popOverList },
            themeSwitcher: { ...colors.themeSwitcher },
            bankBookCard: { ...colors.bankBookCard },
            top5AmcIssuerTable: { ...colors.top5AmcIssuerTable },
          }
        : {
            primary: {
              main: colors.primarySurface,
              light: colors.lightAccent,
              dark: colors.highLight,
              contrast: colors.secondary,
              contrastText: "",
            },
            secondary: {
              main: colors.lightAccent,
              light: colors.secondaryLightAccent,
              dark: colors.secondaryHighLight,
              contrastText: "",
            },
            text: {
              disabled: "",
              hint: "",
              primary: "#000000",
              secondary: "",
            },
            success: {
              main: colors.gain,
              light: colors.successProfit,
              dark: "",
              contrastText: "",
            },
            error: {
              main: colors.loss,
              light: colors.failedLoss,
              dark: "",
              contrastText: "",
            },
            icons: {
              ...defaultThemeIcons,
            },
            gradient: {
              primary:
                "linear-gradient(128deg, #2B0D58 -13.68%, #692FC0 109.3%, #692FC0 124.7%)",
            },
            action: {
              hover: "#222325",
            },
            newMenu: { ...colors.newMenu },
            memberListing: { ...colors.memberListing },
            cards: {
              ...colors.cards,
            },
            searchIcon: { ...colors.searchIcon },
            rightArrowIcon: { ...colors.rightArrowIcon },
            newFilter: { ...colors.newFilter },
            filterCalendar: { ...colors.filterCalendar },
            selectDropDown: { ...colors.selectDropDown },
            popOverList: { ...colors.popOverList },
            themeSwitcher: { ...colors.themeSwitcher },
            bankBookCard: { ...colors.bankBookCard },
            top5AmcIssuerTable: { ...colors.top5AmcIssuerTable },
          }),
    },
  };
};

export const ColorModeContext = createContext({
  toggleColorMode: () => {},
  mode: "default",
});

export const useMode = () => {
  const [mode, setMode] = useState(() => {
    const localMode = window.localStorage.getItem("colorMode");
    return localMode || "default";
  });

  useEffect(() => {
    const handleThemeChange = () => {
      const updatedMode = window.localStorage.getItem("colorMode");
      setMode(updatedMode || "default");
    };

    window.addEventListener("themeChange", handleThemeChange);

    return () => window.removeEventListener("themeChange", handleThemeChange);
  }, []);

  useEffect(() => {
    window.localStorage.setItem("colorMode", mode);

    window.dispatchEvent(new Event("themeChange"));
  }, [mode]);

  const colorMode = {
    toggleColorMode: (modeType) => setMode(modeType),
  };

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return [theme, colorMode, mode, setMode];
};
