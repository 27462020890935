import React from "react";
import { InfoIcon } from "./InfoIcon";
import { useTheme } from "@material-ui/core";

export const TotalGainLossCardInfo = ({
  textToRender,
  styleTotalGainLossCardInfoContainer,
}) => {
  const theme = useTheme();
  // styleTotalGainLossCardInfoContainer porp is used to override style
  // of className totalGainLossCardInfoContainer from outside
  // See HoldingSummaryComp for Implementation
  return (
    <>
      <div
        className="totalGainLossCardInfoContainer"
        style={{
          ...styleTotalGainLossCardInfoContainer,
          background: theme.palette.cards.mainCard,
        }}
      >
        <div className="totalGainLossInfoHeaderContainer">
          {/* Header Icon */}
          <InfoIcon />
          {/* Header Text */}
          <h1 className="totalIncomeText">Other Income</h1>
        </div>
        {textToRender}
      </div>
    </>
  );
};
