import React, { useState, useEffect, useRef } from "react";
import NewTabComp from "../dashboard/products/common/NewTabComp";
import Default from "../dashboard/defaultPages/Default";
import { Grid, Select, makeStyles, FormControl } from "@material-ui/core";
import "./NewVerificationUpload.css";
import AppPagination from "../../components/verificationUpload/AppPagination";
import { ITEMS_LIMIT_FILES, PRODUCT_TYPES } from "../../Constants";
import { PendingVerification } from "./PendingVerification";
import { getFileTypeByProduct } from "../utils/common";
import { useFetchFiles } from "../../hooks/useFetchFiles";
import PageLoader from "../dashboard/products/common/pageLoader";
import { AlertDialog } from "../dashboard/common/AlertDialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import { ReactComponent as CommentIcon } from "../../assets/icons/commentIcon.svg";
import { updateFileStatus } from "../../api";
import CustomizedSnackbars from "../common/CustomizedSnackbars";
import { OnboardingContext } from "../../Context";
import { useNavigate } from "react-router-dom";
import { VerifiedUpload } from "./VerifiedUpload";
import { formatString } from "./utilsVerificationUpload";
import { VERIFICATION_UPLOAD_FILE_TYPES } from "../../Constants";
const useStyles = makeStyles((theme) => ({
  icon: {
    fill: "#FAF9FF",
  },
  text: {
    fontStyle: "normal",
    fontFamily: "Avenir",
  },
  inputField: {
    borderColor: " #711CF1",
    borderRadius: "2px",
    width: "100%",
    alignItems: "center",
    "& .MuiOutlinedInput-root": {
      borderRadius: "6px !important",
      border: "none !important",
      background: "#000 !important",
      width: "90%",
    },
  },

  iconborder: {
    border: "1px solid #4E189F",
    backdropFilter: "blur(50px)",
    borderRadius: "30px",
    margin: "20px 5px 5px 5px",
    padding: "22px 55px 10px 44px",
  },
  uploadicon: {
    position: "absolute",
    top: "-30px",
    left: "-50px",
  },

  line: {
    opacity: "0.2",
    backgroundColor: "#D4BBFB",
    border: ".5px solid #D4BBFB",
    marginBlock: "2em",
    width: "100%",
  },

  uploadsvg: {
    width: "13%",
    paddingTop: "10%",
  },

  selectDropDownMenu: {
    borderRadius: "4px !important",
    border: "1.164px solid #461277 !important",
  },
}));

export const NewVerificationUpload = () => {
  const classes = useStyles();
  const [page, setPage] = useState("pending");
  const [itemOffset, setItemOffset] = useState(0);
  const [formData, setFormData] = useState({
    entity: "mf",
    fileType: "transaction",
  });
  const getDisplayTextForValue = (value) => {
    const fileTypeOption = VERIFICATION_UPLOAD_FILE_TYPES.find(
      (ft) => ft.value === value
    );
    return fileTypeOption ? fileTypeOption.name : "Select Transaction Type";
  };
  const [open, setOpen] = useState(false);
  const [textarea, setTextArea] = useState({ rejectedReason: "", status: "" });
  const documentIdRef = useRef(null);
  const { fetchFiles, content, pageCount, showLoader } = useFetchFiles({
    pageName: page,
    formData: formData,
    searchName: "",
    offset: itemOffset,
    itemLimit: ITEMS_LIMIT_FILES,
  });

  const { dispatch } = OnboardingContext();
  const navigate = useNavigate();
  useEffect(() => {
    fetchFiles();
  }, [page, formData, itemOffset]);

  const handleTabClick = (page) => {
    setPage(page);
    setItemOffset(0);
  };
  function getUploadedDocumentId(documentId) {
    documentIdRef.current = documentId;
  }

  const handleChange = (e) => {
    if (e.target.value == "") {
      return;
    }
    let newFormData = { ...formData, [e.target.name]: e.target.value };
    if (e.target.name === "entity") {
      let defaultFileType = "transaction";
      newFormData.fileType = defaultFileType;
    }
    setFormData(newFormData);
    setItemOffset(0);
  };

  const handlePageChange = (page) => {
    switch (page) {
      case "pending":
        return (
          <PendingVerification
            setOpen={setOpen}
            pendingData={content}
            getUploadedDocumentId={getUploadedDocumentId}
            submitStatus={submitStatus}
          />
        );
      case "verified":
        return (
          <VerifiedUpload
            setOpen={setOpen}
            verifiedData={content}
            getUploadedDocumentId={getUploadedDocumentId}
            submitStatus={submitStatus}
          />
        );
      default:
        return (
          <PendingVerification
            setOpen={setOpen}
            pendingData={content}
            getUploadedDocumentId={getUploadedDocumentId}
            submitStatus={submitStatus}
          />
        );
    }
  };

  const submitStatus = async (status) => {
    // 👇 API CALL
    try {
      const data = await updateFileStatus(
        { ...textarea, status },
        formData.entity,
        documentIdRef.current,
        formData.fileType === "upcoming_transaction"
          ? "upcoming-transaction"
          : formData.fileType === "underlying_transaction"
          ? "underlying-transaction"
          : formData.fileType
      );
      const filesData = data.data;
      if (filesData.success) {
        setOpen(false);
        // setShowLoader(false);

        dispatch({
          type: "UPDATE_SNACKBAR_DATA",
          payload: {
            success: true,
            error: false,
            showMsg: true,
            message: filesData.message,
          },
        });

        window.location.reload();
      }
    } catch (error) {
      setOpen(false);
      // setShowLoader(false);
      if (error?.response?.status === 401) {
        navigate("/logout");
      } else if (!error?.response?.data?.error?.success) {
        dispatch({
          type: "UPDATE_SNACKBAR_DATA",
          payload: {
            success: false,
            error: true,
            showMsg: true,
            message: error?.response?.data?.error?.message,
          },
        });
      }
    }
  };

  return (
    <>
      <CustomizedSnackbars />
      <PageLoader showLoader={showLoader ? true : false} />
      <AlertDialog
        open={open}
        setOpen={setOpen}
        dialogTitle={() => {}}
        dialogContent={() => {
          return (
            <DialogContentText
              id="alert-dialog-description"
              style={{
                color: "#fff",
                textAlign: "center",
                fontFamily: "Avenir",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "22px",
              }}
            >
              <div className="commentBoxContainer">
                <div className="commentBoxHeader">
                  <div className="flexCenterIconLabel">
                    <CommentIcon />
                    <p>Add Comment</p>
                  </div>
                  <div
                    style={{
                      cursor: "pointer",
                      marginRight: "1rem",
                    }}
                    onClick={() => setOpen(false)}
                  >
                    x
                  </div>
                </div>
                <textarea
                  value={textarea.rejectedReason}
                  onChange={(e) => {
                    setTextArea((prev) => ({
                      ...prev,
                      rejectedReason: e.target.value,
                    }));
                  }}
                  placeholder="Comments help users understand why the particular file was rejected and other question....."
                  rows={10}
                  cols={70}
                  className="commentBoxTextArea"
                ></textarea>
              </div>
            </DialogContentText>
          );
        }}
        dialogFooter={() => {
          return (
            <button
              onClick={() => submitStatus("rejected")}
              className="commentBoxBtn"
            >
              Continue
            </button>
          );
        }}
      />
      <div style={{ maxWidth: "90%", margin: "0 auto", marginTop: "1.2rem" }}>
        <NewTabComp
          data={[
            {
              title: "Pending Verification",
              page: "pending",
              path: "/verification-upload/pending",
            },

            {
              title: "Verified Upload",
              page: "verified",
              path: "/verification-upload/verify",
            },
          ]}
          customWidth={"100%"}
          customHandle={handleTabClick}
        />

        <Grid
          container
          style={{
            borderRadius: "4px",
            background:
              "linear-gradient(180deg, #211839 0%, rgba(33, 24, 57, 0.00) 100%)",
            // border: "1px solid red",
            marginTop: "1.75rem",
            padding: "1rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid item xs={6}>
            <p className="selectDropDownLabel" style={{ marginLeft: "2.1rem" }}>
              Select Product
            </p>
            <FormControl
              variant="outlined"
              className={`${classes.formControl} ${classes.inputField}`}
            >
              <Select
                // For PlaceHolder
                displayEmpty
                renderValue={() => {
                  const selectedProduct = PRODUCT_TYPES.find(
                    (p) => p.value === formData.entity
                  );
                  return selectedProduct
                    ? selectedProduct.name
                    : "Select Product";
                }}
                // native
                style={{ color: "#FAF9FF" }}
                labelId="demo-simple-select-outlined-label"
                // label="Select Product"
                value={formData.entity}
                onChange={(e) => handleChange(e)}
                inputProps={{
                  name: "entity",
                  id: "outlined-entity_type-native-simple",
                  classes: {
                    icon: classes.icon,
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      backgroundColor: "#1e0d2d",
                      border: "1.164px solid #461277",
                      marginTop: "1rem",
                    },
                  },
                  MenuListProps: {
                    className: classes.selectDropDownMenu,
                  },
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {PRODUCT_TYPES.map((product) => {
                  return (
                    <option
                      className="optiondesignNew"
                      key={product.value}
                      value={product.value}
                    >
                      {product.name}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <p className="selectDropDownLabel" style={{ marginLeft: "2.1rem" }}>
              Select Transaction Type
            </p>
            <FormControl
              variant="outlined"
              className={`${classes.formControl} ${classes.inputField}`}
            >
              <Select
                // For PlaceHolder
                //displayEmpty
                // renderValue={() => {
                //   return formData?.fileType
                //     ? formData?.fileType
                //     : "Select Transaction Type";
                // }}
                // renderValue={() => {
                //   return getDisplayTextForValue(formData.fileType);
                // }}
                // native
                style={{ color: "#FAF9FF" }}
                labelId="demo-simple-select-outlined-label"
                // label="Select Product"
                value={formData.fileType}
                onChange={(e) => handleChange(e)}
                inputProps={{
                  name: "fileType",
                  id: "outlined-entity_type-native-simple",
                  classes: {
                    icon: classes.icon,
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      backgroundColor: "#1e0d2d",
                      border: "1.164px solid #461277",
                      marginTop: "1rem",
                    },
                  },
                  MenuListProps: {
                    className: classes.selectDropDownMenu,
                  },
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {getFileTypeByProduct(
                  formData?.entity,
                  undefined,
                  {
                    payOutReduction: true,
                  },
                  "optiondesignNew"
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {handlePageChange(page)}

        <div className="paginationstyl">
          <AppPagination
            pageCount={pageCount}
            itemLimit={ITEMS_LIMIT_FILES}
            setItemOffset={setItemOffset ? setItemOffset : () => {}}
            otherProps={{ shape: "rounded" }}
          />
        </div>
      </div>
    </>
  );
};
