import React from "react";
import { useParams } from "react-router-dom";
import Default from "../components/dashboard/defaultPages/Default";
import PmsReport from "../components/dashboard/products/pms/PmsReport";

function PmsSummaryDetails() {
  //   const location = useLocation();
  // const pageName = location?.pathname.split('/')[5]
  let { pageName } = useParams();

  return (
    <>
      <Default>
        <PmsReport pageName={pageName}></PmsReport>
      </Default>
    </>
  );
}

export default PmsSummaryDetails;
