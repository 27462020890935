import React from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { myDebounce } from "../../hooks/constantFunction";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
export default function Searchval({
  setSearchName,
  options = [],
  search,
  setSearch,
  allval,
  option,
  setOptions,
  options2,
  setSelect,
  selected,
}) {
  const wrapperRef = useRef(null);
  const [display, setDisplay] = useState(false);

  // let obj = options.filter((o) => {
  //
  //   return o[option.name ? option.name : "name"];
  // });

  const handleClickOutside = (event) => {
    const { current: wrap } = wrapperRef;
    if (wrap && !wrap.contains(event.target)) {
      setDisplay(false);
    }
  };
  const handleSearchChange = (value) => {
    setSearchName(value);
  };
  const myDebounceSearch = useCallback(myDebounce(handleSearchChange), []);
  const updateselected = (poke, setval, id, setselect) => {
    setOptions([]);
    setval.setSearch({ ...allval, [setSearch.txt]: { value: poke, id: id } });
    setSelect({ ...allval, [setSearch.txt]: { value: poke, id: id } });
    setDisplay(false);
  };
  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <div>
      <div className="sch" ref={wrapperRef}>
        <input
          onClick={(e) => {
            setDisplay(true);
          }}
          value={search}
          onChange={(event) => {
            myDebounceSearch(event.target.value);
            setSearch.setSearch({
              ...allval,
              [setSearch.txt]: { value: event.target.value },
            });
          }}
        ></input>
        <Search
          style={{
            color: "#949BA5",
            verticalAlign: "middle",
            paddingRight: "20px",
          }}
        />
        {display && (
          <div className="autContainer">
            {(options2.length < 1 || !search) &&
              options.length > 0 &&
              options
                .filter(
                  (o) =>
                    o[option.name ? option.name : "name"]
                      ?.toLowerCase()
                      ?.indexOf(search?.toLowerCase()) > -1
                )
                .map((value, i) => {
                  return (
                    <div
                      onClick={() => {
                        return updateselected(
                          value[option.name ? option.name : "name"],
                          setSearch,
                          value[option.id ? option.id : "id"],
                          setSelect
                        );
                      }}
                      className="opt"
                      key={i}
                      tabIndex="0"
                    >
                      <span>{value[option.name ? option.name : "name"]}</span>
                    </div>
                  );
                })}
            {options2.length > 0 &&
              search &&
              options2
                .filter(
                  (o) =>
                    o[option.name ? option.name : "name"]
                      ?.toLowerCase()
                      ?.indexOf(search?.toLowerCase()) > -1
                )
                .map((value, i) => {
                  return (
                    <div
                      onClick={() => {
                        setOptions([]);
                        return updateselected(
                          value[option.name ? option.name : "name"],
                          setSearch,
                          value[option.id ? option.id : "id"],
                          setSelect
                        );
                      }}
                      className="opt"
                      key={i}
                      tabIndex="0"
                    >
                      <span>{value[option.name ? option.name : "name"]}</span>
                    </div>
                  );
                })}
          </div>
        )}
      </div>
    </div>
  );
}
