import React from "react";
import { Link } from "react-router-dom";
import useStyles from "./ProductsStyles";
import { routes } from "../../../../routes";

function TabBondsMain({ pageName }) {
  const classes = useStyles();
  return (
    <div
      style={{ width: "fit-content", margin: "2%", cursor: "pointer" }}
      className={classes.tabMfo}
    >
      <Link
        style={{ textDecoration: "none" }}
        to={routes.bondsSummaryPage_transection}
      >
        <div
          className={`${classes.tabHeaderText} ${
            pageName === undefined || pageName === "transaction"
              ? classes.active
              : ""
          }`}
        >
          Transaction Summary
        </div>
      </Link>
      <Link
        style={{ textDecoration: "none" }}
        to={routes.bondsSummaryPage_holding}
      >
        <div
          className={`${classes.tabHeaderText} ${
            pageName === "holding" ? classes.active : ""
          }`}
        >
          Holding Summary
        </div>
      </Link>
      <Link
        style={{ textDecoration: "none" }}
        to={routes.bondsSummaryPage_credit}
      >
        <div
          className={`${classes.tabHeaderText} ${
            pageName === "credit" ? classes.active : ""
          }`}
        >
          Credit Rating
        </div>
      </Link>
      <Link
        style={{ textDecoration: "none" }}
        to={routes.bondsSummaryPage_maturity}
      >
        <div
          className={`${classes.tabHeaderText} ${
            pageName === "maturity" ? classes.active : ""
          }`}
        >
          Maturity
        </div>
      </Link>
      {/* <Link style={{ textDecoration: 'none' }} to='/home/bonds/bonds-summary/duration'><div className={`${classes.tabHeaderText} ${(pageName === "duration") ? classes.active : ''}`}>Duration</div></Link> */}
      <Link
        style={{ textDecoration: "none" }}
        to={routes.bondsSummaryPage_comparsion}
      >
        <div
          className={`${classes.tabHeaderText} ${
            pageName === "comparsion" ? classes.active : ""
          }`}
        >
          Comparison
        </div>
      </Link>
      <Link
        style={{ textDecoration: "none" }}
        to={routes.bondsSummaryPage_types}
      >
        <div
          className={`${classes.tabHeaderText} ${
            pageName === "types" ? classes.active : ""
          }`}
        >
          Type of Bonds
        </div>
      </Link>
    </div>
  );
}

export default TabBondsMain;

// not used
