import React, { useState, useEffect, useMemo } from "react";
import UserManagementTableWrapper from "../user_management/UserManagementTableWrapper";
import {
  bodyData4,
  headerDataPartner,
  cleintByPartnerId,
} from "../user_management/utils/constands";
import { useNavigate, useLocation } from "react-router-dom";
import ClientAssistentJourneyFlow from "./clientAssistentJourney/ClientAssistentJourneyFlow";
import useGetClientListByPartnerId from "./hooks/useGetClientListByPartnerId";
import { OnboardingContext } from "../../Context";
import ls from "localstorage-slim";
import { ITEMS_LIMIT_FILES_PARTNER_LIST } from "../../Constants";

function NewClientList() {
  const { userState } = OnboardingContext();
  const { state } = useLocation();
  const [openModal, setOpenModal] = useState(
    state?.openModal ? state?.openModal : false
  );
  const [header, setHeader] = useState([]);
  const [data, setData] = useState([]);
  const naivgate = useNavigate();
  const { getClientListByPartnerId, loading } = useGetClientListByPartnerId();
  const scopes = JSON.parse(ls.get("scopes"));
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);
  const totalPage = useMemo(
    () => Math.ceil(total / ITEMS_LIMIT_FILES_PARTNER_LIST),
    [total]
  );
  const handleHeader = (headerData) => {
    headerData?.map((val) => {
      if (val?.field == "client_full_name") {
        val.onClick = (data) => {
          naivgate("/client/user-management-list", {
            state: {
              headerType: "userManagementDetailsHeader",
              data: data,
            },
          });
        };
      }
    });
    setHeader(() => headerData);
  };

  const getData = () => {
    let partnerId = userState?.loginedUserData?.id;
    //#TODO remove this hard coded once we get proper data for api.
    // partnerId = 287;

    let params = {
      limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
      offset: offset,
      search: search,
      orderBy: "full_name",
      onboarding_status: "approved",
      sortBy: "ASC",
    };

    getClientListByPartnerId(partnerId, params)
      .then((res) => {
        // console.log(res, res?.data?.data.meta?.count, "gslgjk");
        setTotal(res?.data?.data.meta?.count);
        setData(() => res?.data?.data?.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    handleHeader(cleintByPartnerId);
    // setData(() => bodyData4);
    getData();
    return () => {
      setData([]);
      setHeader([]);
    };
  }, [offset, search]);

  return (
    <>
      {/* {header?.length > 0 && ( */}
      <UserManagementTableWrapper
        customTitle="Client List"
        addBtnTitle="Add Client"
        headerData={header}
        data={data}
        openModal={openModal}
        setOpenModal={setOpenModal}
        customIcon={<></>}
        customScopes={[scopes?.can_add_client]}
        offset={offset}
        setItemOffset={setOffset}
        setSearchTerm={setSearch}
        totalPage={totalPage}
        loading={loading}
      />
      <ClientAssistentJourneyFlow
        openModal={openModal}
        setOpenModal={setOpenModal}
      />

      {/* )} */}
    </>
  );
}

export default NewClientList;
