import React, { useEffect, useState } from "react";

import PageContainer from "../PageContainer";
import Header from "../Components/Header";
import PrintTable from "../Components/Table";
import ApexNew_print from "../Components/ApexNew_print";
import LineData from "../../../charts/LineData";
import { ReactComponent as Question } from "../images/question.svg";
import { objectFormatingPrint } from "../Components/utils/common";
import { currencyIn } from "../Components/utils/staticData";

const stbodyData = [
  {
    assetClass: "Equity",
    currentValue: "12",
    absoluteValue: "10.3454",
    // totalGainLoss: "2.4",
    // xirr: "12.4",
    // absolueteTwrr: "-12.4",
    // annualizedTwrr: "12.4",
  },
  {
    assetClass: "Debt",
    currentValue: "12",
    absoluteValue: "-10.3454",
    // totalGainLoss: "2.4",
    // xirr: "12.4",
    // absolueteTwrr: "12.4",
    // annualizedTwrr: "-12.4",
  },
  {
    assetClass: "Alternates",
    currentValue: "12",
    absoluteValue: "10.3454",
    // totalGainLoss: "2.4",
    // xirr: "-12.4",
    // absolueteTwrr: "12.4",
    // annualizedTwrr: "12.4",
  },
];

const stHeaderData = [
  {
    field: "assetClass",
    title: "Commodities and Currency",
    align: "left",
    color: "",
  },
  {
    field: "currentValue",
    title: "Current Value",
    subTitle: currencyIn,
  },
  {
    field: "absoluteValue",
    title: "Absolute Value",
    subTitle: currencyIn,
  },
  // {
  //   field: "totalGainLoss",
  //   title: "TOtal Gain/Loss",
  //   subTitle: "₹ In Crore",
  // },
  // {
  //   field: "xirr",
  //   title: "Xirr",
  // },
  // {
  //   field: "absolueteTwrr",
  //   title: "Absoluete Twrr",
  // },
  // {
  //   field: "annualizedTwrr",
  //   title: "Annualized Twrr",
  // },
];

export default function Performance_key_indices({ data1, denomination }) {
  const colors = ["#647DAA", "#000"];
  const [data, setData] = useState([]);
  const [updated, setUpdated] = useState(null);
  useEffect(() => {
    let stData = [];
    stbodyData.map((val) => {
      stData.push(objectFormatingPrint(val, stHeaderData, denomination));
    });
    setData(stData);
  }, []);
  useEffect(() => {
    if (data1.dashboard?.realiseddata?.result?.updatedAsOn) {
      setUpdated(data1.dashboard?.realiseddata?.result?.updatedAsOn);
    }
  }, [data1]);
  return (
    <div style={{ position: "relative" }}>
      <PageContainer layout_t={1} pageNo={2}>
        <Header
          title={`Performance of key Indices`}
          type={1}
          subtext={` `}
          width={"30%"}
          noDateFormat={true}
          updatedAsOn={updated}
        ></Header>
        <div className="p_flex" style={{ width: "50%" }}>
          <PrintTable
            customTableClass={"printTableEvenAndOddRow"}
            hideUnderText={true}
            data={data}
            header={stHeaderData}
          />
        </div>
        <div
          className="p_flex"
          style={{ height: "503px", width: "50%", alignItems: "flex-end" }}
        >
          <div
            className="h_card "
            style={{
              width: "338px",
              borderRadius: "18px",
              padding: "0px 13px 13px 13px",
              marginBottom: "40px",
              display: "flex",
              alignItems: "flex-start",
              border: "1px solid #5C86B0",
            }}
          >
            <Question height={"120"} width={"49"} />
            <div>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's
            </div>
          </div>
          <div></div>
        </div>
        <div
          className="m_card right_container"
          style={{
            width: "7.25in",
            left: "7.99in",
            top: "180px",
          }}
        >
          {" "}
          <div
            className="bold"
            style={{ padding: "30px 20px 20px 20px", fontSize: "24.007px" }}
          >
            Yield Curve & Repo Rate
          </div>
          <ApexNew_print
            linedata={LineData}
            title={{ y: "Returns" }}
            controller_disabled={true}
            legends_hide={true}
            config={{
              y: { show: true },
              colors: ["#00838F", "#ADC371"],
              height: "616px",

              dashed: [0, 0, 0, 0],
            }}
          />
        </div>
        {/* <CardsWrapper /> */}
        {/* <Hr title={"Portfolio Performance"} /> */}
        {/* <div className="h_card" style={{ height: "448px" }}> */}
        {/* <PrintTable /> */}
        {/* </div> */}
      </PageContainer>
    </div>
  );
}
