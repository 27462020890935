// var CryptoJS = require("crypto-js");
import React, { useRef } from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import Overall_portfolio from "./pages/Overall_portfolio";
import Portfolio_Performance from "./pages/Portfolio_Performance";
import TopPerformer from "./pages/TopPerformer";
import BottomPerformer from "./pages/BottomPerformer";
import Top5issues from "./pages/Top5issues";
import Top5amc from "./pages/Top5amc";
import Alternate_sub_asset from "./pages/Alternate_sub_asset";
import PerformanceSummary1v2 from "./pages/PerformanceSummary1v2";
import PerformanceSummary2v2 from "./pages/PerformanceSummary2v2";
import HoldingSummaryDirectEquity from "./pages/HoldingSummaryDirectEquity";
import Engagement_model from "./pages/Engagement_model";
import Equity_summary from "./pages/Equity_summary";
import Performance_key_indices from "./pages/Performance_key_indices";
import Performance_key_indices1 from "./pages/Performance_key_indices1";
import Market_snapshot from "./pages/Market_snapshot";
import { StackTable } from "./Components/StackTable";
import { AssetSubClass } from "./Components/AssetSubClass";
import { FirstPage } from "./Components/FirstPage";
import { LastPage } from "./Components/LastPage";
import EquitySummary from "./pages/EquitySummary";
import EquityTop5Stock from "./pages/EquityTop5Stock";
import Alternates from "./pages/Alternates";
import IncomeCashFlowCorpusExport from "./pages/IncomeCashFlowCorpusExport";
import RiskProfile from "./pages/RiskProfile";
import ReportingHierarchy from "./pages/ReportingHierarchy";
import Disclaimers from "./pages/Disclaimers";
import Disclaimer from "./pages/Disclaimer";
import { useApi } from "./hook/useApi";
import { useEffect } from "react";
import PerformanceSummary from "./pages/PeroformanceSummary";
import PortfolioAllocation from "./pages/PortfolioAllocation";
import EquityMF from "./pages/EquityMF";
import { useLocation, useSearchParams, useParams } from "react-router-dom";
import { alternateStaticData } from "./pages/utils";
import { useState } from "react";
import PageLoader from "../products/common/pageLoader";
import {
  EquityMFHolding,
  corpusTableHeader,
  corpusTableHeader2,
  top5amc,
  top5issuer,
} from "./Components/utils/staticData";
import EquityTop5Sectors from "./pages/EquityTop5Sectors";
import useWindowSize from "./hook/seWindowSize";
import CashFlow from "./pages/CashFlow";
import { Decrypt } from "./Components/utils/common";
import useGetMongoPrintReport from "../../../hooks/useGetMongoPrintReport";
import { getPrintDataMongo } from "../../../api";
import Asset_allocation from "./pages/Asset_allocation";
import Top5pdf from "./pages/Top5pdf";
import CashFlowNew from "./pages/CashFlowNew";
import Member_wise from "./pages/Member_wise";
import Product_Allocation from "./pages/Product_Allocation";
import Top3performer from "./pages/top3Performer";
import HoldingPdfPage from "./pages/HoldingPdfPage";
import ThankYouPage from "./pages/ThankYouPdf";
import AssetvsBenchmark from "./pages/AssetvsBenchmark";
import LoadingScreen from "./pages/LoadingScreen";
import FundInTransit from "./pages/FundInTransit";
import Advisor from "./pages/Advisor";
import TopBottomPerformer from "./pages/TopBottomPerformer";
import CreditRating from "./pages/CreditRating";
import EquitySectorAllocationPdf from "./pages/EquitySectorAllocationPdf";
import MarketCap from "./pages/MarketCap";
import SubCategoryContainer from "./pages/SubCategoryContainer";
import { CoverPage } from "./pages/subPrintPages/CoverPage";
import Annexures from "./pages/Annexures";
import { OnboardingContext } from "../../../Context";
// import Tax from "./pages/Tax";
// import TaxModule from "./pages/TaxModule";
// import TaxSort from "./pages/TaxSort";

export default function CashflowReport({ mongo, handleUploadPDF, download }) {
  const { dispatch } = OnboardingContext();
  const [browserSize, setBrowserSize] = useState(
    ((window.outerWidth - 10) / window.innerWidth) * 100
  );
  // const {
  //   state: { mongoId },
  // } = useLocation();
  // const {} = useSearchParams();
  let [searchParams, setSearchParams] = useSearchParams();
  // let denomination = "Lakhs";
  const mongoId = searchParams.get("id");
  const parentRef = useRef(null);
  // const { type, familyMembers, duration, denomination, mongoId } = Decrypt(
  //   searchParams.get("key")
  // );
  const windowSize = useWindowSize();
  const [changes, setChanges] = useState();
  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "#E4E4E4",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
  });
  //
  let payload = {};
  // if (familyMembers?.length > 0) {
  //   payload["memberId"] = familyMembers?.join(",");
  // }
  // if (duration?.startDate !== "") {
  //   payload["startDate"] = duration?.startDate;
  //   payload["endDate"] = duration?.endDate;
  // }
  // localStorage.setItem("denomination", denomination);
  const [data, setData] = useState({});
  const [reset, setReset] = useState(false);
  const {
    showLoader,
    setShowLoader,
    CallApis,
    errorMessage,
    customData,
    erroredAPIS,
    apiArray,
  } = useApi(payload, setReset);

  const { newData, denomination, meta, pdfInfo, loading } =
    useGetMongoPrintReport(
      // "658187af8a5d24635c5e0298"
      // // "65e00dea0cda3c000851f632"
      // "65e15f4b2fffc9727f32cb17"
      // "65e711971cd0cd00098d8f5e"

      // "65e8538e99eb3863be6ae12e"
      // "65f2a702ae19f10008f6aeb2"

      mongoId || mongo
    );
  // mongoId

  const getDenomination = localStorage.getItem("denomination");
  console.log("mongo", mongoId, newData);

  // useEffect(() => {
  //   // handleData();
  //   // if (getDenomination == denomination) {
  //   // setTimeout(() => {
  //   //   console.log(getDenomination, "asfjkbakjsbahf");
  //   CallApis(apiArray(payload).splice(0, 5), {}, 0, 3);
  //   // }, 5000);
  //   // }
  // }, []);

  // useEffect(() => {
  //   setData(() => customData);
  // }, [customData]);

  useEffect(() => {
    //for testing
    // Object?.keys(newData).map((a) => {
    //   newData[a] = "error";
    // });
    // if (changes) {

    if (browserSize < 99) {
      dispatch({
        type: "UPDATE_SNACKBAR_DATA",
        payload: {
          success: true,
          error: false,
          showMsg: true,
          message:
            "Please set Browser Zoom to 100% for better viewing of report",
        },
      });
    }
    let a = parentRef?.current?.querySelectorAll(".printPageNumber");
    if (!a) {
      return;
    }
    Array.from(a)?.map((val, i) => {
      val.innerHTML = `${i + 1}`;
    });
    // setNewPageList(a);
    // }

    const getData = setTimeout(() => {
      if (Object.keys(newData).length > 0 && download == 0) {
        handleUploadPDF();
      }
    }, 5000);

    return () => clearTimeout(getData);
  }, [changes, data, newData]);

  // console.log(Object?.keys(newData), "aglnj");

  return (
    <>
      {loading && (
        <>
          <CoverPage
            type={"pdf"}
            title={"Cash Flow Summary"}
            meat={meta}
            pdfInfo={pdfInfo}
          />
          <LoadingScreen loading={loading} />
        </>
      )}
      {Object?.keys(newData)?.length > 0 && (
        <Document ref={parentRef}>
          <CoverPage
            type={"pdf"}
            title={"Cash Flow Summary"}
            meat={meta}
            pdfInfo={pdfInfo}
          />

          <CashFlowNew
            denomination={denomination}
            newdata={newData?.["consolidated"] || {}}
            pdfInfo={pdfInfo}
          />
          {newData?.memberWiseData.map((m) => {
            return (
              <CashFlowNew
                denomination={denomination}
                newdata={m}
                pdfInfo={pdfInfo}
              />
            );
          })}

          <ThankYouPage pdfInfo={pdfInfo} />
          <Annexures pdfInfo={pdfInfo} />
          <Disclaimer pdfInfo={pdfInfo} />
        </Document>
      )}
    </>
  );
}
