import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as FileWhite } from "../../assets/icons/file_white.svg";
import { red } from "@material-ui/core/colors";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  TextField,
} from "@material-ui/core";
import { ReactComponent as DownloadFileicon } from "../../assets/icons/download.svg";
import { updateFileStatus } from "../../api";
import { OnboardingContext } from "../../Context";
import { useNavigate } from "react-router-dom";
import Loader from "../loader/Loader";
import CustomizedSnackbars from "../common/CustomizedSnackbars";
import "./card.css";
import { PermissionsGate } from "../auth/PermissionGate";
import ls from "localstorage-slim";
const useStyles = makeStyles((theme) => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  avatar: {
    backgroundColor: red[500],
  },
  filebluebg: {
    background: "linear-gradient(180deg, #AA6FFE 0%, #711CF1 100%)",
    // opacity: '0.3',
  },
  opacity1: {
    opacity: "1",
  },
  buttonfilled: {
    padding: "5px 10px",
    width: "100%",
    color: "rgb(255, 255, 255)",
    background:
      "linear-gradient(rgb(170, 111, 254) 0%, rgb(113, 28, 241) 100%)",
    borderRadius: "20px",
  },
  buttonlined: {
    padding: "5px 10px",
    width: "100%",
    color: "rgb(255, 255, 255)",
    background: "transparent",
    border: "1px solid #711CF1",
    borderRadius: "20px",
  },
  buttonText: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#FFFFFF",
  },
  postParentbutton: {
    position: "relative",
    bottom: "45px",
    left: "180px",
    border: "1px solid #FFFFFF",
    borderRadius: "15px",
    color: "#FFFFFF",
  },
  posttn: {
    fontSize: "16px",
    lineHeight: "22px",
    textAlign: "right",
    float: "right",
    color: "#FFFFFF",
    background: "transparent",
    padding: "5px 10px",
    width: "100%",
    color: "rgb(255, 255, 255)",
    border: "1px solid #fff",
    borderRadius: "20px",
  },
  carddefault: {
    color: "#fff",
    background: "#262346",
    borderRadius: "16px",
    margin: "5px",
  },
  detailbox: {
    background: "#1D1B36",
    borderRadius: "16px",
    color: "#635F94",
  },
  titletext: {
    color: "var(--selectedText)",
    fontWeight: "bold",
  },
  nomargin: {
    margin: "0px",
  },
  subheader: {
    color: "#635F94",
  },
  subnametext: {
    color: "#FFFFFF",
    opacity: "0.7",
  },
  comment: {
    color: "#FFFFFF",
  },
}));

function PendingCard({ singleContent, formDataEntityAndType }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [formData, setFormData] = useState({ rejectedReason: "", status: "" });
  const { dispatch } = OnboardingContext();
  // const { roleType, userPermissionsArr, scopes } = useGetRole();
  const scopes = JSON.parse(ls.get("scopes"));

  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const convertDate = (dateOld) => {
    let date = new Date(dateOld);
    date = date.toString("YYYY-MM-dd").split(" ");

    return `${date[2]} ${date[1]} ${date[3]} ${date[4]}`;
  };

  const handleInputChange = (rejectedReason) => {
    setFormData({ ...formData, rejectedReason });
  };

  const submitStatus = async (status) => {
    try {
      setShowLoader(true);
      const data = await updateFileStatus(
        { ...formData, status },
        formDataEntityAndType.entity,
        singleContent.id,
        formDataEntityAndType.fileType
      );
      const filesData = data.data;
      if (filesData.success) {
        setShowLoader(false);

        dispatch({
          type: "UPDATE_SNACKBAR_DATA",
          payload: {
            success: true,
            error: false,
            showMsg: true,
            message: filesData.message,
          },
        });

        window.location.reload();
      }
    } catch (error) {
      setShowLoader(false);
      if (error.response.request.status === 401) {
        navigate("/logout");
      } else if (!error.response.data.error.success) {
        dispatch({
          type: "UPDATE_SNACKBAR_DATA",
          payload: {
            success: false,
            error: true,
            showMsg: true,
            message: error.response.data.error.message,
          },
        });
      }
    }
  };

  return (
    <>
      {showLoader && <Loader />}
      <CustomizedSnackbars />

      <Card className={classes.carddefault}>
        <div className={classes.detailbox}>
          <CardHeader
            classes={{ title: classes.titletext }}
            avatar={
              <Avatar aria-label="recipe" className={classes.filebluebg}>
                <FileWhite className={classes.opacity1}></FileWhite>
              </Avatar>
            }
            action={
              <IconButton aria-label="download">
                <a href={singleContent.signed_url} download>
                  <DownloadFileicon />
                </a>
              </IconButton>
            }
            title={singleContent.s3_key.split("/")[1].substring(0, 20)}
            subheader={
              <p className={classes.nomargin}>
                <span className={classes.subheader}>
                  Upload Details:{convertDate(singleContent.updated_at)}
                </span>
                <br></br>
                <span className={classes.subheader}>By </span>
                <span className={classes.subnametext}>
                  {singleContent.created_by_name}
                </span>
              </p>
            }
          />
          <PermissionsGate
            scopes={[scopes.can_update_status_uploaded_data_file]}
          >
            <div className={classes.buttonContainer}>
              <Button
                onClick={() => submitStatus("approved")}
                className={classes.button}
              >
                <span className={`${classes.text} ${classes.buttonfilled}`}>
                  Accept
                </span>
              </Button>
              <Button className={classes.button} onClick={handleExpandClick}>
                <span className={`${classes.text} ${classes.buttonlined}`}>
                  Reject
                </span>
              </Button>
            </div>
          </PermissionsGate>
        </div>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <TextField
              style={{ width: "100%" }}
              id="outlined-multiline-static"
              multiline
              minRows={4}
              placeholder="Comment Box!"
              onChange={(e) => handleInputChange(e.target.value)}
              variant="outlined"
            />

            <Button
              onClick={() => submitStatus("rejected")}
              className={classes.postParentbutton}
            >
              <span className={`${classes.text} ${classes.postbtn}`}>Post</span>
            </Button>
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
}

export default PendingCard;
