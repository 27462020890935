import React from "react";
import { useStyles } from "./CardStyles";
import { Card, CardContent } from "@material-ui/core";
export const NestedCard = ({ style, className, children }) => {
  const classes = useStyles();
  return (
    <Card className={`${classes.nestedCard} ${className}`} style={style}>
      <CardContent className={classes.thirdCardNestedCardContent}>
        {children}
      </CardContent>
    </Card>
  );
};
