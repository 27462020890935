import React from "react";
import StrategyBuilderStep3 from "../uniqueCLientIndex/StrategyBuilderStep3";
import TargetPortfolioTable from "./TargetPortfolioTable";
import { useState } from "react";
import { useEffect } from "react";
import { step3tableHead } from "../constand";
import TargetPortfolioStep3Wrapper from "./TargetPortfolioStep3Wrapper";
import { StrategyBuilderSelection } from "../StrategyBuilderSelection";

function TargetPortfolioTableFlow() {
  const [step, setStep] = useState(1);
  const [memberNames, setMemberNames] = useState(null);
  const [rowData, setRowData] = useState(() => {});

  const handleBack = () => {
    if (step > 0) setStep((prev) => prev - 1);
  };

  const handleNext = (members) => {
    setStep((prev) => prev + 1);
    setMemberNames(members);
  };

  const changeInStep = (step) => {
    switch (step) {
      case 1:
        return (
          <TargetPortfolioTable
            handleNext={handleNext}
            setRowData={setRowData}
          />
        );
      case 2:
        return (
          <StrategyBuilderSelection
            handleBack={handleBack}
            handleNext={handleNext}
            rowData={rowData}
          />
        );
      case 3:
        return (
          <TargetPortfolioStep3Wrapper
            handleBack={handleBack}
            memberNames={memberNames}
            handleNext={handleNext}
            rowData={rowData}
          />
        );
      default: {
        setStep(1);
        return <TargetPortfolioTable handleNext={handleNext} />;
      }
    }
  };

  

  return <>{changeInStep(step)}</>;
}

export default TargetPortfolioTableFlow;
