import { useState, useEffect, useContext } from "react";
import {
  fetchAssetDetailView,
  assetDetailsTwrr,
  fetchAssetDetailsViewXIRR,
  benchMarkXIRR,
} from "../api";
import { OnboardingContext } from "../Context";
import { getRealisedData, fetchXirr, fetchTwrr } from "../api";
import { mfoFilterContext } from "../mfo_context/Mfo_Context";
import moment from "moment";

/**
 * Custom hook to fetch the asset detail view data.
 *
 * @returns {Array} - An array containing the rows of the asset detail view table.
 */
export const useAssetDetailView = (
  setLoading,
  resetTabel,
  setLoaderConfigAssetClass,
  setTotalRowLoading
) => {
  const { userState } = OnboardingContext();
  const [tablerRows, setTablerRows] = useState([]);
  const [grandTotal, setGrandTotal] = useState();
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  let clientId = userState.loginedUserData.id;
  // let clientId = 199;

  const getAssetWiseTwrr = async () => {
    let params = { ...getfilter_as_params() };
    params["assetClass"] = "equity,debt,cash,alternate";
    setLoaderConfigAssetClass(true);
    try {
      let res = await assetDetailsTwrr({ clientId: clientId, ...params });
      setTablerRows((prev) => {
        // console.log(res?.data?.data?.result?.data, prev, "agdj");
        let newList = [];
        prev?.map((val) => {
          val.absoluteTwrr = res?.data?.data?.result?.data?.filter(
            (val2) => val2?.assetClass == val?.assetClass
          )[0]?.absoluteTwrr;
          val.annualisedTwrr = res?.data?.data?.result?.data?.filter(
            (val2) => val2?.assetClass == val?.assetClass
          )[0]?.annualisedTwrr;
          newList.push(val);
        });
        return newList;
      });
      setLoaderConfigAssetClass(false);

      // throw "error";
      // return;
    } catch (error) {
      if (error) {
        setTablerRows((prev) => {
          let newList = [];
          prev?.map((val) => {
            val.absoluteTwrr = "Error";
            val.annualisedTwrr = "Error";
            newList.push(val);
          });
          return newList;
        });
        // return 0;
      }
      setLoaderConfigAssetClass(false);
    }
  };

  const getTotalRow = async () => {
    let params = { ...getfilter_as_params() };
    params["assetClass"] = "equity,debt,cash,alternate";
    try {
      setTotalRowLoading(true);
      let res = await fetchTwrr(clientId, params, "dashboard");
      console.log(res, "aglnkj");
      //  .then((res) => {
      // console.log(res, "agkjbjh");
      // if (res.status == "fulfilled") {
      setGrandTotal((prev) => ({ ...prev, ...res?.result }));
      // }
      //   return 0;
      // });
      setTotalRowLoading(false);

      // throw "error";
    } catch (error) {
      setGrandTotal((prev) => ({
        ...prev,
        absoluteTwrr: "Error",
        annualisedTwrr: "Error",
      }));
      setTotalRowLoading(false);
    }
  };

  useEffect(() => {
    let params = { ...getfilter_as_params() };
    params["assetClass"] = "equity,debt,cash,alternate";

    setLoading(true);
    Promise.allSettled([
      benchMarkXIRR(clientId, params, "past"),

      getRealisedData(clientId, params, "dashboard"),
      fetchAssetDetailView(clientId, params),
      fetchAssetDetailsViewXIRR(clientId, params, "past"),
    ]).then(([xirr_res, realiseddata_res, asset_ress, benchmark_xirr]) => {
      if (xirr_res.status == "fulfilled") {
        setGrandTotal((prev) => ({
          ...prev,
          xirrValue: xirr_res?.value?.data?.data?.result?.portfolioXirr,
          benchmarkXirr: xirr_res?.value?.data?.data?.result?.benchmarkXirr,
        }));
      }

      if (realiseddata_res.status == "fulfilled") {
        setGrandTotal((prev) => ({
          ...prev,
          ...realiseddata_res?.value?.result,
        }));
      }

      if (asset_ress.status == "fulfilled") {
        setGrandTotal((prev) => ({
          ...prev,
          ...realiseddata_res?.value?.result,
          absoluteTwrr: "loading",
          annualisedTwrr: "loading",
        }));
        let equityDebtData = [];
        const assetClassEquity = asset_ress.value?.data?.data?.result?.data[0];
        const assetClassDebt = asset_ress.value?.data?.data?.result?.data[1];
        equityDebtData.push(assetClassEquity);
        equityDebtData.push(assetClassDebt);

        asset_ress.value?.data?.data?.result?.data?.forEach((val) => {
          val.absoluteTwrr = "loading";
          val.annualisedTwrr = "loading";
          val.benchmarkXirr = benchmark_xirr?.value?.data?.data?.result?.filter(
            (val2) => val2?.assetClass == val?.assetClass
          )[0]?.benchmarkXirr;
          val.benchmarkName = benchmark_xirr?.value?.data?.data?.result?.filter(
            (val2) => val2?.assetClass == val?.assetClass
          )[0]?.benchmarkName;
        });

        setTablerRows(() => asset_ress.value?.data?.data?.result?.data);
      }

      setLoading(false);
      getTotalRow();
      getAssetWiseTwrr();
    });

    // fetchXirr(clientId, params, "dashboard")
    //   .then((res) => {
    //     setGrandTotal((prev) => ({ ...prev, ...res.result }));
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //   });
    // fetchTwrr(clientId, params, "dashboard")
    //   .then((res) => {
    //     setGrandTotal((prev) => ({ ...prev, ...res.result }));
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //   });

    // getRealisedData(clientId, params, "dashboard").then((res) => {
    //   console.log(res?.result, "sfbheuoe7");
    //   setGrandTotal((prev) => ({ ...prev, ...res.result }));
    // });

    // /* Asset Detail View API CALL*/
    // setLoading(true);
    // fetchAssetDetailView(clientId, params).then((response) => {

    //   let equityDebtData = [];
    //   const assetClassEquity = response?.data?.data?.result?.data[0];
    //   const assetClassDebt = response?.data?.data?.result?.data[1];
    //   equityDebtData.push(assetClassEquity);
    //   equityDebtData.push(assetClassDebt);
    //   setTablerRows(response?.data?.data?.result?.data);
    //   setLoading(false);
    // }).catch((err)=>{
    //   setLoading(false);
    // });
  }, [mfoFilter, resetTabel]);

  return [tablerRows, grandTotal, getAssetWiseTwrr, getTotalRow];
};
