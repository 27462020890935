import React from "react";

import "../../../NewSignUp/PartnerOnboarding.css";
import "../../../NewSignUp/NewSignUp.css";
import { Box, Grid, MenuItem } from "@material-ui/core";

import {
  ClientMemberRoles,
  Client_Member_Entity_Types,
  Client_User_Roles,
} from "../../../../Constants";
import { useForm } from "../../../NewSignUp/hooks/useForm";
import CustomInput from "../../../NewSignUp/common/CustomInput";
import StepProgressBar from "../../../NewSignUp/common/StepProgressBar/StepProgressBar";
import ErrorMessageComp from "../../../NewSignUp/common/ErrorMessageComp";
import LoadingButton from "../../../NewSignUp/common/LoadingButton";
import PartnerHeading from "../../../NewSignUp/PartnerHeading";
import { client_add_member_validation } from "../../../NewSignUp/ValidationSchema";
import { handleInitValue, handleRoleId } from "../../utils/cmmon";

function AddClientMemberForm1({
  handleNext,
  handleBack,
  currentStep,
  customHandleSubmit,
  head,
  onlyPerosn,
  apiErrorMessage,
  loading,
  formData,
  totalStep,
  noStepBottom,
  formMaxHeight,
  noStep,
  formWidth,
}) {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useForm(
    {
      full_name: formData?.fullName ? formData?.fullName : "",
      member_type:
        formData?.member_type !== undefined ? formData?.member_type : "",
      roleId: formData?.roleId
        ? handleRoleId(Number(formData?.roleId), ClientMemberRoles)
        : "",
      pan: formData?.pan ? formData?.pan : "",
      //   dateOfBirth: formData?.dateOfBirth ? formData?.dateOfBirth : "",
      //   formData?.entityType == "sole_proprietor"
      //   ? formData?.dateOfBirth
      //   : formData?.doi
      //   ? formData?.doi
      //   : "",
      email: formData?.email ? formData?.email : "",
      mobile: formData?.mobileNo ? formData?.mobileNo : "",
    },
    // test,
    (values) => {
      console.log(values, "aflkbabfbuawubfw");
      customHandleSubmit(values);
    },
    client_add_member_validation
  );

  return (
    <div
      className="dFlex jtfyContCntr alignCntr h100 flexDirCol"
      style={{ gap: "25px", width: formWidth }}
    >
      <div className="newFormTopSec">
        <PartnerHeading
          mainHead={head?.main ? head?.main : "Add Member"}
          subtitle={
            head?.sub ? head?.sub : "Join us by providing your company details"
          }
          handleBack={handleBack}
          step={currentStep}
          noStep={noStep}
        />
      </div>
      <div className="newFormContainer" style={{ maxHeight: formMaxHeight }}>
        <Box
          className="dFlex jtfyContCntr alignCntr flexDirCol"
          sx={{ width: { xs: "auto", sm: "330px" }, gap: "41px" }}
        >
          {console.log(errors)}
          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid xs={12} lg={12} item>
                <CustomInput
                  title="user role"
                  //   disabled={true}
                  // as={TextField}
                  select
                  variant="outlined"
                  margin="normal"
                  // required
                  fullWidth
                  id="roleId"
                  label="User Role"
                  name="roleId"
                  autoComplete="roleId"
                  autoFocus
                  error={touched.roleId && Boolean(errors.roleId)}
                  helperText={touched.roleId && errors.roleId}
                  value={values.roleId}
                  onChange={handleChange}
                >
                  {ClientMemberRoles.map((entity) => {
                    return (
                      <MenuItem
                        key={entity.value}
                        value={entity.value}
                        selected={entity?.selected}
                        color="white"
                        style={{
                          border: "0.34px solid #3E5389",
                          backgroundColor: "#131826",
                        }}
                      >
                        {entity.name}
                      </MenuItem>
                    );
                  })}
                </CustomInput>
              </Grid>
              <Grid xs={12} lg={12} item>
                <CustomInput
                  title="Entity Type"
                  //   disabled={true}
                  // as={TextField}
                  select
                  variant="outlined"
                  margin="normal"
                  // required
                  fullWidth
                  id="member_type"
                  label="Entity Type"
                  name="member_type"
                  autoComplete="member_type"
                  //   autoFocus
                  error={touched.member_type && Boolean(errors.member_type)}
                  helperText={touched.member_type && errors.member_type}
                  value={values.member_type}
                  onChange={handleChange}
                >
                  {Client_Member_Entity_Types.map((entity) => {
                    return (
                      <MenuItem
                        key={entity.value}
                        value={entity.value}
                        selected={entity?.selected}
                        color="white"
                        style={{
                          border: "0.34px solid #3E5389",
                          backgroundColor: "#131826",
                        }}
                      >
                        {entity.name}
                      </MenuItem>
                    );
                  })}
                </CustomInput>
              </Grid>

              <Grid xs={12} lg={12} item>
                <CustomInput
                  title="Name"
                  // as={TextField}
                  variant="outlined"
                  margin="normal"
                  // required
                  fullWidth
                  id="full_name"
                  label="Full Name"
                  name="full_name"
                  autoComplete="full_name"
                  error={touched.full_name && Boolean(errors.full_name)}
                  helperText={touched.full_name && errors.full_name}
                  value={values.full_name}
                  onChange={handleChange}
                />
              </Grid>

              <Grid
                xs={12}
                lg={12}
                item
                // pr={{ xs: 0, sm: 0, md: 0, lg: 1 }}
              >
                <CustomInput
                  title="Mobile no."
                  // as={TextField}
                  variant="outlined"
                  margin="normal"
                  // required
                  fullWidth
                  id="mobile"
                  label="Mobile Number"
                  name="mobile"
                  error={touched.mobile && Boolean(errors.mobile)}
                  helperText={touched.mobile && errors.mobile}
                  value={values.mobile}
                  onChange={handleChange}
                  // maxLength={10}
                />
              </Grid>
              <Grid xs={12} lg={12} item>
                <CustomInput
                  // as={TextField}
                  title="EMAIL ID"
                  variant="outlined"
                  margin="normal"
                  // required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  //   autoFocus
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  value={values.email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid
                xs={12}
                lg={12}
                item
                // style={{ paddingLeft: { xs: 0, sm: 0, md: 0, lg: 1 } }}
              >
                <CustomInput
                  title="PAN NO"
                  // as={TextField}
                  variant="outlined"
                  margin="normal"
                  // required
                  fullWidth
                  id="pan"
                  label="Pan Number"
                  name="pan"
                  error={touched.pan && Boolean(errors.pan)}
                  helperText={touched.pan && errors.pan}
                  value={values.pan}
                  onChange={handleChange}
                />
              </Grid>
              {/* <Grid
                xs={12}
                lg={12}
                item
                // style={{ paddingLeft: { xs: 0, sm: 0, md: 0, lg: 1 } }}
              >
                <CustomInput
                  title="Date Of Birth"
                  // as={TextField}
                  variant="outlined"
                  margin="normal"
                  // required
                  fullWidth
                  id="dob"
                  label="Date Of Birth"
                  name="dob"
                  error={touched.dob && Boolean(errors.dob)}
                  helperText={touched.dob && errors.dob}
                  value={values.dob}
                  onChange={handleChange}
                />
              </Grid>
              <Grid
                xs={12}
                lg={12}
                item
                // style={{ paddingLeft: { xs: 0, sm: 0, md: 0, lg: 1 } }}
              >
                <CustomInput
                  title="Address"
                  // as={TextField}
                  variant="outlined"
                  margin="normal"
                  // required
                  fullWidth
                  id="address"
                  label="Address"
                  name="address"
                  error={touched.address && Boolean(errors.address)}
                  helperText={touched.address && errors.address}
                  value={values.address}
                  onChange={handleChange}
                />
              </Grid> */}
              {/* <Grid xs={12} lg={12} item>
                <CustomInput
                  // as={TextField}
                  select
                  fullWidth
                  title="Status"
                  variant="outlined"
                  margin="normal"
                  // required
                  id="status"
                  label="Status"
                  name="status"
                  autoComplete="status"
                  // autoFocus
                  // type="select"
                  error={touched.status && Boolean(errors.status)}
                  helperText={touched.status && errors.status}
                  value={values.status}
                  onChange={handleChange}
                >
                  {adduserStatus.map((entity) => {
                    return (
                      <MenuItem
                        key={entity.value}
                        value={entity.value}
                        selected={entity?.selected}
                        style={{
                          border: "0.34px solid #3E5389",
                          backgroundColor: "#131826",
                        }}
                      >
                        {entity.name}
                      </MenuItem>
                    );
                  })}
                </CustomInput>
              </Grid> */}

              <div className="newFormBottonSec">
                <Grid xs={12} lg={12} style={{ marginTop: "30px" }} item>
                  <ErrorMessageComp errorMessage={apiErrorMessage} />
                  <LoadingButton
                    buttonTitle={"Save"}
                    type="submit"
                    showLoader={loading}
                  />
                </Grid>
                {noStepBottom ? (
                  ""
                ) : (
                  <Grid xs={12} item style={{ marginTop: "28px" }}>
                    <StepProgressBar
                      total={totalStep}
                      currentStep={currentStep}
                    />
                  </Grid>
                )}
              </div>
            </Grid>
          </form>
        </Box>
      </div>
    </div>
  );
}

export default AddClientMemberForm1;
