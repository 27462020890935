import React from "react";
import { ReactComponent as Marketuparrow } from "../../../../../assets/clientdashboard/marketuparrow.svg";
import { ReactComponent as Marketdownarrow } from "../../../../../assets/clientdashboard/marketdownarrow.svg";
import { Typography } from "@material-ui/core";

function ValueWithArrow({ align, precentage, showArrow, value }) {
  return (
    <div className="cellContainer">
      <div className={"arrowCellContainer"}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className="arrowCellLeft">
            <Typography
              className={
                showArrow
                  ? precentage >= 0 && showArrow
                    ? "up-green"
                    : "down-red"
                  : ""
              }
            >
              {value}
            </Typography>
          </div>
        </div>
        {precentage && <span className="subHeadDivider"></span>}
        <div className="arrowCellCenter">
          {showArrow ? (
            precentage >= 0 ? (
              <Marketuparrow
                width={"15px"}
                height={"15px"}
                // className="mrright5"
              ></Marketuparrow>
            ) : (
              <Marketdownarrow
                width={"15px"}
                height={"15px"}
                // className="mrright5"
              ></Marketdownarrow>
            )
          ) : (
            ""
          )}
        </div>
        {/* <div
          className="arrowCellRight"
          // style={{
          //   width: "30%",
          // }}
        >
          <Typography
            className={
              showArrow ? (precentage >= 0 ? "up-green" : "down-red") : ""
            }
          >
            {precentage}
          </Typography>
        </div> */}
      </div>
    </div>
  );
}

export default ValueWithArrow;
