import React from 'react'
import ClientMember from '../components/dashboard/clientDashboard/ClientMember'
import Default from '../components/dashboard/defaultPages/Default'

function ClientMemberDetails() {
  return (
    <Default>
         <ClientMember></ClientMember>
    </Default>
   
  )
}

export default ClientMemberDetails