import React, { useContext, useEffect } from "react";
import SummaryPageTop from "../common/SummaryPageTop";
import { NewFilter } from "../../newFilter/NewFilter";
import PortfolioChart from "../../common/portfolioChart";
import { Box, Button, Grid, useTheme } from "@material-ui/core";
import BarChartIcon from "@material-ui/icons/BarChart";
import PageLoader from "../common/pageLoader";
import { useState } from "react";
import useStyles from "../common/ProductsStyles";
import {
  fetchTwrr,
  fetchTwrrCurrentHolding,
  fetchXirr,
  getRealisedData,
  invested_portfolio,
} from "../../../../api";
import { useNavigate } from "react-router-dom";
import { OnboardingContext } from "../../../../Context";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import { useNormalReturns } from "../../../../hooks/useNormalReturns";
import { useReturns } from "../../../../hooks/useReturns";
import { divergingCommonProps } from "../../../charts/nivo_objects";
import { routes } from "../../../../routes";
import { BankBookCard } from "../../../common/HoldingSummaryCards/BankBookCard";
import { useBankSummary } from "../../../../hooks/useBankSummary";
import { colorAndProdBank } from "../utils/constands/prodAndColor";
import { NewHoldingSummaryCards } from "../../../common/HoldingSummaryCards/NewHoldingSummaryCards";
import { useCardsApi } from "../../../../hooks/useCardsApi";
import { holdingSummaryCardsInactiveData } from "../common/util/common";
import { MainCard } from "../../../common/HoldingSummaryCards/MainCard";
import { NestedCard } from "../../../common/HoldingSummaryCards/NestedCard";
import { useStyles as useStylesRdCards } from "../../../common/HoldingSummaryCards/CardStyles";
import { useCurrentHoldingCardsApi } from "../../../../hooks/useCurrentHoldingCardsApi";
import { loadingText } from "../../../utils/constands";
import { currencyFormat } from "../../../../hooks/constantFunction";
import { roundNumberWithoutDecimal } from "../../../utils/common";
import { NewHoldingSummaryCardsActive } from "../../../common/HoldingSummaryCards/NewHoldingSummaryCardsActive";
import { GainsCardInfo } from "../../../common/HoldingSummaryCards/GainsCardInfo";
import { TotalGainLossCardInfo } from "../../../common/HoldingSummaryCards/TotalGainLossCardInfo";
function RecurrringDepositSummary() {
  const classes = useStyles();
  const theme = useTheme();
  const rdCardClass = useStylesRdCards();
  const [checked, setChecked] = useState(false);
  const [infoIconHovered, setInfoIconHovered] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [pie, setPie] = useState({
    title: "",
    value: "",
    colors: Object.values(colorAndProdBank),
    disabled: [],
    fill: [
      {
        match: {
          id: "Commerical",
        },
        id: "c9",
      },
      {
        match: {
          id: "Residential",
        },
        id: "c10",
      },
    ],
  });
  const {
    holdingCardData,
    totalGainLossData,
    xirr,
    twrr,
    loading: cardLoader,
    twrrLoader,
    twrrError,
    retryFetchTwrr,
  } = useCardsApi("rd");
  const {
    // holdingCardData,
    xirrCurrentHolding,
    twrrCurrentHolding,
    loading: currentHoldingCardLoader,
    twrrLoaderCurrentHolding,
    twrrErrorCurrentHolding,
    retryFetchTwrrCurrentHolding,
  } = useCurrentHoldingCardsApi(checked, "rd");
  // const { bankSummary, summaryDetailData, color } = useBankSummary({ setPie });

  // const { returns: twrrCurrentHolding, loading: twrrCurrentHoldingLoading } =
  //   useReturns(fetchTwrrCurrentHolding, "twrr", checked, "invits");
  // const { returns: twrr } = useNormalReturns(
  //   fetchTwrr,
  //   "normal-twrr",
  //   checked,
  //   "invits"
  // );
  // const { returns: xirr } = useNormalReturns(
  //   fetchXirr,
  //   "normal-xirr",
  //   checked,
  //   "rd"
  // );
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  let selectedAssetClass = getfilter_as_params().assetClass;
  console.log("getfilter_as_params", mfoFilter);
  const { userState } = OnboardingContext();
  const [realisedData, setRealisedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [flip, setFlip] = useState(false);

  //   const [asset_color, setAsset_Color] = useState(Object.values(colorAndProd));
  const diverging = {
    ...divergingCommonProps,
  };
  diverging.axisBottom.legendOffset = 65;
  diverging.margin = { top: 5, right: 20, bottom: 70, left: 80 };
  useEffect(() => {
    const clientId = userState.loginedUserData.id;
    // const clientId = 199;

    let params = { ...getfilter_as_params() };

    setLoading(true);
    Promise.allSettled([getRealisedData(clientId, params, "rd")]).then(
      ([realiseddata_res]) => {
        if (realiseddata_res.status == "fulfilled") {
          let arr = [];
          setRealisedData(realiseddata_res.value.result);
        }
        setLoading(false);
      }
    );
  }, [mfoFilter]);

  const navigate = useNavigate();
  const handleSummaryClick = () => {
    navigate(routes?.recurringDepositAllocation);
  };
  console.log("reaslised data rd", realisedData);

  function holdingSummaryCardsInactiveData() {
    return {
      asOnDate:
        realisedData?.asOnDate != null
          ? holdingCardData?.asOnDate
          : loadingText,
      // Current Portfolio Value
      portfolioValueAmount:
        holdingCardData?.currentPortfolioAmount != null
          ? currencyFormat(holdingCardData?.currentPortfolioAmount)
          : loadingText,
      // Total cost of investments
      overallInvestedAmount:
        realisedData?.totalInvestmentAmount != null
          ? currencyFormat(realisedData?.totalInvestmentAmount)
          : loadingText,
      // Cost of Current Holding
      investedAmount:
        realisedData?.totalCurrentHoldingAmount != null
          ? currencyFormat(realisedData?.totalCurrentHoldingAmount)
          : loadingText,
      // Cost of Redeemed Investments
      redeemedInvestedAmount:
        realisedData?.redeemedInvestments != null
          ? currencyFormat(realisedData?.redeemedInvestments)
          : loadingText,
      // Total Gain/Loss
      totalGainLoss:
        realisedData?.totalGainLoss != null
          ? currencyFormat(realisedData?.totalGainLoss)
          : loadingText,
      // Realised Gain/Loss
      realisedGainLoss:
        realisedData?.realisedGainLoss != null
          ? currencyFormat(realisedData?.realisedGainLoss)
          : loadingText,
      // Unrealised Gain/Loss
      unRealisedGainLoss:
        realisedData?.totalUnrealizedGainLossAmount != null
          ? currencyFormat(realisedData?.totalUnrealizedGainLossAmount)
          : loadingText,
      // xirr
      xirrValue:
        xirr?.xirr != null
          ? roundNumberWithoutDecimal(Number(xirr?.xirr)) + " %"
          : loadingText,
      // Absolute TWRR
      absoluteTwrrData:
        twrr?.absoluteTwrr != null
          ? roundNumberWithoutDecimal(Number(twrr?.absoluteTwrr)) + " %"
          : loadingText,
      // Annualised TWRR
      annualisedTwrrData:
        twrr?.annualisedTwrr != null
          ? roundNumberWithoutDecimal(Number(twrr?.annualisedTwrr)) + " %"
          : loadingText,
      // Total Interest Income
      interestIncome:
        realisedData?.totalInterestIncome != null
          ? currencyFormat(realisedData?.totalInterestIncome)
          : loadingText,
    };
  }

  function holdingSummaryCardsActiveData() {
    return {
      asOnDate:
        holdingCardData?.asOnDate !== null
          ? holdingCardData?.asOnDate
          : loadingText,
      portfolioValueAmount:
        holdingCardData?.currentPortfolioAmount !== null
          ? currencyFormat(holdingCardData?.currentPortfolioAmount)
          : loadingText,
      investedAmount:
        holdingCardData?.totalInvestmentAmount !== null
          ? currencyFormat(holdingCardData?.totalInvestmentAmount)
          : loadingText,
      totalGainLoss:
        holdingCardData?.totalGainLoss !== null
          ? currencyFormat(holdingCardData?.totalGainLoss)
          : loadingText,
      realisedGainLoss:
        holdingCardData?.realisedGainLoss !== null
          ? currencyFormat(0)
          : loadingText,
      unRealisedGainLoss:
        holdingCardData?.totalUnrealizedGainLossAmount !== null
          ? currencyFormat(holdingCardData?.totalUnrealizedGainLossAmount)
          : loadingText,
      xirrValue:
        xirrCurrentHolding?.xirr !== null
          ? roundNumberWithoutDecimal(Number(xirrCurrentHolding?.xirr)) + " %"
          : loadingText,
      absoluteTwrrData:
        twrrCurrentHolding?.absoluteTwrr !== null
          ? roundNumberWithoutDecimal(
              Number(twrrCurrentHolding?.absoluteTwrr)
            ) + " %"
          : loadingText,
      annualisedTwrrData:
        twrrCurrentHolding?.annualisedTwrr !== null
          ? roundNumberWithoutDecimal(
              Number(twrrCurrentHolding?.annualisedTwrr)
            ) + " %"
          : loadingText,
      interestIncome:
        holdingCardData?.totalInterestIncome != null
          ? currencyFormat(holdingCardData?.totalInterestIncome)
          : loadingText,
    };
  }
  console.table(realisedData);
  console.table(holdingCardData);

  return (
    <>
      <SummaryPageTop
        backLink={routes?.homeHoldingPage}
        pageTitle={"Recurring Deposit Portfolio Summary"}
        flip={flip}
        setFlip={setFlip}
        checked={checked}
        setChecked={setChecked}
        setFilterModalOpen={setFilterModalOpen}
        // hideRightSec={true}
      />
      <NewFilter
        filterModalOpen={filterModalOpen}
        setFilterModalOpen={setFilterModalOpen}
        productType="rd"
      />
      <div style={{ margin: "2%" }}>
        {!checked ? (
          <NewHoldingSummaryCards
            retryFetchTwrr={retryFetchTwrr}
            twrrLoader={twrrLoader}
            twrrError={twrrError}
            // infoIconHovered={infoIconHovered}
            // Check and Render the div from summary Component
            holdingSummaryCardsInactive={holdingSummaryCardsInactiveData()}
            totalGainLossInactive={
              <>
                {/* <GainsCardInfo
                  text="Realized Gain/Loss"
                  value={holdingSummaryCardsInactiveData()?.realisedGainLoss}
                  align="left"
                /> */}
                <GainsCardInfo
                  text="Unrealized Gain/Loss"
                  value={holdingSummaryCardsInactiveData()?.unRealisedGainLoss}
                  align="left"
                />
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <GainsCardInfo
                    text="Total Interest Income"
                    value={holdingSummaryCardsInactiveData()?.interestIncome}
                    align="right"
                    // customStyle={{ marginRight: "2rem" }}
                  />
                </div>
              </>
            }
          ></NewHoldingSummaryCards>
        ) : (
          <NewHoldingSummaryCardsActive
            retryFetchTwrrCurrentHolding={retryFetchTwrrCurrentHolding}
            twrrLoaderCurrentHolding={twrrLoaderCurrentHolding}
            twrrErrorCurrentHolding={twrrErrorCurrentHolding}
            infoIconHovered={infoIconHovered}
            holdingSummaryCardsActive={holdingSummaryCardsActiveData()}
            totalGainLossActive={
              <>
                <GainsCardInfo
                  text="Unrealized Gain/Loss"
                  value={holdingSummaryCardsActiveData()?.unRealisedGainLoss}
                  align="left"
                />
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <GainsCardInfo
                    text="Total Interest Income"
                    value={holdingSummaryCardsActiveData()?.interestIncome}
                    align="right"
                    // customStyle={{ marginRight: "2rem" }}
                  />
                </div>
              </>
            }
          ></NewHoldingSummaryCardsActive>
        )}
      </div>

      <PortfolioChart
        // type={"bank"}
        // exp={"balance-over-a-period"}
        config={{ colors: ["#509FF1"] }}
        loading={loading}
        type={"rd"}
        noPaddingBottom={true}
        viewDetailBtn={
          <Button
            onClick={handleSummaryClick}
            className={classes.viewDetailBtn + " portfolioBtn "}
            style={{
              width: "20%",
              margin: "12% 40% 0",
              color: "#FFFFFF",
              borderRadius: "7px",
              backgroundColor: "#4f1fda !important",
            }}
            startIcon={<BarChartIcon />}
          >
            View Details
          </Button>
        }
      ></PortfolioChart>
      <PageLoader
        showLoader={
          loading || (currentHoldingCardLoader && checked) ? true : false
        }
      />
    </>
  );
}

export default RecurrringDepositSummary;
