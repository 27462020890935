import React, { useState, useContext, useEffect } from "react";
import { fetchDrawdown, getTransactionsSummary } from "../../../../api";
import { OnboardingContext } from "../../../../Context";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import PmsReportTable from "../pms/PmsReportTable";
import { aifDrawdownTable } from "./AifTable";
import { objectFormating } from "./objectFormating";
import { useClientSearch } from "../../../../hooks/useClientSearch";

function AifDrawdownSummary({ p, setOpenModal, search, openModal }) {
  const [tableColumns, setTableColumns] = useState([]);
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const { userState } = OnboardingContext();
  const [loading, setLoading] = useState(false);
  const [reset, setReset] = useState();
  const [tableHeader, setTableHeader] = useState(aifDrawdownTable);
  const [checkedTrans, setCheckedTrans] = useState([]);
  const { searchResults } = useClientSearch(search, tableColumns);

  // console.log(aifDrawdownTable, "aglkn");

  const getTableData = async () => {
    setLoading(true);
    let tableName = "summary/holding";

    let params = {
      //   clientId: userState?.loginedUserData?.id,
      // clientId: 199,
      search: search,
      ...getfilter_as_params(),
      assetClass: p || "equity",
    };

    try {
      let response = await fetchDrawdown(
        userState?.loginedUserData?.id,
        params
      );
      let newlist = [];
      let transactionList = [];
      response?.data?.data?.result?.data?.map((val) => {
        // if (pageName === "holding") {
        let obj = objectFormating(val, tableHeader, " Days");
        newlist.push(obj);
        // }
      });
      setCheckedTrans(() => transactionList);
      setTableColumns(() => newlist);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getTableData();
  }, [p, mfoFilter, reset, search]);

  return (
    <div style={{ position: "relative" }}>
      <PmsReportTable
        setTableColumns={setTableColumns}
        // tableColumns={searchResults || tableColumns}
        tableColumns={tableColumns}
        loading={loading}
        setOpenModal={setOpenModal}
        openModal={openModal}
        headerList={aifDrawdownTable}
        tableHeader={tableHeader}
        setTableHeader={setTableHeader}
        stickyProductName={true}
        checkedTransList={checkedTrans}
        setReset={setReset}
      />
    </div>
  );
}

export default AifDrawdownSummary;
