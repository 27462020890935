import React from "react";
import { useState } from "react";
import {
  step3tableHead,
  step3tableHeadUniqueIndex,
  step3tableStaticData,
  step3tableStaticDataUniqueIndex,
} from "../constand";
import { useEffect } from "react";
import StrategyBuilderStep3 from "../uniqueCLientIndex/StrategyBuilderStep3";
import {
  handleOnLoadData,
  handlePayloadCreateStrategy,
  objectFomatingStep3table,
} from "../common/utils/utils";
import PopoverComp from "../common/PopoverComp";
import { useDebounce } from "use-debounce";
import EditBtn from "../common/EditBtn";
import useGetStrategyTable from "../hooks/useGetStrategyTable";
import { SelectedMemberList } from "../SelectedMemberList";
import { checkBoxSvgIcon } from "../constand";
import PageLoader from "../../products/common/pageLoader";
import { OnboardingContext } from "../../../../Context";
import moment from "moment";
function TargetPortfolioStep3Wrapper({
  handleBack,
  memberNames,
  handleNext,
  rowData,
  type,
}) {
  const [rawtableData, setRawTableData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [anchorEl, setAnchorEl1] = useState(null);
  const [position, setPosition] = useState(0);
  const [debouncedValue] = useDebounce(rawtableData, 500);
  const [benchmarkList, step3BenchmarkList] = useState({});
  const {
    getIndexExpectedReturn,
    creatStrategy,
    loading,
    getIndexList,
    updateStrategy,
    getAllocationData,
  } = useGetStrategyTable();
  const [portFolioName, setProtFolioName] = useState("");
  const [error, setError] = useState({});
  const { userState } = OnboardingContext();
  const [header, setHeader] = useState([]);
  const [currentBenchMarkList, setCurrentBenchMarkList] = useState([]);

  // Yesterday's Date
  let tMinusOneEndDate = moment().subtract(1, "days").format("YYYY-MM-DD");
  // Hadnler of table name
  const handlePortFolioName = (name) => {
    setProtFolioName(() => name);
  };

  // Hadnler of table input for allocation
  const handleInputChange = (e, index) => {
    setRawTableData((prev) => {
      let newList = [...prev];
      newList[index].allocationPercentage = String(e?.target?.value)?.replace(
        "%",
        ""
      );
      return newList;
    });
  };

  // To open drop down in table
  const handleClick = (e, data, position) => {
    setAnchorEl1(e.currentTarget);
    position = position;
    setPosition(() => {
      setCurrentBenchMarkList(() => {
        return benchmarkList[data?.assetClass];
      });
      return position;
    });
  };

  // Click config
  const columnClickConfig = {
    benchmarkIndex: handleClick,
    allocationPercentage: handleInputChange,
  };

  // Update and Crate API
  const handleCreate = async () => {
    try {
      let { error, payload } = handlePayloadCreateStrategy({
        allocationData: [...rawtableData],
        portFolioName: portFolioName,
        memberNames: memberNames,
      });

      if (Object.keys(error).length > 0) {
        setError(() => error);
        throw error;
      }

      let payLoad = {
        strategyType: memberNames[0]?.strategyType,
        // clientId: 267,
        clientId: userState?.loginedUserData?.id,
        type: type ? type : "TargetPortfolio",
        ...payload,
      };
      // console.log(payLoad, "afbkjgkjab");

      if (rowData) {
        payLoad.id = rowData?._id;
        // console.log(payLoad, "afbkjgkjab");
        await updateStrategy({ params: payLoad });
      } else {
        await creatStrategy({ params: { ...payLoad } });
      }

      setRawTableData(() => []);
      handleNext();

      // console.log(res, "clicked123");
    } catch (error) {
      console.log(error, "clicked123");
    }
  };

  // To close drop down in table
  const handleClose = () => {
    setAnchorEl1(null);
    setPosition(0);
  };

  // Handler of onCLick event of drop down in table
  async function handleClickItem(data) {
    let res;

    if (type !== "UniqueClientIndex") {
      res = await getIndexExpectedReturn({
        params: {
          indexId: data?.sectionId,
          endDate: tMinusOneEndDate,
        },
      });
    }

    setRawTableData((prev) => {
      let newList = [...prev];
      newList[position].benchmarkIndex = {
        name: data?.indexName,
        indexId: data?.sectionId,
      };
      if (type !== "UniqueClientIndex") {
        newList[position].expectedReturns = res[0]?.averageBr;
      }
      return newList;
    });
    handleClose();
  }

  // Object formating
  const handleData = (table, header) => {
    let newList = [];
    table.map((val, i) => {
      newList.push(objectFomatingStep3table(val, header, i, columnClickConfig));
    });
    setTableData(() => newList);
  };

  // API to Populate return index data on table data
  const returnIndex = async (data) => {
    await data?.allocationsBreakUp?.map((val, i) => {
      getIndexExpectedReturn({
        params: {
          indexId: val?.benchmarkIndex?.indexId,
          endDate: tMinusOneEndDate,
        },
      })
        .then(async (res) => {
          setRawTableData((prev) => {
            let index = prev.findIndex(
              (val2) => val2?.assetClass == val.assetClass
            );

            if (index !== -1) {
              prev[index].expectedReturns = res[0]?.averageBr;
            }

            return [...prev];
          });
        })
        .catch((error) => {});
    });
  };

  //to get index list of all asset class
  const getAllIndexListOfAssetClass = async () => {
    ["equity", "debt", "cash", "alternate"].map(async (assetClass) => {
      let params = {};
      if (assetClass === "equity") {
        params.assetClass = "equity";
      }
      if (assetClass === "debt") {
        params.assetClass = "debt";
      }
      if (assetClass === "cash") {
        params.assetClass = "debt";
        params.subAssetClass = "liquid";
      }
      await getIndexList({
        setter: step3BenchmarkList,
        params: params,
        assetClass: assetClass,
      });
    });
  };

  // All nessesory hanldling of data on load is handled here
  const handleOnLoadState = async () => {
    getAllIndexListOfAssetClass();

    if (type == "UniqueClientIndex") {
      setHeader(() => step3tableHeadUniqueIndex);
    } else {
      setHeader(() => step3tableHead);
    }

    // for create new case
    // function to handle get allocation data
    // with step3tableStaticDataUniqueIndex static state
    // this is nessesory to manage data of payload and
    // table expand functionality

    await getAllocationData({
      clientId: userState?.loginedUserData?.id,
      type: "dashboard",
      filters: {
        memberId: memberNames?.map((val) => val?.member_id).join(","),
        // Include endDate in filters only when the type is "UniqueClientIndex"
        ...(type === "UniqueClientIndex" && { endDate: tMinusOneEndDate }),
      },
    })
      .then((res) => {
        if (type == "UniqueClientIndex") {
          let newList = handleOnLoadData({
            allocationData: res,
            staticData: rowData?.allocationsBreakUp
              ? rowData?.allocationsBreakUp
              : step3tableStaticDataUniqueIndex,
            type: type,
          });

          // console.log(newList, "aljbagkabkh");

          setRawTableData((prev) => {
            return newList;
          });
        } else {
          let newList = handleOnLoadData({
            allocationData: res,
            staticData: rowData?.allocationsBreakUp
              ? rowData?.allocationsBreakUp
              : step3tableStaticData,
            type: type,
          });

          // console.log(newList, "aljbagkabkh");

          setRawTableData((prev) => {
            return newList;
          });
        }
      })
      .catch((error) => {
        console.log(error, "aljbagkabkh");
      });

    if (rowData) {
      setProtFolioName(() => rowData?.name);
      if (type !== "UniqueClientIndex") {
        returnIndex(rowData);
      }
    }
  };

  useEffect(() => {
    handleOnLoadState();
  }, []);

  useEffect(() => {
    if (header?.length > 0 && debouncedValue?.length > 0)
      handleData(debouncedValue, header);
  }, [debouncedValue]);

  return (
    <>
      {header?.length > 0 && (
        <StrategyBuilderStep3
          type={type}
          error={error}
          handlePortFolioName={handlePortFolioName}
          portFolioName={portFolioName}
          tableData={tableData}
          customheader={header}
          handleBack={handleBack}
          selectedMemberList={
            <SelectedMemberList
              selectedMembers={memberNames || []}
              svgIcon={checkBoxSvgIcon}
              customStyle={{
                selectedItem: { marginLeft: "0px" },
                wrapper: { gap: "20px" },
              }}
            />
          }
        />
      )}
      <div className="nextBtnStep3">
        <p className="calculationTxt">
          {type === "UniqueClientIndex"
            ? "Current Allocation as on T - 1*"
            : "Expected Returns Calculated as per T - 1*"}
        </p>
        <EditBtn
          title={`${rowData ? "Update" : "Create"} ${
            type === "UniqueClientIndex"
              ? "Unique Client Index"
              : "Target Portfolio"
          }`}
          customClass={"step3ContinueBtn"}
          hanldleClick={handleCreate}
        />
      </div>
      <PopoverComp
        anchorEl={anchorEl}
        handleClose={handleClose}
        data={currentBenchMarkList}
        handleClickItem={handleClickItem}
        open={anchorEl != null ? true : false}
        customItemKey={"indexName"}
      />
      <PageLoader
        showLoader={loading ? loading : tableData?.length > 0 ? false : true}
      />
    </>
  );
}

export default TargetPortfolioStep3Wrapper;
