import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import { mfoFilterContext } from "../mfo_context/Mfo_Context";

function FixedDepositRoutes() {
  const { setEntity } = useContext(mfoFilterContext);
  setEntity("fd");

  return <Outlet />;
}

export default FixedDepositRoutes;
