import React from "react";
import Default from "../components/dashboard/defaultPages/Default";
import { DetailsAccordion } from "../components/dashboard/common/DetailsAccordion";

function NewPartnerDetailPage() {
  return (
    <Default>
      <DetailsAccordion />
    </Default>
  );
}

export default NewPartnerDetailPage;
