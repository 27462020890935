import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  lifeCoverAmount: {
    minHeight: "162px",
    background:
      "linear-gradient(125.9deg, #4C1A97 15.01%, #5822A9 36.08%, #7636D7 90.23%)",
    borderRadius: "16px",
    position: "relative",
  },
  lifeCoverAmountText1: {
    fontFamily: "Avenir",
    fontWeight: 800,
    fontSize: "32px",
    color: "#FFFFFF",
    marginBottom: "8px",
  },
  lifeCoverAmountText2: {
    fontFamily: "Avenir",
    fontWeight: 800,
    fontSize: "23px",
    color: "#FFFFFF",
  },
  lifeCoverAmountImg: {
    position: "absolute",
    top: "0",
    right: "0",
  },
  lifeCoverAmountCardContent: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "35px",
    marginTop: "40px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    width: "70%",
    marginLeft: "15%",
    marginTop: "5%",
    marginBottom: "5%",
  },
  buttonYes: {
    background: "#8b44f6",
    borderRadius: "20px",
  },
  buttonNo: {
    borderRadius: "20px",
    background: "none",
    border: "1px solid #8b44f6",
    marginRight: "2%",
  },
  buttonText: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#FFFFFF",
    textTransform: "none",
  },

  modalContainer: {
    backgroundColor: "#170630",
    borderRadius: "20px",
    border: "1px solid #4E189F",
    position: "absolute",
    outline: 0,
    top: "0",
    right: "0",
    width: "300px",
    padding: "15px",
    height: "100vh",
    overflow: "scroll",
  },
  modalContainerModified: {
    backgroundColor: "var(--secondaryBackground-color)",
    borderRadius: "20px",
    border: "1px solid var(--border)",
    position: "absolute",
    outline: 0,
    top: "45%",
    right: "3.5%",
    width: "20%",
    padding: "15px",
    height: "45%",
    overflow: "scroll",
  },
  backDrop: {
    backdropFilter: "blur(5px)",
    background: "rgba(31, 28, 64, 0.51)",
  },

  titleText: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "22px",
    lineHeight: "30px",
    color: "var(--selectedText)",
  },

  mainContent: {
    margin: "2%",
    // background: "#110425", //background for pie chat cards
    // boxShadow:
    //   "-4px 4px 6px #000000, 4px -4px 6px rgba(89, 22, 189, 0.3), inset -4px 4px 20px #000000, inset 3px -4px 10px #5916BD",
    borderRadius: "20px",
    // marginTop: "3%",
    // marginLeft: "4%",
    // width: "92%",
    height: "auto",
    padding: "15px",
  },
  removeOutline: {
    boxShadow: "none",
    borderRadius: "0px",
    background: "transparent",
  },

  startRating: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paddingLeft: {
    paddingLeft: "10px!important",
  },
  rowTextTable: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "15px",
    color: "#FFFFFF",
  },
  tableHeaderTextDynamic: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#FFFFFF",
    borderBottom: "none",
    whiteSpace: "nowrap",
  },
  tableContainer: {
    marginTop: "20px",
  },
  detailBox: {
    background: "#110425",
    border: "1px solid #220848",
    boxShadow:
      "-4px 4px 6px #000000, 4px -4px 10px rgba(89, 22, 189, 0.5), inset -4px 4px 20px #000000, inset 3px -4px 10px #5916BD",
    borderRadius: "20px",
    margin: "2%",
    padding: "4%",
  },

  tableHeaderText: {
    background: "#4E189F",
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    alignItems: "center",
    color: "#FFFFFF !important",
    textAlign: "right",
  },
  rowText: {
    background: " #1D1B36",
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#FFFFFF !important",
    textAlign: "right",
  },

  cardText: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "16px",
    // lineHeight: "88px",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
  },
  cardSubText: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "22px",
    lineHeight: "32px",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
    marginBottom: "5px",
  },
  cardTextSmall: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "20px",
    lineHeight: "36px",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
  },
  cardSubTextSmall: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "16px",
    lineHeight: "32px",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
  },

  cardContent: {
    marginTop: "20px",
    marginLeft: "20px",
  },

  newCardContent: {
    marginTop: "10%",
    marginLeft: "4%",
  },

  buttonBigText: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "26px",
    lineHeight: "30px",
    display: "flex",
    alignItems: "center",
    color: "#F8F9FB",
    textTransform: "none",
  },
  newBottomTabHeader: {
    background: "#220848",
    boxShadow: "-4px -4px 10px #431D84, 4px 4px 10px #000000",
    padding: "0px 10px",
    borderRadius: "124.46px",
    padding: "16px 20px",
    display: "flex",
    marginLeft: "auto",
  },
  bottomTabHeader: {
    background: "#220848",
    boxShadow: "-4px -4px 10px #431D84, 4px 4px 10px #000000",
    padding: "0px 10px",
    borderRadius: "124.46px",
    // padding: "5px",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      width: "355px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "355px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "340px",
    },
  },
  //   bgholdingsummary : {
  //   backgroundImage:' url("../../../../assets/holding-summary/holding-summary-bg.png")!important',
  //   backgroundSize:' auto  !important',
  //   backgroundPosition:' top right!important',
  //   backgroundRepeat:' no-repeat!important',
  //   borderRadius:' 20px!important',
  //   backgroundColor:' #7636D7 !important',
  // },
  // smallbgholdingsummary :
  // {
  //   backgroundImage:'url("../../../../assets/holdingSummary/holding-summary-bgsmall.png")!important',
  //   backgroundSize:' contain  !important',
  //   backgroundPosition:' top right!important',
  //   backgroundRepeat:' no-repeat!important',
  //   borderRadius:' 20px!important',
  //   marginRight:' 10px!important',
  //   backgroundColor:' #7636D7 !important',
  // },
  bottomTabHeaderTextholdsummary: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    textAlign: "center",
    color: "#FFFFFF",
    padding: "5px",
    margin: "0 10px",
    [theme.breakpoints.down("md")]: {
      margin: "0 auto",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0 auto",
    },
  },
  bottomTabActiveholdsummary: {
    background: "#380D77",
    boxShadow: "inset 0px 4px 10px #220848",
    borderRadius: "50px",
    color: "#F1E8FE",
    lineHeight: "20px",
    padding: "5px 10px",
    border: "1px solid #711CF1",
    marginRight: "0",
    marginLeft: "0",
  },
  bottomTabHeaderText: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    textAlign: "center",
    color: "#FFFFFF",
    paddingTop: "5px",
    margin: "0 20px",
  },
  bottomTabActive: {
    background: "var(--highlightCoal)",
    boxShadow: "inset 0px 4px 10px #220848",
    borderRadius: "50px",
    color: "#F1E8FE",
    lineHeight: "20px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingBottom: "4px",
    paddingTop: "4px",
    border: "1px solid var(--border)",
    marginRight: "0",
    marginLeft: "0",
  },
  tableRowDark: {
    background: "linear-gradient(180deg, #AA6FFE 0%, #711CF1 100%)",
    opacity: "0.2",
    color: "#FFFFFF !important",
  },

  riskText: {
    fontSize: "18px",
    color: "#D3301A",
    textAlign: "center",
  },

  riskDetailsTextMain: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "24px",
    color: "#9488A7",
  },
  riskDetailsTextSub: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "15px",
    lineHeight: "30px",
    color: "#FFFFFF",
    marginTop: "2%",
  },

  riskHeaderMain: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "22.2785px",
    lineHeight: "42px",
    color: "#9488A7",
  },
  riskHeaderSub: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "24px",
    // lineHeight: '124px',
    color: "#FFFFFF",
    display: "flex",
    alignItems: "center",
  },

  riskDetailsTextMain: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "24px",
    color: "#9488A7",
  },
  riskDetailsTextSub: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "15px",
    lineHeight: "30px",
    color: "#FFFFFF",
    marginTop: "2%",
  },
  assetAllocationBar: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    gap: "20px",
  },
  progressBar: {
    width: "80%",
    height: "47.6px",
    display: "flex",
    justifyContent: "flex-start",
    // margin: "20px 30px",
    borderRadius: "0px 10px 10px 0px",
    // overflow: 'hidden'
  },
  progressBarItem1: {
    height: "100%",
    background: "linear-gradient(143.94deg, #E96114 18.28%, #B80000 86.6%)",
  },
  progressBarItem2: {
    height: "100%",
    background: "linear-gradient(270deg, #00C1CE 0%, #82EBA1 100%)",
  },
  progressBarItem3: {
    height: "100%",
    background: "linear-gradient(180deg, #509FF1 0%, #3A56FF 100%)",
  },
  list: {
    width: "170px",
    margin: "0px",
  },
  listItems: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    color: "rgba(250, 249, 255, 0.5)",
  },

  fundButton: {
    background: " #220848",
    border: " 1px solid #4E189F",
    borderRadius: "30px",
  },
  fundPerformanceCardText: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "16px",
    lineHeight: "22px",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
  },
  clickIcon: {
    margin: "-1% 2%",
  },
  customCardText900: {
    fontFamily: "'Avenir'",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "22px",
    lineHeight: "28px",
    textTransform: "capitalize",
    color: "white",
    marginBottom: "10px",
  },
  graphLegends: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center",
    color: "var(--selectedText)",
    opacity: 0.5,
  },
  graphBullets: {
    marginTop: "-3%",
  },
  graphBulletBonds: {
    marginTop: "-2px",
  },
  dateRange: {
    background: " #130C28",
    borderRadius: "20px",
    padding: "5%",
    marginBottom: "10%",
  },

  dataAlign: {
    display: "flex",
    justifyContent: "center",
    height: "250px",
    alignItems: "center",
  },
  percentageTableValue: {
    fontFamily: "'Avenir'",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "16px",
    lineHeight: "22px",
  },
  dataAlign: {
    display: "flex",
    justifyContent: "center",
    height: "250px",
    alignItems: "center",
  },
  labelContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: "16px",
    alignItems: " flex-start",
  },
  labelSymbol: {
    borderRadius: "50%",
    width: "12px",
    height: "12px",
  },
  label: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "24px",
    textAlign: "start",
    color: "#faf9ff",
    width: "275px",
    textOverflow: "ellipsis",
  },
  labelTextLegends: {
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "20px",
    color: "var(--selectedText) ",
    opacity: "0.7",
  },
  filterRange: {
    height: "350px",
  },
  bottomSubTabHeader: {
    background: "#220848",
    boxShadow: "-4px -4px 10px #431D84, 4px 4px 10px #000000",
    // paddingRight: "5px",
    borderRadius: "124.46px",
    padding: "3px",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      width: "255px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "255px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "240px",
    },
  },
  bottomSubTabHeaderText: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#FFFFFF",
    paddingTop: "5px",
    margin: "0 20px",
    textTransform: "capitalize",
  },

  //fonts
  //holding-summary
  mainHeading: {
    fontFamily: "Avenir !important",
    fontStyle: "normal !important",
    fontWeight: "800 !important",
    fontSize: "22px !important",
    lineHeight: "30px !important",
    color: " #F8F9FB !important",
    textTransform: "capitalize !important",
  },
  textCardBig: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "20px",
    // lineHeight: "32px",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
  },
  textCardBigValue: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "25px",
    // lineHeight: "88px",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
  },
  textCardSmall: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "17.2896px",
    lineHeight: "23px",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
  },
  textCardSmallValue: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800!important",
    fontSize: "18px",
    lineHeight: "40px",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
  },
  graphHeader: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "26px",
    lineHeight: "30px",
    display: "flex",
    alignItems: "center",
    color: "#F8F9FB",
  },
  holdingButtonText: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "16px",
    lineHeight: "30px",
    color: "#F8F9FB",
    display: "flex",
    alignItems: "center",
    textTransform: "none",
  },

  //holding-summary
  hldBigcardTxtspacing: {
    marginTop: "20%",
    marginLeft: "10%",
  },
  cardswidth: {
    width: "93vw",
    margin: "0 auto",
    minHeight: "5%",
  },
  aifcardswidth: {
    width: "92vw",
    margin: "0 auto",
    minHeight: "5%",
  },
  insurancecardswidth: {
    width: "92vw",
    margin: "2% auto",
    minHeight: "5%",
  },
  hldaifBigcardTxtspacing: {
    marginTop: "20%",
    marginLeft: "10%",
    marginBottom: "8%",
  },
  hldpmsBigcardTxtspacing: {
    marginTop: "20%",
    marginLeft: "10%",
    marginBottom: "3%",
  },
  hldrealestateBigcardTxtspacing: {
    marginTop: "34%",
    marginLeft: "10%",
    marginBottom: "3%",
  },
  aifsmallcardTxtspacing: {
    marginTop: "45%",
    marginLeft: "10%",
    marginBottom: "8%",
  },
  bluebox: {
    background: " linear-gradient(180deg, #509FF1 0%, #3A56FF 100%)!important",
    boxShadow: "0px 3.54122px 3.54122px #000000!important",
  },
  orangebox: {
    background:
      " linear-gradient(247.79deg, #FE6587 3.21%, #F58D2D 100%)!important",
    boxShadow: "0px 3.54122px 3.54122px #000000!important",
  },
  greenbox: {
    marginTop: "20px",
    background: " linear-gradient(270deg, #00C1CE 0%, #82EBA1 100%)!important",
    boxShadow: "0px 3.54122px 3.54122px #000000!important",
  },
  purplebox: {
    marginTop: "20px",
    background:
      " background: linear-gradient(180deg, #AA6FFE 0%, #711CF1 100%)!important",
    boxShadow: "0px 3.54122px 3.54122px #000000!important",
  },
  mrtopm5: {
    marginTop: "-5%",
  },
  wid380px: {
    width: "380px",
  },

  // pms holding summary

  mrtopleftsmallbox: {
    marginLeft: "10%",
  },

  topHeader: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    alignItems: "center",
    gap: "16px",
    margin: "0% 2%",
  },
  alignTopHeader: {
    margin: "1%  2%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mainHeadingAlign: {
    cursor: "pointer",
    width: "45%",
    // marginLeft: "10px",
  },
  iconAlign: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  iconStyle: {
    borderRadius: "124.46px",
    border: "1px solid var(--primaryHighlight-color)",
    padding: "10px 0px",
    margin: "2px 0px",
    height: "15px",
    width: "50px",
    cursor: "pointer",
  },
  holdingContentAlign: {
    height: "50%",
    width: "98%",
    margin: "4% 1% 8%",
  },
  typoInsideAlign: {
    margin: "1% 2%",
  },

  //mfoStyles
  mfoTextCard: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    marginBottom: "3px",
  },
  mfoTextCardFlippy: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "24px",
    color: "#FFFFFF",
    marginBottom: "3px",
  },
  mfoCardValue: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "21px",
    lineHeight: "30px",
    color: "#FFFFFF",
    marginBottom: "15px",
  },
  mfoSubTextCard: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#FFFFFF",
  },
  subtractCircle: {
    textAlign: "center",
    position: "relative",
    backgroundImage: "conic-gradient(white,white,white)",
    height: "45px",
    width: "45px",
    borderRadius: "50%",
    marginBottom: "5px",
  },
  mfoCardAlign: {
    marginTop: "20px",
    marginLeft: "7%",
  },
  mfoCircleAlign: {
    position: "absolute",
    top: "30%",
    left: "30%",
  },
  mfoGraphHeader: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "24px",
    lineHeight: "30px",
    color: "#FAF9FF",
  },
  mfoSubHeadText: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "30px",
    color: "#EAE7ED",
    display: "flex",
    alignItems: "center",
  },
  mfoTopMainAlign: {
    height: "300px",
    marginBottom: "4%",
    position: "relative",
  },
  mfoBottomMainAlign: {
    height: "300px",
    position: "relative",
  },
  mfoMainContainer: {
    display: "flex",
    width: "98%",
    margin: "0 1%",
  },
  mfoBackCard: {
    background:
      " linear-gradient(180deg, rgba(84, 62, 193, 0.51) 0%, rgba(0, 0, 0, 0.51) 100%)",
    boxShadow: "inset - 6px 10px 31px 41px rgba(0, 0, 0, 0.25)",
    backdropFilter: "blur(21px)",
    borderRadius: " 16px",
  },
  mfoBackTextMain: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "18px",
    lineHeight: "25px",
    color: "#EFEEF5",
  },
  mfoBackTextSub: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "133.02%",
    color: "#DAD3FF",
    letterSpacing: "0.06em",
  },

  //mfoRisk
  amcName: {
    borderRadius: "50%",
  },
  riskMainTitle: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "28px",
    lineHeight: "32px",
    color: "#FFFFFF",
    display: "flex",
    alignItems: "center",
  },
  riskLinkTitle: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    background: " linear-gradient(180deg, #AA6FFE 0%, #711CF1 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
  },
  riskoMeter: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignContent: "center",
    justifyContent: "space-evenly",
  },
  riskHeaderAlign: {
    borderRight: "1px solid #1F1C40",
    padding: "0 45px",
  },
  riskHeaderMain: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "22.2785px",
    lineHeight: "42px",
    color: "#9488A7",
    textAlign: "center",
  },
  riskHeaderSub: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "24px",
    // lineHeight: '124px',
    color: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
  riskTitle: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "30px",
    lineHeight: "30px",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
  },

  //aifStyles

  aifSubText: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#FFFFFF",
  },

  //bonds
  bondsBankPageText: {
    fontFamily: "Avenir",
    fontStyle: " normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "24px",
    color: " #9488A7",
  },
  bondsBankPageHeadingText: {
    fontFamily: "Avenir",
    fontStyle: " normal",
    fontWeight: "800",
    fontSize: "24px",
    lineHeight: "72px",
    color: " #FFFFFF",
  },
  bondsText: {
    fontFamily: "Avenir",
    fontStyle: " normal",
    fontWeight: "900",
    fontSize: "24px",
    lineHeight: "32px",
    color: " #FFFFFF",
  },
  bondsHeadText: {
    fontFamily: "Avenir",
    fontStyle: " normal",
    fontWeight: "800",
    fontSize: "20px",
    lineHeight: "32px",
    color: " #FFFFFF",
  },
  bondsTextNew: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
  },
  bondsSubTextNew: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "18.5px",
    color: "#9488A7",
  },
  bondsBoxTextNew: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "18.5px",
    color: "#FFFFFF",
  },
  bondsHeadNew: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "24px",
    lineHeight: "32px",
    color: "#FFFFFF",
  },
  bondsSubTitleText: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "20px",
    lineHeight: "30px",
    color: "#FFFFFF",
  },
  bondsSubTitleText2: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "32px",
    color: "rgba(255, 255, 255, 0.6)",
  },

  //table
  tableHeaderTextNew: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    color: "var(--selectedText)  !important",
    borderBottom: "none  !important",
    whiteSpace: "nowrap",
    backgroundColor: "transparent",
  },
  tableBodyTextNew: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "var(--selectedText)  !important",
    textAlign: "center",
    borderBottom: "1px solid #28235C",
  },
  tableHeadText: {
    fontFamily: "Avenir  !important",
    fontStyle: "normal",
    fontWeight: "500  !important",
    fontSize: "14px  !important",
    lineHeight: "20px  !important",
    display: "flex",
    whiteSpace: "nowrap",
    alignItems: "center",
    color: "#FFFFFF !important",
    borderBottom: "none  !important",
    background: "#1D1B36",
  },
  tableBodyText: {
    fontFamily: "Avenir !important",
    fontStyle: "normal !important",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "22px !important",
    whiteSpace: "nowrap",
    color: "var(--selectedText) !important",
  },

  grandTotalCells: {
    fontFamily: "Avenir !important",
    fontStyle: "normal !important",
    fontWeight: "800 !important",
    fontSize: "14px !important",
    lineHeight: "22px !important",
    whiteSpace: "nowrap",
    color: "var(--selectedText) !important",
  },
  tableBodyTextOverall: {
    fontFamily: "Avenir !important",
    fontStyle: "normal !important",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "22px !important",
    whiteSpace: "nowrap",
    padding: "30px 16px 16px 16px !important",
    color: "#FFFFFF !important",
  },
  tableHeadBlue: {
    background: "#4E189F",
    boxShadow: "0px 4px 29px rgba(17, 4, 37, 0.5)",
    // borderRadius: "20px 20px 0px 0px",
    borderTopLeftRadius: "20px !important",
    borderTopRightRadius: "20px !important",
  },
  tableHeadBlueText: {
    fontFamily: "Avenir !important",
    fontStyle: "normal !important",
    fontWeight: "800 !important",
    fontSize: "14px !important",
    lineHeight: "20px !important",
    color: "#FFFFFF !important",
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
  },
  tableContainerAlign: {
    overflowY: "scroll",
    minHeight: "300px",
    width: "97%",
    margin: "0% 1.5%",
  },
  subTabEquityStockSummary: {
    border: "1px solid #4E189F",
    display: "flex",
    padding: "8px",
    background: "#220848",
    boxShadow:
      "-6.22302px -6.22302px 18.6691px #302A64, 6.22302px 6.22302px 18.6691px #000000",
    alignItems: "center",
    borderRadius: "124.46px",
    justifyContent: "space-between",
    marginLeft: "2%",
  },
  tableBodyBlue: {
    background: "#1D1B36",
    // boxShadow: "0px 4px 29px rgba(17, 4, 37, 0.5)",
    borderRadius: "20px 20px 0px 0px",
  },

  //button
  buttonLightBlue: {
    background: "linear-gradient(180deg, #AA6FFE 0%, #711CF1 100%)",
    border: "1px solid #711CF1",
    borderRadius: "50px",
    color: "#FFFFFF",
    margin: "2% auto",
    textTransform: "none",
    padding: "12px",
    width: "190px",
  },
  viewDetailBtn: {
    // background: "#4f1fda !important",
    // border: "1px solid #711CF1",
    borderRadius: "7px",
    // color: "#FFFFFF",
    margin: "2% auto",
    textTransform: "none",
    padding: "12px",
    width: "190px",
  },
  buttonLightBlueAlign: {
    width: "100%",
    display: "flex",
  },
  holdingButton: {
    background: "#380D77",
    borderRadius: "50px",
    width: "30%",
    margin: "0 2.5%",
  },
  buttonApplyModal: {
    background: "linear-gradient(180deg, #AA6FFE 0%, #711CF1 100%)",
    borderRadius: "20px",
  },
  buttonContainerTransaction: {
    display: "flex",
    justifyContent: "space-between",
    // width: "70%",
    // marginLeft: "9%",
    // marginTop: "5%",
    // marginBottom: "5%",
    gap: "10px",
    margin: "4%",
  },

  //tab
  tabHeader: {
    background: "#220848",
    paddingRight: "5px",
    borderRadius: "124.46px",
    padding: "12px",
    margin: "1%",
    display: "flex",
    justifyContent: "space-around",
    boxShadow:
      " -6.22302px -6.22302px 18.6691px #302A64, 6.22302px 6.22302px 18.6691px #000000",
  },

  tabHeaderText: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "35px",
    textAlign: "center",
    color: "#FFFFFF",
    // paddingTop: "5px",
    margin: "0px 20px",
  },
  active: {
    background: "#380D77",
    boxShadow: "inset 0px 4px 10px #220848",
    borderRadius: "50px",
    color: "#F1E8FE",
    lineHeight: "20px",
    padding: "8px 20px!important",
    border: "1px solid #711CF1",
    marginRight: "0",
    marginLeft: "0",
    width: "auto",
  },
  tabMfo: {
    background: " #220848",
    border: "1px solid #4E189F",
    boxShadow:
      " inset -4px -4px 10px rgba(76, 50, 117, 0.5), inset 4px 4px 10px rgba(0, 0, 0, 0.5)",
    borderRadius: "124.46px",
    display: "flex",
    justifyContent: "space-between",
    padding: "8px",
    alignItems: "center",
    // margin: "0.5%",
  },

  //TILES
  smallTile: {
    // marginLeft: "15px",
    // marginRight: "15px",
    background:
      "linear-gradient(125.9deg, #4C1A97 15.01%, #5822A9 36.08%, #7636D7 90.23%)",
    borderRadius: "16px",
  },
  textTileDetails: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "22px",
    lineHeight: "28px",
    display: "flex",
    alignItems: "center",
    color: " #FFFFFF",
    marginBottom: "5%",
  },
  textTileAmount: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "25px",
    // lineHeight: "79px",
    display: "flex",
    alignItems: "center",
    color: " #FFFFFF",
  },
  textTilePercent: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    // lineHeight: "28px",
    display: "flex",
    alignItems: "center",
    color: " #FFFFFF",
  },
  subTab: {
    width: " 95%",
    background: " #1D1B36",
    border: "1px solid #010825",
    boxShadow: " 0px 3.54122px 3.54122px #000000",
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
    margin: "0 auto",
    height: "40px",
  },
  cardBottomText: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "28px",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
    justifyContent: "center",
    height: "100%",
  },

  //check and delete
  // mfoCardName: {
  //   fontFamily: 'Avenir',
  //   fontStyle: 'normal',
  //   fontWeight: '500',
  //   fontSize: '16px',
  //   lineHeight: '24px',
  //   color: '#FFFFFF',
  //   marginBottom: "2%"
  // },
  rowTextNew: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#FFFFFF  !important",
    textAlign: "center",
  },
  equityipotext: {
    fontFamily: "Avenir",
    fontWeight: "500",
    fontSize: "18px",
    color: "white",
  },
  equityIPOtext2: {
    color: "white",
    fontFamily: "Avenir",
    fontWeight: "400",
    fontSize: "18px",
  },
  equityIpoIconText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "15px",
  },
  pageiconTitleAlign: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: "20px",
  },

  tooltipElipses: {
    width: "180px !important",
    whiteSpace: "nowrap !important",
    textOverflow: "ellipsis !important",
    overflow: "hidden !important",
  },

  sectorBgCard: {
    background: "linear-gradient(248.45deg, #14529B -42.94%, #5A12A3 100%)",
    boxShadow: " 0px 4px 4px #000000",
    marginLeft: "10px",
    marginRight: "10px",
    borderRadius: "20px",
    marginBottom: "18px",
    minHeight: "248px",
  },
  sectoriconAlign: {
    width: "76%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "1%",
  },
  sectoralignSubTopHeader: {
    margin: "1%  2%",
    display: "flex",
  },
  equitymainContent: {
    margin: "2%",
    background: "#110425",
    boxShadow:
      "-4px 4px 6px #000000, 4px -4px 6px rgba(89, 22, 189, 0.3), inset -4px 4px 20px #000000, inset 3px -4px 10px #5916BD",
    borderRadius: "20px",
    // marginTop: "3%",
    // marginLeft: "4%",
    // width: "92%",
    height: "1200px",
    padding: "15px",
  },
  sectorCard: {
    paddingTop: "8%",
    paddingLeft: "5%",
    // marginBottom: "8%",
  },
  sectorCardTitle: {
    color: "white",
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "24px",
    lineHeight: "36px",
  },
  sectorCardValue: {
    color: "white",
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "36px",
    lineHeight: "54px",
  },
  sectorCardCurrentDes: {
    color: "white",
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "36px",
  },
  GainDetailsHeadingText: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "30px",
    color: "#FAF9FF",
  },

  stockMainHeading: {
    fontFamily: "Avenir",
    fontStyle: " normal",
    fontWeight: "800",
    fontSize: "48px",
    lineHeight: "72px",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
  },
  stockMainValues: {
    fontFamily: "Avenir",
    fontStyle: " normal",
    fontWeight: "800",
    fontSize: "24px",
    lineHeight: "45px",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
  },
  stockContentHeading: {
    fontFamily: "Avenir",
    fontStyle: " normal",
    fontWeight: "800",
    fontSize: "24px",
    lineHeight: "30px",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
  },
  stockContentText: {
    fontFamily: "Avenir",
    fontStyle: " normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    display: "flex",
    alignItems: "center",
    color: "#9488A7",
  },

  ipoTextTitle: {
    fontFamily: "Avenir",
    fontStyle: " normal",
    fontWeight: "800",
    fontSize: "28px",
    lineHeight: "32px",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
  },
  ipoBoxes: {
    width: "92%",
    maxHeight: "20%",
    background: "#1D1B36",
    boxShadow: "0px 4px 29px rgba(17, 4, 37, 0.5)",
    borderRadius: "20px",
    margin: "2%",
    padding: "2%",
  },
  ipoBoxesText: {
    fontFamily: "Avenir",
    fontStyle: " normal",
    fontWeight: "900",
    fontSize: "18px",
    lineHeight: "32px",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
  },
  ipoBoxesBlue: {
    background: "#4E189F",
    mixBlendMode: "normal",
    boxShadow: "0px 4px 29px rgba(17, 4, 37, 0.5)",
    borderRadius: "13px",
    margin: "1%",
    // width: "18%",
    minWidth: "175px",
    minHeight: "60px",
    padding: "1%",
  },
  iposmallText: {
    fontFamily: "Avenir",
    fontStyle: " normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "21px",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
  },
  ipoTrSmallText: {
    fontFamily: "Avenir",
    fontStyle: " normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "45px",
    color: "#FFFFFF",
  },
  ipoTrBigText: {
    fontFamily: "Avenir",
    fontStyle: " normal",
    fontWeight: "900",
    fontSize: "16px",
    lineHeight: "45px",
    color: "#FFFFFF",
    display: "flex",
    alignItems: "center",
  },
  ipoPara: {
    fontFamily: "Avenir",
    fontStyle: " normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#FFFFFF",
  },
  ipoSubPara: {
    fontFamily: "Avenir",
    fontStyle: " normal",
    fontWeight: "900",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#FFFFFF",
  },
  ipoSubParaText: {
    fontFamily: "Avenir",
    fontStyle: " normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "33px",
    color: "#FFFFFF",
  },
  EquityTabHead: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#A468FF !important",
  },
  EquityMarketText: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "24px",
    color: "rgba(250, 249, 255, 0.7)",
  },

  // portfolio comparison card
  portfoliocard: {
    backgroundColor: "#4C1A97!important",
    borderRadius: "16px!important",
  },

  portfoliouser: {
    width: "30%",
    float: "left",
    borderRadius: "14px",
  },
  bgcircles: {
    width: "100%",
    maxHeight: "200px",
    backgroundImage:
      "url('../../../../assets/clientdashboard/bgcircles.svg') !important",
    backgroundRepeat: "no-repeat !important",
    backgroundPosition: "right center !important",
  },
  bigmemberamtportcomp: {
    color: "#EFEEF5",
    fontWeight: "700",
    fontSize: "22px",
  },
  famheadportcomp: {
    color: "#EFEEF5",
    fontWeight: "400",
    fontSize: "16px",
  },
  totgainloss: {
    fontWeight: "400",
    color: "#C9C3D3",
    fontSize: "12px",
    paddingTop: "20px",
  },
  totgainlossamt: {
    color: "#35CBBF",
    fontWeight: "700",
    fontSize: "18px",
  },
  rightportcomp: {
    display: "flex",
    paddingLeft: "10px",
  },
  portcompname: {
    color: "#EFEEF5",
    fontSize: "20px",
    fontWeight: "700",
  },
  equitylabel: {
    color: "#FFFFFF",
    fontSize: "14px",
    background: "linear-gradient(180deg, #FE6587 0%, #F52D6A 100%)",
    borderRadius: "20px",
    padding: "8px 20px",
  },
  debtlabel: {
    color: "#FFFFFF",
    fontSize: "14px",
    background: "linear-gradient(180deg, #AA6FFE 0%, #711CF1 100%);",
    borderRadius: "20px",
    padding: "8px 20px",
  },
  cashlabel: {
    color: "#FFFFFF",
    fontSize: "14px",
    background: "linear-gradient(180deg, #509FF1 0%, #3A56FF 100%);",
    borderRadius: "20px",
    padding: "8px 20px",
  },
  alternatelabel: {
    color: "#FFFFFF",
    fontSize: "14px",
    background: "linear-gradient(270deg, #00C1CE 0%, #82EBA1 100%);",
    borderRadius: "20px",
    padding: "8px 20px",
  },
  edcaamt: {
    color: "#FFFFFF",
    fontSize: "30px",
    fontWeight: "600",
    margin: "30px 0px 0px 5px",
  },
  edcaglamtprofit: {
    color: "#00C1CE",
    fontSize: "14px",
  },
  edcaglamtloss: {
    color: "#B80000",
    fontSize: "14px",
  },
  mrtop20: {
    marginTop: "20px",
  },
  leftedcasection: {
    float: "left",
    width: "50%",
  },
  rightedcasection: {
    display: "flex",
  },
  bgcirclesportcomp: {
    backgroundPosition: "top right !important",
  },
  tableContainerAlign: {
    overflowY: "scroll",
    minHeight: "300px",
    width: "97%",
    margin: "0% 1.5%",
  },

  newAlignTopHeader: {
    display: "flex",
    gap: "10px",
    margin: "1% 2%",
    alignItems: "center",
  },
  newIconAlign: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: "auto",
  },
  // marketmeter style
  idcumainsection: {
    padding: "20px 0px",
  },
  idcusection: {
    padding: "0px 30px",
  },

  idtitlebr: {
    borderRadius: "10px 0px 0px 0px",
    background: "#270c51",
    color: "#fff",
    borderBottom: "2px solid #888",
    padding: "55px 20px",
  },
  cutitlebr: {
    borderRadius: "0px 0px 0px 10px",
    background: "#270c51",
    color: "#fff",
    padding: "55px 20px",
  },
  metertitle: {
    background: "#1D1B36",
    color: "#fff",
    padding: "34px 20px",
    borderRadius: "0px",
    fontWeight: "100",
    fontSize: "13px",
    borderRight: "2px solid #181727",
  },
  metertitlelastborder: {
    borderRadius: "0px 10px 10px 0px!important",
    background: "#1D1B36",
    color: "#fff",
    fontWeight: "100",
    fontSize: "13px",
    padding: "34px 20px",
  },
  subTabModify: {
    width: " 95%",
    background: " #1D1B36",
    border: "1px solid #010825",
    boxShadow: " 0px 3.54122px 3.54122px #000000",
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
    margin: "0 auto",
    height: "40px",
  },
  familyWise: {
    fontFamily: "Avenir",
    fontStyle: " normal",
    fontWeight: "800",
    fontSize: "24px",
    lineHeight: "22px",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
    paddingRight: "3%",
  },
  FamilybottomTabHeaderText: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    textAlign: "center",
    color: "#FFFFFF",
  },
  FamilyFilter: {
    backgroundColor: "white",
    color: "#711CF1",
    borderRadius: "50px",
    width: "7%",
    height: "3%",
    textAlign: "center",
    fontFamily: "Public Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "10px",
    lineHeight: "16px",
  },
  FamilyFiltertitleText: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "18px",
    lineHeight: "24px",
    color: "#F8F9FB",
  },

  inputField: {
    borderColor: " #711CF1",
    borderRadius: "20px",
    width: "17.5vw",
  },
  formControl: {
    border: "1px solid #711CF1",
  },
  icon: {
    fill: "#FAF9FF",
  },
  optiondesign: {
    color: "#9488A7 !important",
    // background: "#0b0318 !important",
    background: "#1F1C40 !important",
    border: "1px solid #4E189F !important",
    margin: "13px 10px !important",
  },
  firstColExpandable: {
    transition: "all 0.2s ease-in",
    "&:hover": {
      transform: "scale(1.05) translate(5px, 0px)",
    },
  },
  tableRowCard: {
    boxShadow: "0px 10px 0px 0px var(--table1)",
  },
}));

export default useStyles;
