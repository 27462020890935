export const colorAndProd = {
  equity: "#BBB580",
  debt: "#907DD0",
  alternate: "#1FAAB2",
  cash: "#6761E6",
  total: "#c5b3b3",
  // 👇 field value from generateTableColumnsForEngagementModel in utilHoldingEngagement
  Held: "#EDCA19",
  ["Held Away"]: "#6A8EF5",
};

export const subColorAndProd = {
  equity: "rgba(223, 215, 146, 0.5)",
  debt: "rgb(144, 125, 208,0.5)",
  alternate: "rgb(31, 170, 178, 0.5)",
  cash: "rgb(103, 97, 230, 0.5)",
};

export const colorAndProdRealEstate = {
  commercial: "#BBB580",
  residential: "#1FAAB2",
};

export const prodAndKeyRealEstate = [
  {
    key: "commercial",
    prod: "commercial",
  },
  {
    key: "residential",
    prod: "residential",
  },
];

export const colorAndProdBank = {
  current: "#BBB580",
  savings: "#1FAAB2",
  total: "#c5b3b3",
};

export const prodAndKeyBank = [
  {
    prod: "savings Account",
    key: "savings",
  },
  {
    key: "current",
    prod: "current Account",
  },
  {
    key: "total",
    prod: "Total",
  },
];
