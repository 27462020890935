import React from "react";
import { Link, useNavigate } from "react-router-dom";
import useStyles from "./ProductsStyles";

function TabComponent(props) {
  const classes = useStyles();
  const navigate = useNavigate();

  function getmaxlen(page) {
    let maxlen = 0;
    page?.forEach((element, i) => {
      if (element.page.indexOf("alternate") >= 0) {
        maxlen = maxlen + element.page.length + 3;
      } else {
        maxlen = maxlen + element.page.length;
      }
    });
    return maxlen + page.length * 3;
  }
  function TabState() {
    return (
      <>
        <div
          style={{
            justifyContent:
              props.data.length < 3 ? "space-around" : "space-between",
            // width: `${
            //   props.data.length < 3
            //     ? getmaxlen(props.data) * 11 + "px"
            //     : props.width
            // }`,
            width: `${props.width}`,
          }}
          className={
            props.tabType === "stock-summary"
              ? classes.subTabEquityStockSummary
              : classes.tabMfo
          }
        >
          {props.data.map((p) => {
            // console.log(p);
            return (
              <a
                style={{ textDecoration: "none", cursor: "pointer" }}
                onClick={() => {
                  props.setPage(() => p.page);
                  if (p?.path) {
                    navigate(p?.path);
                  }
                }}
              >
                <div
                  className={`${classes.tabHeaderText} ${
                    props.page === p.page ? classes.active : ""
                  } capitalize`}
                >
                  {p.title}
                </div>
              </a>
            );
          })}
        </div>
      </>
    );
  }

  function TabParams() {
    return (
      <>
        <div style={{ width: `${props.width}` }} className={classes.tabMfo}>
          {props.data.map((p) => {
            return (
              <Link
                style={{ textDecoration: "none", cursor: "pointer" }}
                to={p.path}
              >
                <div
                  className={`${classes.tabHeaderText} ${
                    props.pageName == p.page ? classes.active : ""
                  }`}
                >
                  {p.title}
                </div>
              </Link>
            );
          })}
        </div>
      </>
    );
  }

  return <>{props.type === "state" ? TabState() : TabParams()}</>;
}

export default TabComponent;
