import React, { useEffect, useState, useContext, useRef } from "react";
import { fetchCoverageSummary } from "../../../../api";
import {
  dateFormatCommon,
  roundNumber,
  summarizeTableData,
} from "../../../utils/common";
import { tableCoverData, tableReturnsData } from "./InsuranceComp";
import { currencyFormat } from "../../../../hooks/constantFunction";
import { OnboardingContext } from "../../../../Context";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import moment from "moment";
import SummaryPageTop from "../common/SummaryPageTop";
import { routes } from "../../../../routes";
import { NewFilter } from "../../newFilter/NewFilter";
import { ReactComponent as EditColumn } from "../../../../assets/icons/edit-column.svg";
import useStyles from "../common/ProductsStyles";
import PmsReportTable from "../pms/PmsReportTable";
import { ITEMS_LIMIT_FILES_PARTNER_LIST } from "../../../../Constants";
import NewtableTopSec from "../../common/NewtableTopSec";
import { useCurrentHoldingCardsApi } from "../../../../hooks/useCurrentHoldingCardsApi";
function InsuranceLifeReport({ ulip, cover, type = "ins" }) {
  const classes = useStyles();
  const {
    holdingCardData,
    xirrCurrentHolding,
    twrrCurrentHolding,
    loading: currentHoldingCardLoader,
    twrrLoaderCurrentHolding,
    twrrErrorCurrentHolding,
    retryFetchTwrrCurrentHolding,
  } = useCurrentHoldingCardsApi(true, "ins", (ulip = "ulip"), "life");
  const [pagiNationData, setPageinationData] = useState({
    pageCount: null,
    // limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
  });

  const [offset, setOffset] = useState(0);
  const [newTableColumns, setNewTableColumns] = useState([]);
  const [secondTableColumns, setSecondTableColumns] = useState([]);
  const [tableHeader, setTableHeader] = useState(
    // cover == "cover" ? tableCoverData : tableReturnsData
    tableReturnsData
  );
  const { userState } = OnboardingContext();
  const { mfoFilter } = useContext(mfoFilterContext);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(true);
  const handleIconClick = () => {
    setOpenModal(!openModal);
  };
  const [tableColumns, setTableColumns] = useState(tableCoverData);
  const [search, setSearch] = useState("");

  const numberOfInstrumentsCount = useRef(0);
  const [numberOfInstruments, setNumberOfInstruments] = useState(0);
  const [percentageOfTotalPortfolio, setPercentageOfTotalPortfolio] =
    useState(0);

  const objectFormating = (data) => {
    let newData = tableHeader.map((header) => {
      if (header?.field == "annualisedTwrr") {
        return {
          key: header?.field,
          field: !isNaN(data[`${header.field}`])
            ? roundNumber(Number(data[`${header.field}`]))
            : "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }

      if (
        header?.field == "policyCommencementDate" ||
        header?.field == "policyEndDate"
      ) {
        return {
          key: header?.field,
          field:
            // moment(data[`${header.field}`]).format("DD - MM - YYYY") || "--",
            dateFormatCommon(new Date(data[`${header.field}`])),
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (
        header?.field == "premiumAmount" ||
        header?.field == "lifeCoverAmount" ||
        header?.field == "investmentValue" ||
        header?.field == "currentValue" ||
        header?.field == "unRealisedGainLoss" ||
        header?.field == "realisedGainLoss" ||
        header?.field == "currentNav"
      ) {
        let currencyformat = currencyFormat(data[`${header.field}`] || 0);
        if (header?.field == "currentNav") {
          currencyformat = currencyFormat(data[`${header.field}`] || 0, {
            format: "actuals",
          });
        }
        return {
          key: header?.field,
          field: currencyformat || 0,
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (
        header?.field == "isin" ||
        header?.field == "folioNo" ||
        header?.field == "executed_by" ||
        header?.field == "noOfPremiums"
      ) {
        return {
          key: header?.field,
          field: data[`${header.field}`]
            ? data[`${header.field}`].toString()
            : "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      return {
        key: header?.field,
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`]))
          : data[`${header.field}`] || "--",
        title: header.title,
        show: header.show,
        align: header?.align,
        secondLine: header?.secondLine,
      };
    });
    return newData;
  };

  useEffect(() => {
    setLoading(true);
    const clientId = userState.loginedUserData.id;
    let params = { search: search };
    if (mfoFilter.startDate) {
      params["startDate"] = moment(mfoFilter.startDate).format("YYYY-MM-DD");
      params["endDate"] = moment(mfoFilter.endDate).format("YYYY-MM-DD");
    }
    if (Object.keys(mfoFilter.checkData).length > 0) {
      params["memberId"] = Object.keys(mfoFilter.checkData).join(",");
    }
    if (Object.keys(mfoFilter.advisor).length > 0) {
      params["advisor"] = Object.keys(mfoFilter.advisor).join(",");
    }
    params["insuranceType"] = "life";
    fetchCoverageSummary(
      clientId,
      { ...params, productSubCategory: "ulip", coverReturnType: "return" },
      type
    )
      .then((cover_ress) => {
        setLoading(false);
        console.log("newList", cover_ress?.data?.data?.result, cover_ress);
        if (cover_ress.status == 200) {
          setPageinationData((prev) => {
            return {
              ...prev,
              pageCount: cover_ress?.data?.data?.meta?.count,
            };
          });

          if (numberOfInstrumentsCount.current === 0) {
            setNumberOfInstruments(cover_ress?.data?.data?.meta?.count);
          }
          numberOfInstrumentsCount.current =
            numberOfInstrumentsCount.current + 1;
          setPercentageOfTotalPortfolio(
            cover_ress?.data?.data?.total?.percentageOfTotalPortfolio
          );
          //new table implimentation
          let newList = [];
          cover_ress?.data?.data?.result?.map((val) => {
            let obj = objectFormating(val);
            newList.push(obj);
          });
          setNewTableColumns(newList);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [mfoFilter, ulip, cover, search]);

  useEffect(() => {
    setSecondTableColumns(
      summarizeTableData(
        newTableColumns,
        holdingCardData,
        xirrCurrentHolding,
        twrrCurrentHolding,
        numberOfInstruments,
        "",
        "",
        "",
        percentageOfTotalPortfolio
      )
    );
  }, [
    newTableColumns,
    holdingCardData,
    xirrCurrentHolding,
    twrrCurrentHolding,
  ]);

  return (
    <>
      <SummaryPageTop
        backLink={routes?.insuranceSummaryPage}
        // pageTitle={"Insurance"}
        ulip={ulip}
        setFilterModalOpen={setFilterModalOpen}
        hideRightSec={true}
      />
      <NewFilter
        filterModalOpen={filterModalOpen}
        setFilterModalOpen={setFilterModalOpen}
        productType="ins"
      />
      <NewtableTopSec
        handleIconClick={handleIconClick}
        // directPageName={"Holding Summary"}
        directPageName={"Policies"}
        setSearch={(e) => {
          setOffset(0);
          setSearch(e);
        }}
        product={"Ulips"}
      />

      <PmsReportTable
        setTableColumns={setNewTableColumns}
        tableColumns={newTableColumns}
        setSecondTableColumns={setSecondTableColumns}
        tableColumns2={secondTableColumns}
        setOpenModal={setOpenModal}
        openModal={openModal}
        loading={loading ? true : false}
        headerList={tableReturnsData}
        tableHeader={tableHeader}
        setTableHeader={setTableHeader}
        stickyProductName={true}
        headerInTwoLine={true}
        pageCount={pagiNationData?.pageCount}
        setItemOffset={setOffset}
        pageNumber={offset / ITEMS_LIMIT_FILES_PARTNER_LIST + 1 || 1}
      />
    </>
  );
}

export default InsuranceLifeReport;
