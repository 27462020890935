let currencyIn = `₹ In ${localStorage.getItem("denomination")}`;

const stbodyData = {
  equity: [
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
  ],
  debt: [
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
  ],
  alternates: [
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
  ],
  reits: [
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
  ],
  commoditie: [
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
  ],
  cash: [
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
    {
      particularSegmentValue: "Debt Mutual Funds",
      holdingCost: "34",
      marketValue: "6.7",
      percentageHolding: "6.7",
      gainLossRealized: "12.454",
      gainLossUnrelized: "12.454",
      irr: "6.7",
      bmIrr: "6.7",
      benchmark: "NIFITY 50 TY",
    },
  ],
};

const stBodyEntityData = {
  equity: {
    particularSegmentValue: "equity",
    holdingCost: "34",
    marketValue: "6.7",
    percentageHolding: "6.7",
    gainLossRealized: "12.454",
    gainLossUnrelized: "12.454",
    irr: "6.7",
    bmIrr: "6.7",
    benchmark: "NIFITY 50 TY",
  },
  debt: {
    particularSegmentValue: "debt",
    holdingCost: "34",
    marketValue: "6.7",
    percentageHolding: "6.7",
    gainLossRealized: "12.454",
    gainLossUnrelized: "12.454",
    irr: "6.7",
    bmIrr: "6.7",
    benchmark: "NIFITY 50 TY",
  },
  alternates: {
    particularSegmentValue: "alternates",
    holdingCost: "34",
    marketValue: "6.7",
    percentageHolding: "6.7",
    gainLossRealized: "12.454",
    gainLossUnrelized: "12.454",
    irr: "6.7",
    bmIrr: "6.7",
    benchmark: "NIFITY 50 TY",
  },
  reits: {
    particularSegmentValue: "reits",
    holdingCost: "34",
    marketValue: "6.7",
    percentageHolding: "6.7",
    gainLossRealized: "12.454",
    gainLossUnrelized: "12.454",
    irr: "6.7",
    bmIrr: "6.7",
    benchmark: "NIFITY 50 TY",
  },
  cash: {
    particularSegmentValue: "cash",
    holdingCost: "34",
    marketValue: "6.7",
    percentageHolding: "6.7",
    gainLossRealized: "12.454",
    gainLossUnrelized: "12.454",
    irr: "6.7",
    bmIrr: "6.7",
    benchmark: "NIFITY 50 TY",
  },
  commoditie: {
    particularSegmentValue: "commoditie",
    holdingCost: "34",
    marketValue: "6.7",
    percentageHolding: "6.7",
    gainLossRealized: "12.454",
    gainLossUnrelized: "12.454",
    irr: "6.7",
    bmIrr: "6.7",
    benchmark: "NIFITY 50 TY",
  },
};

const sdTotalData = {
  particularSegmentValue: "TOTAL PORTFOLIO",
  holdingCost: "34",
  marketValue: "6.7",
  percentageHolding: "6.7",
  gainLossRealized: "12.454",
  gainLossUnrelized: "12.454",
  irr: "6.7",
  bmIrr: "6.7",
};

const stHeaderData = [
  {
    field: "particularSegmentValue",
    title: "Particular Segment Value",
    align: "left",
    color: "",
  },
  {
    field: "holdingCost",
    title: "Holding Cost",
    align: "right",
  },
  {
    field: "marketValue",
    title: "Market Value",
    align: "right",
  },
  {
    field: "percentageHolding",
    title: "Percentage Holding",
    align: "right",
  },
  {
    field: "gainLossRealized",
    title: "Gain/Loss: Realized*",
    align: "right",
  },
  {
    field: "gainLossUnrelized",
    title: "Gain/Loss: Unrelized*",
    align: "right",
  },
  {
    field: "irr",
    title: "IRR (%)",
    align: "center",
  },
  { title: "Benchmark IRR (%)", field: "bmIrr", align: "center" },
  {
    field: "benchmark",
    title: "Benchmark",
    align: "center",
  },
];

const headerData = [
  {
    field: "assetClass",
    title: "Particular Segment Value",
    align: "left",
    color: "",
  },
  {
    field: "investedAmount",
    title: "Invested Value",
    align: "right",
  },
  {
    field: "marketValue",
    title: "Current Value",
    align: "right",
  },
  {
    field: "percentageOfTotalPortfolio",
    title: "Weight (%)",
    align: "right",
  },
  {
    field: "totalGainLoss",
    title: "Total Gain/Loss:",
    align: "right",
  },
  // {
  //   field: "unRealisedGainLoss",
  //   title: "Gain/Loss: Unrelized*",
  //   align: "right",
  // },
  {
    field: "xirr",
    title: "XIRR (%)",
    align: "center",
  },
  {
    title: "Annualized TWRR (%)",
    field: "annualisedTwrr",
    align: "center",
  },
  {
    title: "Absolute TWRR (%)",
    field: "absoluteTwrr",
    align: "center",
  },
  { title: "Benchmark IRR (%)", field: "bmIrr", align: "center" },
  {
    field: "benchmark",
    title: "Benchmark",
    align: "center",
  },
];

const portfolioAllocationHeader = [
  {
    field: "assetClass",
    title: "Asset Class",
    align: "left",
    color: "",
  },
  {
    field: "portfolioValueAmount",
    title: "Current Value",
    subTitle: currencyIn,
  },
  {
    field: "investedAmount",
    title: "Invested Value",
    subTitle: currencyIn,
  },
  {
    field: "totalGainLoss",
    title: "TOtal Gain/Loss",
    subTitle: currencyIn,
  },
  {
    field: "xirr",
    title: "Xirr (%)",
  },
  {
    field: "absoluteTwrr",
    title: "Absolute Twrr (%)",
  },
  {
    field: "annualisedTwrr",
    title: "Annualized Twrr (%)",
  },
];

const EquityMFHeader = [
  {
    field: "schemeName",
    title: "Instrument NAme",
    align: "left",
    color: "",
  },
  {
    field: "investmentValue",
    title: "Invested Value",
  },
  {
    field: "units",
    title: "Units",
  },
  {
    title: "Present Value",
    field: "presentValue",
  },
  {
    field: "totalGainLoss",
    title: "Total gain/Loss",
  },
  {
    field: "xirr",
    title: "XIRR (%)",
  },
  {
    field: "absoluteTwrr",
    title: "Absolute Twrr (%)",
  },
  {
    field: "annualisedTwrr",
    title: "Annualized Twrr (%)",
  },
  {
    field: "benchmarkIrr",
    title: "Benchmark IRR (%)",
  },
  {
    field: "excessPerformance",
    title: "Excess Performance",
  },
  {
    field: "priceAsOnBeNCHMARK",
    title: "Benchmark",
  },
];

const EquityMFHolding = [
  {
    field: "scripName",
    title: "Instrument NAme",
    align: "left",
    color: "",
  },
  {
    field: "investmentValue",
    title: "Invested Value",
    headerStyle: { width: "100%", whiteSpace: "nowrap" },
  },
  {
    field: "units",
    title: "Units",
  },
  {
    title: "Present Value",
    field: "presentValue",
  },
  {
    field: "totalGainLoss",
    title: "Total gain/Loss",
  },
  {
    field: "xirr",
    title: "XIRR (%)",
  },
  {
    field: "absoluteTwrr",
    title: "Absolute Twrr (%)",
  },
  {
    field: "annualisedTwrr",
    title: "Annualized Twrr (%)",
  },
  {
    field: "benchmarkIrr",
    title: "Benchmark IRR (%)",
  },
  {
    field: "excessPerformance",
    title: "Excess Performance",
  },
  {
    field: "priceAsOnBeNCHMARK",
    title: "Benchmark",
  },
];

const newpdfHolding = [
  {
    field: "Instrument Name/Scheme Name",
    title: "Instrument Name",
    align: "left",
    color: "",
  },
  {
    field: "Member Name",
    title: "Member Name",
    customStyle: { width: "fit-content" },
  },
  {
    field: "Advised By",
    title: "Advised By",
  },
  {
    field: "Folio No",
    title: "Folio No",
    align: "left",
    headerStyle: { width: "fit-content", whiteSpace: "nowrap" },
  },

  {
    field: "Quantity/Units",
    title: "Quantity",
  },

  {
    field: "Total Cost",
    title: "Invested Value",
    headerStyle: { width: "100%", whiteSpace: "nowrap" },
  },
  {
    field: "Current Market Value",
    title: "Current Value",
  },
  {
    field: "Other Income",
    title: "Other Income",
  },
  {
    title: "Unrealised Gain/Loss",
    field: "Gain/Loss",
  },
  {
    field: "XIRR",
    title: "XIRR (%)",
  },
  {
    field: "Last Price Date",
    title: "Last Price Date",
  },
];
const newpdfHoldings = [
  {
    field: "schemeName",
    title: "Instrument",
    align: "left",
    color: "",
    // customStyle: { width: "322px" },
  },

  {
    field: "folioNo",
    title: "Folio No",
    align: "left",
    customStyle: { width: "fit-content" },
    headerStyle: { width: "fit-content", whiteSpace: "nowrap" },
  },

  {
    field: "units",
    title: "Quantity",
  },

  {
    field: "totalCost",
    title: "Invested Value",
    customStyle: { width: "fit-content", whiteSpace: "nowrap" },
    headerStyle: { width: "100%", whiteSpace: "nowrap" },
  },
  {
    field: "currentValue",
    title: "Current Value",
    customStyle: { width: "fit-content", whiteSpace: "nowrap" },
    headerStyle: { width: "100%", whiteSpace: "nowrap" },
  },
  {
    field: "otherIncome",
    title: "Other Income",
    customStyle: { width: "fit-content", whiteSpace: "nowrap" },
  },
  {
    title: "Unrealised Gain/Loss",
    field: "unRealisedGainLoss",
    customStyle: { width: "fit-content", whiteSpace: "nowrap" },
  },
  {
    field: "xirr",
    title: "XIRR (%)",
  },
  // {
  //   field: "Last Price Date",
  //   title: "Last Price Date",
  // },
  // { field: "asOnDate", title: "Valuation As On Date" },
];
const newpdfSecurityHoldings = [
  {
    field: "schemeName",
    title: "Instrument",
    align: "left",
    color: "",
    // customStyle: { width: "322px" },
  },

  {
    field: "units",
    title: "Quantity",
  },

  {
    field: "totalCost",
    title: "Invested Value",
    customStyle: { width: "fit-content", whiteSpace: "nowrap" },
  },
  {
    field: "currentValue",
    title: "Current Value",
    customStyle: { width: "fit-content", whiteSpace: "nowrap" },
    headerStyle: { whiteSpace: "nowrap", width: "100%" },
  },
  {
    field: "otherIncome",
    title: "Other Income",
    customStyle: { width: "fit-content", whiteSpace: "nowrap" },
  },
  {
    title: "Unrealised Gain/Loss",
    field: "unRealisedGainLoss",
    customStyle: { width: "fit-content", whiteSpace: "nowrap" },
  },
  {
    field: "xirr",
    title: "XIRR (%)",
  },
  // {
  //   field: "Last Price Date",
  //   title: "Last Price Date",
  // },
  // { field: "asOnDate", title: "Valuation As On Date" },
];
const newpdClientfHoldings = [
  {
    field: "schemeName",
    title: "Instrument",
    align: "lefunitst",
    color: "",
    // customStyle: { width: "322px" },
  },
  {
    field: "folioNo",
    title: "Folio No",
    align: "left",
    headerStyle: { width: "fit-content", whiteSpace: "nowrap" },
  },

  {
    field: "units",
    title: "Quantity",
  },

  {
    field: "totalCost",
    title: "Invested Value",
    customStyle: { width: "fit-content", whiteSpace: "nowrap" },
    headerStyle: { width: "100%", whiteSpace: "nowrap" },
  },
  {
    field: "currentValue",
    title: "Current Value",
    customStyle: { width: "fit-content", whiteSpace: "nowrap" },
    headerStyle: { width: "100%", whiteSpace: "nowrap" },
  },
  {
    field: "otherIncome",
    title: "Other Income",
    customStyle: { width: "fit-content", whiteSpace: "nowrap" },
  },
  {
    title: "Unrealised Gain/Loss",
    field: "unRealisedGainLoss",
    customStyle: { width: "fit-content", whiteSpace: "nowrap" },
  },
  {
    field: "xirr",
    title: "XIRR (%)",
  },
  // {
  //   field: "Last Price Date",
  //   title: "Last Price Date",
  // },
  // { field: "asOnDate", title: "Valuation As On Date" },
];
const newDirectEquityPdfHoldings = [
  {
    field: "schemeName",
    title: "Instrument",
    align: "left",
    color: "",
    // customStyle: { minWidth: "322px" },
  },
  {
    field: "sectorName",
    title: "Sector Name",
    align: "left",
    color: "",
  },

  {
    field: "units",
    title: "Quantity",
  },

  {
    field: "totalCost",
    title: "Invested Value",
    customStyle: { width: "fit-content", whiteSpace: "nowrap" },
    headerStyle: { width: "100%", whiteSpace: "nowrap" },
  },
  {
    field: "currentValue",
    title: "Current Value",
    customStyle: { width: "fit-content", whiteSpace: "nowrap" },
    headerStyle: { width: "100%", whiteSpace: "nowrap" },
  },
  {
    field: "otherIncome",
    title: "Other Income",
    customStyle: { width: "fit-content", whiteSpace: "nowrap" },
  },
  {
    title: "Unrealised Gain/Loss",
    field: "unRealisedGainLoss",
    customStyle: { width: "fit-content", whiteSpace: "nowrap" },
  },
  {
    field: "xirr",
    title: "XIRR (%)",
  },
  // {
  //   field: "Last Price Date",
  //   title: "Last Price Date",
  // },
  // { field: "asOnDate", title: "Valuation As On Date" },
];

const newAifPdfHoldings = [
  {
    field: "schemeName",
    title: "Instrument",
    align: "left",
    color: "",
    // customStyle: { minWidth: "322px" },
  },

  {
    field: "units",
    title: "Quantity",
  },

  {
    field: "totalCost",
    title: "Invested Value",
    headerStyle: { width: "100%", whiteSpace: "nowrap" },
  },
  {
    field: "currentValue",
    title: "Current Value",
    customStyle: { width: "fit-content", whiteSpace: "nowrap" },
    headerStyle: { width: "100%", whiteSpace: "nowrap" },
  },
  {
    field: "otherIncome",
    title: "Other Income",
    customStyle: { width: "fit-content", whiteSpace: "nowrap" },
  },
  {
    title: "Unrealised Gain/Loss",
    field: "unRealisedGainLoss",
    customStyle: { width: "fit-content", whiteSpace: "nowrap" },
  },
  {
    field: "xirr",
    title: "XIRR (%)",
  },
  // {
  //   field: "Last Price Date",
  //   title: "Last Price Date",
  // },
  // { field: "asOnDate", title: "Valuation As On Date" },
];
const newFDRDPMSPdfHoldings = [
  {
    field: "schemeName",
    title: "Instrument",
    showInstruments: true,
    align: "left",
    color: "",
    // customStyle: { minWidth: "322px" },
  },

  {
    field: "totalCost",
    title: "Invested Value",
    headerStyle: { width: "100%", whiteSpace: "nowrap" },
  },
  {
    field: "currentValue",
    title: "Current Value",
    customStyle: { width: "fit-content", whiteSpace: "nowrap" },
    headerStyle: { width: "100%", whiteSpace: "nowrap" },
  },
  {
    field: "otherIncome",
    title: "Other Income",
    customStyle: { width: "fit-content", whiteSpace: "nowrap" },
  },
  {
    title: "Unrealised Gain/Loss",
    field: "unRealisedGainLoss",
    customStyle: { width: "fit-content", whiteSpace: "nowrap" },
  },
  {
    field: "xirr",
    title: "XIRR (%)",
  },
  // {
  //   field: "Last Price Date",
  //   title: "Last Price Date",
  // },
  // { field: "asOnDate", title: "Valuation As On Date" },
];
const newPmsPdfHoldings = [
  {
    field: "schemeName",
    title: "Instrument",
    showInstruments: true,
    align: "left",
    color: "",
    // customStyle: { minWidth: "322px" },
  },

  {
    field: "totalCost",
    title: "Invested Value",
    align: "right",
  },
  {
    field: "currentValue",
    title: "Current Value",
    align: "right",
    customStyle: { whiteSpace: "nowrap" },
    headerStyle: { whiteSpace: "nowrap", with: "100%" },
  },

  {
    title: "Unrealised Gain/Loss",
    field: "unRealisedGainLoss",
    customStyle: { width: "fit-content", whiteSpace: "nowrap" },
  },
  {
    field: "xirr",
    title: "XIRR (%)",
  },
  // {
  //   field: "Last Price Date",
  //   title: "Last Price Date",
  // },
  // { field: "asOnDate", title: "Valuation As On Date" },
];

const newPdfSecurityHolding = [
  {
    field: "Instrument Name/Scheme Name",
    title: "Instrument Name",
    align: "left",
    color: "",
  },

  {
    field: "Quantity/Units",
    title: "Quantity",
  },

  {
    field: "totalCost",
    title: "Invested Value",
    headerStyle: { width: "100%", whiteSpace: "nowrap" },
  },
  {
    field: "Current Market Value",
    title: "Current Value",
    headerStyle: { width: "100%", whiteSpace: "nowrap" },
  },
  {
    field: "Other Income",
    title: "Other Income",
  },
  {
    title: "Unrealised Gain/Loss",
    field: "Gain/Loss",
  },
  {
    field: "XIRR",
    title: "XIRR (%)",
  },
  {
    field: "Last Price Date",
    title: "Last Price Date",
  },
];
const ClientHolding = [
  {
    field: "Instrument Name/Scheme Name",
    title: "Instrument Name",
    align: "left",
    color: "",
  },
  {
    field: "Initial Purchase Date",
    title: "Initial Purchase Date",
    align: "left",
    color: "",
  },

  {
    field: "Quantity/Units",
    title: "Quantity",
  },

  {
    field: "Total Cost",
    title: "Invested Value",
  },
  {
    field: "Current Market Value",
    title: "Current Value",
  },
  {
    field: "Other Income",
    title: "Other Income",
  },
  {
    title: "Unrealised Gain/Loss",
    field: "Gain/Loss",
  },
  {
    field: "XIRR",
    title: "XIRR (%)",
  },
  {
    field: "Last Price Date",
    title: "Last Price Date",
  },
];

const newpdfHoldingsRealEstate = [
  {
    field: "schemeName",
    title: "Real Estate",
    align: "left",
    color: "",
    // customStyle: { minWidth: "322px" },
  },
  {
    field: "type",
    title: "Type",
    align: "left",
    color: "",
  },

  {
    field: "totalCost",
    title: "Invested Value",
    headerStyle: { width: "100%", whiteSpace: "nowrap" },
  },

  {
    field: "currentValue",
    title: "Current Value",
    headerStyle: { width: "100%", whiteSpace: "nowrap" },
  },
  {
    field: "otherIncome",
    title: "Rental Income",
  },
  // {
  //   field: "Quantity/Units",
  //   title: "Quantity",
  // },

  {
    title: "Unrealised Gain/Loss",
    field: "unRealisedGainLoss",
  },
  {
    field: "xirr",
    title: "XIRR (%)",
  },
  // { field: "asOnDate", title: "Valuation As On Date" },
];

const newpdfHoldingsInsuranceUlip = [
  {
    field: "schemeName",
    title: "Policy",
    align: "left",
    color: "",
    // customStyle: { minWidth: "322px" },
  },
  {
    field: "insType",
    title: "Type",
    align: "left",
    color: "",
  },

  {
    field: "premium",
    title: "Premium (₹)",
  },

  {
    field: "coverAmount",
    title: "Cover Amount (₹)",
  },

  {
    field: "xirr",
    title: "XIRR (%)",
  },
  // { field: "asOnDate", title: "Valuation As On Date" },
];
const newpdfHoldingsFundInTransit = [
  // {
  //   field: "pan",
  //   title: "PAN No",
  //   align: "left",
  //   color: "",
  // },
  {
    field: "member",
    title: "Member Name",
    align: "left",
  },

  {
    field: "productName",
    title: "Product Name",
    align: "left",
  },
  {
    field: "asOnDate",
    title: "Date",
    align: "left",
    // title: "Date 🡳",
    // headerStyle: { width: "fit-content", whiteSpace: "nowrap" },
  },

  {
    field: "amount",
    title: "Amount",
    align: "left",
  },
  {
    field: "narration",
    title: "Narration",
    align: "left",
  },
  // { field: "asOnDate", title: "Valuation As On Date" },
];
const newpdfHoldingsBankBook = [
  {
    field: "bankName",
    title: "Bank",
    align: "left",
    color: "",
  },
  {
    field: "accountNo",
    title: "Account No",
    align: "right",
    color: "",
  },

  {
    field: "accountType",
    title: "Account Type",
    align: "left",
  },

  {
    field: "balance",
    title: "Balance (₹)",
    align: "right",
  },
  // { field: "asOnDate", title: "Valuation As On Date" },
];
const newpdfHoldingSecurityBankBook = [
  {
    field: "schemeName",
    title: "Bank",
    align: "left",
    color: "",
  },
  {
    field: "accountNo",
    title: "Account No",
    align: "right",
    color: "",
  },

  {
    field: "accountType",
    title: "Account Type",
    align: "left",
  },

  {
    field: "currentValue",
    title: "Balance (₹)",
    align: "right",
  },
  // { field: "asOnDate", title: "Valuation As On Date" },
];
const newpdfHoldingsInsuranceNonUlip = [
  {
    field: "schemeName",
    title: "Policy",
    align: "left",
    color: "",
    // customStyle: { minWidth: "322px" },
  },
  {
    field: "insType",
    title: "Type",
    align: "left",
    color: "",
  },

  {
    field: "premium",
    title: "Premium (₹)",
  },

  {
    field: "coverAmount",
    title: "Cover Amount (₹)",
  },

  {
    field: "xirr",
    title: "XIRR (%)",
  },
  // { field: "asOnDate", title: "Valuation As On Date" },
];

const newpdfTransactions = [
  {
    field: "Transaction Date",
    title: "Transaction Date 🡳",
    align: "left",
    color: "",
    headerStyle: { width: "fit-content", whiteSpace: "nowrap" },
  },
  {
    field: "Member Name",
    title: "Member Name",
  },
  {
    title: "Instrument Name",
    field: "Instrument Name/Scheme Name ",
    align: "left",
  },
  {
    field: "Transaction Type",
    title: "Transaction type",
    align: "left",
  },
  {
    field: "Quantity/Units",
    title: "Quantity",
  },

  {
    field: "Price",
    title: "Price (₹)",
  },
  {
    field: "Total Transaction Value",
    title: "Transaction Value",
  },

  // {
  //   field: "xirr",
  //   title: "XIRR (%)",
  // },
  // {
  //   field: "lastPriceDate",
  //   title: "Last Price Date",
  // },
];
const familyholding = [
  {
    field: "memberName",
    title: "Member Name",
    align: "left",
    color: "",
    headerStyle: { width: "fit-content", whiteSpace: "nowrap" },
  },
  {
    field: "equity",
    title: "Equity",
    align: "left",
  },
  {
    title: "Debt",
    field: "debt",
    align: "left",
  },
  {
    field: "alternate",
    title: "Alternates",
    align: "left",
  },
  {
    field: "cash",
    title: "Cash",
  },

  {
    field: "totalPortfolioValue",
    title: "TOTAL",
  },
];
const RealisedMemberholding = [
  {
    field: "memberName",
    title: "Members",
    align: "left",
    color: "",
    headerStyle: { width: "fit-content", whiteSpace: "nowrap" },
  },
  {
    field: "equity",
    title: "Equity",
    align: "right",
  },
  {
    title: "Debt",
    field: "debt",
    align: "right",
  },
  {
    field: "alternate",
    title: "Alternates",
    align: "right",
  },
  {
    field: "cash",
    title: "Cash",
  },

  {
    field: "total",
    title: "TOTAL",
  },
];
const IncomeMemberholding = [
  {
    field: "memberName",
    title: "Members",
    align: "left",
    color: "",
    headerStyle: { width: "fit-content", whiteSpace: "nowrap" },
  },
  {
    field: "Statement Of Dividend",
    title: "DIVIDEND",
    align: "right",
  },
  {
    title: "INTEREST",
    field: "Statement Of Interest",
    align: "right",
  },
  {
    field: "Statement Of Other Income",
    title: "OTHER INCOME",
    align: "right",
  },
  // {
  //   field: "PRINCIPAL BUYBACK",
  //   title: "PRINCIPAL BUYBACK",
  // },

  {
    field: "total",
    title: "TOTAL",
  },
];
const newpdfTransactionsv1 = [
  {
    field: "transactionDate",
    title: "Transaction Date 🡳",
    align: "left",
    color: "",
    headerStyle: { width: "fit-content", whiteSpace: "nowrap" },
  },
  // {
  //   field: "memberName",
  //   title: "Member Name",
  // },
  {
    title: "Instrument",
    field: "schemeName",
    align: "left",
  },
  {
    field: "transactionType",
    title: "Transaction Type",
    align: "left",
  },
  {
    field: "units",
    title: "Quantity",
  },

  {
    field: "price",
    title: "Price (Actuals)",
  },
  {
    field: "transactionValue",
    title: "Transaction Value",
  },
];
const newpdfTransactionsFundsInTransit = [
  // {
  //   field: "pan",
  //   title: "PAN No",
  //   align: "left",
  //   color: "",
  // },
  {
    field: "member",
    title: "Member Name",
    align: "left",
  },

  {
    field: "productName",
    title: "Product Name",
    align: "left",
  },
  {
    field: "transactionDate",
    title: "Date",
    // title: "Date 🡳",
    // headerStyle: { width: "fit-content", whiteSpace: "nowrap" },
  },

  {
    field: "transactionValue",
    title: "Amount",
  },
  {
    field: "narration",
    title: "Narration",
    align: "left",
  },
];

const newpdfTransactionsRealEstate = [
  {
    field: "transactionDate",
    title: "Transaction Date 🡳",
    align: "left",
    color: "",
    headerStyle: { width: "fit-content", whiteSpace: "nowrap" },
  },
  {
    field: "schemeName",
    title: "Property",
    align: "left",
  },

  {
    field: "transactionType",
    title: "Transaction Type",
    align: "left",
  },
  {
    field: "transactionValue",
    title: "Transaction Value (₹)",
  },
];
const newpdfTransactionsInsurance = [
  {
    field: "transactionDate",
    title: "Transaction Date 🡳",
    align: "left",
    color: "",
    headerStyle: { width: "fit-content", whiteSpace: "nowrap" },
  },
  {
    field: "schemeName",
    title: "Policy",
    align: "left",
  },

  {
    field: "insType",
    title: "Type",
    align: "left",
  },
  {
    field: "subType",
    title: "Sub Type",
    align: "left",
  },
  {
    field: "transactionType",
    title: "Transaction Type",
    align: "left",
  },

  {
    field: "transactionValue",
    title: "Amount",
  },
];
const assetAllocationModel = [
  {
    field: "assetClass",
    title: "Asset Class",
    align: "left",
    color: "",
  },
  {
    title: "Current Value (₹)",
    field: "portfolioValueAmount",
    align: "left",
    color: "",
  },
  {
    title: "Weightage (%)",
    align: "right",
    field: "percentageOfTotalPortfolio",
  },

  // {
  //   field: "xirr",
  //   title: "XIRR (%)",
  // },
  // {
  //   field: "lastPriceDate",
  //   title: "Last Price Date",
  // },
];
const top5amc = [
  {
    field: "amc",
    title: "AMC Name",
    align: "left",
    color: "",
  },
  {
    title: "Current Value (₹)",
    field: "valuation",
    align: "left",
    color: "",
  },
  {
    title: "Weightage (%)",
    align: "right",
    field: "percentage",
  },
];
const top5issuer = [
  {
    field: "issuer",
    title: "ISSUER NAME",
    align: "left",
    color: "",
  },
  {
    title: "Current Value (₹)",
    field: "valuation",
    align: "left",
    color: "",
  },
  {
    title: "Weightage (%)",
    align: "right",
    field: "percentage",
  },
];
const ProductAllocationModel = [
  {
    field: "product",
    title: "Product Name",
    align: "left",
    color: "",
  },
  {
    title: "Current Value (₹)",
    field: "currentValue",
    align: "left",
    color: "",
  },
  {
    title: "Weightage (%)",
    align: "right",
    field: "weightage",
  },
];
const CreditRatingModel = [
  {
    field: "bondRating",
    title: "Credit Rating",
    align: "left",
    color: "",
  },
  {
    title: "NO of Instruments",
    field: "noOfInstruments",
    align: "center",
    color: "",
  },
  {
    title: "Current Value (₹) ",
    align: "right",
    field: "bondRatingValue",
  },
  {
    title: "Weightage (%) ",
    align: "right",
    field: "bondRatingPercentage",
  },
];
const EquitySectorModel = [
  {
    field: "type",
    title: "Sector Name",
    align: "left",
    color: "",
  },
  {
    title: "NO of Instruments",
    field: "noOfInstruments",
    align: "center",
    color: "",
  },
  {
    title: "Current Value (₹) ",
    align: "right",
    field: "portfolioValueAmount",
  },
  {
    title: "Weightage (%) ",
    align: "right",
    field: "portfolioValueAmountPercentage",
  },
  {
    title: "XIRR (%) ",
    align: "right",
    field: "xirr",
  },
];
const SubCategoryModel = [
  {
    field: "productSubCategory",
    title: "Sub-Category",
    align: "left",
    color: "",
  },
  {
    title: "NO of Instruments",
    field: "noOfInstruments",
    align: "center",
    color: "",
  },
  {
    title: "Current Value (₹) ",
    align: "right",
    field: "presentValue",
  },
  {
    title: "Weightage (%) ",
    align: "right",
    field: "presentValuePercentage",
  },
  {
    title: "XIRR (%) ",
    align: "right",
    field: "xirr",
  },
];
const MarketCapModel = [
  {
    field: "type",
    title: "Sector Name",
    align: "left",
    color: "",
  },
  {
    title: "NO of Instruments",
    field: "noOfInstruments",
    align: "center",
    color: "",
  },
  {
    title: "Current Value (₹) ",
    align: "right",
    field: "portfolioValueAmount",
  },
  {
    title: "Weightage (%) ",
    align: "right",
    field: "portfolioValueAmountPercentage",
  },
  {
    title: "XIRR (%) ",
    align: "right",
    field: "xirr",
  },
];

const engagmentModel = [
  {
    field: "name",
    title: " ",
    align: "left",
    color: "",
  },
  {
    title: "Invested Value",
    field: "investedValue",
    align: "left",
    color: "",
  },
  {
    title: "Current  Value",
    field: "currentPortfolioValue",
  },
  {
    title: "Total Gain/Loss",
    field: "totalGainLoss",
  },
  {
    field: "xirr",
    title: "XIRR",
  },
  {
    field: "absoluteTwrr",
    title: "Absolute TWRR",
  },

  {
    field: "annualizedTwrr",
    title: "Annualized TWRR",
  },

  // {
  //   field: "xirr",
  //   title: "XIRR (%)",
  // },
  // {
  //   field: "lastPriceDate",
  //   title: "Last Price Date",
  // },
];
const advisorModel = [
  {
    field: "advisor",
    title: "Advisor Name",
    align: "left",
    color: "",
  },
  {
    title: "Allocation (%)",
    field: "totalPortfolioPercentage",
    align: "left",
    color: "",
  },
  {
    title: "Invested Value (₹)",
    field: "investedAmount",
  },
  {
    title: "Current Value (₹)",
    field: "portfolioValueAmount",
  },
  {
    field: "interestIncome",
    title: "Other Income (₹)",
  },
  {
    field: "unRealisedGainLoss",
    title: "Unrealised Gain/Loss (₹)",
  },

  {
    field: "xirr",
    title: "XIRR(%)",
  },
];
const MemberModel = [
  {
    field: "memberName",
    title: "Member",
    align: "left",
    color: "",
  },
  {
    title: "Allocation (%) ",
    field: "allocation",
    align: "right",
    color: "",
  },
  {
    title: "Invested Value ",
    field: "investmentValue",
  },
  {
    title: "Current Value ",
    field: "currentValue",
  },
  {
    field: "otherIncome",
    title: "Other Income ",
  },
  {
    field: "unRealisedGainLoss",
    title: "Unrealised Gain/Loss",
  },

  {
    field: "xirr",
    title: "XIRR (%)",
  },

  // {
  //   field: "xirr",
  //   title: "XIRR (%)",
  // },
  // {
  //   field: "lastPriceDate",
  //   title: "Last Price Date",
  // },
];

const TaxModel = [
  {
    field: "instrumentName",
    title: "Instrument Name ISIN",
    align: "left",
    color: "",
  },
  {
    title: "Sale Date ",
    field: "saleDate",
    align: "left",
    color: "",
  },
  {
    title: "Quantity",
    field: "quantity",
    align: "left",
  },
  {
    title: "Sale Rate ",
    field: "saleRate",
    align: "left",
  },
  {
    title: "Sale Amount ",
    field: "saleAmount",
    align: "left",
  },
  {
    field: "purchaseDate",
    title: "Purchase Date",
    align: "left",
  },
  {
    field: "purchaseRate",
    title: "Purchase Rate",
    align: "left",
  },

  {
    field: "purchaseAmount",
    title: "Purchase Amount",
    align: "left",
  },
  {
    field: "holdingPeriod",
    title: "Days Held",
    align: "left",
  },

  {
    field: "stcg",
    title: "ST",
    align: "left",
  },
  {
    field: "ltcg",
    title: "LT",
    align: "left",
  },

  // {
  //   field: "xirr",
  //   title: "XIRR (%)",
  // },
  // {
  //   field: "lastPriceDate",
  //   title: "Last Price Date",
  // },
];
const UnrealisedGainlossModel = [
  {
    field: "instrumentName",
    title: "Instrument Name ISIN",
    align: "left",
    color: "",
  },
  {
    title: "Sale Date ",
    field: "saleDate",
    align: "left",
    color: "",
  },
  {
    title: "Quantity",
    field: "quantity",
    align: "left",
  },
  {
    title: "Sale Rate ",
    field: "saleRate",
    align: "left",
  },
  {
    title: "Sale Amount ",
    field: "saleAmount",
    align: "left",
  },
  {
    field: "purchaseDate",
    title: "Purchase Date",
    align: "left",
  },
  {
    field: "purchaseRate",
    title: "Purchase Rate",
    align: "left",
  },

  {
    field: "purchaseAmount",
    title: "Purchase Amount",
    align: "left",
  },

  {
    field: "realisedGainLoss",
    title: "Realised Gain/Loss",
    align: "left",
  },

  // {
  //   field: "xirr",
  //   title: "XIRR (%)",
  // },
  // {
  //   field: "lastPriceDate",
  //   title: "Last Price Date",
  // },
];
const commonIncomeModel = [
  {
    field: "schemeName",
    title: "Scheme Name ISIN",
    align: "left",
    color: "",
  },
  {
    title: "Product ",
    field: "productName",
    align: "right",
    color: "",
  },
  {
    field: "transactionDate",
    title: "Date",
    align: "right",
  },
  {
    title: "Type",
    field: "transactionType",
    align: "right",
  },
  {
    title: "Quantity",
    field: "quantity",
    align: "right",
  },
  {
    title: "Amount ",
    field: "amount",
    align: "right",
  },
];
const IncomeModel = [
  ...commonIncomeModel,
  {
    title: "Total Amount ",
    field: "totalAmount",
    align: "right",
  },
];
const PrinciplePaybackIncomeModel = [
  ...commonIncomeModel,
  {
    title: "Current FV ",
    field: "currentFaceValue",
    align: "right",
  },
  {
    title: "Total Amount ",
    field: "totalAmount",
    align: "right",
  },
];
const PmsUnderlyimgModel = [
  {
    field: "transaction_date",
    title: "Transaction date",
    align: "left",
    color: "",
  },
  {
    field: "isin_name",
    title: "Instrument Name",
    align: "left",
    color: "",
  },
  // {
  //   field: "Folio_no",
  //   title: "Folio No",
  //   align: "left",
  //   color: "",
  // },
  {
    field: "transaction_type",
    title: "Transaction Type",
    align: "left",
    color: "",
  },
  {
    field: "units",
    title: "Quantity",
    align: "left",
    color: "",
  },
  {
    field: "price",
    title: "Price",
    align: "right",
    color: "",
  },
  {
    field: "amount",
    title: "Transaction Value",
    align: "right",
    color: "",
  },
];
const AssetSummaryModel = [
  {
    field: "assetClass",
    title: "Asset Class",
    align: "left",
    color: "",
  },
  {
    title: "Allocation (%) ",
    field: "percentageOfTotalPortfolio",
    align: "right",
    color: "",
  },
  {
    title: "Invested Value (₹)",
    field: "investedAmount",
  },
  {
    title: "Current Value (₹)",
    field: "portfolioValueAmount",
  },

  {
    field: "unRealisedGainLoss",
    title: "Unrealised Gain/Loss (₹)",
  },
  {
    field: "interestIncome",
    title: "Other Income (₹)",
  },
  {
    field: "xirr",
    title: "XIRR (%)",
  },

  // {
  //   field: "xirr",
  //   title: "XIRR (%)",
  // },
  // {
  //   field: "lastPriceDate",
  //   title: "Last Price Date",
  // },
];
const newProductAllocationModel = [
  {
    field: "assetClass",
    title: "Product",
    align: "left",
    color: "",
  },
  {
    title: "Allocation (%) ",
    field: "percentageOfTotalPortfolio",
    align: "right",
    color: "",
  },
  {
    title: "Invested Value (₹)",
    field: "investedAmount",
  },
  {
    title: "Current Value (₹)",
    field: "portfolioValueAmount",
  },

  {
    field: "unRealisedGainLoss",
    title: "Unrealised Gain/Loss (₹)",
  },
  {
    field: "interestIncome",
    title: "Other Income (₹)",
  },
  {
    field: "xirr",
    title: "XIRR (%)",
  },

  // {
  //   field: "xirr",
  //   title: "XIRR (%)",
  // },
  // {
  //   field: "lastPriceDate",
  //   title: "Last Price Date",
  // },
];
const newMemberProductAllocationModel = [
  {
    field: "product",
    title: "Product",
    align: "left",
    color: "",
  },
  {
    title: "Allocation (%) ",
    field: "weightage",
    align: "right",
    color: "",
  },
  {
    title: "Invested Value (₹)",
    field: "investmentValue",
  },
  {
    title: "Current Value (₹)",
    field: "currentValue",
  },

  {
    field: "unRealisedGainLoss",
    title: "Unrealised Gain/Loss (₹)",
  },
  {
    field: "otherIncome",
    title: "Other Income (₹)",
  },
  {
    field: "xirr",
    title: "XIRR (%)",
  },
];
const corpusTableHeader = [
  {
    field: "assetClass",
    title: "Asset Category",
    align: "left",
    color: "",
  },
  {
    field: "portfolioValueAmount",
    title: `Amount 
    Since Last Month`,
  },
  {
    field: "investedAmount",
    title: `Amount* 
    Financial year till Date`,
  },
  {
    field: "totalGainLoss",
    title: `Amount**
    Since Inception`,
  },
];

const corpusTableHeader2 = [
  {
    field: "assetClass",
    title: "Income and Expenses",
    align: "left",
    color: "",
  },
  {
    field: "portfolioValueAmount",
    title: `Amount 
    Since Last Month`,
  },
  {
    field: "investedAmount",
    title: `Amount* 
    Financial year till Date`,
  },
  {
    field: "totalGainLoss",
    title: `Amount**
    Since Inception`,
  },
];

const riskProfilwHeader = [
  {
    field: "assetClass",
    title: "Asset",
    align: "left",
    color: "",
  },
  {
    field: "portfolioValueAmount",
    title: `Actual %`,
  },
  {
    field: "investedAmount",
    title: `Limit %`,
  },
  {
    field: "totalGainLoss",
    title: `Indicator`,
  },
];

const drawdownHeaderData = [
  {
    field: "schemeName",
    title: "Particular Segment Value",
    align: "left",
    color: "",
  },
  {
    field: "lastDrawdownDate",
    title: "Last Drawdown Date",
    align: "right",
  },
  {
    field: "commitedAmount",
    title: "Committed Amount",
    align: "right",
  },
  {
    field: "drawdownAmount",
    title: "Drawdown Amount",
    align: "right",
  },
  {
    field: "pendingDrawdown",
    title: "Pending Drawdown",
    align: "right",
  },
  {
    field: "totalInvestmentValue",
    title: "Invested Value",
    align: "right",
  },
  {
    field: "totalPresentValue",
    title: "Current Value",
    align: "center",
  },
  { field: "totalGainLoss", title: "Total Gain loss", align: "center" },
];

module.exports = {
  newpdfTransactions,
  newpdfTransactionsFundsInTransit,
  stbodyData,
  stBodyEntityData,
  stHeaderData,
  sdTotalData,
  headerData,
  portfolioAllocationHeader,
  EquityMFHeader,
  EquityMFHolding,
  currencyIn,
  corpusTableHeader,
  riskProfilwHeader,
  corpusTableHeader2,
  drawdownHeaderData,
  newpdfHolding,
  engagmentModel,
  newPdfSecurityHolding,
  assetAllocationModel,
  top5amc,
  top5issuer,
  MemberModel,
  ProductAllocationModel,
  ClientHolding,
  advisorModel,
  newpdfTransactionsv1,
  newpdfHoldings,
  newpdfTransactionsRealEstate,
  newpdfTransactionsInsurance,
  newpdfHoldingsRealEstate,
  newpdfHoldingsInsuranceUlip,
  newpdfHoldingsInsuranceNonUlip,
  newAifPdfHoldings,
  newDirectEquityPdfHoldings,
  newpdfHoldingsBankBook,
  newpdfHoldingsFundInTransit,
  newpdClientfHoldings,
  CreditRatingModel,
  EquitySectorModel,
  MarketCapModel,
  SubCategoryModel,
  AssetSummaryModel,
  newProductAllocationModel,
  newMemberProductAllocationModel,
  TaxModel,
  newpdfSecurityHoldings,
  newpdfHoldingSecurityBankBook,
  newPmsPdfHoldings,
  familyholding,
  newFDRDPMSPdfHoldings,
  PmsUnderlyimgModel,
  RealisedMemberholding,
  UnrealisedGainlossModel,
  IncomeModel,
  IncomeMemberholding,
  PrinciplePaybackIncomeModel,
};
