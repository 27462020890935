import React from "react";
import TaxModule from "./TaxModule";

export default function TaxSort({
  newdata,
  denomination,
  pdfInfo,
  header,
  headers,
  header2,
  model,
  type,
  title,
  infoText,
  setChanges,
}) {
  function addOnTotal(product) {
    if (type == "unrealised") {
      return "Total - " + product;
    } else {
      return undefined;
    }
  }
  function addOnGrandTotal(member, t) {
    if (type == "unrealised" && pdfInfo.processing_format == "productWise") {
      return "Grand Total - " + member;
    } else if (type == "unrealised" && t != "Grand Total") {
      return "Sub Total - " + member;
    } else if (type == "unrealised" && t == "Grand Total") {
      return "Grand Total - " + member;
    } else {
      return undefined;
    }
  }

  function pdfSort() {
    const breadcrumbs = [
      <a key="1" color="#000000" className="printBreadsel">
        {title || "Tax Summary"}
      </a>,
    ];

    let asset =
      newdata && Object.keys(newdata?.["asset-wise-product-sort-order"] || {});
    let arr = [];

    if (pdfInfo.processing_format == "productWise" && asset) {
      console.log("prod ssd");
      newdata?.["member-wise-modules-data"].productWiseMemberData?.map(
        (member) => {
          let list =
            member?.data?.products && Object.keys(member?.data?.products);
          let count = 0;
          // if (member.memberName == "Divita Juneja") {
          //   console.log(list, "pdfinfo");
          // }

          list?.map((product) => {
            let prodarr = member?.data?.products?.[product]?.holdings;
            let total = member?.data?.products?.[product]?.total;
            let bread = [...breadcrumbs];

            bread.push(
              <a
                underline="hover"
                key="2"
                color="
                  #000000"
              >
                <span className="opc80">Product :</span>
                <span className="pdfselectedTxt">{product}</span>
              </a>
            );
            prodarr &&
              (prodarr[prodarr.length - 1]?.instrumentName != "Total" ||
                prodarr[prodarr.length - 2]?.instrumentName != "Total") &&
              prodarr.push({
                isin: "",

                saleDate: "--",
                quantity: "--",
                saleRate: "--",
                saleAmount: "--",
                purchaseDate: "--",
                purchaseRate: "--",
                purchaseAmount: "--",
                holdingPeriod: "--",

                instrumentName: "Total",
                isin: "Total",
                realisedGainLoss: total.productTotalRealisedGainLoss,
                addOn: addOnTotal(product),
                ltcg: total.productTotalLtcg,
                stcg: total.productTotalStcg,
              });
            if (prodarr) {
              count = count + 1;
              if (count == list.length) {
                prodarr.push({
                  saleDate: "--",
                  quantity: "--",
                  saleRate: "--",
                  saleAmount: "--",
                  purchaseDate: "--",
                  purchaseRate: "--",
                  purchaseAmount: "--",
                  holdingPeriod: "--",

                  NoOfTransection:
                    member?.data?.overview.overAllMemberNoOfTransactions,
                  instrumentName: "Grand Total",
                  isin: "",
                  realisedGainLoss:
                    member?.data?.overview?.overAllMemberTotalRealisedGainLoss,
                  addOn: addOnGrandTotal(member.memberName),

                  ltcg: member?.data?.overview.overAllMemberTotalLtcg,
                  stcg: member?.data?.overview.overAllMemberTotalStcg,
                });
              }
            }

            arr.push(
              <TaxModule
                infoText={infoText}
                model={model}
                headers={headers}
                header2={header2}
                setChanges={setChanges}
                breadcrumbs={bread}
                memberName={member.memberName}
                denomination={denomination}
                newdata={prodarr}
                subtext={""}
                product={product}
                pdfInfo={pdfInfo}
              />
            );
          });
        }
      );
    } else {
      if (asset) {
        newdata?.["member-wise-modules-data"]?.assetWiseMemberData?.map(
          (member) => {
            let assetLen = Object.keys(member?.data?.assets || {})?.length;
            asset.map((a, i) => {
              let list =
                member?.data?.assets?.[a]?.holdingSummary &&
                Object.keys(member?.data?.assets?.[a]?.holdingSummary);
              let count = 0;
              newdata?.["asset-wise-product-sort-order"][a]?.map((product) => {
                let prodarr =
                  member?.data?.assets?.[a]?.holdingSummary?.[product]?.data
                    ?.holdings;
                let total =
                  member?.data?.assets?.[a]?.holdingSummary?.[product]?.data
                    ?.total;
                let bread = [...breadcrumbs];
                bread.push(
                  <a
                    underline="hover"
                    key="2"
                    color="
              #000000"
                  >
                    <span className="opc80"> Asset Class :</span>
                    <span className="pdfselectedTxt pdfCaps">{a}</span>
                  </a>
                );

                bread.push(
                  <a
                    underline="hover"
                    key="2"
                    color="
                  #000000"
                  >
                    <span className="opc80">Product :</span>
                    <span className="pdfselectedTxt">{product}</span>
                  </a>
                );
                prodarr &&
                  prodarr.push({
                    isin: "",

                    saleDate: "--",
                    quantity: "--",
                    saleRate: "--",
                    saleAmount: "--",
                    purchaseDate: "--",
                    purchaseRate: "--",
                    purchaseAmount: "--",
                    holdingPeriod: "--",
                    addOn: addOnTotal(product),
                    instrumentName: "Total",
                    isin: "Total",
                    ltcg: total.productTotalLtcg,
                    realisedGainLoss: total.productTotalRealisedGainLoss,
                    stcg: total.productTotalStcg,
                  });
                if (prodarr) {
                  count = count + 1;
                  if (count == list.length) {
                    prodarr.push({
                      saleDate: "--",
                      quantity: "--",
                      saleRate: "--",
                      saleAmount: "--",
                      purchaseDate: "--",
                      purchaseRate: "--",
                      purchaseAmount: "--",
                      holdingPeriod: "--",
                      NoOfTransection:
                        member?.data?.assets?.[a]?.overAllAssetNoOfTransactions,
                      instrumentName: "Grand Total",
                      addOn: addOnGrandTotal(a),
                      realisedGainLoss:
                        member?.data?.assets?.[a]
                          ?.overAllAssetTotalRealisedGainLoss,
                      isin: "",
                      ltcg: member?.data?.assets?.[a]?.overAllAssetTotalLtcg,
                      stcg: member?.data?.assets?.[a]?.overAllAssetTotalStcg,
                    });
                    if (assetLen == i + 1 && type == "unrealised") {
                      prodarr.push({
                        saleDate: "--",
                        quantity: "--",
                        saleRate: "--",
                        saleAmount: "--",
                        purchaseDate: "--",
                        purchaseRate: "--",
                        purchaseAmount: "--",
                        holdingPeriod: "--",
                        NoOfTransection:
                          member?.data?.overview?.overAllMemberNoOfTransactions,
                        instrumentName: "Grand Total",
                        addOn: addOnGrandTotal(
                          member.memberName,
                          "Grand Total"
                        ),
                        realisedGainLoss:
                          member?.data?.overview
                            ?.overAllMemberTotalRealisedGainLoss,
                        isin: "",
                      });
                    }
                  }
                }

                arr.push(
                  <TaxModule
                    infoText={infoText}
                    model={model}
                    headers={headers}
                    header2={header2}
                    setChanges={setChanges}
                    breadcrumbs={bread}
                    memberName={member.memberName}
                    denomination={denomination}
                    newdata={prodarr}
                    subtext={""}
                    product={product}
                    pdfInfo={pdfInfo}
                  />
                );
              });
            });
          }
        );
        // console.log(arr, "arr");
      }
    }
    return arr;
  }

  return <div>{newdata && pdfSort(newdata)}</div>;
}
