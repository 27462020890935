import React from 'react'
import Default from '../components/dashboard/defaultPages/Default'
import MfoSummaryComp from '../components/dashboard/products/mfo/MfoSummaryComp'

function MfoSummary() {

    return (
        <>
            <Default>
                <MfoSummaryComp></MfoSummaryComp>
            </Default>
        </>
    )
}

export default MfoSummary