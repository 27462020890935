import { makeStyles } from "@material-ui/core/styles";

export const useClientMappingStyles = makeStyles({
  defaultCard: {
    width: "266px",
    minHeight: "116px",
    borderRadius: "6px",
  },
  placeholderImg: {
    width: "63px",
    height: "63px",
    borderRadius: "50%",
  },
  defaultCardText: {
    color: "#FFF",
    fontFamily: "Avenir",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "32px",
    marginLeft: "6px", // to align the text vertically with the chip
  },
  cardChip: {
    color: "#fff",
    fontFamily: '"Avenir"',
    fontSize: "12px",
    fontWeight: 350,
    lineHeight: "22px",
    background: "#21163e",
  },
  addCard: {
    width: "388px",
    minHeight: "116px",
    background:
      "linear-gradient(147deg, #2B0D58 0%, #692FC0 88.87%, #692FC0 100%)",
    borderRadius: "6px",
  },
  addCardText: {
    color: "#FFF",
    fontFamily: "Avenir",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "22px",
    marginBottom: "8px",
  },
  addCardSubText: {
    color: "#FFF",
    fontFamily: "Avenir",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 350,
    lineHeight: "18px",
  },

  cardContentWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "116px",
    margin: 0,
    gap: "20px",
  },
  mapWithCard: {
    width: "285px",
    minHeight: "135px",
    borderRadius: "6px",
    background: "#250E4A",
    margin: "0px",
    cursor: "pointer",
  },

  mapWithCardSelected: {
    width: "285px",
    minHeight: "135px",
    borderRadius: "6px",
    background: "#250E4A",
    border: "4px solid #56358C",
    margin: "0px",
    cursor: "pointer",
  },
  mapWithCardText: {
    color: "#FFF",
    fontFamily: "Avenir",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "22px",
    marginBottom: "8px",
  },
  mapWithCardSubText: {
    color: "#FFF",
    fontFamily: "Avenir",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 350,
    lineHeight: "18px",
    opacity: 0.7,
  },
  mapWithCardContentWrapper: {
    padding: "16px",
    marginTop: "12px",
  },
  cardsAlignment: {
    marginTop: "16px",
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },
  dividerText: {
    color: "#FFF",
    fontFamily: "Avenir",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "2.24px",
    textTransform: "uppercase",
    opacity: "0.5",
    marginRight: "10px",
  },
});
