import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import PrintTable from "../Components/Table";
import {
  objectFormatingPrint,
  objectFormatingWithTitleCOlWrapper,
} from "../Components/utils/common";
import { gorupRows } from "../Components/utils/constands";
import PageContainer from "../PageContainer";
import HandlePageBreak from "../Components/HandlePageBreak";
import { drawdownHeaderData as headerData } from "../Components/utils/staticData";

// const headerData = [
//   {
//     field: "schemeName",
//     title: "Particular Segment Value",
//     align: "left",
//     color: "",
//   },
//   {
//     field: "lastDrawdownDate",
//     title: "Last Drawdown Date",
//     align: "right",
//   },
//   {
//     field: "commitedAmount",
//     title: "Committed Amount",
//     align: "right",
//   },
//   {
//     field: "drawdownAmount",
//     title: "Drawdown Amount",
//     align: "right",
//   },
//   {
//     field: "pendingDrawdown",
//     title: "Pending Drawdown",
//     align: "right",
//   },
//   {
//     field: "totalInvestmentValue",
//     title: "Invested Value",
//     align: "right",
//   },
//   {
//     field: "totalPresentValue",
//     title: "CUrrent Value",
//     align: "center",
//   },
//   { title: "Total Gain loss", field: "totalGainLoss", align: "center" },
// ];

function PerformanceSummary1v2({
  title,
  hideTotalRow,
  headerType,
  title2,
  subtext,
  customData,
  loading,
  setChanges,
  denomination,
  pdfInfo,
}) {
  const [data, setData] = useState([]);

  const dataProdKey = (data, keys) => {
    let newData = {};
    let total = {};

    keys.map((product) => {
      let a = {
        [product]: data?.["drawdown-summary-" + `${product}`]?.data,
      };
      let totalOb;

      totalOb = {
        schemeName: product,
        ...data?.[`drawdown-summary-${product}`]?.overallData,
      };

      total = {
        ...total,
        [product]: totalOb,
      };

      newData = {
        ...newData,
        [product]: data?.[`drawdown-summary-${product}`]?.data,
      };
      // if (product == "debt") {
      //   let obj = {
      //     overallData: {
      //       commitedAmount: 62635000,
      //       drawdownAmount: 62635000,
      //       pendingDrawdown: 0,
      //       totalGainLoss: -3525000,
      //       totalPresentValue: 59110000,
      //       totalInvestmentValue: 62635000,
      //     },
      //     data: [
      //       {
      //         assetClass: "debt",
      //         schemeName:
      //           "Edelweiss Stressed and troubled assets revival fund (Edelweiss Star)",
      //         lastDrawdownDate: "2014-09-26",
      //         commitedAmount: 15000000,
      //         drawdownAmount: 15000000,
      //         pendingDrawdown: 0,
      //         totalGainLoss: -13853000,
      //         totalPresentValue: 1147000,
      //         totalInvestmentValue: 15000000,
      //       },
      //       {
      //         assetClass: "debt",
      //         schemeName: "Edelweiss credit opportunities fund",
      //         lastDrawdownDate: "2017-03-31",
      //         commitedAmount: 10759000,
      //         drawdownAmount: 10759000,
      //         pendingDrawdown: 0,
      //         totalGainLoss: -442000,
      //         totalPresentValue: 10317000,
      //         totalInvestmentValue: 10759000,
      //       },
      //       {
      //         assetClass: "debt",
      //         schemeName: "Edelweiss Infrastructure yield plus class B1",
      //         lastDrawdownDate: "2018-03-31",
      //         commitedAmount: 26876000,
      //         drawdownAmount: 26876000,
      //         pendingDrawdown: 0,
      //         totalGainLoss: 5442000,
      //         totalPresentValue: 32318000,
      //         totalInvestmentValue: 26876000,
      //       },
      //       {
      //         assetClass: "debt",
      //         schemeName: "Carmelian Capital compounder Fund I Class C",
      //         lastDrawdownDate: "2020-02-27",
      //         commitedAmount: 10000000,
      //         drawdownAmount: 10000000,
      //         pendingDrawdown: 0,
      //         totalGainLoss: 5328000,
      //         totalPresentValue: 15328000,
      //         totalInvestmentValue: 10000000,
      //       },
      //     ],
      //   };
      //   totalOb = {
      //     schemeName: product,
      //     ...obj?.overallData,
      //   };
      //   total = {
      //     ...total,
      //     [product]: totalOb,
      //   };

      //   newData = {
      //     ...newData,
      //     [product]: obj?.data,
      //   };
      // }
    });
    // console.log(newData, "newData");
    return { data: newData, total: total };
  };

  const handleData = () => {
    let bodyData = dataProdKey(customData, ["equity", "alternate", "debt"]);
    let found = ["equity", "cash", "alternate", "debt"].find((asset) => {
      if (bodyData?.data?.[asset]?.length > 0) {
        return true;
      }
    });
    if (!found) {
      return;
    }

    let a = objectFormatingWithTitleCOlWrapper(
      bodyData?.data,
      headerData,
      [],
      "#ADC371",
      bodyData?.total,
      null,
      undefined,
      denomination,
      "schemeName"
      // sdTotalData
    );

    setData(() => [a]);
  };

  useEffect(() => {
    // console.log(customData, "custom data");
    handleData();
  }, [customData]);

  return (
    <>
      {/* {console.log(data.length, data, "data ")} */}
      {data && data.length != 0 && customData && (
        <HandlePageBreak
          customHeader={
            <>
              <Header
                title={
                  title ? (
                    title
                  ) : (
                    <>
                      Performance <br /> Summary-V2
                    </>
                  )
                }
                title2={title2 ? title2 : undefined}
                type={headerType ? headerType : 3}
                denomination={denomination}
                hideHr={true}
                subtext={subtext ? subtext : ""}
              ></Header>
            </>
          }
          pdfInfo={pdfInfo}
          setChanges={setChanges}
          headerData={headerData}
          customData={data}
          hidercircle={true}
          loading={false}
        />
      )}
    </>
  );
}
export default PerformanceSummary1v2;
