import React, { useEffect, useState } from "react";
import PageContainer from "../PageContainer";
import Header from "../Components/Header";
import PrintTable from "../Components/Table";
import ApexNew_print from "../Components/ApexNew_print";
import chartData from "../../../charts/chartData";
import { ReactComponent as Question } from "../images/question.svg";
import PieChart from "../../../charts/PieChart";
import Print_PieChart from "../Components/Print_PieChart";
import TabCards from "../Components/TabCards";
import { roundNumber } from "../../../utils/common";
import { date } from "yup/lib/locale";
import { currencyFormat } from "../../../../hooks/constantFunction";
import {
  assetAllocationModel,
  engagmentModel,
} from "../Components/utils/staticData";
import { objectFormatingWithTitleCOlWrapper } from "../Components/utils/common";
import CashflowTable from "../Components/cashflow_Comp/CashflowTable";
import { useMemo } from "react";
import "../Components/cashflow_Comp/cashflow.css";
import { Link } from "react-router-dom";
export default function CashFlowNew({ newdata, denomination, pdfInfo }) {
  const getDenomination = localStorage.getItem("denomination");

  // const colors = ["#152687", "#4BB3ED", "#4B64ED", "#BDC5F2"];
  // const [colormapped, setColorMapped] = useState({});
  const [cashflowsData, setCashflowsData] = useState([]);
  let allocationData = [
    {
      assetClass: "equity",
      investedAmount: 126422343.63708304,
      portfolioValueAmount: 209414687.90696117,
      withDrawalAmount: 256010792.57259998,
      realisedGainLoss: 36315640.5257671,
      unRealisedGainLoss: 63442776.23987812,
      totalGainLoss: 177285086.77414522,
      updatedAsOn: "29/02/2024",
      overAllInvestedAmount: null,
      interestIncome: null,
      dividendReinvestment: null,
      principalPayback: null,
      percentageOfTotalPortfolio: 0,
    },
    {
      assetClass: "debt",
      investedAmount: 1241537183.4908226,
      portfolioValueAmount: null,
      withDrawalAmount: 924352959.79,
      realisedGainLoss: 70511750.92747429,
      unRealisedGainLoss: 5353519772.718043,
      totalGainLoss: 5441033538.218043,
      updatedAsOn: "29/02/2024",
      overAllInvestedAmount: null,
      interestIncome: null,
      dividendReinvestment: null,
      principalPayback: null,
      percentageOfTotalPortfolio: 0,
    },
    {
      assetClass: "alternate",
      investedAmount: 49375451.78829998,
      portfolioValueAmount: 68451023.6964267,
      withDrawalAmount: 377717877.1,
      realisedGainLoss: 37848080.26690004,
      unRealisedGainLoss: 19075571.908126723,
      totalGainLoss: 19082157.325126722,
      updatedAsOn: "29/02/2024",
      overAllInvestedAmount: null,
      interestIncome: null,
      dividendReinvestment: null,
      principalPayback: null,
      percentageOfTotalPortfolio: 0,
    },
    {
      assetClass: "cash",
      investedAmount: 75988439.59915292,
      portfolioValueAmount: 12000,
      withDrawalAmount: 929194929.4362999,
      realisedGainLoss: 26632.653465400075,
      unRealisedGainLoss: -75976439.59915292,
      totalGainLoss: -75976439.59915292,
      updatedAsOn: "29/02/2024",
      overAllInvestedAmount: null,
      interestIncome: null,
      dividendReinvestment: null,
      percentageOfTotalPortfolio: 0,
    },
  ];

  const [data1, setData1] = useState([]);
  useEffect(() => {
    let dt = {
      advisor: [
        {
          openingBalance: 0,
          advisor: "bridgemonte",
          redemption: 1804941996.7788,
          principalPayback: 0,
          realisedGainLoss: 46710521.066600025,
          otherIncome: 0,
          purchase: 2399190603.800001,
          dividendReinvestment: 0,
          closingBalance: 795403742.9932121,
          net: 5046246864.638613,
        },
        {
          openingBalance: 0,
          advisor: "neo",
          redemption: 5599603877.0936,
          principalPayback: 1313182205.5,
          realisedGainLoss: 549187743.0651942,
          otherIncome: 5910479340.329488,
          purchase: 11378770553.349102,
          dividendReinvestment: 2982204.5,
          closingBalance: 62382376996.12802,
          net: 87136582919.96541,
        },
        {
          openingBalance: 0,
          advisor: "bridgemonte advisors",
          redemption: 76619393.69999999,
          principalPayback: 76619393.69999999,
          realisedGainLoss: 0,
          otherIncome: 56495080.48000001,
          purchase: 80000000,
          dividendReinvestment: 0,
          closingBalance: 39099136.06,
          net: 328833003.94,
        },
        {
          openingBalance: 0,
          advisor: "abc",
          redemption: 103490781.17,
          principalPayback: 0,
          realisedGainLoss: 30000000.000000007,
          otherIncome: 76500000.00000024,
          purchase: 402956978.77,
          dividendReinvestment: 0,
          closingBalance: 288186475.40983605,
          net: 901134235.3498363,
        },
        {
          openingBalance: 0,
          advisor: "edelweiss",
          redemption: 129916740,
          principalPayback: 3080,
          realisedGainLoss: 72368230,
          otherIncome: 2556905.4,
          purchase: 138725996.2612,
          dividendReinvestment: 2543063,
          closingBalance: 377842379.36,
          net: 723956394.0212001,
        },
      ],
      total: {
        openingBalance: 0,
        redemption: 7714572788.7424,
        principalPayback: 1389804679.2,
        realisedGainLoss: 698266494.1317942,
        otherIncome: 6046031326.209487,
        purchase: 14400103134.037304,
        dividendReinvestment: 5525267.5,
        closingBalance: 63884027845.55106,
        net: 94138331535.37206,
        advisor: "total",
      },
    };
    let cashflow = [...(newdata?.quarter ? newdata?.quarter : [])];
    if (newdata?.total) {
      cashflow.push(newdata?.total);
    }

    setCashflowsData(cashflow);
  }, [newdata]);

  const getRow = (rowName) => {
    if (cashflowsData.length > 0) {
      return cashflowsData?.map((item) => item[rowName]);
    }
    return [];
  };

  // Creating [][] for infows
  const inflows = useMemo(() => {
    let INFLOWS = [];
    if (cashflowsData.length > 0) {
      // INFLOWS.push(["Dividend Received", ...getRow("divident_paid")]);
      // INFLOWS.push(["Interest Received", ...getRow("interest_paid")]);
      // INFLOWS.push(["Other Income", ...getRow("other_income")]);
      // INFLOWS.push(["opening Balance", ...getRow("openingBalance")]);

      INFLOWS.push(["Redemption", ...getRow("redemption")]);
      INFLOWS.push(["Principal Payback", ...getRow("principalPayback")]);
      INFLOWS.push(["Realised Gain/Loss", ...getRow("realisedGainLoss")]);
      INFLOWS.push(["Other Income", ...getRow("otherIncome")]);
      return INFLOWS;
    }
    return [];
  }, [cashflowsData]);

  // Creating [][] for outflows
  const outflows = useMemo(() => {
    let OUTFLOWS = [];
    if (cashflowsData.length) {
      OUTFLOWS.push(["Purchase", ...getRow("purchase")]);
      OUTFLOWS.push([
        "Dividend Reinvestment",
        ...getRow("dividendReinvestment"),
      ]);
      OUTFLOWS.push(["Equalisation Paid", ...getRow("equalisationPaid")]);
      OUTFLOWS.push(["Other Charges", ...getRow("otherCharges")]);
      // OUTFLOWS.push(["Closing Balance", ...getRow("closingBalance")]);
      return OUTFLOWS;
    }
    return [];
  }, [cashflowsData]);
  let bread = [
    <Link
      key="1"
      color="#000000"
      className="printBreadsel fontFamilyAvBold f16"
    >
      Cashflows
    </Link>,
    <div>
      {newdata?.memberName != "Consolidated" ? (
        <span className="f14" style={{ opacity: "80%" }}>
          Member Name :{" "}
        </span>
      ) : (
        ""
      )}
      <Link key="1" color="#000000" className="printBreadsel f14 ">
        {newdata?.memberName || "Consolidated"}
      </Link>
    </div>,
  ];

  return (
    <>
      {newdata && newdata != "error" && (
        <div>
          <PageContainer
            layout_t={1}
            pageNo={2}
            bgColor={"white"}
            pdfInfo={pdfInfo}
          >
            <Header
              title={<>Cash Flow</>}
              type={13}
              breadcrumbs={bread}
              financialYear={pdfInfo.endDate}
              subtext={`Engagement Model segregates the Client portfolio into Held & Held Away. Where in Held refers to the Investments Directly managed by us & Held Away refers to the investments managed by other Advisors. `}
              width={"5in"}
              denomination={denomination}
            ></Header>
            <div className="p_flex" style={{ alignItems: "center" }}>
              <div style={{ height: "590px", width: "100%" }}>
                <div
                  style={{
                    position: "absolute",
                    zIndex: "5",
                    width: "90%",
                    top: "1.8in",

                    marginRight: "66px",
                  }}
                  className="printCashflowTable"
                >
                  <CashflowTable
                    denomination={denomination}
                    topHeader={getRow("quarter")}
                    header={getRow("openingBalance")}
                    header2={getRow("closingBalance")}
                    inflows={inflows}
                    outflows={outflows}
                    footer={getRow("net")}
                  />
                </div>
              </div>
            </div>
          </PageContainer>
        </div>
      )}
    </>
  );
}
