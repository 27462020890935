import React from "react";
import Highlighter from "react-highlight-words";

function HighLightText({ text, search }) {
  return (
    <Highlighter
      highlightClassName="YourHighlightClass"
      searchWords={[search]}
      autoEscape={true}
      textToHighlight={text}
    />
  );
}

export default HighLightText;
