import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import Searchval from "../../../common/Searchval";
import TableSummaryDetails from "../newDesigns/common/newSummaryDetailsTable/TableSummaryDetails";
import PageLoader from "../common/pageLoader";
import NewTopAndBottomButtons from "../../../common/NewTopAndBottomButtons";
import { reportingAgingBtnArray } from "./utils/constands";
import FullWidthInput from "../../../common/FullWidthInput";
import { usePortFolioReportingAging } from "../../../../hooks/usePortfolioReportingAging";
import { useNonEmptyProductList } from "../../../../hooks/useNonEmptyProductList";
import AppPagination from "../../../verificationUpload/AppPagination";
import { ITEMS_LIMIT_FILES_PARTNER_LIST } from "../../../../Constants";
import { isArray } from "lodash";
import { currencyFormat } from "../../../../hooks/constantFunction";
import { roundNumberWithoutDecimal } from "../../../utils/common";

const useHoldingProductWiseAllocation = makeStyles({
  container: {
    margin: "3%",
    marginTop: "5%",
  },
  dataHeader: {
    display: "flex",
    justifyContent: "space-around",
  },
  custombody1: {
    fontWeight: "400 !important",
    cursor: "default",
  },
});

export const HoldingPortfolioReportingAgeing = ({ type }) => {
  const [btnarr, setBtnarr] = useState([reportingAgingBtnArray[0]]);
  const [product, setProduct] = useState("all");
  const [search, setSearch] = useState("");
  const [offset, setItemOffset] = useState(0);

  const { portFolioReportingAging, loading } = usePortFolioReportingAging(
    "dashboard",
    product,
    search,
    offset,
    ITEMS_LIMIT_FILES_PARTNER_LIST
  );
  const { nonEmptyProductList } = useNonEmptyProductList({
    dataType: type,
  });
  const classes = useHoldingProductWiseAllocation();

  useEffect(() => {
    let all = nonEmptyProductList?.find((val) => {
      if (val.all) {
        return true;
      }
    });
    isArray(nonEmptyProductList) && nonEmptyProductList.slice(2);
    isArray(nonEmptyProductList) &&
      !all &&
      nonEmptyProductList.unshift({
        all: "All",
      });

    setBtnarr(
      isArray(nonEmptyProductList)
        ? nonEmptyProductList?.map((item) => {
            const key = Object.keys(item)[0];
            const title = item[key];
            const specialConfig =
              key === "bank"
                ? {
                    // transactionConfig: bankTransactionConfig,
                    // holdingConfig: bankHoldingConfig,
                  }
                : {};

            return { page: key, title, key, ...specialConfig };
          })
        : []
    );
  }, [search, product, nonEmptyProductList]);

  const handleProductSelect = (e) => {
    setProduct(
      () => btnarr.find((val) => val?.title === e?.target?.innerText).page
    );
  };

  const modifiedResponse = portFolioReportingAging?.result?.map((item) => {
    // Add " days" to the daysHeld property
    return {
      ...item,
      daysHeld: `${item.daysHeld} days`,
      currentValue: currencyFormat(item.currentValue),
      quantity: roundNumberWithoutDecimal(item.quantity),
    };
  });

  const newData = btnarr?.filter((item) => item.page !== "bank");

  return (
    <>
      {console.log(newData, "newData")}
      <div className={classes.container}>
        <h3>Portfolio Ageing</h3>
        <NewTopAndBottomButtons
          selected={product}
          handleChange={handleProductSelect}
          btnArray={newData}
        />
        <FullWidthInput
          setSearch={setSearch}
          customWrapperStyle={{ margin: "30px 0 15px 0" }}
        />
        <div className={classes.tableContainerAlign}>
          {console.log(modifiedResponse, "modifiedResponse")}
          <TableSummaryDetails
            headerData={[
              {
                field: "instrumentName",
                title: "INSTRUMENT NAME",
                show: true,
                align: "center",
                dontShowFirstColBorder: true, // ** NOTE TO REMOVE THE LEFT COLOR BORDER
              },
              {
                field: "memberName",
                title: "Member Name",
                show: true,
                align: "center",
              },
              {
                field: "daysHeld",
                title: "DAYS HELD",
                show: true,
                align: "center",
              },
              {
                field: "quantity",
                title: "QUANTITY",
                show: true,
                align: "center",
              },
              {
                field: "currentValue",
                title: "CURRENT VALUE",
                show: true,
                align: "center",
              },
              {
                field: "tenure",
                title: "TENURE",
                show: true,
                align: "center",
              },
            ]}
            data={modifiedResponse}
            hideNavButtons={true}
            customFontClassName={classes.custombody1}
            customStyle={{
              headerStyle: { className: "customHeader1" },
              bodyStyle: { className: "custombody1" },
            }}
            customFontWeight="400"
            truncTitle={true}
          />
          {product !== "all" && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <AppPagination
                pageCount={
                  Math.ceil(
                    portFolioReportingAging?.meta?.count /
                      ITEMS_LIMIT_FILES_PARTNER_LIST
                  ) || 0
                }
                itemLimit={ITEMS_LIMIT_FILES_PARTNER_LIST}
                setItemOffset={setItemOffset}
                pageNumber={offset / ITEMS_LIMIT_FILES_PARTNER_LIST + 1 || 1}
              />
            </div>
          )}

          <PageLoader showLoader={loading} />
        </div>
      </div>
    </>
  );
};
