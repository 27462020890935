import React from "react";
import { ResponsiveLine } from "@nivo/line";
import { theme } from "./nivo_objects";
import moment from "moment";
import { tooltip } from "./barTooltip";
export const linear = {
  pointSize: 5,
  pointBorderWidth: 5,
  enablePoints: true,
  curve: "linear",
};

export default function LineChart({ config, colors, data, xval, min }) {
  const axisleft = { ...config?.leftAxis };
  const defaultLeftAxis = {
    orient: "left",
    tickSize: 7,
    tickPadding: 6,
    tickRotation: 22,
    legend: "Portfolio Value",
    legendOffset: -57,
    legendPosition: "middle",
    renderTick: (tick) => {
      return (
        <g transform={`translate(${tick.x - 30},${tick.y})`}>
          {/* <rect x={-14} y={-6} rx={3} ry={3} width={28} height={24} fill="rgba(0, 0, 0, .05)" />
            <rect x={-12} y={-12} rx={2} ry={2} width={24} height={24} fill="rgb(232, 193, 160)" /> */}
          {/* <line stroke="rgb(232, 193, 160)" strokeWidth={1.5} y1={-22} y2={-12} /> */}
          {/* <rect x={-40} y={-15} rx={2} ry={2} width={100} height={3} fill="rgba(31, 28, 64, 1)" /> */}
          <text
            textAnchor="middle"
            dominantBaseline="middle"
            style={{
              fill: "#b0aab7",
              fontSize: 10,
            }}
          >
            {tick.value}
          </text>
        </g>
      );
    },
  };
  const tempdata = [
    {
      id: "My portfolio",
      color: "hsl(135, 70%, 50%)",
      data: [
        {
          x: "jan",
          y: 10,
        },
        {
          x: "feb",
          y: 20,
        },
        {
          x: "mar",
          y: 30,
        },
        {
          x: "apr",
          y: 40,
        },
        {
          x: "may",
          y: 80,
        },
        {
          x: "jun",
          y: 34,
        },
        {
          x: "jul",
          y: 90,
        },
        {
          x: "aug",
          y: 100,
        },
        {
          x: "sep",
          y: 90,
        },
        {
          x: "oct",
          y: 181,
        },
      ],
    },
    {
      id: "My data",
      color: "hsl(135, 70%, 50%)",
      data: [
        {
          x: "jan",
          y: 5,
        },
        {
          x: "feb",
          y: 20,
        },
        {
          x: "mar",
          y: 30,
        },
        {
          x: "apr",
          y: 60,
        },
        {
          x: "may",
          y: 80,
        },
        {
          x: "jun",
          y: 34,
        },
        {
          x: "jul",
          y: 90,
        },
        {
          x: "aug",
          y: 80,
        },
        {
          x: "sep",
          y: 90,
        },
        {
          x: "oct",
          y: 90,
        },
      ],
    },
    {
      id: "Target Return",
      color: "hsl(347, 70%, 50%)",
      data: [
        {
          x: "jan",
          y: 1,
        },
        {
          x: "feb",
          y: 1,
        },
        {
          x: "mar",
          y: 1,
        },
        {
          x: "apr",
          y: 1,
        },
        {
          x: "may",
          y: 1,
        },
        {
          x: "jun",
          y: 1,
        },
        {
          x: "jul",
          y: 1,
        },
        {
          x: "aug",
          y: 1,
        },
        {
          x: "sep",
          y: 1,
        },
        {
          x: "oct",
          y: 1,
        },
      ],
    },
  ];

  return (
    <div style={{ height: "300px" }}>
      {data && (
        <ResponsiveLine
          // tooltip={tooltip}
          animate={false}
          data={data ? data : tempdata}
          theme={theme}
          {...config?.extra_props}
          margin={{ top: 5, right: 50, bottom: 50, left: 80 }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            min: min ? min : 0,
            max: config.max ? config.max : "auto",
            stacked: false,
            reverse: false,
          }}
          yFormat=" >-.2f"
          curve={config.curve ? config.curve : "monotoneX"}
          axisTop={null}
          axisRight={null}
          axisBottom={
            config.disableAxisBottom
              ? null
              : {
                  orient: "bottom",
                  tickSize: 1,
                  tickPadding: 8,
                  tickRotation: 0,
                  legend: config.bottomLegend ? config.bottomLegend : "",
                  legendOffset: 45,
                  legendPosition: "middle",
                  format: (values) => {
                    if (xval.includes(values)) {
                      return `${moment(values).format(
                        config.format ? config.format : "MMM-YY"
                      )}`;
                    } else return "";
                  },
                }
          }
          axisLeft={{ ...defaultLeftAxis, ...axisleft }}
          enableGridX={false}
          colors={colors ? colors : { scheme: "nivo" }}
          lineWidth={3}
          enablePoints={config.enablePoints ? config.enablePoints : false}
          pointSize={
            config.nopoint ? 0 : config.pointSize ? config.pointSize : 5
          }
          pointColor={{ theme: "background" }}
          pointBorderWidth={
            config.pointBorderWidth ? config.pointBorderWidth : 5
          }
          pointBorderColor={{ from: "serieColor" }}
          enablePointLabel={config.disablePointLabel ? false : true}
          pointLabelYOffset={-11}
          areaBaselineValue={60}
          areaOpacity={0.8}
          enableCrosshair={false}
          useMesh={true}
          legends={
            config.hide_legends
              ? []
              : [
                  {
                    anchor: "bottom",
                    direction: "row",
                    justify: false,
                    translateX: 18,
                    translateY: 73,
                    itemsSpacing: 40,
                    itemDirection: "left-to-right",
                    itemWidth: 200,
                    itemHeight: 63,
                    itemOpacity: 0.75,
                    symbolSize: 9,
                    symbolShape: "circle",
                    symbolBorderColor: "rgba(0, 0, 0, .5)",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemBackground: "rgba(0, 0, 0, .03)",
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]
          }
        />
      )}
      {!data && (
        <ResponsiveLine
          data={data ? data : tempdata}
          theme={theme}
          {...config?.extra_props}
          margin={{ top: 5, right: 20, bottom: 50, left: 80 }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            min: 0,
            max: "auto",
            stacked: false,
            reverse: false,
          }}
          yFormat=" >-.2f"
          curve={config.curve ? config.curve : "monotoneX"}
          axisTop={null}
          axisRight={null}
          axisBottom={
            !config.disableAxisBottom
              ? null
              : {
                  orient: "bottom",
                  tickSize: 1,
                  tickPadding: 8,
                  tickRotation: 30,
                  legend: "",
                  legendOffset: 11,
                  legendPosition: "middle",
                  // format: (values) => {
                  //   // if (xval.includes(values)) {
                  //   //
                  //   //   return `${moment(values).format("DD-MMM-YY")}`;
                  //   // } else
                  //   return "";
                  // },
                }
          }
          axisLeft={{ ...defaultLeftAxis, ...axisleft }}
          enableGridX={false}
          colors={colors ? colors : { scheme: "nivo" }}
          lineWidth={3}
          enablePoints={config.enablePoints ? config.enablePoints : false}
          pointSize={
            config.nopoint ? 0 : config.pointSize ? config.pointSize : 5
          }
          pointColor={{ theme: "background" }}
          pointBorderWidth={
            config.pointBorderWidth ? config.pointBorderWidth : 5
          }
          pointBorderColor={{ from: "serieColor" }}
          enablePointLabel={config.disablePointLabel ? false : true}
          pointLabelYOffset={-11}
          areaBaselineValue={60}
          areaOpacity={0.8}
          enableCrosshair={false}
          useMesh={true}
          legends={
            config.hide_legends
              ? []
              : [
                  {
                    anchor: "bottom",
                    direction: "row",
                    justify: false,
                    translateX: 18,
                    translateY: 73,
                    itemsSpacing: 40,
                    itemDirection: "left-to-right",
                    itemWidth: 200,
                    itemHeight: 63,
                    itemOpacity: 0.75,
                    symbolSize: 9,
                    symbolShape: "circle",
                    symbolBorderColor: "rgba(0, 0, 0, .5)",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemBackground: "rgba(0, 0, 0, .03)",
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]
          }
        />
      )}
    </div>
  );
}
