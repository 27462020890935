import React from "react";
import PageFilter from "../common/PageFilter";
import { NewFilter } from "../../newFilter/NewFilter";
import NewTabComp from "../common/NewTabComp";
import MLDCashFlow from "../mld/MLDCashFlow";
import MLDHolding from "../mld/MLDHolding";
import MLDTransaction from "../mld/MLDTransaction";
import {
  objectFormating1,
  objectFormating2,
} from "../../../utils/objectFormating";
import { getTransactionsSummary } from "../../../../api";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { OnboardingContext } from "../../../../Context";
import { useContext } from "react";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import { routes } from "../../../../routes";
import { customTableConfig } from "../mld/mldConfig";
import { bankbookConfig } from "../REITS/reitsConfig";
import { IosSwitch } from "../../../IosSwitch";
import { colors_allocation } from "../../../charts/colors";
import { currencyFormat } from "../../../../hooks/constantFunction";
import AllocationSummary from "../bankBook/AllocationSummary";
import {
  FDHoldingConfig,
  FDTransactionConfig,
  productName,
} from "./fixedDepositConstands";

const data = [
  {
    page: "allocation",
    title: "Allocation Summary",
    path: routes?.fixedDepositAllocation,
  },
  {
    page: "holding",
    title: "Holding Summary",
    path: routes?.fixedDepositHolding,
  },
  {
    page: "transaction",
    title: "Transaction Summary",
    path: routes?.fixedDepositTransaction,
  },

  // {
  //   page: "cash-flow",
  //   title: "Cash Flow",
  //   path: routes?.invitsCashFlow,
  // },
];

function FixedDepostiReport({ pageName }) {
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const [page, setPage] = useState(() => pageName);
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const { userState } = OnboardingContext();
  //   const classes = useStyles();
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  // const [pagiNationData, setPageinationData] = useState({
  //   pageCount: null,
  //   // limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
  // });

  const getTabelData = async (
    setTableColumns,
    tableName,
    params,
    setLoading,
    objectFormating,
    setCheckedTrans,
    tableHeader,
    limit,
    offset,
    setPageinationData,
    pagiNationData
  ) => {
    setLoading(true);
    params = {
      clientId: userState.loginedUserData.id,
      search: params["search"] ? params["search"] : "",
      // clientId: 197,

      ...getfilter_as_params(),
      // assetClass: params.assetClass,
      offset: offset,
      limit: limit,
      // clientId: 197,
      // memberId: 42,
      // assetClass: "debt",
      // Pass showCorpAction only for Transaction Page
      // ...(page === "transaction" ? { showCorpAction: checked } : {}),
    };

    try {
      let response = await getTransactionsSummary("fd", tableName, params);
      let newlist = [];
      let pieData = [];
      let total = 0;
      let transactionList = [];
      setPageinationData((prev) => {
        return { ...prev, pageCount: response?.data?.data?.meta?.count };
      });
      response?.data?.data?.result?.map((val, i) => {
        if (page === "holding") {
          let obj = objectFormating1(val, tableHeader);

          newlist.push(obj);
        } else if (page === "allocation") {
          pieData.push({
            id: val.isin,
            label: val.account_no,
            value: val.allocation_percentage,
            color: colors_allocation[i],
            amount: val.balance,
          });
          total = total + val.balance;
          let obj = objectFormating2(val, tableHeader, i);

          newlist.push(obj);
        } else {
          // val.advisor = val?.advisor + "%" + val?.updated_at;
          let obj = objectFormating1(val, tableHeader);
          // console.log(obj, "objecttt");
          newlist.push(obj);
          if (
            val["transaction_type"] != null &&
            !transactionList
              .map((val) => val?.title)
              .includes(val["transaction_type"])
          ) {
            transactionList.push({
              title: val["transaction_type"],
              show: false,
            });
          }
        }
      });

      if (page !== "holding") {
        setCheckedTrans(transactionList);
      }
      if (page === "allocation") {
        setTableColumns({
          tableColumns: newlist,
          pieData,
          total: total ? currencyFormat(total) : "--",
        });
      } else {
        setTableColumns(newlist);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const changePage = (p) => {
    switch (p) {
      case "holding":
        return (
          <MLDHolding
            typeOfProduct={"fd"}
            hideSubTab={true}
            // customTableConfig={holdingTableConfig}
            customTableConfig={FDHoldingConfig}
            getTabelData={getTabelData}
            subHeading={productName}
            // backUrl="/home/invits/invits-summary"
            backUrl={routes?.fixedDepositSummaryPage}
            // headerInTwoLine={true}
            // pageCount={pagiNationData?.pageCount}
          ></MLDHolding>
        );

      case "transaction":
        return (
          <MLDTransaction
            hideSubTab={true}
            getTabelData={getTabelData}
            // showCorpActions={checked}
            customTableConfig={FDTransactionConfig}
            subHeading={productName}
            backUrl={routes?.fixedDepositSummaryPage}
            // headerInTwoLine={true}
            // iosSwitch={
            //   <IosSwitch
            //     switchLabelName="Show Corporate Action"
            //     checked={checked}
            //     setChecked={setChecked}
            //   />
            // }
            // pageCount={pagiNationData?.pageCount}
          />
        );
      case "allocation":
        return (
          <AllocationSummary
            hideSubTab={true}
            showisin={"ISIN"}
            // customTableConfig={holdingTableConfig}
            customTableConfig={customTableConfig(bankbookConfig)}
            getTabelData={getTabelData}
            subHeading={productName}
            // backUrl="/home/invits/invits-summary"
            backUrl={routes?.fixedDepositSummaryPage}
            headerInTwoLine={true}
            // pageCount={pagiNationData?.pageCount}
          ></AllocationSummary>
        );

      case "cash-flow":
        return (
          <MLDCashFlow
            entity={productName}
            backUrl={routes?.fixedDepositSummaryPage}
            subHeading={productName}
          />
        );

      default:
        return (
          <MLDHolding
            typeOfProduct={"fd"}
            hideSubTab={true}
            // customTableConfig={holdingTableConfig}
            customTableConfig={FDHoldingConfig}
            getTabelData={getTabelData}
            subHeading={productName}
            // backUrl="/home/invits/invits-summary"
            backUrl={routes?.fixedDepositSummaryPage}
            headerInTwoLine={true}
            // pageCount={pagiNationData?.pageCount}
          ></MLDHolding>
        );
    }
  };

  const handleClick = React.useCallback(
    (page) => {
      setPage(page);
    },
    [page]
  );
  return (
    <>
      <PageFilter
        backLink={routes?.fixedDepositSummaryPage}
        setFilterModalOpen={setFilterModalOpen}
      ></PageFilter>
      <NewFilter
        filterModalOpen={filterModalOpen}
        setFilterModalOpen={setFilterModalOpen}
        productType="fd"
      />
      <div className="backBtnContainer" style={{ margin: "2%" }}>
        <NewTabComp
          data={data}
          customWidth={"100%"}
          customHandle={handleClick}
          customSelect={data.findIndex((val) => val?.page == page)}
        />
      </div>
      {changePage(page)}
    </>
  );
}

export default FixedDepostiReport;
