import { Button, Typography } from "@material-ui/core";
import React from "react";
import { ReactComponent as Add } from "../../assets/icons/add.svg";
import "./addButton.css";
import useStyles from "../user_management/UserManagementStyles";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import "../user_management/userManagemantStyles.css";

function AddButton({ addBtnTitle, handleIconClick, customBtnTxtClass }) {
  const classes = useStyles();

  return (
    <Button onClick={handleIconClick} className={`newButtonWithIcon`}>
      <AddRoundedIcon className="newButtonAddIcon" />
      <Typography
        className={`${classes.buttonTypo} ${
          customBtnTxtClass ? customBtnTxtClass : "newBtnTxt"
        }`}
        style={{ letterSpacing: "1px" }}
      >
        {addBtnTitle ? addBtnTitle : "Add Member"}
      </Typography>
    </Button>
  );
}

export default AddButton;
