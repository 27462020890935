import React from "react";

export default function H_Cards(props) {
  // console.log(props, "classnm");
  const style = {
    // background: " #EFF2FA",
  };

  return (
    <div className={"h_card h_tab printBorder " + props.classnm}>
      <div className="h_block  pt10" style={{ marginLeft: "8%" }}>
        <div
          className={
            "printBorder borderRight cardTitle " + props?.data?.display
          }
        >
          <div
            className="font600 letterSpace1"
            style={{ ...props?.data?.titleStyle }}
          >
            {props.data.title}
            {props?.data?.display == "p_flex" ? (
              <span style={{ paddingRight: "10px", paddingLeft: "10PX" }}>
                :{" "}
              </span>
            ) : (
              ""
            )}
          </div>

          <div
            className="h_block pt10 p_flex round_edge bold1"
            style={{ ...style, ...props?.data?.style, fontWeight: "800" }}
          >
            {props.data.value} <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}
