import React from "react";
import { useState } from "react";
import ClientForm1 from "../../NewSignUp/clientSignUp/ClientForm1";
import NewDialaugeComp from "../NewDialogComp";
import ClientForm2 from "../../NewSignUp/clientSignUp/ClientForm2";
import ClientForm3 from "../../NewSignUp/clientSignUp/ClientForm3";
import ConfirmationComp from "../../NewSignUp/clientSignUp/ConfirmationComp";
import { useLocation, useNavigate } from "react-router-dom";
import EmailAndMobileConfirmation from "./EmailAndMobileConfirmation";
import VerificationComp from "./VerificationComp";
import SetPinForm from "../../NewSignUp/SetPinForm";
import useClientOnboardFlow from "../../NewSignUp/hooks/useClientOnboardFlow";
import SuccessPage from "../../NewSignUp/SuccessPage";
import { ClientMemberRolesDIY } from "../../../Constants";

function ClientAssistentJourneyFlow({ openModal, setOpenModal }) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [next, setNext] = useState(state?.step ? state?.step : 1); //state?.step ? state?.step :
  const [formData, setFormData] = useState(
    state?.formData ? state?.formData : {}
  );
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const handleErrorMessage = (error) => {
    if (error?.message) setApiErrorMessage(error?.message);
    else setApiErrorMessage("Something Went Wrong");
  };
  //   const api1 = (data) => {
  //     console.log("ajfabfjaf", data);
  //     handleNext(1);
  //   };

  const handleBack = (step) => {
    if (step >= 1) {
      setNext(step);
      handleNext(-1);
    } else {
      setOpenModal(false);
    }
    setApiErrorMessage("");
  };

  const handleNext = (stepAddition, formData1) => {
    setNext(next + stepAddition);
    navigate("/client", {
      state: {
        step: next + stepAddition,
        formData: formData1 ? formData1 : formData,
        openModal: true,
      },
    });
    setApiErrorMessage("");
  };

  const handleStep3 = (data) => {
    setFormData((prev) => {
      return {
        ...prev,
        ...data,
      };
    });
    handleNext(1);
  };

  const { api1, api2, api3 } = useClientOnboardFlow({
    handleErrorMessage,
    handleNext,
    setFormData,
    setLoading,
  });

  const chagneInStep = (step) => {
    switch (step) {
      case 1:
        return (
          <ClientForm1
            currentStep={next}
            handleBack={handleBack}
            customHandleSubmit={api1}
            noStepBottom={true}
            apiErrorMessage={apiErrorMessage}
            loading={loading}
            formData={formData}
            formMaxHeight="40vh"
          />
        );
      case 2:
        return (
          <ClientForm2
            currentStep={next}
            handleBack={handleBack}
            customHandleSubmit={api2}
            noStepBottom={true}
            apiErrorMessage={apiErrorMessage}
            loading={loading}
            formData={formData}
            formMaxHeight="65vh"
            userRoleConfig={ClientMemberRolesDIY}
          />
        );
      case 3:
        return (
          <ClientForm3
            currentStep={next}
            handleBack={handleBack}
            customHandleSubmit={handleStep3}
            noStepBottom={true}
            apiErrorMessage={apiErrorMessage}
            formData={formData}
            formMaxHeight="65vh"
          />
        );
      case 4:
        return (
          <ConfirmationComp
            customHandleAccept={() => {
              api3(formData);
            }}
            currentStep={next}
            handleBack={handleBack}
            apiErrorMessage={apiErrorMessage}
            head={{ main: "Add Client" }}
            hideStep={true}
          />
        );
      //   case 5:
      //     return (
      //       <EmailAndMobileConfirmation
      //         title="Email Verification"
      //         subTitle="An email has been sent to your registered Email Id"
      //         currentStep={next}
      //         handleBack={handleBack}
      //         customHandleAccept={() => handleNext(1)}
      //       />
      //     );
      //   case 6:
      //     return (
      //       <EmailAndMobileConfirmation
      //         title="Mobile Verification"
      //         subTitle="A sms has been sent to your registered mobile number"
      //         currentStep={next}
      //         handleBack={handleBack}
      //         customHandleAccept={() => handleNext(1)}
      //       />
      //     );
      //   case 7:
      //     return (
      //       <VerificationComp
      //         title={"Verification"}
      //         handleBack={handleBack}
      //         currentStep={next}
      //         customHandleAccept={() => handleNext(1)}
      //         subTitle={"An email has been sent to your registered Email Id"}
      //       />
      //     );
      //   case 8:
      //     return (
      //       <SetPinForm
      //         currentStep={next}
      //         handlenext={handleNext}
      //         handleBack={handleBack}
      //         userId={formData?.user?.id}
      //         userType={"client"}
      //       />
      //     );
      case 5:
        return (
          <SuccessPage
            handleBack={handleBack}
            subData="Congratulation your documents have been successfully submitted. An email has been sent to your registered Email Id Please Check"
            buttonTxt="Done"
            noStep={true}
            currentStep={next}
            handleNext={() => {
              setOpenModal(false);
              setFormData({});
              setNext(1);
              navigate("/client", {
                state: {
                  step: 1,
                  formData: {},
                  openModal: false,
                },
              });
            }}
          />
        );
      default:
        return navigate("/client", {
          state: {
            step: next,
            formData: formData,
            openModal: false,
          },
        });
    }
  };

  return (
    // <C_Modal openModal={openModal} closemodal={setOpenModal}>
    //   {chagneInStep(step)}
    // </C_Modal>
    <NewDialaugeComp
      width={next == 3 ? "md" : "sm"}
      open={openModal}
      handleClose={() => {
        setOpenModal(false);
        setFormData(() => {});
        setNext(1);
        navigate("/client", {
          state: {
            step: 1,
            formData: {},
            openModal: false,
          },
        });
      }}
      handleAccidentalClose={() => {
        setOpenModal(false);
        navigate("/client", {
          state: {
            step: next,
            formData: formData,
            openModal: false,
          },
        });
      }}
    >
      {chagneInStep(next)}
    </NewDialaugeComp>
  );
}

export default ClientAssistentJourneyFlow;
