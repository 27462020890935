import React, { useState } from "react";
import { Grid, Tooltip, Zoom, DialogContentText } from "@material-ui/core";
import {
  extractFileName,
  getStyleBasedOnVerificationStatus,
} from "./utilsVerificationUpload";
import moment from "moment";
import { ReactComponent as ApprovedVerification } from "../../assets/icons/approvedVerification.svg";
import { ReactComponent as RejectedVerification } from "../../assets/icons/rejectedVerification.svg";
import { ReactComponent as ActiveCommentIcon } from "../../assets/icons/activeCommentIcon.svg";
import { ReactComponent as CommentIcon } from "../../assets/icons/commentIcon.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/uploadVerificationDownloadIcon.svg";
import { AlertDialog } from "../dashboard/common/AlertDialog";
export const VerifiedUpload = ({
  verifiedData,
  getUploadedDocumentId,
  submitStatus,
}) => {
  const [open, setOpen] = useState(false);
  const [textarea, setTextArea] = useState("");
  function renderVerificationStatus(item) {
    return (
      <>
        {item?.status === "approved" ? (
          <ApprovedVerification />
        ) : (
          <RejectedVerification />
        )}
        <p
          className="verificationUploadDownloadText"
          style={{ color: "white" }}
        >
          <span style={getStyleBasedOnVerificationStatus(item?.status)}>
            {item?.status === "approved" ? "Approved" : "Rejected"}{" "}
          </span>
          by {item?.status_changed_by_name ? item?.status_changed_by_name : "-"}
        </p>
      </>
    );
  }
  console.log("Reject Reason", textarea);

  return (
    <>
      <AlertDialog
        open={open}
        setOpen={setOpen}
        dialogTitle={() => {}}
        dialogContent={() => {
          return (
            <DialogContentText
              id="alert-dialog-description"
              style={{
                color: "#fff",
                textAlign: "center",
                fontFamily: "Avenir",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "22px",
              }}
            >
              <div className="commentBoxContainer">
                <div className="commentBoxHeader">
                  <div className="flexCenterIconLabel">
                    <CommentIcon />
                    <p>Comments</p>
                  </div>
                  <div
                    style={{
                      cursor: "pointer",
                      marginRight: "1rem",
                    }}
                    onClick={() => setOpen(false)}
                  >
                    x
                  </div>
                </div>
                <textarea
                  disabled
                  value={textarea}
                  rows={10}
                  cols={70}
                  className="commentBoxTextArea"
                ></textarea>
              </div>
            </DialogContentText>
          );
        }}
        dialogFooter={() => {}}
      />
      <Grid
        container
        style={{
          marginTop: "1.75rem",
          padding: "1rem 1rem 0 1rem",
        }}
      >
        <Grid item xs={3}>
          <p className="gridHeaderText">FILE NAME</p>
        </Grid>
        <Grid item xs={2}>
          <p className="gridHeaderText">UPLOADED BY</p>
        </Grid>
        <Grid item xs={2}>
          <p className="gridHeaderText">UPLOAD DETAILS</p>
        </Grid>
        <Grid item xs={2}>
          <p className="gridHeaderText">VERIFICATION STATUS</p>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2}></Grid>
      </Grid>
      {verifiedData?.map((item, index) => (
        <Grid container className="singleGridDisplay" key={index}>
          <Grid item xs={3}>
            <Tooltip
              TransitionComponent={Zoom}
              TransitionProps={{ timeout: 400 }}
              title={extractFileName(item?.s3_key)}
            >
              <p
                style={{
                  display: "block",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "90%",
                }}
              >
                {extractFileName(item?.s3_key)}
              </p>
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            {item?.created_by_name ? item?.created_by_name : "-"}
          </Grid>
          <Grid item xs={2}>
            {/* 15 Nov 2023 - 16:42:30 */}
            {item?.created_at
              ? moment(item?.created_at).format("DD MMM YYYY - HH:mm:ss")
              : "-"}
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            {renderVerificationStatus(item)}
          </Grid>
          <Grid
            item
            xs={1}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* Show Comment Only For Rejected Status */}
            {item?.status === "rejected" && (
              <>
                <p style={{ marginRight: "0.5rem" }}>Comment</p>
                <ActiveCommentIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setTextArea(item?.rejected_reason);
                    setOpen(true);
                  }}
                />
              </>
            )}
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p
              className="verificationUploadDownloadText"
              style={{ marginRight: "0.75rem" }}
            >
              Download File
            </p>
            <a href={item?.signed_url}>
              <DownloadIcon style={{ cursor: "pointer" }} />
            </a>
          </Grid>
        </Grid>
      ))}
    </>
  );
};
