import React from "react";
import { Grid, Tooltip, Zoom } from "@material-ui/core";
import moment from "moment";
import { extractFileName } from "./utilsVerificationUpload";
import { ReactComponent as DownLoadIcon } from "../../assets/icons/uploadVerificationDownloadIcon.svg";
export const PendingVerification = ({
  setOpen,
  pendingData,
  getUploadedDocumentId,
  submitStatus,
}) => {
  return (
    <>
      <Grid
        container
        style={{ marginTop: "1.75rem", padding: "1rem 1rem 0 1rem" }}
      >
        <Grid item xs={4}>
          <p className="gridHeaderText">FILE NAME</p>
        </Grid>
        <Grid item xs={2}>
          <p className="gridHeaderText">UPLOADED BY</p>
        </Grid>
        <Grid item xs={2}>
          <p className="gridHeaderText">UPLOAD DETAILS</p>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
      </Grid>
      {pendingData?.map((item, index) => (
        <Grid container className="singleGridDisplay" key={index}>
          <Grid item xs={4}>
            <Tooltip
              TransitionComponent={Zoom}
              TransitionProps={{ timeout: 400 }}
              title={extractFileName(item?.s3_key)}
            >
              <p
                style={{
                  display: "block",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "90%",
                }}
              >
                {extractFileName(item?.s3_key)}
              </p>
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            {item?.created_by_name ? item?.created_by_name : "-"}
          </Grid>
          <Grid item xs={2}>
            {item?.created_at
              ? moment(item?.created_at).format("DD MMM YYYY - HH:mm:ss")
              : "-"}
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <p className="verificationUploadDownloadText">Download File</p>
            <a href={item?.signed_url}>
              <DownLoadIcon style={{ cursor: "pointer" }} />
            </a>
          </Grid>
          <Grid item xs={1}>
            <button
              onClick={() => {
                getUploadedDocumentId(item?.id);
                submitStatus("approved");
              }}
              className="verificationUploadApproveBtn verificationUploadFont"
            >
              Approve
            </button>
          </Grid>
          <Grid item xs={1}>
            <button
              className="verificationUploadRejectBtn verificationUploadFont"
              onClick={() => {
                getUploadedDocumentId(item?.id);
                setOpen(true);
              }}
            >
              Reject
            </button>
          </Grid>
        </Grid>
      ))}
    </>
  );
};
