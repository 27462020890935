import React, { useEffect, useState } from "react";
import TableSummaryDetails from "../newDesigns/common/newSummaryDetailsTable/TableSummaryDetails";
import { fetchAssetVsBenchMarkData } from "../../../../api";
import { OnboardingContext } from "../../../../Context";
import PageLoader from "../common/pageLoader";
import { roundNumber } from "../../../utils/common";
import { currencyFormat } from "../../../../hooks/constantFunction";

export const HoldingAssetClassBenchMark = () => {
  const { userState } = OnboardingContext();
  const [assetData, setAssetData] = useState([]);
  const [benchmarkData, setBenchmarkData] = useState([]);
  const [assetDataLoader, setAssetDataLoader] = useState(false);
  const [benchmarkLoader, setBenchmarkLoader] = useState(false);

  const clientId = userState.loginedUserData.id;

  const modifiedResponse = assetData
    .slice()
    .reverse()
    .map((item) => {
      return {
        ...item,
        title: item.benchmarkName ? "none" : item.assetClass,
        assetClass: item.benchmarkName ? (
          <>
            {" "}
            <span style={{ opacity: "0.5" }}>Benchmark : </span>
            <span style={{ fontFamily: "AvenirBold" }}>
              {" "}
              {item.benchmarkName}
            </span>
          </>
        ) : (
          item.assetClass
        ),
        mtdAbsoluteReturn: item.benchmarkName ? (
          <span style={{ fontFamily: "AvenirBold" }}>
            ({roundNumber(item.mtdAbsoluteReturn)}%)
          </span>
        ) : (
          `${currencyFormat(item.mtdTotalGainLoss)} (${roundNumber(
            item.mtdAbsoluteReturn
          )}%)`
        ),

        qtdAbsoluteReturn: item.benchmarkName ? (
          <span style={{ fontFamily: "AvenirBold" }}>
            ({roundNumber(item.qtdAbsoluteReturn)}%)
          </span>
        ) : (
          `${currencyFormat(item.qtdTotalGainLoss)} (${roundNumber(
            item.qtdAbsoluteReturn
          )}%)`
        ),

        ytdAbsoluteReturn: item.benchmarkName ? (
          <span style={{ fontFamily: "AvenirBold" }}>
            ({roundNumber(item.ytdAbsoluteReturn)}%)
          </span>
        ) : (
          `${currencyFormat(item.ytdTotalGainLoss)} (${roundNumber(
            item.ytdAbsoluteReturn
          )}%)`
        ),

        itdAbsoluteReturn: item.benchmarkName ? (
          <span style={{ fontFamily: "AvenirBold" }}>
            ({roundNumber(item.itdAbsoluteReturn)}%)
          </span>
        ) : (
          `${currencyFormat(item.itdTotalGainLoss)} (${roundNumber(
            item.itdAbsoluteReturn
          )}%)`
        ),
      };
    });

  const modifiedResponseForBenchMark = benchmarkData?.map((item) => {
    return {
      ...item,
      mtdAbsoluteReturn: `${roundNumber(item.mtdAbsoluteReturn)}%`,
      qtdAbsoluteReturn: `${roundNumber(item.qtdAbsoluteReturn)}%`,
      ytdAbsoluteReturn: `${roundNumber(item.ytdAbsoluteReturn)}%`,
      itdAbsoluteReturn: `${roundNumber(item.itdAbsoluteReturn)}%`,
    };
  });

  function fetchBenchmark(assetdata) {
    fetchAssetVsBenchMarkData(clientId, "benchmark")
      .then((response) => {
        let result = response?.data?.data?.result;
        let tableData = [];
        assetdata.map((a) => {
          let benchmark = result.find((r) => {
            if (r.assetClass == a.assetClass) {
              return r;
            }
          });
          tableData.push(benchmark);
          tableData.push(a);
        });

        setAssetData(tableData);
        setBenchmarkLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching benchmark data:", error);
        setBenchmarkLoader(false);
      });
  }
  useEffect(() => {
    setAssetDataLoader(true);
    fetchAssetVsBenchMarkData(clientId, "assetClass")
      .then((response) => {
        setAssetDataLoader(false);
        fetchBenchmark(response?.data?.data?.result);
      })
      .catch((error) => {
        console.error("Error fetching asset data:", error);
        setAssetDataLoader(false);
      });

    setBenchmarkLoader(true);
  }, []);

  return (
    <>
      <div style={{ margin: "2%" }}>
        {/* <h3>Asset Class</h3> */}
        <TableSummaryDetails
          headerData={[
            {
              field: "assetClass",
              title: "Asset Class Vs Benchmark ",
              show: true,
              align: "center",
              dontShowFirstColBorder: false, // ** NOTE TO REMOVE THE LEFT COLOR BORDER
            },
            {
              field: "mtdAbsoluteReturn",
              title: "MTD Returns",
              show: true,
              align: "center",
            },
            {
              field: "qtdAbsoluteReturn",
              title: "QTD Returns",
              show: true,
              align: "right",
            },
            {
              field: "ytdAbsoluteReturn",
              title: "YTD Returns",
              show: true,
              align: "right",
            },
            {
              field: "itdAbsoluteReturn",
              title: "ITD Returns",
              show: true,
              align: "right",
            },
          ]}
          data={modifiedResponse}
          hideNavButtons={true}
          customColorAndProd={{
            equity: "#BBB580",
            debt: "#1FAAB2",
            alternate: "#907DD0",
            cash: "#6761E6",
            none: "transparent",
          }}
          customProdAndKey={[
            {
              prod: "equity",
              key: "equity",
            },
            {
              prod: "debt",
              key: "debt",
            },
            {
              prod: "alternate",
              key: "alternate",
            },
            {
              prod: "cash",
              key: "cash",
            },
            {
              prod: "none",
              key: "none",
            },
          ]}
          summaryDetailsBodyMidCellsStyle={{ textAlign: "center" }}
          summaryDetailsLastCellStyle={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          customFontWeight="400"
          alignHeaderColumn={(currentHeaderColumnIndex) => {
            console.log("currentHeaderColumnIndex", currentHeaderColumnIndex);
            const isFirstColumnHeader = currentHeaderColumnIndex === 0;
            return {
              display: "flex",
              justifyContent: isFirstColumnHeader ? "flex-start" : "center",
              alignItems: "center",
            };
          }}
        />
        <PageLoader showLoader={assetDataLoader} />
      </div>

      {/* <div style={{ margin: "2%" }}>
        <h3>BenchMark</h3>
        <TableSummaryDetails
          customFontWeight="400"
          headerData={[
            {
              field: "benchmarkName",
              title: "Benchmark",
              show: true,
              align: "center",
              dontShowFirstColBorder: true, // ** NOTE TO REMOVE THE LEFT COLOR BORDER
            },
            {
              field: "mtdAbsoluteReturn",
              title: "MTD Returns",
              show: true,
              align: "center",
            },
            {
              field: "qtdAbsoluteReturn",
              title: "QTD Returns",
              show: true,
              align: "right",
            },
            {
              field: "ytdAbsoluteReturn",
              title: "YTD Returns",
              show: true,
              align: "right",
            },
            {
              field: "itdAbsoluteReturn",
              title: "ITD Returns",
              show: true,
              align: "right",
            },
          ]}
          data={modifiedResponseForBenchMark}
          hideNavButtons={true}
          customColorAndProd={null}
          summaryDetailsBodyMidCellsStyle={{ textAlign: "center" }}
          summaryDetailsLastCellStyle={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          alignHeaderColumn={(currentHeaderColumnIndex) => {
            console.log("currentHeaderColumnIndex", currentHeaderColumnIndex);
            const isFirstColumnHeader = currentHeaderColumnIndex === 0;
            return {
              display: "flex",
              justifyContent: isFirstColumnHeader ? "flex-start" : "center",
              alignItems: "center",
            };
          }}
        />
        <PageLoader showLoader={benchmarkLoader} />
       
      </div> */}
      <ul>
        <li
          style={{
            fontSize: "12px",
            fontWeight: "400",
            opacity: "70%",
            padding: "5px",
          }}
        >
          MTD - Month to date|QTD - Quarter to date|ITD - Inception to Date
        </li>
        <li
          style={{
            fontSize: "12px",
            fontWeight: "400",
            opacity: "70%",
            padding: "5px",
          }}
        >
          Return MTD/YTD/ITD include Realized, Unrealized and Other Income
        </li>
        <li
          style={{
            fontSize: "12px",
            fontWeight: "400",
            opacity: "70%",
            padding: "5px",
          }}
        >
          MTD/QTD/YTD % returns are absolute returns for the period
        </li>
        <li
          style={{
            fontSize: "12px",
            fontWeight: "400",
            opacity: "70%",
            padding: "5px",
          }}
        >
          ITD % returns are since inception XIRR
        </li>
      </ul>
    </>
  );
};
