import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Union } from "../../../assets/icons/union.svg";
import { ReactComponent as CircularEdit } from "../../../assets/icons/circularEdit.svg";
import { ReactComponent as Updown } from "../../../assets/icons/updown.svg";
import { Popover, Typography } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";
import { firstLetterCapitalize } from "../../utils/strHelper";
import { PermissionsGate } from "../../auth/PermissionGate";
import ls from "localstorage-slim";
const useStyles = makeStyles((theme) => ({
  paddingLeft: {
    paddingLeft: "10px!important",
  },
  rowText: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "14px",
    color: "#FFFFFF",
  },
  table: {
    minWidth: 650,
  },
  tableHeaderText: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#898592",
    borderBottom: "none",
    whiteSpace: "nowrap",
    padding: "0px 16px 0px 16px",
  },
  tableContainer: {
    marginTop: "3%",
    height: "60vh",
    scrollbarWidth: "none",
    // overflowY: "scroll",
    [theme.breakpoints.down("md")]: {
      height: "48vh",
    },
    [theme.breakpoints.down("sm")]: {
      height: "44vh",
    },
    [theme.breakpoints.down("xs")]: {
      height: "42vh",
    },
  },
  button: {
    minWith: "95px",
    paddingTop: "10px",
    background:
      "linear-gradient(0deg, #5626C5, #5626C5),linear-gradient(100deg, #711CF1, #711CF1)",

    color: "rgb(255, 255, 255)",
    // background:
    //   "linear-gradient(rgb(170, 111, 254) 0%, rgb(113, 28, 241) 100%)",
    borderRadius: "7px",
    paddingBottom: "10px",
    border: "none",
    paddingLeft: "13px",
    paddingRight: "13px",
  },

  buttonText: {
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "22px",
    color: "#FFFFFF",
  },
}));

function MasterProductsContent({
  masterProductsData,
  emptyList,
  setOpenNav,
  setActive,
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  // const { roleType, userPermissionsArr, scopes } = useGetRole();
  const scopes = JSON.parse(ls.get("scopes"));
  const handleSelectedProduct = (id, isin) => {
    navigate("/masters/products/update", {
      state: { ...state, id, isin },
    });
  };
  function btn(dat) {
    if (state.master == "mf") {
      return (
        <>
          {(state.master === "mf" && dat?.is_active) ||
          state.master !== "mf" ? ( //change to mf currently mf1 cux api not read
            ""
          ) : (
            <button
              style={{ minWidth: "95px" }}
              className={classes.button + " " + classes.buttonText + " cursor"}
              onClick={() => {
                if (setOpenNav) {
                  setOpenNav(true);
                }
              }}
            >
              Upload Nav
            </button>
          )}
        </>
      );
    }
    if (state.master == "equity") {
      return (
        <>
          {dat?.price_feed == 1 ? ( //change to mf currently mf1 cux api not read
            ""
          ) : (
            <button
              style={{ minWidth: "95px" }}
              className={classes.button + " " + classes.buttonText + " cursor"}
              onClick={() => {
                if (setOpenNav) {
                  setOpenNav(true);
                }
              }}
            >
              Upload Price
            </button>
          )}
        </>
      );
    }
    if (state.master == "bonds") {
      return (
        <>
          {dat?.isUploadAllowed == 0 ? ( //change to mf currently mf1 cux api not read
            ""
          ) : (
            <button
              style={{ minWidth: "95px" }}
              className={classes.button + " " + classes.buttonText + " cursor"}
              onClick={() => {
                if (setOpenNav) {
                  setOpenNav(true);
                }
              }}
            >
              Upload Price
            </button>
          )}
        </>
      );
    }
    if (state.master == "structured") {
      return (
        <>
          {dat?.isUploadAllowed == 0 ? ( //change to mf currently mf1 cux api not read
            ""
          ) : (
            <button
              style={{ minWidth: "95px" }}
              className={classes.button + " " + classes.buttonText + " cursor"}
              onClick={() => {
                if (setOpenNav) {
                  setOpenNav(true);
                }
              }}
            >
              Upload Price
            </button>
          )}
        </>
      );
    }
  }
  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="dropbox">
          <span
            onClick={() => {
              setActive("active");
            }}
          >
            Active
          </span>
          <span
            onClick={() => {
              setActive("inactive");
            }}
          >
            Inactive
          </span>
        </div>
      </Popover>
      <TableContainer className={classes.tableContainer}>
        {emptyList ? (
          <Typography
            style={{ color: "#FFFFFF", marginTop: "10%", textAlign: "center" }}
          >
            List not found!
          </Typography>
        ) : (
          <>
            <Table
              className={classes.table + " seperate "}
              aria-label="simple table"
            >
              <TableHead style={{ borderRadius: "20px" }}>
                <TableRow>
                  {/* <TableCell
                    align="left"
                    style={{
                      borderTopLeftRadius: "20px",
                      borderBottomLeftRadius: "20px",
                    }}
                    className={classes.tableHeaderText}
                  >
                    Product Code
                  </TableCell> */}
                  <TableCell align="left" className={classes.tableHeaderText}>
                    {state.master === "bonds" ||
                    state.master === "mf" ||
                    state.master === "equity"
                      ? "ISIN"
                      : "PRODUCT CODE"}
                  </TableCell>
                  <TableCell align="left" className={classes.tableHeaderText}>
                    SCHEME NAME
                  </TableCell>
                  <TableCell align="left" className={classes.tableHeaderText}>
                    ASSET CLASS
                  </TableCell>
                  {state.master === "bonds" && (
                    <TableCell align="left" className={classes.tableHeaderText}>
                      IS LISTED
                    </TableCell>
                  )}
                  <TableCell align="left" className={classes.tableHeaderText}>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <div>STATUS</div>{" "}
                      <div
                        style={{ paddingLeft: "10px" }}
                        aria-describedby={id}
                        variant="contained"
                        onClick={handleClick}
                      >
                        <Updown className="themeIcons" />
                        <Popover
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        ></Popover>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.tableHeaderText}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow style={{ height: "10px" }}></TableRow>
                {masterProductsData?.map((dat) => (
                  <TableRow key={dat?.id}>
                    {/* <TableCell
                      className={`${classes.rowText} ${classes.paddingLeft}`}
                      style={{ borderBottom: "1px solid #28235C" }}
                      align="left"
                    >
                      {dat?.code}
                    </TableCell> */}
                    <TableCell
                      className={classes.rowText}
                      style={{ borderBottom: "1px solid #28235C" }}
                      align="left"
                    >
                      {state.master === "re" ? dat.project_code : dat.isin}
                    </TableCell>
                    <TableCell
                      className={classes.rowText}
                      style={{ borderBottom: "1px solid #28235C" }}
                      align="left"
                    >
                      {state.master === "reits"
                        ? dat.company_name
                        : state.master === "mld"
                        ? dat.issuer_name
                        : state.master === "private"
                        ? dat.scrip_name
                        : state.master === "re"
                        ? dat.project_name
                        : state.master === "invits"
                        ? dat.name
                        : state.master === "equity"
                        ? dat.company_name
                        : dat.scheme_name
                        ? dat.scheme_name
                        : "--"}
                    </TableCell>
                    <TableCell
                      className={classes.rowText + " tLeftBorderRadius5"}
                      style={{ borderBottom: "1px solid #28235C" }}
                      align="left"
                    >
                      {state.master === "reits" ||
                      state.master === "mld" ||
                      state.master === "invits"
                        ? firstLetterCapitalize(dat?.asset_type)
                        : dat?.asset_class
                        ? firstLetterCapitalize(dat?.asset_class)
                        : "--"}
                    </TableCell>
                    {state.master === "bonds" && (
                      <TableCell
                        className={classes.rowText}
                        style={{ borderBottom: "1px solid #28235C" }}
                        align="left"
                      >
                        {firstLetterCapitalize(dat?.is_listed)}
                      </TableCell>
                    )}
                    <TableCell
                      className={classes.rowText}
                      style={{ borderBottom: "1px solid #28235C" }}
                      align="left"
                    >
                      {dat?.is_active ? "Active" : "Inactive"}
                    </TableCell>
                    <TableCell
                      className={classes.rowText}
                      style={{ borderBottom: "1px solid #28235C" }}
                      align="center"
                    >
                      {btn(dat)}
                    </TableCell>
                    <TableCell
                      className={classes.rowText + " tRightBorderRadius5"}
                      style={{ borderBottom: "1px solid #28235C" }}
                      align="center"
                    >
                      <PermissionsGate
                        scopes={[scopes.can_update_master_product]}
                      >
                        <CircularEdit
                          onClick={() =>
                            handleSelectedProduct(dat.id, dat.isin)
                          }
                          style={{ cursor: "pointer" }}
                        />
                      </PermissionsGate>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}
      </TableContainer>
    </>
  );
}

export default MasterProductsContent;
