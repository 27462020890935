import React, { useState, useEffect } from "react";
import { CheckedSvgIcon } from "./common/CheckedSvgIcon";
import { useAdvisors } from "./hooks/useAdvisors";
import { UncheckedSvgIcon } from "./common/UncheckedSvgIcon";
import { useNewFilterStyles } from "./newFilterStyles";
import Loader from "../../loader/Loader";

export const NewFilterAdvisor = ({
  selectedAdvisors,
  setSelectedAdvisors,
  advisors,
  advisorsLoading,
}) => {
  const classes = useNewFilterStyles();
  const toggleAdvisorSelection = (advisor) => {
    // Prevent deselection of the last remaining advisor
    if (selectedAdvisors.length === 1 && selectedAdvisors.includes(advisor)) {
      return;
    }

    if (selectedAdvisors.includes(advisor)) {
      setSelectedAdvisors((prev) => prev.filter((a) => a !== advisor));
    } else {
      setSelectedAdvisors((prev) => [...prev, advisor]);
    }
  };

  return (
    <>
      {advisorsLoading && <Loader />}
      <div className="newFilterAdvisorContainer" style={{ flexWrap: "wrap" }}>
        {advisors?.map((advisor, index) => (
          <div
            className={classes.advisorCard}
            onClick={() => toggleAdvisorSelection(advisor)}
          >
            <div className="advisorCardTxtContainer">
              <p className="advisorCardSubHeading">Advisor Name</p>
              <h1 className="advisorCardHeading">{advisor}</h1>
            </div>
            <div className="advisorCardCheckedUncheckedIcon">
              {selectedAdvisors.includes(advisor) ? (
                <CheckedSvgIcon cssClassName="checkedSvgIcon" />
              ) : (
                <UncheckedSvgIcon cssClassName="uncheckedSvgIcon" />
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
