import React from "react";
import Default from "../components/dashboard/defaultPages/Default";
import NewClientMaping from "../components/newClientList/NewClientMaping";

function ClientMapingPage() {
  return (
    <Default>
      <NewClientMaping />
    </Default>
  );
}

export default ClientMapingPage;
