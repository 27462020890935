const align = "center";

export const bankTransactionConfig = [
  { field: "pan", title: "Pan No.", show: true, align: align },
  { field: "product_code", title: "Product Code", show: true, align: align },
  { field: "bank_name", title: "Bank Name", show: true, align: align },
  { field: "account_type", title: "Account Type", show: true, align: align },
  {
    field: "account_no",
    title: "Account Number",
    show: true,
    align: align,
  },
  {
    field: "transaction_date",
    title: "Transaction Date",
    show: true,
    align: align,
  },
  {
    field: "transaction_type",
    title: "Transaction Type",
    show: true,
    align: align,
  },
  {
    field: "amount",
    title: "Transaction Amount (₹)",
    show: true,
    align: align,
  },
  {
    field: "reference_no",
    title: "Transaction ID",
    show: true,
    align: align,
  },
  {
    field: "select",
    title: "",
    show: false,
    cellCustomClass: "selectCell",
  },
  //   { field: "quantity", title: "Quantity", show: true, align: align },
  //   {
  //     field: "gross_rate_per_unit",
  //     title: "Gross Rate Per Unit (Actuals)",
  //     show: true,
  //     align: align,
  //   },
  //   {
  //     field: "brokerage_per_unit",
  //     title: "Brokerage Per Unit (Actuals)",
  //     show: true,
  //     align: align,
  //   },
  //   {
  //     field: "gst_sst_other_charges",
  //     title: "GST+SST+Other Charges (Actuals)",
  //     show: true,
  //     align: align,
  //   },
  //   {
  //     field: "net_rate_per_unit",
  //     title: "Net Rate Per Unit (Actuals)",
  //     show: true,
  //     align: align,
  //   },
  //   {
  //     field: "total_transaction_value",
  //     title: "Total Transaction Value (Actuals)",
  //     show: true,
  //     align: align,
  //   },
  //   {
  //     field: "cumulative_quantity",
  //     title: "Cumulative Quantity",
  //     show: true,
  //     align: align,
  //   },
];

export const fundsInTransitTransactionConfig = [
  // { field: "asset_type", title: "Asset Type", show: true, align: align },
  { field: "pan", title: "PAN", show: true, align: align },
  { field: "member_name", title: "Member Name", show: true, align: align },
  { field: "product_name", title: "Product Name", show: true, align: align },
  {
    field: "transaction_date",
    title: "Transaction Date",
    show: true,
    align: align,
  },
  {
    field: "amount",
    title: "Amount",
    show: true,
    align: align,
  },
  {
    field: "narration",
    title: "Narration",
    show: true,
    align: align,
  },
  {
    field: "select",
    title: "",
    show: false,
    cellCustomClass: "selectCell",
  },
];

export const bankHoldingConfig = [
  { field: "bankName", title: "Bank Name", show: true, align: align },
  { field: "memberName", title: "Member Name", show: true, align: align },
  {
    field: "accountNo",
    title: "Account No",
    show: true,
    align: align,
  },
  { field: "accountType", title: "Account Type", show: true, align: align },
  {
    field: "presentValue",
    title: "Amount (₹)",
    show: true,
    align: align,
  },
];
