import React, { useState, useEffect, useCallback } from "react";
import useStyles from "./ProductsStyles";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Modal,
  Button,
  Menu,
} from "@material-ui/core";
import PageLoader from "./pageLoader";
import CheckBoxWithColor from "./CheckBoxWithColor";
import moment from "moment";
import "../common/products-style.css";
import TableContainer from "@material-ui/core/TableContainer";
import DataNotFound from "./DataNotFound";
import { ReactComponent as Transaction } from "../../../../assets/icons/transaction-type.svg";
import { ReactComponent as ColEditIcon } from "../../../../assets/icons/ColEditIcon.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ReactComponent as Marketuparrow } from "../../../../assets/clientdashboard/marketuparrow.svg";
import { ReactComponent as Marketdownarrow } from "../../../../assets/clientdashboard/marketdownarrow.svg";
import ValueWithArrow from "../equity/utils/ValueWithArrow";
import { ReactComponent as UpArrow } from "../../../../assets/icons/upArrow.svg";

function SortableTable({
  setTableColumns,
  tableColumns,
  setOpenModal,
  openModal,
  tableName,
  loading,
  pageName,
  tableHeaderBGColor,
  headerList,
  tableHeader,
  setTableHeader,
  stickyProductName,
  checkedTransList,
  tableBodyBackground,
}) {
  const [tickedColumns, setTickedColumns] = useState(headerList);
  const [tableReOrderConfig, setTableReOrderConfig] = useState([]);
  const [resetTable, setResetTable] = useState();
  const [checkedTrans, setCheckedTrans] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    field: "",
    order: false,
  });

  useEffect(() => {
    if (checkedTransList?.length > 0) {
      setCheckedTrans([...checkedTransList]);
    }
  }, [checkedTransList]);

  const trFilter = () => {
    let checkList = checkedTrans
      .filter((val) => val.show == true)
      .map((val) => val?.title?.toLowerCase());

    setTableColumns((prev) => {
      prev.map((row) => {
        let add = false;
        row.map((column) => {
          if (checkList.length == 0) {
            add = true;
          }
          if (checkList?.includes(column?.field)) {
            add = true;
          }
        });
        if (add == true) {
          row.map((column) => {
            tickedColumns?.map((col) => {
              if (col?.title == column?.title) {
                column.show = col?.show;
              }
            });
            return column;
          });
        } else {
          row.map((column) => {
            column.show = false;
            return column;
          });
        }
      });
      return [...prev];
    });
    handleCloseEvent();
  };

  const handleCheckBoxClickTrans = (e) => {
    const list = [...checkedTrans];
    list.map((col, index) => {
      if (col.title === e.target.name) {
        const data = { ...col };
        data["show"] = !col.show;
        list[index] = { ...col, show: !col.show };
      }
    });
    setCheckedTrans(list);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClickEvent = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseEvent = () => {
    setAnchorEl(null);
  };
  const handleReset = () => {
    setTickedColumns(headerList);
    setTableHeader(headerList);
    setTableReOrderConfig([]);
    setTableColumns((prev) => {
      let newList = [];
      prev?.map((row) => {
        let res = getResetedRow(row, headerList);
        newList.push(res);
      });
      return newList;
    });
    setOpenModal(!openModal);
  };

  const getResetedRow = (row, configArray) => {
    let newRow = [];
    configArray.map((column) => {
      row.map((oldCol) => {
        if (column.title == oldCol.title) {
          oldCol.show = column.show;
          newRow.push(oldCol);
        }
      });
    });

    return newRow;
  };

  const sort = (title, order) => {
    // setSortConfig({
    //   title: title,
    //   order: order,
    // });

    let sortFunction;
    const getFirstAndSecond = (columnVal) => {
      let first;
      let second;
      if (title == "Quantity Per Unit") {
        first = columnVal.filter((val) => val.title == title)[0]?.field
          ?.precentage;
        // second = tableColumns.filter((val) => val.title == title)[0]?.field
        //   ?.precentage;
      } else {
        first = columnVal.filter((val) => val.title == title)[0]?.field?.value;
        // second = tableColumns.filter((val) => val.title == title)[0]?.field
        //   ?.value;
      }

      first = String(first);

      if (first?.includes("-")) {
        first = first
          .split("")
          .splice(2, first.length)
          .join("")
          .replaceAll(",", "");
      } else {
        first = first
          .split("")
          .splice(1, first.length)
          .join("")
          .replaceAll(",", "");
      }

      return Number(first);
    };

    if (order == true) {
      sortFunction = (first, second) =>
        getFirstAndSecond(first) - getFirstAndSecond(second);
    } else {
      sortFunction = (first, second) =>
        getFirstAndSecond(second) - getFirstAndSecond(first);
    }

    setTableColumns((prev) => {
      let newList = prev.pop();
      let sortedList = prev?.sort(sortFunction);

      return [...sortedList, newList];
    });
  };

  const handleButtonClickApply = () => {
    setTableHeader(tickedColumns);
    let filterList = tickedColumns
      .filter((val) => val.show == false)
      .map((val) => val.title);

    setTableColumns((prev) => {
      let newRows = [];
      prev.map(async (row) => {
        let newList = reOrderBody(row);
        newList.map((column) => {
          // console.log(filterList.includes(column.title), 'asfefw');
          if (filterList.includes(column.title)) {
            column.show = false;
          } else {
            column.show = true;
          }
          return column;
        });
        newRows.push(newList);
      });
      return [...newRows];
    });
    setOpenModal(!openModal);
    setTableReOrderConfig([]);
  };

  const handleCheckBoxClick = (e) => {
    const list = [...tickedColumns];
    list.map((col, index) => {
      if (col.title.includes(e.target.name)) {
        const data = { ...col };
        data["show"] = !col.show;

        list[index] = { ...col, show: !col.show };
      }
    });
    setTickedColumns(list);
  };

  const tableHeaders = (headers) => {
    return headers?.map((column, index) =>
      column && column.show ? (
        <TableCell
          style={{
            borderBottom: "none",
            textTransform: "capitalize",
            backgroundColor: tableHeaderBGColor || "#4E189F",
          }}
          key={column.title}
          align={column?.align || "right"}
          className={`${classes.tableHeaderTextNew} thStyle ${
            stickyProductName ? "stickyCellHed" : ""
          } textCapitalize`}
          colSpan={column?.title == "Stock Name" ? "1" : "2"}
          // onClick={() => sort(column?.title, true)}
        >
          <div
            style={{
              display: "flex",
              gap: "4px",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", gap: "4px" }}>
              {column.title === "Transaction Type" &&
              checkedTrans?.length > 0 ? (
                <div style={{ display: "flex", marginTop: "5px" }}>
                  Transaction Type
                  <span
                    onClick={handleClickEvent}
                    style={{ cursor: "pointer", marginLeft: "20px" }}
                  >
                    <Transaction />
                  </span>
                </div>
              ) : column.title.includes("*") ? (
                column.title.split("*")[0]
              ) : (
                column.title
              )}
              {column.title.includes("*") && (
                <>
                  <br />
                  <div
                    className="headSubTextContainer"
                    style={{ justifyContent: "right" }}
                  >
                    <p className="headSubText">
                      {column.title.includes("*")
                        ? "*" + column.title.split("*")[1]
                        : ""}
                    </p>
                  </div>
                </>
              )}
              {column.sort && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "3px",
                    marginTop: "2px",
                  }}
                >
                  <UpArrow
                    width={"10px"}
                    height={"10px"}
                    onClick={() => {
                      setSortConfig({
                        title: column?.title,
                        order: true,
                      });
                      sort(column?.title, true);
                    }}
                    className={
                      sortConfig.title == column?.title &&
                      sortConfig.order == true
                        ? "svgSelected"
                        : ""
                    }
                  />
                  <UpArrow
                    height={"10px"}
                    width={"10px"}
                    onClick={() => {
                      setSortConfig({
                        title: column?.title,
                        order: false,
                      });
                      sort(column?.title, false);
                    }}
                    className={
                      sortConfig.title == column?.title &&
                      sortConfig.order == false
                        ? "svgSelected"
                        : ""
                    }
                    style={{
                      transform: "rotate(180deg)",
                    }}
                  />
                </div>
              )}
            </div>
            {column?.subHeader && (
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: column?.align,
                  color: "#9488A7",
                }}
              >
                <span>{column?.subHeader[0]}</span>
                <span className="subHeadDivider"></span>
                <span>{column?.subHeader[1]}</span>
              </div>
            )}
          </div>
        </TableCell>
      ) : null
    );
  };

  const tableBody = (body) => {
    return body?.map((rows) => (
      <TableRow className="tableRowPms">
        {rows?.map((column, i) =>
          column && column?.hideData ? (
            <>
              <TableCell
                key={column.title}
                align={column?.align || "right"}
                className={`${classes.tableBodyText} tdPms ${
                  stickyProductName ? "stickyCell" : ""
                } textCapitalize`}
                style={{
                  // position: "relative",
                  cursor: column?.onClick ? "pointer" : "",
                  paddingTop: "38px",
                  // backgroundColor: tableBodyBackground || "#231E53",
                }}
              />
              <TableCell
                key={column.title}
                align={column?.align || "right"}
                className={`${classes.tableBodyText} tdPms ${
                  stickyProductName ? "stickyCell" : ""
                } textCapitalize`}
                style={{
                  // position: "relative",
                  cursor: column?.onClick ? "pointer" : "",
                  paddingTop: "38px",
                  // backgroundColor: tableBodyBackground || "#231E53",
                }}
              />
            </>
          ) : column.show ? (
            <>
              <TableCell
                key={column.title}
                align={column?.align || "right"}
                className={`${classes.tableBodyText} tdPms ${
                  stickyProductName ? "stickyCell" : ""
                } textCapitalize`}
                style={{
                  // position: "relative",
                  cursor: column?.onClick ? "pointer" : "",
                  paddingTop: "38px",
                  // backgroundColor: tableBodyBackground || "#231E53",
                }}
              >
                <div
                  style={
                    column.title == "Address" && pageName !== "holding"
                      ? {
                          width: "260px",
                          whiteSpace: "pre-wrap",
                          overFlowWrap: "break-word",
                        }
                      : {
                          width: "auto",
                          whiteSpace: "nowrap",
                          overFlowWrap: "break-word",
                        }
                  }
                  onClick={() => {
                    if (column?.onClick) column?.onClick();
                  }}
                >
                  {/* {column.title == 'Advisor' && pageName !== "holding" &&
                                    <span
                                        style={{
                                            position: 'absolute',
                                            top: '0px', left: '0px',
                                            padding: '4px 4px 4px 18px',
                                            background: 'linear-gradient(180deg, #AA6FFE 0%, #711CF1 100%)',
                                            borderRadius: '22px 0px 0px 0px'
                                        }}>
                                        updated on {moment(column.field.split("%")[1]).format('DD/MM/YYYY')}
                                    </span>
                                } */}
                  {column.title == "Advisor" ? (
                    column.field.split("%")[0]
                  ) : (
                    <ValueWithArrow
                      align={column?.align}
                      precentage={column.field?.precentage}
                      showArrow={column?.showArrow}
                      value={column.field?.value}
                    />
                  )}
                </div>
              </TableCell>

              {column?.title !== "Stock Name" && (
                <>
                  <TableCell
                    key={column.title}
                    align={"left"}
                    className={`${classes.tableBodyText} tdPms ${
                      stickyProductName ? "stickyCell" : ""
                    } textCapitalize`}
                    style={{
                      // position: "relative",
                      cursor: column?.onClick ? "pointer" : "",
                      paddingTop: "38px",
                      // backgroundColor: tableBodyBackground || "#231E53",
                      paddingLeft: "0px",
                    }}
                  >
                    <Typography
                      className={
                        column?.showArrow
                          ? column.field?.precentage >= 0 && column?.showArrow
                            ? "up-green"
                            : "down-red"
                          : ""
                      }
                    >
                      {column.field?.precentage}
                    </Typography>
                  </TableCell>
                </>
              )}
            </>
          ) : (
            ""
          )
        )}
      </TableRow>
    ));
  };

  const handleButtonClick = () => {
    setOpenModal(!openModal);
    setTableColumns(() => [...tickedColumns]);
  };

  // drag fuctionality

  const onDragEndAction = (result) => {
    // console.log(result, "result131341");
    let newList = reorder(
      tickedColumns,
      result.source.index,
      result.destination.index
    );
    setTickedColumns(newList);
    setTableReOrderConfig((prev) => [
      ...prev,
      {
        sourceIndex: result.source.index,
        destinationIndex: result.destination.index,
      },
    ]);
  };

  const reOrderBody = (row) => {
    let newRow = row;

    /* maping through this tableReOrderConfig for to reflect 
    changes on multiple drag */
    tableReOrderConfig.map((val) => {
      newRow = reorder(newRow, val?.sourceIndex, val?.destinationIndex);
    });
    return newRow;
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const getItemStyle = (isDragging = false, draggableStyle) => {
    return {
      ...draggableStyle,
    };
  };

  const classes = useStyles();

  return (
    <>
      <Typography
        style={{ cursor: "pointer", margin: "2%", fontSize: "20px" }}
        className={`${classes.titleText} capitalize`}
      >
        {tableName || ""}
      </Typography>
      <div
        style={{
          minHeight: "300px",
          width: "-webkit-fill-available",
          margin: "2%",
          maxHeight: "400px",
          display: "flex",
          position: "relative",
        }}
      >
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead className="tableRowStyle tableSticky">
              <TableRow>{tableHeaders(tableHeader)}</TableRow>
            </TableHead>
            <TableBody className="tableBodyPms tableSticky">
              <TableRow style={{ height: "10px" }} />
              {tableBody(tableColumns)}
            </TableBody>
          </Table>
        </TableContainer>
        {tableColumns?.length == 0 && (
          <div
            style={{
              position: "absolute",
              left: "44%",
              top: "14%",
            }}
          >
            {!loading && <DataNotFound />}
          </div>
        )}
      </div>
      <Modal
        open={openModal}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={`${classes.modalContainer} form-container`}>
          <div className="filter-header">
            <Typography className="filter-header-text">
              Modify Columns
            </Typography>
          </div>
          <hr className="filter-divider"></hr>
          <div className="member">
            <div className="filter-header" style={{ alignItems: "center" }}>
              <Typography className="filter-header-text">
                Select Columns
              </Typography>
              <Button
                // variant="contained"
                className={classes.buttonNo}
                onClick={handleReset}
              >
                <span className={`${classes.buttonText}`}>Reset</span>
              </Button>
            </div>
            <div className="member-content">
              <DragDropContext onDragEnd={onDragEndAction}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {tickedColumns?.map((column, index) =>
                        column && column.title !== "" ? (
                          <Draggable
                            key={column.title}
                            draggableId={column.title}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                                className="filter-header"
                              >
                                <div
                                  className="colEditItems"
                                  style={{
                                    opacity: snapshot.isDragging ? "0.5" : "1",
                                  }}
                                >
                                  <CheckBoxWithColor
                                    checked={column.show}
                                    value={column.title}
                                    name={column.field}
                                    onChange={(e) => handleCheckBoxClick(e)}
                                  />
                                  <Typography className="filter-value-name textCapitalize">
                                    {column.title}
                                  </Typography>
                                  <ColEditIcon
                                    className={`colEditIcon ${
                                      column.show == true
                                        ? ""
                                        : "tableColumnSelected"
                                    }`}
                                    width={"30px"}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ) : null
                      )}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>

          <hr className="filter-divider"></hr>
          <div className={classes.buttonContainer}>
            <Button
              // variant="contained"
              className={classes.buttonNo}
              style={{ width: "60%", marginTop: "5%" }}
              onClick={() => {
                setOpenModal(!openModal);
              }}
            >
              <span className={`${classes.buttonText}`}>Cancel</span>
            </Button>
            <Button
              // variant="contained"
              className={classes.buttonApplyModal}
              style={{ width: "60%", marginTop: "5%" }}
              onClick={handleButtonClickApply}
            >
              <span className={`${classes.buttonText}`}>Apply</span>
            </Button>
          </div>
        </div>
      </Modal>

      <Menu
        // id="demo-positioned-menu"
        // aria-labelledby="demo-positioned-button"
        className="modal-wrapper"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseEvent}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        // transformOrigin={{
        //     vertical: 'top',
        //     horizontal: 'center',
        // }}
        sx={{
          backgroundColor: "#711CF1 !important",
          borderRadius: "20px",
          border: "1px solid #4E189F",
          // position: "absolute",
          outline: 0,
          // top: "15%",
          // right: "40%",
          width: "220px",
          padding: "15px",
          height: "200px",
          overflow: "scroll",
          boxShadow: "-4px 4px 10px rgba(0, 0, 0, 0.5)",
        }}
        style={{ minWidth: "400px" }}
      >
        <div className="member">
          <div className="member-content">
            {checkedTrans?.length > 0 &&
              checkedTrans?.map((column) =>
                column && column.title !== "" ? (
                  <div key={column.title} className="filter-header">
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <CheckBoxWithColor
                        checked={column.show}
                        value={column.title}
                        name={column.title}
                        onChange={(e) => handleCheckBoxClickTrans(e)}
                      />
                      <Typography className="filter-value-name avtar-text textCapitalize">
                        {column.title}
                      </Typography>
                    </span>
                  </div>
                ) : null
              )}
          </div>
        </div>
        <hr className="filter-divider"></hr>
        <div className={classes.buttonContainerTransaction}>
          <Button
            // variant="contained"
            className={classes.buttonNo}
            style={{ width: "60%", marginTop: "5%" }}
            onClick={handleCloseEvent}
          >
            <span className={`${classes.buttonText}`}>Cancel</span>
          </Button>
          <Button
            // variant="contained"
            className={classes.buttonApplyModal}
            style={{ width: "60%", marginTop: "5%" }}
            onClick={trFilter}
          >
            <span className={`${classes.buttonText}`}>Apply</span>
          </Button>
        </div>
      </Menu>

      <PageLoader showLoader={loading} />
    </>
  );
}

export default SortableTable;
