import { routes } from "../../routes";
import { colors_allocation } from "../charts/colors";

const {
  currencyFormat,
  currencyFormatNoRoundOff,
} = require("../../hooks/constantFunction");
const { roundNumber, dateFormatCommon } = require("./common");
const {
  currencyFormatCheckArray,
  roundNumberArray,
  dateFormatingArray,
  doNothingArray,
  currency_format_exception,
  percentageArray,
  bankBookDoNothing,
  bankBookCurrencyFormat,
  bankBookPercentage,
} = require("./constands");

export const objectFormating1 = (
  data, // val
  headerData,
  navigate,
  isToggleChecked
) => {
  let newData = headerData.map((header) => {
    if (header.field == "select") {
      return {
        key: header.field,
        comp: data[`${header.field}`],
        show: header.show,
        title: header.title,
      };
    }

    if (header?.field == "executed_by") {
      return {
        key: header.field,
        field: data[`${header.field}`] ? data[`${header.field}`] : "--",
        title: header.title,
        show: header.show,
        align: header?.align,
        // tagPresent: data[`${header.is_corp_action}`],
        tagPresent: data.is_corp_action,
      };
    }
    if (
      header?.field === "corp_action" ||
      header.field === "corp_action_effects" ||
      header?.field === "remarks" ||
      header?.field === "ratio" ||
      header?.field === "remark"
    ) {
      return {
        ...header,
        key: header.field,
        field: data[`${header.field}`] ? data[`${header.field}`] : "--",
        title: header.title,
        show: header.show,
        align: header?.align,
        // tagPresent: data[`${header.is_corp_action}`],
        tagPresent: data.is_corp_action,
      };
    }

    if (header?.field == "advisor" && isToggleChecked) {
      return {
        key: header.field,
        field: data[`${header.field}`] ? data[`${header.field}`] : "--",
        title: header.title,
        show: header.show,
        align: header?.align,
        // tagPresent: true,
        // tagPresent: data[`${header.is_corp_action}`],
        tagPresent: data.is_corp_action,
      };
    }
    if (header?.field == "scripName") {
      return {
        key: header.field,
        field: data[`${header.field}`],
        title: header.title,
        show: header.show,
        align: header?.align,
        // onClick: () => {
        //   navigate(`/home/equity/equity-summary/stock-details/${data?.isin}`, {
        //     state: { isin: data?.isin },
        //   });
        // },
        onClick:
          typeof navigate == "function"
            ? () => {
                navigate(routes?.equityHoldingPeriod, {
                  state: {
                    isin: data?.isin,
                    advisor: data?.advisor,
                    assetClass: "equity",
                    type: "equity",
                  },
                });
              }
            : () => {},
        // tagPresent: true,
        // tagPresent: data[`${header.is_corp_action}`],
        tagPresent: data.is_corp_action,
        tagPresentPromoterStake: data?.isPromoterStake,
      };
    }
    if (header?.field == "scrip_name") {
      return {
        key: header.field,
        field: data[`${header.field}`],
        title: header.title,
        show: header.show,
        align: header?.align,
        onClick:
          typeof navigate == "function"
            ? () => {
                navigate(routes?.equityStockdetails + `${data?.isin}`, {
                  state: { isin: data?.isin },
                });
              }
            : () => {},
        // tagPresent: true,
        // tagPresent: data[`${header.is_corp_action}`],
        tagPresent: data.is_corp_action,
        tagPresentPromoterStake: data?.isPromoterStake,
      };
    }
    if (doNothingArray.includes(header?.field)) {
      return {
        key: header.field,
        field: data[`${header.field}`] ? String(data[`${header.field}`]) : "--",
        title: header.title,
        show: header.show,
        align: header?.align,
        // tagPresent: true,
        // tagPresent: data[`${header.is_corp_action}`],
        tagPresent: data.is_corp_action,
      };
    }
    if (header?.field == "holdingPeriod") {
      return {
        key: header.field,
        field: data[`${header.field}`]
          ? data[`${header.field}`] + " days"
          : "--",
        title: header.title,
        show: header.show,
        align: header?.align,
        // tagPresent: true,
        // tagPresent: data[`${header.is_corp_action}`],
        tagPresent: data.is_corp_action,
      };
    }
    if (
      header?.field == "TWRR_annualised" ||
      header?.field == "annualisedTwrr"
    ) {
      return {
        key: header.field,
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`]))
          : "--",
        title: header.title,
        show: header.show,
        align: header?.align,
        // tagPresent: true,
        // tagPresent: data[`${header.is_corp_action}`],
        tagPresent: data.is_corp_action,
      };
    }
    if (
      header?.field == "weightedAvgCost" ||
      header?.field == "nav" ||
      header?.field == "currentPrice" ||
      header?.field == "gross_rate_per_unit" ||
      header?.field == "price" ||
      header?.field == "stamp_duty" ||
      header?.field == "brokerage_per_unit" ||
      header?.field == "gst_sst_other_charges" ||
      header?.field == "net_rate_per_unit"
    ) {
      let currencyformat = currencyFormatNoRoundOff(
        data[`${header.field}`] || 0,
        {
          format: "actuals",
          showDecimal: true,
        }
      );

      return {
        key: header.field,
        field: currencyformat || "--",
        title: header.title,
        show: header.show,
        align: header?.align,
        // tagPresent: true,
        // tagPresent: data[`${header.is_corp_action}`],
        tagPresent: data.is_corp_action,
      };
    }

    if (currencyFormatCheckArray.includes(header?.field)) {
      let currencyformat = currencyFormat(data[`${header.field}`] || 0, {
        showDecimal: true,
      });
      if (currency_format_exception.includes(header?.field)) {
        currencyformat = currencyFormat(data[`${header.field}`] || 0, {
          format: "actuals",
          showDecimal: true,
        });
      }
      return {
        key: header.field,
        field: currencyformat || "--",
        title: header.title,
        show: header.show,
        align: header?.align,
        // tagPresent: true,
        // tagPresent: data[`${header.is_corp_action}`],
        tagPresent: data.is_corp_action,
      };
    }

    if (dateFormatingArray.includes(header?.field)) {
      return {
        key: header.field,
        field: data[`${header.field}`]
          ? dateFormatCommon(new Date(data[`${header.field}`]))
          : "--",
        // moment(new Date(data[`${header.field}`])).format(
        //   "DD - MM - YYYY"
        // ) || "--",
        title: header.title,
        show: header.show,
        align: header?.align,
        // tagPresent: true,
        // tagPresent: data[`${header.is_corp_action}`],
        tagPresent: data.is_corp_action,
      };
    }

    if (percentageArray.includes(header?.field)) {
      return {
        key: header.field,
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`])) + "%"
          : data[`${header.field}`] || "--",
        title: header.title,
        show: header.show,
        align: header?.align,
        // tagPresent: true,
        // tagPresent: data[`${header.is_corp_action}`],
        tagPresent: data.is_corp_action,
      };
    }

    return {
      ...header,
      key: header.field,
      field:
        typeof data[`${header.field}`] == "number" &&
        data[`${header.field}`] != null
          ? roundNumber(Number(data[`${header.field}`]))
          : data[`${header.field}`] || "--",
      title: header.title,
      show: header.show,
      align: header?.align,
      file: data?.file,
      isin: data?.isin,
      advisor: data?.advisor,
      memberId: data.memberId,

      // tagPresent: true,
      // tagPresent: data[`${header.is_corp_action}`],
      tagPresent: data.is_corp_action,
    };
  });

  return newData;
};
export const objectFormatingupcomingTransection = (
  data, // val
  headerData,
  navigate,
  isToggleChecked
) => {
  let newData = headerData.map((header) => {
    if (header.field == "select") {
      return {
        key: header.field,
        comp: data[`${header.field}`],
        show: header.show,
        title: header.title,
      };
    }

    if (
      header?.field === "corp_action" ||
      header.field === "corp_action_effects" ||
      header?.field === "remarks" ||
      header?.field === "ratio" ||
      header?.field === "remark"
    ) {
      return {
        ...header,
        key: header.field,
        field: data[`${header.field}`] ? data[`${header.field}`] : "--",
        title: header.title,
        show: header.show,
        align: header?.align,
        // tagPresent: data[`${header.is_corp_action}`],
        tagPresent: data.is_corp_action,
      };
    }

    if (header?.field == "scripName") {
      return {
        key: header.field,
        field: data[`${header.field}`],
        title: header.title,
        show: header.show,
        align: header?.align,
        // onClick: () => {
        //   navigate(`/home/equity/equity-summary/stock-details/${data?.isin}`, {
        //     state: { isin: data?.isin },
        //   });
        // },
        onClick:
          typeof navigate == "function"
            ? () => {
                navigate(routes?.equityHoldingPeriod, {
                  state: {
                    isin: data?.isin,
                    advisor: data?.advisor,
                    assetClass: "equity",
                    type: "equity",
                  },
                });
              }
            : () => {},
        // tagPresent: true,
        // tagPresent: data[`${header.is_corp_action}`],
        tagPresent: data.is_corp_action,
        tagPresentPromoterStake: data?.isPromoterStake,
      };
    }
    if (header?.field == "scrip_name") {
      return {
        key: header.field,
        field: data[`${header.field}`],
        title: header.title,
        show: header.show,
        align: header?.align,
        onClick:
          typeof navigate == "function"
            ? () => {
                navigate(routes?.equityStockdetails + `${data?.isin}`, {
                  state: { isin: data?.isin },
                });
              }
            : () => {},
        // tagPresent: true,
        // tagPresent: data[`${header.is_corp_action}`],
        tagPresent: data.is_corp_action,
        tagPresentPromoterStake: data?.isPromoterStake,
      };
    }
    ////////////////////////do nothing array
    if (
      [
        "schemeName",
        "folioNo",
        "member",
        "product",
        "type",
        "mergedStock",
        "demergedStock",
      ].includes(header?.field)
    ) {
      return {
        ...header,
        key: header.field,
        field: data[`${header.field}`] ? String(data[`${header.field}`]) : "--",
        title: header.title,
        show: header.show,
        align: header?.align,
        // tagPresent: true,
        // tagPresent: data[`${header.is_corp_action}`],
        sameAsFirst:
          header.field == "mergedStock" || header.field == "demergedStock"
            ? true
            : false,
        tagPresent: data.is_corp_action,
      };
    }
    ////////////////////////currency format value
    if (["amount", "totalAmount"].includes(header?.field)) {
      let currencyformat = currencyFormat(data[`${header.field}`] || 0, {
        showDecimal: true,
      });
      if (currency_format_exception.includes(header?.field)) {
        currencyformat = currencyFormat(data[`${header.field}`] || 0, {
          format: "actuals",
          showDecimal: true,
        });
      }
      return {
        key: header.field,
        field: currencyformat || "--",
        title: header.title,
        show: header.show,
        align: header?.align,
        // tagPresent: true,
        // tagPresent: data[`${header.is_corp_action}`],
        tagPresent: data.is_corp_action,
      };
    }
    if (["date"].includes(header?.field)) {
      return {
        key: header.field,
        field: data[`${header.field}`]
          ? dateFormatCommon(new Date(data[`${header.field}`]))
          : "--",
        // moment(new Date(data[`${header.field}`])).format(
        //   "DD - MM - YYYY"
        // ) || "--",
        title: header.title,
        show: header.show,
        align: header?.align,
        // tagPresent: true,
        // tagPresent: data[`${header.is_corp_action}`],
        tagPresent: data.is_corp_action,
      };
    }

    return {
      key: header.field,
      field:
        typeof data[`${header.field}`] == "number" &&
        data[`${header.field}`] != null
          ? roundNumber(Number(data[`${header.field}`]))
          : data[`${header.field}`] || "--",
      title: header.title,
      show: header.show,
      align: header?.align,
      // tagPresent: true,
      // tagPresent: data[`${header.is_corp_action}`],
      tagPresent: data.is_corp_action,
    };
  });

  return newData;
};

export const objectFormating2 = (
  data, // val
  headerData,
  i,
  navigate,
  isToggleChecked
) => {
  let newData = headerData.map((header) => {
    if (header.field == "select") {
      return {
        comp: data[`${header.field}`],

        show: header.show,
        title: header.title,
      };
    }

    if (
      header?.field === "corp_action" ||
      header.field === "corp_action_effects" ||
      header?.field === "remarks" ||
      header?.field === "ratio" ||
      header?.field === "remark"
    ) {
      return {
        field: data[`${header.field}`] ? data[`${header.field}`] : "--",
        title: header.title,
        show: header.show,
        align: header?.align,
        color2: i > colors_allocation.length ? "yellow" : colors_allocation[i],
        // tagPresent: data[`${header.is_corp_action}`],
        tagPresent: data.is_corp_action,
        customFontClassName: "assetWisetxt",
      };
    }

    if (bankBookDoNothing.includes(header?.field)) {
      return {
        field: data[`${header.field}`] ? String(data[`${header.field}`]) : "--",
        title: header.title,
        show: header.show,
        align: header?.align,
        color2: i > colors_allocation.length ? "yellow" : colors_allocation[i],
        // tagPresent: true,
        // tagPresent: data[`${header.is_corp_action}`],
        tagPresent: data.is_corp_action,
        customFontClassName: "assetWisetxt",
      };
    }

    if (bankBookCurrencyFormat.includes(header?.field)) {
      let currencyformat = currencyFormat(data[`${header.field}`] || 0, {
        showDecimal: true,
      });
      if (currency_format_exception.includes(header?.field)) {
        currencyformat = currencyFormat(data[`${header.field}`] || 0, {
          format: "actuals",
          showDecimal: true,
        });
      }
      return {
        field: currencyformat || "--",
        title: header.title,
        show: header.show,
        align: header?.align,
        color2: i > colors_allocation.length ? "yellow" : colors_allocation[i],
        // tagPresent: true,
        // tagPresent: data[`${header.is_corp_action}`],
        tagPresent: data.is_corp_action,
        customFontClassName: "assetWisetxt",
      };
    }

    if (bankBookPercentage.includes(header?.field)) {
      return {
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`])) + "%"
          : data[`${header.field}`] || "--",
        title: header.title,
        show: header.show,
        align: header?.align,
        color2: i > colors_allocation.length ? "yellow" : colors_allocation[i],
        // tagPresent: true,
        // tagPresent: data[`${header.is_corp_action}`],
        tagPresent: data.is_corp_action,
        customFontClassName: "assetWisetxt",
      };
    }

    return {
      field: !isNaN(data[`${header.field}`])
        ? roundNumber(Number(data[`${header.field}`]))
        : data[`${header.field}`] || "--",
      title: header.title,
      show: header.show,
      align: header?.align,
      color2: i > colors_allocation.length ? "yellow" : colors_allocation[i],
      // tagPresent: true,
      // tagPresent: data[`${header.is_corp_action}`],
      tagPresent: data.is_corp_action,
      customFontClassName: "assetWisetxt",
    };
  });

  return newData;
};
