import React, { useContext, useState } from "react";
import PageFilter from "../common/PageFilter";
import { ReactComponent as Back } from "../../../../assets/holding-summary/back-blue.svg";
import { useNavigate } from "react-router-dom";
import TabComponent from "../common/TabComponent";
import { OnboardingContext } from "../../../../Context";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import PmsHolding from "../pms/PmsHolding";
import PmsTransaction from "../pms/PmsTransaction";
import PmsPortfolio from "../pms/PmsPortfolio";
import { getTransactionsSummary } from "../../../../api";
import {
  transactionTableConfig,
  holdingTableConfig,
} from "../structured_products/utils/constands";
import {
  invitsHoldingConfig,
  reitsTransactionConfig,
  reitsTRCorporateActionsTableConfig,
} from "../REITS/reitsConfig";
import { customTableConfig } from "../mld/mldConfig";
import { objectFormating1 } from "../../../utils/objectFormating";
import MLDHolding from "../mld/MLDHolding";
import MLDTransaction from "../mld/MLDTransaction";
import useStyles from "../common/ProductsStyles";
import MLDCashFlow from "../mld/MLDCashFlow";
import { IosSwitch } from "../../../IosSwitch";
import NewTabComp from "../common/NewTabComp";
import { routes } from "../../../../routes";
import { NewFilter } from "../../newFilter/NewFilter";

const data = [
  {
    page: "holding",
    title: "Holding Summary",
    path: routes?.invitsHolding,
  },
  {
    page: "transaction",
    title: "Transaction Summary",
    path: routes?.invitsTransaction,
  },
  // {
  //   page: "cash-flow",
  //   title: "Cash Flow",
  //   path: routes?.invitsCashFlow,
  // },
];

const stData = {
  success: true,
  event: "PRIVATE_HOLDING_SUMMARY",
  message: "Private Holding Summary fetched successfully.",
  uuid: "5173c680-d444-11ed-930f-411b3acf3bb0",
  data: {
    meta: {
      filters: {
        clientId: 199,
        assetClass: "equity,debt",
      },
    },
    result: [
      {
        advisor: "neo",
        isin: "INE012A01025",
        assetType: "debt",
        transactionType: "fresh purchase",
        productSubCategory: "g-sec",
        scripName: "ACC Ltd",
        stockSymbol: "ACC",
        transactionDate: "2023-03-22",
        units: 400,
        nav: 55,
        investmentValue: 110000,
        withdrawalAmount: 0,
        presentValue: 0,
        totalGainLoss: -110000,
        realisedGainLoss: 0,
        unRealisedGainLoss: -110000,
        xirr: 0,
        absoluteTwrr: -100,
        annualisedTwrr: -1,
        avgHoldingPeriod: 0,
        percentageOfTotalPortfolio: 0,
      },
    ],
  },
};

function INVITSReport({ pageName }) {
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const [page, setPage] = useState(() => pageName);
  const [checked, setChecked] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const { userState } = OnboardingContext();
  const classes = useStyles();
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  // const [pagiNationData, setPageinationData] = useState({
  //   pageCount: 6,
  //   // limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
  // });

  const getTabelData = async (
    setTableColumns,
    tableName,
    params,
    setLoading,
    objectFormating,
    setCheckedTrans,
    tableHeader,
    limit,
    offset,
    setPageinationData,
    pagiNationData
  ) => {
    setLoading(true);
    // static params for testing purpose
    // params =
    //   page === "holding"
    //     ? {
    //         clientId: 199,
    //         assetClass: params.assetClass,
    //       }
    //     : {
    //         clientId: 199,
    //         memberId: 44,
    //         startDate: "2021-04-10",
    //         endDate: "2022-10-09",
    //         assetClass: params.assetClass,
    //       };
    params = {
      clientId: userState.loginedUserData.id,
      search: params["search"] ? params["search"] : "",
      // clientId: 197,

      ...getfilter_as_params(),
      // assetClass: params.assetClass,
      offset: offset,
      limit: limit,
      // Pass showCorpAction only for Transaction Page
      ...(page === "transaction" ? { showCorpAction: checked } : {}),
    };

    try {
      let response = await getTransactionsSummary("invits", tableName, params);
      setPageinationData((prev) => {
        return { ...prev, pageCount: response?.data?.data?.meta?.count };
      });
      let newlist = [];
      let transactionList = [];
      // stData?.data?.result?.map((val) => {
      //   if (page === "holding") {
      //     let obj = objectFormating1(val, tableHeader);
      //     console.log(obj, "afpnafpabwifawf");
      //     newlist.push(obj);
      //   } else {
      //     val.advisor = val?.advisor + "%" + val?.updated_at;
      //     let obj = objectFormating1(val, tableHeader);
      //     newlist.push(obj);
      //     if (
      //       val["transaction_type"] != null &&
      //       !transactionList
      //         .map((val) => val?.title)
      //         .includes(val["transaction_type"])
      //     ) {
      //       transactionList.push({
      //         title: val["transaction_type"],
      //         show: false,
      //       });
      //     }
      //     console.log(newlist, "aflibaibwfowf");
      //   }
      // });
      response?.data?.data?.result?.map((val) => {
        if (page === "holding") {
          let obj = objectFormating1(val, tableHeader);

          newlist.push(obj);
        } else {
          val.advisor = val?.advisor + "%" + val?.updated_at;
          let obj = objectFormating1(val, tableHeader);
          newlist.push(obj);
          if (
            val["transaction_type"] != null &&
            !transactionList
              .map((val) => val?.title)
              .includes(val["transaction_type"])
          ) {
            transactionList.push({
              title: val["transaction_type"],
              show: false,
            });
          }
        }
      });

      if (page !== "holding") {
        setCheckedTrans(transactionList);
      }
      setTableColumns(newlist);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const changePage = (p) => {
    switch (p) {
      case "holding":
        return (
          <MLDHolding
            typeOfProduct={"invits"}
            hideSubTab={true}
            // customTableConfig={holdingTableConfig}
            customTableConfig={customTableConfig(invitsHoldingConfig)}
            getTabelData={getTabelData}
            subHeading={"Infrastructure Investment Trust"}
            // backUrl="/home/invits/invits-summary"
            backUrl={routes?.invitsSummaryPage}
            headerInTwoLine={true}
            // pageCount={pagiNationData?.pageCount}
          ></MLDHolding>
        );

      case "transaction":
        return (
          <MLDTransaction
            hideSubTab={true}
            getTabelData={getTabelData}
            showCorpActions={checked}
            customTableConfig={
              checked
                ? reitsTRCorporateActionsTableConfig
                : customTableConfig(reitsTransactionConfig)
            }
            subHeading={"Infrastructure Investment Trust"}
            backUrl={routes?.invitsSummaryPage}
            headerInTwoLine={true}
            iosSwitch={
              <IosSwitch
                switchLabelName="Show Corporate Action"
                checked={checked}
                setChecked={setChecked}
              />
            }
            // pageCount={pagiNationData?.pageCount}
          />
        );

      case "cash-flow":
        return (
          <MLDCashFlow
            entity={"invits"}
            backUrl={routes?.invitsSummaryPage}
            subHeading={"Infrastructure Investment Trust"}
          />
        );

      default:
        return (
          <MLDHolding
            typeOfProduct={"invits"}
            customTableConfig={customTableConfig(invitsHoldingConfig)}
            getTabelData={getTabelData}
            subHeading={"Infrastructure Investment Trust"}
            backUrl={routes?.invitsSummaryPage}
            headerInTwoLine={true}
            // pageCount={pagiNationData?.pageCount}
          ></MLDHolding>
        );
    }
  };

  const handleClick = React.useCallback(
    (page) => {
      setPage(page);
    },
    [page]
  );

  return (
    <>
      <PageFilter
        backLink={routes?.invitsSummaryPage}
        setFilterModalOpen={setFilterModalOpen}
      ></PageFilter>
      <NewFilter
        filterModalOpen={filterModalOpen}
        setFilterModalOpen={setFilterModalOpen}
        productType="invits"
      />
      <div className="backBtnContainer" style={{ margin: "2%" }}>
        <NewTabComp
          data={data}
          customWidth={"100%"}
          customHandle={handleClick}
          customSelect={data.findIndex((val) => val?.page == page)}
        />
      </div>
      {changePage(page)}
    </>
  );
}

export default INVITSReport;
