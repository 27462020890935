import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

const useStyles = makeStyles( (theme) => ({
    tabHeader:{
        background: '#220848',boxShadow: '-4px -4px 10px #431D84, 4px 4px 10px #000000',
        paddingRight:'5px',
        borderRadius: '124.46px',
        padding: '5px',
        width: '75%',
        display:'flex', justifyContent:'space-between',
        [theme.breakpoints.down('md')]: {
            width: '280px',
        },
        [theme.breakpoints.down('sm')]: {
            width: '280px',
        },
        [theme.breakpoints.down('xs')]: {
            width: '280px',
        },
    },

    tabHeaderText:{
        fontFamily: 'Avenir',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '32px',
        textAlign: 'center',
        color: '#9488A7',
        marginRight:'10px',
        marginLeft:'10px',
    },
    active:{
        background: '#380D77',
        boxShadow: 'inset 0px 4px 10px #220848',
        borderRadius: '50px',
        color:'#F1E8FE',
        lineHeight: '20px',
        paddingLeft: '10px',
        paddingRight: '10px',
        paddingBottom: '4px',
        paddingTop: '4px',
        border: '1px solid #711CF1',
        marginRight:'0',
        marginLeft:'0',
    }
}))

function TabHeader({pageName}) {
    
    const classes = useStyles()
    return (
        <div className={classes.tabHeader}>
            <Link style={{ textDecoration: 'none'}} to='/verification-upload/pending'><div className={`${classes.tabHeaderText} ${pageName === 'pending' ? classes.active: ''}`}>Pending Verification</div></Link>
            <Link style={{ textDecoration: 'none'}} to='/verification-upload/verify'><div className={`${classes.tabHeaderText} ${pageName === 'verify' ? classes.active: ''}`}>Verified Upload</div></Link>
        </div>
    )
}

export default TabHeader