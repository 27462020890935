import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as GainIcon } from "../../../assets/icons/newGainIcon.svg";
import { ReactComponent as LossIcon } from "../../../assets/icons/newLossIcon.svg";
import moment from "moment";
import { loadingText } from "../../utils/constands";

export function formatDate(originalDate) {
  if (originalDate === "...") {
    return loadingText;
  } else {
    return moment(originalDate, "DD MMM YYYY").format("DD-MM-YYYY");
  }
}
export function checkValue(value) {
  let convertedToNumber = parseFloat(value?.replace(/[^0-9.-]+/g, ""));
  if (convertedToNumber > 0) {
    return {
      type: "gain",
      color: "#00B78B",
      icon: <GainIcon />,
    };
  } else if (convertedToNumber < 0) {
    return {
      type: "loss",
      color: "#C1475D",
      icon: <LossIcon />,
    };
  } else {
    return {
      type: "normal",
      color: "#FFFFFF",
      //icon: <></>,
    };
  }
}
export const useStyles = makeStyles((theme) => ({
  // * This is the main card that is the card with Purple background
  mainCard: {
    //background: "linear-gradient(127deg, #4C1A97 14.75%, #7636D7 88.7%)",
    // background: "linear-gradient(146deg, #2B0D58 0%, #692FC0 100%)",
    background: theme.palette.cards.mainCard,
    borderRadius: "8px",
    minHeight: "156px",
  },
  // * This is the nested card inside the purple card
  nestedCard: {
    background: theme.palette.cards.innerCard,
    minHeight: "70px",
    borderRadius: "7px",
  },
  nestedCardBoxPadding: { padding: "5px", marginBottom: "-4.5px" },
  // * This acts as wrapper for the entire card content inside main card i.e side spacing
  mainCardContent: {
    marginRight: "5px",
    marginLeft: "5px",
  },

  // *
  firstCardHeaderText: {
    color: "#FFF",
    fontFamily: "Avenir",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px",
    letterSpacing: "2.24px",
    textTransform: "uppercase",
    // opacity: 0.8,
  },
  firstCardHeaderValue: {
    color: "#FFF",
    fontFamily: "Avenir",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
  },
  secondCardHeaderText: {
    color: "#FFF",
    fontFamily: "Avenir",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "2.24px",
    textTransform: "uppercase",
    marginLeft: "8px",
  },
  secondCardHeaderValue: {
    color: "#FFF",
    fontFamily: "Avenir",
    fontSize: "22.307px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
    marginRight: "8px",
  },
  spaceBetweenMainHeaderAndInnerCard: {
    marginTop: "1.2rem",
    marginBottom: "1.45rem",
  },
  innerCardText: {
    color: "#FFF",
    fontFamily: "Avenir",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22.65px",
    marginBottom: "8px",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9rem",
    },
  },

  innerCardValue: {
    color: "#FFF",
    fontFamily: "Avenir",
    fontSize: "17.954px",
    fontStyle: "normal",
    fontWeight: 900,
    lineHeight: "28.214px",
  },

  // * Common styles for the second row of cards
  secondRowCards: {
    minHeight: "52px",
  },
  secondRowCardValue: {
    color: theme.palette.cards.textColor,
    marginTop: "0.2rem",
    fontFamily: "Avenir",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "28.321px",
  },
  secondRowCardText: {
    color: theme.palette.cards.textColor,
    fontFamily: "Avenir",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "22.336px",
    letterSpacing: "1.66px",
    textTransform: "uppercase",
    paddingTop: "4px",
  },
  gapBetweenIconAndText: {
    gap: "20px",
  },
  lossBackground: {
    background: theme.palette.cards.loss,
  },
  gainBackground: {
    background: theme.palette.cards.gain,
  },
  lossText: { color: "red" },
  gainText: {
    color: "#00B78B",
  },
  dateText: {
    color: "#FFF",
    fontFamily: "Avenir",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
    opacity: "0.9",
  },
  dateValue: {
    color: "#FFF",
    fontFamily: "Avenir",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    opacity: "0.9",
  },
  // ** Active state style
  investedText: {
    color: "#FFF",
    fontFamily: "Avenir",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22.65px",
    textTransform: "uppercase",
    opacity: "0.8",
  },
  investedSubText: {
    color: "#FFF",
    fontFamily: "Avenir",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22.65px",
    opacity: "0.6",
  },
  investedValue: {
    color: "#FFF",
    fontFamily: "Avenir",
    fontSize: "30px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
  },
  dividerText: {
    color: "#FFF",
    fontFamily: "Avenir",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "2.24px",
    textTransform: "uppercase",
    opacity: "0.5",
    marginRight: "10px",
  },
  legendContainer: {
    display: "flex",
    alignItems: "center",
    gap: "18px",
    justifyContent: "flex-end",
    marginRight: "10px",
  },
  gainLossContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  gainLossContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  legendText: {
    color: "#FFF",
    fontFamily: "Avenir",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 300,
    paddingTop: "2px",
    lineHeight: "18px",
  },
  gainCirlce: {
    width: "16px",
    height: "16px",
    borderRadius: "50%",
    background: "#00B78B",
  },
  lossCircle: {
    width: "16px",
    height: "16px",
    borderRadius: "50%",
    background: "#D25C86",
  },
}));
