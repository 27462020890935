import React, { useEffect } from "react";
import { useForm } from "../../NewSignUp/hooks/useForm";
import PartnerHeading from "../../NewSignUp/PartnerHeading";
import "../../NewSignUp/PartnerOnboarding.css";
import "../../NewSignUp/NewSignUp.css";
import { Box, Grid, MenuItem } from "@material-ui/core";
import CustomInput from "../../NewSignUp/common/CustomInput";
import StepProgressBar from "../../NewSignUp/common/StepProgressBar/StepProgressBar";
import LoadingButton from "../../NewSignUp/common/LoadingButton";
import ErrorMessageComp from "../../NewSignUp/common/ErrorMessageComp";
import {
  addUserSchema,
  client_add_member_validation_schema,
  partnerUserAddmember,
  updatePartnerUser,
} from "../../NewSignUp/ValidationSchema";
import {
  Client_User_Roles,
  ENTITY_TYPES,
  adduserRoles,
  adduserStatus,
  partnerUserRoles,
} from "../../../Constants";
import { handleInitValue } from "../utils/cmmon";
import AsyncCheckbox from "../../dashboard/common/AsyncCheckbox";
import { useState } from "react";
import { fetchRepresetOption } from "../../../api";

function AddMemberForm1({
  update,
  handleNext,
  handleBack,
  currentStep,
  customHandleSubmit,
  head,
  onlyPerosn,
  apiErrorMessage,
  loading,
  formData,
  totalStep,
  noStepBottom,
  formMaxHeight,
  noStep,
  formWidth,
  clientId,
}) {
  // console.log(formData, "formdata");
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useForm(
    {
      fullName: formData?.name ? formData?.name : "",
      entityType:
        formData?.entityType !== undefined ? formData?.entityType : "",
      userRole: formData?.partnerUserRole?.partner_role_id || "",
      pin: "",
      // panNumber: formData?.panNumber ? formData?.panNumber : "",
      //   dateOfBirth: formData?.dateOfBirth ? formData?.dateOfBirth : "",
      //   formData?.entityType == "sole_proprietor"
      //   ? formData?.dateOfBirth
      //   : formData?.doi
      //   ? formData?.doi
      //   : "",
      status: isNaN(formData?.status) ? "1" : formData?.status,
      email: formData?.email ? formData?.email : "",
      mobile: formData?.mobile ? formData?.mobile : "",
    },
    // test,
    (value) => {
      value["rep"] = seletedRep;
      value["bank"] = seletedBank;
      if (seletedRep.length == 0 && seletedBank.length == 0) {
        setshowError(true);
        return;
      } else {
        setshowError(false);
      }
      console.log(value, "aflkbabfbuawubfw");
      customHandleSubmit(value);
    },
    update ? updatePartnerUser : partnerUserAddmember
  );

  const [showLoader, setShowLoader] = useState(false);
  const [representative, setRepresentative] = useState([]);
  const [banker, setBanker] = useState([]);
  const [showError, setshowError] = useState(false);
  const [seletedRep, setSelectedRep] = useState(
    update ? formData?.rep || [] : []
  );
  const [seletedBank, setSelectedBank] = useState(
    update ? formData?.banker || [] : []
  );
  function fetchReprsent(entity, search) {
    setShowLoader(true);
    fetchRepresetOption(clientId, {
      limit: 20,
      entityType: entity || "representative",
      search: search || "",
      isActive: 1,
    })
      .then((res) => {
        console.log("res", res.data.data.result.representativesList);
        if (entity == "representative") {
          setRepresentative([
            ...res.data.data.result.map((o) => {
              return { full_name: o.name, id: o.partner_entity_id };
            }),
            { full_name: "None", id: "#0" },
          ]);
          // console.log(
          //   [
          //     ...res.data.data.result.map((o) => {
          //       return { full_name: o.name, id: o.user_id };
          //     }),
          //     { full_name: "None", id: "#0" },
          //   ],
          //   "user"
          // );
        } else {
          // console.log(
          //   [
          //     ...res.data.data.result.map((o) => {
          //       return { full_name: o.name, id: o.user_id };
          //     }),
          //     { full_name: "None", id: "#0" },
          //   ],
          //   "user2"
          // );
          setBanker([
            ...res.data.data.result.map((o) => {
              return { full_name: o.name, id: o.partner_entity_id };
            }),
            { full_name: "None", id: "#0" },
          ]);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  useEffect(() => {
    fetchReprsent("representative", "");
    fetchReprsent("banker", "");
  }, []);

  return (
    <div
      className="dFlex jtfyContCntr alignCntr h100 flexDirCol"
      style={{ gap: "25px", width: formWidth }}
    >
      <div className="newFormTopSec">
        <PartnerHeading
          mainHead={head?.main ? head?.main : "Add Member"}
          subtitle={
            head?.sub ? head?.sub : "Join us by providing your company details"
          }
          handleBack={handleBack}
          step={currentStep}
          noStep={noStep}
        />
      </div>
      <div className="newFormContainer" style={{ maxHeight: formMaxHeight }}>
        <Box
          className="dFlex jtfyContCntr alignCntr flexDirCol"
          sx={{ width: { xs: "auto", sm: "330px" }, gap: "41px" }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid xs={12} lg={12} item>
                <CustomInput
                  title="Full Name"
                  // as={TextField}
                  variant="outlined"
                  margin="normal"
                  // required
                  fullWidth
                  id="fullName"
                  label="Full Name"
                  name="fullName"
                  autoComplete="fullName"
                  error={touched.fullName && Boolean(errors.fullName)}
                  helperText={touched.fullName && errors.fullName}
                  value={values.fullName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid xs={12} lg={12} item>
                <CustomInput
                  title="user role"
                  //   disabled={true}
                  // as={TextField}
                  select
                  variant="outlined"
                  margin="normal"
                  // required
                  fullWidth
                  id="userRole"
                  label="User Role"
                  name="userRole"
                  autoComplete="userRole"
                  autoFocus
                  error={touched.userRole && Boolean(errors.userRole)}
                  helperText={touched.userRole && errors.userRole}
                  value={values.userRole}
                  onChange={handleChange}
                >
                  {partnerUserRoles.map((entity) => {
                    return (
                      <MenuItem
                        key={entity.value}
                        value={entity.value}
                        selected={entity?.selected}
                        color="white"
                        style={{
                          border: "0.34px solid #3E5389",
                          backgroundColor: "#131826",
                        }}
                      >
                        {entity.name}
                      </MenuItem>
                    );
                  })}
                </CustomInput>
              </Grid>
              {update && (
                <Grid xs={12} lg={12} item>
                  <CustomInput
                    title="Status"
                    //   disabled={true}
                    // as={TextField}
                    select
                    variant="outlined"
                    margin="normal"
                    // required
                    fullWidth
                    id="status"
                    label="Status"
                    name="status"
                    autoComplete="status"
                    autoFocus
                    error={touched.status && Boolean(errors.status)}
                    helperText={touched.status && errors.status}
                    value={values.status}
                    onChange={handleChange}
                  >
                    {adduserStatus.map((entity) => {
                      return (
                        <MenuItem
                          key={entity.value}
                          value={entity.value}
                          selected={entity?.selected}
                          color="white"
                          style={{
                            border: "0.34px solid #3E5389",
                            backgroundColor: "#131826",
                          }}
                        >
                          {entity.name}
                        </MenuItem>
                      );
                    })}
                  </CustomInput>
                </Grid>
              )}
              <Grid
                xs={12}
                lg={12}
                item
                // pr={{ xs: 0, sm: 0, md: 0, lg: 1 }}
              >
                <CustomInput
                  title="Mobile no."
                  // as={TextField}
                  variant="outlined"
                  margin="normal"
                  // required
                  fullWidth
                  id="mobile"
                  label="Mobile Number"
                  name="mobile"
                  error={touched.mobile && Boolean(errors.mobile)}
                  helperText={touched.mobile && errors.mobile}
                  value={values.mobile}
                  onChange={handleChange}
                  // maxLength={10}
                />
              </Grid>
              <Grid xs={12} lg={12} item>
                <CustomInput
                  // as={TextField}
                  title="EMAIL ID"
                  variant="outlined"
                  margin="normal"
                  // required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  //   autoFocus
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  value={values.email}
                  onChange={handleChange}
                />
              </Grid>
              {!update && (
                <>
                  {" "}
                  <Grid xs={12} lg={12} item>
                    <CustomInput
                      title="Enter pin"
                      //   disabled={true}
                      // as={TextField}
                      password
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      id="pin"
                      label="Enter Pin"
                      name="pin"
                      autoComplete="pin"
                      autoFocus
                      type={"password"}
                      error={touched.pin && Boolean(errors.pin)}
                      helperText={touched.pin && errors.pin}
                      value={values.pin}
                      onChange={handleChange}
                    ></CustomInput>
                  </Grid>
                  <Grid xs={12} lg={12} item>
                    <CustomInput
                      title="CONFIRM PIN"
                      //   disabled={true}
                      // as={TextField}
                      password
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      id="confirm_pin"
                      label="Confirm Pin"
                      name="confirm_pin"
                      autoComplete="confirm_pin"
                      autoFocus
                      type={"password"}
                      error={touched.confirm_pin && Boolean(errors.confirm_pin)}
                      helperText={touched.confirm_pin && errors.confirm_pin}
                      value={values.confirm_pin}
                      onChange={handleChange}
                    ></CustomInput>
                  </Grid>
                </>
              )}

              <Grid xs={12} lg={12} item>
                <div className="checkboxChip">
                  <AsyncCheckbox
                    data={[...banker]}
                    values={seletedBank}
                    setValues={setSelectedBank}
                    placeholder="Select Banker"
                    getSearchData={
                      (value) =>
                        // fetchClientList("approved", value)
                        fetchReprsent("banker", value)
                      // fetchRepresetOption(clientId, { search: value })
                    }
                    errors={errors}
                    searchLoader={showLoader}
                    label="full_name"
                    optionTitle="full_name"
                    fieldName="Banker"
                    objectFieldName="id"
                    formData={formData}
                    handleChange={(name, value) => {}}
                    // helperText={errors?.clientId}
                  />
                  {seletedBank.length == 0 && seletedRep == 0 && (
                    <span
                      className="hide"
                      style={{
                        display: showError ? "block" : "none",
                        color: "red",
                      }}
                    >
                      {" "}
                      At least one Banker should be selected
                    </span>
                  )}
                </div>
              </Grid>

              <Grid xs={12} lg={12} item>
                <div className="checkboxChip">
                  <AsyncCheckbox
                    data={[...representative]}
                    values={seletedRep}
                    setValues={setSelectedRep}
                    getSearchData={
                      (value) =>
                        // fetchClientList("approved", value)
                        fetchReprsent("representative", value)
                      // fetchRepresetOption(clientId, { search: value })
                    }
                    errors={errors}
                    searchLoader={showLoader}
                    label="full_name"
                    optionTitle="full_name"
                    fieldName="Representative"
                    objectFieldName="id"
                    placeholder="Select Representative"
                    formData={formData}
                    handleChange={(name, value) => {}}
                    // helperText={errors?.clientId}
                  />
                  {seletedBank.length == 0 && seletedRep == 0 && (
                    <span
                      className="hide"
                      style={{
                        display: showError ? "block" : "none",
                        color: "red",
                      }}
                    >
                      {" "}
                      At least one Representative should be selected
                    </span>
                  )}
                </div>
              </Grid>

              {/* <Grid
                xs={12}
                lg={12}
                item
                // style={{ paddingLeft: { xs: 0, sm: 0, md: 0, lg: 1 } }}
              >
                <CustomInput
                  title="PAN NO"
                  // as={TextField}
                  variant="outlined"
                  margin="normal"
                  // required
                  fullWidth
                  id="panNumber"
                  label="Pan Number"
                  name="panNumber"
                  error={touched.panNumber && Boolean(errors.panNumber)}
                  helperText={touched.panNumber && errors.panNumber}
                  value={values.panNumber}
                  onChange={handleChange}
                />
              </Grid> */}
              {/* <Grid xs={12} lg={12} item>
                <CustomInput
                  // as={TextField}
                  select
                  fullWidth
                  title="Status"
                  variant="outlined"
                  margin="normal"
                  // required
                  id="status"
                  label="Status"
                  name="status"
                  autoComplete="status"
                  // autoFocus
                  // type="select"
                  error={touched.status && Boolean(errors.status)}
                  helperText={touched.status && errors.status}
                  value={values.status}
                  onChange={handleChange}
                >
                  {adduserStatus.map((entity) => {
                    return (
                      <MenuItem
                        key={entity.value}
                        value={entity.value}
                        selected={entity?.selected}
                        style={{
                          border: "0.34px solid #3E5389",
                          backgroundColor: "#131826",
                        }}
                      >
                        {entity.name}
                      </MenuItem>
                    );
                  })}
                </CustomInput>
              </Grid> */}

              <div className="newFormBottonSec">
                <Grid xs={12} lg={12} style={{ marginTop: "30px" }} item>
                  <ErrorMessageComp errorMessage={apiErrorMessage} />
                  <LoadingButton
                    buttonTitle={"Save"}
                    type="submit"
                    showLoader={loading}
                  />
                </Grid>
                {noStepBottom ? (
                  ""
                ) : (
                  <Grid xs={12} item style={{ marginTop: "28px" }}>
                    <StepProgressBar
                      total={totalStep}
                      currentStep={currentStep}
                    />
                  </Grid>
                )}
              </div>
            </Grid>
          </form>
        </Box>
      </div>
    </div>
  );
}

export default AddMemberForm1;
