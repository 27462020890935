export const headerDataPartner2 = [
  {
    title: "Full Name",
    field: "fullName",
    align: "left",
    color2: "#711CF1",
  },
  {
    title: "User Role",
    field: "userRole",
  },
  {
    title: "Mobile NO",
    field: "mobileNo",
  },
  {
    title: "Member",
    field: "member",
  },
  {
    title: "Email ID",
    field: "email",
  },
];

export const step3tableHead = [
  {
    title: "Asset Class",
    field: "assetClass",
    customFontClass: "step3FristColFont",
    customCellClassName: "step3FristCol",
  },
  {
    title: "Allocation",
    field: "allocationPercentage",
    customFontClass: "step3AllocationCell",
    align: "right",
    customCellClassName: "step3Allocation",
    cellType: "input",
  },
  {
    title: "Benchmark",
    field: "benchmarkIndex",
    cellType: "dropDown",
    customCellClassName: "step3Benchmark",
  },
  {
    title: "Expected Returns",
    field: "expectedReturns",
    align: "right",
    customCellClassName: "step3LastCell",
  },
];

export const benchmarkTableHead = [
  {
    title: "Asset Class",
    field: "assetClass",
    customFontClass: "step3FristColFont",
    customCellClassName: "step3FristCol",
  },

  {
    title: "Benchmark",
    field: "benchmarkIndex",
    cellType: "dropDown",
    customCellClassName: "step3Benchmark",
  },
];

export const step3tableHeadUniqueIndex = [
  {
    title: "Asset Class",
    field: "assetClass",
    customFontClass: "step3FristColFont",
    customCellClassName: "step3FristCol",
  },
  {
    title: "Allocation",
    field: "allocationPercentage",
    // customFontClass: "step3AllocationCell",
    align: "right",
    customCellClassName: "step3Allocation",
    // cellType: "input",
  },
  {
    title: "Benchmark",
    field: "benchmarkIndex",
    cellType: "dropDown",
    customCellClassName: "step3Benchmark",
  },
  // {
  //   title: "Expected Returns",
  //   field: "expectedReturns",
  //   align: "right",
  //   customCellClassName: "step3LastCell",
  // },
];

export const step3tableStaticDataUniqueIndex = [
  {
    assetClass: "equity",
    allocationPercentage: "--",
    benchmarkIndex: "--",
  },
  {
    assetClass: "debt",
    allocationPercentage: "--",
    benchmarkIndex: "--",
  },
  {
    assetClass: "cash",
    allocationPercentage: "--",
    benchmarkIndex: "--",
  },
  {
    assetClass: "alternate",
    allocationPercentage: "--",
    benchmarkIndex: "--",
  },
];

export const step3tableStaticData = [
  {
    assetClass: "equity",
    allocationPercentage: "0",
    benchmarkIndex: "--",
    expectedReturns: "--",
  },
  {
    assetClass: "debt",
    allocationPercentage: "0",
    benchmarkIndex: "--",
    expectedReturns: "--",
  },
  {
    assetClass: "cash",
    allocationPercentage: "0",
    benchmarkIndex: "--",
    expectedReturns: "--",
  },
  {
    assetClass: "alternate",
    allocationPercentage: "0",
    benchmarkIndex: "--",
    expectedReturns: "--",
  },
];

export const bodyData3 = [];

export const data32 = [
  { title: "Summary" },
  { title: "Performance  Summary" },
  { title: "Top Holdings" },
  { title: "All Transactions" },
  { title: "Top Advisors" },
  { title: "Asset Allocation" },
  { title: "Portfolio Aging" },
  { title: "Top Advisors" },
  { title: "All Transactions" },
  { title: "Summary" },
  { title: "Summary" },
  { title: "Performance  Summary" },
  { title: "Top Holdings" },
  { title: "All Transactions" },
  { title: "Top Advisors" },
  { title: "Asset Allocation" },
  { title: "Portfolio Aging" },
  { title: "Top Advisors" },
  { title: "All Transactions" },
  { title: "Summary" },
];

export const headerDataTargetPotFolio = [
  {
    title: "Name",
    field: "name",
    align: "left",
    color2: "#711CF1",
    customFontClassName: "fontCustom",
  },
  {
    title: "Type",
    field: "strategyType",
    customFontClassName: "fontCustom",
  },
  {
    title: "Members",
    field: "members",
    customFontClassName: "fontCustom",
  },
  {
    title: "Date",
    field: "created_at",
    customFontClassName: "fontCustom",
  },
];

export const checkBoxSvgIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill="#55A47E"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.0234 9.15765L11.3372 4.93849C11.4664 4.83678 11.6228 4.77552 11.7868 4.76242C11.9507 4.74932 12.1149 4.78495 12.2586 4.86484C12.4024 4.94473 12.5193 5.06531 12.5948 5.21145C12.6702 5.35759 12.7008 5.52276 12.6826 5.68622C12.6704 5.79502 12.6368 5.90033 12.5837 5.99607C12.5306 6.09182 12.4591 6.17611 12.3732 6.24409L6.33749 11.0352L6.27889 11.0821C6.25474 11.099 6.2297 11.1146 6.20388 11.1289L6.17107 11.15C5.98052 11.2485 5.75933 11.2695 5.55364 11.2088C5.34794 11.1481 5.17362 11.0103 5.06705 10.8242L4.9733 10.6672L3.42393 7.97394C3.3696 7.87944 3.33441 7.77517 3.32038 7.66707C3.30635 7.55897 3.31375 7.44917 3.34215 7.34393C3.37056 7.2387 3.41941 7.14008 3.48593 7.05373C3.55244 6.96737 3.63532 6.89496 3.72982 6.84063C3.92067 6.73091 4.14729 6.70149 4.35982 6.75886C4.46506 6.78726 4.56367 6.83612 4.65003 6.90263C4.73639 6.96915 4.8088 7.05202 4.86313 7.14652L6.02574 9.15765H6.0234Z"
      fill="white"
    />
  </svg>
);

export const notFoundSvgIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="132"
    height="180"
    viewBox="0 0 132 180"
    fill="none"
  >
    <line
      x1="67.2723"
      y1="69.3774"
      x2="67.2723"
      y2="0.991905"
      stroke="url(#paint0_linear_1724_8566)"
      stroke-width="0.636145"
    />
    <path
      d="M73.9524 75.7391C73.9524 74.052 73.2822 72.4339 72.0892 71.2409C70.8962 70.0479 69.2781 69.3777 67.5909 69.3777C65.9038 69.3777 64.2857 70.0479 63.0927 71.2409C61.8997 72.4339 61.2295 74.052 61.2295 75.7391L67.5909 75.7391H73.9524Z"
      fill="url(#paint1_linear_1724_8566)"
    />
    <ellipse
      cx="27.0361"
      cy="4.77108"
      rx="27.0361"
      ry="4.77108"
      transform="matrix(-1 0 0 1 94.626 96.4141)"
      fill="url(#paint2_linear_1724_8566)"
    />
    <path
      opacity="0.6"
      d="M40.5542 100.999H94.6265L132 179.999H0L40.5542 100.999Z"
      fill="url(#paint3_linear_1724_8566)"
    />
    <path
      d="M94.626 101.185C94.626 94.0146 91.7776 87.1379 86.7073 82.0676C81.637 76.9974 74.7603 74.1489 67.5899 74.1489C60.4194 74.1489 53.5427 76.9974 48.4724 82.0676C43.4022 87.1379 40.5537 94.0146 40.5537 101.185L67.5899 101.185H94.626Z"
      fill="url(#paint4_linear_1724_8566)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1724_8566"
        x1="67.5903"
        y1="35.1847"
        x2="68.5903"
        y2="35.1847"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#402C5E" stop-opacity="0.66" />
        <stop offset="1" stop-color="#8865BD" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1724_8566"
        x1="62.1274"
        y1="72.3917"
        x2="73.8724"
        y2="72.2612"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#711CF1" />
        <stop offset="0.115733" stop-color="#8D42FF" />
        <stop offset="0.222473" stop-color="#AB86E2" />
        <stop offset="0.34481" stop-color="#9E7ECF" />
        <stop offset="0.555237" stop-color="#6C44A7" />
        <stop offset="1" stop-color="#290A58" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1724_8566"
        x1="3.81627"
        y1="2.26053"
        x2="53.5413"
        y2="-0.870306"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#711CF1" />
        <stop offset="0.115733" stop-color="#8D42FF" />
        <stop offset="0.222473" stop-color="#AB86E2" />
        <stop offset="0.34481" stop-color="#9E7ECF" />
        <stop offset="0.555237" stop-color="#6C44A7" />
        <stop offset="1" stop-color="#290A58" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1724_8566"
        x1="66"
        y1="100.999"
        x2="66"
        y2="179.999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#975EFB" />
        <stop offset="0.246075" stop-color="#38117D" />
        <stop offset="1" stop-color="#3B2268" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1724_8566"
        x1="44.37"
        y1="86.9586"
        x2="94.286"
        y2="86.404"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#711CF1" />
        <stop offset="0.115733" stop-color="#8D42FF" />
        <stop offset="0.222473" stop-color="#AB86E2" />
        <stop offset="0.34481" stop-color="#9E7ECF" />
        <stop offset="0.555237" stop-color="#6C44A7" />
        <stop offset="1" stop-color="#290A58" />
      </linearGradient>
    </defs>
  </svg>
);

// USED IN BENCHMARK EXCESS PERFORMANCE
export const allocationTableStaticData = [
  {
    assetClass: "equity",
    investedAmount: 3266291019.1332245,
    portfolioValueAmount: 4045101914.727159,
    withDrawalAmount: 1194149072.62,
    realisedGainLoss: 159074401.13269538,
    unRealisedGainLoss: 577157807.5939344,
    totalGainLoss: 580473042.9039344,
    updatedAsOn: "21/03/2024",
    overAllInvestedAmount: null,
    interestIncome: null,
    dividendReinvestment: null,
    principalPayback: null,
    percentageOfTotalPortfolio: 10.015655782832535,
  },
  {
    assetClass: "debt",
    investedAmount: 3449475772.0259724,
    portfolioValueAmount: 35614393824.35966,
    withDrawalAmount: 2917245319.0726986,
    realisedGainLoss: 445234209.63131386,
    unRealisedGainLoss: 32199984492.702538,
    totalGainLoss: 32511119539.095978,
    updatedAsOn: "21/03/2024",
    overAllInvestedAmount: null,
    interestIncome: null,
    dividendReinvestment: null,
    principalPayback: null,
    percentageOfTotalPortfolio: 88.1810933268124,
  },
  {
    assetClass: "alternate",
    investedAmount: 388360537.33159465,
    portfolioValueAmount: 480495424.052559,
    withDrawalAmount: 484680621.62570006,
    realisedGainLoss: 77096279.82087168,
    unRealisedGainLoss: 92134886.72096439,
    totalGainLoss: 172389492.2937657,
    updatedAsOn: "21/03/2024",
    overAllInvestedAmount: null,
    interestIncome: null,
    dividendReinvestment: null,
    principalPayback: null,
    percentageOfTotalPortfolio: 1.1897047031165306,
  },
  {
    assetClass: "cash",
    investedAmount: 228210604.77370304,
    portfolioValueAmount: 247797738.91852885,
    withDrawalAmount: 1360432426.9525003,
    realisedGainLoss: 131711.88208481873,
    unRealisedGainLoss: 19587134.144825816,
    totalGainLoss: 19587134.144825816,
    updatedAsOn: "21/03/2024",
    overAllInvestedAmount: null,
    interestIncome: null,
    dividendReinvestment: null,
    percentageOfTotalPortfolio: 0.613546187238546,
  },
];
