import React, { useEffect, useState } from "react";
import NewDialaugeComp from "../../newClientList/NewDialogComp";
import { useLocation, useNavigate } from "react-router-dom";
import AddUserForm1 from "./AddUserForm1";

function AddUserFlow({ openModal, setOpenModal, selectedData, handleClose }) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [next, setNext] = useState(state?.step ? state?.step : 1); //state?.step ? state?.step :
  const [formData, setFormData] = useState(selectedData);

  useEffect(() => {
    if (selectedData) setFormData(selectedData);
  }, [selectedData]);

  const chagneInStep = (step) => {
    switch (step) {
      case 1:
        return (
          <AddUserForm1
            formMaxHeight="63vh"
            noStepBottom={true}
            currentStep={next}
            noStep={true}
            formData={formData}
            formWidth={"523px"}
            customHandleSubmit={(data) => {
              console.log(data, "afjkababjsf");
            }}
          />
        );

      default:
        return setOpenModal(false);
      // navigate("/user-management", {
      //   state: {
      //     step: next,
      //     formData: formData,
      //     openModal: false,
      //   },
      // });
    }
  };

  return (
    <NewDialaugeComp
      // width={next == 3 ? "md" : "sm"}
      fullWidth={false}
      open={openModal}
      handleClose={() => {
        setOpenModal(false);
        setFormData({});
        handleClose();
        // navigate("/user-management", {
        //   state: {
        //     step: next,
        //     formData: formData,
        //     openModal: false,
        //   },
        // });
      }}
    >
      {chagneInStep(next)}
    </NewDialaugeComp>
  );
}

export default AddUserFlow;
