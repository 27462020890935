import moment from "moment";

export function getMemberIdsFromSelectedMembersList(selectedFamilyMembers) {
  return selectedFamilyMembers.map((member) => member.member_id).join(",");
}
export function formatSelectedFamilyMembers(selectedFamilyMembers) {
  let checkData = {};
  for (let i = 0; i < selectedFamilyMembers?.length; i++) {
    const memberId = selectedFamilyMembers[i].member_id;
    checkData[memberId] = {
      full_name: selectedFamilyMembers[i].full_name,
      client_code: selectedFamilyMembers[i].client_code,
      member_id: selectedFamilyMembers[i].member_id,
    };
  }
  return checkData;
}

export function formatAdvisor(selectedAdvisor) {
  let checkData = {};
  for (let i = 0; i < selectedAdvisor?.length; i++) {
    const advisor = selectedAdvisor[i];
    checkData[advisor] = true;
  }
  return checkData;
}

export function formatExecutor(selectedExecutor) {
  let checkData = {};
  for (let i = 0; i < selectedExecutor?.length; i++) {
    const executor = selectedExecutor[i];
    checkData[executor] = true;
  }
  return checkData;
}

export function formatDenominationDisplay(selectedDenomination) {
  if (selectedDenomination === "In Crores") {
    return "crores";
  } else if (selectedDenomination === "In Lakhs") {
    return "lakhs";
  } else if (selectedDenomination === "Actuals") {
    return "actuals";
  } else {
    return "default";
  }
}

export function formatCurrencyFromLocalStorage(currencyFormat) {
  if (currencyFormat && typeof currencyFormat === "string") {
    if (currencyFormat === "crores") {
      return "In Crores";
    } else if (currencyFormat === "lakhs") {
      return "In Lakhs";
    } else if (currencyFormat === "actuals") {
      return "Actuals";
    } else {
      return "Default";
    }
  } else {
    console.log(`currencyFormat should be a string`);
  }
}

export function formatSelectedPeriod(selectedPeriod) {
  console.log("formatSelectedPeriod", selectedPeriod);
  if (selectedPeriod && selectedPeriod === "ITD") {
    return "Inception Till Date";
  } else {
    return selectedPeriod;
  }
}

// export function calculateDateRange(duration) {
//   // Set endDate to yesterday's date at the current time
//   const endDate = moment().subtract(1, "days");
//   let startDate;

//   switch (duration) {
//     case "1 Month":
//       startDate = endDate.clone().startOf("day").subtract(1, "months");
//       break;
//     case "3 Months":
//       startDate = endDate.clone().startOf("day").subtract(3, "months");
//       break;
//     case "1 Year":
//       startDate = endDate.clone().startOf("day").subtract(1, "years");
//       break;
//     case "2 Years":
//       startDate = endDate.clone().startOf("day").subtract(2, "years");
//       break;
//     case "3 Years":
//       startDate = endDate.clone().startOf("day").subtract(3, "years");
//       break;
//     // Add more cases as needed
//     default:
//       startDate = endDate.clone().startOf("day"); // Default to start of yesterday
//   }

//   // Return the dates as ISO strings with the time information
//   return [startDate.toISOString(), endDate.toISOString()];
// }

export function calculateDateRange(duration) {
  const today = moment();
  let startDate, endDate;

  switch (duration) {
    case "Current Month":
      startDate = moment().startOf("month");
      endDate = moment().endOf("month");
      break;
    case "Previous Month":
      startDate = moment().subtract(1, "month").startOf("month");
      endDate = moment().subtract(1, "month").endOf("month");
      break;
    case "Current Quarter":
      startDate = moment().startOf("quarter");
      endDate = moment().endOf("quarter");
      break;
    case "Previous Quarter":
      startDate = moment().subtract(1, "quarter").startOf("quarter");
      endDate = moment().subtract(1, "quarter").endOf("quarter");
      break;
    case "MTD":
      startDate = moment().startOf("month");
      endDate = moment().subtract(1, "days");
      break;
    case "ITD":
      startDate = null;
      endDate = moment().subtract(1, "days");
      break;
    case "QTD":
      startDate = moment().startOf("quarter");
      endDate = moment().subtract(1, "days");
      break;
    case "YTD":
      startDate = moment().startOf("year");
      endDate = moment().subtract(1, "days");
      // endDate = today;
      break;
  }

  return [startDate.toISOString(), endDate.toISOString()];
}
