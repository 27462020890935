import React from "react";
import Default from "../components/dashboard/defaultPages/Default";
import ICDSummaryComp from "../components/dashboard/products/icd/ICDSummaryComp";

function ICDSummary() {
  return (
    <>
      <Default>
        <ICDSummaryComp />
      </Default>
    </>
  );
}

export default ICDSummary;
