import React from "react";
import pdfLogo from "../../../../assets/images/pdfLogo.png";
import { Box, Typography } from "@material-ui/core";
import newNeoLogo from "../../../../assets/icons/newNeoLogo.png";
import backgroundGradient from "../../../../assets/images/gradientBackground.png";
import { useLocation } from "react-router";
export const LastPage = ({ type }) => {
  const container = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "11.3in",
    backgroundColor: "#ffffff",
    width: "100%",
    backgroundImage: type == "pdf" ? `url(${backgroundGradient})` : "none",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
  function getTextColor() {
    let black = "#000000";
    let white = "#FFFFFF";
    return type == "print" ? black : white;
  }
  return (
    <div className="pdf-page" style={container}>
      <Box
        className="logo"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          width: "279px",
        }}
      >
        <img src={newNeoLogo} alt="logo"></img>
        <h1
          style={{
            fontSize: "20px",
            fontStyle: "normal",
            marginLeft: "5px",
            fontFamily: "Avenir",
            fontWeight: "800",
            letterSpacing: "3px",
            textTransform: "uppercase",
          }}
        >
          Wealth Partners
        </h1>
      </Box>
    </div>
  );
};
