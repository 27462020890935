import { makeStyles } from "@material-ui/core";

export const useNewFilterStyles = makeStyles((theme) => ({
  memberChipContainer: {
    borderRadius: "8px",
    background: theme.palette.newFilter.memberChipContainer,
    padding: "0.75rem",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "2rem",
  },
  memberChipSelected: {
    border: `1px solid ${theme.palette.newFilter.memberChipSelectedBorder}`,
    backgroundColor: theme.palette.newFilter.memberChipSelected,
  },
  advisorCard: {
    width: "169px",
    height: "100px",
    borderRadius: "8px",
    background: theme.palette.newFilter.advisorCard,
    display: "flex",
    justifyContent: "space-around",
    padding: "1rem 0.5rem",
  },
  assetClassCard: {
    width: "169px",
    height: "72px",
    borderRadius: "8px",
    background: theme.palette.newFilter.assetClassCard,
    display: "flex",
    justifyContent: "space-between",
    padding: "0.75rem",
  },
  currencyCard: {
    borderRadius: "8px",
    background: theme.palette.newFilter.currencyCard,
    width: "188px",
    display: "flex",
    gap: "4rem",
    alignItems: "center",
    position: "relative",
    height: "100px",
  },
  offShoreBtn: {
    borderRadius: "8px",
    background: theme.palette.newFilter.offShoreBtn,
    width: "fit-content",
    padding: "10px 16px 10px 16px",
    border: "none",
    color: "#fff",
    fontFamily: "Avenir",
    fontSize: "15.55px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "27.645px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "0.7rem",
  },
  selectedMemberName: {
    color: theme.palette.newFilter.selectedMemberName,
    fontFamily: "Avenir",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
  },
  promoterStakeContainer: {
    width: "95%",
    padding: "1.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#211839",
    borderRadius: "8px",
    cursor: "pointer",
  },
  promoterHeader: {
    margin: 0,
    color: "#FFF",
    fontFamily: "Avenir",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "32px",
  },
  promoterSubHeader: {
    margin: 0,
    color: "white",
    width: "75%",
    marginTop: "0.5rem",
    color: "#FFF",
    fontFamily: "Avenir",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 350,
    lineHeight: "18px",
    opacity: 0.7,
  },
  promoterCheckedContainer: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    width: "30%",
    justifyContent: "flex-end",
    gap: "1rem",
  },
  promoterHighlightedTxt: {
    margin: 0,
    color: "#55A47E",
    fontFamily: "Avenir",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "18px",
  },
}));
