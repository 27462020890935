import React, { useEffect, useState } from "react";
import { getPrintDataMongo } from "../api";
import { useLocation } from "react-router-dom";
import moment from "moment";

function useGetMongoPrintReport(mongoId, setUrl) {
  const [data, setData] = useState({});
  const [denomination, setDenomination] = useState();
  const [pdfInfo, setPdfInfo] = useState({});
  const [meta, setMeta] = useState({});
  const [loading, setLoading] = useState(false);
  const [reportType, setReportType] = useState("");
  const location = useLocation();

  const getReportMongo = async () => {
    console.log(mongoId, "aglagn");
    setLoading(true);
    try {
      let res = await getPrintDataMongo({
        // mongoDataId: "65604c3693a720206ae3411a",
        // mongoDataId: "65670ed0fda1a6d051a3304e",
        mongoDataId: mongoId,
      });

      // console.log(res?.data?.data?.result?.denomination, "agalnbj");
      setReportType(res?.data?.data?.result?.report_type);
      setDenomination(() => res?.data?.data?.result?.denomination);
      setMeta(() => {
        console.log(
          res?.data?.data?.result?.end_date,
          "end date",
          res?.data?.data?.result
        );
        return {
          range:
            "" +
            (res?.data?.data?.result?.start_date
              ? moment(
                  res?.data?.data?.result?.start_date,
                  "YYYY-MM-DD"
                ).format("DD MMM YYYY") + " - "
              : "As on Date " + " - ") +
            moment(res?.data?.data?.result?.end_date, "YYYY-MM-DD").format(
              "DD MMM YYYY"
            ),
          clientId: res?.data?.data?.result?.client_id,
        };
      });
      if (setUrl) {
        setUrl(res?.data?.data?.result?.download_link);
      }
      setPdfInfo(() => {
        return {
          client:
            res?.data?.data?.result?.clientName ||
            res?.data?.data?.result?.client_name,
          totalMembers:
            res?.data?.data?.result?.totalMemberCount ||
            res?.data?.data?.result?.total_member_count,
          startDate:
            res?.data?.data?.result?.start_date &&
            moment(res?.data?.data?.result?.start_date, "YYYY-MM-DD").format(
              "DD MMM YYYY"
            ),
          endDate:
            res?.data?.data?.result?.end_date &&
            moment(res?.data?.data?.result?.end_date, "YYYY-MM-DD").format(
              "DD MMM YYYY"
            ),
          report: res?.data?.data?.result.report_type,
          banker_name: res?.data?.data?.result?.banker_name,
          banker_email: res?.data?.data?.result?.banker_email,
          banker_mobile: res?.data?.data?.result?.banker_mobile,
          cso_name: res?.data?.data?.result?.cso_name,
          cso_email: res?.data?.data?.result?.cso_email,
          cso_mobile: res?.data?.data?.result?.cso_mobile,
          processing_format: res?.data?.data?.result?.processing_format,
        };
      });
      setData(() => res?.data?.data?.result?.result);
      setLoading(false);
    } catch (error) {
      console.log(error, "agalnbj");
      setLoading(false);
    }
  };

  useEffect(() => {
    getReportMongo();
  }, []);

  return {
    newData: data,
    getReportMongo,
    loading,
    denomination: String(denomination).toLowerCase(),
    meta,
    reportType,
    pdfInfo,
  };
}

export default useGetMongoPrintReport;
