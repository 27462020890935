import React from "react";
import UploadBulk from "../components/dashboard/scheme-masters/UploadBulk";
import { useParams } from "react-router-dom";
import UpdateSingle from "../components/dashboard/scheme-masters/UpdateSingle";

function UploadMasterProducts() {
  const params = useParams();
  const addType = params?.addType;

  if (addType === "bulk") {
    return <UploadBulk></UploadBulk>;
  } else if (addType === "update") {
    return <UpdateSingle></UpdateSingle>;
  }
}

export default UploadMasterProducts;
