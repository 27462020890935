export function get_path_type(path) {
  if (path.indexOf("/portfolio") >= 0) {
    return "portfolio";
  }

  if (path.indexOf("/mutual-fund") >= 0) {
    return "mfo";
  } else if (path.indexOf("/aif") >= 0) {
    return "aif";
  } else if (path.indexOf("/pms") >= 0) {
    return "pms";
  } else if (path.indexOf("/equity") >= 0) {
    return "equity";
  } else if (path.indexOf("/real-estate") >= 0) {
    return "real-estate";
  } else if (path.indexOf("/bonds") >= 0) {
    return "bonds";
  } else if (path.indexOf("/unlisted-equity") >= 0) {
    return "private";
  } else if (path.indexOf("/structured-product") >= 0) {
    return "structured";
  } else if (path.indexOf("/mld") >= 0) {
    return "mld";
  } else if (path.indexOf("/invits") >= 0) {
    return "invits";
  } else if (path.indexOf("/reits") >= 0) {
    return "reits";
  } else if (path.indexOf("/icd") >= 0) {
    return "icd";
  } else if (path.indexOf("/fixed-deposit") >= 0) {
    return "fixed-deposit";
  } else if (path.indexOf("/bank") >= 0) {
    return "bank";
  } else if (path.indexOf("/insurance") >= 0) {
    return "insurance";
  } else if (path.indexOf("/recurring-deposit") >= 0) {
    return "recurring-deposit";
  }

  if (path.indexOf("/holding") >= 0) {
    return "holding";
  } else {
    return "general";
  }
}
