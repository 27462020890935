import React from "react";
import DefaultSign from "../DefaultSign";
import RepresentativeAddComp from "./RepresentativeAddComp";

export default function RepresentativeConfirm({ handleNext, customBack }) {
  const desc = {
    center: [
      {
        title: "",
        description: "",
      },
    ],
  };

  return (
    <>
      <DefaultSign left={7} right={5} desc={desc}>
        <RepresentativeAddComp
          customBack={customBack}
          handleNext={handleNext}
        />
        {/* <div className="dFlex jtfyContCntr alignCntr h100">
          <Box
            className="dFlex jtfyContCntr alignCntr flexDirCol"
            sx={{ width: { xs: "auto", sm: "386px" }, gap: "41px" }}
          >
            {" "}
            <div>
              <PartnerHeading
                handleBack={() => {
                  handleNext((prev) => prev - 1);
                }}
                mainHead="Great !"
                subtitle="You’ve added you details Successfully"
                noStep={true}
              />

              <div style={{ marginBottom: "10px", marginTop: "8px" }}>
                <div
                  style={{
                    borderLeft: "3px solid #D4BBFB",
                    height: "100px",
                    opacity: "0.16",
                    marginLeft: "4px",
                  }}
                ></div>
                <div
                  style={{
                    borderRadius: "50%",
                    backgroundColor: " #2D9878",
                    height: "10px",
                    width: "10px",
                  }}
                ></div>
              </div>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  // mb: "30px",
                }}
              >
                <Box>
                  <Typography
                    // variant="body1"
                    className="generalFontFam"
                    style={{
                      opacity: "0.84",
                      color: "white",
                      marginTop: "12px",
                    }}
                  >
                    NEXT
                  </Typography>
                  <Typography
                    className="generalFontFam"
                    style={{
                      fontSize: "23px",
                      fontWeight: "900",
                      marginTop: "12px",
                      color: "white",
                    }}
                    // variant="h1"
                  >
                    Let’s now add a representative
                  </Typography>
                  <Typography
                    // variant="h6"
                    className="generalFontFam varientH6 nonLetterSpacing"
                    style={{
                      opacity: "0.7",
                      color: "white",
                      marginTop: "8px",
                    }}
                  >
                    Holdings are the contents of an investment portfolio held by
                    an individual or an entity, such as a mutual fund or a
                    pension fund. Portfolio holdings may encompass a wide
                  </Typography>
                </Box>
              </Box>
              <Box mt={"20px"} height={"48px"}>
                
                <LoadingButton
                  buttonTitle={"Continue"}
                  customOnclick={() => {
                    handleNext((prev) => prev + 1);
                  }}
                  // type="submit"
                  // showLoader={loading}
                />
              </Box>
            </div>
          </Box>
        </div> */}
      </DefaultSign>
    </>
  );
}
