import React from "react";
import Default from "../components/dashboard/defaultPages/Default";
import InsuranceLifeReport from "../components/dashboard/products/insurance/InsuranceLifeReport";
function InsuranceSummary() {
  return (
    <>
      <Default>
        <InsuranceLifeReport></InsuranceLifeReport>
      </Default>
    </>
  );
}

export default InsuranceSummary;
