export const bodyData = [
  [
    {
      title: "ASSET CLASS",
      field: "Equity  ",
      color: "#BBB580",
      fontVarient: "h5",
      fontWeight: "900",
    },
    {
      title: "CURRENT VALUE",
      field: "currentValue",
      fontVarient: "h3",
      fontWeight: 800,
      customClass: "assetWisetxt",
    },
    {
      title: "% ALLOCATION",
      field: "PercentageOfAllocation",
      fontVarient: "h5",
      fontWeight: 400,
    },
    {
      title: "UPDATED AS ON",
      field: "updatedAsOn",
      color: "#BBB580",
      spclFont: "italic",
      fontVarient: "h6",
    },
  ],
  [
    {
      title: "ASSET CLASS",
      field: "assetClass",
      fontVarient: "h5",
      fontWeight: 900,
      color: "#1FAAB2",
    },
    {
      title: "CURRENT VALUE",
      field: "currentValue",
      fontVarient: "h3",
      fontWeight: 800,
    },
    {
      title: "% ALLOCATION",
      field: "PercentageOfAllocation",
      fontVarient: "h5",
      fontWeight: 400,
    },
    {
      title: "UPDATED AS ON",
      field: "updatedAsOn",
      color: "#1FAAB2",
      spclFont: "italic",
      fontVarient: "h6",
    },
  ],
  [
    {
      title: "ASSET CLASS",
      field: "Alternate",
      color: "#907DD0",
      fontVarient: "h5",
      fontWeight: 900,
    },
    {
      title: "CURRENT VALUE",
      field: "currentValue",
      fontVarient: "h3",
      fontWeight: 800,
    },
    {
      title: "% ALLOCATION",
      field: "PercentageOfAllocation",
      fontVarient: "h5",
      fontWeight: 400,
    },
    {
      title: "UPDATED AS ON",
      field: "updatedAsOn",
      color: "#907DD0",
      spclFont: "italic",
      fontVarient: "h6",
    },
  ],
  [
    {
      title: "ASSET CLASS",
      field: "Cash",
      color: "#6761E6",
      fontVarient: "h5",
      fontWeight: 900,
    },
    {
      title: "CURRENT VALUE",
      field: "currentValue",
      fontVarient: "h3",
      fontWeight: 800,
    },
    {
      title: "% ALLOCATION",
      field: "PercentageOfAllocation",
      fontVarient: "h5",
      fontWeight: 400,
    },
    {
      title: "UPDATED AS ON",
      field: "updatedAsOn",
      color: "#6761E6",
      spclFont: "italic",
      fontVarient: "h6",
    },
  ],
];

export const bodyData2 = [
  {
    assetClass: "Equity  ",
    currentValue: "#BBB580",
    PercentageOfAllocation: "h5",
    updatedAsOn: "900",
  },
];

export const HeadData = [
  {
    title: "ASSET CLASS",
    field: "assetClass",
    align: "center",
  },
  {
    title: "CURRENT VALUE",
    field: "currentValue",
  },
  {
    title: "% ALLOCATION",
    field: "PercentageOfAllocation",
  },
  {
    title: "UPDATED AS ON",
    field: "updatedAsOn",
    spclFont: "italic",
  },
];

const centerAlign = "center";

export const hierarchyHeader = [
  {
    title: "Full Name",
    field: "member_name",
    align: centerAlign,
    color2: "#711CF1",
    dontShowFirstColBorder: true,
  },
  {
    title: "User Role",
    field: "member_role",
  },
  {
    title: "Pan No",
    field: "member_pan",
  },
  {
    title: "Mobile NO",
    field: "member_mobile",
  },

  {
    title: "Email ID",
    field: "member_email",
  },
  {
    title: "Banker Name",
    field: "representative_name",
  },
  {
    title: "CXO",
    field: "cxo_name",
  },
  {
    title: "Status",
    field: "is_active",
    color2: "#711CF1",
    width: "240px",
  },
];

export const headerData = [
  {
    title: "Full Name",
    field: "fullName",
    align: centerAlign,
    color2: "#711CF1",
  },
  {
    title: "User Role",
    field: "userRole",
  },
  {
    title: "Pan No",
    field: "panNo",
  },
  {
    title: "Mobile NO",
    field: "mobileNo",
  },

  {
    title: "Email ID",
    field: "email",
  },
  {
    title: "Banker Name",
    field: "bankerName",
  },
  {
    title: "CXO",
    field: "cso",
  },
  {
    title: "Status",
    field: "status",
    color2: "#711CF1",
    width: "240px",
  },
];

export const clientHeaderData = [
  {
    title: "Full Name",
    field: "full_name",
    align: centerAlign,
    color2: "#711CF1",
    dontShowFirstColBorder: true,
  },
  {
    title: "User Role",
    field: "role",
  },
  {
    title: "Pan No",
    field: "member_pan",
  },
  {
    title: "Mobile NO",
    field: "client_mobile",
  },

  {
    title: "Email ID",
    field: "client_email",
  },
  {
    title: "Banker Name",
    field: "banker_name",
  },
  {
    title: "CXO",
    field: "cso_name",
  },
  {
    title: "Status",
    field: "status",
    color2: "#711CF1",
    width: "240px",
  },
];

export const OtherUserHeaderData = [
  {
    title: "Full Name",
    field: "name",
    align: centerAlign,
    color2: "#711CF1",
    dontShowFirstColBorder: true,
  },

  {
    title: "Email ID",
    field: "email",
  },
  {
    title: "Mobile NO",
    field: "mobile",
  },

  {
    title: "User Role",
    field: "role",
  },

  {
    title: "Status",
    field: "status",
    color2: "#711CF1",
    width: "240px",
  },
];

export const headerDataPartner = [
  {
    title: "Full Name",
    field: "fullName",
    align: centerAlign,
    color2: "#711CF1",
  },
  {
    title: "User Role",
    field: "userRole",
  },
  {
    title: "Partner Name",
    field: "partnerName",
  },
  {
    title: "Mobile NO",
    field: "mobileNo",
  },

  {
    title: "Email ID",
    field: "email",
  },
  // {
  //   title: "Banker Name",
  //   field: "bankerName",
  // },
  // {
  //   title: "CSO",
  //   field: "cso",
  // },
  {
    title: "Status",
    field: "status",
    color2: "#711CF1",
    width: "240px",
  },
];

export const headerDataPartner2 = [
  {
    title: "Full Name",
    field: "name",
    align: centerAlign,
    color2: "#711CF1",
  },
  {
    title: "User Role",
    field: "partnerRole",
  },
  {
    title: "Mobile NO",
    field: "mobile",
  },

  {
    title: "Email ID",
    field: "email",
  },
  // {
  //   title: "Banker Name",
  //   field: "bankerName",
  // },
  // {
  //   title: "CSO",
  //   field: "cso",
  // },
  {
    title: "Status",
    field: "status",
    color2: "#711CF1",
    width: "240px",
  },
];
export const AdminHeaderUserManagment = [
  {
    title: "Full Name",
    field: "name",
    align: centerAlign,
    color2: "#711CF1",
  },
  {
    title: "User Role",
    field: "role",
  },
  {
    title: "Mobile NO",
    field: "mobile",
  },

  {
    title: "Email ID",
    field: "email",
  },
  // {
  //   title: "Banker Name",
  //   field: "bankerName",
  // },
  // {
  //   title: "CSO",
  //   field: "cso",
  // },
  {
    title: "Status",
    field: "status",
    color2: "#711CF1",
    width: "240px",
  },
];

export const headerDataPartner3 = [
  {
    title: "Banker / Representative Name",
    field: "name",
    // align: "left",
    color2: "#711CF1",
    dontShowFirstColBorder: true,
  },
  // {
  //   title: "User Role",
  //   field: "userRole",
  // },
  {
    title: "Mobile NO",
    field: "mobile",
    fontWeight: "500",
  },

  {
    title: "Email ID",
    field: "email",
  },
  // {
  //   title: "Banker Name",
  //   field: "bankerName",
  // },
  // {
  //   title: "CSO",
  //   field: "cso",
  // },
  {
    title: "",
    field: "action",
    color2: "#711CF1",
    width: "240px",
  },
];
export const headerDataPartner4 = [
  {
    title: "Banker Name",
    field: "fullName",
    // align: "left",
    color2: "#711CF1",
    dontShowFirstColBorder: true,
  },
  // {
  //   title: "User Role",
  //   field: "userRole",
  // },
  {
    title: "Mobile NO",
    field: "mobileNo",
    fontWeight: "500",
  },

  {
    title: "Email ID",
    field: "email",
  },
  // {
  //   title: "Banker Name",
  //   field: "bankerName",
  // },
  // {
  //   title: "CSO",
  //   field: "cso",
  // },
  {
    title: "",
    field: "action",
    color2: "#711CF1",
    width: "240px",
  },
];

export const bankerMappingHeader = [
  {
    title: "Partner Name",
    field: "partnerName",
    // align: "left",
    color2: "#711CF1",
  },
  {
    title: "Partner Code",
    field: "partnerCode",
    fontWeight: "500",
  },

  {
    title: "Partner Type",
    field: "partnerType",
  },
];

export const bankerMappingData = [
  {
    partnerName: "IIFL",
    partnerCode: "RTF56-AV4",
    partnerType: "Non-Individual",
  },
  {
    partnerName: "Devi Shah",
    partnerCode: "HJU56-AV4",
    partnerType: "Individual",
  },
  {
    partnerName: "HDFC",
    partnerCode: "HYG56-AV4",
    partnerType: "Non-Individual",
  },
];

export const clientListHeaderOps = [
  {
    title: "Client Name",
    field: "full_name",
    color2: "#711CF1",
    dontShowFirstColBorder: true,
  },
  {
    title: "Head of Family",
    field: "hof_name",
    fontWeight: "500",
  },
  {
    title: "Client Code",
    field: `code`,
  },
  {
    title: "Partner Name",
    field: "partner_name",
  },
  {
    title: "Banker Name",
    field: "banker_name",
  },
  {
    title: "CXO",
    field: "cso_name",
  },
  {
    title: "Status",
    field: "is_active",
    color2: "#711CF1",
    width: "240px",
  },
];

export const clientListHeaderOpsPending = [
  {
    title: "Client Name",
    field: "full_name",
    color2: "#711CF1",
    dontShowFirstColBorder: true,
  },
  {
    title: "Head of Family",
    field: "hof_name",
    fontWeight: "500",
  },
  {
    title: "Mobile",
    field: "hof_mobile",
  },
  {
    title: "Email",
    field: `hof_email`,
    color2: "#711CF1",
    width: "240px",
  },
  // {
  //   title: "Banker Name",
  //   field: "banker_Name",
  // },
  // {
  //   title: "CSO",
  //   field: "banker_Nmae",
  // },
  // {
  //   title: "Status",
  //   field: "is_active",
  //   color2: "#711CF1",
  //   width: "240px",
  // },
];

export const partnerListHeaderOps = [
  {
    title: "Partner Name",
    field: "entity_name",
    color2: "#711CF1",
    dontShowFirstColBorder: true,
  },
  {
    title: "Representative",
    field: "partnerRepresentative.full_name",
    fontWeight: "500",
  },
  {
    title: "Partner Code",
    field: `code`,
  },
  {
    title: "Entity Type",
    field: "entity_type",
  },
  // {
  //   title: "Banker Name",
  //   field: "banker_Name",
  // },
  // {
  //   title: "CSO",
  //   field: "banker_Nmae",
  // },
  {
    title: "Status",
    field: "is_active",
    color2: "#711CF1",
    width: "240px",
  },
];

export const partnerListHeaderOpsRejected = [
  {
    title: "Partner Name",
    field: "entity_name",
    color2: "#711CF1",
    dontShowFirstColBorder: true,
  },
  {
    title: "Entity Type",
    field: "entity_type",
  },
  {
    title: "Representative",
    field: "partnerRepresentative.full_name",
    fontWeight: "500",
  },
  {
    title: "Representative Email",
    field: "partnerRepresentative.email",
    fontWeight: "500",
  },
  {
    title: "Representative No",
    field: "partnerRepresentative.mobile",
    fontWeight: "500",
    color2: "#711CF1",
    width: "240px",
  },

  // {
  //   title: "Partner Code",
  //   field: `code`,
  // },

  // {
  //   title: "Banker Name",
  //   field: "banker_Name",
  // },
  // {
  //   title: "CSO",
  //   field: "banker_Nmae",
  // },
  // {
  //   title: "Status",
  //   field: "is_active",
  //   color2: "#711CF1",
  //   width: "240px",
  // },
];

export const cleintByPartnerId = [
  {
    title: "Client name",
    field: "client_full_name",
    align: centerAlign,
    color2: "#711CF1",
  },
  // {
  //   title: "Client Code",
  //   field: "client_code",
  //   customFontClassName: "fontW-400",
  // },
  // {
  //   title: "Partner Name",
  //   field: "partner_name",
  // },
  // {
  //   title: "Partner Code",
  //   field: "partner_code",
  // },
  {
    title: "Mapped to (Banker name)",
    field: "banker_name",
  },
  {
    title: "CXO",
    field: "cso_name",
  },

  {
    title: "Total no of members",
    field: "clientMemberCount",
  },
  // {
  //   title: "Status",
  //   field: "status",
  //   color2: "#711CF1",
  //   width: "240px",
  // },
];
