import React from "react";
import Default from "../components/dashboard/defaultPages/Default";
import FamilyFunction from "../components/family_tree/FamilyFunction";
import FamilyFunctionDemo from "../components/family_tree/FamilyFunctionDemo";
import FamilyStructureComp from "../components/family_tree/FamilyStructureComp";
function FamilyStructure() {
  return (
    <>
      <Default>
        <FamilyStructureComp></FamilyStructureComp>
        {/* <FamilyFunction></FamilyFunction> */}
        {/* <FamilyFunctionDemo></FamilyFunctionDemo> */}
      </Default>
    </>
  );
}

export default FamilyStructure;
