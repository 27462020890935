import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../assets/icons/logo.png";
import { ReactComponent as MailBox } from "../assets/icons/mail.svg";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import "../components/formStyles.css";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Welcome } from "../assets/icons/hey-there.svg";
import ErrorMessageComp from "../components/common/ErrorMessageComp";
import LoadingButton from "../components/common/LoadingButton";
import CustomInputLogin from "../components/common/CustomInputLogin";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
  Box,
  MenuItem,
  InputAdornment,
  IconButton,
} from "@material-ui/core";

import { useLogin } from "../hooks/useLogin";
import { useForm } from "../hooks/useForm";
import { logInValidationSchema } from "../hooks/ValidationSchema";
import back from "../assets/images/login-bg.png";
import { OnboardingContext } from "../Context";
const useStyles = makeStyles((theme) => ({
  container: {
    backgroundImage: `url(${back})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "100vh",
    backgroundSize: "cover",
  },
  button: {
    paddingTop: "10px",
    width: "100%",
    color: "rgb(255, 255, 255)",
    background:
      "linear-gradient(rgb(170, 111, 254) 0%, rgb(113, 28, 241) 100%)",
    borderRadius: "20px",
    paddingBottom: "10px",
  },
  buttonText: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#FFFFFF",
  },
  singnInBox: {
    background: "rgba(11, 3, 24, 0.6)",
    backdropFilter: "blur(2.5px)",
    borderRadius: "50px",
    position: "absolute",
    left: "35%",
    width: "30%",
    top: "10%",
    [theme.breakpoints.down("md")]: {
      left: "25%",
      width: "55%",
      top: "10%",
    },
    [theme.breakpoints.down("xs")]: {
      left: "5%",
      width: "90%",
      top: "15%",
    },
  },
  text: {
    fontStyle: "normal",
    fontFamily: "Avenir",
  },
  signInHeading: {
    fontWeight: "900",
    fontSize: "28px",
    lineHeight: "30px",
    color: "#FFFFFF",
    margin: "10px 0px 10px 0px",
  },
  mainContent: {
    padding: "45px",
  },
  logo: {
    width: "105px",
    left: "5px",
    margin: "0px",
    top: "15px",
    position: "absolute",
  },
  inputField: {
    borderColor: " #711CF1",
    borderRadius: "20px",
  },
}));

function SignIn() {
  const classes = useStyles();
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const {
    userState: { loginedUserData },
  } = OnboardingContext();

  // TODO Pass loginEntityType when drop down is ready for user type
  const { submitForm, showLoader, errorMessage } = useLogin();
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useForm(
    {
      loginUserType: "client",
      userName: "",
      password: "",
      rememberInfo: false,
    },
    (values) => {
      // handle form submission
      values.password = Number(values.password);
      submitForm(values);
    },
    logInValidationSchema
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (loginedUserData?.token) {
      navigate("/home");
    }
  }, []);

  const Login_User_Type = [
    { value: "partner", name: "Partner" },
    { value: "client", name: "Client", slected: true },
    { value: "mfo-user", name: "Admin" },
  ];

  return (
    <div className={classes.container}>
      <img className={classes.logo} src={Logo} alt="logo"></img>
      <div className={classes.singnInBox}>
        <div className={classes.mainContent}>
          <div className={`newAlignTopHeader`}>
            <Welcome />
          </div>
          <Typography className={`${classes.signInHeading} ${classes.text}`}>
            Login in as
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid xs={12} lg={12} item sx={{ position: "relative" }}>
                <CustomInputLogin
                  select
                  fullWidth
                  variant="outlined"
                  className="LoginPageMFO"
                  margin="normal"
                  required
                  id="loginUserType"
                  label="Partner"
                  name="loginUserType"
                  autoComplete="entityType"
                  value={values.loginUserType}
                  onChange={handleChange}
                  style={{
                    backgroundColor: "rgba(25, 32, 51, 0.75)",
                    // boxShadow: "0px 45px 43px -32px red",
                  }}
                >
                  {Login_User_Type.map((user) => {
                    return (
                      <MenuItem
                        key={user.value}
                        value={user.value}
                        className="LoginPageMenu"
                        style={{
                          border: "0.34px solid #3E5389",
                          backgroundColor: "#131826",
                        }}
                        selected={user?.slected ? user?.slected : false}
                      >
                        {user.name}
                      </MenuItem>
                    );
                  })}
                </CustomInputLogin>
                {/* <div
                  style={{
                    boxShadow: "0px 45px 43px -32px red",
                  }}
                  className="gradiantBackground"
                ></div> */}
              </Grid>

              <Grid xs={12} lg={12} item mt="44px">
                <CustomInputLogin
                  title="EMAIL ID"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="userName"
                  label="Email Id"
                  name="userName"
                  autoComplete="userName"
                  autoFocus
                  className="LoginPageMFO"
                  pr="0px"
                  error={touched.userName && Boolean(errors.userName)}
                  helperText={touched.userName && errors.userName}
                  value={values.userName}
                  onChange={handleChange}
                  errorBorder={
                    touched.userName && errors.userName ? true : false
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <MailBox style={{ marginTop: "8px" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid xs={12} lg={12} item>
                <CustomInputLogin
                  title="PIN"
                  maxLength={4}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="password"
                  label="Pin"
                  className="LoginPageMFO"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  autoComplete="current-password"
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  value={values.password}
                  onChange={handleChange}
                  errorBorder={
                    touched.userName && errors.userName ? true : false
                  }
                  inputProps={{
                    maxLength: 4,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? (
                            <VisibilityOff style={{ fontSize: "1.2rem" }} />
                          ) : (
                            <Visibility style={{ fontSize: "1.2rem" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                xs={12}
                item
                mt="3px"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      className="customCheckBox"
                      color="primary"
                      id="rememberInfo"
                      name="rememberInfo"
                      checked={values.rememberInfo}
                      onChange={(e) =>
                        setFieldValue("rememberInfo", e.target.checked)
                      }
                    />
                  }
                  label="Remember info"
                />
                <Typography
                  onClick={() => {
                    navigate("/reset-password", {
                      state: { loginEntityType: values.loginUserType },
                    });
                  }}
                  style={{
                    cursor: "pointer",
                    fontFamily: "Avenir",
                    fontWeight: "400",
                    fontSize: "12px",
                    lineHeight: "22px",
                    color: "#FFFFFF",
                    float: "right",
                  }}
                >
                  Forgot Pin?
                </Typography>
              </Grid>
              <Grid xs={12} lg={12} mt={"40px"} item>
                <ErrorMessageComp errorMessage={errorMessage} />
                <LoadingButton
                  showLoader={showLoader}
                  type="submit"
                  bgColor="#4C4ECD"
                  buttonTitle={"Login"}
                />
              </Grid>
            </Grid>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              mt={"30px"}
            >
              <Typography
                sx={{
                  mt: "16px",
                  display: "flex",
                  flexShrink: 0,
                  gap: "5px",
                }}
                variant="h5"
              >
                <span
                  style={{
                    color: "#9A9A9B",
                    fontFamily: "Avenir",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "22px",
                  }}
                >
                  Don’t have an account?
                </span>
                <span
                  onClick={() => {
                    navigate("/register", {
                      state: { entityType: "partner", activeStep: 1 },
                    });
                  }}
                  style={{
                    color: "#3555FF",
                    fontFamily: "Avenir",
                    fontWeight: "700",
                    fontSize: "16px",
                    lineHeight: "22px",
                    cursor: "pointer",
                    cursor: "pointer",
                  }}
                >
                  Sign up
                </span>
              </Typography>
            </Box>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
