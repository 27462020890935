import React from "react";
import "./chart.css";
export default function GlowCircle(props) {
  return (
    <div
      className="center-info"
      // style={props?.centerInfoCircleStyle ? props?.centerInfoCircleStyle : ""}
      style={props?.center_info}
    >
      <div
        className="circle_border"
        // style={props?.circleBorderStyle ? props?.circleBorderStyle : ""}
        style={props?.circle_border}
      >
        <div
          className="glowcircle"
          style={props?.glow_circle}
          // style={props?.glowCircleStyle ? props?.glowCircleStyle : ""}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
}
