import { useState, useEffect, useContext } from "react";
import { OnboardingContext } from "../Context";
import { fetchIpoTransactionSummary, fetchProductSummary } from "../api";
import { mfoFilterContext } from "../mfo_context/Mfo_Context";
import moment from "moment";

export const useIpoTransactionSummary = (schemeName) => {
  const { userState } = OnboardingContext();
  let clientId = userState.loginedUserData.id;
  // let clientId = 199;

  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);

  const [loading, setLoading] = useState(true);
  const [transactionSummary, setTransactionSummary] = useState();

  useEffect(() => {
    let params = {
      schemeName,
    };
    let assetClass = getfilter_as_params().assetClass || "equity";
    if (mfoFilter.startDate) {
      params["startDate"] = moment(mfoFilter.startDate).format("YYYY-MM-DD");
      params["endDate"] = moment(mfoFilter.endDate).format("YYYY-MM-DD");
    }
    if (Object.keys(mfoFilter.checkData).length > 0) {
      params["memberId"] = Object.keys(mfoFilter.checkData).join(",");
    }
    if (Object.keys(mfoFilter.advisor).length > 0) {
      params["advisor"] = Object.keys(mfoFilter.advisor).join(",");
    }
    /* IPO Transaction Summary API Call */
    fetchIpoTransactionSummary(clientId, assetClass, params)
      .then((response) => {
        const apiData = response?.data?.data?.result;

        setTransactionSummary(apiData);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(`An Error ${err} occurred`);
      });
  }, [mfoFilter, schemeName]);
  return [transactionSummary, loading];
};
