const tableReset = () => {
  return 0;
};

const bottomHeader = ({ headerList }) => {
  return headerList.map((val, index) => {
    if (val.field == "percentageOfTotalPortfolio") {
      return {
        field: "totalPortfolioPercentage",
        title: "% of Total Portfolio",
        show: val?.show,
      };
    } else {
      return val;
    }
  });
};

const innerHeader = ({ headerList }) => {
  return headerList.map((val, index) => {
    if (val.field == "assetClass") {
      return { field: "product", title: "", show: val?.show };
    } else {
      return val;
    }
  });
};

module.exports = {
  tableReset,
  bottomHeader,
  innerHeader,
};
