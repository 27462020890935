import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
} from "@material-ui/core";

export function PrintModalCards({ handleSelectedCards, cards, selectedCards }) {
  // TODO Remove static array once api is integrated

  return (
    <div
      style={{
        padding: "0 20px",
        maxHeight: 300,
        overflow: cards.length > 9 ? "scroll" : "none",
      }}
    >
      <Grid container spacing={1}>
        {/* Card 1 */}
        {cards.map((card, index) => (
          <Grid item lg={4} key={index}>
            <Card
              elevation={2}
              id="card"
              onClick={() => handleSelectedCards(card.member_id)}
              className={
                // selectedCards.some(
                //   (selectedCard) => selectedCard.member_id === card.member_id
                // )
                selectedCards.includes(card.member_id) ? "selected-card" : ""
              }
            >
              <CardContent
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  gap: "10px",
                }}
              >
                <CardMedia
                  component="img"
                  src="https://via.placeholder.com/80x80"
                  alt="Profile Picture"
                  id="card-media"
                />
                <Typography variant="h5" id="card-media-txt">
                  {card.full_name}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
