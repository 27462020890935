import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "22px",
    lineHeight: "30px",
    color: "#F8F9FB",
  },
  inputField: {
    borderColor: ' #711CF1',
    borderRadius: '20px',
    width: '17.5vw'
  },
  formControl: {
    border: '1px solid #4E189F',
  },
  icon: {
    fill: '#FAF9FF',
  },
  optiondesign: {
    color: "#9488A7 !important",
    background: "#0b0318 !important",
    border: "1px solid #4E189F !important",
    margin: "13px 10px !important",
  },
  button: {
    background: "linear-gradient(180deg, #AA6FFE 0%, #711CF1 100%)",
    borderRadius: "20px"
  },
  buttonlined: {
    padding: '5px 10px',
    width: '100%',
    color: 'rgb(255, 255, 255)',
    background: 'transparent',
    border: '1px solid #711CF1',
    borderRadius: '20px',
  },
  buttonTypo: {
    fontFamily: 'Avenir',
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
    textTransform: "none"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "70%",
    marginLeft: "15%",
    marginTop: "5%",
    marginBottom: "5%",
  },
  tableHeaderTextNew: {
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#FFFFFF  !important',
    borderBottom: 'none  !important',
    whiteSpace: 'nowrap',
    backgroundColor: "#1D1B36 !important"
  },
  rowTextNew: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#FFFFFF  !important",
    // textAlign: "center"
  },

  familyLinkTypo: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#FFFFFF  !important",
    display: "flex",
    alignItems: "center",
    textAlign: "center"
  },
  modalContainer: {
    backgroundColor: "#170630",
    borderRadius: "20px",
    border: "1px solid #4E189F",
    position: "absolute",
    outline: 0,
    top: "12.5vh",
    right: "32.5%",
    width: "35vw",
    padding: "15px",
    height: "auto",
    overflow: "scroll",
  },
  backDrop: {
    backdropFilter: "blur(5px)",
    background: "rgba(31, 28, 64, 0.51)",
  },

  modalHr: {
    border: "0.5px solid rgba(231, 234, 243, 0.2)",
    background: " #2b1946"
  },
  inputRadio: {
    background: " #1F1C40",
    backdropFilter: "blur(3px)",
    borderRadius: "100px",
    border:"2px solid #711CF1",
    padding:"10px",
    color:"#FFFFFF"
  },
}))

export default useStyles;
