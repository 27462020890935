import React, { useState } from "react";
import HoldingPrint from "./subPrintPages/HoldingPrint";
import {
  ClientHolding,
  newPdfSecurityHolding,
  newpdfHolding,
  newpdfTransactions,
} from "../Components/utils/staticData";

export default function HoldingPdfPage({
  newData,
  denomination,
  reportType,
  setChanges,
  pdfInfo,
  breadcrum,
}) {
  const SECURITY_HOLDING_SUMMARY =
    "Consolidated Securities Holding Summary Report";

  function renderCustomTableHeaderBasedOnReportType() {
    if (reportType === SECURITY_HOLDING_SUMMARY) {
      return newPdfSecurityHolding;
    } else if (reportType === "clientHolding") {
      return ClientHolding;
    } else if (reportType === "transactionSummary") {
      return newpdfTransactions;
    } else {
      return newpdfHolding;
    }
  }

  return (
    <>
      {Array.isArray(newData) &&
        newData?.map((arr) => {
          //   console.log("arr", arr);
          return Object.keys(arr?.assetClass).map((p) => {
            return Object.keys(arr?.assetClass[p]).map((prod) => {
              if (
                arr.assetClass[p][prod]?.investments?.length == 0 ||
                !arr.assetClass[p][prod]?.investments
              ) {
                return <></>;
              }
              let refForTotal = arr.assetClass[p][prod]?.totalValues;

              arr.assetClass[p][prod]?.investments?.push({
                " % of Total Portfolio": refForTotal?.["% of Portfolio"],
                "Absolute TWRR": refForTotal?.["Absolute TWRR"],
                "Advised By": "--",
                "Annualized TWRR": refForTotal?.["Annualised TWRR"],
                "Asset Class": "--",
                "Current Market Value": refForTotal?.["Current Value"],
                "Folio No": "--",
                "Gain/Loss": refForTotal?.["Unrealised Gain/Loss"],
                "ISIN No/Product Code": "--",
                "Instrument Name/Scheme Name": "Total",
                "Last Price Date": "--",
                "Market Price per Unit": "--",
                "Member Name": "--",
                "Other Income": refForTotal?.["Other Income"],
                "Product Name": "--",
                "Quantity/Units": "--",
                "Total Cost": refForTotal?.["Investment Value"],
                XIRR: refForTotal?.["XIRR"],
              });
              return (
                <HoldingPrint
                  headerType={reportType == "executiveHolding" ? "11" : 8}
                  title={breadcrum || "Holding Summary"}
                  pdfInfo={pdfInfo}
                  layout={1}
                  title2={prod}
                  title1={p}
                  customTableHeader={renderCustomTableHeaderBasedOnReportType()}
                  subtext={prod}
                  customKey={prod}
                  loading={false}
                  setChanges={setChanges}
                  // error={data?.equity?.direct_equity_holding_summary?.error}
                  customData={
                    // data?.equity?.direct_equity_holding_summary?.data?.data?.result
                    // stDataHolding
                    arr.assetClass[p][prod]?.investments
                  }
                  customTableClass={"printTableEvenAndOddRow"}
                  hideTitle={true}
                  hideTotalRow={true}
                  denomination={denomination}
                />
              );
            });
          });
        })}
    </>
  );
}
