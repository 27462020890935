import React from "react";
import CustomInput from "./CustomInput";
import { InputAdornment, useTheme } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

function FullWidthInput2({ customWrapperStyle, customInputStyle, setSearch }) {
  const theme = useTheme();
  return (
    <div style={{ marginBottom: "27px", width: "auto", ...customWrapperStyle }}>
      <CustomInput
        handleChange={setSearch}
        customStyle={{
          inputBgColor: "rgba(33, 22, 62, 1)",
          border: "none",
          inputPadding: "16px 2px !important",
        }}
        fullWidth
        style={{
          background: "var(--searchBackground) !important",
          border: "none !important",
          ...customInputStyle,
        }}
        label={"Search"}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="start"
              style={{
                // background: "rgba(86, 38, 197, 1)",
                // background: "var(--primaryHighlight-color)",
                background: theme.palette.searchIcon.background,
                maxHeight: "66px",
                width: "62px",
                margin: "0px",
                padding: "0px",
                height: "98%",
                borderRadius: "6px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <SearchIcon
                style={{
                  opacity: "0.8",
                  color: theme.palette.searchIcon.iconColor,
                }}
              />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}

export default FullWidthInput2;
