import React from "react";
import { createClient, createClientMember } from "../../../api";
import { usePanVerification } from "./usePanVerification";
import { handleDateFormat, handlePanInfo } from "../utils/utils";

function useClientOnboardFlow({
  setLoading,
  setFormData,
  handleNext,
  handleErrorMessage,
}) {
  const { verifyPan } = usePanVerification();

  const api1 = async (data) => {
    // console.log(data, "aflabjf");
    setLoading(true);

    try {
      let payload = {
        name: data?.familyName,
        partner_code: data?.partner_code,
        is_liaison_officer: false,
      };
      let res = await createClient(payload);
      // console.log(res?.data?.data?.result, "aflabjf");

      if (res?.data?.success) {
        setFormData((prev) => {
          handleNext(1, {
            ...prev,
            id: res?.data?.data?.result?.id,
            fullName: res?.data?.data?.result?.full_name,
          });
          return {
            ...prev,
            id: res?.data?.data?.result?.id,
            fullName: res?.data?.data?.result?.full_name,
          };
        });
      }
      setLoading(false);
    } catch (error) {
      // console.log(error?.response?.data?.error, "aflabjf");
      handleErrorMessage(error?.response?.data?.error);
      setLoading(false);
    }
  };

  const api2 = async (data2) => {
    console.log(data2, "aglknj");

    await verifyPan({
      handleSuccess: (data) => {
        let pandInfo = handlePanInfo(data, 3);

        let newPandInfo = {
          ...pandInfo,
          dateOfBirth: data2?.dateOfBirth,
          ...data2,
        };
        setFormData((prev) => {
          handleNext(1, {
            ...prev,
            ...newPandInfo,
          });
          return {
            ...prev,
            ...newPandInfo,
          };
        });
      },
      handleError: (error) => {
        console.log(error, "akhfkjaf");
        handleErrorMessage(error?.response?.data?.error);
      },
      payload: {
        pan: data2?.panNumber,
        dob: handleDateFormat(data2?.dateOfBirth),
      },
    });
  };

  const api3 = async (data) => {
    let payload = {
      full_name: data?.fullName,
      roleId: data?.roleId,
      is_head_of_family: data?.roleId === 2 ? 1 : 0,
      // entityType: data?.entityType,
      member_type: data?.memberType,
      pan: data?.panNumber,
      // pan: "JPLPP4420O",
      // panVerified: String(data?.panVerified),
      email: data?.email,
      // email: "wolota2189@marksia.com",
      mobile: data?.mobile,
      // mobile: "7189893636",
      dob: data?.dateOfBirth,
      // dob: "1999/10/12",
      address: data?.address,
      kyc_status: String(data?.panVerified),
      kycDate: data?.kycDate,
      // kycDate: "2023/03/17",
      pancard: data?.pancard,
      idProof: data?.idProof,
    };

    if (!data?.pancard) {
      delete payload.pancard;
    }

    if (!data?.idProof) {
      delete payload.idProof;
    }

    let newFormData = new FormData();

    for (const key in payload) {
      if (key === "pancard") {
        newFormData.append("pan", payload[key]);
      } else {
        newFormData.append([key], payload[key]);
      }
    }

    try {
      let res = await createClientMember(newFormData, data?.id);

      setFormData((prev) => {
        handleNext(1, {
          ...prev,
          ...res?.data?.data?.result,
        });
        return {
          ...prev,
          ...res?.data?.data?.result,
        };
      });
    } catch (error) {
      console.log(error, "afkabfjkaf");
      handleErrorMessage(error?.response?.data?.error);
    }
  };

  return { api1, api2, api3 };
}

export default useClientOnboardFlow;
