import React from "react";
import { useStyles } from "./UserManagementHeaderStyles";
import { TextField, Typography } from "@material-ui/core";
import { ReactComponent as EditIcon } from "../../assets/clientdashboard/editIcon.svg";

export const EditableComponent = ({
  labelHeader,
  isEditing,
  formikErrors,
  outsideClickRef,
  textFieldId,
  formikValues,
  formikHandleChange,
  formikHandleBlur,
  formikTouched,
  setIsEditingFullName,
  isEditable = true,
}) => {
  const classes = useStyles();
  /*
  isEditing={isEditingFullName}
  formikErrors={formik.errors.fullName}
  outsideClickRef={fullNameOutsideClickRef}
  textFieldId="fullName"
  formikValues={formik.values.fullName}
  formikHandleChange={formik.handleChange}
  formikHandleBlur={formik.handleBlur}
  formikTouched={formik.touched.fullName}
  */
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "7px",
      }}
    >
      <div style={{ display: "flex", gap: "12px" }}>
        <Typography className="label">{labelHeader}</Typography>
        {isEditable && (
          <EditIcon
            style={{ cursor: "pointer" }}
            onClick={() => setIsEditingFullName(true)}
          />
        )}
      </div>
      {isEditing || formikErrors ? (
        <>
          <TextField
            ref={outsideClickRef}
            className={`${classes.editTextFieldText}`}
            id={textFieldId}
            value={formikValues}
            onChange={formikHandleChange}
            onBlur={formikHandleBlur}
            InputProps={{
              disableUnderline: true,
              // style: { paddingInline: "0px", minWidth: "100px" },
            }}
            // style={{ paddingInline: "0px", minWidth: "100px" }}
          />
          {formikTouched && formikErrors ? (
            <small
              style={{
                color: "red",
                display: "block",
                fontSize: "10px",
                textAlign: "center",
                marginTop: "7px",
              }}
            >
              {formikErrors}
            </small>
          ) : null}
        </>
      ) : (
        <>
          {/* <Typography className={classes.labelHeader}>{labelHeader}</Typography> */}
          <Typography className={classes.editText}>{formikValues}</Typography>
        </>
      )}
    </div>
  );
};
