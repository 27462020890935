import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "@material-ui/core";
import { ReactComponent as Search } from "../../../assets/icons/search.svg";
import { ReactComponent as Add } from "../../../assets/icons/circularadd.svg";
import { ReactComponent as Copy } from "../../../assets/icons/copy.svg";
import { myDebounce } from "../../../hooks/constantFunction";
import { useLocation, useNavigate } from "react-router-dom";
import { PermissionsGate } from "../../auth/PermissionGate";
import ls from "localstorage-slim";
import FullWidthInput2 from "../../common/FullWidthInput2";
const useStyles = makeStyles((theme) => ({
  buttonNo: {
    border: "1.5px solid var(--border)",
    background: "var(--secondaryBtn-color)",
    marginRight: "2%",
    marginLeft: "2%",
    borderRadius: "7px",
  },
  grow: {
    flexGrow: "0.8",
  },
  pseudoSearch: {
    background: "#1F1C40",
    borderRadius: "20px",
    display: "inline",
    border: "1px solid #711CF1",
    padding: "5px 12px",
    transition: "background-color 0.5 ease-in-out",
  },
  input: {
    border: "none!important",
    backgroundColor: "transparent",
    width: "200px",
    outline: "none",
    color: "#9488A7!important",
    fontFamily: "Avenir",
  },
  buttonContainer: {
    float: "right",
    display: "flex",
    width: "fit-content",
    marginRight: "45px",
    [theme.breakpoints.down("md")]: {
      width: "305px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "305px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "310px",
      marginBottom: "20px",
      marginTop: "20px",
    },
  },
  verticalMiddle: {
    justifyContent: "center",
  },
  searchHeaderWrapper: {},
  singleSearch: {
    float: "right",
    marginBottom: "15px",
  },
}));

function SearchHeaderSchemeMaster({
  setSearchName,
  setItemOffset,
  showAddButton,
  showAddButtonSingleUpload,
  btn2,
  btn3,
  btn4,
  btnTitle,
  btnTitle4,
}) {
  const classes = useStyles();
  // const { roleType, userPermissionsArr, scopes } = useGetRole();
  const scopes = JSON.parse(ls.get("scopes"));
  const navigate = useNavigate();
  const { state } = useLocation();
  const handleSearchChange = (value) => {
    setSearchName(value);
    setItemOffset(0);
  };

  const myDebounceSearch = useCallback(myDebounce(handleSearchChange), []);

  return (
    <div className={classes.searchHeaderWrapper}>
      <div
        className={` ${showAddButton === false ? "" : ""}`}
        style={{ height: "48px", display: "flex" }}
      >
        {/* <div style={{ width: "100%", display: "flex" }}> */}
        {/* <input
            onChange={(e) => myDebounceSearch(e.target.value)}
            className={" tLeftBorderRadius5 sch_inp_fw"}
            style={{}}
            type="text"
            placeholder="Search by name"
          />
          <div
            style={{
              background: " #711CF1",
              borderRadius: "6px",
              height: "100%",
              width: "48px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Search style={{ color: "#949BA5", verticalAlign: "middle" }} />
          </div> */}
        <FullWidthInput2
          customInputStyle={{
            height: "48px",
            // background: "#211839",
            width: "100%",
          }}
          customWrapperStyle={{
            width: btn4 ? "45%" : "100%",
            flexShrink: "12.9",
          }}
          setSearch={setSearchName}
        />
        {/* </div> */}

        {showAddButton === true && (
          <div className={classes.buttonContainer}>
            {showAddButtonSingleUpload === true && (
              <PermissionsGate scopes={[scopes.can_add_scheme_master_product]}>
                <Button
                  onClick={() =>
                    navigate("/masters/products/update", {
                      state: { ...state, id: 0, isin: 0 },
                    })
                  }
                  variant="contained"
                  style={{ minWidth: "72px", flexShrink: "0" }}
                  className={classes.buttonNo + " mobbtn"}
                  startIcon={<Add style={{ color: "#FFFFFF" }} />}
                >
                  <span
                    style={{
                      textTransform: "none",
                      fontFamily: "Avenir",
                      color: "#FFFFFF",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    ADD
                  </span>
                </Button>
              </PermissionsGate>
            )}
            <PermissionsGate scopes={[scopes.can_add_bulk_master_product]}>
              <Button
                onClick={() => {
                  // navigate("/masters/products/upload/bulk", { state });
                  if (btn2) {
                    console.log("button true");
                    btn2(true);
                  }
                }}
                style={{
                  // border: "1.5px solid #5626C5",
                  color: "#FFFFFF",
                  marginRight: "15px",
                  // background: "#2c1465",
                  borderRadius: "7px",
                  minWidth: "fit-content",
                }}
                variant="contained"
                className={classes.buttonNo + " mobbtn"}
                startIcon={<Copy style={{ color: "#FFFFFF" }} />}
              >
                <span
                  style={{
                    textTransform: "none",
                    fontFamily: "Avenir",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                >
                  Upload Scheme Master
                </span>
              </Button>
            </PermissionsGate>

            {btn3 && (
              <Button
                onClick={() => {
                  // navigate("/masters/products/upload/bulk", { state });
                  if (btn3) {
                    console.log("button true");
                    btn3(true);
                  }
                }}
                style={{
                  color: "#FFFFFF",
                  borderRadius: "7px",
                  minWidth: "fit-content",
                }}
                variant="contained"
                className={classes.buttonNo + " mobbtn"}
                startIcon={<Copy style={{ color: "#FFFFFF" }} />}
              >
                <span
                  style={{
                    textTransform: "none",
                    fontFamily: "Avenir",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                >
                  {btnTitle}
                </span>
              </Button>
            )}
            {btn4 && (
              <Button
                onClick={() => {
                  // navigate("/masters/products/upload/bulk", { state });
                  if (btn4) {
                    console.log("button true");
                    btn4(true);
                  }
                }}
                style={{
                  color: "#FFFFFF",
                  borderRadius: "7px",
                  minWidth: "fit-content",
                  marginRight: "145px",
                }}
                variant="contained"
                className={classes.buttonNo + " mobbtn"}
                startIcon={<Copy style={{ color: "#FFFFFF" }} />}
              >
                <span
                  style={{
                    textTransform: "none",
                    fontFamily: "Avenir",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                >
                  {btnTitle4}
                </span>
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default SearchHeaderSchemeMaster;
