import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Typography,
} from "@material-ui/core";
import React from "react";
import PartnerHeading from "../PartnerHeading";
import CustomInput from "../common/CustomInput";
import { ReactComponent as Upload } from "../../../assets/icons/upload.svg";
import { ReactComponent as VerifiedTick } from "../../../assets/icons/verified.svg";
import {
  registerClientForm1ValidationSchema3,
  registerClientForm1ValidationSchemaNonHOF,
} from "../ValidationSchema";
import seperation from "../../../assets/partner-images/seperation.svg";
import { useForm } from "../hooks/useForm";
import { useState } from "react";
import ErrorMessageComp from "../common/ErrorMessageComp";
import LoadingButton from "../common/LoadingButton";
import StepProgressBar from "../common/StepProgressBar/StepProgressBar";
import ImageModal from "../common/ImageModal";
import {
  ClientMemberRoles,
  ClientMemberRoles2,
  Client_User_Roles,
} from "../../../Constants";
import {
  handleInitValue,
  handleRoleId,
} from "../../user_management/utils/cmmon";
import { useMemo } from "react";

function ClientForm3({
  currentStep,
  handleBack,
  handleNext,
  customHandleSubmit,
  formData = {},
  head,
  apiErrorMessage,
  loading,
  totalStep,
  noStepBottom,
  formMaxHeight,
}) {
  const [files, setFiles] = useState();
  const [filesUrl, setFilesUrl] = useState({ idProof: "", pan: "" });
  const [openModal, setOpenModal] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const {
    kycStatus,
    kycDate,
    address,
    entityName,
    entityType,
    panNumber,
    commencementDate,
    doi,
    dateOfBirth,
    userRole,
    memberType,
    memberName,
    fullName,
    roleId,
  } = formData;

  const handleFileUpload = (e, key) => {
    // console.log(e?.target?.files[0],'afkjabfahsvf');
    setFiles((prev) => ({ ...prev, [key]: e.target.files[0] }));

    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = function (e) {
      setFilesUrl((prev) => ({ ...prev, [key]: reader.result }));
    }.bind(this);
  };

  const handleViewUploadedFile = (fileType) => {
    const fileUrl = filesUrl[fileType];
    if (fileUrl) {
      setOpenModal(true);
      setImageSrc(fileUrl);
    }
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useForm(
    {
      memberName: fullName ? fullName : "",
      memberType: memberType ? memberType : "",
      roleId: roleId ? handleRoleId(Number(roleId), ClientMemberRoles2) : "",
      panNumber: panNumber ? panNumber : "",
      // panNumber: "FPOTY1234D",
      commencementDate: commencementDate ? commencementDate : "",
      kycDate: kycDate ? kycDate : "",
      kycStatus: kycStatus ? kycStatus : "",
      address: address ? address : "",
      // idProof: "",
      // pan: "",
      dateOfBirth: dateOfBirth ? dateOfBirth : "",
    },
    (values) => {
      //   handle form submission
      //   values.password = Number(values.password);
      //   submitForm(values);
      //   customHandleSubmit(values);
      //   setOpen(true);
      let value2 = { ...values };
      value2["idProof"] = files?.["idProof"];
      value2["pancard"] = files?.["pan"];
      // console.log(value2, "aglagbj");
      if (memberType == "individual") {
        delete value2.commencementDate;
      }

      // console.log(values, value2, files, "aflkbabfbuawubfw61984689162481");
      customHandleSubmit(value2);
    },
    // values?.roleId == 2
    // ?
    memberType == "individual"
      ? registerClientForm1ValidationSchemaNonHOF
      : registerClientForm1ValidationSchema3
  );

  const verified = {
    endAdornment: (
      <InputAdornment position="end">
        <VerifiedTick />
      </InputAdornment>
    ),
  };

  return (
    <Box
      className="dFlex jtfyContCntr alignCntr h100 flexDirCol"
      sx={{ width: "auto", gap: "41px" }}
    >
      <div className="newFormTopSec" style={{ width: "730px" }}>
        <PartnerHeading
          marginLeft={"-91px"}
          mainHead={head?.main ? head?.main : "Head of Family Details"}
          step={currentStep}
          handleBack={handleBack}
        />
      </div>
      <div className="newFormContainer" style={{ maxHeight: formMaxHeight }}>
        <Box
          className="dFlex jtfyContCntr flexDirCol secondScreen"
          sx={{
            width: "730px",
            gap: "41px",
            //   position: "relative",
            //   overflow: "auto",
          }}
        >
          {/* <PartnerHeading
        marginLeft={"-91px"}
        mainHead={head?.main ? head?.main : "Head of Family Details"}
        step={currentStep}
        handleBack={handleBack}
      /> */}

          <form onSubmit={handleSubmit}>
            <Box
            //   sx={{ borderRight: "2px dashed #3E5389", paddingRight: "5%" }}
            //   item
            >
              {/* <Formik
          initialValues={{ email: "", pin: "", rememberInfo: false }}
          validationSchema={logInValidationSchema}
        > */}
              {/* {({ errors, touched, isSubmitting, values, setFieldValue }) => ( */}
              <Grid
                container
                spacing={2}
                style={{ justifyContent: "space-between" }}
              >
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: "space-between",
                    maxWidth: "47%",
                    gap: "15px",
                  }}
                  item
                  xs={12}
                  sm={12}
                  md={6}
                >
                  <CustomInput
                    title="PAN NO"
                    // as={TextField}
                    variant="outlined"
                    margin="normal"
                    // required
                    fullWidth
                    id="panNumber"
                    label="Pan Number"
                    name="panNumber"
                    error={touched.panNumber && Boolean(errors.panNumber)}
                    helperText={touched.panNumber && errors.panNumber}
                    value={values.panNumber}
                    onChange={handleChange}
                    disabled={true}
                    InputProps={
                      formData?.kycStatus == "verified" ? verified : {}
                    }
                  />
                  {/* <Grid xs={12} lg={12} item> */}
                  <CustomInput
                    title="user role"
                    //   disabled={true}
                    // as={TextField}
                    select
                    variant="outlined"
                    margin="normal"
                    // required
                    fullWidth
                    id="roleId"
                    label="User Role"
                    name="roleId"
                    autoComplete="roleId"
                    autoFocus
                    error={touched.roleId && Boolean(errors.roleId)}
                    helperText={touched.roleId && errors.roleId}
                    value={values.roleId}
                    onChange={(e) => {
                      console.log(e.target.value, "afljj");
                      // setSchemaSelector(() => e.target.value);
                      handleChange(e);
                    }}
                  >
                    {ClientMemberRoles2.map((entity) => {
                      return (
                        <MenuItem
                          key={entity.value}
                          value={entity.value}
                          selected={entity?.selected}
                          color="white"
                          style={{
                            border: "0.34px solid #3E5389",
                            backgroundColor: "#131826",
                          }}
                        >
                          {entity.name}
                        </MenuItem>
                      );
                    })}
                  </CustomInput>
                  {/* </Grid> */}
                  {/* <CustomInput
                    title="user role"
                    // as={TextField}
                    variant="outlined"
                    margin="normal"
                    // required
                    fullWidth
                    id="userRole"
                    label="User Role"
                    name="userRole"
                    autoComplete="userRole"
                    autoFocus
                    error={touched.userRole && Boolean(errors.userRole)}
                    helperText={touched.userRole && errors.userRole}
                    value={values.userRole}
                    onChange={handleChange}
                    disabled={true}
                  /> */}
                  <CustomInput
                    title="Member NAME"
                    // as={TextField}
                    variant="outlined"
                    margin="normal"
                    // required
                    fullWidth
                    id="memberName"
                    label="Member Name"
                    name="memberName"
                    // autoComplete="email"
                    autoFocus
                    error={touched.memberName && Boolean(errors.memberName)}
                    helperText={touched.memberName && errors.memberName}
                    value={values.memberName}
                    disabled={true}
                  />
                  <CustomInput
                    title="Member type"
                    // as={TextField}
                    variant="outlined"
                    margin="normal"
                    // required
                    fullWidth
                    id="memberType"
                    label="Member type"
                    name="memberType"
                    // autoComplete="email"
                    autoFocus
                    error={touched.memberType && Boolean(errors.memberType)}
                    helperText={touched.memberType && errors.memberType}
                    value={values.memberType}
                    disabled={true}
                  />
                  <CustomInput
                    // as={TextField}
                    title={`Date of ${
                      memberType == "individual" ? "Birth" : "Incorporation"
                    } `}
                    variant="outlined"
                    margin="normal"
                    // required
                    fullWidth
                    id="dateOfBirth"
                    label={`Date of Birth`}
                    name="dateOfBirth"
                    autoComplete="dateOfBirth"
                    autoFocus
                    // type="select"
                    error={touched.dateOfBirth && Boolean(errors.dateOfBirth)}
                    helperText={touched.dateOfBirth && errors.dateOfBirth}
                    type="date"
                    // value={new Date(values.dateOfIncorporation)}
                    value={values.dateOfBirth}
                    onChange={handleChange}
                    // disabled={true}
                  />

                  {memberType == "individual" ? (
                    <CustomInput
                      title="UPLOAD PAN"
                      // as={TextField}
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      className="custom-file-input"
                      id="pan"
                      label="Upload Pan"
                      name="pan"
                      type="file"
                      error={touched.pan && Boolean(errors.pan)}
                      helperText={touched.pan && errors.pan}
                      value={values.pan}
                      onChange={(e) => {
                        handleFileUpload(e, "pan");
                        handleChange(e);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            style={{
                              background: " #3E5389",
                              width: "25px",
                              height: "23px",
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <IconButton
                              onClick={() => {
                                document.getElementById("pan").click();
                              }}
                            >
                              <Upload />
                            </IconButton>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography
                              onClick={() => handleViewUploadedFile("pan")}
                              sx={{ color: "#8C92A0", cursor: "pointer" }}
                            >
                              View
                            </Typography>
                          </InputAdornment>
                        ),
                      }}
                      // disabled={true}
                    />
                  ) : (
                    <CustomInput
                      // as={TextField}
                      title="date of Commencement "
                      variant="outlined"
                      margin="normal"
                      // required
                      // type="date"
                      fullWidth
                      id="commencementDate"
                      label="Commencement Date"
                      name="commencementDate"
                      autoComplete="commencementDate"
                      autoFocus
                      error={
                        touched.commencementDate &&
                        Boolean(errors.commencementDate)
                      }
                      helperText={
                        touched.commencementDate && errors.commencementDate
                      }
                      type="date"
                      value={values.commencementDate}
                      onChange={handleChange}
                    />
                  )}
                </Grid>
                <img src={seperation} style={{ objectFit: "cover" }}></img>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: "space-between",
                    maxWidth: "47%",
                    gap: "15px",
                  }}
                  xs={12}
                  sm={12}
                  md={6}
                >
                  {/* <Grid container>
                    <Grid lg={12} sx={{ mb: "10px" }} item> */}
                  <CustomInput
                    title="ADDRESS"
                    // as={TextField}
                    variant="outlined"
                    margin="normal"
                    // required
                    fullWidth
                    id="address"
                    label="Address"
                    name="address"
                    error={touched.address && Boolean(errors.address)}
                    helperText={touched.address && errors.address}
                    rows={4}
                    value={values.address}
                    onChange={handleChange}
                    multiline
                  />
                  {/* </Grid> */}

                  {/* <Grid lg={12} sx={{ mb: "10px" }} item> */}
                  <CustomInput
                    // as={TextField}
                    title="KYC STATUS"
                    variant="outlined"
                    margin="normal"
                    // required
                    fullWidth
                    id="kycStatus"
                    label="KYC Status"
                    name="kycStatus"
                    autoComplete="email"
                    autoFocus
                    error={touched.kycStatus && Boolean(errors.kycStatus)}
                    helperText={touched.kycStatus && errors.kycStatus}
                    value={values.kycStatus}
                    onChange={handleChange}
                    disabled={true}
                    InputProps={
                      formData?.kycStatus == "verified" ? verified : {}
                    }
                  />
                  {/* </Grid> */}

                  {/* <Grid lg={12} sx={{ mb: "10px" }} item> */}
                  <CustomInput
                    title="KYC DATE"
                    // as={TextField}
                    variant="outlined"
                    margin="normal"
                    // required
                    fullWidth
                    id="kycDate"
                    label="KYC Date"
                    name="kycDate"
                    error={touched.kycDate && Boolean(errors.kycDate)}
                    helperText={touched.kycDate && errors.kycDate}
                    // defaultValue={values.kycDate}
                    value={values.kycDate}
                    onChange={handleChange}
                    type="date"
                    // disabled={true}
                  />
                  <CustomInput
                    title="Upload Aadhar"
                    // as={TextField}
                    variant="outlined"
                    margin="normal"
                    // required
                    className="custom-file-input"
                    fullWidth
                    id="idProof"
                    label="Upload Aadhar"
                    name="idProof"
                    type="file"
                    error={touched.idProof && Boolean(errors.idProof)}
                    helperText={touched.idProof && errors.idProof}
                    value={values?.idProof}
                    // onChange={handleChange}
                    onChange={(e) => {
                      handleFileUpload(e, "idProof");
                      handleChange(e);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          style={{
                            background: " #3E5389",
                            width: "25px",
                            height: "23px",
                            borderRadius: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              document.getElementById("idProof").click();
                            }}
                          >
                            <Upload />
                          </IconButton>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography
                            sx={{ color: "#8C92A0", cursor: "pointer" }}
                            onClick={() => handleViewUploadedFile("idProof")}
                          >
                            View
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    // disabled={true}
                  />
                  {memberType !== "individual" && (
                    <CustomInput
                      title="UPLOAD PAN"
                      // as={TextField}
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      className="custom-file-input"
                      id="pan"
                      label="Upload Pan"
                      name="pan"
                      type="file"
                      error={touched.pan && Boolean(errors.pan)}
                      helperText={touched.pan && errors.pan}
                      value={values.pan}
                      onChange={(e) => {
                        handleFileUpload(e, "pan");
                        handleChange(e);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            style={{
                              background: " #3E5389",
                              width: "25px",
                              height: "23px",
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <IconButton
                              onClick={() => {
                                document.getElementById("pan").click();
                              }}
                            >
                              <Upload />
                            </IconButton>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography
                              onClick={() => handleViewUploadedFile("pan")}
                              sx={{ color: "#8C92A0", cursor: "pointer" }}
                            >
                              View
                            </Typography>
                          </InputAdornment>
                        ),
                      }}
                      // disabled={true}
                    />
                  )}
                  {/* </Grid> */}
                  {/* </Grid> */}
                </Grid>
              </Grid>

              {/* </Formik> */}
            </Box>

            <Box
              sx={{
                position: "sticky",
                bottom: "0px",
                width: "100%",
                backgroundColor: "black",
                paddingTop: "4px",
              }}
            >
              <ErrorMessageComp errorMessage={apiErrorMessage} />

              <LoadingButton
                buttonTitle={"Continue"}
                type="submit"
                showLoader={loading}
              />
              {noStepBottom ? (
                ""
              ) : (
                <Box mt={3.5}>
                  <StepProgressBar
                    total={totalStep}
                    currentStep={currentStep}
                  />
                </Box>
              )}
            </Box>
          </form>
        </Box>
      </div>
      <ImageModal
        openModal={openModal}
        imageSrc={imageSrc}
        setOpenModal={setOpenModal}
      />
    </Box>
  );
}

export default ClientForm3;
