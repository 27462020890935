import BarChart from "../../charts/BarChart";
import BarGraph from "../../charts/BarGraph";
import { divergingCommonProps } from "../../charts/nivo_objects";
import Footer from "./Components/Footer";
import H_Cards from "./Components/H_Cards";
import Header from "./Components/Header";
import Hr from "./Components/Hr";
import Bar from "./Components/barGraph";
import CardsWrapper from "./Components/cardsWrapper";
import background from "./images/background.svg";
import { ReactComponent as Background } from "./images/background.svg";
import "./pag1.css";

import React from "react";
import Footer1 from "./pages/subPrintPages/Footer1";
import PrintHeader from "./Components/PrintHeader";
import useGetMongoPrintReport from "../../../hooks/useGetMongoPrintReport";
import { useSearchParams } from "react-router-dom";
import { FooterLegend } from "./Components/FooterLegend";

export default function PageContainer({
  children,
  pageNo,
  layout_t,
  customRef,
  bgColor,
  pdfInfo,
  renderFooterLegend,
  footerLegendText,
  footerLegendNotes,
  asOnDate,
  headerText,
}) {
  let [searchParams, setSearchParams] = useSearchParams();
  const mongoId = searchParams.get("id");
  // const { newData, denomination, meta, loading } =
  //   useGetMongoPrintReport(mongoId);
  // console.log("fdsfdsf45f343", meta, newData);

  function layout(layout_t) {
    switch (layout_t) {
      case 1:
        return (
          <div
            ref={customRef}
            style={{
              // width: "100vw",
              // height: "100%",
              backgroundColor: bgColor || "#f6f8fd",
            }}
            className="pdf-page"
          >
            <PrintHeader pdfInfo={pdfInfo} headerText={headerText} />
            <div
              style={{
                marginLeft: "66px",
                marginRight: "66px",
                marginBottom: "10px",
                marginTop: "40px",
              }}
            >
              {children}
            </div>

            {/* Held Away Legend */}
            {<FooterLegend notes={footerLegendNotes} />}
            {renderFooterLegend || (
              <FooterLegend
                valuationAsOnDate={asOnDate}
                // notes={footerLegendNotes}
                text={footerLegendText}
                pdfInfo={pdfInfo}
              />
            )}
            <Footer page={pageNo} data={pdfInfo} />
          </div>
        );
      case 2:
        return (
          <div
            style={{
              // width: "100vw",
              // height: "100%",
              backgroundColor: "#ffffff",
              // backgroundImage: `url("${background}")`,
              // backgroundSize: "50% 100%",
              // backgroundRepeat: "no-repeat",
              position: "relative",
            }}
            className="pdf-page"
          >
            <PrintHeader pdfInfo={pdfInfo} />
            <div style={{ backgroundColor: "#f6f8fd", width: "8in" }}>
              <Background width={"100%"} height={"100%"}></Background>
            </div>
            {/* <img src={background} width={"50%"} height={"910px"}></img> */}
            <div style={{ position: "absolute", top: 0, width: "100vw" }}>
              <div
                style={{
                  marginLeft: "66px",
                  marginRight: "66px",
                }}
              >
                {children}
              </div>
            </div>
            <Footer page={pageNo} dFooterata={pdfInfo} />
          </div>
        );

      case 3:
        return (
          <div
            style={{
              // width: "100vw",
              // height: "100%",
              backgroundColor: "#f6f8fd",
              // backgroundImage: `url("${background}")`,
              // backgroundSize: "50% 100%",
              // backgroundRepeat: "no-repeat",
              position: "relative",
            }}
            className="pdf-page"
          >
            <PrintHeader pdfInfo={pdfInfo} />
            <div style={{ backgroundColor: "#FFF", width: "8in" }}>
              <div
                style={{
                  width: "100%",
                  height: "11.3in",
                  background: "#fff",
                }}
              ></div>
            </div>
            {/* <img src={background} width={"50%"} height={"910px"}></img> */}
            <div style={{ position: "absolute", top: 0, width: "100vw" }}>
              <div
                style={{
                  marginLeft: "66px",
                  marginRight: "66px",
                }}
              >
                {children}
              </div>
            </div>
            <Footer page={pageNo} data={pdfInfo} />
          </div>
        );
      case 4:
        return (
          <div
            ref={customRef}
            style={{
              // width: "100vw",
              // height: "100%",
              backgroundColor: "#ffff",
            }}
            className="pdf-page"
          >
            <PrintHeader pdfInfo={pdfInfo} />
            <div
              style={{
                marginLeft: "66px",
                marginRight: "66px",
                marginBottom: "10px",
              }}
            >
              {children}
            </div>
            <Footer page={pageNo} data={pdfInfo} />
          </div>
        );

      default:
        return (
          <div
            style={{
              // width: "100vw",
              // height: "100%",
              backgroundColor: "#f6f8fd",
            }}
            className="pdf-page"
          >
            <PrintHeader pdfInfo={pdfInfo} />
            <div style={{ marginLeft: "66px", marginRight: "66px" }}>
              {children}
            </div>
            <Footer page={pageNo} data={pdfInfo} />
          </div>
        );
    }
  }

  return <div>{layout(layout_t)}</div>;
}
