// import React from "react";
// import { Box } from "@material-ui/core";
// import "./StrategyBuilder.css";
// export const SelectedMemberList = ({ selectedMembers, svgIcon }) => {
//   console.log("selectedMembers", selectedMembers.length);
//   const renderItem = (selectedMembers) => {
//     if (selectedMembers.length > 6) {
//       return <p style={{ color: "white" }}>Show More...</p>;
//     } else {
//       return selectedMembers.map((member, index) => (
//         <Box className="selectedMembersList" key={index}>
//           {svgIcon}
//           <p className="strategyBuilderMemberName">{member.name}</p>
//         </Box>
//       ));
//     }
//   };
//   return (
//     <>
//       <Box className="selectedMembersListWrapper">
//         {renderItem(selectedMembers)}
//       </Box>
//     </>
//   );
// };
// import React, { useState } from "react";
// import { Box } from "@material-ui/core";
// import "./StrategyBuilder.css";

// export const SelectedMemberList = ({ selectedMembers, svgIcon }) => {
//   const [showAll, setShowAll] = useState(false);

//   const toggleShowAll = () => {
//     setShowAll(!showAll);
//   };

//   const renderSelectedMembers = () => {
//     if (showAll) {
//       // Display all selected members
//       return selectedMembers.map((member, index) => (
//         <Box className="selectedMembersList" key={index}>
//           {svgIcon}
//           <p className="strategyBuilderMemberName">{member.name}</p>
//         </Box>
//       ));
//     } else {
//       // Display the first 6 selected members
//       const firstSixMembers = selectedMembers.slice(0, 6);
//       return firstSixMembers.map((member, index) => (
//         <Box className="selectedMembersList" key={index}>
//           {svgIcon}
//           <p className="strategyBuilderMemberName">{member.name}</p>
//         </Box>
//       ));
//     }
//   };

//   return (
//     <>
//       <Box className="selectedMembersListWrapper">
//         {renderSelectedMembers()}
//         {selectedMembers.length > 6 && !showAll && (
//           <p
//             style={{ color: "white", cursor: "pointer" }}
//             onClick={toggleShowAll}
//           >
//             Show More...
//           </p>
//         )}
//       </Box>
//     </>
//   );
// };

import React, { useState } from "react";
import { Box } from "@material-ui/core";
import "./StrategyBuilder.css";

export const SelectedMemberList = ({
  selectedMembers,
  svgIcon,
  customStyle,
}) => {
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const renderSelectedMembers = () => {
    if (showAll) {
      // Display all selected members
      return selectedMembers.map((member, index) => (
        <Box
          style={{ ...customStyle?.selectedItem }}
          className="selectedMembersList"
          key={index}
        >
          {svgIcon}
          <p className="strategyBuilderMemberName">{member.name}</p>
        </Box>
      ));
    } else {
      // Display the first 6 selected members
      const firstSixMembers = selectedMembers.slice(0, 6);
      return firstSixMembers.map((member, index) => (
        <Box
          style={{ ...customStyle?.selectedItem }}
          className="selectedMembersList"
          key={index}
        >
          {svgIcon}
          <p className="strategyBuilderMemberName">{member.name}</p>
        </Box>
      ));
    }
  };

  return (
    <>
      <Box
        className="selectedMembersListWrapper"
        style={{ ...customStyle?.wrapper }}
      >
        {selectedMembers.length === 0 && (
          <p className="strategyBuilderSelectText">
            + Select the members for the consolidated portfolio view.
          </p>
        )}
        {renderSelectedMembers()}
        {selectedMembers.length > 6 && (
          <p
            style={{
              cursor: "pointer",
              marginLeft: "1.5rem",
              fontWeight: "600",
              color: "#FFF",
              fontFamily: "Avenir",
              fontStyle: "normal",

              ...customStyle?.selectedItem,
            }}
            onClick={toggleShowAll}
          >
            {showAll ? "Show Less..." : "Show More..."}
          </p>
        )}
      </Box>
    </>
  );
};
