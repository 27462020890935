import React from "react";
import Default from "../components/dashboard/defaultPages/Default";
import NewClientList from "../components/newClientList/NewClientList";
import { OnboardingContext } from "../Context";
import NewClientListOpsUser from "../components/newClientList/NewClientListOpsUser";

function NewClientListPage() {
  const { userState } = OnboardingContext();

  return (
    <>
      <Default>
        {userState?.loginedUserData?.user_type == "mfo-user" ? (
          <NewClientListOpsUser />
        ) : (
          <NewClientList />
        )}
      </Default>
    </>
  );
}

export default NewClientListPage;
