import { useState } from "react";
import {
  createStrategyBuildersAPI,
  getDashboardAssetData,
  getIndexExpectedReturnAPI,
  getStrategies,
  get_index_performance,
  updateStrategyBuildersAPI,
} from "../../../../api";

function useGetStrategyTable() {
  const [loading, setLoading] = useState(false);

  const getStrategyData = async ({ params }) => {
    setLoading(true);
    try {
      let res = await getStrategies({ params });
      // setter(res?.data?.data?.result);

      setLoading(false);
      return res;
    } catch (error) {
      setLoading(false);
    }
  };

  const getIndexExpectedReturn = async ({ params }) => {
    setLoading(true);
    try {
      let res = await getIndexExpectedReturnAPI({ params });

      setLoading(false);
      return res?.data?.data?.result;
    } catch (error) {
      setLoading(false);
      return error;
    }
  };

  const creatStrategy = async ({ params ,isBenchmark}) => {
    setLoading(true);
    try {
      let res = await createStrategyBuildersAPI(params,isBenchmark);
      setLoading(false);
      return res;
    } catch (error) {
      setLoading(false);
    }
  };

  const getIndexList = ({ setter, params, assetClass }) => {
    setLoading(true);

    try {
      get_index_performance(undefined, {
        //limit: 10,
        ...params,
      }).then((res) => {
        setter((prev) => ({ ...prev, [assetClass]: res?.result }));
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      // console.log(error, "gajgkbkj");
    }
  };

  const updateStrategy = async ({ params }) => {
    setLoading(true);
    try {
      let res = await updateStrategyBuildersAPI(params);
      setLoading(false);
      return res;
    } catch (error) {
      setLoading(false);
      return error;
    }
  };

  const getAllocationData = async ({ clientId, type, filters }) => {
    try {
      let res = await getDashboardAssetData(
        clientId,
        { ...filters, assetClass: "alternate,cash,debt,equity" },
        type
      );

      return res?.data;
    } catch (error) {
      // console.log(error, "kjbhhjh");
    }
  };

  return {
    getIndexList,
    getStrategyData,
    getIndexExpectedReturn,
    creatStrategy,
    updateStrategy,
    getAllocationData,
    loading,
  };
}

export default useGetStrategyTable;
