import React from "react";
import HomePage from "../components/dashboard/clientDashboard/HomePage";
import Default from "../components/dashboard/defaultPages/Default";
import { OnboardingContext } from "../Context";
import { PartnerDashBoardPage } from "../components/dashboard/clientDashboard/PartnerDashBoardPage";
import { AdminDashBoardPage } from "../components/dashboard/clientDashboard/AdminDashBoardPage";

function Home() {
  const { userState } = OnboardingContext();
  const userType = userState?.loginedUserData?.user_type;
  return (
    <Default>
      {userType === "client" && <HomePage></HomePage>}
      {userType === "partner" && <PartnerDashBoardPage></PartnerDashBoardPage>}
      {userType === "mfo-user" && <AdminDashBoardPage></AdminDashBoardPage>}
    </Default>
  );
}

export default Home;
