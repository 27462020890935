import React, { useMemo, useRef } from "react";
import TableSummaryDetails from "../../products/newDesigns/common/newSummaryDetailsTable/TableSummaryDetails";
import { useState } from "react";
import { bodyData3, headerDataTargetPotFolio } from "../constand";
import { useEffect } from "react";
import {
  convertToListObj,
  getListFromStr,
  objectFormater,
  strategyBuilderTopNavData,
} from "../common/utils/utils";
import PopoverComp from "../common/PopoverComp";
import AppPagination from "../../../verificationUpload/AppPagination";
import { ITEMS_LIMIT_FILES_PARTNER_LIST } from "../../../../Constants";
import TableTopComp from "../common/TableTopComp";
import { useParams } from "react-router-dom";
import useGetStrategyTable from "../hooks/useGetStrategyTable";
import PageLoader from "../../products/common/pageLoader";
import { OnboardingContext } from "../../../../Context";
import EditBtn from "../common/EditBtn";
import FullWidthInput from "../../../common/FullWidthInput";
import { TableDataNotFound } from "../common/TableDataNotFound";

function TargetPortfolioTable({ handleNext, setRowData, type }) {
  const [rawtableData, setRawTableData] = useState(bodyData3);
  const [customHeader, setCustomHeader] = useState(headerDataTargetPotFolio);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [data, setData] = useState([]);
  const [offset, setItemOffset] = useState(0);
  const { pageName } = useParams();
  const { getStrategyData, loading } = useGetStrategyTable();
  const { userState } = OnboardingContext();
  const [search, setSearch] = useState("");
  const totalPage = useRef(20);
  // console.log("TargetPortfolioTable", rawtableData);

  const handleMemberColClick = (event, data) => {
    setData(() => convertToListObj(getListFromStr(data?.members), "title"));
    setAnchorEl1(event.currentTarget);
  };

  const handleClickEdit = (e, data) => {
    // console.log(data, "fskfbkjuk");
    setRowData(() => data);
    handleNext();
  };

  const tableColClickConfig = {
    members: handleMemberColClick,
    created_at: handleClickEdit,
  };

  let anchor1 = useMemo(() => {
    if (data?.length > 0) return anchorEl1;
    else return null;
  }, [data]);

  useEffect(() => {
    setRowData(() => {});
  }, []);

  function fetchStratergyData() {
    let params = {
      clientId: userState?.loginedUserData?.id,
      // search: search,
      // clientId: 267,
      limit: 5,
      offset: offset,
      type: type ? type : "TargetPortfolio",
    };
    if (search.length > 1) {
      params.search = search;
    }
    getStrategyData({
      params: params,
    })
      .then((res) => {
        setRawTableData(() => res?.data?.data?.result);
        console.log(res?.data?.data?.meta?.count / 5, "agjkabgkj");
        // console.log(res?.data?.data?.meta?.count, "agjkabgkj");

        totalPage.current = Math.ceil(res?.data?.data?.meta?.count / 5);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (search.length == 0) {
      fetchStratergyData();
    }
  }, [offset, search]);

  useEffect(() => {
    if (search === "" && offset == 0) {
      return;
    }

    let params = {
      clientId: userState?.loginedUserData?.id,
      // search: search,
      // clientId: 267,
      limit: 5,
      offset: offset,
      type: type ? type : "TargetPortfolio",
    };
    if (search.length > 0) {
      if (search == " " && offset == 0) {
        return;
      }
      let params = {
        clientId: userState?.loginedUserData?.id,
        // search: search,
        // clientId: 267,
        limit: 5,
        offset: offset,
        type: type ? type : "TargetPortfolio",
      };
      if (search.length > 0) {
        params.search = search;
      }
      getStrategyData({
        params: params,
      })
        .then((res) => {
          setRawTableData(() => res?.data?.data?.result);
          console.log(res?.data?.data?.meta?.count / 5, "agjkabgkj");
          // console.log(res?.data?.data?.meta?.count, "agjkabgkj");

          totalPage.current = Math.ceil(res?.data?.data?.meta?.count / 5);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [search]);

  const handleClose = () => {
    setData(() => []);
    setAnchorEl1(null);
  };

  // console.log(rawtableData, "aglkagbjkabgj");

  return (
    <>
      <TableTopComp
        addButtonCLick={handleNext}
        page={pageName}
        data={strategyBuilderTopNavData}
        rawtableData={rawtableData}
        back={-1}
      />
      {rawtableData?.length === 0 ? (
        <>
          {<FullWidthInput setSearch={setSearch} />}
          <TableDataNotFound
            tabType={
              type === "UniqueClientIndex"
                ? "Unique Client Index"
                : "Target Portfolio"
            }
            addButtonCLick={handleNext}
          />
        </>
      ) : (
        <>
          {<FullWidthInput setSearch={setSearch} />}
          {customHeader?.length > 0 && (
            <TableSummaryDetails
              headerData={customHeader}
              data={rawtableData}
              customHandleRow={(row, header) => {
                return objectFormater(row, header, tableColClickConfig);
              }}
              customStyle={{ headerStyle: { className: "newHead2" } }}
              customIcon={
                <>
                  <EditBtn />
                </>
              }
            />
          )}
          {anchor1 !== null && (
            <PopoverComp
              anchorEl={anchor1}
              open={anchor1 !== null ? true : false}
              data={data}
              handleClose={handleClose}
            />
          )}
          <div style={{ display: "flex", justifyContent: "center" }}>
            {/* TODO total entries on table is required to calculate 
            pageCount which is not available in API response*/}
            <AppPagination
              pageCount={totalPage.current}
              itemLimit={ITEMS_LIMIT_FILES_PARTNER_LIST}
              setItemOffset={setItemOffset}
            />
          </div>
        </>
      )}

      <PageLoader showLoader={loading} />
    </>
  );
}

export default TargetPortfolioTable;
