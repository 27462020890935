import { OnboardingContext } from "../Context";
import { fetchEquityStockDetail } from "../api";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
export const useEquityStockDetail = () => {
  const { userState } = OnboardingContext();
  let clientId = userState.loginedUserData.id;
  const [loading, setLoading] = useState(true);
  const [equityStockData, setEquityStockData] = useState([]);
  const { isin } = useParams();
  useEffect(() => {
    setLoading(true);
    fetchEquityStockDetail(isin)
      .then((response) => {
        const apiData = response?.data?.data?.result;

        setEquityStockData(apiData);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(`An Error ${err} occurred`);
      });
  }, []);
  return [equityStockData, loading];
};
