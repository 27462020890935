import { makeStyles } from "@material-ui/core/styles";

export const useNewMenuStyles = makeStyles((theme) => ({
  newMenuContainer: {
    display: "flex",
    minWidth: "1425px",
    position: "absolute",
    top: "70px",
    left: "18px",
    zIndex: 99999,
  },
  newMenuLeftSide: {
    width: "308px",
    minHeight: "500px",
    background: theme.palette.newMenu.leftSideBackground,
    boxShadow: "22px 15px 32px 0px rgba(0, 0, 0, 0.81)",
    display: "flex",
    flexDirection: "column",
    borderRadius: "8px 0px 0px 8px",
  },
  newMenuRightSide: {
    minHeight: "500px",
    backgroundColor: theme.palette.newMenu.rightSideBackground,
    boxShadow: "22px 15px 32px 0px rgba(0, 0, 0, 0.81)",
    flexGrow: 1,
    overflow: "scroll",
    overflowX: "hidden",
  },
  "newMenuRightSide::-webkit-scrollbar-thumb": { background: "#0b0717" },
  newMenuList: {
    listStyle: "none",
    display: "flex",
    flexDirection: "column",
    margin: "0",
    padding: "0",
    gap: "20px",
    marginTop: "1rem",
  },
  newMenuItem: {
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "1rem",
    paddingRight: "1.5rem",
    "&:hover": {
      cursor: "pointer",
      background: theme.palette.newMenu.leftSideMenuItemHover,
      color: theme.palette.newMenu.newMenutItemTxtColor,
      boxShadow:
        "0px -5px 22px 0px rgba(0, 0, 0, 0.25), 0px 5px 22px 0px rgba(0, 0, 0, 0.25)",
      transition: "0.3s ease-in",
    },
    "&:last-child": {
      marginBottom: "1rem",
    },
  },

  newMenuIconWithText: { display: "flex", alignItems: "center", gap: "1rem" },
  leftSideMenuItemSelected: {
    background: theme.palette.newMenu.leftSideMenuItemSelected,
    boxShadow:
      "0px -5px 22px 0px rgba(0, 0, 0, 0.25),\n    0px 5px 22px 0px rgba(0, 0, 0, 0.25)",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "1rem",
    paddingRight: "1.5rem",
    "& $newMenuText": {
      // This targets newMenuText when nested under leftSideMenuItemSelected
      color: theme.palette.newMenu.newMenuItemSelectedText, // Change this to whatever color you want for the selected state
    },
  },
  "#newMenuCheckedCirlceIcon": { width: "20px", height: "20px" },
  newMenuIcon: {
    display: "block",
    width: "50px",
    height: "50px",
    borderRadius: "7px",
    background: "#11041e",
    marginLeft: "1rem",
  },
  newMenuText: {
    // color: "#fff",
    color: theme.palette.newMenu.newMenuText,
    fontFamily: "Avenir",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "1.26px",
    textTransform: "uppercase",
  },
  notFoundText: {
    textAlign: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-0%, -50%)",
    color: "white",
    fontFamily: "Avenir",
    fontSize: "1.7rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "1.26px",
  },
  newArrow: { cursor: "pointer" },
  searchWrapper: {
    display: "flex",
    marginTop: "20px !important",
    backgroundColor: "#211839",
    margin: "0px 26px",
  },
  searchWrapperInput: {
    width: "100%",
    margin: "0 auto",
    display: "block",
    background: theme.palette.newMenu.searchWrapperInput,
    borderRadius: "6px !important",
    border: "none",
    padding: "1rem",
  },
  newMenuHeadingWrapper: { marginTop: "1rem" },
  newMenuHeading: {
    // color: "#fff",
    color: theme.palette.newMenu.newMenuHeading,
    fontFamily: "Avenir",
    fontSize: "20px !important",
    fontStyle: "normal",
    fontWeight: 500,
    paddingLeft: "2rem",
  },
  newMenuSubHeading: {
    // color: "#fff",
    color: theme.palette.text.primary,
    fontFamily: "Avenir",
    fontSize: "14px !important",
    fontStyle: "normal",
    fontWeight: 400,
    paddingLeft: ["10px", "2rem"],
    marginTop: "0",
    marginBottom: "0",
    lineHeight: "20px",
  },
  newMenuSearchBar: {
    margin: "0 auto",
    display: "block",
    borderRadius: "6px !important",
    background: "#211839",
    border: "none",
    padding: "20px",
  },
  expandedAccordion: {
    backgroundColor: "#000000 !important",
    color: "white !important",
    padding: "10px 16px 28px 16px !important",
  },
  expandedSvgAccordion: { transform: "rotate(270deg)" },
  collapsedSvgAccordion: { transform: "rotate(0deg)" },
  unexpandedAccordion: {
    boxShadow: "none !important",
    outline: "none !important",
    borderBottom: "none !important",
    "&:hover": {
      backgroundColor: "#211839 !important",
      transition: "0.2s ease-in",
    },
  },
  newMenuDashBoardItem: {
    borderRadius: "8px",
    cursor: "pointer",
    transition: "0.2s ease",
    padding: "0.5rem",
    height: "202px",
    width: "202px",
    display: "flex",
    flexDirection: "column",
    "&:hover": {
      background: theme.palette.newMenu.newMenuDashBoardItemHover,
    },
  },
  expandedAccordion: {
    backgroundColor: "#000000 !important",
    color: "white !important",
    padding: "10px 16px 28px 16px !important",
  },
  unexpandedAccordion: {
    boxShadow: "none !important",
    outline: "none !important",
    borderBottom: "none !important",
    "&:hover": {
      backgroundColor: `${theme.palette.newMenu.unexpandedAccordionHover} !important`,
      transition: "0.2s ease-in",
    },
  },
}));
