import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useStyles from "./common/ProductsStyles";

import PieChart from "../../charts/PieChart";
import GlowCircle from "../../charts/GlowCircle";

import {
  dateFormatCommon,
  roundNumber,
  roundNumberWithoutDecimal,
} from "../../../components/utils/common";
import { divergingCommonProps, pie_onClick2 } from "../../charts/nivo_objects";

import {
  fetchTwrr,
  fetchTwrrCurrentHolding,
  fetchXirr,
  fetchXirrCurrentHolding,
  getDashboardAssetData,
  getRealisedData,
} from "../../../api";
import { OnboardingContext } from "../../../Context";
import { useContext } from "react";
import { mfoFilterContext } from "../../../mfo_context/Mfo_Context";
import moment from "moment";
import { useEffect } from "react";
import { currencyFormat } from "../../../hooks/constantFunction";

import PageLoader from "./common/pageLoader";

import { loadingText } from "../../utils/constands";

import SummaryPageTop from "./common/SummaryPageTop";

import { useHoldingCardActive } from "../../../hooks/useHoldingCardActive";

import { NewHoldingSummaryCards } from "../../common/HoldingSummaryCards/NewHoldingSummaryCards";

import PortfolioChart from "../common/portfolioChart";
import { NewHoldingSummaryCardsActive } from "../../common/HoldingSummaryCards/NewHoldingSummaryCardsActive";
import { summaryDetailsSecConstData } from "./utils/constands/summaryDetailsSec";
import { colorAndProd } from "./utils/constands/prodAndColor";
import { useReturns } from "../../../hooks/useReturns";
import SummaryDetailsSectionWrapper from "./newDesigns/common/SummaryDetailsSectionWrapper";
import { useNormalReturns } from "../../../hooks/useNormalReturns";
import { routes } from "../../../routes";
import { useTotalGainLoss } from "../../../hooks/useTotalGainLoss";
import { NewFilter } from "../newFilter/NewFilter";
import { ReactComponent as InfoIcon } from "../../../assets/icons/TotalGainLossInfoIcon.svg";
import { GainsCardInfo } from "../../common/HoldingSummaryCards/GainsCardInfo";
import { useCardsApi } from "../../../hooks/useCardsApi";
import { useCurrentHoldingCardsApi } from "../../../hooks/useCurrentHoldingCardsApi";
import { TotalGainLossCardInfo } from "../../common/HoldingSummaryCards/TotalGainLossCardInfo";

function HoldingSummaryComp({ setCustomOpenModal, customOpenModal }) {
  // console.log("abc", pageName);
  const [checked, setChecked] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [infoIconHovered, setInfoIconHovered] = useState(false);
  const {
    holdingCardData,
    totalGainLossData,
    xirr,
    twrr,
    loading: cardLoader,
    twrrLoader,
    twrrError,
    retryFetchTwrr,
  } = useCardsApi("dashboard");
  const {
    // holdingCardData,
    xirrCurrentHolding,
    twrrCurrentHolding,
    loading: currentHoldingCardLoader,
    twrrLoaderCurrentHolding,
    twrrErrorCurrentHolding,
    retryFetchTwrrCurrentHolding,
  } = useCurrentHoldingCardsApi(checked, "dashboard");
  // const [holdingCardData] = useHoldingCardActive("dashboard", checked);
  // const [totalGainLossData] = useTotalGainLoss("dashboard");
  // const { returns: xirrCurrentHolding } = useReturns(
  //   fetchXirrCurrentHolding,
  //   "xirr",
  //   checked,
  //   "dashboard"
  // );
  // const { returns: twrrCurrentHolding, loading: twrrCurrentHoldingLoading } =
  //   useReturns(fetchTwrrCurrentHolding, "twrr", checked, "dashboard");
  // const { returns: twrr } = useNormalReturns(
  //   fetchTwrr,
  //   "normal-twrr",
  //   checked,
  //   "dashboard"
  // );
  // console.log("twrrCurrentHoldingLoading", twrrCurrentHoldingLoading);
  // const { returns: xirr } = useNormalReturns(
  //   fetchXirr,
  //   "normal-xirr",
  //   checked,
  //   "dashboard"
  // );
  const classes = useStyles();
  const { setMfofilter, mfoFilter, getfilter_as_params } =
    useContext(mfoFilterContext);
  const { userState } = OnboardingContext();
  // const [twrr, setTwrr] = useState({});
  // const [twrrCurrentHolding, setTwrrCurrentHolding] = useState({});
  // const [xirr, setXirr] = useState({});
  // const [xirrCurrentHolding, setXirrCurrentHolding] = useState({});
  const [loadque, setloadque] = useState(true);
  const [loading, setLoading] = useState(true);
  const [assets, setAssets] = useState({});
  const [assetTotalData, setAssetTotalData] = useState({});
  const [flip, setFlip] = useState(false);
  const [dateRange, setDateRange] = useState([
    new Date(moment().subtract(1, "years")),
    new Date(moment()),
  ]);
  const [realisedData, setRealisedData] = useState({
    arr: [],
  });
  // const { state } = useLocation();
  const [assetData, setAssetData] = useState([]);

  const [asset_color, setAsset_Color] = useState(Object.values(colorAndProd));
  const [pie, setPie] = useState({
    title: "",
    value: "",
    colors: asset_color,
    disabled: [],
    fill: [
      {
        match: {
          id: "debt",
        },
        id: "c6",
      },
      {
        match: {
          id: "equity",
        },
        id: "c3",
      },
      {
        match: {
          id: "alternate",
        },
        id: "c2",
      },
      {
        match: {
          id: "cash",
        },
        id: "c4",
      },
    ],
  });

  const color = colorAndProd;

  // useEffect(() => {
  //   // if (state?.checkData) {
  //   // setMfofilter({ ...mfoFilter, checkData: { ...state?.checkData } });
  //   // } else {
  //   //   setMfofilter({ ...mfoFilter, checkData: { ...state?.checkData } });
  //   // }
  // }, [state]);

  // async function sequentialCall(clientId, params) {
  //   console.log(mfoFilter, "apicall");

  //   await fetchXirr(clientId, params, "dashboard")
  //     .then((xirr_res) => {
  //       setXirr(xirr_res.result);
  //     })
  //     .catch((err) => {});

  //   await fetchTwrr(clientId, params, "dashboard").then((twrr_res) => {
  //     if (twrr_res?.result?.annualisedTwrr) {
  //       twrr_res.result.annualisedTwrr = twrr_res?.result?.annualisedTwrr * 100;
  //     }
  //     setTwrr(twrr_res.result);
  //   });

  //   await getRealisedData(clientId, params, "dashboard").then(
  //     (realiseddata_res) => {
  //       let arr = [];
  //       setRealisedData({ ...realiseddata_res.result, arr });
  //     }
  //   );
  //   await getDashboardAssetData(clientId, params, "dashboard").then(
  //     (asset_ress) => {
  //       let arr = [];
  //       let asset = {};
  //       asset_ress.result.data.forEach((a) => {
  //         arr.push({
  //           id: `${a.assetClass}`,
  //           label: `${
  //             a.assetClass
  //               ? a?.assetClass.charAt(0).toUpperCase() + a?.assetClass.slice(1)
  //               : " "
  //           }`,
  //           value: parseFloat(a.percentageOfTotalPortfolio).toFixed(2),
  //           amount: parseFloat(a.portfolioValueAmount).toFixed(2),
  //         });
  //         asset = {
  //           ...asset,
  //           [a.assetClass]: {
  //             percent: Number(a.percentageOfTotalPortfolio).toFixed(2),
  //             amount: a.portfolioValueAmount,
  //             date: a.updatedAsOn,
  //           },
  //         };
  //       });
  //       console.log(arr);
  //       setAssetData(arr);
  //       setAssets(asset);
  //     }
  //   );
  //   setLoading(false);
  // }

  useEffect(() => {
    const clientId = userState.loginedUserData.id;
    // const clientId = 199;
    divergingCommonProps.axisLeft.legend = "Time Period";
    divergingCommonProps.axisBottom.legend = "Percentage Change";
    divergingCommonProps.axisBottom.legendOffset = 54;
    divergingCommonProps.margin.bottom = 65;
    let params = { ...getfilter_as_params() };
    // console.log(clientId, "afjkabshjvf");
    // if ((type = "dashboard")) {
    params["assetClass"] = "equity,debt,cash,alternate";
    params["promoterStake"] = mfoFilter.promoterStake;
    // }
    setLoading(true);
    // sequentialCall(clientId, params);
    Promise.allSettled([
      // fetchXirr(clientId, params, "dashboard"),
      // fetchXirrCurrentHolding(clientId, params, "dashboard"),
      // fetchTwrr(clientId, params, "dashboard"),
      // fetchTwrrCurrentHolding(clientId, params, "dashboard"),
      getRealisedData(clientId, params, "dashboard"),
      getDashboardAssetData(clientId, params, "dashboard"),
    ]).then(
      ([
        // xirr_res,
        // xirrCurrentHolding_res,
        // twrr_res,
        // twrrCurrentHolding_res,
        realiseddata_res,
        asset_ress,
      ]) => {
        // async function allres(){
        //   await xirr_res
        //   await twrr_res
        //   await realiseddata_res
        //   await asset_ress
        //   setLoading(false)
        // }
        // console.log(xirrCurrentHolding, "setXirr");
        // if (xirr_res.status == "fulfilled") {
        //   setXirr(xirr_res.value.result);
        // }
        // if (xirrCurrentHolding_res.status == "fulfilled") {
        //   setXirrCurrentHolding(xirrCurrentHolding_res.value.result);
        // }
        // if (twrr_res.status == "fulfilled") {
        //   if (twrr_res.value?.result?.annualisedTwrr) {
        //     twrr_res.value.result.annualisedTwrr =
        //       twrr_res.value?.result?.annualisedTwrr * 100;
        //   }
        //   setTwrr(twrr_res.value.result);
        // }
        // if (twrrCurrentHolding_res.status == "fulfilled") {
        //   if (twrrCurrentHolding_res.value?.result?.annualisedTwrr) {
        //     twrrCurrentHolding_res.value.result.annualisedTwrr =
        //       twrrCurrentHolding_res.value?.result?.annualisedTwrr * 100;
        //   }
        //   setTwrrCurrentHolding(twrrCurrentHolding_res.value.result);
        // }

        if (realiseddata_res.status == "fulfilled") {
          let arr = [];
          setRealisedData({ ...realiseddata_res.value.result, arr });
        }

        if (asset_ress.status == "fulfilled") {
          let arr = [];
          let asset = {};
          pie.colors = [];
          asset_ress.value.data.forEach((a) => {
            // if (selectedAssetClass.includes(a?.asset_class)) {
            // arr.push({
            //   id: `${a.asset_class}`,
            //   label: `${
            //     a.asset_class
            //       ? a.asset_class.charAt(0).toUpperCase() +
            //         a?.asset_class?.slice(1)
            //       : " "
            //   }  ${a.percentage}%`,
            //   value: Number(a.percentage).toFixed(2),
            //   amount: a.amount,
            //   // color: color[a.asset_class],
            // });
            // // colors.push(color[a.asset_class]);
            // // }

            // asset = {
            //   ...asset,
            //   [a.asset_class]: {
            //     percent: Number(a.percentage).toFixed(2),
            //     amount: a.amount,
            //     date: a.as_on_date,
            //   },
            // };
            pie.colors.push(colorAndProd[a.assetClass]);
            arr.push({
              id: `${a.assetClass}`,
              label: `${
                a.assetClass
                  ? a?.assetClass.charAt(0).toUpperCase() +
                    a?.assetClass.slice(1)
                  : " "
              }`,
              value: parseFloat(a.percentageOfTotalPortfolio).toFixed(2),
              amount: parseFloat(a.portfolioValueAmount).toFixed(2),
            });
            asset = {
              ...asset,
              [a.assetClass]: {
                percent: Number(a.percentageOfTotalPortfolio).toFixed(2),
                amount: a.portfolioValueAmount,
                date: a.updatedAsOn,
              },
            };
          });

          setAssetData(arr);
          setAssetTotalData({
            percent: Number(
              asset_ress.value?.percentageOfTotalPortfolio
            ).toFixed(2),
            amount: asset_ress.value?.totalPortfolioAmount,
            date: asset_ress.value?.lastUpdatedDate,
          });
          setAssets(asset);
        }
        setLoading(false);
      }
    );
  }, [mfoFilter]);

  const navigate = useNavigate();
  const handleSummaryClick = () => {
    navigate(routes?.homeHoldingPage);
  };

  const data = [
    {
      title: "Equity",
      amount: assets["equity"]
        ? currencyFormat(assets["equity"]?.amount)
        : currencyFormat(0),
      percent: assets["equity"]
        ? roundNumber(assets["equity"]?.percent) + "%"
        : "0.00%",
      dateStr: assets["equity"]?.date ? "Updated as on" : "",
      date: dateFormatCommon(assets["equity"]?.date),
      color: "bluebox",
      color2: "#BBB580",
      width: "240px",
      onClick: () => {
        navigate(routes?.homeHoldingPage, { state: { prodExpand: "equity" } });
      },
    },
    {
      title: "Debt",
      amount: assets["debt"]
        ? currencyFormat(assets["debt"]?.amount)
        : currencyFormat(0),
      percent: assets["debt"]
        ? roundNumber(assets["debt"]?.percent) + "%"
        : "0.00%",
      dateStr: assets["debt"]?.date ? "Updated as on" : "",
      date: dateFormatCommon(assets["debt"]?.date),
      // color: "greenbox",
      color: "orangebox",
      color2: "#907DD0",
      width: "240px",
      onClick: () => {
        navigate(routes?.homeHoldingPage, {
          state: { prodExpand: "debt" },
        });
      },
    },
    {
      title: "Alternates",
      amount: assets["alternate"]
        ? currencyFormat(assets["alternate"]?.amount)
        : currencyFormat(0),
      percent: assets["alternate"]
        ? roundNumber(assets["alternate"]?.percent) + "%"
        : "0.00%",
      dateStr: assets["alternate"]?.date ? "Updated as on" : "",
      date: dateFormatCommon(assets["alternate"]?.date),
      // color: "orangebox",
      color: "greenbox",
      color2: "#1FAAB2",
      width: "240px",
      onClick: () => {
        navigate(routes?.homeHoldingPage, {
          state: { prodExpand: "alternate" },
        });
      },
    },
    {
      title: "Cash",
      amount: assets["cash"]
        ? currencyFormat(assets["cash"]?.amount)
        : currencyFormat(0),
      percent: assets["cash"]
        ? roundNumber(assets["cash"]?.percent) + "%"
        : "0.00%",
      dateStr: assets["cash"]?.date ? "Updated as on" : "",
      date: dateFormatCommon(assets["cash"]?.date),
      color: "purplebox",
      color2: "#6761E6",
      width: "240px",
      onClick: () => {
        navigate(routes?.homeHoldingPage, {
          state: { prodExpand: "cash" },
        });
      },
    },
    {
      key: "total",
      title: "Total",
      amount: assetTotalData?.amount
        ? currencyFormat(assetTotalData?.amount)
        : currencyFormat(0),
      percent: assetTotalData?.percent
        ? roundNumber(assetTotalData?.percent) + "%"
        : "0.00%",
      dateStr: assetTotalData?.date ? "Updated as on" : "",
      date: dateFormatCommon(assetTotalData?.date),
      color: "purplebox",
      width: "240px",
      hideNavButtons: true,
    },
  ];

  const headerData1 = [
    {
      title: "ASSET CLASS",
      field: "title",
      align: "center",
      fontWeight: "900",
    },
    {
      title: "CURRENT VALUE",
      field: "amount",
      fontWeight: "800",
    },
    {
      title: "% ALLOCATION",
      field: "percent",
      fontWeight: "400",
    },
    {
      title: "UPDATED AS ON",
      field: "date",
      spclFont: "italic",
    },
  ];
  // past holding api data is --> realisedData
  // current holding api data is --> holdingCardData
  function holdingSummaryCardsInactiveData() {
    return {
      asOnDate:
        realisedData?.updatedAsOn != null
          ? holdingCardData?.updatedAsOn
          : loadingText,

      //    * current holding api values
      portfolioValueAmount:
        holdingCardData?.portfolioValueAmount != null
          ? currencyFormat(holdingCardData?.portfolioValueAmount)
          : loadingText,
      //   * gain-loss api values
      overallInvestedAmount:
        realisedData?.overAllInvestedAmount != null
          ? currencyFormat(realisedData?.overAllInvestedAmount)
          : loadingText,
      //    * current holding api values
      investedAmount:
        holdingCardData?.investedAmount != null
          ? currencyFormat(holdingCardData?.investedAmount)
          : loadingText,
      //    * current holding api values
      redeemedInvestedAmount:
        holdingCardData?.redeemedInvestedAmount != null
          ? currencyFormat(holdingCardData?.redeemedInvestedAmount)
          : loadingText,
      //   * total-gain-loss api value
      totalGainLoss:
        totalGainLossData?.totalGainLoss != null
          ? currencyFormat(totalGainLossData?.totalGainLoss)
          : loadingText,
      //   * gain-loss api values
      realisedGainLoss:
        realisedData?.realisedGainLoss != null
          ? currencyFormat(realisedData?.realisedGainLoss)
          : loadingText,
      //   * current holding api values
      unRealisedGainLoss:
        holdingCardData?.unRealisedGainLoss != null
          ? currencyFormat(holdingCardData?.unRealisedGainLoss)
          : loadingText,
      //   * gain-loss api values
      xirrValue:
        xirr?.xirrValue != null
          ? roundNumberWithoutDecimal(Number(xirr?.xirrValue)) + " %"
          : loadingText,
      //   * gain-loss api values
      absoluteTwrrData:
        twrr?.absoluteTwrr != null
          ? roundNumberWithoutDecimal(Number(twrr?.absoluteTwrr)) + " %"
          : loadingText,
      //   * gain-loss api values
      annualisedTwrrData:
        twrr?.annualisedTwrr != null
          ? roundNumberWithoutDecimal(Number(twrr?.annualisedTwrr)) + " %"
          : loadingText,
      //* gain-loss api values
      interestIncome:
        realisedData?.interestIncome != null
          ? currencyFormat(realisedData?.interestIncome)
          : loadingText,
    };
  }
  function holdingSummaryCardsActiveData() {
    return {
      asOnDate:
        holdingCardData?.updatedAsOn !== null
          ? holdingCardData?.updatedAsOn
          : loadingText,
      portfolioValueAmount:
        holdingCardData?.portfolioValueAmount !== null
          ? currencyFormat(holdingCardData?.portfolioValueAmount)
          : loadingText,
      investedAmount:
        holdingCardData?.investedAmount !== null
          ? currencyFormat(holdingCardData?.investedAmount)
          : loadingText,
      totalGainLoss:
        holdingCardData?.totalGainLoss !== null
          ? currencyFormat(holdingCardData?.totalGainLoss)
          : loadingText,
      realisedGainLoss:
        holdingCardData?.realisedGainLoss !== null
          ? currencyFormat(0)
          : loadingText,
      unRealisedGainLoss:
        holdingCardData?.unRealisedGainLoss !== null
          ? currencyFormat(holdingCardData?.unRealisedGainLoss)
          : loadingText,
      xirrValue:
        xirrCurrentHolding?.xirrValue !== null
          ? roundNumberWithoutDecimal(Number(xirrCurrentHolding?.xirrValue)) +
            " %"
          : loadingText,
      absoluteTwrrData:
        twrrCurrentHolding?.absoluteTwrr !== null
          ? roundNumberWithoutDecimal(
              Number(twrrCurrentHolding?.absoluteTwrr)
            ) + " %"
          : loadingText,
      annualisedTwrrData:
        twrrCurrentHolding?.annualisedTwrr !== null
          ? roundNumberWithoutDecimal(
              Number(twrrCurrentHolding?.annualisedTwrr)
            ) + " %"
          : loadingText,
      interestIncome:
        holdingCardData?.interestIncome != null
          ? currencyFormat(holdingCardData?.interestIncome)
          : loadingText,
    };
  }
  return (
    <>
      <SummaryPageTop
        backLink={"/home/member/detail"}
        pageTitle="Portfolio Summary"
        asOnDate={false}
        flip={flip}
        setFlip={setFlip}
        doNotShowPageFilter={false}
        customOpenModal={customOpenModal}
        setCustomOpenModal={setCustomOpenModal}
        setChecked={setChecked}
        checked={checked}
        setFilterModalOpen={setFilterModalOpen}
      />
      <h1 style={{ color: "#fff" }}>{loadque}</h1>

      <NewFilter
        filterModalOpen={filterModalOpen}
        setFilterModalOpen={setFilterModalOpen}
        productType="dashboard"
      />

      {/* <FlippyArray
        page={"pms"}
        portfolioData={
          realisedData.portfolioValueAmount != null
            ? currencyFormat(realisedData.portfolioValueAmount)
            : loadingText
        }
        portfolioDate={{
          asOnDate:
            realisedData.updatedAsOn != null
              ? realisedData.updatedAsOn
              : loadingText,
        }}
        investmentData={
          realisedData.investedAmount != null
            ? currencyFormat(realisedData.investedAmount)
            : loadingText
        }
        gainData={
          realisedData.totalGainLoss != null
            ? currencyFormat(realisedData.totalGainLoss)
            : loadingText
        }
        realisedGainData={
          realisedData.realisedGainLoss != null
            ? currencyFormat(realisedData.realisedGainLoss)
            : loadingText
        }
        unrealisedGainData={
          realisedData.unRealisedGainLoss != null
            ? currencyFormat(realisedData.unRealisedGainLoss)
            : loadingText
        }
        xirrData={
          xirr.xirrValue != null
            ? roundNumber(Number(xirr.xirrValue)) + " %"
            : loadingText
        }
        absoluteTwrrData={
          twrr.absoluteTwrr != null
            ? roundNumber(Number(twrr.absoluteTwrr)) + " %"
            : loadingText
        }
        annualisedTwrrData={
          twrr.annualisedTwrr != null
            ? roundNumber(Number(twrr.annualisedTwrr)) + " %"
            : loadingText
        }
        flip={flip}
      /> */}

      <div style={{ margin: "2%" }}>
        {!checked ? (
          // <HoldingSummaryCards
          // asOnDate={
          //   holdingCardData?.updatedAsOn != null
          //     ? holdingCardData?.updatedAsOn
          //     : loadingText
          // }
          // portfolioValueAmount={
          //   holdingCardData?.portfolioValueAmount != null
          //     ? currencyFormat(holdingCardData?.portfolioValueAmount)
          //     : loadingText
          // }
          // investedAmount={
          //   holdingCardData?.investedAmount != null
          //     ? currencyFormat(holdingCardData?.investedAmount)
          //     : loadingText
          // }
          // totalGainLoss={
          //   holdingCardData?.totalGainLoss != null
          //     ? currencyFormat(holdingCardData?.totalGainLoss)
          //     : loadingText
          // }
          // realisedGainLoss={
          //   holdingCardData?.realisedGainLoss != null
          //     ? currencyFormat(holdingCardData?.realisedGainLoss)
          //     : loadingText
          // }
          // unRealisedGainLoss={
          //   holdingCardData?.unRealisedGainLoss != null
          //     ? currencyFormat(holdingCardData?.unRealisedGainLoss)
          //     : loadingText
          // }
          // xirrValue={
          //   xirrCurrentHolding?.xirrValue != null
          //     ? roundNumberWithoutDecimal(
          //         Number(xirrCurrentHolding?.xirrValue)
          //       ) + " %"
          //     : loadingText
          // }
          // // TODO Just like xirr need to change this
          // absoluteTwrrData={
          //   twrrCurrentHolding?.absoluteTwrr != null
          //     ? roundNumberWithoutDecimal(
          //         Number(twrrCurrentHolding?.absoluteTwrr)
          //       ) + " %"
          //     : loadingText
          // }
          // // TODO Just like xirr need to change this
          // annualisedTwrrData={
          //   twrrCurrentHolding?.annualisedTwrr != null
          //     ? roundNumberWithoutDecimal(
          //         Number(twrrCurrentHolding?.annualisedTwrr)
          //       ) + " %"
          //     : loadingText
          // }
          // />

          <NewHoldingSummaryCards
            retryFetchTwrr={retryFetchTwrr}
            twrrLoader={twrrLoader}
            twrrError={twrrError}
            // infoIconHovered={infoIconHovered}
            // Check and Render the div from summary Component
            infoIcon={
              infoIconHovered && (
                <TotalGainLossCardInfo
                  textToRender={
                    <p className="totalIncomeDescription">
                      The field signifies the collective financial gains derived
                      from a spectrum of diverse investment instruments. This
                      amalgamation encapsulates dividends,interest payments,
                      rental income and other such income sources across a range
                      of assets. Essentially, it offers a holistic snapshot of
                      the income generated from your varied investment
                      portfolio, providing a comprehensive overview of your
                      financial returns from multiple sources.
                    </p>
                  }
                  styleTotalGainLossCardInfoContainer={{
                    marginBottom: "-5rem",
                  }}
                />
              )
            }
            holdingSummaryCardsInactive={holdingSummaryCardsInactiveData()}
            totalGainLossInactive={
              <>
                <GainsCardInfo
                  text="Realized Gain/Loss"
                  value={holdingSummaryCardsInactiveData()?.realisedGainLoss}
                  align="left"
                />
                <GainsCardInfo
                  text="Unrealized Gain/Loss"
                  value={holdingSummaryCardsInactiveData()?.unRealisedGainLoss}
                  align="center"
                />
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <GainsCardInfo
                    text="Other Income"
                    value={holdingSummaryCardsInactiveData()?.interestIncome}
                    align="right"
                    customStyle={{ marginRight: "2rem" }}
                  />
                  <InfoIcon
                    onMouseOver={() => setInfoIconHovered(true)}
                    onMouseOut={() => setInfoIconHovered(false)}
                    style={{
                      position: "absolute",
                      top: "4px",
                      right: "4px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </>
            }
          ></NewHoldingSummaryCards>
        ) : (
          // <HoldingSummaryCardsActive
          //   type="dashboard"
          //   asOnDate={
          //     holdingCardData?.updatedAsOn != null
          //       ? holdingCardData?.updatedAsOn
          //       : loadingText
          //   }
          //   // * current holding api values
          //   portfolioValueAmount={
          //     holdingCardData?.portfolioValueAmount != null
          //       ? currencyFormat(holdingCardData?.portfolioValueAmount)
          //       : loadingText
          //   }
          //   // * current holding api values
          //   redeemedInvestedAmount={
          //     holdingCardData?.redeemedInvestedAmount != null
          //       ? currencyFormat(holdingCardData?.redeemedInvestedAmount)
          //       : loadingText
          //   }
          //   // * current holding api values
          //   investedAmount={
          //     holdingCardData?.investedAmount != null
          //       ? currencyFormat(holdingCardData?.investedAmount)
          //       : loadingText
          //   }
          //   // * gain-loss api values
          //   overallInvestedAmount={
          //     realisedData?.overAllInvestedAmount != null
          //       ? currencyFormat(realisedData?.overAllInvestedAmount)
          //       : loadingText
          //   }
          //   // * gain-loss api values
          //   totalGainLoss={
          //     realisedData?.totalGainLoss != null
          //       ? currencyFormat(realisedData?.totalGainLoss)
          //       : loadingText
          //   }
          //   // * current holding api values
          //   unRealisedGainLoss={
          //     holdingCardData?.unRealisedGainLoss != null
          //       ? currencyFormat(holdingCardData?.unRealisedGainLoss)
          //       : loadingText
          //   }
          //   // * gain-loss api values
          //   realisedGainLoss={
          //     realisedData?.realisedGainLoss != null
          //       ? currencyFormat(realisedData?.realisedGainLoss)
          //       : loadingText
          //   }
          //   // * gain-loss api values
          //   xirrValue={
          //     xirr?.xirrValue != null
          //       ? roundNumberWithoutDecimal(Number(xirr?.xirrValue)) + " %"
          //       : loadingText
          //   }
          //   // * gain-loss api values
          //   absoluteTwrrData={
          //     twrr?.absoluteTwrr != null
          //       ? roundNumberWithoutDecimal(Number(twrr?.absoluteTwrr)) + " %"
          //       : loadingText
          //   }
          //   // * gain-loss api values
          //   annualisedTwrrData={
          //     twrr?.annualisedTwrr != null
          //       ? roundNumberWithoutDecimal(Number(twrr?.annualisedTwrr)) + " %"
          //       : loadingText
          //   }
          // />
          <NewHoldingSummaryCardsActive
            retryFetchTwrrCurrentHolding={retryFetchTwrrCurrentHolding}
            twrrLoaderCurrentHolding={twrrLoaderCurrentHolding}
            twrrErrorCurrentHolding={twrrErrorCurrentHolding}
            infoIconHovered={infoIconHovered}
            holdingSummaryCardsActive={holdingSummaryCardsActiveData()}
            totalGainLossActive={
              <>
                <GainsCardInfo
                  text="Unrealized Gain/Loss"
                  value={holdingSummaryCardsActiveData()?.unRealisedGainLoss}
                  align="left"
                />
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <GainsCardInfo
                    text="Other Income"
                    value={holdingSummaryCardsActiveData()?.interestIncome}
                    align="right"
                    customStyle={{ marginRight: "2rem" }}
                  />
                  <InfoIcon
                    onMouseEnter={() => setInfoIconHovered((prev) => !prev)}
                    onMouseLeave={() => setInfoIconHovered((prev) => !prev)}
                    style={{
                      position: "absolute",
                      top: "4px",
                      right: "4px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </>
            }
          ></NewHoldingSummaryCardsActive>
        )}
      </div>
      {/* displaying mid section cards and pie chart */}
      <SummaryDetailsSectionWrapper
        summaryDetailsSecConstData={summaryDetailsSecConstData}
        cardData1={data}
        peiChart={
          <div className="flex_center tr20" style={{ height: "100%" }}>
            <div className={classes.wid380px}>
              <PieChart
                colors={pie.colors}
                // fill={pie.fill}
                config={{
                  innerRadius: "0.80",
                  cornerRadius: 0,
                  padAngle: 0,
                  legends: false,
                  disableArcLink: true,
                  minWith: "380px",
                  chart: {
                    width: 440,
                    height: 420,
                    arcLinkLabelsStraightLength: 15,
                    arcLinkLabelsDiagonalLength: 20,
                  },

                  arcLinkLabel: (d) => (
                    <tspan
                      x="0"
                      y="0"
                      style={{
                        fontFamily: "Avenir",
                        fontWeight: "400",
                        fontSize: "12",
                        textTransform: "capitalize",
                      }}
                    >
                      {" "}
                      {d.id}
                      <tspan
                        x="0"
                        y="18"
                        style={{
                          fontFamily: "Open Sans",
                          fontWeight: "700",
                          fontSize: "14",
                        }}
                      >
                        {" "}
                        {d.value}%
                      </tspan>
                    </tspan>
                  ),
                }}
                onClickEvent={{
                  click: pie_onClick2,
                  set: setPie,
                  cstate: pie,
                  ncolors: asset_color,
                }}
                data={assetData}
              >
                <GlowCircle>
                  <div className="circle_content capitalize">
                    <div>
                      <b>{pie.title ? pie.title : "Portfolio value"}</b>
                      <br></br>
                      <b>
                        {pie.amount
                          ? currencyFormat(pie.amount)
                          : assetTotalData.amount
                          ? currencyFormat(assetTotalData.amount)
                          : "__"}
                      </b>
                      <div
                        className="circle_text2"
                        style={{ paddingTop: "5px" }}
                      >
                        {pie?.value ? roundNumber(pie?.value) + "%" : ""}
                      </div>
                    </div>
                  </div>
                </GlowCircle>
              </PieChart>
            </div>
          </div>
        }
        handleClick={() => {
          navigate(routes?.homeHoldingPage);
        }}
      />

      <PortfolioChart type={"dashboard"} exp="portfolio-performance-graph" />

      <PageLoader showLoader={loading ? true : false} />
      <PageLoader
        showLoader={currentHoldingCardLoader && checked ? true : false}
      />
    </>
  );
}

export default HoldingSummaryComp;
