import React from "react";
import { ReactComponent as LeftBackground } from "../../../assets/icons/left_background.svg";

export default function Container_wrapper({
  children,
  close,
  containerstyle,
  hideclose,
}) {
  return (
    <div
      style={{
        display: "flex",
        background: "#000000",
        borderRadius: "20px",
        paddingLeft: "104px",
        maxHeight: "675px",
        ...containerstyle,
      }}
    >
      <div style={{ width: "100%" }}>{children}</div>
      <div style={{ position: "relative", left: "7px" }}>
        {" "}
        <div
          style={{
            position: "absolute",
            top: hideclose ? "15px" : "10px",
            left: hideclose ? "17px" : "16px",
            background: hideclose ? "#691ba6" : "",
          }}
        >
          <button
            className="invisibleClose"
            onClick={() => {
              close(false);
            }}
          >
            {" "}
          </button>
        </div>
        <LeftBackground style={{ height: "100%" }} />
      </div>
    </div>
  );
}
