import React, { useEffect, useState } from "react";
import PageContainer from "../PageContainer";
import Header from "../Components/Header";
import PrintTable from "../Components/Table";
import ApexNew_print from "../Components/ApexNew_print";
import chartData from "../../../charts/chartData";
import { ReactComponent as Question } from "../images/question.svg";
import PieChart from "../../../charts/PieChart";
import Print_PieChart from "../Components/Print_PieChart";
import TabCards from "../Components/TabCards";
import { roundNumber } from "../../../utils/common";
import { date } from "yup/lib/locale";
import { currencyFormat } from "../../../../hooks/constantFunction";
import { AssetSummaryModel, MemberModel } from "../Components/utils/staticData";
import {
  objectFormatingWithClientMemberWiseTitleCOl,
  objectFormatingWithTitleCOlWrapper,
} from "../Components/utils/common";
import { isArray } from "lodash";
import { Link } from "react-router-dom";

export default function AssetClassSummaryPdf({
  data,
  denomination,
  pdfInfo,
  title,
  member,
}) {
  const getDenomination = localStorage.getItem("denomination");

  const colors = ["#4B64ED", "#BDC5F2"];
  const [tableData, setTabledata] = useState([]);

  const [data1, setData1] = useState([]);
  const breadcrumbs = [
    <Link
      key="1"
      color="#000000"
      className="printBreadsel fontFamilyAvBold f16"
    >
      {title}
    </Link>,
  ];
  useEffect(() => {
    if (!isArray(data) || data == "error") {
      return;
    }

    // console.log(data, "error");
    setTabledata(
      objectFormatingWithTitleCOlWrapper(
        {
          data: data || [],
        },
        AssetSummaryModel,
        [],
        "#ADC371",
        undefined,
        // totaRowData ? totaRowData : hideTotalRow ? undefined : total,
        undefined,
        {},
        denomination,
        "assetClass",
        "AssetClassSummary",
        objectFormatingWithClientMemberWiseTitleCOl
      )
    );
  }, [data]);
  if (!isArray(data) || data == "error") {
    return;
  }
  return (
    <div>
      <PageContainer
        layout_t={1}
        pageNo={2}
        bgColor={"white"}
        pdfInfo={pdfInfo}
      >
        <Header
          title={<></>}
          type={13}
          title2={member || null}
          subtext={`Engagement Model segregates the Client portfolio into Held & Held Away. Where in Held refers to the Investments Directly managed by us & Held Away refers to the investments managed by other Advisors. `}
          width={"5in"}
          breadcrumbs={breadcrumbs}
        ></Header>
        <div className="p_flex" style={{ alignItems: "center" }}>
          <div style={{ height: "590px", width: "100%" }}>
            <div
              style={{
                position: "absolute",
                zIndex: "5",
                width: "90%",
                top: "2in",

                marginRight: "66px",
              }}
            >
              <div className="newPrint pagebreakTable">
                <PrintTable
                  // customRef={pRef2}
                  customTableClass={"printTableEvenAndOddRow"}
                  hideTitle={true}
                  data={tableData || []}
                  header={AssetSummaryModel}
                  hideUnderText={true}
                  customPadding={"2.2px 16px"}
                  firstCellCircleProps={{
                    height: "8px",
                    width: "4.5px",
                  }}
                  titleCellProps={{
                    firstCellStyle: { padding: "10px 0px 10px 0px" },
                    style: {
                      background: "#F3F8FD",
                      padding: "0px 16px 0px 9px",
                      minHeight: "47px",
                    },
                    font: {
                      fontWeight: "900",
                      fontSize: "16px",
                    },
                  }}
                  normalCellProps={{
                    firstCellPadding: "10px 0px 10px 0px",
                    fontStyle: { fontWeight: "600", fontSize: "14px" },
                  }}
                  headerProps={{
                    className: "whiteHeaderCell",
                    titleClassName: "whiteTitle",
                    titleStyle: {
                      fontWeight: "500",
                      fontFamily: "AvenirBold",
                      fontSize: "14px",
                      lineHeight: "18px",
                      letterSpacing: "0.06em",
                      textTransform: "uppercase",
                      color: "#000000",
                    },
                  }}
                  colSpan={1}
                />
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    </div>
  );
}
