import React, { useState, useEffect, useMemo, useContext } from "react";
import TabBondSub from "./TabBondSub";
import PageLoader from "../common/pageLoader";
import { Link, Grid, Typography, Modal, Tooltip } from "@material-ui/core";
import useStyles from "../common/ProductsStyles";
import PieChart from "../../../charts/PieChart";
import DataNotFound from "../common/DataNotFound";
import { pie_onClick, pie_onClick2 } from "../../../charts/nivo_objects";
import TabComponent from "../../products/common/TabComponent";
import { fetchTypeOfBonds } from "../../../../api";
import { OnboardingContext } from "../../../../Context";
import { firstLetterCapitalize } from "../../../utils/strHelper";
import { roundNumber } from "../../../utils/common";
import { currencyFormat } from "../../../../hooks/constantFunction";
import GlowCircle from "../../../charts/GlowCircle";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import moment from "moment";
import NewTabComp from "../common/NewTabComp";

export default function BondsTypes() {
  const { userState } = OnboardingContext();
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const classes = useStyles();
  const [types, setTypes] = useState("Consolidated");
  const [pieData, setPieData] = useState([]);
  const [page, setPage] = useState("consolidated");
  const [load, setload] = useState(true);
  const [portfolio, setPortfolio] = useState(null);
  const [toggle, settoggle] = useState(true);
  const [piecolors, setPieColors] = useState([
    "#FF9446",
    "#82EBA1",
    "#509FF1",
    "#711CF1",
    "#F52D6A",
    "#B80000",
  ]);

  const [pieState, setPieState] = useState({
    title: "",
    value: "",
    amount: null,
    colors: piecolors,
    disabled: [],
    hide: false,
    fill: [],
  });

  const tabs = [
    { page: "consolidated", title: "Consolidated" },
    { page: "corporate bonds", title: "Corporate Bonds" },
    { page: "zero coupon bonds", title: "Zero Coupon Bonds" },
    { page: "government bonds", title: "Government Bonds" },
    { page: "money market bonds", title: "Money Market Bonds" },

    // { page: "g-sec", title: "G-Sec" },
    // { page: "t-bill", title: "T-Bill" },
    // { page: "sdl", title: "SDL" },
    // { page: "corporate bonds", title: "Corporate Bonds" },
    // { page: "certificate of deposit", title: "Certificate Of Deposit" },
    // { page: "commercial paper", title: "Commercial Paper" },
    // { page: "reits", title: "REITS" },
    // { page: "invits", title: "INVITS" },
    // { page: "mld", title: "MLD" },
  ];
  useEffect(() => {
    let params = { ...getfilter_as_params() };

    const clientId = userState.loginedUserData.id;
    setload(true);
    fetchTypeOfBonds(
      clientId,
      { productSubCategory: page, ...params },
      "bonds",
      toggle
    )
      .then((res) => {
        let arr = [];
        let piegradient = [];
        setPortfolio(res.result.totalPortfolioValue);
        res.result?.data?.forEach((a, i) => {
          let name = firstLetterCapitalize(a.name);
          let percent = roundNumber(Number(a.percentageOfTotalPortfolio));
          piegradient.push({
            id: "c" + (i + 1),
            match: {
              id: name,
            },
          });

          arr.push({
            id: name,
            label: `${name ? name : " "}  ${percent}%`,
            value: percent,
            amount: a.portfolioValue,
          });
        });
        setPieData(arr);

        setPieState({ ...pieState, fill: piegradient });
        setload(false);
      })
      .catch((err) => {
        setPieData([]);
        setload(false);
      });
  }, [page, mfoFilter, toggle]);

  const handleClick = React.useCallback(
    (page) => {
      setPage(page);
    },
    [page]
  );

  return (
    <div>
      <TabBondSub
        title="Types of Bonds"
        toggle={toggle}
        // settoggle={settoggle}
      ></TabBondSub>
      <div style={{ marginInline: "2%" }}>
        <NewTabComp
          data={tabs}
          customWidth={"fit-content"}
          customHandle={handleClick}
        />
      </div>
      {/* <TabComponent data={tabs} type={"state"} setPage={setPage} page={page} /> */}
      {/* <div style={{ width: "70vw", margin: "2% 2%", }} >
     
      <div className={classes.bottomSubTabHeader} style={{padding:"10px"}}>
      
          <Link onClick={() => {
            setTypes("Consolidated")
          }} style={{ textDecoration: 'none' }} to='#'><div className={`${classes.bottomSubTabHeaderText} ${types === "Consolidated" ? classes.bottomTabActive : ""}`}>Consolidated</div></Link>
          <Link onClick={() => {
            setTypes("Corporate Bonds")
          }} style={{ textDecoration: 'none' }} to='#'><div className={`${classes.bottomSubTabHeaderText} ${types === "Corporate Bonds" ? classes.bottomTabActive : ""}`}>Corporate Bonds</div></Link>
          <Link onClick={() => {
            setTypes("Municipal Bonds")
          }} style={{ textDecoration: 'none' }} to='#'><div className={`${classes.bottomSubTabHeaderText} ${types === "Municipal Bonds" ? classes.bottomTabActive : ""}`}>Municipal Bonds</div></Link>
          <Link onClick={() => {
            setTypes("Mortage Backed Bonds")
          }} style={{ textDecoration: 'none' }} to='#'><div className={`${classes.bottomSubTabHeaderText} ${types === "Mortage Backed Bonds" ? classes.bottomTabActive : ""}`}>Mortage Backed Bonds</div></Link>
          <Link onClick={() => {
            setTypes("Government Bonds")
          }} style={{ textDecoration: 'none' }} to='#'><div className={`${classes.bottomSubTabHeaderText} ${types === "Government Bonds" ? classes.bottomTabActive : ""}`}>Government Bonds</div></Link>
          <Link style={{ textDecoration: 'none', display: "none" }} to='#'><div className={`${classes.bottomSubTabHeaderText} ${classes.bottomTabActive}`}></div></Link>
        </div>
      </div> */}
      <br />
      <br />
      <div>
        {pieData.length !== 0 ? (
          <div className="flex_center tr20 " style={{ overflow: "hidden" }}>
            <div style={{ width: "700px" }}>
              <PieChart
                fill={pieState.fill}
                config={{
                  innerRadius: "0.80",
                  cornerRadius: 0,
                  padAngle: 0,
                  disableArcLink: true,
                  legends: false,
                  minWith: "380px",
                  margin: { right: 100, top: 20, left: 100 },
                  ldirection: "row",
                  chart: { width: 700, height: 400 },
                  arcLinkLabel: (d) => (
                    <tspan
                      x="0"
                      y="0"
                      style={{
                        fontFamily: "Avenir",
                        fontWeight: "800",
                        fontSize: "14",
                        textTransform: "capitalize",
                      }}
                    >
                      {" "}
                      {d.id.length > 34 ? d.id.substring(0, 31) + "..." : d.id}
                      <tspan
                        x="0"
                        y="18"
                        style={{
                          fontFamily: "Open Sans",
                          fontWeight: "700",
                          fontSize: "14",
                        }}
                      >
                        {" "}
                        {d.value}%
                      </tspan>
                    </tspan>
                  ),
                  // disableArcLink:pieState.hide?true:false
                }}
                colors={pieState.colors}
                onClickEvent={{
                  click: pie_onClick2,
                  set: setPieState,
                  cstate: pieState,
                  ncolors: piecolors,
                }}
                data={pieData}
              >
                <div
                  className="cen"
                  style={{
                    color: "#fff",
                    transform: " translate(94%, -47%)",
                    width: "250px",
                    top: "38%",
                  }}
                >
                  <GlowCircle
                    center_info={{ transform: "translate(-50.5%, -39.5%)" }}
                    circle_border={{ maxWidth: "230px", height: "230px" }}
                    glow_circle={{ width: "212px", height: "207px" }}
                  >
                    <div className="circle_content">
                      <div>
                        <b className="circle_text">
                          {pieState.title && pieState.amount != null
                            ? pieState.title
                            : "Portfolio value"}
                        </b>
                        <br></br>
                        <b className="circle_text2">
                          {pieState.amount != null
                            ? currencyFormat(pieState.amount)
                            : portfolio != null
                            ? currencyFormat(portfolio)
                            : ""}
                        </b>
                        <div className="circle_text2 pt5">
                          {pieState?.value
                            ? roundNumber(pieState.value) + "%"
                            : ""}
                        </div>
                      </div>
                    </div>
                  </GlowCircle>
                </div>
              </PieChart>
            </div>
          </div>
        ) : (
          !load && <DataNotFound></DataNotFound>
        )}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            marginTop: "-2%",
            marginBottom: "3%",
            marginRight: "3%",
          }}
        >
          {pieData?.map((m, i) => {
            return (
              <div style={{ display: "flex" }}>
                <div className={classes.graphBulletBonds}>
                  <svg height="25" width="25">
                    <circle
                      cx="12"
                      cy="12"
                      r="5"
                      stroke-width="3"
                      fill={pieState.colors[i]}
                    />
                    Sorry, your browser does not support inline SVG.
                  </svg>
                </div>
                <div className={`${classes.graphLegends}`}>{m.id} </div>
              </div>
            );
          })}
        </div>
      </div>
      <PageLoader showLoader={load}></PageLoader>
    </div>
  );
}
