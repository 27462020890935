import React from "react";
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Link,
  Typography,
  useTheme,
} from "@material-ui/core";
import useStyles from "../clientDashboard/Clientdashstyle";
import { ReactComponent as PageBack } from "../../../assets/holding-summary/back-blue.svg";
import { ReactComponent as CalenderDate } from "../../../assets/icons/calenderdate.svg";
import { ReactComponent as Marketuparrow } from "../../../assets/clientdashboard/marketuparrow.svg";
import { ReactComponent as Crownhead } from "../../../assets/clientdashboard/crown.svg";
import { ReactComponent as Rightangle } from "../../../assets/icons/borderednext.svg";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Back } from "../../../assets/holding-summary/back-blue.svg";
import SearchHeader from "../../verificationUpload/SearchHeader";
import TabDashboard from "./TabDashboard";
import ClientConsolidated from "./ClientConsolidated";
import ClientIndividual from "./ClientIndividual";
import { useState } from "react";
import { useAllMembersConsolidated } from "../../../hooks/useAllMembersConsolidated";
import { Calendar } from "react-calendar";
import moment from "moment";
import Loader from "../../loader/Loader";
import TabComponent from "../products/common/TabComponent";
import { dateFormatCommon, roundNumber } from "../../utils/common";
import PageLoader from "../products/common/pageLoader";
import SearchInput from "../products/common/SearchInput";
import DataNotFound from "../products/common/DataNotFound";
import { hardCodedRes } from "./utils/constands";
import { FamilyTreeButton } from "./FamilyTreeButton";
import { useRef } from "react";
import { useEffect } from "react";
import NewTabComp from "../products/common/NewTabComp";
import { routes } from "../../../routes";
import FullWidthInput from "../../common/FullWidthInput";
import { IndividualSwitch } from "./IndividualSwitch";
import { currencyFormat } from "../../../hooks/constantFunction";
import { ClientMemberHeaderDetails } from "./ClientMemberHeaderDetails";

const data = [
  { page: "consolidated", title: "Consolidated" },
  { page: "individual", title: "Individual" },
];

function ClientMemder() {
  const [page, setPage] = useState("consolidated");
  const theme = useTheme();
  const BackIcon = theme.palette.icons.backIcon;
  // 👇 For Toggle Individual and Consolidated
  const [customCheck, setCustomCheck] = useState(false);
  const [scripName, setScripName] = useState(null);
  const [calendarVisible, setCalendarVisible] = useState(false);
  let currentDate = new Date()?.toISOString()?.slice(0, 10);
  const date = moment(currentDate).format("DD-MMM-YYYY");
  const formattedDate = date;
  const [endDate, setEndDate] = React.useState(formattedDate);
  const [headOfFamily, loading] = useAllMembersConsolidated(
    customCheck ? "consolidated" : "individual",
    endDate,
    scripName
  );
  const navigate = useNavigate();
  const classes = useStyles();
  const handleClick = () => {
    navigate(routes?.homeHoldingPage);
  };

  const handleCalendarChange = (date) => {
    let dateFormat = moment(date, "ddd MMM DD YYYY HH:mm:ss ZZ").format(
      // "YYYY-MM-DD"
      "DD MMM YYYY"
    );
    setEndDate(dateFormat);
    // Close the calendar as soon as date is selected
    setCalendarVisible(false);
  };

  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setCalendarVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  // const changePage = (p) => {
  //   switch (p) {
  //     case "consolidated":
  //       return (
  //         <>
  //           {headOfFamily?.length === 0 ? (
  //             <DataNotFound />
  //           ) : (
  //             <ClientConsolidated
  //               headOfFamily={headOfFamily}
  //               endDate={endDate}
  //             ></ClientConsolidated>
  //           )}
  //         </>
  //       );

  //     case "individual":
  //       return (
  //         <>
  //           {headOfFamily?.length === 0 ? (
  //             <DataNotFound />
  //           ) : (
  //             <ClientConsolidated
  //               headOfFamily={headOfFamily}
  //             ></ClientConsolidated>
  //           )}
  //         </>
  //       );

  //     default:
  //       return (
  //         <>
  //           {headOfFamily?.length === 0 ? (
  //             <DataNotFound />
  //           ) : (
  //             <ClientConsolidated
  //               headOfFamily={headOfFamily}
  //             ></ClientConsolidated>
  //           )}
  //         </>
  //       );
  //   }
  // };

  const handleClick2 = React.useCallback(
    (page) => {
      setPage(page);
    },
    [page]
  );

  // current date
  //   const today = new Date();
  //   const currentDate =
  //     today.getFullYear() +
  //     "-" +
  //     (today.getMonth() + 1).toString().padStart(2, "0") +
  //     "-" +
  //     today.getDate().toString().padStart(2, "0");

  return (
    <>
      <div
        style={{ margin: "0 auto", maxWidth: "90%", marginTop: "1.7rem" }}
        className={`${classes.alignTopHeader}`}
      >
        <BackIcon
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/home/");
          }}
        />
        {/* <Typography
          className={`${classes.mainHeading} ${classes.mainHeadingAlign}`}
        >
          All Members
        </Typography> */}
        <p className={classes.portfolioline} style={{ marginLeft: "auto" }}>
          Portfolio Value as on: &nbsp;
          <strong className={classes.colorfff}>
            {/* {dateFormatCommon(endDate, "DD-MM-YYYY") || dateFormatCommon(currentDate, "DD-MM-YYYY")} */}
            {endDate || formattedDate}
          </strong>{" "}
          &nbsp;
          <span></span>
        </p>

        <div className={classes.topNavContainer}>
          {/* <button className={"button23"} onClick={handleFamilyTreeNav}>
            <span className="button23Container">
              <FamilyIcon /> Family Tree Mapping{" "}
            </span>
          </button> */}
          <div
            className={"calenderContainer"}
            style={{
              background: theme.palette.filterCalendar.background,
              border: `1px solid ${theme.palette.filterCalendar.border}`,
            }}
          >
            <CalenderDate
              onClick={() => setCalendarVisible((prev) => !prev)}
              style={{ position: "relative", cursor: "pointer" }}
              className={classes.pagebackarrow}
              width="20px"
            ></CalenderDate>
          </div>
          <FamilyTreeButton />
          {calendarVisible && (
            <div
              style={{
                position: "absolute",
                right: "50px",
                top: "40px",
                zIndex: "99",
              }}
              ref={ref}
            >
              <Calendar
                onChange={handleCalendarChange}
                selectRange={false}
                // value={endDate}
                value={endDate ? new Date(endDate) : null}
                maxDate={new Date(moment().format("YYYY-MM-DD"))}
              />
            </div>
          )}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0 auto",
          marginTop: "1rem",
        }}
      >
        <FullWidthInput
          setSearch={setScripName}
          customWrapperStyle={{ width: "90%", margin: "0 auto" }}
        />

        {/* <div style={{ marginRight: "2%" }}>
          <NewTabComp
            data={data}
            customWidth={"fit-content"}
            customHandle={handleClick2}
          />
        </div> */}
      </div>

      {/* 👇 DUMB COMPONENT ONLY RESPONSIBLE FOR DISPLAYING DATA */}
      <ClientMemberHeaderDetails headOfFamily={headOfFamily} />

      {/* {changePage(page)} */}
      <ClientConsolidated
        headOfFamily={headOfFamily?.data}
        endDate={endDate}
        loading={loading}
        customCheck={customCheck}
        individualSwitchComponent={
          <IndividualSwitch
            toggleSwitch={() => setCustomCheck((prev) => !prev)}
            isOn={customCheck}
          />
        }
      />
      <PageLoader showLoader={loading} />
    </>
  );
}

export default ClientMemder;
