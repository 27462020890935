import React, { useEffect, useState } from "react";
import {
  OtherUserHeaderData,
  bodyData3,
  clientHeaderData,
  headerData,
  hierarchyHeader,
} from "./utils/constands";
import "./userManagemantStyles.css";
import UserManagementTableWrapper from "./UserManagementTableWrapper";
import { UserManagementDetailsHeader } from "./UserManagementDetailsHeader";
import ClientAddMember from "./client_flow/ClientAddMember";
import { useNavigate, useLocation } from "react-router-dom";
import AddUserFlow from "./AddUserFlow/AddUserFlow";
import AddMemberFlow from "./client_flow/AddMemberFlow";
import { useFetchOthersOrMembers } from "../../hooks/useFetchOthersOrMembers";
import NewTabComp from "../dashboard/products/common/NewTabComp";
import { Encrypt } from "../dashboard/print/Components/utils/common";
import AddButton from "../common/AddButton";
import AddClientUserFlow from "./addClientUserFLow/AddClientUserFlow";
import { OnboardingContext } from "../../Context";
import { fetchMembersWiseHierarchy, updatepartneruser } from "../../api";
import AddClientMemberFlow from "./client_flow/UsermanagmentClient/AddClientMemberFlow";
import { useMemo } from "react";
import { ITEMS_LIMIT_FILES_PARTNER_LIST } from "../../Constants";
import ls from "localstorage-slim";
import { PermissionsGate } from "../auth/PermissionGate";

function UserManagementComp() {
  const [openModal, setOpenModal] = useState(false);
  const [openModalUser, setOpenModalUser] = useState(false);
  const [openModalMember, setOpenModalMember] = useState(false);
  const [members, setMembers] = useState([]);
  const [header, setHeader] = useState([]);
  const naivgate = useNavigate();
  const { state } = useLocation();
  const { userState } = OnboardingContext();
  const [totalCount, setTotalCount] = useState({});
  const [search, setSearchTerm] = useState("");
  const [offset, setItemOffset] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [total, setTotal] = useState(0);
  const [load, setLoad] = useState(false);
  const scopes = JSON.parse(ls.get("scopes"));

  // console.log(state, "agalgjj");
  const [memberorothers, setUsertype, setParams, userType, isloading, param] =
    useFetchOthersOrMembers(
      "member",
      { limit: ITEMS_LIMIT_FILES_PARTNER_LIST },
      state?.data?.client_id || state?.data?.id,
      true,
      setTotal
    );
  const totalPage = useMemo(
    () => Math.ceil(total / ITEMS_LIMIT_FILES_PARTNER_LIST),
    [total]
  );
  console.log(userState, "logged user");
  // fetchMembersWiseHierarchy()
  useEffect(() => {
    // console.log(userState.loginedUserData, userState.loginedUserData.mid);
    if (userType != "member") {
      // console.log(offset, "offset");
      if ((search && param?.search != search) || param?.offset != offset) {
        setParams({
          search: search || "",
          limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
          offset: offset,
        });
      }

      return;
    }
    let params = {
      search: search || "",
      limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
      offset: offset,
    };
    setLoad(true);
    fetchMembersWiseHierarchy(
      userState.loginedUserData.id,
      // 197,
      userState.loginedUserData.mid,
      params
      // 50
    )
      .then((res) => {
        setLoad(false);
        console.log(
          res.data.data.totalMembersMapped,

          "tabledata"
        );

        // console.log(res.data.dataresult.hierarchy, "hirarchy");
        let headerinfo = {
          name: userState.loginedUserData.name,
          role: userState.loginedUserData.client_role,
          id: userState.loginedUserData.id,
        };
        // res.data.data.result.hierarchy.find((value, i) => {
        //   if (value.member_role == "head_of_family") {
        //     headerinfo.member_name = value.member_name;
        //     // res.data.data.result.hierarchy.splice(i, 1);
        //     return true;
        //   }
        // });
        setMembers(res.data.data.result.hierarchy);
        setTotalCount({
          mapped: res.data.data.result.totalMembersMapped,
          ...headerinfo,
        });
        setTotal(res?.data?.data?.meta?.count);
        // setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
      });
  }, [userType, search, offset, refresh]);

  function handleSave(values, id) {
    if (id) {
      updatepartneruser("clientUser", id, values)
        .then((res) => {})
        .catch((err) => {});
      return;
    }
  }
  const handleHeader = (headerData) => {
    headerData?.map((val) => {
      if (val?.field == "is_active" || val?.title === "Status") {
        val.onClick = (data) => {
          // data["client_id"] = 197;
          // data["member_id"] = 42;
          // console.log(data, "aflajfn");
          if (window?.location.pathname.includes("client")) {
            naivgate("/client/user-management/edit" + `${Encrypt(data)}`, {
              state: { data: data },
            });
          } else {
            naivgate("/user-management/edit/" + `${Encrypt(data)}`, {
              state: { data: data },
            });
          }
        };

        val.disabled = (data) => {
          if (data?.member_user_id === null || data?.member_user_id) {
            if (data?.member_user_id === null) return true;
            else return false;
          }

          // console.log(res, data, "agnlkgj");

          if (data?.client_user_id === null || data?.client_user_id) {
            if (data?.client_user_id === null) return true;
            else return false;
          }
        };

        val.handleTogle = (data) => {
          console.log("toggled", data);
          let data1 = {
            status: "" + data.toglge == "true" ? 1 : 0,
          };
          if (data?.rowData?.member_role_id) {
            data1["roleId"] = "" + data.rowData.member_role_id;
          }
          if (data?.rowData?.role_id) {
            data1["roleId"] = "" + data?.rowData?.role_id;
          }

          handleSave(
            data1,
            data?.rowData?.member_user_id || data?.rowData?.client_user_id
          );
        };
      }
    });
    setHeader(() => headerData);
  };

  const data1 = [
    {
      page: "mapped_users",
      title: "MAPPED USERS",
    },
    {
      page: "other_user",
      title: "Other User",
    },
  ];

  useEffect(() => {
    handleHeader(userType == "member" ? hierarchyHeader : OtherUserHeaderData);
  }, [userType]);

  const hadleOpenAddUser = () => {
    setOpenModalUser(true);
  };

  // console.log(scopes?.can_add_client_member, "agnlkagn");

  return (
    <>
      <UserManagementDetailsHeader
        data={state?.data || totalCount}
        componentToRender={
          state?.headerType ? state?.headerType : "userHeaderInfo"
        }
        customHandleBack={() => {
          if (window?.location.pathname.includes("client")) {
            naivgate("/client");
          }
        }}
      />
      {header?.length > 0 && (
        <UserManagementTableWrapper
          customScopes={[scopes?.can_add_client_member]}
          returnno={true}
          loading={isloading || load}
          setSearchTerm={setSearchTerm}
          setItemOffset={setItemOffset}
          totalPage={totalPage}
          customTitle={"Family Members"}
          headerData={header}
          addBtnTitle={"Add Client Member"}
          addRepComp={
            <>
              <PermissionsGate scopes={[scopes?.can_add_client_member]}>
                <AddButton
                  addBtnTitle={"Add Client User"}
                  handleIconClick={hadleOpenAddUser}
                />
              </PermissionsGate>
            </>
          }
          data={userType == "member" ? members : memberorothers}
          openModal={openModal}
          setOpenModal={setOpenModal}
          customheader={
            state?.headerType == "userManagementDetailsHeader"
              ? undefined
              : () => (
                  <NewTabComp
                    data={data1}
                    customWidth={"fit-content"}
                    disableArrow={true}
                    // customSelect={data.findIndex((val) => val?.page == pageName)}
                    customHandle={(e) => {
                      console.log("clicked", e);
                      if (e == "mapped_users") {
                        setUsertype("member");
                        setItemOffset(0);
                        handleHeader(hierarchyHeader);
                      } else {
                        setUsertype("users");
                        setItemOffset(0);
                        handleHeader(OtherUserHeaderData);
                      }
                    }}
                  />
                )
          }
        />
      )}
      <AddClientMemberFlow
        data={state?.data}
        openModal={openModal}
        setOpenModal={setOpenModal}
        handleRefresh={() => {
          setRefresh(!refresh);
        }}
      />
      <AddClientUserFlow
        data={{ client_id: userState.loginedUserData.id }}
        openModal={openModalUser}
        setOpenModal={setOpenModalUser}
      />
      {/* <AddUserFlow
        // handleClose={handleAddUserClose}
        // selectedData={selectedUserData}
        openModal={openModal}
        setOpenModal={setOpenModal}
      /> */}
    </>
    // <>
    //   <Typography
    //     style={{
    //       cursor: "pointer",
    //       marginTop: "3%",
    //       marginLeft: "5%",
    //       width: " 70vw",
    //     }}
    //     className={classes.titleText}
    //   >
    //     Holding Summary
    //   </Typography>

    //   <div style={{ display: "flex", justifyContent: "space-between" }}>
    //     <div style={{ marginLeft: "5%", width: "35vw" }}>
    //       <SearchInput></SearchInput>
    //     </div>
    //     <div
    //       style={{
    //         display: "flex",
    //         justifyContent: "space-evenly",
    //         width: "35vw",
    //       }}
    //     >
    //       <FormControl
    //         variant="outlined"
    //         className={`${classes.formControl} ${classes.inputField}`}
    //       >
    //         <InputLabel id="demo-simple-select-outlined-label">
    //           Select Roles
    //         </InputLabel>
    //         <Select
    //           native
    //           style={{ color: "#FAF9FF" }}
    //           labelId="demo-simple-select-outlined-label"
    //           label="Select Roles"
    //           inputProps={{
    //             classes: {
    //               icon: classes.icon,
    //             },
    //           }}
    //         >
    //           <option className={classes.optiondesign}>1</option>
    //           <option className={classes.optiondesign}>2</option>
    //           <option className={classes.optiondesign}>3</option>
    //         </Select>
    //       </FormControl>
    //       <PermissionsGate scopes={[scopes.can_add_user_management_member]}>
    //         <Button
    //           onClick={handleIconClick}
    //           style={{
    //             display: "flex",
    //             justifyContent: "space-evenly",
    //             width: "10vw",
    //           }}
    //           className={classes.button}
    //         >
    //           <Add></Add>
    //           <Typography className={classes.buttonTypo}>Add Member</Typography>
    //         </Button>
    //       </PermissionsGate>
    //     </div>
    //   </div>

    //   <div
    //     style={{
    //       minHeight: "300px",
    //       overflowY: "hidden",
    //       width: "90%",
    //       margin: "2% 5%",
    //     }}
    //   >
    //     <TableContainer>
    //       <Table
    //         style={{ borderCollapse: "separate" }}
    //         aria-label="simple table"
    //       >
    //         <TableHead
    //           style={{ background: "#1D1B36", position: "sticky", top: 0 }}
    //         >
    //           <TableRow>
    //             <TableCell
    //               style={{
    //                 borderTopLeftRadius: "20px",
    //                 borderBottomLeftRadius: "20px",
    //                 borderBottom: "none",
    //               }}
    //               className={classes.tableHeaderTextNew}
    //             >
    //               Name
    //             </TableCell>
    //             <TableCell
    //               style={{ borderBottom: "none" }}
    //               className={classes.tableHeaderTextNew}
    //             >
    //               Role
    //             </TableCell>
    //             <TableCell
    //               style={{ borderBottom: "none" }}
    //               className={classes.tableHeaderTextNew}
    //             >
    //               Permission
    //             </TableCell>
    //             <TableCell
    //               style={{
    //                 borderTopRightRadius: "20px",
    //                 borderBottomRightRadius: "20px",
    //                 borderBottom: "none",
    //               }}
    //               className={classes.tableHeaderTextNew}
    //             >
    //               Last Active
    //             </TableCell>
    //           </TableRow>
    //         </TableHead>
    //         <TableBody>
    //           <TableRow>
    //             <TableCell
    //               className={`${classes.rowTextNew} ${classes.paddingLeft}`}
    //               style={{ borderBottom: "1px solid #28235C" }}
    //               align="left"
    //             >
    //               1
    //             </TableCell>
    //             <TableCell
    //               className={classes.rowTextNew}
    //               style={{ borderBottom: "1px solid #28235C" }}
    //               align="left"
    //             >
    //               1
    //             </TableCell>
    //             <TableCell
    //               className={classes.rowTextNew}
    //               style={{ borderBottom: "1px solid #28235C" }}
    //               align="left"
    //             >
    //               1
    //             </TableCell>
    //             <TableCell
    //               className={classes.rowTextNew}
    //               style={{ borderBottom: "1px solid #28235C" }}
    //               align="left"
    //             >
    //               1
    //             </TableCell>
    //           </TableRow>
    //           <TableRow>
    //             <TableCell
    //               className={`${classes.rowTextNew} ${classes.paddingLeft}`}
    //               style={{ borderBottom: "1px solid #28235C" }}
    //               align="left"
    //             >
    //               1
    //             </TableCell>
    //             <TableCell
    //               className={classes.rowTextNew}
    //               style={{ borderBottom: "1px solid #28235C" }}
    //               align="left"
    //             >
    //               1
    //             </TableCell>
    //             <TableCell
    //               className={classes.rowTextNew}
    //               style={{ borderBottom: "1px solid #28235C" }}
    //               align="left"
    //             >
    //               1
    //             </TableCell>
    //             <TableCell
    //               className={classes.rowTextNew}
    //               style={{ borderBottom: "1px solid #28235C" }}
    //               align="left"
    //             >
    //               1
    //             </TableCell>
    //           </TableRow>
    //           <TableRow>
    //             <TableCell
    //               className={`${classes.rowTextNew} ${classes.paddingLeft}`}
    //               style={{ borderBottom: "1px solid #28235C" }}
    //               align="left"
    //             >
    //               1
    //             </TableCell>
    //             <TableCell
    //               className={classes.rowTextNew}
    //               style={{ borderBottom: "1px solid #28235C" }}
    //               align="left"
    //             >
    //               1
    //             </TableCell>
    //             <TableCell
    //               className={classes.rowTextNew}
    //               style={{ borderBottom: "1px solid #28235C" }}
    //               align="left"
    //             >
    //               1
    //             </TableCell>
    //             <TableCell
    //               className={classes.rowTextNew}
    //               style={{ borderBottom: "1px solid #28235C" }}
    //               align="left"
    //             >
    //               1
    //             </TableCell>
    //           </TableRow>
    //           <TableRow>
    //             <TableCell
    //               className={`${classes.rowTextNew} ${classes.paddingLeft}`}
    //               style={{ borderBottom: "1px solid #28235C" }}
    //               align="left"
    //             >
    //               1
    //             </TableCell>
    //             <TableCell
    //               className={classes.rowTextNew}
    //               style={{ borderBottom: "1px solid #28235C" }}
    //               align="left"
    //             >
    //               1
    //             </TableCell>
    //             <TableCell
    //               className={classes.rowTextNew}
    //               style={{ borderBottom: "1px solid #28235C" }}
    //               align="left"
    //             >
    //               1
    //             </TableCell>
    //             <TableCell
    //               className={classes.rowTextNew}
    //               style={{ borderBottom: "1px solid #28235C" }}
    //               align="left"
    //             >
    //               1
    //             </TableCell>
    //           </TableRow>
    //         </TableBody>
    //       </Table>
    //     </TableContainer>
    //   </div>

    //   <div
    //     style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}
    //   >
    //     <Link
    //       to="/user-management/family-structure"
    //       style={{ textDecoration: "none" }}
    //       className={classes.familyLinkTypo}
    //     >
    //       View Family Structure{" "}
    //       <div style={{ marginTop: "4px", marginLeft: "10px" }}>
    //         <RightArrow />{" "}
    //       </div>{" "}
    //     </Link>
    //   </div>

    //   <Modal
    //     open={openModal}
    //     BackdropProps={{
    //       classes: {
    //         root: classes.backDrop,
    //       },
    //     }}
    //     aria-labelledby="modal-modal-title"
    //     aria-describedby="modal-modal-description"
    //   >
    //     <div className={`${classes.modalContainer} form-container`}>
    //       <div style={{ marginBottom: "5%" }}>
    //         <Typography
    //           style={{ textAlign: "center" }}
    //           className={classes.titleText}
    //         >
    //           User Role & Access
    //         </Typography>

    //         <Cross
    //           onClick={handleButtonClick}
    //           style={{ float: "right", marginTop: "-6%", cursor: "pointer" }}
    //         />
    //       </div>
    //       <hr className={classes.modalHr}></hr>
    //       {/* <FormControl> */}
    //       <RadioGroup
    //         row
    //         aria-labelledby="demo-row-radio-buttons-group-label"
    //         name="row-radio-buttons-group"
    //         style={{ display: "flex", justifyContent: "space-evenly" }}
    //       >
    //         <FormControlLabel
    //           className={classes.inputRadio}
    //           value="all_access"
    //           control={<Radio />}
    //           label="All Access"
    //         />
    //         <FormControlLabel
    //           className={classes.inputRadio}
    //           value="restriced_user"
    //           control={<Radio />}
    //           label="Restricted User"
    //         />
    //       </RadioGroup>
    //       {/* </FormControl> */}

    //       <div
    //         style={{
    //           minHeight: "300px",
    //           overflowY: "hidden",
    //           width: "90%",
    //           margin: "2% 5%",
    //         }}
    //       >
    //         <TableContainer>
    //           <Table
    //             style={{ borderCollapse: "separate" }}
    //             aria-label="simple table"
    //           >
    //             <TableHead
    //               style={{ background: "#1D1B36", position: "sticky", top: 0 }}
    //             >
    //               <TableRow>
    //                 <TableCell
    //                   style={{
    //                     borderTopLeftRadius: "20px",
    //                     borderBottomLeftRadius: "20px",
    //                     borderBottom: "none",
    //                     paddingLeft: "45px",
    //                   }}
    //                   className={classes.tableHeaderTextNew}
    //                 >
    //                   Name
    //                 </TableCell>
    //                 <TableCell
    //                   style={{
    //                     borderTopRightRadius: "20px",
    //                     borderBottomRightRadius: "20px",
    //                     borderBottom: "none",
    //                     textAlign: "right",
    //                     paddingRight: "45px",
    //                   }}
    //                   className={classes.tableHeaderTextNew}
    //                   textAlign="right"
    //                 >
    //                   Grant Access
    //                 </TableCell>
    //               </TableRow>
    //             </TableHead>
    //             <TableBody>
    //               <TableRow>
    //                 <TableCell
    //                   className={`${classes.rowTextNew} ${classes.paddingLeft}`}
    //                   style={{
    //                     borderBottom: "1px solid #28235C",
    //                     paddingLeft: "45px",
    //                   }}
    //                 >
    //                   1
    //                 </TableCell>
    //                 <TableCell
    //                   className={classes.rowTextNew}
    //                   style={{
    //                     borderBottom: "1px solid #28235C",
    //                     textAlign: "right",
    //                     paddingRight: "75px",
    //                   }}
    //                 >
    //                   1
    //                 </TableCell>
    //               </TableRow>
    //             </TableBody>
    //           </Table>
    //         </TableContainer>
    //       </div>

    //       <div className={classes.buttonContainer}>
    //         <Button
    //           variant="contained"
    //           className={classes.buttonlined}
    //           style={{ width: "40%", marginTop: "5%" }}
    //           onClick={handleButtonClick}
    //         >
    //           <span className={`${classes.buttonTypo}`}>Cancel</span>
    //         </Button>
    //         <Button
    //           variant="contained"
    //           className={classes.button}
    //           style={{ width: "40%", marginTop: "5%" }}
    //         >
    //           <span className={`${classes.buttonTypo}`}>Save</span>
    //         </Button>
    //       </div>
    //     </div>
    //   </Modal>
    // </>
  );
}

export default UserManagementComp;
