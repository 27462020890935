import React, { useState } from "react";
import "./cashFlowChart.css";
import { Typography } from "@material-ui/core";
import moment from "moment";
import { currencyFormat } from "../../hooks/constantFunction";
import { dateFormatCommon } from "../utils/common";
import DataNotFound from "../dashboard/products/common/DataNotFound";
import { useEffect } from "react";
import { ReactComponent as TodayLine } from "../../assets/icons/todayLine.svg";

function CashflowChart({ data, loading }) {
  const [newData, setNewData] = useState([]);
  // let today = new Date("2024/02/10");
  let today = new Date();

  const handleToday = (data) => {
    setNewData(() =>
      data.map((val, i) => {
        if (
          moment(moment(today).subtract("hours", 4)).isBetween(
            moment(val.when, "DD-MM-YYYY").format("YYYY-MM-DD"),
            moment(data[i + 1]?.when, "DD-MM-YYYY")
          )
        ) {
          // console.log(val, "fiidfif");
          val.today = true;
          val.todayPos = moment(moment(today).subtract("hours", 4)).diff(
            moment(val.when, "DD-MM-YYYY"),
            "days"
          );
        }

        if (moment(val.when, "DD-MM-YYYY").isBefore(today)) {
          val.before = true;
        }

        return val;
      })
    );
  };

  console.log(newData, "fjabkj");

  useEffect(() => {
    handleToday(data);
  }, [data]);

  return (
    <div className="outerWrapperCashFLow">
      {data?.length > 0 && <p className="chartFutureTxt">Future Cash Flow</p>}
      <div className="scrollContainer">
        {data?.length > 0 && (
          <div className="scrollWrapper">
            {newData?.length > 0 &&
              newData.map((item, i) => {
                return (
                  <div className="items">
                    <p className={"timelineYear"}>
                      {dateFormatCommon(item?.when)?.split(" ")[2]}
                    </p>
                    <div className="lineWrapper">
                      <div className="lineContainer">
                        <div className="circle"></div>

                        {item?.today ? (
                          <>
                            <div
                              className="timeLine"
                              style={{
                                height: "2px",
                                width: item?.todayPos + "%",
                              }}
                            ></div>

                            <div
                              className="timeLine"
                              style={{
                                height: "5px",
                                width: 100 - item?.todayPos + "%",
                              }}
                            ></div>
                          </>
                        ) : (
                          <div
                            className="timeLine"
                            style={{
                              height:
                                item?.before && !item?.today ? " 2px" : "5px",
                              // marginTop:
                              //   item?.before && !item?.today ? "1px" : "0px",
                            }}
                          ></div>
                        )}

                        {/* <div className="todatLine"> */}
                        {item?.today && (
                          <div
                            className="todatLine"
                            style={{
                              left: item?.todayPos + "%",
                            }}
                          >
                            <p className="todayTxt">Today</p>
                            <TodayLine />
                          </div>
                        )}
                        {/* </div> */}
                      </div>
                    </div>
                    <p className="timelineDate">
                      {dateFormatCommon(item?.when)?.split(" ")[0] +
                        " " +
                        dateFormatCommon(item?.when)?.split(" ")[1]}
                    </p>
                    <Typography
                      className="investmentIndicator"
                      style={{
                        // color: i == 0 ? "#7b61ff" : "#F7CB59",
                        color: "#F7CB59",
                      }}
                    >
                      {i == data?.length - 1 ? "Amount + Interest" : "Interest"}
                    </Typography>
                    <p className="investedValue">
                      {currencyFormat(item?.amount)}
                    </p>
                  </div>
                );
              })}

            {/* {new Array(10).fill(1).map((val, i) => {
          return (
            <div className="items">
              <p className={"timelineYear"}>2022</p>
              <div className="lineContainer">
                <div className="circle"></div>
                <div className="timeLine"></div>
              </div>
              <p className="timelineDate">23 Sep</p>
              <Typography
                className="investmentIndicator"
                style={{ color: i == 0 ? "#7b61ff" : "#F7CB59" }}
              >
                {i == 0 ? "Your Investment Of" : "Interest"}
              </Typography>
              <p className="investedValue">₹1,03,03,386.30</p>
            </div>
          );
        })} */}
          </div>
        )}
        {data?.length == 0 && (
          <div className="noDataFontContainer">
            <DataNotFound text={loading ? "Loading..." : undefined} />
          </div>
        )}
      </div>
    </div>
  );
}

export default CashflowChart;
