import React, { useState } from "react";
import Default from "../dashboard/defaultPages/Default";
import { Grid, Typography, Box } from "@material-ui/core";
import { DisplayErrorCards } from "./DisplayErrorCards";
import { ErrorBlock } from "./ErrorBlock";
import { ValidationDisplayTable } from "./ValidationDisplayTable";
import { ConfirmationModal } from "./ConfirmationModal";
import { useLocation } from "react-router-dom";
import {
  formatString,
  isOnlyDuplicateRecordsNonEmpty,
} from "./uploadValidationUtils";

export const ProductUploadValidationDetails = () => {
  const [showErrorBlock, setShowErrorBlock] = useState({
    showAll: true,
    1: true,
    2: true,
  });
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [tableData, setTableData] = useState(null);
  const location = useLocation();
  const {
    state: {
      data: {
        file_name,
        upload_date,
        no_of_errors,
        no_of_clients,
        isBulkUpload,
        error_list,
      },
    },
  } = location;
  console.log("table data is as follows", location?.state?.data?.error_list);
  return (
    <Default>
      <Grid container alignItems="center" className="centerContainer">
        <Grid item xs={3}>
          <Typography className="headerTxt">File Name</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className="headerTxt">Upload Date</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className="headerTxt">No. Of Errors</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className="headerTxt">Upload Type</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className="headerTxt">No. Of Clients</Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="center" className="mainContainer">
        <Grid item xs={3}>
          <Typography className="gridItemTxt">
            {formatString(file_name)}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className="gridItemTxt">{upload_date}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className="gridItemTxt">{no_of_errors}</Typography>
        </Grid>
        <Grid item xs={2}>
          <p className="gridItemTxt">{isBulkUpload ? "Bulk" : "Data"}</p>
        </Grid>
        <Grid item xs={2}>
          <p className="gridItemTxt">{no_of_clients}</p>
        </Grid>
      </Grid>
      <ConfirmationModal
        openConfirmationModal={openConfirmationModal}
        setOpenConfirmationModal={setOpenConfirmationModal}
      />
      {/* Error Block */}
      {(showErrorBlock.showAll || showErrorBlock["1"]) && (
        <ErrorBlock
          setShowErrorBlock={(val) => {
            console.log("click");

            setShowErrorBlock({
              ...showErrorBlock,
              showAll: false,
              1: false,
            });
          }}
        />
      )}

      {error_list &&
        error_list?.valuation_transaction_error?.length > 0 &&
        (showErrorBlock.showAll || showErrorBlock["2"]) && (
          <ErrorBlock
            text={
              "Please ensure the Transaction is uploaded before proceeding with the Valuation upload"
            }
            setShowErrorBlock={(val) => {
              setShowErrorBlock({
                ...showErrorBlock,
                showAll: false,
                2: false,
              });
            }}
          />
        )}
      {/* ERROR CARDS */}
      <DisplayErrorCards setTableData={setTableData} />
      {/* Table */}
      <Box className="centerContainer">
        <ValidationDisplayTable tableData={tableData} />
      </Box>
      {/* BUTTON */}
      <Box
        className="centerContainer"
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{ gap: "1rem" }}
      >
        {/* Show the override and proceed button only when there is single error (that is duplicates) */}
        {isOnlyDuplicateRecordsNonEmpty(location?.state?.data?.error_list) && (
          <button
            onClick={() => setOpenConfirmationModal(true)}
            className="btn-override"
          >
            Override & Proceed
          </button>
        )}
      </Box>
    </Default>
  );
};
