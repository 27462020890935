import React, { useContext, useEffect } from "react";
import SummaryPageTop from "../common/SummaryPageTop";
import { NewFilter } from "../../newFilter/NewFilter";
import { NewHoldingSummaryCards } from "../../../common/HoldingSummaryCards/NewHoldingSummaryCards";
import { NewHoldingSummaryCardsActive } from "../../../common/HoldingSummaryCards/NewHoldingSummaryCardsActive";
import PortfolioChart from "../../common/portfolioChart";
import { Button } from "@material-ui/core";
import BarChartIcon from "@material-ui/icons/BarChart";
import PageLoader from "../common/pageLoader";
import { useState } from "react";
import useStyles from "../common/ProductsStyles";
import {
  fetchTwrr,
  fetchTwrrCurrentHolding,
  fetchXirr,
  fetchXirrCurrentHolding,
  invested_portfolio,
} from "../../../../api";
import { useNavigate } from "react-router-dom";
import { OnboardingContext } from "../../../../Context";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import { useNormalReturns } from "../../../../hooks/useNormalReturns";
import { useReturns } from "../../../../hooks/useReturns";
import { useTotalGainLoss } from "../../../../hooks/useTotalGainLoss";
import { useHoldingCardActive } from "../../../../hooks/useHoldingCardActive";
import {
  divergingCommonProps,
  pie_onClick2,
} from "../../../charts/nivo_objects";
import { routes } from "../../../../routes";
import { BankBookCard } from "../../../common/HoldingSummaryCards/BankBookCard";
import { useBankSummary } from "../../../../hooks/useBankSummary";
import SummaryDetailsSectionWrapper from "../newDesigns/common/SummaryDetailsSectionWrapper";
import { summaryDetailsTableConfigBank } from "../utils/constands/summaryDetailsSec";
import {
  colorAndProdBank,
  prodAndKeyBank,
} from "../utils/constands/prodAndColor";
import PieChart from "../../../charts/PieChart";
import GlowCircle from "../../../charts/GlowCircle";
import { roundNumber } from "../../../utils/common";
import { currencyFormat } from "../../../../hooks/constantFunction";

function BankBookSummary() {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [pie, setPie] = useState({
    title: "",
    value: "",
    colors: Object.values(colorAndProdBank),
    disabled: [],
    fill: [
      {
        match: {
          id: "Commerical",
        },
        id: "c9",
      },
      {
        match: {
          id: "Residential",
        },
        id: "c10",
      },
    ],
  });
  const [assetTotalData, setAssetTotalData] = useState({});
  const { bankSummary, summaryDetailData, color } = useBankSummary({
    setPie,
    setAssetTotalData,
  });
  console.log("ramdata", assetTotalData);
  // const { returns: twrrCurrentHolding, loading: twrrCurrentHoldingLoading } =
  //   useReturns(fetchTwrrCurrentHolding, "twrr", checked, "invits");
  // const { returns: twrr } = useNormalReturns(
  //   fetchTwrr,
  //   "normal-twrr",
  //   checked,
  //   "invits"
  // );
  // const { returns: xirr } = useNormalReturns(
  //   fetchXirr,
  //   "normal-xirr",
  //   checked,
  //   "invits"
  // );
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  let selectedAssetClass = getfilter_as_params().assetClass;
  console.log("getfilter_as_params", selectedAssetClass);
  const { userState } = OnboardingContext();
  const [investmentPortfolio, setInvestmentPortfolio] = useState([]);
  const [loading, setLoading] = useState(true);
  const [flip, setFlip] = useState(false);

  //   const [asset_color, setAsset_Color] = useState(Object.values(colorAndProd));
  const diverging = {
    ...divergingCommonProps,
  };
  diverging.axisBottom.legendOffset = 65;
  diverging.margin = { top: 5, right: 20, bottom: 70, left: 80 };
  useEffect(() => {
    const clientId = userState.loginedUserData.id;
    // const clientId = 199;

    let params = { ...getfilter_as_params() };

    setLoading(true);
    Promise.allSettled([invested_portfolio(clientId, params, "invits")]).then(
      ([invest_res]) => {
        if (invest_res.status == "fulfilled") {
          let arr = [];
          setInvestmentPortfolio(invest_res.value.result);
        }
        setLoading(false);
      }
    );
  }, [mfoFilter]);

  const navigate = useNavigate();
  const handleSummaryClick = () => {
    navigate(routes?.bankBookAllocation);
  };

  return (
    <>
      <SummaryPageTop
        backLink={routes?.homeHoldingPage}
        pageTitle={"Bank"}
        flip={flip}
        setFlip={setFlip}
        checked={checked}
        setChecked={setChecked}
        setFilterModalOpen={setFilterModalOpen}
        hideRightSec={true}
      />
      <NewFilter
        filterModalOpen={filterModalOpen}
        setFilterModalOpen={setFilterModalOpen}
        productType="bank"
      />
      <div style={{ margin: "2%" }}>
        <BankBookCard
          bankSummary={bankSummary}
          assetTotalData={assetTotalData}
        />
      </div>
      <SummaryDetailsSectionWrapper
        summaryDetailsSecConstData={summaryDetailsTableConfigBank}
        cardData1={summaryDetailData}
        hideNavButtons={true}
        customColorAndProd={colorAndProdBank}
        customProdAndKey={prodAndKeyBank}
        peiChart={
          <div className="flex_center tr20" style={{ height: "100%" }}>
            <div className={classes.wid380px}>
              <PieChart
                colors={pie.colors}
                fill={pie.fill}
                config={{
                  innerRadius: "0.80",
                  cornerRadius: 0,
                  padAngle: 0,
                  legends: false,
                  minWith: "380px",
                  disableArcLink: true,
                  cornerRadius: 0,
                  chart: {
                    width: 440,
                    height: 420,
                    arcLinkLabelsStraightLength: 20,
                    arcLinkLabelsDiagonalLength: 20,
                  },

                  arcLinkLabel: (d) => (
                    <tspan
                      x="0"
                      y="0"
                      style={{
                        fontFamily: "Avenir",
                        fontWeight: "400",
                        fontSize: "12",
                        textTransform: "capitalize",
                      }}
                    >
                      {" "}
                      {d.id}
                      <tspan
                        x="0"
                        y="18"
                        style={{
                          fontFamily: "Open Sans",
                          fontWeight: "700",
                          fontSize: "14",
                        }}
                      >
                        {" "}
                        {d.value}%
                      </tspan>
                    </tspan>
                  ),
                }}
                onClickEvent={{
                  click: pie_onClick2,
                  set: setPie,
                  cstate: pie,
                  ncolors: color,
                }}
                data={bankSummary?.map((val) => ({
                  id:
                    val?.account_type?.charAt(0).toUpperCase() +
                    val?.account_type?.slice(1),
                  label: val?.account_type,
                  value: Number(val?.allocation_percentage).toFixed(2),
                  amount: val?.balance,
                  color: val?.account_type == "savings" ? "#E96114" : "#509FF1",
                }))}
              >
                <GlowCircle>
                  <div className="circle_content">
                    <div>
                      <b className="circle_text">
                        {pie.title && pie.value != null
                          ? pie.title
                          : "Total balance"}
                      </b>
                      <br></br>
                      <b className="circle_text2">
                        {pie.amount != null
                          ? currencyFormat(pie.amount)
                          : assetTotalData.total != null
                          ? currencyFormat(assetTotalData.total)
                          : ""}
                      </b>
                      <div
                        className="circle_text2"
                        style={{ paddingTop: "5px" }}
                      >
                        {pie?.value ? roundNumber(pie?.value) + "%" : ""}
                      </div>
                    </div>
                  </div>
                </GlowCircle>
              </PieChart>
            </div>
          </div>
        }
      />
      <PortfolioChart
        type={"bank"}
        exp={"balance-over-a-period"}
        config={{ colors: ["#509FF1"] }}
        loading={loading}
        noPaddingBottom={true}
        viewDetailBtn={
          <Button
            onClick={handleSummaryClick}
            className={classes.viewDetailBtn + " portfolioBtn "}
            style={{
              width: "20%",
              margin: "0% 40% 0",
              color: "#FFFFFF",
              borderRadius: "7px",
              backgroundColor: "#4f1fda !important",
            }}
            startIcon={<BarChartIcon />}
          >
            View Details
          </Button>
        }
      ></PortfolioChart>
      <PageLoader showLoader={loading ? true : false} />
    </>
  );
}

export default BankBookSummary;
