import { Button } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import useStyles from "../common/ProductsStyles";

import {
  getRealisedData,
  getInvestmentTypeData,
  fetchTwrr,
  fetchTwrrCurrentHolding,
  fetchXirr,
  fetchXirrCurrentHolding,
} from "../../../../api";

import { divergingCommonProps } from "../../../charts/nivo_objects";

import { OnboardingContext } from "../../../../Context";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";

import { currencyFormat } from "../../../../hooks/constantFunction";
import { roundNumberWithoutDecimal } from "../../../utils/common";
import { loadingText } from "../../../utils/constands";
import PageLoader from "../common/pageLoader";

import SummaryPageTop from "../common/SummaryPageTop";
import PortfolioChart from "../../common/portfolioChart";

import { useHoldingCardActive } from "../../../../hooks/useHoldingCardActive";
import { NewHoldingSummaryCards } from "../../../common/HoldingSummaryCards/NewHoldingSummaryCards";
import { GainsCardInfo } from "../../../common/HoldingSummaryCards/GainsCardInfo";
import { NewHoldingSummaryCardsActive } from "../../../common/HoldingSummaryCards/NewHoldingSummaryCardsActive";
import { useReturns } from "../../../../hooks/useReturns";
import { useNormalReturns } from "../../../../hooks/useNormalReturns";
import BarChartIcon from "@material-ui/icons/BarChart";
import { routes } from "../../../../routes";
import { useTotalGainLoss } from "../../../../hooks/useTotalGainLoss";
import { NewFilter } from "../../newFilter/NewFilter";
import { ReactComponent as InfoIcon } from "../../../../assets/icons/TotalGainLossInfoIcon.svg";
import { useCardsApi } from "../../../../hooks/useCardsApi";
import { useCurrentHoldingCardsApi } from "../../../../hooks/useCurrentHoldingCardsApi";
import { SHOW_ADDITIONAL_DATA_TAB } from "../../../../Config";
function BondsComp() {
  const classes = useStyles();
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [infoIconHovered, setInfoIconHovered] = useState(false);
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(false);
  // const [holdingCardData] = useHoldingCardActive("bonds", checked);
  // const [totalGainLossData] = useTotalGainLoss("bonds");
  // const { returns: twrr } = useNormalReturns(
  //   fetchTwrr,
  //   "normal-twrr",
  //   checked,
  //   "bonds"
  // );
  // const { returns: xirr } = useNormalReturns(
  //   fetchXirr,
  //   "normal-xirr",
  //   checked,
  //   "bonds"
  // );
  const {
    holdingCardData,
    totalGainLossData,
    xirr,
    twrr,
    loading: cardLoader,
    twrrLoader,
    twrrError,
    retryFetchTwrr,
  } = useCardsApi("bonds");
  // const { returns: xirrCurrentHolding } = useReturns(
  //   fetchXirrCurrentHolding,
  //   "xirr",
  //   checked,
  //   "bonds"
  // );
  // const { returns: twrrCurrentHolding, loading: twrrCurrentHoldingLoading } =
  //   useReturns(fetchTwrrCurrentHolding, "twrr", checked, "bonds");
  const {
    // holdingCardData,
    xirrCurrentHolding,
    twrrCurrentHolding,
    loading: currentHoldingCardLoader,
    twrrLoaderCurrentHolding,
    twrrErrorCurrentHolding,
    retryFetchTwrrCurrentHolding,
  } = useCurrentHoldingCardsApi(checked, "bonds");

  const [flip, setFlip] = useState(false);
  const [pie, setPie] = useState({
    text: "Portfolio value",
    value: "3000",
  });
  function pie_onclick(data, setData) {
    setData({
      text: data["id"],
      value: data["value"],
    });
  }
  const [loadque, setloadque] = useState(null);
  // const [twrr, setTwrr] = useState({});
  // const [twrrCurrentHolding, setTwrrCurrentHolding] = useState({});
  // const [xirr, setXirr] = useState({});
  // const [xirrCurrentHolding, setXirrCurrentHolding] = useState({});
  const [portfolioTrend, setPortfolioTrend] = useState([]);

  const [minMax, setMinMax] = useState([0, 5]);

  const [openModal, setOpenModal] = useState(false);
  const [investmentTypeData, setInvestmentTypeData] = useState([]);
  const [realisedData, setRealisedData] = useState({
    arr: [],
  });
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const { userState } = OnboardingContext();
  divergingCommonProps.axisLeft.legend = "Time Period";
  divergingCommonProps.axisBottom.legend = ""; //Percentage Change
  divergingCommonProps.axisBottom.legendOffset = 42;
  const diverging = {
    ...divergingCommonProps,
  };
  diverging.axisBottom.legendOffset = 65;
  diverging.margin = { top: 5, right: 20, bottom: 70, left: 80 };
  useEffect(() => {
    const clientId = userState.loginedUserData.id;

    let params = { ...getfilter_as_params() };

    setLoading(true);
    Promise.allSettled([
      // fetchXirr(clientId, params, "bonds"),
      // fetchXirrCurrentHolding(clientId, params, "bonds"),
      // fetchTwrr(clientId, params, "bonds"),
      // fetchTwrrCurrentHolding(clientId, params, "bonds"),
      getRealisedData(clientId, params, "bonds", checked),
      getInvestmentTypeData(clientId, params, "bonds"),
    ]).then(
      ([
        // xirr_res,
        // xirrCurrentHolding_res,
        // twrr_res,
        // twrrCurrentHolding_res,
        realised_ress,
        invest_res,
      ]) => {
        // async function allres(){
        //   await xirr_res
        //   await twrr_res
        //   await realiseddata_res
        //   await asset_ress
        //   setLoading(false)
        // }

        // if (xirr_res.status == "fulfilled") {
        //   setXirr(xirr_res.value.result);
        // }
        // if (xirrCurrentHolding_res.status == "fulfilled") {
        //   setXirrCurrentHolding(xirrCurrentHolding_res.value.result);
        // }
        // if (twrr_res.status == "fulfilled") {
        //   if (twrr_res.value?.result?.annualisedTwrr) {
        //     twrr_res.value.result.annualisedTwrr =
        //       twrr_res.value.result?.annualisedTwrr * 100;
        //   }
        //   setTwrr(twrr_res.value.result);
        // }
        // if (twrrCurrentHolding_res.status == "fulfilled") {
        //   if (twrrCurrentHolding_res.value?.result?.annualisedTwrr) {
        //     twrrCurrentHolding_res.value.result.annualisedTwrr =
        //       twrrCurrentHolding_res.value?.result?.annualisedTwrr * 100;
        //   }
        //   setTwrrCurrentHolding(twrrCurrentHolding_res.value.result);
        // }
        if (invest_res.status == "fulfilled") {
          let arr = [];
          invest_res.value.result.forEach((a) => {
            if (a.investment_type.toLowerCase() == "sip") {
              a.investment_type = "SIP";
            }
            arr.push({
              id: `${a.investment_type}`,
              label: `${
                a.investment_type
                  ? a.investment_type.charAt(0).toUpperCase() +
                    a.investment_type.slice(1)
                  : " "
              }`,
              value: parseFloat(a.percentage).toFixed(2),
              amount: a.amount,
            });
          });
          setInvestmentTypeData(arr);
        }

        if (realised_ress.status == "fulfilled") {
          let arr = [];
          if (realised_ress.value.result.unRealisedGainLossPercent != null) {
            if (realised_ress.value.result.realisedGainLossPercent != 0) {
              arr.push({
                id: `Realised Gain Loss`,
                label: `Realised Gain`,
                value: parseFloat(
                  realised_ress.value.result.realisedGainLossPercent
                ).toFixed(2),
                amount: realised_ress.value.result.realisedGainLoss,
              });
            }
            if (realised_ress.value.result.unRealisedGainLossPercent != 0) {
              arr.push({
                id: `Unrealised Gain Loss`,
                label: `Unrealised Gain`,
                value: parseFloat(
                  realised_ress.value.result.unRealisedGainLossPercent
                ).toFixed(2),
                amount: realised_ress.value.result.unRealisedGainLoss,
              });
            }
          }

          setRealisedData({ ...realised_ress.value.result, arr });
        }
        setLoading(false);
      }
    );

    // setloadque(8);
    // fetchXirr(clientId, params, "bonds")
    //   .then((res) => {
    //     setXirr(res.result);
    //     setloadque(loadque > 1 ? loadque - 1 : null);
    //   })
    //   .catch((err) => {
    //     setloadque(loadque > 1 ? loadque - 1 : null);
    //   });
    // fetchTwrr(clientId, params, "bonds")
    //   .then((res) => {
    //     if (res?.result?.annualisedTwrr) {
    //       res.result.annualisedTwrr = res?.result?.annualisedTwrr * 100;
    //     }
    //     setTwrr(res.result);
    //     setloadque(loadque > 1 ? loadque - 1 : null);
    //   })
    //   .catch((err) => {
    //     setloadque(loadque > 1 ? loadque - 1 : null);
    //   });

    // // charts api

    // getRealisedData(clientId, params, "bonds")
    //   .then((res) => {
    //     let arr = [];
    //     if (res.result.unRealisedGainLossPercent != null) {
    //       if (res.result.realisedGainLossPercent != 0) {
    //         arr.push({
    //           id: `Realised Gain Loss`,
    //           label: `Realised Gain`,
    //           value: parseFloat(res.result.realisedGainLossPercent).toFixed(2),
    //           amount: res.result.realisedGainLoss,
    //         });
    //       }
    //       if (res.result.unRealisedGainLossPercent != 0) {
    //         arr.push({
    //           id: `Unrealised Gain Loss`,
    //           label: `Unrealised Gain`,
    //           value: parseFloat(res.result.unRealisedGainLossPercent).toFixed(
    //             2
    //           ),
    //           amount: res.result.unRealisedGainLoss,
    //         });
    //       }
    //     }

    //     setRealisedData({ ...res.result, arr });
    //     setloadque(loadque > 1 ? loadque - 1 : null);
    //   })
    //   .catch((err) => {
    //     setloadque(loadque > 1 ? loadque - 1 : null);
    //   });

    // getInvestmentTypeData(clientId, params, "bonds")
    //   .then((res) => {
    //     let arr = [];
    //     res.result.forEach((a) => {
    //       if (a.investment_type.toLowerCase() == "sip") {
    //         a.investment_type = "SIP";
    //       }
    //       arr.push({
    //         id: `${a.investment_type}`,
    //         label: `${
    //           a.investment_type
    //             ? a.investment_type.charAt(0).toUpperCase() +
    //               a.investment_type.slice(1)
    //             : " "
    //         }`,
    //         value: parseFloat(a.percentage).toFixed(2),
    //         amount: a.amount,
    //       });
    //     });
    //     setInvestmentTypeData(arr);
    //     setloadque(loadque > 1 ? loadque - 1 : null);
    //   })
    //   .catch((err) => {
    //     setloadque(loadque > 1 ? loadque - 1 : null);
    //   });
  }, [mfoFilter, checked]);

  const navigate = useNavigate();
  const handleSummaryClick = () => {
    if (SHOW_ADDITIONAL_DATA_TAB === "show") {
      navigate(routes.bondsSummaryPage_credit);
    } else {
      navigate(routes.bondsSummaryPage_types);
    }
  };
  function holdingSummaryCardsInactiveData() {
    return {
      asOnDate:
        realisedData?.asOnDate != null
          ? holdingCardData?.asOnDate
          : loadingText,

      //    * current-holding api values
      portfolioValueAmount:
        holdingCardData?.portfolioValueAmount != null
          ? currencyFormat(holdingCardData?.portfolioValueAmount)
          : loadingText,
      //   * gain-loss api values
      overallInvestedAmount:
        realisedData?.overAllInvestedAmount != null
          ? currencyFormat(realisedData?.overAllInvestedAmount)
          : loadingText,
      //    * current-holding api values
      investedAmount:
        holdingCardData?.investedAmount != null
          ? currencyFormat(holdingCardData?.investedAmount)
          : loadingText,
      //    * current-holding api values
      redeemedInvestedAmount:
        holdingCardData?.redeemedInvestedAmount != null
          ? currencyFormat(holdingCardData?.redeemedInvestedAmount)
          : loadingText,
      //   * total-gain-loss api value
      totalGainLoss:
        totalGainLossData?.totalGainLoss != null
          ? currencyFormat(totalGainLossData?.totalGainLoss)
          : loadingText,
      //   * gain-loss api values
      realisedGainLoss:
        realisedData?.realisedGainLoss != null
          ? currencyFormat(realisedData?.realisedGainLoss)
          : loadingText,
      //    * current-holding api values
      unRealisedGainLoss:
        holdingCardData?.unRealisedGainLoss != null
          ? currencyFormat(holdingCardData?.unRealisedGainLoss)
          : loadingText,
      //   * gain-loss api values
      xirrValue:
        xirr?.xirr != null
          ? roundNumberWithoutDecimal(Number(xirr?.xirr)) + " %"
          : loadingText,
      //   * gain-loss api values
      absoluteTwrrData:
        twrr?.absoluteTwrr != null
          ? roundNumberWithoutDecimal(Number(twrr?.absoluteTwrr)) + " %"
          : loadingText,
      //   * gain-loss api values
      annualisedTwrrData:
        twrr?.annualisedTwrr != null
          ? roundNumberWithoutDecimal(Number(twrr?.annualisedTwrr)) + " %"
          : loadingText,
      //   * gain-loss api values
      interestIncome:
        realisedData?.interestIncome != null
          ? currencyFormat(realisedData?.interestIncome)
          : loadingText,
    };
  }
  function holdingSummaryCardsActiveData() {
    return {
      asOnDate:
        holdingCardData?.asOnDate !== null
          ? holdingCardData?.asOnDate
          : loadingText,
      portfolioValueAmount:
        holdingCardData?.portfolioValueAmount !== null
          ? currencyFormat(holdingCardData?.portfolioValueAmount)
          : loadingText,
      investedAmount:
        holdingCardData?.investedAmount !== null
          ? currencyFormat(holdingCardData?.investedAmount)
          : loadingText,
      totalGainLoss:
        holdingCardData?.totalGainLoss !== null
          ? currencyFormat(holdingCardData?.totalGainLoss)
          : loadingText,
      realisedGainLoss:
        holdingCardData?.realisedGainLoss !== null
          ? currencyFormat(0)
          : loadingText,
      unRealisedGainLoss:
        holdingCardData?.unRealisedGainLoss !== null
          ? currencyFormat(holdingCardData?.unRealisedGainLoss)
          : loadingText,
      xirrValue:
        xirrCurrentHolding?.xirr !== null
          ? roundNumberWithoutDecimal(Number(xirrCurrentHolding?.xirr)) + " %"
          : loadingText,
      absoluteTwrrData:
        twrrCurrentHolding?.absoluteTwrr !== null
          ? roundNumberWithoutDecimal(
              Number(twrrCurrentHolding?.absoluteTwrr)
            ) + " %"
          : loadingText,
      annualisedTwrrData:
        twrrCurrentHolding?.annualisedTwrr !== null
          ? roundNumberWithoutDecimal(
              Number(twrrCurrentHolding?.annualisedTwrr)
            ) + " %"
          : loadingText,
      interestIncome:
        holdingCardData?.interestIncome != null
          ? currencyFormat(holdingCardData?.interestIncome)
          : loadingText,
    };
  }
  return (
    <>
      <SummaryPageTop
        pageTitle={"Bond Portfolio Summary"}
        backLink={routes?.homeHoldingPage}
        asOnDate={false}
        flip={flip}
        setFlip={setFlip}
        checked={checked}
        setChecked={setChecked}
        setFilterModalOpen={setFilterModalOpen}
      />
      <NewFilter
        filterModalOpen={filterModalOpen}
        setFilterModalOpen={setFilterModalOpen}
        productType="bonds"
      />
      {/* <PageFilter
        customOpenModal={openModal}
        setCustomOpenModal={setOpenModal}
      />
      <div className={`${classes.alignTopHeader}`}>
        <Back
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/home/holding-summary/details");
          }}
        />
        <Typography
          style={{ marginLeft: "-4%" }}
          className={`${classes.mainHeading} ${classes.mainHeadingAlign}`}
        >
          Bond Portfolio Summary
        </Typography>
        <div className={`${classes.iconAlign}`}>
          {!flip && (
            <Bulb
              onClick={() => setFlip(!flip)}
              className={classes.iconStyle}
            />
          )}
          {flip && (
            <LitBulb
              onClick={() => setFlip(!flip)}
              className={classes.iconStyle}
            />
          )}
          <Import className={classes.iconStyle} />
          <Export className={classes.iconStyle} />
          <Print className={classes.iconStyle} />
          <Filter
            className={classes.iconStyle}
            onClick={() => handleState(setOpenModal, true)}
          />
        </div>
      </div> */}

      {/* <BondsCardSummary></BondsCardSummary> */}
      {/* <FlippyArray
        page={"bonds"}
        investmentData={
          realisedData.investedAmount != null
            ? currencyFormat(realisedData.investedAmount)
            : loadingText
        }
        portfolioData={
          realisedData.portfolioValueAmount != null
            ? currencyFormat(realisedData.portfolioValueAmount)
            : loadingText
        }
        date={realisedData.asOnDate ? realisedData.asOnDate : ""}
        realisedGainData={
          realisedData.realisedGainLoss != null
            ? currencyFormat(realisedData.realisedGainLoss)
            : loadingText
        }
        unrealisedGainData={
          realisedData.unRealisedGainLoss != null
            ? currencyFormat(realisedData.unRealisedGainLoss)
            : loadingText
        }
        xirrData={
          xirr?.xirr != null
            ? roundNumber(
                Math.round((xirr.xirr + Number.EPSILON) * 100) / 100
              ) + "%"
            : loadingText
        }
        absoluteTwrrData={
          twrr?.absoluteTwrr != null
            ? roundNumber(
                Math.round((twrr.absoluteTwrr + Number.EPSILON) * 100) / 100
              ) + "%"
            : loadingText
        }
        annualisedTwrrData={
          twrr?.annualisedTwrr != null
            ? roundNumber(
                Math.round((twrr.annualisedTwrr + Number.EPSILON) * 100) / 100
              ) + "%"
            : loadingText
        }
        flip={flip}
      /> */}
      <div style={{ margin: "2%" }}>
        {!checked ? (
          <NewHoldingSummaryCards
            retryFetchTwrr={retryFetchTwrr}
            twrrLoader={twrrLoader}
            twrrError={twrrError}
            infoIconHovered={infoIconHovered}
            holdingSummaryCardsInactive={holdingSummaryCardsInactiveData()}
            // totalRentalIncome={
            //   <GainsCardInfo
            //     text="Total Interest Income"
            //     align="right"
            //     value={
            //       realisedData?.interestIncome != null
            //         ? currencyFormat(realisedData?.interestIncome)
            //         : loadingText
            //     }
            //   />
            // }
            totalGainLossInactive={
              <>
                <GainsCardInfo
                  text="Realized Gain/Loss"
                  value={holdingSummaryCardsInactiveData()?.realisedGainLoss}
                  align="left"
                />
                <GainsCardInfo
                  text="Unrealized Gain/Loss"
                  value={holdingSummaryCardsInactiveData()?.unRealisedGainLoss}
                  align="center"
                />
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <GainsCardInfo
                    text="Total Interest Income"
                    value={holdingSummaryCardsInactiveData()?.interestIncome}
                    align="right"
                  />
                </div>
              </>
            }
          ></NewHoldingSummaryCards>
        ) : (
          <NewHoldingSummaryCardsActive
            retryFetchTwrrCurrentHolding={retryFetchTwrrCurrentHolding}
            twrrLoaderCurrentHolding={twrrLoaderCurrentHolding}
            twrrErrorCurrentHolding={twrrErrorCurrentHolding}
            infoIconHovered={infoIconHovered}
            holdingSummaryCardsActive={holdingSummaryCardsActiveData()}
            // totalRentalIncome={
            //   <GainsCardInfo
            //     text="Total Interest Income"
            //     align="right"
            //     value={
            //       holdingCardData?.interestIncome != null
            //         ? currencyFormat(holdingCardData?.interestIncome)
            //         : loadingText
            //     }
            //   />
            // }
            totalGainLossActive={
              <>
                <GainsCardInfo
                  text="Unrealized Gain/Loss"
                  value={holdingSummaryCardsActiveData()?.unRealisedGainLoss}
                  align="left"
                />
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <GainsCardInfo
                    text="Total Interest Income"
                    value={holdingSummaryCardsActiveData()?.interestIncome}
                    align="right"
                  />
                </div>
              </>
            }
          ></NewHoldingSummaryCardsActive>
        )}
      </div>
      <PortfolioChart
        type={"bonds"}
        loading={loading}
        noPaddingBottom={true}
        viewDetailBtn={
          <Button
            onClick={handleSummaryClick}
            className={classes.viewDetailBtn + " portfolioBtn "}
            style={{
              width: "20%",
              margin: "0% 40% 0",

              borderRadius: "7px",
            }}
            startIcon={<BarChartIcon />}
          >
            View Detail Report
          </Button>
        }
      ></PortfolioChart>

      <PageLoader showLoader={loading} />
      <PageLoader
        showLoader={currentHoldingCardLoader && checked ? true : false}
      />
    </>
  );
}

export default BondsComp;
