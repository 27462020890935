import React, { useState, useContext, useEffect } from "react";
import useStyles from "../common/ProductsStyles";
import { useNavigate } from "react-router-dom";
import { tableConfig, CorporateTableConfig } from "./mfoTableColumns";
import PmsReportTable from "../pms/PmsReportTable";
import { OnboardingContext } from "../../../../Context";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import { currencyFormat } from "../../../../hooks/constantFunction";
import { getTransactionsSummary } from "../../../../api";
import { dateFormatCommon, roundNumber } from "../../../utils/common";
import { routes } from "../../../../routes";
import NewtableTopSec from "../../common/NewtableTopSec";
import { IosSwitch } from "../../../IosSwitch";
import { ITEMS_LIMIT_FILES_PARTNER_LIST } from "../../../../Constants";
import { assectClassConfigSelection } from "../common/util/common";
import NewTabComp from "../common/NewTabComp";

function MfoTransaction() {
  const [tableColumns, setTableColumns] = useState([]);
  const [tableHeader, setTableHeader] = useState(tableConfig);
  const [loading, setLoading] = useState(true);
  const { userState } = OnboardingContext();
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const [openModal, setOpenModal] = useState(false);
  const [checkedTrans, setCheckedTrans] = useState([]);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [checked, setChecked] = useState(false);
  const [toggle, settoggle] = useState(false);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(getfilter_as_params().assetClass);
  const [pagiNationData, setPageinationData] = useState({
    pageCount: null,
  });

  const data1 = [
    { page: "equity", title: "Equity" },
    { page: "debt", title: "Debt" },
    { page: "alternate", title: "Alternate Assets" },
    { page: "cash", title: "Cash" },
  ];
  const handleClick = React.useCallback(
    (page) => {
      setOffset(0);
      setPage(page);
    },
    [page]
  );
  const handleIconClick = () => {
    setOpenModal(!openModal);
  };

  //   Object Formatting
  let actualsList = [
    "corporate_action_value",
    "nav",
    "value",
    "stamp_duty",
    "other_charges",
  ];
  const objectFormating = (data) => {
    let newData = tableHeader.map((header) => {
      if (header?.field == "scheme_name") {
        return {
          field: data[`${header.field}`],
          title: header.title,
          show: header.show,
          align: header?.align,
          tagPresent: data.is_corp_action,
          onClick: () => {
            navigate(`${routes.mfRiskDetailsPage}/?isin=${data["isin"]}`, {
              state: data["isin"],
            });
          },
        };
      }
      if (
        header?.field === "corp_action" ||
        header.field === "corp_action_effects" ||
        header?.field === "remarks" ||
        header?.field === "ratio" ||
        header?.field === "remark"
      ) {
        return {
          field: data[`${header.field}`] ? data[`${header.field}`] : "--",
          title: header.title,
          show: header.show,
          align: header?.align,
          // tagPresent: data[`${header.is_corp_action}`],
          tagPresent: data.is_corp_action,
        };
      }
      if (
        header?.field == "projectCode" ||
        header?.field == "folio_no" ||
        header?.field == "executed_by"
      ) {
        return {
          field: data[`${header.field}`] ? data[`${header.field}`] : "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (
        header?.field == "TWRR_annualised" ||
        header?.field == "annualisedTwrr"
      ) {
        return {
          field: !isNaN(data[`${header.field}`])
            ? roundNumber(Number(data[`${header.field}`]) * 100)
            : "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (actualsList.includes(header?.field)) {
        let currencyformat = currencyFormat(data[`${header.field}`] || 0, {
          showDecimal: true,
          currency: "INR",
          style: "currency",
          format: "actuals",
        });

        return {
          field: currencyformat || 0,
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (
        header?.field == "value" ||
        header?.field == "loan_amount" ||
        header?.field == "loanAmount" ||
        header?.field == "remaining_loan_amount" ||
        header?.field == "remainingLoanAmount" ||
        header?.field == "market_value" ||
        header?.field == "self_funding_amount" ||
        header?.field == "selfFundingAmount" ||
        header?.field == "annual_interest_paid_on_loan" ||
        header?.field == "annualInterestPaidOnLoan" ||
        header?.field == "unrealised_gain_loss" ||
        header?.field == "unRealisedGainLoss" ||
        header?.field == "realised_gain_loss" ||
        header?.field == "realisedGainLoss" ||
        header?.field == "rental_incom_PA" ||
        header?.field == "maintainence_PA" ||
        header?.field == "property_tax_PA"
      ) {
        return {
          field: currencyFormat(data[`${header.field}`] || 0) || "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (
        header?.field == "transaction_date" ||
        header?.field == "value_date"
      ) {
        return {
          field: dateFormatCommon(new Date(data[`${header.field}`])),
          // moment(new Date(data[`${header.field}`])).format(
          //   "DD - MM - YYYY"
          // ) || "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      if (header?.field == "holdingPeriod") {
        return {
          field: data[`${header.field}`]
            ? data[`${header.field}`] + " days"
            : "--",
          // moment(new Date(data[`${header.field}`])).format(
          //   "DD - MM - YYYY"
          // ) || "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }
      return {
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`]))
          : data[`${header.field}`] || "--",
        title: header.title,
        show: header.show,
        align: header?.align,
      };
    });

    return newData;
  };
  const getTableData = async () => {
    setLoading(true);
    let params = {
      clientId: userState.loginedUserData.id,
      showCorpAction: checked,
      limit: ITEMS_LIMIT_FILES_PARTNER_LIST,
      offset: offset,
      // clientId: 199,

      // userState.loginedUserData.id,
      ...getfilter_as_params(),
      assetClass: page,

      search: search,
    };

    try {
      setLoading(true);
      let newlist = [];
      let transactionList = [];

      setLoading(true);
      // let params = {
      //   clientId: 199,
      // };

      let response = await getTransactionsSummary(
        "mf",
        "transaction-summary",
        params
      );
      setPageinationData((prev) => {
        return { ...prev, pageCount: response?.data?.data?.meta?.count };
      });
      response?.data?.data?.result?.map((val) => {
        val.advisor = val?.advisor + "%" + val?.updated_at;
        let obj = objectFormating(val);
        newlist.push(obj);
        if (
          val["transaction_type"] != null &&
          !transactionList
            .map((val) => val?.title)
            .includes(val["transaction_type"])
        ) {
          transactionList.push({
            title: val["transaction_type"],
            show: false,
          });
        }
      });

      setCheckedTrans(transactionList);
      setTableColumns(newlist);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getTableData();
  }, [mfoFilter, search, toggle, offset, page]);

  useEffect(() => {
    if (checked) {
      setTableHeader(() => CorporateTableConfig);
    } else {
      setTableHeader(() => tableConfig);
    }
    settoggle(!toggle);
  }, [checked]);
  return (
    <>
      <NewtableTopSec
        handleIconClick={handleIconClick}
        directPageName={"Transaction Summary"}
        setSearch={(e) => {
          setOffset(0);
          setSearch(e);
        }}
        product={"Mutual Fund"}
        iosSwitch={
          <IosSwitch
            switchLabelName="Show Corporate Action"
            checked={checked}
            setChecked={setChecked}
          />
        }
      />
      <div style={{ margin: "2%" }}>
        <NewTabComp
          data={assectClassConfigSelection({
            assetClassList: getfilter_as_params().assetClass,
            data: data1,
          })}
          // customWidth={"fit-content"}
          customHandle={handleClick}
        />
      </div>
      <div style={{ height: "auto" }}>
        <PmsReportTable
          setOpenModal={setOpenModal}
          setTableColumns={setTableColumns}
          tableColumns={tableColumns}
          openModal={openModal}
          headerList={checked ? CorporateTableConfig : tableConfig}
          loading={loading}
          pageName="transaction"
          // tableHeaderBGColor="#4E189F"
          tableHeader={tableHeader}
          setTableHeader={setTableHeader}
          checkedTransList={checkedTrans}
          stickyProductName={true}
          corporateActionsLabel={
            true ? (
              <div
                style={{
                  position: "absolute",
                  top: "-83px",
                  left: "3px",
                  // zIndex: "99",
                }}
              >
                <div id="corporateActions">Corporate Actions</div>
              </div>
            ) : (
              <></>
            )
          }
          setItemOffset={setOffset}
          pageCount={pagiNationData?.pageCount}
          pageNumber={offset / ITEMS_LIMIT_FILES_PARTNER_LIST + 1 || 1}
        />
      </div>
    </>
  );
}

export default MfoTransaction;
