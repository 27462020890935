import React from "react";
import PartnerHeading from "../PartnerHeading";
import "../PartnerOnboarding.css";
import { registerClientForm1ValidationSchema2 } from "../ValidationSchema";
import { Box, Grid, MenuItem } from "@material-ui/core";
import CustomInput from "../common/CustomInput";
import ErrorMessageComp from "../common/ErrorMessageComp";
import LoadingButton from "../common/LoadingButton";
import StepProgressBar from "../common/StepProgressBar/StepProgressBar";
import { useForm } from "../hooks/useForm";
import {
  ClientMemberRoles,
  ClientMemberRoles2,
  Client_Member_Entity_Types,
  Client_User_Roles,
  MEMBER_TYPES,
} from "../../../Constants";
import {
  handleInitValue,
  handleRoleId,
} from "../../user_management/utils/cmmon";

function ClientForm2({
  handleNext,
  handleBack,
  currentStep,
  customHandleSubmit,
  head,
  onlyPerosn,
  apiErrorMessage,
  loading,
  formData,
  totalStep,
  noStepBottom,
  formMaxHeight,
  userRoleConfig,
}) {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useForm(
    {
      fullName: formData?.fullName ? formData?.fullName : "",
      memberType: formData?.memberType ? formData?.memberType : "",
      // userRole: "Head Of Family",
      roleId: formData?.roleId
        ? handleRoleId(
            Number(formData?.roleId),
            userRoleConfig ? userRoleConfig : ClientMemberRoles2
          )
        : (userRoleConfig ? userRoleConfig : ClientMemberRoles2)[0]?.roleId,
      panNumber: formData?.panNumber ? formData?.panNumber : "",
      dateOfBirth: formData?.dateOfBirth ? formData?.dateOfBirth : "",
      // formData?.entityType == "sole_proprietor"
      //   ? formData?.dateOfBirth
      //   : formData?.doi
      //   ? formData?.doi
      //   : "",
      email: formData?.email ? formData?.email : "",
      mobile: formData?.mobile ? formData?.mobile : "",
    },
    // test,
    (values) => {
      //   handle form submission
      //   values.password = Number(values.password);
      //   submitForm(values);
      customHandleSubmit(values);
      console.log(values, "aflkbabfbuawubfw");
    },
    registerClientForm1ValidationSchema2
  );

  const ENTITY_TYPES = Client_Member_Entity_Types;

  return (
    <div
      className="dFlex jtfyContCntr alignCntr h100 flexDirCol"
      style={{ gap: "25px" }}
    >
      <div className="newFormTopSec">
        <PartnerHeading
          mainHead={head?.main ? head?.main : "Add Family Head Details"}
          subtitle={
            head?.sub ? head?.sub : "Join us by providing your company details"
          }
          handleBack={handleBack}
          step={currentStep}
        />
      </div>
      <div className="newFormContainer" style={{ maxHeight: formMaxHeight }}>
        <Box
          className="dFlex jtfyContCntr alignCntr flexDirCol"
          sx={{ width: { xs: "auto", sm: "330px" }, gap: "41px" }}
        >
          {/* <Grid lg={5} item> */}
          {/* <div className="newFormTopSec">
          <PartnerHeading
            mainHead={head?.main ? head?.main : "Add Family Head Details"}
            subtitle={
              head?.sub
                ? head?.sub
                : "Join us by providing your company details"
            }
            handleBack={handleBack}
            step={currentStep}
          />
        </div> */}

          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid xs={12} lg={12} item>
                <CustomInput
                  // as={TextField}
                  select
                  fullWidth
                  title="Member type"
                  variant="outlined"
                  margin="normal"
                  // required
                  id="memberType"
                  label="Member Type"
                  name="memberType"
                  autoComplete="memberType"
                  // autoFocus
                  // type="select"
                  error={touched.memberType && Boolean(errors.memberType)}
                  helperText={touched.memberType && errors.memberType}
                  value={values.memberType}
                  onChange={handleChange}
                >
                  {ENTITY_TYPES.map((entity) => {
                    return (
                      <MenuItem
                        key={entity.value}
                        value={entity.value}
                        selected={entity?.selected}
                        style={{
                          border: "0.34px solid #3E5389",
                          backgroundColor: "#131826",
                        }}
                      >
                        {entity.name}
                      </MenuItem>
                    );
                  })}
                </CustomInput>
              </Grid>
              <Grid xs={12} lg={12} item>
                <CustomInput
                  title="user role"
                  //   disabled={true}
                  // as={TextField}
                  select
                  variant="outlined"
                  margin="normal"
                  // required
                  fullWidth
                  id="roleId"
                  label="User Role"
                  name="roleId"
                  autoComplete="roleId"
                  autoFocus
                  error={touched.roleId && Boolean(errors.roleId)}
                  helperText={touched.roleId && errors.roleId}
                  value={values.roleId}
                  onChange={handleChange}
                >
                  {(userRoleConfig ? userRoleConfig : ClientMemberRoles2).map(
                    (entity) => {
                      return (
                        <MenuItem
                          key={entity.value}
                          value={entity.value}
                          selected={entity?.selected}
                          color="white"
                          style={{
                            border: "0.34px solid #3E5389",
                            backgroundColor: "#131826",
                          }}
                        >
                          {entity.name}
                        </MenuItem>
                      );
                    }
                  )}
                </CustomInput>
              </Grid>
              {/* <Grid xs={12} lg={12} item>
                <CustomInput
                  title="user role"
                  disabled={true}
                  // as={TextField}
                  variant="outlined"
                  margin="normal"
                  // required
                  fullWidth
                  id="userRole"
                  label="User Role"
                  name="userRole"
                  autoComplete="userRole"
                  autoFocus
                  error={touched.userRole && Boolean(errors.userRole)}
                  helperText={touched.userRole && errors.userRole}
                  value={values.userRole}
                  onChange={handleChange}
                />
              </Grid> */}
              <Grid xs={12} lg={12} item>
                <CustomInput
                  title="Full Name"
                  // as={TextField}
                  variant="outlined"
                  margin="normal"
                  // required
                  fullWidth
                  id="fullName"
                  label="Full Name"
                  name="fullName"
                  autoComplete="fullName"
                  autoFocus
                  error={touched.fullName && Boolean(errors.fullName)}
                  helperText={touched.fullName && errors.fullName}
                  value={values.fullName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid xs={12} lg={12} item>
                <CustomInput
                  // as={TextField}
                  title="EMAIL ID"
                  variant="outlined"
                  margin="normal"
                  // required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  value={values.email}
                  onChange={handleChange}
                />
              </Grid>

              {/* {currentStep == 4 && ( */}
              <Grid
                xs={12}
                lg={12}
                item
                // pr={{ xs: 0, sm: 0, md: 0, lg: 1 }}
              >
                <CustomInput
                  title="Mobile no."
                  // as={TextField}
                  variant="outlined"
                  margin="normal"
                  // required
                  fullWidth
                  id="mobile"
                  label="Mobile Number"
                  name="mobile"
                  error={touched.mobile && Boolean(errors.mobile)}
                  helperText={touched.mobile && errors.mobile}
                  value={values.mobile}
                  onChange={handleChange}
                  // maxLength={10}
                />
              </Grid>
              {/* )} */}
              <Grid
                // xs={12}
                // lg={12}
                style={{ padding: "0px", paddingRight: "5px" }}
                container
                spacing={2}
              >
                <Grid
                  xs={12}
                  lg={6}
                  item
                  // style={{ paddingLeft: { xs: 0, sm: 0, md: 0, lg: 1 } }}
                >
                  <CustomInput
                    title="PAN NO"
                    // as={TextField}
                    variant="outlined"
                    margin="normal"
                    // required
                    fullWidth
                    id="panNumber"
                    label="Pan Number"
                    name="panNumber"
                    error={touched.panNumber && Boolean(errors.panNumber)}
                    helperText={touched.panNumber && errors.panNumber}
                    value={values.panNumber}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid
                  xs={12}
                  lg={6}
                  item
                  // style={{ paddingLeft: { xs: 0, sm: 0, md: 0, lg: 1 } }}
                >
                  <CustomInput
                    // as={TextField}
                    title={
                      values.memberType === "individual"
                        ? "Date Of Birth"
                        : "Date Of Incorporation"
                      //   `Date of ${
                      //   values?.entityType == "sole_proprietor"
                      //     ? "Birth"
                      //     : "incorporation"
                      // }`
                    }
                    variant="outlined"
                    margin="normal"
                    // required
                    fullWidth
                    id="dateOfBirth"
                    label={
                      "Date Of Birth"
                      //   `Date of
                      // ${
                      //   values?.entityType == "sole_proprietor"
                      //     ? "Birth"
                      //     : "incorporation"
                      // }`
                    }
                    name="dateOfBirth"
                    autoComplete="dateOfBirth"
                    autoFocus
                    type="date"
                    error={touched.dateOfBirth && Boolean(errors.dateOfBirth)}
                    helperText={touched.dateOfBirth && errors.dateOfBirth}
                    value={values.dateOfBirth}
                    // onChange={handleChange}
                    onChange={(e) => {
                      const { value } = e.target;
                      const year = value.split("-")[0];

                      if (year.length === 4 || value === "") {
                        setFieldValue("dateOfBirth", value);
                      } else {
                      }
                    }}
                  />
                </Grid>
              </Grid>

              <div className="newFormBottonSec">
                <Grid xs={12} lg={12} style={{ marginTop: "30px" }} item>
                  <ErrorMessageComp errorMessage={apiErrorMessage} />
                  <LoadingButton
                    buttonTitle={"Continue"}
                    type="submit"
                    showLoader={loading}
                  />
                </Grid>
                {noStepBottom ? (
                  ""
                ) : (
                  <Grid xs={12} item style={{ marginTop: "28px" }}>
                    <StepProgressBar
                      total={totalStep}
                      currentStep={currentStep}
                    />
                  </Grid>
                )}
              </div>
            </Grid>
          </form>
          {/* )} */}
          {/* </Formik> */}
          {/* </Grid> */}
        </Box>
      </div>
    </div>
  );
}

export default ClientForm2;
