import React, { useEffect, useState } from "react";
import "../PartnerOnboarding.css";
import { registerClientForm1ValidationSchema } from "../ValidationSchema";
import StepProgressBar from "../common/StepProgressBar/StepProgressBar";
import { useForm } from "../hooks/useForm";
import ErrorMessageComp from "../common/ErrorMessageComp";
import LoadingButton from "../common/LoadingButton";
import { ReactComponent as ClientStep1bg } from "../../../assets/client-onboarding/clientStep1bg.svg";
import { ReactComponent as ClientStep1Bulb } from "../../../assets/client-onboarding/clientStep1Bulb.svg";
import { Box, Grid, InputLabel, MenuItem } from "@material-ui/core";
import PartnerHeading from "../PartnerHeading";
import CustomInput from "../common/CustomInput";
import { ENTITY_TYPES } from "../../../Constants";
import { fetchAllUser } from "../../../api";
import AsynchronousInput from "../../dashboard/common/AsynchronousInput";
import { CLIENT_CODE, CVL_KRA_TOKEN } from "../../../Config";
import { OnboardingContext } from "../../../Context";

function ClientForm1({
  handleNext,
  handleBack,
  currentStep,
  customHandleSubmit,
  head,
  apiErrorMessage,
  loading,
  formData,
  totalStep,
  noStepBottom,
  formMaxHeight,
}) {
  const [entity, setEntity] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [partnercode, setPartnerCode] = useState(false);
  const { userState } = OnboardingContext();

  function fetchAllpartners(search) {
    let head = {};
    if (!userState?.loginedUserData) {
      console.log("afalnj", userState?.loginedUserData);
      head = {
        headers: {
          authorization: `${CVL_KRA_TOKEN}`,
          client_code: CLIENT_CODE,
        },
      };
    }

    setShowLoader(true);
    fetchAllUser("approved", search, 10, 0, head)
      .then((res) => {
        setShowLoader(false);
        console.log(res.data.data.result, "res1");
        let array = [];
        res.data.data.result.map((arr) => {
          array.push({
            name: arr.entity_name,
            code: arr.code,
          });
        });
        setEntity(() => array);
      })
      .catch((err) => {
        setShowLoader(false);
        console.log(err);
      });
  }

  useEffect(() => {
    fetchAllpartners("");
  }, []);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useForm(
    {
      familyName: formData?.familyName ? formData?.familyName : "",
      partner_code: "",
    },
    // test
    (values) => {
      values.partner_code = partnercode;
      console.log(values, "aflkbabfbuawubfw");
      customHandleSubmit(values);
    },
    registerClientForm1ValidationSchema
  );

  return (
    <div className="dFlex jtfyContCntr alignCntr h100">
      <Box
        className="dFlex jtfyContCntr alignCntr flexDirCol"
        sx={{ width: { xs: "auto", sm: "330px" }, gap: "15px" }}
      >
        <div className="newFormTopSec" style={{ zIndex: "10", width: "330px" }}>
          <PartnerHeading
            mainHead={head?.main ? head?.main : "Client Onboarding"}
            subtitle={
              head?.sub
                ? head?.sub
                : "Join us by providing your company details"
            }
            handleBack={handleBack}
            step={currentStep}
          />
        </div>
        <div className="clientStep1">
          <ClientStep1bg className="clientStep1bg" />
          <ClientStep1Bulb className="clientStep1Bulb" />
        </div>
        <div className="newFormContainer" style={{ maxHeight: formMaxHeight }}>
          <form onSubmit={handleSubmit} style={{ width: "100%", zIndex: "10" }}>
            <Grid container>
              <Grid xs={12} lg={12} item style={{zIndex:'10'}}>
                <CustomInput
                  title="Family Name"
                  // as={TextField}
                  variant="outlined"
                  margin="normal"
                  // required
                  fullWidth
                  id="familyName"
                  label="Family Name"
                  name="familyName"
                  autoComplete="familyName"
                  autoFocus
                  error={touched.familyName && Boolean(errors.familyName)}
                  helperText={touched.familyName && errors.familyName}
                  value={values.familyName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid xs={12} lg={12} item style={{ marginTop: "21px",zIndex:'10' }}>
                <InputLabel
                  title={"PARTNER CODE"}
                  style={{
                    paddingLeft: "10px",
                    color: "#FFF",
                    fontFamily: "Avenir",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "16px",
                    /* 133.333% */ letterSpacing: "1.92px",
                    textTransform: "uppercase",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    marginBottom: "11px",
                  }}
                >
                  PARTNER CODE
                </InputLabel>
                {entity && (
                  <div
                    className="asyinput"
                    style={{
                      border: "1.4px solid #8939C8",
                      borderRadius: "5px",
                    }}
                  >
                    <AsynchronousInput
                      data={entity || []}
                      getSearchData={(value) => fetchAllpartners(value)}
                      errors={errors}
                      searchLoader={showLoader}
                      placeholder="Select Partner"
                      optionTitle="name"
                      fieldName="partneCode"
                      objectFieldName="code"
                      formData={formData}
                      handleChange={(name, value, e) => {
                        console.log(value, name, "clicked");
                        // e.target.name = "partneCode";
                        // e.target.value = value;
                        setPartnerCode(value);
                        // handleChange({ name: "partneCode", value: value });
                        // handleAsncChange(name, value)
                      }}
                      // helperText={errors?.clientId}
                    />
                  </div>
                )}
                {/* <CustomInput
                  // as={TextField}
                  select
                  fullWidth
                  title="PARTNER CODE"
                  variant="outlined"
                  margin="normal"
                  // required
                  id="partneCode"
                  label="PARTNER CODE"
                  name="partneCode"
                  autoComplete="partneCode"
                  // autoFocus
                  // type="select"
                  error={touched.partneCode && Boolean(errors.partneCode)}
                  helperText={touched.partneCode && errors.partneCode}
                  value={values.partneCode}
                  onChange={handleChange}
                >
                  {ENTITY_TYPES.map((entity) => {
                    return (
                      <MenuItem
                        key={entity.value}
                        value={entity.value}
                        selected={entity?.selected}
                        style={{
                          border: "0.34px solid #3E5389",
                          backgroundColor: "#131826",
                        }}
                      >
                        {entity.name}
                      </MenuItem>
                    );
                  })}
                </CustomInput> */}
              </Grid>
              <div className="newFormBottonSec">
                <Grid xs={12} lg={12} style={{ marginTop: "2rem" }} item>
                  <ErrorMessageComp errorMessage={apiErrorMessage} />
                  <LoadingButton
                    buttonTitle={"Continue"}
                    type="submit"
                    showLoader={loading}
                  />
                </Grid>
                {noStepBottom ? (
                  ""
                ) : (
                  <Grid xs={12} item style={{ marginTop: "28px" }}>
                    <StepProgressBar
                      total={totalStep}
                      currentStep={currentStep}
                    />
                  </Grid>
                )}
              </div>
            </Grid>
          </form>
        </div>
      </Box>
    </div>
  );
}

export default ClientForm1;
