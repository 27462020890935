import React from "react";
import Default from "../components/dashboard/defaultPages/Default";
import UserManagementComp from "../components/user_management/UserManagementComp";
import { OnboardingContext } from "../Context";
import NewPartnerUserManagement from "../components/user_management/PartnerUserManagement/NewPartnerUserManagement";
import { DetailsAccordion } from "../components/dashboard/common/DetailsAccordion";
import NewAdminUserManagement from "../components/user_management/AdminUserManagment/NewadminrUserManagement";
function UserManagement() {
  const { userState } = OnboardingContext();
  console.log(userState?.loginedUserData, "logged data");
  return (
    <Default>
      {userState?.loginedUserData?.user_type === "client" ? (
        <UserManagementComp />
      ) : userState?.loginedUserData?.user_type === "mfo-user" ? (
        <NewAdminUserManagement />
      ) : (
        <NewPartnerUserManagement />
      )}
      {/* <DetailsAccordion></DetailsAccordion> */}
    </Default>
  );
}

export default UserManagement;
