import moment from "moment";
import { roundTo } from "round-to";
import { currencyFormat } from "../../hooks/constantFunction";
import { FILE_TYPES } from "../../Constants";

let currencyConfig = {
  currencyStyle: { style: "currency", currency: "INR" },
};

export const roundNumber = (num) => {
  if (!Number(num) || num == 0) {
    return "0.00";
  }
  num = Number(num);
  return roundTo(num, 2).toFixed(2);
};

export const roundNumberWithoutDecimal = (num) => {
  if (!Number(num) || num == 0) {
    return "0";
  }
  num = Number(num);
  return roundTo(num, 2).toFixed(2);
};

export const amountWithSigns = (amound) => {
  if (amound == 0) {
    return "+" + currencyFormat(amound, currencyConfig);
  } else if (amound > 0) {
    return "+" + currencyFormat(amound, currencyConfig);
  } else {
    return "-" + currencyFormat(amound, currencyConfig).split("-").join("");
  }
};

export const dateFormatCommon = (d, f) => {
  if (new Date(d)) {
    var string = moment(d, f ? f : "DD MM YYYY").format("DD MMM YYYY");
  } else {
    string = "--";
  }
  return string;
};

export const mfoStaticFilter = {
  checkData: {},
  startDate: null, // new Date(moment().subtract(1, 'years').format('YYYY-MM-DD'))
  endDate: null, //new Date(moment().format('YYYY-MM-DD'))
  advisor: {},
  currency: "Rupees",
  period: "Inception Till Date",
  selectedPeriod: "ITD",
  assetclass: {
    mfo: { alternate: true, cash: true, debt: true, equity: true },
    mf: { alternate: true, cash: true, debt: true, equity: true },
    aif: { alternate: true, debt: true, equity: true },
    bonds: { debt: true },
    pms: { alternate: true, debt: true, equity: true },
    equity: { equity: true },
    "real-estate": { alternate: true },
    general: { alternate: true, cash: true, debt: true, equity: true },
    portfolio: {
      alternate: true,
      cash: true,
      debt: true,
      equity: true,
    },
    //insurance: {},
    insurance: { alternate: true },
    private: { equity: true },
    structured: { alternate: true },
    mld: { alternate: true },
    invits: { alternate: true },
    reits: { alternate: true },
    icd: { debt: true },
    "fixed-deposit": { debt: true },
    bank: { cash: true },
    "recurring-deposit": { debt: true },
  },
  selectAll: true,
  selectAllAdvisor: true,
  selectAllExecutor: true,
  executor: {},
  promoterStake: 1,
};

/* NOTE 
For the time being bondsTransactionSummaryDateFormat is created
we need to make the dateFormatCommon a common utility date function
and remove bondsTransactionSummaryDateFormat once proper unit testing of dateFormatCommon
is done
*/
/**
 * Formats a date or date string to the specified format.
 *
 * @param {string|Date} d - The date or date string to format.
 * @param {string} [f] - The desired date format (optional).
 * @returns {string} The formatted date string or "--" if input is invalid.
 */
export const bondsTransactionSummaryDateFormat = (d, f) => {
  // Check if the input is a valid date object or a valid date string using moment.js
  if (moment(d, moment.ISO_8601).isValid()) {
    // Format the valid date to the specified format (or default format if not provided)
    var string = moment(d).format(f ? f : "DD MMM YYYY");
  } else {
    // Return "--" if the input is not a valid date
    string = "--";
  }
  return string;
};
export const dateFormatCommon2 = (d, f) => {
  if (new Date(d)) {
    var string = moment(d, f ? f : "DD MMM YYYY").format("DD MMMM YYYY");
  } else {
    string = "--";
  }
  return string;
};

export const dateFormatCommon3 = (d, f) => {
  if (new Date(d)) {
    var string = moment(d, f ? f : "DD MMM YYYY").format("DD/MM/YYYY");
  } else {
    string = "--";
  }
  return string;
};

export const dateFormatCommon4 = (d, f) => {
  if (new Date(d)) {
    var string = moment(d, f ? f : "DD MMM YYYY").format("DD/MM/YYYY hh:mm A");
  } else {
    string = "--";
  }
  return string;
};

// function to get the first letter
export function getFirsLetterFromMemberName(memberName) {
  let firstName = memberName.split(" ")[0];
  let lastName = memberName.split(" ")[memberName.split(" ").length - 1];
  return firstName[0] + lastName[0];
}
export function getlastnameMemberName(memberName) {
  let lastName = memberName.split(" ")[memberName.split(" ").length - 1];
  return lastName;
}
//
export const getFileTypeByProduct = (
  entity,
  uploadType,
  hide,
  cssClassName
) => {
  if (entity === "aif" && uploadType !== "Data Upload") {
    return Array(...FILE_TYPES, {
      name: "Unit Based Valuation",
      value: "unit-based/valuation",
    }).map((file_type) => {
      return (
        <option
          // className="optiondesign"
          className={cssClassName ? cssClassName : "optiondesign"}
          key={file_type.value}
          value={file_type.value}
        >
          {file_type.name}
        </option>
      );
    });
  }

  if (
    (entity === "private" || entity === "equity") &&
    uploadType !== "Data Upload"
  ) {
    return Array(...FILE_TYPES, {
      name: "Promotor Stake",
      value: "promoter_stake",
    }).map((file_type) => {
      return (
        <option
          // className="optiondesign"
          className={cssClassName ? cssClassName : "optiondesign"}
          key={file_type.value}
          value={file_type.value}
        >
          {file_type.name}
        </option>
      );
    });
  }

  if (entity === "pms" && uploadType === "Data Upload") {
    return Array(...FILE_TYPES, {
      name: "Underlying Transactions",
      value: "underlying_transaction",
    }).map((file_type) => {
      return (
        <option
          // className="optiondesign"
          className={cssClassName ? cssClassName : "optiondesign"}
          key={file_type.value}
          value={file_type.value}
        >
          {file_type.name}
        </option>
      );
    });
  } else if (entity === "pms" && uploadType !== "Data Upload") {
    return Array(
      ...FILE_TYPES,
      {
        name: "Upcoming Transaction",
        value: "upcoming_transaction",
      },
      {
        name: "Underlying Transactions",
        value: "underlying_transaction",
      }
    ).map((file_type) => {
      return (
        <option
          // className="optiondesign"
          className={cssClassName ? cssClassName : "optiondesign"}
          key={file_type.value}
          value={file_type.value}
        >
          {file_type.name}
        </option>
      );
    });
  }

  if (entity === "bonds" && uploadType !== "Data Upload") {
    // console.log(hide?.payOutReduction, "ajabfkj");
    return Array(
      ...FILE_TYPES
      // hide?.payOutReduction ? undefined : null
      // : {
      //     name: "Payout",
      //     value: "bonds-payout-details",
      //   }
    ).map((file_type) => {
      if (file_type)
        return (
          <option
            // className="optiondesign"
            className={cssClassName ? cssClassName : "optiondesign"}
            key={file_type.value}
            value={file_type.value}
          >
            {file_type.name}
          </option>
        );
    });
  }

  return FILE_TYPES.map((file_type) => {
    if (
      ((entity == "mf" ||
        entity == "equity" ||
        entity == "equity-ipo" ||
        entity == "bank" ||
        entity == "fd" ||
        entity == "rd" ||
        entity == "funds-in-transit") &&
        file_type.value == "transaction") ||
      file_type.value == ""
    ) {
      return (
        <option
          // className="optiondesign"
          className={cssClassName ? cssClassName : "optiondesign"}
          key={file_type.value}
          value={file_type.value}
        >
          {file_type.name}
        </option>
      );
    } else if (
      entity !== "mf" &&
      entity != "equity" &&
      entity != "equity-ipo" &&
      entity != "bank" &&
      entity != "fd" &&
      entity != "rd" &&
      entity !== "funds-in-transit"
    ) {
      return (
        <option
          // className="optiondesign"
          className={cssClassName ? cssClassName : "optiondesign"}
          key={file_type.value}
          value={file_type.value}
        >
          {file_type.name}
        </option>
      );
    }
  });
};

export function checkChangePercentage(data) {
  if (data == 0) {
    return "(+" + data.toFixed(2) + "%)";
  } else if (data > 0) {
    return "(+" + roundNumber(data) + "%)";
  } else {
    return "(" + roundNumber(data) + "%)";
  }
}

export const dayFormatter = (dateString) => {
  try {
    let formattedDate = moment(dateString).format("dddd, MMMM D, YYYY");
    return formattedDate;
  } catch (error) {
    console.error(`Error formatting date string: ${error.message}`);
    // You can also set a default value or error message for the formattedDate variable
  }
};

export const dayFormatter2 = (dateString) => {
  try {
    let formattedDate = moment(dateString).format("D MMM YYYY");
    return formattedDate;
  } catch (error) {
    console.error(`Error formatting date string: ${error.message}`);
    // You can also set a default value or error message for the formattedDate variable
  }
};

export const dayFormatterMMMYYYY = (dateString) => {
  try {
    let formattedDate = moment(dateString).format("MMM YYYY");
    return formattedDate;
  } catch (error) {
    console.error(`Error formatting date string: ${error.message}`);
    // You can also set a default value or error message for the formattedDate variable
  }
};

// Converts "8th September, 2022 --> 08-09-2022"
export const dateFormatter = (dateString) => {
  const date = moment(dateString, "Do MMMM, YYYY");
  const formattedDate = date.format("DD-MM-YYYY");
  return formattedDate;
};
/**
Returns an object from an array of objects based on the value of a specified column name.
@param {Array} arr - The array of objects to search through.
@param {string} columnName - The name of the column to search for.
@returns {Object|undefined} - An object with the properties RID, Y202112, Y202012, Y201912, Y201812, Y201712, Y201612, Y201512, Y201412, Y201312, Y201212 if the column name is found, otherwise undefined.
👇
*/
export function getObjectByColumnName(arr, columnName) {
  let result;

  arr?.forEach((obj) => {
    if (obj.COLUMNNAME.trim() === columnName) {
      // result = {
      //   RID: obj.RID,
      //   Y202112: obj.Y202112,
      //   Y202012: obj.Y202012,
      //   Y201912: obj.Y201912,
      //   Y201812: obj.Y201812,
      //   Y201712: obj.Y201712,
      //   Y201612: obj.Y201612,
      //   Y201512: obj.Y201512,
      //   Y201412: obj.Y201412,
      //   Y201312: obj.Y201312,
      //   Y201212: obj.Y201212,
      // };
      result = {
        RID: obj.RID,
        tenYears: obj.tenYears,
        fiveYears: obj.fiveYears,
        threeYears: obj.threeYears,
        currentYears: obj.currentYears,
      };
    }
  });

  return result;
}

export const handleKey = (path, object) => {
  return path.split(".").reduce(function (v, k) {
    return (v || {})[k];
  }, object);
};

const totalText = (totalCount, type) => {
  return (
    <span>
      <span className="totalRowText">Total:</span>
      <span className="totaltextValue">
        <span className="totalRowText">{totalCount}</span>
        {type == "holding-member-wise-performance" ? "Members" : "Instruments"}
      </span>
    </span>
  );
};

export const summarizeTableData = (
  data,
  holdingCardData,
  xirr,
  twrr,
  numberOfInstruments,
  typeOfPage, // This check is important for Portfolio Holding Page
  typeOfProduct,
  bankSummaryTotal,
  percentageOfTotalPortfolio
) => {
  if (data.length > 0) {
    const firstRow = data[0];
    const titleConfig = {
      ["investmentValue"]: () =>
        currencyFormat(holdingCardData?.investedAmount),
      ["investedAmount"]: () => currencyFormat(holdingCardData?.investedAmount),
      // For bank the presentValue which is named as Amount will not be portfolioValueAmount
      ["presentValue"]: () =>
        typeOfProduct === "bank"
          ? currencyFormat(bankSummaryTotal)
          : currencyFormat(holdingCardData?.portfolioValueAmount),
      ["currentValue"]: () =>
        currencyFormat(holdingCardData?.portfolioValueAmount),
      ["presentAmount"]: () =>
        currencyFormat(holdingCardData?.portfolioValueAmount),
      ["otherIncome"]: () => currencyFormat(holdingCardData?.interestIncome),
      ["unRealisedGainLoss"]: () =>
        currencyFormat(holdingCardData?.unRealisedGainLoss),
      ["interestIncome"]: () => currencyFormat(holdingCardData?.interestIncome),
      ["percentageOfTotalPortfolio"]: () =>
        roundNumberWithoutDecimal(percentageOfTotalPortfolio),
      ["totalGainLoss"]: () => currencyFormat(holdingCardData?.totalGainLoss),
      ["xirr"]: () =>
        typeOfProduct === "holding-member-wise-performance"
          ? roundNumberWithoutDecimal(Number(xirr?.xirrValue))
          : roundNumberWithoutDecimal(Number(xirr?.xirr)),
      ["absoluteTwrr"]: () =>
        roundNumberWithoutDecimal(Number(twrr?.absoluteTwrr)),
      ["annualisedTwrr"]: () =>
        roundNumberWithoutDecimal(Number(twrr?.annualisedTwrr)),
      ["schemeName"]: () => totalText(numberOfInstruments, typeOfProduct),
      ["productName"]: () =>
        typeOfPage === "portfolio-holding"
          ? "--"
          : totalText(numberOfInstruments, typeOfProduct),
      ["scripName"]: () => totalText(numberOfInstruments, typeOfProduct),
      ["projectName"]: () => totalText(numberOfInstruments, typeOfProduct),
      ["bankName"]: () => totalText(numberOfInstruments, typeOfProduct),
      ["memberName"]: () =>
        typeOfPage === "holding" &&
        typeOfProduct === "holding-member-wise-performance"
          ? totalText(numberOfInstruments, typeOfProduct)
          : "--",
      ["allocation"]: () =>
        typeOfPage === "holding" &&
        typeOfProduct === "holding-member-wise-performance"
          ? roundNumberWithoutDecimal(100)
          : "--",
    };

    const summaryRow = firstRow.map((item) => {
      let newField = "--"; // Default value if not found in titleConfig

      // Check if the item.key is in titleConfig to override the default
      if (titleConfig[item.key]) {
        newField = titleConfig[item.key]();
      }

      return { ...item, field: newField };
    });

    return [summaryRow];
  }
};
