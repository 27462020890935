import React from "react";
import Default from "../components/dashboard/defaultPages/Default";
import MLDSummaryComp from "../components/dashboard/products/mld/MLDSummaryComp";

function MLDSummary() {
  return (
    <>
      <Default>
        <MLDSummaryComp />
      </Default>
    </>
  );
}

export default MLDSummary;
