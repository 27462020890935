import React from 'react'
import Default from '../components/dashboard/defaultPages/Default'
import { useParams } from "react-router-dom";
import EquityReport from '../components/dashboard/products/equity/EquityReport';


function EquityDetails() {
    const {pageName}=useParams()
    return (
        <>
            <Default>
                <EquityReport pageName={pageName}></EquityReport>
            </Default>
        </>
    )
}

export default EquityDetails

