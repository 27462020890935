import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { dayFormatter2, roundNumber } from "../../../utils/common";
import CardInvestment from "./utils/CardInvestment";
import CardWithPieChartAndTable from "./utils/CardWithPieChartAndTable";
import { getTableAndPieChartData } from "./utils/common";
import {
  creditRatingConfig,
  creditRatingPieChartConfig,
  holdingDetailsConfigChart,
  holdingDetailsConfigTable,
  sectorWiseConfigTable,
} from "./utils/constands";
import FinxedIcomeSection from "./utils/FinxedIcomeSection";
import FundDetailsSection from "./utils/FundDetailsSection";
import FundPortfolioCard from "./utils/FundPortfolioCard";
import MarketCapPieChart from "./utils/MarketCapPieChart";
import { creditRatingStaticData } from "./utils/constands";
import { Typography } from "@material-ui/core";
import useStyles from "../common/ProductsStyles";

function RiskDetailsPageHybrid({
  riskDetails,
  tableColumns,
  setTableColumns,
  tableHeader,
  setTableHeader,
  setSectorWiseState,
  sectorWiseState,
  sectorWiseColor,
  assetProgressBarColors,
  asset_color,
  disabledassetProgressBarColors,
  setAsset_color,
  handleMouseMove,
  marketCapState,
  setMarketCapState,
  marketCapColors,
  sectorAllocation,
}) {
  const [topHoldingData, setTopHoldingData] = useState({
    tableData: [],
    pieChartData: [],
  });
  const [newSectorAllocation, setNewSectorAlocation] = useState({
    tableData: [],
    pieChartData: [],
  });
  const [creditRating, setCreditRating] = useState({
    tableData: [],
    pieChartData: [],
  });
  const classes = useStyles();

  const objectFormating = (data, headerConfig) => {
    let newData = headerConfig?.map((header) => {
      // if (header?.field == "Name") {
      //   return {
      //     field: data[`${header.field}`],
      //     title: header.title,
      //     show: header.show,
      //     align: header?.align,
      //   };
      // }
      return {
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`]))
          : data[`${header.field}`] || "--",
        title: header.title,
        show: header.show,
        align: header?.align,
      };
    });

    return newData;
  };

  const handleTopHoldingData = (data) => {
    let res = getTableAndPieChartData({
      data: data,
      field: "holdingDetails",
      tableConfig: holdingDetailsConfigTable,
      tableDataFormatter: objectFormating,
      pieChartConfig: holdingDetailsConfigChart,
    });

    setTopHoldingData((prev) => {
      return {
        tableData: res?.tableData,
        pieChartData: [],
      };
    });
  };

  const handleSectorAlocationData = (data) => {
    let res = getTableAndPieChartData({
      data: { data: data },
      field: "data",
      tableConfig: sectorWiseConfigTable,
      tableDataFormatter: objectFormating,
    });

    setNewSectorAlocation(() => ({
      tableData: res?.tableData,
      pieChartData: data,
      total: res?.total,
    }));
  };

  const handleCreditRatingData = (data) => {
    if (data) {
      let newData = Object.keys(data).map((val) => ({
        id: val,
        label: val,
        value: data[val],
      }));

      let res = getTableAndPieChartData({
        data: { data: newData },
        field: "data",
        tableConfig: creditRatingConfig,
        tableDataFormatter: objectFormating,
        pieChartConfig: creditRatingPieChartConfig,
      });

      setCreditRating({
        tableData: res?.tableData,
        pieChartData: res?.pieChartData,
      });
    }
  };

  useEffect(() => {
    handleTopHoldingData(riskDetails);
    handleSectorAlocationData(sectorAllocation);
    // if (riskDetails?.creditRating) {
    handleCreditRatingData(riskDetails?.creditRating);
    // }
  }, []);

  return (
    <>
      <FinxedIcomeSection riskDetails={riskDetails} />
      <FundPortfolioCard
        riskDetails={riskDetails}
        assetProgressBarColors={assetProgressBarColors}
        disabledassetProgressBarColors={disabledassetProgressBarColors}
        setAsset_color={setAsset_color}
        handleMouseMove={handleMouseMove}
        asset_color={asset_color}
      />
      <MarketCapPieChart
        sectorWiseColor={sectorWiseColor}
        setSectorWiseState={setSectorWiseState}
        sectorWiseState={sectorWiseState}
        riskDetails={riskDetails}
        marketCapState={marketCapState}
        setMarketCapState={setMarketCapState}
        marketCapColors={marketCapColors}
      />
      <CardWithPieChartAndTable
        sectorWiseColor={sectorWiseColor}
        setSectorWiseState={setSectorWiseState}
        sectorWiseState={sectorWiseState}
        subTitle="Sector Allocation"
        data={newSectorAllocation}
        tableConfig={sectorWiseConfigTable}
      />
      <CardWithPieChartAndTable
        sectorWiseColor={sectorWiseColor}
        setSectorWiseState={setSectorWiseState}
        sectorWiseState={sectorWiseState}
        subTitle={
          <>
            Credit Rating &nbsp;
            <Typography
              style={{
                fontSize: "16px",
                color: "#9488A7",
                fontWeight: "500",
              }}
              className={`${classes.riskHeaderSub}`}
              // id="fundPortfolio"
            >
              {riskDetails?.creditRatingDate
                ? " (As on Date " +
                  dayFormatter2(riskDetails?.creditRatingDate) +
                  ")"
                : ""}
            </Typography>
          </>
        }
        data={creditRating}
        tableConfig={creditRatingConfig}
      />
      <CardWithPieChartAndTable
        sectorWiseColor={sectorWiseColor}
        setSectorWiseState={setSectorWiseState}
        sectorWiseState={sectorWiseState}
        title="Top Holdings"
        id="topHoldings"
        data={topHoldingData}
        tableConfig={holdingDetailsConfigTable}
      />
      <div style={{ margin: "2%" }}>
        <CardInvestment riskDetails={riskDetails} />
      </div>
      <div style={{ margin: "2%" }}>
        <FundDetailsSection riskDetails={riskDetails} />
      </div>
    </>
  );
}

export default RiskDetailsPageHybrid;
