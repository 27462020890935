import React from "react";
import Default from "../components/dashboard/defaultPages/Default";

import UserManagementClientComp from "../components/user_management/userManagmentClientComp";

function NewClintToUserManagement() {
  return (
    <>
      <Default>
        <UserManagementClientComp />
      </Default>
    </>
  );
}

export default NewClintToUserManagement;
