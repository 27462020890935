import React from "react";
import { fetchAllUser, getPartnerDetail } from "../api";
import { useState } from "react";

function useGetNoOfUserAndClient({ setData }) {
  // const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getUserAndClientCount = async (partnerId) => {
    try {
      setLoading(true);
      let res = await getPartnerDetail(partnerId);
      //   console.log(res, "aglaghkj");

      setData(() => res?.data?.data?.result);

      setLoading(false);
      return res?.data?.data?.result;
    } catch (error) {
      setLoading(false);
      return error;
    }
  };

  return { getUserAndClientCount, loading };
}

export default useGetNoOfUserAndClient;
