import React, { useRef, useEffect } from "react";
import { divergingCommonProps } from "../../../charts/nivo_objects";
import Header from "../Components/Header";
import CardsWrapper from "../Components/cardsWrapper";
import Hr from "../Components/Hr";
import Bar from "../Components/barGraph";
import PageContainer from "../PageContainer";
import PrintTable from "../Components/Table";
import { StackBarChart } from "../Components/StackBarChart";
import { StackTable } from "../Components/StackTable";
import { currencyFormatPrint as currencyFormat } from "../../../../hooks/constantFunction";
import { roundNumber, roundNumberWithoutDecimal } from "../../../utils/common";
import { Link } from "react-router-dom";
import { Breadcrumbs } from "@material-ui/core";
import TabCards from "../Components/TabCards";
import { useState } from "react";
import Print_PieChart from "../Components/Print_PieChart";
import { firstLetterCapitalize } from "../../../utils/strHelper";

export default function Overall_portfolioHolding({
  data,
  denomination,
  newData,
  gainLossDataStr,
  xirrStr,
  assetSummaryStr,
  topAllocationsStr,
  meta,
  pdfInfo,
  member,
}) {
  // console.log(meta, "newdata");
  const [data1, setData1] = useState([]);
  const colors = ["#4B64ED", "#BDC5F2", "#4BB3ED", "#13237b"];
  const maxInstrumentLen = 72;
  const breadcrumbs = [
    <Link
      key="1"
      color="#000000"
      className="printBreadsel fontFamilyAvBold f16"
    >
      Portfolio Overview
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="
 #000000"
    >
      Member Name:{" "}
      <span className="pdfselectedTxt fontFamilyAvBold pdfCaps f16">
        {member}
      </span>
    </Link>,
  ];
  const asstclass = { equity: 1, debt: 2, alternate: 3, cash: 4 };
  useEffect(() => {
    let piedata = [];
    if (gainLossDataStr) {
      console.log(newData?.[assetSummaryStr], "asset str");
    }
    newData?.[assetSummaryStr || "dashboard-asset-summary"]?.data?.map(
      (data, i) => {
        piedata[asstclass?.[data?.assetClass]] = {
          id: data?.assetClass,

          label: data?.assetClass,
          value: roundNumber(data?.percentageOfTotalPortfolio),
          val: [
            data?.portfolioValueAmount,
            data?.percentageOfTotalPortfolio,
            currencyFormat(data?.portfolioValueAmount),
          ],

          color: "hsl(228, 70%, 50%)",
        };
        // if (i == 4) {
        //   piedata[i] = undefined;
        // }
      }
    );

    setData1(
      piedata.map((p) => {
        if (p == undefined) {
          return {};
        } else {
          return p;
        }
      })
    );
  }, [newData]);
  function getposColor(val) {
    if (val < 0) {
      return "#A60000";
    } else if (val) {
      return "#00A651";
    }
  }
  let bartheme = {
    background: "transparent",
    textColor: "#333333",
    fontSize: 11,
    axis: {
      domain: {
        line: {
          stroke: "#fff",
          strokeWidth: 2,
        },
      },
      legend: {
        text: {
          fontSize: 12,
          fill: "#F8F9FB",
        },
      },
      ticks: {
        line: {
          stroke: "#1f1c40",
          strokeWidth: 1,
        },
        text: {
          fontSize: 11,
          fill: "#000000",
        },
      },
    },
    grid: {
      line: {
        stroke: "#1f1c40",
        strokeWidth: 1,
      },
    },
    legends: {
      title: {
        text: {
          fontSize: 11,
          fill: "#ebe8ee",
        },
      },
      text: {
        fontSize: 11,
        fill: "#000000",
      },
      ticks: {
        line: {},
        text: {
          fontSize: 10,
          fill: "#333333",
        },
      },
    },
    annotations: {
      text: {
        fontSize: 13,
        fill: "#333333",
        outlineWidth: 2,
        outlineColor: "#3c9cfa",
        outlineOpacity: 0.7,
      },
      link: {
        stroke: "#000000",
        strokeWidth: 1,
        outlineWidth: 2,
        outlineColor: "#ffffff",
        outlineOpacity: 1,
      },
      outline: {
        stroke: "#000000",
        strokeWidth: 2,
        outlineWidth: 2,
        outlineColor: "#ffffff",
        outlineOpacity: 1,
      },
      symbol: {
        fill: "#000000",
        outlineWidth: 2,
        outlineColor: "#ffffff",
        outlineOpacity: 1,
      },
    },
    tooltip: {
      container: {
        background: "#ffffff",
        color: "#333333",
        fontSize: 12,
      },
      basic: {},
      chip: {},
      table: {},
      tableCell: {},
      tableCellValue: {},
    },
  };
  bartheme.axis.domain.line.stroke = "#ECECEC";
  bartheme.axis.domain.line.strokeWidth = "1";

  let diverging = {
    ...divergingCommonProps,
    enableGridY: false,
  };

  let carddata = [
    {
      title: "Current Value",
      value: currencyFormat(
        newData?.[gainLossDataStr ? gainLossDataStr : "dashboard-gain-loss"]
          ?.portfolioValueAmount,
        undefined,
        denomination,
        "prl2"
      ),
      style: { color: "black", fontSize: "20px" },
      type: "rs",
    },
    {
      title: "Invested Value",
      value: currencyFormat(
        newData?.[gainLossDataStr ? gainLossDataStr : "dashboard-gain-loss"]
          ?.investedAmount,
        undefined,
        denomination,
        "prl2"
      ),
      style: { color: "black", fontSize: "20px" },
      type: "rs",
    },
    {
      title: "Unrealised Gain/Loss",
      value: currencyFormat(
        newData?.[gainLossDataStr ? gainLossDataStr : "dashboard-gain-loss"]
          ?.unRealisedGainLoss,
        undefined,
        denomination,
        "prl2"
      ),
      style: {
        color: getposColor(
          newData?.[gainLossDataStr ? gainLossDataStr : "dashboard-gain-loss"]
            ?.unRealisedGainLoss
        ),
        fontSize: "20px",
      },
      type: "rs",
    },
    {
      title: "XIRR ( Since Inception )",
      value:
        newData?.[xirrStr || "dashboard-xirr"]?.xirrValue != null
          ? roundNumber(newData?.[xirrStr || "dashboard-xirr"]?.xirrValue) +
            " %"
          : "--",
      style: {
        color: getposColor(newData?.[xirrStr || "dashboard-xirr"]?.xirrValue),
        fontSize: "20px",
      },
      type: "rs",
    },
  ];

  return (
    <div>
      <PageContainer layout_t={1} pageNo={1} bgColor={"#fff"} pdfInfo={pdfInfo}>
        <Header
          title={"Portfolio Overview"}
          type={member ? 13 : 9}
          subtext={""}
          hideHr={true}
          noDateFormat={true}
          breadcrumbs={breadcrumbs}
          updatedAsOn={
            newData?.[gainLossDataStr ? gainLossDataStr : "dashboard-gain-loss"]
              ?.updatedAsOn
          }
        ></Header>
        <CardsWrapper carddata={carddata} />
        {/* <CardsWrapper
          carddata={carddata2}
          style={{ gridTemplateColumns: "1fr 1fr 1fr", marginTop: "10px" }}
          classnm="tab3"
        /> */}
        {/* <Hr title={"Portfolio Performance"} /> */}

        <br></br>
        <br></br>
        <div
          className="p_flex"
          style={{ height: "100%", paddingBottom: "50px", boxShadow: "none" }}
        >
          <div
            style={{
              width: "50%",
              borderRight: "1px solid #B2BBEF",
              marginRight: "2%",
              height: "7in",
            }}
          >
            <p className="gloabalFont title4">Asset Allocation</p>

            <div
              className="p_flex justify_sb overallTabCards pt10"
              style={{ marginRight: "74px", paddingTop: "24px" }}
            >
              {data1?.slice(0, 3).map((a, i) => {
                if (!a.value) {
                  return <></>;
                }
                return (
                  <TabCards
                    data={a}
                    text={["Current Value", "Weightage"]}
                    type={["rs", "percent"]}
                    color={colors[asstclass[a.label] - 1]}
                    getkey={i}
                    denomination={denomination}
                  ></TabCards>
                );
              })}
            </div>
            <div style={{ height: "340px", overflow: "hidden" }}>
              <div
                style={{ width: "607px", height: "617px", marginTop: "-115px" }}
              >
                {/* <div
                      className="p_circle"
                      style={{ transform: "translate(110%, 100px)" }}
                    >
                      <div>
                        <div className="">Current Value</div>
                        <div></div>
                      </div>
                    </div> */}
                {data1 && (
                  <Print_PieChart
                    data={data1.filter((d) => {
                      if (d && d?.value != undefined) {
                        return true;
                      }
                    })}
                    config={{ colors: colors }}
                  />
                )}
              </div>
            </div>
            <div
              className="p_flex justify_sb overallTabCards"
              style={{ marginRight: "74px" }}
            >
              {data1?.slice(3, 5).map((a, i) => {
                if (!a.value) {
                  return <></>;
                }
                return (
                  <TabCards
                    data={a}
                    text={["Current Value", "Weightage"]}
                    type={["rs", "percent"]}
                    color={colors[asstclass[a.label] - 1]}
                    getkey={i}
                    denomination={denomination}
                  ></TabCards>
                );
              })}
            </div>
          </div>
          <div style={{ width: "50%" }}>
            <p className="gloabalFont title4" style={{ marginBottom: "24px" }}>
              Top 5 Holdings
            </p>
            {newData?.[topAllocationsStr || "top-holdings-by-allocation"]?.map(
              (t, i) => {
                const len = t?.product?.length + t?.assetClass?.length;
                let totalInstrumentLen = maxInstrumentLen - len;
                // console.log(totalInstrumentLen, len, "length");
                //  let totalarea=t?.fund.length -totalInstrumentLen;

                const breadcrumbs = [
                  <div
                    key="1"
                    className="printBreadsel"
                    style={{
                      // maxWidth: "450px",
                      // textOverflow: "ellipsis",
                      textWrap: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {t?.fund?.substring(0, totalInstrumentLen)}
                    {t?.fund?.length > totalInstrumentLen ? "..." : ""}
                  </div>,
                  <Link key="2" color="#000000" className="printBreadsel">
                    {firstLetterCapitalize(t?.assetClass)}
                  </Link>,

                  <Link key="3" color="#000000" className="printBreadsel">
                    {t?.product}
                  </Link>,
                ];
                return (
                  <div
                    className="printBorder element_border pt10 "
                    style={{ margin: "10px 0px", borderRadius: "3.5px" }}
                  >
                    <div style={{ paddingLeft: "10px", fontSize: "14px" }}>
                      {" "}
                      <Breadcrumbs
                        separator="›"
                        aria-label="breadcrumb"
                        className="printBread"
                      >
                        {breadcrumbs}
                      </Breadcrumbs>
                    </div>
                    <div className="p_flex t5holding">
                      <div style={{ flex: 2 }}>
                        <div className="cardTitle font600">Current Value</div>
                        <div className="font900 pt10">
                          {currencyFormat(
                            t?.currentValue,
                            undefined,
                            denomination,
                            ""
                          )}
                        </div>
                      </div>
                      <div style={{ flex: 2 }}>
                        <div className="cardTitle font600">% of Portfolio</div>
                        <div className="font900 pt10">
                          {t?.allocationPercent &&
                            roundNumber(t?.allocationPercent) + "%"}
                        </div>
                      </div>

                      <div style={{ flex: 1.5 }}>
                        <div className="cardTitle font600s">XIRR</div>
                        <div className="font900 pt10">
                          {/* {currencyFormat(t?.xirr, undefined, denomination, "")} */}
                          {roundNumberWithoutDecimal(Number(t?.xirr)) + " %"}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </PageContainer>
    </div>
  );
}
