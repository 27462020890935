import React, { useContext } from "react";
import Default from "../components/dashboard/defaultPages/Default";
import BankBookSummary from "../components/dashboard/products/bankBook/BankBookSummary";
import RecurrringDepositSummary from "../components/dashboard/products/recurringDeposit/RecurrringDepositSummary";
import { mfoFilterContext } from "../mfo_context/Mfo_Context";

function RecurringDepositSummaryPage() {
  const { setEntity } = useContext(mfoFilterContext);
  setEntity("rd");
  return (
    <Default>
      <RecurrringDepositSummary />
    </Default>
  );
}

export default RecurringDepositSummaryPage;
