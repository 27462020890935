import React from "react";
import TabBondSub from "./TabBondSub";
import useStyles from "../common/ProductsStyles";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import BarChart from "../../../charts/BarChart";
import { tooltip } from "../../../charts/barTooltip";

import { colors_s8 } from "../../../charts/colors";
import {
  divergingCommonProps,
  pie_onClick,
} from "../../../charts/nivo_objects";
import { fetchMaturitySummary } from "../../../../api";
import TableSummaryDetails from "../newDesigns/common/newSummaryDetailsTable/TableSummaryDetails";
import { useContext } from "react";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import { ReactComponent as UpArrow } from "../../../../assets/icons/upArrow.svg";
import { OnboardingContext } from "../../../../Context";
import { useEffect } from "react";
import moment from "moment";
import { useState } from "react";
import { currencyFormat } from "../../../../hooks/constantFunction";
import PageLoader from "../common/pageLoader";
import PieChart from "../../../charts/PieChart";
import { ReactComponent as BgCicularPieChart } from "../../../../assets/holding-summary/bgCicularPieChart.svg";
export default function BondsMaturity() {
  const classes = useStyles();
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const { userState } = OnboardingContext();
  const [loader, setLoader] = useState(true);
  const [bardata, setBarData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [defaultPieValue, setDefaultPieValue] = useState(null);
  const [toggle, settoggle] = useState(true);
  let diverging = {
    ...divergingCommonProps,

    minValue: 0,
    maxValue: 100,
    indexBy: "period",
    borderRadius: "3px",
  };
  diverging.axisBottom.legend = "Period";
  diverging.axisBottom.legendOffset = 65;
  diverging.margin = { top: 5, right: 20, bottom: 70, left: 80 };
  diverging.axisLeft.legend = "Percentage of bond ";
  const [sortOrderDetails, setSortOrderDetails] = useState({
    field: "",
    order: false,
  });

  const [piecolors, setPieColors] = useState([
    "#FF9446",
    "#82EBA1",
    "#509FF1",
    "#711CF1",
    "#F52D6A",
    "#B80000",
  ]);
  const [pieState, setPieState] = useState({
    title: "",
    value: "",
    colors: piecolors,
    disabled: [],
    hide: false,
  });

  const tableHead = [
    { value: "Product Name", field: "instrumentName" },
    { value: "Maturing Amount", field: "maturityAmount" },
    { value: "Time to Maturity", field: "timeToMaturity" },
  ];
  function sort(field, order) {
    function compare(a, b) {
      if (a[field] < b[field]) {
        return -1;
      }
      if (a[field] > b[field]) {
        return 1;
      }
      return 0;
    }
    let sortFunction =
      order == false
        ? (a, b) => (b[field] < a[field] ? -1 : b[field] > a[field] ? 1 : 0)
        : (a, b) => (a[field] < b[field] ? -1 : a[field] > b[field] ? 1 : 0);
    setTableData((prev) => {
      return tableData.sort(sortFunction);
    });
  }
  useEffect(() => {
    const clientId = userState.loginedUserData.id;
    let params = { ...getfilter_as_params() };

    setLoader(true);
    fetchMaturitySummary(clientId, params, "bonds")
      .then((res) => {
        let data = [];
        let keys = [];
        let fill = [];

        Object.keys(res?.result?.chartData).forEach((g, i) => {
          data.push({
            id: g,
            label: g,
            value: res?.result?.chartData[g],
            amount: res?.result?.chartData[g],
          });
          // keys.push(g);
          // fill.push({
          //   id: "c" + (i + 1),
          //   match: {
          //     id: g,
          //   },
          // });
        });

        console.log(res, data, "response");

        setLoader(false);
        // setBarData({ data: data, keys: keys, fill: fill });
        setBarData(data);
        setDefaultPieValue(res?.result?.chartData["Within 1 month"]);
        setTableData(res?.result?.maturityData);
      })
      .catch((err) => {
        setLoader(false);
      });
  }, [mfoFilter, toggle]);
  const modifiedResponse = tableData?.map((item) => {
    return {
      ...item,
      maturityAmount: currencyFormat(item.maturityAmount),
    };
  });
  return (
    <div>
      <TabBondSub
        title="Maturity"
        toggle={toggle}
        // settoggle={settoggle}
      ></TabBondSub>
      <br />
      <Grid xs={12}>
        {/* <BarChart
          colors={[
            "#FF9446",
            "#82EBA1",
            "#509FF1",
            "#711CF1",
            "#F52D6A",
            "#B80000",
          ]}
          data={bardata.data}
          fill={bardata.fill}
          keys={bardata.keys}
          config={{ height: "400px", padding: 0.6 }}
          commonprops={diverging}
        /> */}

        <div style={{ width: "100%", height: "500px", position: " relative" }}>
          <BgCicularPieChart
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              // top: "215px",
              // left: "56%",
            }}
          />
          <div
            className="flex_center tr20 "
            style={{
              overflow: "hidden",
              transform: "translate(-19px, 111px)",
            }}
          >
            <div style={{ width: "380px" }}>
              <PieChart
                config={{
                  innerRadius: "0.80",
                  cornerRadius: 0,
                  padAngle: 0,
                  legends: false,
                  disableArcLink: true,
                  minWith: "380px",
                  margin: { right: 80, top: 20, left: 80 },

                  chart: { width: 420, height: 340 },
                  arcLinkLabel: (d) => `${d.value} %`,
                  // disableArcLink:pieState.hide?true:false
                }}
                colors={pieState.colors}
                onClickEvent={{
                  click: pie_onClick,
                  set: setPieState,
                  cstate: pieState,
                  ncolors: piecolors,
                }}
                data={bardata}
              >
                <div
                  className="cen"
                  style={{
                    color: "#fff",
                    transform: "translate(69%, -47%)",
                    top: "37%",
                  }}
                >
                  <div className="center-info">
                    <div>
                      <div
                        className="glowcircle"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {/* <div className='circle_text2'>{pieState.value?pieState.value+" %":""}</div> */}
                        <div>
                          <div className="circle_text3">
                            {!pieState.title || pieState.title == ""
                              ? ""
                              : pieState.title}
                          </div>
                          {/* <div className="circle_text">
                            {!pieState.title || pieState.title == ""
                              ? ""
                              : pieState.title}
                          </div> */}
                          {/* <div className="circle_text3">
                            {!pieState.title || pieState.title == ""
                              ? "Total Balance"
                              : "Balance"}
                          </div> */}

                          {/* pieState.title */}
                          {/* <div className="circle_text">
                            {!pieState.title || pieState.title == ""
                              ? "Total Balance"
                              : ""}
                          </div> */}
                          <div className="circle_text2">
                            {
                              pieState.amount
                                ? "" + currencyFormat(pieState.amount) // currencyFormat(pieState.amount)
                                : ""
                              // tableColumns?.total
                            }
                          </div>
                          {/* <div
                            className="circle_text2"
                            style={{ paddingTop: "5px" }}
                          >
                            {pieState?.value
                              ? roundNumber(pieState.value) + "%"
                              : ""}
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </PieChart>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            marginTop: "-2%",
            marginBottom: "3%",
            marginRight: "3%",
          }}
        >
          {bardata?.map((m, i) => {
            return (
              <div style={{ display: "flex" }}>
                <div className={classes.graphBulletBonds}>
                  <svg height="25" width="25">
                    <circle
                      cx="12"
                      cy="12"
                      r="5"
                      stroke-width="3"
                      fill={pieState.colors[i]}
                    />
                    Sorry, your browser does not support inline SVG.
                  </svg>
                </div>
                <div className={`${classes.graphLegends}`}>{m.id} </div>
              </div>
            );
          })}
        </div>
        {/* <h2 className="text-center"  style={{ color:"#FFFFFF" }} >Graph Data</h2> */}
      </Grid>
      <br />
      <div className={classes.tableContainerAlign}>
        <TableSummaryDetails
          headerData={[
            {
              field: "instrumentName",
              title: "Instrument Name",
              show: true,
              align: "center",
              dontShowFirstColBorder: true, // ** NOTE TO REMOVE THE LEFT COLOR BORDER
            },
            {
              field: "maturityAmount",
              title: "Maturity Amount",
              show: true,
              align: "center",
            },
            {
              field: "timeToMaturity",
              title: "Time To Maturity",
              show: true,
              align: "right",
            },
          ]}
          data={modifiedResponse}
          hideNavButtons={true}
          customColorAndProd={null}
          summaryDetailsBodyMidCellsStyle={{ textAlign: "center" }}
          summaryDetailsLastCellStyle={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          alignHeaderColumn={(currentHeaderColumnIndex) => {
            console.log("currentHeaderColumnIndex", currentHeaderColumnIndex);
            const isFirstColumnHeader = currentHeaderColumnIndex === 0;
            return {
              display: "flex",
              justifyContent: isFirstColumnHeader ? "flex-start" : "center",
              alignItems: "center",
            };
          }}
        />
        <PageLoader showLoader={loader} />
      </div>
    </div>
  );
}
