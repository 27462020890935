import React, { useEffect, useState } from "react";
import TableSummaryDetails from "../../../products/newDesigns/common/newSummaryDetailsTable/TableSummaryDetails";
import { useNavigate, useSearchParams } from "react-router-dom";
import { saveAs } from "file-saver";
import { tableReportDownloadConfig, stdTableData } from "./constands";
import {
  dateFormatCommon2,
  dateFormatCommon3,
  dateFormatCommon4,
  handleKey,
} from "../../../../utils/common";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ReactComponent as Failed } from "../../../../../assets/icons/failed.svg";
import GetAppIcon from "@material-ui/icons/GetApp";
import useGetReportStatusTable from "../../../../../hooks/useGetReportStatusTable";
import PageLoader from "../../../products/common/pageLoader";
import {
  ApproveScheduleRequest,
  getPrintDataMongo,
  getScheduleList,
} from "../../../../../api";
import { Link } from "react-router-dom";
import ls from "localstorage-slim";

import {
  Button,
  DialogContentText,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import { AlertDialog } from "../../../common/AlertDialog";
import { OnboardingContext } from "../../../../../Context";
import CustomizedSnackbars from "../../../../common/CustomizedSnackbars";
// import fileDownload from "js-file-download";
// import axios from "axios";

export default function Reports() {
  const [header, setHeader] = useState([]);
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const scopes = JSON.parse(ls.get("scopes"));
  const { dispatch } = OnboardingContext();
  const [currentData, setCurrentData] = useState();
  let [searchParams, setSearchParams] = useSearchParams();
  // let denomination = "Lakhs";
  const created_at = searchParams.get("created_at");
  // function download(url, filename) {
  //   axios
  //     .get(url, {
  //       responseType: "blob",
  //     })
  //     .then((res) => {
  //       fileDownload(res.data, filename);
  //     });
  // }
  const [requests, setRequests] = useState([]);
  const download = (url) => {
    saveAs(url);
  };
  function getexcel(id) {
    getPrintDataMongo({
      // mongoDataId: "65604c3693a720206ae3411a",
      // mongoDataId: "65670ed0fda1a6d051a3304e",
      mongoDataId: id,
    }).then((res) => {
      download(res.data.data.result.download_link);
      console.log(res.data.data.result.s3_url, "res res");
    });
  }
  function getReportsListin() {
    setLoading(true);
    getScheduleList(created_at)
      .then((res) => {
        setLoading(false);
        // console.log(res, "schedule list res");
        if (res?.data?.data?.result?.length == 0) {
          return navigate(-1);
        }
        setRequests(res.data.data.result);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  useEffect(() => {
    getReportsListin();
  }, []);

  function routeToPdf(type) {
    switch (type) {
      case "Transaction Summary Report":
        return "/transaction";
      case "Holding Summary Report":
        return "/holding";
      case "Consolidated Securities Holding Summary Report":
        return "/security-holding";
      case "Client Portfolio Report":
        return "/client-portfolio-report";
      default:
        return "";
    }
  }

  function approve_reject(data) {
    return ApproveScheduleRequest(data);
  }
  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });

  const classes = useStyles();

  const reportBtn = {
    minWidth: "240px",
    justifyContent: "space-between",
    padding: "0px 25px",
  };
  return (
    <>
      <div style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
        <label className="tbl-Text">Client name</label>
        <label className="tbl-Text">Report Center</label>
      </div>
      {/* body */}
      {requests.map((r) => {
        return (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "20% 50% 30%",
              alignItems: "center",
              borderBottom: "0.5px solid var(--tint)",
              paddingBottom: "20px",
            }}
          >
            <label cal>{r.client_name}</label>
            <div
              className="flex-container"
              style={{
                gap: "10px",
                marginTop: "10px",
                flexWrap: "wrap",
              }}
            >
              {r?.reports?.map((report) => {
                return (
                  <button
                    className="reportBtn "
                    style={reportBtn}
                    onClick={() => {
                      if (report.export_type != "PDF") {
                        getexcel(report.mongo_data_id);
                      } else {
                        window.open(
                          "/print-report" +
                            routeToPdf(report?.report_type) +
                            `?id=${report?.mongo_data_id}`,
                          "_blank"
                        );
                      }
                    }}
                  >
                    {report?.report_type}{" "}
                    <GetAppIcon style={{ padding: "0px 6px" }} />
                  </button>
                );
              })}

              {/* <button
                className="reportBtn"
                style={reportBtn}
                onClick={() => {
                  if (r.export_type != "PDF") {
                    getexcel(r.mongo_data_id);
                  } else {
                    window.open(
                      "/print-report" +
                        routeToPdf(r.report_type) +
                        `?id=${r?.mongo_data_id}`,
                      "_blank"
                    );
                  }
                }}
              >
                Transactional Summary{" "}
                <GetAppIcon style={{ padding: "0px 6px" }} />
              </button> */}
            </div>
            <div
              className="flex-container "
              style={{
                gap: "10px",
                marginTop: "10px",
                justifyContent: "center",
              }}
            >
              <button
                className="reportBtn"
                style={{
                  background: "var(--border)",
                  padding: "0px 30px",
                  height: "46px",
                }}
                onClick={() => {
                  let ids = r?.reports?.map((report) => {
                    return report.id;
                  });
                  let data = {
                    clientId: r.client_id,
                    id: ids.join(","),
                    status: "approved",
                  };
                  setCurrentData(data);
                  setOpenDialog(true);
                }}
              >
                Approve
              </button>
              <button
                className="reportBtn"
                style={{
                  background: "var(--border)",
                  padding: "0px 30px",
                  height: "46px",
                }}
                onClick={() => {
                  let ids = r?.reports?.map((report) => {
                    return report.id;
                  });
                  let data = {
                    clientId: r.client_id,
                    id: ids.join(","),
                    status: "rejected",
                  };
                  setCurrentData(data);
                  setOpenDialog(true);
                }}
              >
                Reject
              </button>
            </div>
          </div>
        );
      })}
      <AlertDialog
        open={openDialog}
        setOpen={setOpenDialog}
        paperStyle={{
          width: "400px",
          padding: "30px 20px",
          background: "var( --secondaryBackground-color)",
        }}
        dialogTitle={() => (
          <DialogTitle id="alert-dialog-title">
            <div
              style={{
                alignTtems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            ></div>
            {/* <Typography
              style={{
                color: "#fff",
                fontFamily: "Avenir",
                fontSize: "18x",
                fontStyle: "normal",
                fontWeight: 800,
                lineHeight: "35.2px",
                textAlign: "center",
              }}
            >
              Your Report is being created
            </Typography> */}
          </DialogTitle>
        )}
        dialogContent={() => (
          <DialogContentText
            id="alert-dialog-description"
            style={{
              color: "#fff",
              textAlign: "center",
              fontFamily: "Avenir",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 350,
              lineHeight: "22px",
            }}
          >
            {` Are you sure you want to ${
              currentData?.status == "approved" ? "approve" : "reject"
            } the request?`}
          </DialogContentText>
        )}
        dialogFooter={() => (
          <>
            <button
              className="approveBtnDetailsAccordion"
              style={{ background: "#7043DE", width: "250px" }}
              onClick={() => {
                approve_reject(currentData)
                  .then((res) => {
                    setOpenDialog(false);
                    getReportsListin();
                    dispatch({
                      type: "UPDATE_SNACKBAR_DATA",
                      payload: {
                        success: true,
                        error: false,
                        showMsg: true,
                        message: res?.data?.message,
                      },
                    });
                  })
                  .catch((error) => {
                    setOpenDialog(false);
                    dispatch({
                      type: "UPDATE_SNACKBAR_DATA",
                      payload: {
                        success: false,
                        error: true,
                        showMsg: true,
                        message: error?.response?.data?.error?.message,
                      },
                    });
                  });
              }}
            >
              Yes
            </button>
            <button
              className="approveBtnDetailsAccordion"
              style={{ background: "#41297b", width: "250px", border: "none" }}
              onClick={() => {
                setOpenDialog(false);
              }}
            >
              No
            </button>
          </>
        )}
      />

      <CustomizedSnackbars />
      <PageLoader showLoader={loading} />
    </>
  );
}
