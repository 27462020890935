import React, { useState } from "react";
import HoldingPrint from "./subPrintPages/HoldingPrint";
import {
  ClientHolding,
  newpdfHoldingsRealEstate,
  newPdfSecurityHolding,
  newpdfHolding,
  newpdfHoldings,
  newpdfTransactions,
  newpdfHoldingsInsuranceNonUlip,
  newpdfHoldingsInsuranceUlip,
  newAifPdfHoldings,
  newDirectEquityPdfHoldings,
  newpdfHoldingsBankBook,
  newpdfTransactionsFundsInTransit,
  newpdfHoldingsFundInTransit,
  newpdfSecurityHoldings,
  newpdfHoldingSecurityBankBook,
  newPmsPdfHoldings,
} from "../Components/utils/staticData";
import { Link } from "react-router-dom";
import Overall_portfolioHolding from "./Overall_portfolioHolding";

export default function HoldingSecurityPdfPagev1({
  newData,
  denomination,
  reportType,
  setChanges,
  pdfInfo,
  key1,
  breadcrum,
}) {
  const colsWithSubtext = [
    "Bonds",
    "Real Estate Investment Trusts",
    "Infrastructure Investment Trusts",
    "AIF",
  ];
  console.log("security");
  const SECURITY_HOLDING_SUMMARY =
    "Consolidated Securities Holding Summary Report";
  function modifyFirstcol(p) {
    let colarr = renderCustomTableHeaderBasedOnReportType(p);
    colarr = JSON.parse(JSON.stringify(colarr));
    colarr[0]["subtext"] = <div className="s_text italic1">Weightage </div>;
    let index = colarr.findIndex((a, i) => {
      if (a?.title == "Current Value") {
        return true;
      }
    });
    let index2 = colarr.findIndex((a, i) => {
      if (a?.title == "Invested Value") {
        return true;
      }
    });

    if (colarr[index]?.title == "Current Value") {
      colarr[index]["subtext"] = (
        <div
          className="s_text italic1"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          Valuation As On
        </div>
      );
    }
    if (colsWithSubtext.includes(p)) {
      colarr[index2]["subdata"] = "principalPayback";
      colarr[index2]["subtext"] = (
        <div className="s_text italic1">Principal Payback</div>
      );
    }

    return colarr;
  }
  function renderCustomTableHeaderBasedOnReportType(product) {
    if (reportType === "clientHolding") {
      return ClientHolding;
    } else if (reportType === "transactionSummary") {
      return newpdfTransactions;
    } else {
      if (product == "Real Estate") {
        return newpdfHoldingsRealEstate;
      } else if (product == "Insurance (Non ULIP)") {
        return newpdfHoldingsInsuranceNonUlip;
      } else if (product == "Insurance (ULIP)") {
        return newpdfHoldingsInsuranceUlip;
      } else if (product == "Bank Book") {
        return newpdfHoldingSecurityBankBook;
      } else if (product == "Funds In Transit") {
        return newpdfHoldingsFundInTransit;
      }
      if (product == "AIF" || product == "Mutual Funds") {
        return newpdfSecurityHoldings;
      }
      if (product == "Direct Equity") {
        return newDirectEquityPdfHoldings;
      }
      if (product == "PMS") {
        return newPmsPdfHoldings;
      } else {
        return newAifPdfHoldings;
      }
    }
  }
  const breadcrumbs = [
    <Link key="1" color="#000000" className="printBreadsel">
      Holding Summary
    </Link>,
  ];
  // console.log(
  //   newData?.[key||"holdingSummary"] && Object?.keys(newData?.[key||"holdingSummary"]),
  //   "newData"
  // );
  function generateTablePages() {
    console.log("in security holding");
    const sortdata = newData?.["asset-wise-product-sort-order"];

    return Object.keys(sortdata).map((arr) => {
      let bread = [...breadcrumbs];
      bread.push(
        <Link
          underline="hover"
          key="2"
          color="
  #000000"
        >
          <span className="opc80"> Asset Class :</span>
          <span className="pdfselectedTxt pdfCaps">{arr}</span>
        </Link>
      );
      //   console.log("arr", arr);
      let count = 0;
      let filteredSort = sortdata?.[arr].filter((assetProduct) => {
        if (
          newData[key1 || "holdingSummary"]?.assetClass?.[arr]?.[
            assetProduct
          ] &&
          newData[key1 || "holdingSummary"]?.assetClass?.[arr]?.[assetProduct]
            ?.investments?.length != 0
        ) {
          return assetProduct;
        }
      });
      let len = filteredSort.length;
      return filteredSort?.map((p, pno) => {
        let breadcrum = [...bread];
        breadcrum.push(
          <Link
            underline="hover"
            key="2"
            color="
        #000000"
          >
            <span className="opc80">Product :</span>
            <span className="pdfselectedTxt">{p}</span>
          </Link>
        );
        let footerLegend = [];

        let dt = newData[key1 || "holdingSummary"]?.assetClass?.[arr]?.[p];
        let productLen = Object.keys(
          newData[key1 || "holdingSummary"]?.assetClass?.[arr]
        ).length;
        try {
          if (dt?.investments?.length == 0 || !dt?.investments) {
            return <></>;
          }
        } catch (err) {
          console.log(err, "err");
        }

        if (p == "Funds In Transit") {
          dt?.investments?.map((arr) => {
            arr.member = dt?.member;
          });
        }
        if (p.toLowerCase() == "unlisted equity") {
          dt?.investments?.map((arr) => {
            arr.CurrentValIndicator = true;
          });
          footerLegend.push({ symbol: "^", text: "Indicative Price" });
          footerLegend.push({ symbol: "**", text: "Promoter stake" });
        }
        if (p.toLowerCase() == "direct equity") {
          footerLegend.push({ symbol: "**", text: "Promoter stake" });
        }

        let grandTotal;
        let refForTotal = dt?.totalValues;
        if (p == "Bank Book") {
          dt?.investments.push({
            schemeName: "Total",
            currentValue: refForTotal.currentValue,
          });
        } else if (p == "Funds In Transit") {
          dt?.investments.push({
            member: "Total",
            amount: refForTotal.totalBalance,
          });
        } else {
          dt?.investments?.push({
            " % of Total Portfolio": refForTotal?.["% of Portfolio"],
            "Absolute TWRR": refForTotal?.["Absolute TWRR"],
            "Advised By": "--",
            "Annualized TWRR": refForTotal?.["Annualised TWRR"],
            "Asset Class": "--",
            currentValue: refForTotal?.["currentValue"],
            "Folio No": "--",
            unRealisedGainLoss: refForTotal?.["unrealisedGainLoss"],
            "ISIN No/Product Code": "--",
            schemeName: "Total",
            "Last Price Date": "--",
            "Market Price per Unit": "--",
            "Member Name": "--",
            otherIncome: refForTotal?.["otherIncome"],
            "Product Name": "--",
            "Quantity/Units": "--",
            totalCost:
              refForTotal?.["totalCost"] || refForTotal?.["investmentValue"],
            xirr: refForTotal?.["xirr"],
            coverAmount: refForTotal?.coverAmount,
            premium: refForTotal?.premium,
          });
          if (pno == len - 1) {
            newData?.["dashboard-asset-details"].data.find((a) => {
              if (arr == a.assetClass) {
                a.schemeName = "Grand Total";
                a.instruments =
                  newData[key1 || "holdingSummary"].totalInstruments?.[arr];
                a.noUniqueInstruments = true;
                a.totalCost = a.investedAmount;
                a.otherIncome = a.interestIncome;
                a.currentValue = a.portfolioValueAmount;

                a.bankName = "Grand Total";
                grandTotal = a;
              }
            });
            dt?.investments?.push(grandTotal);
          }
        }
        // if (
        //   i ==
        //   newData[key1 || "holdingSummary"].assetClass?.[arr][p]?.investments
        //     ?.length -
        //     1
        // ) {
        //   grandTotal =
        //     newData[key1 || "holdingSummary"].assetClass?.[arr][p]
        //       ?.totalValues;
        //   if (p == "Funds In Transit") {
        //   } else if (p == "Bank Book") {
        //     grandTotal.bankName = "Grand Total";
        //     grandTotal.balance = grandTotal.totalBalance;
        //     prod?.holdings?.push(grandTotal);
        //   } else {
        //     grandTotal.schemeName = "Grand Total";
        //     prod?.holdings?.push(grandTotal);
        //   }
        // }
        return (
          <div className="newPrint pagebreakTable">
            <HoldingPrint
              headerType={reportType == "executiveHolding" ? "11" : 13}
              title={breadcrum || "Holding Summary"}
              footerLegendText={footerLegend}
              pdfInfo={pdfInfo}
              asOnDate={refForTotal?.asOnDate}
              layout={1}
              title2={p == "Funds In Transit" ? "" : ""}
              title1={p}
              customTableHeader={modifyFirstcol(p)}
              subtext={""}
              customKey={p}
              type={"holdingSummary"}
              loading={false}
              setChanges={setChanges}
              breadcrumb={breadcrum}
              // error={data?.equity?.direct_equity_holding_summary?.error}
              customData={
                // data?.equity?.direct_equity_holding_summary?.data?.data?.result
                // stDataHolding

                dt?.investments
              }
              customTableClass={"printTableEvenAndOddRow"}
              hideTitle={true}
              hideTotalRow={true}
              denomination={denomination}
              // colmapping={objectFormatingWithClientHoldingTitleCOl}
            />
          </div>
        );
      });
    });
  }
  return (
    <>
      <div className="newPrint">
        <Overall_portfolioHolding
          newData={newData}
          denomination={denomination}
          // data={data}
          pdfInfo={pdfInfo}
        />
      </div>
      {newData?.[key1 || "holdingSummary"] && generateTablePages()}
    </>
  );
}
