import React from "react";
import TabBondSub from "./TabBondSub";
import useStyles from "../common/ProductsStyles";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  divergingCommonProps,
  pie_onClick,
} from "../../../charts/nivo_objects";
import BarChart from "../../../charts/BarChart";
import { fetchCreditRating } from "../../../../api";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import { OnboardingContext } from "../../../../Context";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useState } from "react";
import { ReactComponent as UpArrow } from "../../../../assets/icons/upArrow.svg";
import PageLoader from "../common/pageLoader";
import { currencyFormat } from "../../../../hooks/constantFunction";
import { roundNumber } from "../../../utils/common";
import { routes } from "../../../../routes";
import { ReactComponent as BgCicularPieChart } from "../../../../assets/holding-summary/bgCicularPieChart.svg";
import { colors_allocation } from "../../../charts/colors";
import PieChart from "../../../charts/PieChart";
import TableSummaryDetails from "../newDesigns/common/newSummaryDetailsTable/TableSummaryDetails";
import WithSortingTableSummaryDetails from "../newDesigns/common/newSummaryDetailsTable/withSortingTableSummaryDetails";
export default function BondsCredit() {
  const { mfoFilter, getfilter_as_params } = useContext(mfoFilterContext);
  const { userState } = OnboardingContext();
  const [bardata, setBarData] = useState({ data: [], keys: [], fill: [] });
  const [tableData, setTableData] = useState([]);
  const [checkAdvisor, setCheckAdvisor] = useState({ CRISIL: true });
  const [totalMarketValue, setTotalMarketValue] = useState(0);
  const [load, setLoad] = useState(false);
  const [toggle, settoggle] = useState(true);
  const navigate = useNavigate();
  const colors = [
    "#FF9446",
    "#82EBA1",
    "#509FF1",
    "#711CF1",
    "#F52D6A",
    "#B80000",
  ];
  const [piecolors, setPieColors] = useState([
    "#FF9446",
    "#82EBA1",
    "#509FF1",
    "#711CF1",
    "#F52D6A",
    "#B80000",
  ]);

  const [pieState, setPieState] = useState({
    title: "",
    value: "",
    colors: piecolors,
    disabled: [],
    hide: false,
  });
  let tooltip = (data) => {
    return (
      <div
        style={{
          padding: 7,
          color: "#1F1C40",
          background: "#EAE7ED",
          borderRadius: "10px",
        }}
      >
        {/* <br /> */}

        <div
          style={{
            display: "flex",
            fontFamily: "Avenir",
            paddingLeft: "25px",
            paddingRight: "19px",
            lineHeight: "24px",
          }}
        >
          {/* <div style={{background:data.point.serieColor,width:"20px",height:"20px",borderRadius:"10px",marginRight:"5px"}}></div> */}
          {data.id}: <b>{roundNumber(data.value)}</b>
        </div>

        <div
          style={{
            marginLeft: "25px",
            fontFamily: "Avenir",
            paddingRight: "19px",
            lineHeight: "24px",
          }}
        >
          {/* Date: <b>{`${data.point.data.x}`}</b> */}
        </div>
      </div>
    );
  };

  const credtype = {
    CRISIL: "CRISIL_RAT",
    CARE: "CARE_RAT",
    ICRA: "ICRA_RAT",
    FITCH: "FITCH_RAT",
    BRICK: "BRICK_RAT",
  };
  const classes = useStyles();
  let diverging = {
    ...divergingCommonProps,
    minValue: 0,
    maxValue: 100,
    indexBy: "period",
    valueFormat: (value, id) => {},
  };
  diverging.axisBottom.legend = " ";
  diverging.axisLeft.legend = "percentage of portfolio ";
  const [sortOrderDetails, setSortOrderDetails] = useState({
    field: "",
    order: false,
  });
  const tableHead = [
    { value: "Product Name", field: "schemeName" },
    { value: "Investment Amt", field: "investmentValue" },
    { value: "Interest rate", field: "interestRate" },
    { value: "Bond Quality", field: "bondRating" },
    { value: "Market Value", field: "marketValue" },
  ];
  function sort(field, order) {
    function compare(a, b) {
      if (a[field] < b[field]) {
        return -1;
      }
      if (a[field] > b[field]) {
        return 1;
      }
      return 0;
    }
    let sortFunction =
      order == false
        ? (a, b) => (b[field] < a[field] ? -1 : b[field] > a[field] ? 1 : 0)
        : (a, b) => (a[field] < b[field] ? -1 : a[field] > b[field] ? 1 : 0);
    setTableData((prev) => {
      return tableData?.sort(sortFunction);
    });
  }
  useEffect(() => {
    const clientId = userState.loginedUserData.id;
    let params = { ...getfilter_as_params() };

    if (Object.keys(checkAdvisor).length > 0) {
      params["agency"] = credtype[Object.keys(checkAdvisor)[0]];
    }
    setLoad(true);
    fetchCreditRating(clientId, params, "bonds", toggle)
      .then((res) => {
        // let data = { period: "Credit Rating" }; //bar data
        let data = []; //piechart data
        let keys = [];
        let fill = [];
        let newList = [];
        res?.result?.graphData.forEach((g, i) => {
          fill.push({
            id: "c" + (i + 1),
            match: {
              id: g.bondRating,
            },
          });
          // data[g.bondRating] = g.bondRatingValue; //bar data

          //piechart data
          data.push({
            id: g.bondRating,
            label: g.bondRating,
            value: g.bondRatingPercentage?.toFixed(2),
            color: colors_allocation[i],
            amount: g.bondRatingValue?.toFixed(2),
          });

          keys.push(g.bondRating);
        });

        res?.result?.creditRating.forEach((credit) => {
          newList.push({
            bondRating: credit?.bondRating,
            interestRate: credit?.interestRate,
            investmentValue: currencyFormat(credit?.investmentValue),
            isin: credit?.isin,
            marketValue: currencyFormat(credit?.marketValue),
            schemeName: credit?.schemeName,
          });
          if (credit?.schemeName === "Total") {
            setTotalMarketValue(credit?.marketValue);
          }
        });
        //for bar graph data:[data]

        setBarData({ data: data, keys: keys, fill: fill });

        setTableData(newList);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
      });
  }, [mfoFilter, checkAdvisor, toggle]);

  console.log("ramtable", tableData);
  return (
    <div>
      <TabBondSub
        title="Credit Rating"
        setCheckAdvisor={setCheckAdvisor}
        checkAdvisor={checkAdvisor}
        toggle={toggle}
        // settoggle={settoggle}
      ></TabBondSub>
      <br />
      <Grid xs={12}>
        {/* <h2 className="text-center"  style={{ color:"#FFFFFF" }} >Graph Data</h2> */}

        {/* <BarChart
          enableLabel={false}
          fill={bardata.fill}
          config={{ height: "400px", padding: 0.88 }}
          keys={bardata.keys}
          tooltip={tooltip}
          colors={[
            "#FF9446",
            "#82EBA1",
            "#509FF1",
            "#711CF1",
            "#F52D6A",
            "#B80000",
          ]}
          commonprops={diverging}
          data={bardata.data}
        /> */}

        <div style={{ width: "100%", height: "500px", position: " relative" }}>
          <BgCicularPieChart
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              // top: "215px",
              // left: "56%",
            }}
          />
          <div
            className="flex_center tr20 "
            style={{
              overflow: "hidden",
              transform: "translate(-19px, 111px)",
            }}
          >
            <div style={{ width: "380px" }}>
              <PieChart
                config={{
                  innerRadius: "0.80",
                  cornerRadius: 0,
                  padAngle: 0,
                  legends: false,
                  disableArcLink: true,
                  minWith: "380px",
                  margin: { right: 80, top: 20, left: 80 },

                  chart: { width: 420, height: 340 },
                  arcLinkLabel: (d) => `${d.value} %`,
                  // disableArcLink:pieState.hide?true:false
                }}
                colors={pieState.colors}
                onClickEvent={{
                  click: pie_onClick,
                  set: setPieState,
                  cstate: pieState,
                  ncolors: piecolors,
                }}
                data={bardata.data}
              >
                <div
                  className="cen"
                  style={{
                    color: "#fff",
                    transform: "translate(69%, -47%)",
                    top: "37%",
                  }}
                >
                  <div className="center-info">
                    <div>
                      <div
                        className="glowcircle"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {/* <div className='circle_text2'>{pieState.value?pieState.value+" %":""}</div> */}
                        <div>
                          <div className="circle_text3">
                            {!pieState.title || pieState.title == ""
                              ? "Total Market value"
                              : "CREDIT RATING"}
                          </div>
                          <div className="circle_text">
                            {!pieState.title || pieState.title == ""
                              ? ""
                              : pieState.title}
                          </div>

                          {/* pieState.title */}
                          {/* <div className="circle_text">
                            {!pieState.title || pieState.title == ""
                              ? "Total Balance"
                              : ""}
                          </div> */}
                          <div className="circle_text2">
                            {pieState.amount
                              ? "" + currencyFormat(pieState.amount)
                              : "" + totalMarketValue != null
                              ? currencyFormat(totalMarketValue)
                              : "-- "}
                          </div>
                          {/* <div
                            className="circle_text2"
                            style={{ paddingTop: "5px" }}
                          >
                            {pieState?.value
                              ? roundNumber(pieState.value) + "%"
                              : ""}
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </PieChart>
            </div>
          </div>
        </div>

        {/* <div
            className="p_flex"
            style={{ justifyContent: "flex-end", paddingRight: "10px" }}
          >
            {bardata?.keys?.map((d, i) => {
              return (
                <div key={i} className="p_flex a_cen">
                  {" "}
                  <svg height="25" width="25">
                    <circle
                      cx="12"
                      cy="12"
                      r="5"
                      stroke-width="3"
                      fill={colors[i]}
                    />
                    Sorry, your browser does not support inline SVG.
                  </svg>{" "}
                  {d.name}
                </div>
              );
            })}
          </div> */}
      </Grid>
      <br />
      <div className={classes.tableContainerAlign}>
        {/* <Table>
          <TableHead
            style={{
              background: "#1D1B36",
              borderRadius: "20px",
              color: "#A468FF",
            }}
          >
            <TableRow className="tableRowStyle">
              {tableHead.map((val) => (
                <TableCell
                  style={{ borderBottom: "none", background: "#1D1B36" }}
                  className={`${classes.tableHeadText} thStyle`}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent:
                        val?.value == "Product Name"
                          ? "flex-start"
                          : "flex-end",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    <span style={{ color: "#fff" }}>{val?.value}</span>
                    {val.value == "Bond Quality" && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <UpArrow
                          className={
                            sortOrderDetails.field == val?.field &&
                            sortOrderDetails.order == true
                              ? "svgSelected"
                              : ""
                          }
                          width={"10px"}
                          height={"10px"}
                          onClick={() => {
                            sort(`${val?.field}`, true);
                            setSortOrderDetails((prev) => {
                              return {
                                ...prev,
                                field: val?.field,
                                order: true,
                              };
                            });
                          }}
                        />
                        <UpArrow
                          className={
                            sortOrderDetails.field == val?.field &&
                            sortOrderDetails.order == false
                              ? "svgSelected"
                              : ""
                          }
                          height={"10px"}
                          width={"10px"}
                          style={{
                            transform: "rotate(180deg)",
                          }}
                          onClick={() => {
                            sort(`${val?.field}`, false);
                            setSortOrderDetails((prev) => {
                              return {
                                ...prev,
                                field: val?.field,
                                order: false,
                              };
                            });
                          }}
                        />
                      </div>
                    )}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableCell
              style={{
                height: "5px",
                padding: "0px",
              }}
            ></TableCell>
            {tableData?.map((t) => {
              return (
                <TableRow>
                  {tableHead.map((d) => {
                    if (
                      d.field === "investmentValue" ||
                      d.field === "marketValue"
                    ) {
                      return (
                        <TableCell
                          align="right"
                          className={classes.tableBodyText}
                        >
                          {currencyFormat(t[d.field])}
                        </TableCell>
                      );
                    } else if (d.field === "interestRate") {
                      return (
                        <TableCell
                          align="right"
                          className={classes.tableBodyText}
                        >
                          {`${roundNumber(t[d.field])} %`}
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell
                          onClick={() => {
                            if (d.field == "schemeName") {
                              navigate(
                                `${routes.bondsDetails}/?isin=` + t.isin
                              );
                            }
                          }}
                          align={d.field == "schemeName" ? "left" : "right"}
                          className={
                            d.field == "schemeName"
                              ? classes.tableBodyText + " pointer"
                              : classes.tableBodyText
                          }
                        >
                          {t[d.field]}
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table> */}

        <WithSortingTableSummaryDetails
          headerData={[
            {
              field: "schemeName",
              title: "INSTRUMENT NAME",
              show: true,
              align: "center",
              dontShowFirstColBorder: true, // ** NOTE TO REMOVE THE LEFT COLOR BORDER
            },
            {
              field: "bondRating",
              title: "Credit Rating",
              show: true,
              align: "center",
              sortable: true,
            },
            {
              field: "investmentValue",
              title: "Invested Value",
              show: true,
              align: "right",
            },
            {
              field: "marketValue",
              title: "Current Value",
              show: true,
              align: "right",
            },
          ]}
          data={tableData}
          hideNavButtons={true}
          customColorAndProd={null}
          summaryDetailsBodyMidCellsStyle={{ textAlign: "center" }}
          summaryDetailsLastCellStyle={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          alignHeaderColumn={(currentHeaderColumnIndex) => {
            console.log("currentHeaderColumnIndex", currentHeaderColumnIndex);
            const isFirstColumnHeader = currentHeaderColumnIndex === 0;
            return {
              display: "flex",
              justifyContent: isFirstColumnHeader ? "flex-start" : "center",
              alignItems: "center",
            };
          }}
        />
        <PageLoader showLoader={load} />
      </div>
    </div>
  );
}
