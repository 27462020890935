const align = "center";
const transactionTableConfig = [
  { field: "scrip_name", title: "Script Name", show: true, align: align },
  { field: "advisor", title: "Advised by", show: true, align: align },
  { field: "executed_by", title: "Executed By", show: true, align: align },
  { field: "pan", title: "PAN", show: true, align: align },
  { field: "amount", title: "Value", show: true, align: align },
  { field: "isin", title: "Product Code", show: true, align: align },
  { field: "exchange", title: "Exchange", show: true, align: align },
  { field: "asset_type", title: "Asset Type", show: true },
  {
    field: "product_sub_category",
    title: "Product Sub Category",
    show: true,
    align: align,
  },
  { field: "stock_symbol", title: "Stock Symbol", show: true, align: align },
  {
    field: "transaction_date",
    title: "Transaction date",
    show: true,
    align: align,
  },
  {
    field: "transaction_type",
    title: "Transaction Type",
    show: true,
    align: align,
  },
  { field: "units", title: "Quantity", show: true },
  {
    field: "gross_rate_per_unit",
    title: "Gross Rate Per Unit (Actuals)",
    show: true,
    align: align,
  },
  { field: "account_no", title: "Account No", show: true, align: align },

  {
    field: "transaction_type_id",
    title: "Transaction Id",
    show: true,
    align: align,
  },
  {
    field: "brokerage_per_unit",
    title: "Brokerage Per Unit",
    show: true,
    align: align,
  },
  {
    field: "gst_stt_other_charges",
    title: "GST+STT+Other Charges",
    show: true,
  },
  {
    field: "gross_rate_per_unit",
    title: "Net Rate Per Unit (Actuals)",
    show: true,
  },
  { field: "dividend_received", title: "Dividend Received", show: true },
  { field: "ltp", title: "Last Traded Price(LTP)", show: true },
  {
    field: "cumulative_quantity",
    title: "Cumulative Quantity",
    show: true,
  },
  //   { field: "interest", title: "Interest", show: true },

  //   { field: "other_income", title: "Other Income", show: true },
  //   { field: "management_fees", title: "Management Fees", show: true },
  //   {
  //     field: "securities_transaction_tax",
  //     title: "Security Transaction Tax",
  //     show: true,
  //   },

  //   { field: "other_expense", title: "Other Expense", show: true },
];

const corporateActionsTransactionTableConfig = [
  { field: "scrip_name", title: "Script Name", show: true, align: align },
  { field: "advisor", title: "Advised by", show: true, align: align },
  { field: "executed_by", title: "Executed By", show: true, align: align },
  { field: "pan", title: "PAN", show: true, align: align },
  { field: "amount", title: "Value", show: true, align: align },
  { field: "isin", title: "Product Code", show: true, align: align },
  { field: "exchange", title: "Exchange", show: true, align: align },
  { field: "asset_type", title: "Asset Type", show: true },
  {
    field: "product_sub_category",
    title: "Product Sub Category",
    show: true,
    align: align,
  },
  { field: "stock_symbol", title: "Stock Symbol", show: true, align: align },
  {
    field: "transaction_date",
    title: "Transaction date",
    show: true,
    align: align,
  },
  {
    field: "transaction_type",
    title: "Transaction Type",
    show: true,
    align: align,
  },
  // START
  {
    field: "corp_action",
    title: "Corporate Action",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "corp_action_effects",
    title: "Effects",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "corporate_action_value",
    title: "Value (Actuals)",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "ratio",
    title: "Ratio",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "holdingPeriod",
    title: "Holding Period",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  // END
  { field: "units", title: "Quantity", show: true },
  {
    field: "gross_rate_per_unit",
    title: "Gross Rate Per Unit (Actuals)",
    show: true,
    align: align,
  },
  { field: "account_no", title: "Account No", show: true, align: align },

  {
    field: "transaction_type_id",
    title: "Transaction Id",
    show: true,
    align: align,
  },
  {
    field: "brokerage_per_unit",
    title: "Brokerage Per Unit",
    show: true,
    align: align,
  },
  {
    field: "gst_stt_other_charges",
    title: "GST+STT+Other Charges",
    show: true,
  },
  {
    field: "gross_rate_per_unit",
    title: "Net Rate Per Unit (Actuals)",
    show: true,
  },
  { field: "dividend_received", title: "Dividend Received", show: true },
  { field: "ltp", title: "Last Traded Price(LTP)", show: true },
  { field: "remarks", title: "Remarks", show: true, tagPresent: false },
  //   { field: "interest", title: "Interest", show: true },

  //   { field: "other_income", title: "Other Income", show: true },
  //   { field: "management_fees", title: "Management Fees", show: true },
  //   {
  //     field: "securities_transaction_tax",
  //     title: "Security Transaction Tax",
  //     show: true,
  //   },

  //   { field: "other_expense", title: "Other Expense", show: true },
];
const holdingTableConfig = [
  //   ...transactionTableConfig,
  {
    field: "scripName",
    title: "Script Name",
    show: true,
    align: align,
    tagPresentPromoterStake: "isPromoterStake",
  },
  { field: "memberName", title: "Member Name", show: true, align: align },
  { field: "advisor", title: "Advisor", show: true, align: align },
  { field: "isin", title: "Product Code", show: true, align: align },
  { field: "exchange", title: "Exchange", show: true, align: align },
  { field: "presentValue", title: "Present Value", show: true, align: align },
  {
    field: "investmentValue",
    title: "Investment Value",
    show: true,
    align: align,
  },
  { field: "assetType", title: "Asset Type", show: true },
  {
    field: "productSubCategory",
    title: "Product Sub Category",
    show: true,
    align: "center",
  },
  { field: "stockSymbol", title: "Stock Symbol", show: true, align: align },
  // {
  //   field: "transactionDate",
  //   title: "Transaction date",
  //   show: false,
  //   align: align,
  // },
  // {
  //   field: "transactionType",
  //   title: "Transaction Type",
  //   show: false,
  //   align: align,
  // },
  // { field: "units", title: "Quantity", show: false },
  { field: "holdingUnits", title: "Units", show: true },
  {
    field: "nav",
    title: "Current Market Price (Actuals)",
    show: true,
    align: align,
  },
  // { field: "accountNo", title: "Account No", show: false, align: align },

  // {
  //   field: "transactionTypeId",
  //   title: "Transaction Id",
  //   show: false,
  //   align: align,
  // },
  // {
  //   field: "brokeragePerUnit",
  //   title: "Brokerage Per Unit",
  //   show: false,
  //   align: align,
  // },
  // {
  //   field: "gstSttOtherCharges",
  //   title: "GST+STT+Other Charges",
  //   show: false,
  // },
  // {
  //   field: "grossRatePerUnit",
  //   title: "Net Rate Per Unit (Actuals)",
  //   show: false,
  // },
  // { field: "dividendReceived", title: "Dividend Received", show: false },
  // { field: "ltp", title: "Last Traded Price(LTP)", show: false },
  {
    field: "percentageOfTotalPortfolio",
    title: "% of Total Portfolio",
    show: true,
    align: align,
  },
  { field: "unRealisedGainLoss", title: "Unrealized Gain/Loss", show: true },
  // { field: "realisedGainLoss", title: "Realized Gain/Loss", show: false },
  {
    field: "interestIncome",
    title: "Other Income",
    show: true,
  },
  { field: "totalGainLoss", title: "Total Gain/Loss", show: true },
  //   { field: "presentValue", title: "Present Value", show: true },
  //   { field: "totalGainLoss", title: "Total Gain", show: true },
  { field: "xirr", title: "XIRR (%)", show: true },
  { field: "absoluteTwrr", title: "Absolute TWRR (%)", show: true },
  { field: "annualisedTwrr", title: "Annualized TWRR (%)", show: true },
  //   {
  //     field: "avgHoldingPeriod",
  //     title: "Holding Period (In Years)",
  //     show: true,
  //   },
];

module.exports = {
  transactionTableConfig,
  corporateActionsTransactionTableConfig,
  holdingTableConfig,
};
