import { bankbookConfig } from "../../REITS/reitsConfig";
import {
  bankHoldingConfig,
  bankTransactionConfig,
} from "../../bankBook/bankBoonConfig";

export const hardData = {
  disha: {
    equity: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    pms: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    mutualFunds: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    directEquity: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    debt: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    structuredProducts: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
  },
  riha: {
    equity: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    pms: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    mutualFunds: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    directEquity: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    debt: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    structuredProducts: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
  },
  raj: {
    equity: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    pms: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    mutualFunds: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    directEquity: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    debt: {
      marketValue: "151",
      portfolioPercentage: null,
    },
    structuredProducts: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
  },
  asif: {
    equity: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    pms: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    mutualFunds: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    directEquity: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    debt: {
      marketValue: "151",
      portfolioPercentage: null,
    },
    structuredProducts: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
  },
  azad: {
    equity: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    pms: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    mutualFunds: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    directEquity: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    debt: {
      marketValue: "151",
      portfolioPercentage: null,
    },
    structuredProducts: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
  },
  azad2: {
    equity: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    pms: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    mutualFunds: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    directEquity: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    debt: {
      marketValue: "151",
      portfolioPercentage: null,
    },
    structuredProducts: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
  },
  azad5: {
    equity: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    pms: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    mutualFunds: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    directEquity: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    debt: {
      marketValue: "151",
      portfolioPercentage: null,
    },
    structuredProducts: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
  },
  azad2: {
    equity: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    pms: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    mutualFunds: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    directEquity: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    debt: {
      marketValue: "151",
      portfolioPercentage: null,
    },
    structuredProducts: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
  },
  azad24: {
    equity: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    pms: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    mutualFunds: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    directEquity: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    debt: {
      marketValue: "151",
      portfolioPercentage: null,
    },
    structuredProducts: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
  },
  azad28: {
    equity: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    pms: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    mutualFunds: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    directEquity: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
    debt: {
      marketValue: "151",
      portfolioPercentage: null,
    },
    structuredProducts: {
      marketValue: "151",
      portfolioPercentage: "10",
    },
  },
};

export const TableHeadingConfig = {};

export const tableConfig = {
  equity: {
    show: true,
    title: "Equity",
  },
  pms: {
    show: true,
    title: "PMS",
  },
  mutualFunds: {
    show: true,
    title: "Mutual Funds",
  },
  directEquity: {
    show: true,
    title: "Direct Equity",
  },
  debt: {
    show: true,
    title: "Debt",
  },
  structuredProducts: {
    show: true,
    title: "Structured Products",
  },
};

export const topPerformerPage = {
  title: "Top 5 Performers",
  content: () => (
    <>
      {/* Holdings are the contents of an investment portfolio held by an individual
      or an entity,<br></br> such as a mutual fund or a pension fund. Portfolio
      holdings may encompass a wide */}
    </>
  ),
};

export const cashflowPage = {
  title: "Cash Flow",
  content: () => (
    <>
      Holdings are the contents of an investment portfolio held by an individual
      or an entity,<br></br> such as a mutual fund or a pension fund. Portfolio
      holdings may encompass a wide
    </>
  ),
};

export const bottomPerformerPage = {
  title: "Bottom 5 Performers",
};
export const excessPerformanceTable = [
  {
    field: "assetClass",
    title: "Asset Class",
    align: "left",
    color: "",
    show: true,
  },
  {
    field: "investedAmount",
    title: "Invested Value",
    customStyle: { width: "max-content" },
    show: true,
  },
  {
    field: "portfolioValueAmount",
    title: "Current Value",
    align: "center",
    customStyle: { width: "max-content" },
    show: true,
  },
  {
    field: "totalGainLoss",
    title: "Total Gain/Loss",
    align: "center",
    show: true,
  },
  {
    field: "portfolioXirr",
    title: "XIRR",
    align: "center",
    show: true,
  },
  {
    field: "benchmarkXirr",
    title: "Benchmark XIRR",
    align: "center",
    show: true,
  },
  {
    field: "excessPerformance",
    title: "Excess XIRR",
    align: "center",
    customStyle: { width: "max-content" },
    show: true,
  },
  {
    field: "absoluteTwrr",
    title: "Absolute TWRR",
    align: "center",
    show: true,
    twrr: true,
  },
  {
    field: "benchmarkAbsoluteTwrr",
    title: "Benchmark Absolute TWRR",
    align: "center",
    show: true,
    twrr: true,
  },
  {
    field: "absoluteTwrrExcessPerformance",
    title: "Excess Absolute TWRR",
    align: "center",
    show: true,
    twrr: true,
  },
  {
    field: "annualisedTwrr",
    title: "Annualized TWRR",
    align: "center",
    show: true,
    twrr: true,
  },
  {
    field: "benchmarkAnnualisedTwrr",
    title: "Benchmark Annualized TWRR",
    align: "center",
    show: true,
    twrr: true,
  },
  {
    field: "annualisedTwrrExcessPerformance",
    title: "Excess AnnualizedTWRR",
    align: "center",
    show: true,
    twrr: true,
  },
  {
    field: "benchmarkName",
    title: "Benchmark",
    align: "left",
    show: true,
    twrr: true,
  },
];

export const advisorModalTable = [
  {
    field: "advisor",
    title: "Advisor Name",
    align: "left",
    color: "",
    show: true,
  },
  {
    field: "totalPortfolioPercentage",
    title: "Allocation(%)",
    customStyle: { width: "max-content" },
    show: true,
  },
  {
    field: "investedAmount",
    title: "Investment Value",
    align: "center",
    customStyle: { width: "max-content" },
    show: true,
  },
  {
    field: "portfolioValueAmount",
    title: "Current Value",
    align: "center",
    show: true,
  },
  {
    field: "interestIncome",
    title: "Other Income ",
    align: "center",
    show: true,
  },
  {
    field: "unRealisedGainLoss",
    title: "Unrealised Gain/Loss",
    align: "center",
    show: true,
  },
  {
    field: "xirr",
    title: "XIRR (%)",
    align: "center",
    customStyle: { width: "max-content" },
    show: true,
  },
  {
    field: "absoluteTwrr",
    title: "Absolute TWRR(%)",
    align: "center",
    show: true,
    twrr: true,
  },
  {
    field: "annualisedTwrr",
    title: "Annualised TWRR(%)",
    align: "center",
    show: true,
    twrr: true,
  },
];
export const topPerformanceTable = [
  {
    field: "fund",
    title: "Instrument Name",
    align: "left",
    color: "",
    show: true,
  },
  // {
  //   field: "product",
  //   title: "PRODUCT",
  //   customStyle: { width: "max-content" },
  //   show: true,
  // },
  {
    field: "contributionPercent",
    title: "Contribution (%)",
    align: "right",
    customStyle: { width: "max-content" },
    show: true,
  },
  {
    field: "investmentAmount",
    title: "Invested Value",
    align: "center",
    show: true,
  },
  {
    field: "currentValue",
    title: "Current Value",
    align: "center",
    show: true,
  },
  {
    field: "xirr",
    title: "XIRR (%)",
    align: "center",
    show: true,
  },
  {
    title: "Total Gain/Loss",
    field: "totalGainLoss",
    align: "center",
    customStyle: { width: "max-content" },
    show: true,
  },
];
export const topBottomPerformanceTable = [
  {
    field: "fund",
    title: "Instrument Name",
    align: "left",
    customStyle: { width: "max-content" },
    color: "",
    show: true,
  },
  {
    field: "contributionPercent",
    title: "Contribution (%)",
    // customStyle: { width: "max-content" },
    show: true,
  },
  {
    field: "investmentAmount",
    title: "Invested Value",
    align: "center",
    show: true,
  },
  {
    field: "currentValue",
    title: "Current Value",
    align: "center",
    show: true,
  },
  // {
  //   field: "allocationPercent",
  //   title: "Allocation (%)  ",
  //   align: "right",

  //   show: true,
  // },
  {
    field: "xirr",
    title: "XIRR (%)",
    align: "center",
    show: true,
  },
  {
    title: "Total Gain/Loss",
    field: "totalGainLoss",
    align: "center",
    customStyle: { width: "max-content" },
    show: true,
  },
];
export const assetVbenchmark = [
  {
    field: "assetClass",
    title: "Asset Class",
    align: "left",
    color: "",
    show: true,
  },

  {
    field: "mtdTotalGainLoss",
    percent: "mtdAbsoluteReturn",
    title: "mtd returns",
    align: "right",
    customStyle: { width: "max-content" },
    show: true,
  },
  {
    field: "qtdTotalGainLoss",
    title: "Qtd returns",
    percent: "qtdAbsoluteReturn",
    align: "right",
    customStyle: { width: "max-content" },
    show: true,
  },
  {
    field: "ytdTotalGainLoss",
    title: "YTD returns",
    align: "right",
    percent: "ytdAbsoluteReturn",
    show: true,
  },
  {
    field: "itdTotalGainLoss",
    title: "ITD returns",
    percent: "itdAbsoluteReturn",
    align: "right",
    show: true,
  },
];
export const excessPerformance = [
  {
    field: "assetClass",
    title: "Asset Class",
    align: "left",
    color: "",
    show: true,
  },

  {
    field: "investedAmount",

    title: "Invested Value",
    align: "right",
    customStyle: { width: "max-content" },
    show: true,
  },
  {
    field: "portfolioValueAmount",
    title: "Current Value",

    align: "right",
    customStyle: { width: "max-content" },
    show: true,
  },
  {
    field: "totalGainLoss",
    title: "Total Gain/Loss",
    align: "right",
    percent: "ytdAbsoluteReturn",
    show: true,
  },
  {
    field: "xirr",
    title: "XIRR",
    percent: "itdAbsoluteReturn",
    align: "right",
    show: true,
  },
  {
    field: "benchmarkXirr",
    title: "Benchmark Xirr",
    percent: "itdAbsoluteReturn",
    align: "right",
    show: true,
  },
  {
    field: "excessPerformance",
    title: "Excess Xirr",
    percent: "itdAbsoluteReturn",
    align: "right",
    show: true,
  },
];

export const benchmarkVasset = [
  {
    field: "benchmarkName",
    title: "Benchmark",
    align: "left",
    color: "",
    show: true,
  },

  {
    field: "mtdAbsoluteReturn",
    title: "mtd returns",
    align: "right",
    customStyle: { width: "max-content" },
    show: true,
  },
  {
    field: "qtdAbsoluteReturn",
    title: "Qtd returns",

    align: "right",
    customStyle: { width: "max-content" },
    show: true,
  },
  {
    field: "ytdAbsoluteReturn",
    title: "YTD returns",
    align: "right",

    show: true,
  },
  {
    field: "itdAbsoluteReturn",
    title: "ITD returns",

    align: "right",
    show: true,
  },
];
export const newtopPerformancTable = [
  {
    field: "fund",
    title: "Instrument Name",
    align: "left",
    color: "",
    show: true,
  },
  {
    field: "contributionPercent",
    title: "Allocation (%)",
    align: "center",
    customStyle: { width: "max-content" },
    show: true,
  },

  {
    field: "quantity",
    title: "Quantity",
    align: "right",
    show: true,
  },

  {
    field: "investmentAmount",
    title: "Invested Value (₹)",
    align: "center",
    show: true,
  },
  {
    field: "currentValue",
    title: "Current Value (₹)",
    align: "center",
    show: true,
  },
  {
    field: "xirr",
    title: "XIRR (%)",
    align: "center",
    show: true,
  },
  {
    field: "lastPriceDate",
    title: "Last Price Date",
    align: "center",
    show: true,
  },
];
export const topHoldingByAllocationTable = [
  {
    field: "fund",
    title: "Instrument Name",
    align: "left",
    color: "",
    show: true,
  },
  {
    field: "allocationPercent",
    title: "Allocation (%)",
    align: "right",
    customStyle: { width: "max-content" },
    show: true,
  },

  {
    field: "product",
    title: "PRODUCT",
    align: "right",
    customStyle: { width: "max-content" },
    show: true,
  },
  {
    field: "quantity",
    title: "Quantity",
    align: "right",
    show: true,
  },

  {
    field: "investmentAmount",
    title: "Invested Value (₹)",
    align: "center",
    show: true,
  },
  {
    field: "currentValue",
    title: "Current Value (₹)",
    align: "center",
    show: true,
  },
  {
    field: "xirr",
    title: "XIRR (%)",
    align: "center",
    show: true,
  },
];
export const topPerformanceProductTable = [
  {
    field: "fund",
    title: "Instrument Name",
    align: "left",
    color: "",
    show: true,
  },
  {
    field: "assetClass",
    title: "ASSET CLASS",
    customStyle: { width: "max-content" },
    show: true,
  },
  {
    field: "contributionPercent",
    title: "Contribution (%)",
    align: "center",
    customStyle: { width: "max-content" },
    show: true,
  },
  {
    field: "investmentAmount",
    title: "Invested Value",
    align: "center",
    show: true,
  },
  {
    field: "currentValue",
    title: "Curent Value",
    align: "center",
    show: true,
  },
  {
    field: "xirr",
    title: "XIRR",
    align: "center",
    show: true,
  },
  {
    title: "Total Gain/Loss",
    field: "totalGainLoss",
    align: "center",
    customStyle: { width: "max-content" },
    show: true,
  },
];

export const performanceTableConfig = [
  {
    field: "performerName",
    title: "Asset Category",
    align: "left",
    color: "",
    show: true,
  },
  {
    field: "performerTotalInvestmentValue",
    title: "Invested Value",
    customStyle: { width: "max-content" },
    show: true,
  },
  {
    field: "performerPresentValue",
    title: "Current Value",
    align: "center",
    customStyle: { width: "max-content" },
    show: true,
  },
  {
    field: "performerXirr",
    title: "XIRR(%)",
    align: "center",
    show: true,
  },
  {
    field: "unit",
    title: "UNITS",
    align: "center",
    show: true,
  },
  {
    title: "Realized Gain Loss ",
    field: "performerGainLoss",
    align: "center",
    customStyle: { width: "max-content" },
    show: true,
  },
  {
    field: "unRealiziedGainLoss",
    title: "Unrealized Gain Loss ",
    align: "center",
    show: true,
  },
  {
    field: "performerPercentage",
    title: "Gain (%)",
    align: "center",
    customStyle: { width: "max-content" },
    show: true,
  },
];

export const btnArray = [
  { title: "All", page: "all" },
  { title: "Equity", page: "equity" },
  { title: "Cash", page: "cash" },
  { title: "Debt", page: "debt" },
  { title: "Alternate", page: "alternate" },
  //   { title: "Mutual Funds", page: "mutualFunds" },
  //   { title: "AIF", page: "aif" },
  //   { title: "PMS", page: "pms" },
  //   { title: "Structured Products", page: "structuredProducts" },
  //   { title: "REITS", page: "reits" },
  //   { title: "INVITS", page: "invits" },
  //   { title: "Direct Equity", page: "directEquity" },
];

export const reportingAgingBtnArray = [
  { title: "All", page: "all" },
  { title: "Mutual Funds", page: "mf" },
  { title: "AIF", page: "aif" },
  { title: "PMF", page: "pmf" },
  { title: "Structured Products", page: "sp" },
  { title: "REITS", page: "reits" },
  { title: "INVITS", page: "invits" },
  { title: "Direct Equity", page: "de" },
  { title: "ICD", page: "icd" },
  { title: "Real Estate", page: "re" },
  { title: "Insurance", page: "insurance" },
];

let align = "center";

export const tableConfigHoldingSummary = [
  { field: "schemeName", title: "Security Name", show: true, align: align },
  { field: "memberName", title: "Member Name", show: true, align: align },
  { field: "advisor", title: "Advisor", show: true, align: align },
  { field: "isin", title: "ISIN", show: true, align: align },
  { field: "folioNo", title: "Folio No", show: true, align: align },
  { field: "productName", title: "Product Type", show: true, align: align },
  { field: "assetType", title: "Asset Class", show: true, align: align },
  { field: "holdingUnits", title: "Quantity", show: true, align: align },
  {
    field: "weightedAvgCost",
    title: "Unit Cost (Actuals)",
    show: true,
    align: align,
  },
  {
    field: "investmentValue",
    title: "Total Cost",
    show: true,
    align: align,
  },
  {
    field: "nav",
    title: "Market Price per Unit",
    show: true,
    align: align,
  },
  { field: "presentValue", title: "Current Market Value", show: true },
  { field: "totalCharges", title: "Total Charges", show: true },
  {
    field: "percentageOfTotalPortfolio",
    title: "% of Total Portfolio",
    show: true,
  },

  { field: "unRealisedGainLoss", title: "Unrealized Gain/Loss", show: true },
  { field: "interestIncome", title: "Other Income", show: true },
  { field: "totalGainLoss", title: "Total Gain/Loss", show: true },
  { field: "xirr", title: "XIRR (%)", show: true },
  {
    field: "absoluteTwrr",
    title: "Absolute TWRR (%)",
    show: true,
    align: align,
  },
  {
    field: "annualisedTwrr",
    title: "Annualized TWRR (%)",
    show: true,
    align: align,
  },
  {
    field: "updatedAsOn",
    title: "Last Price Date",
    show: true,
    align: align,
  },
];
export const tableConfigHoldingSummaryPms = [
  {
    field: "schemeName",
    title: "Security Name",
    show: true,
    align: align,
    showUploadDownload: true,
  },
  { field: "memberName", title: "Member Name", show: true, align: align },
  { field: "advisor", title: "Advisor", show: true, align: align },
  { field: "isin", title: "ISIN", show: true, align: align },
  { field: "folioNo", title: "Folio No", show: true, align: align },
  { field: "productName", title: "Product Type", show: true, align: align },
  { field: "assetType", title: "Asset Class", show: true, align: align },
  { field: "holdingUnits", title: "Quantity", show: true, align: align },
  {
    field: "weightedAvgCost",
    title: "Unit Cost (Actuals)",
    show: true,
    align: align,
  },
  {
    field: "investmentValue",
    title: "Total Cost",
    show: true,
    align: align,
  },
  {
    field: "nav",
    title: "Market Price per Unit",
    show: true,
    align: align,
  },
  { field: "presentValue", title: "Current Market Value", show: true },
  { field: "totalCharges", title: "Total Charges", show: true },
  {
    field: "percentageOfTotalPortfolio",
    title: "% of Total Portfolio",
    show: true,
  },

  { field: "unRealisedGainLoss", title: "Unrealized Gain/Loss", show: true },
  { field: "interestIncome", title: "Other Income", show: true },
  { field: "totalGainLoss", title: "Total Gain/Loss", show: true },
  { field: "xirr", title: "XIRR (%)", show: true },
  {
    field: "absoluteTwrr",
    title: "Absolute TWRR (%)",
    show: true,
    align: align,
  },
  {
    field: "annualisedTwrr",
    title: "Annualized TWRR (%)",
    show: true,
    align: align,
  },
  {
    field: "updatedAsOn",
    title: "Last Price Date",
    show: true,
    align: align,
  },
];

export const tableConfigSecurityHoldingSummary = [
  { field: "schemeName", title: "Security Name", show: true, align: align },
  // { field: "memberName", title: "Member Name", show: true, align: align },
  // { field: "advisor", title: "Advisor", show: true, align: align },
  { field: "isin", title: "ISIN", show: true, align: align },
  // { field: "folioNo", title: "Folio No", show: true, align: align },
  { field: "productName", title: "Product Type", show: true, align: align },
  { field: "assetType", title: "Asset Class", show: true, align: align },
  { field: "holdingUnits", title: "Quantity", show: true, align: align },
  {
    field: "weightedAvgCost",
    title: "Unit Cost (Actuals)",
    show: true,
    align: align,
  },
  {
    field: "investmentValue",
    title: "Total Cost",
    show: true,
    align: align,
  },
  {
    field: "nav",
    title: "Market Price per Unit",
    show: true,
    align: align,
  },
  { field: "presentValue", title: "Current Market Value", show: true },
  { field: "totalCharges", title: "Total Charges", show: true },
  {
    field: "percentageOfTotalPortfolio",
    title: "% of Total Portfolio",
    show: true,
  },
  { field: "unRealisedGainLoss", title: "Unrealized Gain/Loss", show: true },
  { field: "interestIncome", title: "Other Income", show: true },
  { field: "totalGainLoss", title: "Total Gain/Loss", show: true },
  { field: "xirr", title: "XIRR (%)", show: true },

  {
    field: "absoluteTwrr",
    title: "Absolute TWRR (%)",
    show: true,
    align: align,
  },
  {
    field: "annualisedTwrr",
    title: "Annualized TWRR (%)",
    show: true,
    align: align,
  },
  {
    field: "updatedAsOn",
    title: "Last Price Date",
    show: true,
    align: align,
  },
];

export const deleteTableConfigSummary = [
  { field: "scrip_name", title: "Security Name", show: true, align: align },
  { field: "member_name", title: "Member Name", show: true, align: align },
  { field: "isin", title: "ISIN", show: true, align: align },
  { field: "productType", title: "Product Type", show: true, align: align },
  { field: "asset_type", title: "Asset Class", show: true, align: align },
  { field: "quantity", title: "Quantity", show: true, align: align },
  {
    field: "gross_rate_per_unit",
    title: "Unit Cost",
    show: true,
    align: align,
  },
];

export const tableConfigTransactionSummary = [
  {
    field: "scrip_name",
    title: "Scheme Name",
    show: true,
    align: "center",
  },
  { field: "member_name", title: "Member Name", show: true, align: align },
  { field: "advisor", title: "Advised by", show: true, align: "center" },
  {
    field: "executed_by",
    title: "Executed By",
    show: true,
    align: "center",
  },
  { field: "isin", title: "ISIN No", show: true, align: "center" },
  { field: "folio_no", title: "Folio No", show: true, align: "center" },
  { field: "productName", title: "Product Name", show: true, align: align },

  { field: "asset_type", title: "Asset Class", show: true, align: align },
  {
    field: "transaction_date",
    title: "Transaction date",
    show: true,
    align: "center",
  },
  {
    field: "transaction_type",
    title: "Transaction Type",
    show: true,
    align: "center",
  },
  { field: "quantity", title: "Quantity/Units", show: true, align: align },
  {
    field: "gross_rate_per_unit",
    title: "Gross Rate per unit",
    show: true,
    align: align,
  },
  {
    field: "stamp_duty",
    title: "Stamp Duty",
    show: true,
    align: align,
  },
  {
    field: "brokerage_per_unit",
    title: "Brokerage",
    show: true,
    align: align,
  },
  {
    field: "gst_sst_other_charges",
    title: "Other Charges * GST+STT+Other Charges",
    show: true,
    align: align,
  },
  {
    field: "net_rate_per_unit",
    title: "Net Rate per Unit",
    show: true,
    align: align,
  },
  {
    field: "total_transaction_value",
    title: "Total Transaction Value",
    show: true,
  },
  { field: "cumulative_quantity", title: "Cumulative Units", show: true },
  {
    field: "select",
    title: "",
    show: false,
    cellCustomClass: "selectCell",
  },
  // { field: "nav", title: "Gain/Loss", show: true },

  // { field: "stamp_duty", title: "% of Total Portfolio", show: true },
  // { field: "other_charges", title: "XIRR", show: true },
];
export const tableConfigUpcomingTransaction = [
  {
    field: "schemeName",
    title: "Scheme Name",
    show: true,
    align: "center",
  },
  { field: "member", title: "Member Name", show: true, align: align },
  { field: "product", title: "Product", show: true, align: align },
  { field: "type", title: "Transaction Type", show: true, align: align },
  {
    field: "date",
    title: "Date",
    show: true,
    align: "center",
  },
  { field: "quantity", title: "Quantity/Units", show: true, align: align },
  {
    field: "amount",
    title: "Amount",
    show: true,
    align: align,
  },
  {
    field: "totalAmount",
    title: "Total Amt",
    show: true,
    align: align,
  },
  { field: "ratio", title: "Ratio", show: true, align: "center" },
  {
    field: "adjustedUnits",
    title: "Adjusted Units",
    show: true,
    align: align,
  },

  {
    field: "demergedStock",
    title: "Demerged Stocks",
    show: true,
    align: "center",
  },

  { field: "mergedStock", title: "Merged Stocks", show: true, align: "center" },

  // {
  //   field: "select",
  //   title: "",
  //   show: false,
  //   cellCustomClass: "selectCell",
  // },
];
export const tableConfigTransactionSummaryCorporateActions = [
  ...tableConfigTransactionSummary?.filter((val) => val?.field !== "select"),
  {
    field: "remarks",
    title: "Remarks",
    show: true,
    tagPresent: false,
    align: align,
    customClass: "customRemarksWidth",
    customWrapperClass: "customCell",
  },
  {
    field: "corp_action_effects",
    title: "Effects",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },

  {
    field: "corporate_action_value",
    title: "Value (Actuals)",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "ratio",
    title: "Ratio",
    show: true,
    align: align,
    tagPresent: "is_corp_action",
  },
  {
    field: "select",
    title: "",
    show: false,
    cellCustomClass: "selectCell",
  },
];

export const tableConfigTransactionSummaryBank = [
  {
    field: "bank_name",
    title: "Scheme Name",
    show: true,
    align: "center",
  },
  { field: "member_name", title: "Member Name", show: true, align: align },
  { field: "advisor", title: "Advised by", show: true, align: "center" },
  {
    field: "executed_by",
    title: "Executed By",
    show: true,
    align: "center",
  },
  { field: "isin", title: "ISIN No", show: true, align: "center" },
  { field: "folio_no", title: "Folio No", show: true, align: "center" },
  { field: "product_name", title: "Product Name", show: true, align: align },

  { field: "asset_type", title: "Asset Class", show: true, align: align },
  {
    field: "transaction_date",
    title: "Transaction date",
    show: true,
    align: "center",
  },
  {
    field: "transaction_type",
    title: "Transaction Type",
    show: true,
    align: "center",
  },
  { field: "quantity", title: "Quantity/Units", show: true, align: align },
  {
    field: "gross_rate_per_unit",
    title: "Gross Rate per unit",
    show: true,
    align: align,
  },
  {
    field: "stamp_duty",
    title: "Stamp Duty",
    show: true,
    align: align,
  },
  {
    field: "brokerage_per_unit",
    title: "Brokerage",
    show: true,
    align: align,
  },
  {
    field: "gst_sst_other_charges",
    title: "Other Charges * GST+STT+Other Charges",
    show: true,
    align: align,
  },
  {
    field: "net_rate_per_unit",
    title: "Net Rate per Unit",
    show: true,
    align: align,
  },
  {
    field: "amount",
    title: "Total Transaction Value",
    show: true,
  },
  { field: "cumulative_quantity", title: "Cumulative Units", show: true },
  {
    field: "select",
    title: "",
    show: true,
    cellCustomClass: "selectCell",
  },
  // { field: "nav", title: "Gain/Loss", show: true },

  // { field: "stamp_duty", title: "% of Total Portfolio", show: true },
  // { field: "other_charges", title: "XIRR", show: true },
];

export const tableConfigHoldingSummaryBank = [
  { field: "bank_name", title: "Security Name", show: true, align: align },
  { field: "member_name", title: "Member Name", show: true, align: align },
  { field: "advisor", title: "Advisor", show: true, align: align },
  { field: "isin", title: "ISIN", show: true, align: align },
  { field: "account_no", title: "Folio No", show: true, align: align },
  { field: "account_type", title: "Product Type", show: true, align: align },
  { field: "assetType", title: "Asset Class", show: true, align: align },
  { field: "holdingUnits", title: "Quantity", show: true, align: align },
  {
    field: "weightedAvgCost",
    title: "Unit Cost (Actuals)",
    show: true,
    align: align,
  },
  {
    field: "amount",
    title: "Total Cost",
    show: true,
    align: align,
  },
  {
    field: "nav",
    title: "Market Price per Unit",
    show: true,
    align: align,
  },
  { field: "presentValue", title: "Current Market Value", show: true },
  { field: "interestIncome", title: "Other Income", show: true },
  { field: "totalGainLoss", title: "Gain/Loss", show: true },

  {
    field: "percentageOfTotalPortfolio",
    title: "% of Total Portfolio",
    show: true,
  },
  { field: "xirr", title: "XIRR (%)", show: true },

  // { field: "value_date", title: "Value Date", show: true, align: align },
  {
    field: "absoluteTwrr",
    title: "Absolute TWRR (%)",
    show: true,
    align: align,
  },
  {
    field: "annualisedTwrr",
    title: "Annualized TWRR (%)",
    show: true,
    align: align,
  },
  {
    field: "updatedAsOn",
    title: "Last Price Date",
    show: true,
    align: align,
  },
  // { field: "nav", title: "Gain/Loss", show: true },

  // { field: "stamp_duty", title: "% of Total Portfolio", show: true },
  // { field: "other_charges", title: "XIRR", show: true },
];

export const holdingSummaryTableNavConfig = [
  // { page: "all", title: "ALL" },
  { page: "equity", title: "Direct Equity", key: "equity" },
  { page: "mfo", title: "Mutual funds", key: "mf" },
  { page: "aif", title: "AIF", key: "aif" },
  { page: "pms", title: "PMS", key: "pms" },
  { page: "icd", title: "ICD", key: "icd" },
  { page: "mld", title: "MLD", key: "mld" },
  { page: "reits", title: "REITS", key: "reits" },
  { page: "invits", title: "INVITS", key: "invits" },
  { page: "structured", title: "structured products", key: "structured" },
  { page: "private", title: "unlisted equity", key: "private" },
  { page: "real-estate", title: "real estate", key: "re" },
  { page: "bonds", title: "bonds", key: "bonds" },
  {
    page: "bank",
    title: "Bank",
    key: "bank",
    transactionConfig: [
      ...bankTransactionConfig,
      {
        field: "select",
        title: "",
        show: true,
        cellCustomClass: "selectCell",
      },
    ],
    holdingConfig: bankHoldingConfig,
  },
  // { page: "fixed-deposit", title: "fixed deposit", key: "fixed-deposit" },
];
