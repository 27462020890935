import React, { useState, useEffect, useCallback } from "react";
import useStyles from "../common/ProductsStyles";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Modal,
  Button,
  Menu,
} from "@material-ui/core";
import PageLoader from "../common/pageLoader";
import CheckBoxWithColor from "../common/CheckBoxWithColor";
import "../common/products-style.css";
import TableContainer from "@material-ui/core/TableContainer";
import DataNotFound from "../common/DataNotFound";
import { ReactComponent as Transaction } from "../../../../assets/icons/transaction-type.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { bottomHeader, innerHeader } from "./util/summaryDetailsTable.util";
import { colorAndProd, subColorAndProd } from "../utils/constands/prodAndColor";
import ArrowButton from "../newDesigns/common/arrowButton/ArrowButton";
import TableEditModal from "./TableEditModal";
import Loader from "../../../loader/Loader";
import ReplayIcon from "@material-ui/icons/Replay";

function SummaryDetailsTable({
  setTableColumns,
  tableColumns,
  setOpenModal,
  openModal,
  tableName,
  loading,
  pageName,
  tableHeaderBGColor,
  headerList,
  tableHeader,
  setTableHeader,
  stickyProductName,
  checkedTransList,
  expandedRows,
  setExpandRow,
  newHandleProductExpand,
  innerTable,
  setInnerTable,
  setInnerTableHeader,
  bottomTabel,
  setBottomTabel,
  setBottomTableHeader,
  handleProductClick,
  tableHeaderclass,
  resetTable,
  loaderConfig,
  loaderConfigAssetClass,
  totalRowLoading,
  renderArrowButton,
}) {
  const [tickedColumns, setTickedColumns] = useState(headerList);
  const [tableReOrderConfig, setTableReOrderConfig] = useState([]);
  const [checkedTrans, setCheckedTrans] = useState([]);
  const [complete, setComplete] = useState(false);
  const [reset, setRest] = useState(false);

  useEffect(() => {
    if (checkedTransList?.length > 0) {
      setCheckedTrans([...checkedTransList]);
    }
  }, [checkedTransList]);

  const trFilter = () => {
    let checkList = checkedTrans
      .filter((val) => val.show == true)
      .map((val) => val?.title?.toLowerCase());

    setTableColumns((prev) => {
      prev.map((row) => {
        let add = false;
        // row.filter( column => checkList.includes(column.field.toLowerCase()))
        row.map((column) => {
          if (checkList.length == 0) {
            add = true;
          }
          if (checkList?.includes(column?.field)) {
            add = true;
          }
        });
        if (add == true) {
          row.map((column) => {
            column.show = true;
            return column;
          });
        } else {
          row.map((column) => {
            column.show = false;
            return column;
          });
        }
      });
      return [...prev];
    });
    handleCloseEvent();
  };

  const handleCheckBoxClickTrans = (e) => {
    const list = [...checkedTrans];
    list.map((col, index) => {
      if (col.title === e.target.name) {
        const data = { ...col };
        data["show"] = !col.show;
        list[index] = { ...col, show: !col.show };
      }
    });
    setCheckedTrans(list);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClickEvent = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseEvent = () => {
    setAnchorEl(null);
  };

  const getResetedRow = (row, configArray) => {
    let newRow = [];
    configArray.map((column) => {
      row.map((oldCol) => {
        if (column.title == oldCol.title) {
          oldCol.show = column.show;
          newRow.push(oldCol);
        }
      });
    });
    console.log(newRow, "resetedRows");
    return newRow;
  };

  const handleReset = () => {
    setTickedColumns(headerList);
    // setRest(true);
    setTableReOrderConfig([]);
    setTableHeader(headerList);

    let innerHeader1 = innerHeader({
      headerList: headerList,
    });

    setInnerTable((prev) => {
      let newData = {};
      expandedRows?.map((expRow) => {
        let newList = [];
        prev[expRow].map((row) => {
          let res = getResetedRow(row, innerHeader1);
          newList.push(res);
        });
        newData = { ...newData, [expRow]: newList };
      });
      return newData;
    });

    setTableColumns((prev) => {
      let newList = [];
      prev?.map((row) => {
        let res = getResetedRow(row, headerList);
        newList.push(res);
      });
      return newList;
    });

    let bottomHeader1 = bottomHeader({
      headerList: headerList,
    });

    setBottomTabel((prev) => {
      let newList = {};
      Object.keys(prev).map((row) => {
        let res = getResetedRow(prev[row], bottomHeader1);
        newList = { ...newList, [row]: [...res] };
      });
      return newList;
    });
    setOpenModal(!openModal);
  };

  const handleButtonClickApply = () => {
    setTableHeader(tickedColumns);
    let innerHeader1 = innerHeader({
      headerList: tickedColumns,
    });
    // console.log(innerHeader1,'innerTableHeaderChanged');
    setInnerTableHeader(innerHeader1);
    let bottomHeader1 = bottomHeader({
      headerList: tickedColumns,
    });
    setBottomTableHeader([...bottomHeader1]);

    if (reset) {
      setRest(false);
      return;
    }

    let filterList = tickedColumns
      .filter((val) => val.show == false)
      .map((val) => val.title);

    setTableColumns((prev) => {
      let newRows = [];
      prev.map(async (row) => {
        let newList = reOrderBody(row);
        newList.map((column) => {
          // console.log(filterList.includes(column.title), 'asfefw');
          if (filterList.includes(column.title)) {
            column.show = false;
          } else {
            column.show = true;
          }
          return column;
        });
        newRows.push(newList);
      });
      return [...newRows];
    });
    setInnerTable((prev) => {
      let newObj = {};
      expandedRows?.map((expRow) => {
        let newRows = [];
        prev[expRow].map((row) => {
          let newList = reOrderBody(row);
          newList.map((column) => {
            // console.log(filterList.includes(column.title), 'asfefw');
            if (filterList.includes(column.title)) {
              column.show = false;
            } else {
              column.show = true;
            }
            return column;
          });
          newRows.push(newList);
        });
        newObj[expRow] = newRows;
      });
      return { ...newObj };
    });
    setBottomTabel((prev) => {
      let newObj = {};
      Object.keys(prev).map((row) => {
        let newRows = [];
        let newList = reOrderBody(prev[row]);
        newList.map((column) => {
          // console.log(filterList.includes(column.title), 'asfefw');
          if (filterList.includes(column.title)) {
            column.show = false;
          } else {
            column.show = true;
          }
          return column;
        });
        newObj[row] = newList;
      });
      return { ...newObj };
    });
    setOpenModal(!openModal);
    setTableReOrderConfig([]);
  };

  const handleCheckBoxClick = (e) => {
    const list = [...tickedColumns];
    list.map((col, index) => {
      if (col.field === e.target.name) {
        const data = { ...col };
        data["show"] = !col.show;
        list[index] = { ...col, show: !col.show };
      }
    });
    setTickedColumns(list);
  };

  const tableHeaders = (headers) => {
    return headers?.map((column, i) =>
      column && column.show ? (
        <TableCell
          style={{
            borderBottom: "none",
            textTransform: "uppercase",
            backgroundColor: tableHeaderBGColor || "transparent",
            // opacity: "0.5",
            letterSpacing: "1.92px",
          }}
          key={column.title}
          align={column.align ? column.align : "right"}
          className={`${classes.tableHeaderTextNew} ${tableHeaderclass || ""} ${
            stickyProductName ? "stickyCellHed" : ""
          } textCapitalize newTableHead`}
        >
          {column.title === "Transaction Type" && checkedTrans?.length > 0 ? (
            <div style={{ display: "flex", marginTop: "5px" }}>
              Transaction Type
              <span
                onClick={handleClickEvent}
                style={{ cursor: "pointer", marginLeft: "20px" }}
              >
                <Transaction />
              </span>
            </div>
          ) : column.title.includes("*") ? (
            column.title.split("*")[0]
          ) : (
            <p
              className="tableHeadFont"
              style={{ visibility: i === 0 ? "hidden" : "visible" }}
            >
              {column.title}
            </p>
          )}
          {column.title.includes("*") && (
            <>
              <br />
              <span className="headSubText">
                {column.title.includes("*")
                  ? "*" + column.title.split("*")[1]
                  : ""}
              </span>
            </>
          )}
        </TableCell>
      ) : null
    );
  };

  const tableBody = (body) => {
    return body?.map((rows) => (
      <>
        <TableRow
          className={`border-bottom ${classes.tableRowCard}`}
          style={{ background: "#2F1D5B" }}
        >
          {rows?.map((column, i) =>
            column && column.show ? (
              <TableCell
                key={column.title}
                align={column.align ? column.align : "right"}
                className={`${classes.tableBodyText} tdPms
                ${expandedRows?.includes(rows[0].field) ? "" : ""} ${
                  stickyProductName ? "stickyCell" : ""
                } expadableTableCell textCapitalize ${
                  i === 0 ? classes.firstColExpandable : ""
                }`}
                style={{
                  // position: "relative",
                  cursor: column?.onClick ? "pointer" : "",
                  background: "var(--secondaryBackground-color)",
                  borderLeft:
                    i === 0 ? `6px solid ${colorAndProd[column.field]}` : "",
                }}
              >
                <div
                  style={
                    column.title == ""
                      ? {
                          minWidth: "160px",
                          whiteSpace: "pre-wrap",
                          overFlowWrap: "break-word",
                        }
                      : {
                          width: "auto",
                          whiteSpace: "nowrap",
                          overFlowWrap: "break-word",
                        }
                  }
                  onClick={() => {
                    if (column?.onClick) column?.onClick(column?.field);
                  }}
                >
                  {column?.field == "loading" ? (
                    <div
                      className="tableLoader"
                      // style={{ position: "relative" }}
                    >
                      <Loader />
                    </div>
                  ) : column?.field == "Error" ? (
                    <div
                      className="tableLoader"
                      // style={{ position: "relative" }}
                    >
                      {loaderConfigAssetClass ? (
                        <Loader />
                      ) : (
                        <button onClick={column?.hanleErrorRetry}>
                          <ReplayIcon color="white" className="reoloadIcon" />
                          <p className="tableLoaderbtnTxt">Reload</p>
                        </button>
                      )}
                    </div>
                  ) : i === 0 ? (
                    <span
                      className="table-head-product"
                      style={{ flexWrap: "nowrap" }}
                    >
                      {column?.field}
                      {expandedRows?.includes(column?.field)
                        ? renderArrowButton && (
                            <ArrowButton
                              onClick={() => {
                                newHandleProductExpand(column?.field, true);
                              }}
                              bgColor={colorAndProd[column.field]}
                              customStyles={{
                                marginLeft: "0px",
                                marginRight: "0px",
                                transform: "rotate(90deg)",
                              }}
                            />
                          )
                        : renderArrowButton && (
                            <ArrowButton
                              onClick={() => {
                                newHandleProductExpand(column?.field, false);
                              }}
                              bgColor={colorAndProd[column.field]}
                              right={true}
                              customStyles={{
                                marginLeft: "0px",
                                marginRight: "0px",
                                transform: "rotate(270deg)",
                              }}
                            />
                          )}
                    </span>
                  ) : column.title == "Advisor" ? (
                    column.field.split("%")[0]
                  ) : (
                    column?.field
                  )}
                </div>
              </TableCell>
            ) : (
              ""
            )
          )}
        </TableRow>
        {expandedRows?.includes(rows[0].field) && (
          <>
            {/* {console.log(rows[0].field, "agjalgjag")} */}
            {innerTable[rows[0].field]?.map((row) => {
              return (
                <TableRow className="border-bottom product-content">
                  {row.map((column, index) =>
                    column && column.show ? (
                      <TableCell
                        style={{
                          cursor:
                            column?.onClick || column?.title == ""
                              ? "pointer"
                              : "",
                          borderLeft:
                            index === 0
                              ? `2px solid ${subColorAndProd[rows[0].field]}`
                              : "",
                        }}
                        className={`${
                          classes.tableBodyText
                        } expadableTableCellWithBoxShadow  ${
                          stickyProductName ? "stickyCell" : ""
                        } ${index === 0 ? classes.firstColExpandable : ""}`}
                        align="left"
                      >
                        {console.log(column, "agkakl")}
                        {column?.field == "loading" ? (
                          <div
                            className="tableLoader"
                            // style={{ position: "relative" }}
                          >
                            <Loader />
                          </div>
                        ) : column?.field == "Error" ? (
                          <div
                            className="tableLoader"
                            // style={{ position: "relative" }}
                          >
                            {loaderConfig[rows[0].field] ? (
                              <Loader />
                            ) : (
                              <button onClick={column?.hanleErrorRetry}>
                                <ReplayIcon
                                  color="white"
                                  className="reoloadIcon"
                                />
                                <p className="tableLoaderbtnTxt">Reload</p>
                              </button>
                            )}
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              if (column?.title == "") {
                                handleProductClick(
                                  column?.field,
                                  rows[0].field
                                );
                              }
                            }}
                            className={
                              index > 0 && index < rows.length - 1
                                ? "innterTableCell"
                                : ""
                            }
                          >
                            <span className="table-data-sub-product">
                              <span className="product-title">
                                {column?.field}
                              </span>
                            </span>
                          </div>
                        )}
                      </TableCell>
                    ) : (
                      ""
                    )
                  )}
                </TableRow>
              );
            })}
          </>
        )}
        <TableRow className="no-border" style={{ height: "21px" }}></TableRow>
      </>
    ));
  };

  const tableBottomBody = () => {
    return Object.keys(bottomTabel).map((row, index) => {
      return (
        <TableRow className="border-grand no-border">
          {bottomTabel[`${row}`]?.map((column, index) =>
            column && column.show ? (
              <TableCell
                className={`${
                  index > 0 && classes.tableBodyText
                } capitalize grandTotalCells  ${
                  stickyProductName ? "stickyCell" : ""
                } ${index === 0 && "totalFirstCell"}`}
                align={
                  index > 0 && index < bottomTabel[`${row}`].length - 1
                    ? "right"
                    : index === bottomTabel[`${row}`].length - 1
                    ? "center"
                    : "left"
                }
                style={{
                  background: index === 0 ? "#6027B4" : "#160F27",
                  borderRadius: "0px",
                  borderLeft: index === 0 ? "7px solid #A26CF2" : "",
                  boxShadow:
                    index === 0 ? "12px -1px 22px 0px rgba(0, 0, 0, 0.45)" : "",
                }}
              >
                {column?.field == "loading" ? (
                  <div
                    className="tableLoader"
                    // style={{ position: "relative" }}
                  >
                    <Loader />
                  </div>
                ) : column?.field == "Error" ? (
                  <div
                    className="tableLoader"
                    // style={{ position: "relative" }}
                  >
                    {totalRowLoading ? (
                      <Loader />
                    ) : (
                      <button onClick={column?.hanleErrorRetry}>
                        <ReplayIcon color="white" className="reoloadIcon" />
                        <p className="tableLoaderbtnTxt">Reload</p>
                      </button>
                    )}
                  </div>
                ) : index == 0 ? (
                  row?.split("_").join(" ")
                ) : (
                  column?.field
                )}
              </TableCell>
            ) : (
              ""
            )
          )}
        </TableRow>
      );
    });
  };

  const handleButtonClick = () => {
    setOpenModal(!openModal);
    setTableColumns(() => [...tickedColumns]);
  };

  // drag fuctionality

  const onDragEndAction = (result) => {
    // console.log(result, "result131341");
    setRest(false);
    let newList = reorder(
      tickedColumns,
      result.source.index,
      result.destination.index
    );
    setTickedColumns(newList);
    setTableReOrderConfig((prev) => [
      ...prev,
      {
        sourceIndex: result.source.index,
        destinationIndex: result.destination.index,
      },
    ]);
  };

  const reOrderBody = (row) => {
    let newRow = row;

    /* maping through this tableReOrderConfig for to reflect 
    changes on multiple drag */
    tableReOrderConfig.map((val) => {
      newRow = reorder(newRow, val?.sourceIndex, val?.destinationIndex);
    });
    return newRow;
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const getItemStyle = (isDragging = false, draggableStyle) => {
    return {
      ...draggableStyle,
    };
  };

  const classes = useStyles();

  return (
    <>
      <Typography
        style={{ cursor: "pointer", margin: "2%", fontSize: "20px" }}
        className={classes.titleText}
      >
        {tableName || ""}
      </Typography>
      <div
        style={{
          minHeight: "300px",
          width: "-webkit-fill-available",
          margin: "2%",
          maxHeight: "600px",
          display: "flex",
          position: "relative",
        }}
      >
        <TableContainer
          className={`${classes.tableContainer} form-container dynamic-table tableContainer newtableContainer`}
          style={{
            marginTop: "0rem",
            paddingBottom: "1.6rem",
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              // style={{ borderRadius: "20px" }}
              className="tableRowStyle tableSticky"
            >
              <TableRow className="tableRowStyle">
                {tableHeaders(tableHeader)}
              </TableRow>
            </TableHead>
            <TableBody className="tableBodyPms tableSticky">
              <TableRow style={{ height: "21px" }} />
              {tableBody(tableColumns)}
              {tableColumns?.length !== 0 && tableBottomBody()}
            </TableBody>
          </Table>
        </TableContainer>
        {tableColumns?.length == 0 && (
          <div
            style={{
              position: "absolute",
              left: "44%",
              top: "14%",
            }}
          >
            {!loading && <DataNotFound />}
            {complete && <DataNotFound />}
          </div>
        )}
      </div>
      <TableEditModal
        openModal={openModal}
        setCheckedTrans={setCheckedTrans}
        setOpenModal={setOpenModal}
        onDragEndAction={onDragEndAction}
        getItemStyle={getItemStyle}
        handleReset={handleReset}
        handleButtonClickApply={handleButtonClickApply}
        tickedColumns={tickedColumns}
        handleCheckBoxClick={handleCheckBoxClick}
        headerList={headerList}
        setTickedColumns={setTickedColumns}
        tableHeader={tableHeader}
      />

      <Menu
        className="modal-wrapper"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseEvent}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={{
          backgroundColor: "#711CF1 !important",
          borderRadius: "20px",
          border: "1px solid #4E189F",
          outline: 0,
          width: "220px",
          padding: "15px",
          height: "200px",
          overflow: "scroll",
          boxShadow: "-4px 4px 10px rgba(0, 0, 0, 0.5)",
        }}
        style={{ minWidth: "400px" }}
      >
        <div className="member">
          <div className="member-content">
            {checkedTrans?.length > 0 &&
              checkedTrans?.map((column) =>
                column && column.title !== "" ? (
                  <div key={column.title} className="filter-header">
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <CheckBoxWithColor
                        checked={column.show}
                        value={column.title}
                        name={column.title}
                        onChange={(e) => handleCheckBoxClickTrans(e)}
                      />
                      <Typography className="filter-value-name avtar-text textCapitalize">
                        {column.title}
                      </Typography>
                    </span>
                  </div>
                ) : null
              )}
          </div>
        </div>
        <hr className="filter-divider"></hr>
        <div className={classes.buttonContainerTransaction}>
          <Button
            // variant="contained"
            className={classes.buttonNo}
            style={{ width: "60%", marginTop: "5%" }}
            onClick={handleCloseEvent}
          >
            <span className={`${classes.buttonText}`}>Cancel</span>
          </Button>
          <Button
            // variant="contained"
            className={classes.buttonApplyModal}
            style={{ width: "60%", marginTop: "5%" }}
            onClick={trFilter}
          >
            <span className={`${classes.buttonText}`}>Apply</span>
          </Button>
        </div>
      </Menu>

      <PageLoader showLoader={loading} />
    </>
  );
}

export default SummaryDetailsTable;
