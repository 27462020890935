import React from 'react'
import CardnewDesign from '../components/dashboard/clientDashboard/CardnewDesign'
import Default from '../components/dashboard/defaultPages/Default'

function carddesignnew() {
  
  return (
    <Default>
      <CardnewDesign></CardnewDesign>
    </Default>
  )
}

export default carddesignnew