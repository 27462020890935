import React, { useState, useEffect, useContext } from "react";
import useStyles from "../common/ProductsStyles";
import { Grid, Typography } from "@material-ui/core";
import { ReactComponent as Import } from "../../../../assets/icons/import.svg";
import { ReactComponent as Export } from "../../../../assets/icons/export.svg";
import { ReactComponent as Print } from "../../../../assets/icons/print.svg";
import { ReactComponent as EditColumn } from "../../../../assets/icons/edit-column.svg";
import TabPmsSubTransaction from "../common/TabPmsSubTransaction";
import PmsReportTable from "./PmsReportTable";
import { mfoFilterContext } from "../../../../mfo_context/Mfo_Context";
import moment from "moment";
import { currencyFormat } from "../../../../hooks/constantFunction";
import PageLoader from "../common/pageLoader";
import { ITEMS_LIMIT_UNDERLYING } from "../../../../Constants";

//need confirmatioon to remove these unused components,
//these components are replaced with PmsReportTable component
import PmsTransactionAll from "./PmsTransactionAll";
import PmsTransactionPurchase from "./PmsTransactionPurchase";
import PmsTransactionRedemption from "./PmsTransactionRedemption";
import { dateFormatCommon, roundNumber } from "../../../utils/common";
import TabComponent from "../common/TabComponent";
import NewTabComp from "../common/NewTabComp";
import NewtableTopSec from "../../common/NewtableTopSec";
import { assectClassConfigSelection } from "../common/util/common";
import { getTransactionsSummary } from "../../../../api";
import PmsUnderlyingTable from "../newDesigns/common/pms/PmsUnderlyimgTable";

const data = [
  { page: "equity", title: "Equity" },
  { page: "debt", title: "Debt" },
  { page: "alternate", title: "Alternate Assets" },
  { page: "cash", title: "Cash" },
];

function PmsUnderlyingTransaction({
  getTabelData,
  customTableConfig,
  client,
  subHeading,
  iosSwitch,
  isToggleChecked,
}) {
  const { getfilter_as_params } = useContext(mfoFilterContext);
  // let assetOrder = ["equity", "debt", "alternate", "cash"];
  // let selected_asset = getfilter_as_params().assetClass.split(",");
  // let defaultAsset = assetOrder.find((a) => {
  //   if (selected_asset.includes(a)) {
  //     return a;
  //   }
  // });
  const [page, setPage] = useState(getfilter_as_params().assetClass);
  const [tableColumns, setTableColumns] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { mfoFilter } = useContext(mfoFilterContext);
  const [reset, setReset] = useState();
  const [offset, setOffset] = useState(0);
  const [pagiNationData, setPageinationData] = useState({
    pageCount: null,
  });
  const [tableData, setTableData] = useState();
  const [tableHeader, setTableHeader] = useState(customTableConfig);
  const [checkedTrans, setCheckedTrans] = useState([]);
  const [search, setSearch] = useState("");
  const [isinBased, setIsinBased] = useState({});
  const [expandTable, setExpandTable] = useState({});
  const handleIconClick = () => {
    setOpenModal(!openModal);
  };
  const headerData = [
    {
      field: "isin",
      title: "ISIN",
      show: true,
      align: "center",
      col: 2,
      dontShowFirstColBorder: true, // ** NOTE TO REMOVE THE LEFT COLOR BORDER
    },
    {
      field: "folio_no",
      title: "Folio No",
      tooltip: true,
      show: true,
      align: "center",
      col: 2,
    },
    {
      field: "member_name",
      title: "MEMBER NAME",
      tooltip: true,
      show: true,
      align: "center",
      col: 2,
    },

    {
      field: "scheme_name",
      title: "SCHEME NAME",
      show: true,
      tooltip: true,
      maxWidth: "300px",
      align: "center",
      col: 2,
    },
    {
      field: "count",
      title: "NO. OF TRANSACTIONS",
      show: true,
      align: "center",
      col: 2,
    },
    {
      field: "id",
      title: "Status",
      show: true,
      align: "center",
      col: 1,
    },
  ];

  const headerData2 = [
    {
      field: "transaction_date",
      title: "TRANSACTION DATE",
      show: true,
      align: "left",
      color2: "transparent",
      col: 1,
      dontShowFirstColBorder: true,
      bgColor: "rgba(46, 35, 78, 1)", // ** NOTE TO REMOVE THE LEFT COLOR BORDER
    },
    {
      field: "isin_name",
      title: "SCHEME NAME",
      show: true,
      tooltip: true,
      maxWidth: "300px",
      align: "left",
      col: 1,
      bgColor: "rgba(46, 35, 78, 1)",
    },
    {
      field: "transaction_type",
      title: "TRANSACTION TYPE",
      show: true,
      align: "left",
      col: 1,
      bgColor: "rgba(46, 35, 78, 1)",
    },

    {
      field: "units",
      title: "UNITS",
      show: true,
      align: "left",
      col: 2,
      bgColor: "rgba(46, 35, 78, 1)",
    },
    {
      field: "price",
      title: "PRICE",
      show: true,
      align: "left",
      col: 1,
      bgColor: "rgba(46, 35, 78, 1)",
    },
    {
      field: "amount",
      title: "AMOUNT",
      show: true,
      align: "left",
      col: 1,
      bgColor: "rgba(46, 35, 78, 1)",
    },
  ];

  const objectFormating2 = (data) => {
    return data.map((d) => {
      let newData = headerData2.map((header) => {
        if (["transaction_type", "isin_name"].includes(header?.field)) {
          return {
            ...header,
            field: d[`${header.field}`] ? d[`${header.field}`] : " -- ",
            title: header.title,
            show: header.show,
            color2: "transparent",

            align: header?.align,
          };
        }
        if (header?.field == "transaction_date") {
          return {
            ...header,
            field:
              // dateFormatCommon(new Date(d[`${header.field}`])),
              moment(d[`${header.field}`], "YYYY-MM-DD").format("DD-MM-YYYY") ||
              "--",
            title: header.title,
            show: header.show,
            align: header?.align,
          };
        }

        if (header?.field == "amount" || header?.field == "price") {
          return {
            ...header,
            field: currencyFormat(d[`${header.field}`] || 0) || "--",
            title: header.title,
            show: header.show,
            align: header?.align,
          };
        }

        return {
          ...header,
          field: !isNaN(d[`${header.field}`])
            ? roundNumber(Number(d[`${header.field}`]))
            : d[`${header.field}`] || "--",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      });

      return { data: newData };
    });
  };

  const donothing = ["isin", "member_name", "scheme_name", "count"];
  const objectFormating = (data, i) => {
    //console.log("object formatting data PmsTransaction", data);
    let newData = headerData.map((header) => {
      if (header.title == "Status") {
        isinBased[i] = objectFormating2(data.details);
        data["id"] = i;
        return {
          ...header,
          field: i,
          handleTogle: (e, val1) => {
            //console.log(e, "event", val1);

            setExpandTable((prev) => {
              if (!prev[val1]) {
                return { ...prev, [val1]: isinBased[val1] };
              } else {
                return { ...prev, [val1]: undefined };
              }
            });

            //  else {
            //   delete expandTable[val1];
            //   setExpandTable({ ...expandTable });
            // }
          },
        };
      }

      if (donothing.includes(header?.field)) {
        return {
          ...header,
          field: data[`${header.field}`] ? data[`${header.field}`] : " -- ",
          title: header.title,
          show: header.show,
          align: header?.align,
        };
      }

      return {
        ...header,
        field: !isNaN(data[`${header.field}`])
          ? roundNumber(Number(data[`${header.field}`]))
          : data[`${header.field}`] || "--",
        title: header.title,
        show: header.show,
        align: header?.align,
      };
    });
    //console.log(newData, "newData");
    return newData;
  };

  async function fetchTransection() {
    setLoading(true);
    let filterParams = getfilter_as_params();
    let params = {
      clientId: client,
      // userState.loginedUserData.id,
      // clientId: 199,
      limit: ITEMS_LIMIT_UNDERLYING,
      offset: offset,
      advisor: filterParams.advisor,
      memberId: filterParams.memberId,
      // ...getfilter_as_params(),
      assetClass: page?.toLocaleLowerCase(),
      // search: params?.search,
    };

    try {
      let response = await getTransactionsSummary(
        "pms",
        "underlying-transaction",
        params
      );
      // if (page !== "holding") {

      setTableData(
        response?.data?.data?.result?.map((d, i) => {
          return { data: objectFormating(d, i), isin: i };
        })
      );
      setPageinationData((prev) => {
        return { ...prev, pageCount: response?.data?.data?.meta?.count };
      });

      let newlist = [];
      let transactionList = [];

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  const classes = useStyles();
  useEffect(() => {
    fetchTransection();
  }, [page, mfoFilter, search, offset]);

  // useEffect(() => {}, [
  //   page,
  //   mfoFilter,
  //   reset,
  //   isToggleChecked,
  //   customTableConfig,
  //   search,
  //   offset,
  // ]);

  const handleClick = React.useCallback(
    (page) => {
      setPage(page);
      setPageinationData((prev) => ({ ...prev, pageCount: 0 }));
      setOffset(0);
    },
    [page]
  );

  return (
    <>
      <div style={{ margin: "2%" }}>
        {/* <NewtableTopSec
        handleIconClick={handleIconClick}
        directPageName={"Transaction Summary"}
        setSearch={(e) => {
          setOffset(0);
          setSearch(e);
        }}
        product={subHeading || "PMS"}
        iosSwitch={iosSwitch}
      /> */}
        {/* <div style={{ margin: "2%" }}>
        <NewTabComp
          data={assectClassConfigSelection({
            assetClassList: getfilter_as_params().assetClass,
            data: data,
          })}
          customWidth={"100%"}
          customHandle={handleClick}
        />
      </div> */}
        <NewTabComp
          data={assectClassConfigSelection({
            assetClassList: getfilter_as_params().assetClass,
            data: data,
          })}
          customWidth={"100%"}
          customHandle={handleClick}
        />
        <Typography
          style={{
            cursor: "pointer",
            margin: "2% 2% 2% 1px",
            fontSize: "20px",
          }}
          className={`${classes.titleText} capitalize`}
        >
          {page || ""}
        </Typography>
        <PmsUnderlyingTable
          data={tableData}
          headerData={headerData.slice(0, -1)}
          headerData2={headerData2}
          expandTable={expandTable}
          setExpandTable={setExpandTable}
          hideNavButtons={true}
          loading={loading}
        />
        <PageLoader showLoader={loading} />
      </div>
    </>
  );
}

export default PmsUnderlyingTransaction;

// if (header?.field == "transaction_date") {
//   return {
//     field: dateFormatCommon(new Date(data[`${header.field}`])),
//     // moment(new Date(data[`${header.field}`])).format(
//     //   "DD - MM - YYYY"
//     // ) || "--",
//     title: header.title,
//     show: header.show,
//     align: header?.align,
//   };
// }

// if (
//   header?.field == "amount" ||
//   header?.field == "load_charges" ||
//   header?.field == "dividend" ||
//   header?.field == "interest" ||
//   header?.field == "other_income" ||
//   header?.field == "management_fees" ||
//   header?.field == "securities_transaction_tax" ||
//   header?.field == "other_expense"
// ) {
//   return {
//     field: currencyFormat(data[`${header.field}`] || 0) || "--",
//     title: header.title,
//     show: header.show,
//     align: header?.align,
//   };
// }
// if (header?.field == "holdingPeriod") {
//   return {
//     field: data[`${header.field}`]
//       ? data[`${header.field}`] + " days"
//       : "--",
//     // moment(new Date(data[`${header.field}`])).format(
//     //   "DD - MM - YYYY"
//     // ) || "--",
//     title: header.title,
//     show: header.show,
//     align: header?.align,
//   };
// }
