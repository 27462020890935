import { Typography } from "@material-ui/core";
import React from "react";
import { roundNumber } from "../../../../utils/common";
import useStyles from "../../common/ProductsStyles";
import { handleData } from "./common";
import "./commoncss.css";
import HashlinkSupport from "./HashlinkSupport";

function FinxedIcomeSection({ riskDetails }) {
  const classes = useStyles();

  let data = [
    {
      title: "Modified Duration",
      data: roundNumber(handleData(riskDetails?.modifiedDuration)),
    },
    {
      title: "Avg Credit Quality",
      data: handleData(riskDetails?.avgCreditQualityName),
    },
    {
      title: "YTM",
      data: roundNumber(handleData(riskDetails?.yieldMaturity)) + "%",
    },
  ];

  return (
    <>
      <HashlinkSupport id={"fixedIncomeAttributes"} />
      <Typography
        style={{ marginLeft: "2%" }}
        className={`${classes.riskHeaderSub} ${classes.typoInsideAlign}`}
        // id="fixedIncomeAttributes"
      >
        Fixed Income Attributes
      </Typography>
      <div
        style={{ padding: "35px 25px 55px 25px" }}
        className={classes.mainContent}
      >
        <div className="dFlex spaceBTWN alignItemsCenter gap10">
          {data.map((val) => {
            return (
              <div className="dFlex alignItemsCenter flexDirCol gap10">
                <Typography className={classes.riskDetailsTextMain}>
                  {val?.title}
                </Typography>
                <Typography
                  className={`${classes.riskDetailsTextSub} fontSize34`}
                >
                  {val?.data}
                </Typography>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default FinxedIcomeSection;
