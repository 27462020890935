import { Grid, Button, Modal, Typography, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import useStyles from "../common/ProductsStyles";
import TabComponent from "../common/TabComponent";
import { ReactComponent as Print } from "../../../../assets/icons/print.svg";
import { ReactComponent as Export } from "../../../../assets/icons/export.svg";
import { ReactComponent as Filter } from "../../../../assets/icons/filter.svg";
import { hardData, tableConfig } from "./utils/constands";
import { useEffect } from "react";
import {
  objectFormatingHolding,
  tableHeadConfiguration,
} from "./utils/objectFormating";
import CustomTableColSpan2 from "../../common/CustomTableColSpan2";
import Marketmeter from "./Marketmeter";
import moment from "moment";
import CheckBoxWithColor from "../common/CheckBoxWithColor";

function HoldingFamily() {
  const classes = useStyles();
  const [page, setPage] = useState("Family Wise");
  const [page2, setPage2] = useState("Portfolio Percentage");
  const [tableData, setTableData] = useState([]);
  const [tableHeader, setTableHead] = useState([]);
  ////////////////////////
  const [openModal, setOpenModal] = useState(false);
  const [dataRange, setDataRange] = useState([
    moment().subtract(1, "months").format("YYYY-MM-DD"),
    moment().format("YYYY-MM-DD"),
  ]);
  const [range, setRange] = useState("1M");
  const [clear, setClear] = useState(false);
  const dataFilter = ["Rahul", "Rhea", "Ram", "Sachin", "Nikhil"];
  const [checkboxvalue, setCheckboxvalue] = useState(dataFilter[0]);

  const options = [
    { id: 1, label: "Rahul" },
    { id: 2, label: "Raj" },
    { id: 3, label: "Rhea" },
    { id: 4, label: "Sachin" },
    { id: 5, label: "Nikhil" },
  ];
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionToggle = (option) => {
    const index = selectedOptions.findIndex((o) => o.id === option.id);
    if (index === -1) {
      setSelectedOptions([...selectedOptions, option]);
    } else {
      setSelectedOptions(selectedOptions.filter((o) => o.id !== option.id));
    }
  };
  ////////////////////////
  const data = [
    { page: "Family Wise", title: "Family Wise" },
    { page: "  Advisor Wise", title: "  Advisor Wise" },
  ];

  const data2 = [
    { page: "Portfolio Percentage", title: "Portfolio Percentage" },
    { page: "Market Value", title: "Market Value" },
  ];

  useEffect(() => {
    setTableHead(tableHeadConfiguration(hardData));
    setTableData(objectFormatingHolding(hardData, tableConfig));
  }, []);

  return (
    <>
      <div className={classes.mainContent}>
        <div className={classes.newAlignTopHeader}>
          <TabComponent
            data={data}
            page={page}
            setPage={setPage}
            type={"state"}
            width={"fit-content"}
          />
          <div className={`${classes.newIconAlign}`}>
            <Print className={classes.iconStyle} />
            <Export className={classes.iconStyle} />
          </div>
        </div>

        <div className={classes.newAlignTopHeader}>
          <div className={classes.familyWise}>Family Wise</div>
          <div className={classes.bottomTabHeader}>
            <Button
              onClick={() => {
                setClear(true);
                setRange("1M");
                setDataRange([
                  moment().subtract(1, "months").format("YYYY-MM-DD"),
                  moment().format("YYYY-MM-DD"),
                ]);
              }}
              style={{ textDecoration: "none" }}
              to="#"
            >
              <div
                className={`${classes.FamilybottomTabHeaderText} ${
                  range === "1M" ? classes.bottomTabActive : ""
                }`}
              >
                1M
              </div>
            </Button>
            <Button
              onClick={() => {
                setClear(true);
                setRange("3M");
                setDataRange([
                  moment().subtract(3, "months").format("YYYY-MM-DD"),
                  moment().format("YYYY-MM-DD"),
                ]);
              }}
              style={{ textDecoration: "none" }}
              to="#"
            >
              <div
                className={`${classes.FamilybottomTabHeaderText} ${
                  range === "3M" ? classes.bottomTabActive : ""
                }`}
              >
                3M
              </div>
            </Button>
            <Button
              onClick={() => {
                setClear(true);
                setRange("6M");
                setDataRange([
                  moment().subtract(6, "months").format("YYYY-MM-DD"),
                  moment().format("YYYY-MM-DD"),
                ]);
              }}
              style={{ textDecoration: "none" }}
              to="#"
            >
              <div
                className={`${classes.FamilybottomTabHeaderText} ${
                  range === "6M" ? classes.bottomTabActive : ""
                }`}
              >
                6M
              </div>
            </Button>
            <Button
              onClick={() => {
                setClear(true);
                setRange("1Y");
                setDataRange([
                  moment().subtract(1, "years").format("YYYY-MM-DD"),
                  moment().format("YYYY-MM-DD"),
                ]);
              }}
              style={{ textDecoration: "none" }}
              to="#"
            >
              <div
                className={`${classes.FamilybottomTabHeaderText} ${
                  range === "1Y" ? classes.bottomTabActive : ""
                }`}
              >
                1Y
              </div>
            </Button>
            <Button
              onClick={() => {
                setClear(true);
                setRange("3Y");
                setDataRange([
                  moment().subtract(3, "years").format("YYYY-MM-DD"),
                  moment().format("YYYY-MM-DD"),
                ]);
              }}
              style={{ textDecoration: "none" }}
              to="#"
            >
              <div
                className={`${classes.FamilybottomTabHeaderText} ${
                  range === "3Y" ? classes.bottomTabActive : ""
                }`}
              >
                3Y
              </div>
            </Button>
            <Button
              onClick={() => {
                setClear(true);
                setRange("YTD");
                setDataRange([null, null]);
              }}
              style={{ textDecoration: "none" }}
              to="#"
            >
              <div
                className={`${classes.FamilybottomTabHeaderText} ${
                  range === "YTD" ? classes.bottomTabActive : ""
                }`}
              >
                YTD
              </div>
            </Button>
            <Button
              onClick={() => {
                setClear(true);
                setRange("Inception");
                setDataRange([null, null]);
              }}
              style={{ textDecoration: "none" }}
              to="#"
            >
              <div
                className={`${classes.FamilybottomTabHeaderText} ${
                  range === "Inception" ? classes.bottomTabActive : ""
                }`}
              >
                Inception
              </div>
            </Button>
            <Button style={{ textDecoration: "none", display: "none" }} to="#">
              <div
                className={`${classes.FamilybottomTabHeaderText} ${classes.bottomTabActive}`}
              ></div>
            </Button>
          </div>
          {/* ///////////// */}
          <Modal
            open={openModal}
            onClose={() => setOpenModal(!openModal)}
            BackdropProps={{
              classes: {
                root: classes.backDrop,
              },
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className={`${classes.modalContainerModified} form-container`}>
              <div className="filter-header">
                <Typography className={classes.FamilyFiltertitleText}>
                  Filter
                </Typography>
              </div>
              {/* <Typography
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "3px",
                  }}
                  className={classes.mfoTextCard + " pointer"}
                  onClick={() => {
                    // setCheckAdvisor({});
                    setClear(true);
                  }}
                >
                  Clear All
                </Typography> */}
              <hr className="filter-divider"></hr>
              <div
                className={classes.fundPerformanceCardText}
                style={{ display: "flex", justifyContent: "space-between" }}
                onClick={handleDropdownClick}
              >
                Family Members
                <div className={classes.FamilyFilter}>
                  {selectedOptions.length > 0 && (
                    <span className="selected-count">
                      {selectedOptions.length}
                    </span>
                  )}
                </div>
              </div>
              <br />
              <div className="member">
                <div className="member-content">
                  {isOpen && (
                    <div className="dropdown-options">
                      {options.map((option) => (
                        <div key={option.id} className="option-item">
                          <input
                            type="checkbox"
                            style={{ borderRadius: "22px" }}
                            checked={
                              selectedOptions.findIndex(
                                (o) => o.id === option.id
                              ) !== -1
                            }
                            onChange={() => handleOptionToggle(option)}
                          />
                          <span className={classes.aifSubText}>
                            {option.label}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Modal>
          <Filter
            onClick={() => setOpenModal(!openModal)}
            className={classes.iconStyle}
          />
          {/* /////////////////////////// */}

          <div className={`${classes.newIconAlign}`}>
            <TabComponent
              data={data2}
              page={page2}
              setPage={setPage2}
              type={"state"}
              width={"fit-content"}
            />
          </div>
        </div>
        <div>
          <Marketmeter />
        </div>
      </div>
      <div className={classes.mainContent}>
        <CustomTableColSpan2
          tableHeader={tableHeader}
          tableHeaderBGColor={"#1D1B36"}
          tableColumns={tableData}
          tableBodyBackground={"#110425"}
        />
      </div>
    </>
  );
}
export default HoldingFamily;
