import React, { useEffect, useState } from "react";
import PageFilter from "../common/PageFilter";
import EquitySubTab from "./EquitySubTab";
import useStyles from "../common/ProductsStyles";
import EquityTransaction from "./EquityTransaction";
import EquityHolding from "./EquityHolding";
import EquityMarket from "./EquityMarket";
import EquityGain from "./EquityGain";
import EquitySector from "./EquitySector";
import EquityStock from "./EquityStock";
import EquityCorporate from "./EquityCorporate";
import TabEquityMain from "../common/TabEquityMain";
import IpoReport from "./ipo/IpoReport";
import EquityStockCompare from "./EquityStockCompare";
import EquityStockDetails from "./EquityStockDetails";
import NewTabComp from "../common/NewTabComp";
import { Link } from "react-router-dom";
import { routes } from "../../../../routes";
import EquityEngagment from "../holding_summary/HoldingEngagment";
import { NewFilter } from "../../newFilter/NewFilter";
import { SHOW_ADDITIONAL_DATA_TAB } from "../../../../Config";

function EquityReport({ pageName }) {
  const [page, setPage] = useState(pageName);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const classes = useStyles();
  let data = [];
  if (SHOW_ADDITIONAL_DATA_TAB === "show") {
    data = [
      {
        // path: "/home/equity/equity-summary/market",
        path: routes?.equityMarket,
        title: "Market capitalization",
        page: "market",
      },
      {
        // path: "/home/equity/equity-summary/sector",
        path: routes?.equitySector,
        title: "Sector Allocation",
        page: "sector",
      },
      {
        // path: "/home/equity/equity-summary/stock",
        path: routes?.equityStock,
        title: "Stock Summary",
        page: "stock",
      },
      // {
      //   // path: "/home/equity/equity-summary/stock",
      //   path: routes?.equityEngagment,
      //   title: "Engagement Model",
      //   page: "engagment",
      // },
      {
        // path: "/home/equity/equity-summary/gain",
        path: routes?.equityGain,
        title: "Gain/Loss Summary",
        page: "gain",
      },
      {
        // path: "/home/equity/equity-summary/stockcompare",
        path: routes?.equityStockCompare,
        title: "Stock Comparison",
        page: "stockcompare",
      },
      {
        // path: "/home/equity/equity-summary/holding",.
        path: routes?.equityHolding,
        title: "Holding Summary",
        page: "holding",
      },
      {
        // path: "/home/equity/equity-summary/transaction",
        path: routes?.equityTransaction,
        title: "Transaction Summary",
        page: "transaction",
      },
      {
        // path: "/home/equity/equity-summary/corporate",
        path: routes?.equityCorporate,
        title: " Corporate Actions",
        page: "corporate",
      },
      {
        // path: "/home/equity/equity-summary/ipo",
        path: routes?.equityIpo,
        title: "IPO",
        page: "ipo",
      },
    ];
  } else {
    data = [
      {
        // path: "/home/equity/equity-summary/market",
        path: routes?.equityMarket,
        title: "Market capitalization",
        page: "market",
      },
      {
        // path: "/home/equity/equity-summary/sector",
        path: routes?.equitySector,
        title: "Sector Allocation",
        page: "sector",
      },

      {
        // path: "/home/equity/equity-summary/holding",.
        path: routes?.equityHolding,
        title: "Holding Summary",
        page: "holding",
      },
      {
        // path: "/home/equity/equity-summary/transaction",
        path: routes?.equityTransaction,
        title: "Transaction Summary",
        page: "transaction",
      },
    ];
  }

  const changePage = (p) => {
    switch (p) {
      case "holding":
        return <EquityHolding />;
      case "transaction":
        return <EquityTransaction />;
      case "market":
        return <EquityMarket />;
      // case "engagment":
      //   return <EquityEngagment />;
      case "sector":
        return <EquitySector />;
      case "gain":
        return <EquityGain />;
      case "stock":
        return <EquityStock />;
      case "corporate":
        return <EquityCorporate />;
      case "ipo":
        return <IpoReport />;
      case "testing":
        return <EquityStockDetails />;

      case "stockcompare":
        return <EquityStockCompare />;
      default:
        return <EquityHolding></EquityHolding>;
    }
  };

  useEffect(() => {
    setPage(pageName);
  }, [pageName]);

  const handleClick = (page) => {
    setPage(page);
  };

  return (
    <>
      {page === "ipo" ? (
        <>
          <PageFilter
            backLink={routes?.equitySummaryPage}
            setFilterModalOpen={setFilterModalOpen}
          ></PageFilter>
          <NewFilter
            filterModalOpen={filterModalOpen}
            setFilterModalOpen={setFilterModalOpen}
            productType="equity"
          />
          <div style={{ margin: "2%" }}>
            <NewTabComp
              data={data}
              customSelect={data.findIndex((val) => val?.page == page)}
              customHandle={handleClick}
            />
          </div>
          {changePage(page)}
        </>
      ) : (
        <>
          <PageFilter
            backLink={routes?.equitySummaryPage}
            setFilterModalOpen={setFilterModalOpen}
          ></PageFilter>
          <NewFilter
            filterModalOpen={filterModalOpen}
            setFilterModalOpen={setFilterModalOpen}
            productType="equity"
          />
          <div style={{ margin: "2%" }}>
            <NewTabComp
              data={data}
              customSelect={data.findIndex((val) => val?.page == page)}
              customHandle={handleClick}
            />
          </div>
          {/* <TabEquityMain pageName={pageName}></TabEquityMain> */}
          <div
            style={{
              minHeight: "500px",
            }}
            className={
              ["gain", "stock", "corporate", "stockcompare"].includes(page)
                ? classes.removeOutline
                : ""
            }
          >
            {changePage(page)}
          </div>
        </>
      )}
    </>
  );
}

export default EquityReport;
