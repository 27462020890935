import React from "react";
import { ReactComponent as FileWhite } from "../../assets/icons/file_white.svg";
import { ReactComponent as DownloadFileicon } from "../../assets/icons/download.svg";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Comments } from "../../assets/icons/comment_icon.svg";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  TextField,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: red[500],
  },
  filebluebg: {
    background: "linear-gradient(180deg, #FE6587 0%, #F52D6A 100%)",
  },
  opacity1: {
    opacity: "1",
  },
  statusrejected: {
    color: "#F52D6A",
    marginLeft: "5px",
    fontWeight: "800",
    fontFamily: "Avenir",
  },
  carddefault: {
    color: "#fff",
    background: "#262346",
    borderRadius: "16px",
    margin: "5px",
  },
  detailbox: {
    background: "#1D1B36",
    borderRadius: "16px",
    color: "#635F94",
  },
  titletext: {
    color: "var(--selectedText)",
    fontWeight: "bold",
  },
  nomargin: {
    margin: "0px",
  },
  subheader: {
    color: "#635F94",
  },
  subnametext: {
    color: "#FFFFFF",
    opacity: "0.7",
  },
  commentdetails: {
    color: "#635F94",
  },
  comment: {
    color: "#FFFFFF",
  },
  text: {
    fontFamily: "Avenir",
  },
}));

function RejectCard({ singleContent }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const convertDate = (dateOld) => {
    let date = new Date(dateOld);
    date = date.toString("YYYY-MM-dd").split(" ");

    return `${date[2]} ${date[1]} ${date[3]} ${date[4]}`;
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.carddefault}>
      <div className={classes.detailbox}>
        <CardHeader
          classes={{ title: classes.titletext }}
          avatar={
            <Avatar aria-label="recipe" className={classes.filebluebg}>
              <FileWhite className={classes.opacity1}></FileWhite>
            </Avatar>
          }
          action={
            <IconButton aria-label="download">
              <a href={singleContent.signed_url} download>
                <DownloadFileicon />
              </a>
            </IconButton>
          }
          title={singleContent.s3_key.split("/")[1].substring(0, 20)}
          subheader={
            <p className={classes.nomargin}>
              <span className={classes.subheader}>
                Upload Details:{convertDate(singleContent.updated_at)}
              </span>
              <br></br>
              <span className={classes.subheader}>By </span>
              <span className={classes.subnametext}>
                {singleContent.created_by_name}
              </span>
            </p>
          }
        />
      </div>

      <CardContent>
        <div style={{ marginBottom: "5px", textAlign: "center" }}>
          <span>
            <span className={classes.text}>Verification Status: </span>
            <span className={classes.statusrejected}>Rejected</span>
          </span>
          <IconButton onClick={handleExpandClick} aria-label="comments">
            <Comments />
          </IconButton>
        </div>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <TextField
            id="outlined-multiline-static"
            multiline
            disabled
            style={{ width: "100%" }}
            minRows={2}
            defaultValue={singleContent.rejected_reason}
            variant="outlined"
          />
        </Collapse>
      </CardContent>
    </Card>
  );
}

export default RejectCard;
