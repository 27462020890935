import React from "react";
import { Box } from "@material-ui/core";
import { firstLetterCapitalize } from "../../utils/strHelper";
import { updateUserTheme } from "../../../api";
import { SHOW_ADDITIONAL_DATA_TAB } from "../../../Config";
export const ThemeSwitchDropDown = ({
  theme,
  navigate,
  colorMode,
  handleLogout,
  loginedUserData,
}) => {
  const isClientLogIn = loginedUserData?.user_type === "client";
  const MODES = {
    DEFAULT: "default",
    GOLD: "gold",
  };
  return (
    <Box>
      <Box
        className="nameHeaderContainer"
        style={{ background: theme.palette.themeSwitcher.headerBackGround }}
      >
        <Box className="userNameContainer">
          <p>User Name</p>
          <h1>{loginedUserData?.name}</h1>
        </Box>
        <Box className="userRoleContainer">
          <p>User Role</p>
          <h1>
            {firstLetterCapitalize(
              loginedUserData?.client_role || loginedUserData?.mfo_role_name
            )}
          </h1>
        </Box>
      </Box>
      {/* Flex Container */}
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap: isClientLogIn ? "1.5rem" : "12rem",
        }}
      >
        {isClientLogIn && (
          <Box display="flex" alignItems="center">
            <p className="dropDownMenuLabel">Family Tree Mapping</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="174"
              height="2"
              viewBox="0 0 174 2"
              fill="none"
            >
              <path opacity="0.2" d="M0 1L174 1.00002" stroke="white" />
            </svg>
          </Box>
        )}

        {isClientLogIn && (
          <Box
            className="dropDownMenuFamilyTreeContainer"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            style={{
              width: "348px",
              borderRadius: "8px",
              padding: "1rem",
              background: theme.palette.themeSwitcher.headerBackGround,
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              style={{ gap: "1rem", marginLeft: "10px" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M6 8V6.33356L10.9149 1.41869L12.5813 3.08513L7.66644 8H6Z"
                  fill="url(#paint0_linear_7759_7794)"
                />
                <path
                  d="M13.87 1.16984C14.0433 1.34315 14.0433 1.62311 13.87 1.79642L13.0568 2.60964L11.3904 0.943202L12.2036 0.129982C12.3769 -0.0433273 12.6569 -0.0433273 12.8302 0.129982L13.87 1.16984Z"
                  fill="url(#paint1_linear_7759_7794)"
                />
                <path
                  d="M2.17241 1.44827C1.77248 1.44827 1.44828 1.77249 1.44828 2.17243V11.8276C1.44828 12.2275 1.77248 12.5517 2.17241 12.5517H11.8276C12.2275 12.5517 12.5517 12.2275 12.5517 11.8276V6.96552C12.5517 6.56558 12.8759 6.24139 13.2759 6.24139C13.6758 6.24139 14 6.56558 14 6.96552V11.8276C14 13.0274 13.0274 14 11.8276 14H2.17241C0.972624 14 0 13.0274 0 11.8276V2.17243C0 0.972626 0.972622 7.17486e-07 2.17241 7.17486e-07H7.03448C7.43441 7.17486e-07 7.75862 0.324219 7.75862 0.724152C7.75862 1.12405 7.43441 1.44827 7.03448 1.44827H2.17241Z"
                  fill="url(#paint2_linear_7759_7794)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_7759_7794"
                    x1="7"
                    y1="0"
                    x2="7"
                    y2="14"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#AA6FFE" />
                    <stop offset="1" stop-color="#711CF1" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_7759_7794"
                    x1="7"
                    y1="0"
                    x2="7"
                    y2="14"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#AA6FFE" />
                    <stop offset="1" stop-color="#711CF1" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_7759_7794"
                    x1="7"
                    y1="0"
                    x2="7"
                    y2="14"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#AA6FFE" />
                    <stop offset="1" stop-color="#711CF1" />
                  </linearGradient>
                </defs>
              </svg>
              <h1 className="dropDownHeaderTxt">View Family Tree</h1>
            </Box>
            <Box style={{ marginRight: "10px" }}>
              <svg
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/user-management/family-structure")}
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <circle
                  cx="10"
                  cy="10"
                  r="10"
                  fill={theme.palette.rightArrowIcon.background}
                />
                <path
                  d="M7.28898 14.8013C7.1137 14.6709 7.00972 14.4876 7.00065 14.2931C6.99157 14.0985 7.07817 13.9092 7.24079 13.7681L10.8955 10.5094L7.28095 7.2507C6.92984 6.95678 6.92984 6.48489 7.28095 6.19097C7.64554 5.92166 8.20343 5.93924 8.54203 6.23071L12.759 9.97952C12.9146 10.1183 13.0006 10.3005 13 10.4895C13.0002 10.6765 12.914 10.8565 12.759 10.9929L8.54203 14.7616C8.37552 14.9092 8.14384 14.9951 7.89944 15C7.67272 15.0002 7.45443 14.9291 7.28898 14.8013Z"
                  fill="white"
                />
              </svg>
            </Box>
          </Box>
        )}

        <Box style={{ flex: 1 }}>
          <p className="dropDownMenuLabel">Theme</p>
          <Box className="themeBtnContainer">
            <button
              className={`themeBtn ${
                colorMode.mode === MODES.DEFAULT && "themeActiveBtn"
              }`}
              onClick={() => {
                colorMode.colorMode.toggleColorMode(MODES.DEFAULT);
                updateUserTheme({ webTheme: MODES.DEFAULT })
                  .then(() => {})
                  .catch(() => {});
              }}
            >
              Default
            </button>
            <button
              disabled={SHOW_ADDITIONAL_DATA_TAB === "show" ? false : true}
              style={{
                cursor:
                  SHOW_ADDITIONAL_DATA_TAB === "show"
                    ? "pointer"
                    : "not-allowed",
              }}
              className={`themeBtn ${
                colorMode.mode === MODES.GOLD && "themeActiveBtn"
              }`}
              onClick={() => {
                colorMode.colorMode.toggleColorMode(MODES.GOLD);
                updateUserTheme({ webTheme: MODES.GOLD })
                  .then(() => {})
                  .catch(() => {});
              }}
            >
              Gold
            </button>
            <button
              disabled
              className={`themeBtn ${
                colorMode.mode === "light" && "themeActiveBtn"
              }`}
              onClick={() => {
                colorMode.colorMode.toggleColorMode("light");
              }}
              style={{ cursor: "not-allowed" }}
            >
              Light
            </button>
          </Box>
        </Box>

        <Box
          onClick={handleLogout}
          className="dropDownMenuFamilyTreeContainer"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          style={{
            width: "348px",
            background: theme.palette.themeSwitcher.headerBackGround,
            borderRadius: "8px",
            padding: "1rem",
            // marginTop: !isClientLogIn && "3rem",
            cursor: "pointer",
            flex: 1,
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            style={{
              gap: "1rem",
              marginLeft: "10px",
              cursor: "pointer",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.895 9.23C7.79279 9.22827 7.69127 9.24692 7.59634 9.28484C7.50141 9.32276 7.41499 9.37921 7.3421 9.45088C7.26922 9.52255 7.21134 9.60802 7.17183 9.7023C7.13232 9.79658 7.11199 9.89778 7.112 10C7.112 10.42 7.458 10.77 7.895 10.77H14V15.55C14 18 11.975 20 9.472 20H4.517C2.025 20 0 18.01 0 15.56V4.45C0 1.99 2.035 0 4.528 0H9.493C11.975 0 14 1.99 14 4.44V9.23H7.895ZM17.63 6.54L20.55 9.45C20.6224 9.52111 20.6799 9.60593 20.7191 9.6995C20.7584 9.79308 20.7786 9.89353 20.7786 9.995C20.7786 10.0965 20.7584 10.1969 20.7191 10.2905C20.6799 10.3841 20.6224 10.4689 20.55 10.54L17.63 13.45C17.48 13.6 17.28 13.68 17.09 13.68C16.9367 13.6808 16.7867 13.6359 16.659 13.5512C16.5313 13.4665 16.4316 13.3458 16.3726 13.2043C16.3137 13.0628 16.2981 12.907 16.3279 12.7567C16.3577 12.6064 16.4315 12.4683 16.54 12.36L18.14 10.77H14V9.23H18.14L16.54 7.64C16.24 7.34 16.24 6.85 16.54 6.55C16.84 6.24 17.33 6.24 17.63 6.54Z"
                fill="#6A30C1"
              />
            </svg>
            <h1 className="dropDownHeaderTxt">LOGOUT</h1>
          </Box>
        </Box>
      </Box>
      {/* Flex Container end */}
    </Box>
  );
};
