import * as Yup from "yup";

let requiredTXT = "Required";
let panError = "Invalid PAN!";
let invalidDateMsg = "Invalid Date";

let entityName = Yup.string()
  .matches(/^[a-z\d\-_\s]+$/i, "Only alphanumeric are allowed")
  .required(requiredTXT);
let basicRequired = Yup.string().required(requiredTXT);
let dateOfBirth = Yup.date().required(requiredTXT);
let email = Yup.string().email("Invalid email address").required(requiredTXT);
let panNumber = Yup.string()
  .matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, panError)
  .required(requiredTXT);
let password = Yup.string()
  .matches(/^\d{4}$/, "Pin must be exactly 4 digits")
  .required(requiredTXT);
let otp = Yup.string()
  .matches(/^\d{8}$/, "OTP must be exactly 8 digits")
  .required(requiredTXT);
let mobile = Yup.string()
  .matches(/^\d{10}$/, "Mobile number must have 10 digits")
  .required(requiredTXT);

let OTP = Yup.string()
  .required("OTP is required")
  .matches(/^[0-9]{6}$/, "OTP must be a 6 digit number");

let pin = Yup.string()
  .required("PIN is required")
  .matches(/^\d{4}$/, "PIN must be a 4-digit number");
let confirmPin = Yup.string()
  .oneOf([Yup.ref("pin"), null], "PINs do not match")
  .required("Confirm PIN is required");
let agreeToTerms = Yup.boolean().oneOf(
  [true],
  "You must agree to the terms and conditions"
);

export const logInValidationSchema = Yup.object({
  userName: email,
  password: password,
  rememberInfo: Yup.boolean(),
});

export const registerForm1ValidationSchema = Yup.object({
  entityName: entityName,
  entityType: basicRequired,
  panNumber: panNumber,
  dateOfBirth: dateOfBirth,
  email: email,
});

export const registerForm4ValidationSchema = Yup.object({
  entityName: entityName,
  entityType: basicRequired,
  panNumber: panNumber,
  dateOfBirth: dateOfBirth,
  email: email,
  mobile: mobile,
});

export const registerForm2ValidationSchema = Yup.object({
  entityName: entityName,
  // entityType: basicRequired,
  dateOfIncorporation: basicRequired,
  panNumber: panNumber,
  // dateOfBirth: dateOfBirth,
  // email: email,
  kycDate: dateOfBirth,
  kycStatus: basicRequired,
  address: basicRequired,
  commencementDate: dateOfBirth,
  // pan: basicRequired,
  // idProof: basicRequired,
});

export const OTPValidationSchema = Yup.object({
  otp: OTP,
});

export const pinValidationSchema = Yup.object({
  pin: pin,
  confirmPin: confirmPin,
  agreeToTerms: agreeToTerms,
});
export const pinSchema = Yup.object({
  pin: pin,
  confirmPin: confirmPin,
  agree: agreeToTerms,
});

export const registerClientForm1ValidationSchema = Yup.object({
  familyName: entityName,
});

export const registerClientForm1ValidationSchema2 = Yup.object({
  fullName: entityName,
  memberType: basicRequired,
  // userRole: basicRequired,
  roleId: basicRequired,
  panNumber: panNumber,
  dateOfBirth: dateOfBirth,
  email: email,
  mobile: mobile,
});
export const client_add_member_validation_schema = Yup.object({
  name: entityName,
  entityType: basicRequired,
  userRole: basicRequired,
  panNumber: panNumber,
  email: email,
  mobile: mobile,
});
export const client_add_member_validation = Yup.object({
  full_name: entityName,
  member_type: basicRequired,
  roleId: basicRequired,
  pan: panNumber,
  email: email,
  mobile: mobile,
});
export const partnerUserAddmember = Yup.object({
  fullName: entityName,
  pin: password,
  confirm_pin: confirmPin,
  userRole: basicRequired,

  email: email,
  mobile: mobile,
});
export const updatePartnerUser = Yup.object({
  fullName: entityName,
  userRole: basicRequired,
  email: email,
  mobile: mobile,
});

export const registerClientForm1ValidationSchema3 = Yup.object({
  memberName: entityName,
  memberType: basicRequired,
  roleId: basicRequired,
  panNumber: panNumber,
  // dateOfBirth: dateOfBirth,
  // email: email,
  // mobile: mobile,
  dateOfBirth: dateOfBirth,
  commencementDate: dateOfBirth,
  address: basicRequired,
  kycStatus: basicRequired,
  kycDate: dateOfBirth,
});

export const registerClientForm1ValidationSchemaNonHOF = Yup.object({
  memberName: entityName,
  memberType: basicRequired,
  roleId: basicRequired,
  panNumber: panNumber,
  // dateOfBirth: dateOfBirth,
  // email: email,
  // mobile: mobile,
  dateOfBirth: dateOfBirth,
  // commencementDate: dateOfBirth,
  address: basicRequired,
  kycStatus: basicRequired,
  kycDate: dateOfBirth,
});

export const addUserSchema = Yup.object({
  fullName: entityName,
  status: basicRequired,
  userRole: basicRequired,
  email: email,
  mobile: mobile,
});

export const addPartnerRepSchema = Yup.object({
  fullName: entityName,
  userRole: basicRequired,
  email: email,
  mobile: mobile,
  lobId: basicRequired,
  subLobId: basicRequired,
  region: basicRequired,
});

export const addPartnerRepSchemaEdit = Yup.object({
  fullName: entityName,
  userRole: basicRequired,
  // lobId: basicRequired,
  // subLobId: basicRequired,
  // region: basicRequired,
});

export const newRegisterForm1ValidationSchema = Yup.object({
  entityName: entityName,
  entityType: basicRequired,
  // panNumber: panNumber,
  // dateOfBirth: dateOfBirth,
  // email: email,
});

export const newRegisterForm4ValidationSchema = Yup.object({
  entityName: entityName,
  // entityType: basicRequired,
  // panNumber: panNumber,
  // dateOfBirth: dateOfBirth,
  email: email,
  mobile: mobile,
  lobId: basicRequired,
  subLobId: basicRequired,
  region: basicRequired,
});

export const newRegisterForm2ValidationSchema = Yup.object({
  entityName: entityName,
  // entityType: basicRequired,
  // dateOfIncorporation: basicRequired,
  // panNumber: panNumber,
  // dateOfBirth: dateOfBirth,
  // email: email,
  // kycDate: dateOfBirth,
  // kycStatus: basicRequired,
  // address: basicRequired,
  // commencementDate: dateOfBirth,
  // pan: basicRequired,
  // idProof: basicRequired,
});
