import React, { useRef, useEffect } from "react";
import { divergingCommonProps } from "../../../charts/nivo_objects";
import Header from "../Components/Header";
import CardsWrapper from "../Components/cardsWrapper";
import Hr from "../Components/Hr";
import Bar from "../Components/barGraph";
import PageContainer from "../PageContainer";
import PrintTable from "../Components/Table";
import { StackBarChart } from "../Components/StackBarChart";
import { StackTable } from "../Components/StackTable";
import { currencyFormat } from "../../../../hooks/constantFunction";
import { roundNumber } from "../../../utils/common";
import { ReactComponent as PdfThankyou } from "../images/Thankyou1.svg";
import Disclaimer from "./Disclaimer";
export default function ThankYouPage({
  data,
  denomination,
  newData,
  meta,
  pdfInfo,
}) {
  // console.log(meta, "newdata")/;

  function getposColor(val) {
    if (val < 0) {
      return "#A60000";
    } else if (val) {
      return "#00A651";
    }
  }
  let bartheme = {
    background: "transparent",
    textColor: "#333333",
    fontSize: 11,
    axis: {
      domain: {
        line: {
          stroke: "#fff",
          strokeWidth: 2,
        },
      },
      legend: {
        text: {
          fontSize: 12,
          fill: "#F8F9FB",
        },
      },
      ticks: {
        line: {
          stroke: "#1f1c40",
          strokeWidth: 1,
        },
        text: {
          fontSize: 11,
          fill: "#000000",
        },
      },
    },
    grid: {
      line: {
        stroke: "#1f1c40",
        strokeWidth: 1,
      },
    },
    legends: {
      title: {
        text: {
          fontSize: 11,
          fill: "#ebe8ee",
        },
      },
      text: {
        fontSize: 11,
        fill: "#000000",
      },
      ticks: {
        line: {},
        text: {
          fontSize: 10,
          fill: "#333333",
        },
      },
    },
    annotations: {
      text: {
        fontSize: 13,
        fill: "#333333",
        outlineWidth: 2,
        outlineColor: "#3c9cfa",
        outlineOpacity: 0.7,
      },
      link: {
        stroke: "#000000",
        strokeWidth: 1,
        outlineWidth: 2,
        outlineColor: "#ffffff",
        outlineOpacity: 1,
      },
      outline: {
        stroke: "#000000",
        strokeWidth: 2,
        outlineWidth: 2,
        outlineColor: "#ffffff",
        outlineOpacity: 1,
      },
      symbol: {
        fill: "#000000",
        outlineWidth: 2,
        outlineColor: "#ffffff",
        outlineOpacity: 1,
      },
    },
    tooltip: {
      container: {
        background: "#ffffff",
        color: "#333333",
        fontSize: 12,
      },
      basic: {},
      chip: {},
      table: {},
      tableCell: {},
      tableCellValue: {},
    },
  };
  bartheme.axis.domain.line.stroke = "#ECECEC";
  bartheme.axis.domain.line.strokeWidth = "1";

  let diverging = {
    ...divergingCommonProps,
    enableGridY: false,
  };

  let carddata = [
    {
      title: "CURRENT VALUE",
      value: currencyFormat(
        newData?.["dashboard-gain-loss"]?.portfolioValueAmount,
        undefined,
        denomination
      ),
      style: { color: "black", fontSize: "20px" },
      type: "rs",
    },
    {
      title: "INVESTED VALUE",
      value: currencyFormat(
        newData?.["dashboard-gain-loss"]?.investedAmount,
        undefined,
        denomination
      ),
      style: { color: "black", fontSize: "20px" },
      type: "rs",
    },
    {
      title: "TOTAL GAIN/LOSS",
      value: currencyFormat(
        newData?.["dashboard-gain-loss"]?.totalGainLoss,
        undefined,
        denomination
      ),
      style: {
        color: getposColor(newData?.["dashboard-gain-loss"]?.totalGainLoss),
        fontSize: "20px",
      },
      type: "rs",
    },
    {
      title: "XIRR",
      value:
        newData?.["dashboard-xirr"]?.xirrValue != null
          ? roundNumber(newData?.["dashboard-xirr"]?.xirrValue) + " %"
          : "--",
      style: {
        color: getposColor(newData?.["dashboard-xirr"]?.xirrValue),
        fontSize: "20px",
      },
      type: "rs",
    },
  ];
  let carddata2 = [
    {
      title: " ANNUALIZED TWRR",
      value:
        newData?.["dashboard-twrr"]?.annualisedTwrr != null
          ? roundNumber(
              parseFloat(newData?.["dashboard-twrr"]?.annualisedTwrr)
            ) + " %"
          : "--",
      display: "p_flex",
      titleStyle: { maxWidth: "222px", minWidth: "fit-content" },
      style: {
        color: getposColor(newData?.["dashboard-twrr"]?.annualisedTwrr),
        paddingTop: "0px",
        fontSize: "20px",
        marginTop: "-7px",
      },
      type: "rs",
    },
    {
      title: "ABSOLUTE TWRR",
      value:
        newData?.["dashboard-twrr"]?.absoluteTwrr != null
          ? roundNumber(newData?.["dashboard-twrr"]?.absoluteTwrr) + " %"
          : "--",
      style: {
        color: getposColor(newData?.["dashboard-twrr"]?.absoluteTwrr),
        paddingTop: "0px",
        fontSize: "20px",
        marginTop: "-7px",
      },
      titleStyle: { maxWidth: "222px", minWidth: "fit-content" },
      display: "p_flex",
      type: "rs",
    },
    {
      title: "BENCHMARK XIRR",
      value:
        newData?.["excess-performance-xirr"]?.benchmarkXirr != null
          ? roundNumber(newData?.["excess-performance-xirr"]?.benchmarkXirr) +
            " %"
          : "--",
      style: {
        paddingTop: "0px",
        fontSize: "20px",
        marginTop: "-7px",
        color: "#000000",
      },
      display: "p_flex",
      titleStyle: { maxWidth: "222px", minWidth: "fit-content" },
      type: "rs",
    },
  ];

  return (
    <div>
      <PageContainer
        layout_t={1}
        pageNo={1}
        bgColor={"#fff"}
        pdfInfo={pdfInfo}
        renderFooterLegend={<></>}
        headerText={" "}
      >
        <div
          style={{
            height: "100%",
            // aspectRatio: 6.5 / 2,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            top: "-2%",
            position: "absolute",
          }}
        >
          <p
            className="thankuText"
            // style={{
            //   position: "absolute",
            //   bottom: "27px",
            //   justifyContent: "center",
            //   alignItems: "center",
            //   display: " flex",
            //   paddingRight: "50px",
            // }}
          >
            Thank You
          </p>
          <div>
            <PdfThankyou height={"432px"} width={"432px"} />
          </div>
        </div>
      </PageContainer>
    </div>
  );
}
