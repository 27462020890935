import React from "react";
import { Link } from "react-router-dom";
import useStyles from "./ProductsStyles";

function TabPmsSubHolding({ subPage, setSubPage }) {
  const classes = useStyles();

  return (
    <>
      <div
        style={{ width: "fit-content", margin: "2%", cursor: "pointer" }}
        className={classes.tabMfo}
      >
        <a
          onClick={() => {
            setSubPage("equity");
          }}
          style={{ textDecoration: "none" }}
        >
          <div
            className={`${classes.tabHeaderText} ${
              subPage === "equity" ? classes.active : ""
            }`}
          >
            Equity
          </div>
        </a>
        <a
          onClick={() => {
            setSubPage("debt");
          }}
          style={{ textDecoration: "none" }}
        >
          <div
            className={`${classes.tabHeaderText} ${
              subPage === "debt" ? classes.active : ""
            }`}
          >
            Debt
          </div>
        </a>
        <a
          onClick={() => {
            setSubPage("alternate");
          }}
          style={{ textDecoration: "none" }}
        >
          <div
            className={`${classes.tabHeaderText}  ${
              subPage === "alternate" ? classes.active : ""
            } `}
          >
            Alternate Assets
          </div>
        </a>
        <a
          onClick={() => {
            setSubPage("cash");
          }}
          style={{ textDecoration: "none" }}
        >
          <div
            className={`${classes.tabHeaderText}  ${
              subPage === "cash" ? classes.active : ""
            }`}
          >
            Cash
          </div>
        </a>
      </div>
    </>
  );
}

export default TabPmsSubHolding;

// not used
